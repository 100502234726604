import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

export const retrieveBankOptions = (defaultValue) => {
    const configKey = "massive.payments.allowed.banks";
    const options = configUtils.getArray(configKey);

    return [
        {
            value: defaultValue,
            label: i18nUtils.get(`${configKey}.sameBank`),
        },
    ].concat(
        options.map((key) => ({
            value: key,
            label: i18nUtils.get(`${configKey}.${key}`),
        })),
    );
};

export default {};
