/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import { func, bool } from "prop-types";
import * as i18n from "util/i18n";
import { push } from "react-router-redux/actions";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";

class TokenPasswordActivation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobileNative: bool.isRequired,
    };

    static defaultProps = {};

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(push("/settings"));
    };

    getMobileContent = () => (
        <div className="above-the-fold">
            <div style={{ marginRight: "1rem", marginLeft: "1rem" }}>{this.renderDevices()}</div>
        </div>
    );

    centerContentMobile = () => (
        <div>
            <h1 className="m-0 text-white">{i18n.get("settings.token")}</h1>
        </div>
    );

    codeActivation = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/token/codeActivation"));
    };

    changeTokenPass = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/token/ChangeTokenPass"));
    };

    disabledTokenPass = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/token/disabledTokenPass"));
    };

    goToTokenPassword = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/token/tokenPassword"));
    };

    renderContent = () => {
        const { isMobileNative } = this.props;
        return (
            <>
                <MainContainer className="bg-white-mobile w-100">
                    <Container className=" align-items-center flex-grow container-white active-token">
                        <Col className="col col-12 mt-4 mt-lg-0">
                            <Image className="token-image" src="images/setting-tokenActive.svg" />

                            <div className="admin-content-center" style={{ position: "relative", bottom: "1.5rem" }}>
                                <I18n
                                    id="setting.token.text.image.active"
                                    component="h3"
                                    componentProps={{ style: { fontWeight: "normal" } }}
                                />
                            </div>
                        </Col>
                    </Container>
                    <Container
                        className={`flex-grow align-items-center ${isMobileNative ? "container-white" : ""}`}
                        gridClassName="form-content"
                        style={isMobileNative ? { marginTop: "4rem", marginBottom: "10vh" } : {}}>
                        <Col sm={12} md={4} lg={4} xl={4} className="col-12 col-md-6 col-lg-5 col-xl-4 px-0">
                            {isMobileNative && (
                                <Col sm={12} md={4} lg={4} xl={4} className="col col-12 align-items-center ">
                                    <Button
                                        label="setting.token.mobile.activation.button1"
                                        className="btn btn-outline btn-uppercase"
                                        onClick={this.goToTokenPassword}
                                        style={{ button: "normal" }}
                                        bsStyle="primary"
                                    />
                                </Col>
                            )}
                            <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                                <Button
                                    label="setting.token.mobile.activation.button2"
                                    className={`${
                                        !isMobileNative
                                            ? "btn btn-outline btn-uppercase"
                                            : "btn btn-outline btn-uppercase"
                                    }`}
                                    onClick={() => {
                                        this.changeTokenPass();
                                    }}
                                />
                            </Col>
                            <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                                <Button
                                    label="setting.token.mobile.activation.button3"
                                    onClick={() => {
                                        this.disabledTokenPass();
                                    }}
                                    className={`${
                                        !isMobileNative
                                            ? "btn btn-outline btn-uppercase"
                                            : "btn btn-outline btn-uppercase"
                                    }`}
                                />
                            </Col>
                        </Col>
                    </Container>
                </MainContainer>
            </>
        );
    };

    render() {
        return this.renderContent();
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(TokenPasswordActivation);
