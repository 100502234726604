import { NO_TRANSITION } from "constants.js";

export const getTransition = (props, nextProps) => {
    const { location: actualLocation } = props;
    const { location: nextLocation } = nextProps;

    if (nextLocation.state && nextLocation.state.transition) {
        return nextLocation.state.transition === NO_TRANSITION ? "" : nextLocation.state.transition;
    }

    // logica basica para navegacion hacia adelante y atras
    // despues ver si hay que poner alguna logica especifica dependiendo de la url
    // si la url es de la forma /path/paso1 y /path/paso2 analiza el paso
    if (actualLocation.pathname.slice(0, -1) === nextLocation.pathname.slice(0, -1)) {
        const stepLocation1 = parseInt(actualLocation.pathname.slice(-1), 10);
        const stepLocation2 = parseInt(nextLocation.pathname.slice(-1), 10);
        if (stepLocation1 && stepLocation2) {
            return stepLocation1 < stepLocation2 ? "transition-drill-in" : "transition-drill-out";
        }
    }

    if (nextLocation.pathname !== "/" && actualLocation.pathname.indexOf(nextLocation.pathname) !== -1) {
        return "transition-drill-out";
    }
    if (nextLocation.pathname.indexOf(actualLocation.pathname) !== -1) {
        return "transition-drill-in";
    }

    return "transition-change-feature";
};

export default {};
