/* eslint-disable no-useless-computed-key */
import { getGeneralTicketItem } from "util/transaction";
import * as i18nUtils from "util/i18n";

function dataFromTransaction(key, extraData) {
    const signaturesModifySend = {
        submitDateTimeAsString: {
            i18nLabel: "forms.transaction.ticket.date",
            type: "string",
        },
        idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
        ["extraData.scheme.value"]: {
            i18nLabel: extraData?.extraData?.scheme?.label,
            type: "string",
        },
        ["extraData.transactionDispatch.value"]: {
            i18nLabel: extraData?.extraData?.transactionDispatch?.label,
            type: "string",
        },
        ["extraData.transactionType.value"]: {
            i18nLabel: extraData?.extraData?.transactionType?.label,
            type: "string",
        },
        ["extraData.functionalGroups.value"]: {
            i18nLabel: extraData?.extraData?.functionalGroups?.label,
            type: "string",
        },
        ["extraData.topAmount.value"]: {
            i18nLabel: extraData?.extraData?.topAmount?.label,
            type: "amountFrequency",
        },
    };

    const groupModifySend = {
        submitDateTimeAsString: {
            i18nLabel: "forms.transaction.ticket.date",

            type: "string",
        },
        idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
        ["data.name"]: {
            i18nLabel: "administration.advanced.group.create.name.label",

            type: "string",
        },
        ["data.description"]: {
            i18nLabel: "administration.advanced.group.create.description.label",

            type: "string",
        },
        ["data.status"]: {
            i18nLabel: "administration.advanced.group.create.status.label",
            i18nValue: "administration.advanced.group.create.status.#value#.label",
            type: "string",
        },
    };

    const fileData = {
        ["administration.users.invite.send"]: {
            submitDateTimeAsString: {
                i18nLabel: "forms.transaction.ticket.date",

                type: "string",
            },
            idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
            ["data.firstName"]: {
                i18nLabel: "administration.users.invite.firstName.label",

                type: "string",
            },
            ["data.lastName"]: {
                i18nLabel: "administration.users.invite.lastName.label",

                type: "string",
            },
            ["data.email"]: {
                i18nLabel: "administration.users.invite.email.label",

                type: "string",
            },
            ["data.mobilePhone"]: {
                i18nLabel: "administration.users.invite.mobilePhone.label",
                type: "string",
            },
            ["data1.value"]: {
                i18nLabel: extraData?.data1?.label,

                type: "string",
            },
            ["data2.value"]: {
                i18nLabel: extraData?.data2?.label,
                type: "string",
            },
        },
        ["administration.advanced.group.modify.send"]: groupModifySend,
        ["administration.advanced.group.create.send"]: groupModifySend,
        ["administration.signatures.modify.send"]: signaturesModifySend,
        ["administration.signatures.create.send"]: signaturesModifySend,
        ["administration.signatures.delete.send"]: signaturesModifySend,
        ["administration.advanced.modify.signature.send"]: {
            submitDateTimeAsString: {
                i18nLabel: "forms.transaction.ticket.date",
                type: "string",
            },
            idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
            ["user.value"]: {
                i18nLabel: extraData?.user?.label,
                type: "string",
            },
            ["scheme.value"]: {
                i18nLabel: extraData?.scheme?.label,
                type: "string",
            },
        },
        ["administration.user.detail.groups.modify.send"]: {
            submitDateTimeAsString: {
                i18nLabel: "forms.transaction.ticket.date",

                type: "string",
            },
            idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
            ["userExtraData"]: {
                i18nLabel: "administration.signature.user",

                type: "string",
            },
            ["groupsExtraData"]: {
                i18nLabel: "administration.users.detail.configureGroups.confirm",

                type: "string",
            },
        },
        ["administration.restrictions.manage.send"]: {
            submitDateTimeAsString: {
                i18nLabel: "forms.transaction.ticket.date",

                type: "string",
            },
            idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
            ["value"]: {
                i18nLabel: "administration.restrictions.restrictions.type",

                type: "string",
            },
            ["data1.value"]: {
                i18nLabel: extraData?.data1?.label,
                type: "string",
            },
            ["data2.value"]: {
                i18nLabel: extraData?.data2?.label,
                type: "string",
            },
            ["data3.value"]: {
                i18nLabel: extraData?.data3?.label,
                type: "string",
            },
            ["data4.value"]: {
                i18nLabel: extraData?.data4?.label,
                type: "string",
            },
            ["data5.value"]: {
                i18nLabel: extraData?.data5?.label,
                type: "string",
            },
            ["data6.value"]: {
                i18nLabel: extraData?.data6?.label,
                type: "string",
            },
            ["data7.value"]: {
                i18nLabel: extraData?.data7?.label,
                type: "string",
            },
            ["data8.value"]: {
                i18nLabel: extraData?.data8?.label,
                type: "string",
            },
            ["data9.value"]: {
                i18nLabel: extraData?.data9?.label,
                type: "string",
            },
            ["user.value"]: {
                i18nLabel: extraData?.user?.label,
                type: "string",
            },
            ["document.value"]: {
                i18nLabel: extraData?.document?.label,
                type: "string",
            },
        },
        ["administration.advanced.modify.channels.send"]: {
            submitDateTimeAsString: {
                i18nLabel: "forms.transaction.ticket.date",

                type: "string",
            },
            idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
            ["userExtraData"]: {
                i18nLabel: "administration.signature.user",

                type: "string",
            },
            ["topAmount.value"]: {
                i18nLabel: extraData?.topAmount?.label,
                type: "amountFrequency",
            },
            ["channel.value"]: {
                i18nLabel: extraData?.channel?.label,
                type: "string",
            },
            ["channelsfrontend.value"]: {
                i18nLabel: extraData?.channelsfrontend?.label,
                type: "amountFrequency",
            },
            ["channelsphonegap.value"]: {
                i18nLabel: extraData?.channelsphonegap?.label,
                type: "amountFrequency",
            },
        },
        ["administration.users.update"]: {
            submitDateTimeAsString: {
                i18nLabel: "forms.transaction.ticket.date",

                type: "string",
            },
            idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
            ["data.userFullName"]: {
                i18nLabel: "administration.groups.edit.name",
                type: "string",
            },
            ["data.userDocument"]: {
                i18nLabel: "forms.document.number.label",
                type: "string",
                i18nValue: "#value#",
            },
        },
        ["administration.groups.delete.send"]: {
            submitDateTimeAsString: {
                i18nLabel: "forms.transaction.ticket.date",

                type: "string",
            },
            idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
            ["group.value"]: {
                i18nLabel: extraData?.group?.label,
                type: "string",
            },
        },
        ["administration.modify.permissions.send"]: {
            submitDateTimeAsString: {
                i18nLabel: "forms.transaction.ticket.date",

                type: "string",
            },
            idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
            ["user.value"]: {
                i18nLabel: extraData?.user?.label,
                type: "string",
            },
            ...extraData.export,
        },
        ["administration.restrictions.user.delete.send"]: {
            submitDateTimeAsString: {
                i18nLabel: "forms.transaction.ticket.date",

                type: "string",
            },
            idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
            ["type.value"]: {
                i18nLabel: extraData?.type?.label,
                type: "string",
            },
        },
        ["administration.users.delete.send"]: {
            submitDateTimeAsString: {
                i18nLabel: "forms.transaction.ticket.date",

                type: "string",
            },
            idTransaction: { i18nLabel: "forms.transaction.ticket.number", type: "string" },
            ["data1.value"]: {
                i18nLabel: extraData?.data1?.label,
                type: "string",
            },
        },
    };

    return fileData[key];
}

function getAttributeValue(item, attributeValue) {
    if (attributeValue && attributeValue[item]) {
        return item === "idTransaction" ? attributeValue[item].substring(0, 8).toUpperCase() : attributeValue[item];
    }
    return null;
}

function findI18nText(dataDefinition, key, attributeValue) {
    const value = i18nUtils.get(dataDefinition[key].i18nValue.replace("#value#", attributeValue));

    return value.includes("*") ? attributeValue : value;
}

export default function searchForFileData(data, activity) {
    if (activity) {
        const dataDefinition = dataFromTransaction(activity, data);
        let ticketBody = [];

        if (dataDefinition) {
            // eslint-disable-next-line no-restricted-syntax
            for (const key of Object.keys(dataDefinition)) {
                const attributes = key.split(".");
                let attributeValue = data;
                attributes.forEach(
                    // eslint-disable-next-line no-return-assign
                    (item) => (attributeValue = getAttributeValue(item, attributeValue)),
                );

                if (attributeValue) {
                    const ticketItem = getGeneralTicketItem(
                        dataDefinition[key].i18nLabel,
                        dataDefinition[key].i18nValue
                            ? findI18nText(dataDefinition, key, attributeValue)
                            : attributeValue,
                        dataDefinition[key].type,
                    );
                    ticketBody = [...ticketBody, ticketItem];
                }
            }

            return ticketBody;
        }
        return [];
    }

    return [];
}
