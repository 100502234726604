import React, { Component } from "react";
import { string, shape, bool } from "prop-types";

import AccountAction from "pages/accounts/_components/AccountAction";
import Dropdown from "pages/_components/Dropdown";

class AccountTransfers extends Component {
    static propTypes = {
        account: shape({}).isRequired,
        isDesktop: bool.isRequired,
        classNames: string.isRequired,
    };

    render() {
        const { classNames, account, isDesktop } = this.props;
        const transferActions = [];
        if (account.permissions["transfer.internal"]) {
            transferActions.push(
                <AccountAction
                    key="transfer.internal"
                    labelKey="menu.transfers.internal"
                    to={`/form/transferInternal?debitAccount=${account.idProduct}`}
                    className={classNames}
                />,
            );
        }
        if (account.permissions["transfer.thirdParties"]) {
            transferActions.push(
                <AccountAction
                    key="transfer.thirdParties"
                    labelKey="menu.transfers.thirdParties"
                    className={classNames}
                    to={`/form/transferThirdParties?debitAccount=${account.idProduct}`}
                />,
            );
        }
        if (account.permissions["transfer.local"]) {
            transferActions.push(
                <AccountAction
                    key="transfer.local"
                    labelKey="menu.transfers.local"
                    className={classNames}
                    to={`/form/transferLocal?debitAccount=${account.idProduct}`}
                />,
            );
        }

        return isDesktop && transferActions.length > 1 ? (
            <Dropdown key="transfers" label="menu.transfers" buttonClass="btn btn-quiet">
                {transferActions}
            </Dropdown>
        ) : (
            transferActions.map((transferAction) => <li className="navigational-list-item">{transferAction}</li>)
        );
    }
}

export default AccountTransfers;
