import * as API from "middleware/api";

export const tokenCreatePreview = (idDevice, idForm, userId) =>
    API.executeAnonymous("/codeinvitetoken.create.preview", { idDevice, idForm, userId });

export const tokenCreate = (sendChannel) => API.executeWithAccessToken("/codeinvitetoken.create", { sendChannel });

export const dowloadFormToken = () => API.executeWithAccessToken("/codeinvitetoken.ExportFormBanck");

export const tokenActivationPre = () => API.executeWithAccessToken("/codeinvitetoken.activation.pre");

export const tokenActivationPreview = (code) =>
    API.executeWithAccessToken("/codeinvitetoken.activation.preview", { code });

export const tokenActivation = (password, passwordConfirmation, idDevice) =>
    API.executeWithAccessToken("codeinvitetoken.activation", {
        _tokenPass: password,
        _tokenPassConfirmation: passwordConfirmation,
        idDevice,
    });

export const changeTokenPass = (password, newPassword, newPasswordConfirmation) =>
    API.executeWithAccessToken("/change.tokenPass", {
        _tokenPass: password,
        _newPassword: newPassword,
        _newPasswordConfirmation: newPasswordConfirmation,
    });

export const recoverTokenPass = (username, documentType, document, password, sendChannel) =>
    API.executeWithAccessToken("/recover.tokenPass", {
        _password: password,
        _pin: username,
        document,
        documentType,
        sendNotification: sendChannel,
    });

export const tokenPasswordValidationExternal = (password, userId, idDevice) =>
    API.executeAnonymous("token.password.validation.external", {
        _tokenPass: password,
        userId,
        idDevice,
    });

export const tokenPasswordValidation = (password, userId, idDevice) =>
    API.executeAnonymous("token.password.validation", {
        _tokenPass: password,
        userId,
        idDevice,
    });

export const recoverTokenAnonymous = ({ password, username, document, documentType }) =>
    API.executeAnonymous("/token.recoverToken", {
        _password: password,
        _pin: username,
        documentType,
        document,
    });

export const recoverTokenPassAnonymous = (username, documentType, document, password, sendChannel) =>
    API.executeAnonymous("/recover.tokenPass.anonymous", {
        _password: password,
        _pin: username,
        document,
        documentType,
        sendNotification: sendChannel,
    });

export const tokenGenerationExternal = (idDevice, exchangeToken) =>
    API.executeWithExchangeToken("token.generation.external", { idDevice }, exchangeToken);

export const refreshTokenExternal = (idDevice, userId, exchangeToken) =>
    API.executeWithExchangeToken("refresh.token.validation.external", { idDevice, userId }, exchangeToken);

export const checkTokenExternal = (idDevice, userId, exchangeToken) =>
    API.executeWithExchangeToken("check.status.exchange.token", { idDevice, userId }, exchangeToken);

export const tokenGeneration = (idDevice) => API.executeWithAccessToken("token.generation.internal", { idDevice });

export const tokenPasswordDisabled = (password) =>
    API.executeWithAccessToken("/codeinvitetoken.disabled", {
        _tokenPass: password,
    });

export const getTokenStatus = (idDevice) => API.executeWithAccessToken("get.tokenPass.status", { idDevice });
export const changeNewToken = () => API.executeWithAccessToken("/changeDevice.new");

export const tokenSyncNewDevicePreview = (codeRandom) =>
    API.executeWithAccessToken("/token.sync.newDevice.preview", {
        _otp: codeRandom,
    });

export const tokenSyncNewDevice = (password, idDevice) =>
    API.executeWithAccessToken("/token.sync.newDevice", {
        _tokenPass: password,
        idDevice,
    });

export const tokenActivationSendNewCode = () => API.executeWithAccessToken("/codeinvitetoken.activation.newCode");

export const tokenActivationResendCode = () => API.executeWithAccessToken("/codeinvitetoken.activation.resendCode");

export const deleteDeviceWithoutSeed = (uuid) =>
    API.executeWithAccessToken("/token.softToken.deleteDeviceWithoutSeed", { uuid });