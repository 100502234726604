/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, shape, arrayOf, func } from "prop-types";
import { connect } from "react-redux";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";

import { selectors as metrocorpSelectors, actions as metrocorpActions } from "reducers/metrocorp";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import MetrocorpFilterSelect from "./MetrocorpFilterSelect";

const FORM_ID = "metrocorp.list";

class MetrocorpFutureValuesList extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        futureValues: arrayOf(shape({})).isRequired,
        filter: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    changeFilter = () => {
        const { dispatch, futureValues, filter } = this.props;

        dispatch(metrocorpActions.changeFilter(futureValues, filter));
    };

    renderListForFutureValues = () => {
        const { isDesktop, futureValues, filter } = this.props;

        const options = [
            {
                value: "species",
                label: <I18n id="metrocorp.list.holding.column.species" />,
            },
        ];

        const radioOptions = [
            {
                id: "0",
                label: <I18n id="metrocorp.list.futureValues.filter.ascendent" />,
            },
            {
                id: "1",
                label: <I18n id="metrocorp.list.futureValues.filter.descendent" />,
            },
        ];

        return (
            <>
                {futureValues && futureValues.length > 0 ? (
                    <>
                        {isDesktop && (
                            <div className="container-white mb-3 w-100 d-flex justify-content-start pb-3 pl-3">
                                <MetrocorpFilterSelect
                                    columnFilter={filter}
                                    changeFilter={this.changeFilter}
                                    options={options}
                                    radioOptions={radioOptions}
                                    className="metrocorp__orderby"
                                />
                            </div>
                        )}
                        {futureValues.map((item) => this.renderListForFutureValuesItem(item))}
                    </>
                ) : (
                    <div className="text-center no-more-data">
                        <div className="illustration-wrapper">
                            <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                        </div>
                        <p className="text-lead">
                            <I18n id={`${FORM_ID}.empty.message`} />
                        </p>
                    </div>
                )}
            </>
        );
    };

    renderListForFutureValuesItem = (item) => {
        const { isDesktop } = this.props;
        const {
            descripcionEspecie,
            descripcion,
            valorUnitario,
            saldoValorizado,
            fechaConcertacion,
            fechaLiquidacion,
            cantidad,
            especieComision,
            comision,
        } = item;

        const currency = "0";

        function quantityformatter(number) {
            const numbers = number
                .toFixed(2)
                .toString()
                .split(".");
            numbers[0] = numbers[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            return numbers.join(",");
        }

        if (isDesktop) {
            return (
                <>
                    <CardContainer className="metrocorp__card-container">
                        <Card
                            className="metrocorp__card"
                            title={
                                <div className="metrocorp__card-title">
                                    <div
                                        className={
                                            fechaLiquidacion !== null
                                                ? "metrocorp__date-movement metrocorp__separator"
                                                : "metrocorp__date-movement"
                                        }>
                                        <I18n
                                            id={`${FORM_ID}.futureValues.column.movementDate`}
                                            componentProps={{ className: "metrocorp__date-movement-info" }}
                                        />
                                        <FormattedDate
                                            date={fechaConcertacion}
                                            className="metrocorp__date-movement-data"
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="metrocorp__due-date">
                                        <I18n
                                            id={`${FORM_ID}.futureValues.column.dueDate`}
                                            componentProps={{ className: "metrocorp__due-date-info" }}
                                        />
                                        <FormattedDate
                                            date={fechaLiquidacion}
                                            className="metrocorp__due-date-data"
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                </div>
                            }
                            content={
                                <div className="metrocorp__card-content">
                                    <div className="metrocorp__description">
                                        <span className="metrocorp__description-info">{descripcion}</span>
                                    </div>
                                    <div className="metrocorp__quantity">
                                        <I18n
                                            id={`${FORM_ID}.futureValues.column.quantity`}
                                            componentProps={{ className: "metrocorp__quantity-info" }}
                                        />
                                        <span className="metrocorp__quantity-data">{quantityformatter(cantidad)}</span>
                                    </div>
                                    <div className="metrocorp__specie ml-0">
                                        <I18n
                                            id={`${FORM_ID}.futureValues.column.species`}
                                            componentProps={{ className: "metrocorp__specie-info" }}
                                        />
                                        <span className="metrocorp__specie-data">{descripcionEspecie}</span>
                                    </div>
                                </div>
                            }
                            bottom={
                                <div className="metrocorp__card-bottom">
                                    <div
                                        className={
                                            especieComision !== null || comision !== null
                                                ? "metrocorp__unit-value metrocorp__separator"
                                                : "metrocorp__unit-value"
                                        }>
                                        <I18n
                                            id={`${FORM_ID}.futureValues.column.unitValue`}
                                            componentProps={{ className: "metrocorp__unit-value-info" }}
                                        />
                                        <FormattedAmount
                                            currency={currency}
                                            className={
                                                valorUnitario >= 0
                                                    ? "metrocorp__unit-value-data"
                                                    : "metrocorp__unit-value-data metrocorp__red"
                                            }
                                            quantity={valorUnitario}
                                            notBold
                                        />
                                    </div>
                                    <div
                                        className={
                                            comision !== null
                                                ? "metrocorp__comission-specie metrocorp__separator"
                                                : "metrocorp__comission-specie"
                                        }>
                                        <I18n
                                            id={`${FORM_ID}.futureValues.column.speciesCommission`}
                                            componentProps={{ className: "metrocorp__comission-specie-info" }}
                                        />
                                        <span className="metrocorp__comission-specie-data">{especieComision}</span>
                                    </div>
                                    <div className="metrocorp__comission">
                                        <I18n
                                            id={`${FORM_ID}.futureValues.column.commission`}
                                            componentProps={{ className: "metrocorp__comission-info" }}
                                        />
                                        <FormattedAmount
                                            noCurrency
                                            className={
                                                comision >= 0
                                                    ? "metrocorp__comission-data"
                                                    : "metrocorp__comission-data metrocorp__red"
                                            }
                                            quantity={comision}
                                            notBold
                                        />
                                    </div>
                                </div>
                            }
                            rightContent={
                                <div className="metrocorp__card-right">
                                    <div className="metrocorp__totalamount">
                                        <I18n
                                            id={`${FORM_ID}.futureValues.column.balance`}
                                            componentProps={{ className: "metrocorp__totalamount-info" }}
                                        />
                                        <FormattedAmount
                                            currency={currency}
                                            className={
                                                saldoValorizado >= 0
                                                    ? "metrocorp__totalamount-data"
                                                    : "metrocorp__totalamount-data metrocorp__red"
                                            }
                                            quantity={saldoValorizado}
                                            notBold
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        }
        return (
            <>
                <CardContainer className="metrocorp__card-container">
                    <Card
                        className="metrocorp__card mt-0"
                        title={
                            <div className="metrocorp__card-title">
                                <div className="metrocorp__description">
                                    <span className="metrocorp__description-info">{descripcion}</span>
                                </div>
                                <div className="metrocorp__quantity">
                                    <I18n
                                        id={`${FORM_ID}.futureValues.column.quantity`}
                                        componentProps={{ className: "metrocorp__quantity-info" }}
                                    />
                                    <span className="metrocorp__quantity-data">{quantityformatter(cantidad)}</span>
                                </div>
                                <hr className="mr-0 ml-0" />
                            </div>
                        }
                        content={
                            <div className="metrocorp__card-content">
                                <div className="metrocorp__date-movement">
                                    <I18n
                                        id={`${FORM_ID}.futureValues.column.movementDate`}
                                        componentProps={{ className: "metrocorp__date-movement-info" }}
                                    />
                                    <FormattedDate
                                        date={fechaConcertacion}
                                        className="metrocorp__date-movement-data"
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                <div className="metrocorp__due-date">
                                    <I18n
                                        id={`${FORM_ID}.futureValues.column.dueDate`}
                                        componentProps={{ className: "metrocorp__due-date-info" }}
                                    />
                                    <FormattedDate
                                        date={fechaLiquidacion}
                                        className="metrocorp__due-date-data"
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                <div className="metrocorp__specie">
                                    <I18n
                                        id={`${FORM_ID}.futureValues.column.species`}
                                        componentProps={{ className: "metrocorp__specie-info" }}
                                    />
                                    <span className="metrocorp__specie-data">{descripcionEspecie}</span>
                                </div>
                                <div className="metrocorp__unit-value">
                                    <I18n
                                        id={`${FORM_ID}.futureValues.column.unitValue`}
                                        componentProps={{ className: "metrocorp__unit-value-info" }}
                                    />
                                    <FormattedAmount
                                        currency={currency}
                                        className={
                                            valorUnitario >= 0
                                                ? "metrocorp__unit-value-data"
                                                : "metrocorp__unit-value-data metrocorp__red"
                                        }
                                        quantity={valorUnitario}
                                        notBold
                                    />
                                </div>
                                <div className="metrocorp__comission-specie">
                                    <I18n
                                        id={`${FORM_ID}.futureValues.column.speciesCommission`}
                                        componentProps={{ className: "metrocorp__comission-specie-info" }}
                                    />
                                    <span className="metrocorp__comission-specie-data">{especieComision}</span>
                                </div>
                                <div className="metrocorp__comission">
                                    <I18n
                                        id={`${FORM_ID}.futureValues.column.commission`}
                                        componentProps={{ className: "metrocorp__comission-info" }}
                                    />
                                    <FormattedAmount
                                        noCurrency
                                        className={
                                            comision >= 0
                                                ? "metrocorp__comission-data"
                                                : "metrocorp__comission-data metrocorp__red"
                                        }
                                        quantity={comision}
                                        notBold
                                    />
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="metrocorp__card-right">
                                <div className="metrocorp__totalamount">
                                    <I18n
                                        id={`${FORM_ID}.futureValues.column.balanceMobile`}
                                        componentProps={{ className: "metrocorp__totalamount-info" }}
                                    />
                                    <FormattedAmount
                                        currency={currency}
                                        className={
                                            saldoValorizado >= 0
                                                ? "metrocorp__totalamount-data"
                                                : "metrocorp__totalamount-data metrocorp__red"
                                        }
                                        quantity={saldoValorizado}
                                        notBold
                                    />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            </>
        );
    };

    render() {
        return <>{this.renderListForFutureValues()}</>;
    }
}

const mapStateToProps = (state) => ({
    filter: metrocorpSelectors.getFilter(state),
});

export default connect(mapStateToProps)(MetrocorpFutureValuesList);
