export const types = {
    PARTIAL_CLEAN_UP: "servicePayments/PARTIAL_CLEAN_UP",
    PARTIAL_CLEAN_UP_WITH_RUBROS: "servicePayments/PARTIAL_CLEAN_UP_WITH_RUBROS",
    CLEAN_UP: "servicePayments/CLEAN_UP",
    CLEAN_UP_FOR_BACK: "servicePayments/CLEAN_UP_FOR_BACK",
    LIST_RUBROS_REQUEST: "servicePayments/LIST_RUBROS_REQUEST",
    LIST_RUBROS_FAILURE: "servicePayments/LIST_RUBROS_FAILURE",
    LIST_RUBROS_SUCCESS: "servicePayments/LIST_RUBROS_SUCCESS",
    LIST_ENTITIES_REQUEST: "servicesPayments/LIST_ENTITIES_REQUEST",
    LIST_ENTITIES_FAILURE: "servicePayments/LIST_ENTITIES_FAILURE",
    LIST_ENTITIES_SUCCESS: "servicePayments/LIST_ENTITIES_SUCCESS",
    CHECK_CPE_REQUEST: "servicePayments/CHECK_CPE_REQUEST",
    CHECK_CPE_FAILURE: "servicePayments/CHECK_CPE_FAILURE",
    CHECK_CPE_SUCCESS: "servicePayments/CHECK_CPE_SUCCESS",
    SELECT_CONCEPT: "servicePayments/SELECT_CONCEPT",
    SUBMIT_PAYMENT_REQUEST: "servicePayments/SUBMIT_PAYMENT_REQUEST",
    SUBMIT_PAYMENT_FAILURE: "servicePayments/SUBMIT_PAYMENT_FAILURE",
    SUBMIT_PAYMENT_SUCCESS: "servicePayments/SUBMIT_PAYMENT_SUCCESS",
    LOAD_SUMMARY_INFO: "servicePayments/LOAD_SUMMARY_INFO",
    LOAD_CONFIRM_SUBMIT_PAYMENT: "servicePayments/LOAD_CONFIRM_SUBMIT_PAYMENT",
    LIST_ADHESIONES_REQUEST: "servicePayments/LIST_ADHESIONES_REQUEST",
    LIST_ADHESIONES_FAILURE: "servicePayments/LIST_ADHESIONES_FAILURE",
    LIST_ADHESIONES_SUCCESS: "servicePayments/LIST_ADHESIONES_SUCCESS",
    UNATTACH_SERVICE_REQUEST: "servicePayments/UNATTACH_SERVICE_REQUEST",
    UNATTACH_SERVICE_FAILURE: "servicePayments/UNATTACH_SERVICE_FAILURE",
    UNATTACH_SERVICE_SUCCESS: "servicePayments/UNATTACH_SERVICE_SUCCESS",
    MODIFY_ADHESION_REFERENCE_REQUEST: "servicePayments/MODIFY_ADHESION_REFERENCE_REQUEST",
    MODIFY_ADHESION_REFERENCE_FAILURE: "servicePayments/MODIFY_ADHESION_REFERENCE_FAILURE",
    MODIFY_ADHESION_REFERENCE_SUCCESS: "servicePayments/MODIFY_ADHESION_REFERENCE_SUCCESS",
    LIST_AGENDA_PAGOS_REQUEST: "servicePayments/LIST_AGENDA_PAGOS_REQUEST",
    LIST_AGENDA_PAGOS_FAILURE: "servicePayments/LIST_AGENDA_PAGOS_FAILURE",
    LIST_AGENDA_PAGOS_SUCCESS: "servicePayments/LIST_AGENDA_PAGOS_SUCCESS",
    UPDATE_COMPONENT_STATE: "servicePayments/UPDATE_COMPONENT_STATE",
    PREPARE_PAYMENT_REQUEST: "servicePayments/PREPARE_PAYMENT_REQUEST",
    PREPARE_PAYMENT_FAILURE: "servicePayments/PREPARE_PAYMENT_FAILURE",
    PREPARE_PAYMENT_SUCCESS: "servicePayments/PREPARE_PAYMENT_SUCCESS",
    UPDATE_COMPONENT_VALUES: "servicePayments/UPDATE_COMPONENT_VALUES",
    PAYMENT_SELECTED: "payments/PAYMENT_SELECTED",
    SERVICE_SELECTED: "payments/SERVICE_SELECTED",
    SERVICE: "payments/SERVICE",
    PREPARE_MULTIPLE_PAYMENTS_REQUEST: "servicePayments/PREPARE_MULTIPLE_PAYMENTS_REQUEST",
    PREPARE_MULTIPLE_PAYMENTS_FAILURE: "servicePayments/PREPARE_MULTIPLE_PAYMENTS_FAILURE",
    PREPARE_MULTIPLE_PAYMENTS_SUCCESS: "servicePayments/PREPARE_MULTIPLE_PAYMENTS_SUCCESS",
    SUBMIT_MULTIPLE_PAYMENTS_REQUEST: "servicePayments/SUBMIT_MULTIPLE_PAYMENTS_REQUEST",
    SUBMIT_MULTIPLE_PAYMENTS_FAILURE: "servicePayments/SUBMIT_MULTIPLE_PAYMENTS_FAILURE",
    SUBMIT_MULTIPLE_PAYMENTS_SUCCESS: "servicePayments/SUBMIT_MULTIPLE_PAYMENTS_SUCCESS",
    MODIFY_MULTIPLE_PAYMENTS_INFO: "servicePayments/MODIFY_MULTIPLE_PAYMENTS_INFO",
    SET_MULTIPLE_PAYMENTS_ACCOUNT: "servicePayments/SET_MULTIPLE_PAYMENTS_ACCOUNT",
    HANDLE_BACK_TICKET: "servicePayments/HANDLE_BACK_TICKET",
    CLEAN_BACK_TICKET: "servicePayments/CLEAN_BACK_TICKET",
    CLEAN_SUMMARY_INFO: "servicePayments/CLEAN_SUMMARY_INFO",
};

export const INITIAL_STATE = {
    fetching: false,
    rubros: null,
    entities: null,
    paymentsConcept: null,
    isSubmittingConcepts: false,
    selectedConcept: false,
    serviceSelected: true,
    summaryInfo: null,
    isSubmitting: false,
    summary: null,
    adhesiones: null,
    agendaPagos: [],
    componentState: {
        multipleConcepts: false,
        isEntitiesDisabled: true,
        showConcepts: false,
        isAddServiceSelected: true,
    },
    componentValues: {
        rubros: "",
        entities: "",
        cpe: "",
        concept: "",
        account: "",
        reference: "",
        paymentReference: "",
    },
    multiplePaymentsInfo: [],
    multiplePaymentsAccount: null,
    backFromTicket: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        rubros,
        entities,
        paymentsConcept,
        conceptCode,
        statusCPE,
        summary,
        summaryInfo,
        adhesiones,
        agendaPagos,
        flatPayments,
        newState,
        newValues,
        serviceSelected,
        multiplePaymentsInfo,
        multiplePaymentsAccount,
    } = action;

    switch (action.type) {
        case types.LIST_RUBROS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LIST_RUBROS_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_RUBROS_SUCCESS:
            return {
                ...state,
                fetching: false,
                rubros,
            };
        case types.LIST_ENTITIES_REQUEST:
            return {
                ...state,
                isSubmittingConcepts: true,
            };
        case types.LIST_ENTITIES_FAILURE:
            return {
                ...state,
                isSubmittingConcepts: false,
            };
        case types.LIST_ENTITIES_SUCCESS:
            return {
                ...state,
                isSubmittingConcepts: false,
                entities,
            };
        case types.CHECK_CPE_REQUEST:
            return {
                ...state,
                isSubmittingConcepts: true,
            };
        case types.CHECK_CPE_FAILURE:
            return {
                ...state,
                isSubmittingConcepts: false,
            };
        case types.CHECK_CPE_SUCCESS:
            return {
                ...state,
                isSubmittingConcepts: false,
                paymentsConcept,
                statusCPE,
            };
        case types.SELECT_CONCEPT:
            return {
                ...state,
                selectedConcept: conceptCode,
            };
        case types.LOAD_SUMMARY_INFO:
            return {
                ...state,
                summaryInfo,
            };
        case types.SUBMIT_PAYMENT_REQUEST:
            return {
                ...state,
                isSubmitting: true,
            };
        case types.SUBMIT_PAYMENT_FAILURE:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.SUBMIT_PAYMENT_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.LOAD_CONFIRM_SUBMIT_PAYMENT:
            return {
                ...state,
                summary,
                summaryInfo,
            };
        case types.PARTIAL_CLEAN_UP_WITH_RUBROS:
            return {
                ...INITIAL_STATE,
                rubros: state.rubros,
                serviceSelected: state.serviceSelected,
            };
        case types.PARTIAL_CLEAN_UP:
            return {
                ...INITIAL_STATE,
                summaryInfo: state.summaryInfo,
                entities: state.entities,
                serviceSelected: state.serviceSelected,
                fetching: state.fetching,
                backFromTicket: state.backFromTicket,
            };
        case types.CLEAN_UP:
            return INITIAL_STATE;
        case types.CLEAN_UP_FOR_BACK:
            return { ...INITIAL_STATE, serviceSelected: state.serviceSelected, backFromTicket: state.backFromTicket };
        case types.CLEAN_SUMMARY_INFO:
            return { ...state, summaryInfo: INITIAL_STATE.summaryInfo };
        case types.LIST_ADHESIONES_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LIST_ADHESIONES_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_ADHESIONES_SUCCESS:
            return {
                ...state,
                adhesiones,
                fetching: false,
            };
        case types.UNATTACH_SERVICE_REQUEST:
            return {
                ...state,
                isSubmitting: true,
            };
        case types.UNATTACH_SERVICE_FAILURE:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.UNATTACH_SERVICE_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.MODIFY_ADHESION_REFERENCE_REQUEST:
            return {
                ...state,
                isSubmitting: true,
            };
        case types.MODIFY_ADHESION_REFERENCE_FAILURE:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.MODIFY_ADHESION_REFERENCE_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.LIST_AGENDA_PAGOS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LIST_AGENDA_PAGOS_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_AGENDA_PAGOS_SUCCESS:
            return {
                ...state,
                fetching: false,
                agendaPagos,
                flatPayments,
            };
        case types.UPDATE_COMPONENT_STATE:
            return {
                ...state,
                componentState: newState,
            };
        case types.UPDATE_COMPONENT_VALUES:
            return {
                ...state,
                componentValues: newValues,
            };
        case types.SERVICE_SELECTED:
            return { ...state, ...INITIAL_STATE, serviceSelected: true };
        case types.PAYMENT_SELECTED:
            return { ...state, ...INITIAL_STATE, serviceSelected: false };
        case types.SERVICE:
            return { ...state, serviceSelected };
        case types.PREPARE_PAYMENT_SUCCESS:
            return { ...state, summaryInfo };
        case types.MODIFY_MULTIPLE_PAYMENTS_INFO:
            return {
                ...state,
                multiplePaymentsInfo,
            };
        case types.SET_MULTIPLE_PAYMENTS_ACCOUNT:
            return {
                ...state,
                multiplePaymentsAccount,
            };
        case types.SUBMIT_MULTIPLE_PAYMENTS_REQUEST:
            return {
                ...state,
                isSubmitting: true,
            };
        case types.SUBMIT_MULTIPLE_PAYMENTS_FAILURE:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.SUBMIT_MULTIPLE_PAYMENTS_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.HANDLE_BACK_TICKET:
            return {
                ...state,
                backFromTicket: true,
            };
        case types.CLEAN_BACK_TICKET:
            return {
                ...state,
                backFromTicket: false,
            };
        default:
            return state;
    }
};

export const actions = {
    listRubros: (account) => ({
        type: types.LIST_RUBROS_REQUEST,
        account,
    }),
    listEntities: (categoryCode) => ({
        type: types.LIST_ENTITIES_REQUEST,
        categoryCode,
    }),
    partialCleanUp: () => ({
        type: types.PARTIAL_CLEAN_UP,
    }),
    cleanUp: () => ({
        type: types.CLEAN_UP,
    }),
    cleanUpForBack: () => ({
        type: types.CLEAN_UP_FOR_BACK,
    }),
    partialCleanUpWithRubros: () => ({
        type: types.PARTIAL_CLEAN_UP_WITH_RUBROS,
    }),
    checkCpe: (entityCode, cpe) => ({
        type: types.CHECK_CPE_REQUEST,
        entityCode,
        cpe,
    }),
    selectConcept: (conceptCode) => ({
        type: types.SELECT_CONCEPT,
        conceptCode,
    }),
    selectAmountToPay: (amountToPay) => ({
        type: types.SELECT_AMOUNT_TO_PAY,
        amountToPay,
    }),
    loadSummaryInfo: (summaryInfo) => ({
        type: types.LOAD_SUMMARY_INFO,
        summaryInfo,
    }),
    submitPayment: (summaryInfo, otp, formikBag) => ({
        type: types.SUBMIT_PAYMENT_REQUEST,
        summaryInfo,
        otp,
        formikBag,
    }),
    loadConfirmServicePayments: (summary) => ({
        type: types.LOAD_CONFIRM_SUBMIT_PAYMENT,
        summary,
        summaryInfo: summary,
    }),
    listAdhesiones: () => ({
        type: types.LIST_ADHESIONES_REQUEST,
    }),
    unattachService: (adhesion) => ({
        type: types.UNATTACH_SERVICE_REQUEST,
        adhesion,
    }),
    modifyAdhesionReference: (reference, adhesion) => ({
        type: types.MODIFY_ADHESION_REFERENCE_REQUEST,
        reference,
        adhesion,
    }),
    listAgendaPagos: () => ({
        type: types.LIST_AGENDA_PAGOS_REQUEST,
    }),
    updateComponetState: (newState) => ({
        type: types.UPDATE_COMPONENT_STATE,
        newState,
    }),
    updateComponentValues: (newValues) => ({
        type: types.UPDATE_COMPONENT_VALUES,
        newValues,
    }),
    preparePayment: (paymentInfo, formikBag) => ({
        type: types.PREPARE_PAYMENT_REQUEST,
        paymentInfo,
        formikBag,
    }),
    setServiceSelected: () => ({
        type: types.SERVICE_SELECTED,
    }),
    setPaymentSelected: () => ({
        type: types.PAYMENT_SELECTED,
    }),
    setService: (serviceSelected) => ({
        type: types.SERVICE,
        serviceSelected,
    }),
    updatePaymentsInfo: (multiplePaymentsInfo) => ({
        type: types.MODIFY_MULTIPLE_PAYMENTS_INFO,
        multiplePaymentsInfo,
    }),
    prepareMultiplePayments: (preparePayments, account, formikBag) => ({
        type: types.PREPARE_MULTIPLE_PAYMENTS_REQUEST,
        preparePayments,
        account,
        formikBag,
    }),
    setMultiplePaymentsAccount: (multiplePaymentsAccount) => ({
        type: types.SET_MULTIPLE_PAYMENTS_ACCOUNT,
        multiplePaymentsAccount,
    }),
    submitMultiplePayments: (submitPayments, formikBag) => ({
        type: types.SUBMIT_MULTIPLE_PAYMENTS_REQUEST,
        submitPayments,
        formikBag,
    }),
    cleanHandleBackFromTicket: () => ({
        type: types.CLEAN_BACK_TICKET,
    }),
    cleanSummary: () => ({
        type: types.CLEAN_SUMMARY_INFO,
    }),
};

export const selectors = {
    getRubros: ({ servicePayments }) => servicePayments.rubros,
    getFetching: ({ servicePayments }) => servicePayments.fetching,
    getEntities: ({ servicePayments }) => servicePayments.entities,
    getPaymentsConcept: ({ servicePayments }) => servicePayments.paymentsConcept,
    getIsSubmittingConcepts: ({ servicePayments }) => servicePayments.isSubmittingConcepts,
    getSelectedConcept: ({ servicePayments }) => servicePayments.selectedConcept,
    getStatusCPE: ({ servicePayments }) => servicePayments.statusCPE,
    getSummaryInfo: ({ servicePayments }) => servicePayments.summaryInfo,
    getSummary: ({ servicePayments }) => servicePayments.summary,
    getAdhesiones: ({ servicePayments }) => servicePayments.adhesiones,
    getSubmitting: ({ servicePayments }) => servicePayments.isSubmitting,
    getAgendaPagos: ({ servicePayments }) => servicePayments.agendaPagos,
    getComponetState: ({ servicePayments }) => servicePayments.componentState,
    getComponentValues: ({ servicePayments }) => servicePayments.componentValues,
    getServiceSelected: ({ servicePayments }) => servicePayments.serviceSelected,
    getMultiplePaymentsInfo: ({ servicePayments }) => servicePayments.multiplePaymentsInfo,
    getFlatPayments: ({ servicePayments }) => servicePayments.flatPayments,
    getMultiplePaymentsAccount: ({ servicePayments }) => servicePayments.multiplePaymentsAccount,
    getBackFromTicket: ({ servicePayments }) => servicePayments.backFromTicket,
};
