import { store } from "../store";

const availableLanguages = ["en", "es", "pt"];

export const replaceParams = (message, params) =>
    Object.keys(params).reduce((str, key) => {
        const regex = new RegExp(`[#$]{${key}}`, "gi");
        return str.replace(regex, params[key]);
    }, message);

export const get = (id, defaultValue = null, params = {}) => {
    const { items } = store.getState().i18n;
    const finalId = id ? id.trim() : undefined;
    if (items && typeof items[finalId] !== "undefined") {
        return replaceParams(items[finalId], params);
    }

    return defaultValue !== null ? defaultValue : `*${finalId}*`;
};

export const getArray = (id, defaultValue = null, params = {}) => get(id, defaultValue, params).split("|");

export const getLang = () => store.getState().i18n.lang;

export const deviceLanguage = () => {
    const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

    return availableLanguages.find((item) => language.indexOf(item) > -1);
};
