import React, { Component } from "react";
import { connect } from "react-redux";
import Heading from "pages/_components/Heading";
import Col from "react-bootstrap/lib/Col";
import { selectors as accountsSelectors } from "reducers/accounts";
import { selectors as debinSelectors } from "reducers/debin";
import * as i18n from "util/i18n";
import { shape, arrayOf, bool } from "prop-types";
import { getIncomingDebinAccountName } from "util/accounts";
import { parseFloatToAmountFormat } from "util/number";

class CreateCredinSellerSummary extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        seller: shape({}).isRequired,
        accounts: arrayOf(shape({})).isRequired,
    };

    getDataAccount = () => {
        const { seller, accounts } = this.props;
        const { account } = seller;
        const selectedAccount = accounts.find((a) => a.idProduct === account);

        if (selectedAccount) {
            return `${getIncomingDebinAccountName(selectedAccount)}\nDisponible ${i18n.get(
                `currency.label.${selectedAccount.currency}`,
            )} ${parseFloatToAmountFormat(selectedAccount.balance)}`;
        }
        return "-";
    };

    render() {
        const { isDesktop, seller } = this.props;
        if (!seller) {
            return null;
        }
        const { concept, description } = seller;

        return (
            <>
                <Col lg={3} md={6} sm={12}>
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${
                            isDesktop ? "f-dir-col" : "space-between debin-detail"
                        }`}
                        label="requestCredin.summary.debitAccount.label"
                        data={this.getDataAccount()}
                        dataClassName="data-desc white-space-preline text-right"
                    />
                </Col>

                <Col lg={3} md={6} sm={12}>
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${
                            isDesktop ? "f-dir-col" : "space-between debin-detail"
                        }`}
                        label="requestCredin.summary.concept.label"
                        data={i18n.get(`transfers.concept.${concept}`)}
                    />
                </Col>

                <Col lg={3} md={6} sm={12}>
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${
                            isDesktop ? "f-dir-col" : "space-between debin-detail"
                        }`}
                        label="requestCredin.summary.description.label"
                        data={description || "-"}
                        dataClassName="data-desc break"
                    />
                </Col>

                <Col lg={3} md={6} sm={12}>
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-0 ${
                            isDesktop ? "f-dir-col" : "space-between debin-detail"
                        }`}
                        label="requestCredin.summary.expiration.label"
                        data="-"
                    />
                </Col>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: debinSelectors.getDebinSeller(state),
    accounts: accountsSelectors.getAccounts(state),
});

export default connect(mapStateToProps)(CreateCredinSellerSummary);
