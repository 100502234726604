import React, { Component } from "react";
import { func, bool, arrayOf, shape, string, number } from "prop-types";
import { connect } from "react-redux";

import { selectors as transactionLinesSelectors } from "reducers/form/transactionLines";
import { selectors as multilineFileSelectors } from "reducers/formFields/multilineFile";
// import { actions as thirdPaymentActions } from "reducers/thirdPayment";

import PaymentLines from "pages/forms/PaymentLines";
import { listItemProps } from "pages/forms/_components/_fields/_transactionlines/ListItem";

class FormPaymentLines extends Component {
    static propTypes = {
        transactionLines: arrayOf(shape(listItemProps)),
        isConfirmation: bool,
        paymentCurrency: string.isRequired,
        totalLines: number.isRequired,
        isFetching: bool.isRequired,
        dispatch: func.isRequired,
    };

    static defaultProps = {
        transactionLines: [],
        isConfirmation: false,
    };

    handleDownloadClick = () => {
        // const { transactionLines, paymentCurrency, dispatch } = this.props;
        // dispatch(thirdPaymentActions.downloadTransactionLines("pdf", transactionLines));
    };

    render() {
        const { transactionLines, paymentCurrency, isFetching } = this.props;
        const totalAmount = transactionLines.reduce(
            (result, line) => ({ currency: result.currency, quantity: result.quantity + line.creditAmountQuantity }),
            {
                quantity: 0,
                currency: paymentCurrency,
            },
        );
        return (
            <PaymentLines
                totalAmount={totalAmount}
                totalLines={transactionLines.length}
                isFetching={isFetching}
                onDownloadClick={this.handleDownloadClick}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    idFile: multilineFileSelectors.getProcessedFileData(state).idFile,
    paymentCurrency: transactionLinesSelectors.getCurrency(state),
    isFetching: transactionLinesSelectors.isFetching(state),
    transactionLines: transactionLinesSelectors.getEditedLines(state),
    isEditingPayment: transactionLinesSelectors.isEditingPayment(state),
});

export default connect(mapStateToProps)(FormPaymentLines);
