/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import FormattedAmount from "pages/_components/FormattedAmount";
import Table from "pages/_components/Table";
import * as i18n from "util/i18n";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";
import classNames from "classnames";

class SelectedAccountsAmount extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        list: shape([]).isRequired,
        summaryView: bool,
        summaryViewMoreStyles: shape({}),
        withoutTables: bool,
        leftOperations: bool,
        className: string,
        currency: string,
        withGrid: bool,
        selectedAccount: shape({}),
        setErrors: func,
        setTouched: func,
        spaceBetween: bool,
    };

    static defaultProps = {
        summaryView: false,
        summaryViewMoreStyles: {},
        withoutTables: false,
        leftOperations: false,
        className: "",
        currency: null,
        withGrid: false,
        selectedAccount: null,
        setErrors: null,
        setTouched: null,
        spaceBetween: false,
    };

    state = {
        total: 0,
        account: null,
    };

    componentDidMount() {
        const { list } = this.props;
        let totalAmount = 0;

        list.forEach((object) => {
            const amount = object?.amount?.quantity || object.monto;
            totalAmount += parseFloat(amount, 10) > 0 && parseFloat(amount, 10);
        });
        this.setState({
            total: totalAmount,
        });
    }

    componentDidUpdate() {
        const { list, selectedAccount, setErrors, setTouched } = this.props;
        const { total, account } = this.state;
        let totalAmount = 0;

        list.forEach((object) => {
            const amount = object?.amount?.quantity || object.monto;
            totalAmount += parseFloat(amount, 10) > 0 && parseFloat(amount, 10);
        });

        if (total !== totalAmount || account !== selectedAccount) {
            this.setState({
                total: totalAmount,
                account: selectedAccount,
            });
            if (selectedAccount && totalAmount > selectedAccount.balance) {
                setTouched({ account: true });
                setErrors({ account: i18n.get("services.multiplePayment.field.account.amountExceeded") });
            } else if (setErrors) {
                setErrors({});
            }
        }
    }

    render() {
        const {
            list,
            isDesktop,
            summaryView,
            summaryViewMoreStyles,
            leftOperations,
            currency,
            className,
            withoutTables,
            withGrid,
            spaceBetween,
        } = this.props;
        const { total } = this.state;

        const operations = i18n.get("transactions.selected.operations").concat(" ", list.length);

        if (summaryView) {
            return (
                <div style={{ ...summaryViewMoreStyles }} className={className}>
                    <div className="d-flex align-items-center">
                        <div>
                            <span className="acoount-header-detail-value-font p-0 text-14  bold-font mb-0">
                                {i18n.get("transactions.selected.total.amount").toUpperCase()}
                            </span>
                        </div>
                        <FormattedAmount
                            currency={currency || "0"}
                            quantity={total}
                            fontClassName="acoount-header-detail-value-font text-14 bold-font m-0 p-0 pl-1"
                        />
                    </div>

                    <div
                        className={classNames("d=grid", {
                            "text-left": leftOperations,
                            "text-right": !leftOperations,
                        })}>
                        <div className="data-label m-0 text-12 text-capitalize">{operations}</div>
                    </div>
                </div>
            );
        }

        if (withoutTables || spaceBetween) {
            return (
                <>
                    <div className={classNames({ "space-between d-flex w-100": spaceBetween })}>
                        <span
                            className={classNames("acoount-header-detail-value-font no-padding small-font  bold-font", {
                                "small-margin-bottom": !isDesktop,
                            })}>
                            {i18n.get("transactions.selected.total.amount").toUpperCase()}
                        </span>
                        <FormattedAmount
                            className={classNames("acoount-header-detail-value-font small-font bold-font-important", {
                                "small-margin-bottom pl-0": !isDesktop,
                            })}
                            currency={currency || "0"}
                            quantity={total}
                        />
                    </div>

                    <div className="data-label m-0 f-size-55 text-capitalize">
                        {i18n.get("transactions.selected.operations").concat(" ", list.length)}
                    </div>
                </>
            );
        }

        if (withGrid) {
            return (
                <Container className="mt-2">
                    <Col xs={3} className="text-align-center">
                        <span
                            className={`acoount-header-detail-value-font no-padding small-font  bold-font ${
                                !isDesktop ? "small-margin-bottom" : ""
                            }`}>
                            {i18n.get("transactions.selected.total.amount").toUpperCase()}
                        </span>

                        <div className="data-label m-0 f-size-55">{operations}</div>
                    </Col>
                    <Col xs={5}>
                        <FormattedAmount
                            className={`acoount-header-detail-value-font small-font bold-font-important ${
                                !isDesktop ? "small-margin-bottom" : ""
                            }`}
                            currency={currency || "0"}
                            quantity={total}
                        />
                    </Col>
                </Container>
            );
        }

        return (
            <>
                <Table.Data />
                <Table.Data align="center">
                    <span
                        className={`acoount-header-detail-value-font no-padding small-font  bold-font ${
                            !isDesktop ? "small-margin-bottom" : ""
                        }`}>
                        {i18n.get("transactions.selected.total.amount").toUpperCase()}
                    </span>
                    <div className="data-label m-0 f-size-55">{operations}</div>
                </Table.Data>
                <Table.Data align="left" moreStyles={{ verticalAlign: "top" }}>
                    <FormattedAmount
                        className={`acoount-header-detail-value-font mb-0 small-font bold-font-important ${
                            !isDesktop ? "small-margin-bottom" : ""
                        }`}
                        currency={currency || "0"}
                        quantity={total}
                    />
                </Table.Data>
                <Table.Data />
                <Table.Data />
            </>
        );
    }
}

export default connect()(SelectedAccountsAmount);
