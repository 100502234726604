import React, { PureComponent } from "react";
import { shape, string } from "prop-types";
import { Col } from "react-bootstrap";
import * as i18n from "util/i18n";

class ModalNotification extends PureComponent {
    static propTypes = {
        errors: shape({}).isRequired,
        formId: string.isRequired,
    };

    render() {
        const { errors, formId } = this.props;

        const container = document.getElementById("containerNotification");
        if (container) {
            container.innerHTML = "";
            Object.keys(errors).map((error) => {
                const children = document.createElement("div");
                children.innerText = `${i18n.get(`${formId}.${error}.label`)}: ${errors[error]}`;
                container.appendChild(children);
                return "";
            });
        }

        return (
            <Col className="col col-12 visually-hidden">
                <div id="containerNotification" aria-live="polite" />
            </Col>
        );
    }
}

export default ModalNotification;
