import React, { Component } from "react";
import { func } from "prop-types";

import Button from "pages/_components/Button";
import { connect } from "react-redux";
import { actions as sessionActions } from "reducers/session";
import { isDesktop } from "react-device-detect";

class ModalPrimaryAction extends Component {
    static propTypes = {
        handleClick: func.isRequired,
        dispatch: func.isRequired,
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    render() {
        const { handleClick } = this.props;

        return (
            <div className={isDesktop ? "d-flex w-100" : "d-flex f-dir-col-reverse"}>
                <Button
                    className={isDesktop ? "mb-0 mr-4" : "mb-0"}
                    bsStyle="outline"
                    onClick={this.logOut}
                    label="global.logout"
                />
                <Button label="session.extend" bsStyle="primary" onClick={handleClick} />
            </div>
        );
    }
}

export default connect()(ModalPrimaryAction);
