/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { arrayOf, bool, objectOf, shape, string } from "prop-types";
import moment from "moment";
import { Field } from "formik";

import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";
import LapseSubOption from "pages/forms/_components/_scheduler/LapseSubOption";
import { DateField } from "pages/_components/fields/DateField";

class CommonFrequencySubOption extends Component {
    static propTypes = {
        enabledWeekDays: arrayOf(bool),
        errors: objectOf(string),
        nonWorkingDays: arrayOf(string),
        lapse: shape({
            day: string,
        }).isRequired,
        frequency: string.isRequired,
        startsOn: string.isRequired,
        isDesktop: bool,
        FORM_ID: string,
        disabledWeekends: bool,
    };

    static defaultProps = {
        errors: null,
        isDesktop: false,
        enabledWeekDays: [],
        nonWorkingDays: [],
        FORM_ID: "scheduler",
        disabledWeekends: false,
    };

    obtainMaxDate = () => {
        const { frequency, lapse } = this.props;
        const withLimits = [schedulerUtils.DAY, schedulerUtils.WEEK, schedulerUtils.MONTH];
        const hasLapse = lapse?.lapse === "UNTIL" && withLimits.includes(frequency);
        return hasLapse ? lapse?.date : null;
    };

    render() {
        const {
            frequency,
            lapse,
            startsOn,
            enabledWeekDays,
            nonWorkingDays,
            errors,
            isDesktop,
            FORM_ID,
            disabledWeekends,
        } = this.props;

        return (
            <>
                {frequency !== schedulerUtils.TODAY && (
                    <>
                        <div className="w-100">
                            {disabledWeekends ? (
                                <Field
                                    component={DateField}
                                    idForm={FORM_ID}
                                    name="startsOn"
                                    minDate={moment(startsOn)}
                                    maxDate={this.obtainMaxDate()}
                                    dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18nUtils.getLang())}
                                    idField="startsOn.date"
                                    filterDate={(date) => dateUtils.filterForAvailableDays(nonWorkingDays, date)}
                                />
                            ) : (
                                <Field
                                    component={DateField}
                                    idForm={FORM_ID}
                                    name="startsOn"
                                    minDate={moment(startsOn)}
                                    maxDate={this.obtainMaxDate()}
                                    dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18nUtils.getLang())}
                                    idField="startsOn.date"
                                    excludeDates={dateUtils.excludeDates(nonWorkingDays, dateUtils.DAY_MONTH_YEAR)}
                                />
                            )}
                        </div>

                        <div className="w-100">
                            {frequency !== schedulerUtils.ONCE && (
                                <div className="scheduler-frequency-suboptions">
                                    <Field
                                        name="lapse"
                                        component={LapseSubOption}
                                        enabledWeekDays={enabledWeekDays}
                                        errors={errors}
                                        firstWorkingDate={startsOn}
                                        nonWorkingDays={nonWorkingDays}
                                        value={lapse}
                                        frequency={frequency}
                                        isDesktop={isDesktop}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </>
        );
    }
}

export default CommonFrequencySubOption;
