import { call, put, takeLatest } from "redux-saga/effects";
import { types } from "reducers/holidays";
import listHolidays from "middleware/holidays";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

const sagas = [takeLatest(types.LIST_HOLIDAYS_REQUEST, getHolidays)];

export default sagas;

function* getHolidays({ summary, format }) {
    const { type, data } = yield call(listHolidays, summary, format);

    if (type === "W") {
        yield put({ type: types.LIST_HOLIDAYS_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    } else {
        const { holidays } = data.data;

        yield put({ type: types.LIST_HOLIDAYS_SUCCESS, holidays });
    }
}
