/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import { selectors as transactionsSelectors } from "reducers/transactions";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import * as i18n from "util/i18n";

class EcheqAcceptReturnDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const echeq = object.transaction.data.echeqList[0];
            return (
                <>
                    <CardContainer className="confirmation__cards-container mt-0">
                        <Card
                            className="confirmation__cards"
                            icon={isDesktop && "images/transfer-PENDING.svg"}
                            iconClass="card__pending-transaction-icon"
                            title={
                                isDesktop ? (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get("table.emitCesion.date")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <FormattedDate date={echeq.fecha_pago} dateFormat="dd/MM/yyyy" withSpan />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get("table.echeq.beneficiary.cuit")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>{echeq.cuit_solic_devol}</span>
                                        </div>
                                    </div>
                                )
                            }
                            content={
                                isDesktop ? (
                                    <div className="d-flex align-items-center">
                                        <div className="confirmation-cards__content pb-1">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.echeq.beneficiary.cuit")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{echeq.cuit_solic_devol}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.type")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{echeq.cheque_tipo}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content info-type-b">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.motive")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    {echeq.cheque_motivo_pago}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.date")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <FormattedDate
                                                    date={echeq.fecha_pago}
                                                    dateFormat="dd/MM/yyyy"
                                                    withSpan
                                                />
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.type")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{echeq.cheque_tipo}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.motive")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{echeq.cheque_motivo_pago}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.acceptReturn.solicitante")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{object.transaction.data.solicitantName}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.echeq")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{echeq.cheque_numero}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            bottom={
                                isDesktop && (
                                    <div className="d-flex align-items-center">
                                        <div
                                            className={
                                                echeq.cheque_numero
                                                    ? "confirmation-cards__bottom confirmation-cards-separator"
                                                    : "confirmation-cards__bottom"
                                            }>
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get("table.acceptReturn.solicitante")}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{object.transaction.data.solicitantName}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__bottom">
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get("table.emitCesion.echeq")}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{echeq.cheque_numero}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            rightContent={
                                <div className="confirmation-cards__right">
                                    <div className="confirmation-cards__right-data">
                                        <FormattedAmount currency="0" quantity={echeq.monto} />
                                    </div>
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        });
    };

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(EcheqAcceptReturnDataTable);
