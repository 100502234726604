import { takeLatest, call, put } from "redux-saga/effects";

import { routerActions } from "react-router-redux";

import { types } from "reducers/recoveryPassword";
import { types as typesToken } from "reducers/token";
import { actions as notificationActions } from "reducers/notification";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18nUtils from "util/i18n";
import * as recovery from "middleware/recoveryPassword";
import { actions as loginActions } from "reducers/login";
import { types as fingerprintTypes } from "reducers/fingerprint";
import * as deviceUtils from "util/device";
import * as secureStorageUtils from "util/secureStorage";
import * as configUtils from "util/config";

const sagas = [
    takeLatest(types.RECOVERY_PASS_BACK_TO_STEP_1, handleGoToStep1),
    takeLatest(types.RECOVERY_PASS_PREVIEW_REQUEST, handleRecoveryPassPreview),
    takeLatest(types.RECOVERY_PASS_STEP1_REQUEST, handleRecoveryPassStep1),
    takeLatest(types.RECOVERY_PASS_STEP2_REQUEST, handleRecoveryPassStep2),
    takeLatest(types.RECOVERY_PASS_STEP3_REQUEST, handleRecoveryPassStep3),
    takeLatest(types.RECOVERY_USERPASS_STEP0_REQUEST, handleRecoveryUserpassStep0),
    takeLatest(types.RECOVERY_USERPASS_STEP1_REQUEST, handleRecoveryUserpassStep1),
    takeLatest(types.RECOVERY_USERPASS_STEP2_REQUEST, handleRecoveryUserpassStep2),
    takeLatest(types.RECOVERY_USERPASS_STEP3_REQUEST, handleRecoveryUserpassStep3),
    takeLatest(types.RECOVERY_USERPASS_BACK_TO_STEP_1, handleGoToUserpassStep1),
    takeLatest(types.RECOVERY_PASS_USERPASS_STEP1_REQUEST, handleRecoveryPassAndUserpassStep1),
    takeLatest(types.RECOVERY_PASS_USERPASS_STEP2_REQUEST, handleRecoveryPassAndUserpassStep2),
    takeLatest(types.RECOVERY_PASS_USERPASS_STEP3_REQUEST, handleRecoveryPassAndUserpassStep3),
];

export default sagas;

function* handleGoToStep1() {
    yield put(routerActions.push("/recoveryPassword/step1"));
}

function* handleRecoveryPassStep1({ sendChannel, userName, secondFactor, formikBag, exchangeToken }) {
    const { isMobileNative } = formikBag.props;
    let idDevice = null;
    if (window.app) {
        idDevice = window.app.getDeviceUUID();
    } else {
        idDevice = yield deviceUtils.getDeviceFingerprint();
    }
    const response = yield call(
        recovery.recoveryPassStep1,
        sendChannel,
        userName,
        secondFactor,
        exchangeToken,
        idDevice,
    );

    if (
        response.type === "W" ||
        (response.data.data._result !== null &&
            response.data.data._result !== undefined &&
            response.data.data._result !== "VALIDO")
    ) {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (response.data.data._result === "INVALIDO") {
            formikBag.setErrors(
                adjustIdFieldErrors({ secondFactor: i18nUtils.get("settings.token.password.invalid") }),
            );
        } else if (response.data.data._result === "DISABLED") {
            yield put({
                type: typesToken.CHECK_USER_ACTIVE_TOKEN_SUCCESS,
                webTokenStatus: "blocked",
            });
            yield put(routerActions.push("/settings/token/recoverPass"));
        } else if (response.data.code === "API021W" || response.data.code === "COR050W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put({ type: types.RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA });
        } else if (!isMobileNative) {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
        }
    } else {
        const { _exchangeToken } = response.data.data;
        yield put({ type: types.RECOVERY_PASS_STEP1_SUCCESS, exchangeToken: _exchangeToken });
        yield put(routerActions.push("/recoveryPassword/step2"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryPassStep2({ code, formikBag, exchangeToken }) {
    const response = yield call(recovery.recoveryPassStep2, code, exchangeToken);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (response.data.code !== "COR020W") {
            // exchangeToken expired, restart flow
            yield put({ type: types.CLEAN });
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put(routerActions.push("/recoveryPassword/step2"));
        }
    } else {
        const { _exchangeToken, _resetCode } = response.data.data;
        yield put({ type: types.RECOVERY_PASS_STEP2_SUCCESS, exchangeToken: _exchangeToken, resetCode: _resetCode });
        yield put(routerActions.push("/recoveryPassword/step3"));
    }
    formikBag.setSubmitting(false);
}

function* handleRecoveryPassStep3({ newPassword, newPasswordConfirmation, resetCode, exchangeToken, formikBag }) {
    const response = yield call(
        recovery.recoveryPassStep3,
        newPassword,
        newPasswordConfirmation,
        resetCode,
        exchangeToken,
    );

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (response.data.code !== "COR020W") {
            // exchangeToken expired, restart flow
            yield put({ type: types.CLEAN });
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put(routerActions.push("/recoveryPassword/step2"));
        }
    } else {
        yield put(
            notificationActions.showNotification(i18nUtils.get("recoveryPassword.success.message"), "success", [
                "login",
            ]),
        );
        yield put(routerActions.push("/"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryUserpassStep0({
    password,
    document,
    documentType,
    formikBag,
    isRecoveryPassAndUserpass,
    captcha,
}) {
    try {
        const response = yield call(
            recovery.recoveryUserpassStep0,
            password,
            document,
            documentType,
            isRecoveryPassAndUserpass,
            captcha,
        );
        if (response.data.data.showCaptcha) {
            yield put({ type: types.RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA });

            formikBag.setValues({
                documentType: documentType || "DNI",
                document: document || "",
                password: "",
                captcha: "",
            });
        }
        const { error } = response.data.data;
        if (error) {
            if (error === "login.credencialesbloqueadas.snackbar") {
                yield put(
                    notificationActions.showNotification(
                        configUtils.get("login.credencialesbloqueadas.snackbar"),
                        "error",
                        ["recoveryUserpass"],
                        i18nUtils.get("login.step2.password.blocked.tittle"),
                        false,
                    ),
                );
            } else if (error === "login.step1.blocked.disable.document" || error === "login.step1.blocked.document") {
                yield put(
                    notificationActions.showNotification(
                        i18nUtils.get(error),
                        "error",
                        ["recoveryUserpass"],
                        i18nUtils.get(`${error}.title`),
                        false,
                    ),
                );
            } else if (error === "login.step1.captcha.required") {
                formikBag.setErrors({ captcha: i18nUtils.get(error) });
            } else {
                formikBag.setErrors({ password: i18nUtils.get(error) });
            }
            formikBag.setValues({
                documentType,
                document: document || "",
                password: "",
                captcha: "",
            });
        } else {
            const {
                _userFirstName,
                _userFullName,
                lang,
                _userMaskedEmail,
                _userMaskedPhoneNumber,
            } = response.data.data;

            yield put({
                type: types.RECOVERY_USERPASS_STEP0_SUCCESS,
                userFirstName: _userFirstName,
                userFullName: _userFullName,
                userName: documentType + document,
                lang,
                userMaskedEmail: _userMaskedEmail,
                userMaskedPhone: _userMaskedPhoneNumber,
            });
            yield put(routerActions.push("/recoveryUserpass/step1"));
        }
    } finally {
        yield put({ type: types.RECOVERY_PASS_RESET_CAPTCHA, resetCaptcha: true });
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
    }
}

function* handleRecoveryUserpassStep1({ sendChannel, userName, secondFactor, formikBag, isRecoveryPassAndUserpass }) {
    const { isMobileNative } = formikBag.props;
    let idDevice = null;
    if (window.app) {
        idDevice = window.app.getDeviceUUID();
    } else {
        idDevice = yield deviceUtils.getDeviceFingerprint();
    }
    const response = yield call(
        recovery.recoveryUserpassStep1,
        sendChannel,
        userName,
        secondFactor,
        isRecoveryPassAndUserpass,
        idDevice,
    );

    if (
        response.type === "W" ||
        (response.data.data._result !== null &&
            response.data.data._result !== undefined &&
            response.data.data._result !== "VALIDO")
    ) {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        if (response.data.data._result === "INVALIDO") {
            formikBag.setErrors(
                adjustIdFieldErrors({ secondFactor: i18nUtils.get("settings.token.password.invalid") }),
            );
        } else if (response.data.data._result === "DISABLED") {
            yield put({
                type: typesToken.CHECK_USER_ACTIVE_TOKEN_SUCCESS,
                webTokenStatus: "blocked",
            });
            yield put(routerActions.push("/settings/token/recoverPass"));
        } else if (response.data.code === "API021W" || response.data.code === "COR050W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put({ type: types.RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA });
        } else if (!isMobileNative) {
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
        }
    } else {
        yield put(routerActions.push("/recoveryUserpass/step2"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryUserpassStep2({ code, formikBag }) {
    const response = yield call(recovery.recoveryUserpassStep2, code);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (response.data.code !== "COR020W") {
            // exchangeToken expired, restart flow
            yield put({ type: types.CLEAN });
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put(routerActions.push("/recoveryUserpass/step2"));
        }
    } else {
        const { _exchangeToken, _resetCode } = response.data.data;

        yield put({
            type: types.RECOVERY_USERPASS_STEP2_SUCCESS,
            exchangeToken: _exchangeToken,
            resetCode: _resetCode,
        });
        yield put(routerActions.push("/recoveryUserpass/step3"));
    }
    formikBag.setSubmitting(false);
}

function* handleRecoveryUserpassStep3({ newPassword, newPasswordConfirmation, resetCode, exchangeToken, formikBag }) {
    let deviceToken = "";
    let fingerprintAuthToken = null;
    let fingerprintAuthTokenExists = true;

    try {
        fingerprintAuthToken = yield call(secureStorageUtils.get, "fingerprintAuthToken");
    } catch (error) {
        fingerprintAuthTokenExists = false;
    }

    if (fingerprintAuthTokenExists) {
        deviceToken = fingerprintAuthToken;
    }

    const response = yield call(
        recovery.recoveryUserpassStep3,
        newPassword,
        newPasswordConfirmation,
        resetCode,
        exchangeToken,
        deviceToken,
    );

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors({ password: response.data.data.pin }));
        if (response.data.code !== "COR020W") {
            // exchangeToken expired, restart flow
            yield put({ type: types.CLEAN });
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put(routerActions.push("/recoveryUserpass/step2"));
        }
    } else {
        if (fingerprintAuthTokenExists) {
            yield put({ type: fingerprintTypes.CLEAN_UP });
            yield put(loginActions.removeRememberedUser());
            yield put(loginActions.removeBiometricLoginShow());
            yield call(secureStorageUtils.remove, "showLogin");
            yield call(secureStorageUtils.remove, "documentType");
            yield call(secureStorageUtils.remove, "userFullName");
            yield call(secureStorageUtils.remove, "userFirstName");
        }

        yield put({ type: types.RECOVERY_PASS_SUCCESS });
        yield put(
            notificationActions.showNotification(i18nUtils.get("recoveryUserpass.success.message"), "success", [
                "login",
            ]),
        );

        yield put(routerActions.push("/"));
    }

    formikBag.setSubmitting(false);
}

function* handleGoToUserpassStep1() {
    yield put(routerActions.push("/recoveryUserpass/step1"));
}

function* handleRecoveryPassAndUserpassStep1({ code, formikBag }) {
    const response = yield call(recovery.recoveryPassAndUserpassStep1, code);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (response.data.code !== "COR020W") {
            // exchangeToken expired, restart flow
            yield put({ type: types.CLEAN });
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put(routerActions.push("/recoveryUserpass/step2"));
        }
    } else {
        const { _exchangeToken, _resetCode } = response.data.data;

        yield put({
            type: types.RECOVERY_PASS_USERPASS_STEP1_SUCCESS,
            exchangeToken: _exchangeToken,
            resetCode: _resetCode,
        });

        yield put(routerActions.push("/recoveryPassAndUserpass/step2"));
    }
    formikBag.setSubmitting(false);
}

function* handleRecoveryPassAndUserpassStep2({ newPin, newPinConfirmation, resetCode, exchangeToken, formikBag }) {
    const response = yield call(
        recovery.recoveryPassAndUserpassStep2,
        newPin,
        newPinConfirmation,
        resetCode,
        exchangeToken,
    );

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (response.data.code !== "COR020W") {
            // exchangeToken expired, restart flow
            yield put({ type: types.CLEAN });
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put(routerActions.push("/recoveryPassAndUserpass/step2"));
        }
    } else {
        const { _exchangeToken } = response.data.data;

        yield put({
            type: types.RECOVERY_PASS_USERPASS_STEP2_SUCCESS,
            exchangeToken: _exchangeToken,
        });
        yield put(routerActions.push("/recoveryPassAndUserpass/step3"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryPassAndUserpassStep3({
    newPassword,
    newPasswordConfirmation,
    resetCode,
    exchangeToken,
    formikBag,
}) {
    let deviceToken = "";
    let fingerprintAuthToken = null;
    let fingerprintAuthTokenExists = true;

    try {
        fingerprintAuthToken = yield call(secureStorageUtils.get, "fingerprintAuthToken");
    } catch (error) {
        fingerprintAuthTokenExists = false;
    }

    if (fingerprintAuthTokenExists) {
        deviceToken = fingerprintAuthToken;
    }

    const response = yield call(
        recovery.recoveryPassAndUserpassStep3,
        newPassword,
        newPasswordConfirmation,
        resetCode,
        exchangeToken,
        deviceToken,
    );

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (response.data.code !== "COR020W") {
            // exchangeToken expired, restart flow
            yield put({ type: types.CLEAN });
            yield put(notificationActions.showNotification(response.data.message, "error", ["recoveryPassword"]));
            yield put(routerActions.push("/recoveryPassAndUserpass/step2"));
        }
    } else {
        yield put({ type: types.RECOVERY_PASS_SUCCESS });
        yield put(
            notificationActions.showNotification(i18nUtils.get("recoverPinAndPassword.success.message"), "success", [
                "login",
            ]),
        );

        if (fingerprintAuthTokenExists) {
            yield put({ type: fingerprintTypes.CLEAN_UP });
            yield put(loginActions.removeRememberedUser());
            yield put(loginActions.removeBiometricLoginShow());
            yield call(secureStorageUtils.remove, "showLogin");
            yield call(secureStorageUtils.remove, "documentType");
            yield call(secureStorageUtils.remove, "userFullName");
            yield call(secureStorageUtils.remove, "userFirstName");
        }

        yield put(routerActions.push("/"));
    }

    formikBag.setSubmitting(false);
}

function* handleRecoveryPassPreview({ username, exchangeToken }) {
    const response = yield call(recovery.recoveryPassPreview, username, exchangeToken);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["loginStep2"]));
    } else {
        const { _exchangeToken } = response.data.data;
        yield put({ type: types.RECOVERY_PASS_PREVIEW_SUCCESS, exchangeToken: _exchangeToken });
        yield put(routerActions.push("/recoveryPassword/step1"));
    }
}
