import React, { Component } from "react";
import { connect } from "react-redux";
import { string, shape, func, bool } from "prop-types";
import { Link } from "react-router-dom";
import { Collapse as BSCollapse } from "react-bootstrap";
import FormattedAmount from "pages/_components/FormattedAmount";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

class ClientData extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        referenceColor: string,
        clientData: shape({}).isRequired,
        environments: shape({}).isRequired,
    };

    static defaultProps = {
        referenceColor: "",
    };

    state = {
        open: false,
    };

    handleCollapse = () => {
        const { open } = this.state;

        this.setState({ open: !open });
    };

    handleChangeEnvironment = (idEnvironment) => {
        const { dispatch } = this.props;

        dispatch(sessionActions.changeEnvironment(idEnvironment, false, null));
    };

    renderSelectEnvironmentControl = (clientData, environmentIds) =>
        clientData.canLogin &&
        environmentIds.includes(clientData.idEnvironment.toString()) && (
            <button
                onClick={() => {
                    this.handleChangeEnvironment(clientData.idEnvironment);
                }}
                className="btn btn-link"
                type="button">
                {i18n.get("desktop.corporateGroup.clientData.selectEnvironment")}
            </button>
        );

    render() {
        const { isDesktop, referenceColor, clientData, environments } = this.props;
        const { open } = this.state;
        const { accounts } = clientData;
        const environmentIds = Object.keys(environments);

        return (
            <div className="table-row">
                <div
                    className="Product economic-group color-reference"
                    style={{
                        borderColor: referenceColor,
                    }}>
                    <div className="Product-row">
                        <div className="data-wrapper">
                            <p className="data-name">{clientData.name}</p>
                            <p className="data-text">
                                {` ${accounts.length} ${i18n.get("desktop.corporateGroup.clientData.products")}`}{" "}
                            </p>
                            {isDesktop && this.renderSelectEnvironmentControl(clientData, environmentIds)}
                        </div>
                    </div>

                    <div className="Product-row text-right">
                        <p className="data-label">
                            <I18n id="desktop.corporateGroup.clientData.total" />
                        </p>
                        <FormattedAmount
                            quantity={clientData.total}
                            currency={configUtils.get("core.masterCurrency")}
                        />
                    </div>

                    {!isDesktop && (
                        <div className="Product-row">
                            {this.renderSelectEnvironmentControl(clientData, environmentIds)}
                        </div>
                    )}
                    {isDesktop && accounts.length > 0 && (
                        <div className="Product-row-action">
                            <Button
                                bsStyle="outline"
                                image={open ? "images/minus.svg" : "images/plus.svg"}
                                className="btn-circle-bg"
                                onClick={this.handleCollapse}
                            />
                        </div>
                    )}
                </div>

                <BSCollapse in={open}>
                    <div>
                        <AccountsTable accounts={accounts} />
                    </div>
                </BSCollapse>
            </div>
        );
    }
}

const AccountsTable = (data) =>
    data.accounts.length > 0 && (
        <div className="table">
            <div className="table-head">
                <div className="table-row">
                    <div className="table-data table-data-head">
                        <I18n id="desktop.corporateGroup.clientData.account" />
                    </div>
                    <div className="table-data table-data-head text-right">
                        <I18n id="desktop.corporateGroup.clientData.availableBalance" />
                    </div>
                    <div className="table-data table-data-head text-right">
                        <I18n id="desktop.corporateGroup.clientData.bookBalance" />
                    </div>
                    <div className="table-data table-data-head" />
                </div>
            </div>
            <div className="table-body">
                {data.accounts.map((account) => (
                    <AccountData key={account.idProduct} account={account} />
                ))}
            </div>
        </div>
    );

const AccountData = (data) => (
    <Link className="table-row" to={`/accounts/${data.account.idProduct}`}>
        <div className="table-data">
            <div className="data-text">{data.account.shortLabel}</div>
        </div>
        <div className="table-data text-right">
            <FormattedAmount currency={data.account.currency} quantity={data.account.balance} />
        </div>
        <div className="table-data text-right">
            <FormattedAmount currency={data.account.currency} quantity={data.account.countableBalance} />
        </div>
        <ChevromRight />
    </Link>
);

const mapStateToProps = (state) => ({
    environments: sessionSelectors.getEnvironments(state),
});

export default connect(mapStateToProps)(resizableRoute(ClientData));
