import React, { Component } from "react";
import { bool, shape, arrayOf, func, number, string } from "prop-types";
import { connect } from "react-redux";

import { selectors as ecomexSelectors, actions as ecomexActions } from "reducers/ecomex";

import Table from "pages/_components/Table";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Col from "react-bootstrap/lib/Col";
import FilterTableHead from "pages/transactions/FilterTableHead";
import PaginationWithPageSelector from "pages/_components/pagination/PaginationWithPageSelector";
import InfoTag from "pages/_components/InfoTag";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "pages/_components/Spinner";
import Heading from "pages/_components/Heading";

import * as i18nUtils from "util/i18n";
import classNames from "classnames";

const FORM_ID = "ecomex.informativeRegime.list";

class EcomexInformativeRegimeList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        ecomexInformativeRegime: arrayOf(shape({})).isRequired,
        filter: shape({}).isRequired,
        pageNumber: number.isRequired,
        numberPerPageOptions: arrayOf(shape({})).isRequired,
        numberPerPage: number.isRequired,
        optionSelected: string.isRequired,
        totalPages: number.isRequired,
        filtersData: shape({}).isRequired,
    };

    getInformativeRegimeDetail = (informativeRegime) => {
        const { dispatch, optionSelected } = this.props;

        dispatch(ecomexActions.getInformativeRegimeDetail(informativeRegime, optionSelected));
    };

    filterChange = (filterName, filterType) => {
        const { dispatch, ecomexInformativeRegime } = this.props;

        const filter = {
            filterSelected: filterName,
            directionFilter: filterType,
        };

        dispatch(ecomexActions.filterChangeInformativeRegime(ecomexInformativeRegime, filter));
    };

    paginateEcomexList = (pageNumber) => {
        const { dispatch, filtersData, numberPerPage } = this.props;
        const {
            optionSelected,
            ecomexState,
            operationType,
            destination,
            operationNumber,
            payDateFrom,
            payDateTo,
            dueDateFrom,
            dueDateTo,
            officialDateFrom,
            officialDateTo,
            settlementDateFrom,
            settlementDateTo,
            embDateFrom,
            embDateTo,
        } = filtersData;

        dispatch(
            ecomexActions.listEcomexInformativeRegime(
                pageNumber,
                numberPerPage,
                optionSelected,
                ecomexState,
                operationType,
                destination,
                operationNumber,
                payDateFrom,
                payDateTo,
                dueDateFrom,
                dueDateTo,
                officialDateFrom,
                officialDateTo,
                settlementDateFrom,
                settlementDateTo,
                embDateFrom,
                embDateTo,
            ),
        );
    };

    paginateEcomexListMobile = () => {
        const { dispatch, filtersData, pageNumber, numberPerPage } = this.props;
        const {
            optionSelected,
            ecomexState,
            operationType,
            destination,
            operationNumber,
            payDateFrom,
            payDateTo,
            dueDateFrom,
            dueDateTo,
            officialDateFrom,
            officialDateTo,
            settlementDateFrom,
            settlementDateTo,
            embDateFrom,
            embDateTo,
        } = filtersData;

        dispatch(
            ecomexActions.moreListEcomexInformativeRegime(
                pageNumber + 1,
                numberPerPage,
                optionSelected,
                ecomexState,
                operationType,
                destination,
                operationNumber,
                payDateFrom,
                payDateTo,
                dueDateFrom,
                dueDateTo,
                officialDateFrom,
                officialDateTo,
                settlementDateFrom,
                settlementDateTo,
                embDateFrom,
                embDateTo,
            ),
        );
    };

    changeNumberPerPage = (numberPerPage) => {
        const { dispatch, filtersData, pageNumber } = this.props;
        const {
            optionSelected,
            ecomexState,
            operationType,
            destination,
            operationNumber,
            payDateFrom,
            payDateTo,
            dueDateFrom,
            dueDateTo,
            officialDateFrom,
            officialDateTo,
            settlementDateFrom,
            settlementDateTo,
            embDateFrom,
            embDateTo,
        } = filtersData;

        dispatch(
            ecomexActions.listEcomexInformativeRegime(
                pageNumber,
                numberPerPage,
                optionSelected,
                ecomexState,
                operationType,
                destination,
                operationNumber,
                payDateFrom,
                payDateTo,
                dueDateFrom,
                dueDateTo,
                officialDateFrom,
                officialDateTo,
                settlementDateFrom,
                settlementDateTo,
                embDateFrom,
                embDateTo,
            ),
        );
    };

    renderHeaderData = (header) => {
        const { filter } = this.props;
        const { name, align, className } = header;

        return (
            <Table.HeaderData className={className}>
                <div className={`flex-table justify-content-${align}`}>
                    <I18n id={`${FORM_ID}.column.${name}`} componentProps={{ className: `text-${align}` }} />
                    <FilterTableHead filterName={name} columnFilter={filter} changeFilter={this.filterChange} />
                </div>
            </Table.HeaderData>
        );
    };

    renderListEcomexInformativeRegime = () => {
        const {
            isDesktop,
            ecomexInformativeRegime,
            pageNumber,
            numberPerPageOptions,
            numberPerPage,
            optionSelected,
            totalPages,
        } = this.props;

        if (!(ecomexInformativeRegime && ecomexInformativeRegime.length > 0)) {
            return (
                <div className="text-center no-more-data">
                    <div className="illustration-wrapper">
                        <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                    </div>
                    <p className="text-lead">
                        <I18n id={`${FORM_ID}.empty.message`} />
                    </p>
                </div>
            );
        }

        const numberPerPageOptionsToShow = numberPerPageOptions.map((numberPerPageOption) => ({
            value: numberPerPageOption,
            label: numberPerPageOption,
        }));

        let headerElements = [];
        if (optionSelected === "1") {
            headerElements = [
                { name: "referenciaOperacion", align: "left" },
                { name: "codigoConceptoBCRA", align: "left" },
                { name: "fechaPago", align: "center" },
                { name: "fechaVencimientoOriginal", align: "center" },
                { name: "siglaDivisa", align: "center" },
                { name: "importe", align: "right", classNameItem: "pr-3" },
                { name: "aplicado", align: "right", classNameItem: "pr-3" },
                { name: "saldo", align: "right", classNameItem: "pr-3" },
                { name: "estado", align: "right", className: "pr-2", classNameItem: "pr-3" },
            ];
        } else if (optionSelected === "2") {
            headerElements = [
                { name: "refOp", align: "left" },
                { name: "fechaLiquidacion", align: "center" },
                { name: "fechaPreFinanciacionOriginal", align: "center" },
                { name: "siglaDivisa", align: "center" },
                { name: "importeOperacion", align: "right", classNameItem: "pr-3" },
                { name: "tasa", align: "right", classNameItem: "pr-3" },
                { name: "tipoOperacion", align: "right", classNameItem: "pr-3" },
                { name: "estado", align: "right", className: "pr-2" },
            ];
        } else if (optionSelected === "3") {
            headerElements = [
                { name: "destinacion", align: "left" },
                { name: "fechaOficializacion", align: "right", classNameItem: "pr-3" },
                { name: "condicionVenta", align: "right", classNameItem: "pr-3" },
                { name: "siglaDivisaFOB", align: "right", classNameItem: "pr-3" },
                { name: "importeFOB", align: "right", classNameItem: "pr-3" },
                { name: "siglaDivisaFLETE", align: "right", classNameItem: "pr-3" },
                { name: "importeFLETE", align: "right", classNameItem: "pr-3" },
                { name: "siglaDivisaSEGURO", align: "right", classNameItem: "pr-3" },
                { name: "importeSEGURO", align: "right", classNameItem: "pr-3" },
                { name: "saldoTotalFOB", align: "right", className: "pr-2", classNameItem: "pr-35" },
            ];
        } else {
            headerElements = [
                { name: "destinacion", align: "left" },
                { name: "fechaCumpEmb", align: "center" },
                { name: "fechaVencimiento", align: "center" },
                { name: "fechaOficializacion", align: "center" },
                { name: "siglaDivisa", align: "center" },
                { name: "importeTotal", align: "right", classNameItem: "pr-3" },
                { name: "importeAplicado", align: "right", classNameItem: "pr-3" },
                { name: "saldoTOTAL", align: "right", classNameItem: "pr-3" },
                { name: "condicionVenta", align: "right", classNameItem: "pr-3" },
                { name: "estado", align: "right", className: "pr-2" },
            ];
        }

        const list = ecomexInformativeRegime.map((item) =>
            this.renderListEcomexInformativeRegimeItem(item, headerElements),
        );

        if (isDesktop) {
            return (
                <>
                    <Container className="flex-grow scrollable">
                        <Col md={12} className="table-with-border px-0">
                            <Table>
                                <Table.Header className="container-white">
                                    {headerElements.map((header) => this.renderHeaderData(header))}
                                </Table.Header>
                                <Table.Body>{list}</Table.Body>
                            </Table>
                        </Col>
                    </Container>
                    {totalPages > 0 && (
                        <PaginationWithPageSelector
                            totalPages={totalPages}
                            pageNumber={pageNumber}
                            action={this.paginateEcomexList}
                            showFirstAndLastButton
                            numberPerPageOptionsToShow={numberPerPageOptionsToShow}
                            changeNumberPerPage={this.changeNumberPerPage}
                            className="ecomex-pagination"
                            selectedValue={numberPerPage}
                        />
                    )}
                </>
            );
        }

        return (
            <Container className={classNames("flex-grow scrollable", { "mt-6": isDesktop })}>
                <Col md={12} className="with-table-header-margin px-0">
                    <InfiniteScroll
                        dataLength={list.length}
                        next={this.paginateEcomexListMobile}
                        hasMore={totalPages !== pageNumber}
                        loader={<Spinner />}>
                        {list}
                    </InfiniteScroll>
                </Col>
            </Container>
        );
    };

    renderListEcomexInformativeRegimeItem = (item, headerElements) => {
        const { isDesktop, optionSelected } = this.props;

        if (isDesktop) {
            return (
                <Table.Row
                    className="container-white cursor-pointer"
                    onClick={() => this.getInformativeRegimeDetail(item)}>
                    {headerElements.map((header) => (
                        <Table.Data align={header.align} className={header.classNameItem}>
                            <div className="data-text">
                                {header.name === "estado" ? (
                                    <InfoTag
                                        type="default"
                                        message={item[header.name]}
                                        tagBackground="#dddddd"
                                        color="black"
                                    />
                                ) : (
                                    item[header.name]
                                )}
                            </div>
                        </Table.Data>
                    ))}
                </Table.Row>
            );
        }

        return (
            <div
                className="account-table-wrapper account-table-header cursor-pointer py-2 my-2"
                onClick={() => this.getInformativeRegimeDetail(item)}
                onKeyDown={() => this.getInformativeRegimeDetail(item)}
                role="button"
                tabIndex={0}>
                <Col sm={6}>
                    {headerElements.map((header) => (
                        <>
                            {["referenciaOperacion", "nroBol"].includes(header.name) && (
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex space-between"
                                    dataClassName="data-desc small-font"
                                    label={`${FORM_ID}.column.${header.name}`}
                                    data={item[header.name] ? item[header.name] : "-"}
                                />
                            )}

                            {header.name === "destinacion" && optionSelected === "4" && (
                                <div className="transfer-data data-wrapper-flex space-between">
                                    <div className="d-flex flex-column">
                                        <span className="data-label">
                                            {i18nUtils.get(`${FORM_ID}.column.${header.name}`)}
                                        </span>
                                        <span className="data-label font-light">{item[header.name]}</span>
                                    </div>
                                    <span className="data-desc small-font">
                                        <InfoTag
                                            type="default"
                                            message={item.estado}
                                            tagBackground="#dddddd"
                                            color="black"
                                            tagClass="align-self-start m-0 mt-1"
                                        />
                                    </span>
                                </div>
                            )}

                            {optionSelected !== "4" && header.name === "estado" && (
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex space-between"
                                    dataClassName="data-desc small-font"
                                    label={`${FORM_ID}.column.${header.name}`}
                                    data={
                                        <InfoTag
                                            type="default"
                                            message={item[header.name]}
                                            tagBackground="#dddddd"
                                            color="black"
                                            tagClass="align-self-start m-0 mt-1"
                                        />
                                    }
                                />
                            )}

                            {optionSelected !== "3" ? (
                                <>
                                    {!["estado", "referenciaOperacion", "nroBol", "destinacion"].includes(
                                        header.name,
                                    ) && (
                                        <Heading.DataGroup
                                            containerClassName="transfer-data data-wrapper-flex space-between"
                                            dataClassName="data-desc small-font"
                                            label={`${FORM_ID}.column.${header.name}`}
                                            data={item[header.name] ? item[header.name] : "-"}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    {header.name !== "estado" && (
                                        <Heading.DataGroup
                                            containerClassName="transfer-data data-wrapper-flex space-between"
                                            dataClassName="data-desc small-font"
                                            label={`${FORM_ID}.column.${header.name}`}
                                            data={item[header.name] ? item[header.name] : "-"}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    ))}
                </Col>
            </div>
        );
    };

    render() {
        return <>{this.renderListEcomexInformativeRegime()}</>;
    }
}

const mapStateToProps = (state) => ({
    ecomexInformativeRegime: ecomexSelectors.getEcomexInformativeRegime(state),
    filter: ecomexSelectors.getFilter(state),
    pageNumber: ecomexSelectors.getPageNumber(state),
    numberPerPageOptions: ecomexSelectors.getNumberPerPageOptions(state),
    numberPerPage: ecomexSelectors.getNumberPerPage(state),
    optionSelected: ecomexSelectors.getOptionSelected(state),
    totalPages: ecomexSelectors.getTotalPages(state),
    filtersData: ecomexSelectors.getFiltersData(state),
});

export default connect(mapStateToProps)(EcomexInformativeRegimeList);
