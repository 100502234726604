import React, { useEffect } from "react";
import { arrayOf, number, shape, string, bool, func } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";
import { connect } from "react-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { getIbsAccount, getProductLabel } from "util/fundcorp";
import * as i18n from "util/i18n";

import I18n from "pages/_components/I18n";
import PageLoading from "pages/_components/PageLoading";
import FormattedAmount from "pages/_components/FormattedAmount";
import useValidateDebitMonetaryAccount from "../hooks/useValidateDebitMonetaryAccount";
import useUpdateFormValue from "../hooks/useUpdateFormValue";

function DebitAccountInfo({
    fund,
    shareAccount,
    activeEnvironment,
    isDesktop,
    fetching,
    dispatch,
    loggedUser,
    accounts,
    setIsValidAccount,
    setIbsAccount,
    setFieldValue,
}) {
    useEffect(() => {
        if (activeEnvironment.permissions.accounts && (!accounts || accounts.length === 0)) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
    }, [dispatch, loggedUser.userId, accounts, activeEnvironment.permissions.accounts]);

    const { monetaryAccount } = useValidateDebitMonetaryAccount(fund, shareAccount);

    const ibsAccount = getIbsAccount(accounts, monetaryAccount);
    useUpdateFormValue("accountBalance", ibsAccount?.balance, setFieldValue, true);

    useEffect(() => {
        if (ibsAccount) {
            setIbsAccount(ibsAccount);
            setIsValidAccount(true);
        }
    }, [ibsAccount, setIbsAccount, setIsValidAccount]);

    const label = getProductLabel(ibsAccount);

    if (i18n.get("fund.operation.subscribe.debitAccount.noAccount") === label) {
        setIsValidAccount(false);
        return (
            <PageLoading loading={fetching}>
                <Col
                    sm={12}
                    className={classNames("w-100 px-0", {
                        "align-items-center space-between": isDesktop,
                    })}>
                    <div
                        className={classNames({
                            "space-between w-100": !isDesktop,
                            "d-flex align-items-center": isDesktop,
                        })}>
                        <I18n
                            id="fund.operation.subscribe.debitAccount.label"
                            component="p"
                            componentProps={{ className: "f-size-55 text-uppercase pr-1 my-0" }}
                        />
                        <p className="f-size-55 font-light text-uppercase pr-1 my-0">{label}</p>
                    </div>
                </Col>
            </PageLoading>
        );
    }

    if (!shareAccount || !fund) {
        setIsValidAccount(false);
        return (
            <Col sm={12} className="align-items-center d-flex space-between w-100 px-0">
                <div className="d-flex align-items-center">
                    <I18n
                        id="fund.operation.subscribe.debitAccount.label"
                        component="p"
                        componentProps={{ className: "f-size-55 text-uppercase pr-1" }}
                    />
                </div>
            </Col>
        );
    }

    return (
        <PageLoading loading={fetching}>
            <Col
                sm={12}
                className={classNames("w-100 px-0", {
                    "align-items-center space-between": isDesktop,
                })}>
                <div
                    className={classNames(
                        { "space-between w-100": !isDesktop },
                        { "d-flex align-items-center": isDesktop },
                    )}>
                    <I18n
                        id="fund.operation.subscribe.debitAccount.label"
                        component="p"
                        componentProps={{ className: "f-size-55 text-uppercase pr-1 my-0" }}
                    />
                    <p
                        className={classNames("font-light text-uppercase pr-1 my-0", {
                            "f-size-55": isDesktop,
                            "f-size-65": !isDesktop,
                        })}>
                        {label}
                    </p>
                </div>

                <div className={classNames({ "w-100": !isDesktop })}>
                    <FormattedAmount
                        currency={ibsAccount.currency}
                        quantity={ibsAccount.balance}
                        className={classNames("data-amount d-flex justify-content-end", {
                            "f-size-65 font-light": !isDesktop,
                        })}
                        medium
                        notBold
                    />
                </div>
            </Col>
        </PageLoading>
    );
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    fetching: accountsSelectors.getFetching(state),
});

DebitAccountInfo.propTypes = {
    shareAccount: shape({
        cuit: number,
        description: string,
        id: number,
        monetaryAccounts: arrayOf(shape({})),
    }).isRequired,
    fund: shape({
        numero: number,
        rescatePlazo: number,
        suscripcionPlazo: number,
        nombre: string,
        estaAnulado: bool,
        horizonteInversionDescripcion: string,
        sociedadGerenteDescripcion: string,
        moneda: string,
        monedaDescripcion: string,
        regionDescripcion: string,
    }).isRequired,
    isDesktop: bool.isRequired,
    fetching: bool,
    dispatch: func.isRequired,
    loggedUser: shape({}).isRequired,
    accounts: arrayOf(shape({})).isRequired,
    setIsValidAccount: func.isRequired,
    setIbsAccount: func.isRequired,
    setFieldValue: func.isRequired,
    activeEnvironment: shape({
        permissions: shape({
            accounts: bool,
        }),
    }).isRequired,
};

DebitAccountInfo.defaultProps = {
    fetching: false,
};

export default connect(mapStateToProps)(DebitAccountInfo);
