import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { bool, string, func, number, shape } from "prop-types";

import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";

import Step3Content from "pages/login/_components/Step3Content";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";
import { push } from "react-router-redux/actions";
import * as i18n from "util/i18n";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Logo from "pages/login/_components/Logo";

class LoginStep3 extends Component {
    static propTypes = {
        fetchingBiometric: bool.isRequired,
        biometricLogin: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, activeEnvironment, username, fetchingBiometric } = this.props;
        if (activeEnvironment) {
            dispatch(push("/desktop"));
        } else if (!username && !fetchingBiometric) {
            dispatch(push("/"));
        }
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    renderTitleMobile = () => <Logo className="svg-image logo logo-mobile-size" logoColor />;

    render() {
        const { isDesktop, userFirstName, propsGrid, biometricLogin } = this.props;
        const title = i18n.get("login.step3.header");
        const firstName = userFirstName || (biometricLogin ? biometricLogin.userFirstName : null);

        const userName =
            firstName && firstName.length > 1
                ? firstName
                      .toLowerCase()
                      .split(" ")
                      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
                      .join(" ")
                : "";

        return (
            <GridLayoutExternal scopeToShow="loginStep3" isDesktop={isDesktop} {...propsGrid}>
                <Head
                    title={isDesktop && "login.step3.header"}
                    replace={
                        isDesktop && {
                            USER_NAME: userName,
                        }
                    }
                    onClose={this.onHeaderClose}
                    headerClassName={!isDesktop ? "login-background-color-white new-logo" : "recover-pass-desktop"}
                    centerElement={!isDesktop && this.renderTitleMobile}
                    hideMobileMenu={!isDesktop && true}
                    navStyle="default w-100"
                />
                <MainContainer
                    viewPageClassName="login-step3"
                    className={classNames("main-container", { "container-fluid": isDesktop, "mb-6": isDesktop })}>
                    {!isDesktop && (
                        <h1 className={isDesktop ? "text-center mt-2" : "pl-3 mt-2"}>
                            {title.replace("#{USER_NAME}", userName)}
                        </h1>
                    )}
                    <Step3Content />
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}

LoginStep3.propTypes = {
    isDesktop: bool.isRequired,
    userFirstName: string.isRequired,
    dispatch: func.isRequired,
    username: string.isRequired,
    activeEnvironment: number,
    propsGrid: shape({}).isRequired,
};

LoginStep3.defaultProps = {
    activeEnvironment: null,
};

const mapStateToProps = (state) => ({
    userFirstName: loginSelectors.getUserFirstName(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    username: loginSelectors.getUsername(state),
    fetchingBiometric: loginSelectors.getFetchingBiometric(state),
    biometricLogin: loginSelectors.getBiometricLogin(state),
});

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(LoginStep3));
