/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { shape, bool, arrayOf, func, string } from "prop-types";

import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import Pagination from "pages/_components/pagination/Pagination";
import CardContainer from "pages/_components/Card/CardContainer";
import IncomingDebinItem from "./IncomingDebinItem";

const tableLabel = "payments.debin.table.header";
class IncomingDebinTable extends Component {
    static propTypes = {
        requests: arrayOf(shape({})).isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        debinObject: shape({}).isRequired,
        filtersIncomingDebins: shape({
            dateFrom: shape({}).isRequired,
            dateTo: shape({}).isRequired,
            status: string.isRequired,
            recipient: string.isRequired,
        }).isRequired,
    };

    static defaultProps = {};

    fetchMoreTransactions = (pageNumber) => {
        const { dispatch, filtersIncomingDebins } = this.props;
        const { status, dateFrom, dateTo, recipient } = filtersIncomingDebins;

        dispatch(
            debinActions.loadIncomingRequests({
                status,
                dateFrom,
                dateTo,
                seller: recipient,
                pageNumber,
            }),
        );
    };

    render() {
        const { requests, isDesktop, debinObject, filtersIncomingDebins } = this.props;

        const list = requests?.debinList?.map((request) => {
            if (isDesktop) {
                return (
                    <>
                        <CardContainer className="w-100 mb-0 mt-0" key={request.id}>
                            <IncomingDebinItem
                                key={`transaction-${request.id}`}
                                request={request}
                                isDesktop={isDesktop}
                                link={`/payments/payDebin/detail/${request.id}`}
                            />
                        </CardContainer>
                    </>
                );
            }

            return (
                <CardContainer className="px-3">
                    <IncomingDebinItem
                        key={`transaction-${request.id}`}
                        request={request}
                        isDesktop={isDesktop}
                        tableLabel={tableLabel}
                        link={`/payments/payDebin/detail/${request.id}`}
                    />
                </CardContainer>
            );
        });

        const renderTable = isDesktop ? (
            <>
                {list}
                <div className="d-flex w-100 justify-content-end mr-3 mt-3">
                    <Pagination
                        totalPages={debinObject.totalPages}
                        pageNumber={filtersIncomingDebins.pageNumber}
                        action={this.fetchMoreTransactions}
                    />
                </div>
            </>
        ) : (
            list
        );

        return (
            <Container className="flex-grow overflow-visible">
                <Col md={12} className="px-0">
                    <div
                        style={{
                            borderSpacing: "0px 2px",
                            width: "100%",
                            minWidth: requests.length && isDesktop && "1170px",
                        }}
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {requests?.debinList?.length ? (
                            renderTable
                        ) : (
                            <div className="text-center no-more-data" key="noMoreMovements">
                                <div className="illustration-wrapper">
                                    <Image src="images/coloredIcons/find-in-page.svg" className="svg-big-icon" />
                                </div>
                                <p className="text-lead">
                                    <I18n id="payments.debin.table.empty.message" />
                                </p>
                            </div>
                        )}
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    requests: debinSelectors.getSentRequests(state),
    debinObject: debinSelectors.getDebinObject(state),
    filtersIncomingDebins: debinSelectors.getFiltersIncomingDebins(state),
});

export default connect(mapStateToProps)(IncomingDebinTable);
