import React, { Component } from "react";
import { bool, node, arrayOf, oneOfType, string } from "prop-types";

import Loader from "pages/_components/Loader";

/**
 * * Puede considerarse ubicarlo en sitios diferentes para bloquear toda o parte de la app
 */
class PageLoading extends Component {
    static propTypes = {
        loading: bool,
        children: oneOfType([arrayOf(node), node]),
        className: string,
    };

    static defaultProps = {
        loading: true,
        children: null,
        className: "",
    };

    render() {
        const { children, loading, className } = this.props;

        return <>{loading ? <Loader className={className} /> : children}</>;
    }
}

export default PageLoading;
