import React from "react";
import Col from "react-bootstrap/lib/Col";
import { bool, func, string } from "prop-types";
import * as i18n from "util/i18n";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import AddAdhesionReference from "./AddAdhesionReference";

function ContinueButton({
    showConcepts,
    isSubmittingConcepts,
    addService,
    isDisabled,
    handleBaseDebts,
    submitForm,
    isDesktop,
    className,
    btnContainerClassName,
    buttonClassName,
}) {
    ContinueButton.propTypes = {
        showConcepts: bool,
        isSubmittingConcepts: bool,
        addService: bool,
        isDisabled: bool,
        handleBaseDebts: bool,
        submitForm: func.isRequired,
        isDesktop: bool,
        className: string,
        btnContainerClassName: string,
        buttonClassName: string,
    };

    ContinueButton.defaultProps = {
        showConcepts: true,
        isSubmittingConcepts: false,
        addService: false,
        isDisabled: false,
        handleBaseDebts: false,
        isDesktop: false,
        className: "",
        btnContainerClassName: "",
        buttonClassName: "drawer-button-image",
    };

    const handleClick = () => {
        setTimeout(() => {
            submitForm();
        }, 100);
    };

    const drawerWidth = isDesktop ? "30%" : "100%";

    return (
        <Container className={`container--layout flex-grow ${className}`} gridClassName="form-content">
            <Col sm={12} md={6} className="d-flex align-items-center justify-content-center px-0">
                <Col
                    sm={12}
                    className={`col col-12 px-0 d-flex align-items-center justify-content-center 
                    ${btnContainerClassName}`}>
                    {addService && showConcepts && handleBaseDebts ? (
                        <ButtonDrawer
                            width={drawerWidth}
                            buttonClassName={buttonClassName}
                            backButton
                            bsStyle="dark"
                            label={!showConcepts ? "services.newPayment.check.button" : "global.continue"}
                            content={<AddAdhesionReference handleClick={handleClick} isDesktop={isDesktop} />}
                            headerContent={
                                !isDesktop && (
                                    <div className="justify-content-center text-white">
                                        <h1 className="mt-1">
                                            {i18n.get("services.newPayment.addService.addReference.label")}
                                        </h1>
                                    </div>
                                )
                            }
                        />
                    ) : (
                        <Button
                            style={{ width: !isDesktop && "100%" }}
                            bsStyle="dark"
                            label={!showConcepts ? "services.newPayment.check.button" : "global.continue"}
                            onClick={handleClick}
                            loading={isSubmittingConcepts}
                            disabled={isDisabled}
                        />
                    )}
                </Col>
            </Col>
        </Container>
    );
}

export default ContinueButton;
