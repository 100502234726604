import React, { useEffect } from "react";
import { bool, func, shape, arrayOf } from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import classNames from "classnames";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as fundcorpSelectors, actions as fundcorpActions } from "reducers/fundcorp";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import * as i18n from "util/i18n";

import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import RegisterShareAccountForm from "./_subscribe/RegisterShareAccountForm";

const FORM_ID = "fund.operation.registerShareAccount";

function FundRegisterShareAccount(props) {
    const {
        isDesktop,
        dispatch,
        fetching,
        loggedUser,
        accounts,
        shareAccounts,
        fetchingAccount,
        investorProfile,
    } = props;

    useEffect(() => {
        dispatch(fundcorpActions.createShareAccountPreRequest());
        if (!accounts || accounts.length === 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
        // eslint-disable-next-line
    }, [dispatch]);

    if (shareAccounts?.length > 0) {
        dispatch(push("/fundcorpOperations"));
    }

    const handleBack = () => {
        if (investorProfile?.valid) {
            dispatch(push("/fundcorpOperations"));
        } else {
            dispatch(push("/fundcorpOperations/investor-profile"));
        }
    };

    const getSubtitle = () => (
        <div className="view-title data-wrapper-flex">
            <h4 className={`my-0 mr-3 ${!isDesktop ? "text-justify mx-3 mb-4" : ""}`}>
                {i18n.get(`${FORM_ID}.subtitle.label`)}
            </h4>
        </div>
    );

    const renderHead = () => (
        <>
            <Head
                onBack={handleBack}
                headerClassName={classNames({
                    "blue-main-header-mobile": !isDesktop,
                })}
                centerContentClassName={classNames({
                    "mx-5": !isDesktop,
                })}
                title={!isDesktop && `${FORM_ID}.head.label`}
                titleClassName="text-white"
                accessibilityTextId={`${FORM_ID}.head.label`}
            />
            {isDesktop && <h1 className="mt-1 mb-3">{i18n.get(`${FORM_ID}.head.label`)}</h1>}
            {isDesktop ? (
                <Head centerElement={getSubtitle} centerContentClassName="d-flex flex-column align-items-start" />
            ) : (
                getSubtitle()
            )}
        </>
    );

    return (
        <>
            <Notification scopeToShow="registerCuotapartista" />
            <div className="admin-detail-head px-0">{renderHead()}</div>
            <PageLoading loading={fetching || fetchingAccount}>
                <MainContainer>
                    <RegisterShareAccountForm
                        {...props}
                        isDesktop={isDesktop}
                        dispatch={dispatch}
                        fetching={fetching}
                        accounts={accounts}
                        idForm={FORM_ID}
                        handleBack={handleBack}
                    />
                </MainContainer>
            </PageLoading>
        </>
    );
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    shareAccounts: fundcorpSelectors.getShareAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    accounts: accountsSelectors.getAccounts(state),
    fetchingAccount: accountsSelectors.getFetching(state),
    fetching: fundcorpSelectors.getFetching(state),
    investorProfile: fundcorpSelectors.getInvestorProfile(state),
});

FundRegisterShareAccount.propTypes = {
    isDesktop: bool,
    fetching: bool,
    dispatch: func.isRequired,
    loggedUser: shape({}).isRequired,
    shareAccounts: arrayOf(shape({})).isRequired,
    accounts: arrayOf(shape({})).isRequired,
    fetchingAccount: bool,
    investorProfile: shape({}).isRequired,
};

FundRegisterShareAccount.defaultProps = {
    isDesktop: true,
    fetching: false,
    fetchingAccount: false,
};

export default connect(mapStateToProps)(FundRegisterShareAccount);
