import React, { Component } from "react";
import { selectors as accountsSelectors } from "reducers/accounts";
import { connect } from "react-redux";
import { shape } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import * as i18nUtils from "util/i18n";
import FormattedDate from "pages/_components/FormattedDate";
import Heading from "pages/_components/Heading";
import { isFinalState } from "util/transaction";

class AccountOpeningData extends Component {
    static propTypes = {
        openingData: shape({}).isRequired,
    };

    render() {
        const { openingData } = this.props;

        return (
            <>
                <Col xs={12} className="px-0">
                    {isFinalState(openingData?.idTransactionStatus) && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="accounts.new.account.transaction.date"
                            data={<FormattedDate date={openingData?.submitDateTime} dateFormat="dd/MM/yyyy" showTime />}
                        />
                    )}
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="accounts.new.account.transaction.comprobante"
                        data={
                            <p className="data-desc m-0">{openingData?.idTransaction?.substring(0, 8).toUpperCase()}</p>
                        }
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="accounts.new.account.accountType.label"
                        data={
                            <p className="data-desc m-0">
                                {i18nUtils.get(`accounts.new.account.accountType.${openingData?.accountType}`)}
                            </p>
                        }
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="accounts.new.account.currency.label"
                        data={
                            <p className="data-desc m-0">
                                {i18nUtils.get(`accounts.new.account.currency.${openingData?.currency}`)}
                            </p>
                        }
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="accounts.new.account.message.label"
                        data={<p className="data-desc m-0">{openingData?.message}</p>}
                    />
                </Col>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    openingData: accountsSelectors.getAccountOpeningData(state),
    // fullName: sessionSelectors.getUser(state).userFullName,
});

export default connect(mapStateToProps)(AccountOpeningData);
