import React, { Component } from "react";

import UserInviteTicket from "pages/administration/_components/tickets/UserInviteTicket";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";

class UserInviteTicketContent extends Component {
    render() {
        return (
            <AdministrationTicket {...this.props}>
                <UserInviteTicket {...this.props} />
            </AdministrationTicket>
        );
    }
}

export default UserInviteTicketContent;
