import React from "react";
import { Modal, Row } from "react-bootstrap";
import { bool, func, shape, arrayOf, string } from "prop-types";
import classNames from "classnames";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";

function BatchDetailErrorsModal(props) {
    const { isVisible, isDesktop, toggleModal, dataModal, className = "echeq-template-modal"} = props;

    const getItem = (item) => (
        <li key={item.code} className="pb-2">
            <div>
                <h4 className="data-label text-label danger-label mb-0">{item.code}</h4>
                <p>{item.description}</p>
            </div>
        </li>
    );

    const contentInfo = () => (
        <div>
            <div className="w-100 align-items-start flex-column pb-4">
                <Row className="my-3">
                    <div className="d-flex align-items-center">
                        {isDesktop && <div className="bg-danger" />}
                        <I18n
                            component="h2"
                            componentProps={{
                                className: classNames("content-data-strong", {
                                    "pl-4": isDesktop,
                                    "pl-5": !isDesktop,
                                }),
                            }}
                            id="multiple.transfers.detail.modal.error.title"
                        />
                    </div>
                </Row>
                <div className="px-4 form-group-control-list">
                    <ul>{dataModal?.map((item) => (item?.code ? getItem(item) : <></>))}</ul>
                </div>
                {!isDesktop && (
                    <div className="px-4 w-100 d-flex justify-content-end">
                        <Button
                            block={false}
                            bsStyle="link"
                            label="global.accept"
                            onClick={() => toggleModal(false, "[]")}
                            className="my-0 py-0"
                        />
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <Modal
            show={isVisible}
            dialogClassName="asdas"
            className={className}
            bsClass="modal"
            onHide={() => toggleModal(false, "[]")}>
            {isDesktop && <Modal.Header closeButton className="no-title" />}
            <Modal.Body className={classNames("pb-2 show", { "max-width-600": isDesktop })}>{contentInfo()}</Modal.Body>
        </Modal>
    );
}

BatchDetailErrorsModal.propTypes = {
    isDesktop: bool,
    isVisible: bool,
    toggleModal: func.isRequired,
    dataModal: arrayOf(shape({})),
    className: string
};

BatchDetailErrorsModal.defaultProps = {
    isDesktop: true,
    isVisible: false,
    dataModal: [],
    className: "echeq-template-modal"
};

export default BatchDetailErrorsModal;
