/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, shape, arrayOf } from "prop-types";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import { isEnabledApi5 } from "util/fundcorp";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import { isMobileNative } from "util/device";
import NoDataMessage from "./NoDataMessage";

const FORM_ID = "fundcorp.list";
class FundcorpShareValuesList extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        shareValues: arrayOf(shape({})).isRequired,
        isEmptyState: bool.isRequired,
    };

    renderListForShareValues = () => {
        const { shareValues, isEmptyState } = this.props;
        return (
            <>
                {shareValues && shareValues.length > 0 && (
                    <Container className="flex-grow scrollable">
                        {shareValues.map((item) => this.renderListForShareValuesItem(item))}
                    </Container>
                )}
                {isEmptyState && (
                    <NoDataMessage>
                        <I18n id={`${FORM_ID}.empty.message.information`} />
                    </NoDataMessage>
                )}
            </>
        );
    };

    renderListForShareValuesItem = (item) => {
        const { isDesktop } = this.props;
        const { fondoNombre, tipoVCPAbreviatura, vcpFecha, monedaID, vcpValor, variacion } = item;
        const useAPI5 = isEnabledApi5();

        if (isDesktop) {
            return (
                <CardContainer>
                    <Card
                        title={
                            <div className="fundcorp__card-title">
                                <div className="fundcorp__share-date">
                                    <I18n
                                        id={`${FORM_ID}.shareValues.column.${
                                            useAPI5 ? "shareValueDate" : "dateLastValue"
                                        }`}
                                        componentProps={{ className: "fundcorp__share-date-info" }}
                                    />
                                    <FormattedDate
                                        date={vcpFecha}
                                        dateFormat="dd/MM/yyyy"
                                        className="fundcorp__share-date-data"
                                    />
                                </div>
                            </div>
                        }
                        content={
                            <div className="d-flex fundcorp__card-content pt-0">
                                <span className="fundcorp__share-name">{fondoNombre}</span>
                                <div className="fundcorp__type">
                                    <I18n
                                        id={`${FORM_ID}.shareValues.column.${useAPI5 ? "class" : "shareType"}`}
                                        componentProps={{ className: "fundcorp__type-share-info" }}
                                    />
                                    <span>{tipoVCPAbreviatura}</span>
                                </div>
                                <div className="fundcorp__card-description">
                                    <I18n
                                        id={`${FORM_ID}.shareValues.column.balance`}
                                        componentProps={{ className: "fundcorp__card-description-share-info" }}
                                    />
                                    <FormattedAmount
                                        noCurrency
                                        quantity={variacion}
                                        notBold
                                        minimumDecimalsCustom={7}
                                        maximumDecimalsCustom={7}
                                    />
                                </div>
                            </div>
                        }
                        bottom={
                            <div className="d-flex fundcorp__card-bottom">
                                <I18n
                                    id={`${FORM_ID}.shareValues.column.currency`}
                                    componentProps={{ className: "fundcorp__share-currency-info" }}
                                />
                                <span className="data-text">{monedaID}</span>
                            </div>
                        }
                        rightContent={
                            <div className="fundcorp__card-right-content">
                                <div className="fundcorp__card-balance">
                                    <I18n
                                        id={`${FORM_ID}.shareValues.column.${useAPI5 ? "shareValue" : "valueShare"}`}
                                        componentProps={{ className: "fundcorp__value-info" }}
                                    />
                                    <FormattedAmount
                                        className="data-amount"
                                        quantity={vcpValor}
                                        currency={monedaID}
                                        minimumDecimalsCustom={7}
                                        maximumDecimalsCustom={7}
                                    />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            );
        }
        return (
            <CardContainer className="px-3 mt-0">
                <Card
                    className="fundcorp__card-mobile sharevalues"
                    title={
                        <div className="fundcorp__card-title">
                            <span className="fundcorp__name-data">{fondoNombre}</span>
                            <div className={isMobileNative ? "fundcorp__type mobile-native" : "fundcorp__type"}>
                                <I18n
                                    id={`${FORM_ID}.shareValues.column.shareClass`}
                                    componentProps={{ className: "fundcorp__class-info" }}
                                />
                                <span>{tipoVCPAbreviatura}</span>
                            </div>
                            <hr className="mr-0 mb-1" />
                        </div>
                    }
                    content={
                        <div className="fundcorp__card-content pb-0 pt-0">
                            <div className="d-flex fundcorp__card-share-date">
                                <I18n
                                    id={`${FORM_ID}.shareValues.column.dateLastValue`}
                                    componentProps={{ className: "fundcorp__share-date-info" }}
                                />
                                <FormattedDate
                                    date={vcpFecha}
                                    dateFormat="dd/MM/yyyy"
                                    className="fundcorp__share-date-data"
                                />
                            </div>
                            <div className="d-flex fundcorp__card-share-currency">
                                <I18n
                                    id={`${FORM_ID}.shareValues.column.currency`}
                                    componentProps={{ className: "fundcorp__share-currency-info" }}
                                />
                                <span className="fundcorp__share-currency-data">{monedaID}</span>
                            </div>
                            <div className="d-flex fundcorp__card-share-balance">
                                <I18n
                                    id={`${FORM_ID}.shareValues.column.balance`}
                                    componentProps={{ className: "fundcorp__share-balance-info" }}
                                />
                                <FormattedAmount
                                    noCurrency
                                    className="fundcorp__share-balance-data"
                                    quantity={variacion}
                                    notBold
                                    minimumDecimalsCustom={7}
                                    maximumDecimalsCustom={7}
                                />
                            </div>
                        </div>
                    }
                    rightContent={
                        <div className="d-flex fundcorp__card-right-content">
                            <div className="fundcorp__card-share-value">
                                <I18n
                                    id={`${FORM_ID}.shareValues.column.valueShare`}
                                    componentProps={{ className: "fundcorp__share-value-info" }}
                                />
                                <FormattedAmount
                                    className="data-amount sharevalues"
                                    quantity={vcpValor}
                                    noCurrency
                                    minimumDecimalsCustom={7}
                                    maximumDecimalsCustom={7}
                                />
                            </div>
                        </div>
                    }
                />
            </CardContainer>
        );
    };

    render() {
        return <>{this.renderListForShareValues()}</>;
    }
}

export default FundcorpShareValuesList;
