import React, { Component } from "react";
import { string, func } from "prop-types";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import * as format from "util/format";
import { Link } from "react-router-dom";

class DownloadableDocument extends Component {
    static propTypes = {
        fileName: string.isRequired,
        handleClickDownload: func.isRequired,
        size: string,
        sizeUnit: string,
        fileFormat: string,
        dateToDisplay: string,
        expirationDate: string,
    };

    static defaultProps = {
        size: 0,
        sizeUnit: "KB",
        fileFormat: "pdf",
        dateToDisplay: "",
        expirationDate: "",
    };

    render() {
        const { fileName, size, sizeUnit, fileFormat, handleClickDownload, dateToDisplay, expirationDate } = this.props;

        return (
            <Link to="#" onClick={() => handleClickDownload(fileName)}>
                <div>
                    <I18n id={fileName} />
                    {size && (
                        <span>
                            {dateToDisplay && (
                                <>
                                    <br />
                                    {`Signed: ${format.date(dateToDisplay, i18nUtils.get("datepicker.format"))}`}
                                    {expirationDate ? ` - Expires: ${expirationDate}` : ""}
                                </>
                            )}
                            <br />
                            {`${i18nUtils.get(`global.download.${fileFormat}`)}, ${size} ${sizeUnit}`}
                        </span>
                    )}
                </div>

                <Image src="images/cloud-download.svg" className="svg-icon" />
            </Link>
        );
    }
}

export default DownloadableDocument;
