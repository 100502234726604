import React, { Component, Fragment } from "react";
import { withFormik, Form, Field } from "formik";
import { compose } from "redux";
import { connect } from "react-redux";
import Yup from "yup";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool } from "prop-types";

import { actions as settingsActions } from "reducers/settings";
import * as i18n from "util/i18n";
import * as config from "util/config";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import PasswordHelp from "pages/_components/PasswordHelp";
import Container from "pages/_components/Container";

const FORM_ID = "settings.changePassword";

class ChangePassword extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    renderTitleMobile = () => (
        <div>
            <h1 className="m-0">
                <I18n id="settings.changePassword" />
            </h1>
        </div>
    );

    render() {
        const { isSubmitting, isDesktop } = this.props;
        const maxLength = config.getInteger("core.password.maxLength", 8);
        const minLength = config.getInteger("core.password.minLength", 20);

        return (
            <Fragment>
                <Notification scopeToShow="changePassword" />
                <div className="admin-detail-head px-0">
                    <Head
                        backLinkTo="/settings"
                        headerClassName={!isDesktop && "blue-main-header-mobile"}
                        centerElement={!isDesktop && this.renderTitleMobile}
                        hideMobileMenu={!isDesktop && true}
                    />
                    {isDesktop && (
                        <h1>
                            <I18n id="settings.changePassword" />
                        </h1>
                    )}
                </div>
                <MainContainer>
                    <Form className="confirmation__container">
                        <Container
                            className={`align-items-center flex-grow px-0 ${
                                isDesktop ? "container--layout with-margin" : "pb-0"
                            }`}
                            gridClassName="confirmation__form confirmation__box">
                            <section className="container--layout flex-grow align-items-center cmf-container-white">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <div className="admin-content-center">
                                            <I18n id="settings.changePassword.textInfo" component="h2" />
                                        </div>
                                        <Col sm={12} md={9} lg={12} xl={12} className="col col-12 align-items-center">
                                            <Field
                                                name="password"
                                                idForm={FORM_ID}
                                                component={Credential}
                                                maxLength={maxLength}
                                            />
                                            <Field
                                                name="newPassword"
                                                idForm={FORM_ID}
                                                component={Credential}
                                                maxLength={maxLength}
                                            />
                                            <Field
                                                name="newPasswordConfirmation"
                                                idForm={FORM_ID}
                                                component={Credential}
                                                maxLength={maxLength}
                                            />
                                            <PasswordHelp
                                                formId={FORM_ID}
                                                minLength={minLength}
                                                maxLength={maxLength}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="admin-content-center">
                                        <I18n
                                            id="confirmation.withoutotp.text"
                                            component="p"
                                            componentProps={{ className: "confirmation__text-without-otp" }}
                                        />
                                    </div>
                                    <Container
                                        className="flex-grow align-items-center w-100"
                                        gridClassName="form-content">
                                        <Col sm={12} md={12} lg={12} className="px-0">
                                            <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                                                <div className="admin-content-center">
                                                    <Button
                                                        label="global.confirm"
                                                        loading={isSubmitting}
                                                        type="submit"
                                                        bsStyle="primary"
                                                        image="images/arrowRight.svg"
                                                        imageStyle="mr-2"
                                                    />
                                                </div>
                                            </Col>
                                        </Col>
                                    </Container>
                                </Grid>
                            </section>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

export default compose(
    connect(null),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ password: "", newPassword: "", newPasswordConfirmation: "" }),
        validationSchema: () =>
            Yup.lazy((values) => {
                if (
                    values.password === undefined ||
                    values.newPassword === undefined ||
                    values.newPasswordConfirmation === undefined
                ) {
                    return Yup.object().shape({
                        password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
                        newPassword: Yup.string().required(i18n.get(`${FORM_ID}.newPassword.required`)),
                        newPasswordConfirmation: Yup.string().required(
                            i18n.get(`${FORM_ID}.newPasswordConfirmation.required`),
                        ),
                    });
                }

                return Yup.object().shape({
                    newPassword: Yup.string()
                        .min(
                            config.getInteger("core.password.minLength", 8),
                            i18n.get(`${FORM_ID}.modify.error.minLength`),
                        )
                        .max(
                            config.getInteger("core.password.maxLength", 20),
                            i18n.get(`${FORM_ID}.modify.error.maxLength`),
                        )
                        .matches(
                            new RegExp(config.get("core.userconfiguration.updateuserpassword.pattern")),
                            i18n.get("settings.changePassword.modify.error.mustBeAlphanumeric"),
                        ),
                    newPasswordConfirmation: Yup.string()
                        .min(
                            config.getInteger("core.password.minLength", 8),
                            i18n.get(`${FORM_ID}.modify.error.minLength`),
                        )
                        .max(
                            config.getInteger("core.password.maxLength", 20),
                            i18n.get(`${FORM_ID}.modify.error.maxLength`),
                        )
                        .oneOf([values.newPassword], i18n.get(`${FORM_ID}.modify.error.passwordMustMatch`)),
                });
            }),

        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(
                settingsActions.changePassword(
                    values.password,
                    values.newPassword,
                    values.newPasswordConfirmation,
                    formikBag,
                ),
            );
        },
    }),
)(ChangePassword);
