import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push, goBack } from "react-router-redux";
import { bool, func } from "prop-types";

import { actions as loginActions } from "reducers/login";
import { actions as onboardingActions } from "reducers/onboarding";

import { resizableRoute } from "pages/_components/Resizable";
import Col from "react-bootstrap/lib/Col";

import MainContainer from "pages/_components/MainContainer";
import DecisionButton from "pages/onboarding/_components/DecisionButton";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import { getDisplay, getMobileOS, isMobileNative } from "util/device";
import { isSafari } from "util/browser";
import { MAIN_ONBOARDING_ROUTE } from "../onboarding/Step0";

class NoUser extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
    };

    renderAdditionalInfo = ({ stepCount, assetsRequired }) => {
        const stepsInfo = `${stepCount} ${i18nUtils.get("onboarding.noUser.remainingSteps")}`;

        return (
            <>
                <div className="flex-container">
                    <small className="pull-right">{stepsInfo}</small>
                </div>

                <I18n component="p" id={assetsRequired} />
            </>
        );
    };

    handleGoClick = (isClient) => {
        const { dispatch } = this.props;
        if (!isClient) {
            if (!isMobileNative && getMobileOS(getDisplay()) === "iOS" && !isSafari) {
                dispatch(push("/onboarding/SafariSuggestionPage"));
            } else {
                dispatch(push(MAIN_ONBOARDING_ROUTE));
            }
        } else {
            dispatch(push("/enrollment"));
        }
    };

    handleClose = () => {
        const { isMobile } = this.props;

        return isMobile ? this.onHeaderClose() : null;
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(onboardingActions.cleanUserCredentials());
    };

    render() {
        const { isMobile } = this.props;

        return (
            <Fragment>
                <Head title="onboarding.noUser.title" onClose={isMobile && this.onHeaderClose} />
                <MainContainer className="main-container">
                    <div className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col className="col col-12">
                                <div className="decision-wrapper">
                                    <DecisionButton
                                        onClick={() => this.handleGoClick(false)}
                                        label="onboarding.noUser.newUser"
                                        image="images/newUser.svg">
                                        {this.renderAdditionalInfo({
                                            stepCount: "13",
                                            assetsRequired: "onboarding.noUser.requirements.newUser",
                                        })}
                                    </DecisionButton>
                                    <DecisionButton
                                        onClick={() => this.handleGoClick(true)}
                                        label="onboarding.noUser.haveCode"
                                        image="images/InvitationCode.svg">
                                        {this.renderAdditionalInfo({
                                            stepCount: "5",
                                            assetsRequired: "onboarding.noUser.requirements.code",
                                        })}
                                    </DecisionButton>
                                </div>
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default connect(null)(resizableRoute(NoUser));
