import React from "react";
import { withRouter } from "react-router-dom";

import * as i18n from "util/i18n";

import { resizableRoute } from "pages/_components/Resizable";

class BackButtonListener extends React.Component {
    componentDidMount() {
        if (window.cordova) {
            document.addEventListener("backbutton", this.backButtonAction);
        }
    }

    componentWillUnmount() {
        if (window.cordova) {
            document.removeEventListener("backbutton", this.backButtonAction);
        }
    }

    onConfirm = (buttonIndex) => {
        if (buttonIndex === 2) {
            navigator.app.exitApp();
        }
    };

    backButtonAction = () => {
        const { history, location } = this.props;

        if (location.pathname === "/desktop") {
            navigator.notification.confirm(i18n.get("desktop.askCloseApp"), this.onConfirm, "", [
                i18n.get("global.no"),
                i18n.get("global.yes"),
            ]);
        } else {
            history.goBack();
        }
    };

    render() {
        return null;
    }
}

export default resizableRoute(withRouter(BackButtonListener));
