import React, { Component } from "react";
import { func, bool } from "prop-types";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";

import Head from "pages/_components/Head";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import { Field, Form, withFormik } from "formik";
import I18n from "pages/_components/I18n";
import Yup from "yup";
import { compose } from "redux";
import Credential from "pages/_components/fields/credentials/Credential";
import Notification from "pages/_components/Notification";

import { selectors as transferSelectors, actions as transferActions } from "reducers/transfer";

import * as i18nUtils from "util/i18n";
import isTokenActive from "util/token";

const FORM_ID = "scheduler.cancel";

class TransferScheduledCancel extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1>{i18nUtils.get(`${FORM_ID}.title`)}</h1>
        </div>
    );

    render() {
        const { isDesktop, fetching } = this.props;

        const continueButton = (
            <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                <Row>
                    <div className="admin-content-center">
                        <Button
                            image="images/arrowRight.svg"
                            imageStyle={isDesktop ? "mr-2" : "mr-1"}
                            type="submit"
                            bsStyle="primary"
                            label="global.confirm"
                            loading={fetching}
                        />
                    </div>
                </Row>
            </Col>
        );

        const credentials = (
            <>
                <Row>
                    {!isTokenActive(!isDesktop) ? (
                        <div className="admin-content-center">
                            <I18n id="transfer.summary.confirm.label" />
                        </div>
                    ) : (
                        <div className="admin-content-center pb-3">
                            <I18n
                                id="confirmation.withoutotp.text"
                                component="p"
                                componentProps={{ className: "confirmation__text-without-otp" }}
                            />
                        </div>
                    )}
                </Row>
                <Row className={!isDesktop ? "px-0" : "px-3"}>
                    <div className="admin-content-center">
                        <Field
                            placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                            name="otp"
                            type="otp"
                            idForm={FORM_ID}
                            component={Credential}
                        />
                    </div>
                </Row>
            </>
        );

        return (
            <>
                <Notification scopeToShow="transactionScheduledCancel" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        onClose={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile mb-3 py-2" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && <h1>{i18nUtils.get(`${FORM_ID}.title`)}</h1>}
                </div>
                <MainContainer>
                    <Form className={isDesktop ? "confirmation__container" : "above-the-fold"}>
                        <Container
                            className={!isDesktop && "mb-0 flex-grow align-items-center cmf-container-white"}
                            gridClassName={isDesktop ? "confirmation__form confirmation__box" : "form-content"}>
                            <Col className="col-12" lg={12} md={12} sm={12}>
                                <h1 className="text-align-center">{i18nUtils.get(`${FORM_ID}.question`)}</h1>
                            </Col>
                            <Col className="col-12 col-xl-12" lg={12} md={9} sm={12}>
                                {isDesktop && credentials}
                                {isDesktop && (
                                    <Container
                                        className="flex-grow align-items-center w-100"
                                        gridClassName="form-content">
                                        {continueButton}
                                    </Container>
                                )}
                            </Col>
                        </Container>
                        {!isDesktop && (
                            <Container
                                className=" container--layaut flex-grow align-items-center cmf-container-white without-margin-top"
                                gridClassName="form-content"
                                style={{ width: "100%" }}>
                                <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                    <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                        {credentials}
                                    </Col>
                                    {continueButton}
                                </Col>
                            </Container>
                        )}
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: transferSelectors.getSummary(state),
    fetching: transferSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, summary } = formikBag.props;
            const { debitAccount, creditAccount, cbu, amount, currency, clientUser } = summary;
            dispatch(
                transferActions.cancelScheduled(
                    summary.idTransaction,
                    otp,
                    debitAccount,
                    creditAccount,
                    cbu,
                    amount,
                    currency,
                    formikBag,
                    clientUser,
                ),
            );
        },
    }),
)(TransferScheduledCancel);
