/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
import * as API from "middleware/api";

export const downloadPosicionConsolidada = (year, month, filters) =>
    API.executeWithAccessToken("/posicionConsolidada.download", {
        year,
        month,
        f1: filters[0].on,
        f2: filters[1].on,
        f3: filters[2].on,
        f4: filters[3].on,
        f5: filters[4].on,
        f6: filters[5].on,
    });
