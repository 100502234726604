import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Route from "react-router-dom/Route";
import withRouter from "react-router-dom/withRouter";
import Col from "react-bootstrap/lib/Col";

import { resizableRoute } from "pages/_components/Resizable";
import Container from "pages/_components/Container";
import ErrorBoundary from "pages/_components/ErrorBoundary";

class BackofficeLayout extends Component {
    getDesktopLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };

        return (
            <Fragment>
                <div className="app theme-auth backoffice-layout">
                    <ErrorBoundary>
                        <Container className="container--layout flex-grow align-items-center">
                            <Col className="col col-12">
                                <ReceivedComponent {...matchProps} {...extras} />
                            </Col>
                        </Container>
                    </ErrorBoundary>
                </div>
            </Fragment>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(matchProps) => <React.Fragment>{this.getDesktopLayout(matchProps)}</React.Fragment>}
            />
        );
    }
}

export default withRouter(connect()(resizableRoute(BackofficeLayout)));
