import React, { Component } from "react";
import { shape } from "prop-types";

import { checksDetailStatusTagColor, discountedChecksDetailGetCodeMsg } from "util/checks";
import FormattedAmount from "pages/_components/FormattedAmount";
import InfoTag from "pages/_components/InfoTag";
import FormattedDate from "pages/_components/FormattedDate";

class DiscountedChecksDetailItem extends Component {
    static propTypes = {
        checkDetail: shape({}).isRequired,
    };

    static defaultProps = {};

    render() {
        const { checkDetail } = this.props;

        return (
            <>
                <div className="table-data">
                    <span className="data-aux">{checkDetail.comprobante}</span>
                </div>
                <div className="table-data center">
                    <span className="data-aux">{checkDetail.numeroOperacion}</span>
                </div>
                <div className="table-data center">
                    <span className="data-aux">{checkDetail.codigoSolicitud}</span>
                </div>
                <div className="table-data center">
                    <span className="data-aux">{checkDetail.denominacionBancoLibrador}</span>
                </div>
                <div className="table-data">
                    <FormattedAmount
                        currency={checkDetail.codigoMoneda.toString()}
                        quantity={checkDetail.importe}
                        notBold
                    />
                </div>
                <div className="table-data center">
                    <span className="data-aux">
                        <FormattedDate date={checkDetail?.fechaVencimientoCheque} dateFormat="dd/MM/yyyy" />
                    </span>
                </div>
                <div className="table-data" style={{ display: "flex" }}>
                    <InfoTag
                        type="info"
                        message={discountedChecksDetailGetCodeMsg(checkDetail.codigoEstadoCheque)}
                        tagBackground={checksDetailStatusTagColor(
                            discountedChecksDetailGetCodeMsg(checkDetail.codigoEstadoCheque),
                        )}
                        moreStyles={{
                            alignSelf: "flex-end",
                            color: "black",
                            margin: "0.5rem 0",
                        }}
                    />
                </div>
                <div className="table-data" />
            </>
        );
    }
}

export default DiscountedChecksDetailItem;
