import React, { useEffect } from "react";
import classNames from "classnames";
import Button from "pages/_components/Button";
import { FORM_ID } from "constants/servicePayments";
import Container from "pages/_components/Container";
import FormattedDate from "pages/_components/FormattedDate";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "redux";
import { chooseImage } from "util/transaction";

import { selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import { selectors as accountsSelectors } from "reducers/accounts";
import Head from "pages/_components/Head";
import { getAccountDescription } from "util/accounts";
import Notification from "pages/_components/Notification";

const MultiplePaymentsSuccess = ({ isDesktop, isSmallDesktop, dispatch, accounts, account, payments }) => {
    const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === account);
    const currencies = [{ id: 0, label: i18n.get(`currency.label.0`) }];
    const total = payments
        .reduce((accumulator, payment) => accumulator + (payment?.amount?.quantity || 0), 0)
        .toFixed(2);

    useEffect(() => {
        if (!payments.length) {
            dispatch(push("/servicePayments"));
        }
    }, [payments, dispatch]);

    const handleBack = () => {
        dispatch(push("/servicePayments"));
    };

    const centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", margin: 0 }}>{i18n.get("services.newPayment")}</h1>
        </div>
    );

    const renderButtons = () => (
        <>
            <Button
                type="button"
                bsStyle="outline"
                onClick={() => dispatch(push("/transactions/list"))}
                label="goto.historic.transaction"
                block={!isDesktop}
                className={classNames("m-0", { "max-width-btn w-100": isDesktop })}
            />
            <Button
                type="button"
                bsStyle="primary"
                onClick={() => dispatch(push("/servicePayments"))}
                label="services.newPayment.confirmationTicket.goToPayments"
                block={!isDesktop}
                className={classNames("m-0", { "max-width-btn w-100": isDesktop })}
            />
        </>
    );

    return (
        <>
            <Notification scopeToShow="payments" />
            <div className={classNames({ isDesktop: "admin-detail-head px-0 mb-4" })}>
                <Head
                    onBack={handleBack}
                    headerClassName={!isDesktop ? "blue-main-header-mobile agenda-payment-title-mobile" : ""}
                    navStyle="default pl-0"
                    centerElement={isDesktop ? undefined : centerContentMobile}
                />
                {isDesktop && (
                    <div className="view-title mb-2">
                        <h1>{i18n.get(`${FORM_ID}.confirmationTicket.title`)}</h1>
                    </div>
                )}
            </div>
            <main className="px-0">
                <Container className="flex-grow align-items-center">
                    <Col sm={12} md={9} lg={isSmallDesktop ? 6 : 5} className="cmf-container-white">
                        <div className="admin-content-center mt-3">
                            <Image
                                src={chooseImage("processing")}
                                styles={{
                                    width: isDesktop ? "80px" : "65px",
                                    height: isDesktop ? "80px" : "65px",
                                }}
                            />
                        </div>
                        <div className="admin-content-center transaction-ticket-status">
                            <I18n id="menu.payments.confirmationTicket.status" />
                        </div>
                        <div className="admin-content-center ">
                            <h1 className="cmf-title-center transaction-ticket-title">
                                <I18n id="menu.payments.confirmationTicket.title" />
                            </h1>
                        </div>
                        <Col sm={12} className="cmf-container-white px-0">
                            <Col sm={12} className="px-0 mt-4">
                                <Heading.DataGroup
                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                    labelClassName="data-label-medium"
                                    dataClassName="data-desc"
                                    label="accounts.new.account.transaction.date"
                                    data={
                                        <p className="data-desc m-0">
                                            <FormattedDate date={Date.now()} dateFormat="dd/MM/yyyy" showTime />
                                        </p>
                                    }
                                />
                                <Heading.DataGroup
                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                    labelClassName="data-label-medium"
                                    dataClassName="data-desc"
                                    label="services.newPayment.amountToPay"
                                    data={
                                        <p className="data-desc m-0">{`${currencies[0].label.toString()} ${total}`}</p>
                                    }
                                />
                                <Heading.DataGroup
                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                    labelClassName="data-label-medium"
                                    dataClassName="data-desc"
                                    label="services.newPayment.account.label"
                                    data={
                                        completeDebitAccount?.number !== undefined &&
                                        getAccountDescription(completeDebitAccount)
                                    }
                                />
                                <Heading.DataGroup
                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                    labelClassName="data-label-medium"
                                    dataClassName="data-desc"
                                    label="menu.payments.confirmationTicket.quantity"
                                    data={
                                        <p className="data-desc m-0">
                                            {payments.filter(({ isChecked }) => isChecked).length}
                                        </p>
                                    }
                                />
                            </Col>
                        </Col>
                    </Col>
                </Container>
                <div
                    className={classNames("mb-4 pt-4 d-flex gap-3", {
                        "justify-content-center": isDesktop,
                        "flex-column px-3": !isDesktop,
                    })}>
                    {renderButtons()}
                </div>
            </main>
        </>
    );
};

MultiplePaymentsSuccess.propTypes = {
    isDesktop: bool,
    isSmallDesktop: bool.isRequired,
    dispatch: func.isRequired,
    account: string.isRequired,
    accounts: arrayOf(
        shape({
            idProduct: string.isRequired,
        }),
    ).isRequired,
    payments: arrayOf(shape({})).isRequired,
};

MultiplePaymentsSuccess.defaultProps = {
    isDesktop: false,
};

const mapStateToProps = (state) => ({
    account: servicePaymentsSelectors.getMultiplePaymentsAccount(state),
    accounts: accountsSelectors.getAccounts(state),
    payments: servicePaymentsSelectors.getMultiplePaymentsInfo(state),
});

export default compose(connect(mapStateToProps))(MultiplePaymentsSuccess);
