import React, { Component, Fragment } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { func, string, bool, shape } from "prop-types";
import Yup from "yup";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import PasswordRules from "pages/_components/PasswordRules";
import Credential from "pages/_components/fields/credentials/Credential";
import withExchangeToken from "pages/_components/withExchangeToken";
import OnboardingStepper, { orientations } from "pages/onboarding/_components/OnboardingStepper";
import { Mixpanel } from "util/clickstreaming";
import Image from "pages/_components/Image";
import EnrollmentStepper from "./_components/EnrollmentStepper";
import AssociateStepperSimple from "./_components/AssociateStepperSimple";

const FORM_ID = "enrollment.step3";

class Step3Part1 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        exchangeToken: string.isRequired,
        invitationCode: string.isRequired,
        isMobile: bool.isRequired,
        isSubmitting: bool.isRequired,
        documentData: shape({
            firstName: string.isRequired,
        }).isRequired,
        match: shape.isRequired,
    };

    componentDidMount() {
        const { dispatch, invitationCode, exchangeToken } = this.props;
        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }

        dispatch(enrollmentActions.requestSecuritySeals(exchangeToken));
    }

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    renderTitleMobile = () => (
        <Image src="images/logoMobileHeader.svg" styles={{ position: "relative", top: "5px" }} className="" />
    );

    render() {
        const { isSubmitting, isMobile, documentData, match } = this.props;
        const maxLength = configUtils.getInteger("username.maxLength");
        const minLength = configUtils.getInteger("username.minLength");
        const step = 9;
        const isCredentialWhitening = match.path === "/credentialWhitening/step3part1";

        return (
            <Fragment>
                <Notification scopeToShow="userpassValidity" />
                <Head
                    onClose={isMobile && this.handleHeaderBack}
                    onBack={isMobile && this.handleHeaderBack}
                    headerClassName={isMobile ? "blue-main-header-mobile" : ""}
                    centerElement={!isMobile ? undefined : this.renderTitleMobile}
                    closeImageWhite={isMobile && true}
                />
                <div className="view-page">
                    {!isMobile && documentData.firstName && (
                        <OnboardingStepper currentStep={step} className="onboarding-steps" />
                    )}
                    {!(isMobile || documentData.firstName) && (
                        <EnrollmentStepper
                            currentStep={3}
                            credentialWhitening={isCredentialWhitening}
                            className="onboarding-steps"
                        />
                    )}
                    <div className="view-content">
                        <main className="main-container">
                            <Form className="above-the-fold">
                                {isMobile && (
                                    <Container className="container--layout align-items-center">
                                        <Col className="col">
                                            {documentData.firstName && (
                                                <OnboardingStepper
                                                    currentStep={step}
                                                    orientation={orientations.horizontal}
                                                />
                                            )}
                                            {!documentData.firstName && <AssociateStepperSimple currentStep={3} />}
                                        </Col>
                                    </Container>
                                )}
                                <Container
                                    className="container--layout align-items-center"
                                    gridClassName="form-content">
                                    <Col sm={12} md={5} lg={4} className="col col-12">
                                        <Col sm={12} md={12} lg={12} className="col col-12 enrollment-create-pass-text">
                                            <I18n
                                                component="h1"
                                                componentProps={{ className: "title-content" }}
                                                id="onboarding.step.9.title"
                                            />
                                            <I18n
                                                component="p"
                                                componentProps={{ className: "text-lead small-margin-bottom" }}
                                                id="onboarding.step.9.info"
                                            />
                                        </Col>
                                        <Col
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            className="col col-12 enrollment-create-pass-inputs">
                                            <Field
                                                component={Credential}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="user"
                                                maxLength={maxLength}
                                            />
                                            <Field
                                                component={Credential}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="userConfirmation"
                                                maxLength={maxLength}
                                            />
                                            <div className="password-rules">
                                                <PasswordRules>
                                                    <I18n
                                                        id={`${FORM_ID}.userRules.rule1`}
                                                        MIN_LENGTH={minLength}
                                                        MAX_LENGTH={maxLength}
                                                    />
                                                    <I18n id={`${FORM_ID}.userRules.rule2`} />
                                                    <I18n id={`${FORM_ID}.userRules.rule3`} />
                                                    <I18n id={`${FORM_ID}.userRules.rule4`} />
                                                    <I18n id={`${FORM_ID}.userRules.rule5`} />
                                                </PasswordRules>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12} lg={12} className="col col-12">
                                            <Button
                                                label="global.continue"
                                                type="submit"
                                                bsStyle="primary"
                                                loading={isSubmitting}
                                            />
                                        </Col>
                                    </Col>
                                </Container>
                            </Form>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: enrollmentSelectors.getFetching(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    documentData: onboardingSelectors.getDocumentData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ user: "", userConfirmation: "" }),
        validationSchema: () =>
            Yup.lazy((values) => {
                if (values.user === undefined || values.userConfirmation === undefined) {
                    return Yup.object().shape({
                        user: Yup.string().required(i18nUtils.get("enrollment.step3.user.error.empty")),
                        userConfirmation: Yup.string().required(i18nUtils.get("enrollment.step3.user.error.empty")),
                    });
                }
                return Yup.object().shape({
                    user: Yup.string()
                        .required(i18nUtils.get("enrollment.step3.user.error.empty"))
                        .min(
                            configUtils.getInteger("username.minLength", 8),
                            i18nUtils.get("enrollment.step3.user.error.MinLength"),
                        )
                        .max(
                            configUtils.getInteger("username.maxLength", 20),
                            i18nUtils.get("enrollment.step3.user.error.MaxLength"),
                        )
                        .matches(
                            new RegExp(configUtils.get("core.userconfiguration.updateuserpassword.pattern")),
                            i18nUtils.get(`settings.changeUserpass.modify.error.mustBeAlphanumeric`),
                        )
                        .oneOf(
                            [values.userConfirmation],
                            i18nUtils.get("enrollment.step3.userConfirmation.error.userMustMatch"),
                        ),
                    userConfirmation: Yup.string()
                        .required(i18nUtils.get("enrollment.step3.userConfirmation.error.empty"))
                        .min(
                            configUtils.getInteger("username.minLength", 8),
                            i18nUtils.get("enrollment.step3.userConfirmation.error.unfulfilledRules"),
                        )
                        .max(
                            configUtils.getInteger("username.maxLength", 20),
                            i18nUtils.get("enrollment.step3.userConfirmation.error.unfulfilledRules"),
                        )
                        .oneOf([values.user], i18nUtils.get("enrollment.step3.userConfirmation.error.userMustMatch")),
                });
            }),
        handleSubmit: ({ user, userConfirmation }, formikBag) => {
            const { match } = formikBag.props;
            const isCredentialWhitening = match.path === "/credentialWhitening/step3part1";

            formikBag.props.dispatch(
                enrollmentActions.validateUser(user, userConfirmation, formikBag, isCredentialWhitening),
            );

            Mixpanel.track(`${FORM_ID}.user`);
        },
    }),
)(withExchangeToken(Step3Part1));
