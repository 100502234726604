import { uniqBy } from "lodash";

// Action types
export const types = {
    SET_BOTTOM_SHEET_DISPLAY: "pointsOfInterest/SET_BOTTOM_SHEET_DISPLAY",
    LOAD_POINTS_FILTERS: "pointsOfInterest/LOAD_POINTS_FILTERS",
    LOAD_POINTS_FILTERS_SUCCESS: "pointsOfInterest/LOAD_POINTS_FILTERS_SUCCESS",
    LOAD_POINTS_OF_INTEREST: "pointsOfInterest/LOAD_POINTS_OF_INTEREST",
    LOAD_POINTS_OF_INTEREST_SUCCESS: "pointsOfInterest/LOAD_POINTS_OF_INTEREST_SUCCESS",
    LOAD_POINTS_OF_INTEREST_ERROR: "pointsOfInterest/LOAD_POINTS_OF_INTEREST_ERROR",
    FILTER_POINTS_OF_INTEREST: "pointsOfInterest/FILTER_POINTS_OF_INTEREST",
    CHANGE_MAP_DATA: "pointsOfInterest/CHANGE_MAP_DATA",
    UPDATE_POINTS: "pointsOfInterest/UPDATE_POINTS",
    UPDATE_POINTS_SUCCESS: "pointsOfInterest/POINTS_UPDATE_SUCCESS",
    CLEAN_ERRORS: "settings/CLEAN_ERRORS",
};

// Initial state
export const INITIAL_STATE = {
    isBottomSheetDisplayed: false,
    defaultPosition: {
        lat: 0,
        lng: 0,
    },
    mapData: {
        center: {},
        zoom: 15,
    },
    pointsList: [],
    errors: null,
    filters: [],
    loading: false,
    lastPage: false,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.SET_BOTTOM_SHEET_DISPLAY:
            return {
                ...state,
                isBottomSheetDisplayed: action.BottomSheetDisplay.isBottomSheetDisplayed,
            };

        // obtain POIs data actions
        case types.LOAD_POINTS_OF_INTEREST_SUCCESS:
            return {
                ...state,
                pointsList: action.data.pointsList,
                defaultPosition: action.data.defaultPosition,
                mapData: {
                    ...state.mapData,
                    center: action.data.defaultPosition,
                },
                loading: false,
                lastPage: action.data.isLastPage,
            };

        case types.FILTER_POINTS_OF_INTEREST: {
            return {
                ...state,
                pointsList: state.pointsList.map((poi) => {
                    if (poi.idPoiType === Number(Object.keys(action.filterParams)[0])) {
                        return Object.assign(poi, {
                            visible: !poi.visible,
                        });
                    }
                    return poi;
                }),
            };
        }

        case types.LOAD_POINTS_FILTERS_SUCCESS:
            return {
                ...state,
                filters: action.data,
            };

        case types.CHANGE_MAP_DATA:
            return {
                ...state,
                mapData: Object.assign(state.mapData, action.data),
            };

        case types.POINTS_UPDATE_SUCCESS:
            return {
                ...state,
                pointsList: uniqBy(state.pointsList.concat(action.data), "idPoi"),
            };

        case types.CLEAN_ERRORS:
            return {
                ...state,
                errors: null,
            };

        case types.LOAD_POINTS_OF_INTEREST:
            return {
                ...state,
                loading: true,
            };

        default:
            return state;
    }
};

// Action creators
export const actions = {
    setBottomSheetDisplay: (BottomSheetDisplay) => ({
        type: types.SET_BOTTOM_SHEET_DISPLAY,
        BottomSheetDisplay,
    }),

    getFilters: () => ({
        type: types.LOAD_POINTS_FILTERS,
    }),
    // poi data actions
    getPoiData: (payload) => ({
        type: types.LOAD_POINTS_OF_INTEREST,
        payload,
    }),
    // general actions
    cleanErrors: () => ({
        type: types.CLEAN_ERRORS,
    }),

    filterData: (filterParams) => ({
        type: types.FILTER_POINTS_OF_INTEREST,
        filterParams,
    }),

    changeMapData: (data) => ({
        type: types.CHANGE_MAP_DATA,
        data,
    }),

    updatePointsList: (mapProps) => ({
        type: types.UPDATE_POINTS,
        mapProps,
    }),
};

// Selectors
export const selectors = {
    getBottomSheetDisplay: (state) => state.pointsOfInterest.isBottomSheetDisplayed,
    getPointsData: (state) => state.pointsOfInterest.pointsList,
    getCurrentPosition: (state) => state.pointsOfInterest.defaultPosition,
    getCenter: (state) => state.pointsOfInterest.mapData.center,
    getZoom: (state) => state.pointsOfInterest.mapData.zoom,
    getFilters: (state) => state.pointsOfInterest.filters,
    isLoading: (state) => state.pointsOfInterest.loading,
    isLastPage: (state) => state.pointsOfInterest.lastPage,
};
