import React, { useEffect, useState } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Col } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import classNames from "classnames";

import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import PageLoading from "pages/_components/PageLoading";
import Container from "pages/_components/Container";
import InfoTag from "pages/_components/InfoTag";
import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";
import Card from "pages/_components/Card/Card";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import TransactionTicket from "pages/_components/TransactionTicket";
import Pagination from "pages/_components/pagination/Pagination";
import ContextMenu from "pages/_components/ContextMenu";

import { actions as formActions } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";
import {
    actions as multipleTransferActions,
    selectors as multipleTransferSelectors,
} from "reducers/multiple-transfers";
import { selectors as transactionsSelectors, actions as transactionsActions } from "reducers/transactions";
import { selectors as sessionSelectors } from "reducers/session";

import { multipleTransfersPre } from "middleware/multiple-transfers";
import * as i18n from "util/i18n";
import * as multipleTransfersUtils from "util/multiple-transfers";
import { getAccountDescription } from "util/accounts";
import { VALIDATION_ERROR } from "util/responses";
import { checkSign, exportGenericTicketPdfWithSuccessMessage, getGeneralTicketItem } from "util/transaction";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "pages/_components/Spinner";
import MultipleTransferIndividualTicketData from "./MultipleTransferIndividualTicketData";
import MultipleTransferContextMenu from "./components/MultipleTransferContextMenu";
import BatchDetailErrorsModal from "./components/BatchDetailErrorsModal";
import MultipleTransferTicketData from "./MultipleTransferTicketData";

const idActivity = "transfer.multiple.send";

function MultipleTransferDetail({
    batch,
    match: { params },
    location: { state: locationState },
    location,
    dispatch,
    fetchingDownload,
    fetching,
    isDesktop,
    batchDetail,
    pageNumber,
    totalPages,
    bankConcepts,
    batchErrors,
    loggedUser,
    potentialSignaturesData,
    activeEnvironment,
}) {
    const [statusFilter, setStatusFilter] = useState("TODOS");
    const [showErrorsModal, setShowErrorsModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [batchData, setBatchData] = useState(null);
    const [activeTransfer, setActiveTransfer] = useState(null);
    const { id } = params;

    useEffect(() => {
        if (batch) {
            dispatch(transactionsActions.getPotentialSignaturesData(batch.transactionId));
            setBatchData({
                id: {
                    value: batch.idBatch,
                    format: "string",
                },
                debitAccount: {
                    value: getAccountDescription(batch.account),
                    format: "string",
                },
                reference: {
                    value: batch.reference,
                    format: "string",
                },
                uploadDate: {
                    value: batch.creationDate,
                    format: "datetime",
                },
                executionDate: {
                    value: batch.processDate,
                    format: "date",
                },
                fileName: {
                    value: batch.fileName,
                    format: "string",
                },
                quantity: {
                    value: batch.quantity,
                    format: "string",
                },
                amount: {
                    value: batch.amount?.quantity,
                    format: `amount${i18n.get(`currency.label.${batch.amount?.currency || "ARS"}`)}`,
                },
            });
        }
    }, [batch]);

    useEffect(() => {
        if (locationState.statusDetail === "FINISHED_ERROR") {
            setStatusFilter("ERROR");
            dispatch(multipleTransferActions.getMultipleTransferById(id, null, "ERROR"));
        } else {
            dispatch(multipleTransferActions.getMultipleTransferById(id, null, statusFilter));
        }
    }, [id]);

    const handleBack = () => dispatch(push("/transfer/multiple"));

    const getTransferContent = (transfer) => {
        const content = [];
        if (batch?.batchStatus?.toUpperCase()?.startsWith("FINISHED") && transfer?.destinationName) {
            content.push(`${i18n.get("multiple.transfers.modal.cbu.label")} ${transfer.cbuDestination}`);
        }
        content.push(
            `${i18n.get("multiple.transfers.modal.concept.label")} ${transfer?.idBankConcepts}-${
                bankConcepts ? bankConcepts[transfer?.idBankConcepts] : ""
            }`,
        );
        if (batch?.batchStatus?.toUpperCase()?.startsWith("FINISHED") && transfer?.voucherNumber) {
            content.push(
                `${i18n.get("multiple.transfers.individual.data.voucherNumber.label")}: ${transfer.voucherNumber}`,
            );
        }
        return content.join("  |  ");
    };

    const fetchMoreTransactions = (currPageNumber, status) => {
        dispatch(
            multipleTransferActions.getMultipleTransferById(id, currPageNumber, status || statusFilter, isDesktop),
        );
    };

    const handleChangeState = (option) => {
        setStatusFilter(option?.value);
        fetchMoreTransactions(1, option.value);
    };

    const renderFilterButton = () => {
        const options = [
            { value: "TODOS", label: "TODOS" },
            { value: "SUCCESS", label: i18n.get(`multiple.transfers.status.success`).toUpperCase() },
            { value: "ERROR", label: i18n.get(`multiple.transfers.status.error`).toUpperCase() },
        ];

        return (
            <Container className="mb-3" rowClassName="justify-content-start">
                <Col sm={12} lg={3} md={3}>
                    <div className="form-group-text">
                        <label htmlFor="status" className="data-label">
                            <I18n id="multiple.transfers.status.label" />
                        </label>
                    </div>
                    <div className="input-group">
                        <Select
                            name="status"
                            className="slideFromBottom"
                            clearable={false}
                            searchable={isDesktop}
                            onChange={handleChangeState}
                            options={options}
                            optionClassName="needsclick"
                            placeholder=""
                            value={statusFilter}
                            defaultValue={statusFilter}
                            noResultsText={i18n.get("global.no.results")}
                        />
                    </div>
                </Col>
            </Container>
        );
    };

    const renderDetailErrors = () => (
        <>
            <hr />
            <Container rowClassName="">
                <Col xs={12} sm={6} lg={2}>
                    <Heading.DataGroup
                        containerClassName={`data-wrapper-flex ${!isDesktop ? "space-between" : "my-15"}`}
                        labelClassName="data-label-medium-normal transfer-multiple__data"
                        dataClassName="transfer-multiple__data ml-2"
                        label="multiple.transfers.detail.header.successedTransfers"
                        data={batchErrors?.successes}
                    />
                </Col>
                <Col xs={12} sm={6} lg={3}>
                    <Heading.DataGroup
                        containerClassName={`data-wrapper-flex ${!isDesktop ? "space-between" : "my-15"}`}
                        labelClassName="data-label-medium-normal transfer-multiple__data"
                        dataClassName="transfer-multiple__data ml-2"
                        label={
                            isDesktop
                                ? "multiple.transfers.detail.header.amount"
                                : "multiple.transfers.detail.header.amount.success"
                        }
                        data={
                            <FormattedAmount
                                currency={batch?.amount.currency}
                                quantity={batchErrors?.amountSuccess}
                                cleanClassName
                                className="transfer-multiple__data d-flex justify-content-end"
                            />
                        }
                    />
                </Col>
            </Container>
            <Container rowClassName="">
                <Col xs={12} sm={6} lg={2}>
                    <Heading.DataGroup
                        containerClassName={`data-wrapper-flex ${!isDesktop ? "space-between" : "my-15"}`}
                        labelClassName="data-label-medium-normal transfer-multiple__data"
                        dataClassName="transfer-multiple__data ml-2"
                        label="multiple.transfers.detail.header.failedTransfers"
                        data={batchErrors?.errors}
                    />
                </Col>
                <Col xs={12} sm={6} lg={3}>
                    <Heading.DataGroup
                        containerClassName={`data-wrapper-flex ${!isDesktop ? "space-between" : "my-15"}`}
                        labelClassName="data-label-medium-normal transfer-multiple__data"
                        dataClassName="transfer-multiple__data ml-2"
                        label={
                            isDesktop
                                ? "multiple.transfers.detail.header.amount"
                                : "multiple.transfers.detail.header.amount.failed"
                        }
                        data={
                            <FormattedAmount
                                currency={batch?.amount.currency}
                                quantity={batchErrors?.amountError}
                                cleanClassName
                                className="transfer-multiple__data d-flex justify-content-end"
                            />
                        }
                    />
                </Col>
            </Container>
        </>
    );

    const toggleModal = (isVisible, data) => {
        setShowErrorsModal(isVisible);
        setDataModal(data ? JSON.parse(data) : []);
    };

    const generateTicketBody = (transferData) => {
        const formattedData = Object.entries(transferData).map(([key, item]) =>
            getGeneralTicketItem(
                `multiple.transfers.summary.${key}.label`,
                key === "transferAmount" ? item.value?.quantity : item.value,
                item.format,
            ),
        );

        return formattedData;
    };

    const handleDownloadVoucher = async (transferData) => {
        dispatch(multipleTransferActions.setFetching(true));

        const transferType =
            transferData?.cuitCuiltDestination === activeEnvironment?.clients[0]?.idClient
                ? "transferInternal"
                : "transferThirdParties";

        const ticketData = {
            ticketName: i18n.get(`multiple.transfers.summary.${transferType}.title`),
            ticketBody: generateTicketBody(transferData),
        };

        await exportGenericTicketPdfWithSuccessMessage(ticketData, dispatch, i18n.get("global.download.success"));

        dispatch(multipleTransferActions.setFetching(false));
    };

    const renderTransfers = () =>
        batchDetail?.map((transfer) => (
            <Card
                key={transfer.id}
                id={transfer.id}
                isDesktop={isDesktop}
                title={
                    <div className="transfer-multiple__detail-list-head">
                        <div className="d-flex space-between align-items-center w-100">
                            <div className="d-flex align-items-center">
                                <h3 className="mr-2">{`${i18n.get("multiple.transfers.modal.document.label")} ${
                                    transfer.cuitCuiltDestination
                                }`}</h3>
                                {batch?.batchStatus?.toUpperCase()?.startsWith("FINISHED") &&
                                transfer?.destinationName ? (
                                    <InfoTag
                                        type="info"
                                        message={`${i18n.get("multiple.transfers.detail.column.beneficiary")} ${
                                            transfer.destinationName
                                        }`}
                                        tagBackground="#E1F5FC"
                                        capitalize
                                        moreStyles={{
                                            alignSelf: "flex-start",
                                            color: "black",
                                            fontFamily: "Graphik-Regular",
                                        }}
                                    />
                                ) : (
                                    <InfoTag
                                        type="info"
                                        message={`${i18n.get("multiple.transfers.modal.cbu.label")} ${
                                            transfer.cbuDestination
                                        }`}
                                        tagBackground="#E1F5FC"
                                        capitalize
                                        moreStyles={{
                                            alignSelf: "flex-start",
                                            color: "black",
                                            fontFamily: "Graphik-Regular",
                                        }}
                                    />
                                )}
                                {transfer?.reference ? (
                                    <InfoTag
                                        type="info"
                                        message={`${i18n.get("multiple.transfers.modal.reference.label")} ${
                                            transfer.reference
                                        }`}
                                        tagBackground="#E8EAF6"
                                        capitalize
                                        tagClass="ml-2"
                                        moreStyles={{
                                            alignSelf: "flex-start",
                                            color: "black",
                                            fontFamily: "Graphik-Regular",
                                        }}
                                    />
                                ) : (
                                    undefined
                                )}
                            </div>
                        </div>
                    </div>
                }
                content={<span className="text-12 text-bold">{getTransferContent(transfer)}</span>}
                rightContent={
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-column align-items-end">
                            <FormattedAmount
                                currency={batch?.amount.currency}
                                quantity={transfer.amount}
                                className="transfer-multiple__amount"
                            />
                            {batch?.batchStatus?.toUpperCase()?.startsWith("FINISHED") && transfer?.status ? (
                                <InfoTag
                                    type="info"
                                    message={i18n.get(`multiple.transfers.status.${transfer?.status?.toLowerCase()}`)}
                                    tagBackground={multipleTransfersUtils.statusTagColorBatch(transfer?.status)}
                                    tagClass="text-12 d-flex align-self-flex-end widht max-width-mincontent no-wrap py-0 px-3 mx-0"
                                    moreStyles={{
                                        color: "black",
                                        fontFamily: "Graphik-Regular",
                                    }}
                                />
                            ) : (
                                undefined
                            )}
                        </div>
                        <MultipleTransferContextMenu
                            dispatch={dispatch}
                            isDesktop={isDesktop}
                            batch={batch}
                            transfer={transfer}
                            bankConcepts={bankConcepts}
                            setActiveTransfer={setActiveTransfer}
                            handleDownloadVoucher={handleDownloadVoucher}
                            action={() =>
                                transfer?.status === "ERROR" ? toggleModal(true, transfer?.error) : undefined
                            }
                            idTransactionStatus={transfer?.status}
                        />
                    </div>
                }
            />
        ));

    const renderMobileTransfers = () =>
        batchDetail?.map((transfer) => (
            <Card
                key={transfer.id}
                id={transfer.id}
                isDesktop={isDesktop}
                title={
                    <div className="transfer-multiple__detail-list-head">
                        <div className="d-flex space-between align-items-center w-100">
                            <div className="d-flex align-items-center">
                                <h3>{`${i18n.get("multiple.transfers.modal.document.label")} ${
                                    transfer.cuitCuiltDestination
                                }`}</h3>
                            </div>
                            {transfer?.status === "SUCCESS" || transfer?.error ? (
                                <MultipleTransferContextMenu
                                    dispatch={dispatch}
                                    isDesktop={isDesktop}
                                    batch={batch}
                                    transfer={transfer}
                                    bankConcepts={bankConcepts}
                                    setActiveTransfer={setActiveTransfer}
                                    handleDownloadVoucher={handleDownloadVoucher}
                                    action={() =>
                                        transfer?.status === "ERROR" ? toggleModal(true, transfer?.error) : undefined
                                    }
                                    idTransactionStatus={transfer?.status}
                                />
                            ) : (
                                undefined
                            )}
                        </div>
                        <hr />
                    </div>
                }
                content={
                    <>
                        {transfer?.status ? (
                            <Heading.DataGroup
                                containerClassName="data-wrapper-flex space-between my-15"
                                labelClassName="data-label-medium text-12 text-bold"
                                dataClassName="data-desc text-12"
                                label="multiple.transfers.status.label"
                                data={
                                    <InfoTag
                                        type="info"
                                        message={i18n.get(
                                            `multiple.transfers.status.${transfer?.status?.toLowerCase()}`,
                                        )}
                                        tagBackground={multipleTransfersUtils.statusTagColorBatch(transfer?.status)}
                                        tagClass="text-12 d-flex align-self-flex-end widht max-width-mincontent no-wrap py-0 px-3 m-0"
                                        moreStyles={{
                                            color: "black",
                                            fontFamily: "Graphik-Regular",
                                        }}
                                    />
                                }
                            />
                        ) : (
                            undefined
                        )}
                        {transfer?.destinationName && transfer?.status?.toUpperCase()?.startsWith("FINISHED") ? (
                            <Heading.DataGroup
                                containerClassName="data-wrapper-flex space-between my-15"
                                labelClassName="data-label-medium text-12 text-bold"
                                dataClassName="data-desc text-12"
                                label="multiple.transfers.detail.column.beneficiary"
                                data={transfer?.destinationName}
                            />
                        ) : (
                            undefined
                        )}
                        <Heading.DataGroup
                            containerClassName="data-wrapper-flex space-between my-15"
                            labelClassName="data-label-medium text-12 text-bold"
                            dataClassName="data-desc text-12"
                            label="multiple.transfers.reference.label"
                            data={transfer.reference}
                        />
                        <Heading.DataGroup
                            containerClassName="data-wrapper-flex space-between my-15"
                            labelClassName="data-label-medium text-12 text-bold"
                            dataClassName="data-desc text-12"
                            label="multiple.transfers.modal.cbu.label"
                            data={transfer.cbuDestination}
                        />
                        <Heading.DataGroup
                            containerClassName="data-wrapper-flex space-between my-15"
                            labelClassName="data-label-medium text-12 text-bold"
                            dataClassName="data-desc text-12"
                            label="multiple.transfers.summary.concept.label"
                            data={`${transfer?.idBankConcepts}-${
                                bankConcepts ? bankConcepts[transfer?.idBankConcepts] : ""
                            }`}
                        />
                        {transfer.voucherNumber ? (
                            <Heading.DataGroup
                                containerClassName="data-wrapper-flex space-between my-15"
                                labelClassName="data-label-medium text-12 text-bold"
                                dataClassName="data-desc text-12"
                                label="multiple.transfers.individual.data.voucherNumber.label"
                                data={transfer.voucherNumber}
                            />
                        ) : (
                            undefined
                        )}
                    </>
                }
                rightContent={
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-column align-items-end">
                            <FormattedAmount
                                currency={batch?.amount.currency}
                                quantity={transfer.amount}
                                className="transfer-multiple__amount"
                            />
                        </div>
                    </div>
                }
            />
        ));

    const cancelTransaction = () => {
        const cancelTransactionData = (
            <MultipleTransferTicketData
                dispatch={dispatch}
                isDesktop={isDesktop}
                data={batchData}
                batch={{ ...batch, idTransactionStatus: batch?.batchStatus }}
            />
        );
        dispatch(push({ pathname: "/transactions/cancel", state: { goBackState: location.pathname } }));
        dispatch(
            formActions.cancelTransactionData({
                idTransaction: batch?.transactionId,
                content: cancelTransactionData,
                title: "multiple.transfers.ticket.title",
                force: true,
            }),
        );
        dispatch(formActions.cancelTransactionPre({ idActivity, idForm: "" }));
    };

    const signTransaction = () => {
        const signTransactionData = (
            <MultipleTransferTicketData
                dispatch={dispatch}
                isDesktop={isDesktop}
                data={batchData}
                batch={{ ...batch, idTransactionStatus: batch?.batchStatus }}
            />
        );

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: batch?.transactionId,
                idActivity,
                idForm: "",
                content: signTransactionData,
                title: "multiple.transfers.ticket.title",
                pendingDispatch: false,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity,
                idTransaction: batch?.transactionId,
                idForm: "",
                ticketData: batchData,
            }),
        );
    };

    const validateSignTransaction = async () => {
        dispatch(multipleTransferActions.setFetching(true));
        const { type, data } = await multipleTransfersPre();
        if (type === "W") {
            const { NO_FIELD } = data.data;
            if (data.code === VALIDATION_ERROR) {
                dispatch(notificationActions.showNotification(NO_FIELD, "warning", ["multipleTransfer.detail"]));
            }
        } else {
            signTransaction();
        }
        dispatch(multipleTransferActions.setFetching(false));
    };

    const handleDownload = () => {
        const { idBatch, fileName } = batch;
        dispatch(multipleTransferActions.downloadBatchDetail(idBatch, statusFilter, fileName));
    };

    const renderIndividualTransferTicket = () => {
        const transferType =
            activeTransfer?.cuitCuiltDestination === activeEnvironment?.clients[0]?.idClient
                ? "transferInternal"
                : "transferThirdParties";

        return (
            <>
                <Notification scopeToShow="multipleTransfer.detail" />
                <TransactionTicket
                    notificationScope="multipleTransfer.detail"
                    handleBack={() => setActiveTransfer(null)}
                    handleClick={() => handleDownloadVoucher(activeTransfer)}
                    isFetchingExport={fetchingDownload}
                    handleClickMessage="global.download"
                    headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                    centerElement={undefined}
                    downloadImageWhite={!isDesktop}
                    uniqueDownload
                    fetching={fetching}
                    isDesktop={isDesktop}
                    summaryTitle={`multiple.transfers.summary.${transferType}.title`}
                    idSubtitle="transfer.multiple.individual.subtitle"
                    summary={{ ...activeTransfer, idTransactionStatus: activeTransfer?.status }}
                    hideMobileMenu={!isDesktop}
                    showDrawerMobile={false}
                    hideSigns
                    ignoreHomeButton>
                    <MultipleTransferIndividualTicketData
                        isDesktop={isDesktop}
                        data={{
                            date: activeTransfer.transferDate?.value,
                            voucherNumber: activeTransfer.voucherNumber?.value,
                            originName: activeTransfer.originName?.value,
                            debitAccount: activeTransfer.debitAccount?.value,
                            transferType: activeTransfer.transferType?.value,
                            cbuDestination: activeTransfer.cbuDestination?.value,
                            cuitCuilDestination: activeTransfer.cuitCuiltDestination?.value,
                            holder: activeTransfer.holder?.value,
                            bankName: activeTransfer.bankName?.value,
                            amount: activeTransfer.transferAmount?.value,
                            concept: activeTransfer.concept?.value,
                            reference: activeTransfer.transferReference?.value,
                        }}
                    />
                </TransactionTicket>
            </>
        );
    };

    if (activeTransfer) {
        return renderIndividualTransferTicket();
    }

    const renderAmountBlock = () => {
        let totalAmount;
        if (["PENDING", "CANCELLED", "PENDING_VALIDATION"].includes(batch?.batchStatus)) {
            totalAmount = batch?.amount?.quantity;
        } else {
            totalAmount = batch?.totalAmountValidated;
        }

        return (
            <div
                className={classNames("transfer-multiple__data-container py-2", {
                    "mb-3": !batch?.batchStatus?.toUpperCase()?.startsWith("FINISHED"),
                })}>
                <Heading.DataGroup
                    containerClassName="data-wrapper-flex my-15 px-3"
                    labelClassName="data-label-medium transfer-multiple__amount"
                    dataClassName="ml-2"
                    label="multiple.transfers.amount.card"
                    data={
                        <FormattedAmount
                            currency={batch?.amount?.currency}
                            quantity={totalAmount}
                            small
                            className="transfer-multiple__amount d-flex justify-content-end"
                        />
                    }
                />

                {batch?.batchStatus?.toUpperCase()?.startsWith("FINISHED") &&
                    batchErrors?.errors > 0 &&
                    renderDetailErrors()}
            </div>
        );
    };

    const endOfListItem = (
        <div className="table-row min-height-0 justify-content-center" key="noMoreTransactions">
            <I18n id="transfers.multiple.finish.detail.list" componentProps={{ className: "my-2" }} />
        </div>
    );

    return (
        <PageLoading loading={(isDesktop && fetching) || fetchingDownload || (!isDesktop && fetching && !batch)}>
            <Notification scopeToShow="multipleTransfer.detail" />
            <div className="transfer-multiple__detail">
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : undefined}
                        titleText={
                            !isDesktop ? `${i18n.get("multiple.transfers.idBatch.label")}: ${batch?.idBatch}` : ""
                        }
                        titleClassName="transfer-multiple__detail-title-mobile"
                        accessibilityTextId="transfers.main.label"
                        rightContent={() => {
                            const items = [];
                            if (
                                batch?.batchStatus === "PENDING" &&
                                checkSign({ signatures: batch.signatures }, loggedUser, potentialSignaturesData)
                            ) {
                                items.push({
                                    label: "multiple.transfers.detail.sign",
                                    onClick: validateSignTransaction,
                                });
                            }
                            if (batch?.batchStatus?.toUpperCase()?.startsWith("PENDING")) {
                                items.push({
                                    label: "multiple.transfers.card.cancel",
                                    onClick: cancelTransaction,
                                });
                            }
                            return !isDesktop ? (
                                <ContextMenu
                                    imageStyle="pr-0"
                                    items={[
                                        ...items,
                                        {
                                            label: "echeq.batch.detail.button.downloadExcel",
                                            onClick: handleDownload,
                                        },
                                    ]}
                                />
                            ) : (
                                undefined
                            );
                        }}
                    />
                    {isDesktop ? (
                        <div className="transfer-multiple__header-desktop">
                            <div className="view-title d-flex flex-column">
                                <div className="d-flex">
                                    <I18n
                                        defaultValue={`${i18n.get("multiple.transfers.idBatch.label")}: ${
                                            batch?.idBatch
                                        }`}
                                        component="h1"
                                    />
                                    <InfoTag
                                        type="info"
                                        message={i18n.get(
                                            `multiple.transfers.status.${batch?.batchStatus?.toLowerCase()}`,
                                        )}
                                        tagBackground={multipleTransfersUtils.statusTagColorBatch(batch?.batchStatus)}
                                        tagClass="d-flex align-self-flex-end widht max-width-mincontent no-wrap py-1 px-2 ml-3"
                                        moreStyles={{
                                            color: "black",
                                        }}
                                    />
                                    <h1>{batch?.automaticMessage}</h1>
                                </div>
                                <Heading.DataGroup
                                    containerClassName="data-wrapper-flex"
                                    labelClassName="transfer-multiple__file-data-label"
                                    dataClassName="ml-2"
                                    label="multiple.transfers.summary.fileName.label"
                                    data={batch?.fileName}
                                    addDots
                                />
                            </div>
                            {batch?.batchStatus?.toUpperCase()?.startsWith("PENDING") ? (
                                <Button
                                    block
                                    label="multiple.transfers.card.cancel"
                                    loading={fetching}
                                    onClick={cancelTransaction}
                                    className="btn-primary"
                                />
                            ) : (
                                undefined
                            )}
                            {batch?.batchStatus === "PENDING" &&
                            checkSign({ signatures: batch.signatures }, loggedUser, potentialSignaturesData) ? (
                                <Button
                                    block
                                    label="multiple.transfers.detail.sign"
                                    loading={fetching}
                                    onClick={validateSignTransaction}
                                    className="btn-primary"
                                />
                            ) : (
                                undefined
                            )}
                            <Button
                                block
                                label="echeq.batch.detail.button.downloadExcel"
                                loading={fetchingDownload}
                                image="images/download_bold.svg"
                                onClick={handleDownload}
                                className="btn-outline cmf-button-width"
                                imageStyle="mr-2"
                            />
                        </div>
                    ) : (
                        <div className="px-3">
                            <div className="d-flex">
                                <InfoTag
                                    type="info"
                                    message={i18n.get(`multiple.transfers.status.${batch?.batchStatus?.toLowerCase()}`)}
                                    tagBackground={multipleTransfersUtils.statusTagColorBatch(batch?.batchStatus)}
                                    tagClass="d-flex align-self-flex-end widht max-width-mincontent no-wrap py-1 px-2 mr-2 ml-0"
                                    moreStyles={{
                                        color: "black",
                                    }}
                                />
                                <h1>{batch?.automaticMessage}</h1>
                            </div>
                            <Heading.DataGroup
                                containerClassName="data-wrapper-flex"
                                labelClassName="transfer-multiple__file-data-label"
                                dataClassName="transfer-multiple__file-data-label ml-2"
                                label="multiple.transfers.summary.fileName.label"
                                data={batch?.fileName}
                            />
                        </div>
                    )}
                </div>
                <div className="transfer-multiple__data-container">
                    <Container rowClassName="">
                        <Col xs={12} sm={6} lg={4}>
                            <Heading.DataGroup
                                containerClassName={`data-wrapper-flex ${!isDesktop ? "space-between" : "my-15"}`}
                                labelClassName={`transfer-multiple__data ${isDesktop ? "data-label-medium" : ""}`}
                                dataClassName="transfer-multiple__data"
                                label="multiple.transfers.summary.account.label"
                                data={
                                    <div className="d-flex">
                                        <I18n
                                            defaultValue={batch?.account ? getAccountDescription(batch.account) : ""}
                                            componentProps={{ className: "transfer-multiple__data ml-1" }}
                                        />
                                    </div>
                                }
                            />
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                            <Heading.DataGroup
                                containerClassName={`data-wrapper-flex ${!isDesktop ? "space-between" : "my-15"}`}
                                labelClassName={`transfer-multiple__data ${isDesktop ? "data-label-medium" : ""}`}
                                dataClassName="transfer-multiple__data ml-2"
                                label="multiple.transfers.summary.uploadDate.label"
                                data={moment(batch?.creationDate).format("DD/MM/yyyy")}
                            />
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                            <Heading.DataGroup
                                containerClassName={`data-wrapper-flex ${!isDesktop ? "space-between" : "my-15"}`}
                                labelClassName={`transfer-multiple__data ${isDesktop ? "data-label-medium" : ""}`}
                                dataClassName="transfer-multiple__data ml-2"
                                label="multiple.transfers.summary.date.label"
                                data={moment(batch?.processDate).format("DD/MM/yyyy")}
                            />
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                            <Heading.DataGroup
                                containerClassName={`data-wrapper-flex ${!isDesktop ? "space-between" : "my-15"}`}
                                labelClassName={`transfer-multiple__data ${isDesktop ? "data-label-medium" : ""}`}
                                dataClassName="transfer-multiple__data ml-2"
                                label="multiple.transfers.reference.label"
                                data={batch?.reference}
                            />
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                            <Heading.DataGroup
                                containerClassName={`data-wrapper-flex ${!isDesktop ? "space-between" : "my-15"}`}
                                labelClassName={`transfer-multiple__data ${isDesktop ? "data-label-medium" : ""}`}
                                dataClassName="transfer-multiple__data ml-2"
                                label="multiple.transfers.summary.quantity.label"
                                data={batch?.quantity}
                            />
                        </Col>
                    </Container>
                </div>
                {renderAmountBlock()}
                {batch?.batchStatus?.toUpperCase()?.startsWith("FINISHED") && batchErrors?.errors > 0
                    ? renderFilterButton()
                    : undefined}
                <BatchDetailErrorsModal
                    isDesktop={isDesktop}
                    isVisible={showErrorsModal}
                    toggleModal={toggleModal}
                    dataModal={dataModal}
                    className="multiple-transfer__modal-errors"
                />
                {isDesktop ? (
                    <>
                        <div className="transfer-multiple__detail-list">{renderTransfers()}</div>
                        <div className="d-flex w-100 justify-content-end mb-5">
                            <Pagination
                                totalPages={totalPages}
                                pageNumber={pageNumber}
                                action={fetchMoreTransactions}
                            />
                        </div>
                    </>
                ) : (
                    <div className="transfer-multiple__detail-list">
                        <InfiniteScroll
                            dataLength={batchDetail?.length}
                            next={() => fetchMoreTransactions(pageNumber + 1)}
                            hasMore={pageNumber < totalPages}
                            loader={<Spinner />}
                            endMessage={endOfListItem}>
                            {renderMobileTransfers()}
                        </InfiniteScroll>
                    </div>
                )}
            </div>
        </PageLoading>
    );
}

const mapStateToProps = (state) => ({
    fetching: multipleTransferSelectors.getFetching(state),
    fetchingDownload: multipleTransferSelectors.getFetchingDownload(state),
    batch: multipleTransferSelectors.getActiveMultipleTransfer(state),
    batchDetail: multipleTransferSelectors.getBatchDetail(state),
    pageNumber: multipleTransferSelectors.getPageNumberDetail(state),
    totalPages: multipleTransferSelectors.getTotalPagesDetail(state),
    bankConcepts: multipleTransferSelectors.getBankConcepts(state),
    batchErrors: multipleTransferSelectors.getTransferBatchErrors(state),
    loggedUser: sessionSelectors.getUser(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    potentialSignaturesData: transactionsSelectors.getPotentialSignaturesData(state),
});

MultipleTransferDetail.propTypes = {
    match: shape({ params: shape({ id: string }) }).isRequired,
    location: shape({}).isRequired,
    batch: shape({}).isRequired,
    dispatch: func.isRequired,
    fetchingDownload: bool,
    fetching: bool.isRequired,
    isDesktop: bool.isRequired,
    batchDetail: arrayOf().isRequired,
    pageNumber: number.isRequired,
    totalPages: number.isRequired,
    bankConcepts: arrayOf().isRequired,
    batchErrors: shape({}).isRequired,
    loggedUser: shape({
        userId: string.isRequired,
    }).isRequired,
    potentialSignaturesData: arrayOf(shape({})).isRequired,
    activeEnvironment: shape({}).isRequired,
};

MultipleTransferDetail.defaultProps = {
    fetchingDownload: false,
};

export default compose(connect(mapStateToProps))(MultipleTransferDetail);
