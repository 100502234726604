import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as accountsActions } from "reducers/accounts";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";

import * as i18n from "util/i18n";

const FORM_ID = "accounts.movements.filters.reference";

class ReferenceFilter extends Component {
    render() {
        const { isSubmitting } = this.props;

        return (
            <Form>
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Col sm={12} md={8} className="col col-12 col-no-pad-mobile">
                        <Field component={TextField} hidePlaceholder idForm={FORM_ID} name="reference" type="text" />
                    </Col>

                    <Col
                        sm={12}
                        md={4}
                        className="col col-no-pad-mobile"
                        style={{
                            alignSelf: "flex-end",
                        }}>
                        <Button bsStyle="primary" label="product.filters.filter" loading={isSubmitting} type="submit" />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => ({
        reference: "",
    }),
    validationSchema: (props) =>
        Yup.object().shape({
            reference: Yup.string().max(50, i18n.get(`${FORM_ID}.reference.error`)),
        }),
    handleSubmit: ({ ...filters }, formikBag) => {
        const { dispatch, isDesktop, productId } = formikBag.props;

        if (isDesktop) {
            dispatch(accountsActions.details(productId, filters, formikBag));
        } else {
            dispatch(accountsActions.setMobileFilter({ filter: "reference", ...filters }));
            dispatch(push(`/accounts/${productId}/filters/results`));
        }
    },
})(ReferenceFilter);
