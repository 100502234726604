/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, shape, arrayOf, func } from "prop-types";
import { push } from "react-router-redux";

import { actions as fundcorpActions } from "reducers/fundcorp";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import { isEnabledMvp } from "util/fundcorp";

import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Image from "pages/_components/Image";
import ContextMenu from "pages/_components/ContextMenu";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import { isMobileNative } from "util/device";

const FORM_ID = "fundcorp.list";
class FundcorpPositionList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        positions: arrayOf(shape({})).isRequired,
        filtersData: shape({}).isRequired,
        activeEnvironment: bool.isRequired,
        hasPositionsToday: func.isRequired,
    };

    renderListForPositionByShare = (itemList) => {
        const { isDesktop } = this.props;

        const currency = i18nUtils.get(`fundcorp.list.position.currency.${itemList.positionList[0].monedaSimbolo}`);

        const { title, total } = itemList;
        const match = title.match(/(.*?)\s*(\d+)\s*\|(.*)/);
        const extractedTextBefore = match ? match[1].trim() : "";
        const extractedTextAfter = match ? match[3].trim() : "";
        const extractedNumber = match ? match[2] : "";

        return (
            <>
                {isDesktop ? (
                    <>
                        <h2 className="fundcorp__title mt-4 ml-1 mb-1 ellipsis">
                            {extractedTextBefore} {extractedTextAfter}
                            <I18n
                                id={`${FORM_ID}.position.byFund.column.account`}
                                componentProps={{ className: "fundcorp__title-account" }}
                            />
                            {extractedNumber}
                        </h2>
                    </>
                ) : (
                    <h4 className="fundcorp__title px-3 ml-1 mb-0 container">
                        {extractedTextBefore} {extractedTextAfter}
                        <span>
                            <I18n
                                id={`${FORM_ID}.position.byFund.column.account`}
                                componentProps={{ className: "fundcorp__title-account" }}
                            />
                            {itemList.positionList[0].cuotapartistaNumero}
                        </span>
                    </h4>
                )}
                <Container>
                    {itemList.positionList.map((item) => this.renderListForPositionByShareItem(item, currency))}
                    <Card
                        className={!isDesktop ? "fundcorp__card-total-amount" : null}
                        rightContent={
                            <div className="d-flex fundcorp__card-right-content">
                                <I18n
                                    id={`${FORM_ID}.position.balanceTotal`}
                                    componentProps={{ className: "fundcorp__total-info" }}
                                />
                                <FormattedAmount
                                    currency={currency}
                                    quantity={total}
                                    className="fundcorp__total-data"
                                />
                            </div>
                        }
                    />
                </Container>
            </>
        );
    };

    isFundActive = ({ fondoNumero }) => {
        const fundsParameters = JSON.parse(configUtils.get("frontend.investment.funds.parameters"));
        return fundsParameters[`fondo${fondoNumero}`]?.isON;
    };

    handleSubscribe = (url) => {
        const showMvp4Release = isEnabledMvp("MVP4");
        const { dispatch } = this.props;

        if (showMvp4Release) {
            dispatch(fundcorpActions.getInvestorProfile(url));
        } else {
            dispatch(push(url));
        }
    };

    renderContexMenu = (fondo) => {
        const { activeEnvironment, dispatch, hasPositionsToday } = this.props;
        const showMvp3Release = configUtils.getBoolean("frontend.show.MVP3.functionalities");
        const {
            fondoNumero,
            tipoVCPAbreviatura: fondoAbreviatura,
            monedaIso: fondoMoneda,
            cuotapartistaNumero,
        } = fondo;
        const items = [];

        if (activeEnvironment.permissions.fundSubscribe && this.isFundActive(fondo)) {
            items.push({
                label: "fund.operation.subscribe",
                onClick: () =>
                    this.handleSubscribe(`/fundcorpOperations/subscribe/${fondoNumero}/${cuotapartistaNumero}`),
            });
        }

        if (activeEnvironment.permissions.fundRescue && showMvp3Release && hasPositionsToday()) {
            items.push({
                label: "fund.operation.rescue",
                onClick: () =>
                    dispatch(
                        push(
                            // eslint-disable-next-line max-len
                            `/fundcorpOperations/rescue/${fondoNumero}/${fondoAbreviatura}/${fondoMoneda}/${cuotapartistaNumero}`,
                        ),
                    ),
            });
        }

        if (items.length === 0) {
            return null;
        }

        return <ContextMenu imageStyle="pr-0" items={items} />;
    };

    renderListForPositionByShareItem = (item, currency) => {
        const { isDesktop } = this.props;
        const {
            fondoNombre,
            tipoVCPDescripcion,
            ultimoVCPFecha,
            ultimoVCPValor,
            cuotapartesTotales,
            cuotapartesValuadas,
        } = item;

        const separatorVCPDescription = tipoVCPDescripcion.split(" ");
        const clase = separatorVCPDescription[0];
        const vcp = separatorVCPDescription[1];

        if (isDesktop) {
            return (
                <CardContainer className="d-flex flex-column">
                    <Card
                        className="mb-2"
                        content={
                            <div className="d-flex fundcorp__card-content pt-0">
                                <span className="fundcorp__name">{fondoNombre}</span>
                                <div className="fundcorp__card-description">
                                    <span className="fundcorp__card-description-info">{clase}</span>
                                    <span>{vcp}</span>
                                </div>
                                <div className="fundcorp__type">
                                    <I18n
                                        id={`${FORM_ID}.position.byFund.column.shareAmountFound`}
                                        componentProps={{ className: "fundcorp__content-third-info" }}
                                    />
                                    <FormattedAmount
                                        noCurrency
                                        quantity={cuotapartesTotales}
                                        minimumDecimalsCustom={4}
                                        maximumDecimalsCustom={4}
                                        notBold
                                    />
                                </div>
                            </div>
                        }
                        bottom={
                            <div className="d-flex fundcorp__card-bottom">
                                <div className="d-flex fundcorp__card-last-amount">
                                    <I18n
                                        id={`${FORM_ID}.position.byShare.column.lastValueShare`}
                                        componentProps={{ className: "fundcorp__last-amount-info" }}
                                    />
                                    <FormattedAmount
                                        quantity={ultimoVCPValor}
                                        notBold
                                        currency={currency}
                                        minimumDecimalsCustom={7}
                                        maximumDecimalsCustom={7}
                                    />
                                </div>
                                <div className="d-flex fundcorp__card-date">
                                    <I18n
                                        id={`${FORM_ID}.position.byShare.column.date`}
                                        componentProps={{ className: "fundcorp__date-info" }}
                                    />
                                    <FormattedDate date={ultimoVCPFecha} className="fundcorp__date-data" />
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="d-flex fundcorp__card-right-content">
                                <div className="fundcorp__card-balance">
                                    <I18n
                                        id={`${FORM_ID}.position.byShare.column.balance`}
                                        componentProps={{ className: "fundcorp__balance-info" }}
                                    />
                                    <FormattedAmount
                                        currency={currency}
                                        className="data-amount"
                                        quantity={cuotapartesValuadas}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-link p-0 m-0"
                                    onClick={(e) => e.stopPropagation()}>
                                    {this.renderContexMenu(item)}
                                </button>
                            </div>
                        }
                    />
                </CardContainer>
            );
        }
        return (
            <CardContainer className="px-3">
                <Card
                    className="fundcorp__card-mobile pb-2"
                    title={
                        <div className="fundcorp__card-title">
                            <span className="fundcorp__name-data">{fondoNombre}</span>
                            <div className={isMobileNative ? "fundcorp__type mobile-native" : "fundcorp__type"}>
                                <span className="fundcorp__class-info">{clase}</span>
                                <span>{vcp}</span>
                            </div>
                            <hr className="mr-0 mb-1" />
                        </div>
                    }
                    content={
                        <div className="fundcorp__card-content pb-0 pt-0">
                            <div className="d-flex fundcorp__card-quantity-amount">
                                <I18n
                                    id={`${FORM_ID}.position.byFund.column.shareAmountFound`}
                                    componentProps={{ className: "fundcorp__quantity-info" }}
                                />
                                <FormattedAmount
                                    className="fundcorp__quantity-data"
                                    quantity={cuotapartesTotales}
                                    noCurrency
                                    minimumDecimalsCustom={4}
                                    maximumDecimalsCustom={4}
                                    notBold
                                />
                            </div>
                            <div className="d-flex fundcorp__card-last-amount">
                                <I18n
                                    id={`${FORM_ID}.position.byShare.column.lastValueShare`}
                                    componentProps={{ className: "fundcorp__last-amount-info" }}
                                />
                                <FormattedAmount
                                    className="fundcorp__last-amount-data"
                                    quantity={ultimoVCPValor}
                                    notBold
                                    currency={currency}
                                    minimumDecimalsCustom={7}
                                    maximumDecimalsCustom={7}
                                />
                            </div>
                            <div className="d-flex fundcorp__card-date">
                                <I18n
                                    id={`${FORM_ID}.position.byShare.column.date`}
                                    componentProps={{ className: "fundcorp__date-info" }}
                                />
                                <FormattedDate date={ultimoVCPFecha} className="fundcorp__date-data" />
                            </div>
                        </div>
                    }
                    rightMenu={
                        <div className="d-flex fundcorp__card-right-content">
                            <button type="button" className="btn btn-link p-0 m-0" onClick={(e) => e.stopPropagation()}>
                                {this.renderContexMenu(item)}
                            </button>
                        </div>
                    }
                    rightContent={
                        <div className="d-flex fundcorp__card-right-content">
                            <div className="fundcorp__card-balance">
                                <FormattedAmount
                                    currency={currency}
                                    className="data-amount"
                                    quantity={cuotapartesValuadas}
                                />
                            </div>
                        </div>
                    }
                />
            </CardContainer>
        );
    };

    renderListForPositionByFund = (itemList) => {
        const { isDesktop } = this.props;

        const currency = i18nUtils.get(`fundcorp.list.position.currency.${itemList.positionList[0].monedaSimbolo}`);

        const { total, title } = itemList;

        const separatortitle = title.split("Fondo");

        return isDesktop ? (
            <>
                <h2 className="fundcorp__title mt-4 mb-3 ml-1 ellipsis">{separatortitle}</h2>
                {itemList.positionList.map((item) => this.renderListForPositionByFundItem(item, currency))}
                <Card
                    rightContent={
                        <div className="d-flex fundcorp__card-right-content">
                            <I18n
                                id={`${FORM_ID}.position.balanceTotal`}
                                componentProps={{ className: "fundcorp__total-info" }}
                            />
                            <FormattedAmount currency={currency} quantity={total} className="fundcorp__total-data" />
                        </div>
                    }
                />
            </>
        ) : (
            <>
                <h4 className="fundcorp__title ml-1 mb-0 px-3">{separatortitle}</h4>
                <Container className="flex-grow scrollable">
                    {itemList.positionList.map((item) => this.renderListForPositionByFundItem(item, currency))}
                    <Card
                        className="fundcorp__card-total-amount"
                        rightContent={
                            <div className="d-flex fundcorp__card-right-content">
                                <I18n
                                    id={`${FORM_ID}.position.balanceTotal`}
                                    componentProps={{ className: "fundcorp__total-info" }}
                                />
                                <FormattedAmount
                                    currency={currency}
                                    quantity={total}
                                    className="fundcorp__total-data"
                                />
                            </div>
                        }
                    />
                </Container>
            </>
        );
    };

    renderListForPositionByFundItem = (item, currency) => {
        const { isDesktop } = this.props;
        const {
            cuotapartistaNumero,
            cuotapartistaNombre,
            ultimoVCPFecha,
            ultimoVCPValor,
            cuotapartesTotales,
            cuotapartesValuadas,
        } = item;

        if (isDesktop) {
            return (
                <CardContainer>
                    <Card
                        content={
                            <div className="d-flex fundcorp__card-content pt-0">
                                <div className="d-flex fundcorp__card-fund-name">
                                    <I18n
                                        id={`${FORM_ID}.position.byShare.title`}
                                        componentProps={{ className: "fundcorp__fund-name-info" }}
                                    />
                                    <span>{cuotapartistaNombre}</span>
                                    <div className="fundcorp__content-second">
                                        <I18n
                                            id={`${FORM_ID}.position.byFund.column.account`}
                                            componentProps={{ className: "fundcorp__content-second-info" }}
                                        />
                                        <span>{cuotapartistaNumero}</span>
                                    </div>
                                    <div className="fundcorp__content-third">
                                        <I18n
                                            id={`${FORM_ID}.position.byFund.column.shareAmountFound`}
                                            componentProps={{ className: "fundcorp__content-third-info" }}
                                        />
                                        <FormattedAmount
                                            className="fundcorp__fund-cuotaparte-data"
                                            noCurrency
                                            quantity={cuotapartesTotales}
                                            notBold
                                            minimumDecimalsCustom={4}
                                            maximumDecimalsCustom={4}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        bottom={
                            <div className="d-flex fundcorp__card-bottom">
                                <I18n
                                    id={`${FORM_ID}.position.byFund.column.lastValueShare`}
                                    componentProps={{ className: "fundcorp__fund-cuota-info" }}
                                />
                                <FormattedAmount
                                    className="fundcorp__fund-cuota-data"
                                    quantity={ultimoVCPValor}
                                    notBold
                                    currency={currency}
                                    minimumDecimalsCustom={7}
                                    maximumDecimalsCustom={7}
                                />
                                <I18n
                                    id={`${FORM_ID}.position.byFund.column.date`}
                                    componentProps={{ className: "fundcorp__fund-cuota-date-info" }}
                                />
                                <FormattedDate date={ultimoVCPFecha} />
                            </div>
                        }
                        rightContent={
                            <div className="d-flex fundcorp__card-right-content">
                                <div className="fundcorp__card-balance">
                                    <I18n
                                        id={`${FORM_ID}.position.byFund.column.balance`}
                                        componentProps={{ className: "fundcorp__balance-info" }}
                                    />
                                    <FormattedAmount
                                        currency={currency}
                                        className="data-amount"
                                        quantity={cuotapartesValuadas}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-link p-0 m-0"
                                    onClick={(e) => e.stopPropagation()}>
                                    {this.renderContexMenu(item)}
                                </button>
                            </div>
                        }
                    />
                </CardContainer>
            );
        }
        return (
            <CardContainer className="px-3">
                <Card
                    className="fundcorp__card-mobile"
                    title={
                        <div className="fundcorp__card-title">
                            <div>
                                <I18n
                                    id={`${FORM_ID}.position.byShare.title`}
                                    componentProps={{ className: "fundcorp__name-fund-info" }}
                                />
                                <span>{cuotapartistaNombre}</span>
                            </div>
                            <div className={isMobileNative ? "fundcorp__type mobile-native" : "fundcorp__type"}>
                                <I18n
                                    id={`${FORM_ID}.position.byFund.column.account`}
                                    componentProps={{ className: "fundcorp__account-fund-info" }}
                                />
                                {cuotapartistaNumero}
                            </div>
                            <hr className="mr-0 mb-0" />
                        </div>
                    }
                    content={
                        <div className="fundcorp__card-content pt-0">
                            <div className="d-flex fundcorp__card-quantity-amount">
                                <I18n
                                    id={`${FORM_ID}.position.byFund.column.shareAmountFound`}
                                    componentProps={{ className: "fundcorp__quantity-info" }}
                                />
                                <FormattedAmount
                                    className="fundcorp__quantity-data"
                                    quantity={cuotapartesTotales}
                                    noCurrency
                                    minimumDecimalsCustom={4}
                                    maximumDecimalsCustom={4}
                                    notBold
                                />
                            </div>
                            <div className="d-flex fundcorp__card-last-amount">
                                <I18n
                                    id={`${FORM_ID}.position.byShare.column.lastValueShare`}
                                    componentProps={{ className: "fundcorp__last-amount-info" }}
                                />
                                <FormattedAmount
                                    className="fundcorp__last-amount-data"
                                    quantity={ultimoVCPValor}
                                    notBold
                                    currency={currency}
                                    minimumDecimalsCustom={7}
                                    maximumDecimalsCustom={7}
                                />
                            </div>
                            <div className="d-flex fundcorp__card-date">
                                <I18n
                                    id={`${FORM_ID}.position.byFund.column.dateFound`}
                                    componentProps={{ className: "fundcorp__date-info" }}
                                />
                                <FormattedDate date={ultimoVCPFecha} className="fundcorp__date-data" />
                            </div>
                        </div>
                    }
                    rightMenu={
                        <div className="d-flex fundcorp__card-right-content">
                            <button type="button" className="btn btn-link p-0 m-0" onClick={(e) => e.stopPropagation()}>
                                {this.renderContexMenu(item)}
                            </button>
                        </div>
                    }
                    rightContent={
                        <div className="d-flex fundcorp__card-right-content">
                            <div className="fundcorp__card-balance">
                                <FormattedAmount
                                    currency={currency}
                                    className="data-amount"
                                    quantity={cuotapartesValuadas}
                                />
                            </div>
                        </div>
                    }
                />
            </CardContainer>
        );
    };

    render() {
        const { positions, filtersData } = this.props;

        if (!positions || !filtersData) {
            return null;
        }

        if (!(positions && positions.length > 0)) {
            return (
                <div className="text-center no-more-data">
                    <div className="illustration-wrapper">
                        <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                    </div>
                    <p className="text-lead">
                        <I18n id={`${FORM_ID}.empty.message`} />
                    </p>
                </div>
            );
        }

        if (filtersData.positionsType === "byShare") {
            return <>{positions.map((position) => this.renderListForPositionByShare(position))}</>;
        }

        return <>{positions.map((position) => this.renderListForPositionByFund(position))}</>;
    }
}

export default FundcorpPositionList;
