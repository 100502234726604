import React from "react";
import Heading from "pages/_components/Heading";
import { string, shape, bool } from "prop-types";
import * as accountUtils from "util/accounts";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";

export default function ServicePaymentsSummaryData({ summaryInfo, FORM_ID, signature, summary }) {
    ServicePaymentsSummaryData.propTypes = {
        summaryInfo: shape({}).isRequired,
        summary: shape({}).isRequired,
        FORM_ID: string.isRequired,
        signature: bool,
    };
    ServicePaymentsSummaryData.defaultProps = {
        signature: false,
    };

    const PreparedBy = () => (
        <p>
            {summary?.creatorFullName} - <FormattedDate date={summary?.creationDate} dateFormat="dd/MM/yyyy" showTime />
        </p>
    );
    const SignBy = () => (
        <p>
            {summary?.signatures.map((object) => (
                <>
                    {object?.userFirstName.concat(" ", object?.userLastName)} -{" "}
                    <FormattedDate date={object?.creationDateTime} dateFormat="dd/MM/yyyy" showTime />
                    <br />
                </>
            ))}
        </p>
    );

    return (
        <>
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between summary-data"
                label={`${FORM_ID}.rubros.label`}
                labelClassName="f-size-55"
                data={summaryInfo?.selectedRubro?.description}
                dataClassName="deta-desc f-size-55 font-light"
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between summary-data"
                label={`${FORM_ID}.entities.label`}
                labelClassName="f-size-55"
                data={summaryInfo?.selectedEntity?.description}
                dataClassName="deta-desc f-size-55 font-light"
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between summary-data"
                label={`${FORM_ID}.cpe.label`}
                labelClassName="f-size-55"
                data={summaryInfo?.selectedCPE}
                dataClassName="deta-desc f-size-55 font-light"
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between summary-data"
                label={`${FORM_ID}.amountToPay`}
                labelClassName="f-size-55"
                data={
                    <FormattedAmount
                        fontClassName="f-size-55 font-light"
                        currency="ARS"
                        quantity={summaryInfo?.selectedAmountToPay}
                        medium
                        notBold
                    />
                }
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between summary-data"
                label={`${FORM_ID}.expiration`}
                labelClassName="f-size-55"
                data={
                    summaryInfo?.statusCPE?.handleBaseDebts ? (
                        <FormattedDate date={summaryInfo?.selectedConceptExpirationDate} dateFormat="dd/MM/yyyy" />
                    ) : (
                        summaryInfo?.selectedConceptExpirationDate
                    )
                }
                dataClassName="deta-desc f-size-55 font-light"
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between summary-data"
                label={`${FORM_ID}.concept`}
                labelClassName="f-size-55"
                data={summaryInfo?.selectedConcept?.description}
                dataClassName="deta-desc f-size-55 font-light"
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between summary-data"
                label={`${FORM_ID}.account.label`}
                labelClassName="f-size-55"
                data={
                    summaryInfo?.selectedDebitAccount &&
                    accountUtils.getIncomingDebinAccountName(summaryInfo.selectedDebitAccount)
                }
                dataClassName="data-desc w-50 break f-size-55 font-light text-right"
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between summary-data"
                label={`${FORM_ID}.paymentReference`}
                labelClassName="f-size-55"
                data={summaryInfo?.paymentReference}
                dataClassName="deta-desc f-size-55 font-light"
            />
            {signature && (
                <>
                    <I18n
                        component="h3"
                        id="accounts.new.account.signatures.label"
                        componentProps={{ className: "font-size-25-px" }}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex"
                        label="accounts.new.account.signatures.preparedBy.label"
                        labelClassName="f-size-55"
                        data={<PreparedBy />}
                        dataClassName="deta-desc f-size-55 font-light"
                    />
                    {summaryInfo?.signatures?.length > 0 && (
                        <>
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                label="accounts.new.account.signatures.signBy.label"
                                labelClassName="f-size-55"
                                data={<SignBy />}
                                dataClassName="deta-desc f-size-55 font-light"
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}
