import { store } from "store";
import { replaceParams } from "./i18n";

const parseTimeInMillis = (time) => {
    const value = time.slice(0, -1);

    switch (time.slice(-1).toLowerCase()) {
        case "d":
            return value * 24 * 60 * 60 * 1000;
        case "h":
            return value * 60 * 60 * 1000;
        case "m":
            return value * 60 * 1000;
        case "s":
            return value * 1000;
        default:
            // Si no es ninguna de esas letras asumimos que ya esta en milisegundos
            return time;
    }
};

export const getTimeInMillis = (id, defaultValue) => {
    const { items } = store.getState().config;
    if (items && items[id]) {
        return parseTimeInMillis(items[id]);
    }

    return defaultValue;
};

export const get = (id, defaultValue = null, params = {}) => {
    const { items } = store.getState().config;
    if (items && items[id]) {
        return replaceParams(items[id], params);
    }

    return defaultValue;
};

export const getArray = (id, defaultValue = []) => {
    const { items } = store.getState().config;
    if (items && items[id]) {
        return items[id].split("|");
    }

    return defaultValue;
};

export const getInteger = (id, defaultValue = null) => {
    const result = get(id, defaultValue);

    return result ? parseInt(result, 10) : result;
};

export const getArrayInteger = (id, defaultValue = []) => {
    const { items } = store.getState().config;
    if (items && items[id]) {
        const arrayNumberInString = items[id].split("|");
        const result = arrayNumberInString.map((numberStr) => parseInt(numberStr, 10));

        return result;
    }

    return defaultValue;
};

export const delay = (millis) => new Promise((resolve) => setTimeout(() => resolve(true), millis));

export const setRecaptchaLang = (lang) => {
    window.recaptchaOptions = {
        lang,
    };
};

export const getBoolean = (id, defaultValue = false) => {
    const value = get(id);
    return value ? value.toLowerCase() === "true" : defaultValue;
};

export const getDefaultPosition = (id) => {
    const value = get(id);
    if (value) {
        const values = value.split("|");
        return { lat: values[0], lng: values[1] };
    }
};
