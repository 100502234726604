import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { arrayOf, func, number, oneOfType, shape, string, boolean } from "prop-types";
import { actions as adminActions } from "reducers/administration/common/administrationTicket";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";

class SignaturesScheme extends Component {
    static propTypes = {
        functionalGroups: arrayOf(string),
        schemeName: string.isRequired,
        signatureLevelsCounts: oneOfType([
            shape({
                A: number,
            }),
            shape(
                configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                    const result = res;
                    result[signLevel] = number;

                    return result;
                }, {}),
            ),
        ]),
        signatureType: string,
        signatureDispatch: boolean,
        topAmount: shape({
            amount: oneOfType([number, string]),
            period: string,
        }),
        dispatch: func.isRequired,
    };

    static defaultProps = {
        functionalGroups: [],
        signatureLevelsCounts: null,
        signatureType: "",
        signatureDispatch: false,
        topAmount: {
            amount: 0,
            period: "daily",
        },
    };

    componentDidMount() {
        this.chargeData(this.signersCount());
    }

    getFunctionalName = (key) => key && i18nUtils.get(`administration.signatures.functionalGroups.${key}`);

    generateFunctionalGroupsData = () => {
        const { functionalGroups } = this.props;
        return functionalGroups.reduce((a, b) => a + this.getFunctionalName(b)?.concat("\n "), "");
    };

    chargeData = (scheme) => {
        const { dispatch } = this.props;
        const {
            schemeName,
            signatureLevelsCounts,
            signatureType,
            signatureDispatch,
            functionalGroups,
            topAmount,
        } = this.props;

        const hasAmount = topAmount && schemeName === "advanced" && signatureType === "AMOUNT";

        const extraData = {
            extraData: {
                scheme: signatureLevelsCounts && {
                    label:
                        schemeName === "medium"
                            ? `administration.signatures.create.${schemeName}.confirm.signersCount`
                            : `administration.signatures.create.${schemeName}.confirm.schemeReference`,
                    value: scheme,
                },
                transactionDispatch: {
                    label: `administration.signatures.confirm.dispatcher`,
                    value: i18nUtils.get(`administration.signatures.confirm.dispatcher.${signatureDispatch}`),
                },
                transactionType: signatureType && {
                    label: `administration.signatures.create.${schemeName}.confirm.transactionType`,
                    value: i18nUtils.get(`administration.signatures.create.signatureType.${signatureType}`),
                },
                functionalGroups: Array.isArray(functionalGroups) &&
                    functionalGroups.length > 0 && {
                        label: `administration.signatures.functionalGroups.label`,
                        value: this.generateFunctionalGroupsData(),
                    },
                topAmount: hasAmount && {
                    label: `administration.signatures.create.topAmount.label`,
                    value: {
                        currency: configUtils.get("core.masterCurrency"),
                        frequency: i18nUtils.get("administration.signatures.create.advanced.topAmountPerPeriod", null, {
                            period: i18nUtils.get(
                                `administration.signatures.transactions.capFrequency.${topAmount.period}`,
                            ),
                        }),
                        quantity: topAmount.amount,
                    },
                },
            },
        };

        dispatch(adminActions.setExtraData(extraData));
    };

    renderFunctionalGroups = (functionalGroups) => (
        <Heading.DataGroup
            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
            labelClassName="data-label-medium"
            dataClassName="data-desc"
            label="administration.signatures.functionalGroups.label"
            data={functionalGroups.map((functionalGroup) => (
                <div key={functionalGroup}>
                    <I18n id={`administration.signatures.functionalGroups.${functionalGroup}`} />
                </div>
            ))}
            notMarginYLabel
        />
    );

    renderSchemeReference = (schemeName) => (
        // <div className="transfer-block">
        <Heading.DataGroup
            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
            labelClassName="data-label-medium"
            dataClassName="data-desc"
            label={
                schemeName === "medium"
                    ? `administration.signatures.create.${schemeName}.confirm.signersCount`
                    : `administration.signatures.create.${schemeName}.confirm.schemeReference`
            }
            data={<p className="data-desc m-0">{this.signersCount()}</p>}
            notMarginYLabel
        />
        // </div>
    );

    renderTopAmount = ({ amount, period }) => (
        // <div className="transfer-block">
        <Heading.DataGroup
            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
            labelClassName="data-label-medium"
            dataClassName="data-desc"
            notMarginYLabel
            label="administration.signatures.create.topAmount.label"
            data={
                <Fragment>
                    <FormattedAmount
                        className="data-amount"
                        currency={configUtils.get("core.masterCurrency")}
                        frequency={i18nUtils.get("administration.signatures.create.advanced.topAmountPerPeriod", null, {
                            period: i18nUtils.get(`administration.signatures.transactions.capFrequency.${period}`),
                        })}
                        quantity={amount}
                        notBold
                    />
                </Fragment>
            }
        />
        // </div>
    );

    renderTransactionType = (administrationScheme, signatureType) => (
        // <div className="transfer-block">
        <Heading.DataGroup
            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
            labelClassName="data-label-medium"
            dataClassName="data-desc"
            label={`administration.signatures.create.${administrationScheme}.confirm.transactionType`}
            data={
                <p className="data-desc m-0">
                    {i18nUtils.get(`administration.signatures.create.signatureType.${signatureType}`)}
                </p>
            }
            notMarginYLabel
        />
        // </div>
    );

    renderTransactionDispatch = (administrationScheme, signatureDispatch) => (
        // <div className="transfer-block">
        <Heading.DataGroup
            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
            labelClassName="data-label-medium"
            dataClassName="data-desc"
            label="administration.signatures.confirm.dispatcher"
            data={
                <p className="data-desc m-0">
                    {i18nUtils.get(`administration.signatures.confirm.dispatcher.${signatureDispatch}`)}
                </p>
            }
            notMarginYLabel
        />
        // </div>
    );

    signersCount = () => {
        const { schemeName, signatureLevelsCounts } = this.props;

        return schemeName === "medium"
            ? `${signatureLevelsCounts.A}`
            : Object.keys(signatureLevelsCounts).reduce(
                  (result, key) => result + key.toString().repeat(signatureLevelsCounts[key]),
                  "",
              );
    };

    render() {
        const {
            functionalGroups,
            schemeName,
            signatureLevelsCounts,
            signatureType,
            topAmount,
            signatureDispatch,
        } = this.props;

        return (
            // <Container className="align-items-center flex-grow" gridClassName="form-content">
            <Col className="px-0" sm={12} md={12} lg={12} xl={12}>
                {signatureLevelsCounts && this.renderSchemeReference(schemeName)}
                {this.renderTransactionDispatch(schemeName, signatureDispatch)}
                {signatureType && this.renderTransactionType(schemeName, signatureType)}
                {functionalGroups && functionalGroups.length > 0 && this.renderFunctionalGroups(functionalGroups)}
                {topAmount && signatureType === "AMOUNT" && this.renderTopAmount(topAmount)}
            </Col>
            // </Container>
        );
    }
}

export default SignaturesScheme;
