import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { func, string } from "prop-types";

import { selectors as enrollmentSelectors } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";
import { Mixpanel } from "util/clickstreaming";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import GeneralMsg from "pages/_components/GeneralMsg";

import * as i18n from "util/i18n";

class Error extends Component {
    handleClick = () => {
        const { dispatch, error } = this.props;

        dispatch(loginActions.reset());
        Mixpanel.track("enrollment.error", { error });
    };

    cta = () => (
        <Button bsStyle="primary" onClick={this.handleClick} label="global.back" />
    )

    render() {
        const { error, isDesktop } = this.props;
        
        return (
            <MainContainer className="main-container">
                <Container className="flex-grow align-items-center container--layout" rowClassName="justify-content-center p-0">
                    <Col xs={12} className="col col-12">
                        <div className="error-container">
                            <GeneralMsg
                                description={i18n.get(error)}
                                imagePath="images/iconRedError.svg"
                                callToAction={this.cta()}
                                addContainerWhite
                                isDesktop={isDesktop}
                                className="d-flex flex-column align-items-center w-100"
                                rowCn="justify-content-center p-0"
                            />
                        </div>
                    </Col>
                </Container>
            </MainContainer>
        );
    }
}

Error.propTypes = {
    dispatch: func.isRequired,
    error: string.isRequired,
};

const mapStateToProps = (state) => ({
    error: enrollmentSelectors.getError(state),
});

export default connect(mapStateToProps)(Error);
