import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import Head from "pages/_components/Head";
import Logo from "pages/login/_components/Logo";
import { func, shape, bool } from "prop-types";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import I18n from "./I18n";

class SupportInfo extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        hasActiveSession: bool,
        history: shape({ goBack: func }),
        propsGrid: shape({}).isRequired,
        isMobile: bool,
    };

    static defaultProps = {
        hasActiveSession: false,
        history: null,
        isMobile: false,
    };

    backButtonAction = () => {
        const { dispatch, hasActiveSession, history } = this.props;
        return hasActiveSession ? history.goBack() : dispatch(push("/"));
    };

    renderLogoMobile = () => <Logo className="svg-image logo logo-mobile-size" logoColor />;

    renderContent = () => {
        const { isMobile } = this.props;

        return (
            <>
                <div>
                    {isMobile && (
                        <I18n
                            id="global.support"
                            accessibilityTextId="login.step4.termsAndCondiciones.label.a11yss"
                            component="h2"
                            componentProps={{ className: "modal-header text-center" }}
                        />
                    )}
                    <I18n
                        componentProps={{ className: "border-top max-width-600 mx-auto px-3" }}
                        component="p"
                        id="global.support.message"
                    />
                </div>
            </>
        );
    };

    render() {
        const { hasActiveSession, isMobile, propsGrid } = this.props;

        return hasActiveSession ? (
            <>
                <div className="admin-detail-head px-0">
                    <Head onBack={this.backButtonAction} />
                    <Head title="global.support" titleClassName="mx-auto" />
                </div>
                {this.renderContent()}
            </>
        ) : (
            <GridLayoutExternal isDesktop={!isMobile} {...propsGrid} showLogoState>
                <Head
                    onClose={this.backButtonAction}
                    title={!isMobile && "global.support"}
                    headerClassName={isMobile ? "login-background-color-white" : "recover-pass-desktop h-auto mb-2"}
                    centerElement={isMobile && this.renderLogoMobile}
                    fixed
                />
                {this.renderContent()}
            </GridLayoutExternal>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default withRouter(connect(mapStateToProps)(SupportInfo));
