import React, { Component } from "react";
import { string, func, bool } from "prop-types";

import classNames from "classnames";

import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";

class DownloadButton extends Component {
    static propTypes = {
        isMobile: bool,
        handleClick: func.isRequired,
        handleClickMessage: string.isRequired,
        isFetchingExport: bool,
        csvDownload: bool,
        xlsDownload: bool,
        downloadImageWhite: bool,
        exportDisabled: bool,
        iconButton: bool,
        toolbarItemClassName: string,
        btnClass: string,
    };

    static defaultProps = {
        isMobile: false,
        isFetchingExport: false,
        csvDownload: false,
        xlsDownload: false,
        downloadImageWhite: false,
        exportDisabled: false,
        iconButton: false,
        toolbarItemClassName: "",
        btnClass: "",
    };

    render() {
        const {
            handleClick,
            isFetchingExport,
            handleClickMessage,
            csvDownload,
            xlsDownload,
            exportDisabled,
            downloadImageWhite,
            isMobile,
            iconButton,
            toolbarItemClassName,
            btnClass,
        } = this.props;

        return (
            <div
                className={classNames("toolbar-item", toolbarItemClassName, { "toolbar-item-right": isMobile })}
                key="exportList">
                <Dropdown
                    image={`images/download_bold${downloadImageWhite ? "_white" : ""}.svg`}
                    label={!isMobile && !iconButton && (handleClickMessage || "global.download")}
                    buttonClass={`btn btn-outline download ${btnClass} ${
                        isMobile || iconButton ? "button-white-header" : "ml-0"
                    }`}
                    fetching={!isMobile && isFetchingExport}
                    exportDisabled={exportDisabled}
                    bsStyle={isMobile ? "link" : undefined}
                    imageStyle={classNames({ "toolbar-btn-image-mobile": isMobile })}
                    pullDown
                    {...this.props}>
                    <Button
                        onClick={() => handleClick("pdf")}
                        label="global.filetype.pdf"
                        className="dropdown__item-btn
                                   btn-b-none dropdown__item-btn-custom
                                   justify-content-start my-0 ml-0"
                    />
                    {xlsDownload && (
                        <Button
                            onClick={() => handleClick("xls")}
                            label="global.filetype.xls"
                            className="dropdown__item-btn
                                   btn-b-none
                                   dropdown__item-btn-custom
                                   justify-content-start my-0 ml-0"
                        />
                    )}
                    {csvDownload && (
                        <Button
                            onClick={() => handleClick("csv")}
                            label="global.filetype.csv"
                            className="dropdown__item-btn
                                       btn-b-none dropdown__item-btn-custom
                                       justify-content-start my-0 ml-0"
                        />
                    )}
                </Dropdown>
            </div>
        );
    }
}

export default DownloadButton;
