import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, arrayOf, bool, string } from "prop-types";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import Button from "pages/_components/Button";
import Yup from "yup";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import { actions as checkActions } from "reducers/checks";
import { selectors as accountsSelectors, actions as accountsActions } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import Productselector from "pages/forms/_components/_fields/Productselector";
import { push } from "react-router-redux";
import classNames from "classnames";
import PageLoading from "pages/_components/PageLoading";

const FORM_ID = "echeqs";

class CustodyEcheq extends Component {
    static propTypes = {
        handleSubmit: func.isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        fetching: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, accounts } = this.props;
        if (!accounts.length) {
            dispatch(accountsActions.listAccounts());
        }
    }

    render() {
        const { handleSubmit, isDesktop, accounts, fetching } = this.props;

        const accountsPesos = accounts?.filter((account) => account.currency === "0");

        return (
            <PageLoading loading={fetching}>
                <Container className="px-2">
                    <div className="form-section-title admin-content-center my-5">
                        {isDesktop && <I18n component="h2" id={`${FORM_ID}.action.custody.title`} />}
                    </div>
                    <Form
                        className="above-the-fold"
                        onSubmit={(e) => {
                            handleSubmit(e);
                            e.stopPropagation();
                        }}>
                        <Container className="flex-grow align-items-center" gridClassName="form-content">
                            <Col xs={12} md={8} className={classNames({ "px-0": isDesktop })}>
                                <Field
                                    name="custody"
                                    component={Productselector}
                                    idField="custody"
                                    customPlaceholder="Seleccioná"
                                    data={{
                                        emptyOptionLabel: "",
                                        options: accountsPesos,
                                    }}
                                    isRequired
                                    mode="edit"
                                    labelText="custody"
                                    idForm={FORM_ID}
                                    isDesktop={isDesktop}
                                    blackLabel
                                    withoutBalance
                                />
                            </Col>

                            <Col md={8} className={classNames({ "px-0": isDesktop, "mt-5": true })}>
                                <Button type="submit" bsStyle="primary" label="global.select" />
                            </Col>
                        </Container>
                    </Form>
                </Container>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    accounts: accountsSelectors.getAccounts(state),
    fetching: accountsSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ custody: "" }),
        validationSchema: () =>
            Yup.object().shape({
                custody: Yup.string().required(i18n.get("accounts.new.account.field.error.required")),
            }),
        handleSubmit: (filters, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(checkActions.setEcheqsSpecialAttribute(filters.custody));
            dispatch(push("/echeqs/action/confirmation"));
        },
    }),
)(CustodyEcheq);
