import { globalTypes } from "reducers/types/global";

export const types = {
    SAVE_EDITED_PERMISSIONS: "permissions/SAVE_EDITED_PERMISSIONS",
};

export const INITIAL_STATE = {
    editedPermissions: {},
};

export default (state = INITIAL_STATE, action = {}) => {
    const { editedPermissions } = action;

    switch (action.type) {
        case types.SAVE_EDITED_PERMISSIONS:
            return {
                ...state,
                editedPermissions,
            };
        case globalTypes.CLEAN_UP:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export const actions = {
    saveEditedPermissions: (editedPermissions) => ({
        type: types.SAVE_EDITED_PERMISSIONS,
        editedPermissions,
    }),
};

export const selectors = {
    getEditedPermissions: (state) => state.permissions.editedPermissions,
};
