import React, { Component } from "react";
import { string, shape, bool, func, objectOf, arrayOf, node } from "prop-types";

import * as i18nUtils from "util/i18n";
import Password from "pages/_components/fields/credentials/Password";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { actions as tokenActions, selectors as tokenSelectors } from "reducers/token";
import { selectors as sessionSelectors } from "reducers/session";
import { connect } from "react-redux";
import isTokenActive from "util/token";

const styles = {
    hidden: {
        opacity: 0,
        position: "absolute",
        zIndex: "-9999",
    },
};

class Credential extends Component {
    static propTypes = {
        type: string,
        field: shape({}),
        copyEnabled: bool,
        dispatch: func.isRequired,
        tokenNumber: string,
        form: shape({
            errors: objectOf({}),
            touched: objectOf({}),
        }).isRequired,
        loggedUser: shape({
            previousLoginInfo: arrayOf(
                shape({
                    idUser: string,
                }),
            ),
        }).isRequired,
        inputProps: shape({}),
        withoutOtpTxt: node,
        otpTxt: node,
    };

    static defaultProps = {
        type: "password",
        field: {},
        copyEnabled: true,
        tokenNumber: null,
        inputProps: { autofocus: false },
        withoutOtpTxt: null,
        otpTxt: null,
    };

    componentDidMount = () => {
        const { type, dispatch, loggedUser, form, field } = this.props;

        const otpMobile = type === "otp" && isTokenActive() && loggedUser;

        if (otpMobile) {
            dispatch(tokenActions.tokenQueryCredential("Token", loggedUser?.userId, form, field));
        }
    };

    updateCode = () => {
        const { dispatch, loggedUser, form, field } = this.props;
        dispatch(tokenActions.tokenGeneratedValue(loggedUser?.userId, form, field));
        // repeat animation in 0.5 seconds
        return [true, 500];
    };

    render() {
        const { type, inputProps, withoutOtpTxt, otpTxt, ...props } = this.props;

        if (type === "password") {
            return <Password autoFocus={inputProps.autofocus} autoComplete="off" copyEnabled {...props} />;
        }

        if (type === "otp") {
            const {
                field: { name },
                loggedUser,
            } = this.props;

            return (
                <React.Fragment>
                    <div>
                        {isTokenActive() && loggedUser ? (
                            <div>
                                {withoutOtpTxt}
                                <div className="hide">
                                    <CountdownCircleTimer
                                        isPlaying
                                        duration={40}
                                        trailColor={[["#18e2a7"]]}
                                        colors={[["#d9d9d9"]]}
                                        size={260}
                                        strokeLinecap="square"
                                        strokeWidth={9}
                                        onComplete={() => this.updateCode()}
                                    />
                                </div>
                            </div>
                        ) : (
                            <>
                                {otpTxt}
                                <div>
                                    {/*
                                    The objetive of this input is to avoid the differents
                                    behaviors of autocomplete="off" of browsers.
                                    */}
                                    <input
                                        name={name}
                                        style={styles.hidden}
                                        type="password"
                                        aria-hidden
                                        tabIndex="-1"
                                    />
                                    <Password
                                        autoComplete="new-password"
                                        inputmode="numeric"
                                        pattern="[0-9]{0,6}"
                                        copyEnabled
                                        {...props}
                                        warningMessage={i18nUtils.get("form.credential.otp.warning")}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </React.Fragment>
            );
        }

        if (type === "mobilePhone") {
            return <Password autoComplete="off" inputmode="numeric" pattern="[0-9]{0,6}" copyEnabled {...props} />;
        }

        if (type === "otpMobile") {
            return (
                <Password
                    autoComplete="off"
                    inputmode="numeric"
                    pattern="[0-9]{0,6}"
                    copyEnabled
                    {...props}
                    warningMessage={i18nUtils.get("form.credential.otp.warning")}
                />
            );
        }

        if (type === "tokenPass") {
            const {
                field: { name },
            } = this.props;

            return (
                <React.Fragment>
                    <input name={name} style={styles.hidden} type="password" aria-hidden tabIndex="-1" />
                    <Password
                        autoComplete="off"
                        inputmode="numeric"
                        pattern="[0-9]{0,6}"
                        copyEnabled
                        {...props}
                        warningMessage={i18nUtils.get("settings.tokenPassword.password.warning")}
                        autofocus
                    />
                </React.Fragment>
            );
        }

        return null;
    }
}
const mapStateToProps = (state) => ({
    tokenNumber: tokenSelectors.getTokenNumber(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(Credential);
