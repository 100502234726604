import React from "react";
import Button from "pages/_components/Button";
import Col from "react-bootstrap/lib/Col";
import { func, string } from "prop-types";
import { push } from "react-router-redux/actions";

function HaveCodeButton({ label, to, dispatch, className }) {
    const handleClick = () => {
        if (dispatch) {
            dispatch(push(to));
        }
    };

    return (
        <Col sm={12} md={12} lg={12} className={`${className} col col-12`}>
            <Button type="button" bsStyle="outline" label={label} onClick={handleClick} />
        </Col>
    );
}

HaveCodeButton.defaultProps = {
    label: "recoveryPassword.step1.userHasCode",
    className: null,
};

HaveCodeButton.propTypes = {
    label: string,
    dispatch: func.isRequired,
    to: string.isRequired,
    className: string,
};

export default HaveCodeButton;
