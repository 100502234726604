import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { groupsOfUserTicketSelectors } from "reducers/administration";
import { groupsOfUserTicketActions } from "reducers/administration/advanced";
import { shape, arrayOf } from "prop-types";

import GroupsOfUserTicket from "pages/administration/_components/tickets/GroupsOfUserTicket";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";
import PageLoading from "pages/_components/PageLoading";

class GroupsOfuserTicketContent extends Component {
    static propTypes = {
        actions: shape({}).isRequired,
        match: shape({}).isRequired,
        groups: arrayOf(shape({})).isRequired,
    };

    state = {
        finishLoading: false,
    };

    componentDidMount() {
        const { actions, match } = this.props;
        actions.loadGroupsOfUserTicketRequest(match.params.idTransaction);
    }

    render() {
        const { finishLoading } = this.state;
        const { groups } = this.props;
        if (groups.length > 0 && !finishLoading) {
            this.setState({ finishLoading: true });
        }

        return (
            <PageLoading loading={!finishLoading}>
                <AdministrationTicket {...this.props}>
                    <GroupsOfUserTicket {...this.props} />
                </AdministrationTicket>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    user: groupsOfUserTicketSelectors.getUser(state),
    fetching: groupsOfUserTicketSelectors.isFetching(state),
    groups: groupsOfUserTicketSelectors.getGroups(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(groupsOfUserTicketActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsOfuserTicketContent);
