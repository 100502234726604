import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { bool, func, arrayOf } from "prop-types";
import { connect } from "react-redux";

import Button from "pages/_components/Button";
import Card from "pages/_components/Card/Card";
import InfoTag from "pages/_components/InfoTag";
import FormattedAmount from "pages/_components/FormattedAmount";
import Pagination from "pages/_components/pagination/Pagination";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { selectors as multipleTransferSelectors } from "reducers/multiple-transfers";

function MultipleTransferModal({ isDesktop, showDetailModal, setShow, list }) {
    const [nextPageNumber, setNextPageNumber] = useState(1);
    const [transfers, setTransfers] = useState([]);

    const rowsPerPage = configUtils.getInteger("administration.rowsPerPage", 10);

    const totalPage = () => Math.ceil(list?.length / rowsPerPage);

    const onClose = () => {
        setShow(false);
        setNextPageNumber(1);
        setTransfers(isDesktop ? list.slice(0, rowsPerPage) : list);
    };

    useEffect(() => {
        if (isDesktop) {
            setTransfers(list.slice(0, rowsPerPage));
        } else {
            setTransfers(list);
        }
        // eslint-disable-next-line
    }, [list]);

    const handleLoadMoreClick = (pageNumber) => {
        if (pageNumber > nextPageNumber) {
            setTransfers(list.slice(nextPageNumber * rowsPerPage, pageNumber * rowsPerPage));
        } else {
            setTransfers(list.slice((pageNumber - 1) * rowsPerPage, (nextPageNumber - 1) * rowsPerPage));
        }

        setNextPageNumber(pageNumber);
    };

    return (
        <Modal show={showDetailModal} className="transfer-multiple__modal max-height-100">
            <Modal.Header>
                <Button
                    bsStyle="link"
                    image="images/cross.svg"
                    className="toolbar-btn view-close mh-auto mt-2 mr-0 justify-content-end"
                    onClick={onClose}
                />
            </Modal.Header>
            {isDesktop ? (
                <Modal.Body className={`d-flex flex-column ${!isDesktop && "pt-0"}`}>
                    {transfers?.map((transfer) => (
                        <Card
                            id={transfer.lineNumber}
                            isDesktop={isDesktop}
                            className={!isDesktop && "mb-0"}
                            hrClass="ml-0"
                            title={
                                <div className="transfer-multiple__modal-head">
                                    <span className="transfer-multiple__modal-title">{`${i18n.get(
                                        "multiple.transfers.modal.document.label",
                                    )} ${transfer.document}`}</span>
                                    <InfoTag
                                        type="info"
                                        message={`${i18n.get("multiple.transfers.modal.cbu.label")} ${transfer.cbu}`}
                                        tagBackground="#E1F5FC"
                                        capitalize
                                        moreStyles={{
                                            alignSelf: "flex-start",
                                            color: "black",
                                            fontFamily: "Graphik-Regular",
                                        }}
                                    />
                                    {transfer.reference ? (
                                        <InfoTag
                                            type="info"
                                            message={`${i18n.get("multiple.transfers.modal.reference.label")} ${
                                                transfer.reference
                                            }`}
                                            tagBackground="#E8EAF6"
                                            capitalize
                                            moreStyles={{
                                                alignSelf: "flex-start",
                                                color: "black",
                                                fontFamily: "Graphik-Regular",
                                            }}
                                        />
                                    ) : (
                                        undefined
                                    )}
                                </div>
                            }
                            content={`${i18n.get("multiple.transfers.modal.concept.label")} ${transfer.concept}-${
                                transfer.conceptDescription
                            }`}
                            rightContent={<FormattedAmount currency={transfer.currency} quantity={transfer.amount} />}
                        />
                    ))}
                    <div className="d-flex w-100 justify-content-end">
                        <div className="d-flex pr-2">
                            <Pagination
                                totalPages={totalPage()}
                                pageNumber={nextPageNumber}
                                action={handleLoadMoreClick}
                            />
                        </div>
                    </div>
                </Modal.Body>
            ) : (
                <Modal.Body className={`d-flex flex-column ${!isDesktop && "pt-0"}`}>
                    {transfers?.map((transfer) => (
                        <Card
                            id={transfer.lineNumber}
                            isDesktop={isDesktop}
                            className={!isDesktop && "mb-0"}
                            hrClass="ml-0"
                            title={
                                <div className="transfer-multiple__modal-head">
                                    <span className="transfer-multiple__modal-title">{`${i18n.get(
                                        "multiple.transfers.modal.document.label",
                                    )} ${transfer.document}`}</span>
                                    <InfoTag
                                        type="info"
                                        message={`${i18n.get("multiple.transfers.modal.cbu.label")} ${transfer.cbu}`}
                                        tagBackground="#E1F5FC"
                                        capitalize
                                        moreStyles={{
                                            alignSelf: "flex-start",
                                            color: "black",
                                            fontFamily: "Graphik-Regular",
                                        }}
                                    />
                                </div>
                            }
                            content={
                                <div className="transfer-multiple__modal-content">
                                    <hr className="ml-0" />
                                    {transfer.reference ? (
                                        <div className="transfer-multiple__modal-reference">
                                            <span className="transfer-multiple__modal-reference-info">{`${i18n.get(
                                                "multiple.transfers.modal.reference.label",
                                            )}`}</span>
                                            <span className="transfer-multiple__modal-reference-data">{`${transfer.reference}`}</span>
                                        </div>
                                    ) : (
                                        undefined
                                    )}
                                    <div className="transfer-multiple__modal-concept">
                                        <span className="transfer-multiple__modal-concept-info">
                                            {`${i18n.get("multiple.transfers.modal.concept.label")}`}
                                        </span>
                                        <span className="transfer-multiple__modal-concept-data">
                                            {`${transfer.concept}-${transfer.conceptDescription}`}
                                        </span>
                                    </div>
                                </div>
                            }
                            rightContent={
                                <div className="transfer-multiple__modal-right">
                                    <FormattedAmount currency={transfer.currency} quantity={transfer.amount} />
                                </div>
                            }
                        />
                    ))}
                </Modal.Body>
            )}
        </Modal>
    );
}

MultipleTransferModal.propTypes = {
    isDesktop: bool.isRequired,
    showDetailModal: bool.isRequired,
    setShow: func.isRequired,
    list: arrayOf().isRequired,
};

const mapStateToProps = (state) => ({
    showDetailModal: multipleTransferSelectors.showDetailModal(state),
});

export default connect(mapStateToProps)(MultipleTransferModal);
