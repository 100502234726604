import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { goBack, replace } from "react-router-redux";
import { bool, func, string, shape } from "prop-types";
import Col from "react-bootstrap/lib/Col";

import { actions as loginActions } from "reducers/login";
import { actions as onboardingActions, selectors as onboardingSelectors } from "reducers/onboarding";
import { actions as notificationActions } from "reducers/notification";
import * as cameraUtils from "util/camera";
import * as i18nUtils from "util/i18n";
import Container from "pages/_components/Container";
import Webcam from "pages/_components/Webcam";
import withExchangeToken from "pages/_components/withExchangeToken";
import Head from "pages/_components/Head";
import { Mixpanel } from "util/clickstreaming";
import { MAIN_ONBOARDING_ROUTE } from "./Step0";
import OnboardingStepper, { orientations } from "./_components/OnboardingStepper";

const DOCUMENT_SIDE_FRONT = "front";

class Step2 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        documentType: string,
        isMobile: bool,
        isMobileNative: bool,
        match: shape({}).isRequired,
        email: string,
        exchangeToken: string,
    };

    static defaultProps = {
        documentType: "",
        isMobile: false,
        isMobileNative: false,
        email: null,
        exchangeToken: null,
    };

    state = {
        onBackClick: false,
    };

    componentDidMount() {
        const { dispatch, documentType, email } = this.props;

        if (!documentType) {
            dispatch(replace(MAIN_ONBOARDING_ROUTE));
        }

        if (email !== null) {
            dispatch(onboardingActions.setEmail(email));
        }
    }

    componentWillUnmount() {
        const { onBackClick } = this.state;
        const { isMobileNative } = this.props;

        if (isMobileNative && !onBackClick) {
            cameraUtils.stop();
        }

        this.setState({ onBackClick: false });
    }

    handleTakePicture = (picture) => {
        const {
            dispatch,
            match: { path },
            exchangeToken,
        } = this.props;
        const documentSide = path.split("/").pop();

        Mixpanel.track(`onboarding.step2.${documentSide}`);
        dispatch(onboardingActions.takePicture("step2", documentSide, picture, exchangeToken));
    };

    handleTakePictureError = () => {
        const { dispatch } = this.props;

        dispatch(onboardingActions.takePictureError());
        dispatch(
            notificationActions.showNotification(i18nUtils.get("onboarding.takePicture.error"), "error", [
                "onboarding",
            ]),
        );
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        this.setState({ onBackClick: true });

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const {
            isMobile,
            isMobileNative,
            match: { path },
            documentType,
        } = this.props;
        const documentSide = path.split("/").pop();
        const frontReverse =
            documentSide === DOCUMENT_SIDE_FRONT
                ? i18nUtils.get("onboarding.step2.front")
                : i18nUtils.get("onboarding.step2.reverse");

        return (
            <Fragment>
                <Head
                    titleText={
                        documentType === "passport"
                            ? `${i18nUtils.get("onboarding.step2.passportTitle")}`
                            : `${i18nUtils.get("onboarding.step2.idTitle")}: ${frontReverse}`
                    }
                    onBack={isMobile && documentSide === DOCUMENT_SIDE_FRONT && this.onHeaderBack}
                    onClose={isMobile && this.onHeaderClose}
                />
                <div className="view-page">
                    {!isMobile && <OnboardingStepper currentStep={2} className="onboarding-steps" />}

                    <div className="view-content">
                        <main className="main-container">
                            <div className="above-the-fold">
                                <Container className="container--layout flex-grow align-items-center">
                                    <Col className="col col-12">
                                        <Webcam
                                            direction={cameraUtils.directions.BACK}
                                            handleTakePicture={this.handleTakePicture}
                                            handleTakePictureError={this.handleTakePictureError}
                                            isMobile={isMobile}
                                            isMobileNative={isMobileNative}
                                            camera="landscape"
                                        />
                                    </Col>
                                </Container>
                                {isMobile && (
                                    <Container className="container--layout  align-items-center">
                                        <Col className="col col-12">
                                            <OnboardingStepper currentStep={2} orientation={orientations.horizontal} />
                                        </Col>
                                    </Container>
                                )}
                            </div>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    documentType: onboardingSelectors.getDocumentType(state),
});

export default compose(connect(mapStateToProps), withExchangeToken)(Step2);
