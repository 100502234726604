import React, { Component } from "react";
import { string, number as numberType, bool } from "prop-types";

import * as i18nUtils from "util/i18n";

import ListItemAccount from "pages/_components/listItem/widgetAccount/ListItemAccount";
import { maskedAccountNumber } from "util/accounts";

class AccountsListItem extends Component {
    static propTypes = {
        number: string.isRequired,
        productAlias: string,
        idProduct: string,
        productType: string.isRequired,
        amountLabel: string.isRequired,
        countableBalance: numberType.isRequired,
        balance: numberType.isRequired,
        hideAmountLabel: bool,
        showLink: bool,
    };

    static defaultProps = {
        productAlias: null,
        idProduct: null,
        hideAmountLabel: false,
        showLink: true,
    };

    productType = () => {
        const { productType } = this.props;

        if (productType === "CA") {
            return i18nUtils.get("accounts.productType.savings");
        }

        return i18nUtils.get("accounts.productType.checking");
    };

    render() {
        const {
            productAlias,
            number,
            balance,
            amountLabel = i18nUtils.get("accounts.availableBalance"),
            idProduct,
            hideAmountLabel,
            showLink,
            countableBalance,
            ...props
        } = this.props;

        const numberOfuscado = maskedAccountNumber(number);

        const reference = `${i18nUtils.get("global.number")} ${numberOfuscado}`;
        const productName = productAlias || numberOfuscado;
        const productTypeTitle = i18nUtils.get("desktop.widgets.account");
        return (
            <ListItemAccount
                {...props}
                idProduct={idProduct}
                productTypeTitle={productTypeTitle}
                title={this.productType()}
                name={productName}
                reference={reference}
                amount={balance}
                realBalance={countableBalance}
                amountLabel={!hideAmountLabel && amountLabel}
                path={showLink ? `/accounts/${idProduct}` : null}
            />
        );
    }
}

export default AccountsListItem;
