import { CURRENCY_CODE } from "constants.js";
import React from "react";
import { bool, func, shape, arrayOf, string } from "prop-types";

import { compose } from "redux";
import { connect } from "react-redux";
import Yup from "yup";
import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import { Col } from "react-bootstrap";

import { actions as fundcorpActions } from "reducers/fundcorp";

import { selectors as sessionSelectors } from "reducers/session";
import { maskedAccountNumber } from "util/accounts";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import Container from "pages/_components/Container";
import Selector from "pages/_components/fields/formik/Selector";
import FormattedAmount from "pages/_components/FormattedAmount";
import { downloadUrl } from "util/download";
import TermsAndCoditionsLink from "./TermsAndCoditionsLink";

function RegisterShareAccountForm({ isDesktop, fetching, idForm, values, accounts, handleBack }) {
    const termsAndConditions = () => configUtils.get("frontend.investment.funds.TyC");

    const handleDownloadTyC = () => {
        downloadUrl(termsAndConditions());
    };

    const getLabelAccount = (elem) => {
        if (isDesktop) {
            return (
                <>
                    <span className="control-label">
                        {`${elem.productAlias || ""} ${maskedAccountNumber(elem.number)} ${elem.productType} ${i18n.get(
                            `currency.label.${elem.currency}`,
                        )}`}
                    </span>
                    <FormattedAmount quantity={elem.balance} currency={elem.currency} notBold />
                </>
            );
        }
        return (
            <div style={{ display: "grid" }}>
                <div className="control-label transfer-dropdown-account-font">
                    {`${elem.productAlias || ""} ${maskedAccountNumber(elem.number)} ${elem.productType} ${i18n.get(
                        `currency.label.${elem.currency}`,
                    )}`}
                </div>
                <FormattedAmount
                    quantity={elem.balance}
                    currency={elem.currency}
                    className="transfer-dropdown-account-font gray-color"
                    notBold
                />
            </div>
        );
    };

    const getAccountsByCurrency = (currency) =>
        accounts
            ?.filter((account) => account.currency === currency)
            .map((account) => ({
                value: account.idProduct,
                label: getLabelAccount(account),
            }));

    return (
        <Form>
            <div className="above-the-fold">
                <Container
                    className="flex-grow align-items-center container-white my-2 py-4"
                    gridClassName="form-content pb-2 px-0">
                    <Col sm={12} md={9} lg={6} xl={7}>
                        <Col xs={12} className={classNames("align-items-center px-md-3", { "px-0": isDesktop })}>
                            <Field
                                component={Selector}
                                options={getAccountsByCurrency(CURRENCY_CODE.ARS)}
                                idForm={idForm}
                                name="accountArs"
                            />
                        </Col>
                        <Col xs={12} className={classNames("align-items-center px-md-3", { "px-0": isDesktop })}>
                            <Field
                                component={Selector}
                                options={getAccountsByCurrency(CURRENCY_CODE.USD)}
                                idForm={idForm}
                                name="accountUsd"
                            />
                        </Col>
                        <Col xs={12} className="justify-content-left my-2">
                            <Field
                                name="termsAndConditions"
                                idForm={idForm}
                                label={<TermsAndCoditionsLink url={termsAndConditions()} isDesktop={isDesktop} />}
                                component={Checkbox}
                                checked={values.termsAndConditions}
                                value={values.termsAndConditions}
                                disabled={termsAndConditions() === "#"}
                                formGroupTextClassName="form-checkbox-link-label"
                            />
                        </Col>
                        {!isDesktop ? (
                            <Col xs={12} className="justify-content-left my-2">
                                <Button
                                    type="button"
                                    bsStyle="outline"
                                    label="global.download"
                                    image="images/download_bold.svg"
                                    onClick={() => handleDownloadTyC()}
                                    disabled={termsAndConditions() === "#"}
                                />
                            </Col>
                        ) : (
                            undefined
                        )}
                    </Col>
                </Container>
            </div>
            <Container className="flex-grow align-items-center my-2" gridClassName="form-content">
                <Col lg={4} md={9} sm={12}>
                    <Col
                        className={classNames("d-flex aligns-items-center px-0 transfer-bottom-buttons", {
                            "mt-8": !isDesktop,
                        })}
                        sm={12}>
                        {isDesktop ? <Button bsStyle="outline" onClick={handleBack} label="global.back" /> : undefined}
                        <Button
                            type="submit"
                            bsStyle="primary"
                            label="global.continue"
                            disabled={
                                !values.termsAndConditions || (values.accountUsd === "" && values.accountArs === "")
                            }
                            loading={fetching}
                        />
                    </Col>
                </Col>
            </Container>
        </Form>
    );
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

RegisterShareAccountForm.propTypes = {
    isDesktop: bool,
    fetching: bool,
    idForm: string.isRequired,
    values: shape({}).isRequired,
    accounts: arrayOf(shape({})).isRequired,
    handleBack: func.isRequired,
};

RegisterShareAccountForm.defaultProps = {
    isDesktop: true,
    fetching: false,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            accountArs: "",
            accountUsd: "",
            termsAndConditions: false,
        }),
        validationSchema: ({ idForm }) =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    accountArs: !values.accountUsd
                        ? Yup.string().required(i18n.get(`${idForm}.field.error.required`))
                        : Yup.mixed().nullable(),
                    accountUsd: !values.accountArs
                        ? Yup.string().required(i18n.get(`${idForm}.field.error.required`))
                        : Yup.mixed().nullable(),
                    termsAndConditions: Yup.bool().oneOf([true], i18n.get(`${idForm}.termsAndConditions.error`)),
                }),
            ),

        handleSubmit: ({ accountArs, accountUsd }, formikBag) => {
            const { dispatch, accounts } = formikBag.props;
            const bankAccountArs = accounts.find((a) => a.idProduct === accountArs);
            const bankAccountUsd = accounts.find((a) => a.idProduct === accountUsd);
            const accountLabel = (a) =>
                `${a.productAlias || ""} ${maskedAccountNumber(a.number)} ${a.productType} ${i18n.get(
                    `currency.label.${a.currency}`,
                )}`;

            const labelArs = !bankAccountArs ? null : accountLabel(bankAccountArs);
            const labelUsd = !bankAccountUsd ? null : accountLabel(bankAccountUsd);

            dispatch(
                fundcorpActions.createShareAccount(
                    {
                        accountArs,
                        accountUsd,
                        labelArs,
                        labelUsd,
                    },
                    formikBag,
                ),
            );
        },
    }),
)(RegisterShareAccountForm);
