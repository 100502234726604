/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
import { ThemeProvider, ThemeProvider2, Tooltip } from "@ui-kit/components/index.es";
import { SALARY_PAYMENT_ID_FORM } from "constants.js";
import Button from "pages/_components/Button";
import CardContainer from "pages/_components/Card/CardContainer";
import Container from "pages/_components/Container";
import Check from "pages/_components/fields/Checkbox";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Notification from "pages/_components/Notification";
import Pagination from "pages/_components/pagination/Pagination";
import Spinner from "pages/_components/Spinner";
import CardTransactionItem from "pages/transactions/_components/CardTransactionItem";
import { array, arrayOf, bool, func, instanceOf, number, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import { findScheduleDesc } from "util/scheduler";
import { dynamicSort } from "util/transaction";
import TransactionAditionalInfo from "./TransactionAditionalInfo";
import TransactionFrom from "./TransactionFrom";
import TransactionTo from "./TransactionTo";

class List extends Component {
    static propTypes = {
        defaultFilters: shape({
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            filter: string,
        }),
        addMultipleSelectedRow: func.isRequired,
        activitySelected: string.isRequired,
        status: string.isRequired,
        dispatch: func.isRequired,
        pageNumber: number,
        totalPages: number.isRequired,
        onlyPendings: bool,
        columnFilter: shape({}).isRequired,
        filters: shape({
            idFilter: string,
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            pageNumber: number,
            minAmount: number,
            maxAmount: number,
        }),
        transactions: oneOfType([null, array]).isRequired,
        isDesktop: bool.isRequired,
        hasMoreData: bool.isRequired,
        showEnvironment: bool,
        itemsAreClickeable: bool,
        selectedTransactionRows: arrayOf().isRequired,
        activeEnvironment: shape({}).isRequired,
        administrationScheme: string.isRequired,
        selectedFilter: string,
        dataSelect: func.isRequired,
        filtered: bool,
        fetching: bool.isRequired,
        multipleSigns: func.isRequired,
        deleteMultipleSigns: func.isRequired,
        montoOperaciones: func.isRequired,
        controlActivity: bool,
        controlARS: number,
        controlUSD: number,
    };

    static defaultProps = {
        defaultFilters: null,
        pageNumber: 1,
        onlyPendings: false,
        filters: null,
        showEnvironment: false,
        itemsAreClickeable: true,
        selectedFilter: undefined,
        filtered: false,
        controlActivity: false,
        controlARS: 0,
        controlUSD: 0,
    };

    componentDidMount = () => {
        const { dataSelect, columnFilter } = this.props;
        dataSelect(columnFilter, this.changeFilter);
    };

    fetchMoreTransactions = (pageNumber) => {
        const { defaultFilters, status, dispatch, onlyPendings, isDesktop, selectedFilter } = this.props;
        let { filters } = this.props;
        const pendingDispatch = selectedFilter === "liberador";
        filters = filters ? { ...filters, pageNumber, status } : { ...defaultFilters, pageNumber };
        dispatch(transactionsActions.loadMoreTransactionsRequest(filters, onlyPendings, pendingDispatch, isDesktop));
    };

    onMobileItemCLick = (event, transaction) => {
        const { dispatch, isDesktop } = this.props;
        if (
            transaction.transaction.idForm === SALARY_PAYMENT_ID_FORM &&
            !isDesktop &&
            transaction.transaction.idTransactionStatus === "DRAFT"
        ) {
            event.preventDefault();
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "transactionList",
                ]),
            );
        }
    };

    changeFilter = (filterName, filterDirecion) => {
        const { dispatch } = this.props;
        dispatch(
            transactionsActions.changeColumnFilter({ filterSelected: filterName, directionFilter: filterDirecion }),
        );
    };

    fetchNextTransactions = () => {
        const { pageNumber } = this.props;
        this.fetchMoreTransactions(pageNumber + 1);
    };

    showCheckboxes = () => {
        const { activitySelected, transactions } = this.props;
        if (!activitySelected) {
            return false;
        }
        return (
            transactions.filter((transaction) => transaction.transaction.idActivity !== activitySelected).length === 0
        );
    };

    renderCheckAll = () => {
        const { transactions, filtered, filters } = this.props;
        return (
            <>
                <div className="pending-transactions__select-all">
                    <Check
                        hideLabel
                        id="selectAll"
                        name="selectAll"
                        formGroupClassName="mb-0"
                        checked={transactions.length <= 0 ? null : this.isAllChecked(transactions)}
                        onChange={(e) => {
                            this.selectAll(e.target.checked, transactions);
                        }}
                        disabledCheck={!filtered && !filters?.idActivity}
                    />
                    <I18n
                        componentProps={{ className: `${!filtered && !filters?.idActivity && "opacity-50"}` }}
                        id="transactions.list.filters.selectall.filter"
                    />
                </div>
            </>
        );
    };

    list = () => {
        const {
            transactions,
            itemsAreClickeable,
            showEnvironment,
            isDesktop,
            columnFilter,
            status,
            addMultipleSelectedRow,
            selectedTransactionRows,
            dispatch,
        } = this.props;

        if (columnFilter.filterSelected !== "") {
            transactions.sort(dynamicSort(columnFilter.filterSelected, columnFilter.directionFilter));
        }
        return transactions.map((transaction) => {
            const transactionSelected = selectedTransactionRows.find(
                (selectedTransaction) =>
                    selectedTransaction.transaction.idTransaction === transaction.transaction.idTransaction,
            );

            function checksOptions() {
                return (
                    <div
                        className="table-data pending-transactions-checks"
                        role="button"
                        tabIndex={0}
                        onClick={(e) => {
                            e.preventDefault();
                            if (!transaction?.transaction?.control) {
                                addMultipleSelectedRow(!transactionSelected, transaction);
                            }
                        }}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            if (!transaction?.transaction?.control) {
                                addMultipleSelectedRow(!transactionSelected, transaction);
                            }
                        }}>
                        {!transaction?.transaction?.control && (
                            <Check
                                hideLabel
                                formGroupClassName="mb-0 pending-transactions__check"
                                id={transaction.transaction.idTransaction}
                                name={transaction.transaction.idTransaction}
                                checked={transactionSelected}
                            />
                        )}
                        {transaction?.transaction?.control && (
                            <div>
                                <ThemeProvider2>
                                    <ThemeProvider>
                                        <Tooltip
                                            placement="right"
                                            id={`pending-tooltip-control-${transaction?.transaction?.idTransaction}`}
                                            label={configUtils.get("frontend.mobileSignatureControl.tooltip", "")}>
                                            <Image src="images/infoUserInvite.svg" className="small-icon" />
                                        </Tooltip>
                                    </ThemeProvider>
                                </ThemeProvider2>
                            </div>
                        )}
                    </div>
                );
            }
            return (
                <CardContainer
                    className={`${isDesktop ? "" : "infinite-scroll-height-80-px"}`}
                    key={transaction.transaction.idTransaction}
                    renderAs={itemsAreClickeable ? Link : "div"}
                    onClick={itemsAreClickeable ? (e) => this.onMobileItemCLick(e, transaction) : null}
                    to={
                        itemsAreClickeable
                            ? `/transaction/${transaction.transaction.idTransaction}`
                            : `/transactions/list`
                    }>
                    <CardTransactionItem
                        key={`transaction-${transaction.transaction.idTransaction}`}
                        transaction={transaction}
                        showEnvironment={showEnvironment}
                        filter={status}
                        checks={this.showCheckboxes() ? checksOptions() : null}
                        isDesktop={isDesktop}
                        dispatch={dispatch}
                    />
                </CardContainer>
            );
        });
    };

    signaturesContent = () => {
        const {
            status,
            activeEnvironment,
            selectedTransactionRows,
            fetching,
            multipleSigns,
            deleteMultipleSigns,
            montoOperaciones,
            isDesktop,
            filtered,
            controlActivity,
            controlARS,
            controlUSD,
        } = this.props;

        const pendingTransactionsFunctionalities = configUtils.getBoolean(
            "backend.show.PendingTransactions.functionalities",
            false,
        );
        const hasMultipleSignPermission = activeEnvironment?.permissions["multiple.signatures"] ?? false;
        const hasSomeUnavaliableToSign = selectedTransactionRows?.some(
            (item) => !item.transaction?.signatureAvaliableByUser,
        );
        const hasSomeItemControl = selectedTransactionRows?.some((item) => item.transaction?.control);

        // Realiza control de importe sobre los elementos seleccionados (suma)
        const initialValue = 0;
        let hasControlActivity = false;
        if (controlActivity) {
            const amountArs = selectedTransactionRows?.reduce(
                (prev, curr) =>
                    prev +
                    (curr?.transactionAmounts["0"] ||
                        curr?.transactionAmounts["01"] ||
                        curr?.transactionAmounts["032"] ||
                        0),
                initialValue,
            );
            const amountUsd = selectedTransactionRows?.reduce(
                (prev, curr) =>
                    prev +
                    (curr?.transactionAmounts["2"] ||
                        curr?.transactionAmounts["02"] ||
                        curr?.transactionAmounts["840"] ||
                        0),
                initialValue,
            );

            hasControlActivity = controlActivity && (amountArs > controlARS || amountUsd > controlUSD);
        }

        const multipleSignDisable =
            !hasMultipleSignPermission ||
            status !== "PENDING" ||
            selectedTransactionRows.length < 1 ||
            fetching ||
            hasSomeUnavaliableToSign ||
            hasControlActivity;

        return (
            <>
                {!isDesktop ? (
                    <>
                        {!status ||
                            (status === "PENDING" && (
                                <div className="selected-transactions-signatures">
                                    <div className="selected-transactions-signatures-container">
                                        {this.renderCheckAll()}
                                        <div className="selected-transactions-signatures-content">
                                            <Button
                                                label="transaction.sign"
                                                bsStyle="primary"
                                                className="selected-transactions-signatures-btn-sign"
                                                disabled={multipleSignDisable}
                                                onClick={multipleSigns}
                                            />
                                            <Button
                                                label="global.delete"
                                                bsStyle="primary"
                                                className="selected-transactions-signatures-btn-delete"
                                                disabled={
                                                    status !== "PENDING" ||
                                                    selectedTransactionRows.length < 1 ||
                                                    fetching
                                                }
                                                onClick={deleteMultipleSigns}
                                            />
                                        </div>
                                    </div>
                                    {filtered && <>{montoOperaciones()}</>}
                                </div>
                            ))}
                    </>
                ) : (
                    <>
                        {!status ||
                            (status === "PENDING" && (
                                <div className="selected-transactions-signatures">
                                    <div className="selected-transactions-signatures-container">
                                        {this.renderCheckAll()}
                                        <div className="selected-transactions-signatures-content">
                                            <Button
                                                label="transaction.sign"
                                                bsStyle="primary"
                                                className="selected-transactions-signatures-btn-sign"
                                                disabled={multipleSignDisable}
                                                onClick={multipleSigns}
                                            />
                                            {pendingTransactionsFunctionalities && (
                                                <Button
                                                    label="global.delete"
                                                    bsStyle="primary"
                                                    className="selected-transactions-signatures-btn-delete"
                                                    disabled={
                                                        status !== "PENDING" ||
                                                        selectedTransactionRows.length < 1 ||
                                                        fetching ||
                                                        hasSomeItemControl ||
                                                        hasControlActivity
                                                    }
                                                    onClick={deleteMultipleSigns}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {montoOperaciones()}
                                </div>
                            ))}
                    </>
                )}
            </>
        );
    };

    getAditionalInfo = (transaction) => {
        const { administrationScheme } = this.props;
        const { data, idActivity } = transaction;
        const returnContent = TransactionAditionalInfo?.[idActivity];
        return returnContent ? returnContent(data, administrationScheme) : "";
    };

    getFrom = (transaction) => {
        const { data, idActivity } = transaction;
        const returnContent = TransactionFrom?.[idActivity];
        return returnContent ? returnContent(data) : "";
    };

    getTo = (transaction) => {
        const { data, idActivity } = transaction;
        const returnContent = TransactionTo?.[idActivity];
        return returnContent ? returnContent(data) : "";
    };

    isAllChecked = () => {
        const { transactions, selectedTransactionRows } = this.props;
        // Se fitran las transacciones que tienen control de importe
        const filterTransactions = transactions?.filter((element) => !element.transaction?.control);
        if (filterTransactions.length === 0) {
            return false;
        }
        return filterTransactions.every((transaction) =>
            selectedTransactionRows?.some(
                (selectedTransaction) =>
                    transaction.transaction.idTransaction === selectedTransaction.transaction.idTransaction,
            ),
        );
    };

    selectAll = (checked, transactions) => {
        const { dispatch, selectedTransactionRows } = this.props;
        // Se fitran las transacciones que tienen control de importe
        const filterTransactions = transactions?.filter((element) => !element.transaction?.control);
        for (let i = 0; i < filterTransactions.length; i++) {
            const transaction = filterTransactions[i];
            const selected =
                selectedTransactionRows?.filter(
                    (selectedTransaction) =>
                        selectedTransaction.transaction.idTransaction === transaction.transaction.idTransaction,
                ).length > 0;
            if (checked) {
                if (!selected) {
                    dispatch(transactionsActions.saveSelectedTransactionRows(transaction));
                }
            } else if (selected) {
                dispatch(transactionsActions.removeSelectedTransactionRows(transaction));
            }
        }
    };

    render() {
        const {
            transactions,
            hasMoreData,
            isDesktop,
            totalPages,
            pageNumber,
            itemsAreClickeable,
            addMultipleSelectedRow,
            selectedTransactionRows,
        } = this.props;

        if (!transactions) {
            return null;
        }

        if (!isDesktop) {
            if (!transactions.length) {
                return (
                    <div className="text-center no-more-data" key="noMoreMovements">
                        <div className="illustration-wrapper">
                            <Image src="images/coloredIcons/folder-empty.svg" className="svg-big-icon" />
                        </div>
                        <p className="text-lead">
                            <I18n id="transactions.list.none" />
                        </p>
                    </div>
                );
            }

            const endOfListItem = (
                <div className="table-row table-end" key="noMoreTransactions">
                    <I18n id="transactions.list.noMoreTransactions" />
                </div>
            );

            const transactionItemList = transactions.map((item) => {
                const currency = Object.keys(item.transactionAmounts);
                const amount = item.transactionAmounts[currency];
                const showAmount = amount > 0;
                const {
                    data: { checkBookChecksAmount },
                } = item.transaction;
                const dataList = [
                    {
                        key: 1,
                        label: "transactions.list.header.transactionName",
                        data: <span>{item.transaction.activityName}</span>,
                    },
                    {
                        key: 2,
                        label: "transactions.list.header.creationDate",
                        data: <FormattedDate showTime date={item.transaction.creationDateTime} />,
                    },
                ];

                const from = this.getFrom(item.transaction);
                if (from) {
                    dataList.push({
                        key: 3,
                        label: "transaction.list.header.from",
                        data: from,
                    });
                }

                const to = this.getTo(item.transaction);
                if (to) {
                    dataList.push({
                        key: 4,
                        label: "transaction.list.header.to",
                        data: to,
                    });
                }

                const aditionalInfo = this.getAditionalInfo(item.transaction);
                if (aditionalInfo) {
                    dataList.push({
                        key: 5,
                        label: "transaction.list.header.aditionalInfo",
                        data: aditionalInfo,
                    });
                }

                if (showAmount) {
                    dataList.push({
                        key: 6,
                        label: "transactions.list.header.amount",
                        data:
                            item.transactionAmounts[currency] !== 0 &&
                            currency &&
                            (showAmount && currency ? (
                                <FormattedAmount
                                    currency={currency}
                                    quantity={item.transactionAmounts[currency]}
                                    cleanClassName
                                    className="span"
                                />
                            ) : (
                                "-"
                            )),
                    });
                } else if (checkBookChecksAmount) {
                    dataList.push({
                        key: 6,
                        label: "transactions.list.header.quantity",
                        data: checkBookChecksAmount,
                    });
                }

                return {
                    id: item.transaction.idTransaction,
                    showCheck: this.showCheckboxes(),
                    linkTo: itemsAreClickeable
                        ? `/transaction/${item.transaction.idTransaction}`
                        : `/transactions/list`,
                    checkBoxOptions: {
                        id: item.transaction.idTransaction,
                        name: item.transaction.idTransaction,
                        checked: selectedTransactionRows.find(
                            (element) => element.transaction.idTransaction === item.transaction.idTransaction,
                        ),
                        disabled: false,
                        onChange: (e) => {
                            addMultipleSelectedRow(e.target.checked, item);
                        },
                    },
                    infoTag: item.transaction.programed && {
                        type: "default",
                        message: findScheduleDesc(item.transaction?.program?.frequency?.frequencyUnit),
                    },
                    dataList,
                };
            });

            return (
                <>
                    <Notification scopeToShow="transactionList" />
                    <div
                        className="containerDetails container-fluid px-0"
                        style={{
                            flexGrow: 1,
                            overflow: "auto",
                            transform: `translate3d(0, 0px, 0)`,
                            transition: "transform .3s ease",
                        }}>
                        <InfiniteScroll
                            dataLength={transactionItemList.length}
                            next={this.fetchNextTransactions}
                            hasMore={hasMoreData}
                            loader={<Spinner />}
                            endMessage={endOfListItem}>
                            {this.signaturesContent()}
                            {this.list()}
                        </InfiniteScroll>
                    </div>
                </>
            );
        }

        return (
            <>
                <Container className="flex-grow scrollable ">
                    <Col md={12} className="px-0">
                        {this.signaturesContent()}

                        <div
                            className="d-flex w-100"
                            ref={(tableRef) => {
                                this.child = tableRef;
                            }}>
                            {transactions.length ? (
                                <div className="w-100">{this.list()}</div>
                            ) : (
                                <div className="text-center no-more-data" key="noMoreMovements">
                                    <div className="illustration-wrapper">
                                        <Image src="images/coloredIcons/folder-empty.svg" className="svg-big-icon" />
                                    </div>
                                    <p className="text-lead">
                                        <I18n id="transactions.list.none" />
                                    </p>
                                </div>
                            )}
                        </div>
                    </Col>
                </Container>
                <div className="d-flex w-100 justify-content-end">
                    <div className="d-flex mt-1">
                        <Pagination
                            totalPages={totalPages}
                            pageNumber={pageNumber}
                            action={this.fetchMoreTransactions}
                        />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactions: transactionsSelectors.getTransactions(state),
    hasMoreData: transactionsSelectors.getHasMoreData(state),
    fetching: transactionsSelectors.getFetching(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    totalPages: transactionsSelectors.getTotalPages(state),
    filters: transactionsSelectors.getFilters(state),
    status: transactionsSelectors.getStatusType(state),
    columnFilter: transactionsSelectors.getColumnFilter(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    controlActivity: transactionsSelectors.getControlActivity(state),
    controlARS: transactionsSelectors.getControlARS(state),
    controlUSD: transactionsSelectors.getControlUSD(state),
});

export default connect(mapStateToProps)(List);
