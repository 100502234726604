/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, createElement } from "react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import { bool, func, instanceOf, number, shape, string } from "prop-types";
import { connect } from "react-redux";
import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import I18n from "pages/_components/I18n";
import AmountFilter from "pages/transactions/_components/AmountFilter";
import PeriodFilter from "pages/transactions/_components/PeriodFilter";
import LastUpdateFilter from "pages/transactions/_components/LastUpdateFilter";
import StatusFilter from "pages/transactions/_components/StatusFilter";
import TransactionFilter from "pages/transactions/_components/TransactionFilter";
import EnvironmentFilter from "pages/transactions/_components/EnvironmentFilter";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import moment from "moment";
import FilterSelect from "../FilterSelect";

const components = {
    creationDate: PeriodFilter,
    lastUpdate: LastUpdateFilter,
    amount: AmountFilter,
    environment: EnvironmentFilter,
    status: StatusFilter,
    transaction: TransactionFilter,
};

const DEFAULT_ORDER_BY = "submit_date_time DESC";

class HiddenFilters extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool,
        onlyPendings: bool,
        pendingDispatch: bool,
        getList: func.isRequired,
        isDesktop: bool.isRequired,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        filters: shape({
            dateFrom: instanceOf(Date),
            dateTo: instanceOf(Date),
            minAmount: shape({ amount: number, currency: number }).isRequired,
            maxAmount: shape({ amount: number, currency: number }).isRequired,
            pageNumber: number,
            filter: string,
            idActivity: string,
        }),
    };

    static defaultProps = {
        fetching: false,
        onlyPendings: false,
        pendingDispatch: false,
        filters: null,
    };

    state = {
        selectedFilter: "",
        cleanFilterOrder: false,
    };

    componentWillMount() {
        this.setState({ selectedFilter: this.chargeSelectedFilter() });
    }

    chargeSelectedFilter = () => {
        const { filters, dispatch } = this.props;

        const filter = filters?.filter;

        const selectedFilter = filter && components[filter] ? filter : "lastUpdate";

        if (!filter && !filters?.dateFrom) {
            const limitMonths = configUtils.getInteger("frontend.historyQuery.allowedPeriod.Months", 6);
            dispatch(transactionsActions.changeStatusType(null));
            dispatch(transactionsActions.setFilters(null));
            const dateFrom = new Date();
            dateFrom.setMonth(dateFrom.getMonth() - limitMonths);
            const dateTo = new Date();
            const defaultFilters = {
                orderBy: "submit_date_time DESC",
                dateFrom,
                dateTo,
                dateType: "lastUpdate",
                pageNumber: 1,
                filter: "lastUpdate",
            };
            dispatch(transactionsActions.loadListRequest(defaultFilters, false, false, true));
        }

        return selectedFilter;
    };

    compareByLabel = (a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        return a.label === b.label ? 0 : 1;
    };

    handleChange = ({ value }) => {
        const { dispatch } = this.props;
        this.setState({ selectedFilter: value });
        const minDate = configUtils.getInteger("frontend.historyQuery.allowedPeriod.Months", 6);
        const dateFrom = new Date();
        dateFrom.setMonth(dateFrom.getMonth() - minDate);
        const dateTo = new Date();
        const datefilters = {
            dateFrom,
            dateTo,
            filter: value,
        };
        dispatch(transactionsActions.setFilters(datefilters));
        this.setDefaultOrderBy();
    };

    setOrder = (orderByName, orderDirection) => {
        const { getList } = this.props;
        const order = orderDirection === "ascendent" ? " ASC" : " DESC";
        getList(orderByName + order);
    };

    setDefaultOrderBy = () => {
        this.setState({ cleanFilterOrder: true });
    };

    renderFilter = () => {
        const { selectedFilter } = this.state;

        const { dispatch, fetching, onlyPendings, pendingDispatch, filters, activeEnvironment } = this.props;
        let props = {
            ...filters,
            dispatch,
            isDesktop: true,
            fetching,
            onlyPendings,
            pendingDispatch,
            className: "cmf-button-width",
            buttonLabel: "transactions.list.filters.button.filter",
            showMonthYearDropdown: false,
            withSelector: true,
            selectedFilter,
            activeEnvironment,
            rightButton: true,
            orderBy: filters?.orderBy || DEFAULT_ORDER_BY,
        };

        if (selectedFilter === "amount") {
            props = { ...props };
        }

        if (selectedFilter === "transaction") {
            props = { ...props, activity: filters?.idActivity };
        }

        if (selectedFilter === "creationDate" || selectedFilter === "lastUpdate") {
            if (filters === null || !filters?.dateFrom) {
                return null;
            }
            const minDate = configUtils.getInteger("frontend.historyQuery.allowedPeriod.Months", 6);
            props = { ...props, minDate: moment().add(minDate * -1, "months"), maxDate: moment() };
        }

        return selectedFilter && createElement(components[selectedFilter], props);
    };

    render() {
        const { selectedFilter, cleanFilterOrder } = this.state;
        const showOrderOptionFunctionality = configUtils.getBoolean("frontend.historyQuery.showOrderOption", false);

        const { activeEnvironment, isDesktop } = this.props;

        let options = [
            {
                value: "status",
                label: i18n.get("transactions.filters.searchBy.status"),
            },
            {
                value: "transaction",
                label: i18n.get("transactions.filters.searchBy.transaction"),
            },
            {
                value: "creationDate",
                label: i18n.get("transactions.filters.searchBy.creationDate"),
            },
            {
                value: "amount",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
            {
                value: "lastUpdate",
                label: i18n.get("transactions.filters.searchBy.lastUpdate"),
            },
        ];

        if (activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE) {
            options = options.concat({
                value: "environment",
                label: i18n.get("accounts.movements.filters.searchBy.client"),
            });
        }

        options = options.sort(this.compareByLabel);

        const orderByOptions = [
            {
                value: "id_activity",
                label: i18n.get("accounts.movements.filters.searchBy.transaction"),
            },
            {
                value: "total_amount_data",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
            {
                value: "creation_date_time",
                label: i18n.get("transactions.filters.searchBy.creationDate"),
            },
            {
                value: "submit_date_time",
                label: i18n.get("transactions.filters.searchBy.lastUpdate"),
            },
        ];

        const orderOptions = [
            {
                id: "0",
                label: <I18n id="accounts.movements.filters.searchBy.upward" />,
            },
            {
                id: "1",
                label: <I18n id="accounts.movements.filters.searchBy.falling" />,
            },
        ];

        return (
            <div>
                <Row className="filters">
                    <Col sm={12} md={2} lg={3}>
                        <div className="form-group form-group--select">
                            <div className="form-group-text">
                                <label htmlFor="searchBy" className="control-label">
                                    <I18n id="accounts.movements.filters.searchBy" />
                                </label>
                            </div>
                            <div className="input-group historic-list-filter">
                                <div style={{ flex: 1 }}>
                                    <Select
                                        name="searchBy"
                                        clearable={false}
                                        searchable
                                        onChange={this.handleChange}
                                        options={options}
                                        value={selectedFilter}
                                        optionClassName="needsclick"
                                        placeholder={i18n.get("transactions.filters.placeholder").toUpperCase()}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={10} lg={9} className="px-0">
                        {this.renderFilter()}
                    </Col>
                    {!showOrderOptionFunctionality ? null : (
                        <>
                            <Col sm={12} className="mt-2">
                                <FilterSelect
                                    orderByOptions={orderByOptions}
                                    orderOptions={orderOptions}
                                    isDesktop={isDesktop}
                                    changeFilter={this.setOrder}
                                    defaultOrderBy={orderByOptions.find(({ value }) => value === "submit_date_time")}
                                    defaultOrder="descendent"
                                    cleanFilter={cleanFilterOrder}
                                    setCleanFilter={(value) => this.setState({ cleanFilterOrder: value })}
                                />
                            </Col>
                        </>
                    )}
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    filters: transactionsSelectors.getHistoryFilters(state),
});

export default connect(mapStateToProps)(HiddenFilters);
