import React from "react";
import { Modal } from "react-bootstrap";
import { bool, func } from "prop-types";

import * as configUtil from "util/config";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";

function FixedTermModal({ showModal, handleCancel, handleGoToSite }) {
    return (
        <Modal show={showModal} className="fixed-term__modal modal-container">
            <Modal.Header className="">
                <Modal.Title componentClass="div" className="text-align-left w-100">
                    <I18n
                        defaultValue={configUtil.get("plazoFijoWeb.Boton.Nombre")}
                        component="h3"
                        componentProps={{ className: "session-to-expire text-align-left" }}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <I18n id="" defaultValue={configUtil.get("plazoFijoWeb.Descripcion.Texto")} component="p" />
            </Modal.Body>
            <Modal.Footer style={{ width: "100%" }}>
                <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <Button
                        bsStyle="link"
                        onClick={handleCancel}
                        label="global.cancel"
                        style={{ width: "130px", margin: 0, padding: 0 }}
                    />
                    <Button
                        bsStyle="link"
                        label="fixedTerm.web.button.goToSite"
                        onClick={handleGoToSite}
                        style={{ width: "164px", margin: 0, padding: 0 }}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    );
}

FixedTermModal.propTypes = {
    showModal: bool,
    handleCancel: func,
    handleGoToSite: func,
};

FixedTermModal.defaultProps = {
    showModal: false,
    handleCancel: func.isRequired,
    handleGoToSite: func.isRequired,
};

export default FixedTermModal;
