import React, { Component } from "react";
import { shape, string, func, number } from "prop-types";

import Image from "pages/_components/Image";
import List, { withList } from "pages/_components/List";
import * as utilsI18n from "util/i18n";

class TemplateItem extends Component {
    static propTypes = {
        item: shape({
            name: string.isRequired,
        }).isRequired,
        onDelete: func.isRequired,
        num: number.isRequired,
    };

    handleClick = (event) => {
        const { item, onDelete } = this.props;
        event.stopPropagation();
        onDelete(item);
    };

    render() {
        const { item, num } = this.props;
        return (
            <List.Item num={num} className="list-item--deleteable" role="option" name="Plantillas">
                {(Button) => (
                    <div className="list-item-inner without-border-bottom">
                        <Button
                            className="btn btn-quiet background-color-gray-light"
                            aria-describedBy="ItemBtnDescription">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}>
                                <div className="data-wrapper-flex">
                                    <Image
                                        className="svg-wrapper"
                                        src="images/template-icon.svg"
                                        styles={{ width: "24px", height: "24px", color: "#707070" }}
                                    />
                                    <span className="visually-hidden">{utilsI18n.get("forms.templates.apply")}</span>
                                    <div style={{ marginLeft: "1rem" }} className="template-font">
                                        {item.name}
                                    </div>
                                </div>

                                <button
                                    type="button"
                                    className="close"
                                    aria-label={`${utilsI18n.get("file.upload.input.labelButtonRemoveItem")} ${
                                        item.name
                                    }`}
                                    onClick={this.handleClick}>
                                    <Image
                                        className="svg-wrapper"
                                        src="images/cancel_black.svg"
                                        styles={{ width: "24px", height: "24px" }}
                                    />
                                </button>
                            </div>
                        </Button>
                    </div>
                )}
            </List.Item>
        );
    }
}

export default withList(TemplateItem);
