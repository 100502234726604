/* eslint-disable import/no-unresolved */
import React from "react";
import { bool, func } from "prop-types";
import { Modal } from "react-bootstrap";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { isDesktop } from "react-device-detect";
import { string } from "yup";

function ModalEnvironments({ environment, isVisible, cancelClick, submitClick }) {
    return (
        <>
            {isDesktop ? (
                <div className="modal-container">
                    <Modal show={isVisible} className="messages__modal" dialogClassName="messages__modal">
                        <Modal.Header className="mt-0">
                            <Image src="images/warning-modal.svg" />
                            <Modal.Title componentClass="div" className="mt-4">
                                <I18n
                                    id="communications.environments.title.modal"
                                    component="h3"
                                    componentProps={{ className: "messages__text-title my-0" }}
                                />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="mt-4">
                            {i18n.get(`communications.environments.description.modal`, null, {
                                ENVIRONMENT: environment,
                            })}
                        </Modal.Body>
                        <Modal.Footer className="my-0 w-100">
                            <div className="d-flex w-100 mt-4 justify-content-center">
                                <Button
                                    className="btn-primary my-0 communications__btn-environment-modal"
                                    label="global.accept"
                                    onClick={submitClick}
                                />
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            ) : (
                <div className="modal-container">
                    <Modal show={isVisible} className="messages__modal" dialogClassName="messages__modal">
                        <Modal.Header className="mt-0 pt-4">
                            <Button className="messages__dropdown-btn" onClick={cancelClick}>
                                <Image src="images/rectangle-dropdown.svg" />
                            </Button>
                            <Image className="mt-5" src="images/warning-modal.svg" />
                            <Modal.Title componentClass="div" className="mt-3 px-4">
                                <I18n
                                    id="communications.environments.title.modal"
                                    component="h3"
                                    componentProps={{ className: "messages__text-title my-0" }}
                                />
                            </Modal.Title>
                            <Modal.Body className="mt-4 px-4">
                                {i18n.get(`communications.environments.description.modal`, null, {
                                    ENVIRONMENT: environment,
                                })}
                            </Modal.Body>
                        </Modal.Header>
                        <Modal.Footer className="my-0 w-100">
                            <div className="d-flex flex-column my-5 px-4">
                                <Button
                                    className="btn-primary my-0 communications__btn-environment-modal"
                                    label="global.accept"
                                    onClick={submitClick}
                                />
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </>
    );
}

ModalEnvironments.propTypes = {
    environment: string.isRequired,
    isVisible: bool.isRequired,
    cancelClick: func.isRequired,
    submitClick: func.isRequired,
};

export default ModalEnvironments;
