import { call, put, takeLatest, select } from "redux-saga/effects";
import { types } from "reducers/ecomexIframe";
import * as ecomexIframe from "middleware/ecomexIframe";
import { actions as notificationActions } from "reducers/notification";

const sagas = [
    takeLatest(types.SHOW_SNACKBAR, showNot),
    takeLatest(types.ENTER_REQUESTS_PRE, enterRequestsRedirect),
    takeLatest(types.REQUEST_HISTORY_PRE, requestHistory),
];

export default sagas;

function* showNot() {
    yield put(
        notificationActions.showNotification("tienes transacciones pendientes de token", "error", [
            "pages.ecomexIframe",
        ]),
    );
    yield put({ type: types.OK_NOT });
}

function* enterRequestsRedirect() {
    const response = yield call(ecomexIframe.loadEnterRequestCredentials);

    if (response.data?.code === "COR020W") {
        yield put({ type: types.ENTER_REQUESTS_FAILURE, error: response.data.data.message });
        yield put(notificationActions.showNotification(response.data.data.message, "error", ["desktop"]));
    } else {
        const { token, payload } = response.data.data;

        yield put({
            type: types.ENTER_REQUESTS_SUCCESS,
            token,
            payload,
        });
    }
}

function* requestHistory() {
    const response = yield call(ecomexIframe.loadRequestHistoryCredentials);

    if (response.data?.code === "COR020W") {
        yield put({ type: types.ENTER_REQUESTS_FAILURE, error: response.data.data.message });
        yield put(notificationActions.showNotification(response.data.data.message, "error", ["desktop"]));
    } else {
        const { token, payload } = response.data.data;

        yield put({
            type: types.REQUEST_HISTORY_SUCCESS,
            token,
            payload,
        });
    }
}
