import { maskedAccountNumber } from "util/accounts";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";

const getRescueDeadlineLabel = (rescatePlazo) => {
    const formattedRescatePlazo = rescatePlazo.toString().toLowerCase();
    switch (formattedRescatePlazo) {
        case "0":
        case "inmediato":
            return i18n.get("fund.deadline.0");
        case "1":
            return i18n.get("fund.deadline.1");
        case "2":
            return i18n.get("fund.deadline.2");
        default:
            return `${rescatePlazo} dias`;
    }
};

export const getIbsAccount = (loggedUserAccounts, monetaryAccount) => {
    if (!loggedUserAccounts || loggedUserAccounts.length < 0 || !monetaryAccount) {
        return {};
    }
    const account = loggedUserAccounts.find((userAccount) => userAccount.cbu === monetaryAccount.cbu);
    return account;
};

export const getProductLabel = (ibsAccount) => {
    if (!ibsAccount || !ibsAccount?.number || !ibsAccount?.idProduct) {
        return i18n.get("fund.operation.subscribe.debitAccount.noAccount");
    }

    const { productAlias, number, productType, currency } = ibsAccount;
    return `${productAlias || ""} ${maskedAccountNumber(number)} ${productType} ${i18n.get(
        `currency.label.${currency}`,
    )}`;
};

export const isEnabledMvp = (mvp) => configUtils.getBoolean(`frontend.show.${mvp.toUpperCase()}.functionalities`);

export const fundStatusTagColor = (state) => {
    switch (state.toUpperCase()) {
        case "LIQUIDADA":
            return "#84EFD0";
        case "ANULADA":
            return "#FEB0AF";
        case "PENDIENTE DE LIQUIDACIÓN":
            return "#DDDDDD";
        case "RECHAZADA":
            return "#FEB0AF";
        case "NO REQUIERE AUTORIZACIÓN":
            return "#DDDDDD";
        default:
            return "#FEB0AF";
    }
};
export const isEnabledApi5 = () => configUtils.getBoolean("frontend.show.fundsAPI5.advisory.functionalities", false);

export default getRescueDeadlineLabel;

// Se valida si la suscripcion tiene cancelacion inmediata para mostrar mensaje correspondiente
export const validateMessageCancellation = (numberFund, fundName) => {
    let messageCancellation;

    if (numberFund && fundName) {
        const cancelableLlist = configUtils.getArrayInteger("frontend.investment.funds.cancelable.list");
        const isCanceleable = cancelableLlist?.some((fund) => fund === numberFund);
        if (isCanceleable) {
            messageCancellation = configUtils
                .get("frontend.investment.funds.cancellation.message")
                ?.replace("#{NOMBRE_FONDO}", fundName);
        }
    }
    return messageCancellation;
};
