import React, { Component, createRef } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { Spring } from "react-spring";
import { shape, bool, string, arrayOf, instanceOf, func } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";

import { actions as formsActions, selectors as formsSelectors } from "reducers/form";
import * as format from "util/format";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import FormSignatures from "pages/forms/_components/FormSignatures";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import FormattedDate from "pages/_components/FormattedDate";
import TransactionTicketCancelConfirmation from "pages/forms/_components/TransactionTicketCancelConfirmation";
import DefaultTicketHeader, * as TicketHeaders from "pages/forms/_components/ticketHeaders/Index";
import * as schedulerUtils from "util/scheduler";
import * as i18nUtils from "util/i18n";
import MainContainer from "pages/_components/MainContainer";
import FieldLabel from "pages/_components/fields/FieldLabel";

class TransactionTicket extends Component {
    state = {
        showDetails: false,
    };

    transactionTicketCancelSection = createRef();

    static propTypes = {
        // tells if the form is being rendered from Backoffice
        fromBackoffice: bool,
        isCancelling: bool,
        transaction: shape({
            programed: bool,
            idTransaction: string,
            submitDateTimeAsString: string,
            idTransactionStatus: string,
        }),
        childrenTransactions: arrayOf(
            shape({
                valueDateTime: instanceOf(Date),
                idTransaction: string,
                idTransactionStatus: string,
            }),
        ),
        formActions: shape({}),
        parentTransaction: shape({
            idTransaction: string,
            creationDateTime: instanceOf(Date),
        }),
        children: shape({}),
        // tells if we are rendering to confirm a recent transaction
        ticketConfirmation: bool,
        dispatch: func,
    };

    static defaultProps = {
        fromBackoffice: false,
        isCancelling: false,
        ticketConfirmation: false,
        transaction: {},
        childrenTransactions: null,
        formActions: null,
        parentTransaction: null,
        children: null,
        dispatch: () => {},
    };

    componentDidUpdate() {
        if (this.transactionTicketCancelSection && this.transactionTicketCancelSection.scrollIntoView) {
            this.transactionTicketCancelSection.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(formsActions.formClosed());
    }

    handleClick = () => {
        this.setState(({ showDetails }) => ({ showDetails: !showDetails }));
    };

    handleCancelTransaction = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(push(`/form/requestTransactionCancellation?referenceToCancel=${idTransaction}`));
    };

    render() {
        const {
            childrenTransactions,
            formActions,
            fromBackoffice,
            isCancelling,
            parentTransaction,
            ticketConfirmation,
            transaction,
            children,
        } = this.props;
        const { idTransaction, submitDateTimeAsString, cancelable } = transaction;
        const { showDetails } = this.state;
        const selectedOption = transaction.data.scheduler ? transaction.data.scheduler.selectedOption : null;

        const scheduled = selectedOption ? selectedOption !== schedulerUtils.TODAY : false;
        const TicketHeader =
            TicketHeaders[transaction.idForm.charAt(0).toUpperCase() + transaction.idForm.substr(1)] ||
            DefaultTicketHeader;

        return (
            <>
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="container--layout align-items-center " gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Col
                                    sm={12}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    className="col col-12 container-white"
                                    style={{ paddingBottom: "2rem" }}>
                                    {!fromBackoffice && <TicketHeader {...this.props} />}
                                    <Container
                                        className="container--layout align-items-center"
                                        gridClassName="form-content">
                                        <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                                            <Row>
                                                <I18n
                                                    component="h2"
                                                    id="forms.transaction.ticket"
                                                    componentProps={{ className: "font-size-28-px" }}
                                                />
                                            </Row>
                                            <Row style={{ justifyContent: "space-between" }}>
                                                <FieldLabel labelKey="forms.transaction.ticket.date" />
                                                <span className="data-date">{submitDateTimeAsString}</span>
                                            </Row>
                                            <Row style={{ justifyContent: "space-between" }}>
                                                <FieldLabel labelKey="forms.transaction.ticket.number" />
                                                <span className="data-desc">
                                                    {idTransaction.substring(0, 8).toUpperCase()}
                                                </span>
                                            </Row>
                                        </Col>
                                    </Container>
                                    <Spring
                                        from={{ height: 0 }}
                                        to={{ height: showDetails ? "auto" : 0, overflow: "hidden" }}>
                                        {(styles) => (
                                            <Container
                                                className="container--layout align-items-center"
                                                gridClassName="form-content"
                                                style={!fromBackoffice && ticketConfirmation ? styles : null}>
                                                {scheduled && (
                                                    <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                                                        <Row style={{ justifyContent: "space-between" }}>
                                                            <FieldLabel labelKey="forms.confirmation.scheduler" />
                                                            <Message value={transaction.data.scheduler} />
                                                        </Row>
                                                    </Col>
                                                )}
                                                {children && children}
                                                <FormSignatures transaction={transaction} />
                                                {parentTransaction && (
                                                    <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                                                        <Row>
                                                            <Link
                                                                to={`/transaction/${parentTransaction.idTransaction}`}>
                                                                <I18n
                                                                    id="forms.transaction.ticket.parent"
                                                                    idTransaction={parentTransaction.idTransaction}
                                                                    date={format.date(
                                                                        parentTransaction.creationDateTime,
                                                                        i18nUtils.get("datepicker.format"),
                                                                    )}
                                                                />
                                                            </Link>
                                                        </Row>
                                                    </Col>
                                                )}
                                                {childrenTransactions && childrenTransactions.length > 0 && (
                                                    <Row className="">
                                                        <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                                                            <Row>
                                                                <I18n
                                                                    component="h2"
                                                                    id="forms.transaction.ticket.children.title"
                                                                    componentProps={{ className: "font-size-28-px" }}
                                                                />
                                                            </Row>
                                                            <div className="table">
                                                                <div className="table-body">
                                                                    {childrenTransactions.map((child) => (
                                                                        <div className="table-row" key={child.id}>
                                                                            <div className="table-data">
                                                                                <div className="data-wrapper">
                                                                                    <span className="data-date">
                                                                                        <FormattedDate
                                                                                            date={child.valueDateTime}
                                                                                        />
                                                                                    </span>
                                                                                    <Link
                                                                                        to={`/transaction/${child.idTransaction}`}>
                                                                                        <span className="data-numeric">
                                                                                            {child.idTransaction}
                                                                                        </span>
                                                                                    </Link>
                                                                                    <span className="data-aux">
                                                                                        <I18n
                                                                                            id={`transaction.status.${child.idTransactionStatus}`}
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Container>
                                        )}
                                    </Spring>
                                </Col>
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Col sm={12} md={10} lg={10} xl={10} className="col col-12" style={{ display: "flex" }}>
                                    {formActions}
                                </Col>
                            </Col>
                        </Container>

                        {isCancelling && (
                            <Container
                                ref={(node) => {
                                    this.transactionTicketCancelSection = node;
                                }}
                                className="container--layout align-items-center flex-grow"
                                gridClassName="form-content">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <Col sm={12} md={10} lg={10} xl={10} className="col col-12">
                                        <TransactionTicketCancelConfirmation idTransaction={idTransaction} />
                                    </Col>
                                </Col>
                            </Container>
                        )}
                        {!fromBackoffice && ticketConfirmation && !showDetails && (
                            <Container className="container--layout align-items-center">
                                <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                                    <Button
                                        className="btn-link"
                                        label="forms.transaction.ticket.more"
                                        onClick={this.handleClick}
                                    />
                                </Col>
                            </Container>
                        )}
                        {cancelable && !fromBackoffice && (
                            <Container className="container--layout align-items-center">
                                <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                                    <Button
                                        className="btn-outline"
                                        label="global.cancel"
                                        onClick={(e) => this.handleCancelTransaction(e, idTransaction)}
                                    />
                                </Col>
                            </Container>
                        )}
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isCancelling: formsSelectors.getIsCancellingTransaction(state),
});

export default connect(mapStateToProps)(TransactionTicket);
