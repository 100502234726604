import React, { Component } from "react";
import { func, shape, bool } from "prop-types";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import * as i18nUtils from "util/i18n";
import Credential from "pages/_components/fields/credentials/Credential";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";
import Yup from "yup";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import classNames from "classnames";

import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import { selectors as debinSelectors, actions as debinActions, types } from "reducers/debin";
import Checkbox from "pages/_components/Checkbox";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import { actions as loginActions } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import isTokenActive from "util/token";
import { maskedAccountNumber } from "util/accounts";
import DebinTermsAndConditionsContent from "./_components/DebinTermsAndConditionsContent";

const FORM_ID = "debin.addAccount.summary";
class DebinAddAccountsSummary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        accountToChange: shape({}).isRequired,
        values: shape({}).isRequired,
        fetching: bool.isRequired,
    };

    componentDidMount() {
        const { accountToChange: account, dispatch } = this.props;

        if (!account || !account.productType) {
            dispatch(push("/charges/debin/addAccounts"));
        }
    }

    componentDidUpdate() {
        const { accountToChange: account, dispatch } = this.props;

        if (!account || !account.productType) {
            dispatch(push("/charges/debin/addAccounts"));
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({
            type: types.SEND_ADD_ACCOUNT_ACTION_SUCCESS,
        });
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleDownload = () => {
        const { dispatch } = this.props;
        dispatch(
            loginActions.downloadDocumentRequest({ format: "pdf", i18nTerms: "debin.termsAndConditions.content" }),
        );
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 className="w-100">{i18nUtils.get("debin.addAccounts.title")}</h1>
        </div>
    );

    render() {
        const { isDesktop, accountToChange: account, values, fetching } = this.props;
        const { productType, productAlias, currency, number, cbu } = account;
        const accountType = `${productType} ${i18nUtils.get(`currency.label.${currency}`)}`;
        const maskedNumber = maskedAccountNumber(number);
        const reference = `${i18nUtils.get("global.number")} ${maskedNumber}`;
        const productName = productAlias || reference;
        const continueButton = (
            <Col lg={12} md={12} sm={12} className="px-0">
                <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                    <div className="admin-content-center">
                        <Button
                            loading={fetching}
                            disabled={!values.acceptTerms}
                            type="submit"
                            bsStyle="primary"
                            label="global.confirm"
                            image="images/arrowRight.svg"
                            imageStyle="mr-2"
                        />
                    </div>
                </Col>
            </Col>
        );

        const credentials = (
            <div className="pt-5">
                {!isTokenActive(!isDesktop) ? (
                    <div className="admin-content-center font-size-15-px mt-4">
                        <I18n id="transfer.summary.confirm.label" component="p" />
                    </div>
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}
                <div className="admin-content-center">
                    <Field
                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </div>
        );

        return (
            <>
                <Notification scopeToShow="debin" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? null : this.centerContentMobile}
                        emptyRightOption
                    />
                    {isDesktop && <h1>{i18nUtils.get("debin.addAccounts.title")}</h1>}
                </div>

                <MainContainer>
                    <Form className={!isDesktop ? "above-the-fold bg-white-mobile" : "confirmation__container"}>
                        {!isDesktop && (
                            <div
                                className={classNames("admin-content-center", {
                                    "mb-6": isDesktop,
                                    "mb-3": !isDesktop,
                                })}>
                                <I18n id="debin.addAccounts.summary.label" component="h3" />
                            </div>
                        )}
                        <Container
                            className={classNames("", {
                                "flex-grow align-items-center container-white with-padding mb-0": !isDesktop,
                            })}
                            gridClassName={!isDesktop ? "form-content" : "confirmation__form confirmation__box"}>
                            {isDesktop && (
                                <div className="admin-content-center">
                                    <I18n id="debin.addAccounts.summary.label" component="h1" />
                                </div>
                            )}

                            <Col className={`col col-12 ${!isDesktop && "px-xl-4"}`} lg={12} xl={12} md={9} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                                        "space-between": !isDesktop,
                                    })}
                                    label="debin.addAccount.summary.name.label"
                                    data={productName}
                                />
                                <Heading.DataGroup
                                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                                        "space-between": !isDesktop,
                                    })}
                                    label="debin.addAccount.summary.accountType.label"
                                    data={accountType}
                                />
                                <Heading.DataGroup
                                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                                        "space-between": !isDesktop,
                                    })}
                                    label="debin.addAccount.summary.number.label"
                                    data={number}
                                />
                                <Heading.DataGroup
                                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                                        "space-between": !isDesktop,
                                    })}
                                    label="debin.addAccount.summary.cbu.label"
                                    data={cbu}
                                />
                                {credentials}
                            </Col>
                            <Container
                                className={classNames("flex-grow align-items-center", {
                                    "mb-0": !isDesktop,
                                })}
                                gridClassName={
                                    !isDesktop
                                        ? "form-content"
                                        : "confirmation__form confirmation__box-transparent mb-0"
                                }>
                                <Col
                                    className={`col col-12 ${isDesktop &&
                                        "d-flex align-items-center pl-0"} ${!isDesktop && "my-3 px-xl-12"}`}
                                    lg={12}
                                    xl={12}
                                    md={9}
                                    sm={12}>
                                    <Field
                                        name="acceptTerms"
                                        component={Checkbox}
                                        className={!isDesktop ? "mb-3" : "mb-0"}
                                        errorClassName="p-absolute min-width-maxcontent"
                                        idForm={FORM_ID}
                                    />
                                    <ButtonDrawer
                                        bsStyle="link"
                                        label="debin.termAndConditions"
                                        width={isDesktop ? "600px" : "auto"}
                                        buttonClassName="btn-noBlock btn-asLink p-0"
                                        content={<DebinTermsAndConditionsContent isDesktop={isDesktop} />}
                                        headerClassName={classNames({
                                            "mt-6": isDesktop,
                                            "background-white": !isDesktop,
                                        })}
                                        closeButton
                                        extraButton={
                                            <Button
                                                className={classNames({
                                                    "mr-2": isDesktop,
                                                    "my-0": !isDesktop,
                                                })}
                                                onClick={this.handleDownload}
                                                image="images/download_bold.svg"
                                                bsStyle="link"
                                                label={isDesktop ? "global.download" : ""}
                                                imageStyle="mr-2"
                                            />
                                        }
                                    />
                                </Col>
                            </Container>
                            <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                                {continueButton}
                            </Container>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    accountToChange: debinSelectors.getAccountToChange(state),
    fetching: debinSelectors.getFetching(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
                acceptTerms: Yup.bool()
                    .required(i18nUtils.get("enrollment.index.invitationCode.empty"))
                    .oneOf([true], i18nUtils.get("enrollment.index.acceptTerms.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, accountToChange, loggedUser } = formikBag.props;
            dispatch(debinActions.sendAddAccountChange(accountToChange, loggedUser.userId, otp, formikBag));
        },
    }),
)(DebinAddAccountsSummary);
