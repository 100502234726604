import React, { Component } from "react";
import { func, object } from "prop-types";

import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";

class Statement extends Component {
    static propTypes = {
        statement: object.isRequired,
        handleClick: func.isRequired,
    };

    static defaultProps = {};

    handleClick = (idStatement) => {
        const { handleClick } = this.props;

        handleClick(idStatement);
    };

    render() {
        const { statement } = this.props;

        return (
            <div className="navigational-list-item-container">
                {`${i18nUtils.getArray("global.months")[statement.month - 1]} ${statement.year}`}
                <Button
                    image="images/download.svg"
                    className="btn btn-link btn-block"
                    label="global.download.pdf"
                    onClick={() => this.handleClick(statement.idStatementLine)}
                />
            </div>
        );
    }
}

export default Statement;
