import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { func, shape, string, objectOf, bool, oneOfType, arrayOf } from "prop-types";
import classNames from "classnames";

import { actions, selectors } from "reducers/settings";
import * as i18n from "util/i18n";
import { getArray } from "util/config";
import { lowerCaseFirstLetter } from "util/string";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import SwitchField from "pages/_components/fields/SwitchField";
import Image from "pages/_components/Image";
import DrawerModal from "pages/_components/drawer/DrawerModal";
import NotificationsConfigruationsOptionsHint from "./NotificationsConfigruationsOptionsHint";

class NotificationsConfigurationTransports extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({
            params: shape({
                communicationType: string.isRequired,
            }),
        }).isRequired,
        communicationTransportsConfigurations: objectOf(oneOfType([bool, string])),
        fetching: bool.isRequired,
        keys: arrayOf(string).isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        communicationTransportsConfigurations: null,
    };

    state = {
        showHintDrawer: false,
    };

    componentDidMount() {
        const { dispatch, match } = this.props;

        dispatch(actions.communicationTypePre(match.params.communicationType));
    }

    areOtherChannelsDisabled = (id) => {
        const { communicationTransportsConfigurations: options } = this.props;
        const otherOptions = Object.keys(options).filter(
            (o) => o.includes("subscribed") && !o.toLowerCase().includes(id),
        );
        return otherOptions.every((option) => options[option] === false);
    };

    toggleSwitch = ({ target }, id, transportConfiguration) => {
        const {
            dispatch,
            match,
            communicationTransportsConfigurations: { options },
        } = this.props;

        if (target.value === false && this.areOtherChannelsDisabled(id)) {
            Object.keys(options).forEach((option) =>
                dispatch(actions.modifyNotificationConfigurationOptions(match.params.communicationType, false, option)),
            );
        }
        dispatch(
            actions.modifyNotificationConfigurations(
                match.params.communicationType,
                id,
                target.value,
                transportConfiguration,
            ),
        );
    };

    renderDefaultTransports = (defaultTransports) =>
        defaultTransports.length > 0 && (
            <p>
                <I18n id="communications.communicationsTypes.defaultTransports" />
                {Object.keys(defaultTransports).map((defaultTransportIndex) => {
                    const key = defaultTransports[defaultTransportIndex];
                    const id = `userInfo.preferences.notificationsConfiguration.${key.toLowerCase()}.label`;

                    return (
                        <strong key={key}>
                            <I18n id={id} />{" "}
                        </strong>
                    );
                })}
            </p>
        );

    renderCommunicationTransportsConfigurations = (defaultTransports) => {
        const { communicationTransportsConfigurations, keys } = this.props;

        return keys.map((transportConfiguration) => {
            if (transportConfiguration.includes("subscribed")) {
                const transportConfigurationName = transportConfiguration.substring("subscribed".length).toLowerCase();
                if (
                    !defaultTransports.includes(transportConfigurationName.toUpperCase()) &&
                    communicationTransportsConfigurations.enabledChannels?.includes(
                        transportConfigurationName.toUpperCase(),
                    )
                ) {
                    return (
                        <SwitchField
                            name={transportConfigurationName}
                            key={transportConfigurationName}
                            label={i18n.get(
                                `userInfo.preferences.notificationsConfiguration.${transportConfigurationName}.label`,
                            )}
                            value={communicationTransportsConfigurations[transportConfiguration]}
                            onChange={(event) =>
                                this.toggleSwitch(event, transportConfigurationName, transportConfiguration)
                            }
                        />
                    );
                }
            }

            return null;
        });
    };

    toggleOptionsSwitch = ({ target }, option) => {
        const { dispatch, match } = this.props;
        dispatch(actions.modifyNotificationConfigurationOptions(match.params.communicationType, target.value, option));
    };

    renderCommunicationOptions = (options, subscribedAnyChannel) =>
        Object.entries(options).map(([option, enabled]) => (
            <SwitchField
                name={option}
                key={option}
                label={i18n.get(`userInfo.preferences.notificationsConfiguration.${option.toLowerCase()}.label`)}
                value={enabled}
                isDisabled={!subscribedAnyChannel}
                onChange={(event) => this.toggleOptionsSwitch(event, option)}
            />
        ));

    renderTitleMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: "0.2rem" }}>
                <I18n id="settings.notificationsConfiguration" />
            </h1>
        </div>
    );

    closeDrawer = () => {
        this.setState({ showHintDrawer: false });
    };

    showHintDrawer = () => {
        this.setState({
            showHintDrawer: true,
        });
    };

    renderContent() {
        const { communicationTransportsConfigurations, match, isDesktop } = this.props;
        const { showHintDrawer } = this.state;

        if (communicationTransportsConfigurations) {
            const defaultTransports = getArray(
                `core.communications.communicationTypes.${match.params.communicationType}.default`,
            );

            const defaultTransportsUpper = defaultTransports.map((item) => item.toUpperCase());
            const communicationType = i18n.get(`communications.communicationsTypes.${match.params.communicationType}`);
            const { options } = communicationTransportsConfigurations;
            const subscribedAnyChannel =
                communicationTransportsConfigurations.subscribedDefault ||
                communicationTransportsConfigurations.subscribedFacebook ||
                communicationTransportsConfigurations.subscribedMail ||
                communicationTransportsConfigurations.subscribedPush ||
                communicationTransportsConfigurations.subscribedSMS ||
                communicationTransportsConfigurations.subscribedTwitter;
            if (!subscribedAnyChannel) {
                Object.keys(options).forEach((key) => {
                    options[key] = false;
                });
            }
            const lowerCaseCommunicationType = lowerCaseFirstLetter(communicationType);
            return (
                <form className="above-the-fold">
                    {showHintDrawer && (
                        <DrawerModal
                            content={
                                <NotificationsConfigruationsOptionsHint
                                    isDesktop={isDesktop}
                                    options={options}
                                    communicationType={communicationType}
                                />
                            }
                            showDrawerRemote={showHintDrawer}
                            isDesktop={isDesktop}
                            width={isDesktop ? "30%" : "100%"}
                            closeRemotely={() => this.closeDrawer()}
                            noBlueHeader
                        />
                    )}
                    <section className="container--layout flex-grow align-items-center">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={8} lg={6} className="col col-12">
                                    <p className="text-lead text-left">
                                        <I18n id="settings.notificationsConfiguration.description.base" />{" "}
                                        <strong>{lowerCaseCommunicationType}</strong>
                                    </p>
                                    <div className="form-group">
                                        <div className="form-group-control-list">
                                            {this.renderCommunicationTransportsConfigurations(defaultTransportsUpper)}
                                        </div>
                                    </div>
                                    {this.renderDefaultTransports(defaultTransportsUpper)}
                                    {Object.keys(options).length ? (
                                        <>
                                            <div className="d-flex">
                                                <p className="text-lead text-left my-25 mr-2">
                                                    <I18n id="settings.notificationsConfiguration.options" />{" "}
                                                    <strong>{lowerCaseCommunicationType}</strong>
                                                </p>
                                                <div className="p-relative d-flex">
                                                    <button
                                                        type="button"
                                                        className={classNames("btn btn-hint my-0", {
                                                            "p-absolute": isDesktop,
                                                        })}
                                                        onClick={this.showHintDrawer}>
                                                        <Image src="images/infoUserInvite.svg" />
                                                        {isDesktop ? (
                                                            <I18n
                                                                id="echeqs.emit.emision.modes.hint"
                                                                component="p"
                                                                componentProps={{
                                                                    className: "hint-text text-nowrap my-0",
                                                                }}
                                                            />
                                                        ) : (
                                                            undefined
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group-control-list">
                                                    {this.renderCommunicationOptions(options, subscribedAnyChannel)}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        undefined
                                    )}
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                </form>
            );
        }

        return null;
    }

    render() {
        const { fetching, isDesktop } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="settings.notificationsConfiguration.modify" />
                <div className="admin-detail-head px-0">
                    <Head
                        backLinkTo="/settings/notificationsConfiguration"
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={!isDesktop && this.renderTitleMobile}
                        hideMobileMenu={!isDesktop}
                    />
                    {isDesktop && (
                        <h1>
                            <I18n id="settings.notificationsConfiguration" />
                        </h1>
                    )}
                </div>
                <MainContainer showLoader={fetching}>{this.renderContent()}</MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    communicationTransportsConfigurations: selectors.getCommunicationTransportsConfigurations(state),
    fetching: selectors.isFetching(state),
    keys: selectors.getCommunicationTransportsConfigurationsKeys(state),
});

export default withRouter(connect(mapStateToProps)(NotificationsConfigurationTransports));
