/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-unresolved */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "react-bootstrap/lib/Navbar";
import classNames from "classnames";
import { push } from "react-router-redux/actions";
import { arrayOf, bool, func, number, objectOf, shape, string } from "prop-types";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { selectors as communicationsSelectors } from "reducers/communications";
import { actions as notificationActions } from "reducers/notification";
import { getLastLoginDate, getLastLoginPlace } from "util/settings";
import { selectors as i18nSelectors } from "reducers/i18n";
import { get as getMessage } from "util/i18n";

import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";
import UserLink from "pages/_components/UserLink";
import Button from "pages/_components/Button";
import Badge from "pages/_components/Badge";
import Dropdown from "pages/_components/Dropdown";
import EnvironmentTag from "pages/_components/EnvironmentTag";
import Popup from "pages/_components/Popup";
import Avatar from "pages/_components/Avatar";
import ButtonDrawer from "./drawer/ButtonDrawer";
import Card from "./Card/Card";

class NavigationBarDesktop extends React.Component {
    static propTypes = {
        activeEnvironment: shape({
            administrationScheme: string,
            forms: shape({}),
            id: number,
            name: string,
            permissions: shape({
                accounts: bool,
                requestTransactionCancellation: bool,
                transferForeign: bool,
                transferInternal: bool,
                transferLocal: bool,
                transferThirdParties: bool,
            }),
            type: string,
        }),
        dispatch: func.isRequired,
        environments: objectOf(
            shape({
                environmentType: string,
                idEnvironment: string,
                name: string,
            }),
        ),
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: number,
            userFullName: string,
        }),
        unreadCommunications: number,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        activeEnvironment: null,
        environments: null,
        loggedUser: null,
        unreadCommunications: 0,
    };

    state = {
        communications: 0,
        query: "",
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    goToEnvironment = async (idEnvironment) => {
        const { dispatch, environments } = this.props;
        if (environments[idEnvironment].allowedToAccess === "true") {
            await dispatch(push("/desktop"));
            dispatch(sessionActions.changeEnvironment(idEnvironment, false, null));
        } else {
            dispatch(
                notificationActions.showNotification(
                    getMessage("administration.restrictions.unavailableEnvironment"),
                    "error",
                    ["desktop"],
                ),
            );
            dispatch(push("/desktop"));
        }
    };

    getLastLoginInfo = () => {
        const { loggedUser } = this.props;
        if (loggedUser) {
            const { previousLoginInfo } = loggedUser;

            if (previousLoginInfo && previousLoginInfo.length) {
                return (
                    <React.Fragment>
                        <div className="text-center">
                            <p className="last-login">
                                <I18n id="settings.lastLogin.date" />
                            </p>
                            <p className="ellipsis data-desc">
                                {getLastLoginDate(previousLoginInfo[0])} - {getLastLoginPlace(previousLoginInfo[0])}
                            </p>
                        </div>
                    </React.Fragment>
                );
            }
        }
        return null;
    };

    onMailboxAnimationEnd = () => {
        const { unreadCommunications } = this.props;
        this.setState({ communications: unreadCommunications });
    };

    render() {
        const { environments, activeEnvironment, loggedUser, unreadCommunications, isDesktop } = this.props;
        const { communications, query } = this.state;
        const hasToAnimateMailBox = communications === unreadCommunications;

        return (
            <Navbar collapseOnSelect fluid>
                <Navbar.Header>
                    <div>
                        <Navbar.Brand>
                            <Link className={isDesktop ? "navbar-brand pl-0" : "navbar-brand"} to="/desktop">
                                <Image src="images/logoCompany.svg" className="header__logo" />
                                <I18n id="global.companyName" componentProps={{ className: "visually-hidden" }} />
                            </Link>
                        </Navbar.Brand>

                        <ul className="nav navbar-nav navbar-right header-command-pallette">
                            <li className="header-message-button">
                                <Link to="/communications">
                                    <div>
                                        <div className="rounder-content ">
                                            {unreadCommunications > 0 && (
                                                <>
                                                    <Badge
                                                        className={classNames({
                                                            animation: !hasToAnimateMailBox,
                                                            "badge-header": true,
                                                        })}
                                                    />
                                                    <I18n
                                                        id="menu.chat.badge"
                                                        componentProps={{ className: "visually-hidden" }}
                                                    />
                                                </>
                                            )}
                                            {!isDesktop ? (
                                                <Image className="svg-icon" src="images/email-white.svg" />
                                            ) : (
                                                <Image className="svg-icon" src="images/email-blue.svg" />
                                            )}
                                        </div>
                                    </div>
                                    <I18n id="menu.chat" />
                                </Link>
                            </li>
                            {environments && Object.keys(environments).length === 1 && (
                                <li className="environments-dropdown">
                                    {isDesktop ? (
                                        <Button className="header-dropdown-button">
                                            <Image className="mr-3" src={`images/${activeEnvironment.type}.svg`} />
                                            {activeEnvironment.name}
                                        </Button>
                                    ) : (
                                        <div className="header-dropdown-button">
                                            <EnvironmentTag
                                                type={activeEnvironment.type}
                                                name={activeEnvironment.name}
                                                color="white"
                                            />
                                        </div>
                                    )}
                                </li>
                            )}

                            {environments && Object.keys(environments).length > 1 && (
                                <li className="environments-dropdown">
                                    {isDesktop ? (
                                        <ButtonDrawer
                                            drawerContentClassName="enviroments__drawer-content px-5 pt-5"
                                            headerContent={
                                                <div className="enviroments__drawer-header pb-4">
                                                    <I18n
                                                        component="h1"
                                                        id="settings.changeEnvironment.title.desktop"
                                                    />
                                                    <div className="environments__input">
                                                        <input
                                                            placeholder={i18n.get(
                                                                `settings.changeEnvironment.search.placeholder`,
                                                            )}
                                                            value={query}
                                                            onChange={(e) => this.setState({ query: e.target.value })}
                                                        />
                                                        <Image src="images/search.svg" />
                                                    </div>
                                                </div>
                                            }
                                            closeButton={false}
                                            buttonElement={
                                                <Button className="header-dropdown-button">
                                                    <Image
                                                        className="mr-3"
                                                        src={`images/${activeEnvironment.type}.svg`}
                                                    />
                                                    {activeEnvironment.name}
                                                </Button>
                                            }
                                            isDesktop
                                            content={
                                                <>
                                                    {Object.values(environments)
                                                        .filter((environment) =>
                                                            environment.name
                                                                .toLowerCase()
                                                                .includes(query.toLocaleLowerCase()),
                                                        )
                                                        .filter(
                                                            (environment) =>
                                                                parseInt(environment.idEnvironment, 10) !==
                                                                activeEnvironment.id,
                                                        )
                                                        .sort((a, b) => {
                                                            if (
                                                                a.environmentType === "retail" &&
                                                                b.environmentType !== "retail"
                                                            ) {
                                                                return -1;
                                                            }
                                                            if (
                                                                a.environmentType !== "retail" &&
                                                                b.environmentType === "retail"
                                                            ) {
                                                                return 1;
                                                            }
                                                            return a.name.localeCompare(b.name);
                                                        })
                                                        .map((environment) => (
                                                            <Card
                                                                className="px-0 py-0"
                                                                key={environment.idEnvironment}
                                                                content={
                                                                    <Button
                                                                        role="option"
                                                                        className="dropdown__item-btn header-dropdown-item"
                                                                        bsStyle="link"
                                                                        onClick={() =>
                                                                            this.goToEnvironment(
                                                                                environment.idEnvironment,
                                                                            )
                                                                        }>
                                                                        <EnvironmentTag
                                                                            type={environment.environmentType}
                                                                            color="#000000"
                                                                            name={environment.name.toUpperCase()}
                                                                        />
                                                                    </Button>
                                                                }
                                                            />
                                                        ))}
                                                </>
                                            }
                                        />
                                    ) : (
                                        <Dropdown
                                            buttonClass="header-dropdown-button"
                                            dropdownButtonContent={
                                                <EnvironmentTag
                                                    type={activeEnvironment.type}
                                                    name={activeEnvironment.name}
                                                    color="white"
                                                />
                                            }
                                            pullRight>
                                            {Object.values(environments)
                                                .filter(
                                                    (environment) =>
                                                        parseInt(environment.idEnvironment, 10) !==
                                                        activeEnvironment.id,
                                                )
                                                .map((environment) => (
                                                    <Button
                                                        role="option"
                                                        className="dropdown__item-btn header-dropdown-item"
                                                        bsStyle="link"
                                                        onClick={() => this.goToEnvironment(environment.idEnvironment)}>
                                                        <EnvironmentTag
                                                            type={environment.environmentType}
                                                            name={environment.name}
                                                        />
                                                    </Button>
                                                ))}
                                        </Dropdown>
                                    )}
                                </li>
                            )}
                            <li>
                                <Popup
                                    popupTitleID="menu.settings.panelTitle"
                                    popupButtonContent={
                                        <>
                                            <Avatar user={loggedUser} />
                                            <I18n id="menu.settings" />
                                        </>
                                    }>
                                    <UserLink loggedUser={loggedUser} />
                                    <div>
                                        {this.getLastLoginInfo()}
                                        <Button
                                            className="btn btn-link btn-small px-0"
                                            onClick={this.logOut}
                                            label="global.logout"
                                        />
                                    </div>
                                </Popup>
                            </li>
                        </ul>
                    </div>
                </Navbar.Header>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    environments: sessionSelectors.getEnvironments(state),
    lang: i18nSelectors.getLang(state),
    loggedUser: sessionSelectors.getUser(state),
    unreadCommunications: communicationsSelectors.getUnreadCommunications(state),
});

export default connect(mapStateToProps)(NavigationBarDesktop);
