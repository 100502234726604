import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { func, arrayOf, shape, bool, string } from "prop-types";
import { routerActions } from "react-router-redux/actions";
import { Formik } from "formik";

import { actions as formActions } from "reducers/form";
import { actions } from "reducers/administration/common/groupFormData";
import { groupFormDataSelectors, navigationSelectors } from "reducers/administration";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import GroupForm from "pages/administration/_components/groupForm/GroupForm";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Notification from "pages/_components/Notification";

const FORM_ID = "administration.advanced.group.create";

class AdministrationAdvancedGroupFormContainer extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        location: shape({}).isRequired,
        formValues: shape({}).isRequired,
        idTransaction: string,
        idActivity: string,
        fetching: bool.isRequired,
        hasBack: bool.isRequired,
        hasClose: bool.isRequired,
    };

    static defaultProps = { idTransaction: null, idActivity: null };

    componentDidMount() {
        const { dispatch, match } = this.props;

        dispatch(actions.loadGroupFormDataRequest(match.params.id));
    }

    handleSubmit = (values, formikBag) => {
        const { match, location, dispatch, idTransaction, idActivity } = this.props;
        const path = location.pathname.split("/");

        if (path.includes("confirm")) {
            const { otp } = values;
            if (otp && otp.length !== 0) {
                if (!idTransaction) {
                    dispatch(actions.submitGroupFormRequest(values, match.params.id, formikBag));
                } else {
                    const { name, description, users, status, permissions, ...credentials } = values;
                    const paramsSign = { idForm: null, idActivity, idTransaction };
                    dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
                }
            } else {
                formikBag.setErrors({ otp: i18n.get("token.required") });
                formikBag.setSubmitting(false);
            }
        } else {
            dispatch(actions.submitGroupFormPreviewRequest({ ...values }, match.params.id, formikBag));
        }
    };

    render() {
        const { credentialGroups, fetching, match, formValues, hasBack, hasClose, dispatch } = this.props;
        const buttonText = match.params.id ? `${FORM_ID}.button.modify.text` : `${FORM_ID}.button.create.text`;
        const navProps = {};

        if (hasBack) {
            navProps.onBack = () => dispatch(routerActions.goBack());
        }
        if (hasClose) {
            if (hasClose && hasBack) {
                if (match.params.id) {
                    navProps.onClose = () =>
                        dispatch(routerActions.push(`/administration/advanced/group/${match.params.id}/details`));
                } else {
                    navProps.onClose = () => dispatch(routerActions.push(`/administration/groups`));
                }
            } else {
                navProps.onBack = () => dispatch(routerActions.goBack());
            }
        }

        return (
            <>
                <Notification scopeToShow="signature" />
                <div className="admin-detail-head admin-detail-head-section px-0">
                    <Head {...navProps} />
                    <div>
                        <I18n
                            id={`administration.advanced.group.${!match.params.id ? "create" : "modify"}.title`}
                            component="h1"
                            componentProps={{ className: "d-inline-flex view-title" }}
                        />
                        <h1 className="d-inline-flex view-title ml-2">{formValues.name}</h1>
                    </div>
                </div>
                <MainContainer viewContentClassName="pt-0" showLoader={fetching}>
                    <div className="above-the-fold">
                        <Formik initialValues={{ ...formValues, otp: "" }} onSubmit={this.handleSubmit}>
                            {(props) => (
                                <Switch>
                                    <Route path={`${match.path}/confirm`}>
                                        {() => {
                                            if (props.values.name === "") {
                                                return <Redirect to="/administration/advanced/group/sign/create" />;
                                            }

                                            return (
                                                <GroupForm
                                                    {...props}
                                                    idForm={FORM_ID}
                                                    path={`${match.path}/confirm`}
                                                    mode="view"
                                                    buttonText={buttonText}
                                                    credentialGroups={credentialGroups}
                                                    handleSubmit={this.handleSubmit}
                                                />
                                            );
                                        }}
                                    </Route>
                                    <Route path={`${match.path}`}>
                                        <GroupForm {...props} idForm={FORM_ID} buttonText={buttonText} />
                                    </Route>
                                </Switch>
                            )}
                        </Formik>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    credentialGroups: groupFormDataSelectors.getCredentialGroups(state),
    idTransaction: groupFormDataSelectors.getIdTransaction(state),
    idActivity: groupFormDataSelectors.getIdActivity(state),
    availableUsers: groupFormDataSelectors.getAvailableUsers(state),
    adminUsers: groupFormDataSelectors.getAdminUsers(state),
    formValues: groupFormDataSelectors.getFormValues(state),
    fetching: groupFormDataSelectors.isFetching(state),
    hasClose: navigationSelectors.isHasClose(state),
    hasBack: navigationSelectors.isHasBack(state),
});

export default connect(mapStateToProps)(AdministrationAdvancedGroupFormContainer);
