import React, { Component } from "react";
import { Spring, config } from "react-spring";

import Image from "pages/_components/Image";

class DropdownArrow extends Component {
    render() {
        const { isActive } = this.props;

        return (
            <Spring
                config={config.stiff}
                from={{ transform: "rotate(90deg)" }}
                to={{
                    transform: isActive ? "rotate(-90deg)" : "rotate(90deg)",
                }}>
                {(styles) => <Image src="images/chevromRight.svg" className="svg-icon svg-caret" styles={styles} />}
            </Spring>
        );
    }
}

export default DropdownArrow;
