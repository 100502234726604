import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, arrayOf, string, shape, bool } from "prop-types";
import Notification from "pages/_components/Notification";
import { selectors, actions } from "reducers/checks";
import { echeqShape } from "util/checks";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Row from "react-bootstrap/lib/Row";
import I18n from "pages/_components/I18n";
import Yup from "yup";
import TextField from "pages/_components/fields/TextField";
import Productselector from "pages/forms/_components/_fields/Productselector";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import { maskedAccountNumber } from "util/accounts";
import Hint from "pages/_components/hints/Hint";
import Image from "pages/_components/Image";
import classNames from "classnames";

const FORM_ID = "echeqs";
class NegotiateEcheq extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        echeqAction: shape({
            action: string.isRequired,
            echeqList: arrayOf(echeqShape),
            specialAttribute: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        loggedUser: shape({}).isRequired,
    };

    state = {
        showHintInfoMb: false,
    };

    componentDidMount() {
        const { dispatch, loggedUser, accounts = [] } = this.props;
        if (accounts.length === 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    rightContentMobile = () => {
        const { showHintInfoMb } = this.state;
        return (
            <button
                type="button"
                className="btn btn-hint"
                onClick={() => this.setState((prev) => ({ ...prev, showHintInfoMb: !prev.showHintInfoMb }))}>
                {!showHintInfoMb ? (
                    <Image src="images/infoWhite.svg" styles={{ height: "24px", width: "24px" }} />
                ) : (
                    <Image src="images/infoWhiteFull.svg" styles={{ height: "24px", width: "24px" }} />
                )}
            </button>
        );
    };

    renderHeader = () => {
        const { echeqAction, isDesktop } = this.props;
        const title = echeqAction.echeqList?.length > 1 ? "echeqs.negotiate.title.multi" : "echeqs.negotiate.title";

        if (isDesktop) {
            return (
                <>
                    <Head onBack={this.handleBack} headerClassName="negotiate-header" />

                    <Head
                        title={title}
                        headerClassName="mb-md-3"
                        titleClassName="align-font-fix-04"
                        handleClickMessage="global.download"
                        centerContentClassName="p-0"
                        hasInlineButtons>
                        <Hint
                            idMessage={`${FORM_ID}.${echeqAction?.action?.toLowerCase()}.information`}
                            styleText={{
                                maxWidth: "35rem",
                            }}
                            styleDiv={{ marginLeft: "0.5rem" }}
                            styleImage={{ alignSelf: "center", margin: !isDesktop && "2rem 0 1.5rem" }}
                            classNameHintP="hint-font-size-small font-weight-normal my-0"
                            alwaysShowMessage
                        />
                    </Head>
                </>
            );
        }

        return (
            <Head
                headerClassName="blue-main-header-mobile"
                titleClassName="w-100"
                onBack={this.handleBack}
                title={title}
                rightContent={this.rightContentMobile}
            />
        );
    };

    render() {
        const { echeqAction, accounts, isDesktop } = this.props;
        const { showHintInfoMb } = this.state;
        const formattedMessage = () => {
            const msg = i18n.get(`${FORM_ID}.${echeqAction?.action?.toLowerCase()}.information`);
            const arr = [-4];

            for (let i = 0; i < msg.length; i++) {
                const element = msg[i];
                if (element === "<") {
                    arr.push(i);
                }
            }
            const arrTxt = [];
            for (let i = 0; i < arr.length; i++) {
                if (i === arr.length - 1) {
                    arrTxt.push(msg.slice(arr[i] + 4));
                } else {
                    arrTxt.push(msg.slice(arr[i] + 4, arr[i + 1]));
                }
            }

            return arrTxt.join("");
        };

        // Solo muestro cuentas en ARS
        const accountList = accounts.filter((account) => account.currency === "0");

        return (
            <>
                <div className="admin-detail-head px-0">
                    {this.renderHeader()}
                    {!isDesktop && showHintInfoMb && (
                        <span className="hint-snackbar-mobile px-4 py-2">{formattedMessage()}</span>
                    )}
                </div>
                <Notification scopeToShow="echeqs" />
                <MainContainer>
                    <div className="above-the-fold">
                        <Form>
                            <Container className="flex-grow align-items-center" gridClassName="form-content">
                                <Col
                                    xs={12}
                                    className={classNames("container-white py-3 mb-2", { "mt-4": !isDesktop })}>
                                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                                        <Col xs={12} md={8} lg={6} className="px-2">
                                            <Row className="mx-0">
                                                <I18n
                                                    upperCase
                                                    component="span"
                                                    id="echeqs.add.beneficiary"
                                                    componentProps={{
                                                        style: {
                                                            margin: "0 10px 0.7em 0",
                                                            fontFamily: "Graphik-Regular",
                                                        },
                                                    }}
                                                />
                                                <I18n
                                                    upperCase
                                                    id="global.bankName"
                                                    componentProps={{ className: "data-desc" }}
                                                />
                                            </Row>
                                            <Row className="mx-0">
                                                <I18n
                                                    upperCase
                                                    component="span"
                                                    id="echeqs.type.label"
                                                    componentProps={{
                                                        style: { margin: "0 10px 0 0", fontFamily: "Graphik-Regular" },
                                                    }}
                                                />
                                                <I18n
                                                    id="echeqs.nom.label"
                                                    componentProps={{ className: "data-desc" }}
                                                />
                                            </Row>
                                        </Col>
                                    </Container>
                                </Col>
                                <Col xs={12} className="container-white py-3 px-0">
                                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                                        <Col xs={12} md={8} lg={6} className="px-0 negotiate-field">
                                            <Col xs={12} md={6}>
                                                <Field
                                                    name="account"
                                                    component={Productselector}
                                                    idField="account"
                                                    data={{
                                                        emptyOptionLabel: "",
                                                        options: accountList,
                                                    }}
                                                    disabled={accountList.length === 0}
                                                    isRequired
                                                    mode="edit"
                                                    labelText="transfers.creditaccount.label"
                                                    idForm={FORM_ID}
                                                    isDesktop={isDesktop}
                                                    customPlaceholder={i18n.get(
                                                        "transfers.productSelector.placeholder",
                                                    )}
                                                    notBold
                                                    small
                                                />
                                            </Col>
                                        </Col>
                                    </Container>
                                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                                        <Col xs={12} md={8} lg={6} className="px-0 negotiate-field">
                                            <Col xs={12} md={6}>
                                                <Field
                                                    component={TextField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    name="reference"
                                                    type="text"
                                                    style={{ paddingTop: "13px", paddingBottom: "13px" }}
                                                />
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Field
                                                    component={TextField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    name="referencevalue"
                                                    type="text"
                                                    style={{ paddingTop: "13px", paddingBottom: "13px" }}
                                                />
                                            </Col>
                                        </Col>
                                    </Container>
                                </Col>
                            </Container>
                            <Container className="flex-grow align-items-center" gridClassName="form-content">
                                <Col
                                    xs={12}
                                    md={8}
                                    lg={4}
                                    className={classNames({ "mt-1": !isDesktop, "mt-5": isDesktop })}>
                                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.continue"
                                            style={{ marginTop: "2rem" }}
                                        />
                                    </Container>
                                </Col>
                            </Container>
                        </Form>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    option: selectors.getEcheqOption(state),
    echeqAction: selectors.getEcheqsToDoActions(state),
    accounts: accountsSelectors.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ account: "", reference: "", referencevalue: "" }),
        validationSchema: () =>
            Yup.object().shape({
                account: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
            }),
        handleSubmit: (form, formikBag) => {
            const { dispatch, accounts } = formikBag.props;
            const completeAccount = accounts.find(({ idProduct }) => idProduct === form.account);
            const { productType, productAlias, number: accountNumber, currency } = completeAccount;
            const currencyDescription = i18n.get(`currency.label.${currency}`);
            const account = productAlias || maskedAccountNumber(accountNumber);
            const accountLabel = `${account} ${productType} ${currencyDescription}`;
            const negotiateForm = { ...form, type: "NOM", cbu: completeAccount?.cbu, accountLabel };
            dispatch(actions.endorseEcheqData(negotiateForm));
            dispatch(push("/echeqs/action/endorse/confirm"));
        },
    }),
)(NegotiateEcheq);
