import utils from "axios/lib/utils";
import settle from "axios/lib/core/settle";

export default function cordovaAdapter(config) {
    return new Promise((resolve, reject) => {
        const requestData = config.data;
        const requestHeaders = config.headers;

        if (utils.isFormData(requestData)) {
            delete requestHeaders["Content-Type"]; // Let the browser set it
        }

        if (window.cordova && window.cordova.plugin.http) {
            const requestCordova = {
                method: config.method,
                data: config.data,
                serializer: "utf8",
                timeout: config.timeout,
                headers: JSON.parse(JSON.stringify(requestHeaders)),
            };
            try {
                window.cordova.plugin.http.sendRequest(
                    config.url,
                    requestCordova,
                    (response) => {
                        const responseAxios = {
                            data: response.data,
                            status: response.status,
                            statusText: "ok",
                            config,
                            request: requestCordova,
                            headers: response.headers,
                        };
                        settle(resolve, reject, responseAxios);
                    },
                    (response) => {
                        const responseAxios = {
                            data: response.error,
                            status: response.status,
                            config,
                            headers: response.headers,
                        };
                        settle(resolve, reject, responseAxios);
                    },
                );
            } catch (error) {
                reject(error);
            }
        }
    });
}
