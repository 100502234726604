import React, { Component } from "react";
import { string } from "prop-types";

import I18n from "pages/_components/I18n";

import Container from "pages/_components/Container";

class ModalContent extends Component {
    static propTypes = {
        seconds: string.isRequired,
    };

    render() {
        const { seconds } = this.props;

        return (
            <Container className="container--layout align-items-center flex-grow" gridClassName="container-fluid">
                <div style={{ display: "flex" }}>
                    <div>
                        <I18n
                            component="p"
                            id="session.expires.in.label"
                            componentProps={{ className: "timer-sesion-font" }}
                        />
                        <I18n
                            component="p"
                            componentProps={{ className: "timer-sesion-font timer bold" }}
                            id="session.expires.in"
                            SECONDS={seconds}
                        />
                    </div>
                </div>
            </Container>
        );
    }
}

export default ModalContent;
