import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bool, shape, string } from "prop-types";

import { resizableRoute } from "pages/_components/Resizable";
import { selectors as sessionSelectors } from "reducers/session";

import Error from "pages/error/Error";
import ServerError from "pages/error/ServerError";
import LanguageSelection from "pages/login/LanguageSelection";
import RegionSelection from "pages/login/RegionSelection";
import AssistantLogin from "pages/login/AssistantLogin";
import LoginStep1 from "pages/login/LoginStep1";
import LoginStep2 from "pages/login/LoginStep2";
import LoginStep3 from "pages/login/LoginStep3";
import LoginStep4 from "pages/login/LoginStep4";
import LoginAssistantStep from "pages/login/LoginStep5";
import NoUser from "pages/login/NoUser";
import RecoveryPassStep1 from "pages/recoveryPassword/RecoveryPassStep1";
import RecoveryPassStep2 from "pages/recoveryPassword/RecoveryPassStep2";
import RecoveryPassStep3 from "pages/recoveryPassword/RecoveryPassStep3";
import RecoveryPassStep4 from "pages/recoveryPassword/RecoveryPassStep4";
import RecoveryUserpassStep0 from "pages/recoveryUserpass/RecoveryUserpassStep0";
import RecoveryUserpassStep1 from "pages/recoveryUserpass/RecoveryUserpassStep1";
import RecoveryUserpassStep2 from "pages/recoveryUserpass/RecoveryUserpassStep2";
import RecoveryUserpassStep3 from "pages/recoveryUserpass/RecoveryUserpassStep3";
import RecoveryPassAndUserpassStep2 from "pages/recoveryPassAndUserpass/RecoveryPassAndUserpassStep2";
import RecoveryPassAndUserpassStep3 from "pages/recoveryPassAndUserpass/RecoveryPassAndUserpassStep3";
import ExpiredByMaxAttempts from "pages/session/expiredByMaxAttempts";
import BackofficeTransaction from "pages/forms/BackofficeTransaction";
import BackofficeLayout from "pages/_layouts/BackofficeLayout";
import ExternalLayout from "pages/_layouts/ExternalLayout";
import EnrollmentLayout from "pages/_layouts/EnrollmentLayout";
import OnboardingError from "pages/onboarding/_components/Error";
import OnboardingSafariSuggestionPage from "pages/onboarding/_components/SafariSuggestionPage";
import Processing from "pages/onboarding/_components/Processing";
import OnboardingStep0 from "pages/onboarding/Step0";
import OnboardingStepCIP from "pages/onboarding/StepCIP";
import OnboardingStep1 from "pages/onboarding/Step1";
import OnboardingStep2 from "pages/onboarding/Step2";
import OnboardingStep3 from "pages/onboarding/Step3";
import OnboardingStep4 from "pages/onboarding/Step4";
import OnboardingStepPersonalDataUSA from "pages/onboarding/StepPersonalDataUSA";
import OnboardingStep5 from "pages/onboarding/Step5";
import OnboardingStepAddressUSA from "pages/onboarding/StepAddressUSA";
import OnboardingStep6 from "pages/onboarding/Step6";
import OnboardingStepOccupationUSA from "pages/onboarding/StepOccupationUSA";
import OnboardingStepPEP from "pages/onboarding/StepPEP";
import OnboardingInvitationCodeSendFail from "pages/onboarding/_components/InvitationCodeSendFail";
import EnrollmentIndex from "pages/enrollment/Index";
import EnrollmentError from "pages/enrollment/Error";
import EnrollmentStep1 from "pages/enrollment/Step1";
import EnrollmentStep3Part1 from "pages/enrollment/Step3Part1";
import EnrollmentStep3Part2 from "pages/enrollment/Step3Part2";
import EnrollmentStep3Part3 from "pages/enrollment/Step3Part3";
import EnrollmentStep3Part4 from "pages/enrollment/Step3Part4";
import EnrollmentStepIRS from "pages/enrollment/StepIRS";
import EnrollmentSuccess from "pages/enrollment/Success";
import RequestInvitationCode from "pages/enrollment/RequestInvitationCode";
import RequestInvitationCodeSuccess from "pages/enrollment/RequestInvitationCodeSuccess";
import PrivateRoutes from "routes/PrivateRoutes";
import PrivacyPolicy from "pages/_components/PrivacyPolicy";
import TermsAndConditions from "pages/_components/TermsAndConditions";
import SupportInfo from "pages/_components/SupportInfo";
import { selectors as loginSelectors } from "reducers/login";
import { REGION_USA } from "constants.js";
import ProcessDisclaimer from "pages/_components/ProcessDisclaimer";
import ProcessDisclaimerClaveToken from "pages/_components/ProcessDisclaimerClaveToken";
import TokenPassword from "pages/settings/TokenPassword";
import TokenRandomCode from "pages/settings/TokenRandomCode";
import RecoverToken from "pages/settings/_components/token/RecoverTokenPassword";
import SelectChannel from "pages/settings/_components/token/SelectChannel";
import RecoveryPassAndUserpassNoneToken from "pages/recoveryPassAndUserpass/RecoveryPassAndUserpassNoneToken";

class Routes extends Component {
    static propTypes = {
        hasActiveSession: bool,
        isMobile: bool.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
        activeRegion: string,
    };

    static defaultProps = {
        hasActiveSession: false,
        activeRegion: "",
    };

    shouldComponentUpdate(nextProps) {
        const {
            location: { pathname },
            hasActiveSession,
        } = this.props;
        // With oauth, the login step 2 sets an active session before routing to step3
        // Changing the value of active session triggers an additional transition animation
        // This avoids that unnecessary re-render
        if (pathname.includes("loginStep2")) {
            if (!hasActiveSession && nextProps.hasActiveSession) {
                return false;
            }
        }
        return true;
    }

    render() {
        const { hasActiveSession, isMobile, activeRegion } = this.props;

        return (
            <Switch>
                <Route exact path="/serverError" component={ServerError} />
                <Route exact path="/error" component={Error} />

                <ExternalLayout exact path="/assistant" component={AssistantLogin} />
                <ExternalLayout exact path="/assistant/:pageToken" component={AssistantLogin} />
                <ExternalLayout exact path="/" component={LoginStep1} />
                <ExternalLayout exact path="/loginStep1" component={LoginStep1} />
                <ExternalLayout exact path="/loginStep2" component={LoginStep2} />
                <ExternalLayout exact path="/loginStep3" component={LoginStep3} />
                <ExternalLayout exact path="/loginStep4" showLogo={false} component={LoginStep4} />
                <ExternalLayout exact path="/loginStep5" component={LoginAssistantStep} />

                <ExternalLayout exact path="/tokenPassword" component={TokenPassword} />
                <ExternalLayout exact path="/tokenRandomCode" component={TokenRandomCode} />

                <ExternalLayout exact path="/recoveryPassword/step1" component={RecoveryPassStep1} />
                <ExternalLayout exact path="/recoveryPassword/step2" component={RecoveryPassStep2} />
                <ExternalLayout exact path="/recoveryPassword/step3" component={RecoveryPassStep3} />
                <ExternalLayout exact path="/recoveryPassword/step4" component={RecoveryPassStep4} />
                <ExternalLayout exact path="/recoveryPassword/step2/processDisclaimer" component={ProcessDisclaimer} />
                <ExternalLayout exact path="/recoveryPassword/step2/processDisclaimerClaveToken" component={ProcessDisclaimerClaveToken} />

                <ExternalLayout exact path="/recoveryUserpass/step0" component={RecoveryUserpassStep0} />
                <ExternalLayout exact path="/recoveryUserpass/step1" component={RecoveryUserpassStep1} />
                <ExternalLayout exact path="/recoveryUserpass/step2" component={RecoveryUserpassStep2} />
                <ExternalLayout exact path="/recoveryUserpass/step3" component={RecoveryUserpassStep3} />

                <ExternalLayout exact path="/recoveryPassAndUserpass/step2" component={RecoveryPassAndUserpassStep2} />
                <ExternalLayout exact path="/recoveryPassAndUserpass/step3" component={RecoveryPassAndUserpassStep3} />
                <ExternalLayout
                    exact
                    path="/recoveryPassAndUserpass/inactiveToken"
                    component={RecoveryPassAndUserpassNoneToken}
                />

                <ExternalLayout exact path="/session/expiredByMaxAttempts" component={ExpiredByMaxAttempts} />
                <BackofficeLayout exact path="/forms/backoffice/ticket" component={BackofficeTransaction} />

                <ExternalLayout exact path="/languageSelection" component={LanguageSelection} />
                <ExternalLayout exact path="/regionSelection" component={RegionSelection} />

                <EnrollmentLayout exact path="/noUser" component={NoUser} />

                <EnrollmentLayout step={7} exact path="/enrollment" component={EnrollmentIndex} showBack />
                <EnrollmentLayout
                    step={7}
                    exact
                    path="/enrollment/code/:code/:lang/:reg?"
                    component={EnrollmentIndex}
                />
                <EnrollmentLayout
                    step={7}
                    exact
                    path="/credentialWhitening/code/:code/:lang/:reg?"
                    component={EnrollmentIndex}
                />
                <EnrollmentLayout exact path="/enrollment/error" component={EnrollmentError} />

                <EnrollmentLayout
                    exact
                    path="/enrollment/requestInvitationCode"
                    component={RequestInvitationCode}
                    mustGobackWithClose
                    showBack
                />
                <EnrollmentLayout
                    exact
                    path="/enrollment/requestInvitationCode/success"
                    component={RequestInvitationCodeSuccess}
                />
                <EnrollmentLayout step={8} exact path="/enrollment/step1" component={EnrollmentStep1} showBack />
                <EnrollmentLayout
                    step={8}
                    exact
                    path="/credentialWhitening/step1"
                    component={EnrollmentStep1}
                    showBack
                />
                <EnrollmentLayout
                    step={9}
                    exact
                    path="/enrollment/step3part1"
                    component={EnrollmentStep3Part1}
                    showBack
                />
                <EnrollmentLayout
                    step={9}
                    exact
                    path="/credentialWhitening/step3part1"
                    component={EnrollmentStep3Part1}
                    showBack
                />
                <EnrollmentLayout
                    step={10}
                    exact
                    path="/enrollment/step3part2"
                    component={EnrollmentStep3Part2}
                    showBack
                />
                <EnrollmentLayout
                    step={10}
                    exact
                    path="/credentialWhitening/step3part2"
                    component={EnrollmentStep3Part2}
                    showBack
                />
                <EnrollmentLayout
                    step={11}
                    exact
                    path="/enrollment/step3part3"
                    component={EnrollmentStep3Part3}
                    showBack
                />
                <EnrollmentLayout
                    step={12}
                    exact
                    path="/enrollment/step3part4"
                    component={EnrollmentStep3Part4}
                    showBack
                />
                <EnrollmentLayout exact path="/enrollment/stepIRS" component={EnrollmentStepIRS} showBack />

                <EnrollmentLayout exact path="/enrollment/success" component={EnrollmentSuccess} />
                <EnrollmentLayout exact path="/credentialWhitening/success" component={EnrollmentSuccess} />
                <EnrollmentLayout
                    exact
                    path="/enrollment/termsAndConditions"
                    component={() => <TermsAndConditions fromOnboarding />}
                    mustGobackWithClose
                    showBack
                />

                <EnrollmentLayout exact path="/onboarding" component={NoUser} />
                <EnrollmentLayout exact path="/onboarding/step0" component={OnboardingStep0} showBack />
                <EnrollmentLayout exact path="/onboarding/stepCIP" component={OnboardingStepCIP} showBack />
                <EnrollmentLayout step={1} exact path="/onboarding/step1" component={OnboardingStep1} showBack />
                <EnrollmentLayout
                    step={2}
                    camera="landscape"
                    exact
                    path="/onboarding/step2/front"
                    transition="transition-change-feature"
                    component={OnboardingStep2}
                    showBack
                />
                <EnrollmentLayout
                    step={2}
                    exact
                    camera="landscape"
                    path="/onboarding/step2/front/processing"
                    transition="transition-change-feature"
                    component={Processing}
                />
                <EnrollmentLayout
                    step={2}
                    camera="landscape"
                    exact
                    path="/onboarding/step2/back"
                    transition="transition-change-feature"
                    component={OnboardingStep2}
                />
                <EnrollmentLayout
                    step={2}
                    exact
                    camera="landscape"
                    path="/onboarding/step2/back/processing"
                    transition="transition-change-feature"
                    component={Processing}
                />
                <EnrollmentLayout
                    step={3}
                    camera
                    exact
                    path="/onboarding/step3"
                    transition="transition-change-feature"
                    component={OnboardingStep3}
                />
                <EnrollmentLayout
                    step={3}
                    exact
                    path="/onboarding/step3/processing"
                    transition="transition-change-feature"
                    component={Processing}
                />
                {activeRegion !== REGION_USA ? (
                    <EnrollmentLayout step={4} exact path="/onboarding/step4" component={OnboardingStep4} />
                ) : (
                    <EnrollmentLayout
                        step={4}
                        exact
                        path="/onboarding/step4"
                        component={OnboardingStepPersonalDataUSA}
                    />
                )}
                {activeRegion !== REGION_USA ? (
                    <EnrollmentLayout step={5} exact path="/onboarding/step5" component={OnboardingStep5} showBack />
                ) : (
                    <EnrollmentLayout
                        step={5}
                        exact
                        path="/onboarding/step5"
                        component={OnboardingStepAddressUSA}
                        showBack
                    />
                )}
                {activeRegion !== REGION_USA ? (
                    <EnrollmentLayout step={6} exact path="/onboarding/step6" component={OnboardingStep6} showBack />
                ) : (
                    <EnrollmentLayout
                        step={6}
                        exact
                        path="/onboarding/step6"
                        component={OnboardingStepOccupationUSA}
                        showBack
                    />
                )}
                <EnrollmentLayout step={6} exact path="/onboarding/stepPEP" component={OnboardingStepPEP} showBack />
                <EnrollmentLayout exact path="/onboarding/*/error" component={OnboardingError} />
                <EnrollmentLayout
                    exact
                    path="/onboarding/SafariSuggestionPage"
                    component={OnboardingSafariSuggestionPage}
                />
                <EnrollmentLayout
                    exact
                    path="/onboarding/invitation/fail"
                    component={OnboardingInvitationCodeSendFail}
                />
                <EnrollmentLayout
                    exact
                    path="/onboarding/termsAndConditions"
                    component={() => <TermsAndConditions fromOnboarding />}
                    mustGobackWithClose
                />

                {!hasActiveSession && <ExternalLayout exact path="/privacyPolicy" component={PrivacyPolicy} />}
                {!hasActiveSession && (
                    <ExternalLayout exact path="/termsAndConditions" component={TermsAndConditions} />
                )}
                {!hasActiveSession && <ExternalLayout exact path="/support" component={SupportInfo} />}
                {isMobile && (
                    <ExternalLayout
                        exact
                        path="/settings/token/recoverPass"
                        component={RecoverToken}
                        transition="transition-flow-open"
                    />
                )}
                {isMobile && <ExternalLayout exact path="/settings/token/selectChannel" component={SelectChannel} />}
                <PrivateRoutes />
            </Switch>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    activeRegion: loginSelectors.getRegion(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(Routes)));
