import React, { Component, Fragment } from "react";
import { func, arrayOf, shape, number, bool } from "prop-types";
import { Marker, InfoWindow, GoogleApiWrapper, Map } from "google-maps-react";

import * as i18n from "util/i18n";
import MapPin from "styles/images/ubicacion.png";

const ContentWrapper = ({ children, ...rest }) => children(rest);

export class PoiMap extends Component {
    static google = window.google;

    static propTypes = {
        onCenterChange: func.isRequired,
        positions: arrayOf(
            shape({
                idPoi: number,
                idPoiType: number,
                latitude: number,
                longitude: number,
                visible: bool,
            }),
        ).isRequired,
        center: shape({ lat: number, lng: number }).isRequired,
        currentLocation: shape({ lat: number, lng: number }).isRequired,
        google: shape().isRequired,
        zoom: number.isRequired,
        selectedPoi: number,
    };

    static defaultProps = {
        selectedPoi: null,
    };

    centerMoved = (_, map) => {
        const { onCenterChange } = this.props;
        onCenterChange({
            lat: map.center.lat(),
            lng: map.center.lng(),
        });
    };

    pathToMarker = (type) => {
        if (window.cordova) {
            return require(`styles/images//markerFilter${type}.svg`).replace(/^\//, ""); // eslint-disable-line
        }
        return require(`styles/images/markerFilter${type}.svg`); // eslint-disable-line
    };

    render() {
        const {
            positions,
            center,
            currentLocation,
            zoom,
            google,
            selectedPoi,
            onInfoClose,
            onMarkerClick,
        } = this.props;

        const markersProps = positions.map((position, i) => ({
            key: position.idPoi,
            name: position.name,
            position: { lat: position.latitude, lng: position.longitude },
            title: position.name,
            description: (
                <div>
                    <h4>{position.poiName}</h4>
                    <p
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: position.poiDescription,
                        }}
                    />
                </div>
            ),
            onClick: () => onMarkerClick(position, i),
            icon: {
                url: this.pathToMarker(position.idPoiType),
                anchor: new google.maps.Point(32, 32),
                scaledSize: new google.maps.Size(32, 32),
            },
        }));
        return (
            <Map
                zoomControl={false}
                mapTypeControl={false}
                scaleControl={false}
                streetViewControl={false}
                panControl={false}
                rotateControl={false}
                fullscreenControl={false}
                google={google}
                initialCenter={center}
                center={center}
                zoom={zoom}
                onDragend={this.centerMoved}>
                <ContentWrapper>
                    {(mapProps) => (
                        <Fragment>
                            <Marker
                                {...mapProps}
                                name="Current Marker"
                                position={currentLocation}
                                title={i18n.get("pointsofinterest.marker.currentPosition")}
                                description={
                                    <div>
                                        <p>{i18n.get("pointsofinterest.marker.currentPosition")}</p>
                                    </div>
                                }
                                icon={{
                                    url: MapPin,
                                    anchor: new google.maps.Point(32, 32),
                                    scaledSize: new google.maps.Size(32, 32),
                                }}
                            />
                            {markersProps.map((markerProps) => (
                                <Marker {...mapProps} {...markerProps} />
                            ))}
                            <InfoWindow
                                {...mapProps}
                                marker={
                                    selectedPoi !== null &&
                                    new google.maps.Marker({ ...markersProps[selectedPoi], map: mapProps.map })
                                }
                                onClose={onInfoClose}
                                visible={selectedPoi !== null}>
                                {selectedPoi !== null && markersProps[selectedPoi].description}
                            </InfoWindow>
                        </Fragment>
                    )}
                </ContentWrapper>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyAeG8KAM9fZ7amUikdy5AZvsD5bYCAXH40",
})(PoiMap);
