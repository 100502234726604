import React, { Component } from "react";
import { string } from "prop-types";
import I18n from "pages/_components/I18n";

class Loader extends Component {
    static propTypes = {
        className: string,
    };

    static defaultProps = {
        className: "",
    };

    render() {
        const { className } = this.props;

        return (
            <div className={`preloader ${className}`} aria-live="polite">
                <div className="loader">
                    <div />
                    <div />
                    <I18n id="global.loading" componentProps={{ className: "visually-hidden" }} />
                </div>
            </div>
        );
    }
}

export default Loader;
