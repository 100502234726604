import React, { Component, Fragment } from "react";
import { bool, shape, string, func, arrayOf } from "prop-types";

import * as i18nUtils from "util/i18n";
import { permissionsAggrupation } from "util/permissions";

import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import PermissionsList from "pages/administration/_components/PermissionsList";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import InfoTag from "pages/_components/InfoTag";

class PermissionsConfirm extends Component {
    static propTypes = {
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        actions: shape({}).isRequired,
        user: shape({}),
        hasMassiveEnabled: bool,
        fetching: bool,
        routerActions: shape({
            goBack: func,
        }).isRequired,
        formActions: shape({
            signTransaction: func,
        }).isRequired,
        idActivity: string,
        idTransaction: string,
        permissions: shape({}),
        oldPermissions: shape({}),
    };

    static defaultProps = {
        hasMassiveEnabled: false,
        fetching: false,
        idActivity: null,
        idTransaction: null,
        user: null,
        permissions: null,
        oldPermissions: null,
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, permissions, user, idTransaction, idActivity, oldPermissions } = this.props;

        if (!idTransaction) {
            actions.updatePermissionsRequest(
                {
                    permissions,
                    oldPermissions,
                    credentials,
                    idUser: user.idUser,
                    status: user.idUserStatus,
                    userFullName: user.fullName,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    renderHeader = () => {
        const { user, hasMassiveEnabled } = this.props;
        const isAdministrator = !hasMassiveEnabled;

        if (user) {
            const { fullName, idUserStatus } = user;

            return (
                <Fragment>
                    <div className="admin-detail-head admin-detail-head-section px-0">
                        <div>
                            <Head onBack={this.handleBack} />
                        </div>
                        {fullName && (
                            <div className="page-title px-0 mb-3">
                                <I18n
                                    component="h1"
                                    componentProps={{ className: "d-flex align-items-center" }}
                                    id="administration.configuratePermissions.title"
                                    fullName={fullName}
                                />
                                <div className="data-wrapper data-wrapper-flex ml-2">
                                    {isAdministrator && (
                                        <InfoTag
                                            type="default"
                                            message={i18nUtils.get(`administration.administrator`)}
                                            tagBackground="#707070"
                                        />
                                    )}
                                    {user && (
                                        <InfoTag
                                            type="default"
                                            message={i18nUtils.get(`user.status.${idUserStatus}`)}
                                            tagBackground={idUserStatus === "blocked" ? "#fc5f5f" : "#0be0a2"}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Fragment>
            );
        }
        return null;
    };

    render() {
        const { fetching, credentialGroups, user, permissions, oldPermissions } = this.props;
        const { newPermissionsToShow, removePermissionsToShow } = permissionsAggrupation(permissions, oldPermissions);

        return (
            <Fragment>
                {this.renderHeader()}
                <MainContainer
                    showLoader={fetching && !Object.keys(user).length}
                    viewContentClassName="confirmation__container pt-0">
                    <Container
                        className="align-items-center flex-grow px-0 container--layout with-margin mb-0"
                        gridClassName="confirmation__form confirmation__box">
                        <Container
                            className="container--layout align-items-center flex-grow cmf-container-white"
                            gridClassName="form-content">
                            <I18n
                                component="h2"
                                componentProps={{ className: "cmf-title-center mt-4" }}
                                id="administration.configuratePermissions.confirm.subtitle"
                            />

                            <Col className="col max-xl-480 px-xl-0" md={9} lg={9}>
                                <div className="transfer-block permission-confirm">
                                    <div className="transfer-data">
                                        <Container.ColumnBody columnClass="list-wrapper">
                                            {newPermissionsToShow && (
                                                <>
                                                    <I18n
                                                        component="h3"
                                                        id="administration.configuratePermissions.confirm.added.subtitle"
                                                        componentProps={{
                                                            className: "cmf-title-center",
                                                        }}
                                                    />

                                                    <PermissionsList
                                                        permissions={newPermissionsToShow}
                                                        withoutBorderItem>
                                                        {(list) => {
                                                            if (list) {
                                                                return <Fragment>{list}</Fragment>;
                                                            }
                                                            return <I18n id="administration.permissions.empty" />;
                                                        }}
                                                    </PermissionsList>
                                                </>
                                            )}
                                            {removePermissionsToShow && (
                                                <>
                                                    <I18n
                                                        component="h3"
                                                        id="administration.configuratePermissions.confirm.removed.subtitle"
                                                        componentProps={{
                                                            className: "cmf-title-center",
                                                        }}
                                                    />
                                                    <PermissionsList
                                                        permissions={removePermissionsToShow}
                                                        withoutBorderItem>
                                                        {(list) => {
                                                            if (list) {
                                                                return <Fragment>{list}</Fragment>;
                                                            }
                                                            return <I18n id="administration.permissions.empty" />;
                                                        }}
                                                    </PermissionsList>
                                                </>
                                            )}
                                        </Container.ColumnBody>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <div>
                            <AdministrationFormConfirmation
                                credentialGroups={credentialGroups}
                                onSubmit={this.handleSubmit}
                                MDcolumnWidth={12}
                                LGcolumnWidth={12}
                                buttonLabel="global.confirm"
                                buttonImage="images/arrowRight.svg"
                                buttonImageClass="mr-2"
                                formContainerClassName="confirmation__container"
                            />
                        </div>
                    </Container>
                </MainContainer>
            </Fragment>
        );
    }
}

export default PermissionsConfirm;
