import React, { Component } from "react";

import classNames from "classnames";
import { string, oneOf, bool } from "prop-types";

import * as utils from "util/general";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";

class TransactionStatus extends Component {
    static propTypes = {
        idTransactionStatus: string.isRequired,
        iconFidelity: oneOf(["transaction", "ticket"]),
        showLabel: bool,
        showIcon: bool,
        isDesktop: bool.isRequired,
        textClassName: string,
        ticketWrapperStyles: bool,
        showLabelClassName: string,
    };

    static defaultProps = {
        iconFidelity: "transaction",
        showLabel: false,
        showIcon: true,
        textClassName: "",
        ticketWrapperStyles: false,
        showLabelClassName: "",
    };

    render() {
        const {
            idTransactionStatus,
            iconFidelity,
            showIcon,
            showLabel,
            textClassName,
            ticketWrapperStyles,
            isDesktop,
            showLabelClassName,
        } = this.props;
        return (
            <div
                className={classNames(`text-label ${textClassName}`, {
                    " danger-label": idTransactionStatus === "FAILED" || idTransactionStatus === "CANCELLED",
                    " warning-label": idTransactionStatus === "RETURNED" || idTransactionStatus === "PROCESSING",
                    " pending-label": idTransactionStatus === "PENDING",
                    " draft-label": idTransactionStatus === "DRAFT",
                    " success-label": idTransactionStatus === "FINISHED",
                    " scheduled-label": idTransactionStatus === "SCHEDULED",
                })}>
                {showIcon && (
                    <Image
                        src={`images/${iconFidelity}StatusIcons/${utils.getTransactionStatusIcon(
                            idTransactionStatus,
                        )}.svg`}
                        className={classNames("svg-icon", {
                            "svg-icon-big": iconFidelity === "ticket",
                        })}
                        styles={
                            ticketWrapperStyles
                                ? { width: isDesktop ? "102.5px" : "54px", height: isDesktop ? "102.5px" : "54px" }
                                : {}
                        }
                    />
                )}

                {showLabel && (
                    <I18n
                        id={`transaction.status.${idTransactionStatus}`}
                        componentProps={{ className: showLabelClassName }}
                    />
                )}
            </div>
        );
    }
}

export default TransactionStatus;
