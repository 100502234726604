import { call, takeLatest, put } from "redux-saga/effects";
import { replace } from "react-router-redux";

import currentLocation from "util/geolocation";
import * as poiMiddleware from "middleware/pointsOfInterest";
import { types as poiTypes } from "reducers/pointsOfInterest";
import { actions as notificationActions } from "reducers/notification";

const sagas = [
    takeLatest(poiTypes.LOAD_POINTS_OF_INTEREST, getPointsOfinterest),
    takeLatest(poiTypes.UPDATE_POINTS, updatePointsList),
    takeLatest(poiTypes.LOAD_POINTS_FILTERS, getFilters),
];

export default sagas;

function pointsListMapper(pointsList) {
    return pointsList.map((point) =>
        Object.assign(point, {
            visible: true,
        }),
    );
}

function* getFilters() {
    const response = yield call(poiMiddleware.getTypes);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["poi"]));
        yield put(replace("/pointsOfInterest"));
    } else {
        const data = response.data.data.poiTypes;
        yield put({
            type: poiTypes.LOAD_POINTS_FILTERS_SUCCESS,
            data,
        });
    }
}

function* getPointsOfinterest({ payload }) {
    const position = yield call(currentLocation);

    const response = yield call(poiMiddleware.get, Object.assign({}, position, payload));

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["poi"]));
        yield put(replace("/pointsOfInterest"));
    } else {
        const data = pointsListMapper(response.data.data.poiList);
        const { isLastPage } = response.data.data;

        yield put({
            type: poiTypes.LOAD_POINTS_OF_INTEREST_SUCCESS,
            data: Object.assign(
                {},
                {
                    pointsList: data,
                    defaultPosition: position,
                    isLastPage,
                },
            ),
        });
    }
}

function* updatePointsList({ mapProps }) {
    const response = yield call(poiMiddleware.get, mapProps);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["poi"]));
        yield put(replace("/pointsOfInterest"));
    } else {
        const data = pointsListMapper(response.data.data.poiList);

        yield put({
            type: poiTypes.UPDATE_POINTS_SUCCESS,
            data,
        });
    }
}
