import React, { Component } from "react";
import { goBack, push } from "react-router-redux";
import Head from "pages/_components/Head";
import { bool, shape, func, string, arrayOf } from "prop-types";
import Container from "pages/_components/Container";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import Col from "react-bootstrap/lib/Col";
import Yup from "yup";
import { connect } from "react-redux";
import { compose } from "redux";
import Productselector from "pages/forms/_components/_fields/Productselector";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import moment from "moment";
import RecurrencyList from "pages/debinPayments/_components/recurrentDebin/RecurrencyList";
import Date from "pages/_components/fields/DateField";
import classNames from "classnames";
import { saveLocation } from "util/transaction";
import { actions as transactionsActions } from "reducers/transactions";

const FORM_ID = "debin.recurrency";

const statuses = [
    { value: "ACTIVA", label: "ACTIVA" },
    { value: "INACTIVA", label: "INACTIVA" },
    { value: "BLOQUEADA", label: "BLOQUEADA" },
];

const statusesV3 = [
    { value: "ACTIVA", label: "ACTIVA" },
    { value: "INACTIVA", label: "INACTIVA" },
    { value: "PENDIENTE", label: "PENDIENTE" },
    { value: "RECHAZADO", label: "RECHAZADO" },
];

class RecurrentDebins extends Component {
    static propTypes = {
        setSubmitting: func.isRequired,
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        accountToChange: shape({}).isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        location: shape({}).isRequired,
        values: shape({}).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
    };

    state = {
        showMessage: false,
        showForm: false,
    };

    componentDidMount() {
        const { dispatch, values, setSubmitting, accounts, location } = this.props;
        saveLocation(this.props);
        dispatch(debinActions.debinPreActivity());

        const selectedAccount = accounts.find((object) => object.idProduct === values.account);

        setSubmitting(true);
        dispatch(
            debinActions.getDebinRecurrencies(
                { ...values, cbu: selectedAccount?.cbu || "", pageNumber: 1 },
                setSubmitting,
            ),
        );
        dispatch(transactionsActions.saveTransactionRoute(location?.pathname));
    }

    componentDidUpdate(prevProps) {
        const { dispatch, accountToChange, fetching } = this.props;
        if (!fetching && prevProps?.accountToChange !== accountToChange) {
            dispatch(debinActions.debinPreActivity());
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    loadDebinRecurrency = () => {
        const { dispatch } = this.props;

        dispatch(debinActions.setdebinPrestacionData({}));
        dispatch(push("/payments/recurrent/load"));
    };

    changeMessageDisplay = () => {
        const { showMessage } = this.state;
        this.setState({ showMessage: !showMessage });
    };

    getMinDateFrom = () => {
        const daysBefore = configUtils.getInteger("frontend.debin.requests.min.dateFrom", 180);
        return moment().add(-daysBefore, "days");
    };

    rightContent = (useApiV3) => {
        const { dispatch, isDesktop } = this.props;

        if (isDesktop) {
            return (
                <Button
                    className="ml-1"
                    block
                    bsStyle="primary"
                    onClick={this.loadDebinRecurrency}
                    label={`${FORM_ID}.load.recurrency${useApiV3 ? "V3" : ""}`}
                />
            );
        }

        return (
            <div className="debin-recurrencies__buttons-header d-flex">
                <Button
                    className="ml-1"
                    block
                    bsStyle="primary"
                    image="plusMobile.svg"
                    onClick={() => {
                        dispatch(debinActions.cleanDebinSeller());
                        dispatch(push("/payments/recurrent/load"));
                    }}
                />
            </div>
        );
    };

    render() {
        const { isDesktop, isSubmitting, accounts, values, setSubmitting } = this.props;
        const { showMessage, showForm } = this.state;

        const defaultOption = { value: "TODOS", label: "TODOS" };
        const useApiV3 = configUtils.getBoolean("frontend.show.RecurringDebin.functionalities", false);
        const tooltipDebin = configUtils.get("debin.recurrencias.tooltip.text", "");

        return (
            <div className="debin-recurrencies__main">
                <Notification scopeToShow="debin.recurrency" />
                <Notification scopeToShow="debin" />

                <div className={classNames({ "admin-detail-head": isDesktop }, "px-0")}>
                    {isDesktop && <Head onBack={this.handleBack} />}
                    <Head
                        title={`${FORM_ID}.title${useApiV3 ? "V3" : ""}`}
                        titleClassName="my-0 align-center"
                        onBack={!isDesktop && this.handleBack}
                        headerClassName={classNames({ "blue-main-header-mobile": !isDesktop })}
                        rightContent={() => this.rightContent(useApiV3)}
                        messageTooltip={!useApiV3 && `${FORM_ID}.hint.title`}
                        hintText={useApiV3 && tooltipDebin}
                        alwaysShowMessage={isDesktop}
                    />
                </div>
                <div className={classNames("above-the-fold", { "mt-5": !showMessage && isDesktop })}>
                    {isDesktop && (
                        <Form>
                            {isDesktop && (
                                <Container>
                                    <Col className="hide-filter__btn-content mt-5 mb-3">
                                        <Button
                                            block
                                            bsStyle="outline"
                                            className="hide-filter__btn m-0"
                                            label={showForm ? "global.hide.filter" : "global.see.filter"}
                                            image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                                            onClick={() =>
                                                this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))
                                            }
                                        />
                                    </Col>
                                </Container>
                            )}
                            {showForm && (
                                <Container
                                    className={`flex-grow align-items-center my-2 ${isDesktop && "py-2"} ${
                                        isDesktop ? "container-white" : ""
                                    }`}
                                    gridClassName="form-content">
                                    <Col
                                        xs={12}
                                        md={4}
                                        lg={3}
                                        className={classNames(
                                            "pt-1 mt-2 mt-md-0",
                                            { "px-3": !isDesktop },
                                            { "px-1": isDesktop },
                                        )}>
                                        <Field
                                            name="account"
                                            component={Productselector}
                                            idField="account"
                                            data={{
                                                emptyOptionLabel: "",
                                                options: accounts,
                                            }}
                                            disabled={accounts.length === 0}
                                            isRequired
                                            mode="edit"
                                            value={values?.account}
                                            idForm={FORM_ID}
                                            isDesktop={isDesktop}
                                            customPlaceholder={i18n.get("transfers.productSelector.placeholder")}
                                            addOptionEverything
                                            formGroupClassName="mb-1 mt-md-2"
                                        />
                                    </Col>
                                    <Col
                                        xs={12}
                                        md={4}
                                        lg={2}
                                        className={classNames("pt-1", { "px-3": !isDesktop }, { "px-1": isDesktop })}>
                                        <Field
                                            component={TextField}
                                            idForm={FORM_ID}
                                            name="seller"
                                            type="text"
                                            pattern="\d*"
                                            inputmode="numeric"
                                            formGroupClassName="mb-1 mt-md-2"
                                            maxLength={11}
                                        />
                                    </Col>
                                    <Col
                                        xs={12}
                                        md={4}
                                        lg={2}
                                        className={classNames("pt-1", { "px-3": !isDesktop }, { "px-1": isDesktop })}>
                                        <Field
                                            component={Selector}
                                            hidePlaceholder
                                            options={[defaultOption, ...(useApiV3 ? statusesV3 : statuses)]}
                                            idForm={FORM_ID}
                                            name="status"
                                            isRequired
                                            formGroupClassName="mb-1 mt-md-2"
                                        />
                                    </Col>
                                    <Col
                                        xs={12}
                                        md={4}
                                        lg={2}
                                        className={classNames("pt-1", { "px-3": !isDesktop }, { "px-1": isDesktop })}>
                                        <Field
                                            idField="createdFrom"
                                            formikValueFirst
                                            component={Date}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="createdFrom"
                                            customKey={
                                                isDesktop
                                                    ? useApiV3
                                                        ? `${FORM_ID}.createdFrom.labelV3`
                                                        : ""
                                                    : "echeqs.from.label"
                                            }
                                            selectsEnd
                                            value={values.createdFrom}
                                            startDate={new Date()}
                                            maxDate={values.createdTo}
                                            minDate={this.getMinDateFrom()}
                                            formGroupClassName="mb-1 mt-md-2"
                                        />
                                    </Col>
                                    <Col
                                        xs={12}
                                        md={4}
                                        lg={2}
                                        className={classNames("pt-1", { "px-3": !isDesktop }, { "px-1": isDesktop })}>
                                        <Field
                                            idField="createdTo"
                                            formikValueFirst
                                            component={Date}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="createdTo"
                                            customKey={
                                                isDesktop
                                                    ? useApiV3
                                                        ? `${FORM_ID}.createdTo.labelV3`
                                                        : ""
                                                    : "echeqs.to.label"
                                            }
                                            value={values.To}
                                            selectsEnd
                                            startDate={new Date()}
                                            minDate={values.createdFrom}
                                            formGroupClassName="mb-1 mt-md-2"
                                        />
                                    </Col>

                                    <Col
                                        xs={12}
                                        md={4}
                                        lg={1}
                                        className={classNames(
                                            "d-flex aling-items-center mt-3 pt-3",
                                            { "px-3": !isDesktop },
                                            { "px-1": isDesktop },
                                        )}>
                                        <Button
                                            type="submit"
                                            label={`${FORM_ID}.search`}
                                            className="mr-0 max-height-3"
                                            bsStyle="primary"
                                            loading={isSubmitting}
                                        />
                                    </Col>
                                </Container>
                            )}
                        </Form>
                    )}
                    <PageLoading className="line-loader" loading={isSubmitting}>
                        <RecurrencyList setSubmitting={setSubmitting} isDesktop={isDesktop} />
                    </PageLoading>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: debinSelectors.getAccounts(state),
    filters: debinSelectors.getFilterRecurrency(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            ...props.filters,
        }),
        validationSchema: () =>
            Yup.lazy(() => {
                const validationObject = {
                    createdFrom: Yup.string()
                        .nullable()
                        .required(i18n.get("accounts.new.account.field.error.required")),
                    createdTo: Yup.string()
                        .nullable()
                        .required(i18n.get("accounts.new.account.field.error.required")),
                    account: Yup.string()
                        .nullable()
                        .required(i18n.get("accounts.new.account.field.error.required")),
                    status: Yup.string()
                        .nullable()
                        .required(i18n.get("accounts.new.account.field.error.required")),
                };

                return Yup.object().shape(validationObject);
            }),
        handleSubmit: (formFilters, formikBag) => {
            const { setSubmitting } = formikBag;
            const { dispatch, accounts } = formikBag.props;

            const selectedAccount = accounts.find((object) => object.idProduct === formFilters.account);

            setSubmitting(true);
            dispatch(
                debinActions.getDebinRecurrencies(
                    { ...formFilters, cbu: selectedAccount?.cbu || "", pageNumber: 1 },
                    setSubmitting,
                ),
            );
        },
    }),
)(RecurrentDebins);
