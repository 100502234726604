import React, { useState } from "react";
import { Field, Form, withFormik } from "formik";
import { func, bool, arrayOf, shape, string } from "prop-types";
import Checkbox from "pages/_components/Checkbox";

import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { FORM_ID, FORM_LIST_ID } from "constants/servicePayments";
import classNames from "classnames";

import Button from "pages/_components/Button";
import Productselector from "pages/forms/_components/_fields/Productselector";
import ContextMenu from "pages/_components/ContextMenu";
import Pagination from "pages/_components/pagination/Pagination";

import Table from "pages/_components/Table";

import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";

import Heading from "pages/_components/Heading";
import InfoTag from "pages/_components/InfoTag";

import { Col } from "react-bootstrap";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import { selectors as accountsSelectors } from "reducers/accounts";
import Container from "pages/_components/Container";
import { compose } from "redux";
import { connect } from "react-redux";
import { actions as paymentsAFIPActions } from "reducers/paymentsAFIP";
import moment from "moment";

const MultipleAFIPListHeader = ({
    isSmallDesktop,
    isDesktop,
    accounts,
    allSelected,
    handleSelectAll,
    payments,
    currencies,
    dispatch,
    submitForm,
    setErrors,
    setTouched,
    handleSetPayments,
    setSelectedPayments,
    selectedPayments,
    setHasPagination,
    filterValues: { paymentType, generatedVEP, numberVEP, taxpayerCUIT, dateFrom, dateTo, thirdPartyCuit },
    args: { pageNumber, totalPages },
}) => {
    const [selectedAccount, setSelectedAccount] = useState(null);

    const accountsArs = accounts?.filter((account) => account.currency === currencies[0].id.toString());

    const onChangeAccount = (account) => {
        const selAccount = accounts.find((object) => object.idProduct === account);
        if (selAccount) {
            setSelectedAccount(selAccount);
        }
    };

    const handleSelectOne = ({ key: paymentKey, isPending, actions }) => {
        if (isPending || !actions.isPaymentAllowed) {
            return;
        }
        const updatedPayments = payments.map(({ isChecked, key, ...payment }) => ({
            ...payment,
            key,
            isChecked: paymentKey === key ? !isChecked : isChecked,
        }));
        handleSetPayments(updatedPayments);
        const alreadySelected = selectedPayments.some((p) => p.key === paymentKey);
        const currPayment = updatedPayments.find((p) => p.key === paymentKey);

        if (currPayment.isChecked && !alreadySelected) {
            setSelectedPayments([...selectedPayments, currPayment]);
        } else if (!currPayment.isChecked) {
            setSelectedPayments(selectedPayments.filter((p) => p.key !== paymentKey));
        }
    };

    const handleUniquePayment = (payment) => {
        const { isChecked, key: paymentKey } = payment;

        if (isChecked) {
            const updatedPayments = payments.map(({ key, ...rest }) => ({
                ...rest,
                key,
                isChecked: key === paymentKey,
            }));
            handleSetPayments(updatedPayments);
            setSelectedPayments(payments.filter(({ key }) => key === payment.key));
            submitForm();
        } else {
            handleSelectOne(payment);
        }
    };

    const getContextMenuItems = (paymentAFIP) => {
        const {
            actions: { isPaymentAllowed, isDeleteAllowed },
        } = paymentAFIP;
        const contextMenuItems = [];

        if (isPaymentAllowed) {
            contextMenuItems.push({
                label: "payments.afip.list.actions.pay",
                onClick: () => {
                    handleUniquePayment(paymentAFIP);
                },
            });
        }

        if (isDeleteAllowed) {
            contextMenuItems.push({
                label: "payments.afip.list.actions.delete",
                onClick: () => {
                    dispatch(paymentsAFIPActions.deletePaymentAFIPPreFromMain(paymentAFIP));
                },
            });
        }
        return contextMenuItems;
    };

    const getPaymentsAFIPNextPage = (page) => {
        let dateFromFinal = paymentType === "stillToPay" ? null : dateFrom;
        let dateToFinal = paymentType === "stillToPay" ? null : dateTo;

        if (dateFrom && !dateTo) {
            dateToFinal = moment();
        }

        if (dateTo && !dateFrom) {
            dateFromFinal = dateTo;
        }

        setHasPagination(true);
        dispatch(
            paymentsAFIPActions.fetchPaymentsAFIP(
                paymentType,
                generatedVEP,
                numberVEP,
                taxpayerCUIT,
                dateFromFinal,
                dateToFinal,
                page,
                thirdPartyCuit,
            ),
        );
    };

    const renderPaymentsAFIP = () => {
        const list = payments.map((paymentAFIP) => {
            const { numberVEP: AFIPNumberVEP, conceptDesc, description, dueDate, amount, type, actions } = paymentAFIP;
            const contextMenuItems = getContextMenuItems(paymentAFIP);
            const readPaymentAFIP = () => dispatch(paymentsAFIPActions.readMultiplePaymentAFIP(paymentAFIP));

            if (isDesktop) {
                return (
                    <Table.Row className="container-white" key={AFIPNumberVEP}>
                        <Table.Data className="cursor-pointer" align="left">
                            <Heading.DataGroup
                                containerClassName={classNames(
                                    "data-wrapper-flex ",
                                    "data-text",
                                    "color-dark-grey",
                                    "font-light",
                                    {
                                        "mb-0": isSmallDesktop,
                                        "my-2": !isSmallDesktop,
                                    },
                                )}
                                data={
                                    <>
                                        <Checkbox
                                            className="mb-0"
                                            hideLabel
                                            checked={paymentAFIP.isChecked}
                                            name={AFIPNumberVEP}
                                            onChange={() => handleSelectOne(paymentAFIP)}
                                            isDisabled={paymentAFIP.isPending || !actions.isPaymentAllowed}
                                        />
                                        {Number(AFIPNumberVEP)}
                                        {paymentAFIP.isPending && (
                                            <InfoTag
                                                type="info"
                                                message={i18n.get(`payments.serviceAFIP.detail.status.PENDING`)}
                                                tagBackground="#FFE5B6"
                                                moreStyles={{
                                                    color: "black",
                                                }}
                                            />
                                        )}
                                    </>
                                }
                                dataClassName={classNames("d-flex", {
                                    "align-items-center": !isSmallDesktop,
                                })}
                            />
                        </Table.Data>
                        <Table.Data onClick={readPaymentAFIP} className="cursor-pointer" align="center">
                            <div className="data-text color-dark-grey font-light">{conceptDesc}</div>
                        </Table.Data>
                        <Table.Data onClick={readPaymentAFIP} className="cursor-pointer" align="center">
                            <div className="data-text color-dark-grey font-light">{description}</div>
                        </Table.Data>
                        <Table.Data onClick={readPaymentAFIP} className="cursor-pointer" align="right">
                            <FormattedDate
                                className="color-dark-grey font-light"
                                date={dueDate}
                                dateFormat="dd/MM/yyyy"
                            />
                        </Table.Data>
                        <Table.Data onClick={readPaymentAFIP} className="cursor-pointer" align="right">
                            <FormattedAmount
                                currency={amount.currency}
                                className="data-amount color-dark-grey font-light"
                                quantity={amount.quantity}
                                notBold
                            />
                        </Table.Data>
                        <Table.Data className="pr-0">
                            {contextMenuItems.length > 0 && (
                                <ContextMenu
                                    styleContext={{ display: "flex", justifyContent: "center" }}
                                    items={contextMenuItems}
                                />
                            )}
                        </Table.Data>
                    </Table.Row>
                );
            }
            return (
                <Container
                    key={AFIPNumberVEP}
                    className={classNames("my-3 py-3 d-flex container-white justify-content-center", {
                        "pl-3": contextMenuItems.length > 0,
                    })}>
                    {type === "PE" ? (
                        <Col xs={1} className="px-0 mt-2">
                            <Checkbox
                                className="mb-0"
                                hideLabel
                                checked={paymentAFIP.isChecked}
                                onChange={() => handleSelectOne(paymentAFIP)}
                                name={AFIPNumberVEP}
                                isDisabled={paymentAFIP.isPending || !actions.isPaymentAllowed}
                            />
                        </Col>
                    ) : (
                        undefined
                    )}
                    <Col
                        xs={type === "PE" ? 10 : 12}
                        onClick={readPaymentAFIP}
                        className={type === "PE" ? "px-0" : ""}
                        onKeyDown={readPaymentAFIP}
                        role="button"
                        tabIndex={0}>
                        {paymentAFIP.isPending && (
                            <InfoTag
                                type="info"
                                tagClass="w-50 m-0"
                                message={i18n.get(`payments.serviceAFIP.detail.status.PENDING`)}
                                tagBackground="#FFE5B6"
                                moreStyles={{
                                    color: "black",
                                }}
                            />
                        )}
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between container-details"
                            label={`${FORM_LIST_ID}.numberVEP`}
                            data={Number(AFIPNumberVEP)}
                        />

                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between container-details"
                            label={`${FORM_LIST_ID}.concept`}
                            data={conceptDesc}
                        />

                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between container-details"
                            label={`${FORM_LIST_ID}.description`}
                            data={description}
                        />

                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between container-details"
                            label={`${FORM_LIST_ID}.dueDate`}
                            data={<FormattedDate date={dueDate} dateFormat="dd/MM/yyyy" />}
                        />

                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between container-details"
                            label={`${FORM_LIST_ID}.amount`}
                            data={
                                <FormattedAmount
                                    currency={amount.currency}
                                    className="data-amount"
                                    quantity={amount.quantity}
                                    notBold
                                    small
                                />
                            }
                        />
                    </Col>
                    {contextMenuItems.length > 0 && (
                        <Col xs={1} aling="left" className="p-0 pt-15 justify-content-start">
                            <ContextMenu dropBtnClass="with-svg-dropdown" items={contextMenuItems} />
                        </Col>
                    )}
                </Container>
            );
        });

        return (
            <>
                {list.length > 0 && (
                    <Container className="flex-grow">
                        <Col md={12} className={`with-table-header-margin px-0 ${!isDesktop && "table-vep-mobile"}`}>
                            <Table>
                                {isDesktop && (
                                    <Table.Header className="container-white">
                                        <Table.HeaderData className="border-bottom-none pl-5" align="left">
                                            <I18n id={`${FORM_LIST_ID}.numberVEP`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData className="border-bottom-none" align="center">
                                            <I18n id={`${FORM_LIST_ID}.concept`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData className="border-bottom-none" align="center">
                                            <I18n id={`${FORM_LIST_ID}.description`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData className="border-bottom-none" align="right">
                                            <I18n id={`${FORM_LIST_ID}.dueDate`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData className="border-bottom-none" align="right">
                                            <I18n id={`${FORM_LIST_ID}.amount`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData className="pr-0 border-bottom-none" />
                                    </Table.Header>
                                )}
                                <Table.Body>{list}</Table.Body>
                            </Table>
                        </Col>
                    </Container>
                )}
            </>
        );
    };

    return (
        <>
            {payments.every((payment) => payment.type === "PE") ? (
                <Form autocomplete="off">
                    <Container rowClassName={!isDesktop ? "f-dir-col-reverse" : ""}>
                        <Col lg={3} sm={12} className="col py-2">
                            <Field
                                name="account"
                                component={Productselector}
                                idField="account"
                                idForm={FORM_ID}
                                data={{
                                    emptyOptionLabel: "",
                                    options: accountsArs,
                                }}
                                disabled={accountsArs.length === 0}
                                mode="edit"
                                isRequired
                                defaultValue={accountsArs.find((account) => account.favorite)?.idProduct}
                                isDesktop={isDesktop}
                                labelText="transfers.debitaccount.label"
                                customPlaceholder={i18n.get("payments.afip.list.generatedVEP.placeholder")}
                                onCustomChange={onChangeAccount}
                            />
                        </Col>
                        <Col lg={4} lgOffset={5} sm={12} className="d-flex justify-content-end">
                            <div
                                className={`d-flex flex-column justify-content-center text-${
                                    isDesktop ? "right" : "left max-width-50"
                                } text-uppercase pr-3`}>
                                <SelectedAccountsAmount
                                    list={selectedPayments.filter(({ isChecked }) => isChecked)}
                                    withoutTables
                                    leftOperations={false}
                                    selectedAccount={selectedAccount}
                                    setErrors={setErrors}
                                    setTouched={setTouched}
                                />
                            </div>
                            <div className="align-items-center d-flex">
                                <Button
                                    block={false}
                                    label={`${FORM_ID}.paySelected`}
                                    bsStyle="primary"
                                    type="submit"
                                    disabled={selectedPayments?.length === 0}
                                />
                            </div>
                        </Col>
                    </Container>
                    <div>
                        <Checkbox
                            className="mb-3 ml-3"
                            labelText={`${FORM_ID}.selectAll`}
                            name="selectAllPayments"
                            checked={allSelected}
                            onChange={handleSelectAll}
                        />
                    </div>
                </Form>
            ) : (
                undefined
            )}

            {renderPaymentsAFIP()}
            <div className="d-flex w-100 justify-content-end">
                <Pagination totalPages={totalPages} pageNumber={pageNumber} action={getPaymentsAFIPNextPage} />
            </div>
        </>
    );
};

MultipleAFIPListHeader.propTypes = {
    isSmallDesktop: bool,
    isDesktop: bool.isRequired,
    accounts: arrayOf(
        shape({
            idProduct: string.isRequired,
        }),
    ).isRequired,
    allSelected: func.isRequired,
    handleSelectAll: func.isRequired,
    payments: arrayOf(shape({})).isRequired,
    currencies: arrayOf(
        shape({
            id: string.isRequired,
        }),
    ).isRequired,
    dispatch: func.isRequired,
    submitForm: func.isRequired,
    setErrors: func.isRequired,
    setTouched: func.isRequired,
    handleSetPayments: func.isRequired,
    setSelectedPayments: func.isRequired,
    selectedPayments: arrayOf(shape({})).isRequired,
    setHasPagination: func.isRequired,
    filterValues: shape({}),
    args: shape({}).isRequired,
};

MultipleAFIPListHeader.defaultProps = {
    isSmallDesktop: false,
    filterValues: {},
};

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
});

const handlePrepareMultipleAFIP = ({ account }, formikBag) => {
    const {
        props: { dispatch, payments, accounts },
        setTouched,
        setErrors,
    } = formikBag;
    const selectedPaymentsAFIP = payments.filter(({ isChecked }) => isChecked);
    const debitAccountMultipleAFIP = accounts.find(({ idProduct }) => idProduct === account);
    const total = selectedPaymentsAFIP.reduce((acc, val) => acc + val?.amount?.quantity || val.monto, 0);
    const { balance } = debitAccountMultipleAFIP;

    if (total > balance) {
        setTouched({ account: true });
        setErrors({ account: i18n.get("services.multiplePayment.field.account.amountExceeded") });
    } else {
        setErrors({});
        dispatch(paymentsAFIPActions.cleanHandleBackFromTicket());
        dispatch(paymentsAFIPActions.payMultipleAFIPPre(selectedPaymentsAFIP, debitAccountMultipleAFIP, formikBag));
    }
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            account: "",
        }),
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    account: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                }),
            ),
        handleSubmit: (values, formikBag) => handlePrepareMultipleAFIP(values, formikBag),
    }),
)(MultipleAFIPListHeader);
