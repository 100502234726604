import React, { Component } from "react";
import { arrayOf, number, shape, string, func, bool } from "prop-types";

import "./_chat-messages.scss";
import Card from "pages/_components/Card/Card";
import Button from "pages/_components/Button";
import classNames from "classnames";

class ChatAttachment extends Component {
    static propTypes = {
        attachment: shape({
            idCommunication: number,
            files: arrayOf(
                shape({
                    idFile: number,
                    fileName: string,
                }),
            ),
        }),
        handleDownloadAttachment: func.isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        attachment: null,
    };

    getFileExtension = (fileName) => fileName.split(".").pop();

    drawAttachmentIcon = (fileName) => {
        if (fileName === "txt") {
            return "images/fileIconsByExtension/file-txt.svg";
        }
        if (fileName === "doc" || fileName === "docx") {
            return "images/fileIconsByExtension/file-doc.svg";
        }
        if (fileName === "xls" || fileName === "xlsx") {
            return "images/fileIconsByExtension/file-exel.svg";
        }
        if (fileName === "ppt" || fileName === "pptx") {
            return "images/fileIconsByExtension/file-ppt.svg";
        }
        if (fileName === "jpg") {
            return "images/fileIconsByExtension/file-jpg.svg";
        }
        if (fileName === "gif") {
            return "images/fileIconsByExtension/file-gif.svg";
        }
        if (fileName === "bmp") {
            return "images/fileIconsByExtension/file-bmp.svg";
        }
        if (fileName === "png") {
            return "images/fileIconsByExtension/file-png.svg";
        }
        return "images/fileIconsByExtension/file-default.svg";
    };

    render() {
        const { attachment, handleDownloadAttachment, isDesktop } = this.props;

        return attachment.fileList.map((file) => (
            <Card
                className={classNames("py-25 chat-download-card")}
                icon={this.drawAttachmentIcon(this.getFileExtension(file?.fileName))}
                isDesktop={isDesktop}
                title={
                    <div className="card__transaction-data-description d-flex justify-content-start">
                        <span className="mr-auto ml-2 communications__download-label">{file?.fileName}</span>
                    </div>
                }
                rightContent={
                    <div>
                        <Button
                            onClick={handleDownloadAttachment(file.idFile)}
                            target="_blank"
                            rel="noopener noreferrer"
                            label="global.download"
                            className="btn btn-outline download font-regular m-0 pl-0 pr-2 min-height-2 text-capitalize"
                            image="images/download_bold.svg"
                            imageStyle="mx-0"
                        />
                    </div>
                }
            />
        ));
    }
}
export default ChatAttachment;
