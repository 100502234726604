/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { selectors, actions } from "reducers/checks";
import { connect } from "react-redux";
import { shape, string, func, bool } from "prop-types";
import ContextMenu from "pages/_components/ContextMenu";
import { receivedEcheqsStatusActions, echeqShape } from "util/checks";
import DrawerModal from "pages/_components/drawer/DrawerModal";
import specialOptions, { receivedEcheqAction } from "pages/checks/Echeqs/EcheqActions/EcheqsActionMaker";
import Button from "pages/_components/Button";

const FORM_ID = "echeq";

class ReceivedContextMenu extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        echeq: shape(echeqShape).isRequired,
        isDesktop: bool.isRequired,
        environmentCuit: string.isRequired,
        serverSearchDate: string.isRequired,
        detailActions: bool,
        isSmallDesktop: bool,
    };

    static defaultProps = {
        detailActions: false,
        isSmallDesktop: false,
    };

    state = {
        showDrawer: false,
        contentDrawer: "",
    };

    echeqActions = (status) => {
        const actionsEcheqs = receivedEcheqsStatusActions;
        const { echeq, environmentCuit, dispatch, isDesktop, serverSearchDate } = this.props;
        let contextMenuActions = [];

        if (!isDesktop) {
            contextMenuActions = [
                {
                    label: "echeq.request.moreInfo.download",
                    onClick: () => {
                        dispatch(actions.downloadEcheqMobileInfo("pdf", echeq));
                    },
                },
            ];
        }

        contextMenuActions = [
            ...contextMenuActions,
            ...specialOptions(echeq, serverSearchDate, environmentCuit, (action, echeqInfo) =>
                receivedEcheqAction(
                    action,
                    echeqInfo,
                    dispatch,
                    (newState) => this.setState({ ...newState }),
                    isDesktop,
                ),
            ),
        ];
        if (actionsEcheqs[status]) {
            actionsEcheqs[status].forEach((action) => {
                contextMenuActions = [
                    ...contextMenuActions,
                    {
                        label: `${FORM_ID}.${action.toLowerCase()}.echeq`,
                        onClick: () => {
                            receivedEcheqAction(
                                action,
                                echeq,
                                dispatch,
                                (newState) => this.setState({ ...newState }),
                                isDesktop,
                            );
                        },
                    },
                ];
            });
        }

        return contextMenuActions;
    };

    render() {
        const { echeq, isDesktop, detailActions, isSmallDesktop } = this.props;
        const { showDrawer, contentDrawer, title } = this.state;

        const itemsContextMenu = this.echeqActions(echeq.estado);
        let filteredEcheqActions;
        let depositOrCustodyButton;
        let discountButton;
        if (itemsContextMenu.length > 0) {
            depositOrCustodyButton = itemsContextMenu.find(
                (item) => item.label === "echeq.deposit.echeq" || item.label === "echeq.custody.echeq",
            );
            discountButton = itemsContextMenu.find((item) => item.label === "echeq.negotiate.echeq");
            filteredEcheqActions = itemsContextMenu.filter(
                (item) => item.label !== depositOrCustodyButton?.label && item.label !== discountButton?.label,
            );
        }

        let maxButtonsActions;
        if (depositOrCustodyButton && discountButton) {
            maxButtonsActions = isSmallDesktop ? 0 : 2;
        } else if (depositOrCustodyButton || discountButton) {
            maxButtonsActions = isSmallDesktop ? 0 : 2;
        } else {
            maxButtonsActions = isSmallDesktop ? 2 : 4;
        }

        return (
            <>
                {detailActions && depositOrCustodyButton && (
                    <Button
                        className="btn-outline cmf-button-width"
                        block
                        label={depositOrCustodyButton.label}
                        onClick={depositOrCustodyButton.onClick}
                    />
                )}
                {detailActions && discountButton && (
                    <Button
                        className="btn-outline cmf-button-width"
                        block
                        label={discountButton.label}
                        onClick={discountButton.onClick}
                    />
                )}
                {detailActions &&
                    filteredEcheqActions &&
                    filteredEcheqActions.length <= maxButtonsActions &&
                    filteredEcheqActions?.map((item) => (
                        <Button
                            className="btn-outline cmf-button-width"
                            block
                            label={item.label}
                            key={item.label}
                            onClick={item.onClick}
                        />
                    ))}
                {detailActions && filteredEcheqActions?.length > maxButtonsActions && (
                    <ContextMenu isDesktop={isDesktop} items={filteredEcheqActions} />
                )}
                {!detailActions && itemsContextMenu.length > 0 && (
                    <ContextMenu isDesktop={isDesktop} items={itemsContextMenu} />
                )}
                {showDrawer && (
                    <DrawerModal
                        content={contentDrawer}
                        centerContentClassName="mx-5"
                        width={isDesktop ? "30vw" : "100vw"}
                        title={title}
                        isDesktop={isDesktop}
                        showDrawerRemote={showDrawer}
                        closeRemotely={() => this.setState({ showDrawer: false, contentDrawer: "" })}
                        drawerClassName="echeq__drawer"
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    echeqsList: selectors.getEcheqsList(state),
    environmentCuit: selectors.getEnvironmentCuit(state),
    serverSearchDate: selectors.getServerSearchDate(state),
});

export default connect(mapStateToProps)(ReceivedContextMenu);
