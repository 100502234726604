import React, { Component } from "react";
import { bool, arrayOf, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as accountsActions } from "reducers/accounts";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import MultiSelectField from "pages/_components/fields/MultiSelectField";

import * as i18n from "util/i18n";

const FORM_ID = "accounts.movements.filters.channels";

class ChannelFilter extends Component {
    static propTypes = {
        channels: arrayOf(string).isRequired,
        isSubmitting: bool.isRequired,
    };

    render() {
        const { channels, isSubmitting } = this.props;
        const filterOptionPrefix = "accounts.movements.filters.channel.option.";

        return (
            <Form>
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Col sm={12} md={6} className="col col-12 col-no-pad-mobile">
                        <Field
                            className="slideFromBottom"
                            allOption="all"
                            component={MultiSelectField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="channels"
                            options={channels}
                            i18nOptionsPrefix={filterOptionPrefix}
                        />
                    </Col>

                    <Col
                        sm={12}
                        md={4}
                        className="col col-12 col-no-pad-mobile"
                        style={{
                            alignSelf: "flex-end",
                        }}>
                        <Button bsStyle="primary" label="product.filters.filter" loading={isSubmitting} type="submit" />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
        channels: [],
    }),
    validationSchema: () =>
        Yup.object().shape({
            channels: Yup.array()
                .of(Yup.string())
                .min(1, i18n.get(`${FORM_ID}.channels.error`)),
        }),
    handleSubmit: ({ ...filters }, formikBag) => {
        const { dispatch, isDesktop, productId } = formikBag.props;
        if (isDesktop) {
            dispatch(accountsActions.details(productId, filters, formikBag));
        } else {
            dispatch(accountsActions.setMobileFilter({ filter: "channels", ...filters }));
            dispatch(push(`/accounts/${productId}/filters/results`));
        }
    },
})(ChannelFilter);
