/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import { string, obj, bool, shape, node } from "prop-types";

import Image from "pages/_components/Image";
import I18n from "../I18n";

export default function Hint(props) {
    const {
        idMessage,
        styleDiv,
        styleText,
        styleImage,
        classNameGroup,
        classNameHintP,
        blueBackground,
        buttonClassName,
        alwaysShowMessage,
        right,
        classNameMessage,
        classNameImage,
        defaultValue,
        nodeMessage,
        longText,
    } = props;
    Hint.propTypes = {
        idMessage: string,
        defaultValue: string,
        styleDiv: obj,
        styleText: obj,
        styleImage: shape({}),
        classNameGroup: string,
        classNameHintP: string,
        blueBackground: bool,
        buttonClassName: string,
        alwaysShowMessage: bool,
        right: bool,
        classNameMessage: string,
        classNameImage: string,
        nodeMessage: node,
        longText: bool,
    };
    Hint.defaultProps = {
        idMessage: "",
        defaultValue: null,
        styleDiv: undefined,
        styleText: undefined,
        styleImage: undefined,
        classNameGroup: "",
        classNameHintP: "",
        blueBackground: false,
        buttonClassName: "",
        alwaysShowMessage: false,
        right: false,
        classNameMessage: "",
        classNameImage: "",
        nodeMessage: null,
        longText: false,
    };

    const [showMessage, setShowMessage] = useState(alwaysShowMessage || false);

    const changeMessageDisplay = () => {
        setShowMessage(alwaysShowMessage || !showMessage);
    };

    return (
        <div className={`form-group d-flex p-relative ${classNameGroup}`} style={{ userSelect: "none", ...styleDiv }}>
            {showMessage && right && (
                <div style={{ ...styleText }} className={classNameMessage}>
                    <I18n
                        component="p"
                        componentProps={{ className: `hint-text text-right ${classNameHintP}` }}
                        id={idMessage}
                        defaultValue={defaultValue}
                        {...props}
                    />
                </div>
            )}
            {showMessage && !nodeMessage && right && (
                <div style={{ ...styleText }} className={classNameMessage}>
                    {nodeMessage}
                </div>
            )}
            <div style={styleImage} className={classNameImage}>
                <span
                    className={`hint-button mr-2 ${buttonClassName}`}
                    onClick={() => changeMessageDisplay()}
                    onKeyDown={() => changeMessageDisplay()}
                    role="button"
                    tabIndex={0}>
                    {showMessage ? (
                        <Image
                            src={blueBackground ? "images/infoWhiteFull.svg" : "images/info.svg"}
                            className={`hint-image-style ${showMessage && longText && "mb-56"}`}
                        />
                    ) : (
                        <Image
                            src={blueBackground ? "images/infoWhite.svg" : "images/infoUserInvite.svg"}
                            className="hint-image-style"
                        />
                    )}
                </span>
            </div>
            {showMessage && !nodeMessage && !right && (
                <div style={{ ...styleText }} className={classNameMessage}>
                    <I18n
                        component="p"
                        componentProps={{ className: `hint-text ${classNameHintP}` }}
                        id={idMessage}
                        defaultValue={defaultValue}
                        {...props}
                    />
                </div>
            )}
            {showMessage && nodeMessage && !right && (
                <div style={{ ...styleText }} className={classNameMessage}>
                    {nodeMessage}
                </div>
            )}
        </div>
    );
}
