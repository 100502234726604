import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool, arrayOf } from "prop-types";

import { selectors as ecomexSelectors } from "reducers/ecomex";

import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Image from "pages/_components/Image";

const FORM_ID = "ecomex.informativeRegime.detail";

class EcomexInformativeRegimeDetailList extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        optionSelected: shape({}).isRequired,
        notificationsDetail: arrayOf(shape({})).isRequired,
        affectationsDetail: arrayOf(shape({})).isRequired,
        impurationsAndNegotiations: arrayOf(shape({})).isRequired,
        boardingPermitsNegotiations: arrayOf(shape({})).isRequired,
    };

    renderHeaderElements = () => {
        const { optionSelected, isDesktop } = this.props;

        let headerElements = [];

        const getHeaderData = (text, aling) => {
            const alignHeader = isDesktop ? aling : "left";

            return (
                <Table.HeaderData align={alignHeader}>
                    <I18n id={`${FORM_ID}.${text}`} />
                </Table.HeaderData>
            );
        };

        switch (optionSelected) {
            case "1":
                headerElements = [
                    getHeaderData("talks.column.report"),
                    getHeaderData("talks.column.sendDate"),
                    getHeaderData("talks.column.noveltyDate"),
                    getHeaderData("talks.column.office"),
                    getHeaderData("talks.column.currency"),
                    getHeaderData("talks.column.amount"),
                ];
                break;
            case "2":
                headerElements = [
                    getHeaderData("impacts.column.affectation"),
                    getHeaderData("impacts.column.affectedAmount"),
                    getHeaderData("impacts.column.applicationDate"),
                    getHeaderData("impacts.column.balance"),
                ];
                break;
            case "3":
                headerElements = [
                    getHeaderData("dispatches.column.ab", "left"),
                    getHeaderData("dispatches.column.news"),
                    getHeaderData("dispatches.column.cert"),
                    getHeaderData("dispatches.column.ciu"),
                    getHeaderData("dispatches.column.sent"),
                    getHeaderData("dispatches.column.sendDate"),
                    getHeaderData("dispatches.column.newsDate"),
                    getHeaderData("dispatches.column.debtCurrency"),
                    getHeaderData("dispatches.column.accusedAmount", "right"),
                    getHeaderData("dispatches.column.aplicFob", "right"),
                    getHeaderData("dispatches.column.aplicFlete", "right"),
                    getHeaderData("dispatches.column.aplicSeguro", "right"),
                    getHeaderData("dispatches.column.concept"),
                    getHeaderData("dispatches.column.refOperation", "right"),
                ];
                break;
            case "4":
                headerElements = [
                    getHeaderData("talks.column.reference", "left"),
                    getHeaderData("talks.column.bcra"),
                    getHeaderData("talks.column.settlementDate"),
                    getHeaderData("talks.column.amountFOB", "right"),
                    getHeaderData("talks.column.amountFLETE", "right"),
                    getHeaderData("talks.column.amountSEGURO", "right"),
                ];
                break;
            default:
                break;
        }

        return headerElements;
    };

    renderLists = () => {
        const {
            isDesktop,
            notificationsDetail,
            affectationsDetail,
            impurationsAndNegotiations,
            boardingPermitsNegotiations,
            optionSelected,
        } = this.props;

        let listToRender;

        switch (optionSelected) {
            case "1":
                listToRender = notificationsDetail;
                break;
            case "2":
                listToRender = affectationsDetail;
                break;
            case "3":
                listToRender = impurationsAndNegotiations;
                break;
            case "4":
                listToRender = boardingPermitsNegotiations;
                break;
            default:
                break;
        }

        return (
            <>
                {listToRender && listToRender.length > 0 ? (
                    <Container className="flex-grow scrollable">
                        <Col md={12} className="table-with-border px-0">
                            <Table>
                                {isDesktop && (
                                    <Table.Header className="container-white">
                                        {this.renderHeaderElements()}
                                    </Table.Header>
                                )}
                                <Table.Body>{listToRender.map((item) => this.renderListsItem(item))}</Table.Body>
                            </Table>
                        </Col>
                    </Container>
                ) : (
                    <div className="text-center no-more-data">
                        <div className="illustration-wrapper">
                            <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                        </div>
                        <p className="text-lead">
                            <I18n id={`${FORM_ID}.empty.message`} />
                        </p>
                    </div>
                )}
            </>
        );
    };

    renderListsItem = (item) => {
        const { optionSelected } = this.props;

        switch (optionSelected) {
            case "1":
                return this.renderListsItemForNotifications(item);
            case "2":
                return this.renderListsItemForAffectations(item);
            case "3":
                return this.renderListsItemForImpurationsAndNegotiations(item);
            case "4":
                return this.renderListsItemForBoardingPermitsNegotiations(item);
            default:
                return null;
        }
    };

    renderListsItemForNotifications = (item) => {
        const { isDesktop } = this.props;
        const { reporte, fechaEnvio, fechaNovedad, nroDespacho, siglaDivisa, montoImputado } = item;

        const elementForList = [reporte, fechaEnvio, fechaNovedad, nroDespacho, siglaDivisa, montoImputado];

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    {elementForList.map((elementItem) => (
                        <Table.Data>
                            <div className="data-text">{elementItem !== null ? elementItem : "-"}</div>
                        </Table.Data>
                    ))}
                </Table.Row>
            );
        }

        const headerElements = this.renderHeaderElements();

        return (
            <div className="w-100 my-1">
                {elementForList.map((elementItem, index) => (
                    <Table.Row className="container-white">
                        {headerElements[index]}
                        <Table.Data align="right">
                            <div className="data-text">{elementItem !== null ? elementItem : "-"}</div>
                        </Table.Data>
                    </Table.Row>
                ))}
            </div>
        );
    };

    renderListsItemForAffectations = (item) => {
        const { isDesktop } = this.props;
        const { referenciaAfectacion, importeAfectacion, fechaAfectacion, saldoAfectacion } = item;

        const elementForList = [referenciaAfectacion, importeAfectacion, fechaAfectacion, saldoAfectacion];

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    {elementForList.map((elementItem) => (
                        <Table.Data>
                            <div className="data-text">{elementItem !== null ? elementItem : "-"}</div>
                        </Table.Data>
                    ))}
                </Table.Row>
            );
        }

        const headerElements = this.renderHeaderElements();

        return (
            <div className="w-100 my-1">
                {elementForList.map((elementItem, index) => (
                    <Table.Row className="container-white">
                        {headerElements[index]}
                        <Table.Data align="right">
                            <div className="data-text">{elementItem !== null ? elementItem : "-"}</div>
                        </Table.Data>
                    </Table.Row>
                ))}
            </div>
        );
    };

    renderListsItemForImpurationsAndNegotiations = (item) => {
        const { isDesktop } = this.props;
        const {
            fechaEnviado,
            fechaNovedad,
            tipoMovimiento,
            tipoNovedad,
            reporte,
            ciu,
            regEnviado,
            siglaDivisaDeuda,
            importe,
            impAplicadoFOB,
            impAplicadoFLETE,
            impAplicadoSEGURO,
            referenciaOperacion,
            concepto,
        } = item;

        const elementForList = [
            tipoMovimiento,
            tipoNovedad,
            reporte,
            ciu,
            regEnviado,
            fechaEnviado,
            fechaNovedad,
            siglaDivisaDeuda,
            importe,
            impAplicadoFOB,
            impAplicadoFLETE,
            impAplicadoSEGURO,
            concepto,
            referenciaOperacion,
        ];

        const alignments = [
            "left",
            "center",
            "center",
            "center",
            "center",
            "center",
            "center",
            "center",
            "right",
            "right",
            "right",
            "right",
            "right",
            "right",
        ];

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    {elementForList.map((elementItem, index) => (
                        <Table.Data align={alignments[index]}>
                            <div className="data-text">{elementItem !== null ? elementItem : "-"}</div>
                        </Table.Data>
                    ))}
                </Table.Row>
            );
        }

        const headerElements = this.renderHeaderElements();

        return (
            <div className="w-100 my-1">
                {elementForList.map((elementItem, index) => (
                    <Table.Row className="container-white">
                        {headerElements[index]}
                        <Table.Data align="right">
                            <div className="data-text">{elementItem !== null ? elementItem : "-"}</div>
                        </Table.Data>
                    </Table.Row>
                ))}
            </div>
        );
    };

    renderListsItemForBoardingPermitsNegotiations = (item) => {
        const { isDesktop } = this.props;
        const {
            bcra,
            fechaLiquidacion,
            importeFOB,
            importeFLETE,
            siglaOperacion,
            sucursalOperacion,
            numeroOperacion,
            importeSEGURO,
        } = item;

        const elementForList = [
            `${siglaOperacion} ${sucursalOperacion} ${numeroOperacion}`,
            bcra,
            fechaLiquidacion,
            importeFOB,
            importeFLETE,
            importeSEGURO,
        ];

        const alignments = ["left", "center", "center", "right", "right", "right"];

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    {elementForList.map((elementItem, index) => (
                        <Table.Data align={alignments[index]}>
                            <div className="data-text">{elementItem !== null ? elementItem : "-"}</div>
                        </Table.Data>
                    ))}
                </Table.Row>
            );
        }

        const headerElements = this.renderHeaderElements();

        return (
            <div className="w-100 my-1">
                {elementForList.map((elementItem, index) => (
                    <Table.Row className="container-white">
                        {headerElements[index]}
                        <Table.Data align="right">
                            <div className="data-text">{elementItem !== null ? elementItem : "-"}</div>
                        </Table.Data>
                    </Table.Row>
                ))}
            </div>
        );
    };

    render() {
        return <>{this.renderLists()}</>;
    }
}

const mapStateToProps = (state) => ({
    optionSelected: ecomexSelectors.getOptionSelected(state),
    selectedInformativeRegime: ecomexSelectors.getSelectedInformativeRegime(state),
    notificationsDetail: ecomexSelectors.getNegociationsDetail(state),
    affectationsDetail: ecomexSelectors.getAffectationsDetail(state),
    impurationsAndNegotiations: ecomexSelectors.getImpurationsAndNegotiations(state),
    boardingPermitsNegotiations: ecomexSelectors.getBoardingPermitsNegotiations(state),
});

export default connect(mapStateToProps)(EcomexInformativeRegimeDetailList);
