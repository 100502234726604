import React, { Component } from "react";
import Jimp from "jimp";
import { Buffer } from "buffer";
import { connect } from "react-redux";
import ReactWebcam from "react-webcam";
import classNames from "classnames";
import { bool, func, string } from "prop-types";
import { push } from "react-router-redux/actions";

import { actions as notificationActions } from "reducers/notification";
import * as cameraUtils from "util/camera";
import { get as getMessage } from "util/i18n";

import CameraButton from "pages/_components/CameraButton";

const cameraWithMirroredProp = true;

const videoConstraints = {
    height: 720,
    width: 1080,
};

class Webcam extends Component {
    static propTypes = {
        direction: string.isRequired,
        handleTakePicture: func.isRequired,
        handleTakePictureError: func.isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        overlayPath: string,
        selfie: bool,
        camera: string,
        dispatch: func.isRequired,
    };

    static defaultProps = {
        overlayPath: null,
        selfie: false,
        camera: "",
    };

    state = {
        takingPhoto: false,
    };

    componentDidMount() {
        const { direction, isMobileNative } = this.props;

        if (isMobileNative) {
            this.initMobileNativeCamera(direction);
        }
    }

    componentWillUnmount() {
        this.stopCamera();
    }

    handleTakePicture = () => {
        const { handleTakePicture, handleTakePictureError, isMobileNative } = this.props;

        if (isMobileNative) {
            cameraUtils
                .takePicture()
                .then((picture) => {
                    handleTakePicture(picture[0]);
                })
                .catch(() => {
                    handleTakePictureError();
                });
        } else {
            let picture = this.webcam.getScreenshot({ width: videoConstraints.width, height: videoConstraints.height });
            if (picture !== null) {
                picture = picture.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
                if (cameraWithMirroredProp) {
                    this.setState(
                        {
                            takingPhoto: true,
                        },
                        () => {
                            Jimp.read(Buffer.from(picture, "base64"))
                                .then((image) => {
                                    const mirroredImage = image.mirror(true, false);
                                    mirroredImage.getBase64(Jimp.AUTO, (err, res) => {
                                        handleTakePicture(res.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
                                    });
                                })
                                .catch((err) => {
                                    // eslint-disable-next-line no-console
                                    console.error(err);
                                });
                        },
                    );
                } else {
                    handleTakePicture(picture);
                }
            }
        }
    };

    initMobileNativeCamera = (direction) => {
        cameraUtils.start(cameraUtils.directions[direction.toUpperCase()]);
    };

    getWebcamProps = () => {
        const { direction, isMobile, camera } = this.props;
        let webcamProps = {
            mirrored: cameraWithMirroredProp,
            audio: false,
            ref: (webcam) => {
                this.webcam = webcam;
            },
            height: 580,
            width: 1200,
            screenshotFormat: "image/jpeg",
            videoConstraints: {
                facingMode: "environment",
                height: videoConstraints.height,
                width: videoConstraints.width,
            },
            screenshotQuality: 1,
            onUserMediaError: this.handleCameraError,
        };

        if (isMobile) {
            const height = camera === "landscape" ? window.innerWidth : window.innerHeight;
            const width = camera === "landscape" ? window.innerHeight : window.innerWidth;

            webcamProps = {
                ...webcamProps,
                height,
                width,
                videoConstraints: {
                    ...webcamProps.videoConstraints,
                    facingMode: direction === cameraUtils.directions.BACK ? "environment" : "user",
                    height,
                    width,
                },
            };
        }

        return webcamProps;
    };

    handleCameraError = (err) => {
        // eslint-disable-next-line no-console
        console.log("Webcam - The following error has ocurred: ");
        // eslint-disable-next-line no-console
        console.log(err);
        const { dispatch } = this.props;
        this.stopCamera();
        dispatch(notificationActions.showNotification(getMessage("onboarding.camera.error"), "error", ["login"]));
        dispatch(push("/"));
    };

    stopCamera = () => {
        const { isMobileNative } = this.props;

        if (isMobileNative) {
            cameraUtils.stop();
        } else if (this.webcam) {
            const { stream } = this.webcam;
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        }
    };

    render() {
        const { isMobileNative, selfie } = this.props;
        const { takingPhoto } = this.state;
        return (
            <div className="camera-stream">
                <div className={classNames("camera-mask", { "camera-mask--face": selfie })}>
                    {!isMobileNative && <ReactWebcam {...this.getWebcamProps()} />}
                </div>

                <CameraButton
                    image="images/take-picture.svg"
                    loading={takingPhoto}
                    handleClick={this.handleTakePicture}
                    selfie={selfie}
                />
            </div>
        );
    }
}

export default connect(null)(Webcam);
