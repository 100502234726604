export const types = {
    UPDATE_REQUEST: "config/UPDATE_REQUEST",
    UPDATE_SUCCESS: "config/UPDATE_SUCCESS",
    UPDATE_FAILURE: "config/UPDATE_FAILURE",
    RESET_SAGAS_UPDATE: "config/RESET_SAGAS_UPDATE",
};

export const INITIAL_STATE = {
    fetching: true,
    items: {},
    timesFailed: 0,
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.UPDATE_REQUEST:
            return { ...state, fetching: true };

        case types.UPDATE_SUCCESS:
            return { ...state, fetching: false, items: action.items, timesFailed: 0 };

        case types.UPDATE_FAILURE:
            return { ...state, fetching: false, timesFailed: state.timesFailed + 1 };

        default:
            return state;
    }
};

export const actions = {
    load: () => ({
        type: types.LOAD,
    }),
    updateConfig: () => ({
        type: types.UPDATE_REQUEST,
    }),
};

export const selectors = {
    getConfig: (state) => state.config.items,
    getConfigKey: (state, id) => state.config.items[id] && state.config.items[id] === "true",
    getFetching: (state) => state.config.fetching,
    getTimesFailed: (state) => state.config.timesFailed,
};
