import React, { useState, useEffect, useRef } from "react";
import { node, string } from "prop-types";

import I18n from "pages/_components/I18n";

function Popup({ popupButtonContent, children, popupTitleID, popupDescID }) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setIsOpen(false));

    return (
        <div className="popup">
            <button
                className="popup-button"
                onClick={handleClick}
                type="button"
                aria-haspopup="true"
                aria-expanded={isOpen}
                onKeyDown={handleFirstTabPrev}>
                {popupButtonContent}
                <span className="Select-arrow-zone">
                    <span className="Select-arrow white-arrow" />
                </span>
            </button>
            {isOpen && (
                <>
                    <div id="panelID" className="popup-panel" role="dialog" aria-labelledby="dialog1Title" ref={ref}>
                        <h2
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                            onKeyDown={handleFirstTabPrev}
                            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                            tabIndex="0"
                            className="visually-hidden"
                            id="dialog1Title"
                            aria-describedby={popupDescID && "dialogDesc"}>
                            <I18n id={popupTitleID} />
                        </h2>
                        {popupDescID && (
                            <p className="visually-hidden" id="dialogDesc">
                                {popupDescID}
                            </p>
                        )}
                        {children}
                    </div>
                    <button
                        className="popup-overlay"
                        onClick={handleOverlayClick}
                        onKeyDown={handleLastTabNext}
                        type="button">
                        <I18n id="global.close" componentProps={{ className: "visually-hidden" }} />
                    </button>
                </>
            )}
        </div>
    );

    function handleFirstTabPrev(event) {
        if (event.shiftKey && event.key === "Tab") {
            setIsOpen(false);
        }
    }

    function handleLastTabNext(event) {
        if (event.key === "Tab" && isOpen) {
            setIsOpen(false);
        }
    }

    function handleOverlayClick() {
        setIsOpen((prevState) => !prevState);
    }

    function handleClick() {
        setIsOpen((prevState) => !prevState);
    }
}

function useOnClickOutside(ref, handler) {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
}

Popup.propTypes = {
    popupButtonContent: node.isRequired,
    children: node.isRequired,
    popupTitleID: string.isRequired,
    popupDescID: string,
};

Popup.defaultProps = {
    popupDescID: null,
};

export default Popup;
