import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import { Form } from "formik";
import { func, bool } from "prop-types";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import { Modal } from "react-bootstrap";

class IRSModal extends Component {
    static propTypes = {
        goBack: func,
        handleProceedClick: func,
        isDisplayed: bool,
        isMobile: bool.isRequired,
    };

    static defaultProps = {
        goBack: () => {},
        isDisplayed: false,
        handleProceedClick: () => {},
    };

    render() {
        const { isDisplayed, goBack, isMobile, handleProceedClick } = this.props;

        return (
            <Modal
                aria-labelledby="modalTitleID"
                aria-describedby="modalDescID"
                aria-modal="true"
                onHide={goBack}
                show={isDisplayed}>
                <div className="modal-container modal-enrollment">
                    <Modal.Header closeButton={!isMobile}>
                        <Modal.Title>
                            {isMobile && (
                                <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                                    <Button
                                        className="toolbar-btn view-back"
                                        onClick={goBack}
                                        image="images/arrowLeft.svg"
                                        label="global.back"
                                        bsStyle="link"
                                    />
                                </div>
                            )}
                            <Image src="images/warningIcon.svg" className="svg-icon" />
                            <I18n id="onboarding.step.12.esign.modal.header" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="above-the-fold">
                            <MainContainer>
                                <div className="above-the-fold">
                                    <Container className="container--layout flex-grow ">
                                        <Col sm={12} className="col col-12">
                                            <div className="form-text-group">
                                                <I18n className="control-label" id="enrollment.stepIRS.warning.text" />

                                                <I18n id="enrollment.stepIRS.warning.footer" />
                                            </div>
                                        </Col>
                                    </Container>
                                    <Container className="container--layout align-items-center">
                                        <Col className="col col-12">
                                            <Button
                                                type="button"
                                                label="global.continue"
                                                bsStyle="primary"
                                                onClick={handleProceedClick}
                                            />
                                        </Col>
                                    </Container>
                                </div>
                            </MainContainer>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}

export default IRSModal;
