const getField = (formFieldList, idField) => formFieldList.filter((formField) => formField.idField === idField)[0];

const getFieldValue = (formFieldValues, idField) => {
    if (formFieldValues && formFieldValues[idField]) {
        return formFieldValues[idField];
    }
    return null;
};

const TRUE = 1;
const FALSE = 2;
const HAS_VALUE = 3;
const HAS_VALUE_FIELD = 4;
const HAS_NOT_VALUE = 5;
const HAS_NOT_VALUE_FIELD = 6;
const SHOWN = 7;
const SHOWN_FIELD = 8;
const HIDDEN = 9;
const HIDDEN_FIELD = 10;
const VALUE = 11;
const VALUE_FIELD = 12;
const VALUE_OPERATOR = 13;
const VALUE_RIGHT_SIDE = 14;

const operators = {
    "==": (leftSide, rightSide) => leftSide === rightSide,
    "!=": (leftSide, rightSide) => leftSide !== rightSide,
    ">": (leftSide, rightSide) => leftSide > rightSide,
    ">=": (leftSide, rightSide) => leftSide >= rightSide,
    "<": (leftSide, rightSide) => leftSide < rightSide,
    "<=": (leftSide, rightSide) => leftSide <= rightSide,
};

const isNumeric = (val) => !Number.isNaN(val - parseFloat(val));

function evalSimpleValue(leftSide, operator, rightSide) {
    if (isNumeric(leftSide) && isNumeric(rightSide)) {
        return operators[operator](Number(leftSide), Number(rightSide));
    }
    if (isNumeric(leftSide) || isNumeric(rightSide)) {
        // not matching types
    } else if (typeof leftSide === "string" && typeof rightSide === "string") {
        if (operator === "==") {
            return leftSide === rightSide;
        }
        if (operator === "!=") {
            return leftSide !== rightSide;
        }
        return false;
    }
}

// export for test
export const evalOperation = (leftSide, operator, rightSide) => {
    if (Array.isArray(leftSide)) {
        if (operator === "!=") {
            /* if all values evals to true */
            let result = true;
            for (const i in leftSide) {
                result = result && evalOperation(leftSide[i], operator, rightSide);
            }
            return result;
        }
        /* if any value evals to true */
        for (const i in leftSide) {
            const result = evalOperation(leftSide[i], operator, rightSide);
            if (result) {
                return true;
            }
        }
        return false;
    }

    let rightTokens = rightSide ? rightSide.toString().split("|") : "";

    if (!(rightTokens.length > 1)) {
        rightTokens = rightSide ? rightSide.toString().split("&") : "";
    }

    if (rightTokens.length > 1) {
        /**
         * we don't support using combined or's and and's
         */
        if (rightSide.indexOf("|") !== -1) {
            return rightTokens.some((token) => evalSimpleValue(leftSide, operator, token));
        }
        return rightTokens.some((token) => !evalSimpleValue(leftSide, operator, token));
    }

    const isValid = evalSimpleValue(leftSide, operator, rightSide);

    if (typeof isValid === "boolean") {
        return isValid;
    }
    /* if not array or numeric or string, we got mismatched types */
    return operator === "!=";
};

const isQuality = (qualityName, idField, fieldList, fieldValues) => {
    let quality = false;
    const field = getField(fieldList, idField);
    // eslint-disable-next-line max-len
    const regex = /^(TRUE)|(FALSE)|(hasValue\((.+)\))|(hasNotValue\((.+)\))|(shown\((.+)\))|(hidden\((.+)\))|(value\((.+)\) (==|!=|<|<=|>=|>) '(.*)')$/;

    const match = regex.exec(field[qualityName]);

    if (match !== null) {
        if (match[TRUE] !== undefined) {
            quality = true;
        } else if (match[FALSE] !== undefined) {
            quality = false;
        } else if (match[HAS_VALUE] !== undefined) {
            quality = !!getFieldValue(fieldValues, match[HAS_VALUE_FIELD]);
        } else if (match[HAS_NOT_VALUE] !== undefined) {
            quality = !getFieldValue(fieldValues, match[HAS_NOT_VALUE_FIELD]);
        } else if (match[SHOWN] !== undefined) {
            quality = isQuality(quality, match[SHOWN_FIELD], fieldList, fieldValues);
        } else if (match[HIDDEN] !== undefined) {
            quality = !isQuality(quality, match[HIDDEN_FIELD], fieldList, fieldValues);
        } else if (match[VALUE] !== undefined) {
            const operator = match[VALUE_OPERATOR];
            const rightSide = match[VALUE_RIGHT_SIDE];

            let leftSide;
            const fieldStr = match[VALUE_FIELD];
            const fieldTokens = fieldStr.split(".");
            if (fieldTokens.length === 1) {
                // Get the value with the field name
                leftSide = getFieldValue(fieldValues, fieldStr);

                if (leftSide !== null && !Array.isArray(leftSide) && typeof leftSide === "object") {
                    leftSide = leftSide.value;
                }
            } else if (fieldTokens.length === 2) {
                // Get the value with the field name and then take only a sub part of it,
                // for example debitAccount.currency
                leftSide = getFieldValue(fieldValues, fieldTokens[0]).fieldTokens[1];
            }

            quality = evalOperation(leftSide, operator, rightSide);
        }
    }

    return quality;
};

export const isVisible = (idField, fieldList, fieldValues) => isQuality("visible", idField, fieldList, fieldValues);

export const isRequired = (idField, fieldList, fieldValues) => isQuality("required", idField, fieldList, fieldValues);

export const adjustIdFieldErrors = (errors) => {
    const newErrors = {};
    Object.keys(errors).forEach((key) => {
        newErrors[key.replace(/^_/, "")] = errors[key];
    });
    return newErrors;
};

export const credentialsToUnderscoreFormat = (credentials) =>
    Object.entries(credentials).reduce((values, [key, value]) => ({ ...values, [`_${key}`]: value }), {});
