/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "redux";
import { string, func, shape, arrayOf, bool, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import classNames from "classnames";

import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import Productselector from "pages/forms/_components/_fields/Productselector";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import CreateFrequentDesitinationModal from "pages/forms/_components/CreateFrequentDesetinationModal";
import GeneralMsg from "pages/_components/GeneralMsg";
import Selector from "pages/_components/fields/formik/Selector";
import TextField from "pages/_components/fields/TextField";
import SelectedReceivers from "pages/charges/_components/SelectedReceivers";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import NewReceiver from "pages/charges/_components/NewReceiver";
import Image from "pages/_components/Image";
import Notification from "pages/_components/Notification";
import FieldError from "pages/_components/fields/FieldError";
import Limits from "pages/transfer/_components/Limits";
import { actions as formActions } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";
import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import { saveLocation } from "util/transaction";
import * as i18n from "util/i18n";
import PageLoading from "pages/_components/PageLoading";
import { calculateTotalAmountCbuList } from "util/credin";
import {
    actions as frequentDestinationActions,
    selectors as frequentDestinationSelectors,
} from "reducers/frequentDestination";
import {
    Drawer,
    Text,
    Box,
    ThemeProvider2,
    ThemeProvider,
    Form as FormUI,
    BottomSheet,
    Separator,
} from "@ui-kit/components/index.es";
import * as configUtils from "util/config";

const FORM_ID = "requestCredin";

class RequestCredin extends Component {
    static propTypes = {
        setValues: func.isRequired,
        values: shape({ account: string.isRequired }).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        errors: shape({}).isRequired,
        setErrors: func.isRequired,
        cbuList: arrayOf(
            shape({
                email: string.isRequired,
                cbu: string.isRequired,
                document: string.isRequired,
                headline: string.isRequired,
                reference: string.isRequired,
                idFrequentDestination: bool.isRequired,
                key: number.isRequired,
                isOwn: string.isRequired,
                comprobante: number.isRequired,
                schedule: bool.isRequired,
            }),
        ).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        fetching: bool.isRequired,
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        concepts: arrayOf(shape({})).isRequired,
        limit: number.isRequired,
        limitUsed: number.isRequired,
        credinData: shape({}).isRequired,
        recipients: arrayOf(shape({})).isRequired,
        isBackFromSummary: bool.isRequired,
        activeEnvironment: shape({
            permissions: shape({
                frequentDestination: bool,
            }),
        }).isRequired,
        seller: shape({
            idTransaction: string,
        }).isRequired,
        idEnvironment: number.isRequired,
        listFrequentDestinations: shape({}).isRequired,
        agendaDrawerVisibleRed: bool.isRequired,
        isRetailEnvironment: bool.isRequired,
        frequentDestinationLoading: bool.isRequired,
    };

    state = {
        frequentVisible: false,
        currency: 1,
        chargeCbuList: false,
        buttonDisabled: false,
        modalValues: {
            cbuOrAliasAgenda: "",
            emailAgenda: "",
            referenceAgenda: "",
            tokenAgenda: "",
        },
    };

    componentDidMount() {
        const { dispatch, accounts, loggedUser, isBackFromSummary, idEnvironment } = this.props;
        saveLocation(this.props);

        dispatch(debinActions.clearRequestData());
        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
        if (!isBackFromSummary) {
            dispatch(debinActions.setCbuSelected([]));
        }

        dispatch(debinActions.debinPreActivity());
        dispatch(transferActions.resetModalAgendaValues());
        dispatch(debinActions.cleanIsBackFromSummary());
        dispatch(frequentDestinationActions.loadListRequest(idEnvironment, null));
        dispatch(transferActions.preActivity());
    }

    componentDidUpdate(prevProps) {
        const { listFrequentDestinations } = this.props;

        if (listFrequentDestinations?.length !== prevProps?.listFrequentDestinations?.length) {
            this.verifyHasToShowAgenda();
        }
    }

    verifyHasToShowAgenda = () => {
        const { cbuList, values } = this.props;

        if (!values?.cbuOnModal) {
            return;
        }

        let accountInfo = cbuList.find((object) => object.cbu === values?.cbuOnModal);

        accountInfo = { ...accountInfo, hasToShowAgendaDrawer: false };
        this.updateCbuList(accountInfo);
        this.setState({
            modalValues: {
                cbuOrAliasAgenda: values?.cbuOnModal,
                referenceAgenda: "",
                emailAgenda: "",
                tokenAgenda: "",
            },
        });
    };

    closeModal = () => {
        this.setState({ frequentVisible: false });
    };

    emptyReceivers = (errors) => {
        const { isDesktop } = this.props;
        return (
            <>
                <GeneralMsg
                    idEnviroment
                    imagePath="images/loupeInFile.svg"
                    imageStyle={{ width: "96px", height: "96px" }}
                    description={i18n.get("requestCredin.noRecords")}
                    isDesktop={isDesktop}
                />
                {errors.emptyReceivers && (
                    <div className="form-group has-error">
                        <FieldError error={i18n.get("charges.debin.requestDebin.emptyReceivers.label")} />
                    </div>
                )}
            </>
        );
    };

    selectCBU = (selected) => {
        const { dispatch, cbuList } = this.props;
        const { account, reference, recipientDocumentType, recipientDocumentNumber, name } = selected;

        const recipient = cbuList?.find((e) => e.account === account);

        if (recipient) {
            this.closeModal();
            return;
        }

        const mapInfo = recipientDocumentType
            ? { document: `${recipientDocumentType} ${recipientDocumentNumber}`, reference: name }
            : {};
        const newRecipient = { ...selected, ...mapInfo, headline: reference || name, cbu: account };
        let list = [{ ...newRecipient, schedule: false }];
        if (cbuList !== null) {
            list = [...cbuList, ...list];
        }
        this.closeModal();
        dispatch(debinActions.setCbuSelected(list));
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("requestCredin.title")}</h1>
        </div>
    );

    renderLimits = () => {
        const { limit, limitUsed } = this.props;
        const { currency } = this.state;
        return <Limits limit={limit} limitUsed={limitUsed} currency={currency} />;
    };

    onChangeAccount = (account) => {
        const { accounts, values, dispatch } = this.props;
        const { debitAccount } = values;
        const selectedAccount = accounts.find((object) => object.idProduct === account);
        if (selectedAccount) {
            this.setState({ currency: parseInt(selectedAccount.currency, 10) });
            if (debitAccount !== account) {
                dispatch(transferActions.accountLimitsActivities(selectedAccount.idProduct, "credin.sentRequest.send"));
            }
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop/"));
    };

    saveDraft = () => {
        const { dispatch, values, credinData, cbuList, accounts, recipients, seller, setErrors } = this.props;
        const account = accounts.find((a) => a.idProduct === values.account);
        let summary = null;

        this.setState({ buttonDisabled: true });

        if (cbuList.length === 0) {
            setErrors({ emptyReceivers: i18n.get(`${FORM_ID}.emptyReceivers.label`) });
            this.setState({ buttonDisabled: false });
            return;
        }

        if (!emptyFieldReceiver(cbuList, dispatch, recipients)) {
            summary = {
                debinSeller: {
                    ...values,
                    cbu: account?.cbu,
                    idAccount: account?.idProduct,
                    idTransaction: seller?.idTransaction,
                    debitAccount: account,
                },
                cbuList,
            };
            const amount = calculateTotalAmountCbuList(cbuList);
            dispatch(
                formActions.saveDraft({
                    idForm: "credinSentRequest",
                    data: {
                        ...values,
                        cbuList,
                        amount,
                    },
                    idTransaction: credinData?.idTransaction || null,
                    summary,
                    idActivityDraft: "credin.sentRequest.send",
                }),
            );
        } else {
            this.setState({ buttonDisabled: false });
        }
    };

    handleModalChange = (e) => {
        const { modalValues } = this.state;
        const modalAgendaValuesToSet = { ...modalValues, [e.target.id]: e.target.value };
        this.setState({ modalValues: modalAgendaValuesToSet });
    };

    handleOnSubmit = () => {
        const { dispatch, idEnvironment } = this.props;
        const { modalValues } = this.state;

        const requestValues = {
            cbu: modalValues?.cbuOrAliasAgenda,
            recipientEmail: modalValues?.emailAgenda,
            name: modalValues?.referenceAgenda,
            otp: modalValues?.tokenAgenda,
            scopeToShow: "credin",
            idEnvironment,
        };

        dispatch(transferActions.frequentDestinationCreateRequest(requestValues));
    };

    isCbuOnList = (cbuOrAlias) => {
        const { listFrequentDestinations } = this.props;
        const found = listFrequentDestinations.find((frequentDestination) => frequentDestination.cbu === cbuOrAlias);
        let existCbu = false;
        if (found) {
            existCbu = true;
        }

        return existCbu;
    };

    hasToAddBeneficiary = (amountParameter, currencyParameter, cbuOrAlias) => {
        const { isRetailEnvironment, dispatch, cbuList, values, setValues } = this.props;
        const environmentTypeTag = isRetailEnvironment ? "retail" : "corporate";
        const currencyTypeTag = currencyParameter === "2" ? "Dolares" : "Pesos";
        const amountControl = configUtils.getInteger(
            `frontend.controlAgenda.amount${currencyTypeTag}.${environmentTypeTag}`,
            10000,
        );

        const controlAgendaAvailable = configUtils.getBoolean("frontend.controlAgenda.validation.credin.enable", false);

        const amountOverMax = amountParameter >= amountControl;

        const hasToShowAgendaDrawer = controlAgendaAvailable && amountOverMax && !this.isCbuOnList(cbuOrAlias);

        setValues({ ...values, cbuOnModal: cbuOrAlias, hasToShowAgendaDrawer });
        dispatch(transferActions.setAgendaDrawerVisible(hasToShowAgendaDrawer));

        this.setState({
            modalValues: { cbuOrAliasAgenda: cbuOrAlias, referenceAgenda: "", emailAgenda: "", tokenAgenda: "" },
        });

        let accountInfo = cbuList.find((object) => object.cbu === cbuOrAlias);
        accountInfo = { ...accountInfo, hasToShowAgendaDrawer };
        this.updateCbuList(accountInfo);
    };

    updateCbuList = (accountInfo) => {
        const { cbuList, dispatch } = this.props;
        const index = cbuList.findIndex((r) => r.cbu === accountInfo.cbu);
        const receivers = [...cbuList];
        receivers[index] = { ...accountInfo };
        dispatch(debinActions.setCbuSelected(receivers));
    };

    showAgendaDrawerAfterSubmit = () => {
        const { dispatch, cbuList, values, setValues } = this.props;
        const controlAgendaAvailable = configUtils.getBoolean("frontend.controlAgenda.validation.credin.enable", false);
        if (!controlAgendaAvailable) {
            return;
        }

        const firstCbu = cbuList.find((item) => item.hasToShowAgendaDrawer);
        if (firstCbu) {
            setValues({ ...values, cbuOnModal: firstCbu?.cbu });
            this.setState({ modalValues: { cbuOrAliasAgenda: firstCbu?.cbu } });
            dispatch(transferActions.setAgendaDrawerVisible(true));
        }
    };

    render() {
        const {
            accounts,
            fetching,
            isDesktop,
            cbuList,
            errors,
            values,
            concepts,
            recipients,
            activeEnvironment,
            agendaDrawerVisibleRed,
            dispatch,
            frequentDestinationLoading,
        } = this.props;
        const { frequentVisible, chargeCbuList, buttonDisabled, modalValues } = this.state;
        const { account } = values;

        if (!chargeCbuList) {
            cbuList.concat(recipients);
            this.setState({ chargeCbuList: true });
        }

        const initialValues = {
            referenceAgenda: "",
            emailAgenda: "",
            tokenAgenda: "",
        };

        return (
            <ThemeProvider>
                <ThemeProvider2>
                    <PageLoading loading={fetching}>
                        <Notification notificationClassname="snackbar__agenda" scopeToShow="credin" />
                        <CreateFrequentDesitinationModal
                            isVisible={frequentVisible}
                            onClick={this.selectCBU}
                            closeModal={this.closeModal}
                            isDesktop={isDesktop}
                        />
                        {isDesktop ? (
                            <Drawer
                                controlElementId="open-menu"
                                keepFixedOnWeb={false}
                                iconTitle="UserAdd"
                                mainContentId="drawer-left-main"
                                onClose={() => {
                                    dispatch(transferActions.setAgendaDrawerVisible(false));
                                    this.setState({ modalValues: initialValues });
                                }}
                                placement="right"
                                isVisible={agendaDrawerVisibleRed}
                                title={i18n.get("transfers.controlAgenda.title")}>
                                <Notification notificationClassname="snackbar__agenda" scopeToShow="frequentDestinationDrawer" />
                                <Box flex align="baseline" mb={24}>
                                    <Text variant="body2">{`${configUtils.get(
                                        `frontend.ControlAgenda.warningMessage`,
                                    )}`}</Text>
                                </Box>
                                <FormUI
                                    disabledSubmitByDirty={false}
                                    hiddeForm={false}
                                    rowSpacingForm={24}
                                    buttonSubmit={{
                                        colProps: {
                                            size: {
                                                lg: 12,
                                                xs: 12,
                                            },
                                        },
                                        text: i18n.get(`global.confirm`),
                                        variant: "solid",
                                        icon: "ArrowRight",
                                        iconVariant: "outline",
                                        loading: frequentDestinationLoading,
                                    }}
                                    initialValues={initialValues}
                                    inputsList={[
                                        {
                                            colProps: {
                                                size: {
                                                    lg: 12,
                                                    md: 12,
                                                    xs: 12,
                                                },
                                            },
                                            component: "accountSearcher",
                                            id: "cbuOrAliasAgenda",
                                            onChange: (e) => this.handleModalChange(e),
                                            value: modalValues?.cbuOrAliasAgenda,
                                            disabled: true,
                                        },
                                        {
                                            colProps: {
                                                size: {
                                                    lg: 12,
                                                    md: 12,
                                                    xs: 12,
                                                },
                                            },
                                            component: "textField",
                                            id: "referenceAgenda",
                                            required: true,
                                            label: i18n.get(`transfers.referenceAgenda`),
                                            maxLength: 50,
                                            onChange: (e) => this.handleModalChange(e),
                                            value: modalValues?.referenceAgenda,
                                        },
                                        {
                                            colProps: {
                                                size: {
                                                    lg: 12,
                                                    md: 12,
                                                    xs: 12,
                                                },
                                            },
                                            component: "textField",
                                            id: "emailAgenda",
                                            label: i18n.get(`transfers.emailAgenda`),
                                            maxLength: 50,
                                            onChange: (e) => this.handleModalChange(e),
                                            value: modalValues?.emailAgenda,
                                        },
                                        {
                                            colProps: {
                                                size: {
                                                    xs: 12,
                                                    md: 12,
                                                    lg: 12,
                                                },
                                            },
                                            id: "separator",
                                            customElement: () => (
                                                <Box flex w="100%" mt={12}>
                                                    <Separator color="neutral-light" type="horizontal" />
                                                </Box>
                                            ),
                                        },
                                        {
                                            colProps: {
                                                size: {
                                                    xs: 12,
                                                    md: 12,
                                                    lg: 12,
                                                },
                                            },
                                            id: "tokenText",
                                            customElement: () => (
                                                <Box flex align="baseline">
                                                    <Text variant="body2">
                                                        {`${i18n.get(`transfers.frequentDestinationModal.token.text`)}`}
                                                    </Text>
                                                </Box>
                                            ),
                                        },
                                        {
                                            colProps: {
                                                size: {
                                                    lg: 12,
                                                    md: 12,
                                                    xs: 12,
                                                },
                                            },
                                            component: "tokenPassField",
                                            id: "tokenAgenda",
                                            label: "TOKEN",
                                            placeholder: "Ej: 000000",
                                            onChange: (e) => this.handleModalChange(e),
                                            value: modalValues?.tokenAgenda,
                                        },
                                    ]}
                                    onSubmit={() => {
                                        this.handleOnSubmit();
                                    }}
                                    validationSchema={Yup.object().shape({
                                        emailAgenda: Yup.string().email(i18n.get(`transfers.field.error.invalid`)),
                                        referenceAgenda: Yup.string()
                                            .required(i18n.get(`transfers.field.error.required`))
                                            .max(
                                                configUtils.getInteger("frequentDestination.reference.maxLength", 100),
                                                i18n.get(`frequentDestination.reference.length.error`),
                                            ),
                                        tokenAgenda: Yup.string().required(i18n.get(`transfers.field.error.required`)),
                                    })}
                                />
                            </Drawer>
                        ) : (
                            <>
                                <Notification scopeToShow="frequentDestinationDrawer" />
                                <BottomSheet
                                    iconTitle="UserAdd"
                                    iconColor="primary-pressed-color"
                                    title={i18n.get("transfers.controlAgenda.title")}
                                    className="px-4"
                                    onClose={() => {
                                        dispatch(transferActions.setAgendaDrawerVisible(false));
                                        this.setState({ modalValues: initialValues });
                                    }}
                                    isVisible={agendaDrawerVisibleRed}>
                                    <Box flex mt={12} mb={24}>
                                        <Text variant="body2">{`${configUtils.get(
                                            `frontend.ControlAgenda.warningMessage`,
                                        )}`}</Text>
                                    </Box>
                                    <FormUI
                                        className="pb-5"
                                        disabledSubmitByDirty
                                        hiddeForm={false}
                                        rowSpacingForm={24}
                                        buttonSubmit={{
                                            colProps: {
                                                size: {
                                                    lg: 12,
                                                    xs: 12,
                                                },
                                            },
                                            text: i18n.get(`global.confirm`),
                                            variant: "solid",
                                            icon: "ArrowRight",
                                            iconVariant: "outline",
                                            loading: frequentDestinationLoading,
                                        }}
                                        initialValues={initialValues}
                                        inputsList={[
                                            {
                                                colProps: {
                                                    size: {
                                                        lg: 12,
                                                        md: 12,
                                                        xs: 12,
                                                    },
                                                },
                                                component: "accountSearcher",
                                                id: "cbuOrAliasAgenda",
                                                onChange: (e) => this.handleModalChange(e),
                                                value: modalValues?.cbuOrAliasAgenda,
                                                disabled: true,
                                            },
                                            {
                                                colProps: {
                                                    size: {
                                                        lg: 12,
                                                        md: 12,
                                                        xs: 12,
                                                    },
                                                },
                                                component: "textField",
                                                id: "referenceAgenda",
                                                required: true,
                                                label: i18n.get(`transfers.referenceAgenda`),
                                                maxLength: 50,
                                                onChange: (e) => this.handleModalChange(e),
                                                value: modalValues?.referenceAgenda,
                                            },
                                            {
                                                colProps: {
                                                    size: {
                                                        lg: 12,
                                                        md: 12,
                                                        xs: 12,
                                                    },
                                                },
                                                component: "textField",
                                                id: "emailAgenda",
                                                label: i18n.get(`transfers.emailAgenda`),
                                                maxLength: 50,
                                                onChange: (e) => this.handleModalChange(e),
                                                value: modalValues?.emailAgenda,
                                            },
                                            {
                                                colProps: {
                                                    size: {
                                                        xs: 12,
                                                        md: 12,
                                                        lg: 12,
                                                    },
                                                },
                                                id: "separator",
                                                customElement: () => (
                                                    <Box flex w="100%" mt={12}>
                                                        <Separator color="neutral-light" type="horizontal" />
                                                    </Box>
                                                ),
                                            },
                                            {
                                                colProps: {
                                                    size: {
                                                        xs: 12,
                                                        md: 12,
                                                        lg: 12,
                                                    },
                                                },
                                                id: "tokenText",
                                                customElement: () => (
                                                    <Box flex align="baseline">
                                                        <Text variant="body2">
                                                            {`${i18n.get(
                                                                `transfers.frequentDestinationModal.token.text`,
                                                            )}`}
                                                        </Text>
                                                    </Box>
                                                ),
                                            },
                                            {
                                                colProps: {
                                                    size: {
                                                        lg: 12,
                                                        md: 12,
                                                        xs: 12,
                                                    },
                                                },
                                                component: "tokenPassField",
                                                id: "tokenAgenda",
                                                label: "TOKEN",
                                                placeholder: "Ej: 000000",
                                                onChange: (e) => this.handleModalChange(e),
                                                value: modalValues?.tokenAgenda,
                                            },
                                        ]}
                                        onSubmit={() => {
                                            this.handleOnSubmit();
                                        }}
                                        validationSchema={Yup.object().shape({
                                            emailAgenda: Yup.string().email(i18n.get(`transfers.field.error.invalid`)),
                                            referenceAgenda: Yup.string()
                                                .required(i18n.get(`transfers.field.error.required`))
                                                .max(
                                                    configUtils.getInteger(
                                                        "frequentDestination.reference.maxLength",
                                                        100,
                                                    ),
                                                    i18n.get(`frequentDestination.reference.length.error`),
                                                ),
                                            tokenAgenda: Yup.string().required(
                                                i18n.get(`${FORM_ID}.field.error.required`),
                                            ),
                                        })}
                                    />
                                </BottomSheet>
                            </>
                        )}

                        <div className="admin-detail-head px-0">
                            <Head
                                onBack={this.handleBack}
                                headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                                centerElement={isDesktop ? undefined : this.centerContentMobile}
                            />
                            {isDesktop && <Head title="requestCredin.title" rightContent={this.rightContent} />}
                        </div>
                        <MainContainer>
                            <Form autoComplete="off">
                                <div className="above-the-fold">
                                    <Container
                                        className="flex-grow align-items-center container-white my-2 py-2"
                                        gridClassName="form-content">
                                        <Col sm={12} md={9} lg={6} xl={7} className="align-items-center">
                                            <Col className="col col-12 col-no-pad-mobile py-2">
                                                {!isDesktop && account && this.renderLimits()}
                                                <Field
                                                    name="account"
                                                    component={Productselector}
                                                    onCustomChange={this.onChangeAccount}
                                                    idField="account"
                                                    data={{
                                                        emptyOptionLabel: "",
                                                        options: accounts,
                                                    }}
                                                    disabled={accounts.length === 0}
                                                    isRequired
                                                    mode="edit"
                                                    labelText="transfers.debitaccount.label"
                                                    idForm={FORM_ID}
                                                    isDesktop={isDesktop}
                                                    customPlaceholder={i18n.get(
                                                        "transfers.productSelector.placeholder",
                                                    )}
                                                    formGroupClassName="mb-md-2"
                                                />
                                                {isDesktop && account && (
                                                    <div className="credin-limits">{this.renderLimits()}</div>
                                                )}
                                            </Col>
                                        </Col>
                                    </Container>
                                    <Container
                                        className="flex-grow align-items-center container-white with-debin-toolbar-margin py-2"
                                        gridClassName="form-content">
                                        <Col sm={12} md={9} lg={6} xl={7}>
                                            <Col
                                                sm={12}
                                                className="align-items-center d-flex space-between w-100 px-0 px-md-3">
                                                <div className="d-flex align-items-center">
                                                    <I18n
                                                        id="requestCredin.receiver"
                                                        componentProps={{ className: "data-label" }}
                                                    />
                                                </div>
                                                <div className="d-flex">
                                                    <ButtonDrawer
                                                        backButton={!isDesktop}
                                                        content={
                                                            <NewReceiver date={new Date()} isDesktop={isDesktop} />
                                                        }
                                                        width={isDesktop ? "31.25rem" : "100%"}
                                                        buttonElement={
                                                            <Button
                                                                className={classNames("btn-outline plus-button", {
                                                                    "radius mr-4 small-svg bold-svg": !isDesktop,
                                                                })}
                                                                disabled={values.account === ""}>
                                                                <Image
                                                                    src="images/plus.svg"
                                                                    styles={isDesktop ? {} : { fontSize: "1.8rem" }}
                                                                />
                                                                {isDesktop && (
                                                                    <I18n
                                                                        id="requestCredin.new.receiver"
                                                                        componentProps={{ className: "line-height-1" }}
                                                                    />
                                                                )}
                                                            </Button>
                                                        }
                                                        headerContent={
                                                            <>
                                                                {!isDesktop && (
                                                                    <div className="title-account-header-multiline-background-blue">
                                                                        <h1 className="w-100 m-0">
                                                                            {i18n.get(
                                                                                "charges.debin.requestDebin.add.receiver",
                                                                            )}
                                                                        </h1>
                                                                    </div>
                                                                )}
                                                            </>
                                                        }
                                                    />
                                                    {activeEnvironment?.permissions.frequentDestination ? (
                                                        <Button
                                                            bsStyle="link"
                                                            image="images/frequent-destination.svg"
                                                            className="no-shadow p-0 m-0 svg30"
                                                            disabled={values.account === ""}
                                                            onClick={() => {
                                                                this.setState({ frequentVisible: true });
                                                            }}
                                                        />
                                                    ) : (
                                                        undefined
                                                    )}
                                                </div>
                                            </Col>
                                        </Col>
                                    </Container>
                                    {isDesktop && (
                                        <Container
                                            className="flex-grow align-items-center container-white with-debin-toolbar-margin py-2 relative"
                                            gridClassName="form-content cbu-list">
                                            {cbuList?.length !== 0 && <hr />}

                                            <Col sm={12} md={9} lg={6} xl={7} className="col col-12 align-items-center">
                                                <Col className="col-12 col-no-pad-mobile py-2 pr-2">
                                                    {cbuList?.length === 0 && this.emptyReceivers(errors)}
                                                    {cbuList?.length !== 0 && (
                                                        <SelectedReceivers
                                                            accountSelected={values.account}
                                                            hasToAddBeneficiary={this.hasToAddBeneficiary}
                                                            updateCbuList={this.updateCbuList}
                                                            {...this.props}
                                                        />
                                                    )}
                                                </Col>
                                            </Col>
                                        </Container>
                                    )}
                                    {cbuList?.length !== 0 && !isDesktop && (
                                        <SelectedReceivers
                                            accountSelected={values.account}
                                            hasToAddBeneficiary={this.hasToAddBeneficiary}
                                            updateCbuList={this.updateCbuList}
                                            {...this.props}
                                        />
                                    )}

                                    <Container
                                        className="flex-grow align-items-center container-white my-2 py-2"
                                        gridClassName="form-content">
                                        <Col sm={12} md={9} lg={6} xl={7}>
                                            <Col xs={12} className="align-items-center px-0 px-md-3">
                                                <Field
                                                    component={Selector}
                                                    searchable
                                                    options={concepts.map(({ id, name }) => ({
                                                        value: id,
                                                        label: `${id} - ${name}`,
                                                    }))}
                                                    idForm={FORM_ID}
                                                    name="concept"
                                                    hidePlaceholder
                                                    isRequired
                                                />
                                            </Col>
                                            <Col xs={12} className="px-0 px-md-3">
                                                <Field
                                                    component={TextField}
                                                    renderAs="input"
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    maxLength="100"
                                                    name="description"
                                                    type="text"
                                                    optional={i18n.get("transfers.reference.optional.label")}
                                                />
                                            </Col>
                                        </Col>
                                    </Container>
                                    <Container
                                        className="flex-grow align-items-center"
                                        gridClassName="form-content mt-3">
                                        <Col sm={12} md={9} lg={6} xl={7} className="px-0 px-md-3">
                                            {activeEnvironment?.permissions.createDraft ? (
                                                <Col xs={12} md={6} className="align-items-center">
                                                    <Button
                                                        loading={buttonDisabled}
                                                        bsStyle="outline"
                                                        onClick={this.saveDraft}
                                                        label="transfers.actions.saveDraft"
                                                        disabled={fetching}
                                                    />
                                                </Col>
                                            ) : (
                                                undefined
                                            )}
                                            <Col
                                                xs={12}
                                                md={activeEnvironment?.permissions.createDraft ? 6 : 12}
                                                className="align-items-center">
                                                <Button
                                                    loading={fetching}
                                                    type="submit"
                                                    bsStyle="primary"
                                                    label="global.continue"
                                                    disabled={buttonDisabled}
                                                    onClick={() => {
                                                        this.showAgendaDrawerAfterSubmit();
                                                    }}
                                                />
                                            </Col>
                                        </Col>
                                    </Container>
                                </div>
                            </Form>
                        </MainContainer>
                    </PageLoading>
                </ThemeProvider2>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    seller: debinSelectors.getDebinSeller(state),
    cbuList: debinSelectors.getListCbu(state),
    fetching: debinSelectors.getFetching(state),
    concepts: debinSelectors.getConcepts(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    limit: transferSelectors.getLimit(state),
    limitUsed: transferSelectors.getLimitUsed(state),
    recipients: debinSelectors.getRecipients(state),
    isBackFromSummary: debinSelectors.getIsBackFromSummary(state),
    idEnvironment: frequentDestinationSelectors.getEnviromentId(state),
    listFrequentDestinations: frequentDestinationSelectors.getListFrequentDestinations(state),
    agendaDrawerVisibleRed: transferSelectors.getAgendaDrawerVisibleRed(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
    frequentDestinationLoading: transferSelectors.getFrequentDestinationLoading(state),
});

function addId(cbuList, item, index, recipients) {
    const recipient = recipients?.find((e) => e.cbu === item.cbu);

    if (recipient) {
        cbuList.splice(index, 1, { ...item, id: recipient.id });
    }
}

function emptyFieldReceiver(cbuList, dispatch, recipients) {
    let empty = false;
    let messageI18n = "";

    cbuList.forEach((item, index) => {
        if (!item.amount || !item.amount.amount) {
            empty = true;
            messageI18n = "amount";
        } else if (!item.comprobante || !item.comprobante) {
            empty = true;
            messageI18n = "comprobante";
        }

        addId(cbuList, item, index, recipients);
    });

    if (empty) {
        dispatch(
            notificationActions.showNotification(i18n.get(`credin.empty.${messageI18n}.receivers`), "error", [
                "credin",
            ]),
        );
    }

    return empty;
}

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            account: props.isBackFromSummary && props.seller ? props.seller.account : "",
            concept: props.isBackFromSummary && props.seller ? props.seller.concept : "VAR",
            description: props.isBackFromSummary && props.seller ? props.seller.description : "",
            cbuList: props.isBackFromSummary && props.cbuList ? props.cbuList : [],
        }),
        validationSchema: () =>
            Yup.object().shape({
                account: Yup.string().required(i18n.get("accounts.new.account.field.error.required")),
                concept: Yup.string().required(i18n.get("accounts.new.account.field.error.required")),
            }),
        handleSubmit: ({ ...fields }, formikBag) => {
            const { props, setErrors } = formikBag;
            const { dispatch, cbuList, accounts, seller, recipients } = props;
            const account = accounts.find((a) => a.idProduct === fields.account);

            const controlAgendaAvailable = configUtils.getBoolean(
                "frontend.controlAgenda.validation.credin.enable",
                false,
            );

            const someHasToShowDrawer = cbuList.some((item) => item.hasToShowAgendaDrawer);

            if (controlAgendaAvailable && someHasToShowDrawer) {
                return;
            }
            if (cbuList.length === 0) {
                setErrors({ emptyReceivers: i18n.get(`${FORM_ID}.emptyReceivers.label`) });
                return;
            }
            if (!emptyFieldReceiver(cbuList, dispatch, recipients)) {
                dispatch(
                    debinActions.preGenerateCredin(
                        {
                            ...fields,
                            cbu: account?.cbu,
                            idAccount: account?.idProduct,
                            idTransaction: seller?.idTransaction,
                            debitAccount: account,
                        },
                        cbuList,
                    ),
                );
            }
        },
    }),
)(RequestCredin);
