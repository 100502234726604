import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, bool, shape } from "prop-types";
import Notification from "pages/_components/Notification";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import { Field, Form, withFormik } from "formik";
import I18n from "pages/_components/I18n";
import { compose } from "redux";
import Yup from "yup";
import Credential from "pages/_components/fields/credentials/Credential";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import isTokenActive from "util/token";
import RcurrencyDebinActionSummaryData from "pages/debinPayments/_components/recurrentDebin/RecurrencyDebinActionSummaryData";
import classNames from "classnames";

const FORM_ID = "debin.recurrency";

class ConfirmRecurrencyDebinAction extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        recurrencyAction: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    componentWillMount = () => {
        const { recurrencyAction, dispatch } = this.props;

        if (!recurrencyAction?.action) {
            dispatch(goBack());
        }
    };

    render() {
        const { isSubmitting, recurrencyAction, isDesktop } = this.props;
        return (
            <div className="debin-recurrencies__confirm-action">
                <Notification scopeToShow="debin" />
                <div className={classNames({ "admin-detail-head": isDesktop }, "px-0")}>
                    {isDesktop && <Head onBack={this.handleBack} onClose={this.handleBack} />}
                    <Head
                        titleText={`${i18n.get(`${FORM_ID}.${recurrencyAction?.action?.toLowerCase()}.title`)} ${
                            recurrencyAction?.debin?.debin?.prestacion
                        }`}
                        headerClassName={!isDesktop ? "blue-main-header-mobile pb-2 pt-1" : ""}
                        titleClassName={!isDesktop ? "px-3" : ""}
                        onBack={!isDesktop && this.handleBack}
                        emptyRightOption
                    />
                    <I18n
                        id={`${FORM_ID}.${recurrencyAction?.action?.toLowerCase()}.subtitle`}
                        component="p"
                        componentProps={{
                            className: classNames("debin-recurrencies__confirm-subtitle ml-1 my-0", {
                                "px-6": recurrencyAction?.action?.toLowerCase() !== "bloquear" && !isDesktop,
                            }),
                        }}
                    />
                </div>
                <MainContainer>
                    <div className="above-the-fold">
                        {recurrencyAction?.action && (
                            <RcurrencyDebinActionSummaryData
                                recurrencyAction={recurrencyAction?.debin?.debin?.prestacion || ""}
                                isDesktop={isDesktop}
                            />
                        )}
                        <Container>
                            <Form className="above-the-fold">
                                <Container
                                    className={`flex-grow align-items-center container-white mt-2 ${
                                        isDesktop ? "py-4" : "pt-8 pb-4"
                                    }`}>
                                    <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                        {!isTokenActive(!isDesktop) ? (
                                            <div className="admin-content-center font-light">
                                                <I18n id="transfer.summary.confirm.label" />
                                            </div>
                                        ) : (
                                            <div className="admin-content-center pb-3">
                                                <I18n
                                                    id="confirmation.withoutotp.text"
                                                    component="p"
                                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                                />
                                            </div>
                                        )}

                                        <div className="admin-content-center">
                                            <Field
                                                name="otp"
                                                type="otp"
                                                idForm={FORM_ID}
                                                component={Credential}
                                                loading={isSubmitting}
                                                placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                            />
                                        </div>
                                    </Col>
                                </Container>
                                <Container
                                    className="flex-grow align-items-center container-white mt-0"
                                    gridClassName="form-content">
                                    <Col className="col-12" lg={4} md={9} sm={12}>
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.confirm"
                                            image="images/arrowRight.svg"
                                            imageStyle="mr-2"
                                            className="text-capitalize"
                                            loading={isSubmitting}
                                        />
                                    </Col>
                                </Container>
                            </Form>
                        </Container>
                    </div>
                </MainContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    recurrencyAction: debinSelectors.getRecurrencyDebinAction(state),
    seller: debinSelectors.getSeller(state),
    accounts: debinSelectors.getAccounts(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: (form, formikBag) => {
            const { setSubmitting } = formikBag;
            const { dispatch, recurrencyAction, seller, accounts } = formikBag.props;

            setSubmitting(true);

            const { prestacion, referencia, detalle, concepto, moneda } = recurrencyAction.debin.debin;
            const { cbu } = recurrencyAction.debin.comprador;
            const debitAccount = accounts.find((a) => a.cbu === cbu);

            const recurrencyInfo = {
                _otp: form.otp,
                prestacion,
                referencia,
                detalle,
                concepto,
                moneda,
                cbu,
                seller: recurrencyAction.debin.vendedor.cuit,
                action: recurrencyAction.action,
                id: recurrencyAction.debin.id,
                sellerName: seller?.titular,
                debitAccount,
            };
            dispatch(debinActions.updateRecurrencyState(recurrencyInfo, setSubmitting));
        },
    }),
)(ConfirmRecurrencyDebinAction);
