import React, { Component } from "react";
import { Modal, Col, Row, Grid } from "react-bootstrap";
import { bool, func, string } from "prop-types";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { actions as formActions } from "reducers/form";

class ModifyPendingTransactionModal extends Component {
    static propTypes = {
        isDisplayed: bool.isRequired,
        handleDismiss: func.isRequired,
        dispatch: func.isRequired,
        idTransaction: string.isRequired,
        isDesktop: bool.isRequired,
    };

    showModifyPendingTransaction = () => {
        const { dispatch, idTransaction } = this.props;
        dispatch(formActions.modifyTransaction(idTransaction));
    };

    getClassName = () => {
        const { isDesktop } = this.props;
        const className = `modal-modify-pending-transactions`;
        return isDesktop ? className : `modal-modify-pending-transactions-mb ${className}`;
    };

    render() {
        const { isDisplayed, handleDismiss } = this.props;
        return (
            <Modal onHide={handleDismiss} show={isDisplayed} closeButton className={this.getClassName()}>
                <div>
                    <Modal.Title>
                        <div style={{ display: "flex" }}>
                            <div className="modal-modify-pending-transactions-bullet" />
                            <div className="modal-modify-pending-transactions-title">
                                <I18n id="pending.transactions.modal.title" />
                            </div>
                        </div>
                    </Modal.Title>
                    <Modal.Body>
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col>
                                    <div className="modal-modify-pending-transactions-text">
                                        <I18n component="span" id="pending.transactions.modal.body" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={5} lg={5} xl={5} className="col col-12" />
                                <Col sm={12} md={7} lg={7} xl={7} className="col col-12">
                                    <div className="modal-modify-pending-transactions-buttons">
                                        <div style={{ padding: 0 }}>
                                            <Button bsStyle="link" onClick={handleDismiss} label="global.cancel" />
                                        </div>
                                        <div style={{ padding: 0 }}>
                                            <Button
                                                bsStyle="link"
                                                onClick={this.showModifyPendingTransaction}
                                                label="global.accept"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Grid>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}

export default ModifyPendingTransactionModal;
