import React from "react";
import { func, bool, obj, string, shape } from "prop-types";
import Image from "pages/_components/Image";
import { actions as accountsActions } from "reducers/accounts";
import Button from "pages/_components/Button";

export default function Favorite({ styleDiv, styleImage, favorite, idProduct, dispatch, loggedUser }) {
    Favorite.propTypes = {
        styleDiv: obj,
        styleImage: {},
        favorite: bool.isRequired,
        dispatch: func.isRequired,
        idProduct: string.isRequired,
        loggedUser: shape({}).isRequired,
    };
    Favorite.defaultProps = {
        styleDiv: undefined,
        styleImage: undefined,
    };

    const setFavoriteStatus = () =>
        dispatch(accountsActions.setFavoriteAccount(idProduct, loggedUser.userId, !favorite));

    return (
        <div className="form-group m-0 d-flex" style={styleDiv}>
            <div>
                <Button className="hint-button btn-link m-0 p-0" onClick={setFavoriteStatus}>
                    {favorite ? (
                        <Image src="images/favorito.svg" styles={styleImage} />
                    ) : (
                        <Image src="images/favorito-no-seleccionado.svg" styles={styleImage} />
                    )}
                </Button>
            </div>
        </div>
    );
}
