import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, replace, push } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { bool, func, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import Yup from "yup";
import * as format from "util/format";

import { actions as onboardingActions, selectors as onboardingSelectors } from "reducers/onboarding";
import { actions as loginActions } from "reducers/login";
import * as config from "util/config";
import * as i18n from "util/i18n";
import PhoneInput from "pages/_components/fields/PhoneInput";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import withExchangeToken from "pages/_components/withExchangeToken";
import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import Credential from "pages/_components/fields/credentials/Credential";
import { MAIN_ONBOARDING_ROUTE } from "./Step0";
import OnboardingStepper, { orientations } from "./_components/OnboardingStepper";

const FORM_ID = "onboarding.step4";

class StepPersonalDataUSA extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        documentType: string,
        isSubmitting: bool.isRequired,
        isMobile: bool.isRequired,
    };

    static defaultProps = {
        documentType: "",
    };

    componentDidMount() {
        const { dispatch, documentType } = this.props;

        if (!documentType) {
            dispatch(replace(MAIN_ONBOARDING_ROUTE));
        }
    }

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { isSubmitting, isMobile } = this.props;
        return (
            <Fragment>
                <Head title={FORM_ID} onClose={isMobile && this.onHeaderClose} />
                <div className="view-page">
                    {!isMobile && <OnboardingStepper currentStep={4} className="onboarding-steps" />}
                    <div className="view-content">
                        <main className="main-container">
                            <Form className="above-the-fold">
                                {isMobile && (
                                    <Container className="container--layout align-items-center">
                                        <Col className="col col-12">
                                            <OnboardingStepper currentStep={4} orientation={orientations.horizontal} />
                                        </Col>
                                    </Container>
                                )}
                                <Container className="container--layout align-items-center flex-grow">
                                    <Col className="col col-12">
                                        <Field
                                            component={TextField}
                                            idForm={FORM_ID}
                                            classNameForViewMode="data-desc"
                                            maxLength={50}
                                            mode="view"
                                            name="fullName"
                                        />
                                        <Field
                                            component={TextField}
                                            idForm={FORM_ID}
                                            classNameForViewMode="data-desc"
                                            maxLength={50}
                                            mode="view"
                                            name="dateOfBirth"
                                        />
                                        <Field
                                            component={TextField}
                                            idForm={FORM_ID}
                                            classNameForViewMode="data-desc"
                                            maxLength={50}
                                            mode="view"
                                            name="document"
                                        />
                                        <Field
                                            component={TextField}
                                            idForm={FORM_ID}
                                            classNameForViewMode="data-desc"
                                            maxLength={50}
                                            mode="view"
                                            name="email"
                                            type="email"
                                        />
                                        <Field
                                            component={PhoneInput}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="cellPhone"
                                        />
                                        <I18n component="p" id={`${FORM_ID}.infoText`} />
                                        <Field
                                            component={Credential}
                                            idForm={FORM_ID}
                                            maxLength={9}
                                            minLength={9}
                                            name="SSNID"
                                            type="password"
                                            copyEnabled={false}
                                        />
                                    </Col>
                                    <Col className="col col-12">
                                        <Button
                                            label="global.continue"
                                            type="submit"
                                            bsStyle="primary"
                                            loading={isSubmitting}
                                        />
                                    </Col>
                                </Container>
                                {/* <Container className="container--layout align-items-center"></Container> */}
                            </Form>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    documentData: onboardingSelectors.getDocumentData(state),
    documentType: onboardingSelectors.getDocumentType(state),
    exchangeToken: onboardingSelectors.getExchangeToken(state),
    fetching: onboardingSelectors.getFetching(state),
    email: onboardingSelectors.getEmail(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: (props) => {
            const document =
                props.documentData && props.documentData.documentNumber ? props.documentData.documentNumber : "";
            const name = props.documentData && props.documentData.firstName ? props.documentData.firstName : "";
            const lastName = props.documentData && props.documentData.lastName ? props.documentData.lastName : "";
            return {
                document,
                name,
                lastName,
                fullName: `${name} ${lastName}`,
                cellPhone: props.cellPhone || {
                    prefix: config.get(`cellPhone.code.${config.get("cellPhone.code.default")}`),
                    value: "",
                },
                email: props.email || "",
                dateOfBirth:
                    props.documentData && props.documentData.dateOfBirth
                        ? format.date(props.documentData.dateOfBirth.split("T")[0], i18n.get("datepicker.format"))
                        : "",
                SSNID: "",
            };
        },
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    document: Yup.number()
                        .required(i18n.get("onboarding.step4.document.error.empty"))
                        .typeError(i18n.get("onboarding.step4.document.error.mustBeNumber")),
                    name: Yup.string().required(i18n.get("onboarding.step4.name.error.empty")),
                    lastName: Yup.string().required(i18n.get("onboarding.step4.lastName.error.empty")),
                    cellPhone: Yup.object().shape({
                        prefix: Yup.string().required(),
                        value: Yup.string().required(i18n.get("onboarding.step4.cellPhone.error.empty")),
                    }),
                    email: Yup.string().required(i18n.get("onboarding.step4.email.error.empty")),
                    SSNID: Yup.number()
                        .required(i18n.get("onboarding.step6.field.error.empty"))
                        .typeError(i18n.get("onboarding.step4.SSNID.error.mustBeNumber")),
                }),
            ),
        handleSubmit: ({ document, name, lastName, cellPhone, email, SSNID }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(
                onboardingActions.uploadClientPersonalInfo(
                    document,
                    email,
                    name,
                    formikBag,
                    lastName,
                    cellPhone,
                    SSNID,
                ),
            );
            dispatch(push("/onboarding/step5"));
        },
    }),
)(withExchangeToken(StepPersonalDataUSA));
