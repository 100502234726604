import * as API from "middleware/api";
import { buildPayMultipleAFIPRequest } from "util/paymentsAFIP";

export const listEnabledContributors = () => API.executeWithAccessToken("/list.enabledContributors");

export const enableContributor = (cuit, reference) =>
    API.executeWithAccessToken("/enableContributor", { cuit, reference });

export const deleteEnabledContributor = (cuit) => API.executeWithAccessToken("/deleteEnabledContributor", { cuit });

export const listPaymentsAFIP = (
    paymentType,
    generatedVEP,
    numberVEP,
    taxpayerCUIT,
    dateFrom,
    dateTo,
    pageNumber,
    thirdPartyCuit,
) =>
    API.executeWithAccessToken("/list.paymentsAFIP", {
        paymentType,
        generatedVEP,
        numberVEP,
        taxpayerCUIT,
        dateFrom,
        dateTo,
        pageNumber,
        thirdPartyCuit,
    });

export const payPaymentAFIPPre = () => API.executeWithAccessToken("/pay.paymentAFIP.pre");

export const payMultipleAFIP = (payMultipleAFIPRequest) =>
    API.executeWithAccessToken("pay.paymentsAFIP", buildPayMultipleAFIPRequest(payMultipleAFIPRequest));

export const payPaymentAFIP = (summary, otp) => {
    delete summary?.formikBag;
    return API.executeWithAccessToken("/pay.paymentAFIP", {
        ...summary,
        amount: {
            currency: summary?.paymentAFIP?.amount.currency,
            quantity: summary?.paymentAFIP?.amount.quantity,
        },
        _otp: otp,
    });
};

export const deletePaymentAFIP = (summary, otp) =>
    API.executeWithAccessToken("/delete.paymentAFIP", {
        ...summary,
        _otp: otp,
    });

export const downloadPay = (summary) => API.executeWithAccessToken("/downloadPay.paymentAFIP", { summary });

export const getToken = () => API.executeWithAccessToken("/getToken.afip");

export const preparePaymentAFIP = (selectedPaymentsAFIP, debitAccountMultipleAFIP) =>
    API.executeWithAccessToken("/prepare.paymentsAFIP", {
        payments: selectedPaymentsAFIP,
        idAccount: debitAccountMultipleAFIP?.idProduct,
    });
