import React, { Component } from "react";
import { node, string } from "prop-types";

import TableData from "pages/_components/table/Data";

class TableHeaderData extends Component {
    static propTypes = {
        children: node,
        align: string,
        className: string,
        width: string,
    };

    static defaultProps = {
        children: null,
        align: "center",
        className: "",
        width: "",
    };

    render() {
        const { children, className, ...props } = this.props;
        return (
            <TableData className={`table-data-head ${className}`} {...props}>
                {children}
            </TableData>
        );
    }
}

export default TableHeaderData;
