import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, arrayOf, shape, string } from "prop-types";
import { push } from "react-router-redux";
import { withFormik } from "formik";
import { compose } from "redux";
import classNames from "classnames";

import { actions as ecomexActions, selectors as ecomexSelectors } from "reducers/ecomex";

import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import TabletSelectors from "pages/_components/TabletSelectors";
import EcomexAlertsAndDuesList from "pages/ecomex/_components/EcomexAlertsAndDuesList";
import * as configUtils from "util/config";

const FORM_ID = "ecomex.alertsAndDues.list";

class EcomexAlertsAndDuesMain extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        fetchingDownload: bool.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        dispatch: func.isRequired,
        ecomexAlerts: arrayOf(shape({})).isRequired,
        ecomexDues: arrayOf(shape({})).isRequired,
        optionSelected: string.isRequired,
    };

    componentDidMount() {
        this.changeOption("1");
    }

    changeOption = (option) => {
        const { dispatch } = this.props;
        const defaultNumberPerPage = configUtils.getArray("ecomex.paginacion.cantidadRegistros");
        dispatch(ecomexActions.listEcomexAlertsAndDues(option, 1, defaultNumberPerPage[0]));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    handleExport = (format) => {
        const { dispatch, ecomexAlerts, ecomexDues, optionSelected } = this.props;

        const ecomexAlertsAndDuesToShow = optionSelected === "1" ? ecomexAlerts : ecomexDues;

        const summary = { ecomexAlertsAndDues: ecomexAlertsAndDuesToShow, optionSelected };
        dispatch(ecomexActions.downloadAlertsAndDuesList(summary, format));
    };

    rowButtons = () => {
        const { isDesktop, optionSelected } = this.props;

        const possibleOptions = ["1", "2"];

        const options = possibleOptions.map((possibleOption) => ({
            value: possibleOption,
            label: `ecomex.alertsAndDues.${possibleOption}.label`,
        }));

        return (
            <TabletSelectors
                isDesktop={isDesktop}
                possibleOptions={options}
                changeOption={this.changeOption}
                optionSelected={optionSelected}
                containerClass={classNames("ecomex-tablet-selector", {
                    "mt-4 mb-2": isDesktop,
                    "my-0 py-3": !isDesktop,
                })}
                centerButtons
            />
        );
    };

    renderList() {
        const { fetching, isDesktop, isSmallDesktop } = this.props;

        return (
            <PageLoading loading={fetching}>
                <EcomexAlertsAndDuesList isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
            </PageLoading>
        );
    }

    renderContent() {
        const { isDesktop, fetching, fetchingDownload } = this.props;

        const centerElement = () => (
            <div>
                <I18n
                    id="ecomex.alertsAndDues.title"
                    component="h1"
                    componentProps={{ className: "cmf-title-center font-size-28-px m-0" }}
                />
            </div>
        );

        if (isDesktop) {
            return (
                <PageLoading loading={fetching} className="screen-loader">
                    <Notification scopeToShow={FORM_ID} />
                    <div className="ecomex__page admin-detail-head px-0 mb-0">
                        <Head onBack={this.handleBack} />
                        <Head
                            centerElement={centerElement}
                            isFetchingExport={fetchingDownload}
                            exportList
                            handleClick={this.handleExport}
                            handleClickMessage="global.download"
                            csvDownload
                            accessibilityTextId="ecomex.alertsAndDues.title"
                            imageStyle="mr-2"
                        />
                        {this.rowButtons()}
                        {this.renderList()}
                    </div>
                </PageLoading>
            );
        }

        return (
            <div className="admin-detail-head px-0 mb-0 d-block">
                <Notification scopeToShow={FORM_ID} notificationClassname="navbar-fixed-top" />
                <Head
                    centerElement={centerElement}
                    centerContentClassName="mx-5"
                    isFetchingExport={fetchingDownload}
                    exportList
                    handleClick={this.handleExport}
                    handleClickMessage="global.download"
                    onBack={this.handleBack}
                    headerClassName="blue-main-header-mobile"
                    accessibilityTextId="ecomex.alertsAndDues.title"
                    downloadImageWhite={!fetching}
                    uniqueDownload
                />
                <PageLoading loading={fetching} className="screen-loader">
                    {this.rowButtons()}
                    {this.renderList()}
                </PageLoading>
            </div>
        );
    }

    render() {
        return this.renderContent();
    }
}

const mapStateToProps = (state) => ({
    fetching: ecomexSelectors.getFetching(state),
    fetchingDownload: ecomexSelectors.getFetchingDownload(state),
    ecomexAlerts: ecomexSelectors.getEcomexAlerts(state),
    ecomexDues: ecomexSelectors.getEcomexDues(state),
    optionSelected: ecomexSelectors.getOptionSelected(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        handleSubmit: () => {},
    }),
)(EcomexAlertsAndDuesMain);
