/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, bool } from "prop-types";
import { connect } from "react-redux";

import Card from "pages/_components/Card/Card";
import Button from "pages/_components/Button";
import classNames from "classnames";

class FundReportItem extends Component {
    static propTypes = {
        fundReport: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    openUrl = (url) => {
        if (window.cordova && window.cordova.InAppBrowser) {
            window.cordova.InAppBrowser.open(url, "_system", "hidden=no,location=no");
        } else {
            window.open(url, "_blank");
        }
    };

    render() {
        const { isDesktop, fundReport } = this.props;

        return (
            <Card
                className={classNames("py-25 fundcorp__report-card", { "mx-3": !isDesktop })}
                icon="images/pdf-icon.svg"
                isDesktop={isDesktop}
                title={
                    <div className="card__transaction-data-description d-flex justify-content-start">
                        <span className="mr-auto no-wrap ml-2">{fundReport?.name}</span>
                    </div>
                }
                rightContent={
                    <div>
                        <Button
                            onClick={() => {
                                this.openUrl(fundReport?.reportUrl);
                            }}
                            rel="noopener noreferrer"
                            label="global.download"
                            className="btn btn-outline download font-regular m-0 min-height-2 text-capitalize"
                            image="images/download_bold.svg"
                            disabled={!fundReport?.reportUrl}
                        />
                    </div>
                }
            />
        );
    }
}

export default connect()(FundReportItem);
