import React, { Component } from "react";
import { string, number, bool } from "prop-types";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { selectors as configSelectors } from "reducers/config";
import { selectors as i18nSelectors } from "reducers/i18n";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { countDecimalPlaces, numberFormat } from "util/number";
import classNames from "classnames";

class FormattedAmount extends Component {
    static propTypes = {
        className: string,
        frequencyInSpan: bool,
        notBold: bool,
        currency: string,
        messageAmount: string,
        frequency: string,
        lang: string.isRequired,
        maximumDecimals: number.isRequired,
        minimumDecimals: number.isRequired,
        maximumDecimalsCustom: number,
        minimumDecimalsCustom: number,
        quantity: number,
        showAbbreviature: bool,
        main: bool,
        ml: bool,
        small: bool,
        fontClassName: string,
        noCurrency: bool,
        cleanClassName: bool,
    };

    static defaultProps = {
        className: "data-amount",
        frequencyInSpan: true,
        frequency: "",
        showAbbreviature: false,
        quantity: 0,
        messageAmount: "",
        notBold: false,
        main: false,
        ml: false,
        small: false,
        currency: configUtils.get("core.masterCurrency"),
        fontClassName: "",
        noCurrency: false,
        cleanClassName: false,
        maximumDecimalsCustom: null,
        minimumDecimalsCustom: null,
    };

    getAmountDetails = (quantity) => {
        const { showAbbreviature } = this.props;
        const million = 1000000;

        if (showAbbreviature) {
            if (quantity >= million) {
                return {
                    quantity: quantity / million,
                    abbreviature: i18n.get("formattedAmount.millionAbbreviature"),
                };
            }
        }

        return {
            quantity,
            abbreviature: "",
        };
    };

    renderText = (formattedValue, isNegative) => {
        const {
            className,
            frequencyInSpan,
            currency,
            frequency,
            messageAmount,
            main,
            ml,
            notBold,
            small,
            fontClassName,
            noCurrency,
            cleanClassName,
        } = this.props;
        const currencyText = !noCurrency ? i18n.get(`currency.label.${currency}`) : "";
        return (
            <>
                <div className={classNames({ red: isNegative }, { "small-font": small }, className)}>
                    {messageAmount && <span>{messageAmount}</span>}{" "}
                    <span
                        className={classNames(
                            { "data-amount-currency": !notBold && !main && !cleanClassName },
                            { "data-amount-currency-big-font": main && notBold && !cleanClassName },
                            { "ml-2": ml && !cleanClassName },
                            fontClassName,
                        )}>
                        {`${currencyText} ${formattedValue}`}
                    </span>
                    {frequencyInSpan && <span>{` ${frequency}`}</span>}
                </div>
                {!frequencyInSpan && (
                    <div className="text-align-right" style={{ fontSize: "0.75rem" }}>
                        {frequency}
                    </div>
                )}
            </>
        );
    };

    render() {
        const {
            currency,
            quantity,
            maximumDecimals,
            minimumDecimals,
            lang,
            noCurrency,
            maximumDecimalsCustom,
            minimumDecimalsCustom,
        } = this.props;
        const { decimalSeparator, thousandSeparator } = numberFormat(lang);

        if (!currency && !noCurrency) {
            return null;
        }

        const amount = this.getAmountDetails(quantity);
        let decimalPlaces;
        let decimalScale;

        if (amount.abbreviature.length > 0) {
            decimalScale = 1;
        } else {
            decimalPlaces = countDecimalPlaces(quantity, decimalSeparator);
            const maximumDecimalsToUse = maximumDecimalsCustom || maximumDecimals;
            const minimumDecimalsToUse = minimumDecimalsCustom || minimumDecimals;
            decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimalsToUse), minimumDecimalsToUse);
        }
        const isNegative = parseFloat(amount.quantity, 10) < 0;

        return (
            <NumberFormat
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                decimalScale={decimalScale}
                value={amount.quantity}
                displayType="text"
                renderText={(formattedValue) => this.renderText(formattedValue, isNegative)}
                suffix={amount.abbreviature}
                fixedDecimalScale
            />
        );
    }
}

const mapStateToProps = (state) => ({
    maximumDecimals: Number(configSelectors.getConfig(state)["defaultDecimal.maximum"] || 0),
    minimumDecimals: Number(configSelectors.getConfig(state)["defaultDecimal.minimum"] || 0),
    lang: i18nSelectors.getLang(state),
});

export default connect(mapStateToProps)(FormattedAmount);
