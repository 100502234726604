import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { detailsSelectors, groupsSelectors } from "reducers/administration";
import { actions as formActions } from "reducers/form";
import { groupsActions } from "reducers/administration/advanced";

import GroupsOfUserConfirm from "pages/administration/_components/confirmations/GroupsOfUserConfirm";

const mapStateToProps = (state) => ({
    fetching: groupsSelectors.isFetching(state),
    credentialGroups: groupsSelectors.getCredentialGroups(state),
    user: detailsSelectors.getUser(state),
    selectedGroups: groupsSelectors.getSelectedGroups(state),
    availableGroups: groupsSelectors.getAvailableGroups(state),
    groupsToSave: groupsSelectors.getGroupsToSave(state),
    idTransaction: groupsSelectors.getIdTransaction(state),
    idActivity: groupsSelectors.getIdActivity(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(groupsActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GroupsOfUserConfirm);
