import { string, bool, shape, number, arrayOf } from "prop-types";
import { DETAIL_URL } from "constants/servicePayments";
import * as i18nUtils from "util/i18n";

export const adhesionShape = shape({
    adhesionCode: string,
    enteDescription: string,
    enteCode: string,
    userLp: string,
    category: shape({
        code: string,
        description: string,
        shortDescrition: string,
    }),
    manejaBaseDeuda: bool,
    reference: string,
});

export const statusCPEShape = shape({
    code: string.isRequired,
    description: string.isRequired,
    handleBaseDebts: bool.isRequired,
    handleMultipleConcepts: bool.isRequired,
    handleIncomeReference: bool.isRequired,
    handleIncomeAmount: bool.isRequired,
    enable: bool.isRequired,
    category: shape({
        code: string.isRequired,
        description: string.isRequired,
        shortDescrition: string.isRequired,
    }).isRequired,
    adhesion: shape({
        adhesionCode: string.isRequired,
        enteDescription: string.isRequired,
        enteCode: string.isRequired,
        belongsTo: string.isRequired,
        userLp: string.isRequired,
        category: string.isRequired,
    }),
});

export const paymentConceptWithoutBaseDebtShape = shape({
    conceptCode: string.isRequired,
    description: string.isRequired,
    paymentType: string.isRequired,
    managesIncomeAmounts: bool.isRequired,
    managesRange: bool.isRequired,
    managesIncomeReference: bool.isRequired,
    validLengthReference: bool.isRequired,
    minimumlengthTextReference: number.isRequired,
    maxLengthTextReference: number.isRequired,
    originalAmount: number.isRequired,
    permanentAmount: number.isRequired,
    minAmount: number.isRequired,
    maxAmount: number.isRequired,
});

export const expirationShape = arrayOf(
    shape({
        date: string.isRequired,
        amount: number.isRequired,
        debtId: string.isRequired,
        concept: paymentConceptWithoutBaseDebtShape.isRequired,
    }),
);

export const paymentConceptWithBaseDebtShape = shape({
    expirations: expirationShape.isRequired,
    entity: statusCPEShape.isRequired,
});

export const agendaPagoShape = shape({
    ususerLP: string.isRequired,
    codeAdherence: string.isRequired,
    entity: statusCPEShape.isRequired,
    expirations: arrayOf(expirationShape.isRequired),
    concepts: arrayOf(paymentConceptWithoutBaseDebtShape.isRequired),
    reference: string.isRequired,
});

export const flatScheduledPayments = (scheduledPayments, transactions) => {
    const pendingPayments = [
        ...new Set(transactions?.elementList?.map(({ data }) => data?.summaryInfo?.agendaPago?.key)),
    ];
    return scheduledPayments.flatMap(({ concepts, expirations, ...payment }) => {
        const allConcepts =
            concepts?.length > 0
                ? concepts
                : expirations.map(({ concept, ...expiration }) => ({
                      ...concept,
                      date: expiration.date,
                      amount: expiration.amount,
                      debtId: expiration.debtId,
                      concept: { ...concept },
                  }));
        return allConcepts.map((concept) => {
            let key = `${payment.ususerLP}-${payment.codeAdherence}-${concept.conceptCode}`;
            key = concept.debtId ? `${key}-${concept.debtId}` : key;
            return {
                key,
                isPending: pendingPayments.includes(key),
                isChecked: false,
                amount: { quantity: concept.amount },
                link: `${DETAIL_URL}/${key}`,
                concepts: [{ ...concept }],
                ...payment,
                concept,
                expiration: concept,
                paymentReference: "",
                expirations: [{ ...concept }],
            };
        });
    });
};

export const buildPreparePaymentsRequest = (payments) =>
    payments.map(({ concept, key, ...payment }) => ({
        key,
        selectedConceptCode: concept.conceptCode,
        selectedAmountToPay: payment.amount.quantity || 0,
        conceptPaymentType: concept.paymentType,
        conceptManagesIncomeAmounts: concept.managesIncomeAmounts,
        conceptManagesRange: concept.managesRange,
        conceptOriginalAmount: concept.originalAmount,
        conceptMinAmount: concept.minAmount,
        conceptMaxAmount: concept.maxAmount,
        validateReferenceLength: concept.validLengthReference,
        minLengthTextReference: concept.minimumlengthTextReference,
        maxLengthTextReference: concept.maxLengthTextReference,
        managesIncomeReference: concept.managesIncomeReference,
        _paymentReference: payment.paymentReference,
        selectedDebtId: concept?.debtId || "",
    }));

export const buildSummaryInfo = (payment, completeDebitAccount) => {
    const {
        entity: { handleBaseDebts, category },
        concept,
        ususerLP,
        amount,
        paymentReference,
    } = payment;

    return {
        agendaPago: payment,
        selectedRubro: category,
        selectedEntity: payment.entity,
        selectedCPE: ususerLP,
        selectedAmountToPay: amount.quantity,
        selectedConceptExpirationDate: handleBaseDebts
            ? concept?.date
            : i18nUtils.get("services.newPayment.noExpirationDate"),
        selectedConcept: concept,
        selectedDebitAccount: completeDebitAccount,
        paymentReference,
        statusCPE: payment.entity,
        serviceReference: "NO_REFERENCE",
        debtId: handleBaseDebts ? concept?.debtId : "",
        conceptCode: handleBaseDebts ? concept?.conceptCode : concept.conceptCode,
    };
};
