import * as i18nUtils from "util/i18n";

export const TODAY = "TODAY";
export const ONCE = "FUTURE";
export const DAY = "DAY";
export const WEEK = "WEEK";
export const MONTH = "MONTH";
export const YEAR = "YEAR";
export const CUSTOM = "CUSTOM";
export const NEVER = "UNLIMITED";
export const UP_TO = "UP_TO";
export const UNTIL = "UNTIL";

const FIRST = "first";
const SECOND = "second";
const THRID = "third";
const FOURTH = "fourth";
const LAST = "last";

export const findScheduleDesc = (selectedOption) => {
    if (!selectedOption) {
        return "";
    }
    switch (selectedOption.toUpperCase()) {
        case "DAY":
            return i18nUtils.get("scheduled.transaction.day");
        case "WEEK":
            return i18nUtils.get("scheduled.transaction.week");
        case "MONTH":
            return i18nUtils.get("scheduled.transaction.month");
        case "YEAR":
            return i18nUtils.get("scheduled.transaction.year");
        case "FUTURE":
            return i18nUtils.get("scheduled.transaction.future");
        default:
            return "";
    }
};

export const listRecurrencies = () => [
    {
        value: TODAY,
        label: i18nUtils.get(`scheduler.${TODAY.toLowerCase()}`),
    },
    {
        value: ONCE,
        label: i18nUtils.get(`scheduler.${ONCE.toLowerCase()}`),
    },
    {
        value: DAY,
        label: i18nUtils.get(`scheduler.${DAY.toLowerCase()}`),
    },
    {
        value: WEEK,
        label: i18nUtils.get(`scheduler.${WEEK.toLowerCase()}`),
    },
    {
        value: MONTH,
        label: i18nUtils.get(`scheduler.${MONTH.toLowerCase()}`),
    },
    {
        value: YEAR,
        label: i18nUtils.get(`scheduler.${YEAR.toLowerCase()}`),
    },
    // {
    //     value: CUSTOM,
    //     label: i18nUtils.get(`scheduler.${CUSTOM.toLowerCase()}`),
    // },
];

export const listEvery = () => [
    {
        value: DAY,
        label: i18nUtils.get(`scheduler.${DAY.toLowerCase()}s`),
    },
    {
        value: WEEK,
        label: i18nUtils.get(`scheduler.${WEEK.toLowerCase()}s`),
    },
    {
        value: MONTH,
        label: i18nUtils.get(`scheduler.${MONTH.toLowerCase()}s`),
    },
    {
        value: YEAR,
        label: i18nUtils.get(`scheduler.${YEAR.toLowerCase()}s`),
    },
];

export const getOccurrenceWithWeekOfMonth = (weekOfMonth) => {
    switch (weekOfMonth) {
        case 1:
            return FIRST;
        case 2:
            return SECOND;
        case 3:
            return THRID;
        case 4:
            return FOURTH;
        default:
            return LAST;
    }
};
