import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { replace } from "react-router-redux";
import { func, number, shape, arrayOf } from "prop-types";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as loginSelectors } from "reducers/login";
import { actions as statusActions } from "reducers/status";

import DetailHeadInfo from "pages/accounts/_components/DetailHeadInfo";
import DetailHeadTitle from "pages/accounts/_components/DetailHeadTitle";

import MoreFilters from "pages/accounts/_components/MoreFilters";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";

import TabContent, { TabComponent } from "pages/_components/TabComponent";

import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";
import * as configUtil from "util/config";

import { bool, string } from "yup";
import { productTypes } from "util/accounts";

class Details extends Component {
    static propTypes = {
        account: shape({}).isRequired,
        activeEnvironmentForms: shape({
            reduce: func,
        }).isRequired,
        channels: arrayOf().isRequired,
        fetching: bool.isRequired,
        fetchingDownload: func.isRequired,
        fetchingMovements: func.isRequired,
        totalAccounts: number.isRequired,
        match: shape().isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        totalCount: number.isRequired,
        activeRegion: string.isRequired,
        loggedUser: shape({}).isRequired,
        accountsHref: string.isRequired,
    };

    state = {
        lastMonthPeriod: null,
        secondLastMonthPeriod: null,
        selectedFilter: null,
        tabSelected: 1,
    };

    componentDidMount() {
        const { dispatch, loggedUser, ...rest } = this.props;

        dispatch(accountsActions.details(rest.match.params.id, loggedUser.userId));

        this.setState((prevState) => ({
            ...prevState,
            lastMonthPeriod: dateUtils.getLastMonthPeriod(),
            secondLastMonthPeriod: dateUtils.getSecondLastMonthPeriod(),
        }));
        dispatch(statusActions.saveLastHref(rest.location));
    }

    handleClick = () => {
        const { dispatch } = this.props;

        dispatch(accountsActions.toggleOptions());
    };

    handleFilterButtonsClick = (id) => {
        const { lastMonthPeriod, secondLastMonthPeriod } = this.state;
        const { dispatch, ...rest } = this.props;

        this.setState((prevState) => ({ ...prevState, selectedFilter: id }));

        let period = [];
        switch (id) {
            case "lastMonth":
                period = lastMonthPeriod;
                break;
            case "secondLastMonth":
                period = secondLastMonthPeriod;
                break;
            case "all":
            default:
                break;
        }

        const filters = { dateFrom: period[0], dateTo: period[1] };
        dispatch(accountsActions.fetchMovements(rest.match.params.id, filters));
    };

    handleClickDownloadMovements = (format) => {
        const { dispatch, match } = this.props;
        dispatch(accountsActions.downloadMovements(match.params.id, format));
    };

    formatDocument = (document, documentType) => {
        let documentFormated = "";
        const documentWithoutBlank = document.replace(" ", "").trim();
        if (documentType !== "DNI" && documentWithoutBlank.length > 2) {
            if (documentWithoutBlank.length > 2) {
                documentFormated = documentWithoutBlank.substring(0, 2).concat("-");
            }
            if (documentWithoutBlank.length <= 10) {
                documentFormated += documentWithoutBlank.substring(2);
            }
            if (documentWithoutBlank.length > 10) {
                documentFormated += documentWithoutBlank
                    .substring(2, 10)
                    .concat("-")
                    .concat(documentWithoutBlank.substring(10));
            }
        } else {
            documentFormated = documentWithoutBlank;
        }

        return documentFormated;
    };

    handleClickDownloadInfo = (format) => {
        const { dispatch, match, account } = this.props;
        const { productType, number: accountNumber, cbu, cbuAlias, ownerName, documentNumber, currency } = account;
        const accountType = i18nUtils.get(`accounts.productType.${productTypes[productType]}`).toUpperCase();
        const currencyType = i18nUtils.get(`currency.label.${currency}`);
        const accountLabel = `${accountType} en ${currencyType}`;
        const cuit = documentNumber ? this.formatDocument(documentNumber.toString(), "CUIT") : " - ";

        dispatch(
            accountsActions.downloadInformation(match.params.id, {
                accountLabel,
                accountNumber,
                cbu,
                cbuAlias,
                ownerName,
                cuit,
                format,
            }),
        );
    };

    handleBack = () => {
        const { dispatch, accountsHref } = this.props;
        dispatch(replace(accountsHref));
    };

    handleTab = (value) => {
        this.setState((prevState) => ({ ...prevState, tabSelected: value }));
    };

    showFilters = (totalCount) => totalCount > configUtil.get("accounts.statementsPerPage");

    render() {
        const { secondLastMonthPeriod, selectedFilter, tabSelected } = this.state;
        const {
            totalCount,
            account,
            channels,
            fetching,
            fetchingDownload,
            fetchingMovements,
            dispatch,
            isDesktop,
            activeRegion,
            ...rest
        } = this.props;

        const filters = [
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "all",
                })}
                key="all"
                label="accounts.movements.filters.all"
                onClick={() => this.handleFilterButtonsClick("all")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "lastMonth",
                })}
                key="lastMonth"
                label="accounts.movements.filters.lastMonth"
                onClick={() => this.handleFilterButtonsClick("lastMonth")}
            />,
            <Button
                className={classNames("btn btn-outline", {
                    "is-active": selectedFilter === "secondLastMonth",
                })}
                key="secondLastMonth"
                label="accounts.movements.filters.secondLastMonth"
                onClick={() => this.handleFilterButtonsClick("secondLastMonth")}
                replace={{
                    SECOND_LAST_MONTH:
                        secondLastMonthPeriod &&
                        `${
                            i18nUtils.getArray("global.months")[secondLastMonthPeriod[0].getMonth()]
                        } ${secondLastMonthPeriod[0].getFullYear().toString()}`,
                }}
            />,
        ];

        return (
            <PageLoading loading={fetching} className="screen-loader">
                {!fetching && account && (
                    <>
                        <Notification scopeToShow="account/details" />
                        <ProductDetail>
                            <ProductDetail.Head
                                onBack={this.handleBack}
                                dispatch={dispatch}
                                hideDownload
                                productId={rest.match.params.id}
                                title="account.detail.title">
                                {isDesktop && <div />}
                                <DetailHeadTitle account={account} isDesktop={isDesktop} dispatch={dispatch} />
                                <DetailHeadInfo {...this.props}>
                                    <div className="tab-container">
                                        <TabComponent
                                            tabSelected={tabSelected}
                                            changeState={this.handleTab}
                                            isDesktop={isDesktop}
                                        />
                                    </div>
                                </DetailHeadInfo>
                            </ProductDetail.Head>

                            <div className="accounts-main-content">
                                <ProductDetail.Body
                                    closeMoreFiltersWhenSiblingClick
                                    isDesktop={isDesktop}
                                    filters={this.showFilters(totalCount) ? filters : null}
                                    filtersKeyLabel="accounts.movements"
                                    moreFilters={
                                        <MoreFilters
                                            channels={channels}
                                            currency={account.currency}
                                            dispatch={dispatch}
                                            isDesktop={isDesktop}
                                            isSubmitting={rest.isSubmitting}
                                            productId={rest.match.params.id}
                                        />
                                    }
                                    moreFiltersClosedKeyLabel="accounts.movements.filters.more"
                                    moreFiltersOpenedKeyLabel="accounts.movements.filters.less">
                                    <div>
                                        <TabContent
                                            account={account}
                                            isDesktop={isDesktop}
                                            tabSelected={tabSelected}
                                            onClickDownloadInfo={this.handleClickDownloadInfo}
                                            {...this.props}
                                        />
                                    </div>
                                </ProductDetail.Body>
                            </div>
                        </ProductDetail>
                    </>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    totalAccounts: accountsSelectors.getTotalAccounts(state),
    channels: configUtil.getArray("core.enabledChannels"),
    fetching: accountsSelectors.getFetching(state),
    fetchingDownload: accountsSelectors.getFetchingDownload(state),
    fetchingMovements: accountsSelectors.getFetchingMovements(state),
    activeEnvironmentForms: sessionSelectors.getActiveEnvironmentForms(state, "accounts"),
    totalCount: accountsSelectors.getTotalCount(state),
    activeRegion: loginSelectors.getRegion(state),
    loggedUser: sessionSelectors.getUser(state),
    credentialGroups: accountsSelectors.getCredentialGroups(state),
    accountsHref: accountsSelectors.getAccountsLastHref(state),
});

export default connect(mapStateToProps)(Details);
