import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, arrayOf, shape, string, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import Yup from "yup";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";
import moment from "moment";

import { actions as ecomexActions, selectors as ecomexSelectors } from "reducers/ecomex";
import { actions as globalActions } from "reducers/types/global";
import { getLimitDate } from "util/date";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import Selector from "pages/_components/fields/formik/Selector";
import Date from "pages/_components/fields/DateField";
import TextField from "pages/_components/fields/TextField";
import TabletSelectors from "pages/_components/TabletSelectors";

import EcomexOperationsList from "pages/ecomex/_components/EcomexOperationsList";
import EcomexOperationsDetail from "pages/ecomex/_components/EcomexOperationsDetail";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18nUtils from "util/i18n";
import classNames from "classnames";
import * as configUtils from "util/config";

const FORM_ID = "ecomex.consultOperations.list";

class EcomexOperationsMain extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        dispatch: func.isRequired,
        fetchingDownload: bool.isRequired,
        ecomexOperations: arrayOf(shape({})).isRequired,
        filtersData: shape({}).isRequired,
        values: shape({}).isRequired,
        operationsTypes: arrayOf(shape({})).isRequired,
        beneficiaryNames: arrayOf(shape({})).isRequired,
        optionSelected: string.isRequired,
        setFieldValue: func.isRequired,
        selectedOperation: shape({}).isRequired,
        pageNumber: number.isRequired,
        numberPerPage: number.isRequired,
        isRetailEnvironment: bool.isRequired,
    };

    state = {
        selectedOpenDateFrom: null,
        selectedDueDateFrom: null,
        showForm: false,
        theFiltersAreChanged: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(ecomexActions.listEcomexOperationsPre("1"));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(globalActions.resetSelectedElements());
    }

    handleChangeOpenDateFrom = (selectedDate) => {
        this.setState({ selectedOpenDateFrom: selectedDate });
    };

    handleChangeDueDateFrom = (selectedDate) => {
        this.setState({ selectedDueDateFrom: selectedDate });
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    handleExport = (format) => {
        const { dispatch, ecomexOperations, filtersData, optionSelected, pageNumber, numberPerPage } = this.props;

        const ecomexOperationsToShow = ecomexOperations.slice(
            numberPerPage * (pageNumber - 1),
            numberPerPage * pageNumber,
        );

        const summary = { ecomexOperations: ecomexOperationsToShow, optionSelected, filtersData };
        dispatch(ecomexActions.downloadOperationsList(summary, format));
    };

    checkFiltersChanged = () => {
        const { values } = this.props;
        const {
            ecomexState,
            operationType,
            beneficiaryName,
            operationNumber,
            openDateFrom,
            openDateTo,
            dueDateFrom,
            dueDateTo,
        } = values;

        let filtersChanged = false;
        if (
            ecomexState !== "1" ||
            operationType !== "all" ||
            beneficiaryName !== "all" ||
            operationNumber ||
            openDateFrom ||
            openDateTo ||
            dueDateFrom ||
            dueDateTo
        ){
            filtersChanged = true;
        }
        this.setState({...this.state, theFiltersAreChanged: filtersChanged});
    };

    changeOption = (optionSelected) => {
        const { dispatch } = this.props;
        this.clearFilters();
        const defaultNumberPerPage = configUtils.getArray("ecomex.paginacion.cantidadRegistros");
        dispatch(ecomexActions.saveOptionSelected(optionSelected, "1", "all", "all", "", "", "", "", ""));
        dispatch(ecomexActions.ecomexOperationTypeChange(optionSelected));
        dispatch(
            ecomexActions.listEcomexOperations(
                1,
                defaultNumberPerPage[0],
                optionSelected,
                "1",
                "all",
                "all",
                "",
                "",
                "",
                "",
                "",
            ),
        );
    };

    clearFilters = () => {
        const { setFieldValue } = this.props;

        setFieldValue("ecomexState", "1");
        setFieldValue("operationType", "all");
        setFieldValue("beneficiaryName", "all");
        setFieldValue("operationNumber", "");
        setFieldValue("openDateFrom", "");
        setFieldValue("openDateTo", "");
        setFieldValue("dueDateFrom", "");
        setFieldValue("dueDateTo", "");

        this.setState({...this.state, theFiltersAreChanged: false})
    };

    rowButtons = () => {
        const { isDesktop, optionSelected, isRetailEnvironment } = this.props;
        const { showForm } = this.state;
        
        const possibleOptions = ["1", "2", "3", "5"];

        if (isRetailEnvironment) {
            possibleOptions.splice(2, 1);
        }

        const options = possibleOptions.map((possibleOption) => ({
            value: possibleOption,
            label: `ecomex.consultOperations.${possibleOption}.label${isRetailEnvironment ? ".retail" : ""}`,
        }));

        return (
            <div className={isDesktop && "d-flex space-between align-items-center w-100 background-white mb-3 px-3"}>
                <TabletSelectors
                    isDesktop={isDesktop}
                    possibleOptions={options}
                    changeOption={this.changeOption}
                    optionSelected={optionSelected}
                    containerClass={classNames("ecomex-tablet-selector", {
                        "py-3 w-100 flex-3": isDesktop,
                        "py-2": !isDesktop,
                    })}
                />
                {isDesktop && (
                    <div className="hide-filter__btn-content flex">
                        <div className="d-flex align-items-center flex-column justify-content-center">
                            <Button
                                block
                                bsStyle="outline"
                                className="hide-filter__btn m-0"
                                label={
                                    showForm
                                        ? "global.hide.filter"
                                        : `${
                                            this.state.theFiltersAreChanged ? "global.filter.active" : "global.see.filter"
                                          }`
                                }
                                image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                                onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                            />
                            {this.state.theFiltersAreChanged && (
                                <Button
                                    label="echeq.clean.filters"
                                    className="hide-filter__clean-btn"
                                    onClick={()=>this.changeOption(optionSelected)}
                                    image="images/cross.svg"
                                    imageStyle="circle-cross"
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderList() {
        const { beneficiaryNames, fetching, isDesktop, isSmallDesktop, operationsTypes, values } = this.props;
        const { showForm } = this.state;

        const { selectedOpenDateFrom, selectedDueDateFrom } = this.state;
        const { openDateFrom, openDateTo, dueDateFrom, dueDateTo } = values;
        const newDateTo = configUtils.get("frontend.eComex.historyQuery.limitDateFrom.Months");

        const states = [
            {
                value: "1",
                label: i18nUtils.get(`${FORM_ID}.search.ecomexStates.options.1`),
            },
            {
                value: "2",
                label: i18nUtils.get(`${FORM_ID}.search.ecomexStates.options.2`),
            },
        ];

        const operationsTypesOptions = [
            {
                value: "all",
                label: i18nUtils.get(`${FORM_ID}.search.operationsTypes.options.all`),
            },
            ...operationsTypes.map((operationType) => ({
                value: operationType.siglaOperacion,
                label: `${operationType.siglaOperacion} - ${operationType.descripcionTipoOperacion}`,
            })),
        ];

        const beneficiaryNamesOptions = [
            {
                value: "all",
                label: i18nUtils.get(`${FORM_ID}.search.beneficiaryNames.options.all`),
            },
            ...beneficiaryNames.map((beneficiaryName) => ({
                value: beneficiaryName.codigoBeneficiario,
                label: beneficiaryName.nombreBeneficiario,
            })),
        ];

        return (
            <PageLoading loading={fetching}>
                {!isDesktop && (
                    <Col xs={12} className="justify-content-end d-flex">
                        <div className="hide-filter__btn-content my-3 pr-0">
                            <Button
                                bsStyle="outline"
                                className="hide-filter__btn m-0"
                                image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                                label={
                                    showForm
                                        ? "global.hide.filter"
                                        : `${
                                            this.state.theFiltersAreChanged ? "global.filter.active" : "global.see.filter"
                                          }`
                                }
                                onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                            />
                        </div>
                    </Col>
                )}
                {showForm && (
                    <Form autoComplete="off">
                        <Container
                            className={`align-items-left account-header-detail account-header-align-items-left ${!isDesktop &&
                                "background-transparent"}  ${isDesktop && "px-3"}`}
                            gridClassName="form-content"
                            rowClassName="justify-content-left">
                            <Col
                                sm={12}
                                md={4}
                                lg={2}
                                className={classNames("align-self-flex-end ecomex-first-filter", {
                                    "pr-4 px-1": isDesktop,
                                    "px-3 pt-0": !isDesktop,
                                })}>
                                <Field
                                    component={Selector}
                                    options={states}
                                    idForm={`${FORM_ID}.search`}
                                    name="ecomexState"
                                    isRequired
                                    hidePlaceholder
                                    className="text-uppercase"
                                />
                            </Col>
                            <Col
                                sm={12}
                                md={4}
                                lg={3}
                                className={classNames("align-self-flex-end", {
                                    "pr-4 px-1": isDesktop,
                                    "px-3": !isDesktop,
                                })}>
                                <Field
                                    component={Selector}
                                    options={operationsTypesOptions}
                                    idForm={`${FORM_ID}.search`}
                                    name="operationType"
                                    isRequired
                                    searchable={isDesktop}
                                />
                            </Col>
                            <Col
                                sm={12}
                                md={4}
                                lg={5}
                                className={classNames("align-self-flex-end", {
                                    "pr-4 px-1": isDesktop,
                                    "px-3": !isDesktop,
                                    "pr-4": isSmallDesktop,
                                })}>
                                <Field
                                    component={Selector}
                                    options={beneficiaryNamesOptions}
                                    idForm={`${FORM_ID}.search`}
                                    name="beneficiaryName"
                                    isRequired
                                    searchable={isDesktop}
                                />
                            </Col>
                        </Container>
                        <Container
                            className={`align-items-left account-header-detail account-header-align-items-left mb-2 ${!isDesktop &&
                                "background-transparent"} ${isDesktop && "px-3"}`}
                            gridClassName="form-content"
                            rowClassName="justify-content-left pb-35 align-items-start">
                            <Col
                                sm={12}
                                md={4}
                                lg={2}
                                className={classNames({
                                    "pr-4 px-1": isDesktop,
                                    "px-3": !isDesktop,
                                })}>
                                <Field
                                    component={TextField}
                                    formGroupTextClassName="ellipsis-span"
                                    hidePlaceholder
                                    idForm={`${FORM_ID}.search`}
                                    name="operationNumber"
                                    type="text"
                                    pattern="[0-9]*"
                                    maxLength={6}
                                />
                            </Col>

                            <Col
                                sm={6}
                                md={4}
                                lg={2}
                                className={classNames({
                                    "px-1": isDesktop,
                                    "px-3 col-6": !isDesktop,
                                    "pr-4": isSmallDesktop,
                                })}>
                                <Field
                                    component={Date}
                                    formGroupTextClassName="ellipsis-span"
                                    idForm={`${FORM_ID}.search`}
                                    name="openDateFrom"
                                    hidePlaceholder
                                    startDate={openDateFrom}
                                    handleChange={this.handleChangeOpenDateFrom}
                                    endDate={openDateTo}
                                    isRequired
                                    minDate={moment().add(-newDateTo, "months")}
                                    value={openDateFrom}
                                />
                            </Col>

                            <Col
                                sm={6}
                                md={4}
                                lg={2}
                                className={classNames({ "pr-4 px-1": isDesktop, "px-3 col-6": !isDesktop })}>
                                <Field
                                    component={Date}
                                    formGroupTextClassName="ellipsis-span"
                                    hidePlaceholder
                                    idForm={`${FORM_ID}.search`}
                                    name="openDateTo"
                                    minDate={selectedOpenDateFrom}
                                    isRequired
                                    value={openDateTo}
                                />
                            </Col>

                            <Col
                                sm={6}
                                md={4}
                                lg={2}
                                className={classNames({
                                    "px-1": isDesktop,
                                    "px-3 col-6": !isDesktop,
                                    "pr-4": isSmallDesktop,
                                })}>
                                <Field
                                    component={Date}
                                    formGroupTextClassName="ellipsis-span"
                                    idForm={`${FORM_ID}.search`}
                                    name="dueDateFrom"
                                    hidePlaceholder
                                    startDate={dueDateFrom}
                                    handleChange={this.handleChangeDueDateFrom}
                                    endDate={dueDateTo}
                                    isRequired
                                    minDate={moment().add(-newDateTo, "months")}
                                    value={dueDateFrom}
                                />
                            </Col>

                            <Col
                                sm={6}
                                md={4}
                                lg={2}
                                className={classNames({ "pr-4 px-1": isDesktop, "px-3 col-6": !isDesktop })}>
                                <Field
                                    component={Date}
                                    formGroupTextClassName="ellipsis-span"
                                    hidePlaceholder
                                    idForm={`${FORM_ID}.search`}
                                    name="dueDateTo"
                                    minDate={selectedDueDateFrom}
                                    isRequired
                                    value={dueDateTo}
                                />
                            </Col>

                            <Col
                                sm={12}
                                md={4}
                                lg={2}
                                className={classNames("mb-1 d-flex", {
                                    "px-1 pt-45": isDesktop,
                                    "px-3": !isDesktop,
                                    "pr-4": isSmallDesktop,
                                })}>
                                <Button
                                    bsStyle="primary"
                                    label={`${FORM_ID}.search.button`}
                                    loading={fetching}
                                    type="submit"
                                    className="mt-1 mx-0"
                                    onClick={() => this.checkFiltersChanged()}
                                />
                            </Col>
                        </Container>
                    </Form>
                )}
                <div>
                    <EcomexOperationsList isDesktop={isDesktop} />
                </div>
            </PageLoading>
        );
    }

    renderContent() {
        const { isDesktop, fetching, fetchingDownload, selectedOperation } = this.props;

        const centerElement = () => (
            <div>
                <I18n
                    id="ecomex.consultOperations.title"
                    component="h1"
                    componentProps={{ className: "cmf-title-center font-size-28-px m-0" }}
                />
            </div>
        );

        if (selectedOperation) {
            return <EcomexOperationsDetail isDesktop={isDesktop} />;
        }

        if (isDesktop) {
            return (
                <PageLoading loading={fetching} className="screen-loader">
                    <div className="admin-detail-head px-0 mb-0">
                        <Head onBack={this.handleBack} />
                        <Head
                            centerElement={centerElement}
                            isFetchingExport={fetchingDownload}
                            exportList
                            handleClick={this.handleExport}
                            handleClickMessage="global.download"
                            headerClassName="ecomex-header mb-2"
                            csvDownload
                            accessibilityTextId="ecomex.consultOperations.title"
                            imageStyle="mr-2"
                        />
                    </div>
                    <div className="m-0">{this.rowButtons()}</div>
                    {this.renderList()}
                </PageLoading>
            );
        }

        return (
            <div className="admin-detail-head px-0 mb-0 d-block">
                <Head
                    centerElement={centerElement}
                    isFetchingExport={fetchingDownload}
                    exportList
                    handleClick={this.handleExport}
                    handleClickMessage="global.download"
                    onBack={this.handleBack}
                    headerClassName="blue-main-header-mobile"
                    accessibilityTextId="ecomex.consultOperations.title"
                    downloadImageWhite={!fetching}
                    uniqueDownload
                />
                <PageLoading loading={fetching} className="screen-loader">
                    {this.rowButtons()}
                    {this.renderList()}
                </PageLoading>
            </div>
        );
    }

    render() {
        const { isDesktop } = this.props;

        return (
            <>
                <Notification
                    scopeToShow={FORM_ID}
                    notificationClassname={classNames({ "navbar-fixed-top": !isDesktop })}
                />
                {this.renderContent()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: ecomexSelectors.getFetching(state),
    fetchingDownload: ecomexSelectors.getFetchingDownload(state),
    ecomexOperations: ecomexSelectors.getEcomexOperations(state),
    filtersData: ecomexSelectors.getFiltersData(state),
    operationsTypes: ecomexSelectors.getOperationsTypes(state),
    beneficiaryNames: ecomexSelectors.getBeneficiaryNames(state),
    optionSelected: ecomexSelectors.getOptionSelected(state),
    selectedOperation: ecomexSelectors.getSelectedOperation(state),
    pageNumber: ecomexSelectors.getPageNumber(state),
    numberPerPage: ecomexSelectors.getNumberPerPage(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: true,
        validateOnBlur: true,
        mapPropsToValues: () => ({
            ecomexState: "1",
            operationType: "all",
            beneficiaryName: "all",
            operationNumber: "",
            openDateFrom: "",
            openDateTo: "",
            dueDateFrom: "",
            dueDateTo: "",
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    ecomexState: Yup.string().required(i18nUtils.get(`${FORM_ID}.search.ecomexState.error.required`)),
                    operationType: Yup.string().required(
                        i18nUtils.get(`${FORM_ID}.search.operationsTypes.error.required`),
                    ),
                    beneficiaryName: Yup.string().required(
                        i18nUtils.get(`${FORM_ID}.search.beneficiaryName.error.required`),
                    ),
                    openDateFrom: values.openDateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.openDateTo, i18nUtils.get(`${FORM_ID}.search.minDate.error`))
                        : Yup.date().nullable(),
                    openDateTo: values.openDateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.openDateFrom, i18nUtils.get(`${FORM_ID}.search.maxDate.error`))
                        : Yup.date().nullable(),
                    dueDateFrom: values.dueDateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dueDateTo, i18nUtils.get(`${FORM_ID}.search.minDate.error`))
                        : Yup.date().nullable(),
                    dueDateTo: values.dueDateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dueDateFrom, i18nUtils.get(`${FORM_ID}.search.maxDate.error`))
                        : Yup.date().nullable(),
                }),
            ),
        handleSubmit: (
            {
                ecomexState,
                operationType,
                beneficiaryName,
                operationNumber,
                openDateFrom,
                openDateTo,
                dueDateFrom,
                dueDateTo,
            },
            formikBag,
        ) => {
            const { dispatch, optionSelected, pageNumber, numberPerPage } = formikBag.props;
            dispatch(
                ecomexActions.listEcomexOperations(
                    pageNumber,
                    numberPerPage,
                    optionSelected,
                    ecomexState,
                    operationType,
                    beneficiaryName,
                    operationNumber,
                    openDateFrom,
                    openDateTo,
                    dueDateFrom,
                    dueDateTo,
                ),
            );
        },
    }),
)(EcomexOperationsMain);
