import { func, node, string } from "prop-types";
import { useEffect } from "react";

function useUpdateFormValue(key, value, setFieldValue, shouldValidate = false) {
    useEffect(() => {
        const updateValue = () => {
            setFieldValue([key], value, shouldValidate);
        };
        updateValue();
    }, [key, value, setFieldValue, shouldValidate]);
}

useUpdateFormValue.propTypes = {
    key: string.isRequired,
    value: node.isRequired,
    setFieldValue: func.isRequired,
};

export default useUpdateFormValue;
