import React, { Component } from "react";
import { actions as financingActions, selectors as financingSelectors } from "reducers/financing";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { shape, bool, arrayOf, number, func } from "prop-types";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import FinancingItem from "pages/financing/_components/FinancingItem";
import PageLoading from "pages/_components/PageLoading";
import classNames from "classnames";
import Pagination from "pages/_components/pagination/Pagination";

const tableLabel = "financing.table.header";
class FinancingTable extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        financingList: arrayOf(shape({})).isRequired,
        isDesktop: bool.isRequired,
        values: shape({}).isRequired,
        category: shape({}).isRequired,
        totalPages: number.isRequired,
        pageNumber: number.isRequired,
        dispatch: func.isRequired,
    };

    static defaultProps = {};

    fetchMoreTransactions = (pageNumber) => {
        const { dispatch, category, values } = this.props;
        const { currency, dateFrom, dateTo } = values;

        dispatch(
            financingActions.loadListRequest({
                dateFrom: dateFrom.format(),
                dateTo: dateTo.format(),
                currency,
                categoryCode: category.productCategoryCode,
                pageNumber,
            }),
        );
    };

    render() {
        const { financingList, isDesktop, fetching, totalPages, pageNumber } = this.props;

        const list = financingList?.map((item) => (
            <Table.Row
                className={classNames(
                    "container-white",
                    { "ml-3 mr-3 mb-2 w-auto": !isDesktop },
                    { "pr-2": isDesktop },
                )}
                key={item.numeroOperacion}
                renderAs={Link}
                to={`/financing/detail/${item.numeroOperacion}`}>
                <FinancingItem
                    key={`transaction-${item.numeroOperacion}`}
                    request={item}
                    isDesktop={isDesktop}
                    tableLabel={tableLabel}
                />
            </Table.Row>
        ));

        const renderTable = isDesktop ? (
            <>
                <Table className="without-border-bottom">
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left" className="header-bold">
                            <I18n id={`${tableLabel}.operationNumber`} />
                        </Table.HeaderData>
                        <Table.HeaderData align="center" className="header-bold">
                            <I18n id={`${tableLabel}.typeNumber`} />
                        </Table.HeaderData>
                        <Table.HeaderData className="header-bold">
                            <I18n id={`${tableLabel}.date`} />
                        </Table.HeaderData>
                        <Table.HeaderData className="header-bold">
                            <I18n id={`${tableLabel}.dateExpiration`} />
                        </Table.HeaderData>
                        <Table.HeaderData className="header-bold">
                            <I18n id={`${tableLabel}.pendingFee`} />
                        </Table.HeaderData>
                        <Table.HeaderData align="right" className="header-bold">
                            <I18n id={`${tableLabel}.amount`} />
                        </Table.HeaderData>
                    </Table.Header>
                    <Table.Body>{list}</Table.Body>
                </Table>
                <div className="d-flex w-100 justify-content-end">
                    <Pagination totalPages={totalPages} pageNumber={pageNumber} action={this.fetchMoreTransactions} />
                </div>
            </>
        ) : (
            list
        );

        return (
            <PageLoading className="line-loader" loading={isDesktop && fetching}>
                <Container className="flex-grow">
                    <Col className="col col-12 px-0">
                        <div
                            className="bd-spacing-0-2 w-100"
                            ref={(tableRef) => {
                                this.child = tableRef;
                            }}>
                            {financingList?.length ? (
                                renderTable
                            ) : (
                                <div className="text-center no-more-data" key="noMoreMovements">
                                    <div className="illustration-wrapper">
                                        <Image src="images/coloredIcons/find-in-page.svg" className="svg-big-icon" />
                                    </div>
                                    <p className="text-lead opacity-1">
                                        <I18n id="financing.table.header.empty.message" />
                                    </p>
                                </div>
                            )}
                        </div>
                    </Col>
                </Container>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    financingList: financingSelectors.getFinancing(state),
    fetching: financingSelectors.getFetching(state),
    totalPages: financingSelectors.getTotalPages(state),
    pageNumber: financingSelectors.getPageNumber(state),
});

export default connect(mapStateToProps)(FinancingTable);
