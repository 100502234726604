import React, { Component } from "react";
import { func, bool } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";

import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";

import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";

import Heading from "pages/_components/Heading";

class EnableContributorDelete extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        cuit: bool.isRequired,
        reference: bool.isRequired,
        fetching: bool.isRequired,
    };

    handleGoBack = () => {
        const { dispatch } = this.props;

        dispatch(paymentsAFIPActions.hideDeleteEnabledContributor());
    };

    render() {
        const { cuit, reference, fetching, isDesktop } = this.props;
        const containerClassName = `transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`;

        return (
            <>
                <MainContainer>
                    <div className="above-the-fold">
                        <Form>
                            <Container
                                className={`flex-grow align-items-center container-white ${
                                    !isDesktop ? "" : "with-margin with-padding pb-5"
                                }`}
                                gridClassName="form-content">
                                <Col sm={12} md={6}>
                                    <div className="transfer-block">
                                        <h1 className="form-title text-center">
                                            <I18n id="payments.enabledContributors.delete.subTitle" />
                                        </h1>
                                    </div>
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        label="payments.enabledContributors.delete.cuit"
                                        data={cuit}
                                    />

                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        label="payments.enabledContributors.delete.reference"
                                        data={reference}
                                    />
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="payments.enabledContributors.delete.sendButton"
                                        loading={fetching}
                                        className="mt-5"
                                    />
                                </Col>
                            </Container>
                        </Form>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: paymentsAFIPSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: true,
        validateOnBlur: false,
        handleSubmit: ({ cuit }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(paymentsAFIPActions.deleteEnabledContributor(cuit, formikBag));
        },
    }),
)(EnableContributorDelete);
