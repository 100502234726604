import React, { Component, Fragment } from "react";
import { bool, shape, func, arrayOf } from "prop-types";

import { goBack } from "react-router-redux";

import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import Table from "pages/_components/Table";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";
import Pagination from "pages/_components/pagination/Pagination";
import BatchItem from "./BatchItem";
import BatchDetailErrorsModal from "./BatchDetailErrorsModal";

class BatchDetailList extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isFetching: bool.isRequired,
        dispatch: func.isRequired,
        echeqBatch: shape({}).isRequired,
        batchDetail: arrayOf(shape({})),
        pagination: shape({}).isRequired,
        fetchMoreTransactions: func.isRequired,
        summary: shape({}).isRequired,
    };

    static defaultProps = {
        batchDetail: [],
    };

    state = {
        modalVisible: false,
        dataModal: [],
    };

    handleBackClick = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    toggleModal = (status, data) => {
        this.setState({ modalVisible: status, dataModal: data });
    };

    renderItem = (item) => {
        const { summary, isDesktop } = this.props;
        return (
            <BatchItem
                key={item.id}
                summary={summary}
                showErrorsModal={this.toggleModal}
                echeq={item}
                isDesktop={isDesktop}
            />
        );
    };

    renderMobile = (items) => {
        if (!items.length) {
            return <I18n id="transactions.list.none" />;
        }
        const { pagination, fetchMoreTransactions } = this.props;
        const { totalPages, pageNumber } = pagination;

        const list = items.map((item) => this.renderItem(item));

        return (
            <Container className="flex-grow">
                <Col md={12} className="table-with-border px-0">
                    <Table className="mb-0">
                        <Table.Body>{list}</Table.Body>
                    </Table>
                    <div className="d-flex w-100 justify-content-end mr-3 mb-5">
                        <Pagination totalPages={totalPages} pageNumber={pageNumber} action={fetchMoreTransactions} />
                    </div>
                </Col>
            </Container>
        );
    };

    renderDesktop = (items) => {
        const { pagination, fetchMoreTransactions, echeqBatch } = this.props;
        const { totalPages, pageNumber } = pagination;
        const list = items.map((item) => this.renderItem(item));
        return (
            <>
                <Table className="container-white">
                    <Table.Header>
                        <Table.HeaderData align="left" className="data-ref">
                            <I18n id="echeq.batch.detail.table.header.echeqNumber" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center" className="data-ref">
                            <I18n id="echeq.batch.detail.body.header.document" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right" className="data-ref">
                            <I18n id="echeq.batch.detail.body.header.paymentDate" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right" className="data-cell-amount">
                            <I18n id="echeq.batch.detail.body.header.amount" />
                        </Table.HeaderData>
                        {["PROCESS_SUCCESS", "PROCESS_ERROR"].some((s) => s === echeqBatch?.status) && (
                            <Table.HeaderData align="right" className="data-state">
                                <I18n id="echeq.batch.detail.body.header.state" />
                            </Table.HeaderData>
                        )}
                    </Table.Header>
                    <Table.Body>{list}</Table.Body>
                </Table>
                <div className="d-flex w-100 justify-content-end mr-3">
                    <Pagination totalPages={totalPages} pageNumber={pageNumber} action={fetchMoreTransactions} />
                </div>
            </>
        );
    };

    renderContentList = () => {
        const { batchDetail, isDesktop } = this.props;

        if (!isDesktop) {
            return this.renderMobile(batchDetail);
        }

        return this.renderDesktop(batchDetail);
    };

    renderContent = () => {
        const { isFetching, isDesktop } = this.props;
        const { modalVisible, dataModal } = this.state;
        return (
            <>
                <BatchDetailErrorsModal
                    isDesktop={isDesktop}
                    isVisible={modalVisible}
                    toggleModal={this.toggleModal}
                    dataModal={dataModal}
                />
                <MainContainer showLoader={isFetching}>
                    <div className="above-the-fold">
                        <Container.Column style={{ padding: "0px" }}>{this.renderContentList()}</Container.Column>
                    </div>
                </MainContainer>
            </>
        );
    };

    render() {
        const { isFetching } = this.props;
        return (
            <Fragment>
                <PageLoading loading={isFetching}>{this.renderContent()}</PageLoading>
            </Fragment>
        );
    }
}

export default BatchDetailList;
