import React from "react";

const FieldHint = (props) => {
    const { text } = props;

    if (text) {
        // TODO: This component requires proper HTML ;)
        return (
            <div className="form-group-text">
                <span>{text}</span>
            </div>
        );
    }
    return null;
};

export default FieldHint;
