import React, { Component } from "react";
import { bool, shape } from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers/checks";
import FormattedDate from "pages/_components/FormattedDate";
import moment from "moment";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import { echeqConceptLabel } from "util/checks";
import * as configUtils from "util/config";
import { isFinalState } from "util/transaction";

const FORM_ID = "echeq.transaction";

class EcheqActionTransactionData extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dateTimeCreationIsDifferentFromModify = (creation, modification) => {
        const creation_ = moment(creation);
        const modification_ = moment(modification);
        return modification_.diff(creation_, "seconds") > 1;
    };

    render() {
        const { transactionData, isDesktop } = this.props;
        const { specialAttribute } = transactionData;

        const concept = `${transactionData.cheque_concepto} ${echeqConceptLabel(transactionData.cheque_concepto, "-")}`;

        const isAdmittedCesion = ["echeq.admit-cesion.action"].includes(transactionData?.idActivity);
        const chequesFunctionalities = configUtils.getBoolean(
            "frontend.show.failedVoucher.chequesFunctionalities",
            false,
        );

        const cuit = `CUIT ${transactionData?.emisor_cuit}`;
        const sender = transactionData?.emisor_razon_social;

        return (
            transactionData?.idTransaction && (
                <>
                    {isFinalState(transactionData.idTransactionStatus) && (
                        <>
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="forms.transaction.ticket.date"
                                data={
                                    <p className="data-desc m-0">
                                        <FormattedDate
                                            date={transactionData.submitDateTime || new Date()}
                                            dateFormat="dd/MM/yyyy"
                                            showTime
                                        />
                                    </p>
                                }
                            />
                        </>
                    )}
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="negotiate.transaction.voucher.label"
                        data={
                            <p className="data-desc m-0">
                                {transactionData.idTransaction.substring(0, 8).toUpperCase()}
                            </p>
                        }
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.echeq.number`}
                        data={transactionData.cheque_numero}
                    />

                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.echeq.id`}
                        data={transactionData.cheque_id}
                    />

                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between transaction-ticket-amount"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.amount`}
                        data={<FormattedAmount currency="0" quantity={transactionData.monto} notBold />}
                    />

                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.emision`}
                        data={<FormattedDate date={transactionData?.fecha_emision} dateFormat="dd/MM/yyyy" />}
                    />

                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.payed`}
                        data={
                            <FormattedDate
                                showTime={false}
                                date={transactionData?.fecha_pago}
                                dateFormat="dd/MM/yyyy"
                            />
                        }
                    />

                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.echeq.type`}
                        data={
                            <I18n
                                component="p"
                                id={`echeqs.emit.${transactionData.cheque_tipo.toLowerCase()}.label`}
                                componentProps={{ className: "data-desc m-0" }}
                            />
                        }
                    />

                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.echeq.character`}
                        data={transactionData.cheque_caracter}
                    />

                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.mode`}
                        data={i18n.get(`echeqs.${transactionData.cheque_modo}.modo`)}
                    />

                    {isAdmittedCesion && (
                        <>
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="echeqs.document.label"
                                data={cuit}
                            />

                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="echeqs.sender.label"
                                data={sender}
                            />
                        </>
                    )}

                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.concept`}
                        data={<p className="data-desc m-0">{concept}</p>}
                    />

                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.motive`}
                        data={transactionData.cheque_motivo_pago}
                    />

                    {(transactionData.idActivity === "echeq.deposit.action" ||
                        transactionData.idActivity === "echeq.custody.action") && (
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`echeqs.${transactionData.action.toLowerCase()}.account`}
                            data={specialAttribute}
                        />
                    )}
                    {transactionData.idActivity === "echeq.repudiate.action" && (
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="echeqs.repudiate_motive"
                            data={specialAttribute}
                        />
                    )}

                    {!chequesFunctionalities && (
                        <>
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label={`${FORM_ID}.coelsa.response`}
                                data={transactionData.coelsaResponse}
                            />
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label={`${FORM_ID}.coelsa.code`}
                                data={transactionData.coelsaCode}
                            />
                        </>
                    )}
                </>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionEcheqData(state),
});

export default connect(mapStateToProps)(EcheqActionTransactionData);
