import React from "react";
import { Field, FieldArray } from "formik";
import { string, shape, number, func, arrayOf, bool } from "prop-types";

import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Selector from "pages/_components/fields/formik/Selector";
import AmountField from "pages/_components/fields/formik/AmountField";
import * as i18nUtils from "util/i18n";
import { statusCPEShape, paymentConceptWithoutBaseDebtShape } from "util/servicePayments";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import classNames from "classnames";

function ConceptWithoutBaseDebt({ FORM_ID, concepts, statusCPE, getRadioOptions, currencies, isDesktop }) {
    ConceptWithoutBaseDebt.propTypes = {
        FORM_ID: string.isRequired,
        statusCPE: statusCPEShape.isRequired,
        concepts: arrayOf(paymentConceptWithoutBaseDebtShape).isRequired,
        getRadioOptions: func.isRequired,
        currencies: arrayOf(shape({ id: number, label: string })),
        isDesktop: bool,
    };

    ConceptWithoutBaseDebt.defaultProps = {
        currencies: [{ id: 0, label: "ARS" }],
        isDesktop: true,
    };

    const { handleMultipleConcepts: multipleConcepts } = statusCPE;

    return isDesktop ? (
        <FieldArray
            name="concepts"
            render={() =>
                concepts.map((concept, index) => (
                    <Row className="mx-0 d-flex justify-content-center align-items-center px-3">
                        {concept.managesIncomeAmounts ? (
                            <>
                                <Col className="d-flex pl-md-0 align-items-center" sm={12} md={6}>
                                    <div className="d-flex align-items-center">
                                        {multipleConcepts && (
                                            <Field
                                                idForm={FORM_ID}
                                                formGroupClassName={concept.managesRange ? "mb-0" : "mb-3"}
                                                name="concept"
                                                component={Selector}
                                                renderAs="radio"
                                                hideLabel
                                                options={getRadioOptions(index)}
                                            />
                                        )}
                                        <div className="d-flex flex-column">
                                            <Field
                                                component={AmountField}
                                                formGroupClassName={concept.managesRange ? "mb-0" : "mb-4"}
                                                data={{ options: currencies }}
                                                name={`conceptAmount${concept.conceptCode}`}
                                                clearable
                                                maxLength={15}
                                                disableSelect
                                                hideLabel
                                                fixedDecimalScale
                                                plainValue
                                            />
                                            {concept.paymentType === "PAGO_INDISTINTO" && (
                                                <div style={{ display: "flex", color: "rgb(0, 113, 206)" }}>
                                                    <span
                                                        className="font-light f-size-small"
                                                        style={{ paddingRight: "5px" }}>
                                                        {i18nUtils
                                                            .get("accounts.movements.filters.minAmount.label")
                                                            .toUpperCase()}
                                                    </span>
                                                    <FormattedAmount
                                                        cleanClassName
                                                        className="font-light f-size-small"
                                                        currency={currencies[0].id.toString()}
                                                        quantity={concept.minAmount}
                                                    />
                                                    <span
                                                        className="font-light f-size-small"
                                                        style={{ padding: "0 5px" }}>
                                                        {" "}
                                                        |{" "}
                                                    </span>
                                                    <span
                                                        className="font-light f-size-small"
                                                        style={{ paddingRight: "5px" }}>
                                                        {i18nUtils
                                                            .get("accounts.movements.filters.maxAmount.label")
                                                            .toUpperCase()}
                                                    </span>
                                                    <FormattedAmount
                                                        cleanClassName
                                                        className="font-light f-size-small"
                                                        currency={currencies[0].id.toString()}
                                                        quantity={concept.maxAmount}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col className="d-flex justify-content-center" sm={6} md={3}>
                                    <span className="font-light f-size-55">
                                        {i18nUtils.get("services.newPayment.noExpirationDate")}
                                    </span>
                                    <FormattedDate date={concept.date} dateFormat="dd/MM/yyyy" />
                                </Col>
                                <Col className="d-flex justify-content-end px-0" sm={6} md={3}>
                                    <span className="font-light f-size-55">{concept.description}</span>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col className="d-flex justify-content-start align-items-center pl-0" sm={12} md={6}>
                                    {multipleConcepts && (
                                        <Field
                                            idForm={FORM_ID}
                                            formGroupClassName="m-0"
                                            name="concept"
                                            component={Selector}
                                            renderAs="radio"
                                            hideLabel
                                            options={getRadioOptions(index)}
                                        />
                                    )}
                                    <FormattedAmount
                                        cleanClassName
                                        fontClassName="font-light f-size-55"
                                        currency={currencies[0].id.toString()}
                                        quantity={concept.amount}
                                    />
                                </Col>
                                <Col className="d-flex justify-content-center" sm={6} md={3}>
                                    <FormattedDate
                                        className="font-light f-size-55"
                                        date={concept.date}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </Col>
                                <Col className="d-flex justify-content-end px-0" sm={6} md={3}>
                                    <span className="font-light f-size-55">{concept.description}</span>
                                </Col>
                            </>
                        )}
                    </Row>
                ))
            }
        />
    ) : (
        <FieldArray
            name="concepts"
            render={() =>
                concepts.map((concept, index) => (
                    <Row className="d-flex flex-no-wrap justify-content-center align-items-center mx-0 px-3 mt-3 pb-2">
                        {multipleConcepts && (
                            <Field
                                idForm={FORM_ID}
                                formGroupClassName={classNames("m-0", { "mb-35": concept.managesIncomeAmounts })}
                                name="concept"
                                component={Selector}
                                renderAs="radio"
                                hideLabel
                                options={getRadioOptions(index)}
                            />
                        )}
                        {concept.managesIncomeAmounts ? (
                            <div className="flex-column w-100">
                                <Col className="px-0 mb-25" sm={12} md={6}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.amountToPay`}
                                    />
                                    <Field
                                        component={AmountField}
                                        formGroupClassName="w-100 mb-0"
                                        amountClassName="text-align-right"
                                        data={{ options: currencies }}
                                        name={`conceptAmount${concept.conceptCode}`}
                                        clearable
                                        maxLength={15}
                                        disableSelect
                                        hideLabel
                                        fixedDecimalScale
                                        plainValue
                                    />

                                    {concept.paymentType === "PAGO_INDISTINTO" && (
                                        <div style={{ display: "flex", color: "rgb(0, 113, 206)" }}>
                                            <span className="font-light f-size-small" style={{ paddingRight: "5px" }}>
                                                {i18nUtils
                                                    .get("accounts.movements.filters.minAmount.label")
                                                    .toUpperCase()}
                                            </span>
                                            <FormattedAmount
                                                cleanClassName
                                                className="font-light f-size-small"
                                                currency={currencies[0].id.toString()}
                                                quantity={concept.minAmount}
                                            />
                                            <span className="font-light f-size-small" style={{ padding: "0 5px" }}>
                                                {" "}
                                                |{" "}
                                            </span>
                                            <span className="font-light f-size-small" style={{ paddingRight: "5px" }}>
                                                {i18nUtils
                                                    .get("accounts.movements.filters.maxAmount.label")
                                                    .toUpperCase()}
                                            </span>
                                            <FormattedAmount
                                                cleanClassName
                                                className="font-light f-size-small"
                                                currency={currencies[0].id.toString()}
                                                quantity={concept.maxAmount}
                                            />
                                        </div>
                                    )}
                                </Col>
                                <Col className="d-flex space-between line-height-1 px-0 mb-25" xs={12}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.expiration`}
                                    />
                                    <span>{i18nUtils.get("services.newPayment.noExpirationDate")}</span>
                                </Col>
                                <Col className="d-flex space-between line-height-1 px-0" xs={12}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.concept`}
                                    />
                                    <span className="font-light f-size-55">{concept.description}</span>
                                </Col>
                            </div>
                        ) : (
                            <div className="flex-column w-100">
                                <Col className="d-flex space-between line-height-1 px-0 mb-25" sm={12} md={6}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.amountToPay`}
                                    />
                                    <FormattedAmount
                                        cleanClassName
                                        fontClassName="font-light f-size-55"
                                        currency={currencies[0].id.toString()}
                                        quantity={concept.amount}
                                    />
                                </Col>
                                <Col className="d-flex space-between line-height-1 px-0 mb-25" sm={12} md={3}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.expiration`}
                                    />
                                    <span>{i18nUtils.get("services.newPayment.noExpirationDate")}</span>
                                </Col>
                                <Col className="d-flex space-between line-height-1 px-0" sm={12} md={3}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.concept`}
                                    />
                                    <span className="font-light f-size-55">{concept.description}</span>
                                </Col>
                            </div>
                        )}
                    </Row>
                ))
            }
        />
    );
}

export default ConceptWithoutBaseDebt;
