import React, { Component } from "react";
import { string } from "prop-types";

class FieldWarning extends Component {
    static propTypes = {
        warning: string,
    };

    static defaultProps = {
        warning: "",
    };

    render() {
        const { warning } = this.props;

        if (!warning) {
            return null;
        }
        return (
            <div className="form-group-warning">
                <span>{warning}</span>
            </div>
        );
    }
}

export default FieldWarning;
