export const statusTagColorBatch = (state) => {
    switch (state) {
        case "PENDING":
        case "PENDING_VALIDATION":
        case "PENDING_SEND":
            return "#ffe5b6";
        case "RECEIVED":
            return "#e6e6e6";
        case "FINISHED_ERROR":
        case "FINISHED_SUCCESS":
        case "SUCCESS":
            return "#84efd0";
        case "CANCELLED":
        case "FAILED":
        case "ERROR":
            return "#feb0af";
        default:
            return "#e6e6e6";
    }
};
