import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { bool, shape, string } from "prop-types";
import Selector from "pages/_components/fields/formik/Selector";
import { actions as transactionsActions } from "reducers/transactions";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import { selectors as sessionSelectors } from "reducers/session";

const FORM_ID = "transactions.list.filters.client";

class EnvironmentFilter extends Component {
    static propTypes = {
        fetching: bool,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    render() {
        const { fetching, activeEnvironment } = this.props;
        const members = activeEnvironment.clients.reduce(
            (prev, curr) => [...prev, { value: curr.idClient, label: curr.name }],
            [],
        );

        return (
            <Form>
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Col sm={12} md={4} className="col col-12 col-no-pad-mobile">
                        <Field component={Selector} options={members} idForm={FORM_ID} name="client" renderAs="combo" />
                    </Col>
                    <Col sm={12} md={4} className="col col-no-pad-mobile" style={{ alignSelf: "flex-end" }}>
                        <Button bsStyle="primary" label="product.filters.filter" loading={fetching} type="submit" />
                    </Col>
                </Container>
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: ({ ...filters }, formikBag) => {
            const { dispatch, isDesktop, onlyPendings, pendingDispatch, selectedFilter } = formikBag.props;

            if (isDesktop) {
                dispatch(
                    transactionsActions.loadListRequest(
                        { ...filters, filter: selectedFilter },
                        onlyPendings,
                        pendingDispatch,
                        formikBag,
                    ),
                );
            } else {
                dispatch(transactionsActions.setFilters({ ...filters }));
                dispatch(push(`/transactions/list/filters/results`));
            }
        },
    }),
)(EnvironmentFilter);
