import React, { Component } from "react";
import { func, bool, shape } from "prop-types";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";

import Head from "pages/_components/Head";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import { Form, withFormik } from "formik";
import { compose } from "redux";
import Notification from "pages/_components/Notification";

import { selectors as fundcorpSelectors, actions as fundcorpActions } from "reducers/fundcorp";

import * as i18nUtils from "util/i18n";

const FORM_ID = "scheduler.cancel";

class FundScheduledCancel extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
        location: shape({}).isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    centerContentMobile = (formId) => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: "-0.5rem 2rem" }}>{i18nUtils.get(`${formId}.title`)}</h1>
        </div>
    );

    render() {
        const { isDesktop, fetching, location } = this.props;
        const typeTransaction = location?.pathname?.split("/")[1];

        const continueButton = (
            <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                <Row>
                    <div className="admin-content-center">
                        <Button
                            className="text-initial"
                            type="submit"
                            bsStyle="primary"
                            label="global.confirm"
                            loading={fetching}
                        />
                    </div>
                </Row>
            </Col>
        );

        return (
            <>
                <Notification scopeToShow="transactionScheduledCancel" />
                <div className="admin-detail-head px-0 pb-4">
                    <Head
                        onBack={this.handleBack}
                        onClose={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={
                            isDesktop ? undefined : this.centerContentMobile(`${FORM_ID}.${typeTransaction}`)
                        }
                    />
                    {isDesktop && <h1>{i18nUtils.get(`${FORM_ID}.${typeTransaction}.title`)}</h1>}
                </div>
                <MainContainer>
                    <Form className="above-the-fold">
                        <Container
                            className={`flex-grow align-items-center cmf-container-white py-4 ${
                                !isDesktop ? "mb-0" : ""
                            }`}
                            gridClassName="form-content">
                            <Col className="col-12" lg={12} md={12} sm={12}>
                                <h1 className="text-align-center">{i18nUtils.get(`${FORM_ID}.question`)}</h1>
                            </Col>
                        </Container>
                        {isDesktop ? (
                            <Container
                                className="flex-grow align-items-center py-4"
                                gridClassName="form-content"
                                style={{ paddingLeft: 0 }}>
                                {continueButton}
                            </Container>
                        ) : (
                            <Container
                                className=" container--layaut flex-grow align-items-center cmf-container-white without-margin-top"
                                gridClassName="form-content"
                                style={{ width: "100%" }}>
                                <Col className="col-12 col-xl-4 py-4" lg={4} md={9} sm={12}>
                                    {continueButton}
                                </Col>
                            </Container>
                        )}
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: fundcorpSelectors.getSummary(state),
    fetching: fundcorpSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        handleSubmit: (_, formikBag) => {
            const { dispatch, summary, location } = formikBag.props;
            const typeTransaction = location?.pathname?.split("/")[1];
            dispatch(fundcorpActions.cancelScheduled({ ...summary, formikBag, type: typeTransaction }));
        },
    }),
)(FundScheduledCancel);
