import React, { Component } from "react";
import ReactDOM from "react-dom";
import { string, number, func, bool } from "prop-types";
import { connect } from "react-redux";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import {
    selectors as transactionLinesSelectors,
    actions as transactionLinesActions,
} from "reducers/form/transactionLines";

import Table from "pages/_components/Table";
import FormattedAmount from "pages/_components/FormattedAmount";
import PaymentLinesForm from "pages/forms/_components/_fields/_transactionlines/Form";
import PaymentLinesModal from "pages/_components/Modal";
import { ACCOUNT_TYPE, CURRENCY_I18N_CODE } from "constants.js";

export const listItemProps = {
    account: string,
    cbu: string,
    creditAmountCurrency: string,
    creditAmountQuantity: number,
    bankIdentifier: string,
    cuit: string,
    detailPreview: bool,
    motivo: string,
    voucher: string,
    referenc: string,
    status: string.isRequired,
    errorCode: string,
    errorCodeMsg: string,
    lineNumber: number.isRequired,
    isDesktop: bool,
    isTicket: bool,
    isConfirmation: bool,

    isReadOnly: bool.isRequired,
};

class TransactionLinesListItem extends Component {
    static propTypes = {
        ...listItemProps,
        dispatch: func.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        // These rules are ignored because eslint doesn't detect props that come from a spread object
        // eslint-disable-next-line react/default-props-match-prop-types
        errorCode: "",
        // eslint-disable-next-line react/default-props-match-prop-types
        errorCodeMsg: "",
        // eslint-disable-next-line react/default-props-match-prop-types
        creditAmountCurrency: configUtils.get("core.masterCurrency"),
        // eslint-disable-next-line react/default-props-match-prop-types
        creditAmountQuantity: 0,
        // eslint-disable-next-line react/default-props-match-prop-types
        account: "0",
        // eslint-disable-next-line react/default-props-match-prop-types
        cbu: "",
        isDesktop: true,
    };

    state = {
        isEditingItem: false,
    };

    handleCloseModal = () => {
        this.setState(() => ({ isEditingItem: false }));
    };

    toggleEdit = () => {
        this.setState(({ isEditingItem }) => ({ isEditingItem: !isEditingItem }));
    };

    handleRemoveLine = () => {
        const { dispatch, lineNumber } = this.props;

        dispatch(transactionLinesActions.removeTransactionLine(lineNumber));
    };

    handleSubmit = (values) => {
        this.updateLine(values);

        this.handleCloseModal();
    };

    getCurrencyByAccount = (account) => {
        switch (account) {
            case ACCOUNT_TYPE.CA_ARS:
            case ACCOUNT_TYPE.CC_ARS:
                return CURRENCY_I18N_CODE.ARS;

            case ACCOUNT_TYPE.CA_USD:
            case ACCOUNT_TYPE.CC_USD:
                return CURRENCY_I18N_CODE.USD;
            default:
                return null;
        }
    };

    updateLine = (values) => {
        const { dispatch, lineNumber } = this.props;
        dispatch(transactionLinesActions.editTransactionLine({ lineNumber, ...values }));
    };

    renderModal(isEditingItem) {
        const {
            account,
            cbu,
            creditAmountCurrency,
            creditAmountQuantity,
            cuit,
            motivo,
            voucher,
            referenc,
        } = this.props;
        const initialValues = {
            account,
            cbu,
            credit: {
                currency: creditAmountCurrency,
                amount: creditAmountQuantity,
            },
            cuit,
            motivo,
            voucher,
            referenc,
        };
        const paymentsModal = (
            <PaymentLinesModal
                handleDismiss={this.handleCloseModal}
                hasDismissButton
                isModalDisplayed={isEditingItem}
                title={i18nUtils.get("salaryPayment.manual.editPayment.title")}>
                <PaymentLinesForm
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    submitButtonLabel="global.accept"
                    cancelButtonHandler={this.handleCloseModal}
                />
            </PaymentLinesModal>
        );

        return ReactDOM.createPortal(paymentsModal, document.querySelector("#modal"));
    }

    renderDesktop = () => {
        const {
            account,
            cbu,
            creditAmountQuantity,
            cuit,
            detailPreview,
            reference,
            voucher,
            referenc,
            status,
        } = this.props;
        const { isEditingItem } = this.state;

        let quantity = creditAmountQuantity;

        if (typeof creditAmountQuantity === "string" || creditAmountQuantity instanceof String) {
            quantity = parseFloat(creditAmountQuantity) / 100;
        }

        return (
            <>
                {this.renderModal(isEditingItem)}
                <Table.Row>
                    <Table.Data align="left" className="data-cbu">
                        <div className="data-text light-font">{cbu}</div>
                    </Table.Data>
                    <Table.Data align="center" className="data-cta">
                        {account === "01" && (
                            <div className="data-text light-font">
                                {i18nUtils.get("payments.thirdPayment.detail.account.caars")}
                            </div>
                        )}
                        {account === "02" && (
                            <div className="data-text light-font">
                                {i18nUtils.get("payments.thirdPayment.detail.account.causd")}
                            </div>
                        )}
                        {account === "03" && (
                            <div className="data-text light-font">
                                {i18nUtils.get("payments.thirdPayment.detail.account.ccars")}
                            </div>
                        )}
                        {account === "04" && (
                            <div className="data-text light-font">
                                {i18nUtils.get("payments.thirdPayment.detail.account.ccusd")}
                            </div>
                        )}
                    </Table.Data>

                    <Table.Data align="center" className="data-cuit">
                        <div className="data-text light-font">{cuit}</div>
                    </Table.Data>
                    <Table.Data align="right" className="data-cell-amount">
                        <div className="data-text light-font">
                            <FormattedAmount
                                quantity={quantity}
                                currency={this.getCurrencyByAccount(account)}
                                showAbbreviature
                            />
                        </div>
                    </Table.Data>
                    <Table.Data align="center" className="data-vaucher">
                        <div className="data-text light-font">{voucher}</div>
                    </Table.Data>
                    <Table.Data align="center" className="data-reason">
                        <div className="data-text light-font">{referenc}</div>
                    </Table.Data>
                    <Table.Data align="center" className="data-ref">
                        <div className="data-text light-font">{reference}</div>
                    </Table.Data>
                    {!detailPreview && (
                        <Table.Data align="center" className="data-state">
                            <div className="data-text light-font">
                                {i18nUtils.get(`payments.thirdPayment.detail.status.service.${status}`)}
                            </div>
                        </Table.Data>
                    )}
                    <Table.Data />
                </Table.Row>
            </>
        );
    };

    renderMobile = () => {
        const { account, cbu, creditAmountQuantity, status } = this.props;

        return (
            <Table.Row>
                <Table.Data>
                    <span className="data-text text-left">
                        {" "}
                        {i18nUtils.get(`payments.thirdPayment.detail.status.service.${status}`)}{" "}
                    </span>
                </Table.Data>
                <Table.Data align="left">
                    <span className="data-text text-left">{account}</span>
                    <span className="data-aux text-left">{cbu}</span>
                </Table.Data>
                <Table.Data align="right">
                    {creditAmountQuantity && <FormattedAmount quantity={creditAmountQuantity} currency="01" notBold />}
                </Table.Data>
            </Table.Row>
        );
    };

    render() {
        const { isDesktop } = this.props;

        if (!isDesktop) {
            return this.renderMobile();
        }
        return this.renderDesktop();
    }
}

const mapStateToProps = (state) => ({
    isEditingPayment: transactionLinesSelectors.isEditingPayment(state),
    shouldResetLines: transactionLinesSelectors.shouldResetLines(state),
});

export default connect(mapStateToProps)(TransactionLinesListItem);
