import React from "react";
import { number, func, bool } from "prop-types";
import Button from "../Button";

export default function Pagination({ totalPages, pageNumber, action, showFirstAndLastButton, showText }) {
    Pagination.propTypes = {
        totalPages: number.isRequired,
        pageNumber: number.isRequired,
        action: func.isRequired,
        showFirstAndLastButton: bool.isRequired,
        showText: bool.isRequired,
    };

    return (
        <div className="pagination__container">
            {showFirstAndLastButton && (
                <Button
                    image="images/pagination-first.svg"
                    disabled={pageNumber === 1}
                    onClick={() => action(1)}
                    fitWidth
                    className="my-0"
                    bsStyle="primary"
                />
            )}
            {pageNumber <= totalPages && (
                <Button
                    image="images/pagination-left.svg"
                    disabled={pageNumber === 1}
                    onClick={() => action(pageNumber - 1)}
                    fitWidth
                    className="my-0"
                    bsStyle="primary"
                />
            )}
            {showText && <div>{`${pageNumber} de ${totalPages}`}</div>}
            {pageNumber <= totalPages && (
                <Button
                    image="images/pagination-right.svg"
                    disabled={pageNumber === totalPages}
                    onClick={() => action(pageNumber + 1)}
                    fitWidth
                    className="my-0"
                    bsStyle="primary"
                />
            )}
            {showFirstAndLastButton && (
                <Button
                    image="images/pagination-last.svg"
                    disabled={pageNumber === totalPages}
                    onClick={() => action(totalPages)}
                    fitWidth
                    className="my-0"
                    bsStyle="primary"
                />
            )}
        </div>
    );
}
