import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { goBack } from "react-router-redux";
import { string, bool, shape } from "prop-types";

import { flattenArray, removeDuplicateItems } from "util/array";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { selectors as transferSelectors } from "reducers/transfer";
import { actions as holidaysActions, selectors as holidaysSelector } from "reducers/holidays";

import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import isTokenActive from "util/token";
import { updateSchedulerToSend } from "util/transaction";
import moment from "moment-timezone";
import { DAY_MONTH_YEAR } from "util/date";

class TransactionTicketSignConfirmation extends Component {
    propTypes = {
        isDesktop: bool.isRequired,
        dispatch: shape({}).isRequired,
        credentials: string.isRequired,
        fetching: bool.isRequired,
        signTransactionData: shape({}).isRequired,
        summary: shape({}).isRequired,
        isSuccessSign: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(holidaysActions.listHolidays());
    }

    componentDidUpdate({ isSuccessSign }) {
        const { dispatch, signTransactionData, summary, isSuccessSign: isSuccessSignNew } = this.props;
        if (isSuccessSignNew !== isSuccessSign && isSuccessSignNew === false) {
            const issetScheduler = summary?.scheduler != null && Object.keys(summary?.scheduler).length > 0;
            const isSameActivity = signTransactionData?.idActivity === summary?.idActivity;
            if (isSameActivity && summary?.transferKind && summary?.tadTrx && !issetScheduler) {
                dispatch(
                    formActions.updateScheduledTransactionRequest({
                        idTransaction: signTransactionData?.idTransaction,
                        schedulerData: {},
                    }),
                );
            }
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    centerContentMobile = () => {
        const { signTransactionData } = this.props;
        return (
            <div className="title-account-header-multiline-background-blue">
                <h1 style={{ margin: "0.2rem" }}>{i18n.get(signTransactionData?.title)}</h1>
            </div>
        );
    };

    render() {
        const { credentials, fetching, signTransactionData, isDesktop } = this.props;

        return (
            <>
                <Notification scopeToShow="signature" />
                <div className={isDesktop ? "admin-detail-head px-0" : ""}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop || !signTransactionData?.title ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && (
                        <div className="view-title">
                            {signTransactionData?.title !== undefined ? (
                                <h1>{i18n.get(signTransactionData?.title)}</h1>
                            ) : null}
                        </div>
                    )}
                </div>
                <MainContainer>
                    <Form className="confirmation__container">
                        {signTransactionData?.title !== undefined ? (
                            <Container
                                className={`align-items-center flex-grow px-0 container--layout with-margin mb-0 ${!isDesktop &&
                                    "mb-0 cmf-container-white"}`}
                                gridClassName="confirmation__form confirmation__box">
                                <div className="admin-content-center mb-3">
                                    <I18n id="sign.transaction" component="h2" />
                                </div>
                                <Col className="col-12" lg={12} md={12} sm={12}>
                                    {signTransactionData?.content}

                                    {!isTokenActive(!isDesktop) ? (
                                        <div className="admin-content-center">
                                            <I18n id="form.credential.hint" component="p" />
                                        </div>
                                    ) : (
                                        <div className="admin-content-center pb-3">
                                            <I18n
                                                id="confirmation.withoutotp.text"
                                                component="p"
                                                componentProps={{ className: "confirmation__text-without-otp" }}
                                            />
                                        </div>
                                    )}
                                    {credentials.map((credential) => (
                                        <div key={credential}>
                                            <Field
                                                idForm="form.credential"
                                                name={credential}
                                                component={Credential}
                                                type={credential}
                                                placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                            />
                                        </div>
                                    ))}
                                    <Container
                                        className="flex-grow align-items-center w-100"
                                        gridClassName="form-content">
                                        <Col className="col-12 confirmation__content-button" lg={12} md={12} sm={12}>
                                            <div className="admin-content-center">
                                                <Button
                                                    type="submit"
                                                    label="global.confirm"
                                                    bsStyle="primary"
                                                    loading={fetching}
                                                    image="images/arrowRight.svg"
                                                    imageStyle={isDesktop ? "mr-2" : "mr-1"}
                                                />
                                            </div>
                                        </Col>
                                    </Container>
                                </Col>
                            </Container>
                        ) : null}
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(formSelectors.getCredentialsGroups(state)),
    fetching: formSelectors.getFetching(state),
    signTransactionData: formSelectors.getSignTransactionData(state),
    user: sessionSelectors.getUser(state),
    potentialSignaturesData: transactionsSelectors.getPotentialSignaturesData(state),
    summary: transferSelectors.getSummary(state),
    isSuccessSign: formSelectors.getIsSuccessSign(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    holidays: holidaysSelector.getHolidays(state),
    serverDate: transactionsSelectors.getServerDate(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ credentials }) =>
            credentials.reduce((values, credential) => ({ ...values, [credential]: "" }), { otp: "" }),
        validationSchema: ({ credentials }) =>
            Yup.object().shape(
                credentials.reduce(
                    (values, credential) => ({
                        ...values,
                        [credential]: Yup.string().required(i18n.get(`form.credential.${credential}.required`)),
                    }),
                    {},
                ),
            ),
        handleSubmit: (credentials, formikBag) => {
            const {
                dispatch,
                signTransactionData,
                user,
                potentialSignaturesData,
                summary,
                isRetailEnvironment,
                activeEnvironment,
                holidays,
                serverDate,
            } = formikBag.props;

            const TDAenabledCorporate = configUtils.getBoolean("frontend.show.TAD.functionalities.Corporate", false);
            const TDAenabledRetail = configUtils.getBoolean("frontend.show.TAD.functionalities.Retail", false);
            const isSameActivity = signTransactionData?.idActivity === summary?.idActivity;
            const enabledRetail = isRetailEnvironment && TDAenabledRetail;
            const enabledCorporate = !isRetailEnvironment && TDAenabledCorporate;
            const isTransfer = ["transferInternal", "transferThirdParties"].includes(summary?.idForm);
            if (
                isSameActivity &&
                isTransfer &&
                summary?.tadTrx &&
                (enabledRetail || enabledCorporate) &&
                activeEnvironment?.permissions?.transferDiferredAccreditation
            ) {
                const isCompleteSignatures = potentialSignaturesData.some(({ typesOfSignatures }) => {
                    let isComplete = false;
                    let completeSignatures = 1; // frima actual
                    typesOfSignatures.forEach((typeOfSignature) => {
                        if (typeOfSignature.selected === true) {
                            completeSignatures += 1;
                        }
                    });
                    isComplete = typesOfSignatures.length === completeSignatures;
                    return isComplete;
                });

                if (isCompleteSignatures) {
                    let schedulerData;
                    schedulerData = updateSchedulerToSend();
                    const format = "hh:mm:ss";
                    const TDAhourFrom = configUtils.get("frontend.TAD.workingHours.hourFrom", "10:00:00");
                    const TDAhourTo = configUtils.get("frontend.TAD.workingHours.hourTo", "17:00:00");
                    const TDAnonWorkingDays = configUtils.getArrayInteger("frontend.TAD.nonWorkingDays", "5|6|7");
                    const beforeTime = moment.tz(TDAhourFrom, format, "America/Argentina/Buenos_Aires");
                    const afterTime = moment.tz(TDAhourTo, format, "America/Argentina/Buenos_Aires");
                    const dateNow = moment.tz(serverDate, "America/Argentina/Buenos_Aires");
                    const formattedDate = dateNow.format(DAY_MONTH_YEAR);

                    if (
                        dateNow.isBetween(beforeTime, afterTime) &&
                        TDAnonWorkingDays.indexOf(dateNow.isoWeekday()) === -1 &&
                        !holidays.includes(formattedDate)
                    ) {
                        schedulerData = {};
                    }
                    dispatch(
                        formActions.updateScheduledAndSignTransactionRequest({
                            schedulerData,
                            credentials,
                            formikBag,
                            idTransaction: signTransactionData?.idTransaction,
                            idForm: signTransactionData?.idForm,
                            idActivity: signTransactionData?.idActivity,
                            userId: user.userId,
                            pendingDispatch: signTransactionData?.pendingDispatch,
                            useTransactionData: signTransactionData?.useTransactionData,
                        }),
                    );
                } else {
                    dispatch(
                        formActions.signTransaction({
                            credentials,
                            formikBag,
                            idTransaction: signTransactionData?.idTransaction,
                            idForm: signTransactionData?.idForm,
                            idActivity: signTransactionData?.idActivity,
                            userId: user.userId,
                            pendingDispatch: signTransactionData?.pendingDispatch,
                            useTransactionData: signTransactionData?.useTransactionData,
                        }),
                    );
                }
            } else {
                dispatch(
                    formActions.signTransaction({
                        credentials,
                        formikBag,
                        idTransaction: signTransactionData?.idTransaction,
                        idForm: signTransactionData?.idForm,
                        idActivity: signTransactionData?.idActivity,
                        userId: user.userId,
                        pendingDispatch: signTransactionData?.pendingDispatch,
                        useTransactionData: signTransactionData?.useTransactionData,
                    }),
                );
            }
        },
    }),
)(TransactionTicketSignConfirmation);
