import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";

import { actions as tokenActions } from "reducers/token";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18n from "util/i18n";
import Credential from "pages/_components/fields/credentials/Credential";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import { func, bool } from "prop-types";

const FORM_ID = "settings.token.validate.codeRandom";

class TokenValidateRandomCode extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/token"));
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("settings.token.validate.codeRandom")}</h1>
        </div>
    );

    unlock = () => {
        const { dispatch } = this.props;

        dispatch(push("/settings/token/recoverPass"));
    };

    recoverTokenPass = () => {
        const { dispatch } = this.props;

        dispatch(
            notificationActions.showNotification(
                i18n.get("settings.token.recoverPassword.password.notification"),
                "warning",
                ["recoverTokenPass"],
            ),
        );
        dispatch(push("/settings/token/recoverPass"));
    };

    render() {
        const { isSubmitting } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="tokenValidateRandomCode" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName="blue-main-header-mobile"
                        centerElement={this.centerContentMobile}
                        emptyRightOption
                    />
                </div>
                <MainContainer>
                    <Form className="above-the-fold" noValidate="novalidate" style={{ marginTop: "-2.5rem" }}>
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                className="align-items-center mt-3"
                                style={{ paddingTop: "1rem" }}>
                                <div className="admin-content-center" style={{ paddingBottom: "1rem" }}>
                                    <h3>
                                        <I18n id="settings.token.validate.codeRandom.tittle" />
                                    </h3>
                                    <p className="mt-3 small">
                                        <I18n id="settings.token.validate.codeRandom.subtittle" />
                                    </p>
                                </div>
                                <Col sm={12} md={6} lg={6} xl={6} className="align-items-center">
                                    <Row className="mt-4 mb-8">
                                        <Col xs={12} className="admin-content-center px-0">
                                            <Field
                                                idForm={FORM_ID}
                                                name="codeRandom"
                                                component={Credential}
                                                type="tokenPass"
                                            />
                                        </Col>
                                        <Col xs={12} className="admin-content-center">
                                            <p className="my-0 small">
                                                <I18n id="settings.token.validate.codeRandom.textHelp" />
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="admin-content-center mt-4">
                                            <Button
                                                type="submit"
                                                bsStyle="primary"
                                                label="global.continue"
                                                loading={isSubmitting}
                                            />
                                        </div>
                                    </Row>
                                </Col>
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            codeRandom: "",
        }),

        validationSchema: () =>
            Yup.object().shape({
                codeRandom: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: ({ codeRandom }, formikBag) => {
            formikBag.props.dispatch(tokenActions.tokenSyncNewDevicePreviewRequest(codeRandom, formikBag));
        },
    }),
)(TokenValidateRandomCode);
