import React, { Children, Component } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";

import { replace, push } from "react-router-redux";
import { arrayOf, bool, element, func, shape, string } from "prop-types";
import classNames from "classnames";

import { selectors as i18nSelectors } from "reducers/i18n";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

class FooterDesktop extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool,
        location: shape({}).isRequired,
        moreOptions: arrayOf(element),
        messages: string,
    };

    static defaultProps = {
        moreOptions: [],
        isDesktop: false,
        messages: undefined,
    };

    goToClickedOption = (url) => {
        const { dispatch, location } = this.props;
        if (
            location.pathname === "/termsAndConditions" ||
            location.pathname === "/support" ||
            location.pathname === "/privacyPolicy"
        ) {
            dispatch(replace(url));
        } else {
            dispatch(push(url));
        }
    };

    render() {
        const { messages, moreOptions, isDesktop } = this.props;
        if (!messages) {
            return null;
        }

        return (
            <>
                <Container className="align-items-center container--layout app-footer app-footer-links">
                    <div className="col-12">
                        <ul className={classNames("legal-nav list-inline d-flex", { "flex-end": isDesktop })}>
                            <li>
                                <Button
                                    label="global.privacy"
                                    bsStyle="link"
                                    className="btn-asLink"
                                    onClick={() => {
                                        this.goToClickedOption("/privacyPolicy");
                                    }}
                                />
                            </li>
                            <li>
                                <Button
                                    label="global.termAndConditions"
                                    bsStyle="link"
                                    className="btn-asLink"
                                    onClick={() => {
                                        this.goToClickedOption("/termsAndConditions");
                                    }}
                                />
                            </li>
                            <li>
                                <Button
                                    label="global.support"
                                    bsStyle="link"
                                    className="btn-asLink"
                                    onClick={() => {
                                        this.goToClickedOption("/support");
                                    }}
                                />
                            </li>
                            {Children.map(moreOptions, (option) => (
                                <li>{option}</li>
                            ))}
                        </ul>
                    </div>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    messages: i18nSelectors.getMessages(state),
});

export default withRouter(connect(mapStateToProps)(FooterDesktop));
