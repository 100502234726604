/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { getAccountDescription } from "util/accounts";
import { idTransactionFormat } from "util/format";
import * as i18n from "util/i18n";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class ThirdPaymentDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;

        const typePaymentOptions = [
            {
                value: `PH`,
                label: i18n.get("payments.payThirdParties.newPayment.salaryPayments.label"),
            },
            {
                value: `PP`,
                label: i18n.get("payments.payThirdParties.newPayment.supplierPayments.label"),
            },
        ];

        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                data: {
                    typePayment,
                    dateProcess,
                    amount: { quantity, currency },
                    debitAccountData,
                },
            } = object.transaction;

            const accountName = debitAccountData?.number !== undefined && getAccountDescription(debitAccountData);

            return (
                <>
                    <CardContainer className="confirmation__cards-container mt-0">
                        <Card
                            className="confirmation__cards"
                            icon={isDesktop && "images/transfer-PENDING.svg"}
                            iconClass="card__pending-transaction-icon"
                            title={
                                isDesktop ? (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get("thirdPay.confirm.table.header.dateProcess")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <FormattedDate date={dateProcess} dateFormat="dd/MM/yyyy" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get("thirdPay.confirm.table.header.account")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>{accountName}</span>
                                        </div>
                                    </div>
                                )
                            }
                            content={
                                isDesktop ? (
                                    <div className="d-flex align-items-center">
                                        <div className="confirmation-cards__content pb-1">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("thirdPay.confirm.table.header.account")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{accountName}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("thirdPay.confirm.table.header.typePayment")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {
                                                        typePaymentOptions.find(({ value }) => value === typePayment)
                                                            .label
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("thirdPay.confirm.table.header.dateProcess")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <FormattedDate date={dateProcess} dateFormat="dd/MM/yyyy" />
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("thirdPay.confirm.table.header.typePayment")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {
                                                        typePaymentOptions.find(({ value }) => value === typePayment)
                                                            .label
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("forms.transaction.ticket.number")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            bottom={
                                isDesktop && (
                                    <div className="confirmation-cards__bottom">
                                        <div className="confirmation-cards__bottom-data">
                                            <span>{i18n.get("forms.transaction.ticket.number")}</span>
                                        </div>
                                        <div className="confirmation-cards__bottom-info">
                                            <span>{idTransactionFormat(idTransaction)}</span>
                                        </div>
                                    </div>
                                )
                            }
                            rightContent={
                                <div className="confirmation-cards__right">
                                    <div className="confirmation-cards__right-data">
                                        <FormattedAmount currency={currency} quantity={quantity} />
                                    </div>
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        });
    };

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(ThirdPaymentDataTable);
