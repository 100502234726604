import * as API from "middleware/api";

const weather = (longitude, latitude, timeout) =>
    API.executeAnonymousWithTimeout(
        "/weather.get",
        {
            longitude,
            latitude,
        },
        timeout,
    );

export default weather;
