import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import { routerActions } from "react-router-redux/actions";
import Redirect from "react-router-dom/Redirect";
import { func, arrayOf, oneOf, shape, string } from "prop-types";

import { actions, selectors } from "reducers/administration/users";
import { actions as formActions } from "reducers/form";

import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Notification from "pages/_components/Notification";

class UserActionConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        usersToApplyAction: arrayOf(string).isRequired,
        usersNamesToApplyAction: arrayOf(string).isRequired,
        userAction: oneOf(["block", "unblock", "delete"]).isRequired,
        idTransaction: string,
        idActivity: string,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    handleSubmit = (credentials, formikBag) => {
        const {
            dispatch,
            usersToApplyAction,
            usersNamesToApplyAction,
            userAction,
            idTransaction,
            idActivity,
        } = this.props;

        if (!idTransaction) {
            dispatch(
                actions.changeUserStatusConfirmation(
                    usersToApplyAction,
                    usersNamesToApplyAction,
                    userAction,
                    credentials,
                    formikBag,
                ),
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const { usersToApplyAction, usersNamesToApplyAction, userAction, credentialGroups } = this.props;

        if (!usersToApplyAction || usersToApplyAction.length === 0) {
            return <Redirect to="/administration/users" />;
        }

        const usersText = usersNamesToApplyAction.map((userName) => (
            <span className="chip f-size-4" key={userName}>
                {" "}
                {userName}{" "}
            </span>
        ));
        const titleKey = `administration.users.action.title.${userAction}`;

        return (
            <>
                <Notification scopeToShow="signature" />
                <Fragment>
                    <div className="admin-detail-head admin-detail-head-section px-0">
                        <Head onBack={this.handleBack} />
                        <div className="mb-4">
                            <I18n component="h1" componentProps={{ className: "view-title" }} id={titleKey} />
                        </div>
                    </div>
                    <MainContainer viewContentClassName="confirmation__container pt-0">
                        <Container
                            className="align-items-center flex-grow px-0 container--layout with-margin mb-0"
                            gridClassName="confirmation__form confirmation__box">
                            <Container
                                className="align-items-center flex-grow admin-action"
                                gridClassName="form-content">
                                <Col className="col-12" lg={12} md={12} sm={12}>
                                    <div className="transfer-block mb-3">
                                        <I18n
                                            component="h2"
                                            componentProps={{ className: "form-title text-center mt-4" }}
                                            id={`administration.users.action.confirmation.${userAction}`}
                                        />
                                    </div>
                                    <div className="transfer-block mb-2 pb-2">
                                        <div className="inline-grid">{usersText}</div>
                                    </div>
                                </Col>
                            </Container>
                            <AdministrationFormConfirmation
                                classNameCredentialsText="mb-3"
                                credentialGroups={credentialGroups}
                                onSubmit={this.handleSubmit}
                                MDcolumnWidth={12}
                                LGcolumnWidth={12}
                                buttonLabel="global.confirm"
                                buttonImage="images/arrowRight.svg"
                                buttonImageClass="mr-2"
                                formContainerClassName="confirmation__container"
                            />
                        </Container>
                    </MainContainer>
                </Fragment>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userAction: selectors.getUserAction(state),
    usersToApplyAction: selectors.getUsersToApplyAction(state),
    usersNamesToApplyAction: selectors.getUsersNamesToApplyAction(state),
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(UserActionConfirmation);
