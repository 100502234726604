/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form, withFormik, Field } from "formik";
import { func, shape, bool, string } from "prop-types";
import Button from "pages/_components/Button";
import * as i18nUtils from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import Selector from "pages/_components/fields/formik/Selector";
import CommonFrequencySubOption from "pages/forms/_components/_scheduler/CommonFrequencySubOption";
import CustomFrequencySubOption from "pages/forms/_components/_scheduler/CustomFrequencySubOption";
import moment from "moment";
import I18n from "pages/_components/I18n";
import { Modal } from "react-bootstrap";
import ModalNotification from "pages/forms/_components/ModalNotification";
import Image from "pages/_components/Image";

const STARTS_ON_DATE = moment(new Date()).add(1, "days");

class SchedulerModal extends Component {
    static propTypes = {
        goBack: func,
        onScheduleClick: func,
        setTouched: func,
        setErrors: func,
        handleCloseBottomSheetClick: func,
        touched: shape({}),
        errors: shape({}),
        values: shape({}),
        isDisplayed: bool,
        isDesktop: bool,
        idForm: string.isRequired,
    };

    static defaultProps = {
        goBack: () => {},
        onScheduleClick: () => {},
        setTouched: () => {},
        setErrors: () => {},
        handleCloseBottomSheetClick: () => {},
        touched: {},
        errors: {},
        values: {},
        isDisplayed: false,
        isDesktop: false,
    };

    goBack = () => {
        const { goBack } = this.props;
        goBack();
    };

    validateFields = () => {
        const { setErrors, errors, touched, setTouched, values } = this.props;
        let res = true;
        // clean errors
        setErrors({});

        const moreThan2daysaWeek = dateUtils.moreThan2daysSelected(values.days);

        // validate if it's week and days is just one day and freqValue = 1
        if (values.customFreq === schedulerUtils.WEEK) {
            if (moreThan2daysaWeek && values.customFreqValue !== "1") {
                setTouched({ ...touched, days: true });
                setErrors({
                    ...errors,
                    days: { value: i18nUtils.get(`scheduler.multipleDaysSelectedValidation`) },
                });
                res = false;
            }
        }
        // starts on must be after than today
        if (moment(values.startsOn).isBefore(moment())) {
            setTouched({ ...touched, startsOn: true });
            setErrors({
                ...errors,
                startsOn: i18nUtils.get(`scheduler.startsOnBeforeToday`),
            });
            res = false;
        }

        return res;
    };

    onScheduleClick = () => {
        const { onScheduleClick, values } = this.props;

        if (this.validateFields()) {
            onScheduleClick(values);
        }
    };

    render() {
        const {
            isDisplayed,
            handleCloseBottomSheetClick,
            values: { lapse, frequency, customFreq },
            errors,
            idForm,
            isDesktop,
        } = this.props;

        return (
            <>
                {isDesktop ? (
                    <Modal
                        className="scheduler__modal"
                        dialogClassName="scheduler__modal"
                        aria-labelledby="modalTitleID"
                        aria-modal="true"
                        onHide={handleCloseBottomSheetClick}
                        show={isDisplayed}>
                        <div className="modal-container modal-container-scheduler">
                            <>
                                <Modal.Header closeButton className="mt-0" />
                                <Modal.Title componentClass="div" id="modalTitleID" className="mt-4">
                                    <Image src="images/calendar-circle.svg" />
                                    <I18n
                                        id="scheduler.title"
                                        component="h3"
                                        componentProps={{ className: "scheduler__text-title" }}
                                    />
                                </Modal.Title>
                            </>
                            <Modal.Body className="my-0 w-100">
                                <Form className="w-100">
                                    <MainContainer>
                                        <div>
                                            <Container>
                                                <ModalNotification idForm={idForm} errors={errors} />
                                                <div className="w-100 scheduler__modal-selector">
                                                    <Field
                                                        idForm={idForm}
                                                        component={Selector}
                                                        name="frequency"
                                                        options={schedulerUtils.listRecurrencies()}
                                                    />
                                                </div>
                                            </Container>

                                            <Container>
                                                {frequency !== schedulerUtils.CUSTOM ? (
                                                    <CommonFrequencySubOption
                                                        lapse={lapse}
                                                        frequency={frequency}
                                                        startsOn={STARTS_ON_DATE}
                                                        isDesktop={isDesktop}
                                                    />
                                                ) : (
                                                    <CustomFrequencySubOption
                                                        lapse={lapse}
                                                        frequency={frequency}
                                                        startsOn={STARTS_ON_DATE}
                                                        customFreq={customFreq}
                                                        isDesktop={isDesktop}
                                                    />
                                                )}
                                            </Container>
                                            <div className="d-flex justify-content-center">
                                                <Button
                                                    type="button"
                                                    bsStyle="primary"
                                                    label="scheduler.schedule"
                                                    onClick={this.onScheduleClick}
                                                />
                                            </div>
                                        </div>
                                    </MainContainer>
                                </Form>
                            </Modal.Body>
                        </div>
                    </Modal>
                ) : (
                    <div className="modal-container modal-container-scheduler">
                        <Modal
                            show={isDisplayed}
                            className="scheduler__modal"
                            onHide={handleCloseBottomSheetClick}
                            dialogClassName="scheduler__modal">
                            <Modal.Header className="mt-0 pt-4">
                                <Modal.Title componentClass="div" id="modalTitleID" className="mt-3 px-4">
                                    <div className="close-without-btn">
                                        <Image src="images/rectangle-dropdown.svg" />
                                    </div>
                                    <I18n
                                        id="scheduler.title"
                                        component="h3"
                                        componentProps={{ className: "scheduler__text-title my-0" }}
                                    />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="my-0 w-100">
                                <Form className="w-100">
                                    <MainContainer>
                                        <div>
                                            <Container>
                                                <ModalNotification idForm={idForm} errors={errors} />
                                                <div className="w-100 scheduler__modal-selector">
                                                    <Field
                                                        idForm={idForm}
                                                        component={Selector}
                                                        name="frequency"
                                                        options={schedulerUtils.listRecurrencies()}
                                                    />
                                                </div>
                                            </Container>

                                            <Container>
                                                {frequency !== schedulerUtils.CUSTOM ? (
                                                    <CommonFrequencySubOption
                                                        lapse={lapse}
                                                        frequency={frequency}
                                                        startsOn={STARTS_ON_DATE}
                                                        isDesktop={isDesktop}
                                                    />
                                                ) : (
                                                    <CustomFrequencySubOption
                                                        lapse={lapse}
                                                        frequency={frequency}
                                                        startsOn={STARTS_ON_DATE}
                                                        customFreq={customFreq}
                                                        isDesktop={isDesktop}
                                                    />
                                                )}
                                            </Container>
                                            <div className="d-flex justify-content-center">
                                                <Button
                                                    type="button"
                                                    bsStyle="primary"
                                                    label="scheduler.schedule"
                                                    onClick={this.onScheduleClick}
                                                />
                                            </div>
                                        </div>
                                    </MainContainer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = () => ({});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { value } = props;
            const hasDate = value?.valueDate;
            const newProgram = {
                frequency: value?.selectedOption ? value.selectedOption : schedulerUtils.TODAY,
                startsOn:
                    value && value.selectedOption === schedulerUtils.ONCE && hasDate
                        ? moment(value.valueDate).toDate()
                        : STARTS_ON_DATE.startOf("day").toDate(),
                lapse: {
                    date: STARTS_ON_DATE.startOf("day").toDate(),
                    lapse: schedulerUtils.NEVER,
                },
                customFreq: schedulerUtils.DAY,
                customFreqValue: "1",
                days: dateUtils.getDayFromDate(STARTS_ON_DATE.startOf("day").toDate()),
                occurrenceType: "day", // day(every month on x day ) or occurrence(every month on the "tirth" "tuesday")
            };

            if (!value || !value.program) {
                return newProgram;
            }

            const startsOn =
                typeof value.program.startsOn === "string"
                    ? moment(value.program.startsOn).toDate()
                    : value.program.startsOn;
            return {
                ...value.program,
                startsOn,
                frequency: value.program.isCustom ? schedulerUtils.CUSTOM : value.program.frequency,
                lapse: {
                    lapse: value.program.lapse,
                    date: value.program.date,
                    number: value.program.number,
                },
                customFreq: value.program.frequency,
                customFreqValue: value.program.frequencyValue,
                occurrenceType: value.program.occurrence ? "occurrence" : "day",
            };
        },
    }),
)(SchedulerModal);
