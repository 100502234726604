import React, { Component } from "react";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { compose } from "redux";
import { Grid, Row, Col } from "react-bootstrap";
import { replace } from "react-router-redux";
import PropTypes, { bool } from "prop-types";

import * as i18n from "util/i18n";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Credential from "pages/_components/fields/credentials/Credential";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { goBack } from "react-router-redux/actions";
import isTokenActive from "util/token";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";

const FORM_ID = "settings.changeSecuritySeal";
class ChangeSecuritySealConfirmation extends Component {
    static propTypes = {
        selectedSecuritySeal: PropTypes.shape({
            id: PropTypes.string.isRequired,
            securitySeal: PropTypes.string.isRequired,
        }).isRequired,
        dispatch: PropTypes.func.isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
    };

    componentDidMount() {
        const { selectedSecuritySeal, dispatch } = this.props;
        if (!selectedSecuritySeal || !selectedSecuritySeal.id) {
            dispatch(replace("/settings/"));
        }
    }

    back = (event) => {
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(settingsActions.changeSecuritySealConfirmationBack());
    };

    confirmSecuritySealForm = () => {
        const { selectedSecuritySeal, isDesktop, fetching } = this.props;

        return (
            <Form className="confirmation__container">
                <Container
                    className={`align-items-center flex-grow px-0 ${
                        isDesktop ? "container--layout with-margin" : "pb-0"
                    }`}
                    gridClassName="confirmation__form confirmation__box">
                    <Container
                        className={`align-items-center flex-grow container-white ${isDesktop ? "" : "pb-0"}`}
                        gridClassName="form-content">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={12} xl={12} className="col col-12 align-items-center">
                                    <div className="admin-content-center">
                                        <I18n id="settings.changeSecuritySeal.confirmation.title" component="h2" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={12} md={10} lg={12} xl={12}>
                                    <div className="admin-content-center">
                                        <I18n
                                            id="settings.changeSecuritySeal.confirmation.contact"
                                            component="p"
                                            componentProps={{ className: "settings-text" }}
                                        />
                                        <div className="admin-content-center pt-3">
                                            <div className="image">
                                                <figure>
                                                    <img
                                                        src={selectedSecuritySeal.securitySeal}
                                                        alt={selectedSecuritySeal.id}
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                        <Button block bsStyle="link" onClick={this.back}>
                                            <I18n
                                                id="settings.changeSecuritySeal.confirmation.back"
                                                component="p"
                                                componentProps={{ className: "settings-text" }}
                                            />
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center pt-3">
                                <Col sm={12} md={12} lg={12}>
                                    {!isTokenActive(!isDesktop) ? (
                                        <div className="admin-content-center">
                                            <I18n
                                                id="settings.changeSecuritySeal.verifyWithCredential"
                                                component="p"
                                                componentProps={{ className: "settings-text" }}
                                            />
                                        </div>
                                    ) : (
                                        <div className="admin-content-center pb-3">
                                            <I18n
                                                id="confirmation.withoutotp.text"
                                                component="p"
                                                componentProps={{ className: "confirmation__text-without-otp" }}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={12} md={12} lg={12}>
                                    <Field
                                        placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                        name="otp"
                                        type="otp"
                                        idForm={FORM_ID}
                                        component={Credential}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                        <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                            <Col sm={12} md={12} lg={12} className="px-0">
                                <Col className="confirmation__content-button" sm={12} md={12} lg={12}>
                                    <div className="admin-content-center">
                                        <Button
                                            type="submit"
                                            label="global.confirm"
                                            block
                                            bsStyle="primary"
                                            image="images/arrowRight.svg"
                                            imageStyle="mr-2"
                                            onClick={this.send}
                                            loading={fetching}
                                            className="text-capitalize"
                                        />
                                    </div>
                                </Col>
                            </Col>
                        </Container>
                    </Container>
                </Container>
            </Form>
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    render() {
        const { isDesktop } = this.props;
        return (
            <React.Fragment>
                <Notification scopeToShow={FORM_ID} />
                <div className="admin-detail-head px-0">
                    <Head
                        title={!isDesktop && "settings.changeSecuritySeal"}
                        titleClassName={!isDesktop && "title-account-header-multiline-background-blue"}
                        centerContentClassName={!isDesktop && "mx-5"}
                        onBack={this.handleBack}
                        headerClassName={!isDesktop && "blue-main-header-mobile"}
                    />
                    {isDesktop && (
                        <div className="view-title">
                            <h1>{i18n.get("settings.changeSecuritySeal")}</h1>
                        </div>
                    )}
                </div>
                <MainContainer>{this.confirmSecuritySealForm()}</MainContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    errors: settingsSelectors.getErrors(state),
    selectedSecuritySeal: {
        id: settingsSelectors.getSelectedSecuritySealId(state),
        securitySeal: settingsSelectors.getSelectedSecuritySeal(state),
    },
    fetching: settingsSelectors.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            otp: "",
            selectedSecuritySeal: props.selectedSecuritySeal,
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: ({ otp, selectedSecuritySeal }, formikBag) => {
            formikBag.props.dispatch(
                settingsActions.changeSecuritySealConfirmation(selectedSecuritySeal.id, otp, formikBag),
            );
        },
    }),
)(ChangeSecuritySealConfirmation);
