import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bool, oneOfType, arrayOf, node } from "prop-types";
import { selectors } from "reducers/config";

const FeatureFlag = ({ isEnabled, children }) => <Fragment>{isEnabled && children}</Fragment>;

FeatureFlag.propTypes = {
    isEnabled: bool.isRequired,
    children: oneOfType([arrayOf(node), node]).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    isEnabled: selectors.getConfigKey(state, ownProps.id) || false,
});

export default connect(mapStateToProps)(FeatureFlag);
