import React, { Component } from "react";
import { bool, arrayOf, shape, string, number, func } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";

import { routerActions, push } from "react-router-redux";
import {
    selectors as transactionLinesSelectors,
    actions as transactionLinesActions,
} from "reducers/form/transactionLines";

import { actions as thirdPaymentActions, selectors as thirdPaymentSelectors } from "reducers/thirdPayment";

import { selectors as accountsSelectors, actions as accountsActions } from "reducers/accounts";

import PaymentLines from "pages/forms/PaymentLines";

import * as i18nUtils from "util/i18n";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import * as accountUtils from "util/accounts";
import * as thirdPaymentUtils from "util/thirdPayment";

import PageLoading from "pages/_components/PageLoading";

import { actions as formActions } from "reducers/form";
import InfoTag from "pages/_components/InfoTag";
import DownloadButton from "pages/_components/DownloadButton";
import Head from "../_components/Head";

import ThirdPaymentSummaryDetail from "./_components/ThirdPaymentSummaryDetail";
import ThirdPaymentCancelData from "./_components/ThirdPaymentCancelData";

class ThirdPaymentDetail extends Component {
    static propTypes = {
        transactionLines: arrayOf(shape({})),
        isConfirmation: bool,
        totalLines: number.isRequired,
        fetchingThirdPayment: bool.isRequired,
        fetchingTransactionLines: bool.isRequired,
        fetchingAccount: bool.isRequired,
        fetchingDownload: bool.isRequired,
        shouldLoadForm: bool,
        dispatch: func.isRequired,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
        }).isRequired,
        transaction: shape({}),
        isTicket: string,
        match: shape({
            params: shape({}),
        }).isRequired,
        state: shape({}).isRequired,
        location: shape({}).isRequired,

        accounts: shape({}).isRequired,
        activeFilters: shape({}).isRequired,
    };

    static defaultProps = {
        transactionLines: [],
        isConfirmation: false,
        shouldLoadForm: false,
        isTicket: true,
        transaction: null,
    };

    componentDidMount() {
        const { dispatch, match, loggedUser, activeFilters, location } = this.props;
        dispatch(thirdPaymentActions.getTransaction(match.params.idTransaction, true));
        dispatch(transactionLinesActions.clearTransactionLines());
        const fileId = location?.state?.tr?.data?.file[0]?.fileId;
        const reference = location?.state?.tr?.data?.reference;

        let data = {
            id: match.params.idTransaction,
            pageNumber: 1,
            fileId,
            reference,
        };
        if (activeFilters) {
            data = {
                ...data,
                ...activeFilters,
            };
        }

        dispatch(transactionLinesActions.listTransactionLinesRequest(data));
        dispatch(accountsActions.listAccounts(loggedUser.userId));
    }

    componentWillUnmount() {
        const { dispatch, match } = this.props;
        dispatch(thirdPaymentActions.clearData());
        dispatch(transactionLinesActions.clearTransactionLines());
        dispatch(transactionLinesActions.setPageNumber(0));
        dispatch(thirdPaymentActions.setPageToGoBack(`/payThirdParties/${match.params.idTransaction}/detail`));
    }

    linesToData = (lines, currency) => {
        const id = Date.now();
        return lines.reduce(
            (data, { account, cbu, cuit, creditAmountCurrency, creditAmountQuantity, voucher, motivo, referenc }) =>
                `${data}\n${account},${cbu},${cuit},${creditAmountCurrency},${creditAmountQuantity},
                ${voucher},${motivo},${referenc}`,
            `${id},${currency}`,
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push("/payThirdParties/list"));
    };

    getTitle = () => {
        const { match, transaction, location } = this.props;
        const transactionStatusName = location?.state?.tr?.transactionStatusName;
        const name = `${i18nUtils.get("payments.thirdPayment.detail.title ")} ${match.params.idTransaction
            .substring(0, 8)
            .toUpperCase()}`;
        let fileName = "";
        const idTransactionStatus = location?.state?.tr?.idTransactionStatus;

        if (transaction !== null) {
            fileName = `${transaction.file[0].fileName}`;
        }

        return (
            <>
                <div className="view-title data-wrapper-flex">
                    <h1 className="my-0 mr-3">{name}</h1>
                    {transaction !== null && (
                        <InfoTag
                            type="info"
                            message={
                                transactionStatusName || thirdPaymentUtils.getCodeMsg(transaction.idTransactionStatus)
                            }
                            tagBackground={thirdPaymentUtils.statusTagColor(
                                transactionStatusName || idTransactionStatus,
                            )}
                            moreStyles={{
                                color: "black",
                            }}
                        />
                    )}
                </div>
                <div className="view-title data-wrapper-flex">
                    <span className="data-label">{i18nUtils.get("payments.thirdPayment.detail.fileName")}:</span>
                    <span className="pl-2 data-desc">{fileName}</span>
                </div>
            </>
        );
    };

    cancelTransaction = () => {
        const { transaction, dispatch, accounts, match, location } = this.props;
        let accountName = null;
        const numberAccount = transaction.debitAccount;
        if (accounts.length > 0) {
            const loggedUserAccount = accounts.find((account) => numberAccount.includes(account?.idProduct));
            accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
        }
        const trOrder = location?.state?.tr?.data.processedFileData?.validLines;

        const cancelTransactionData = (
            <ThirdPaymentCancelData
                transactionOrder={trOrder}
                code={thirdPaymentUtils.getCodeMsg(transaction.idTransactionStatus)}
                summary={{
                    data: transaction,
                }}
                accountName={accountName}
            />
        );

        dispatch(push("/transactions/cancel"));
        dispatch(
            formActions.cancelTransactionData({
                idTransaction: match.params.idTransaction,
                content: cancelTransactionData,
                title: "activities.thirdpay.table.menu.decline",
            }),
        );
        dispatch(formActions.cancelTransactionPre({ idActivity: "pay.multiline.send", idForm: "" }));
        dispatch(thirdPaymentActions.loadConfirmThirdPayment({ ...transaction, action: "cancel" }));
    };

    rightContent = () => (
        <Button
            onClick={this.cancelTransaction}
            label="thirdPayment.cancel.button"
            className="btn-outline cmf-button-width"
        />
    );

    renderPageHeader = () => {
        const { transaction, fetchingDownload } = this.props;
        return (
            <>
                {transaction !== null && transaction?.idTransactionStatus === "PENDING" && (
                    <>
                        <Notification scopeToShow="form" />
                        <div className="admin-detail-head px-0">
                            <Head onBack={this.handleBack} />
                            <Head
                                headerClassName="mb-2"
                                rightContent={this.rightContent}
                                isFetchingExport={false}
                                centerElement={this.getTitle}
                            />
                            {this.renderSubtitle()}
                        </div>
                    </>
                )}
                {transaction !== null && transaction?.idTransactionStatus !== "PENDING" && (
                    <>
                        <Notification scopeToShow="form" />
                        <div className="admin-detail-head px-0">
                            <Head onBack={this.handleBack} />
                            <Head
                                headerClassName="mb-2"
                                centerElement={this.getTitle}
                                centerContentClassName="d-flex flex-column align-items-start"
                                rightContent={() => (
                                    <DownloadButton
                                        fetching={fetchingDownload}
                                        handleClick={this.handleDownloadClick}
                                        handleClickMessage="global.download"
                                        csvDownload
                                        xlsDownload
                                        imageStyle="mr-2"
                                    />
                                )}
                            />
                            {this.renderSubtitle()}
                        </div>
                    </>
                )}
            </>
        );
    };

    renderSubtitle = () => {
        const { transaction, accounts, location } = this.props;
        const trOrder = location?.state?.tr?.data.processedFileData?.validLines;
        return (
            <>
                {transaction !== null && (
                    <ThirdPaymentSummaryDetail
                        transactionOrder={trOrder}
                        transaction={transaction}
                        accounts={accounts}
                    />
                )}
            </>
        );
    };

    handleDownloadClick = (format) => {
        const { transactionLines, transaction, accounts, dispatch, match, location } = this.props;
        const trOrder = location?.state?.tr?.data.processedFileData?.validLines;

        const { typePayment, file, reference, dateProcess, amount } = transaction;
        const { idTransactionStatus, transactionStatusName } = location?.state?.tr;

        let accountName = null;

        const numberAccount = transaction?.debitAccount;
        if (numberAccount && accounts.length > 0) {
            const loggedUserAccount = accounts.find((account) => numberAccount.includes(account?.idProduct));
            accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
        }

        const translatedTransactionLines = transactionLines.map((transactionLine) =>
            this.translateStatusTransactionLine(transactionLine),
        );

        dispatch(
            thirdPaymentActions.downloadFileTransaction(
                format,
                translatedTransactionLines,
                typePayment,
                file[0].fileName,
                match.params.idTransaction,
                reference,
                dateProcess,
                trOrder,
                amount?.currency,
                idTransactionStatus,
                accountName,
                transactionStatusName,
            ),
        );
    };

    translateStatusTransactionLine = (transactionLine) => ({
        ...transactionLine,
        status: thirdPaymentUtils.statusByState.find(({ value }) => value === transactionLine.status).label,
    });

    renderContent() {
        const { transactionLines, fetchingTransactionLines } = this.props;
        return (
            <>
                <div className="mb-2">{this.renderPageHeader()}</div>
                <PaymentLines
                    totalLines={transactionLines.length}
                    isFetching={fetchingTransactionLines}
                    isTicket
                    {...this.props}
                />
            </>
        );
    }

    render() {
        const { fetchingThirdPayment, fetchingTransactionLines, fetchingAccount } = this.props;

        return (
            <PageLoading loading={fetchingThirdPayment || fetchingTransactionLines || fetchingAccount}>
                {this.renderContent()}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    transaction: thirdPaymentSelectors.getTransaction(state),
    transactionLines: transactionLinesSelectors.getTransactionLines(state),
    accounts: accountsSelectors.getAccounts(state),
    isEditingPayment: transactionLinesSelectors.isEditingPayment(state),
    loggedUser: sessionSelectors.getUser(state),
    fetchingThirdPayment: thirdPaymentSelectors.getFetching(state),
    fetchingAccount: accountsSelectors.getFetching(state),
    fetchingTransactionLines: transactionLinesSelectors.isFetching(state),
    fetchingDownload: thirdPaymentSelectors.getFetchingDownload(state),
    activeFilters: transactionLinesSelectors.getActiveFilters(state),
});

export default connect(mapStateToProps)(ThirdPaymentDetail);
