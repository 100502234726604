import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import { element, func, string, bool } from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";

const FrequentDestinationMobileRow = ({
    name,
    recipientName,
    recipientDocumentType,
    recipientDocumentNumber,
    cbu,
    recipientEmail,
    idFrequentDestination,
    isOwn,
    contextMenu,
    onRowClick,
    fromModal,
}) => {
    FrequentDestinationMobileRow.propTypes = {
        name: string.isRequired,
        recipientName: string.isRequired,
        recipientDocumentType: string.isRequired,
        recipientDocumentNumber: string.isRequired,
        cbu: string.isRequired,
        recipientEmail: string.isRequired,
        idFrequentDestination: string.isRequired,
        isOwn: bool.isRequired,
        contextMenu: element.isRequired,
        onRowClick: func,
        fromModal: bool,
    };

    FrequentDestinationMobileRow.defaultProps = {
        onRowClick: null,
        fromModal: false,
    };

    const content = (
        <Container className="align-items-center flex-grow container-white py-2 my-2 container-details">
            <Col className="col col-12 d-flex space-between mb-2">
                <span className="f-size-6 font-light">{name}</span>
            </Col>
            <Col className="col col-12">
                <Heading.DataGroup
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    label="transfer.frequentDestinations.column.headline"
                    labelClassName="mb-0"
                    data={<span>{`${recipientName}`}</span>}
                />
            </Col>
            <Col className="col col-12">
                <Heading.DataGroup
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                    label="transfer.frequentDestinations.column.documentType"
                    data={
                        <span className="text-uppercase">{`${recipientDocumentType} ${recipientDocumentNumber}`}</span>
                    }
                />
            </Col>
            <Col className="col col-12">
                <Heading.DataGroup
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    label="transfer.frequentDestinations.column.acount"
                    labelClassName="mb-0"
                    data={<span>{`${cbu}`}</span>}
                />
            </Col>
            <Col className="col col-12">
                <Heading.DataGroup
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    label="transfer.frequentDestinations.column.email"
                    labelClassName="mb-0"
                    data={<span>{`${recipientEmail}`}</span>}
                />
            </Col>
        </Container>
    );

    return (
        <div className="p-relative">
            {!fromModal && <div className="context-menu-container container px-2">{contextMenu}</div>}
            <Col
                onClick={() => {
                    onRowClick({
                        name,
                        recipientName,
                        recipientDocumentType,
                        recipientDocumentNumber,
                        account: cbu,
                        email: recipientEmail,
                        idFrequentDestination,
                        isOwn,
                    });
                }}>
                {content}
            </Col>
        </div>
    );
};

export default FrequentDestinationMobileRow;
