import React, { Component } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";
import { func, bool, shape } from "prop-types";

import { actions as recoveryPasswordActions, selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import { actions as loginActions } from "reducers/login";
import MainContainer from "pages/_components/MainContainer";
import Step3Content from "pages/recoveryPassword/_components/Step3Content";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import withExchangeToken from "pages/_components/withExchangeToken";
import I18n from "pages/_components/I18n";
import Logo from "pages/login/_components/Logo";

import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";

class RecoveryPassStep3 extends Component {
    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    renderTitleMobile = () => <Logo className="svg-image logo logo-mobile-size" logoColor />;

    render() {
        const { isDesktop, propsGrid } = this.props;

        return (
            <GridLayoutExternal scopeToShow="recoveryPassword" isDesktop={isDesktop} {...propsGrid}>
                <div className={`admin-detail-head px-0 ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                    <Head
                        onClose={this.onHeaderClose}
                        title={isDesktop && "recoveryPassword.step3.header"}
                        headerClassName={
                            !isDesktop
                                ? "login-background-color-white"
                                : "recover-pass-desktop recover-pass-desktop-token"
                        }
                        centerElement={!isDesktop && this.renderTitleMobile}
                        hideMobileMenu={!isDesktop && true}
                        fixed
                    />
                </div>
                <MainContainer className={classNames("login__layout main-container", { 
                    "container-fluid": isDesktop,
                })}>
                    {!isDesktop && (
                         <Container className="container--layout flex-grow" >
                            <Col className="col col-12 view-title mt-3">
                                <div className="justify-content-center">
                                    <I18n
                                        id="recoveryPassword.step3.header"
                                        componentProps={{ className: "f-size-5 font-regular" }}
                                        component="h2"
                                    />
                                </div>
                            </Col>
                        </Container>
                    )}
                    <Step3Content {...this.props} />
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}

RecoveryPassStep3.propTypes = {
    dispatch: func.isRequired,
    propsGrid: shape({}).isRequired,
    isDesktop: bool,
};

RecoveryPassStep3.defaultProps = {
    isDesktop: true,
};

const mapStateToProps = (state) => ({
    exchangeToken: recoveryPasswordSelectors.getExchangeToken(state),
    resetCode: recoveryPasswordSelectors.getResetCode(state),
});

export default connect(mapStateToProps)(withExchangeToken(RecoveryPassStep3));
