/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { idTransactionFormat } from "util/format";
import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class AdminRestrictionManageDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                submitDateTimeAsString,
                data: { idUser, ipsList, anyIP, disableIP, userName },
            } = object.transaction;

            const type = idUser ? (
                <I18n id="administration.restrictions.restrictions.user" />
            ) : (
                <I18n id="administration.restrictions.restrictions.environment" />
            );

            return (
                <CardContainer className="confirmation__cards-container">
                    <Card
                        className="confirmation__cards"
                        icon={isDesktop && "images/transfer-PENDING.svg"}
                        iconClass="card__pending-transaction-icon"
                        hrClass="d-none"
                        title={
                            isDesktop ? (
                                submitDateTimeAsString && (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <I18n id="forms.transaction.ticket.date" />
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>{submitDateTimeAsString}</span>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div>
                                    {type && (
                                        <div className="confirmation-cards__title">
                                            <div className="confirmation-cards__title-data">
                                                {i18nUtils.get("administration.restrictions.restrictions.type")}
                                            </div>
                                            <div className="confirmation-cards__title-info">
                                                <span>{type}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        content={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    {type && (
                                        <div
                                            className={
                                                this.ipValue(ipsList, anyIP, disableIP) || (type && userName)
                                                    ? "confirmation-cards__content pb-1"
                                                    : "confirmation-cards__content"
                                            }>
                                            <div className="confirmation-cards__content-data">
                                                {i18nUtils.get("administration.restrictions.restrictions.type")}
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{type}</span>
                                            </div>
                                        </div>
                                    )}
                                    {this.ipValue(ipsList, anyIP, disableIP) && (
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-data">
                                                {i18nUtils.get("administration.restrictions.IP")}
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    {this.ipValue(ipsList, anyIP, disableIP)}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {type && userName && (
                                        <div className="confirmation-cards__content info-type-b">
                                            <div className="confirmation-cards__content-data">
                                                {i18nUtils.get("administration.restrictions.name")}
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">{userName}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {submitDateTimeAsString && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="forms.transaction.ticket.date" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{submitDateTimeAsString}</span>
                                            </div>
                                        </div>
                                    )}
                                    {this.ipValue(ipsList, anyIP, disableIP) && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                {i18nUtils.get("administration.restrictions.IP")}
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    {this.ipValue(ipsList, anyIP, disableIP)}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {type && userName && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                {i18nUtils.get("administration.restrictions.name")}
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{userName}</span>
                                            </div>
                                        </div>
                                    )}
                                    {idTransactionFormat(idTransaction) && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                {i18nUtils.get("forms.transaction.ticket.number")}
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        bottom={
                            isDesktop &&
                            idTransactionFormat(idTransaction) && (
                                <div className="confirmation-cards__bottom">
                                    <div className="confirmation-cards__bottom-data">
                                        <I18n id="forms.transaction.ticket.number" />
                                    </div>
                                    <div className="confirmation-cards__bottom-info">
                                        <span>{idTransactionFormat(idTransaction)}</span>
                                    </div>
                                </div>
                            )
                        }
                    />
                </CardContainer>
            );
        });
    };

    ipValue = (ipsList, anyIP, disableIP) => {
        if ((ipsList && ipsList.length > 0) || anyIP) {
            return anyIP ? i18nUtils.get("administration.restrictions.anyIP.label") : ipsList.join(", ");
        }
        return disableIP
            ? i18nUtils.get("administration.restrictions.disableIP.label")
            : i18nUtils.get("administration.restrictions.enableIP.label");
    };

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(AdminRestrictionManageDataTable);
