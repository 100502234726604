import React, { Component } from "react";
import { string, func, shape } from "prop-types";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";

import { selectors as sessionSelectors } from "reducers/session";

import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";

import moment from "moment";
import { Col } from "react-bootstrap";
import { getAccountDescription } from "util/accounts";
import { isFinalState } from "util/transaction";

class PayPaymentAFIPTransactionData extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        summary: shape({
            debitAccount: string.isRequired,
            paymentAFIP: shape({}).isRequired,
        }).isRequired,
        fullName: string.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    preparedBy = () => {
        const { summary, fullName } = this.props;
        return (
            <p>
                {summary?.creatorFullName || fullName} -{" "}
                <FormattedDate date={summary?.creationDate || new Date()} dateFormat="dd/MM/yyyy" showTime />
            </p>
        );
    };

    signBy = () => {
        const { summary } = this.props;
        return (
            <p>
                {summary?.signatures.map((object) => (
                    <>
                        {object?.userFirstName.concat(" ", object?.userLastName)} -{" "}
                        <FormattedDate date={object?.creationDateTime} dateFormat="dd/MM/yyyy" showTime />
                        <br />
                    </>
                ))}
            </p>
        );
    };

    render() {
        const { summary } = this.props;
        const { debitAccount, paymentAFIP, idTransaction, transactionData } = summary;

        const {
            fiscalPeriod,
            dueDate,
            amount,
            conceptDesc,
            subConceptDesc,
            description,
            formNumber,
            advancePayment,
            taxpayerCUIT,
            contributorsCUIT,
        } = paymentAFIP;

        return (
            <Col xs={12} className="px-0">
                {isFinalState(transactionData?.idTransactionStatus) && (
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="pay.paymentAFIP.openDate"
                        data={<FormattedDate date={transactionData?.submitDateTime} showTime />}
                    />
                )}

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="pay.paymentAFIP.idTransaction"
                    data={idTransaction && idTransaction.substring(0, 8).toUpperCase()}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="pay.paymentAFIP.numberVEP"
                    data={paymentAFIP?.numberVEP && Number(paymentAFIP?.numberVEP)}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="pay.paymentAFIP.fiscalPeriod"
                    data={fiscalPeriod}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="pay.paymentAFIP.dueDate"
                    data={<FormattedDate date={moment(dueDate, "YYYY-MM-DD")} />}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="pay.paymentAFIP.amount"
                    data={<FormattedAmount currency={amount?.currency} quantity={amount?.quantity} notBold />}
                />

                {debitAccount && (
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="pay.paymentAFIP.debitAccount"
                        data={debitAccount?.number !== undefined && getAccountDescription(debitAccount)}
                    />
                )}

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="pay.paymentAFIP.taxpayerCUIT"
                    data={contributorsCUIT || taxpayerCUIT}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="pay.paymentAFIP.concept"
                    data={conceptDesc}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="pay.paymentAFIP.subConcept"
                    data={subConceptDesc}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="pay.paymentAFIP.description"
                    data={description}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="pay.paymentAFIP.formNumber"
                    data={formNumber}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="pay.paymentAFIP.advancePayment"
                    data={advancePayment}
                />
            </Col>
        );
    }
}
const mapStateToProps = (state) => ({
    fullName: sessionSelectors.getUser(state).userFullName,
});

export default compose(connect(mapStateToProps))(PayPaymentAFIPTransactionData);
