import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { string } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { selectors as enrollmentSelectors } from "reducers/enrollment";

import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";

import * as i18nUtils from "util/i18n";

class RequestInvitationCodeSuccess extends Component {
    static propTypes = {
        channel: string.isRequired,
    };

    render() {
        const { channel } = this.props;

        return (
            <Fragment>
                <Head title="enrollment.requestInvitationCode.header" />
                <MainContainer className="main-container">
                    <div className="above-the-fold">
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col className="col col-12">
                                <div className="list-icon-container text-center">
                                    <div className="list-item--has-icon">
                                        <h2>
                                            <span className="bullet-icon">
                                                <Image src="images/check.svg" />
                                            </span>
                                            <I18n id="enrollment.requestInvitationCode.success" />
                                        </h2>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <Container className="align-items-center container--layout">
                            <Col className="col col-12">
                                <Link to="/enrollment" className="btn btn-block btn-primary">
                                    <I18n id="global.continue" />
                                </Link>
                                <p className="text-center">
                                    <I18n
                                        id="enrollment.requestInvitationCode.success.info"
                                        CHANNEL_SENT={i18nUtils.get(
                                            `enrollment.requestInvitationCode.success.${channel}`,
                                        )}
                                    />
                                </p>
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    channel: enrollmentSelectors.getChannelSent(state),
});

export default connect(mapStateToProps)(RequestInvitationCodeSuccess);
