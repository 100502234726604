const headerNameMap = {
    refOperation: "numeroOperacion",
    originDate: "fechaOrigen",
    dueDate: "fechaVencimiento",
    beneficiaryName: "nombreBeneficiario",
    currency: "siglaDivisa",
    amount: "importe",
    balance: "saldo",
};

export const getNumberFromFormattedText = (formattedNumber) => {
    if (formattedNumber) {
        return Number(formattedNumber.replace(",", "").replace(".", ""));
    }
    return null;
};

export const getDateFromFormattedText = (dateString) => {
    const dateParts = dateString.split("/");

    const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject;
};

const dynamicSort = (property, direction) => {
    let result = 0;
    const ascendentValue = direction === "ascendent" ? 1 : -1;
    return (a, b) => {
        if (property === "originDate" || property === "dueDate") {
            result =
                ascendentValue *
                (getDateFromFormattedText(a[headerNameMap[property]]) -
                    getDateFromFormattedText(b[headerNameMap[property]]));
        }
        if (property === "beneficiaryName" || property === "currency") {
            result = ascendentValue * a[headerNameMap[property]].localeCompare(b[headerNameMap[property]]);
        }

        if (property === "amount" || property === "balance") {
            result =
                ascendentValue *
                (getNumberFromFormattedText(a[headerNameMap[property]]) -
                    getNumberFromFormattedText(b[headerNameMap[property]]));
        }

        if (property === "refOperation") {
            let operationA = a.siglaOperacion;
            operationA += a.sucursalOperacion;
            operationA += a.numeroOperacion;

            let operationB = b.siglaOperacion;
            operationB += b.sucursalOperacion;
            operationB += b.numeroOperacion;

            result = ascendentValue * operationA.localeCompare(operationB);
        }

        return result;
    };
};

export default dynamicSort;
