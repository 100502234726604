import React from "react";
import Heading from "pages/_components/Heading";
import { arrayOf, shape } from "prop-types";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";

export default function MovementDetail({ arrayInfo }) {
    MovementDetail.propTypes = {
        arrayInfo: arrayOf(shape({})).isRequired,
    };

    return (
        <>
            <Container className="container--layout flex-grow align-items-center">
                <Col className="col-12" style={{ backgroundColor: "#f9f9f9", marginTop: "1rem", padding: "1rem" }}>
                    {arrayInfo.map((item) => (
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex flex-start"
                            label={item.label}
                            data={item.data}
                        />
                    ))}
                </Col>
            </Container>
        </>
    );
}
