import React, { Component, Children } from "react";
import { string, node, func } from "prop-types";
import classNames from "classnames";

import NavItem from "pages/_components/nav/NavItem";

class Nav extends Component {
    static Item = NavItem;

    static propTypes = {
        children: node.isRequired,
        className: string,
        onSelect: func,
        variant: string,
    };

    static defaultProps = {
        className: "",
        onSelect: null,
        variant: "tabs",
    };

    render() {
        const { variant, className, children, onSelect } = this.props;

        return (
            <ul className={classNames(`nav nav-${variant}`, className)}>
                {Children.map(children, (child, index) => {
                    if (child.type !== NavItem) {
                        return child;
                    }

                    return React.cloneElement(child, {
                        onClick: () => {
                            if (onSelect) {
                                onSelect(index);
                            }
                        },
                    });
                })}
            </ul>
        );
    }
}

export default Nav;
