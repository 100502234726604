import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { func, bool } from "prop-types";
import { compose } from "redux";
import { Col } from "react-bootstrap";

import { actions as loginActions } from "reducers/login";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";

class StepCIP extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
    };

    handleButtonClick = () => () => {
        const { dispatch } = this.props;
        dispatch(push("/onboarding/step1"));
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    render() {
        const { isMobile } = this.props;

        return (
            <Fragment>
                <Head title="onboarding.stepCIP.title" onBack={isMobile && this.onHeaderBack} />
                <MainContainer>
                    <Container className="container--layout flex-grow align-items-center" gridClassName="form-content">
                        <Col className="col col-12">
                            <I18n component="h3" id="onboarding.stepCIP.importantInfo" />{" "}
                        </Col>
                        <Col className="col col-12">
                            <I18n component="p" id="onboarding.stepCIP.info1" />{" "}
                        </Col>
                        <Col className="col col-12">
                            <I18n component="p" id="onboarding.stepCIP.info2" />{" "}
                        </Col>
                        <Col className="col col-12">
                            <I18n component="p" id="onboarding.stepCIP.info3" />{" "}
                        </Col>
                        <Col className="col col-12">
                            <I18n component="span" id="onboarding.stepCIP.info4" />{" "}
                        </Col>
                    </Container>
                    <Container className="container--layout align-items-center">
                        <Col className="col col-12">
                            <Button
                                bsStyle="primary"
                                label="global.continue"
                                type="button"
                                onClick={this.handleButtonClick()}
                            />
                        </Col>
                    </Container>
                </MainContainer>
            </Fragment>
        );
    }
}

export default compose(connect(null))(StepCIP);
