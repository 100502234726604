/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import moment from "moment";
import { arrayOf, bool, instanceOf, objectOf, string, shape } from "prop-types";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import DatePicker from "pages/_components/fields/datepicker";
import Select from "pages/forms/_components/_fields/Select";
import Col from "react-bootstrap/lib/Col";

import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import * as i18nUtils from "util/i18n";
import Container from "pages/_components/Container";

class LapseSubOption extends Component {
    static propTypes = {
        enabledWeekDays: arrayOf(bool).isRequired,
        errors: objectOf(string),
        firstWorkingDate: string.isRequired,
        nonWorkingDays: arrayOf(string).isRequired,
        value: instanceOf(moment).isRequired,
        frequency: string.isRequired,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        errors: null,
        isDesktop: null,
    };

    excludeDates = () => {
        const { nonWorkingDays } = this.props;

        const excludeDates = [];
        nonWorkingDays.forEach((entry) => {
            excludeDates.push(moment(entry));
        });

        return excludeDates;
    };

    filterDate = (date) => {
        const { enabledWeekDays } = this.props;

        return enabledWeekDays[(date.isoWeekday() % 7) + 1];
    };

    getNeverOption = () => (
        <div className="c-control c-control--radio c-control--has-icon">{this.getOption(schedulerUtils.NEVER)}</div>
    );

    getUntilOption = () => {
        const {
            errors,
            firstWorkingDate,
            value: { date, lapse },
            form,
            isDesktop,
        } = this.props;

        return (
            <Container
                className={classNames("c-control c-control--radio c-control-block c-control--has-icon", {
                    "has-error": !isEmpty(errors) && lapse === schedulerUtils.UNTIL,
                })}>
                <Col xs={12} md={3} className="mt-3 pl-0">
                    {this.getOption(schedulerUtils.UNTIL)}
                </Col>
                <Col xs={12} md={9} className={classNames("input-group", { "mt-3": !isDesktop })}>
                    <div className="form-group form-group--datepicker form-group-row">
                        <label htmlFor="untilValue.date" className="visually-hidden" aria-hidden>
                            {i18nUtils.get("scheduler.until.a11y")}
                        </label>
                        <DatePicker
                            tabIndex={lapse === schedulerUtils.UNTIL ? "0" : "-1"}
                            className="form-control"
                            dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18nUtils.getLang())}
                            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 2)}
                            minDate={form?.values?.startsOn || moment(firstWorkingDate)}
                            name="untilValue"
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={(newDate) => this.handleChange(schedulerUtils.UNTIL, newDate)}
                            selected={moment(date || moment().format("YYYY-MM-DD"))}
                            autoComplete="off"
                            id="untilValue.date"
                            showYearDropdown
                            showMonthDropdown
                        />
                    </div>
                </Col>
            </Container>
        );
    };

    getTimesOption = () => {
        const {
            errors,
            value: { lapse, number },
        } = this.props;
        const options = Array.from({ length: 42 }, (_, index) => ({ id: index + 1, label: `${index + 1}` }));

        return (
            <div
                className={classNames("c-control c-control--radio c-control-block c-control--has-icon", {
                    "has-error": !isEmpty(errors) && lapse === schedulerUtils.UP_TO,
                })}>
                {this.getOption(schedulerUtils.UP_TO)}
                <div className="form-group form-group--select form-group-row">
                    <div className="form-text-group">
                        <label id="label.occurrencies" className="control-label" aria-hidden>
                            <I18n id="scheduler.after" />
                        </label>
                    </div>
                    <div className="input-group">
                        <Select
                            tabIndex={lapse === schedulerUtils.UP_TO ? "0" : "-1"}
                            className="slideFromBottom transfer-ocurrencies min-width-5"
                            clearable={false}
                            labelKey="label"
                            onChange={(option) => this.handleChange(schedulerUtils.UP_TO, option)}
                            optionClassName="needsclick"
                            options={options}
                            searchable={false}
                            value={number || 1}
                            valueKey="id"
                            id="occurrencies"
                        />
                    </div>
                </div>
            </div>
        );
    };

    getOption = (id) => {
        const { field } = this.props;

        return (
            <>
                <input
                    tabIndex={field.value.lapse === id ? "0" : "-1"}
                    type="radio"
                    id={id.toLowerCase()}
                    name="lapseOptions"
                    value={id}
                    className="c-control-input"
                    checked={field.value.lapse === id}
                    onChange={() => this.handleChange(id)}
                />
                <label className="c-control-label" htmlFor={id.toLowerCase()}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="images/check.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>
                    {id === schedulerUtils.NEVER && <I18n id="scheduler.never" />}
                    {id === schedulerUtils.UNTIL && (
                        <>
                            <I18n id="scheduler.on" />
                            <label className="visually-hidden" htmlFor="scheduler.on">
                                {i18nUtils.get("scheduler.until.a11y")}
                            </label>
                        </>
                    )}
                    {id === schedulerUtils.UP_TO && (
                        <label className="visually-hidden">{i18nUtils.get("scheduler.up_to.a11y")}</label>
                    )}
                </label>
            </>
        );
    };

    handleChange = (id, data) => {
        const { value, form, field } = this.props;
        let newValue = null;
        if (id === schedulerUtils.NEVER) {
            newValue = { ...value, lapse: id };
        } else if (id === schedulerUtils.UNTIL) {
            newValue = { ...value, date: data || form?.values?.startsOn, lapse: id };
        } else if (id === schedulerUtils.UP_TO) {
            newValue = { ...value, lapse: id, number: data ? data.id : 1 };
        }
        form.setFieldValue(field.name, newValue);
        form.setTouched({ ...form.touched, [field.name]: true });
    };

    render() {
        const { frequency } = this.props;
        return (
            <fieldset id="endRulesFieldsetID">
                <legend>
                    <I18n id="scheduler.ends" component="b" />
                </legend>
                <div className="form-group">
                    <div className="form-check-group">
                        {this.getNeverOption()}
                        {frequency !== schedulerUtils.YEAR && this.getUntilOption()}
                        {this.getTimesOption()}
                    </div>
                </div>
            </fieldset>
        );
    }
}

export default LapseSubOption;
