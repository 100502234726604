import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { string, shape, func, bool } from "prop-types";

import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";

class AgendaPaymentInfo extends Component {
    static propTypes = {
        FORM_ID: string.isRequired,
        agendaPago: shape({}).isRequired,
        handleBack: func.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        isDesktop: false,
    };

    componentDidMount() {
        const { agendaPago, handleBack } = this.props;
        if (!agendaPago) {
            handleBack();
        }
    }

    render() {
        const { FORM_ID, agendaPago, isDesktop } = this.props;
        return (
            <Container
                className="px-3 my-0 flex-grow container-white pt-25 pb-3 pb-md-25 mt-2 mt-md-0 mb-2"
                rowClassName="align-items-center justify-content-center">
                <Col
                    className={`d-flex payments-taxes-width px-0 px-md-3 px-xl-0 ${!isDesktop ? "flex-column" : ""} `}
                    sm={12}
                    md={9}>
                    <Col md={6} xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName={`d-flex align-items-start f-size-55 line-height-1 mb-25 my-md-2 ${
                                isDesktop ? "flex-column" : "space-between"
                            }`}
                            label={`${FORM_ID}.rubros.label`}
                            labelClassName="f-size-55"
                            data={agendaPago?.entity?.category?.description}
                            dataClassName={`font-light f-size-55 ${isDesktop ? "px-0 mt-2" : " pl-2 text-right"}`}
                        />
                    </Col>
                    <Col md={3} xs={12} className={`px-0 px-md-2 ${isDesktop && "justify-content-center flex"}`}>
                        <Heading.DataGroup
                            containerClassName={`d-flex align-items-center f-size-55 line-height-1 mt-0 mb-25 my-md-2 ${
                                isDesktop ? "flex-column" : "space-between align-items-start"
                            }`}
                            label={`${FORM_ID}.entities.label`}
                            labelClassName="f-size-55 text-left w-50"
                            data={agendaPago?.entity?.description}
                            dataClassName={`font-light f-size-55 ${isDesktop ? "px-0 mt-2" : " pl-2 text-right"}`}
                        />
                    </Col>
                    <Col md={3} xs={12} className={`px-0 ${isDesktop && "justify-content-end f-size-55 flex"}`}>
                        <Heading.DataGroup
                            containerClassName={`d-flex align-items-end f-size-55 line-height-1 mt-0 
                            mb-0 mb-md-25 my-md-2 ${isDesktop ? "flex-column" : "space-between"}`}
                            label={`${FORM_ID}.cpe.label`}
                            labelClassName="f-size-55"
                            data={agendaPago?.ususerLP}
                            dataClassName={`font-light f-size-55 ${isDesktop ? "px-0 mt-2" : " pl-2 text-right"}`}
                        />
                    </Col>
                </Col>
            </Container>
        );
    }
}

export default AgendaPaymentInfo;
