import React, { Component } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { func, arrayOf, shape, string } from "prop-types";

import { actions, selectors } from "reducers/administration/restrictions";
import { actions as formActions } from "reducers/form";
import { selectors as sessionSelectors } from "reducers/session";

import * as dateUtils from "util/date";
import I18n from "pages/_components/I18n";
import { Col } from "react-bootstrap";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Container from "pages/_components/Container";
import * as i18nUtils from "util/i18n";
import Heading from "pages/_components/Heading";

function renderCalendarAndIPSection(onlyEnvironment, dataToSave) {
    return (
        <div className="mt-6 mb-65">
            {/* ip Detail */}

            {(dataToSave.iPEnabled && dataToSave.ipsList && dataToSave.ipsList.length > 0) || dataToSave.anyIP ? (
                <Heading.DataGroup
                    containerClassName="-flex justify-content-center"
                    data={
                        <div>
                            {!dataToSave.anyIP ? (
                                <I18n componentProps={{ className: "mr-2" }} id="administration.restrictions.IP" />
                            ) : (
                                <I18n
                                    componentProps={{ className: "mr-2" }}
                                    id="administration.restrictions.enableIP.label"
                                />
                            )}
                            <span className="font-light white-space-wrap">
                                {!dataToSave.anyIP && dataToSave.ipsList.join("\n")}
                            </span>
                        </div>
                    }
                />
            ) : (
                <>
                    {!dataToSave.iPEnabled && (
                        <I18n
                            component="h4"
                            componentProps={{ className: "admin-content-center mb-3" }}
                            id="administration.restrictions.disableIP.label"
                        />
                    )}
                    {dataToSave.iPEnabled && (
                        <I18n
                            component="h4"
                            componentProps={{ className: "admin-content-center mb-3" }}
                            id="administration.restrictions.enableIP.label"
                        />
                    )}
                </>
            )}
        </div>
    );
}

class RestrictionsManageConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        dataToSave: shape({}).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        idTransaction: string,
        idActivity: string,
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        loggedUser: shape({
            userId: string,
        }).isRequired,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    constructor(props) {
        super(props);
        this.renderCalendarAndIPSection = renderCalendarAndIPSection.bind(this); // add this
    }

    handleSubmit = (credentials, formikBag) => {
        const { otp } = credentials;
        if (otp.length !== 0) {
            const { dispatch, dataToSave, idTransaction, idActivity } = this.props;
            if (!idTransaction) {
                dispatch(actions.manageRestrictions(dataToSave, credentials, formikBag));
            } else {
                const paramsSign = { idForm: null, idActivity, idTransaction };
                dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
            }
        } else {
            formikBag.setErrors({ otp: i18nUtils.get("form.credential.otp.required") });
            formikBag.setSubmitting(false);
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    renderDays = (bitWiseNumber) => (
        <div>
            {dateUtils
                .getDaysNamesWithBitwiseNumber(bitWiseNumber)
                .map((i) => i)
                .join(", ")}
        </div>
    );

    renderDetail() {
        const { dataToSave } = this.props;
        const onlyEnvironment = typeof dataToSave.idUser === "undefined";
        return (
            <>
                <div className="admin-content-center">
                    <Col className="col col-6-xl">
                        <I18n
                            id={
                                onlyEnvironment
                                    ? "administration.restrictions.restrictions.subtitle.enviroment"
                                    : "administration.restrictions.restrictions.subtitle.user"
                            }
                        />
                    </Col>
                    <Col className="col col-6-xl">{this.renderCalendarAndIPSection(onlyEnvironment, dataToSave)}</Col>
                </div>
            </>
        );
    }

    render() {
        const {
            credentialGroups,
            dataToSave,
            loggedUser: { userId },
            activeEnvironment: { administrationScheme },
        } = this.props;

        const onlyEnvironment = typeof dataToSave.idUser === "undefined";
        if (!credentialGroups) {
            return null;
        }

        return (
            <>
                <div className="admin-detail-head admin-detail-head-section rigth-position-desktop px-0">
                    <Head
                        onBack={this.handleBack}
                        closeLinkTo={`/administration/${administrationScheme}/details/${userId}`}
                    />
                    <div className="mb-4">
                        <h1 className="view-title">
                            {onlyEnvironment
                                ? `${i18nUtils.get("administration.restrictions.environmentRestrictions.title")} ${
                                      dataToSave.nameEnviroment
                                  }`
                                : `${i18nUtils.get("administration.restrictions.userRestrictions.title")} ${
                                      dataToSave.userName
                                  }`}
                        </h1>
                    </div>
                </div>

                <MainContainer viewContentClassName="pt-0">
                    <div className="confirmation__container">
                        <Container
                            className="align-items-center flex-grow px-0
                                container--layout with-margin mb-0"
                            gridClassName="confirmation__form confirmation__box">
                            <Col className="col-12 d-flex justify-content-center" sm={12}>
                                <h1 className="mt-45 mb-3">
                                    {onlyEnvironment
                                        ? `${i18nUtils.get("administration.restrictions.restrictions.beforeSave")}`
                                        : `${i18nUtils.get("administration.restrictions.restrictions.beforeModify")}`}
                                </h1>
                            </Col>

                            <Col className="col-12" sm={12}>
                                {this.renderDetail()}
                            </Col>
                            <AdministrationFormConfirmation
                                credentialGroups={credentialGroups}
                                onSubmit={this.handleSubmit}
                                MDcolumnWidth={12}
                                LGcolumnWidth={12}
                                buttonLabel="global.confirm"
                                buttonImage="images/arrowRight.svg"
                                buttonImageClass="mr-2"
                                formContainerClassName="confirmation__container"
                            />
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    credentialGroups: selectors.getCredentialGroups(state),
    dataToSave: selectors.getDataToSave(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
    loggedUser: sessionSelectors.getUser(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(RestrictionsManageConfirmation);
