import React from "react";
import { Field } from "formik";
import { func, arrayOf, shape, number, string, bool } from "prop-types";
import Col from "react-bootstrap/lib/Col";

import * as i18nUtils from "util/i18n";
import Table from "pages/_components/Table";
import AmountField from "pages/_components/fields/formik/AmountField";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";

import Heading from "pages/_components/Heading";

function AgendaConceptWithBaseDebt({ agendaPago, currencies, isDesktop, FORM_ID }) {
    AgendaConceptWithBaseDebt.propTypes = {
        agendaPago: func.isRequired,
        currencies: arrayOf(shape({ id: number, label: string })),
        isDesktop: bool,
        FORM_ID: string.isRequired,
    };

    AgendaConceptWithBaseDebt.defaultProps = {
        currencies: [{ id: 0, label: "ARS" }],
        isDesktop: false,
    };

    const { expiration } = agendaPago;

    return isDesktop ? (
        <Table.Row className="container-white">
            {expiration.concept.managesIncomeAmounts ? (
                <>
                    <Col className="d-flex justify-content-start align-items-center pl-0" sm={12} md={6}>
                        <Field
                            amountClassName="text-align-right"
                            component={AmountField}
                            data={{ options: currencies }}
                            name={`conceptAmount${expiration.debtId}`}
                            clearable
                            maxLength={15}
                            disableSelect
                            hideLabel
                            fixedDecimalScale
                            plainValue
                        />
                        {expiration.concept.managesRange && (
                            <div style={{ display: "flex", color: "rgb(0, 113, 206)" }}>
                                <span style={{ paddingRight: "5px" }}>
                                    {i18nUtils.get("accounts.movements.filters.minAmount.label").toUpperCase()}
                                </span>
                                <FormattedAmount
                                    className="data-amount"
                                    currency={currencies[0].id.toString()}
                                    quantity={expiration.minAmount}
                                />
                                <span style={{ padding: "0 5px" }}> | </span>
                                <span style={{ paddingRight: "5px" }}>
                                    {i18nUtils.get("accounts.movements.filters.maxAmount.label").toUpperCase()}
                                </span>
                                <FormattedAmount
                                    className="data-amount"
                                    currency={currencies[0].id.toString()}
                                    quantity={expiration.maxAmount}
                                />
                            </div>
                        )}
                    </Col>
                    <Col className="d-flex justify-content-center" sm={6} md={3}>
                        <FormattedDate
                            className="font-light f-size-55"
                            date={expiration.date}
                            dateFormat="dd/MM/yyyy"
                        />
                    </Col>
                    <Col className="d-flex justify-content-end px-0" sm={6} md={3}>
                        <span className="font-light f-size-55">{expiration.concept.description}</span>
                    </Col>
                </>
            ) : (
                <>
                    <Col className="d-flex justify-content-start align-items-center pl-0" sm={12} md={6}>
                        <FormattedAmount
                            cleanClassName
                            fontClassName="font-light f-size-55"
                            currency={currencies[0].id.toString()}
                            quantity={expiration.amount}
                        />
                    </Col>
                    <Col className="d-flex justify-content-center" sm={6} md={3}>
                        <FormattedDate
                            className="font-light f-size-55"
                            date={expiration.date}
                            dateFormat="dd/MM/yyyy"
                        />
                    </Col>
                    <Col className="d-flex justify-content-end px-0" sm={6} md={3}>
                        <span className="font-light f-size-55">{expiration.concept.description}</span>
                    </Col>
                </>
            )}
        </Table.Row>
    ) : (
        // ACA ARRANCA MOBILE
        <>
            <div className="d-flex payments-taxes-width px-0 pt-2 flex-column">
                <Heading.DataGroup
                    containerClassName="d-flex align-items-start f-size-55 line-height-1 my-md-2 space-between"
                    label={`${FORM_ID}.amountToPay`}
                    labelClassName="f-size-55"
                    data={expiration.amount}
                    dataClassName="font-light f-size-55  pl-3 text-right"
                />
                <Heading.DataGroup
                    dataClassName="data-desc"
                    containerClassName="transfer-data data-wrapper-flex my-2 space-between"
                    label={`${FORM_ID}.expiration`}
                    labelClassName="f-size-55"
                    data={
                        <FormattedDate
                            className="data-name font-light f-size-55  pl-3 text-right"
                            date={expiration.date}
                            dateFormat="dd/MM/yyyy"
                        />
                    }
                />
                <Heading.DataGroup
                    containerClassName="d-flex align-items-start f-size-55 line-height-1 mb-25 my-md-2 space-between"
                    label={`${FORM_ID}.concept`}
                    labelClassName="f-size-55"
                    data={expiration.concept.description}
                    dataClassName="font-light f-size-55  pl-3 text-right"
                />
            </div>
            <Table.Row className="container-white container-white-mobile flex-table agenda-concept f-dir-col">
                {expiration.concept.managesIncomeAmounts ? (
                    <>
                        <Table.Body className="container-white container-white-mobile amount-field-mobile">
                            <Table.Row className="container-white container-white-mobile agenda-concept">
                                <Table.Data align="center">
                                    <Field
                                        amountClassName="text-align-right"
                                        component={AmountField}
                                        data={{ options: currencies }}
                                        name={`conceptAmount${expiration.debtId}`}
                                        clearable
                                        maxLength={15}
                                        disableSelect
                                        hideLabel
                                        fixedDecimalScale
                                        plainValue
                                    />

                                    {expiration.concept.managesRange && (
                                        <div style={{ display: "flex", color: "rgb(0, 113, 206)" }}>
                                            <span style={{ paddingRight: "5px" }}>
                                                {i18nUtils
                                                    .get("accounts.movements.filters.minAmount.label")
                                                    .toUpperCase()}
                                            </span>
                                            <FormattedAmount
                                                className="data-amount"
                                                currency={currencies[0].id.toString()}
                                                quantity={expiration.minAmount}
                                            />
                                            <span style={{ padding: "0 5px" }}> | </span>
                                            <span style={{ paddingRight: "5px" }}>
                                                {i18nUtils
                                                    .get("accounts.movements.filters.maxAmount.label")
                                                    .toUpperCase()}
                                            </span>
                                            <FormattedAmount
                                                className="data-amount"
                                                currency={currencies[0].id.toString()}
                                                quantity={expiration.maxAmount}
                                            />
                                        </div>
                                    )}
                                </Table.Data>
                            </Table.Row>
                        </Table.Body>

                        <Table.Row
                            className="container-white container-white-mobile flex-table"
                            style={{ flexDirection: "row" }}>
                            <Table.Header className="container-white">
                                <Table.HeaderData align="right" className="small-font">
                                    <I18n id={`${FORM_ID}.expiration`} />
                                </Table.HeaderData>
                                <Table.HeaderData align="right" className="small-font">
                                    <I18n id={`${FORM_ID}.concept`} />
                                </Table.HeaderData>
                            </Table.Header>

                            <Table.Body className="container-white container-white-mobile">
                                <Table.Row className="container-white container-white-mobile agenda-concept text-right">
                                    <Table.Data align="right">
                                        <FormattedDate
                                            className="data-desc small-font"
                                            date={expiration.date}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </Table.Data>
                                    <Table.Data align="center">
                                        <span className="data-name small-font">{expiration.concept.description}</span>
                                    </Table.Data>
                                </Table.Row>
                            </Table.Body>
                        </Table.Row>
                    </>
                ) : (
                    <> </>
                )}
            </Table.Row>
        </>
    );
}

export default AgendaConceptWithBaseDebt;
