import React, { useState } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { Field } from "formik";
import { string, bool, func, arrayOf, shape } from "prop-types";
import classNames from "classnames";

import Selector from "pages/_components/fields/formik/Selector";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import AlfaNumericStgField from "pages/_components/fields/AlfaNumericStgField";
import Hint from "pages/_components/hints/Hint";

import * as i18nUtils from "util/i18n";

function SelectServicePaymentInfo({
    FORM_ID,
    isFetching,
    isEntitiesDisabled,
    handleRubroChange,
    rubros,
    entities,
    isDesktop,
}) {
    SelectServicePaymentInfo.propTypes = {
        FORM_ID: string.isRequired,
        isFetching: bool,
        isDesktop: bool,
        isEntitiesDisabled: bool,
        handleRubroChange: func.isRequired,
        // getEntityOptions: func.isRequired,
        rubros: arrayOf(
            shape({ code: string.isRequired, description: string.isRequired, shortDescrition: string.isRequired }),
        ).isRequired,
        entities: arrayOf(
            shape({
                code: string.isRequired,
                description: string.isRequired,
                handleBaseDebts: bool.isRequired,
                handleMultipleConcepts: bool.isRequired,
                handleIncomeReference: bool.isRequired,
                handleIncomeAmount: bool.isRequired,
                enable: bool.isRequired,
                category: shape({
                    code: string.isRequired,
                    description: string.isRequired,
                    shortDescrition: string.isRequired,
                }),
            }),
        ).isRequired,
    };
    const [showHintInfoMb, setShowHintInfoMb] = useState(false);

    SelectServicePaymentInfo.defaultProps = {
        isFetching: false,
        isEntitiesDisabled: true,
        isDesktop: false,
    };

    const getHeadingOptions = () => {
        const options = rubros.map((rubro) => ({ value: rubro.code, label: rubro.description }));

        return options;
    };

    const getEntityOptions = () => {
        const options = entities ? entities.map((entity) => ({ value: entity.code, label: entity.description })) : [];
        return options;
    };

    return (
        <Container className="pd-3 container--layout container-white" gridClassName="form-content">
            <Col
                sm={12}
                md={9}
                xl={8}
                className={classNames(" align-items-center payments-taxes-width px-xl-0", {
                    "pb-4 payments-taxes-width": isDesktop,
                })}>
                <Row>
                    <Col sm={12} md={6}>
                        <Field
                            idForm={FORM_ID}
                            name="rubros"
                            component={Selector}
                            options={getHeadingOptions()}
                            searchable={isDesktop}
                            customOnChange
                            onCustomChange={handleRubroChange}
                            placeholder={
                                !isFetching
                                    ? i18nUtils.get(`${FORM_ID}.placeholder`)
                                    : i18nUtils.get(`${FORM_ID}.placeholder.loading`)
                            }
                            controlLabelChildClassName="f-size-55"
                            isDesktop={isDesktop}
                            optionClassName="needsclick"
                            className="label-break"
                        />
                    </Col>
                    <Col sm={12} md={6}>
                        <Field
                            idForm={FORM_ID}
                            name="entities"
                            component={Selector}
                            isDisabled={isEntitiesDisabled || isFetching}
                            options={getEntityOptions() || [{ value: "0", label: "Cargando..." }]}
                            searchable={isDesktop}
                            placeholder={
                                !isFetching
                                    ? i18nUtils.get(`${FORM_ID}.placeholder`)
                                    : i18nUtils.get(`${FORM_ID}.placeholder.loading`)
                            }
                            controlLabelChildClassName="f-size-55"
                            isDesktop={isDesktop}
                            optionClassName={classNames("needsclick", { "align-left white-space-wrap": !isDesktop })}
                            className="label-break"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={11} md={6} className={classNames({ "pr-3": !isDesktop })}>
                        <Field
                            name="cpe"
                            idForm={FORM_ID}
                            component={AlfaNumericStgField}
                            hidePlaceholder
                            controlLabelChildClassName="f-size-55"
                            isDisabled={isEntitiesDisabled || isFetching}
                            maxLength={50}
                        />
                    </Col>
                    <Col
                        xs={1}
                        md={6}
                        className={classNames({
                            "d-flex justify-content-center align-items-center pl-0": !isDesktop,
                            "px-0": isDesktop,
                        })}>
                        {isDesktop && (
                            <Hint
                                idMessage={`${FORM_ID}.help`}
                                classNameGroup="min-height-100 mb-0"
                                classNameImage="d-flex"
                                buttonClassName="pt-3 pl-0"
                                classNameHintP="my-0 d-flex h-100 align-items-center"
                                longText={isDesktop}
                            />
                        )}

                        {!isDesktop && (
                            <button
                                type="button"
                                className="d-flex btn btn-hint h-auto my-0 mt-35 py-25 pr-2 pl-1"
                                onClick={() => setShowHintInfoMb(!showHintInfoMb)}>
                                {!showHintInfoMb ? (
                                    <Image src="images/infoUserInvite.svg" styles={{ height: "24px", width: "24px" }} />
                                ) : (
                                    <Image
                                        src="images/infoActiveUserInvite.svg"
                                        styles={{ height: "24px", width: "24px" }}
                                    />
                                )}
                            </button>
                        )}
                    </Col>
                </Row>

                {!isDesktop && showHintInfoMb && (
                    <span className="hint-snackbar-mobile no-fixed px-4 py-2">{i18nUtils.get(`${FORM_ID}.help`)}</span>
                )}
            </Col>
        </Container>
    );
}

export default SelectServicePaymentInfo;
