import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import MainContainer from "pages/_components/MainContainer";
import { func, bool, shape, string } from "prop-types";
import { push } from "react-router-redux";
import Head from "pages/_components/Head";
import { compose } from "redux";
import Col from "react-bootstrap/lib/Col";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import { selectors as checkSelector } from "reducers/checks";
import { selectors as sessionSelectors } from "reducers/session";
import CheckTokenConfirmation from "pages/checks/CheckTokenConfirmation";
import ChecksSummaryData from "pages/checks/ChecksSummaryData";

const FORM_ID = "new.check";

class ChecksSummary extends Component {
    static propTypes = {
        isDesktop: bool,
        dispatch: func.isRequired,
        checksRequestData: shape({}).isRequired,
        userId: string.isRequired,
        fullName: string.isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(push(`/checks/back`));
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 className="w-100 m-0">{i18n.get("new.check.request")}</h1>
        </div>
    );

    render() {
        const { isDesktop, checksRequestData, userId, fullName } = this.props;
        return (
            <>
                <Notification scopeToShow="checksSummary" />
                {checksRequestData?.account && (
                    <>
                        <div
                            className={classNames({
                                "admin-detail-head px-0": isDesktop,
                            })}>
                            <Head
                                onBack={this.handleBack}
                                headerClassName={classNames({
                                    "blue-main-header-mobile": !isDesktop,
                                })}
                                centerElement={!isDesktop && this.centerContentMobile}
                            />
                            {isDesktop && (
                                <div className="view-title">
                                    <h1>
                                        {i18n.get(
                                            checksRequestData?.returnToPending
                                                ? "modify.check.request"
                                                : "new.check.request",
                                        )}
                                    </h1>
                                </div>
                            )}
                        </div>
                        <MainContainer viewPageClassName="confirmation__container">
                            <Container
                                className={classNames({
                                    "flex-grow container-white pt-3": !isDesktop,
                                    "align-items-center": isDesktop,
                                })}
                                gridClassName={isDesktop && "confirmation__form confirmation__box mb-0"}>
                                <div className={!isDesktop && "admin-content-center mb-45"}>
                                    <I18n
                                        id="check.request.summary.label"
                                        component={isDesktop ? "h1" : "h2"}
                                        componentProps={{ className: classNames({ "f-size-35 px-3": !isDesktop }) }}
                                    />
                                </div>
                                <Col className="col-12 col-xl-12 mb-3" lg={12} md={9} sm={12}>
                                    <ChecksSummaryData
                                        checksRequestData={checksRequestData}
                                        FORM_ID={FORM_ID}
                                        isDesktop={isDesktop}
                                        fullName={fullName}
                                    />
                                </Col>
                            </Container>

                            <CheckTokenConfirmation
                                checksRequestData={checksRequestData}
                                userId={userId}
                                isDraft="true"
                                isDesktop={isDesktop}
                            />
                        </MainContainer>
                    </>
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    checksRequestData: checkSelector.getChecksRequestData(state),
    userId: sessionSelectors.getUser(state).userId,
    fullName: sessionSelectors.getUser(state).userFullName,
});

export default compose(connect(mapStateToProps))(ChecksSummary);
