import React, { Component, Fragment } from "react";
import { shape, string, func, arrayOf } from "prop-types";

import { actions as adminActions } from "reducers/administration/common/administrationTicket";

import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import PermissionsList from "pages/administration/_components/PermissionsList";

import * as arrayUtils from "util/array";
import * as creditCardsUtils from "util/creditCards";
import { permissionsAggrupation } from "util/permissions";
import * as i18nUtils from "util/i18n";

class AdministrationPermissionsTicket extends Component {
    static propTypes = {
        user: shape({
            idUser: string,
            fullName: string,
        }),
        dispatch: func.isRequired,
        products: arrayOf(string).isRequired,
        permissions: arrayOf(string).isRequired,
        groups: arrayOf({}).isRequired,
        transactionData: shape({}).isRequired,
    };

    static defaultProps = {
        user: null,
    };

    componentDidMount() {
        this.chargeData();
    }

    chargeData = () => {
        const { dispatch, user, transactionData } = this.props;
        const { permissions, oldPermissions } = transactionData.data;
        const { newPermissionsToShow, removePermissionsToShow } = permissionsAggrupation(permissions, oldPermissions);

        const newPermissions = this.getPermissionsAndGroups(this.obtainAmount(), newPermissionsToShow);
        const removePermissions = this.getPermissionsAndGroups(this.obtainAmount(), removePermissionsToShow);

        const newPermissionsTitleData = {
            addedSubtitle: {
                value: " ",
            },
        };
        const removePermissionsTitleData = {
            removedSubtitle: {
                value: " ",
            },
        };

        const newPermissionsTitle = {
            "addedSubtitle.value": {
                i18nLabel: i18nUtils.get("administration.configuratePermissions.confirm.added.subtitle"),
                type: "string",
            },
        };
        const removePermissionsTitle = {
            "removedSubtitle.value": {
                i18nLabel: i18nUtils.get("administration.configuratePermissions.confirm.removed.subtitle"),
                type: "string",
            },
        };

        const dataNew = {
            ...newPermissionsTitleData,
            ...newPermissions.reduce((obj, permission) => {
                const { id, value } = permission;
                if (!value) {
                    return obj;
                }
                const elem = {
                    [id]: {
                        value,
                    },
                };
                return { ...obj, ...elem };
            }, {}),
        };

        const dataOld = {
            ...removePermissionsTitleData,
            ...removePermissions.reduce((obj, permission) => {
                const { id, value } = permission;
                if (!value) {
                    return obj;
                }
                const elem = {
                    [id]: {
                        value,
                    },
                };
                return { ...obj, ...elem };
            }, {}),
        };

        const exportObjNew = {
            ...newPermissionsTitle,
            ...newPermissions.reduce((obj, permission) => {
                const { id, label, value } = permission;
                if (!value) {
                    return obj;
                }
                const elem = {
                    [`${id}.value`]: {
                        i18nLabel: label,
                        type: "string",
                    },
                };
                return { ...obj, ...elem };
            }, {}),
        };

        const exportObjOld = {
            ...removePermissionsTitle,
            ...removePermissions.reduce((obj, permission) => {
                const { id, label, value } = permission;
                if (!value) {
                    return obj;
                }
                const elem = {
                    [`${id}.value`]: {
                        i18nLabel: label,
                        type: "string",
                    },
                };
                return { ...obj, ...elem };
            }, {}),
        };

        const { firstName, lastName } = user;

        const extraData = {
            user: {
                label: "administration.permissions.user",
                value: `${firstName} ${lastName}`,
            },
            ...dataNew,
            ...dataOld,
            export: { ...exportObjNew, ...exportObjOld },
        };

        dispatch(adminActions.setExtraData(extraData));
    };

    countOptions = ({ childrenList, permissionList, idItem }, predicate) => {
        if (permissionList.length && permissionList[0].simpleAllowProductSelection) {
            const { products } = this.props;

            return predicate ? predicate(idItem, products) : products.length;
        }

        if (childrenList.length) {
            return childrenList.reduce((amount, option) => amount + this.countOptions(option, predicate), 0);
        }

        if (predicate && !predicate(idItem)) {
            return 0;
        }

        return 1;
    };

    predicateAmount = (idItem, products) => {
        const { permissions } = this.props;

        if (products) {
            return arrayUtils.intersection(
                permissions[idItem] || [],
                products.map(({ idProduct }) => idProduct),
            ).length;
        }

        return permissions[idItem] && permissions[idItem].length;
    };

    obtainAmount = () => {
        const { groups } = this.props;

        const getAmount = (amounts, group) => {
            const amount = this.countOptions(group, this.predicateAmount);

            if (!amount) {
                return amounts;
            }

            return {
                ...amounts,
                [group.idItem]: amount,
            };
        };

        return groups?.reduce(getAmount, {});
    };

    detectBrand = (number) => number && creditCardsUtils.detectBrand(number);

    renderProducts = (option, permissionsToLoad) => {
        const { products: productFromProps } = this.props;

        return productFromProps.reduce((products, { idProduct, number, productAlias, label }) => {
            const permissions = permissionsToLoad[option.idItem];

            if (!permissions.includes(idProduct)) {
                return products;
            }

            const brand = this.detectBrand(number);

            const correctLabel = label ? label.replaceAll("null", "") : "";

            let text = brand || "";

            if (!productAlias) {
                text += correctLabel.concat("\n");
            } else {
                text += productAlias.concat(" ").concat(correctLabel);
            }

            return products.concat(text).concat("\n\n");
        }, "");
    };

    loadOptions = (childrenList, permissionsToLoad) =>
        childrenList?.reduce((categoryOptions, option) => {
            if (option.childrenList && option.childrenList.length) {
                const subOptions = this.loadOptions(option.childrenList, permissionsToLoad);

                return categoryOptions
                    .concat(option.label)
                    .concat("\n")
                    .concat(subOptions);
            }
            const permissions = permissionsToLoad[option.idItem] || [];

            if (permissions.length) {
                const [permission] = option.permissionList || [];

                const text =
                    permission && permission.simpleAllowProductSelection
                        ? this.renderProducts(option, permissionsToLoad)
                        : option.label;

                return categoryOptions.concat(text).concat("\n");
            }

            return categoryOptions;
        }, "") || "";

    getPermissionsAndGroups = (amountsById, permissions) => {
        const { groups } = this.props;

        return groups.reduce((mainCategories, option) => {
            const { childrenList, idItem, permissionList = [] } = option;

            const categoryOptions = this.loadOptions(childrenList, permissions);
            const optionsAmount = amountsById[idItem];
            const [permission] = permissionList;

            const data = {
                label: option.label,
                id: idItem.replaceAll(".", ""),
            };

            if ((permission && permission.simpleAllowProductSelection) || categoryOptions) {
                if (permission && permission.simpleAllowProductSelection && !optionsAmount) {
                    return mainCategories;
                }

                const text =
                    permission && permission.simpleAllowProductSelection
                        ? this.renderProducts(option, permissions)
                        : categoryOptions;

                return [...mainCategories, { ...data, value: text }];
            }

            if (!optionsAmount) {
                return mainCategories;
            }

            return [...mainCategories, data];
        }, []);
    };

    render() {
        const { user, transactionData } = this.props;
        const { permissions, oldPermissions } = transactionData.data;
        const { newPermissionsToShow, removePermissionsToShow } = permissionsAggrupation(permissions, oldPermissions);

        if (user) {
            const { firstName, lastName } = user;
            return (
                <Container
                    className="container--layout align-items-center flex-grow p-0 m-0"
                    gridClassName="form-content">
                    <Col sm={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="administration.permissions.user"
                            data={`${firstName} ${lastName}`}
                        />
                        <div className="px-0">
                            {newPermissionsToShow && (
                                <>
                                    <I18n
                                        component="h4"
                                        id="administration.configuratePermissions.confirm.added.subtitle"
                                        componentProps={{
                                            className: "form-title mb-4 control-label mb-0 permisions-control-label",
                                        }}
                                    />
                                    <PermissionsList permissions={newPermissionsToShow}>
                                        {(list) => {
                                            if (list) {
                                                return <Fragment>{list}</Fragment>;
                                            }

                                            return <I18n id="administration.permissions.empty" />;
                                        }}
                                    </PermissionsList>
                                </>
                            )}
                            {removePermissionsToShow && (
                                <>
                                    <I18n
                                        component="h4"
                                        id="administration.configuratePermissions.confirm.removed.subtitle"
                                        componentProps={{
                                            className: "form-title mb-4 control-label mb-0 permisions-control-label",
                                        }}
                                    />
                                    <PermissionsList permissions={removePermissionsToShow}>
                                        {(list) => {
                                            if (list) {
                                                return <Fragment>{list}</Fragment>;
                                            }

                                            return <I18n id="administration.permissions.empty" />;
                                        }}
                                    </PermissionsList>
                                </>
                            )}
                        </div>
                    </Col>
                </Container>
            );
        }
        return null;
    }
}

export default AdministrationPermissionsTicket;
