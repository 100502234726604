import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { bool, func } from "prop-types";
import classNames from "classnames";

import { selectors as settingsSelectors, actions as settingsActions } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18n from "util/i18n";
import Credential from "pages/_components/fields/credentials/Credential";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Hint from "pages/_components/hints/Hint";
import Image from "pages/_components/Image";
import isTokenActive from "util/token";
import PageLoading from "pages/_components/PageLoading";

const FORM_ID = "settings.changeEmail";

class ChangeEmail extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        fetching: bool.isRequired,
        dispatch: func.isRequired,
    };

    state = {
        showMessage: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(settingsActions.getUserData());
    }

    changeMessageDisplay = () => {
        const { showMessage } = this.state;
        this.setState({ showMessage: !showMessage });
    };

    renderButtonInfo = () => {
        const { showMessage } = this.state;

        return (
            <button
                type="button"
                className="no-shadow btn btn-link hint-button change-data-hint-button p-0 m-0"
                onClick={() => this.changeMessageDisplay()}>
                {showMessage ? (
                    <Image src="images/infoActiveUserInvite.svg" className="hint-image-style" />
                ) : (
                    <Image src="images/infoUserInvite.svg" className="hint-image-style" />
                )}
            </button>
        );
    };

    changeMailForm = () => {
        const { isDesktop, isSubmitting } = this.props;
        const { showMessage } = this.state;

        return (
            <Form className={!isDesktop ? "above-the-fold" : "confirmation__container"} noValidate="novalidate">
                <Container
                    className={`align-items-center flex-grow px-0 ${
                        isDesktop ? "container--layout with-margin" : "pb-0"
                    }`}
                    gridClassName={!isDesktop ? "form-content" : "confirmation__form confirmation__box"}>
                    <Container
                        className={classNames("align-items-center flex-grow container-white", {
                            "pb-0": !isDesktop,
                        })}
                        gridClassName="form-content">
                        <Row className="justify-content-center pb-4">
                            <Col sm={12} md={8} lg={12}>
                                <div className="admin-content-center change-info-explanation">
                                    <I18n id="settings.changeEmail.explanation" component="h2" />
                                </div>
                            </Col>
                        </Row>
                        <div
                            className={classNames("w-100", {
                                "d-flex justify-content-end mb-2": isDesktop,
                                "mb-0 min-height-14": !isDesktop,
                            })}>
                            <Col
                                sm={12}
                                md={9}
                                lg={9}
                                className={classNames({
                                    "mb-5 mt-1": isDesktop,
                                })}>
                                <Field
                                    idForm={FORM_ID}
                                    name="mail"
                                    maxLength="100"
                                    component={Credential}
                                    autoFocus={isDesktop}
                                    extraLabel={!isDesktop && this.renderButtonInfo()}
                                    errorClassName={classNames({
                                        "p-absolute": isDesktop,
                                    })}
                                />
                            </Col>
                            {isDesktop ? (
                                <Col sm={12} md={3} lg={2} className="d-flex align-items-center pl-0">
                                    <Hint
                                        idMessage="settings.changeEmail.mail.info"
                                        classNameGroup="align-items-center my-0"
                                        classNameHintP="d-flex align-items-end my-0"
                                        buttonClassName="my-0"
                                        longText
                                    />
                                </Col>
                            ) : (
                                <>
                                    {showMessage && (
                                        <Col sm={12}>
                                            <I18n
                                                id="settings.changeEmail.mail.info"
                                                component="p"
                                                componentProps={{ className: "hint-text" }}
                                            />
                                        </Col>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="w-100">
                            <Col xs={12} className="w-100 d-flex justify-content-center">
                                <Col sm={12} md={6} lg={12} className="p-0">
                                    <div className="admin-content-center">
                                        {!isTokenActive(!isDesktop) ? (
                                            <I18n
                                                id="settings.changeEmail.verifyWithCredential"
                                                component="p"
                                                componentProps={{ className: "settings-text" }}
                                            />
                                        ) : (
                                            <div className="admin-content-center pb-3">
                                                <I18n
                                                    id="confirmation.withoutotp.text"
                                                    component="p"
                                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Col>

                            <Col className="w-100 d-flex justify-content-center">
                                <Col sm={12} md={6} lg={10}>
                                    <Field
                                        placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                        idForm={FORM_ID}
                                        name="otp"
                                        component={Credential}
                                        type="otp"
                                    />
                                </Col>
                            </Col>
                            <Col className="w-100 d-flex justify-content-center" />
                        </div>
                        <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                            <Col sm={12} md={12} lg={12} className="px-0">
                                <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                                    <div className="admin-content-center">
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.confirm"
                                            image="images/arrowRight.svg"
                                            imageStyle="mr-2"
                                            loading={isSubmitting}
                                            className="text-capitalize"
                                        />
                                    </div>
                                </Col>
                            </Col>
                        </Container>
                    </Container>
                </Container>
            </Form>
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/"));
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("settings.changeEmail")}</h1>
        </div>
    );

    render() {
        const { fetching, isDesktop } = this.props;
        return (
            <PageLoading loading={fetching}>
                <Notification scopeToShow="changeEmail" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        centerContentClassName="mx-5"
                    />
                    {isDesktop && (
                        <div className="view-title">
                            <h1>{i18n.get("settings.changeEmail")}</h1>
                        </div>
                    )}
                </div>
                <MainContainer>{this.changeMailForm()}</MainContainer>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: settingsSelectors.isFetching(state),
    currentEmail: settingsSelectors.getEmail(state),
    userEmail: settingsSelectors.getMailToChange(state) || "",
    user: sessionSelectors.getUser(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            mail: props.user.email || props.userEmail || props.currentEmail || "",
            otp: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                mail: Yup.string()
                    .email(i18n.get(`${FORM_ID}.mail.invalid`))
                    .required(i18n.get(`${FORM_ID}.mail.required`)),
                otp: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: ({ mail, otp }, formikBag) => {
            formikBag.props.dispatch(settingsActions.sendMailCode(mail, otp, formikBag));
        },
    }),
)(ChangeEmail);
