import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { types as fingerprintTypes } from "reducers/fingerprint";
import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    RESET: "login/RESET",
    INIT_LOGIN_FLOW: "login/INIT_LOGIN_FLOW",

    FINALIZE_LOGIN: "login/FINALIZE_LOGIN",

    LOGOUT_REQUEST: "login/LOGOUT_REQUEST",

    LOGIN_STEP_1_REQUEST: "login/LOGIN_STEP_1_REQUEST",
    LOGIN_STEP_1_SUCCESS: "login/LOGIN_STEP_1_SUCCESS",

    USERPASS_NOTIFICATION_SHOWED: "login/USERPASS_NOTIFICATION_SHOWED",
    DESKTOP_CREDENTIALS_NOTIFICATION_SHOWED: "login/DESKTOP_CREDENTIALS_NOTIFICATION_SHOWED",

    // TODO: Delete these types if oauth is the only flavour
    LOGIN_STEP_2_REQUEST: "login/LOGIN_STEP_2_REQUEST",
    LOGIN_STEP_2_SUCCESS: "login/LOGIN_STEP_2_SUCCESS",

    LOGIN_OAUTH_REQUEST: "login/LOGIN_OAUTH_REQUEST",
    LOGIN_OAUTH_SUCCESS: "login/LOGIN_OAUTH_SUCCESS",
    LOGIN_LIST_ENVIRONMENTS_REQUEST: "login/LOGIN_LIST_ENVIRONMENTS_REQUEST",
    LOGIN_LIST_ENVIRONMENTS_SUCCESS: "login/LOGIN_LIST_ENVIRONMENTS_SUCCESS",

    LOGIN_STEP_3_REQUEST: "login/LOGIN_STEP_3_REQUEST",
    LOGIN_STEP_3_SUCCESS: "login/LOGIN_STEP_3_SUCCESS",
    LOGIN_STEP_4_REQUEST: "login/LOGIN_STEP_4_REQUEST",

    LOGIN_FAILURE: "login/LOGIN_FAILURE",
    LOGIN_FAILURE_REQUIRE_CAPTCHA: "login/LOGIN_FAILURE_REQUIRE_CAPTCHA",
    LOGIN_RESET_CAPTCHA: "login/LOGIN_RESET_CAPTCHA",
    LOGIN_FINGERPRINT_FAILURE: "login/LOGIN_FINGERPRINT_FAILURE",
    LOGIN_SUCCESS: "login/LOGIN_SUCCESS",

    FINGERPRINT_LOGIN_SUBMIT: "login/FINGERPRINT_LOGIN_SUBMIT",
    FINGERPRINT_LOGIN_PRE: "login/FINGERPRINT_LOGIN_PRE",
    FINGERPRINT_LOGIN_PRE_SUCCESS: "login/FINGERPRINT_LOGIN_PRE_SUCCESS",

    SET_REMEMBERED_USER: "login/SET_REMEMBERED_USER",
    SET_BIOMETRIC_LOGIN: "login/SET_BIOMETRIC_LOGIN",
    REMOVE_BIOMETRIC_LOGIN: "login/REMOVE_BIOMETRIC_LOGIN",
    REMOVE_REMEMBERED_USER: "login/REMOVE_REMEMBERED_USER",
    SET_FROM_ONBOARDING_DATA: "login/SET_FROM_ONBOARDING_DATA",
    MARK_ENVIRONMENTS_DISABLED: "login/MARK_ENVIRONMENTS_DISABLED",
    GO_BACK_LOGIN_SHOW_MESSAGE: "login/GO_BACK_LOGIN_SHOW_MESSAGE",
    SET_FINGERPRINT_ENVIRONMENT_RESTRICTED: "login/SET_FINGERPRINT_ENVIRONMENT_RESTRICTED",
    SET_REGION: "session/SET_REGION",
    GET_CLIENT_COUNTRY_REQ: "session/GET_CLIENT_COUNTRY_REQ",
    GET_CLIENT_COUNTRY_RES: "session/GET_CLIENT_COUNTRY_RES",

    DISMISS_CAMPAIGN_REQUEST: "campaigns/DISMISS_CAMPAIGN_REQUEST",
    GET_RANDOM_SECURITY_SEAL: "session/GET_RANDOM_SECURITY_SEAL",
    SET_RANDOM_SECURITY_SEAL: "session/SET_RANDOM_SECURITY_SEAL",
    DOWNLOAD_TERMS_REQUEST: "session/DOWNLOAD_TERMS_REQUEST",
    DOWNLOAD_TERMS_SUCCESS: "session/DOWNLOAD_TERMS_SUCCESS",
    DOWNLOAD_TERMS_FAILURE: "session/DOWNLOAD_TERMS_FAILURE",
    FINGERPRINT_SUBMIT: "login/FINGERPRINT_SUBMIT",
    KEEP_USER_FIRST_NAME: "login/KEEP_USER_FIRST_NAME",
};

export const INITIAL_STATE = {
    rememberedUser: null,
    fetchingDownload: false,
    biometricLogin: null,
    exchangeToken: null,
    showCaptcha: false,
    resetCaptcha: false,
    fetching: false,
    fetchingBiometric: false,
    fingerprintSubmiting: false,
    lang: "",
    environments: {},
    fromOnboardingLoginData: null,
    username: null,
    fingerPrintEnvironmentRestricted: false,
    idEnvironmentToAcceptConditions: null,
    region: null,
    clientCountry: null,
    lastLogin: null,
    displayCampaigns: true,
    fingerprintInitialized: false,
    userpassNotificationShowed: false,
    desktopCredentialsNotificationShowed: false,
    daysToExpireCredential: {},
};

const reducer = createReducer(INITIAL_STATE, {
    [types.SET_REGION]: (state, { region }) => ({
        ...state,
        region,
    }),
    [types.GET_CLIENT_COUNTRY_RES]: (state, { clientCountry }) => ({
        ...state,
        clientCountry,
    }),
    [types.SET_FROM_ONBOARDING_DATA]: (state, { fromOnboardingLoginData }) => ({
        ...state,
        fromOnboardingLoginData,
    }),
    [types.RESET]: ({ rememberedUser, fromOnboardingLoginData, region, clientCountry, biometricLogin }) => ({
        ...INITIAL_STATE,
        rememberedUser,
        fromOnboardingLoginData,
        region,
        clientCountry,
        biometricLogin,
    }),
    [types.INIT_LOGIN_FLOW]: ({ fromOnboardingLoginData, rememberedUser, region, clientCountry, biometricLogin }) => ({
        ...INITIAL_STATE,
        rememberedUser,
        fromOnboardingLoginData,
        region,
        clientCountry,
        biometricLogin,
    }),

    [types.SET_FINGERPRINT_ENVIRONMENT_RESTRICTED]: (state) => ({ ...state, fingerPrintEnvironmentRestricted: true }),
    [types.USERPASS_NOTIFICATION_SHOWED]: (state) => ({
        ...state,
        userpassNotificationShowed: true,
        fetching: false,
        fetchingBiometric: false,
    }),
    [types.DESKTOP_CREDENTIALS_NOTIFICATION_SHOWED]: (state) => ({
        ...state,
        desktopCredentialsNotificationShowed: true,
    }),
    [types.LOGIN_STEP_1_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        fetchingBiometric: true,
        notification: null,
    }),
    [types.LOGIN_STEP_1_SUCCESS]: (
        state,
        {
            exchangeToken,
            lang,
            securitySeal,
            securitySealAlt,
            userFirstName,
            userFullName,
            username,
            lastLogin,
            userMaskedEmail,
            userMaskedPhone,
            daysToExpireCredential,
        },
    ) => ({
        ...state,
        fetching: false,
        fetchingBiometric: false,
        showCaptcha: false,
        exchangeToken,
        lang,
        securitySeal,
        securitySealAlt,
        userFirstName,
        userFullName,
        username,
        lastLogin,
        userMaskedEmail,
        userMaskedPhone,
        daysToExpireCredential,
    }),
    [types.SET_RANDOM_SECURITY_SEAL]: (state, { securitySeal, securitySealAlt }) => ({
        ...state,
        securitySeal,
        securitySealAlt,
    }),

    // TODO: Delete these types if oauth is the only flavour
    [types.LOGIN_STEP_2_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        fetchingBiometric: true,
        notification: null,
    }),
    [types.LOGIN_STEP_2_SUCCESS]: (state, { environments }) => ({
        ...state,
        fetching: false,
        fetchingBiometric: false,
        showCaptcha: false,
        environments,
    }),
    [types.MARK_ENVIRONMENTS_DISABLED]: (state, { environments }) => ({
        ...state,
        fetching: false,
        fetchingBiometric: false,
        environments,
    }),

    [types.LOGIN_OAUTH_REQUEST]: (state) => ({ ...state, fetching: true, notification: null }),
    [types.LOGIN_OAUTH_SUCCESS]: (state, { environments }) => ({
        ...state,
        fetching: false,
        showCaptcha: false,
        environments,
    }),

    [types.LOGIN_STEP_3_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        fetchingBiometric: true,
        notification: null,
    }),
    [types.LOGIN_STEP_3_SUCCESS]: (state, { termsAndConditions }) => ({
        ...state,
        fetching: false,
        fetchingBiometric: false,
        termsAndConditions,
    }),

    [types.LOGIN_STEP_4_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        fetchingBiometric: true,
        notification: null,
    }),
    [types.LOGIN_SUCCESS]: ({ rememberedUser, region, clientCountry, biometricLogin, daysToExpireCredential }) => ({
        ...INITIAL_STATE,
        rememberedUser,
        region,
        clientCountry,
        biometricLogin,
        daysToExpireCredential,
    }),

    [types.REMOVE_REMEMBERED_USER]: (state) => ({ ...state, rememberedUser: INITIAL_STATE.rememberedUser }),
    [types.SET_REMEMBERED_USER]: (state, { rememberedUser }) => ({ ...state, rememberedUser }),

    [types.SET_BIOMETRIC_LOGIN]: (state, { biometricLogin }) => ({ ...state, biometricLogin }),

    [types.REMOVE_BIOMETRIC_LOGIN]: (state) => ({ ...state, biometricLogin: INITIAL_STATE.biometricLogin }),

    [types.LOGIN_FAILURE]: (state) => ({ ...state, fetching: false, fingerprintSubmiting: false }),

    [types.LOGIN_FAILURE_REQUIRE_CAPTCHA]: (state) => ({
        ...state,
        fetching: false,
        fetchingBiometric: false,
        showCaptcha: true,
        fingerprintSubmiting: false,
    }),
    [types.LOGIN_RESET_CAPTCHA]: (state, { resetCaptcha }) => ({
        ...state,
        resetCaptcha,
    }),
    [types.LOGIN_FINGERPRINT_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        fetchingBiometric: false,
        fingerprintSubmiting: false,
        fingerprintLoginFail: true,
        fingerprintInitialized: false,
    }),
    [types.FINALIZE_LOGIN]: (state, { response }) => ({
        ...state,
        generalConditions: response.data.data.generalConditions,
        idEnvironmentToAcceptConditions: response.data.data.idEnvironmentToAcceptConditions,
        fetching: false,
        fetchingBiometric: false,
    }),
    [types.FINGERPRINT_LOGIN_SUBMIT]: (state) => ({ ...state, fingerprintSubmiting: true }),
    [types.FINGERPRINT_LOGIN_PRE]: (state) => ({
        ...state,
        fingerprintInitialized: true,
    }),
    [types.FINGERPRINT_LOGIN_PRE_SUCCESS]: (state, { exchangeToken }) => ({
        ...state,
        fetching: false,
        fetchingBiometric: false,
        exchangeToken,
    }),

    [fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE_SUCCESS]: (state, { exchangeToken }) => ({
        ...state,
        exchangeToken,
    }),
    [types.DISMISS_CAMPAIGN_REQUEST]: (state) => ({
        ...state,
        displayCampaigns: false,
    }),
    [types.DOWNLOAD_TERMS_REQUEST]: (state) => ({
        ...state,
        fetching: true,
        fetchingBiometric: false,
        fetchingDownload: true,
    }),
    [types.DOWNLOAD_TERMS_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
        fetchingBiometric: false,
        fetchingDownload: false,
    }),
    [types.DOWNLOAD_TERMS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        fetchingDownload: false,
    }),

    [types.FINGERPRINT_SUBMIT]: (state) => ({
        ...state,
        fetching: true,
        fetchingBiometric: true,
    }),

    [types.KEEP_USER_FIRST_NAME]: (state, { userFirstName }) => ({
        ...state,
        userFirstName,
    }),
});

export default persistReducer(
    {
        storage,
        key: "login",
        whitelist: ["rememberedUser", "region", "clientCountry", "biometricLogin"],
    },
    reducer,
);

export const actions = {
    getRandomSecuritySeal: () => ({
        type: types.GET_RANDOM_SECURITY_SEAL,
    }),
    dismissCampaigns: () => ({
        type: types.DISMISS_CAMPAIGN_REQUEST,
    }),
    getClientContry: () => ({
        type: types.GET_CLIENT_COUNTRY_REQ,
    }),
    setRegion: (region) => ({
        type: types.SET_REGION,
        region,
    }),
    reset: makeActionCreator(types.RESET),
    initLoginFlow: makeActionCreator(types.INIT_LOGIN_FLOW),

    loginStep1: makeActionCreator(
        types.LOGIN_STEP_1_REQUEST,
        "username",
        "rememberDocument",
        "document",
        "documentType",
        "recaptchaResponse",
        "formikBag",
        "userpassNotificationShowed",
    ),
    // TODO: Delete this action if oauth is the only flavour
    loginStep2: makeActionCreator(types.LOGIN_STEP_2_REQUEST, "password", "recaptchaResponse", "formikBag"),
    oauth: makeActionCreator(types.LOGIN_OAUTH_REQUEST, "password", "recaptchaResponse", "formikBag", "showCaptcha"),
    loginStep3: makeActionCreator(types.LOGIN_STEP_3_REQUEST, "idEnvironment", "rememberEnvironment", "formikBag"),
    loginStep4: makeActionCreator(types.LOGIN_STEP_4_REQUEST, "acceptConditions", "formikBag"),

    fingerprintLoginPre: makeActionCreator(types.FINGERPRINT_LOGIN_PRE),

    setRememberedUser: makeActionCreator(types.SET_REMEMBERED_USER, "rememberedUser"),
    setBiometricLoginShow: makeActionCreator(types.SET_BIOMETRIC_LOGIN, "biometricLogin"),
    removeBiometricLoginShow: makeActionCreator(types.REMOVE_BIOMETRIC_LOGIN),
    removeRememberedUser: makeActionCreator(types.REMOVE_REMEMBERED_USER),
    goBackToLoginAndShowMessage: makeActionCreator(types.GO_BACK_LOGIN_SHOW_MESSAGE, "message"),
    downloadDocumentRequest: makeActionCreator(types.DOWNLOAD_TERMS_REQUEST, "format"),
    setDesktopCredentialNotificationShowed: makeActionCreator(types.DESKTOP_CREDENTIALS_NOTIFICATION_SHOWED),
    setResetCaptcha: makeActionCreator(types.LOGIN_RESET_CAPTCHA, "resetCaptcha"),
};

export const selectors = {
    getUsername: ({ login }) => login.username,
    getFingerPrintEnvironmentRestricted: ({ login }) => login.fingerPrintEnvironmentRestricted,
    getRememberedUser: ({ login }) => login.rememberedUser,
    getUserFirstName: ({ login }) => login.userFirstName,
    getUserFullName: ({ login }) => login.userFullName,
    getSecuritySeal: ({ login }) => login.securitySeal,
    getSecuritySealAlt: ({ login }) => login.securitySealAlt,
    getShowCaptcha: ({ login }) => login.showCaptcha,
    getResetCaptcha: ({ login }) => login.resetCaptcha,
    getFetching: ({ login }) => login.fetching,
    getFetchingBiometric: ({ login }) => login.fetchingBiometric,
    getNotification: ({ login }) => login.notification,
    getTermsAndConditions: ({ login }) => login.generalConditions,
    getExchangeToken: ({ login }) => login.exchangeToken,
    getAccessToken: ({ login }) => login.accessToken,
    getFingerprintSubmiting: ({ login }) => login.fingerprintSubmiting,
    getFingerprintLoginFail: ({ login }) => login.fingerprintLoginFail,
    getEnvironments: ({ login }) => login.environments,
    getIsInFlow: ({ login }) => !!login.exchangeToken,
    getFromOnboardingLoginData: ({ login }) => login.fromOnboardingLoginData,
    getIdEnvironmentToAcceptConditions: ({ login }) => login.idEnvironmentToAcceptConditions,
    getRegion: ({ login }) => login.region,
    getLastLogin: ({ login }) => login.lastLogin,
    getDisplayCampaigns: ({ login }) => login.displayCampaigns,
    getMaskedEmail: ({ login }) => login.userMaskedEmail,
    getMaskedPhone: ({ login }) => login.userMaskedPhone,
    getDaysToExpireCredential: ({ login }) => login.daysToExpireCredential,
    getBiometricLogin: ({ login }) => login.biometricLogin,
    getFingerprintInitialized: ({ login }) => login.fingerprintInitialized,
    getUserpassNotificationShowed: ({ login }) => login.userpassNotificationShowed,
    getDesktopCredentialsNotificationShowed: ({ login }) => login.desktopCredentialsNotificationShowed,
    getFetchingDownload: ({ login }) => login.fetchingDownload,
};
