import React, { Component } from "react";
import { Modal, Col } from "react-bootstrap";
import { bool, func, string } from "prop-types";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";

class TermsAndConditionsModal extends Component {
    static propTypes = {
        isDisplayed: bool.isRequired,
        handleDismiss: func.isRequired,
        handleDownload: func.isRequired,
        termsConditions: string.isRequired,
        enrrollment: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { isDisplayed, handleDismiss, handleDownload, termsConditions, enrrollment, isDesktop } = this.props;

        return (
            <Modal
                onHide={handleDismiss}
                show={isDisplayed}
                closeButton
                className={`modal-termsAndConditions ${enrrollment && "enrollment-modal-tyc"}`}>
                <div className="modal-container">
                    <Modal.Header>
                        <div className="flex-container justify-content-end align-items-center">
                            <Col>
                                <Button
                                    className={`toolbar-btn view-close button-download ${isDesktop && "pr-5"}`}
                                    onClick={handleDownload}
                                    image="images/download.svg"
                                    bsStyle="link"
                                    label="global.download"
                                />
                            </Col>
                            <Col>
                                <Button
                                    className={`toolbar-btn view-close button-close ${isDesktop &&
                                        "p-absolute tac-modal-close-button"}`}
                                    onClick={handleDismiss}
                                    image="images/cross.svg"
                                    bsStyle="link"
                                />
                            </Col>
                        </div>
                    </Modal.Header>
                    <Modal.Title className={`${isDesktop && "mt-3"}`}>
                        <div className="text-center">
                            <I18n id="global.termAndConditions" />
                        </div>
                    </Modal.Title>

                    <Modal.Body>
                        <div className="scrollable-content">
                            <p dangerouslySetInnerHTML={{ __html: termsConditions }} />
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}

export default TermsAndConditionsModal;
