const toggleParamsFrom = (value) => (state, ...params) =>
    params.reduce(
        (newState, param) => ({
            ...newState,
            [param]: !value,
        }),
        state,
    );

export const toggleParamsFromFalse = toggleParamsFrom(false);
export const toggleParamsFromTrue = toggleParamsFrom(true);
