import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { actions as adminActions } from "reducers/administration/common/administrationTicket";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import { bool, shape, string, func } from "prop-types";
import * as i18nUtils from "util/i18n";

class AdministrationChannelsTicket extends Component {
    static propTypes = {
        user: shape({}).isRequired,
        hasSignatureEnabled: bool.isRequired,
        schemeName: string.isRequired,
        signatureLevel: string.isRequired,
        dispatch: func.isRequired,
    };

    state = {
        idUser: null,
    };

    componentDidMount() {
        this.chargeData();
    }

    chargeData = () => {
        const { dispatch } = this.props;
        const { schemeName, hasSignatureEnabled, signatureLevel, user } = this.props;

        const advancedTicket = signatureLevel
            ? i18nUtils.get("administration.signature.ticket.configure", null, { SIGNATURE_LEVEL: signatureLevel })
            : i18nUtils.get("administration.signature.ticket.change.true");

        const scheme =
            schemeName === "medium"
                ? i18nUtils.get(`administration.signature.ticket.change.${!hasSignatureEnabled}`)
                : advancedTicket;

        const extraData = {
            user: {
                label: "administration.signature.user",
                value: `${user.firstName} ${user.lastName}`,
            },
            scheme: {
                value: scheme,
            },
        };

        dispatch(adminActions.setExtraData(extraData));
    };

    renderMediumTicket = () => {
        const { hasSignatureEnabled } = this.props;

        return <I18n id={`administration.signature.ticket.change.${!hasSignatureEnabled}`} />;
    };

    renderAdvancedTicket = () => {
        const { signatureLevel } = this.props;

        if (signatureLevel) {
            return <I18n id="administration.signature.ticket.configure" SIGNATURE_LEVEL={signatureLevel} />;
        }

        return <I18n id="administration.signature.ticket.change.true" />;
    };

    render() {
        const { user, schemeName } = this.props;

        const { idUser } = this.state;

        if (user?.idUser && user.idUser !== idUser) {
            this.setState({ idUser: user.idUser });
            this.chargeData();
        }

        return (
            <Container className="container--layout align-items-center flex-grow m-0 p-0" gridClassName="form-content">
                <Col className="signature-ticket-data" lg={12} xl={12} md={12} sm={12}>
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.signature.user"
                        data={`${user.firstName} ${user.lastName}`}
                        notMarginYLabel
                        spaceBetween
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label=""
                        data={schemeName === "medium" ? this.renderMediumTicket() : this.renderAdvancedTicket()}
                        spaceBetween
                    />
                </Col>
            </Container>
        );
    }
}

export default AdministrationChannelsTicket;
