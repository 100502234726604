import React, { Component } from "react";
import { selectors as transferSelectors } from "reducers/transfer";
import { string, func, shape, bool } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import Button from "pages/_components/Button";

import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import { selectors as sessionSelectors } from "reducers/session";
import Image from "../_components/Image";

class TransferError extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        summary: shape({
            debitAccount: string.isRequired,
            creditAccount: string.isRequired,
            amount: string.isRequired,
            currency: string.isRequired,
            reference: string,
            email: string,
            emailText: string,
        }).isRequired,
        isDesktop: bool.isRequired,
    };

    componentDidMount() {
        const {
            summary: { debitAccount },
            dispatch,
        } = this.props;
        if (!debitAccount) {
            dispatch(push("/transfer"));
        }
    }

    render() {
        const { isDesktop, dispatch } = this.props;

        const continueButton = (
            <Col lg={2} md={4} sm={12} className="mt-4">
                <Button
                    type="button"
                    bsStyle="primary"
                    label="deposits.constitute.confirmation.goDesktop.button"
                    onClick={() => dispatch(push(`/desktop`))}
                />
            </Col>
        );
        return (
            <>
                <div className="admin-detail-head px-0">
                    <Head
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                </div>
                <MainContainer>
                    <div className="above-the-fold">
                        <Container
                            className={`flex-grow align-items-center cmf-container-white py-8${
                                !isDesktop ? "mb-0" : ""
                            }`}
                            gridClassName="form-content">
                            <div className="text-center no-more-data" key="noMoreMovements">
                                <div className="illustration-wrapper">
                                    <Image src="images/iconRedError.svg" className="svg-small-icon" />
                                </div>
                            </div>
                            <br />
                            <div className="admin-content-center" style={{ marginBottom: isDesktop ? "2rem" : "1rem" }}>
                                <I18n id="transfers.summary.error.label1" component="h4" />
                                <I18n id="transfers.summary.error.label2" component="h4" />
                                <br />
                                <I18n id="transfers.summary.error.label3" component="h4" />
                            </div>
                            <Col className="col-12 col-xl-4" lg={4} md={9} sm={12} />
                        </Container>
                        {isDesktop ? (
                            <Container
                                className="flex-grow align-items-center"
                                gridClassName="form-content"
                                style={{ paddingLeft: 0 }}>
                                {continueButton}
                            </Container>
                        ) : (
                            <Container
                                className=" container--layaut flex-grow align-items-center cmf-container-white without-margin-top"
                                gridClassName="form-content"
                                style={{ width: "100%" }}>
                                <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                    <Col className="col-12 col-xl-4" lg={4} md={9} sm={12} />
                                    {continueButton}
                                </Col>
                            </Container>
                        )}
                    </div>
                </MainContainer>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    summary: transferSelectors.getSummary(state),
    userId: sessionSelectors.getUser(state).userId,
});

export default compose(connect(mapStateToProps))(TransferError);
