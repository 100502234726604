import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import IPRestriction from "pages/administration/restrictions/IPRestriction";
import Button from "pages/_components/Button";
import { Form, withFormik } from "formik";
import { compose } from "redux";
import { number, func, shape, string, bool } from "prop-types";
import * as i18nUtils from "util/i18n";
import { actions as restrictionActions, selectors as restrictionSelectors } from "reducers/administration/restrictions";
import { selectors as sessionSelectors } from "reducers/session";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import { routerActions } from "react-router-redux/actions";
import Head from "pages/_components/Head";

class Restrictions extends Component {
    static propTypes = {
        days: number.isRequired,
        dispatch: func.isRequired,
        activeEnvironmentId: shape({}).isRequired,
        idUser: string,
        values: shape({}),
        history: shape({}).isRequired,
        timeZones: shape([]),
        fetching: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
        userName: string,
    };

    static defaultProps = {
        idUser: undefined,
        values: {},
        timeZones: [],
        userName: "",
    };

    state = {
        buttonEnabled: true,
        changeSwitch: false,
    };

    componentDidMount() {
        this.preLoadInfo();
    }

    componentDidUpdate(prevProps) {
        const { idUser } = this.props;
        if (prevProps.idUser !== idUser) {
            this.preLoadInfo();
        }
    }

    setButtonEnabled = (value) => {
        this.setState({ buttonEnabled: value });
    };

    setOnChangeSwitch = (value) => {
        this.setState({ changeSwitch: value });
    };

    onCancelClick = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    preLoadInfo() {
        const { dispatch, timeZones, idUser } = this.props;
        dispatch(restrictionActions.setInitialState());

        if (timeZones.length === 0) {
            dispatch(restrictionActions.manageRestrictionsPre());
        }
        dispatch(restrictionActions.getRestrictionsAvailability(idUser));
        if (typeof idUser === "undefined") {
            dispatch(restrictionActions.getEnvironmentRestrictionsData());
        } else {
            dispatch(restrictionActions.getUserRestrictionsData(idUser));
        }
    }

    render() {
        const { idUser, fetching, activeEnvironment, userName, values } = this.props;
        const { buttonEnabled, changeSwitch } = this.state;
        const isEnvironmentRestrictions = typeof idUser === "undefined";
        const stateButton = isEnvironmentRestrictions ? buttonEnabled : !values.anyIP;
        const title = isEnvironmentRestrictions
            ? `${i18nUtils.get("administration.restrictions.environmentRestrictions.title")} ${activeEnvironment.name}`
            : `${i18nUtils.get("administration.restrictions.userRestrictions.title")} ${userName}`;
        return (
            <>
                <Notification scopeToShow="restrictions" />
                <PageLoading loading={fetching} className="screen-loader">
                    <div className="admin-detail-head admin-detail-head-section px-0">
                        <Head
                            onBack={this.handleBack}
                            accessibilityTextId={
                                isEnvironmentRestrictions
                                    ? "administration.restrictions.environmentRestrictions.title"
                                    : "administration.restrictions.userRestrictions.title"
                            }
                        />
                        <div className="mb-4">
                            <div className="view-title">
                                <h1>{title}</h1>
                            </div>
                        </div>
                    </div>
                    <MainContainer viewContentClassName="pt-0" showLoader={fetching} loadingClassName="screen-loader">
                        <Form className="above-the-fold">
                            <Container className="flex-grow">
                                <div style={{ width: "100%", marginBottom: "3rem" }}>
                                    <IPRestriction
                                        {...this.props}
                                        setButtonEnabled={this.setButtonEnabled}
                                        setOnChangeSwitch={this.setOnChangeSwitch}
                                        idUser={idUser}
                                    />
                                </div>
                            </Container>
                            <Container className="container--layout flex-grow">
                                <Col md={9} lg={6} className="col col-12 max-xl-480 px-0">
                                    <Button
                                        block={false}
                                        className="btn btn-primary btn-block"
                                        label="global.continue"
                                        type="submit"
                                        disabled={
                                            (!values.iPEnabled && !changeSwitch) ||
                                            (stateButton && !values.ipsList.length > 0 && values.iPEnabled)
                                        }
                                    />
                                </Col>
                            </Container>
                        </Form>
                    </MainContainer>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state, { match }) => ({
    days: restrictionSelectors.getDays(state),
    startTime: restrictionSelectors.getStartTime(state),
    endTime: restrictionSelectors.getEndTime(state),
    timeZone: restrictionSelectors.getTimeZone(state),
    perpetual: restrictionSelectors.getPerpetual(state),
    ipsList: restrictionSelectors.getIpsList(state),
    restrictions: restrictionSelectors.getRestrictions(state),
    timeZones: restrictionSelectors.getTimezones(state),
    fetching: restrictionSelectors.getFetching(state),
    anyIP: restrictionSelectors.getAnyIP(state),
    calendarRestrictionId: restrictionSelectors.getCalendarRestrictionId(state),
    activeEnvironmentId: sessionSelectors.getActiveEnvironment(state).id,
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    idUser: match.params.userId,
    calendarEnabled: restrictionSelectors.getCalendarEnabled(state),
    iPEnabled: restrictionSelectors.getIPEnabled(state),
    userName: restrictionSelectors.getUserName(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: (props) => {
            const { userId } = props.match.params;
            const allDays = props.days === 127;

            return {
                days: props.days,
                startTime: props.startTime,
                endTime: props.endTime,
                timeZone: props.timeZone,
                perpetual: props.perpetual,
                ipsList: props.ipsList,
                anyIP: props.anyIP,
                calendarEnabled: props.calendarEnabled,
                iPEnabled: props.iPEnabled,
                idUser: userId,
                allDays,
            };
        },

        handleSubmit: (fields, formikBag) => {
            const { dispatch, activeEnvironmentId, activeEnvironment, userName } = formikBag.props;
            const dataToSave = {
                ...fields,
                environmentId: activeEnvironmentId,
                nameEnviroment: activeEnvironment.name,
                userName,
            };
            if (!dataToSave.idUser) {
                dataToSave.perpetual = false;
            }
            dispatch(restrictionActions.manageRestrictionsConfirmPre(dataToSave, formikBag));
        },
    }),
)(Restrictions);
