import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { channelsSelectors, channelsTicketSelectors } from "reducers/administration";
import { shape, objectOf, string, func } from "prop-types";
import { selectors } from "reducers/administration/common/administrationTicket";
import { detailsActions } from "reducers/administration/advanced";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";
import UpdateUserTicketData from "pages/administration/_components/UpdateUserTicketData";

class UpdateUserTicket extends Component {
    static propTypes = {
        transactionData: shape({
            data: objectOf(string),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
        dispatch: func.isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
    };

    render() {
        return (
            <AdministrationTicket {...this.props}>
                <UpdateUserTicketData />
            </AdministrationTicket>
        );
    }
}

const mapStateToProps = (state) => ({
    user: channelsSelectors.getUser(state),
    fetching: channelsTicketSelectors.isFetching(state),
    transactionData: selectors.getData(state),
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(detailsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserTicket);
