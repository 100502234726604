import React, { Component } from "react";
import { func, bool, shape } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";

import Col from "react-bootstrap/lib/Col";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";

import PaymentAFIPSummaryData from "pages/servicePayments/_components/PaymentAFIPSummaryData";

import * as i18nUtils from "util/i18n";
import isTokenActive from "util/token";

const FORM_ID = "delete.paymentAFIP.summary";

class DeletePaymentAFIPSummary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        paymentAFIP: shape({}).isRequired,
        fetching: bool.isRequired,
        isReturnFromDetail: bool.isRequired,
    };

    handleBack = () => {
        const { dispatch, isReturnFromDetail } = this.props;
        dispatch(paymentsAFIPActions.setIsBackFromOtherPage(isReturnFromDetail));
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: "-0.5rem 2rem" }}>{i18nUtils.get(`${FORM_ID}.label`)}</h1>
        </div>
    );

    render() {
        const { isDesktop, isSubmitting, paymentAFIP, fetching } = this.props;

        const summary = paymentAFIP ? { paymentAFIP } : null;

        const continueButton = (
            <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                <div className="admin-content-center">
                    <Button
                        type="submit"
                        bsStyle="primary"
                        label="global.confirm"
                        image="images/arrowRight.svg"
                        imageStyle={isDesktop ? "mr-2" : "mr-1"}
                        loading={isSubmitting}
                        className="text-capitalize"
                    />
                </div>
            </Col>
        );

        const credentials = (
            <>
                {!isTokenActive(!isDesktop) ? (
                    <div className="admin-content-center VEP-confirmation-label">
                        <I18n id="paymentAFIP.summary.confirm.label" />
                    </div>
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}
                <div className="admin-content-center VEP-confirmation-input">
                    <Field
                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </>
        );

        return (
            <PageLoading loading={fetching}>
                <Notification scopeToShow="deletePaymentAFIPSummary" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && <h1>{i18nUtils.get(`${FORM_ID}.label`)}</h1>}
                </div>
                <MainContainer>
                    <Form className="above-the-fold">
                        <Container
                            className={`flex-grow align-items-center container-white with-margin ${
                                !isDesktop ? "mb-0" : ""
                            }`}
                            gridClassName="form-content">
                            <div
                                className={`admin-content-center ${!isDesktop && "mx-3"}`}
                                style={{ marginBottom: isDesktop ? "2rem" : "1rem" }}>
                                <I18n id={`${FORM_ID}.checkInfo.label`} component="h2" />
                            </div>

                            <Col
                                className={`${isDesktop ? "col-12 col-xl-4" : "paypayment-afip-summary"}`}
                                lg={4}
                                md={9}
                                sm={12}>
                                <PaymentAFIPSummaryData isDesktop={isDesktop} isAPay={false} summary={summary} />
                                {credentials}
                                {continueButton}
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    paymentAFIP: paymentsAFIPSelectors.getPaymentAFIP(state),
    fetching: paymentsAFIPSelectors.getFetching(state),
    userId: sessionSelectors.getUser(state).userId,
    isReturnFromDetail: paymentsAFIPSelectors.getIsReturnFromDetail(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, paymentAFIP } = formikBag.props;
            const summary = { ...paymentAFIP, formikBag };
            dispatch(paymentsAFIPActions.deletePaymentAFIP(summary, otp));
        },
    }),
)(DeletePaymentAFIPSummary);
