// Opera 8.0+
export const isOpera = !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== "undefined";

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari = navigator.userAgent.match(/\bVersion\/[\d.]+\sSafari\b/);

// Internet Explorer 6-11
export const isIE = /* @cc_on!@ */ false || !!document.documentMode;

// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!window.CSS;
