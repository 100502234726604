import React, { Component } from "react";
import { Field, Form } from "formik";
import { arrayOf, func, shape, bool, string, oneOf } from "prop-types";
import { Route } from "react-router-dom";
import { Col } from "react-bootstrap";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";

import * as i18nUtils from "util/i18n";
import { actions as navigationActions } from "reducers/administration/common/navigation";

import Container from "pages/_components/Container";
import TextField from "pages/_components/fields/TextField";
import GroupFormWizardSteps from "pages/administration/_components/groupForm/WizardSteps";
import GroupFormWizardTabs from "pages/administration/_components/groupForm/WizardTabs";
import Button from "pages/_components/Button";
import Wizard from "pages/_components/Wizard";
import PermissionsPanel from "pages/administration/_components/advancedPermissionsForm/PermissionsPanel";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import AdvancedPermissionsForm from "pages/administration/_components/AdvancedPermissionsForm";
import ConfirmationInfo from "./ConfirmationInfo";
import GroupMembers from "./GroupMembers";

class GroupForm extends Component {
    static propTypes = {
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        handleSubmit: func.isRequired,
        isSubmitting: bool.isRequired,
        idForm: string.isRequired,
        mode: oneOf(["view", "edit"]),
        buttonText: string.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
        actions: shape({
            updateHasClose: func,
            updateHasBack: func,
            reset: func,
        }).isRequired,
    };

    static defaultProps = {
        mode: "edit",
    };

    componentDidMount() {
        this.updateCloseBackButtonsVisibility();
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
        } = this.props;

        if (prevProps.location.pathname !== pathname) {
            this.updateCloseBackButtonsVisibility();
        }
    }

    updateCloseBackButtonsVisibility = () => {
        const {
            actions,
            location: { pathname },
        } = this.props;
        actions.reset();
        if (pathname.match(/create\/step/)) {
            actions.updateHasClose(true);
        } else if (pathname.match(/create\/confirm/)) {
            actions.updateHasBack(true);
            actions.updateHasClose(true);
        } else if (pathname.match(/group\/\d+\/step/)) {
            actions.updateHasClose(true);
        } else if (pathname.match(/group\/\d+\/confirm/)) {
            actions.updateHasBack(true);
            actions.updateHasClose(true);
        }
    };

    validateName = (value) => {
        const { idForm } = this.props;

        if (!value) {
            return i18nUtils.get(`${idForm}.name.required`);
        }

        return undefined;
    };

    render() {
        const {
            credentialGroups,
            handleSubmit,
            isSubmitting,
            idForm,
            mode,
            buttonText,
            location,
            ...props
        } = this.props;

        const confirmStep1Path =
            location?.pathname === "/administration/advanced/group/create/confirm/step1" ||
            location?.pathname === "/administration/advanced/group/create/confirm/step3";

        const editingGroup = props.initialValues.name !== "";
        return (
            <Form className={confirmStep1Path ? "confirmation__container" : "above-the-fold"}>
                <Wizard>
                    <Container
                        className={
                            confirmStep1Path
                                ? `align-items-center flex-grow px-0 container--layout ${!confirmStep1Path &&
                                      "with-margin"} ${confirmStep1Path && "mb-0 mt-0"}`
                                : "flex-grow px-0"
                        }
                        gridClassName={confirmStep1Path ? "confirmation__form confirmation__box mb-0 pt-0" : null}>
                        {mode === "view" && credentialGroups && (
                            <Col
                                sm={12}
                                className={`col-12 container-edit-name-group px-0 ${confirmStep1Path && "py-0"}`}>
                                <Container className="container--layout flex-grow cmf-container px-0">
                                    <Col className="col-12 px-0">
                                        <ConfirmationInfo
                                            idForm={idForm}
                                            editingGroup={editingGroup}
                                            values={props.values}
                                            location={location}
                                        />
                                    </Col>
                                </Container>
                            </Col>
                        )}
                        {mode !== "view" && !credentialGroups && (
                            <>
                                <Col sm={12} className="col-12 container-edit-name-group p-3">
                                    <Container className=" flex-grow">
                                        <Col className="col-12" sm={12}>
                                            <div>
                                                {!editingGroup ? (
                                                    <h2 className="header-create-group-info">
                                                        {i18nUtils.get(`${idForm}.title.type.data.create.group`)}
                                                    </h2>
                                                ) : (
                                                    <h2 className="header-create-group-info">
                                                        {i18nUtils.get(`${idForm}.title.type.data.edit.group`)}
                                                    </h2>
                                                )}
                                            </div>
                                        </Col>
                                    </Container>
                                    <Container className="flex-grow">
                                        <Col className="edit-group col-12 max-xl-480 px-0 asd" md={9} lg={6}>
                                            <div>
                                                <Field
                                                    name="name"
                                                    idForm={idForm}
                                                    component={TextField}
                                                    mode={mode}
                                                    validate={this.validateName}
                                                    hidePlaceholder
                                                />
                                            </div>
                                        </Col>
                                    </Container>
                                </Col>
                                <div className="col-12 container-tabs-group mb-2 px-0">
                                    <GroupFormWizardTabs idForm={idForm} mode={mode} formProps={props} />
                                </div>
                            </>
                        )}
                    </Container>
                    <Route
                        render={({ location }) => (
                            <PermissionsPanel
                                key={location.pathname}
                                mode={location.pathname.indexOf("confirm") === -1 ? "edit" : "view"}
                                render={(content) => (
                                    <Container
                                        className={
                                            confirmStep1Path
                                                ? "align-items-center flex-grow px-0 container--layout with-margin mt-0"
                                                : "flex-grow container-edit-group"
                                        }
                                        gridClassName={
                                            confirmStep1Path ? "confirmation__form confirmation__box mt-0 pt-0" : null
                                        }>
                                        <Container
                                            className={
                                                location.pathname.indexOf("/step2") !== -1
                                                    ? "container--layout edit-group flex-grow px-0"
                                                    : "container--layout edit-group flex-grow px-0"
                                            }>
                                            <Container.Column
                                                className={
                                                    location.pathname.indexOf("/step2") !== -1 &&
                                                    mode !== "view" &&
                                                    !credentialGroups
                                                        ? "mr-auto col-sm-12 col-md-12 col-lg-12"
                                                        : "col-12 px-0"
                                                }>
                                                <Container.ColumnBody>
                                                    {mode !== "view" &&
                                                        !credentialGroups &&
                                                        (location.pathname.indexOf("/step2") !== -1 ? (
                                                            <GroupFormWizardSteps
                                                                idForm={idForm}
                                                                mode={mode}
                                                                formProps={props}
                                                                location={location}
                                                            />
                                                        ) : (
                                                            <div className="d-flex justify-content-center background-white new-group-description">
                                                                <GroupFormWizardSteps
                                                                    idForm={idForm}
                                                                    mode={mode}
                                                                    formProps={props}
                                                                    isStep2
                                                                    location={location}
                                                                />
                                                            </div>
                                                        ))}
                                                    {mode === "view" && credentialGroups && (
                                                        <>
                                                            <AdvancedPermissionsForm
                                                                location={location}
                                                                {...props}
                                                                mode={mode}
                                                            />
                                                            <div>
                                                                <GroupMembers
                                                                    idForm={idForm}
                                                                    mode={mode}
                                                                    className="text-uppercase"
                                                                    location={location}
                                                                />

                                                                <AdministrationFormConfirmation
                                                                    credentialGroups={credentialGroups}
                                                                    onSubmit={handleSubmit}
                                                                    renderOutOfFormik
                                                                    MDcolumnWidth={12}
                                                                    LGcolumnWidth={12}
                                                                    buttonLabel="global.confirm"
                                                                    buttonImage="images/arrowRight.svg"
                                                                    buttonImageClass="mr-2"
                                                                    formContainerClassName="confirmation__container"
                                                                    location={location}
                                                                    classNameCredentialsText="px-0"
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </Container.ColumnBody>
                                            </Container.Column>
                                            {content && location.pathname.indexOf("/step2") !== -1 && (
                                                <Container.Column md={12} sm={12} lg={12}>
                                                    <Container.ColumnBody>{content}</Container.ColumnBody>
                                                </Container.Column>
                                            )}
                                            <>
                                                {confirmStep1Path ? (
                                                    <Button
                                                        type="submit"
                                                        bsStyle="primary"
                                                        label="global.confirm"
                                                        loading={isSubmitting}
                                                        className="fullWidth-button"
                                                        image="images/arrowRight.svg"
                                                        imageStyle="mr-2"
                                                    />
                                                ) : null}
                                            </>
                                        </Container>
                                    </Container>
                                )}
                            />
                        )}
                    />
                </Wizard>
                {!confirmStep1Path && (
                    <Container className="container--layout align-items-center" gridClassName="form-content">
                        <Container.Column className="col-12 max-xl-480 px-0" md={9} lg={6}>
                            <Container.ColumnBody>
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="administration.advanced.group.create.button.confirm.text"
                                    loading={isSubmitting}
                                    className="fullWidth-button"
                                />
                            </Container.ColumnBody>
                        </Container.Column>
                    </Container>
                )}
            </Form>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(navigationActions, dispatch),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(GroupForm);
