import React, { Component } from "react";

import formField from "pages/forms/_components/_fields/_commons/formField";

class Horizontalrule extends Component {
    render() {
        if (this.props.editing) {
            return <hr />;
        }
        return null;
    }
}

export default formField({ pureRender: true })(Horizontalrule);
