import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, replace } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";

import { actions as loginActions } from "reducers/login";
import { actions as onboardingActions, selectors as onboardingSelectors } from "reducers/onboarding";
import { actions as notificationActions } from "reducers/notification";
import * as cameraUtils from "util/camera";
import * as i18nUtils from "util/i18n";

import Webcam from "pages/_components/Webcam";
import withExchangeToken from "pages/_components/withExchangeToken";
import Container from "pages/_components/Container";
import { bool, func, string } from "prop-types";
import Head from "pages/_components/Head";
import { Mixpanel } from "util/clickstreaming";
import { MAIN_ONBOARDING_ROUTE } from "./Step0";
import OnboardingStepper, { orientations } from "./_components/OnboardingStepper";

class Step3 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        documentType: string,
        isMobile: bool,
        isMobileNative: bool,
        exchangeToken: string,
    };

    static defaultProps = {
        documentType: "",
        isMobile: false,
        isMobileNative: false,
        exchangeToken: null,
    };

    state = {
        onBackClick: false,
    };

    componentDidMount() {
        const { dispatch, documentType } = this.props;

        if (!documentType) {
            dispatch(replace(MAIN_ONBOARDING_ROUTE));
        }
    }

    componentWillUnmount() {
        const { onBackClick } = this.state;
        const { isMobileNative } = this.props;

        if (isMobileNative && !onBackClick) {
            cameraUtils.stop();
        }

        this.setState({ onBackClick: false });
    }

    handleTakePicture = (picture) => {
        const { dispatch, exchangeToken } = this.props;

        Mixpanel.track("onboarding.step3");
        dispatch(onboardingActions.takePicture("step3", null, picture, exchangeToken));
    };

    handleTakePictureError = () => {
        const { dispatch } = this.props;

        dispatch(onboardingActions.takePictureError());
        dispatch(
            notificationActions.showNotification(i18nUtils.get("onboarding.takePicture.error"), "error", [
                "onboarding",
            ]),
        );
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        this.setState({ onBackClick: true });

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { isMobile, isMobileNative } = this.props;

        return (
            <Fragment>
                <Head
                    title="onboarding.step3.title"
                    note={i18nUtils.get("onboarding.step3.note")}
                    onClose={isMobile && this.onHeaderClose}
                />
                <div className="view-page">
                    {!isMobile && <OnboardingStepper currentStep={3} className="onboarding-steps" />}

                    <div className="view-content">
                        <main className="main-container">
                            <div className="above-the-fold">
                                <Container className="container--layout flex-grow align-items-center">
                                    <Col className="col col-12">
                                        <Webcam
                                            direction={cameraUtils.directions.FRONT}
                                            handleTakePicture={this.handleTakePicture}
                                            handleTakePictureError={this.handleTakePictureError}
                                            isMobile={isMobile}
                                            isMobileNative={isMobileNative}
                                            selfie
                                        />
                                    </Col>
                                </Container>
                                {isMobile && (
                                    <Container className="container--layout  align-items-center">
                                        <Col className="col col-12">
                                            <OnboardingStepper currentStep={3} orientation={orientations.horizontal} />
                                        </Col>
                                    </Container>
                                )}
                            </div>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    documentType: onboardingSelectors.getDocumentType(state),
    exchangeToken: onboardingSelectors.getExchangeToken(state),
    fetching: onboardingSelectors.getFetching(state),
});

export default connect(mapStateToProps)(withExchangeToken(Step3));
