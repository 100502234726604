import React, { Component } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { routerActions } from "react-router-redux/actions";
import Redirect from "react-router-dom/Redirect";
import { func, arrayOf, oneOf, oneOfType, shape, string, number } from "prop-types";

import { actions, selectors } from "reducers/administration/groups";
import { actions as formActions } from "reducers/form";

import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class GroupActionConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        groupsToApplyAction: arrayOf(oneOfType([number, string])).isRequired,
        groupsNamesToApplyAction: arrayOf(string).isRequired,
        groupAction: oneOf(["block", "unblock", "delete"]).isRequired,
        idTransaction: string,
        idActivity: string,
    };

    static defaultProps = {
        idTransaction: null,
        idActivity: null,
    };

    handleSubmit = (credentials, formikBag) => {
        const {
            groupsToApplyAction,
            groupsNamesToApplyAction,
            groupAction,
            idTransaction,
            idActivity,
            dispatch,
        } = this.props;

        if (!idTransaction) {
            dispatch(
                actions.changeGroupsStatusConfirmation(
                    groupsToApplyAction,
                    groupsNamesToApplyAction,
                    groupAction,
                    credentials,
                    formikBag,
                ),
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
        }
    };

    handleClose = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    render() {
        const { credentialGroups, groupsToApplyAction, groupsNamesToApplyAction, groupAction } = this.props;

        if (!groupsToApplyAction || groupsToApplyAction.length === 0) {
            return <Redirect to="/administration/groups" />;
        }

        const groupsText = groupsNamesToApplyAction.map((groupName) => (
            <span className="chip" key={`administration.groups.${groupName}`}>
                {" "}
                {groupName}{" "}
            </span>
        ));

        const titleKey = `administration.groups.action.title.${groupAction}`;

        return (
            <>
                <Notification scopeToShow="administration" />
                <div className="admin-detail-head admin-detail-head-section px-0">
                    <Head onBack={this.handleBack} />
                    <div className="mb-4">
                        <I18n component="h1" componentProps={{ className: "view-title" }} id={titleKey} />
                    </div>
                </div>
                <MainContainer viewContentClassName="confirmation__container pt-0">
                    <Container
                        className="align-items-center flex-grow px-0 container--layout with-margin mb-0"
                        gridClassName="confirmation__form confirmation__box">
                        <Container className="align-items-center flex-grow admin-action" gridClassName="form-content">
                            <Col className="col-12" lg={7} md={9} sm={12}>
                                <div className="transfer-block">
                                    <I18n
                                        component="h2"
                                        componentProps={{ className: "form-title text-center mt-4 mb-0" }}
                                        id={`administration.groups.action.confirmation.${groupAction}`}
                                    />
                                </div>
                                <div className="transfer-block text-center mt-0">
                                    <div className="inline-grid">{groupsText}</div>
                                </div>
                            </Col>
                        </Container>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            MDcolumnWidth={12}
                            LGcolumnWidth={12}
                            buttonLabel="global.confirm"
                            buttonImage="images/arrowRight.svg"
                            buttonImageClass="mr-2"
                            formContainerClassName="confirmation__container"
                        />
                    </Container>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    groupAction: selectors.getGroupAction(state),
    groupsToApplyAction: selectors.getGroupsToApplyAction(state),
    groupsNamesToApplyAction: selectors.getGroupsNamesToApplyAction(state),
    credentialGroups: selectors.getCredentialGroups(state),
    idTransaction: selectors.getIdTransaction(state),
    idActivity: selectors.getIdActivity(state),
});

export default connect(mapStateToProps)(GroupActionConfirmation);
