import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, func, bool } from "prop-types";

import { selectors as ecomexSelectors, actions as ecomexActions } from "reducers/ecomex";

import * as i18nUtils from "util/i18n";

import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Notification from "pages/_components/Notification";
import ProductDetail from "pages/_components/ProductDetail";
import PageLoading from "pages/_components/PageLoading";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import InfoTag from "pages/_components/InfoTag";

import EcomexOperationsDetailList from "./EcomexOperationsDetailList";

const FORM_ID = "ecomex.consultOperations.detail";

class EcomexOperationsDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        selectedOperation: shape({}).isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(ecomexActions.closeDetail());
    };

    renderContent = () => {
        const { isDesktop, selectedOperation } = this.props;
        const { fechaOrigen, fechaVencimiento, nombreBeneficiario, siglaDivisa, importe, saldo } = selectedOperation;
        const className = "align-items-center flex-grow container-white with-margin py-2";

        const balance = saldo || "-";

        if (isDesktop) {
            return (
                <Container className={className}>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id={`${FORM_ID}.openDate`} />
                            <span className="data-text">{fechaOrigen}</span>
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id={`${FORM_ID}.dueDate`} />
                            <span className="data-text">{fechaVencimiento}</span>
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id={`${FORM_ID}.beneficiaryName`} />
                            <span className="data-text">{nombreBeneficiario}</span>
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id={`${FORM_ID}.currency`} />
                            <span className="data-text">{siglaDivisa}</span>
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id={`${FORM_ID}.quantity`} />

                            <FormattedAmount noCurrency className="data-amount" quantity={importe} notBold />
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id={`${FORM_ID}.amount`} />
                            <FormattedAmount noCurrency className="data-amount" quantity={balance} notBold />
                        </div>
                    </Col>
                </Container>
            );
        }

        const containerClassName = `transfer-data data-wrapper-flex ${!isDesktop ? "space-between mb-0 my-1" : ""}`;
        const labelClassName = isDesktop ? "mb-0" : "";
        return (
            <Container className="align-items-center flex-grow container-white py-2">
                <Col sm={12} md={4} lg={4} className="col col-12">
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label={`${FORM_ID}.openDate`}
                        data={fechaOrigen}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label={`${FORM_ID}.dueDate`}
                        data={fechaVencimiento}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between my-1"
                        labelClassName={`${labelClassName} financing-beneficiary-label`}
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.beneficiaryName`}
                        data={nombreBeneficiario}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label={`${FORM_ID}.currency`}
                        data={siglaDivisa}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label={`${FORM_ID}.quantity`}
                        data={<FormattedAmount noCurrency className="data-amount" quantity={importe} notBold />}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label={`${FORM_ID}.amount`}
                        data={<FormattedAmount noCurrency className="data-amount" quantity={balance} notBold />}
                    />
                </Col>
            </Container>
        );
    };

    statusTagColorBackgroud = (state) => {
        switch (state) {
            case "1":
                return "#dddddd";
            case "2":
                return "#feb0af";
            default:
                return "#feb0af";
        }
    };

    statusTagColor = (state) => {
        switch (state) {
            case "1":
                return "black";
            case "2":
                return "white";
            default:
                return "white";
        }
    };

    renderHeader = () => {
        const { selectedOperation, isDesktop } = this.props;
        const {
            siglaOperacion,
            sucursalOperacion,
            numeroOperacion,
            tipoConsulta,
            subTipoDescripcion,
        } = selectedOperation;
        return (
            <>
                {isDesktop ? (
                    <>
                        <div className="view-title data-wrapper-flex align-items-center">
                            <h1 className="my-2 mr-3">
                                {`${i18nUtils.get(
                                    "ecomex.consultOperations.detail.ecomexState",
                                )} ${siglaOperacion}-${sucursalOperacion}-${numeroOperacion}`}
                            </h1>
                            <InfoTag
                                type="default"
                                message={i18nUtils.get(
                                    `ecomex.consultOperations.detail.ecomexStates.options.${tipoConsulta}`,
                                )}
                                tagBackground={this.statusTagColorBackgroud(tipoConsulta)}
                                color={this.statusTagColor(tipoConsulta)}
                                tagClass="px-2"
                            />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>{subTipoDescripcion.toUpperCase()}</div>
                    </>
                ) : (
                    <div className="toolbar-item view-title justify-content-center">
                        <p className="title-content header-mobile-title-background-blue">
                            {`${i18nUtils.get("ecomex.consultOperations.detail.ecomexState")}`}
                        </p>
                        <p className="title-content header-mobile-title-background-blue">
                            {siglaOperacion}-{sucursalOperacion}-{numeroOperacion}
                        </p>
                    </div>
                )}
            </>
        );
    };

    render() {
        const { selectedOperation, isDesktop, dispatch, fetching } = this.props;
        const { tipoConsulta, subTipoDescripcion } = selectedOperation;

        return (
            <PageLoading loading={fetching}>
                {!fetching && selectedOperation && (
                    <>
                        <Notification scopeToShow="ecomex.consult.operations.details" />
                        <ProductDetail>
                            <ProductDetail.Head
                                onBack={this.handleBack}
                                dispatch={dispatch}
                                renderDownload={() => {}}
                                title="ecomex.consultOperations.detail.title">
                                {isDesktop && <div />}
                                {this.renderHeader()}
                                {!isDesktop && (
                                    <div className="view-title data-wrapper-flex align-items-center space-between my-2 flex-no-wrap">
                                        <div className="d-flex align-items-center text-left">
                                            {subTipoDescripcion.toUpperCase()}
                                        </div>
                                        <InfoTag
                                            type="default"
                                            message={i18nUtils.get(
                                                `ecomex.consultOperations.detail.ecomexStates.options.${tipoConsulta}`,
                                            )}
                                            tagBackground={this.statusTagColorBackgroud(tipoConsulta)}
                                            color={this.statusTagColor(tipoConsulta)}
                                            tagClass="flex-end px-2"
                                        />
                                    </div>
                                )}
                            </ProductDetail.Head>
                            <ProductDetail.Body isDesktop={isDesktop} moreFilters={false}>
                                {this.renderContent()}
                                <EcomexOperationsDetailList isDesktop={isDesktop} />
                            </ProductDetail.Body>
                        </ProductDetail>
                    </>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: ecomexSelectors.getFetching(state),
    selectedOperation: ecomexSelectors.getSelectedOperation(state),
});

export default connect(mapStateToProps)(EcomexOperationsDetail);
