import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { arrayOf, bool, func, shape, string } from "prop-types";

import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as settingsActions } from "reducers/settings";
import * as i18n from "util/i18n";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import { getArray } from "util/config";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";

class LanguageSelection extends Component {
    static propTypes = {
        activeLanguage: string.isRequired,
        dispatch: func.isRequired,
        history: shape({
            goBack: func,
        }),
        propsGrid: shape({}).isRequired,
        supportedLanguages: arrayOf(string).isRequired,
        legendTextID: string,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        history: null,
        legendTextID: "settings.changeLanguage",
    };

    handleClick = (language) => {
        const { activeLanguage, dispatch } = this.props;

        if (language !== activeLanguage) {
            document.documentElement.lang = language;
            dispatch(settingsActions.changeLanguage(language, false));
        }
    };

    backButtonAction = () => {
        const { history } = this.props;
        history.goBack();
    };

    renderCenterElement = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", margin: 0 }}>{i18n.get("settings.changeLanguage")}</h1>
        </div>
    );

    render() {
        const { activeLanguage, supportedLanguages, legendTextID, isDesktop, propsGrid } = this.props;

        return (
            <GridLayoutExternal scopeToShow="changeLanguage" isDesktop={isDesktop} {...propsGrid}>
                <Head
                    title={isDesktop ? "settings.changeLanguage" : null}
                    onClose={isDesktop ? this.backButtonAction : undefined}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                    centerElement={!isDesktop ? this.renderCenterElement : undefined}
                    onBack={!isDesktop ? this.backButtonAction : undefined}
                />
                <MainContainer>
                    <div className="above-the-fold">
                        <section className="container--layout flex-grow align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                        <fieldset className="form-group">
                                            {legendTextID && (
                                                <I18n
                                                    id={legendTextID}
                                                    component="legend"
                                                    componentProps={{ className: "visually-hidden" }}
                                                />
                                            )}
                                            <div className="selection-list">
                                                {Object.keys(supportedLanguages).map((idLanguage) => (
                                                    <div
                                                        className="c-control  c-control--radio"
                                                        key={`language-${idLanguage}`}>
                                                        <input
                                                            defaultChecked={
                                                                supportedLanguages[idLanguage] === activeLanguage
                                                                    ? "checked"
                                                                    : ""
                                                            }
                                                            id={`language-${supportedLanguages[idLanguage]}`}
                                                            type="radio"
                                                            name="language"
                                                            className="c-control-input"
                                                            onClick={() =>
                                                                this.handleClick(supportedLanguages[idLanguage])
                                                            }
                                                        />
                                                        {/* eslint-disable-next-line */}
                                                        <label
                                                            className="c-control-label c-control-label--environment"
                                                            htmlFor={`language-${supportedLanguages[idLanguage]}`}>
                                                            <I18n
                                                                id={`settings.changeLanguage.label.
                                                                ${supportedLanguages[idLanguage]}`}
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </fieldset>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
    supportedLanguages: getArray("frontend.i18n.languages"),
});

export default connect(mapStateToProps)(LanguageSelection);
