import { types as productsTypes } from "reducers/products";

export const types = {
    LIST_DEPOSITS_REQUEST: "deposits/LIST_DEPOSITS_REQUEST",
    LIST_DEPOSITS_SUCCESS: "deposits/LIST_DEPOSITS_SUCCESS",
    LIST_DEPOSITS_ERROR: "deposits/LIST_DEPOSITS_ERROR",
    CHECK_HOURS_ENABLED: "deposits/CHECK_HOURS_ENABLED",
    DEPOSIT_READ_REQUEST: "deposits/DEPOSIT_READ_REQUEST",
    DEPOSIT_READ_SUCCESS: "deposits/DEPOSIT_READ_SUCCESS",
    SET_SELECTED_DEPOSIT: "deposits/SET_SELECTED_DEPOSIT",
    CLOSE_OPTIONS: "deposits/CLOSE_OPTIONS",
    DOWNLOAD_DEPOSITS_LIST_REQUEST: "deposits/DOWNLOAD_DEPOSITS_LIST_REQUEST",
    DOWNLOAD_DEPOSITS_LIST_FAILURE: "deposits/DOWNLOAD_DEPOSITS_LIST_FAILURE",
    DOWNLOAD_DEPOSITS_LIST_SUCCESS: "deposits/DOWNLOAD_DEPOSITS_LIST_SUCCESS",
    DOWNLOAD_DEPOSIT_DETAIL_REQUEST: "deposits/DOWNLOAD_DEPOSIT_DETAIL_REQUEST",
    DOWNLOAD_DEPOSIT_DETAIL_FAILURE: "deposits/DOWNLOAD_DEPOSIT_DETAIL_FAILURE",
    DOWNLOAD_DEPOSIT_DETAIL_SUCCESS: "deposits/DOWNLOAD_DEPOSIT_DETAIL_SUCCESS",
    SIMULATE_DEPOSIT_PREVIEW_REQUEST: "deposits/SIMULATE_DEPOSIT_PREVIEW_REQUEST",
    SIMULATE_DEPOSIT_PREVIEW_FAILURE: "deposits/SIMULATE_DEPOSIT_PREVIEW_FAILURE",
    SIMULATE_DEPOSIT_PREVIEW_SUCCESS: "deposits/SIMULATE_DEPOSIT_PREVIEW_SUCCESS",
    SIMULATE_DEPOSIT_REQUEST: "deposits/SIMULATE_DEPOSIT_REQUEST",
    SIMULATE_DEPOSIT_FAILURE: "deposits/SIMULATE_DEPOSIT_FAILURE",
    SIMULATE_DEPOSIT_SUCCESS: "deposits/SIMULATE_DEPOSIT_SUCCESS",
    CONSTITUTE_DEPOSIT_REQUEST: "deposits/CONSTITUTE_DEPOSIT_REQUEST",
    CONSTITUTE_DEPOSIT_FAILURE: "deposits/CONSTITUTE_DEPOSIT_FAILURE",
    CONSTITUTE_DEPOSIT_SUCCESS: "deposits/CONSTITUTE_DEPOSIT_SUCCESS",
    CONFIRM_DEPOSIT_REQUEST: "deposits/CONFIRM_DEPOSIT_REQUEST",
    CONFIRM_DEPOSIT_FAILURE: "deposits/CONFIRM_DEPOSIT_FAILURE",
    CONFIRM_DEPOSIT_SUCCESS: "deposits/CONFIRM_DEPOSIT_SUCCESS",
    GO_BACK_TO_DEPOSIT_SIMULATION: "deposits/GO_BACK_TO_DEPOSIT_SIMULATION",
    DOWNLOAD_DEPOSITS_CONFIRMATION_REQUEST: "deposits/DOWNLOAD_DEPOSITS_CONFIRMATION_REQUEST",
    DOWNLOAD_DEPOSITS_CONFIRMATION_FAILURE: "deposits/DOWNLOAD_DEPOSITS_CONFIRMATION_FAILURE",
    DOWNLOAD_DEPOSITS_CONFIRMATION_SUCCESS: "deposits/DOWNLOAD_DEPOSITS_CONFIRMATION_SUCCESS",
};

export const INITIAL_STATE = {
    fetching: false,
    depositsList: [],
    isOptionsVisible: false,
    selectedDeposit: null,
    fetchingDownload: false,
    ARSTotalBalance: null,
    USDTotalBalance: null,
    loadingPreview: false,
    minAmountPesos: null,
    minAmountDolares: null,
    simulationResult: null,
    summary: null,
    holidays: [],
    hourFrom: null,
    hourTo: null,
    idTransaction: null,
    depositTypes: [],
    environmentDate: null,
    defaultDaysQuantity: 30,
    loadingSimulation: false,
    loadingConstitution: false,
    loadingConfirm: false,
    serverDate: null,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        depositsList,
        ARSTotalBalance,
        USDTotalBalance,
        minAmountPesos,
        minAmountDolares,
        debitAccount,
        depositType,
        consolidatedAmount,
        termInDays,
        dueDate,
        rate,
        capital,
        totalAmount,
        earnedInterests,
        automaticRenewal,
        actionToDue,
        profitTax,
        dateAndHour,
        voucher,
        openDate,
        requestCode,
        operationNumber,
        profitTaxCode,
        witnessRate,
        codeRate,
        holidays,
        accountNumber,
        summary,
        idTransaction,
        depositTypes,
        alias,
        environmentDate,
        idProduct,
        defaultDaysQuantity,
        serverDate,
    } = action;

    switch (action.type) {
        case types.LIST_DEPOSITS_REQUEST:
            return {
                ...state,
                isOptionsVisible: false,
                fetching: true,
                ARSTotalBalance: null,
                USDTotalBalance: null,
                loadingPreview: false,
                fetchingDownload: false,
                simulationResult: null,
            };
        case types.LIST_DEPOSITS_SUCCESS:
            return {
                ...state,
                depositsList,
                fetching: false,
                ARSTotalBalance,
                USDTotalBalance,
                serverDate,
            };
        case types.LIST_DEPOSITS_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.DEPOSIT_READ_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.DEPOSIT_READ_SUCCESS:
            return {
                ...state,
                selectedDeposit: action.deposit,
                fetching: false,
            };
        case types.CLOSE_OPTIONS:
            return {
                ...state,
                isOptionsVisible: false,
            };
        case types.DOWNLOAD_DEPOSITS_LIST_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_DEPOSITS_LIST_FAILURE:
        case types.DOWNLOAD_DEPOSITS_LIST_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_DEPOSITS_CONFIRMATION_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_DEPOSITS_CONFIRMATION_FAILURE:
        case types.DOWNLOAD_DEPOSITS_CONFIRMATION_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_DEPOSIT_DETAIL_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_DEPOSIT_DETAIL_FAILURE:
        case types.DOWNLOAD_DEPOSIT_DETAIL_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.SIMULATE_DEPOSIT_PREVIEW_REQUEST:
            return {
                ...state,
                loadingPreview: true,
                loadingSimulation: false,
                loadingConstitution: false,
                loadingConfirm: false,
            };
        case types.SIMULATE_DEPOSIT_PREVIEW_FAILURE:
            return {
                ...state,
                loadingPreview: false,
            };
        case types.SIMULATE_DEPOSIT_PREVIEW_SUCCESS:
            return {
                ...state,
                loadingPreview: false,
                minAmountPesos,
                minAmountDolares,
                holidays,
                depositTypes,
                environmentDate,
                defaultDaysQuantity,
            };
        case types.SIMULATE_DEPOSIT_REQUEST:
            return {
                ...state,
                loadingSimulation: true,
            };
        case types.SIMULATE_DEPOSIT_FAILURE:
            return {
                ...state,
                loadingSimulation: false,
            };
        case types.SIMULATE_DEPOSIT_SUCCESS:
            return {
                ...state,
                loadingSimulation: false,
                simulationResult: {
                    dueDate,
                    rate,
                    capital,
                    totalAmount,
                    earnedInterests,
                    requestCode,
                    operationNumber,
                    profitTaxCode,
                    profitTax,
                    witnessRate,
                    codeRate,
                    accountNumber,
                    debitAccount,
                },
            };
        case types.CONSTITUTE_DEPOSIT_REQUEST:
            return {
                ...state,
                loadingConstitution: true,
            };
        case types.CONSTITUTE_DEPOSIT_FAILURE:
            return {
                ...state,
                loadingConstitution: false,
            };
        case types.CONSTITUTE_DEPOSIT_SUCCESS:
            return {
                ...state,
                loadingConstitution: false,
                summary: {
                    debitAccount,
                    depositType,
                    consolidatedAmount,
                    termInDays,
                    dueDate,
                    rate,
                    capital,
                    totalAmount,
                    earnedInterests,
                    automaticRenewal,
                    actionToDue,
                    dateAndHour,
                    voucher,
                    openDate,
                    requestCode,
                    operationNumber,
                    profitTaxCode,
                    profitTax,
                    witnessRate,
                    codeRate,
                    accountNumber,
                },
                simulationResult: null,
            };
        case types.CONFIRM_DEPOSIT_REQUEST:
            return {
                ...state,
                loadingConfirm: true,
            };
        case types.CONFIRM_DEPOSIT_FAILURE:
            return {
                ...state,
                loadingConfirm: false,
            };
        case types.CONFIRM_DEPOSIT_SUCCESS:
            return {
                ...state,
                loadingConfirm: false,
                summary: { ...summary, idTransaction },
            };
        case types.GO_BACK_TO_DEPOSIT_SIMULATION:
            return {
                ...state,
                simulationResult: null,
            };

        case productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS: {
            const thereIsASelectedDeposit = !!state.selectedDeposit;
            if (thereIsASelectedDeposit) {
                const { depositsList: depositListToChange } = state;
                const newDepositList = depositListToChange.map((depositElem) => {
                    if (depositElem.idProduct === idProduct) {
                        return { ...depositElem, productAlias: alias };
                    }
                    return depositElem;
                });

                return {
                    ...state,
                    selectedDeposit: {
                        ...state.selectedDeposit,
                        productAlias: alias,
                    },
                    depositsList: newDepositList,
                };
            }
            return state;
        }
        case types.CHECK_HOURS_ENABLED:
        default:
            return state;
    }
};

export const actions = {
    listDeposits: () => ({
        type: types.LIST_DEPOSITS_REQUEST,
    }),
    readDeposit: (idDeposit) => ({
        type: types.DEPOSIT_READ_REQUEST,
        idDeposit,
    }),
    checkHours: () => ({
        type: types.CHECK_HOURS_ENABLED,
    }),
    closeOptions: () => ({
        type: types.CLOSE_OPTIONS,
    }),
    downloadList: (format) => ({
        type: types.DOWNLOAD_DEPOSITS_LIST_REQUEST,
        format,
    }),
    downloadDetail: (idDeposit, format) => ({
        type: types.DOWNLOAD_DEPOSIT_DETAIL_REQUEST,
        idDeposit,
        format,
    }),
    simulateDepositPreview: () => ({
        type: types.SIMULATE_DEPOSIT_PREVIEW_REQUEST,
    }),
    simulateDeposit: (deposit) => ({
        type: types.SIMULATE_DEPOSIT_REQUEST,
        ...deposit,
    }),
    constituteDeposit: (deposit) => ({
        type: types.CONSTITUTE_DEPOSIT_REQUEST,
        ...deposit,
    }),
    confirmDeposit: (summary, otp) => ({
        type: types.CONFIRM_DEPOSIT_REQUEST,
        summary,
        otp,
    }),
    goBackToSimulation: () => ({
        type: types.GO_BACK_TO_DEPOSIT_SIMULATION,
    }),
};

export const selectors = {
    getDeposits: ({ deposits }) => deposits.depositsList,
    getFetching: ({ deposits }) => deposits.fetching,
    getFetchingDownload: ({ deposits }) => deposits.fetchingDownload,
    getSelectedDeposit: ({ deposits }) => deposits.selectedDeposit,
    getARSTotalBalance: ({ deposits }) => deposits.ARSTotalBalance,
    getUSDTotalBalance: ({ deposits }) => deposits.USDTotalBalance,
    getLoadingPreview: ({ deposits }) => deposits.loadingPreview,
    getMinAmountPesos: ({ deposits }) => deposits.minAmountPesos,
    getMinAmountDolares: ({ deposits }) => deposits.minAmountDolares,
    getSimulationResult: ({ deposits }) => deposits.simulationResult,
    getSummary: ({ deposits }) => deposits.summary,
    getHolidays: ({ deposits }) => deposits.holidays,
    getDepositTypes: ({ deposits }) => deposits.depositTypes,
    getEnvironmentDate: ({ deposits }) => deposits.environmentDate,
    getDefaultDaysQuantity: ({ deposits }) => deposits.defaultDaysQuantity,
    getLoadingSimulation: ({ deposits }) => deposits.loadingSimulation,
    getLoadingConstitution: ({ deposits }) => deposits.loadingConstitution,
    getLoadingConfirm: ({ deposits }) => deposits.loadingConfirm,
    getServerDate: ({ deposits }) => deposits.serverDate,
};
