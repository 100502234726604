import React, { Component } from "react";
import { string, shape, arrayOf, func, oneOf, objectOf, bool } from "prop-types";

import * as i18nUtils from "util/i18n";

import Select from "pages/_components/fields/Select";
import List from "pages/_components/List";
import Image from "pages/_components/Image";

class MultiSelectGroup extends Component {
    static propTypes = {
        name: string.isRequired,
        options: shape({
            ids: arrayOf(string),
            byId: objectOf(
                shape({
                    label: string,
                    value: string,
                }),
            ),
        }).isRequired,
        values: arrayOf(string),
        noResultsText: string,
        placeholder: string,
        children: func.isRequired,
        onSelect: func.isRequired,
        onDelete: func.isRequired,
        mode: oneOf(["view", "edit"]),
        label: string.isRequired,
        labelKey: string,
        valueKey: string,
        searchable: bool,
        deletable: bool,
        renderCloseButton: func,
    };

    static defaultProps = {
        noResultsText: "global.no.results",
        placeholder: "",
        mode: "edit",
        values: [],
        labelKey: "label",
        valueKey: "value",
        searchable: true,
        deletable: true,
        renderCloseButton: null,
    };

    renderCloseButton = (option) => {
        const { renderCloseButton, onDelete } = this.props;
        if (renderCloseButton) {
            return renderCloseButton(option);
        }
        return (
            <button type="button" className="close-button" onClick={() => onDelete(option)}>
                <Image className="Select-value-icon" src="cancel_white.svg" />
            </button>
        );
    };

    render() {
        const {
            options,
            values,
            noResultsText,
            placeholder,
            children,
            onSelect,
            onDelete,
            mode,
            label,
            deletable,
            ...props
        } = this.props;
        const i18nLabel = label && i18nUtils.get(label);

        if (mode === "view") {
            return (
                <div className="form-group">
                    <div className="form-group-text">
                        <span className="control-label">{label}</span>
                    </div>
                    <div className="form-group-control-list list">
                        {values.map((id) => {
                            const { valueKey, labelKey } = this.props;
                            const option = options.byId[id];

                            return (
                                <span key={option[valueKey]} className="list-item">
                                    {option[labelKey]}
                                </span>
                            );
                        })}
                    </div>
                </div>
            );
        }

        const valuesToRender = values.filter((value) => options.ids.includes(value));
        return (
            <>
                <Select
                    onChange={onSelect}
                    inputGroupClassName="mb-0"
                    options={options.ids
                        .filter((id) => !values.some((selectedOption) => id === selectedOption))
                        .map((id) => options.byId[id])}
                    noResultsText={i18nUtils.get(noResultsText)}
                    placeholder={i18nUtils.get(placeholder)}
                    label={i18nLabel}
                    {...props}
                />
                {values.length > 0 && (
                    <div className="transfer-block list-pills">
                        {valuesToRender.map((value, index) => (
                            <List.Item key={value} num={index} className="list-item--deleteable inline-grid ">
                                {() => {
                                    const option = options.byId[value];
                                    return (
                                        <span
                                            className="gray-pill-group d-flex m-1"
                                            style={{
                                                "background-color": "#efefef",
                                            }}>
                                            {" "}
                                            {children(option)} {deletable && this.renderCloseButton(option)}{" "}
                                        </span>
                                    );
                                }}
                            </List.Item>
                        ))}
                    </div>
                )}
            </>
        );
    }
}

export default MultiSelectGroup;
