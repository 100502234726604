import React, { Component } from "react";
import { Droppable } from "react-beautiful-dnd";
import classNames from "classnames";
import { array, bool, func, string } from "prop-types";

import DraggableItem from "pages/_components/DraggableItem";

class InnerDraggableColumn extends Component {
    shouldComponentUpdate(nextProps) {
        return nextProps.items !== this.props.items;
    }

    render() {
        const { isDragEnabled, itemRenderer, items } = this.props;

        return items.map((item, index) => (
            <DraggableItem isDragEnabled={isDragEnabled} item={item} itemRenderer={itemRenderer} key={index} />
        ));
    }
}

class DraggableColumn extends Component {
    static propTypes = {
        columnId: string.isRequired,
        isDragEnabled: bool,
        itemRenderer: func.isRequired,
        items: array.isRequired,
        title: string,
    };

    static defaultProps = {
        isDragEnabled: false,
        title: null,
    };

    render() {
        const { columnId, isDragEnabled } = this.props;

        return (
            <Droppable droppableId={columnId} isDropDisabled={!isDragEnabled}>
                {(provided) => (
                    <div
                        className={classNames({ "draggable-list__item-container": isDragEnabled })}
                        ref={provided.innerRef}
                        {...provided.droppableProps}>
                        <InnerDraggableColumn {...this.props} />
                    </div>
                )}
            </Droppable>
        );
    }
}

export default DraggableColumn;
