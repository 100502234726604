/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import moment from "moment";
import { instanceOf, string, shape, func } from "prop-types";

import * as schedulerUtils from "util/scheduler";
import * as i18nUtils from "util/i18n";
import { Box, Datefield, Radio, RadioGroup, SelectField, Text } from "@ui-kit/components/index.es";

function LapseSubOption(props) {
    LapseSubOption.propTypes = {
        value: instanceOf(moment).isRequired,
        frequency: string.isRequired,
        values: shape({}).isRequired,
        onChange: func.isRequired,
    };

    const { frequency } = props;
    const options = Array.from({ length: 42 }, (_, index) => ({ value: index + 1, label: `${index + 1}` }));

    const handleOnChange = (id, data) => {
        const { value, onChange } = props;
        let newValue = null;
        if (id === schedulerUtils.NEVER) {
            newValue = { ...value, lapse: id };
        } else if (id === schedulerUtils.UNTIL) {
            newValue = { ...value, date: data || new Date(), lapse: id };
        } else if (id === schedulerUtils.UP_TO) {
            newValue = { ...value, lapse: id, number: data || 1 };
        }
        onChange(newValue);
    };

    const { values } = props;
    const { startsOn, lapse, date} = values;

    return (
        <Box mt={24} mb={48} w="100%">
            <Text variant="bodyStrong">{i18nUtils.get("scheduler.ends")}</Text>
            <Box mt={24}>
                <RadioGroup value={lapse} onChange={({ target }) => handleOnChange(target.value)}>
                    <Radio
                        id={schedulerUtils.NEVER}
                        value={schedulerUtils.NEVER}
                        label={i18nUtils.get("scheduler.never")}
                    />
                    {frequency !== schedulerUtils.YEAR && (
                        <Box flex align="center">
                            <Radio
                                id={schedulerUtils.UNTIL}
                                value={schedulerUtils.UNTIL}
                                label={i18nUtils.get("scheduler.on")}
                            />
                            <Box ml={24} w="100%">
                                <Datefield
                                    id="endDate"
                                    minDate={startsOn}
                                    startDate={startsOn}
                                    value={date}
                                    onDateChange={(newDate) => {
                                        handleOnChange(schedulerUtils.UNTIL, newDate);
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                    <Box flex align="center">
                        <Radio
                            id={schedulerUtils.UP_TO}
                            value={schedulerUtils.UP_TO}
                            label={i18nUtils.get("scheduler.up_to.a11y")}
                        />
                        <Box ml={24}>
                            <SelectField
                                defaultOption={{
                                    value: 1,
                                    label: "1",
                                }}
                                id="default"
                                onFocus={() => {}}
                                onSelectOption={(optionSelected) => handleOnChange(schedulerUtils.UP_TO, optionSelected.value)}
                                options={options}
                            />
                        </Box>
                    </Box>
                </RadioGroup>
            </Box>
        </Box>
    );
}

export default LapseSubOption;
