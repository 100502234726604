import { createReducer, makeActionCreator } from "util/redux";
import * as arrayUtils from "util/array";

const INITIAL_STATE = {
    availableUsers: [],
    adminUsers: [],
    fetching: true,
    adminGroup: false,
    formValues: {
        name: "",
        description: "",
        status: "active",
        permissions: {},
        users: [],
    },
    credentialGroups: [],
    idTransaction: null,
    idActivity: null,
};

const groupFormValues = (state, group, permissions) => {
    if (!group) {
        return state.formValues;
    }

    return {
        permissions,
        name: group.name,
        description: group.description,
        status: group.blocked ? "blocked" : "active",
        users: group.userList.map(({ idUser }) => idUser),
    };
};

export const types = {
    LOAD_GROUP_FORM_DATA_REQUEST: "administrationAdvanced/LOAD_GROUP_FORM_DATA_REQUEST",
    LOAD_GROUP_FORM_DATA_FAILURE: "administrationAdvanced/LOAD_GROUP_FORM_DATA_FAILURE",
    LOAD_GROUP_FORM_DATA_SUCCESS: "administrationAdvanced/LOAD_GROUP_FORM_DATA_SUCCESS",
    SUBMIT_CREATE_GROUP_FORM_PREVIEW_SUCCESS: "administrationAdvanced/SUBMIT_CREATE_GROUP_FORM_PREVIEW_SUCCESS",
    SUBMIT_GROUP_FORM_PREVIEW_REQUEST: "administrationAdvanced/SUBMIT_GROUP_FORM_PREVIEW_REQUEST",
    SUBMIT_GROUP_FORM_PREVIEW_SUCCESS: "administrationAdvanced/SUBMIT_GROUP_FORM_PREVIEW_SUCCESS",
    SUBMIT_GROUP_FORM_REQUEST: "administrationAdvanced/SUBMIT_GROUP_FORM_REQUEST",
    SUBMIT_GROUP_FORM_SUCCESS: "administrationAdvanced/SUBMIT_GROUP_FORM_SUCCESS",
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_GROUP_FORM_DATA_REQUEST]: (state) => ({
        ...INITIAL_STATE,
        credentialGroups: state.credentialGroups,
    }),
    [types.LOAD_GROUP_FORM_DATA_FAILURE]: (state) => ({ ...state, fetching: false }),
    [types.LOAD_GROUP_FORM_DATA_SUCCESS]: (state, { availableUsers, group, permissions, adminGroup, adminUsers }) => ({
        ...state,
        availableUsers,
        adminUsers,
        adminGroup,
        fetching: false,
        formValues: groupFormValues(state, group, permissions),
    }),
    [types.SUBMIT_GROUP_FORM_PREVIEW_SUCCESS]: (state, { credentialGroups, idTransaction, idActivity }) => ({
        ...state,
        credentialGroups,
        idTransaction,
        idActivity,
    }),
    [types.SUBMIT_CREATE_GROUP_FORM_PREVIEW_SUCCESS]: (state, { formValues }) => ({
        ...state,
        formValues,
    }),
});

export const actions = {
    loadGroupFormDataRequest: makeActionCreator(types.LOAD_GROUP_FORM_DATA_REQUEST, "id"),
    loadGroupFormDataFailure: makeActionCreator(types.LOAD_GROUP_FORM_DATA_FAILURE),
    loadGroupFormDataSuccess: makeActionCreator(
        types.LOAD_GROUP_FORM_DATA_SUCCESS,
        "group",
        "permissions",
        "adminUsers",
        (isAdminGroup) => ["adminGroup", isAdminGroup || false],
        (availableUsers) => ["availableUsers", arrayUtils.mapItemsIds(availableUsers, "idUser")],
    ),
    submitCreateGroupFormPreviewSuccess: makeActionCreator(
        types.SUBMIT_CREATE_GROUP_FORM_PREVIEW_SUCCESS,
        "formValues",
    ),
    submitGroupFormPreviewRequest: makeActionCreator(
        types.SUBMIT_GROUP_FORM_PREVIEW_REQUEST,
        "formData",
        "idGroup",
        "formikBag",
    ),
    submitGroupFormPreviewSuccess: makeActionCreator(
        types.SUBMIT_GROUP_FORM_PREVIEW_SUCCESS,
        "credentialGroups",
        "idTransaction",
        "idActivity",
    ),
    submitGroupFormRequest: makeActionCreator(types.SUBMIT_GROUP_FORM_REQUEST, "formData", "idGroup", "formikBag"),
    submitGroupFormSuccess: makeActionCreator(types.SUBMIT_GROUP_FORM_SUCCESS),
};

export const selectors = {
    getCredentialGroups: ({ groupFormData }) => groupFormData.credentialGroups,
    getIdTransaction: ({ groupFormData }) => groupFormData.idTransaction,
    getIdActivity: ({ groupFormData }) => groupFormData.idActivity,
    getAvailableUsers: ({ groupFormData }) => groupFormData.availableUsers,
    getAdminUsers: ({ groupFormData }) => groupFormData.adminUsers,
    getFormValues: ({ groupFormData }) => groupFormData.formValues,
    isAdminGroup: ({ groupFormData }) => groupFormData.adminGroup,
    isFetching: ({ groupFormData }) => groupFormData.fetching,
};
