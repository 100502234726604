import React, { Component, Fragment } from "react";
import { Col, Grid } from "react-bootstrap";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { string, func, shape, bool } from "prop-types";

import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";

import { insertLinebreaks, insertHTMLLinebreaks } from "util/string";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

const channels = { frontend: "Web", phonegap: "Mobile", assistant: "Assistant" };

class MovementDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        movement: shape({
            idAccount: string,
            idStatement: string,
            note: string,
        }).isRequired,
        account: shape({
            currency: string,
        }).isRequired,
        fetching: bool,
        voucher: string.isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    state = {
        note: {
            value: "",
            isEditing: true,
        },
    };

    componentDidMount() {
        const { dispatch, movement } = this.props;

        if (movement) {
            dispatch(accountsActions.movementDetails(movement.idAccount, movement.idStatement));
        }

        const hasNote = movement && movement.note;
        this.setState({ note: { value: hasNote || "", isEditing: !hasNote } });
    }

    handleChange = (event) => {
        const { value } = event.target;

        this.setState({ note: { value, isEditing: true } });
    };

    handleClick = () => {
        const { note } = this.state;
        const { isEditing } = note;
        let { value } = note;

        value = value.replace(/\n+/g, "\n");

        if (isEditing) {
            const { dispatch, movement } = this.props;
            const { idAccount, idStatement } = movement;

            dispatch(accountsActions.editMovementNote(idAccount, idStatement, insertLinebreaks(value)));
            this.setState({ note: { isEditing: false, value } });
        } else {
            this.setState({ note: { isEditing: true, value } });
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(accountsActions.closeOptions());
        dispatch(goBack());
    };

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(accountsActions.closeOptions());
        dispatch(goBack());
    };

    render() {
        const {
            note: { value, isEditing },
        } = this.state;

        const { account, fetching, movement, voucher } = this.props;

        const isCheck = movement && movement.check && movement.check !== 0;
        const isVoucher = voucher && movement.check !== 0;

        return (
            movement && (
                <Fragment>
                    <Head onBack={this.handleBack} onClose={this.handleClose} title="accounts.movement.detail.title" />
                    <Notification scopeToShow="movementDetail" />

                    <MainContainer showLoader={fetching}>
                        <form className="above-the-fold">
                            <Container
                                className="container--layout align-items-center section-content-heading"
                                gridClassName="container-fluid">
                                <Col sm={12} md={9} lg={6}>
                                    <Grid>
                                        <ul className="data-list">
                                            <li className="data-wrapper data-wrapper-inline">
                                                <span className="data-label">
                                                    <I18n id="accounts.movement.detail.date" />
                                                </span>{" "}
                                                <span className="data-date">
                                                    <FormattedDate date={movement.date} />
                                                </span>
                                            </li>
                                            <li className="data-wrapper data-wrapper-inline">
                                                <span className="data-label">
                                                    <I18n id="accounts.movement.detail.valueDate" />
                                                </span>{" "}
                                                <span className="data-date">
                                                    <FormattedDate date={movement.valueDate} />
                                                </span>
                                            </li>
                                        </ul>
                                    </Grid>
                                </Col>
                            </Container>

                            <Container className="container--layout align-items-center flex-grow">
                                <Col sm={12} md={9} lg={6}>
                                    <ul className="data-list">
                                        <li className="data-wrapper">
                                            <span className="data-label">
                                                <I18n id="accounts.movement.detail.reference" />
                                            </span>{" "}
                                            <span className="data-desc">{movement.reference || movement.concept}</span>
                                        </li>
                                        <li className="data-wrapper">
                                            <span className="data-label">
                                                <I18n id="accounts.movement.detail.amount" />
                                            </span>{" "}
                                            <FormattedAmount
                                                className={`data-amount data-numeric data-amount-${
                                                    movement.credit ? "credit" : "debit"
                                                }`}
                                                currency={account.currency}
                                                quantity={movement.credit || movement.debit}
                                            />
                                        </li>
                                        <li className="data-wrapper">
                                            <span className="data-label">
                                                <I18n id="accounts.movement.detail.channel" />
                                            </span>{" "}
                                            <span className="data-desc">{channels[movement.channel]}</span>
                                        </li>
                                        {Boolean(isCheck) && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="accounts.movement.detail.check" />
                                                </span>{" "}
                                                <span className="data-numeric">
                                                    <span>{movement.check}</span>
                                                </span>
                                            </li>
                                        )}
                                        {Boolean(isVoucher) && (
                                            <li className="data-wrapper">
                                                <span className="data-label">
                                                    <I18n id="accounts.movement.detail.voucher" />
                                                </span>{" "}
                                                <span className="data-img">
                                                    <img src={voucher} alt="voucher" />
                                                </span>
                                            </li>
                                        )}
                                    </ul>
                                </Col>
                            </Container>
                            <Container className="container--layout align-items-center">
                                <Col sm={12} md={9} lg={6} className="col">
                                    <div className="form-group">
                                        <label htmlFor="movement.detail" className="control-label">
                                            <I18n id="accounts.movement.detail.notes" />
                                        </label>
                                        {(!isEditing && (
                                            <div dangerouslySetInnerHTML={{ __html: insertHTMLLinebreaks(value) }} />
                                        )) || (
                                            <div className="input-group">
                                                <textarea
                                                    id="movement.detail"
                                                    className="form-control"
                                                    value={insertLinebreaks(value)}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <Button
                                        className="btn btn-primary btn-block"
                                        label={
                                            isEditing
                                                ? "accounts.movement.detail.saveNotes"
                                                : "accounts.movement.detail.editNotes"
                                        }
                                        onClick={this.handleClick}
                                    />
                                </Col>
                            </Container>
                        </form>
                    </MainContainer>
                </Fragment>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    fetching: accountsSelectors.getFetching(state),
    movement: accountsSelectors.getSelectedMovement(state),
    voucher: accountsSelectors.getVoucher(state),
});

export default connect(mapStateToProps)(MovementDetail);
