import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, withFormik, Form } from "formik";
import Yup from "yup";
import { string, shape, func, arrayOf, bool } from "prop-types";
import classNames from "classnames";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import TextField from "pages/_components/fields/TextField";
import Container from "pages/_components/Container";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import { isDesktop } from "react-device-detect";

const FORM_ID = "accounts.cbuAlias";

class SetAlias extends Component {
    static propTypes = {
        account: shape({
            ownerName: string.isRequired,
            nationalIdentifier: string,
        }).isRequired,
        credentialGroups: arrayOf(
            shape({
                idCredentialGroup: string,
            }),
        ).isRequired,
        dispatch: func.isRequired,
        values: shape({}).isRequired,
        fetchingAlias: bool.isRequired,
        closeDrawer: func,
    };

    static defaultProps = {
        closeDrawer: undefined,
    };

    componentDidUpdate(prevProps) {
        const { fetchingAlias, closeDrawer } = this.props;
        if (closeDrawer) {
            // En caso de que se esté ejecutando desde el buttonDrawer se le agrega esta función como prop
            if (prevProps.fetchingAlias && !fetchingAlias) {
                closeDrawer();
            }
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, account, values } = this.props;

        const { documentNumber: cuit, cbu, cbuAlias, idProduct: idAccount } = account;
        const { setAlias: newAlias } = values;
        const { otp } = credentials;

        if (newAlias !== null && newAlias.length !== 0 && otp !== null && otp.length !== 0) {
            formikBag.setErrors({});

            dispatch(accountsActions.modifyCbuAlias(idAccount, cuit, cbu, cbuAlias, newAlias, credentials, formikBag));
        } else {
            formikBag.setSubmitting(false);
            if (otp === null || otp.length === 0) {
                formikBag.setErrors({ otp: i18n.get("token.required") });
            }
        }
    };

    render() {
        const { credentialGroups } = this.props;
        return (
            <>
                <Form
                    className={classNames({
                        "mt-4 mb-2": isDesktop,
                    })}>
                    <Container className="align-items-center flex-grow" gridClassName="form-content">
                        <Col className="col-12" lg={9} md={9} sm={12}>
                            <div className="transfer-block">
                                <h1 className="form-title text-center">
                                    <I18n id="accounts.cbuAlias.setAlias.title" />
                                </h1>
                            </div>
                            <div className="transfer-block text-center">
                                <div className="inline-grid account-alias-drawer-font account-alias-drawer-subtitle">
                                    <I18n id="accounts.cbuAlias.setAlias.subtitle" />
                                </div>
                            </div>
                            <Field
                                autoFocus
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="setAlias"
                                type="text"
                            />
                            <div className="inline-grid account-alias-drawer-font">
                                <I18n id="accounts.cbuAlias.setAlias.info1" />
                            </div>
                            <div className="inline-grid account-alias-drawer-font">
                                <I18n id="accounts.cbuAlias.setAlias.info2" />
                            </div>
                            <div className="inline-grid account-alias-drawer-font">
                                <I18n id="accounts.cbuAlias.setAlias.info3" />
                            </div>
                            <div className="inline-grid account-alias-drawer-font">
                                <I18n id="accounts.cbuAlias.setAlias.info4" />
                            </div>
                        </Col>
                        <div className="admin-content-center set-alias-token-confirmation">
                            <AdministrationFormConfirmation
                                credentialGroups={credentialGroups}
                                onSubmit={this.handleSubmit}
                                withoutCredentialHint
                                MDcolumnWidth={9}
                                LGcolumnWidth={9}
                            />
                        </div>
                    </Container>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    fetching: accountsSelectors.getFetching(state),
    fetchingAlias: accountsSelectors.getFetchingAlias(state),
    aliasError: accountsSelectors.getAliasError(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            setAlias: props.account ? props.account.cbuAlias : "",
            productId: props.account.idProduct,
        }),
        validationSchema: () =>
            Yup.object().shape({
                setAlias: Yup.string()
                    .nullable()
                    .required(i18n.get("accounts.cbuAlias.setAlias.empty")),
            }),
        handleSubmit: () => {},
    }),
)(SetAlias);
