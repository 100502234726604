import { bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import moment from "moment";

import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import ProductDetail from "pages/_components/ProductDetail";
import Accordion from "pages/_components/Accordion";
import I18n from "pages/_components/I18n";
import InfoTag from "pages/_components/InfoTag";
import Table from "pages/_components/Table";
import TableBody from "pages/_components/table/Body";
import TableHeader from "pages/_components/table/Header";
import TableHeaderData from "pages/_components/table/HeaderData";

import { actions as cedipActions, selectors as cedipSelectors } from "reducers/cedip";

import * as i18n from "util/i18n";
import classNames from "classnames";
import Heading from "pages/_components/Heading";
import Information from "./_components/Information";
import DetailHeadTitle from "./_components/DetailHeadTitle";

function CedipDetail(props) {
    const { dispatch, match, cedip, isDesktop, fetching, fetchingDownload } = props;

    useEffect(() => {
        const { ...rest } = props;
        dispatch(
            cedipActions.readCedip({ cedipId: rest.match.params.idCedip, fraccionNumero: rest.match.params.fraction }),
        );
    }, []);

    const handleBack = () => {
        dispatch(goBack());
    };

    const handleClickDownload = () => {
        dispatch(cedipActions.downloadDetail(match.params.idCedip, match.params.fraction, "pdf"));
    };

    const statusTagColor = (state) => {
        switch (state) {
            case "ACTIVO":
            case "PRESENTADO":
            case "DEPOSITADO":
            case "NEGOCIACION":
                return "#84efd0";
            case "ACTIVO-PENDIENTE":
                return "#FFE5B6";
            case "REPUDIADA":
            case "RECHAZADO":
            case "ANULADO":
            case "PAGADO":
            case "CADUCADO":
                return "#feb0af";
            default:
                return "#84efd0";
        }
    };

    const detailBody = () =>
        cedip?.transmisiones?.map((transmision) => (
            <Table.Row className="container-white">
                <Table.Data align="left">
                    <div>{transmision.razonSocialTransmisor}</div>
                    <div>{`${transmision.tipoDocumentoTransmisor} ${transmision.cuitTransmisor}`}</div>
                </Table.Data>
                <Table.Data align="left">
                    <div>{transmision.razonSocialBeneficiario}</div>
                    <div>{`${transmision.tipoDocumentoBeneficiario} ${transmision.cuitBeneficiario}`}</div>
                </Table.Data>
                <Table.Data align="left">
                    <div>
                        {transmision.fecha
                            ? moment(transmision.fecha.toString().replace(".000Z", "")).format("DD/MM/YYYY")
                            : "null"}
                    </div>
                </Table.Data>
                <Table.Data align="left">
                    <div>{transmision.tipo}</div>
                </Table.Data>
                <Table.Data align="left">
                    <InfoTag
                        type="info"
                        message={transmision.estado}
                        tagBackground={statusTagColor(transmision.estado)}
                        tagClass
                        moreStyles={{
                            alignSelf: "flex-end",
                            width: "fit-content",
                            color: "black",
                        }}
                    />
                </Table.Data>
            </Table.Row>
        ));

    const componentProps = {
        className: classNames("w-100 text-left", { "font-light f-size-25": isDesktop }, { "f-size-5": !isDesktop }),
    };

    return (
        <PageLoading loading={fetching}>
            {!fetching && cedip && (
                <>
                    <Notification scopeToShow="cedip/detail" />
                    <ProductDetail>
                        <DetailHeadTitle
                            cedip={cedip}
                            isDesktop={isDesktop}
                            dispatch={dispatch}
                            fetchingDownload={fetchingDownload}
                            handleClickDownload={() => handleClickDownload}
                            handleBack={handleBack}
                        />
                        <ProductDetail.Body isDesktop={isDesktop} moreFilters={false}>
                            <Information cedip={cedip} keyLabel="deposits.details.information" dispatch={dispatch} />
                            {/* Indicar texto a completar */}
                            {/* <Container className="align-items-center flex-grow">
                                <Col className="col col-12" lg={6} xl={6} sm={12} md={9}>
                                    <Hint
                                        idMessage="deposits.information.disclaimer.untransferable"
                                        styleImage={{
                                            marginTop: "1.2rem",
                                        }}
                                        classNameHintP="hint-font-size-small font-weight-normal"
                                        alwaysShowMessage
                                    />
                                </Col>
                            </Container> */}
                        </ProductDetail.Body>
                    </ProductDetail>
                    {cedip?.transmisiones?.length ? (
                        <Accordion>
                            <Accordion.Item
                                number="1"
                                item={
                                    <I18n
                                        id="deposits.cedip.transmissions"
                                        component="p"
                                        componentProps={componentProps}
                                    />
                                }
                                fitContent
                                navigationListItemClassName="background-transparent my-2 pl-0"
                                buttonClassName="container-white py-2 px-3">
                                {isDesktop ? (
                                    <Table>
                                        <TableHeader className="container-white">
                                            <TableHeaderData align="left">
                                                <I18n component="span" id="deposits.cedip.title.transmisor" />
                                            </TableHeaderData>
                                            <TableHeaderData align="left">
                                                <I18n id="deposits.cedip.title.beneficiario" />
                                            </TableHeaderData>
                                            <TableHeaderData align="left">
                                                <I18n id="deposits.cedip.title.fecha" />
                                            </TableHeaderData>
                                            <TableHeaderData align="left">
                                                <I18n id="deposits.cedip.title.tipo" />
                                            </TableHeaderData>
                                            <TableHeaderData align="left">
                                                <I18n id="deposits.cedip.title.estado" />
                                            </TableHeaderData>
                                            <TableHeaderData />
                                        </TableHeader>
                                        <TableBody>{detailBody()}</TableBody>
                                    </Table>
                                ) : (
                                    cedip?.transmisiones?.map((transmision) => (
                                        <div className="container-white p-3 mb-1">
                                            <Heading.DataGroup
                                                containerClassName="data-wrapper-flex align-items-start space-between my-15"
                                                labelClassName="data-label-medium text-12 text-bold"
                                                dataClassName="data-desc text-12"
                                                label="deposits.cedip.title.transmisor"
                                                data={
                                                    <div className="flex-column justify-content-end d-flex">
                                                        <div className="no-wrap text-right">
                                                            {transmision.razonSocialTransmisor}
                                                        </div>
                                                        <div className="no-wrap text-right">{`${i18n.get(
                                                            "deposits.cedip.transmission.documentType",
                                                        )} ${transmision.cuitTransmisor}`}</div>
                                                    </div>
                                                }
                                            />
                                            <Heading.DataGroup
                                                containerClassName="data-wrapper-flex align-items-start space-between my-15"
                                                labelClassName="data-label-medium text-12 text-bold"
                                                dataClassName="data-desc text-12"
                                                label="deposits.cedip.title.beneficiario"
                                                data={
                                                    <div className="flex-column justify-content-end d-flex">
                                                        <div className="no-wrap text-right">
                                                            {transmision.razonSocialBeneficiario}
                                                        </div>
                                                        <div className="no-wrap text-right">{`${i18n.get(
                                                            "deposits.cedip.transmission.documentType",
                                                        )} ${transmision.cuitBeneficiario}`}</div>
                                                    </div>
                                                }
                                            />
                                            <Heading.DataGroup
                                                containerClassName="data-wrapper-flex space-between my-15"
                                                labelClassName="data-label-medium text-12 text-bold"
                                                dataClassName="data-desc text-12"
                                                label="deposits.cedip.title.fecha"
                                                data={moment(transmision.fecha.toString().replace(".000Z", "")).format(
                                                    "DD/MM/YYYY",
                                                )}
                                            />
                                            <Heading.DataGroup
                                                containerClassName="data-wrapper-flex space-between my-15"
                                                labelClassName="data-label-medium text-12 text-bold"
                                                dataClassName="data-desc text-12"
                                                label="deposits.cedip.title.tipo"
                                                data={transmision.tipo}
                                            />
                                        </div>
                                    ))
                                )}
                            </Accordion.Item>
                        </Accordion>
                    ) : (
                        undefined
                    )}
                </>
            )}
        </PageLoading>
    );
}

CedipDetail.propTypes = {
    dispatch: func.isRequired,
    cedip: shape({
        cedip_id: string,
        tipo_moneda: string,
    }).isRequired,
    match: shape().isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    fetchingDownload: bool.isRequired,
};

const mapStateToProps = (state) => ({
    cedip: cedipSelectors.getSelectedCedip(state),
    fetching: cedipSelectors.getFetching(state),
    fetchingDownload: cedipSelectors.getFetchingDownload(state),
});

export default connect(mapStateToProps)(CedipDetail);
