import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";

import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import { actions as servicePaymentsActions } from "reducers/servicePayments";

const FORM_ID = "services.adhesiones.edit";

class EditAdhesionReference extends Component {
    static propTypes = {
        handleSubmit: func.isRequired,
        isDesktop: bool.isRequired,
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", margin: 0 }}>{`${FORM_ID}.label`}</h1>
        </div>
    );

    rightContent = () => <></>;

    render() {
        const { handleSubmit, isDesktop } = this.props;

        return (
            <>
                <div className="edit-adhesion-content">
                    {isDesktop && (
                        <div className="form-section-title admin-content-center" style={{ marginBottom: "3rem" }}>
                            <I18n component="h2" id={`${FORM_ID}.reference.title`} />
                        </div>
                    )}

                    <Form
                        className="flex-grow align-items-center adhesion-field"
                        onSubmit={(e) => {
                            handleSubmit(e);
                            e.stopPropagation();
                        }}>
                        <Container className="flex-grow align-items-center" gridClassName="form-content">
                            <Col className="col col-12">
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="reference"
                                    type="text"
                                    style={{ paddingTop: "12px", paddingBottom: "12px" }}
                                    maxLength="20"
                                />
                            </Col>

                            <Col className="col col-12" style={{ marginTop: isDesktop ? "20vh" : "4vh" }}>
                                <Button type="submit" bsStyle="primary" label="global.modify" />
                            </Col>
                        </Container>
                    </Form>
                </div>
            </>
        );
    }
}

export default compose(
    connect(),
    withFormik({
        mapPropsToValues: ({ adhesion }) => ({ reference: adhesion.reference || "" }),
        // validationSchema: () =>
        //     Yup.object().shape({
        //         reference: Yup.string().required(i18n.get("accounts.new.account.field.error.required")),
        //     }),
        handleSubmit: (values, formikBag) => {
            const { dispatch, adhesion } = formikBag.props;

            dispatch(servicePaymentsActions.modifyAdhesionReference(values.reference, adhesion));
        },
    }),
)(EditAdhesionReference);
