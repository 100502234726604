/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
// eslint-disable-next-line import/no-unresolved
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import { bool, func, string, arrayOf } from "prop-types";
import { Col, Modal } from "react-bootstrap";
import Image from "pages/_components/Image";
import { connect } from "react-redux";
import { selectors, actions as idEcheqAction } from "reducers/checks";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import Tooltip from "pages/_components/tooltip/Tooltip";
import { isAndroid } from "react-device-detect";

function IdEcheqSelector({
    echeqNumber,
    echeqId,
    dispatch,
    idSelectorError,
    numberSelectorError,
    isDesktop,
    echeqList,
    cleanFilter,
    idTooltip,
    label,
    ...props
}) {
    const [selectorValue, setSelectorValue] = useState("");
    const [showList, setShowList] = useState(false);

    const handleSelectorValue = (e) => {
        setSelectorValue(e.target.value);
    };

    useEffect(() => {
        const hasErrorItem = echeqList.some((data) => !data.isValidItem) || selectorValue !== "";
        if (cleanFilter) {
            if (isDesktop) {
                setSelectorValue("");
            }
        } else {
            return;
        }
        if (echeqId) {
            dispatch(idEcheqAction.setIdSelectorValidationError(hasErrorItem));
        } else {
            return;
        }

        if (echeqNumber) {
            dispatch(idEcheqAction.setNumberSelectorValidationError(hasErrorItem));
        } else {
            return;
        }

        if (echeqList.length <= 0) {
            setShowList(false);
        } else {
            // eslint-disable-next-line no-useless-return
            return;
        }
    }, [echeqList, dispatch, selectorValue, cleanFilter]);

    const validateItems = (data) => {
        const limit = configUtils.getInteger("echeq.cant.max.allowed.numbers", 20);

        const validateItem = data.map((item) => {
            const value = item.toUpperCase();
            const isValidItem = echeqNumber
                ? /^[0-9]{8}$/.test(item)
                : /^[0-9A-Za-z]{1,15}$/.test(item) && value.length === 15;
            return { value: item, isValidItem };
        });
        const filteredList = [...echeqList, ...validateItem].filter((item, index) => index + 1 <= limit);
        if (echeqId) {
            dispatch(idEcheqAction.setIdList([...filteredList]));
        } else {
            dispatch(idEcheqAction.setNumberList([...filteredList]));
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData("text");
        setSelectorValue(paste);
    };

    const addItems = () => {
        const trimmedValue = selectorValue.trim();
        if (trimmedValue) {
            const newData = trimmedValue
                .split(/[\s,]+/) // Dividir por comas y espacios
                .map((item) => item.trim())
                .filter((item) => item !== "");

            validateItems(newData);
            setSelectorValue("");
        }
    };

    const handleSelectorKeyDown = (e) => {
        const { key } = e;
        if ((!isAndroid && key === "Enter") || (!isAndroid && key === ",")) {
            e.preventDefault();
            addItems();
        }
    };

    const handleSelectorKeyUp = (e) => {
        const eKeyPress = e.target.value.slice(-1);
        if (isAndroid && eKeyPress === ",") {
            e.preventDefault();
            addItems();
        }
    };

    const removeItems = (e, index) => {
        e.preventDefault();
        if (echeqId) {
            dispatch(idEcheqAction.setIdList(echeqList.filter((_, i) => i !== index)));
        } else {
            dispatch(idEcheqAction.setNumberList(echeqList.filter((_, i) => i !== index)));
        }
    };

    const itemCounts = () => {
        const noItems = echeqList.length === 0;
        const echeqsCount = echeqList.length;
        const oneItem = echeqsCount === 1;
        const errorCount = echeqList.some((data) => !data.isValidItem);

        if (echeqNumber) {
            if (noItems) {
                return (
                    <span className="echeqs__id-selector-count-label">
                        {i18nUtils.get("echeqs.filter.nonumber.label")}
                    </span>
                );
            }
            return (
                <Button
                    className={`echeqs__id-selector-count-label ${errorCount ? "echeqs__id-selector-error" : ""}`}
                    onClick={() => setShowList(!showList)}>
                    {i18nUtils.get("echeqs.filter.numberid.see.label")} {echeqsCount}{" "}
                    {i18nUtils.get("echeqs.filter.number.character")}
                    <Image src={!showList ? "/images/arrowDown.svg" : "/images/arrowUp.svg"} />
                </Button>
            );
        }
        if (echeqId) {
            if (noItems) {
                return (
                    <span className="echeqs__id-selector-count-label">{i18nUtils.get("echeqs.filter.noid.label")}</span>
                );
            }
            return (
                <Button
                    className={`echeqs__id-selector-count-label ${errorCount && "echeqs__id-selector-error"}`}
                    onClick={() => setShowList(!showList)}>
                    {`${i18nUtils.get("echeqs.filter.numberid.see.label")} ${echeqsCount} ${" "} ${i18nUtils.get(
                        "echeqs.filter.id.character",
                    )}${!oneItem ? "s" : ""}`}
                    <Image src={!showList ? "/images/arrowDown.svg" : "/images/arrowUp.svg"} />
                </Button>
            );
        }
        return null;
    };

    const maxListLength = configUtils.getInteger("echeq.cant.max.allowed.numbers", 20) <= echeqList.length;

    return (
        <div className="echeqs__id-selector">
            <TextField
                handleOnChange={handleSelectorValue}
                customKeyDown={handleSelectorKeyDown}
                onKeyUp={handleSelectorKeyUp}
                handleOnBlur={isAndroid && addItems}
                value={selectorValue}
                inputFunctions={itemCounts()}
                onPaste={handlePaste}
                isDisabled={maxListLength}
                infoMsg={
                    <Tooltip
                        id={idTooltip}
                        message={
                            <>
                                {i18nUtils.get("echeqs.filter.hint.label.one")}{" "}
                                {configUtils.getInteger("echeq.cant.max.allowed.numbers", 20)}{" "}
                                {i18nUtils.get("echeqs.filter.hint.label.two")}
                            </>
                        }
                    />
                }
                placeholder={
                    echeqNumber
                        ? `${i18nUtils.get("echeqs.filter.number.placeholder")}`
                        : `${i18nUtils.get("echeqs.filter.id.placeholder")}`
                }
                labelText={label}
                {...props}
            />
            {showList && isDesktop && (
                <Col className="px-0" xs={12}>
                    {echeqList.length > 0 ? (
                        <ul className="echeqs__id-selector-list">
                            {echeqList.map((data, index) => (
                                <li key={data.id} className={!data.isValidItem ? "echeqs__id-selector-error" : null}>
                                    <span>{data.value}</span>
                                    <Button
                                        className="echeqs__id-selector-list"
                                        onClick={(e) => removeItems(e, index)}
                                    />
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </Col>
            )}
            {showList && !isDesktop && (
                <div className="modal-container modal-container-echeqs">
                    <Modal
                        className="echeqs__modal"
                        dialogClassName="echeqs__modal"
                        aria-labelledby="modalTitleID"
                        aria-modal="true"
                        onHide={() => setShowList(!showList)}
                        show={showList}>
                        <Modal.Header className="mt-0">
                            <Modal.Title componentClass="div" id="modalTitleID" className="px-4">
                                <div className="close-without-btn">
                                    <Button
                                        image="images/rectangle-dropdown.svg"
                                        onClick={() => setShowList(!showList)}
                                    />
                                </div>
                                <div className="echeqs__modal-title">
                                    <Image src="images/echeqs__modal-mobile-title.svg" />
                                    {echeqNumber ? (
                                        <span>{i18nUtils.get("echeqs.filter.modal.label.number")}</span>
                                    ) : (
                                        <span>{i18nUtils.get("echeqs.filter.modal.label.id")}</span>
                                    )}
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="my-0 w-100">
                            <Col className="px-0" xs={12}>
                                {echeqList.length > 0 ? (
                                    <ul className="echeqs__id-selector-list">
                                        {echeqList.map((data, index) => (
                                            <li
                                                key={data.id}
                                                className={!data.isValidItem ? "echeqs__id-selector-error" : null}>
                                                <span>{data.value}</span>
                                                <Button
                                                    className="echeqs__id-selector-list"
                                                    onClick={(e) => removeItems(e, index)}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </Col>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
}

IdEcheqSelector.propTypes = {
    echeqNumber: bool,
    echeqId: bool,
    dispatch: func.isRequired,
    idSelectorError: bool.isRequired,
    numberSelectorError: bool.isRequired,
    isDesktop: bool.isRequired,
    echeqList: arrayOf(string).isRequired,
    cleanFilter: bool,
    idTooltip: string,
    label: string.isRequired,
};

IdEcheqSelector.defaultProps = {
    echeqNumber: false,
    echeqId: false,
    cleanFilter: false,
    idTooltip: "",
};

const mapStateToProps = (state, ownProps) => ({
    idSelectorError: selectors.getIdSelectorErrorState(state),
    numberSelectorError: selectors.getNumberSelectorErrorState(state),
    echeqList: ownProps.echeqId ? selectors.getIdListSelector(state) : selectors.getNumberListSelector(state),
});

export default connect(mapStateToProps)(IdEcheqSelector);
