import React from "react";
import { shape, string, oneOf } from "prop-types";
import cx from "classnames";

function Avatar({ user, size, className }) {
    const userParsed = user.userFullName.split(" ");
    const initials =
        (user && user.userFullName.match(/[A-Z]/g)?.join("")) ||
        `${userParsed[0][0]}${userParsed[userParsed.length - 1][0]}`.toLocaleUpperCase();

    const userInitials = initials && initials.substring(0, 2);
    return (
        <div className={cx("avatar mx-0", { "avatar-big": size === "big" }, className)}>
            <span className="visually-hidden">{user && user.userFullName}</span>
            {/* {content} */}
            <span className={cx("avatar-text", className)} aria-hidden>
                {userInitials}
            </span>
        </div>
    );
}

Avatar.propTypes = {
    size: oneOf(["normal", "big"]),
    user: shape({
        userFullName: string.isRequired,
        defaultAvatarId: string.isRequired,
    }).isRequired,
    className: string,
};

Avatar.defaultProps = {
    size: "normal",
    className: "",
};

export default Avatar;
