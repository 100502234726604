import React, { Component } from "react";

import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import { number, bool, string } from "prop-types";

import * as i18nUtils from "util/i18n";

class EquivalentTotalBalance extends Component {
    static propTypes = {
        ARSTotalBalance: number,
        USDTotalBalance: number,
        isDesktop: bool,
        title: string,
    };

    static defaultProps = {
        ARSTotalBalance: 0,
        USDTotalBalance: 0,
        isDesktop: true,
        title: "accounts.balance.total",
    };

    render() {
        const { ARSTotalBalance, USDTotalBalance, isDesktop, title } = this.props;

        const arsTotalComponent = (
            <FormattedAmount
                className={`medium-font m-0 h3 ${isDesktop && "d-inline-flex"}`}
                currency={i18nUtils.get("currency.label.ARS")}
                quantity={ARSTotalBalance}
                main
            />
        );

        const usdTotalComponent = (
            <FormattedAmount
                className={`medium-font m-0 h3 ${isDesktop && "d-inline-flex"}`}
                currency={i18nUtils.get("currency.label.USD")}
                quantity={USDTotalBalance}
                main
            />
        );

        return (
            <div className={`d-flex flex-column ${isDesktop ? "mt-3 mb-4" : "container align-self-center my-4"}`}>
                <div>
                    <I18n
                        id={title}
                        componentProps={{
                            className: "account-data-label-title mt-0",
                        }}
                        component="h3"
                    />
                </div>
                <div>
                    {arsTotalComponent}
                    {isDesktop && <span className="medium-font h3 d-inline-flex my-0 mx-2">|</span>}
                    {usdTotalComponent}
                </div>
            </div>
        );
    }
}

export default EquivalentTotalBalance;
