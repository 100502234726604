/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { compose } from "redux";
import { string, shape, bool, arrayOf } from "prop-types";
import { connect } from "react-redux";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import Heading from "pages/_components/Heading";
import FormattedDate from "pages/_components/FormattedDate";
import Message from "pages/forms/_components/_fields/_scheduler/Message";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as transferSelectors } from "reducers/transfer";

import * as i18nUtils from "util/i18n";
import { getAccountDescription } from "util/accounts";

import Hint from "pages/_components/hints/Hint";
import * as config from "util/config";

class TransferSummaryData extends Component {
    static propTypes = {
        internalTransfer: bool.isRequired,
        summary: shape({
            debitAccount: string.isRequired,
            creditAccount: string.isRequired,
            amount: string.isRequired,
            currency: string.isRequired,
            reference: string,
            sendEmail: bool,
            email: string,
            emailText: string,
            tadTrx: bool,
        }).isRequired,
        fullName: string.isRequired,
        isDesktop: bool.isRequired,
        signature: bool,
        concepts: arrayOf(shape({})).isRequired,
        isCVU: bool,
    };

    static defaultProps = {
        signature: false,
        isCVU: false,
    };

    preparedBy = () => {
        const { summary, fullName } = this.props;
        return (
            <p>
                {summary?.creatorFullName || fullName} -{" "}
                <FormattedDate date={summary?.creationDate || new Date()} dateFormat="dd/MM/yyyy" showTime />
            </p>
        );
    };

    signBy = () => {
        const { summary } = this.props;
        return (
            <p>
                {summary?.signatures.map((object) => (
                    <>
                        {object?.userFirstName.concat(" ", object?.userLastName)} -{" "}
                        <FormattedDate date={object?.creationDateTime} dateFormat="dd/MM/yyyy" showTime />
                        <br />
                    </>
                ))}
            </p>
        );
    };

    getCuitDescription = (clientUser) => {
        if (clientUser?.cuil !== undefined) {
            return ` ${i18nUtils.get(`transferSummary.cuil.input.label`)} ${clientUser?.cuil}`;
        }
        return ` ${i18nUtils.get(`transferSummary.cuit.input.label`)} ${clientUser?.cuit}`;
    };

    render() {
        const {
            summary: {
                debitAccount,
                creditAccount,
                amount,
                reference,
                currency,
                sendEmail,
                email,
                emailText,
                concept: conceptId,
                transferKind,
                creditAccountDetail,
                clientUser,
                scheduler,
                idTransactionToCancel,
                pspEntityName,
                tadTrx,
            },
            summary,
            internalTransfer,
            isDesktop,
            signature,
            concepts,
            isCVU,
        } = this.props;

        const transferKindOptions = [
            {
                value: `same`,
                label: i18nUtils.get("transfers.titular.same.label"),
            },
            {
                value: `other`,
                label: i18nUtils.get("transfers.titular.other.label"),
            },
        ];

        const concept = concepts.find(({ id }) => id === conceptId);
        const amountValue = amount?.quantity || amount;
        const currencyValue = currency?.toString() || (amount?.currency ? amount.currency.toString() : "");
        const transferCvuEnabled = config.getBoolean("transfer.cvu.enabled");

        return (
            <>
                {idTransactionToCancel && (
                    <>
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            dataClassName="data-desc"
                            label="transferConfirmation.idTransaction.label"
                            data={
                                <p className="data-desc m-0">{summary?.idTransaction?.substring(0, 8).toUpperCase()}</p>
                            }
                        />
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            dataClassName="data-desc"
                            label="scheduled.transactions.cancel.idTransactionToCancel.label"
                            data={<p className="data-desc m-0">{idTransactionToCancel}</p>}
                        />
                    </>
                )}

                {debitAccount && debitAccount.idProduct && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "mb-2" : "mb-3"} ${
                            isDesktop ? "" : "space-between"
                        }`}
                        label="transfers.debitAccount.label"
                        data={debitAccount?.number !== undefined && getAccountDescription(debitAccount)}
                    />
                )}

                {creditAccount && creditAccount?.number && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                        label="transfers.creditAccount.label"
                        data={creditAccount?.number !== undefined && getAccountDescription(creditAccount)}
                    />
                )}

                {!internalTransfer && creditAccountDetail?.cbu && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                        label="transfers.creditAccount.label"
                        data={creditAccountDetail?.cbu.slice(-14, -1)}
                    />
                )}

                {!internalTransfer && transferKind && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                        label="transfers.transferKind.label"
                        data={transferKindOptions.find(({ value }) => value === transferKind).label}
                    />
                )}

                {!internalTransfer && creditAccountDetail?.cbu && (
                    <Heading.DataGroup
                        label={
                            transferCvuEnabled
                                ? `transferSummary.${!isCVU ? "cbu" : "cvu"}.label`
                                : "transferSummary.cbu.label"
                        }
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                        data={creditAccountDetail?.cbu}
                    />
                )}

                {!internalTransfer && (clientUser?.cuit || clientUser?.cuil) && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                        label="transferSummary.cuit.label"
                        data={this.getCuitDescription(clientUser)}
                    />
                )}

                {!internalTransfer && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                        label="transferSummary.owner.label"
                        data={clientUser?.firstName}
                    />
                )}

                {!internalTransfer && creditAccountDetail?.bankName && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "mb-2" : "mb-3"} ${
                            isDesktop ? "" : "space-between"
                        }`}
                        label={`transferSummary.${!isCVU ? "bank" : "entity"}.label`}
                        data={transferCvuEnabled && isCVU ? pspEntityName : creditAccountDetail?.bankName}
                    />
                )}

                {amount && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                        dataClassName="data-desc small-font"
                        label="transfers.amount.label"
                        data={
                            <FormattedAmount
                                currency={currencyValue}
                                quantity={amountValue}
                                notBold
                                className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                            />
                        }
                    />
                )}

                {!internalTransfer && concept && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                        label="transfers.concept.label"
                        data={`${concept.id} - ${concept.name}`}
                    />
                )}

                {!idTransactionToCancel && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                        label="transfers.reference.label"
                        data={reference}
                    />
                )}

                {scheduler && !tadTrx && (
                    <>
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            label="forms.confirmation.scheduler"
                            data={<Message value={scheduler} />}
                        />
                        {!internalTransfer &&
                            debitAccount &&
                            debitAccount.idProduct &&
                            debitAccount.productType !== "CA" &&
                            transferKind === "other" &&
                            !tadTrx && (
                                <Hint
                                    defaultValue={config.get(
                                        `transferencias.impuestos${debitAccount.productType}.Texto`,
                                    )}
                                    styleText={{
                                        maxWidth: "35rem",
                                    }}
                                    classNameGroup="transfer__text-hint"
                                    classNameMessage="d-flex align-items-center"
                                    styleDiv={{ marginLeft: "0.5rem" }}
                                    styleImage={{ alignSelf: "center", margin: !isDesktop && "2rem 0 1.5rem" }}
                                    classNameHintP="font-weight-normal my-0"
                                    alwaysShowMessage
                                />
                            )}
                    </>
                )}

                {email && sendEmail && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                        label="transfers.email.label"
                        data={email}
                    />
                )}
                {email && emailText && sendEmail && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                        label="transfers.emailText.label"
                        data={emailText}
                    />
                )}
                {tadTrx && (
                    <I18n
                        id={
                            internalTransfer
                                ? "transfer.TAD.summary.internalTransfer"
                                : "transfer.TAD.summary.thirdPartiesTransfer"
                        }
                        componentProps={{ className: "d-flex mt-4 font-weight-600 color-primary text-center" }}
                    />
                )}
                {signature && (
                    <>
                        <I18n
                            component="h3"
                            id="accounts.new.account.signatures.label"
                            componentProps={{ className: "font-size-25-px" }}
                        />
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            label="accounts.new.account.signatures.preparedBy.label"
                            data={this.preparedBy()}
                        />
                        {summary?.signatures?.length > 0 && (
                            <>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    label="accounts.new.account.signatures.signBy.label"
                                    data={this.signBy()}
                                />
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    summary: transferSelectors.getSummary(state),
    internalTransfer: transferSelectors.getInternalTransfer(state),
    fullName: sessionSelectors.getUser(state).userFullName,
    concepts: transferSelectors.getConcepts(state),
    isCVU: transferSelectors.getIsCVU(state),
});

export default compose(connect(mapStateToProps))(TransferSummaryData);
