import React from "react";
import PropTypes from "prop-types";
import { push } from "react-router-redux";

import { actions as fundcorpActions } from "reducers/fundcorp";

import Button from "pages/_components/Button";
import ContextMenu from "pages/_components/ContextMenu";
import DownloadButton from "pages/_components/DownloadButton";
import { isEnabledMvp } from "util/fundcorp";

function OperationRightContent(props) {
    const {
        activeEnvironment,
        isDesktop,
        dispatch,
        isSmallDesktop,
        isFetchingFundcorp,
        isFetchingDownload,
        hasPositionsToday,
        handleExport,
    } = props;

    let itemActiveMultiple = [];

    const showMvp4Release = isEnabledMvp("MVP4");

    if (showMvp4Release && isDesktop) {
        itemActiveMultiple = [
            ...itemActiveMultiple,
            {
                label: "fund.operation.investorProfile.menu.label",
                onClick: () => dispatch(push("/fundcorpOperations/investor-profile")),
            },
        ];
    }

    const handleSubscribe = (url) => {
        if (showMvp4Release) {
            dispatch(fundcorpActions.getInvestorProfile(url));
        } else {
            dispatch(push(url));
        }
    };

    if (isDesktop && !isSmallDesktop) {
        return (
            <>
                {activeEnvironment.permissions.fundSubscribe && (
                    <Button
                        block
                        label="fund.operation.subscribe"
                        bsStyle="primary"
                        className="cmf-button-width"
                        onClick={() => handleSubscribe("/fundcorpOperations/subscribe")}
                    />
                )}
                {activeEnvironment.permissions.fundRescue && (
                    <Button
                        block
                        label="fund.operation.rescue"
                        bsStyle="primary"
                        className="cmf-button-width"
                        onClick={() => dispatch(push("/fundcorpOperations/rescue"))}
                        disabled={!hasPositionsToday || isFetchingFundcorp}
                    />
                )}
                <DownloadButton
                    isFetchingExport={isFetchingDownload}
                    handleClick={handleExport}
                    handleClickMessage="global.download"
                    csvDownload
                    xlsDownload
                    imageStyle="mr-2"
                />
                {showMvp4Release && (
                    <ContextMenu
                        isDesktop={isDesktop}
                        buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                        items={itemActiveMultiple}
                    />
                )}
            </>
        );
    }

    if (activeEnvironment.permissions.fundSubscribe) {
        itemActiveMultiple = [
            ...itemActiveMultiple,
            {
                label: "fund.operation.subscribe",
                onClick: () => handleSubscribe("/fundcorpOperations/subscribe"),
            },
        ];
    }

    if (activeEnvironment.permissions.fundRescue && hasPositionsToday) {
        itemActiveMultiple = [
            ...itemActiveMultiple,
            {
                label: "fund.operation.rescue",
                onClick: () => dispatch(push("/fundcorpOperations/rescue")),
            },
        ];
    }

    let menuItems = [
        {
            label: "global.download",
            onClick: () => handleExport("pdf"),
        },
    ];

    if (showMvp4Release) {
        menuItems = [
            ...menuItems,
            {
                label: "fund.operation.investorProfile.menu.label",
                onClick: () => dispatch(push("/fundcorpOperations/investor-profile")),
            },
        ];
    }

    if (itemActiveMultiple.length > 0 && isSmallDesktop) {
        menuItems = [...itemActiveMultiple];
    }

    if (itemActiveMultiple.length > 0 && !isSmallDesktop) {
        menuItems = [...itemActiveMultiple, ...menuItems];
    }

    return (
        <>
            {isDesktop && (
                <DownloadButton
                    isFetchingExport={isFetchingDownload}
                    handleClick={handleExport}
                    handleClickMessage="global.download"
                    csvDownload
                    xlsDownload
                />
            )}
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                items={menuItems}
            />
        </>
    );
}

OperationRightContent.propTypes = {
    activeEnvironment: PropTypes.shape({
        permissions: PropTypes.shape({
            fundRescue: PropTypes.bool,
            fundSubscribe: PropTypes.bool,
        }),
    }).isRequired,
    isDesktop: PropTypes.bool.isRequired,
    isSmallDesktop: PropTypes.bool.isRequired,
    isFetchingFundcorp: PropTypes.bool.isRequired,
    isFetchingDownload: PropTypes.bool.isRequired,
    hasPositionsToday: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleExport: PropTypes.func.isRequired,
};

export default OperationRightContent;
