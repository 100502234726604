import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { shape, arrayOf, bool, func } from "prop-types";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";

import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Notification from "pages/_components/Notification";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import CreateDebinSellerSummary from "pages/charges/_components/CreateDebinSummary/CreateDebinSellerSummary";
import CreateDebinRecipientSummary from "pages/charges/_components/CreateDebinSummary/CreateDebinRecipientSummary";

import isTokenActive from "util/token";
import * as i18nUtils from "util/i18n";

const FORM_ID = "debin.create.summary";

class CreateDebinSummary extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
        seller: shape({}).isRequired,
        recipients: arrayOf(shape({})).isRequired,
        dispatch: func.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(debinActions.setIsBackFromSummary("/charges/requestDebin"));
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1>{i18nUtils.get("debin.create.summary.title")}</h1>
        </div>
    );

    render() {
        const { isDesktop, fetching, seller, recipients } = this.props;

        const credentials = (
            <>
                {!isTokenActive(!isDesktop) ? (
                    <div className="admin-content-center">
                        <I18n id="transfer.summary.confirm.label" />
                    </div>
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}
                <div className="admin-content-center">
                    <Field
                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </>
        );

        const containerClassName = `flex-grow align-items-center container-white px-md-3 ${!isDesktop ? "mb-0" : ""}`;

        return (
            <>
                <Notification scopeToShow="debinSummary" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && (
                        <div className="d-flex">
                            <div className="view-title">
                                <h1 className="mb-0">{i18nUtils.get("debin.create.summary.title")}</h1>
                                <div className="row">
                                    <div className="px-0 col-md-8 d-flex align-items-end">
                                        <I18n
                                            id="debin.create.summary.label"
                                            componentProps={{ style: { fontFamily: "Graphik-Light" } }}
                                        />
                                    </div>
                                    <div className="col-md-4 debin__totals justify-content-end d-flex mt-md-0">
                                        <SelectedAccountsAmount
                                            list={recipients}
                                            currency={recipients && recipients[0].amount.currency}
                                            summaryView
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!isDesktop && (
                        <>
                            <div className="admin-content-center d-flex justify-content-center">
                                <I18n
                                    id="debin.create.summary.label"
                                    component="h2"
                                    componentProps={{ style: { width: "70%", margin: 0 } }}
                                />
                            </div>
                            <SelectedAccountsAmount
                                list={recipients}
                                summaryView
                                className="debin-summary-view justify-space-between container mx-auto"
                            />
                        </>
                    )}
                </div>
                <MainContainer>
                    <Form className="above-the-fold">
                        <Container className={containerClassName} gridClassName="form-content">
                            <CreateDebinSellerSummary isDesktop={isDesktop} seller={seller} />
                        </Container>

                        {recipients.map((recipient) => (
                            <Container className={containerClassName} gridClassName="form-content">
                                <CreateDebinRecipientSummary isDesktop={isDesktop} recipient={recipient} />
                            </Container>
                        ))}
                        <Container className={containerClassName} gridClassName="form-content">
                            <Col className="col col-12 px-0" lg={4} xl={4} md={9} sm={12}>
                                {credentials}
                            </Col>
                        </Container>

                        <Container
                            className="flex-grow align-items-center container-white pb-3"
                            gridClassName="form-content">
                            <Col className="col col-12" lg={4} xl={4} md={9} sm={12}>
                                <Button
                                    loading={fetching}
                                    // disabled={!values.acceptTerms}
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.confirm"
                                    image="images/arrowRight.svg"
                                    imageStyle="mr-2"
                                    className="text-capitalize"
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    recipients: debinSelectors.getRecipients(state),
    seller: debinSelectors.getDebinSeller(state),
    fetching: debinSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, seller, recipients } = formikBag.props;
            dispatch(debinActions.sendDebinRequest(otp, seller, recipients, formikBag));
        },
    }),
)(CreateDebinSummary);
