/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

import { selectors as loginSelectors } from "reducers/login";
import { selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import { selectors as enrollmentSelectors } from "reducers/enrollment";

const withExchangeToken = (Component) => ({ exchangeTokens, ...props }) => {
    const {
        match: { path },
    } = props;

    if (path.indexOf("loginStep") !== -1 && path.indexOf("loginStep1") !== -1 && !exchangeTokens.loginExchangeToken) {
        return <Redirect to="/loginStep1" />;
    }
    if (
        (path.indexOf("recoveryPassword/step") !== -1 ||
            path.indexOf("recoveryPassword/step1") !== -1 ||
            path.indexOf("recoveryPassword/step2") !== -1 ||
            path.indexOf("recoveryPassword/step3") !== -1) &&
        !exchangeTokens.recoveryPasswordExchangeToken
    ) {
        return <Redirect to="/loginStep1" />;
    }

    if (path.indexOf("/onboarding/step") !== -1 && path.indexOf("/onboarding/step1") !== -1) {
        return <Redirect to="/onboarding/step1" />;
    }
    if (
        path.indexOf("enrollment/step") !== -1 &&
        path !== "/enrollment" &&
        !exchangeTokens.enrollmentExchangeToken &&
        !exchangeTokens.onboardingExchangeToken &&
        !exchangeTokens.loginExchangeToken
    ) {
        return <Redirect to="/enrollment" />;
    }

    return <Component {...props} />;
};

const mapStateToProps = (state) => ({
    exchangeTokens: {
        loginExchangeToken: loginSelectors.getExchangeToken(state),
        recoveryPasswordExchangeToken: recoveryPasswordSelectors.getExchangeToken(state),
        onboardingExchangeToken: onboardingSelectors.getExchangeToken(state),
        enrollmentExchangeToken: enrollmentSelectors.getExchangeToken(state),
    },
});

export default compose(connect(mapStateToProps), withExchangeToken);
