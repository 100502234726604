import React, { Component, Fragment } from "react";

import * as i18nUtils from "util/i18n";

import ItemName from "pages/_components/item/Name";
import ChevromRight from "pages/_components/listItem/ChevromRight";

class BankSearchItem extends Component {
    handleClick = () => {
        this.props.onSelect(this.props);
    };

    renderData = () => {
        const { codeNumber, bankName, isDesktop } = this.props;

        if (isDesktop) {
            return (
                <Fragment>
                    <div className="table-data">
                        <div className="data-desc">{codeNumber}</div>
                    </div>
                    <div className="table-data">
                        <ItemName name={bankName} />
                    </div>
                </Fragment>
            );
        }

        return (
            <div className="table-data">
                <div className="data-aux">{codeNumber}</div>
                <ItemName name={bankName} />
            </div>
        );
    };

    render() {
        const { bankCountryLabel } = this.props;

        return (
            <div className="table-row" onClick={this.handleClick}>
                {this.renderData()}
                <div className="table-data table-data-icon">
                    <div className="data-aux">{i18nUtils.get(bankCountryLabel)}</div>
                </div>
                <ChevromRight />
            </div>
        );
    }
}

export default BankSearchItem;
