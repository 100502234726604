import React, { Component } from "react";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { shape, bool, arrayOf, func } from "prop-types";
import Pagination from "pages/_components/pagination/Pagination";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import FixedTermAcceptanceItem from "pages/fixedTermAcceptance/_components/FixedTermAcceptanceItem";
import { push } from "react-router-redux";

const tableLabel = "fixedTermAcceptance.table.header";
class FixedTermAcceptanceTable extends Component {
    static propTypes = {
        requests: arrayOf(shape({})).isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        debinObject: shape({}).isRequired,
    };

    static defaultProps = {};

    fetchMoreTransactions = (pageNumber) => {
        const { dispatch, debinObject } = this.props;
        const { status, dateFrom, dateTo, recipient, tipo } = debinObject;

        dispatch(
            debinActions.loadIncomingRequests({
                status,
                dateFrom,
                dateTo,
                recipient,
                tipo,
                pageNumber,
            }),
        );
    };

    render() {
        const { requests, isDesktop, dispatch, debinObject } = this.props;

        const list = requests?.debinList?.map((request) => {
            if (isDesktop) {
                return (
                    <Table.Row
                        className="container-white"
                        key={request.id}
                        renderAs={Link}
                        to={`/fixedTermAcceptance/detail/${request.id}`}>
                        <FixedTermAcceptanceItem
                            key={`transaction-${request.id}`}
                            request={request}
                            isDesktop={isDesktop}
                        />
                    </Table.Row>
                );
            }

            return (
                <Col
                    className="col col-12 container-white py-2 my-2"
                    onClick={() => dispatch(push(`/charges/debin/detail/${request.id}`))}>
                    <FixedTermAcceptanceItem
                        key={`transaction-${request.id}`}
                        request={request}
                        isDesktop={isDesktop}
                    />
                </Col>
            );
        });

        const renderTable = isDesktop ? (
            <>
                <Table>
                    <Table.Header className="container-white">
                        <Table.HeaderData align="left">
                            <I18n component="span" id={`${tableLabel}.id`} />
                        </Table.HeaderData>
                        <Table.HeaderData align="center">
                            <I18n id={`${tableLabel}.expiration`} />
                        </Table.HeaderData>
                        <Table.HeaderData>
                            <I18n id={`${tableLabel}.applicant`} />
                        </Table.HeaderData>
                        <Table.HeaderData>
                            <I18n id={`${tableLabel}.documentType`} />
                        </Table.HeaderData>
                        <Table.HeaderData>
                            <I18n id={`${tableLabel}.debitAccount`} />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id={`${tableLabel}.amount`} />
                        </Table.HeaderData>
                        <Table.HeaderData align="right">
                            <I18n id={`${tableLabel}.status`} />
                        </Table.HeaderData>
                        <Table.HeaderData />
                    </Table.Header>
                    <Table.Body>{list}</Table.Body>
                </Table>
                <div className="d-flex w-100 justify-content-end mr-3">
                    <Pagination
                        totalPages={debinObject.totalPages}
                        pageNumber={debinObject.pageNumber}
                        action={this.fetchMoreTransactions}
                    />
                </div>
            </>
        ) : (
            list
        );

        return (
            <Container className="flex-grow scrollable ">
                <Col className="col col-12 px-0">
                    <div
                        style={{ borderSpacing: "0px 2px", width: "100%" }}
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {requests?.debinList?.length ? (
                            renderTable
                        ) : (
                            <div className="text-center no-more-data" key="noMoreMovements">
                                <div className="illustration-wrapper">
                                    <Image src="images/coloredIcons/find-in-page.svg" className="svg-big-icon" />
                                </div>
                                <p className="text-lead" style={{ opacity: 1 }}>
                                    <I18n id="charges.debin.sentRequests.empty.message" />
                                </p>
                            </div>
                        )}
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    requests: debinSelectors.getSentRequests(state),
    debinObject: debinSelectors.getDebinObject(state),
});

export default connect(mapStateToProps)(FixedTermAcceptanceTable);
