/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import { func, shape, bool, string } from "prop-types";

export default function FilterSelect({
    columnFilter,
    changeFilter,
    isDesktop,
    orderByOptions,
    orderOptions,
    className,
    cleanFilter,
    setCleanFilter,
    defaultOrderBy,
    defaultOrder,
}) {
    const [ascendent, setAscendent] = useState(defaultOrder === "ascendent");
    const [selectedOrderBy, setSelectedOrderBy] = useState(defaultOrderBy);

    FilterSelect.propTypes = {
        columnFilter: shape({}),
        orderByOptions: shape({}).isRequired,
        orderOptions: shape({}).isRequired,
        changeFilter: func.isRequired,
        isDesktop: bool.isRequired,
        className: string,
        cleanFilter: bool.isRequired,
        setCleanFilter: func.isRequired,
        defaultOrderBy: shape({}).isRequired,
        defaultOrder: string.isRequired,
    };

    FilterSelect.defaultProps = {
        columnFilter: { filterSelected: "amount" },
        className: "",
    };

    const changeOrder = (order) => {
        setAscendent(order === "0");
        changeFilter(selectedOrderBy.value, order === "0" ? "ascendent" : "descendent");
    };

    const changeOrderBy = (orderBy) => {
        const { value } = orderBy;
        setSelectedOrderBy(orderBy);
        changeFilter(value, ascendent ? "ascendent" : "descendent");
    };

    useEffect(() => {
        if (columnFilter && columnFilter.filterSelected === selectedOrderBy) {
            setAscendent(ascendent);
        }

        if (cleanFilter) {
            setSelectedOrderBy(defaultOrderBy);
            setAscendent(defaultOrder === "ascendent");
            setCleanFilter(false);
        }
    }, [columnFilter, selectedOrderBy, cleanFilter, setCleanFilter]);

    return (
        <Col sm={12} md={12} lg={12} className="px-0 mr-4">
            <Col sm={12} lg={12} md={12} className="pl-0">
                <div className="form-group-text">
                    <label htmlFor="activity" className="control-label">
                        <I18n id="transactions.list.filters.transaction.orderby.label" />
                    </label>
                </div>
                <div className={`pending-transactions__orderby ${className}`}>
                    <div className="input-group mr-3">
                        <Select
                            name="activity"
                            className="slideFromBottom"
                            clearable={false}
                            searchable={isDesktop}
                            onChange={changeOrderBy}
                            options={orderByOptions}
                            optionClassName="needsclick"
                            placeholder={i18n.get("transactions.filters.placeholder")}
                            value={selectedOrderBy}
                            defaultValue={selectedOrderBy}
                            noResultsText={i18n.get("global.no.results")}
                        />
                    </div>
                    <RadioButtonGroup
                        inLineControl
                        name="radioGroup"
                        selectorId="radioGroup"
                        onChange={changeOrder}
                        optionClassName="needsclick"
                        options={orderOptions}
                        value={ascendent ? "0" : "1"}
                        valueKey="id"
                        labelKey="label"
                    />
                </div>
            </Col>
        </Col>
    );
}
