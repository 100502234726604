import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, func, arrayOf, string, bool } from "prop-types";
import Head from "pages/_components/Head";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import { push } from "react-router-redux";
import { selectors, actions } from "reducers/checks";
import { echeqShape, multipleEcheqsTicketData } from "util/checks";
import { chooseImage } from "util/transaction";
import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";

import { actions as transactionsActions } from "reducers/transactions";

class MultipleEcheqsTransaction extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape({}).isRequired,
        echeqAction: shape({
            action: string.isRequired,
            echeqList: arrayOf(echeqShape),
            specialAttribute: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        cesionData: shape({
            beneficiary: shape({}),
        }).isRequired,
        endorseData: shape({
            beneficiary: shape({}),
        }).isRequired,
        echeqsToEmit: arrayOf(shape({})).isRequired,
    };

    componentWillMount() {
        const { dispatch, echeqAction } = this.props;
        if (!echeqAction.action) {
            dispatch(push("/transactions/list"));
        }
    }

    componentWillUnmount = () => {
        const { dispatch } = this.props;

        dispatch(actions.cleanEcheqAction());
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    centerContentMobile = () => {
        const { echeqAction } = this.props;

        return (
            <div className="title-account-header-multiline-background-blue">
                <h1 style={{ width: "100%", margin: "-0.2rem" }}>
                    {i18nUtils.get(`multiple.echeq.transaction.${echeqAction.action.toLowerCase()}`)}
                </h1>
            </div>
        );
    };

    calculateAmount = () => {
        const { echeqAction, echeqsToEmit } = this.props;
        if (echeqAction.action.toLowerCase() !== "emit-echeq") {
            return echeqAction?.echeqList.reduce((a, b) => a + b.monto, 0);
        }

        return echeqsToEmit.reduce((a, b) => a + b.amount, 0);
    };

    goToHistory = () => {
        const { dispatch, echeqAction } = this.props;
        dispatch(push("/transactions/list"));
        if (echeqAction.action.toLowerCase() === "negotiate") {
            dispatch(
                transactionsActions.loadListRequest(
                    {
                        pageNumber: 1,
                        filterPendingDispatch: false,
                        idActivity: "negotiate.echeq.activity",
                    },
                    false,
                    false,
                ),
            );
            dispatch(
                transactionsActions.setFilters({
                    idActivity: "negotiate.echeq.activity",
                    filter: "transaction",
                }),
            );
        }
    };

    render() {
        const { echeqAction, endorseData, cesionData, dispatch, match, isDesktop, isSmallDesktop } = this.props;
        const { status } = match.params;

        if (!echeqAction.action) {
            return "";
        }

        const bodyTicketItems = multipleEcheqsTicketData(
            echeqAction.action.toLowerCase() === "emit-cesion" ? "cesion" : echeqAction.action.toLowerCase(),
        );
        return (
            <>
                <Notification scopeToShow="multipleSignaturesProcessing" />
                <div className="admin-detail-head px-0" style={{ marginTop: isDesktop && "2rem" }}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        emptyRightOption
                        centerElement={isDesktop ? null : this.centerContentMobile}
                    />
                </div>
                <MainContainer>
                    <Container className="flex-grow align-items-center">
                        <Col sm={12} md={9} lg={isSmallDesktop ? 6 : 5} className="cmf-container-white">
                            <div className="admin-content-center" style={{ marginTop: "2rem" }}>
                                <Image
                                    src={chooseImage(status)}
                                    styles={{
                                        width: isDesktop ? "80px" : "65px",
                                        height: isDesktop ? "80px" : "65px",
                                    }}
                                />
                            </div>
                            <div className="admin-content-center transaction-ticket-status">
                                <I18n id="multiple.signatures.processing" />
                            </div>
                            <div className="admin-content-center ">
                                <I18n
                                    component="h1"
                                    id={`multiple.echeq.transaction.${echeqAction.action.toLowerCase()}`}
                                    componentProps={{
                                        className: `cmf-title-center transaction-ticket-title`,
                                    }}
                                />
                            </div>
                            <Col xs={12} className="px-2 pb-4">
                                <Container className="flex-grow align-items-center mt-2">
                                    <Col sm={12} className="cmf-container-white px-0">
                                        <Heading.DataGroup
                                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                            labelClassName="data-label-medium"
                                            dataClassName="data-desc"
                                            label="multiple.echeq.date"
                                            data={
                                                <p className="data-desc m-0">
                                                    <FormattedDate date={new Date()} dateFormat="dd/MM/yyyy" showTime />
                                                </p>
                                            }
                                        />
                                        {echeqAction.action.toLowerCase() !== "emit-cesion" &&
                                            echeqAction.action.toLowerCase() !== "emit-echeq" && (
                                                <>
                                                    <Heading.DataGroup
                                                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                                        labelClassName="data-label-medium"
                                                        dataClassName="data-desc"
                                                        label="multiple.echeq.beneficiary"
                                                        data={
                                                            <p className="data-desc m-0">
                                                                {endorseData?.beneficiary?.reference.replaceAll(
                                                                    "#",
                                                                    "Ñ",
                                                                )}
                                                            </p>
                                                        }
                                                    />
                                                    <Heading.DataGroup
                                                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                                        labelClassName="data-label-medium"
                                                        dataClassName="data-desc"
                                                        label="multiple.echeq.document"
                                                        data={
                                                            <p className="data-desc m-0">
                                                                {endorseData?.beneficiary?.document?.toUpperCase()}
                                                            </p>
                                                        }
                                                    />
                                                    {bodyTicketItems.map((item) => (
                                                        <Heading.DataGroup
                                                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                                            labelClassName="data-label-medium"
                                                            dataClassName="data-desc"
                                                            label={item[Object.keys(item)[0]]}
                                                            data={
                                                                <p className="data-desc m-0">
                                                                    {Object.keys(item)[0] === "type" &&
                                                                        i18nUtils.get(
                                                                            `echeqs.${endorseData.endorseData[
                                                                                Object.keys(item)[0]
                                                                            ].toLowerCase()}.label`,
                                                                        )}
                                                                    {Object.keys(item)[0] !== "type" &&
                                                                        endorseData.endorseData[Object.keys(item)[0]]}
                                                                </p>
                                                            }
                                                        />
                                                    ))}
                                                </>
                                            )}
                                        {echeqAction.action.toLowerCase() === "emit-cesion" && (
                                            <>
                                                <Heading.DataGroup
                                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                                    labelClassName="data-label-medium"
                                                    dataClassName="data-desc"
                                                    label="multiple.echeq.beneficiary"
                                                    data={
                                                        <p className="data-desc m-0">
                                                            {cesionData?.beneficiary?.reference?.replaceAll("#", "Ñ")}
                                                        </p>
                                                    }
                                                />
                                                <Heading.DataGroup
                                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                                    labelClassName="data-label-medium"
                                                    dataClassName="data-desc"
                                                    label="multiple.echeq.document"
                                                    data={
                                                        <p className="data-desc m-0">
                                                            {cesionData?.beneficiary?.document?.toUpperCase()}
                                                        </p>
                                                    }
                                                />
                                                {bodyTicketItems.map((item) => (
                                                    <Heading.DataGroup
                                                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                                        labelClassName="data-label-medium"
                                                        dataClassName="data-desc"
                                                        label={item[Object.keys(item)[0]]}
                                                        data={
                                                            <p className="data-desc m-0">
                                                                {cesionData[Object.keys(item)[0]]}
                                                            </p>
                                                        }
                                                    />
                                                ))}
                                            </>
                                        )}
                                        <Heading.DataGroup
                                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                            labelClassName="data-label-medium"
                                            dataClassName="data-desc"
                                            label="multiple.echeq.balance.send"
                                            data={
                                                <p className="data-desc m-0">
                                                    <FormattedAmount
                                                        currency="0"
                                                        quantity={this.calculateAmount()}
                                                        notBold
                                                    />
                                                </p>
                                            }
                                        />
                                        <Heading.DataGroup
                                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                            labelClassName="data-label-medium"
                                            dataClassName="data-desc"
                                            label="multiple.echeq.amount.send"
                                            data={<p className="data-desc m-0">{echeqAction?.echeqList?.length}</p>}
                                        />
                                        <Row style={{ padding: "0 0 0 1rem" }}>
                                            <p>{i18nUtils.get("multiple.echeq.message")}</p>
                                        </Row>
                                    </Col>
                                </Container>
                            </Col>
                        </Col>
                    </Container>
                    <Container
                        className={`flex-grow align-items-center mb-1 mt-2${
                            !isDesktop ? "cmf-container-white pt-2" : ""
                        }`}
                        gridClassName="form-content">
                        <Col xs={12} sm={12} md={9} lg={isSmallDesktop ? 6 : 5} className={`"align-items-center`}>
                            <Row className="justify-content-center transaction-ticket-buttons">
                                <Button
                                    type="button"
                                    bsStyle="outline"
                                    onClick={() => this.goToHistory()}
                                    label="goto.historic.transaction"
                                />
                                <Button
                                    type="button"
                                    bsStyle="primary"
                                    onClick={() => dispatch(push("/desktop"))}
                                    label="new.check.right.buttom"
                                />
                            </Row>
                        </Col>
                    </Container>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    echeqAction: selectors.getEcheqsToDoActions(state),
    endorseData: selectors.getEcheqsEndorseData(state),
    cesionData: selectors.getEmitCesionData(state),
    echeqsToEmit: selectors.getEcheqsToEmit(state),
});

export default connect(mapStateToProps)(MultipleEcheqsTransaction);
