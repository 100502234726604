import * as API from "middleware/api";

export const recoveryPassPreview = (username, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.recoverPassword.preview",
        {
            _usernameToValidate: username,
        },
        exchangeToken,
    );

export const recoveryPassStep1 = (sendChannel, userName, secondFactor, exchangeToken, idDevice) =>
    API.executeWithExchangeToken(
        "/session.recoverPassword.step1",
        {
            _username: userName,
            _secondFactor: secondFactor,
            _sendChannel: sendChannel,
            idDevice,
        },
        exchangeToken,
    );

export const recoveryPassStep2 = (code, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.recoverPassword.step2",
        {
            _resetCode: code,
        },
        exchangeToken,
    );

export const recoveryPassStep3 = (newPassword, newPasswordConfirmation, resetCode, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.recoverPassword.step3",
        {
            _password: newPassword,
            _passwordConfirmation: newPasswordConfirmation,
            _resetCode: resetCode,
        },
        exchangeToken,
    );

export const recoveryUserpassStep0 = (password, document, documentType, isRecoveryPassAndUserpass, captcha) =>
    API.executeAnonymous("/session.recoverPin.step0", {
        _password: password,
        document,
        documentType,
        isRecoveryPassAndUserpass,
        _captcha: captcha,
    });

export const recoveryUserpassStep1 = (sendChannel, userName, secondFactor, isRecoveryPassAndUserpass, idDevice) =>
    API.executeAnonymous("/session.recoverPin.step1", {
        _username: userName,
        _secondFactor: secondFactor,
        _sendChannel: sendChannel,
        isRecoveryPassAndUserpass,
        idDevice,
    });

export const recoveryUserpassStep2 = (code) =>
    API.executeAnonymous("/session.recoverPin.step2", {
        _resetCode: code,
    });

export const recoveryUserpassStep3 = (newPassword, newPasswordConfirmation, resetCode, exchangeToken, deviceToken) =>
    API.executeWithExchangeToken(
        "/session.recoverPin.step3",
        {
            _pin: newPassword,
            _pinConfirmation: newPasswordConfirmation,
            _resetCode: resetCode,
            _deviceToken: deviceToken,
        },
        exchangeToken,
    );

export const recoveryPassAndUserpassStep1 = (code) =>
    API.executeAnonymous("session.recoverPinAndPassword.step1", {
        _accessCode: code,
    });

export const recoveryPassAndUserpassStep2 = (newPin, newPinConfirmation, resetCode, exchangeToken) =>
    API.executeWithExchangeToken(
        "/session.recoverPinAndPassword.step2",
        {
            _pin: newPin,
            _pinConfirmation: newPinConfirmation,
            _accessCode: resetCode,
        },
        exchangeToken,
    );

export const recoveryPassAndUserpassStep3 = (
    newPassword,
    newPasswordConfirmation,
    resetCode,
    exchangeToken,
    deviceToken,
) =>
    API.executeWithExchangeToken(
        "/session.recoverPinAndPassword.step3",
        {
            _password: newPassword,
            _passwordConfirmation: newPasswordConfirmation,
            _accessCode: resetCode,
            _deviceToken: deviceToken,
        },
        exchangeToken,
    );
