import axios from "axios";
import * as API from "middleware/api";
import * as config from "util/config";

export const loadEnterRequestCredentials = () => API.executeWithAccessToken("/ecomex.enterRequests", {});

export const loadRequestHistoryCredentials = () => API.executeWithAccessToken("/ecomex.requestHistory", {});

export const logoutEcomex = () => {
    const url = config.get("ecomex.service.url.logout");

    axios
        .get(url)
        .then(() => {})
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error("Error:", error);
        });
};
