import React, { Component } from "react";
import { bool, func } from "prop-types";

import PageLoading from "pages/_components/PageLoading";
import FundcorpPositionList from "pages/fundcorp/_components/FundcorpPositionList";
import FundcorpRequestList from "pages/fundcorp/_components/FundcorpRequestList";
import FundcorpMovementList from "pages/fundcorp/_components/FundcorpMovementList";
import FundcorpShareValuesList from "pages/fundcorp/_components/FundcorpShareValuesList";
import FundcorpPortfolioCompositionList from "./FundcorpPortfolioCompositionList";
import FundcorpMovementAndRequestList from "./FundcorpMovementAndRequestList";

class FundcorpList extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        optionSelected: bool.isRequired,
        isFetchingFundcorp: bool.isRequired,
        hasPositionsToday: func.isRequired,
    };

    renderList = () => {
        const { optionSelected, hasPositionsToday } = this.props;

        switch (optionSelected) {
            case "positions":
                return <FundcorpPositionList {...this.props} hasPositionsToday={hasPositionsToday} />;
            case "movements":
                return <FundcorpMovementList {...this.props} />;
            case "requests":
                return <FundcorpRequestList {...this.props} />;
            case "movementsAndRequests":
                return <FundcorpMovementAndRequestList {...this.props} />;
            case "shareValues":
                return <FundcorpShareValuesList {...this.props} />;
            case "portfolioComposition":
                return <FundcorpPortfolioCompositionList {...this.props} />;
            default:
                return null;
        }
    };

    render() {
        const { isFetchingFundcorp } = this.props;

        return (
            <PageLoading className="line-loader" loading={isFetchingFundcorp}>
                {this.renderList()}
            </PageLoading>
        );
    }
}

export default FundcorpList;
