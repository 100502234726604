import React, { Component } from "react";
import { connect } from "react-redux";
import Measure from "react-measure";
import { Col } from "react-bootstrap";
import Table from "rc-table";
import { oneOf, shape, func, string, array, oneOfType, bool } from "prop-types";
import queryString from "query-string";

import {
    actions as pushNotificationsActions,
    selectors as pushNotificationsSelectors,
} from "reducers/pushNotifications";
import { naturalDate } from "util/format";
import * as i18n from "util/i18n";
import { routerActions } from "react-router-redux/actions";
import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Image from "pages/_components/Image";
import Hint from "pages/_components/hints/Hint";
import Heading from "pages/_components/Heading";

class MyDevices extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        location: shape({ search: string }),
        isMobile: bool.isRequired,
        pushNotificationsConfiguredUserDevices: oneOfType([array.isRequired, oneOf([null]).isRequired]),
        fetching: bool,
    };

    static defaultProps = {
        pushNotificationsConfiguredUserDevices: null,
        location: shape({ search: "" }),
        fetching: false,
    };

    state = {
        tableHeight: 0,
        showMessage: false,
        showHintInfoMb: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(pushNotificationsActions.listAllUserDevices());
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    renderDevices = () => {
        const { pushNotificationsConfiguredUserDevices } = this.props;
        if (pushNotificationsConfiguredUserDevices && pushNotificationsConfiguredUserDevices.length) {
            return pushNotificationsConfiguredUserDevices.map(
                ({ idDevice, model, platform, modificationDate, lastEntryCity, lastEntryCountry, ...rest }) => (
                    <div className="container-white my-2 with-padding" style={{ display: "flex" }}>
                        <div style={{ width: "5rem", alignSelf: "center" }}>{this.renderIcon(rest)}</div>
                        <div className="">
                            <Heading.DataGroup
                                label="settings.myDevices.description"
                                data={<span>{platform}</span>}
                                containerClassName="my-0"
                            />
                            <Heading.DataGroup
                                label="settings.myDevices.data.lastLogin"
                                data={
                                    lastEntryCountry && lastEntryCity
                                        ? `${naturalDate(modificationDate)} (${lastEntryCity}, ${lastEntryCountry})`
                                        : naturalDate(modificationDate)
                                }
                                containerClassName="data-wrapper my-0"
                            />
                        </div>
                    </div>
                ),
            );
        }
        return <I18n id="settings.pushNotifications.configuredDevices.none" />;
    };

    getMobileContent = () => (
        <div className="above-the-fold">
            <div style={{ marginRight: "1rem", marginLeft: "1rem" }}>{this.renderDevices()}</div>
        </div>
    );

    generateTableColumns = () => [
        {
            key: "icon",
            dataIndex: "icon",
            title: i18n.get("settings.myDevices.data.device"),
            width: "10%",
            align: "center",
        },
        {
            key: "description",
            dataIndex: "description",
            title: i18n.get("settings.myDevices.description"),
            width: "20%",
            align: "center",
        },
        {
            key: "lastLogin",
            dataIndex: "lastLogin",
            title: i18n.get("settings.myDevices.data.lastLogin"),
            width: "35%",
            align: "center",
        },
    ];

    renderIcon = (data) => (
        <Image
            src={data.isAndroid || data.isIOS ? "images/phone-cmf.svg" : "images/desktop-cmf.svg"}
            className="svg-icon"
        />
    );

    getTableData = () => {
        const { pushNotificationsConfiguredUserDevices } = this.props;

        return pushNotificationsConfiguredUserDevices.map(
            ({ idDevice, model, platform, modificationDate, lastEntryCountry, lastEntryCity, ...rest }) => ({
                key: idDevice,
                idDeviceToShow: `****${idDevice.substring(idDevice.length - 4, idDevice.length)}`,
                icon: <div className="my-devices-name">{this.renderIcon(rest)}</div>,
                description: model ? (
                    <I18n id={`devices.apple.identifier.${model}`} defaultValue={`${model}`} />
                ) : (
                    <I18n id={`devices.platform.${platform}`} defaultValue={`${platform}`} />
                ),
                lastLogin:
                    lastEntryCountry && lastEntryCity
                        ? `${naturalDate(modificationDate)} (${lastEntryCity}, ${lastEntryCountry})`
                        : naturalDate(modificationDate),
            }),
        );
    };

    setSlectedRow = (record) => {
        const { location } = this.props;
        const { query } = queryString.parseUrl(location.search);

        if (record.key === query.idDevice) {
            return "tableNoInteraction clicked";
        }
        return "tableNoInteraction";
    };

    getDesktopContent = () => {
        const { pushNotificationsConfiguredUserDevices } = this.props;
        const { tableHeight } = this.state;

        if (pushNotificationsConfiguredUserDevices) {
            return (
                <div className="above-the-fold">
                    <Container
                        className="container--scrollable-table without-padding flex-grow align-items-center"
                        gridClassName="form-content">
                        <Col className="col col-12 px-0 container-white">
                            {tableHeight ? (
                                <Table
                                    columns={this.generateTableColumns()}
                                    data={this.getTableData()}
                                    scroll={{ y: tableHeight }}
                                    rowKey={(record) => record.key}
                                    rowClassName="container-white"
                                />
                            ) : (
                                <Measure
                                    bounds
                                    onResize={({ bounds }) => this.setState({ tableHeight: bounds.height })}>
                                    {({ measureRef }) => (
                                        <div ref={measureRef} style={{ height: "100%", width: "100%" }} />
                                    )}
                                </Measure>
                            )}
                        </Col>
                    </Container>
                </div>
            );
        }
        return null;
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("settings.myDevices")}</h1>
        </div>
    );

    changeMessageDisplay = () => {
        const { showMessage } = this.state;
        this.setState({ showMessage: !showMessage });
    };

    renderButtonInfo = () => {
        const { showHintInfoMb } = this.state;
        return (
            <button
                type="button"
                className="btn btn-hint mt-1"
                onClick={() => {
                    this.setState((prev) => ({ ...prev, showHintInfoMb: !prev.showHintInfoMb }));
                    this.changeMessageDisplay();
                }}>
                {!showHintInfoMb ? (
                    <Image src="images/infoWhite.svg" styles={{ height: "24px", width: "24px" }} />
                ) : (
                    <Image src="images/infoWhiteFull.svg" styles={{ height: "24px", width: "24px" }} />
                )}
            </button>
        );
    };

    render() {
        const { fetching, isMobile } = this.props;
        const { showMessage } = this.state;
        const { showHintInfoMb } = this.state;
        return (
            <>
                <Notification scopeToShow="pushNotifications" />

                <div className="admin-detail-head px-0 mb-4" style={isMobile ? { marginBottom: 0 } : {}}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={isMobile ? "blue-main-header-mobile" : ""}
                        centerElement={isMobile && this.centerContentMobile}
                        showHintInfoMb={showHintInfoMb}
                    />

                    {!isMobile ? (
                        <div className="view-title" style={{ display: "flex" }}>
                            <I18n id="settings.myDevices" component="h1" />

                            <Hint
                                idMessage="settings.myDevices.hint"
                                styleDiv={{ marginLeft: "1rem", marginTop: 0 }}
                                styleImage={{ alignItems: "center", display: "flex" }}
                                classNameHintP="mt-1 mb-0"
                            />
                        </div>
                    ) : (
                        <div style={{ marginLeft: "1rem", marginRight: "1rem", opacity: showMessage ? 1 : 0 }}>
                            <I18n
                                id="settings.myDevices.hint"
                                component="p"
                                componentProps={{
                                    className: "hint-text my-0 f-size-55",
                                }}
                            />
                        </div>
                    )}
                </div>

                <MainContainer showLoader={fetching}>
                    {isMobile ? this.getMobileContent() : this.getDesktopContent()}
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: pushNotificationsSelectors.getFetching(state),
    pushNotificationsConfiguredUserDevices: pushNotificationsSelectors.getPushNotificationsConfiguredUserDevices(state),
});

export default connect(mapStateToProps)(MyDevices);
