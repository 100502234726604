import React, { Component } from "react";
import { connect } from "react-redux";
import { arrayOf, string, shape, bool } from "prop-types";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Heading from "pages/_components/Heading";
import { compose } from "redux";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as checkSelector } from "reducers/checks";
import { accountDescription, echeqConceptLabel } from "util/checks";
import classNames from "classnames";

const FORM_ID = "echeqs.emit";

class EmitSummaryData extends Component {
    static propTypes = {
        emitData: shape({
            beneficiary: shape({}),
        }).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        isDesktop: bool.isRequired,
        echeqsToEmit: arrayOf(
            shape({
                amount: string.isRequired,
                date: string.isRequired,
                reference: string.isRequired,
                referencevalue: string.isRequired,
                type: string.isRequired,
            }),
        ).isRequired,
    };

    createAccountDescription = () => {
        const { emitData, accounts } = this.props;
        const account = accounts.find((a) => a.idProduct === emitData.emision);

        const { productAlias, number, currency, productType } = account;

        return accountDescription(productAlias, number, currency, productType);
    };

    render() {
        const { emitData, isDesktop, echeqsToEmit } = this.props;

        if (!emitData?.emision) {
            return "";
        }

        return (
            <>
                <Container
                    className="flex-grow align-items-center container-white px-3 pt-3 my-0"
                    gridClassName="form-content"
                    rowClassName="justify-content-start">
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            dataClassName="data-label light-font"
                            label={`${FORM_ID}.emision.label`}
                            data={this.createAccountDescription()}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            dataClassName="data-label light-font"
                            label="echeqs.add.beneficiary"
                            data={emitData?.beneficiary?.reference?.replaceAll("#", "Ñ")}
                        />
                    </Col>
                    <Col xs={12} md={6} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            dataClassName="data-label light-font"
                            label="echeqs.beneficiario_documento"
                            data={emitData?.beneficiary?.document.toUpperCase()}
                        />
                    </Col>
                </Container>
                <Container
                    className={classNames("flex-grow align-items-center container-white px-3 mt-0 ", {
                        "pb-3 mb-2": isDesktop,
                        "pb-0 mb-0": !isDesktop,
                    })}
                    dataClassName="f-size-4 light-font"
                    gridClassName="form-content"
                    rowClassName="justify-content-start">
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            dataClassName="data-label light-font"
                            label={`${FORM_ID}.concept.label`}
                            data={
                                <span>
                                    {emitData?.concept.toUpperCase()} {echeqConceptLabel(emitData?.concept, "-")}
                                </span>
                            }
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            dataClassName="data-label light-font"
                            label={`${FORM_ID}.motive.label`}
                            data={emitData?.motive}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            dataClassName="data-label light-font"
                            label={`${FORM_ID}.mode.label`}
                            data={emitData?.mode}
                        />
                    </Col>
                    <Col xs={12} md={3} className="px-1">
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                            dataClassName="data-label light-font"
                            label={`${FORM_ID}.character.label`}
                            data={emitData?.character}
                        />
                    </Col>
                </Container>
                {echeqsToEmit.map((echeq) => (
                    <Container
                        className={classNames("flex-grow align-items-center container-white", {
                            "p-3 mb-2": !isDesktop,
                            "p-3": isDesktop,
                        })}
                        gridClassName="form-content">
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-label light-font"
                                label={`${FORM_ID}.date.label`}
                                data={<FormattedDate date={echeq.date} />}
                            />
                        </Col>
                        <Col xs={12} md={6} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-label light-font"
                                label={`${FORM_ID}.type.label`}
                                data={i18n.get(`${FORM_ID}.${echeq?.type.toLowerCase()}.label`)}
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-label light-font"
                                label="echeqs.emit.amount.label"
                                data={
                                    <FormattedAmount
                                        currency={i18n.get("currency.label.0")}
                                        quantity={echeq?.amount}
                                        fontClassName="data-label light-font mr-0"
                                        notBold
                                    />
                                }
                            />
                        </Col>
                        <Col xs={12} md={3} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-label light-font"
                                label={`${FORM_ID}.reference.label`}
                                data={echeq?.reference ? echeq?.reference : "-"}
                            />
                        </Col>
                        <Col xs={12} md={9} className="px-1">
                            <Heading.DataGroup
                                containerClassName={`transfer-data data-wrapper-flex ${
                                    isDesktop ? "" : "space-between"
                                }`}
                                dataClassName="data-label light-font"
                                label={`${FORM_ID}.referencevalue.label`}
                                data={echeq?.referencevalue ? echeq?.referencevalue : "-"}
                            />
                        </Col>
                    </Container>
                ))}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userId: sessionSelectors.getUser(state).userId,
    accounts: checkSelector.getAccounts(state),
});

export default compose(connect(mapStateToProps))(EmitSummaryData);
