/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, shape } from "prop-types";
import { selectors as transactionsSelectors } from "reducers/transactions";
import FormattedAmount from "pages/_components/FormattedAmount";
import { getAccountDescription } from "util/accounts";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import * as i18n from "util/i18n";

class TransferInternalDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;

        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                data: { formikBag, creditReference },
            } = object.transaction;

            const [currency] = Object.keys(object.transactionAmounts);
            const amount = object.transactionAmounts[currency];

            return (
                <CardContainer className="confirmation__cards-container mt-0">
                    <Card
                        className="confirmation__cards"
                        icon={isDesktop && "images/transfer-PENDING.svg"}
                        iconClass="card__pending-transaction-icon"
                        title={
                            !isDesktop &&
                            formikBag?.props?.summary?.debitAccount && (
                                <div className="confirmation-cards__title">
                                    <div className="confirmation-cards__title-data">
                                        <span>{i18n.get("table.transferInternal.debitAccount")}</span>
                                    </div>
                                    <div className="confirmation-cards__title-info">
                                        <span>{getAccountDescription(formikBag?.props?.summary?.debitAccount)}</span>
                                    </div>
                                </div>
                            )
                        }
                        content={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    {formikBag?.props?.summary?.debitAccount && (
                                        <div className="confirmation-cards__content pb-1">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.transferInternal.debitAccount")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {getAccountDescription(formikBag?.props?.summary?.debitAccount)}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {formikBag?.props?.summary?.creditAccount && (
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.transferInternal.creditAccount")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {getAccountDescription(formikBag?.props?.summary?.creditAccount)}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {creditReference && (
                                        <div className="confirmation-cards__content info-type-b">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.transferInternal.reference")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    {creditReference}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {formikBag?.props?.summary?.creditAccount && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.transferInternal.creditAccount")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {getAccountDescription(formikBag?.props?.summary?.creditAccount)}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {creditReference && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.transferInternal.reference")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    {creditReference}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("table.transferInternal.idTransaction")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{idTransaction.substring(0, 8).toUpperCase()}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        bottom={
                            isDesktop && (
                                <div className="confirmation-cards__bottom">
                                    <div className="confirmation-cards__bottom-data">
                                        <span>{i18n.get("table.transferInternal.idTransaction")}</span>
                                    </div>
                                    <div className="confirmation-cards__bottom-info">
                                        <span>{idTransaction.substring(0, 8).toUpperCase()}</span>
                                    </div>
                                </div>
                            )
                        }
                        rightContent={
                            <div className="confirmation-cards__right">
                                <div className="confirmation-cards__right-data">
                                    <FormattedAmount currency={currency} quantity={amount} />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            );
        });
    };

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(TransferInternalDataTable);
