import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";
import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import { actions as formActions } from "reducers/form";
import ModifyPendingTransactionModal from "pages/transactions/_components/ModifyPendingTransactionModal";
import PrestacionTransactionData from "pages/debinPayments/_components/recurrentDebin/PrestacionTransactionData";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";
import { selectors as debinSelectors } from "reducers/debin";
import ContextMenu from "pages/_components/ContextMenu";
import TransactionTicket from "pages/_components/TransactionTicket";

const FORM_ID = "debin.recurrency";

class PrestacionTransaction extends Component {
    static propTypes = {
        debinPrestacion: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
    };

    state = {
        isModalDisplayed: false,
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { dispatch, debinPrestacion } = this.props;
        if (!debinPrestacion.idTransaction) {
            dispatch(push("/transactions/list"));
        }
    }

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", marginTop: "2px" }}>{i18n.get("debin.recurrency.transaction.load.title")}</h1>
        </div>
    );

    summaryDataComponent = () => <PrestacionTransactionData />;

    signTransaction = () => {
        const { dispatch, debinPrestacion } = this.props;

        const signTransactionData = this.summaryDataComponent();

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: debinPrestacion?.idTransaction,
                idActivity: debinPrestacion.idActivity,
                idForm: "",
                content: signTransactionData,
                title: "debin.recurrency.transaction.load.title",
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: debinPrestacion.idActivity,
                idTransaction: debinPrestacion.idTransaction,
                idForm: "",
                ticketData: debinPrestacion,
            }),
        );
    };

    rightContentTransactionScheme = () => {
        const { isDesktop, debinPrestacion } = this.props;

        if (debinPrestacion.idTransactionStatus === "FINISHED") {
            return (
                <ContextMenu
                    isDesktop={isDesktop}
                    buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                    items={[
                        {
                            label: "global.download",
                            onClick: this.exportPdf,
                        },
                    ]}
                />
            );
        }

        return (
            <>
                {debinPrestacion?.idTransactionStatus !== "CANCELLED" && (
                    <ContextMenu
                        isDesktop={isDesktop}
                        buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                        items={[
                            {
                                label: "pendingTransaction.info.scheme",
                                onClick: () => this.setState({ showDrawerMobile: true }),
                            },
                        ]}
                    />
                )}
            </>
        );
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { debinPrestacion } = this.props;

        const ticketData = {
            ticketName: i18n.get("debin.recurrency.transaction.load.title"),
            ticketBody: [
                getGeneralTicketItem("negotiate.transaction.date.label", debinPrestacion?.submitDateTime, "datefulltime"),
                getGeneralTicketItem(
                    "payments.debin.detail.table.header.voucher",
                    debinPrestacion.idTransaction.substring(0, 8).toUpperCase(),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.account.title`, debinPrestacion?.accountFull, "string"),
                getGeneralTicketItem(`${FORM_ID}.seller.name.title`, debinPrestacion?.sellerInfo?.titular, "string"),
                getGeneralTicketItem(`${FORM_ID}.seller.cuit.title`, debinPrestacion?.seller, "string"),
                getGeneralTicketItem(
                    `${FORM_ID}.prestacion.title`,
                    debinPrestacion?.prestacionSeleccionada?.nombre,
                    "string",
                ),
                getGeneralTicketItem(
                    `${FORM_ID}.concept.title`,
                    debinPrestacion?.concept
                        ? `${debinPrestacion?.concept} - ${i18n.get(`transfers.concept.${debinPrestacion?.concept}`)}`
                        : "",
                    "string",
                ),
                getGeneralTicketItem(
                    `${FORM_ID}.reference.title`,
                    debinPrestacion?.prestacionSeleccionada?.ayuda_referencia,
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.descripcion.title`, debinPrestacion?.description, "string"),
                getGeneralTicketItem(`${FORM_ID}.coelsa.response`, debinPrestacion?.coelsaResponse, "string"),
                getGeneralTicketItem(`${FORM_ID}.coelsa.code`, debinPrestacion?.coelsaCode, "string"),
            ],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    buttonsDetail = () => {
        const { dispatch, debinPrestacion } = this.props;

        if (debinPrestacion) {
            const { idTransactionStatus } = debinPrestacion;
            if (idTransactionStatus !== "PENDING") {
                return [
                    {
                        label: "debin.recurrency.goToRecurrencyList",
                        bsStyle: "primary",
                        onClick: () => dispatch(push("/payments/recurrent")),
                    },
                ];
            }
        }
        return [
            {
                label: "debin.recurrency.goToRecurrencyList",
                bsStyle: "primary",
                onClick: () => dispatch(push("/payments/recurrent")),
            },
        ];
    };

    render() {
        const { debinPrestacion, isDesktop, dispatch, isSmallDesktop, fetching } = this.props;
        const { isModalDisplayed, showDrawerMobile, fetchingDownload } = this.state;

        return (
            <>
                {debinPrestacion?.idTransaction && (
                    <TransactionTicket
                        notificationScope="debin.ticket"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        uniqueDownload={debinPrestacion?.idTransactionStatus === "FINISHED"}
                        emptyRightOption={
                            (isDesktop && debinPrestacion?.idTransactionStatus !== "FINISHED") ||
                            (!isDesktop &&
                                debinPrestacion?.idTransactionStatus !== "PENDING" &&
                                debinPrestacion?.idTransactionStatus !== "FINISHED")
                        }
                        rightContent={
                            debinPrestacion?.idTransactionStatus === "PENDING" && this.rightContentTransactionScheme
                        }
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={debinPrestacion}
                        buttonsDetail={this.buttonsDetail()}
                        fetching={fetching}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        showDrawerMobile={showDrawerMobile}
                        closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                        onSignTicket={this.signTransaction}
                        ignoreHomeButton
                        summaryTitle="debin.recurrency.transaction.load.title"
                        isFetchingExport={fetchingDownload}>
                        <ModifyPendingTransactionModal
                            isDesktop={isDesktop}
                            isDisplayed={isModalDisplayed}
                            handleDismiss={this.handleDismiss}
                            dispatch={dispatch}
                            idTransaction={debinPrestacion.idTransaction}
                        />
                        <PrestacionTransactionData isDesktop={isDesktop} />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    debinPrestacion: debinSelectors.getdebinPrestacionData(state),
    fetching: debinSelectors.getFetching(state),
});

export default connect(mapStateToProps)(PrestacionTransaction);
