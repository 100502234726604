import { globalTypes } from "reducers/types/global";

export const types = {
    FILTER_CHANGE: "ecomex/FILTER_CHANGE",
    ECOMEX_OPERATION_TYPE_CHANGE_REQUEST: "ecomex/ECOMEX_OPERATION_TYPE_CHANGE_REQUEST",
    ECOMEX_OPERATION_TYPE_CHANGE_FAILURE: "ecomex/ECOMEX_OPERATION_TYPE_CHANGE_FAILURE",
    ECOMEX_OPERATION_TYPE_CHANGE_SUCCESS: "ecomex/ECOMEX_OPERATION_TYPE_CHANGE_SUCCESS",

    LIST_ECOMEX_OPERATIONS_REQUEST: "ecomex/LIST_ECOMEX_OPERATIONS_REQUEST",
    LIST_ECOMEX_OPERATIONS_FAILURE: "ecomex/LIST_ECOMEX_OPERATIONS_FAILURE",
    LIST_ECOMEX_OPERATIONS_SUCCESS: "ecomex/LIST_ECOMEX_OPERATIONS_SUCCESS",
    MORE_LIST_ECOMEX_OPERATIONS_REQUEST: "ecomex/MORE_LIST_ECOMEX_OPERATIONS_REQUEST",
    MORE_LIST_ECOMEX_OPERATIONS_FAILURE: "ecomex/MORE_LIST_ECOMEX_OPERATIONS_FAILURE",
    MORE_LIST_ECOMEX_OPERATIONS_SUCCESS: "ecomex/MORE_LIST_ECOMEX_OPERATIONS_SUCCESS",
    LIST_ECOMEX_OPERATIONS_PRE_REQUEST: "ecomex/LIST_ECOMEX_OPERATIONS_PRE_REQUEST",
    LIST_ECOMEX_OPERATIONS_PRE_FAILURE: "ecomex/LIST_ECOMEX_OPERATIONS_PRE_FAILURE",
    LIST_ECOMEX_OPERATIONS_PRE_SUCCESS: "ecomex/LIST_ECOMEX_OPERATIONS_PRE_SUCCESS",
    DOWNLOAD_LIST_ECOMEX_OPERATIONS_REQUEST: "ecomex/DOWNLOAD_LIST_ECOMEX_OPERATIONS_REQUEST",
    DOWNLOAD_LIST_ECOMEX_OPERATIONS_FAILURE: "ecomex/DOWNLOAD_LIST_ECOMEX_OPERATIONS_FAILURE",
    DOWNLOAD_LIST_ECOMEX_OPERATIONS_SUCCESS: "ecomex/DOWNLOAD_LIST_ECOMEX_OPERATIONS_SUCCESS",
    SAVE_OPTION_SELECTED: "ecomex/SAVE_OPTION_SELECTED",
    CLOSE_OPERATION_DETAIL: "ecomex/CLOSE_OPERATION_DETAIL",
    ECOMEX_OPERATION_DETAIL_REQUEST: "ecomex/ECOMEX_OPERATION_DETAIL_REQUEST",
    ECOMEX_OPERATION_DETAIL_FAILURE: "ecomex/ECOMEX_OPERATION_DETAIL_FAILURE",
    ECOMEX_OPERATION_DETAIL_SUCCESS: "ecomex/ECOMEX_OPERATION_DETAIL_SUCCESS",
    DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_REQUEST: "ecomex/DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_REQUEST",
    DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_FAILURE: "ecomex/DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_FAILURE",
    DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_SUCCESS: "ecomex/DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_SUCCESS",

    LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST: "ecomex/LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST",
    LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE: "ecomex/LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE",
    LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS: "ecomex/LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS",
    MORE_LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST: "ecomex/MORE_LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST",
    MORE_LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE: "ecomex/MORE_LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE",
    MORE_LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS: "ecomex/MORE_LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS",
    LIST_ECOMEX_INFORMATIVE_REGIME_PRE_REQUEST: "ecomex/LIST_ECOMEX_INFORMATIVE_REGIME_PRE_REQUEST",
    LIST_ECOMEX_INFORMATIVE_REGIME_PRE_FAILURE: "ecomex/LIST_ECOMEX_INFORMATIVE_REGIME_PRE_FAILURE",
    LIST_ECOMEX_INFORMATIVE_REGIME_PRE_SUCCESS: "ecomex/LIST_ECOMEX_INFORMATIVE_REGIME_PRE_SUCCESS",
    DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST: "ecomex/DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST",
    DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE: "ecomex/DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE",
    DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS: "ecomex/DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS",
    SAVE_OPTION_SELECTED_FOR_REGIME: "ecomex/SAVE_OPTION_SELECTED_FOR_REGIME",
    CLOSE_INFORMATIVE_REGIME_DETAIL: "ecomex/CLOSE_INFORMATIVE_REGIME_DETAIL",
    ECOMEX_INFORMATIVE_REGIME_DETAIL_REQUEST: "ecomex/ECOMEX_INFORMATIVE_REGIME_DETAIL_REQUEST",
    ECOMEX_INFORMATIVE_REGIME_DETAIL_FAILURE: "ecomex/ECOMEX_INFORMATIVE_REGIME_DETAIL_FAILURE",
    ECOMEX_INFORMATIVE_REGIME_DETAIL_SUCCESS: "ecomex/ECOMEX_INFORMATIVE_REGIME_DETAIL_SUCCESS",
    DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_REQUEST: "ecomex/DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_REQUEST",
    DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_FAILURE: "ecomex/DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_FAILURE",
    DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_SUCCESS: "ecomex/DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_SUCCESS",
    FILTER_CHANGE_FOR_INFORMATIVE_REGIME: "ecomex/FILTER_CHANGE_FOR_INFORMATIVE_REGIME",

    LIST_ECOMEX_ALERTS_AND_DUES_REQUEST: "ecomex/LIST_ECOMEX_ALERTS_AND_DUES_REQUEST",
    LIST_ECOMEX_ALERTS_AND_DUES_FAILURE: "ecomex/LIST_ECOMEX_ALERTS_AND_DUES_FAILURE",
    LIST_ECOMEX_ALERTS_AND_DUES_SUCCESS: "ecomex/LIST_ECOMEX_ALERTS_AND_DUES_SUCCESS",
    DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_REQUEST: "ecomex/DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_REQUEST",
    DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_FAILURE: "ecomex/DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_FAILURE",
    DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_SUCCESS: "ecomex/DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS",
    MORE_LIST_ECOMEX_ALERTS_AND_DUES_REQUEST: "ecomex/MORE_LIST_ECOMEX_ALERTS_AND_DUES_REQUEST",
    MORE_LIST_ECOMEX_ALERTS_AND_DUES_FAILURE: "ecomex/MORE_LIST_ECOMEX_ALERTS_AND_DUES_FAILURE",
    MORE_LIST_ECOMEX_ALERTS_AND_DUES_SUCCESS: "ecomex/MORE_LIST_ECOMEX_ALERTS_AND_DUES_SUCCESS",
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingDownload: false,
    ecomexOperations: [],
    operationsTypes: [],
    beneficiaryNames: [],
    filtersData: null,
    optionSelected: "1",
    selectedOperation: null,
    filter: {
        filterSelected: "originDate",
        directionFilter: "ascendent",
    },
    pageNumber: 1,
    numberPerPageOptions: [],
    numberPerPage: 0,
    totalPages: 0,
    settlementsDetail: [],
    notificationsDetail: [],
    swiftMessagesDetail: [],
    ticketsDetail: [],
    duesDetail: [],
    dueDatesDetail: [],
    usesDetail: [],

    ecomexInformativeRegime: [],
    negociationsDetail: [],
    affectationsDetail: [],
    impurationsAndNegotiations: [],
    selectedInformativeRegime: null,
    advanceExportPaymentsDetail: null,
    boardingPermitsNegotiations: [],
    ecomexAlerts: [],
    ecomexDues: [],
    ecomexStates: [],
    ecomexState: null,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        ecomexOperations,
        operationsTypes,
        beneficiaryNames,
        optionSelected,
        ecomexState,
        operationType,
        beneficiaryName,
        operationNumber,
        openDateFrom,
        openDateTo,
        dueDateFrom,
        dueDateTo,
        selectedOperation,
        filter,
        pageNumber,
        numberPerPageOptions,
        numberPerPage,
        settlementsDetail,
        notificationsDetail,
        swiftMessagesDetail,
        ticketsDetail,
        duesDetail,
        dueDatesDetail,
        usesDetail,
        destination,
        payDateFrom,
        payDateTo,
        officialDateFrom,
        officialDateTo,
        settlementDateFrom,
        settlementDateTo,
        embDateFrom,
        embDateTo,
        ecomexInformativeRegime,
        negociationsDetail,
        affectationsDetail,
        impurationsAndNegotiations,
        selectedInformativeRegime,
        advanceExportPaymentsDetail,
        boardingPermitsNegotiations,
        ecomexAlerts,
        ecomexDues,
        ecomexStates,
        totalPages,
    } = action;

    switch (action.type) {
        case types.ECOMEX_OPERATION_TYPE_CHANGE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ECOMEX_OPERATION_TYPE_CHANGE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.ECOMEX_OPERATION_TYPE_CHANGE_SUCCESS:
            return {
                ...state,
                fetching: false,
                operationsTypes,
            };

        case types.LIST_ECOMEX_OPERATIONS_REQUEST:
            return {
                ...state,
                fetching: true,
                filtersData: {
                    optionSelected,
                    ecomexState,
                    operationType,
                    beneficiaryName,
                    operationNumber,
                    openDateFrom,
                    openDateTo,
                    dueDateFrom,
                    dueDateTo,
                },
            };
        case types.LIST_ECOMEX_OPERATIONS_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_ECOMEX_OPERATIONS_SUCCESS:
            return {
                ...state,
                fetching: false,
                fetchingDownload: false,
                ecomexOperations,
                pageNumber,
                numberPerPage,
                totalPages,
            };
        case types.MORE_LIST_ECOMEX_OPERATIONS_REQUEST:
            return {
                ...state,
            };
        case types.MORE_LIST_ECOMEX_OPERATIONS_FAILURE:
            return {
                ...state,
            };
        case types.MORE_LIST_ECOMEX_OPERATIONS_SUCCESS:
            return {
                ...state,
                ecomexOperations: state.ecomexOperations.concat(ecomexOperations),
                pageNumber,
                numberPerPage,
                totalPages,
            };
        case types.LIST_ECOMEX_OPERATIONS_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LIST_ECOMEX_OPERATIONS_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_ECOMEX_OPERATIONS_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                ecomexOperations,
                operationsTypes,
                beneficiaryNames,
                numberPerPage: numberPerPageOptions[0],
                numberPerPageOptions,
                totalPages,
                pageNumber: 1,
                filtersData: {
                    optionSelected: "1",
                    ecomexState: "1",
                    operationType: "all",
                    beneficiaryName: "all",
                    operationNumber: "",
                    openDateFrom: "",
                    openDateTo: "",
                    dueDateFrom: "",
                    dueDateTo: "",
                },
            };
        case types.DOWNLOAD_LIST_ECOMEX_OPERATIONS_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_LIST_ECOMEX_OPERATIONS_FAILURE:
        case types.DOWNLOAD_LIST_ECOMEX_OPERATIONS_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };

        case types.SAVE_OPTION_SELECTED:
            return {
                ...state,
                optionSelected,
                filtersData: {
                    optionSelected,
                    ecomexState,
                    operationType,
                    beneficiaryName,
                    operationNumber,
                    openDateFrom,
                    openDateTo,
                    dueDateFrom,
                    dueDateTo,
                },
                ecomexOperations: [],
                ecomexInformativeRegime: [],
            };

        case types.CLOSE_OPERATION_DETAIL:
            return {
                ...state,
                selectedOperation: null,
            };
        case types.FILTER_CHANGE:
            return {
                ...state,
                filter: {
                    filterSelected: filter.filterSelected,
                    directionFilter: filter.directionFilter,
                },
                ecomexOperations: ecomexOperations.sort((operation1, operation2) => {
                    const ascendentValue = filter.directionFilter === "ascendent" ? 1 : -1;

                    if (filter.filterSelected === "ecomexState") {
                        let state1 = operation1.siglaOperacion;
                        state1 += operation1.siglaOperacion;
                        state1 += operation1.numeroOperacion;
                        let state2 = operation2.siglaOperacion;
                        state2 += operation2.siglaOperacion;
                        state2 += operation2.numeroOperacion;

                        return ascendentValue * state1.localeCompare(state2);
                    }
                    if (filter.filterSelected === "nombreBeneficiario" || filter.filterSelected === "siglaDivisa") {
                        return (
                            ascendentValue *
                            operation1[filter.filterSelected].localeCompare(operation2[filter.filterSelected])
                        );
                    }
                    if (filter.filterSelected === "fechaOrigen" || filter.filterSelected === "fechaVencimiento") {
                        const getDateFromFormattedText = (dateString) => {
                            const dateParts = dateString.split("/");

                            const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                            return dateObject;
                        };
                        return (
                            ascendentValue *
                            (getDateFromFormattedText(operation1[filter.filterSelected]) -
                                getDateFromFormattedText(operation2[filter.filterSelected]))
                        );
                    }

                    const obtainNumberFromFormattedText = (formattedNumer) => {
                        if (formattedNumer) {
                            return Number(formattedNumer.replace(",", "").replace(".", ""));
                        }
                        return null;
                    };

                    return (
                        ascendentValue *
                        (obtainNumberFromFormattedText(operation1[filter.filterSelected]) -
                            obtainNumberFromFormattedText(operation2[filter.filterSelected]))
                    );
                }),
            };
        case types.ECOMEX_OPERATION_DETAIL_REQUEST:
            return {
                ...state,
                fetching: true,
                selectedOperation,
            };
        case types.ECOMEX_OPERATION_DETAIL_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.ECOMEX_OPERATION_DETAIL_SUCCESS:
            return {
                ...state,
                fetching: false,
                settlementsDetail,
                notificationsDetail,
                swiftMessagesDetail,
                ticketsDetail,
                duesDetail,
                dueDatesDetail,
                usesDetail,
            };

        case types.DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_FAILURE:
        case types.DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };

        case types.LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST:
            return {
                ...state,
                fetching: true,
                filtersData: {
                    optionSelected,
                    ecomexState,
                    operationType,
                    destination,
                    operationNumber,
                    payDateFrom,
                    payDateTo,
                    dueDateFrom,
                    dueDateTo,
                    officialDateFrom,
                    officialDateTo,
                    settlementDateFrom,
                    settlementDateTo,
                    embDateFrom,
                    embDateTo,
                },
            };
        case types.LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS:
            return {
                ...state,
                fetching: false,
                fetchingDownload: false,
                ecomexInformativeRegime,
                pageNumber,
                numberPerPage,
                totalPages,
            };
        case types.MORE_LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST:
            return {
                ...state,
            };
        case types.MORE_LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE:
            return {
                ...state,
            };
        case types.MORE_LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS:
            return {
                ...state,
                ecomexInformativeRegime: state.ecomexInformativeRegime.concat(ecomexInformativeRegime),
                pageNumber,
                numberPerPage,
                totalPages,
            };
        case types.LIST_ECOMEX_INFORMATIVE_REGIME_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LIST_ECOMEX_INFORMATIVE_REGIME_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_ECOMEX_INFORMATIVE_REGIME_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                ecomexInformativeRegime,
                operationsTypes,
                numberPerPage: numberPerPageOptions[0],
                numberPerPageOptions,
                totalPages,
                pageNumber: 1,
                ecomexStates,
                filtersData: {
                    optionSelected,
                    ecomexState,
                    operationsTypes: operationsTypes && operationsTypes[0] && operationsTypes[0].siglaOperacion,
                    destination: "",
                    operationNumber: "",
                    payDateFrom: "",
                    payDateTo: "",
                    dueDateFrom: "",
                    dueDateTo: "",
                    officialDateFrom: "",
                    officialDateTo: "",
                    settlementDateFrom: "",
                    settlementDateTo: "",
                    embDateFrom: "",
                    embDateTo: "",
                },
            };
        case types.DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_FAILURE:
        case types.DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };

        case types.SAVE_OPTION_SELECTED_FOR_REGIME:
            return {
                ...state,
                optionSelected,
                filtersData: {
                    ecomexState,
                    operationType,
                    destination,
                    operationNumber,
                    payDateFrom,
                    payDateTo,
                    dueDateFrom,
                    dueDateTo,
                    officialDateFrom,
                    officialDateTo,
                    settlementDateFrom,
                    settlementDateTo,
                    embDateFrom,
                    embDateTo,
                },
                ecomexInformativeRegime: [],
            };
        case types.ECOMEX_INFORMATIVE_REGIME_DETAIL_REQUEST:
            return {
                ...state,
                fetching: true,
                selectedInformativeRegime,
            };
        case types.ECOMEX_INFORMATIVE_REGIME_DETAIL_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.ECOMEX_INFORMATIVE_REGIME_DETAIL_SUCCESS:
            return {
                ...state,
                fetching: false,
                negociationsDetail,
                affectationsDetail,
                advanceExportPaymentsDetail,
                impurationsAndNegotiations,
                boardingPermitsNegotiations,
            };
        case types.DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_FAILURE:
        case types.DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.CLOSE_INFORMATIVE_REGIME_DETAIL:
            return {
                ...state,
                selectedInformativeRegime: null,
            };

        case types.FILTER_CHANGE_FOR_INFORMATIVE_REGIME:
            return {
                ...state,
                filter: {
                    filterSelected: filter.filterSelected,
                    directionFilter: filter.directionFilter,
                },
                ecomexInformativeRegime: ecomexInformativeRegime.sort((operation1, operation2) => {
                    const ascendentValue = filter.directionFilter === "ascendent" ? 1 : -1;

                    const textToFilter = [
                        "referenciaOperacion",
                        "codigoConceptoBCRA",
                        "siglaDivisa",
                        "estado",
                        "nroBol",
                        "tipoOperacion",
                        "destinacion",
                        "condicionVenta",
                        "siglaDivisaFOB",
                        "siglaDivisaFLETE",
                        "siglaDivisaSEGURO",
                    ];

                    if (textToFilter.includes(filter.filterSelected)) {
                        return (
                            ascendentValue *
                            operation1[filter.filterSelected].localeCompare(operation2[filter.filterSelected])
                        );
                    }

                    const datesToFilter = [
                        "fechaPago",
                        "fechaVencimientoOriginal",
                        "fechaLiquidacion",
                        "fechaPreFinanciacionOriginal",
                        "fechaOficializacion",
                        "fechaConfirmacion",
                        "fechaVencimiento",
                    ];

                    if (datesToFilter.includes(filter.filterSelected)) {
                        const getDateFromFormattedText = (dateString) => {
                            const dateParts = dateString.split("/");

                            const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                            return dateObject;
                        };
                        return (
                            ascendentValue *
                            (getDateFromFormattedText(operation1[filter.filterSelected]) -
                                getDateFromFormattedText(operation2[filter.filterSelected]))
                        );
                    }

                    const obtainNumberFromFormattedText = (formattedNumer) => {
                        if (formattedNumer) {
                            return Number(formattedNumer.replace(",", "").replace(".", ""));
                        }
                        return null;
                    };

                    return (
                        ascendentValue *
                        (obtainNumberFromFormattedText(operation1[filter.filterSelected]) -
                            obtainNumberFromFormattedText(operation2[filter.filterSelected]))
                    );
                }),
            };

        case types.LIST_ECOMEX_ALERTS_AND_DUES_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LIST_ECOMEX_ALERTS_AND_DUES_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_ECOMEX_ALERTS_AND_DUES_SUCCESS:
            return {
                ...state,
                fetching: false,
                ecomexAlerts,
                ecomexDues,
                numberPerPageOptions,
                optionSelected,
                numberPerPage,
                pageNumber,
                totalPages,
            };
        case types.MORE_LIST_ECOMEX_ALERTS_AND_DUES_REQUEST:
            return {
                ...state,
                fetching: false,
            };
        case types.MORE_LIST_ECOMEX_ALERTS_AND_DUES_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.MORE_LIST_ECOMEX_ALERTS_AND_DUES_SUCCESS:
            return {
                ...state,
                ecomexAlerts: state.ecomexAlerts.concat(ecomexAlerts),
                ecomexDues: state.ecomexDues.concat(ecomexDues),
                numberPerPageOptions,
                optionSelected,
                numberPerPage,
                pageNumber,
                totalPages,
            };
        case types.DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_FAILURE:
        case types.DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.FILTER_CHANGE_FOR_ALERTS_AND_DUES:
            return {
                ...state,
                filter: {
                    filterSelected: filter.filterSelected,
                    directionFilter: filter.directionFilter,
                },
                ecomexAlerts: ecomexAlerts.sort((operation1, operation2) => {
                    const ascendentValue = filter.directionFilter === "ascendent" ? 1 : -1;

                    if (filter.filterSelected === "refOperation") {
                        let state1 = operation1.siglaOperacion;
                        state1 += operation1.siglaOperacion;
                        state1 += operation1.numeroOperacion;

                        let state2 = operation2.siglaOperacion;
                        state2 += operation2.siglaOperacion;
                        state2 += operation2.numeroOperacion;

                        return ascendentValue * state1.localeCompare(state2);
                    }

                    const textToFilter = ["nombreBeneficiario", "siglaDivisa"];

                    if (textToFilter.includes(filter.filterSelected)) {
                        return (
                            ascendentValue *
                            operation1[filter.filterSelected].localeCompare(operation2[filter.filterSelected])
                        );
                    }

                    const datesToFilter = ["fechaOrigen", "fechaVencimiento"];

                    if (datesToFilter.includes(filter.filterSelected)) {
                        const getDateFromFormattedText = (dateString) => {
                            const dateParts = dateString.split("/");

                            const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                            return dateObject;
                        };
                        return (
                            ascendentValue *
                            (getDateFromFormattedText(operation1[filter.filterSelected]) -
                                getDateFromFormattedText(operation2[filter.filterSelected]))
                        );
                    }

                    const obtainNumberFromFormattedText = (formattedNumer) => {
                        if (formattedNumer) {
                            return Number(formattedNumer.replace(",", "").replace(".", ""));
                        }

                        return null;
                    };

                    return (
                        ascendentValue *
                        (obtainNumberFromFormattedText(operation1[filter.filterSelected]) -
                            obtainNumberFromFormattedText(operation2[filter.filterSelected]))
                    );
                }),
            };
        case globalTypes.RESET_SELECTED_ELEMENTS:
        case globalTypes.CLEAN_UP:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export const actions = {
    filterChange: (ecomexOperations, filter) => ({
        type: types.FILTER_CHANGE,
        ecomexOperations,
        filter,
    }),
    ecomexOperationTypeChange: (optionSelected) => ({
        type: types.ECOMEX_OPERATION_TYPE_CHANGE_REQUEST,
        optionSelected,
    }),

    listEcomexOperationsPre: (optionSelected) => ({
        type: types.LIST_ECOMEX_OPERATIONS_PRE_REQUEST,
        optionSelected,
    }),
    listEcomexOperations: (
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        beneficiaryName,
        operationNumber,
        openDateFrom,
        openDateTo,
        dueDateFrom,
        dueDateTo,
    ) => ({
        type: types.LIST_ECOMEX_OPERATIONS_REQUEST,
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        beneficiaryName,
        operationNumber,
        openDateFrom,
        openDateTo,
        dueDateFrom,
        dueDateTo,
    }),
    moreListEcomexOperations: (
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        beneficiaryName,
        operationNumber,
        openDateFrom,
        openDateTo,
        dueDateFrom,
        dueDateTo,
    ) => ({
        type: types.MORE_LIST_ECOMEX_OPERATIONS_REQUEST,
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        beneficiaryName,
        operationNumber,
        openDateFrom,
        openDateTo,
        dueDateFrom,
        dueDateTo,
    }),

    downloadOperationsList: (summary, format) => ({
        type: types.DOWNLOAD_LIST_ECOMEX_OPERATIONS_REQUEST,
        summary,
        format,
    }),
    saveOptionSelected: (
        optionSelected,
        ecomexState,
        operationType,
        beneficiaryName,
        operationNumber,
        openDateFrom,
        openDateTo,
        dueDateFrom,
        dueDateTo,
    ) => ({
        type: types.SAVE_OPTION_SELECTED,
        optionSelected,
        ecomexState,
        operationType,
        beneficiaryName,
        operationNumber,
        openDateFrom,
        openDateTo,
        dueDateFrom,
        dueDateTo,
    }),
    getOperationDetail: (selectedOperation) => ({
        type: types.ECOMEX_OPERATION_DETAIL_REQUEST,
        selectedOperation,
    }),
    closeDetail: () => ({
        type: types.CLOSE_OPERATION_DETAIL,
    }),
    downloadOperationsDetail: (tabSelected, identification, format, fileName) => ({
        type: types.DOWNLOAD_DETAIL_ECOMEX_OPERATIONS_REQUEST,
        tabSelected,
        identification,
        format,
        fileName,
    }),

    listEcomexInformativeRegimePre: (optionSelected, ecomexState) => ({
        type: types.LIST_ECOMEX_INFORMATIVE_REGIME_PRE_REQUEST,
        optionSelected,
        ecomexState,
    }),
    listEcomexInformativeRegime: (
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        destination,
        operationNumber,
        payDateFrom,
        payDateTo,
        dueDateFrom,
        dueDateTo,
        officialDateFrom,
        officialDateTo,
        settlementDateFrom,
        settlementDateTo,
        embDateFrom,
        embDateTo,
    ) => ({
        type: types.LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST,
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        destination,
        operationNumber,
        payDateFrom,
        payDateTo,
        dueDateFrom,
        dueDateTo,
        officialDateFrom,
        officialDateTo,
        settlementDateFrom,
        settlementDateTo,
        embDateFrom,
        embDateTo,
    }),
    moreListEcomexInformativeRegime: (
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        destination,
        operationNumber,
        payDateFrom,
        payDateTo,
        dueDateFrom,
        dueDateTo,
        officialDateFrom,
        officialDateTo,
        settlementDateFrom,
        settlementDateTo,
        embDateFrom,
        embDateTo,
    ) => ({
        type: types.MORE_LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST,
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        destination,
        operationNumber,
        payDateFrom,
        payDateTo,
        dueDateFrom,
        dueDateTo,
        officialDateFrom,
        officialDateTo,
        settlementDateFrom,
        settlementDateTo,
        embDateFrom,
        embDateTo,
    }),
    downloadInformativeRegimeList: (summary, format) => ({
        type: types.DOWNLOAD_LIST_ECOMEX_INFORMATIVE_REGIME_REQUEST,
        summary,
        format,
    }),
    saveOptionSelectedForRegime: (
        optionSelected,
        ecomexState,
        operationType,
        destination,
        operationNumber,
        payDateFrom,
        payDateTo,
        dueDateFrom,
        dueDateTo,
        officialDateFrom,
        officialDateTo,
        settlementDateFrom,
        settlementDateTo,
        embDateFrom,
        embDateTo,
    ) => ({
        type: types.SAVE_OPTION_SELECTED_FOR_REGIME,
        optionSelected,
        ecomexState,
        operationType,
        destination,
        operationNumber,
        payDateFrom,
        payDateTo,
        dueDateFrom,
        dueDateTo,
        officialDateFrom,
        officialDateTo,
        settlementDateFrom,
        settlementDateTo,
        embDateFrom,
        embDateTo,
    }),
    getInformativeRegimeDetail: (selectedInformativeRegime, optionSelected) => ({
        type: types.ECOMEX_INFORMATIVE_REGIME_DETAIL_REQUEST,
        selectedInformativeRegime,
        optionSelected,
    }),
    closeDetailInformativeRegime: () => ({
        type: types.CLOSE_INFORMATIVE_REGIME_DETAIL,
    }),
    downloadInformativeRegimeDetail: (tabSelected, identification, format, fileName) => ({
        type: types.DOWNLOAD_DETAIL_ECOMEX_INFORMATIVE_REGIME_REQUEST,
        tabSelected,
        identification,
        format,
        fileName,
    }),
    filterChangeInformativeRegime: (ecomexInformativeRegime, filter) => ({
        type: types.FILTER_CHANGE_FOR_INFORMATIVE_REGIME,
        ecomexInformativeRegime,
        filter,
    }),

    listEcomexAlertsAndDues: (optionSelected, pageNumber, numberPerPage) => ({
        type: types.LIST_ECOMEX_ALERTS_AND_DUES_REQUEST,
        optionSelected,
        pageNumber,
        numberPerPage,
    }),
    moreListEcomexAlertsAndDues: (optionSelected, pageNumber, numberPerPage) => ({
        type: types.MORE_LIST_ECOMEX_ALERTS_AND_DUES_REQUEST,
        optionSelected,
        pageNumber,
        numberPerPage,
    }),
    downloadAlertsAndDuesList: (summary, format) => ({
        type: types.DOWNLOAD_LIST_ECOMEX_ALERTS_AND_DUES_REQUEST,
        summary,
        format,
    }),
};

export const selectors = {
    getFetching: ({ ecomex }) => ecomex.fetching,
    getFetchingDownload: ({ ecomex }) => ecomex.fetchingDownload,
    getEcomexOperations: ({ ecomex }) => ecomex.ecomexOperations,
    getOperationsTypes: ({ ecomex }) => ecomex.operationsTypes,
    getBeneficiaryNames: ({ ecomex }) => ecomex.beneficiaryNames,
    getFiltersData: ({ ecomex }) => ecomex.filtersData,
    getOptionSelected: ({ ecomex }) => ecomex.optionSelected,
    getSelectedOperation: ({ ecomex }) => ecomex.selectedOperation,
    getFilter: ({ ecomex }) => ecomex.filter,
    getPageNumber: ({ ecomex }) => ecomex.pageNumber,
    getNumberPerPageOptions: ({ ecomex }) => ecomex.numberPerPageOptions,
    getNumberPerPage: ({ ecomex }) => ecomex.numberPerPage,
    getTotalPages: ({ ecomex }) => ecomex.totalPages,
    getSettlementsDetail: ({ ecomex }) => ecomex.settlementsDetail,
    getNotificationsDetail: ({ ecomex }) => ecomex.notificationsDetail,
    getSwiftMessagesDetail: ({ ecomex }) => ecomex.swiftMessagesDetail,
    getTicketsDetail: ({ ecomex }) => ecomex.ticketsDetail,
    getDuesDetail: ({ ecomex }) => ecomex.duesDetail,
    getDueDatesDetail: ({ ecomex }) => ecomex.dueDatesDetail,
    getUsesDetail: ({ ecomex }) => ecomex.usesDetail,
    getEcomexInformativeRegime: ({ ecomex }) => ecomex.ecomexInformativeRegime,
    getSelectedInformativeRegime: ({ ecomex }) => ecomex.selectedInformativeRegime,
    getNegociationsDetail: ({ ecomex }) => ecomex.negociationsDetail,
    getAffectationsDetail: ({ ecomex }) => ecomex.affectationsDetail,
    getImpurationsAndNegotiations: ({ ecomex }) => ecomex.impurationsAndNegotiations,
    getAdvanceExportPaymentsDetail: ({ ecomex }) => ecomex.advanceExportPaymentsDetail,
    getBoardingPermitsNegotiations: ({ ecomex }) => ecomex.boardingPermitsNegotiations,
    getEcomexAlerts: ({ ecomex }) => ecomex.ecomexAlerts,
    getEcomexDues: ({ ecomex }) => ecomex.ecomexDues,
    getEcomexStates: ({ ecomex }) => ecomex.ecomexStates,
};
