import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";
import { selectors } from "reducers/cedip";
import * as i18n from "util/i18n";
import { push, goBack } from "react-router-redux";
import TransactionTicket from "pages/_components/TransactionTicket";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";
import RejectCedipTicketData from "./RejectCedipTicketData";

const FORM_ID = "cedip.reject";

class RejectCedipTicket extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
    };

    state = {
        fetchingDownload: false,
        fetching: false,
    };

    componentDidMount() {
        const { dispatch, transactionData } = this.props;
        if (!transactionData.idTransaction) {
            dispatch(push("/transactions/list"));
        }
    }

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("activities.cedip.reject")}</h1>
        </div>
    );

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { transactionData } = this.props;

        const maskCedipId = (idCedip) => {
            const maskedPart = "***";
            const visiblePart = idCedip.slice(-7);
            return maskedPart + visiblePart;
        };

        const { cedip, idCedip } = transactionData;
        const { montoACobrar, tipoMoneda, transmisiones } = cedip;

        const maskedCedipId = maskCedipId(idCedip);
        const emissionDate = `${cedip?.fechaEmision}T00:00:00.000-03`;
        const dueDate = `${cedip?.fechaVencimiento}T00:00:00.000-03`;

        const pendingTransmission = transmisiones?.find((item) => item.estado === "PENDIENTE");
        const { cuitTransmisor, razonSocialTransmisor, fecha } = pendingTransmission;
        const transmisionDate = `${fecha}T00:00:00.000-03`;

        const ticketData = {
            ticketName: i18n.get("cedip.transaction.reject.label"),
            ticketBody: [
                getGeneralTicketItem(`${FORM_ID}.transaction.date.time`, transactionData?.submitDateTime, "datefulltime"),
                getGeneralTicketItem(
                    `${FORM_ID}.transaction.transaction`,
                    transactionData?.idTransaction.substring(0, 8).toUpperCase(),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.transaction.certificateCedipNumber`, maskedCedipId, "string"),
                getGeneralTicketItem(`${FORM_ID}.transaction.emissionDate`, emissionDate, "date"),
                getGeneralTicketItem(`${FORM_ID}.transaction.dueDate`, dueDate, "date"),
                getGeneralTicketItem("deposits.cedip.recieved.from", razonSocialTransmisor, "string"),
                getGeneralTicketItem(`${FORM_ID}.transaction.documentType`, cuitTransmisor, "string"),
                getGeneralTicketItem(
                    `${FORM_ID}.transaction.amount`,
                    montoACobrar,
                    tipoMoneda === "032" ? "amountARS" : "amountUSD",
                ),
                getGeneralTicketItem(`${FORM_ID}.transaction.transmissionDate`, transmisionDate, "date"),
                getGeneralTicketItem(
                    `${FORM_ID}.transaction.status`,
                    i18n.get("cedip.reject.transaction.rejected"),
                    "string",
                ),
            ],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    buttonsDetail = () => {
        const { dispatch } = this.props;

        return [
            {
                label: "global.goToHome",
                bsStyle: "primary",
                onClick: () => dispatch(push("/desktop")),
            },
        ];
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    render() {
        const { transactionData, isDesktop, isSmallDesktop } = this.props;
        const { fetchingDownload, fetching } = this.state;

        return (
            <>
                {transactionData?.idTransaction && (
                    <TransactionTicket
                        notificationScope="cedip"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        handleBack={this.handleBack}
                        uniqueDownload={transactionData?.idTransactionStatus === "FINISHED"}
                        emptyRightOption={
                            (isDesktop && transactionData?.idTransactionStatus !== "FINISHED") ||
                            (!isDesktop &&
                                transactionData?.idTransactionStatus !== "PENDING" &&
                                transactionData?.idTransactionStatus !== "FINISHED")
                        }
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={transactionData}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        ignoreHomeButton
                        buttonsDetail={this.buttonsDetail()}
                        summaryTitle="cedip.transaction.reject.label"
                        isFetchingExport={fetchingDownload}
                        fetching={fetching}
                        hideSigns>
                        <RejectCedipTicketData isDesktop={isDesktop} />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionSummary(state),
});

export default connect(mapStateToProps)(RejectCedipTicket);
