import React from "react";
import Drawer from "rc-drawer";
import { bool, func, element, string } from "prop-types";
import Head from "pages/_components/Head";
import classNames from "classnames";

export default function ButtonDrawer({
    content,
    title,
    width,
    showDrawerRemote,
    closeRemotely,
    isDesktop,
    backButton,
    centerContentClassName,
    contentClassName,
    headerClassName,
    drawerClassName,
    noBlueHeader,
}) {
    ButtonDrawer.propTypes = {
        content: element.isRequired,
        title: string,
        centerContentClassName: string,
        contentClassName: string,
        width: string,
        showDrawerRemote: bool.isRequired,
        closeRemotely: func.isRequired,
        isDesktop: bool,
        backButton: bool,
        headerClassName: string,
        drawerClassName: string,
        noBlueHeader: bool,
    };
    ButtonDrawer.defaultProps = {
        title: "",
        width: "",
        centerContentClassName: "",
        contentClassName: "",
        isDesktop: true,
        backButton: false,
        headerClassName: "",
        drawerClassName: "",
        noBlueHeader: false,
    };

    return (
        <>
            <Drawer
                width={width}
                open={showDrawerRemote}
                handler={false}
                placement="right"
                level={null}
                className={drawerClassName}
                onClose={() => closeRemotely(!showDrawerRemote)}>
                <div className="drawer-content-inner">
                    <Head
                        onBack={backButton && closeRemotely}
                        title={title}
                        onClose={!backButton && closeRemotely}
                        closeImageWhite={!isDesktop && !noBlueHeader}
                        headerClassName={classNames(`${headerClassName}`, {
                            "blue-main-header-mobile blue-main-title-mobile": !isDesktop && !noBlueHeader,
                            "background-white": !isDesktop && noBlueHeader,
                        })}
                        centerContentClassName={centerContentClassName}
                        hideNavbarInMobile
                    />
                    <div className={contentClassName}>
                        {showDrawerRemote && (
                            <div>
                                {React.cloneElement(content, {
                                    ...content.props,
                                    closeDrawer: () => closeRemotely(!showDrawerRemote),
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </Drawer>
        </>
    );
}
