import * as API from "middleware/api";

export const listDeposits = () => API.executeWithAccessToken("/deposits.list");

export const readDeposit = (idDeposit) => API.executeWithAccessToken("/deposits.read", { idDeposit });

export const downloadList = (format) => API.executeWithAccessToken("/deposits.exportList", { format });

export const downloadDetail = (idDeposit, format) =>
    API.executeWithAccessToken("/deposits.downloadInformation", { idDeposit, format });

export const simulateDepositPreview = () => API.executeWithAccessToken("/deposits.simulateDeposit.preview");

export const simulateDeposit = (debitAccount, depositType, consolidatedAmount, termInDays, dueDate) =>
    API.executeWithAccessToken("/deposits.simulateDeposit", {
        debitAccount,
        depositType,
        consolidatedAmount,
        termInDays,
        dueDate,
    });

export const constituteDeposit = (
    debitAccount,
    depositType,
    consolidatedAmount,
    dueDate,
    termInDays,
    rate,
    automaticRenewal,
    requestCode,
    operationNumber,
    profitTaxCode,
    witnessRate,
    codeRate,
    accountNumber,
) =>
    API.executeWithAccessToken("/deposits.constituteDeposit", {
        debitAccount,
        depositType,
        consolidatedAmount,
        dueDate,
        termInDays,
        automaticRenewal,
        rate,
        requestCode,
        operationNumber,
        profitTaxCode,
        witnessRate,
        codeRate,
        accountNumber,
    });

export const confirmDeposit = (summary, otp) =>
    API.executeWithAccessToken("/deposits.confirmDeposit", {
        ...summary,
        amount: summary.totalAmount,
        _otp: otp,
    });
