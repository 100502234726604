import React, { Component } from "react";
import Infinite from "react-infinite";
import { arrayOf, bool, element, func, number, shape, node } from "prop-types";
import ItemHeight from "pages/_components/item/ItemHeight";
import I18n from "pages/_components/I18n";

class Scroll extends Component {
    scrollRef = React.createRef();

    static propTypes = {
        fetchMoreData: func.isRequired,
        items: arrayOf(element).isRequired,
        setTop: func,
        handleScroll: func,
        containerBounds: shape({
            top: number,
            height: number,
        }),
        fetching: bool,
        isInfiniteScroll: bool,
        maxPullLength: number,
        removeListenersWhenPulled: bool,
        endOfListItem: node,
        emptyList: node,
        lastPage: bool,
        searchMore: node,
        pageNumber: number,
        elements: number,
        headerHeight: number,
        calculateHeight: bool,
    };

    static defaultProps = {
        containerBounds: {},
        fetching: false,
        isInfiniteScroll: false,
        maxPullLength: 80,
        removeListenersWhenPulled: false,
        lastPage: false,
        setTop: () => {},
        handleScroll: () => {},
        endOfListItem: (
            <div key="lastItem" className="text-center no-more-data">
                <p>
                    <I18n id="products.list.end" />
                </p>
            </div>
        ),
        searchMore: null,
        emptyList: null,
        pageNumber: null,
        elements: 10,
        headerHeight: 0,
        calculateHeight: false,
    };

    state = {
        lastTouchMovePosition: 0,
        pulledForMoreInfo: false,
        scrollableElement: null,
        searchMoreVisible: false,
        lastPageSearched: 1,
    };

    componentWillUnmount() {
        const { searchMoreVisible } = this.state;
        if (searchMoreVisible) {
            this.removeListeners();
        }
    }

    removeListeners = () => {
        if (this.scrollRef.current.scrollable) {
            const scrollable = this.scrollRef.current.scrollable.parentElement;
            scrollable.removeEventListener("touchmove", this.handleTouchMove);
            scrollable.removeEventListener("touchend", this.handleTouchEnd);
        }
    };

    handleResize = () => {
        const { searchMoreVisible } = this.state;
        if (!searchMoreVisible && this.scrollRef.current) {
            const { parentElement } = this.scrollRef.current.scrollable;
            this.setState({ scrollableElement: parentElement });
            parentElement.addEventListener("touchmove", this.handleTouchMove);
            parentElement.addEventListener("touchend", this.handleTouchEnd);
        }
    };

    handleScroll = (elem) => {
        const { handleScroll } = this.props;
        if (handleScroll) {
            handleScroll(elem);
        }
        this.setState({ scrollableElement: elem });
    };

    handleTouchMove = (event) => {
        const { containerBounds, maxPullLength, setTop } = this.props;
        const { lastTouchMovePosition, scrollableElement, pulledForMoreInfo } = this.state;

        if (
            lastTouchMovePosition &&
            event.touches[0].clientY > lastTouchMovePosition &&
            scrollableElement.scrollTop === 0
        ) {
            const top =
                event.touches[0].clientY - containerBounds.top > maxPullLength
                    ? maxPullLength
                    : event.touches[0].clientY - containerBounds.top;

            setTop(top);

            if (!pulledForMoreInfo && top === maxPullLength) {
                this.setState({ searchMoreVisible: true, pulledForMoreInfo: true });
            }
        }

        this.setState({ lastTouchMovePosition: event.touches[0].clientY });
    };

    handleTouchEnd = () => {
        const { removeListenersWhenPulled, setTop } = this.props;
        const { pulledForMoreInfo } = this.state;

        if (removeListenersWhenPulled && pulledForMoreInfo) {
            this.removeListeners();
        }

        setTop(0);
        this.setState({ lastTouchMovePosition: 0, pulledForMoreInfo: false });
    };

    executeGetData = () => {
        const { fetchMoreData, pageNumber } = this.props;
        const { lastPageSearched } = this.state;

        if (!pageNumber || lastPageSearched !== pageNumber + 1) {
            this.setState({ lastPageSearched: pageNumber + 1 });
            fetchMoreData();
        }
    };

    calculateHeight = (height) => {
        const { elements, pageNumber } = this.props;
        return height * elements * pageNumber + 100;
    };

    buildContainerHeight = (height) => {
        const { containerBounds, headerHeight, calculateHeight } = this.props;
        const totalHeight = containerBounds?.height || window.innerHeight - headerHeight;
        return calculateHeight ? this.calculateHeight(height) : totalHeight;
    };

    render() {
        const { endOfListItem, fetching, lastPage, isInfiniteScroll, searchMore, emptyList, items } = this.props;
        const { searchMoreVisible } = this.state;

        let itemsList = [...items];
        const [item] = itemsList;

        if (lastPage) {
            itemsList = [...itemsList, endOfListItem];
        } else {
            itemsList = [
                ...itemsList,
                <div className="table-row table-end without-border-bottom" key="noMoreTransactions">
                    <Spinner />
                </div>,
            ];
        }
        if (searchMoreVisible) {
            itemsList = [searchMore, ...itemsList];
        }
        if (!(itemsList.length || fetching)) {
            return emptyList;
        }

        if (item) {
            return (
                <ItemHeight item={item} onResize={this.handleResize}>
                    {(height) => (
                        <Infinite
                            ref={this.scrollRef}
                            containerHeight={this.buildContainerHeight(height)}
                            elementHeight={height}
                            handleScroll={this.handleScroll}
                            infiniteLoadBeginEdgeOffset={isInfiniteScroll && !lastPage ? 100 : undefined}
                            isInfiniteLoading={fetching}
                            onInfiniteLoad={this.executeGetData}>
                            {itemsList}
                        </Infinite>
                    )}
                </ItemHeight>
            );
        }
        return null;
    }
}

export default Scroll;

function Spinner() {
    return (
        <div className="btn is-loading no-button-spinner transparent">
            <span className="btn-loading-indicator">
                <span />
                <span />
                <span />
            </span>
            <span className="btn-loading-text">Loading</span>
        </div>
    );
}
