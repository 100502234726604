export const types = {
    LIST_HOLIDAYS_REQUEST: "holidays/LIST_HOLIDAYS_REQUEST",
    LIST_HOLIDAYS_FAILURE: "holidays/LIST_HOLIDAYS_FAILURE",
    LIST_HOLIDAYS_SUCCESS: "holidays/LIST_HOLIDAYS_SUCCESS",
};

export const INITIAL_STATE = {
    holidays: [],
    fetching: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    const { holidays } = action;
    switch (action.type) {
        case types.LIST_HOLIDAYS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LIST_HOLIDAYS_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_HOLIDAYS_SUCCESS:
            return {
                ...state,
                fetching: false,
                holidays,
            };
        default:
            return state;
    }
};
export const actions = {
    listHolidays: () => ({
        type: types.LIST_HOLIDAYS_REQUEST,
    }),
};

export const selectors = {
    isFetching: ({ holidays }) => holidays.fetching,
    getHolidays: ({ holidays }) => holidays.holidays,
};
