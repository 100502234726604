import React, { Component } from "react";
import { func } from "prop-types";
import Modal from "react-bootstrap/lib/Modal";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

class ModifyTransaction extends Component {
    static propTypes = {
        cancelModifyHandleClick: func,
    };

    render() {
        const { modifyTransactionClick, onClose } = this.props;

        return (
            <Modal show>
                <Modal.Body>
                    <Container
                        className="container--layout align-items-center media-object media-object--state-warning"
                        gridClassName="container-fluid">
                        <Col className="col">
                            <div className="illustration-wrapper">
                                <Image src="images/alert.svg" className="svg-big-icon" />
                            </div>
                        </Col>

                        <Col className="col">
                            <div className="media-object--text">
                                <p className="text-lead">
                                    <I18n id="forms.modifyTransaction.Warning" />
                                </p>
                            </div>
                        </Col>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="justify-content-center">
                        <Col className="col col-6">
                            <Button
                                label="forms.modifyTransaction.link"
                                bsStyle="primary"
                                onClick={() => modifyTransactionClick()}
                            />
                        </Col>
                        <Col className="col col-6">
                            <Button
                                label="forms.modifyTransaction.cancel"
                                bsStyle="primary"
                                onClick={() => onClose()}
                            />
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ModifyTransaction;
