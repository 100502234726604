import React, { Component } from "react";
import { shape, bool, func } from "prop-types";

import ContextMenu from "pages/_components/ContextMenu";
import Heading from "pages/_components/Heading";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";

class EnabledContributor extends Component {
    static propTypes = {
        enabledContributor: shape({}).isRequired,
        isDesktop: bool.isRequired,
        showDeleteForm: func.isRequired,
    };

    render() {
        const { enabledContributor, isDesktop, showDeleteForm } = this.props;
        const { cuit, referencia } = enabledContributor;

        if (isDesktop) {
            return (
                <>
                    <div className="table-data" style={{ textAlign: "center" }}>
                        <span className="data-aux">{cuit}</span>
                    </div>
                    <div className="table-data" style={{ textAlign: "center " }}>
                        <span className="data-aux">{referencia}</span>
                    </div>
                    <ContextMenu
                        contributorDropdown
                        styleContext={{ justifyContent: "flex-end" }}
                        items={[
                            {
                                label: "payments.enabledContributors.list.actions.delete",
                                onClick: () => {
                                    showDeleteForm(cuit, referencia);
                                },
                            },
                        ]}
                    />
                </>
            );
        }

        return (
            <>
                {isDesktop ? (
                    <div className="my-2">
                        <div className="table-data text-center">
                            <Heading.DataGroup label="payments.enabledContributors.list.cuit" labelClassName="my-0" />
                            <Heading.DataGroup
                                label="payments.enabledContributors.list.reference"
                                labelClassName="my-0"
                            />
                        </div>
                        <div className="table-data" style={{ textAlign: "right" }}>
                            <span className="data-aux">{cuit}</span>
                            <span className="data-aux">{referencia}</span>
                        </div>
                        <ContextMenu
                            contributorDropdown
                            styleContext={{ marginTop: "0.5em" }}
                            items={[
                                {
                                    label: "payments.enabledContributors.list.actions.delete",
                                    onClick: () => {
                                        showDeleteForm(cuit, referencia);
                                    },
                                },
                            ]}
                        />
                    </div>
                ) : (
                    <Container className="flex-grow container-white-mobile adhesion-item-mobile enabled-contributor">
                        <Col className="pl-0 pr-2" xs={11}>
                            <Col xs={12} className="pr-0">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex space-between mb-0 "
                                    label="payments.enabledContributors.list.cuit"
                                    labelClassName="my-0"
                                    data={cuit}
                                />
                            </Col>
                            <Col xs={12} className="pr-0">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                                    label="payments.enabledContributors.list.reference"
                                    labelClassName="my-0"
                                    data={referencia}
                                />
                            </Col>
                        </Col>
                        <Col className="px-0" xs={1}>
                            <ContextMenu
                                contributorDropdown
                                dropBtnClass="pl-0"
                                items={[
                                    {
                                        label: "payments.enabledContributors.list.actions.delete",
                                        onClick: () => {
                                            showDeleteForm(cuit, referencia);
                                        },
                                    },
                                ]}
                            />
                        </Col>
                    </Container>
                )}
            </>
        );
    }
}

export default EnabledContributor;
