import React, { Component } from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import { string, shape } from "prop-types";

class Header extends Component {
    static propTypes = {
        additionalClassName: string,
        children: shape({}).isRequired,
        headerClassName: string,
        navStyle: string,
    };

    static defaultProps = { additionalClassName: "", headerClassName: "", navStyle: "default" };

    render() {
        const { additionalClassName, headerClassName, navStyle } = this.props;
        return (
            <header className={`view-header ${additionalClassName}`}>
                <Navbar collapseOnSelect fluid bsStyle={navStyle}>
                    <Navbar.Header className={headerClassName}>
                        <div className="toolbar">{this.props.children}</div>
                    </Navbar.Header>
                </Navbar>
            </header>
        );
    }
}

export default Header;
