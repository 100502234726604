/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { func, string, bool, shape, node } from "prop-types";
import { connect } from "react-redux";

import WidgetLoading from "pages/_components/WidgetLoading";
import InfoWidget from "pages/desktop/widgets/_components/InfoWidget";
import ErrorProduct from "./_components/ErrorProduct";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as desktopSelectors } from "reducers/desktop";
import { selectors, actions } from "reducers/widgets";

import * as i18nUtils from "util/i18n";

class WidgetList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        children: node.isRequired,
        item: func,
        keyExtractor: func,
        name: string.isRequired,
        shouldMapList: bool,
        list: shape({}).isRequired,
        isFetching: bool,
        isEditable: bool,
        activeEnvironment: shape({}),
        loggedUser: shape({}).isRequired,
        close: node.isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        shouldMapList: true,
        keyExtractor: () => {},
        item: null,
        isFetching: false,
        isEditable: false,
        activeEnvironment: {},
    };

    componentDidMount() {
        const { dispatch, name, loggedUser } = this.props;
        dispatch(actions.listRequest(name, loggedUser.userId));
    }

    renderItem = (item) => {
        const { item: Item, keyExtractor, isEditable, isDesktop } = this.props;

        return <Item key={keyExtractor(item)} isDesktop={isDesktop} isEditable={isEditable} {...item} />;
    };

    renderList = () => {
        const { list, errorMsg, isFetching, name, shouldMapList, isEditable } = this.props;

        if (errorMsg) {
            return <ErrorProduct text={errorMsg} />
        }

        if (!list || !(list.length || isFetching)) {
            return (
                <div className="table table--products" aria-hidden={isEditable}>
                    <InfoWidget
                        text={`desktop.widgets.${name}.empty`}
                    />
                </div>
            );
        }

        if (!shouldMapList) {
            return list;
        }

        return (
            <WidgetLoading loading={!list.length && isFetching}>
                <div className="table table--products" aria-hidden={isEditable}>
                    <div
                        aria-label={i18nUtils.get(`widgets.list.title.${name}.a11y`)}
                        role="menu"
                        className="table-body">
                        {list.map(this.renderItem)}
                    </div>
                </div>
            </WidgetLoading>
        );
    };

    render() {
        const { children, close } = this.props;

        return (
            <>
                {close}
                <div className="widget__container">{children(this.renderList())}</div>
            </>
        );
    }
}

const mapStateToProps = (state, { name }) => {
    const { isFetching, data, errorMsg } = selectors.getWidget(state, name);

    return {
        isFetching,
        list: data[name],
        errorMsg,
        isEditable: desktopSelectors.getIsEditale(state),
        activeEnvironment: sessionSelectors.getActiveEnvironment(state),
        loggedUser: sessionSelectors.getUser(state),
    };
};

export default connect(mapStateToProps)(WidgetList);
