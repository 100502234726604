import React, { Component } from "react";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { shape, func, bool, string } from "prop-types";

import Selector from "pages/_components/fields/formik/Selector";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Credential from "pages/_components/fields/credentials/Credential";
import RegionSelectionLink from "pages/login/_components/RegionSelectionLink";
import FooterDesktop from "pages/_components/FooterDesktop";

import { actions as loginActions } from "reducers/login";
import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";

import { Mixpanel } from "util/clickstreaming";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import HaveCodeButton from "./HaveCodeButton";

const FORM_ID = "recoveryPassword.step1";

class Step1Content extends Component {
    static propTypes = {
        location: shape({}).isRequired,
    };

    onVerify = (value) => {
        const { setFieldValue } = this.props;
        setFieldValue("captcha", value);
    };

    handleGoHome = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    contentTopDesktop = () => {
        const {
            maskedEmail,
            maskedPhone,
            isRecoveringUserpass,
            isRecoveryPassAndUserpass,
            isMobileNative,
            isDesktop,
        } = this.props;
        const titleId =
            isRecoveringUserpass && !isRecoveryPassAndUserpass
                ? "recoveryUserpass.step1.title"
                : "recoveryPassword.step1.title";

        return (
            <Col sm={12} md={12} lg={12} className={isDesktop ? "col col-12 pl-0" : "col col-12"}>
                <I18n id={titleId} component="p" componentProps={{ className: "text-lead mb-4" }} />
                <Field
                    component={Selector}
                    renderAs="radio"
                    radioDisplayGrid
                    name="sendChannel"
                    options={[
                        {
                            id: `phone`,
                            label: `${i18n.get(`${FORM_ID}.device.phone`)} `,
                            maskedData: maskedPhone,
                            textAlign: "text-left",
                        },
                        {
                            id: `email`,
                            label: `${i18n.get(`${FORM_ID}.device.email`)} `,
                            maskedData: maskedEmail,
                            textAlign: "text-left",
                        },
                    ]}
                />
                <div>
                    <Link className="medium-font" to="/recoveryPassword/step2/processDisclaimer">
                        <I18n id="recoveryPassword.step1.device.none" />
                    </Link>
                </div>
                <div className="login__label-required">
                    <Field
                        name="secondFactor"
                        idForm={FORM_ID}
                        component={Credential}
                        type={isMobileNative ? "tokenPass" : "otp"}
                        labelText={isMobileNative ? "settings.token.disabledTokenPass.password.label" : null}
                        placeholderText={
                            isMobileNative ? i18n.get("settings.token.disabledTokenPass.password.placeholder") : null
                        }
                    />
                </div>
                {isMobileNative && (
                    <div className="align-center">
                        <Link className="medium-font" to="/recoveryPassword/step2/processDisclaimerClaveToken">
                            <I18n id="recoveryPassword.step1.clavetoken.none" />
                        </Link>
                    </div>
                )}
            </Col>
        );
    };

    contentTopMobile = () => (
        <>
            <div className="view-title mt-3">
                <I18n
                    id="credential.recovery.mobile"
                    component="h2"
                    componentProps={{ className: "f-size-5 font-regular" }}
                />
            </div>
            <p className="text-lead font-size-14-px">{configUtils.get("login.RecuperarCredenciales.Mobile.Texto")}</p>
        </>
    );

    contentBottomDesktop = () => {
        const { isSubmitting, dispatch, isRecoveringUserpass, isDesktop } = this.props;

        return (
            <>
                <Col sm={12} md={12} lg={12} className={isDesktop ? "col col-12 pt-4 pl-0" : "col col-12"}>
                    <Button label="global.continue" type="submit" bsStyle="primary" loading={isSubmitting} />
                </Col>
                <HaveCodeButton
                    label="recoveryPassword.step1.token.none"
                    to="/recoveryPassAndUserpass/inactiveToken"
                    dispatch={dispatch}
                    className={`${isDesktop && "pl-0"} text-transform-none`}
                />
                <HaveCodeButton
                    label="recoveryPassword.step1.userHasCode"
                    to={isRecoveringUserpass ? "/recoveryUserpass/step2" : "/recoveryPassword/step2"}
                    dispatch={dispatch}
                    className={`${isDesktop && "pl-0"} text-transform-none`}
                />
            </>
        );
    };

    contentBottomMobile = () => {
        const {
            isDesktop,
            dispatch,
            isRecoveringUserpass,
            location: { pathname },
        } = this.props;
        return (
            <>
                <Col sm={12} className="pt-4 px-0">
                    <Button
                        label="credential.recovery.mobile.goback"
                        type="button"
                        bsStyle="primary"
                        onClick={this.handleGoHome}
                    />
                    {configUtils.getBoolean("mobile.recovercredentials.enabled", false) && (
                        <HaveCodeButton
                            label="recoveryPassword.step1.userHasCode"
                            to={isRecoveringUserpass ? "/recoveryUserpass/step2" : "/recoveryPassword/step2"}
                            dispatch={dispatch}
                            className="px-0 text-transform-none"
                        />
                    )}
                </Col>
                <Col sm={12}>
                    <FooterDesktop
                        isDesktop={isDesktop}
                        moreOptions={[<RegionSelectionLink disabled={pathname === "/regionSelection"} />]}
                    />
                </Col>
            </>
        );
    };

    render() {
        const { isDesktop, isMobileNative } = this.props;

        return isDesktop || (isMobileNative && configUtils.getBoolean("mobile.recovercredentials.enabled", false)) ? (
            <Form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                <Container className="container--layout flex-grow align-items-center">
                    {this.contentTopDesktop()}
                </Container>
                <Container className="align-items-center container--layout">{this.contentBottomDesktop()}</Container>
            </Form>
        ) : (
            <>
                <Container className="container--layout pass__recovery-top-content flex-grow align-items-center">
                    {this.contentTopMobile()}
                </Container>
                <Container className="align-items-center container--layout">{this.contentBottomMobile()}</Container>
            </>
        );
    }
}

Step1Content.propTypes = {
    isSubmitting: bool,
    setFieldValue: func.isRequired,
    isDesktop: bool,
    isMobileNative: bool,
    maskedEmail: string,
    maskedPhone: string,
    dispatch: func.isRequired,
    isRecoveringUserpass: bool,
    isRecoveryPassAndUserpass: bool,
};

Step1Content.defaultProps = {
    isSubmitting: false,
    isDesktop: true,
    isMobileNative: false,
    maskedEmail: "",
    maskedPhone: "",
    isRecoveringUserpass: false,
    isRecoveryPassAndUserpass: false,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({ secondFactor: "", sendChannel: "phone" }),
    validationSchema: () =>
        Yup.object().shape({
            sendChannel: Yup.string().required(i18n.get(`${FORM_ID}.sendChannel.required`)),
        }),
    handleSubmit: (values, formikBag) => {
        Mixpanel.track(FORM_ID);
        const { userName, isRecoveringUserpass, isRecoveryPassAndUserpass, exchangeToken } = formikBag.props;
        const { sendChannel, secondFactor } = values;

        if (isRecoveringUserpass) {
            formikBag.props.dispatch(
                recoveryPasswordActions.recoveryUserpassStep1(
                    sendChannel,
                    userName,
                    secondFactor,
                    formikBag,
                    isRecoveryPassAndUserpass,
                ),
            );
        } else {
            formikBag.props.dispatch(
                recoveryPasswordActions.recoveryPassStep1(
                    sendChannel,
                    userName,
                    secondFactor,
                    formikBag,
                    exchangeToken,
                ),
            );
        }
    },
})(Step1Content);
