import React, { Component } from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import { func, bool, string } from "prop-types";
import * as i18n from "util/i18n";
import { routerActions, push } from "react-router-redux/actions";
import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import { actions as tokenActions, selectors as tokenSelectors } from "reducers/token";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import MaskedTextField from "pages/_components/fields/MaskedTextField";
import * as maskUtils from "util/mask";

const FORM_ID = "setting.token.mobile.activation.code";

class CodeActivationMobile extends Component {
    static propTypes = {
        dispatch: func.isRequired,

        isMobile: bool.isRequired,

        fetching: bool.isRequired,

        email: string,
        numberPhone: string,
        fetchingToken: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        email: null,
        numberPhone: null,
    };

    state = {
        showMessage: false,
    };

    componentDidMount = () => {
        const { dispatch } = this.props;

        const showMobile = window.isMobileApp();

        if (!showMobile) {
            dispatch(push("/desktop"));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    getMobileContent = () => (
        <div className="above-the-fold">
            <div style={{ marginRight: "1rem", marginLeft: "1rem" }}>{this.renderDevices()}</div>
        </div>
    );

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("setting.token.mobile.activation.tittle")}</h1>
        </div>
    );

    sendNewCode = () => {
        const { dispatch } = this.props;

        dispatch(tokenActions.tokenActivationSendNewCodeRequest());
    };

    renderContent = () => {
        const { email, numberPhone, fetchingToken, fetching, isDesktop } = this.props;
        let maskPhone;
        let firstEmailLetter;
        let textAfterLastLetter;

        if (email !== "") {
            firstEmailLetter = email.charAt(0);

            textAfterLastLetter = email.substring(email.indexOf("@") - 1, email.length);
        } else {
            maskPhone = `
            ${numberPhone.charAt(0)}${numberPhone.charAt(1)}******${numberPhone.charAt(
                numberPhone.length - 2,
            )}${numberPhone.charAt(numberPhone.length - 1)}`;
        }
        const sendNotification =
            email !== "" ? `${firstEmailLetter}\u2022\u2022\u2022\u2022${textAfterLastLetter}` : maskPhone;
        return (
            <>
                <MainContainer>
                    <Form className="above-the-fold bg-white-mobile min-height-vh-page d-flex">
                        <Container className=" align-items-center flex-grow container-white">
                            <Col className="col col-12">
                                <div className="admin-content-center">
                                    <I18n id="setting.token.mobile.activation.subtittle" component="h3" />
                                    {sendNotification}
                                </div>
                            </Col>
                            <div className="admin-content-center mt-4 p-2">
                                <I18n id="setting.token.mobile.activation.messaje" component="h5" />
                            </div>

                            <Col sm={12} md={12} lg={12} className="col col-12 mt-4">
                                <Field
                                    idForm={FORM_ID}
                                    autoComplete="off"
                                    component={MaskedTextField}
                                    hidePlaceholder
                                    mask={maskUtils.invitationCodeMask()}
                                    maxLength={14}
                                    name="resetCode"
                                    autoFocus={isDesktop}
                                />
                            </Col>
                        </Container>
                        <Container className="align-items-end container--layout">
                            <Col sm={12} md={12} lg={12} className="col col-12">
                                <Button
                                    type="submit"
                                    label="global.continue"
                                    bsStyle="primary"
                                    loading={fetchingToken}
                                />
                            </Col>
                            <Col sm={12} md={12} lg={12} className="col col-12">
                                <Button
                                    label="setting.token.mobile.activation.newInviteCode"
                                    className="btn btn-outline"
                                    onClick={() => this.sendNewCode()}
                                    style={{ button: "normal" }}
                                    loading={fetching}
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    };

    render() {
        const { isMobile } = this.props;

        const { showMessage } = this.state;

        return (
            <>
                <Notification scopeToShow="tokenCreateCodeInvitation" />

                <div className="admin-detail-head px-0" style={isMobile ? { marginBottom: 0 } : {}}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={isMobile ? "blue-main-header-mobile" : ""}
                        centerElement={this.centerContentMobile}
                        emptyRightOption
                    />

                    <div style={{ marginLeft: "1rem", marginRight: "1rem", display: showMessage ? "block" : "none" }}>
                        <I18n
                            id="setting.token.mobile.activation.tittle"
                            component="p"
                            componentProps={{
                                className: "hint-text my-0 f-size-55",
                            }}
                        />
                    </div>
                </div>
                {this.renderContent()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    email: tokenSelectors.getEmail(state),
    numberPhone: tokenSelectors.getNumberPhone(state),
    fetching: tokenSelectors.getFetching(state),
    fetchingToken: tokenSelectors.getFetchingToken(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ resetCode: "" }),
        validationSchema: () =>
            Yup.object().shape({
                resetCode: Yup.string().required(i18n.get(`${FORM_ID}.resetCode.required`)),
            }),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(tokenActions.tokenActivationPreviewRequest(values.resetCode));
        },
    }),
)(CodeActivationMobile);
