import React, { Component, Fragment } from "react";
import Select from "react-select";
import classNames from "classnames";
import { arrayOf, bool, object } from "prop-types";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import withFocus from "pages/_components/withFocus";

import * as i18n from "util/i18n";

class DocumentField extends Component {
    static propTypes = {
        clearable: bool,
        countries: arrayOf(object),
        disableDocumentTypeSelect: bool,
        disableSelect: bool,
        documentTypes: arrayOf(object),
        hideDocumentCountryLabel: bool,
        hideDocumentPlaceholder: bool,
        searchable: bool,
    };

    static defaultProps = {
        clearable: true,
        disableSelect: false,
        disableDocumentTypeSelect: false,
        hideDocumentCountryLabel: false,
        hideDocumentPlaceholder: false,
        searchable: true,
        countries: [],
        documentTypes: [],
    };

    componentDidUpdate(prevProps) {
        const { form, field, defaultDocumentType, defaultCountry } = this.props;
        if (!prevProps.defaultDocumentType && defaultDocumentType && defaultCountry) {
            form.setFieldValue(field.name, {
                ...field.value,
                type: defaultDocumentType,
                country: defaultCountry,
            });
        }
    }

    handleCountryChange = (selectedOption) => {
        const {
            data: { documentTypes },
            field,
            form,
        } = this.props;

        form.setFieldValue(field.name, {
            ...field.value,
            country: selectedOption ? selectedOption.value : null,
            type: selectedOption
                ? documentTypes.filter((documentType) => documentType.id_country_code === selectedOption.value)[0]
                      .id_document_type
                : null,
        });
    };

    handleDocumentChange = (event) => {
        const { field, form, handleDocumentChange } = this.props;

        if (handleDocumentChange) {
            handleDocumentChange(event);
        }

        form.setFieldValue(field.name, {
            ...field.value,
            document: event.target.value,
        });
    };

    handleTypeChange = (selectedOption) => {
        const { field, form } = this.props;

        form.setFieldValue(field.name, {
            ...field.value,
            type: selectedOption ? selectedOption.value : null,
        });
    };

    render() {
        const {
            clearable,
            data: { countries, documentTypes },
            defaultCountry,
            defaultDocumentType,
            disableDocumentTypeSelect,
            disableSelect,
            field,
            form: { touched, errors },
            hideDocumentCountryLabel,
            hideDocumentPlaceholder,
            idForm,
            inputRef,
            searchable,
        } = this.props;

        const hasError = {
            countries:
                touched[field.name] && errors[field.name]
                    ? touched[field.name].country && errors[field.name].country
                    : false,
            document:
                touched[field.name] && errors[field.name]
                    ? touched[field.name].document && errors[field.name].document
                    : false,
            documentTypes:
                touched[field.name] && errors[field.name] ? touched[field.name].type && errors[field.name].type : false,
        };

        return (
            <Fragment>
                <div
                    className={classNames("form-group", {
                        "has-error": hasError.countries,
                    })}>
                    {!hideDocumentCountryLabel && <FieldLabel labelKey={`${idForm}.${field.name}Country.label`} />}
                    <div className="input-group">
                        <Select
                            placeholder=""
                            clearable={clearable}
                            className="flex-container"
                            disabled={disableSelect}
                            onChange={this.handleCountryChange}
                            options={
                                countries
                                    ? countries.map(({ id, name }) => ({
                                          value: id,
                                          label: name,
                                      }))
                                    : []
                            }
                            searchable={searchable}
                            matchProp="any"
                            value={field.value.country || defaultCountry}
                            optionClassName="needsclick"
                        />
                    </div>
                    {hasError.countries && <FieldError error={errors[field.name].country} />}
                </div>
                <div
                    className={classNames("form-group", "form-group--composite", {
                        "has-error": hasError.documentType || hasError.document,
                    })}>
                    {!hideDocumentCountryLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                    <div className="input-group">
                        <Select
                            placeholder=""
                            noResultsText=""
                            clearable={clearable}
                            className="currency-selector slideFromBottom flex-container"
                            disabled={disableDocumentTypeSelect}
                            onChange={this.handleTypeChange}
                            options={
                                documentTypes && field.value.country
                                    ? documentTypes
                                          .filter(
                                              (documentType) => documentType.id_country_code === field.value.country,
                                          )
                                          .map(({ id_document_type }) => ({
                                              value: id_document_type,
                                              label: id_document_type,
                                          }))
                                    : []
                            }
                            searchable={false}
                            value={field.value.type || defaultDocumentType}
                            optionClassName="needsclick"
                        />
                        <input
                            className="form-control"
                            placeholder={hideDocumentPlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)}
                            ref={inputRef}
                            {...field}
                            onChange={this.handleDocumentChange}
                            value={field.value.document}
                        />
                    </div>
                    {hasError.documentTypes && <FieldError error={errors[field.name].type} />}
                    {hasError.document && <FieldError error={errors[field.name].document} />}
                </div>
            </Fragment>
        );
    }
}

export default withFocus(DocumentField);
