import React, { useEffect, useRef, useState } from "react";
import { func, number, string } from "prop-types";
import { connect } from "react-redux";
import { Col, Modal } from "react-bootstrap";

import Image from "pages/_components/Image";
import Container from "pages/_components/Container";
import Button from "../Button";
import I18n from "../I18n";

import { actions as tokenActions, selectors as tokenSelectors } from "reducers/token";

function TokenDisconectModal ({ dispatch, showNotificationIn, userId, exchangeToken }) {

    const [showModal, setShowModal] = useState(false);
    const notificationRef = useRef();
    const checkExchangeTokenRef = useRef();

    useEffect(() => {
        if (showNotificationIn) {
            notificationRef.current = setTimeout(() => {
                setShowModal(true);
                checkExchangeTokenRef.current = setInterval(() => {
                    dispatch(tokenActions.exchangeTokenCheck(userId, exchangeToken))
                }, 5000);
            }, showNotificationIn)
        }
        return () => {
            clearTimeout(notificationRef.current)
            clearInterval(checkExchangeTokenRef.current)
        }
    }, [showNotificationIn, dispatch, userId, exchangeToken]);

    const refreshExchangeToken = () => dispatch(tokenActions.refreshTokenRequest(userId, exchangeToken))

    return (
        <div className="modal-container">
            <Modal
                show={showModal}
                className="token__modal-disconect-container">
                <Modal.Header>
                    <Container>
                        <Col xs={12} className="justify-content-center d-flex">
                            <div className="token__modal-disconect-icon">
                                <Image src="images/history.svg" />
                            </div>
                        </Col>
                        <Col xs={12} className="justify-content-center d-flex">
                            <I18n
                                id="token.otp.modal.title"
                                component="h2"
                                componentProps={{ className: "token__modal-disconect-title" }}
                            />
                        </Col>
                    </Container>
                </Modal.Header>
                <Modal.Body className="justify-content-center mb-2">
                    <I18n
                        id="token.otp.modal.body"
                        component="p"
                        componentProps={{ className: "token__modal-disconect-body" }}
                    />
                </Modal.Body>
                <Modal.Footer className="px-4">
                    <Button
                        onClick={() => {
                            refreshExchangeToken()
                            setShowModal(false)
                            clearInterval(checkExchangeTokenRef.current)
                        }}
                        type="button"
                        bsStyle="primary"
                        label="token.otp.modal.button"
                    />
                </Modal.Footer>
            </Modal>
        </div>
    );
}

TokenDisconectModal.propTypes = {
    dispatch: func.isRequired,
    showNotificationIn: number.isRequired,
    userId: string.isRequired,
    exchangeToken: string.isRequired,
};

const mapStateToProps = (state) => ({
    showNotificationIn: tokenSelectors.getShowNotificationIn(state),
    userId: tokenSelectors.getUserIdToken(state),
    exchangeToken: tokenSelectors.getExchangeToken(state),
});

export default connect(mapStateToProps)(TokenDisconectModal);
