/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import moment from "moment";
import { bool, shape, string } from "prop-types";
import { isSameDay, isPast } from "date-fns";
import isEmpty from "lodash/isEmpty";
import I18n from "pages/_components/I18n";
import FieldError from "pages/_components/fields/FieldError";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import SchedulerModal from "pages/forms/_components/_scheduler/SchedulerModal";
import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Image from "pages/_components/Image";

const FORM_ID = "scheduler";

class Scheduler extends Component {
    static propTypes = {
        data: shape({}).isRequired,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        location: shape({}).isRequired,
        isDesktop: bool,
        showShedulerMessage: bool,
        disabled: bool,
        label: string,
    };

    static defaultProps = {
        isDesktop: false,
        showShedulerMessage: true,
        disabled: false,
        label: "transfers.schedule.label",
    };

    state = {
        errors: null,
        isDisplayed: false,
    };

    componentDidMount() {
        const { field, form } = this.props;
        if (field.value === null) {
            form.setFieldValue(field.name, {
                selectedOption: schedulerUtils.TODAY,
                valueDate: this.getDefaultValueDate(),
            });
        }
    }

    static getDerivedStateFromProps(props) {
        const {
            form: { errors },
        } = props;

        const programErrors = Object.keys(errors)
            .filter((key) => key.includes("program@"))
            .reduce(
                (obj, key) => ({
                    ...obj,
                    [key]: errors[key],
                }),
                {},
            );

        return {
            errors: programErrors,
        };
    }

    getDefaultValueDate() {
        const {
            data: { firstWorkingDate },
        } = this.props;

        return moment(firstWorkingDate, "YYYY-MM-DD");
    }

    handleClick = () => {
        this.setState({ isDisplayed: true });
    };

    handleErrors = () => {
        const { errors } = this.state;
        if (!isEmpty(errors)) {
            return (
                <div className="form-group has-error">
                    {Object.keys(errors).map((error) => (
                        <div className="form-group has-error">
                            <FieldError error={errors[error]} />
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    handleValueChange = (data) => {
        const { field, form } = this.props;
        if (data.frequency === schedulerUtils.ONCE) {
            form.setFieldValue(field.name, {
                ...field.value,
                selectedOption: data.frequency,
                valueDate: moment(data.startsOn),
                program: null,
            });
        } else {
            const newProgramData = {
                frequency: data.frequency === schedulerUtils.CUSTOM ? data.customFreq : data.frequency, // one of DAY, WEEK, MONTH, YEAR
                frequencyValue: data.frequency === schedulerUtils.CUSTOM ? data.customFreqValue : "1", // int that represents the frequency, frequency = DAY +  frequencyValue = 2 => every 2 days
                days: dateUtils.getDayFromDate(data.startsOn), // int that represents the days of the week used by the java class Days at the backend. Same class used by the calendar restriction
                day: data.startsOn.getDate(), // day of the month.
                occurrence: null, // occurrence=3 + days=4 every 3rd WEDNESDAY of the month
                startsOn: data.startsOn,
                lapse: data.lapse.lapse, // no change at all
                number: data.lapse.number,
                date: data.lapse.date,
                isCustom: data.frequency === schedulerUtils.CUSTOM,
            };
            if (newProgramData.frequency === schedulerUtils.WEEK && newProgramData.isCustom) {
                // if it's custom, I take the days from the bitwisedays control
                newProgramData.days = data.days;
            }
            if (newProgramData.frequency === schedulerUtils.MONTH && data.occurrenceType === "occurrence") {
                newProgramData.occurrence = dateUtils.weekOfMonth(moment(data.startsOn));
                newProgramData.day = null;
            }
            form.setFieldValue(field.name, {
                ...field.value,
                selectedOption: newProgramData.frequency,
                program: newProgramData,
            });
        }
        form.setTouched({ ...form.touched, [field.name]: true });
        this.handleCloseBottomSheetClick();
    };

    handleCloseBottomSheetClick = () => {
        this.setState({ isDisplayed: false });
    };

    updatePastDate(value) {
        if (value && value.valueDate) {
            const currentDate = new Date();
            if (isPast(value.valueDate) && !isSameDay(currentDate.getTime(), value.valueDate)) {
                const { form } = this.props;
                form.values.scheduler.valueDate = new Date();
            }
        }
    }

    render() {
        const {
            data: { mode, programable, schedulable },
            field: { value },
            location,
            isDesktop,
            showShedulerMessage,
            disabled,
            label,
        } = this.props;
        const { errors, isDisplayed } = this.state;
        this.updatePastDate(value);
        if (programable || schedulable) {
            if (mode === "edit") {
                return (
                    <>
                        <SchedulerModal
                            idForm={FORM_ID}
                            handleCloseBottomSheetClick={this.handleCloseBottomSheetClick}
                            goBack={this.handleCloseBottomSheetClick}
                            onScheduleClick={this.handleValueChange}
                            value={value}
                            isDisplayed={isDisplayed}
                            isDesktop={isDesktop}
                        />
                        <FieldLabel
                            labelKey="scheduler.label"
                            idField="readableID"
                            className="display"
                            hideLabel
                            notMarginY
                        />
                        <div className="form-group form-group--scheduler">
                            <div className="form-group-text scheduler mt-0">
                                <button
                                    aria-haspopup="dialog"
                                    className="btn btn-primary px-2 btn-scheduler ml-0"
                                    onClick={this.handleClick}
                                    aria-expanded={isDisplayed}
                                    type="button"
                                    disabled={disabled}>
                                    <Image src="images/calendar-white.svg" className="svg-icon mr-2" />
                                    {!value || value.selectedOption === undefined ? (
                                        <>
                                            <I18n
                                                id={`${
                                                    location && location.pathname !== "/transfer/modify"
                                                        ? label
                                                        : "scheduler.editDate"
                                                }`}
                                                componentProps={{ "aria-hidden": true }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <I18n
                                                id="transfers.schedule.label.scheduled.transfer"
                                                componentProps={{ "aria-hidden": true }}
                                            />
                                            <I18n
                                                id="transfers.schedule.label.scheduled.transfer"
                                                componentProps={{ className: "visually-hidden" }}
                                            />
                                        </>
                                    )}
                                </button>
                                {!isEmpty(errors) && this.handleErrors()}
                            </div>
                            {showShedulerMessage && (
                                <span className="scheduler-message">
                                    <Message value={value || { valueDate: "" }} />
                                </span>
                            )}
                        </div>
                    </>
                );
            }
        }

        return null;
    }
}

export default Scheduler;
