import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { shape, string, func, bool, arrayOf } from "prop-types";
import { Formik } from "formik";

import { actions } from "reducers/administration/common/group";
import { actions as groupsActions } from "reducers/administration/groups";
import { groupSelectors, permissionsSelectors } from "reducers/administration";
import * as administrationUtils from "util/administration";

import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import PageLoading from "pages/_components/PageLoading";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import PermissionsPanel from "pages/administration/_components/advancedPermissionsForm/PermissionsPanel";
import MembersList from "pages/administration/_components/MembersList";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import AdvancedDetailPermissionsList from "pages/administration/_components/AdvancedDetailPermissionsList";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import InfoTag from "pages/_components/InfoTag";
import AdministrationHeading from "pages/administration/_components/Heading";
import Circle from "pages/_components/Circle";

class Group extends Component {
    static propTypes = {
        match: shape({
            params: shape({
                id: string,
            }),
        }).isRequired,
        dispatch: func.isRequired,
        groupStatus: string.isRequired,
        name: string.isRequired,
        description: string,
        adminGroup: bool.isRequired,
        fetching: bool.isRequired,
        permissions: administrationUtils.permissionsPropType,
        members: arrayOf(shape({})),
    };

    static defaultProps = {
        description: "",
        permissions: {},
        members: [],
    };

    componentDidMount() {
        const { match, dispatch } = this.props;

        dispatch(actions.loadGroupRequest(match.params.id));
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(push(`/administration/groups`));
    };

    handleActionClick = (actionOnTheUser, fullName) => {
        const { match, dispatch } = this.props;
        dispatch(groupsActions.changeGroupStatusPreview([match.params.id], [fullName], actionOnTheUser));
    };

    handleModify = () => {
        const { match, dispatch } = this.props;

        dispatch(push(`/administration/advanced/group/${match.params.id}/step1`));
    };

    renderPageHeader = () => {
        const { name, description } = this.props;

        return (
            <>
                <div className="admin-detail-head admin-detail-head-section px-0">
                    <Head onBack={this.handleBack} />
                    <div className="mt-2 mb-45">
                        <Head
                            titleText={name}
                            toolbarItemClassName="pr-0"
                            titleClassName="align-font-fix-03"
                            handleModifyClick={() => this.handleModify()}
                            hasInlineButtons>
                            {this.renderActionButtons()}
                        </Head>
                    </div>
                    <AdministrationHeading>
                        {() => (
                            <Row className="content-data px-2">
                                <Heading.Data>
                                    <Heading.DataGroup
                                        label="administration.advanced.group.create.description.label"
                                        data={description}
                                    />
                                </Heading.Data>
                            </Row>
                        )}
                    </AdministrationHeading>
                </div>
            </>
        );
    };

    renderActionButtons = () => {
        const { name, groupStatus, adminGroup } = this.props;
        const isAdministrator = adminGroup;

        return (
            <div className="flex-container ml-3">
                <div className="data-wrapper data-wrapper-flex ml-2">
                    {isAdministrator && (
                        <InfoTag
                            type="default"
                            message={i18nUtils.get(`administration.administrator`)}
                            tagBackground="#707070"
                        />
                    )}
                    <InfoTag
                        type="default"
                        message={i18nUtils.get(`user.status.${groupStatus}`)}
                        tagBackground={groupStatus === "blocked" ? "#fc5f5f" : "#0be0a2"}
                    />
                </div>
                {!isAdministrator && (
                    <div className="flex-container">
                        {groupStatus === "active" && (
                            <div>
                                <Button
                                    bsStyle="link"
                                    key="block"
                                    label="administration.block"
                                    type="button"
                                    onClick={() => this.handleActionClick("block", name)}
                                />
                            </div>
                        )}
                        {groupStatus === "blocked" && (
                            <div>
                                <Button
                                    bsStyle="link"
                                    key="unblock"
                                    label="administration.unblock"
                                    type="button"
                                    onClick={() => this.handleActionClick("unblock", name)}
                                />
                            </div>
                        )}

                        <Button
                            bsStyle="link"
                            key="delete"
                            label="administration.delete"
                            type="button"
                            onClick={() => this.handleActionClick("delete", name)}
                        />
                    </div>
                )}
            </div>
        );
    };

    renderContent = () => {
        const { permissions, members } = this.props;

        return (
            <>
                {this.renderPageHeader()}
                <MainContainer>
                    <div className="above-the-fold mh-auto">
                        <Container className="flex-grow">
                            <Col md={4} sm={12} className="pl-0">
                                <header className="col-header align-items-center mt-4 mb-2">
                                    <I18n
                                        id="administration.members"
                                        component="h2"
                                        componentProps={{
                                            className: "mr-3 my-0align-font-fix-04",
                                        }}
                                    />
                                    <Circle value={members.length} bgColor="#0071ce" smallSize />
                                </header>
                                <Container.ColumnBody>
                                    <MembersList />
                                </Container.ColumnBody>
                            </Col>
                            <Col md={8} sm={12}>
                                <header className="col-header mt-4 mb-2">
                                    <I18n
                                        id="administration.permissions"
                                        component="h2"
                                        componentProps={{
                                            className: "my-0 align-font-fix-04",
                                        }}
                                    />
                                </header>
                                <Col className="list-wrapper">
                                    <Formik
                                        initialValues={{ permissions: permissions || {} }}
                                        onSubmit={() => {}}
                                        enableReinitialize
                                        render={({ values }) => (
                                            <PermissionsPanel
                                                mode="view"
                                                render={(content) => (
                                                    <div className="row">
                                                        <Col className="col col-6">
                                                            <div className="form-group">
                                                                <AdvancedDetailPermissionsList
                                                                    permissions={values.permissions}
                                                                />
                                                            </div>
                                                        </Col>
                                                        {content && <Col className="col col-6 mt-25">{content}</Col>}
                                                    </div>
                                                )}
                                            />
                                        )}
                                    />
                                </Col>
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    };

    render() {
        const { fetching, name } = this.props;
        const isLoading = fetching && name === "";

        return (
            <>
                <Notification scopeToShow="administration" />
                <PageLoading loading={isLoading}>{!isLoading && this.renderContent()}</PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    name: groupSelectors.getName(state),
    description: groupSelectors.getDescription(state),
    adminGroup: groupSelectors.isAdminGroup(state),
    groupStatus: groupSelectors.getGroupStatus(state),
    fetching: groupSelectors.isFetching(state),
    permissions: permissionsSelectors.getPermissions(state),
    members: groupSelectors.getMembers(state),
});

export default connect(mapStateToProps)(Group);
