import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { selectors as transactionsSelectors } from "reducers/transactions";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import ProductDetail from "pages/_components/ProductDetail";
import List from "pages/transactions/_components/List";
import FormattedDate from "pages/_components/FormattedDate";

const filtersWithInfo = ["period", "amount"];

class MobileFiltersResults extends Component {
    componentDidMount() {}

    renderHeadInfo = () => {
        const {
            filters: { dateFrom, dateTo, filter, minAmount, maxAmount },
        } = this.props;

        const results = {
            period: (dateFrom || dateTo) && (
                <Fragment>
                    {dateFrom && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="transactions.list.filters.period.dateFrom.label" />
                            </div>
                            <div className="data-date">
                                <FormattedDate date={dateFrom} />
                            </div>
                        </div>
                    )}
                    {dateTo && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="transactions.list.filters.period.dateTo.label" />
                            </div>
                            <div className="data-date">
                                <FormattedDate date={dateTo} />
                            </div>
                        </div>
                    )}
                </Fragment>
            ),
            amount: (minAmount || maxAmount) && (
                <Fragment>
                    {minAmount && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="transactions.list.filters.amount.minAmount.label" />
                            </div>
                            <div className="data-amount">{` ${minAmount}`}</div>
                        </div>
                    )}
                    {maxAmount && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="transactions.list.filters.amount.maxAmount.label" />
                            </div>
                            <div className="data-amount">{` ${maxAmount}`}</div>
                        </div>
                    )}
                </Fragment>
            ),
        };

        return <div>{results[filter]}</div>;
    };

    render() {
        const { isDesktop, filters } = this.props;
        const { filter } = filters;
        return (
            <Fragment>
                <Head
                    backLinkTo="/transactions/list/filters"
                    title={`transactions.list.filter.${
                        !filtersWithInfo.includes(filter) ? filter : `searchBy.${filter}`
                    }`}>
                    {this.renderHeadInfo()}
                </Head>
                <MainContainer>
                    <div className="above-the-fold">
                        <ProductDetail>
                            <ProductDetail.Body>
                                <List isDesktop={isDesktop} />
                            </ProductDetail.Body>
                        </ProductDetail>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: transactionsSelectors.getFilters(state),
});

export default connect(mapStateToProps)(MobileFiltersResults);
