import React, { Component } from "react";
import { connect } from "react-redux";
import { string, shape, bool, func, obj } from "prop-types";
import { selectors as debinSelectors } from "reducers/debin";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Yup from "yup";
import * as i18n from "util/i18n";
import { actions, selectors as frequentDestinationSelector } from "reducers/frequentDestination";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import classNames from "classnames";
import { selectors as sessionSelectors } from "reducers/session";

const FORM_ID = "charges.debin.requestDebin";
const MIN_REQUIRED = "6";
const MAX_REQUIRED = "22";
class NewReceiver extends Component {
    static propTypes = {
        handleSubmit: func.isRequired,
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        values: shape({
            receiver: string.isRequired,
            reference: string.isRequired,
            email: string.isRequired,
            schedule: bool.isRequired,
        }).isRequired,
        activeEnvironment: shape({
            permissions: shape({
                accounts: bool,
                requestTransactionCancellation: bool,
                transferForeign: bool,
                transferInternal: bool,
                transferLocal: bool,
                transferThirdParties: bool,
            }),
            forms: obj,
            administrationScheme: string,
            type: string,
        }).isRequired,
    };

    render() {
        const { isSubmitting, isDesktop, values, handleSubmit, activeEnvironment } = this.props;

        return (
            <div className={classNames({ "mt-7 mx-6": isDesktop })}>
                <div className="form-section-title admin-content-center mb-5">
                    {isDesktop && <I18n component="h2" id="charges.debin.requestDebin.add.receiver" />}
                </div>
                <Form
                    className={classNames("above-the-fold", { "mt-8": !isDesktop })}
                    onSubmit={(e) => {
                        handleSubmit(e);
                        e.stopPropagation();
                    }}>
                    <Container className="flex-grow align-items-center" gridClassName="form-content add-receiver-debin">
                        <Col lg={12}>
                            <Col lg={12} className="px-0">
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="receiver"
                                    type="text"
                                />
                            </Col>
                            {activeEnvironment?.permissions["frequentDestination.manage"] && (
                                <Col lg={12} className="px-0">
                                    <Field
                                        component={Checkbox}
                                        idForm={FORM_ID}
                                        name="schedule"
                                        isDesktop={isDesktop}
                                        labelText={`${FORM_ID}.schedule.label`}
                                        labelTextProps={{ componentProps: { className: "data-label" } }}
                                    />
                                </Col>
                            )}
                        </Col>

                        {values.schedule && (
                            <>
                                <Col lg={12}>
                                    <Field
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="reference"
                                        type="text"
                                    />
                                </Col>
                                <Col lg={12}>
                                    <Field
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="email"
                                        type="email"
                                    />
                                </Col>
                            </>
                        )}
                        <Col lg={12} className="schedule-debin-receiver-button">
                            <Button type="submit" bsStyle="primary" label="global.add" loading={isSubmitting} />
                        </Col>
                    </Container>
                </Form>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    cbuList: debinSelectors.getListCbu(state),
    frequentDestination: frequentDestinationSelector.getFrequentDestination(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ receiver: "", email: "", reference: "", schedule: false }),
        validationSchema: ({ cbuList }) =>
            Yup.lazy((values) => {
                const isValidCbu = (value) => {
                    const reg = /^\d+$/;
                    return reg.test(value) && value.length === parseInt(MAX_REQUIRED, 10);
                };

                const isValidAlias = (value) => {
                    /* Regex that accept letter from a to z including capital letters, (.), (-), numbers 
                    and excluding (Ñ) and (ñ) */
                    const reg = /^(?:(?![ñÑ])[a-zA-Z.\-\d])+$/;
                    return reg.test(value);
                };

                const validateCbuOrAlias = (value) => isValidCbu(value) || isValidAlias(value);

                if (!values.schedule) {
                    return Yup.object().shape({
                        receiver: Yup.string()
                            .min(
                                parseInt(MIN_REQUIRED, 10),
                                `${i18n.get("forms.validation.min.required")} ${MIN_REQUIRED}`,
                            )
                            .max(
                                parseInt(MAX_REQUIRED, 10),
                                `${i18n.get("forms.validation.max.required")} ${MAX_REQUIRED}`,
                            )
                            .required(i18n.get("accounts.new.account.field.error.required"))
                            .test("format", i18n.get(`${FORM_ID}.field.error.invalid`), validateCbuOrAlias)
                            .notOneOf(
                                cbuList.map((cbuElement) => cbuElement.cbu),
                                i18n.get("accounts.new.account.field.error.repeated"),
                            ),
                    });
                }
                return Yup.object().shape({
                    receiver: Yup.string()
                        .required(i18n.get("accounts.new.account.field.error.required"))
                        .notOneOf(
                            cbuList.map((cbuElement) => cbuElement.cbu),
                            i18n.get("accounts.new.account.field.error.repeated"),
                        ),
                    reference: Yup.string().required(i18n.get("accounts.new.account.field.error.required")),
                    email: Yup.string().email(i18n.get("frequentDestination.create.email.invalid")),
                });
            }),
        handleSubmit: (filters, formikBag) => {
            const { dispatch } = formikBag.props;
            const params = {
                cbu: filters.receiver,
                email: filters.email,
                name: filters.reference,
                schedule: filters.schedule,
            };

            dispatch(actions.getCbuAccountInformation(params, formikBag));
        },
    }),
)(NewReceiver);
