import React from "react";
import { func, bool } from "prop-types";
import { connect } from "react-redux";
import Image from "pages/_components/Image";
import classNames from "classnames";
import { selectors as sessionSelectors } from "reducers/session";

class ToggleSidebar extends React.Component {
    static propTypes = {
        onToggle: func.isRequired,
        isRetailEnvironment: bool.isRequired,
    };

    render() {
        const { onToggle, isRetailEnvironment } = this.props;
        return (
            <button
                type="button"
                className={classNames("toggle-menu-button", {
                    "menu-retail-sidebar": isRetailEnvironment,
                })}
                onClick={onToggle}>
                <Image src="images/sidebar.svg" className="svg-icon sidebar-menu" />
            </button>
        );
    }
}

const mapStateToProps = (state) => ({
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
});

export default connect(mapStateToProps)(ToggleSidebar);
