import React, { Component } from "react";
import { Spring, config } from "react-spring";

class AccordionItemContent extends Component {
    render() {
        const { isActive, children } = this.props;

        return (
            <Spring
                config={config.stiff}
                from={{ height: 0, margin: "0rem 0 0rem" }}
                to={{
                    height: isActive ? "auto" : 0,
                    overflow: "hidden",
                }}>
                {(styles) => (
                    <div className="form-group" style={styles}>
                        <div className="form-group-control-list">{children}</div>
                    </div>
                )}
            </Spring>
        );
    }
}

export default AccordionItemContent;
