import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { idTransactionFormat } from "util/format";
import * as accountUtils from "util/accounts";

import FormattedAmount from "pages/_components/FormattedAmount";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import * as i18n from "util/i18n";

class PaymentServiceDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                data: {
                    summaryInfo: { selectedEntity, selectedAmountToPay, selectedConcept, selectedDebitAccount },
                },
            } = object.transaction;

            return (
                <>
                    <CardContainer className="confirmation__cards-container mt-0">
                        <Card
                            className="confirmation__cards"
                            icon={isDesktop && "images/transfer-PENDING.svg"}
                            iconClass="card__pending-transaction-icon"
                            title={
                                !isDesktop && (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get("services.newPayment.account.label")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>
                                                {selectedDebitAccount &&
                                                    accountUtils.getIncomingDebinAccountName(selectedDebitAccount)}
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                            content={
                                isDesktop ? (
                                    <div className="d-flex align-items-center">
                                        <div className="confirmation-cards__content pb-1">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("services.newPayment.account.label")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {selectedDebitAccount &&
                                                        accountUtils.getIncomingDebinAccountName(selectedDebitAccount)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("services.newPayment.entities.label")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{selectedEntity?.description}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content info-type-b">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("services.newPayment.concept")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{selectedConcept?.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("services.newPayment.entities.label")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{selectedEntity?.description}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("services.newPayment.concept")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{selectedConcept?.description}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("forms.transaction.ticket.number")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            bottom={
                                isDesktop && (
                                    <div className="confirmation-cards__bottom">
                                        <div className="confirmation-cards__bottom-data">
                                            <span>{i18n.get("forms.transaction.ticket.number")}</span>
                                        </div>
                                        <div className="confirmation-cards__bottom-info">
                                            <span>{idTransactionFormat(idTransaction)}</span>
                                        </div>
                                    </div>
                                )
                            }
                            rightContent={
                                <div className="confirmation-cards__right">
                                    <div className="confirmation-cards__right-data">
                                        <FormattedAmount currency="ARS" quantity={selectedAmountToPay} />
                                    </div>
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        });
    };

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(PaymentServiceDataTable);
