import { takeLatest, put, call } from "redux-saga/effects";

import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { types, actions } from "reducers/files";
import * as fileMiddleware from "middleware/file";
import * as downloadUtil from "util/download";
import { download } from "util/download";

const sagas = [
    takeLatest(types.DOWNLOAD_FILE_REQUEST, downloadFileRequest),
    takeLatest(types.GET_FILE_CONTENTS_REQUEST, getFileContentsRequest),
];

export default sagas;

const fetchBlobData = async (url) => {
    let responseBlob;
    try {
        const response = await fetch(url);
        responseBlob = await response.blob();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
    return responseBlob;
};

function* downloadFileRequest({ idFile }) {
    const fileResponse = yield call(fileMiddleware.download, idFile);
    const { fileName, content } = fileResponse.data.data;
    download(fileName, content);

    // solo para quitar el descargando
    yield put(actions.downloadFileSuccess());
}

function* getFileContentsRequest({ idFile, deleteFile, idUserCreator }) {
    let fileResponse;
    let blob;

    if (idUserCreator) {
        fileResponse = yield fileMiddleware.downloadStreamWithUser(idFile, idUserCreator);
        const base64 = fileResponse?.data?.data?.file?.contents;

        if (base64) {
            blob = yield fetchBlobData(`data:application/octet-stream;base64,${base64}`);
        }
    } else {
        fileResponse = yield fileMiddleware.downloadStream(idFile, null);
        const base64 = fileResponse?.data?.data?.file?.contents;

        if (base64) {
            blob = yield fetchBlobData(`data:application/octet-stream;base64,${base64}`);
        }

        blob = fileResponse.data;
    }

    const content =
        blob &&
        downloadUtil
            .getBlobContent(blob)
            .split("\n")
            .map((line, i) => {
                const [account, cbu, cuit, amount, voucher, motivo, referenc, reference] = line.split(";");
                return {
                    account,
                    cbu,
                    cuit,
                    creditAmountQuantity: amount,
                    voucher,
                    motivo,
                    referenc,
                    reference,
                    lineNumber: i + 1,
                };
            });
    if (deleteFile) {
        yield fileMiddleware.deleteFile(Number(idFile));
    }

    content.pop();
    yield put(transactionLinesActions.getFileContentsSuccess(content || []));
}
