import React, { Component } from "react";
import Button from "pages/_components/Button";
import { func, string, bool } from "prop-types";
import classNames from "classnames";

class CameraButton extends Component {
    static propTypes = {
        id: string,
        loading: bool,
        handleClick: func.isRequired,
        selfie: bool,
    };

    static defaultProps = {
        id: "cameraBtn",
        loading: false,
        selfie: false,
    };

    handleClick = () => {
        const { handleClick } = this.props;

        if (handleClick) {
            handleClick();
        }
    };

    render() {
        const { id, selfie, loading } = this.props;

        return (
            <Button
                bsStyle="primary"
                className={classNames("btn-picture", { "selfie-btn": selfie })}
                block={false}
                loading={loading}
                id={id}
                onClick={this.handleClick}
                defaultLabelText="Scan"
            />
        );
    }
}

export default CameraButton;
