import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Link from "react-router-dom/Link";
import { func, arrayOf, objectOf, bool } from "prop-types";
import { actions, selectors } from "reducers/settings";
import { goBack } from "react-router-redux";
import { Checkbox as CheckBoxComponent } from "pages/_components/fields/Checkbox";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Image from "pages/_components/Image";

class NotificationsConfiguration extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        communicationTypes: arrayOf(objectOf(bool)),
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        communicationTypes: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.notificationsConfigurationPre());
    }

    renderTitleMobile = () => (
        <div>
            <h1 className="m-0 align-center">
                <I18n id="settings.notificationsConfiguration" />
            </h1>
        </div>
    );

    renderCommunicationTypes = () => {
        const { communicationTypes } = this.props;

        return communicationTypes.map((communicationType) => {
            const [key, value] = Object.entries(communicationType)[0];

            return (
                <li className="navigational-list-item" key={key}>
                    <Link to={`/settings/notificationsConfiguration/${key}`}>
                        <CheckBoxComponent checked={value} block={false} formGroup={false} readOnly hideLabel />
                        <span>
                            <I18n id={`communications.communicationsTypes.${key}`} />
                        </span>
                        <Image src="images/chevromRight.svg" className="svg-icon svg-caret" />
                    </Link>
                </li>
            );
        });
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    renderContent = () => {
        const { communicationTypes } = this.props;

        return (
            communicationTypes && (
                <form className="above-the-fold">
                    <section className="container--layout flex-grow align-items-center">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <p className="text-lead">
                                        <I18n id="settings.notificationsConfiguration.title" />
                                    </p>
                                    <ul className="navigational-list navigational-list--notifications">
                                        {this.renderCommunicationTypes()}
                                    </ul>
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                </form>
            )
        );
    };

    render() {
        const { fetching } = this.props;
        const { isDesktop } = this.props;
        return (
            <Fragment>
                <div className="admin-detail-head px-0">
                    <Head
                        backLinkTo="/settings"
                        headerClassName={!isDesktop && "blue-main-header-mobile"}
                        centerElement={!isDesktop && this.renderTitleMobile}
                        hideMobileMenu={!isDesktop}
                    />
                    {isDesktop && (
                        <h1>
                            <I18n id="settings.notificationsConfiguration" />
                        </h1>
                    )}
                </div>
                <MainContainer showLoader={fetching}>{this.renderContent()}</MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    communicationTypes: selectors.getCommunicationTypes(state),
    fetching: selectors.isFetching(state),
});

export default connect(mapStateToProps)(NotificationsConfiguration);
