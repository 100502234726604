import React from "react";
import { arrayOf, bool, func, shape } from "prop-types";
import { connect } from "react-redux";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

import { selectors as fundcorpSelectors } from "reducers/fundcorp";

import * as configUtils from "util/config";
import FundSuscriptionCard from "./_subscribe/FundSuscriptionCard";

function FundListDrawer({ isDesktop, onSelect, fundOrderedFeatures }) {
    return (
        <div className="fundcorp__suscribe-drawer-content">
            {isDesktop ? (
                <div className="fundcorp__suscribe-drawer-title">
                    <Image src="images/funds-blue.svg" className="svg-icon" />
                    <I18n id="fundcorp.suscribe.drawer.title.desktop" component="h2" />
                </div>
            ) : (
                undefined
            )}
            <I18n
                defaultValue={configUtils.get("subscription.fundAvailable.text")}
                component="p"
                componentProps={{
                    className: "fundcorp__suscribe-drawer-subtitle",
                }}
            />
            {fundOrderedFeatures && fundOrderedFeatures?.length > 0 && (
                <>
                    {fundOrderedFeatures.map((fundGroup) => (
                        <div className="fundcorp__suscribe-cards-container">
                            <div className="fundcorp__suscribe-cards-container-header">
                                <h3>{Object.values(fundGroup)[0].title}</h3>
                            </div>
                            {Object.values(fundGroup)[0]
                                .founds.filter((found) => Object.values(found)[0].isON)
                                .map((found) => {
                                    const item = Object.values(found)[0];
                                    return (
                                        <FundSuscriptionCard
                                            onSelect={() => onSelect(item)}
                                            isDesktop={isDesktop}
                                            {...item}
                                        />
                                    );
                                })}
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

FundListDrawer.propTypes = {
    isDesktop: bool.isRequired,
    onSelect: func.isRequired,
    fundOrderedFeatures: arrayOf(shape({})).isRequired,
};

const mapStateToProps = (state) => ({
    fundOrderedFeatures: fundcorpSelectors.getFundOrderedFeatures(state),
});

export default connect(mapStateToProps)(FundListDrawer);
