import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { compose } from "redux";
import { connect } from "react-redux";
import { shape, func, bool } from "prop-types";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedRate from "pages/_components/FormattedRate";

import { resizableRoute } from "pages/_components/Resizable";
import { selectors as depositsSelectors } from "reducers/deposits";
import * as i18nUtils from "util/i18n";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";

class Information extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        deposit: shape({}).isRequired,
    };

    renderContent = ({
        currency,
        nextPaymentAmount,
        dueDate,
        productType,
        rate,
        openDate,
        automaticRenewal,
        consolidatedAmount,
        earnedInterests,
        anticipatedCancelation,
        withHoldings,
        termInDays,
    }) => {
        const { isDesktop } = this.props;
        const getFormattedAmount = (amount) => {
            if (amount) {
                return (
                    <FormattedAmount
                        currency={currency}
                        quantity={amount}
                        notBold
                        fontClassName={isDesktop ? "f-size-4" : "f-size-65"}
                    />
                );
            }

            return <span className="data-text">-</span>;
        };

        const getFormattedDate = (date) => {
            if (date) {
                return (
                    <FormattedDate
                        date={date}
                        dateFormat="dd/MM/yyyy"
                        className={isDesktop ? "f-size-4 font-light" : "f-size-65"}
                    />
                );
            }

            return <span className="data-text">-</span>;
        };

        const className = "align-items-center flex-grow container-white with-margin py-2";
        if (isDesktop) {
            return (
                <>
                    <Container className={className}>
                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <I18n componentProps={{ className: "data-label" }} id="deposits.information.type" />
                                <span className="data-text">{productType}</span>
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <I18n componentProps={{ className: "data-label" }} id="deposits.information.dueDate" />
                                {getFormattedDate(dueDate)}
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <I18n
                                    componentProps={{ className: "data-label" }}
                                    id="deposits.information.nextPaymentAmount"
                                />
                                {getFormattedAmount(nextPaymentAmount)}
                            </div>
                        </Col>
                        {/* </Row>
                    <Row> */}
                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <I18n
                                    componentProps={{ className: "data-label" }}
                                    id="deposits.information.consolidatedAmount"
                                />
                                {getFormattedAmount(consolidatedAmount)}
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <I18n componentProps={{ className: "data-label" }} id="deposits.information.rate" />
                                <FormattedRate rate={rate} symbolToTheLeft={false} />
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <I18n
                                    componentProps={{ className: "data-label" }}
                                    id="deposits.information.earnedInterests"
                                />
                                {getFormattedAmount(earnedInterests)}
                            </div>
                        </Col>
                    </Container>
                    <Container className={className}>
                        {/* <Row> */}
                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <I18n componentProps={{ className: "data-label" }} id="deposits.information.openDate" />
                                {getFormattedDate(openDate)}
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <I18n
                                    componentProps={{ className: "data-label" }}
                                    id="deposits.information.termInDays"
                                />
                                <span className="data-text">{termInDays}</span>
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <I18n
                                    componentProps={{ className: "data-label" }}
                                    id="deposits.information.withHoldings"
                                />
                                {getFormattedAmount(withHoldings)}
                            </div>
                        </Col>

                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <I18n
                                    componentProps={{ className: "data-label" }}
                                    id="deposits.information.automaticRenewal"
                                />
                                <span className="data-text">
                                    {i18nUtils.get(`deposits.information.automaticRenewal.${automaticRenewal}`)}
                                </span>
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4} className="col col-12">
                            <div className="data-wrapper">
                                <I18n
                                    componentProps={{ className: "data-label" }}
                                    id="deposits.information.anticipatedCancelation"
                                />
                                <span className="data-text">
                                    {i18nUtils.get(
                                        `deposits.information.anticipatedCancelation.${anticipatedCancelation}`,
                                    )}
                                </span>
                            </div>
                        </Col>
                        <Col sm={12} md={4} lg={4} className="col col-12" />
                    </Container>
                </>
            );
        }

        const containerClassName = `transfer-data data-wrapper-flex ${!isDesktop ? "space-between mb-0" : ""}`;
        const labelClassName = isDesktop ? "mb-0" : "";
        return (
            <Container className={className}>
                <Col sm={12} md={4} lg={4} className="col col-12">
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label="deposits.information.type"
                        data={<span className="data-text">{productType}</span>}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label="deposits.information.dueDate"
                        data={getFormattedDate(dueDate)}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label="deposits.information.nextPaymentAmount"
                        data={getFormattedAmount(nextPaymentAmount)}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label="deposits.information.consolidatedAmount"
                        data={getFormattedAmount(consolidatedAmount)}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label="deposits.information.rate"
                        data={
                            <FormattedRate
                                rate={rate}
                                symbolToTheLeft={false}
                                className={isDesktop ? "f-size-4" : "f-size-65"}
                            />
                        }
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label="deposits.information.earnedInterests"
                        data={getFormattedAmount(earnedInterests)}
                    />

                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label="deposits.information.openDate"
                        data={getFormattedDate(openDate)}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label="deposits.information.termInDays"
                        data={termInDays}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label="deposits.information.withHoldings"
                        data={getFormattedAmount(withHoldings)}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label="deposits.information.automaticRenewal"
                        data={i18nUtils.get(`deposits.information.automaticRenewal.${automaticRenewal}`)}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        label="deposits.information.anticipatedCancelation"
                        data={i18nUtils.get(`deposits.information.anticipatedCancelation.${anticipatedCancelation}`)}
                    />
                </Col>
            </Container>
        );
    };

    render() {
        const { deposit, dispatch } = this.props;
        return this.renderContent(deposit, dispatch);
    }
}

const mapStateToProps = (state) => ({
    deposit: depositsSelectors.getSelectedDeposit(state),
});

export default compose(connect(mapStateToProps), resizableRoute)(Information);
