import React, { Component } from "react";
import { node, string } from "prop-types";
import classNames from "classnames";

import TableBody from "pages/_components/table/Body";
import TableFooter from "pages/_components/table/Footer";
import TableHeader from "pages/_components/table/Header";
import TableHeaderData from "pages/_components/table/HeaderData";
import TableRow from "pages/_components/table/Row";
import TableData from "pages/_components/table/Data";

class Table extends Component {
    static propTypes = {
        children: node.isRequired,
        className: string,
    };

    static defaultProps = {
        className: "",
    };

    static Body = TableBody;

    static Footer = TableFooter;

    static Header = TableHeader;

    static HeaderData = TableHeaderData;

    static Row = TableRow;

    static Data = TableData;

    render() {
        const { children, className } = this.props;
        return <div className={classNames("table", className)}>{children}</div>;
    }
}

export default Table;
