/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { idTransactionFormat } from "util/format";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class AdminUserChannelsDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                submitDateTimeAsString,
                data: { userFullName, enabledChannels, maxAmounts, capFrequencies },
            } = object.transaction;

            return (
                <CardContainer className="confirmation__cards-container">
                    <Card
                        className="confirmation__cards"
                        icon={isDesktop && "images/transfer-PENDING.svg"}
                        iconClass="card__pending-transaction-icon"
                        hrClass={!maxAmounts && "d-none"}
                        title={
                            isDesktop ? (
                                submitDateTimeAsString && (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <I18n id="forms.transaction.ticket.date" />
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>{submitDateTimeAsString}</span>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div>
                                    {userFullName && (
                                        <div className="confirmation-cards__title">
                                            <div className="confirmation-cards__title-data">
                                                <I18n id="transaction.ticket.user" />
                                            </div>
                                            <div className="confirmation-cards__title-info">
                                                <span>{userFullName}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        content={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    {userFullName && (
                                        <div
                                            className={
                                                enabledChannels && maxAmounts && capFrequencies
                                                    ? "confirmation-cards__content pb-1"
                                                    : "confirmation-cards__content"
                                            }>
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="administration.users.invite.firstName.label" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{userFullName}</span>
                                            </div>
                                        </div>
                                    )}
                                    {enabledChannels &&
                                        maxAmounts &&
                                        capFrequencies &&
                                        this.renderChannels(enabledChannels, maxAmounts, capFrequencies)}
                                </div>
                            ) : (
                                <div>
                                    {submitDateTimeAsString && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="forms.transaction.ticket.date" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{submitDateTimeAsString}</span>
                                            </div>
                                        </div>
                                    )}
                                    {enabledChannels &&
                                        maxAmounts &&
                                        capFrequencies &&
                                        this.renderChannels(enabledChannels, maxAmounts, capFrequencies)}
                                    {idTransactionFormat(idTransaction) && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="forms.transaction.ticket.number" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        bottom={
                            isDesktop && (
                                <div className="d-flex align-items-center">
                                    {idTransactionFormat(idTransaction) && (
                                        <div className="confirmation-cards__bottom">
                                            <div className="confirmation-cards__bottom-data">
                                                <I18n id="forms.transaction.ticket.number" />
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        rightContent={
                            enabledChannels &&
                            maxAmounts &&
                            capFrequencies && (
                                <div className="confirmation-cards__right">
                                    <div className="confirmation-cards__right-data">
                                        <I18n id="administration.channels.topAmount" />
                                    </div>
                                    <div
                                        className={
                                            isDesktop
                                                ? "confirmation-cards__right-info withEllipsis"
                                                : "confirmation-cards__right-info"
                                        }>
                                        <div className={isDesktop && "confirmation-cards__with-ellipsis"}>
                                            {this.renderTotalAmount(enabledChannels, maxAmounts, capFrequencies)}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    />
                </CardContainer>
            );
        });
    };

    renderChannels = (enabledChannels, maxAmounts, capFrequencies) =>
        enabledChannels
            .filter((channel) => channel !== "all")
            .map((channel, index) => (
                <div
                    className={
                        channel === "frontend"
                            ? `confirmation-cards__content ${this.props.isDesktop && "info-type-a"}`
                            : `confirmation-cards__content ${this.props.isDesktop && "info-type-b"}`
                    }>
                    <div className="confirmation-cards__content-data">
                        <I18n id={`channels.${channel}`} />
                    </div>
                    <div
                        className={
                            this.props.isDesktop
                                ? "confirmation-cards__content-info withEllipsis"
                                : "confirmation-cards__content-info"
                        }>
                        <div className={this.props.isDesktop && "confirmation-cards__with-ellipsis"}>
                            {this.renderCap(maxAmounts[index], capFrequencies[index])}
                        </div>
                    </div>
                </div>
            ));

    renderTotalAmount = (enabledChannels, maxAmounts, capFrequencies) =>
        enabledChannels
            .filter((channel) => channel === "all")
            .map((channel, index) => this.renderCap(maxAmounts[index], capFrequencies[index]));

    renderCap = (quantity, frequency) => (
        <FormattedAmount
            currency={configUtils.get("core.masterCurrency")}
            frequency={i18nUtils.get(`administration.channels.${frequency}`)}
            quantity={quantity}
        />
    );

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(AdminUserChannelsDataTable);
