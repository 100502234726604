import { call, put, takeLatest } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import * as onboarding from "middleware/onboarding";

import { types as onboardingTypes } from "reducers/onboarding";

import { adjustIdFieldErrors } from "util/form.js";

const sagas = [
    takeLatest(onboardingTypes.TAKE_PICTURE_REQUEST, takePicture),
    takeLatest(onboardingTypes.REQUEST_LIST_STATES, listStates),
    takeLatest(onboardingTypes.REQUEST_LIST_JOBS, listJobs),
    takeLatest(onboardingTypes.REQUEST_LIST_SELECTOR_OPTIONS, listSelectorList),
    takeLatest(onboardingTypes.REQUEST_LIST_OCCUPATIONS, listOccupations),

    takeLatest(
        onboardingTypes.UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_REQUEST,
        uploadClientInfoAndSendInvitationCode,
    ),
    takeLatest(onboardingTypes.UPLOAD_PICTURE_REQUEST, uploadPicture),
];

export default sagas;
const errorStep = {
    documentBack: "step2/back",
    documentFront: "step2/front",
    passport: "step2",
    selfie: "step3",
};

const nextStep = {
    documentBack: "step3",
    documentFront: "step2/back",
    passport: "step3",
    selfie: "step4",
};

function* takePicture({ currentStep, documentSide, picture, exchangeToken }) {
    yield put({ type: onboardingTypes.TAKE_PICTURE_SUCCESS, picture, exchangeToken });
    yield put(push(`/onboarding/${currentStep}${documentSide ? `/${documentSide}` : ""}/processing`));
}

function* listStates() {
    const response = yield call(onboarding.listStates);

    if (response) {
        if (response.type === "W") {
            const errors = adjustIdFieldErrors(response.data.data);

            yield put({
                type: onboardingTypes.FAILURE_LIST_STATES,
                errors,
            });
            yield put(push(`/onboarding/step5/error`));
        } else {
            const { states } = response.data.data;

            yield put({
                type: onboardingTypes.SUCCESS_LIST_STATES,
                states,
            });
        }
    }
}

function* listJobs() {
    const response = yield call(onboarding.listJobs);

    if (response) {
        if (response.type === "W") {
            const errors = adjustIdFieldErrors(response.data.data);

            yield put({
                type: onboardingTypes.FAILURE_LIST_JOBS,
                errors,
            });
            yield put(push(`/onboarding/step6/error`));
        } else {
            const { jobs } = response.data.data;

            yield put({
                type: onboardingTypes.SUCCESS_LIST_JOBS,
                jobs,
            });
        }
    }
}

function* listSelectorList() {
    const response = yield call(onboarding.listSelectorList);

    if (response) {
        if (response.type === "W") {
            const errors = adjustIdFieldErrors(response.data.data);

            yield put({
                type: onboardingTypes.FAILURE_LIST_OCCUPATIONS,
                errors,
            });
            yield put(push(`/onboarding/step6/error`));
        } else {
            const {
                occupation_options,
                source_of_wealth_options,
                annual_income_options,
                countryList,
            } = response.data.data;

            yield put({
                type: onboardingTypes.SUCCESS_LIST_OCCUPATIONS,
                occupation_options,
                source_of_wealth_options,
                annual_income_options,
                countryList,
            });
        }
    }
}

function* listOccupations() {
    const response = yield call(onboarding.listOccupations);

    if (response) {
        if (response.type === "W") {
            const errors = adjustIdFieldErrors(response.data.data);

            yield put({
                type: onboardingTypes.FAILURE_LIST_OCCUPATIONS,
                errors,
            });
            yield put(push(`/onboarding/step6/error`));
        } else {
            const { occupations } = response.data.data;
            yield put({
                type: onboardingTypes.SUCCESS_LIST_OCCUPATIONS,
                occupations,
            });
        }
    }
}

function* uploadClientInfoAndSendInvitationCode({
    documentNumber,
    email,
    firstName,
    formikBag,
    lastName,
    mobileNumber,
    address,
    jobInformation,
    SSNID,
    region,
}) {
    const { exchangeToken } = formikBag.props;
    try {
        const response = yield call(
            onboarding.uploadClientInfo,
            documentNumber,
            email,
            firstName,
            lastName,
            mobileNumber,
            address,
            jobInformation,
            exchangeToken,
            SSNID,
            region,
        );

        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);

                yield put({
                    type: onboardingTypes.UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_ERROR,
                    errors,
                });
                yield put(push(`/onboarding/step4/error`));
                // yield put(push("/onboarding/EmailValidation"));
            } else if (response.status === 200) {
                yield put({
                    type: onboardingTypes.UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_SUCCESS,
                    ...response.data.data,
                });

                // yield put(push("/onboarding/success"));
                yield put(push("/enrollment"));
            }
        }

        if (formikBag) {
            formikBag.setSubmitting(false);
        }
    } catch (error) {
        if (error.data.code === "API507E") {
            yield put(push("/onboarding/invitation/fail"));
            // yield put(push("/onboarding/EmailValidation"));
        } else {
            throw error;
        }
    }
}

function* uploadPicture({ documentType, exchangeToken, picture, rotatePicture }) {
    try {
        const response = yield call(onboarding.uploadPicture, documentType, picture, exchangeToken, rotatePicture);

        if (response && response.status === 200) {
            const { _exchangeToken, ...rest } = response.data.data;

            if (response.type === "W") {
                const errors = adjustIdFieldErrors(rest);

                yield put({
                    type: onboardingTypes.UPLOAD_PICTURE_ERROR,
                    errors,
                    exchangeToken: _exchangeToken || exchangeToken,
                });
                yield put(replace(`/onboarding/${errorStep[documentType]}/error`));
            } else {
                if (documentType === "documentBack" || documentType === "passport") {
                    const { documentCountry, documentNumber, firstName, fullDocument, lastName, dateOfBirth } = rest;
                    yield put({
                        type: onboardingTypes.SET_DOCUMENT_DATA,
                        documentCountry,
                        documentNumber,
                        firstName,
                        fullDocument,
                        lastName,
                        dateOfBirth,
                    });
                }

                yield put({
                    type: onboardingTypes.UPLOAD_PICTURE_SUCCESS,
                    exchangeToken: _exchangeToken,
                    ...rest,
                });

                if (rest.skipBackDocumentStep) {
                    const { documentCountry, documentNumber, firstName, fullDocument, lastName, dateOfBirth } = rest;
                    yield put({
                        type: onboardingTypes.SET_DOCUMENT_DATA,
                        documentCountry,
                        documentNumber,
                        firstName,
                        fullDocument,
                        lastName,
                        dateOfBirth,
                    });
                    yield put(replace(`/onboarding/${nextStep.documentBack}`));
                } else {
                    yield put(replace(`/onboarding/${nextStep[documentType]}`));
                }
            }
        }
    } catch (error) {
        const errors = adjustIdFieldErrors(error);
        yield put({ type: onboardingTypes.UPLOAD_PICTURE_ERROR, errors });

        yield put(replace(`/onboarding/${errorStep[documentType]}/error`));
    }
}
