import React, { Component } from "react";
import * as i18nUtils from "util/i18n";
import { shape, string } from "prop-types";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";

class ConfirmationInfo extends Component {
    propTypes = {
        idForm: string.isRequired,
        values: shape({}).isRequired,
        editingGroup: string.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
    };

    render() {
        const { values, idForm, editingGroup, location } = this.props;
        const confirmStep1Path =
            location?.pathname === "/administration/advanced/group/create/confirm/step1" ||
            location?.pathname === "/administration/advanced/group/create/confirm/step3";

        return (
            <>
                <section>
                    {editingGroup ? (
                        <h2 className={confirmStep1Path ? "mb-0" : "header-create-group-info"}>
                            {i18nUtils.get(`${idForm}.title.confirm.edit.group`)}
                        </h2>
                    ) : (
                        <h2 className={confirmStep1Path ? "mb-0" : "header-create-group-info"}>
                            {i18nUtils.get(`${idForm}.title.confirm.create.group`)}
                        </h2>
                    )}
                    <Container>
                        <Col
                            className={confirmStep1Path ? "col-12 px-0" : "col-12 max-xl-480 px-0"}
                            md={confirmStep1Path ? 12 : 9}
                            lg={confirmStep1Path ? 12 : 6}>
                            <ul className="ul-group-info">
                                <li>
                                    <span className="create-group-info-item">
                                        {i18nUtils.get(`${idForm}.name.label`).toUpperCase()}
                                    </span>{" "}
                                    {values?.name}
                                </li>
                                <li>
                                    <span className="create-group-info-item">
                                        {i18nUtils.get(`${idForm}.description.label`).toUpperCase()}
                                    </span>{" "}
                                    {values?.description}
                                </li>

                                <li>
                                    <span className="create-group-info-item">
                                        {i18nUtils.get(`${idForm}.status.label`).toUpperCase()}
                                    </span>{" "}
                                    {i18nUtils.get(`${idForm}.status.${values?.status}.label`)}
                                </li>
                            </ul>
                        </Col>
                    </Container>
                </section>
            </>
        );
    }
}

export default ConfirmationInfo;
