import React, { Component } from "react";
import classNames from "classnames";
import ReactSelect from "react-select";
import { shape, func } from "prop-types";

import ResizableComponent from "pages/_components/ResizableComponent";
import { string } from "prop-types";

class Select extends Component {
    static propTypes = {
        onChange: func,
        form: shape({}),
        field: shape({}),
        id: string.isRequired,
        className: string,
    };

    static defaultProps = {
        onChange: null,
        form: {},
        field: {},
        className: "",
    };

    ref = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            openTop: false,
        };
    }

    componentDidMount() {
        this.setOpenDirection();
    }

    handleResize = () => {
        this.setOpenDirection();
    };

    setOpenDirection = () => {
        const { current } = this.ref;
        const { control } = current;
        const { bottom, height } = control.getBoundingClientRect();
        const criteria = bottom > window.innerHeight ? window.outerHeight : window.innerHeight;

        // 5 is the minimum number of options that have to be visible to open the select down
        if (bottom + Math.min(5, current._visibleOptions.length) * height > criteria) {
            this.setState({
                openTop: true,
            });
        }
    };

    render() {
        const { className, id, onChange, form, field, ...props } = this.props;
        const { openTop } = this.state;

        return (
            <ResizableComponent onResize={this.handleResize}>
                <ReactSelect
                    value={typeof field.value === "string" ? field.value : undefined}
                    id={id}
                    instanceId={id}
                    aria-labelledby={`label.${id}`}
                    ref={this.ref}
                    className={classNames([
                        className,
                        {
                            "select-open-top": openTop,
                        },
                    ])}
                    onChange={(option) => {
                        if (onChange) {
                            onChange(option);
                        } else if (form && form.setFieldValue) {
                            form.setFieldValue(field.name, !option ? "" : option.value);
                        }
                    }}
                    {...props}
                />
            </ResizableComponent>
        );
    }
}

export default Select;
