import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    ACCOUNT_UNLINKING: "assistant/ACCOUNT_UNLINKING",
    SEND_MESSAGE_TO_MESSENGER: "assistant/SEND_MESSAGE_TO_MESSENGER",
    SET_PARAMS: "assistant/SET_PARAMS",
    SET_ACCESS_TOKEN: "assistant/SET_ACCESS_TOKEN",
    SET_MESSENGER_PSID: "assistant/SET_MESSENGER_PSID",
};

export const INITIAL_STATE = {
    isAssistantLogin: false,
    response_type: "",
    client_id: "",
    redirect_uri: "",
    state: "",
    _accessToken: "",
    isFromAmazon: false,
    isFromGoogle: false,
    isFromMessenger: false,
    isFromWhatsapp: false,
    currentAssistant: "",
    accountLinkingToken: null,
    messengerPSID: null,
    twilio_redirect_uri: null,
    clientNumber: null,
    thirdPartyToken: "",
};

export default createReducer(INITIAL_STATE, {
    [types.SET_PARAMS]: (state, action) => ({
        ...state,
        isAssistantLogin: true,
        response_type: action.params.response_type,
        client_id: action.params.client_id,
        redirect_uri: action.params.redirect_uri,
        state: action.params.state,
        isFromAmazon: action.params.isFromAmazon,
        isFromGoogle: action.params.isFromGoogle,
        isFromMessenger: action.params.isFromMessenger,
        currentAssistant: action.params.currentAssistant,
        accountLinkingToken: action.params.account_linking_token,
        isFromWhatsapp: action.params.isFromWhatsapp,
        twilio_redirect_uri: action.params.twilio_redirect_uri,
        clientNumber: action.params.client_number,
        thirdPartyToken: action.params.custom_token,
        recipientId: action.params.recipientId,
        senderId: action.params.senderId,
    }),
    [types.SET_ACCESS_TOKEN]: (state, action) => ({
        ...state,
        _accessToken: action._accessToken,
    }),
    [types.SET_MESSENGER_PSID]: (state, action) => ({
        ...state,
        messengerPSID: action.messengerPSID,
    }),
    [types.SET_THIRD_PARTY_TOKEN]: (state, action) => ({
        ...state,
        thirdPartyToken: action.thirdPartyToken,
    }),
});

export const actions = {
    sendMessageToMessenger: makeActionCreator(types.SEND_MESSAGE_TO_MESSENGER, "psid", "subject"),
    setParams: makeActionCreator(types.SET_PARAMS, "params"),
    setAccessToken: makeActionCreator(types.SET_ACCESS_TOKEN, "_accessToken"),
    setMessengerPSID: makeActionCreator(types.SET_MESSENGER_PSID, "messengerPSID"),
};

export const selectors = {
    isAssistantLogin: ({ assistant }) => assistant.isAssistantLogin,
    getAccessToken: ({ assistant }) => assistant._accessToken,
    getParams: ({ assistant }) => ({
        response_type: assistant.response_type,
        client_id: assistant.client_id,
        redirect_uri: assistant.redirect_uri ? assistant.redirect_uri : assistant.twilio_redirect_uri,
        state: assistant.state,
    }),
    isFromGoogle: ({ assistant }) => assistant.isFromGoogle,
    isFromAmazon: ({ assistant }) => assistant.isFromAmazon,
    isFromMessenger: ({ assistant }) => assistant.isFromMessenger,
    isFromWhatsapp: ({ assistant }) => assistant.isFromWhatsapp,
    getClientNumber: ({ assistant }) => assistant.clientNumber,
    getCurrentAssistant: ({ assistant }) => assistant.currentAssistant,
    getAccountLinkingToken: ({ assistant }) => assistant.accountLinkingToken,
    getMessengerPSID: ({ assistant }) => assistant.messengerPSID,
    getThirdPartyToken: ({ assistant }) => assistant.thirdPartyToken,
    getRecipientId: ({ assistant }) => assistant.recipientId,
    getSenderId: ({ assistant }) => assistant.senderId,
};
