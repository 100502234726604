import React from "react";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import { element, string, number, func, bool } from "prop-types";
import { connect } from "react-redux";

import classNames from "classnames";

import Image from "pages/_components/Image";
import Badge from "pages/_components/Badge";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { Mixpanel } from "util/clickstreaming";

class MenuItem extends React.Component {
    static propTypes = {
        title: element, // en caso de que el titulo ya venga "traducido"
        titleKey: string, // en caso de que el titulo sea una key de mensaje
        linkTo: string,
        image: string,
        isSelected: bool,
        onSelect: func,
        onClick: func,
        closeOnSelect: bool,
        notificationCount: number,
        notificationCountTextID: string,
        itemClassName: string,
        svgClassName: string,
        dispatch: func.isRequired,
    };

    static defaultProps = {
        title: null,
        titleKey: "",
        linkTo: "",
        image: "",
        isSelected: false,
        onSelect: null,
        onClick: null,
        closeOnSelect: false,
        notificationCount: 0,
        notificationCountTextID: "",
        itemClassName: "",
        svgClassName: "svg-image",
    };

    onClickMenu = (linkTo) => {
        const { onSelect, dispatch } = this.props;
        if (onSelect) {
            onSelect();
        }
        Mixpanel.track_user("Menu Action", { linkTo });
        dispatch(push(linkTo));
        return null;
    };

    getContent() {
        const {
            dispatch,
            linkTo,
            image,
            isSelected,
            title,
            titleKey,
            onClick: handleOnClick,
            closeOnSelect,
            onSelect,
            notificationCount,
            notificationCountTextID,
            itemClassName,
            svgClassName,
            ...rest
        } = this.props;
        let content = null;

        if (linkTo) {
            content = (
                <Button
                    bsStyle="link"
                    onClick={() => {
                        this.onClickMenu(linkTo);
                    }}
                    className={classNames("w-100 m-0 color-inherit", {
                        "item-selected": isSelected,
                        "with-badge": notificationCount,
                    })}
                    {...rest}>
                    {image && <Image src={image} className={svgClassName} />}
                    {title || <I18n id={titleKey} componentProps={{ className: "m-0 text-left" }} />}
                    {notificationCount > 0 && (
                        <>
                            <Badge count={notificationCount} />
                            <I18n id={notificationCountTextID} componentProps={{ className: "visually-hidden" }} />
                        </>
                    )}
                </Button>
            );
        } else {
            content = (
                <span
                    role="button"
                    className={classNames({ "with-badge": notificationCount }, itemClassName)}
                    onClick={() => {
                        if (closeOnSelect) {
                            onSelect();
                        }
                        handleOnClick();
                    }}
                    onKeyDown={() => {
                        if (closeOnSelect) {
                            onSelect();
                        }
                        handleOnClick();
                    }}
                    tabIndex={0}>
                    {image && <Image src={image} />}
                    {title || <I18n id={titleKey} componentProps={{ className: "m-0 text-left" }} />}
                </span>
            );
        }

        return content;
    }

    render() {
        return this.getContent();
    }
}

export default withRouter(connect()(MenuItem));
