import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { string, func, bool, shape } from "prop-types";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import { Field } from "formik";
import Col from "react-bootstrap/lib/Col";

import { actions as servicePaymentsActions } from "reducers/servicePayments";
import { selectors as sessionSelectors } from "reducers/session";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import Checkbox from "pages/_components/Checkbox";
import AmountField from "pages/_components/fields/formik/AmountField";
import TextField from "pages/_components/fields/TextField";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";

import { agendaPagoShape } from "util/servicePayments";
import Table from "pages/_components/Table";
import classNames from "classnames";
import ContextMenu from "pages/_components/ContextMenu";
import Range from "pages/_components/Range";
import InfoTag from "pages/_components/InfoTag";
import PaymentItemMobile from "./PaymentItemMobile";

const MultiplePaymentsItem = (props) => {
    const {
        currencies,
        isDesktop,
        isSmallDesktop,
        tableLabel,
        FORM_ID,
        payment,
        handleSelectOne,
        handleUniquePayment,
        setQuantity,
        setPaymentReference,
        activeEnvironment,
        dispatch,
    } = props;
    const [hasError, setHasError] = useState(false);

    const unattachService = () => {
        const {
            entity: { description, handleBaseDebts, code, category },
        } = payment;

        const adhesion = {
            adhesionCode: payment.codeAdherence,
            enteDescription: description,
            enteCode: code,
            userLp: payment.ususerLP,
            category: {
                code: category.code,
                description: category.description,
                shortDescrition: category.shortDescrition,
            },
            reference: payment.reference,
            manejaBaseDeuda: handleBaseDebts,
        };
        dispatch(servicePaymentsActions.loadSummaryInfo(adhesion));
        dispatch(push(`/desadherir/confirmation`));
    };

    const menuExpiration = ({ isPending }) => {
        const actions = [];
        if (activeEnvironment.permissions.newPayment && !isPending) {
            actions.push({
                label: "payments.afip.list.actions.pay",
                onClick: () => handleUniquePayment(payment),
            });
        }
        actions.push({
            label: "services.adhesiones.table.header.unattachService",
            onClick: () => unattachService(),
        });
        return <ContextMenu imageStyle="pr-0" items={actions} />;
    };

    const menuConcept = ({ isPending }) => {
        if (activeEnvironment.permissions.newPayment && !isPending) {
            return (
                <ContextMenu
                    imageStyle="pr-0"
                    items={[
                        {
                            label: "payments.afip.list.actions.pay",
                            onClick: () => handleUniquePayment(payment),
                        },
                    ]}
                />
            );
        }
        return null;
    };

    const {
        entity: { description, handleBaseDebts },
        reference,
    } = payment;

    const row = handleBaseDebts
        ? payment.expirations.map((expiration) => (
              <Table.Row className={`container-white border-bottom-none ${!isDesktop && "my-2"}`} key={payment.key}>
                  <Container className="flex-grow container-white text-uppercase">
                      {isDesktop ? (
                          <Col
                              sm={12}
                              className={classNames("px-0", {
                                  "d-flex align-items-center": isSmallDesktop,
                              })}>
                              <Col md={3} sm={12} className="d-flex h-100">
                                  <Heading.DataGroup
                                      containerClassName={classNames("data-wrapper-flex ", {
                                          "mb-0": isSmallDesktop,
                                          "my-2": !isSmallDesktop,
                                      })}
                                      data={
                                          <>
                                              <Checkbox
                                                  className="mb-0"
                                                  hideLabel
                                                  checked={payment.isChecked}
                                                  name={payment.key}
                                                  onChange={() => handleSelectOne(payment)}
                                                  isDisabled={payment.isPending}
                                              />
                                              <Link to={payment.link} className="text-decoration-none">
                                                  <span className="data-label f-size-4 mr-2">
                                                      {description.toUpperCase()}
                                                  </span>
                                                  {payment.isPending ? (
                                                      <span>
                                                          <InfoTag
                                                              type="info"
                                                              message={i18nUtils.get(
                                                                  `services.payments.detail.status.PENDING`,
                                                              )}
                                                              tagBackground="#FFE5B6"
                                                              tagClass="max-width-mincontent text-nowrap"
                                                              moreStyles={{
                                                                  color: "black",
                                                              }}
                                                          />
                                                      </span>
                                                  ) : (
                                                      <span className="f-size-6 color-primary">
                                                          {reference.toUpperCase()}
                                                      </span>
                                                  )}
                                              </Link>
                                          </>
                                      }
                                      dataClassName={classNames("d-flex", {
                                          "f-dir-col": isSmallDesktop,
                                          "align-items-center": !isSmallDesktop,
                                      })}
                                  />
                              </Col>
                              <Col md={2} sm={12} className="d-flex h-100">
                                  <Heading.DataGroup
                                      containerClassName={classNames(
                                          "light-font font-weight-600 data-wrapper-flex justify-content-center ",
                                          {
                                              "my-2": !isSmallDesktop,
                                          },
                                      )}
                                      label={`${tableLabel}.agenda.expiration.label`}
                                      data={
                                          <FormattedDate
                                              className="f-size-4"
                                              date={expiration.date}
                                              dateFormat="dd/MM/yyyy"
                                          />
                                      }
                                  />
                              </Col>
                              <Col md={3} sm={12} className="payment__input-group d-flex h-100">
                                  <Heading.DataGroup
                                      containerClassName={classNames(
                                          "light-font font-weight-600 data-wrapper-flex justify-content-center mb-0",
                                          {
                                              "my-2": !isSmallDesktop,
                                          },
                                      )}
                                      label="services.newPayment.paymentRef.label"
                                      labelClassName="f-size-6 max-width-7rem"
                                      data={
                                          expiration.managesIncomeReference ? (
                                              <Field
                                                  idForm={FORM_ID}
                                                  formGroupClassName="mb-2"
                                                  component={TextField}
                                                  renderAs="input"
                                                  hidePlaceholder
                                                  name={`${payment.key}_paymentReference`}
                                                  type="text"
                                                  label="services.newPayment.paymentRef.label"
                                                  hideLabel
                                                  isDisabled={
                                                      !payment.isChecked || expiration.maxLengthTextReference === 0
                                                  }
                                                  onCustomChange={(value) => setPaymentReference(payment, value)}
                                              />
                                          ) : (
                                              <I18n
                                                  id="transfer.notAvailable"
                                                  componentProps={{ className: "data-label f-size-6" }}
                                              />
                                          )
                                      }
                                  />
                              </Col>
                              <Col
                                  md={4}
                                  sm={12}
                                  className={`payment__amount-input-group 
                                  d-flex align-items-center justify-content-end h-100 ${expiration.managesRange &&
                                      expiration.managesIncomeAmounts &&
                                      "flex-column"}`}>
                                  <Heading.DataGroup
                                      containerClassName="data-wrapper-flex justify-content-end w-100"
                                      label="services.newPayment.amountToPay"
                                      labelClassName="f-size-6 mr-2"
                                      data={
                                          expiration.managesIncomeAmounts && payment.isChecked ? (
                                              <>
                                                  <div className="d-flex align-items-center">
                                                      <Field
                                                          idForm={FORM_ID}
                                                          inputGroupClassName="mt-0"
                                                          formGroupClassName="my-0"
                                                          autocomplete="off"
                                                          component={AmountField}
                                                          data={{
                                                              options: currencies,
                                                          }}
                                                          name={`${payment.key}_conceptAmount${expiration.debtId}`}
                                                          label="services.newPayment.amountToPay"
                                                          hideLabel
                                                          disableSelect
                                                          fixedDecimalScale
                                                          isDesktop={isDesktop}
                                                          onCustomOnChange={(quantity) =>
                                                              setQuantity(payment, quantity)
                                                          }
                                                          setHasError={setHasError}
                                                      />
                                                      {menuExpiration(payment)}
                                                  </div>
                                              </>
                                          ) : (
                                              <div className="d-flex align-items-center">
                                                  <FormattedAmount
                                                      className="data-amount"
                                                      fontClassName="f-size-4"
                                                      currency={currencies[0].id.toString()}
                                                      quantity={expiration.amount}
                                                  />
                                                  {menuExpiration(payment)}
                                              </div>
                                          )
                                      }
                                  />
                                  {expiration.managesRange && expiration.managesIncomeAmounts && (
                                      <div className="d-flex justify-content-end w-100 pr-45 mt-1">
                                          <Range
                                              currency={currencies[0].id}
                                              min={expiration.minAmount}
                                              max={expiration.maxAmount}
                                              hasError={hasError}
                                          />
                                      </div>
                                  )}
                              </Col>
                          </Col>
                      ) : (
                          <PaymentItemMobile
                              handleChek={handleSelectOne}
                              setPaymentReference={setPaymentReference}
                              setQuantity={setQuantity}
                              payment={payment}
                              concept={expiration}
                              paymentHasExpiration
                              reference={reference}
                              description={description}
                              editableAmount={expiration.managesIncomeAmounts}
                              currencies={currencies}
                              tableLabel={tableLabel}
                              FORM_ID={FORM_ID}
                              menu={menuExpiration(payment)}
                          />
                      )}
                  </Container>
              </Table.Row>
          ))
        : payment.concepts.map((concept) => (
              <Table.Row
                  className={`container-white border-bottom-none ${!isDesktop && "h-auto mh-auto py-2 my-2"}`}
                  key={payment.key}>
                  <Container gridClassName="py-3" className="flex-grow container-white text-uppercase">
                      {isDesktop ? (
                          <Col
                              sm={12}
                              className={classNames("px-0", {
                                  "d-flex align-items-center": isSmallDesktop,
                              })}>
                              <Col md={3} sm={12} className="d-flex h-100">
                                  <Heading.DataGroup
                                      containerClassName={classNames("data-wrapper-flex ", {
                                          "mb-0": isSmallDesktop,
                                          "my-2": !isSmallDesktop,
                                      })}
                                      data={
                                          <>
                                              <Checkbox
                                                  className="my-2"
                                                  hideLabel
                                                  checked={payment.isChecked}
                                                  name={payment.key}
                                                  onChange={() => handleSelectOne(payment)}
                                                  isDisabled={payment.isPending}
                                              />
                                              <Link to={payment.link} className="text-decoration-none word-break">
                                                  <span className="data-label f-size-4 mr-2">
                                                      {description.toUpperCase()}
                                                  </span>
                                                  {payment.isPending ? (
                                                      <span>
                                                          <InfoTag
                                                              type="info"
                                                              message={i18nUtils.get(
                                                                  `services.payments.detail.status.PENDING`,
                                                              )}
                                                              tagClass="max-width-mincontent text-nowrap"
                                                              tagBackground="#FFE5B6"
                                                              moreStyles={{
                                                                  color: "black",
                                                              }}
                                                          />
                                                      </span>
                                                  ) : (
                                                      <span className="f-size-6 color-primary">
                                                          {reference.toUpperCase()}
                                                      </span>
                                                  )}
                                              </Link>
                                          </>
                                      }
                                      dataClassName={classNames("d-flex", {
                                          "align-items-center": !isSmallDesktop,
                                      })}
                                  />
                              </Col>
                              <Col md={2} sm={12} className="d-flex h-100">
                                  <Heading.DataGroup
                                      containerClassName={classNames(
                                          "light-font font-weight-600 data-wrapper-flex justify-content-center mb-0",
                                          {
                                              "my-2": !isSmallDesktop,
                                          },
                                      )}
                                      label={`${tableLabel}.agenda.expiration.label`}
                                      data={<I18n id="services.newPayment.noExpirationDate" />}
                                  />
                              </Col>
                              <Col md={3} sm={12} className="payment__input-group d-flex h-100">
                                  <Heading.DataGroup
                                      containerClassName={classNames(
                                          "light-font font-weight-600 data-wrapper-flex justify-content-center mb-0",
                                          {
                                              "my-2": !isSmallDesktop,
                                          },
                                      )}
                                      label="services.newPayment.paymentRef.label"
                                      labelClassName="f-size-6 max-width-7rem"
                                      data={
                                          concept.managesIncomeReference ? (
                                              <Field
                                                  idForm={FORM_ID}
                                                  formGroupClassName="mb-2"
                                                  component={TextField}
                                                  renderAs="input"
                                                  hidePlaceholder
                                                  name={`${payment.key}_paymentReference`}
                                                  type="text"
                                                  label="services.newPayment.paymentRef.label"
                                                  hideLabel
                                                  isDisabled={
                                                      !payment.isChecked || concept.maxLengthTextReference === 0
                                                  }
                                                  onCustomChange={(value) => setPaymentReference(payment, value)}
                                              />
                                          ) : (
                                              <I18n
                                                  id="transfer.notAvailable"
                                                  componentProps={{ className: "data-label f-size-6" }}
                                              />
                                          )
                                      }
                                  />
                              </Col>
                              <Col
                                  md={4}
                                  sm={12}
                                  className="payment__amount-input-group d-flex align-items-center justify-content-end h-100">
                                  <div>
                                      <Heading.DataGroup
                                          containerClassName="data-wrapper-flex justify-content-end w-100"
                                          label="services.newPayment.amountToPay"
                                          labelClassName="f-size-6 mr-2"
                                          data={
                                              concept.managesIncomeAmounts && payment.isChecked ? (
                                                  <>
                                                      <Field
                                                          idForm={FORM_ID}
                                                          inputGroupClassName="mt-0"
                                                          formGroupClassName="my-0"
                                                          autocomplete="off"
                                                          component={AmountField}
                                                          data={{
                                                              options: currencies,
                                                          }}
                                                          name={`${payment.key}_conceptAmount${concept.conceptCode}`}
                                                          label="services.newPayment.amountToPay"
                                                          hideLabel
                                                          disableSelect
                                                          fixedDecimalScale
                                                          isDesktop={isDesktop}
                                                          onCustomOnChange={(quantity) =>
                                                              setQuantity(payment, quantity)
                                                          }
                                                          setHasError={setHasError}
                                                      />
                                                  </>
                                              ) : (
                                                  <>
                                                      <span className="d-flex align-items-center">
                                                          <span className="data-amount px-2">
                                                              <span className="data-amount-currency f-size-4">-</span>
                                                          </span>
                                                      </span>
                                                  </>
                                              )
                                          }
                                          dataClassName="data-desc"
                                      />
                                      {concept.managesRange && concept.managesIncomeAmounts && payment.isChecked && (
                                          <div className="d-flex justify-content-end w-100">
                                              <Range
                                                  currency={currencies[0].id}
                                                  min={concept.minAmount}
                                                  max={concept.maxAmount}
                                                  className="p-absolute bottom-minus2"
                                                  containerClassName="d-inline-block"
                                                  hasError={hasError}
                                              />
                                          </div>
                                      )}
                                  </div>
                                  {menuConcept(payment)}
                              </Col>
                          </Col>
                      ) : (
                          <PaymentItemMobile
                              handleChek={handleSelectOne}
                              setPaymentReference={setPaymentReference}
                              setQuantity={setQuantity}
                              payment={payment}
                              concept={concept}
                              paymentHasExpiration={false}
                              reference={reference}
                              description={description}
                              editableAmount={concept.managesIncomeAmounts}
                              currencies={currencies}
                              tableLabel={tableLabel}
                              FORM_ID={FORM_ID}
                              menu={menuConcept(payment)}
                          />
                      )}
                  </Container>
              </Table.Row>
          ));

    return row;
};

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

MultiplePaymentsItem.propTypes = {
    tableLabel: string.isRequired,
    currencies: string.isRequired,
    dispatch: func.isRequired,
    isDesktop: bool,
    isSmallDesktop: bool,
    activeEnvironment: shape({
        permissions: shape({
            newPayment: bool,
        }),
    }).isRequired,
    payment: agendaPagoShape.isRequired,
    FORM_ID: string.isRequired,
    setQuantity: func.isRequired,
    setPaymentReference: func.isRequired,
    agendaPago: agendaPagoShape.isRequired,
    formikBag: shape({}),
};

MultiplePaymentsItem.defaultProps = {
    isDesktop: false,
    isSmallDesktop: false,
};

export default compose(connect(mapStateToProps))(MultiplePaymentsItem);
