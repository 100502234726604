import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { func, shape } from "prop-types";
import { selectors, actions } from "reducers/administration/common/administrationTicket";

import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";

class GroupActionTicket extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    componentDidMount() {
        this.chargeData();
    }

    chargeData = () => {
        const { dispatch, transactionData } = this.props;

        const hasGroup = transactionData?.data?.groupNameList;
        const isBlocked = transactionData?.data?.blocked;

        const groupAction = hasGroup ? "delete" : (isBlocked && "block") || "unblock";

        const groups = hasGroup ? transactionData.data.groupNameList.reduce((a, b) => a + b?.concat("\n "), "") : null;

        const extraData = {
            group: {
                label: `administration.groups.action.confirmation.${groupAction}`,
                value: groups,
            },
        };

        dispatch(actions.setExtraData(extraData));
    };

    render() {
        const { transactionData } = this.props;

        if (transactionData?.data?.groupNameList) {
            let groupAction = "delete";
            if (transactionData.data.blocked !== undefined) {
                groupAction = transactionData?.data?.blocked ? "block" : "unblock";
            }
            const textKey = `administration.groups.action.confirmation.${groupAction}`;
            const ticketText = transactionData?.data?.groupNameList?.map((groupName, index) => (
                <div key={index}> {groupName} </div>
            ));
            return (
                <Container
                    className="container--layout align-items-center flex-grow group-action-ticket group-action-ticket-data"
                    gridClassName="form-content">
                    <Col>
                        <div className="transfer-data data-wrapper">
                            <Container.ColumnBody columnClass="list-wrapper">
                                <h4 className="form-title">
                                    <I18n id={textKey} />
                                </h4>
                                {ticketText}
                            </Container.ColumnBody>
                        </div>
                    </Col>
                </Container>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(GroupActionTicket);
