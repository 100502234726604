import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, string, func, bool } from "prop-types";
import { selectors as restrictionSelectors, actions as restrictionActions } from "reducers/administration/restrictions";
import { selectors as sessionSelectors } from "reducers/session";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import classNames from "classnames";

import Link from "react-router-dom/Link";

const RESTRICTION_TYPE_CALENDAR = "Calendar";
const RESTRICTION_TYPE_IP = "IP";
const RESTRICTION_IP = "ALL";

class RestrictionsUserProfile extends Component {
    static propTypes = {
        restrictions: shape([]),
        activeEnvironment: shape({}).isRequired,
        idUser: string.isRequired,
        dispatch: func.isRequired,
        // calendarEnabled: bool.isRequired,
        iPEnabled: bool.isRequired,
    };

    static defaultProps = {
        restrictions: [],
    };

    componentDidMount() {
        const { dispatch, idUser } = this.props;

        dispatch(restrictionActions.getRestrictionsAvailability(idUser));
    }

    renderNotRestrictionsApplied = () => (
        <I18n componentProps={{ className: "f-size-4 font-light" }} id="administration.restrictions.noRestrictions" />
    );

    getIPSection = (ipsData) =>
        ipsData.length > 0 && (
            <div>
                <div>
                    <I18n id="administration.restrictions.IP" /> {ipsData.map((i) => i.ip).join(", ")}
                </div>
            </div>
        );

    renderEnvironmentSection = (restrictions) => {
        const { activeEnvironment, iPEnabled } = this.props;
        if (!iPEnabled) {
            return this.renderNotRestrictionsApplied();
        }
        const ipsRestrictions = restrictions.filter(
            (i) => i.idEnvironment && !i.idUser && i.type === RESTRICTION_TYPE_IP,
        );
        const ipsRestrictionsUser = restrictions.filter(
            (i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_IP,
        );

        const thereIsIPRestrByUser =
            restrictions.filter((i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_IP).length > 0;
        const ipsOverwritten = ipsRestrictions.length > 0 && thereIsIPRestrByUser;
        if (iPEnabled && ipsRestrictions.length === 0) {
            return (
                <div>
                    <div
                        style={{ display: "grid" }}
                        className={`environment-IconName ${ipsRestrictionsUser.length > 0 ? "section-disabled" : ""}`}>
                        <div style={{ display: "flex" }} className="environment-Icon">
                            <div style={{ width: "2rem", alignSelf: "center" }}>
                                <Image src={`images/${activeEnvironment.type}.svg`} className="svg-icon" />
                            </div>

                            <p>{activeEnvironment.name}</p>
                        </div>
                        <div className="data-wrapper-flex">
                            <div className={classNames({ "mb-2": iPEnabled })}>
                                <I18n id="administration.restrictions.enableIP.label" /> &nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                    {ipsRestrictionsUser.length > 0 && (
                        <div className="restrictions-warning mb-3">
                            <Image src="images/warningIcon.svg" className="svg-icon" />{" "}
                            <span>
                                <I18n id="administration.restrictions.overwritten" />
                            </span>
                        </div>
                    )}
                </div>
            );
        }
        return (
            <>
                <div className={`environment-IconName ${ipsRestrictionsUser.length > 0 ? "section-disabled" : ""}`}>
                    <div className="environment-Icon">
                        <Image src={`images/${activeEnvironment.type}.svg`} className="svg-icon" />
                    </div>
                    <p>{activeEnvironment.name}</p>
                </div>
                <div className="section-disabled">{ipsOverwritten && this.getIPSection(ipsRestrictions)}</div>
                {ipsRestrictionsUser.length > 0 && (
                    <div className="restrictions-warning">
                        <Image src="images/warningIcon.svg" className="svg-icon" />{" "}
                        <span>
                            <I18n id="administration.restrictions.overwritten" />
                        </span>
                    </div>
                )}
                <div className="section-enabled">{!ipsOverwritten && this.getIPSection(ipsRestrictions)}</div>
            </>
        );
    };

    renderUserSection = (restrictions) => {
        const { iPEnabled } = this.props;

        const ipsRestrictionsUser = restrictions.filter(
            (i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_IP,
        );
        if (!iPEnabled) {
            return this.renderNotRestrictionsApplied();
        }
        if (ipsRestrictionsUser.length === 0 && iPEnabled) {
            return <I18n id="administracion.restrictions.user" />;
        }

        const ipsRestrictions = restrictions.filter(
            (i) => i.idEnvironment && i.idUser && i.type === RESTRICTION_TYPE_IP,
        );
        const ipValue = ipsRestrictions.filter((i) => i.ip === RESTRICTION_IP);
        if (ipValue.length > 0) {
            return <I18n id="administration.restrictions.noRestrictions.IP" />;
        }
        return <>{ipsRestrictions.length > 0 && this.getIPSection(ipsRestrictions)}</>;
    };

    render() {
        const { restrictions, idUser, iPEnabled } = this.props;
        const thereIsCalendarOrIpByUser =
            restrictions.filter(
                (i) => i.idUser && (i.type === RESTRICTION_TYPE_CALENDAR || i.type === RESTRICTION_TYPE_IP),
            ).length > 0;
        return (
            <>
                <div className={classNames("mb-2", { "col-User mt-2": !iPEnabled })}>
                    <span className="data-label mt-1 mb-1 mr-4">
                        <I18n id="administration.restrictions.environment" />
                    </span>
                    {this.renderEnvironmentSection(restrictions)}
                </div>
                <div className="col-User">
                    <div className="flex-wrap d-flex align-items-center">
                        <span className="data-label mt-1 mb-1 mr-4">
                            <I18n id="administration.restrictions.user" />
                        </span>

                        {iPEnabled ? (
                            <>
                                {thereIsCalendarOrIpByUser && (
                                    <Link
                                        className="btn btn-link mr-3 ml-0 mt-0 mb-0 p-0"
                                        to={`/administration/restrictions/delete/${idUser || ""}`}>
                                        <I18n id="administration.restrictions.remove" />
                                    </Link>
                                )}
                                <Link
                                    className="btn btn-link m-0 p-0"
                                    to={`/administration/restrictions/${idUser || ""}`}>
                                    <I18n
                                        linkText="administration.configure"
                                        id={`administration.restrictions.${
                                            thereIsCalendarOrIpByUser ? "edit" : "createNew"
                                        }`}
                                    />
                                </Link>
                                <div className="w-100">{this.renderUserSection(restrictions)}</div>
                            </>
                        ) : (
                            this.renderUserSection(restrictions)
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    restrictions: restrictionSelectors.getRestrictions(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    // calendarEnabled: restrictionSelectors.getCalendarEnabled(state),
    iPEnabled: restrictionSelectors.getIPEnabled(state),
});

export default connect(mapStateToProps)(RestrictionsUserProfile);
