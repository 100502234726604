import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { func, bool, shape } from "prop-types";

import TableFrequentDestination from "pages/frequentDestinations/_components/TableFrequentDestination";
import Head from "pages/_components/Head";

const FORM_ID = "frequentDestination.modal";

class CreateFrequentDesetinationModal extends Component {
    static propTypes = {
        isVisible: bool.isRequired,
        onClick: func.isRequired,
        closeModal: func.isRequired,
        isDesktop: bool.isRequired,
        selectedDebitAccount: shape({}),
    };

    static defaultProps = {
        selectedDebitAccount: {},
    };

    render() {
        const { isVisible, onClick, closeModal, isDesktop, selectedDebitAccount } = this.props;
        return (
            <Modal
                show={isVisible}
                className={isDesktop ? "transfer-template-modal" : "transfer-frequent-destination-modal px-0"}
                onHide={closeModal}>
                {isDesktop ? (
                    <Modal.Header closeButton className="no-title" />
                ) : (
                    <Head
                        onBack={closeModal}
                        title="administration.permissions.medium.itemMenu.frequentDestination"
                        titleClassName="title-content header-mobile-title-background-blue mb-0"
                    />
                )}
                <Modal.Body className="mt-0 pb-2 w-100">
                    <div className="w-100 align-items-start flex-column">
                        <TableFrequentDestination
                            formId={FORM_ID}
                            onRowClick={onClick}
                            searchClassName="transfer-frequent-destinations-search"
                            isDesktop={isDesktop}
                            selectedDebitAccount={selectedDebitAccount}
                            fromModal
                        />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default CreateFrequentDesetinationModal;
