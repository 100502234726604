import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";

import * as i18nUtils from "util/i18n";

import Container from "pages/_components/Container";
import BankSearchItem from "pages/forms/_components/_fields/_bankselector/bankSearch/Item";
import { resizableRoute } from "pages/_components/Resizable";

class BankSearchList extends Component {
    render() {
        const { items, onSelect, isDesktop, hasMoreResults } = this.props;

        return (
            <Container className="container--layout align-items-center">
                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                    <div className="table">
                        <div className="table-body">
                            {items.map((item) => (
                                <BankSearchItem key={item.code} {...item} onSelect={onSelect} isDesktop={isDesktop} />
                            ))}
                        </div>
                        <div className="table-caption table-caption-bottom">
                            {hasMoreResults && (
                                <div className="table-row">
                                    <div className="table-data">
                                        {i18nUtils.get("forms.bankselector.hasMoreResults")}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default resizableRoute(BankSearchList);
