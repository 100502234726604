/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, shape, arrayOf } from "prop-types";

import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import FormattedAmount from "pages/_components/FormattedAmount";
import EquivalentTotalBalance from "pages/metrocorp/_components/EquivalentTotalBalance";
import FormattedDate from "pages/_components/FormattedDate";
import Image from "pages/_components/Image";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

const FORM_ID = "metrocorp.list";

class MetrocorpHoldingList extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        holdings: arrayOf(shape({})).isRequired,
        currentDate: shape({}).isRequired,
    };

    renderNoResults = () => (
        <>
            <div className="text-center no-more-data px-3 mt-2">
                <div className="illustration-wrapper">
                    <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                </div>
                <p className="text-lead">
                    <I18n id={`${FORM_ID}.empty.message`} />
                </p>
            </div>
        </>
    );

    renderListForHolding = (itemList, title) => {
        const currency = "0";

        const sumFunction = (accumulator, holding) => accumulator + holding.saldoValorizadoPesos;
        const total = itemList.reduce(sumFunction, 0);

        const { isDesktop } = this.props;

        return (
            <>
                {itemList.length > 0 ? (
                    <Container className="flex-grow scrollable">
                        <Col md={12} className="with-table-header-margin px-0">
                            <I18n id={`${FORM_ID}.holding.${title}.title`} component="h4" />
                            <div className="w-100">
                                {itemList.map((item) => this.renderListForHoldingItem(item))}
                                <CardContainer className="metrocorp__card-container metrocorp__holding-container">
                                    <Card
                                        className="metrocorp__card no-hr"
                                        rightContent={
                                            <div className="metrocorp__card-right">
                                                <div className="metrocorp__totalcash">
                                                    <I18n
                                                        id={`${FORM_ID}.holding.${title}.balanceTotal`}
                                                        componentProps={{
                                                            className: "metrocorp__totalcash-info",
                                                        }}
                                                    />
                                                    <FormattedAmount
                                                        currency={currency}
                                                        className={
                                                            total >= 0
                                                                ? "metrocorp__totalcash-data"
                                                                : "metrocorp__totalcash-data metrocorp__red"
                                                        }
                                                        quantity={total}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </CardContainer>
                                {!isDesktop && <hr className="metrocorp__holding-separator" />}
                            </div>
                        </Col>
                    </Container>
                ) : (
                    <></>
                )}
            </>
        );
    };

    renderListForHoldingItem = (item) => {
        const { isDesktop } = this.props;
        const { codEspe, valorUnitario, saldo, valorPromedio, saldoValorizadoPesos } = item;

        const currency = "0";

        if (isDesktop) {
            return (
                <>
                    <CardContainer className="metrocorp__card-container">
                        <Card
                            className="metrocorp__card"
                            title={
                                <div className="metrocorp__card-title">
                                    <div className="metrocorp__specie">
                                        <I18n
                                            id={`${FORM_ID}.holding.column.species`}
                                            componentProps={{ className: "metrocorp__specie-info" }}
                                        />
                                        <span className="metrocorp__specie-data">{codEspe}</span>
                                    </div>
                                </div>
                            }
                            content={
                                <div className="metrocorp__card-content">
                                    <div className="metrocorp__description">
                                        <span className="metrocorp__description-data">{item?.descripcionEspecie}</span>
                                    </div>
                                </div>
                            }
                            bottom={
                                <div className="metrocorp__card-bottom">
                                    <div
                                        className={
                                            saldo !== null || valorPromedio !== null
                                                ? "metrocorp__unit-value metrocorp__separator"
                                                : "metrocorp__unit-value"
                                        }>
                                        <I18n
                                            id={`${FORM_ID}.holding.column.unitValue`}
                                            componentProps={{ className: "metrocorp__unit-value-info" }}
                                        />
                                        <FormattedAmount
                                            currency={currency}
                                            className={
                                                valorUnitario >= 0
                                                    ? "metrocorp__unit-value-data"
                                                    : "metrocorp__unit-value-data metrocorp__red"
                                            }
                                            quantity={valorUnitario}
                                            notBold
                                        />
                                    </div>
                                    <div
                                        className={
                                            valorPromedio !== null
                                                ? "metrocorp__balance metrocorp__separator"
                                                : "metrocorp__balance"
                                        }>
                                        <I18n
                                            id={`${FORM_ID}.holding.column.balance`}
                                            componentProps={{ className: "metrocorp__balance-info" }}
                                        />
                                        <FormattedAmount
                                            noCurrency
                                            className={
                                                saldo >= 0
                                                    ? "metrocorp__balance-data"
                                                    : "metrocorp__balance-data metrocorp__red"
                                            }
                                            quantity={saldo}
                                            notBold
                                        />
                                    </div>
                                    <div className="metrocorp__average">
                                        <I18n
                                            id={`${FORM_ID}.holding.column.averagePrice`}
                                            componentProps={{ className: "metrocorp__average-info" }}
                                        />
                                        <FormattedAmount
                                            noCurrency
                                            className={
                                                valorPromedio >= 0
                                                    ? "metrocorp__average-data"
                                                    : "metrocorp__average-data metrocorp__red"
                                            }
                                            quantity={valorPromedio}
                                            notBold
                                        />
                                    </div>
                                </div>
                            }
                            rightContent={
                                <div className="metrocorp__card-right">
                                    <div className="metrocorp__totalamount">
                                        <I18n
                                            id={`${FORM_ID}.holding.column.balanceValuedInCurrency`}
                                            componentProps={{ className: "metrocorp__totalamount-info" }}
                                        />
                                        <FormattedAmount
                                            currency={currency}
                                            className={
                                                saldoValorizadoPesos >= 0
                                                    ? "metrocorp__totalamount-data"
                                                    : "metrocorp__totalamount-data metrocorp__red"
                                            }
                                            quantity={saldoValorizadoPesos}
                                            notBold
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        }
        return (
            <>
                <CardContainer className="metrocorp__card-container">
                    <Card
                        className="metrocorp__card mt-0"
                        title={
                            <div className="metrocorp__card-title">
                                <div className="metrocorp__specie">
                                    <I18n
                                        id={`${FORM_ID}.holding.column.species`}
                                        componentProps={{ className: "metrocorp__specie-info" }}
                                    />
                                    <span className="metrocorp__specie-data">{codEspe}</span>
                                </div>
                                <div className="metrocorp__description">
                                    <span className="metrocorp__description-data">{item?.descripcionEspecie}</span>
                                </div>
                                <hr className="mr-0" />
                            </div>
                        }
                        content={
                            <div className="metrocorp__card-content">
                                <div className="metrocorp__unit-value">
                                    <I18n
                                        id={`${FORM_ID}.holding.column.unitValue`}
                                        componentProps={{ className: "metrocorp__unit-value-info" }}
                                    />
                                    <FormattedAmount
                                        currency={currency}
                                        className={
                                            valorUnitario >= 0
                                                ? "metrocorp__unit-value-data"
                                                : "metrocorp__unit-value-data metrocorp__red"
                                        }
                                        quantity={valorUnitario}
                                        notBold
                                    />
                                </div>
                                <div className="metrocorp__balance">
                                    <I18n
                                        id={`${FORM_ID}.holding.column.balance`}
                                        componentProps={{ className: "metrocorp__balance-info" }}
                                    />
                                    <FormattedAmount
                                        noCurrency
                                        className={
                                            saldo >= 0
                                                ? "metrocorp__balance-data"
                                                : "metrocorp__balance-data metrocorp__red"
                                        }
                                        quantity={saldo}
                                        notBold
                                    />
                                </div>
                                <div className="metrocorp__average">
                                    <I18n
                                        id={`${FORM_ID}.holding.column.averagePrice`}
                                        componentProps={{ className: "metrocorp__average-info" }}
                                    />
                                    <FormattedAmount
                                        currency={currency}
                                        className={
                                            valorPromedio >= 0
                                                ? "metrocorp__average-data"
                                                : "metrocorp__average-data metrocorp__red"
                                        }
                                        quantity={valorPromedio}
                                        notBold
                                    />
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="metrocorp__card-right">
                                <div className="metrocorp__totalamount">
                                    <I18n
                                        id={`${FORM_ID}.holding.column.balanceValuedInCurrency`}
                                        componentProps={{ className: "metrocorp__totalamount-info" }}
                                    />
                                    <FormattedAmount
                                        currency={currency}
                                        className={
                                            saldoValorizadoPesos >= 0
                                                ? "metrocorp__totalamount-data"
                                                : "metrocorp__totalamount-data metrocorp__red"
                                        }
                                        quantity={saldoValorizadoPesos}
                                        notBold
                                    />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            </>
        );
    };

    render() {
        const { holdings, isDesktop, currentDate } = this.props;

        if (!holdings) {
            return null;
        }

        const cashList = holdings.filter((holding) => holding.tipoEspecie === " " || holding.tipoEspecie === "4");
        const fixedRentList = holdings
            .filter((holding) => holding.tipoEspecie === "1")
            .map((holding) => {
                const descripcionEspecie = `${holding.codEspe} - ${holding.descripcionEspecie}`;
                const newHolding = { ...holding, descripcionEspecie };
                return newHolding;
            });
        const variableRentList = holdings.filter((holding) => holding.tipoEspecie === "2");
        const others = holdings.filter((holding) => holding.tipoEspecie === "3" || holding.tipoEspecie === "5");

        const sumFunction = (accumulator, holding) => accumulator + holding.saldoValorizadoPesos;

        const accountTotal = holdings.reduce(sumFunction, 0);

        return (
            <>
                <Container rowClassName="metrocorp__total-acount">
                    <EquivalentTotalBalance
                        balance={accountTotal}
                        title="metrocorp.balance.accountTotal"
                        bold
                        smallAmount
                    />
                    {!isDesktop && (
                        <Col xs={12} className="d-flex font-light">
                            <I18n
                                id="metrocorp.list.toTheDate"
                                component="p"
                                componentProps={{
                                    className: "font-light my-0 mr-2",
                                }}
                            />
                            <FormattedDate date={currentDate} />
                        </Col>
                    )}
                </Container>
                {cashList.length > 0 || fixedRentList.length > 0 || variableRentList.length > 0 ? (
                    <>
                        {this.renderListForHolding(cashList, "cash")}
                        {fixedRentList.length > 0 && this.renderListForHolding(fixedRentList, "fixedRent")}
                        {variableRentList.length > 0 && this.renderListForHolding(variableRentList, "variableRent")}
                        {others.length > 0 && this.renderListForHolding(others, "others")}
                    </>
                ) : (
                    <>{this.renderNoResults()}</>
                )}
            </>
        );
    }
}

export default MetrocorpHoldingList;
