import React, { Component, Fragment } from "react";
import { withFormik, Form, Field } from "formik";
import { compose } from "redux";
import { connect } from "react-redux";
import Yup from "yup";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, func } from "prop-types";

import { actions as tokenActions, selectors as tokenSelectors } from "reducers/token";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18n from "util/i18n";
import * as config from "util/config";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import { push } from "react-router-redux/actions";
import TokenModal from "pages/settings/_components/token/TokenModal";

const FORM_ID = "settings.token.changePassword";

class ChangeTokenPass extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        isTokenBlocked: bool.isRequired,
    };

    renderTitleMobile = () => (
        <div>
            <h1 className="m-0">
                <I18n id="settings.token.changePassword.title" />
            </h1>
        </div>
    );

    PasswordHelp = (minLength, maxLength) => (
        <Fragment>
            <div className="password-rules" style={{ marginBottom: "2rem" }}>
                <ul>
                    <li>
                        <I18n id={`${FORM_ID}.passwordHelp1`} MIN_LENGTH={minLength} MAX_LENGTH={maxLength} />
                    </li>
                    <li>
                        <I18n id={`${FORM_ID}.passwordHelp2`} />
                    </li>
                    <li>
                        <I18n id={`${FORM_ID}.passwordHelp3`} />
                    </li>
                </ul>
            </div>
        </Fragment>
    );

    recoverTokenPass = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/token/recoverPass"));
    };

    renderButton = () => {
        const { isSubmitting, isDesktop } = this.props;

        return (
            <Container className="flex-grow align-items-center container-white" gridClassName="form-content">
                {isDesktop ? (
                    <Col className="px-0 col-12 col-md-12 col-lg-12 col-xl-12">
                        <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                            <Button
                                label="setting.token.changeTokenPass.forget.password"
                                onClick={() => {
                                    this.recoverTokenPass();
                                }}
                                className="btn btn-outline text-transform-none mt-2"
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                            <Button
                                label="global.confirm"
                                loading={isSubmitting}
                                type="submit"
                                bsStyle="primary"
                                image="images/arrowRight.svg"
                                imageStyle="mr-2"
                                className="mt-2"
                            />
                        </Col>
                    </Col>
                ) : (
                    <Col sm={12} md={4} lg={4} xl={4} className="px-0 col-12 col-md-7 col-lg-5 col-xl-4">
                        <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                            <Button
                                label="global.confirm"
                                loading={isSubmitting}
                                type="submit"
                                bsStyle="primary"
                                className="text-capitalize"
                                image="images/arrowRight.svg"
                                imageStyle="mr-2"
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                            <Button
                                label="setting.token.changeTokenPass.forget.password"
                                onClick={() => {
                                    this.recoverTokenPass();
                                }}
                                className="btn btn-outline text-transform-none"
                            />
                        </Col>
                    </Col>
                )}
            </Container>
        );
    };

    unlock = () => {
        const { dispatch } = this.props;

        dispatch(push("/settings/token/recoverPass"));
    };

    render() {
        const { isDesktop, isTokenBlocked } = this.props;
        const minLength = config.getInteger("token.password.minLength", 4);
        const maxLength = config.getInteger("token.password.maxLength", 6);

        return (
            <Fragment>
                <Notification scopeToShow="changeTokenPass" />
                <div className="admin-detail-head px-0">
                    <Head
                        backLinkTo="/settings/token"
                        headerClassName={!isDesktop && "blue-main-header-mobile"}
                        centerElement={!isDesktop && this.renderTitleMobile}
                        hideMobileMenu={!isDesktop}
                    />
                    {isDesktop && (
                        <h1>
                            <I18n id="settings.token.changePassword.title" />
                        </h1>
                    )}
                    <TokenModal isDisplayed={isTokenBlocked} handleDismiss={this.unlock} isDesktop={isDesktop} />
                </div>
                <MainContainer>
                    <Form
                        className={
                            isDesktop ? "confirmation__container" : "above-the-fold bg-white-mobile h-100-mobile"
                        }>
                        <Container
                            className={
                                isDesktop
                                    ? "align-items-center flex-grow px-0 container--layout with-margin mb-0"
                                    : "container--layout flex-grow align-items-center cmf-container-white"
                            }
                            gridClassName="confirmation__form confirmation__box">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <div className="admin-content-center">
                                        <I18n id="settings.token.changePassword" component="h2" />
                                    </div>
                                    <Col className="col-12 col-md-12 col-lg-12 col-xl-12 align-items-center">
                                        <Field
                                            type="otpMobile"
                                            name="password"
                                            idForm={FORM_ID}
                                            component={Credential}
                                            maxLength={maxLength}
                                        />
                                        <Field
                                            type="otpMobile"
                                            name="newPassword"
                                            idForm={FORM_ID}
                                            component={Credential}
                                            maxLength={maxLength}
                                        />
                                        <Field
                                            type="otpMobile"
                                            name="newPasswordConfirmation"
                                            idForm={FORM_ID}
                                            component={Credential}
                                            maxLength={maxLength}
                                        />
                                        {this.PasswordHelp(minLength, maxLength)}
                                        <div className="admin-content-center pb-3">
                                            <I18n
                                                id="confirmation.withoutotp.text"
                                                component="p"
                                                componentProps={{ className: "confirmation__text-without-otp" }}
                                            />
                                        </div>
                                        {this.renderButton()}
                                    </Col>
                                </Row>
                            </Grid>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetchingToken: tokenSelectors.getFetchingToken(state),
    loggedUser: sessionSelectors.getUser(state),
    isTokenBlocked: tokenSelectors.getIsTokenBlocked(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: true,
        mapPropsToValues: () => ({ password: "", newPassword: "", newPasswordConfirmation: "" }),
        validationSchema: () =>
            Yup.lazy((values) => {
                if (
                    values.password === undefined ||
                    values.newPassword === undefined ||
                    values.newPasswordConfirmation === undefined
                ) {
                    return Yup.object().shape({
                        password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
                        newPassword: Yup.string().required(i18n.get(`${FORM_ID}.newPassword.required`)),
                        newPasswordConfirmation: Yup.string().required(
                            i18n.get(`${FORM_ID}.newPasswordConfirmation.required`),
                        ),
                    });
                }

                return Yup.object().shape({
                    password: Yup.string()
                        .min(
                            config.getInteger("token.password.minLength", 4),
                            i18n.get(`settings.changePassword.modify.error.minLength`),
                        )
                        .max(
                            config.getInteger("token.password.maxLength", 6),
                            i18n.get(`settings.changePassword.modify.error.maxLength`),
                        ),
                    newPassword: Yup.string()
                        .min(
                            config.getInteger("token.password.minLength", 4),
                            i18n.get(`settings.changePassword.modify.error.minLength`),
                        )
                        .max(
                            config.getInteger("token.password.maxLength", 6),
                            i18n.get(`settings.changePassword.modify.error.maxLength`),
                        )
                        .notOneOf([values.password], i18n.get(`${FORM_ID}.modify.error.lastPasswordMustNotMatch`)),
                    newPasswordConfirmation: Yup.string()
                        .min(
                            config.getInteger("token.password.minLength", 4),
                            i18n.get(`settings.changePassword.modify.error.minLength`),
                        )
                        .max(
                            config.getInteger("token.password.maxLength", 6),
                            i18n.get(`settings.changePassword.modify.error.maxLength`),
                        )
                        .notOneOf([values.password], i18n.get(`${FORM_ID}.modify.error.lastPasswordMustNotMatch`))
                        .oneOf([values.newPassword], i18n.get(`${FORM_ID}.modify.error.passwordMustMatch`)),
                });
            }),

        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(
                tokenActions.changePassword(
                    values.password,
                    values.newPassword,
                    values.newPasswordConfirmation,
                    formikBag,
                ),
            );
        },
    }),
)(ChangeTokenPass);
