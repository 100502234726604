import React, { Component } from "react";
import { func, bool, string, shape, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";

import Col from "react-bootstrap/lib/Col";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Productselector from "pages/forms/_components/_fields/Productselector";
import PaymentAFIPDetailContent from "pages/servicePayments/_components/PaymentAFIPDetailContent";
import classNames from "classnames";
import * as i18n from "util/i18n";
import { push } from "react-router-redux";

const FORM_ID = "pay.paymentAFIP";

class PayPaymentAFIP extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        paymentAFIP: shape({}).isRequired,
        values: shape({
            debitAccount: string.isRequired,
        }).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        fetching: bool.isRequired,
        isReturnFromDetail: bool.isRequired,
        redirect: bool.isRequired,
    };

    componentDidMount() {
        const { redirect, dispatch } = this.props;
        if (redirect) {
            dispatch(push("/servicePayments"));
        }
    }

    handleGoBack = () => {
        const { dispatch, isReturnFromDetail } = this.props;
        dispatch(paymentsAFIPActions.setIsBackFromOtherPage(isReturnFromDetail));
    };

    centerContentMobile = () => {
        const { isDesktop, paymentAFIP } = this.props;

        const numberVEP = paymentAFIP ? Number(paymentAFIP.numberVEP) : undefined;

        if (!isDesktop) {
            return (
                <div className="title-account-header-multiline-background-blue">
                    <h1>{i18n.get("pay.paymentAFIP.title", null, { numberVEP })}</h1>
                </div>
            );
        }

        return (
            <div className="view-title d-flex">
                <h1>{i18n.get("pay.paymentAFIP.title", null, { numberVEP })}</h1>
            </div>
        );
    };

    render() {
        const { accounts, values, isDesktop, fetching, paymentAFIP } = this.props;

        const { debitAccount } = values;

        const { amount } = paymentAFIP;

        const accountsToShow = accounts.filter(
            (account) => amount.currency === account.currency && amount.quantity < account.balance,
        );

        return (
            <>
                <Notification scopeToShow="paymentAFIP" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleGoBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && <Head centerElement={this.centerContentMobile} hasInlineButtons />}
                </div>
                <PageLoading loading={fetching}>
                    <MainContainer>
                        <div className="above-the-fold">
                            <Form>
                                {paymentAFIP && (
                                    <PaymentAFIPDetailContent paymentAFIP={paymentAFIP} isDesktop={isDesktop} />
                                )}
                                <Container
                                    className={`container--layout align-items-center container-white ${isDesktop &&
                                        "mb-1 mt-2 with-padding"}`}
                                    gridClassName="form-content">
                                    <Col sm={12} md={10} lg={7} className="align-items-center">
                                        <Col sm={12} className={classNames({ "px-0": !isDesktop })}>
                                            <Field
                                                name="debitAccount"
                                                component={Productselector}
                                                idField="debitAccount"
                                                data={{
                                                    emptyOptionLabel: "",
                                                    options: accountsToShow,
                                                }}
                                                value={debitAccount}
                                                isRequired
                                                mode="edit"
                                                labelText={`${FORM_ID}.debitAccount`}
                                                idForm={FORM_ID}
                                                isDesktop={isDesktop}
                                                customPlaceholder={i18n.get("transfers.productSelector.placeholder")}
                                                style={{ marginTop: 0 }}
                                            />
                                        </Col>
                                    </Col>
                                </Container>
                                {isDesktop ? (
                                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                                        <Col sm={12} md={9} lg={4} className="mt-8">
                                            <Button
                                                type="submit"
                                                bsStyle="dark"
                                                label={`${FORM_ID}.button`}
                                                loading={fetching}
                                            />
                                        </Col>
                                    </Container>
                                ) : (
                                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                                        <Col sm={12} className="align-items-center">
                                            <Button
                                                type="submit"
                                                bsStyle="dark"
                                                label={`${FORM_ID}.button`}
                                                loading={fetching}
                                                className="mt-6"
                                            />
                                        </Col>
                                    </Container>
                                )}
                            </Form>
                        </div>
                    </MainContainer>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    accounts: paymentsAFIPSelectors.getAccounts(state),
    fetching: paymentsAFIPSelectors.getFetching(state),
    paymentAFIP: paymentsAFIPSelectors.getPaymentAFIP(state),
    isReturnFromDetail: paymentsAFIPSelectors.getIsReturnFromDetail(state),
    redirect: paymentsAFIPSelectors.getBackFromTicket(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: true,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            debitAccount: "",
        }),
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    debitAccount: Yup.string().required(i18n.get(`${FORM_ID}.debitAccount.field.error.required`)),
                }),
            ),
        handleSubmit: ({ debitAccount }, formikBag) => {
            const { dispatch, accounts, paymentAFIP } = formikBag.props;
            const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);

            dispatch(paymentsAFIPActions.payPaymentAFIPPreview(completeDebitAccount, paymentAFIP));
        },
    }),
)(PayPaymentAFIP);
