import * as API from "middleware/api";
import { RESCUE_TYPE } from "constants.js";

export const listFundcorp = (
    optionSelected,
    positionsType,
    positionDate,
    movementsShareAccount,
    movementsShareAccountDescription,
    movementsFund,
    movementsFundDescription,
    movementsDateFrom,
    movementsDateTo,
    requestsShareAccount,
    requestsShareAccountDescription,
    requestsDateFrom,
    requestsDateTo,
    shareValuesFund,
    shareValuesFundDescription,
    shareValuesDate,
    portfolioCompositionFund,
    portfolioCompositionFundDescription,
    portfolioCompositionDate,
    verifyCode,
) =>
    API.executeWithAccessToken("/fundcorp.list", {
        optionSelected,
        positionsType,
        positionDate,
        movementsShareAccount,
        movementsShareAccountDescription,
        movementsFund,
        movementsFundDescription,
        movementsDateFrom,
        movementsDateTo,
        requestsShareAccount,
        requestsShareAccountDescription,
        requestsDateFrom,
        requestsDateTo,
        shareValuesFund,
        shareValuesFundDescription,
        shareValuesDate,
        portfolioCompositionFund,
        portfolioCompositionFundDescription,
        portfolioCompositionDate,
        verifyCode,
    });

export const listFundcorpInf = (
    optionSelected,
    positionsType,
    positionDate,
    movementsShareAccount,
    movementsShareAccountDescription,
    movementsFund,
    movementsFundDescription,
    movementsDateFrom,
    movementsDateTo,
    requestsShareAccount,
    requestsShareAccountDescription,
    requestsDateFrom,
    requestsDateTo,
    shareValuesFund,
    shareValuesFundDescription,
    shareValuesDate,
    portfolioCompositionFund,
    portfolioCompositionFundDescription,
    portfolioCompositionDate,
) =>
    API.executeWithAccessToken("/fundcorp.list.inf", {
        optionSelected,
        positionsType,
        positionDate,
        movementsShareAccount,
        movementsShareAccountDescription,
        movementsFund,
        movementsFundDescription,
        movementsDateFrom,
        movementsDateTo,
        requestsShareAccount,
        requestsShareAccountDescription,
        requestsDateFrom,
        requestsDateTo,
        shareValuesFund,
        shareValuesFundDescription,
        shareValuesDate,
        portfolioCompositionFund,
        portfolioCompositionFundDescription,
        portfolioCompositionDate,
    });

export const listFundcorpPre = (page) => API.executeWithAccessToken("/fundcorp.list.pre", { page });

export const listFundcorpInfPre = (page) => API.executeWithAccessToken("/fundcorp.list.inf.pre", { page });
export const downloadList = (summary, format) =>
    API.executeWithAccessToken("/fundcorp.downloadList", { summary, format });

export const rescueRequestPre = () => API.executeWithAccessToken("/fundcorp.rescue.pre");

export const validateRescueRequest = (
    amount,
    cuotapartes,
    rescueType,
    maxAmount,
    maxCuotapartes,
    selectedShareAccount,
    selectedFund,
    equivalentAmount,
    equivalentCuotapartes,
    creditAccountLabel,
    rescueDate,
    selectedScheduler,
) =>
    API.executeWithAccessToken("/fundcorp.rescue.preview", {
        amount,
        cuotapartes,
        rescueType,
        maxAmount,
        maxCuotapartes,
        selectedShareAccount,
        selectedFund,
        equivalentAmount,
        equivalentCuotapartes,
        creditAccountLabel,
        rescueDate,
        scheduler: selectedScheduler,
    });

export const sendRescueRequest = (summary) => {
    const { BY_AMOUNT, TOTAL } = RESCUE_TYPE;

    const {
        rescueType,
        selectedFund: {
            cuotapartistaNumero,
            fondoNumero,
            tipoVCPAbreviatura: fundClass,
            monetaryAccount: { idCptCuentaBancaria, idProduct },
            ultimoVCPValor,
        },
        amount,
        idTransaction,
        selectedScheduler,
        equivalentCuotapartes,
    } = summary;

    const isRescueAmount = rescueType === BY_AMOUNT;
    const isTotal = rescueType === TOTAL;

    const { quantity, currency } = amount;
    const tempAmount = { quantity, currency: currency.toString() };

    return API.executeWithAccessToken("/fundcorp.register.rescue.request", {
        numCuotapartista: cuotapartistaNumero,
        idCptCuentaBancaria,
        equivalentCuotapartes,
        ultimoVCPValor,
        numFund: fondoNumero,
        fundClass,
        isRescueAmount,
        isTotal,
        rescueType,
        creditAccountId: idProduct,
        amount: { ...tempAmount },
        valueDate: new Date(),
        idTransaction,
        scheduler: selectedScheduler,
    });
};

export const validateSubscribeRequest = (
    fundName,
    selectedFund,
    amount,
    debitAccontLabel,
    debitAccountId,
    shareAccount,
    subscribeDate,
    selectedScheduler,
) =>
    API.executeWithAccessToken("/fundcorp.subscribe.preview", {
        fundName,
        selectedFund: selectedFund.numero,
        amount,
        debitAccontLabel,
        debitAccountId,
        shareAccount,
        subscribeDate,
        scheduler: selectedScheduler,
    });

export const sendSubscribeRequest = (summary) => {
    const {
        selectedFund: { numero },
        amount,
        fondoClase,
        debitAccountId,
        selectedScheduler,
        shareAccount,
    } = summary;
    return API.executeWithAccessToken("/fundcorp.register.suscription.request", {
        amount,
        numFund: numero,
        fondoClase,
        debitAccountId,
        shareAccount,
        valueDate: new Date(),
        scheduler: selectedScheduler,
    });
};

export const subscribePreRequest = () => API.executeWithAccessToken("/fundcorp.subscribe.pre");

export const createShareAccountPreRequest = () => API.executeWithAccessToken("/fundcorp.register.shareaccount.pre");

export const createShareAccountRequest = ({ accountArs, accountUsd }) =>
    API.executeWithAccessToken("/fundcorp.register.shareaccount", {
        _accountArs: accountArs,
        _accountUsd: accountUsd,
    });

export const insertBankAccountCptRequest = (debitAccount, shareAccount) =>
    API.executeWithAccessToken("/fundcorp.shareaccount.associate", {
        debitAccount,
        shareAccount,
    });

export const updateInvestorProfile = (investorProfile) =>
    API.executeWithAccessToken("/fundcorp.update.investorProfile.send", {
        investorProfile,
    });

export const cancelScheduledTransaction = (summary) => {
    const {
        rescueType,
        rescueDate,
        equivalentAmount,
        equivalentCuotapartes,
        creditAccountLabel,
        maxCuotapartes,

        fundName,
        selectedFund,
        amount,
        debitAccontLabel,
        debitAccountId,
        shareAccount,
        subscribeDate,
        selectedScheduler,
        type,
    } = summary;
    return API.executeWithAccessToken("/scheduled.fund.transactions.cancel", {
        rescueType,
        rescueDate,
        equivalentAmount,
        equivalentCuotapartes,
        creditAccountLabel,
        maxCuotapartes,

        fundName,
        selectedFund,
        amount,
        debitAccontLabel,
        debitAccountId,
        shareAccount,
        subscribeDate,
        selectedScheduler,
        idTransactionToCancel: summary.idTransaction,
        type,
    });
};
