import React, { Component } from "react";
import { string, number, bool } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import InfoTag from "pages/_components/InfoTag";

import { selectors } from "reducers/widgets";

import * as i18n from "util/i18n";
import { statusTagColor } from "util/checks";

const WIDGET_NAME = "echeqsReceived";

class EcheqRecivedWidgetItem extends Component {
    static propTypes = {
        cheque_numero: number.isRequired,
        monto: number.isRequired,
        fecha_pago: string.isRequired,
        emisor_razon_social: string.isRequired,
        emisor_moneda: string.isRequired,
        tenencia_beneficiario_documento: number.isRequired,
        estado: string.isRequired,
        cheque_id: string.isRequired,
        environmentCuit: number.isRequired,
        isDesktop: bool.isRequired,
        isEditable: bool.isRequired,
    };

    static defaultProps = {};

    render() {
        const {
            cheque_numero: echeqNumber,
            monto: amount,
            fecha_pago: paymentDate,
            emisor_razon_social: socialName,
            emisor_moneda: currency,
            estado: state,
            tenencia_beneficiario_documento: document,
            environmentCuit,
            isDesktop,
            cheque_id: id,
            isEditable
        } = this.props;
        const text = `${i18n.get("echeq.received.from")}: ${socialName}`;
        return (
            <Link to={!isEditable ? `/echeqs/received/detail/${id}` : "/desktop"} className={isDesktop ? "table-row px-4 py-3" : ""}>
                {isDesktop ? (
                    <div className="widget__echeqs">
                        <div>
                            <div className="d-flex font-medium">
                                <I18n
                                    id="echeq.paymentDate"
                                    componentProps={{ className: "widget__echeqs-payment-date-info f-size-6" }}
                                />
                                <FormattedDate date={paymentDate} className="mr-2 d-block f-size-6" />
                            </div>
                            <div className="d-flex">
                                <span className="align-items-center d-flex f-size-6">{`${i18n.get(
                                    "echeq.nro",
                                )} ${echeqNumber}`}</span>
                                {document === environmentCuit && (
                                    <Image
                                        src="images/ownershipEcheqs.svg"
                                        wrapperClassName="svg-wrapper w-auto mx-2"
                                    />
                                )}
                                <InfoTag
                                    type="info"
                                    message={text}
                                    tagBackground="#E1F5FC"
                                    capitalize
                                    moreStyles={{
                                        alignSelf: "flex-start",
                                        width: "fit-content",
                                        color: "black",
                                        fontFamily: "Graphik-Regular",
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <FormattedAmount
                                currency={i18n.get("currency.label.".concat(currency))}
                                quantity={amount}
                                small
                                notBold
                                fontClassName="justify-content-end d-flex f-size-5 font-medium"
                            />
                            <InfoTag
                                type="info"
                                message={state}
                                tagBackground={statusTagColor(state)}
                                moreStyles={{
                                    alignSelf: "flex-end",
                                    width: "fit-content",
                                    color: "black",
                                    fontFamily: "Graphik-Regular",
                                    lineHeigth: "1.125rem",
                                    padding: "0.125rem 0.75rem",
                                    margin: "auto",
                                    marginRight: 0,
                                    marginTop: "0.125rem",
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="pl-1 pr-3 pb-3 w-100">
                        <div className="f-size-5 font-medium d-flex p-2">
                            <span className="align-items-center d-flex f-size-6">{`${i18n.get(
                                "echeq.received.nro",
                            )}: ${echeqNumber}`}</span>
                            {document === environmentCuit && (
                                <Image src="images/ownershipEcheqs.svg" wrapperClassName="svg-wrapper w-auto mx-2" />
                            )}
                        </div>
                        <hr className="ml-2 my-0" />
                        <div className="echeqs__cards-content">
                            <div className="d-flex space-between w-100 pb-1 align-items-center">
                                <I18n id="echeqs.status.label" componentProps={{ className: "ml-2 echeq__info" }} />
                                <InfoTag
                                    type="info"
                                    message={state}
                                    tagBackground={statusTagColor(state)}
                                    tagClass="d-flex py-0 ml-2 my-0 mr-0"
                                    moreStyles={{
                                        color: "black",
                                    }}
                                />
                            </div>
                            <div className="d-flex space-between w-100">
                                <I18n id="echeqs.payedCheck.label" componentProps={{ className: "ml-2 echeq__info" }} />
                                <FormattedDate showTime={false} date={paymentDate} />
                            </div>
                            <div className="d-flex space-between w-100 pt-1">
                                <I18n id="echeq.received.from" componentProps={{ className: "ml-2 echeq__info" }} />
                                <span className="echeq__data">{socialName.replaceAll("#", "Ñ")}</span>
                            </div>
                            <div className="d-flex space-between w-100 pt-1">
                                <I18n id="echeq.amount" componentProps={{ className: "ml-2 echeq__info" }} />
                                <FormattedAmount
                                    currency={i18n.get("currency.label.".concat(currency))}
                                    quantity={amount}
                                    small
                                    notBold
                                    fontClassName="font-regular"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </Link>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        data: { environmentCuit },
    } = selectors.getWidget(state, WIDGET_NAME);

    return {
        environmentCuit,
    };
};

export default connect(mapStateToProps)(EcheqRecivedWidgetItem);
