import React, { Component } from "react";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";
import RestrictionsUserDeleteTicketData from "pages/administration/restrictions/tickets/RestrictionsUserDeleteTicketData";

class RestrictionsUserDeleteTicket extends Component {
    render() {
        return (
            <AdministrationTicket {...this.props}>
                <RestrictionsUserDeleteTicketData {...this.props} />
            </AdministrationTicket>
        );
    }
}

export default RestrictionsUserDeleteTicket;
