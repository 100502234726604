/*
 * API middleware to interact with the RESTful API (new composite Microservices)
 */

import axios from "axios";
import { version } from "../../package.json";
import cordovaAdapter from "./axiosCordovaAdapter";

let apiAxios;
if (window.isMobileApp() && window.API_URL.startsWith("https://")) {
    apiAxios = axios.create({
        adapter: cordovaAdapter,
    });
    apiAxios.defaults.headers["x-app-version"] = version;
} else {
    apiAxios = axios.create();
}
apiAxios.defaults.baseURL = window.API_URL_MS;
apiAxios.defaults.headers.Accept = "application/json, application/octet-stream";

const cachedEtags = new Map();

apiAxios.interceptors.request.use((request) => {
    if (cachedEtags.has(request.url)) {
        request.headers["If-None-Match"] = cachedEtags.get(request.url);
    }
    return request;
});

apiAxios.interceptors.response.use(
    (response) => {
        if (response.headers.etag) {
            cachedEtags.set(response.request.responseURL, response.headers.etag);
        }

        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status < 500) {
                return error.response;
            }
        }

        // se agrega el atributo para saber que es un error de saga
        // eslint-disable-next-line
        error.httpError = true;

        throw error;
    },
);

export const setAuthToken = (token) => {
    apiAxios.defaults.headers.common.Authorization = `bearer ${token}`;
};

export const getWithAccessToken = (endpoint) => apiAxios.get(endpoint);

export const postWithAccessToken = (endpoint, params) => apiAxios.post(endpoint, params);
