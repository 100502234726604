import * as API from "middleware/api";

export const getUserData = () => API.executeWithAccessToken("/preferences.userData.modify.pre");

export const getAccountsOfficerData = () => API.executeWithAccessToken("/preferences.userData.accountsOfficer.get");

export const sendMailCode = (mail, otp) =>
    API.executeWithAccessToken("/preferences.userData.mail.sendCode", {
        mail,
        _otp: otp,
    });

export const updateMail = ({ mailCode, newEmail }) =>
    API.executeWithAccessToken("/preferences.userData.mail.update", {
        idForm: "modifyUserData",
        mailCode,
        newEmail,
    });

export const sendMobilePhoneCode = (mobilePhone, otp) =>
    API.executeWithAccessToken("/preferences.userData.mobilePhone.sendCode", {
        mobilePhone,
        _otp: otp,
    });

export const updateMobilePhone = ({ mobilePhoneCode, newPhoneNumber }) =>
    API.executeWithAccessToken("/preferences.userData.mobilePhone.update", {
        idForm: "modifyUserData",
        mobilePhoneCode,
        newPhoneNumber,
    });

export const changeLanguage = (lang) => API.executeWithAccessToken("/preferences.lang.modify", { userLang: lang });

export const changeDefaultEnvironment = (idEnvironment) =>
    API.executeWithAccessToken("/preferences.environment.modify", { idEnvironmentToSetDefault: idEnvironment });

export const notificationsConfigurationPre = () =>
    API.executeWithAccessToken("/preferences.notifications.configuration.pre");

export const communicationTypePre = (communicationType) =>
    API.executeWithAccessToken("/preferences.notifications.configuration.modify.pre", {
        communicationType,
    });

export const modifyNotificationConfigurations = (notificationType, transport, subscribed) =>
    API.executeWithAccessToken("/preferences.notifications.configuration.modify", {
        notificationType,
        transport,
        subscribed,
    });

export const modifyNotificationConfigurationOptions = (communicationType, enabled, option) =>
    API.executeWithAccessToken("/preferences.notifications.configuration.options.modify", {
        communicationType,
        enabled,
        option,
    });

export const modifyPaperlessConfigurations = (idProduct, paperless) =>
    API.executeWithAccessToken("/core.product.setPaperlessValue", {
        idProduct,
        paperless,
    });

export const fetchProductsList = () => API.executeWithAccessToken("/preferences.products.list", {});

export const setAllPaperlessValue = (paperless) =>
    API.executeWithAccessToken("/core.product.setAllPaperlessValue", {
        paperless,
    });

export const changePassword = (password, newPassword, newPasswordConfirmation) =>
    API.executeWithAccessToken("/preferences.changepassword.send", {
        _password: password,
        _newPassword: newPassword,
        _newPasswordConfirmation: newPasswordConfirmation,
    });

export const changeUserpass = (userpass, newUserpass, newUserpassConfirmation) =>
    API.executeWithAccessToken("/preferences.changeuserpass.send", {
        _userpass: userpass,
        _newUserpass: newUserpass,
        _newUserpassConfirmation: newUserpassConfirmation,
    });

export const listSecuritySeals = () => API.executeWithAccessToken("/preferences.securityseals.list");

export const modifySecuritySeal = (securitySeal, otp) =>
    API.executeWithAccessToken("/preferences.securityseals.modify", {
        _securitySeal: securitySeal,
        _otp: otp,
    });

export const listFingerprintConfiguredUserDevices = (device) =>
    API.executeWithAccessToken("/preferences.fingerprint.pre", {
        device,
    });

export const saveFingerprint = (deviceId, deviceModel) =>
    API.executeWithAccessToken("/preferences.fingerprint.save", {
        deviceId,
        deviceModel,
    });

export const deleteFingerprint = (idSession) =>
    API.executeWithAccessToken("/preferences.fingerprint.delete", {
        idSession,
    });

export const pushNotificationsListUserDevices = () =>
    API.executeWithAccessToken("/communications.pushnotifications.listUserDevices");

export const pushNotificationsRegisterUserDevice = (idDevice, pushToken, extraInfo) =>
    API.executeWithAccessToken("/communications.pushnotifications.subscribe", {
        idDevice,
        pushToken,
        extraInfo,
    });

export const pushNotificationsDeleteUserDevice = (idDevice) =>
    API.executeWithAccessToken("/communications.pushnotifications.unsubscribe", {
        idDevice,
    });

export const getTermsAndConditions = () => API.executeAnonymous("/session.terms.conditions", {});

export const listAllUserDevices = () => API.executeWithAccessToken("/preferences.userData.listAllUserDevices");

export const listAllGeneralConditionDocuments = () =>
    API.executeAnonymous("/session.listAllGeneralConditionDocuments", {});

export const downloadDocument = (fileNameKey) =>
    API.executeAnonymous("/session.downloadGeneralConditions", { fileNameKey });

export const getAddressesInformation = () =>
    API.executeWithAccessToken("/preferences.userData.getUserAddressInformation");

export const setAddressesInformation = (address, mailingAddress, otp) =>
    API.executeWithAccessToken("/preferences.userData.setUserAddressInformation", {
        address,
        mailingAddress,
        _otp: otp,
    });

export const getAllEnvironments = () => API.executeWithAccessToken("/environment.list");
