import React from "react";
import { arrayOf, bool, func, number, shape } from "prop-types";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import InfoTag from "pages/_components/InfoTag";
import Pagination from "pages/_components/pagination/Pagination";
import Heading from "pages/_components/Heading";
import Spinner from "pages/_components/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";

import { selectors as multipleTransfersSelector } from "reducers/multiple-transfers";

import * as i18n from "util/i18n";
import * as multipleTransfersUtils from "util/multiple-transfers";
import { getAccountDescription } from "util/accounts";
import MultipleTransferContextMenu from "./components/MultipleTransferContextMenu";

function MultipleTransferList({
    dispatch,
    multipleTransfersList,
    getListTrasfersMultiple,
    pageNumber,
    totalPages,
    isDesktop,
}) {
    const statusTagClass = "text-12 d-flex align-self-flex-end widht max-width-mincontent no-wrap py-0 px-3 mx-0";

    const endOfListItem = (
        <div className="table-row min-height-0 justify-content-center" key="noMoreTransactions">
            <I18n id="transfers.multiple.finish.list" componentProps={{ className: "my-2" }} />
        </div>
    );

    if (!multipleTransfersList?.length) {
        return (
            <div className="text-center no-more-data my-5" key="noMoreMovements">
                <div className="illustration-wrapper">
                    <Image src="images/coloredIcons/find-in-page.svg" className="svg-big-icon" />
                </div>
                <p className="text-lead" style={{ opacity: 1 }}>
                    <I18n id="transactions.list.none" />
                </p>
            </div>
        );
    }

    return (
        <>
            {isDesktop ? (
                <div className="transfer-multiple__list mt-4 p-3">
                    {multipleTransfersList.map((batch) => (
                        <CardContainer
                            key={batch.transactionId}
                            renderAs={Link}
                            to={{
                                pathname: `/transfer/multiple/detail/${batch.transactionId}`, 
                                state: {
                                    statusDetail: batch?.batchStatus,
                                }
                            }}>
                            <Card
                                className="card__transaction px-2"
                                isDesktop={isDesktop}
                                content={
                                    <div className="card__transaction-data-description">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex mt-2">
                                                <I18n
                                                    id="multiple.transfers.dates.card"
                                                    componentProps={{ className: "transfer-multiple__card-head" }}
                                                    DATE_UPLOAD={moment(batch.creationDate).format("DD/MM/yyyy")}
                                                    DATE_MADE={moment(batch.processDate).format("DD/MM/yyyy")}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <I18n
                                                    id="multiple.transfers.id.card"
                                                    componentProps={{ className: "transfer-multiple__card-data" }}
                                                />
                                                <I18n
                                                    defaultValue={batch.idBatch}
                                                    componentProps={{ className: "transfer-multiple__card-data mx-2" }}
                                                />
                                                <InfoTag
                                                    type="info"
                                                    message={`${i18n.get("multiple.transfers.quantity.card")} ${
                                                        batch.quantity
                                                    }`}
                                                    tagBackground="#E1F5FC"
                                                    capitalize
                                                    moreStyles={{
                                                        alignSelf: "flex-start",
                                                        color: "black",
                                                        fontFamily: "Graphik-Regular",
                                                    }}
                                                />
                                                {batch.reference ? (
                                                    <InfoTag
                                                        type="info"
                                                        message={`${i18n.get(
                                                            "multiple.transfers.modal.reference.label",
                                                        )} ${batch.reference}`}
                                                        tagBackground="#E8EAF6"
                                                        capitalize
                                                        moreStyles={{
                                                            alignSelf: "flex-start",
                                                            color: "black",
                                                            fontFamily: "Graphik-Regular",
                                                        }}
                                                    />
                                                ) : (
                                                    undefined
                                                )}
                                            </div>
                                            <div className="d-flex">
                                                <I18n
                                                    id="multiple.transfers.account.card"
                                                    componentProps={{ className: "transfer-multiple__card-data-bold" }}
                                                />
                                                <I18n
                                                    defaultValue={getAccountDescription(batch?.account)}
                                                    componentProps={{
                                                        className: "transfer-multiple__card-data-bold ml-1",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                rightContent={
                                    <div className="d-flex align-items-center">
                                        <div className="card__component-item-currency  justify-content-end d-flex flex-column">
                                            <I18n
                                                id="multiple.transfers.amount.card"
                                                componentProps={{ className: "transfer-multiple__card-amount-label" }}
                                            />
                                            {batch?.amount ? (
                                                <FormattedAmount
                                                    {...batch?.amount}
                                                    className="data-amount d-flex justify-content-end"
                                                />
                                            ) : (
                                                undefined
                                            )}
                                            {isDesktop ? (
                                                <InfoTag
                                                    type="info"
                                                    message={i18n.get(
                                                        `multiple.transfers.status.${batch.batchStatus.toLowerCase()}`,
                                                    )}
                                                    tagBackground={multipleTransfersUtils.statusTagColorBatch(
                                                        batch.batchStatus,
                                                    )}
                                                    tagClass="d-flex align-self-flex-end widht max-width-mincontent no-wrap py-1 px-2 mx-0"
                                                    moreStyles={{
                                                        color: "black",
                                                        fontFamily: "Graphik-Regular",
                                                    }}
                                                />
                                            ) : (
                                                undefined
                                            )}
                                        </div>
                                        <MultipleTransferContextMenu
                                            dispatch={dispatch}
                                            isDesktop={isDesktop}
                                            batch={batch}
                                            idTransactionStatus={batch.batchStatus}
                                            isBatchListLayout
                                        />
                                    </div>
                                }
                            />
                        </CardContainer>
                    ))}
                    <div className="d-flex w-100 justify-content-end mr-3 mb-5">
                        <Pagination totalPages={totalPages} pageNumber={pageNumber} action={getListTrasfersMultiple} />
                    </div>
                </div>
            ) : (
                <div className="transfer-multiple__list p-3">
                    <InfiniteScroll
                        dataLength={multipleTransfersList.length}
                        next={() => getListTrasfersMultiple(pageNumber + 1, isDesktop)}
                        hasMore={totalPages !== pageNumber}
                        loader={<Spinner />}
                        endMessage={endOfListItem}>
                        {multipleTransfersList.map((batch) => (
                            <CardContainer
                                key={batch.transactionId}
                                renderAs={Link}
                                to={{
                                    pathname: `/transfer/multiple/detail/${batch.transactionId}`, 
                                    state: {
                                        statusDetail: batch?.batchStatus,
                                    }
                                }}>
                                <Card
                                    key={batch.transactionId}
                                    className="card__transaction"
                                    isDesktop={isDesktop}
                                    content={
                                        <>
                                            <div className="d-flex space-between align-items-center">
                                                <div className="d-flex">
                                                    <I18n
                                                        id="multiple.transfers.id.card"
                                                        componentProps={{
                                                            className:
                                                                "transfer-multiple__card-data data-label-medium text-bold",
                                                        }}
                                                    />
                                                    <I18n
                                                        defaultValue={batch.idBatch}
                                                        componentProps={{
                                                            className: "transfer-multiple__card-data ml-1",
                                                        }}
                                                    />
                                                </div>
                                                <MultipleTransferContextMenu
                                                    dispatch={dispatch}
                                                    isDesktop={isDesktop}
                                                    batch={batch}
                                                    idTransactionStatus={batch.batchStatus}
                                                    isBatchListLayout
                                                />
                                            </div>
                                            <hr />
                                            <Heading.DataGroup
                                                containerClassName="data-wrapper-flex space-between"
                                                labelClassName="data-label-medium text-12 text-bold"
                                                dataClassName="data-desc"
                                                label="multiple.transfers.status.label"
                                                data={
                                                    <InfoTag
                                                        type="info"
                                                        message={i18n.get(
                                                            `multiple.transfers.status.${batch?.batchStatus.toLowerCase()}`,
                                                        )}
                                                        tagBackground={multipleTransfersUtils.statusTagColorBatch(
                                                            batch?.batchStatus,
                                                        )}
                                                        tagClass={statusTagClass}
                                                        moreStyles={{
                                                            color: "black",
                                                            fontFamily: "Graphik-Regular",
                                                        }}
                                                    />
                                                }
                                            />
                                            <Heading.DataGroup
                                                containerClassName="data-wrapper-flex space-between my-15"
                                                labelClassName="data-label-medium text-12 text-bold"
                                                dataClassName="data-desc text-12"
                                                label="multiple.transfers.upload.date"
                                                data={moment(batch.creationDate).format("DD/MM/yyyy")}
                                            />
                                            <Heading.DataGroup
                                                containerClassName="data-wrapper-flex space-between my-15"
                                                labelClassName="data-label-medium text-12 text-bold"
                                                dataClassName="data-desc text-12"
                                                label="multiple.transfers.date.label.mobile"
                                                data={moment(batch.processDate).format("DD/MM/yyyy")}
                                            />
                                            <Heading.DataGroup
                                                containerClassName="data-wrapper-flex space-between my-15"
                                                labelClassName="data-label-medium text-12 text-bold"
                                                dataClassName="data-desc text-12"
                                                label="multiple.transfers.summary.transferReference.label"
                                                data={batch.reference}
                                            />
                                            <Heading.DataGroup
                                                containerClassName="data-wrapper-flex space-between my-15"
                                                labelClassName="data-label-medium text-12 text-bold"
                                                dataClassName="data-desc text-12"
                                                label="multiple.transfers.summary.quantity.label"
                                                data={batch.quantity}
                                            />
                                            <Heading.DataGroup
                                                containerClassName="data-wrapper-flex space-between my-15"
                                                labelClassName="data-label-medium text-12 text-bold"
                                                dataClassName="data-desc"
                                                label="multiple.transfers.summary.account.label"
                                                data={
                                                    <div className="d-flex">
                                                        <I18n
                                                            defaultValue={getAccountDescription(batch?.account)}
                                                            componentProps={{
                                                                className: "transfer-multiple__card-data-bold ml-1",
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            />
                                        </>
                                    }
                                    rightContent={
                                        <div className="card__component-item-currency">
                                            <I18n
                                                id="multiple.transfers.amount.card"
                                                componentProps={{ className: "transfer-multiple__card-amount-label" }}
                                            />
                                            {batch?.totalAmount ? (
                                                <FormattedAmount
                                                    {...batch?.amount}
                                                    className="data-amount d-flex justify-content-end"
                                                />
                                            ) : (
                                                undefined
                                            )}
                                            {isDesktop ? (
                                                <InfoTag
                                                    type="info"
                                                    message={i18n.get(
                                                        `multiple.transfers.status.${batch?.batchStatus.toLowerCase()}`,
                                                    )}
                                                    tagBackground={multipleTransfersUtils.statusTagColorBatch(
                                                        batch?.batchStatus,
                                                    )}
                                                    tagClass="d-flex align-self-flex-end widht max-width-mincontent no-wrap py-1 px-2 mx-0"
                                                    moreStyles={{
                                                        color: "black",
                                                    }}
                                                />
                                            ) : (
                                                undefined
                                            )}
                                        </div>
                                    }
                                />
                            </CardContainer>
                        ))}
                    </InfiniteScroll>
                </div>
            )}
        </>
    );
}

MultipleTransferList.propTypes = {
    dispatch: func.isRequired,
    multipleTransfersList: arrayOf(shape({})).isRequired,
    getListTrasfersMultiple: func.isRequired,
    pageNumber: number.isRequired,
    totalPages: number.isRequired,
    isDesktop: bool.isRequired,
};

const mapStateToProps = (state) => ({
    multipleTransfersList: multipleTransfersSelector.getMultipleTransfersList(state),
    pageNumber: multipleTransfersSelector.getPageNumber(state),
    totalPages: multipleTransfersSelector.getTotalPages(state),
});

export default connect(mapStateToProps)(MultipleTransferList);
