import { differenceInDays, format, isToday, isYesterday } from "date-fns";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en";
import pt from "date-fns/locale/pt";
import capitalize from "lodash/capitalize";

import { get as getText, getLang } from "util/i18n";
import { get as getConfig } from "util/config";

const locale = { es, en, pt };

export const date = (dateToFormat, formatDate = null, lang = null) => {
    const currentLang = getLang();

    const shortDateFormat = getConfig("frontend.shortDateFormat");
    return format(dateToFormat, formatDate || shortDateFormat, { locale: locale[lang || currentLang] });
};

export const naturalDate = (dateToFormat) => {
    const wordBeforeTime = getText("settings.lastLogin.beforeTime");

    if (isToday(dateToFormat)) {
        const wordToday = getText("scheduler.executionToday");

        return capitalize(`${wordToday} ${wordBeforeTime} ${date(dateToFormat, "HH:mm")}`);
    }
    if (isYesterday(dateToFormat)) {
        const wordYesterday = getText("scheduler.executionYesterday");

        return capitalize(`${wordYesterday} ${wordBeforeTime} ${date(dateToFormat, "HH:mm")}`);
    }
    if (differenceInDays(new Date(), dateToFormat) < 7) {
        return capitalize(date(dateToFormat, `dddd [${wordBeforeTime}] HH:mm`));
    }

    return capitalize(date(dateToFormat, getText("datepicker.format")));
};

export const documentTypeFormats = (documentType, document) => {
    const isnum = /^\d+$/.test(document);
    if (documentType === "DNI") {
        if (document.length < 6 || document.length > 8 || !isnum) {
            return false;
        }
    }

    if (documentType === "CUIT" || documentType === "CUIL" || documentType === "CDI") {
        if (document.length !== 11 || !isnum) {
            return false;
        }
    }
    return true;
};

export const idTransactionFormat = (idTransaction) => idTransaction.substring(0, 8).toUpperCase();

export default {};
