import { call, put, takeLatest } from "redux-saga/effects";

import { types, actions } from "reducers/formFields/multilineFileProcess";
import * as multilineFileProcess from "middleware/formFields/multilineProcess";

const sagas = [takeLatest([types.UPDATE_PROCESS_REQUEST], updateProcessRequest)];

export default sagas;

function* updateProcessRequest({ data }) {
    const response = yield call(multilineFileProcess.updateProcessRequest, data);

    if (response.type === "W") {
        yield put(actions.updateProcessFailure());
    } else {
        yield put(actions.updateProcessSuccess(response.data.data));
    }
}
