import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { func, bool, shape } from "prop-types";

import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";
import * as i18n from "util/i18n";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import TextField from "pages/_components/fields/TextField";
import { Mixpanel } from "util/clickstreaming";

const FORM_ID = "recoveryPassword.step2";

class Step2Content extends Component {
    handleResendEmail = () => {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.backToStep1());
    };

    handleResendEmailForUserpass = () => {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.backToUserpassStep1());
    };

    handleResetCodeChange = (value) => {
        if (value.length > 0 && value.length <= 14) {
            const separator = "-";
            const { values, setValues } = this.props;
            const oldValue = value.slice(0, -1);
            let editedValue = value[value.length - 1];
            editedValue = editedValue.toUpperCase();
            if (editedValue !== separator && value.length % 5 === 0) {
                editedValue = `${separator}${editedValue}`;
            }

            setValues({
                ...values,
                resetCode: `${oldValue}${editedValue}`,
            });
        }
    };

    render() {
        const { isSubmitting, fetching, isDesktop, isRecoveringUserpass, isRecoveryPassAndUserpass } = this.props;

        return (
            <Form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                <Container className="container--layout flex-grow align-items-center">
                    <Col sm={12} md={12} lg={12} className={isDesktop ? "col col-12 px-0" : "col col-12"}>
                        <p className="text-lead">
                            {!isRecoveringUserpass && <I18n id="recoveryPassword.step2.mailSent.message" />}
                            {isRecoveringUserpass && !isRecoveryPassAndUserpass && (
                                <I18n id="recoveryUserpass.step2.mailSent.message" />
                            )}
                            {isRecoveringUserpass && isRecoveryPassAndUserpass && (
                                <I18n id="recoveryPassAndUserpass.step2.mailSent.message" />
                            )}
                        </p>
                    </Col>
                </Container>
                <Container
                    className="flex-grow align-items-center container--layout px-0"
                    gridClassName={isDesktop ? "container px-0" : "container"}>
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <div className="text-transform-none">
                            <Field
                                name="resetCode"
                                idForm={FORM_ID}
                                autoComplete="off"
                                maxLength={14}
                                component={TextField}
                                onCustomChange={this.handleResetCodeChange}
                                pattern="[a-zA-Z0-9\-]*"
                            />
                        </div>
                    </Col>
                </Container>
                <Container
                    className="align-items-center container--layout"
                    gridClassName={isDesktop ? "container px-0" : "container"}>
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Button type="submit" loading={isSubmitting} label="global.continue" bsStyle="primary" />
                    </Col>
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Button
                            loading={fetching}
                            label="recoveryPassword.step2.resendMail.link"
                            className="btn-outline"
                            onClick={isRecoveringUserpass ? this.handleResendEmailForUserpass : this.handleResendEmail}
                        />
                    </Col>
                </Container>
            </Form>
        );
    }
}

Step2Content.propTypes = {
    isSubmitting: bool,
    dispatch: func.isRequired,
    fetching: bool,
    isDesktop: bool,
    isRecoveringUserpass: bool,
    isRecoveryPassAndUserpass: bool,
    setValues: func.isRequired,
    values: shape({}).isRequired,
};

Step2Content.defaultProps = {
    isSubmitting: false,
    fetching: false,
    isDesktop: true,
    isRecoveringUserpass: false,
    isRecoveryPassAndUserpass: false,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({ resetCode: "" }),
    validationSchema: () =>
        Yup.object().shape({
            resetCode: Yup.string().required(i18n.get(`${FORM_ID}.resetCode.required`)),
        }),
    handleSubmit: (values, formikBag) => {
        Mixpanel.track(FORM_ID);
        const { isRecoveringUserpass, isRecoveryPassAndUserpass, exchangeToken } = formikBag.props;

        if (isRecoveringUserpass && !isRecoveryPassAndUserpass) {
            formikBag.props.dispatch(recoveryPasswordActions.recoveryUserpassStep2(values.resetCode, formikBag));
        } else if (isRecoveringUserpass && isRecoveryPassAndUserpass) {
            formikBag.props.dispatch(recoveryPasswordActions.recoveryPassAndUserpassStep1(values.resetCode, formikBag));
        } else {
            formikBag.props.dispatch(
                recoveryPasswordActions.recoveryPassStep2(values.resetCode, formikBag, exchangeToken),
            );
        }
    },
})(Step2Content);
