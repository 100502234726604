import React, { Component } from "react";
import { string, node } from "prop-types";

import * as i18nUtils from "util/i18n";

class DetailBoxData extends Component {
    static propTypes = {
        label: string.isRequired,
        children: node.isRequired,
    };

    render() {
        const { label, children } = this.props;

        return (
            <div className="detailBox-row">
                <div className="detailBox-data">
                    <div>{i18nUtils.get(label)}</div>
                </div>
                <div className="text-right">
                    <div>{children}</div>
                </div>
            </div>
        );
    }
}

export default DetailBoxData;
