import React, { Component } from "react";
import { func, shape, bool } from "prop-types";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";

import TransactionTicket from "pages/_components/TransactionTicket";

import { selectors as echeqSelector, actions as echeqActions } from "reducers/checks";
import { actions as formActions } from "reducers/form";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as transactionsSelectors, actions as transactionsActions } from "reducers/transactions";
import { selectors as frequentDestinationSelectors } from "reducers/frequentDestination";
import * as i18n from "util/i18n";
import { exportGenericTicketPdf, getGeneralTicketItem, normalizeTransactionPath } from "util/transaction";
import { getAccountDescription } from "util/accounts";
import EmitMassiveSummaryData from "./EmitMassiveSummaryData";

const FORM_ID = "echeq.massive.emit";
class EmitMassiveTicket extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        summary: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { summary, dispatch } = this.props;
        if (!summary?.idTransaction) {
            dispatch(push("/transactions/list"));
        } else {
            dispatch(echeqActions.setRememberMassiveData(false));
            dispatch(transactionsActions.getPotentialSignaturesData(summary.idTransaction));
        }
    }

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 className="w-100 m-0 px-2">{i18n.get("echeq.massive.emit")}</h1>
        </div>
    );

    handleDownload = async () => {
        this.setState({ fetchingDownload: true });
        const ticketData = {
            ticketName: i18n.get("echeq.massive.emit"),
            ticketBody: [...this.generateTicketBody()],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    generateTicketBody = () => {
        const { summary } = this.props;

        return [
            getGeneralTicketItem(`forms.transaction.ticket.date`, summary?.submitDateTime, "datefulltime"),
            getGeneralTicketItem(`${FORM_ID}.summary.idBatch.label`, summary?.batchId, "string"),
            getGeneralTicketItem(
                `${FORM_ID}.summary.account.label`,
                summary?.account?.number !== undefined && getAccountDescription(summary?.account),
                "string",
            ),
            getGeneralTicketItem(`${FORM_ID}.summary.batchReference.label`, summary?.reference, "string"),
            getGeneralTicketItem(`${FORM_ID}.summary.processDate.label`, summary?.processDate, "datefulltime"),
            getGeneralTicketItem(`${FORM_ID}.summary.fileName.label`, summary?.file?.fileName, "string"),
            getGeneralTicketItem(`${FORM_ID}.summary.quantity.label`, summary?.echeqsQuantity, "string"),
            getGeneralTicketItem(`${FORM_ID}.summary.totalAmount.label`, summary?.amountToShow.quantity, "amountARS"),
        ];
    };

    buttonsDetail = () => {
        const { dispatch } = this.props;
        const buttons = [
            {
                label: `${FORM_ID}.ticket.goTo.echeqMassive`,
                bsStyle: "primary",
                onClick: () => {
                    dispatch(echeqActions.setEcheqOption("emitted.massive.echeq"));
                    dispatch(push("/echeqs"));
                },
            },
        ];

        return buttons;
    };

    signTransaction = () => {
        const { dispatch, summary, isDesktop } = this.props;
        const { idActivity } = summary;

        const signTransactionData = (
            <EmitMassiveSummaryData isDesktop={isDesktop} summary={summary} FORM_ID={FORM_ID} spaceBetween signature />
        );

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: summary?.idTransaction,
                idActivity,
                idForm: "",
                content: signTransactionData,
                title: "echeq.massive.emit",
                pendingDispatch: summary?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity,
                idTransaction: summary.idTransaction,
                idForm: "",
                ticketData: summary,
            }),
        );
        dispatch(echeqActions.checksRequestData({ ...summary, action: "sign" }));
    };

    render() {
        const { isDesktop, summary, fetching } = this.props;
        const { showDrawerMobile, fetchingDownload } = this.state;

        const isFinished = summary.idTransactionStatus === "FINISHED";

        const handleBack = () => {
            const { dispatch } = this.props;
            dispatch(echeqActions.cleanEcheqAction());
            normalizeTransactionPath(this.props);
            dispatch(goBack());
        };

        return (
            <TransactionTicket
                notificationScope="echeqs"
                handleBack={handleBack}
                handleClick={this.handleDownload}
                handleClickMessage="global.download"
                headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                centerElement={isDesktop ? undefined : this.centerContentMobile}
                uniqueDownload={isFinished}
                hasInlineButtons
                hideMobileMenu={!isDesktop}
                summary={summary}
                buttonsDetail={this.buttonsDetail()}
                isDesktop={isDesktop}
                fetching={fetching}
                onSignTicket={this.signTransaction}
                summaryTitle="echeq.massive.emit"
                closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                showDrawerMobile={showDrawerMobile}
                isFetchingExport={fetchingDownload}
                ignoreHomeButton
                downloadImageWhite={!isDesktop}>
                <EmitMassiveSummaryData isDesktop={isDesktop} summary={summary} FORM_ID={FORM_ID} spaceBetween />
            </TransactionTicket>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: echeqSelector.getTransactionEcheqData(state),
    fetching: echeqSelector.getFetching(state),
    potentialSignaturesData: transactionsSelectors.getPotentialSignaturesData(state),
    loggedUser: sessionSelectors.getUser(state),
    frequentDestination: frequentDestinationSelectors.getFrequentDestination(state),
    idEnvironment: frequentDestinationSelectors.getEnviromentId(state),
});

export default connect(mapStateToProps)(EmitMassiveTicket);
