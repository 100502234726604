import React, { Component } from "react";
import { shape, instanceOf, string, bool } from "prop-types";

import moment from "moment";

import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import { capitalizeFirstLetter } from "util/string";

const Mustache = require("mustache");

class Message extends Component {
    static propTypes = {
        value: shape({}).isRequired,
        FORM_ID: string,
        showExecuteToday: bool,
    };

    static defaultProps = {
        FORM_ID: "scheduler",
        showExecuteToday: true,
    };

    render() {
        const { value, FORM_ID, showExecuteToday } = this.props;

        let message = "";

        let data = {};
        if (value) {
            if (value.program) {
                const { program } = value;

                if (program.lapse === "UNTIL") {
                    // until date
                    data.until = moment(program.date).format("ll");
                } else if (program.lapse === "UP_TO") {
                    // Times quntity
                    if (program.number <= 1) {
                        data.upToOne = program.number;
                    } else {
                        data.upTo = program.number;
                    }
                }

                const date = moment(program.startsOn);
                const dateWithoutYear = date.format("ll").split(".")[0];
                data.day = date.format("dddd");
                data.date = date.format("ll");
                data.dateWithoutYear = dateWithoutYear;
                if (program.days) {
                    data.day = dateUtils.getDaysNamesWithBitwiseNumber(program.days).join(", ");
                }
                if (program.frequencyValue && Number(program.frequencyValue) > 1) {
                    data.frequencyValue = program.frequencyValue;
                }
                if (program.occurrence) {
                    data = {
                        ...data,
                        [schedulerUtils.getOccurrenceWithWeekOfMonth(program.occurrence)]: true,
                    };
                } else {
                    data.numberDay = Number(date.format("DD"));
                }
                if (typeof program.frequency === "string") {
                    message = Mustache.render(
                        i18n.get(`${FORM_ID}.execute${capitalizeFirstLetter(program.frequency.toLowerCase())}`),
                        data,
                    );
                }
            } else {
                let valueDate = instanceOf(moment);
                if (value.valueDate || value.newValueDate) {
                    valueDate = value.valueDate || value.newValueDate;
                } else if (value.data) {
                    valueDate = value.data.scheduler.valueDate || value.data.scheduler.newValueDate;
                } else {
                    valueDate = "";
                }
                const date = valueDate !== "" ? moment(valueDate) : moment();

                if (date.isSame(moment(), "day")) {
                    message = showExecuteToday ? i18n.get(`${FORM_ID}.executeToday`) : "";
                } else {
                    data.day = date.format(i18n.get("datepicker.format.transfers.scheduled"));
                    message = Mustache.render(i18n.get(`${FORM_ID}.executeFuture`), data);
                }
            }
        } else {
            return null;
        }
        return (
            <>
                {message}
                <span className="visually-hidden" id="readableTextID">
                    {message}
                </span>
            </>
        );
    }
}

export default Message;
