import React, { Component } from "react";
import { string } from "prop-types";
import Image from "pages/_components/Image";

class ItemName extends Component {
    static propTypes = {
        name: string.isRequired,
        icon: string,
        className: string,
    };

    static defaultProps = {
        icon: null,
        className: "",
    };

    render() {
        const { name, icon, className } = this.props;

        return (
            <p className={`data-name ${className}`}>
                {icon && <Image src={`images/${icon}.svg`} className="svg-icon" />}
                <span>{name}</span>
            </p>
        );
    }
}

export default ItemName;
