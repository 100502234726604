import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_REQUEST: "widgets/LIST_REQUEST",
    LIST_FAILURE: "widgets/LIST_FAILURE",
    LIST_SUCCESS: "widgets/LIST_SUCCESS",
    DELETE_WIDGET: "widgets/DELETE_WIDGET",
};

export const INITIAL_STATE = {
    accounts: {
        isFetching: false,
        errorMsg: "",
        data: {
            accounts: [],
        },
    },
    exchangeRates: {
        isFetching: false,
        data: { rates: [] },
    },
    portfolio: {
        isFetching: false,
        data: undefined,
    },
    notifications: {
        isFetching: false,
        data: undefined,
    },
    pendingTransactions: {
        isFetching: false,
        errorMsg: "",
        data: {
            transactionCounts: 0,
            pendingTransactions: [],
        },
    },
    echeqsReceived: {
        isFetching: false,
        errorMsg: "",
        data: {
            echeqsReceived: [],
            totalPages: 1,
            environmentCuit: "",
        },
    },
    metrocorp: {
        isFetching: false,
        errorMsg: "",
        data: {
            client: {
                cuentas: {
                    ctaDep: [],
                },
            },
            metrocorp: [],
        },
    },
    financing: {
        isFetching: false,
        errorMsg: "",
        data: {
            financing: [],
        },
    },
    scheduledTransactions: {
        isFetching: false,
        errorMsg: "",
        data: {
            scheduledTransactions: [],
        },
    },
    deposits: {
        isFetching: false,
        errorMsg: "",
        data: {
            deposits: [],
        },
    },
    funds: {
        isFetching: false,
        errorMsg: "",
        data: {
            funds: [],
        },
    },
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_REQUEST]: (state, action) => ({
        ...state,
        [action.widget]: {
            ...state[action.widget],
            isFetching: true,
        },
    }),
    [types.LIST_FAILURE]: (state, action) => ({
        ...state,
        [action.widget]: {
            ...state[action.widget],
            errorMsg: action.errorMsg,
            isFetching: false,
        },
    }),
    [types.LIST_SUCCESS]: (state, action) => ({
        ...state,
        [action.widget]: {
            data: action.data,
            errorMsg: "",
            isFetching: false,
        },
    }),
    [types.DELETE_WIDGET]: (state, action) => ({
        ...state,
        [action.widget.id]: {
            ...INITIAL_STATE[action.widget.id],
        },
    }),
});

export const actions = {
    listRequest: makeActionCreator(types.LIST_REQUEST, "widget", "userId"),
    listFailure: makeActionCreator(types.LIST_FAILURE, "widget", "errorMsg"),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "widget", "data"),
    deleteWidget: makeActionCreator(types.DELETE_WIDGET, "widget"),
};

export const selectors = {
    getWidget: ({ widgets }, name) => widgets[name],
};
