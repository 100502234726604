import React from "react";
import { bool, func, string } from "prop-types";
import * as configUtils from "util/config";
import I18n from "../I18n";
import Image from "../Image";

const WarningAmount = ({ idText, isConfigText, onPressWarn }) => (
    <div className="warning-amount__container">
        <button className="warning-amount__button" onClick={onPressWarn} type="button">
            <Image
                src="images/infoActiveUserInvite.svg"
                wrapperClassName="warning-amount__wrapper"
                className="warning-amount__icon"
                alt="Más información sobre transferencias de alto monto"
            />
        </button>
        {isConfigText ? (
            <p className="warning-amount__text">{configUtils.get(idText)}</p>
        ) : (
            <I18n component="p" componentProps={{ className: "warning-amount__text" }} id={idText} />
        )}
    </div>
);

WarningAmount.propTypes = {
    idText: string.isRequired,
    isConfigText: bool.isRequired,
    onPressWarn: func.isRequired,
};

export default WarningAmount;
