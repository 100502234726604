/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { func, number, arrayOf, bool, shape } from "prop-types";
import { connect } from "react-redux";
import { Box, Button, Agenda } from "@ui-kit/components/index.es";
import copy from "copy-to-clipboard";

import { selectors as transferSelectors, actions as transferActions } from "reducers/transfer";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as agendaActions, selectors as agendaSelectors } from "reducers/frequentDestination";
import { actions as notificationActions } from "reducers/notification";

import * as i18n from "util/i18n";
import { push } from "react-router-redux";

const FORM_ID = "transfers.historic";
function TransfersAgenda(props) {
    TransfersAgenda.propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        filters: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
        idEnvironment: number.isRequired,
        listFrequentDestinations: arrayOf().isRequired,
        formikProps: shape({}).isRequired,
    };

    TransfersAgenda.defaultProps = {};

    const { dispatch, isDesktop, filters, idEnvironment, listFrequentDestinations, formikProps } = props;

    const [showAgenda, setShowAgenda] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState(null);

    useEffect(() => {
        dispatch(agendaActions.loadListRequest(idEnvironment));
    }, []);

    const handleAddContact = () => {
        dispatch(agendaActions.resetListFrequentDestinations());
        dispatch(push("/FrequentDestination/create/step1"));
    };

    const handleAgendaModify = (id) => {
        dispatch(agendaActions.modifyFrequentDestinationPreRequest(id, true));
    };

    const handleAgendaDelete = (id) => {
        dispatch(agendaActions.deleteFrequentDestinationPreviewRequest(id));
    };

    const handleAgendaShare = (contact) => {
        const { name, recipientName, cbu, recipientEmail, recipientDocumentNumber } = contact;
        const i18nParams = {
            reference: name,
            headline: recipientName,
            document: recipientDocumentNumber,
            account: cbu,
            email: recipientEmail || "",
        };
        const textCopy = i18n.get("transfer.frequentDestinations.textCopy", null, i18nParams);

        if (!isDesktop && window.cordova) {
            const options = {
                message: textCopy,
            };
            window.plugins.socialsharing.shareWithOptions(options, null, null);
        } else {
            copy(textCopy);
            dispatch(
                notificationActions.showNotification(i18n.get("frequentDestination.copyDate"), "success", [
                    "transfersHistoric",
                ]),
            );
        }
    };

    const handleAgendaFilter = (contact) => {
        const { recipientName } = contact;
        const { dateFrom, dateTo, reference, transferType, onlyPendings } = filters;
        const filtersToSet = {
            dateTo,
            dateFrom,
            beneficiary: recipientName,
            reference,
            onlyPendings,
            onlyTransfers: true,
            transferType,
            minAmount: filters.minAmount?.amount || 0,
            maxAmount: filters.maxAmount?.amount || 0,
            hasSummitted: true,
            orderBy: "submit_date_time DESC",
            dateType: "submit_date_time",
        };
        formikProps.setValues(filtersToSet);
        dispatch(transferActions.setFiltersHistoric(filtersToSet));

        dispatch(transferActions.setSelectedChip("historic"));
        dispatch(
            transferActions.loadListRequest({ ...filters, beneficiary: recipientName, status: null }, false, false),
        );
        dispatch(transferActions.setShowFilters(true));
        dispatch(transferActions.setHasFilterChanged(true));
    };

    const handleContactSelect = (id) => {
        setSelectedContactId((prevSelectedId) => (prevSelectedId === id ? null : id));
    };

    const manageAgenda = () => {
        const { activeEnvironment } = props;
        return activeEnvironment.permissions["frequentDestination.manage"];
    };

    const agendaMenuOptions = (contact) => {
        const { idFrequentDestination } = contact;
        const items = [];
        if (manageAgenda()) {
            items.push(
                {
                    icon: "Pencil",
                    iconVariant: "outline",
                    label: i18n.get(`${FORM_ID}.agenda.modify`),
                    callback: () => {
                        handleAgendaModify(idFrequentDestination);
                    },
                },
                {
                    icon: "Trash",
                    iconVariant: "outline",
                    label: i18n.get(`${FORM_ID}.agenda.delete`),
                    callback: () => {
                        handleAgendaDelete(idFrequentDestination);
                    },
                },
            );
        }
        items.push(
            {
                icon: "ShareAlt",
                iconVariant: "outline",
                label: i18n.get(`${FORM_ID}.agenda.share`),
                callback: () => {
                    handleAgendaShare(contact);
                },
            },
            {
                icon: "Search",
                iconVariant: "outline",
                label: i18n.get(`${FORM_ID}.agenda.filter`),
                callback: () => {
                    handleAgendaFilter(contact);
                },
            },
        );
        return items;
    };

    const agendaItems = listFrequentDestinations.map((contact) => ({
        cbucvu: contact.cbu,
        name: contact.recipientName,
        email: contact.recipientEmail,
        reference: contact.name,
        document: contact.recipientDocumentNumber,
        menuOptions: agendaMenuOptions(contact),
        isSelected: selectedContactId === contact.idFrequentDestination,
        onClick: () => handleContactSelect(contact.idFrequentDestination),
    }));

    return (
        <Box>
            <Box pt={12} pb={showAgenda && 12}>
                <Button
                    text={i18n.get(FORM_ID + (showAgenda ? ".hide.agenda" : ".show.agenda"))}
                    iconVariant="outline"
                    variant={isDesktop ? "outline" : "text"}
                    icon={showAgenda ? "EyeOff" : "Eye"}
                    size={isDesktop ? "small" : "medium"}
                    onClick={() => setShowAgenda(!showAgenda)}
                    className={`${!isDesktop && "p-0 background-transparent"}`}
                />
            </Box>
            {showAgenda && (
                <Agenda
                    contactsArray={agendaItems}
                    id="AgendaElement"
                    showAddContact={manageAgenda()}
                    showSearcher
                    onAddContact={() => {
                        handleAddContact();
                    }}
                />
            )}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    fetching: transferSelectors.isFetchingMore(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    listFrequentDestinations: agendaSelectors.getListFrequentDestinations(state),
    idEnvironment: agendaSelectors.getEnviromentId(state),
    filters: transferSelectors.getFilters(state),
});
export default connect(mapStateToProps)(TransfersAgenda);
