import React, { Component } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { array, bool, func, objectOf } from "prop-types";
import classNames from "classnames";
import * as i18nUtils from "util/i18n";

import DraggableColumn from "pages/_components/DraggableColumn";

import * as arrayUtils from "util/array";

class DraggableList extends Component {
    static propTypes = {
        columns: objectOf(array).isRequired,
        isDragEnabled: bool,
        itemRenderer: func.isRequired,
        onItemsPositionChange: func.isRequired,
    };

    static defaultProps = {
        isDragEnabled: false,
    };

    handleDragStart = (dragResult, dragProvided) => {
        const { source } = dragResult;
        const { announce } = dragProvided;

        if (source) {
            announce(i18nUtils.get("desktop.widgets.message.start", null, { START: source.index + 1 }));
        }
    };

    handleDragUpdate = (dragResult, dragProvided) => {
        const { destination } = dragResult;
        const { announce } = dragProvided;

        if (destination) {
            announce(i18nUtils.get("desktop.widgets.message.update", null, { END: destination.index + 1 }));
        }
    };

    handleDragEnd = (dragResult, dragProvided) => {
        const { columns, onItemsPositionChange } = this.props;
        const { destination, source, reason } = dragResult;
        const { announce } = dragProvided;

        if (reason === "CANCEL") {
            announce(i18nUtils.get("desktop.widgets.message.end.canceled", null, { START: source.index + 1 }));
            return;
        }

        if (!destination) {
            announce(i18nUtils.get("desktop.widgets.message.end.nullDestination", null, { START: source.index + 1 }));
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            announce(i18nUtils.get("desktop.widgets.message.end.notMoved", null, { START: source.index + 1 }));
            return;
        }

        const column = columns[source.droppableId];
        const reinsertedItems = arrayUtils.reinsert(column, source.index, destination.index);
        const rowsUpdatedLayout = reinsertedItems.map((item, index) => ({
            ...item,
            row: index,
        }));

        onItemsPositionChange(rowsUpdatedLayout);
        announce(
            i18nUtils.get("desktop.widgets.message.end.moved", null, {
                START: source.index + 1,
                END: destination.index + 1,
            }),
        );
    };

    render() {
        const { columns, isDragEnabled, itemRenderer } = this.props;

        return (
            <div
                className={classNames("draggable-list", {
                    "is-enabled": isDragEnabled,
                    "is-disabled": !isDragEnabled,
                })}>
                <DragDropContext
                    onDragStart={this.handleDragStart}
                    onDragUpdate={this.handleDragUpdate}
                    onDragEnd={this.handleDragEnd}>
                    {Object.keys(columns).map((columnId, index) => {
                        const newIndex = index;
                        return (
                            <DraggableColumn
                                columnId={columnId}
                                isDragEnabled={isDragEnabled}
                                items={columns[columnId]}
                                itemRenderer={itemRenderer}
                                key={newIndex}
                            />
                        );
                    })}
                </DragDropContext>
            </div>
        );
    }
}

export default DraggableList;
