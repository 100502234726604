import React, { Component } from "react";
import { func } from "prop-types";
import { Field } from "formik";
import Col from "react-bootstrap/lib/Col";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import { bool } from "yup";

const FORM_ID = "services.newPayment.addService.addReference";

class AddAdhesionReference extends Component {
    static propTypes = {
        handleClick: func.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        isDesktop: false,
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", margin: 0 }}>{`${FORM_ID}.label`}</h1>
        </div>
    );

    rightContent = () => <></>;

    render() {
        const { handleClick, isDesktop } = this.props;

        return (
            <>
                <div
                    style={{
                        margin: isDesktop ? "7rem" : "2rem",
                        height: !isDesktop && "70vh",
                        display: !isDesktop && "flex",
                        alignItems: !isDesktop && "center",
                        marginBottom: 0,
                    }}>
                    {isDesktop && (
                        <div className="form-section-title admin-content-center" style={{ marginBottom: "3rem" }}>
                            <I18n component="h2" id={`${FORM_ID}.label`} />
                        </div>
                    )}

                    <Container className="flex-grow align-items-center adhesion-field" gridClassName="form-content">
                        <Col className="col col-12">
                            <Field
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="reference"
                                type="text"
                                style={{ paddingTop: "12px", paddingBottom: "12px" }}
                                maxLength="20"
                            />
                        </Col>

                        <Col className="col col-12" style={{ marginTop: isDesktop ? "20vh" : "4vh" }}>
                            <Button type="submit" bsStyle="primary" label="global.add" onClick={handleClick} />
                        </Col>
                    </Container>
                </div>
            </>
        );
    }
}

export default AddAdhesionReference;
