import { globalTypes } from "reducers/types/global";

export const types = {
    SET_ACCOUNT_LIST: "checks/SET_ACCOUNT_LIST",
    SET_CHECKS_AMOUNT: "checks/SET_CHECKS_AMOUNT",
    SET_CHECKS_DATA: "checks/SET_CHECKS_DATA",
    FETCH_ACCOUNTS: "checks/FETCH_ACCOUNTS",
    CHECKS_AMOUNT: "checks/CHECKS_AMOUNT",
    REQUEST_CHECKS: "checks/REQUEST_CHECKS",
    GET_ACCOUNT_INFO: "checks/GET_ACCOUNT_INFO",
    SET_ACCOUNT_INFO: "checks/SET_ACCOUNT_INFO",
    CLEAR_REQUEST_DATA: "checks/CLEAR_REQUEST_DATA",
    FILTER_ECHEQ_LIST: "checks/FILTER_ECHEQ_LIST",
    FILTER_ECHEQ_LIST_MOBILE: "checks/FILTER_ECHEQ_LIST_MOBILE",
    DOWNLOAD_ECHEQ_LIST_REQUEST: "checks/DOWNLOAD_ECHEQ_LIST_REQUEST",
    DOWNLOAD_ECHEQ_LIST_FAILURE: "checks/DOWNLOAD_ECHEQ_LIST_FAILURE",
    DOWNLOAD_ECHEQ_LIST_SUCCESS: "checks/DOWNLOAD_ECHEQ_LIST_SUCCESS",
    SET_ECHEQ_LIST: "checks/SET_ECHEQ_LIST",
    SET_ECHEQ_LIST_MOBILE: "checks/SET_ECHEQ_LIST_MOBILE",
    SET_ECHEQ_OPTION: "checks/SET_ECHEQ_OPTION",
    SET_ECHEQ_FILTERS: "checks/SET_ECHEQ_FILTERS",
    SET_ECHEQ_TODO_ACTIONS: "checks/SET_ECHEQ_TODO_ACTIONS",
    SET_ECHEQ_REPUDIATE_MOTIVE: "checks/SET_ECHEQ_REPUDIATE_MOTIVE",
    SET_REQUEST_RETURN_SOLICITANT_NAME: "checks/SET_REQUEST_RETURN_SOLICITANT_NAME",
    EXECUTE_ACTION_ECHEQ: "checks/EXECUTE_ACTION_ECHEQ",
    BENEFICIARY_ENDORSE_ECHEQ_DATA: "checks/BENEFICIARY_ENDORSE_ECHEQ_DATA",
    ENDORSE_ECHEQ_DATA: "checks/ENDORSE_ECHEQ_DATA",
    BANKED_BENEFICIARY: "checks/BANKED_BENEFICIARY",
    ECHEQ_DETAIL_REQUEST: "checks/ECHEQ_DETAIL_REQUEST",
    ECHEQ_DETAIL_FAILURE: "checks/ECHEQ_DETAIL_FAILURE",
    ECHEQ_DETAIL_SUCCESS: "checks/ECHEQ_DETAIL_SUCCESS",
    ENDORSE_ECHEQ: "checks/ENDORSE_ECHEQ",
    NEGOTIATE_ECHEQ: "checks/NEGOTIATE_ECHEQ",
    NEGOTIATE_MULTIPLE_ECHEQ: "checks/NEGOTIATE_MULTIPLE_ECHEQ",
    TRANSACTION_ECHEQ: "checks/TRANSACTION_ECHEQ",
    ENDORSE_MULTIPLE_ECHEQ: "checks/ENDORSE_MULTIPLE_ECHEQ",
    CLEAN_ECHEQ_ACTION: "checks/CLEAN_ECHEQ_ACTION",
    EMIT_ECHEQ: "checks/EMIT_ECHEQ",
    EMIT_ECHEQ_DATA: "checks/EMIT_ECHEQ_DATA",
    EMIT_ECHEQ_BENEFICIARY: "checks/EMIT_ECHEQ_BENEFICIARY",
    CESION_ECHEQ_BENEFICIARY: "checks/CESION_ECHEQ_BENEFICIARY",
    CESION_ECHEQ_DATA: "checks/CESION_ECHEQ_DATA",
    CESION_ECHEQ: "checks/CESION_ECHEQ",
    CESION_MULTIPLE_ECHEQ: "checks/CESION_MULTIPLE_ECHEQ",
    ACCEPT_RETURN_ECHEQ: "checks/ACCEPT_RETURN_ECHEQ",
    SET_EMITTED_CHECKS: "checks/SET_EMITTED_CHECKS",
    SET_DEPOSITED_CHECKS: "checks/SET_DEPOSITED_CHECKS",
    LIST_CHECK_DETAIL_REQUEST: "checks/LIST_CHECK_DETAIL_REQUEST",
    LIST_CHECK_DETAIL_FAILURE: "checks/LIST_CHECK_DETAIL_FAILURE",
    LIST_CHECK_DETAIL_SUCCESS: "checks/LIST_CHECK_DETAIL_SUCCESS",
    SET_CHECK_DETAIL: "checks/SET_CHECK_DETAIL",
    CLEAR_LIST_CHECK_DETAIL: "checks/CLEAR_LIST_CHECK_DETAIL",
    DOWNLOAD_CHECK_DETAIL: "checks/DOWNLOAD_CHECK_DETAIL",
    DOWNLOAD_CHECK_DETAIL_SUCESS: "checks/DOWNLOAD_CHECK_DETAIL_SUCESS",
    DOWNLOAD_CHECK_DETAIL_FAILURE: "checks/DOWNLOAD_CHECK_DETAIL_FAILURE",
    LIST_DISCOUNTED_CHECKS_DETAIL_REQUEST: "checks/LIST_DISCOUNTED_CHECKS_DETAIL_REQUEST",
    LIST_DISCOUNTED_CHECKS_DETAIL_FAILURE: "checks/LIST_DISCOUNTED_CHECKS_DETAIL_FAILURE",
    LIST_DISCOUNTED_CHECKS_DETAIL_SUCCESS: "checks/LIST_DISCOUNTED_CHECKS_DETAIL_SUCCESS",
    DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_REQUEST: "checks/DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_REQUEST",
    DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_FAILURE: "checks/DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_FAILURE",
    DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_SUCCESS: "checks/DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_SUCCESS",
    DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_REQUEST: "checks/DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_REQUEST",
    DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_FAILURE: "checks/DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_FAILURE",
    DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_SUCCESS: "checks/DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_SUCCESS",
    DOWNLOAD_CHECKS_REQUEST_TICKET_REQUEST: "checks/DOWNLOAD_CHECKS_REQUEST_TICKET_REQUEST",
    DOWNLOAD_CHECKS_REQUEST_TICKET_FAILURE: "checks/DOWNLOAD_CHECKS_REQUEST_TICKET_FAILURE",
    DOWNLOAD_CHECKS_REQUEST_TICKET_SUCCESS: "checks/DOWNLOAD_CHECKS_REQUEST_TICKET_SUCCESS",
    DOWNLOAD_ECHEQ_DETAIL_MOBILE_REQUEST: "checks/DOWNLOAD_ECHEQ_DETAIL_MOBILE_REQUEST",
    DOWNLOAD_ECHEQ_DETAIL_MOBILE_FAILURE: "checks/DOWNLOAD_ECHEQ_DETAIL_MOBILE_FAILURE",
    DOWNLOAD_ECHEQ_DETAIL_MOBILE_SUCCESS: "checks/DOWNLOAD_ECHEQ_DETAIL_MOBILE_SUCCESS",
    DOWNLOAD_CHECK_DETAIL_MOBILE_REQUEST: "checks/DOWNLOAD_CHECK_DETAIL_MOBILE_REQUEST",
    DOWNLOAD_CHECK_DETAIL_MOBILE_FAILURE: "checks/DOWNLOAD_CHECK_DETAIL_MOBILE_FAILURE",
    DOWNLOAD_CHECK_DETAIL_MOBILE_SUCCESS: "checks/DOWNLOAD_CHECK_DETAIL_MOBILE_SUCCESS",
    SET_CONFIG_FORM: "checks/SET_CONFIG_FORM",
    GO_BACK_ECHEQS: "checks/GO_BACK_ECHEQS",
    SET_SHOW_FORM: "checks/SET_SHOW_FORM",
    DOWNLOAD_DISCOUNT_CHECKS_REQUEST: "checks/DOWNLOAD_DISCOUNT_CHECKS_REQUEST",
    DOWNLOAD_DISCOUNT_CHECKS_FAILURE: "checks/DOWNLOAD_DISCOUNT_CHECKS_FAILURE",
    DOWNLOAD_DISCOUNT_CHECKS_SUCCESS: "checks/DOWNLOAD_DISCOUNT_CHECKS_SUCCESS",
    ECHEQS_TO_EMIT: "checks/ECHEQS_TO_EMIT",
    CLEAN_ECHEQ_EMISSION: "checks/CLEAN_ECHEQ_EMISSION",
    CLEAN_EMISSION_DATA: "checks/CLEAN_EMISSION_DATA",
    SET_EMISSION_DATA: "checks/SET_EMISSION_DATA",
    SET_ACCOUNT_FINISHED: "checks/SET_ACCOUNT_FINISHED",
    VALIDATE_ACCOUNT_EMIT_REQUEST: "checks/VALIDATE_ACCOUNT_EMIT_REQUEST",
    VALIDATE_ACCOUNT_EMIT_FAILURE: "checks/VALIDATE_ACCOUNT_EMIT_FAILURE",
    VALIDATE_ACCOUNT_EMIT_SUCCESS: "checks/VALIDATE_ACCOUNT_EMIT_SUCCESS",
    EMIT_MASSIVE_ECHEQ_PREVIEW_REQUEST: "checks/EMIT_MASSIVE_ECHEQ_PREVIEW_REQUEST",
    EMIT_MASSIVE_ECHEQ_PREVIEW_FAILURE: "checks/EMIT_MASSIVE_ECHEQ_PREVIEW_FAILURE",
    EMIT_MASSIVE_ECHEQ_PREVIEW_SUCCESS: "checks/EMIT_MASSIVE_ECHEQ_PREVIEW_SUCCESS",
    EMIT_MASSIVE_ECHEQ_SEND_REQUEST: "checks/EMIT_MASSIVE_ECHEQ_SEND_REQUEST",
    EMIT_MASSIVE_ECHEQ_SEND_FAILURE: "checks/EMIT_MASSIVE_ECHEQ_SEND_FAILURE",
    EMIT_MASSIVE_ECHEQ_SEND_SUCCESS: "checks/EMIT_MASSIVE_ECHEQ_SEND_SUCCESS",
    SET_REMEMBER_MASSIVE_DATA: "checks/SET_REMEMBER_MASSIVE_DATA",
    CLEAN_MASSIVE_DATA: "checks/CLEAN_MASSIVE_DATA",
    ECHEQ_BATCH_DETAIL_REQUEST: "checks/ECHEQ_BATCH_DETAIL_REQUEST",
    ECHEQ_BATCH_DETAIL_FAILURE: "checks/ECHEQ_BATCH_DETAIL_FAILURE",
    ECHEQ_BATCH_DETAIL_SUCCESS: "checks/ECHEQ_BATCH_DETAIL_SUCCESS",
    DOWNLOAD_ECHEQ_BATCH_DETAIL: "checks/DOWNLOAD_ECHEQ_BATCH_DETAIL",
    DOWNLOAD_ECHEQ_BATCH_DETAIL_SUCCESS: "checks/DOWNLOAD_ECHEQ_BATCH_DETAIL_SUCCESS",
    DOWNLOAD_ECHEQ_BATCH_DETAIL_FAILURE: "checks/DOWNLOAD_ECHEQ_BATCH_DETAIL_FAILURE",
    SET_IS_AUTOMATED_DISCOUNT: "checks/SET_IS_AUTOMATED_DISCOUNT",
    REQUEST_DOWNLOAD_ECHEQ_BY_CUIT: "checks/REQUEST_DOWNLOAD_EXTRACT_BY_CUIT",
    REQUEST_DOWNLOAD_ECHEQ_BY_CUIT_FINISH: "checks/REQUEST_DOWNLOAD_EXTRACT_BY_CUIT_FINISH",
    SET_ID_SELECTOR_VALIDATION_ERROR: "checks/SET_ID_SELECTOR_VALIDATION_ERROR",
    SET_NUMBER_SELECTOR_VALIDATION_ERROR: "checks/SET_NUMBER_SELECTOR_VALIDATION_ERROR",
    SET_ID_LIST: "checks/SET_ID_LIST",
    SET_NUMBER_LIST: "checks/SET_NUMBER_LIST",
    CLEAN_IDS_NRS: "checks/CLEAN_IDS_NRS",
    RESET_SHOW_CLEAN_FILTER_BUTTON: "checks/RESET_SHOW_CLEAN_FILTER_BUTTON",
    RESET_ERRORS_ID_NUMBER: "checks/RESET_ERRORS_ID_NUMBER",
};

export const INITIAL_STATE = {
    fetching: false,
    accountList: [],
    checkTypeList: [],
    checkbookChecksAmount: [],
    checksRequestData: null,
    echeqList: [],
    echeqPagination: {},
    environmentCuit: "",
    optionEcheq: "received.echeqs",
    echeqFilters: {
        orderColumName: "fecha_pago",
        orderDirection: "descendent",
        status:
            "Activo,Activo-pendiente,Anulado,Caducado,Custodia,Cesion-pendiente,Depositado,Devolucion-pendiente,Emitido-Pendiente,Negociación,Negociacion-Pendiente,Pagado,Presentado,Rechazado,Repudiado",
    },
    echeqsToDoActions: {},
    echeqsEndorseData: {},
    echeqTransactionData: {},
    emitEcheqData: {},
    echeqsToEmit: [],
    cesionEcheqData: {},
    isEmittedSelected: true,
    isDepositedSelected: false,
    selectedCheckDetail: null,
    checkDetailCurrentPageNumber: null,
    checkDetailTotalPages: null,
    listCheckDetail: [],
    discountedChecksDetailCurrentPageNumber: null,
    discountedChecksDetailTotalPages: null,
    listDiscountedChecksDetail: [],
    fetchingDownload: false,
    fetchingEcheqList: false,
    goBackEcheqs: false,
    concepts: [],
    showForm: false,
    hasMoreData: false,
    emission: null,
    fetchingAccounts: false,
    serverSearchDate: null,
    fetchingValidateAccount: false,
    isAvailableAccount: false,
    emitMassiveEcheqData: {},
    rememberMassiveData: false,
    statusList: [],
    isAutomatedDiscount: false,
    hasIdEcheqError: false,
    hasNumberEcheqError: false,
    showCleanFilterButton: false,
    idList: [],
    numberList: [],
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        accounts,
        checks,
        checksAmount,
        checksData,
        accountInfo,
        listEcheqs,
        totalPages,
        pageNumber,
        echeqOption,
        filtersEcheqs,
        echeqs,
        specialAttribute,
        endorseData,
        beneficiary,
        echeqDetail,
        formData,
        checkDetailCurrentPageNumber,
        checkDetailTotalPages,
        listCheckDetail,
        selectedCheckDetail,
        discountedChecksDetailCurrentPageNumber,
        discountedChecksDetailTotalPages,
        listDiscountedChecksDetail,
        configForm,
        goBackEcheqs,
        concepts,
        showForm,
        solicitantName,
        emissionData,
        serverDate,
        emitMassiveEcheqData,
        rememberMassiveData,
        echeqBatch,
        batchDetail,
        statusList,
        batchErrors,
    } = action;

    switch (action.type) {
        case types.SET_ACCOUNT_LIST:
            return {
                ...state,
                accountList: accounts,
                checkTypeList: checks,
                concepts,
                fetching: false,
            };
        case types.SET_ECHEQ_OPTION:
            return {
                ...state,
                optionEcheq: echeqOption,
                echeqList: [],
            };
        case types.SET_ECHEQ_FILTERS:
            return {
                ...state,
                echeqFilters: filtersEcheqs,
                showCleanFilterButton: true,
                echeqList: [],
            };
        case types.SET_ECHEQ_LIST:
            return {
                ...state,
                echeqList: listEcheqs,
                hasMoreData: action.pageNumber < action.totalPages,
                echeqPagination: { totalPages, pageNumber },
                environmentCuit: action.environmentCuit || state.environmentCuit,
                serverSearchDate: serverDate,
                statusList,
            };
        case types.SET_ECHEQ_LIST_MOBILE:
            return {
                ...state,
                echeqList: [...state.echeqList, ...action.echeqList],
                hasMoreData: action.pageNumber < action.totalPages,
                echeqPagination: { totalPages, pageNumber },
                environmentCuit: action.environmentCuit || state.environmentCuit,
                serverSearchDate: serverDate,
                statusList,
            };
        case types.SET_ACCOUNT_INFO:
            return {
                ...state,
                checksRequestData: { ...state.checksRequestData, account: accountInfo },
            };
        case types.CLEAR_REQUEST_DATA:
            return {
                ...state,
                checksRequestData: {},
            };
        case types.SET_CHECKS_AMOUNT:
            return {
                ...state,
                checkbookChecksAmount: checksAmount,
            };
        case types.SET_CHECKS_DATA:
            return {
                ...state,
                checksRequestData: checksData,
            };
        case types.SET_ECHEQ_TODO_ACTIONS:
            return {
                ...state,
                echeqsToDoActions: echeqs,
            };
        case types.SET_ECHEQ_REPUDIATE_MOTIVE:
            return {
                ...state,
                echeqsToDoActions: { ...state.echeqsToDoActions, specialAttribute },
            };
        case types.SET_REQUEST_RETURN_SOLICITANT_NAME:
            return {
                ...state,
                echeqsToDoActions: { ...state.echeqsToDoActions, solicitantName },
            };
        case types.BENEFICIARY_ENDORSE_ECHEQ_DATA:
            return {
                ...state,
                echeqsEndorseData: { ...state.echeqsEndorseData, beneficiary },
            };
        case types.ENDORSE_ECHEQ_DATA:
            return {
                ...state,
                echeqsEndorseData: { ...state.echeqsEndorseData, endorseData },
            };

        case types.ECHEQ_DETAIL_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ECHEQ_DETAIL_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.ECHEQ_DETAIL_SUCCESS:
            return {
                ...state,
                echeqDetail,
                environmentCuit: action.environmentCuit,
                fetching: false,
            };
        case types.TRANSACTION_ECHEQ:
            return {
                ...state,
                echeqTransactionData: action.echeqTransactionData,
                fetching: false
            };
        case types.CLEAN_ECHEQ_ACTION:
            return {
                ...state,
                echeqsToDoActions: {},
                echeqsEndorseData: {},
                echeqTransactionData: {},
                emitEcheqData: {},
                echeqList: [],
                cesionEcheqData: {},
                echeqsToEmit: INITIAL_STATE.echeqsToEmit,
            };
        case types.CLEAN_ECHEQ_EMISSION:
            return {
                emitEcheqData: {},
                echeqsToEmit: [],
            };
        case types.CLEAN_EMISSION_DATA:
            return {
                ...state,
                emission: null,
            };
        case types.ECHEQS_TO_EMIT:
            return { ...state, echeqsToEmit: action.echeqList };
        case types.EMIT_ECHEQ_BENEFICIARY:
            return { ...state, emitEcheqData: { ...state.emitEcheqData, beneficiary } };
        case types.EMIT_ECHEQ_DATA:
            return {
                ...state,
                emitEcheqData: { ...state.emitEcheqData, ...formData },
                echeqsToEmit: action.echeqList,
                emission: emissionData,
            };
        case types.CESION_ECHEQ_BENEFICIARY:
            return { ...state, cesionEcheqData: { ...state.cesionEcheqData, beneficiary } };
        case types.CESION_ECHEQ_DATA:
            return { ...state, cesionEcheqData: { ...state.cesionEcheqData, ...formData } };
        case types.SET_EMITTED_CHECKS:
            return {
                ...state,
                isEmittedSelected: true,
                isDepositedSelected: false,
            };
        case types.SET_DEPOSITED_CHECKS:
            return {
                ...state,
                isEmittedSelected: false,
                isDepositedSelected: true,
            };
        case types.LIST_CHECK_DETAIL_REQUEST:
            return {
                ...state,
            };
        case types.LIST_CHECK_DETAIL_FAILURE:
            return {
                ...state,
            };
        case types.LIST_CHECK_DETAIL_SUCCESS:
            return {
                ...state,
                checkDetailCurrentPageNumber,
                checkDetailTotalPages,
                listCheckDetail,
                showForm: false,
            };
        case types.SET_CHECK_DETAIL:
            return {
                ...state,
                selectedCheckDetail,
            };
        case types.CLEAR_LIST_CHECK_DETAIL:
            return {
                ...state,
                listCheckDetail: [],
            };
        case types.DOWNLOAD_CHECK_DETAIL:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.LIST_DISCOUNTED_CHECKS_DETAIL_REQUEST:
            return {
                ...state,
            };
        case types.LIST_DISCOUNTED_CHECKS_DETAIL_FAILURE:
            return {
                ...state,
            };
        case types.LIST_DISCOUNTED_CHECKS_DETAIL_SUCCESS:
            return {
                ...state,
                discountedChecksDetailCurrentPageNumber,
                discountedChecksDetailTotalPages,
                listDiscountedChecksDetail,
            };
        case types.DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_FAILURE:
        case types.DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_FAILURE:
        case types.DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_CHECKS_REQUEST_TICKET_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_CHECKS_REQUEST_TICKET_FAILURE:
        case types.DOWNLOAD_CHECKS_REQUEST_TICKET_SUCCESS:
        case types.DOWNLOAD_CHECK_DETAIL_SUCESS:
        case types.DOWNLOAD_CHECK_DETAIL_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.SET_SHOW_FORM:
            return {
                ...state,
                showForm,
            };
        case types.DOWNLOAD_ECHEQ_LIST_REQUEST:
            return {
                ...state,
                fetchingEcheqList: true,
            };
        case types.DOWNLOAD_ECHEQ_LIST_FAILURE:
        case types.DOWNLOAD_ECHEQ_LIST_SUCCESS:
            return {
                ...state,
                fetchingEcheqList: false,
            };
        case types.DOWNLOAD_CHECK_DETAIL_MOBILE_FAILURE:
        case types.DOWNLOAD_CHECK_DETAIL_MOBILE_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.SET_CONFIG_FORM:
            return {
                ...state,
                configForm,
            };
        case types.GO_BACK_ECHEQS:
            return {
                ...state,
                goBackEcheqs,
            };
        case types.FETCH_ACCOUNTS:
            return {
                ...state,
                fetchAccounts: true,
            };
        case types.SET_ACCOUNT_FINISHED:
            return {
                ...state,
                fetchAccounts: false,
            };
        case globalTypes.RESET_SELECTED_ELEMENTS:
            return { ...INITIAL_STATE, serverSearchDate: state.serverSearchDate };
        case globalTypes.CLEAN_UP:
            return INITIAL_STATE;
        case types.VALIDATE_ACCOUNT_EMIT_REQUEST:
            return {
                ...state,
                fetchingValidateAccount: true,
            };
        case types.VALIDATE_ACCOUNT_EMIT_FAILURE:
        case types.VALIDATE_ACCOUNT_EMIT_SUCCESS:
            return {
                ...state,
                fetchingValidateAccount: false,
                isAvailableAccount: true,
            };
        case types.EMIT_MASSIVE_ECHEQ_PREVIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                emitMassiveEcheqData,
            };
        case types.EMIT_MASSIVE_ECHEQ_PREVIEW_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.EMIT_MASSIVE_ECHEQ_PREVIEW_SUCCESS:
            return {
                ...state,
                fetching: false,
                emitMassiveEcheqData,
            };
        case types.CLEAN_MASSIVE_DATA:
            return {
                ...state,
                isAvailableAccount: false,
                emitMassiveEcheqData: INITIAL_STATE.emitMassiveEcheqData,
            };
        case types.SET_REMEMBER_MASSIVE_DATA:
            return {
                ...state,
                rememberMassiveData,
            };
        case types.ECHEQ_BATCH_DETAIL_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ECHEQ_BATCH_DETAIL_SUCCESS:
            return {
                ...state,
                echeqBatch,
                batchDetail,
                batchErrors,
                echeqPagination: { totalPages, pageNumber },
                fetching: false,
            };
        case types.DOWNLOAD_ECHEQ_BATCH_DETAIL:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_ECHEQ_BATCH_DETAIL_SUCCESS:
        case types.DOWNLOAD_ECHEQ_BATCH_DETAIL_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.SET_IS_AUTOMATED_DISCOUNT:
            return {
                ...state,
                isAutomatedDiscount: action.isAutomatedDiscount,
            };
        case types.REQUEST_DOWNLOAD_ECHEQ_BY_CUIT:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.REQUEST_DOWNLOAD_ECHEQ_BY_CUIT_FINISH:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.SET_ID_SELECTOR_VALIDATION_ERROR:
            return {
                ...state,
                hasIdEcheqError: action.payload,
            };
        case types.SET_NUMBER_SELECTOR_VALIDATION_ERROR:
            return {
                ...state,
                hasNumberEcheqError: action.payload,
            };
        case types.SET_NUMBER_LIST:
            return {
                ...state,
                numberList: action.payload,
            };
        case types.SET_ID_LIST:
            return {
                ...state,
                idList: action.payload,
            };
        case types.CLEAN_IDS_NRS:
            return {
                ...state,
                idList: INITIAL_STATE.idList,
                numberList: INITIAL_STATE.numberList,
            };
        case types.RESET_SHOW_CLEAN_FILTER_BUTTON:
            return {
                ...state,
                showCleanFilterButton: INITIAL_STATE.showCleanFilterButton,
            };
        case types.RESET_ERRORS_ID_NUMBER:
            return {
                ...state,
                hasIdEcheqError: INITIAL_STATE.hasIdEcheqError,
                hasNumberEcheqError: INITIAL_STATE.hasNumberEcheqError,
            };
        default:
            return state;
    }
};

export const actions = {
    clearRequestData: () => ({ type: types.CLEAR_REQUEST_DATA }),
    fetchAccounts: (idUser) => ({
        type: types.FETCH_ACCOUNTS,
        idUser,
    }),
    getAccountInfo: (account, accountType) => ({
        type: types.GET_ACCOUNT_INFO,
        account,
        accountType,
    }),
    fetchChecksAmount: (checksType) => ({
        type: types.CHECKS_AMOUNT,
        checksType,
    }),
    checksRequestData: (checksData) => ({
        type: types.SET_CHECKS_DATA,
        checksData,
        fetching: false
    }),

    requestChecks: (checksRequestData, otp, userId, formikBag) => ({
        type: types.REQUEST_CHECKS,
        checksRequestData,
        otp,
        userId,
        formikBag,
    }),
    filterEcheqList: (filters, setSubmitting, isDesktop) => ({
        type: types.FILTER_ECHEQ_LIST,
        filters,
        setSubmitting,
        isDesktop,
    }),
    filterEcheqListMobile: (filters, setSubmitting, isDesktop) => ({
        type: types.FILTER_ECHEQ_LIST_MOBILE,
        filters,
        setSubmitting,
        isDesktop,
    }),
    downloadEcheqList: (filters) => ({
        type: types.DOWNLOAD_ECHEQ_LIST_REQUEST,
        filters,
    }),

    setEcheqOption: (echeqOption) => ({
        type: types.SET_ECHEQ_OPTION,
        echeqOption,
    }),
    setEcheqFilters: (filtersEcheqs) => ({
        type: types.SET_ECHEQ_FILTERS,
        filtersEcheqs,
    }),
    setEcheqsToDoActions: (echeqs) => ({
        type: types.SET_ECHEQ_TODO_ACTIONS,
        echeqs,
    }),
    setEcheqsSpecialAttribute: (specialAttribute) => ({
        type: types.SET_ECHEQ_REPUDIATE_MOTIVE,
        specialAttribute,
    }),
    setRequestReturnSolicitanInformation: (cuit) => ({
        type: types.SET_REQUEST_RETURN_SOLICITANT_NAME,
        cuit,
    }),
    executeActionEcheq: (echeqAction, userId) => ({
        type: types.EXECUTE_ACTION_ECHEQ,
        echeqAction,
        userId,
    }),
    beneficiaryEcheq: (beneficiary, stringType) => ({
        type: stringType,
        beneficiary,
    }),
    beneficiaryEmitEcheq: (beneficiary) => ({
        type: types.EMIT_ECHEQ_BENEFICIARY,
        beneficiary,
    }),

    endorseEcheqData: (endorseData) => ({
        type: types.ENDORSE_ECHEQ_DATA,
        endorseData,
    }),
    bankedBeneficiary: (document, setSubmitting, stringType, setErrors) => ({
        type: types.BANKED_BENEFICIARY,
        document,
        setSubmitting,
        stringType,
        setErrors,
    }),
    endorseEcheq: (otp, setSubmitting, setErrors, endorseData, echeqAction, userId) => ({
        type: types.ENDORSE_ECHEQ,
        otp,
        setSubmitting,
        setErrors,
        endorseData,
        echeqAction,
        userId,
    }),
    emitEcheq: (otp, setSubmitting, setErrors, emitData, userId) => ({
        type: types.EMIT_ECHEQ,
        otp,
        setSubmitting,
        setErrors,
        emitData,
        userId,
    }),
    negotiateEcheq: (otp, setSubmitting, setErrors, endorseData, echeqAction, userId, isAutomatedDiscount) => ({
        type: types.NEGOTIATE_ECHEQ,
        otp,
        setSubmitting,
        setErrors,
        endorseData,
        echeqAction,
        userId,
        isAutomatedDiscount,
    }),
    negotiateMultipleEcheq: (otp, setSubmitting, setErrors, endorseData, echeqAction, userId) => ({
        type: types.NEGOTIATE_MULTIPLE_ECHEQ,
        otp,
        setSubmitting,
        setErrors,
        endorseData,
        echeqAction,
        userId,
    }),
    endorseMultipleEcheq: (otp, setSubmitting, setErrors, endorseData, echeqAction, userId) => ({
        type: types.ENDORSE_MULTIPLE_ECHEQ,
        otp,
        setSubmitting,
        setErrors,
        endorseData,
        echeqAction,
        userId,
    }),
    cesionMultipleEcheq: (otp, setSubmitting, setErrors, cesionData, echeqAction, userId) => ({
        type: types.CESION_MULTIPLE_ECHEQ,
        otp,
        setSubmitting,
        setErrors,
        cesionData,
        echeqAction,
        userId,
    }),
    cesionEcheq: (otp, setSubmitting, setErrors, cesionData, echeqAction, userId) => ({
        type: types.CESION_ECHEQ,
        otp,
        setSubmitting,
        setErrors,
        cesionData,
        echeqAction,
        userId,
    }),
    acceptReturnEcheq: (otp, setSubmitting, setErrors, echeqAction, userId) => ({
        type: types.ACCEPT_RETURN_ECHEQ,
        otp,
        setSubmitting,
        setErrors,
        echeqAction,
        userId,
    }),

    requestEcheqDetail: (echeqId) => ({
        type: types.ECHEQ_DETAIL_REQUEST,
        echeqId,
    }),
    transactionEcheq: (echeqTransactionData) => ({
        type: types.TRANSACTION_ECHEQ,
        echeqTransactionData,
    }),
    cleanEcheqAction: () => ({
        type: types.CLEAN_ECHEQ_ACTION,
    }),
    echeqEmitData: (formData, echeqList, emissionData) => ({
        type: types.EMIT_ECHEQ_DATA,
        formData,
        echeqList,
        emissionData,
    }),
    updateListEcheqToEmit: (echeqList) => ({
        type: types.ECHEQS_TO_EMIT,
        echeqList,
    }),
    echeqCesionData: (formData) => ({
        type: types.CESION_ECHEQ_DATA,
        formData,
    }),
    setEmmitedChecks: () => ({
        type: types.SET_EMITTED_CHECKS,
    }),
    setDepositedChecks: () => ({
        type: types.SET_DEPOSITED_CHECKS,
    }),
    fetchCheckDetailList: (
        setSubmitting,
        checksTypeChamber,
        pageNumber,
        checkNumber,
        dateFrom,
        dateTo,
        amountFrom,
        amountTo,
    ) => ({
        type: types.LIST_CHECK_DETAIL_REQUEST,
        setSubmitting,
        checksTypeChamber,
        pageNumber,
        checkNumber,
        dateFrom,
        dateTo,
        amountFrom,
        amountTo,
    }),
    setCheckDetail: (selectedCheckDetail) => ({
        type: types.SET_CHECK_DETAIL,
        selectedCheckDetail,
    }),
    clearListCheckDetail: () => ({
        type: types.CLEAR_LIST_CHECK_DETAIL,
    }),
    downloadCheckDetail: (
        format,
        checksTypeChamber,
        pageNumber,
        checkNumber,
        dateFrom,
        dateTo,
        amountFrom,
        amountTo,
        registersByPage,
    ) => ({
        type: types.DOWNLOAD_CHECK_DETAIL,
        format,
        checksTypeChamber,
        pageNumber,
        checkNumber,
        dateFrom,
        dateTo,
        amountFrom,
        amountTo,
        registersByPage,
    }),
    fetchDiscountedChecksDetailList: (
        setSubmitting,
        pageNumber,
        checkNumber,
        liquidationNumber,
        checkStatus,
        dateFrom,
        dateTo,
    ) => ({
        type: types.LIST_DISCOUNTED_CHECKS_DETAIL_REQUEST,
        setSubmitting,
        pageNumber,
        checkNumber,
        liquidationNumber,
        checkStatus,
        dateFrom,
        dateTo,
    }),
    downloadDiscountedChecksDetail: (
        format,
        pageNumber,
        checkNumber,
        liquidationNumber,
        checkStatus,
        dateFrom,
        dateTo,
        registersByPage,
    ) => ({
        type: types.DOWNLOAD_DISCOUNTED_CHECKS_DETAIL_REQUEST,
        format,
        pageNumber,
        checkNumber,
        liquidationNumber,
        checkStatus,
        dateFrom,
        dateTo,
        registersByPage,
    }),
    downloadNegotiateEcheqTicket: (
        creationDate,
        idTransaction,
        beneficiary,
        endorseType,
        accountLabel,
        reference,
        referencevalue,
        echeqNumber,
        idEcheq,
        emittedDate,
        paymentDate,
        totalAmount,
        echeqType,
        echeqCharacter,
        echeqMode,
        echeqMotive,
        echeqConcept,
    ) => ({
        type: types.DOWNLOAD_NEGOTIATE_ECHEQ_TICKET_REQUEST,
        creationDate,
        idTransaction,
        beneficiary,
        endorseType,
        accountLabel,
        reference,
        referencevalue,
        echeqNumber,
        idEcheq,
        emittedDate,
        paymentDate,
        totalAmount,
        echeqType,
        echeqCharacter,
        echeqMode,
        echeqMotive,
        echeqConcept,
    }),
    downloadChecksRequestTicket: (
        dateTime,
        receipt,
        accountName,
        checksTypes,
        checkBookChecksAmount,
        checkBooksAmount,
        authorizedDocument,
        authorizedName,
        authorizedSurname,
        deliveryPoint,
        responseIBS,
    ) => ({
        type: types.DOWNLOAD_CHECKS_REQUEST_TICKET_REQUEST,
        dateTime,
        receipt,
        accountName,
        checksTypes,
        checkBookChecksAmount,
        checkBooksAmount,
        authorizedDocument,
        authorizedName,
        authorizedSurname,
        deliveryPoint,
        responseIBS,
    }),
    downloadEcheqMobileInfo: (format, echeqDetail) => ({
        type: types.DOWNLOAD_ECHEQ_DETAIL_MOBILE_REQUEST,
        format,
        echeqDetail,
    }),
    downloadCheckMobileInfo: (format, checkDetail, isEmittedSelected) => ({
        type: types.DOWNLOAD_CHECK_DETAIL_MOBILE_REQUEST,
        format,
        checkDetail,
        isEmittedSelected,
    }),
    setConfigForm: (configForm) => ({
        type: types.SET_CONFIG_FORM,
        configForm,
    }),
    setGoBackEcheqs: (goBackEcheqs) => ({
        type: types.GO_BACK_ECHEQS,
        goBackEcheqs,
    }),
    setShowForm: (showForm) => ({
        type: types.SET_SHOW_FORM,
        showForm,
    }),
    downloadDiscountChecks: (operationNumber) => ({
        type: types.DOWNLOAD_DISCOUNT_CHECKS_REQUEST,
        operationNumber,
    }),
    validateAccountEmit: (documentType, document, setErrors) => ({
        type: types.VALIDATE_ACCOUNT_EMIT_REQUEST,
        documentType,
        document,
        setErrors,
    }),
    emitMassiveEcheqPreview: (formikBag, emitMassiveEcheqData) => ({
        type: types.EMIT_MASSIVE_ECHEQ_PREVIEW_REQUEST,
        formikBag,
        emitMassiveEcheqData,
    }),
    emitMassiveEcheqSend: (otp, formikBag, summary) => ({
        type: types.EMIT_MASSIVE_ECHEQ_SEND_REQUEST,
        otp,
        formikBag,
        summary,
    }),
    cleanMassiveSummary: () => ({
        type: types.CLEAN_MASSIVE_DATA,
    }),
    setRememberMassiveData: (rememberMassiveData) => ({
        type: types.SET_REMEMBER_MASSIVE_DATA,
        rememberMassiveData,
    }),
    echeqBatchDetail: (idTransaction, batchId, statusFilter, pageNumber) => ({
        type: types.ECHEQ_BATCH_DETAIL_REQUEST,
        idTransaction,
        batchId,
        statusFilter,
        pageNumber,
    }),
    downloadEcheqBatchDetail: (batchId, statusFilter, fileName) => ({
        type: types.DOWNLOAD_ECHEQ_BATCH_DETAIL,
        batchId,
        statusFilter,
        fileName,
    }),
    setIsAutomatedDiscount: (isAutomatedDiscount) => ({
        type: types.SET_IS_AUTOMATED_DISCOUNT,
        isAutomatedDiscount,
    }),
    requestDownloadEcheqByCuit: (fromDate, toDate) => ({
        type: types.REQUEST_DOWNLOAD_ECHEQ_BY_CUIT,
        fromDate,
        toDate,
    }),
    setIdSelectorValidationError: (hasIdEcheqError) => ({
        type: types.SET_ID_SELECTOR_VALIDATION_ERROR,
        payload: hasIdEcheqError,
    }),
    setNumberSelectorValidationError: (hasNumberEcheqError) => ({
        type: types.SET_NUMBER_SELECTOR_VALIDATION_ERROR,
        payload: hasNumberEcheqError,
    }),
    setIdList: (list) => ({
        type: types.SET_ID_LIST,
        payload: list,
    }),
    setNumberList: (list) => ({
        type: types.SET_NUMBER_LIST,
        payload: list,
    }),
    cleanEcheqsIdsNrs: () => ({
        type: types.CLEAN_IDS_NRS,
    }),
    resetShowCleanFilterButton: () => ({
        type: types.RESET_SHOW_CLEAN_FILTER_BUTTON,
    }),
    resetErrorsIdNumber: () => ({
        type: types.RESET_ERRORS_ID_NUMBER,
    }),
};

export const selectors = {
    getFetching: ({ checks }) => checks.fetching,
    getAccounts: ({ checks }) => checks.accountList,
    getChecksTypes: ({ checks }) => checks.checkTypeList,
    getChecksAmount: ({ checks }) => checks.checkbookChecksAmount,
    getChecksRequestData: ({ checks }) => checks.checksRequestData,
    getEcheqsList: ({ checks }) => checks.echeqList,
    getEcheqsPagination: ({ checks }) => checks.echeqPagination,
    getEcheqOption: ({ checks }) => checks.optionEcheq,
    getEcheqFilters: ({ checks }) => checks.echeqFilters,
    getEcheqsToDoActions: ({ checks }) => checks.echeqsToDoActions,
    getEcheqsEndorseData: ({ checks }) => checks.echeqsEndorseData,
    getEcheqsEmitData: ({ checks }) => checks.emitEcheqData,
    getEcheqsToEmit: ({ checks }) => checks.echeqsToEmit,
    getEcheqDetail: ({ checks }) => checks.echeqDetail,
    getEnvironmentCuit: ({ checks }) => checks.environmentCuit,
    getTransactionEcheqData: ({ checks }) => checks.echeqTransactionData,
    getEmitCesionData: ({ checks }) => checks.cesionEcheqData,
    isEmittedSelected: ({ checks }) => checks.isEmittedSelected,
    isDepositedSelected: ({ checks }) => checks.isDepositedSelected,
    getCheckDetailList: ({ checks }) => checks.listCheckDetail,
    getSelectedCheckDetail: ({ checks }) => checks.selectedCheckDetail,
    getCheckDetailTotalPages: ({ checks }) => checks.checkDetailTotalPages,
    getCheckDetailCurrentPageNumber: ({ checks }) => checks.checkDetailCurrentPageNumber,
    getDiscountedChecksDetailList: ({ checks }) => checks.listDiscountedChecksDetail,
    getDiscountedChecksDetailTotalPages: ({ checks }) => checks.discountedChecksDetailTotalPages,
    getDiscountedChecksDetailCurrentPageNumber: ({ checks }) => checks.discountedChecksDetailCurrentPageNumber,
    getFetchingDownload: ({ checks }) => checks.fetchingDownload,
    getConfigForm: ({ checks }) => checks.configForm,
    isGoBackEcheqs: ({ checks }) => checks.goBackEcheqs,
    getFetchingEcheqList: ({ checks }) => checks.fetchingEcheqList,
    getConcepts: ({ checks }) => checks.concepts,
    getShowForm: ({ checks }) => checks.showForm,
    getHasMoreData: ({ checks }) => checks.hasMoreData,
    getFetchingAccounts: ({ checks }) => checks.fetchingAccounts,
    getEmissionData: ({ checks }) => checks.emission,
    getServerSearchDate: ({ checks }) => checks.serverSearchDate,
    getFetchingValidateAccount: ({ checks }) => checks.fetchingValidateAccount,
    isAvailableAccount: ({ checks }) => checks.isAvailableAccount,
    getEmitMassiveEcheqData: ({ checks }) => checks.emitMassiveEcheqData,
    getRememberMassiveData: ({ checks }) => checks.rememberMassiveData,
    getEcheqBatch: ({ checks }) => checks.echeqBatch,
    getEcheqBatchDetailList: ({ checks }) => checks.batchDetail,
    getStateList: ({ checks }) => checks.statusList,
    getBatchErrors: ({ checks }) => checks.batchErrors,
    getIsAutomatedDiscount: ({ checks }) => checks.isAutomatedDiscount,
    getIdSelectorErrorState: ({ checks }) => checks.hasIdEcheqError,
    getNumberSelectorErrorState: ({ checks }) => checks.hasNumberEcheqError,
    getIdListSelector: ({ checks }) => checks.idList,
    getNumberListSelector: ({ checks }) => checks.numberList,
    getShowCleanFilterButton: ({ checks }) => checks.showCleanFilterButton,
};
