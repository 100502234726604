import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { shape, bool } from "prop-types";

import { checksDetailGetCodeMsg, getChecksDetailAccountName } from "util/checks";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";

class RowDetailInfo extends Component {
    static propTypes = {
        selectedCheckDetail: shape({}).isRequired,
        isEmittedSelected: bool.isRequired,
    };

    static defaultProps = {};

    render() {
        const { selectedCheckDetail, isEmittedSelected } = this.props;
        const bankType = isEmittedSelected ? "depositary" : "issuing";

        return (
            <Container className="flex-grow container-white my-2 with-padding" style={{ textTransform: "uppercase" }}>
                <Col className="px-0" md={12} xs={12}>
                    <Col md={3} sm={12}>
                        <Heading.DataGroup
                            dataClassName="data-desc small-font"
                            containerClassName="transfer-data data-wrapper-flex space-evenly my-2"
                            label="checksDetail.table.check"
                            data={checksDetailGetCodeMsg(selectedCheckDetail.descripcionTipoCheque) || <p> - </p>}
                        />
                    </Col>
                    <Col md={4} sm={12}>
                        <Heading.DataGroup
                            dataClassName="data-desc small-font"
                            containerClassName="transfer-data data-wrapper-flex space-evenly my-2"
                            label={`checksDetail.table.${bankType}Bank`}
                            data={
                                isEmittedSelected
                                    ? selectedCheckDetail.denominacionBancoDepositario
                                    : selectedCheckDetail.denominacionBancoLibrador || <p> - </p>
                            }
                        />
                    </Col>
                    <Col md={2} sm={12}>
                        <Heading.DataGroup
                            dataClassName="data-desc small-font"
                            containerClassName="transfer-data data-wrapper-flex space-evenly my-2"
                            label="checksDetail.table.account"
                            data={
                                getChecksDetailAccountName({ ...selectedCheckDetail, isEmittedSelected }) || <p> - </p>
                            }
                        />
                    </Col>
                    <Col md={3} sm={12}>
                        <Heading.DataGroup
                            dataClassName="data-desc small-font"
                            containerClassName="transfer-data data-wrapper-flex space-evenly my-2"
                            label="checksDetail.table.depositDate"
                            data={selectedCheckDetail.fechaDepositoCheque || <p> - </p>}
                        />
                    </Col>
                </Col>
            </Container>
        );
    }
}

export default RowDetailInfo;
