/* eslint-disable react/button-has-type */
import React from "react";

import { number } from "prop-types";

const AssociateStepperSimple = ({ currentStep, totalSteps }) => {
    const stepCount = new Array(totalSteps).fill(<></>);
    return (
        <div style={{ marginTop: "0.8rem", display: "flex", justifyContent: "center" }}>
            <ul className="slick-dots stepper-component" style={{ display: "block" }}>
                {stepCount.map((_x, idx) => (
                    <li className={currentStep === idx + 1 ? "slick-active" : ""}>
                        <button>{idx + 1}</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

AssociateStepperSimple.propTypes = {
    currentStep: number.isRequired,
    totalSteps: number,
};
AssociateStepperSimple.defaultProps = {
    totalSteps: 5,
};

export default AssociateStepperSimple;
