import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { func, arrayOf, shape, string } from "prop-types";

import { groupFormDataSelectors } from "reducers/administration";
import { actions as formActions } from "reducers/form";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class CreateGroupSignTransaction extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        idTransaction: string.isRequired,
        idActivity: string.isRequired,
    };

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, idActivity, idTransaction } = this.props;
        const paramsSign = { idForm: null, idActivity, idTransaction };
        dispatch(formActions.signTransaction({ ...paramsSign, credentials, formikBag }));
    };

    render() {
        const { credentialGroups } = this.props;

        return (
            <Fragment>
                <Head
                    title="administration.advanced.group.create.title"
                    onBack={this.handleBack}
                    closeLinkTo="/administration/groups"
                />
                <MainContainer>
                    <div className="above-the-fold">
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    credentialGroups: groupFormDataSelectors.getCredentialGroups(state),
    idTransaction: groupFormDataSelectors.getIdTransaction(state),
    idActivity: groupFormDataSelectors.getIdActivity(state),
    fetching: groupFormDataSelectors.isFetching(state),
});

export default connect(mapStateToProps)(CreateGroupSignTransaction);
