import { createReducer, makeActionCreator } from "util/redux";

const INITIAL_STATE = {
    hasBack: false,
    hasClose: false,
};

export const selectors = {
    isHasBack: ({ navigation }) => navigation.hasBack,
    isHasClose: ({ navigation }) => navigation.hasClose,
};

export const types = {
    UPDATE_HAS_BACK: `administration/navigation/UPDATE_HAS_BACK`,
    UPDATE_HAS_CLOSE: `administration/navigation/UPDATE_HAS_CLOSE`,
    RESET: `administration/navigation/RESET`,
};

export const actions = {
    updateHasClose: makeActionCreator(types.UPDATE_HAS_CLOSE, "hasClose"),
    updateHasBack: makeActionCreator(types.UPDATE_HAS_BACK, "hasBack"),
    reset: makeActionCreator(types.RESET),
};

export default createReducer(INITIAL_STATE, {
    [types.UPDATE_HAS_CLOSE]: (state, { hasClose }) => ({
        ...state,
        hasClose,
    }),
    [types.UPDATE_HAS_BACK]: (state, { hasBack }) => ({
        ...state,
        hasBack,
    }),
    [types.RESET]: (state) => ({
        ...state,
        ...INITIAL_STATE,
    }),
});
