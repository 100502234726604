import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { shape, string } from "prop-types";
import * as i18nUtils from "util/i18n";

import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";

class GroupFormDataTicketContent extends Component {
    static propTypes = {
        transactionData: shape({ data: shape({ name: string, description: string, status: string }) }).isRequired,
    };

    render() {
        const {
            transactionData: { data },
        } = this.props;
        return (
            <Container className="container--layout align-items-center flex-grow m-0 p-0" gridClassName="form-content">
                <Col sm={12} className="px-0 m-0">
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.advanced.group.create.name.label"
                        data={<p className="data-desc m-0">{data.name}</p>}
                    />

                    {data.description && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="administration.advanced.group.create.description.label"
                            data={<p className="data-desc m-0">{data.description}</p>}
                        />
                    )}
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.advanced.group.create.status.label"
                        data={
                            <p className="data-desc m-0">
                                {i18nUtils.get(`administration.advanced.group.create.status.${data.status}.label`)}
                            </p>
                        }
                    />
                </Col>
            </Container>
        );
    }
}

export default GroupFormDataTicketContent;
