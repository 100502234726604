import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { func, arrayOf, string, shape, bool } from "prop-types";
import Yup from "yup";

import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Selector from "pages/_components/fields/formik/Selector";
import TextField from "pages/_components/fields/TextField";
import Productselector from "pages/forms/_components/_fields/Productselector";
import { ReactComponent as Show } from "styles/images/search.svg";

import * as i18n from "util/i18n";
import { documentTypeFormats } from "util/debin";

const FORM_ID = "debin.recurrency";

class LoaddebinPrestacion extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        setValues: func.isRequired,
        isDesktop: bool.isRequired,
        setFieldTouched: func.isRequired,
        setErrors: func.isRequired,
        prestaciones: arrayOf(
            shape({
                nombre: string.isRequired,
            }),
        ).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        values: shape({}).isRequired,
        seller: shape({}).isRequired,
        fetching: bool.isRequired,
        debinPrestacion: shape({}).isRequired,
        concepts: arrayOf(
            shape({
                nombre: string.isRequired,
            }),
        ).isRequired,
    };

    state = {
        prestacionSeleccionada: {},
    };

    componentWillMount() {
        const { dispatch, debinPrestacion } = this.props;
        dispatch(debinActions.debinPreActivity());
        if (!debinPrestacion?.account) {
            dispatch(debinActions.cleanDebinSeller());
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    centerContentMobile = () => (
        <div>
            <h1 className="w-100 m-0">{i18n.get("debin.recurrency.load.title")}</h1>
        </div>
    );

    searchSellerCuit = () => {
        const { values, setErrors, dispatch, setFieldTouched } = this.props;
        const page = FORM_ID;
        setFieldTouched("seller", true);

        const schema = Yup.object().shape({
            seller: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
        });

        schema
            .validate(values)
            .then(() => {
                if (!documentTypeFormats("CUIT", values.seller)) {
                    setErrors({ seller: i18n.get(`${FORM_ID}.incorrect.format`) });
                } else {
                    dispatch(debinActions.debinSeller(values.seller, page));
                }
            })
            .catch((err) => {
                setErrors({ [err.path]: err.message });
            });
    };

    searchButton = () => (
        <Button className="btn-only-icon" bsStyle="link" block={false} onClick={this.searchSellerCuit}>
            <Show className="svg-image" />
        </Button>
    );

    setPrestacion = (selected) => {
        const { prestaciones } = this.props;

        const prestacion = prestaciones.find((object) => object.nombre === selected);

        this.setState({ prestacionSeleccionada: prestacion });
    };

    enabledButton = () => {
        const { values } = this.props;
        if (document.getElementById("btnContinue") !== null) {
            if (
                values.account !== "" &&
                values.concept !== "" &&
                values.prestacion !== "" &&
                values.reference !== "" &&
                values.seller !== ""
            ) {
                document.getElementById("btnContinue").disabled = false;
            } else {
                document.getElementById("btnContinue").disabled = true;
            }
        }
    };

    render() {
        const { isDesktop, accounts, values, seller, fetching, prestaciones, setValues, concepts } = this.props;
        const { prestacionSeleccionada } = this.state;
        let optionsPrestaciones = [];
        prestaciones.forEach((prestacion) => {
            optionsPrestaciones = [...optionsPrestaciones, { value: prestacion.nombre, label: prestacion.nombre }];
        });

        if (prestaciones.length === 0 && values.reference !== "") {
            setValues({ ...values, reference: "" });
            this.setState({ prestacionSeleccionada: {} });
        } else if (prestacionSeleccionada?.nombre && values.reference !== prestacionSeleccionada?.ayuda_referencia) {
            setValues({ ...values, reference: prestacionSeleccionada?.ayuda_referencia });
        }

        return (
            <div className="debin-recurrencies__add">
                <Notification scopeToShow="debin" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? null : this.centerContentMobile}
                    />
                    {isDesktop && <Head title="debin.recurrency.load.title" />}
                </div>
                <MainContainer viewContentClassName="overflow-visible">
                    <div className="above-the-fold">
                        <Form autoComplete="off">
                            <Container
                                className="flex-grow align-items-center container-white my-2 py-2"
                                gridClassName="form-content">
                                <Col sm={12} md={9} lg={6} xl={7} className="load-debin-recurrency-form">
                                    <Field
                                        name="account"
                                        component={Productselector}
                                        idField="account"
                                        data={{
                                            emptyOptionLabel: "",
                                            options: accounts,
                                        }}
                                        disabled={accounts.length === 0}
                                        isRequired
                                        mode="edit"
                                        value={values?.account}
                                        idForm={FORM_ID}
                                        isDesktop={isDesktop}
                                        customPlaceholder={i18n.get("transfers.productSelector.placeholder")}
                                    />
                                </Col>
                            </Container>
                            {isDesktop ? (
                                <Container
                                    className="flex-grow align-items-center container-white my-2 py-2"
                                    gridClassName="form-content">
                                    <Col sm={12} md={9} lg={6} xl={7} className="px-0 load-debin-recurrency-form">
                                        <Col xs={12} md={8}>
                                            <Field
                                                component={TextField}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="seller"
                                                maxLength="150"
                                                type="text"
                                                onKeyDown={this.searchSellerCuit}
                                            />
                                        </Col>

                                        <Col xs={12} md={4} className="pt-md-25">
                                            <Button
                                                bsStyle="primary"
                                                label={`${FORM_ID}.search`}
                                                onClick={this.searchSellerCuit}
                                                loading={fetching}
                                                className="mt-2 mt-md-4"
                                            />
                                        </Col>
                                    </Col>
                                </Container>
                            ) : (
                                <Container
                                    className="flex-grow align-items-center container-white my-2 py-2"
                                    gridClassName="form-content">
                                    <Col sm={12} md={9} lg={6} className="px-3 ">
                                        <Field
                                            component={TextField}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="seller"
                                            maxLength="150"
                                            type="text"
                                            inputmode="numeric"
                                            onKeyDown={this.searchSellerCuit}
                                            inputFunctions={this.searchButton()}
                                        />
                                    </Col>
                                </Container>
                            )}
                            <Container
                                className="flex-grow align-items-center container-white my-2 py-2"
                                gridClassName="form-content">
                                <Col sm={12} md={9} lg={6} xl={7} className="px-0 load-debin-recurrency-form">
                                    <Col xs={12} className="py-2">
                                        <div>
                                            <span className="data-label">{i18n.get(`${FORM_ID}.business.name`)}</span>
                                            <span className="data-desc pl-2">{seller?.titular}</span>
                                        </div>

                                        <Col xs={12} md={6} className="px-0 pl-md-0 pr-md-3">
                                            <Field
                                                component={Selector}
                                                hidePlaceholder
                                                options={optionsPrestaciones}
                                                idForm={FORM_ID}
                                                name="prestacion"
                                                isRequired
                                                customOnChange
                                                onCustomChange={(selected) => this.setPrestacion(selected)}
                                                formGroupClassName="mb-1 mt-md-2"
                                                isDisabled={!seller?.titular}
                                            />
                                        </Col>

                                        <Col xs={12} md={6} className="px-0 pr-md-0 pl-md-3">
                                            <Field
                                                component={TextField}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="reference"
                                                handleOnChange={this.enabledButton()}
                                                disabled
                                                maxLength="150"
                                                type="text"
                                                formGroupClassName="mb-1 mt-md-2"
                                                isDisabled={!seller?.titular}
                                            />
                                        </Col>
                                    </Col>
                                    <Col xs={12}>
                                        <Col xs={12} md={6} className="px-0 pl-md-0 pr-md-3">
                                            <Field
                                                component={Selector}
                                                hidePlaceholder
                                                options={concepts.map(({ id, name }) => ({
                                                    value: id,
                                                    label: `${id} - ${name}`,
                                                }))}
                                                idForm={FORM_ID}
                                                name="concept"
                                                isRequired
                                                formGroupClassName="mb-1 mt-md-2"
                                                isDisabled={!seller?.titular}
                                            />
                                        </Col>

                                        <Col xs={12} md={6} className="px-0 pr-md-0 pl-md-3">
                                            <Field
                                                component={TextField}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="description"
                                                handleOnChange={this.enabledButton()}
                                                minLength={prestacionSeleccionada?.min || 0}
                                                maxLength={prestacionSeleccionada?.max || 0}
                                                type="text"
                                                formGroupClassName="mb-1 mt-md-2"
                                                isDisabled={!seller?.titular}
                                            />
                                        </Col>
                                    </Col>
                                </Col>
                            </Container>
                            <Container className="flex-grow align-items-center" gridClassName="form-content">
                                <Col sm={12} md={4} lg={4} xl={4}>
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.continue"
                                        loading={fetching}
                                        id="btnContinue"
                                        disabled
                                        className="my-4"
                                    />
                                </Col>
                            </Container>
                        </Form>
                    </div>
                </MainContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: debinSelectors.getAccounts(state),
    seller: debinSelectors.getSeller(state),
    prestaciones: debinSelectors.getPrestaciones(state),
    fetching: debinSelectors.getFetching(state),
    debinPrestacion: debinSelectors.getdebinPrestacionData(state),
    concepts: debinSelectors.getConcepts(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            account: props.debinPrestacion?.account || "",
            concept: props.debinPrestacion?.concept || "VAR",
            seller: props.debinPrestacion?.seller?.cuit || "",
            reference: props.debinPrestacion?.reference || "",
            description: props.debinPrestacion?.description || "",
            prestacion: props.debinPrestacion?.prestacion || "",
        }),
        validationSchema: () =>
            Yup.lazy(() => {
                const validate = {
                    account: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                    concept: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                    reference: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                    prestacion: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                    description: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                };

                return Yup.object().shape(validate);
            }),

        handleSubmit: (form, formikBag) => {
            const { props, setErrors } = formikBag;
            const { dispatch, prestaciones, seller, accounts } = props;
            const prestacionSeleccionada = prestaciones.find((object) => object.nombre === form.prestacion);
            const selectedAccount = accounts.find((object) => object.idProduct === form.account);
            if (form.description !== "" && form.description.length < prestacionSeleccionada.min) {
                setErrors({
                    description: i18n.get(`${FORM_ID}.min.length.is`).concat(" ", prestacionSeleccionada.min),
                });
            } else {
                dispatch(
                    debinActions.setdebinPrestacionData({ ...form, selectedAccount, prestacionSeleccionada, seller }),
                );
                dispatch(push("/payments/recurrent/load/confirm"));
            }
        },
    }),
)(LoaddebinPrestacion);
