/* eslint-disable no-return-assign */
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { string, shape, objectOf, func } from "prop-types";
import { selectors, actions as adminActions } from "reducers/administration/common/administrationTicket";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";

import Heading from "pages/_components/Heading";

class UserInviteTicket extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        transactionData: shape({
            data: objectOf(string),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    state = {
        chargedData: false,
    };

    componentDidMount() {
        this.generateExtraData();
    }

    renderMediumSchemeConfiguration = () => {
        const { transactionData } = this.props;

        let signatureKeyLabel = "administration.users.invite.transaction.signature.yes";
        if (transactionData.data.signatureLevel === "N") {
            signatureKeyLabel = "administration.users.invite.transaction.signature.no";
        }
        return (
            <>
                <Col xs={12} className="px-0">
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.users.invite.transaction.signature"
                        data={
                            <p className="data-desc m-0">
                                <I18n id={signatureKeyLabel} />
                            </p>
                        }
                    />
                </Col>

                <Col xs={12} className="px-0">
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.users.invite.roles.label"
                        data={
                            <p className="data-desc m-0">
                                <I18n id={`invitation.permissions.role.${transactionData.data.role}`} />
                            </p>
                        }
                    />
                </Col>
            </>
        );
    };

    chargeDataAdvanced = () => {
        const {
            dispatch,
            transactionData: { data },
        } = this.props;
        const { chargedData } = this.state;
        if (!chargedData) {
            this.setState({ chargedData: true });
            const signatureLabel =
                data.signatureLevel === "N"
                    ? i18nUtils.get("administration.users.edit.signatureLevel.dontSign")
                    : data.signatureLevel;

            let groupText = "";

            // eslint-disable-next-line no-unused-expressions
            data?.groupNames?.forEach((groupName) => (groupText = groupText.concat(groupName, "\n")));

            const extraData = {
                signature: {
                    label: "administration.users.edit.signatureLevel",
                    value: signatureLabel,
                },
                group: {
                    label: "administration.users.edit.groups",
                    value: groupText,
                },
            };

            dispatch(adminActions.setExtraData(extraData));
        }
    };

    generateExtraData() {
        const {
            dispatch,
            transactionData,
            activeEnvironment: { administrationScheme },
        } = this.props;

        const mediumScheme = administrationScheme === "medium";

        let signatureKeyLabel = "administration.users.invite.transaction.signature.yes";
        if (transactionData?.data?.signatureLevel === "N") {
            signatureKeyLabel = "administration.users.invite.transaction.signature.no";
        }

        const signatureLabel =
            transactionData?.data?.signatureLevel === "N"
                ? i18nUtils?.get("administration.users.edit.signatureLevel.dontSign")
                : transactionData?.data?.signatureLevel;

        const groupText = transactionData?.data?.groupNames?.reduce(
            (acc, groupName) => acc?.concat(groupName)?.concat("\n"),
            "",
        );

        const schemeData = mediumScheme
            ? {
                  data1: {
                      label: "administration.users.invite.transaction.signature",
                      value: i18nUtils.get(signatureKeyLabel),
                  },
                  data2: {
                      label: "administration.users.invite.roles.label",
                      value: i18nUtils.get(`invitation.permissions.role.${transactionData.data.role}`),
                  },
              }
            : {
                  data1: {
                      label: "administration.users.edit.signatureLevel",
                      value: signatureLabel,
                  },
                  data2: {
                      label: "administration.users.edit.groups",
                      value: groupText,
                  },
              };

        const extraData = {
            ...schemeData,
        };

        dispatch(adminActions.setExtraData(extraData));
    }

    renderAdvancedSchemeConfiguration = () => {
        const {
            transactionData: { data },
        } = this.props;
        this.chargeDataAdvanced();
        const signatureLabel =
            data.signatureLevel === "N"
                ? i18nUtils.get("administration.users.edit.signatureLevel.dontSign")
                : data.signatureLevel;
        const groupText = data?.groupNames?.map((groupName, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
                <span> {groupName} </span> <br />
            </Fragment>
        ));
        return (
            <>
                <Col xs={12} className="px-0">
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.users.edit.signatureLevel"
                        data={<p className="data-desc m-0">{signatureLabel}</p>}
                    />
                </Col>
                <Col xs={12} className="px-0">
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.users.edit.groups"
                        data={<p className="data-desc m-0">{groupText}</p>}
                    />
                </Col>
            </>
        );
    };

    render() {
        const {
            transactionData,
            activeEnvironment: { administrationScheme },
        } = this.props;
        if (transactionData.data) {
            return (
                <>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="administration.users.invite.firstName.label"
                            data={<p className="data-desc m-0">{transactionData.data.firstName}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="administration.users.invite.lastName.label"
                            data={<p className="data-desc m-0">{transactionData.data.lastName}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="administration.users.invite.email.label"
                            data={<p className="data-desc m-0">{transactionData.data.email}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="administration.users.invite.mobilePhone.label"
                            data={<p className="data-desc m-0">{transactionData?.data?.mobilePhone}</p>}
                        />
                    </Col>
                    {administrationScheme === "medium"
                        ? this.renderMediumSchemeConfiguration()
                        : this.renderAdvancedSchemeConfiguration()}
                    {transactionData?.data?.cardCode && (
                        <Col xs={12} className="px-0">
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="transfers.cvu.code.label"
                                data={<p className="data-desc m-0">{transactionData?.data?.cardCode}</p>}
                            />
                        </Col>
                    )}

                    {transactionData?.data?.cardErrorDescription && (
                        <Col xs={12} className="px-0">
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="transfers.cvu.response.label"
                                data={<p className="data-desc m-0">{transactionData?.data?.cardErrorDescription}</p>}
                            />
                        </Col>
                    )}
                </>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(UserInviteTicket);
