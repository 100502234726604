import React from "react";
import { Col } from "react-bootstrap";
import { bool, func } from "prop-types";
import classNames from "classnames";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";

import * as config from "util/config";

function SelectFund({ isDesktop, openModal, haveFundSelected }) {
    const orderFuncionality = config.getBoolean("backend.show.managementFunds.functionalities", false);

    return (
        <Col sm={12} className="align-items-center d-flex space-between w-100 px-md-3">
            {isDesktop && (
                <div className="d-flex align-items-center">
                    <I18n id="fund.operation.subscribe.selectFund.label" componentProps={{ className: "data-label" }} />
                </div>
            )}
            <div className={classNames({ "w-100": !isDesktop }, { "cmf-button-width": isDesktop })}>
                <Button
                    image={orderFuncionality ? "images/funds-blue.svg" : ""}
                    imageStyle="mr-2"
                    label={
                        orderFuncionality
                            ? `fundcorp.suscribe.drawer.button.${haveFundSelected ? "change" : "select"}`
                            : "fund.operation.subscribe.selectFund.button.label"
                    }
                    className={classNames("btn-outline", { "text-transform-none": orderFuncionality })}
                    onClick={openModal}
                    disabled={haveFundSelected && !orderFuncionality}
                />
            </div>
        </Col>
    );
}

SelectFund.propTypes = {
    isDesktop: bool.isRequired,
    openModal: func.isRequired,
    disabled: bool,
};

SelectFund.defaultProps = {
    disabled: false,
};

export default SelectFund;
