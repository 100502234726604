/* eslint-disable react/no-danger */
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { Redirect } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { arrayOf, bool, func, number, oneOfType, shape, string } from "prop-types";
import Yup from "yup";
import { connect } from "react-redux";

import { actions as userActions, selectors as userSelectors } from "reducers/administration/users";

import * as arrayUtils from "util/array";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import { resizableRoute } from "pages/_components/Resizable";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import FeatureFlag from "pages/_components/FeatureFlag";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import MultiSelect from "pages/_components/fields/formik/MultiSelect";
import Selector from "pages/_components/fields/formik/Selector";
import StepperFieldGroup from "pages/_components/fields/StepperFieldGroup";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import SectionTitle from "pages/_components/SectionTitle";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import ShowCurrency from "./ShowCurrency";

const FORM_ID = "administration.signatures.modify";

class SignaturesSchemeModify extends Component {
    static propTypes = {
        actions: shape({
            modifySignaturesSchemePreRequest: func,
        }),
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        capFrequencyList: arrayOf(string),
        caps: shape({
            all: shape({
                channel: string,
                frequency: string,
                idEnvironment: number,
                idSignature: number,
                maximum: number,
                used: number,
                usedLastReset: string,
            }),
        }),
        fetching: bool,
        functionalGroups: arrayOf(
            shape({
                idFeature: string,
            }),
        ),
        isDesktop: bool.isRequired,
        masterCurrency: string,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }),
        routerActions: shape({
            goBack: func,
            push: func,
        }),
        signature: shape({
            signatureType: string,
            signatureDispatch: bool,
        }),
        signatureGroupMap: oneOfType([
            shape({
                A: number,
            }),
            shape(
                configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                    const result = res;
                    result[signLevel] = number;

                    return result;
                }, {}),
            ),
        ]),
        signatureTypeList: arrayOf(string),
        selectedFunctionalGroups: arrayOf(string),
        environmentProducts: arrayOf(Object),
        dispatch: func.isRequired,
        fetchingExport: bool.isRequired,
    };

    static defaultProps = {
        actions: null,
        capFrequencyList: [],
        caps: null,
        fetching: false,
        functionalGroups: [],
        masterCurrency: configUtils.get("core.masterCurrency"),
        match: null,
        routerActions: null,
        signature: null,
        signatureGroupMap: null,
        signatureTypeList: null,
        selectedFunctionalGroups: [],
        environmentProducts: [],
    };

    componentDidMount() {
        const {
            actions,
            match: {
                params: { idSignature },
            },
        } = this.props;

        actions.modifySignaturesSchemePreRequest(idSignature);
    }

    handleSubmit = ({
        functionalGroups,
        signatureType,
        signatureLevelsCounts,
        toggleFunctionalGroups,
        toggleProducts,
        selectedProducts,
        topAmount,
        toggleDispatchControl,
    }) => {
        const {
            actions,
            credentialGroups,
            activeEnvironment: { administrationScheme },
            match: {
                params: { idSignature },
            },
            routerActions,
        } = this.props;

        actions.modifySignaturesSchemeConfirmPre(
            {
                selectedFunctionalGroups: toggleFunctionalGroups ? functionalGroups : [],
                signatureId: idSignature,
                signatureLevelsCounts,
                selectedProducts: toggleProducts ? selectedProducts : [],
                signatureType,
                topAmount,
                signatureDispatch: toggleDispatchControl,
            },
            credentialGroups,
        );

        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/${idSignature}/confirm`);
    };

    renderContent = () => {
        const {
            activeEnvironment: { administrationScheme, type },
            caps,
            signature,
            signatureGroupMap,
            selectedFunctionalGroups,
        } = this.props;

        let signatureLevelsCounts = {};
        if (administrationScheme === "advanced") {
            signatureLevelsCounts = configUtils.getArray("administration.signatures.signatureLevels").reduce(
                (acc, signLevel) => ({
                    ...acc,
                    [signLevel]: !Object.keys(signatureGroupMap).includes(signLevel) ? 0 : signatureGroupMap[signLevel],
                }),
                signatureLevelsCounts,
            );
        } else {
            signatureLevelsCounts = signatureGroupMap;
        }

        let topAmount = {
            amount: "",
            period: configUtils.get("administration.signatures.topAmount.defaultFrequency"),
        };
        if (caps.all) {
            const { frequency, maximum } = caps.all;

            let amount = "";
            if (signature.signatureType === "AMOUNT") {
                if (maximum !== -1) {
                    amount = maximum;
                } else {
                    amount = configUtils.get(`default_cap_signature_${type}`);
                }
            }

            topAmount = {
                amount,
                period: frequency,
            };
        }

        const initialValues = {
            functionalGroups: selectedFunctionalGroups,
            signatureType: signature.signatureType,
            signatureLevelsCounts,
            toggleFunctionalGroups: selectedFunctionalGroups.length > 0,
            toggleProducts: signature.products && signature.products.length > 0,
            topAmount,
            toggleDispatchControl: signature.signatureDispatch,
            selectedProducts: signature.products,
        };

        return (
            <Fragment>
                <MainContainer>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={this.validationSchema}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </Fragment>
        );
    };

    renderForm = ({ isSubmitting, values }) => {
        const {
            activeEnvironment,
            capFrequencyList,
            caps,
            functionalGroups,
            environmentProducts,
            masterCurrency,
            signatureGroupMap,
            signatureTypeList,
        } = this.props;
        const { administrationScheme } = activeEnvironment;
        const { signatureType, toggleFunctionalGroups, toggleProducts } = values;

        return (
            <Form className="above-the-fold">
                <Container className="flex-grow align-items-center cmf-container-white" gridClassName="form-content">
                    {administrationScheme === "advanced" ? (
                        signatureGroupMap && this.renderRequiredSignatures(administrationScheme)
                    ) : (
                        <Col className="col col-12">{this.renderRequiredSignatures(administrationScheme)}</Col>
                    )}
                </Container>
                <Container className="flex-grow align-items-center" gridClassName="form-content">
                    <Col className="col col-12 p-0">
                        <FeatureFlag id="feature.signatureSchema.dispatchControl">
                            <SectionTitle
                                title="details.administration.dispatcher.label"
                                subTitle="administration.signatures.dispatcher"
                            />
                        </FeatureFlag>
                    </Col>
                </Container>
                <Container
                    className="flex-grow align-items-center cmf-container-white"
                    gridClassName="form-content py-3">
                    <Col className="col col-12 mb-0-switch">
                        <Field component={SwitchField} idForm={FORM_ID} name="toggleDispatchControl" />
                    </Col>
                </Container>
                {signatureTypeList &&
                    this.renderTransactions(
                        capFrequencyList,
                        caps,
                        functionalGroups,
                        masterCurrency,
                        toggleFunctionalGroups,
                        toggleProducts,
                        environmentProducts,
                        signatureTypeList,
                        signatureType && signatureType === "AMOUNT",
                    )}
                <Container className="container--layout align-items-center mt-5" gridClassName="form-content">
                    <Col sm={12} md={4} className="col col-12">
                        <Button bsStyle="primary" label="global.continue" loading={isSubmitting} type="submit" />
                    </Col>
                </Container>
            </Form>
        );
    };

    renderFunctionalGroups = (functionalGroups, showFunctionalGroups) => {
        const labelFunctionalGroups = "administration.signatures.functionalGroups";
        return (
            <Fragment>
                <Field component={SwitchField} idForm={FORM_ID} name="toggleFunctionalGroups" />
                {showFunctionalGroups && (
                    <Col sm={12} md={9} className="col col-12 px-0">
                        <div className="d-flex justify-content-start max-width-600">
                            <Col className="col col-9">
                                <MultiSelect
                                    label="administration.signatures.functionalGroups.label"
                                    name="functionalGroups"
                                    options={arrayUtils.mapItemsIds(
                                        functionalGroups
                                            .map((functionalGroup) => ({
                                                label: i18nUtils.get(
                                                    `${labelFunctionalGroups}.${functionalGroup.idFeature}`,
                                                ),
                                                value: functionalGroup.idFeature,
                                            }))
                                            .sort((fGroupA, fGroupB) => fGroupA.label.localeCompare(fGroupB.label)),
                                        "value",
                                    )}
                                    placeholder="administration.signatures.functionalGroups.add">
                                    {({ label }) => (
                                        <span className="Select-value-label cmf-multi-select-label">{label}</span>
                                    )}
                                </MultiSelect>
                            </Col>
                        </div>
                    </Col>
                )}
            </Fragment>
        );
    };

    renderProducts = (environmentProducts, toggleProducts) => (
        <Fragment>
            <Field component={SwitchField} idForm={FORM_ID} name="toggleProducts" />
            {toggleProducts && (
                <Col sm={12} md={9} className="col col-12 px-0">
                    <div className="d-flex justify-content-start max-width-600">
                        <Col className="col col-9">
                            <MultiSelect
                                label="administration.signatures.products.label"
                                name="selectedProducts"
                                options={arrayUtils.mapItemsIds(
                                    environmentProducts.map((product) => ({
                                        label: product.label,
                                        value: product.idProduct,
                                    })),
                                    "value",
                                )}
                                placeholder="administration.signatures.products.add">
                                {({ label }) => (
                                    <span className="Select-value-label cmf-multi-select-label">{label}</span>
                                )}
                            </MultiSelect>
                        </Col>
                    </div>
                </Col>
            )}
        </Fragment>
    );

    renderRequiredSignatures = (administrationScheme) => (
        <Fragment>
            <div
                className={
                    administrationScheme === "advanced"
                        ? "form-section-title admin-content-center"
                        : "form-section-title "
                }>
                <h2>
                    <I18n id="administration.signatures.modify.requiredSignatures" />
                </h2>
            </div>
            <div className={administrationScheme === "advanced" ? "admin-content-center" : ""}>
                <p>
                    <I18n id={`administration.signatures.modify.${administrationScheme}.signersLevel`} />
                </p>
            </div>

            <div className="form-group form-group--stepper-field">
                <div className="control-label form-group-text">
                    <I18n id={`administration.signatures.modify.${administrationScheme}.signersCount`} />
                </div>

                <Field
                    component={StepperFieldGroup}
                    fieldClassName="signature-scheme-modify-count"
                    hideLabel={administrationScheme === "medium"}
                    idForm={FORM_ID}
                    name="signatureLevelsCounts"
                    min={administrationScheme === "medium" ? "1" : "0"}
                    max={configUtils.get("administration.signatures.maxNeeded")}
                    options={
                        administrationScheme === "medium"
                            ? ["A"]
                            : configUtils.getArray("administration.signatures.signatureLevels")
                    }
                    showFirstErrorWhenEquals
                />
            </div>
        </Fragment>
    );

    renderTransactions = (
        capFrequencyList,
        caps,
        functionalGroups,
        masterCurrency,
        showFunctionalGroups,
        toggleProducts,
        environmentProducts,
        signatureTypeList,
        transactionWithAmount,
    ) => (
        <Fragment>
            <Container className="flex-grow align-items-center" gridClassName="form-content">
                <Col className="col col-12 px-0">
                    <SectionTitle
                        title="administration.signatures.modify.transactions"
                        subTitle="administration.signatures.modify.transactions.subtitle"
                    />
                </Col>
            </Container>
            <Container
                className="container--layout flex-grow align-items-center cmf-container-white"
                rowClassName="justify-content-start"
                gridClassName={`form-content ${
                    transactionWithAmount && masterCurrency && capFrequencyList ? "py-0" : "py-4"
                }`}>
                <Col
                    md={transactionWithAmount && masterCurrency && capFrequencyList ? 3 : 12}
                    className="px-0 d-flex align-items-center">
                    <div className="form-group">
                        <div className="form-group-control-list my-0 mb-0-fieldset">
                            <Field
                                component={Selector}
                                idForm={FORM_ID}
                                name="signatureType"
                                options={signatureTypeList.map((signatureType) => ({
                                    id: signatureType,
                                    label: i18nUtils.get(
                                        `administration.signatures.modify.signatureType.${signatureType}`,
                                    ),
                                }))}
                                renderAs="radio"
                            />
                        </div>
                    </div>
                </Col>
                {transactionWithAmount && masterCurrency && capFrequencyList && caps && (
                    <Col sm={12} md={9} className="col col-12 max-width-500">
                        <ShowCurrency
                            masterCurrency={masterCurrency}
                            capFrequencyList={capFrequencyList}
                            form={FORM_ID}
                        />
                    </Col>
                )}
            </Container>

            <Container
                className="flex-grow align-items-center cmf-container-white"
                gridClassName="form-content mb-2 py-3">
                <Col className="col col-12 d-flex align-items-start mb-0-switch">
                    {functionalGroups && this.renderFunctionalGroups(functionalGroups, showFunctionalGroups)}
                </Col>
            </Container>
            <Container className="flex-grow align-items-center cmf-container-white" gridClassName="form-content py-3">
                <Col className="col col-12 d-flex align-items-start mb-0-switch">
                    {environmentProducts && this.renderProducts(environmentProducts, toggleProducts)}
                </Col>
            </Container>
        </Fragment>
    );

    validationSchema = () => {
        const {
            activeEnvironment: { administrationScheme },
        } = this.props;
        const maxSignersCount = configUtils.get("administration.signatures.maxNeeded");

        return Yup.lazy((values) =>
            Yup.object().shape({
                functionalGroups: values.toggleFunctionalGroups
                    ? Yup.array()
                          .of(Yup.string())
                          .min(1, i18nUtils.get("administration.signatures.functionalGroups.atLeastOne"))
                    : Yup.array().notRequired(),

                selectedProducts: values.toggleProducts
                    ? Yup.array()
                          .of(Yup.string())
                          .min(1, i18nUtils.get("administration.signatures.environmentProducts.atLeastOne"))
                    : Yup.array().notRequired(),
                signatureLevelsCounts: Yup.object().shape(
                    administrationScheme === "medium"
                        ? {
                              A: Yup.number()
                                  .moreThan(
                                      0,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .max(
                                      maxSignersCount,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .required(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`))
                                  .typeError(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`)),
                          }
                        : configUtils.getArray("administration.signatures.signatureLevels").reduce((levels, level) => {
                              const newLevels = levels;
                              newLevels[level] = Object.values(values.signatureLevelsCounts).some((value) => value)
                                  ? Yup.number()
                                        .min(
                                            0,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .max(
                                            maxSignersCount,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .required(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                        .typeError(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                  : Yup.number().moreThan(0, i18nUtils.get(`${FORM_ID}.signersCount.atLeastOne`));
                              return newLevels;
                          }, {}),
                ),
                signatureType: Yup.string().required(i18nUtils.get(`${FORM_ID}.signatureType.medium.required`)),
                topAmount: Yup.object().when("signatureType", {
                    is: (signatureType) => signatureType === "AMOUNT",
                    then: Yup.object().shape({
                        amount: Yup.number()
                            .moreThan(0, i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.mustBePositive`))
                            .required(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`))
                            .typeError(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`)),
                        period: Yup.string().required(),
                    }),
                    otherwise: Yup.object().notRequired(),
                }),
            }),
        );
    };

    renderDrawerContent = (drawerText) => {
        const { isDesktop } = this.props;
        return (
            <MainContainer>
                <Container className="container--layaut flex-grow align-items-center">
                    <div className="transfer-block">
                        <div className="scrollable-content" style={{ marginBottom: "0", height: !isDesktop && "55vh" }}>
                            <p dangerouslySetInnerHTML={{ __html: drawerText }} />
                        </div>
                    </div>
                </Container>
            </MainContainer>
        );
    };

    handleDownload = () => {
        const { dispatch } = this.props;
        dispatch(userActions.exportAdministrationSignatureHelpRequest("pdf"));
    };

    render() {
        const {
            fetching,
            isDesktop,
            signatureGroupMap,
            activeEnvironment: { administrationScheme },
            fetchingExport,
        } = this.props;

        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        return (
            <Fragment>
                <Notification scopeToShow="administrationModifySignatureScheme" />
                <div className="admin-detail-head admin-detail-head-section px-0">
                    <Head backLinkTo={`/administration/${administrationScheme}/signaturesSchemes`} />
                    <div className="view-title d-flex w-100">
                        <h1>{i18nUtils.get("administration.signatures.modify.title")}</h1>
                        <span className="align-self-center ml-3">
                            <ButtonDrawer
                                image="images/help-outline.svg"
                                buttonClassName="drawer-button-image button-without-margin button-without-padding btn-info-signature-scheme mh-auto"
                                headerClassName="mt-4"
                                content={this.renderDrawerContent(
                                    i18nUtils.get(`administration.signatures.help.content.${administrationScheme}`),
                                )}
                                width="560px"
                                navStyle="default p-0"
                                itemRightClassName="align-self-start min-width-auto"
                                headerContent={
                                    <div className="terms-coditions-header">
                                        <Col xs={12} className="px-0 terms-conditions-buttons">
                                            <div className="tc-download-btn">
                                                <Button
                                                    className="btn btn-link btn-noBlock mr-3"
                                                    onClick={this.handleDownload}
                                                    image="images/download.svg"
                                                    label="global.download"
                                                    style={{ padding: "0.25rem" }}
                                                    loading={fetchingExport}
                                                />
                                            </div>
                                            <Col className="form-section-title mx-3">
                                                <I18n component="h3" id="administration.signatures.help.title" />
                                            </Col>
                                        </Col>
                                    </div>
                                }
                                closeClassname="mh-auto w-auto justify-content-end flex px-0 my-1"
                                flex
                                side
                                customHeight
                            />
                        </span>
                    </div>

                    <p className="regular-font text-lead">
                        <I18n id={`administration.signatures.modify.${administrationScheme}.subtitle`} />
                    </p>
                </div>

                <PageLoading loading={fetching}>{signatureGroupMap && this.renderContent()}</PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetchingExport: userSelectors.isFetchingExport(state),
});

export default connect(mapStateToProps)(resizableRoute(SignaturesSchemeModify));
