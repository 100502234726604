/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";

class MessageActions extends Component {
    static propTypes = {
        communication: shape({
            direction: string,
            idCommunication: number,
            idFileList: arrayOf(number),
            userRead: bool,
        }).isRequired,
        handleChangeMessageStatus: func.isRequired,
        handleRemoveClick: func.isRequired,
        index: number.isRequired,
    };

    render() {
        const { communication, handleChangeMessageStatus, handleRemoveClick, index } = this.props;

        return (
            <>
                <div className="icons">
                    <div className="icon">
                        {communication.idFileList.length > 0 && (
                            <>
                                <Image src="images/attachment.svg" styles={{ color: "#0071ce" }} />
                                <I18n
                                    id="communications.hasAttachment"
                                    componentProps={{ className: "visually-hidden" }}
                                />
                            </>
                        )}
                    </div>

                    <Button
                        className="btn-only-icon pr-2"
                        block={false}
                        onClick={(e) =>
                            handleChangeMessageStatus(e, communication.idCommunication, communication.userRead, index)
                        }
                        bsStyle="circle">
                        <Image
                            className="message__read-icon"
                            src={communication.userRead ? "images/read.svg" : "images/email.svg"}
                            styles={{ color: "#0071ce" }}
                        />
                        <I18n
                            id={!communication.userRead ? "communications.unreadMessage" : "communications.readMessage"}
                        />
                    </Button>

                    <Button
                        className="btn-only-icon"
                        block={false}
                        onClick={(e) => handleRemoveClick(e, communication.idCommunication, index)}
                        image="images/delete-message.svg"
                        bsStyle="circle">
                        <Image src="images/delete-message.svg" styles={{ color: "#0071ce" }} />
                        <I18n id="communications.deleteMessage" />
                    </Button>
                </div>
            </>
        );
    }
}

export default MessageActions;
