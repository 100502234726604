/* eslint-disable import/no-unresolved */
import React from "react";
import { bool, func } from "prop-types";
import { Modal } from "react-bootstrap";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { isDesktop } from "react-device-detect";

function ModalMessage({ isVisible, cancelClick, submitClick }) {
    return (
        <>
            {isDesktop ? (
                <div className="modal-container">
                    <Modal show={isVisible} className="messages__modal" dialogClassName="messages__modal">
                        <Modal.Header className="mt-0">
                            <Image src="images/delete-confirm.svg" />
                            <Modal.Title componentClass="div" className="mt-4">
                                <I18n
                                    id="communications.deleteMessage.modal"
                                    component="h3"
                                    componentProps={{ className: "messages__text-title my-0" }}
                                />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Footer className="my-0 w-100">
                            <div className="d-flex w-100 mt-4">
                                <Button className="btn-outline my-0 mr-4" label="global.cancel" onClick={cancelClick} />
                                <Button className="btn-primary my-0" label="global.accept" onClick={submitClick} />
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            ) : (
                <div className="modal-container">
                    <Modal show={isVisible} className="messages__modal" dialogClassName="messages__modal">
                        <Modal.Header className="mt-0 pt-4">
                            <Button className="messages__dropdown-btn" onClick={cancelClick}>
                                <Image src="images/rectangle-dropdown.svg" />
                            </Button>
                            <Image className="mt-5" src="images/delete-confirm.svg" />
                            <Modal.Title componentClass="div" className="mt-3 px-4">
                                <I18n
                                    id="communications.deleteMessage.modal"
                                    component="h3"
                                    componentProps={{ className: "messages__text-title my-0" }}
                                />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Footer className="my-0 w-100">
                            <div className="d-flex flex-column my-5 px-4">
                                <Button className="btn-primary" label="global.accept" onClick={submitClick} />
                                <Button className="btn-outline mb-3" label="global.cancel" onClick={cancelClick} />
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </>
    );
}

ModalMessage.propTypes = {
    isVisible: bool.isRequired,
    cancelClick: func.isRequired,
    submitClick: func.isRequired,
};

export default ModalMessage;
