import React from "react";
import { Field } from "formik";
import { string, shape, number, func, arrayOf, bool } from "prop-types";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import AmountField from "pages/_components/fields/formik/AmountField";
import * as i18nUtils from "util/i18n";
import FormattedAmount from "pages/_components/FormattedAmount";

import I18n from "pages/_components/I18n";

function AgendaConceptWithoutBaseDebt({ agendaPago, currencies, isDesktop, FORM_ID }) {
    AgendaConceptWithoutBaseDebt.propTypes = {
        agendaPago: func.isRequired,
        currencies: arrayOf(shape({ id: number, label: string })),
        isDesktop: bool,
        FORM_ID: string.isRequired,
    };

    AgendaConceptWithoutBaseDebt.defaultProps = {
        currencies: [{ id: 0, label: "ARS" }],
        isDesktop: false,
    };

    const { concept } = agendaPago;

    return isDesktop ? (
        <>
            <Row className="mx-0 d-flex justify-content-center align-items-center">
                {concept.managesIncomeAmounts ? (
                    <>
                        <Col className="pl-md-0" sm={12} md={6}>
                            <Field
                                component={AmountField}
                                formGroupClassName="mb-0"
                                amountClassName="text-align-right"
                                data={{ options: currencies }}
                                name={`conceptAmount${concept.conceptCode}`}
                                clearable
                                maxLength={15}
                                disableSelect
                                hideLabel
                                fixedDecimalScale
                                plainValue
                            />

                            {concept.managesRange && (
                                <div style={{ display: "flex", color: "rgb(0, 113, 206)" }}>
                                    <span className="font-light f-size-small" style={{ paddingRight: "5px" }}>
                                        {i18nUtils.get("accounts.movements.filters.minAmount.label").toUpperCase()}
                                    </span>
                                    <FormattedAmount
                                        cleanClassName
                                        className="font-light f-size-small"
                                        currency={currencies[0].id.toString()}
                                        quantity={concept.minAmount}
                                    />
                                    <span className="font-light f-size-small" style={{ padding: "0 5px" }}>
                                        {" "}
                                        |{" "}
                                    </span>
                                    <span className="font-light f-size-small" style={{ paddingRight: "5px" }}>
                                        {i18nUtils.get("accounts.movements.filters.maxAmount.label").toUpperCase()}
                                    </span>
                                    <FormattedAmount
                                        cleanClassName
                                        className="font-light f-size-small"
                                        currency={currencies[0].id.toString()}
                                        quantity={concept.maxAmount}
                                    />
                                </div>
                            )}
                        </Col>
                        <Col className="d-flex justify-content-center" sm={6} md={3}>
                            <span className="font-light f-size-55">
                                {i18nUtils.get("services.newPayment.noExpirationDate")}
                            </span>
                        </Col>
                        <Col className="d-flex justify-content-end px-0" sm={6} md={3}>
                            <span className="font-light f-size-55">{concept.description}</span>
                        </Col>
                    </>
                ) : (
                    <>
                        <Col className="d-flex justify-content-start pl-0" sm={12} md={6}>
                            <FormattedAmount
                                cleanClassName
                                className="font-light f-size-55"
                                currency={currencies[0].id.toString()}
                                quantity={concept.permanentAmount}
                            />
                        </Col>
                        <Col className="d-flex justify-content-center" sm={6} md={3}>
                            <span className="font-light f-size-55">
                                {i18nUtils.get("services.newPayment.noExpirationDate")}
                            </span>
                        </Col>
                        <Col className="d-flex justify-content-end px-0" sm={6} md={3}>
                            <span className="font-light f-size-55">{concept.description}</span>
                        </Col>
                    </>
                )}
            </Row>
        </>
    ) : (
        <>
            <Row className="d-flex justify-content-center align-items-center mx-0">
                {concept.managesIncomeAmounts ? (
                    <div className="flex-column w-100">
                        <Col className="px-0 mb-25" sm={12} md={6}>
                            <I18n
                                componentProps={{ className: "text-uppercase f-size-55" }}
                                id={`${FORM_ID}.amountToPay`}
                            />
                            <Field
                                component={AmountField}
                                formGroupClassName="mb-0"
                                amountClassName="text-align-right"
                                data={{ options: currencies }}
                                name={`conceptAmount${concept.conceptCode}`}
                                clearable
                                maxLength={15}
                                disableSelect
                                hideLabel
                                fixedDecimalScale
                                plainValue
                            />

                            {concept.managesRange && (
                                <div style={{ display: "flex", color: "rgb(0, 113, 206)" }}>
                                    <span className="font-light f-size-small" style={{ paddingRight: "5px" }}>
                                        {i18nUtils.get("accounts.movements.filters.minAmount.label").toUpperCase()}
                                    </span>
                                    <FormattedAmount
                                        cleanClassName
                                        className="font-light f-size-small"
                                        currency={currencies[0].id.toString()}
                                        quantity={concept.minAmount}
                                    />
                                    <span className="font-light f-size-small" style={{ padding: "0 5px" }}>
                                        {" "}
                                        |{" "}
                                    </span>
                                    <span className="font-light f-size-small" style={{ paddingRight: "5px" }}>
                                        {i18nUtils.get("accounts.movements.filters.maxAmount.label").toUpperCase()}
                                    </span>
                                    <FormattedAmount
                                        cleanClassName
                                        className="font-light f-size-small"
                                        currency={currencies[0].id.toString()}
                                        quantity={concept.maxAmount}
                                    />
                                </div>
                            )}
                        </Col>
                        <Col className="d-flex space-between line-height-1 px-0 mb-25" sm={12} md={3}>
                            <I18n
                                componentProps={{ className: "text-uppercase f-size-55" }}
                                id={`${FORM_ID}.expiration`}
                            />
                            <span className="font-light f-size-55 text-right pl-2">
                                {i18nUtils.get("services.newPayment.noExpirationDate")}
                            </span>
                        </Col>
                        <Col className="d-flex space-between line-height-1 px-0" sm={12} md={3}>
                            <I18n
                                componentProps={{ className: "text-uppercase f-size-55" }}
                                id={`${FORM_ID}.concept`}
                            />
                            <span className="font-light f-size-55 text-right pl-2">{concept.description}</span>
                        </Col>
                    </div>
                ) : (
                    <>
                        <Col className="d-flex space-between line-height-1 px-0 mb-25" sm={12} md={6}>
                            <I18n
                                componentProps={{ className: "text-uppercase f-size-55" }}
                                id={`${FORM_ID}.amountToPay`}
                            />
                            <FormattedAmount
                                cleanClassName
                                className="font-light f-size-55"
                                currency={currencies[0].id.toString()}
                                quantity={concept.permanentAmount}
                            />
                        </Col>
                        <Col className="d-flex space-between line-height-1 px-0 mb-25" sm={12} md={3}>
                            <I18n
                                componentProps={{ className: "text-uppercase f-size-55" }}
                                id={`${FORM_ID}.expiration`}
                            />
                            <span className="font-light f-size-55 text-right pl-2">
                                {i18nUtils.get("services.newPayment.noExpirationDate")}
                            </span>
                        </Col>
                        <Col className="d-flex space-between line-height-1 px-0" sm={12} md={3}>
                            <I18n
                                componentProps={{ className: "text-uppercase f-size-55" }}
                                id={`${FORM_ID}.concept`}
                            />
                            <span className="font-light f-size-55 text-right pl-2">{concept.description}</span>
                        </Col>
                    </>
                )}
            </Row>
        </>
    );
}

export default AgendaConceptWithoutBaseDebt;
