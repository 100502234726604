import React from "react";
import { func, bool } from "prop-types";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { Box, Button, Icon, Modal, Text } from "@ui-kit/components/index.es";

function TADWarningModal(props) {
    TADWarningModal.propTypes = {
        isVisible: bool.isRequired,
        closeModal: func.isRequired,
    };

    const { isVisible, closeModal } = props;
    const TDAhourTo = configUtils.get("frontend.TAD.workingHours.hourTo", "17:00:00");
    return (
        <Modal
            ariaLabelledBy="modal-label"
            modalControlElementId="modal-button"
            closeOnBgTap
            onClose={() => {
                closeModal();
            }}
            isVisible={isVisible}>
            <Box align="center" gap={16} mt={24} mx={48}>
                <Icon id="warn" name="Warning" color="primary-color" />
                <Text variant="h2" color="primary" align="left">
                    {i18n.get(`transfer.TAD.warning.title`).toUpperCase()}
                </Text>
            </Box>
            <Box mt={24} mx={48} mb={24}>
                <Text variant="body" color="text" align="left">
                    {configUtils.get(`frontend.TAD.maxAmount.text`, null, {
                        DATE_TO: TDAhourTo.substring(0, 5),
                    })}
                </Text>
                <Box justify="end">
                    <Button
                        text={i18n.get("global.accept")}
                        variant="text"
                        size="large"
                        onClick={() => {
                            closeModal();
                        }}
                    />
                </Box>
            </Box>
        </Modal>
    );
}

export default TADWarningModal;
