import React from "react";
import Heading from "pages/_components/Heading";
import { string, shape, bool } from "prop-types";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";

export default function UnattachServiceSummaryData({ summaryInfo, FORM_ID, signature, summary }) {
    UnattachServiceSummaryData.propTypes = {
        summaryInfo: shape({}).isRequired,
        summary: shape({}).isRequired,
        FORM_ID: string.isRequired,
        signature: bool,
        // isDesktop: bool,
    };
    UnattachServiceSummaryData.defaultProps = {
        signature: false,
        // isDesktop: true,
    };

    const PreparedBy = () => (
        <p>
            {summary?.creatorFullName} - <FormattedDate date={summary?.creationDate} dateFormat="dd/MM/yyyy" showTime />
        </p>
    );
    const SignBy = () => (
        <p>
            {summary?.signatures.map((object) => (
                <>
                    {object?.userFirstName.concat(" ", object?.userLastName)} -{" "}
                    <FormattedDate date={object?.creationDateTime} dateFormat="dd/MM/yyyy" showTime />
                    <br />
                </>
            ))}
        </p>
    );
    const {
        enteDescription,
        category: { description },
        userLp,
        reference,
    } = summaryInfo;

    return (
        <>
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between"
                label={`${FORM_ID}.table.header.entity`}
                dataClassName="data-desc text-right w-50"
                data={enteDescription}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between"
                label={`${FORM_ID}.table.header.category`}
                data={description}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between"
                label={`${FORM_ID}.table.header.reference`}
                data={reference}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex space-between"
                label={`${FORM_ID}.table.header.code`}
                data={userLp}
            />

            {signature && (
                <>
                    <I18n
                        component="h3"
                        id="accounts.new.account.signatures.label"
                        componentProps={{ className: "font-size-25-px" }}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex"
                        label="accounts.new.account.signatures.preparedBy.label"
                        data={<PreparedBy />}
                    />
                    {summaryInfo?.signatures?.length > 0 && (
                        <>
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                label="accounts.new.account.signatures.signBy.label"
                                data={<SignBy />}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}
