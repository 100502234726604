/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { bool, func, string, oneOfType, element, arrayOf } from "prop-types";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import InfoTag from "pages/_components/InfoTag";
import * as i18nUtils from "util/i18n";

class EditableLabel extends Component {
    static propTypes = {
        hidePlaceholder: bool,
        placeholder: string,
        onSave: func.isRequired,
        value: string.isRequired,
        children: oneOfType([element, arrayOf(element)]).isRequired,
        favorite: bool,
    };

    static defaultProps = {
        hidePlaceholder: true,
        favorite: null,
        placeholder: "",
    };

    state = {
        isEditing: false,
        value: "",
    };

    handleEdit = () => {
        const { value } = this.props;

        this.setState({ isEditing: true, value });
    };

    handleKeyDown = (event) => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (event.key === "Enter" && onSave) {
            this.setState({ isEditing: false });

            onSave(value);
        } else if (event.key === "Escape") {
            this.setState({ isEditing: false });
        }
    };

    handleOnChange = (event) => {
        this.setState({ value: event.target.value });
    };

    handleSave = () => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (onSave) {
            this.setState({ isEditing: false });

            onSave(value);
        }
    };

    handleCancel = () => {
        const { value } = this.props;

        this.setState({
            isEditing: false,
            value,
        });
    };

    render() {
        const { isEditing, value } = this.state;
        const { children, hidePlaceholder, placeholder, favorite } = this.props;

        return (
            (isEditing && (
                <>
                    <div className="align-items-center d-flex">
                        <label htmlFor="modifyAlias" className="visually-hidden">
                            <I18n id="products.alias.editingAliasLabel.a11y" />
                        </label>
                        <input
                            id="modifyAlias"
                            className="form-control w-auto py-0"
                            onChange={this.handleOnChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={!hidePlaceholder ? placeholder : ""}
                            value={value}
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus={isEditing}
                            maxLength="50"
                            autoComplete="off"
                        />
                        {favorite && (
                            <InfoTag
                                type="info"
                                message={i18nUtils.get(`account.favorite`)}
                                tagBackground="#0071ce"
                                tagClass="min-width-auto ml-2"
                            />
                        )}
                        <Button
                            bsStyle="link account-button-margin-top w-auto"
                            label="global.cancel"
                            type="button"
                            className="mx-2 my-0"
                            onClick={this.handleCancel}
                        />

                        <Button
                            bsStyle="link account-button-margin-top w-auto"
                            label="global.accept"
                            type="button"
                            className="mx-2 my-0"
                            onClick={this.handleSave}
                        />
                    </div>
                </>
            )) || (
                <>
                    <div className="d-flex align-items-center">
                        {children}
                        {favorite && (
                            <InfoTag
                                type="info"
                                message={i18nUtils.get(`account.favorite`)}
                                tagBackground="#0071ce"
                                tagClass="ml-3"
                            />
                        )}
                        <Button
                            bsStyle="link"
                            className="py-0 mx-2 my-0"
                            label="products.alias.edit"
                            type="button"
                            block={false}
                            onClick={this.handleEdit}
                        />
                    </div>
                </>
            )
        );
    }
}

export default EditableLabel;
