/* eslint-disable import/no-unresolved */
import React from "react";
import { string, node, number } from "prop-types";
import Badge from "pages/_components/Badge";

function WidgetHeader({ title, action, countBadge }) {
    return (
        <header className="flex-container">
            <div className="widget__title">
                <h1>{title}</h1>
                {countBadge !== null && <Badge count={countBadge} />}
            </div>
            {action}
        </header>
    );
}
WidgetHeader.propTypes = {
    title: string.isRequired,
    action: node,
    countBadge: number,
};
WidgetHeader.defaultProps = {
    action: null,
    countBadge: null,
};
export default WidgetHeader;
