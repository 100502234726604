import React, { Component } from "react";
import { connect } from "react-redux";
import { arrayOf, objectOf, shape, string, node } from "prop-types";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";

import * as i18nUtils from "util/i18n";
import { permissionsSelectors } from "reducers/administration";
import { selectors as administrationUsersSelector } from "reducers/administration/users";

import Heading from "pages/_components/Heading";

class AdministrationHeading extends Component {
    static propTypes = {
        children: node.isRequired,
        user: arrayOf(shape({ firstName: string, lastName: string, email: string })).isRequired,
        usersInfo: objectOf(
            shape({
                status: string.isRequired,
            }),
        ).isRequired,
        colClassName: string,
    };

    static defaultProps = {
        colClassName: "",
    };

    renderContent = () => {
        const { children, user, usersInfo } = this.props;

        if (typeof children === "function") {
            return children(user);
        }

        const { firstName, lastName, email } = user;
        const { status } = usersInfo;

        return (
            <Grid>
                <Row className="content-data">
                    <Heading.Data>
                        <Heading.DataGroup label="administration.permissions.user" data={`${firstName} ${lastName}`} />
                    </Heading.Data>
                    <Heading.Data>
                        <Heading.DataGroup label="administration.permissions.email" data={email} />
                    </Heading.Data>
                    <Heading.Data>
                        <Heading.DataGroup
                            label="administration.permissions.status"
                            data={<span>{i18nUtils.get(`user.status.${status}`)}</span>}
                            dataClassName={`data-tag data-tag-${status}`}
                        />
                    </Heading.Data>
                </Row>
            </Grid>
        );
    };

    render() {
        const { colClassName } = this.props;
        return <Heading colClassName={colClassName}>{this.renderContent()}</Heading>;
    }
}

const mapStateToProps = (state) => ({
    user: permissionsSelectors.getUser(state),
    usersInfo: administrationUsersSelector.getUserInfo(state, permissionsSelectors.getUser(state)),
});

export default connect(mapStateToProps)(AdministrationHeading);
