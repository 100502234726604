import { FUNCTIONALITY_PERMISSION } from "constants.js";
import * as configUtils from "util/config";

export const convertSmartGroupsToProducts = (permissions, products) => {
    const parsedPermissions = [];
    const productsCopy = [...products];
    if (permissions) {
        for (let i = permissions.length - 1; i > -1; i -= 1) {
            const permission = permissions[i].split("_");
            if (permission.length === 1) {
                parsedPermissions.push(permission[0]);
            } else {
                for (let j = productsCopy.length - 1; j > -1; j -= 1) {
                    const { idProduct, productType } = productsCopy[j];

                    if (permission.indexOf(productType) !== -1) {
                        parsedPermissions.push(idProduct);
                        productsCopy.splice(j, 1);
                    }
                }
            }
        }
    }
    return parsedPermissions;
};

export const permissionsAggrupation = (permissions, oldPermissions) => {
    let newPermissionsToShow = null;
    let removePermissionsToShow = null;

    const getPermissionsSelected = (permissionsMap) => {
        const permissionKeys = Object.keys(permissionsMap);
        return permissionKeys?.filter((key) => permissionsMap[key] && permissionsMap[key].length > 0);
    };

    const removeDuplicates = (permissionsA, permissionsB) =>
        permissionsA?.filter((permission) => !permissionsB?.includes(permission));

    const getPermissionsMap = (permissionsFiltered, permissionsMap) =>
        permissionsFiltered?.reduce((acc, id) => ({ ...acc, [id]: permissionsMap[id] }), {});

    const getProducts = (label, permissionsToAdd, permissionsToRemove) => {
        const productReads = permissions && permissions[label];
        const productReadsForOld = oldPermissions && oldPermissions[label];

        if (productReads && productReadsForOld) {
            const newProductReads = removeDuplicates([...productReads], [...productReadsForOld]);
            const oldProductReads = removeDuplicates([...productReadsForOld], [...productReads]);

            return [
                { ...permissionsToAdd, [label]: newProductReads },
                { ...permissionsToRemove, [label]: oldProductReads },
            ];
        }

        return [permissionsToAdd, permissionsToRemove];
    };

    if (permissions) {
        const permissionsSelected = getPermissionsSelected(permissions);

        let newPermissions = [...permissionsSelected];

        if (oldPermissions) {
            const oldPermissionsArray = getPermissionsSelected(oldPermissions);

            newPermissions = removeDuplicates(permissionsSelected, oldPermissionsArray);

            const eliminatedPermissions = removeDuplicates(oldPermissionsArray, permissionsSelected);

            removePermissionsToShow = getPermissionsMap(eliminatedPermissions, oldPermissions);
        }

        newPermissionsToShow = getPermissionsMap(newPermissions, permissions);

        Object.keys(permissions).forEach((label) => {
            [newPermissionsToShow, removePermissionsToShow] = getProducts(
                label,
                newPermissionsToShow,
                removePermissionsToShow,
            );
        });
    }

    return { newPermissionsToShow, removePermissionsToShow };
};

export const isEnabledFunctionality = (idItem) => {
    const functionality = FUNCTIONALITY_PERMISSION[idItem];
    if (idItem === "transfer.access") {
        return configUtils.getBoolean("frontend.showMVP1.transfers.functionalities");
    }
    const isEnabled =
        functionality === undefined || configUtils.getBoolean(`frontend.show.${functionality}.functionalities`);
    return isEnabled;
};
