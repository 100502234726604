import React, { Component, Fragment } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { func, bool, shape, string } from "prop-types";
import Yup from "yup";
import TextField from "pages/_components/fields/TextField";
import { actions as loginActions } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as enrollmentActions } from "reducers/enrollment";
import { get } from "util/i18n";

import Container from "pages/_components/Container";
import FieldError from "pages/_components/fields/FieldError";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Credential from "pages/_components/fields/credentials/Credential";
import IRSModal from "./_components/IRSModal";

const FORM_ID = "enrollment.stepIRS";
const IRS_no = "IRS_no";
const IRS_yes = "IRS_yes";

class StepIRS extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        fetching: bool.isRequired,
        touched: func.isRequired,
        errors: shape({}).isRequired,
        values: shape({}).isRequired,
        setFieldValue: func.isRequired,
        setErrors: func.isRequired,
        fullName: string.isRequired,
    };

    static defaultProps = {};

    componentDidMount() {}

    changeIRSValue = (radio) => {
        const { setFieldValue } = this.props;
        setFieldValue("IRS", radio);
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    handleCloseBottomSheetClick = () => {
        const { setFieldValue } = this.props;
        setFieldValue("displayModal", false);
    };

    handleIRSYesClick = () => {
        const { dispatch, values, setErrors, setFieldValue } = this.props;

        setFieldValue("displayModal", false);
        dispatch(enrollmentActions.sendIRS(true, values.validateSSNID, setErrors));
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    render() {
        const {
            isMobile,
            values: { IRS, displayModal },
            errors,
            touched,
            fetching,
            fullName,
        } = this.props;
        const IRShasError = touched.IRS && errors.IRS;
        return (
            <Fragment>
                <IRSModal
                    idForm={FORM_ID}
                    goBack={this.handleCloseBottomSheetClick}
                    handleProceedClick={this.handleIRSYesClick}
                    isDisplayed={displayModal}
                    isMobile={isMobile}
                />
                <Notification scopeToShow="pendingActions" />
                <Head title="pendingActions.irs.areyou" onBack={this.onHeaderBack} hideNavbarInMobile />
                <div className="view-page">
                    <div className="view-content">
                        <main className="main-container">
                            <Form className="above-the-fold" noValidate>
                                <Container className="container--layout flex-grow align-items-center">
                                    <Col className="col col-12 col-lg-6 col-md-9">
                                        <I18n component="p" id={`${FORM_ID}.ssnid.disclaimer`} />
                                        <Field
                                            component={TextField}
                                            idForm={FORM_ID}
                                            classNameForViewMode="data-desc"
                                            maxLength={50}
                                            mode="view"
                                            name="ssnid"
                                        />
                                    </Col>
                                    <Col className="col col-12">
                                        <I18n
                                            component="span"
                                            componentProps={{ className: "text-lead" }}
                                            id="enrollment.stepIRS.text1"
                                        />
                                    </Col>
                                    <Col className={`col col-12 col-lg-6 col-md-9 ${IRShasError ? "has-error" : ""}`}>
                                        <RadioButtonGroup
                                            inLineControl
                                            name="IRS"
                                            onChange={this.changeIRSValue}
                                            optionClassName="needsclick"
                                            options={[
                                                {
                                                    id: IRS_no,
                                                    label: <I18n id="enrollment.stepIRS.radio1" />,
                                                },
                                                {
                                                    id: IRS_yes,
                                                    label: <I18n id="enrollment.stepIRS.radio2" />,
                                                },
                                            ]}
                                            value={IRS}
                                        />
                                        {IRShasError && <FieldError error={errors.IRS} />}
                                    </Col>
                                    {IRS === IRS_no && (
                                        <Col className="col col-12">
                                            <I18n
                                                component="span"
                                                componentProps={{ className: "text-lead" }}
                                                id={
                                                    IRS === IRS_no
                                                        ? "enrollment.stepIRS.textUnderRadio1"
                                                        : "enrollment.stepIRS.textUnderRadio2"
                                                }
                                            />
                                        </Col>
                                    )}
                                    {IRS === IRS_no && (
                                        <Col className="col col-12 col-lg-6 col-md-9">
                                            <Field
                                                idForm={FORM_ID}
                                                component={Checkbox}
                                                labelTextProps={{ username: fullName }}
                                                labelText="enrollment.stepIRS.acceptanceCheck"
                                                name="IRSAcceptance"
                                            />
                                        </Col>
                                    )}
                                </Container>
                                <Container className="container--layout align-items-center">
                                    <Col className="col col-12 col-lg-6 col-md-9">
                                        <Field
                                            component={Credential}
                                            idForm={FORM_ID}
                                            maxLength={9}
                                            minLength={9}
                                            name="validateSSNID"
                                            type="password"
                                            labelText="enrollment.stepIRS.confirmSSNID"
                                            placeholder={false}
                                            copyEnabled={false}
                                        />
                                        <Button
                                            label="global.accept"
                                            type="submit"
                                            className="btn btn-primary btn-block"
                                            loading={fetching}
                                        />
                                    </Col>
                                </Container>
                            </Form>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    ssnid: sessionSelectors.getUser(state).ssnid,
    fullName: sessionSelectors.getUser(state).userFullName,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { ssnid } = props;
            return {
                ssnid,
                IRS: "",
                IRSAcceptance: false,
                displayModal: false,
                validateSSNID: "",
            };
        },
        validationSchema: () =>
            Yup.lazy((values) => {
                const { IRS } = values;
                const mustValidateCheck = IRS === IRS_no;
                return Yup.object().shape({
                    IRS: Yup.string().required(get(`${FORM_ID}.field.required`)),
                    IRSAcceptance: mustValidateCheck
                        ? Yup.bool()
                              .required(get(`${FORM_ID}.field.required`))
                              .oneOf([true], get(`${FORM_ID}.field.required`))
                        : Yup.bool(),
                    validateSSNID: Yup.string().required(get(`${FORM_ID}.field.required`)),
                });
            }),
        handleSubmit: ({ IRS, validateSSNID }, formikBag) => {
            const { dispatch } = formikBag.props;
            if (IRS === IRS_no) {
                dispatch(enrollmentActions.sendIRS(false, validateSSNID, formikBag.setErrors));
            } else {
                formikBag.setFieldValue("displayModal", true);
            }
        },
    }),
)(StepIRS);
