import React, { Component } from "react";
import { string } from "prop-types";

import formField from "pages/forms/_components/_fields/_commons/formField";

class Sectiontitle extends Component {
    static propTypes = {
        label: string.isRequired,
    };

    render() {
        const { label } = this.props;

        return (
            <div className="form-section-title">
                <h3 className="section-title">{label}</h3>
            </div>
        );
    }
}

export default formField({ pureRender: true })(Sectiontitle);
