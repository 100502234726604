import React, { Component } from "react";
import { shape, bool } from "prop-types";

import { checksDetailStatusTagColor, checksDetailGetCodeMsg, getChecksDetailAccountName } from "util/checks";
import FormattedAmount from "pages/_components/FormattedAmount";
import InfoTag from "pages/_components/InfoTag";

class CheckDetailItem extends Component {
    static propTypes = {
        checkDetail: shape({}).isRequired,
        isEmittedSelected: bool.isRequired,
    };

    static defaultProps = {};

    render() {
        const { checkDetail, isEmittedSelected } = this.props;

        return (
            <>
                <div className="table-data">
                    <span className="data-aux">{checkDetail.numeroCheque}</span>
                </div>
                <div className="table-data center">
                    <span className="data-aux">
                        {getChecksDetailAccountName({ ...checkDetail, isEmittedSelected })}
                    </span>
                </div>
                <div className="table-data center">
                    <span className="data-aux">
                        {isEmittedSelected
                            ? checkDetail.denominacionBancoDepositario
                            : checkDetail.denominacionBancoLibrador}
                    </span>
                </div>
                <div className="table-data center">
                    <span className="data-aux">{checkDetail.fechaDepositoCheque}</span>
                </div>
                <div className="table-data">
                    <FormattedAmount
                        currency={
                            isEmittedSelected
                                ? checkDetail.codigoMonedaDebitoCheque
                                : checkDetail.codigoMonedaAcreditacionCheque
                        }
                        quantity={checkDetail.importeCheque}
                        notBold
                    />
                </div>
                <div className="table-data center">
                    <span className="data-aux">{checkDetail.fechaDebitoAcreditacionCheque}</span>
                </div>
                <div className="table-data check-info-tag" style={{ display: "flex" }}>
                    <InfoTag
                        type="info"
                        message={checksDetailGetCodeMsg(checkDetail.descripcionEstadoCheque)}
                        tagBackground={checksDetailStatusTagColor(checkDetail.descripcionEstadoCheque)}
                        moreStyles={{
                            alignSelf: "flex-end",
                            color: "black",
                            margin: "0.5rem 0",
                        }}
                    />
                </div>
                <div className="table-data" />
            </>
        );
    }
}

export default CheckDetailItem;
