/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { string, shape } from "prop-types";

import { selectors as i18nSelectors } from "reducers/i18n";
import I18n from "pages/_components/I18n";

class ViewTitle extends Component {
    static propTypes = {
        defaultValue: string,
        id: string.isRequired,
        accessibilityTextId: string,
        replace: shape({ USER_NAME: string }),
        titleClassName: string,
        tag: string,
    };

    static defaultProps = {
        defaultValue: null,
        accessibilityTextId: null,
        replace: { USER_NAME: null },
        titleClassName: "",
        tag: "",
    };

    render() {
        const { id, replace, defaultValue, accessibilityTextId, titleClassName, tag } = this.props;

        // eslint-disable-next-line no-nested-ternary
        const titleComponent = accessibilityTextId ? "p" : tag !== "" ? tag : "h1";

        return (
            <>
                {accessibilityTextId && (
                    <I18n id={accessibilityTextId} component="h1" componentProps={{ className: "visually-hidden" }} />
                )}

                <I18n
                    id={id}
                    defaultValue={defaultValue}
                    component={titleComponent}
                    componentProps={{ className: `title-content ${titleClassName}` }}
                    {...replace}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
});

export default connect(mapStateToProps)(ViewTitle);
