import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push, goBack } from "react-router-redux";
import { Redirect } from "react-router-dom";

import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { shape, bool, string, func } from "prop-types";

import Image from "pages/_components/Image";
import { Col } from "react-bootstrap";
import Button from "pages/_components/Button";
import GeneralMsg from "pages/_components/GeneralMsg";
import * as i18nUtils from "util/i18n";
import Container from "pages/_components/Container";
import REGION_USA from "constants";
import Head from "../_components/Head";

class Success extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fromOnboardingLoginData: shape({
            acceptESign: bool,
            firstName: string,
            lastName: string,
        }).isRequired,
        activeRegion: string.isRequired,
        isMobile: bool.isRequired,
    };

    handleButtonClick = () => {
        const { dispatch } = this.props;
        this.setState(() => {
            dispatch(push("/"));
        });
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    renderTitleMobile = () => (
        <Image src="images/logoMobileHeader.svg" styles={{ position: "relative", top: "5px" }} className="" />
    );

    render() {
        const { isMobile, fromOnboardingLoginData, activeRegion, dispatch } = this.props;

        if (!fromOnboardingLoginData) {
            return <Redirect to="/desktop" />;
        }
        const isUSA = REGION_USA === activeRegion;
        const msg = i18nUtils.get(
            !fromOnboardingLoginData?.acceptESign && isUSA
                ? "login.step1.welcomeFromOnboarding.noEsign.title"
                : "login.step1.welcomeFromOnboarding.success.title",
        );
        return (
            <Fragment>
                <Head
                    onClose={isMobile && this.onHeaderClose}
                    onBack={isMobile && this.onHeaderBack}
                    headerClassName={isMobile ? "blue-main-header-mobile" : ""}
                    centerElement={!isMobile ? undefined : this.renderTitleMobile}
                    closeImageWhite={isMobile && true}
                />
                <div className="view-page">
                    <div className="view-content">
                        <main className="main-container">
                            <div className="above-the-fold">
                                <Container className="container--layout flex-grow enrollment-success-message">
                                    <Col sm={12} className="col col-12 mt-6 align-items-center">
                                        <Image
                                            src={
                                                !fromOnboardingLoginData?.acceptESign && isUSA
                                                    ? "images/incompleteOnboard.svg"
                                                    : "images/successOnboard.svg"
                                            }
                                            styles={{
                                                width: !isMobile ? "160px" : "64px",
                                                height: !isMobile ? "160px" : "64px",
                                            }}
                                        />
                                        {!isMobile ? (
                                            <GeneralMsg
                                                title={
                                                    !fromOnboardingLoginData?.acceptESign && isUSA
                                                        ? `${fromOnboardingLoginData?.firstName}${msg}`
                                                        : `${msg} ${fromOnboardingLoginData?.firstName}!`
                                                }
                                                description={`${i18nUtils.get(
                                                    !fromOnboardingLoginData?.acceptESign && isUSA
                                                        ? "login.step1.welcomeFromOnboarding.noEsign"
                                                        : "login.step1.welcomeFromOnboarding.success",
                                                )}`}
                                                isDesktop={!isMobile}
                                            />
                                        ) : (
                                            <div>
                                                <h2 className="cmf-title-center">
                                                    {!fromOnboardingLoginData?.acceptESign && isUSA
                                                        ? `${fromOnboardingLoginData?.firstName}${msg}`
                                                        : `${msg} ${fromOnboardingLoginData?.firstName}!`}
                                                </h2>
                                                <p className="mt-3 text-center">
                                                    {`${i18nUtils.get(
                                                        !fromOnboardingLoginData?.acceptESign && isUSA
                                                            ? "login.step1.welcomeFromOnboarding.noEsign"
                                                            : "login.step1.welcomeFromOnboarding.success",
                                                    )}`}
                                                </p>
                                            </div>
                                        )}
                                    </Col>
                                </Container>
                                <Container className="container--layout flex-grow enrollment-success-message">
                                    <Col sm={12} md={8} lg={4} className="col col-12 enrollment-success-button-height">
                                        <Button
                                            type="button"
                                            bsStyle="primary"
                                            label="end.enrollment.success.button"
                                            onClick={() => dispatch(push("/"))}
                                            style={{ position: "relative", top: "calc(100% - 4rem)" }}
                                        />
                                    </Col>
                                </Container>
                            </div>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fromOnboardingLoginData: loginSelectors.getFromOnboardingLoginData(state),
    activeRegion: loginSelectors.getRegion(state),
});

export default connect(mapStateToProps)(Success);
