import React, { Component } from "react";
import { connect } from "react-redux";

import { permissionsSelectors } from "reducers/administration";
import * as administrationUtils from "util/administration";

import Accordion from "pages/_components/Accordion";
import AdvancedPermissionsAmount from "pages/administration/_components/AdvancedPermissionsAmount";
import PermissionField from "pages/administration/_components/advancedPermissionsForm/PermissionField";
import I18n from "pages/_components/I18n";
import { string, shape, arrayOf } from "prop-types";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";
import { isEnabledFunctionality } from "util/permissions";

class AdvancedDetailPermissionsList extends Component {
    static propTypes = {
        permissions: administrationUtils.permissionsPropType.isRequired,
        groups: administrationUtils.groupsPropType.isRequired,
        products: arrayOf(
            shape({
                idProduct: string,
            }),
        ).isRequired,
    };

    renderItem = (option) => (
        <li className="list-item" key={option.idItem}>
            <div className="list-item">{option.label}</div>
        </li>
    );

    renderOptions = (parentOption) =>
        parentOption.childrenList.reduce((categoryOptions, childOption) => {
            const { idItem } = childOption;
            if (!isEnabledFunctionality(idItem)) {
                return [...categoryOptions];
            }
            if (childOption.childrenList && childOption.childrenList.length) {
                const subOptions = this.renderOptions(childOption);

                if (!subOptions.length) {
                    return categoryOptions;
                }

                return [
                    ...categoryOptions,
                    <li className="list-item" key={idItem}>
                        <div className="list-item-inner">
                            <span className="navigational-list-subtitle">{childOption.label}</span>
                        </div>
                        <ul className="list">{subOptions}</ul>
                    </li>,
                ];
            }

            const { permissions } = this.props;
            const permission = permissions[idItem] || [];
            if (permission.length) {
                const [{ advancedAllowProductSelection }] = childOption.permissionList || [];
                const field = advancedAllowProductSelection ? (
                    <PermissionField key={idItem} {...childOption} parentOption={parentOption} mode="view" level={2} />
                ) : (
                    this.renderItem(childOption)
                );

                return [...categoryOptions, field];
            }

            return categoryOptions;
        }, []);

    render() {
        const { groups, permissions, products } = this.props;

        return (
            <AdvancedPermissionsAmount permissions={permissions} isEnabledFunctionality={isEnabledFunctionality}>
                {(amountsById) => {
                    if (!Object.keys(amountsById).length) {
                        return (
                            <ul className="list">
                                <li className="list-item">
                                    <div className="list-item-inner">
                                        <div className="data-wrapper data-wrapper-flex">
                                            <I18n id="administration.permissions.empty" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        );
                    }

                    return (
                        <Container>
                            <Col xs={12} className="px-0">
                                <Accordion className="list create-group-list--permissions">
                                    {groups.reduce((mainCategories, option, index) => {
                                        const { idItem, label, permissionList = [] } = option;
                                        const parentOption = { ...option, number: index };
                                        const categoryOptions = this.renderOptions(parentOption);
                                        const optionsAmount = amountsById[idItem];
                                        const [permission] = permissionList;

                                        if (
                                            (permission && permission.advancedAllowProductSelection) ||
                                            categoryOptions.length
                                        ) {
                                            if (optionsAmount === 0) {
                                                return mainCategories;
                                            }

                                            if (permission) {
                                                const productTypes = permission.productTypes.split(",");
                                                const filteredProducts = products.filter(({ productType }) =>
                                                    productTypes.includes(productType),
                                                );
                                                const values = permissions[idItem] || [];

                                                const {
                                                    selectedSmartGroupAmount,
                                                } = administrationUtils.smartGroupsOptionsCount(productTypes, values);
                                                const {
                                                    selectedProductsAmount,
                                                } = administrationUtils.productsOptionsCount(
                                                    filteredProducts.map(({ value }) => ({ idProduct: value })),
                                                    values,
                                                );
                                                const amount = selectedSmartGroupAmount + selectedProductsAmount;
                                                return [
                                                    ...mainCategories,
                                                    <Accordion.Item
                                                        key={idItem}
                                                        buttonClassName="my-0"
                                                        number={index}
                                                        fitContent
                                                        item={
                                                            <span>
                                                                {label} ({amount})
                                                            </span>
                                                        }>
                                                        <ul className="list">
                                                            <PermissionField
                                                                key={option.idItem}
                                                                {...option}
                                                                number={index}
                                                                mode="view"
                                                                level={1}
                                                            />
                                                        </ul>
                                                    </Accordion.Item>,
                                                ];
                                            }

                                            return [
                                                ...mainCategories,
                                                <Accordion.Item
                                                    key={idItem}
                                                    buttonClassName="my-0"
                                                    number={index}
                                                    fitContent
                                                    item={
                                                        <span>
                                                            {label} ({optionsAmount})
                                                        </span>
                                                    }>
                                                    <ul className="list">{categoryOptions}</ul>
                                                </Accordion.Item>,
                                            ];
                                        }

                                        return mainCategories;
                                    }, [])}
                                </Accordion>
                            </Col>
                        </Container>
                    );
                }}
            </AdvancedPermissionsAmount>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    permissions: ownProps.permissions || permissionsSelectors.getPermissions(state),
    groups: permissionsSelectors.getGroups(state),
    products: permissionsSelectors.getMappedProducts(state),
});

export default connect(mapStateToProps)(AdvancedDetailPermissionsList);
