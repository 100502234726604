import { combineReducers } from "redux";

import createChannelsReducerModule from "reducers/administration/common/channels";
import createChannelsTicketReducerModule from "reducers/administration/common/channelsTicket";
import createDetailsReducerModule from "reducers/administration/common/details";
import createPermissionsReducerModule from "reducers/administration/common/permissions";
import createPermissionsTicketReducerModule from "reducers/administration/common/permissionsTicket";
import createSignaturesSchemesReducerModule from "reducers/administration/common/signaturesSchemes";
import createSignaturesSchemesTicketReducerModule from "reducers/administration/common/signaturesSchemesTicket";
import createSignatureTicketReducerModule from "reducers/administration/common/signatureTicket";

const { types: detailsTypes, actions: detailsActions, reducer: detailsReducer } = createDetailsReducerModule(
    "administrationMedium",
);
const {
    types: permissionsTypes,
    actions: permissionsActions,
    reducer: permissionsReducer,
} = createPermissionsReducerModule("administrationMedium");
const {
    types: permissionsTicketTypes,
    actions: permissionsTicketActions,
    reducer: permissionsTicketReducer,
} = createPermissionsTicketReducerModule("administrationMedium");
const {
    types: channelsTicketTypes,
    actions: channelsTicketActions,
    reducer: channelsTicketReducer,
} = createChannelsTicketReducerModule("administrationMedium");
const {
    types: signatureTicketTypes,
    actions: signatureTicketActions,
    reducer: signatureTicketReducer,
} = createSignatureTicketReducerModule("administrationMedium");
const { types: channelsTypes, actions: channelsActions, reducer: channelsReducer } = createChannelsReducerModule(
    "administrationMedium",
);
const {
    actions: signaturesSchemesActions,
    reducer: signaturesSchemesReducer,
    types: signaturesSchemesTypes,
} = createSignaturesSchemesReducerModule("administrationMedium");
const {
    actions: signaturesSchemesTicketActions,
    reducer: signaturesSchemesTicketReducer,
    types: signaturesSchemesTicketTypes,
} = createSignaturesSchemesTicketReducerModule("administrationMedium");

export {
    channelsActions,
    channelsTypes,
    channelsTicketActions,
    channelsTicketTypes,
    detailsActions,
    detailsTypes,
    permissionsActions,
    permissionsTypes,
    permissionsTicketActions,
    permissionsTicketTypes,
    signaturesSchemesActions,
    signaturesSchemesTypes,
    signaturesSchemesTicketActions,
    signaturesSchemesTicketTypes,
    signatureTicketActions,
    signatureTicketTypes,
};

export default combineReducers({
    channels: channelsReducer,
    channelsTicket: channelsTicketReducer,
    details: detailsReducer,
    signaturesSchemes: signaturesSchemesReducer,
    signaturesSchemesTicket: signaturesSchemesTicketReducer,
    signatureTicket: signatureTicketReducer,
    permissions: permissionsReducer,
    permissionsTicket: permissionsTicketReducer,
});
