import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { element, func, shape, string } from "prop-types";

import * as i18n from "util/i18n";
import Image from "pages/_components/Image";

class UserLink extends Component {
    static propTypes = {
        children: element,
        onSelect: func,
        loggedUser: shape({
            userFullName: string.isRequired,
            email: string.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        children: null,
        onSelect: null,
    };

    render() {
        const {
            children,
            onSelect,
            loggedUser: { userFullName, email },
        } = this.props;

        const firstEmailLetter = email.charAt(0);
        const textAfterLastLetter = email.substring(email.indexOf("@") - 1, email.length);

        return (
            <Fragment>
                <div style={{ paddingLeft: "1rem", paddingTop: "0.5rem" }}>
                    <p className="ellipsis pr-3">
                        <span className="text-lead">{userFullName}</span>
                    </p>
                    <p className="ellipsis">
                        <span className="data-email">
                            {firstEmailLetter}••••{textAfterLastLetter}
                        </span>
                    </p>
                    <div style={{ display: "flex" }}>
                        <div style={{ marginTop: "5px" }}>
                            <Image className="svg-icon-profile" src="images/configuration.svg" />
                        </div>
                        <Link className="btn-link popup-settings-item" to="/settings" onClick={onSelect}>
                            {i18n.get("settings.index")}
                        </Link>
                    </div>
                </div>
                <hr className="popup-settings-line" />
                <div style={{ paddingLeft: "1rem" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ marginTop: "5px" }}>
                            <Image className="svg-icon-profile" src="images/account_circle.svg" />
                        </div>
                        <Link
                            // image="images/transferencias.svg"
                            className="btn-link popup-settings-item"
                            to="/accountsOfficer"
                            onClick={onSelect}>
                            {i18n.get("settings.index.accountsOfficer.label")}
                        </Link>
                    </div>
                </div>
                <hr className="popup-settings-line" />
                {children}
            </Fragment>
        );
    }
}

export default UserLink;
