import React, { Component, Fragment } from "react";

import LoginAssistantStepContent from "pages/login/_components/Step5Content";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";

class LoginAssistantStep extends Component {
    render() {
        return (
            <Fragment>
                <Head title="assistant.account.link.finish.title" />
                <MainContainer className="main-container">
                    <LoginAssistantStepContent />
                </MainContainer>
            </Fragment>
        );
    }
}

export default LoginAssistantStep;
