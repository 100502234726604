import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, replace } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { arrayOf, bool, func, string, shape } from "prop-types";
import { Row, Col } from "react-bootstrap";
import Yup from "yup";

import { actions as onboardingActions, selectors as onboardingSelectors } from "reducers/onboarding";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";

import Button from "pages/_components/Button";
import withExchangeToken from "pages/_components/withExchangeToken";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import * as i18n from "util/i18n";
import TextField from "pages/_components/fields/TextField";

import { format } from "date-fns";
import * as config from "util/config";
import OnboardingStepper, { orientations } from "./_components/OnboardingStepper";
import Selector from "../_components/fields/formik/Selector";
import { MAIN_ONBOARDING_ROUTE } from "./Step0";

const FORM_ID = "onboarding.step6";

class StepOccupationUSA extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        documentType: string,
        isSubmitting: bool.isRequired,
        isMobile: bool.isRequired,
        occupation_options: arrayOf({}),
        source_of_wealth_options: arrayOf({}),
        annual_income_options: arrayOf({}),
        values: shape({}).isRequired,
        countryList: arrayOf({}),
    };

    static defaultProps = {
        documentType: "",
        occupation_options: [],
        source_of_wealth_options: [],
        annual_income_options: [],
        countryList: [],
    };

    componentDidMount() {
        const { dispatch, documentType } = this.props;
        if (!documentType) {
            dispatch(replace(MAIN_ONBOARDING_ROUTE));
        }
    }

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    mustShowOtherOccupation = () => {
        const { values } = this.props;
        return values.occupation === "5_OTH";
    };

    mustShowOtherSourceOfWealth = () => {
        const { values } = this.props;
        return values.sourceOfWealth === "5_OTH";
    };

    mustHideEmployerInformation = () => {
        const { values } = this.props;
        return values.occupation === "3_ST" || values.occupation === "4_MI";
    };

    render() {
        const {
            isSubmitting,
            isMobile,
            occupation_options,
            source_of_wealth_options,
            annual_income_options,
            countryList,
        } = this.props;

        return (
            <Fragment>
                <Head title={FORM_ID} onBack={isMobile && this.onHeaderBack} onClose={isMobile && this.onHeaderClose} />
                <div className="view-page">
                    {!isMobile && <OnboardingStepper currentStep={6} className="onboarding-steps" />}
                    <div className="view-content">
                        <main className="main-container">
                            <Form className="above-the-fold">
                                {isMobile && (
                                    <Container className="container--layout align-items-center">
                                        <Col className="col col-12">
                                            <OnboardingStepper currentStep={6} orientation={orientations.horizontal} />
                                        </Col>
                                    </Container>
                                )}
                                <Container className="container--layout flex-grow align-items-center">
                                    <Col className="col col-12">
                                        <div className="form-group">
                                            <Row>
                                                <Col
                                                    sm={12}
                                                    md={this.mustShowOtherOccupation() ? 6 : 12}
                                                    lg={this.mustShowOtherOccupation() ? 6 : 12}
                                                    xl={this.mustShowOtherOccupation() ? 6 : 12}
                                                    className="col">
                                                    <Field
                                                        component={Selector}
                                                        options={
                                                            occupation_options &&
                                                            Object.entries(occupation_options).map(([id, text]) => ({
                                                                value: id,
                                                                label: text,
                                                            }))
                                                        }
                                                        idForm={FORM_ID}
                                                        classNameForViewMode="data-desc"
                                                        maxLength={50}
                                                        name="occupation"
                                                    />
                                                </Col>
                                                {this.mustShowOtherOccupation() && (
                                                    <Col sm={12} md={6} lg={6} xl={6} className="col">
                                                        <Field
                                                            component={TextField}
                                                            idForm={FORM_ID}
                                                            classNameForViewMode="data-desc"
                                                            maxLength={50}
                                                            name="otherOccupation"
                                                        />
                                                    </Col>
                                                )}
                                                {!this.mustHideEmployerInformation() && (
                                                    <Col className="col col-12">
                                                        <Field
                                                            component={TextField}
                                                            idForm={FORM_ID}
                                                            classNameForViewMode="data-desc"
                                                            maxLength={50}
                                                            name="jobTitle"
                                                        />
                                                    </Col>
                                                )}
                                                {!this.mustHideEmployerInformation() && (
                                                    <Col className="col col-12">
                                                        <Field
                                                            component={TextField}
                                                            idForm={FORM_ID}
                                                            classNameForViewMode="data-desc"
                                                            maxLength={50}
                                                            name="employerName"
                                                        />
                                                    </Col>
                                                )}
                                                {!this.mustHideEmployerInformation() && (
                                                    <Col className="col col-12">
                                                        <Field
                                                            component={Selector}
                                                            options={
                                                                countryList &&
                                                                countryList.map((c) => ({
                                                                    value: c.id,
                                                                    label: c.name,
                                                                }))
                                                            }
                                                            idForm={FORM_ID}
                                                            classNameForViewMode="data-desc"
                                                            maxLength={50}
                                                            name="employerHqCountry"
                                                            searchable
                                                        />
                                                    </Col>
                                                )}
                                                <Col className="col col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <Field
                                                        component={Selector}
                                                        options={
                                                            source_of_wealth_options &&
                                                            Object.entries(source_of_wealth_options).map(
                                                                ([id, text]) => ({
                                                                    value: id,
                                                                    label: text,
                                                                }),
                                                            )
                                                        }
                                                        idForm={FORM_ID}
                                                        classNameForViewMode="data-desc"
                                                        maxLength={50}
                                                        name="sourceOfWealth"
                                                    />
                                                </Col>
                                                {this.mustShowOtherSourceOfWealth() && (
                                                    <Col sm={12} md={6} lg={6} xl={6} className="col">
                                                        <Field
                                                            component={TextField}
                                                            idForm={FORM_ID}
                                                            classNameForViewMode="data-desc"
                                                            maxLength={50}
                                                            name="otherSourceOfWealth"
                                                        />
                                                    </Col>
                                                )}
                                                <Col className="col col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <Field
                                                        component={Selector}
                                                        options={
                                                            annual_income_options &&
                                                            Object.entries(annual_income_options).map(([id, text]) => ({
                                                                value: id,
                                                                label: text,
                                                            }))
                                                        }
                                                        idForm={FORM_ID}
                                                        classNameForViewMode="data-desc"
                                                        maxLength={50}
                                                        name="annualIncome"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col className="col col-12">
                                        <Button
                                            label="global.continue"
                                            type="submit"
                                            bsStyle="primary"
                                            loading={isSubmitting}
                                        />
                                    </Col>
                                </Container>
                                {/* <Container className="container--layout align-items-center"></Container> */}
                            </Form>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    documentData: onboardingSelectors.getDocumentData(state),
    documentType: onboardingSelectors.getDocumentType(state),
    exchangeToken: onboardingSelectors.getExchangeToken(state),
    fetching: onboardingSelectors.getFetching(state),
    email: onboardingSelectors.getEmail(state),
    cellPhone: onboardingSelectors.getMobileNumber(state),
    address: onboardingSelectors.getAddress(state),
    occupation_options: onboardingSelectors.getOccupation_options(state),
    source_of_wealth_options: onboardingSelectors.getSource_of_wealth_options(state),
    annual_income_options: onboardingSelectors.getAnnual_income_options(state),
    countryList: onboardingSelectors.getCountryList(state),
    SSNID: onboardingSelectors.getSSNID(state),
    region: loginSelectors.getRegion(state) || null,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: () => ({
            occupation: "",
            otherOccupation: "",
            jobTitle: "",
            employerName: "",
            employerHqCountry: "",
            sourceOfWealth: "",
            otherSourceOfWealth: "",
            annualIncome: "",
        }),
        validationSchema: () =>
            Yup.lazy((values) => {
                const showValidateOtherOcupation = values.occupation === "5_OTH";
                const itsStudentOrMilitary = values.occupation === "3_ST" || values.occupation === "4_MI";
                const showValidateOtherSourceOfW = values.sourceOfWealth === "5_OTH";
                return Yup.object().shape({
                    occupation: Yup.string().required(i18n.get("onboarding.step6.field.error.empty")),
                    otherOccupation: showValidateOtherOcupation
                        ? Yup.string().required(i18n.get("onboarding.step6.field.error.empty"))
                        : Yup.string(),
                    jobTitle: !itsStudentOrMilitary
                        ? Yup.string().required(i18n.get("onboarding.step6.field.error.empty"))
                        : Yup.string(),
                    employerName: !itsStudentOrMilitary
                        ? Yup.string().required(i18n.get("onboarding.step6.field.error.empty"))
                        : Yup.string(),
                    employerHqCountry: !itsStudentOrMilitary
                        ? Yup.string().required(i18n.get("onboarding.step6.field.error.empty"))
                        : Yup.string(),
                    sourceOfWealth: Yup.string().required(i18n.get("onboarding.step6.field.error.empty")),
                    otherSourceOfWealth: showValidateOtherSourceOfW
                        ? Yup.string().required(i18n.get("onboarding.step6.field.error.empty"))
                        : Yup.string(),
                    annualIncome: Yup.string().required(i18n.get("onboarding.step6.field.error.empty")),
                });
            }),
        handleSubmit: ({ jobTitle, occupation }, formikBag) => {
            const { dispatch, address, SSNID, cellPhone, documentData, email, region } = formikBag.props;
            const entryDate = format(new Date(), "YYYY-MM-DD");
            const jobInformation = {
                companyName: jobTitle,
                occupation,
                income: { quantity: 0, currency: config.get("core.masterCurrency") },
                entryDate,
            };

            dispatch(
                onboardingActions.uploadClientInfoAndSendInvitationCode(
                    documentData.documentNumber,
                    email,
                    documentData.firstName,
                    formikBag,
                    documentData.lastName,
                    cellPhone,
                    address,
                    jobInformation,
                    SSNID,
                    region,
                ),
            );
        },
    }),
)(withExchangeToken(StepOccupationUSA));
