import React, { Component } from "react";
import { string, bool, node } from "prop-types";

import classNames from "classnames";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";

class FieldLabel extends Component {
    static propTypes = {
        idField: string.isRequired,
        labelKey: string,
        labelText: string,
        mode: string,
        optional: string,
        hideLabel: bool,
        small: bool,
        isLegend: bool,
        notMarginY: bool,
        blackLabel: bool,
        extraLabel: node,
        isDisabled: bool,
        labelRegular: bool,
        bold: bool,
        isTransferFormSelect: bool,
        formGroupTextClassName: string,
        controlLabelClassName: string,
        controlLabelChildClassName: string,
    };

    static defaultProps = {
        mode: "edit",
        hideLabel: false,
        labelKey: null,
        labelText: null,
        optional: null,
        isLegend: false,
        small: false,
        notMarginY: false,
        blackLabel: false,
        extraLabel: null,
        isDisabled: false,
        labelRegular: false,
        bold: false,
        isTransferFormSelect: false,
        formGroupTextClassName: "",
        controlLabelClassName: "",
        controlLabelChildClassName: "",
    };

    getFocus = (id) => {
        const element = document.getElementById(id) || document.getElementById(`react-select-${id}--value`);
        if (
            element &&
            element.nodeName !== "INPUT" &&
            element.nodeName !== "TEXTAREA" &&
            element.nodeName !== "SELECT"
        ) {
            element.querySelector(".Select-input").focus();
        }
    };

    render() {
        const {
            idField,
            labelKey,
            labelText,
            mode,
            optional,
            hideLabel,
            isLegend,
            extraLabel,
            small,
            notMarginY,
            isDisabled,
            blackLabel,
            labelRegular,
            bold,
            isTransferFormSelect,
            formGroupTextClassName,
            controlLabelClassName,
            controlLabelChildClassName,
        } = this.props;
        const LabelTag = isLegend ? "legend" : "label";
        if (mode === "edit") {
            return (
                <div
                    className={classNames(
                        "form-group-text",
                        formGroupTextClassName,
                        { "visually-hidden": hideLabel },
                        { "my-0 mr-2": notMarginY },
                        { "transfer-form-select": isTransferFormSelect },
                        { "justify-content-normal": !!extraLabel },
                    )}
                    role="presentation"
                    onClick={() => this.getFocus(idField)}>
                    <LabelTag
                        id={`${LabelTag}.${idField}`}
                        className={classNames(
                            "control-label",
                            controlLabelClassName,
                            { "visually-hidden": isLegend },
                            { "small-font": small },
                            { "control-label-disable": isDisabled },
                            { "text-optional": labelRegular },
                            { "my-0": notMarginY },
                        )}
                        htmlFor={idField}
                        title={labelKey && i18n.get(labelKey)}>
                        {labelKey ? (
                            <I18n
                                id={labelKey}
                                componentProps={{ className: controlLabelChildClassName }}
                                component={bold ? "b" : "span"}
                            />
                        ) : (
                            labelText
                        )}
                        {optional && <small className="text-optional">{optional}</small>}
                    </LabelTag>
                    {extraLabel}
                    {isLegend && (
                        <>
                            <label id={`labelLegend.${idField}`} className="control-label" htmlFor={idField}>
                                {labelKey ? <I18n id={labelKey} /> : labelText}
                                {optional && <small className="text-optional">{optional}</small>}
                            </label>
                            {extraLabel}
                        </>
                    )}
                </div>
            );
        }
        return (
            <span className={classNames("control-label", { "font-black-alpha": blackLabel })}>
                {labelKey ? <I18n id={labelKey} /> : labelText}
            </span>
        );
    }
}

export default FieldLabel;
