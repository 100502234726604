import React, { Component } from "react";
import Route from "react-router-dom/Route";
import Redirect from "react-router-dom/Redirect";
import withRouter from "react-router-dom/withRouter";
import { resizableRoute } from "pages/_components/Resizable";
import { shape, bool, string, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";

class PrivateRoute extends Component {
    static propTypes = {
        authorizedRoute: bool,
        exact: bool,
        path: string,
        component: shape({}),
        children: shape({}),
        excludeScheme: arrayOf(string),
        activeEnvironment: shape({}).isRequired,
        excludeEnvType: arrayOf(string),
        render: shape({}),
    };

    static defaultProps = {
        authorizedRoute: true,
        exact: false,
        path: "/",
        component: null,
        children: null,
        excludeScheme: [],
        excludeEnvType: [],
        render: null,
    };

    render() {
        const {
            authorizedRoute,
            exact,
            path,
            component,
            children,
            excludeScheme,
            activeEnvironment,
            excludeEnvType,
            render,
            ...rest
        } = this.props;

        const environmentScheme = activeEnvironment.administrationScheme;
        const isExcludedScheme = excludeScheme.includes(environmentScheme);

        const environmentType = activeEnvironment.type;
        const isExcludedEnvType = excludeEnvType.includes(environmentType);

        if (!authorizedRoute || isExcludedScheme || isExcludedEnvType) {
            return <Redirect to="/desktop" />;
        }

        return (
            <Route {...rest} render={render} exact={exact} path={path} component={component}>
                {children && children}
            </Route>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(withRouter(resizableRoute(PrivateRoute)));
