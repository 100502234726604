import moment from "moment";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import { capitalizeFirstLetter } from "util/string";

const Mustache = require("mustache");

export default function getScheduledDate(value) {
    let message = "";

    let data = {};

    if (value) {
        if (value.program) {
            const { program } = value;

            if (program.lapse === "UNTIL") {
                data.until = moment(program.date).format("ll");
            } else if (program.lapse === "UP_TO") {
                if (program.number <= 1) {
                    data.upToOne = program.number;
                } else {
                    data.upTo = program.number;
                }
            }

            const date = moment(program.startsOn);
            const dateWithoutYear = date.format("ll").split(".")[0];
            data.day = date.format("dddd");
            data.date = date.format("ll");
            data.dateWithoutYear = dateWithoutYear;
            if (program.days) {
                data.day = dateUtils.getDaysNamesWithBitwiseNumber(program.days).join(", ");
            }
            if (program.frequencyValue && Number(program.frequencyValue) > 1) {
                data.frequencyValue = program.frequencyValue;
            }
            if (program.occurrence) {
                data = {
                    ...data,
                    [schedulerUtils.getOccurrenceWithWeekOfMonth(program.occurrence)]: true,
                };
            } else {
                data.numberDay = Number(date.format("DD"));
            }
            if (typeof program.frequency === "string") {
                message = Mustache.render(
                    i18n.get(`scheduler.execute${capitalizeFirstLetter(program.frequency.toLowerCase())}`),
                    data,
                );
            }
        } else {
            let valueDate = moment();
            if (value.valueDate || value.newValueDate) {
                valueDate = value.valueDate || value.newValueDate;
            } else if (value.data) {
                valueDate = value.data.scheduler.valueDate || value.data.scheduler.newValueDate;
            } else {
                valueDate = "";
            }
            const date = valueDate !== "" ? moment(valueDate) : moment();

            if (date.isSame(moment(), "day")) {
                message = i18n.get("scheduler.executeToday");
            } else {
                data.day = date.format(i18n.get("datepicker.format"));
                if (date.isSame(moment().add(1, "day"), "day")) {
                    message = Mustache.render(i18n.get("scheduler.executeTomorrow"), data);
                } else {
                    message = Mustache.render(i18n.get("scheduler.executeFuture"), data);
                }
            }
        }
    } else {
        return null;
    }
    return message;
}
