import React, { Component } from "react";
import { connect } from "react-redux";
import { func, arrayOf, shape, string, bool, number } from "prop-types";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { Form, withFormik } from "formik";
import { compose } from "redux";
import * as Yup from "yup";

import { actions as accountsActions, selectors as accountsSelector } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as servicePaymentsActions, selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import I18n from "pages/_components/I18n";
import PageLoading from "pages/_components/PageLoading";

import { statusCPEShape } from "util/servicePayments";
import * as i18nUtils from "util/i18n";

import classNames from "classnames";
import { saveLocation } from "util/transaction";
import SelectPaymentInfo from "./_components/SelectServicePaymentInfo";
import SelectPaymentMethod from "./_components/SelectPaymentMethod";
import ContinueButton from "./_components/ContinueButton";
import ConceptWithoutBaseDebt from "./_components/ConceptWithoutBaseDebt";
import ConceptWithBaseDebt from "./_components/ConceptWithBaseDebt";

const FORM_ID = "services.newPayment";
class ServiceNewPayment extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        values: shape({}).isRequired,
        isDesktop: bool.isRequired,
        isFetching: bool,
        rubros: arrayOf(
            shape({ code: string.isRequired, description: string.isRequired, shortDescrition: string.isRequired }),
        ).isRequired,
        entities: arrayOf(
            shape({
                code: string.isRequired,
                description: string.isRequired,
                handleBaseDebts: bool.isRequired,
                handleMultipleConcepts: bool.isRequired,
                handleIncomeReference: bool.isRequired,
                handleIncomeAmount: bool.isRequired,
                enable: bool.isRequired,
                category: shape({
                    code: string.isRequired,
                    description: string.isRequired,
                    shortDescrition: string.isRequired,
                }),
            }),
        ).isRequired,
        paymentsConcept: arrayOf(
            shape({
                conceptCode: string.isRequired,
                description: string.isRequired,
                paymentType: string.isRequired,
                managesIncomeAmounts: bool.isRequired,
                managesRange: bool.isRequired,
                managesIncomeReference: bool.isRequired,
                validLengthReference: bool.isRequired,
                minimumlengthTextReference: number.isRequired,
                maxLengthTextReference: number.isRequired,
                originalAmount: number.isRequired,
                permanentAmount: number.isRequired,
                minAmount: number.isRequired,
                maxAmount: number.isRequired,
            }),
        ).isRequired,
        statusCPE: statusCPEShape.isRequired,
        isSubmittingConcepts: bool,
        submitForm: func.isRequired,
        componentState: shape({
            multipleConcepts: bool.isRequired,
            isEntitiesDisabled: bool.isRequired,
            showConcepts: bool.isRequired,
            isAddServiceSelected: bool.isRequired,
        }).isRequired,
        isReturnFromDetail: bool.isRequired,
    };

    static defaultProps = {
        isFetching: false,
        isSubmittingConcepts: false,
    };

    state = {
        multipleConcepts: false,
        isEntitiesDisabled: true,
        showConcepts: false,
        isAddServiceSelected: true,
    };

    componentDidMount() {
        const { dispatch, accounts, values, componentState } = this.props;
        const { rubros } = values;
        saveLocation(this.props);
        dispatch(accountsActions.listAccounts());
        if (accounts && accounts.length > 0) {
            dispatch(servicePaymentsActions.listRubros(accounts[0]));
        }
        if (rubros) {
            this.setState((prevState) => ({
                ...prevState,
                isEntitiesDisabled: false,
            }));
            dispatch(
                servicePaymentsActions.updateComponetState({
                    ...componentState,
                    multipleConcepts: false,
                    showConcepts: true,
                    isEntitiesDisabled: false,
                }),
            );

            dispatch(servicePaymentsActions.updateComponentValues({ ...values }));
            dispatch(servicePaymentsActions.checkCpe(values.entities, values.cpe));
        }
    }

    componentDidUpdate(prevProp, prevState) {
        const { paymentsConcept, accounts, dispatch } = this.props;
        if (!prevState.showConcepts && paymentsConcept) {
            this.handleUpdateState(paymentsConcept);
        }
        if (accounts && accounts.length > 0 && prevProp.accounts !== accounts) {
            dispatch(servicePaymentsActions.listRubros(accounts[0]));
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(servicePaymentsActions.partialCleanUp());
    }

    handleUpdateState = (paymentsConcept) => {
        const { componentState, dispatch } = this.props;
        this.setState((prevState) => ({
            ...prevState,
            showConcepts: true,
            multipleConcepts: paymentsConcept.length > 1,
        }));
        dispatch(
            servicePaymentsActions.updateComponetState({
                ...componentState,
                showConcepts: true,
                multipleConcepts: paymentsConcept.length > 1,
            }),
        );
    };

    handleAddService = () => {
        const { componentState, dispatch } = this.props;
        this.setState((prevState) => ({
            ...prevState,
            isAddServiceSelected: !prevState.isAddServiceSelected,
        }));
        const { isAddServiceSelected } = componentState;
        dispatch(
            servicePaymentsActions.updateComponetState({
                ...componentState,
                isAddServiceSelected: !isAddServiceSelected,
            }),
        );
    };

    handleBack = () => {
        const { dispatch, isReturnFromDetail } = this.props;
        dispatch(paymentsAFIPActions.setIsBackFromOtherPage(isReturnFromDetail));
    };

    getConceptOptions = (renderOption) => {
        const { paymentsConcept } = this.props;
        const options = paymentsConcept
            ? paymentsConcept
                  .filter((_, index) => index === renderOption)
                  .map((concept) => ({ id: concept.conceptCode, label: "" }))
            : [];
        return options;
    };

    getExpirationOptions = (renderOption) => {
        const { paymentsConcept } = this.props;
        const options = paymentsConcept.expirations
            ? paymentsConcept.expirations
                  .filter((x, index) => index === renderOption)
                  .map((expiration) => ({ id: expiration.debtId, label: "" }))
            : [];
        return options;
    };

    handleRubroChange = (value) => {
        const { dispatch, componentState } = this.props;
        dispatch(servicePaymentsActions.partialCleanUpWithRubros());
        this.setState({ multipleConcepts: false, isEntitiesDisabled: true, showConcepts: false });
        dispatch(servicePaymentsActions.listEntities(value));
        this.setState((prevState) => ({
            ...prevState,
            isEntitiesDisabled: false,
        }));
        dispatch(
            servicePaymentsActions.updateComponetState({
                ...componentState,
                multipleConcepts: false,
                showConcepts: false,
                isEntitiesDisabled: false,
            }),
        );
    };

    handleSelectConcept = (conceptCode) => {
        const { dispatch } = this.props;
        dispatch(servicePaymentsActions.selectConcept(conceptCode));
    };

    renderConceptsBody = (paymentsConcept, statusCPE, isDesktop) => {
        const currencies = [{ id: 0, label: i18nUtils.get(`currency.label.0`) }];

        if (!statusCPE?.handleBaseDebts) {
            return (
                <ConceptWithoutBaseDebt
                    FORM_ID={FORM_ID}
                    concepts={paymentsConcept}
                    statusCPE={statusCPE}
                    getRadioOptions={this.getConceptOptions}
                    currencies={currencies}
                    isDesktop={isDesktop}
                />
            );
        }

        return (
            <ConceptWithBaseDebt
                FORM_ID={FORM_ID}
                expirations={paymentsConcept.expirations}
                getRadioOptions={this.getExpirationOptions}
                currencies={currencies}
                isDesktop={isDesktop}
            />
        );
    };

    renderConcepts = () => {
        const { accounts, values, isDesktop, paymentsConcept, statusCPE } = this.props;
        const { account } = values;
        const { showConcepts, isAddServiceSelected } = this.state;

        const handleIncomeReference = statusCPE?.handleIncomeReference;

        if (!showConcepts) {
            return <></>;
        }

        const accountsToShow = accounts.filter((accountToShow) => accountToShow.currency === "0");

        return (
            accountsToShow &&
            paymentsConcept && (
                <>
                    <Container
                        className={classNames(
                            "flex-grow scrollable container-white",
                            { "my-2": !isDesktop },
                            { "px-3": isDesktop },
                            { "mb-2": !statusCPE?.handleBaseDebts },
                        )}>
                        {/* payment-detail-mobile */}
                        <Col
                            className={classNames("px-0 px-xl-0 my-1 my-md-3 payments-taxes-width", {
                                "pb-2": !isDesktop && !statusCPE?.handleBaseDebts,
                                "px-md-0": isDesktop && statusCPE?.handleBaseDebts,
                                "px-md-3": isDesktop && !statusCPE?.handleBaseDebts,
                            })}
                            sm={12}
                            md={9}
                            xl={8}>
                            {isDesktop ? (
                                <div className="d-flex flex-column w-100 bd-spacing-0-2">
                                    <Row className="mx-0 mb-2">
                                        <Col className="d-flex justify-content-start pl-md-0" xs={12} md={6}>
                                            <I18n
                                                componentProps={{ className: "text-uppercase f-size-55" }}
                                                id={`${FORM_ID}.amountToPay`}
                                            />
                                        </Col>

                                        <Col className="d-flex justify-content-center" xs={6} md={3}>
                                            <I18n
                                                componentProps={{ className: "text-uppercase f-size-55" }}
                                                id={`${FORM_ID}.expiration`}
                                            />
                                        </Col>
                                        <Col className="d-flex justify-content-end px-0" xs={6} md={3}>
                                            <I18n
                                                componentProps={{ className: "text-uppercase f-size-55" }}
                                                id={`${FORM_ID}.concept`}
                                            />
                                        </Col>
                                    </Row>
                                    {this.renderConceptsBody(paymentsConcept, statusCPE)}
                                </div>
                            ) : (
                                <>{this.renderConceptsBody(paymentsConcept, statusCPE, isDesktop)}</>
                            )}
                        </Col>
                    </Container>

                    <SelectPaymentMethod
                        FORM_ID={FORM_ID}
                        accounts={accountsToShow}
                        account={account}
                        isDesktop={isDesktop}
                        statusCPE={statusCPE}
                        handleAddService={this.handleAddService}
                        isAddServiceSelected={isAddServiceSelected}
                        handleIncomeReference={handleIncomeReference}
                    />
                </>
            )
        );
    };

    rightContent = () => <></>;

    renderContent = () => {
        const { rubros, isSubmittingConcepts, values, statusCPE, submitForm, entities, isDesktop } = this.props;
        const { showConcepts, isAddServiceSelected, isEntitiesDisabled } = this.state;
        const isContinueButtonDisabled = !(values.rubros && values.entities && values.cpe);

        if (!rubros) {
            return <> </>;
        }

        return (
            <>
                <Notification scopeToShow="servicePayments" />
                {isDesktop ? (
                    <div className="admin-detail-head px-0" style={{ marginBottom: "0" }}>
                        <Head onBack={this.handleBack} />
                        <Head title={FORM_ID} />
                    </div>
                ) : (
                    <div className="admin-detail-head px-0" style={{ marginBottom: "0" }}>
                        <Head
                            onBack={this.handleBack}
                            title="services.newPayment"
                            rightContent={this.rightContent}
                            headerClassName="blue-main-header-mobile new-payment-title-mobile"
                            centerContentClassName="mx-5 align-center"
                            emptyRightOption
                        />
                    </div>
                )}
                <MainContainer>
                    <div className="above-the-fold payments-taxes">
                        <Form className="d-flex f-dir-col min-height-vh-page">
                            <SelectPaymentInfo
                                FORM_ID={FORM_ID}
                                isFetching={isSubmittingConcepts}
                                isEntitiesDisabled={isEntitiesDisabled}
                                rubros={rubros}
                                entities={entities}
                                handleRubroChange={this.handleRubroChange}
                                isDesktop={isDesktop}
                            />
                            {this.renderConcepts()}
                            <ContinueButton
                                showConcepts={showConcepts}
                                isSubmittingConcepts={isSubmittingConcepts}
                                addService={isAddServiceSelected}
                                isDisabled={isContinueButtonDisabled}
                                handleBaseDebts={statusCPE?.handleBaseDebts}
                                submitForm={submitForm}
                                className={`align-items-end pt-2 ${!isDesktop && "px-3"}`}
                                btnContainerClassName="max-xl-480 px-xl-0"
                                buttonClassName="w-100"
                                isDesktop={isDesktop}
                            />
                        </Form>
                    </div>
                </MainContainer>
            </>
        );
    };

    render() {
        const { isFetching } = this.props;
        return <PageLoading loading={isFetching}>{this.renderContent()}</PageLoading>;
    }
}

const handleCheckCPE = (formikBag, values) => {
    const { dispatch } = formikBag.props;

    dispatch(servicePaymentsActions.updateComponentValues({ ...values }));
    dispatch(servicePaymentsActions.checkCpe(values.entities, values.cpe));
};

const handlePreparePayment = (formikBag, values, serviceReference) => {
    const { dispatch, rubros, statusCPE, paymentsConcept, accounts, componentState } = formikBag.props;

    const selectedId = values.concept;
    const selectedRubro = rubros.find((rubro) => rubro.code === values.rubros);
    const selectedEntity = statusCPE;
    const selectedCPE = values.cpe;
    const selectedDebitAccount = accounts.find((account) => account.idProduct === values.account);
    const { paymentReference } = values;
    const selectedAmountToPay = values[`conceptAmount${selectedId}`];
    const { isAddServiceSelected } = componentState;

    let debtId = "";
    let selectedConceptExpirationDate;
    let selectedConcept;
    let conceptCode;

    if (statusCPE?.handleBaseDebts) {
        const selectedVencimiento = paymentsConcept.expirations.find(
            (vencimiento) => vencimiento?.debtId === selectedId,
        );
        selectedConceptExpirationDate = selectedVencimiento.date;
        selectedConcept = {
            ...selectedVencimiento.concept,
            originalAmount:
                selectedVencimiento.concept.originalAmount === 0
                    ? values.expirationAmount
                    : selectedVencimiento.concept.originalAmount,
        };
        ({ debtId } = selectedVencimiento);
        ({ conceptCode } = selectedConcept);
    } else {
        selectedConceptExpirationDate = i18nUtils.get("services.newPayment.noExpirationDate");
        selectedConcept = paymentsConcept.find((concept) => concept.conceptCode === selectedId);
        conceptCode = selectedId;
    }

    dispatch(
        servicePaymentsActions.preparePayment(
            {
                selectedRubro,
                selectedEntity,
                selectedCPE,
                selectedAmountToPay,
                selectedConceptExpirationDate,
                selectedConcept,
                selectedDebitAccount,
                paymentReference,
                statusCPE,
                serviceReference,
                debtId,
                conceptCode,
                isAddServiceSelected,
            },
            formikBag,
        ),
    );
};

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    accounts: accountsSelector.getAccounts(state),
    rubros: servicePaymentsSelectors.getRubros(state),
    isFetching: servicePaymentsSelectors.getFetching(state),
    entities: servicePaymentsSelectors.getEntities(state),
    paymentsConcept: servicePaymentsSelectors.getPaymentsConcept(state),
    isSubmittingConcepts: servicePaymentsSelectors.getIsSubmittingConcepts(state),
    selectedConcept: servicePaymentsSelectors.getSelectedConcept(state),
    statusCPE: servicePaymentsSelectors.getStatusCPE(state),
    componentState: servicePaymentsSelectors.getComponetState(state),
    componentValues: servicePaymentsSelectors.getComponentValues(state),
    summaryInfo: servicePaymentsSelectors.getSummaryInfo(state),
    isReturnFromDetail: paymentsAFIPSelectors.getIsReturnFromDetail(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: (props) => {
            let initialValues = {
                ...props.componentValues,
            };
            const { paymentsConcept } = props;

            if (props.summaryInfo) {
                const {
                    selectedRubro,
                    selectedEntity,
                    selectedCPE,
                    paymentReference,
                    selectedDebitAccount,
                    selectedAmountToPay,
                    conceptCode,
                    debtId,
                    statusCPE,
                } = props.summaryInfo;
                let selectedId;
                if (statusCPE?.handleBaseDebts) {
                    selectedId = debtId;
                } else {
                    selectedId = conceptCode;
                }
                initialValues = {
                    ...initialValues,
                    rubros: selectedRubro?.code,
                    entities: selectedEntity?.code,
                    cpe: selectedCPE,
                    paymentReference,
                    account: selectedDebitAccount?.idProduct,
                    [`conceptAmount${selectedId}`]: selectedAmountToPay,
                    concept: selectedId,
                };
            }
            if (paymentsConcept) {
                if (!props.statusCPE?.handleBaseDebts) {
                    initialValues = paymentsConcept.reduce(
                        (prev, concept) => ({
                            ...prev,
                            [`conceptAmount${concept.conceptCode}`]: prev[`conceptAmount${concept.conceptCode}`] || "",
                            concept: prev.concept ? prev.concept : concept.conceptCode,
                        }),
                        initialValues,
                    );
                } else {
                    initialValues = paymentsConcept.expirations.reduce(
                        (prev, expiration) => ({
                            ...prev,
                            [`conceptAmount${expiration.debtId}`]:
                                prev[`conceptAmount${expiration.debtId}`] || expiration.amount || "",
                            reference: paymentsConcept.entity.adhesion.userLp,
                            concept: prev.concept ? prev.concept : expiration.debtId,
                            expirationAmount: expiration.amount,
                        }),
                        initialValues,
                    );
                }
            }

            return initialValues;
        },

        validationSchema: (props) => {
            const { showConcepts } = props.componentState;

            return Yup.lazy(() =>
                Yup.object().shape({
                    rubros: Yup.string().required(i18nUtils.get("services.payment.field.isRequired")),
                    entities: Yup.string().required(i18nUtils.get("services.payment.field.isRequired")),
                    cpe: Yup.string()
                        .matches(/^[0-9]*$/, i18nUtils.get("services.payment.field.isNotNumeric"))
                        .required(i18nUtils.get("services.payment.field.isRequired")),
                    account: showConcepts
                        ? Yup.string().required(i18nUtils.get("services.payment.field.isRequired"))
                        : Yup.mixed().nullable(),
                    paymentReference: Yup.string().matches(
                        /^[0-9]*$/,
                        i18nUtils.get("services.payment.field.isNotNumeric"),
                    ),
                }),
            );
        },
        handleSubmit: (values, formikBag) => {
            const { componentState } = formikBag.props;
            const { showConcepts } = componentState;

            if (!showConcepts) {
                handleCheckCPE(formikBag, values);
            } else {
                handlePreparePayment(formikBag, values, values.reference || "NO_REFERENCE");
            }
        },
    }),
)(ServiceNewPayment);
