import React from "react";
import { arrayOf, shape, string, func, bool, oneOfType, object } from "prop-types";

import formField from "pages/forms/_components/_fields/_commons/formField";
import Select from "pages/forms/_components/_fields/Select";

class Countrylist extends React.Component {
    static propTypes = {
        data: shape({
            countries: arrayOf(shape({ id: string, name: string })).isRequired,
        }).isRequired,
        setValue: func.isRequired,
        editing: bool.isRequired,
        placeholder: string,
        value: oneOfType([string, object]),
        idField: string.isRequired,
    };

    static defaultProps = {
        placeholder: "",
        value: "" || {},
    };

    handleChange = ({ id }) => {
        const { setValue } = this.props;
        setValue(id);
    };

    render() {
        const {
            editing,
            value,
            placeholder,
            data: { countries },
            idField,
        } = this.props;

        if (!editing) {
            const { name } = countries.find(({ id }) => id === value) || {};
            return name || null;
        }
        return (
            <div className="input-group">
                <div style={{ flex: 1 }}>
                    <Select
                        id={idField}
                        placeholder={placeholder}
                        value={value}
                        clearable={false}
                        searchable={false}
                        onChange={this.handleChange}
                        valueKey="id"
                        labelKey="name"
                        options={countries}
                        className="flex-container slideFromBottom"
                        optionClassName="needsclick"
                    />
                </div>
            </div>
        );
    }
}
export default formField({
    formClass: "form-group--select",
})(Countrylist);
