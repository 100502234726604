import React from "react";
import { string, bool } from "prop-types";
import * as i18nUtils from "util/i18n";

function ChecksDetailViewReceived({
    payDay,
    payMonth,
    payYear,
    firstLine,
    secondLine,
    echeqNro,
    denominacionLibrador,
    denominacionLibradorNro,
    amount,
    order,
    largeNumber,
    bankName,
    showOrder,
}) {
    ChecksDetailViewReceived.propTypes = {
        payDay: string.isRequired,
        payMonth: string.isRequired,
        payYear: string.isRequired,
        firstLine: string.isRequired,
        secondLine: string,
        echeqNro: string.isRequired,
        amount: string.isRequired,
        denominacionLibrador: string.isRequired,
        denominacionLibradorNro: string.isRequired,
        order: string.isRequired,
        largeNumber: string.isRequired,
        bankName: string.isRequired,
        showOrder: bool,
    };

    ChecksDetailViewReceived.defaultProps = {
        secondLine: "",
        showOrder: true,
    };

    const getBankNameArray = () => {
        const arr = bankName.split(" ");
        const finalArray = new Array(2).fill("");
        arr.map((s) => {
            const i = finalArray[1].length > 0 || finalArray[0].length + s.length > 20 ? 1 : 0;
            finalArray[i] = finalArray[i].concat(`${finalArray[i].length > 0 ? " " : ""}${s}`);
            return true;
        });

        return finalArray;
    };

    const bankNameArray = getBankNameArray();

    return (
        <>
            <div className="check-image deposit">
                <div className="d-flex">
                    <div className="w-50">
                        <div className="d-flex flex-column medium-font line-height-normal">
                            <span>{bankNameArray[0]}</span>
                            <span>{bankNameArray[1]}</span>
                        </div>
                        <div className="f-size-65 pt-1">
                            {denominacionLibrador} - {denominacionLibradorNro}
                        </div>
                    </div>
                    <div className="w-50 text-right">
                        <div className="font-light">
                            {i18nUtils.get("checksDetail.info.chequeNro")} {echeqNro}
                        </div>
                        <div className="medium-font f-size-25">{amount}</div>
                        <div className={showOrder && (order === "NO A LA ORDEN" ? "red-border" : "black-border")}>
                            {showOrder && order}
                        </div>
                    </div>
                </div>
                <div className="d-flex text-uppercase font-light pt-1">
                    {i18nUtils.get("checksDetail.info.el")}
                    <span className="font-regular border-bottom px-2 mx-2">{payDay}</span>
                    {i18nUtils.get("checksDetail.info.de")}
                    <span className="font-regular border-bottom px-3 mx-2">{payMonth}</span>
                    {i18nUtils.get("checksDetail.info.de")}
                    <span className="font-regular border-bottom px-2 mx-2">{payYear}</span>
                </div>
                <div className="d-flex w-100 pt-1">
                    <span className="text-uppercase font-light no-wrap add-colon">
                        {i18nUtils.get("checksDetail.info.pagueseA")}
                    </span>
                    <span className="border-bottom px-2 ml-2 w-100">{firstLine}</span>
                </div>
                <div className="border-bottom pt-1">{secondLine}</div>
                <div>{largeNumber}</div>
            </div>
        </>
    );
}

export default ChecksDetailViewReceived;
