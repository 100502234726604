import React from "react";
import { Col, Modal } from "react-bootstrap";
import { bool, string, func, shape } from "prop-types";
import classNames from "classnames";
import { Form, withFormik } from "formik";
import { compose } from "redux";
import { connect } from "react-redux";

import * as i18n from "util/i18n";
import * as stringUtils from "util/string";

import { actions as fundcorpActions, selectors as fundcorpSelectors } from "reducers/fundcorp";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";

function InfoRiskLevelModal({
    isVisible,
    isDesktop,
    toggleModal,
    selectedFundFeature,
    investorProfile,
    FORM_ID,
    handleResetModal,
}) {
    const profile = !stringUtils.isEmpty(investorProfile?.investorProfileType?.trim())
        ? i18n.get(`fund.operation.registerShareAccount.investorProfile.type.${investorProfile?.investorProfileType}`)
        : "-";

    const contentInfo = () => (
        <Form>
            <div className="w-100 align-items-start flex-column pb-4">
                <Col className="cmf-container-white col-12">
                    <div className="admin-content-center">
                        <Image
                            src="images/info-warning.svg"
                            styles={{
                                width: isDesktop ? "90px" : "65px",
                                height: isDesktop ? "90px" : "65px",
                                transform: "rotate(180deg)",
                            }}
                        />
                    </div>
                </Col>
                <Col className="col-12 px-4">
                    <I18n
                        component="p"
                        componentProps={{ className: "text-center px-4" }}
                        id={`${FORM_ID}.riskLevel.message`}
                        INVESTOR_PROFILE_TYPE={profile}
                        FUND_RISK_LEVEL={selectedFundFeature?.regionDescripcion?.toUpperCase()}
                    />
                </Col>
                <Col
                    className={classNames("text-center", {
                        "f-dir-col-reverse d-flex": !isDesktop,
                    })}
                    md={12}>
                    <Button
                        block={false}
                        type="button"
                        bsStyle="outline"
                        label="global.no"
                        className="px-5 mx-2"
                        onClick={handleResetModal}
                    />
                    <Button block={false} type="submit" bsStyle="primary" label="global.yes" className="px-5 mx-2" />
                </Col>
            </div>
        </Form>
    );

    return (
        <Modal
            show={isVisible}
            className="transfer-template-modal risk-label"
            bsClass="no-radius modal"
            onHide={() => toggleModal(false)}>
            <Modal.Header closeButton className="no-title min-height-3" />
            <Modal.Body className="pb-2 show">{contentInfo()}</Modal.Body>
        </Modal>
    );
}

InfoRiskLevelModal.propTypes = {
    isDesktop: bool,
    isVisible: bool,
    FORM_ID: string.isRequired,
    toggleModal: func.isRequired,
    handleResetModal: func.isRequired,
    selectedFundFeature: shape({}).isRequired,
    investorProfile: shape({}).isRequired,
};

InfoRiskLevelModal.defaultProps = {
    isDesktop: true,
    isVisible: false,
};

const mapStateToProps = (state) => ({
    summary: fundcorpSelectors.getSummary(state),
    investorProfile: fundcorpSelectors.getInvestorProfile(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        handleSubmit: (_, formikBag) => {
            const { dispatch, summary, toggleModal } = formikBag.props;
            toggleModal(false);
            dispatch(fundcorpActions.createSubscribeRequest(summary, formikBag));
        },
    }),
)(InfoRiskLevelModal);
