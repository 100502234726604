import React from "react";
import classNames from "classnames";
import { shape, string, bool, func } from "prop-types";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FieldHelp from "pages/_components/fields/FieldHelp";
import FieldHint from "pages/_components/fields/FieldHint";

const formField = (
    options = {
        /* render component without label/error wrapper */
        pureRender: false,
        /* string | (props) => string */
        formClass: "",
        /* (value, props) => Boolean */
        isValidValue: null,
        isEmptyValue: null,
        renderLegend: null,
        /* (props) => <Element /> */
        customLabel: () => null,
    },
) => (FormFieldComponent) =>
    class extends React.Component {
        static getDerivedStateFromProps(nextProps) {
            const { form, fieldList, dependsOnProduct } = nextProps;

            if (!dependsOnProduct) {
                return {};
            }

            const dependencyValue = form.values[dependsOnProduct];
            const dependencyField = fieldList.find(({ idField }) => idField === dependsOnProduct) || {};

            return {
                dependencyValue,
                dependencyField,
            };
        }

        static displayName = `field(${FormFieldComponent.name})`;

        static propTypes = {
            optionalMessageMap: shape({}).isRequired,
            placeholderMap: shape({}).isRequired,
            helpMap: shape({}).isRequired,
            hintMap: shape({}).isRequired,
            dateFormat: string.isRequired,
            field: shape({}).isRequired.isRequired,
            form: shape({}).isRequired.isRequired,
            lang: string.isRequired,
            isRequired: bool.isRequired,
            readOnly: bool.isRequired,
            mode: string.isRequired,
            fieldList: shape({ find: func }).isRequired,
            dependsOnProduct: string.isRequired,
            defaultValue: string,
            value: string.isRequired,
            idValidation: string.isRequired,
            isFocused: bool.isRequired,
            idField: string,
            idForm: string.isRequired,
            ticketOnly: bool.isRequired,
            type: string,
            formGroupClassName: string,
        };

        static defaultProps = {
            defaultValue: "",
            idField: "",
            type: "",
            formGroupClassName: "",
        };

        constructor(props) {
            super(props);
            this.state = {
                tags: [],
            };
        }

        componentDidMount() {
            const { defaultValue, field, value } = this.props;
            if (!this.isEmptyValue(defaultValue) && this.isEmptyValue(field.value)) {
                this.setValue(defaultValue);
            } else if (this.isEmptyValue(field.value) && !this.isEmptyValue(value)) {
                this.setValue(value);
            }
        }

        customLabel = (props) => {
            if (typeof options.customLabel === "function") {
                return options.customLabel(props);
            }
            return null;
        };

        setTouched = () => {
            const {
                form: { setFieldTouched },
                field: { name },
            } = this.props;
            setFieldTouched(name);
        };

        isTouched = () => {
            const {
                form: { touched },
                field: { name },
            } = this.props;
            return !!touched[name];
        };

        setError = (msg) => {
            const {
                form: { errors, setErrors },
                field: { name },
            } = this.props;

            if (msg) {
                setErrors({ ...errors, [name]: msg });
            } else {
                const { [name]: error, ...rest } = errors;
                setErrors(rest);
            }
        };

        hasError = () => !!this.errorText();

        errorText = () => {
            const {
                form: { errors },
                field: { name },
            } = this.props;

            return errors[name];
        };

        setAllTagValue = (value) => {
            const {
                form: { setFieldValue },
                field: { name },
            } = this.props;

            const newAddedItems = value.map((item) => item.id);
            this.setState({ tags: newAddedItems }, () => {
                // eslint-disable-next-line react/destructuring-assignment
                setFieldValue(name, this.state.tags); // This has to be this way as it has to reflect the updated value
            });
        };

        setTagValue = (value) => {
            const {
                form: { setFieldValue },
                field: { name },
            } = this.props;

            const { tags } = this.state;

            const newTags = [...tags, value.id];
            this.setState({ tags: newTags }, () => {
                // eslint-disable-next-line react/destructuring-assignment
                setFieldValue(name, this.state.tags); // This has to be this way as it has to reflect the updated value
                this.validate(value);
            });
        };

        removeTagValue = (i) => {
            const {
                form: { setFieldValue },
                field: { name },
            } = this.props;

            const { tags } = this.state;
            const newTags = tags.filter((tag, index) => index !== i);
            setFieldValue(name, newTags);
            this.setState({ tags: newTags }, () => {});
        };

        setValue = (value) => {
            const {
                form: { setFieldValue },
                field: { name },
                idValidation,
            } = this.props;

            if (idValidation === "email" || this.isValidValue(value)) {
                setFieldValue(name, value);
                this.validate(value);
            }
        };

        validate = (value) => {
            const { isRequired } = this.props;

            if (isRequired && this.isEmptyValue(value)) {
                this.setError(this.i18n("requiredError"));
                return false;
            }

            this.setError(null);

            return true;
        };

        onBlur = () => {
            this.setTouched();
        };

        i18n = (type) => {
            const { lang } = this.props;
            const map = this.props[`${type}Map`] || {};
            return map[lang];
        };

        isValidValue = (value) => {
            if (value == null) {
                return false;
            }
            if (typeof options.isValidValue === "function") {
                const props = this.componentProps();
                return options.isValidValue(value, props);
            }
            return true;
        };

        isEmptyValue = (value) => {
            if (value == null) {
                return true;
            }
            if (Array.isArray(value)) {
                let retorno = true;
                value.forEach((val) => {
                    if (val !== "") {
                        retorno = false;
                    }
                });
                return retorno;
            }
            if (typeof options.isEmptyValue === "function") {
                const props = this.componentProps();
                return options.isEmptyValue(value, props);
            }
            return value == null || value === "";
        };

        formClass = (props) => {
            if (typeof options.formClass === "function") {
                return options.formClass(props);
            }
            if (typeof options.formClass === "string") {
                return options.formClass;
            }
            return "";
        };

        componentProps = () => {
            const { mode, readOnly, field, value, idForm } = this.props;
            let { idField } = this.props;
            const { dependencyField, dependencyValue } = this.state;
            if (!idField) {
                idField = `${idForm}.${idField}`;
            }

            return {
                ...this.props,
                idField,
                name: field.name,
                value: field.value || value,
                label: this.i18n("label"),
                placeholder: this.i18n("placeholder"),
                optionalMessage: this.i18n("optionalMessage"),
                editing: mode === "edit" && !readOnly,
                i18n: this.i18n,
                setTagValue: this.setTagValue,
                setAllTagValue: this.setAllTagValue,
                removeTagValue: this.removeTagValue,
                setValue: this.setValue,
                setError: this.setError,
                onBlur: this.onBlur,
                setTouched: this.setTouched,
                dependencyField,
                dependencyValue,
            };
        };

        render() {
            const Component = options.renderLegend ? "fieldset" : "div";
            const props = this.componentProps();
            const { idField } = props;
            if (options.pureRender) {
                return <FormFieldComponent {...props} />;
            }

            const {
                mode,
                isRequired,
                isFocused,
                readOnly,
                ticketOnly,
                field: { value },
                type,
                formGroupClassName,
            } = this.props;

            const formClass = this.formClass(props);

            if ((mode === "view" || mode === "preview" || readOnly) && !this.isEmptyValue(value)) {
                return (
                    <div className={classNames("data-wrapper space-between", formClass)}>
                        {type !== "coordinates" &&
                            (this.customLabel(props) || (
                                <FieldLabel labelText={this.i18n("label")} mode="view" idField={idField} />
                            ))}
                        <FormFieldComponent idField={idField} {...props} />
                    </div>
                );
            }
            if (mode === "edit" && !ticketOnly) {
                return (
                    <Component
                        className={classNames("form-group", formClass, formGroupClassName, {
                            "has-error": this.hasError() && this.isTouched(),
                            "has-focus": isFocused,
                        })}>
                        {type !== "coordinates" &&
                            (this.customLabel(props) || (
                                <FieldLabel
                                    labelText={this.i18n("label")}
                                    optional={isRequired ? "" : this.i18n("optionalMessage")}
                                    idField={idField}
                                    isLegend={options.renderLegend}
                                />
                            ))}

                        <FormFieldComponent idField={idField} {...props} />

                        {this.hasError() && this.isTouched() && <FieldError error={this.errorText()} />}
                        {this.i18n("help") && <FieldHelp text={this.i18n("help")} />}
                        {this.i18n("hint") && <FieldHint text={this.i18n("hint")} />}
                    </Component>
                );
            }
            return null;
        }
    };

export default formField;
