import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool, shape, string, arrayOf } from "prop-types";
import { push } from "react-router-redux/actions";

import { actions as settingsActions } from "reducers/settings";
import { actions as tokenActions, selectors as tokenSelectors } from "reducers/token";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as statusActions } from "reducers/status";

import * as i18n from "util/i18n";
import getLoggedUserId from "util/session";

import I18n from "pages/_components/I18n";
import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import TokenActived from "pages/settings/_components/token/TokenActive";
import TokenDisabled from "pages/settings/_components/token/TokenDisabled";

class Token extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        loggedUser: shape({
            previousLoginInfo: arrayOf(
                shape({
                    idUser: string,
                }),
            ),
        }).isRequired,
        fetching: bool.isRequired,
        userHasActiveToken: bool.isRequired,
        userHasBloquedToken: bool.isRequired,
        isTokenActiveInDevice: bool.isRequired,
    };

    state = {
        showMessage: false,
    };

    componentDidMount() {
        const { dispatch, location } = this.props;
        dispatch(statusActions.saveLastHref(location));
    }

    componentWillMount() {
        const { dispatch, isMobileNative } = this.props;
        dispatch(tokenActions.checkUserActiveToken());
        dispatch(settingsActions.getUserData());
        if (isMobileNative) {
            dispatch(tokenActions.tokenStatusPasswordRequest(getLoggedUserId()));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(push("/desktop"));
    };

    getMobileContent = () => (
        <div className="above-the-fold">
            <div className="mx-3">{this.renderDevices()}</div>
        </div>
    );

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("settings.token")}</h1>
        </div>
    );

    codeActivation = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/token/codeActivation"));
    };

    contents = () => {
        const { fetching, isMobileNative, userHasBloquedToken, userHasActiveToken, isTokenActiveInDevice } = this.props;
        let isActive = false;

        if (userHasBloquedToken) {
            isActive = true;
        } else if (userHasActiveToken) {
            if (!isMobileNative || isTokenActiveInDevice) {
                isActive = true;
            }
        }

        return (
            <PageLoading loading={fetching}>
                {isActive ? <TokenActived {...this.props} /> : <TokenDisabled {...this.props} />}
            </PageLoading>
        );
    };

    render() {
        const { isMobile } = this.props;
        const { showMessage } = this.state;

        return (
            <>
                <Notification scopeToShow="tokenCreateCodeInvitation" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={isMobile ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isMobile ? this.centerContentMobile : null}
                    />
                    {!isMobile ? (
                        <div className="view-title d-flex mb-3">
                            <I18n id="settings.token" component="h1" />
                        </div>
                    ) : (
                        <div
                            style={{
                                marginLeft: "1rem",
                                marginRight: "1rem",
                                display: showMessage ? "block" : "none",
                            }}>
                            <I18n
                                id="settings.token"
                                component="p"
                                componentProps={{
                                    className: "hint-text my-0 f-size-55",
                                }}
                            />
                        </div>
                    )}
                </div>

                {this.contents()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    fetching: tokenSelectors.getFetching(state),
    userHasActiveToken: tokenSelectors.userHasActiveToken(state),
    isTokenActiveInDevice: tokenSelectors.getIsTokenActiveInThisDevice(state),
    userHasBloquedToken: tokenSelectors.getIsTokenBlocked(state),
});

export default connect(mapStateToProps)(Token);
