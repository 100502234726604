/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, func, string, bool } from "prop-types";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import { selectors } from "reducers/status";
import { actions as sessionActions } from "reducers/session";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import ModalContent from "./_components/ModalContent";
import ModalPrimaryAction from "./_components/ModalPrimaryAction";

class SessionAboutToExpire extends Component {
    // eslint-disable-next-line react/sort-comp
    static propTypes = {
        dispatch: func.isRequired,
        status: shape({
            sessionAboutToExpire: bool.isRequired,
        }).isRequired,
        location: shape({
            href: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
    };

    static getDerivedStateFromProps({ status }, { seconds }) {
        if (status.sessionAboutToExpire && seconds === 0) {
            return { seconds: status.sessionSecondsToExpire };
        }
        if (!status.sessionAboutToExpire && seconds !== 0) {
            return { seconds: 0 };
        }
    }

    state = { seconds: 0 };

    interval = null;

    componentDidUpdate() {
        const { status } = this.props;

        if (!this.interval && status.sessionAboutToExpire) {
            this.interval = setInterval(this.decreaseSeconds, 1000);
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    decreaseSeconds = () => {
        const { seconds } = this.state;
        const { location, dispatch } = this.props;

        if (seconds > 1) {
            this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
        } else {
            dispatch(sessionActions.expire(location.href));
        }
    };

    extendSession = () => {
        const { dispatch } = this.props;

        dispatch(sessionActions.extend());
        clearInterval(this.interval);
        this.interval = null;
    };

    render() {
        const { status, isDesktop } = this.props;
        const { seconds } = this.state;

        return (
            <div className="modal-container">
                <Modal
                    show={status.sessionAboutToExpire}
                    className="transfer-template-modal session-to-expire"
                    dialogClassName="session-to-expire-modal">
                    <Modal.Header className={!isDesktop ? "without-margin-top" : ""}>
                        <Image src="images/session-expire.svg" />
                        <Modal.Title componentClass="div">
                            <I18n
                                id="session.aboutToExpire"
                                component="h3"
                                componentProps={{ className: "session-expire-text-title" }}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="session-timeout-modal-body">
                        <ModalContent seconds={seconds} />
                    </Modal.Body>
                    <Modal.Footer className="mt-0 w-100">
                        <ModalPrimaryAction handleClick={this.extendSession} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ status: selectors.getStatus(state) });

export default connect(mapStateToProps)(SessionAboutToExpire);
