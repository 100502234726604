import { Form, Modal } from "@ui-kit/components/index.es";
import { bool, func, shape, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as i18n from "util/i18n";

import { selectors as templateSelectors, actions as templateActions } from "reducers/template";

function CreateTemplateModal(props) {
    CreateTemplateModal.propTypes = {
        dispatch: func.isRequired,
        isTemplateModalVisible: bool.isRequired,
        values: shape({}).isRequired,
        idActivityTemplate: string.isRequired,
        idForm: string.isRequired,
    };

    const parseAmount = (value) => {
        if (typeof value === "string") {
            return parseFloat(value.replaceAll(".", "").replace(",", "."));
        }
        return value;
    };

    const { isTemplateModalVisible, values } = props;

    const handleHideTemplateModal = () => {
        const { dispatch } = props;
        dispatch(templateActions.closeCreateModal());
    };

    const handleSubmitTemplate = (templateName) => {
        const { dispatch } = props;
        const { idForm, idActivityTemplate } = props;
        const templateValues = {
            ...values,
            amount: {
                amount: parseAmount(values.amount),
                currency: values.currency,
            },
        };
        dispatch(templateActions.saveTemplate(idForm, templateValues, templateName, null, idActivityTemplate));
    };

    return (
        <Modal
            ariaLabelledBy="modal-label"
            modalControlElementId="modal-button"
            closeOnBgTap
            onClose={() => {}}
            isVisible={isTemplateModalVisible}>
                <Modal.Header>{i18n.get("transfers.template.title")}</Modal.Header>
            <Form
                buttonSubmit={{}}
                initialValues={{}}
                inputsList={[
                    {
                        colProps: {
                            size: 12,
                        },
                        component: "textField",
                        id: "templateName",
                        label: i18n.get("transfers.template.name"),
                        placeholder: i18n.get("transfers.template.name"),
                        maxLength: 50,
                    },
                ]}
                buttonsList={[
                    {
                        colProps: {
                            size: {
                                xs: 7,
                                lg: 3,
                            },
                        },
                        component: "button",
                        key: "cancel",
                        text: i18n.get("global.cancel"),
                        variant: "text",
                        type: "button",
                        onClick: () => handleHideTemplateModal(),
                    },
                    {
                        colProps: {
                            size: {
                                xs: 7,
                                lg: 3,
                            },
                        },
                        component: "button",
                        key: "template",
                        text: i18n.get("global.accept"),
                        variant: "text",
                        type: "submit",
                        id: "modal-button",
                        onClick: () => {},
                    },
                ]}
                onSubmit={(template) => {
                    handleSubmitTemplate(template.templateName);
                }}
                rowSpacingForm={16}
                wrapButtons
            />
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    isTemplateModalVisible: templateSelectors.isCreateModalVisible(state),
});

export default withRouter(connect(mapStateToProps)(CreateTemplateModal));
