import React, { Component } from "react";
import { bool, shape, string, func } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";

import * as i18nUtils from "util/i18n";
import { resizableRoute } from "pages/_components/Resizable";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import Heading from "pages/_components/Heading";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import { selectors as financingSelectors, actions as financingActions } from "reducers/financing";
import InfoTag from "pages/_components/InfoTag";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";

class FeedsDetail extends Component {
    static propTypes = {
        match: shape({
            params: shape({
                id: string,
            }),
        }),
        id: string.isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        financingFeed: shape([]).isRequired,
        financingDetail: shape([]).isRequired,
        fetchingDownload: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        match: null,
    };

    componentDidMount() {}

    statusTag = (estado) => {
        switch (estado) {
            case 1:
                return "PAGADA";
            case 2:
                return "VENCIDA";
            case 3:
                return "VIGENTE";
            default:
                return "FUTURA";
        }
    };

    statusTagColor = (estado) => {
        switch (estado) {
            case 1:
                return "#84efd0";
            case 2:
                return "#feb0af";
            case 3:
                return "#ffe5b6";
            case 4:
                return "#ffe5b6";
            default:
                return "#84efd0";
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    getTitle = () => {
        const {
            financingFeed,
            financingDetail,
            match: { params },
            isDesktop,
        } = this.props;

        const { id } = params;
        const name = `${i18nUtils.get("feeds.detail.header.label")} ${financingFeed[id].numeroCuota}`;
        if (!financingFeed[id].estado) {
            return <></>;
        }

        if (isDesktop) {
            return (
                <>
                    <div className="data-wrapper-flex">
                        <h2 className={classNames({ "title-content my-0 mr-3": isDesktop })}>{name}</h2>
                        <InfoTag
                            type="info"
                            message={this.statusTag(financingFeed[id].estado)}
                            tagBackground={this.statusTagColor(financingFeed[id].estado)}
                            tagClass="px-2"
                            color="black"
                        />
                    </div>
                    <p className="text-uppercase px-3">
                        {financingDetail.descripcionTipoOperacion} | N° {financingDetail.numeroOperacion}{" "}
                    </p>
                </>
            );
        }
        return <h1 className="title-content">{name}</h1>;
    };

    handleExport = (format) => {
        const {
            dispatch,
            financingFeed,
            financingDetail,
            match: { params },
        } = this.props;
        const { id } = params;

        dispatch(financingActions.downloadFeed(format, financingFeed[id], financingDetail));
    };

    renderPageHeader = (fetchingDownload, id, financingFeed, isDesktop) => (
        <>
            <Notification scopeToShow="fiancing" />
            <div className="admin-detail-head px-0 mb-2">
                {isDesktop && <Head onBack={this.handleBack} />}
                {isDesktop && (
                    <Head
                        exportList={!isEmpty(financingFeed[id])}
                        handleClick={this.handleExport}
                        isFetchingExport={fetchingDownload}
                        handleClickMessage="global.download"
                        csvDownload
                        hasInlineButtons
                        centerElement={this.getTitle}
                        imageStyle="mr-2"
                    />
                )}
                {!isDesktop && (
                    <Head
                        onBack={!isDesktop && this.handleBack}
                        exportList={!isEmpty(financingFeed[id])}
                        handleClick={this.handleExport}
                        isFetchingExport={fetchingDownload}
                        handleClickMessage="global.download"
                        downloadImageWhite={!isDesktop}
                        addButtonImageWhite={!isDesktop}
                        uniqueDownload
                        centerElement={this.getTitle}
                        headerClassName={!isDesktop && "blue-main-header-mobile"}
                    />
                )}
            </div>
        </>
    );

    renderContent = () => {
        const {
            financingFeed,
            financingDetail,
            fetchingDownload,
            match: { params },
            isDesktop,
        } = this.props;
        const { id } = params;

        const other = financingFeed[id].importeGastos + financingFeed[id].compensatorio + financingFeed[id].punitorios;
        const containerClassName = isDesktop ? "transfer-data mb-0" : "d-flex space-between align-items-center";
        return (
            <>
                {this.renderPageHeader(fetchingDownload, id, financingFeed, isDesktop)}
                <MainContainer>
                    <div className="above-the-fold">
                        {!isDesktop && (
                            <Container
                                className="flex-grow my-2 with-padding text-uppercase mt-2"
                                rowClassName="justify-content-start">
                                <div className="d-flex f-dir-col w-100 ml-3">
                                    <p className="text-uppercase my-0 f-size-4 line-height-1">
                                        {financingDetail.descripcionTipoOperacion}
                                    </p>
                                    <p className="my-0 f-size-4 line-height-1">N° {financingDetail.numeroOperacion}</p>
                                    <InfoTag
                                        type="info"
                                        message={this.statusTag(financingFeed[id].estado)}
                                        tagBackground={this.statusTagColor(financingFeed[id].estado)}
                                        moreStyles={{
                                            width: "min-content",
                                            color: "black",
                                            margin: "0.5rem 0",
                                            padding: "6px 12px",
                                            fontWeight: "bold",
                                        }}
                                    />
                                </div>
                            </Container>
                        )}
                        <Container
                            className="flex-grow container-white my-2 with-padding text-uppercase"
                            rowClassName="justify-content-start">
                            <Col md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName="data-label-medium"
                                    label="feeds.detail.header.expirationDate"
                                    dataClassName={`${
                                        isDesktop ? "data-amount-currency-medium-font" : "font-weight-600"
                                    }`}
                                    data={
                                        financingFeed[id].fechaVencimiento ? (
                                            <FormattedDate
                                                date={financingFeed[id].fechaVencimiento}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        ) : (
                                            <p className="my-0"> - </p>
                                        )
                                    }
                                />
                            </Col>
                            <Col md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName="data-label-medium"
                                    label="feeds.detail.header.days"
                                    dataClassName={`${
                                        isDesktop ? "data-amount-currency-medium-font" : "font-weight-600"
                                    }`}
                                    data={
                                        <div>
                                            <span className={classNames({ "font-size-20-px": isDesktop })}>
                                                {financingFeed[id].diasAtraso}
                                            </span>
                                        </div>
                                    }
                                />
                            </Col>
                            <Col md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    label="feeds.detail.header.amount"
                                    labelClassName="data-label-medium"
                                    dataClassName={`${
                                        isDesktop ? "data-amount-currency-medium-font" : "font-weight-600"
                                    }`}
                                    data={
                                        financingFeed[id].saldoCuota ? (
                                            <FormattedAmount
                                                currency="ARS"
                                                quantity={financingFeed[id].saldoCuota}
                                                fontClassName="f-size-5"
                                                className={classNames({
                                                    "font-weight-600": !isDesktop,
                                                    "wrapper-data-amount-currency": isDesktop,
                                                })}
                                            />
                                        ) : (
                                            <p className="my-0"> - </p>
                                        )
                                    }
                                />
                            </Col>
                        </Container>
                        <Container className="flex-grow container-white my-2 with-padding text-uppercase">
                            <Col md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={classNames("d-flex space-between align-items-baseline", {
                                        "flex-column": isDesktop,
                                    })}
                                    dataClassName="f-size-5"
                                    label="feeds.detail.header.cap"
                                    data={
                                        financingFeed[id].capital ? (
                                            <FormattedAmount
                                                className={`font-light ${isDesktop ? "f-size-4" : "f-size-5"}`}
                                                currency="ARS"
                                                quantity={financingFeed[id].capital}
                                                notBold
                                            />
                                        ) : (
                                            <p className="my-0"> - </p>
                                        )
                                    }
                                />
                            </Col>
                            <Col md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={classNames("d-flex space-between align-items-baseline", {
                                        "flex-column": isDesktop,
                                    })}
                                    dataClassName="f-size-5"
                                    label="feeds.detail.header.interest"
                                    data={
                                        financingFeed[id].intereses ? (
                                            <FormattedAmount
                                                className={`font-light ${isDesktop ? "f-size-4" : "f-size-5"}`}
                                                currency="ARS"
                                                quantity={financingFeed[id].intereses}
                                                notBold
                                            />
                                        ) : (
                                            <p className="my-0"> - </p>
                                        )
                                    }
                                />
                            </Col>
                            <Col md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={classNames("d-flex space-between align-items-baseline", {
                                        "flex-column": isDesktop,
                                    })}
                                    dataClassName="f-size-5"
                                    label="feeds.detail.header.taxes"
                                    data={
                                        financingFeed[id].impuestos ? (
                                            <FormattedAmount
                                                className={`font-light ${isDesktop ? "f-size-4" : "f-size-5"}`}
                                                currency="ARS"
                                                quantity={financingFeed[id].impuestos}
                                                notBold
                                            />
                                        ) : (
                                            <p className="my-0"> - </p>
                                        )
                                    }
                                />
                            </Col>
                            <Col md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={classNames("d-flex space-between align-items-baseline", {
                                        "flex-column": isDesktop,
                                    })}
                                    dataClassName="f-size-5"
                                    label="feeds.detail.header.others"
                                    data={
                                        other ? (
                                            <FormattedAmount
                                                className={`font-light ${isDesktop ? "f-size-4" : "f-size-5"}`}
                                                currency="ARS"
                                                quantity={other}
                                                notBold
                                            />
                                        ) : (
                                            <p className="my-0"> - </p>
                                        )
                                    }
                                />
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    };

    render() {
        const { fetching } = this.props;

        return (
            <>
                <PageLoading loading={fetching}>{!fetching && this.renderContent()}</PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: financingSelectors.getFetching(state),
    financingFeed: financingSelectors.getFinancingFeed(state),
    financingDetail: financingSelectors.getFinancingDetail(state),
    fetchingDownload: financingSelectors.getFetchingDownload(state),
});

export default connect(mapStateToProps)(resizableRoute(FeedsDetail));
