import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { shape, func, string, arrayOf, number, bool } from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";

import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Head from "pages/_components/Head";
import AdministrationHeading from "pages/administration/_components/Heading";
import PageLoading from "pages/_components/PageLoading";
import MainContainer from "pages/_components/MainContainer";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import AmountField from "pages/_components/fields/formik/AmountField";
import Select from "pages/_components/fields/Select";
import Row from "react-bootstrap/lib/Row";
import Heading from "pages/_components/Heading";
import InfoTag from "pages/_components/InfoTag";
import Hint from "pages/_components/hints/Hint";
import { actions as notificationActions } from "reducers/notification";
import { Col } from "react-bootstrap";

class Channels extends Component {
    static propTypes = {
        actions: shape({ loadChannelsRequest: func.isRequired }).isRequired,
        routerActions: shape({ goBack: func.isRequired }).isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        enabledChannelsFrequencies: arrayOf(
            shape({
                value: string.isRequired,
                label: string.isRequired,
            }),
        ).isRequired,
        enabledChannels: arrayOf(string).isRequired,
        currencies: arrayOf(
            shape({
                id: string.isRequired,
                value: string.isRequired,
                label: string.isRequired,
            }),
        ).isRequired,
        nonRemovableChannels: arrayOf(string).isRequired,
        caps: shape({}).isRequired,
        topAmount: shape({
            maximum: number,
            frequency: string,
        }).isRequired,
        fetching: bool.isRequired,
        hasMassiveEnabled: bool,
        user: shape({}),
        dispatch: func.isRequired,
    };

    static defaultProps = {
        hasMassiveEnabled: false,
        user: null,
    };

    componentDidMount() {
        const { actions, match } = this.props;
        actions.loadChannelsRequest(match.params.id);
    }

    handleBack = () => {
        const { routerActions } = this.props;
        routerActions.goBack();
    };

    handleSubmit = (values, formikBag) => {
        const { match, actions, dispatch } = this.props;
        const { setSubmitting } = formikBag;
        const enabledChannels = ["topAmount", ...values.enabledChannels];
        if (values.channels?.topAmount?.amount >= 0 && values.channels?.topAmount?.amount !== "") {
            actions.updateChannelsPreview(
                {
                    caps: Object.entries(values.channels).reduce((enabled, [channel, data]) => {
                        if (!enabledChannels.includes(channel)) {
                            return enabled;
                        }

                        return { ...enabled, [channel]: data };
                    }, {}),
                    idUser: match.params.id,
                },
                setSubmitting,
            );
        } else {
            setSubmitting(false);
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("administration.channels.configurateLimits.topAmount.required"),
                    "error",
                    ["administration"],
                ),
            );
        }
    };

    renderForm = ({ isSubmitting, values }) => {
        const { enabledChannelsFrequencies, enabledChannels, currencies, nonRemovableChannels } = this.props;

        return (
            <Form className="above-the-fold">
                <div className="d-flex mt-3 mb-25">
                    <I18n
                        component="h2"
                        componentProps={{ className: "m-0 mr-3 align-item-center" }}
                        id="administration.channels.configurateLimits.topAmount.label"
                    />

                    <Hint
                        idMessage="administration.channels.configurateLimits.topAmount.info"
                        styleText={{ width: "275px" }}
                        classNameImage="d-flex mt-0 mr-lg-3 mr-1"
                        styleDiv={{ alignContent: "center", marginBottom: 0 }}
                        classNameHintP="m-0 p-absolute absolute-vertical-center"
                    />
                </div>
                <Container className="flex-grow" gridClassName="form-content">
                    <Container
                        className="container--layout flex-grow container-white my-0"
                        gridClassName="form-content">
                        <Col className="py-3 px-0" sm={12}>
                            <div className="form-group-wrapper pl-xl-16 pl-sm-75 without-margins form-group-wrapper-inline">
                                <Field
                                    idForm="administration"
                                    name="channels.topAmount"
                                    component={AmountField}
                                    maxLength={15}
                                    data={{ options: currencies }}
                                    clearable={false}
                                    hideLabel
                                    fixedDecimalScale
                                    inputGroupClassName="my-0"
                                    amountClassName="limit-input"
                                />
                                <Select
                                    name="channels.topAmount.frequency"
                                    className="form-group select-small ellipsis-text big-height"
                                    label={i18nUtils.get("administration.channels.topAmount.frequency")}
                                    options={enabledChannelsFrequencies}
                                    hideLabel
                                    inputGroupClassName="my-0"
                                    selectClassName="limit-select"
                                />
                                <Hint
                                    idMessage="administration.channels.configurateLimits.frequency.info"
                                    styleText={{ width: "20.5vw" }}
                                    classNameImage="d-flex mt-0 mr-lg-3 mr-1"
                                    styleDiv={{ alignContent: "center", marginBottom: 0 }}
                                    classNameHintP="m-0 p-absolute absolute-vertical-center"
                                />
                            </div>
                        </Col>
                    </Container>
                    <div className="d-flex mr-auto mt-3 mb-25">
                        <I18n
                            component="h2"
                            componentProps={{ className: "m-0 mr-3 align-item-center" }}
                            id="administration.channels.configurateLimits.byChannel"
                        />

                        <Hint
                            idMessage="administration.channels.configurateLimits.byChannel.info"
                            styleText={{ width: "435px" }}
                            classNameImage="d-flex mt-0 mr-lg-3 mr-1"
                            styleDiv={{ alignContent: "center", marginBottom: 0 }}
                            classNameHintP="m-0 p-absolute absolute-vertical-center"
                        />
                    </div>

                    {enabledChannels.map((channel, i) => (
                        <Container
                            className="flex-grow container-white my-1 py-3 transition-03"
                            gridClassName="form-content">
                            <Container.Column sm={12}>
                                <div className="form-group-wrapper without-margins form-group-wrapper-inline ">
                                    <Container.Column md={1} sm={12} className="p-absolute pl-lg-0 pl-xl-3">
                                        <Field name={`channels.${channel}`}>
                                            {({ field, form }) => (
                                                <div className="c-control c-control--has-icon c-control--checkbox mb-0 py-3">
                                                    <input
                                                        id={field.name}
                                                        className="c-control-input"
                                                        type="checkbox"
                                                        name={field.name}
                                                        disabled={nonRemovableChannels.includes(channel)}
                                                        checked={values.enabledChannels.includes(channel)}
                                                        onChange={() =>
                                                            form.setFieldValue(
                                                                "enabledChannels",
                                                                values.enabledChannels.includes(channel)
                                                                    ? values.enabledChannels.filter(
                                                                          (value) => value !== channel,
                                                                      )
                                                                    : values.enabledChannels.concat(channel),
                                                            )
                                                        }
                                                    />
                                                    {/* eslint-disable-next-line */}
                                                    <label className="c-control-label" htmlFor={field.name}>
                                                        <div className="c-control-icons">
                                                            <div className="c-control-mark">
                                                                <Image
                                                                    src="images/check.svg"
                                                                    className="svg-icon svg-caret"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="c-control-text">
                                                            <I18n id={`channels.${channel}`} />
                                                        </div>
                                                    </label>
                                                </div>
                                            )}
                                        </Field>
                                    </Container.Column>
                                    <div className="pl-xl-16 pl-sm-75">
                                        <div
                                            className={classNames(
                                                "form-group-wrapper my-0 form-group-wrapper-inline form-group--small",
                                                {
                                                    hidden: !values.enabledChannels.includes(channel),
                                                },
                                            )}>
                                            <Field
                                                name={`channels.${channel}`}
                                                component={AmountField}
                                                maxLength={15}
                                                data={{ options: currencies }}
                                                clearable={false}
                                                hideLabel
                                                fixedDecimalScale
                                                inputGroupClassName="my-0"
                                                amountClassName="limit-input"
                                            />
                                            <Select
                                                name={`channels.${channel}.frequency`}
                                                className={classNames(
                                                    "form-group select-small ellipsis-text big-height",
                                                    {
                                                        "select-open-top": i === enabledChannels.length - 1,
                                                    },
                                                )}
                                                options={enabledChannelsFrequencies}
                                                inputGroupClassName="my-0"
                                                selectClassName="limit-select"
                                            />
                                            <Hint
                                                idMessage="administration.channels.configurateLimits.frequency.info"
                                                styleText={{ width: "20.5vw" }}
                                                classNameImage="d-flex mt-0 mr-lg-3 mr-1"
                                                styleDiv={{ alignContent: "center", marginBottom: 0 }}
                                                classNameHintP="m-0 p-absolute absolute-vertical-center"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Container.Column>
                        </Container>
                    ))}

                    <Container
                        className="container--layout align-items-center mb-45 mt-xl-7 mt-3 pt-4"
                        gridClassName="form-content">
                        <Container.Column className="col-12" lg={5} md={6} sm={12}>
                            <Container.ColumnBody>
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="administration.channels.modify"
                                    className="m-0"
                                    loading={isSubmitting}
                                />
                            </Container.ColumnBody>
                        </Container.Column>
                    </Container>
                </Container>
            </Form>
        );
    };

    renderHeader = () => {
        const { user, hasMassiveEnabled } = this.props;
        const isAdministrator = !hasMassiveEnabled;

        if (user) {
            const { fullName } = user;

            return (
                <>
                    <div className="admin-detail-head admin-detail-head-section px-0">
                        <Head onBack={this.handleBack} />
                        {fullName && (
                            <div className="mb-25">
                                <div className="view-title d-flex">
                                    <I18n
                                        id="administration.channels.configurateLimits.title"
                                        component="h1"
                                        fullName={fullName}
                                        componentProps={{
                                            className: "mx-0 align-self-center",
                                        }}
                                    />
                                    <div className="data-wrapper data-wrapper-flex mt-3 mb-2 ml-3">
                                        {isAdministrator && (
                                            <InfoTag
                                                type="default"
                                                message={i18nUtils.get(`administration.administrator`)}
                                                tagBackground="#707070"
                                            />
                                        )}
                                        {user && (
                                            <InfoTag
                                                type="default"
                                                message={i18nUtils.get(`user.status.${user.idUserStatus}`)}
                                                tagBackground={user.idUserStatus === "blocked" ? "#fc5f5f" : "#0be0a2"}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {user.email && (
                            <AdministrationHeading className="mt-0">
                                {({ email, documentType, documentNumber, mobileNumber }) => (
                                    <Row className="content-data px-2">
                                        <Container.Column md={3} sm={12} className="d-flex align-items-center">
                                            <Heading.DataGroup
                                                label="administration.permissions.document"
                                                dataClassName="f-size-4"
                                                data={
                                                    documentType &&
                                                    documentNumber &&
                                                    `${documentType} ${documentNumber}`
                                                }
                                            />
                                        </Container.Column>
                                        <Container.Column
                                            md={6}
                                            sm={12}
                                            className="d-flex align-items-center justify-content-center">
                                            <Heading.DataGroup
                                                containerClassName="text-center"
                                                label="administration.permissions.email"
                                                dataClassName="f-size-4 font-light"
                                                data={email}
                                            />
                                        </Container.Column>
                                        <Container.Column
                                            md={3}
                                            sm={12}
                                            className="d-flex align-items-center justify-content-end">
                                            <Heading.DataGroup
                                                label="administration.permissions.mobileNumber"
                                                dataClassName="f-size-4"
                                                data={mobileNumber}
                                            />
                                        </Container.Column>
                                    </Row>
                                )}
                            </AdministrationHeading>
                        )}
                    </div>
                </>
            );
        }
        return null;
    };

    renderContent = () => {
        const { enabledChannels, caps, topAmount } = this.props;
        const { maximum, frequency } = topAmount;

        if (!enabledChannels.length) {
            return <PageLoading loading />;
        }

        return (
            <>
                <MainContainer viewContentClassName="pt-0">
                    <div className="above-the-fold">
                        <Formik
                            onSubmit={this.handleSubmit}
                            initialValues={{
                                channels: enabledChannels.reduce(
                                    (values, channel) => ({
                                        ...values,
                                        [channel]: caps[channel] || { frequency: "daily" },
                                    }),
                                    {
                                        topAmount: {
                                            amount: maximum || 0,
                                            frequency,
                                        },
                                    },
                                ),
                                enabledChannels: Object.keys(caps),
                            }}>
                            {this.renderForm}
                        </Formik>
                    </div>
                </MainContainer>
            </>
        );
    };

    render() {
        const { fetching, caps } = this.props;
        const isLoading = fetching && !Object.keys(caps).length;

        return (
            <>
                {this.renderHeader()}
                <PageLoading loading={isLoading}>{!isLoading && this.renderContent()}</PageLoading>
            </>
        );
    }
}

export default connect()(Channels);
