import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";
import { func, shape, bool } from "prop-types";
import Yup from "yup";

import { actions, selectors } from "reducers/administration/usersInvite";
import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import SelectorInput from "pages/_components/fields/formik/SelectorInput";

const FORM_ID = "administration.users.invite";

class UserInvite extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        setValues: func.isRequired,
        setErrors: func.isRequired,
        values: shape({}).isRequired,
        isSubmitting: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.userInvitePre());
    }

    render() {
        const { values, setValues, isSubmitting, setErrors } = this.props;

        return (
            <>
                <Notification scopeToShow="administrationUserInvite" />
                <div className="admin-detail-head admin-detail-head-section px-0">
                    <Head backLinkTo="/administration/users" />
                    <div className="mb-4">
                        <I18n
                            component="h1"
                            componentProps={{ className: "view-title" }}
                            id="administration.users.invite.title"
                        />
                    </div>
                </div>

                <MainContainer className="main-container administration" viewContentClassName="pt-0" showLoader={false}>
                    <Form className="above-the-fold">
                        <Container
                            className="container--layout flex-grow container-invite-user container-user-invite-margin-bottom"
                            gridClassName="form-content">
                            <Col className="col col-12">
                                <I18n
                                    component="h2"
                                    componentProps={{ className: "text-center mt-4" }}
                                    id="administration.users.document.subtitle"
                                />
                            </Col>
                            <Col className="col col-12 px-xl-0 inputs-invite-user pb-3" md={9} lg={6}>
                                <Field
                                    component={SelectorInput}
                                    controlLabelClassName="mb-15"
                                    name="document"
                                    selectorSize="small"
                                    selectProps={{
                                        name: "documentType",
                                        value: {
                                            value: values.documentType,
                                            label: values.documentType,
                                        } /* values.documentType */,
                                        options: [
                                            { value: "DNI", label: "DNI" },
                                            { value: "CUIL", label: "CUIL" },
                                            { value: "CUIT", label: "CUIT" },
                                            { value: "CDI", label: "CDI" },
                                        ],
                                    }}
                                    isFocused
                                    label={i18nUtils.get("login.step1.documentType.label")}
                                    inputProps={{
                                        name: "document",
                                        value: values.document,
                                        inputmode: "numeric",
                                        placeholder: values.documentType === "DNI" ? "Ej: 25000666" : "Ej: 20250006660",
                                    }}
                                    onChange={(type, stringDocument) => {
                                        setErrors({ document: "" });
                                        setValues({
                                            ...values,
                                            documentType: type?.value ? type.value : type,
                                            document: stringDocument,
                                        });
                                    }}
                                />
                            </Col>
                        </Container>

                        <Container className="container--layout align-items-center" gridClassName="form-content">
                            <Col className="col col-12 px-0 inputs-invite-user" md={9} lg={6}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    countries: selectors.getCountryList(state),
    documentTypes: selectors.getDocumentTypeList(state),
    defaultCountry: selectors.getDefaultCountry(state),
    defaultDocumentType: selectors.getDefaultDocumentType(state),
});

const documentTypeFormats = (documentType, document) => {
    const isnum = /^\d+$/.test(document);
    if (documentType === "DNI") {
        if (document.length < 6 || document.length > 8 || !isnum) {
            return false;
        }
    }

    if (documentType === "CUIT" || documentType === "CUIL" || documentType === "CDI") {
        if (document.length !== 11 || !isnum) {
            return false;
        }
    }
    return true;
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ defaultDocumentType }) => ({
            documentType: defaultDocumentType !== "" && defaultDocumentType === "DNI" ? defaultDocumentType : "DNI",
            document: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                documentType: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentType.required`)),
                document: Yup.string().required(i18nUtils.get(`${FORM_ID}.documentNumber.required`)),
            }),
        handleSubmit: ({ document, documentType }, formikBag) => {
            const { dispatch } = formikBag.props;

            const documentWithoutBlank = document.replace(" ", "").trim();

            formikBag.setValues({
                documentType,
                document: documentWithoutBlank,
            });

            const formatValidation = documentTypeFormats(documentType, document);

            if (formatValidation) {
                dispatch(actions.userInviteVerify({ type: documentType, document }, formikBag));
            } else {
                formikBag.setErrors({ document: i18nUtils.get("login.step1.wrong.format") });
                formikBag.setValues({
                    documentType: "DNI",
                    document: "",
                });
                formikBag.setSubmitting(false);
            }
        },
    }),
)(UserInvite);
