import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { string, bool, func } from "prop-types";
import { selectors as loginSelectors, actions as loginActions } from "reducers/login";
import * as i18n from "util/i18n";

import Button from "pages/_components/Button";

class RegionSelectionLink extends Component {
    static propTypes = {
        dispatch: func,
        activeRegion: string,
        disabled: bool,
    };

    static defaultProps = {
        activeRegion: "",
        disabled: false,
        dispatch: () => {},
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(loginActions.getClientContry());
    }

    handleClick = () => {
        const { disabled, dispatch } = this.props;

        if (!disabled) {
            dispatch(push("/regionSelection"));
        }
    };

    render() {
        const { activeRegion } = this.props;
        const a11yMessage1 = i18n.get("settings.changeRegion.link.a11y");
        const a11yMessage2 = i18n.get(`settings.changeRegion.label.${activeRegion}.a11y`);
        if (activeRegion) {
            return (
                <Button
                    label={`settings.regions.label.${activeRegion}`}
                    aria-label={`${a11yMessage1}, ${a11yMessage2}`}
                    bsStyle="link"
                    className="btn-asLink"
                    onClick={() => this.handleClick()}
                />
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    activeRegion: loginSelectors.getRegion(state),
});

export default connect(mapStateToProps)(RegionSelectionLink);
