import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, arrayOf, string, bool } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import { compose } from "redux";
import { accountDescription } from "util/checks";
import { selectors as debinSelectors } from "reducers/debin";
import classNames from "classnames";

const FORM_ID = "debin.recurrency";

class RecurrencyDebinActionSummaryData extends Component {
    static propTypes = {
        recurrencyAction: shape({}).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        seller: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    createAccountDescription = (cbu) => {
        const { accounts } = this.props;

        const account = accounts.find((a) => a.cbu === cbu);

        if (account?.cbu) {
            const { productAlias, number, currency, productType } = account;

            return accountDescription(productAlias, number, currency, productType);
        }
        return cbu;
    };

    render() {
        const { recurrencyAction, seller, isDesktop } = this.props;

        return (
            <>
                <Container
                    className="flex-grow align-items-center container-white mt-2 py-2"
                    gridClassName="form-content">
                    <Col xs={12} md={3}>
                        <Heading.DataGroup
                            containerClassName={classNames(
                                "data-wrapper-flex",
                                { "transfer-data data-wrapper-flex-column": isDesktop },
                                { "transaction-ticket-detail space-between m-0": !isDesktop },
                            )}
                            labelClassName="data-label-medium mr-2"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.recurrency.id`}
                            data={recurrencyAction?.debin?.id}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <Heading.DataGroup
                            containerClassName={classNames(
                                "data-wrapper-flex",
                                { "transfer-data data-wrapper-flex-column": isDesktop },
                                { "transaction-ticket-detail space-between m-0": !isDesktop },
                            )}
                            labelClassName="data-label-medium mr-2"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.cuenta`}
                            data={this.createAccountDescription(recurrencyAction?.debin?.comprador?.cbu)}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <Heading.DataGroup
                            containerClassName={classNames(
                                "data-wrapper-flex",
                                { "transfer-data data-wrapper-flex-column": isDesktop },
                                { "transaction-ticket-detail space-between m-0": !isDesktop },
                            )}
                            labelClassName="data-label-medium mr-2"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.seller.name.title`}
                            data={seller?.titular}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <Heading.DataGroup
                            containerClassName={classNames(
                                "data-wrapper-flex",
                                { "transfer-data data-wrapper-flex-column": isDesktop },
                                { "transaction-ticket-detail space-between m-0": !isDesktop },
                            )}
                            labelClassName="data-label-medium mr-2"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.cuit.vendedor`}
                            data={recurrencyAction?.debin?.vendedor?.cuit}
                        />
                    </Col>
                </Container>
                <Container
                    className="flex-grow align-items-center container-white mt-2 py-2"
                    gridClassName="form-content">
                    <Col xs={12} md={3}>
                        <Heading.DataGroup
                            containerClassName={classNames(
                                "data-wrapper-flex",
                                { "transfer-data data-wrapper-flex-column": isDesktop },
                                { "transaction-ticket-detail space-between m-0": !isDesktop },
                            )}
                            labelClassName="data-label-medium mr-2"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.prestacion`}
                            data={recurrencyAction?.debin?.debin?.prestacion}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <Heading.DataGroup
                            containerClassName={classNames(
                                "data-wrapper-flex",
                                { "transfer-data data-wrapper-flex-column": isDesktop },
                                { "transaction-ticket-detail space-between m-0": !isDesktop },
                            )}
                            labelClassName="data-label-medium mr-2"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.referencia`}
                            data={recurrencyAction?.debin?.debin?.detalle}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <Heading.DataGroup
                            containerClassName={classNames(
                                "data-wrapper-flex",
                                { "transfer-data data-wrapper-flex-column": isDesktop },
                                { "transaction-ticket-detail space-between m-0": !isDesktop },
                            )}
                            labelClassName="data-label-medium mr-2"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.concepto`}
                            data={recurrencyAction?.debin?.debin?.concepto}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <Heading.DataGroup
                            containerClassName={classNames(
                                "data-wrapper-flex",
                                { "transfer-data data-wrapper-flex-column": isDesktop },
                                { "transaction-ticket-detail space-between m-0": !isDesktop },
                            )}
                            labelClassName="data-label-medium mr-2"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.descripcion`}
                            data={recurrencyAction?.debin?.debin?.referencia}
                        />
                    </Col>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    recurrencyAction: debinSelectors.getRecurrencyDebinAction(state),
    accounts: debinSelectors.getAccounts(state),
    seller: debinSelectors.getSeller(state),
});

export default compose(connect(mapStateToProps))(RecurrencyDebinActionSummaryData);
