/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, node, func, number, bool, string } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import DepositsListItem from "pages/deposits/ListItem";
import WidgetList from "pages/desktop/widgets/WidgetList";
import Button from "pages/_components/Button";

import { selectors } from "reducers/widgets";

import * as i18nUtils from "util/i18n";
import * as config from "util/config";
import WidgetHeader from "./_components/WidgetHeader";

const WIDGET_NAME = "deposits";

class Deposits extends Component {
    static propTypes = {
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
        dispatch: func.isRequired,
        depositCount: number.isRequired,
        errorMsg: string.isRequired,
        isEditable: bool,
        isDesktop: bool,
    };

    static defaultProps = {
        closeButton: null,
        isEditable: false,
        isDesktop: true,
    };

    render() {
        const { closeButton, errorMsg, draggableItemProps, dispatch, depositCount, isEditable, isDesktop } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetDeposits" };
        const maxItems = config.getInteger("dektop.max.items.widget");

        return (
            <WidgetList
                item={DepositsListItem}
                keyExtractor={(item) => item.idProduct}
                name="deposits"
                isEditable={isEditable}
                close={closeButton}
            >
                {(list) => (
                    <div
                        role={isEditable ? "button" : "none"}
                        className={!isDesktop ? "widget deposits-widget" : "deposits-widget"}
                        {...uiAutomationProp}
                        {...draggableItemProps}
                        aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                        <WidgetHeader
                            title={i18nUtils.get("menu.deposits.fixed")}
                            action={
                                <>
                                    {depositCount > maxItems && !isEditable && !errorMsg && (
                                        <Button
                                            onClick={() => {
                                                dispatch(push("/deposits"));
                                            }}
                                            bsStyle="outline"
                                            label="menu.seemore.label"
                                            type="button"
                                            image="images/arrow-right-blue.svg"
                                        />
                                    )}
                                </>
                            }
                        />
                        <div className="widget__content">{list}</div>
                    </div>
                )}
            </WidgetList>
        );
    }
}

const mapStateToProps = (state) => {
    const { data, errorMsg, isFetching } = selectors.getWidget(state, WIDGET_NAME);

    return {
        isFetching,
        depositCount: data.depositCount,
        errorMsg,
    };
};

export default connect(mapStateToProps)(Deposits);
