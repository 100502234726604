import React, { Component } from "react";
import { string, bool, node, arrayOf } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import Image from "pages/_components/Image";
import { chooseImage } from "util/transaction";
import Heading from "./Heading";
import I18n from "./I18n";
import Container from "./Container";

export const TYPE_DETAIL = {
    INLINE: "inline",
    INLINE_WITH_SPACE: "inline_without_space",
    COLUMNS: "columns",
};
class SummaryData extends Component {
    static propTypes = {
        childrenHeader: node,
        imageHeader: string,
        isDesktop: bool,
        secondaryTitle: string,
        title: string,
        itemsDetail: arrayOf({}),
        childrenBottomDetail: node,
        typeDetail: string,
    };

    static defaultProps = {
        childrenHeader: null,
        imageHeader: null,
        isDesktop: true,
        secondaryTitle: null,
        title: null,
        itemsDetail: [],
        childrenBottomDetail: null,
        typeDetail: TYPE_DETAIL.INLINE_WITH_SPACE,
    };

    getClassContainer = () => {
        const { typeDetail } = this.props;
        const containerClass = "transaction-ticket-detail data-wrapper-flex space-between";

        switch (typeDetail) {
            case TYPE_DETAIL.INLINE_WITH_SPACE: {
                return `${containerClass} space-between`;
            }
            case TYPE_DETAIL.COLUMNS: {
                return "";
            }
            default: {
                return `${containerClass}`;
            }
        }
    };

    getDetail = () => {
        const { itemsDetail } = this.props;
        const classContainer = this.getClassContainer();

        return (
            <>
                <Col xs={12} className="px-0">
                    {itemsDetail && (
                        <>
                            {itemsDetail.map((item) => (
                                <Col xs={12} className="px-0">
                                    <Heading.DataGroup
                                        containerClassName={classContainer}
                                        labelClassName="data-label-medium pr-2"
                                        dataClassName="data-desc"
                                        key={`${item.label}`}
                                        label={item.label}
                                        data={item.data}
                                    />
                                </Col>
                            ))}
                        </>
                    )}
                </Col>
            </>
        );
    };

    render() {
        const { childrenBottomDetail, childrenHeader, isDesktop, imageHeader, title, secondaryTitle } = this.props;

        return (
            <>
                <Container className={!isDesktop && "flex-grow align-items-center cmf-container-white pb-2 mt-2"}>
                    <Col sm={12} className="px-0">
                        {!!imageHeader && (
                            <div className="admin-content-center mt-5">
                                <Image
                                    src={chooseImage(imageHeader)}
                                    styles={{
                                        width: isDesktop ? "102.5px" : "56px",
                                        height: isDesktop ? "102.5px" : "56px",
                                    }}
                                />
                            </div>
                        )}
                        {secondaryTitle && (
                            <div className="admin-content-center mt-2">
                                <I18n id={secondaryTitle} />
                            </div>
                        )}
                        {title && (
                            <div className={!isDesktop && "mb-4"}>
                                <I18n
                                    component={!isDesktop ? "h2" : "h1"}
                                    id={title}
                                    componentProps={{ className: "cmf-title-center" }}
                                />
                            </div>
                        )}
                        {childrenHeader}
                        {this.getDetail()}
                        {childrenBottomDetail}
                    </Col>
                </Container>
            </>
        );
    }
}

export default SummaryData;
