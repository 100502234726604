import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { func, bool } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { actions as onboardingActions } from "reducers/onboarding";
import { actions as loginActions } from "reducers/login";
import DecisionButton from "pages/onboarding/_components/DecisionButton";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import { Mixpanel } from "util/clickstreaming";
import OnboardingStepper, { orientations } from "./_components/OnboardingStepper";

class Step1 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool,
    };

    static defaultProps = {
        isMobile: false,
    };

    handleButtonClick = (documentType) => () => {
        const { dispatch } = this.props;
        dispatch(onboardingActions.setDocumentType(documentType));
        Mixpanel.track("onboarding.step1", { document: documentType });
        dispatch(push("/onboarding/step2/front"));
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    render() {
        const { isMobile } = this.props;

        return (
            <Fragment>
                <Head
                    title="onboarding.step1.title"
                    onBack={isMobile && this.onHeaderBack}
                    onClose={isMobile && this.onHeaderClose}
                />
                <div className="view-page">
                    {!isMobile && <OnboardingStepper currentStep={1} className="onboarding-steps" />}
                    <div className="view-content">
                        <main className="main-container">
                            <div className="above-the-fold">
                                {isMobile && (
                                    <Container className="container--layout align-items-center">
                                        <Col className="col col-12">
                                            <OnboardingStepper currentStep={1} orientation={orientations.horizontal} />
                                        </Col>
                                    </Container>
                                )}
                                <Container className="container--layout flex-grow align-items-center">
                                    <Col className="col col-12">
                                        <div className="decision-wrapper">
                                            <DecisionButton
                                                onClick={this.handleButtonClick("document")}
                                                label="onboarding.step1.document"
                                                image="images/ID.svg"
                                            />
                                            <DecisionButton
                                                onClick={this.handleButtonClick("passport")}
                                                label="onboarding.step1.passport"
                                                image="images/passportEnrollment.svg"
                                            />
                                        </div>
                                    </Col>
                                </Container>
                            </div>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default connect(null)(Step1);
