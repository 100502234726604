import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { node, string, number } from "prop-types";

import { countDecimalPlaces, numberFormat } from "util/number";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as configSelectors } from "reducers/config";

class FormattedNumber extends Component {
    static propTypes = {
        lang: string.isRequired,
        children: node,
        maximumDecimals: number.isRequired,
        minimumDecimals: number.isRequired,
    };

    static defaultProps = {
        children: 0,
    };

    renderText = (formattedValue) => (
        <>
            <span className="data-amount content-data-strong">{formattedValue}</span>
        </>
    );

    render() {
        const { children, lang, maximumDecimals, minimumDecimals } = this.props;

        const { decimalSeparator, thousandSeparator } = numberFormat(lang);

        const decimalPlaces = countDecimalPlaces(children, decimalSeparator);
        const decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);

        return (
            <NumberFormat
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                decimalScale={decimalScale}
                displayType="text"
                renderText={(formattedValue) => this.renderText(formattedValue)}
                value={children}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
    maximumDecimals: Number(configSelectors.getConfig(state)["defaultDecimal.maximum"] || 0),
    minimumDecimals: Number(configSelectors.getConfig(state)["defaultDecimal.minimum"] || 0),
});

export default connect(mapStateToProps)(FormattedNumber);
