export const makeActionCreator = (type, ...argNames) => (...args) => {
    const action = { type };
    if (!argNames.length && args.length) {
        const [payload] = args;
        action.payload = payload;
    } else {
        argNames.forEach((_, index) => {
            const argName = argNames[index];

            if (typeof argName !== "function") {
                action[argName] = args[index];
            } else {
                const [name, value] = argName(args[index]);

                action[name] = value;
            }
        });
    }
    return action;
};

export const createReducer = (initialState, handlers) => (state = initialState, action) => {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
        return handlers[action.type](state, action);
    }
    return state;
};
