/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, shape, string, node } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as transactionsActions } from "reducers/transactions";

import Button from "pages/_components/Button";
import Date from "pages/_components/fields/DateField";

import * as i18n from "util/i18n";

const FORM_ID = "transactions.list.filters.period";

class PeriodFilterPendingTransactions extends Component {
    state = {
        selectedDateFrom: null,
    };

    static propTypes = {
        fetching: bool,
        values: shape({}),
        buttonLabel: string,
        cleanFiltersButton: node,
        renderOrder: node,
    };

    static defaultProps = {
        fetching: false,
        values: shape({
            dateFrom: null,
            dateTo: null,
        }),
        buttonLabel: null,
        cleanFiltersButton: null,
        renderOrder: null,
    };

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    render() {
        const {
            fetching,
            dateFrom,
            dateTo,
            buttonLabel,
            cleanFiltersButton,
            renderOrder,
        } = this.props;

        const { selectedDateFrom } = this.state;

        return (
            <Form autoComplete="off">
                <Col sm={12} md={3} lg={3} className="d-flex f-dir-col mt-35 pt-3 px-3">
                    <Button
                        bsStyle="primary"
                        label={buttonLabel || "product.filters.filter"}
                        loading={fetching}
                        type="submit"
                        className="filter-button m-0"
                    />
                    {cleanFiltersButton}
                </Col>
                <Col sm={12} md={3} lg={3} className="d-flex f-dir-col px-0">
                    {renderOrder}
                </Col>
                <Col sm={12} md={3} lg={3} className="period-filter__periods">
                    <Field
                        idForm={FORM_ID}
                        idField="dateFromPeriodFilter"
                        name="dateFrom"
                        component={Date}
                        maxDate={dateTo}
                        value={dateFrom}
                        handleChange={this.handleChangeDateFrom}
                        hidePlaceholder
                        selectsStart
                    />
                </Col>
                <Col sm={12} md={3} lg={3} className="period-filter__periods">
                    <Field
                        idForm={FORM_ID}
                        idField="dateToPeriodFilter"
                        name="dateTo"
                        component={Date}
                        minDate={dateFrom}
                        maxDate={new Date()}
                        value={dateTo}
                        hidePlaceholder
                        selectsEnd
                    />
                </Col>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => ({
        dateFrom: props.dateFrom ? props.dateFrom : null,
        dateTo: props.dateTo ? props.dateTo : null,
    }),
    validationSchema: () =>
        Yup.lazy((values) =>
            Yup.object().shape({
                dateFrom: values.dateTo
                    ? Yup.date()
                          .nullable()
                          .max(values.dateTo, i18n.get("accounts.movements.filters.from.error"))
                    : Yup.date().nullable(),
                dateTo: values.dateFrom
                    ? Yup.date()
                          .nullable()
                          .min(values.dateFrom, i18n.get("accounts.movements.filters.to.error"))
                    : Yup.date().nullable(),
            }),
        ),
    handleSubmit: ({ ...filters }, formikBag) => {
        const {
            dispatch,
            isDesktop,
            onlyPendings,
            pendingDispatch,
            dateType,
            status,
            idActivity,
            cleanSelectedRows,
        } = formikBag.props;

        if (cleanSelectedRows) {
            cleanSelectedRows();
        }
        if (isDesktop) {
            dispatch(
                transactionsActions.loadListRequest(
                    { ...filters, dateType, status, idActivity, filter: "creationDate" },
                    onlyPendings,
                    pendingDispatch,
                    formikBag,
                ),
            );
        } else {
            dispatch(
                transactionsActions.setFilters({
                    filter: "period",
                    ...filters,
                }),
            );
            dispatch(push(`/transactions/list/filters/results`));
        }
    },
})(PeriodFilterPendingTransactions);
