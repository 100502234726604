import React, { Component } from "react";
import { arrayOf, string, shape, bool } from "prop-types";
import * as i18n from "util/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import { connect } from "react-redux";
import Circle from "pages/_components/Circle";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";

class TransactionPotencialSchemesDrawer extends Component {
    static propTypes = {
        administrationScheme: string.isRequired,
        potentialSignaturesData: arrayOf(
            shape({ typesOfSignatures: arrayOf({ signature: string, selected: bool }), liberator: string }),
        ).isRequired,
    };

    showAdvancedSchemas = () => {
        const blue = "rgb(0, 113, 206)";
        const white = "rgb(255, 255, 255)";

        const { potentialSignaturesData } = this.props;
        return potentialSignaturesData.map((potentialSignature) => (
            <Col xs={12}>
                <Col xs={6}>
                    <h3 className="drawer-uppercase-text" style={{ color: "black" }}>
                        {i18n.get("administration.signatures.list.item.schema.label")}
                    </h3>
                    <div style={{ alignContent: "center", width: "100%" }}>
                        {potentialSignature.typesOfSignatures.map((typeOfSignature) => (
                            <Circle
                                marginRight="0.5rem"
                                value={typeOfSignature.signature}
                                bgColor={typeOfSignature.selected === true ? blue : white}
                                fontColor={typeOfSignature.selected === true ? white : blue}
                                smallSize
                                borderStyle="dotted"
                                borderColor={blue}
                                borderWidth="2px"
                                marginTop="0.5rem"
                                paddingTop="2px"
                            />
                        ))}
                    </div>
                </Col>
                <Col xs={6} style={{ textAlign: "end" }}>
                    {this.showLiberator(potentialSignature.liberator)}
                </Col>
            </Col>
        ));
    };

    showLiberator = (liberator) => {
        const red = "rgb(252, 95, 95)";
        const green = "rgb(24, 226, 167)";
        const label = i18n.get(liberator === true ? "global.yes" : "global.no");
        return (
            <div>
                <h3 className="drawer-uppercase-text" style={{ color: "black" }}>
                    {i18n.get("administration.signatures.list.item.liberator.label")}
                </h3>
                <div style={{ textAlign: "end", width: "100%" }}>
                    <Circle
                        value={label}
                        bgColor={liberator === true ? green : red}
                        smallSize
                        marginRight="2.5rem"
                        marginTop="0.5rem"
                        uppercase
                    />
                </div>
            </div>
        );
    };

    showMediumSchemas = () => {
        const { potentialSignaturesData } = this.props;
        return potentialSignaturesData.map((potentialSignature) => (
            <Col xs={12}>
                <Col xs={6}>
                    <h3 className="drawer-uppercase-text" style={{ color: "black" }}>
                        {i18n.get("administration.signatures.list.item.schema.label")}
                    </h3>
                    <div style={{ alignContent: "center", width: "100%" }}>
                        {this.mediumCircles(potentialSignature.typesOfSignatures)}
                    </div>
                </Col>
                <Col xs={6} style={{ textAlign: "end" }}>
                    {this.showLiberator(potentialSignature.liberator)}
                </Col>
            </Col>
        ));
    };

    mediumCircles = (typeOfSignatures) => {
        const readySignatures = this.countReadySignatures(typeOfSignatures);
        const blue = "rgb(0, 113, 206)";
        const white = "rgb(255, 255, 255)";

        const circles = [];
        let i;

        for (i = 0; i < readySignatures; i += 1) {
            circles.push(
                <Circle
                    bgColor={blue}
                    marginRight="0.5rem"
                    smallSize
                    borderStyle="dotted"
                    borderWidth="2px"
                    borderColor={blue}
                    marginTop="0.5rem"
                />,
            );
        }

        for (i = readySignatures; i < typeOfSignatures.length; i += 1) {
            circles.push(
                <Circle
                    bgColor={white}
                    marginRight="0.5rem"
                    smallSize
                    borderStyle="dotted"
                    borderWidth="2px"
                    borderColor={blue}
                    marginTop="0.5rem"
                />,
            );
        }

        return circles;
    };

    countReadySignatures = (typeOfSignatures) => {
        let pendingSignatures = 0;
        typeOfSignatures.forEach((typeOfSignature) => {
            if (typeOfSignature.selected === true) {
                pendingSignatures += 1;
            }
        });
        return pendingSignatures;
    };

    render() {
        const { administrationScheme } = this.props;
        const potentialSchemas =
            administrationScheme === "advanced" ? this.showAdvancedSchemas() : this.showMediumSchemas();

        return (
            <Container>
                <div className="mt-4 justify-content-center">
                    <h2 style={{ textAlign: "center", color: "black" }}>
                        {i18n.get("administration.signatures.list.label")}
                    </h2>
                    <hr style={{ margin: "0 2rem", borderWidth: "2px" }} />
                    {potentialSchemas}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    administrationScheme: sessionSelectors.getAdministrationScheme(state),
});

export default connect(mapStateToProps)(TransactionPotencialSchemesDrawer);
