import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { arrayOf, bool, func, string } from "prop-types";
import { selectors as loginSelectors, actions as loginActions } from "reducers/login";
import * as i18n from "util/i18n";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import { getArray } from "util/config";
import Button from "pages/_components/Button";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Logo from "./_components/Logo";

class RegionSelection extends Component {
    static propTypes = {
        activeRegion: string.isRequired,
        dispatch: func.isRequired,
        supportedRegions: arrayOf(string).isRequired,
        propsGrid: arrayOf({}).isRequired,
        legendTextID: string,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        legendTextID: "settings.changeRegion",
    };

    handleClick = (region) => {
        const { activeRegion, dispatch } = this.props;

        if (region !== activeRegion) {
            dispatch(loginActions.setRegion(region));
        }
    };

    backButtonAction = () => {
        const { dispatch } = this.props;
        dispatch(push("/"));
    };

    renderCenterElement = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 className="w-100 m-0">{i18n.get("settings.changeLanguage")}</h1>
        </div>
    );

    rightContentMobile = () => (
        <Button
            className="toolbar-btn view-close pb-0"
            onClick={this.backButtonAction}
            image="images/cross-mobile-blue.svg"
            bsStyle="link"
        />
    );

    renderLogoMobile = () => <Logo className="svg-image logo logo-mobile-size" logoColor />;

    render() {
        const { activeRegion, supportedRegions, legendTextID, isDesktop, propsGrid } = this.props;
        return (
            <GridLayoutExternal scopeToShow="changeRegion" isDesktop={isDesktop} {...propsGrid} showLogoState>
                <Head
                    title={isDesktop && "settings.changeRegion"}
                    headerClassName={isDesktop ? "recover-pass-desktop mb-2 h-auto" : "login-background-color-white"}
                    centerElement={!isDesktop && this.renderLogoMobile}
                    onClose={this.backButtonAction}
                    hideMobileMenu
                    fixed
                />
                <MainContainer>
                    {!isDesktop && (
                        <I18n
                            id="settings.changeRegion"
                            accessibilityTextId="settings.changeRegion"
                            component="h2"
                            componentProps={{ className: "text-center pt-0 pb-4" }}
                        />
                    )}
                    <div className="above-the-fold">
                        <section className="container--layout flex-grow align-items-center container--layout-regions mt-0 pt-0">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={12} lg={12} xl={12} className="col col-12">
                                        <fieldset className="form-group">
                                            {legendTextID && (
                                                <I18n
                                                    id={legendTextID}
                                                    component="legend"
                                                    componentProps={{ className: "visually-hidden" }}
                                                />
                                            )}
                                            <div className="border-top">
                                                <Row className="selection-list region-relection ">
                                                    {Object.keys(supportedRegions).map((idRegion) => (
                                                        <div
                                                            className="c-control c-control--radio two-columns"
                                                            key={`region-${idRegion}`}>
                                                            <input
                                                                defaultChecked={
                                                                    supportedRegions[idRegion] === activeRegion
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                id={`region-${supportedRegions[idRegion]}`}
                                                                type="radio"
                                                                name="region"
                                                                className="c-control-input"
                                                                onClick={() =>
                                                                    this.handleClick(supportedRegions[idRegion])
                                                                }
                                                            />
                                                            {/* eslint-disable-next-line */}
                                                            <label
                                                                className="c-control-label c-control-label--environment"
                                                                htmlFor={`region-${supportedRegions[idRegion]}`}>
                                                                {/* eslint-disable-next-line */}
                                                                <I18n id={`settings.regions.label.${supportedRegions[idRegion]}`} />
                                                            </label>
                                                        </div>
                                                    ))}
                                                </Row>
                                            </div>
                                        </fieldset>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}

const mapStateToProps = (state) => ({
    supportedRegions: getArray("frontend.regions") ? getArray("frontend.regions").sort() : [],
    activeRegion: loginSelectors.getRegion(state),
});

export default connect(mapStateToProps)(RegionSelection);
