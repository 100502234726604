import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    UPDATE_LAST_WEATHER: "weather/UPDATE_LAST_WEATHER",
    WEATHER_REQUEST: "weather/WEATHER_REQUEST",
    WEATHER_FAILURE: "weather/WEATHER_FAILURE",
    WEATHER_SUCCESS: "weather/WEATHER_SUCCESS",
};

export const INITIAL_STATE = {
    weather: null,
    lastWeather: null,
};

const reducer = createReducer(INITIAL_STATE, {
    [types.WEATHER_REQUEST]: (state) => state,
    [types.WEATHER_FAILURE]: (state) => ({
        ...state,
        weather: {},
    }),
    [types.WEATHER_SUCCESS]: (state, action) => ({
        ...state,
        weather: action.data,
    }),
    [types.UPDATE_LAST_WEATHER]: (state, action) => ({
        ...state,
        lastWeather: action.data,
    }),
});

export default persistReducer(
    {
        storage,
        key: "weather",
        whitelist: ["lastWeather"],
    },
    reducer,
);

export const actions = {
    updateLastWeather: makeActionCreator(types.UPDATE_LAST_WEATHER, "data"),
    weatherRequest: makeActionCreator(types.WEATHER_REQUEST, "longitude", "latitude"),
    weatherFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    weatherSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "data"),
};

export const selectors = {
    getWeather: ({ weather }) => weather.weather,
    getLastWeather: ({ weather }) => weather.lastWeather,
};
