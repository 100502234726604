/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, shape, arrayOf } from "prop-types";
import Col from "react-bootstrap/lib/Col";

import Table from "pages/_components/Table";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import InfoTag from "pages/_components/InfoTag";
import { fundStatusTagColor } from "util/fundcorp";
import NoDataMessage from "./NoDataMessage";

const FORM_ID = "fundcorp.list";

class FundcorpRequestList extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        requests: arrayOf(shape({})).isRequired,
    };

    renderListForRequests = () => {
        const { isDesktop, requests } = this.props;

        return (
            <>
                {requests && requests.length > 0 ? (
                    <Container className="flex-grow scrollable">
                        <Col md={12} className="table-with-border px-0">
                            <Table>
                                {isDesktop && (
                                    <Table.Header className="container-white">
                                        <Table.HeaderData>
                                            <I18n id={`${FORM_ID}.request.column.consultationDate`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="left">
                                            <I18n id={`${FORM_ID}.request.column.request`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData>
                                            <I18n id={`${FORM_ID}.request.column.number`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="left">
                                            <I18n id={`${FORM_ID}.request.column.fundName`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData>
                                            <I18n id={`${FORM_ID}.request.column.shareType`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="right">
                                            <I18n id={`${FORM_ID}.request.column.balance`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="right">
                                            <I18n id={`${FORM_ID}.request.column.currency`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData>
                                            <I18n id={`${FORM_ID}.request.column.shares`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="right">
                                            <I18n id={`${FORM_ID}.request.column.state`} />
                                        </Table.HeaderData>
                                    </Table.Header>
                                )}
                                <Table.Body>
                                    <>{requests.map((item) => this.renderListForRequestsItem(item))}</>
                                </Table.Body>
                            </Table>
                        </Col>
                    </Container>
                ) : (
                    <NoDataMessage>
                        <I18n id={`${FORM_ID}.empty.message.information`} />
                    </NoDataMessage>
                )}
            </>
        );
    };

    renderAmount = (monedaCodISO, importe) => {
        if (importe === 0) {
            return <div className="data-text"> - </div>;
        }
        return <FormattedAmount currency={monedaCodISO} className="data-amount" quantity={importe} notBold />;
    };

    renderCuotapartes = (cantCuotapartes) => {
        if (cantCuotapartes === 0) {
            return <div className="data-text"> - </div>;
        }
        return <div className="data-text">{cantCuotapartes}</div>;
    };

    renderListForRequestsItem = (item) => {
        const { isDesktop } = this.props;
        const {
            fechaConcertacion,
            tipoSolicitud,
            numSolicitud,
            fondoNombre,
            tipoVCPAbreviatura,
            importe,
            monedaCodISO,
            estadoSolicitud,
            cantCuotapartes,
            esTotal,
        } = item;

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    <Table.Data>
                        <FormattedDate date={fechaConcertacion} dateFormat="dd/MM/yyyy" />
                    </Table.Data>
                    <Table.Data align="left">
                        <div className="data-text">{tipoSolicitud}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text">{numSolicitud}</div>
                    </Table.Data>
                    <Table.Data align="left">
                        <div className="data-text">{fondoNombre}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text">{tipoVCPAbreviatura}</div>
                    </Table.Data>
                    <Table.Data align="right">{!esTotal && this.renderAmount(monedaCodISO, importe)}</Table.Data>
                    <Table.Data align="right">
                        <div className="data-text">{monedaCodISO}</div>
                    </Table.Data>
                    <Table.Data>
                        {esTotal ? (
                            <I18n id={`${FORM_ID}.position.balanceTotal`} />
                        ) : (
                            this.renderCuotapartes(cantCuotapartes)
                        )}
                    </Table.Data>
                    <Table.Data align="right">
                        <div className="data-text">
                            <InfoTag
                                type="info"
                                message={estadoSolicitud}
                                tagBackground={fundStatusTagColor(estadoSolicitud)}
                                moreStyles={{
                                    alignSelf: "flex-end",
                                    width: "fit-content",
                                    color: "black",
                                    margin: "auto",
                                    marginRight: 0,
                                }}
                            />
                        </div>
                    </Table.Data>
                </Table.Row>
            );
        }
        return (
            <div className="w-100 my-1">
                <Table.Row className="container-white pt-1">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.request.column.state`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <div className="data-text">
                            <InfoTag
                                type="info"
                                message={estadoSolicitud}
                                tagBackground={fundStatusTagColor(estadoSolicitud)}
                                moreStyles={{
                                    alignSelf: "flex-end",
                                    width: "fit-content",
                                    color: "black",
                                    margin: "auto",
                                    marginRight: 0,
                                }}
                            />
                        </div>
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.request.column.consultationDate`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <FormattedDate date={fechaConcertacion} dateFormat="dd/MM/yyyy" />
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.request.column.request`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <div className="data-text">{tipoSolicitud}</div>
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.request.column.number`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <div className="data-text">{numSolicitud}</div>
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.request.column.fundName`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <div className="data-text">{fondoNombre}</div>
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.request.column.shareType`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <div className="data-text">{tipoVCPAbreviatura}</div>
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.request.column.balance`} />
                    </Table.HeaderData>
                    <Table.Data align="right">{!esTotal && this.renderAmount(monedaCodISO, importe)}</Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.request.column.currency`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <div className="data-text">{monedaCodISO}</div>
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.request.column.shares`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        {esTotal ? (
                            <I18n id={`${FORM_ID}.position.balanceTotal`} />
                        ) : (
                            this.renderCuotapartes(cantCuotapartes)
                        )}
                    </Table.Data>
                </Table.Row>
            </div>
        );
    };

    render() {
        return <>{this.renderListForRequests()}</>;
    }
}

export default FundcorpRequestList;
