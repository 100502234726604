import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { connect } from "react-redux";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";

class Step4Content extends Component {
    send = (event) => {
        this.props.dispatch(push("/"));
    };

    render() {
        return (
            <form className="above-the-fold login-desktop-wrapper">
                <Container className="flex-grow align-items-center container--layout">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        <p className="text-lead">
                            <I18n id="recoveryPassword.step4.text1" />
                        </p>
                    </Col>
                </Container>

                <Container className="align-items-center container--layout">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        <p className="text-lead">
                            <I18n id="recoveryPassword.step4.text2" />
                        </p>
                    </Col>
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        <Button
                            label="global.login"
                            loading={false}
                            onClick={this.send}
                            type="button"
                            bsStyle="primary"
                        />
                    </Col>
                </Container>
            </form>
        );
    }
}

export default connect(null)(Step4Content);
