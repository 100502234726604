/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";

import { selectors as settingsSelectors } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as loginSelectors } from "reducers/login";
import { selectors as tokenSelectors, actions as tokenActions } from "reducers/token";
import { actions as notificationActions } from "reducers/notification";
import { selectors as statusSelectors } from "reducers/status";

import * as i18n from "util/i18n";
import Credential from "pages/_components/fields/credentials/Credential";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import { func, bool, shape, string } from "prop-types";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Notification from "pages/_components/Notification";
import classNames from "classnames";
import TokenModal from "./_components/token/TokenModal";

const FORM_ID = "settings.tokenPassword";

class TokenPassword extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isTokenBlocked: bool.isRequired,
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        match: shape().isRequired,
        propsGrid: shape({}).isRequired,
        loggedUser: shape({}).isRequired,
        rememberedUser: shape({}).isRequired,
        firstName: string.isRequired,
        lastPathname: string.isRequired,
    };

    handleBack = () => {
        const { dispatch, lastPathname } = this.props;
        dispatch(push(lastPathname));
    };

    centerContentMobile = () => {
        const { match } = this.props;
        const isSync = match.path === "/settings/token/synchronization/tokenPassword";

        return (
            <div>
                {isSync ? (
                    <h1 className="m-0 text-white">{i18n.get("settings.token.validate.codeRandom")}</h1>
                ) : (
                    <h1 className="m-0 text-white">{i18n.get("settings.tokenPassword")}</h1>
                )}
            </div>
        );
    };

    unlock = () => {
        const { dispatch } = this.props;

        dispatch(push("/settings/token/recoverPass"));
    };

    recoverTokenPass = () => {
        const { dispatch } = this.props;

        dispatch(
            notificationActions.showNotification(
                i18n.get("settings.token.recoverPassword.password.notification"),
                "warning",
                ["recoverTokenPass"],
            ),
        );
        dispatch(push("/settings/token/recoverPass"));
    };

    render() {
        const {
            match,
            isTokenBlocked,
            isSubmitting,
            isDesktop,
            propsGrid,
            loggedUser,
            firstName,
            rememberedUser,
        } = this.props;

        return (
            <GridLayoutExternal isDesktop={isDesktop} {...propsGrid}>
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName="blue-main-header-mobile blue-main-title-mobile"
                        centerElement={this.centerContentMobile}
                        centerContentClassName="mx-65"
                        emptyRightOption
                    />
                    <TokenModal isDisplayed={isTokenBlocked} handleDismiss={this.unlock} />
                </div>
                <Notification
                    scopeToShow="tokenPassValidation"
                    notificationClassname={classNames({
                        "recover-token-notification": match.path === "/tokenPassword",
                    })}
                />
                <MainContainer viewContentClassName="h-85vh">
                    <Form noValidate="novalidate">
                        <Container
                            className="container--layout flex-grow h-100"
                            gridClassName="form-content"
                            rowClassName="h-100">
                            <Col sm={12} md={6} lg={6} xl={6} className="col col-12 d-flex flex-flow-wrap">
                                <div className="admin-content-center py-4">
                                    {loggedUser ? (
                                        <I18n id="settings.tokenPassword.label" component="h3" />
                                    ) : (
                                        <>
                                            <h3 className="f-size-5 font-bold">
                                                {i18n.get("settings.tokenPassword.notLogged.subtitle.part1", null, {
                                                    firstName: rememberedUser?.userFirstName || firstName,
                                                })}
                                                {rememberedUser?.userFirstName || firstName ? "," : ""}
                                                <br />
                                                {i18n.get("settings.tokenPassword.notLogged.subtitle.part2")}
                                            </h3>
                                        </>
                                    )}
                                </div>
                                <div className="admin-content-center">
                                    <Field idForm={FORM_ID} name="password" component={Credential} type="tokenPass" />
                                </div>
                                <div className="align-self-end f-dir-col w-100 mr-0">
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.send"
                                        loading={isSubmitting}
                                        className="btn-uppercase"
                                    />
                                    <Button
                                        onClick={this.recoverTokenPass}
                                        bsStyle="btn btn-outline"
                                        label="settings.tokenPassword.forgotPassword.label"
                                    />
                                </div>
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}

const mapStateToProps = (state) => ({
    currentEmail: settingsSelectors.getEmail(state),
    fetchingToken: tokenSelectors.getFetchingToken(state),
    userToken: tokenSelectors.getUserIdToken(state),
    isTokenBlocked: tokenSelectors.getIsTokenBlocked(state),
    loggedUser: sessionSelectors.getUser(state),
    firstName: tokenSelectors.getUserFristName(state),
    rememberedUser: loginSelectors.getRememberedUser(state),
    lastPathname: statusSelectors.getPathname(state),
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            password: "",
        }),

        validationSchema: () =>
            Yup.object().shape({
                password: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: ({ password }, formikBag) => {
            const { match, userToken } = formikBag.props;
            const isSync = match.path === "/settings/token/synchronization/tokenPassword";
            if (isSync) {
                formikBag.props.dispatch(tokenActions.tokenSyncNewDeviceRequest(password, formikBag));
            } else {
                formikBag.props.dispatch(tokenActions.tokenPasswordValidationRequest(password, userToken, formikBag));
            }
        },
    }),
)(TokenPassword);
