/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, shape } from "prop-types";
import * as i18n from "util/i18n";
import { getAccountDescription } from "util/accounts";
import { selectors as transactionsSelectors } from "reducers/transactions";
import FormattedAmount from "pages/_components/FormattedAmount";
import { getAccountInfo } from "middleware/accounts";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class TransferExternalInternalDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        body: "",
    };

    componentDidMount = () => {
        this.tableBody();
    };

    tableBody = () => {
        const { multipleSignaturesData } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;

        const bodyPromises = selectedTransactionRows.map((object) => {
            const { debitAccount } = object.transaction.data;
            return this.accountInfo(debitAccount);
        });

        Promise.all(bodyPromises).then((values) => {
            this.setState({
                body: selectedTransactionRows.map((object, index) => this.dataRow(object, values[index])),
            });
        });
    };

    dataRow = (object, account) => {
        const { idActivity } = object.transaction;

        const external =
            idActivity === "transfers.thirdParties.tad.send" || idActivity === "transfers.thirdParties.send";
        return external ? this.externalCard(object, account) : this.internalCard(object);
    };

    accountInfo = async (debit) => {
        const responseDebit = await getAccountInfo(debit);

        const accountDebit = responseDebit.data.data.account;

        return accountDebit.number.concat(
            " ",
            accountDebit.productType,
            " ",
            i18n.get(`currency.label.${accountDebit.currency}`),
        );
    };

    internalCard(object) {
        const { isDesktop } = this.props;
        const [currency] = Object.keys(object.transactionAmounts);
        const amount = object.transactionAmounts[currency];
        const {
            idTransaction,
            data: { formikBag, creditReference },
        } = object.transaction;
        return (
            <CardContainer className="confirmation__cards-container mt-0">
                <Card
                    className="confirmation__cards"
                    icon={isDesktop && "images/transfer-PENDING.svg"}
                    iconClass="card__pending-transaction-icon"
                    title={
                        !isDesktop &&
                        formikBag?.props?.summary?.debitAccount && (
                            <div className="confirmation-cards__title">
                                <div className="confirmation-cards__title-data">
                                    <span>{i18n.get("table.transferInternal.debitAccount")}</span>
                                </div>
                                <div className="confirmation-cards__title-info">
                                    <span>{getAccountDescription(formikBag?.props?.summary?.debitAccount)}</span>
                                </div>
                            </div>
                        )
                    }
                    content={
                        isDesktop ? (
                            <div className="d-flex align-items-center">
                                {formikBag?.props?.summary?.debitAccount && (
                                    <div className="confirmation-cards__content pb-1">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("table.transferInternal.debitAccount")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>
                                                {getAccountDescription(formikBag?.props?.summary?.debitAccount)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {formikBag?.props?.summary?.creditAccount && (
                                    <div className="confirmation-cards__content info-type-a">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("table.transferInternal.creditAccount")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>
                                                {getAccountDescription(formikBag?.props?.summary?.creditAccount)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {creditReference && (
                                    <div className="confirmation-cards__content info-type-b">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("table.transferInternal.reference")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info withEllipsis">
                                            <div className="confirmation-cards__with-ellipsis">{creditReference}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                {formikBag?.props?.summary?.creditAccount && (
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("table.transferInternal.creditAccount")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>
                                                {getAccountDescription(formikBag?.props?.summary?.creditAccount)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {creditReference && (
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("table.transferInternal.reference")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info withEllipsis">
                                            <div className="confirmation-cards__with-ellipsis">{creditReference}</div>
                                        </div>
                                    </div>
                                )}
                                <div className="confirmation-cards__content">
                                    <div className="confirmation-cards__content-data">
                                        <span>{i18n.get("table.transferInternal.idTransaction")}</span>
                                    </div>
                                    <div className="confirmation-cards__content-info">
                                        <span>{idTransaction.substring(0, 8).toUpperCase()}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    bottom={
                        isDesktop && (
                            <div className="confirmation-cards__bottom">
                                <div className="confirmation-cards__bottom-data">
                                    <span>{i18n.get("table.transferInternal.idTransaction")}</span>
                                </div>
                                <div className="confirmation-cards__bottom-info">
                                    <span>{idTransaction.substring(0, 8).toUpperCase()}</span>
                                </div>
                            </div>
                        )
                    }
                    rightContent={
                        <div className="confirmation-cards__right">
                            <div className="confirmation-cards__right-data">
                                <FormattedAmount currency={currency} quantity={amount} />
                            </div>
                        </div>
                    }
                />
            </CardContainer>
        );
    }

    externalCard(object, account) {
        const { isDesktop } = this.props;
        const [currency] = Object.keys(object.transactionAmounts);
        const amount = object.transactionAmounts[currency];

        return (
            <CardContainer className="confirmation__cards-container mt-0">
                <Card
                    className="confirmation__cards"
                    icon={isDesktop && "images/transfer-PENDING.svg"}
                    iconClass="card__pending-transaction-icon"
                    title={
                        !isDesktop && (
                            <div className="confirmation-cards__title">
                                <div className="confirmation-cards__title-data">
                                    <span>{i18n.get("table.transferExternal.cbu")}</span>
                                </div>
                                <div className="confirmation-cards__title-info">
                                    <span>{object.transaction.data.cbu}</span>
                                </div>
                            </div>
                        )
                    }
                    content={
                        isDesktop ? (
                            <div className="d-flex align-items-center">
                                <div className="confirmation-cards__content pb-1">
                                    <div className="confirmation-cards__content-data">
                                        <span>{i18n.get("table.transferExternal.cbu")}</span>
                                    </div>
                                    <div className="confirmation-cards__content-info">
                                        <span>{object.transaction.data.cbu}</span>
                                    </div>
                                </div>
                                <div className="confirmation-cards__content info-type-a">
                                    <div className="confirmation-cards__content-data">
                                        <span>{i18n.get("table.transferInternal.debitAccount")}</span>
                                    </div>
                                    <div className="confirmation-cards__content-info">
                                        <span>{account}</span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="confirmation-cards__content">
                                    <div className="confirmation-cards__content-data">
                                        <span>{i18n.get("table.transferInternal.debitAccount")}</span>
                                    </div>
                                    <div className="confirmation-cards__content-info">
                                        <span>
                                            <span>{account}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="confirmation-cards__content">
                                    <div className="confirmation-cards__content-data">
                                        <span>{i18n.get("table.transferInternal.idTransaction")}</span>
                                    </div>
                                    <div className="confirmation-cards__content-info">
                                        <span>{object.transaction.idTransaction.substring(0, 8).toUpperCase()}</span>
                                    </div>
                                </div>
                                <div className="confirmation-cards__content">
                                    <div className="confirmation-cards__content-data">
                                        <span>{i18n.get("table.transferExternal.concept")}</span>
                                    </div>
                                    <div className="confirmation-cards__content-info">
                                        <span>
                                            {`${object.transaction.data.concept} - ${i18n.get(
                                                `transfers.concept.${object.transaction.data.concept}`,
                                            )}`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    bottom={
                        isDesktop && (
                            <div className="d-flex align-items-center">
                                <div className="confirmation-cards__bottom confirmation-cards-separator">
                                    <div className="confirmation-cards__bottom-data">
                                        <span>{i18n.get("table.transferInternal.idTransaction")}</span>
                                    </div>
                                    <div className="confirmation-cards__bottom-info">
                                        <span>{object.transaction.idTransaction.substring(0, 8).toUpperCase()}</span>
                                    </div>
                                </div>
                                <div className="confirmation-cards__bottom">
                                    <div className="confirmation-cards__bottom-data">
                                        <span>{i18n.get("table.transferExternal.concept")}</span>
                                    </div>
                                    <div className="confirmation-cards__bottom-info">
                                        <span>
                                            {`${object.transaction.data.concept} - ${i18n.get(
                                                `transfers.concept.${object.transaction.data.concept}`,
                                            )}`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    rightContent={
                        <div className="confirmation-cards__right">
                            <div className="confirmation-cards__right-data">
                                <FormattedAmount currency={currency} quantity={amount} />
                            </div>
                        </div>
                    }
                />
            </CardContainer>
        );
    }

    render() {
        const { body } = this.state;

        return body;
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(TransferExternalInternalDataTable);
