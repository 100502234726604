/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import { selectors as transactionsSelectors } from "reducers/transactions";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

function EcheqMassiveEmisionDataTable({ isDesktop, multipleSignaturesData }) {
    const { selectedTransactionRows } = multipleSignaturesData;
    return selectedTransactionRows.map((object) => {
        const {
            data: { batchId, reference, echeqsQuantity, processDate, file, amountToShow, account },
        } = object.transaction;

        function maskAccount(accountLabel) {
            if (!accountLabel) {
                return "";
            }

            const lastFourDigits = accountLabel.slice(-11);
            return `****${lastFourDigits}`;
        }

        return (
            <>
                <CardContainer className={`confirmation__cards-container mt-0 ${isDesktop && "pr-1"}`}>
                    <Card
                        className="confirmation__cards"
                        icon={isDesktop && "images/transfer-PENDING.svg"}
                        iconClass="card__pending-transaction-icon"
                        title={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    <div
                                        className={`confirmation-cards__title ${file?.fileName &&
                                            "confirmation-cards-separator"}`}>
                                        <div className="confirmation-cards__title-data no-wrap">
                                            <span>{i18n.get("echeq.massive.emit.summary.processDate.label")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <FormattedDate date={processDate} />
                                        </div>
                                    </div>
                                    {file?.fileName && (
                                        <div className="confirmation-cards__title">
                                            <div className="confirmation-cards__title-data no-wrap">
                                                <span>{i18n.get("echeq.massive.emit.summary.fileName.label")}</span>
                                            </div>
                                            <div className="confirmation-cards__title-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    <span>{file?.fileName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="confirmation-cards__title">
                                    <div className="confirmation-cards__title-data">
                                        <span>{i18n.get("echeq.massive.emit.summary.idBatch.label")}</span>
                                    </div>
                                    <div className="confirmation-cards__title-info no-wrap">
                                        <span>{batchId}</span>
                                    </div>
                                </div>
                            )
                        }
                        content={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    <div className="confirmation-cards__content pb-1">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("echeq.massive.emit.summary.idBatch.label")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{batchId}</span>
                                        </div>
                                    </div>
                                    {echeqsQuantity && (
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("echeq.massive.emit.summary.quantity.label")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    <span>{echeqsQuantity}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {reference && (
                                        <div className="confirmation-cards__content info-type-b">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("echeq.massive.emit.summary.reference.label")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    <span>{reference}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("echeq.massive.emit.summary.processDate.label")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <FormattedDate date={processDate} />
                                        </div>
                                    </div>
                                    {file?.fileName && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("echeq.massive.emit.summary.fileName.label")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    <span>{file?.fileName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("echeq.massive.emit.summary.quantity.label")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>
                                                <span>{echeqsQuantity}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("echeq.massive.emit.summary.reference.label")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info withEllipsis">
                                            <div className="confirmation-cards__with-ellipsis">
                                                <span>{reference}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("echeq.massive.emit.summary.account.label")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <div className="confirmation-cards__with-ellipsis">
                                                <span>{maskAccount(account?.shortLabel)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        bottom={
                            isDesktop && (
                                <div className="d-flex align-items-center">
                                    <div className="confirmation-cards__bottom">
                                        <div className="confirmation-cards__bottom-data">
                                            <span>{i18n.get("echeq.massive.emit.summary.account.label")}</span>
                                        </div>
                                        <div className="confirmation-cards__bottom-info">
                                            <span>{maskAccount(account?.shortLabel)}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        rightContent={
                            <div className="confirmation-cards__right">
                                <div className="confirmation-cards__right-data">
                                    <FormattedAmount
                                        currency={amountToShow.currency}
                                        quantity={amountToShow.quantity}
                                    />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            </>
        );
    });
}

EcheqMassiveEmisionDataTable.propTypes = {
    transactions: shape.isRequired,
    multipleSignaturesData: shape.isRequired,
    isDesktop: bool.isRequired,
};

const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(EcheqMassiveEmisionDataTable);
