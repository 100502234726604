export const types = {
    SET_EMAIL: "onboarding/SET_EMAIL",
    SET_DOCUMENT_DATA: "onboarding/SET_DOCUMENT_DATA",
    SET_DOCUMENT_TYPE: "onboarding/SET_DOCUMENT_TYPE",
    TAKE_PICTURE_ERROR: "onboarding/TAKE_PICTURE_ERROR",
    TAKE_PICTURE_REQUEST: "onboarding/TAKE_PICTURE_REQUEST",
    TAKE_PICTURE_SUCCESS: "onboarding/TAKE_PICTURE_SUCCESS",
    UPLOAD_CLIENT_PERSONAL_INFO_REQUEST: "onboarding/UPLOAD_CLIENT_PERSONAL_INFO_REQUEST",
    UPLOAD_CLIENT_PERSONAL_ADDRESS_REQUEST: "onboarding/UPLOAD_CLIENT_PERSONAL_ADDRESS_REQUEST",
    UPLOAD_PICTURE_ERROR: "onboarding/UPLOAD_PICTURE_ERROR",
    UPLOAD_PICTURE_REQUEST: "onboarding/UPLOAD_PICTURE_REQUEST",
    UPLOAD_PICTURE_SUCCESS: "onboarding/UPLOAD_PICTURE_SUCCESS",
    UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_REQUEST:
        "onboarding/UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_REQUEST",
    UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_ERROR: "onboarding/UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_ERROR",
    UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_SUCCESS:
        "onboarding/UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_SUCCESS",
    VERIFY_INVITATION_CODE_ERROR: "onboarding/VERIFY_INVITATION_CODE_ERROR",
    VERIFY_INVITATION_CODE_REQUEST: "onboarding/VERIFY_INVITATION_CODE_REQUEST",
    VERIFY_INVITATION_CODE_SUCCESS: "onboarding/VERIFY_INVITATION_CODE_SUCCESS",
    VERIFY_VERIFICATION_CODE_ERROR: "onboarding/VERIFY_VERIFICATION_CODE_ERROR",
    VERIFY_VERIFICATION_CODE_REQUEST: "onboarding/VERIFY_VERIFICATION_CODE_REQUEST",
    VERIFY_VERIFICATION_CODE_SUCCESS: "onboarding/VERIFY_VERIFICATION_CODE_SUCCESS",
    REQUEST_VERIFICATION_CODE_PRE_ERROR: "onboarding/REQUEST_VERIFICATION_CODE_PRE_ERROR",
    REQUEST_VERIFICATION_CODE_PRE_REQUEST: "onboarding/REQUEST_VERIFICATION_CODE_PRE_REQUEST",
    REQUEST_VERIFICATION_CODE_PRE_SUCCESS: "onboarding/REQUEST_VERIFICATION_CODE_PRE_SUCCESS",
    SAVE_USER_CREDENTIALS: "onboarding/SAVE_USER_CREDENTIALS",
    CLEAN_USER_CREDENTIALS: "onboarding/CLEAN_USER_CREDENTIALS",
    SET_USER_CREDENTIALS_ERROR: "onboarding/SET_USER_CREDENTIALS_ERROR",
    SET_USER_CREDENTIALS_REQUEST: "onboarding/SET_USER_CREDENTIALS_REQUEST",
    SET_USER_CREDENTIALS_SUCCESS: "onboarding/SET_USER_CREDENTIALS_SUCCESS",
    REQUEST_SECURITY_SEALS_ERROR: "onboarding/REQUEST_SECURITY_SEALS_ERROR",
    REQUEST_SECURITY_SEALS_REQUEST: "onboarding/REQUEST_SECURITY_SEALS_REQUEST",
    REQUEST_SECURITY_SEALS_SUCCESS: "onboarding/REQUEST_SECURITY_SEALS_SUCCESS",

    REQUEST_LIST_STATES: "onboarding/REQUEST_LIST_STATES",
    FAILURE_LIST_STATES: "onboarding/FAILURE_LIST_STATES",
    SUCCESS_LIST_STATES: "onboarding/SUCCESS_LIST_STATES",

    REQUEST_LIST_JOBS: "onboarding/REQUEST_LIST_JOBS",
    FAILURE_LIST_JOBS: "onboarding/FAILURE_LIST_JOBS",
    SUCCESS_LIST_JOBS: "onboarding/SUCCESS_LIST_JOBS",

    REQUEST_LIST_OCCUPATIONS: "onboarding/REQUEST_LIST_OCCUPATIONS",
    REQUEST_LIST_SELECTOR_OPTIONS: "onboarding/REQUEST_LIST_SELECTOR_OPTIONS",
    FAILURE_LIST_OCCUPATIONS: "onboarding/FAILURE_LIST_OCCUPATIONS",
    SUCCESS_LIST_OCCUPATIONS: "onboarding/SUCCESS_LIST_OCCUPATIONS",
};

export const INITIAL_STATE = {
    documentData: {
        documentCountry: null,
        documentNumber: null,
        firstName: null,
        fullDocument: null,
        lastName: null,
        dateOfBirth: null,
    },
    documentType: null,
    email: null,
    errors: null,
    error: null,
    exchangeToken: null,
    fetching: false,
    mobileNumber: null,
    picture: null,
    invitationCode: null,
    verificationCode: null,
    personalDataEnabled: null,
    secondFactorAuth: null,
    client: null,
    invitation: null,
    userCredentials: {
        password: "",
        passwordConfirmation: "",
        securitySeal: {
            id: "",
            image: undefined,
        },
    },
    securitySeals: {},
    address: {
        addressLine1: null,
        addressLine2: null,
        city: null,
        federalState: null,
        zipcode: null,
        residentSince: null,
    },
    states: [],
    jobs: [],
    occupation_options: [],
    source_of_wealth_options: [],
    annual_income_options: [],
    countryList: [],
    SSNID: "",
    region: null,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        documentCountry,
        documentNumber,
        documentType,
        email,
        errors,
        error,
        exchangeToken,
        firstName,
        fullDocument,
        lastName,
        mobileNumber,
        picture,
        invitationCode,
        verificationCode,
        personalDataEnabled,
        client,
        invitation,
        secondFactorAuth,
        userCredentials,
        securitySeals,
        dateOfBirth,
        address,
        SSNID,
    } = action;

    const documentData = {
        documentCountry,
        documentNumber,
        firstName,
        fullDocument,
        lastName,
        dateOfBirth,
    };

    switch (action.type) {
        case types.REQUEST_LIST_OCCUPATIONS:
            return { ...state };

        case types.SUCCESS_LIST_OCCUPATIONS:
            return {
                ...state,
                occupation_options: action.occupation_options,
                source_of_wealth_options: action.source_of_wealth_options,
                annual_income_options: action.annual_income_options,
                countryList: action.countryList,
                occupations: action.occupations,
            };

        case types.FAILURE_LIST_OCCUPATIONS:
            return {
                ...state,
                errors,
                fetching: false,
            };

        case types.REQUEST_LIST_JOBS:
            return { ...state };

        case types.SUCCESS_LIST_JOBS:
            return { ...state, jobs: action.jobs };

        case types.FAILURE_LIST_JOBS:
            return {
                ...state,
                errors,
                fetching: false,
            };

        case types.REQUEST_LIST_STATES:
            return { ...state };

        case types.SUCCESS_LIST_STATES:
            return { ...state, states: action.states };

        case types.FAILURE_LIST_STATES:
            return {
                ...state,
                errors,
                fetching: false,
            };

        case types.SET_EMAIL:
            return {
                ...state,
                email,
            };
        case types.SET_DOCUMENT_DATA:
            return {
                ...state,
                documentData,
            };
        case types.SET_DOCUMENT_TYPE:
            return {
                ...state,
                documentType,
            };
        case types.TAKE_PICTURE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.TAKE_PICTURE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.TAKE_PICTURE_SUCCESS:
            return {
                ...state,
                fetching: false,
                picture,
                exchangeToken,
            };
        case types.UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_ERROR:
            return {
                ...state,
                errors,
                fetching: false,
            };
        case types.UPLOAD_CLIENT_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                mobileNumber,
                SSNID,
            };
        case types.UPLOAD_CLIENT_PERSONAL_ADDRESS_REQUEST:
            return {
                ...state,
                address,
            };
        case types.UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_SUCCESS:
            return {
                ...state,
                fetching: false,
            };

        case types.UPLOAD_PICTURE_ERROR:
            return {
                ...state,
                errors,
                exchangeToken,
                fetching: false,
            };
        case types.UPLOAD_PICTURE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.UPLOAD_PICTURE_SUCCESS:
            return {
                ...state,
                error: null,
                exchangeToken,
                fetching: false,
            };
        case types.VERIFY_INVITATION_CODE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.VERIFY_INVITATION_CODE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.VERIFY_INVITATION_CODE_SUCCESS:
            return {
                ...state,
                exchangeToken,
                fetching: false,
                invitationCode,
            };
        case types.REQUEST_VERIFICATION_CODE_PRE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.REQUEST_VERIFICATION_CODE_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.REQUEST_VERIFICATION_CODE_PRE_SUCCESS:
            return {
                ...state,
                client,
                fetching: false,
                invitation,
                personalDataEnabled,
                secondFactorAuth,
            };
        case types.VERIFY_VERIFICATION_CODE_ERROR:
            return {
                ...state,
                error,
                fetching: false,
            };
        case types.VERIFY_VERIFICATION_CODE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.VERIFY_VERIFICATION_CODE_SUCCESS:
            return {
                ...state,
                exchangeToken,
                fetching: false,
                verificationCode,
            };
        case types.SAVE_USER_CREDENTIALS:
            return {
                ...state,
                userCredentials,
            };
        case types.CLEAN_USER_CREDENTIALS:
            return {
                ...state,
                userCredentials: INITIAL_STATE.userCredentials,
                documentData: INITIAL_STATE.documentData,
            };
        case types.SET_USER_CREDENTIALS_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.SET_USER_CREDENTIALS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.SET_USER_CREDENTIALS_SUCCESS:
            return {
                ...state,
                fetching: false,
                userCredentials: INITIAL_STATE.userCredentials,
            };
        case types.REQUEST_SECURITY_SEALS_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.REQUEST_SECURITY_SEALS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.REQUEST_SECURITY_SEALS_SUCCESS:
            return {
                ...state,
                exchangeToken,
                fetching: false,
                securitySeals,
            };
        default:
            return state;
    }
};

export const actions = {
    listSelectorsOptionsRequest: () => ({
        type: types.REQUEST_LIST_SELECTOR_OPTIONS,
    }),
    listOccupationsRequest: () => ({
        type: types.REQUEST_LIST_OCCUPATIONS,
    }),
    listOccupationsSuccess: (jobs) => ({
        type: types.REQUEST_LIST_OCCUPATIONS,
        jobs,
    }),
    listJobsRequest: () => ({
        type: types.REQUEST_LIST_JOBS,
    }),
    listJobsSuccess: (jobs) => ({
        type: types.REQUEST_LIST_JOBS,
        jobs,
    }),
    listStatesRequest: () => ({
        type: types.REQUEST_LIST_STATES,
    }),
    listStateSuccess: (states) => ({
        type: types.REQUEST_LIST_STATES,
        states,
    }),
    setEmail: (email) => ({
        type: types.SET_EMAIL,
        email,
    }),
    setDocumentType: (documentType) => ({
        type: types.SET_DOCUMENT_TYPE,
        documentType,
    }),
    takePicture: (currentStep, documentSide = null, picture, exchangeToken = null) => ({
        type: types.TAKE_PICTURE_REQUEST,
        currentStep,
        documentSide,
        picture,
        exchangeToken,
    }),
    takePictureError: () => ({
        type: types.TAKE_PICTURE_ERROR,
    }),
    uploadClientPersonalInfo: (documentNumber, email, firstName, formikBag, lastName, mobileNumber, SSNID) => ({
        type: types.UPLOAD_CLIENT_PERSONAL_INFO_REQUEST,
        documentNumber,
        email,
        firstName,
        formikBag,
        lastName,
        mobileNumber,
        SSNID,
    }),
    uploadClientPersonalAddress: (formikBag, address) => ({
        type: types.UPLOAD_CLIENT_PERSONAL_ADDRESS_REQUEST,
        formikBag,
        address,
    }),
    uploadClientInfoAndSendInvitationCode: (
        documentNumber,
        email,
        firstName,
        formikBag,
        lastName,
        mobileNumber,
        address,
        jobInformation,
        SSNID,
        region,
    ) => ({
        type: types.UPLOAD_CLIENT_INFO_AND_SEND_INVITATION_CODE_REQUEST,
        documentNumber,
        email,
        firstName,
        formikBag,
        lastName,
        mobileNumber,
        address,
        jobInformation,
        SSNID,
        region,
    }),
    uploadPicture: (documentType, exchangeToken, picture, rotatePicture) => ({
        type: types.UPLOAD_PICTURE_REQUEST,
        documentType,
        exchangeToken,
        picture,
        rotatePicture,
    }),
    verifyInvitationCode: (invitationCode, formikBag) => ({
        type: types.VERIFY_INVITATION_CODE_REQUEST,
        invitationCode,
        formikBag,
    }),
    verifyVerificationCode: (verificationCode, formikBag) => ({
        type: types.VERIFY_VERIFICATION_CODE_REQUEST,
        verificationCode,
        formikBag,
    }),
    requestVerificationCodePre: (invitationCode, exchangeToken) => ({
        type: types.REQUEST_VERIFICATION_CODE_PRE_REQUEST,
        invitationCode,
        exchangeToken,
    }),
    saveUserCredentials: (userCredentials) => ({
        type: types.SAVE_USER_CREDENTIALS,
        userCredentials,
    }),
    cleanUserCredentials: () => ({
        type: types.CLEAN_USER_CREDENTIALS,
    }),
    setUserCredentials: (password, passwordConfirmation, securitySealId, username, formikBag) => ({
        type: types.SET_USER_CREDENTIALS_REQUEST,
        password,
        passwordConfirmation,
        securitySealId,
        username,
        formikBag,
    }),
    requestSecuritySeals: (exchangeToken) => ({
        type: types.REQUEST_SECURITY_SEALS_REQUEST,
        exchangeToken,
    }),
};

export const selectors = {
    getDocumentData: ({ onboarding }) => onboarding.documentData,
    getDocumentType: ({ onboarding }) => onboarding.documentType,
    getEmail: ({ onboarding }) => onboarding.email,
    getErrors: ({ onboarding }) => onboarding.errors,
    getExchangeToken: ({ onboarding }) => onboarding.exchangeToken,
    getFetching: ({ onboarding }) => onboarding.fetching,
    getMobileNumber: ({ onboarding }) => onboarding.mobileNumber,
    getPicture: ({ onboarding }) => onboarding.picture,
    getInvitationCode: ({ onboarding }) => onboarding.invitationCode,
    getPersonalDataEnabled: ({ onboarding }) => onboarding.personalDataEnabled,
    getUserCredentials: ({ onboarding }) => onboarding.userCredentials,
    getClient: ({ onboarding }) => onboarding.client,
    getInvitation: ({ onboarding }) => onboarding.invitation,
    getVerificationCode: ({ onboarding }) => onboarding.verificationCode,
    getSecuritySeals: ({ onboarding }) => onboarding.securitySeals,
    getAddress: ({ onboarding }) => onboarding.address,
    getStates: ({ onboarding }) => onboarding.states,
    getOccupation_options: ({ onboarding }) => onboarding.occupation_options,
    getSource_of_wealth_options: ({ onboarding }) => onboarding.source_of_wealth_options,
    getAnnual_income_options: ({ onboarding }) => onboarding.annual_income_options,
    getCountryList: ({ onboarding }) => onboarding.countryList,
    getOccupations: ({ onboarding }) => onboarding.occupations,
    getSSNID: ({ onboarding }) => onboarding.SSNID,
};
