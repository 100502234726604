import React from "react";
import { bool, shape, number, func } from "prop-types";
import classNames from "classnames";

import Wizard from "pages/_components/Wizard";
import Information from "pages/accounts/_components/Information";
import MovementsV2 from "pages/accounts/MovementsV2";
import Movements from "pages/accounts/Movements";
import Extract from "pages/accounts/Extract";
import Button from "pages/_components/Button";

import * as config from "util/config";

export default function TabContent(props) {
    TabContent.propTypes = {
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        account: shape({}),
        tabSelected: number.isRequired,
        onClickDownloadInfo: func,
    };
    TabContent.defaultProps = {
        account: null,
        onClickDownloadInfo: null,
    };

    const transactionsDetailEnebled = config.getBoolean("frontend.movement.transactionsdetail");

    const { account, isDesktop, isSmallDesktop, tabSelected, onClickDownloadInfo } = props;

    const renderContent = () => {
        switch (tabSelected) {
            case 1:
                if (transactionsDetailEnebled) {
                    return <MovementsV2 {...props} />;
                }
                return (
                    <Movements
                        accountId={account.idProduct}
                        accountCurrency={account.currency}
                        isDesktop={isDesktop}
                        keyLabel="accounts.movements"
                        {...props}
                    />
                );

            case 2:
                return (
                    <Information
                        account={account}
                        keyLabel="accounts.information"
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        onClickDownloadInfo={onClickDownloadInfo}
                        {...props}
                    />
                );
            case 3:
                return <Extract isDesktop={isDesktop} />;
            default:
                if (transactionsDetailEnebled) {
                    return <MovementsV2 {...props} />;
                }
                return (
                    <Movements
                        accountId={account.idProduct}
                        accountCurrency={account.currency}
                        isDesktop={isDesktop}
                        keyLabel="accounts.movements"
                        {...props}
                    />
                );
        }
    };

    return renderContent();
}

export function TabComponent(props) {
    TabComponent.propTypes = {
        tabSelected: number.isRequired,
        changeState: func.isRequired,
        isDesktop: bool.isRequired,
    };

    const { tabSelected, changeState, isDesktop } = props;

    return (
        <Wizard.Tabs className="mx-auto my-0 max-width-fit">
            <Wizard.Tab>
                <Button
                    className={classNames("account-detail-tab-button p-0 mx-0", {
                        "cmf-button-width": isDesktop,
                        "is-selected": tabSelected === 1,
                    })}
                    bsStyle="link"
                    label="accounts.tab.movements"
                    type="button"
                    withoutRedirect
                    additionalNavItemClass="account-detail-tab"
                    onClick={() => changeState(1)}
                />
            </Wizard.Tab>
            <Wizard.Tab>
                <Button
                    className={classNames("account-detail-tab-button p-0 mx-0", {
                        "cmf-button-width": isDesktop,
                        "is-selected": tabSelected === 2,
                    })}
                    bsStyle="link"
                    label="accounts.tab.information"
                    type="button"
                    withoutRedirect
                    additionalNavItemClass="account-detail-tab"
                    onClick={() => changeState(2)}
                />
            </Wizard.Tab>
            <Wizard.Tab>
                <Button
                    className={classNames("account-detail-tab-button p-0 mx-0", {
                        "cmf-button-width": isDesktop,
                        "is-selected": tabSelected === 3,
                    })}
                    bsStyle="link"
                    label="accounts.tab.resumenes"
                    type="button"
                    withoutRedirect
                    additionalNavItemClass="account-detail-tab"
                    onClick={() => changeState(3)}
                />
            </Wizard.Tab>
        </Wizard.Tabs>
    );
}
