import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, string } from "prop-types";
import * as i18nUtils from "util/i18n";

import { groupFormDataSelectors } from "reducers/administration";

import Wizard from "pages/_components/Wizard";

class GroupFormWizardTabs extends Component {
    static propTypes = {
        idForm: string.isRequired,
        mode: string.isRequired,
        adminGroup: shape({}).isRequired,
        formProps: shape({}).isRequired,
    };

    render() {
        const { idForm, formProps, adminGroup, mode } = this.props;
        const { permissions, users } = formProps.values;

        return (
            <Wizard.Tabs>
                <Wizard.Tab>
                    <p className="info-group-tab">{i18nUtils.get(`${idForm}.tab.basicInfo`).toUpperCase()}</p>
                </Wizard.Tab>

                {permissions && (
                    <Wizard.Tab disabled={!Object.keys(permissions).length && mode === "view"}>
                        <p className="info-group-tab">{i18nUtils.get(`${idForm}.tab.permissions`).toUpperCase()}</p>
                    </Wizard.Tab>
                )}
                <Wizard.Tab disabled={adminGroup || (users && !users.length && mode === "view")}>
                    <p className="info-group-tab">{i18nUtils.get(`${idForm}.tab.members`).toUpperCase()}</p>
                </Wizard.Tab>
            </Wizard.Tabs>
        );
    }
}

const mapStateToProps = (state) => ({
    adminUsers: groupFormDataSelectors.getAdminUsers(state),
    adminGroup: groupFormDataSelectors.isAdminGroup(state),
});

export default connect(mapStateToProps)(GroupFormWizardTabs);
