/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import MainContainer from "pages/_components/MainContainer";
import { func, bool } from "prop-types";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { Modal } from "react-bootstrap";
import Image from "pages/_components/Image";
import * as configUtils from "util/config";

class IncomingDebinModal extends Component {
    static propTypes = {
        handleCloseBottomSheetClick: func,
        isDisplayed: bool,
        isDesktop: bool,
        acceptClick: func,
        cancelClick: func,
    };

    static defaultProps = {
        handleCloseBottomSheetClick: () => {},
        isDisplayed: false,
        isDesktop: false,
        acceptClick: () => {},
        cancelClick: () => {},
    };

    render() {
        const { isDisplayed, handleCloseBottomSheetClick, isDesktop, acceptClick, cancelClick } = this.props;

        return (
            <>
                {isDesktop ? (
                    <Modal
                        className="debin__modal"
                        dialogClassName="debin__modal"
                        aria-labelledby="modalTitleID"
                        aria-modal="true"
                        onHide={handleCloseBottomSheetClick}
                        backdrop
                        show={isDisplayed}>
                        <div className="modal-container modal-container-debin">
                            <>
                                <Modal.Title componentClass="div" id="modalTitleID" className="mt-0">
                                    <Image src="images/warning-modal.svg" />
                                    <I18n
                                        id="payments.debin.confirm.summary"
                                        component="h3"
                                        componentProps={{ className: "debin__text-title" }}
                                    />
                                </Modal.Title>
                            </>
                            <Modal.Body className="my-0 w-100">
                                <div className="w-100">
                                    <MainContainer>
                                        <div className="debin__modal-description">
                                            <I18n
                                                id=""
                                                // defaultValue={configUtils.get("plazoFijoWeb.Descripcion.Texto")}
                                                defaultValue={configUtils.get("DEBIN.requestreceived.text")}
                                                component="span"
                                            />
                                        </div>
                                        <div>
                                            <div className="d-flex justify-content-center">
                                                <Button
                                                    type="button"
                                                    bsStyle="outline"
                                                    label="global.cancel"
                                                    onClick={cancelClick}
                                                />
                                                <Button
                                                    type="button"
                                                    bsStyle="primary"
                                                    label="global.accept"
                                                    onClick={acceptClick}
                                                />
                                            </div>
                                        </div>
                                    </MainContainer>
                                </div>
                            </Modal.Body>
                        </div>
                    </Modal>
                ) : (
                    <div className="modal-container">
                        <Modal
                            show={isDisplayed}
                            className="debin__modal"
                            onHide={handleCloseBottomSheetClick}
                            dialogClassName="debin__modal">
                            <Modal.Header className="mt-0 pt-1">
                                <Modal.Title
                                    componentClass="div"
                                    id="modalTitleID"
                                    className="mt-0 px-4 debin__modal-title">
                                    <div className="close-without-btn">
                                        <Image src="images/rectangle-dropdown.svg" />
                                    </div>
                                    <Image src="images/warning-modal.svg" />
                                    <I18n
                                        id="payments.debin.confirm.summary"
                                        component="h3"
                                        componentProps={{ className: "debin__text-title" }}
                                    />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="my-0 w-100">
                                <div className="w-100">
                                    <MainContainer>
                                        <div className="debin__modal-description">
                                            <I18n
                                                id=""
                                                // defaultValue={configUtils.get("plazoFijoWeb.Descripcion.Texto")}
                                                defaultValue={configUtils.get("DEBIN.requestreceived.text")}
                                                component="span"
                                            />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <Button
                                                type="button"
                                                bsStyle="primary"
                                                label="global.accept"
                                                onClick={acceptClick}
                                            />
                                            <Button
                                                type="button"
                                                bsStyle="outline"
                                                label="global.cancel"
                                                onClick={cancelClick}
                                            />
                                        </div>
                                    </MainContainer>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                )}
            </>
        );
    }
}

export default IncomingDebinModal;
