import React from "react";
import Heading from "pages/_components/Heading";
import { string, shape, bool } from "prop-types";
import * as i18n from "util/i18n";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import { accountDescription } from "util/checks";

export default function ChecksSummaryData({ checksRequestData, FORM_ID, signature, isDesktop, fullName }) {
    ChecksSummaryData.propTypes = {
        checksRequestData: shape({}).isRequired,
        FORM_ID: string.isRequired,
        signature: bool,
        fullName: string.isRequired,
        isDesktop: bool,
    };
    ChecksSummaryData.defaultProps = {
        signature: false,
        isDesktop: true,
    };

    const getAccountDescription = () => {
        const productAlias = checksRequestData?.account?.productAlias;
        const number = checksRequestData?.account.number;
        const currency = checksRequestData?.account?.currency;
        const productType = checksRequestData?.account.productType;

        return accountDescription(productAlias, number, currency, productType);
    };

    const PreparedBy = () => (
        <p>
            {checksRequestData?.creatorFullName || fullName} -{" "}
            <FormattedDate date={checksRequestData?.creationDate} dateFormat="dd/MM/yyyy" showTime />
        </p>
    );
    const SignBy = () => (
        <p>
            {checksRequestData?.signatures.map((object) => (
                <>
                    {object?.userFirstName.concat(" ", object?.userLastName)} -{" "}
                    <FormattedDate date={object?.creationDateTime} dateFormat="dd/MM/yyyy" showTime />
                    <br />
                </>
            ))}
        </p>
    );

    return (
        <>
            <Heading.DataGroup
                containerClassName={`transfer-data data-wrapper-flex ${!isDesktop ? "space-between" : ""}`}
                label={`${FORM_ID}.account.label`}
                data={checksRequestData?.account && getAccountDescription()}
            />
            <Heading.DataGroup
                containerClassName={`transfer-data data-wrapper-flex ${!isDesktop ? "space-between" : ""}`}
                label={`${FORM_ID}.checksTypes.label`}
                data={checksRequestData?.checksTypes?.label}
            />
            <Heading.DataGroup
                containerClassName={`transfer-data data-wrapper-flex ${!isDesktop ? "space-between" : ""}`}
                label={`${FORM_ID}.checkBookChecksAmount.label`}
                data={checksRequestData?.checkBookChecksAmount}
            />
            <Heading.DataGroup
                containerClassName={`transfer-data data-wrapper-flex ${!isDesktop ? "space-between" : ""}`}
                label={`${FORM_ID}.checkBooksAmount.label`}
                data={checksRequestData?.checkBooksAmount}
            />
            {checksRequestData?.checksTypes?.showsDeliveryPoint && (
                <div style={{ marginTop: "2rem" }}>
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${!isDesktop ? "space-between" : ""}`}
                        label={`${FORM_ID}.authorized.data`}
                        data=""
                    />
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${!isDesktop ? "space-between" : ""}`}
                        label={`${FORM_ID}.documentType.label`}
                        data={checksRequestData?.documentType.concat(" ", checksRequestData?.document)}
                    />
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${!isDesktop ? "space-between" : ""}`}
                        label={`${FORM_ID}.name.label`}
                        data={checksRequestData?.name}
                    />
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${!isDesktop ? "space-between" : ""}`}
                        label={`${FORM_ID}.surname.label`}
                        data={checksRequestData?.surname}
                    />
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex ${!isDesktop ? "space-between" : ""}`}
                        label={`${FORM_ID}.delivery.point`}
                        data={i18n.get(`${FORM_ID}.delivery.point.description`)}
                    />
                </div>
            )}
            {signature && (
                <>
                    <I18n
                        component="h3"
                        id="accounts.new.account.signatures.label"
                        componentProps={{ className: "font-size-25-px" }}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex"
                        label="accounts.new.account.signatures.preparedBy.label"
                        data={<PreparedBy />}
                    />
                    {checksRequestData?.signatures?.length > 0 && (
                        <>
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                label="accounts.new.account.signatures.signBy.label"
                                data={<SignBy />}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}
