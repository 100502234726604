import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { routerActions } from "react-router-redux/actions";
import { func, shape, boolean } from "prop-types";

import { permissionsSelectors, permissionsTicketSelectors } from "reducers/administration";
import { permissionsTicketActions } from "reducers/administration/medium";

import PermissionsTicket from "pages/administration/_components/PermissionsTicket";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";
import PageLoading from "pages/_components/PageLoading";

class PermissionsTicketContent extends Component {
    static propTypes = {
        actions: func.isRequired,
        match: shape({}).isRequired,
        fetching: boolean,
    };

    static defaultProps = {
        fetching: false,
    };

    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadPermissionsTicketRequest(match.params.idTransaction);
    }

    render() {
        const { fetching } = this.props;

        return (
            <PageLoading loading={fetching}>
                <AdministrationTicket {...this.props}>
                    <PermissionsTicket {...this.props} />
                </AdministrationTicket>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: permissionsSelectors.getUser(state),
    fetching: permissionsTicketSelectors.isFetching(state),
    products: permissionsSelectors.getProducts(state),
    permissions: ownProps.permissions || permissionsSelectors.getPermissions(state),
    groups: permissionsSelectors.getGroups(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(permissionsTicketActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsTicketContent);
