import React, { Component, createElement } from "react";
import { func, arrayOf, string } from "prop-types";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";

import I18n from "pages/_components/I18n";
import AmountFilter from "pages/accounts/_components/AmountFilter";
import ChannelFilter from "pages/accounts/_components/ChannelFilter";
import CheckFilter from "pages/accounts/_components/CheckFilter";
import PeriodFilter from "pages/accounts/_components/PeriodFilter";
import ReferenceFilter from "pages/accounts/_components/ReferenceFilter";

import * as i18n from "util/i18n";

const components = {
    period: PeriodFilter,
    amount: AmountFilter,
    reference: ReferenceFilter,
    channel: ChannelFilter,
    check: CheckFilter,
};

class MoreFilters extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        channels: arrayOf(string).isRequired,
        currency: string.isRequired,
        productId: string.isRequired,
    };

    state = {
        selectedFilter: "period",
    };

    handleChange = (selectedOption) => {
        this.setState({ selectedFilter: selectedOption.value });
    };

    renderFilter = () => {
        const { selectedFilter } = this.state;
        const { channels, currency, dispatch, productId } = this.props;

        let props = { dispatch, isDesktop: true, productId };
        if (selectedFilter === "channel") {
            props = { ...props, channels };
        }

        if (selectedFilter === "amount") {
            props = { ...props, currency };
        }

        return selectedFilter && createElement(components[selectedFilter], props);
    };

    render() {
        const { selectedFilter } = this.state;

        const options = [
            {
                value: "period",
                label: i18n.get("accounts.movements.filters.searchBy.period"),
            },
            {
                value: "amount",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
            {
                value: "reference",
                label: i18n.get("accounts.movements.filters.searchBy.reference"),
            },
            {
                value: "channel",
                label: i18n.get("accounts.movements.filters.searchBy.channels"),
            },
            {
                value: "check",
                label: i18n.get("accounts.movements.filters.searchBy.check"),
            },
        ];

        return (
            <div>
                <Row className="filters">
                    <Col className="col" sm={12} md={3}>
                        <div className="form-group form-group--select">
                            <div className="form-group-text">
                                <label className="control-label">
                                    <I18n id="accounts.movements.filters.searchBy" />
                                </label>
                            </div>
                            <div className="input-group ">
                                <div style={{ flex: 1 }}>
                                    <Select
                                        clearable={false}
                                        searchable={false}
                                        onChange={this.handleChange}
                                        options={options}
                                        value={selectedFilter}
                                        optionClassName="needsclick"
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className="col" sm={12} md={9}>
                        {this.renderFilter()}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default MoreFilters;
