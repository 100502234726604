import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { routerActions } from "react-router-redux/actions";

import { permissionsSelectors } from "reducers/administration";
import { permissionsActions } from "reducers/administration/simple";

import Permissions from "pages/administration/_components/Permissions";

const mapStateToProps = (state) => ({
    products: permissionsSelectors.getProducts(state),
    permissions: permissionsSelectors.getPermissions(state),
    groups: permissionsSelectors.getGroups(state),
    user: permissionsSelectors.getUser(state),
    fetching: permissionsSelectors.isFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(permissionsActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Permissions);
