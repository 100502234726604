import React, { Component } from "react";
import { connect } from "react-redux";
import { string, shape, number, arrayOf } from "prop-types";

import { selectors as transactionLinesSelectors } from "reducers/form/transactionLines";
import { selectors as fileSelectors } from "reducers/formFields/multilineFile";
import { selectors as formSelectors } from "reducers/form";

import CollapsibleInfo from "pages/_components/CollapsibleInfo";
import Container from "pages/_components/Container";
import DataContent from "pages/_components/DataContent";
import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";

class FileCollapsibleInfo extends Component {
    static propTypes = {
        processedFileData: shape({
            fileIdentifier: string,
            fileName: string,
            totalAmount: shape({
                quantity: number,
                currency: string,
            }),
        }).isRequired,
        transaction: shape({
            creationDateTime: string,
            data: shape({
                file: arrayOf(
                    shape({
                        fileName: string,
                    }),
                ),
            }),
        }).isRequired,
    };

    render() {
        const { processedFileData, transaction } = this.props;
        const [file] = transaction.data ? transaction.data.file : [{ fileName: "" }];
        if (!file) {
            return null;
        }
        return (
            <CollapsibleInfo
                about={
                    <span className="text-lead">
                        <I18n id="productHead.moreInfo.text" />
                    </span>
                }>
                <Container.Row>
                    <Container.Column sm={12} md={4}>
                        <DataContent label={<I18n id="transaction.process.details.heading.id" />}>
                            {processedFileData.fileIdentifier}
                        </DataContent>
                    </Container.Column>
                    <Container.Column sm={12} md={4}>
                        <DataContent label={<I18n id="transaction.process.details.heading.name" />}>
                            {file.fileName}
                        </DataContent>
                    </Container.Column>
                    <Container.Column sm={12} md={4}>
                        <DataContent label={<I18n id="transaction.process.details.heading.date" />}>
                            <FormattedDate date={transaction.creationDateTime} />
                        </DataContent>
                    </Container.Column>
                </Container.Row>
            </CollapsibleInfo>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionLines: transactionLinesSelectors.getTransactionLines(state),
    transaction: formSelectors.getTransaction(state),
    processedFileData: fileSelectors.getProcessedFileData(state),
});

export default connect(mapStateToProps)(FileCollapsibleInfo);
