import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { func, arrayOf, shape, string, bool, element } from "prop-types";
import CampaignItem from "pages/campaigns/CampaignItem";

import { selectors, actions } from "reducers/campaigns";

class Campaigns extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        section: string.isRequired,
        items: arrayOf(
            shape({
                image: string,
                idCampaign: string,
                url: string,
                clickable: bool,
            }),
        ).isRequired,
        container: element,
    };

    static defaultProps = {
        container: null,
    };

    componentDidMount() {
        const { dispatch, section } = this.props;
        dispatch(actions.loadCampaign(section));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(actions.removeCampaigns());
    }

    render() {
        const { items, container, dispatch } = this.props;

        if (items.length === 0) {
            return null;
        }

        const list = items.map((item) => <CampaignItem {...item} dispatch={dispatch} />);

        const campaignsOut = container ? (
            <container.element className={container.className}>{list}</container.element>
        ) : (
            <Fragment>{list}</Fragment>
        );

        return campaignsOut;
    }
}

const mapStateToProps = (state) => ({
    items: selectors.getCampaigns(state),
});

export default connect(mapStateToProps)(Campaigns);
