import React, { Component, Fragment } from "react";
import Row from "react-bootstrap/lib/Row";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { func, shape, string, bool, arrayOf } from "prop-types";

import { groupsSelectors, detailsSelectors } from "reducers/administration";

import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import AdministrationHeading from "pages/administration/_components/Heading";
import PageLoading from "pages/_components/PageLoading";
import MainContainer from "pages/_components/MainContainer";
import MultiSelectField from "pages/_components/fields/MultiSelectField";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import InfoTag from "pages/_components/InfoTag";
import Heading from "pages/_components/Heading";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { Col } from "react-bootstrap";

const FORM_ID = "administration.groupsOfUser.modify";

class GroupsOfUser extends Component {
    static propTypes = {
        actions: shape({ loadChannelsRequest: func }).isRequired,
        routerActions: shape({ goBack: func.isRequired }).isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        fetching: bool.isRequired,
        availableGroups: arrayOf(
            shape({
                idGroupAsString: string,
                name: string,
            }),
        ),
        selectedGroups: arrayOf(
            shape({
                idGroupAsString: string,
                name: string,
            }),
        ),
        hasMassiveEnabled: bool,
        user: shape({}),
    };

    static defaultProps = {
        availableGroups: [],
        selectedGroups: [],
        hasMassiveEnabled: false,
        user: null,
    };

    componentDidMount() {
        const { actions, match } = this.props;

        actions.loadGroupsRequest(match.params.id);
    }

    handleBack = () => {
        const { routerActions } = this.props;
        routerActions.goBack();
    };

    handleSubmit = (values, formikBag) => {
        const { match, actions } = this.props;

        actions.updateGroupsOfUserPreview(
            {
                groups: values.groups,
                idUser: match.params.id,
            },
            formikBag,
        );
    };

    renderContent = () => (
        <>
            <div className="admin-detail-head admin-detail-head-section px-0">
                {this.renderHeader()}
                <AdministrationHeading>
                    {({ email, documentType, documentNumber, mobileNumber }) => (
                        <Row className="content-data px-2">
                            <Col md={3} sm={12} className="px-3 d-flex align-items-center">
                                <Heading.DataGroup
                                    label="administration.permissions.document"
                                    data={`${documentType} ${documentNumber}`}
                                />
                            </Col>
                            <Col md={6} sm={12} className="px-3 d-flex justify-content-center align-items-center">
                                <Heading.DataGroup label="administration.permissions.email" data={email} />
                            </Col>
                            <Col md={3} sm={12} className="px-3 d-flex align-items-center justify-content-end">
                                <Heading.DataGroup
                                    label="administration.permissions.mobileNumber"
                                    data={mobileNumber}
                                />
                            </Col>
                        </Row>
                    )}
                </AdministrationHeading>
            </div>

            <MainContainer>
                <Formik initialValues={{ groups: "" }} onSubmit={this.handleSubmit} enableReinitialize>
                    {this.renderForm}
                </Formik>
            </MainContainer>
        </>
    );

    renderForm = ({ isSubmitting }) => {
        const { availableGroups, selectedGroups } = this.props;
        const groupOptionsMap = new Map();
        availableGroups.forEach((group) => {
            groupOptionsMap.set(group.idGroupAsString, group.name);
        });
        const groupOptions = [...groupOptionsMap.keys()];

        const initialOptionsMap = new Map();
        selectedGroups.forEach((group) => {
            initialOptionsMap.set(group.idGroupAsString, group.name);
        });
        const initialOptions = [...initialOptionsMap.keys()];
        return (
            <Form className="above-the-fold">
                <div className="text-center">
                    <I18n
                        component="h2"
                        componentProps={{ className: "cmf-title-center mt-25 mb-25" }}
                        id="administration.configurateGroups.subtitle"
                    />
                </div>

                <Container
                    className="flex-grow py-4 pt-xl-6 pb-xl-7 mb-6 mb-xl-8  align-items-center cmf-container-white cmf-container-margin-bottom"
                    gridClassName="form-content">
                    <Container.Column className="col-12 mt-1 mb-4 max-xl-480 px-xl-0" md={9} lg={6}>
                        <FieldLabel
                            formGroupTextClassName="mb-1"
                            labelKey="administration.configurateGroups.usersGroups"
                        />
                        <Container.ColumnBody>
                            <Field
                                component={MultiSelectField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="groups"
                                inputClassName="forms-multi"
                                options={groupOptions}
                                hideLabel
                                textOptionsMap={groupOptionsMap}
                                initialOptions={initialOptions}
                            />
                        </Container.ColumnBody>
                    </Container.Column>
                </Container>
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Container.Column className="col-12 max-xl-480 px-0" md={9} lg={6}>
                        <Container.ColumnBody>
                            <Button
                                className="mt-xl-5"
                                type="submit"
                                bsStyle="primary"
                                label="administration.channels.modify"
                                loading={isSubmitting}
                            />
                        </Container.ColumnBody>
                    </Container.Column>
                </Container>
            </Form>
        );
    };

    renderHeader = () => {
        const { user, hasMassiveEnabled } = this.props;
        const isAdministrator = !hasMassiveEnabled;

        if (user) {
            const { fullName, idUserStatus } = user;

            return (
                <>
                    <Head onBack={this.handleBack} />
                    {fullName && (
                        <div className="mb-3">
                            <div className="view-title d-flex">
                                <I18n
                                    id="administration.configurateGroups.title"
                                    componentProps={{ className: "align-font-fix-04" }}
                                    fullName={fullName}
                                    component="h1"
                                />
                                <div className="data-wrapper data-wrapper-flex">
                                    {isAdministrator && (
                                        <InfoTag
                                            type="default"
                                            message={i18nUtils.get(`administration.administrator`)}
                                            tagBackground="#707070"
                                        />
                                    )}
                                    {user && (
                                        <InfoTag
                                            type="default"
                                            message={i18nUtils.get(`user.status.${idUserStatus}`)}
                                            tagBackground={idUserStatus === "blocked" ? "#fc5f5f" : "#0be0a2"}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            );
        }
        return null;
    };

    render() {
        const { fetching } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="administrationGroupsOfUser" />
                <PageLoading loading={fetching}>{!fetching && this.renderContent()}</PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: groupsSelectors.getUser(state),
    availableGroups: groupsSelectors.getAvailableGroups(state),
    selectedGroups: groupsSelectors.getSelectedGroups(state),
    hasMassiveEnabled: detailsSelectors.hasMassiveEnabled(state),
});

export default connect(mapStateToProps)(GroupsOfUser);
