import { createReducer, makeActionCreator } from "util/redux";

const INITIAL_STATE = {
    fetching: true,
};

export const selectors = {
    isFetching: ({ permissionsTicket }) => permissionsTicket.fetching,
};

export default (name) => {
    const types = {
        LOAD_PERMISSIONS_TICKET_REQUEST: `${name}/LOAD_PERMISSIONS_TICKET_REQUEST`,
        LOAD_PERMISSIONS_TICKET_FAILURE: `${name}/LOAD_PERMISSIONS_TICKET_FAILURE`,
        LOAD_PERMISSIONS_TICKET_SUCCESS: `${name}/LOAD_PERMISSIONS_TICKET_SUCCESS`,
    };

    return {
        types,
        reducer: createReducer(INITIAL_STATE, {
            [types.LOAD_PERMISSIONS_TICKET_REQUEST]: () => ({
                fetching: true,
            }),
            [types.LOAD_PERMISSIONS_TICKET_FAILURE]: () => ({
                fetching: false,
            }),
            [types.LOAD_PERMISSIONS_TICKET_SUCCESS]: () => ({
                fetching: false,
            }),
        }),
        actions: {
            loadPermissionsTicketRequest: makeActionCreator(types.LOAD_PERMISSIONS_TICKET_REQUEST, "idTransaction"),
            loadPermissionsTicketFailure: makeActionCreator(types.LOAD_PERMISSIONS_TICKET_FAILURE),
            loadPermissionsTicketSuccess: makeActionCreator(types.LOAD_PERMISSIONS_TICKET_SUCCESS),
        },
    };
};
