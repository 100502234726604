import React, { Component } from "react";
import classNames from "classnames";

import Notification from "pages/_components/Notification";
import Step4Content from "pages/recoveryPassword/_components/Step4Content";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";
import { bool } from "prop-types";

class RecoveryPassStep4 extends Component {
    render() {
        const { isDesktop } = this.props;

        return (
            <React.Fragment>
                <Notification scopeToShow="recoveryPassword" />

                <MainContainer className={classNames("login__layout main-container", { 
                    "container-fluid": isDesktop,
                    "px-3": !isDesktop, 
                })}>
                    <Step4Content {...this.props} />
                </MainContainer>
            </React.Fragment>
        );
    }
}

RecoveryPassStep4.propTypes = {
    isDesktop: bool.isRequired,
};

export default withExchangeToken(RecoveryPassStep4);
