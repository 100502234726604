import React, { Component } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { func, bool, shape } from "prop-types";
import { goBack } from "react-router-redux";
import { compose } from "redux";

import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import { selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import ServicePaymentsSummaryData from "./_components/ServicePaymentsSummaryData";
import ServicePaymentsTokenConfirmation from "./_components/ServicePaymentsTokenConfirmation";

const FORM_ID = "services.newPayment";

class ServicePaymentsConfirmationTicket extends Component {
    static propTypes = {
        isDesktop: bool,
        dispatch: func.isRequired,
        summaryInfo: shape({}).isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", margin: 0 }}>{i18n.get("services.newPayment")}</h1>
        </div>
    );

    render() {
        const { isDesktop, summaryInfo } = this.props;

        return (
            <>
                <Notification scopeToShow="servicePayments" />
                <div className={isDesktop ? "admin-detail-head px-0" : ""}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile new-payment-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && <Head title={`${FORM_ID}.confirmationTicket.title`} />}
                </div>
                <MainContainer>
                    <Container
                        className={`flex-grow ${isDesktop ? "align-items-center" : ""} container-white`}
                        gridClassName="form-content">
                        <div
                            className={`admin-content-center ${!isDesktop && "admin-content-center-mobile"}`}
                            style={{ marginBottom: "2rem", width: !isDesktop && "70%" }}>
                            <I18n id={`${FORM_ID}.confirmationTicket.label`} component="h2" />
                        </div>
                        <Col className="col-12" lg={4} md={9} sm={12}>
                            <ServicePaymentsSummaryData
                                summaryInfo={summaryInfo}
                                FORM_ID={FORM_ID}
                                isDesktop={isDesktop}
                            />
                        </Col>
                    </Container>

                    <ServicePaymentsTokenConfirmation paymentRequestData={summaryInfo} />
                </MainContainer>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    summaryInfo: servicePaymentsSelectors.getSummaryInfo(state),
});

export default compose(connect(mapStateToProps))(ServicePaymentsConfirmationTicket);
