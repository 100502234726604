import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form } from "formik";
import { bool, func, shape } from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";

import classNames from "classnames";
import MainContainer from "pages/_components/MainContainer";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import { Mixpanel } from "util/clickstreaming";
import Head from "pages/_components/Head";
import { compose } from "redux";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Logo from "pages/login/_components/Logo";

const FORM_ID = "recoveryPassword.step1";

class RecoveryPassAndUserpassNoneToken extends Component {
    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(push("/"));
    };

    renderTitleMobile = () => <Logo className="svg-image logo logo-mobile-size" logoColor />;

    render() {
        const { isDesktop, dispatch, propsGrid } = this.props;

        return (
            <GridLayoutExternal isDesktop={isDesktop} {...propsGrid}>
                <Form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                    <div className="admin-detail-head px-0">
                        <Head
                            title={isDesktop ? "recoveryPassAndUserpass.noneToken.title" : null}
                            onClose={this.onHeaderClose}
                            headerClassName={
                                !isDesktop
                                    ? "login-background-color-white"
                                    : "recover-pass-desktop recover-pass-desktop-token"
                            }
                            centerElement={!isDesktop && this.renderTitleMobile}
                            hideMobileMenu={!isDesktop && true}
                            fixed
                        />
                    </div>
                    <MainContainer
                        className={classNames("login__layout main-container", { "container-fluid": isDesktop })}>
                        {!isDesktop && (
                            <Container className="container--layout flex-grow">
                                <Col className="col col-12 view-title mt-3">
                                    <div className="justify-content-center">
                                        <I18n
                                            id="recoveryPassAndUserpass.noneToken.title"
                                            componentProps={{ className: "f-size-5 font-regular" }}
                                            component="h2"
                                        />
                                    </div>
                                </Col>
                            </Container>
                        )}
                        <form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                            <Container className="container--layout flex-grow align-items-center mt-4 px-0">
                                <Col
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    className={classNames("col col-12", { "px-0": isDesktop })}>
                                    <I18n
                                        component="p"
                                        componentProps={{ className: "text-left recovery-pass" }}
                                        id="recoveryPassAndUserpass.noneToken.menssage1"
                                    />

                                    <ul className={`mt-5 mx-0 px-${isDesktop ? "0" : "3"} recovery-pass-list}`}>
                                        <li className="item-list my-3">
                                            <I18n
                                                componentProps={{ className: "item-list-text" }}
                                                id="recoveryPassAndUserpass.noneToken.menssage2"
                                            />
                                        </li>
                                        <li className="item-list my-3">
                                            <I18n
                                                componentProps={{ className: "item-list-text" }}
                                                id="recoveryPassAndUserpass.noneToken.menssage3"
                                            />
                                        </li>
                                        <li className="item-list my-3">
                                            <I18n
                                                componentProps={{ className: "item-list-text" }}
                                                id="recoveryPassAndUserpass.noneToken.menssage4"
                                            />
                                        </li>
                                    </ul>
                                </Col>
                            </Container>
                            <Container className="align-items-center container--layout">
                                <Col sm={12} md={12} lg={12} className={`col col-12 mt-4 px-${isDesktop ? "0" : "3"}`}>
                                    <Button
                                        label="global.button.signout"
                                        type="button"
                                        bsStyle="primary"
                                        onClick={() => {
                                            dispatch(push("/"));
                                        }}
                                    />
                                </Col>
                            </Container>
                        </form>
                    </MainContainer>
                </Form>
            </GridLayoutExternal>
        );
    }
}

RecoveryPassAndUserpassNoneToken.propTypes = {
    isDesktop: bool,
    dispatch: func.isRequired,
    propsGrid: shape({}).isRequired,
};

RecoveryPassAndUserpassNoneToken.defaultProps = {
    isDesktop: true,
};

const mapStateToProps = () => ({});

export default compose(
    connect(mapStateToProps),
    withFormik({
        handleSubmit: () => {
            Mixpanel.track(FORM_ID);
        },
    }),
)(RecoveryPassAndUserpassNoneToken);
