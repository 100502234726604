import React, { Component } from "react";

import ResizableComponent from "pages/_components/ResizableComponent";

import { getDisplay, isMobileNative, getMobileOS } from "util/device";

/**
 * Higher-Order Component para el manejo del evento resize de la ventana.
 * Se utiliza en conjunto con el HOC resizable
 * @param {*} BaseComponent
 */
export const resizableRoute = (BaseComponent) =>
    class ResizableRoute extends Component {
        state = {
            display: getDisplay(),
        };

        _timeout = null;

        componentWillUnmount() {
            if (this._timeout) {
                clearTimeout(this._timeout);
            }
        }

        handleResize = () => {
            clearTimeout(this._timeout);
            this._timeout = setTimeout(this.updateDisplay, 100);
        };

        updateDisplay = () => {
            const { display } = this.state;
            const newDisplay = getDisplay();

            if (display !== newDisplay) {
                // Dispatch 'display-changed' event
                document.dispatchEvent(new Event("display-changed"));

                this.setState({
                    display: newDisplay,
                });
            }
        };

        render() {
            const { display } = this.state;

            return (
                <ResizableComponent onResize={this.handleResize}>
                    <BaseComponent
                        {...this.props}
                        isDesktop={display === "desktop" || display === "smallDesktop"}
                        isSmallDesktop={display === "smallDesktop"}
                        isTablet={display === "tablet"}
                        isMobile={display === "mobile" || display === "tablet"}
                        isMobileNative={isMobileNative}
                        mobileOS={getMobileOS(display)}
                    />
                </ResizableComponent>
            );
        }
    };

export default { resizableRoute };
