import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "pages/_components/Button";

function ConditionalContent({ children, className }) {
    const [showContent, setShowContent] = useState(false);

    const handleClick = () => {
        setShowContent((prev) => !prev);
    };

    return (
        <div>
            <Button
                onClick={handleClick}
                bsStyle="link"
                label={showContent ? "menu.seelees.label" : "menu.seemore.label"}
                type="button"
                className={className}
            />
            <div className={`card--expand ${showContent ? "active" : "inactive"}`}>{children}</div>
        </div>
    );
}

ConditionalContent.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

ConditionalContent.defaultProps = {
    className: "",
};

export default ConditionalContent;
