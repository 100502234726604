import React, { Component } from "react";
import { string, shape, bool } from "prop-types";
import moment from "moment";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedRate from "pages/_components/FormattedRate";
import Heading from "pages/_components/Heading";

import * as i18nUtils from "util/i18n";

class DepositTransactionData extends Component {
    static propTypes = {
        depositTransactionData: shape({
            debitAccount: string.isRequired,
            depositType: string.isRequired,
            consolidatedAmount: shape({}).isRequired,
            termInDays: string.isRequired,
            dueDate: string.isRequired,
            capital: shape({}).isRequired,
            totalAmount: shape({}).isRequired,
            earnedInterests: shape({}).isRequired,
            rate: string.isRequired,
            automaticRenewal: bool.isRequired,
            actionToDue: string.isRequired,
        }).isRequired,
    };

    getAccountDescription = ({ number, productType, currency }) => {
        if (!number) {
            return "";
        }
        return `${number} ${productType} ${i18nUtils.get(`currency.label.${currency}`)}`;
    };

    dateTimeCreationIsDifferentFromModify = (creation, modification) => {
        const creation_ = moment(creation);
        const modification_ = moment(modification);
        return modification_.diff(creation_, "seconds") > 1;
    };

    render() {
        const {
            depositTransactionData: {
                debitAccount,
                depositType,
                termInDays,
                dueDate,
                capital,
                totalAmount,
                earnedInterests,
                rate,
                automaticRenewal,
                actionToDue,
                profitTax,
                dateAndHour,
                voucher,
                idTransaction,
                openDate,
                tea,
                idTransactionStatus,
            },
        } = this.props;

        const getFormattedAmount = (amount) => {
            if (amount && amount.quantity) {
                return (
                    <FormattedAmount
                        currency={amount.currency}
                        quantity={amount.quantity}
                        fontClassName="data-desc small-font"
                        notBold
                        small
                    />
                );
            }

            return <span className="data-text">-</span>;
        };

        const containerClassName = "transaction-ticket-detail data-wrapper-flex space-between";
        const depositTypeLabel = depositType?.split("-")[2];

        return (
            <>
                <div>
                    {idTransactionStatus === "FINISHED" && (
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="deposits.constitute.confirmation.dateAndHour.label"
                            data={
                                <FormattedDate
                                    date={dateAndHour.toString().replace("Z", "")}
                                    dateFormat="dd/MM/yyyy"
                                    showTime
                                    small
                                />
                            }
                        />
                    )}

                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.idTransaction.label"
                        data={<p className="data-desc m-0">{idTransaction.substring(0, 8).toUpperCase()}</p>}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.voucher.label"
                        data={<p className="data-desc m-0">{voucher}</p>}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.type.label"
                        data={<p className="data-desc m-0">{depositTypeLabel}</p>}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.debitAccount.label"
                        data={
                            <p className="data-desc m-0">
                                {debitAccount?.number !== undefined && this.getAccountDescription(debitAccount)}
                            </p>
                        }
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.consolidatedAmount.label"
                        data={
                            <FormattedAmount
                                currency={capital?.currency.toString()}
                                quantity={capital?.quantity}
                                fontClassName="data-desc small-font"
                                notBold
                                small
                            />
                        }
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.earnedInterests.label"
                        data={getFormattedAmount(earnedInterests)}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.profitTax.label"
                        data={getFormattedAmount(profitTax)}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.totalAmount.label"
                        data={
                            <FormattedAmount
                                currency={totalAmount?.currency.toString()}
                                quantity={totalAmount?.quantity}
                                fontClassName="data-desc small-font"
                                notBold
                                small
                            />
                        }
                    />

                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.openDate.label"
                        data={
                            <FormattedDate
                                date={openDate?.toString()?.replace("Z", "")}
                                dateFormat="dd/MM/yyyy"
                                showTime
                                timeWithoutSeconds
                                small
                            />
                        }
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.dueDate.label"
                        data={<FormattedDate date={dueDate} dateFormat="dd/MM/yyyy" small />}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.termInDays.label"
                        data={<FormattedRate rate={termInDays} small intNumber className="data-desc small-font" />}
                    />
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.rate.label"
                        data={
                            <FormattedRate rate={rate} small symbolToTheLeft={false} className="data-desc small-font" />
                        }
                    />
                    {tea !== undefined && (
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="deposits.constitute.confirmation.effectiveRate.label"
                            data={
                                <FormattedRate
                                    rate={tea}
                                    small
                                    symbolToTheLeft={false}
                                    className="data-desc small-font"
                                />
                            }
                        />
                    )}

                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.constitute.confirmation.automaticRenewal.label"
                        data={
                            <p className="data-desc m-0">
                                {i18nUtils.get(`deposits.constitute.automaticRenewal.options.${automaticRenewal}`)}
                            </p>
                        }
                    />
                    {automaticRenewal && (
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="deposits.constitute.confirmation.actionToDue.label"
                            data={
                                <p className="data-desc m-0">
                                    {i18nUtils.get(`deposits.constitute.actionToDue.options.${actionToDue}`)}
                                </p>
                            }
                        />
                    )}
                </div>
            </>
        );
    }
}

export default DepositTransactionData;
