import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Button from "pages/_components/Button";
import Col from "react-bootstrap/lib/Col";
import { Field, Form, withFormik } from "formik";
import { bool, func, shape } from "prop-types";
import Yup from "yup";
import Container from "pages/_components/Container";
import Selector from "pages/_components/fields/formik/Selector";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import I18n from "pages/_components/I18n";
import moment from "moment";
import * as i18nUtils from "util/i18n";
import classNames from "classnames";
import Image from "../_components/Image";
import ExtractListItem from "./ExtractListItem";

const FORM_ID = "accounts.summaries";
class Extract extends Component {
    static propTypes = {
        selectedAccount: shape({}).isRequired,
        loadingPreview: bool.isRequired,
        dispatch: func.isRequired,
        values: shape({}),
        listDateToYear: shape([]).isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        values: {},
    };

    componentDidMount() {
        const {
            dispatch,
            isDesktop,
            values: { searchYear, searchMonth },
            selectedAccount: { idProduct },
        } = this.props;
        if (!isDesktop) {
            dispatch(accountsActions.summariesQuery(idProduct, searchYear, searchMonth));
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(accountsActions.cleanAccountSummaries());
    }

    handleClickYearMonth = (searchYear, searchMonth, accountId) => {
        const { dispatch } = this.props;
        dispatch(accountsActions.summariesQuery(accountId, searchYear, searchMonth));
    };

    renderTable = () => {
        const {
            selectedAccount: { productType },
            listDateToYear,
        } = this.props;
        const list = listDateToYear.map((item) => <ExtractListItem {...item} productType={productType} />);

        return (
            <Fragment>
                <div>
                    {list.length > 0 ? (
                        <Container className="flex-grow align-items-center">
                            <Col className="col col-12 mt-3 extract-list" sm={12} md={8} lg={6} xl={6}>
                                {list}
                            </Col>
                        </Container>
                    ) : (
                        <div className="text-center no-more-data" key="noMoreMovements">
                            <div className="illustration-wrapper">
                                <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                            </div>
                            <p className="text-lead">
                                <I18n id="accounts.summaries.noMoreMovements" />
                            </p>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    };

    render() {
        const {
            selectedAccount: { idProduct },
            loadingPreview,
            listDateToYear,
            values: { searchYear, searchMonth },
            isDesktop,
        } = this.props;

        const currentYear = moment().year();
        // Ale garrahan preguntó al banco y dijeron que va a ser el año mínimo 2021
        const yearBase = 2021;

        const years = new Array(currentYear - yearBase + 1)
            .fill(currentYear)
            .map((x, idx) => ({ value: x - idx, label: x - idx }));

        const monthsArray = i18nUtils.getArray("global.months").map((x, idx) => ({
            value: idx < 9 ? `0${idx + 1}` : `${idx + 1}`,
            label: x.toUpperCase(),
        }));

        const sortedMonth = monthsArray
            .sort((a, b) => {
                if (a.value < b.value) {
                    return 1;
                }
                return -1;
            })
            .filter((month) => parseInt(month.value, 10) <= new Date().getMonth() + 1 || searchYear < currentYear);

        const months = [
            { value: i18nUtils.get("channels.all"), label: i18nUtils.get("channels.all").toUpperCase() },
            ...sortedMonth,
        ];

        return (
            <>
                <Form autoComplete="off">
                    <Container
                        className="align-items-center account-header-detail align-items-center"
                        gridClassName="form-content">
                        <Col sm={12} md={8} lg={6} xl={6}>
                            <Col sm={4}>
                                <Field
                                    component={Selector}
                                    hidePlaceholder
                                    options={years}
                                    idForm={FORM_ID}
                                    name="searchYear"
                                    inLineControl
                                    isRequired
                                    style={{ padding: "12px 1rem" }}
                                />
                            </Col>
                            <Col sm={4}>
                                <Field
                                    component={Selector}
                                    hidePlaceholder
                                    options={months}
                                    idForm={FORM_ID}
                                    name="searchMonth"
                                    inLineControl
                                    isRequired
                                    style={{ padding: "12px 1rem" }}
                                />
                            </Col>
                            <Col sm={4} className={classNames("d-flex align-items-end", { "h-100": isDesktop })}>
                                <Button
                                    onClick={() => this.handleClickYearMonth(searchYear, searchMonth, idProduct)}
                                    bsStyle="primary"
                                    label="generate.extract.search"
                                    className={classNames("filter-button mb-3", { "w-100": !isDesktop })}
                                    loading={loadingPreview}
                                />
                            </Col>
                        </Col>
                    </Container>
                </Form>

                {listDateToYear && this.renderTable()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    listDateToYear: accountsSelectors.getListDateToYear(state),
    selectedAccount: accountsSelectors.getSelectedAccount(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            searchYear: moment().year(),
            searchMonth: i18nUtils.get("channels.all"),
        }),
        validationSchema: () => Yup.object().shape({}),
        handleSubmit: () => {},
    }),
)(Extract);
