import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { detailsSelectors } from "reducers/administration";
import { actions as formActions } from "reducers/form";
import { detailsActions } from "reducers/administration/medium";

import SignatureConfirm from "pages/administration/_components/confirmations/SignatureConfirm";

const mapStateToProps = (state) => ({
    user: detailsSelectors.getUser(state),
    hasSignatureEnabled: detailsSelectors.hasSignatureEnabled(state),
    credentialGroups: detailsSelectors.getCredentialGroups(state),
    idTransaction: detailsSelectors.getIdTransaction(state),
    idActivity: detailsSelectors.getIdActivity(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(detailsActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    schemeName: "medium",
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignatureConfirm);
