import React, { Component } from "react";
import { arrayOf, number, shape, string, func } from "prop-types";

import "./_chat-messages.scss";

import I18n from "pages/_components/I18n";
import ChatAttachment from "./ChatAttachment";

function MessageSender(props) {
    const { direction, date, idCommunicationTray, subject } = props;

    if (direction === "BANK_TO_CUSTOMER") {
        return subject ? (
            <div
                style={{
                    padding: "0 0 1rem",
                }}>
                <strong>{subject}</strong> - {date}
            </div>
        ) : (
            <div>
                <I18n component="strong" id={`communications.tray.${idCommunicationTray}`} /> - {date}
            </div>
        );
    }
    return (
        <div>
            <strong>
                <I18n component="strong" id="communication.thread.message.me" />
            </strong>{" "}
            - {date}
        </div>
    );
}

MessageSender.propTypes = {
    direction: string.isRequired,
    date: string.isRequired,
    idCommunicationTray: string.isRequired,
    subject: string,
};

class ChatLayout extends Component {
    static propTypes = {
        attachments: arrayOf({
            idCommunication: number,
            files: arrayOf(
                shape({
                    idFile: number,
                    fileName: string,
                }),
            ),
        }),
        detail: arrayOf(
            shape({
                body: string,
                communicationTray: shape({
                    name: string,
                }),
                idCommunication: number,
                subject: string,
                sentDateAsString: string,
            }),
        ),
        scrollToBottom: func.isRequired,
        handleDownloadAttachment: func.isRequired,
        handleOnClickOnLink: func.isRequired,
    };

    static defaultProps = {
        attachments: null,
        detail: null,
    };

    componentDidMount() {
        const { scrollToBottom } = this.props;
        scrollToBottom();
    }

    drawChatBubbles = () => {
        const { detail, attachments, handleDownloadAttachment, handleOnClickOnLink } = this.props;

        return (
            <ul>
                {detail.map((obj, i = 0) => (
                    <li className={`${obj.direction}`} key={i}>
                        <div key={i}>
                            <MessageSender
                                direction={obj.direction}
                                date={obj.sentDateAsString}
                                idCommunicationTray={obj.communicationTray.idCommunicationTray}
                                subject={detail[0].bodyMap.TITLE ? obj.subject : null}
                            />
                            <div onClick={handleOnClickOnLink} dangerouslySetInnerHTML={{ __html: obj.body }} />
                            {attachments &&
                                attachments[i] &&
                                attachments[i].idCommunication === obj.idCommunication &&
                                attachments[i].fileList && (
                                    <ChatAttachment
                                        attachment={attachments[i]}
                                        handleDownloadAttachment={handleDownloadAttachment}
                                    />
                                )}
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    render() {
        return this.drawChatBubbles();
    }
}
export default ChatLayout;
