import React from "react";
import { string, node, bool } from "prop-types";
import cx from "classnames";
import Image from "../Image";

function Card({
    isDesktop,
    icon,
    iconClass,
    iconWithText,
    title,
    content,
    bottom,
    rightContent,
    rightArrow,
    rightContextMenu,
    className,
    checks,
    hrClass,
    rightMenu,
}) {
    return (
        <div className={cx("card__component-item", className)}>
            <div className={isDesktop ? "d-flex" : "align-items-start"}>
                {checks}
                {icon ? <Image className={iconClass} src={icon} /> : undefined}
                {iconWithText}
                <div className="w-100">
                    <div className="card__component-item-title">{title}</div>
                    <div className="card__component-item-text">{content}</div>
                    {bottom && <div className="card__component-item-bottom">{bottom}</div>}
                </div>
                {rightMenu}
            </div>
            {!isDesktop && <hr className={hrClass} />}
            {rightContent && (
                <div className={cx("d-flex align-items-center justify-content-end", { "w-100": !isDesktop })}>
                    {rightContent}
                    {rightArrow && <Image className="card-arrow-icon" src="images/arrow-right.svg" />}
                    {rightContextMenu}
                </div>
            )}
        </div>
    );
}

Card.propTypes = {
    icon: string,
    iconClass: string,
    iconWithText: node,
    title: node,
    content: node,
    bottom: node,
    rightContent: node,
    rightArrow: bool,
    className: string,
    isDesktop: bool.isRequired,
    checks: node,
    hrClass: string,
    rightMenu: node,
    rightContextMenu: node,
};

Card.defaultProps = {
    icon: "",
    iconClass: "",
    iconWithText: null,
    title: null,
    content: null,
    bottom: null,
    rightContent: null,
    rightArrow: false,
    className: "",
    checks: null,
    hrClass: "",
    rightMenu: null,
    rightContextMenu: null,
};

export default Card;
