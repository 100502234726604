import React, { Component } from "react";
import { Modal, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import Yup from "yup";
import { func, bool, shape } from "prop-types";
import { Form, Field, withFormik } from "formik";

import * as i18n from "util/i18n";
import { selectors as templateSelectors, actions as templateActions } from "reducers/template";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import TextField from "pages/_components/fields/TextField";
import { resizableRoute } from "pages/_components/Resizable";
import Container from "pages/_components/Container";
import ModalNotification from "pages/forms/_components/ModalNotification";
import classNames from "classnames";

const FORM_ID = "forms.template";

class CreatTemplateModal extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        isVisible: bool.isRequired,
        errors: shape({}).isRequired,
    };

    handleHide = () => {
        const { dispatch } = this.props;
        dispatch(templateActions.closeCreateModal());
    };

    render() {
        const { isSubmitting, isDesktop, isVisible, errors } = this.props;

        return (
            <Modal show={isVisible} className="transfer-template-modal">
                <Form className={isDesktop && "modal-container"}>
                    <Modal.Header className={!isDesktop ? "without-margin-top" : ""}>
                        <Modal.Title className="text-align-left">
                            <I18n
                                id={`${FORM_ID}.title`}
                                component="h3"
                                componentProps={{ className: "create-template-modal-title text-align-left" }}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className="container--layout align-items-center flex-grow create-template-modal-body">
                            <ModalNotification formId={FORM_ID} errors={errors} />
                            <Col className={classNames("col col-12", { "px-2": isDesktop, "px-4": !isDesktop })}>
                                <Field name="templateName">
                                    {({ field, form }) => (
                                        <TextField
                                            maxLength={20}
                                            pattern="^[a-zA-Z0-9-\u00f1\u00d1 ]*$"
                                            autoFocus={isDesktop}
                                            idForm={FORM_ID}
                                            field={field}
                                            form={{ ...form, touched: { templateName: true } }}
                                        />
                                    )}
                                </Field>
                            </Col>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="create-template-modal-footer">
                            <Button
                                label="global.cancel"
                                bsStyle="link"
                                loading={isSubmitting}
                                className="inline-link-button"
                                onClick={this.handleHide}
                            />
                            <Button
                                type="submit"
                                label="global.accept"
                                bsStyle="link"
                                className="inline-link-button account-button-margin-top"
                                loading={isSubmitting}
                            />
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    isVisible: templateSelectors.isCreateModalVisible(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            templateName: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                templateName: Yup.string().required(i18n.get(`${FORM_ID}.templateName.required`)),
            }),
        handleSubmit: ({ templateName }, formikBag) => {
            const { idForm, values, idActivityTemplate } = formikBag.props;

            formikBag.props.dispatch(
                templateActions.saveTemplate(idForm, values, templateName, formikBag, idActivityTemplate),
            );

            formikBag.resetForm();
        },
    }),
    resizableRoute,
)(CreatTemplateModal);
