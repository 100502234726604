import { call, takeEvery, takeLatest, put, select } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import * as settings from "middleware/settings";
import { types as settingsTypes } from "reducers/settings";
import { types as sessionTypes, selectors as sessionSelectors } from "reducers/session";
import { actions as i18nActions, types as i18nTypes } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";

import { adjustIdFieldErrors } from "util/form.js";
import * as utilDownload from "util/download";
import { globalTypes } from "reducers/types/global";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(settingsTypes.CHANGE_LANGUAGE_PRE, changeLanguage),

    takeLatest(settingsTypes.CHANGE_DEFAULT_ENVIRONMENT_PRE, changeDefaultEnvironment),

    takeLatest(settingsTypes.CHANGE_PASSWORD_REQUEST, changePassword),

    takeLatest(settingsTypes.CHANGE_USERPASS_REQUEST, changeUserpass),

    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_PRE, changeSecuritySealPre),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE, changeSecuritySealConfirmationPre),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK, changeSecuritySealConfirmationBack),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION, changeSecuritySealConfirmation),

    takeLatest(settingsTypes.NOTIFICATIONS_CONFIGURATION_PRE, notificationsConfigurationPre),
    takeLatest(settingsTypes.COMMUNICATION_TYPE_PRE, communicationTypePre),
    takeEvery(settingsTypes.MODIFY_NOTIFICATION_CONFIGURATIONS, modifyNotificationConfigurations),
    takeEvery(settingsTypes.MODIFY_NOTIFICATION_CONFIGURATION_OPTIONS, modifyNotificationConfigurationOptions),
    takeEvery(settingsTypes.MODIFY_PAPERLESS_CONFIGURATIONS, modifyPaperlessConfigurations),

    takeLatest(settingsTypes.GET_USER_DATA, getUserData),
    takeLatest(settingsTypes.GET_ACCOUNTS_OFFICER_DATA, getAccountsOfficerData),
    takeLatest(settingsTypes.MAIL_SEND_CODE, sendMailCode),
    takeLatest(settingsTypes.MAIL_UPDATE, updateMail),

    takeLatest(settingsTypes.MOBILEPHONE_SEND_CODE, sendMobilePhoneCode),
    takeLatest(settingsTypes.MOBILEPHONE_UPDATE, updateMobilePhone),
    takeLatest(settingsTypes.LOAD_DOCUMENTS_REQUEST, loadDocumentsRequest),
    takeLatest(settingsTypes.DOWNLOAD_DOCUMENT_REQUEST, downloadDocumentRequest),

    takeLatest(settingsTypes.FETCH_PRODUCTS_LIST, fetchProductsList),
    takeLatest(settingsTypes.SET_ALL_PAPERLESS_SUCCESS, fetchProductsList),
    takeLatest(settingsTypes.SET_ALL_PAPERLESS_REQ, setAllPaperlessValue),

    takeLatest(settingsTypes.GET_ADDRESSES_INFORMATION_REQ, getAddressesInformationRequest),
    takeLatest(settingsTypes.SET_ADDRESSES_INFORMATION_REQ, setAddressesInformationRequest),

    takeLatest(settingsTypes.GET_TERMS_AND_CONDITIONS_REQUEST, getTermsAndConditions),
    takeLatest(settingsTypes.LIST_ALL_ENVIRONMENT, listAllEnvironment),
];

export default sagas;

function* changeLanguage({ language, changeUserLanguage }) {
    if (changeUserLanguage) {
        const result = yield call(settings.changeLanguage, language);

        if (result && result.status === 200) {
            configUtils.setRecaptchaLang(language);
            yield put(i18nActions.setLang(language));
            yield put({ type: settingsTypes.CHANGE_LANGUAGE_PRE_SUCCESS });
        } else {
            const confirmationMessage = i18n.get("settings.changeLanguage.errorMessage");
            yield put(notificationActions.showNotification(confirmationMessage, "error", ["changeLanguage"]));
            yield put({ type: settingsTypes.CHANGE_LANGUAGE_PRE_ERROR });
        }
    } else {
        yield put(i18nActions.setLang(language));
        yield takeLatest(i18nTypes.UPDATE_SUCCESS, notifyLanguageChangedSuccess);
    }
}

function* changeDefaultEnvironment({ idEnvironment }) {
    const response = yield call(settings.changeDefaultEnvironment, idEnvironment);

    const { code, data } = response.data;
    if (code === "COR000I") {
        const defaultEnvironmentRemoved = data.idEnvironment === 0;
        yield put({
            type: settingsTypes.CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS,
            idDefaultEnvironment: !defaultEnvironmentRemoved ? data.idEnvironment : null,
        });

        let confirmationMessage = "";
        if (defaultEnvironmentRemoved) {
            confirmationMessage = i18n.get("settings.defaultEnvironment.confirmationRemovedMessage");
        } else {
            confirmationMessage = i18n.get("settings.defaultEnvironment.confirmationMessage");
        }
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["settings"]));
        yield put(push("/settings"));
    } else {
        const errorMessage = i18n.get("settings.defaultEnvironment.errorMessage");
        yield put(notificationActions.showNotification(errorMessage, "error", ["changeDefaultEnvironment"]));
    }
}

function* notifyLanguageChangedSuccess() {
    const confirmationMessage = i18n.get("settings.changeLanguage.confirmationMessage");
    yield put(notificationActions.showNotification(confirmationMessage, "success", ["changeLanguage"]));
}

function* notificationsConfigurationPre() {
    const response = yield call(settings.notificationsConfigurationPre);

    if (response && response.status === 200) {
        yield put({
            type: settingsTypes.NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS,
            communicationTypes: response.data.data.communicationConfigurations,
        });
    }
}

function* communicationTypePre({ communicationType }) {
    const response = yield call(settings.communicationTypePre, communicationType);

    if (response && response.status === 200) {
        const communicationTransportsConfigurations = response.data.data.communicationConfigurations;

        yield put({
            type: settingsTypes.COMMUNICATION_TYPE_PRE_SUCCESS,
            communicationTransportsConfigurations,
        });
    } else {
        yield put(push("/desktop"));
    }
}

function* modifyNotificationConfigurations({ notificationType, transport, subscribed }) {
    const response = yield call(settings.modifyNotificationConfigurations, notificationType, transport, subscribed);
    if (response && response.status === 200) {
        if (!response.data.data.transportModified) {
            yield put(
                notificationActions.showNotification(
                    i18n.get("settings.notificationsConfiguration.errorMessage"),
                    "error",
                    ["settings.notificationsConfiguration.modify"],
                ),
            );
        }
    }
}

function* modifyNotificationConfigurationOptions({ communicationType, enabled, option }) {
    const response = yield call(settings.modifyNotificationConfigurationOptions, communicationType, enabled, option);
    if (response && response.status === 200) {
        if (!response.data.data.optionModified) {
            yield put(
                notificationActions.showNotification(
                    i18n.get("settings.notificationsConfiguration.errorMessage"),
                    "error",
                    ["settings.notificationsConfiguration.modify"],
                ),
            );
        }
    }
}

function* modifyPaperlessConfigurations({ idProduct, paperless }) {
    const response = yield call(settings.modifyPaperlessConfigurations, idProduct, paperless);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    }
}

function* fetchProductsList() {
    const response = yield call(settings.fetchProductsList);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    } else {
        yield put({ type: settingsTypes.UPDATE_PRODUCT_LIST, productsList: response.data.data });
        yield put({ type: settingsTypes.FETCH_PRODUCTS_LIST_SUCCESS });
    }
}

function* setAllPaperlessValue({ paperless }) {
    const response = yield call(settings.setAllPaperlessValue, paperless);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
        yield put({ type: settingsTypes.SET_ALL_PAPERLESS_ERROR });
    } else {
        yield put({ type: settingsTypes.SET_ALL_PAPERLESS_SUCCESS });
    }
}

function* getUserData() {
    const response = yield call(settings.getUserData);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["settings"]));
        yield put(replace("/settings"));
    } else {
        yield put({ type: settingsTypes.GET_USER_DATA_SUCCESS, data: response.data.data });
    }
}

function* getAccountsOfficerData() {
    const response = yield call(settings.getAccountsOfficerData);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["settings"]));
        yield put(replace("/settings"));
    } else {
        yield put({ type: settingsTypes.GET_ACCOUNTS_OFFICER_DATA_SUCCESS, data: response.data.data });
    }
}

function* sendMailCode({ mail, otp, formikBag }) {
    const response = yield call(settings.sendMailCode, mail, otp);

    if (response.type === "W") {
        if (response.data.data.otp === "returnCode.COR091W") {
            yield put({ type: globalTypes.CLEAN_UP });
            yield put(
                push({
                    pathname: "/session/expiredByMaxAttempts",
                }),
            );
        }
        if (response.data.code === "COR020W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["changeEmail"]));
        }
    } else {
        yield put(replace("/settings/changeEmail/confirmation"));
    }

    formikBag.setSubmitting(false);
}

function* updateMail({ mailCode, formikBag }) {
    const { userEmail } = formikBag.props;
    const response = yield call(settings.updateMail, {
        mailCode,
        newEmail: userEmail,
    });

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        const { NO_FIELD } = response.data.data;
        yield put(
            notificationActions.showNotification(
                NO_FIELD || i18n.get("settings.changeEmail.modify.error.message"),
                "error",
                ["changeEmail"],
            ),
        );
    } else {
        const { mail } = response.data.data;
        const rememberedUser = yield select(loginSelectors.getRememberedUser);

        if (rememberedUser !== null) {
            yield put(loginActions.setRememberedUser({ ...rememberedUser, username: mail }));
        }

        yield put({
            type: settingsTypes.MAIL_UPDATE_SUCCESS,
            mail,
        });
        yield put(
            notificationActions.showNotification(i18n.get("settings.changeEmail.modify.success"), "success", [
                "settings",
            ]),
        );
        yield put(replace("/settings"));

        yield select(sessionSelectors.getUser);
        yield put({
            type: sessionTypes.UPDATE_USER,
            mail,
        });
    }
    formikBag.setSubmitting(false);
}

function* sendMobilePhoneCode({ mobilePhone, otp, formikBag }) {
    const phone = mobilePhone.replace("-", "").replace(/ /g, "");
    const response = yield call(settings.sendMobilePhoneCode, phone, otp);

    if (response.type === "W") {
        if (response.data.data.otp === "returnCode.COR091W") {
            yield put({ type: globalTypes.CLEAN_UP });
            yield put(
                push({
                    pathname: "/session/expiredByMaxAttempts",
                }),
            );
        }
        if (response.data.code === "COR020W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["changePhone"]));
        }
    } else {
        yield put(replace("/settings/changePhone/confirmation"));
    }
    formikBag.setSubmitting(false);
}

function* updateMobilePhone({ mobilePhoneCode, formikBag }) {
    const { mobilePhone } = formikBag.props;
    const response = yield call(settings.updateMobilePhone, {
        mobilePhoneCode,
        newPhoneNumber: mobilePhone,
    });

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        const { NO_FIELD } = response.data.data;
        yield put(
            notificationActions.showNotification(
                NO_FIELD || i18n.get("settings.changePhone.modify.error.message"),
                "error",
                ["changePhone"],
            ),
        );
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("settings.changePhone.modify.success"), "success", [
                "settings",
            ]),
        );
        yield put(replace("/settings"));
    }
    formikBag.setSubmitting(false);
}

function* setAddressesInformationRequest({ address, mailingAddress, otp, formikBag }) {
    const response = yield call(settings.setAddressesInformation, address, mailingAddress, otp);

    const message = response.data.data.message || response.data.message;

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["changeAddresses"]));
        }
    } else {
        yield put(notificationActions.showNotification(message, "success", ["settings"]));
        yield put(replace("/settings"));
    }
    formikBag.setSubmitting(false);
}

function* changePassword({ password, newPassword, newPasswordConfirmation, formikBag }) {
    const response = yield call(settings.changePassword, password, newPassword, newPasswordConfirmation);
    if (response.type === "W") {
        if (response.data.data.NO_FIELD) {
            yield put({ type: globalTypes.CLEAN_UP });
            yield put(
                notificationActions.showNotification(
                    i18n.get("login.step2.password.blocked"),
                    "error",
                    ["login", "loginStep2"],
                    i18n.get("login.step2.password.blocked.tittle"),
                ),
            );
        } else {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("settings.changePassword.modify.success"), "success", [
                "settings",
            ]),
        );
        yield put(push("/settings"));
    }
    formikBag.setSubmitting(false);
}

function* changeUserpass({ userpass, newUserpass, newUserpassConfirmation, formikBag }) {
    const response = yield call(settings.changeUserpass, userpass, newUserpass, newUserpassConfirmation);
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (!Object.keys(response.data.data).length > 0) {
            yield put(notificationActions.showNotification(response.data.message, "error", ["changeUserpass"]));
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("settings.changeUserpass.modify.success"), "success", [
                "settings",
            ]),
        );
        yield put(push("/settings"));
    }
    formikBag.setSubmitting(false);
}

function* changeSecuritySealPre() {
    const response = yield call(settings.listSecuritySeals);

    if (response && response.status === 200) {
        yield put({
            type: settingsTypes.CHANGE_SECURITY_SEAL_PRE_SUCCESS,
            // eslint-disable-next-line no-underscore-dangle
            securitySeals: response.data.data._securitySeals,
        });
    }
}

function* changeSecuritySealConfirmationPre() {
    yield put(push("/settings/changeSecuritySeal/confirmation"));
}

function* changeSecuritySealConfirmationBack() {
    yield put(push("/settings/changeSecuritySeal/"));
}

function* changeSecuritySealConfirmation({ securitySeal, otp, formikBag }) {
    const response = yield call(settings.modifySecuritySeal, securitySeal, otp);

    if (response && response.status === 200) {
        if (response.type === "W") {
            if (response.data.code === "COR020W") {
                formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            } else {
                yield put(
                    notificationActions.showNotification(response.data.message, "error", [
                        "settings.changeSecuritySeal",
                    ]),
                );
            }
            yield put({ type: settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR, errors: response.data.data });
        } else {
            const confirmationMessage = i18n.get("settings.changeSecuritySeal.confirmationMessage");

            yield put({ type: settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS, securitySeal });
            yield put(notificationActions.showNotification(confirmationMessage, "success", ["settings"]));
            yield put(push("/settings"));
        }
    }
}

function* loadDocumentsRequest() {
    const { type, data } = yield call(settings.listAllGeneralConditionDocuments);

    if (type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    } else {
        yield put({ type: settingsTypes.LOAD_DOCUMENTS_SUCCESS, documents: data.data.documents });
    }
}

function* downloadDocumentRequest({ fileNameKey }) {
    const { type, data } = yield call(settings.downloadDocument, fileNameKey);

    if (type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    } else {
        const { content } = data.data;
        utilDownload.downloadPdf(i18n.get(fileNameKey), content);
    }
}

function* getAddressesInformationRequest() {
    const response = yield call(settings.getAddressesInformation);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["session"]));
        yield put({ type: settingsTypes.GET_ADDRESSES_INFORMATION_FAILURE });
    } else {
        yield put({
            type: settingsTypes.GET_ADDRESSES_INFORMATION_SUCCESS,
            address: response.data.data.address,
            mailingAddress: response.data.data.mailingAddress,
            countries: response.data.data.countries,
        });
    }
}

function* getTermsAndConditions() {
    const { type, data } = yield call(settings.getTermsAndConditions);

    if (type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
        yield put({ type: settingsTypes.GET_TERMS_AND_CONDITIONS_FAILUTE });
    } else {
        yield put({
            type: settingsTypes.GET_TERMS_AND_CONDITIONS_SUCCESS,
            termsAndConditions: data.data.content,
        });
    }
}

function* listAllEnvironment() {
    const { type, data } = yield call(settings.getAllEnvironments);
    if (type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "changeDefaultEnvironment",
            ]),
        );
    } else {
        yield put({
            type: settingsTypes.LIST_ALL_ENVIRONMENT_SUCCESS,
            allEnvironments: data.data.environments,
        });
    }
}
