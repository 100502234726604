import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool, arrayOf, shape, string } from "prop-types";
import { push } from "react-router-redux";

import { actions as servicePaymentsActions, selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";
import { actions as accountsActions, selectors as accountsSelector } from "reducers/accounts";
import { selectors as statusSelectors } from "reducers/status";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import { FORM_ID } from "constants/servicePayments";

import Col from "react-bootstrap/lib/Col";
import Table from "pages/_components/Table";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import ContextMenu from "pages/_components/ContextMenu";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import DrawerModal from "pages/_components/drawer/DrawerModal";

import { agendaPagoShape } from "util/servicePayments";

import AFIPServiceConditions from "pages/enabledContributors/_components/AFIPServiceConditions";
import PaymentAFIPList from "pages/servicePayments/_components/PaymentAFIPList";
import classNames from "classnames";
import { saveLocation } from "util/transaction";
import AgendaPagoItem from "./_components/AgendaPagoItem";
import MultiplePaymentsList from "./multiple/MultiplePaymentsList";
import MultipleAFIPList from "./multiple/MultipleAFIPList";

class ServicePaymentsMain extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isFetching: bool,
        servicePaymentsSelected: bool.isRequired,
        agendaPagos: arrayOf(agendaPagoShape).isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        fetchingPayOrDelete: bool.isRequired,
        activeEnvironment: shape({
            permissions: shape({
                newPayment: bool,
            }),
        }).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        isRetailEnvironment: bool,
        lastPathname: string.isRequired,
    };

    static defaultProps = {
        isFetching: false,
        isRetailEnvironment: false,
    };

    state = {
        showDrawer: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        saveLocation(this.props);

        dispatch(accountsActions.listAccounts());

        dispatch(servicePaymentsActions.listAgendaPagos());
        dispatch(paymentsAFIPActions.getToken());
        dispatch(paymentsAFIPActions.cleanBackFromOtherPage());
    }

    componentWillUnmount() {
        const { dispatch, servicePaymentsSelected } = this.props;
        dispatch(servicePaymentsActions.setService(servicePaymentsSelected));
    }

    handleBack = () => {
        const { dispatch, lastPathname } = this.props;
        dispatch(push(lastPathname));
    };

    handleNewPayment = () => {
        const { dispatch, accounts } = this.props;
        if (accounts && accounts.length > 0) {
            dispatch(servicePaymentsActions.cleanSummary());
            dispatch(push(`/newPayment`));
        } else {
            dispatch(
                notificationActions.showNotification(i18nUtils.get("newPayment.noAccounts.error"), "error", [
                    "servicePayments",
                ]),
            );
        }
    };

    allowMultiplePayments = () => {
        const { isRetailEnvironment } = this.props;

        const showMultiplePaymentsForCorporate = configUtils.getBoolean(
            "frontend.show.MultiplePaymentTAX.functionalities.Corporate",
            false,
        );
        const showMultiplePaymentsForRetail = configUtils.getBoolean(
            "frontend.show.MultiplePaymentTAX.functionalities.Retail",
            false,
        );

        return isRetailEnvironment ? showMultiplePaymentsForRetail : showMultiplePaymentsForCorporate;
    };

    closeDrawer = () => {
        this.setState({ showDrawer: false });
    };

    rightContent = () => {
        const { isDesktop, activeEnvironment, dispatch } = this.props;

        if (isDesktop) {
            return (
                <>
                    {activeEnvironment.permissions.newPayment ? (
                        <Button label={`${FORM_ID}.newPayment`} bsStyle="primary" onClick={this.handleNewPayment} />
                    ) : (
                        undefined
                    )}
                    <Button
                        block={false}
                        label={`${FORM_ID}.attachedServices`}
                        onClick={() => {
                            dispatch(push("/adhesiones/list"));
                        }}
                        bsStyle="outline"
                    />
                    <ContextMenu
                        styleContext={{ justifyContent: "flex-end" }}
                        dropBtnClass="pl-md-3 pr-md-0"
                        items={[
                            {
                                label: `${FORM_ID}.afipServices`,
                                onClick: () => {
                                    this.setState({ showDrawer: true });
                                },
                            },
                            {
                                label: "menu.payments.enabledContributors",
                                onClick: () => {
                                    dispatch(push(`/payments/enabledContributors`));
                                },
                            },
                        ]}
                    />
                </>
            );
        }

        const items = [];

        if (activeEnvironment.permissions.newPayment) {
            items.push({
                label: `${FORM_ID}.newPayment`,
                onClick: () => this.handleNewPayment(),
            });
        }

        items.push(
            {
                label: `${FORM_ID}.attachedServices`,
                onClick: () => {
                    dispatch(push("/adhesiones/list"));
                },
            },
            {
                label: `${FORM_ID}.afipServices`,
                onClick: () => {
                    this.setState({ showDrawer: true });
                },
            },
            {
                label: "menu.payments.enabledContributors",
                onClick: () => {
                    dispatch(push(`/payments/enabledContributors`));
                },
            },
        );

        return (
            <div>
                <ContextMenu styleContext={{ justifyContent: "flex-end" }} items={items} />
            </div>
        );
    };

    changeOption = (option) => {
        const { dispatch } = this.props;

        if (option === "pay.services") {
            dispatch(servicePaymentsActions.setServiceSelected());
            dispatch(servicePaymentsActions.cleanUp());
            dispatch(servicePaymentsActions.listAgendaPagos());
            dispatch(paymentsAFIPActions.getToken());
        } else {
            dispatch(servicePaymentsActions.setPaymentSelected());
        }
    };

    rowButtons = () => {
        const { isDesktop, servicePaymentsSelected } = this.props;
        return (
            <Container
                className={classNames("align-items-center container-white py-1", {
                    "mt-2": isDesktop,
                })}>
                <Col xs={12} className="d-flex py-2 px-1 justify-content-center">
                    <div className="tablet-selector-button my-1 mx-1">
                        <Button
                            className={classNames("btn-outline btn-regular chip-selector-btn", {
                                selected: servicePaymentsSelected,
                                "py-2 px-0 m-0 pl-2": !isDesktop,
                            })}
                            block={false}
                            image={servicePaymentsSelected ? "images/check.svg" : ""}
                            onClick={() => this.changeOption("pay.services")}
                            label={`${FORM_ID}.services.label`}
                        />
                    </div>
                    <div className="tablet-selector-button my-1 ml-1">
                        <Button
                            className={classNames("btn-outline btn-regular chip-selector-btn", {
                                selected: !servicePaymentsSelected,
                                "py-2 px-0 m-0 pl-2": !isDesktop,
                            })}
                            block={false}
                            image={servicePaymentsSelected ? "" : "images/check.svg"}
                            onClick={() => this.changeOption("pay.afip")}
                            label={`${FORM_ID}.AFIP.label`}
                        />
                    </div>
                </Col>
            </Container>
        );
    };

    allowMultipleAFIP = () => {
        const { isRetailEnvironment } = this.props;
        const showMultipleAFIPForCorporate = configUtils.getBoolean(
            "frontend.show.MultiplePaymentVEP.functionalities.Corporate",
            false,
        );
        const showMultipleAFIPForRetail = configUtils.getBoolean(
            "frontend.show.MultiplePaymentVEP.functionalities.Retail",
            false,
        );

        return isRetailEnvironment ? showMultipleAFIPForRetail : showMultipleAFIPForCorporate;
    };

    renderList = () => {
        const { isDesktop, isSmallDesktop, servicePaymentsSelected } = this.props;
        const currencies = [{ id: 0, label: i18nUtils.get(`currency.label.0`) }];
        const { agendaPagos } = this.props;
        const renderTable =
            agendaPagos &&
            agendaPagos.length &&
            agendaPagos.map((agendaPago) => (
                <AgendaPagoItem
                    key={`transaction-${agendaPago.ususerLP}`}
                    agendaPago={agendaPago}
                    isDesktop={isDesktop}
                    isSmallDesktop={isSmallDesktop}
                    tableLabel={FORM_ID}
                    currencies={currencies}
                />
            ));

        if (servicePaymentsSelected) {
            return (
                <>
                    <Container
                        className={`flex-grow scrollable ${!isDesktop &&
                            !agendaPagos &&
                            !agendaPagos.length &&
                            "no-results-container"}`}
                        rowClassName={`${!isDesktop && "container-mobile-full-height"} ${agendaPagos !== undefined &&
                            !agendaPagos.length &&
                            "container-empty-results"}`}>
                        <Col className="col col-12 px-0">
                            <div
                                className={`w-100 ${!isDesktop ? "d-flex bd-spacing-0-2" : "bd-spacing-0-8"}`}
                                ref={(tableRef) => {
                                    this.child = tableRef;
                                }}>
                                {agendaPagos !== undefined && agendaPagos.length ? (
                                    <Table>{renderTable}</Table>
                                ) : (
                                    <div
                                        className={`text-center no-more-data ${!isDesktop} mx-4`}
                                        key="noMoreMovements">
                                        <div className="illustration-wrapper">
                                            <Image
                                                src="images/coloredIcons/no-debts.svg"
                                                className="svg-big-icon"
                                                styles={{ width: isDesktop ? "96px" : "72px", height: "auto" }}
                                            />
                                        </div>
                                        <p
                                            className="text-lead"
                                            style={{
                                                opacity: 1,
                                                marginTop: isDesktop && "2rem",
                                                fontFamily: !isDesktop && "Graphik-Light",
                                                color: !isDesktop && "#707070",
                                            }}>
                                            <I18n id={`${FORM_ID}.empty.message`} />
                                        </p>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Container>
                </>
            );
        }

        if (this.allowMultipleAFIP()) {
            return (
                <>
                    <MultipleAFIPList isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
                </>
            );
        }

        return (
            <>
                <PaymentAFIPList isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
            </>
        );
    };

    renderContent() {
        const { showDrawer } = this.state;
        const { isDesktop, isSmallDesktop, isFetching, fetchingPayOrDelete } = this.props;
        const loading = !!(isFetching || fetchingPayOrDelete);

        return (
            <>
                <Notification scopeToShow="servicePayments" />
                <div className="admin-detail-head px-0 mb-0">
                    {isDesktop ? <Head onBack={this.handleBack} /> : undefined}
                    {showDrawer && (
                        <DrawerModal
                            content={<AFIPServiceConditions closeDrawer={this.closeDrawer} isDesktop={isDesktop} />}
                            width={isDesktop ? "600px" : "100%"}
                            showDrawerRemote={showDrawer}
                            closeRemotely={() => this.closeDrawer()}
                            backButton={!isDesktop}
                            title={!isDesktop ? "payments.serviceAFIP.title" : undefined}
                            headerClassName={!isDesktop ? "blue-main-header-mobile" : undefined}
                            contentClassName="afip-conditions-modal"
                        />
                    )}
                    <Head
                        title="menu.payments.payServicesAfip"
                        rightContent={this.rightContent}
                        isFetchingExport={false}
                        onBack={!isDesktop ? this.handleBack : undefined}
                        headerClassName={
                            !isDesktop ? "blue-main-header-mobile servive-payment-title-mobile" : undefined
                        }
                    />
                </div>
                {this.rowButtons()}
                <PageLoading className="line-loader" loading={loading}>
                    {this.allowMultiplePayments() ? (
                        <MultiplePaymentsList isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
                    ) : (
                        this.renderList()
                    )}
                </PageLoading>
            </>
        );
    }

    render() {
        return this.renderContent();
    }
}

const mapStateToProps = (state) => ({
    isFetching: servicePaymentsSelectors.getFetching(state),
    fetchingPayOrDelete: paymentsAFIPSelectors.getFetchingPayOrDelete(state),
    servicePaymentsSelected: servicePaymentsSelectors.getServiceSelected(state),
    agendaPagos: servicePaymentsSelectors.getAgendaPagos(state),
    isBackFromOtherPage: paymentsAFIPSelectors.getIsBackFromOtherPage(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    accounts: accountsSelector.getAccounts(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
    lastPathname: statusSelectors.getPathname(state),
});

export default connect(mapStateToProps)(ServicePaymentsMain);
