import React, { Component, Fragment } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { func, string, bool, shape } from "prop-types";
import Yup from "yup";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import { selectors as loginSelectors } from "reducers/login";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import withExchangeToken from "pages/_components/withExchangeToken";
import SecuritySealCarrouselField from "pages/_components/fields/SecuritySealCarrouselField";
import I18n from "pages/_components/I18n";
import OnboardingStepper, { orientations } from "pages/onboarding/_components/OnboardingStepper";

import { Mixpanel } from "util/clickstreaming";
import Image from "pages/_components/Image";
import EnrollmentStepper from "./_components/EnrollmentStepper";
import AssociateStepperSimple from "./_components/AssociateStepperSimple";

const FORM_ID = "enrollment.step3";

class Step3Part3 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        invitationCode: string.isRequired,
        isSubmitting: bool.isRequired,
        userCredentials: shape({
            securitySeal: shape({
                image: string,
            }),
        }).isRequired,
        securitySeals: shape({}).isRequired,
        isMobile: bool.isRequired,
        currentSecuritySeal: shape({}).isRequired,
        documentData: shape({}).isRequired,
        fetching: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, invitationCode } = this.props;

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
    }

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    handleSecuritySealClick = (id) => {
        const { dispatch, userCredentials, securitySeals } = this.props;

        userCredentials.securitySeal = {
            id,
            image: securitySeals[id],
        };

        dispatch(enrollmentActions.saveUserCredentials(userCredentials));
    };

    renderTitleMobile = () => (
        <Image src="images/logoMobileHeader.svg" styles={{ position: "relative", top: "5px" }} className="" />
    );

    render() {
        const { isSubmitting, isMobile, currentSecuritySeal, securitySeals, documentData, fetching } = this.props;
        const step = 10;

        return (
            <Fragment>
                <Head
                    onClose={isMobile && this.onHeaderBack}
                    onBack={isMobile && this.onHeaderBack}
                    headerClassName={isMobile ? "blue-main-header-mobile" : ""}
                    centerElement={!isMobile ? undefined : this.renderTitleMobile}
                    closeImageWhite={isMobile && true}
                />
                <div className="view-page">
                    {!isMobile && documentData.firstName && (
                        <OnboardingStepper currentStep={step} className="onboarding-steps" />
                    )}
                    {!(isMobile || documentData.firstName) && (
                        <EnrollmentStepper currentStep={5} className="onboarding-steps" />
                    )}

                    <div className="view-content">
                        <main className="main-container">
                            <Form className="above-the-fold">
                                {isMobile && (
                                    <Container
                                        className="container--layout align-items-center"
                                        gridClassName="form-content">
                                        <Col className="col col-12">
                                            {documentData.firstName && (
                                                <OnboardingStepper
                                                    currentStep={step}
                                                    orientation={orientations.horizontal}
                                                />
                                            )}
                                            {!documentData.firstName && <AssociateStepperSimple currentStep={5} />}
                                        </Col>
                                    </Container>
                                )}
                                <Container
                                    className="container--layout align-items-center"
                                    gridClassName="form-content">
                                    <Col sm={12} md={5} lg={4} className="col col-12">
                                        <Col
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            className="col col-12 enrollment-create-image-text">
                                            <I18n
                                                component="h1"
                                                componentProps={{ className: "title-content" }}
                                                id="onboarding.step.11.title"
                                            />
                                            <I18n
                                                component="p"
                                                componentProps={{ className: "text-lead" }}
                                                id="enrollment.setcredentials.securitySeal.info"
                                            />
                                        </Col>
                                        <Col sm={12} md={12} lg={12} className="col col-12 enrolment-select-picture">
                                            <Field
                                                component={SecuritySealCarrouselField}
                                                idForm={FORM_ID}
                                                name="securitySeal"
                                                securitySeals={securitySeals}
                                                currentSecuritySeal={currentSecuritySeal}
                                                handleSecuritySealClick={this.handleSecuritySealClick}
                                                fetching={fetching}
                                                isDesktop={!isMobile}
                                                hideLabel
                                            />
                                        </Col>
                                        <Col sm={12} md={12} lg={12} className="col col-12">
                                            <Button
                                                label="global.continue"
                                                type="submit"
                                                bsStyle="primary"
                                                loading={isSubmitting}
                                            />
                                        </Col>
                                    </Col>
                                </Container>
                            </Form>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    client: enrollmentSelectors.getClient(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    fetching: enrollmentSelectors.getFetching(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    personalDataEnabled: enrollmentSelectors.getPersonalDataEnabled(state),
    userCredentials: enrollmentSelectors.getUserCredentials(state),
    verificationCode: enrollmentSelectors.getVerificationCode(state),
    securitySeals: enrollmentSelectors.getSecuritySeals(state),
    currentSecuritySeal: enrollmentSelectors.getUserCredentials(state).securitySeal,
    documentData: onboardingSelectors.getDocumentData(state),
    activeRegion: loginSelectors.getRegion(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { client, invitation, userCredentials } = props;
            return {
                email: client?.email || invitation?.email,
                ...userCredentials,
            };
        },
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    email: Yup.string()
                        .email()
                        .required(i18nUtils.get("enrollment.step3.email.error.empty")),
                    securitySeal: Yup.object().shape({
                        id: Yup.string().required(i18nUtils.get("enrollment.step3.securitySeal.error.empty")),
                        image: Yup.string(),
                    }),
                }),
            ),
        handleSubmit: ({ email, securitySeal }, formikBag) => {
            const { dispatch, activeRegion } = formikBag.props;
            const { password, passwordConfirmation, user, userConfirmation } = formikBag.props.userCredentials;
            dispatch(
                enrollmentActions.setUserCredentials(
                    password,
                    passwordConfirmation,
                    user,
                    userConfirmation,
                    securitySeal.id,
                    email,
                    formikBag,
                    activeRegion,
                ),
            );
            Mixpanel.track(`${FORM_ID}.securitySeal`);
        },
    }),
)(withExchangeToken(Step3Part3));
