/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as i18nUtils from "util/i18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import { string, number as numberType, bool } from "prop-types";
import InfoTag from "pages/_components/InfoTag";
import { resizableRoute } from "pages/_components/Resizable";
import moment from "moment";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import FormattedRate from "pages/_components/FormattedRate";

class DepositsListItem extends Component {
    static propTypes = {
        productType: string.isRequired,
        depositNumber: string.isRequired,
        nextPaymentAmount: numberType.isRequired,
        dueDate: string.isRequired,
        currency: string.isRequired,
        automaticRenewal: bool.isRequired,
        isDesktop: bool,
        idProduct: string.isRequired,
        productAlias: string.isRequired,
        consolidatedAmount: numberType.isRequired,
        rate: numberType.isRequired,
        isEditable: bool.isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    render() {
        const {
            productType,
            depositNumber,
            nextPaymentAmount,
            dueDate,
            currency,
            automaticRenewal,
            isDesktop,
            idProduct,
            consolidatedAmount,
            rate,
            isEditable
        } = this.props;

        const name = depositNumber && depositNumber;
        const expirationText = i18nUtils.get("deposits.expiration.label");
        const path = !isEditable ? `/deposits/${idProduct}` : "/desktop";
        const dueDateFormatted = moment(dueDate.toString().replace(".000Z", "")).format("DD/MM/YYYY");
        const consolidatedAmountLabel = i18nUtils.get("deposits.information.consolidatedAmount");
        const rateShortLabel = i18nUtils.get("deposits.information.rate.short");
        const certificate = i18nUtils.get("deposits.information.certificate.number");
        const nextPaymentAmountLabel = i18nUtils.get("deposits.information.nextPaymentAmount");
        const description = i18nUtils.get("deposits.information.description");

        if (isDesktop) {
            return (
                <>
                    <CardContainer renderAs={Link} to={path} className="widget__card-deposits">
                        <Card
                            title={
                                <>
                                    {dueDateFormatted && (
                                        <div className="deposit__card-title">
                                            <div className="deposit__date">
                                                <div className="deposit__date-info">
                                                    <span>{expirationText}</span>
                                                </div>
                                                <div className="deposit__date-data">
                                                    <span>{dueDateFormatted}</span>
                                                </div>
                                            </div>
                                            {automaticRenewal && (
                                                <div className="deposit__info-tag">
                                                    <InfoTag
                                                        type="default"
                                                        message="auto"
                                                        color="#072229"
                                                        tagBackground="#84EFD0"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            }
                            content={
                                <div className="deposit__card-content">
                                    <div className="deposit__invested">
                                        <div className="deposit__invested-info">
                                            <span>{consolidatedAmountLabel}</span>
                                        </div>
                                        <div className="deposit__invested-data">
                                            <FormattedAmount
                                                quantity={consolidatedAmount}
                                                currency={currency}
                                                notBold
                                            />
                                        </div>
                                    </div>
                                    <div className="deposit__tna">
                                        <div className="deposit__tna-info">
                                            <span>{rateShortLabel}</span>
                                        </div>
                                        <div className="deposit__tna-data">
                                            <FormattedRate rate={rate} symbolToTheLeft={false} />
                                        </div>
                                    </div>
                                </div>
                            }
                            bottom={
                                <div className="deposit__card-bottom">
                                    <div className="deposit__product">
                                        <span>{productType}</span>
                                    </div>
                                    <div className="deposit__certificate">
                                        <div className="deposit__certificate-info">
                                            <span>{certificate}</span>
                                        </div>
                                        <div className="deposit__certificate-data">
                                            <span>{name}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            rightContent={
                                <div className="deposit__card-right-content">
                                    <div className="deposit__amount">
                                        <span className="deposit__amount-info">{nextPaymentAmountLabel}</span>
                                        <FormattedAmount
                                            className="deposit__amount-data"
                                            quantity={nextPaymentAmount}
                                            currency={currency}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        }

        return (
            <>
                <CardContainer renderAs={Link} to={path} className="widget__card-deposits">
                    <Card
                        hrClass="d-none"
                        title={
                            <>
                                {dueDateFormatted && (
                                    <div className="deposit__card-title">
                                        <div className="d-flex">
                                            <div className="deposit__date">
                                                <div className="deposit__date-info">
                                                    <span>{expirationText}</span>
                                                </div>
                                                <div className="deposit__date-data">
                                                    <span>{dueDateFormatted}</span>
                                                </div>
                                            </div>
                                            {automaticRenewal && (
                                                <div className="deposit__info-tag">
                                                    <InfoTag
                                                        type="default"
                                                        message="auto"
                                                        color="#072229"
                                                        tagBackground="#84EFD0"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <hr className={automaticRenewal ? "w-100 ml-0" : "w-100 ml-0 mt-1"} />
                                    </div>
                                )}
                            </>
                        }
                        content={
                            <div className="deposit__card-content">
                                <div className="deposit__invested">
                                    <div className="deposit__invested-info">
                                        <span>{consolidatedAmountLabel}</span>
                                    </div>
                                    <div className="deposit__invested-data">
                                        <FormattedAmount notBold quantity={consolidatedAmount} currency={currency} />
                                    </div>
                                </div>
                                <div className="deposit__tna">
                                    <div className="deposit__tna-info">
                                        <span>{rateShortLabel}</span>
                                    </div>
                                    <div className="deposit__tna-data">
                                        <FormattedRate rate={rate} symbolToTheLeft={false} />
                                    </div>
                                </div>
                                <div className="deposit__product">
                                    <div className="deposit__product-info">
                                        <span>{description}</span>
                                    </div>
                                    <div className="deposit__product-data">
                                        <span>{productType}</span>
                                    </div>
                                </div>
                                <div className="deposit__certificate">
                                    <div className="deposit__certificate-info">
                                        <span>{certificate}</span>
                                    </div>
                                    <div className="deposit__certificate-data">
                                        <span>{name}</span>
                                    </div>
                                </div>
                                <div className="deposit__amount">
                                    <span className="deposit__amount-info">{nextPaymentAmountLabel}</span>
                                    <FormattedAmount
                                        className="deposit__amount-data"
                                        quantity={nextPaymentAmount}
                                        currency={currency}
                                    />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            </>
        );
    }
}

export default resizableRoute(DepositsListItem);
