import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, shape, string, arrayOf } from "prop-types";
import { goBack, push } from "react-router-redux";

import { selectors, actions } from "reducers/administration/common/administrationTicket";
import { selectors as accountsSelectors, actions as accountsActions } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18nUtils from "util/i18n";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";

import DepositTransactionData from "pages/deposits/_components/DepositTransactionData";
import TransactionTicket from "pages/_components/TransactionTicket";
import { parseFloatToAmountFormat } from "util/number";

class DepositConstituteTicket extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        transactionData: shape({}).isRequired,
        dispatch: func.isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        accounts: arrayOf(shape({})).isRequired,
        loggedUser: shape({}).isRequired,
    };

    state = {
        fetchingDownload: false,
    };

    componentDidMount() {
        const { match, dispatch, loggedUser } = this.props;
        const { idTransaction } = match.params;

        dispatch(accountsActions.listAccounts(loggedUser.userId));
        dispatch(actions.loadAdministrationTicketRequest(idTransaction));
    }

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: "-0.5rem 2rem" }}>{i18nUtils.get("deposits.summary.label")}</h1>
        </div>
    );

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });

        const ticketData = {
            ticketName: i18nUtils.get("deposits.summary.label"),
            ticketBody: [...this.generateTicketBody()],
            ticketFooterInformation: i18nUtils.get("deposits.constitute.confirmation.information"),
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    getAccountDescription = ({ number, productType, currency }) => {
        if (!number) {
            return "";
        }
        return `${number} ${productType} ${i18nUtils.get(`currency.label.${currency}`)}`;
    };

    getAmountLabel = (amount, label) => {
        const hasAmount = amount && amount.quantity;
        return getGeneralTicketItem(label, hasAmount ? amount : "-", hasAmount ? "amountFrequency" : "string");
    };

    generateTicketBody = () => {
        const {
            transactionData,
            transactionData: { data },
            accounts,
        } = this.props;

        const depositTypeLabel = data?.depositType?.split("-")[2];

        let debitAccountToShow;
        if (data) {
            const { debitAccount } = data;

            debitAccountToShow = accounts.find(
                ({ idProduct }) => idProduct === (debitAccount?.idProduct || debitAccount),
            );
        }

        const ticketBody = [
            getGeneralTicketItem(
                `deposits.constitute.confirmation.dateAndHour.label`,
                data?.dateAndHour?.toString().replace("Z", ""),
                "datefulltime",
            ),
            getGeneralTicketItem(
                `deposits.constitute.confirmation.idTransaction.label`,
                transactionData?.idTransaction.substring(0, 8).toUpperCase(),
                "string",
            ),
            getGeneralTicketItem(`deposits.constitute.confirmation.voucher.label`, data?.voucher, "string"),
            getGeneralTicketItem(`deposits.constitute.confirmation.type.label`, depositTypeLabel, "string"),
            getGeneralTicketItem(
                `deposits.constitute.confirmation.debitAccount.label`,
                this.getAccountDescription((data?.debitAccount && debitAccountToShow) || {}),
                "string",
            ),
            getGeneralTicketItem(
                `deposits.constitute.confirmation.consolidatedAmount.label`,
                data?.capital,
                "amountFrequency",
            ),
            this.getAmountLabel(data?.earnedInterests, `deposits.constitute.confirmation.earnedInterests.label`),
            this.getAmountLabel(data?.profitTax, `deposits.constitute.confirmation.profitTax.label`),
            getGeneralTicketItem(
                `deposits.constitute.confirmation.totalAmount.label`,
                data?.totalAmount,
                "amountFrequency",
            ),
            getGeneralTicketItem(
                `deposits.constitute.confirmation.openDate.label`,
                data?.openDate?.toString().replace("Z", ""),
                "datetime",
            ),
            getGeneralTicketItem(`deposits.constitute.confirmation.dueDate.label`, data?.dueDate, "date"),
            getGeneralTicketItem(`deposits.constitute.confirmation.termInDays.label`, data?.termInDays, "string"),
            getGeneralTicketItem(
                `deposits.constitute.confirmation.rate.label`,
                `${parseFloatToAmountFormat(data?.rate) || 0} %`,
                "string",
            ),
        ];

        if (data?.tea !== undefined) {
            ticketBody.push(
                getGeneralTicketItem(
                    `deposits.constitute.confirmation.effectiveRate.label`,
                    `${parseFloatToAmountFormat(data?.tea) || 0} %`,
                    "string",
                ),
            );
        }

        ticketBody.push(
            getGeneralTicketItem(
                `deposits.constitute.confirmation.automaticRenewal.label`,
                i18nUtils.get(`deposits.constitute.automaticRenewal.options.${data?.automaticRenewal}`),
                "string",
            ),
        );

        if (data?.automaticRenewal) {
            ticketBody.push(
                getGeneralTicketItem(
                    `deposits.constitute.confirmation.actionToDue.label`,
                    i18nUtils.get(`deposits.constitute.actionToDue.options.${data?.actionToDue}`),
                    "string",
                ),
            );
        }

        return ticketBody;
    };

    buttonsDetail = () => {
        const { dispatch } = this.props;

        return [
            {
                label: "deposits.constitute.confirmation.goToDeposit.button",
                bsStyle: "primary",
                onClick: () => dispatch(push(`/deposits`)),
            },
            {
                label: "deposits.constitute.confirmation.goDesktop.button",
                bsStyle: "outline",
                onClick: () => dispatch(push(`/desktop`)),
            },
        ];
    };

    render() {
        const {
            transactionData,
            transactionData: { data },
            isDesktop,
            isSmallDesktop,
            accounts,
            fetching
        } = this.props;

        const { fetchingDownload } = this.state;

        const creatorFullName =
            transactionData?.creatorFullName ||
            `${transactionData.userCreatorLastName}, ${transactionData.userCreatorFirstName}`;

        let debitAccountToShow;
        if (data) {
            const { debitAccount } = data;

            debitAccountToShow = accounts.find(
                ({ idProduct }) => idProduct === (debitAccount?.idProduct || debitAccount),
            );
        }

        const depositTransactionData = {
            ...data,
            idTransaction: transactionData.idTransaction,
            idTransactionStatus: transactionData.idTransactionStatus,
            debitAccount: debitAccountToShow,
        };

        return (
            <>
                {data && (
                    <TransactionTicket
                        notificationScope="depositsConfirmation"
                        handleClick={this.exportPdf}
                        defaultConfiguration
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        handleBack={this.handleBack}
                        summary={{ ...transactionData, creatorFullName }}
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        fetching={fetching}
                        summaryTitle="deposits.summary.label"
                        generateSignFunction
                        ignoreHomeButton
                        isFetchingExport={fetchingDownload}>
                        <DepositTransactionData depositTransactionData={depositTransactionData} />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    accounts: accountsSelectors.getAccounts(state),
    transactionData: selectors.getData(state),
    fetching: selectors.isFetching(state),
});

export default connect(mapStateToProps)(DepositConstituteTicket);
