import React, { Component } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { func, bool, shape } from "prop-types";
import { push } from "react-router-redux";
import { compose } from "redux";
import classNames from "classnames";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import { selectors as servicePaymentsSelectors, actions as servicePaymentsActions } from "reducers/servicePayments";
import Button from "pages/_components/Button";
import UnattachServiceSummaryData from "./_components/UnattachServiceSummaryData";

const FORM_ID = "services.adhesiones";

class UnattachServiceConfirmationTicket extends Component {
    static propTypes = {
        isDesktop: bool,
        dispatch: func.isRequired,
        summaryInfo: shape({}).isRequired,
        isSubmitting: bool.isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(push("/adhesiones/list"));
    };

    handleClick = (summaryInfo) => {
        const { dispatch } = this.props;

        dispatch(servicePaymentsActions.unattachService(summaryInfo));
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", margin: 0 }}>{i18n.get(`${FORM_ID}.desvincular.title`)}</h1>
        </div>
    );

    render() {
        const { isDesktop, summaryInfo, isSubmitting } = this.props;

        return (
            <>
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={classNames({ "blue-main-header-mobile": !isDesktop })}
                        centerElement={!isDesktop && this.centerContentMobile}
                        centerContentClassName="mx-5"
                        onClose={!isDesktop && this.handleBack}
                    />
                    {isDesktop && <Head title={`${FORM_ID}.desvincular.title`} />}
                </div>
                <MainContainer className="main-container px-0">
                    <Container
                        className={classNames("flex-grow container-white mb-6", { isDesktop: "align-items-center" })}
                        gridClassName="form-content py-4">
                        <div className="admin-content-center mb-5">
                            <I18n id={`${FORM_ID}.desvincular.confirmationTicket.label`} component="h2" />
                        </div>
                        <Col className="col-12" lg={4} md={9} sm={12}>
                            <UnattachServiceSummaryData
                                summaryInfo={summaryInfo}
                                FORM_ID={FORM_ID}
                                isDesktop={isDesktop}
                            />
                            <Button
                                type="submit"
                                bsStyle="primary"
                                label="global.send"
                                loading={isSubmitting}
                                onClick={() => this.handleClick(summaryInfo)}
                                className="mt-5"
                            />
                        </Col>
                    </Container>
                </MainContainer>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    summaryInfo: servicePaymentsSelectors.getSummaryInfo(state),
    isSubmitting: servicePaymentsSelectors.getSubmitting(state),
});

export default compose(connect(mapStateToProps))(UnattachServiceConfirmationTicket);
