import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { arrayOf, shape, func, string, bool } from "prop-types";

import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import CapsList from "pages/administration/_components/CapsList";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

import * as i18nUtils from "util/i18n";
import InfoTag from "pages/_components/InfoTag";
import Notification from "pages/_components/Notification";

class ChannelsConfirm extends Component {
    static propTypes = {
        credentialGroups: arrayOf(
            shape({
                idCredentialGroup: string,
            }),
        ).isRequired,
        user: shape({
            idUser: string,
            idUserStatus: string,
            firstName: string,
            lastName: string,
        }).isRequired,
        routerActions: shape({
            goBack: func,
        }).isRequired,
        actions: shape({
            updateChannelsRequest: func,
        }).isRequired,
        formActions: shape({
            signTransaction: func,
        }).isRequired,
        caps: shape({}).isRequired,
        topAmount: shape({}).isRequired,
        idTransaction: string,
        idActivity: string,
        hasMassiveEnabled: bool,
    };

    static defaultProps = {
        idTransaction: "",
        idActivity: "",
        hasMassiveEnabled: false,
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, caps, topAmount, user, idTransaction, idActivity } = this.props;

        if (!idTransaction) {
            actions.updateChannelsRequest(
                {
                    caps: { ...caps, topAmount },
                    idUser: user.idUser,
                    status: user.idUserStatus,
                    credentials,
                    userFullName: user.fullName,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    renderHeader = () => {
        const { user, hasMassiveEnabled } = this.props;
        const isAdministrator = !hasMassiveEnabled;

        const { fullName } = user;

        if (user) {
            return (
                <>
                    <div className="admin-detail-head admin-detail-head-section px-0">
                        <Head onBack={this.handleBack} />
                        {fullName && (
                            <div className="view-title d-flex">
                                <I18n
                                    id="administration.channels.configurateLimits.title"
                                    component="h1"
                                    fullName={fullName}
                                    componentProps={{
                                        className: "mx-0 align-self-center",
                                    }}
                                />
                                <div className="data-wrapper data-wrapper-flex mt-3 mb-2 ml-3">
                                    {isAdministrator && (
                                        <InfoTag
                                            type="default"
                                            message={i18nUtils.get(`administration.administrator`)}
                                            tagBackground="#707070"
                                        />
                                    )}
                                    {user && (
                                        <InfoTag
                                            type="default"
                                            message={i18nUtils.get(`user.status.${user.idUserStatus}`)}
                                            tagBackground={user.idUserStatus === "blocked" ? "#fc5f5f" : "#0be0a2"}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            );
        }
        return null;
    };

    render() {
        const { user, credentialGroups } = this.props;

        return (
            <>
                <Notification scopeToShow="signature" />
                {this.renderHeader()}

                <MainContainer
                    showLoader={!Object.keys(user).length}
                    viewContentClassName="confirmation__container pt-0">
                    <Container
                        className="align-items-center flex-grow px-0 container--layout with-margin mb-0"
                        gridClassName="confirmation__form confirmation__box">
                        <Container
                            className=" align-items-center flex-grow with-margin"
                            gridClassName="form-content"
                            rowClassName="f-dir-col align-items-center">
                            <I18n
                                component="h2"
                                id="administration.channels.configurateLimits.confirm.subtitle"
                                componentProps={{
                                    className: "pb-3 pt-3 mb-0 background-white text-center w-100",
                                }}
                            />
                            <div className="d-flex w-100 justify-content-center background-white pt-3">
                                <Col className="col col-12 max-xl-480 px-xl-0" md={12} lg={12}>
                                    <div className="transfer-block">
                                        <div className="transfer-data data-wrapper">
                                            <Container.ColumnBody columnClass="list-wrapper">
                                                <CapsList
                                                    hasDefaultLabel
                                                    isConfirmation
                                                    topAmountInline
                                                    noBackgroundChip
                                                    h4ClassName="caps"
                                                />
                                            </Container.ColumnBody>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                            <AdministrationFormConfirmation
                                credentialGroups={credentialGroups}
                                onSubmit={this.handleSubmit}
                                MDcolumnWidth={12}
                                LGcolumnWidth={12}
                                buttonLabel="global.confirm"
                                buttonImage="images/arrowRight.svg"
                                buttonImageClass="mr-2"
                                formContainerClassName="confirmation__container"
                            />
                        </Container>
                    </Container>
                </MainContainer>
            </>
        );
    }
}

export default ChannelsConfirm;
