/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import { func, bool, string } from "prop-types";
import * as i18n from "util/i18n";
import { push } from "react-router-redux/actions";
import classNames from "classnames";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import Button from "pages/_components/Button";
import TokenDrawer from "pages/settings/_components/token/TokenDrawer";
import TokenModal from "pages/settings/_components/token/TokenModal";
import { actions as tokenActivation, selectors as tokenSelectors } from "reducers/token";
import { isActiveInOtherDevice } from "util/token";
import * as configUtils from "util/config";
import { actions as notificationActions } from "reducers/notification";

class TokenDisabled extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        fetching: bool.isRequired,
        tokenStatus: string.isRequired,
    };

    state = {
        onClickState: false,
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(push("/settings"));
    };

    getMobileContent = () => (
        <div className="above-the-fold">
            <div className="mx-3">{this.renderDevices()}</div>
        </div>
    );

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("settings.token")}</h1>
        </div>
    );

    codeActivation = () => {
        const { dispatch } = this.props;
        dispatch(tokenActivation.tokenActivationPreRequest());
    };

    setStateOnClick = () => {
        const { onClickState } = this.state;

        this.setState((prevState) => ({ ...prevState, onClickState: !onClickState }));
    };

    changueNewToken = () => {
        const { dispatch } = this.props;

        dispatch(tokenActivation.tokenOtherDevicesNewTokenRequest());
    };

    SyncNewToken = () => {
        const { dispatch } = this.props;

        dispatch(push("/settings/token/synchronization/ValidateRandomCode"));
    };

    buttonMobile = () => {
        const { isMobile, dispatch, tokenStatus } = this.props;
        const buttonModalUsed = isActiveInOtherDevice();
        const showTokenForm = configUtils.getBoolean("frontend.show.formularioSolicitarToken.functionalities", false);

        if (buttonModalUsed) {
            return (
                <>
                    <Button
                        bsStyle="primary"
                        label="setting.token.request"
                        className="mt-45 btn-uppercase"
                        onClick={() => this.setStateOnClick()}
                        emptyRightOption
                        headerClassName={classNames({
                            "blue-main-header-mobile blue-main-title-mobile": isMobile,
                        })}
                    />
                </>
            );
        }
        if (!showTokenForm || (showTokenForm && (!tokenStatus || tokenStatus === "disabled"))) {
            return (
                <>
                    <ButtonDrawer
                        type="submit"
                        bsStyle="primary"
                        label="setting.token.request"
                        styleCloseButton={{ color: "white" }}
                        headerContent={
                            <I18n
                                id="setting.token.request"
                                component="h1"
                                componentProps={{
                                    className: "title-content header-mobile-title-background-blue m-0",
                                }}
                            />
                        }
                        closeButton={!isMobile}
                        backButton={isMobile}
                        buttonElement={<Button bsStyle="primary" label="setting.token.request" className="mt-45" />}
                        content={<TokenDrawer />}
                        width="auto"
                        drawerClassName="token-drawer-mobile"
                        navStyle="default align-items-center d-flex"
                        drawerContentClassName="pt-0"
                    />
                </>
            );
        }
        if (showTokenForm && tokenStatus !== null) {
            return (
                <>
                    <Button
                        bsStyle="primary"
                        label="setting.token.request"
                        className="mt-45"
                        onClick={() =>
                            dispatch(
                                notificationActions.showNotification(
                                    i18n.get("setting.token.noForm.requested"),
                                    "warning",
                                    ["tokenCreateCodeInvitation"],
                                ),
                            )
                        }
                    />
                </>
            );
        }

        return null;
    };

    renderRequestButton = () => {
        const { isMobile, dispatch, tokenStatus } = this.props;
        const showTokenForm = configUtils.getBoolean("frontend.show.formularioSolicitarToken.functionalities", false);

        if (isMobile) {
            return this.buttonMobile();
        }

        if (!showTokenForm || (showTokenForm && (!tokenStatus || tokenStatus === "disabled"))) {
            return (
                <>
                    <ButtonDrawer
                        type="submit"
                        bsStyle="primary"
                        label="setting.token.request"
                        wrapperClassName="token-drawer-content"
                        headerClassName="token-drawer-header"
                        closeButton
                        isDesktop
                        buttonElement={<Button bsStyle="primary" label="setting.token.request" className="mt-3" />}
                        content={<TokenDrawer />}
                    />
                </>
            );
        }

        if (showTokenForm && tokenStatus !== null) {
            return (
                <>
                    <Button
                        bsStyle="primary"
                        label="setting.token.request"
                        className="mt-3"
                        onClick={() =>
                            dispatch(
                                notificationActions.showNotification(
                                    i18n.get("setting.token.noForm.requested"),
                                    "warning",
                                    ["tokenCreateCodeInvitation"],
                                ),
                            )
                        }
                    />
                </>
            );
        }

        return null;
    };

    renderContent = () => {
        const { isMobile, fetching } = this.props;
        const { onClickState } = this.state;
        const showMobile = window.isMobileApp();
        const isInOtherDevice = isActiveInOtherDevice();

        return (
            <div className="above-the-fold bg-white-mobile">
                <Container
                    className={classNames("align-items-center flex-grow container-white", {
                        "py-45 px-6": !isMobile,
                    })}>
                    <TokenModal
                        isDisplayed={isInOtherDevice && onClickState}
                        isOtherDevice={isInOtherDevice}
                        buttonNewToken={this.changueNewToken}
                        buttonSyncToken={this.SyncNewToken}
                        setStateOnClick={this.setStateOnClick}
                        isDesktop={!isMobile}
                    />
                    <Col sm={12} md={8}>
                        <div className="admin-content-center mt-3">
                            <I18n id="setting.token.subTittle" component={isMobile ? "h4" : "h2"} />
                        </div>

                        <Col className={isMobile ? "mt-3" : "mt-5"}>
                            <Image className="token-image" src="images/setting-tokenDisabled.svg" />
                            <div className="admin-content-center">
                                <I18n
                                    id="setting.token.text.image"
                                    component="h3"
                                    componentProps={{ className: "f-size-5 font-regular" }}
                                />
                            </div>
                        </Col>

                        <Col className={isMobile ? "mt-4" : "mt-5"}>
                            <div className="admin-content-center mt-3">
                                <I18n
                                    id="setting.token.label.text"
                                    componentProps={{
                                        className: isMobile ? "f-size-6 font-light" : "f-size-4 font-light text-gray",
                                    }}
                                    component="p"
                                />
                            </div>
                        </Col>
                    </Col>
                </Container>

                <Container className="flex-grow align-items-center" gridClassName="form-content">
                    <Col sm={12} md={4} className="align-items-center">
                        {this.renderRequestButton()}

                        {showMobile && (
                            <Col
                                className="col-12 align-items-center px-0 "
                                style={{ position: "relative", bottom: "0.5rem" }}>
                                <Button
                                    label="setting.token.mobile.activation.button"
                                    className="btn btn-outline"
                                    onClick={() => this.codeActivation()}
                                    loading={fetching}
                                    style={{ button: "normal" }}
                                />
                            </Col>
                        )}
                    </Col>
                </Container>
            </div>
        );
    };

    render() {
        return <>{this.renderContent()}</>;
    }
}

const mapStateToProps = (state) => ({
    fetching: tokenSelectors.getFetching(state),
    tokenStatus: tokenSelectors.getUserTokenStatus(state),
});

export default connect(mapStateToProps)(TokenDisabled);
