import React from "react";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import Image from "pages/_components/Image";
import { string, shape, func, bool } from "prop-types";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";
import Heading from "pages/_components/Heading";
import Button from "pages/_components/Button";

export default function EcheqToEmit({ echeq, removeEcheq, isDesktop }) {
    EcheqToEmit.propTypes = {
        echeq: shape({
            amount: string.isRequired,
            date: string.isRequired,
            reference: string.isRequired,
            referencevalue: string.isRequired,
            type: string.isRequired,
        }).isRequired,
        removeEcheq: func.isRequired,
        isDesktop: bool.isRequired,
    };

    if (!isDesktop) {
        return (
            <Container className="container-white mt-2 py-2">
                <Col xs={11} className="px-0">
                    <Col xs={12} className="pr-2">
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between m-0"
                            dataClassName="data-desc small-font"
                            label="echeqs.monto"
                            data={
                                <FormattedAmount
                                    currency={i18n.get("currency.label.0")}
                                    quantity={echeq?.amount}
                                    fontClassName="data-desc small-font"
                                    notBold
                                />
                            }
                        />
                    </Col>
                    <Col xs={12} className="pr-2">
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between m-0"
                            dataClassName="data-desc small-font"
                            label="echeqs.emit.date.label"
                            data={<FormattedDate date={echeq?.date} />}
                        />
                    </Col>
                    <Col xs={12} className="pr-2">
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between m-0"
                            dataClassName="data-desc small-font"
                            label="echeqs.emit.type.label"
                            data={echeq?.type}
                        />
                    </Col>
                    <Col xs={12} className="pr-2">
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between m-0"
                            dataClassName="data-desc small-font"
                            label="echeqs.reference.label"
                            data={echeq?.reference}
                        />
                    </Col>
                    <Col xs={12} className="pr-2">
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between m-0"
                            dataClassName="data-desc small-font"
                            label="echeqs.referencevalue.label"
                            data={echeq?.referencevalue}
                        />
                    </Col>
                </Col>
                <Col xs={1} className="pl-0 mt-2">
                    <Button type="button" className="btn-link h-auto mh-auto p-0 mt-0" onClick={removeEcheq}>
                        <Image src="images/cross-mobile-blue.svg" className="svg-icon-standard" />
                    </Button>
                </Col>
            </Container>
        );
    }

    return (
        <Container
            className="flex-grow align-items-center container-white my-2"
            gridClassName="form-content"
            rowClassName="py-3 px-5">
            <Col xs={12} md={3} className="text-align-right d-flex align-items-start justify-content-right pt-2">
                <FormattedAmount
                    currency={i18n.get("currency.label.0")}
                    quantity={echeq?.amount}
                    fontClassName="font-regular"
                    notBold
                />
            </Col>
            <Col xs={12} md={2} className="pl-45 pt-2 d-flex align-items-start">
                <FormattedDate date={echeq?.date} className="pl-1" />
            </Col>
            <Col xs={12} md={1} className="pl-45">
                <p className="break-word pl-1">{echeq?.type}</p>
            </Col>
            <Col xs={12} md={2} className="pl-45">
                <p className="break-word">{echeq?.reference}</p>
            </Col>
            <Col xs={12} md={2} className="pl-4">
                <p className="break-word pl-1">{echeq?.referencevalue}</p>
            </Col>
            <Col xs={12} md={2} className="px-0">
                <button type="button" className="btn-link pt-2" onClick={removeEcheq}>
                    <Image src="images/cross-mobile-blue.svg" styles={{ width: "25px", height: "25px" }} />
                </button>
            </Col>
        </Container>
    );
}
