import React, { Component } from "react";
import { Form, Field } from "formik";
import Col from "react-bootstrap/lib/Col";
import Redirect from "react-router-dom/Redirect";

import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { shape, string, bool, arrayOf } from "prop-types";
import * as i18nUtils from "util/i18n";
import InfoTag from "pages/_components/InfoTag";

class GroupsOfUserConfirm extends Component {
    static propTypes = {
        user: string.isRequired,
        routerActions: shape({}).isRequired,
        actions: shape({}).isRequired,
        formActions: shape({}).isRequired,
        match: shape({}).isRequired,
        groupsToSave: arrayOf().isRequired,
        idTransaction: string.isRequired,
        idActivity: string.isRequired,
        isDesktop: bool.isRequired,
        credentialGroups: arrayOf().isRequired,
        fetching: bool.isRequired,
        hasMassiveEnabled: bool,
    };

    static defaultProps = {
        hasMassiveEnabled: false,
    };

    componentDidMount() {
        const { user, routerActions } = this.props;
        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, match, groupsToSave, idTransaction, idActivity, user } = this.props;

        if (!idTransaction) {
            actions.updateGroupsOfUserConfirm(
                {
                    credentials,
                    idUser: match.params.id,
                    groupsToSave,
                    userFullName: user.fullName,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;
        routerActions.goBack();
    };

    renderForm = ({ isSubmitting }) => (
        <Form>
            <Container
                className="container--layout align-items-center"
                gridClassName="form-content container--flex-middle">
                <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                    <I18n id="form.credential.hint" />
                    <Field name="otp" type="otp" idForm="administration.users.action" component={Credential} />
                </Col>
            </Container>
            <Container className="container--layout align-items-center" gridClassName="form-footer">
                <Col className="col-12 col-xl-6" lg={6} md={9} sm={12}>
                    <Button type="submit" bsStyle="primary" label="global.send" loading={isSubmitting} />
                </Col>
            </Container>
        </Form>
    );

    renderHeader = () => {
        const { user, hasMassiveEnabled } = this.props;
        const isAdministrator = !hasMassiveEnabled;

        const { fullName } = user;
        if (user) {
            return (
                <>
                    <div className="admin-detail-head admin-detail-head-section mb-4 px-0 rigth-position-desktop">
                        <Head
                            onBack={this.handleBack}
                            closeLinkTo={`/administration/advanced/details/${user.idUser}`}
                        />
                        {fullName && (
                            <div className="view-title d-flex">
                                <I18n
                                    component="h1"
                                    id="administration.configurateGroups.title"
                                    componentProps={{ className: "align-font-fix-04" }}
                                    fullName={fullName}
                                />

                                <div className="data-wrapper data-wrapper-flex">
                                    {isAdministrator && (
                                        <InfoTag
                                            type="default"
                                            message={i18nUtils.get(`administration.administrator`)}
                                            tagBackground="#707070"
                                        />
                                    )}
                                    {user && (
                                        <InfoTag
                                            type="default"
                                            message={i18nUtils.get(`user.status.${user.idUserStatus}`)}
                                            tagBackground={user.idUserStatus === "blocked" ? "#fc5f5f" : "#0be0a2"}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            );
        }
        return null;
    };

    render() {
        const { isDesktop, credentialGroups, groupsToSave, fetching, user } = this.props;
        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        let index = 0;
        const groupText = groupsToSave.map(({ name, idGroupAsString }) => {
            index += 1;
            return (
                <span key={idGroupAsString} className="font-light f-size-4">
                    {name}
                    {index !== groupsToSave.length && ","}
                    &nbsp;
                </span>
            );
        });

        return (
            <>
                <Notification scopeToShow="signature" />
                {this.renderHeader()}
                <MainContainer
                    viewContentClassName="confirmation__container pt-0"
                    showLoader={fetching && !Object.keys(user).length}>
                    <Container
                        className="align-items-center flex-grow px-0 container--layout with-margin mb-0"
                        gridClassName="confirmation__form confirmation__box">
                        <Container
                            className="align-items-center flex-grow container-white"
                            gridClassName="form-content">
                            <I18n
                                component="h2"
                                componentProps={{ className: "cmf-title-center mt-4 mb-5 pb-1" }}
                                id="administration.configurateGroups.confirm.subtitle"
                            />

                            <Col className="col col-12">
                                <div className="d-flex justify-content-center w-100">
                                    <Col className="col mb-4 max-xl-480 px-xl-0" md={12} lg={12}>
                                        <div className="d-flex align-items-baseline">
                                            <div className="data-label admin-group-data-label">
                                                {i18nUtils.get("administration.signature.user")}
                                            </div>
                                            &nbsp;&nbsp;
                                            <div>
                                                <span className="font-light f-size-4">{`${user.firstName} ${user.lastName}`}</span>
                                            </div>
                                        </div>
                                        &nbsp;
                                        <div className="d-flex align-items-baseline">
                                            <div className="data-label admin-group-data-label">
                                                {i18nUtils.get("administration.configurateGroups.groups")}
                                            </div>
                                            &nbsp;&nbsp;
                                            <div>{groupText}</div>
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                        </Container>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            MDcolumnWidth={12}
                            LGcolumnWidth={12}
                            buttonLabel="global.confirm"
                            buttonImage="images/arrowRight.svg"
                            buttonImageClass="mr-2"
                            formContainerClassName="confirmation__container"
                        />
                    </Container>
                </MainContainer>
            </>
        );
    }
}

export default resizableRoute(GroupsOfUserConfirm);
