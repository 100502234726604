/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool, string } from "prop-types";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { selectors as sessionSelectors } from "reducers/session";
import { idTransactionFormat } from "util/format";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class AdminSignaturesDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
        administrationScheme: string.isRequired,
    };

    dataRow = (schemeLabel, typeLabel) => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                submitDateTimeAsString,
                data: { signatureLevelsCounts, signatureType, topAmount, maxAmount, capFrequencies },
            } = object.transaction;

            const amount = topAmount?.amount || maxAmount || undefined;
            const period = capFrequencies && capFrequencies.length ? capFrequencies[0] : topAmount?.period || undefined;

            return (
                <CardContainer className="confirmation__cards-container">
                    <Card
                        className="confirmation__cards"
                        icon={isDesktop && "images/transfer-PENDING.svg"}
                        iconClass="card__pending-transaction-icon"
                        hrClass={!this.renderTopAmount(amount, period, signatureType) && "d-none"}
                        title={
                            isDesktop
                                ? submitDateTimeAsString && (
                                      <div className="confirmation-cards__title">
                                          <div className="confirmation-cards__title-data">
                                              <I18n id="forms.transaction.ticket.date" />
                                          </div>
                                          <div className="confirmation-cards__title-info">
                                              <span>{submitDateTimeAsString}</span>
                                          </div>
                                      </div>
                                  )
                                : signatureType && (
                                      <div className="confirmation-cards__title">
                                          <div className="confirmation-cards__title-data">
                                              {i18nUtils.get(`${typeLabel}`)}
                                          </div>
                                          <div className="confirmation-cards__title-info">
                                              <span>
                                                  {i18nUtils.get(
                                                      `administration.signatures.create.signatureType.${signatureType}`,
                                                  )}
                                              </span>
                                          </div>
                                      </div>
                                  )
                        }
                        content={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    {signatureType && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                {i18nUtils.get(`${typeLabel}`)}
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {i18nUtils.get(
                                                        `administration.signatures.create.signatureType.${signatureType}`,
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {this.signersCount(signatureLevelsCounts) && (
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-data text-capitalize">
                                                {i18nUtils.get(`${schemeLabel}`)}
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{this.signersCount(signatureLevelsCounts)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {submitDateTimeAsString && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="forms.transaction.ticket.date" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{submitDateTimeAsString}</span>
                                            </div>
                                        </div>
                                    )}
                                    {this.signersCount(signatureLevelsCounts) && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                {i18nUtils.get(`${schemeLabel}`)}
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{this.signersCount(signatureLevelsCounts)}</span>
                                            </div>
                                        </div>
                                    )}
                                    {idTransactionFormat(idTransaction) && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="forms.transaction.ticket.number" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        bottom={
                            isDesktop &&
                            idTransactionFormat(idTransaction) && (
                                <div className="confirmation-cards__bottom">
                                    <div className="confirmation-cards__bottom-data">
                                        <I18n id="forms.transaction.ticket.number" />
                                    </div>
                                    <div className="confirmation-cards__bottom-info">
                                        <span>{idTransactionFormat(idTransaction)}</span>
                                    </div>
                                </div>
                            )
                        }
                        rightContent={
                            this.renderTopAmount(amount, period, signatureType) && (
                                <div className="confirmation-cards__right">
                                    <div className="confirmation-cards__right-data">
                                        <I18n id="administration.signatures.create.topAmount.label" />
                                    </div>
                                    <div className="confirmation-cards__right-info">
                                        <span>{this.renderTopAmount(amount, period, signatureType)}</span>
                                    </div>
                                </div>
                            )
                        }
                    />
                </CardContainer>
            );
        });
    };

    renderTopAmount = (amount, period, signatureType) => {
        if (amount && signatureType === "AMOUNT") {
            return (
                <FormattedAmount
                    currency={configUtils.get("core.masterCurrency")}
                    frequency={i18nUtils.get("administration.signatures.create.advanced.topAmountPerPeriod", null, {
                        period: i18nUtils.get(`administration.signatures.transactions.capFrequency.${period}`),
                    })}
                    quantity={amount}
                />
            );
        }
        return null;
    };

    signersCount = (signatureLevelsCounts) => {
        const { administrationScheme } = this.props;
        return administrationScheme === "medium"
            ? `${signatureLevelsCounts.A}`
            : Object.keys(signatureLevelsCounts).reduce(
                  (result, key) => result + key.toString().repeat(signatureLevelsCounts[key]),
                  "",
              );
    };

    render() {
        const { administrationScheme } = this.props;

        const schemeLabel =
            administrationScheme === "advanced"
                ? `administration.signatures.create.${administrationScheme}.confirm.schemeReference`
                : `administration.signatures.create.${administrationScheme}.confirm.signersCount`;
        const typeLabel = `administration.signatures.create.${administrationScheme}.confirm.transactionType`;

        return this.dataRow(schemeLabel, typeLabel);
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
    administrationScheme: sessionSelectors.getAdministrationScheme(state),
});

export default connect(mapStateToProps)(AdminSignaturesDataTable);
