import React, { Component } from "react";
import { string, node } from "prop-types";

import Image from "pages/_components/Image";
import ListMessage from "./ListMessage";

class NoResults extends Component {
    static propTypes = {
        children: node.isRequired,
        iconPath: string,
    };

    static defaultProps = {
        iconPath: "images/coloredIcons/mailbox-empty.svg",
    };

    render() {
        const { children, iconPath } = this.props;

        return (
            <ListMessage>
                <div className="illustration-wrapper">
                    <Image src={iconPath} className="svg-big-icon" />
                </div>
                {children}
            </ListMessage>
        );
    }
}

export default NoResults;
