import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import queryString from "query-string";
import url from "url";
import { actions as assistantActions, selectors as assistantSelectors } from "reducers/assistant";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

import * as config from "util/config";

class AssistantLogin extends Component {
    static getDerivedStateFromProps(props, state) {
        const queryObject = url.parse(props.location.search, true).query;
        const {
            account_linking_token,
            client_id,
            redirect_uri,
            response_type,
            twilio_redirect_uri,
            client_number,
        } = queryObject;
        let params = {
            ...queryObject,
            isFromAmazon: false,
            isFromGoogle: false,
            isFromMessenger: false,
            isFromWhatsapp: false,
        };

        if (response_type && client_id && redirect_uri && queryObject.state) {
            const isFromAmazon = redirect_uri.indexOf("amazon") >= 0;
            const isFromGoogle = redirect_uri.indexOf("google") >= 0;

            params = {
                ...params,
                isFromAmazon,
                isFromGoogle,
                currentAssistant: isFromAmazon ? "Alexa" : isFromGoogle ? "Google Assistant" : "",
            };
        } else if (account_linking_token && redirect_uri && redirect_uri.indexOf("messenger") >= 0) {
            params = {
                ...params,
                isFromMessenger: true,
                currentAssistant: "Facebook Messenger",
            };
        } else if (twilio_redirect_uri && client_number) {
            params = {
                ...params,
                isFromWhatsapp: true,
                currentAssistant: "WhatsApp",
            };
        } else {
            props.dispatch(push("/"));
        }

        props.dispatch(assistantActions.setParams(params));

        return state;
    }

    handleOkClick = () => {
        const queryObject = queryString.parse(this.props.location.search);
        const {
            account_linking_token,
            client_id,
            redirect_uri,
            response_type,
            state,
            twilio_redirect_uri,
        } = queryObject;

        if (
            (response_type && client_id && redirect_uri && state) ||
            (account_linking_token && redirect_uri) ||
            twilio_redirect_uri
        ) {
            this.props.dispatch(push("/"));
        }
    };

    handleCancelClick = () => {
        const { isFromMessenger, isDesktop } = this.props;
        let redirectUrl = "";

        if (isFromMessenger) {
            if (isDesktop) {
                window.close();
            } else {
                redirectUrl = `https://m.me/${config.get("assistant.bots.facebookMessenger.pageId")}`;
            }
        } else {
            const queryObject = queryString.parse(this.props.location.search);
            const { redirect_uri, state } = queryObject;
            redirectUrl = `${decodeURIComponent(redirect_uri)}?code=cancel&state=${state}`;
        }

        window.location = redirectUrl;
    };

    render() {
        const {
            isAssistantLogin,
            currentAssistant,
            isFromAmazon,
            isFromGoogle,
            isFromMessenger,
            isFromWhatsapp,
        } = this.props;

        return (
            isAssistantLogin && (
                <Fragment>
                    <header className="view-header">
                        <nav className="navbar navbar-default">
                            <div className="container-fluid">
                                <div className="navbar-header">
                                    <div className="toolbar">
                                        <div className="toolbar-item view-title">
                                            <h2>
                                                <span>
                                                    <I18n
                                                        id="assistant.account.link.title"
                                                        ASSISTANT={currentAssistant}
                                                    />
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </header>

                    <div className="view-page">
                        <div role="main" className="view-content">
                            <main className="main-container">
                                <form className="above-the-fold login-desktop-wrapper">
                                    <section className="container--layout flex-grow align-items-center">
                                        <div className="container">
                                            <div className="justify-content-center row">
                                                <div xl="6" className="col col-12 col-lg-6 col-md-9 col-sm-12">
                                                    <div className="media-object media-object--image-branded">
                                                        <figure>
                                                            {isFromAmazon && (
                                                                <Image src="images/alexa.svg" className="svg-icon" />
                                                            )}
                                                            {isFromGoogle && (
                                                                <Image
                                                                    src="images/google-assistant.svg"
                                                                    className="svg-icon"
                                                                />
                                                            )}
                                                            {isFromMessenger && (
                                                                <Image
                                                                    src="images/facebook-messenger.svg"
                                                                    className="svg-icon"
                                                                />
                                                            )}
                                                            {isFromWhatsapp && (
                                                                <Image src="images/whatsapp.svg" className="svg-icon" />
                                                            )}
                                                        </figure>
                                                        <div className="media-object--text">
                                                            <I18n
                                                                id="assistant.account.link.subtitle"
                                                                ASSISTANT={currentAssistant}
                                                            />
                                                        </div>
                                                    </div>

                                                    <p className="text-lead">
                                                        <I18n
                                                            id="assistant.account.link.permissions.title"
                                                            ASSISTANT={currentAssistant}
                                                        />
                                                    </p>
                                                    <ul className="normal-list">
                                                        <li>
                                                            <I18n
                                                                id="assistant.account.link.permissions.1"
                                                                ASSISTANT={currentAssistant}
                                                            />
                                                        </li>
                                                        <li>
                                                            <I18n
                                                                id="assistant.account.link.permissions.2"
                                                                ASSISTANT={currentAssistant}
                                                            />
                                                        </li>
                                                        <li>
                                                            <I18n
                                                                id="assistant.account.link.permissions.3"
                                                                ASSISTANT={currentAssistant}
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="align-items-center container--layout">
                                        <div className="container">
                                            <div className="justify-content-center row">
                                                <div xl="6" className="col col-12 col-lg-6 col-md-9 col-sm-12">
                                                    <Button
                                                        className="btn-primary"
                                                        label="assistant.account.link.acept"
                                                        onClick={this.handleOkClick}
                                                        replace={{ ASSISTANT: currentAssistant }}
                                                    />
                                                    <Button
                                                        label="assistant.account.link.cancel"
                                                        className="btn-outline btn-block"
                                                        onClick={this.handleCancelClick}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                            </main>
                        </div>
                    </div>
                </Fragment>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    isAssistantLogin: assistantSelectors.isAssistantLogin(state),
    currentAssistant: assistantSelectors.getCurrentAssistant(state),
    isFromGoogle: assistantSelectors.isFromGoogle(state),
    isFromAmazon: assistantSelectors.isFromAmazon(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
});

export default connect(mapStateToProps)(AssistantLogin);
