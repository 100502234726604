import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { func, arrayOf, objectOf, shape, bool, string } from "prop-types";
import { Field } from "formik";
import { connect } from "react-redux";

import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Selector from "pages/_components/fields/formik/Selector";
import { detailsSelectors } from "reducers/administration";
import InfoTag from "pages/_components/InfoTag";
import Notification from "pages/_components/Notification";

const FORM_ID = "administration.advanced.signature.modify";

class SignatureConfirm extends Component {
    static propTypes = {
        user: shape({ firstName: string, lastName: string }).isRequired,
        routerActions: shape({
            goBack: func,
        }),
        idActivity: string,
        idTransaction: string,
        schemeName: string.isRequired,
        signatureLevels: arrayOf(shape({ id: string, label: string })),
        signatureLevel: string,
        actions: objectOf(func).isRequired,
        formActions: objectOf(func).isRequired,
        hasSignatureEnabled: bool,
        match: shape({
            params: shape({ id: string.isRequired }),
        }).isRequired,
        fetching: bool,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        hasMassiveEnabled: bool,
        userEnvStatus: string,
    };

    static defaultProps = {
        routerActions: null,
        idActivity: null,
        idTransaction: null,
        fetching: false,
        signatureLevel: "",
        signatureLevels: [],
        hasSignatureEnabled: false,
        hasMassiveEnabled: false,
        userEnvStatus: "",
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    renderFormContent = () => {
        const { signatureLevels, signatureLevel, idTransaction, schemeName } = this.props;

        if (schemeName === "advanced") {
            if (!idTransaction) {
                return (
                    <Field
                        component={Selector}
                        options={signatureLevels}
                        idForm={FORM_ID}
                        name="signatureLevel"
                        value={signatureLevel || "N"}
                        renderAs="radio"
                        inLineControl
                    />
                );
            }
            if (signatureLevel) {
                return <I18n id="administration.signature.ticket.configure" SIGNATURE_LEVEL={signatureLevel} />;
            }
            return <I18n id="administration.signature.ticket.change.true" />;
        }
        return null;
    };

    renderForm = () => (
        <Container className="container-invite-user my-0 align-items-center admin-action" gridClassName="form-content">
            <Col sm={12} className="inputs-invite-user col-12 d-flex justify-content-center my-5">
                {this.renderFormContent()}
            </Col>
        </Container>
    );

    formatSubmitSignatureValue = (signatureLevel) => {
        const { hasSignatureEnabled, schemeName } = this.props;
        if (schemeName === "medium") {
            return hasSignatureEnabled ? null : "A";
        }
        return signatureLevel === "N" ? null : signatureLevel;
    };

    handleSubmit = (data, formikBag) => {
        const { actions, formActions, match, idTransaction, idActivity, user } = this.props;
        const { signatureLevel, ...credentials } = data;

        if (!idTransaction) {
            actions.updateSignatureRequest(
                {
                    credentials,
                    idUser: match.params.id,
                    signatureLevel: this.formatSubmitSignatureValue(signatureLevel),
                    userFullName: user.fullName,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const {
            fetching,
            signatureLevel,
            schemeName,
            user,
            credentialGroups,
            hasSignatureEnabled,
            hasMassiveEnabled,
            userEnvStatus,
        } = this.props;
        const isAdministrator = !hasMassiveEnabled;

        return (
            <>
                <Notification scopeToShow="signature" />
                <div className="admin-detail-head admin-detail-head-section px-0 mb-4">
                    <Head onBack={this.handleBack} />
                    <div className="view-title d-flex">
                        <h1>{`${i18nUtils.get("administration.signature.configureSignatureOf")} ${user.fullName}`}</h1>

                        <div className="data-wrapper data-wrapper-flex ml-2">
                            {isAdministrator && (
                                <InfoTag
                                    type="default"
                                    message={i18nUtils.get(`administration.administrator`)}
                                    tagBackground="#707070"
                                />
                            )}
                            <InfoTag
                                type="default"
                                message={i18nUtils.get(`user.status.${userEnvStatus}`)}
                                tagBackground={userEnvStatus === "blocked" ? "#fc5f5f" : "#0be0a2"}
                            />
                        </div>
                    </div>
                </div>
                <MainContainer viewContentClassName="pt-0" showLoader={fetching && !Object.keys(user).length}>
                    <div className="confirmation__container">
                        <Container
                            className="align-items-center flex-grow px-0
                                container--layout with-margin mb-0"
                            gridClassName="confirmation__form confirmation__box">
                            <Col className="col-12 px-0 max-xl-480" md={12} lg={12}>
                                <div className="admin-content-center mb-6">
                                    <I18n
                                        id={
                                            schemeName === "advanced"
                                                ? "administration.users.forms.confirm.credentials"
                                                : `administration.signature.change.${hasSignatureEnabled}`
                                        }
                                        component="h2"
                                        componentProps={{ className: "mt-4" }}
                                    />
                                </div>
                                {schemeName === "advanced" ? (
                                    <Heading.DataGroup
                                        containerClassName="d-flex"
                                        labelClassName="f-size-4 mr-3"
                                        dataClassName="f-size-4 font-light"
                                        label="administration.signature.user"
                                        data={`${user.firstName} ${user.lastName}`}
                                    />
                                ) : (
                                    <div className="my-5">
                                        <Heading.DataGroup
                                            containerClassName="d-flex"
                                            labelClassName="f-size-4"
                                            data={`${user.firstName} ${user.lastName}`}
                                            dataClassName="chip f-size-4"
                                        />
                                    </div>
                                )}
                            </Col>
                            <AdministrationFormConfirmation
                                otherFieldFunction={this.renderForm}
                                otherInitialValues={{ signatureLevel: signatureLevel || "N" }}
                                credentialGroups={credentialGroups}
                                onSubmit={this.handleSubmit}
                                MDcolumnWidth={12}
                                LGcolumnWidth={12}
                                disclaimerMessageKey="dispatcher.disclaimer.message"
                                buttonLabel="global.confirm"
                                buttonImage="images/arrowRight.svg"
                                buttonImageClass="mr-2"
                                formContainerClassName="confirmation__container"
                            />
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    hasMassiveEnabled: detailsSelectors.hasMassiveEnabled(state),
    userEnvStatus: detailsSelectors.getUserEnvStatus(state),
});

export default connect(mapStateToProps)(SignatureConfirm);
