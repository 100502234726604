/* eslint-disable linebreak-style */
export const types = {
    DOWNLOAD_POSICION_CONSOLIDADA_REQUEST: "posicionConsolidada/DOWNLOAD_POSICION_CONSOLIDADA_REQUEST",
    DOWNLOAD_POSICION_CONSOLIDADA_FAILURE: "posicionConsolidada/DOWNLOAD_POSICION_CONSOLIDADA_FAILURE",
    DOWNLOAD_POSICION_CONSOLIDADA_SUCCESS: "posicionConsolidada/DOWNLOAD_POSICION_CONSOLIDADA_SUCCESS",
};

export const INITIAL_STATE = {
    fetchingDownload: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.DOWNLOAD_POSICION_CONSOLIDADA_REQUEST:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.DOWNLOAD_POSICION_CONSOLIDADA_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.DOWNLOAD_POSICION_CONSOLIDADA_SUCCESS:
            return {
                ...state,
                fetchingDownload: false,
            };
        default:
            return state;
    }
};

export const actions = {
    downloadPosicionConsolidada: (year, month, filters) => ({
        type: types.DOWNLOAD_POSICION_CONSOLIDADA_REQUEST,
        year,
        month,
        filters,
    }),
};

export const selectors = {
    isFetching: ({ posicionConsolidada }) => posicionConsolidada.fetchingDownload,
};
