import React, { Component, Fragment } from "react";
import { func, bool } from "prop-types";
import PlaidLink from "react-plaid-link";

import * as configUtils from "util/config";

import formField from "pages/forms/_components/_fields/_commons/formField";

class Accountfundplaid extends Component {
    static propTypes = {
        setValue: func.isRequired,
        editing: bool.isRequired,
    };

    state = {
        plaidSuccess: false,
    };

    handleOnExit = () => {};

    handleOnSuccess = (token, metadata) => {
        const { setValue } = this.props;
        this.setState({ plaidSuccess: true, selectedAccountName: metadata.account.name });

        setValue({ publicToken: token, debitAccountId: metadata.account.id });
    };

    render() {
        const { editing } = this.props;
        const { plaidSuccess, selectedAccountName } = this.state;

        if (editing) {
            return (
                <Fragment>
                    <PlaidLink
                        type="button"
                        clientName="Techbank"
                        env="sandbox"
                        product={["transactions"]}
                        publicKey={configUtils.get("plaid.integration.publicKey")}
                        onExit={this.handleOnExit}
                        onSuccess={this.handleOnSuccess}
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                        className="btn btn-quiet btn-small">
                        Select source account
                    </PlaidLink>
                    {plaidSuccess && <div> {selectedAccountName}</div>}
                </Fragment>
            );
        }
        return null;
    }
}

export default formField({ pureRender: true })(Accountfundplaid);
