/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, createRef } from "react";
import { arrayOf, element, string, func, shape, bool } from "prop-types";
import classNames from "classnames";
import { Spring, config } from "react-spring";
import { withRouter } from "react-router-dom";
import Button from "pages/_components/Button";

const DropdownMenuSection = ({ children, image, buttonClass, label, history, isCollapsed }) => {
    const node = createRef();
    const childrenLength = React.Children.toArray(children).length;
    const [isOpen, setIsOpen] = useState(false);
    const [navIndex, setNavIndex] = useState(0);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        setNavIndex(0);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const validateTarget = (target) => {
        let parentsTargetNodes = [];
        let auxChild = target;
        for (let index = 0; index < 6; index++) {
            parentsTargetNodes = [...parentsTargetNodes, auxChild];
            auxChild = auxChild?.parentNode;
        }
        const validateSVG = parentsTargetNodes.find(
            (parent) =>
                parent?.classList?.contains("dropdown-selected-svg") ||
                parent?.classList?.contains("toggle-menu-button"),
        );
        const result = target.classList.contains("toggle-menu-button") || validateSVG || target.id === "dropdown-text";
        return result;
    };

    const handleClick = ({ target }) => {
        if (!node.current?.contains(target) && validateTarget(target) && isOpen) {
            toggleOpen();
            target.classList.remove("dropdown-selected-svg");
        } else if (node.current?.contains(target) && validateTarget(target)) {
            toggleOpen();
            target.classList.add("dropdown-selected-svg");
            const link = target.closest("a");
            if (link) {
                history.push(link.getAttribute("href"));
            }
        } else if (
            (target.parentNode?.classList?.contains("menu-item-single") ||
                target.parentNode?.parentNode?.classList?.contains("menu-item-single") ||
                target.parentNode?.classList?.contains("menu-svg-single") ||
                target.parentNode?.parentNode?.classList?.contains("menu-svg-single")) &&
            isOpen
        ) {
            onClose();
        }
    };

    useEffect(() => {
        const menuItems = document.querySelectorAll(".menu-item-single");

        menuItems.forEach((elem) => {
            elem.firstChild.addEventListener("click", handleClick);
        });
        document.addEventListener("click", handleClick, false);
        if (isCollapsed) {
            document.querySelectorAll(".app-content").forEach((elem) => elem.addEventListener("mouseover", onClose));
        }
        return () => {
            menuItems.forEach((elem) => {
                elem.firstChild.removeEventListener("click", handleClick);
            });
            document.removeEventListener("click", handleClick, false);
            if (isCollapsed) {
                document
                    .querySelectorAll(".app-content")
                    .forEach((elem) => elem.removeEventListener("mouseover", onClose, false));
            }
        };
    });

    const onChildFocus = (param) => {
        setNavIndex(param);
    };

    const keyPressHandler = (ev) => {
        let aux = navIndex;
        if (ev.shiftKey && ev.key === "Tab") {
            aux -= 1;
        } else if (ev.key === "Tab") {
            aux += 1;
        }

        if (aux >= childrenLength || aux < 0) {
            setIsOpen(false);
        }
    };

    return (
        <div className="dropdown" ref={node}>
            <Button
                block={false}
                className={classNames(buttonClass)}
                image={image}
                label={label}
                aria-haspopup
                aria-expanded={isOpen}
                onKeyDown={keyPressHandler}
                replace={{ componentProps: { id: "dropdown-text" } }}
                fitWidth
            />

            <Spring
                config={config.stiff}
                from={{ height: 0 }}
                to={{
                    height: isOpen ? "auto" : 0,
                    overflow: "hidden",
                    borderWidth: isOpen ? 1 : 0,
                }}>
                {(styles) =>
                    isOpen && (
                        <ul style={styles}>
                            {React.Children.map(children, (child, ix) => (
                                <li className="dropdown__item" onKeyDown={keyPressHandler}>
                                    {child &&
                                        React.cloneElement(child, {
                                            ...child.props,
                                            ix,
                                            keyPressHandler,
                                            onFocus: () => {
                                                onChildFocus(ix);
                                            },
                                        })}
                                </li>
                            ))}
                        </ul>
                    )
                }
            </Spring>
        </div>
    );
};

DropdownMenuSection.propTypes = {
    children: arrayOf(element).isRequired,
    buttonClass: string,
    label: string,
    history: shape({ push: func }).isRequired,
    isCollapsed: bool.isRequired,
    image: string,
};

DropdownMenuSection.defaultProps = {
    buttonClass: null,
    label: null,
    image: "",
};

export default withRouter(DropdownMenuSection);
