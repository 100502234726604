/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { func, shape, bool, number, string } from "prop-types";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";
import { selectors as sessionSelectors } from "reducers/session";

import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Reader from "pages/communications/_components/Reader";
import PageLoading from "pages/_components/PageLoading";
import ModalEnvironments from "pages/communications/_components/ModalEnvironments";
import WrongEnvironmentModal from "./_components/WrongEnvironmentModal";

import ModalMessage from "./_components/ModalMessage";

class ReadMessage extends Component {
    state = {
        modalOpen: false,
        deleteMsg: null,
        isPopupVisible: false,
        environment: "",
    };

    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isFetching: bool.isRequired,
        detail: shape({
            body: string,
            communicationTray: shape({
                name: string,
            }),
            idCommunication: number,
            subject: string,
            sentDateAsString: string,
        }).isRequired,
        isWrongEnvironmentModalVisible: bool.isRequired,
        correctEnvironmentName: string.isRequired,
        activeEnvironment: string.isRequired,
    };

    componentWillMount() {
        window.showPopup = (idTransaction, idEnvironment, environment) => {
            const { dispatch, activeEnvironment } = this.props;
            if (idEnvironment === activeEnvironment.id) {
                dispatch(routerActions.push(`/transaction/${idTransaction}`));
            } else {
                this.setState({ isPopupVisible: true, environment });
            }
        };
    }

    componentDidMount() {
        const { dispatch, ...rest } = this.props;

        dispatch(communicationActions.detailRequest(rest.match.params.id));
    }

    closeModal = () => {
        const { dispatch } = this.props;
        dispatch(communicationActions.hideWrongEnvironmentModal());
    };

    isModalVisible = (e, state) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        this.setState({ modalOpen: state });
    };

    handleModalDelete = (deleteFunction) => {
        this.setState({ deleteMsg: deleteFunction });
    };

    render() {
        const { isDesktop, detail, isFetching, isWrongEnvironmentModalVisible, correctEnvironmentName } = this.props;
        const { modalOpen, deleteMsg, isPopupVisible, environment } = this.state;

        return (
            <>
                <PageLoading loading={isFetching}>
                    <Notification scopeToShow="communicationsRead" />
                    {isDesktop
                        ? detail && <Head titleText={detail[0].subject} closeLinkTo="/communications" />
                        : detail && (
                              <Head
                                  titleText={detail[0].subject}
                                  backLinkTo="/communications"
                                  emptyRightOption
                                  titleClassName="header-mobile-title-background-blue"
                                  headerClassName="blue-main-header-mobile"
                              />
                          )}
                    <MainContainer>
                        <Reader
                            isDesktop={isDesktop}
                            isModalVisible={this.isModalVisible}
                            handleModalDelete={this.handleModalDelete}
                        />
                        <WrongEnvironmentModal
                            isVisible={isWrongEnvironmentModalVisible}
                            isDesktop={isDesktop}
                            closeModal={this.closeModal}
                            environment={correctEnvironmentName}
                        />
                        <ModalEnvironments
                            environment={environment}
                            isVisible={isPopupVisible}
                            submitClick={() => {
                                this.setState({ isPopupVisible: false });
                            }}
                        />
                    </MainContainer>
                    <ModalMessage
                        isVisible={modalOpen}
                        submitClick={deleteMsg}
                        cancelClick={(e) => this.isModalVisible(e)}
                    />
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    detail: communicationSelectors.detail(state),
    isFetching: communicationSelectors.isFetching(state),
    isWrongEnvironmentModalVisible: communicationSelectors.isWrongEnvironmentModalVisible(state),
    correctEnvironmentName: communicationSelectors.correctEnvironmentName(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(ReadMessage);
