import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import moment from "moment";
import { goBack } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";
import { func, shape, bool } from "prop-types";
import { push } from "react-router-redux/actions";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import Date from "pages/_components/fields/DateField";
import PageLoading from "pages/_components/PageLoading";
import MainContainer from "pages/_components/MainContainer";
import FixedTermAcceptanceTable from "pages/fixedTermAcceptance/_components/FixedTermAcceptanceTable";
import * as i18n from "util/i18n";
import ContextMenu from "pages/_components/ContextMenu";
import { allStatus } from "util/debin";
import Yup from "yup";
import * as configUtils from "util/config";

const FORM_ID = "fixedTermAcceptance.list";

class FixedTermAcceptanceList extends Component {
    state = {
        selectedDateFrom: null,
        selectedDateTo: null,
    };

    static propTypes = {
        dispatch: func.isRequired,
        dateFrom: shape({}).isRequired,
        dateTo: shape({}).isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        values: shape({}).isRequired,
        isSmallDesktop: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, values } = this.props;
        const { status, dateFrom, dateTo, recipient, tipo } = values;

        this.setState({ selectedDateFrom: dateFrom });
        this.setState({ selectedDateTo: dateTo });

        dispatch(
            debinActions.loadIncomingRequests({
                status,
                dateFrom,
                dateTo,
                recipient,
                tipo,
                pageNumber: 1,
            }),
        );
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    rightContent = () => {
        const { dispatch, isDesktop } = this.props;

        if (isDesktop) {
            return (
                <Button
                    className="btn-outline mb-2"
                    block
                    onClick={() => dispatch(push("/payments/recurrent"))}
                    label="fixedTermAcceptance.buyerRecurrences.button"
                />
            );
        }

        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName={`align-self-center account-dropdown-header-font ${
                    !isDesktop ? "font-black-alpha" : ""
                }`}
                items={[
                    {
                        label: "fixedTermAcceptance.buyerRecurrences.button",
                        onClick: () => {
                            dispatch(push("/payments/payDebin"))
                            dispatch(debinActions.resetFilters());
                        },
                    },
                ]}
            />
        );
    };

    renderEmptyState = () => {};

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    handleChangeDateTo = (selectedDate) => {
        this.setState({ selectedDateTo: selectedDate });
    };

    getPreviousDate = (days) => {
        const currentDate = new Date();
        const myPastDate = new Date(currentDate);
        myPastDate.setDate(myPastDate.getDate() - days);
        return myPastDate;
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0 align-center">{i18n.get("fixedTermAcceptance.list.title")}</h1>
        </div>
    );

    render() {
        const { dateFrom, dateTo, fetching, isDesktop, isSmallDesktop } = this.props;
        const { selectedDateFrom, selectedDateTo } = this.state;
        const defaultOptionStatus = { value: "Todos", label: "TODOS " };

        return (
            <>
                <Notification scopeToShow="debin" />
                <div className="admin-detail-head px-0" style={!isDesktop ? { marginBottom: 0 } : {}}>
                    {isDesktop && <Head onBack={this.handleBack} />}
                    <Head
                        title={isDesktop ? "fixedTermAcceptance.list.title" : null}
                        rightContent={this.rightContent}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        onBack={!isDesktop ? this.handleBack : null}
                        centerContentClassName="p-0"
                    />
                </div>
                <Form autocomplete="off">
                    <Container
                        className={`flex-grow align-items-center container-white with-margin ${
                            isDesktop ? "py-3" : "py-0"
                        }`}
                        gridClassName="form-content"
                        rowClassName={isSmallDesktop ? "justify-content-center" : "justify-content-start"}>
                        <Col sm={2} md={3}>
                            <Field
                                component={TextField}
                                idForm={FORM_ID}
                                name="recipient"
                                type="number"
                                pattern="\d*"
                                maxLength={configUtils.get("cuit.validation.character.amount")}
                                minLength={configUtils.get("cuit.validation.character.amount")}
                                className="py-25"
                            />
                        </Col>
                        {isDesktop && (
                            <>
                                <Col sm={2}>
                                    <Field
                                        component={Selector}
                                        options={[defaultOptionStatus, ...allStatus]}
                                        idForm={FORM_ID}
                                        name="status"
                                        isRequired
                                        hidePlaceholder
                                        className="py-25"
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Field
                                        idField="dateFrom"
                                        component={Date}
                                        endDate={dateTo}
                                        idForm={FORM_ID}
                                        name="dateFrom"
                                        hidePlaceholder
                                        selectsStart
                                        minDate={dateFrom}
                                        maxDate={selectedDateTo}
                                        handleChange={this.handleChangeDateFrom}
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Field
                                        idField="dateTo"
                                        component={Date}
                                        endDate={dateTo}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="dateTo"
                                        selectsEnd
                                        handleChange={this.handleChangeDateTo}
                                        minDate={selectedDateFrom}
                                    />
                                </Col>
                                <Col sm={isSmallDesktop ? 3 : 2}>
                                    <div className="form-group mt-4">
                                        <Button
                                            label="fixedTermAcceptance.list.button.search"
                                            bsStyle="primary"
                                            type="submit"
                                            className="mt-25"
                                        />
                                    </div>
                                </Col>
                            </>
                        )}
                    </Container>
                </Form>
                <PageLoading loading={fetching}>
                    <MainContainer showLoader={false} showChildrenWithLoader>
                        <FixedTermAcceptanceTable isDesktop={isDesktop} />
                    </MainContainer>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    sentRequests: debinSelectors.getSentRequests(state),
    fetching: debinSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            dateTo: moment(),
            dateFrom: moment().add(-1, "weeks"),
            status: "INICIADO",
            tipo: "debinplf",
        }),
        validationSchema: () =>
            Yup.object().shape({
                recipient: Yup.string()
                    .nullable()
                    .min(
                        configUtils.get("cuit.validation.character.amount"),
                        i18n.get(`charges.debin.sentRequests.recipient.invalid`),
                    )
                    .max(
                        configUtils.get("cuit.validation.character.amount"),
                        i18n.get(`charges.debin.sentRequests.recipient.invalid`),
                    ),
                dateFrom: Yup.string()
                    .nullable()
                    .required(i18n.get("administration.restrictions.field.error.empty")),
                dateTo: Yup.string()
                    .nullable()
                    .required(i18n.get("administration.restrictions.field.error.empty")),
            }),
        handleSubmit: (values, formikBag) => {
            const { dispatch } = formikBag.props;
            const { status, dateFrom, dateTo, recipient, tipo } = values;
            dispatch(
                debinActions.loadIncomingRequests({
                    status,
                    dateFrom,
                    dateTo,
                    recipient,
                    tipo,
                    pageNumber: 1,
                }),
            );
        },
    }),
)(FixedTermAcceptanceList);
