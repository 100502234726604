import React from "react";
import { func, node, string, oneOfType } from "prop-types";

class TableRow extends React.Component {
    static propTypes = {
        children: node.isRequired,
        renderAs: oneOfType([func, string]),
        className: string,
    };

    static defaultProps = {
        renderAs: "div",
        className: "",
    };

    render() {
        const { children, renderAs: Component, className, ...props } = this.props;
        return (
            <Component className={`table-row ${className}`} {...props}>
                {children}
            </Component>
        );
    }
}

export default TableRow;
