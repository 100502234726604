import React, { Component, Fragment } from "react";
import FocusTrap from "focus-trap-react";
import { Spring, config } from "react-spring";
import { func, bool, node, string } from "prop-types";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";

class Modal extends Component {
    static propTypes = {
        fromY: string,
        isModalDisplayed: bool,
        children: node.isRequired,
        title: string,
        hasDismissButton: bool,
        handleDismiss: func,
    };

    static defaultProps = {
        fromY: "1000px",
        isModalDisplayed: false,
        title: null,
        hasDismissButton: false,
        handleDismiss: null,
    };

    onDismiss = () => {
        const { handleDismiss } = this.props;

        if (typeof handleDismiss === "function") {
            handleDismiss();
        }
    };

    render() {
        const { fromY, isModalDisplayed, children, title, hasDismissButton } = this.props;

        return (
            <Fragment>
                <Spring
                    config={config.stiff}
                    from={{ opacity: 0, transform: `translate3d(0px,${fromY},0px)` }}
                    to={{
                        opacity: isModalDisplayed ? 1 : 0,
                        transform: isModalDisplayed ? "translate3d(0px,0px,0px)" : `translate3d(0px,${fromY},0px)`,
                    }}>
                    {(props) => (
                        <div style={props} className="new-modal-wrapper container">
                            <div className="new-modal">
                                {isModalDisplayed && (
                                    <FocusTrap
                                        focusTrapOptions={{
                                            onDeactivate: this.onDismiss,
                                        }}>
                                        <div>
                                            {(title || hasDismissButton) && (
                                                <header className="new-modal-header">
                                                    <h4 className="new-modal-title">{title}</h4>
                                                    {hasDismissButton && (
                                                        <Button
                                                            block={false}
                                                            bsStyle="link"
                                                            image="images/cross.svg"
                                                            label="global.close"
                                                            onClick={() => {
                                                                this.onDismiss();
                                                            }}
                                                        />
                                                    )}
                                                </header>
                                            )}
                                            <Container className="container--layout align-items-center">
                                                <Container.Column sm={12} md={12} lg={10} className="col-12">
                                                    {children}
                                                </Container.Column>
                                            </Container>
                                        </div>
                                    </FocusTrap>
                                )}
                            </div>
                        </div>
                    )}
                </Spring>
                <Spring
                    config={config.stiff}
                    from={{ opacity: 0, pointerEvents: "none" }}
                    to={{
                        opacity: isModalDisplayed ? 1 : 0,
                        pointerEvents: isModalDisplayed ? "all" : "none",
                    }}>
                    {(props) => <div className="new-modal-overlay" style={props} />}
                </Spring>
            </Fragment>
        );
    }
}

export default Modal;
