import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { push } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";

class ExpiredByMaxAtemps extends Component {
    send = (event) => {
        this.props.dispatch(push("/"));
    };

    getContent = () => (
        <div className="above-the-fold login-desktop-wrapper">
            <Container className="align-items-center container--layout">
                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                    <p className="text-lead">
                        <I18n id="session.expiredByMaxAtemps.title" />
                    </p>
                </Col>
                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                    <Button
                        label="session.expiredByMaxAtemps.loginAgain"
                        loading={false}
                        onClick={this.send}
                        type="button"
                        bsStyle="primary"
                    />
                </Col>
            </Container>
        </div>
    );

    render() {
        const mainContainerClass = classNames({
            "main-container": true,
            "container-fluid": this.props.isDesktop,
        });

        return (
            <Fragment>
                <MainContainer className={mainContainerClass}>{this.getContent()}</MainContainer>
            </Fragment>
        );
    }
}

export default connect(null)(ExpiredByMaxAtemps);
