import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isSameDay, isPast } from "date-fns";
import isEmpty from "lodash/isEmpty";

import * as dateUtils from "util/date";
import * as schedulerUtils from "util/scheduler";

import Message from "pages/forms/_components/_fields/_scheduler/Message";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Image from "pages/_components/Image";
import FieldError from "pages/_components/fields/FieldError";
import I18n from "pages/_components/I18n";

function FundScheduler(props) {
    const {
        data: { mode, programable, schedulable, firstWorkingDate },
        field: { value },
        isDesktop,
        showShedulerMessage,
        disabled,
        label,
        field,
        form,
        children,
        FORM_ID,
    } = props;

    const [errors, setErrors] = useState(null);
    const [isDisplayed, setIsDisplayed] = useState(false);

    const getDefaultValueDate = () => moment(firstWorkingDate, "YYYY-MM-DD");

    const updatePastDate = () => {
        if (value && value.valueDate) {
            const currentDate = new Date();
            if (isPast(value.valueDate) && !isSameDay(currentDate.getTime(), value.valueDate)) {
                form.values.scheduler.valueDate = new Date();
            }
        }
    };
    // on initial rendering
    useEffect(() => {
        if (field.value === null) {
            form.setFieldValue(field.name, {
                selectedOption: schedulerUtils.TODAY,
                valueDate: getDefaultValueDate(),
            });
        }

        // eslint-disable-next-line
    }, []);

    // on props change
    useEffect(() => {
        const programErrors =
            errors &&
            Object.keys(errors)
                .filter((key) => key.includes("program@"))
                .reduce(
                    (obj, key) => ({
                        ...obj,
                        [key]: errors[key],
                    }),
                    {},
                );
        setErrors(programErrors);
    }, [errors]);

    const handleClick = () => {
        setIsDisplayed(true);
    };

    const handleErrors = () => {
        if (!isEmpty(errors)) {
            return (
                <div className="form-group has-error">
                    {Object.keys(errors).map((error) => (
                        <div className="form-group has-error">
                            <FieldError error={errors[error]} />
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    const handleCloseBottomSheetClick = () => {
        setIsDisplayed(false);
    };

    const handleValueChange = (data) => {
        if (data.frequency === schedulerUtils.ONCE) {
            form.setFieldValue(field.name, {
                ...field.value,
                selectedOption: data.frequency,
                valueDate: moment(data.startsOn),
                program: null,
            });
        } else {
            const newProgramData = {
                // one of DAY, WEEK, MONTH, YEAR
                frequency: data.frequency === schedulerUtils.CUSTOM ? data.customFreq : data.frequency,
                // int that represents the frequency, frequency = DAY +  frequencyValue = 2 => every 2 days
                frequencyValue: data.frequency === schedulerUtils.CUSTOM ? data.customFreqValue : "1",
                // int that represents the days of the week used by the java class Days at the backend.
                // Same class used by the calendar restriction
                days: dateUtils.getDayFromDate(data.startsOn),
                // day of the month.
                day: data.startsOn.getDate(),
                // occurrence=3 + days=4 every 3rd WEDNESDAY of the month
                occurrence: null,
                startsOn: data.startsOn,
                lapse: data.lapse.lapse, // no change at all
                number: data.lapse.number,
                date: data.lapse.date,
                isCustom: data.frequency === schedulerUtils.CUSTOM,
            };
            if (newProgramData.frequency === schedulerUtils.WEEK && newProgramData.isCustom) {
                // if it's custom, I take the days from the bitwisedays control
                newProgramData.days = data.days;
            }
            if (newProgramData.frequency === schedulerUtils.MONTH && data.occurrenceType === "occurrence") {
                newProgramData.occurrence = dateUtils.weekOfMonth(moment(data.startsOn));
                newProgramData.day = null;
            }
            form.setFieldValue(field.name, {
                ...field.value,
                selectedOption: newProgramData.frequency,
                program: newProgramData,
            });
        }
        form.setTouched({ ...form.touched, [field.name]: true });
        handleCloseBottomSheetClick();
    };

    updatePastDate(value);

    if ((programable || schedulable) && mode === "edit") {
        return (
            <>
                {children({
                    // inyected props
                    ...props,
                    idForm: FORM_ID,
                    handleCloseBottomSheetClick,
                    goBack: handleCloseBottomSheetClick,
                    onScheduleClick: handleValueChange,
                    value,
                    isDisplayed,
                    isDesktop,
                })}
                <FieldLabel labelKey="scheduler.label" idField="readableID" className="display" hideLabel notMarginY />
                <div className="form-group form-group--scheduler mb-0">
                    <div className="form-group-text scheduler mt-0">
                        <button
                            aria-haspopup="dialog"
                            className="btn btn-link btn-small btn-scheduler ml-0"
                            onClick={handleClick}
                            aria-expanded={isDisplayed}
                            type="button"
                            disabled={disabled}>
                            <Image src="images/calendar-plus.svg" className="svg-icon mr-2" />
                            {!value || value.selectedOption !== schedulerUtils.TODAY ? (
                                <>
                                    <I18n id={label} componentProps={{ "aria-hidden": true }} />
                                </>
                            ) : (
                                <>
                                    <I18n id="scheduler.schedule" componentProps={{ "aria-hidden": true }} />
                                    <I18n
                                        id="scheduler.schedule.a11y"
                                        componentProps={{ className: "visually-hidden" }}
                                    />
                                </>
                            )}
                        </button>
                        {!isEmpty(errors) && handleErrors()}
                    </div>
                    {showShedulerMessage && (
                        <span className="scheduler-message">
                            <Message FORM_ID={FORM_ID} value={value || { valueDate: "" }} showExecuteToday={false} />
                        </span>
                    )}
                </div>
            </>
        );
    }
    return null;
}

FundScheduler.propTypes = {
    data: PropTypes.shape({
        mode: PropTypes.string,
        programable: PropTypes.bool,
        schedulable: PropTypes.bool,
        firstWorkingDate: PropTypes.node,
    }).isRequired,
    field: PropTypes.shape({ value: PropTypes.node, name: PropTypes.string }).isRequired,
    form: PropTypes.node.isRequired,
    location: PropTypes.shape({}).isRequired,
    isDesktop: PropTypes.bool,
    showShedulerMessage: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    FORM_ID: PropTypes.string,
};

FundScheduler.defaultProps = {
    isDesktop: false,
    showShedulerMessage: true,
    disabled: false,
    FORM_ID: "scheduler",
};

export default FundScheduler;
