import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { push } from "react-router-redux";
import { Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as fundcorpSelectors, actions as fundcorpActions } from "reducers/fundcorp";
import * as configUtils from "util/config";

import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Button from "pages/_components/Button";
import useUpdateFormValue from "./hooks/useUpdateFormValue";
import InvestorProfileCard from "./_subscribe/InvestorProfileCard";

const COMPONENT_ID = "fundcorp.investorProfile";

function InvestorProfile({ activeInvestorProfile: {investorProfileType}, dispatch, isDesktop, setFieldValue }) {
    const [selectedId, setSelectedId] = useState("0");
    useUpdateFormValue("investorProfile", selectedId, setFieldValue);

    useEffect(() => {
        if (investorProfileType) {
            setSelectedId(investorProfileType)
        }
    }, [investorProfileType])
    
    const handleBack = () => {
        dispatch(push("/fundcorpOperations"));
    };

    const handleCardClick = (id) => {
        setSelectedId(id);
    };

    return (
        <>
            <Notification scopeToShow={COMPONENT_ID} />
            <div className="admin-detail-head px-0">
                {isDesktop ? (
                    <>
                        <Head onBack={handleBack} />
                        <Head
                            title={`${COMPONENT_ID}.title`}
                            imageStyle="mr-2"
                            navbarClassName="fundcorp__navbar-header"
                        />
                    </>
                ) : (
                    <>
                        <Head
                            onBack={handleBack}
                            title={`${COMPONENT_ID}.title`}
                            imageStyle="mr-2"
                            headerClassName="blue-main-header-mobile"
                        />
                    </>
                )}
            </div>
            <MainContainer>
                <Form autoComplete="off">
                    <div className="above-the-fold">
                        <div className="d-flex justify-content-center">
                            <div className={`d-flex flex-column p-3 ${isDesktop ? "f-size-55" : "f-size-65"}`}>
                                <I18n id="fundcorp.investorProfile.general.description.line.1" />
                                <I18n id="fundcorp.investorProfile.general.description.line.2" />
                            </div>
                        </div>
                        <Container
                            className="flex-grow align-items-center py-3 mb-4"
                            gridClassName="form-content card-container">
                            <Col className="col col-no-pad-mobile px-2 py-2">
                                <InvestorProfileCard
                                    id="0"
                                    img="images/coloredIcons/conservative.svg"
                                    title="Conservador"
                                    description={configUtils.get("funds.investor.profile.conservative")}
                                    isActive={selectedId === "0"}
                                    handleClick={handleCardClick}
                                    profileDescriptionFeatures={configUtils.getArray(
                                        "funds.investor.profile.conservative.features",
                                    )}
                                    isDesktop={isDesktop}
                                />
                            </Col>

                            <Col className="col col-no-pad-mobile px-2 py-2">
                                <InvestorProfileCard
                                    id="1"
                                    img="images/coloredIcons/moderate.svg"
                                    title="Moderado"
                                    description={configUtils.get("funds.investor.profile.moderate")}
                                    isActive={selectedId === "1"}
                                    handleClick={handleCardClick}
                                    profileDescriptionFeatures={configUtils.getArray(
                                        "funds.investor.profile.moderate.features",
                                    )}
                                    isDesktop={isDesktop}
                                />
                            </Col>
                            <Col className="col col-no-pad-mobile px-2 py-2">
                                <InvestorProfileCard
                                    id="2"
                                    img="images/coloredIcons/aggressive.svg"
                                    title="Agresivo"
                                    description={configUtils.get("funds.investor.profile.aggressive")}
                                    isActive={selectedId === "2"}
                                    handleClick={handleCardClick}
                                    profileDescriptionFeatures={configUtils.getArray(
                                        "funds.investor.profile.aggressive.features",
                                    )}
                                    isDesktop={isDesktop}
                                />
                            </Col>
                        </Container>
                    </div>
                    <Container className="flex-grow align-items-center my-2" gridClassName="form-content">
                        <Col lg={4} md={6} sm={12}>
                            <Button type="submit" bsStyle="primary" label="global.confirm" />
                        </Col>
                    </Container>
                </Form>
            </MainContainer>
        </>
    );
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    shareAccounts: fundcorpSelectors.getShareAccounts(state),
    activeInvestorProfile: fundcorpSelectors.getInvestorProfile(state),
});

InvestorProfile.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        handleSubmit: ({ investorProfile }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(fundcorpActions.updateInvestorProfileRequest(investorProfile));
        },
    }),
)(InvestorProfile);
