import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { string, bool, func, shape } from "prop-types";

import { actions as assistantActions, selectors as assistantSelectors } from "reducers/assistant";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class LoginAssistantStepContent extends Component {
    static propTypes = {
        params: shape({
            redirect_uri: string,
            state: string,
        }).isRequired,
        _accessToken: string.isRequired,
        currentAssistant: string.isRequired,
        messengerPSID: string.isRequired,
        clientNumber: string.isRequired,
        isFromMessenger: bool.isRequired,
        isFromWhatsapp: bool.isRequired,
        dispatch: func.isRequired,
    };

    redirect = () => {
        const {
            dispatch,
            params,
            _accessToken,
            isFromMessenger,
            isFromWhatsapp,
            messengerPSID,
            clientNumber,
        } = this.props;
        let redirectUrl = decodeURIComponent(params.redirect_uri);
        if (isFromMessenger) {
            redirectUrl += `&authorization_code=${_accessToken}`;
        } else if (isFromWhatsapp) {
            redirectUrl += `?code=${_accessToken}&client_number=${clientNumber}`;
        } else {
            redirectUrl += `#access_token=${_accessToken}&token_type=bearer&state=${params.state}`;
        }
        window.location = redirectUrl;

        if (isFromMessenger) {
            dispatch(assistantActions.sendMessageToMessenger(messengerPSID, "account_linking_success"));
        }
    };

    render() {
        const { currentAssistant } = this.props;

        return (
            <Fragment>
                <form className="above-the-fold login-desktop-wrapper">
                    <section className="container--layout flex-grow align-items-center">
                        <div className="container">
                            <div className="justify-content-center row">
                                <div xl="6" className="col col-12">
                                    <div className="media-object">
                                        <figure>
                                            <Image src="images/assistants-big.svg" className="svg-icon" />
                                        </figure>
                                    </div>

                                    <p className="text-lead">
                                        <I18n
                                            id="assistant.account.link.finish.subtitle"
                                            ASSISTANT={currentAssistant}
                                        />
                                    </p>
                                    <ul className="normal-list">
                                        <li>
                                            <I18n id="assistant.account.link.info1" />
                                        </li>
                                        <li>
                                            <I18n id="assistant.account.link.info2" />
                                        </li>
                                        <li>
                                            <I18n id="assistant.account.link.info3" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="align-items-center container--layout">
                        <div className="container">
                            <div className="justify-content-center row">
                                <div className="col col-12">
                                    <Button className="btn-primary" label="global.continue" onClick={this.redirect} />
                                </div>
                            </div>
                        </div>
                    </section>
                </form>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    _accessToken: assistantSelectors.getAccessToken(state),
    accountLinkingToken: assistantSelectors.getAccountLinkingToken(state),
    currentAssistant: assistantSelectors.getCurrentAssistant(state),
    isAssistantLogin: assistantSelectors.isAssistantLogin(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
    clientNumber: assistantSelectors.getClientNumber(state),
    params: assistantSelectors.getParams(state),
    messengerPSID: assistantSelectors.getMessengerPSID(state),
});

export default connect(mapStateToProps)(LoginAssistantStepContent);
