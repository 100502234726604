import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { func, arrayOf, shape, bool, string } from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";
import Yup from "yup";

import { actions, selectors } from "reducers/administration/usersInvite";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import Selector from "pages/_components/fields/formik/Selector";
import MultiSelectField from "pages/_components/fields/MultiSelectField";

const FORM_ID = "administration.users.invite";

class GroupSignature extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        setFieldValue: func.isRequired,
        isSubmitting: bool.isRequired,
        isValid: bool.isRequired,
        signatureLevels: arrayOf(shape({ id: string, label: string })),
        activeEnvironment: shape({ administrationScheme: string }).isRequired,
        roleList: arrayOf(shape({ id: string, label: string })).isRequired,
        groupList: arrayOf(shape({ idGroupAsString: string, name: string })).isRequired,
        selectedDocument: shape({ country: string, document: string, type: string }).isRequired,
    };

    static defaultProps = {
        signatureLevels: [],
    };

    componentDidMount() {
        const { setFieldValue } = this.props;
        setFieldValue("signatureLevel", "N");
    }

    componentDidUpdate(prevProps) {
        const { dispatch, isSubmitting, isValid } = this.props;

        if (prevProps.isSubmitting && isSubmitting !== prevProps.isSubmitting && !isValid) {
            dispatch(
                notificationActions.showNotification(i18nUtils.get("forms.fieldsErrors"), "error", [
                    "administrationUserInvite",
                ]),
            );
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    renderMediumSchemeConfiguration = () => {
        const { roleList } = this.props;
        const transactionSignatureOptions = [
            { id: "yes", label: i18nUtils.get("administration.users.invite.transaction.signature.yes") },
            { id: "no", label: i18nUtils.get("administration.users.invite.transaction.signature.no") },
        ];

        return (
            <>
                <Col className="col col-12 col-md-10">
                    <I18n
                        component="h2"
                        componentProps={{ className: "text-center" }}
                        id="administration.users.invite.initial.configuration.subtitle"
                    />
                </Col>
                <Col className="col col-12 px-xl-0 inputs-invite-user" md={9} lg={6}>
                    <Col className="col col-12 px-0">
                        <div className="form-group form-group--select mb-4">
                            <div className="form-group-text">
                                {/* eslint-disable-next-line */}
                                <label
                                    className="control-label"
                                    htmlFor="administration.users.invite.transaction.signature">
                                    <I18n id="administration.users.invite.transaction.signature" />
                                </label>
                            </div>
                            <Field
                                component={Selector}
                                options={transactionSignatureOptions}
                                idForm={FORM_ID}
                                name="signatureLevel"
                                renderAs="radio"
                                inLineControl
                            />
                        </div>
                    </Col>
                    <Col className="col col-12 px-0">
                        <Field
                            component={Selector}
                            hidePlaceholder
                            options={
                                roleList &&
                                roleList.map((c) => ({
                                    value: c.id,
                                    label: c.label,
                                }))
                            }
                            idForm={FORM_ID}
                            name="role"
                            classNameForViewMode="data-desc"
                            searchable
                        />
                    </Col>
                </Col>
            </>
        );
    };

    renderAdvancedSchemeConfiguration = () => {
        const { groupList, signatureLevels } = this.props;

        const groupOptionsMap = new Map();
        groupList.forEach((group) => {
            groupOptionsMap.set(group.idGroupAsString, group.name);
        });
        const groupOptions = [...groupOptionsMap.keys()];

        return (
            <>
                <Col className="col col-12">
                    <I18n
                        component="h2"
                        componentProps={{ className: "text-center mt-4" }}
                        id="administration.users.invite.initial.configuration.subtitle"
                    />
                </Col>
                <Col className="col col-12 px-xl-0 inputs-invite-user" md={9} lg={6}>
                    <div className="form-group form-group--select mb-4">
                        <div className="form-group-text">
                            {/* eslint-disable-next-line */}
                            <label className="control-label" htmlFor="administration.users.edit.signatureLevel">
                                <I18n id="administration.users.edit.signatureLevel" />
                            </label>
                        </div>
                        <Field
                            component={Selector}
                            options={signatureLevels}
                            idForm={FORM_ID}
                            name="signatureLevel"
                            renderAs="radio"
                            inLineControl
                        />
                    </div>

                    <div className="form-group-text">
                        {/* eslint-disable-next-line */}
                        <label className="control-label" htmlFor="administration.users.edit.groups">
                            <I18n id="administration.users.edit.groups" />
                        </label>
                    </div>
                    <Field
                        component={MultiSelectField}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="groups"
                        options={groupOptions}
                        hideLabel
                        textOptionsMap={groupOptionsMap}
                    />
                </Col>
            </>
        );
    };

    render() {
        const {
            activeEnvironment: { administrationScheme },
            selectedDocument,
            isSubmitting,
        } = this.props;

        if (!selectedDocument) {
            return <Redirect to="/administration/users/invite" />;
        }

        return (
            <>
                <Notification scopeToShow="administrationUserInvite" />
                <div className="admin-detail-head admin-detail-head-section px-0 rigth-position-desktop">
                    <Head onBack={this.handleBack} closeLinkTo="/administration/users" />
                    <div className="mb-4">
                        <I18n
                            component="h1"
                            componentProps={{ className: "view-title" }}
                            id="administration.users.invite.title"
                        />
                    </div>
                </div>

                <MainContainer viewContentClassName="pt-0" className="main-container administration" showLoader={false}>
                    <Form className="above-the-fold">
                        <Container
                            className="container--layout container-invite-user container-user-invite-signature"
                            gridClassName="form-content">
                            {administrationScheme === "medium"
                                ? this.renderMediumSchemeConfiguration()
                                : this.renderAdvancedSchemeConfiguration()}
                        </Container>
                        <Container className="container--layout" gridClassName="form-content">
                            <Col className="col col-12 px-0 inputs-invite-user" md={9} lg={6}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    roleList: selectors.getRoleList(state),
    groupList: selectors.getGroupList(state),
    encryptedDocument: selectors.getEncryptedDocument(state),
    selectedDocument: selectors.getSelectedDocument(state),
    isInvitingNewUser: selectors.isInvitingNewUser(state),
    existentUserInfo: selectors.getExistentUserInfo(state),
    userFinalInformation: selectors.getUserFinalInformation(state),
    signatureLevels: configUtils
        .getArray("administration.signatures.signatureLevels")
        .map((value) => ({
            id: value,
            label: value,
        }))
        .concat({
            id: "N",
            label: i18nUtils.get("administration.users.edit.signatureLevel.dontSign"),
        }),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            role: "login",
            signatureLevel: "",
            groups: "",
        }),

        validationSchema: (props) => {
            const {
                activeEnvironment: { administrationScheme },
            } = props;
            return Yup.object().shape({
                role:
                    administrationScheme === "medium"
                        ? Yup.string().required(i18nUtils.get("administration.users.emptyRole"))
                        : Yup.string().notRequired(),
                groups:
                    administrationScheme === "advanced"
                        ? Yup.string().required(i18nUtils.get("administration.users.mustSelectGroup"))
                        : Yup.string().notRequired(),
                signatureLevel: Yup.string().required(i18nUtils.get("administration.users.emptySignatureLevel")),
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                dispatch,
                userFinalInformation,
                groupList,
                activeEnvironment: { administrationScheme },
            } = formikBag.props;
            let { signatureLevel } = data;
            if (administrationScheme === "medium") {
                signatureLevel = data.signatureLevel === "no" ? "N" : "A";
            }
            const groupNames = groupList
                .filter((group) => data.groups.indexOf(group.idGroupAsString) > -1)
                .map((x) => x.name);

            const params = {
                ...data,
                firstName: userFinalInformation.firstName,
                lastName: userFinalInformation.lastName,
                email: userFinalInformation.email,
                mobilePhone: userFinalInformation.mobilePhone,
                country: "Argentina",
                province: userFinalInformation.province,
                localidad: userFinalInformation.localidad,
                address: userFinalInformation.address,
                number: userFinalInformation.number,
                postalCode: userFinalInformation.postalCode,
                birthdate: userFinalInformation.birthdate,

                document: userFinalInformation.document,
                docType: userFinalInformation.docType,
                docNumber: userFinalInformation.docNumber,
                signatureLevel,
                groupNames,
            };

            dispatch(actions.userInvitePreview(params, formikBag));
        },
    }),
)(GroupSignature);
