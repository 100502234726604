import React, { Component } from "react";
import { func, bool, shape } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Credential from "pages/_components/fields/credentials/Credential";
import Yup from "yup";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import { Field, Form, withFormik } from "formik";

import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";

import PayPaymentAFIPSummaryData from "pages/servicePayments/_components/PaymentAFIPSummaryData";

import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import isTokenActive from "util/token";

const FORM_ID = "pay.paymentAFIP.summary";

class PayPaymentAFIPSummary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        summary: shape({}).isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push(`/payPaymentAFIP`));
    };

    centerContentMobile = () => {
        const { isDesktop, summary } = this.props;

        const { paymentAFIP } = summary;

        const numberVEP = summary ? Number(paymentAFIP.numberVEP) : undefined;

        if (!isDesktop) {
            return (
                <div className="title-account-header-multiline-background-blue">
                    <h1>{i18n.get(`${FORM_ID}.label`, null, { numberVEP })}</h1>
                </div>
            );
        }

        return (
            <div className="view-title" style={{ display: "flex" }}>
                <h1>{i18n.get(`${FORM_ID}.label`, null, { numberVEP })}</h1>
            </div>
        );
    };

    render() {
        const { isDesktop, isSubmitting, summary } = this.props;

        const { paymentAFIP } = summary;

        const { numberVEP } = paymentAFIP;

        const continueButton = (
            <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                <div className="admin-content-center">
                    <Button
                        type="submit"
                        bsStyle="primary"
                        label="global.confirm"
                        image="images/arrowRight.svg"
                        imageStyle={isDesktop ? "mr-2" : "mr-1"}
                        loading={isSubmitting}
                        className="text-capitalize"
                    />
                </div>
            </Col>
        );

        const credentials = (
            <>
                {!isTokenActive(!isDesktop) ? (
                    <div className="admin-content-center VEP-confirmation-label my-4">
                        <I18n id="paymentAFIP.summary.confirm.label" />
                    </div>
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}
                <div className="admin-content-center VEP-confirmation-input">
                    <Field
                        placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </>
        );

        return (
            <>
                <Notification scopeToShow="payPaymentAFIPSummary" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        onClose={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && <h1>{i18n.get(`${FORM_ID}.label`, null, { numberVEP: Number(numberVEP) })}</h1>}
                </div>
                <MainContainer>
                    <Form className="above-the-fold">
                        <Container
                            className={`flex-grow align-items-center container-white  ${
                                !isDesktop ? "mb-0" : "with-margin"
                            }`}
                            gridClassName="form-content">
                            <div
                                className={`admin-content-center ${!isDesktop && "mx-3"}`}
                                style={{ marginBottom: isDesktop ? "2rem" : "1rem" }}>
                                <I18n id={`${FORM_ID}.checkInfo.label`} component="h2" />
                            </div>

                            <Col className="paypayment-afip-summary col-lg-4 col-md-9 col-sm-12 px-lg-2">
                                <PayPaymentAFIPSummaryData isDesktop={isDesktop} isAPay summary={summary} />
                                {credentials}
                                {continueButton}
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: paymentsAFIPSelectors.getSummary(state),
    userId: sessionSelectors.getUser(state).userId,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, summary } = formikBag.props;

            dispatch(paymentsAFIPActions.payPaymentAFIP({ ...summary, formikBag }, otp));
        },
    }),
)(PayPaymentAFIPSummary);
