import React from "react";
import { Modal } from "react-bootstrap";
import { bool, func } from "prop-types";
import classNames from "classnames";

import Head from "pages/_components/Head";
import FundFeaturesTable from "./FundFeaturesTable";

function FundListModal({ isVisible, isDesktop, closeModal, onRowClick }) {
    return (
        <Modal
            show={isVisible}
            className={isDesktop ? "transfer-template-modal" : "transfer-frequent-destination-modal px-0"}
            dialogClassName={classNames({ "h-100": !isDesktop })}
            onHide={closeModal}>
            {isDesktop ? (
                <Modal.Header closeButton className="no-title min-height-3" />
            ) : (
                <Head
                    onClose={closeModal}
                    hideMobileMenu
                    closeImageWhite
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                    title={!isDesktop ? "fund.operation.select.head.label" : undefined}
                    titleClassName="title-content text-white header-mobile-title-background-blue mb-0"
                    accessibilityTextId="fund.operation.select.head.label"
                    navbarClassName="py-2"
                />
            )}
            <Modal.Body className="mt-0 pb-2 w-100 h-100 show">
                <div className="w-100 flex-column">
                    <FundFeaturesTable onRowClick={onRowClick} isDesktop={isDesktop} />
                </div>
            </Modal.Body>
        </Modal>
    );
}

FundListModal.propTypes = {
    isVisible: bool.isRequired,
    onRowClick: func.isRequired,
    closeModal: func.isRequired,
    isDesktop: bool.isRequired,
};

export default FundListModal;
