/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { push, replace } from "react-router-redux";
import moment from "moment";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import TADWarningModal from "pages/v2/transfer/TADWarningModal";

import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { actions as formActions } from "reducers/form";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { actions as templateActions, selectors as templateSelectors } from "reducers/template";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as holidaysActions } from "reducers/holidays";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import * as schedulerUtils from "util/scheduler";
import { saveLocation, updateSchedulerToSend } from "util/transaction";

import { saveDraft } from "middleware/form";
import {
    Box,
    Button,
    Col,
    Container,
    Grid,
    Tab,
    Tabs,
    Text,
    ThemeProvider,
    ThemeProvider2,
} from "@ui-kit/components/index.es";
import * as dateUtils from "util/date";
import TemplateDrawer from "./TemplateDrawer";
import TransferInternalForm from "./TransferInternalForm";
import TransferThirdPartiesForm from "./TransferThirdPartiesForm";
import SchedulerModal from "./scheduler/SchedulerModal";

const FORM_ID = "transfers";
const MIN_REQUIRED = "6";
const MAX_REQUIRED = "22";

function V2Transfer(props) {
    V2Transfer.propTypes = {
        fetching: bool.isRequired,
        dispatch: func.isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        internalTransfer: bool.isRequired,
        location: shape({
            pathname: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
        templates: {},
        idTransaction: string.isRequired,
        concepts: arrayOf(shape({})).isRequired,
        activeEnvironment: shape({
            permissions: shape({
                transferInternal: bool,
                transferThirdParties: bool,
            }),
        }).isRequired,
        isRetailEnvironment: bool.isRequired,
        isCVU: bool.isRequired,
        summary: shape({
            cbu: string.isRequired,
            email: string.isRequired,
            transferKind: string.isRequired,
        }).isRequired,
    };

    V2Transfer.defaultProps = {
        templates: null,
    };

    const {
        fetching,
        dispatch,
        match,
        accounts,
        internalTransfer,
        isDesktop,
        templates,
        concepts = [],
        location,
        activeEnvironment,
        isCVU,
    } = props;

    const [agendaVisible, setAgendaVisible] = useState(false);
    const [tadWarningPopupVisible, setTadWarningPopupVisible] = useState(false);
    const [tadWarningShowed, setTadWarningShowed] = useState(false);
    const [isTemplatesVisible, setIsTemplatesVisible] = useState(false);
    const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);

    const [values, setValues] = useState({
        debitAccount: undefined,
        creditAccount: undefined,
        amount: 0,
        currency: 0,
        sendMail: false,
    });
    const getActivityId = () => {
        const { internalTransfer } = props;
        return internalTransfer ? "transfers.internal.send" : "transfers.thirdParties.send";
    };

    const getActivityIdTad = () => {
        const { internalTransfer } = props;
        return internalTransfer ? "transfers.internal.tad.send" : "transfers.thirdParties.tad.send";
    };

    const getFormName = () => {
        const { internalTransfer, location } = props;
        if (location?.state) {
            return location?.state?.isInternal ? "transferInternal" : "transferThirdParties";
        }
        return internalTransfer ? "transferInternal" : "transferThirdParties";
    };

    const isTad = (amountWithCurrency) => {
        const { isRetailEnvironment, activeEnvironment, isCVU } = props;
        const value = amountWithCurrency.amount;
        const environmentTypeTag = isRetailEnvironment ? "Retail" : "Corporate";
        const currencyTag = amountWithCurrency.currency.toString() === "0" ? "Pesos" : "Dolares";
        const minAmount = configUtils.getInteger(`frontend.TAD.minAmount${currencyTag}.${environmentTypeTag}`, 100000);
        const maxAmount = configUtils.getInteger(`frontend.TAD.maxAmount${currencyTag}.${environmentTypeTag}`, 1000000);
        const enabledCorporate = configUtils.getBoolean("frontend.show.TAD.functionalities.Corporate", false);
        const enabledRetail = configUtils.getBoolean("frontend.show.TAD.functionalities.Retail", false);

        let tadEnabled = false;

        if (isCVU) {
            return false;
        }

        if (!isRetailEnvironment) {
            if (enabledCorporate) {
                tadEnabled = true;
            }
        } else if (enabledRetail) {
            tadEnabled = true;
        }

        if (tadEnabled && activeEnvironment?.permissions?.transferDiferredAccreditation) {
            if (value >= minAmount && value <= maxAmount) {
                return true;
            }
        }
        return false;
    };

    const parseAmount = (value) => {
        if (typeof value === "string") {
            return parseFloat(value.replaceAll(".", "").replace(",", "."));
        }
        return value;
    };

    const setHint = (value) => {
        const { amount } = values;

        const currency = value.currency || amount.currency;
        const isTadTrx = isTad({
            currency,
            amount: parseAmount(value.amount) || value,
        });
        if (isTadTrx) {
            setAgendaVisible(false);
            if (!tadWarningShowed) {
                setTadWarningPopupVisible(true);
                setTadWarningShowed(true);
            }
        } else {
            setTadWarningPopupVisible(false);
            setTadWarningShowed(false);
            setAgendaVisible(true);
        }
    };

    useEffect(() => {
        const { dispatch, location, summary } = props;
        const {
            amount: { amount },
            debitAccount: debitAccountDraft,
        } = values;
        if (location?.pathname === "/v2/transfer/transfer") {
            dispatch(transferActions.clearTransferData());
        }

        dispatch(transferActions.preActivity());
        dispatch(holidaysActions.listHolidays());

        const { cbu, email, transferKind, isFrequentDestinations } = summary;

        if (location?.pathname === "/v2/transfer/transfer") {
            saveLocation(props);
            if (location?.state) {
                const { isInternal, debitAccount } = location?.state;
                dispatch(transferActions.changeTransferGroup(isInternal));
                setValues({
                    ...values,
                    debitAccount,
                    creditAccount: "",
                    amount: shape({
                        amount: 0,
                        currency: "",
                    }),
                    cbuOrAlias: "",
                    reference: "",
                    email: "",
                    emailText: "",
                    concept: "VAR",
                    transferKind: "",
                });
                dispatch(transferActions.accountLimitsActivities(debitAccount, getActivityId()));
                dispatch(templateActions.loadTemplateList(getFormName()));
            } else {
                const { activeEnvironment } = props;
                const isInternalTrx = transferKind === "other" ? false : activeEnvironment.permissions.transferInternal;

                if (!isInternalTrx && isFrequentDestinations) {
                    if (cbu) {
                        setValues({
                            ...values,
                            cbuOrAlias: cbu,
                            email,
                            transferKind,
                        });
                    }
                } else if (email && isFrequentDestinations) {
                    setValues({
                        ...values,
                        email,
                    });
                }
                const idForm = isInternalTrx ? "transferInternal" : "transferThirdParties";
                dispatch(transferActions.changeTransferGroup(isInternalTrx));
                dispatch(templateActions.loadTemplateList(idForm));
            }
        } else {
            dispatch(templateActions.loadTemplateList(getFormName()));
            if (location?.pathname === "/transfer/modify" && debitAccountDraft) {
                dispatch(transferActions.accountLimitsActivities(debitAccountDraft, getActivityId()));
            }
        }
        if (amount > 0) {
            setHint(amount);
        }
    }, []);

    useEffect(() => {
        // const { values } = props;
        const { amount } = values;
        const { amount: amountValue } = amount;
        if (amountValue > 0) {
            setHint(amountValue);
        }
    }, [isCVU]);

    useEffect(() => {
        // const { values, dispatch } = props;
        const { dispatch } = props;
        const { sendEmail } = values;
        dispatch(transferActions.setSendEmail(sendEmail));
    }, [sendEmail]);

    useEffect(() => {
        changeTransferGroup(match.params.transferType === "internal");
    }, [match.params.transferType]);

    const handleBack = () => {
        const { dispatch, location } = props;
        if (location?.pathname === "/transfer/back") {
            return dispatch(replace("/desktop"));
        }
        return dispatch(replace("/v2/transfer/historic"));
    };

    const isValidAlias = (value) => {
        /* Regex that accept letter from a to z including capital letters, (.), (-), numbers
        and excluding (Ñ) and (ñ) */
        const reg = /^(?:(?![ñÑ])[a-zA-Z.\-\d])+$/;
        return reg.test(value);
    };

    const isValidCbu = (value) => {
        const reg = /^\d+$/;
        return reg.test(value) && value.length === parseInt(MAX_REQUIRED, 10);
    };

    // VERIFICAR EN ONBLUR / OnChange de cbu/alias
    const verifyCvu = (value) => {
        const { dispatch } = props;
        if (isValidCbu(value)) {
            const isCVU = value.startsWith("000");
            dispatch(transferActions.setIsCVU(isCVU));
        } else if (isValidAlias(value)) {
            dispatch(transferActions.getCbuCvuFromAlias(value));
        }
    };

    const setHint2 = (amount, currency) => {
        const isTadTrx = isTad({ amount, currency });
        if (isTadTrx) {
            setAgendaVisible(false);
            if (!tadWarningShowed) {
                setTadWarningShowed(true);
                setTadWarningPopupVisible(true);
            }
        } else {
            setTadWarningPopupVisible(false);
            setTadWarningShowed(false);
            setAgendaVisible(true);
        }
    };

    const getCurrencyForDraft = () => {
        // const { values, accounts } = props;
        const { accounts } = props;
        const { debitAccount } = values;
        if (values?.amount?.currency !== null && values?.amount?.currency !== undefined) {
            return values?.amount?.currency.toString();
        }
        if (values.debitAccount) {
            return accounts.find(({ idProduct }) => debitAccount === idProduct).currency.toString();
        }
        return null;
    };
    const getDraftSummary = () => {
        // const { values, idTransaction, internalTransfer, accounts } = props;
        const { idTransaction, internalTransfer, accounts } = props;
        const {
            debitAccount,
            creditAccount,
            cbuOrAlias,
            amount: { amount, currency },
            reference,
            sendEmail,
            email,
            emailText,
            tadTrx,
            concept,
            transferKind,
            scheduler,
            flagHint,
        } = values;

        const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
        const schedulerToSend = scheduler && scheduler.selectedOption !== schedulerUtils.TODAY ? scheduler : null;
        if (internalTransfer) {
            const completeCreditAccount = accounts.find(({ idProduct }) => idProduct === creditAccount);
            return {
                debitAccount: completeDebitAccount,
                creditAccount: completeCreditAccount,
                currency,
                amount,
                reference,
                sendEmail,
                email,
                emailText,
                scheduler: schedulerToSend,
                idTransaction,
                tadTrx,
                flagHint,
            };
        }
        const isCbu = () => /^\d+$/.test(cbuOrAlias);
        return {
            accounts,
            debitAccount: completeDebitAccount,
            cbu: isCbu() ? cbuOrAlias : null,
            alias: !isCbu() ? cbuOrAlias : null,
            currency,
            amount,
            reference,
            sendEmail,
            email,
            emailText,
            concept,
            transferKind,
            scheduler: schedulerToSend,
            idTransaction,
            tadTrx,
            flagHint,
        };
    };

    const handleSaveDraft = (draftValues) => {
        const { dispatch, idTransaction, internalTransfer, accounts } = props;
        const { tadTrx } = draftValues;
        const { debitAccount } = values;

        const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
        dispatch(
            formActions.saveDraft({
                idForm: internalTransfer ? "transferInternal" : "transferThirdParties",
                idActivityDraft: tadTrx ? getActivityIdTad() : getActivityId(),
                data: {
                    ...draftValues,
                    amount: {
                        quantity: parseAmount(draftValues.amount),
                        currency: completeDebitAccount.currency,
                    },
                    debitAccount: { value: debitAccount },
                    creditAccount: { value: draftValues.creditAccount },
                },
                idTransaction: idTransaction || null,
                scheduler: draftValues?.scheduler,
                summary: getDraftSummary(),
            }),
        );
    };

    const handleCreateTemplateClick = () => {
        const { dispatch } = props;
        dispatch(templateActions.createTemplate());
    };

    const handleTemplateSelect = (template) => {
        const {
            // setValues,
            // values,
            setErrors,
            dispatch,
        } = props;
        const amount = template.amount?.amount || 0;
        const amountWithCurrency = template.amount;
        const isTadTrx = isTad(amountWithCurrency);
        verifyCvu(template.cbuOrAlias);

        if (amount > 0) {
            setHint(amountWithCurrency);
        }
        setValues({
            ...values,
            debitAccount: template.debitAccount,
            creditAccount: template.creditAccount,
            transferKind: template.transferKind,
            cbuOrAlias: template.cbuOrAlias,
            amount: template.amount,
            concept: template.concept,
            reference: template.reference,
            sendEmail: template.sendEmail,
            email: template.email,
            flagHint: isTadTrx,
            tadTrx: isTadTrx,
        });

        // setErrors({});
        dispatch(transactionLinesActions.loadTransactionLinesTemplateData(template));
        const { debitAccount } = template;
        if (debitAccount) {
            dispatch(transferActions.accountLimitsActivities(debitAccount, getActivityId()));
        }
    };

    const changeCbu = (record) => {
        // const { setValues, values } = props;
        const transferKindDestination = record.isOwn === "1" ? "same" : "other";
        setValues({
            ...values,
            cbuOrAlias: record.account,
            transferKind: transferKindDestination,
            email: record.email,
        });
        verifyCvu(record.account);
    };

    const changeTransferGroup = (isInternal) => {
        dispatch(transferActions.changeTransferGroup(isInternal));
        const idForm = isInternal ? "transferInternal" : "transferThirdParties";
        dispatch(templateActions.loadTemplateList(idForm));
        setValues({
            ...values,
            debitAccount: "",
            creditAccount: "",
            amount: {
                amount: 0,
                currency: 0,
            },
            cbuOrAlias: "",
            reference: "",
            email: "",
            emailText: "",
            concept: "VAR",
            transferKind: "",
            flagHint: false,
            scheduler: {},
            sendEmail: false,
        });
        setTadWarningPopupVisible(false);
        setTadWarningShowed(false);
        setAgendaVisible(true);
    };

    const closeTADModal = () => {
        setTadWarningPopupVisible(false);
    };

    const onChangeAccount = (account) => {
        const { accounts, dispatch } = props;
        const { debitAccount } = values;
        const selectedAccount = accounts.find((object) => object.idProduct === account);
        if (selectedAccount) {
            setValues({
                ...values,
                amount: { currency: parseInt(selectedAccount.currency, 10), amount: values.amount.amount },
            });
            if (debitAccount !== account) {
                dispatch(transferActions.accountLimitsActivities(selectedAccount.idProduct, getActivityId()));
            }
        }
    };

    const extendedAmountEnabled = configUtils.getBoolean("increase.size.amount.link.iso8583", false);
    const transferCvuEnabled = configUtils.getBoolean("transfer.cvu.enabled");
    const { debitAccount, creditAccount, sendEmail, flagHint } = values;
    const currenciesAll = [
        { id: 0, label: i18n.get(`currency.label.ARS`) },
        { id: 2, label: i18n.get(`currency.label.USD`) },
    ];
    const transferKindOptions = [
        {
            value: `same`,
            label: i18n.get("transfers.titular.same.label"),
        },
        {
            value: `other`,
            label: i18n.get("transfers.titular.other.label"),
        },
    ];
    const cbuOrAliasPlaceholder = i18n.get(`${FORM_ID}.cbuCvuOrAlias.placeholder`);
    const cbuOrAliasLabel = `${FORM_ID}.cbuCvuOrAlias.label`;
    const selectedDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
    const creditAccounts = debitAccount
        ? accounts.filter(
              ({ idProduct: id, currency }) => id !== debitAccount && selectedDebitAccount?.currency === currency,
          )
        : accounts;

    const data = {
        enabledWeekDays: [1, 2, 3, 4, 5, 6, 7],
        firstWorkingDate: moment(),
        maxDaysToSchedule: 10000,
        nonWorkingDays: [],
        lang: "es",
        mode: "edit",
        programable: true,
        schedulable: true,
        activeEnvironment,
    };

    const handleKeyDown = (e) => {
        if (isDesktop) {
            if (e.key === "Enter") {
                const { target } = e;
                setHint(Number(target.value.replaceAll(".", "").replace(",", ".")));
            } else {
                // eslint-disable-next-line no-useless-return
                return;
            }
        } else {
            // eslint-disable-next-line no-useless-return
            return;
        }
    };

    const handleSubmit = ({
        debitAccount,
        creditAccount,
        amount,
        reference,
        sendEmail,
        email,
        emailText,
        concept,
        transferKind,
        flagHint,
    }) => {
        const { dispatch, accounts, location, summary, isRetailEnvironment, activeEnvironment, isCVU } = props;
        const { scheduler } = values;

        const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);
        const completeCreditAccount = accounts.find(({ idProduct }) => idProduct === creditAccount);
        const currency = parseInt(completeDebitAccount.currency, 10);
        const isCbu = () => {
            const reg = /^\d+$/;
            return reg.test(values.cbuOrAlias);
        };

        let schedulerToSend = scheduler && scheduler.selectedOption !== schedulerUtils.TODAY ? scheduler : null;
        const newValueDate = schedulerToSend?.valueDate;
        schedulerToSend = { ...schedulerToSend, newValueDate };
        delete schedulerToSend.valueDate;

        const tadTrx = isTad({
            currency,
            amount: parseAmount(amount),
        });
        if (!isCVU && tadTrx) {
            const schedulerTad = updateSchedulerToSend();
            if (schedulerTad) {
                schedulerToSend = schedulerTad;
            }
        }

        if (internalTransfer) {
            dispatch(
                transferActions.createInternalTransfer(
                    {
                        debitAccount: completeDebitAccount,
                        creditAccount: completeCreditAccount,
                        currency,
                        amount: parseAmount(amount),
                        reference,
                        sendEmail,
                        email,
                        emailText,
                        scheduler: schedulerToSend,
                        idTransaction: summary.idTransaction,
                        tadTrx,
                        flagHint,
                    },
                    null, // formikBag,
                ),
            );
        } else {
            dispatch(
                transferActions.createExternalTransfer(
                    {
                        accounts,
                        debitAccount: completeDebitAccount,
                        cbu: isCbu() ? values.cbuOrAlias : null,
                        alias: !isCbu() ? values.cbuOrAlias : null,
                        currency,
                        amount: parseAmount(amount),
                        reference,
                        sendEmail,
                        email,
                        emailText,
                        concept: concept.value,
                        transferKind: transferKind.value,
                        scheduler: schedulerToSend,
                        idTransaction: summary.idTransaction,
                        tadTrx,
                        flagHint,
                    },
                    null, // formikBag,
                ),
            );
            if (location?.pathname === "/transfer/modify") {
                saveDraft();
            }
        }
    };

    const handleSchedulerChange = (data) => {
        if (data.frequency === schedulerUtils.ONCE) {
            setValues({
                ...values,
                scheduler: {
                    selectedOption: data.frequency,
                    valueDate: moment(data.startsOn),
                    program: null,
                },
            });
        } else {
            const newProgramData = {
                frequency: data.frequency === schedulerUtils.CUSTOM ? data.customFreq : data.frequency, // one of DAY, WEEK, MONTH, YEAR
                frequencyValue: data.frequency === schedulerUtils.CUSTOM ? data.customFreqValue : "1", // int that represents the frequency, frequency = DAY +  frequencyValue = 2 => every 2 days
                days: dateUtils.getDayFromDate(data.startsOn), // int that represents the days of the week used by the java class Days at the backend. Same class used by the calendar restriction
                day: data.startsOn.getDate(), // day of the month.
                occurrence: null, // occurrence=3 + days=4 every 3rd WEDNESDAY of the month
                startsOn: data.startsOn,
                lapse: data.lapse.lapse, // no change at all
                number: data.lapse.number,
                date: data.lapse.date,
                isCustom: data.frequency === schedulerUtils.CUSTOM,
            };
            if (newProgramData.frequency === schedulerUtils.WEEK && newProgramData.isCustom) {
                // if it's custom, I take the days from the bitwisedays control
                newProgramData.days = data.days;
            }
            if (newProgramData.frequency === schedulerUtils.MONTH && data.occurrenceType === "occurrence") {
                newProgramData.occurrence = dateUtils.weekOfMonth(moment(data.startsOn));
                newProgramData.day = null;
            }
            setValues({
                ...values,
                scheduler: {
                    selectedOption: newProgramData.frequency,
                    program: newProgramData,
                },
            });
        }
        setIsScheduleModalVisible(false);
    };

    return (
        <ThemeProvider>
            <ThemeProvider2>
                <Notification scopeToShow="form" />
                {!isDesktop && (
                    <Box className="admin-detail-head z-index-1 px-0">
                        <Head
                            onBack={handleBack}
                            headerClassName="blue-main-header-mobile"
                            centerElement={() => (
                                <Text as="h1" className="m-0">
                                    {i18n.get("transfers.make.transfer.title")}
                                </Text>
                            )}
                            rightContent={() => (
                                <Button
                                    variant="solid"
                                    icon="NewspaperAlt"
                                    iconVariant="outline"
                                    onClick={() => {
                                        setIsTemplatesVisible(true);
                                    }}
                                />
                            )}
                            accessibilityTextId="transfers.make.transfer.title"
                        />
                    </Box>
                )}
                <Container fixed className="h-100">
                    {isDesktop && (
                        <Box className="admin-detail-head px-0">
                            <Head onBack={handleBack} accessibilityTextId="transfers.make.transfer.title" />
                            <Box flex align="center" justify="between" mb={24}>
                                <Text variant="h2">{i18n.get("transfers.make.transfer.title")}</Text>
                                <Button
                                    justify="right"
                                    text={i18n.get("transfers.templates")}
                                    variant="outline"
                                    icon="NewspaperAlt"
                                    iconVariant="outline"
                                    onClick={() => {
                                        setIsTemplatesVisible(true);
                                    }}
                                />
                            </Box>
                            <SchedulerModal
                                isVisible={isScheduleModalVisible}
                                onClose={() => setIsScheduleModalVisible(false)}
                                onScheduleClick={(data) => handleSchedulerChange(data)}
                            />
                        </Box>
                    )}
                    <TemplateDrawer
                        isVisible={isTemplatesVisible}
                        setVisible={setIsTemplatesVisible}
                        templates={templates}
                        onSelect={handleTemplateSelect}
                    />
                    <TADWarningModal
                        isVisible={tadWarningPopupVisible}
                        onClick={changeCbu}
                        closeModal={closeTADModal}
                        isDesktop={isDesktop}
                    />
                    <Grid>
                        <Col size={{ xs: 12, md: 10, lg: 8 }} offset={{ md: 2, lg: 3 }}>
                            {isDesktop ? (
                                <Tabs defaultActiveKey={match.params.transferType || "internal"}>
                                    {activeEnvironment.permissions.transferInternal && (
                                        <Tab
                                            eventKey="internal"
                                            label={i18n.get("transfers.form.internal.title")}
                                            onClick={() => {
                                                dispatch(push("/v2/transfer/internal"));
                                            }}>
                                            <TransferInternalForm
                                                isDesktop={isDesktop}
                                                onSubmit={handleSubmit}
                                                onSaveDraft={handleSaveDraft}
                                                onCreateTemplate={handleCreateTemplateClick}
                                                values={values}
                                                setValues={setValues}
                                                isScheduleModalVisible={isScheduleModalVisible}
                                                setIsScheduleModalVisible={setIsScheduleModalVisible}
                                                setHint={setHint2}
                                                tadWarningShowed={tadWarningShowed}
                                                onClickTadWaring={() => setTadWarningPopupVisible(true)}
                                                agendaVisible={agendaVisible}
                                            />
                                        </Tab>
                                    )}
                                    {activeEnvironment.permissions.transferThirdParties && (
                                        <Tab
                                            eventKey="thirdParties"
                                            label={i18n.get("transfers.form.thirdParties.title")}
                                            onClick={() => dispatch(push("/v2/transfer/thirdParties"))}>
                                            <TransferThirdPartiesForm
                                                isDesktop={isDesktop}
                                                onSubmit={handleSubmit}
                                                onSaveDraft={handleSaveDraft}
                                                onCreateTemplate={handleCreateTemplateClick}
                                                values={values}
                                                setValues={setValues}
                                                isScheduleModalVisible={isScheduleModalVisible}
                                                setIsScheduleModalVisible={setIsScheduleModalVisible}
                                                setHint={setHint2}
                                                tadWarningShowed={tadWarningShowed}
                                                onClickTadWaring={() => setTadWarningPopupVisible(true)}
                                                isValidCbu={isValidCbu}
                                                agendaVisible={agendaVisible}
                                            />
                                        </Tab>
                                    )}
                                </Tabs>
                            ) : (
                                <>
                                    {activeEnvironment.permissions.transferInternal &&
                                        match.params.transferType === "internal" && (
                                            <TransferInternalForm
                                                isDesktop={isDesktop}
                                                onSubmit={handleSubmit}
                                                onSaveDraft={handleSaveDraft}
                                                onCreateTemplate={handleCreateTemplateClick}
                                                values={values}
                                                setValues={setValues}
                                                isScheduleModalVisible={isScheduleModalVisible}
                                                setIsScheduleModalVisible={setIsScheduleModalVisible}
                                                setHint={setHint2}
                                                tadWarningShowed={tadWarningShowed}
                                                onClickTadWaring={() => setTadWarningPopupVisible(true)}
                                                agendaVisible={agendaVisible}
                                            />
                                        )}
                                    {activeEnvironment.permissions.transferThirdParties &&
                                        match.params.transferType === "thirdParties" && (
                                            <TransferThirdPartiesForm
                                                isDesktop={isDesktop}
                                                onSubmit={handleSubmit}
                                                onSaveDraft={handleSaveDraft}
                                                onCreateTemplate={handleCreateTemplateClick}
                                                values={values}
                                                setValues={setValues}
                                                isScheduleModalVisible={isScheduleModalVisible}
                                                setIsScheduleModalVisible={setIsScheduleModalVisible}
                                                setHint={setHint2}
                                                tadWarningShowed={tadWarningShowed}
                                                onClickTadWaring={() => setTadWarningPopupVisible(true)}
                                                isValidCbu={isValidCbu}
                                                agendaVisible={agendaVisible}
                                            />
                                        )}
                                </>
                            )}
                        </Col>
                    </Grid>
                </Container>
            </ThemeProvider2>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => ({
    fetching: transferSelectors.getFetching(state),
    accounts: transferSelectors.getAccounts(state),
    concepts: transferSelectors.getConcepts(state),
    internalTransfer: transferSelectors.getInternalTransfer(state),
    summary: transferSelectors.getSummary(state),
    templates: templateSelectors.getTemplateList(state),
    limit: transferSelectors.getLimit(state),
    limitUsed: transferSelectors.getLimitUsed(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
    isCVU: transferSelectors.getIsCVU(state),
    sendEmail: transferSelectors.getSendEmail(state),
});

export default compose(connect(mapStateToProps))(V2Transfer);
