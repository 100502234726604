import { createReducer, makeActionCreator } from "util/redux";

const INITIAL_STATE = {
    optionsVisible: false,
    fetchingDownload: false,
};

export const types = {
    TOGGLE_OPTIONS: "massPayments/TOGGLE_OPTIONS",
    DOWNLOAD_LINES_REQUEST: "massPayments/DOWNLOAD_LINES_REQUEST",
    DOWNLOAD_LINES_FAILURE: "massPayments/DOWNLOAD_LINES_FAILURE",
    DOWNLOAD_LINES_SUCCESS: "massPayments/DOWNLOAD_LINES_SUCCESS",
};

export default createReducer(INITIAL_STATE, {
    [types.TOGGLE_OPTIONS]: (state) => ({
        ...state,
        optionsVisible: !state.optionsVisible,
    }),
});

export const actions = {
    toggleOptions: makeActionCreator(types.TOGGLE_OPTIONS),
    downloadLinesRequest: makeActionCreator(types.DOWNLOAD_LINES_REQUEST, "idTransaction", "format", "filters"),
};

export const selectors = {
    getProcessedFileData: ({ formFields }) => formFields.multilineFile,
    isFetchingDownload: () => false,
};
