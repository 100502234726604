import { routerActions } from "react-router-redux";

import * as administrationMedium from "middleware/administration/medium";
import {
    detailsActions,
    detailsTypes,
    permissionsActions,
    permissionsTypes,
    permissionsTicketActions,
    permissionsTicketTypes,
    channelsActions,
    channelsTypes,
    channelsTicketActions,
    channelsTicketTypes,
    signaturesSchemesActions,
    signaturesSchemesTypes,
    signaturesSchemesTicketActions,
    signaturesSchemesTicketTypes,
    signatureTicketActions,
    signatureTicketTypes,
} from "reducers/administration/medium";
import detailsSagasCreator from "sagas/administration/common/details";
import permissionsSagasCreator from "sagas/administration/common/permissions";
import permissionsTicketSagasCreator from "sagas/administration/common/permissionsTicket";
import channelsSagasCreator from "sagas/administration/common/channels";
import channelsTicketSagasCreator from "sagas/administration/common/channelsTicket";
import signaturesSchemesSagasCreator from "sagas/administration/common/signaturesSchemes";
import signaturesSchemesTicketSagasCreator from "sagas/administration/common/signaturesSchemesTicket";
import signatureTicketSagasCreator from "sagas/administration/common/signatureTicket";

export default [
    ...detailsSagasCreator(administrationMedium, detailsTypes, {
        ...detailsActions,
        ...permissionsActions,
        ...channelsActions,
    }),
    ...permissionsSagasCreator(administrationMedium, permissionsTypes, {
        ...permissionsActions,
        onSuccess: (id) => routerActions.push(`/administration/medium/permissions/${id}/confirm`),
    }),
    ...permissionsTicketSagasCreator(administrationMedium, permissionsTicketTypes, {
        ...permissionsTicketActions,
        ...permissionsActions,
    }),
    ...channelsSagasCreator(administrationMedium, channelsTypes, {
        ...channelsActions,
        onSuccess: (id) => routerActions.push(`/administration/medium/channels/${id}/confirm`),
    }),
    ...channelsTicketSagasCreator(administrationMedium, channelsTicketTypes, {
        ...channelsTicketActions,
        ...channelsActions,
    }),
    ...signaturesSchemesSagasCreator(administrationMedium, signaturesSchemesTypes, {
        ...signaturesSchemesActions,
    }),
    ...signaturesSchemesTicketSagasCreator(administrationMedium, signaturesSchemesTicketTypes, {
        ...signaturesSchemesActions,
        ...signaturesSchemesTicketActions,
    }),
    ...signatureTicketSagasCreator(administrationMedium, signatureTicketTypes, {
        ...signatureTicketActions,
        ...detailsActions,
    }),
];
