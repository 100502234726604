import React, { Component, Fragment } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import { bool, func } from "prop-types";

import { selectors as loginSelectors } from "reducers/login";
import { selectors as assistantSelectors } from "reducers/assistant";

import { resizableRoute } from "pages/_components/Resizable";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Logo from "pages/login/_components/Logo";
import AssistantAlert from "pages/login/_components/AssistantAlert";

class Step0Content extends Component {
    static propTypes = {
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
        isFromAmazon: bool.isRequired,
        isFromGoogle: bool.isRequired,
        isFromMessenger: bool.isRequired,
        isFromWhatsapp: bool.isRequired,
    };

    send = (loginType) => {
        const { isMobile, dispatch } = this.props;

        switch (loginType) {
            case "email": {
                if (isMobile) {
                    dispatch(push("/loginStep1"));
                }
                break;
            }
            case "google": {
                // TODO Google
                break;
            }
            default: {
                // TODO Undefined
                break;
            }
        }
    };

    render() {
        const { isMobile, isFromAmazon, isFromGoogle, isFromMessenger, isFromWhatsapp } = this.props;

        return (
            <Fragment>
                {isMobile && (
                    <>
                        <div className="above-the-fold">
                            <Fragment>
                                {(isFromAmazon || isFromGoogle || isFromMessenger || isFromWhatsapp) && (
                                    <AssistantAlert />
                                )}
                                <section className="container--layout flex-grow align-items-center">
                                    <Grid className="form-content">
                                        <Row className="justify-content-center">
                                            <Col sm={10} md={8} lg={6} className="col col-10">
                                                <Logo className="svg-image logo logo-mobile-size" logoColor />
                                            </Col>
                                        </Row>
                                    </Grid>
                                </section>
                            </Fragment>
                            <section className="container--layout align-items-center">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            <Button
                                                className="btn-login"
                                                onClick={(event) => this.send("email", event)}
                                                image="images/email.svg"
                                                label="login.step0.withEmail"
                                                bsStyle="outline"
                                            />
                                        </Col>
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            <p className="text-center">
                                                <I18n id="login.footer.noUser" />{" "}
                                                <Link to="/noUser">
                                                    <I18n id="login.footer.createUser" />
                                                </Link>
                                            </p>
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        </div>
                    </>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: loginSelectors.getFetching(state),
    isFromAmazon: assistantSelectors.isFromAmazon(state),
    isFromGoogle: assistantSelectors.isFromGoogle(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
});

export default withRouter(connect(mapStateToProps)(resizableRoute(Step0Content)));
