import * as API from "middleware/api";

export const inviteUsersPre = () => API.executeWithAccessToken("/administration.users.invite.pre");

export const inviteUserVerify = (params) =>
    API.executeWithAccessToken("/administration.users.invite.verify", {
        ...params,
        docCountry: "AR",
    });

export const inviteUserPreview = (params) =>
    API.executeWithAccessToken("/administration.users.invite.preview", { ...params, docCountry: "AR" });

export const inviteUserConfirm = (params) =>
    API.executeWithAccessToken("/administration.users.invite.send", { ...params, docCountry: "AR" });
