import React from "react";
import FundScheduler from "pages/fundcorp/FundScheduler";
import moment from "moment";
import * as dateUtils from "util/date";
import { arrayOf, string } from "prop-types";
import FundSchedulerModal from "../_rescue/FundSchedulerModal";

function FundSubscribeScheduler(props) {
    const { nonWorkingDays } = props;
    const startOnDate = moment(new Date()).add(1, "days");

    const getStartOnDate = () => {
        while (!dateUtils.filterForAvailableDays(nonWorkingDays, startOnDate)) {
            startOnDate.add(1, "days");
        }
        return startOnDate;
    };

    return (
        <FundScheduler {...props} STARTS_ON_DATE={getStartOnDate()}>
            {(inyectedParentProps) => <FundSchedulerModal {...inyectedParentProps} />}
        </FundScheduler>
    );
}

FundSubscribeScheduler.propTypes = {
    nonWorkingDays: arrayOf(string).isRequired,
};

export default FundSubscribeScheduler;
