import React, { Component } from "react";
import { connect } from "react-redux";
import { bool } from "prop-types";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Yup from "yup";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import { actions as checkActions } from "reducers/checks";
import Col from "react-bootstrap/lib/Col";
import isTokenActive from "util/token";

const FORM_ID = "new.check";

class CheckTokenConfirmation extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { isSubmitting, isDesktop } = this.props;

        return (
            <Form className="confirmation__container">
                <Container
                    className={!isDesktop && "flex-grow align-items-center container-white"}
                    gridClassName={!isDesktop ? "form-content" : "confirmation__form confirmation__box"}>
                    <Col className="col-12 col-xl-12" lg={12} md={9} sm={12}>
                        <Container
                            className={!isDesktop && "flex-grow align-items-center container-white"}
                            gridClassName={!isDesktop && "form-content"}>
                            {!isTokenActive(!isDesktop) ? (
                                <div className="admin-content-center">
                                    <I18n id="transfer.summary.confirm.label" />
                                </div>
                            ) : (
                                <div className="admin-content-center pb-3">
                                    <I18n
                                        id="confirmation.withoutotp.text"
                                        component="p"
                                        componentProps={{ className: "confirmation__text-without-otp" }}
                                    />
                                </div>
                            )}
                            <div className="admin-content-center echeq__solicitude-pass">
                                <Field
                                    placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                    name="otp"
                                    type="otp"
                                    idForm={FORM_ID}
                                    component={Credential}
                                />
                            </div>
                        </Container>
                    </Col>
                    <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                        <Col lg={12} md={12} sm={12} className="px-0">
                            <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                                <div className="admin-content-center">
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.confirm"
                                        loading={isSubmitting}
                                        image="images/arrowRight.svg"
                                        imageStyle={isDesktop ? "mr-2" : "mr-1"}
                                    />
                                </div>
                            </Col>
                        </Col>
                    </Container>
                </Container>
            </Form>
        );
    }
}

export default compose(
    connect(),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, checksRequestData, userId } = formikBag.props;

            dispatch(
                checkActions.requestChecks(
                    { ...checksRequestData, idActivity: "account.checks" },
                    otp,
                    userId,
                    formikBag,
                ),
            );
        },
    }),
)(CheckTokenConfirmation);
