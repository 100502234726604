import React, { Component } from "react";
import { string, func, bool, number, shape } from "prop-types";
import { actions as productsActions } from "reducers/products";
import EditableLabel from "pages/_components/fields/EditableLabel";
import InfoTag from "pages/_components/InfoTag";
import Col from "react-bootstrap/lib/Col";

import * as i18nUtils from "util/i18n";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import { push } from "react-router-redux";

// import { actions as depositsActions } from "reducers/deposits";

class DetailHeadTitle extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        // match: shape().isRequired,
        deposit: shape({
            productType: string.isRequired,
            depositNumber: string.isRequired,
            nextPaymentAmount: number.isRequired,
            dueDate: string.isRequired,
            currency: string.isRequired,
            automaticRenewal: bool.isRequired,
            isDesktop: bool.isRequired,
            idProduct: string.isRequired,
        }).isRequired,
        fetchingDownload: bool.isRequired,
        handleClickDownload: func,
    };

    static defaultProps = {
        handleClickDownload: null,
    };

    state = {
        alias: null,
    };

    constructor(props) {
        super(props);
        const { deposit } = props;
        const { depositNumber, productAlias } = deposit;

        this.state = {
            alias: productAlias || depositNumber,
        };
    }
    
    saveAlias = (alias) => {
        const { deposit, dispatch } = this.props;
        dispatch(productsActions.changeProductAlias(alias, deposit.idProduct));
        this.setState({ alias });
    };

    render() {
        const { deposit, isDesktop, fetchingDownload, handleClickDownload, dispatch } = this.props;
        const { depositNumber, automaticRenewal, productAlias } = deposit;
        const { alias } = this.state;

        const editAccountNameButton = (
            <Button
                className="dropdown-btn align-self-center account-dropdown-header-font"
                label="products.alias.edit.deposit"
                onClick={() => dispatch(push(`/deposit/${deposit.idProduct}/setProductAlias`))}
                style={!isDesktop ? { borderColor: "none" } : undefined}
            />
        );

        const downloadMobile = (
            <Button
                onClick={handleClickDownload("pdf")}
                label="global.download"
                className="dropdown-btn align-self-center account-dropdown-header-font"
                style={!isDesktop ? { borderColor: "none" } : undefined}
            />
        );

        const dropDownContextMenuMobile = (
            <div className="toolbar-item">
                <Dropdown
                    image="images/context-menu.svg"
                    buttonClass="context-menu-button context-menu-dropdown view-back ml-0"
                    bsStyle="link"
                    imageStyle={!isDesktop ? "toolbar-btn-image-mobile margin-right-negative" : undefined}
                    pullRight
                    closeOnBlur={false}>
                    {editAccountNameButton}
                    {downloadMobile}
                </Dropdown>
            </div>
        );

        return (
            <>
                {!isDesktop && (
                    <>
                        <div className="d-flex w-100 toolbar-item justify-content-center">
                            <div className="title-account-header-multiline-background-blue w-100 f-dir-col">
                                <h1>{`${productAlias || depositNumber}`}</h1>
                                <p className="my-0">
                                    {`${i18nUtils
                                        .get("deposits.information.certificate")
                                        .toUpperCase()}  Nº ${depositNumber}`}
                                </p>
                            </div>
                        </div>
                        {dropDownContextMenuMobile}
                    </>
                )}

                {isDesktop && (
                    <>
                        <Col sm={12} md={6} lg={8} className="col col 12 pl-2">
                            <EditableLabel isDesktop={isDesktop} onSave={this.saveAlias} value={alias}>
                                <h1 className="my-0">{alias}</h1>

                                {automaticRenewal && (
                                    <div className="d-flex align-self-center pl-2">
                                        <InfoTag type="default" message="auto" tagBackground="#0be0a2" />
                                    </div>
                                )}
                            </EditableLabel>
                        </Col>
                        <Col sm={12} md={6} lg={4} className="col d-flex justify-content-end">
                            <div className="mr-4">
                                <Dropdown
                                    image="images/download_bold.svg"
                                    imageStyle="mr-2"
                                    buttonClass="min-width-14"
                                    label="global.download"
                                    bsStyle="outline"
                                    fetching={fetchingDownload}
                                    pullDown>
                                    <Button
                                        onClick={handleClickDownload("pdf")}
                                        label="accounts.pdfFile"
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                    />
                                    <Button
                                        onClick={handleClickDownload("xls")}
                                        label="accounts.xlsFile"
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                    />

                                    <Button
                                        onClick={handleClickDownload("csv")}
                                        label="accounts.csvFile"
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                    />
                                </Dropdown>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={12} xl={12} className="content-data-wrapper col pl-2">
                            <p className="account-display-font" style={{ fontWeight: "normal", color: "#000000" }}>
                                {`${i18nUtils
                                    .get("deposits.information.certificate")
                                    .toUpperCase()}  Nº ${depositNumber}`}
                            </p>
                        </Col>
                    </>
                )}
            </>
        );
    }
}

export default DetailHeadTitle;
