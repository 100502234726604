import React, { Component } from "react";
import { node } from "prop-types";
import BSNavbar from "react-bootstrap/lib/Navbar";

import NavbarItem from "pages/_components/navbar/Item";

class Navbar extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    render() {
        const { children } = this.props;
        return (
            <BSNavbar fluid>
                <div className="navbar-header">
                    <div className="toolbar">{children}</div>
                </div>
            </BSNavbar>
        );
    }
}

Navbar.Item = NavbarItem;

export default Navbar;
