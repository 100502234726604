import React, { Component } from "react";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import { Formik, Field, Form } from "formik";
import { compose } from "redux";
import { string, bool, func, arrayOf, shape, number } from "prop-types";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import { flattenArray, removeDuplicateItems } from "util/array";
import classNames from "classnames";

import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import { Checkbox } from "pages/_components/fields/Checkbox";

class AdministrationFormConfirmation extends Component {
    static propTypes = {
        onSubmit: func.isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        renderOutOfFormik: bool,
        otherInitialValues: shape({}),
        otherFieldFunction: func.isRequired,
        disclaimerMessageKey: string,
        withoutCredentialHint: bool,
        SMcolumnWidth: number,
        MDcolumnWidth: number,
        LGcolumnWidth: number,
        XLcolumnWidth: number,
        containerClassName: string,
        formContainerClassName: string,
        classNameCredentialsText: string,
        buttonClassName: string,
        buttonLabel: string,
        buttonContainerClassName: string,
        buttonContentClassName: string,
        isDesktop: bool.isRequired,
        buttonImage: string,
        buttonImageClass: string,
        location: shape({
            pathname: string,
        }).isRequired,
    };

    static defaultProps = {
        renderOutOfFormik: false,
        otherInitialValues: {},
        disclaimerMessageKey: "",
        withoutCredentialHint: false,
        SMcolumnWidth: 12,
        MDcolumnWidth: 9,
        LGcolumnWidth: 6,
        XLcolumnWidth: 6,
        containerClassName: "",
        formContainerClassName: "",
        classNameCredentialsText: "",
        buttonClassName: "",
        buttonLabel: "global.send",
        buttonContainerClassName: "",
        buttonContentClassName: "",
        buttonImage: "",
        buttonImageClass: "",
    };

    state = {
        hasDisclaimerAccepted: false,
    };

    handleAcceptDisclaimer = () => {
        const { hasDisclaimerAccepted } = this.state;
        this.setState({
            hasDisclaimerAccepted: !hasDisclaimerAccepted,
        });
    };

    renderCredentials = (credentialGroupsFiltered, withoutCredentialHint, classNameCredentialsText) =>
        credentialGroupsFiltered.map((credential) => (
            <div key={credential}>
                {!withoutCredentialHint && (
                    <p
                        className={classNames(
                            "form-credential-hint mt-0 mb-2 px-2 font-light f-size-55 text-center",
                            classNameCredentialsText,
                        )}>
                        {i18nUtils.get(`form.credential.hint`)}
                    </p>
                )}
                <Field
                    placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                    idForm="form.credential"
                    name={credential}
                    component={Credential}
                    type={credential}
                    autoFocus
                />
            </div>
        ));

    render() {
        const {
            credentialGroups,
            onSubmit,
            otherInitialValues,
            otherFieldFunction,
            renderOutOfFormik,
            disclaimerMessageKey,
            withoutCredentialHint,
            SMcolumnWidth,
            MDcolumnWidth,
            LGcolumnWidth,
            XLcolumnWidth,
            containerClassName,
            formContainerClassName,
            classNameCredentialsText,
            buttonClassName,
            buttonLabel,
            buttonContainerClassName,
            buttonContentClassName,
            isDesktop,
            buttonImage,
            buttonImageClass,
            location,
        } = this.props;

        const confirmStep1Path =
            location?.pathname === "/administration/advanced/group/create/confirm/step1" ||
            location?.pathname === "/administration/advanced/group/create/confirm/step3";

        if (credentialGroups !== undefined) {
            const credentialGroupsFiltered = compose(
                (array) => array.filter((item) => item !== "accessToken"),
                removeDuplicateItems,
                flattenArray,
                (array) => array.map(({ credentials }) => credentials),
            )(credentialGroups);
            const initialValues = credentialGroupsFiltered.reduce(
                (values, credential) => ({ ...values, [credential]: "" }),
                {},
            );

            const disclaimerMessage = configUtils.get(disclaimerMessageKey, "");
            const { hasDisclaimerAccepted } = this.state;

            if (!Object.keys(initialValues).length) {
                return null;
            }

            if (renderOutOfFormik) {
                return (
                    <Container className="background-white pb-3">
                        <Col
                            className={confirmStep1Path ? "px-0" : "col-12 max-xl-480 px-0"}
                            md={confirmStep1Path ? 12 : 9}
                            lg={confirmStep1Path ? 12 : 6}>
                            {this.renderCredentials(
                                credentialGroupsFiltered,
                                withoutCredentialHint,
                                classNameCredentialsText,
                            )}
                        </Col>
                    </Container>
                );
            }

            return (
                <Formik initialValues={{ ...initialValues, ...otherInitialValues }} onSubmit={onSubmit}>
                    {({ isSubmitting }) => (
                        <Form className={formContainerClassName}>
                            {otherFieldFunction && otherFieldFunction()}
                            <Container
                                className={`align-items-center flex-grow px-0 ${
                                    isDesktop ? "container--layout with-margin" : "pb-0"
                                }`}
                                gridClassName="">
                                <Container
                                    className={`container--layout my-0 align-items-center admin-action background-white
                                 ${containerClassName}`}>
                                    <Grid className="form-content">
                                        <Row className="justify-content-center mx-0">
                                            <Col
                                                sm={SMcolumnWidth}
                                                md={MDcolumnWidth}
                                                lg={LGcolumnWidth}
                                                xl={XLcolumnWidth}
                                                className={
                                                    confirmStep1Path
                                                        ? "px-0"
                                                        : "col text-center max-xl-480 px-0 mb-3 px-0"
                                                }>
                                                {this.renderCredentials(
                                                    credentialGroupsFiltered,
                                                    withoutCredentialHint,
                                                    classNameCredentialsText,
                                                )}
                                            </Col>
                                        </Row>
                                        {disclaimerMessageKey && (
                                            <Row className="justify-content-center mx-0">
                                                <Col
                                                    sm={SMcolumnWidth}
                                                    md={MDcolumnWidth}
                                                    lg={LGcolumnWidth}
                                                    xl={XLcolumnWidth}
                                                    className={
                                                        confirmStep1Path
                                                            ? "px-0"
                                                            : "col max-xl-480 px-0 mb-3 mt--1 px-0"
                                                    }>
                                                    <Checkbox
                                                        name="responsabilityDisclaimer"
                                                        label={disclaimerMessage}
                                                        checked={hasDisclaimerAccepted}
                                                        onChange={this.handleAcceptDisclaimer}
                                                        formGroupClassName="form-checkbox-signature-schemes"
                                                        formGroupTextClassName="font-light font-weight-bold my-0 pt-4 f-size-55"
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    </Grid>
                                    <Container
                                        className={`flex-grow align-items-center w-100 ${buttonContainerClassName}`}>
                                        <Col lg={12} md={12} sm={12} className={`px-0 ${buttonContentClassName}`}>
                                            <Col
                                                className="confirmation__content-button"
                                                sm={SMcolumnWidth}
                                                md={MDcolumnWidth}
                                                lg={LGcolumnWidth}
                                                xl={XLcolumnWidth}>
                                                <div className="admin-content-center">
                                                    <Button
                                                        className={buttonClassName}
                                                        type="submit"
                                                        label={buttonLabel}
                                                        bsStyle="primary"
                                                        loading={isSubmitting}
                                                        disabled={!hasDisclaimerAccepted && disclaimerMessageKey !== ""}
                                                        image={buttonImage}
                                                        imageStyle={buttonImageClass}
                                                    />
                                                </div>
                                            </Col>
                                        </Col>
                                    </Container>
                                </Container>
                            </Container>
                        </Form>
                    )}
                </Formik>
            );
        }
        return <></>;
    }
}

export default AdministrationFormConfirmation;
