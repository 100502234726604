/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { idTransactionFormat } from "util/format";

import I18n from "pages/_components/I18n";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class AdminUserInviteDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                submitDateTimeAsString,
                data: { firstName, lastName, email, mobilePhone },
            } = object.transaction;

            return (
                <CardContainer className="confirmation__cards-container">
                    <Card
                        className="confirmation__cards"
                        icon={isDesktop && "images/transfer-PENDING.svg"}
                        iconClass="card__pending-transaction-icon"
                        hrClass="d-none"
                        title={
                            isDesktop ? (
                                submitDateTimeAsString && (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <I18n id="forms.transaction.ticket.date" />
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>{submitDateTimeAsString}</span>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div>
                                    {firstName && (
                                        <div className="confirmation-cards__title">
                                            <div className="confirmation-cards__title-data">
                                                <I18n id="administration.users.invite.firstName.label" />
                                            </div>
                                            <div className="confirmation-cards__title-info">
                                                <span>{firstName}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        content={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    {firstName && (
                                        <div
                                            className={
                                                lastName || email
                                                    ? "confirmation-cards__content pb-1"
                                                    : "confirmation-cards__content"
                                            }>
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="administration.users.invite.firstName.label" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{firstName}</span>
                                            </div>
                                        </div>
                                    )}
                                    {lastName && (
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="administration.users.invite.lastName.label" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{lastName}</span>
                                            </div>
                                        </div>
                                    )}
                                    {email && (
                                        <div className="confirmation-cards__content info-type-b">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="administration.users.invite.email.label" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{email}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {submitDateTimeAsString && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="forms.transaction.ticket.date" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{submitDateTimeAsString}</span>
                                            </div>
                                        </div>
                                    )}
                                    {lastName && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="administration.users.invite.lastName.label" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{lastName}</span>
                                            </div>
                                        </div>
                                    )}
                                    {email && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="administration.users.invite.email.label" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{email}</span>
                                            </div>
                                        </div>
                                    )}
                                    {idTransactionFormat(idTransaction) && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="forms.transaction.ticket.number" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    )}
                                    {mobilePhone && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="administration.users.invite.mobilePhone.label" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{mobilePhone}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        bottom={
                            isDesktop && (
                                <div className="d-flex align-items-center">
                                    {idTransactionFormat(idTransaction) && (
                                        <div
                                            className={
                                                mobilePhone
                                                    ? "confirmation-cards__bottom confirmation-cards-separator"
                                                    : "confirmation-cards__bottom"
                                            }>
                                            <div className="confirmation-cards__bottom-data">
                                                <I18n id="forms.transaction.ticket.number" />
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    )}
                                    {mobilePhone && (
                                        <div className="confirmation-cards__bottom">
                                            <div className="confirmation-cards__bottom-data">
                                                <I18n id="administration.users.invite.mobilePhone.label" />
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{mobilePhone}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    />
                </CardContainer>
            );
        });
    };

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(AdminUserInviteDataTable);
