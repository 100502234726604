import React from "react";
import { string } from "prop-types";
import Image from "pages/_components/Image";

const FieldError = (props) => {
    const { error, errorClassName } = props;

    return (
        error && (
            <div className={`form-group-error ${errorClassName}`}>
                <Image src="images/warningIcon.svg" className="svg-icon" /> <span>{error}</span>
            </div>
        )
    );
};

FieldError.propTypes = {
    error: string.isRequired,
    errorClassName: string,
};

FieldError.defaultProps = {
    errorClassName: "",
};

export default FieldError;
