import React, { Component } from "react";
import { shape, func, bool, string } from "prop-types";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { Col } from "react-bootstrap";

import Notification from "pages/_components/Notification";
import ProductDetail from "pages/_components/ProductDetail";
import Information from "pages/deposits/_components/Information";
import DetailHeadTitle from "pages/deposits/_components/DetailHeadTitle";
import PageLoading from "pages/_components/PageLoading";
import Hint from "pages/_components/hints/Hint";
import Container from "pages/_components/Container";

import { selectors as depositsSelectors, actions as depositsActions } from "reducers/deposits";

class Details extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        deposit: shape({
            idProduct: string,
            currency: string,
        }).isRequired,
        rest: shape().isRequired,
        match: shape().isRequired,
        isDesktop: bool.isRequired,
        fetching: bool.isRequired,
        fetchingDownload: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, ...rest } = this.props;

        dispatch(depositsActions.readDeposit(rest.match.params.id));
    }

    handleClick = () => {
        const { dispatch } = this.props;
        dispatch(depositsActions.toggleOptions());
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(depositsActions.closeOptions());
        dispatch(goBack());
    };

    handleClickDownload = (format) => () => {
        const { dispatch, match } = this.props;
        dispatch(depositsActions.downloadDetail(match.params.id, format));
    };

    render() {
        const { deposit, isDesktop, dispatch, fetching, fetchingDownload, ...rest } = this.props;

        return (
            <PageLoading loading={fetching}>
                {!fetching && deposit && (
                    <>
                        <Notification scopeToShow="deposits/details" />
                        <ProductDetail>
                            <ProductDetail.Head
                                onBack={this.handleBack}
                                dispatch={dispatch}
                                productId={rest.match.params.id}
                                renderDownload={() => {}}
                                title="activities.deposits.detail.title">
                                {isDesktop && <div />}
                                <DetailHeadTitle
                                    deposit={deposit}
                                    isDesktop={isDesktop}
                                    dispatch={dispatch}
                                    fetchingDownload={fetchingDownload}
                                    handleClickDownload={this.handleClickDownload}
                                />
                            </ProductDetail.Head>
                            <ProductDetail.Body isDesktop={isDesktop} moreFilters={false}>
                                <Information
                                    deposit={deposit}
                                    keyLabel="deposits.details.information"
                                    dispatch={dispatch}
                                />

                                <Container className="align-items-center flex-grow">
                                    <Col className="col col-12" lg={6} xl={6} sm={12} md={9}>
                                        <Hint
                                            idMessage="deposits.information.disclaimer.untransferable"
                                            styleImage={{
                                                marginTop: "1.2rem",
                                            }}
                                            classNameHintP="hint-font-size-small font-weight-normal"
                                            alwaysShowMessage
                                        />
                                    </Col>
                                </Container>
                            </ProductDetail.Body>
                        </ProductDetail>
                    </>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    deposit: depositsSelectors.getSelectedDeposit(state),
    fetching: depositsSelectors.getFetching(state),
    fetchingDownload: depositsSelectors.getFetchingDownload(state),
});

export default connect(mapStateToProps)(Details);
