import { connect } from "react-redux";
import { compose } from "redux";

import { selectors as formSelectors } from "reducers/form";

import FormRender from "pages/forms/_components/FormRender";

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    metadata: formSelectors.getMetadata(state),
    fetching: formSelectors.getFetching(state),
    currentLang: "es",
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    mode: "view",
    fromBackoffice: true,
});

export default compose(connect(mapStateToProps))(FormRender);
