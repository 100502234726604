import React, { Component } from "react";

import GroupActionTicket from "pages/administration/_components/tickets/GroupActionTicket";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";

class GroupActionTicketContent extends Component {
    render() {
        return (
            <AdministrationTicket {...this.props}>
                <GroupActionTicket {...this.props} />
            </AdministrationTicket>
        );
    }
}

export default GroupActionTicketContent;
