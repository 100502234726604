import * as API from "middleware/api";

export const loadMultipleTransfers = (filters) => API.executeWithAccessToken("/transfer.multiple.list", filters);

export const multipleTransfersPre = () => API.executeWithAccessToken("/transfer.multiple.pre");

export const multipleTransfersPreview = (summary) => API.executeWithAccessToken("/transfer.multiple.preview", summary);

export const multipleTransfersSend = (otp, summary) => {
    delete summary?.oldBatchId;
    return API.executeWithAccessToken("/transfer.multiple.send", { ...summary, _otp: otp });
};

export const readBatch = (idTransaction, pageNumber, status) =>
    API.executeWithAccessToken("/transfer.multiple.detail", {
        idTransactionToRead: idTransaction,
        pageNumber,
        status,
    });

export const downloadBatchDetailRequest = (batchId, statusFilter) =>
    API.executeWithAccessToken("/transfer.multiple.detail.download", {
        batchId,
        status: statusFilter,
    });
