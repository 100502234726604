import React from "react";
import { bool } from "prop-types";

import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";
import classNames from "classnames";

const FORM_ID = "rescue.fund.hint";

function FundRescueHint({ isDesktop }) {
    return (
        <Container>
            <Col md={9} className={classNames({ "mr-4": !isDesktop })}>
                <div
                    className={classNames("form-section-title admin-content-center", {
                        "mt-8 mb-6": isDesktop,
                        "mt-4 mb-5": !isDesktop,
                    })}>
                    <I18n component="h2" id={`${FORM_ID}.title`} />
                </div>
                <div className="emision-modes-drawer p-0">
                    <I18n
                        component="p"
                        id={`${FORM_ID}.text1`}
                        componentProps={{
                            className: "mb-0 color-black light-font",
                        }}
                    />
                    <I18n
                        component="p"
                        id={`${FORM_ID}.text2`}
                        componentProps={{
                            className: "mt-0 color-black light-font",
                        }}
                    />
                </div>
            </Col>
        </Container>
    );
}

FundRescueHint.propTypes = {
    isDesktop: bool.isRequired,
};

FundRescueHint.defaultProps = {};

export default FundRescueHint;
