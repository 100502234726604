/* eslint-disable react/no-danger */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { arrayOf, bool, func, shape, string } from "prop-types";
import Yup from "yup";
import { connect } from "react-redux";

import { actions as userActions, selectors as userSelectors } from "reducers/administration/users";

import * as arrayUtils from "util/array";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import { resizableRoute } from "pages/_components/Resizable";

import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import FeatureFlag from "pages/_components/FeatureFlag";
import Selector from "pages/_components/fields/formik/Selector";
import MultiSelect from "pages/_components/fields/formik/MultiSelect";
import StepperFieldGroup from "pages/_components/fields/StepperFieldGroup";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import SectionTitle from "pages/_components/SectionTitle";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import ShowCurrency from "./ShowCurrency";

const FORM_ID = "administration.signatures.create";

class SignaturesSchemeCreate extends Component {
    static propTypes = {
        actions: shape({
            createSignaturesSchemePreRequest: func,
        }),
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        capFrequencyList: arrayOf(string),
        fetching: bool,
        functionalGroups: arrayOf(
            shape({
                idFeature: string,
            }),
        ),
        environmentProducts: arrayOf(Object),
        isDesktop: bool.isRequired,
        masterCurrency: string,
        routerActions: shape({
            goBack: func,
            push: func,
        }),
        signatureTypeList: arrayOf(string),
        selectedFunctionalGroups: arrayOf(string),
        dispatch: func.isRequired,
        fetchingExport: bool.isRequired,
    };

    static defaultProps = {
        actions: null,
        capFrequencyList: [],
        fetching: false,
        functionalGroups: [],
        masterCurrency: configUtils.get("core.masterCurrency"),
        routerActions: null,
        signatureTypeList: null,
        selectedFunctionalGroups: [],
        environmentProducts: [],
    };

    componentDidMount() {
        const { actions } = this.props;

        actions.createSignaturesSchemePreRequest();
    }

    handleSubmit = ({
        functionalGroups,
        signatureType,
        signatureLevelsCounts,
        toggleFunctionalGroups,
        toggleProducts,
        selectedProducts,
        topAmount,
        toggleDispatchControl,
    }) => {
        const {
            credentialGroups,
            actions,
            activeEnvironment: { administrationScheme },
            routerActions,
        } = this.props;

        actions.createSignaturesSchemeConfirmPre(
            {
                selectedFunctionalGroups: toggleFunctionalGroups ? functionalGroups : [],
                selectedProducts: toggleProducts ? selectedProducts : [],
                signatureLevelsCounts,
                signatureType,
                topAmount,
                signatureDispatch: toggleDispatchControl,
            },
            credentialGroups,
        );

        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/create/confirm`);
    };

    renderContent = () => {
        const {
            activeEnvironment: { administrationScheme },
            selectedFunctionalGroups,
        } = this.props;

        let signatureLevelsCounts = {};
        if (administrationScheme === "advanced") {
            signatureLevelsCounts = configUtils.getArray("administration.signatures.signatureLevels").reduce(
                (acc, signLevel) => ({
                    ...acc,
                    [signLevel]: 0,
                }),
                signatureLevelsCounts,
            );
        } else {
            signatureLevelsCounts = {
                A: 1,
            };
        }

        const initialValues = {
            functionalGroups: selectedFunctionalGroups,
            signatureLevelsCounts,
            toggleFunctionalGroups: selectedFunctionalGroups.length > 0,
            topAmount: {
                amount: "",
                period: configUtils.get("administration.signatures.topAmount.defaultFrequency"),
            },
            signatureType: "",
            signatureDispatch: false,
            selectedProducts: [],
        };

        return (
            <>
                <MainContainer className="administration">
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={this.validationSchema}>
                        {this.renderForm}
                    </Formik>
                </MainContainer>
            </>
        );
    };

    renderForm = ({ isSubmitting, values }) => {
        const {
            activeEnvironment,
            capFrequencyList,
            functionalGroups,
            environmentProducts,
            masterCurrency,
            signatureTypeList,
        } = this.props;
        const { administrationScheme } = activeEnvironment;
        const { signatureType, toggleFunctionalGroups, toggleProducts } = values;

        return (
            <Form className="above-the-fold">
                <Container
                    className="flex-grow align-items-center cmf-container-white without-margin"
                    gridClassName="form-content">
                    {administrationScheme === "advanced" ? (
                        this.renderRequiredSignatures(administrationScheme)
                    ) : (
                        <Col className="col col-12">{this.renderRequiredSignatures(administrationScheme)}</Col>
                    )}
                </Container>
                <Container className="flex-grow align-items-center" gridClassName="form-content">
                    <Col className="col col-12 p-0">
                        <FeatureFlag id="feature.signatureSchema.dispatchControl">
                            <SectionTitle
                                title="details.administration.dispatcher.label"
                                subTitle="administration.signatures.dispatcher"
                            />
                        </FeatureFlag>
                    </Col>
                </Container>
                <Container
                    className="flex-grow align-items-center cmf-container-white"
                    gridClassName="form-content py-3">
                    <Col className="col col-12 mb-0-switch">
                        <Field component={SwitchField} idForm={FORM_ID} name="toggleDispatchControl" />
                    </Col>
                </Container>

                {signatureTypeList &&
                    this.renderTransactions(
                        capFrequencyList,
                        functionalGroups,
                        masterCurrency,
                        toggleFunctionalGroups,
                        toggleProducts,
                        environmentProducts,
                        signatureTypeList,
                        signatureType && signatureType === "AMOUNT",
                    )}

                <Container className="container--layout align-items-center mt-5" gridClassName="form-content">
                    <Col sm={12} md={4} className="col col-12 mb-12">
                        <Button bsStyle="primary" label="global.continue" loading={isSubmitting} type="submit" />
                    </Col>
                </Container>
            </Form>
        );
    };

    renderFunctionalGroups = (functionalGroups, showFunctionalGroups) => (
        <>
            <Field component={SwitchField} idForm={FORM_ID} name="toggleFunctionalGroups" />
            {showFunctionalGroups && (
                <Col sm={12} md={9} className="col col-12 px-0">
                    <div className="d-flex justify-content-start max-width-600">
                        <Col className="col col-9">
                            <MultiSelect
                                label="administration.signatures.functionalGroups.label"
                                name="functionalGroups"
                                options={arrayUtils.mapItemsIds(
                                    functionalGroups
                                        .map((functionalGroup) => ({
                                            label: i18nUtils.get(
                                                `administration.signatures.functionalGroups.${functionalGroup.idFeature}`, //eslint-disable-line
                                            ),
                                            value: functionalGroup.idFeature,
                                        }))
                                        .sort((fGroupA, fGroupB) => fGroupA.label.localeCompare(fGroupB.label)),
                                    "value",
                                )}
                                placeholder="administration.signatures.functionalGroups.add">
                                {({ label }) => (
                                    <span className="Select-value-label cmf-multi-select-label">{label}</span>
                                )}
                            </MultiSelect>
                        </Col>
                    </div>
                </Col>
            )}
        </>
    );

    renderProducts = (environmentProducts, toggleProducts) => (
        <>
            <Field component={SwitchField} idForm={FORM_ID} name="toggleProducts" />
            {toggleProducts && (
                <Col sm={12} md={9} className="col col-12 px-0">
                    <div className="d-flex justify-content-start max-width-600">
                        <Col className="col col-9">
                            <MultiSelect
                                label="administration.signatures.products.label"
                                name="selectedProducts"
                                options={arrayUtils.mapItemsIds(
                                    environmentProducts.map((product) => ({
                                        label: product.label,
                                        value: product.idProduct,
                                    })),
                                    "value",
                                )}
                                placeholder="administration.signatures.products.add">
                                {({ label }) => (
                                    <span className="Select-value-label cmf-multi-select-label">{label}</span>
                                )}
                            </MultiSelect>
                        </Col>
                    </div>
                </Col>
            )}
        </>
    );

    renderRequiredSignatures = (administrationScheme) => (
        <>
            <div
                className={
                    administrationScheme === "advanced"
                        ? "form-section-title admin-content-center"
                        : "form-section-title"
                }>
                <I18n
                    id="administration.signatures.create.requiredSignatures"
                    component="h2"
                    componentProps={{ className: "font-size-28-px" }}
                />
            </div>
            <div className={administrationScheme === "advanced" ? "admin-content-center" : ""}>
                <p>
                    <I18n id={`administration.signatures.create.${administrationScheme}.signersLevel`} />
                </p>
            </div>

            <div className="form-group form-group--stepper-field">
                <div className="control-label form-group-text">
                    <I18n id={`administration.signatures.create.${administrationScheme}.signersCount`} />
                </div>

                <Field
                    component={StepperFieldGroup}
                    hideLabel={administrationScheme === "medium"}
                    idForm={FORM_ID}
                    name="signatureLevelsCounts"
                    min={administrationScheme === "medium" ? "1" : "0"}
                    max={configUtils.get("administration.signatures.maxNeeded")}
                    options={
                        administrationScheme === "medium"
                            ? ["A"]
                            : configUtils.getArray("administration.signatures.signatureLevels")
                    }
                    showFirstErrorWhenEquals
                />
            </div>
        </>
    );

    renderTransactions = (
        capFrequencyList,
        functionalGroups,
        masterCurrency,
        showFunctionalGroups,
        toggleProducts,
        environmentProducts,
        signatureTypeList,
        transactionWithAmount,
    ) => (
        <>
            <Container className="flex-grow align-items-center" gridClassName="form-content">
                <Col className="col col-12 px-0">
                    <SectionTitle
                        title="administration.signatures.create.transactions"
                        subTitle="administration.signatures.create.transactions.subtitle"
                    />
                </Col>
            </Container>
            <Container
                className="container--layout flex-grow align-items-center cmf-container-white"
                rowClassName="justify-content-start"
                gridClassName="form-content">
                <Col
                    md={transactionWithAmount && masterCurrency && capFrequencyList ? 3 : 12}
                    className="px-0 d-flex align-items-center my-45 py-2">
                    <div className="form-group mb-0">
                        <div className="form-group-control-list my-0 mb-0-fieldset">
                            <Field
                                component={Selector}
                                idForm={FORM_ID}
                                name="signatureType"
                                options={signatureTypeList.map((signatureType) => ({
                                    id: signatureType,
                                    label: i18nUtils.get(
                                        `administration.signatures.create.signatureType.${signatureType}`,
                                    ),
                                }))}
                                renderAs="radio"
                            />
                        </div>
                    </div>
                </Col>
                {transactionWithAmount && masterCurrency && capFrequencyList && (
                    <Col sm={12} md={9} className="col col-12 max-width-500">
                        <ShowCurrency
                            masterCurrency={masterCurrency}
                            capFrequencyList={capFrequencyList}
                            form={FORM_ID}
                        />
                    </Col>
                )}
            </Container>
            <Container
                className="flex-grow align-items-center cmf-container-white"
                gridClassName="form-content mb-2 py-3">
                <Col className="col col-12 d-flex align-items-start mb-0-switch">
                    {functionalGroups && this.renderFunctionalGroups(functionalGroups, showFunctionalGroups)}
                </Col>
            </Container>
            <Container className="flex-grow align-items-center cmf-container-white" gridClassName="form-content py-3">
                <Col className="col col-12 d-flex align-items-start mb-0-switch">
                    {environmentProducts && this.renderProducts(environmentProducts, toggleProducts)}
                </Col>
            </Container>
        </>
    );

    validationSchema = () => {
        const {
            activeEnvironment: { administrationScheme },
        } = this.props;
        const maxSignersCount = configUtils.get("administration.signatures.maxNeeded");

        return Yup.lazy((values) =>
            Yup.object().shape({
                functionalGroups: values.toggleFunctionalGroups
                    ? Yup.array()
                          .of(Yup.string())
                          .min(1, i18nUtils.get("administration.signatures.functionalGroups.atLeastOne"))
                    : Yup.array().notRequired(),

                selectedProducts: values.toggleProducts
                    ? Yup.array()
                          .of(Yup.string())
                          .min(1, i18nUtils.get("administration.signatures.environmentProducts.atLeastOne"))
                    : Yup.array().notRequired(),

                signatureLevelsCounts: Yup.object().shape(
                    administrationScheme === "medium"
                        ? {
                              A: Yup.number()
                                  .moreThan(
                                      0,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .max(
                                      maxSignersCount,
                                      i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                          maxSignersCount,
                                      }),
                                  )
                                  .required(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`))
                                  .typeError(i18nUtils.get(`${FORM_ID}.signersCount.medium.required`)),
                          }
                        : configUtils.getArray("administration.signatures.signatureLevels").reduce((levels, level) => {
                              const newLevels = levels;
                              newLevels[level] = Object.values(values.signatureLevelsCounts).some((value) => value)
                                  ? Yup.number()
                                        .min(
                                            0,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .max(
                                            maxSignersCount,
                                            i18nUtils.get(`${FORM_ID}.signersCount.notInRange`, null, {
                                                maxSignersCount,
                                            }),
                                        )
                                        .required(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                        .typeError(i18nUtils.get(`${FORM_ID}.signersCount.advanced.required`))
                                  : Yup.number().moreThan(0, i18nUtils.get(`${FORM_ID}.signersCount.atLeastOne`));
                              return newLevels;
                          }, {}),
                ),
                signatureType: Yup.string().required(i18nUtils.get(`${FORM_ID}.signatureType.required`)),
                topAmount: Yup.object().when("signatureType", {
                    is: (signatureType) => signatureType === "AMOUNT",
                    then: Yup.object().shape({
                        amount: Yup.number()
                            .moreThan(0, i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.mustBePositive`))
                            .required(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`))
                            .typeError(i18nUtils.get(`${FORM_ID}.topAmount.amount.advanced.required`)),
                        period: Yup.string().required(),
                    }),
                    otherwise: Yup.object().notRequired(),
                }),
            }),
        );
    };

    renderDrawerContent = (drawerText) => {
        const { isDesktop } = this.props;
        return (
            <MainContainer>
                <Container className="container--layaut flex-grow align-items-center">
                    <div className="transfer-block">
                        <div className="scrollable-content" style={{ marginBottom: "0", height: !isDesktop && "55vh" }}>
                            <p dangerouslySetInnerHTML={{ __html: drawerText }} />
                        </div>
                    </div>
                </Container>
            </MainContainer>
        );
    };

    handleDownload = () => {
        const { dispatch } = this.props;
        dispatch(userActions.exportAdministrationSignatureHelpRequest("pdf"));
    };

    render() {
        const {
            fetching,
            isDesktop,
            activeEnvironment: { administrationScheme },
            fetchingExport,
        } = this.props;

        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        return (
            <>
                <Notification scopeToShow="signature" />
                <div className="admin-detail-head admin-detail-head-section px-0">
                    <Head backLinkTo={`/administration/${administrationScheme}/signaturesSchemes`} />

                    <div className="view-title d-flex w-100">
                        <h1>{i18nUtils.get("administration.signatures.create.title")}</h1>
                        <span className="align-self-center ml-3 mt-15">
                            <ButtonDrawer
                                image="images/help-outline.svg"
                                buttonClassName="drawer-button-image button-without-margin button-without-padding btn-info-signature-scheme mb-1 mh-auto"
                                headerClassName="mt-4"
                                content={this.renderDrawerContent(
                                    i18nUtils.get(`administration.signatures.help.content.${administrationScheme}`),
                                )}
                                width="560px"
                                navStyle="default p-0"
                                itemRightClassName="align-self-start min-width-auto"
                                headerContent={
                                    <div className="terms-coditions-header">
                                        <div className="tc-download-btn justify-content-end d-flex">
                                            <Button
                                                className="btn btn-link btn-noBlock mr-3"
                                                onClick={this.handleDownload}
                                                image="images/download.svg"
                                                label="global.download"
                                                loading={fetchingExport}
                                            />
                                        </div>
                                        <div className="form-section-title mx-3">
                                            <I18n component="h3" id="administration.signatures.help.title" />
                                        </div>
                                    </div>
                                }
                                closeClassname="mh-auto my-2 px-0"
                                flex
                                side
                                customHeight
                            />
                        </span>
                    </div>
                    <p className="regular-font text-lead">
                        <I18n id={`administration.signatures.create.${administrationScheme}.subtitle`} />
                    </p>
                </div>
                <PageLoading loading={fetching}>{this.renderContent()}</PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetchingExport: userSelectors.isFetchingExport(state),
});

export default connect(mapStateToProps)(resizableRoute(SignaturesSchemeCreate));
