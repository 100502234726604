export const globalTypes = {
    INIT: "INIT",
    CLEAN_UP: "CLEAN_UP",
    RESET_SELECTED_ELEMENTS: "RESET_SELECTED_ELEMENTS",
    SET_VOUCHER_SNACKBAR: "SET_VOUCHER_SNACKBAR",
};

export const actions = {
    resetSelectedElements: () => ({
        type: globalTypes.RESET_SELECTED_ELEMENTS,
    }),
    setVoucherSnackbar: (snackbarMessage) => ({
        type: globalTypes.SET_VOUCHER_SNACKBAR,
        snackbarMessage,
    }),
};
