import React, { Component } from "react";
import classNames from "classnames";
import { string, func, bool, int, shape } from "prop-types";
import { connect } from "react-redux";

import { actions as productsActions } from "reducers/products";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import EditableLabel from "pages/_components/fields/EditableLabel";
import Button from "pages/_components/Button";
import { actions as accountsActions } from "reducers/accounts";
import { maskedAccountNumber, productTypes, accountContextMenuItems } from "util/accounts";
import ContextMenu from "pages/_components/ContextMenu";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18nUtils from "util/i18n";

class DetailHeadTitle extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        account: shape({
            idEnvironment: int,
            idProduct: string,
            productType: string.isRequired,
            productAlias: string,
            label: string,
            shortLabel: string,
            extraInfo: string,
            number: string.isRequired,
            ownerName: string,
            currency: string.isRequired,
            extraInfoMarked: string,
            favorite: bool,
        }).isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    constructor(props) {
        super(props);
        this.titleRef = React.createRef();
        this.state = {
            isHorizontalTable: false,
        };

        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount() {
        if (this.titleRef.current) {
            this.titleRef.current.focus();
        }
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentDidUpdate() {
        if (this.titleRef.current) {
            this.titleRef.current.focus();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    saveAlias = (alias) => {
        const { account, dispatch } = this.props;
        dispatch(productsActions.changeProductAlias(alias, account.idProduct));
    };

    gotoProyectedBalance = () => {
        const { account, dispatch } = this.props;

        dispatch(push(`/account/proyectedBalance/${account.idProduct}`));
        if (account.number) {
            dispatch(accountsActions.proyectedBalance(account.idProduct));
            dispatch(accountsActions.diferedMovements(account.idProduct, "1"));
        }
    };

    gotoSetProductAlias = () => {
        const { account, dispatch } = this.props;
        dispatch(push(`/account/${account.idProduct}/setProductAlias`));
    };

    updatePredicate() {
        this.setState({ isHorizontalTable: window.innerWidth >= 861 && window.innerWidth <= 1080 });
    }

    render() {
        const { account, isDesktop, dispatch, activeEnvironment } = this.props;
        const { number, productAlias, productType, favorite, idProduct } = account;
        const { isHorizontalTable } = this.state;

        let arrayItemsDropdown = [];

        if (isDesktop) {
            if (isHorizontalTable) {
                arrayItemsDropdown = [
                    {
                        label: "accounts.projected.balance",
                        onClick: () => this.gotoProyectedBalance(),
                    },
                    {
                        label: "accounts.historic.balance",
                        onClick: () => dispatch(push(`/account/historicBalance/${account.idProduct}`)),
                    },
                ];
            }
        } else {
            arrayItemsDropdown = [
                {
                    label: "products.alias.account.edit.title.mobile",
                    onClick: () => dispatch(push(`/account/${account.idProduct}/setProductAlias`)),
                },
                {
                    label: "accounts.projected.balance",
                    onClick: () => this.gotoProyectedBalance(),
                },
                {
                    label: "accounts.historic.balance",
                    onClick: () => dispatch(push(`/account/historicBalance/${account.idProduct}`)),
                },
            ];
        }

        const proyectedButton = (
            <Button
                onClick={this.gotoProyectedBalance}
                className="toolbar-btn cmf-button-width text-nowrap my-0"
                label="accounts.projected.balance"
            />
        );
        const historicButton = (
            <Button
                className="toolbar-btn cmf-button-width text-nowrap my-0"
                label="accounts.historic.balance"
                onClick={() => dispatch(push(`/account/historicBalance/${account.idProduct}`))}
            />
        );

        const accountType = `accounts.productType.${productTypes[productType]}`;
        const accountTypeFormatted = i18nUtils.get(accountType);

        const title = productAlias || (
            <>
                <span>{maskedAccountNumber(number)}</span>
            </>
        );

        return (
            <>
                {isDesktop ? (
                    <Col sm={12} md={6} lg={6} className="pl-0">
                        <EditableLabel
                            isDesktop={isDesktop}
                            favorite={favorite}
                            onSave={this.saveAlias}
                            value={productAlias || maskedAccountNumber(number)}>
                            <h1 className="my-0">{title}</h1>
                        </EditableLabel>
                    </Col>
                ) : (
                    <>
                        <div className="view-title toolbar-item mt-0 mx-5 title-account-header-multiline-background-blue justify-content-center">
                            <span className="title-content header-mobile-title-background-blue">{title}</span>
                            <p>
                                <span>{accountTypeFormatted.toUpperCase()} </span>
                                <span>Nº {maskedAccountNumber(number)}</span>
                            </p>
                        </div>
                        <div className="toolbar-item">
                            <ContextMenu
                                isDesktop={isDesktop}
                                items={accountContextMenuItems(
                                    dispatch,
                                    activeEnvironment,
                                    idProduct,
                                    arrayItemsDropdown,
                                )}
                            />
                        </div>
                    </>
                )}

                {isHorizontalTable ? (
                    <>
                        <Col
                            sm={12}
                            md={6}
                            lg={6}
                            className={classNames("content-data-wrapper col col-12 data-wrapper-flex", {
                                "padding-right-2":
                                    accountContextMenuItems(dispatch, activeEnvironment, idProduct).length === 0,
                            })}>
                            <div className="data-wrapper-flex d-flex w-100 justify-content-end context-menu-horizontal-table">
                                <ContextMenu
                                    isDesktop={isDesktop}
                                    items={accountContextMenuItems(
                                        dispatch,
                                        activeEnvironment,
                                        idProduct,
                                        arrayItemsDropdown,
                                    )}
                                />
                            </div>
                        </Col>
                        <Col sm={12} className="content-data-wrapper mb-3 px-0">
                            <p className="account-display-font" style={{ fontWeight: "normal", color: "#000000" }}>
                                <span>{accountTypeFormatted.toUpperCase()} </span>
                                <span>Nº {maskedAccountNumber(number)}</span>
                            </p>
                        </Col>
                    </>
                ) : (
                    <>
                        {isDesktop && (
                            <>
                                <Col
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    className={classNames("content-data-wrapper col col-12 data-wrapper-flex", {
                                        "padding-right-2":
                                            accountContextMenuItems(dispatch, activeEnvironment, idProduct).length ===
                                            0,
                                    })}>
                                    <div className="data-wrapper-flex d-flex w-100 justify-content-end">
                                        {proyectedButton}
                                        {historicButton}
                                        <ContextMenu
                                            isDesktop={isDesktop}
                                            items={accountContextMenuItems(
                                                dispatch,
                                                activeEnvironment,
                                                idProduct,
                                                arrayItemsDropdown,
                                            )}
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} className="mb-3 px-0">
                                    <p className="account-display-font my-0">
                                        <span>{accountTypeFormatted.toUpperCase()} </span>
                                        <span>Nº {maskedAccountNumber(number)}</span>
                                    </p>
                                </Col>
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});
export default connect(mapStateToProps)(DetailHeadTitle);
