import React, { Component } from "react";
import { bool, node, string, arrayOf, oneOfType, shape } from "prop-types";

import PageLoading from "pages/_components/PageLoading";

export const MainContainerContext = React.createContext();

class MainContainer extends Component {
    static propTypes = {
        children: oneOfType([arrayOf(node), node]),
        className: string,
        customHeight: bool,
        loadingClassName: string,
        showLoader: bool,
        showChildrenWithLoader: bool,
        viewContentClassName: string,
        viewContentStyle: shape({}),
        viewPageClassName: string,
    };

    static defaultProps = {
        children: null,
        className: "main-container px-0",
        loadingClassName: "",
        showLoader: false,
        showChildrenWithLoader: false,
        viewContentStyle: {},
        viewPageClassName: "",
        viewContentClassName: "",
        customHeight: false,
    };

    render() {
        const {
            children,
            className,
            customHeight,
            loadingClassName,
            showChildrenWithLoader,
            showLoader,
            viewContentClassName,
            viewContentStyle,
            viewPageClassName,
        } = this.props;

        return (
            <PageLoading loading={showLoader} className={loadingClassName}>
                <MainContainerContext.Provider value={this.scrollRef}>
                    <div className={`view-page ${viewPageClassName}`}>
                        <div
                            className={`view-content ${viewContentClassName} ${customHeight ? "custom-height" : ""}`}
                            style={viewContentStyle}
                            ref={(ref) => {
                                this.scrollRef = ref;
                            }}>
                            <main className={className}>
                                {showChildrenWithLoader ? children : !showLoader && children}
                            </main>
                        </div>
                    </div>
                </MainContainerContext.Provider>
            </PageLoading>
        );
    }
}

export default MainContainer;
