import React from "react";
import { string } from "prop-types";

import I18n from "pages/_components/I18n";
import ListMessage from "pages/_components/ListMessage";

const EndOfList = ({ label }) => (
    <ListMessage className="flex">
        <I18n id="" defaultValue={label} component="p" />
    </ListMessage>
);
EndOfList.propTypes = {
    label: string.isRequired,
};

export default EndOfList;
