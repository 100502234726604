import { put, takeLatest } from "redux-saga/effects";

import types from "reducers/types/form";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";

const sagas = [takeLatest(types.READ_TRANSACTION_SUCCESS, getFileContents)];

export default sagas;

function* getFileContents({ transaction }) {
    yield put(transactionLinesActions.listTransactionLinesRequest({ id: transaction.idTransaction, pageNumber: 1 }));
}
