import React, { useCallback, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { Field, Form, withFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import Yup from "yup";
import classNames from "classnames";
import moment from "moment";

import { actions as transactionsActions } from "reducers/transactions";
import { selectors as fundcorpSelectors, actions as fundcorpActions } from "reducers/fundcorp";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as holidaysActions, selectors as holidaysSelector } from "reducers/holidays";
import { maskedAccountNumber } from "util/accounts";
import { RESCUE_TYPE } from "constants.js";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import * as schedulerUtils from "util/scheduler";

import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Selector from "pages/_components/fields/formik/Selector";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import DrawerModal from "pages/_components/drawer/DrawerModal";
import AmountField from "pages/_components/fields/formik/AmountField";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import FundRescueHint from "./FundRescueHint";
import FundRescueScheduler from "./_rescue/FundRescueScheduler";

const FORM_ID = "fund.operation.rescue";
const { BY_AMOUNT, BY_SHAREACCOUNT, TOTAL } = RESCUE_TYPE;

function FundRescue({
    dispatch,
    isDesktop,
    shareAccounts,
    positionsByShareAccount,
    values,
    setFieldValue,
    fetching,
    activeEnvironment,
    location,
    holidays,
    fetchingHolidays,
}) {
    const [showDrawer, setShowDrawer] = useState(false);
    const [contentDrawer, setContentDrawer] = useState(null);
    const [isValidAccount, setIsValidAccount] = useState(true);

    const { fund, shareAccount, rescueType } = values;

    const hasFundData = () => !!shareAccounts && shareAccounts.length > 0;

    const selectedShareAccount = () => values?.shareAccount;

    const showMvp3Release = configUtils.getBoolean("frontend.show.MVP3.functionalities");
    const isValidFundPosition = (positionList) =>
        positionList.some((position) => showMvp3Release || position.rescatePlazo === "0");

    const getShareAccountOptions = () => {
        if (!hasFundData()) {
            return [];
        }
        return shareAccounts
            .filter((sAccount) =>
                positionsByShareAccount.some(
                    (position) => position.idNumber === sAccount.id && isValidFundPosition(position.positionList),
                ),
            )
            .map(({ id, description }) => ({
                value: id,
                label: `${id} ${description}`,
            }));
    };

    const getPositionList = useCallback(
        (shareAccountNumber) => {
            if (!shareAccountNumber || !positionsByShareAccount) {
                return undefined;
            }
            return positionsByShareAccount
                .filter(({ idNumber }) => idNumber.toString() === shareAccountNumber.toString())
                .reduce((prev, { positionList }) => [...prev, ...positionList], []);
        },
        [positionsByShareAccount],
    );

    const getPosition = (positionId, positionList) => {
        if (!positionId || !positionList) {
            return undefined;
        }

        const [fundNumber, fundClass, fundCurrency] = positionId.split(".");
        const result = positionList.find(
            (position) =>
                position.fondoNumero.toString() === fundNumber &&
                position.tipoVCPAbreviatura === fundClass &&
                position.monedaIso === fundCurrency,
        );
        return result;
    };

    useEffect(() => {
        dispatch(fundcorpActions.createRescueRequestPre());
        dispatch(holidaysActions.listHolidays());
        dispatch(transactionsActions.saveTransactionRoute(location?.pathname))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setContentDrawer(<FundRescueHint isDesktop={isDesktop} />);
    }, [isDesktop]);

    useEffect(() => {
        const positionList = getPositionList(shareAccount);
        if (!fund || !shareAccount || !positionList) {
            return;
        }

        const [fundNumber, fundClass, fundCurrency] = fund.split(".");
        const fundPosition = positionList.find(
            (position) =>
                position.fondoNumero.toString() === fundNumber &&
                position.tipoVCPAbreviatura === fundClass &&
                position.monedaIso === fundCurrency,
        );

        if (!fundPosition) {
            setFieldValue("fund", "", false);
            return;
        }

        const {
            cuotapartesTotales: maxCuotapartes,
            cuotapartesValuadas: amount,
            monetaryAccount: { monedaIso, productAlias, productNumber, productType, validForRescue, productCurrency },
        } = fundPosition;

        const creditAccountLabel = `${productAlias || ""} ${maskedAccountNumber(
            productNumber,
        )} ${productType} ${monedaIso}`;

        setIsValidAccount(validForRescue);

        setFieldValue("maxCuotapartes", maxCuotapartes, false);
        setFieldValue("maxAmount", { amount, currency: productCurrency }, false);
        setFieldValue("creditAccountLabel", creditAccountLabel, false);
    }, [shareAccount, fund, setFieldValue, getPositionList]);

    const handleBack = () => {
        dispatch(push("/fundcorpOperations"));
    };

    const getCurrencyOptions = () => {
        const shareAccountNumber = selectedShareAccount();
        const positionList = getPositionList(shareAccountNumber);
        const position = getPosition(fund, positionList);

        if (!hasFundData() || shareAccountNumber === "" || !position) {
            return [{ id: 0, label: i18n.get(`currency.label.ARS`) }];
        }

        const {
            monetaryAccount: { productCurrency, monedaIso },
        } = position;
        const currencyOption = { id: productCurrency, label: i18n.get(`currency.label.${monedaIso}`) };
        return [currencyOption];
    };

    const getFundOptions = () => {
        const shareAccountNumber = selectedShareAccount();
        if (!hasFundData() || shareAccountNumber === "") {
            return [];
        }

        let positionList = getPositionList(shareAccountNumber);
        if (!showMvp3Release) {
            positionList = positionList.filter(({ rescatePlazo }) => rescatePlazo === "0");
        }
        return positionList.map(
            ({
                fondoNumero,
                fondoNombre,
                tipoVCPDescripcion: fondoClase,
                tipoVCPAbreviatura: fondoAbreviatura,
                monedaIso: fondoMoneda,
            }) => ({
                value: `${fondoNumero}.${fondoAbreviatura}.${fondoMoneda}`,
                label: `${fondoNombre} ${fondoClase} (${fondoMoneda})`,
            }),
        );
    };

    const getRescueTypeOptions = () => [
        {
            id: BY_AMOUNT,
            label: <I18n id={`${FORM_ID}.type.amount`} upperCase />,
        },
        {
            id: BY_SHAREACCOUNT,
            label: <I18n id={`${FORM_ID}.type.shareAccount`} upperCase />,
        },
        {
            id: TOTAL,
            label: <I18n id={`${FORM_ID}.type.total`} upperCase />,
        },
    ];

    const renderHintButton = () => (
        <button type="button" className="btn-hint fund-rescue p-0" onClick={() => setShowDrawer(true)}>
            <Image src={`images/info${!isDesktop ? "White" : "UserInvite"}.svg`} />
            {isDesktop ? (
                <I18n
                    id={`${FORM_ID}.fund.hint`}
                    component="p"
                    componentProps={{ className: "hint-text text-nowrap my-0" }}
                />
            ) : (
                undefined
            )}
        </button>
    );

    const renderHead = () => (
        <>
            <div className="admin-detail-head px-0">
                <Head
                    onBack={handleBack}
                    headerClassName={classNames({
                        "blue-main-header-mobile": !isDesktop,
                    })}
                    centerContentClassName={classNames({
                        "mx-5": !isDesktop,
                    })}
                    title={!isDesktop ? "fund.operation.rescue.head.label" : undefined}
                    titleClassName="text-white"
                    accessibilityTextId="fund.operation.rescue.head.label"
                    rightContent={!isDesktop ? renderHintButton : undefined}
                />
                {isDesktop && <h1 className="mt-1 mb-3">{i18n.get("fund.operation.rescue.head.label")}</h1>}
            </div>
        </>
    );

    const renderCreditAccount = () => {
        const shareAccountNumber = selectedShareAccount();
        const positionList = getPositionList(shareAccountNumber);
        const position = getPosition(fund, positionList);

        if (!shareAccountNumber || !positionList || !position) {
            return (
                <Col
                    sm={12}
                    className={classNames("align-items-center d-flex space-between w-100 px-md-3", {
                        "px-0": isDesktop,
                    })}>
                    <div className="d-flex align-items-center">
                        <I18n
                            id={`${FORM_ID}.creditAccount.label`}
                            component="p"
                            componentProps={{ className: "f-size-55 text-uppercase pr-1" }}
                        />
                    </div>
                </Col>
            );
        }

        const {
            monetaryAccount: {
                monedaIso,
                productAlias,
                productBalance,
                productNumber,
                productType,
                productCurrency,
                validForRescue,
            },
        } = position;

        let label;
        if (!validForRescue) {
            label = i18n.get(`${FORM_ID}.creditAccount.noAccount`);
        } else {
            label = `${productAlias || ""} ${maskedAccountNumber(productNumber)} ${productType} ${monedaIso}`;
        }

        return (
            <Col
                sm={12}
                className={classNames("w-100 px-md-3", { "align-items-center space-between px-0": isDesktop })}>
                <div className={classNames({ "space-between w-100": !isDesktop }, { "d-flex": isDesktop })}>
                    <I18n
                        id={`${FORM_ID}.creditAccount.label`}
                        component="p"
                        componentProps={{ className: "f-size-55 text-uppercase pr-1 mb-0" }}
                    />
                    <p className="f-size-55 font-light text-uppercase pr-1 mb-0">{label}</p>
                </div>

                <div className={classNames({ "w-100": !isDesktop })}>
                    <FormattedAmount
                        currency={productCurrency}
                        quantity={productBalance}
                        className={classNames("data-amount d-flex justify-content-end", {
                            "f-size-55 font-light": !isDesktop,
                        })}
                        medium
                        notBold
                    />
                </div>
            </Col>
        );
    };

    const renderTotalHolding = () => {
        const shareAccountNumber = selectedShareAccount();
        const positionList = getPositionList(shareAccountNumber);
        const position = getPosition(fund, positionList);

        if (!shareAccountNumber || !positionList || !position) {
            return (
                <Col
                    sm={12}
                    className={classNames("align-items-center d-flex space-between w-100 px-md-3", {
                        "px-0": isDesktop,
                    })}>
                    <div className="d-flex align-items-center">
                        <I18n
                            id={`${FORM_ID}.totalHolding.label`}
                            component="p"
                            componentProps={{ className: "f-size-55 text-uppercase font-weight-bold pr-1" }}
                        />
                    </div>
                </Col>
            );
        }

        const { cuotapartesTotales: cuotapartes, cuotapartesValuadas: monto, monedaIso: currency } = position;

        if (rescueType === BY_AMOUNT) {
            return (
                <Col
                    sm={12}
                    className={classNames("align-items-center d-flex space-between w-100 px-md-3", {
                        "px-0": isDesktop,
                    })}>
                    <div className="d-flex align-items-center">
                        <I18n
                            id={`${FORM_ID}.totalHolding.label`}
                            component="p"
                            componentProps={{ className: "f-size-55 text-uppercase font-weight-bold pr-1" }}
                        />
                    </div>

                    <div className="d-flex">
                        <FormattedAmount currency={currency} quantity={monto} medium notBold />
                    </div>
                </Col>
            );
        }

        if (rescueType === BY_SHAREACCOUNT) {
            return (
                <Col
                    sm={12}
                    className={classNames("align-items-center d-flex space-between w-100 px-md-3", {
                        "px-0": isDesktop,
                    })}>
                    <div className="d-flex align-items-center">
                        <I18n
                            id={`${FORM_ID}.totalHolding.label`}
                            component="p"
                            componentProps={{ className: "f-size-55 text-uppercase font-weight-bold pr-1" }}
                        />
                    </div>

                    <div className="d-flex">
                        <FormattedAmount
                            currency="CUOTAPARTES"
                            quantity={cuotapartes}
                            minimumDecimalsCustom={4}
                            maximumDecimalsCustom={4}
                            medium
                            notBold
                        />
                    </div>
                </Col>
            );
        }

        return (
            <>
                <Col
                    sm={12}
                    className={classNames("align-items-center d-flex space-between w-100 px-md-3", {
                        "px-0": isDesktop,
                    })}>
                    <div className="d-flex align-items-center">
                        <I18n
                            id={`${FORM_ID}.amount.label`}
                            component="p"
                            componentProps={{ className: "f-size-55 text-uppercase font-weight-bold pr-1" }}
                        />
                    </div>

                    <div className="d-flex">
                        <FormattedAmount currency={currency} quantity={monto} medium notBold />
                    </div>
                </Col>
                <Col
                    sm={12}
                    className={classNames("align-items-center d-flex space-between w-100 px-md-3", {
                        "px-0": isDesktop,
                    })}>
                    <div className="d-flex align-items-center">
                        <I18n
                            id={`${FORM_ID}.shareAccountQuantity.label`}
                            component="p"
                            componentProps={{ className: "f-size-55 text-uppercase font-weight-bold pr-1" }}
                        />
                    </div>

                    <div className="d-flex">
                        <FormattedAmount
                            currency="CUOTAPARTES"
                            noCurrency={!isDesktop}
                            quantity={cuotapartes}
                            minimumDecimalsCustom={4}
                            maximumDecimalsCustom={4}
                            medium
                            notBold
                        />
                    </div>
                </Col>
            </>
        );
    };

    const closeDrawer = () => {
        setShowDrawer(false);
    };

    if (!hasFundData()) {
        return <div className="admin-detail-head px-0">{renderHead()}</div>;
    }

    const showMvp5Release = configUtils.getBoolean("frontend.show.MVP5.functionalities");

    const data = {
        enabledWeekDays: [1, 2, 3, 4, 5, 6, 7],
        firstWorkingDate: moment(),
        maxDaysToSchedule: 10000,
        nonWorkingDays: [],
        lang: "es",
        mode: "edit",
        programable: true,
        schedulable: true,
        activeEnvironment,
    };

    return (
        <>
            <Notification scopeToShow="rescue" />
            {renderHead()}
            <PageLoading loading={fetching || fetchingHolidays}>
                <MainContainer>
                    <Form>
                        <div className="above-the-fold">
                            {showDrawer && (
                                <DrawerModal
                                    content={contentDrawer}
                                    showDrawerRemote={showDrawer}
                                    isDesktop={isDesktop}
                                    width={isDesktop ? "30%" : "100%"}
                                    closeRemotely={() => closeDrawer()}
                                    noBlueHeader
                                />
                            )}
                            <Container
                                className="flex-grow align-items-center container-white my-2 py-2"
                                gridClassName="form-content pb-2 px-0">
                                <Col sm={12} md={9} lg={6} xl={7}>
                                    <Col
                                        xs={12}
                                        className={classNames("align-items-center px-md-3", { "px-0": isDesktop })}>
                                        <Field
                                            component={Selector}
                                            options={getShareAccountOptions()}
                                            idForm={FORM_ID}
                                            name="shareAccount"
                                        />
                                    </Col>
                                    {renderCreditAccount()}
                                    <Col
                                        xs={12}
                                        className={classNames("align-items-center px-md-3", { "px-0": isDesktop })}>
                                        <Field
                                            component={Selector}
                                            options={getFundOptions()}
                                            idForm={FORM_ID}
                                            name="fund"
                                        />
                                        {isDesktop ? renderHintButton() : undefined}
                                    </Col>
                                    <Col
                                        xs={12}
                                        className={classNames("align-items-center px-md-3", { "px-0": isDesktop })}>
                                        <span className="d-flex fundcorp__radio">
                                            <Field
                                                component={Selector}
                                                formGroupClassName="mb-0"
                                                renderAs="radio"
                                                inLineControl
                                                name="rescueType"
                                                options={getRescueTypeOptions()}
                                            />
                                        </span>
                                    </Col>
                                    {renderTotalHolding()}
                                    {values.rescueType === BY_AMOUNT && (
                                        <Col
                                            xs={12}
                                            className={classNames("align-items-center px-md-3", {
                                                "px-0": isDesktop,
                                            })}>
                                            <Field
                                                idForm={FORM_ID}
                                                component={AmountField}
                                                name="amount"
                                                autocomplete="off"
                                                isFocused
                                                data={{
                                                    options: getCurrencyOptions(),
                                                }}
                                                clearable={false}
                                                label={`${FORM_ID}.amount.label`}
                                                maxLength={15}
                                                disableSelect
                                                fixedDecimalScale
                                            />
                                        </Col>
                                    )}
                                    {values.rescueType === BY_SHAREACCOUNT && (
                                        <Col
                                            xs={12}
                                            className={classNames("align-items-center px-md-3", {
                                                "px-0": isDesktop,
                                            })}>
                                            <Field
                                                idForm={FORM_ID}
                                                component={AmountField}
                                                name="cuotapartes"
                                                autocomplete="off"
                                                isFocused
                                                hidePlaceholder
                                                hideCurrency
                                                maxLength={15}
                                                minimumDecimalsCustom={4}
                                                maximumDecimalsCustom={4}
                                                fixedDecimalScale
                                            />
                                        </Col>
                                    )}
                                    {showMvp5Release && (
                                        <Col xs={12} className="schedule justify-content-left">
                                            <Field
                                                component={FundRescueScheduler}
                                                data={data}
                                                name="scheduler"
                                                location={location}
                                                isDesktop={isDesktop}
                                                label={`${FORM_ID}.scheduler.label`}
                                                FORM_ID="fund.sheduler.rescue"
                                                nonWorkingDays={holidays}
                                            />
                                        </Col>
                                    )}
                                </Col>
                            </Container>
                        </div>
                        <Container className="flex-grow align-items-center my-2" gridClassName="form-content">
                            <Col lg={3} xl={3} md={9} sm={12}>
                                <Row
                                    className={classNames("admin-content-center", {
                                        "mx-0 mt-2": !isDesktop,
                                    })}>
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.continue"
                                        disabled={!isValidAccount}
                                    />
                                </Row>
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </PageLoading>
        </>
    );
}

FundRescue.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool,
    shareAccounts: arrayOf(shape({})).isRequired,
    positionsByShareAccount: arrayOf(
        shape({
            positionList: arrayOf(shape({})),
            title: string,
            total: number,
        }),
    ).isRequired,
    values: shape({}).isRequired,
    setFieldValue: func.isRequired,
    fetching: bool,
    activeEnvironment: shape({
        permmisions: shape({}),
    }).isRequired,
    location: shape({
        pathname: string.isRequired,
    }).isRequired,
    holidays: arrayOf(string).isRequired,
    fetchingHolidays: bool.isRequired,
};

FundRescue.defaultProps = {
    isDesktop: true,
    fetching: false,
};

const mapStateToProps = (state) => ({
    shareAccounts: fundcorpSelectors.getShareAccounts(state),
    positionsByShareAccount: fundcorpSelectors.getPositionsByShareAccount(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    fetching: fundcorpSelectors.getFetching(state),
    summary: fundcorpSelectors.getSummary(state),
    holidays: holidaysSelector.getHolidays(state),
    fetchingHolidays: holidaysSelector.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => {
            const {
                summary,
                location: { pathname },
                match: { params },
            } = props;

            const isCommingBackFromSummary =
                pathname === "/fundcorpOperations/rescue/back" && summary?.selectedShareAccount;

            if (isCommingBackFromSummary) {
                const {
                    selectedShareAccount,
                    selectedFund: { fondoNumero, tipoVCPAbreviatura: fondoAbreviatura, monedaIso: fondoMoneda },
                    rescueType,
                    amount,
                    cuotapartes,
                    selectedScheduler,
                } = summary;
                const shareAccount = selectedShareAccount?.id;
                const fund = `${fondoNumero}.${fondoAbreviatura}.${fondoMoneda}`;
                const formattedCuotapartes = rescueType === BY_SHAREACCOUNT ? { amount: cuotapartes } : { amount: "" };
                const formattedAmmount =
                    rescueType === BY_AMOUNT
                        ? { amount: amount.quantity, currency: amount.currency }
                        : { currency: "0", amount: "" };
                const scheduler = { ...selectedScheduler, valueDate: selectedScheduler?.newValueDate };
                return {
                    shareAccount,
                    fund,
                    rescueType,
                    amount: formattedAmmount,
                    cuotapartes: formattedCuotapartes,
                    scheduler,
                };
            }

            const rescueListRegexPath = /\/fundcorpOperations\/rescue\/\d+\/\w+\/\w{3}\/\d+/;
            const isCommingFromPositionList =
                rescueListRegexPath.test(pathname) && params?.fondoNumero && params?.cuotapartistaNumero;

            if (isCommingFromPositionList) {
                const { fondoNumero, fondoAbreviatura, fondoMoneda, cuotapartistaNumero } = params;

                return {
                    shareAccount: cuotapartistaNumero,
                    fund: `${fondoNumero}.${fondoAbreviatura}.${fondoMoneda}`,
                    rescueType: BY_AMOUNT,
                    amount: { currency: "0", amount: "" },
                    cuotapartes: { amount: "" },
                    scheduler: {},
                };
            }

            return {
                shareAccount: "",
                fund: "",
                rescueType: BY_AMOUNT,
                amount: { currency: "0", amount: "" },
                cuotapartes: { amount: "" },
                scheduler: {},
            };
        },
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    amount: Yup.object().shape({
                        amount:
                            values.rescueType === BY_AMOUNT
                                ? Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`))
                                : Yup.mixed().nullable(),
                    }),
                    shareAccount: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    fund: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    cuotapartes: Yup.object().shape({
                        amount:
                            values.rescueType === BY_SHAREACCOUNT
                                ? Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`))
                                : Yup.mixed().nullable(),
                    }),
                }),
            ),

        handleSubmit: (
            {
                amount,
                cuotapartes,
                fund,
                maxAmount,
                maxCuotapartes,
                rescueType,
                shareAccount,
                creditAccountLabel,
                scheduler,
            },
            formikBag,
        ) => {
            const formattedCuotapartes = cuotapartes?.amount && cuotapartes.amount.toString();
            const parsedCuotapartes = formattedCuotapartes && Number(formattedCuotapartes.replace(",", "."));
            const { dispatch, shareAccounts, positionsByShareAccount } = formikBag.props;
            const selectedShareAccount = shareAccounts.find(
                (account) => account.id.toString() === shareAccount || account.id === shareAccount,
            );
            const positionListFund = positionsByShareAccount
                .filter(({ idNumber }) => idNumber.toString() === shareAccount.toString())
                .reduce((prev, { positionList }) => [...prev, ...positionList], []);

            const [fundNumber, fundClass, fundCurrency] = fund.split(".");
            const selectedFund = positionListFund.find(
                (position) =>
                    position.fondoNumero.toString() === fundNumber &&
                    position.tipoVCPAbreviatura === fundClass &&
                    position.monedaIso === fundCurrency,
            );

            const {
                monetaryAccount: { productCurrency },
            } = selectedFund;

            const equivalentAmount = { quantity: 0, currency: "" };
            let equivalentCuotapartes = 0;
            if (rescueType === BY_AMOUNT) {
                equivalentAmount.quantity = amount.amount;
                equivalentAmount.currency = productCurrency.toString();

                equivalentCuotapartes = amount.amount / selectedFund.ultimoVCPValor;
            } else if (rescueType === BY_SHAREACCOUNT) {
                equivalentCuotapartes = parsedCuotapartes;

                equivalentAmount.quantity = parsedCuotapartes * selectedFund.ultimoVCPValor;
                equivalentAmount.currency = productCurrency.toString();
            } else {
                equivalentCuotapartes = maxCuotapartes;

                equivalentAmount.quantity = maxAmount.amount;
                equivalentAmount.currency = maxAmount.currency.toString();
            }

            let schedulerToSend = scheduler && scheduler.selectedOption !== schedulerUtils.TODAY ? scheduler : null;
            const newValueDate = schedulerToSend?.valueDate;
            schedulerToSend = { ...schedulerToSend, newValueDate };
            delete schedulerToSend.valueDate;

            dispatch(
                fundcorpActions.createRescueRequest(
                    {
                        amount: equivalentAmount,
                        cuotapartes: parsedCuotapartes,
                        rescueType,
                        maxAmount: { quantity: maxAmount.amount, currency: maxAmount.currency.toString() },
                        maxCuotapartes,
                        selectedShareAccount,
                        selectedFund,
                        equivalentAmount,
                        equivalentCuotapartes,
                        creditAccountLabel,
                        rescueDate: new Date(),
                        selectedScheduler: schedulerToSend,
                    },
                    formikBag,
                ),
            );
        },
    }),
)(FundRescue);
