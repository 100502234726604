import { connect } from "react-redux";
import { compose } from "redux";

import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";

import FormTransition from "pages/forms/_components/FormTransition";

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    metadata: formSelectors.getMetadata(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    mode: formSelectors.getMode(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
});

export default compose(connect(mapStateToProps))(FormTransition);
