import * as API from "middleware/api";

export const requestInvitationCode = (_captcha, docCountry, docNumber, docType, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.digital.finish",
        { _captcha, docCountry, docNumber, docType },
        _exchangeToken,
    );

export const requestInvitationCodePre = () => API.executeAnonymous("/enrollment.digital.pre");

export const requestPersonalData = (_code, _verificationCode, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.personalData", { _code, _verificationCode }, _exchangeToken);

export const requestSecuritySeals = (_exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.securitySeals.list", {}, _exchangeToken);

export const requestVerificationCodePre = (_code, _exchangeToken, isCredentialWhitening) =>
    API.executeWithExchangeToken("/enrollment.wizard.pre", { _code, isCredentialWhitening }, _exchangeToken);

export const validateUser = (user, documentType, documentNumber, id, _exchangeToken, isCredentialWhitening) =>
    API.executeWithExchangeToken(
        "/enrollment.validate.user.credential",
        { _pin: user, documentNumber, documentType, invitationId: id, isCredentialWhitening },
        _exchangeToken,
    );
export const validatePassword = (password, documentType, documentNumber, id, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.validate.password.credential",
        { _password: password, documentType, documentNumber, invitationId: id },
        _exchangeToken,
    );

export const resendVerificationCode = (_code, _exchangeToken, isCredentialWhitening) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.resendVerificationCode",
        { _code, isCredentialWhitening },
        _exchangeToken,
    );

export const setUserCredentials = (
    email,
    _code,
    _password,
    _passwordConfirmation,
    _securitySeal,
    _verificationCode,
    _exchangeToken,
    _isUSA,
    _user,
    _userConfirmation,
    isCredentialWhitening,
) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.finish",
        {
            email,
            _code,
            _password,
            _passwordConfirmation,
            _securitySeal,
            _verificationCode,
            _isUSA,
            _user,
            _userConfirmation,
            _pin: _user,
            isCredentialWhitening,
        },
        _exchangeToken,
    );

export const esignAccept = (userMail, idEnvironment, _exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.esignAccept", { userMail, idEnvironment }, _exchangeToken);

export const sendIRS = (IRS, validateSSNID) =>
    API.executeWithAccessToken("/pendingActions.irs", { IRS, validateSSNID });

export const sendPEP = () => API.executeWithAccessToken("/pendingActions.pep");

export const sendDocumentsByEmail = (userEmail) =>
    API.executeAnonymous("/session.sendGeneralDocumentsByMail", { userEmail });

export const verifyInvitationCode = (_code, isCredentialWhitening) =>
    API.executeAnonymous("/enrollment.invitationCode.verify", { _code, isCredentialWhitening });

export const verifyInvitationCodePre = (_code) =>
    API.executeAnonymous("/enrollment.invitationCode.preVerify", { _code });

export const verifyVerificationCode = (
    personalDataEnabled,
    _code,
    _verificationCode,
    _exchangeToken,
    isCredentialWhitening,
) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.verificationCode",
        { personalDataEnabled, _code, _verificationCode, isCredentialWhitening },
        _exchangeToken,
    );

export const setCredentials = (
    _newPassword,
    _passwordConfirmation,
    _user,
    _userConfirmation,
    invitationId,
    documentNumber,
    documentType,
    exchangeToken,
) =>
    API.executeWithExchangeToken(
        "/login.recoverPinAndPassword.credentialWhitening",
        {
            _newPassword,
            _passwordConfirmation,
            _user,
            _userConfirmation,
            _pin: _user,
            _password: _newPassword,
            documentNumber,
            documentType,
            invitationId,
        },
        exchangeToken,
    );
