import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, withFormik, Form } from "formik";
import Yup from "yup";
import { bool } from "prop-types";

import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";

import TextField from "pages/_components/fields/TextField";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

import * as i18n from "util/i18n";

const FORM_ID = "payments.enabledContributors.list.enable";

class EnableContributor extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { fetching, isDesktop } = this.props;

        return (
            <Form>
                <Container className="align-items-center flex-grow" gridClassName="form-content">
                    <Col className="col-12" lg={9} md={9} sm={12}>
                        {isDesktop && (
                            <h2 className="text-center my-5">
                                {i18n.get("payments.enabledContributors.list.enable.title")}
                            </h2>
                        )}
                        <Field
                            autoFocus
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="cuit"
                            type="text"
                            inputmode="numeric"
                            maxLength={12}
                            className="contributor-field"
                        />
                        <Field
                            autoFocus
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="reference"
                            type="text"
                            className="contributor-field"
                        />
                        <Button
                            type="submit"
                            label="payments.enabledContributors.list.enable.button"
                            bsStyle="primary"
                            loading={fetching}
                            className="my-5"
                        />
                    </Col>
                </Container>
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: paymentsAFIPSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            cuit: "",
            reference: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                cuit: Yup.string()
                    .nullable()
                    .required(i18n.get("payments.enabledContributors.list.enable.cuit.empty")),
                reference: Yup.string()
                    .nullable()
                    .required(i18n.get("payments.enabledContributors.list.enable.reference.empty")),
            }),
        handleSubmit: (values, formikBag) => {
            const { dispatch, closeDrawer } = formikBag.props;
            const { cuit, reference } = values;

            dispatch(paymentsAFIPActions.enableContributor(cuit, reference, closeDrawer));
        },
    }),
)(EnableContributor);
