import React from "react";
import { shape } from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers/cedip";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";
import Heading from "pages/_components/Heading";

const FORM_ID = "cedip.reject";

function RejectCedipTicketData({ transactionData }) {
    const maskCedipId = (idCedip) => {
        const maskedPart = "***";
        const visiblePart = idCedip.slice(-7);
        return maskedPart + visiblePart;
    };

    const { cedip, idCedip } = transactionData;
    const { montoACobrar, tipoMoneda, transmisiones } = cedip;

    const maskedCedipId = maskCedipId(idCedip);
    const emissionDate = `${cedip?.fechaEmision}T00:00:00.000-03`;
    const dueDate = `${cedip?.fechaVencimiento}T00:00:00.000-03`;

    const pendingTransmission = transmisiones?.find((item) => item.estado === "PENDIENTE");
    const { cuitTransmisor, razonSocialTransmisor, fecha } = pendingTransmission;

    return (
        transactionData?.idTransaction && (
            <>
                {transactionData?.submitDateTime && (
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.transaction.date.time`}
                        data={<FormattedDate date={transactionData.submitDateTime} dateFormat="dd/MM/yyyy" showTime />}
                    />
                )}

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label={`${FORM_ID}.transaction.transaction`}
                    data={transactionData.idTransaction.substring(0, 8).toUpperCase()}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label={`${FORM_ID}.transaction.certificateCedipNumber`}
                    data={maskedCedipId}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label={`${FORM_ID}.transaction.emissionDate`}
                    data={<FormattedDate date={emissionDate} dateFormat="dd/MM/yyyy" />}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label={`${FORM_ID}.transaction.dueDate`}
                    data={<FormattedDate date={dueDate} dateFormat="dd/MM/yyyy" />}
                />

                {razonSocialTransmisor && (
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="deposits.cedip.recieved.from"
                        data={razonSocialTransmisor}
                    />
                )}

                {cuitTransmisor && (
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.transaction.documentType`}
                        data={cuitTransmisor}
                    />
                )}

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc line-height-1"
                    label={`${FORM_ID}.transaction.amount`}
                    data={<FormattedAmount currency={tipoMoneda} quantity={montoACobrar} notBold />}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label={`${FORM_ID}.transaction.transmissionDate`}
                    data={<FormattedDate date={fecha} dateFormat="dd/MM/yyyy" />}
                />

                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label={`${FORM_ID}.transaction.status`}
                    data={i18n.get("cedip.reject.transaction.rejected")}
                />
            </>
        )
    );
}

RejectCedipTicketData.propTypes = {
    transactionData: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionSummary(state),
});

export default connect(mapStateToProps)(RejectCedipTicketData);
