import React, { Component, Fragment } from "react";
import { bool, shape, string, func, arrayOf, number } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux";
import * as i18nUtils from "util/i18n";
import { resizableRoute } from "pages/_components/Resizable";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import Heading from "pages/_components/Heading";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import * as accountUtils from "util/accounts";

import { actions as debinActions, selectors as debinSelectors } from "reducers/debin";
import InfoTag from "pages/_components/InfoTag";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";

class FixedTermAcceptanceDetail extends Component {
    static propTypes = {
        fetching: bool,
        fetchingAccounts: bool,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }),
        dispatch: func.isRequired,
        debinDetail: shape({
            id: string,
        }).isRequired,
        buyerDetail: shape({}).isRequired,
        accounts: shape({}),
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: number,
            userFullName: string,
        }),
    };

    static defaultProps = {
        fetching: false,
        fetchingAccounts: false,
        match: null,
        accounts: null,
        loggedUser: null,
    };

    componentDidMount() {
        const {
            dispatch,
            match: {
                params: { idDebin },
            },
            accounts,
            loggedUser,
        } = this.props;

        dispatch(debinActions.acceptIncomingRequestPre(idDebin));

        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
    }

    componentWillUnmount() {
        const {
            dispatch,
            accounts,
            debinDetail: { buyerDetail },
        } = this.props;
        const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);
        const accountName = this.getAccountName(loggedUserAccount);
        dispatch(debinActions.loadAccountName(accountName));
    }

    statusTagColor = (state) => {
        switch (state) {
            case "INICIADO":
                return "#ffe5b6";
            case "CANCELADO":
                return "#feb0af";
            case "ELIMINADO":
                return "#feb0af";
            case "VENCIDO":
                return "#feb0af";
            case "RECHAZO DE CLIENTE":
                return "#feb0af";
            default:
                return "#84efd0";
        }
    };

    handleAccept = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.push(`/fixedTermAcceptance/accept/confirm`));
    };

    handleDecline = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.push(`/fixedTermAcceptance/decline/confirm`));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push(`/fixedTermAcceptance`));
    };

    getDebinTitle = () => {
        const {
            debinDetail: {
                id,
                status: { code },
                buyerDetail,
            },
            accounts,
        } = this.props;
        const name = `${i18nUtils.get("fixedTermAcceptance.detail.header.label ")} ${id}`.toUpperCase();
        const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);
        const accountName = this.getAccountName(loggedUserAccount);
        if (!code) {
            return <></>;
        }

        return (
            <>
                <div className="view-title data-wrapper-flex">
                    <h1 style={{ marginRight: "1rem" }}>{name}</h1>
                    <InfoTag
                        type="info"
                        message={code}
                        tagBackground={this.statusTagColor(code)}
                        moreStyles={{ alignSelf: "flex-end", width: "min-content", color: "black", margin: "0.5rem 0" }}
                    />
                </div>
                <p>{accountName}</p>
            </>
        );
    };

    getAccountName = (account) => {
        const { productAlias, number: accountNumber, productType, currency } = account;
        return `${productAlias} ${accountUtils.maskedAccountNumber(accountNumber)} ${productType} ${i18nUtils.get(
            `currency.label.${currency}`,
        )}`;
    };

    renderPageHeader = () => {
        const {
            debinDetail: {
                status: { code },
            },
        } = this.props;
        if (code === "INICIADO") {
            return (
                <Fragment>
                    <Notification scopeToShow="debin" />
                    <div className="admin-detail-head px-0">
                        <Head onBack={this.handleBack} />
                        <Head
                            handleAcceptDebinClick={() => this.handleAccept()}
                            handleDeclineDebinClick={() => this.handleDecline()}
                            hasInlineButtons
                            centerElement={this.getDebinTitle}
                        />
                    </div>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Notification scopeToShow="debin" />
                <div className="admin-detail-head px-0">
                    <Head onBack={this.handleBack} />
                    <Head hasInlineButtons centerElement={this.getDebinTitle} />
                </div>
            </Fragment>
        );
    };

    renderContent = () => {
        const {
            debinDetail: {
                amount: { currency, quantity },
                concept,
                description,
                expirationDate,
                idVoucher,
                sellerDetail,
                creationDate,
                reference,
            },
            debinDetail: { amount },
        } = this.props;

        return (
            <Fragment>
                {this.renderPageHeader()}
                <MainContainer>
                    <div className="above-the-fold">
                        <Container
                            className=" flex-grow container-white with-margin"
                            style={{ textTransform: "uppercase" }}>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.amount"
                                    data={
                                        amount ? (
                                            <FormattedAmount currency={currency} quantity={quantity} />
                                        ) : (
                                            <p> - </p>
                                        )
                                    }
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.concept"
                                    data={concept ? i18nUtils.get(`transfers.concept.${concept}`) : <p> - </p>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.description"
                                    data={description || <p> - </p>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.expirationDate"
                                    data={
                                        expirationDate ? (
                                            <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                                        ) : (
                                            <p> - </p>
                                        )
                                    }
                                />
                            </Col>
                        </Container>
                        <Container
                            className=" flex-grow container-white with-margin"
                            style={{ textTransform: "uppercase" }}>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.voucher"
                                    data={idVoucher || <p> - </p>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.sender"
                                    data={sellerDetail.titular || <p> - </p>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.documentType"
                                    data={
                                        (
                                            <div>
                                                <span style={{ marginRight: "0.5rem" }}>
                                                    {sellerDetail.documentType}
                                                </span>
                                                <span>{sellerDetail.documentNumber}</span>
                                            </div>
                                        ) || <p> - </p>
                                    }
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.creationDate"
                                    data={
                                        creationDate ? (
                                            <FormattedDate date={creationDate} dateFormat="dd/MM/yyyy" showTime />
                                        ) : (
                                            <p> - </p>
                                        )
                                    }
                                />
                            </Col>
                        </Container>
                        <Container
                            className=" flex-grow container-white with-margin"
                            style={{ textTransform: "uppercase" }}>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.cbu"
                                    data={sellerDetail.cbu || <p> - </p>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.alias"
                                    data={sellerDetail.cbuAlias || <p> - </p>}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.branch"
                                    data={sellerDetail.bank}
                                />
                            </Col>
                            <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="payments.debin.detail.table.header.reference"
                                    data={reference || <p> - </p>}
                                />
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    };

    render() {
        const { fetching, debinDetail, fetchingAccounts } = this.props;

        return (
            <Fragment>
                <PageLoading loading={fetching || fetchingAccounts}>
                    {!fetching && debinDetail && this.renderContent()}
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    debinDetail: debinSelectors.getDebinDetail(state),
    loggedUser: sessionSelectors.getUser(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
    accounts: accountsSelectors.getAccounts(state),
    fetching: debinSelectors.getFetching(state),
});

export default connect(mapStateToProps)(resizableRoute(FixedTermAcceptanceDetail));
