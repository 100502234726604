/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, node, func, bool, number, string } from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";

import * as i18nUtils from "util/i18n";

import { selectors } from "reducers/widgets";
import FinancingListItem from "pages/financing/ListItem";
import WidgetList from "pages/desktop/widgets/WidgetList";
import Button from "pages/_components/Button";
import WidgetHeader from "./_components/WidgetHeader";

const WIDGET_NAME = "financing";

class Financing extends Component {
    static propTypes = {
        closeButton: node,
        dispatch: func.isRequired,
        financingCount: number.isRequired,
        draggableItemProps: shape({}).isRequired,
        errorMsg: string.isRequired,
        isEditable: bool,
        isDesktop: bool,
    };

    static defaultProps = {
        closeButton: null,
        isEditable: false,
        isDesktop: true,
    };

    render() {
        const { closeButton, errorMsg, draggableItemProps, financingCount, dispatch, isEditable, isDesktop } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetFinancing" };

        return (
            <WidgetList 
                item={FinancingListItem} 
                keyExtractor={(item) => item.idProduct} 
                name="financing"
                isEditable={isEditable}
                close={closeButton}>
                {(list) => (
                    <div
                        role={isEditable ? "button" : "none"}
                        className={!isDesktop ? "widget financing-widget" : "financing-widget"}
                        {...uiAutomationProp}
                        {...draggableItemProps}
                        aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                        <WidgetHeader
                            title={i18nUtils.get("menu.financing")}
                            action={
                                <>
                                    {financingCount > 3 && !isEditable && !errorMsg &&(
                                        <Button
                                            onClick={() => {
                                                dispatch(push("/financing/categories"));
                                            }}
                                            bsStyle="outline"
                                            label="menu.seemore.label"
                                            type="button"
                                            image="images/arrow-right-blue.svg"
                                        />
                                    )}
                                </>
                            }
                        />
                        <div className="widget__content">{list}</div>
                        
                    </div>
                )}
            </WidgetList>
        );
    }
}

const mapStateToProps = (state) => {
    const { isFetching, errorMsg, data: {financing} } = selectors.getWidget(state, WIDGET_NAME);

    return { 
        isFetching,
        financingCount: financing.length,
        errorMsg
    };
};

export default connect(mapStateToProps)(Financing);
