/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import Drawer from "rc-drawer";
import { bool, element, shape, string } from "prop-types";
import Button from "pages/_components/Button";
import Header from "pages/_components/header/Header";
import Image from "pages/_components/Image";
import classNames from "classnames";

export default function ButtonDrawer({
    backButton,
    bsStyle,
    buttonClassName,
    buttonElement,
    closeButton,
    closeButtonWhite,
    closeClassname,
    content,
    contentClassname,
    drawerClassName,
    drawerContentClassName,
    extraButton,
    flex,
    headerClassName,
    headerContent,
    image,
    isDesktop,
    itemRightClassName,
    label,
    navStyle,
    notificationContent,
    side,
    styleButton,
    styleCloseButton,
    styleImage,
    width,
    wrapperClassName,
}) {
    ButtonDrawer.propTypes = {
        backButton: bool,
        bsStyle: string,
        buttonClassName: string,
        buttonElement: element,
        closeButton: bool,
        closeButtonWhite: bool,
        closeClassname: string,
        content: element.isRequired,
        contentClassname: string,
        drawerClassName: string,
        drawerContentClassName: string,
        extraButton: element,
        flex: bool,
        headerClassName: string,
        headerContent: element,
        image: string,
        isDesktop: bool,
        itemRightClassName: string,
        label: string,
        navStyle: string,
        notificationContent: element,
        side: bool,
        styleButton: shape({}),
        styleCloseButton: shape({}),
        styleImage: shape({}),
        width: string,
        wrapperClassName: string,
    };
    ButtonDrawer.defaultProps = {
        backButton: false,
        bsStyle: "",
        buttonClassName: "drawer-button-image",
        buttonElement: null,
        closeButton: true,
        closeButtonWhite: null,
        closeClassname: "",
        contentClassname: "",
        drawerClassName: "",
        drawerContentClassName: "",
        extraButton: null,
        flex: false,
        headerClassName: "",
        headerContent: undefined,
        image: undefined,
        isDesktop: false,
        itemRightClassName: "",
        label: undefined,
        navStyle: "default",
        notificationContent: undefined,
        side: false,
        styleButton: undefined,
        styleCloseButton: null,
        styleImage: undefined,
        width: "",
        wrapperClassName: "",
    };

    const [showDrawer, setShowDrawer] = useState(false);

    const backComponent = (
        <div className="toolbar-item toolbar-item-left padding-top-safe-area">
            <Button
                className="toolbar-btn view-back pl-0 mx-0"
                onClick={() => setShowDrawer(!showDrawer)}
                image="images/arrowLeftMobile.svg"
                label="global.back"
                bsStyle="link"
            />
        </div>
    );

    const closeComponent = (
        <div className="toolbar-item padding-top-safe-area">
            <Button
                bsStyle="link"
                image={closeButtonWhite ? "images/cross-mobile-white.svg" : "images/cross.svg"}
                className={`toolbar-btn view-close ${closeClassname}`}
                onClick={() => setShowDrawer(!showDrawer)}
                style={styleCloseButton}
            />
        </div>
    );

    const component =
        buttonElement &&
        React.cloneElement(buttonElement, {
            ...buttonElement.props,
            onClick: () => setShowDrawer(!showDrawer),
            drawerOpened: showDrawer
        });

    return (
        <>
            {(buttonElement && component) || (
                <Button
                    bsStyle={bsStyle}
                    label={label}
                    className={buttonClassName}
                    style={styleButton}
                    onClick={() => setShowDrawer(!showDrawer)}>
                    {image && <Image src={image} className="drawer-button" styles={styleImage} />}
                </Button>
            )}

            <Drawer
                width={width}
                className={drawerClassName}
                wrapperClassName={wrapperClassName}
                open={showDrawer}
                handler={false}
                placement="right"
                level={null}
                onClose={() => setShowDrawer(!showDrawer)}>
                {showDrawer && (
                    <div className={classNames("drawer-content-inner", { side: !!side }, drawerContentClassName)}>
                        {notificationContent}
                        <Header additionalClassName={headerClassName} navStyle={navStyle}>
                            <div
                                className={classNames({
                                    "navbar-header navbar-header-fixed px-2": !isDesktop,
                                })}>
                                <div className="drawer-toolbar-item">{backButton && backComponent}</div>
                                <div
                                    className={classNames(
                                        "view-title align-items-center justify-content-center padding-top-safe-area ",
                                        { "d-flex": flex },
                                    )}>
                                    {headerContent}
                                </div>
                                <div className={classNames("drawer-toolbar-item", itemRightClassName)}>
                                    {extraButton && (
                                        <div className="toolbar-item padding-top-safe-area">{extraButton}</div>
                                    )}
                                    {closeButton && closeComponent}
                                </div>
                            </div>
                        </Header>
                        <div className={classNames({ "h-100": isDesktop })}>
                            <div className={contentClassname}>
                                {React.cloneElement(content, {
                                    ...content.props,
                                    closeDrawer: () => setShowDrawer(!showDrawer),
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </Drawer>
        </>
    );
}
