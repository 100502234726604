/* eslint-disable import/no-unresolved */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { func, bool } from "prop-types";

import { actions as communicationsActions, selectors as communicationsSelector } from "reducers/communications";
import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";

import List from "pages/communications/_components/List";
import Head from "pages/_components/Head";
import LeftPanel from "pages/communications/_components/LeftPanel";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import WrongEnvironmentModal from "./_components/WrongEnvironmentModal";
import ModalMessage from "./_components/ModalMessage";

class CommunicationMain extends Component {
    state = {
        isComposing: false,
        modalOpen: false,
        deleteMsg: null,
    };

    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        detail: bool.isRequired,
        isWrongEnvironmentModalVisible: bool.isRequired,
        correctEnvironmentName: String.isRequired,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(communicationsActions.listTypesRequest());
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER" }));
    }

    handleComposeClick = (isReply) => {
        const { isDesktop, dispatch } = this.props;

        if (isDesktop) {
            if (isReply) {
                dispatch(communicationsActions.showReplyPanel());
            } else {
                dispatch(communicationsActions.showComposePanel());
            }
        } else {
            dispatch(push("/communications/compose"));
        }
    };

    isModalVisible = (e, state) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        this.setState({ modalOpen: state });
    };

    handleModalDelete = (deleteFunction) => {
        this.setState({ deleteMsg: deleteFunction });
    };

    handleComposeCloseClick = () => {
        const { dispatch, detail } = this.props;
        if (detail) {
            dispatch(communicationsActions.showReadPanel());
        } else {
            dispatch(communicationsActions.hideAllPanels());
        }
    };

    handleBackClick = () => {
        const { dispatch } = this.props;

        dispatch(push("/desktop"));
    };

    closeModal = () => {
        const { dispatch } = this.props;
        dispatch(communicationActions.hideWrongEnvironmentModal());
    };

    render() {
        const { isDesktop, isWrongEnvironmentModalVisible, correctEnvironmentName } = this.props;
        const { isComposing, modalOpen, deleteMsg } = this.state;

        if (isDesktop) {
            return (
                <Fragment>
                    <Notification scopeToShow="communications" />
                    <WrongEnvironmentModal
                        isVisible={isWrongEnvironmentModalVisible}
                        isDesktop={isDesktop}
                        closeModal={this.closeModal}
                        environment={correctEnvironmentName}
                    />
                    <div className="admin-detail-head px-0">
                        <Head backLinkTo="/desktop" />
                        <Head
                            title="chat.title"
                            isDesktop={isDesktop}
                            onAdd={this.handleComposeClick}
                            addLinkToLabel="communications.compose.message.new"
                        />
                    </div>
                    <MainContainer>
                        <div className="above-the-folds">
                            <Container
                                className="align-items-center mailing communications-desktop-cols"
                                gridClassName="form-content">
                                <Col className="col col-6 col-no-padding overflow-auto h-100">
                                    <List
                                        isDesktop={isDesktop}
                                        handleComposeCloseClick={this.handleComposeCloseClick}
                                        isModalVisible={this.isModalVisible}
                                        handleModalDelete={this.handleModalDelete}
                                    />
                                </Col>
                                <Col className="col col-6 messages-container overflow-auto h-100">
                                    <LeftPanel
                                        compose={isComposing}
                                        close={this.handleComposeCloseClick}
                                        reply={this.handleComposeClick}
                                        isDesktop={isDesktop}
                                        isModalVisible={this.isModalVisible}
                                        handleModalDelete={this.handleModalDelete}
                                    />
                                </Col>
                            </Container>
                        </div>
                    </MainContainer>
                    <ModalMessage
                        isVisible={modalOpen}
                        submitClick={deleteMsg}
                        cancelClick={(e) => this.isModalVisible(e)}
                    />
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Notification scopeToShow="communications" />
                <div className="admin-detail-head px-0">
                    <Head
                        title="chat.title"
                        titleClassName="header-mobile-title-background-blue"
                        headerClassName="blue-main-header-mobile"
                        isComposing={false}
                        isDesktop={isDesktop}
                        onBack={this.handleBackClick}
                        addLinkTo="/communications/compose"
                        showPlusIcon
                        addButtonFirstPosition
                        addButtonImageWhite
                    />
                </div>
                <MainContainer viewContentStyle={isDesktop ? {} : { overflowY: "hidden" }}>
                    <WrongEnvironmentModal
                        isVisible={isWrongEnvironmentModalVisible}
                        isDesktop={isDesktop}
                        closeModal={this.closeModal}
                        environment={correctEnvironmentName}
                    />
                    <div className={`above-the-fold mailing ${isDesktop ? "" : "without-auto-margin"}`}>
                        <List
                            isDesktop={isDesktop}
                            isModalVisible={this.isModalVisible}
                            handleModalDelete={this.handleModalDelete}
                        />
                    </div>
                </MainContainer>
                <ModalMessage
                    isVisible={modalOpen}
                    submitClick={deleteMsg}
                    cancelClick={(e) => this.isModalVisible(e)}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    types: communicationsSelector.listTypes(state),
    detail: communicationSelectors.detail(state),
    isWrongEnvironmentModalVisible: communicationSelectors.isWrongEnvironmentModalVisible(state),
    correctEnvironmentName: communicationSelectors.correctEnvironmentName(state),
});

export default connect(mapStateToProps)(CommunicationMain);
