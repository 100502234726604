import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, func, bool, string } from "prop-types";
import ContextMenu from "pages/_components/ContextMenu";
import { recurrencyDebinStatusActions, recurrencyDebinStatusActionsV3 } from "util/debin";
import * as configUtils from "util/config";

const FORM_ID = "debin.recurrency";

class RecurrencyContextMenu extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        recurrency: shape({}).isRequired,
        isDesktop: bool.isRequired,
        contextClass: string,
        onConfirmAction: func.isRequired,
    };

    static defaultProps = {
        contextClass: "",
    };

    getEstado = (recurrency) => {
        if (recurrency.estado === "ACTIVA" && recurrency.autorizado === "AUTORIZADO") {
            return "ACTIVA";
        }
        if (recurrency.estado === "INACTIVA" && recurrency.autorizado === "AUTORIZADO") {
            return "INACTIVA";
        }
        if (
            (recurrency.estado === "ACTIVA" || recurrency.estado === "INACTIVA") &&
            recurrency.autorizado === "PENDIENTE"
        ) {
            return "PENDIENTE";
        }
        if (
            (recurrency.estado === "ACTIVA" || recurrency.estado === "INACTIVA") &&
            recurrency.autorizado === "RECHAZADO"
        ) {
            return "RECHAZADO";
        }
        return recurrency.estado;
    };

    debinActions = (status, useApiV3) => {
        const actions = useApiV3 ? recurrencyDebinStatusActionsV3 : recurrencyDebinStatusActions;
        const { recurrency, dispatch, onConfirmAction } = this.props;

        let contextMenuActions = [];

        if (actions[status]) {
            actions[status].forEach((action) => {
                contextMenuActions = [
                    ...contextMenuActions,
                    {
                        label: `${FORM_ID}.${action.toLowerCase()}.debin`,
                        onClick: () => {
                            onConfirmAction(action, recurrency, dispatch);
                        },
                    },
                ];
            });
        }

        return contextMenuActions;
    };

    render() {
        const { recurrency, isDesktop, contextClass } = this.props;
        const useApiV3 = configUtils.getBoolean("frontend.show.RecurringDebin.functionalities", false);
        const itemsContextMenu = this.debinActions(useApiV3 ? this.getEstado(recurrency) : recurrency.estado, useApiV3);

        return (
            <div className={contextClass}>
                {itemsContextMenu.length > 0 && <ContextMenu isDesktop={isDesktop} items={itemsContextMenu} />}
            </div>
        );
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(RecurrencyContextMenu);
