import React, { Component } from "react";
import classNames from "classnames";
import { oneOf, node } from "prop-types";

class FormGroup extends Component {
    static propTypes = {
        children: node.isRequired,
        // keep adding enum values here to add a new class
        variant: oneOf(["select", ""]),
    };

    static defaultProps = {
        variant: "",
    };

    render() {
        const { variant, children } = this.props;

        return (
            <div
                className={classNames("form-group", {
                    "form-group--select": variant === "select",
                })}>
                {children}
            </div>
        );
    }
}

export default FormGroup;
