import React from "react";
import classNames from "classnames";
import { arrayOf, bool, func, shape } from "prop-types";
import { connect } from "react-redux";

import { selectors as fundcorpSelectors } from "reducers/fundcorp";
import * as configUtils from "util/config";

import Container from "pages/_components/Container";
import ModalEmptyMessage from "./ModalEmptyMessage";
import FundFeaturesTableContent from "./FundFeaturesTableContent";

function FundFeaturesTable({ isDesktop, fundFeatures, onRowClick }) {
    const fundsParameters = JSON.parse(configUtils.get("frontend.investment.funds.parameters"));

    const activeFunds = fundFeatures?.filter(({ numero }) => fundsParameters[`fondo${numero}`]?.isON);

    return (
        <Container
            fluid
            className={classNames("scrollable px-0 frequent-destination-modal-body", {
                "pb-7 overflow-auto h-100vh": !isDesktop,
            })}>
            {fundFeatures && fundFeatures.length > 0 ? (
                <FundFeaturesTableContent isDesktop={isDesktop} fundFeatures={activeFunds} onRowClick={onRowClick} />
            ) : (
                <ModalEmptyMessage isDesktop={isDesktop} />
            )}
        </Container>
    );
}

FundFeaturesTable.propTypes = {
    isDesktop: bool.isRequired,
    fundFeatures: arrayOf(shape({})).isRequired,
    onRowClick: func.isRequired,
};

const mapStateToProps = (state) => ({
    fundFeatures: fundcorpSelectors.getFundFeatures(state),
});

export default connect(mapStateToProps)(FundFeaturesTable);
