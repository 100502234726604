import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { bool, string, func } from "prop-types";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

class RowButtons extends Component {
    static propTypes = {
        isButton1Selected: bool,
        isButton2Selected: bool,
        FORM_ID: string.isRequired,
        button1Id: string.isRequired,
        button2Id: string.isRequired,
        handleChangeButton: func.isRequired,
        showForm: bool.isRequired,
        setShowForm: func.isRequired,
        isDesktop: bool.isRequired,
        hasChanged: bool.isRequired,
    };

    static defaultProps = {
        isButton1Selected: true,
        isButton2Selected: false,
    };

    render() {
        const {
            isButton1Selected,
            isButton2Selected,
            FORM_ID,
            button1Id,
            button2Id,
            handleChangeButton,
            setShowForm,
            showForm,
            isDesktop,
            hasChanged,
        } = this.props;
        return (
            <Container className="flex-grow align-items-center container-white py-3">
                <Col className="d-flex justify-content-start align-item-center w-100">
                    <div className="d-flex align-item-center w-100">
                        <Button
                            className={`btn-pills w-100 max-width-btn ${isDesktop && "mx-3"}`}
                            block={false}
                            image={isButton1Selected ? "images/check.svg" : ""}
                            style={{
                                backgroundColor: isButton1Selected ? "#f2f8fd" : "",
                                borderColor: isButton1Selected ? "#0071ce" : "#ccc",
                                color: isButton1Selected ? "#0071ce" : "#666",
                            }}
                            onClick={() => handleChangeButton(button1Id)}
                            label={`${FORM_ID}.${button1Id}`}
                        />
                        <Button
                            className="btn-pills w-100 max-width-btn"
                            block={false}
                            image={isButton2Selected ? "images/check.svg" : ""}
                            style={{
                                backgroundColor: isButton2Selected ? "#f2f8fd" : "",
                                borderColor: isButton2Selected ? "#0071ce" : "#ccc",
                                color: isButton2Selected ? "#0071ce" : "#666",
                            }}
                            onClick={() => handleChangeButton(button2Id)}
                            label={`${FORM_ID}.${button2Id}`}
                        />
                    </div>
                    {isDesktop && (
                        <div className="hide-filter__btn-content mr-3">
                            <Button
                                block
                                bsStyle="outline"
                                className="hide-filter__btn m-0"
                                label={
                                    showForm
                                        ? "global.hide.filter"
                                        : `${hasChanged ? "global.filter.active" : "global.see.filter"}`
                                }
                                image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                                onClick={setShowForm}
                            />
                        </div>
                    )}
                </Col>
            </Container>
        );
    }
}

export default RowButtons;
