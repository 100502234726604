import React, { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { bool, func, node, number, object, oneOf, oneOfType, shape, string } from "prop-types";
import { compose } from "redux";

import * as i18n from "util/i18n";
import { getNestedObject } from "util/general";

import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { resizableRoute } from "pages/_components/Resizable";
import withFocus from "pages/_components/withFocus";

function AlfaNumericStgField({
    className,
    classNameForViewMode,
    controlLabelClassName,
    copyEnabled,
    elementClassName,
    errorClassName,
    extraLabel,
    field,
    form,
    formGroupClassName,
    formGroupTextClassName,
    handleOnBlur,
    hideLabel,
    hidePlaceholder,
    idField,
    idForm,
    innerDisabled,
    inputRef,
    isNumeric,
    isDesktop,
    isDisabled,
    isFetching,
    isFocused,
    isMobile,
    isMobileNative,
    labelText,
    mobileOS,
    mode,
    msgStatic,
    nestedErrorsObject,
    onKeyDown,
    optional,
    placeholderText,
    renderAs: Element,
    toggleIsFocused,
    warningMessage,
    maxLength,
    ...props
}) {
    const { touched, errors, setFieldValue } = form;

    const hasError = nestedErrorsObject
        ? getNestedObject(touched, field.name.split(".")) && getNestedObject(errors, field.name.split("."))
        : touched[field.name] && errors[field.name];

    const fieldLabel = labelText || `${idForm}.${field.name}.label`;
    const id = idField || `${idForm}.${field.name}`;

    const onResize = useCallback(() => {
        if (isFocused && !isDesktop && mobileOS === "Android") {
            /* eslint-disable-next-line react/no-find-dom-node */
            ReactDOM.findDOMNode(this).scrollIntoView({ block: "center", behavior: "smooth" });
        }
    }, [isFocused, isDesktop, mobileOS]);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [onResize]);

    const cleanErrors = (form, field) => {
        const { [field?.name]: fieldName, ...errorObj } = form?.errors;
        form.setErrors(errorObj);
    };

    const handleBlur = (event) => {
        field.onBlur(event);
        toggleIsFocused();
    };

    const handleOnChange = (event) => {
        const val = event.target.value || "";

        const reg = new RegExp(isNumeric ? "[0-9]" : "[a-zA-Z0-9]");
        const newValue = val
            .split("")
            .filter((key) => reg.test(key))
            .join("");
        const length = event.target.value.length <= maxLength ? event.target.value.length : maxLength;

        setFieldValue(field.name, newValue.slice(0, length));
    };

    const handleOnCopy = (event) => {
        if (!copyEnabled) {
            event.preventDefault();
        }
    };

    const renderPlaceholder = () => {
        if (hidePlaceholder) {
            return "";
        }
        if (placeholderText !== null) {
            return placeholderText;
        }
        return i18n.get(`${idForm}.${field.name}.placeholder`);
    };

    const customMinLengthValidation = ({ target }) => {
        const { value } = target;

        if (props.minLength) {
            if (value && value.length < props.minLength) {
                target.setCustomValidity(
                    i18n.get("generic.text.field.minLength.warning.message", null, {
                        MINLENGTH: props.minLength,
                        CURRENTLENGTH: value.length,
                    }),
                );
            } else {
                target.setCustomValidity("");
            }
        }

        if (props.idValidation && props.validationRegularExpresion) {
            const rexp = new RegExp(props.validationRegularExpresion);
            if (!rexp.test(value)) {
                switch (props.idValidation) {
                    case "email":
                        target.setCustomValidity(i18n.get("generic.text.field.email.validationError", null));
                        break;
                    default:
                        break;
                }
            } else {
                target.setCustomValidity("");
            }
        }
    };

    return (
        <div
            className={classNames("form-group", formGroupClassName, {
                "has-error": hasError,
                "has-focus": isFocused,
            })}>
            <FieldLabel
                hideLabel={hideLabel}
                labelKey={fieldLabel}
                optional={optional}
                idField={id}
                extraLabel={extraLabel}
                isDisabled={isDisabled && !innerDisabled}
                isFetching={isFetching}
                controlLabelClassName={controlLabelClassName}
                formGroupTextClassName={formGroupTextClassName}
            />
            <div
                className={classNames(`input-group ${className}`, {
                    "input-group-disable": (isDisabled && !innerDisabled) || isFetching,
                    "inner-input-group-disable": innerDisabled,
                })}>
                <input
                    id={id}
                    className={classNames("form-control", elementClassName)}
                    placeholder={renderPlaceholder()}
                    {...field}
                    {...props}
                    onFocus={toggleIsFocused}
                    onBlur={handleOnBlur || handleBlur}
                    ref={inputRef}
                    onChange={handleOnChange}
                    onCopy={handleOnCopy}
                    onCut={handleOnCopy}
                    onInvalid={customMinLengthValidation}
                    onKeyDown={(e) => e.key === "Enter" && onKeyDown && onKeyDown()}
                    disabled={isDisabled}
                    onClick={() => cleanErrors(form, field)}
                    autoComplete="off"
                    type="text"
                    pattern="\d*"
                />
            </div>
            {hasError && (
                <FieldError
                    error={nestedErrorsObject ? getNestedObject(errors, field.name.split(".")) : errors[field.name]}
                    msgStatic={msgStatic}
                    errorClassName={errorClassName}
                />
            )}
        </div>
    );
}

AlfaNumericStgField.propTypes = {
    onCustomChange: func,
    autoComplete: string,
    autoFocus: bool,
    controlLabelClassName: string,
    errorClassName: string,
    formGroupTextClassName: string,
    field: shape({
        onBlur: func,
        onChange: func,
        name: string,
        value: oneOfType([number, string]),
    }).isRequired,
    form: shape({
        errors: {},
        touched: {},
    }).isRequired,
    handleOnBlur: func,
    hideLabel: bool,
    hidePlaceholder: bool,
    inputRef: oneOfType([func, object]),
    isNumeric: bool,
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    isMobileNative: bool.isRequired,
    maxLength: number,
    mobileOS: string.isRequired,
    mode: oneOf(["view", "edit"]),
    nestedErrorsObject: bool,
    optional: string,
    renderAs: string,
    type: string,
    warningMessage: string,
    labelText: string,
    placeholderText: string,
    isFocused: bool,
    toggleIsFocused: func,
    idForm: string,
    classNameForViewMode: string,
    copyEnabled: bool,
    idField: string,
    minLength: number,
    validationRegularExpresion: string,
    idValidation: string,
    onKeyDown: func,
    extraLabel: node,
    formGroupClassName: string,
    className: string,
    elementClassName: string,
    isDisabled: bool,
    isFetching: bool,
    msgStatic: bool,
    innerDisabled: bool,
};

AlfaNumericStgField.defaultProps = {
    onCustomChange: null,
    autoComplete: "on",
    autoFocus: false,
    controlLabelClassName: "",
    errorClassName: "",
    formGroupTextClassName: "",
    handleOnBlur: null,
    hideLabel: false,
    hidePlaceholder: false,
    inputRef: React.createRef(),
    maxLength: 50,
    mode: "edit",
    nestedErrorsObject: false,
    optional: "",
    renderAs: "input",
    type: "text",
    warningMessage: "",
    labelText: null,
    placeholderText: null,
    isFocused: false,
    toggleIsFocused: null,
    idForm: "",
    classNameForViewMode: "",
    copyEnabled: true,
    idField: "",
    minLength: 0,
    validationRegularExpresion: "",
    idValidation: "",
    isNumeric: false,
    onKeyDown: null,
    extraLabel: null,
    formGroupClassName: "",
    className: "",
    elementClassName: "",
    isDisabled: false,
    isFetching: false,
    msgStatic: false,
    innerDisabled: false,
};

export default compose(resizableRoute, withFocus)(AlfaNumericStgField);
