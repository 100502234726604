export const ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE = "COR101I";

export const CAPTCHA_REQUIRED = "API021W";

export const CAPTCHA_INVALID = "COR050W";

export const INSUFFICIENT_SIGNATURES = "COR023I";

export const INTERNAL_ERROR = "API516W";

export const SCHEDULED_TRANSACTION = "COR013I";

export const TRANSACTION_FINISHED = "COR000I";

export const UNEXPECTED_ERROR = "COR999E";

export const VALIDATION_ERROR = "COR020W";
