import React from "react";
import { number, oneOf } from "prop-types";
import { string } from "yup";
import { connect } from "react-redux";

import Stepper, { Step, orientations } from "pages/_components/StepIndicator/index";
import I18n from "pages/_components/I18n";

import { selectors as loginSelectors } from "reducers/login";
import { REGION_USA } from "constants.js";

const OnboardingStepper = ({ activeRegion, currentStep, orientation, className }) => {
    if (activeRegion === REGION_USA) {
        return (
            <Stepper className={className} orientation={orientation}>
                <Step currentStep={currentStep} stepNumber={1}>
                    <I18n id="onboarding.steps.indicator.documentType" />
                </Step>
                <Step currentStep={currentStep} stepNumber={2}>
                    <I18n id="onboarding.steps.indicator.scanDoc" />
                </Step>
                <Step currentStep={currentStep} stepNumber={3}>
                    <I18n id="onboarding.steps.indicator.faceScanner" />
                </Step>
                <Step currentStep={currentStep} stepNumber={4}>
                    <I18n id="onboarding.steps.indicator.personalData" />
                </Step>
                <Step currentStep={currentStep} stepNumber={5}>
                    <I18n id="onboarding.steps.indicator.personalAddress" />
                </Step>
                <Step currentStep={currentStep} stepNumber={6}>
                    <I18n id="onboarding.steps.indicator.occupation" />
                </Step>
                <Step currentStep={currentStep} stepNumber={7}>
                    <I18n id="onboarding.steps.indicator.emailValidation" />
                </Step>
                <Step currentStep={currentStep} stepNumber={8}>
                    <I18n id="onboarding.steps.indicator.cellPhoneValidation" />
                </Step>
                <Step currentStep={currentStep} stepNumber={9}>
                    <I18n id="onboarding.steps.indicator.password" />
                </Step>
                <Step currentStep={currentStep} stepNumber={10}>
                    <I18n id="onboarding.steps.indicator.secSeal" />
                </Step>
                <Step currentStep={currentStep} stepNumber={11}>
                    <I18n id="onboarding.steps.indicator.esign" />
                </Step>
            </Stepper>
        );
    }

    return (
        <Stepper className={className} orientation={orientation}>
            <Step currentStep={currentStep} stepNumber={1}>
                <I18n id="onboarding.steps.indicator.documentType" />
            </Step>
            <Step currentStep={currentStep} stepNumber={2}>
                <I18n id="onboarding.steps.indicator.scanDoc" />
            </Step>
            <Step currentStep={currentStep} stepNumber={3}>
                <I18n id="onboarding.steps.indicator.faceScanner" />
            </Step>
            <Step currentStep={currentStep} stepNumber={4}>
                <I18n id="onboarding.steps.indicator.personalData" />
            </Step>
            <Step currentStep={currentStep} stepNumber={5}>
                <I18n id="onboarding.steps.indicator.personalAddress" />
            </Step>
            <Step currentStep={currentStep} stepNumber={6}>
                <I18n id="onboarding.steps.indicator.occupation" />
            </Step>
            <Step currentStep={currentStep} stepNumber={7}>
                <I18n id="onboarding.steps.indicator.emailValidation" />
            </Step>
            <Step currentStep={currentStep} stepNumber={8}>
                <I18n id="onboarding.steps.indicator.cellPhoneValidation" />
            </Step>
            <Step currentStep={currentStep} stepNumber={9}>
                <I18n id="onboarding.steps.indicator.password" />
            </Step>
            <Step currentStep={currentStep} stepNumber={10}>
                <I18n id="onboarding.steps.indicator.secSeal" />
            </Step>
        </Stepper>
    );
};

OnboardingStepper.propTypes = {
    currentStep: number.isRequired,
    activeRegion: string.isRequired,
    orientation: oneOf(Object.keys(orientations)),
    className: string,
};

OnboardingStepper.defaultProps = {
    orientation: orientations.vertical,
    className: undefined,
};

const mapStateToProps = (state) => ({
    activeRegion: loginSelectors.getRegion(state),
});

export { orientations };

export default connect(mapStateToProps)(OnboardingStepper);
