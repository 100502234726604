import React, { Component } from "react";

const mapProps = (propsMapper) => (BaseComponent) =>
    class extends Component {
        static displayName = `mapProps${BaseComponent.name}`;

        render() {
            return <BaseComponent {...propsMapper(this.props)} />;
        }
    };

export default mapProps;
