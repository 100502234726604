import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LOAD_LIST_REQUEST: "bankSelector/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "bankSelector/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "bankSelector/LOAD_LIST_SUCCESS",
    BANK_SELECTED: "bankSelector/BANK_SELECTED",
    RESET_SELECTED_BANK: "bankSelector/RESET_SELECTED_BANK",
};

export const INITIAL_STATE = {
    banksResults: [],
    hasMoreResults: false,
    selectedBanks: {},
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_LIST_REQUEST]: (state) => state,
    [types.LOAD_LIST_FAILURE]: (state) => state,
    [types.LOAD_LIST_SUCCESS]: (state, action) => ({
        ...state,
        banksResults: action.data.banks,
        hasMoreResults: action.data.moreResults,
    }),
    [types.BANK_SELECTED]: (state, action) => ({
        ...state,
        selectedBanks: {
            ...state.selectedBanks,
            [action.field]: action.selectedBank,
        },
    }),
    [types.RESET_SELECTED_BANK]: (state) => ({
        ...state,
        selectedBanks: INITIAL_STATE.selectedBanks,
    }),
});

export const actions = {
    loadListRequest: makeActionCreator(
        types.LOAD_LIST_REQUEST,
        "filters",
        "setSubmitting",
        "currentUrl",
        "navigationAction",
    ),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(types.LOAD_LIST_SUCCESS, "data"),
    bankSelected: makeActionCreator(types.BANK_SELECTED, "selectedBank", "field"),
    resetSelectedBanks: makeActionCreator(types.RESET_SELECTED_BANK),
};

export const selectors = {
    getBanks: ({ bankSelector }) => bankSelector.banksResults,
    getHasMoreResults: ({ bankSelector }) => bankSelector.hasMoreResults,
    getSelectedBank: ({ bankSelector }, idField) => bankSelector.selectedBanks[idField],
};
