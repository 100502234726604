import React, { Component } from "react";
import { bool, string } from "prop-types";

class Circle extends Component {
    static propTypes = {
        value: string,
        fontColor: string,
        bgColor: string,
        smallSize: bool,
        borderStyle: string,
        borderColor: string,
        borderWidth: string,
        marginRight: string,
        marginTop: string,
        uppercase: bool,
        paddingTop: string,
    };

    static defaultProps = {
        value: "",
        fontColor: "white",
        bgColor: "#707070",
        smallSize: false,
        borderStyle: "",
        borderColor: "",
        borderWidth: "",
        marginRight: "",
        marginTop: "",
        uppercase: false,
        paddingTop: "0.25rem",
    };

    render() {
        const {
            value,
            bgColor,
            fontColor,
            smallSize,
            borderStyle,
            borderColor,
            borderWidth,
            marginRight,
            uppercase,
            marginTop,
            paddingTop,
        } = this.props;

        if (smallSize) {
            return (
                <div
                    style={{
                        height: "2rem",
                        width: "2rem",
                        background: bgColor,
                        borderRadius: "50%",
                        display: "inline-flex",
                        borderStyle,
                        borderColor,
                        borderWidth,
                        marginTop,
                        marginRight,
                        paddingTop,
                    }}>
                    <span
                        style={{
                            color: fontColor,
                            width: "2rem",
                            textAlign: "center",
                            paddingTop: { paddingTop },
                            textTransform: uppercase ? "uppercase" : "",
                        }}>
                        {value}
                    </span>
                </div>
            );
        }

        return (
            <div
                style={{
                    height: "2.5rem",
                    width: "2.5rem",
                    background: bgColor,
                    borderRadius: "50%",
                    display: "flex",
                    borderStyle,
                    borderColor,
                    borderWidth,
                    marginRight,
                }}>
                <div
                    style={{
                        width: "2.5rem",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                    }}>
                    <span style={{ color: fontColor }}>{value}</span>
                </div>
            </div>
        );
    }
}

export default Circle;
