import React, { Component } from "react";
import { oneOfType, string, func, bool, shape } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors, actions } from "reducers/widgets";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";

import * as i18nUtils from "util/i18n";
import FormattedAmount from "pages/_components/FormattedAmount";

import { Link } from "react-router-dom";
import Image from "pages/_components/Image";
import { Col } from "react-bootstrap";
import classNames from "classnames";

class FinancingListCategories extends Component {
    static propTypes = {
        loggedUser: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool,
        fetching: oneOfType([string, bool]),
        list: shape({}).isRequired,
        listCategories: shape({}).isRequired,
    };

    static defaultProps = {
        fetching: false,
        isDesktop: false,
    };

    componentDidMount() {
        const { dispatch, loggedUser } = this.props;
        dispatch(actions.listRequest("financing", loggedUser.userId));
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    getBalanceDescription = (list, isDesktop) => {
        const ARS_POSITION = 0;
        const USD_POSITION = 1;

        const fontClassName = classNames("medium-font ", { "f-size-25": !isDesktop }, { "f-size-1": isDesktop });

        return (
            <div
                className={classNames(
                    "flex-flow-wrap",
                    { "d-inline-flex": isDesktop },
                    { "d-flex f-dir-col": !isDesktop },
                    { "ml-2": !isDesktop },
                    { "line-height-28 my-2 flex-column": !isDesktop },
                )}>
                {list[ARS_POSITION]?.balance ? (
                    <FormattedAmount
                        fontClassName={fontClassName}
                        quantity={list[ARS_POSITION].balance}
                        currency={list[ARS_POSITION].currencyCode.toString()}
                        main
                    />
                ) : (
                    <FormattedAmount
                        fontClassName={fontClassName}
                        quantity={0}
                        currency={i18nUtils.get("currency.label.ARS")}
                        main
                    />
                )}
                {isDesktop && <span className="d-inline-flex align-items-center medium-font h1 my-0 mx-4"> | </span>}
                {list[USD_POSITION]?.balance ? (
                    <FormattedAmount
                        fontClassName={fontClassName}
                        quantity={list[USD_POSITION].balance}
                        currency={list[USD_POSITION].currencyCode.toString()}
                        main
                    />
                ) : (
                    <FormattedAmount
                        fontClassName={fontClassName}
                        quantity={0}
                        currency={i18nUtils.get("currency.label.USD")}
                        main
                    />
                )}
            </div>
        );
    };

    renderHeader = () => {
        const { isDesktop } = this.props;

        return (
            <div className="admin-detail-head px-0">
                {isDesktop && <Head onBack={this.handleBack} />}
                <Head
                    title="menu.financing.title"
                    headerClassName={classNames({ "blue-main-header-mobile": !isDesktop })}
                    titleClassName={classNames({ "py-3": isDesktop })}
                    onBack={!isDesktop && this.handleBack}
                />
            </div>
        );
    };

    render() {
        const { list, fetching, listCategories, isDesktop } = this.props;
        const amountLabel = i18nUtils.get("financing.totalAmount.label");
        const isLoading = fetching && !list.length;
        const categoryDescription = i18nUtils.get("financing.category.description");
        return (
            <>
                <Notification scopeToShow="loans" />
                {this.renderHeader()}
                <MainContainer showLoader={isLoading} className={isDesktop ? "ml-1 mr-1" : "main-container px-0"}>
                    <div className={classNames("above-the-fold", { "mx-2": !isDesktop })}>
                        <div className={classNames({ "d-flex f-dir-col": isDesktop })}>
                            <div className={classNames("d-inline-flex flex-flow-wrap", { "ml-2": !isDesktop })}>
                                <p className="data-label data-amount-label-flex mb-0">{amountLabel}</p>
                            </div>
                            {this.getBalanceDescription(list, isDesktop)}
                        </div>
                        <section className={classNames({ "mt-4": isDesktop })}>
                            {listCategories &&
                                listCategories.map((category) => (
                                    <Link
                                        to={`/financing/${category.productCategoryDescription}`}
                                        className={`btn btn-link product-hover ${
                                            isDesktop ? "min-height-5 pl-45 pr-3" : "min-height-4 px-3 py-2"
                                        } `}>
                                        <Col xs={12} className="px-0 d-flex space-between align-items-center">
                                            <span
                                                className={`text-initial data-label text-align-left ${
                                                    isDesktop
                                                        ? "my-0 f-size-1 line-height-1 font-light align-font-fix-02"
                                                        : "pl-25"
                                                }`}>
                                                {category.productCategoryCode === 2
                                                    ? categoryDescription
                                                    : category.productCategoryDescription}
                                            </span>

                                            <div
                                                className={classNames(
                                                    "data-label d-flex min-width-50",
                                                    { "f-dir-col align-items-end": !isDesktop },
                                                    { "mr-45 align-items-center justify-content-end": isDesktop },
                                                )}>
                                                <FormattedAmount
                                                    quantity={category?.amountPerCategory[0]}
                                                    currency={i18nUtils.get("currency.label.ARS")}
                                                    className="text-align-right"
                                                    fontClassName={`d-flex ${
                                                        isDesktop ? "f-size-25 align-font-fix-02" : "f-size-5"
                                                    }`}
                                                />
                                                {isDesktop && (
                                                    <span className="data-label d-flex f-size-25 mx-2 align-font-fix-02">
                                                        {" "}
                                                        |{" "}
                                                    </span>
                                                )}
                                                <FormattedAmount
                                                    quantity={category?.amountPerCategory[2]}
                                                    currency={i18nUtils.get("currency.label.USD")}
                                                    className="text-align-right"
                                                    fontClassName={`d-flex ${
                                                        isDesktop ? "f-size-25 align-font-fix-02" : "f-size-5"
                                                    }`}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            xs={1}
                                            className={`justify-content-right flex w-100 px-0 ${
                                                isDesktop ? "mr-4" : "mr-0"
                                            } `}>
                                            <Image src="images/chevromRight.svg" className="svg-icon svg-caret" />
                                        </Col>
                                    </Link>
                                ))}
                        </section>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { data } = selectors.getWidget(state, "financing");
    return {
        loggedUser: sessionSelectors.getUser(state),
        list: data.financing,
        listCategories: data.financingCategories,
    };
};

export default connect(mapStateToProps)(FinancingListCategories);
