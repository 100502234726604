import React, { Component } from "react";
import { func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { push } from "react-router-redux/actions";
import FormattedAmount from "pages/_components/FormattedAmount";
import InfoTag from "pages/_components/InfoTag";
import ContextMenu from "pages/_components/ContextMenu";
import FormattedDate from "pages/_components/FormattedDate";
import { actions as debinActions } from "reducers/debin";
import Heading from "pages/_components/Heading";

class FixedTermAcceptanceItem extends Component {
    static propTypes = {
        request: shape({}).isRequired,
        isDesktop: shape({}).isRequired,
        dispatch: func.isRequired,
        tableLabel: string,
    };

    static defaultProps = {
        tableLabel: "fixedTermAcceptance.table.header",
    };

    statusTagColor = (state) => {
        switch (state) {
            case "INICIADO":
                return "#ffe5b6";
            case "CANCELADO":
                return "#feb0af";
            case "ELIMINADO":
                return "#feb0af";
            case "VENCIDO":
                return "#feb0af";
            case "RECHAZO DE CLIENTE":
                return "#feb0af";
            case "SIN GARANTIA":
                return "#feb0af";
            case "SIN SALDO":
                return "#feb0af";
            case "ERROR":
                return "#feb0af";
            default:
                return "#84efd0";
        }
    };

    getCodeMsg = (code) => {
        if (code === "RECHAZO DE CLIENTE") {
            return "RECHAZADO";
        }
        return code;
    };

    contextMenu = () => {
        const { request, isDesktop, dispatch } = this.props;
        const {
            id,
            status: { code },
        } = request;
        if (code === "INICIADO") {
            return (
                <ContextMenu
                    styleContext={{ justifyContent: "flex-end" }}
                    isDesktop={isDesktop}
                    items={[
                        {
                            label: "fixedTermAcceptance.table.menu.accept",
                            onClick: () => {
                                dispatch(debinActions.acceptIncomingRequestPre(id));
                                dispatch(push(`/fixedTermAcceptance/accept/confirm`));
                            },
                        },
                        {
                            label: "fixedTermAcceptance.table.menu.decline",
                            onClick: () => {
                                dispatch(debinActions.acceptIncomingRequestPre(id));
                                dispatch(push(`/fixedTermAcceptance/decline/confirm`));
                            },
                        },
                    ]}
                />
            );
        }
        return null;
    };

    render() {
        const { request, isDesktop, tableLabel } = this.props;
        const {
            id,
            expirationDate,
            seller: { titular, documentType, documentNumber },
            amount: { currency, quantity },
            status: { code },
            buyer: { cbu },
        } = request;

        if (isDesktop) {
            return (
                <>
                    <div className="table-data">
                        <span className="data-aux">{id}</span>
                    </div>
                    <div className="table-data" style={{ textAlign: "center" }}>
                        <span className="data-aux">
                            <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                        </span>
                    </div>
                    <div className="table-data" style={{ textAlign: "center" }}>
                        <span className="data-aux">{titular}</span>
                    </div>
                    <div className="table-data" style={{ textAlign: "center" }}>
                        <span className="data-aux">{`${documentType} ${documentNumber}`}</span>
                    </div>
                    <div className="table-data" style={{ textAlign: "center " }}>
                        <span className="data-aux">{cbu}</span>
                    </div>
                    <div className="table-data">
                        <FormattedAmount currency={currency} quantity={quantity} />
                    </div>
                    <div className="table-data" style={{ display: "flex" }}>
                        <InfoTag
                            type="info"
                            message={this.getCodeMsg(code)}
                            tagBackground={this.statusTagColor(code)}
                            moreStyles={{
                                alignSelf: "flex-end",
                                width: "min-content",
                                color: "black",
                                margin: "0.5rem 0",
                            }}
                        />
                    </div>
                    <div className="table-data">{this.contextMenu()}</div>
                </>
            );
        }

        return (
            <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Heading.DataGroup
                        label={`${tableLabel}.id`}
                        data={<span className="data-aux">{id}</span>}
                        containerClassName="transfer-data data-wrapper my-0"
                        labelClassName="my-0"
                    />
                    <div style={{ display: "flex", position: "relative", left: "1rem" }}>
                        <InfoTag
                            type="info"
                            message={code}
                            tagBackground={this.statusTagColor(code)}
                            moreStyles={{
                                alignSelf: "flex-start",
                                width: "fit-content",
                                color: "black",
                                margin: "0",
                            }}
                        />
                        <button
                            type="button"
                            className="btn btn-link no-shadow"
                            style={{ padding: 0, margin: 0, height: "1.4rem", width: "1.7rem" }}
                            onClick={(e) => e.stopPropagation()}>
                            {this.contextMenu()}
                            {/* <ContextMenu
                                styleContext={{ justifyContent: "flex-end", opacity: code === "INICIADO" ? 1 : 0 }}
                                isDesktop={isDesktop}
                                items={[
                                    {
                                        label: "charges.debin.table.menu.cancel",
                                        onClick: this.handleCancel,
                                    },
                                ]}
                            /> */}
                        </button>
                    </div>
                </div>
                <div style={{ paddingRight: "1rem" }}>
                    <Heading.DataGroup
                        label={`${tableLabel}.expiration`}
                        data={
                            <span className="data-aux">
                                <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                            </span>
                        }
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label={`${tableLabel}.applicant`}
                        data={<span className="data-aux">{titular}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label={`${tableLabel}.documentType`}
                        data={<span className="data-aux">{`${documentType} ${documentNumber}`}</span>}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                    <Heading.DataGroup
                        label={`${tableLabel}.amount`}
                        data={<FormattedAmount currency={currency} quantity={quantity} />}
                        containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                        labelClassName="mb-0"
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(FixedTermAcceptanceItem);
