import React from "react";
import { connect } from "react-redux";
import { bool } from "prop-types";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import classNames from "classnames";
import Yup from "yup";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Col from "react-bootstrap/lib/Col";
import Notification from "pages/_components/Notification";
import isTokenActive from "util/token";
import { buildSummaryInfo } from "util/servicePayments";

import { actions as servicePaymentsActions, selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import { selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";

const MultiplePaymentsConfirmationToken = ({ isSubmitting, isDesktop }) => (
    <Form className={`${!isDesktop && "token-mobile-form mx-4"}`}>
        <Notification scopeToShow="servicePayments" />
        <Col sm={12} className="p-0">
            <Container
                className={`flex-grow align-items-center ${isDesktop && "container-white"} pt-5 pb-3 mb-3`}
                gridClassName="form-content">
                <Col className={classNames("px-lg-0", { "token-mobile": !isDesktop })} lg={4} md={6} sm={12}>
                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                        {!isTokenActive(!isDesktop) ? (
                            <div className={`admin-content-center ${!isDesktop && "admin-content-center-mobile mb-4"}`}>
                                <I18n id="transfer.summary.confirm.label" />
                            </div>
                        ) : (
                            <div className="admin-content-center pb-3">
                                <I18n
                                    id="confirmation.withoutotp.text"
                                    component="p"
                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                />
                            </div>
                        )}
                        <div className="admin-content-center">
                            <Field
                                placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                name="otp"
                                type="otp"
                                idForm="new.check"
                                component={Credential}
                            />
                        </div>
                    </Container>
                </Col>
            </Container>
        </Col>
        <Col className="col col-12 px-0" sm={12}>
            <Container className="flex-grow align-items-center" gridClassName="form-content">
                <Col className={`${!isDesktop && "token-button-mobile"}`} lg={4} md={6} sm={12} xl={4}>
                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                        <Button
                            type="submit"
                            bsStyle="primary"
                            label="global.confirm"
                            image="images/arrowRight.svg"
                            imageStyle="mr-2"
                            className="text-capitalize"
                            loading={isSubmitting}
                        />
                    </Container>
                </Col>
            </Container>
        </Col>
    </Form>
);

MultiplePaymentsConfirmationToken.propTypes = {
    isSubmitting: bool.isRequired,
    isDesktop: bool,
};

MultiplePaymentsConfirmationToken.defaultProps = {
    isDesktop: false,
};

const mapStateToProps = (state) => ({
    account: servicePaymentsSelectors.getMultiplePaymentsAccount(state),
    accounts: accountsSelectors.getAccounts(state),
    userId: sessionSelectors.getUser(state).userId,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, payments, accounts, account, userId } = formikBag.props;
            const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === account);
            const checkedPayments = payments.filter(({ isChecked }) => isChecked);
            const onePaymentSelected = checkedPayments.length === 1;

            if (onePaymentSelected) {
                const summaryInfo = buildSummaryInfo(checkedPayments[0], completeDebitAccount);
                dispatch(servicePaymentsActions.submitPayment(summaryInfo, otp, formikBag));
            } else {
                dispatch(
                    servicePaymentsActions.submitMultiplePayments(
                        {
                            checkedPayments: checkedPayments.map((payment) => {
                                const summaryInfo = buildSummaryInfo(payment, completeDebitAccount);
                                const {
                                    paymentReference,
                                    selectedConcept: { minimumlengthTextReference },
                                } = summaryInfo;
                                const newPaymentReference = summaryInfo.selectedConcept?.validLengthReference
                                    ? paymentReference.padStart(parseInt(minimumlengthTextReference, 10), "0")
                                    : paymentReference;
                                const newPayment = { ...payment };
                                newPayment.paymentReference = newPaymentReference;
                                return {
                                    summaryInfo,
                                    ...newPayment,
                                };
                            }),
                            completeDebitAccount,
                            otp,
                            userId,
                        },
                        formikBag,
                    ),
                );
            }
        },
    }),
)(MultiplePaymentsConfirmationToken);
