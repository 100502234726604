import * as API from "middleware/api";

const documentType = {
    documentBack: "BackDocument",
    documentFront: "FrontDocument",
    passport: "FrontDocument",
    selfie: "Selfie",
};

export const listStates = () => API.executeAnonymous("/onboarding.states.list");

export const listJobs = () => API.executeAnonymous("/onboarding.jobs.list");

export const listOccupations = () => API.executeAnonymous("/onboarding.occupations.list");

export const listSelectorList = () => API.executeAnonymous("/onboarding.selectors.list");

export const uploadClientInfo = (
    _documentNumber,
    _email,
    _firstName,
    _lastName,
    _mobile,
    _address,
    _jobInformation,
    _exchangeToken,
    _SSNID,
    _region,
) =>
    API.executeWithExchangeToken(
        "/onboarding.wizard.step5",
        {
            _documentNumber,
            _email,
            _firstName,
            _lastName,
            _mobile,
            _address,
            _jobInformation,
            _SSNID,
            _region,
        },
        _exchangeToken,
    );

export const uploadPicture = (docType, _documentToSave, _exchangeToken, rotatePicture) => {
    const params = {
        _documentToSave,
        isPassport: docType === "passport",
        rotatePicture,
    };
    if (_exchangeToken) {
        switch (documentType[docType]) {
            case documentType.documentFront:
                return API.executeWithExchangeToken("/onboarding.wizard.uploadFrontDocument", params, _exchangeToken);
            case documentType.documentBack:
                return API.executeWithExchangeToken("/onboarding.wizard.uploadBackDocument", params, _exchangeToken);
            case documentType.selfie:
                return API.executeWithExchangeToken("/onboarding.wizard.uploadSelfie", params, _exchangeToken);
            default:
                break;
        }
    }

    switch (documentType[docType]) {
        case documentType.documentFront:
            return API.executeAnonymous("/onboarding.wizard.uploadFrontDocument", params);
        case documentType.documentBack:
            return API.executeAnonymous("/onboarding.wizard.uploadBackDocument", params);
        case documentType.selfie:
            return API.executeAnonymous("/onboarding.wizard.uploadSelfie", params);
        default:
            break;
    }
    return null;
};
