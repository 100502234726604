import { combineReducers } from "redux";

import createChannelsReducerModule from "reducers/administration/common/channels";
import createChannelsTicketReducerModule from "reducers/administration/common/channelsTicket";
import createDetailsReducerModule from "reducers/administration/common/details";
import createGroupsOfUserTicketReducerModule from "reducers/administration/common/groupsOfUserTicket";
import createGroupsReducerModule from "reducers/administration/common/groups";
import createPermissionsReducerModule from "reducers/administration/common/permissions";
import createSignaturesSchemeReducerModule from "reducers/administration/common/signaturesSchemes";
import createSignaturesSchemesTicketReducerModule from "reducers/administration/common/signaturesSchemesTicket";
import createSignatureTicketReducerModule from "reducers/administration/common/signatureTicket";
import groupFormDataReducer from "reducers/administration/common/groupFormData";
import groupReducer from "reducers/administration/common/group";
import navigationReducer from "reducers/administration/common/navigation";

const { types: detailsTypes, actions: detailsActions, reducer: detailsReducer } = createDetailsReducerModule(
    "administrationAdvanced",
);
const { types: groupsTypes, actions: groupsActions, reducer: groupsReducer } = createGroupsReducerModule(
    "administrationAdvanced",
);

const {
    actions: channelsTicketActions,
    reducer: channelsTicketReducer,
    types: channelsTicketTypes,
} = createChannelsTicketReducerModule("administrationAdvanced");

const {
    actions: groupsOfUserTicketActions,
    reducer: groupsOfUserTicketReducer,
    types: groupsOfUserTicketTypes,
} = createGroupsOfUserTicketReducerModule("administrationAdvanced");

const {
    actions: permissionsActions,
    reducer: permissionsReducer,
    types: permissionsTypes,
} = createPermissionsReducerModule("administrationAdvanced");

const {
    actions: signatureTicketActions,
    reducer: signatureTicketReducer,
    types: signatureTicketTypes,
} = createSignatureTicketReducerModule("administrationAdvanced");

const {
    actions: signaturesSchemesActions,
    reducer: signaturesSchemesReducer,
    types: signaturesSchemesTypes,
} = createSignaturesSchemeReducerModule("administrationAdvanced");

const {
    actions: signaturesSchemesTicketActions,
    reducer: signaturesSchemesTicketReducer,
    types: signaturesSchemesTicketTypes,
} = createSignaturesSchemesTicketReducerModule("administrationAdvanced");

const { types: channelsTypes, actions: channelsActions, reducer: channelsReducer } = createChannelsReducerModule(
    "administrationAdvanced",
);

export {
    channelsActions,
    channelsTypes,
    channelsTicketActions,
    channelsTicketTypes,
    detailsActions,
    detailsTypes,
    groupsActions,
    groupsTypes,
    groupsOfUserTicketActions,
    groupsOfUserTicketTypes,
    signaturesSchemesActions,
    signaturesSchemesTypes,
    signaturesSchemesTicketActions,
    signaturesSchemesTicketTypes,
    signatureTicketActions,
    signatureTicketTypes,
    permissionsActions,
    permissionsTypes,
};

export default combineReducers({
    channels: channelsReducer,
    channelsTicket: channelsTicketReducer,
    details: detailsReducer,
    group: groupReducer,
    navigation: navigationReducer,
    groupFormData: groupFormDataReducer,
    groups: groupsReducer,
    groupsOfUserTicket: groupsOfUserTicketReducer,
    permissions: permissionsReducer,
    signaturesSchemes: signaturesSchemesReducer,
    signaturesSchemesTicket: signaturesSchemesTicketReducer,
    signatureTicket: signatureTicketReducer,
});
