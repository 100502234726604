import React, { Component } from "react";
import { arrayOf, shape, bool, string, func } from "prop-types";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/lib/Col";

import { actions as checksActions } from "reducers/checks";
import Table from "pages/_components/Table";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import Heading from "pages/_components/Heading";
import InfoTag from "pages/_components/InfoTag";
import { checksDetailStatusTagColor, discountedChecksDetailGetCodeMsg } from "util/checks";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import DiscountedChecksDetailItem from "./DiscountedChecksDetailItem";

class DiscountedChecksDetailTable extends Component {
    static propTypes = {
        listDiscountedChecksDetail: arrayOf(shape({})).isRequired,
        isDesktop: bool,
        tableLabel: string.isRequired,
        dispatch: func.isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    handleCheckDetailClick = (checkDetail) => {
        const { dispatch } = this.props;
        dispatch(checksActions.setCheckDetail(checkDetail));
    };

    cheqsListMobile = (checkDetail, tableLabel) => {
        const {
            comprobante: cheqNumber,
            numeroOperacion: opNumber,
            codigoEstadoCheque: state,
            codigoMoneda: codeCurrency,
            importe: amount,
            denominacionBancoLibrador: delivererBank,
            codigoSolicitud: requestCode,
            fechaVencimientoCheque: expireDate,
        } = checkDetail;

        return (
            <div className="container-white row my-2 mx-0" key={cheqNumber}>
                <Col xs={12} className="px-1">
                    <Link
                        to={`/discountedChecksDetail/${cheqNumber}`}
                        onClick={() => this.handleCheckDetailClick(checkDetail)}>
                        <Col xs={5}>
                            <Heading.DataGroup
                                containerClassName="transfer-data wrap data-wrapper-flex space-between mt-2"
                                dataClassName="data-desc small-font"
                                label={`${tableLabel}.table.checkNumber`}
                                data={cheqNumber}
                            />
                        </Col>
                        <Col xs={7} className="px-2 pt-2">
                            <div className="justify-content-end d-flex w-100">
                                <InfoTag
                                    type="info"
                                    message={discountedChecksDetailGetCodeMsg(state)}
                                    tagBackground={checksDetailStatusTagColor(discountedChecksDetailGetCodeMsg(state))}
                                    moreStyles={{
                                        display: "flex",
                                        alignSelf: "flex-end",
                                        width: "fit-content",
                                        color: "black",
                                        margin: "0.5rem 0",
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs={12} className="pr-2">
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex space-between"
                                dataClassName="data-desc small-font"
                                label={`${tableLabel}.table.operationNumber`}
                                data={opNumber}
                            />
                        </Col>
                        <Col xs={12} className="pr-2">
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex space-between"
                                dataClassName="data-desc small-font"
                                label={`${tableLabel}.table.liquidationNumber`}
                                data={requestCode}
                            />
                        </Col>
                        <Col xs={12} className="pr-2">
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex space-between"
                                dataClassName="data-desc small-font"
                                label={`${tableLabel}.table.issuingBank`}
                                data={delivererBank}
                            />
                        </Col>
                        <Col xs={12} className="pr-2">
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex space-between"
                                dataClassName="data-desc small-font"
                                label={`${tableLabel}.table.amount`}
                                data={
                                    <FormattedAmount
                                        currency={codeCurrency.toString()}
                                        quantity={amount}
                                        notBold
                                        small
                                    />
                                }
                            />
                        </Col>
                        <Col xs={12} className="pr-2">
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex space-between"
                                dataClassName="data-desc small-font"
                                label={`${tableLabel}.table.expirationDate`}
                                data={<FormattedDate date={expireDate} dateFormat="dd/MM/yyyy" />}
                            />
                        </Col>
                    </Link>
                </Col>
            </div>
        );
    };

    render() {
        const { listDiscountedChecksDetail, isDesktop, tableLabel } = this.props;

        if (!isDesktop && listDiscountedChecksDetail?.length) {
            return listDiscountedChecksDetail.map((checkDetail) => this.cheqsListMobile(checkDetail, tableLabel));
        }

        const list = listDiscountedChecksDetail.map((checkDetail) => (
            <Table.Row
                className="container-white check-detail-item"
                key={checkDetail.numeroCheque}
                renderAs={Link}
                to={`/discountedChecksDetail/${checkDetail.comprobante}`}
                onClick={() => this.handleCheckDetailClick(checkDetail)}>
                <DiscountedChecksDetailItem key={`transaction-${checkDetail.numeroCheque}`} checkDetail={checkDetail} />
            </Table.Row>
        ));

        const renderTable = isDesktop ? (
            <Table className="my-0">
                <Table.Header className="container-white">
                    <Table.HeaderData align="left">
                        <I18n component="span" id={`${tableLabel}.table.checkNumber`} />
                    </Table.HeaderData>
                    <Table.HeaderData>
                        <I18n id={`${tableLabel}.table.operationNumber`} />
                    </Table.HeaderData>
                    <Table.HeaderData>
                        <I18n id={`${tableLabel}.table.liquidationNumber`} />
                    </Table.HeaderData>
                    <Table.HeaderData>
                        <I18n id={`${tableLabel}.table.issuingBank`} />
                    </Table.HeaderData>
                    <Table.HeaderData align="right">
                        <I18n id={`${tableLabel}.table.amount`} />
                    </Table.HeaderData>
                    <Table.HeaderData align="right">
                        <I18n id={`${tableLabel}.table.expirationDate`} />
                    </Table.HeaderData>
                    <Table.HeaderData align="right">
                        <I18n id={`${tableLabel}.table.situation`} />
                    </Table.HeaderData>
                    <Table.HeaderData />
                </Table.Header>
                <Table.Body>{list}</Table.Body>
            </Table>
        ) : (
            list
        );

        return (
            <Container className="flex-grow scrollable mt-6">
                <Col className="col col-12 px-0">
                    <div
                        style={{ borderSpacing: "0px 2px", width: "100%" }}
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {listDiscountedChecksDetail.length ? (
                            renderTable
                        ) : (
                            <div className="text-center no-more-data" key="noMoreMovements">
                                <div className="illustration-wrapper">
                                    <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                                </div>
                                <p className="text-lead" style={{ opacity: 1 }}>
                                    <I18n id={`${tableLabel}.empty.message`} />
                                </p>
                            </div>
                        )}
                    </div>
                </Col>
            </Container>
        );
    }
}

export default DiscountedChecksDetailTable;
