import React, { Component, Fragment } from "react";
import { shape, bool, string } from "prop-types";
import { connect } from "react-redux";
import * as i18nUtils from "util/i18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import Heading from "pages/_components/Heading";
import classNames from "classnames";

import { selectors as financingSelectors } from "reducers/financing";

class FinancingItem extends Component {
    static propTypes = {
        request: shape({}).isRequired,
        isDesktop: bool.isRequired,
        tableLabel: string.isRequired,
    };

    render() {
        const { request, isDesktop, tableLabel } = this.props;
        const {
            numeroOperacion,
            cuotasVencidasImpagas,
            descripcionTipoOperacion,
            fechaOrigen,
            fechaVencimientoPrestamo,
            cuotasPagadas,
            cantidadCuotasTotales,
            codigoMoneda,
            saldoOperativo,
        } = request;

        if (isDesktop) {
            return (
                <Fragment>
                    <div className="table-data financing-item-data text-align-left">
                        <span className="data-aux">{numeroOperacion}</span>
                    </div>
                    <div className="table-data financing-item-data text-align-center">
                        <span className="data-aux">{descripcionTipoOperacion}</span>
                    </div>
                    <div className="table-data financing-item-data text-align-center">
                        <span className="data-aux">{fechaOrigen}</span>
                    </div>
                    <div className="table-data financing-item-data text-align-center">
                        {fechaVencimientoPrestamo ? (
                            <span className="data-aux">{fechaVencimientoPrestamo}</span>
                        ) : (
                            <span className="data-aux"> - </span>
                        )}
                    </div>
                    <div className="table-data financing-item-data text-align-center">
                        <span
                            className={classNames("data-aux", {
                                "red-dot p-relative top-10": cuotasVencidasImpagas > 0,
                            })}>
                            {cuotasPagadas}/{cantidadCuotasTotales}
                        </span>
                        {cuotasVencidasImpagas > 0 && (
                            <span className="red small-font data-amount text-center p-relative top-4">
                                {i18nUtils.get(`financing.table.header.expirationFeed`)}
                            </span>
                        )}
                    </div>
                    <p className="table-data text-right pr-2">
                        <FormattedAmount
                            currency={i18nUtils.get(`currency.label.${codigoMoneda}`)}
                            quantity={saldoOperativo}
                            fontClassName="font-size-14-px"
                        />
                    </p>
                </Fragment>
            );
        }
        return (
            <div className="d-flex f-dir-col w-100 pt-2 pb-2 pl-2 pr-2 container-details">
                <Heading.DataGroup
                    label={`${tableLabel}.operationNumber`}
                    data={numeroOperacion}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.typeNumber`}
                    data={descripcionTipoOperacion}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.date`}
                    data={fechaOrigen}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.dateExpiration`}
                    data={fechaVencimientoPrestamo}
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.pendingFee`}
                    data={`${cuotasPagadas}/${cantidadCuotasTotales}`}
                    containerClassName={classNames("transfer-data data-wrapper-flex space-between mb-0", {
                        "red-dot-mb": cuotasVencidasImpagas > 0,
                    })}
                    labelClassName="mb-0"
                />
                <Heading.DataGroup
                    label={`${tableLabel}.amount`}
                    data={
                        <FormattedAmount
                            currency={i18nUtils.get(`currency.label.${codigoMoneda}`)}
                            quantity={saldoOperativo}
                        />
                    }
                    containerClassName="transfer-data data-wrapper-flex space-between mb-0 transfer-data-custom"
                    labelClassName="mb-0"
                />
                {cuotasVencidasImpagas > 0 && (
                    <span className="red small-font data-amount text-right">
                        {i18nUtils.get(`financing.table.header.expirationFeed`)}
                    </span>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    requests: financingSelectors.getFinancing(state),
});

export default connect(mapStateToProps)(FinancingItem);
