import React, { Component, Fragment } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { Formik, Field, Form } from "formik";
import Yup from "yup";
import { arrayOf, func, string, shape } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import { selectors as formSelectors } from "reducers/form";
import * as i18n from "util/i18n";
import { flattenArray, removeDuplicateItems } from "util/array";

import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import I18n from "pages/_components/I18n";
import * as FormConfirmationMessages from "pages/forms/_components/_confirmations/Index";
import Message from "pages/forms/_components/_fields/_scheduler/Message";

class FormConfirmation extends Component {
    static propTypes = {
        idForm: string.isRequired,
        credentials: arrayOf(string).isRequired,
        dispatch: func.isRequired,
        submitAction: func.isRequired,
        submitActionParams: shape({
            values: shape({
                scheduler: string,
            }),
        }).isRequired,
        currentLang: string.isRequired,
        metadata: shape({}).isRequired,
        renderFields: func.isRequired,
    };

    validationSchema = () => {
        const { credentials } = this.props;
        return Yup.object().shape(
            credentials.reduce(
                (values, credential) => ({
                    ...values,
                    [credential]: Yup.string().required(i18n.get(`form.credential.${credential}.required`)),
                }),
                {},
            ),
        );
    };

    handleSubmit = (credentials, formikBag) => {
        const { dispatch, submitAction, submitActionParams } = this.props;
        dispatch(submitAction({ ...submitActionParams, credentials, formikBag }));
    };

    renderDefaultConfirmation = (requireSomeCredential) => {
        const { renderFields } = this.props;
        return (
            <Fragment>
                {requireSomeCredential && (
                    <p className="text-lead">
                        <I18n id="forms.default.confirmation.title" />
                    </p>
                )}
                {renderFields()}
            </Fragment>
        );
    };

    render() {
        const {
            credentials,
            idForm,
            submitActionParams: {
                values: { scheduler },
            },
            currentLang,
            metadata,
        } = this.props;

        const FormConfirmationMessage =
            FormConfirmationMessages[(idForm.charAt(0).toUpperCase() + idForm.substr(1)).replace(".", "_")];
        const requireSomeCredential = credentials.length > 0;

        return (
            <div className="above-the-fold">
                <section className="container--layout align-items-center flex-grow">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                {FormConfirmationMessage ? (
                                    <FormConfirmationMessage metadata={metadata} currentLang={currentLang} />
                                ) : (
                                    this.renderDefaultConfirmation(requireSomeCredential)
                                )}
                            </Col>
                        </Row>
                        {scheduler && (
                            <Row className="justify-content-center">
                                <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                    <div className="data-wrapper">
                                        <span className="data-label">
                                            <span>
                                                <I18n id="forms.confirmation.scheduler" />
                                            </span>
                                        </span>
                                        <span className="data-text">
                                            <Message value={scheduler} />
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Grid>
                </section>

                <Formik
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={credentials.reduce((values, credential) => ({ ...values, [credential]: "" }), {})}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <section className="container--layout align-items-center">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            {requireSomeCredential ? (
                                                <I18n id="form.credential.hint" />
                                            ) : (
                                                <I18n id="form.confirmation.without.credential" />
                                            )}
                                            {credentials.map((credential) => (
                                                <div key={credential}>
                                                    <Field
                                                        idForm="form.credential"
                                                        name={credential}
                                                        component={Credential}
                                                        type={credential}
                                                    />
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                            <section className="container--layout align-items-center">
                                <Grid className="form-footer">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            <Button
                                                type="submit"
                                                label="global.send"
                                                bsStyle="primary"
                                                loading={isSubmitting}
                                            />
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    name: formSelectors.getName(state),
    metadata: formSelectors.getMetadata(state),
    submitAction: formSelectors.getSubmitAction(state),
    submitActionParams: formSelectors.getSubmitActionParams(state),
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(formSelectors.getCredentialsGroups(state)),
});

export default connect(mapStateToProps)(FormConfirmation);
