import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";
import { selectors } from "reducers/checks";
import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import EcheqTransactionData from "pages/checks/Echeqs/EcheqTransactions/EcheqTransactionData";
import ModifyPendingTransactionModal from "pages/transactions/_components/ModifyPendingTransactionModal";
import { actions as formActions } from "reducers/form";
import NegotiateEcheqTransactionData from "pages/checks/Echeqs/EcheqTransactions/NegotiateEcheqTransactionData";
import ContextMenu from "pages/_components/ContextMenu";
import TransactionTicket from "pages/_components/TransactionTicket";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";
import { echeqConceptLabel } from "util/checks";

const NEGOTIATE_ACTIVITY_ID = "negotiate.echeq.activity";
const FORM_ID = "echeq.transaction";

class EndorseTransaction extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
    };

    state = {
        isModalDisplayed: false,
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { dispatch, transactionData } = this.props;
        if (!transactionData.idTransaction) {
            dispatch(push("/transactions/list"));
        }
    }

    summaryDataComponent = () => <EcheqTransactionData />;

    signTransaction = () => {
        const { dispatch, transactionData } = this.props;

        const signTransactionData = this.summaryDataComponent();

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: transactionData?.idTransaction,
                idActivity: transactionData.idActivity,
                idForm: "",
                content: signTransactionData,
                title:
                    transactionData.idActivity === NEGOTIATE_ACTIVITY_ID
                        ? "activities.negotiate.echeq.activity"
                        : "activities.endorse.echeq.activity",
                pendingDispatch: transactionData?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: transactionData.idActivity,
                idTransaction: transactionData.idTransaction,
                idForm: "",
                ticketData: transactionData,
            }),
        );
    };

    centerContentMobile = () => {
        const { transactionData } = this.props;
        return (
            <div className="title-account-header-multiline-background-blue">
                <h1 style={{ width: "100%", marginTop: "2px" }}>
                    {i18n.get(`activities.${transactionData.idActivity}`)}
                </h1>
            </div>
        );
    };

    handleDismiss = () => {
        this.setState({ isModalDisplayed: false });
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { transactionData } = this.props;
        const ticketData = {
            ticketName:
                transactionData?.idActivity === NEGOTIATE_ACTIVITY_ID
                    ? i18n.get("echeqs.negotiate.title")
                    : i18n.get("echeqs.transaction.label"),
            ticketBody: [...this.generateTicketBody()],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    generateTicketBody = () => {
        const { transactionData } = this.props;
        const { echeq } = transactionData;

        const concept = `${echeq.cheque_concepto} ${echeqConceptLabel(echeq.cheque_concepto, "-")}`;

        if (transactionData?.idActivity === NEGOTIATE_ACTIVITY_ID) {
            return [
                getGeneralTicketItem(`negotiate.transaction.date.label`, transactionData?.submitDateTime, "datefulltime"),
                getGeneralTicketItem(
                    `negotiate.transaction.voucher.label`,
                    transactionData.idTransaction.substring(0, 8).toUpperCase(),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.beneficiary`, transactionData.beneficiary.reference, "string"),
                getGeneralTicketItem(
                    `${FORM_ID}.endorse`,
                    i18n.get(`echeqs.${transactionData.type.toLowerCase()}.label`),
                    "string",
                ),
                getGeneralTicketItem("echeqs.account.label", transactionData?.accountLabel, "string"),
                getGeneralTicketItem(`${FORM_ID}.reference`, transactionData.reference, "string"),
                getGeneralTicketItem(`${FORM_ID}.value.reference`, transactionData.referencevalue, "string"),
                getGeneralTicketItem(`${FORM_ID}.echeq.number`, echeq.cheque_numero, "string"),
                getGeneralTicketItem(`${FORM_ID}.echeq.id`, echeq.cheque_id, "string"),
                getGeneralTicketItem(`${FORM_ID}.emision`, echeq.fecha_emision, "date"),
                getGeneralTicketItem(`${FORM_ID}.payed`, echeq.fecha_pago, "date"),
                getGeneralTicketItem(`${FORM_ID}.amount`, echeq.monto, "amountARS"),
                getGeneralTicketItem(
                    `${FORM_ID}.echeq.type`,
                    i18n.get(`echeqs.emit.${echeq?.cheque_tipo.toLowerCase()}.label`),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.echeq.character`, echeq?.cheque_caracter, "string"),
                getGeneralTicketItem(`${FORM_ID}.mode`, i18n.get(`echeqs.${echeq.cheque_modo}.modo`), "string"),
                getGeneralTicketItem(`${FORM_ID}.motive`, echeq.cheque_motivo_pago, "string"),
                getGeneralTicketItem(`${FORM_ID}.concept`, concept, "string"),
            ];
        }

        return [
            getGeneralTicketItem(`negotiate.transaction.date.label`, transactionData?.creationDate, "datefulltime"),
            getGeneralTicketItem(
                `${FORM_ID}.transaction`,
                transactionData.idTransaction.substring(0, 8).toUpperCase(),
                "string",
            ),
            getGeneralTicketItem(`${FORM_ID}.echeq.number`, echeq.cheque_numero, "string"),
            getGeneralTicketItem(`${FORM_ID}.echeq.id`, echeq.cheque_id, "string"),
            getGeneralTicketItem(
                `${FORM_ID}.endorse`,
                i18n.get(`echeqs.${transactionData.type.toLowerCase()}.label`),
                "string",
            ),
            getGeneralTicketItem(`echeqs.endorse.date`, transactionData?.submitDateTime, "datefulltime"),
            getGeneralTicketItem(`${FORM_ID}.beneficiary`, transactionData.beneficiary.reference, "string"),
            getGeneralTicketItem(`${FORM_ID}.beneficiary.document`, transactionData.beneficiary.document, "string"),
            getGeneralTicketItem(`${FORM_ID}.amount`, echeq.monto, "amountARS"),
            getGeneralTicketItem(`${FORM_ID}.emision`, echeq?.fecha_emision, "date"),
            getGeneralTicketItem(`${FORM_ID}.payed`, echeq?.fecha_pago, "date"),
            getGeneralTicketItem(
                `${FORM_ID}.echeq.type`,
                i18n.get(`echeqs.emit.${echeq?.cheque_tipo.toLowerCase()}.label`),
                "string",
            ),
            getGeneralTicketItem(`${FORM_ID}.echeq.character`, echeq?.cheque_caracter, "string"),
            getGeneralTicketItem(`${FORM_ID}.mode`, i18n.get(`echeqs.${echeq.cheque_modo}.modo`), "string"),
            getGeneralTicketItem(`${FORM_ID}.concept`, concept, "string"),
            getGeneralTicketItem(`${FORM_ID}.motive`, echeq.cheque_motivo_pago, "string"),
            getGeneralTicketItem(`${FORM_ID}.reference`, transactionData.reference, "string"),
            getGeneralTicketItem(`${FORM_ID}.value.reference`, transactionData.referencevalue, "string"),
            getGeneralTicketItem(`${FORM_ID}.coelsa.response`, transactionData.coelsaResponse, "string"),
            getGeneralTicketItem(`${FORM_ID}.coelsa.code`, transactionData.coelsaCode, "string"),
        ];
    };

    rightContentTransactionScheme = (isFinished) => {
        const { isDesktop, transactionData } = this.props;

        if (isDesktop || isFinished) {
            return <></>;
        }

        return (
            <>
                {transactionData?.idTransactionStatus !== "CANCELLED" ||
                    (transactionData?.idTransactionStatus !== "FAILED" && (
                        <ContextMenu
                            isDesktop={isDesktop}
                            buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                            items={[
                                {
                                    label: "pendingTransaction.info.scheme",
                                    onClick: () => this.setState({ showDrawerMobile: true }),
                                },
                            ]}
                        />
                    ))}
            </>
        );
    };

    buttonsDetail = () => {
        const { dispatch, transactionData } = this.props;

        if (transactionData) {
            const { idTransactionStatus } = transactionData;
            if (idTransactionStatus !== "PENDING") {
                return [
                    {
                        label: "global.goToHome",
                        bsStyle: "primary",
                        onClick: () => dispatch(push("/desktop")),
                    },
                ];
            }
        }
        return [];
    };

    getData = () => {
        const { transactionData, isDesktop } = this.props;
        return transactionData?.idActivity === NEGOTIATE_ACTIVITY_ID ? (
            <NegotiateEcheqTransactionData isDesktop={isDesktop} />
        ) : (
            <EcheqTransactionData isDesktop={isDesktop} />
        );
    };

    render() {
        const { transactionData, isDesktop, dispatch, isSmallDesktop, fetching } = this.props;
        const { isModalDisplayed, showDrawerMobile, fetchingDownload } = this.state;
        const isFinished = transactionData?.idTransactionStatus === "FINISHED";

        return (
            <>
                {transactionData?.idTransaction && (
                    <TransactionTicket
                        notificationScope="echeqs"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        uniqueDownload={isFinished}
                        rightContent={() => this.rightContentTransactionScheme(isFinished)}
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={transactionData}
                        isFetchingExport={fetchingDownload}
                        fetching={fetching}
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        showDrawerMobile={showDrawerMobile}
                        closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                        onSignTicket={this.signTransaction}
                        ignoreHomeButton
                        summaryTitle={`activities.${transactionData.idActivity}`}>
                        <ModifyPendingTransactionModal
                            isDesktop={isDesktop}
                            isDisplayed={isModalDisplayed}
                            handleDismiss={this.handleDismiss}
                            dispatch={dispatch}
                            idTransaction={transactionData.idTransaction}
                        />
                        {this.getData()}
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionEcheqData(state),
    fetchingDownload: selectors.getFetchingDownload(state),
    fetching: selectors.getFetching(state),
});

export default connect(mapStateToProps)(EndorseTransaction);
