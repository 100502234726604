import { createReducer, makeActionCreator } from "util/redux";

const INITIAL_STATE = {
    products: [],
    permissions: null,
    futurePermissions: null,
    groups: [],
    fetching: true,
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
};

export const selectors = {
    getProducts: ({ permissions }) => permissions.products,
    getPermissions: ({ permissions }) => permissions.permissions,
    getFuturePermissions: ({ permissions }) => permissions.futurePermissions,
    getGroups: ({ permissions }) => permissions.groups,
    isFetching: ({ permissions }) => permissions.fetching,
    getIdTransaction: ({ permissions }) => permissions.idTransaction,
    getIdActivity: ({ permissions }) => permissions.idActivity,
    getCredentialGroups: ({ permissions }) => permissions.credentialGroups,
};

export default (name) => {
    const types = {
        LOAD_PERMISSIONS_REQUEST: `${name}/LOAD_PERMISSIONS_REQUEST`,
        LOAD_PERMISSIONS_FAILURE: `${name}/LOAD_PERMISSIONS_FAILURE`,
        LOAD_PERMISSIONS_SUCCESS: `${name}/LOAD_PERMISSIONS_SUCCESS`,
        UPDATE_PERMISSIONS_PREVIEW: `${name}/UPDATE_PERMISSIONS_PREVIEW`,
        UPDATE_PERMISSIONS_PREVIEW_SUCCESS: `${name}/UPDATE_PERMISSIONS_PREVIEW_SUCCESS`,
        UPDATE_PERMISSIONS_REQUEST: `${name}/UPDATE_PERMISSIONS_REQUEST`,
        UPDATE_PERMISSIONS_REQUEST_SUCCESS: `${name}/UPDATE_PERMISSIONS_REQUEST_SUCCESS`,
    };

    return {
        types,
        reducer: createReducer(INITIAL_STATE, {
            [types.LOAD_PERMISSIONS_REQUEST]: (state) => ({ ...state, fetching: true, futurePermissions: null }),
            [types.LOAD_PERMISSIONS_FAILURE]: (state) => ({ ...state, fetching: false }),

            [types.LOAD_PERMISSIONS_SUCCESS]: (state, { data }) => ({
                ...state,
                permissions: data.permissions,
                products: data.products,
                groups: data.groups,
                fetching: false,
            }),
            [types.UPDATE_PERMISSIONS_PREVIEW_SUCCESS]: (
                state,
                { permissions, credentialGroups, idActivity, idTransaction },
            ) => ({
                ...state,
                futurePermissions: permissions,
                idActivity,
                idTransaction,
                credentialGroups,
            }),
            [types.UPDATE_PERMISSIONS_REQUEST_SUCCESS]: (state) => ({
                ...state,
                permissions: state.futurePermissions,
                fetching: true,
                futurePermissions: null,
            }),
        }),
        actions: {
            loadPermissionsRequest: makeActionCreator(types.LOAD_PERMISSIONS_REQUEST, "id"),
            loadPermissionsFailure: makeActionCreator(types.LOAD_PERMISSIONS_FAILURE),
            loadPermissionsSuccess: makeActionCreator(types.LOAD_PERMISSIONS_SUCCESS, "data"),
            updatePermissionsPreview: makeActionCreator(types.UPDATE_PERMISSIONS_PREVIEW, "data", "setSubmitting"),
            updatePermissionsPreviewSuccess: makeActionCreator(
                types.UPDATE_PERMISSIONS_PREVIEW_SUCCESS,
                "permissions",
                "credentialGroups",
                "idTransaction",
                "idActivity",
            ),
            updatePermissionsRequest: makeActionCreator(types.UPDATE_PERMISSIONS_REQUEST, "data", "formikBag"),
            updatePermissionsRequestSuccess: makeActionCreator(types.UPDATE_PERMISSIONS_REQUEST_SUCCESS),
        },
    };
};
