/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, node, number, func, bool, string } from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers/widgets";

import AccountsListItem from "pages/accounts/ListItem";
import WidgetList from "pages/desktop/widgets/WidgetList";
import Button from "pages/_components/Button";
import { push } from "react-router-redux/actions";
import WidgetHeader from "./_components/WidgetHeader";

import * as i18nUtils from "util/i18n";
import * as config from "util/config";


const WIDGET_NAME = "accounts";

class Accounts extends Component {
    static propTypes = {
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
        accountCount: number.isRequired,
        isEditable: bool,
        dispatch: func.isRequired,
        errorMsg: string.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        closeButton: null,
        isEditable: false,
        isDesktop: true,
    };

    render() {
        const { closeButton, errorMsg, draggableItemProps, accountCount, dispatch, isEditable, isDesktop } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetAccounts" };
        const maxItems = config.getInteger("dektop.max.items.widget", 3);

        return (
            <>
                <WidgetList 
                    item={AccountsListItem} 
                    keyExtractor={(item) => item.idProduct} 
                    name="accounts"
                    isEditable={isEditable}
                    close={closeButton}
                    >
                    {(list) => (
                        <div
                            role={isEditable ? "button" : "none"}
                            className={!isDesktop ? "widget accounts-widget" : "accounts-widget"}
                            {...uiAutomationProp}
                            {...draggableItemProps}
                            aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                            <WidgetHeader
                                title={i18nUtils.get("menu.accounts")}
                                action={
                                    <>
                                        {accountCount > maxItems && !isEditable && !errorMsg && (
                                            <Button
                                                onClick={() => {
                                                    dispatch(push("/accounts"));
                                                }}
                                                bsStyle="outline"
                                                label="menu.seemore.label"
                                                type="button"
                                                image="images/arrow-right-blue.svg"
                                            />
                                        )}
                                    </>
                                }
                            />
                            <div className="widget__content">{list}</div>
                        </div>
                    )}
                </WidgetList>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { data, errorMsg, isFetching } = selectors.getWidget(state, WIDGET_NAME);

    return {
        isFetching,
        accountCount: data.accountCount,
        errorMsg
    };
};

export default connect(mapStateToProps)(Accounts);
