import React, { Component } from "react";
import { func, bool, string, shape } from "prop-types";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { selectors as formSelectors } from "reducers/form";

import { get } from "util/i18n";
import { selectors as templateSelectors } from "reducers/template";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { connect } from "react-redux";
import ModifyTransaction from "./_confirmations/ModifyTransaction";

class FormActions extends Component {
    static propTypes = {
        onClick: func.isRequired,
        fetching: bool,
        mode: string.isRequired,
        draftsEnabled: bool,
        templatesEnabled: bool,
        transaction: shape({
            idTransactionStatus: bool.isRequired,
        }),
        isEditable: bool,
        isCancellingTransaction: bool.isRequired,
        idForm: bool.isRequired,
        isModalVisible: bool,
    };

    static defaultProps = {
        fetching: false,
        draftsEnabled: false,
        templatesEnabled: false,
        isEditable: true,
        transaction: {},
        isModalVisible: false,
    };

    state = {
        modifyTransactionConfirm: false,
    };

    getEditButtons = () => {
        const { onClick, fetching, draftsEnabled, templatesEnabled, mode, idForm, isModalVisible } = this.props;

        const label = `forms.${idForm}.send`;
        const defaultLabelText = get("global.send", "");

        if (mode === "edit") {
            return (
                <React.Fragment>
                    <Button
                        type="submit"
                        label={label}
                        bsStyle="primary"
                        loading={fetching}
                        defaultLabelText={defaultLabelText}
                    />

                    {draftsEnabled && (
                        <button
                            type="button"
                            className="btn btn-outline btn-block"
                            onClick={() => onClick("saveDraft")}>
                            <I18n id="forms.saveDraft.link" />
                        </button>
                    )}

                    {templatesEnabled && (
                        <button
                            type="button"
                            className="btn btn-outline btn-block"
                            onClick={() => onClick("createTemplate")}
                            aria-haspopup="dialog"
                            aria-expanded={isModalVisible}>
                            <I18n id="forms.saveTemplate.link" />
                        </button>
                    )}
                </React.Fragment>
            );
        }

        return null;
    };

    getViewButtons = () => {
        const { onClick, fetching, transaction, mode, isCancellingTransaction, isEditable } = this.props;
        const { modifyTransactionConfirm } = this.state;

        if (mode === "view" && transaction) {
            const { idTransactionStatus } = transaction;

            if (idTransactionStatus === "PENDING" || idTransactionStatus === "SCHEDULED") {
                return (
                    <>
                        <Col
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "2rem",
                            }}
                            sm={12}
                            className="col col-12 col-no-pad-mobile">
                            {idTransactionStatus === "PENDING" && (
                                <Button
                                    onClick={() => onClick("signTransaction")}
                                    label="forms.signTransaction.link"
                                    bsStyle="primary"
                                    loading={fetching}
                                />
                            )}
                            <Button
                                onClick={() => onClick("cancelTransaction")}
                                label="forms.cancelTransaction.link"
                                bsStyle="primary"
                                loading={isCancellingTransaction}
                            />
                            {isEditable && (
                                <button
                                    type="button"
                                    className="btn btn-outline btn-block"
                                    onClick={() => this.verifyBeforeModifyTransaction()}
                                    style={{ marginTop: "0.5rem" }}>
                                    <I18n id="forms.modifyTransaction.link" />
                                </button>
                            )}
                            {modifyTransactionConfirm && (
                                <ModifyTransaction
                                    modifyTransactionConfirm={this.state.modifyTransactionConfirm}
                                    onClose={this.cancelModifyHandleClick}
                                    modifyTransactionClick={() => onClick("modifyTransaction")}
                                />
                            )}
                        </Col>
                    </>
                );
            }
        }

        return null;
    };

    verifyBeforeModifyTransaction = () => {
        const { onClick, transaction } = this.props;

        if (transaction.signatures.length > 0) {
            this.setState({ modifyTransactionConfirm: true });
        } else {
            onClick("modifyTransaction");
        }
    };

    cancelModifyHandleClick = () => {
        this.setState({ modifyTransactionConfirm: false });
    };

    render() {
        const editButtons = this.getEditButtons();
        const viewButtons = this.getViewButtons();

        return (
            (editButtons || viewButtons) && (
                <section className="container--layout align-items-center">
                    <Grid className="form-footer">
                        <Row className="justify-content-center">
                            <Col className="col col-12">
                                {editButtons}
                                {viewButtons}
                            </Col>
                        </Row>
                    </Grid>
                </section>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    isCancellingTransaction: formSelectors.getFetching(state) && formSelectors.getIsCancellingTransaction(state),
    isModalVisible: templateSelectors.isCreateModalVisible(state),
});

export default connect(mapStateToProps)(FormActions);
