import React from "react";
import Heading from "pages/_components/Heading";
import { string, shape, bool } from "prop-types";
import * as i18n from "util/i18n";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";

export default function AccountSummaryData({ openingData, FORM_ID, signature, fullName, isDesktop }) {
    AccountSummaryData.propTypes = {
        openingData: shape({}).isRequired,
        FORM_ID: string.isRequired,
        signature: bool,
        fullName: string.isRequired,
    };
    AccountSummaryData.defaultProps = {
        signature: false,
    };

    const PreparedBy = () => (
        <p>
            {openingData?.creatorFullName || fullName} -{" "}
            <FormattedDate date={openingData?.creationDate} dateFormat="dd/MM/yyyy" showTime />
        </p>
    );

    const SignBy = () => (
        <p>
            {openingData?.signatures.map((object) => (
                <>
                    {object?.userFirstName.concat(" ", object?.userLastName)} -{" "}
                    <FormattedDate date={object?.creationDateTime} dateFormat="dd/MM/yyyy" showTime />
                    <br />
                </>
            ))}
        </p>
    );

    return (
        <>
            <Heading.DataGroup
                label={`${FORM_ID}.accountType.label`}
                data={i18n.get(`accounts.new.account.accountType.${openingData?.accountType}`)}
                containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between "}summary-data`}
                dataClassName={`deta-desc font-light ${isDesktop ? "f-size-4 ml-2" : "f-size-5"}`}
            />
            <Heading.DataGroup
                label={`${FORM_ID}.currency.label`}
                data={i18n.get(`accounts.new.account.currency.${openingData?.currency}`)}
                containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between "}summary-data`}
                dataClassName={`deta-desc font-light ${isDesktop ? "f-size-4 ml-2" : "f-size-5"}`}
            />
            <Heading.DataGroup
                label={`${FORM_ID}.message.label`}
                data={openingData?.message}
                containerClassName={`transfer-data data-wrapper-flex ${!isDesktop && "space-between "}summary-data`}
                dataClassName={`deta-desc font-light ${!isDesktop && "f-size-5"}`}
            />
            {signature && (
                <>
                    <I18n
                        component="h3"
                        id="accounts.new.account.signatures.label"
                        componentProps={{ className: "font-size-25-px" }}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex"
                        label="accounts.new.account.signatures.preparedBy.label"
                        data={<PreparedBy />}
                    />
                    {openingData?.signatures?.length > 0 && (
                        <>
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                label="accounts.new.account.signatures.signBy.label"
                                data={<SignBy />}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}
