import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { func, shape } from "prop-types";

import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import { selectors as sessionSelectors } from "reducers/session";

class PendingActions extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        user: shape({}).isRequired,
    };

    goToPage = (page) => {
        const { dispatch } = this.props;
        dispatch(push(page));
    };

    render() {
        const {
            user: { pepCompleted, irsCompleted },
        } = this.props;
        return (
            <>
                <Notification scopeToShow="pendingActions" />
                <MainContainer className="onboarding-layout">
                    <div className="above-the-fold pending-actions">
                        <Container className="container--layout flex-grow align-items-top">
                            <Col className="col col-12">
                                <div className="illustration-wrapper">
                                    <Image src="images/pending-actions.svg" className="illustration-icon" />
                                    <div className="illustration-text">
                                        <h1>
                                            <I18n component="strong" id="pendingActions.title" />
                                        </h1>
                                        <p>
                                            <I18n id="pendingActions.subtitle" />
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Container>
                        <Container className="container--layout flex-grow align-items-top">
                            <div className="col col-12 col-lg-6 col-md-9 centered">
                                {!pepCompleted && (
                                    <Container
                                        className="container--layout flex-grow pending-actions-item"
                                        onClick={() => {
                                            this.goToPage("/completePEP");
                                        }}>
                                        <Col className="col col-10 col-lg-11">
                                            <p>
                                                <I18n component="strong" id="pendingActions.pep.areyou" />
                                            </p>
                                            <h6>
                                                <I18n id="pendingActions.pep.menu.description" />
                                            </h6>
                                        </Col>
                                        <Col className="col col-2 col-lg-1">
                                            <ChevromRight />
                                        </Col>
                                    </Container>
                                )}
                                {!irsCompleted && (
                                    <Container
                                        className="container--layout flex-grow pending-actions-item"
                                        onClick={() => {
                                            this.goToPage("/completeIRS");
                                        }}>
                                        <Col className="col col-10 col-lg-11">
                                            <p>
                                                <I18n component="strong" id="pendingActions.irs.areyou" />
                                            </p>
                                            <h6>
                                                <I18n id="pendingActions.irs.menu.description" />
                                            </h6>
                                        </Col>
                                        <Col className="col col-2 col-lg-1">
                                            <ChevromRight />
                                        </Col>
                                    </Container>
                                )}
                            </div>
                        </Container>
                        <Container className="container--layout align-items-center">
                            <Col className="col col-12 text-centered">
                                <Button
                                    className="btn-outline"
                                    onClick={() => {
                                        this.goToPage("/desktop");
                                    }}
                                    label="pendingActions.remindmeLater"
                                />
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(PendingActions);
