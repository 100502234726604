import React, { Component } from "react";
import { bool } from "prop-types";
import I18n from "pages/_components/I18n";
import classNames from "classnames";
import Container from "pages/_components/Container";

class DebinTermsAndConditionsContent extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
    };

    render() {
        const { isDesktop } = this.props;

        return (
            <div className="above-the-fold">
                <div
                    className={classNames("admin-detail-head px-0", {
                        "mx-7": isDesktop,
                        "mx-3": !isDesktop,
                    })}>
                    <I18n
                        component="h1"
                        id="debin.termAndConditions.title"
                        componentProps={{ className: "mb-3 text-center" }}
                    />
                </div>
                <Container
                    className={classNames("container--layaut flex-grow align-items-center", {
                        "mx-6": isDesktop,
                        "mx-3": !isDesktop,
                    })}>
                    <div className="scrollable-content">
                        <I18n component="p" id="debin.termsAndConditions.content" />
                    </div>
                </Container>
            </div>
        );
    }
}

export default DebinTermsAndConditionsContent;
