import { call, put, takeLatest } from "redux-saga/effects";

import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as form from "middleware/form";

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.LOAD_CHANNELS_TICKET_REQUEST, loadChannelsTicketRequest(middleware, actions)),
];

export default sagasCreator;

const loadChannelsTicketRequest = (middleware, actions) =>
    function*({ idTransaction }) {
        const transactionResponse = yield call(form.readTransaction, idTransaction);

        if (transactionResponse.type === "W") {
            yield put(actions.loadChannelsTicketFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        } else {
            const {
                idUser,
                enabledChannels,
                maxAmounts,
                capFrequencies,
            } = transactionResponse.data.data.transaction.data;
            const channelsResponse = yield call(middleware.loadChannelsRequest, idUser);

            if (channelsResponse.type === "W") {
                yield put(actions.loadChannelsTicketFailure());
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                        "administration",
                    ]),
                );
            } else {
                yield put(actions.loadChannelsTicketSuccess());
                yield put(
                    actions.loadChannelsSuccess({
                        ...channelsResponse.data.data,
                        ...enabledChannels.reduce(
                            ({ caps, topAmount }, channel, index) => {
                                if (channel === "all") {
                                    return {
                                        caps,
                                        topAmount: {
                                            amount: maxAmounts[index],
                                            frequency: capFrequencies[index],
                                        },
                                    };
                                }

                                return {
                                    topAmount,
                                    caps: [
                                        ...caps,
                                        {
                                            channel,
                                            maximum: maxAmounts[index],
                                            frequency: capFrequencies[index],
                                        },
                                    ],
                                };
                            },
                            { caps: [], topAmount: {} },
                        ),
                    }),
                );
            }
        }
    };
