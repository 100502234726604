import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Heading from "pages/_components/Heading";
import { arrayOf, shape, func } from "prop-types";
import { actions as adminActions } from "reducers/administration/common/administrationTicket";

class GroupsOfUserTicket extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        groups: arrayOf(shape({})).isRequired,
        user: shape({}).isRequired,
    };

    state = {
        chargedData: false,
    };

    render() {
        const { groups, user, dispatch } = this.props;
        const groupText = groups.map(({ name, idGroupAsString }) => <div key={idGroupAsString}> {name} </div>);
        const { chargedData } = this.state;
        if (groups.length > 0) {
            const groupTextExport = groups.reduce((a, b) => a + b?.name.concat("\n "), "");

            if (groupTextExport && !chargedData) {
                this.setState({ chargedData: true });
                dispatch(
                    adminActions.setExtraData({
                        groupsExtraData: groupTextExport,
                        userExtraData: user.firstName.concat(" ", user.lastName),
                    }),
                );
            }

            return (
                <Col className="col col-12 group-of-users-ticket-data" sm={12} md={12} lg={12} xl={12}>
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.signature.user"
                        data={`${user.firstName} ${user.lastName}`}
                        spaceBetween
                    />
                    {/* <div className="transfer-data transfer-data-flow"> */}
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.users.detail.configureGroups.confirm"
                        data={groupText}
                        spaceBetween
                    />
                    {/* <Col columnClass="list-wrapper">
                    <div className="transfer-block">
                        <h4>
                            <I18n id="administration.users.detail.configureGroups.confirm" />
                        </h4>
                        {groupText}
                    </div>
                </Col> */}
                </Col>
            );
        }

        return null;
    }
}

export default GroupsOfUserTicket;
