/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, func } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as debinActions } from "reducers/debin";
import FormattedAmount from "pages/_components/FormattedAmount";
import InfoTag from "pages/_components/InfoTag";
import ContextMenu from "pages/_components/ContextMenu";
import { push } from "react-router-redux/actions";
import FormattedDate from "pages/_components/FormattedDate";
import { statusTagColor } from "util/debin";
import Card from "pages/_components/Card/Card";
import I18n from "pages/_components/I18n";
import CardContainer from "pages/_components/Card/CardContainer";

class RequestItem extends Component {
    static propTypes = {
        request: shape({}).isRequired,
        isDesktop: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    handleCancel = () => {
        const { request, dispatch } = this.props;
        dispatch(debinActions.debinToCancel(request));
        dispatch(push("debin/cancel/summary"));
    };

    render() {
        const { request, isDesktop } = this.props;
        const {
            id,
            expirationDate,
            buyer: { titular, documentType, documentNumber },
            amount: { currency, quantity },
            status: { code },
        } = request;

        if (isDesktop) {
            return (
                <>
                    <Card
                        className="mb-0"
                        title={
                            <div className="debin__card-title">
                                <div className="debin__date">
                                    <I18n
                                        id="charges.debin.table.header.expiration"
                                        componentProps={{ className: "debin__date-info" }}
                                    />
                                    <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                                </div>
                            </div>
                        }
                        content={
                            <div className="debin__card-content">
                                <div className="debin__cuit">
                                    <I18n
                                        id="charges.debin.table.header.documentType"
                                        componentProps={{ className: "debin__cuit-info" }}
                                    />
                                    <span className="debin__cuit-data">{`${documentType} ${documentNumber}`}</span>
                                </div>
                                <div className="debin__account">
                                    <I18n
                                        id="charges.debin.table.header.titular"
                                        componentProps={{ className: "debin__account-info" }}
                                    />
                                    <span className="debin__account-data">{titular}</span>
                                </div>
                            </div>
                        }
                        bottom={
                            <div className="debin__card-bottom">
                                <div className="debin__id no-separator">
                                    <I18n
                                        id="charges.debin.table.header.id"
                                        componentProps={{ className: "debin__id-info" }}
                                    />
                                    <span className="debin__id-data">{id}</span>
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="debin__card-right">
                                <div className="debin__balance">
                                    <FormattedAmount
                                        currency={currency}
                                        quantity={quantity}
                                        className="debin__balance-currency"
                                    />
                                    <InfoTag
                                        type="info"
                                        message={code}
                                        color="#000"
                                        tagBackground={statusTagColor(code)}
                                    />
                                </div>
                                <div className="debin__menu">
                                    {code === "INICIADO" ? (
                                        <ContextMenu
                                            styleContext={{ justifyContent: "flex-end" }}
                                            isDesktop={isDesktop}
                                            items={[
                                                {
                                                    label: "charges.debin.table.menu.cancel",
                                                    onClick: this.handleCancel,
                                                },
                                            ]}
                                        />
                                    ) : (
                                        <div className="debin__hidden-menu">&nbsp;</div>
                                    )}
                                </div>
                            </div>
                        }
                    />
                </>
            );
        }

        return (
            <CardContainer className="mt-0">
                <Card
                    className="mt-0"
                    title={
                        <div className="debin__card-title">
                            <div className="space-between w-100">
                                <div>
                                    <div className="debin__account">
                                        <I18n
                                            id="charges.debin.table.header.titular"
                                            componentProps={{ className: "debin__account-info" }}
                                        />
                                        <span className="debin__account-data">{titular}</span>
                                    </div>
                                    <div className="debin__cuit">
                                        <I18n
                                            id="charges.debin.table.header.documentType"
                                            componentProps={{ className: "debin__cuit-info" }}
                                        />
                                        <span className="debin__cuit-data">{`${documentType} ${documentNumber}`}</span>
                                    </div>
                                </div>
                                <div className="debin__menu">
                                    <button
                                        type="button"
                                        className="btn btn-link no-shadow m-0 p-0 pl-2"
                                        style={{ height: "1.4rem", width: "2rem" }}
                                        onClick={(e) => e.stopPropagation()}>
                                        <ContextMenu
                                            styleContext={{ opacity: code === "INICIADO" ? 1 : 0 }}
                                            isDesktop={isDesktop}
                                            items={[
                                                {
                                                    label: "charges.debin.table.menu.cancel",
                                                    onClick: this.handleCancel,
                                                },
                                            ]}
                                        />
                                    </button>
                                </div>
                            </div>
                            <hr className="mr-0" />
                        </div>
                    }
                    content={
                        <div className="debin__card-content">
                            <div className="debin__state">
                                <I18n
                                    id="charges.debin.table.header.status"
                                    componentProps={{ className: "debin__state-info" }}
                                />
                                <InfoTag type="info" message={code} color="#000" tagBackground={statusTagColor(code)} />
                            </div>
                            <div className="debin__date">
                                <I18n
                                    id="charges.debin.table.header.expiration"
                                    componentProps={{ className: "debin__date-info" }}
                                />
                                <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                            </div>
                            <div className="debin__id">
                                <I18n
                                    id="charges.debin.table.header.id"
                                    componentProps={{ className: "debin__id-info" }}
                                />
                                <span className="debin__id-data">{id}</span>
                            </div>
                        </div>
                    }
                    rightContent={
                        <div className="debin__card-right">
                            <div className="debin__balance">
                                <FormattedAmount
                                    currency={currency}
                                    quantity={quantity}
                                    className="debin__balance-currency"
                                />
                            </div>
                        </div>
                    }
                />
            </CardContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(RequestItem);
