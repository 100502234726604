import React, { Component } from "react";
import { bool } from "prop-types";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";

const FORM_ID = "echeqs";

class EmisionModesHint extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
    };

    render() {
        const { isDesktop } = this.props;

        return (
            <Container>
                <Col md={9} className={classNames({ "mr-4": !isDesktop })}>
                    <div
                        className={classNames("form-section-title admin-content-center", {
                            "mt-8 mb-6": isDesktop,
                            "mt-4 mb-5": !isDesktop,
                        })}>
                        <I18n component="h2" id={`${FORM_ID}.title.hint.emision.modes`} />
                    </div>
                    <div className="emision-modes-drawer p-0">
                        <ul className="ul-dash-style-type mb-6">
                            <I18n
                                component="li"
                                id={`${FORM_ID}.individual.mode`}
                                componentProps={{
                                    className: "mb-3 color-black light-font",
                                }}
                            />
                            <I18n
                                component="li"
                                id={`${FORM_ID}.multiple.mode`}
                                componentProps={{
                                    className: "mb-3 color-black light-font",
                                }}
                            />
                            <I18n
                                component="li"
                                id={`${FORM_ID}.personalized.mode`}
                                componentProps={{
                                    className: "pb-6 color-black light-font",
                                }}
                            />
                        </ul>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default EmisionModesHint;
