import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, replace, push } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { bool, func, string } from "prop-types";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import Yup from "yup";

import { selectors as onboardingSelectors, actions as onboardingActions } from "reducers/onboarding";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import withExchangeToken from "pages/_components/withExchangeToken";
import Container from "pages/_components/Container";
import * as i18n from "util/i18n";
import TextField from "pages/_components/fields/TextField";
import AddressGoogleField from "pages/_components/fields/AddressGoogleField";
import { DateField } from "pages/_components/fields/DateField";
import { format } from "date-fns";
import { Mixpanel } from "util/clickstreaming";

import { MAIN_ONBOARDING_ROUTE } from "./Step0";
import OnboardingStepper, { orientations } from "./_components/OnboardingStepper";

const FORM_ID = "onboarding.step5";

class Step5 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        documentType: string,
        isSubmitting: bool.isRequired,
        isMobile: bool.isRequired,
        activeRegion: string.isRequired,
        setFieldValue: func,
    };

    static defaultProps = {
        documentType: "",
        setFieldValue: () => {},
    };

    componentDidMount() {
        const { dispatch, documentType } = this.props;

        if (!documentType) {
            dispatch(replace(MAIN_ONBOARDING_ROUTE));
        }
    }

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    onPlaceChanged = (city, country, federalState) => {
        const { setFieldValue } = this.props;
        setFieldValue("city", city);
        setFieldValue("country", country);
        setFieldValue("federalState", federalState);
    };

    render() {
        const { documentType, isSubmitting, isMobile, activeRegion } = this.props;
        const indicators = [<I18n id="onboarding.step3" key="step3" />, <I18n id="onboarding.step4" key="step4" />];
        if (documentType === "document") {
            indicators.unshift(
                <I18n id="onboarding.step2.front.document" key="step2 - front" />,
                <I18n id="onboarding.step2.back.document" key="step2 - back" />,
            );
        } else if (documentType === "passport") {
            indicators.unshift(<I18n id="onboarding.step2.front.passport" key="step2" />);
        }

        return (
            <Fragment>
                <Head title={FORM_ID} onBack={isMobile && this.onHeaderBack} onClose={isMobile && this.onHeaderClose} />
                <div className="view-page">
                    {!isMobile && <OnboardingStepper currentStep={5} className="onboarding-steps" />}

                    <div className="view-content">
                        <main className="main-container">
                            <Form className="above-the-fold">
                                {isMobile && (
                                    <Container className="container--layout align-items-center">
                                        <Col className="col col-12 z_-1">
                                            <OnboardingStepper currentStep={5} orientation={orientations.horizontal} />
                                        </Col>
                                    </Container>
                                )}
                                <Container className="flex-grow container--layout align-items-center">
                                    <Col className="col col-12">
                                        <div className="form-group">
                                            <Row>
                                                <Col className="col col-12">
                                                    <Field
                                                        component={AddressGoogleField}
                                                        idForm={FORM_ID}
                                                        classNameForViewMode="data-desc"
                                                        maxLength={50}
                                                        name="addressLine1"
                                                        activeRegion={activeRegion}
                                                        onPlaceChanged={this.onPlaceChanged}
                                                    />
                                                </Col>
                                                <Col className="col col-12">
                                                    <Field
                                                        component={TextField}
                                                        idForm={FORM_ID}
                                                        classNameForViewMode="data-desc"
                                                        maxLength={50}
                                                        name="addressLine2"
                                                        hideLabel
                                                    />
                                                </Col>

                                                <Col className="col col-12">
                                                    <Field
                                                        component={TextField}
                                                        idForm={FORM_ID}
                                                        classNameForViewMode="data-desc"
                                                        maxLength={50}
                                                        name="city"
                                                    />
                                                </Col>
                                                <Col className="col col-12">
                                                    <Field
                                                        component={TextField}
                                                        idForm={FORM_ID}
                                                        classNameForViewMode="data-desc"
                                                        maxLength={50}
                                                        name="federalState"
                                                    />
                                                </Col>
                                                <Col className="col col-12">
                                                    <Row>
                                                        <Col className="col col-lg-6 col-md-6 col-sm-6 col-6">
                                                            <Field
                                                                component={TextField}
                                                                idForm={FORM_ID}
                                                                classNameForViewMode="data-desc"
                                                                maxLength={50}
                                                                name="zipcode"
                                                            />
                                                        </Col>
                                                        <Col className="col col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <Field
                                                                component={DateField}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                idForm={FORM_ID}
                                                                classNameForViewMode="data-desc"
                                                                maxLength={50}
                                                                name="residentSinceDate"
                                                                minDate={moment("2009-01-01")}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Container>
                                <Container className="container--layout align-items-center">
                                    <Col className="col col-12">
                                        <Button
                                            label="global.continue"
                                            type="submit"
                                            bsStyle="primary"
                                            loading={isSubmitting}
                                        />
                                    </Col>
                                </Container>
                            </Form>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    documentData: onboardingSelectors.getDocumentData(state),
    documentType: onboardingSelectors.getDocumentType(state),
    exchangeToken: onboardingSelectors.getExchangeToken(state),
    fetching: onboardingSelectors.getFetching(state),
    email: onboardingSelectors.getEmail(state),
    activeRegion: loginSelectors.getRegion(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: () => ({
            addressLine1: "",
            addressLine2: "",
            city: "",
            federalState: "",
            zipcode: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                addressLine1: Yup.string().required(i18n.get("onboarding.step5.field.error.empty")),
                city: Yup.string().required(i18n.get("onboarding.step5.field.error.empty")),
                federalState: Yup.string().required(i18n.get("onboarding.step5.field.error.empty")),
                zipcode: Yup.string().required(i18n.get("onboarding.step5.field.error.empty")),
                residentSinceDate: Yup.date().nullable(i18n.get("onboarding.step5.field.error.empty")),
            }),
        handleSubmit: ({ addressLine1, addressLine2, city, federalState, zipcode, residentSinceDate }, formikBag) => {
            const { dispatch } = formikBag.props;
            const residentSince = format(residentSinceDate, "YYYY-MM-DD");
            const address = {
                addressLine1,
                addressLine2,
                city,
                federalState,
                zipcode,
                residentSince,
            };

            dispatch(onboardingActions.uploadClientPersonalAddress(formikBag, address));
            Mixpanel.track(FORM_ID, { ...address });
            dispatch(push("/onboarding/step6"));
        },
    }),
)(withExchangeToken(Step5));
