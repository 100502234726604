import React, { Component } from "react";
import { connect } from "react-redux";
import { string, shape, objectOf, func } from "prop-types";
import { selectors, actions as adminActions } from "reducers/administration/common/administrationTicket";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import * as dateUtils from "util/date";
import Heading from "pages/_components/Heading";
import * as i18nUtils from "util/i18n";

class RestrictionsManageTicketData extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        transactionData: shape({
            data: objectOf(string),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
    };

    state = {
        chargedData: false,
        ip: null,
    };

    renderDays = (bitWiseNumber) => (
        <div>
            {dateUtils
                .getDaysNamesWithBitwiseNumber(bitWiseNumber)
                .map((i) => i)
                .join(", ")}
        </div>
    );

    generateExtraData(onlyEnvironment, dataToSave) {
        const { dispatch } = this.props;
        this.setState({ chargedData: true });

        let ip = null;

        let extraData = {
            label: "administration.restrictions.restrictions.type",
            value: onlyEnvironment
                ? i18nUtils.get("administration.restrictions.restrictions.environment")
                : i18nUtils.get("administration.restrictions.restrictions.user"),
        };

        if (!dataToSave.calendarToSave && onlyEnvironment) {
            extraData = {
                ...extraData,
                data1: {
                    label: "administration.restrictions.restrictions.byCalendar",
                    value: dataToSave.disableCalendar
                        ? i18nUtils.get("administration.restrictions.disableCalendar.label")
                        : i18nUtils.get("administration.restrictions.enableCalendar.label"),
                },
            };
        }
        if (!dataToSave.perpetual && dataToSave.calendarToSave && !dataToSave.disableCalendar) {
            extraData = {
                ...extraData,
                data2: { label: "administration.restrictions.timeZone.label", value: dataToSave.timeZone },
                data3: {
                    label: "administration.restrictions.restrictions.days",
                    value:
                        dataToSave.days !== 127
                            ? this.renderDays(dataToSave.days)
                            : i18nUtils.get("administration.restrictions.allDays.label"),
                },
                data4: {
                    label: "administration.restrictions.from",
                    value: dateUtils.timeinMinToString(dataToSave.startTime),
                },
                data5: {
                    label: "administration.restrictions.to",
                    value: dateUtils.timeinMinToString(dataToSave.endTime),
                },
                data6: {
                    label: "administration.restrictions.hrs",
                    value: dateUtils.timeinMinToString(dataToSave.endTime),
                },
            };
        }
        if (dataToSave.perpetual) {
            extraData = {
                ...extraData,
                data7: {
                    label: "administration.restrictions.restrictions.byCalendar",
                    value: "administration.restrictions.perpetual.detail",
                },
            };
        }
        if ((dataToSave.ipsList && dataToSave.ipsList.length > 0) || dataToSave.anyIP) {
            ip = dataToSave.ipsList.join(", ");
            extraData = {
                ...extraData,
                data8: {
                    label: "administration.restrictions.IP",
                    value: dataToSave.anyIP ? i18nUtils.get("administration.restrictions.anyIP.label") : ip,
                },
            };
        } else if (onlyEnvironment) {
            extraData = {
                ...extraData,
                data9: {
                    label: "administration.restrictions.restrictions.byIP",
                    value: dataToSave.disableIP
                        ? i18nUtils.get("administration.restrictions.disableIP.label")
                        : i18nUtils.get("administration.restrictions.enableIP.label"),
                },
            };
        }

        if (!onlyEnvironment) {
            extraData = {
                ...extraData,
                user: {
                    label: "administration.restrictions.name",
                    value: dataToSave.userName,
                },
                document: {
                    label: "administration.restrictions.document",
                    value: dataToSave.documentNumber,
                },
            };
        }

        this.setState({ chargedData: true, ip });
        dispatch(adminActions.setExtraData(extraData));
    }

    renderCalendarAndIPSection(onlyEnvironment, dataToSave) {
        const { chargedData, ip } = this.state;

        const ipValue = dataToSave?.ipsList?.join(", ");

        if (!chargedData || (ipValue && ipValue !== ip)) {
            this.generateExtraData(onlyEnvironment, dataToSave);
        }

        return (
            <>
                {/* calendar Detail */}
                {!dataToSave.calendarToSave && onlyEnvironment && (
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.restrictions.restrictions.byCalendar"
                        data={
                            dataToSave.disableCalendar ? (
                                <p className="data-desc m-0">
                                    {" "}
                                    <I18n id="administration.restrictions.disableCalendar.label" />
                                </p>
                            ) : (
                                <p className="data-desc m-0">
                                    {" "}
                                    <I18n id="administration.restrictions.enableCalendar.label" />
                                </p>
                            )
                        }
                    />
                )}
                {!dataToSave.perpetual && dataToSave.calendarToSave && !dataToSave.disableCalendar && (
                    <>
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="administration.restrictions.timeZone.label"
                            data={<p className="data-desc m-0">{dataToSave.timeZone}</p>}
                        />
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="administration.restrictions.restrictions.days"
                            data={
                                <p className="data-desc m-0">
                                    {dataToSave.days !== 127 && this.renderDays(dataToSave.days)}
                                    {dataToSave.days === 127 && <I18n id="administration.restrictions.allDays.label" />}
                                </p>
                            }
                        />
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="administration.restrictions.timeZone.label"
                            data={
                                <div className="data-desc m-0">
                                    <I18n id="administration.restrictions.from" />
                                    {` ${dateUtils.timeinMinToString(dataToSave.startTime)} `}
                                    <I18n id="administration.restrictions.to" />
                                    {` ${dateUtils.timeinMinToString(dataToSave.endTime)} `}
                                    <I18n id="administration.restrictions.hrs" />
                                </div>
                            }
                        />
                    </>
                )}
                {dataToSave.perpetual && (
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.restrictions.restrictions.byCalendar"
                        data={
                            <p className="data-desc m-0">
                                {" "}
                                <I18n id="administration.restrictions.perpetual.detail" />
                            </p>
                        }
                    />
                )}
                {/* ip Detail */}
                {(dataToSave.ipsList && dataToSave.ipsList.length > 0) || dataToSave.anyIP ? (
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="administration.restrictions.IP"
                        data={
                            <div div className="data-desc m-0">
                                {dataToSave.anyIP && <I18n id="administration.restrictions.anyIP.label" />}{" "}
                                <span>{!dataToSave.anyIP && dataToSave.ipsList.join(", ")}</span>
                            </div>
                        }
                    />
                ) : (
                    <>
                        {onlyEnvironment && (
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label="administration.restrictions.restrictions.byIP"
                                data={
                                    dataToSave.disableIP ? (
                                        <p className="data-desc m-0">
                                            <I18n id="administration.restrictions.disableIP.label" />
                                        </p>
                                    ) : (
                                        <p className="data-desc m-0">
                                            <I18n id="administration.restrictions.enableIP.label" />
                                        </p>
                                    )
                                }
                            />
                        )}
                    </>
                )}
                {!onlyEnvironment && (
                    <>
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="administration.restrictions.name"
                            data={<p className="data-desc m-0">{dataToSave.userName}</p>}
                        />
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label="administration.restrictions.document"
                            data={<p className="data-desc m-0">{dataToSave.documentNumber}</p>}
                        />
                    </>
                )}
            </>
        );
    }

    renderDetail(data) {
        const onlyEnvironment = typeof data.idUser === "undefined";
        return (
            <>
                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="administration.restrictions.restrictions.type"
                    data={
                        onlyEnvironment ? (
                            <I18n id="administration.restrictions.restrictions.environment" />
                        ) : (
                            <I18n id="administration.restrictions.restrictions.user" />
                        )
                    }
                />

                {this.renderCalendarAndIPSection(onlyEnvironment, data)}
            </>
        );
    }

    render() {
        const { transactionData } = this.props;
        if (transactionData.data) {
            return (
                <Container
                    className="container--layout align-items-center flex-grow p-0 m-0"
                    gridClassName="form-content">
                    <Col sm={12} className="px-0">
                        {this.renderDetail(transactionData.data)}
                    </Col>
                </Container>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(RestrictionsManageTicketData);
