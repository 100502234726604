import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { func, arrayOf, shape, bool } from "prop-types";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import Pagination from "pages/_components/pagination/Pagination";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import DebinRecurrency from "pages/debinPayments/_components/recurrentDebin/DebinRecurrency";
import * as configUtils from "util/config";

class RecurrencyList extends Component {
    static propTypes = {
        setSubmitting: func.isRequired,
        dispatch: func.isRequired,
        debinRecurrencies: arrayOf(shape({})).isRequired,
        filterRecurrency: shape({}).isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        isDesktop: false,
    };

    fetchMoreTransactions = (pageNumber) => {
        const { dispatch, filterRecurrency, setSubmitting } = this.props;

        setSubmitting(true);
        dispatch(debinActions.setRecurrencyDebinFilters({ ...filterRecurrency, pageNumber }, setSubmitting));
        dispatch(debinActions.getDebinRecurrencies({ ...filterRecurrency, pageNumber }, setSubmitting));
    };

    render() {
        const { debinRecurrencies, filterRecurrency, isDesktop } = this.props;
        const useApiV3 = configUtils.getBoolean("frontend.show.RecurringDebin.functionalities", false);    
        const debinRecurrenciesLength = debinRecurrencies?.filter((obj) =>
            (useApiV3 ? obj.estado !== "INACTIVA DEFINITIVA" : true),
        )?.length;

        return (
            <Container className="flex-grow">
                <Col className="col col-12 px-0">
                    <div
                        style={{ borderSpacing: "0px 2px", width: "100%" }}
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {debinRecurrenciesLength > 0 ? (
                            <>
                                <DebinRecurrency isDesktop={isDesktop} />
                                <div className="d-flex justify-content-end mb-4">
                                    <Pagination
                                        totalPages={filterRecurrency.paginas_totales}
                                        pageNumber={filterRecurrency.pageNumber}
                                        action={this.fetchMoreTransactions}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="text-center no-more-data mt-4" key="noMoreMovements">
                                <div className="illustration-wrapper">
                                    <Image
                                        src="images/coloredIcons/find-in-page.svg"
                                        className="svg-big-icon"
                                        styles={{ width: "120px", height: "132px" }}
                                    />
                                </div>
                                <p className="text-lead" style={{ opacity: 1, marginTop: "2rem" }}>
                                    <I18n id="global.list.noRecords" />
                                </p>
                            </div>
                        )}
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    debinRecurrencies: debinSelectors.getRecurrenciasDebin(state),
    filterRecurrency: debinSelectors.getFilterRecurrency(state),
});

export default connect(mapStateToProps)(RecurrencyList);
