import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Button from 'pages/_components/Button';
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

import { selectors as desktopSelectors, actions } from "reducers/desktop";

import * as configUtils from "util/config";

function WidgetDrawerContent({
    dispatch,
    availableWidgets,
    layout,
    closeDrawer
}) {
    const idWidgetFunds = "funds";
    const showMvp2Release = configUtils.getBoolean("frontend.show.MVP2.functionalities");

    const [widgetsSelected, setWidgetsSelected] = useState(
        availableWidgets.map(
            widget => ({
                ...widget,
                selected: layout.some( widgetSelected => widgetSelected.id === widget.id) 
            })
        )
    )

    const [widgetsFiltered, setwidgetsFiltered] = useState(widgetsSelected.filter(w => w.selected))

    useEffect(() => {
        setwidgetsFiltered(widgetsSelected.filter(w => w.selected))  
    }, [widgetsSelected])

    return (
        <>
            <div className="widgets__drawer-content">
                <div className="widgets__drawer-content-title">
                    <Image src="images/escritorio.svg" />
                    <I18n id="desktop.widgets.drawer.title" component="h1" />
                </div>
                <div className="widgets__drawer-content-description">
                    <div className="widgets__description">
                        <I18n id="desktop.widgets.drawer.description" />
                    </div>
                    <div className="widgets__selected">
                        <I18n id="desktop.widgets.drawer.selected" />
                        <span className="px-1">{widgetsFiltered.length}</span>
                        <I18n id="desktop.widgets.drawer.selected.number" />
                    </div>
                    {widgetsFiltered.length >= 5 && (
                        <div className="d-flex mb-3">
                            <div className="widgets__warning-icon-container">
                                <Image src="images/warning-yellow-tri.svg" />
                            </div>
                            <div className="widgets__warning-text">
                                <I18n id="desktop.widgets.drawer.warning.text" />
                            </div>
                        </div>
                    )}
                </div>
                {widgetsSelected.map((widget) => {
                    let svgImage;
                    switch (widget.id) {
                        case "accounts":
                            svgImage = "images/cuentas.svg";
                            break;
                        case "pendingTransactions":
                            svgImage = "images/transaccionesPendientes.svg";
                            break;
                        case "founds":
                            svgImage = "images/inversiones.svg";
                            break;
                        case "deposits":
                            svgImage = "images/inversiones.svg";
                            break;
                        case "financing":
                            svgImage = "images/financiaciones.svg";
                            break;
                        case "echeqs":
                            svgImage = "images/cheques.svg";
                            break;
                        case "metrocorp":
                            svgImage = "images/metrocorp.svg";
                            break;
                        default:
                            svgImage = "images/inversiones.svg";
                            break;
                    }
                    return (
                        ((showMvp2Release && widget.id === idWidgetFunds) ||
                            widget.id !== idWidgetFunds) && (
                            <div
                                key={widget.id}
                                className={
                                    !widget.selected ? "widgets__button" : "widgets__button widgets__selected"
                                }>                                
                                    <Button
                                        key={widget.id}
                                        onClick={() => 
                                            setWidgetsSelected(
                                                widgetsSelected.map(
                                                    widgetSelected => widgetSelected.id === widget.id ?
                                                        {
                                                            ...widgetSelected,
                                                            selected: !widgetSelected.selected && widgetsFiltered.length < 5
                                                        } : widgetSelected
                                                )
                                            )
                                        }
                                        label={`list.addWidget.${widget.id}`}
                                        image={svgImage}
                                        imageStyle="px-2"
                                        bsStyle="link"
                                    />
                                    {widget.selected && <Image src="images/check.svg" />}
                                </div>
                            )
                        );
                    })}                
            </div>
            <div className="drawer__btn-confirm">
                <Button
                    bsStyle="primary"
                    label="global.confirm"
                    onClick={() => {
                        const sortedWidgets = widgetsFiltered
                                                .sort((a, b) => b.row - a.row)
                                                .sort((a, b) => (a.column === 0 ? 1 : b.column === 0 ? -1 : 0))
                        const newLayout = sortedWidgets
                                            .map(({selected, ...item }, i) => ({...item, column: 1, row: i}))
                        dispatch(actions.setLayout(newLayout));
                        dispatch(actions.saveLayoutRequest());
                        closeDrawer()
                    }}
                />
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    availableWidgets: desktopSelectors.getAvailableWidgets(state),
    layout: desktopSelectors.getLayout(state),
});

export default connect(mapStateToProps)(WidgetDrawerContent);
