/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { arrayOf, bool, shape, func, string } from "prop-types";

import Button from "pages/_components/Button";
import classNames from "classnames";

class TabletSelectors extends Component {
    static propTypes = {
        possibleOptions: arrayOf(shape({})).isRequired,
        isDesktop: bool.isRequired,
        optionSelected: shape({}).isRequired,
        changeOption: func.isRequired,
        section: string,
        containerClass: string,
        buttonClass: string,
        buttonContainerClass: string,
        imageClassName: string,
        buttonsWrapperClassNames: string,
        centerButtons: bool,
        leftButtons: bool,
        allDisabled: bool,
    };

    static defaultProps = {
        section: "",
        containerClass: "",
        buttonClass: "",
        buttonContainerClass: "",
        imageClassName: "",
        buttonsWrapperClassNames: "",
        centerButtons: false,
        leftButtons: false,
        allDisabled:false,
    };

    render() {
        const {
            isDesktop,
            possibleOptions,
            optionSelected,
            changeOption,
            section,
            containerClass,
            buttonClass,
            buttonContainerClass,
            centerButtons,
            leftButtons,
            imageClassName,
            allDisabled,
            buttonsWrapperClassNames
        } = this.props;

        return (
            <div
                className={classNames("flex-grow align-items-center bg-white", containerClass)}
                rowClassName={section === "MovementsFinancing" && "justify-content-start"}>
                <div
                    className={classNames("d-flex flex-wrap", buttonsWrapperClassNames, {
                        "justify-content-center px-2 gap-1 w-100": !isDesktop,
                        "justify-content-center": (!isDesktop || centerButtons) && !leftButtons,
                        "gap-1": isDesktop && section !== "metrocorp",
                        "justify-content-center gap-2": isDesktop && section === "metrocorp",
                        "w-100": isDesktop && section === "transfer",
                    })}>
                    {possibleOptions.map((option) => (
                        <div className={classNames("tablet-selector-button", buttonContainerClass)}>
                            <Button
                                disabled={allDisabled}
                                className={classNames("btn-regular chip-selector-btn rounded-full", buttonClass)}
                                bsStyle="outline"
                                block={false}
                                imageStyle={`m-0 ${imageClassName}`}
                                image={optionSelected === option.value ? "images/check.svg" : ""}
                                style={{
                                    backgroundColor: optionSelected === option.value ? "#f2f8fd" : "",
                                    borderColor: optionSelected === option.value ? "#0071ce" : "#ccc",
                                    color: optionSelected === option.value ? "#0071ce" : "#666",
                                }}
                                onClick={() => changeOption(option.value)}
                                label={option.label}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default TabletSelectors;
