import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Button } from "react-bootstrap";
import { arrayOf, bool, func, number, shape, string } from "prop-types";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { selectors as loginSelectors } from "reducers/login";
import { getLastLoginDate, getLastLoginPlace } from "util/settings";
import { REGION_USA } from "constants.js";
import { actions as settingsActions } from "reducers/settings";
import { actions as statusActions } from "reducers/status";
import * as configUtils from "util/config";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";
import EnvironmentTag from "pages/_components/EnvironmentTag";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Avatar from "pages/_components/Avatar";
import SettingsOption from "pages/settings/_components/SettingsOption";
import Container from "pages/_components/Container";
import classNames from "classnames";

class Settings extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
        activeRegion: string.isRequired,
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
        user: shape({
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            userFullName: string,
        }),
        activeEnvironment: shape(string).isRequired,
    };

    static defaultProps = {
        user: null,
    };

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        dispatch(settingsActions.cleanEmail());
        dispatch(statusActions.saveLastHref(location));
    }

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    getSettingsData = () => {
        const { user, activeEnvironment, isMobile } = this.props;
        const { defaultAvatarId, previousLoginInfo, userFullName, email } = user;
        const firstEmailLetter = email.charAt(0);
        const textAfterLastLetter = email.substring(email.indexOf("@") - 1, email.length);

        return (
            <>
                <Col
                    xs={12}
                    className={classNames("px-4 d-flex align-items-center min-height-2 ", { "flex-column": isMobile })}>
                    {isMobile ? (
                        <div className="d-flex flex-grow align-items-start">
                            <div className="mr-25 d-flex justify-content-center">
                                <Avatar
                                    size="big"
                                    user={{ userFullName, defaultAvatarId, email }}
                                    className="avatar-cmf-primary"
                                />
                            </div>
                            <div className="d-flex flex-column">
                                <div className="content-data-wrapper mt-3 px-0">
                                    <span className="data-text content-data-strong line-height-1 mb-1">
                                        {userFullName}
                                    </span>
                                    <span className="data-email-light mb-1">
                                        {firstEmailLetter}••••{textAfterLastLetter}
                                    </span>
                                </div>
                                <div className="content-data-wrapper my-1">
                                    <EnvironmentTag
                                        type={activeEnvironment.type}
                                        name={activeEnvironment.name}
                                        className="color-primary"
                                    />
                                </div>
                                <div className="d-block">
                                    <div className="content-data-wrapper content-data-small">
                                        <div className="data-label">
                                            <I18n id="settings.lastLogin.date" />
                                        </div>
                                    </div>
                                    <div className="content-data-wrapper content-data-small">
                                        <span className="data-email-light">
                                            {" "}
                                            {getLastLoginDate(previousLoginInfo[0])} -{" "}
                                            {getLastLoginPlace(previousLoginInfo[0])}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Col className="content-data-wrapper" md={4}>
                            <span className="data-text content-data-strong font-light f-size-1 align-font-fix-04 line-height-1">
                                {userFullName}
                            </span>
                            {isMobile && (
                                <span className="data-email-light">
                                    {firstEmailLetter}••••{textAfterLastLetter}
                                </span>
                            )}
                        </Col>
                    )}
                    {!isMobile && (
                        <Col sm={12} md={4} className="content-data-wrapper mt-1">
                            <span
                                className="content-data-wrapper content-data-small"
                                style={{ display: "inline-block" }}>
                                <span className="data-label f-size-4 mr-2">
                                    <I18n id="settings.mail.label" />
                                </span>
                                <span className="data-email font-light f-size-4">
                                    {firstEmailLetter}••••{textAfterLastLetter}
                                </span>
                            </span>
                        </Col>
                    )}

                    {previousLoginInfo.length > 0 && !isMobile && (
                        <Col sm={12} md={4} className="d-flex justify-content-end flex-wrap content-data-wrapper mt-1">
                            <span
                                className="content-data-wrapper content-data-small"
                                style={{ display: "inline-block" }}>
                                <span className="data-label">
                                    <I18n componentProps={{ className: "f-size-4" }} id="settings.lastLogin.date" />
                                </span>{" "}
                            </span>
                            <span className="content-data-wrapper content-data-small">
                                <span className="data-email f-size-4 font-light ml-2">
                                    {" "}
                                    {getLastLoginDate(previousLoginInfo[0])} - {getLastLoginPlace(previousLoginInfo[0])}
                                </span>
                            </span>
                        </Col>
                    )}
                </Col>
            </>
        );
    };

    renderTitleMobile = () => (
        <div>
            <I18n id="settings.index" component="h1" componentProps={{ className: "m-0" }} />
        </div>
    );

    render() {
        const { isMobile, activeRegion, isDesktop } = this.props;
        const buildNumber = window.BUILD_NUMBER ? `(Build ${window.BUILD_NUMBER})` : "";
        const fingerprintEnabled = configUtils.getBoolean("frontend.login.fingerprint.enabled");

        return (
            <>
                <Notification scopeToShow="settings" />
                <div className="admin-detail-head px-0">
                    <Head
                        backLinkTo="/desktop"
                        headerClassName={!isDesktop && "blue-main-header-mobile blue-main-title-mobile"}
                        centerElement={!isDesktop && this.renderTitleMobile}
                        centerContentClassName={classNames({ "mx-5": !isDesktop })}
                        hideMobileMenu={!isDesktop && true}
                        accessibilityTextId="settings.index"
                    />
                </div>
                <div className="view-morphing">
                    {isDesktop && (
                        <div className="view-title">
                            <h1>{i18n.get("settings.index")}</h1>
                        </div>
                    )}
                    {isDesktop && (
                        <Container className="container--layaut flex-grow align-items-center container-white py-4 my-45">
                            {this.getSettingsData()}
                        </Container>
                    )}
                </div>
                {isMobile && (
                    <div className="view-morphing">
                        <section className="container--layout align-items-center section-content-heading container-pearl pt-0 mb-4">
                            <div className="d-flex w-100 justify-content-center p-0">{this.getSettingsData()}</div>
                        </section>
                    </div>
                )}

                <div className="mt-2">
                    <MainContainer>
                        <div className="above-the-fold" ref={this.containerRef}>
                            <Container
                                className={classNames("flex-grow align-items-center", {
                                    "container-white pt-3": isMobile,
                                })}
                                gridClassName="form-content">
                                <Col md={4} sm={12} className={classNames({ "pl-0": isDesktop })}>
                                    <I18n
                                        component="h4"
                                        componentProps={{
                                            className: "navigational-list-title f-size-1 line-height-1 mt-0 pb-1",
                                        }}
                                        id="settings.title.personal"
                                    />

                                    <ul className="navigational-list">
                                        <SettingsOption
                                            linkTo="/settings/changeEmail"
                                            messageKey="settings.options.changeEmail"
                                            isMobile={isMobile}
                                        />
                                        <SettingsOption
                                            linkTo="/settings/changePhone"
                                            messageKey="settings.options.changePhone"
                                            isMobile={isMobile}
                                        />
                                    </ul>
                                </Col>
                                <Col md={4} sm={12}>
                                    <I18n
                                        component="h4"
                                        componentProps={{
                                            className: "navigational-list-title f-size-1 line-height-1 mt-0 pb-1",
                                        }}
                                        id="settings.title.security"
                                    />

                                    <ul className="navigational-list">
                                        <SettingsOption
                                            linkTo="/settings/changePassword"
                                            messageKey="settings.options.changePassword"
                                            isMobile={isMobile}
                                        />
                                        <SettingsOption
                                            linkTo="/settings/changeUserpass"
                                            messageKey="settings.options.changeUserpass"
                                            isMobile={isMobile}
                                        />
                                        <SettingsOption
                                            linkTo="/settings/changeSecuritySeal"
                                            messageKey="settings.options.changeSecuritySeal"
                                            isMobile={isMobile}
                                        />
                                        <SettingsOption
                                            linkTo="/settings/myDevices"
                                            messageKey="settings.myDevices"
                                            isMobile={isMobile}
                                        />
                                        {fingerprintEnabled ? (
                                            <SettingsOption
                                                linkTo="/settings/fingerprintConfiguration"
                                                messageKey="settings.biometrics"
                                                isMobile={isMobile}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </ul>
                                </Col>
                                <Col md={4} sm={12} className={classNames({ "pr-0": isDesktop })}>
                                    <I18n
                                        component="h4"
                                        componentProps={{
                                            className: "navigational-list-title f-size-1 line-height-1 mt-0 pb-1",
                                        }}
                                        id="settings.title.otherConfigurations"
                                    />

                                    <ul className="navigational-list">
                                        <SettingsOption
                                            linkTo="/settings/notificationsConfiguration"
                                            messageKey="settings.notificationsConfiguration"
                                            isMobile={isMobile}
                                        />
                                        <SettingsOption
                                            linkTo="/settings/setDefaultEnvironment"
                                            messageKey="settings.options.setDefaultEnvironment"
                                            isMobile={isMobile}
                                        />
                                    </ul>
                                </Col>

                                {activeRegion === REGION_USA && (
                                    <Col md={6} sm={12}>
                                        <h4 className="navigational-list-title pl-2">
                                            <I18n id="settings.title.documents" />
                                        </h4>
                                        <ul className="navigational-list">
                                            <SettingsOption
                                                linkTo="/settings/esign/documents"
                                                messageKey="settings.options.documents.termsAndConditions"
                                                isMobile={isMobile}
                                            />
                                        </ul>
                                    </Col>
                                )}
                            </Container>
                            {isMobile && (
                                <Container
                                    className="container--layout flex-grow align-items-center"
                                    gridClassName="form-content">
                                    <Col className="col col-12">
                                        <div className="flex-container">
                                            <small className="text-muted">
                                                {/* global process */}
                                                <I18n id="global.version" /> {process.env.REACT_APP_VERSION}{" "}
                                                {buildNumber}
                                            </small>
                                            <Button
                                                className="btn-link"
                                                onClick={this.logOut}
                                                style={{ marginRight: "-1em" }}>
                                                <Image src="images/cerrarSesion.svg" className="svg-icon svg-caret" />
                                                <span>
                                                    <I18n id="global.logout" />
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Container>
                            )}
                        </div>
                    </MainContainer>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    environments: sessionSelectors.getEnvironments(state),
    user: sessionSelectors.getUser(state),
    activeRegion: loginSelectors.getRegion(state),
});

export default connect(mapStateToProps)(Settings);
