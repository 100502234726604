/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, number, string, shape } from "prop-types";

import { Link } from "react-router-dom";

import * as i18nUtils from "util/i18n";

import FormattedDate from "pages/_components/FormattedDate";

import { resizableRoute } from "pages/_components/Resizable";
import InfoTag from "pages/_components/InfoTag";
import { findScheduleDesc } from "util/scheduler";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class ScheduledTransactionsListItem extends Component {
    static propTypes = {
        activityName: string.isRequired,
        idTransaction: string.isRequired,
        isDesktop: bool,
        isEditable: bool.isRequired,
        creationDateTime: string.isRequired,
        currency: string,
        quantity: number,
        idTransactionStatus: string.isRequired,
        data: shape().isRequired,
    };

    static defaultProps = {
        isDesktop: true,
        quantity: null,
        currency: null,
    };

    renderRightContent = (data) => {
        const currency = data?.amount?.currency ? i18nUtils.get(`currency.label.${data.amount.currency}`) : "";
        const amount = data?.amount?.quantity ? data.amount.quantity : data?.amount;
        return (
            <div className="card__component-item-currency d-flex align-items-center">
                <span className="pr-1">{currency}</span>
                <span>
                    {amount &&
                        amount?.toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                </span>
            </div>
        );
    };

    render() {
        const {
            activityName,
            idTransaction,
            idTransactionStatus,
            isDesktop,
            creationDateTime,
            data,
            isEditable,
        } = this.props;

        return (
            <CardContainer
                renderAs={Link}
                to={!isEditable ? `/transaction/${idTransaction}` : "/desktop"}
                className="widget__card-transactions">
                <Card
                    icon={`images/transfer-${idTransactionStatus}.svg`}
                    iconClass="card__pending-transaction-icon"
                    rightArrow
                    title={
                        <div className="card__pending-transaction-data-title">
                            <div className="d-flex align-items-center">
                                <span className="card__pending-transaction-data-creation">
                                    {i18nUtils.get("transactions.list.item.created")}
                                </span>
                                <FormattedDate showTime date={creationDateTime} />
                                {isDesktop && (
                                    <InfoTag
                                        tagBackground="#757575"
                                        message={findScheduleDesc(data?.scheduler?.selectedOption)}
                                    />
                                )}
                            </div>
                        </div>
                    }
                    bottom={
                        <div className="card__pending-transaction-data-bottom">
                            <span>{activityName}</span>
                            {!isDesktop && (
                                <InfoTag
                                    tagBackground="#757575"
                                    message={findScheduleDesc(data?.scheduler?.selectedOption)}
                                />
                            )}
                        </div>
                    }
                    rightContent={this.renderRightContent(data)}
                />
            </CardContainer>
        );
    }
}

export default resizableRoute(ScheduledTransactionsListItem);
