/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { selectors as sessionSelectors } from "reducers/session";
import { idTransactionFormat } from "util/format";

import I18n from "pages/_components/I18n";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class AdminUserSignatureDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                submitDateTimeAsString,
                data: { userFullName, schemeName, hasSignatureEnabled, signatureLevel },
            } = object.transaction;

            return (
                <CardContainer className="confirmation__cards-container">
                    <Card
                        className="confirmation__cards"
                        icon={isDesktop && "images/transfer-PENDING.svg"}
                        iconClass="card__pending-transaction-icon"
                        hrClass="d-none"
                        title={
                            isDesktop
                                ? submitDateTimeAsString && (
                                      <div className="confirmation-cards__title">
                                          <div className="confirmation-cards__title-data">
                                              <I18n id="forms.transaction.ticket.date" />
                                          </div>
                                          <div className="confirmation-cards__title-info">
                                              <span>{submitDateTimeAsString}</span>
                                          </div>
                                      </div>
                                  )
                                : userFullName && (
                                      <div className="confirmation-cards__title with-subtitle">
                                          <div className="d-flex align-items-center">
                                              <div className="confirmation-cards__title-data">
                                                  <I18n id="transaction.ticket.user" />
                                              </div>
                                              <div className="confirmation-cards__title-info">
                                                  <span>{userFullName}</span>
                                              </div>
                                          </div>
                                          <span className="confirmation-cards__subtitle">
                                              {this.renderSignatureLevel(
                                                  schemeName,
                                                  hasSignatureEnabled,
                                                  signatureLevel,
                                              )}
                                          </span>
                                      </div>
                                  )
                        }
                        content={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    {userFullName && (
                                        <div
                                            className={
                                                this.renderSignatureLevel(
                                                    schemeName,
                                                    hasSignatureEnabled,
                                                    signatureLevel,
                                                )
                                                    ? "confirmation-cards__content pb-1"
                                                    : "confirmation-cards__content"
                                            }>
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="administration.users.invite.firstName.label" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{userFullName}</span>
                                            </div>
                                        </div>
                                    )}
                                    {this.renderSignatureLevel(schemeName, hasSignatureEnabled, signatureLevel) && (
                                        <div className="confirmation-cards__content info-type-a">
                                            <span>
                                                {this.renderSignatureLevel(
                                                    schemeName,
                                                    hasSignatureEnabled,
                                                    signatureLevel,
                                                )}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {submitDateTimeAsString && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="forms.transaction.ticket.date" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{submitDateTimeAsString}</span>
                                            </div>
                                        </div>
                                    )}
                                    {submitDateTimeAsString && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="forms.transaction.ticket.number" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        bottom={
                            isDesktop && (
                                <div className="d-flex align-items-center">
                                    {idTransactionFormat(idTransaction) && (
                                        <div className="confirmation-cards__bottom">
                                            <div className="confirmation-cards__bottom-data">
                                                <I18n id="forms.transaction.ticket.number" />
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    />
                </CardContainer>
            );
        });
    };

    renderSignatureLevel = (schemeName, hasSignatureEnabled, signatureLevel) => {
        const { activeEnvironment } = this.props;

        if (schemeName === "medium") {
            return <I18n id={`administration.signature.ticket.change.${!hasSignatureEnabled}`} />;
        }
        if (signatureLevel && activeEnvironment.administrationScheme === "advanced") {
            return <I18n id="administration.signature.ticket.configure" SIGNATURE_LEVEL={signatureLevel} />;
        }
        if (signatureLevel && activeEnvironment.administrationScheme === "medium") {
            return <I18n id="administration.signature.ticket.change.false" />;
        }
        return <I18n id="administration.signature.ticket.change.true" />;
    };

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(AdminUserSignatureDataTable);
