import React, { Component } from "react";
import { string, bool } from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";
import classNames from "classnames";

class FormattedDate extends Component {
    static propTypes = {
        date: string.isRequired,
        showTime: bool.isRequired,
        className: string,
        ml: bool,
        small: bool,
        timeWithoutSeconds: bool,
        withSpan: bool,
    };

    static defaultProps = {
        className: "",
        timeWithoutSeconds: false,
        ml: false,
        small: false,
        withSpan: false,
    };

    formatTime = (date) => {
        const { timeWithoutSeconds, ml, small, className } = this.props;

        const dateToParse = moment(date).format();
        const newDate = new Date(dateToParse).toLocaleTimeString("en-GB", {
            timeZone: "America/Argentina/Buenos_Aires",
        });

        const cName = classNames({ "ml-2 my-0": ml }, { "data-desc small-font": small }, className);

        return <span className={cName}>{!timeWithoutSeconds ? newDate : newDate.slice(0, newDate.length - 3)}</span>;
    };

    formatDate = (date) => {
        const { ml, small, className } = this.props;

        if (date) {
            const dateToParse = moment(date).format();
            const newDate = new Date(dateToParse).toLocaleString("en-GB", {
                timeZone: "America/Argentina/Buenos_Aires",
            });
            const cName = classNames({ "ml-2 my-0": ml }, { "data-desc small-font": small }, className);

            return <span className={cName}>{newDate.slice(0, newDate.length - 10)}</span>;
        }
        return <></>;
    };

    render() {
        const { date, showTime, withSpan } = this.props;

        const Tag = withSpan ? "span" : "div";
        return (
            <Tag>
                {this.formatDate(date)}
                {showTime && <> {this.formatTime(date)}</>}
            </Tag>
        );
    }
}

export default connect()(FormattedDate);
