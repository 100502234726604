import React, { Component } from "react";
import { connect } from "react-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { adhesionShape } from "util/servicePayments";
import AdhesionContextMenu from "pages/servicePayments/_components/AdhesionContextMenu";

import { bool, string } from "prop-types";

import Heading from "pages/_components/Heading";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";

class AdhesionesItem extends Component {
    static propTypes = {
        adhesion: adhesionShape.isRequired,
        isDesktop: bool,
        tableLabel: string,
    };

    static defaultProps = {
        isDesktop: false,
        tableLabel: "",
    };

    render() {
        const { adhesion, isDesktop, tableLabel } = this.props;
        const {
            enteDescription,
            category: { description },
            userLp,
            manejaBaseDeuda,
            reference,
        } = adhesion;

        if (isDesktop) {
            return (
                <>
                    <div className="table-data">
                        <span className="data-aux">{enteDescription}</span>
                    </div>
                    <div className="table-data" style={{ textAlign: "center" }}>
                        <span className="data-aux">{description}</span>
                    </div>
                    <div className="table-data" style={{ textAlign: "center " }}>
                        <span className="data-aux">{reference}</span>
                    </div>
                    <div className="table-data" style={{ textAlign: "center " }}>
                        <span className="data-aux">{userLp}</span>
                    </div>
                    <div className="table-data height-64">
                        {manejaBaseDeuda && (
                            <button
                                type="button"
                                className="btn btn-link"
                                style={{ padding: 0, margin: 0 }}
                                onClick={(e) => e.stopPropagation()}>
                                <AdhesionContextMenu adhesion={adhesion} isDesktop={isDesktop} />
                            </button>
                        )}
                    </div>
                </>
            );
        }

        // Acá arranca mobile

        return (
            <>
                <Container className="flex-grow container-white container-white-mobile adhesion-item-mobile text-uppercase py-1">
                    <Col className="px-0" xs={11}>
                        <Col md={3} xl={3} lg={3} sm={12} className="pr-0 pr-md-3">
                            <Heading.DataGroup
                                dataClassName="data-desc small-font text-right w-50"
                                containerClassName={`transfer-data data-wrapper-flex mb-0 ${isDesktop &&
                                    "my-2"} ${!isDesktop && "space-between"}`}
                                label={`${tableLabel}.entity`}
                                data={enteDescription}
                            />
                        </Col>
                        <Col md={3} xl={3} lg={3} sm={12} className="pr-0 pr-md-3">
                            <Heading.DataGroup
                                dataClassName="data-desc small-font"
                                containerClassName={`transfer-data data-wrapper-flex mb-0 ${isDesktop &&
                                    "my-2"} ${!isDesktop && "space-between"}`}
                                label={`${tableLabel}.category`}
                                data={description}
                            />
                        </Col>
                        <Col md={3} xl={3} lg={3} sm={12} className="pr-0 pr-md-3">
                            <Heading.DataGroup
                                dataClassName="data-desc small-font"
                                containerClassName={`transfer-data data-wrapper-flex mb-0 ${isDesktop &&
                                    "my-2"} ${!isDesktop && "space-between"}`}
                                label={`${tableLabel}.reference`}
                                data={reference}
                            />
                        </Col>
                        <Col md={3} xl={3} lg={3} sm={12} className="pr-0 pr-md-3">
                            <Heading.DataGroup
                                dataClassName="data-desc small-font"
                                containerClassName={`transfer-data data-wrapper-flex mb-0 ${isDesktop &&
                                    "my-2"} ${!isDesktop && "space-between"}`}
                                label={`${tableLabel}.code`}
                                data={userLp}
                            />
                        </Col>
                    </Col>
                    <Col xs={1} className="px-0 py-1 pr-0">
                        {manejaBaseDeuda && <AdhesionContextMenu adhesion={adhesion} isDesktop={isDesktop} />}
                    </Col>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(AdhesionesItem);
