// eslint-disable-next-line import/prefer-default-export
export const calculateTotalAmountCbuList = (cbuList) =>
    cbuList.reduce(
        (newElem, elem) => ({
            currency: elem.amount.currency,
            quantity: newElem.quantity + elem.amount.quantity,
        }),
        {
            currency: null,
            quantity: 0,
        },
    );
