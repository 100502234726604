import { call, put, takeLatest } from "redux-saga/effects";
import * as file from "middleware/file";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";

import { types } from "reducers/massPayments";
import { download, downloadXls } from "util/download";

const sagas = [takeLatest([types.DOWNLOAD_LINES_REQUEST], downloadLines)];

export default sagas;

function* downloadLines({ idTransaction, filters, format }) {
    const { type, data } = yield call(file.downloadLines, idTransaction, filters, format);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_LINES_FAILURE });

        // TODO: Change environment of notification
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["account/details"]),
        );
    } else {
        const { content, fileName } = data.data;

        if (format === "txt") {
            download(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_LINES_SUCCESS });
    }
}
