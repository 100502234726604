import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { Field, Formik } from "formik";
import { array, func, bool } from "prop-types";

import * as i18nUtils from "util/i18n";

import SelectorInput from "pages/_components/fields/formik/SelectorInput";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";

const FORM_ID = "forms.bankselector";

class BankSearchForm extends Component {
    static propTypes = {
        codes: array,
        countries: array,
        loadListRequest: func.isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        codes: [],
        countries: [],
    };

    handleChange = ({ type, code, setTouched, setValues, touched, values }) => {
        setTouched({ ...touched, select: true });
        setValues({ ...values, select: { type: type.value || values.select.type, code } });
    };

    validateSearch = ({ select, bank }) => {
        const { type, code } = select;
        const errors = {};

        if (!type) {
            errors.select = i18nUtils.get("forms.bankselector.typeRequired");
        }

        if (bank && bank.length < 3) {
            errors.bank = i18nUtils.get("forms.bankselector.nameToShort");
        }

        if (!errors.type && !bank && !code) {
            errors.select = i18nUtils.get("forms.bankselector.codeOrNameNeeded");
            errors.bank = i18nUtils.get("forms.bankselector.codeOrNameNeeded");
        }

        return errors;
    };

    handleSubmit = ({ bank, country, select }, { setSubmitting }) => {
        const { loadListRequest, match, isDesktop } = this.props;
        const navigationAction = isDesktop ? "replace" : "push";

        loadListRequest({ name: bank, country, ...select }, setSubmitting, match.url, navigationAction);
    };

    renderForm = ({ values, touched, errors, isSubmitting, handleSubmit, ...rest }) => {
        const { codes, countries } = this.props;

        return (
            <Fragment>
                <Container className="container--layout align-items-center flex-grow">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        <Field
                            component={SelectorInput}
                            name="select"
                            selectProps={{
                                name: "type",
                                value: values.select.type,
                                options: codes.slice(1, codes.length).map(({ id, label }) => ({
                                    value: id,
                                    label,
                                })),
                            }}
                            label={i18nUtils.get("forms.bankselector.bankCode")}
                            inputProps={{ name: "code", value: values.select.code }}
                            onChange={(type, code) => this.handleChange({ type, code, values, touched, ...rest })}
                        />
                        <Field idForm={FORM_ID} name="bank" component={TextField} />
                        <Field
                            idForm={FORM_ID}
                            name="country"
                            component={Selector}
                            options={countries.slice(1, countries.length).map(({ id, label }) => ({
                                value: id,
                                label,
                            }))}
                        />
                    </Col>
                </Container>
                <Container className="container--layout align-items-center">
                    <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                        <Button
                            bsStyle="primary"
                            label="forms.bankselector.search"
                            loading={isSubmitting}
                            onClick={handleSubmit}
                        />
                    </Col>
                </Container>
            </Fragment>
        );
    };

    render() {
        return (
            <Formik
                initialValues={{
                    select: { type: "", code: "" },
                    bank: "",
                    country: "",
                }}
                validate={this.validateSearch}
                onSubmit={this.handleSubmit}>
                {this.renderForm}
            </Formik>
        );
    }
}

export default BankSearchForm;
