import React, { Component, Fragment } from "react";
import { func, shape, bool, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { replace } from "react-router-redux";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import ProductDetail from "pages/_components/ProductDetail";
import Movements from "pages/accounts/Movements";

import * as i18n from "util/i18n";
import FormattedDate from "pages/_components/FormattedDate";

const filtersWithInfo = ["period", "amount", "reference", "channels", "check"];

class MobileFiltersResults extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        filters: shape({}).isRequired,
        account: shape({}).isRequired,
        moreMovements: bool.isRequired,
        movements: arrayOf(shape({})).isRequired,
    };

    componentDidMount() {
        const { dispatch, filters, ...rest } = this.props;

        dispatch(accountsActions.details(rest.match.params.id, filters));
    }

    handleBack = () => {
        const { dispatch, account } = this.props;
        dispatch(replace(`/accounts/${account.idProduct}`));
    };

    handleClose = () => {
        const { dispatch, account } = this.props;
        dispatch(accountsActions.closeOptions());
        dispatch(replace(`/accounts/${account.idProduct}`));
    };

    renderHeadInfo = () => {
        const {
            filters: { channels, check, dateFrom, dateTo, filter, minAmount, maxAmount, reference },
        } = this.props;

        const results = {
            period: (dateFrom || dateTo) && (
                <Fragment>
                    {dateFrom && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="accounts.movements.filters.period.dateFrom.label" />
                            </div>
                            <div className="data-date">
                                <FormattedDate date={dateFrom} />
                            </div>
                        </div>
                    )}
                    {dateTo && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="accounts.movements.filters.period.dateTo.label" />
                            </div>
                            <div className="data-date">
                                <FormattedDate date={dateTo} />
                            </div>
                        </div>
                    )}
                </Fragment>
            ),
            amount: (minAmount || maxAmount) && (
                <Fragment>
                    {minAmount && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="accounts.movements.filters.amount.minAmount.label" />
                            </div>
                            <div className="data-amount">{` ${minAmount}`}</div>
                        </div>
                    )}
                    {maxAmount && (
                        <div className="data-wrapper data-wrapper-inline">
                            <div className="data-label">
                                <I18n id="accounts.movements.filters.amount.maxAmount.label" />
                            </div>
                            <div className="data-amount">{` ${maxAmount}`}</div>
                        </div>
                    )}
                </Fragment>
            ),
            reference: reference && (
                <div className="data-wrapper data-wrapper-inline">
                    <div className="data-label">
                        <I18n id="accounts.movements.filters.reference.reference.label" />
                    </div>
                    <div className="data-desc">{` ${reference}`}</div>
                </div>
            ),
            channels: channels && (
                <div className="data-wrapper data-wrapper-inline">
                    <div className="data-label">
                        <I18n id="accounts.movements.filters.channels.channels.label" />
                    </div>
                    <div className="data-text">{` ${channels}`}</div>
                </div>
            ),
            check: check && (
                <div className="data-wrapper data-wrapper-inline">
                    <div className="data-label">
                        <I18n id="accounts.movements.filters.check.check.label" />
                    </div>
                    <div className="data-text">{` ${check}`}</div>
                </div>
            ),
        };

        return <div style={{ minWidth: "100%" }}>{results[filter]}</div>;
    };

    render() {
        const {
            account,
            filters: { dateFrom, filter },
            moreMovements,
            movements,
        } = this.props;

        return (
            <Fragment>
                <Head
                    onBack={this.handleBack}
                    onClose={this.handleClose}
                    title={`accounts.movements.filters.${
                        !filtersWithInfo.includes(filter) ? filter : `searchBy.${filter}`
                    }`}
                    replace={{
                        SECOND_LAST_MONTH:
                            filter === "secondLastMonth" &&
                            `${
                                i18n.getArray("global.months")[dateFrom.getMonth()]
                            } ${dateFrom.getFullYear().toString()}`,
                    }}>
                    {this.renderHeadInfo()}
                </Head>
                <MainContainer>
                    <div className="above-the-fold">
                        <ProductDetail>
                            <ProductDetail.Body>
                                <Movements
                                    accountId={account.idProduct}
                                    accountCurrency={account.currency}
                                    isDesktop={false}
                                    keyLabel="accounts.movements"
                                    moreMovements={moreMovements}
                                    movements={movements}
                                />
                            </ProductDetail.Body>
                        </ProductDetail>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    account: accountsSelectors.getSelectedAccount(state),
    filters: accountsSelectors.getMobileFilter(state),
    moreMovements: accountsSelectors.getMoreMovements(state),
    movements: accountsSelectors.getMovements(state),
});

export default connect(mapStateToProps)(MobileFiltersResults);
