import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { groupsSelectors } from "reducers/administration";
import { groupsActions } from "reducers/administration/advanced";

import GroupsOfUser from "pages/administration/_components/GroupsOfUser";

const mapStateToProps = (state) => ({
    fetching: groupsSelectors.isFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(groupsActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GroupsOfUser);
