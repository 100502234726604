/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import classNames from "classnames";
import { string, func, bool, oneOfType, node, arrayOf } from "prop-types";

import I18n from "pages/_components/I18n";
import FieldError from "pages/_components/fields/FieldError";
import Image from "pages/_components/Image";

export class Checkbox extends Component {
    static propTypes = {
        name: string.isRequired,
        onChange: func,
        onBlur: func,
        error: string,
        label: oneOfType([string, node]),
        hideLabel: bool,
        idForm: string,
        checked: bool,
        readOnly: bool,
        block: bool,
        formGroup: bool,
        value: oneOfType([bool, arrayOf(string)]).isRequired,
        formGroupClassName: string,
        formGroupTextClassName: string,
        disabledCheck: bool,
    };

    static defaultProps = {
        error: "",
        label: "",
        hideLabel: false,
        idForm: "",
        checked: false,
        readOnly: false,
        block: true,
        formGroup: false,
        onChange: null,
        onBlur: null,
        formGroupClassName: "",
        formGroupTextClassName: "",
        disabledCheck: false,
    };

    render() {
        const {
            idForm,
            error,
            hideLabel,
            label,
            block,
            formGroup,
            readOnly,
            formGroupClassName,
            formGroupTextClassName,
            disabledCheck,
            ...props
        } = this.props;

        return (
            <div
                className={classNames("c-control c-control--has-icon c-control--checkbox", formGroupClassName, {
                    "has-error": error,
                    "c-control-block": block,
                    "form-group": formGroup,
                })}>
                <input
                    id={props.name}
                    tabIndex={props.tabIndex}
                    className="c-control-input"
                    type="checkbox"
                    autoComplete="off"
                    {...props}
                    readOnly={readOnly}
                    disabled={disabledCheck}
                />

                <label className="c-control-label pl-md-45" htmlFor={props.name}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="images/check.svg" className="svg-icon svg-caret checkbox" />
                        </div>
                    </div>

                    {!hideLabel && (
                        <div className={classNames("form-group-text", formGroupTextClassName)}>
                            {label || <I18n id={`${idForm}.${props.name}.label`} />}
                        </div>
                    )}
                </label>
                {error && <FieldError error={error} />}
            </div>
        );
    }
}

export default Checkbox;
