import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, func, bool } from "prop-types";
import classNames from "classnames";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Row from "react-bootstrap/lib/Row";
import Container from "pages/_components/Container";
import FormattedDate from "pages/_components/FormattedDate";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import { selectors as transactionsSelectors, actions as transactionsActions } from "reducers/transactions";
import * as i18nUtils from "util/i18n";
import { push } from "react-router-redux";

class ConfirmationOpeningAccount extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(
            transactionsActions.setMultipleSignaturesData({
                activitySelected: "",
                selectedRows: [],
                selectedTransactionRows: [],
            }),
        );
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1>{i18nUtils.get("pendingDispatch.list.title")}</h1>
        </div>
    );

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/pendingTransaction/list"));
    };

    render() {
        const { multipleSignaturesData, dispatch, isDesktop } = this.props;

        return (
            <>
                <Notification scopeToShow="multipleSignaturesProcessing" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={!isDesktop && this.handleBack}
                        uniqueDownload
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        isFetchingExport={false}
                        hasInlineButtons
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        emptyRightOption
                    />
                </div>
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="flex-grow align-items-center">
                            <Col className="col col-xl-6 ">
                                <Container className="flex-grow align-items-center">
                                    <Col className="col col-xl-10 cmf-container-white">
                                        <div className="admin-content-center" style={{ margin: "2rem 0" }}>
                                            <Image
                                                src="images/processing.svg"
                                                styles={{ width: "102.5px", height: "102.5px" }}
                                            />
                                        </div>
                                        <div className="admin-content-center" style={{ "font-size": "19px" }}>
                                            <I18n id="multiple.signatures.processing" />
                                        </div>
                                        <div style={{ margin: "2rem" }}>
                                            <Row style={{ justifyContent: "space-between" }}>
                                                <FieldLabel labelKey="account.new.account.date" />
                                                <p>
                                                    <FormattedDate date={new Date()} dateFormat="dd/MM/yyyy" showTime />
                                                </p>
                                            </Row>
                                            <Row style={{ justifyContent: "space-between" }}>
                                                <FieldLabel labelKey="multiple.signatures.count" />
                                                <p>{multipleSignaturesData?.selectedRows?.length}</p>
                                            </Row>
                                            <Row style={{ justifyContent: "space-between" }}>
                                                <p>{i18nUtils.get("multiple.signatures.process.explanation")}</p>
                                            </Row>
                                        </div>
                                    </Col>
                                </Container>
                            </Col>
                        </Container>
                        <Container className="flex-grow align-items-center">
                            <Col className="col col-xl-6">
                                <Container className="flex-grow align-items-center">
                                    <Col
                                        className={classNames("col col-xl-12 col-no-pad-mobile mt-2", {
                                            "d-flex align-items-center": isDesktop,
                                        })}>
                                        <Button
                                            type="button"
                                            bsStyle="outline"
                                            onClick={() => {
                                                dispatch(transactionsActions.initPendings());
                                                dispatch(push("/pendingTransaction/list"));
                                            }}
                                            label="new.check.left.buttom"
                                        />
                                        <Button
                                            type="button"
                                            bsStyle="primary"
                                            onClick={() => dispatch(push("/desktop"))}
                                            label="new.check.right.buttom"
                                        />
                                    </Col>
                                </Container>
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(ConfirmationOpeningAccount);
