import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Form, withFormik, Field } from "formik";
import Yup from "yup";
import { bool, func, shape, string } from "prop-types";
import { Col } from "react-bootstrap";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { actions as settingsActions } from "reducers/settings";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
// import OnboardingStepper, { orientations } from "pages/onboarding/_components/OnboardingStepper";
import { Mixpanel } from "util/clickstreaming";
import { selectors as i18nSelectors } from "reducers/i18n";
import Checkbox from "pages/_components/Checkbox";
import PageLoading from "pages/_components/PageLoading";
import Image from "pages/_components/Image";
import Notification from "pages/_components/Notification";
import EnrollmentStepper from "./_components/EnrollmentStepper";
import TermsAndConditionsModal from "./_components/TermsAndConditionsModal";
import AssociateStepperSimple from "./_components/AssociateStepperSimple";

const FORM_ID = "enrollment.index";

class Index extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        dispatch: func.isRequired,
        documentData: shape({}).isRequired,
        isMobile: bool.isRequired,
        activeLanguage: string.isRequired,
        match: shape.isRequired,
        email: string.isRequired,
        invitationCode: string.isRequired,
        fetching: bool.isRequired,
        values: shape({}).isRequired,
        termsConditions: String.isRequired,
    };

    state = {
        isModalDisplayed: false,
    };

    handleDismiss = () => {
        this.setState({ isModalDisplayed: false });
    };

    handleDownload = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.downloadDocumentRequest({ format: "pdf" }));
    };

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    handleHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    parse = (email) => {
        if (email) {
            const arr = email.split("@");
            const startMail = arr[0];
            const endMail = arr[1];

            const startMailLength = startMail.length;

            const startBeforeMask = startMailLength > 4 ? `${startMail[0]}` : `${startMail[0]}${startMail[1]}`;

            const minMaskLength = 3;
            const mask = "*".repeat(startMailLength - 3 > minMaskLength ? startMailLength - 3 : minMaskLength);

            const startAfterMask = `${startMailLength > 5 ? startMail[startMailLength - 1] : ""}`;

            return `${startBeforeMask}${mask}${startAfterMask}@${endMail}`;
        }
        return email;
    };

    componentDidMount = () => {
        const { match, dispatch, activeLanguage } = this.props;
        const linkLang = match.params.lang;
        if (linkLang !== undefined && linkLang !== null && linkLang !== activeLanguage) {
            dispatch(settingsActions.changeLanguage(linkLang));
        }
        dispatch(enrollmentActions.verifyInvitationCodePre(match.params.code));
    };

    renderTitleMobile = () => <Image src="images/logoMobileHeader.svg" className="enrolment-logo-mobile" />;

    render() {
        const {
            isDesktop,
            isMobile,
            isSubmitting,
            documentData,
            email,
            invitationCode,
            fetching,
            values,
            termsConditions,
            match,
        } = this.props;
        const { isModalDisplayed } = this.state;
        const isCredentialWhitening = match.path === "/credentialWhitening/code/:code/:lang/:reg?";
        const isDigitalEnrollmentEnabled = configUtils.getBoolean("enrollment.digital.automatic");
        const step = 1;
        if (isCredentialWhitening) {
            values.acceptTerms = isCredentialWhitening;
        }
        // const orientation = isDesktop ? orientations.vertical : orientations.horizontal;
        return (
            <PageLoading loading={fetching}>
                <Notification scopeToShow="enrollment" />
                <Head
                    onBack={!isDesktop ? this.handleHeaderBack : null}
                    onClose={!isDesktop ? this.handleHeaderClose : null}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                    centerElement={isDesktop ? undefined : this.renderTitleMobile}
                />
                <TermsAndConditionsModal
                    handleDownload={this.handleDownload}
                    isDisplayed={isModalDisplayed}
                    handleDismiss={this.handleDismiss}
                    termsConditions={termsConditions}
                    enrrollment
                    isDesktop={isDesktop}
                />
                <div className="view-page">
                    {!(isMobile || documentData.firstName) && (
                        <EnrollmentStepper
                            currentStep={step}
                            credentialWhitening={isCredentialWhitening}
                            className="onboarding-steps"
                        />
                    )}

                    <div className="view-content">
                        <main className="main-container">
                            <Form className="above-the-fold">
                                {isMobile /* && documentData.firstName */ && (
                                    <>
                                        <Container className="container--layout align-items-center">
                                            <Col className="col col-12">
                                                <AssociateStepperSimple currentStep={1} />
                                            </Col>
                                        </Container>
                                    </>
                                )}
                                <Container
                                    className="container--layout align-items-center"
                                    gridClassName="form-content">
                                    <Col sm={12} md={5} lg={4} className="col col-12 enrollment-index">
                                        <Col sm={12} md={12} lg={12} className="col col-12">
                                            <I18n
                                                component="h1"
                                                componentProps={{
                                                    className: "title-content",
                                                }}
                                                id={`${FORM_ID}.header`}
                                            />
                                        </Col>
                                        {email && (
                                            <Col sm={12} md={12} lg={12} className="col col-12">
                                                <I18n
                                                    component="p"
                                                    id="enrollment.index.invitationCode.email.label"
                                                    EMAIL={this.parse(email)}
                                                />
                                            </Col>
                                        )}
                                        <Col sm={12} md={12} lg={12} className="col col-12 invitation-code light-font">
                                            <I18n
                                                componentProps={{ className: "control-label" }}
                                                id="enrollment.index.invitationCode.label"
                                            />
                                            <div style={{ color: "#707070" }}>{invitationCode}</div>
                                        </Col>
                                        <Col
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            className="col col-12 on-error big-padding-bottom-mobile">
                                            <Image className="svg-icon" src="images/info.svg" />
                                            <I18n
                                                componentProps={{ className: "control-label text-wrap" }}
                                                id="enrollment.index.error.message"
                                            />
                                        </Col>
                                        {!isCredentialWhitening && (
                                            <Col sm={12} md={12} lg={12} className="col col-12">
                                                <Field name="acceptTerms" component={Checkbox} idForm={FORM_ID} />
                                                <Button
                                                    bsStyle="link"
                                                    label="global.termAndConditions"
                                                    className="btn-noBlock btn-asLink"
                                                    onClick={() => this.setState({ isModalDisplayed: true })}
                                                />
                                            </Col>
                                        )}
                                        {isDigitalEnrollmentEnabled && (
                                            <Col sm={12} md={12} lg={12} className="col col-12">
                                                <Button
                                                    bsStyle="primary"
                                                    label="global.continue"
                                                    loading={isSubmitting}
                                                    type="submit"
                                                    disabled={!values.acceptTerms}
                                                />
                                            </Col>
                                        )}
                                    </Col>
                                </Container>
                            </Form>
                        </main>
                    </div>
                </div>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    email: enrollmentSelectors.getEmail(state),
    documentData: onboardingSelectors.getDocumentData(state),
    activeLanguage: i18nSelectors.getLang(state),
    region: loginSelectors.getRegion(state),
    fetching: enrollmentSelectors.isFetching(state),
    termsConditions: enrollmentSelectors.getTermsAndCond(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            acceptTerms: false,
        }),
        validationSchema: () =>
            Yup.object().shape({
                acceptTerms: Yup.bool()
                    .required(i18nUtils.get("enrollment.index.invitationCode.empty"))
                    .oneOf([true], i18nUtils.get("enrollment.index.acceptTerms.required")),
            }),
        handleSubmit: (_, formikBag, region) => {
            const { dispatch, invitationCode, match } = formikBag.props;
            const isCredentialWhitening = match.path === "/credentialWhitening/code/:code/:lang/:reg?";
            dispatch(enrollmentActions.verifyInvitationCode(invitationCode, formikBag, region, isCredentialWhitening));
            Mixpanel.track(FORM_ID);
        },
    }),
)(Index);
