import React, { Component, Fragment } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Statement from "pages/accounts/_components/Statement";
import groupBy from "lodash/groupBy";

class Statements extends Component {
    state = {
        statementsByYear: {},
    };

    componentDidMount() {
        const { dispatch, ...rest } = this.props;

        dispatch(accountsActions.listStatements(rest.match.params.id));
    }

    componentWillReceiveProps(nextProps) {
        const { statements } = nextProps;
        const statementsByYear = statements ? groupBy(statements, "year") : {};

        this.setState({ statementsByYear });
    }

    handleClick = (idStatementLine) => {
        const { dispatch, ...rest } = this.props;

        dispatch(accountsActions.downloadStatement(rest.match.params.id, idStatementLine));
    };

    render() {
        const { statementsByYear } = this.state;
        const { fetching } = this.props;

        return (
            <Fragment>
                <Head closeLinkTo={`/accounts/${this.props.match.params.id}`} title="accounts.statements" />
                <MainContainer showLoader={fetching}>
                    <div className="above-the-fold">
                        {statementsByYear && (
                            <section className="container--layout flex-grow">
                                <Grid fluid>
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col">
                                            {Object.keys(statementsByYear).map((year, index) => (
                                                <ul className="navigational-list" key={`year-${index}`}>
                                                    <li className="navigational-list-item">
                                                        <h4>{year}</h4>
                                                    </li>
                                                    {Object.values(statementsByYear[year]).map((statement, index) => (
                                                        <li
                                                            className="navigational-list-item"
                                                            key={`statement-${index}`}>
                                                            <Statement
                                                                handleClick={this.handleClick}
                                                                statement={statement}
                                                            />
                                                        </li>
                                                    ))}
                                                </ul>
                                            ))}
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        )}
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: accountsSelectors.getFetching(state),
    statements: accountsSelectors.getStatements(state),
});

export default connect(mapStateToProps)(Statements);
