import React from "react";
import { connect } from "react-redux";
import { bool, shape, string } from "prop-types";

import { selectors as cedipSelectors } from "reducers/cedip";

import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";

function CedipSummaryData({ isDesktop, spaceBetween, cedip, cbu, isAdmitting }) {
    if (!cedip) {
        return <></>;
    }

    const { cbuAAcreditar, montoACobrar, tipoMoneda, transmisiones } = cedip;
    const pendingTransmission = transmisiones?.find((item) => item.estado === "PENDIENTE");
    const { cuitTransmisor, razonSocialTransmisor, tipo } = pendingTransmission;
    const cbuToShow = isAdmitting ? cbu : cbuAAcreditar;

    return (
        <>
            {cbuToShow && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="deposits.cedip.beneficiaryCbu"
                    data={cbuToShow}
                />
            )}

            {cuitTransmisor && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="deposits.cedip.documentType"
                    data={cuitTransmisor}
                />
            )}

            {razonSocialTransmisor && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="deposits.cedip.recieved.from"
                    data={razonSocialTransmisor}
                />
            )}

            {tipo && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="deposits.cedip.transactionType"
                    data={tipo}
                />
            )}

            {montoACobrar && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${
                        !isDesktop || spaceBetween ? "space-between" : ""
                    }`}
                    label="deposits.cedip.total.amount"
                    data={
                        <FormattedAmount
                            currency={tipoMoneda}
                            quantity={montoACobrar}
                            notBold
                            className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                        />
                    }
                />
            )}
        </>
    );
}

CedipSummaryData.propTypes = {
    isDesktop: bool.isRequired,
    spaceBetween: bool,
    isAdmitting: bool.isRequired,
    cedip: shape({}).isRequired,
    cbu: string,
};

CedipSummaryData.defaultProps = {
    spaceBetween: false,
    cbu: "",
};

const mapStateToProps = (state) => ({
    cedip: cedipSelectors.getSelectedCedip(state),
    cbu: cedipSelectors.getSelectedCBU(state),
    isAdmitting: cedipSelectors.isAdmitting(state),
});

export default connect(mapStateToProps)(CedipSummaryData);
