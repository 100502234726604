import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as restrictionSelectors } from "reducers/administration/restrictions";
import I18n from "pages/_components/I18n";
import { Col } from "react-bootstrap";
import { Field } from "formik";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import TagsInputControl from "pages/_components/fields/TagsInputControl";
import classNames from "classnames";
import Checkbox from "pages/_components/Checkbox";
import * as i18n from "util/i18n";
import { string, func, shape, bool } from "prop-types";
import { validationRegexIP } from "util/general.js";
import Container from "pages/_components/Container";
import Hint from "pages/_components/hints/Hint";

const FORM_ID = "administration.restrictions";
const Delimiter = {
    comma: ",",
    space: " ",
};

class IPRestrictions extends Component {
    static propTypes = {
        idUser: string,
        setFieldValue: func.isRequired,
        values: shape({}),
        iPEnabled: bool.isRequired,
        setButtonEnabled: func.isRequired,
        setOnChangeSwitch: func.isRequired,
    };

    static defaultProps = {
        idUser: null,
        values: {},
    };

    state = {
        isDisabled: false,
        listIp: [],
    };

    addAllIps = (value, disabled) => {
        const { listIp } = this.state;
        const { setFieldValue } = this.props;
        if (disabled) {
            this.setState({ listIp: value });
            this.removeAllIps();
        } else {
            setFieldValue("ipsList", listIp);
        }
    };

    removeAllIps = () => {
        const { setFieldValue } = this.props;
        setFieldValue("ipsList", []);
    };

    handleOnChangeSwitch = () => {
        const { setOnChangeSwitch } = this.props;
        setOnChangeSwitch(true);
    };

    hanldeButton = (value) => {
        const { setButtonEnabled } = this.props;

        setButtonEnabled(!value);
        this.setDisabled(value);
    };

    setDisabled = (value) => {
        this.setState({ isDisabled: value });
    };

    render() {
        const { values, idUser } = this.props;
        const { isDisabled } = this.state;

        const isLocked = idUser ? values.anyIP : isDisabled;
        return (
            <Container className={classNames("flex-grow  cmf-container-white", { "pb-3": values.iPEnabled })}>
                {idUser === null && (
                    <div className="data-wrapper-flex" style={{ width: "100%", borderBottom: "solid 0.5px #e4e1e17d" }}>
                        <div className="restriction-legend ml-4 my-3 py-1">
                            <Field
                                name="iPEnabled"
                                onChange={this.handleOnChangeSwitch}
                                idForm={FORM_ID}
                                component={SwitchField}
                                formGroup
                                biggerLabel
                                notMb
                            />
                        </div>
                        <Hint
                            idMessage="administration.restrictions.title.help"
                            styleText={{ alignSelf: "center", width: "21rem" }}
                            classNameImage="m-0"
                            classNameGroup="mt-0 mb-0 ml-4 align-items-center"
                            classNameHintP="font-weight-normal"
                        />
                    </div>
                )}

                {values.iPEnabled && (
                    <>
                        <div className="admin-content-center">
                            <I18n
                                id="administration.restrictions.titleCheck"
                                component="h2"
                                componentProps={{ className: "font-size-28-px mt-4 mb-6" }}
                            />
                        </div>

                        <Col className="max-xl-480 px-xl-0 col-12 col-xl-6" lg={6} md={9}>
                            <I18n
                                id="administration.restriSctions.allIPsAvailable"
                                componentProps={{
                                    className: `${isLocked ? "text-gray" : ""}`,
                                    style: { marginRight: "1rem" },
                                }}
                            />
                            <TagsInputControl
                                name="ipsList"
                                placeholder={i18n.get(`${FORM_ID}.addIP.placeholder`)}
                                readOnly={isLocked}
                                customDelimiter={Delimiter}
                                validationRegex={validationRegexIP}
                                validateDuplicatedItems
                            />

                            <div style={{ marginTop: "2rem" }}>
                                {idUser ? (
                                    <Field name="anyIP" component={Checkbox} idForm={FORM_ID} />
                                ) : (
                                    <Field
                                        name="anyIP"
                                        component={Checkbox}
                                        idForm={FORM_ID}
                                        onChange={(e) => {
                                            this.hanldeButton(e);
                                            this.addAllIps(values.ipsList, e);
                                        }}
                                    />
                                )}
                            </div>
                        </Col>
                    </>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    restrictions: restrictionSelectors.getRestrictions(state),
});

export default connect(mapStateToProps)(IPRestrictions);
