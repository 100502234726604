import React from "react";
import { connect } from "react-redux";
import { string, node, shape, oneOfType, bool } from "prop-types";
import { isString } from "lodash";

import { selectors as i18nSelectors } from "reducers/i18n";
import { get } from "util/i18n";

class I18n extends React.Component {
    static propTypes = {
        defaultValue: string,
        upperCase: bool,
        id: string.isRequired,
        component: oneOfType([node, string]),
        componentProps: shape({}),
    };

    static defaultProps = {
        upperCase: false,
        defaultValue: null,
        component: "span",
        componentProps: {},
    };

    getFinalMessage = () => {
        // Genero los parametros a partir de las props excluyendo las utilizadas por el componente.
        // Todo el resto de las props recibidas son utilizadas como variables del mensaje
        const { id, defaultValue = null, upperCase } = this.props;
        const excludedProps = ["dispatch", "id"];

        const params = Object.keys(this.props).reduce((map, key) => {
            const mapParam = map;
            const propsParam = this.props;

            if (excludedProps.indexOf(key) < 0) {
                mapParam[key] = propsParam[key];
            }
            return mapParam;
        }, {});

        const returnText = get(id, defaultValue, params);

        return upperCase ? returnText.toUpperCase() : returnText;
    };

    isHTMLComponent = (component) =>
        isString(component) && component.length > 0 && component[0] === component[0].toLowerCase();

    render() {
        const { component, componentProps } = this.props;
        const finalMessage = this.getFinalMessage();

        if (this.isHTMLComponent(component)) {
            return React.createElement(component, {
                dangerouslySetInnerHTML: { __html: finalMessage },
                ...componentProps,
            });
        }
        return React.createElement(component, componentProps, [finalMessage]);
    }
}

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
});

export default connect(mapStateToProps)(I18n);
