import React, { Component } from "react";
import Iframe from "@nicholasadamou/react-iframe";
import { routerActions } from "react-router-redux";
import { connect } from "react-redux";
import { actions as ecomexIframeActions, selectors } from "reducers/ecomexIframe";
import Notification from "pages/_components/Notification";
import { func } from "prop-types";
import { actions as notificationActions } from "reducers/notification";
import PageLoading from "pages/_components/PageLoading";
import * as config from "util/config";

class ecomexNewRequests extends Component {
    static propTypes = {
        dispatch: func.isRequired,
    };

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(ecomexIframeActions.loadEnterRequestCredentials());
    };

    componentDidUpdate = () => {
        const { dispatch, error } = this.props;
        if (error) {
            dispatch(routerActions.push("/desktop"));
        }
    };

    componentWillUnmount = () => {
        const { dispatch } = this.props;
        dispatch(ecomexIframeActions.cleanUp());
    };

    handleShowSnackBar() {
        const { dispatch } = this.props;
        dispatch(
            notificationActions.showNotification(
                "Existen transacciones pendientes de Token. Haz click aquí para continuar.",
                "warning",
                ["pages.ecomexIframe"],
            ),
        );
    }

    render() {
        // this.handleShowSnackBar();
        const { fetching, title, width = "100%", height = "100%", token } = this.props;
        const url = config.get("ecomex.service.url.newRequest");

        return (
            <PageLoading loading={fetching}>
                <Notification scopeToShow="pages.ecomexIframe" notificationClassname="navbar-fixed-top" />
                {token && (
                    <div className="pl-0 pr-0 w-100 h-100">
                        <Iframe
                            src={url}
                            sandbox={[
                                "allow-downloads-without-user-activation, allow-popups, allow-downloads, allow-forms, allow-modals, allow-pointer-lock, allow-popups-to-escape-sandbox, allow-presentation, allow-same-origin, allow-scripts, allow-top-navigation, allow-top-navigation-by-user-activation",
                            ]}
                            headers={{
                                Authorization: token,
                                // "Content-Type": "application/json;charset=UTF-8",
                                "Access-Control-Allow-Origin": "*",
                                // "Sec-Fetch-Mode": "cors",
                                // "Accept-Encoding": "gzip, deflate, br, zstd",
                                // "Accept-Language": "en-US,en;q=0.9",
                                // Connection: "keep-alive",
                                // "Sec-Fetch-Dest": "empty",
                            }}
                            frameBorder="0"
                            title={title}
                            width={width}
                            height={height}
                        />
                    </div>
                )}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    token: selectors.getToken(state),
    error: selectors.getError(state),
    fetching: selectors.getFetching(state),
});
export default connect(mapStateToProps)(ecomexNewRequests);
