import React, { Component } from "react";
import { shape, bool, func } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { routerActions } from "react-router-redux/actions";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Notification from "pages/_components/Notification";
import Enviroments from "pages/_components/Enviroments";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";

const FORM_ID = "settings";

class SetDefaultEnvironment extends Component {
    static propTypes = {
        environments: shape([]).isRequired,
        activeEnvironment: shape({}),
        userData: shape({}),
        isFetching: bool.isRequired,
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
    };

    static defaultProps = {
        activeEnvironment: {},
        userData: {},
    };

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(settingsActions.listAllEnvironments());
    };

    handleRemoveDefaultEnvironmentClick = () => {
        const { dispatch } = this.props;
        dispatch(settingsActions.changeDefaultEnvironment(0));
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("settings.defaultEnvironment")}</h1>
        </div>
    );

    render() {
        const { environments, userData, isFetching, activeEnvironment, isMobile } = this.props;

        return (
            <>
                <div className="admin-detail-head px-0" style={{ marginBottom: 0 }}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={isMobile ? "blue-main-header-mobile" : ""}
                        centerElement={!isMobile ? undefined : this.centerContentMobile}
                    />
                    {!isMobile && (
                        <div className="view-title">
                            <h1>{i18n.get("settings.defaultEnvironment")}</h1>
                        </div>
                    )}
                </div>
                <Notification scopeToShow="changeDefaultEnvironment" />
                <MainContainer>
                    <Form className="above-the-fold">
                        <Container
                            className={`${
                                !isMobile ? "container--layaut with-margin" : ""
                            } align-items-center flex-grow container-white`}
                            style={!isMobile ? { paddingBottom: "1rem" } : {}}>
                            <section className="container--layout">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            <div className="admin-content-center">
                                                <I18n id="settings.defaultEnvironment.title" component="h2" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                            <p className={`text-lead ${isMobile ? "account-dropdown-header-font" : ""}`}>
                                <I18n id="settings.defaultEnvironment.info" />
                            </p>
                            <section className="container--layout flex-grow align-items-center">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        {isMobile && (
                                            <Col
                                                sm={12}
                                                md={6}
                                                lg={4}
                                                xl={4}
                                                className="col col-12 d-flex align-items-center">
                                                <div className="ml-3">
                                                    <Image src="images/person.svg" className="svg-icon" />
                                                </div>
                                                <span className="text-uppercase f-size-55">
                                                    {userData.userFullName}
                                                </span>
                                            </Col>
                                        )}
                                        <Col sm={12} md={6} lg={4} xl={4} className="col col-12">
                                            <Field
                                                name="environment"
                                                environments={environments}
                                                activeEnvironment={activeEnvironment}
                                                component={Enviroments}
                                                legendTextID="settings.changeEnvironment"
                                                fromSetDefaultEnvironmentPage
                                                userData={userData}
                                            />
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                            <section className="container--layout">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                            {userData.idDefaultEnvironment && !isMobile && (
                                                <Button
                                                    type="button"
                                                    bsStyle="link"
                                                    loading={isFetching}
                                                    label="settings.defaultEnvironment.buttonRemoveDefaultEnvironment"
                                                    className="btn-outline"
                                                    onClick={this.handleRemoveDefaultEnvironmentClick}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        </Container>
                        <section className="container--layout" style={{ marginTop: "2rem" }}>
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col sm={12} md={9} lg={4} xl={4} className="col col-12">
                                        <Button
                                            type="submit"
                                            loading={isFetching}
                                            label="global.select"
                                            bsStyle="primary"
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                        {userData.idDefaultEnvironment && isMobile && (
                            <section className="container--layout">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={3} lg={6} xl={6} className="col col-12">
                                            <Button
                                                type="button"
                                                loading={isFetching}
                                                label="settings.defaultEnvironment.buttonRemoveDefaultEnvironment"
                                                className="btn-outline"
                                                onClick={this.handleRemoveDefaultEnvironmentClick}
                                            />
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        )}
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    environments: Object.entries(settingsSelectors.getAllEnvironments(state)),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    userData: sessionSelectors.getUser(state),
    isFetching: settingsSelectors.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: ({ userData }) => ({
            environment: userData.idDefaultEnvironment || "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                environment: Yup.string().required(i18n.get(`${FORM_ID}.defaultEnvironment.environment.required`)),
            }),
        handleSubmit: ({ environment }, formikBag) => {
            formikBag.props.dispatch(settingsActions.changeDefaultEnvironment(environment));
        },
    }),
)(SetDefaultEnvironment);
