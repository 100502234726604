import React, { Component } from "react";
import { node } from "prop-types";

import Collapse from "pages/_components/Collapse";

class CollapsibleInfo extends Component {
    static propTypes = {
        about: node.isRequired,
        children: node.isRequired,
    };

    render() {
        const { about, children } = this.props;
        return (
            <div className="container--layout">
                <div className="container">
                    <div className="align-product-head">
                        {about}
                        <Collapse
                            buttonLabelClosed="productHead.moreInformation"
                            buttonLabelOpened="productHead.lessInformation">
                            <div>{children}</div>
                        </Collapse>
                    </div>
                </div>
            </div>
        );
    }
}

export default CollapsibleInfo;
