import React, { Component } from "react";
import PropTypes, { bool } from "prop-types";
import classNames from "classnames";

import ListItem from "pages/accounts/ListItem";
import ExtendedAccountItem from "pages/accounts/ExtendedAccountItem";
import Container from "pages/_components/Container";

class AccountsList extends Component {
    static propTypes = {
        accounts: PropTypes.oneOfType([PropTypes.array]).isRequired,
        extendedData: bool,
        isDesktop: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        extendedData: false,
    };

    renderList = () => {
        const { accounts, extendedData, isDesktop } = this.props;

        if (!accounts.length) {
            return null;
        }

        return (
            <div role="menu" className={classNames("table-body")}>
                {accounts.map((account) => {
                    if (extendedData) {
                        return <ExtendedAccountItem {...account} key={account.idProduct} isDesktop={isDesktop} />;
                    }
                    return <ListItem {...account} key={account.idProduct} />;
                })}
            </div>
        );
    };

    render() {
        return (
            <Container className=" flex-grow">
                <div className="table table--products">{this.renderList()}</div>
            </Container>
        );
    }
}

export default AccountsList;
