import React, { useEffect, useState } from "react";
import { func, bool, string, shape, arrayOf, number } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { push } from "react-router-redux";

import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import moment from "moment";

import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedRate from "pages/_components/FormattedRate";
import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Hint from "pages/_components/hints/Hint";
import Productselector from "pages/forms/_components/_fields/Productselector";
import Selector from "pages/_components/fields/formik/Selector";
import AmountField from "pages/_components/fields/formik/AmountField";
import TextField from "pages/_components/fields/TextField";
import { DateField } from "pages/_components/fields/DateField";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import Heading from "pages/_components/Heading";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as holidaysActions, selectors as holidaysSelectors } from "reducers/holidays";
import { selectors as depositsSelectors, actions as depositsActions } from "reducers/deposits";
import { actions as notificationActions } from "reducers/notification";
import { actions as transferActions } from "reducers/transfer";

import * as i18n from "util/i18n";
import * as configUtil from "util/config";
import { filterForAvailableDays } from "util/date";

const FORM_ID = "deposits.constitute";
const INVALID_ACCOUNT = "CA";
const CURRENCY_PESOS = 0;

function DepositConstitute({
    dispatch,
    isDesktop,
    fetchingAccounts,
    values,
    accounts,
    loggedUser,
    setValues,
    minAmountPesos,
    minAmountDolares,
    simulationResult = null,
    holidays,
    depositTypes,
    setFieldValue,
    environmentDate,
    defaultDaysQuantity,
    loadingPreview,
    loadingSimulation,
    loadingConstitution,
    isRetailEnvironment,
    serverDate,
}) {
    DepositConstitute.propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        fetchingAccounts: bool.isRequired,
        values: shape({
            debitAccount: string.isRequired,
            type: string.isRequired,
            consolidatedAmount: number.isRequired,
        }).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        setValues: func.isRequired,
        minAmountPesos: number.isRequired,
        minAmountDolares: number.isRequired,
        simulationResult: shape({}).isRequired,
        holidays: arrayOf(string).isRequired,
        depositTypes: arrayOf(shape({})).isRequired,
        setFieldValue: func.isRequired,
        environmentDate: shape({}).isRequired,
        defaultDaysQuantity: number.isRequired,
        loadingPreview: bool.isRequired,
        loadingSimulation: bool.isRequired,
        loadingConstitution: bool.isRequired,
        isRetailEnvironment: bool.isRequired,
        serverDate: string,
    };

    DepositConstitute.defaultProps = {
        serverDate: "",
    };

    const [depositState, setDepositState] = useState({
        showMessage: false,
        showHintInfoMb: false,
        areDatesInPageLoad: false,
        typeAmount: "0",
    });
    const { type, automaticRenewal, actionToDue, termInDays, debitAccount, dueDate } = values;
    const currencyType = type ? type.split("-")[0] : "0";
    const [accountsToShow, setAccountsToShow] = useState(
        accounts.filter((account) => account.currency === currencyType),
    );

    const { showMessage, showHintInfoMb, areDatesInPageLoad, typeAmount } = depositState;

    const currenciesAll =
        currencyType === "0"
            ? [{ id: 0, label: i18n.get(`currency.label.ARS`) }]
            : [{ id: 2, label: i18n.get(`currency.label.USD`) }];
    const minAmount = currencyType === "2" ? minAmountDolares : minAmountPesos;

    useEffect(() => {
        dispatch(depositsActions.simulateDepositPreview());
        dispatch(holidaysActions.listHolidays());
        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
        if (!isRetailEnvironment) {
            setAccountsToShow(
                accounts.filter(
                    (account) => account.currency === currencyType && account.productType !== INVALID_ACCOUNT,
                ),
            );
        } else {
            setAccountsToShow(accounts.filter((account) => account.currency === currencyType));
        }
    }, [accounts, loggedUser.userId, currencyType, isRetailEnvironment, dispatch]);

    useEffect(() => {
        const format = "hh:mm:ss";
        const timeZone = "America/Argentina/Buenos_Aires";
        const hourFrom = configUtil.get("frontend.deposits.workingHours.hourFrom", "10:00:00");
        const hourTo = configUtil.get("frontend.deposits.workingHours.hourTo", "17:00:00");
        const beforeTime = moment.tz(hourFrom, format, timeZone);
        const afterTime = moment.tz(hourTo, format, timeZone);
        const today = moment.tz(serverDate, timeZone);
        const isOutOfWorkingHours = !today.isBetween(beforeTime, afterTime);
        const isHoliday = holidays.includes(today.format("DD/MM/YYYY"));

        if (serverDate && holidays && (isOutOfWorkingHours || isHoliday)) {
            dispatch(
                notificationActions.showNotification(
                    i18n.get("deposits.constitute.error.invalidHour", null, {
                        hourFrom: hourFrom.substring(0, 5),
                        hourTo: hourTo.substring(0, 5),
                    }),
                    "warning",
                    ["deposits"],
                ),
            );
            dispatch(push("/deposits"));
        } else {
            if (!simulationResult && !type && depositTypes && depositTypes.length > 0) {
                setFieldValue("type", depositTypes[0].value);
            }

            if (!simulationResult && !areDatesInPageLoad && environmentDate && termInDays !== 0) {
                setFieldValue("termInDays", defaultDaysQuantity);
                setFieldValue("dueDate", moment(environmentDate.replace("Z", "")).add(defaultDaysQuantity, "days"));
                setFieldValue("consolidatedAmount", { currency: currencyType, amount: minAmount });
                setDepositState({ ...depositState, areDatesInPageLoad: true });
            }
            if (typeAmount !== currencyType) {
                setFieldValue("consolidatedAmount", { currency: currencyType, amount: minAmount });
                setDepositState({ ...depositState, typeAmount: currencyType });
            }
        }
    }, [
        areDatesInPageLoad,
        currencyType,
        defaultDaysQuantity,
        dispatch,
        depositState,
        depositTypes,
        environmentDate,
        holidays,
        minAmount,
        serverDate,
        setFieldValue,
        simulationResult,
        termInDays,
        type,
        typeAmount,
    ]);

    const renderLimitsForCapital = () => {
        const currency = type ? type.split("-")[0] : "0";

        const minimumAmount = currency === "0" ? minAmountPesos : minAmountDolares;

        return (
            <div className="p-relative">
                <div className="transfer-limits">
                    <div className="item-limit justify-content-end">
                        <span className="transfer-limit-label">CAPITAL MÍNIMO</span>
                        <FormattedAmount
                            className="transfer-limit-amount"
                            currency={currency.toString()}
                            quantity={minimumAmount}
                            notBold
                        />
                    </div>
                </div>
            </div>
        );
    };

    const handleDueDateChange = (date) => {
        const environmentDateWithoutHours = moment(environmentDate).startOf("day");
        const dueDateWithoutHours = moment(date).startOf("day");
        setValues({
            ...values,
            termInDays: date ? dueDateWithoutHours.diff(environmentDateWithoutHours, "days") : defaultDaysQuantity,
            dueDate: date || "",
        });
    };

    const handleTermInDaysChange = (event) =>
        setValues({
            ...values,
            dueDate: event.target.value ? moment(environmentDate.replace("Z", "")).add(event.target.value, "days") : "",
        });

    const handleOnBlur = () => {
        if (termInDays < 30) {
            setValues({
                ...values,
                termInDays: defaultDaysQuantity,
                dueDate: moment(environmentDate).add(defaultDaysQuantity, "days"),
            });
        }
    };

    const handleGoBack = () => dispatch(push("/deposits"));

    const handleReSimulate = () => {
        dispatch(depositsActions.goBackToSimulation());
        setValues({
            ...values,
            termInDays: defaultDaysQuantity,
            dueDate: environmentDate && moment(environmentDate).add(defaultDaysQuantity, "days"),
            consolidatedAmount: { currency: currencyType, amount: minAmount },
            debitAccount: "",
        });
    };

    const renderSimularionResult = () => {
        const { dueDate: simulationDueDate, rate, totalAmount, earnedInterests } = simulationResult;

        const { currency: currencyTotalAmount, quantity: amountTotalAmount } = totalAmount;
        const { currency: currencyEarnedInterests, quantity: amountEarnedInterests } = earnedInterests;

        const actionToDueOptions = [
            {
                value: "capitalRenovationPlusInterests",
                label: i18n.get("deposits.constitute.actionToDue.options.capitalRenovationPlusInterests"),
            },
        ];
        // transfer-data // space-between
        const containerClassName = `data-wrapper-flex`;

        return (
            <Container className={`flex-grow mt-2 ${isDesktop && "container-white py-3"}`} gridClassName="form-content">
                <Col className="col col-12 col-md-10 col-lg-9 col-xl-7 align-items-center data-plazo">
                    <Col sm={12} className="col col-12 px-0">
                        <Col sm={12} xl={6} lg={6} md={9} className="px-0 px-md-3">
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                label="deposits.constitute.earnedInterests.label"
                                data={
                                    <FormattedAmount
                                        currency={currencyEarnedInterests}
                                        quantity={amountEarnedInterests}
                                        fontClassName="font-regular"
                                        ml
                                        notBold
                                    />
                                }
                            />
                        </Col>
                        <Col sm={12} xl={6} lg={6} md={9} className=" px-0 px-md-3">
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                label="deposits.constitute.totalAmount.label"
                                data={
                                    <FormattedAmount currency={currencyTotalAmount} quantity={amountTotalAmount} ml />
                                }
                            />
                        </Col>
                        <Col sm={12} xl={6} lg={6} md={9} className="px-0 px-md-3">
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                label="deposits.constitute.rate.short.label"
                                data={
                                    <FormattedRate
                                        rate={rate}
                                        ml
                                        className="data-amount f-size-55"
                                        symbolToTheLeft={false}
                                    />
                                }
                            />
                        </Col>
                        <Col sm={12} xl={6} lg={6} md={9} className=" px-0 px-md-3">
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                label="deposits.list.dueDate"
                                data={
                                    <FormattedDate
                                        date={simulationDueDate.replace("Z", "")}
                                        dateFormat="dd/MM/yyyy"
                                        className="data-amount f-size-55 pb-2"
                                        ml
                                    />
                                }
                            />
                        </Col>
                        <Col sm={12} xl={12} lg={12} md={12} className="px-0 px-md-3">
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                label="deposits.constitute.termInDays.label"
                                data={<span className="data-amount f-size-55 ml-2">{termInDays}</span>}
                            />
                        </Col>
                        <Col
                            sm={12}
                            xl={12}
                            lg={12}
                            md={12}
                            className={`px-0 align-items-center d-flex ${!isDesktop ? "f-dir-col" : ""}`}>
                            <Col sm={12} xl={6} lg={6} md={9} className="px-0 px-md-3">
                                <div className={`d-flex ${isDesktop ? "mt-5 mb-2" : "align-items-center"}`}>
                                    <div>
                                        <Field
                                            name="automaticRenewal"
                                            idForm={FORM_ID}
                                            label={<I18n id="deposits.constitute.autoRenew.label" />}
                                            component={Checkbox}
                                            checked={automaticRenewal}
                                            value={automaticRenewal}
                                            formGroupClassName="my-0"
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        className={`btn btn-link no-shadow hint-check ${
                                            isDesktop ? "align-items-start" : "align-items-center pb-1"
                                        }`}
                                        onClick={() => setDepositState({ ...depositState, showMessage: !showMessage })}>
                                        {showMessage ? (
                                            <Image src="images/infoActiveUserInvite.svg" className="hint-image-style" />
                                        ) : (
                                            <Image src="images/infoUserInvite.svg" className="hint-image-style" />
                                        )}
                                    </button>
                                </div>
                                {showMessage ? (
                                    <I18n
                                        id="deposits.constitute.confirmation.automatic.renewal.information"
                                        component="p"
                                        componentProps={{
                                            className: "hint-text hint-font-size-small font-weight-normal",
                                        }}
                                    />
                                ) : (
                                    undefined
                                )}
                            </Col>
                            {automaticRenewal && (
                                <Col sm={12} xl={6} lg={6} md={9} className={isDesktop ? "px-md-3 pb-1" : "px-0"}>
                                    <Field
                                        name="actionToDue"
                                        component={Selector}
                                        idField="actionToDue"
                                        options={actionToDueOptions}
                                        value={actionToDue}
                                        isRequired
                                        idForm={FORM_ID}
                                        isDesktop={isDesktop}
                                        customPlaceholder={i18n.get("transfers.productSelector.placeholder")}
                                        formGroupClassName="my-0"
                                    />
                                </Col>
                            )}
                        </Col>
                        <Col sm={12} xl={12} lg={12} md={12} className="px-0 px-md-3 mt-3">
                            <Hint
                                idMessage="deposits.constitute.confirmation.information"
                                classNameGroup="hint-disclaimer mt-2"
                                classNameHintP="font-weight-normal"
                                alwaysShowMessage
                            />
                        </Col>
                    </Col>
                </Col>
            </Container>
        );
    };

    const centerContent = () => {
        if (!isDesktop) {
            return (
                <div>
                    <h1 className="m-0">{i18n.get("deposits.constitute.title")}</h1>
                </div>
            );
        }
        return (
            <div className="view-title d-flex">
                <h1 className="my-2">{i18n.get("deposits.constitute.title")}</h1>
                <Hint
                    idMessage="deposits.constitute.simulation.information"
                    classNameMessage="max-width-30rem d-flex align-center"
                    classNameHintP="hint-font-size-small font-weight-normal my-0"
                    classNameGroup="ml-2"
                    classNameImage="mt-0 align-self-center"
                />
            </div>
        );
    };

    const rightContentMobile = () => (
        <button
            type="button"
            className="btn btn-hint mt-1"
            onClick={() => setDepositState({ ...depositState, showHintInfoMb: !showHintInfoMb })}>
            {!showHintInfoMb ? (
                <Image src="images/infoWhite.svg" styles={{ height: "24px", width: "24px" }} />
            ) : (
                <Image src="images/infoWhiteFull.svg" styles={{ height: "24px", width: "24px" }} />
            )}
        </button>
    );

    const onChangeAccount = (account) => {
        const selectedAccount = accounts.find((object) => object.idProduct === account);
        if (selectedAccount) {
            setValues({
                ...values,
                consolidatedAmount: {
                    currency: parseInt(selectedAccount.currency, 10),
                    amount: values.consolidatedAmount.amount,
                },
            });
            if (debitAccount !== account) {
                dispatch(
                    transferActions.accountLimitsActivities(selectedAccount.idProduct, "deposits.constituteDeposit"),
                );
            }
        }
    };

    const onChangeDepositType = () => {
        setValues({ ...values, debitAccount: "" });
    };

    return (
        <>
            <Notification scopeToShow="depositsConstitute" />
            <div className="admin-detail-head px-0">
                <Head
                    onBack={handleGoBack}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                    centerElement={!isDesktop && centerContent}
                    rightContent={!isDesktop && rightContentMobile}
                    showHintInfoMb={showHintInfoMb}
                />
                {isDesktop && <Head centerElement={centerContent} hasInlineButtons centerContentClassName="p-0 mb-1" />}
            </div>
            <PageLoading loading={loadingPreview || fetchingAccounts}>
                <MainContainer viewPageClassName="z-index-auto" viewContentClassName="overflow-visible">
                    <div className={`above-the-fold ${isDesktop && "mr-2"}`}>
                        <Form>
                            <Container className="align-items-center container-white py-3" gridClassName="form-content">
                                <Col className="col col-12 col-md-10 col-lg-9 col-xl-7 align-items-center">
                                    <Col sm={12} className="col col-12 col-no-pad-mobile">
                                        {!isDesktop && showHintInfoMb && (
                                            <Row>
                                                <span className="hint-snackbar-mobile z-index-1 px-4 py-2">
                                                    {i18n.get("deposits.constitute.simulation.information")}
                                                </span>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col md={6}>
                                                <Field
                                                    name="type"
                                                    component={Selector}
                                                    idField="type"
                                                    options={depositTypes}
                                                    value={type}
                                                    isRequired
                                                    idForm={FORM_ID}
                                                    isDesktop={isDesktop}
                                                    isDisabled={!!simulationResult}
                                                    onCustomChange={onChangeDepositType}
                                                    disabled={!!simulationResult}
                                                    customPlaceholder={i18n.get(
                                                        "transfers.productSelector.placeholder",
                                                    )}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Field
                                                    autocomplete="off"
                                                    component={AmountField}
                                                    data={{ options: currenciesAll }}
                                                    idForm={FORM_ID}
                                                    name="consolidatedAmount"
                                                    clearable={false}
                                                    label="deposits.constitute.consolidatedAmount.label"
                                                    maxLength={15}
                                                    amountClassName="text-align-right"
                                                    formGroupClassName="mb-0"
                                                    fixedDecimalScale
                                                    isDisabled={!!simulationResult}
                                                    disabled={!!simulationResult}
                                                />
                                                {renderLimitsForCapital()}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className="col col-12">
                                                <Field
                                                    name="debitAccount"
                                                    component={Productselector}
                                                    idField="debitAccount"
                                                    data={{
                                                        emptyOptionLabel: "",
                                                        options: accountsToShow,
                                                    }}
                                                    value={debitAccount}
                                                    isRequired
                                                    mode="edit"
                                                    labelText="deposits.constitute.debitAccount"
                                                    idForm={FORM_ID}
                                                    isDesktop={isDesktop}
                                                    customPlaceholder={i18n.get(
                                                        "transfers.productSelector.placeholder",
                                                    )}
                                                    style={{ marginTop: 0 }}
                                                    onCustomChange={onChangeAccount}
                                                    isDisabled={!!simulationResult}
                                                    disabled={!!simulationResult}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={isDesktop ? 6 : 5}>
                                                <Field
                                                    component={TextField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    name="termInDays"
                                                    type="text"
                                                    label="deposits.constitute.termInDays"
                                                    pattern="[0-9]*"
                                                    handleOnChange={handleTermInDaysChange}
                                                    handleOnBlur={handleOnBlur}
                                                    value={termInDays}
                                                    maxLength={3}
                                                    isDisabled={!!simulationResult}
                                                />
                                            </Col>
                                            <Col xs={isDesktop ? 6 : 7} className={!isDesktop && "pl-1"}>
                                                <Field
                                                    component={DateField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    name="dueDate"
                                                    minDate={moment(environmentDate).add(defaultDaysQuantity, "days")}
                                                    maxDate={moment(environmentDate).add(999, "days")}
                                                    handleChange={handleDueDateChange}
                                                    filterDate={(date) => filterForAvailableDays(holidays, date)}
                                                    value={dueDate}
                                                    formikValueFirst
                                                    isDisabled={!!simulationResult}
                                                    disabled={!!simulationResult}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Container>

                            {simulationResult && renderSimularionResult()}

                            {!simulationResult ? (
                                <Container className="flex-grow align-items-center" gridClassName="form-content">
                                    <Col className="col col-12 col-md-9 col-lg-7 col-xl-5 align-items-center px-0 mt-3">
                                        <Col
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: !isDesktop ? "column-reverse" : "",
                                            }}
                                            sm={12}
                                            className="col col-12 col-no-pad-mobile">
                                            <Col className="col-12 col-md-6">
                                                <Button
                                                    onClick={handleGoBack}
                                                    className="btn-outline my-1"
                                                    label="global.cancel"
                                                />
                                            </Col>
                                            <Col className="col-12 col-md-6">
                                                <Button
                                                    type="submit"
                                                    bsStyle="primary"
                                                    className="my-1"
                                                    label="deposits.constitute.simulation.button"
                                                    loading={loadingSimulation}
                                                />
                                            </Col>
                                        </Col>
                                    </Col>
                                </Container>
                            ) : (
                                <Container className="flex-grow align-items-center" gridClassName="form-content">
                                    <Col className="col col-12 col-md-9 col-lg-7 col-xl-5 align-items-center px-0 mt-3">
                                        <Col
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: !isDesktop ? "column-reverse" : "",
                                            }}
                                            sm={12}
                                            className="col col-12 col-no-pad-mobile">
                                            <Col className="col-12 col-md-6">
                                                <Button
                                                    onClick={handleReSimulate}
                                                    className="btn-outline my-1"
                                                    label="deposits.constitute.confirmation.goBack"
                                                />
                                            </Col>
                                            <Col className="col-12 col-md-6">
                                                <Button
                                                    type="submit"
                                                    bsStyle="primary"
                                                    className="my-1"
                                                    label="deposits.constitute.confirmation.button"
                                                    loading={loadingConstitution}
                                                />
                                            </Col>
                                        </Col>
                                    </Col>
                                </Container>
                            )}
                        </Form>
                    </div>
                </MainContainer>
            </PageLoading>
        </>
    );
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    accounts: accountsSelectors.getAccounts(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
    minAmountPesos: depositsSelectors.getMinAmountPesos(state),
    minAmountDolares: depositsSelectors.getMinAmountDolares(state),
    simulationResult: depositsSelectors.getSimulationResult(state),
    holidays: holidaysSelectors.getHolidays(state),
    depositTypes: depositsSelectors.getDepositTypes(state),
    environmentDate: depositsSelectors.getEnvironmentDate(state),
    defaultDaysQuantity: depositsSelectors.getDefaultDaysQuantity(state),
    loadingPreview: depositsSelectors.getLoadingPreview(state),
    loadingSimulation: depositsSelectors.getLoadingSimulation(state),
    loadingConstitution: depositsSelectors.getLoadingConstitution(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
    serverDate: depositsSelectors.getServerDate(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { depositTypes, minAmountPesos, defaultDaysQuantity, environmentDate, simulationResult } = props;

            let termInDaysDefault = defaultDaysQuantity;
            let consolidatedAmountDefault = { currency: CURRENCY_PESOS, amount: minAmountPesos || "" };
            let dueDataDefault = environmentDate ? moment(environmentDate).add(defaultDaysQuantity, "days") : "";
            let debitAccountDefault = "";

            if (simulationResult) {
                const { dueDate, capital, debitAccount } = simulationResult;
                termInDaysDefault = moment(dueDate).diff(moment(environmentDate), "days");
                consolidatedAmountDefault = { currency: capital.currency, amount: capital.quantity };
                dueDataDefault = moment(dueDate);
                debitAccountDefault = debitAccount;
            }

            return {
                debitAccount: debitAccountDefault,
                type: depositTypes && depositTypes.length > 0 ? depositTypes[0].value : null,
                consolidatedAmount: consolidatedAmountDefault,
                termInDays: termInDaysDefault || "",
                dueDate: dueDataDefault,
                automaticRenewal: false,
                actionToDue: "capitalRenovationPlusInterests",
            };
        },
        validationSchema: () =>
            Yup.lazy((values) => {
                const { termInDays, dueDate } = values;

                let dueAndTermValidation = {};

                if (!termInDays && !dueDate) {
                    dueAndTermValidation = {
                        termInDays: Yup.number().required(i18n.get(`${FORM_ID}.field.error.required`)),
                        dueDate: Yup.string()
                            .nullable()
                            .required(i18n.get(`${FORM_ID}.dueDate.error.required`)),
                    };
                } else if (!termInDays) {
                    dueAndTermValidation = {
                        termInDays: Yup.number().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    };
                } else if (!dueDate) {
                    dueAndTermValidation = {
                        dueDate: Yup.string()
                            .nullable()
                            .required(i18n.get(`${FORM_ID}.dueDate.error.required`)),
                    };
                }

                return Yup.object().shape({
                    debitAccount: Yup.string().required(i18n.get(`${FORM_ID}.debitAccount.field.error.required`)),
                    type: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    consolidatedAmount: Yup.object().shape({
                        amount: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    }),
                    ...dueAndTermValidation,
                });
            }),
        handleSubmit: (
            { debitAccount, type, consolidatedAmount, termInDays, dueDate, automaticRenewal, actionToDue },
            formikBag,
        ) => {
            const { dispatch, accounts, simulationResult } = formikBag.props;
            const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === debitAccount);

            if (!simulationResult) {
                dispatch(
                    depositsActions.simulateDeposit({
                        debitAccount: completeDebitAccount,
                        depositType: type,
                        consolidatedAmount,
                        termInDays,
                        dueDate,
                        formikBag,
                    }),
                );
            } else {
                const {
                    rate,
                    capital,
                    totalAmount,
                    earnedInterests,
                    requestCode,
                    operationNumber,
                    profitTaxCode,
                    profitTax,
                    witnessRate,
                    codeRate,
                    accountNumber,
                } = simulationResult;

                const actionToDueValue = automaticRenewal ? actionToDue : undefined;

                dispatch(
                    depositsActions.constituteDeposit({
                        debitAccount: completeDebitAccount,
                        depositType: type,
                        consolidatedAmount,
                        termInDays,
                        dueDate,
                        automaticRenewal,
                        rate,
                        capital,
                        totalAmount,
                        earnedInterests,
                        actionToDue: actionToDueValue,
                        requestCode,
                        operationNumber,
                        profitTaxCode,
                        profitTax,
                        witnessRate,
                        codeRate,
                        accountNumber,
                        formikBag,
                    }),
                );
            }
        },
    }),
)(DepositConstitute);
