import React, { Component } from "react";
import { connect } from "react-redux";
import { bool } from "prop-types";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import Yup from "yup";
import * as i18n from "util/i18n";
import Credential from "pages/_components/fields/credentials/Credential";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import I18n from "pages/_components/I18n";
import isTokenActive from "util/token";

const FORM_ID = "accounts.new.account";

class AccountTokenConfirmation extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { isSubmitting, isDesktop } = this.props;

        return (
            <>
                <Form className={!isDesktop ? "above-the-fold" : "confirmation__container"}>
                    <Container
                        className={!isDesktop && "flex-grow align-items-center container-white pb-4"}
                        gridClassName={isDesktop && "confirmation__form confirmation__box"}>
                        <Col className="col-12 col-xl-12" lg={12} md={9} sm={12}>
                            <div className="admin-content-center">
                                {!isTokenActive(!isDesktop) ? (
                                    <I18n id="transfer.summary.confirm.label" />
                                ) : (
                                    <I18n
                                        id="confirmation.withoutotp.text"
                                        component="p"
                                        componentProps={{ className: "confirmation__text-without-otp" }}
                                    />
                                )}
                            </div>
                            <div className="admin-content-center account__solicitude-pass">
                                <Field
                                    name="otp"
                                    type="otp"
                                    idForm={FORM_ID}
                                    component={Credential}
                                    loading={isSubmitting}
                                    placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                />
                            </div>
                        </Col>
                        <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                            <Col lg={12} md={12} sm={12} className="px-0">
                                <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                                    <div className="admin-content-center">
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.confirm"
                                            loading={isSubmitting}
                                            image="images/arrowRight.svg"
                                            imageStyle="mr-2"
                                        />
                                    </div>
                                </Col>
                            </Col>
                        </Container>
                    </Container>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    openingData: accountsSelectors.getAccountOpeningData(state),
    userId: sessionSelectors.getUser(state).userId,
    fullName: sessionSelectors.getUser(state).userFullName,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, openingData, userId } = formikBag.props;
            dispatch(
                accountsActions.openingAccount(
                    { ...openingData, idActivity: "accounts.opening" },
                    otp,
                    userId,
                    formikBag,
                ),
            );
        },
    }),
)(AccountTokenConfirmation);
