/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { string, bool, number, func, shape } from "prop-types";

import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import Credential from "pages/_components/fields/credentials/Credential";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import { Mixpanel } from "util/clickstreaming";
import RegionSelectionLink from "pages/login/_components/RegionSelectionLink";
import FooterDesktop from "pages/_components/FooterDesktop";
import Captcha from "pages/_components/fields/credentials/Captcha";
import ForgotUserButton from "./ForgotUserButton";

const FORM_ID = "login.step2";

class Step2Content extends Component {
    static propTypes = {
        securitySeal: number.isRequired,
        securitySealAlt: string.isRequired,
        isSubmitting: bool.isRequired,
        isDesktop: bool,
        dispatch: func.isRequired,
        location: shape({}).isRequired,
        showCaptcha: bool.isRequired,
        resetCaptcha: bool.isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    render() {
        const {
            securitySeal,
            securitySealAlt,
            isSubmitting,
            isDesktop,
            dispatch,
            location,
            showCaptcha,
            resetCaptcha,
        } = this.props;
        const captchaEnabled = configUtils.getBoolean("core.auth.captcha.enabled");
        return (
            <Form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                <Container className="container--layout flex-grow align-items-center">
                    <Col sm={12} md={12} lg={12} className={!isDesktop ? "pb-2" : "pb-2 pl-0"}>
                        <div className="text-lead security-image">
                            <div id="figureId" className="image">
                                <figure>
                                    <img
                                        aria-describedBy="verifyImage"
                                        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                                        tabIndex="0"
                                        src={securitySeal}
                                        alt={i18n.get(securitySealAlt)}
                                    />
                                </figure>
                            </div>
                            <p id="verifyImage">
                                <I18n id="login.step2.verifyYourImage" />
                            </p>
                        </div>
                        <div className="login__label-required">
                            <Field
                                hideLabel={false}
                                idForm={FORM_ID}
                                name="password"
                                copyEnabled={false}
                                component={Credential}
                                isDisabled={isSubmitting}
                                inputProps={{
                                    autofocus: true,
                                }}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={12} className={isDesktop ? "col col-12 px-0" : "col col-12"}>
                        {captchaEnabled && showCaptcha && (
                            <Field name="captcha" idForm={FORM_ID} component={Captcha} resetCaptcha={resetCaptcha} />
                        )}
                    </Col>

                    {!isDesktop && (
                        <>
                            <ForgotUserButton label="global.forgot.password" dispatch={dispatch} />
                            <Col sm={12} md={12} lg={12} className="col col-12 pt-4">
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                />
                            </Col>

                            <Col sm={12} md={12}>
                                <FooterDesktop
                                    isDesktop={isDesktop}
                                    moreOptions={[
                                        <RegionSelectionLink disabled={location?.pathname === "/regionSelection"} />,
                                    ]}
                                />
                            </Col>
                        </>
                    )}
                </Container>
                {isDesktop && (
                    <Container className="align-items-center container--layout">
                        <ForgotUserButton className="text-none" label="global.forgot.password" {...this.props} />
                        <Col sm={12} md={12} lg={12} className={!isDesktop ? "col col-12" : "col col-12 pl-0"}>
                            <Button
                                type="submit"
                                bsStyle="primary"
                                label="global.getInto"
                                loading={isSubmitting}
                                className="text-none"
                            />
                        </Col>
                    </Container>
                )}
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    securitySeal: loginSelectors.getSecuritySeal(state),
    securitySealAlt: loginSelectors.getSecuritySealAlt(state),
    showCaptcha: loginSelectors.getShowCaptcha(state),
    resetCaptcha: loginSelectors.getResetCaptcha(state),
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            password: "",
            captcha: "",
        }),
        validationSchema: (props) =>
            Yup.object().shape({
                password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
                captcha: props.showCaptcha
                    ? Yup.string().required(i18n.get(`${FORM_ID}.captcha.required`))
                    : Yup.string(),
            }),
        handleSubmit: ({ password, captcha }, formikBag) => {
            formikBag.props.dispatch(loginActions.setResetCaptcha(false));
            if (
                configUtils.get("core.sessionHandler.componentFQN") ===
                "com.technisys.omnichannel.core.session.DbSessionHandler"
            ) {
                formikBag.props.dispatch(loginActions.loginStep2(password, captcha, formikBag));
            } else {
                formikBag.props.dispatch(loginActions.oauth(password, captcha, formikBag, formikBag.props.showCaptcha));
            }
            Mixpanel.track(FORM_ID);
        },
    }),
)(Step2Content);
