import React, { Component, Fragment } from "react";
import { Collapse as BSCollapse } from "react-bootstrap";
import { string, oneOfType, element, arrayOf } from "prop-types";

import Button from "pages/_components/Button";

class Collapse extends Component {
    static propTypes = {
        buttonClassName: string,
        buttonLabelClosed: string,
        buttonLabelOpened: string,
        children: oneOfType([element, arrayOf(element)]).isRequired,
    };

    static defaultProps = {
        buttonClassName: "btn-small",
        buttonLabelClosed: "",
        buttonLabelOpened: "",
    };

    state = {
        open: false,
    };

    handleClick = () => {
        const { open } = this.state;
        this.setState({ open: !open });
    };

    render() {
        const { open } = this.state;
        const { buttonClassName, buttonLabelClosed, buttonLabelOpened, children } = this.props;

        return (
            <Fragment>
                <Button
                    className={buttonClassName}
                    label={open ? buttonLabelOpened : buttonLabelClosed}
                    onClick={this.handleClick}
                    bsStyle="link"
                    aria-haspopup
                    aria-expanded={open}
                />
                <BSCollapse in={open}>
                    <div>{children}</div>
                </BSCollapse>
            </Fragment>
        );
    }
}

export default Collapse;
