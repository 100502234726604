import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";

import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";
import * as i18n from "util/i18n";
import * as config from "util/config";
import { bool } from "prop-types";

import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import { Mixpanel } from "util/clickstreaming";
import PasswordHelp from "pages/_components/PasswordHelp";

const FORM_ID = "recoveryPassAndUserpass.step3";

class Step3Content extends Component {
    render() {
        const { isSubmitting, isDesktop } = this.props;
        const maxLength = config.getInteger("core.password.maxLength", 8);
        const minLength = config.getInteger("core.password.minLength", 20);

        return (
            <Form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                <Container className="container--layout flex-grow align-items-center">
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <p className="text-lead">
                            <I18n id="recoveryPassword.step3.credential.message" />
                        </p>
                    </Col>
                </Container>
                <Container className="container--layout flex-grow align-items-center">
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Field name="newPassword" idForm={FORM_ID} component={Credential} maxLength={maxLength} />                        
                        <Field
                            name="newPasswordConfirmation"
                            idForm={FORM_ID}
                            component={Credential}
                            maxLength={maxLength}
                        />
                        <PasswordHelp formId="settings.changePassword" minLength={minLength} maxLength={maxLength} />
                    </Col>
                </Container>
                <Container className="align-items-center container--layout">
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Button label="global.confirm" loading={isSubmitting} type="submit" bsStyle="primary" />
                    </Col>
                </Container>
            </Form>
        );
    }
}

Step3Content.propTypes = {
    isSubmitting: bool,
    isDesktop: bool,
};

Step3Content.defaultProps = {
    isSubmitting: false,
    isDesktop: true,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: true,
    mapPropsToValues: () => ({ newPassword: "", newPasswordConfirmation: "" }),
    validationSchema: () =>
        Yup.lazy((values) => {
            if (values.newPassword === undefined || values.newPasswordConfirmation === undefined) {
                return Yup.object().shape({
                    newPassword: Yup.string().required(i18n.get(`settings.changePassword.password.required`)),
                    newPasswordConfirmation: Yup.string().required(
                        i18n.get(`settings.changePassword.newPassword.required`),
                    ),
                });
            }
            return Yup.object().shape({
                newPassword: Yup.string()
                    .min(
                        config.getInteger("core.password.minLength", 8),
                        i18n.get(`settings.changePassword.modify.error.minLength`),
                    )
                    .max(
                        config.getInteger("core.password.maxLength", 20),
                        i18n.get(`settings.changePassword.modify.error.maxLength`),
                    )
                    .matches(
                        new RegExp(config.get("core.userconfiguration.updateuserpassword.pattern")),
                        i18n.get(`settings.changePassword.modify.error.mustBeAlphanumeric`),
                    ),
                newPasswordConfirmation: Yup.string()
                    .min(
                        config.getInteger("core.password.minLength", 8),
                        i18n.get(`settings.changePassword.modify.error.minLength`),
                    )
                    .max(
                        config.getInteger("core.password.maxLength", 20),
                        i18n.get(`settings.changePassword.modify.error.maxLength`),
                    )
                    .oneOf([values.newPassword], i18n.get(`settings.changePassword.modify.error.passwordMustMatch`)),
            });
        }),
    handleSubmit: (values, formikBag) => {
        Mixpanel.track(FORM_ID);

        formikBag.props.dispatch(
            recoveryPasswordActions.recoveryPassAndUserpassStep3(
                values.newPassword,
                values.newPasswordConfirmation,
                formikBag.props.exchangeToken,
                formikBag.props.resetCode,
                formikBag,
            ),
        );
    },
})(Step3Content);
