/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import * as i18nUtils from "util/i18n";
import { bool, shape, string } from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";

import InfoTag from "pages/_components/InfoTag";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedRate from "pages/_components/FormattedRate";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class Deposit extends Component {
    static propTypes = {
        deposit: shape({}).isRequired,
        productType: string.isRequired,
        isDesktop: bool.isRequired,
    };

    productType = () => {
        const { productType } = this.props;
        if (productType === "PA") {
            return i18nUtils.get("deposits.list.item.title.pa");
        }

        return i18nUtils.get("deposits.list.item.title.pi");
    };

    render() {
        const { deposit, isDesktop } = this.props;

        const {
            currency,
            dueDate,
            nextPaymentAmount,
            idProduct,
            productType,
            depositNumber,
            automaticRenewal,
            rate,
            consolidatedAmount,
            productAlias,
        } = deposit;

        const name = productAlias || depositNumber;
        const path = `deposits/${idProduct}`;
        const expirationText = i18nUtils.get("deposits.expiration.label");
        const nextPaymentAmountLabel = i18nUtils.get("deposits.information.nextPaymentAmount");
        const consolidatedAmountLabel = i18nUtils.get("deposits.information.consolidatedAmount");
        const rateShortLabel = i18nUtils.get("deposits.information.rate.short");
        const dueDateFormatted = moment(dueDate.toString().replace(".000Z", "")).format("DD/MM/YYYY");
        const certificate = i18nUtils.get("deposits.information.certificate.number");
        const description = i18nUtils.get("deposits.information.description");

        if (isDesktop) {
            return (
                <>
                    <CardContainer className="mt-0" renderAs={Link} to={path}>
                        <Card
                            className="mb-0"
                            title={
                                <>
                                    {dueDate && (
                                        <div className="deposit__card-title">
                                            <div className="deposit__date">
                                                <div className="deposit__date-info">
                                                    <span>{expirationText}</span>
                                                </div>
                                                <div className="deposit__date-data">
                                                    <span>{dueDateFormatted}</span>
                                                </div>
                                            </div>
                                            {automaticRenewal && (
                                                <div className="deposit__info-tag">
                                                    <InfoTag
                                                        type="default"
                                                        message="auto"
                                                        color="#072229"
                                                        tagBackground="#84EFD0"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            }
                            content={
                                <div className="deposit__card-content">
                                    <div className="deposit__invested">
                                        <div className="deposit__invested-info">
                                            <span>{consolidatedAmountLabel}</span>
                                        </div>
                                        <div className="deposit__invested-data">
                                            <FormattedAmount
                                                quantity={consolidatedAmount}
                                                currency={currency}
                                                notBold
                                            />
                                        </div>
                                    </div>
                                    <div className="deposit__tna">
                                        <div className="deposit__tna-info">
                                            <span>{rateShortLabel}</span>
                                        </div>
                                        <div className="deposit__tna-data">
                                            <FormattedRate rate={rate} symbolToTheLeft={false} />
                                        </div>
                                    </div>
                                </div>
                            }
                            bottom={
                                <div className="deposit__card-bottom">
                                    <div className="deposit__product">
                                        <span>{productType}</span>
                                    </div>
                                    <div className="deposit__certificate">
                                        <div className="deposit__certificate-info">
                                            <span>{certificate}</span>
                                        </div>
                                        <div className="deposit__certificate-data">
                                            <span>{name}</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            rightContent={
                                <div className="deposit__card-right-content">
                                    <div className="deposit__amount">
                                        <span className="deposit__amount-info">{nextPaymentAmountLabel}</span>
                                        <FormattedAmount
                                            className="deposit__amount-data"
                                            quantity={nextPaymentAmount}
                                            currency={currency}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        }

        return (
            <>
                <CardContainer renderAs={Link} to={path} className="mt-0">
                    <Card
                        className="mt-0 mx-3 mb-1"
                        hrClass="ml-0"
                        title={
                            <>
                                {dueDate && (
                                    <div className="deposit__card-title">
                                        <div className="d-flex">
                                            <div className="deposit__date">
                                                <div className="deposit__date-info">
                                                    <span>{expirationText}</span>
                                                </div>
                                                <div className="deposit__date-data">
                                                    <span>{dueDateFormatted}</span>
                                                </div>
                                            </div>
                                            {automaticRenewal && (
                                                <div className="deposit__info-tag">
                                                    <InfoTag
                                                        type="default"
                                                        message="auto"
                                                        color="#072229"
                                                        tagBackground="#84EFD0"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <hr className={automaticRenewal ? "w-100 ml-0" : "w-100 ml-0 mt-1"} />
                                    </div>
                                )}
                            </>
                        }
                        content={
                            <div className="deposit__card-content">
                                <div className="deposit__invested">
                                    <div className="deposit__invested-info">
                                        <span>{consolidatedAmountLabel}</span>
                                    </div>
                                    <div className="deposit__invested-data">
                                        <FormattedAmount notBold quantity={consolidatedAmount} currency={currency} />
                                    </div>
                                </div>
                                <div className="deposit__tna">
                                    <div className="deposit__tna-info">
                                        <span>{rateShortLabel}</span>
                                    </div>
                                    <div className="deposit__tna-data">
                                        <FormattedRate rate={rate} symbolToTheLeft={false} />
                                    </div>
                                </div>
                                <div className="deposit__product">
                                    <div className="deposit__product-info">
                                        <span>{description}</span>
                                    </div>
                                    <div className="deposit__product-data">
                                        <span>{productType}</span>
                                    </div>
                                </div>
                                <div className="deposit__certificate">
                                    <div className="deposit__certificate-info">
                                        <span>{certificate}</span>
                                    </div>
                                    <div className="deposit__certificate-data">
                                        <span>{name}</span>
                                    </div>
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="deposit__card-right-content">
                                <div className="deposit__amount">
                                    <span className="deposit__amount-info">{nextPaymentAmountLabel}</span>
                                    <FormattedAmount
                                        className="deposit__amount-data"
                                        quantity={nextPaymentAmount}
                                        currency={currency}
                                    />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            </>
        );
    }
}

export default Deposit;
