import React, { Component, Fragment } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { func, shape } from "prop-types";
import { connect } from "react-redux";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import SettingsOption from "pages/settings/_components/SettingsOption";

class DocumentsIndex extends Component {
    static propTypes = {
        history: shape(func).isRequired,
    };

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    backButtonAction = () => {
        const { history } = this.props;

        history.goBack();
    };

    render() {
        return (
            <Fragment>
                <Notification scopeToShow="settings" />
                <Head title="settings.options.documents.termsAndConditions" onClose={this.backButtonAction} />

                <MainContainer>
                    <div className="above-the-fold tight-containers" ref={this.containerRef}>
                        <section className="container--layout flex-grow align-items-center">
                            <Grid className="form-content">
                                <Row className="justify-content-center">
                                    <Col md={6} sm={12}>
                                        <p className="text-lead">
                                            <I18n id="settings.options.documents.termsAndConditions.subtitle" />
                                        </p>
                                        <ul className="navigational-list">
                                            <SettingsOption
                                                linkTo="/settings/esign/documentsSigned"
                                                messageKey="settings.options.documents.termsAndConditions.viewOrDownload"
                                            />
                                        </ul>
                                    </Col>
                                </Row>
                            </Grid>
                        </section>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default connect(null)(DocumentsIndex);
