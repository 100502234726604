import React, { Component } from "react";
import { array, bool, func, oneOfType, shape, string } from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import classNames from "classnames";

import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";

import EnableContributor from "pages/enabledContributors/_components/EnableContributor";
import EnableContributorDelete from "pages/enabledContributors/_components/EnableContributorDelete";
import EnabledContributor from "pages/enabledContributors/_components/EnabledContributor";
import Container from "pages/_components/Container";
import ContextMenu from "pages/_components/ContextMenu";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Table from "pages/_components/Table";

import DrawerModal from "pages/_components/drawer/DrawerModal";

class EnabledContributorsList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: oneOfType([string, bool]),
        enabledContributors: oneOfType([array]),
        isDesktop: bool.isRequired,
        showDeletePage: bool.isRequired,
        history: shape({}).isRequired,
        isReturnFromDetail: bool.isRequired,
    };

    static defaultProps = {
        fetching: false,
        enabledContributors: [],
    };

    state = {
        selectedEnabledContributor: null,
        showDrawer: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(paymentsAFIPActions.listEnabledContributors());
    }

    handleBack = () => {
        const { dispatch, showDeletePage, isReturnFromDetail } = this.props;
        if (showDeletePage) {
            dispatch(paymentsAFIPActions.hideDeleteEnabledContributor());
        } else {
            dispatch(paymentsAFIPActions.setIsBackFromOtherPage(isReturnFromDetail));
        }
    };

    enableContributorAction = () => {
        this.setState({
            showDrawer: true,
        });
    };

    rightContent = () => {
        const { isDesktop } = this.props;

        if (isDesktop) {
            return (
                <>
                    <ButtonDrawer
                        buttonClassName="btn btn-outline cmf-button-width"
                        label="payments.enabledContributors.list.enable"
                        content={<EnableContributor {...this.props} />}
                        type="submit"
                        closeButton
                        headerContent={
                            !isDesktop && (
                                <div className="title-account-header-multiline-background-blue">
                                    <h1 className="w-100 m-0">
                                        <I18n id="payments.enabledContributors.list.enable.title" />
                                    </h1>
                                </div>
                            )
                        }
                        width="485px"
                    />
                </>
            );
        }

        return (
            <ContextMenu
                styleContext={{ justifyContent: "flex-end" }}
                items={[
                    {
                        label: "payments.enabledContributors.list.enable",
                        onClick: () => this.enableContributorAction(),
                    },
                ]}
            />
        );
    };

    renderHeader = () => {
        const { isDesktop, showDeletePage } = this.props;
        return (
            <>
                <Notification scopeToShow="enabledContributors" />
                <div className="admin-detail-head px-0">
                    {isDesktop && <Head onBack={this.handleBack} onClose={showDeletePage ? this.handleBack : null} />}
                    <Head
                        title={
                            !showDeletePage
                                ? "payments.enabledContributors.list.title"
                                : "payments.enabledContributors.delete.title"
                        }
                        onBack={!isDesktop ? this.handleBack : null}
                        titleClassName="align-center"
                        headerClassName={classNames({
                            "blue-main-header-mobile": !isDesktop,
                        })}
                        rightContent={!showDeletePage ? this.rightContent : null}
                    />
                </div>
            </>
        );
    };

    showDeleteForm = (cuit, reference) => {
        const { dispatch } = this.props;

        this.setState({ selectedEnabledContributor: { cuit, reference } });

        dispatch(paymentsAFIPActions.showDeleteEnabledContributor());
    };

    renderDelete() {
        const { selectedEnabledContributor } = this.state;
        const { cuit, reference } = selectedEnabledContributor;

        return <EnableContributorDelete cuit={cuit} reference={reference} />;
    }

    renderList() {
        const { enabledContributors, isDesktop } = this.props;

        const list = enabledContributors.map((enabledContributor) => {
            if (isDesktop) {
                return (
                    <Table.Row className="container-white" style={{ height: "40px" }}>
                        <EnabledContributor
                            enabledContributor={enabledContributor}
                            isDesktop={isDesktop}
                            showDeleteForm={this.showDeleteForm}
                        />
                    </Table.Row>
                );
            }

            return (
                <Table.Row className={`container-white without-border-bottom ${!isDesktop && "my-2"}`}>
                    <EnabledContributor
                        enabledContributor={enabledContributor}
                        isDesktop={isDesktop}
                        showDeleteForm={this.showDeleteForm}
                    />
                </Table.Row>
            );
        });

        const renderTable = isDesktop ? (
            <Table>
                <Table.Header className="container-white">
                    <Table.HeaderData align="center">
                        <I18n id="payments.enabledContributors.list.cuit" />
                    </Table.HeaderData>
                    <Table.HeaderData align="center">
                        <I18n id="payments.enabledContributors.list.reference" />
                    </Table.HeaderData>
                    <Table.HeaderData />
                </Table.Header>
                <Table.Body>{list}</Table.Body>
            </Table>
        ) : (
            list
        );

        return (
            <Container
                className={`flex-grow scrollable h-100 ${!isDesktop &&
                    !enabledContributors.length &&
                    "no-results-container"}`}
                rowClassName={`${!isDesktop && "container-mobile-full-height"} ${!enabledContributors.length &&
                    "container-empty-results"}`}
                gridClassName="h-100">
                <Col className="col col-12 px-0">
                    <div
                        style={{ borderSpacing: "0px 2px", width: "100%" }}
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {enabledContributors.length ? (
                            renderTable
                        ) : (
                            <div className="text-center no-more-data">
                                <div className="illustration-wrapper">
                                    <Image src="images/coloredIcons/find-in-page.svg" className="svg-big-icon" />
                                </div>
                                <p className="text-lead" style={{ opacity: 1 }}>
                                    <I18n id="payments.enabledContributors.list.empty" />
                                </p>
                            </div>
                        )}
                    </div>
                </Col>
            </Container>
        );
    }

    render = () => {
        const { enabledContributors, fetching, showDeletePage, isDesktop } = this.props;
        const { showDrawer } = this.state;

        const isLoading = fetching && !enabledContributors.length;

        return (
            <>
                {showDrawer && !isDesktop && (
                    <DrawerModal
                        width="100%"
                        showDrawerRemote={showDrawer}
                        headerClassName="blue-main-header-mobile"
                        closeRemotely={() => this.setState({ showDrawer: false })}
                        backButton
                        title="payments.enabledContributors.list.enable.title"
                        content={<EnableContributor {...this.props} />}
                    />
                )}
                {!isLoading && this.renderHeader()}
                <MainContainer showLoader={isLoading}>
                    <div role="menu" className={classNames("table-body")}>
                        {!showDeletePage ? this.renderList() : this.renderDelete()}
                    </div>
                </MainContainer>
            </>
        );
    };
}

const mapStateToProps = (state) => ({
    enabledContributors: paymentsAFIPSelectors.getEnabledContributors(state),
    fetching: paymentsAFIPSelectors.getFetching(state),
    showDeletePage: paymentsAFIPSelectors.getShowDeletePage(state),
    isReturnFromDetail: paymentsAFIPSelectors.getIsReturnFromDetail(state),
});

export default connect(mapStateToProps)(EnabledContributorsList);
