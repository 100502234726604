import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import { makeActionCreator } from "util/redux";
import { globalTypes } from "reducers/types/global";
import { types as settingsTypes } from "reducers/settings";
import { types as loginTypes } from "reducers/login";
import * as configUtils from "util/config";
import { ENVIRONMENT_TYPE } from "constants.js";

export const types = {
    CHANGE_ENVIRONMENT_REQUEST: "session/CHANGE_ENVIRONMENT_REQUEST",
    CHANGE_ENVIRONMENT_FAILURE: "session/CHANGE_ENVIRONMENT_FAILURE",
    CHANGE_ENVIRONMENT_SUCCESS: "session/CHANGE_ENVIRONMENT_SUCCESS",

    UPDATE_USER: "session/UPDATE_USER",

    LOGOUT_REQUEST: "session/LOGOUT_REQUEST",

    SET_TOKENS: "session/SET_TOKENS",

    EXTEND: "session/EXTEND",
    EXTEND_SUCCESS: "session/EXTEND_SUCCESS",
    EXPIRE: "session/EXPIRE",

    LOCATION_REQUESTED: "session/LOCATION_REQUESTED",
    UPDATE_PENDINGACTIONS_PEP: "session/PENDING_ACTIONS_PEP",
    UPDATE_PENDINGACTIONS_IRS: "session/PENDING_ACTIONS_IRS",

    CHANGE_FETCHING_FINALLY: "session/CHANGE_FETCHING_FINALLY",
};

export const INITIAL_STATE = {
    activeEnvironment: null,
    environments: {},
    user: null,
    fetching: false,
    isLocationRequested: false,
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.LOCATION_REQUESTED:
            return {
                ...state,
                isLocationRequested: true,
            };
        case globalTypes.CLEAN_UP:
        case loginTypes.INIT_LOGIN_FLOW:
        case types.LOGOUT_REQUEST:
            return INITIAL_STATE;

        case loginTypes.LOGIN_SUCCESS:
            return {
                ...state,
                activeEnvironment: action.environment,
                environments: action.environments,
                user: action.user,
                isAdministrator: action.isAdministrator,
            };
        case types.UPDATE_PENDINGACTIONS_PEP:
            return {
                ...state,
                user: {
                    ...state.user,
                    pepCompleted: true,
                },
            };
        case types.UPDATE_PENDINGACTIONS_IRS:
            return {
                ...state,
                user: {
                    ...state.user,
                    irsCompleted: true,
                },
            };
        case loginTypes.MARK_ENVIRONMENTS_DISABLED:
            return {
                ...state,
                environments: action.environments,
            };
        case loginTypes.FINGERPRINT_LOGIN_PRE_SUCCESS:
            return { ...state, activeEnvironment: action.environment };
        case types.CHANGE_ENVIRONMENT_REQUEST:
            return { ...state, fetching: true };
        case types.CHANGE_ENVIRONMENT_FAILURE:
            return { ...state, fetching: false };
        case types.CHANGE_ENVIRONMENT_SUCCESS:
            return {
                ...state,
                fetching: false,
                activeEnvironment: action.environment,
                environments: action.environments,
                isAdministrator: action.isAdministrator,
                user: {
                    ...state.user,
                    liberator: action.liberator,
                    signatureLevel: action.signatureLevel,
                },
            };
        case types.SET_TOKENS:
            return { ...state, accessToken: action.accessToken, refreshToken: action.refreshToken };
        case settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    securitySeal: action.securitySeal,
                },
            };
        case settingsTypes.CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    idDefaultEnvironment: action.idDefaultEnvironment,
                },
            };
        case types.UPDATE_USER:
            return {
                ...state,
                user: { ...state.user, email: action.mail },
            };
        case types.CHANGE_FETCHING_FINALLY:
            return {
                ...state,
                fetching: false,
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        key: "session",
        storage: storageSession,
        blacklist: ["showCaptcha"],
    },
    reducer,
);

export const actions = {
    logout: () => ({
        type: types.LOGOUT_REQUEST,
    }),
    changeEnvironment: (idEnvironment, rememberEnvironment, formikBag) => ({
        type: types.CHANGE_ENVIRONMENT_REQUEST,
        idEnvironment,
        rememberEnvironment,
        formikBag,
    }),
    extend: () => ({
        type: types.EXTEND,
    }),
    expire: (lastHref) => ({
        type: types.EXPIRE,
        lastHref,
    }),
    setTokens: makeActionCreator(types.SET_TOKENS, "accessToken", "refreshToken"),
};

export const selectors = {
    getAccessToken: ({ session }) => {
        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            return session.user ? session.user.accessToken : null;
        }
        return session.accessToken;
    },
    isLoggedIn: ({ session }) => {
        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            return session.user && !!session.user.accessToken;
        }
        return !!session.accessToken;
    },
    getUser: ({ session }) => session.user,
    getUserId: ({ session }) => session.user.userId,
    getUsername: ({ session }) => (session.user ? session.user.email : null),
    getUserFullName: ({ session }) => (session.user ? session.user.userFullName : ""),
    getEnvironments: ({ session }) => session.environments,
    getActiveEnvironment: ({ session }) => session.activeEnvironment,
    getActiveEnvironmentForms: ({ session }, idForm) => session.activeEnvironment.forms[idForm] || [],
    hasForm: ({ session }, category, idForm) =>
        session.activeEnvironment.forms[category] &&
        !!session.activeEnvironment.forms[category].find((f) => f.idForm === idForm),
    getUserSecuritySeal: ({ session }) => (session.user ? session.user.securitySeal : null),
    isFetching: ({ session }) => session.fetching,
    isAdministrator: ({ session }) => session.isAdministrator,
    getAdministrationScheme: ({ session }) => session.activeEnvironment.administrationScheme,
    isRetailEnvironment: ({ session }) => session.activeEnvironment?.type === ENVIRONMENT_TYPE.RETAIL,
    hasPermissions: ({ session }, permissions) =>
        !permissions ||
        !permissions.find(
            (permission) => Object.keys(session.activeEnvironment.permissions).indexOf(permission) === -1,
        ),
};
