import React, { Component, Fragment } from "react";
import { func, shape, bool, string, node } from "prop-types";

import Navbar from "react-bootstrap/lib/Navbar";

import { connect } from "react-redux";

import { Link, Route, withRouter } from "react-router-dom";
import { goBack } from "react-router-redux";
import PageTransition from "react-router-page-transition";
import classNames from "classnames";

import { actions as loginActions } from "reducers/login";

import { getTransition } from "util/transition";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";

class EnrollmentLayout extends Component {
    state = { transition: "", backgroundColor: "#f9f9f9" };

    static propTypes = {
        location: shape({
            pathname: string,
        }).isRequired,
        dispatch: func.isRequired,
        component: node.isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        isDesktop: bool.isRequired,
        showBack: bool,
        mustGobackWithClose: bool,
        camera: string,
        transition: string,
    };

    static defaultProps = {
        showBack: false,
        mustGobackWithClose: false,
        camera: null,
        transition: "transition-drill-in",
    };

    componentDidMount() {
        const { isMobileNative } = this.props;
        const { backgroundColor } = this.state;

        if (isMobileNative) {
            const deviceHeight = window.innerHeight;

            this.setState({
                styles: {
                    position: "absolute",
                    top: 0,
                    height: deviceHeight,
                },
            });
        }

        document.body.style.backgroundColor = backgroundColor;
    }

    componentDidUpdate() {
        const { camera, isMobileNative } = this.props;
        const { backgroundColor } = this.state;

        let bodyColor = backgroundColor;

        if (camera && isMobileNative) {
            bodyColor = "transparent";
        }

        document.body.style.backgroundColor = bodyColor;
    }

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = this.props;
        if (location.pathname !== nextProps.location.pathname) {
            this.setState({
                transition: nextProps.transition || getTransition(this.props, nextProps),
            });
        }
    }

    handleClick = () => {
        const { dispatch, mustGobackWithClose } = this.props;
        if (mustGobackWithClose) {
            dispatch(goBack());
        } else {
            dispatch(loginActions.reset());
        }
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    getMobileLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative, camera } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative, camera };
        const { transition, styles } = this.state;

        return (
            <div className={transition} style={styles}>
                <PageTransition timeout={600}>
                    <div
                        className={classNames("view-wrapper theme-auth transition-item enrollment-layout", {
                            "camera-layout": camera,
                            "camera-layout--landscape": camera === "landscape",
                        })}>
                        <ErrorBoundary>
                            <ReceivedComponent {...matchProps} {...extras} />
                        </ErrorBoundary>
                    </div>
                </PageTransition>
            </div>
        );
    };

    getDesktopLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative, showBack } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        return (
            <ErrorBoundary>
                <div className="app theme-auth enrollment-layout">
                    <header className="app-header">
                        <Navbar collapseOnSelect fluid className="enrollment-header">
                            <Navbar.Header>
                                <div className="toolbar">
                                    {showBack && (
                                        <div className="toolbar-item toolbar-item--fixed toolbar-item-left">
                                            <Button
                                                block={false}
                                                className="btn-only-icon btn-circle enrollment-button"
                                                bsStyle="link"
                                                onClick={this.onHeaderBack}
                                                image="images/arrowLeftMobile.svg"
                                                label="global.back"
                                            />
                                        </div>
                                    )}

                                    <div className="toolbar-item view-title">
                                        <Link className="navbar-brand" to="/desktop">
                                            <Image src="images/logoCompany.svg" className="header__logo" />
                                            <I18n
                                                id="global.companyName"
                                                componentProps={{ className: "visually-hidden" }}
                                            />
                                        </Link>
                                    </div>
                                    <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                                        <Button
                                            block={false}
                                            className="btn-only-icon btn-circle enrollment-button"
                                            bsStyle="link"
                                            label="global.close"
                                            image="images/cross-mobile-white.svg"
                                            onClick={this.handleClick}
                                        />
                                    </div>
                                </div>
                            </Navbar.Header>
                        </Navbar>
                    </header>

                    <div className="app-page">
                        <div className="app-content">
                            <div className="view-wrapper theme-auth enrollment-component">
                                <ReceivedComponent {...matchProps} {...extras} />
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(matchProps) => {
                    const { isMobile } = this.props;

                    if (isMobile) {
                        return <Fragment>{this.getMobileLayout(matchProps)}</Fragment>;
                    }

                    return <Fragment>{this.getDesktopLayout(matchProps)}</Fragment>;
                }}
            />
        );
    }
}

export default withRouter(connect()(resizableRoute(EnrollmentLayout)));
