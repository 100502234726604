import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, func, bool } from "prop-types";

import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";

import Compose from "pages/communications/_components/Compose";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";

class ReplyMessage extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        match: shape().isRequired,
        detail: shape().isRequired,
        history: shape().isRequired,
        isDesktop: bool.isRequired,
    };

    componentDidMount() {
        const { match, dispatch } = this.props;

        dispatch(communicationActions.detailRequest(match.params.id));
    }

    handleBackClick = () => {
        const { history } = this.props;

        history.goBack();
    };

    render() {
        const { detail, isDesktop } = this.props;

        if (!detail) {
            return null;
        }

        return (
            <>
                <Head
                    titleText={detail[0].subject}
                    onBack={this.handleBackClick}
                    closeLinkTo="/communications"
                    closeImageWhite
                    titleClassName={!isDesktop && "header-mobile-title-background-blue"}
                    headerClassName={!isDesktop && "blue-main-header-mobile"}
                />
                <MainContainer>
                    <Compose isReply />
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    detail: communicationSelectors.detail(state),
});

export default connect(mapStateToProps)(ReplyMessage);
