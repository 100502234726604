import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool, string } from "prop-types";
import { actions as transactionsActions } from "reducers/transactions";
import TransactionScheme from "pages/transactions/TransactionScheme";
import TransactionSchemeMobile from "pages/transactions/TransactionSchemeMobile";

class GlobalTransactionScheme extends Component {
    static propTypes = {
        idTransaction: string.isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        showDrawerMobile: bool.isRequired,
        closeMobileDrawer: func.isRequired,
    };

    componentDidMount() {
        const { idTransaction, dispatch } = this.props;
        dispatch(transactionsActions.getPotentialSignaturesData(idTransaction));
    }

    render() {
        const { isDesktop, showDrawerMobile, closeMobileDrawer } = this.props;

        return (
            <>
                {isDesktop ? (
                    <TransactionScheme />
                ) : (
                    <TransactionSchemeMobile
                        showDrawerMobile={showDrawerMobile}
                        closeMobileDrawer={closeMobileDrawer}
                    />
                )}
            </>
        );
    }
}

export default connect()(GlobalTransactionScheme);
