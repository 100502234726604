import * as fileMiddleware from "middleware/file";
import {
    downloadBatchDetailRequest,
    loadMultipleTransfers,
    multipleTransfersPre,
    multipleTransfersPreview,
    multipleTransfersSend,
    readBatch,
} from "middleware/multiple-transfers";
import { actions as formActions } from "reducers/form";
import { call, put, take, takeLatest } from "redux-saga/effects";

import { push } from "react-router-redux";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { types } from "reducers/multiple-transfers";
import { actions as notificationActions } from "reducers/notification";
import * as downloadUtil from "util/download";
import * as i18n from "util/i18n";

const sagas = [
    takeLatest(types.LOAD_MULTIPLE_TRANSFERS_LIST_REQUEST, loadMultipleTransfersPre),
    takeLatest(types.CREATE_MULTIPLE_TRANSFERS_PRE_REQUEST, multipleTransfersForm),
    takeLatest(types.CREATE_MULTIPLE_TRANSFERS_PREVIEW_REQUEST, createMultipleTransfersPreview),
    takeLatest(types.GET_FILE_CONTENTS_REQUEST, getFileContentsRequest),
    takeLatest(types.CREATE_MULTIPLE_TRANSFERS_SEND_REQUEST, createMultipleTransfersSend),
    takeLatest(types.GET_TRANSFER_MULTIPLE_BY_ID_REQUEST, getMultipleTransferById),
    takeLatest(types.DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_REQUEST, downloadBatchDetail),
];

export default sagas;

function* loadMultipleTransfersPre({ filters, isDesktop }) {
    const response = yield call(loadMultipleTransfers, filters);

    if (response.type === "W") {
        yield put({ type: types.LOAD_MULTIPLE_TRANSFERS_LIST_FAILURE });
    } else {
        yield put({
            type: types.LOAD_MULTIPLE_TRANSFERS_LIST_SUCCESS,
            list: response.data.data.list,
            pageNumber: response.data.data.pageNumber,
            totalPages: response.data.data.totalPage,
            statusList: response.data.data.statusList,
            isDesktop,
        });
    }
}

function* multipleTransfersForm() {
    const response = yield call(multipleTransfersPre);

    if (response.type === "W") {
        const { NO_FIELD } = response.data.data;
        if (response.data.code === "COR020W") {
            if (response.data.data?.message) {
                yield put(
                    notificationActions.showNotification(response.data.data.message, "error", [
                        "multipleTransfer.list",
                    ]),
                );
            } else if (NO_FIELD) {
                yield put(notificationActions.showNotification(NO_FIELD, "warning", ["multipleTransfer.list"]));
                yield put(push("/transfer/multiple"));
            }
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["multipleTransfer.list"]));
        }
        yield put({ type: types.CREATE_MULTIPLE_TRANSFERS_PRE_FAILURE });
    } else {
        yield put(push("/transfer/multiple/create"));
        yield put({
            type: types.CREATE_MULTIPLE_TRANSFERS_PRE_SUCCESS,
            accounts: response.data.data.accounts,
        });
    }
}

function* createMultipleTransfersPreview({ summary, formikBag }) {
    try {
        const response = yield call(multipleTransfersPreview, summary);

        if (response.type === "W") {
            const { message, NO_FIELD } = response.data.data;
            if (response.data.code === "COR020W") {
                if (message) {
                    yield put(notificationActions.showNotification(message, "error", ["form"]));
                } else if (NO_FIELD) {
                    yield put(push("/transfer/multiple"));
                    yield put(notificationActions.showNotification(NO_FIELD, "warning", ["multipleTransfer.list"]));
                }
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["form"]));
            }
            yield put({ type: types.CREATE_MULTIPLE_TRANSFERS_PREVIEW_FAILURE, summary });
        } else {
            const { batchId, control } = response.data.data;
            yield put({
                type: types.CREATE_MULTIPLE_TRANSFERS_PREVIEW_SUCCESS,
                summary: { ...summary, batchId, control },
            });

            yield put(push("/transfer/multiple/confirm"));
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

const fetchBlobData = async (url) => {
    let responseBlob;
    try {
        const response = await fetch(url);
        responseBlob = await response.blob();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
    return responseBlob;
};

function* getFileContentsRequest({ idFile, deleteFile, idUserCreator }) {
    let fileResponse;
    let blob;

    if (idUserCreator) {
        fileResponse = yield fileMiddleware.downloadStreamWithUser(idFile, idUserCreator);
        const base64 = fileResponse?.data?.data?.file?.contents;

        if (base64) {
            blob = yield fetchBlobData(`data:application/octet-stream;base64,${base64}`);
        }
    } else {
        fileResponse = yield fileMiddleware.downloadStream(idFile, null);
        const base64 = fileResponse?.data?.data?.file?.contents;

        if (base64) {
            blob = yield fetchBlobData(`data:application/octet-stream;base64,${base64}`);
        }

        blob = fileResponse.data;
    }

    const content =
        blob &&
        downloadUtil
            .getBlobContent(blob)
            .split("\n")
            .map((line, i) => {
                const [document, cbu, amount, concept, reference, currency, conceptDescription] = line.split(",");
                return {
                    document,
                    cbu,
                    amount: amount?.replace(".", ","),
                    currency,
                    concept,
                    conceptDescription,
                    reference,
                    lineNumber: i + 1,
                };
            });
    if (deleteFile) {
        yield fileMiddleware.deleteFile(Number(idFile));
    }

    content.pop();
    yield put(transactionLinesActions.getFileContentsSuccess(content || []));
    yield put({ type: types.GET_FILE_CONTENTS_SUCCESS });
}

function* createMultipleTransfersSend({ otp, summary, formikBag }) {
    try {
        const response = yield call(multipleTransfersSend, otp, summary);
        if (response.type === "W") {
            const { message, NO_FIELD } = response.data.data;
            if (response.data.code === "COR020W") {
                if (message) {
                    yield put(notificationActions.showNotification(message, "error", ["form"]));
                } else if (NO_FIELD) {
                    yield put(push("/transfer/multiple"));
                    yield put(notificationActions.showNotification(NO_FIELD, "warning", ["multipleTransfer.list"]));
                }
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["form"]));
            }
            yield put({ type: types.CREATE_MULTIPLE_TRANSFERS_PREVIEW_FAILURE, summary });
        } else {
            yield put(
                formActions.readTransaction({
                    pathname: `/transaction/${response.data.idTransaction}`,
                    newTransaction: true,
                }),
            );
            yield take("READ_TRANSACTION_REQUEST");
        }
    } finally {
        formikBag.setSubmitting(false);
    }
}

function* getMultipleTransferById({ id, pageNumberDetail, status, isDesktop }) {
    const response = yield call(readBatch, id, pageNumberDetail, status);

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            yield put(push("/transfer/multiple"));
            yield put(
                notificationActions.showNotification(response.data.data?.message, "error", ["multipleTransfer.list"]),
            );
        }
        yield put({ type: types.GET_TRANSFER_MULTIPLE_BY_ID_FAILURE });
    } else {
        yield put({
            type: types.GET_TRANSFER_MULTIPLE_BY_ID_SUCCESS,
            transfer: response.data.data?.transferBatch,
            batchDetail: response.data.data?.batchDetail,
            totalPagesDetail: response.data.data?.totalPage,
            pageNumberDetail: response.data.data?.pageNumber,
            bankConcepts: response.data.data?.bankConcepts,
            batchErrors: response.data.data?.batchErrors,
            isDesktop,
        });
    }
}

function* downloadBatchDetail({ batchId, statusFilter, fileName }) {
    try {
        const { type, data } = yield call(downloadBatchDetailRequest, batchId, statusFilter);

        if (type === "W") {
            yield put({ type: types.DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_FAILURE });
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "multipleTransfer.detail",
                ]),
            );
        } else {
            const { content } = data.data;
            const xlsFileName = `${fileName.split(".")[0]}.xls`;
            downloadUtil.downloadXls(xlsFileName, content);
            yield put({ type: types.DOWNLOAD_MULTIPLE_TRANSFER_DETAIL_SUCCESS });
            yield put(
                notificationActions.showNotification(i18n.get("global.download.success"), "success", [
                    "multipleTransfer.detail",
                ]),
            );
        }
    } catch (error) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "multipleTransfer.detail",
            ]),
        );
    }
}
