import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { func, shape, boolean } from "prop-types";
import { signatureTicketSelectors, detailsSelectors } from "reducers/administration";
import { signatureTicketActions } from "reducers/administration/medium";
import PageLoading from "pages/_components/PageLoading";
import SignatureTicket from "pages/administration/_components/tickets/SignatureTicket";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";

class SignatureTicketTicketContent extends Component {
    static propTypes = {
        actions: func.isRequired,
        match: shape({}).isRequired,
        fetching: boolean,
    };

    static defaultProps = {
        fetching: false,
    };

    componentDidMount() {
        const { actions, match } = this.props;
        actions.loadSignatureTicketRequest(match.params.idTransaction);
    }

    render() {
        const { fetching } = this.props;

        return (
            <PageLoading loading={fetching}>
                <AdministrationTicket {...this.props}>
                    <SignatureTicket {...this.props} />
                </AdministrationTicket>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    user: detailsSelectors.getUser(state),
    hasSignatureEnabled: signatureTicketSelectors.getSignatureLevel(state) !== undefined,
    fetching: detailsSelectors.isFetching(state),
    schemeName: "medium",
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(signatureTicketActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignatureTicketTicketContent);
