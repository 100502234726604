import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { func, bool } from "prop-types";
import classNames from "classnames";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import * as i18n from "util/i18n";

class WrongEnvironmentModal extends Component {
    static propTypes = {
        isVisible: bool.isRequired,
        closeModal: func.isRequired,
        isDesktop: bool.isRequired,
        environment: String.isRequired,
    };

    render() {
        const { isVisible, closeModal, isDesktop, environment } = this.props;

        return (
            <Modal
                show={isVisible}
                className={classNames({ "transfer-template-modal": isDesktop, "d-flex": !isDesktop })}
                dialogClassName={isDesktop ? "justify-content-center" : "justify-content-end"}
                onHide={closeModal}>
                <Modal.Body className={classNames("mt-0 pb-2 p-4 d-flex flex-column", { "max-width-700": isDesktop })}>
                    <div className="mb-2">
                        <h3 className="font-bold text-blue text-uppercase d-flex align-items-center my-0">
                            <Image
                                src="images/transactionStatusIcons/alert.svg"
                                className="svg-icon invert-colors py-2 pr-2"
                                wrapperClassName="svg-wrapper w-auto"
                            />
                            {i18n.get(`transfer.TAD.warning.title`)}
                        </h3>
                        <p className="text-left">
                            {i18n.get(`echeq.news.DEFAULT.link.warning`).replace("#{ENVIRONMENT}", environment)}
                        </p>
                    </div>
                    <div className="d-flex justify-content-right w-100">
                        <Button
                            onClick={closeModal}
                            type="submit"
                            bsStyle="link"
                            label="global.accept"
                            className={!isDesktop ? "w-auto" : ""}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default WrongEnvironmentModal;
