import React, { Component, Fragment } from "react";
import { node, func, bool, string } from "prop-types";

import SwipeableBottomSheet from "react-swipeable-bottom-sheet";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

class BottomSheet extends Component {
    static propTypes = {
        children: node.isRequired,
        handleDismiss: func.isRequired,
        hasDismissButton: bool,
        isDisplayed: bool.isRequired,
        title: string,
        onChange: func.isRequired,
    };

    static defaultProps = {
        hasDismissButton: false,
        title: null,
    };

    static Content = ({ children }) => <div className="bottom-sheet-content">{children}</div>;

    openBottomSheet(isDisplayed) {
        this.setState({ isDisplayed });
    }

    toggleBottomSheet() {
        const { isDisplayed } = this.state;

        this.openBottomSheet(!isDisplayed);
    }

    render() {
        const { children, isDisplayed, handleDismiss, hasDismissButton, title, onChange } = this.props;
        return (
            <SwipeableBottomSheet open={isDisplayed} onChange={onChange} style={{ zIndex: 1000 }}>
                <div className="bottom-sheet">
                    <Container className=" container--layout align-items-center">
                        <Container.Column sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <Fragment>
                                {(title || hasDismissButton) && (
                                    <header className="bottom-sheet-header">
                                        <h4 className="bottom-sheet-title">{title}</h4>

                                        {hasDismissButton && (
                                            <Button
                                                block={false}
                                                bsStyle="link"
                                                image="images/cross.svg"
                                                label="global.close"
                                                onClick={handleDismiss}
                                            />
                                        )}
                                    </header>
                                )}
                                {children}
                            </Fragment>
                        </Container.Column>
                    </Container>
                </div>
            </SwipeableBottomSheet>
        );
    }
}

export default BottomSheet;
