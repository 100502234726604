import React from "react";
import { string, bool } from "prop-types";

import Image from "pages/_components/Image";
import * as i18nUtils from "util/i18n";

function EnvironmentTag({ type, name, color, imageSize, className, isBlocked }) {
    return (
        <div className="environment-tag">
            <Image
                className="svg-icon svg-caret"
                src={`images/${type}.svg`}
                styles={{ fill: color, width: imageSize, height: imageSize }}
            />
            <span className="visually-hidden"> {`${type}, ${name}`} </span>
            <span
                aria-hidden
                style={imageSize ? { marginTop: "0.4rem", fontWeight: "normal" } : {}}
                className={className}>
                {name}
            </span>
            {isBlocked && (
                <span aria-hidden style={imageSize ? { marginTop: "0.4rem" } : {}} className="blocked-environment pl-3">
                    {i18nUtils.get("login.step3.environment.blocked")}
                </span>
            )}
        </div>
    );
}

EnvironmentTag.propTypes = {
    name: string.isRequired,
    type: string.isRequired,
    color: string,
    imageSize: string,
    className: string,
    isBlocked: bool,
};
EnvironmentTag.defaultProps = {
    color: "#0071CE",
    imageSize: "",
    className: "",
    isBlocked: false,
};

export default EnvironmentTag;
