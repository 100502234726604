import React, { Component, Fragment } from "react";
import { shape } from "prop-types";
import { connect } from "react-redux";

import { selectors as formSelectors } from "reducers/form";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import FormattedAmount from "pages/_components/FormattedAmount";

class TransferThirdParties extends Component {
    static propTypes = {
        previewData: shape({}).isRequired,
    };

    render() {
        const { previewData } = this.props;

        const {
            debitAmount,
            debitAccountAlias,
            creditAmount,
            reference,
            amount,
            notificationMails,
            notificationBody,
            creditAccount,
        } = previewData;

        const showDebitAmount = debitAmount.currency !== amount.currency;
        const showCreditAmount = creditAmount.currency !== amount.currency;

        return (
            <Fragment>
                <p className="text-lead">
                    <I18n id="forms.transferThirdParties.confirmation.title" />
                </p>

                <div className="transfer-block">
                    <div className="transfer-data transfer-data-highlight">
                        <span className="data-label">
                            <I18n id="forms.transferThirdParties.confirmation.amount" />{" "}
                        </span>
                        <FormattedAmount currency={amount.currency} quantity={amount.quantity} />
                    </div>

                    {showCreditAmount && (
                        <Fragment>
                            <div className="transfer-data data-wrapper">
                                <span className="data-label">
                                    <I18n id="forms.transferThirdParties.confirmation.creditAmount" />{" "}
                                </span>
                                <FormattedAmount currency={creditAmount.currency} quantity={creditAmount.quantity} />
                            </div>
                        </Fragment>
                    )}

                    {showDebitAmount && (
                        <Fragment>
                            <div className="transfer-data data-wrapper">
                                <span className="data-label">
                                    <I18n id="forms.transferThirdParties.confirmation.debitAmount" />{" "}
                                </span>
                                <FormattedAmount currency={debitAmount.currency} quantity={debitAmount.quantity} />
                            </div>
                        </Fragment>
                    )}

                    <div className="transfer-data">
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.transferThirdParties.confirmation.debitAccount" />{" "}
                            </span>
                            <span className="data-name">{debitAccountAlias}</span>
                        </div>
                        <Image src="images/arrowRight.svg" className="svg-icon" />
                        <div className="data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.transferThirdParties.confirmation.creditAccount" />{" "}
                            </span>
                            <span className="data-name">{creditAccount}</span>
                        </div>
                    </div>
                </div>
                {reference && (
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transferThirdParties.confirmation.reference" />{" "}
                        </span>
                        <span className="data-text">{reference}</span>
                    </div>
                )}
                {notificationMails && notificationMails.length > 0 && (
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transferThirdParties.confirmation.notificationMails" />{" "}
                        </span>
                        <span className="data-text">{notificationMails.map((email) => email.text).join(", ")}</span>
                    </div>
                )}
                {notificationBody && (
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transferThirdParties.confirmation.notificationBody" />{" "}
                        </span>
                        <span className="data-text">{notificationBody}</span>
                    </div>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    previewData: formSelectors.getPreviewData(state),
});

export default connect(mapStateToProps)(TransferThirdParties);
