import React, { Component } from "react";
import Select from "react-select";
import classNames from "classnames";
import { arrayOf, any, bool, func, objectOf, oneOfType, shape, string, node } from "prop-types";

import * as i18n from "util/i18n";

import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FieldError from "pages/_components/fields/FieldError";
import withFocus from "pages/_components/withFocus";

class Selector extends Component {
    static propTypes = {
        field: shape({
            name: string,
        }).isRequired,
        form: shape({
            errors: objectOf(oneOfType([string, objectOf(string)])),
            touched: objectOf(oneOfType([arrayOf(bool), bool, objectOf(bool)])),
        }).isRequired,
        idForm: string.isRequired,
        inLineControl: bool,
        onCustomChange: func.isRequired,
        customOnChange: bool,
        isFocused: bool,
        options: arrayOf(objectOf(any)).isRequired,
        renderAs: string,
        toggleIsFocused: func,
        searchable: bool,
        hideLabel: bool,
        hidePlaceholder: bool.isRequired,
        radioDisplayGrid: bool,
        customPlaceholder: string,
        formGroupClassName: string,
        isDisabled: bool,
        isDesktop: bool,
        sortByLabel: bool,
        spaceBetween: bool,
        controlLabelClassName: string,
        controlLabelChildClassName: string,
        className: string,
        errorClassName: string,
        formGroupTextClassName: string,
        buttonInfo: node,
    };

    static defaultProps = {
        isFocused: false,
        customOnChange: false,
        inLineControl: false,
        renderAs: "combo",
        toggleIsFocused: null,
        searchable: false,
        radioDisplayGrid: false,
        customPlaceholder: null,
        formGroupClassName: "",
        isDisabled: false,
        isDesktop: false,
        hideLabel: false,
        sortByLabel: false,
        spaceBetween: false,
        controlLabelClassName: "",
        controlLabelChildClassName: "",
        className: "",
        errorClassName: "",
        formGroupTextClassName: "",
        buttonInfo: null,
    };

    handleChange = (param) => {
        const { field, form, renderAs, customOnChange, onCustomChange } = this.props;
        let valueParam = param;

        if (renderAs === "combo") {
            const { value } = param;

            valueParam = value;
        }

        if (customOnChange || onCustomChange) {
            onCustomChange(valueParam, form);
        }

        form.setFieldValue(field.name, valueParam);
    };

    compareByLabel = (a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        return a.label === b.label ? 0 : 1;
    };

    renderAsRadio() {
        const {
            options,
            form,
            field,
            isFocused,
            inLineControl,
            radioDisplayGrid,
            formGroupClassName,
            spaceBetween,
        } = this.props;
        const { touched, errors } = form;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <fieldset
                className={classNames("form-group", formGroupClassName, {
                    "has-error": touched[field.name] && errors[field.name],
                    "has-focus": isFocused,
                    "display-grid-center": radioDisplayGrid,
                    "w-100": spaceBetween,
                })}>
                <RadioButtonGroup
                    inLineControl={inLineControl}
                    name={field.name}
                    onChange={this.handleChange}
                    optionClassName="needsclick"
                    options={options}
                    value={field.value}
                    spaceBetween={spaceBetween}
                />
                {hasError && <FieldError error={errors[field.name]} />}
            </fieldset>
        );
    }

    renderAsCombo() {
        const {
            form,
            field,
            isFocused,
            idForm,
            options,
            toggleIsFocused,
            searchable,
            hidePlaceholder,
            customPlaceholder,
            formGroupClassName,
            isDisabled,
            isDesktop,
            hideLabel,
            sortByLabel,
            controlLabelClassName,
            controlLabelChildClassName,
            className,
            errorClassName,
            formGroupTextClassName,
            buttonInfo,
            ...restProps
        } = this.props;
        const { touched, errors } = form;
        const { name, value } = field;
        const hasError = touched[field.name] && errors[field.name];
        const finalOptions =
            sortByLabel && Array.isArray(options) ? options.sort((a, b) => this.compareByLabel(a, b)) : options;

        return (
            <div
                className={classNames("form-group", formGroupClassName, {
                    "has-error": touched[name] && errors[name],
                    "has-focus": isFocused,
                })}>
                {!hideLabel && (
                    <div className={buttonInfo !== null ? "d-flex align-items-center" : null}>
                        <FieldLabel
                            idField={field.name}
                            labelKey={`${idForm}.${field.name}.label`}
                            isDisabled={isDisabled}
                            controlLabelClassName={controlLabelClassName}
                            controlLabelChildClassName={controlLabelChildClassName}
                            formGroupTextClassName={formGroupTextClassName}
                        />
                        {buttonInfo}
                    </div>
                )}
                <div className={`input-group ${isDisabled ? "input-group-disable" : ""}`}>
                    <Select
                        className={`slideFromBottom flex-container ${className}`}
                        searchable={searchable}
                        onChange={this.handleChange}
                        clearable={false}
                        name={name}
                        value={value}
                        options={finalOptions}
                        onBlur={toggleIsFocused}
                        onFocus={toggleIsFocused}
                        placeholder={
                            hidePlaceholder ? "" : customPlaceholder || i18n.get(`${idForm}.${field.name}.placeholder`)
                        }
                        optionClassName="needsclick"
                        disabled={isDisabled}
                        noResultsText={i18n.get(`global.no.results`)}
                        {...restProps}
                    />
                </div>
                {hasError && <FieldError error={errors[field.name]} errorClassName={errorClassName} />}
            </div>
        );
    }

    render() {
        const { renderAs } = this.props;

        if (renderAs === "combo") {
            return this.renderAsCombo();
        }
        if (renderAs === "radio") {
            return this.renderAsRadio();
        }

        return null;
    }
}

export default withFocus(Selector);
