import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, func, number, shape } from "prop-types";

import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import MainContainer from "pages/_components/MainContainer";

class ChangeSecuritySeal extends Component {
    static propTypes = {
        currentSecuritySeal: number,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        securitySeals: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        currentSecuritySeal: null,
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(settingsActions.changeSecuritySealPre());
    }

    getContent() {
        const { securitySeals, currentSecuritySeal, isDesktop } = this.props;

        return (
            <form className="above-the-fold cmf-container-white">
                <section className="container--layout align-items-center flex-grow">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={9} xl={9} className="col col-12">
                                <div className="admin-content-center">
                                    <I18n
                                        id="settings.changeSecuritySeal.title"
                                        component="h2"
                                        componentProps={isDesktop ? {} : { style: { fontSize: "1.3rem" } }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} className="col col-12 image-grid">
                                <Row>
                                    {Object.entries(securitySeals).map(([id, securitySeal]) => {
                                        const colClass = classNames(
                                            "col",
                                            "col-4",
                                            {
                                                "is-current": id === `${currentSecuritySeal}`,
                                            },
                                            {
                                                "not-current": id !== `${currentSecuritySeal}`,
                                            },
                                        );

                                        return (
                                            <Col sm={4} md={3} className={colClass} key={`securitySeal-${id}`}>
                                                <Button
                                                    className="btn-image"
                                                    id={id}
                                                    label=""
                                                    onClick={() => {
                                                        this.handleClick(id);
                                                    }}
                                                    style={{ backgroundImage: `url(${securitySeal})` }}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    }

    handleClick = (id) => {
        const { dispatch } = this.props;
        dispatch(settingsActions.changeSecuritySealConfirmationPre(id));
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0 align-center">{i18n.get("settings.changeSecuritySeal")}</h1>
        </div>
    );

    render() {
        const { fetching, isDesktop } = this.props;
        return (
            <>
                <div className="admin-detail-head px-0">
                    <Head
                        backLinkTo="/settings"
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && (
                        <div className="view-title mb-3">
                            <h1>{i18n.get("settings.changeSecuritySeal")}</h1>
                        </div>
                    )}
                </div>
                <MainContainer showLoader={fetching} loadingClassName="screen-loader">
                    {this.getContent()}
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    securitySeals: settingsSelectors.getSecuritySeals(state),
    fetching: settingsSelectors.isFetching(state),
    currentSecuritySeal: sessionSelectors.getUserSecuritySeal(state),
});

export default connect(mapStateToProps)(ChangeSecuritySeal);
