import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { withFormik, Form, Field } from "formik";
import { func, shape, bool, string } from "prop-types";
import { Col } from "react-bootstrap";
import Yup from "yup";

import { actions as enrollmentActions } from "reducers/enrollment";
import { actions as loginActions } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Container from "pages/_components/Container";
import Selector from "pages/_components/fields/formik/Selector";
import TextField from "pages/_components/fields/TextField";

const FORM_ID = "onboarding.stepPEP";

class StepPEP extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        values: shape({
            userIsAPEP: string,
            relatedToPEP: string,
            closeToPEP: string,
            descriptionFieldPEP: string,
        }).isRequired,
        fetching: bool,
        isSubmitting: bool.isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    getContent() {
        const { values, isSubmitting } = this.props;
        const { userIsAPEP, relatedToPEP, closeToPEP } = values;

        return (
            <Form className="above-the-fold">
                <Container className="container--layout flex-grow align-items-center">
                    <Col className="col col-12 col-lg-6 col-md-9">
                        <I18n component="p" className="text-lead" id="onboarding.stepPEP.description" />
                    </Col>
                </Container>
                <Container className="container--layout flex-grow align-items-center">
                    <Col className="col col-12 col-lg-6 col-md-9">
                        {this.addRadioGroup("userIsAPEP")}
                        {this.addRadioGroup("relatedToPEP")}
                        {this.addRadioGroup("closeToPEP")}
                    </Col>
                </Container>
                <Container className="container--layout flex-grow align-items-center">
                    <Col className="col col-12 col-lg-6 col-md-9">
                        {userIsAPEP &&
                            relatedToPEP &&
                            closeToPEP &&
                            (userIsAPEP.includes("true") ||
                                relatedToPEP.includes("true") ||
                                closeToPEP.includes("true")) && (
                                <>
                                    <div className="form-group">
                                        <div className="form-group-text" role="presentation">
                                            <I18n
                                                component="label"
                                                id="onboarding.stepPEP.aditionalInfo.title"
                                                componentProps={{
                                                    className: "control-label",
                                                    htmlFor: "descriptionFieldPEP",
                                                }}
                                            />
                                        </div>
                                        <I18n id="onboarding.stepPEP.aditionalInfo.description" />
                                        <Field
                                            component={TextField}
                                            renderAs="textarea"
                                            name="descriptionFieldPEP"
                                            idForm={FORM_ID}
                                            className="form-control"
                                            hidePlaceholder
                                            labelText="onboarding.stepPEP.aditionalInfo.title"
                                            hideLabel
                                        />
                                    </div>
                                </>
                            )}
                        {userIsAPEP &&
                            relatedToPEP &&
                            closeToPEP &&
                            userIsAPEP.includes("false") &&
                            relatedToPEP.includes("false") &&
                            closeToPEP.includes("false") && (
                                <Field
                                    idForm={FORM_ID}
                                    component={Checkbox}
                                    labelText="onboarding.stepPEP.aditionalInfo.check"
                                    name="noPEPCheck"
                                />
                            )}
                    </Col>
                </Container>
                <Container className="container--layout align-items-center">
                    <Col className="col col-12 col-lg-6 col-md-9">
                        <Button
                            className="btn btn-primary btn-block"
                            label="global.accept"
                            type="submit"
                            loading={isSubmitting}
                        />
                    </Col>
                </Container>
            </Form>
        );
    }

    addRadioGroup = (name) => (
        <fieldset>
            <I18n id={`onboarding.stepPEP.option.${name}`} />
            <Field
                component={Selector}
                renderAs="radio"
                inLineControl
                name={name}
                options={[
                    {
                        id: `true|${name}`,
                        label: <I18n id="administration.signature.true" />,
                    },
                    {
                        id: `false|${name}`,
                        label: <I18n id="administration.signature.false" />,
                    },
                ]}
            />
        </fieldset>
    );

    render() {
        const { fetching } = this.props;
        return (
            <Fragment>
                <PageLoading loading={fetching}>
                    <Head title="pendingActions.pep.areyou" onBack={this.onHeaderBack} hideNavbarInMobile />
                    <Notification scopeToShow="pendingActions" />
                    <div className="view-page">
                        <div className="view-content">
                            <main className="main-container">{this.getContent()}</main>
                        </div>
                    </div>
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    email: sessionSelectors.getUser(state).email,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        mapPropsToValues: (props) => ({
            userIsAPEP: null,
            relatedToPEP: null,
            closeToPEP: null,
            noPEPCheck: false,
            descriptionFieldPEP: "",
            email: props.email,
        }),
        validationSchema: () =>
            // VALIDATIONS
            Yup.lazy((values) => {
                const { userIsAPEP } = values;
                const { relatedToPEP } = values;
                const { closeToPEP } = values;
                const exist = userIsAPEP && relatedToPEP && closeToPEP;
                const checkRequired =
                    exist &&
                    userIsAPEP.includes("false") &&
                    relatedToPEP.includes("false") &&
                    closeToPEP.includes("false");
                const descriptionRequired =
                    exist &&
                    (userIsAPEP.includes("true") || relatedToPEP.includes("true") || closeToPEP.includes("true"));
                return Yup.object().shape({
                    userIsAPEP: Yup.string()
                        .nullable()
                        .required(i18n.get("onboarding.stepPEP.question.error")),
                    relatedToPEP: Yup.string()
                        .nullable()
                        .required(i18n.get("onboarding.stepPEP.question.error")),
                    closeToPEP: Yup.string()
                        .nullable()
                        .required(i18n.get("onboarding.stepPEP.question.error")),
                    noPEPCheck: checkRequired
                        ? Yup.bool().oneOf([true], i18n.get("onboarding.stepPEP.check.error"))
                        : Yup.bool(),
                    descriptionFieldPEP: descriptionRequired
                        ? Yup.string().required(i18n.get("onboarding.stepPEP.description.error"))
                        : Yup.string(),
                });
            }),
        handleSubmit: ({ email }, formikBag) => {
            const { dispatch } = formikBag.props;
            dispatch(enrollmentActions.sendPEP(email));
        },
    }),
)(StepPEP);
