import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { bool, func, number, oneOfType, shape, arrayOf, objectOf, string } from "prop-types";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import Head from "pages/_components/Head";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";

class SignaturesSchemeCreate extends Component {
    static propTypes = {
        actions: shape({
            createSignaturesSchemeRequest: func,
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        fetching: bool.isRequired,
        functionalGroups: arrayOf(string),
        selectedProducts: arrayOf(string),
        environmentProducts: arrayOf(Object),
        masterCurrency: string,
        routerActions: shape({
            goBack: func,
        }),
        signatureLevelsCounts: oneOfType([
            shape({
                A: number,
            }),
            shape(
                configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                    const result = res;
                    result[signLevel] = number;

                    return result;
                }, {}),
            ),
        ]),
        signatureType: string,
        topAmount: shape({
            amount: oneOfType([number, string]),
            period: string,
        }),
        formActions: objectOf(func).isRequired,
        idActivity: string,
        idTransaction: string,
        signatureDispatch: bool,
    };

    static defaultProps = {
        functionalGroups: [],
        masterCurrency: configUtils.get("core.masterCurrency"),
        routerActions: null,
        signatureLevelsCounts: null,
        signatureType: "",
        topAmount: {
            amount: 0,
            period: "daily",
        },
        idActivity: null,
        idTransaction: null,
        signatureDispatch: false,
        selectedProducts: [],
        environmentProducts: [],
    };

    componentDidMount() {
        const { routerActions, signatureLevelsCounts, signatureType } = this.props;

        if (!signatureLevelsCounts || !signatureType) {
            routerActions.goBack();
        }
    }

    handleSubmit = (credentials, formikBag) => {
        const {
            idTransaction,
            idActivity,
            formActions,
            actions,
            functionalGroups,
            selectedProducts,
            signatureLevelsCounts,
            signatureType,
            topAmount,
            signatureDispatch,
        } = this.props;

        if (credentials.otp && credentials.otp.length !== 0) {
            if (!idTransaction) {
                actions.createSignaturesSchemeRequest(
                    {
                        credentials,
                        functionalGroups,
                        products: selectedProducts,
                        signatureLevelsCounts,
                        signatureType,
                        topAmount,
                        signatureDispatch,
                    },
                    formikBag,
                );
            } else {
                const paramsSign = { idForm: null, idActivity, idTransaction };
                formActions.signTransaction({ ...paramsSign, credentials, formikBag });
            }
        } else {
            formikBag.setErrors({ otp: i18nUtils.get("transactions.otp.empty") });
            formikBag.setSubmitting(false);
        }
    };

    renderFunctionalGroups = (functionalGroups) => (
        <Heading.DataGroup
            containerClassName="data-wrapper-flex align-items-baseline mb-1 flex-wrap"
            labelClassName="font-light font-weight-600 mr-2"
            label="administration.signatures.functionalGroups.label"
            data={
                functionalGroups && functionalGroups.length > 0
                    ? this.getSeparatedByCharacterStringArray(
                          functionalGroups,
                          "administration.signatures.functionalGroups",
                          " | ",
                      )
                    : "-"
            }
        />
    );

    renderProducts = (selectedProducts, environmentProducts) => (
        <Heading.DataGroup
            containerClassName="data-wrapper-flex align-items-baseline mb-1 flex-wrap"
            labelClassName="font-light font-weight-600 mr-2"
            label="administration.signatures.products.label"
            data={
                selectedProducts && selectedProducts.length > 0
                    ? environmentProducts
                          .filter((product) => selectedProducts.indexOf(product.idProduct) > -1)
                          .map((product) => <div key={product}>{product.label}</div>)
                    : "-"
            }
        />
    );

    renderSchemeReference = (administrationScheme) => (
        <Heading.DataGroup
            containerClassName="data-wrapper-flex align-items-baseline mb-1"
            labelClassName="font-light font-weight-600 mr-2"
            label={
                administrationScheme === "medium"
                    ? `administration.signatures.create.${administrationScheme}.confirm.signersCount`
                    : `administration.signatures.create.${administrationScheme}.confirm.schemeReference`
            }
            data={this.signersCount()}
        />
    );

    renderSignatureDispatch = (signatureDispatch) => (
        <Heading.DataGroup
            containerClassName="data-wrapper-flex align-items-baseline mb-1"
            labelClassName="font-light font-weight-600 mr-2"
            label="details.administration.dispatcher.label"
            data={signatureDispatch ? i18nUtils.get("global.yes") : "-"}
        />
    );

    renderTopAmount = (masterCurrency, topAmount) => (
        <div>
            <Heading.DataGroup
                containerClassName="data-wrapper-flex align-items-baseline mb-1"
                labelClassName="font-light font-weight-600 mr-2"
                label="administration.signatures.create.topAmount.label"
                data={
                    <Fragment>
                        <FormattedAmount
                            className="data-amount"
                            currency={masterCurrency || configUtils.get("core.masterCurrency")}
                            frequency={i18nUtils.get(
                                "administration.signatures.create.advanced.topAmountPerPeriod",
                                null,
                                {
                                    period: i18nUtils.get(
                                        `administration.signatures.transactions.capFrequency.${topAmount.period}`,
                                    ),
                                },
                            )}
                            quantity={topAmount.amount}
                            notBold
                        />
                    </Fragment>
                }
            />
        </div>
    );

    renderTransactionType = (administrationScheme, signatureType) => (
        <Heading.DataGroup
            containerClassName="data-wrapper-flex align-items-baseline mb-1"
            labelClassName="font-light font-weight-600 mr-2"
            label={`administration.signatures.create.${administrationScheme}.confirm.transactionType`}
            data={
                signatureType ? i18nUtils.get(`administration.signatures.create.signatureType.${signatureType}`) : "-"
            }
        />
    );

    signersCount = () => {
        const {
            activeEnvironment: { administrationScheme },
            signatureLevelsCounts,
        } = this.props;

        return administrationScheme === "medium"
            ? `${signatureLevelsCounts.A}`
            : Object.keys(signatureLevelsCounts)
                  .sort()
                  .reduce((result, key) => result + key.toString().repeat(signatureLevelsCounts[key]), "");
    };

    getSeparatedByCharacterStringArray = (itemList, i18nPrefix, separator) => {
        const resultArray = itemList.map((element) => i18nUtils.get(`${i18nPrefix}.${element}`));
        return resultArray.join(separator);
    };

    render() {
        const {
            credentialGroups,
            activeEnvironment: { administrationScheme },
            fetching,
            functionalGroups,
            environmentProducts,
            selectedProducts,
            masterCurrency,
            routerActions,
            signatureLevelsCounts,
            signatureType,
            topAmount,
            signatureDispatch,
        } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="signature" />
                <div className="admin-detail-head admin-detail-head-section px-0 rigth-position-desktop">
                    <div>
                        <Head
                            onBack={routerActions.goBack}
                            closeLinkTo={`/administration/${administrationScheme}/signaturesSchemes`}
                        />
                    </div>
                    <div className="view-title mb-4">
                        <h1>{i18nUtils.get("administration.signatures.create.title")}</h1>
                    </div>
                </div>
                <MainContainer showLoader={fetching} viewPageClassName="confirmation__container">
                    <div className="confirmation__form confirmation__box-transparent px-0">
                        <Container className="container-white mb-0" gridClassName="px-0">
                            <div>
                                <I18n id="administration.forms.confirm.credentials" component="h1" />
                            </div>
                            <Col className="col-12" md={9} lg={12}>
                                {signatureLevelsCounts && this.renderSchemeReference(administrationScheme)}
                                {this.renderSignatureDispatch(signatureDispatch)}
                                {this.renderTransactionType(administrationScheme, signatureType)}
                                {topAmount &&
                                    signatureType === "AMOUNT" &&
                                    this.renderTopAmount(masterCurrency, topAmount)}
                                {this.renderFunctionalGroups(functionalGroups)}
                                {this.renderProducts(selectedProducts, environmentProducts)}
                            </Col>
                        </Container>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            MDcolumnWidth={12}
                            LGcolumnWidth={12}
                            XLcolumnWidth={12}
                            formContainerClassName="confirmation__container"
                            containerClassName="confirmation__form confirmation__box pb-0 mb-3"
                            buttonLabel="global.confirm"
                            buttonImage="images/arrowRight.svg"
                            buttonImageClass="mr-2"
                        />
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default SignaturesSchemeCreate;
