import React from "react";
import { Field, FieldArray } from "formik";
import { string, shape, number, func, arrayOf, bool } from "prop-types";

import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Selector from "pages/_components/fields/formik/Selector";
import AmountField from "pages/_components/fields/formik/AmountField";
import * as i18nUtils from "util/i18n";
import { paymentConceptWithBaseDebtShape } from "util/servicePayments";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import Range from "pages/_components/Range";

function ConceptWithBaseDebt({ FORM_ID, expirations, getRadioOptions, currencies, isDesktop }) {
    ConceptWithBaseDebt.propTypes = {
        FORM_ID: string.isRequired,
        expirations: arrayOf(paymentConceptWithBaseDebtShape.isRequired).isRequired,
        getRadioOptions: func.isRequired,
        currencies: arrayOf(shape({ id: number, label: string })),
        isDesktop: bool,
    };

    ConceptWithBaseDebt.defaultProps = {
        currencies: [{ id: 0, label: "ARS" }],
        isDesktop: true,
    };

    const multipleExpirations = expirations.length > 1;

    return isDesktop ? (
        <FieldArray
            name="expirations"
            render={() =>
                expirations.map((expiration, index) => (
                    <Row className="mx-0 d-flex justify-content-center align-items-center" key={expiration.debtId}>
                        {expiration.concept.managesIncomeAmounts ? (
                            <>
                                <Col className="d-flex pl-md-0 align-items-center" sm={12} md={6}>
                                    <div className="d-flex align-items-center">
                                        {multipleExpirations && (
                                            <Field
                                                idForm={FORM_ID}
                                                formGroupClassName={expiration.concept.managesRange ? "mb-0" : "mb-3"}
                                                name="concept"
                                                component={Selector}
                                                renderAs="radio"
                                                hideLabel
                                                options={getRadioOptions(index)}
                                            />
                                        )}
                                        <div className="d-flex flex-column">
                                            <Field
                                                component={AmountField}
                                                formGroupClassName={expiration.concept.managesRange ? "mb-0" : "mb-4"}
                                                data={{ options: currencies }}
                                                name={`conceptAmount${expiration.debtId}`}
                                                clearable
                                                maxLength={15}
                                                disableSelect
                                                hideLabel
                                                fixedDecimalScale
                                                plainValue
                                            />
                                            {expiration.concept.managesRange && (
                                                <Range 
                                                    currency={currencies[0].id.toString()}
                                                    min={expiration.concept.minAmount}
                                                    max={expiration.concept.maxAmount}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col className="d-flex justify-content-center" sm={6} md={3}>
                                    {
                                        expiration.date ?
                                            <FormattedDate date={expiration.date} dateFormat="dd/MM/yyyy" />
                                        :
                                            <span className="font-light f-size-55">
                                                {i18nUtils.get("services.newPayment.noExpirationDate")}
                                            </span>
                                    }
                                </Col>
                                <Col className="d-flex justify-content-end px-0" sm={6} md={3}>
                                    <span className="font-light f-size-55">{expiration.concept.description}</span>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col className="d-flex justify-content-start align-items-center pl-0" sm={12} md={6}>
                                    {multipleExpirations && (
                                        <Field
                                            idForm={FORM_ID}
                                            formGroupClassName="m-0"
                                            name="concept"
                                            component={Selector}
                                            renderAs="radio"
                                            hideLabel
                                            options={getRadioOptions(index)}
                                        />
                                    )}
                                    <FormattedAmount
                                        cleanClassName
                                        fontClassName="font-light f-size-55"
                                        currency={currencies[0].id.toString()}
                                        quantity={expiration.amount}
                                    />
                                </Col>
                                <Col className="d-flex justify-content-center" sm={6} md={3}>
                                    <FormattedDate
                                        className="font-light f-size-55"
                                        date={expiration.date}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </Col>
                                <Col className="d-flex justify-content-end px-0" sm={6} md={3}>
                                    <span className="font-light f-size-55">{expiration.concept.description}</span>
                                </Col>
                            </>
                        )}
                    </Row>
                ))
            }
        />
    ) : (
        <FieldArray
            name="expirations"
            render={() =>
                expirations.map((expiration, index) => (
                    <Row className="d-flex flex-no-wrap justify-content-center align-items-center mx-0 p-3">
                        {multipleExpirations && (
                            <Field
                                idForm={FORM_ID}
                                formGroupClassName="m-0"
                                name="concept"
                                component={Selector}
                                renderAs="radio"
                                hideLabel
                                options={getRadioOptions(index)}
                            />
                        )}
                        {expiration.concept.managesIncomeAmounts ? (
                            <div className="flex-column w-100">
                                <Col className="px-0 mb-25 " sm={12} md={6}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.amountToPay`}
                                    />
                                    <Field
                                        component={AmountField}
                                        formGroupClassName="w-100 mb-0"
                                        amountClassName="text-align-right"
                                        data={{ options: currencies }}
                                        name={`conceptAmount${expiration.debtId}`}
                                        clearable
                                        maxLength={15}
                                        disableSelect
                                        hideLabel
                                        fixedDecimalScale
                                        plainValue
                                    />

                                    {expiration.concept.managesRange && (
                                        <Range 
                                            currency={currencies[0].id.toString()}
                                            min={expiration.concept.minAmount}
                                            max={expiration.concept.maxAmount}
                                            className="m-0"
                                        />
                                    )}
                                </Col>
                                <Col className="d-flex space-between line-height-1 px-0 mb-25" xs={12}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.expiration`}
                                    />
                                    <FormattedDate date={expiration.date} dateFormat="dd/MM/yyyy" />
                                </Col>
                                <Col className="d-flex space-between line-height-1 px-0" xs={12}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.concept`}
                                    />
                                    <span className="font-light f-size-55">{expiration.concept.description}</span>
                                </Col>
                            </div>
                        ) : (
                            <div className="flex-column w-100">
                                <Col className="d-flex space-between line-height-1 px-0 mb-25" sm={12} md={6}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.amountToPay`}
                                    />
                                    <FormattedAmount
                                        cleanClassName
                                        fontClassName="font-light f-size-55"
                                        currency={currencies[0].id.toString()}
                                        quantity={expiration.amount}
                                    />
                                </Col>
                                <Col className="d-flex space-between line-height-1 px-0 mb-25" sm={12} md={3}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.expiration`}
                                    />
                                    <FormattedDate
                                        className="font-light f-size-55"
                                        date={expiration.date}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </Col>
                                <Col className="d-flex space-between line-height-1 px-0" sm={12} md={3}>
                                    <I18n
                                        componentProps={{ className: "text-uppercase f-size-55" }}
                                        id={`${FORM_ID}.concept`}
                                    />
                                    <span className="font-light f-size-55">{expiration.concept.description}</span>
                                </Col>
                            </div>
                        )}
                    </Row>
                ))
            }
        />
    );
}

export default ConceptWithBaseDebt;
