import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    DOWNLOAD_FILE_REQUEST: "files/DOWNLOAD_FILE_REQUEST",
    DOWNLOAD_FILE_SUCCESS: "files/DOWNLOAD_FILE_SUCCESS",
    GET_FILE_CONTENTS_REQUEST: "files/GET_FILE_CONTENTS_REQUEST",
    GET_FILE_CONTENTS_SUCCESS: "files/GET_FILE_CONTENTS_SUCCESS",
};

export const INITIAL_STATE = {
    fetching: false,
    lines: [],
};

export default createReducer(INITIAL_STATE, {
    [types.DOWNLOAD_FILE_REQUEST]: () => ({ ...INITIAL_STATE, fetching: true }),
    [types.DOWNLOAD_FILE_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
});

export const actions = {
    getFileContentsRequest: makeActionCreator(types.GET_FILE_CONTENTS_REQUEST, "idFile", "deleteFile", "idUserCreator"),
    downloadFileRequest: makeActionCreator(types.DOWNLOAD_FILE_REQUEST, "idFile", "fileName"),
    downloadFileSuccess: makeActionCreator(types.DOWNLOAD_FILE_SUCCESS),
};

// Selectors
export const selectors = {
    isFetching: (state) => state.files.fetching,
};
