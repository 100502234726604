export const isString = (value) => typeof value === "string";

export const isEmpty = (value) => isString(value) && (value.trim() === "" || value.trim() === "_empty");

export const trim = (value) => {
    if (isString(value)) {
        return value.trim();
    }

    return value;
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const lowerCaseFirstLetter = (string) => string.charAt(0).toLowerCase() + string.slice(1);

export const insertLinebreaks = (string) => string.replace(/<br ?\/?>/g, "\n");

export const insertHTMLLinebreaks = (string) => string.replace(/\n/g, "<br>");
