import React, { Component } from "react";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import { number, bool, string } from "prop-types";

import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";

import * as i18nUtils from "util/i18n";

class EquivalentTotalBalance extends Component {
    static propTypes = {
        balance: number,
        isDesktop: bool,
        title: string,
        bold: bool,
        block: bool,
        smallAmount: bool,
    };

    static defaultProps = {
        balance: 0,
        isDesktop: true,
        title: "accounts.balance.total",
        bold: false,
        block: false,
        smallAmount: false,
    };

    render() {
        const { balance, isDesktop, title, bold, block, smallAmount } = this.props;

        const totalComponent = (
            <FormattedAmount
                className={classNames(
                    "data-amount",
                    "bold-font",
                    { "big-font": !smallAmount },
                    { "small-margin-bottom": !isDesktop },
                )}
                currency={i18nUtils.get("currency.label.ARS")}
                quantity={balance}
            />
        );

        return (
            <div>
                <Container className="content-data-row">
                    <Col
                        xs={12}
                        className={`content-data-wrapper align-items-baseline d-flex ${
                            !isDesktop ? "text-align-left" : ""
                        } ${block ? "d-block" : ""}`}>
                        <I18n
                            id={title}
                            componentProps={{
                                className: `account-label my-0 mr-2 text-uppercase line-height-normal ${
                                    bold ? "data-amount-currency" : ""
                                } ${!isDesktop ? "text-align-left" : ""}`,
                            }}
                            component="h3"
                        />
                        <h3 className={`content-data-wrapper d-flex my-0 ${!isDesktop ? "text-align-left" : ""}`}>
                            {isDesktop ? <>{totalComponent}</> : <div>{totalComponent}</div>}
                        </h3>
                    </Col>
                </Container>
            </div>
        );
    }
}

export default EquivalentTotalBalance;
