import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import Head from "pages/_components/Head";
import { func, shape, bool } from "prop-types";
import Logo from "pages/login/_components/Logo";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import classNames from "classnames";
import Button from "./Button";
import I18n from "./I18n";

class PrivacyPolicy extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        hasActiveSession: bool,
        history: shape({ goBack: func }),
        propsGrid: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
        history: null,
    };

    backButtonAction = () => {
        const { dispatch, hasActiveSession, history } = this.props;
        return hasActiveSession ? history.goBack() : dispatch(push("/"));
    };

    rightContentMobile = () => (
        <Button
            className="toolbar-btn view-close pb-0"
            onClick={this.backButtonAction}
            image="images/cross-mobile-blue.svg"
            bsStyle="link"
        />
    );

    renderLogoMobile = () => <Logo className="svg-image logo logo-mobile-size" logoColor />;

    renderContent = () => {
        const { isDesktop } = this.props;

        return (
            <>
                <div
                    className={classNames({
                        "pr-0": isDesktop,
                    })}>
                    {!isDesktop && (
                        <h2 className="modal-header text-center pt-0 pb-4">
                            <I18n id="global.privacy" accessibilityTextId="global.privacy" />
                        </h2>
                    )}
                    <div className="scrollable-content border-top max-width-600 mx-auto">
                        <I18n id="global.privacy.message" component="p" />
                    </div>
                </div>
            </>
        );
    };

    render() {
        const { hasActiveSession, isDesktop, propsGrid } = this.props;
        return hasActiveSession ? (
            <>
                <div className="admin-detail-head px-0">
                    <Head onBack={this.backButtonAction} />
                    <Head title="global.privacy" titleClassName="mx-auto" />
                </div>
                {this.renderContent()}
            </>
        ) : (
            <GridLayoutExternal isDesktop={isDesktop} {...propsGrid} showLogoState>
                <Head
                    onClose={this.backButtonAction}
                    title={isDesktop && "global.privacy"}
                    headerClassName={classNames({
                        "login-background-color-white": !isDesktop,
                        "recover-pass-desktop mb-2 h-auto": isDesktop,
                    })}
                    centerElement={!isDesktop && this.renderLogoMobile}
                    fixed
                />
                {this.renderContent()}
            </GridLayoutExternal>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default withRouter(connect(mapStateToProps)(PrivacyPolicy));
