import * as config from "util/config";

const getTransferPath = (type = "internal") => {
    const mvp4 = config.getBoolean("frontend.showMVP4.transfers.functionalities", false);
    if (mvp4) {
        return `/v2/transfer/${type}`;
    }
    return "/transfer";
};

export { getTransferPath as default };
