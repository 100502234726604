import { getDefaultPosition } from "util/config";
import { store } from "store";
import { types } from "reducers/session";

function getLocation() {
    return new Promise((resolve, reject) => {
        const { geolocation } = navigator;

        geolocation.getCurrentPosition(resolve, reject, {
            timeout: 3000,
        });
    });
}

export default function(fallBackValue = getDefaultPosition("pois.defaultMapLocation")) {
    const { isLocationRequested } = store.getState().session;
    if (!isLocationRequested) {
        const location = getLocation()
            .then((position) => ({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            }))
            .catch(() => fallBackValue);

        store.dispatch({ type: types.LOCATION_REQUESTED });
        return location;
    }
    return fallBackValue;
}
