import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import * as i18n from "util/i18n";
import * as config from "util/config";
import { bool, func } from "prop-types";
import { routerActions, push } from "react-router-redux/actions";
import { actions as tokenActions, selectors as tokenSelectors } from "reducers/token";

import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import { Mixpanel } from "util/clickstreaming";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import { compose } from "redux";
import { connect } from "react-redux";

const FORM_ID = "setting.token.mobile.activation.code.password";

class CodeActivationPasswordMobile extends Component {
    static propTypes = {
        isDesktop: bool,
        isMobile: bool.isRequired,
        dispatch: func.isRequired,
        fetchingToken: bool.isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    state = {
        showMessage: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        const showMobile = window.isMobileApp();

        if (!showMobile) {
            dispatch(push("/desktop"));
        }
    }

    getPasswordHelp = (maxLength, minLength) => (
        <>
            <div className="password-rules mb-5">
                <ul>
                    <li>
                        <I18n
                            id="setting.token.mobile.activation.code.passwordHelp1"
                            MIN_LENGTH={minLength}
                            MAX_LENGTH={maxLength}
                        />
                    </li>
                    <li>
                        <I18n id="setting.token.mobile.activation.code.passwordHelp2" />
                    </li>
                    <li>
                        <I18n id="setting.token.mobile.activation.code.passwordHelp3" />
                    </li>
                </ul>
            </div>
        </>
    );

    getMobileContent = () => (
        <div className="above-the-fold">
            <div className="mx-3">{this.renderDevices()}</div>
        </div>
    );

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("setting.token.mobile.activation.tittle")}</h1>
        </div>
    );

    renderContent = () => {
        const { isDesktop, fetchingToken } = this.props;
        const minLength = config.getInteger("token.password.minLength", 4);
        const maxLength = config.getInteger("token.password.maxLength", 6);

        return (
            <Form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                <Container className="container--layout flex-grow align-items-center">
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <p className="text-lead">
                            <I18n id="setting.token.mobile.activation.code.password.credential.message" />
                        </p>
                    </Col>
                </Container>
                <Container className="container--layout flex-grow align-items-center">
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Field
                            type="tokenPass"
                            name="password"
                            idForm={FORM_ID}
                            component={Credential}
                            maxLength={maxLength}
                        />
                        <Field
                            type="tokenPass"
                            name="passwordConfirmation"
                            idForm={FORM_ID}
                            component={Credential}
                            maxLength={maxLength}
                        />
                        {this.getPasswordHelp(maxLength, minLength)}
                    </Col>
                </Container>
                <Container className="align-items-center container--layout">
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Button label="global.send" type="submit" bsStyle="primary" loading={fetchingToken} />
                    </Col>
                </Container>
            </Form>
        );
    };

    render() {
        const { isMobile } = this.props;

        const { showMessage } = this.state;

        return (
            <>
                <Notification scopeToShow="tokenCreateCodeInvitation" />

                <div className="admin-detail-head px-0" style={isMobile ? { marginBottom: 0 } : {}}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName="blue-main-header-mobile"
                        centerElement={this.centerContentMobile}
                        emptyRightOption
                    />

                    <div className="mx-3" style={{ opacity: showMessage ? 1 : 0 }}>
                        <I18n
                            id="setting.token.mobile.activation.tittle"
                            component="p"
                            componentProps={{
                                className: "hint-text my-0 f-size-55",
                            }}
                        />
                    </div>
                </div>
                {this.renderContent()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetchingToken: tokenSelectors.getFetchingToken(state),
});
export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: true,
        mapPropsToValues: () => ({ password: "", passwordConfirmation: "" }),
        validationSchema: (props) =>
            Yup.lazy((values) => {
                if (values.password === undefined || values.passwordConfirmation === undefined) {
                    return Yup.object().shape({
                        password: Yup.string().required(
                            i18n.get(
                                props.isRecoveringUserpass
                                    ? `settings.changeUserpass.newUserpass.required`
                                    : `${FORM_ID}.password.required`,
                            ),
                        ),
                        passwordConfirmation: Yup.string().required(
                            i18n.get(
                                props.isRecoveringUserpass
                                    ? `settings.changeUserpass.newUserpassConfirmation.required`
                                    : `${FORM_ID}.passwordConfirmation.required`,
                            ),
                        ),
                    });
                }
                return Yup.object().shape({
                    password: Yup.string()
                        .min(
                            config.getInteger("token.password.minLength", 4),
                            i18n.get(`settings.changePassword.modify.error.minLength`),
                        )
                        .max(
                            config.getInteger("token.password.maxLength", 6),
                            i18n.get(`settings.changePassword.modify.error.maxLength`),
                        ),
                    passwordConfirmation: Yup.string()
                        .min(
                            config.getInteger("token.password.minLength", 4),
                            i18n.get(`settings.changePassword.modify.error.minLength`),
                        )
                        .max(
                            config.getInteger("token.password.maxLength", 6),
                            i18n.get(`settings.changePassword.modify.error.maxLength`),
                        )
                        .oneOf([values.password], i18n.get(`settings.changePassword.modify.error.passwordMustMatch`)),
                });
            }),
        handleSubmit: (values, formikBag) => {
            Mixpanel.track(`${FORM_ID}`);

            formikBag.props.dispatch(
                tokenActions.tokenActivationRequest(values.password, values.passwordConfirmation, formikBag),
            );
        },
    }),
)(CodeActivationPasswordMobile);
