import React from 'react'
import I18n from 'pages/_components/I18n'
import Image from 'pages/_components/Image'

function MovementsEmptyState() {
    return (
        <div className="text-center no-more-data" key="noMoreMovements">
            <div className="illustration-wrapper">
                <Image src="images/loupeInFile.svg" className="svg-big-icon" />
            </div>
            <p className="text-lead">
                <I18n id="accounts.movements.notFound" />
            </p>
        </div>
    )
}

export default MovementsEmptyState
