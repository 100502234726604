import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bool, func, string } from "prop-types";

import classNames from "classnames";
import Button from "pages/_components/Button";

class MenuFormItem extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSelected: bool,
        link: string.isRequired,
        name: string.isRequired,
    };

    static defaultProps = {
        isSelected: false,
    };

    render() {
        const { dispatch, isSelected, link, name, ...rest } = this.props;

        return (
            <Button
                bsStyle="link"
                onClick={() => {
                    dispatch(push(link));
                }}
                className={classNames("w-100", { "item-selected": isSelected })}
                {...rest}>
                <span className="m-0 text-left">{name}</span>
            </Button>
        );
    }
}

export default connect()(MenuFormItem);
