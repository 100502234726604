import React, { Component } from "react";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import { func, string, shape, int, bool } from "prop-types";
import { routerActions } from "react-router-redux/actions";

import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import { Field, withFormik, Form } from "formik";
import { compose } from "redux";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import { actions, selectors } from "reducers/frequentDestination";
import * as config from "util/config";
import * as i18n from "util/i18n";
import Yup from "yup";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Heading from "pages/_components/Heading";
import classNames from "classnames";

const FORM_ID = "frequentDestination.modify";

class ModifyFrequentDestination extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        frequentDestination: shape({
            idFrequentDestinations: int,
            accountNumber: string,
            productType: string,
            isOwn: string,
            cbu: string,
            recipientDocumentType: string,
            recipientDocumentNumber: string,
            recipientName: string,
            localBank: string,
            SubsidiaryBank: string,
            recipientEmail: string,
            name: string,
        }).isRequired,
    };

    static defaultProps = {};

    state = {};

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("frequentDestination.modify.tittle")}</h1>
        </div>
    );

    renderHeader = () => {
        const { isDesktop } = this.props;
        return (
            <div className="admin-detail-head px-0">
                <Head
                    onBack={this.handleBack}
                    centerElement={!isDesktop && this.centerContentMobile}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                    emptyRightOption
                />
                {isDesktop && (
                    <div className="view-title mb-3">
                        <h1>{i18n.get("frequentDestination.modify.tittle")}</h1>
                    </div>
                )}
            </div>
        );
    };

    renderForm = () => {
        const { frequentDestination, isDesktop } = this.props;

        const containerClassName = `transfer-data data-wrapper-flex ${isDesktop && "justify-content-start"} ${
            !isDesktop ? "space-between mb-0 container-details" : "mb-2"
        }`;
        const labelClassName = isDesktop ? "mb-0" : "";

        return (
            <>
                <Container
                    className="container--layout flex-grow align-items-center flex-grow admin-action"
                    gridClassName="form-content">
                    <Col sm={12} md={5} className="col col-12 py-3">
                        <div className="d-flex justify-content-center">
                            <Col sm={12} className="px-0 col col-12">
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.numberAccount"
                                    data={`${frequentDestination.accountNumber}`}
                                />
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.accountType"
                                    data={`${frequentDestination.productType}`}
                                />
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.own"
                                    data={`${frequentDestination.isOwn === "1" ? "SI" : "NO"}`}
                                />
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.cbu"
                                    data={`${frequentDestination.cbu}`}
                                />
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.document"
                                    data={`${frequentDestination.recipientDocumentType}
                                    ${frequentDestination.recipientDocumentNumber}`}
                                />
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.recipientName"
                                    data={`${frequentDestination.recipientName}`}
                                />
                                <Heading.DataGroup
                                    containerClassName={containerClassName}
                                    labelClassName={labelClassName}
                                    label="frequentDestination.create.confirmate.bank"
                                    data={`${frequentDestination.localBank}/${frequentDestination.subsidiaryBank}`}
                                />
                            </Col>
                        </div>
                    </Col>
                </Container>
                <Container
                    className={classNames("container--layout flex-grow align-items-center flex-grow admin-action", {
                        "mt-2": !isDesktop,
                    })}
                    gridClassName="form-content">
                    <Col sm={12} md={5} className="col col-12">
                        <Col sm={12} lg={10} className="col col-12 px-0">
                            <Field idForm={FORM_ID} component={TextField} name="email" type="text" isFocused />
                        </Col>
                        <Col sm={12} lg={10} className="col col-12 px-0">
                            <Field idForm={FORM_ID} component={TextField} name="name" type="text" isFocused />
                        </Col>
                    </Col>
                </Container>
            </>
        );
    };

    render() {
        const { fetching, frequentDestination, isDesktop } = this.props;

        return (
            <>
                <Notification scopeToShow="FrequentDestinationsModify" />
                {this.renderHeader()}
                <Form>
                    <MainContainer>
                        {frequentDestination && this.renderForm()}
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col sm={12} md={5} className="col col-12">
                                <Col sm={12} lg={10} className="col col-12 px-0">
                                    <Button
                                        bsStyle="primary"
                                        label="global.continue"
                                        type="submit"
                                        loading={fetching}
                                        className={classNames({ "mt-7": isDesktop }, { "mt-6": !isDesktop })}
                                    />
                                </Col>
                            </Col>
                        </Container>
                    </MainContainer>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: selectors.getFetching(state),
    frequentDestination: selectors.getFrequentDestinationModify(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ frequentDestination }) => ({
            email: frequentDestination?.recipientEmail,
            name: frequentDestination?.name,
        }),
        validationSchema: () =>
            Yup.lazy((values) => {
                if (values.name === undefined) {
                    return Yup.object().shape({
                        name: Yup.string().required(i18n.get(`${FORM_ID}.name.required`)),
                    });
                }

                return Yup.object().shape({
                    email: Yup.string()
                        .email(i18n.get(`${FORM_ID}.email.invalid`))
                        .nullable(),
                    name: Yup.string().max(
                        config.getInteger("frequentDestination.reference.maxLength", 100),
                        i18n.get(`frequentDestination.reference.length.error`),
                    ),
                });
            }),
        handleSubmit: (data, formikBag) => {
            const { credentialGroups, dispatch, frequentDestination } = formikBag.props;
            const params = {
                ...data,
                email: data.email,
                name: data.name,
                idFrequentDestination: frequentDestination.idFrequentDestination,
            };
            dispatch(actions.modifyFrequentDestinationPreviewRequest(params, formikBag, credentialGroups));
        },
    }),
)(ModifyFrequentDestination);
