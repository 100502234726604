import React, { Component } from "react";
import { string, node, func, number } from "prop-types";

import Image from "pages/_components/Image";

class IndeterminateCheckbox extends Component {
    static propTypes = {
        className: string,
        id: string.isRequired,
        label: node,
        onCheckClick: func.isRequired,
        optionsAmount: number.isRequired,
        selectedOptionsAmount: number.isRequired,
    };

    static defaultProps = {
        className: "c-control c-control--has-icon",
        label: null,
    };

    render() {
        const { id, onCheckClick, selectedOptionsAmount, optionsAmount, label, className } = this.props;
        const isIndeterminate = selectedOptionsAmount > 0 && selectedOptionsAmount < optionsAmount;

        return (
            <div className={className} onClick={(event) => event.stopPropagation()}>
                <input
                    id={id}
                    tabIndex="-1"
                    type="checkbox"
                    className="c-control-input"
                    onChange={onCheckClick}
                    checked={selectedOptionsAmount > 0}
                    indeterminate={`${isIndeterminate}`}
                    readOnly
                />
                <label className="c-control-label" htmlFor={id}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image
                                src={`images/${isIndeterminate ? "indeterminate" : "check"}.svg`}
                                className="svg-icon svg-caret"
                            />
                        </div>
                    </div>
                    {label && <div className="form-group-text">{label}</div>}
                </label>
            </div>
        );
    }
}

export default IndeterminateCheckbox;
