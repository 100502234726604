import { Button, Box, Form, Text, useMediaQuery } from "@ui-kit/components/index.es";
import React, { useEffect, useState } from "react";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CreateTemplateModal from "pages/v2/transfer/CreateTemplateModal";
import { actions as templateActions } from "reducers/template";
import Limits from "./Limits";
import Message from "./scheduler/Message";

function TransferInternalForm(props) {
    TransferInternalForm.propTypes = {
        dispatch: func.isRequired,
        onSubmit: func.isRequired,
        onSaveDraft: func.isRequired,
        onCreateTemplate: func.isRequired,
        isDesktop: bool.isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        limit: number.isRequired,
        limitUsed: number.isRequired,
        values: shape({}).isRequired,
        setValues: func.isRequired,
        setHint: func.isRequired,
        setIsScheduleModalVisible: func.isRequired,
        onClickTadWaring: func.isRequired,
        tadWarningShowed: bool.isRequired,
        agendaVisible: bool.isRequired,
        internalTransfer: bool.isRequired,
    };

    const { accounts, setValues, setIsScheduleModalVisible, values, setHint, internalTransfer } = props;
    const isMobile = useMediaQuery("min", "md");

    const handleDebitAccountSelect = (accountId) => {
        const { dispatch } = props;
        setValues({ ...values, debitAccount: accountId });
        dispatch(transferActions.accountLimitsActivities(accountId, "transfers.internal.send"));
    };

    const [formikProps, setFormikProps] = useState({});

    useEffect(() => {
        const { dispatch } = props;
        dispatch(transferActions.preActivity());
        dispatch(templateActions.loadTemplateList("transferInternal"));
    }, []);

    useEffect(() => {
        const { dispatch } = props;
        const defaultAccount = accounts.find((account) => account.favorite) || accounts[0];
        setValues({ ...values, debitAccount: defaultAccount?.idProduct });
        dispatch(transferActions.accountLimitsActivities(defaultAccount?.idProduct, "transfers.internal.send"));
        if (formikProps?.setValues) {
            formikProps.setValues({ debitAccount: defaultAccount?.idProduct });
        }
    }, [accounts, internalTransfer]);

    const selectedCurrency = () => {
        const selectedAccount = accounts.find((account) => account.idProduct === values.debitAccount);
        return i18n.get(`currency.label.${selectedAccount?.currency}`);
    };

    const selectedAccount = () => accounts.find((account) => account.idProduct === values.debitAccount);

    const mapAccounts = accounts.map((account) => ({
        accountName: account.productAlias || account.shortLabel,
        amount: account.balance,
        currency: i18n.get(`currency.label.${account.currency}`),
        description: i18n.get(`productType.${account.productType}`),
        id: account.idProduct,
    }));

    const {
        isDesktop,
        limit,
        limitUsed,
        onSubmit,
        onSaveDraft,
        onCreateTemplate,
        tadWarningShowed,
        agendaVisible,
        onClickTadWaring,
    } = props;

    const handleCreateTemplate = (propsFormik) => {
        const { currency } = accounts.find((account) => account.idProduct === values.debitAccount);
        onCreateTemplate({ ...propsFormik.values, currency });
    };
    const parseAmount = (value) => {
        if (typeof value === "string") {
            return parseFloat(value.replaceAll(".", "").replace(",", "."));
        }
        return value;
    };

    const handleChangeAmount = (amount) => {
        const { currency } = accounts.find((account) => account.idProduct === values.debitAccount);
        setHint(parseAmount(amount), currency);
    };

    const referenceMaxLength = configUtils.getBoolean("frontend.transfers.reference.maxLength", 12);
    const idActivity = "transfers.internal.send";
    const idForm = "transferInternal";
    const inputList = [
        {
            colProps: {
                size: 12,
            },
            component: "accountSelect",
            id: "debitAccount",
            label: i18n.get("transfers.form.debit.account"),
            required: true,
            options: mapAccounts,
            defaultValue: values.debitAccount,
            value: values.debitAccount,
            onChange: (id) => handleDebitAccountSelect(id),
        },
        {
            colProps: {
                size: 12,
            },
            id: "limits",
            customElement: () => (
                <Limits
                    values={values}
                    limit={limit}
                    limitUsed={limitUsed}
                    isDesktop={isDesktop}
                    currency={selectedAccount()?.currency}
                />
            ),
        },
        {
            colProps: {
                size: 12,
            },
            component: "accountSelect",
            id: "creditAccount",
            label: i18n.get("transfers.form.credit.account"),
            required: true,
            options: mapAccounts.filter(
                (account) => account.id !== values.debitAccount && account.currency === selectedCurrency(),
            ),
            placeholder: i18n.get("transfers.form.credit.account.placeholder"),
        },
        {
            colProps: {
                size: 12,
            },
            component: "amountField",
            allowNegative: false,
            currency: selectedCurrency(),
            id: "amount",
            label: i18n.get("transfers.form.amount"),
            required: true,
            onBlur: (e, propsFormik) => handleChangeAmount(propsFormik.values.amount),
            maxLength: 24, // maximo de 18 numeros + puntos y comas
            afterElement: tadWarningShowed ? (
                <Box mx={12}>
                    <Button icon="Info" variant="text" onClick={onClickTadWaring} />
                </Box>
            ) : null,
            helper: tadWarningShowed ? (
                <Text color="primary" variant="caption">
                    {configUtils.get("frontend.TAD.warning.text")}
                </Text>
            ) : null,
        },
        {
            colProps: {
                size: 12,
            },
            component: "textField",
            id: "reference",
            label: i18n.get("transfers.form.reference"),
            placeholder: i18n.get("transfers.form.reference.placeholder"),
            maxLength: referenceMaxLength,
        },
        {
            colProps: {
                size: {
                    xs: 9,
                    lg: 5,
                },
            },
            id: "schedule",
            customElement: () =>
                (agendaVisible ? (
                    <Box>
                        <Button
                            text={i18n.get("transfers.form.schedule")}
                            icon="CalendarAdd"
                            variant="solid"
                            id="modal-button"
                            onClick={() => setIsScheduleModalVisible(true)}
                        />
                        <Message value={values.scheduler || { valueDate: "" }} />
                    </Box>
                ) : (
                    <></>
                )),
        },
        {
            colProps: {
                size: 12,
            },
            component: "checkbox",
            id: "sendMail",
            label: i18n.get("transfers.form.send.mail"),
            isChecked: values.sendMail,
            onChange: () => setValues({ ...values, sendMail: !values.sendMail }),
        },
    ];
    if (values.sendMail) {
        inputList.push(
            {
                colProps: {
                    size: 12,
                },
                component: "textField",
                id: "email",
                label: "Email",
                maxLength: 50,
                required: true,
            },
            {
                colProps: {
                    size: 12,
                },
                component: "textField",
                id: "emailText",
                label: "Mensaje",
                maxLength: 50,
            },
        );
    }
    return (
        <Box p={24}>
            <Form
                wrapButtons
                wrapButtonsFlex
                buttonSubmit={{
                    colProps: {
                        size: {
                            md: 3,
                            xs: 12,
                        },
                    },
                    text: i18n.get("transfers.form.continue"),
                    variant: "solid",
                }}
                initialValues={{ amount: 0 }}
                inputsList={inputList}
                buttonsList={[
                    {
                        colProps: {
                            size: {
                                xs: 12,
                                lg: 3,
                            },
                        },
                        w: isMobile ? "100%" : 200,
                        component: "button",
                        key: "draft",
                        text: i18n.get("transfers.form.save.draft"),
                        icon: "Save",
                        variant: "outline",
                        type: "button",
                        onClick: (e, propsFormik) => onSaveDraft(propsFormik.values),
                    },
                    {
                        colProps: {
                            size: {
                                xs: 12,
                                lg: 3,
                            },
                            offset: {
                                lg: 5,
                            },
                        },
                        w: isMobile ? "100%" : 200,
                        component: "button",
                        key: "template",
                        text: i18n.get("transfers.form.save.template"),
                        icon: "Save",
                        variant: "outline",
                        type: "button",
                        id: "modal-button",
                        onClick: (e, propsFormik) => handleCreateTemplate(propsFormik),
                    },
                    {
                        colProps: {
                            size: {
                                xs: 12,
                                lg: 3,
                            },
                            offset: {
                                lg: 10,
                            },
                        },
                        w: isMobile ? "100%" : 200,
                        component: "button",
                        key: "submit",
                        text: i18n.get("transfers.form.continue"),
                        icon: "ArrowRight",
                        variant: "solid",
                        iconVariant: "outline",
                        type: "submit",
                        onClick: () => {},
                    },
                ]}
                onSubmit={(v) => onSubmit(v)}
                setFormikProps={setFormikProps}
                rowSpacingForm={16}
            />
            <CreateTemplateModal values={values} idActivityTemplate={idActivity} idForm={idForm} />
        </Box>
    );
}

const mapStateToProps = (state) => ({
    accounts: transferSelectors.getAccounts(state),
    limit: transferSelectors.getLimit(state),
    limitUsed: transferSelectors.getLimitUsed(state),
});

export default withRouter(connect(mapStateToProps)(TransferInternalForm));
