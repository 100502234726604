import React, { Component } from "react";
import { string, arrayOf, func } from "prop-types";

import Checkbox from "pages/_components/fields/Checkbox";
import I18n from "pages/_components/I18n";

class SmartGroups extends Component {
    static propTypes = {
        name: string.isRequired,
        onChange: func.isRequired,
        productTypes: arrayOf(string).isRequired,
        value: arrayOf(string),
    };

    static defaultProps = {
        value: [],
    };

    render() {
        const { name, value, onChange, productTypes } = this.props;

        return (
            <div className="form-group">
                {productTypes.map((productType) => {
                    const inputValue = `ALL_${productType}`;

                    return (
                        <Checkbox
                            key={inputValue}
                            name={`${name}-${productType}`}
                            value={inputValue}
                            label={<I18n id={`productType.${productType}`} />}
                            onChange={onChange}
                            checked={value.includes(inputValue)}
                            formGroup={false}
                        />
                    );
                })}
            </div>
        );
    }
}

export default SmartGroups;
