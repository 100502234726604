import * as API from "middleware/api";
import * as configUtils from "util/config";

export const listRubros = (idAccount) => API.executeWithAccessToken("/list.paymentsCategory", { idAccount });

export const listEntities = (categoryCode) =>
    API.executeWithAccessToken("/list.paymentsEntity", { code: categoryCode });

export const getPaymentsConcept = (entityCode, cpe) =>
    API.executeWithAccessToken("/checkCPE.payments", { entityCode, cpe });

export const submitPayment = (summaryInfo, otp) => {
    // we do this to ensure that the service receive a valid reference
    const {
        paymentReference,
        selectedConcept: { minimumlengthTextReference },
    } = summaryInfo;
    const validateReferenceLength = summaryInfo.selectedConcept.validLengthReference;
    const validReference = validateReferenceLength
        ? paymentReference.padStart(parseInt(minimumlengthTextReference, 10), "0")
        : paymentReference;

    const useStatusCPE = configUtils.getBoolean("frontend.show.useStatusCPE.functionalities", false);

    return API.executeWithAccessToken("/payments.makePayment", {
        summaryInfo,
        debitAccount: summaryInfo.selectedDebitAccount.idProduct,
        idAccount: summaryInfo.selectedDebitAccount.idProduct,
        usuarioLP: useStatusCPE ? summaryInfo.statusCPE?.adhesion?.userLp : summaryInfo.selectedCPE,
        entityCode: summaryInfo.selectedEntity.code,
        amount: {
            quantity: summaryInfo.selectedAmountToPay,
            currency: "0",
        },
        referencia: validReference,
        codigoConcepto: summaryInfo.selectedConcept.conceptCode,
        extraInfoAccount: summaryInfo.selectedDebitAccount.extraInfo,
        productType: summaryInfo.selectedDebitAccount.productType,
        idDeuda: summaryInfo.debtId,
        _otp: otp,
    });
};

export const listAdhesiones = () => API.executeWithAccessToken("/list.adhesiones");

export const unattachAdhesion = (adhesion) =>
    API.executeWithAccessToken("/unattach.service", {
        adhesionCode: adhesion.adhesionCode,
        usuarioLp: adhesion.userLp,
    });

export const modifyAdhesionReference = (reference, adhesion) =>
    API.executeWithAccessToken("/adhesion.edit.reference", {
        newReference: reference,
        adhesionCode: adhesion.adhesionCode,
        usuarioLp: adhesion.userLp,
    });

export const addServiceReference = (summaryInfo) =>
    API.executeWithAccessToken("/adhesion.add.reference", {
        userLp: summaryInfo.statusCPE.adhesion.userLp,
        entityCode: summaryInfo.statusCPE.adhesion.enteCode,
        newReference: summaryInfo.serviceReference,
    });

export const listAgendaPagos = () => API.executeWithAccessToken("/list.agendaPago");

export const preparePayment = (paymentInfo) =>
    API.executeWithAccessToken("/payments.preparePayment", {
        selectedConceptCode: paymentInfo.conceptCode,
        selectedAmountToPay: paymentInfo.selectedAmountToPay,
        conceptPaymentType: paymentInfo.selectedConcept.paymentType,
        conceptManagesIncomeAmounts: paymentInfo.selectedConcept.managesIncomeAmounts,
        conceptManagesRange: paymentInfo.selectedConcept.managesRange,
        conceptOriginalAmount: paymentInfo.selectedConcept.originalAmount,
        conceptMinAmount: paymentInfo.selectedConcept.minAmount,
        conceptMaxAmount: paymentInfo.selectedConcept.maxAmount,
        validateReferenceLength: paymentInfo.selectedConcept.validLengthReference,
        minLengthTextReference: paymentInfo.selectedConcept.minimumlengthTextReference,
        maxLengthTextReference: paymentInfo.selectedConcept.maxLengthTextReference,
        managesIncomeReference: paymentInfo.selectedConcept.managesIncomeReference,
        _paymentReference: paymentInfo.paymentReference,
        selectedDebtId: paymentInfo.debtId,
        isAddServiceSelected: paymentInfo.isAddServiceSelected,
        idAccount: paymentInfo.selectedDebitAccount.idProduct,
    });

export const prepareMultiplePayments = (preparePayments, account) =>
    API.executeWithAccessToken("/payments.preparePayments", { preparePayments, idAccount: account });

export const submitMultiplePayments = ({ checkedPayments, completeDebitAccount, otp, userId }) =>
    API.executeWithAccessToken("/payments.makePayments", {
        payments: checkedPayments.map(({ entity, concept, summaryInfo, ...payment }) => ({
            summaryInfo,
            debitAccount: completeDebitAccount.idProduct,
            idAccount: completeDebitAccount.idProduct,
            usuarioLP: payment.ususerLP,
            entityCode: entity.code,
            amount: payment.amount.quantity,
            currency: "0",
            referencia: payment.paymentReference,
            codigoConcepto: concept.conceptCode,
            extraInfoAccount: completeDebitAccount.extraInfo,
            productType: completeDebitAccount.productType,
            idDeuda: concept?.debtId || "",
            _otp: otp,
        })),
        _otp: otp,
        userId,
    });
