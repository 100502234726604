import React from "react";
import Col from "react-bootstrap/lib/Col";
import { bool, string } from "prop-types";
import classNames from "classnames";

import GeneralMsg from "pages/_components/GeneralMsg";
import * as i18n from "util/i18n";

function ModalEmptyMessage({ isDesktop, i18nId }) {
    return (
        <Col xs={12} className="px-0">
            <div className={classNames({ "illustration-wrapper py-8": !isDesktop }, { "py-6": isDesktop }, "w-auto")}>
                <GeneralMsg
                    idEnviroment
                    imagePath="images/loupeInFile.svg"
                    description={i18n.get(i18nId)}
                    isDesktop={isDesktop}
                />
            </div>
        </Col>
    );
}

ModalEmptyMessage.propTypes = {
    isDesktop: bool.isRequired,
    i18nId: string,
};

ModalEmptyMessage.defaultProps = {
    i18nId: "global.list.noRecords",
};

export default ModalEmptyMessage;
