import React, { Component } from "react";
import { node, func, bool, shape } from "prop-types";

import ProductFilters from "pages/_components/ProductFilters";
import MainContainer from "pages/_components/MainContainer";

class ProductBody extends Component {
    static propTypes = {
        children: node.isRequired,
        fetching: func.isRequired,
        isDesktop: bool.isRequired,
        filters: shape().isRequired,
    };

    render() {
        const { children, fetching, filters, isDesktop, ...rest } = this.props;

        return (
            <>
                {filters && isDesktop && <ProductFilters {...rest}>{filters}</ProductFilters>}
                <MainContainer showLoader={fetching}>
                    <div className="above-the-fold">{children}</div>
                </MainContainer>
            </>
        );
    }
}

export default ProductBody;
