import React, { Component } from "react";
import { connect } from "react-redux";
import { bool } from "prop-types";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import classNames from "classnames";
import Yup from "yup";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import { actions as servicePaymentsActions } from "reducers/servicePayments";
import Col from "react-bootstrap/lib/Col";
import Notification from "pages/_components/Notification";
import isTokenActive from "util/token";

const FORM_ID = "new.check";

class ServicePaymentsTokenConfirmation extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        isDesktop: false,
    };

    render() {
        const { isSubmitting, isDesktop } = this.props;

        return (
            <Form className={`${!isDesktop && "token-mobile-form bg-white-mobile mb-0"}`}>
                <Notification scopeToShow="servicePayments" />
                <Col lg={12} xl={12} md={9} sm={12} className="p-0">
                    <Container
                        className="flex-grow align-items-center container-white pt-5 pb-3 mb-3"
                        gridClassName="form-content">
                        <Col className={classNames("px-lg-0", { "token-mobile": !isDesktop })} lg={4} md={9} sm={12}>
                            <Container className="flex-grow align-items-center" gridClassName="form-content">
                                {!isTokenActive(!isDesktop) ? (
                                    <div
                                        className={`admin-content-center ${!isDesktop &&
                                            "admin-content-center-mobile"}`}>
                                        <I18n id="transfer.summary.confirm.label" />
                                    </div>
                                ) : (
                                    <div className="admin-content-center pb-3">
                                        <I18n
                                            id="confirmation.withoutotp.text"
                                            component="p"
                                            componentProps={{ className: "confirmation__text-without-otp" }}
                                        />
                                    </div>
                                )}

                                <div className="admin-content-center">
                                    <Field
                                        placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                        name="otp"
                                        type="otp"
                                        idForm={FORM_ID}
                                        component={Credential}
                                    />
                                </div>
                            </Container>
                        </Col>
                    </Container>
                </Col>
                <Col className="col col-12" lg={12} xl={12} md={9} sm={12}>
                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                        <Col className={`${!isDesktop && "token-button-mobile"}`} lg={4} md={9} sm={12} xl={4}>
                            <Container className="flex-grow align-items-center" gridClassName="form-content">
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.confirm"
                                    image="images/arrowRight.svg"
                                    imageStyle={isDesktop ? "mr-2" : "mr-1"}
                                    loading={isSubmitting}
                                    className="text-capitalize"
                                />
                            </Container>
                        </Col>
                    </Container>
                </Col>
            </Form>
        );
    }
}

export default compose(
    connect(),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, paymentRequestData } = formikBag.props;

            dispatch(
                servicePaymentsActions.submitPayment(
                    { ...paymentRequestData, idActivity: "payments.makePayment" },
                    otp,
                    formikBag,
                ),
            );
        },
    }),
)(ServicePaymentsTokenConfirmation);
