import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { compose } from "redux";
import { connect } from "react-redux";
import Yup from "yup";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, func } from "prop-types";

import { actions as tokenActions, selectors as tokenSelectors } from "reducers/token";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18n from "util/i18n";
import * as config from "util/config";

import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import { push } from "react-router-redux/actions";
import TokenModal from "pages/settings/_components/token/TokenModal";

const FORM_ID = "settings.token.disabledTokenPass";

class DisabledTokenPass extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        isTokenBlocked: bool.isRequired,
    };

    renderTitleMobile = () => (
        <div>
            <h1 className="m-0">
                <I18n id="settings.token.disabledTokenPass.title" />
            </h1>
        </div>
    );

    recoverTokenPass = () => {
        const { dispatch } = this.props;

        dispatch(
            notificationActions.showNotification(
                i18n.get("settings.token.recoverPassword.password.notification"),
                "warning",
                ["recoverTokenPass"],
            ),
        );
        dispatch(push("/settings/token/recoverPass"));
    };

    renderButton = () => {
        const { isSubmitting, isDesktop } = this.props;

        return (
            <Container
                className={`flex-grow align-items-center ${!isDesktop ? "container-white" : ""}`}
                gridClassName="form-content">
                {isDesktop ? (
                    <Col sm={12} md={12} lg={12} xl={12} className="px-0">
                        <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                            <Button
                                label="setting.token.changeTokenPass.forget.password"
                                onClick={() => {
                                    this.recoverTokenPass();
                                }}
                                className="btn btn-outline mt-2 text-transform-none"
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                            <Button
                                label="global.confirm"
                                loading={isSubmitting}
                                type="submit"
                                bsStyle="primary"
                                className="mt-2"
                                image="images/arrowRight.svg"
                                imageStyle="mr-2"
                            />
                        </Col>
                    </Col>
                ) : (
                    <Col sm={12} md={12} lg={12} xl={12} className="px-0">
                        <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                            <Button
                                label="global.confirm"
                                loading={isSubmitting}
                                type="submit"
                                bsStyle="primary"
                                image="images/arrowRight.svg"
                                imageStyle="mr-2"
                                className="text-capitalize"
                            />
                        </Col>
                        <Col sm={12} md={6} lg={6} xl={6} className="col col-12 align-items-center">
                            <Button
                                label="setting.token.changeTokenPass.forget.password"
                                onClick={() => {
                                    this.recoverTokenPass();
                                }}
                                className="btn btn-outline text-transform-none"
                            />
                        </Col>
                    </Col>
                )}
            </Container>
        );
    };

    unlock = () => {
        const { dispatch } = this.props;

        dispatch(push("/settings/token/recoverPass"));
    };

    render() {
        const { isDesktop, isTokenBlocked } = this.props;
        const maxLength = config.getInteger("token.password.maxLength", 6);

        return (
            <>
                <Notification scopeToShow="changeTokenPassDisabled" />
                <div className="admin-detail-head px-0">
                    <Head
                        backLinkTo="/settings/token"
                        headerClassName={!isDesktop && "blue-main-header-mobile"}
                        centerElement={!isDesktop && this.renderTitleMobile}
                        hideMobileMenu={!isDesktop && true}
                    />
                    {isDesktop && (
                        <h1>
                            <I18n
                                id="settings.token.disabledTokenPass.title"
                                componentProps={{ style: { fontWeight: "normal" } }}
                            />
                        </h1>
                    )}
                    <TokenModal isDisplayed={isTokenBlocked} handleDismiss={this.unlock} isDesktop={isDesktop} />
                </div>
                <MainContainer>
                    <Form
                        className={
                            isDesktop ? "confirmation__container" : "above-the-fold bg-white-mobile h-100-mobile"
                        }>
                        <Container
                            className={
                                isDesktop
                                    ? "align-items-center flex-grow px-0 container--layout with-margin mb-0"
                                    : "container--layout flex-grow align-items-center cmf-container-white"
                            }
                            gridClassName="confirmation__form confirmation__box">
                            <Container className="form-content">
                                <Row className="justify-content-center">
                                    <I18n
                                        id="settings.token.disabledTokenPass.subtittle"
                                        component="h2"
                                        componentProps={{ className: "text-center px-2 pb-2" }}
                                    />
                                </Row>
                                <Row className="justify-content-center">
                                    <Col sm={12} md={12} lg={12} xl={12} className="col col-12 align-items-center">
                                        <div className="admin-content-center">
                                            <I18n
                                                id="settings.token.disabledTokenPass.info"
                                                component="h5"
                                                componentProps={{ className: "font-weight-normal" }}
                                            />
                                        </div>
                                        <div className="admin-content-center">
                                            <I18n
                                                id="transfer.summary.confirm.label"
                                                component="h5"
                                                componentProps={{ className: "font-weight-normal my-3" }}
                                            />
                                        </div>
                                        <div className="my-4">
                                            <Field
                                                type="otpMobile"
                                                name="password"
                                                idForm={FORM_ID}
                                                component={Credential}
                                                maxLength={maxLength}
                                            />
                                        </div>
                                        {this.renderButton()}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isTokenBlocked: tokenSelectors.getIsTokenBlocked(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: true,
        mapPropsToValues: () => ({ password: "" }),
        validationSchema: () =>
            Yup.lazy((values) => {
                if (values.password === undefined) {
                    return Yup.object().shape({
                        password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
                    });
                }

                return Yup.object().shape({
                    password: Yup.string()
                        .min(
                            config.getInteger("token.password.minLength", 4),
                            i18n.get(`settings.changePassword.modify.error.minLength`),
                        )
                        .max(
                            config.getInteger("token.password.maxLength", 6),
                            i18n.get(`settings.changePassword.modify.error.maxLength`),
                        ),
                });
            }),

        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(tokenActions.tokenPasswordDisabled(values.password, formikBag));
        },
    }),
)(DisabledTokenPass);
