import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";
import { push } from "react-router-redux";

import { selectors } from "reducers/checks";
import ContextMenu from "pages/_components/ContextMenu";
import { actions as servicePaymentsActions } from "reducers/servicePayments";
import DrawerModal from "pages/_components/drawer/DrawerModal";
import { adhesionShape } from "util/servicePayments";
import EditAdhesionReference from "./EditAdhesionReference";

class AdhesionContextMenu extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        adhesion: adhesionShape.isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        showDrawer: false,
        contentDrawer: "",
    };

    adhesionAction = (action, dispatch, adhesion) => {
        const { isDesktop } = this.props;
        dispatch(servicePaymentsActions.loadSummaryInfo(adhesion));

        switch (action) {
            case "EDIT_REFERENCE": {
                this.setState({
                    showDrawer: true,
                    contentDrawer: <EditAdhesionReference adhesion={adhesion} isDesktop={isDesktop} />,
                });
                break;
            }
            case "UNATTACH_SERVICE": {
                dispatch(push(`/desadherir/confirmation`));
                break;
            }

            default:
                break;
        }
    };

    adhesionActions = () => {
        const { dispatch, adhesion } = this.props;
        const contextMenuActions = [
            {
                label: `services.adhesiones.table.header.editReference`,
                onClick: () => this.adhesionAction("EDIT_REFERENCE", dispatch, adhesion),
            },
            {
                label: `services.adhesiones.table.header.unattachService`,
                onClick: () => this.adhesionAction("UNATTACH_SERVICE", dispatch, adhesion),
            },
        ];

        return contextMenuActions;
    };

    render() {
        const { isDesktop } = this.props;
        const { showDrawer, contentDrawer } = this.state;

        const itemsContextMenu = this.adhesionActions();

        return (
            <div style={{ position: isDesktop && "relative", left: isDesktop && "0.5rem" }}>
                {itemsContextMenu.length > 0 && (
                    <ContextMenu
                        styleContext={{ justifyContent: "flex-end", marginRight: 0 }}
                        isDesktop={isDesktop}
                        items={itemsContextMenu}
                    />
                )}
                {showDrawer && (
                    <DrawerModal
                        content={contentDrawer}
                        width={isDesktop ? "600px" : "100%"}
                        isDesktop={isDesktop}
                        showDrawerRemote={showDrawer}
                        closeRemotely={() => this.setState({ showDrawer: false, contentDrawer: "" })}
                        backButton={!isDesktop}
                        drawerClassName="edit-adhesion-drawer"
                        headerClassName="edit-adhesion-header"
                        contentClassName="edit-adhesion-drawer-content"
                        centerContentClassName="mx-5"
                        title={!isDesktop && "services.adhesiones.edit.reference.title"}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    echeqsList: selectors.getEcheqsList(state),
    environmentCuit: selectors.getEnvironmentCuit(state),
});

export default connect(mapStateToProps)(AdhesionContextMenu);
