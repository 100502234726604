import React from "react";
import { string } from "prop-types";
import * as i18nUtils from "util/i18n";
import Image from "pages/_components/Image";

const EcheqViewEmitted = ({
    day,
    month,
    year,
    payDay,
    payMonth,
    payYear,
    firstLine,
    secondLine,
    echeqNro,
    echeqStatus,
    echeqStatusColor,
    companyData,
    amount,
    order,
    largeNumber,
}) => {
    EcheqViewEmitted.propTypes = {
        day: string.isRequired,
        month: string.isRequired,
        year: string.isRequired,
        payDay: string.isRequired,
        payMonth: string.isRequired,
        payYear: string.isRequired,
        firstLine: string.isRequired,
        secondLine: string,
        echeqNro: string.isRequired,
        echeqStatus: string.isRequired,
        echeqStatusColor: string.isRequired,
        amount: string.isRequired,
        companyData: string.isRequired,
        order: string.isRequired,
        largeNumber: string.isRequired,
    };

    EcheqViewEmitted.defaultProps = {
        secondLine: "",
    };
    return (
        <>
            <div className="check-image emitted">
                <div className="d-flex space-between">
                    <div className="min-width-14">
                        <div className="d-flex flex-column medium-font">
                            <Image
                                src="images/logoCompanyColor.svg"
                                wrapperClassName="line-height-1"
                                className="check-logo mt-0"
                            />
                        </div>
                    </div>
                    <div className="text-right">
                        <div className="d-flex justify-content-right mt-2">
                            <div className="f-size-65 font-light text-uppercase align-item-center">
                                {i18nUtils.get("checksDetail.info.echeqNro")} {echeqNro}
                            </div>
                            <div
                                className="ml-25 px-2 align-item-center small bold"
                                style={{ background: echeqStatusColor }}>
                                {echeqStatus}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex space-between">
                    <div>
                        <div className="text-uppercase f-size-65 pt-2">{companyData}</div>
                    </div>
                    <div className="text-right">
                        <div className="medium-font f-size-25">{amount}</div>
                    </div>
                </div>
                <div className="d-flex space-between text-uppercase py-2">
                    <div className="font-light">
                        {i18nUtils.get("checksDetail.info.buenosAires")}
                        <span className="font-regular check-underline px-2 mx-2">{day}</span>
                        {i18nUtils.get("checksDetail.info.de")}
                        <span className="font-regular check-underline px-3 mx-2">{month}</span>
                        {i18nUtils.get("checksDetail.info.de")}
                        <span className="font-regular check-underline px-2 mx-2">{year}</span>
                    </div>
                    <div>
                        <div className={order === "NO A LA ORDEN" ? "bold red-border m-0" : " bold black-border m-0"}>
                            {order}
                        </div>
                    </div>
                </div>
                <div className="d-flex text-uppercase font-light pt-1">
                    {i18nUtils.get("checksDetail.info.el")}
                    <span className="font-regular check-underline px-2 mx-2">{payDay}</span>
                    {i18nUtils.get("checksDetail.info.de")}
                    <span className="font-regular check-underline px-3 mx-2">{payMonth}</span>
                    {i18nUtils.get("checksDetail.info.de")}
                    <span className="font-regular check-underline px-2 mx-2">{payYear}</span>
                </div>
                <div className="d-flex w-100 pt-1">
                    <span className="text-uppercase font-light no-wrap add-colon">
                        {i18nUtils.get("checksDetail.info.pagueseA")}
                    </span>
                    <span className="check-underline px-2 ml-2 w-100">{firstLine}</span>
                </div>
                <div className="check-underline pt-1">{secondLine}</div>
                <div>{largeNumber}</div>
            </div>
        </>
    );
};

export default EcheqViewEmitted;
