import React from "react";
import { string, shape, bool } from "prop-types";

import Heading from "pages/_components/Heading";
import * as i18nUtils from "util/i18n";
import * as accountUtils from "util/accounts";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";

export default function IncomingDebinSummaryData({ summary, signature, debinDetail, accountName, FORM_ID }) {
    const {
        sellerDetail,
        amount: { currency, quantity },
        idVoucher,
        concept,
        expirationDate,
    } = debinDetail;

    IncomingDebinSummaryData.propTypes = {
        summary: shape({}).isRequired,
        debinDetail: shape({}).isRequired,
        signature: bool,
        accountName: string.isRequired,
        FORM_ID: string.isRequired,
    };
    IncomingDebinSummaryData.defaultProps = {
        signature: false,
    };

    const PreparedBy = () => (
        <p>
            {summary?.creatorFullName} - <FormattedDate date={summary?.creationDate} dateFormat="dd/MM/yyyy" showTime />
        </p>
    );

    const SignBy = () => (
        <p>
            {summary?.signatures.map((object) => (
                <>
                    {object?.userFirstName.concat(" ", object?.userLastName)} -{" "}
                    <FormattedDate date={object?.creationDateTime} dateFormat="dd/MM/yyyy" showTime />
                    <br />
                </>
            ))}
        </p>
    );

    return (
        <>
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.confirm.table.header.id`}
                data={summary?.idDebin}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.confirm.table.header.debitAccount`}
                data={accountName}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.confirm.table.header.cbu`}
                data={sellerDetail.cbu}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.confirm.table.header.creditAccount`}
                data={accountUtils.getAccountNumber(sellerDetail.cbu)}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.detail.table.header.documentType`}
                data={
                    <div>
                        <span style={{ marginRight: "0.5rem" }}>{sellerDetail.documentType}</span>
                        <span>{sellerDetail.documentNumber}</span>
                    </div>
                }
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.detail.table.header.sender`}
                data={sellerDetail.titular}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.confirm.table.header.bank`}
                data={sellerDetail.bank}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.detail.table.header.reference`}
                data={debinDetail.reference}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.detail.table.header.amount`}
                data={<FormattedAmount currency={currency} quantity={quantity} notBold />}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.detail.table.header.voucher`}
                data={idVoucher}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.detail.table.header.concept`}
                data={i18nUtils.get(`transfers.concept.${concept}`)}
            />
            <Heading.DataGroup
                containerClassName="transfer-data data-wrapper-flex"
                label={`${FORM_ID}.table.header.expiration`}
                data={<FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />}
            />
            {signature && (
                <>
                    <I18n
                        component="h3"
                        id="accounts.new.account.signatures.label"
                        componentProps={{ className: "font-size-25-px" }}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex"
                        label="accounts.new.account.signatures.preparedBy.label"
                        data={<PreparedBy />}
                    />
                    {summary?.signatures?.length > 0 && (
                        <>
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                label="accounts.new.account.signatures.signBy.label"
                                data={<SignBy />}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}
