import React, { Component } from "react";
import { bool, shape, number, string } from "prop-types";
import { connect } from "react-redux";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";
import classNames from "classnames";

class SelectedItemsAmount extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        summaryViewMoreStyles: shape({}),
        leftOperations: bool,
        className: string,
        amount: number.isRequired,
        itemsAmount: number.isRequired,
        amountText: string.isRequired,
        justify: string,
    };

    static defaultProps = {
        justify: "left",
        className: "",
        summaryViewMoreStyles: {},
        leftOperations: false,
    };

    render() {
        const {
            amountText,
            justify,
            isDesktop,
            itemsAmount,
            summaryViewMoreStyles,
            leftOperations,
            amount,
            className,
        } = this.props;

        return (
            <div style={{ ...summaryViewMoreStyles }} className={className}>
                <div
                    className={classNames("d-flex", `justify-content-${justify}`, {
                        "f-size-55": !isDesktop,
                        "f-size-35": isDesktop,
                    })}>
                    <span
                        className={classNames("medium-font", {
                            "text-right": !leftOperations,
                        })}>
                        {i18n.get("transactions.selected.total.amount").toUpperCase()}
                    </span>
                    <FormattedAmount className="medium-font px-0" currency="0" quantity={amount} notBold ml />
                </div>
                <div
                    className={classNames({
                        "f-size-65 pb-3": !isDesktop,
                        "f-size-55": isDesktop,
                        "align-left text-left": leftOperations,
                        "align-right text-right": !leftOperations,
                    })}>
                    {i18n.get(amountText).concat(" ", itemsAmount)}
                </div>
            </div>
        );
    }
}

export default connect()(SelectedItemsAmount);
