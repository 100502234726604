import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";
import { arrayOf, bool, func, number, shape, string } from "prop-types";

import { selectors as settingsSelectors } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as communicationActions } from "reducers/communication";
import { actions as communicationsActions } from "reducers/communications";
import { getLastLoginDate, getLastLoginPlace } from "util/settings";

import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import GoogleMapContainer from "pages/settings/_components/GoogleMap";

import * as i18n from "util/i18n";

class LastLogin extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isMobileNative: bool.isRequired,
        previousLoginInfo: arrayOf(
            shape({
                city: string,
                country: string,
                date: string,
                idUser: string,
                ip: string,
                latitude: number,
                longitude: number,
            }),
        ),
    };

    static defaultProps = {
        previousLoginInfo: null,
    };

    handleReportClick = (event) => {
        const { dispatch, isDesktop, isMobileNative } = this.props;

        if (isMobileNative) {
            event.preventDefault();
            window.open("tel:+9999999999", "_system", "location=yes");
        } else if (isDesktop) {
            event.preventDefault();
            dispatch(
                communicationActions.preloadedMessage({
                    subject: i18n.get("communications.suspectActivity.defaultSubject"),
                }),
            );
            dispatch(communicationsActions.showComposePanel());
            dispatch(push("/communications"));
        }
    };

    render() {
        const { previousLoginInfo } = this.props;

        return (
            <Fragment>
                <Head title="settings.lastLogin.head" closeLinkTo="/settings" />
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="align-items-center container--layout">
                            <Col sm={12} md={9} lg={6} xl={6} className="col">
                                <ul className="data-list">
                                    <li className="data-wrapper data-wrapper-inline">
                                        <span className="data-label add-colon">
                                            <I18n id="settings.lastLogin.date" />
                                        </span>{" "}
                                        <span className="data-desc">{getLastLoginDate(previousLoginInfo[0])}</span>
                                    </li>
                                    <li className="data-wrapper data-wrapper-inline">
                                        <span className="data-label add-colon">
                                            <I18n id="settings.lastLogin.from" />
                                        </span>{" "}
                                        <span className="data-desc">{getLastLoginPlace(previousLoginInfo[0])}</span>
                                    </li>
                                </ul>
                            </Col>
                        </Container>

                        <section className="container--layout flex-grow googleMap">
                            <GoogleMapContainer google={window.google} positions={previousLoginInfo} />
                        </section>

                        <Container className="align-items-center container--layout">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <a
                                    href="tel:+9999999999"
                                    onClick={this.handleReportClick}
                                    className="btn btn-primary btn-block"
                                    style={{ color: "white" }}>
                                    {" "}
                                    <I18n id="settings.lastLogin.report" />
                                </a>
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

export default connect((state) => ({
    ...sessionSelectors.getUser(state),
    errors: settingsSelectors.getErrors(state),
}))(LastLogin);
