/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, arrayOf, func, string } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as accountsSelectors } from "reducers/accounts";
import { getAccountDescription, maskedAccountNumber } from "util/accounts";
import { statusTagColor } from "util/debin";
import FormattedAmount from "pages/_components/FormattedAmount";
import InfoTag from "pages/_components/InfoTag";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import Card from "pages/_components/Card/Card";
import CardContainer from "pages/_components/Card/CardContainer";
import ContextMenu from "pages/_components/ContextMenu";
import { Link } from "react-router-dom";
import { actions as debinActions } from "reducers/debin";
import IncomingDebinModal from "./IncomingDebinModal";

class IncomingDebinItem extends Component {
    static propTypes = {
        request: shape({}).isRequired,
        isDesktop: shape({}).isRequired,
        accounts: arrayOf(shape({})).isRequired,
        dispatch: func.isRequired,
        link: string.isRequired,
    };

    state = {
        modalDisplayed: false,
    };

    accountDescription = (cbu) => {
        const { accounts } = this.props;
        const accountInfo = accounts.find((account) => account.cbu === cbu);
        if (accountInfo) {
            const { number, productType, currency } = accountInfo;

            return getAccountDescription({ number, productType, currency });
        }

        return maskedAccountNumber(cbu.substring(cbu.length - 8));
    };

    handleModal = () => {
        this.setState({ modalDisplayed: true });
    };

    handleAccept = (e) => {
        const { dispatch, request } = this.props;
        const { id } = request;
        e.preventDefault();
        this.setState({ modalDisplayed: false });
        dispatch(debinActions.acceptIncomingRequestPreRedirect(id, `/payments/payDebin/accept/confirm`));
    };

    handleDecline = (e) => {
        const { dispatch, request } = this.props;
        const { id } = request;
        e.preventDefault();
        this.setState({ modalDisplayed: false });
        dispatch(debinActions.acceptIncomingRequestPreRedirect(id, `/payments/payDebin/decline/confirm`));
    };

    handleCancel = (e) => {
        e.preventDefault();
        this.setState({ modalDisplayed: false });
    };

    render() {
        const { request, isDesktop, link } = this.props;
        const { modalDisplayed } = this.state;
        const {
            id,
            expirationDate,
            seller: { titular, documentType, documentNumber },
            amount: { currency, quantity },
            status: { code },
            buyer: { cbu }, // hay que armar despues con esto la cuenta de debito
        } = request;

        if (isDesktop) {
            return (
                <>
                    <IncomingDebinModal
                        isDisplayed={modalDisplayed}
                        isDesktop={isDesktop}
                        acceptClick={this.handleAccept}
                        cancelClick={this.handleCancel}
                    />
                    <CardContainer renderAs={Link} to={link}>
                        <Card
                            className="mb-0"
                            title={
                                <div className="debin__card-title">
                                    <div className="debin__date">
                                        <I18n
                                            id="payments.debin.table.header.expiration"
                                            componentProps={{ className: "debin__date-info" }}
                                        />
                                        <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                                    </div>
                                </div>
                            }
                            content={
                                <div className="debin__card-content">
                                    <div className="debin__cuit">
                                        <I18n
                                            id="payments.debin.table.header.documentType"
                                            componentProps={{ className: "debin__cuit-info" }}
                                        />
                                        <span className="debin__cuit-data">{`${documentType} ${documentNumber}`}</span>
                                    </div>
                                    <div className="debin__account">
                                        <I18n
                                            id="payments.debin.table.header.applicant"
                                            componentProps={{ className: "debin__account-info" }}
                                        />
                                        <span className="debin__account-data">{titular}</span>
                                    </div>
                                </div>
                            }
                            bottom={
                                <div className="debin__card-bottom">
                                    <div className="debin__id">
                                        <I18n
                                            id="payments.debin.table.header.id"
                                            componentProps={{ className: "debin__id-info" }}
                                        />
                                        <span className="debin__id-data">{id}</span>
                                    </div>
                                    <div className="debin__debit">
                                        <I18n
                                            id="payments.debin.table.header.debitAccount"
                                            componentProps={{ className: "debin__debit-info" }}
                                        />
                                        <span className="debin__debit-data">{this.accountDescription(cbu)}</span>
                                    </div>
                                </div>
                            }
                            rightContent={
                                <div className="debin__card-right">
                                    <div className="debin__balance">
                                        <FormattedAmount
                                            currency={currency}
                                            quantity={quantity}
                                            className="debin__balance-currency"
                                        />
                                        <InfoTag
                                            type="info"
                                            message={code}
                                            color="#000"
                                            tagBackground={statusTagColor(code)}
                                        />
                                    </div>
                                    <div className="debin__menu">
                                        {code === "INICIADO" ? (
                                            <ContextMenu
                                                styleContext={{ justifyContent: "flex-end" }}
                                                isDesktop={isDesktop}
                                                items={[
                                                    {
                                                        label: "payments.debin.table.menu.accept",
                                                        onClick: this.handleModal,
                                                    },
                                                    {
                                                        label: "payments.debin.table.menu.decline",
                                                        onClick: this.handleDecline,
                                                    },
                                                ]}
                                            />
                                        ) : (
                                            <div className="debin__hidden-menu">&nbsp;</div>
                                        )}
                                    </div>
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        }

        return (
            <>
                <IncomingDebinModal
                    isDisplayed={modalDisplayed}
                    isDesktop={isDesktop}
                    acceptClick={this.handleAccept}
                    cancelClick={this.handleCancel}
                />
                <CardContainer className="mt-0" renderAs={Link} to={link}>
                    <Card
                        className="mt-0"
                        title={
                            <div className="debin__card-title">
                                <div className="space-between w-100">
                                    <div>
                                        <div className="debin__account">
                                            <I18n
                                                id="payments.debin.table.header.applicant"
                                                componentProps={{ className: "debin__account-info" }}
                                            />
                                            <span className="debin__account-data">{titular}</span>
                                        </div>
                                        <div className="debin__cuit">
                                            <I18n
                                                id="payments.debin.table.header.documentType"
                                                componentProps={{ className: "debin__cuit-info" }}
                                            />
                                            <span className="debin__cuit-data">{`${documentType} ${documentNumber}`}</span>
                                        </div>
                                    </div>
                                    {code === "INICIADO" && (
                                        <div className="debin__menu">
                                            <button
                                                type="button"
                                                className="btn btn-link no-shadow m-0 p-0 pl-2"
                                                onClick={(e) => e.stopPropagation()}>
                                                <ContextMenu
                                                    styleContext={{ justifyContent: "flex-end" }}
                                                    isDesktop={isDesktop}
                                                    items={[
                                                        {
                                                            label: "payments.debin.table.menu.accept",
                                                            onClick: this.handleModal,
                                                        },
                                                        {
                                                            label: "payments.debin.table.menu.decline",
                                                            onClick: this.handleDecline,
                                                        },
                                                    ]}
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <hr className="mr-0" />
                            </div>
                        }
                        content={
                            <div className="debin__card-content">
                                <div className="debin__state">
                                    <I18n
                                        id="payments.debin.table.header.status"
                                        componentProps={{ className: "debin__state-info" }}
                                    />
                                    <InfoTag
                                        type="info"
                                        message={code}
                                        color="#000"
                                        tagBackground={statusTagColor(code)}
                                    />
                                </div>
                                <div className="debin__date">
                                    <I18n
                                        id="payments.debin.table.header.expiration"
                                        componentProps={{ className: "debin__date-info" }}
                                    />
                                    <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                                </div>
                                <div className="debin__idpay">
                                    <I18n
                                        id="payments.debin.table.header.applicant"
                                        componentProps={{ className: "debin__id-info" }}
                                    />
                                    <span className="debin__id-data">{id}</span>
                                </div>
                                <div className="debin__debit">
                                    <I18n
                                        id="payments.debin.table.header.debitAccount"
                                        componentProps={{ className: "debin__debit-info" }}
                                    />
                                    <span className="debin__debit-data">{this.accountDescription(cbu)}</span>
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="debin__card-right">
                                <div className="debin__balance">
                                    <FormattedAmount
                                        currency={currency}
                                        quantity={quantity}
                                        className="debin__balance-currency"
                                    />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    accounts: accountsSelectors.getAccounts(state),
});

export default connect(mapStateToProps)(IncomingDebinItem);
