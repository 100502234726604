/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, func, string, bool } from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";

import Notification from "pages/_components/Notification";
import TransactionTicket from "pages/_components/TransactionTicket";
import ModifyPendingTransactionModal from "pages/transactions/_components/ModifyPendingTransactionModal";
import AccountSummaryData from "pages/accounts/AccountSummaryData";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as formActions } from "reducers/form";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as statusSelectors } from "reducers/status";
import { selectors as transactionsSelectors, actions as transactionsActions } from "reducers/transactions";

import { downloadPdf } from "util/download";
import * as i18nUtils from "util/i18n";
import * as accountsMiddleware from "middleware/accounts";
import AccountOpeningData from "./AccountOpeningData";

class AccountOpeningTicket extends Component {
    static propTypes = {
        openingData: shape({}).isRequired,
        dispatch: func.isRequired,
        fullName: string.isRequired,
        isDesktop: bool.isRequired,
        lastPathname: string.isRequired,
        fetching: bool.isRequired,
    };

    state = {
        isModalDisplayed: false,
    };

    componentDidMount() {
        const { openingData, dispatch } = this.props;
        if (!openingData) {
            dispatch(push("/accountOpening"));
        }
        dispatch(transactionsActions.getPotentialSignaturesData(openingData.idTransaction));
    }

    exportPdf = async () => {
        const { openingData } = this.props;
        const { currency, accountType, message, accountNumber, cbu, accountStatus, creationDate } = openingData;
        const idComprobante = openingData?.idTransaction.substring(0, 8).toUpperCase();
        const { data } = await accountsMiddleware.accountOpeningConfirmationPdf(
            currency,
            accountType,
            message,
            accountNumber,
            cbu,
            accountStatus,
            idComprobante,
            creationDate,
        );
        const { content, fileName } = data.data;
        downloadPdf(fileName, content);
    };

    centerContentMobile = () => (
        <div>
            <h1 className="w-100 m-0 px-2">{i18nUtils.get("accounts.new.account.transaction.success.label")}</h1>
        </div>
    );

    signTransaction = () => {
        const { dispatch, openingData, fullName } = this.props;

        const signTransactionData = (
            <AccountSummaryData
                openingData={openingData}
                FORM_ID="accounts.new.account"
                signature
                fullName={fullName}
            />
        );

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: openingData?.idTransaction,
                idActivity: openingData.idActivity,
                idForm: "",
                content: signTransactionData,
                title: "new.account",
                pendingDispatch: openingData?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: openingData.idActivity,
                idTransaction: openingData.idTransaction,
                idForm: "",
                ticketData: openingData,
            }),
        );
        dispatch(accountsActions.accountOpeningData({ ...openingData, action: "sign" }));
    };

    handleDismiss = () => {
        this.setState({ isModalDisplayed: false });
    };

    buttonsDetailPending = () => [
        {
            label: "global.modify",
            bsStyle: "outline",
            onClick: () => this.setState({ isModalDisplayed: true }),
        },
    ];

    buttonsDetailFinished = () => {
        const { dispatch } = this.props;

        return [
            {
                label: "new.check.right.buttom",
                bsStyle: "primary",
                onClick: () => dispatch(push("/desktop")),
            },
        ];
    };

    render() {
        const { openingData, isDesktop, dispatch, lastPathname, fetching } = this.props;
        const { isModalDisplayed } = this.state;
        const isPending = openingData?.idTransactionStatus === "PENDING";

        return (
            <>
                <Notification scopeToShow="accountOpeningConfirmation" />
                <TransactionTicket
                    handleClick={this.exportPdf}
                    handleClickMessage="global.download"
                    handleBack={() => dispatch(push(lastPathname))}
                    uniqueDownload={openingData?.idTransactionStatus === "FINISHED"}
                    downloadImageWhite={!isDesktop}
                    headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                    centerElement={!isDesktop && this.centerContentMobile}
                    emptyRightOption={isPending}
                    hasInlineButtons
                    hideMobileMenu={!isDesktop}
                    summary={openingData}
                    buttonsDetail={isPending ? this.buttonsDetailPending() : this.buttonsDetailFinished()}
                    isDesktop={isDesktop}
                    fetching={fetching}
                    onSignTicket={this.signTransaction}
                    summaryTitle="accounts.new.account.internal.label">
                    <ModifyPendingTransactionModal
                        isDesktop={isDesktop}
                        isDisplayed={isModalDisplayed}
                        handleDismiss={this.handleDismiss}
                        dispatch={dispatch}
                        idTransaction={openingData.idTransaction}
                        cancelTransactionData={() => (
                            <AccountSummaryData
                                openingData={openingData}
                                signature
                                FORM_ID="accounts.new.account"
                                fullName={openingData.fullName}
                            />
                        )}
                    />
                    <AccountOpeningData />
                </TransactionTicket>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    openingData: accountsSelectors.getAccountOpeningData(state),
    fetching: accountsSelectors.getFetching(state),
    fullName: sessionSelectors.getUser(state).userFullName,
    potentialSignaturesData: transactionsSelectors.getPotentialSignaturesData(state),
    lastPathname: statusSelectors.getPathname(state),
});

export default connect(mapStateToProps)(AccountOpeningTicket);
