import React from "react";
import { string, bool } from "prop-types";
import * as i18nUtils from "util/i18n";
import Image from "pages/_components/Image";

function ChecksDetailViewEmitted({
    payDay,
    payMonth,
    payYear,
    firstLine,
    secondLine,
    echeqNro,
    denominacionLibrador,
    denominacionLibradorNro,
    amount,
    order,
    largeNumber,
    showOrder,
}) {
    ChecksDetailViewEmitted.propTypes = {
        payDay: string.isRequired,
        payMonth: string.isRequired,
        payYear: string.isRequired,
        firstLine: string.isRequired,
        secondLine: string,
        echeqNro: string.isRequired,
        amount: string.isRequired,
        denominacionLibrador: string.isRequired,
        denominacionLibradorNro: string.isRequired,
        order: string.isRequired,
        largeNumber: string.isRequired,
        showOrder: bool,
    };

    ChecksDetailViewEmitted.defaultProps = {
        secondLine: "",
        showOrder: true,
    };

    return (
        <>
            <div className="check-image emitted">
                <div className="d-flex space-between">
                    <div className="w-50">
                        <div className="d-flex flex-column medium-font line-height-normal">
                            <Image
                                src="images/logoCompanyColor.svg"
                                wrapperClassName="justify-content-start"
                                className="max-width-8rem mt-0"
                            />
                        </div>
                        <div className="f-size-65 pt-2">
                            {denominacionLibrador} - {denominacionLibradorNro}
                        </div>
                    </div>
                    <div className="w-50 text-right">
                        <div className="font-light">
                            {i18nUtils.get("checksDetail.info.chequeNro")} {echeqNro}
                        </div>
                        <div className="medium-font f-size-25">{amount}</div>
                        <div className={showOrder && (order === "NO A LA ORDEN" ? "red-border" : "black-border")}>
                            {order}
                        </div>
                    </div>
                </div>
                <div className="d-flex text-uppercase font-light pt-1">
                    {i18nUtils.get("checksDetail.info.el")}
                    <span className="font-regular check-underline px-2 mx-2">{payDay}</span>
                    {i18nUtils.get("checksDetail.info.de")}
                    <span className="font-regular check-underline px-3 mx-2">{payMonth}</span>
                    {i18nUtils.get("checksDetail.info.de")}
                    <span className="font-regular check-underline px-2 mx-2">{payYear}</span>
                </div>
                <div className="d-flex w-100 pt-1">
                    <span className="text-uppercase font-light no-wrap add-colon">
                        {i18nUtils.get("checksDetail.info.pagueseA")}
                    </span>
                    <span className="check-underline px-2 ml-2 w-100">{firstLine}</span>
                </div>
                <div className="check-underline pt-1">{secondLine}</div>
                <div>{largeNumber}</div>
            </div>
        </>
    );
}

export default ChecksDetailViewEmitted;
