/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { bool, number, oneOfType, shape, string } from "prop-types";

import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";

class ListItemMobileAccount extends Component {
    static propTypes = {
        amount: number.isRequired,
        amountLabel: string.isRequired,
        currency: string.isRequired,
        name: string.isRequired,
        favorite: bool.isRequired,
        path: oneOfType([string, shape({ pathname: string })]).isRequired,
        reference: string.isRequired,
        title: string.isRequired,
        franchise: string,
        productTypeTitle: string.isRequired,
        idProduct: string.isRequired,
        balanceDiffers: string.isRequired,
    };

    static defaultProps = {
        franchise: "",
    };

    getContent = () => {
        const { amount, amountLabel, currency, name, favorite, reference, title, balanceDiffers } = this.props;

        const nameWithN = /^\*\*\*\*\d+/.test(name) ? `${i18nUtils.get(`global.numero`)} ${name}` : name;

        return (
            <CardContainer className="accounts__card">
                <Card
                    iconWithText={favorite ? <Image src="images/favorito.svg" /> : <Image src="images/billetera.svg" />}
                    title={
                        <div className="accounts__card-title">
                            <span>{nameWithN}</span>
                        </div>
                    }
                    content={
                        <div className="accounts__card-content">
                            <span className="accounts__card-content-title">{title}</span>
                            <span className="accounts__card-reference">{reference}</span>
                        </div>
                    }
                    rightContent={
                        <div className="accounts__card-right">
                            <div className="accounts__card-balance">
                                <span className="accounts__card-balance-info">{amountLabel}</span>
                                <FormattedAmount
                                    quantity={amount}
                                    currency={currency}
                                    className={`accounts__card-balance-data ${amount < 0 ? "red" : ""}`}
                                    cleanClassName
                                />
                                {balanceDiffers && (
                                    <span className="accounts__card-balance-differs">
                                        <I18n id="accounts.balance.differs.enabled" component="span" />
                                    </span>
                                )}
                            </div>
                        </div>
                    }
                />
            </CardContainer>
        );
    };

    render() {
        const { name, path, franchise, productTypeTitle, idProduct } = this.props;

        if (path) {
            return (
                <Link
                    aria-label={`${productTypeTitle}: ${franchise !== "" ? `${franchise} ` : ""}${name}`}
                    aria-describedby={idProduct}
                    to={path}
                    className="widget__card-accounts"
                    role="menuitem">
                    {this.getContent()}
                </Link>
            );
        }
        return this.getContent();
    }
}

export default ListItemMobileAccount;
