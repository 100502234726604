export const shouldKeepFormState = ({ state, pathname }, prevRoute) => {
    if (state) {
        return !state.shouldLoadForm;
    }
    if (!prevRoute.pathname.includes(pathname)) {
        return false;
    }
    return false;
};

export default { shouldKeepFormState };
