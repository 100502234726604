import React from "react";
import { string, node, func, oneOfType } from "prop-types";
import cx from "classnames";

function CardContainer({ children, renderAs: Component, className, ...props }) {
    return (
        <Component className={cx("card__component-container", className)} {...props}>
            {children}
        </Component>
    );
}

CardContainer.propTypes = {
    children: node.isRequired,
    className: string,
    renderAs: oneOfType([func, string]),
};

CardContainer.defaultProps = {
    className: "",
    renderAs: "div",
};

export default CardContainer;
