import React, { Component } from "react";
import NavLink from "react-router-dom/NavLink";
import { string, node, func, bool, number } from "prop-types";

class NavItem extends Component {
    static propTypes = {
        children: node.isRequired,
        className: string,
        disabled: bool,
        href: string.isRequired,
        onClick: func,
        tabIndex: number,
    };

    static defaultProps = {
        className: "",
        disabled: false,
        onClick: null,
        tabIndex: null,
    };

    render() {
        const { href, className, children, onClick, disabled, tabIndex } = this.props;

        return (
            <li>
                <NavLink
                    to={href}
                    className={className}
                    onClick={!disabled ? onClick : null}
                    disabled={disabled}
                    tabIndex={tabIndex}
                    activeClassName="is-active"
                    replace>
                    {children}
                </NavLink>
            </li>
        );
    }
}

export default NavItem;
