export const statuses = [
    { value: "PENDING", label: "PENDIENTE DE FIRMA" },
    { value: "PROCESSING", label: "FIRMA COMPLETA" },
    { value: "CANCELLED", label: "FIRMA RECHAZADA" },
    { value: "RETURNED", label: "FIRMA PENDIENTE VENCIDA" },
    { value: "REVOKED", label: "ANULADO" },
    { value: "FAILED", label: "FALLO" },
    { value: "FINISHED", label: "ENVIADO AL BANCO" },
    { value: "1", label: "PROCESADO CONFORME" },
    { value: "2", label: "PROCESADO CON RECHAZOS" },
];

export const retailStatuses = [
    { value: "PROCESSING", label: "FIRMA COMPLETA" },
    { value: "FINISHED", label: "ENVIADO AL BANCO" },
    { value: "1", label: "PROCESADO CONFORME" },
    { value: "2", label: "PROCESADO CON RECHAZOS" },
];

export const getCodeMsg = (code) => {
    if (code === "PROCESSING") {
        return "FIRMA COMPLETA";
    }
    if (code === "FINISHED") {
        return "ENVIADO AL BANCO";
    }
    if (code === "PENDING") {
        return "PENDIENTE DE FIRMA";
    }
    if (code === "CANCELLED") {
        return "ANULADO";
    }
    if (code === "FAILED") {
        return "FALLO";
    }
    return code;
};

export const statusTagColor = (state) => {
    switch (state) {
        case "PENDING":
            return "#ffe5b6";
        // FIRMA COMPLETA
        case "PROCESSING":
            return "#ffe5b6";
        case "FINISHED":
            return "#ffe5b6";
        case "PROCESADO CONFORME":
            return "#84EFD0";
        // GRIS
        case "PROCESADO CON RECHAZOS":
            return "#c7c7c8";
        case "FIRMA PENDIENTE VENCIDA":
            return "#feb0af";
        case "CANCELLED":
            return "#feb0af";
        case "FIRMA RECHAZADA CLIENTE":
            return "#feb0af";
        case "FAILED":
            return "#feb0af";

        default:
            return "#84efd0";
    }
};

export const statusByState = [
    {
        value: `FORMAT_ERROR`,
        label: ` `,
    },
    {
        value: `PROCESSED`,
        label: `PROCESSED`,
    },
    {
        value: `CANCEL`,
        label: `ANULADO`,
    },
    {
        value: `VENCIDO`,
        label: `FIRMA PENDIENTE VENCIDA`,
    },
    {
        value: `PENDING`,
        label: `PENDING`,
    },

    {
        value: `PROCESSING`,
        label: `ENVIADO AL BANCO`,
    },
    {
        value: `1`,
        label: `PROCESADO CONFORME`,
    },
    {
        value: `2`,
        label: `PROCESADO CON RECHAZOS`,
    },
    {
        value: `REFUSED`,
        label: `REFUSED`,
    },
    {
        value: `NO_INFORMATION`,
        label: `NO_INFORMATION`,
    },
];
