import { routerActions } from "react-router-redux";

import * as administrationSimple from "middleware/administration/simple";
import * as administrationAdvanced from "middleware/administration/advanced";
import {
    channelsActions,
    channelsTypes,
    channelsTicketActions,
    channelsTicketTypes,
    detailsActions,
    detailsTypes,
    groupsActions,
    groupsTypes,
    groupsOfUserTicketActions,
    groupsOfUserTicketTypes,
    signaturesSchemesActions,
    signaturesSchemesTypes,
    signaturesSchemesTicketActions,
    signaturesSchemesTicketTypes,
    signatureTicketActions,
    signatureTicketTypes,
    permissionsActions,
    permissionsTypes,
} from "reducers/administration/advanced";

import channelsSagasCreator from "sagas/administration/common/channels";
import channelsTicketSagasCreator from "sagas/administration/common/channelsTicket";
import detailsSagasCreator from "sagas/administration/common/details";
import groupFormDataSagas from "sagas/administration/groupFormData";
import groupSagas from "sagas/administration/group";
import groupsOfUserSagasCreator from "sagas/administration/common/groupsOfUser";
import groupsOfUserTicketSagasCreator from "sagas/administration/common/groupsOfUserTicket";
import permissionsSagasCreator from "sagas/administration/common/permissions";
import signaturesSchemesSagasCreator from "sagas/administration/common/signaturesSchemes";
import signaturesSchemesTicketSagasCreator from "sagas/administration/common/signaturesSchemesTicket";
import signatureTicketSagasCreator from "sagas/administration/common/signatureTicket";

export default [
    ...detailsSagasCreator(administrationAdvanced, detailsTypes, {
        ...detailsActions,
        ...groupsActions,
        ...channelsActions,
    }),
    ...groupsOfUserSagasCreator(administrationAdvanced, groupsTypes, {
        ...groupsActions,
        onSuccess: (id) => routerActions.push(`/administration/advanced/groupsOfUser/${id}/confirm`),
    }),
    ...channelsSagasCreator(administrationAdvanced, channelsTypes, {
        ...channelsActions,
        onSuccess: (id) => routerActions.push(`/administration/advanced/channels/${id}/confirm`),
    }),

    ...channelsTicketSagasCreator(administrationAdvanced, channelsTicketTypes, {
        ...channelsTicketActions,
        ...channelsActions,
    }),
    ...signatureTicketSagasCreator(administrationAdvanced, signatureTicketTypes, {
        ...signatureTicketActions,
        ...detailsActions,
    }),
    ...groupsOfUserTicketSagasCreator(administrationAdvanced, groupsOfUserTicketTypes, {
        ...groupsOfUserTicketActions,
        ...detailsActions,
    }),
    ...permissionsSagasCreator(administrationSimple, permissionsTypes, {
        ...permissionsActions,
        onSuccess: (id) => routerActions.push(`/administration/simple/permissions/${id}/confirm`),
    }),
    ...signaturesSchemesSagasCreator(administrationAdvanced, signaturesSchemesTypes, {
        ...signaturesSchemesActions,
    }),
    ...signaturesSchemesTicketSagasCreator(administrationAdvanced, signaturesSchemesTicketTypes, {
        ...signaturesSchemesActions,
        ...signaturesSchemesTicketActions,
    }),
    ...groupSagas,
    ...groupFormDataSagas,
];
