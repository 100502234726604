/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import { selectors as transactionsSelectors } from "reducers/transactions";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class EcheqEmisionDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const {
            multipleSignaturesData: { selectedTransactionRows },
            isDesktop,
        } = this.props;
        return selectedTransactionRows.map((object) => {
            const {
                data: { echeqsToEmit, beneficiary, beneficiaryName, motive, echeqNumeration },
            } = object.transaction;
            const numeration = JSON.parse(echeqNumeration);

            return (
                <>
                    <CardContainer className="confirmation__cards-container mt-0">
                        <Card
                            className="confirmation__cards"
                            icon={isDesktop && "images/transfer-PENDING.svg"}
                            iconClass="card__pending-transaction-icon"
                            title={
                                isDesktop ? (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get("table.emitCesion.date")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <FormattedDate
                                                date={echeqsToEmit[0].date}
                                                dateFormat="dd/MM/yyyy"
                                                withSpan
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get("table.echeq.beneficiary.cuit")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>{beneficiary}</span>
                                        </div>
                                    </div>
                                )
                            }
                            content={
                                isDesktop ? (
                                    <div className="d-flex align-items-center">
                                        <div className="confirmation-cards__content pb-1">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.echeq.beneficiary.cuit")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{beneficiary}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.type")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{echeqsToEmit[0].type}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content info-type-b">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.motive")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">{motive}</div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.date")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <FormattedDate
                                                    date={echeqsToEmit[0].date}
                                                    dateFormat="dd/MM/yyyy"
                                                    withSpan
                                                />
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.type")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{echeqsToEmit[0].type}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.motive")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">{motive}</div>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.echeq.beneficiary")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{beneficiaryName}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emisionEcheq.mode")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {numeration.length > 1
                                                        ? i18n.get("table.emisionEcheq.multiple")
                                                        : i18n.get("table.emisionEcheq.individual")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.emitCesion.echeq")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    {numeration.length > 0 ? (
                                                        numeration.map((item) => (
                                                            <span
                                                                className="confirmation-cards__with-ellipsis-item confirmation-cards-separator"
                                                                key={item.id}>
                                                                {item.cheque_numero}
                                                            </span>
                                                        ))
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            bottom={
                                isDesktop && (
                                    <div className="d-flex align-items-center">
                                        <div className="confirmation-cards__bottom confirmation-cards-separator">
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get("table.echeq.beneficiary")}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{beneficiaryName}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__bottom confirmation-cards-separator">
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get("table.emisionEcheq.mode")}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>
                                                    {numeration.length > 1
                                                        ? i18n.get("table.emisionEcheq.multiple")
                                                        : i18n.get("table.emisionEcheq.individual")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__bottom">
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get("table.emitCesion.echeq")}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    {numeration.length > 0 ? (
                                                        numeration.map((item) => (
                                                            <span
                                                                className="confirmation-cards__with-ellipsis-item confirmation-cards-separator"
                                                                key={item.id}>
                                                                {item.cheque_numero}
                                                            </span>
                                                        ))
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            rightContent={
                                <div className="confirmation-cards__right">
                                    <div className="confirmation-cards__right-data">
                                        <FormattedAmount
                                            currency="0"
                                            quantity={echeqsToEmit[0]?.amount * echeqsToEmit.length}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        });
    };

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    transactions: transactionsSelectors.getTransactions(state),
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(EcheqEmisionDataTable);
