import React from "react";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";
import { bool, func, shape } from "prop-types";
import { routerActions, push } from "react-router-redux";
import { connect } from "react-redux";

import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Logo from "pages/login/_components/Logo";

function ProcessDisclaimerClaveToken({ dispatch, isDesktop, isSubmitting, propsGrid }) {
    const onHeaderClose = () => {
        dispatch(routerActions.goBack());
    };

    const handleClick = () => {
        dispatch(push("/"));
    };

    const renderTitleMobile = () => <Logo className="svg-image logo logo-mobile-size" logoColor />;

    return (
        <GridLayoutExternal scopeToShow="recoveryPassword" isDesktop={isDesktop} {...propsGrid}>
            <div className="process-disclaimer">
                <Head
                    onClose={onHeaderClose}
                    title={isDesktop && "recoveryPassword.step2.disclaimer3.header"}
                    headerClassName={!isDesktop ? "login-background-color-white" : "recover-pass-desktop"}
                    hideMobileMenu={!isDesktop && true}
                    centerElement={!isDesktop && renderTitleMobile}
                />

                <MainContainer
                    className={classNames("login__layout main-container", {
                        "container-fluid": isDesktop,
                    })}>
                    {!isDesktop && (
                        <Container className="container--layout flex-grow">
                            <Col className="col col-12 view-title mt-3">
                                <div className="justify-content-center">
                                    <I18n
                                        id="recoveryPassword.step2.disclaimer3.header"
                                        component="h2"
                                        componentProps={{ className: "f-size-5 font-regular" }}
                                    />
                                </div>
                            </Col>
                        </Container>
                    )}
                    <form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                        <Container className="container--layout flex-grow align-items-center">
                            <Col sm={12} md={12} lg={12} className="col col-12 mt-3">
                                <p className="text-lead">
                                    <I18n id="recoveryPassword.step2.disclaimer.message" />
                                </p>
                            </Col>
                        </Container>
                        <Container className="align-items-center container--layout">
                            <Col sm={12} md={12} lg={12} className="col col-12 process-disclaimer-button mt3">
                                <Button
                                    label="global.button.signout"
                                    loading={isSubmitting}
                                    type="submit"
                                    bsStyle="primary"
                                    onClick={handleClick}
                                />
                            </Col>
                        </Container>
                    </form>
                </MainContainer>
            </div>
        </GridLayoutExternal>
    );
}

ProcessDisclaimerClaveToken.propTypes = {
    dispatch: func.isRequired,
    propsGrid: shape({}).isRequired,
    isDesktop: bool,
    isSubmitting: bool,
};

ProcessDisclaimerClaveToken.defaultProps = {
    isDesktop: true,
    isSubmitting: false,
};

export default connect(null)(ProcessDisclaimerClaveToken);
