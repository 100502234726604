import React, { useEffect, useState } from "react";
import { arrayOf, bool, func, node, shape, string } from "prop-types";
import Link from "react-router-dom/Link";
import { Col } from "react-bootstrap";
import { Field } from "formik";

import AmountField from "pages/_components/fields/formik/AmountField";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import Range from "pages/_components/Range";
import TextField from "pages/_components/fields/TextField";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import * as i18nUtils from "util/i18n";

import classNames from "classnames";
import { agendaPagoShape } from "util/servicePayments";
import InfoTag from "pages/_components/InfoTag";

function PaymentItemMobile({
    handleChek,
    setPaymentReference,
    setQuantity,
    payment,
    concept,
    paymentHasExpiration,
    description,
    reference,
    editableAmount,
    FORM_ID,
    currencies,
    tableLabel,
    menu,
    setHasError,
}) {
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        setIsCollapsed(!payment.isChecked);
    }, [payment.isChecked]);

    return (
        <>
            <Col xs={10} className="col col-12 d-flex h-100 pr-1">
                <Checkbox
                    className="mb-0"
                    hideLabel
                    checked={payment.isChecked}
                    name={payment.key}
                    onChange={() => handleChek(payment)}
                    isDisabled={payment.isPending}
                />
                <Link to={payment.link} className="text-decoration-none">
                    <span className="f-size-6 mr-2">{description.toUpperCase()}</span>
                    {payment.isPending ? (
                        <span>
                            <InfoTag
                                type="info"
                                message={i18nUtils.get(`services.payments.detail.status.PENDING`)}
                                tagBackground="#FFE5B6"
                                tagClass="max-width-mincontent text-nowrap"
                                moreStyles={{
                                    color: "black",
                                }}
                            />
                        </span>
                    ) : (
                        <span className="f-size-6 color-primary">{reference.toUpperCase()}</span>
                    )}
                </Link>
            </Col>
            <Col xs={1} className="col col-12 px-0 h-100">
                <Button
                    block={false}
                    image="images/styledmenu-arrow-down.svg"
                    className="btn-multiple-payments-dropdown btn-b-none h-auto mh-auto my-0 py-0"
                    aria-haspopup
                    aria-expanded={!isCollapsed}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    onKeyDown={() => setIsCollapsed(!isCollapsed)}
                    fitWidth
                />
            </Col>
            <Col xs={1} className="col col-12 px-0 h-100">
                {menu}
            </Col>
            {!isCollapsed && (
                <>
                    <Col xs={12} className="payment__input-group col col-12 d-flex h-100">
                        <Heading.DataGroup
                            containerClassName={classNames("justify-content-center w-100 mx-45")}
                            label="services.newPayment.paymentRef.label"
                            labelClassName="f-size-6"
                            data={
                                concept.managesIncomeReference ? (
                                    <Field
                                        idForm={FORM_ID}
                                        formGroupClassName="mb-1 w-100"
                                        component={TextField}
                                        renderAs="input"
                                        hidePlaceholder
                                        name={`${payment.key}_paymentReference`}
                                        type="text"
                                        label="services.newPayment.paymentRef.label"
                                        hideLabel
                                        isDisabled={!payment.isChecked || concept.maxLengthTextReference === 0}
                                        onCustomChange={(value) => setPaymentReference(payment, value)}
                                    />
                                ) : (
                                    <I18n
                                        id="transfer.notAvailable"
                                        componentProps={{ className: "data-label font-light f-size-6 mb-2" }}
                                    />
                                )
                            }
                        />
                    </Col>
                    <Col xs={12} className="payment__amount-rangre-group col col-12 d-flex align-items-center h-100">
                        <div className="w-100">
                            <Heading.DataGroup
                                containerClassName={classNames("mx-45")}
                                label="services.newPayment.amountToPay"
                                labelClassName="f-size-6 mr-2"
                                data={
                                    editableAmount && payment.isChecked ? (
                                        <Field
                                            idForm={FORM_ID}
                                            formGroupClassName="mb-2"
                                            autocomplete="off"
                                            component={AmountField}
                                            data={{
                                                options: currencies,
                                            }}
                                            name={
                                                paymentHasExpiration
                                                    ? `${payment.key}_conceptAmount${concept.debtId}`
                                                    : `${payment.key}_conceptAmount${concept.conceptCode}`
                                            }
                                            label="services.newPayment.amountToPay"
                                            hideLabel
                                            disableSelect
                                            fixedDecimalScale
                                            isDesktop={false}
                                            onCustomOnChange={(quantity) => setQuantity(payment, quantity)}
                                            setHasError={setHasError}
                                        />
                                    ) : (
                                        <span className="d-flex align-items-center">
                                            {concept?.date ? (
                                                <>
                                                    <FormattedAmount
                                                        className="data-amount"
                                                        fontClassName="f-size-4"
                                                        currency={currencies[0].id.toString()}
                                                        quantity={concept?.amount}
                                                    />
                                                    {/* setQuantity(payment, concept.amount) Hacer esto en el Check */}
                                                </>
                                            ) : (
                                                <span className="data-amount px-2">
                                                    <span className="data-amount-currency f-size-4">-</span>
                                                </span>
                                            )}
                                        </span>
                                    )
                                }
                                dataClassName="data-desc"
                            />
                            {concept.managesRange && editableAmount && payment.isChecked && (
                                <Range
                                    currency={currencies[0].id}
                                    min={concept.minAmount}
                                    max={concept.maxAmount}
                                    containerClassName="d-inline-block"
                                />
                            )}
                        </div>
                    </Col>
                    <Col xs={12} className="col col-12 d-flex h-100">
                        <Heading.DataGroup
                            containerClassName={classNames("data-wrapper-flex justify-content-center mb-0 mx-45")}
                            labelClassName="f-size-6 mr-2"
                            label={`${tableLabel}.agenda.expiration.label`}
                            data={
                                concept?.date ? (
                                    <FormattedDate className="f-size-4" date={concept.date} dateFormat="dd/MM/yyyy" />
                                ) : (
                                    <I18n
                                        id="services.newPayment.noExpirationDate"
                                        componentProps={{ className: "f-size-6 mb-2" }}
                                    />
                                )
                            }
                        />
                    </Col>
                </>
            )}
        </>
    );
}

PaymentItemMobile.propTypes = {
    handleChek: func.isRequired,
    setPaymentReference: func.isRequired,
    setQuantity: func.isRequired,
    payment: agendaPagoShape.isRequired,
    concept: shape({}).isRequired,
    paymentHasExpiration: bool.isRequired,
    description: string.isRequired,
    reference: string.isRequired,
    editableAmount: bool.isRequired,
    FORM_ID: string.isRequired,
    currencies: arrayOf(shape({})).isRequired,
    tableLabel: string.isRequired,
    menu: node.isRequired,
    setHasError: func.isRequired,
};

export default PaymentItemMobile;
