// eslint-disable-next-line no-unused-vars
import { call, put, takeLatest } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import * as settings from "middleware/settings";
import { types as fingerprintTypes, actions as fingerprintActions } from "reducers/fingerprint";

import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";

import * as deviceUtils from "util/device";
import * as fingerprintUtils from "util/fingerprint";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import * as secureStorageUtils from "util/secureStorage";
import { actions as loginActions } from "reducers/login";

import { store } from "../store";

const sagas = [
    takeLatest(fingerprintTypes.FINGERPRINT_AVAILABILITY, isAvailable),
    takeLatest(fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE, fingerprintConfigurationPre),
    takeLatest(fingerprintTypes.FINGERPRINT_VERIFICATION_REQUEST, verify),
    takeLatest(fingerprintTypes.FINGERPRINT_DELETE_DEVICE, deleteDevice),
    takeLatest(fingerprintTypes.ENROLL_FINGERPRINT_ON_DEVICE, enrollFingerprintOnDevice),
    takeLatest(fingerprintTypes.CLEAN_UP, handleFingerprintCleanUp),
    takeLatest(fingerprintTypes.FINGERPRINT_GET_INITIAL_DATA, setInitalFingerprintConfigData),
];

export default sagas;

function* isAvailable() {
    if (window.cordova) {
        try {
            const availability = yield call(fingerprintUtils.isAvailable);

            yield put({ type: fingerprintTypes.FINGERPRINT_AVAILABILITY_SUCCESS, availability });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log("ERROR!!! - ", error);
        }
    }
}

function* fingerprintConfigurationPre({ device }) {
    const response = yield call(settings.listFingerprintConfiguredUserDevices, device);
    const { documentNumber, documentType, fullName, firstName } = response.data.data;

    if (response && response.status === 200) {
        yield put({
            type: fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE_SUCCESS,
            isDeviceWithFingerprint: response.data.data.isThisDeviceWithFingerPrint,
            fingerprintConfiguredUserDevices: response.data.data.devices,
            // eslint-disable-next-line no-underscore-dangle
            exchangeToken: response.data.data._exchangeToken,
            document: documentNumber,
            documentType,
            userFullName: fullName,
            userFirstName: firstName,
        });
    }
}

function* setInitalFingerprintConfigData() {
    try {
        const documentNumber = yield call(secureStorageUtils.get, "documentNumber");
        const documentType = yield call(secureStorageUtils.get, "documentType");
        const userFullName = yield call(secureStorageUtils.get, "userFullName");
        const userFirstName = yield call(secureStorageUtils.get, "userFirstName");
        const showLogin = yield call(secureStorageUtils.get, "showLogin");

        if (
            documentNumber !== undefined &&
            documentType !== undefined &&
            userFullName !== undefined &&
            userFirstName !== undefined
        ) {
            yield put(
                loginActions.setRememberedUser({
                    document: documentNumber,
                    documentType,
                    userFullName,
                    userFirstName,
                }),
            );
        }
        if (showLogin === "true" && documentNumber !== undefined && userFirstName !== undefined) {
            yield put(
                loginActions.setBiometricLoginShow({
                    showLogin: true,
                    document: documentNumber,
                    userFirstName,
                }),
            );
        }
    } catch (error) {
        /* do something with the error */
    }
}

function* verify() {
    try {
        let result = yield call(fingerprintUtils.verifyAio, sessionSelectors.getAccessToken(store.getState()));
        const idDevice = deviceUtils.getDeviceId();
        const responseDevices = yield call(settings.listFingerprintConfiguredUserDevices, idDevice);
        const { username, documentNumber, documentType, fullName, firstName } = responseDevices.data.data;

        if (result && result.withFingerprint) {
            const response = yield call(
                settings.saveFingerprint,
                deviceUtils.getDeviceId(),
                deviceUtils.getDeviceModel(),
            );

            if (response && response.status === 200) {
                result = yield call(
                    secureStorageUtils.set,
                    "fingerprintAuthToken",
                    sessionSelectors.getAccessToken(store.getState()),
                );

                result = yield call(secureStorageUtils.set, "fingerprintUsername", username);
                result = yield call(secureStorageUtils.set, "fingerprintIdDevice", idDevice);
                result = yield call(secureStorageUtils.set, "documentNumber", documentNumber);
                result = yield call(secureStorageUtils.set, "documentType", documentType);
                result = yield call(secureStorageUtils.set, "userFullName", fullName);
                result = yield call(secureStorageUtils.set, "userFirstName", firstName);
                result = yield call(secureStorageUtils.set, "showLogin", "true");

                if (result) {
                    const device = idDevice;
                    yield put(
                        loginActions.setRememberedUser({
                            document: documentNumber,
                            documentType,
                            userFullName: fullName,
                            userFirstName: firstName,
                        }),
                    );

                    yield put(
                        loginActions.setBiometricLoginShow({
                            showLogin: true,
                            document: documentNumber,
                            userFirstName: firstName,
                        }),
                    );
                    yield put(fingerprintActions.fingerprintConfigurationPre(device));
                    yield put(
                        notificationActions.showNotification(
                            i18nUtils.get("settings.fingerprintConfiguration.register.success"),
                            "success",
                            ["fingerprintConfiguration"],
                        ),
                    );
                    yield put(fingerprintActions.fingerprintResetAcceptTermsConditions());
                }
            } else {
                const errorMessage = i18nUtils.get("settings.fingerprintConfiguration.register.error");
                yield put(notificationActions.showNotification(errorMessage, "error", ["settings"]));
            }
        } else {
            const errorMessage = i18nUtils.get("settings.fingerprintConfiguration.register.error");
            yield put(notificationActions.showNotification(errorMessage, "error", ["settings", "login"]));
        }
    } catch (error) {
        const errorCode = error.code;
        // eslint-disable-next-line no-empty
        if (errorCode === fingerprintUtils.fingerprintErrors.BIOMETRIC_DISMISSED) {
        } else if (errorCode === fingerprintUtils.fingerprintErrors.BIOMETRIC_LOCKED_OUT) {
            const message = configUtils.get("biometric.NumberAttemptsPassed.Snackbar");
            yield put(notificationActions.showNotification(message, "error", ["fingerprintConfiguration"]));
        } else if (errorCode === fingerprintUtils.fingerprintErrors.BIOMETRIC_NOT_ENROLLED) {
            const message = configUtils.get("biometric.NumberAttemptsPassed.Snackbar");
            yield put(notificationActions.showNotification(message, "error", ["fingerprintConfiguration"]));
        } else {
            yield put(notificationActions.showNotification(error.message, "error", ["fingerprintConfiguration"]));
        }
    }
}

function* deleteDevice({ idSession }) {
    const response = yield call(settings.deleteFingerprint, idSession);

    if (response && response.status === 200) {
        const confirmationMessage = i18nUtils.get("settings.fingerprintConfiguration.device.remove.success");
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["fingerprintConfiguration"]));
    } else {
        const errorMessage = i18nUtils.get("settings.fingerprintConfiguration.device.remove.error");
        yield put(notificationActions.showNotification(errorMessage, "error", ["fingerprintConfiguration"]));
    }

    yield put(push("/settings/fingerprintConfiguration"));
}

function* enrollFingerprintOnDevice() {
    const response = yield call(fingerprintUtils.enrollOnDevice);

    if (!response) {
        const errorMessage = i18nUtils.get(
            "settings.fingerprintConfiguration.device.enrolledFingerprints.enroll.error",
        );
        yield put(notificationActions.showNotification(errorMessage, "error", ["fingerprintConfiguration"]));
    } else {
        yield put(replace("/settings"));
        yield put(push("/settings/fingerprintConfiguration"));
    }
}

function* handleFingerprintCleanUp() {
    if (window.cordova) {
        try {
            yield call(secureStorageUtils.remove, "fingerprintAuthToken");
            yield call(secureStorageUtils.remove, "fingerprintUsername");
        } catch (error) {
            /* do something with the error */
        }
    }
}
