/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";
import { selectors } from "reducers/checks";
import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import EcheqActionTransactionData from "pages/checks/Echeqs/EcheqTransactions/EcheqActionTransactionData";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";
import ContextMenu from "pages/_components/ContextMenu";
import TransactionTicket from "pages/_components/TransactionTicket";
import { echeqConceptLabel } from "util/checks";

const FORM_ID = "echeq.transaction";

class EcheqTransaction extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
    };

    state = {
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { dispatch, transactionData } = this.props;
        if (!transactionData.idTransaction) {
            dispatch(push("/transactions/list"));
        }
    }

    rightContentTransactionScheme = () => {
        const { transactionData, isDesktop } = this.props;

        if (transactionData?.idTransactionStatus === "FINISHED") {
            return (
                <ContextMenu
                    isDesktop={isDesktop}
                    buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                    items={[
                        {
                            label: "global.download",
                            onClick: this.exportPdf,
                        },
                    ]}
                />
            );
        }

        return (
            <>
                {transactionData?.idTransactionStatus !== "CANCELLED" && (
                    <ContextMenu
                        isDesktop={isDesktop}
                        buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                        items={[
                            {
                                label: "pendingTransaction.info.scheme",
                                onClick: () => this.setState({ showDrawerMobile: true }),
                            },
                        ]}
                    />
                )}
            </>
        );
    };

    centerContentMobile = () => {
        const { transactionData } = this.props;

        return (
            <div className="title-account-header-multiline-background-blue">
                <h1 style={{ width: "100%", marginTop: "2px" }}>
                    {i18n.get(`activities.${transactionData.idActivity}`)}
                </h1>
            </div>
        );
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { transactionData } = this.props;

        const concept = `${transactionData.cheque_concepto} ${echeqConceptLabel(transactionData.cheque_concepto, "-")}`;

        const isAdmittedCesion = ["echeq.admit-cesion.action"].includes(transactionData?.idActivity);

        const cuit = `CUIT ${transactionData?.emisor_cuit}`;
        const sender = transactionData?.emisor_razon_social;

        const bodyData = [
            getGeneralTicketItem(`forms.transaction.ticket.date`, transactionData.submitDateTime, "datefulltime"),
            getGeneralTicketItem(
                `pay.paymentAFIP.idTransaction`,
                transactionData?.idTransaction.substring(0, 8).toUpperCase(),
                "string",
            ),
            getGeneralTicketItem(`${FORM_ID}.echeq.number`, transactionData?.cheque_numero, "string"),
            getGeneralTicketItem(`${FORM_ID}.echeq.id`, transactionData?.cheque_id, "string"),
            getGeneralTicketItem(`${FORM_ID}.amount`, transactionData?.monto, "amountARS"),
            getGeneralTicketItem(`${FORM_ID}.emision`, transactionData?.fecha_emision, "date"),
            getGeneralTicketItem(`${FORM_ID}.payed`, transactionData?.fecha_pago, "date"),
            getGeneralTicketItem(
                `${FORM_ID}.echeq.type`,
                i18n.get(`echeqs.emit.${transactionData?.cheque_tipo.toLowerCase()}.label`),
                "string",
            ),
            getGeneralTicketItem(`${FORM_ID}.echeq.character`, transactionData?.cheque_caracter, "string"),
            getGeneralTicketItem(`${FORM_ID}.mode`, i18n.get(`echeqs.${transactionData.cheque_modo}.modo`), "string"),
        ];

        if (isAdmittedCesion) {
            bodyData.push(
                getGeneralTicketItem(`echeqs.document.label`, cuit, "string"),
                getGeneralTicketItem(`echeqs.sender.label`, sender, "string"),
            );
        }

        bodyData.push(
            getGeneralTicketItem(`${FORM_ID}.concept`, concept, "string"),
            getGeneralTicketItem(`${FORM_ID}.motive`, transactionData?.cheque_motivo_pago, "string"),
        );

        if (transactionData?.idActivity === "echeq.repudiate.action") {
            bodyData.push(getGeneralTicketItem(`echeqs.repudiate_motive`, transactionData?.specialAttribute, "string"));
        }

        bodyData.push(
            getGeneralTicketItem(`${FORM_ID}.coelsa.response`, transactionData?.coelsaResponse, "string"),
            getGeneralTicketItem(`${FORM_ID}.coelsa.code`, transactionData?.coelsaCode, "string"),
        );

        if (
            transactionData?.idActivity === "echeq.deposit.action" ||
            transactionData?.idActivity === "echeq.custody.action"
        ) {
            bodyData.push(
                getGeneralTicketItem(
                    `echeqs.${transactionData?.action?.toLowerCase()}.account`,
                    transactionData?.specialAttribute,
                    "string",
                ),
            );
        }

        const ticketData = {
            ticketName: i18n.get(`activities.${transactionData.idActivity}`),
            ticketBody: bodyData,
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    buttonsDetail = () => {
        const { dispatch } = this.props;

        return [
            {
                label: "global.goToHome",
                bsStyle: "primary",
                onClick: () => dispatch(push("/desktop")),
            },
        ];
    };

    render() {
        const { transactionData, isDesktop, isSmallDesktop, fetching } = this.props;
        const { showDrawerMobile, fetchingDownload } = this.state;

        return (
            <>
                {transactionData?.idTransaction && (
                    <TransactionTicket
                        notificationScope="echeqs"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        uniqueDownload={transactionData?.idTransactionStatus === "FINISHED"}
                        rightContent={
                            transactionData?.idTransactionStatus === "PENDING" && this.rightContentTransactionScheme
                        }
                        emptyRightOption={
                            (isDesktop && transactionData?.idTransactionStatus !== "FINISHED") ||
                            (!isDesktop &&
                                transactionData?.idTransactionStatus !== "PENDING" &&
                                transactionData?.idTransactionStatus !== "FINISHED")
                        }
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={transactionData}
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        showDrawerMobile={showDrawerMobile}
                        fetching={fetching}
                        generateSignFunction
                        ignoreHomeButton
                        closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                        summaryTitle={`activities.${transactionData.idActivity}`}
                        isFetchingExport={fetchingDownload}>
                        <EcheqActionTransactionData />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionEcheqData(state),
    fetching: selectors.getFetching(state),
});

export default connect(mapStateToProps)(EcheqTransaction);
