import * as b64toBlob from "b64-to-blob";
import moment from "moment";
import { getFolderPath, isMobileNative, getMIMEtype, getDisplay } from "./device";

const fakeClick = (element) => {
    const event = document.createEvent("MouseEvents");

    event.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    element.dispatchEvent(event);
};

const getFileExtention = (fileName) => fileName.split(".").pop();

const readFile = (fileEntry, url, fileName) => {
    const onErrorReadFile = () => {
        throw new Error("Error on ReadFile");
    };
    const fileExtention = getFileExtention(fileName);
    fileEntry.file((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            window.cordova.plugins.fileOpener2.open(url, getMIMEtype(fileExtention), {
                error: (e) => {
                    throw new Error(`Error open file:  ${e}`);
                },
                success: () => {},
            });
        };

        reader.readAsText(file);
    }, onErrorReadFile);
};

const writeFile = (fileEntry, dataObj, url, fileName) => {
    // Create a FileWriter object for our FileEntry
    fileEntry.createWriter((fileWriter) => {
        const file = fileWriter;
        file.onwriteend = () => {
            readFile(fileEntry, url, fileName);
        };
        file.onerror = (e) => {
            throw new Error(`Error on write file:  ${e}`);
        };

        file.write(dataObj);
    });
};

export const downloadMobile = (name, exportBlob) => {
    const folderPath = getFolderPath();

    const extension = name?.split(".")?.pop();
    const DATE_FORMAT = "YYYYMMDD_HHmmss";
    const filename = `${name?.slice(0, -extension?.length - 1)}-${moment().format(DATE_FORMAT)}.${extension}`;
    const url = folderPath + filename;

    window.resolveLocalFileSystemURL(folderPath, (dir) => {
        dir.getFile(
            filename,
            { create: true, exclusive: false },
            (fileEntry) => {
                writeFile(fileEntry, exportBlob, url, filename);
            },
            () => {},
        );
    });
};

export const download = (name, data) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    const mediaType = "application/octet-stream";
    saveLink.href = `data:${mediaType};base64,${data}`;
    saveLink.download = name;
    const exportBlob = b64toBlob(data, getMIMEtype(getFileExtention(name)));

    if (isMobileNative) {
        return downloadMobile(name, exportBlob);
    }

    return fakeClick(saveLink);
};

export const downloadLink = (name, path) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
    saveLink.href = path;
    saveLink.target = "_blank";
    saveLink.download = name;
    fakeClick(saveLink);
};

const downloadPdfWeb = (name, exportBlob) => {
    const urlObject = window.URL || window.webkitURL || window;

    if ("msSaveBlob" in navigator) {
        /**
         * Prefer msSaveBlob if available - Edge supports a[download] but ignores the filename provided,
         * using the blob UUID instead
         * msSaveBlob will respect the provided filename
         */
        navigator.msSaveBlob(exportBlob, name);
    } else if ("download" in HTMLAnchorElement.prototype) {
        const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");

        saveLink.href = urlObject.createObjectURL(exportBlob);
        saveLink.download = name;
        fakeClick(saveLink);
    } else {
        throw new Error("Neither a[download] nor msSaveBlob is available");
    }
};

export function getBlobContent(blob) {
    const url = URL.createObjectURL(blob);
    const request = new XMLHttpRequest();
    request.open("GET", url, false);
    request.send();
    URL.revokeObjectURL(url);
    return request.responseText;
}

export const downloadBlobAsTxt = (name, data) => {
    const blob = data && getBlobContent(data);

    const exportBlob = new Blob([blob], {
        type: "text/plain",
    });

    return downloadMobile(name, exportBlob);
};

export const downloadTxt = (name, data) => {
    const exportBlob = b64toBlob(data, "text/plain;charset=utf-8");

    if (isMobileNative) {
        return downloadMobile(name, exportBlob);
    }

    return downloadPdfWeb(name, exportBlob);
};

export const downloadPdf = (name, data) => {
    const exportBlob = b64toBlob(data, "application/pdf");

    if (isMobileNative) {
        return downloadMobile(name, exportBlob);
    }

    return downloadPdfWeb(name, exportBlob);
};

export const downloadXls = (name, data) => {
    const saveLink = document.createElementNS("http://www.w3.org/1999/xhtml", "a");

    saveLink.href = `data:application/vnd.ms-excel;base64,${window.atob(data)}`;
    saveLink.download = name;
    // it's necesary to decode the data one more time for xls files
    const decodedData = window.atob(data);
    const exportBlob = b64toBlob(decodedData, getMIMEtype(getFileExtention(name)));

    if (isMobileNative) {
        return downloadMobile(name, exportBlob);
    }

    return fakeClick(saveLink);
};

export const downloadUrl = (fileURL, fileName = null) => {
    if (("download" in HTMLAnchorElement.prototype || getDisplay() === "mobile") && !isMobileNative) {
        const name = fileName === null ? fileURL.substring(fileURL.lastIndexOf("/") + 1) : fileName;
        downloadLink(name, fileURL);
    } else if (isMobileNative) {
        window.cordova.InAppBrowser.open(fileURL, "_system");
    }
};
