import React from "react";
import { number, func, bool, arrayOf, shape, string } from "prop-types";
import { Field } from "formik";

import Pagination from "pages/_components/pagination/Pagination";
import Selector from "pages/_components/fields/formik/Selector";

export default function PaginationWithPageSelector({
    totalPages,
    pageNumber,
    action,
    showFirstAndLastButton,
    numberPerPageOptionsToShow,
    changeNumberPerPage,
    className,
    selectedValue,
}) {
    Pagination.propTypes = {
        totalPages: number.isRequired,
        pageNumber: number.isRequired,
        action: func.isRequired,
        showFirstAndLastButton: bool.isRequired,
        numberPerPageOptionsToShow: arrayOf(shape({})).isRequired,
        className: string.isRequired,
    };

    const createPagination = () => (
        <div className={className} style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
            <Field
                component={Selector}
                options={numberPerPageOptionsToShow}
                hideLabel
                hidePlaceholder
                customOnChange
                onCustomChange={changeNumberPerPage}
                value={selectedValue}
            />

            <div
                style={{
                    display: "flex",
                    marginTop: "0.5rem",
                }}>
                <Pagination
                    totalPages={totalPages}
                    pageNumber={pageNumber}
                    action={action}
                    showFirstAndLastButton={showFirstAndLastButton}
                    showText
                />
            </div>
        </div>
    );

    return createPagination();
}
