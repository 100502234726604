const buildActions = ({ type, token, isPending }, activeEnvironment) => ({
    isDeleteAllowed: type === "PE" && activeEnvironment?.permissions?.afipVepDeleteManage === true && token !== null,
    isPaymentAllowed:
        type === "PE" &&
        activeEnvironment?.permissions?.accounts === true &&
        activeEnvironment?.permissions?.afipVepPaymentsManage === true &&
        token !== null &&
        isPending === false,
});

export const setActions = (paymentsAFIP, activeEnvironment) =>
    paymentsAFIP.map((paymentAFIP) => ({
        actions: buildActions(paymentAFIP, activeEnvironment),
        ...paymentAFIP,
    }));

export const buildMultiplePaymentsAFIP = (paymentsAFIP) =>
    paymentsAFIP.map(({ numberVEP, ...paymentAFIP }) => ({
        isChecked: false,
        key: numberVEP,
        numberVEP,
        ...paymentAFIP,
    }));

export const buildPayMultipleAFIPRequest = ({ selectedPaymentsAFIP, debitAccountMultipleAFIP, otp, userId }) => ({
    payments: selectedPaymentsAFIP.map(({ ...payment }) => ({
        debitAccount: debitAccountMultipleAFIP,
        idAccount: debitAccountMultipleAFIP.idProduct,
        paymentAFIP: payment,
        amount: {
            currency: payment?.amount.currency,
            quantity: payment?.amount.quantity,
        },
        _otp: otp,
    })),
    _otp: otp,
    userId,
});
