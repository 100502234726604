import React, { Component } from "react";
import classNames from "classnames";
import { bool, func, shape, string } from "prop-types";

import FieldLabel from "pages/_components/fields/FieldLabel";

class StepperField extends Component {
    static propTypes = {
        field: shape({
            name: string,
        }).isRequired,
        handleChange: func,
        hideLabel: bool,
        idForm: string.isRequired,
        max: string.isRequired,
        min: string.isRequired,
        className: string,
    };

    static defaultProps = {
        handleChange: null,
        hideLabel: false,
        className: "",
    };

    state = {
        usedControl: false,
    };

    handleChange = (event) => {
        const { field, handleChange, max, min } = this.props;
        const { usedControl } = this.state;
        const { value } = event.target;
        if (value > 0 && !usedControl) {
            this.setState({ usedControl: true });
        }
        if (value >= min && value <= max && value.length <= max.length) {
            field.onChange(event);

            if (handleChange) {
                handleChange(event);
            }
        }
    };

    render() {
        const { className, field, hideLabel, idForm, max, min } = this.props;
        const { usedControl } = this.state;

        return (
            <div className={classNames("form-group", className)}>
                {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                <div className="input-group">
                    <input
                        {...field}
                        className={`form-control form-control-stepper ${usedControl ? "form-control-used" : ""}`}
                        name={field.name}
                        max={max}
                        min={min}
                        onChange={this.handleChange}
                        type="number"
                        pattern="\d*"
                    />
                </div>
            </div>
        );
    }
}

export default StepperField;
