import React, { Component, Children } from "react";
import { Route, Switch } from "react-router-dom";
import { node } from "prop-types";

class WizardSteps extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <Route>
                {({ match }) => (
                    <Route path={`${match.path}/:step`}>
                        <Switch>
                            {Children.map(children, (child, index) => (
                                <Route path={`${match.path}/step${index + 1}`}>{child}</Route>
                            ))}
                        </Switch>
                    </Route>
                )}
            </Route>
        );
    }
}

export default WizardSteps;
