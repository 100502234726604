import React from "react";
import { node } from "prop-types";

import Image from "pages/_components/Image";

function NoDataMessage({ children }) {
    return (
        <div className="text-center no-more-data">
            <div className="illustration-wrapper">
                <Image src="images/loupeInFile.svg" className="svg-big-icon" />
            </div>
            <p className="text-lead">{children}</p>
        </div>
    );
}

NoDataMessage.propTypes = {
    children: node.isRequired,
};

export default NoDataMessage;
