import React, { Component, Fragment } from "react";
import { Field, withFormik } from "formik";
import { compose } from "redux";
import { bool, string, func, oneOf, shape, arrayOf } from "prop-types";
import { replace, goBack } from "react-router-redux";
import cloneDeep from "lodash/cloneDeep";
import queryString from "query-string";
import moment from "moment";
import { routerActions } from "react-router-redux/actions";

import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { actions as formActions } from "reducers/form";
import { actions as templateActions } from "reducers/template";
import { isVisible, isRequired } from "util/form";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as stringUtils from "util/string";
import { Mixpanel } from "util/clickstreaming";

import FormActions from "pages/forms/_components/FormActions";
import Scheduler from "pages/forms/_components/_fields/Scheduler";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import mapProps from "pages/_components/mapProps";
import CreateTemplateModal from "pages/forms/_components/CreateTemplateModal";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import TransactionTicket from "pages/forms/_components/TransactionTicket";
import Container from "pages/_components/Container";
import TemplateList from "pages/forms/_components/TemplateList";
// import TicketHead from "pages/_components/TicketHead";
import FormConfirmation from "./FormConfirmation";
import TransactionTicketBackoffice from "./TransactionTicketBackoffice";

class FormRender extends Component {
    static propTypes = {
        values: shape({}).isRequired,
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        currentLang: string.isRequired,
        validations: shape({}).isRequired,
        metadata: shape({}).isRequired,
        errors: shape({}).isRequired,
        handleSubmit: func.isRequired,
        mode: oneOf(["view", "edit", "preview"]),
        // tells if the form is being rendered from Backoffice
        fromBackoffice: bool,
        // tells if the form is being rendered to confirm a recently made transaction
        ticketConfirmation: bool,
        templates: arrayOf(shape()),
        history: shape({ location: shape({ pathname: string }) }).isRequired,
        match: shape({ path: string, url: string, isExact: bool }).isRequired,
        setValues: func.isRequired,
        setErrors: func.isRequired,
        isMobile: bool,
        isDesktop: bool.isRequired,
        isCancellingTransaction: bool,
        fetching: bool,
        location: shape({ pathname: string }).isRequired,
        id: string,
        childrenTransactions: shape({}),
        parentTransaction: shape({}),
        transaction: shape({
            idTransaction: string.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        mode: "edit",
        fromBackoffice: false,
        ticketConfirmation: false,
        templates: [],
        isMobile: false,
        isCancellingTransaction: false,
        fetching: false,
        id: null,
        childrenTransactions: null,
        parentTransaction: null,
    };

    componentWillUnmount() {
        const { dispatch, history, match } = this.props;
        const { pathname } = history.location;
        const { url } = match.url;

        if (!pathname.includes(url)) {
            dispatch(formActions.formClosed());
        }
    }

    handleClick = (action) => {
        const {
            dispatch,
            metadata: { idForm, idActivity },
            transaction,
            values,
        } = this.props;

        const { idTransaction } = transaction;

        Mixpanel.track_form(idForm, {
            action,
            idActivity,
            ...values,
        });
        switch (action) {
            case "createTemplate":
                dispatch(templateActions.createTemplate());
                break;
            case "saveDraft":
                dispatch(
                    formActions.saveDraft({
                        idForm,
                        idActivityDraft: idActivity,
                        data: values,
                        idTransaction: transaction ? idTransaction : null,
                        scheduler: values?.scheduler,
                    }),
                );
                break;
            case "cancelTransaction":
                dispatch(formActions.cancelTransactionPre({ idActivity, idForm }));
                break;
            case "modifyTransaction":
                dispatch(formActions.modifyTransaction(idTransaction));
                break;
            case "signTransaction":
                dispatch(
                    formActions.signTransactionPreview({
                        idForm,
                        idActivity,
                        idTransaction: transaction.idTransaction,
                    }),
                );
                break;
            case "downloadTicketPdf":
                dispatch(formActions.downloadTicket(idTransaction, "pdf", idForm));
                break;
            case "shareTicket":
                dispatch(formActions.shareTicket(idTransaction, "pdf", idForm));
                break;
            default:
                break;
        }
    };

    renderFields = () => {
        const {
            currentLang,
            metadata,
            validations,
            mode,
            fromBackoffice,
            transaction: { idTransaction },
            values,
        } = this.props;
        return metadata?.fieldList?.reduce((fields, field) => {
            if (validations[field.idField].isVisible && (mode === "view" || !field.ticketOnly)) {
                const FormField = FormFieldsComponents[field.type.charAt(0).toUpperCase() + field.type.substr(1)];
                const required = validations[field.idField].isRequired;
                const value = values[field.idField];

                if (!required && (mode === "preview" || mode === "view")) {
                    if (
                        stringUtils.isEmpty(value) ||
                        (typeof value === "object" &&
                            Object.values(value).some((element) => stringUtils.isEmpty(element)))
                    ) {
                        return fields;
                    }
                }
                return [
                    ...fields,
                    <Field
                        {...field}
                        fieldList={metadata?.fieldList}
                        dateFormat={i18n.get("datepicker.format", "DD/MM/YYYY")}
                        isRequired={required}
                        key={field.idField}
                        name={field.idField}
                        component={FormField}
                        lang={currentLang}
                        mode={mode}
                        formTitle={metadata?.formNameMap[currentLang]}
                        fromBackoffice={fromBackoffice}
                        idTransactionTicket={idTransaction}
                    />,
                ];
            }

            return fields;
        }, []);
    };

    renderScheduler = () => {
        const {
            currentLang,
            metadata: {
                enabledWeekDays,
                firstWorkingDate,
                maxDaysToSchedule,
                nonWorkingDays,
                programable,
                schedulable,
            },
            mode,
            location,
        } = this.props;

        const data = {
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            nonWorkingDays,
            lang: currentLang,
            mode,
            programable,
            schedulable,
        };

        return <Field component={Scheduler} data={data} name="scheduler" location={location} />;
    };

    openTemplateScreen = () => {
        const { dispatch, match, values } = this.props;

        dispatch(routerActions.push(`${match.url}/templates`, { shouldLoadForm: true }));
        dispatch(formActions.setData(values));
    };

    handleClose = () => {
        const { dispatch, location } = this.props;
        const pathname = location.pathname.match("/transaction/") ? "/transactions/list" : "/desktop";

        dispatch(replace({ pathname, state: { transition: "transition-flow-close" } }));
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(formActions.closeConfirmation());
    };

    handleTemplateSelect = (template) => {
        const { setValues, setErrors, dispatch } = this.props;
        setValues(template);
        setErrors({});
        dispatch(transactionLinesActions.loadTransactionLinesTemplateData(template));
    };

    renderFieldsSection = () => {
        const { metadata, mode, isDesktop, templates } = this.props;
        const isTemplatesVisibile = mode === "edit" && metadata?.templatesEnabled && templates.length > 0;
        return (
            <Container className="align-items-center flex-grow" gridClassName="form-content">
                <Container.Column
                    sm={12}
                    md={isTemplatesVisibile ? 6 : 12}
                    lg={isTemplatesVisibile ? 6 : 12}
                    xl={isTemplatesVisibile ? 6 : 12}>
                    <Container.ColumnBody>
                        {!isDesktop && isTemplatesVisibile && (
                            <Button
                                className="btn-ico"
                                onClick={this.openTemplateScreen}
                                label="forms.templates.load"
                                image="images/draft.svg"
                            />
                        )}
                        {this.renderFields()}
                        {(metadata?.programable || metadata?.schedulable) && this.renderScheduler()}
                    </Container.ColumnBody>
                </Container.Column>
                {isDesktop && isTemplatesVisibile && (
                    <Container.Column sm={6} md={6} lg={6} xl={6}>
                        <Container.ColumnHeader title="forms.templates" />
                        <Container.ColumnBody>
                            <TemplateList onSelect={this.handleTemplateSelect} className="navigational-list" />
                        </Container.ColumnBody>
                    </Container.Column>
                )}
            </Container>
        );
    };

    renderFormContent = () => {
        const {
            mode,
            isDesktop,
            transaction,
            childrenTransactions,
            parentTransaction,
            fromBackoffice,
            ticketConfirmation,
            isSubmitting,
            metadata,
        } = this.props;

        const isEditable =
            typeof childrenTransactions === "undefined" ||
            childrenTransactions === null ||
            childrenTransactions.length === 0;

        const actionButtons = !fromBackoffice && (
            <FormActions
                onClick={this.handleClick}
                fetching={isSubmitting}
                mode={mode}
                draftsEnabled={metadata?.draftsEnabled}
                templatesEnabled={metadata?.templatesEnabled}
                transaction={transaction}
                isEditable={isEditable}
                idForm={metadata?.idForm}
            />
        );

        if (mode === "edit") {
            return (
                <Fragment>
                    {this.renderFieldsSection()}
                    {actionButtons}
                </Fragment>
            );
        }

        if (fromBackoffice) {
            return (
                <TransactionTicketBackoffice
                    childrenTransactions={childrenTransactions}
                    formActions={actionButtons}
                    fromBackoffice={fromBackoffice}
                    parentTransaction={parentTransaction}
                    ticketConfirmation={ticketConfirmation}
                    transaction={transaction}
                    isDesktop={isDesktop}
                />
            );
        }
        return (
            <TransactionTicket
                childrenTransactions={childrenTransactions}
                formActions={actionButtons}
                fromBackoffice={fromBackoffice}
                parentTransaction={parentTransaction}
                ticketConfirmation={ticketConfirmation}
                transaction={transaction}>
                {this.renderFieldsSection()}
            </TransactionTicket>
        );
    };

    renderContent = () => {
        const { mode, values, id, metadata, transaction, currentLang, handleSubmit } = this.props;
        const { idActivity } = metadata || transaction;

        if (mode === "edit" || mode === "view") {
            return (
                <Fragment>
                    {(mode === "edit" && (
                        <Fragment>
                            <form className="above-the-fold" onSubmit={handleSubmit}>
                                {this.renderFormContent()}
                            </form>
                            <CreateTemplateModal values={values} idForm={id} idActivityTemplate={idActivity} />
                        </Fragment>
                    )) ||
                        (mode === "view" && <div className="above-the-fold">{this.renderFormContent()}</div>)}
                </Fragment>
            );
        }
        return (
            <FormConfirmation
                idForm={id}
                metadata={metadata}
                currentLang={currentLang}
                renderFields={this.renderFields}
            />
        );
    };

    goToBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    renderHeader = () => {
        const { mode, metadata, currentLang, fromBackoffice, location, isMobile, isDesktop, transaction } = this.props;

        const isTicket = location.pathname.match("/transaction/") != null;

        if (!fromBackoffice) {
            if (mode !== "view" && mode !== "edit") {
                return (
                    <Head
                        uniqueDownload={transaction?.idTransactionStatus === "FINISHED"}
                        accessibilityTextId={i18n.get("administration.forms.confirm.credentials")}
                        onClose={this.handleClose}
                        titleText={metadata?.formNameMap[currentLang]}
                        onBack={this.handleBack}
                        hideMenu
                        headerClassName={isMobile ? "blue-main-header-mobile" : ""}
                        centerElement={!isMobile ? undefined : this.centerContentMobile}
                    />
                );
            }

            if (isTicket || (isMobile && mode === "view")) {
                return (
                    <div className="admin-detail-head px-0">
                        {isDesktop && (
                            <Head
                                uniqueDownload={transaction?.idTransactionStatus === "FINISHED"}
                                accessibilityTextId="administration.forms.transaction.details"
                                onBack={this.goToBack}
                                exportList={false}
                            />
                        )}
                        <Head
                            uniqueDownload={transaction?.idTransactionStatus === "FINISHED"}
                            exportList={false}
                            headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                            centerElement={isDesktop ? undefined : this.centerContentMobile}
                            onBack={!isDesktop && this.goToBack}
                            downloadImageWhite={!isDesktop}
                        />
                    </div>
                );
            }

            return (
                <div className="admin-detail-head px-0">
                    <Head
                        onClose={this.handleClose}
                        titleText={metadata?.formNameMap[currentLang]}
                        hideMenu
                        accessibilityTextId={
                            isTicket || (mode === "view" && "administration.forms.transaction.details")
                        }
                    />
                </div>
            );
        }
        return null;
    };

    render() {
        const { fetching, isCancellingTransaction, metadata, currentLang, errors } = this.props;
        const hasMetadata = metadata && Object.keys(metadata).length > 2;

        return (
            <Fragment>
                <Notification scopeToShow="form" errors={errors} metadata={metadata} currentLang={currentLang} />
                {this.renderHeader()}
                <MainContainer showLoader={fetching && !isCancellingTransaction && !hasMetadata} shouldHideOnLoad>
                    {this.renderContent()}
                </MainContainer>
            </Fragment>
        );
    }
}

export default compose(
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ metadata, data, location }) => {
            const { query } = queryString.parseUrl(location.search);

            return {
                ...metadata?.fieldList?.reduce((values, { idField }) => {
                    // TODO: Either remove mapPropsToValues and call setValue in every form field's componentDidMount
                    // or modify the query string of creditCard to an encoded JSON with the correct initial value
                    // instead of just having the credit card id
                    if (idField === "creditCard" || idField === "debitAccount") {
                        return { ...values, [idField]: data[idField] || "" };
                    }
                    return { ...values, [idField]: query[idField] || data[idField] || "" };
                }, {}),
                scheduler: data.scheduler
                    ? {
                          ...data.scheduler,
                          valueDate: data.scheduler.valueDate ? moment(data.scheduler.valueDate) : null,
                      }
                    : null,
            };
        },
        validate: (values, { metadata, currentLang, dispatch }) => {
            const { errors: formErrors } = metadata?.fieldList?.reduce(
                ({ insideValues, errors }, { idField, type, requiredErrorMap }) => {
                    const isFieldVisible = isVisible(idField, metadata.fieldList, insideValues);
                    const copiedInsideValues = { ...insideValues };

                    if (!isFieldVisible) {
                        copiedInsideValues[idField] = null;
                    }

                    if (
                        isRequired(idField, metadata.fieldList, copiedInsideValues) &&
                        isFieldVisible &&
                        (stringUtils.isEmpty(copiedInsideValues[idField]) ||
                            (Array.isArray(copiedInsideValues[idField]) &&
                                copiedInsideValues[idField].every((element) => stringUtils.isEmpty(element))) ||
                            (typeof copiedInsideValues[idField] === "object" &&
                                Object.values(copiedInsideValues[idField]).some((element) =>
                                    stringUtils.isEmpty(element),
                                )) ||
                            (type === "termsandconditions" && !copiedInsideValues[idField]))
                    ) {
                        return {
                            errors: {
                                ...errors,
                                [idField]: requiredErrorMap[currentLang],
                            },
                            insideValues: copiedInsideValues,
                        };
                    }

                    return { errors, insideValues };
                },
                { errors: {}, insideValues: cloneDeep(values) },
            );

            if (Object.keys(formErrors).length !== 0) {
                dispatch(notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"]));
            }

            return formErrors;
        },
        handleSubmit: (values, formikBag) => {
            const focusedField = document.activeElement;
            if (focusedField) {
                focusedField.blur();
            }

            const filteredValues = Object.entries(values).reduce((accumulator, [key, value]) => {
                if (value === "") {
                    return accumulator;
                }
                if (Array.isArray(value) && value.length > 0 && value[0].text !== undefined) {
                    const arrayValues = [];
                    value.forEach((item) => {
                        arrayValues.push(item.text || item);
                    });
                    return {
                        ...accumulator,
                        [key]: arrayValues,
                    };
                }
                return {
                    ...accumulator,
                    [key]: stringUtils.trim(value),
                };
            }, {});

            Mixpanel.track_form(formikBag.props.id, filteredValues);
            formikBag.props.dispatch(
                formActions.previewForm({
                    formikBag,
                    idForm: formikBag.props.id,
                    idActivity: formikBag.props.metadata.idActivity,
                    idTransaction: formikBag.props.transaction ? formikBag.props.transaction.idTransaction : null,
                    values: filteredValues,
                }),
            );
        },
    }),
    mapProps(({ metadata, ...props }) => ({
        ...props,
        metadata,
        validations: metadata?.fieldList?.reduce(
            ({ validations, insideValues }, { idField }) => {
                const isFieldVisible = isVisible(idField, metadata.fieldList, insideValues);
                const copiedInsideValues = { ...insideValues };

                if (!isFieldVisible) {
                    copiedInsideValues[idField] = null;
                }

                return {
                    validations: {
                        ...validations,
                        [idField]: {
                            isVisible: isFieldVisible,
                            isRequired: isRequired(idField, metadata.fieldList, copiedInsideValues),
                        },
                    },
                    insideValues: copiedInsideValues,
                };
            },
            { validations: {}, insideValues: cloneDeep(props.values) },
        ).validations,
    })),
)(FormRender);
