import React, { Component } from "react";
import classNames from "classnames";
import { func, string, bool } from "prop-types";

import Button from "pages/_components/Button";

class TransactionLinesFilters extends Component {
    static propTypes = {
        isExpanded: bool.isRequired,
        onFilterClick: func.isRequired,
        onCollapseClick: func.isRequired,
        selectedFilter: string,
        hasState: bool,
    };

    static defaultProps = {
        selectedFilter: null,
        hasState: true,
    };

    render() {
        const { selectedFilter, isExpanded, onFilterClick, onCollapseClick, hasState } = this.props;

        if (hasState) {
            return (
                <ul className="nav nav-pills movement-commands">
                    <li>
                        <Button
                            className={classNames("btn btn-outline", {
                                "is-active": selectedFilter === "failed",
                            })}
                            label="massive.payments.filters.failed"
                            onClick={() => {
                                onFilterClick("failed");
                            }}
                        />
                    </li>
                    <li>
                        <Button
                            className={classNames("btn btn-outline", {
                                "is-active": selectedFilter === "executed",
                            })}
                            label="massive.payments.filters.executed"
                            onClick={() => {
                                onFilterClick("executed");
                            }}
                        />
                    </li>
                    <li>
                        <Button
                            className={classNames("btn btn-outline", {
                                "is-active": selectedFilter === null,
                            })}
                            label="massive.payments.filters.all"
                            onClick={() => {
                                onFilterClick(null);
                            }}
                        />
                    </li>
                    <li>
                        <Button
                            className={classNames("btn btn-outline", {
                                "is-active": isExpanded,
                            })}
                            label="massive.payments.filters.search"
                            onClick={() => {
                                onCollapseClick();
                            }}
                        />
                    </li>
                </ul>
            );
        }
        return (
            <ul className="nav nav-pills movement-commands">
                <li>
                    <Button
                        className={classNames("btn btn-outline", {
                            "is-active": isExpanded,
                        })}
                        label="massive.payments.filters.search"
                        onClick={() => {
                            onCollapseClick();
                        }}
                    />
                </li>
            </ul>
        );
    }
}

export default TransactionLinesFilters;
