import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";
import { actions } from "reducers/checks";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import SelectorInput from "pages/_components/fields/formik/SelectorInput";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Notification from "pages/_components/Notification";
import { documentTypeFormats } from "util/format";
import classNames from "classnames";

const FORM_ID = "echeqs";

class AddBeneficiary extends Component {
    static propTypes = {
        handleSubmit: func.isRequired,
        values: shape({}).isRequired,
        setValues: func.isRequired,
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    changePlaceholderDocument = (documentType) => i18n.get(`${FORM_ID}.${documentType}.placeholder.example`);

    render() {
        const { handleSubmit, values, setValues, isSubmitting, isDesktop } = this.props;

        return (
            <Container>
                <div className={classNames("form-section-title admin-content-center mt-8", { "mb-6": isDesktop })}>
                    {isDesktop && <I18n component="h2" id={`${FORM_ID}.add.beneficiary.title`} />}
                </div>
                <Notification scopeToShow="echeqs" />
                <Form
                    className="above-the-fold"
                    onSubmit={(e) => {
                        handleSubmit(e);
                        e.stopPropagation();
                    }}>
                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                        <Col xs={12} md={8}>
                            <Field
                                component={SelectorInput}
                                name="document"
                                selectorSize="small"
                                hidePlaceholder
                                selectProps={{
                                    name: "documentType",
                                    value: {
                                        value: values.type,
                                        label: values.type,
                                    } /* values.documentType */,
                                    options: [
                                        { value: "CUIT", label: "CUIT" },
                                        { value: "CUIL", label: "CUIL" },
                                        { value: "CDI", label: "CDI" },
                                    ],
                                }}
                                isFocused
                                label={i18n.get(`${FORM_ID}.document.label`)}
                                inputProps={{
                                    name: "document",
                                    value: values.document,
                                    inputmode: "numeric",
                                }}
                                onChange={(type, stringDocument) => {
                                    setValues({
                                        ...values,
                                        type: type?.value ? type.value : type,
                                        document: stringDocument,
                                    });
                                }}
                            />
                        </Col>

                        <Col md={8} className="mt-5">
                            <Button type="submit" bsStyle="primary" label="global.add" loading={isSubmitting} />
                        </Col>
                    </Container>
                </Form>
            </Container>
        );
    }
}

export default compose(
    connect(),
    withFormik({
        mapPropsToValues: () => ({
            type: "CUIT",
            document: "",
        }),
        handleSubmit: (form, formikBag) => {
            const { setSubmitting, setErrors } = formikBag;
            const { dispatch, type } = formikBag.props;

            if (form.document === "" || form.document === "") {
                setErrors({
                    document: i18n.get("accounts.new.account.field.error.required"),
                });
                setSubmitting(false);
            } else if (documentTypeFormats(form.type, form.document)) {
                dispatch(actions.bankedBeneficiary(form, setSubmitting, type, setErrors));
                setSubmitting(true);
            } else {
                setErrors({
                    document: i18n.get("login.step1.wrong.format"),
                });
                setSubmitting(false);
            }
        },
    }),
)(AddBeneficiary);
