import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as accountsActions } from "reducers/accounts";

import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import AmountField from "pages/_components/fields/formik/AmountField";
import { bool, string } from "prop-types";

import * as i18n from "util/i18n";

const FORM_ID = "accounts.movements.filters.amount";

class AmountFilter extends Component {
    static propTypes = {
        currency: string.isRequired,
        isSubmitting: bool.isRequired,
    };

    render() {
        const { currency, isSubmitting } = this.props;

        return (
            <Form>
                <Container className="container--layout align-items-center" gridClassName="form-content">
                    <Col sm={12} md={4} className="col col-12 col-no-pad-mobile">
                        <Field
                            component={AmountField}
                            data={{ options: [{ id: 1, label: i18n.get(`currency.label.${currency}`) }] }}
                            disableSelect
                            idForm={FORM_ID}
                            name="minAmount"
                            fixedDecimalScale
                        />
                    </Col>
                    <Col sm={12} md={4} className="col col-12 col-no-pad-mobile">
                        <Field
                            component={AmountField}
                            data={{ options: [{ id: 1, label: i18n.get(`currency.label.${currency}`) }] }}
                            disableSelect
                            idForm={FORM_ID}
                            name="maxAmount"
                            fixedDecimalScale
                        />
                    </Col>
                    <Col
                        sm={12}
                        md={4}
                        className="col col-12 col-no-pad-mobile"
                        style={{
                            alignSelf: "flex-end",
                        }}>
                        <Button bsStyle="primary" label="product.filters.filter" loading={isSubmitting} type="submit" />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
        minAmount: {
            amount: "",
            currency: "",
        },
        maxAmount: {
            amount: "",
            currency: "",
        },
    }),
    validationSchema: () =>
        Yup.lazy((values) =>
            Yup.object().shape({
                minAmount: Yup.object().shape({
                    // eslint-disable-next-line no-nested-ternary
                    amount: Number.isInteger(values.minAmount.amount)
                        ? Number.isInteger(values.maxAmount.amount)
                            ? Yup.number().max(values.maxAmount.amount, i18n.get(`${FORM_ID}.minAmount.error`))
                            : Yup.number()
                        : Yup.string().nullable(),
                }),
                maxAmount: Yup.object().shape({
                    // eslint-disable-next-line no-nested-ternary
                    amount: Number.isInteger(values.maxAmount.amount)
                        ? Number.isInteger(values.minAmount.amount)
                            ? Yup.number().min(values.minAmount.amount, i18n.get(`${FORM_ID}.maxAmount.error`))
                            : Yup.number()
                        : Yup.string().nullable(),
                }),
            }),
        ),
    handleSubmit: ({ ...filters }, formikBag) => {
        const { dispatch, isDesktop, productId } = formikBag.props;
        const { maxAmount, minAmount } = filters;

        if (isDesktop) {
            dispatch(
                accountsActions.details(
                    productId,
                    { ...filters, maxAmount: maxAmount.amount, minAmount: minAmount.amount },
                    formikBag,
                ),
            );
        } else {
            dispatch(
                accountsActions.setMobileFilter({
                    filter: "amount",
                    ...filters,
                    maxAmount: maxAmount.amount,
                    minAmount: minAmount.amount,
                }),
            );
            dispatch(push(`/accounts/${productId}/filters/results`));
        }
    },
})(AmountFilter);
