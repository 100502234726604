import React, { useEffect } from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";

import I18n from "pages/_components/I18n";
import Hint from "pages/_components/hints/Hint";
import Heading from "pages/_components/Heading";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";

import * as configUtils from "util/config";

import { actions as multipleTransferActions } from "reducers/multiple-transfers";
import {
    actions as actionsTransactionLines,
    selectors as selectorsTransactionLines,
} from "reducers/form/transactionLines";

import MultipleTransferModal from "./MultipleTransferModal";

const FORM_ID = "multiple.transfers";

function MultipleTransferTicketData({ dispatch, isDesktop, data, batch, itemsDetail, isBackoffice, componentClass }) {
    const { idFile, idUser, amount, idTransactionStatus } = batch;

    useEffect(() => {
        if (idFile && itemsDetail?.length <= 0 && !isBackoffice) {
            dispatch(multipleTransferActions.getFileContentsRequest(idFile, false, idUser));
        }
        return () => {
            dispatch(actionsTransactionLines.clearTransactionLines());
        };
    }, []);

    return (
        <>
            <div className={`transfer-multiple__ticket ${componentClass}`}>
                {Object.entries(data).map(([key, item]) => (
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex"
                        label={`${FORM_ID}.summary.${key}.label`}
                        labelClassName="no-wrap"
                        data={(() => {
                            if (item.format.includes("date")) {
                                return (
                                    <FormattedDate
                                        date={item.value}
                                        dateFormat={item.format === "datetime" ? "dd/MM/yyyy hh:mm:ss" : "dd/MM/yyyy"}
                                        showTime={item.format === "datetime"}
                                    />
                                );
                            }
                            if (item.format.includes("amount")) {
                                return (
                                    <FormattedAmount
                                        {...amount}
                                        notBold
                                        className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                                    />
                                );
                            }
                            return item.value;
                        })()}
                        dataClassName="data-desc text-right"
                    />
                ))}
                {["FINISHED", "PENDING"].includes(idTransactionStatus) ? (
                    <I18n
                        id={`${FORM_ID}.ticket.list.text `}
                        component="p"
                        componentProps={{ className: "font-ligth" }}
                    />
                ) : (
                    undefined
                )}
                {!isBackoffice && (
                    <div className="d-flex justify-content-center">
                        <Button
                            type="button"
                            bsStyle="outline"
                            block={false}
                            label={`${FORM_ID}.showList.button`}
                            className="px-5"
                            onClick={() => dispatch(multipleTransferActions.setShowDetail(true))}
                        />
                    </div>
                )}
                {idTransactionStatus === "PROCESSING" ? (
                    <Hint
                        classNameMessage=""
                        classNameGroup="justify-content-center d-flex my-3"
                        classNameImage="my-0 ml-2 justify-content-center align-self-center"
                        classNameHintP="aling-items-center my-2"
                        alwaysShowMessage
                        nodeMessage={
                            <I18n
                                defaultValue={configUtils.get("frontend.MultipleTransfer.notice.message", null, {
                                    MAX_ALLOWED_TIME: configUtils.getInteger(
                                        "frontend.MultipleTransfer.max.allowed.time",
                                    ),
                                })}
                                component="p"
                                componentProps={{ className: "hint-text" }}
                            />
                        }
                    />
                ) : (
                    undefined
                )}
            </div>
            {!isBackoffice && (
                <MultipleTransferModal
                    isDesktop={isDesktop}
                    setShow={(show) => dispatch(multipleTransferActions.setShowDetail(show))}
                    list={itemsDetail}
                />
            )}
        </>
    );
}

MultipleTransferTicketData.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    data: shape({}).isRequired,
    batch: shape({}).isRequired,
    itemsDetail: arrayOf(),
    isBackoffice: bool,
    componentClass: string,
};

MultipleTransferTicketData.defaultProps = {
    itemsDetail: [],
    isBackoffice: false,
    componentClass: "",
};

const mapStateToProps = (state) => ({
    itemsDetail: selectorsTransactionLines.getTransactionLines(state),
});

export default connect(mapStateToProps)(MultipleTransferTicketData);
