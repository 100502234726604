import React, { Component, Fragment } from "react";

import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Compose from "pages/communications/_components/Compose";
import { connect } from "react-redux";
import { bool } from "prop-types";

class NewMessage extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
    };

    render() {
        const { isDesktop } = this.props;
        return (
            <Fragment>
                <Head
                    title="communications.compose.message.new"
                    closeLinkTo="/communications"
                    emptyRightOption
                    backLinkTo={!isDesktop ? "/communications" : null}
                    titleClassName={!isDesktop ? "header-mobile-title-background-blue" : null}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : null}
                />
                <MainContainer>
                    <Compose />
                </MainContainer>
            </Fragment>
        );
    }
}

export default connect()(NewMessage);
