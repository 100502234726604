import React from 'react'
import { bool, number, shape } from 'prop-types';
import { Field } from 'formik';
import { Col } from 'react-bootstrap';
import classNames from 'classnames';

import Date from "pages/_components/fields/DateField";
import Checkbox from "pages/_components/Checkbox";

import { getLimitDate } from 'util/date';

const FORM_ID = "echeqs";

function EcheqsDatesSelectors({
    values,
    isNotFullsize,
    isSmallDesktop,
    isDesktop,
    width
}) {
    return (
        <>
            {!isDesktop && (
                <Col xs={12}>
                    <Field
                        component={Checkbox}
                        idForm={FORM_ID}
                        name="emitedCheck"
                        isDesktop={isDesktop}
                    />
                </Col>
            )}
            <Col
                xs={12}
                md={width < 1020 ? 10 : 6}
                lg={isNotFullsize ? 5 : 4}
                className={classNames("d-flex align-items-center justify-content-start", {
                    "pl-3 pr-2": isDesktop,
                })}>
                {isDesktop && (
                    <div className="pt-5">
                        <Field
                            component={Checkbox}
                            hideLabel
                            idForm={FORM_ID}
                            name="emitedCheck"
                            isDesktop={isDesktop}
                        />
                    </div>
                )}
                <div className={classNames("d-flex align-items-center flex", { "mr-3": isDesktop })}>
                    <Field
                        idField="createdFrom"
                        formikValueFirst
                        component={Date}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="createdFrom"
                        customKey={!isDesktop && "echeqs.from.label"}
                        disabled={!values.emitedCheck}
                        isDisabled={!values.emitedCheck}
                        maxDate={values.createdTo}
                        minDate={getLimitDate(-3650)}
                        formGroupClassName={classNames("mr-1 w-100", {
                            "mb-0": isDesktop,
                            "field-disabled": !values.emitedCheck,
                        })}
                    />
                </div>
                <div
                    className={classNames("d-flex align-items-center flex", {
                        "mr-3": isSmallDesktop,
                        "mr-1": isDesktop,
                    })}>
                    <Field
                        idField="createdTo"
                        formikValueFirst
                        component={Date}
                        hidePlaceholder
                        idForm={FORM_ID}
                        disabled={!values.emitedCheck}
                        isDisabled={!values.emitedCheck}
                        name="createdTo"
                        customKey={!isDesktop && "echeqs.to.label"}
                        minDate={values.createdFrom}
                        maxDate={getLimitDate(3650)}
                        formGroupClassName={classNames("w-100", {
                            "mb-0": isDesktop,
                            "field-disabled": !values.emitedCheck,
                        })}
                    />
                </div>
            </Col>
            {!isDesktop && (
                <Col xs={12}>
                    <Field
                        component={Checkbox}
                        idForm={FORM_ID}
                        name="payedCheck"
                        label="tableHeader.paydate"
                        isDesktop={isDesktop}
                    />
                </Col>
            )}
            <Col
                xs={12}
                md={width < 1020 ? 10 : 6}
                lg={isNotFullsize ? 5 : 4}
                className={classNames("d-flex align-items-center justify-content-start", {
                    "pl-3 pr-2": isDesktop,
                })}>
                {isDesktop && (
                    <div className="pt-5">
                        <Field
                            component={Checkbox}
                            hideLabel
                            idForm={FORM_ID}
                            name="payedCheck"
                            isDesktop={isDesktop}
                        />
                    </div>
                )}
                <div className={classNames("d-flex align-items-center flex", { "mr-3": isDesktop })}>
                    <Field
                        idField="payedFrom"
                        formikValueFirst
                        component={Date}
                        hidePlaceholder
                        idForm={FORM_ID}
                        name="payedFrom"
                        customKey={!isDesktop && "echeqs.from.label"}
                        disabled={!values.payedCheck}
                        isDisabled={!values.payedCheck || values.action === "to_custody" || values.action === "request_discount"}
                        minDate={getLimitDate(-3650)}
                        maxDate={values.payedTo}
                        formGroupClassName={classNames("mr-1 w-100", {
                            "mb-0": isDesktop,
                            "field-disabled": !values.payedCheck,
                        })}
                    />
                </div>
                <div className={classNames("d-flex align-items-center flex", { "mr-3": isDesktop })}>
                    <Field
                        idField="payedTo"
                        formikValueFirst
                        component={Date}
                        hidePlaceholder
                        idForm={FORM_ID}
                        disabled={!values.payedCheck}
                        isDisabled={!values.payedCheck || values.action === "to_deposit"}
                        name="payedTo"
                        customKey={!isDesktop && "echeqs.to.label"}
                        minDate={values.payedFrom}
                        maxDate={getLimitDate(3650)}
                        formGroupClassName={classNames("mr-1 w-100 mr-md-0", {
                            "mb-0": isDesktop,
                            "field-disabled": !values.payedCheck,
                        })}
                    />
                </div>
            </Col>
        </>
    );
}

EcheqsDatesSelectors.propTypes = {
    values: shape({}).isRequired,
    isNotFullsize: bool.isRequired,
    isSmallDesktop: bool.isRequired,
    isDesktop: bool.isRequired,
    width: number.isRequired
}

export default EcheqsDatesSelectors