import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool, arrayOf, string } from "prop-types";
import { selectors } from "reducers/checks";
import { selectors as transactionsSelectors } from "reducers/transactions";
import TransactionPotencialSchemesDrawer from "pages/transactions/_components/TransactionPotencialSchemesDrawer";
import Button from "pages/_components/Button";
import DrawerModal from "pages/_components/drawer/DrawerModal";
import I18n from "pages/_components/I18n";

class TransactionScheme extends Component {
    static propTypes = {
        potentialSignaturesData: arrayOf(
            shape({ typesOfSignatures: arrayOf({ signature: string, selected: bool }), liberator: string }),
        ).isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        showDrawer: false,
        contentDrawer: "",
    };

    openModal = () => {
        const { potentialSignaturesData } = this.props;
        this.setState({
            showDrawer: true,
            contentDrawer: <TransactionPotencialSchemesDrawer potentialSignaturesData={potentialSignaturesData} />,
        });
    };

    closeDrawer = () => {
        this.setState({ showDrawer: false });
    };

    render() {
        const { isDesktop, potentialSignaturesData } = this.props;
        const { showDrawer, contentDrawer } = this.state;

        return (
            <>
                {potentialSignaturesData?.length !== 0 && (
                    <div className="d-flex justify-content-end pr-4">
                        {showDrawer && (
                            <DrawerModal
                                content={contentDrawer}
                                showDrawerRemote={showDrawer}
                                isDesktop={isDesktop}
                                closeRemotely={() => this.closeDrawer()}
                                width="30%"
                                noBlueHeader
                            />
                        )}
                        <Button
                            className="btn-outline"
                            style={{ height: "3rem", width: "11rem" }}
                            onClick={this.openModal}>
                            <I18n id="pendingTransaction.info.scheme" componentProps={{ style: { lineHeight: 1 } }} />
                        </Button>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionEcheqData(state),
    potentialSignaturesData: transactionsSelectors.getPotentialSignaturesData(state),
});

export default connect(mapStateToProps)(TransactionScheme);
