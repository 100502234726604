import React, { useState, useEffect, useCallback } from "react";
import Tour from "reactour";
import { bool, oneOfType, arrayOf, shape, string, node, element, func } from "prop-types";

import TooltipTourContent from "pages/forms/_components/_fields/TooltipTourContent";

const TooltipTour = (props) => {
    const { steps, run } = props;
    const [isOpen, setIsOpen] = useState(run);
    const [stepsData, setStepsData] = useState(steps);

    const handleSteps = useCallback((data) => {
        const newSteps = data.map((step) => {
            const { title, content, selector, isClassSelector } = step;
            return {
                ...step,
                selector: isClassSelector ? `.${selector}` : `[id='${selector}']`,
                content: <TooltipTourContent title={title} content={content} />,
            };
        });
        setStepsData(newSteps);
    }, []);

    useEffect(() => {
        handleSteps(steps);
    }, [steps, handleSteps]);

    useEffect(() => {
        setTimeout(() => {
            setIsOpen(run);
        }, 1000);
    }, [run]);

    return (
        <Tour
            {...props}
            isOpen={isOpen}
            steps={stepsData}
            disableFocusLock
            rounded={10}
            // accentColor="#c30000"
            // className="ourClass"
            className="tooltip-tour"
            onRequestClose={() => setIsOpen(false)}
        />
    );
};

TooltipTour.propTypes = {
    steps: arrayOf(
        shape({
            title: string,
            content: oneOfType([node, element, func]).isRequired,
            selector: string.isRequired,
        }),
    ).isRequired,
    run: bool,
    isClassSelector: bool,
};

TooltipTour.defaultProps = {
    run: false,
    isClassSelector: false,
};

export default TooltipTour;
