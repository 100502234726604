import React, { Component } from "react";
import Panel from "react-bootstrap/lib/Panel";
import { func, arrayOf, shape, string, oneOf } from "prop-types";
import { Field } from "formik";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";

import { permissionsSelectors } from "reducers/administration";
import * as arrayUtils from "util/array";
import * as i18nUtils from "util/i18n";

import SmartGroups from "pages/administration/_components/advancedPermissionsForm/SmartGroups";
import I18n from "pages/_components/I18n";
import MultiSelect from "pages/_components/fields/formik/FormickMultiSelectGroup";

export const PermissionsPanelContext = React.createContext();

class PermissionsPanel extends Component {
    static propTypes = {
        render: func.isRequired,
        products: arrayOf(
            shape({
                label: string,
                value: string,
            }),
        ).isRequired,
        mode: oneOf(["view", "edit"]).isRequired,
    };

    state = {
        option: null,
    };

    handleClick = (option) => {
        this.setState({ option });
    };

    handleBlur = () => {
        this.setState({ option: null });
    };

    removePermission = (form, name, selectedValue) => {
        const { setValues, values } = form;
        const value = values.permissions[name] || [];

        setValues({
            ...values,
            permissions: {
                ...values.permissions,
                [name]: value.filter((activePermission) => activePermission !== selectedValue),
            },
        });
    };

    addPermission = (form, name, selectedValue) => {
        const { setValues, values } = form;
        const value = values.permissions[name] || [];

        setValues({
            ...values,
            permissions: {
                ...values.permissions,
                [name]: [...value, selectedValue],
            },
        });
    };

    renderPanel = () => {
        const { option } = this.state;
        const { mode, products } = this.props;

        if (option) {
            const { permissionList, idItem, label } = option;
            const [permission] = permissionList;
            const productTypes = permission.productTypes.split(",");
            const productOptions = arrayUtils.mapItemsIds(
                products.filter(({ productType }) => productTypes.includes(productType)),
                "value",
            );

            const marginValue =
                option &&
                option.accordionContext &&
                option.accordionContext.itemOpen &&
                option.accordionContext.itemOpen * 66;

            const marginTopStyle = marginValue && marginValue.toString().concat("px");

            if (mode !== "edit") {
                return (
                    <div style={{ position: "relative" }}>
                        <Panel style={marginValue ? { marginTop: marginTopStyle.toString() } : {}}>
                            <Panel.Heading className="pt-4 px-4">
                                <Panel.Title className="mt-0" componentClass="h3">
                                    {label}
                                </Panel.Title>
                            </Panel.Heading>
                            <Field
                                name={idItem}
                                render={({ form, field }) => {
                                    const { values = {} } = form;
                                    const { name } = field;
                                    const value = values.permissions[name] || [];

                                    const { smartGroups, productsAdded } = value.reduce(
                                        (acc, val) => {
                                            if (val.indexOf("ALL") !== -1) {
                                                return { ...acc, smartGroups: [...acc.smartGroups, val] };
                                            }

                                            return { ...acc, productsAdded: [...acc.productsAdded, val] };
                                        },
                                        { smartGroups: [], productsAdded: [] },
                                    );

                                    return (
                                        <Panel.Body className="pt-2 px-4 pb-4">
                                            {smartGroups.length > 0 && (
                                                <div className="form-group mb-0">
                                                    <div className="form-group-text font-weight-normal mb-0">
                                                        <I18n id="administration.permissions.advanced.smart.groups" />
                                                    </div>
                                                    <ul className="form-group-control-list list my-0">
                                                        {smartGroups.map((smartGroup) => (
                                                            <li key={smartGroup} className="list-item">
                                                                <I18n id={`productType.${smartGroup.split("_")[1]}`} />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                            {productsAdded.length > 0 && (
                                                <div className="form-group mb-0">
                                                    <div className="form-group-text font-weight-normal mb-0">
                                                        <I18n id="administration.permissions.advanced.products" />
                                                    </div>
                                                    <ul className="form-group-control-list list my-0">
                                                        {productsAdded.map((productId) => (
                                                            <li key={productId} className="list-item">
                                                                {productOptions.byId[productId].label}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </Panel.Body>
                                    );
                                }}
                            />
                        </Panel>
                    </div>
                );
            }

            return (
                <Panel>
                    <Panel.Heading>
                        <Panel.Title className="mt-0" componentClass="h3">
                            {label}
                        </Panel.Title>
                        <p>
                            <I18n id="administration.permissions.select.products.types" />
                        </p>
                    </Panel.Heading>
                    <Field
                        name={idItem}
                        render={({ form, field }) => {
                            const { values } = form;
                            const { name } = field;
                            const value = values.permissions[name] || [];

                            return (
                                <>
                                    <Panel.Body>
                                        <SmartGroups
                                            name={name}
                                            productTypes={productTypes}
                                            value={value}
                                            onChange={({ target }) => {
                                                if (value.includes(target.value)) {
                                                    this.removePermission(form, name, target.value);
                                                } else {
                                                    this.addPermission(form, name, target.value);
                                                }
                                            }}
                                            mode={mode}
                                        />
                                    </Panel.Body>
                                    <div className="separator-admin" />
                                    <Panel.Body className="flex-container group-product-permisions mt-45 mb-5">
                                        <Col className="col-12 max-xl-480 px-0" md={9}>
                                            <p className="text-label">
                                                {i18nUtils
                                                    .get(`administration.permissions.select.specific.products`)
                                                    .toUpperCase()}
                                            </p>
                                            <MultiSelect
                                                name={`permissions.${name}`}
                                                options={productOptions}
                                                placeholder="administration.permissions.products.placeholder"
                                                values={value}
                                                onSelect={(selectedOption) =>
                                                    this.addPermission(form, name, selectedOption.value)
                                                }
                                                onDelete={(selectedOption) =>
                                                    this.removePermission(form, name, selectedOption.value)
                                                }
                                                mode={mode}>
                                                {(product) => <span className="data-desc">{product.label}</span>}
                                            </MultiSelect>
                                        </Col>
                                    </Panel.Body>
                                </>
                            );
                        }}
                    />
                </Panel>
            );
        }

        return null;
    };

    render() {
        const { render } = this.props;
        const { option } = this.state;

        return (
            <PermissionsPanelContext.Provider
                value={{
                    onClick: this.handleClick,
                    onBlur: this.handleBlur,
                    activeIdItem: option && option.idItem,
                }}>
                {render(this.renderPanel())}
            </PermissionsPanelContext.Provider>
        );
    }
}

const mapStateToProps = (state) => ({
    products: permissionsSelectors.getMappedProducts(state),
});

export default connect(mapStateToProps)(PermissionsPanel);
