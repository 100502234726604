import React from "react";
import { connect } from "react-redux";
import { bool, shape } from "prop-types";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";

import { selectors as fundcorpSelectors } from "reducers/fundcorp";
import { RESCUE_TYPE } from "constants.js";
import getRescueDeadlineLabel from "util/fundcorp";

import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import FieldLabel from "pages/_components/fields/FieldLabel";
import * as configUtils from "util/config";

function RescueSummaryData({ isDesktop, spaceBetween, summary }) {
    const { BY_AMOUNT, BY_SHAREACCOUNT, TOTAL } = RESCUE_TYPE;

    const {
        selectedFund,
        amount,
        equivalentCuotapartes,
        rescueType,
        creditAccountLabel,
        rescueDate,
        idTransactionStatus,
        idTransaction,
        scheduler,
        selectedScheduler,
        submitDateTime,
    } = summary;

    if (!amount || !equivalentCuotapartes || !rescueType) {
        return <></>;
    }

    const {
        tipoVCPDescripcion: fondoClase,
        fondoNombre,
        monedaIso: fondoMoneda,
        rescatePlazo,
        cuotapartistaNumero,
    } = selectedFund;

    const investmentsFunctionalities = configUtils.getBoolean(
        "frontend.show.failedVoucher.investmentsFunctionalities",
        false,
    );

    return (
        <>
            {submitDateTime && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between": spaceBetween,
                    })}
                    label="forms.transaction.ticket.date"
                    labelClassName="no-wrap"
                    data={<FormattedDate date={submitDateTime} dateFormat="dd/MM/yyyy" showTime />}
                    dataClassName="data-desc"
                />
            )}
            {idTransaction && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between": spaceBetween,
                    })}
                    label="forms.transaction.ticket.number"
                    labelClassName="no-wrap"
                    data={idTransaction.substring(0, 8).toUpperCase()}
                    dataClassName="data-desc"
                />
            )}
            {fondoNombre && fondoMoneda && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between": spaceBetween,
                    })}
                    label="fund.rescue.summary.fund.label"
                    labelClassName="no-wrap"
                    data={`${fondoNombre}`}
                    dataClassName="data-desc"
                />
            )}

            {rescueType === BY_AMOUNT && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between": spaceBetween,
                    })}
                    label="fund.rescue.summary.rescueAmount.label"
                    labelClassName="no-wrap"
                    data={<FormattedAmount {...amount} notBold className={`${isDesktop ? "f-size-4" : "f-size-65"}`} />}
                    dataClassName="data-desc"
                />
            )}

            {rescueType === BY_SHAREACCOUNT && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between": spaceBetween,
                    })}
                    label="fund.rescue.summary.cuotapartesAmount.label"
                    labelClassName="no-wrap"
                    data={
                        <FormattedAmount
                            noCurrency
                            quantity={equivalentCuotapartes}
                            minimumDecimalsCustom={4}
                            maximumDecimalsCustom={4}
                            className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                            notBold
                        />
                    }
                    dataClassName="data-desc"
                />
            )}

            {rescueType === TOTAL && (
                <>
                    <Heading.DataGroup
                        containerClassName={classNames("transfer-data data-wrapper-flex", {
                            "space-between": spaceBetween,
                        })}
                        label="fund.rescue.summary.rescueAmount.label"
                        labelClassName="no-wrap"
                        data={
                            <FormattedAmount
                                {...amount}
                                notBold
                                className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                            />
                        }
                        dataClassName="data-desc"
                    />
                    <Heading.DataGroup
                        containerClassName={classNames("transfer-data data-wrapper-flex", {
                            "space-between": spaceBetween,
                        })}
                        label="fund.rescue.summary.cuotapartesAmount.label"
                        labelClassName="no-wrap"
                        data={
                            <FormattedAmount
                                noCurrency
                                quantity={equivalentCuotapartes}
                                minimumDecimalsCustom={4}
                                maximumDecimalsCustom={4}
                                className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                                notBold
                            />
                        }
                        dataClassName="data-desc"
                    />
                </>
            )}

            {fondoClase && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between": spaceBetween,
                    })}
                    label="fund.rescue.summary.class.label"
                    labelClassName="no-wrap"
                    data={fondoClase}
                    dataClassName="data-desc"
                />
            )}

            {rescatePlazo && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between": spaceBetween,
                    })}
                    label="fund.rescue.summary.deadline.label"
                    labelClassName="no-wrap"
                    data={getRescueDeadlineLabel(rescatePlazo)}
                    dataClassName="data-desc"
                />
            )}

            {creditAccountLabel && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between": spaceBetween,
                    })}
                    label="fund.rescue.summary.creditAccount.label"
                    labelClassName="no-wrap"
                    data={creditAccountLabel}
                    dataClassName="data-desc"
                />
            )}

            {cuotapartistaNumero && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between": spaceBetween,
                    })}
                    label="fund.rescue.summary.shareAccount.label"
                    data={cuotapartistaNumero}
                    dataClassName="data-desc"
                />
            )}

            {(!idTransactionStatus || idTransactionStatus === "PENDING" || idTransactionStatus === "SCHEDULED") &&
                (scheduler?.newValueDate || selectedScheduler?.newValueDate) && (
                    <Heading.DataGroup
                        containerClassName={classNames("transfer-data data-wrapper-flex", {
                            "space-between": spaceBetween,
                        })}
                        label="fund.rescue.summary.date.label"
                        labelClassName="no-wrap"
                        data={<Message FORM_ID="fund.sheduler.rescue" value={selectedScheduler || scheduler} />}
                        dataClassName="data-desc"
                    />
                )}

            {rescueDate && !scheduler?.newValueDate && !selectedScheduler?.newValueDate && (
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`}
                    label="fund.rescue.summary.date.label"
                    data={<FormattedDate date={rescueDate || new Date()} dateFormat="dd/MM/yyyy" />}
                />
            )}
            {idTransactionStatus && idTransactionStatus === "FAILED" && !investmentsFunctionalities && (
                <>
                    <Row className="mx-0">
                        <I18n
                            component="h2"
                            id="fund.rescue.summary.reference.label"
                            componentProps={{
                                className: "transaction-ticket-signature-title",
                            }}
                        />
                    </Row>
                    <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                        <Row className="space-between">
                            <FieldLabel labelKey="transfers.failed.response" notMarginY />
                            <I18n
                                component="p"
                                id="fund.rescue.summary.generic.error.message"
                                componentProps={{
                                    className: "data-desc",
                                }}
                            />
                        </Row>
                    </Col>
                </>
            )}
        </>
    );
}

RescueSummaryData.propTypes = {
    isDesktop: bool,
    spaceBetween: bool,
    summary: shape({
        selectedFund: shape({}),
    }).isRequired,
};

RescueSummaryData.defaultProps = {
    isDesktop: true,
    spaceBetween: false,
};

const mapStateToProps = (state) => ({
    summary: fundcorpSelectors.getSummary(state),
});

export default connect(mapStateToProps)(RescueSummaryData);
