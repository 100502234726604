import React, { Component, Fragment } from "react";

import ProductBody from "pages/_components/ProductBody";
import ProductHead from "pages/_components/ProductHead";

class ProductDetail extends Component {
    static Head = ProductHead;

    static Body = ProductBody;

    render() {
        const { children } = this.props;

        return <Fragment>{children}</Fragment>;
    }
}

export default ProductDetail;
