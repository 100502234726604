import { arrayOf, bool, func, shape } from "prop-types";
import { FORM_ID } from "constants/servicePayments";
import React, { useEffect, useState } from "react";

import { selectors as servicePaymentsSelectors, actions as servicePaymentsActions } from "reducers/servicePayments";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as accountsSelectors } from "reducers/accounts";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

import Container from "pages/_components/Container";
import { Form, withFormik } from "formik";
import * as Yup from "yup";
import Col from "react-bootstrap/lib/Col";
import Table from "pages/_components/Table";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import { compose } from "redux";
import { connect } from "react-redux";
import { buildPreparePaymentsRequest } from "util/servicePayments";
import Checkbox from "pages/_components/Checkbox";
import ListItem from "./MultiplePaymentsListItem";
import ListHeader from "./MultiplePaymentsListHeader";
import PaymentAFIPList from "../_components/PaymentAFIPList";
import MultipleAFIPList from "./MultipleAFIPList";

const MultiplePaymentsList = ({
    isDesktop,
    isSmallDesktop,
    servicePaymentsSelected,
    accounts,
    submitForm,
    dispatch,
    flatPayments,
    isRetailEnvironment,
    setFieldValue,
    setErrors,
    setTouched,
}) => {
    const [allSelected, setAllSelected] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [payments, setPayments] = useState(flatPayments || []);
    const currencies = [{ id: 0, label: i18nUtils.get(`currency.label.0`) }];

    useEffect(() => {
        setPayments(flatPayments);

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let allPaymentsSelected =
            payments?.filter(({ isPending }) => !isPending).every(({ isChecked }) => isChecked) &&
            payments?.length !== 0;
        const atLeastOnePaymentSelected = payments?.some(({ isChecked }) => isChecked);

        if (payments?.every(({ isPending }) => isPending)) {
            allPaymentsSelected = false;
        }
        setAllSelected(allPaymentsSelected);
        setDisableButton(!atLeastOnePaymentSelected);
    }, [payments]);

    const setExpirationAmount = (payment) => {
        if (payment.concept?.date) {
            setFieldValue(`${payment.key}_conceptAmount${payment.concept?.debtId}`, {
                amount: payment.concept?.amount,
            });
        }
    };

    const handleSelectAll = (checked) => {
        const updatedPayments = payments.map(({ isPending, isChecked, ...payment }) => {
            setExpirationAmount(payment);
            return {
                ...payment,
                isChecked: isPending ? false : checked,
                isPending,
            };
        });
        setPayments(updatedPayments);
        dispatch(servicePaymentsActions.updatePaymentsInfo(updatedPayments));
    };

    const handleSelectOne = (payment) => {
        const { key: paymentKey, isPending } = payment;
        if (isPending) {
            return;
        }
        const updatedPayments = payments.map(({ isChecked, key, amount, concept, ...rest }) => ({
            ...rest,
            key,
            concept,
            isChecked: paymentKey === key ? !isChecked : isChecked,
            amount: paymentKey === key && concept?.date ? { quantity: concept?.amount } : amount,
        }));

        setExpirationAmount(payment);
        setPayments(updatedPayments);
        dispatch(servicePaymentsActions.updatePaymentsInfo(updatedPayments));
    };

    const handleUniquePayment = (payment) => {
        const { isChecked, key: paymentKey } = payment;
        if (isChecked) {
            const updatedPayments = payments.map(({ key, ...rest }) => ({
                ...rest,
                key,
                isChecked: key === paymentKey,
            }));
            setPayments(updatedPayments);
            dispatch(servicePaymentsActions.updatePaymentsInfo(updatedPayments));
            submitForm();
        } else {
            handleSelectOne(payment);
        }
    };

    const setQuantity = ({ key: paymentKey }, quantity) => {
        const updatedPayments = payments.map(({ amount, key, ...payment }) => ({
            ...payment,
            key,
            amount: paymentKey === key ? { quantity } : amount,
        }));
        setPayments(updatedPayments);
        dispatch(servicePaymentsActions.updatePaymentsInfo(updatedPayments));
    };

    const setPaymentReference = ({ key: paymentKey }, value) => {
        const updatedPayments = payments.map(({ paymentReference, key, ...payment }) => ({
            ...payment,
            key,
            paymentReference: paymentKey === key ? value : paymentReference,
        }));
        setPayments(updatedPayments);
        dispatch(servicePaymentsActions.updatePaymentsInfo(updatedPayments));
    };

    const renderNoPaymentsAvailable = () => (
        <div className={`text-center no-more-data ${!isDesktop} mx-4`} key="noMoreMovements">
            <div className="illustration-wrapper">
                <Image
                    src="images/coloredIcons/no-debts.svg"
                    className="svg-big-icon"
                    styles={{ width: isDesktop ? "96px" : "72px", height: "auto" }}
                />
            </div>
            <p
                className="text-lead"
                style={{
                    opacity: 1,
                    marginTop: isDesktop && "2rem",
                    fontFamily: !isDesktop && "Graphik-Light",
                    color: !isDesktop && "#707070",
                }}>
                <I18n id={`${FORM_ID}.empty.message`} />
            </p>
        </div>
    );

    const allowMultipleAFIP = () => {
        const showMultipleAFIPForCorporate = configUtils.getBoolean(
            "frontend.show.MultiplePaymentVEP.functionalities.Corporate",
            false,
        );
        const showMultipleAFIPForRetail = configUtils.getBoolean(
            "frontend.show.MultiplePaymentVEP.functionalities.Retail",
            false,
        );

        return isRetailEnvironment ? showMultipleAFIPForRetail : showMultipleAFIPForCorporate;
    };

    if (servicePaymentsSelected) {
        return (
            <>
                <Form autocomplete="off">
                    <ListHeader
                        isDesktop={isDesktop}
                        FORM_ID={FORM_ID}
                        accounts={accounts}
                        submitForm={submitForm}
                        disableButton={disableButton}
                        payments={payments}
                        currencies={currencies}
                        setErrors={setErrors}
                        setTouched={setTouched}
                    />
                    <Container
                        className={`flex-grow scrollable ${!isDesktop &&
                            !payments &&
                            !payments.length &&
                            "no-results-container"}`}
                        rowClassName={`${!isDesktop && "container-mobile-full-height"} ${payments !== undefined &&
                            !payments.length &&
                            "container-empty-results"}`}>
                        <Col className="col col-12 px-0">
                            <div className={`w-100 ${!isDesktop ? "d-flex bd-spacing-0-2" : "bd-spacing-0-8"}`}>
                                {payments && payments.length ? (
                                    <div className="w-100 my-1">
                                        <Checkbox
                                            className="mb-0 ml-3"
                                            labelText={`${FORM_ID}.selectAll`}
                                            name="selectAllPayments"
                                            checked={allSelected}
                                            onChange={handleSelectAll}
                                        />
                                        <Table>
                                            {payments.map((payment) => (
                                                <ListItem
                                                    isDesktop={isDesktop}
                                                    isSmallDesktop={isSmallDesktop}
                                                    tableLabel={FORM_ID}
                                                    currencies={currencies}
                                                    payment={payment}
                                                    handleSelectOne={handleSelectOne}
                                                    handleUniquePayment={handleUniquePayment}
                                                    setQuantity={setQuantity}
                                                    setPaymentReference={setPaymentReference}
                                                    agendaPago={payment}
                                                />
                                            ))}
                                        </Table>
                                    </div>
                                ) : (
                                    renderNoPaymentsAvailable()
                                )}
                            </div>
                        </Col>
                    </Container>
                </Form>
            </>
        );
    }

    if (allowMultipleAFIP()) {
        return (
            <>
                <MultipleAFIPList isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
            </>
        );
    }

    return (
        <>
            <PaymentAFIPList isDesktop={isDesktop} isSmallDesktop={isSmallDesktop} />
        </>
    );
};

const handlePrepareMultiplePayments = ({ account }, formikBag) => {
    const {
        props: { dispatch, paymentsInfo, account: accountObj },
        setTouched,
        setErrors,
    } = formikBag;
    const selectedPayments = paymentsInfo.filter(({ isChecked }) => isChecked);

    const total = selectedPayments.reduce((acc, val) => {
        const amount = val?.amount?.quantity || val.monto;
        return acc + amount;
    }, 0);

    if (total > accountObj?.balance) {
        setTouched({ account: true });
        setErrors({
            account: i18nUtils.get("services.multiplePayment.field.account.amountExceeded"),
        });
    } else {
        setErrors({});
        const preparePayments = buildPreparePaymentsRequest(selectedPayments);
        dispatch(servicePaymentsActions.cleanHandleBackFromTicket());
        dispatch(servicePaymentsActions.prepareMultiplePayments(preparePayments, account, formikBag));
    }
};

const mapStateToProps = (state) => ({
    servicePaymentsSelected: servicePaymentsSelectors.getServiceSelected(state),
    accounts: accountsSelectors.getAccounts(state),
    account: servicePaymentsSelectors.getMultiplePaymentsAccount(state),
    flatPayments: servicePaymentsSelectors.getFlatPayments(state),
    paymentsInfo: servicePaymentsSelectors.getMultiplePaymentsInfo(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
});

MultiplePaymentsList.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    isSmallDesktop: bool.isRequired,
    servicePaymentsSelected: bool.isRequired,
    accounts: arrayOf(shape({})).isRequired,
    flatPayments: arrayOf(shape({})).isRequired,
    submitForm: func.isRequired,
    isRetailEnvironment: bool.isRequired,
    setFieldValue: func.isRequired,
    setErrors: func.isRequired,
    setTouched: func.isRequired,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: (props) => {
            const initialValues = {
                account: "",
                ...props.componentValues,
            };

            return initialValues;
        },
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    account: Yup.string().required(i18nUtils.get(`${FORM_ID}.field.error.required`)),
                }),
            ),
        handleSubmit: (values, formikBag) => handlePrepareMultiplePayments(values, formikBag),
    }),
)(MultiplePaymentsList);
