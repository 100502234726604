import React from "react";
import { bool, string } from "prop-types";

import * as i18n from "util/i18n";

import I18n from "pages/_components/I18n";

function TermsAndCoditionsLink({ FORM_ID, url, isDesktop }) {
    return (
        <>
            <I18n id={`${FORM_ID}.termAndConditions.prefix`} />
            {url === "#" || !isDesktop ? (
                <span className="pl-1">{i18n.get(`${FORM_ID}.termAndConditions.sufix`)}</span>
            ) : (
                <a href={url} target="_blank" rel="noopener noreferrer" className="pl-1">
                    {i18n.get(`${FORM_ID}.termAndConditions.sufix`)}
                </a>
            )}
        </>
    );
}

TermsAndCoditionsLink.propTypes = {
    isDesktop: bool,
    FORM_ID: string,
    url: string.isRequired,
};

TermsAndCoditionsLink.defaultProps = {
    isDesktop: true,
    FORM_ID: "fund.operation.subscribe",
};

export default TermsAndCoditionsLink;
