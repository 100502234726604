import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bool, func, shape, number, arrayOf, string } from "prop-types";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as debinSelectors } from "reducers/debin";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import * as i18nUtils from "util/i18n";
import * as accountUtils from "util/accounts";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";

import CredinTransactionData from "pages/transfer/_components/CredinTransactionData";
import ModifyPendingTransactionModal from "pages/transactions/_components/ModifyPendingTransactionModal";
import TransactionTicket from "pages/_components/TransactionTicket";

class CredinPrepareConfirmation extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        dispatch: func.isRequired,
        summary: shape({}).isRequired,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
        }).isRequired,
        accounts: shape({}).isRequired,
    };

    state = {
        isModalDisplayed: false,
        fetchingDownload: false,
        fetching: false,
    };

    componentDidMount() {
        const { summary, dispatch, loggedUser } = this.props;
        if (!summary?.idTransactionStatus) {
            dispatch(push(`/pendingTransaction/list`));
        } else {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
    }

    centerContentMobile = () => {
        const { isDesktop } = this.props;
        return (
            <div>
                {!isDesktop && (
                    <div className="title-account-header-multiline-background-blue">
                        <h1 style={{ margin: "0.2rem" }}>{i18nUtils.get("requestCredin.title")}</h1>
                    </div>
                )}
            </div>
        );
    };

    getAccountName = (account) => {
        const { productAlias, number: accountNumber, productType, currency } = account;
        return `${productAlias} ${accountUtils.maskedAccountNumber(accountNumber)} ${productType} ${i18nUtils.get(
            `currency.label.${currency}`,
        )}`;
    };

    buttonsDetail = () => {
        const { dispatch } = this.props;

        return [
            {
                label: "credin.confirm.goToRequestCredin",
                bsStyle: "primary",
                onClick: () => dispatch(push(`/requestCredin`)),
            },
        ];
    };

    exportPdf = async (accountName) => {
        this.setState({ fetchingDownload: true });
        const { summary } = this.props;

        const ticketBody = !Array.isArray(summary.recipients)
            ? this.buildPdf(summary.recipients, accountName)
            : summary.recipients
                  .map((r) => this.buildPdf(r, accountName))
                  .reduce((acum, element) => acum.concat(element), []);

        const ticketData = {
            ticketName: i18nUtils.get("requestCredin.title"),
            ticketBody,
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    buildPdf = (recipient, accountName) => {
        const { summary } = this.props;
        const { concept, description } = summary;
        const {
            recipientName,
            recipientDocumentType,
            recipientDocumentNumber,
            amount: { currency, quantity },
            accountNumber,
            cbu,
            localBank,
            reference,
        } = recipient;
        return [
            getGeneralTicketItem("negotiate.transaction.date.label", summary?.submitDateTime, "datefulltime"),
            getGeneralTicketItem(
                `requestCredin.confirm.table.header.ticket`,
                summary?.idTransaction.substring(0, 8).toUpperCase(),
                "string",
            ),
            getGeneralTicketItem("requestCredin.confirm.table.header.debitAccount", accountName, "string"),
            getGeneralTicketItem("credin.transaction.id.credin", summary.idCredin || "", "string"),
            getGeneralTicketItem("requestCredin.confirm.table.header.cbu", cbu, "string"),
            getGeneralTicketItem("requestCredin.confirm.table.header.creditAccount.label", accountNumber, "string"),
            getGeneralTicketItem(
                "requestCredin.confirm.table.header.creditAccount.cuit",
                `${recipientDocumentType} ${recipientDocumentNumber}`,
                "string",
            ),
            getGeneralTicketItem("requestCredin.confirm.table.header.creditAccount.owner", recipientName, "string"),
            getGeneralTicketItem("requestCredin.confirm.table.header.creditAccount.bank", localBank, "string"),
            getGeneralTicketItem(
                "requestCredin.confirm.table.header.creditAccount.reference",
                reference || "",
                "string",
            ),
            getGeneralTicketItem(
                "requestCredin.confirm.table.header.amount",
                { quantity, currency },
                "amountFrequency",
            ),
            getGeneralTicketItem(`requestCredin.confirm.table.header.ticket`, recipient?.id, "string"),
            getGeneralTicketItem(
                "requestCredin.confirm.table.header.concept",
                i18nUtils.get(`transfers.concept.${concept}`),
                "string",
            ),
            getGeneralTicketItem("requestCredin.confirm.table.header.description", description || "", "string"),
            { key: "", value: "" },
        ];
    };

    closeModifyModal = () => {
        this.setState({ isModalDisplayed: false });
    };

    openModifyModal = () => {
        this.setState({ isModalDisplayed: true });
    };

    render = () => {
        const { isDesktop, summary, accounts, isSmallDesktop, dispatch } = this.props;
        const { fetchingDownload, fetching } = this.state;
        let accountName = null;
        if (summary) {
            const numberAccount = summary.account;

            if (accounts.length > 0) {
                const loggedUserAccount = accounts.find((account) => numberAccount.includes(account?.idProduct));
                accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
            }
        }

        const isPending = summary?.idTransactionStatus === "PENDING";
        const { isModalDisplayed } = this.state;
        const buttonsDetail = isPending
            ? [
                  {
                      label: "global.modify",
                      bsStyle: "outline",
                      onClick: this.openModifyModal,
                  },
              ]
            : [
                  {
                      label: "credin.confirm.goToRequestCredin",
                      bsStyle: "primary",
                      onClick: () => dispatch(push(`/requestCredin`)),
                  },
              ];

        return (
            <>
                {summary && (
                    <TransactionTicket
                        defaultConfiguration
                        handleClick={accountName ? () => this.exportPdf(accountName) : null}
                        notificationScope="forms"
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={this.centerContentMobile}
                        hideMobileMenu={!isDesktop}
                        summary={{ ...summary, useTransactionData: true }}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        generateSignFunction
                        buttonsDetail={buttonsDetail}
                        fetching={fetching}
                        cancelButton
                        summaryTitle="requestCredin.title"
                        isFetchingExport={fetchingDownload}>
                        <ModifyPendingTransactionModal
                            isDesktop={isDesktop}
                            isDisplayed={isModalDisplayed}
                            handleDismiss={this.closeModifyModal}
                            dispatch={dispatch}
                            idTransaction={summary?.idTransaction}
                        />
                        {Array.isArray(summary.recipients) &&
                            summary.recipients.map((recipient) => (
                                <CredinTransactionData
                                    summary={summary}
                                    isDesktop={isDesktop}
                                    recipient={recipient}
                                    accountName={accountName}
                                />
                            ))}
                        {!Array.isArray(summary.recipients) && (
                            <CredinTransactionData
                                summary={summary}
                                isDesktop={isDesktop}
                                creationDate
                                recipient={summary.recipients}
                                accountName={accountName}
                            />
                        )}
                    </TransactionTicket>
                )}
            </>
        );
    };
}

const mapStateToProps = (state) => ({
    recipients: debinSelectors.getRecipients(state),
    id: debinSelectors.getId(state),
    seller: debinSelectors.getDebinSeller(state),
    fetching: debinSelectors.getFetching(state),
    summary: debinSelectors.getSummary(state),
    loggedUser: sessionSelectors.getUser(state),
    accounts: accountsSelectors.getAccounts(state),
});

export default connect(mapStateToProps)(CredinPrepareConfirmation);
