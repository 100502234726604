import React, { Component } from "react";
import { string } from "prop-types";

import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";

class NoResults extends Component {
    static propTypes = {
        message: string.isRequired,
    };

    render() {
        const { message } = this.props;

        return (
            <div className="text-center no-more-data">
                <div className="illustration-wrapper">
                    <Image src="images/coloredIcons/mailbox-empty.svg" className="svg-big-icon" />
                </div>
                <p className="text-lead">
                    <I18n id={message} />
                </p>
            </div>
        );
    }
}

export default NoResults;
