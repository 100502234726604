import React, { Component } from "react";
import { bool, shape } from "prop-types";

import Col from "react-bootstrap/lib/Col";

import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Heading from "pages/_components/Heading";
import Hint from "pages/_components/hints/Hint";

const FORM_ID = "pay.paymentAFIP";

class PaymentAFIPDetailContent extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        paymentAFIP: shape({}).isRequired,
    };

    render() {
        const { isDesktop, paymentAFIP } = this.props;

        const {
            fiscalPeriod,
            dueDate,
            amount,
            conceptDesc,
            subConceptDesc,
            description,
            formNumber,
            advancePayment,
            taxpayerCUIT,
            contributorsCUIT,
            type,
        } = paymentAFIP;

        const getFormattedAmount = (amountToFormat) => {
            if (amountToFormat) {
                return (
                    <FormattedAmount currency={amountToFormat.currency} quantity={amountToFormat.quantity} notBold />
                );
            }

            return <span className="data-text">-</span>;
        };

        const getFormattedDate = (date) => {
            if (date) {
                return <FormattedDate date={date} dateFormat="dd/MM/yyyy" />;
            }

            return <span className="data-text">-</span>;
        };

        const className = `flex-grow align-items-center container-white ${
            isDesktop ? "with-margin with-padding pb-45" : "py-3"
        }`;
        const containerClassName = `transfer-data data-wrapper-flex ${isDesktop ? "" : "space-between"}`;

        const dataClassName = "data-desc small-font";

        if (isDesktop) {
            return (
                <>
                    <Container className={className} gridClassName="form-content">
                        <Col sm={12} md={4}>
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                dataClassName={dataClassName}
                                label={`${FORM_ID}.fiscalPeriod`}
                                data={<span className="data-text">{fiscalPeriod}</span>}
                            />
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                dataClassName={dataClassName}
                                label={`${FORM_ID}.concept`}
                                data={<span className="data-text">{conceptDesc}</span>}
                            />
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                dataClassName={dataClassName}
                                label={`${FORM_ID}.formNumber`}
                                data={<span className="data-text">{formNumber}</span>}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                dataClassName={dataClassName}
                                label={`${FORM_ID}.dueDate`}
                                data={getFormattedDate(dueDate)}
                            />
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                dataClassName={dataClassName}
                                label={`${FORM_ID}.subConcept`}
                                data={<span className="data-text">{subConceptDesc}</span>}
                            />
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                dataClassName={dataClassName}
                                label={`${FORM_ID}.advancePayment`}
                                data={<span className="data-text">{advancePayment}</span>}
                            />
                        </Col>
                        <Col sm={12} md={4}>
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                dataClassName={dataClassName}
                                label={`${FORM_ID}.amount`}
                                data={getFormattedAmount(amount)}
                            />
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                dataClassName={dataClassName}
                                label={`${FORM_ID}.description`}
                                data={<span className="data-text">{description}</span>}
                            />
                            <Heading.DataGroup
                                containerClassName={containerClassName}
                                dataClassName={dataClassName}
                                label={`${FORM_ID}.taxpayerCUIT`}
                                data={<span className="data-text">{contributorsCUIT || taxpayerCUIT}</span>}
                            />
                        </Col>
                    </Container>
                    {type === "PA" && (
                        <Container className="align-items-left flex-grow">
                            <Col className="col col-12" lg={12} xl={12} sm={12} md={12}>
                                <Hint
                                    idMessage="paymentsAFIP.details.disclaimer"
                                    styleImage={{
                                        marginTop: "0.5rem",
                                    }}
                                    classNameHintP="hint-font-size-small font-weight-normal"
                                    alwaysShowMessage
                                />
                            </Col>
                        </Container>
                    )}
                </>
            );
        }

        return (
            <>
                <Container className={className}>
                    <Col sm={12} md={4} lg={4} className="payment-VEP-mobile">
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            dataClassName={dataClassName}
                            label={`${FORM_ID}.fiscalPeriod`}
                            data={<span className="data-text">{fiscalPeriod}</span>}
                        />
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            dataClassName={dataClassName}
                            label={`${FORM_ID}.dueDate`}
                            data={getFormattedDate(dueDate)}
                        />
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            dataClassName={dataClassName}
                            label={`${FORM_ID}.amount`}
                            data={getFormattedAmount(amount)}
                        />
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            dataClassName={dataClassName}
                            label={`${FORM_ID}.concept`}
                            data={<span className="data-text">{conceptDesc}</span>}
                        />
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            dataClassName={dataClassName}
                            label={`${FORM_ID}.subConcept`}
                            data={<span className="data-text">{subConceptDesc}</span>}
                        />
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            dataClassName={dataClassName}
                            label={`${FORM_ID}.description`}
                            data={<span className="data-text">{description}</span>}
                        />
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            dataClassName={dataClassName}
                            label={`${FORM_ID}.formNumber`}
                            data={<span className="data-text">{formNumber}</span>}
                        />
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            dataClassName={dataClassName}
                            label={`${FORM_ID}.advancePayment`}
                            data={<span className="data-text">{advancePayment}</span>}
                        />
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            dataClassName={dataClassName}
                            label={`${FORM_ID}.taxpayerCUIT`}
                            data={<span className="data-text">{contributorsCUIT || taxpayerCUIT}</span>}
                        />
                    </Col>
                </Container>
                {type === "PA" && (
                    <Container className="align-items-left flex-grow VEP-hint-mobile">
                        <Col className="col col-12" lg={6} xl={6} sm={12} md={9}>
                            <Hint
                                idMessage="paymentsAFIP.details.disclaimer"
                                styleImage={{
                                    marginTop: "2.2rem",
                                }}
                                classNameHintP="hint-font-size-small font-weight-normal"
                                alwaysShowMessage
                            />
                        </Col>
                    </Container>
                )}
            </>
        );
    }
}

export default PaymentAFIPDetailContent;
