import React, { Component } from "react";
import { bool, func, shape } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { selectors as paymentsAFIPSelectors, actions as paymentsAFIPActions } from "reducers/paymentsAFIP";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Image from "pages/_components/Image";

import PayPaymentAFIPTransactionData from "pages/servicePayments/_components/PayPaymentAFIPTransactionData";

import * as i18nUtils from "util/i18n";

class PayPaymentAFIPConfirmation extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        summary: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: "-0.5rem 2rem" }}>{i18nUtils.get("pay.paymentsAFIP.confirmation.label")}</h1>
        </div>
    );

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push(`/servicePayments`));
    };

    handleExport = () => {
        const { dispatch, summary } = this.props;
        dispatch(paymentsAFIPActions.downloadPay(summary));
    };

    render() {
        const { summary, isDesktop, dispatch, isSmallDesktop } = this.props;

        return (
            <>
                {summary && (
                    <>
                        <div className="admin-detail-head px-0" style={isDesktop ? { marginTop: "2rem" } : {}}>
                            <Head
                                uniqueDownload
                                onBack={!isDesktop ? this.handleBack : null}
                                handleClick={this.handleExport}
                                handleClickMessage="global.download"
                                headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                                isFetchingExport={false}
                                centerElement={isDesktop ? undefined : this.centerContentMobile}
                                hasInlineButtons
                                hideMobileMenu={!isDesktop}
                                downloadImageWhite={!isDesktop}
                            />
                        </div>

                        <Notification scopeToShow="paymentAFIPConfirmation" />

                        <MainContainer>
                            <Container className="flex-grow align-items-center">
                                <Col sm={12} md={9} lg={isSmallDesktop ? 6 : 4} className="cmf-container-white">
                                    {summary?.idTransactionStatus === "FINISHED" && (
                                        <>
                                            <div className="admin-content-center" style={{ margin: "2rem 0" }}>
                                                <Image
                                                    src="images/successOnboard.svg"
                                                    styles={{
                                                        width: isDesktop ? "102px" : "64px",
                                                        height: isDesktop ? "102px" : "64px",
                                                    }}
                                                />
                                            </div>
                                            <div className="admin-content-center">
                                                <I18n id="accounts.new.account.transaction.success.label" />
                                            </div>
                                            <div className="admin-content-center">
                                                <I18n
                                                    component="h3"
                                                    id="pay.paymentsAFIP.confirmation.label"
                                                    componentProps={{
                                                        className: `cmf-title-center font-size-20-px`,
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                    {summary?.idTransactionStatus === "PENDING" && (
                                        <>
                                            <div className="admin-content-center" style={{ margin: "2rem 0" }}>
                                                <Image
                                                    src="images/transfer-PENDING.svg"
                                                    styles={{
                                                        width: isDesktop ? "102px" : "64px",
                                                        height: isDesktop ? "102px" : "64px",
                                                    }}
                                                />
                                            </div>
                                            <div className="admin-content-center">
                                                <I18n id="pendingTransaction.info.label" />
                                            </div>
                                            <div className="admin-content-center">
                                                <I18n
                                                    component="h3"
                                                    id="pay.paymentAFIP.confirmation.filename"
                                                    componentProps={{
                                                        className: `cmf-title-center font-size-20-px`,
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <PayPaymentAFIPTransactionData summary={summary} />
                                </Col>
                            </Container>
                            <Container className="flex-grow align-items-center" gridClassName="form-content">
                                <Col sm={12} md={9} lg={isSmallDesktop ? 6 : 4} className="align-items-center">
                                    <Col
                                        style={
                                            isDesktop
                                                ? {
                                                      display: "flex",
                                                      alignItems: "center",
                                                      marginTop: "2rem",
                                                  }
                                                : {}
                                        }
                                        sm={12}
                                        className="col-no-pad-mobile">
                                        <Button
                                            type="button"
                                            bsStyle="outline"
                                            label="pay.paymentAFIP.confirmation.goDesktop.button"
                                            onClick={() => dispatch(push(`/desktop`))}
                                        />
                                        <Button
                                            type="button"
                                            bsStyle="primary"
                                            label="pay.paymentAFIP.confirmation.goToServicePayments.button"
                                            onClick={() => dispatch(push(`/servicePayments`))}
                                        />
                                    </Col>
                                </Col>
                            </Container>
                        </MainContainer>
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: paymentsAFIPSelectors.getSummary(state),
});

export default connect(mapStateToProps)(PayPaymentAFIPConfirmation);
