import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter, Link } from "react-router-dom";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { bool, string, func } from "prop-types";
import classNames from "classnames";

import { selectors as settingsSelectors, actions as settingsActions } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";

import MaskedTextField from "pages/_components/fields/MaskedTextField";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";

import * as i18n from "util/i18n";
import * as maskUtils from "util/mask";

const FORM_ID = "settings.changeEmail";
class ChangeEmailConfirmation extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        userFullName: string.isRequired,
        dispatch: func.isRequired,
        userEmail: string.isRequired,
    };

    confirmCodeForm = () => {
        const { isDesktop, isSubmitting, userEmail } = this.props;
        // const lastGroupUserEmail = userEmail.substr(userEmail.length - 4);
        const firstEmailLetter = userEmail.charAt(0);
        const textAfterLastLetter = userEmail.substring(userEmail.indexOf("@") - 1, userEmail.length);

        return (
            <Form className="above-the-fold">
                <section
                    className={`align-items-center flex-grow container-white ${
                        isDesktop ? "with-margin container--layout" : ""
                    }`}>
                    <Grid className="form-content">
                        <Row className="justify-content-center" style={{ paddingBottom: "3rem" }}>
                            <Col sm={12} md={12} lg={12} xl={12}>
                                <div className="admin-content-center change-info-confirmation-explanation">
                                    <h2>{`${i18n.get(
                                        "settings.changeEmail.code.hasBeenSent",
                                    )} ${firstEmailLetter}••••${textAfterLastLetter}`}</h2>
                                    <p className="change-info-confirm-text">
                                        <I18n
                                            id="settings.changeEmail.code.hasBeenSent.info"
                                            component="span"
                                            componentProps={{ className: "settings-text" }}
                                        />
                                        <I18n
                                            id="settings.changeEmail.code.didNotReceive"
                                            component="span"
                                            componentProps={{ className: "settings-text" }}
                                        />
                                        <I18n
                                            id="settings.changePhone.itWasNotReceived"
                                            component="span"
                                            componentProps={{ className: "settings-text to-uppercase" }}
                                        />
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row
                            className={classNames("justify-content-center pt-5 ", {
                                "pb-6": !isDesktop,
                            })}>
                            <Col sm={12} md={6} lg={4} xl={4}>
                                <Field
                                    idForm={FORM_ID}
                                    autoComplete="off"
                                    component={MaskedTextField}
                                    hidePlaceholder
                                    mask={maskUtils.invitationCodeMask()}
                                    maxLength={50}
                                    name="mailCode"
                                    autoFocus={isDesktop}
                                />
                            </Col>
                        </Row>

                        <Row className={classNames("justify-content-center", { "pb-8": isDesktop })}>
                            <Link to="/settings/changeEmail" style={{ textDecoration: "none" }}>
                                <Button
                                    className="didnot-receive-code-link-button"
                                    bsStyle="link"
                                    label="settings.changeEmail.tryAgain"
                                    type="button"
                                    style={{ padding: "0 0", marginLeft: 0, marginRight: 0 }}
                                />
                            </Link>
                        </Row>
                    </Grid>
                </section>

                <section className="container--layout align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={6} lg={4} xl={4}>
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </Form>
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/changeEmail/"));
    };

    render() {
        const { userFullName, isDesktop } = this.props;

        return (
            <>
                <Notification scopeToShow="changeEmail" />
                <div className="admin-detail-head px-0">
                    <div className="view-title">
                        {isDesktop && <Head onBack={this.handleBack} replace={{ USER_NAME: userFullName }} />}
                        <Head
                            title="settings.changeEmailConfirmation"
                            titleClassName={isDesktop ? "" : "title-account-header-multiline-background-blue"}
                            centerContentClassName={!isDesktop && "mx-5"}
                            onBack={isDesktop ? null : this.handleBack}
                            replace={{ USER_NAME: userFullName }}
                            headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        />
                    </div>
                </div>
                <MainContainer className={classNames({ "container-white": !isDesktop })}>
                    {this.confirmCodeForm()}
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    userEmail: settingsSelectors.getMailToChange(state) || "",
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            mailCode: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                mailCode: Yup.string().required(i18n.get(`${FORM_ID}.code.required`)),
            }),
        handleSubmit: ({ mailCode }, formikBag) => {
            formikBag.props.dispatch(settingsActions.updateMail(mailCode, formikBag));
        },
    }),
)(ChangeEmailConfirmation);
