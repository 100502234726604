/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { selectors, actions } from "reducers/checks";
import { connect } from "react-redux";
import { shape, func, bool, string } from "prop-types";
import ContextMenu from "pages/_components/ContextMenu";
import { emittedEcheqsStatusActions, echeqShape } from "util/checks";
import DrawerModal from "pages/_components/drawer/DrawerModal";
import specialOptions, { receivedEcheqAction } from "pages/checks/Echeqs/EcheqActions/EcheqsActionMaker";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";

const FORM_ID = "echeq";

class EmittedContextMenu extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        echeq: shape(echeqShape).isRequired,
        isDesktop: bool.isRequired,
        environmentCuit: string.isRequired,
        detailActions: bool,
        serverSearchDate: string.isRequired,
        isSmallDesktop: bool,
    };

    state = {
        showDrawer: false,
        contentDrawer: "",
    };

    static defaultProps = {
        detailActions: false,
        isSmallDesktop: false,
    };

    echeqActions = (status) => {
        const actionsEcheqs = emittedEcheqsStatusActions;
        const { dispatch, echeq, environmentCuit, isDesktop, serverSearchDate } = this.props;

        let contextMenuActions = [];

        if (!isDesktop) {
            contextMenuActions = [
                {
                    label: "echeq.request.moreInfo.download",
                    onClick: () => {
                        dispatch(actions.downloadEcheqMobileInfo("pdf", echeq));
                    },
                },
            ];
        }

        contextMenuActions = [
            ...contextMenuActions,
            ...specialOptions(echeq, serverSearchDate, environmentCuit, (action, echeqInfo) =>
                receivedEcheqAction(
                    action,
                    echeqInfo,
                    dispatch,
                    (newState) => this.setState({ ...newState }),
                    isDesktop,
                ),
            ),
        ];
        if (actionsEcheqs[status]) {
            actionsEcheqs[status].forEach((action) => {
                contextMenuActions = [
                    ...contextMenuActions,
                    {
                        label: `${FORM_ID}.${action.toLowerCase()}.echeq`,
                        onClick: () => {
                            receivedEcheqAction(
                                action,
                                echeq,
                                dispatch,
                                (newState) => this.setState({ ...newState }),
                                isDesktop,
                            );
                        },
                    },
                ];
            });
        }

        return contextMenuActions;
    };

    render() {
        const { echeq, isDesktop, detailActions, isSmallDesktop } = this.props;
        const { showDrawer, contentDrawer, title } = this.state;
        const { innerWidth: width } = window;

        const itemsContextMenu = this.echeqActions(echeq.estado);
        const maxButtonsActions = width > 859 && width < 1137 ? 2 : 3;
        let filteredEcheqActions;
        let depositOrCustodyButton;
        if (itemsContextMenu.length > 0) {
            depositOrCustodyButton = itemsContextMenu.find(
                (item) => item.label === "echeq.deposit.echeq" || item.label === "echeq.custody.echeq",
            );
            filteredEcheqActions = itemsContextMenu.filter((item) => item.label !== depositOrCustodyButton?.label);
        }

        return (
            <>
                {detailActions &&
                    isDesktop &&
                    !isSmallDesktop &&
                    itemsContextMenu.length <= maxButtonsActions &&
                    itemsContextMenu?.map((item) => (
                        <Button
                            className="btn-outline cmf-button-width"
                            block
                            label={item.label}
                            onClick={item.onClick}
                        />
                    ))}
                {detailActions && isDesktop && (itemsContextMenu.length > maxButtonsActions || isSmallDesktop) && (
                    <>
                        {depositOrCustodyButton && (
                            <Button
                                className="btn-outline cmf-button-width"
                                block
                                label={depositOrCustodyButton.label}
                                onClick={depositOrCustodyButton.onClick}
                            />
                        )}
                        {filteredEcheqActions?.map((echeqAction) => (
                            <Button
                                className="btn-outline cmf-button-width"
                                block
                                label={echeqAction.label}
                                onClick={echeqAction.onClick}
                            />
                        ))}
                    </>
                )}
                {(!detailActions || !isDesktop) && itemsContextMenu.length > 0 && (
                    <ContextMenu
                        styleContext={{ justifyContent: "flex-end", marginRight: 0 }}
                        isDesktop={isDesktop}
                        items={itemsContextMenu}
                    />
                )}
                {showDrawer && (
                    <DrawerModal
                        content={contentDrawer}
                        width={isDesktop ? "600px" : "100vw"}
                        title={title}
                        headerContent={
                            !isDesktop && (
                                <div className="toolbar-item view-title align-self-center mx-0">
                                    <I18n
                                        id={title}
                                        component="h1"
                                        componentProps={{
                                            className:
                                                "title-content header-mobile-title-background-blue align-self-center mb-0",
                                        }}
                                    />
                                </div>
                            )
                        }
                        isDesktop={isDesktop}
                        navbarHeaderClassName="mt-3"
                        showDrawerRemote={showDrawer}
                        closeRemotely={() => this.setState({ showDrawer: false, contentDrawer: "" })}
                        drawerClassName="echeq__drawer"
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    echeqsList: selectors.getEcheqsList(state),
    environmentCuit: selectors.getEnvironmentCuit(state),
    serverSearchDate: selectors.getServerSearchDate(state),
});

export default connect(mapStateToProps)(EmittedContextMenu);
