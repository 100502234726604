import { useCallback, useEffect, useState } from "react";
import * as configUtils from "util/config";

function useFundConfigParam(selectedFundFeature) {
    const [fundConfigParam, setFundConfigParam] = useState({});

    const updateFundConfigParam = useCallback(() => {
        const fundsParameters = JSON.parse(configUtils.get("frontend.investment.funds.parameters"));
        if (selectedFundFeature) {
            const fundFeature = fundsParameters[`fondo${selectedFundFeature.numero}`];
            setFundConfigParam(fundFeature);
        } else {
            setFundConfigParam({});
        }
    }, [selectedFundFeature]);
    useEffect(() => {
        updateFundConfigParam();
    }, [updateFundConfigParam, selectedFundFeature]);

    return fundConfigParam;
}

export default useFundConfigParam;
