import React, { Component } from "react";
import { bool, shape } from "prop-types";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Col from "react-bootstrap/lib/Col";
import classNames from "classnames";

const FORM_ID = "userInfo.preferences.notificationsConfiguration";

class NotificationsConfigruationsOptionsHint extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        options: shape({}).isRequired,
        communicationType: shape({}).isRequired,
    };

    render() {
        const { isDesktop, options, communicationType } = this.props;
        if (communicationType.includes("ECHEQ")) {
            return (
                <Container>
                    <Col md={9} className={classNames({ "mr-4": !isDesktop })}>
                        <div
                            className={classNames("form-section-title admin-content-center", {
                                "mt-8 mb-6": isDesktop,
                                "mt-4 mb-5": !isDesktop,
                            })}>
                            <I18n component="h2" id={`${FORM_ID}.hint.title`} />
                        </div>
                        <div className="emision-modes-drawer p-0">
                            <ul className="ul-dash-style-type mb-6">
                                {Object.entries(options).map(([option]) => (
                                    <I18n
                                        component="li"
                                        id={`${FORM_ID}.${option.toLowerCase()}.hint`}
                                        componentProps={{
                                            className: "mb-3 color-black light-font",
                                        }}
                                    />
                                ))}
                            </ul>
                        </div>
                    </Col>
                </Container>
            );
        }
        return (
            <Container>
                <Col md={9} className={classNames({ "mr-4": !isDesktop })}>
                    <div
                        className={classNames("form-section-title admin-content-center", {
                            "mt-8 mb-6": isDesktop,
                            "mt-4 mb-5": !isDesktop,
                        })}>
                        <I18n component="h2" id={`${FORM_ID}.hint.title`} />
                    </div>
                    <div className="emision-modes-drawer p-0">
                        <ul className="ul-dash-style-type mb-6">
                            {Object.entries(options).map(([option]) => (
                                <I18n
                                    component="li"
                                    id={`${FORM_ID}.${option.toLowerCase()}.hint.CEDIP`}
                                    componentProps={{
                                        className: "mb-3 color-black light-font",
                                    }}
                                />
                            ))}
                        </ul>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default NotificationsConfigruationsOptionsHint;
