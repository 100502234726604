import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import { func, bool, string } from "prop-types";
import { goBack } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import { Field, withFormik, Form } from "formik";
import { compose } from "redux";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import { actions, selectors } from "reducers/frequentDestination";
import * as i18n from "util/i18n";
import Yup from "yup";
import Notification from "pages/_components/Notification";
import classNames from "classnames";
import * as config from "util/config";

const FORM_ID = "frequentDestination.create";
const MIN_REQUIRED = "6";
const MAX_REQUIRED = "22";

class CreateFrequentDestination extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        setFieldValue: func.isRequired,
        alias: string.isRequired,
    };

    static defaultProps = {};

    state = {};

    componentDidMount() {
        const {
            setFieldValue,
            frequentDestination: { cbu, name, recipientEmail },
            alias,
        } = this.props;
        setFieldValue("cbuAlias", alias || cbu);
        setFieldValue("email", recipientEmail);
        setFieldValue("name", name);
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    centerContentMobile = () => (
        <div className="d-flex justify-content-center">
            <h1 className="m-0">{i18n.get("frequentDestination.create.newFrequentDestination")}</h1>
        </div>
    );

    renderHeader = () => {
        const { isDesktop } = this.props;
        return (
            <div className="admin-detail-head px-0">
                <Head
                    onBack={this.handleBack}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                    centerElement={isDesktop ? undefined : this.centerContentMobile}
                />
                {isDesktop && (
                    <div className="view-title">
                        <h1>{i18n.get("frequentDestination.create.newFrequentDestination")}</h1>
                    </div>
                )}
            </div>
        );
    };

    renderForm = () => {
        const { isDesktop } = this.props;
        return (
            <Fragment>
                <Container
                    className={classNames("flex-grow align-items-center container-white with-margin", {
                        "pt-4 pb-5 px-4 mt-4": isDesktop,
                    })}
                    gridClassName="form-content">
                    <Col sm={12} md={6} lg={5}>
                        <Col sm={12} className="px-0">
                            <Field idForm={FORM_ID} component={TextField} name="cbuAlias" type="text" isFocused />
                        </Col>
                    </Col>
                </Container>
                <Container
                    className={classNames("flex-grow align-items-center container-white", {
                        "pt-4 pb-5 px-4": isDesktop,
                    })}
                    gridClassName="form-content">
                    <Col sm={12} md={6} lg={5}>
                        <Col sm={12} className="px-0">
                            <Field component={TextField} name="email" type="text" isFocused idForm={FORM_ID} />
                        </Col>
                        <Col sm={12} className="px-0">
                            <Field idForm={FORM_ID} component={TextField} name="name" type="text" isFocused />
                        </Col>
                    </Col>
                </Container>
            </Fragment>
        );
    };

    render() {
        const { fetching, isDesktop } = this.props;

        const button = (
            <Col sm={12} md={6} lg={5} className={classNames({ "p-4 mt-7": isDesktop })}>
                <Button bsStyle="primary" label="global.continue" type="submit" loading={fetching} />
            </Col>
        );

        return (
            <>
                <Notification scopeToShow="FrequentDestinationsCreate" />
                {this.renderHeader()}
                <Form>
                    <MainContainer>
                        <div className="above-the-fold">
                            {this.renderForm()}
                            {isDesktop ? (
                                <Container className="flex-grow  align-items-center">{button}</Container>
                            ) : (
                                <div
                                    style={{
                                        height: "30vh",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    {button}
                                </div>
                            )}
                        </div>
                    </MainContainer>
                </Form>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    frequentDestination: selectors.getFrequentDestination(state),
    alias: selectors.getAlias(state),
    fetching: selectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            cbuAlias: "",
            email: "",
            name: "",
        }),
        validationSchema: () => {
            const isValidCbu = (value) => {
                const reg = /^\d+$/;
                return reg.test(value) && value.length === parseInt(MAX_REQUIRED, 10);
            };

            const isValidAlias = (value) => {
                /* Regex that accept letter from a to z including capital letters, (.), (-), numbers
                and excluding (Ñ) and (ñ) */
                const reg = /^(?:(?![ñÑ])[a-zA-Z.\-\d])+$/;
                return reg.test(value);
            };

            const validateCbuOrAlias = (value) => isValidCbu(value) || isValidAlias(value);

            return Yup.object().shape({
                cbuAlias: Yup.string()
                    .min(parseInt(MIN_REQUIRED, 10), `${i18n.get("forms.validation.min.required")} ${MIN_REQUIRED}`)
                    .max(parseInt(MAX_REQUIRED, 10), `${i18n.get("forms.validation.max.required")} ${MAX_REQUIRED}`)
                    .required(i18n.get(`${FORM_ID}.field.error.required`))
                    .test("format", i18n.get(`${FORM_ID}.field.error.invalid`), validateCbuOrAlias),

                email: Yup.string().email(i18n.get(`${FORM_ID}.field.error.invalid`)),
                name: Yup.string()
                    .required(i18n.get(`${FORM_ID}.field.error.required`))
                    .max(
                        config.getInteger("frequentDestination.reference.maxLength", 100),
                        i18n.get(`frequentDestination.reference.length.error`),
                    ),
            });
        },
        handleSubmit: (data, formikBag) => {
            const { credentialGroups, dispatch } = formikBag.props;
            const params = {
                ...data,
                cbu: data.cbuAlias,
                email: data.email,
                name: data.name,
            };
            dispatch(actions.frequentDestinationCreatePreview(params, formikBag, credentialGroups));
        },
    }),
)(CreateFrequentDestination);
