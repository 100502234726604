import React from "react";
import Logo from "pages/login/_components/Logo";
import RegionSelectionLink from "pages/login/_components/RegionSelectionLink";
import FooterDesktop from "pages/_components/FooterDesktop";
import { Col, Grid, Row } from "react-bootstrap";
import { bool, element, string } from "prop-types";
import Notification from "pages/_components/Notification";

export default function GridLayoutExternal({ children, isDesktop, showLogoState, pathname, scopeToShow }) {
    if (!isDesktop) {
        return (
            <>
                {scopeToShow ? <Notification scopeToShow={scopeToShow} /> : undefined}
                {children}
            </>
        );
    }

    return (
        <div className="w-100 d-flex justify-content-end">
            {scopeToShow ? <Notification scopeToShow={scopeToShow} /> : undefined}
            <Grid className="login-desktop-wrapper panel">
                <Row className="justify-content-center login-desktop-wrapper-info">
                    <Col sm={10} lg={10} className="d-flex flex-column col col-12 content-center">
                        {showLogoState && (
                            <div className="logo-login">
                                <Logo logoColor className="svg-image logo logo-mobile-size" />
                            </div>
                        )}
                        {children}
                    </Col>
                    <FooterDesktop
                        isDesktop={isDesktop}
                        moreOptions={[<RegionSelectionLink disabled={pathname === "/regionSelection"} />]}
                    />
                </Row>
            </Grid>
        </div>
    );
}

GridLayoutExternal.propTypes = {
    children: element.isRequired,
    isDesktop: bool.isRequired,
    showLogoState: bool.isRequired,
    pathname: string.isRequired,
    scopeToShow: string,
};

GridLayoutExternal.defaultProps = {
    scopeToShow: null,
};
