/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import Head from "pages/_components/Head";
import * as i18n from "util/i18n";
import { bool, arrayOf, shape, func, string } from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import Container from "pages/_components/Container";
import PageLoading from "pages/_components/PageLoading";
import DebinAccountItem from "pages/charges/_components/DebinAccountItem";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Hint from "pages/_components/hints/Hint";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import { selectors as accountsSelectors, actions as accountsActions } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as statusSelectors } from "reducers/status";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import { push } from "react-router-redux";

class DebinAddAccounts extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        accounts: arrayOf(shape({})).isRequired,
        debinAccounts: arrayOf(shape({})).isRequired,
        accountToChange: shape({}).isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        loggedUser: shape({}).isRequired,
        fetchingAccounts: bool.isRequired,
        lastPathname: string.isRequired,
    };

    state = {
        showMessage: false,
        showHintInfoMb: false,
    };

    componentDidMount() {
        const { dispatch, loggedUser } = this.props;
        dispatch(accountsActions.listAccounts(loggedUser.id));
        dispatch(debinActions.debinPreActivity());
    }

    componentDidUpdate(prevProps) {
        const { dispatch, accountToChange, fetching } = this.props;
        if (!fetching && prevProps?.accountToChange !== accountToChange) {
            dispatch(debinActions.debinPreActivity());
        }
    }

    handleBack = () => {
        const { dispatch, lastPathname } = this.props;
        dispatch(push(lastPathname));
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0 align-center">{i18n.get("debin.addAccounts.title")}</h1>
        </div>
    );

    renderButtonInfo = () => {
        const { showHintInfoMb } = this.state;
        return (
            <button
                type="button"
                className="btn btn-hint"
                onClick={() => {
                    this.setState((prev) => ({ ...prev, showHintInfoMb: !prev.showHintInfoMb }));
                    this.changeMessageDisplay();
                }}>
                {!showHintInfoMb ? (
                    <Image src="images/infoWhite.svg" styles={{ height: "24px", width: "24px" }} />
                ) : (
                    <Image src="images/infoWhiteFull.svg" styles={{ height: "24px", width: "24px" }} />
                )}
            </button>
        );
    };

    changeMessageDisplay = () => {
        const { showMessage } = this.state;
        this.setState({ showMessage: !showMessage });
    };

    render() {
        const { isDesktop, debinAccounts, accounts, fetching, fetchingAccounts, dispatch } = this.props;
        const { showMessage } = this.state;
        const { showHintInfoMb } = this.state;

        return (
            <>
                {!fetching && !fetchingAccounts && (
                    <>
                        <Notification scopeToShow="debin" /> <Notification scopeToShow="debinAccounts" />
                    </>
                )}

                <div
                    className={classNames("admin-detail-head px-0", {
                        "mb-0": !isDesktop,
                    })}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={classNames({
                            "blue-main-header-mobile": !isDesktop,
                        })}
                        centerElement={!isDesktop && this.centerContentMobile}
                        rightContent={!isDesktop && this.renderButtonInfo}
                        showHintInfoMb={showHintInfoMb}
                    />
                    {isDesktop ? (
                        <div className="view-title d-flex">
                            <I18n
                                id="debin.addAccounts.title"
                                component="h1"
                                componentProps={{
                                    className: "my-35 ml-0 mr-2",
                                }}
                            />
                            <Hint
                                idMessage="debin.addAccounts.title.help"
                                classNameMessage="align-self-center mb-1 max-width-20rem"
                                classNameImage="d-flex"
                                classNameHintP="font-weight-normal"
                            />
                        </div>
                    ) : (
                        <div className={`mx-3 ${showMessage ? "opacity-1" : "opacity-0"}`}>
                            <I18n
                                id="debin.addAccounts.title.help"
                                component="p"
                                componentProps={{
                                    className: "hint-text mt-0 mb-3 mx-2",
                                }}
                            />
                        </div>
                    )}
                </div>
                <PageLoading loading={fetching || fetchingAccounts}>
                    <MainContainer>
                        <div className="above-the-fold">
                            <Container
                                className="above-the-fold flex-grow align-items-center debin"
                                gridClassName="form-content">
                                {accounts.map((account) => (
                                    <DebinAccountItem
                                        isDesktop={isDesktop}
                                        dispatch={dispatch}
                                        {...account}
                                        debinAccounts={debinAccounts}
                                    />
                                ))}
                            </Container>
                        </div>
                    </MainContainer>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    debinAccounts: debinSelectors.getAccounts(state),
    fetching: debinSelectors.getFetching(state),
    accounts: accountsSelectors.getAccounts(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
    loggedUser: sessionSelectors.getUser(state),
    accountToChange: debinSelectors.getAccountToChange(state),
    lastPathname: statusSelectors.getPathname(state),
});

export default connect(mapStateToProps)(DebinAddAccounts);
