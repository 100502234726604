import React, { useEffect } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Col from "react-bootstrap/lib/Col";
import Head from "pages/_components/Head";
import PropTypes from "prop-types";
import { Form, withFormik } from "formik";
import classNames from "classnames";
import moment from "moment";
import { connect } from "react-redux";
import { compose } from "redux";

import * as dateUtils from "util/date";
import * as schedulerUtils from "util/scheduler";
import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import CommonFrequencySubOption from "pages/forms/_components/_scheduler/CommonFrequencySubOption";

function FundSchedulerModal({
    handleCloseBottomSheetClick,
    isDesktop,
    isDisplayed,
    idForm,
    errors,
    values: { lapse, frequency },
    onScheduleClick,
    setErrors,
    touched,
    setTouched,
    values,
    setFieldValue,
    nonWorkingDays,
    STARTS_ON_DATE,
}) {
    useEffect(() => {
        setFieldValue("frequency", "FUTURE");
        // eslint-disable-next-line
    }, []);

    const validateFields = () => {
        let res = true;
        // clean errors
        setErrors({});

        const moreThan2daysaWeek = dateUtils.moreThan2daysSelected(values.days);

        // validate if it's week and days is just one day and freqValue = 1
        if (values.customFreq === schedulerUtils.WEEK) {
            if (moreThan2daysaWeek && values.customFreqValue !== "1") {
                setTouched({ ...touched, days: true });
                setErrors({
                    ...errors,
                    days: { value: i18nUtils.get(`scheduler.multipleDaysSelectedValidation`) },
                });
                res = false;
            }
        }
        // starts on must be after than today
        if (moment(values.startsOn).isBefore(moment())) {
            setTouched({ ...touched, startsOn: true });
            setErrors({
                ...errors,
                startsOn: i18nUtils.get(`scheduler.startsOnBeforeToday`),
            });
            res = false;
        }

        return res;
    };

    const scheduleClickHandler = () => {
        if (validateFields()) {
            onScheduleClick(values);
        }
    };

    return (
        <Modal
            className="transfer-template-modal scheduler-founds"
            aria-labelledby="modalTitleID"
            aria-modal="true"
            onHide={handleCloseBottomSheetClick}
            bsClass={classNames({ "h-100": !isDesktop }, "no-radius modal")}
            show={isDisplayed}>
            {isDesktop ? (
                <Modal.Header closeButton className="no-title min-height-3" />
            ) : (
                <Head
                    onClose={handleCloseBottomSheetClick}
                    hideMobileMenu
                    closeImageWhite
                    headerClassName="blue-main-header-mobile"
                    title={`${idForm}.head.label`}
                    titleClassName="title-content text-white header-mobile-title-background-blue mb-0"
                    accessibilityTextId={`${idForm}.head.label`}
                    navbarClassName="py-2"
                />
            )}

            <Modal.Body className="pb-2 modal-md show">
                <Form className="h-100">
                    <Container className="h-100" gridClassName="h-100" rowClassName="h-100">
                        <div className="above-the-fold scheduler">
                            {isDesktop ? (
                                <Col md={12} className={classNames("text-center", { "px-md-5": isDesktop })}>
                                    <I18n id={idForm} component="h3" />
                                </Col>
                            ) : (
                                undefined
                            )}
                            <Container className="container--layout align-items-center">
                                <CommonFrequencySubOption
                                    lapse={lapse}
                                    frequency={frequency}
                                    startsOn={STARTS_ON_DATE}
                                    isDesktop={isDesktop}
                                    FORM_ID={idForm}
                                    nonWorkingDays={nonWorkingDays}
                                    disabledWeekends
                                />
                            </Container>
                            <Col
                                md={12}
                                className={classNames("justify-content-center d-flex my-4", {
                                    "align-items-end": !isDesktop,
                                })}>
                                <Button
                                    type="button"
                                    bsStyle="primary"
                                    label="scheduler.schedule"
                                    className="px-7"
                                    onClick={scheduleClickHandler}
                                />
                            </Col>
                        </div>
                    </Container>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

FundSchedulerModal.propTypes = {
    handleCloseBottomSheetClick: PropTypes.func.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    isDisplayed: PropTypes.bool.isRequired,
    idForm: PropTypes.string.isRequired,
    errors: PropTypes.shape({}),
    values: PropTypes.shape({
        frequency: PropTypes.string,
        lapse: PropTypes.shape({
            day: PropTypes.string,
        }),
        days: PropTypes.node,
        customFreq: PropTypes.string,
        customFreqValue: PropTypes.string,
        startsOn: PropTypes.node,
    }),
    onScheduleClick: PropTypes.func,
    setErrors: PropTypes.func,
    touched: PropTypes.shape({}),
    setTouched: PropTypes.func,
    setFieldValue: PropTypes.func.isRequired,
    nonWorkingDays: PropTypes.arrayOf(PropTypes.string),
    STARTS_ON_DATE: PropTypes.string.isRequired,
};

FundSchedulerModal.defaultProps = {
    errors: {},
    values: {},
    onScheduleClick: () => {},
    setErrors: () => {},
    touched: {},
    setTouched: () => {},
    nonWorkingDays: [],
};

const mapStateToProps = () => {};

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { value, STARTS_ON_DATE } = props;
            const hasDate = value?.valueDate;
            const newProgram = {
                frequency: value?.selectedOption ? value.selectedOption : schedulerUtils.TODAY,
                startsOn:
                    value && value.selectedOption === schedulerUtils.ONCE && hasDate
                        ? moment(value.valueDate).toDate()
                        : STARTS_ON_DATE.startOf("day").toDate(),
                lapse: {
                    date: STARTS_ON_DATE.startOf("day").toDate(),
                    lapse: schedulerUtils.NEVER,
                },
                customFreq: schedulerUtils.DAY,
                customFreqValue: "1",
                days: dateUtils.getDayFromDate(STARTS_ON_DATE.startOf("day").toDate()),
                occurrenceType: "day", // day(every month on x day ) or occurrence(every month on the "tirth" "tuesday")
            };

            if (!value || !value.program) {
                return newProgram;
            }

            const startsOn =
                typeof value.program.startsOn === "string"
                    ? moment(value.program.startsOn).toDate()
                    : value.program.startsOn;
            return {
                ...value.program,
                startsOn,
                frequency: value.program.isCustom ? schedulerUtils.CUSTOM : value.program.frequency,
                lapse: {
                    lapse: value.program.lapse,
                    date: value.program.date,
                    number: value.program.number,
                },
                customFreq: value.program.frequency,
                customFreqValue: value.program.frequencyValue,
                occurrenceType: value.program.occurrence ? "occurrence" : "day",
            };
        },
    }),
)(FundSchedulerModal);
