/* eslint-disable import/no-unresolved */
import { call, put, takeLatest, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import * as communicationMiddleware from "middleware/communication";
import * as fileMiddleware from "middleware/file";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import { types, actions, selectors } from "reducers/communication";
import { actions as communicationsActions } from "reducers/communications";
import { download } from "util/download";

const sagas = [
    takeLatest([types.DETAIL_REQUEST], detailRequest),
    takeLatest([types.DELETE_REQUEST], deleteRequest),
    takeLatest([types.SEND_REQUEST], sendRequest),
    takeLatest([types.DOWNLOAD_ATTACHMENT_REQUEST], downloadAttachment),
    takeLatest([types.MARK_AS_READ_REQUEST], markAsReadRequest),
    takeLatest([types.MARK_AS_UNREAD_REQUEST], markAsUnReadRequest),
    takeLatest([types.REPLY_REQUEST], replyRequest),
    takeLatest([types.SELECT_ATTACHMENT], selectAttachment),
];

export default sagas;

function* detailRequest({ idCommunication, index }) {
    const response = yield call(communicationMiddleware.detailRequest, { idCommunication });

    if (response.type === "W") {
        yield put(actions.detailFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        const { communicationThread, attachments } = response.data.data;
        yield put(actions.detailSuccess(communicationThread, attachments, index));
    }
}

function* deleteRequest({ idCommunication, index }) {
    const response = yield call(communicationMiddleware.deleteRequest, { idCommunication });

    if (response.type === "W") {
        yield put(actions.deleteFailure());
        yield put(
            notificationActions.showNotification(i18n.get("communications.deleteMessage.error"), "error", [
                "communications",
            ]),
        );
    } else {
        yield put(actions.deleteSuccess());
        yield put(communicationsActions.removeFromTray(index));
        yield put(
            notificationActions.showNotification(i18n.get("communications.deleteMessage.success"), "success", [
                "communications",
            ]),
        );
    }
}

function* sendRequest({ idCommunicationTray, subject, body, files, isDesktop }) {
    try {
        const idFileList = yield uploadFiles(files, "communications.send");

        const errorMessage = idFileList.NO_FIELD;

        let response;

        if (!errorMessage) {
            response = yield call(communicationMiddleware.sendRequest, idCommunicationTray, subject, body, idFileList);
        }

        if (errorMessage || response.type === "W") {
            yield put(actions.sendFailure());
            yield put(
                notificationActions.showNotification(
                    errorMessage || i18n.get("communications.compose.send.error"),
                    "error",
                    ["communications"],
                ),
            );
        } else {
            yield put(actions.sendSuccess());
            if (response.type === "W") {
                yield put(actions.sendFailure);
                yield put(
                    notificationActions.showNotification(i18n.get("communications.compose.send.error"), "error", [
                        "communications",
                    ]),
                );
            } else {
                yield put(actions.sendSuccess());
                yield put(
                    notificationActions.showNotification(i18n.get("communications.compose.sent"), "success", [
                        "communications",
                    ]),
                );
                if (isDesktop) {
                    yield put(communicationsActions.listRequest({}));
                    yield put(communicationsActions.hideAllPanels());
                } else {
                    yield put(push("/communications"));
                }
            }
        }
    } catch (e) {
        yield put(actions.sendFailure());
        yield put(
            notificationActions.showNotification(i18n.get("communications.compose.send.error"), "error", [
                "communications",
            ]),
        );
    }
}

function* replyRequest({
    idCommunicationTray,
    subject,
    body,
    idCommunication,
    communicationPriority,
    files,
    isDesktop,
}) {
    try {
        const idFileList = yield uploadFiles(files, "communications.send");

        const errorMessage = idFileList.NO_FIELD;

        let response;

        if (!errorMessage) {
            response = yield call(
                communicationMiddleware.replyRequest,
                idCommunicationTray,
                subject,
                body,
                idFileList,
                idCommunication,
                communicationPriority,
            );
        }

        if (errorMessage || response.type === "W") {
            yield put(actions.replyFailure());

            const message =
                errorMessage || response.data?.data?.NO_FIELD || i18n.get("communications.compose.send.error");

            yield put(notificationActions.showNotification(message, "error", ["communications"]));
        } else {
            yield put(actions.replySuccess());
            let notificationScope;
            if (isDesktop) {
                yield put(communicationsActions.listRequest({}));
                yield put(actions.detailRequest(idCommunication));
                yield put(communicationsActions.showReadPanel());
                notificationScope = "communications";
            } else {
                yield put(actions.replySuccess());
                yield put(push(`/communications/read/${idCommunication}`));
                notificationScope = "communicationsRead";
            }
            yield put(
                notificationActions.showNotification(i18n.get("communications.compose.sent"), "success", [
                    notificationScope,
                ]),
            );
        }
    } catch (e) {
        yield put(actions.replyFailure());
        yield put(
            notificationActions.showNotification(i18n.get("communications.compose.send.error"), "error", [
                "communications",
            ]),
        );
    }
}

function* uploadFiles(files, idActivity) {
    if (!files) {
        return [];
    }

    const responses = yield files.map((f) => call(fileMiddleware.upload, f.content, f.name, "", idActivity));
    const result = responses.filter((r) => r.type === "W");

    if (result.length > 0) {
        const response = result.reduce((resp, fileResp) => ({ ...resp, ...fileResp?.data?.data }), {});
        return response;
    }

    return responses.map((r) => r.data.data.file.idFile);
}

function* downloadAttachment({ idFile }) {
    const response = yield call(fileMiddleware.download, idFile);

    if (response.type === "W") {
        yield put(actions.sendFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        const { fileName, content } = response.data.data;

        download(fileName, content);

        yield put(actions.downloadAttachmentSuccess());
    }
}

function* markAsReadRequest({ idCommunication }) {
    const response = yield call(communicationMiddleware.markAsReadRequest, idCommunication);

    if (response.type === "W") {
        yield put(actions.markAsReadFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        yield put(actions.markAsReadSuccess());
    }
}

function* markAsUnReadRequest({ idCommunication }) {
    const response = yield call(communicationMiddleware.markAsUnReadRequest, idCommunication);

    if (response.type === "W") {
        yield put(actions.markAsReadFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        yield put(actions.markAsUnReadSuccess());
    }
}

const readFileAsDataUrl = (file) => {
    const fileReader = new FileReader();

    return new Promise((resolve) => {
        fileReader.onload = (fileLoadedEvent) => {
            resolve(fileLoadedEvent.target.result);
        };
        fileReader.readAsDataURL(file);
    });
};

function* selectAttachment({ file }) {
    const selectedAttachmentsResult = yield select(selectors.selectedAttachments);
    const exitingFiles = selectedAttachmentsResult.filter((attachment) => attachment.name === file.name);

    if (exitingFiles.length > 0) {
        yield put(
            notificationActions.showNotification(i18n.get("communications.attachment.selected"), "error", [
                "communications",
            ]),
        );
    } else {
        const result = yield readFileAsDataUrl(file);
        yield put(actions.setAttachment({ name: file.name, size: file.size, content: result.split(",")[1] }));
    }
}
