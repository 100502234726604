import React, { Component } from "react";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import { Field, Form, withFormik } from "formik";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Selector from "pages/_components/fields/formik/Selector";
import { push } from "react-router-redux";
import Yup from "yup";
import { bool, shape } from "prop-types";

import { actions as transactionsActions } from "reducers/transactions";

const FORM_ID = "transactions.list.filters.status";

class StatusFilter extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        values: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    render() {
        const {
            fetching,
            values: { status },
            activeEnvironment,
        } = this.props;

        const options = [
            {
                value: i18n.get("transactions.filters.status.DRAFT"),
                label: i18n.get("transactions.filters.status.DRAFT"),
            },
            {
                value: i18n.get("transactions.filters.status.CANCELLED"),
                label: i18n.get("transactions.filters.status.CANCELLED"),
            },
            {
                value: i18n.get("transactions.filters.status.SCHEDULED"),
                label: i18n.get("transactions.filters.status.SCHEDULED"),
            },
            {
                value: i18n.get("transactions.filters.status.FAILED"),
                label: i18n.get("transactions.filters.status.FAILED"),
            },
            {
                value: i18n.get("transactions.filters.status.FINISHED"),
                label: i18n.get("transactions.filters.status.FINISHED"),
            },
            {
                value: i18n.get("transactions.filters.status.PROCESSING"),
                label: i18n.get("transactions.filters.status.PROCESSING"),
            },
            {
                value: i18n.get("transactions.filters.status.PENDING"),
                label: i18n.get("transactions.filters.status.PENDING"),
            },
        ];

        if (activeEnvironment?.administrationScheme === "simple") {
            options.pop();
        }

        return (
            <Form autoComplete="off">
                <Container className="container--layout align-items-center" rowClassName="">
                    <Col sm={12} md={6} lg={4} className="col-no-pad-mobile">
                        <Field
                            idForm={FORM_ID}
                            name="status"
                            hidePlaceholder
                            searchable
                            component={Selector}
                            sortByLabel
                            options={options}
                            style={{ paddingTop: "12px", paddingBottom: "12px" }}
                            placeholder={i18n.get("transactions.filters.placeholder").toUpperCase()}
                        />
                    </Col>
                    <Col xs={12} md={3} className="col-no-pad-mobile align-self-flex-end">
                        <Button
                            bsStyle="primary"
                            label="transactions.list.filters.button.filter"
                            loading={fetching}
                            type="submit"
                            className="filter-button m-0"
                            disabled={!status}
                        />
                    </Col>
                </Container>
            </Form>
        );
    }
}

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: () =>
        Yup.object().shape({
            status: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
        }),
    handleSubmit: ({ status }, formikBag) => {
        const {
            dispatch,
            isDesktop,
            onlyPendings,
            pendingDispatch,
            selectedFilter,
            dateFrom,
            dateTo,
            orderBy,
        } = formikBag.props;

        const filters = {
            filter: selectedFilter,
            status,
            dateFrom,
            dateTo,
            orderBy,
        };

        if (isDesktop) {
            dispatch(transactionsActions.loadListRequest(filters, onlyPendings, pendingDispatch, formikBag));
        } else {
            dispatch(transactionsActions.setFilters(filters));
            dispatch(push(`/transactions/list/filters/results`));
        }
    },
})(StatusFilter);
