import React, { Component } from "react";
import { shape, bool, func, string, arrayOf, number } from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import Container from "pages/_components/Container";
import { push } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import classNames from "classnames";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions, selectors } from "reducers/checks";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as fileActions, selectors as multilineFileSelectors } from "reducers/formFields/multilineFile";

import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";
import Productselector from "pages/forms/_components/_fields/Productselector";
import EcheqMassiveFile from "pages/forms/_components/_fields/_echeqmassivefile/EcheqMassiveFile";
import Notification from "pages/_components/Notification";
import Row from "react-bootstrap/lib/Row";
import ContextMenu from "pages/_components/ContextMenu";
import TextField from "pages/_components/fields/TextField";
import Head from "pages/_components/Head";
import { saveLocation } from "util/transaction";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { downloadUrl } from "util/download";
import PageLoading from "pages/_components/PageLoading";
import { CURRENCY_CODE } from "constants.js";
import Hint from "pages/_components/hints/Hint";

const FORM_ID = "echeq.massive.emit";

class EmitMassive extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        isFetchingDownload: bool,
        accountsFetching: bool.isRequired,
        values: shape({}).isRequired,
        setErrors: func.isRequired,
        fetchingValidateAccount: bool,
        isAvailableAccount: bool,
        setFieldValue: func.isRequired,
        rememberMassiveData: bool,
        summary: shape({}).isRequired,
        isSubmitting: bool.isRequired,
        processedFileData: shape({
            invalidLines: number,
            fileIdentifier: string,
            fileName: string,
            totalAmount: shape({
                quantity: number,
                currency: string,
            }),
        }).isRequired,
    };

    static defaultProps = {
        isFetchingDownload: false,
        fetchingValidateAccount: false,
        isAvailableAccount: false,
        rememberMassiveData: false,
    };

    state = {
        fileUploaded: false,
    };

    componentDidMount() {
        const { dispatch, accounts, loggedUser, setFieldValue, rememberMassiveData, summary } = this.props;

        if (rememberMassiveData && summary?.file) {
            setFieldValue("account", summary?.account?.idProduct);
            setFieldValue("description", summary?.reference);
        } else {
            dispatch(actions.cleanMassiveSummary());
            dispatch(fileActions.onFileRemoved());
            setFieldValue("account", "");
            setFieldValue("description", "");
        }

        saveLocation(this.props);
        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/echeqs"));
    };

    handleDownload = () => {
        const termsAndConditions = configUtils.get("frontend.MassiveEcheq.file.download.example");
        downloadUrl(termsAndConditions || "");
    };

    renderDownloadDropdown = (isFetchingDownload) => (
        <div className="toolbar-item--fixed align-items-center justify-content-end">
            <Button
                block
                label="echeq.massive.download"
                loading={isFetchingDownload}
                image="images/download.svg"
                onClick={() => this.handleDownload()}
                className="btn-outline cmf-button-width"
                imageStyle="mr-2"
            />
        </div>
    );

    rightContent = () => {
        const { isDesktop, isFetchingDownload } = this.props;

        if (isDesktop) {
            return (
                <Col className="p-0 d-flex space-between align-items-center" xs={12}>
                    <div className="toolbar-item justify-content-end">
                        {this.renderDownloadDropdown(isFetchingDownload)}
                    </div>
                </Col>
            );
        }

        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                items={[
                    {
                        label: "echeq.massive.download",
                        onClick: () => this.handleDownload(),
                    },
                ]}
            />
        );
    };

    renderHeader = () => {
        const { isDesktop } = this.props;

        return (
            <>
                {isDesktop && <Head onBack={this.handleBack} />}
                <Head
                    onBack={!isDesktop && this.handleBack}
                    title={FORM_ID}
                    titleClassName="my-0 align-center"
                    messageTooltip={isDesktop ? `${FORM_ID}.title.help` : undefined}
                    rightContent={() => this.rightContent()}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : "mb-3"}
                    hintClassName="max-width-none"
                />
            </>
        );
    };

    onChangeAccount = (account) => {
        const { dispatch, accounts, setErrors } = this.props;
        const selAccount = accounts.find((object) => object.idProduct === account);
        dispatch(actions.validateAccountEmit("CBU", selAccount?.cbu, setErrors));
    };

    render() {
        const {
            accounts,
            accountsFetching,
            isDesktop,
            values,
            fetchingValidateAccount,
            isAvailableAccount,
            processedFileData,
            rememberMassiveData,
            isSubmitting,
        } = this.props;
        const { fileUploaded } = this.state;
        const accountsFilter = accounts?.filter(
            (account) =>
                account.currency === CURRENCY_CODE.ARS &&
                account.productType === "CC" &&
                account?.permiteChequera === "SI",
        );
        const hasErrorFile = processedFileData?.invalidLines > 0;

        const continueButton = (
            <Col lg={3} xl={3} md={9} sm={12}>
                <Row className={`${!isDesktop && "ml-0 mr-0"}`}>
                    <div className="admin-content-center">
                        <Button
                            type="submit"
                            bsStyle="primary"
                            label="global.continue"
                            disabled={!values.file || values.file.length < 1 || hasErrorFile}
                            loading={fetchingValidateAccount || isSubmitting}
                        />
                    </div>
                </Row>
            </Col>
        );

        return (
            <PageLoading loading={accountsFetching}>
                <Notification scopeToShow="form" />
                <div className="admin-detail-head w-100 px-0 mb-0">{this.renderHeader()}</div>
                <Form autoComplete="off">
                    <div className="above-the-fold">
                        <Container
                            className="flex-grow align-items-center container-white my-2 pb-1"
                            gridClassName="form-content">
                            <Col sm={12} md={9} lg={6} className="align-items-center">
                                {!isDesktop ? (
                                    <Hint
                                        idMessage={`${FORM_ID}.title.help${!isDesktop ? ".mobile" : ""}`}
                                        classNameGroup="my-0"
                                        classNameImage="mt-0 mb-0 justify-content-center align-self-center"
                                        alwaysShowMessage
                                    />
                                ) : (
                                    undefined
                                )}
                                <Col sm={12} className="px-0">
                                    <Field
                                        name="account"
                                        component={Productselector}
                                        idField="account"
                                        data={{
                                            emptyOptionLabel: "",
                                            options: accountsFilter,
                                        }}
                                        disabled={accountsFilter.length === 0}
                                        isRequired
                                        mode="edit"
                                        labelText="transfers.debitaccount.label"
                                        idForm={FORM_ID}
                                        isDesktop={isDesktop}
                                        customPlaceholder={i18n.get("transfers.productSelector.placeholder")}
                                        onCustomChange={this.onChangeAccount}
                                    />
                                </Col>
                                <Col sm={12} className="px-0">
                                    <Field
                                        component={TextField}
                                        renderAs="input"
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        maxLength="100"
                                        name="description"
                                        type="text"
                                        formGroupClassName={!isDesktop ? "mb-0" : ""}
                                        optional={i18n.get("form.field.optional")}
                                        errorClassName={classNames({
                                            "p-absolute": isDesktop,
                                        })}
                                    />
                                </Col>

                                {true && (
                                    <Col sm={12} className="px-0 mt-3">
                                        <div className="new-payment-multiline-file">
                                            <Field
                                                name="file"
                                                component={EcheqMassiveFile}
                                                type="multilinefile"
                                                mode="edit"
                                                formVersion="1"
                                                sub_type="echeqMassive"
                                                formTitle="echeqMassive"
                                                allowMultiple="false"
                                                acceptedFileTypes="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                useForTotalAmount="true"
                                                maxFileSizeMB="4"
                                                idField="file"
                                                idForm="echeqMassive"
                                                isRequired
                                                fileUploaded={fileUploaded}
                                                values={values}
                                                FORM_ID={FORM_ID}
                                                rememberMassiveData={rememberMassiveData}
                                                enabledUpload={isAvailableAccount && !fetchingValidateAccount}
                                            />
                                        </div>
                                    </Col>
                                )}
                            </Col>
                        </Container>
                    </div>
                    <Container className="flex-grow align-items-center my-2" gridClassName="form-content">
                        {continueButton}
                    </Container>
                </Form>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    accounts: accountsSelectors.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    accountsFetching: accountsSelectors.getFetching(state),
    fetchingValidateAccount: selectors.getFetchingValidateAccount(state),
    isAvailableAccount: selectors.isAvailableAccount(state),
    rememberMassiveData: selectors.getRememberMassiveData(state),
    summary: selectors.getEmitMassiveEcheqData(state),
    processedFileData: ownProps?.processedFileData || multilineFileSelectors.getProcessedFileData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            account: "",
            file: null,
            description: "",
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    account: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                    file: values.file
                        ? Yup.array()
                              .of(Yup.object())
                              .min(1, i18n.get(`${FORM_ID}.field.error.required`))
                        : Yup.array().notRequired(),
                }),
            ),
        handleSubmit: ({ account, description }, formikBag) => {
            const { props } = formikBag;
            const { dispatch, accounts, processedFileData, summary } = props;
            const emitAccount = accounts.find(({ idProduct }) => idProduct === account);
            const emitMassiveEcheqData = {
                file: processedFileData?.filesMetadata,
                emision: emitAccount?.cbu,
                account: emitAccount,
                reference: description,
                processDate: new Date(),
                echeqsQuantity: processedFileData?.validLines,
                oldBatchId: summary?.batchId,
                higherAmount: {
                    currency: processedFileData?.higherAmount?.currency,
                    quantity: processedFileData?.higherAmount?.quantity,
                },
                amountToShow: {
                    currency: processedFileData?.totalAmount?.currency,
                    quantity: processedFileData?.totalAmount?.quantity,
                },
                echeqsToEmit: [
                    // send one eche for audit
                    {
                        amount: {
                            currency: processedFileData?.totalAmount?.currency,
                            quantity: processedFileData?.totalAmount?.quantity,
                        },
                    },
                ],
            };
            dispatch(actions.setRememberMassiveData(true));
            dispatch(actions.emitMassiveEcheqPreview(formikBag, emitMassiveEcheqData));
        },
    }),
)(EmitMassive);
