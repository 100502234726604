/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as i18nUtils from "util/i18n";

import FormattedAmount from "pages/_components/FormattedAmount";
import { bool, string, shape, number } from "prop-types";
import { resizableRoute } from "pages/_components/Resizable";
import { isEnabledApi5 } from "util/fundcorp";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import { isMobileNative } from "util/device";

class FundListItem extends Component {
    static propTypes = {
        fondo: shape({
            numFondo: number.isRequired,
            nombre: string.isRequired,
            nombreAbreviado: string.isRequired,
            plazoCero: bool.isRequired,
        }).isRequired,
        moneda: shape({
            descripcion: string.isRequired,
            iso: string.isRequired,
            estaAnulado: bool.isRequired,
            idMoneda: string.isRequired,
            cafci: string.isRequired,
        }).isRequired,
        cuotapartista: shape({
            numero: number.isRequired,
            nombre: string.isRequired,
        }).isRequired,
        tipoValorCuotaparte: shape({
            descripcion: string.isRequired,
            abreviatura: string.isRequired,
        }).isRequired,
        isDesktop: bool,
        isEditable: bool.isRequired,
        cuotapartesValuadas: number.isRequired,
        cuotapartesTotal: number.isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    render() {
        const {
            fondo,
            moneda,
            cuotapartista,
            tipoValorCuotaparte,
            cuotapartesValuadas,
            cuotapartesTotal,
            isDesktop,
            isEditable,
        } = this.props;

        const cuotapartistaLabel = `${i18nUtils.get("fundcorp.widgets.cuotapartista.label")} ${cuotapartista.nombre}`;
        const accountLabel = `${i18nUtils.get("fundcorp.widgets.cuotapartista.account.label")} ${cuotapartista.numero}`;
        const cuotapartesLabel = i18nUtils.get("fundcorp.widgets.cuotapartes.label");
        const amountLabel = i18nUtils.get("fundcorp.widgets.amount.label");
        const option = isEnabledApi5 ? "movementsAndRequests" : "movements";
        const path = !isEditable
            ? `/fundcorpOperations?opt=${option}&c=${cuotapartista.numero}&f=${fondo.numFondo}`
            : "/desktop";
        const clase = i18nUtils.get("fundcorp.widgets.class.label");
        const vcp = tipoValorCuotaparte.abreviatura;

        if (isDesktop) {
            return (
                <CardContainer renderAs={Link} to={path} className="widget__card-funds flex-column">
                    <Card
                        className="pb-0 mb-0"
                        title={
                            <div className="d-flex fundcorp__card-title">
                                <div className="d-flex fundcorp__card-cuotapartista">
                                    <span className="fundcorp__cuotapartista-info">{cuotapartistaLabel}</span>
                                    <span className="fundcorp__cuotapartista-data">{accountLabel}</span>
                                </div>
                            </div>
                        }
                        content={
                            <div className="d-flex fundcorp__card-content pt-0">
                                <span className="fundcorp__name">{fondo.nombre}</span>
                                <div className="fundcorp__card-description">
                                    <span className="fundcorp__card-description-info">{clase}</span>
                                    <span>{vcp}</span>
                                </div>
                                <div className="fundcorp__type">
                                    <span className="fundcorp__content-third-info">{cuotapartesLabel}</span>
                                    <FormattedAmount
                                        noCurrency
                                        quantity={cuotapartesTotal}
                                        minimumDecimalsCustom={4}
                                        maximumDecimalsCustom={4}
                                        notBold
                                    />
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="d-flex fundcorp__card-right-content">
                                <div className="fundcorp__card-balance">
                                    <span className="fundcorp__balance-info">{amountLabel}</span>
                                    <FormattedAmount
                                        currency={moneda.iso}
                                        className="data-amount"
                                        quantity={cuotapartesValuadas}
                                    />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            );
        }

        return (
            <>
                <CardContainer renderAs={Link} to={path} className="m-0">
                    <Card
                        className="my-0"
                        hrClass="d-none"
                        title={
                            <div className="fundcorp__card-title">
                                <div className="fundcorp__card-cuotapartista d-flex">
                                    <span className="fundcorp__cuotapartista-info">{cuotapartistaLabel}</span>
                                    <span className="fundcorp__cuotapartista-data">{accountLabel}</span>
                                </div>
                                <div>
                                    <span className="fundcorp__name-widget">{fondo.nombre}</span>
                                </div>
                                <div
                                    className={
                                        isMobileNative ? "fundcorp__type mobile-native m-0" : "fundcorp__type m-0"
                                    }>
                                    <span className="fundcorp__class-info">{clase}</span>
                                    <span>{vcp}</span>
                                </div>
                                <hr className="mx-0 mb-1" />
                            </div>
                        }
                        content={
                            <div className="fundcorp__card-content pb-0 pt-0">
                                <div className="d-flex fundcorp__card-cuotapartes">
                                    <span className="fundcorp__cuotapartes-info">{cuotapartesLabel}</span>
                                    <div className="fundcorp__cuotapartes-data">
                                        <FormattedAmount
                                            noCurrency
                                            quantity={cuotapartesTotal}
                                            minimumDecimalsCustom={4}
                                            maximumDecimalsCustom={4}
                                            notBold
                                        />
                                    </div>
                                </div>
                                <div className="d-flex fundcorp__card-last-amount">
                                    <span className="fundcorp__last-amount-info">{amountLabel}</span>
                                    <FormattedAmount
                                        currency={moneda.iso}
                                        className="data-amount"
                                        quantity={cuotapartesValuadas}
                                        notBold
                                    />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            </>
        );
    }
}

export default resizableRoute(FundListItem);
