import React from "react";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";
import classNames from "classnames";

import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import { getAccountDescription } from "util/accounts";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import { push } from "react-router-redux";
import { isFinalState } from "util/transaction";
import { FAILED, CANCELLED } from "constants.js";

function EmitMassiveSummaryData({ isDesktop, spaceBetween, summary, FORM_ID, dispatch }) {
    const { file, account, reference, processDate, echeqsQuantity, amountToShow, submitDateTime, creationDate, batchId } = summary;

    if (!file || !account || !amountToShow) {
        return <></>;
    }

    const isCancelled = summary.idTransactionStatus === CANCELLED;
    const isFailed = summary.idTransactionStatus === FAILED;

    return (
        <>
            {(isFinalState(summary.idTransactionStatus) || creationDate) && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between flex-no-wrap": spaceBetween,
                    })}
                    label="forms.transaction.ticket.date"
                    labelClassName="no-wrap"
                    data={<FormattedDate date={isFinalState(summary.idTransactionStatus) ? submitDateTime : creationDate} dateFormat="dd/MM/yyyy" showTime />}
                    dataClassName="data-desc text-right"
                />
            )}
            {summary?.idTransaction && batchId && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between flex-no-wrap": spaceBetween,
                    })}
                    label={`${FORM_ID}.summary.idBatch.label`}
                    labelClassName="no-wrap"
                    data={batchId}
                    dataClassName="data-desc text-right"
                />
            )}
            {account && (
                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label={`${FORM_ID}.summary.account.label`}
                    data={
                        <p className="data-desc m-0">
                            {account?.number !== undefined && getAccountDescription(account)}
                        </p>
                    }
                />
            )}
            <Heading.DataGroup
                containerClassName={classNames("transfer-data data-wrapper-flex", {
                    "space-between flex-no-wrap": spaceBetween,
                })}
                label={`${FORM_ID}.summary.batchReference.label`}
                labelClassName="no-wrap"
                data={reference}
                dataClassName="data-desc text-right"
            />
            {processDate && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between flex-no-wrap": spaceBetween,
                    })}
                    label={`${FORM_ID}.summary.processDate.label`}
                    labelClassName="no-wrap"
                    data={<FormattedDate date={processDate || new Date()} dateFormat="dd/MM/yyyy" showTime />}
                    dataClassName="data-desc text-right"
                />
            )}
            {file?.fileName && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between flex-no-wrap": spaceBetween,
                    })}
                    label={`${FORM_ID}.summary.fileName.label`}
                    labelClassName="no-wrap"
                    data={file?.fileName}
                    dataClassName="data-desc text-right"
                />
            )}
            {echeqsQuantity && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between flex-no-wrap": spaceBetween,
                    })}
                    label={`${FORM_ID}.summary.quantity.label`}
                    labelClassName="no-wrap"
                    data={echeqsQuantity}
                    dataClassName="data-desc text-right"
                />
            )}
            {amountToShow && (
                <Heading.DataGroup
                    containerClassName={classNames("transfer-data data-wrapper-flex", {
                        "space-between flex-no-wrap": spaceBetween,
                    })}
                    label={`${FORM_ID}.summary.totalAmount.label`}
                    labelClassName="no-wrap"
                    data={
                        <FormattedAmount
                            {...amountToShow}
                            notBold
                            className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                        />
                    }
                    dataClassName="data-desc text-right"
                />
            )}

            {summary?.idTransaction && !isCancelled && !isFailed && (
                <div className="mt-3">
                    <I18n
                        id="echeq.massive.emit.ticket.description"
                        component="p"
                        componentProps={{
                            className: `text-justify text-12`,
                        }}
                    />
                </div>
            )}
            {summary?.idTransaction && (
                <div className="mt-1 mb-2">
                    <div className="d-flex justify-content-center">
                        <Button
                            type="button"
                            bsStyle="outline"
                            block={false}
                            label={`${FORM_ID}.showList.button`}
                            className="px-4"
                            onClick={() => dispatch(push(`/echeqs/emit/massive/${summary?.idTransaction}/detail`))}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

EmitMassiveSummaryData.propTypes = {
    isDesktop: bool,
    spaceBetween: bool,
    summary: shape({}).isRequired,
    FORM_ID: string.isRequired,
    dispatch: func.isRequired,
};

EmitMassiveSummaryData.defaultProps = {
    isDesktop: true,
    spaceBetween: false,
};

export default connect()(EmitMassiveSummaryData);
