import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";
import { bool, func, object } from "prop-types";

class DraggableItem extends Component {
    static propTypes = {
        isDragEnabled: bool,
        item: object.isRequired,
        itemRenderer: func.isRequired,
    };

    static defaultProps = {
        isDragEnabled: false,
    };

    render() {
        const { isDragEnabled, item, itemRenderer } = this.props;

        return (
            <Draggable draggableId={item.id} isDragDisabled={!isDragEnabled} index={item.row}>
                {(provided, snapshot) => {
                    const draggableItemProps = {
                        ref: provided.innerRef,
                        ...provided.draggableProps,
                        ...provided.dragHandleProps,
                    };

                    return itemRenderer(item, {
                        isDragging: snapshot.isDragging,
                        draggableItemProps,
                    });
                }}
            </Draggable>
        );
    }
}

export default DraggableItem;
