import React, { Component } from "react";
import { shape, bool } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";
import FeatureFlag from "pages/_components/FeatureFlag";
import Signatures from "pages/forms/_components/Signatures";
import Row from "react-bootstrap/lib/Row";

const rename = (transformation, obj) =>
    Object.keys(obj).reduce((acc, key) => {
        const newKey = transformation[key] ? transformation[key] : key;
        acc[newKey] = obj[key];
        return acc;
    }, {});

class FormSignatures extends Component {
    static propTypes = {
        transaction: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    hasChanges = () => {
        const { transaction } = this.props;
        return transaction.creationDateTime !== transaction.modificationDateTime;
    };

    hasDispatcher = () => {
        const {
            transaction: { dispatcher },
        } = this.props;
        return dispatcher;
    };

    authorizerSignature = (signature) => ({
        fullName: signature.userFullName,
        date: signature.creationDateTime,
        signatureLevel: signature.signatureLevel,
    });

    dispatcherSignature = (dispatcher) => ({
        lastName: dispatcher.split(" ")[1],
        firstName: dispatcher.split(" ")[0],
    });

    render() {
        const { transaction, isDesktop } = this.props;
        const { signatures, dispatcher } = transaction;

        const creatorSignature = rename(
            {
                userCreatorLastName: "lastName",
                userCreatorFirstName: "firstName",
                creationDateTime: "date",
            },
            transaction,
        );

        const modifyerSignature = rename(
            {
                userEditorLastName: "lastName",
                userEditorFirstName: "firstName",
                modificationDateTime: "date",
            },
            transaction,
        );

        return (
            <Col sm={12} className="administration-ticket-signature">
                <Row>
                    <I18n
                        component="h2"
                        id="forms.transaction.ticket.title"
                        componentProps={{ className: "transaction-ticket-signature-title" }}
                    />
                </Row>

                <Signatures
                    title="forms.transaction.ticket.createdBy"
                    list={[creatorSignature]}
                    isDesktop={isDesktop}
                />

                {this.hasChanges() && (
                    <Signatures
                        title="forms.transaction.ticket.modifiedBy"
                        list={[modifyerSignature]}
                        isDesktop={isDesktop}
                    />
                )}

                <Signatures
                    title="forms.transaction.ticket.authorizedBy"
                    list={signatures.map(this.authorizerSignature)}
                    isDesktop={isDesktop}
                />

                <FeatureFlag id="feature.signatureSchema.dispatchControl">
                    {this.hasDispatcher() && (
                        <Signatures
                            title="forms.transaction.ticket.dispatchedBy"
                            list={[this.dispatcherSignature(dispatcher)]}
                            isDesktop={isDesktop}
                        />
                    )}
                </FeatureFlag>
            </Col>
        );
    }
}

export default FormSignatures;
