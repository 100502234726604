import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, shape } from "prop-types";
import { routerActions } from "react-router-redux";
import moment from "moment";

import { DOCUMENT_TYPES } from "constants.js";
import * as i18nUtils from "util/i18n";
import { checksDetailStatusTagColor, discountedChecksDetailGetCodeMsg } from "util/checks";
import Head from "pages/_components/Head";
import InfoTag from "pages/_components/InfoTag";
import MainContainer from "pages/_components/MainContainer";
import { selectors as checksSelectors, actions as checksActions } from "reducers/checks";
import Button from "pages/_components/Button";
import ContextMenu from "pages/_components/ContextMenu";
import classNames from "classnames";
import Container from "pages/_components/Container";
import ChecksDetailViewReceived from "./Echeqs/Preview/ChecksDetailViewReceived";
import ChecksDetailViewEmitted from "./Echeqs/Preview/ChecksDetailViewEmitted";

class DiscountedChecksDetailInfo extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        selectedCheckDetail: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {};

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push("/discountedChecksDetail"));
    };

    getTag = () => {
        const { selectedCheckDetail } = this.props;

        return (
            <InfoTag
                type="info"
                message={discountedChecksDetailGetCodeMsg(selectedCheckDetail.codigoEstadoCheque)}
                tagBackground={checksDetailStatusTagColor(
                    discountedChecksDetailGetCodeMsg(selectedCheckDetail.codigoEstadoCheque),
                )}
                moreStyles={{
                    width: "fit-content",
                    color: "black",
                }}
            />
        );
    };

    getCheckDetailTitle = () => {
        const { selectedCheckDetail, isDesktop } = this.props;
        if (!selectedCheckDetail) {
            return <></>;
        }

        const label = `${i18nUtils.get("checksDetail.info.header.title")} ${selectedCheckDetail.comprobante}`;
        const subLabel = !isDesktop ? (
            <p className="subtitle-numbers my-0">{`${i18nUtils
                .get("discountedChecksDetail.table.operationNumber")
                .toUpperCase()} ${selectedCheckDetail.comprobante} | ${i18nUtils
                .get("discountedChecksDetail.table.liquidationNumber")
                .toUpperCase()} ${selectedCheckDetail.codigoSolicitud}`}</p>
        ) : (
            <div className="d-flex mb-4">
                <p>{i18nUtils.get("discountedChecksDetail.table.operationNumber").toUpperCase()}</p>
                <p className="font-light mx-2">{selectedCheckDetail.comprobante}</p>
                <p className="mr-2">{` | `}</p>
                <p>{i18nUtils.get("discountedChecksDetail.table.liquidationNumber").toUpperCase()}</p>
                <p className="font-light mx-2">{selectedCheckDetail.codigoSolicitud}</p>
            </div>
        );

        if (isDesktop) {
            return (
                <>
                    <div className="view-title data-wrapper-flex">
                        <h1 className="mr-3 m-0">{label}</h1>
                        {this.getTag()}
                    </div>
                    {subLabel}
                </>
            );
        }
        return (
            <div className={classNames("view-title data-wrapper-flex", "justify-content-center")}>
                <div>
                    <h1 className="mb-0">{label}</h1>
                    {subLabel}
                </div>
            </div>
        );
    };

    rightContent = () => {
        const { dispatch, isDesktop, selectedCheckDetail } = this.props;

        const options = [
            {
                label: "discountedChecksDetail.info.creditOperation",
                onClick: () => dispatch(checksActions.downloadDiscountChecks(selectedCheckDetail.comprobante)),
            },
        ];

        if (isDesktop) {
            return (
                <>
                    <Button
                        className="btn-outline cmf-button-width"
                        block
                        onClick={() => dispatch(checksActions.downloadDiscountChecks(selectedCheckDetail.comprobante))}
                        label="discountedChecksDetail.info.creditOperation"
                    />
                </>
            );
        }

        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                items={options}
            />
        );
    };

    renderHeader() {
        const { isDesktop } = this.props;

        if (isDesktop) {
            return (
                <div className="admin-detail-head px-0">
                    <Head onBack={this.handleBack} />
                    <Head
                        hasInlineButtons
                        navbarClassName="align-items-start"
                        centerElement={this.getCheckDetailTitle}
                        centerContentClassName="f-dir-col align-items-start pt-2"
                        rightContent={this.rightContent}
                    />
                </div>
            );
        }

        return (
            <Head
                centerElement={this.getCheckDetailTitle}
                rightContent={this.rightContent}
                headerClassName="blue-main-header-mobile blue-main-title-mobile"
                onBack={this.handleBack}
            />
        );
    }

    render() {
        const { selectedCheckDetail, isDesktop } = this.props;

        if (!selectedCheckDetail) {
            this.handleBack();
        }
        const payDay = moment(selectedCheckDetail.fechaDepositoCheque).format("DD");
        const payMonth = i18nUtils.get(`checks.month.${moment(selectedCheckDetail.fechaDepositoCheque).format("M")}`);
        const payYear = moment(selectedCheckDetail.fechaDepositoCheque).format("YYYY");
        const firstLine = selectedCheckDetail.denominacionBeneficiario.trim()
            ? `${DOCUMENT_TYPES[selectedCheckDetail.tipoDocumentoBeneficiario]} ${
                  selectedCheckDetail.numeroDocumentoBeneficiario
              }`
            : "";
        const checkNumber = selectedCheckDetail.comprobante;
        const amount = `${i18nUtils.get(
            `currency.label.${selectedCheckDetail.codigoMoneda.toString()}`,
        )} ${new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
            selectedCheckDetail.importe,
        )}`;
        const { cmc7 } = selectedCheckDetail;
        const bankName = selectedCheckDetail.denominacionBancoLibrador;

        return (
            <>
                {this.renderHeader()}
                <MainContainer>
                    <div className={classNames({ "ml-2 mt-3": !isDesktop })}>{!isDesktop && this.getTag()}</div>
                    <div className="above-the-fold">
                        <Container
                            className="align-items-center flex-grow"
                            rowClassName={classNames(
                                { "content-echeq-mobile": !isDesktop },
                                { "justify-content-center": isDesktop },
                            )}>
                            <div
                                className={classNames(
                                    { "echeq-view-mobile": !isDesktop },
                                    { "echeq-view-desktop": isDesktop },
                                )}>
                                {selectedCheckDetail.codigoBancoLibrador === 319 ? (
                                    <ChecksDetailViewEmitted
                                        payDay={payDay}
                                        payMonth={payMonth}
                                        payYear={payYear}
                                        firstLine={firstLine}
                                        echeqNro={checkNumber}
                                        amount={amount}
                                        largeNumber={cmc7}
                                        bankName={bankName}
                                        showOrder={false}
                                    />
                                ) : (
                                    <ChecksDetailViewReceived
                                        payDay={payDay}
                                        payMonth={payMonth}
                                        payYear={payYear}
                                        firstLine={firstLine}
                                        echeqNro={checkNumber}
                                        amount={amount}
                                        largeNumber={cmc7}
                                        bankName={bankName}
                                        showOrder={false}
                                    />
                                )}
                            </div>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedCheckDetail: checksSelectors.getSelectedCheckDetail(state),
});

export default connect(mapStateToProps)(DiscountedChecksDetailInfo);
