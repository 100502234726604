import React from "react";
import { bool, func, string } from "prop-types";

import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import Button from "pages/_components/Button";
import InfoTag from "pages/_components/InfoTag";
import Heading from "pages/_components/Heading";

import * as i18n from "util/i18n";

function FundSuscriptionCard({
    isDesktop,
    horizonteInversionDescripcion,
    nombre,
    rescatePlazo,
    reportUrl,
    horaDesde,
    horaHasta,
    importeMin,
    moneda,
    regionDescripcion,
    onSelect,
}) {
    const openUrl = (e) => {
        e.stopPropagation();
        if (window.cordova && window.cordova.InAppBrowser) {
            window.cordova.InAppBrowser.open(reportUrl, "_system", "hidden=no,location=no");
        } else {
            window.open(reportUrl, "_blank");
        }
    };

    return (
        <CardContainer role={onSelect ? "button" : undefined} onClick={onSelect}>
            <Card
                hrClass="d-none"
                isDesktop={isDesktop}
                className="fundcorp__suscribe-card"
                title={
                    isDesktop ? (
                        <div>
                            <p className="fundcorp__sucribe-card-title">
                                {`${horizonteInversionDescripcion}  |  ${i18n.get(
                                    `fundcorp.card.rescue.time.${rescatePlazo}`,
                                )}`}
                            </p>
                        </div>
                    ) : (
                        <div>
                            <h4>{nombre}</h4>
                            <InfoTag
                                type="info"
                                message={`${i18n.get("fundcorp.suscribe.card.risk")} ${regionDescripcion}`}
                                tagBackground="#E1F5FC"
                                tagClass="mx-0"
                                capitalize
                                moreStyles={{
                                    alignSelf: "flex-start",
                                    color: "#072229",
                                    fontFamily: "Graphik-Regular",
                                    width: "fit-content",
                                }}
                            />
                            <hr className="mx-0" />
                        </div>
                    )
                }
                content={
                    isDesktop ? (
                        <div className="d-flex align-items-center flex-flow-wrap">
                            <p className="fundcorp__suscribe-card-center">{nombre}</p>
                            <InfoTag
                                type="info"
                                message={`${i18n.get("fundcorp.suscribe.card.risk")} ${regionDescripcion}`}
                                tagBackground="#E1F5FC"
                                capitalize
                                moreStyles={{
                                    alignSelf: "flex-start",
                                    color: "#072229",
                                    fontFamily: "Graphik-Regular",
                                }}
                            />
                        </div>
                    ) : (
                        <div>
                            <Heading.DataGroup
                                containerClassName="data-wrapper-flex space-between"
                                label="fundcorp.suscribe.card.horizon"
                                labelClassName="no-wrap"
                                data={horizonteInversionDescripcion}
                                dataClassName="data-desc text-right"
                            />
                            <Heading.DataGroup
                                containerClassName="data-wrapper-flex space-between"
                                label="fundcorp.suscribe.card.rescue.time"
                                labelClassName="no-wrap"
                                data={i18n.get(`fundcorp.card.rescue.time.${rescatePlazo}`)}
                                dataClassName="data-desc text-right"
                            />
                            <Heading.DataGroup
                                containerClassName="data-wrapper-flex space-between"
                                label="fundcorp.suscribe.card.time.label"
                                labelClassName="no-wrap"
                                data={i18n.get("fundcorp.suscribe.card.time.data", null, {
                                    HOUR_FROM: horaDesde,
                                    HOUR_TO: horaHasta,
                                })}
                                dataClassName="data-desc text-right"
                            />
                            <Heading.DataGroup
                                containerClassName="data-wrapper-flex space-between"
                                label="fundcorp.suscribe.card.min.amount"
                                labelClassName="no-wrap"
                                data={`${moneda} ${importeMin}`}
                                dataClassName="data-desc text-right"
                            />
                            <Heading.DataGroup
                                containerClassName="data-wrapper-flex space-between mt-1"
                                label="fundcorp.subscribe.detail.label"
                                labelClassName="no-wrap"
                                data={
                                    <Button
                                        onClick={openUrl}
                                        rel="noopener noreferrer"
                                        label="fundcorp.subscribe.detail.button.label"
                                        className="btn btn-outline fundcorp__btn-detail m-0"
                                        disabled={!reportUrl}
                                    />
                                }
                                dataClassName="data-desc text-right"
                            />
                        </div>
                    )
                }
                bottom={
                    isDesktop ? (
                        <p className="fundcorp__suscribe-card-bottom">
                            {`${i18n.get("fundcorp.suscribe.card.time.label")}: ${i18n.get(
                                "fundcorp.suscribe.card.time.data",
                                null,
                                {
                                    HOUR_FROM: horaDesde,
                                    HOUR_TO: horaHasta,
                                },
                            )}  |  ${i18n.get("fundcorp.suscribe.card.min.amount")}: ${moneda} ${importeMin}`}
                        </p>
                    ) : (
                        undefined
                    )
                }
                rightContent={
                    isDesktop ? (
                        <div className="fundcorp__sucribe-card-right-content">
                            <Button
                                onClick={openUrl}
                                rel="noopener noreferrer"
                                label="fundcorp.subscribe.detail.drawer.button.label"
                                className="btn btn-outline fundcorp__btn-detail m-0"
                                disabled={!reportUrl}
                            />
                        </div>
                    ) : (
                        undefined
                    )
                }
            />
        </CardContainer>
    );
}

FundSuscriptionCard.propTypes = {
    isDesktop: bool.isRequired,
    horizonteInversionDescripcion: string.isRequired,
    nombre: string.isRequired,
    rescatePlazo: string.isRequired,
    reportUrl: string.isRequired,
    horaDesde: string.isRequired,
    horaHasta: string.isRequired,
    importeMin: string.isRequired,
    moneda: string.isRequired,
    regionDescripcion: string.isRequired,
    onSelect: func.isRequired,
};

export default FundSuscriptionCard;
