import React, { Component } from "react";
import Link from "react-router-dom/Link";
import { string } from "prop-types";

import I18n from "pages/_components/I18n";

class ContainerColumnHeader extends Component {
    static propTypes = {
        title: string.isRequired,
        url: string,
        linkText: string,
    };

    static defaultProps = {
        url: "",
        linkText: "",
    };

    render() {
        const { title, url, linkText } = this.props;
        // eslint-disable-next-line no-console
        console.warn(`${ContainerColumnHeader.name} component is deprecated and it will be removed`);
        return (
            <header className="col-header">
                <I18n component="h2" id={title} />

                <div style={{ marginTop: "0.1rem" }}>
                    {url && (
                        <Link className="btn btn-link" to={url}>
                            <I18n id={linkText} />
                        </Link>
                    )}
                </div>
            </header>
        );
    }
}

export default ContainerColumnHeader;
