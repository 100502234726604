import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import { Form } from "formik";
import { func, bool } from "prop-types";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import { Modal } from "react-bootstrap";

class EsignModal extends Component {
    static propTypes = {
        goBack: func,
        acceptEsign: bool.isRequired,
        acceptTerms: bool.isRequired,
        handleCloseBottomSheetClick: func.isRequired,
        handleProceedClick: func,
        isDisplayed: bool,
    };

    static defaultProps = {
        goBack: () => {},
        isDisplayed: false,
        handleProceedClick: () => {},
    };

    goBack = () => {
        const { goBack } = this.props;
        goBack();
    };

    render() {
        const { isDisplayed, acceptEsign, acceptTerms, handleCloseBottomSheetClick, handleProceedClick } = this.props;

        return (
            <Modal
                aria-labelledby="modalTitleID"
                aria-describedby="modalDescID"
                aria-modal="true"
                onHide={handleCloseBottomSheetClick}
                show={isDisplayed}>
                <div className="modal-container modal-enrollment">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Image src="images/warningIcon.svg" className="svg-icon" />
                            <I18n id="onboarding.step.12.esign.modal.header" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="above-the-fold">
                            <MainContainer>
                                <div className="above-the-fold">
                                    <Container className="container--layout flex-grow ">
                                        <Col sm={12} className="col col-12">
                                            <div className="form-text-group">
                                                <I18n
                                                    className="control-label"
                                                    id={`onboarding.step.12.esign.modal.${
                                                        acceptEsign === "ACCEPT" && !acceptTerms
                                                            ? "missingTerms"
                                                            : "declineEsign"
                                                    }`}
                                                />

                                                <I18n id="onboarding.step.12.esign.modal.help" />
                                            </div>
                                        </Col>
                                    </Container>
                                    <Container className="container--layout align-items-center">
                                        <Col className="col col-12">
                                            <Button
                                                type="button"
                                                label="onboarding.step.12.esign.modal.buttonDecline"
                                                bsStyle="primary"
                                                onClick={handleCloseBottomSheetClick}
                                            />
                                            <Button
                                                type="button"
                                                className="btn-outline"
                                                label="onboarding.step.12.esign.modal.buttonAccept"
                                                onClick={handleProceedClick}
                                            />
                                        </Col>
                                    </Container>
                                </div>
                            </MainContainer>
                        </Form>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}

export default EsignModal;
