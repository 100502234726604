import React from "react";
import { shape } from "prop-types";
import Heading from "pages/_components/Heading";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import * as i18nUtils from "util/i18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as accountUtils from "util/accounts";

import FormattedDate from "pages/_components/FormattedDate";

export default function ThirdPaymentSummaryDetail({ transaction, accounts, transactionOrder }) {
    ThirdPaymentSummaryDetail.propTypes = {
        transaction: shape({}).isRequired,
        accounts: shape({}).isRequired,
        transactionOrder: shape({}).isRequired,
    };
    const { reference, typePayment, debitAccount, dateProcess, totalAmount, amount } = transaction;
    const containerClassName = `data-wrapper-flex mb-0`;

    const labelClassName = "mb-0 data-wrapper-inline mr-2";
    const typePaymentOptions = [
        {
            value: `PH`,
            label: i18nUtils.get("payments.payThirdParties.newPayment.salaryPayments.label"),
        },
        {
            value: `PP`,
            label: i18nUtils.get("payments.payThirdParties.newPayment.supplierPayments.label"),
        },
    ];
    let accountName = null;
    const numberAccount = debitAccount;
    if (accounts.length > 0) {
        const loggedUserAccount = accounts.find((account) => numberAccount.includes(account?.idProduct));
        accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
    }

    return (
        <>
            <div className="above-the-fold container-white py-2">
                <Container className="flex-grow container-white data-wrapper-inline">
                    <Col className="col col-12" md={4} xl={4} lg={4} sm={12}>
                        <Heading.DataGroup
                            labelClassName={labelClassName}
                            containerClassName={containerClassName}
                            label="thirdPay.confirm.table.header.typePayment"
                            data={typePaymentOptions.find(({ value }) => value === typePayment).label}
                            addDots
                        />
                    </Col>
                    <Col className="col col-12" md={4} xl={4} lg={4} sm={12}>
                        <Heading.DataGroup
                            labelClassName={labelClassName}
                            containerClassName={containerClassName}
                            label="thirdPay.confirm.table.header.account"
                            data={accountName}
                            addDots
                        />
                    </Col>
                    <Col className="col col-12" md={4} xl={4} lg={4} sm={12}>
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            labelClassName={labelClassName}
                            label="thirdPay.confirm.table.header.reference"
                            data={reference}
                            addDots
                        />
                    </Col>
                </Container>
                <Container className=" flex-grow container-white data-wrapper-inline">
                    <Col className="col col-12" md={4} xl={4} lg={4} sm={12}>
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            labelClassName={labelClassName}
                            label="thirdPay.confirm.table.header.dateProcess"
                            data={<FormattedDate date={dateProcess} dateFormat="dd/MM/yyyy" />}
                            addDots
                        />
                    </Col>
                    <Col className="col col-12" md={4} xl={4} lg={4} sm={12}>
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            labelClassName={labelClassName}
                            label="payments.thirdPayment.detail.linesNumber"
                            data={transactionOrder}
                        />
                    </Col>
                    <Col className="col col-12" md={4} xl={4} lg={4} sm={12}>
                        <Heading.DataGroup
                            containerClassName={containerClassName}
                            labelClassName={labelClassName}
                            label="payments.payThirdParties.newPayment.amount.label"
                            data={<FormattedAmount notBold currency={amount?.currency} quantity={totalAmount} />}
                            addDots
                        />
                    </Col>
                </Container>
            </div>
        </>
    );
}
