import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { func, string } from "prop-types";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";

class AccountAction extends Component {
    static propTypes = {
        handleClick: func.isRequired,
        className: string,
        labelKey: string,
        to: string,
    };

    static defaultProps = {
        className: null,
        labelKey: null,
        to: null,
    };

    handleClick = () => {
        const { handleClick } = this.props;

        if (handleClick) {
            handleClick();
        }
    };

    render() {
        const { className, labelKey, to } = this.props;

        return (
            (to && (
                <Link className={className || classNames("btn btn-quiet")} to={to}>
                    <I18n id={labelKey} />
                </Link>
            )) || <Button className={className} label={labelKey} onClick={this.handleClick} bsStyle="link" />
        );
    }
}

export default AccountAction;
