import { node } from "prop-types";
import React, { Children, Component } from "react";

class PasswordRules extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <ul style={{ paddingRight: "0.5rem" }}>
                {Children.map(children, (child, index) => (
                    <li key={`PasswordRules.${index.toString()}`}>{child}</li>
                ))}
            </ul>
        );
    }
}

export default PasswordRules;
