import React from "react";
import { arrayOf, bool, func, oneOfType, string, shape } from "prop-types";

import formField from "pages/forms/_components/_fields/_commons/formField";
import CheckboxGroup from "pages/forms/_components/_fields/_commons/CheckboxGroup";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import Select from "pages/forms/_components/_fields/Select";

class Selector extends React.Component {
    static propTypes = {
        optionList: arrayOf(shape({ id: string, label: string })).isRequired,
        idField: string.isRequired,
        renderAs: string.isRequired,
        defaultValue: string.isRequired,
        setValue: func.isRequired,
        editing: bool.isRequired,
        value: oneOfType([string, shape({})]),
        placeholder: string,
        name: string.isRequired,
    };

    static defaultProps = {
        value: "",
        placeholder: "",
    };

    componentDidMount() {
        const { defaultValue, value, setValue } = this.props;
        if (defaultValue !== null && !value) {
            setValue([defaultValue]);
        } else if (!value) {
            setValue([]);
        }
    }

    handleChange = (newValue) => {
        const { renderAs, setValue } = this.props;

        let selectedValues;
        if (renderAs === "combo") {
            selectedValues = [newValue.id];
        } else if (renderAs === "radio") {
            selectedValues = [newValue];
        } else {
            selectedValues = newValue;
        }

        setValue(selectedValues);
    };

    viewAsCheck() {
        const { optionList, value } = this.props;
        const checkValue = value || [];

        return <CheckboxGroup options={optionList} values={checkValue} onChange={this.handleChange} mode="view" />;
    }

    renderAsCombo() {
        const { optionList, value, placeholder, idField } = this.props;
        const comboValue = value ? value[0] : "";

        return (
            <div className="input-group">
                <div style={{ flex: 1 }}>
                    <Select
                        id={idField}
                        placeholder={placeholder}
                        value={comboValue}
                        clearable={false}
                        searchable={false}
                        onChange={this.handleChange}
                        valueKey="id"
                        labelKey="label"
                        options={optionList}
                        className="flex-container slideFromBottom"
                        optionClassName="needsclick"
                    />
                </div>
            </div>
        );
    }

    renderAsCheck() {
        const { optionList, value, name } = this.props;
        const checkValue = value || [];

        return <CheckboxGroup options={optionList} values={checkValue} onChange={this.handleChange} name={name} />;
    }

    renderAsRadio() {
        const { optionList, value, idField, name } = this.props;
        const radioValue = value ? value[0] : "";

        return (
            <RadioButtonGroup
                value={radioValue}
                selectorId={idField}
                options={optionList}
                onChange={this.handleChange}
                name={name}
            />
        );
    }

    renderEditMode() {
        const { renderAs } = this.props;

        if (renderAs === "combo") {
            return this.renderAsCombo();
        }
        if (renderAs === "check") {
            return this.renderAsCheck();
        }
        return this.renderAsRadio();
    }

    renderViewMode() {
        const { optionList, value, renderAs } = this.props;

        if (renderAs === "check") {
            return this.viewAsCheck();
        }

        const options = optionList.filter((option) => value.indexOf(option.id) !== -1);

        if (options.length === 0) {
            return null;
        }

        return (
            <ul>
                {options.map((option) => (
                    <li key={option.id}>{option.label}</li>
                ))}
            </ul>
        );
    }

    render() {
        const { editing } = this.props;
        if (editing) {
            return this.renderEditMode();
        }
        return this.renderViewMode();
    }
}

const options = () => {
    const { renderAs } = this.props;
    const asLegend = renderAs === "radio";

    return {
        formClass: "form-group--select",
        isEmptyValue: (value) => value.length === 0,
        isValidValue: (value) => Array.isArray(value),
        renderLegend: asLegend,
    };
};

export default formField(options)(Selector);
