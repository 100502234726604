import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { go, goBack, replace } from "react-router-redux";
import { string, func, shape } from "prop-types";

import { selectors as onboardingSelectors } from "reducers/onboarding";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import { Mixpanel } from "util/clickstreaming";

const errorCodesToBackFrontImage = ["151", "152", "153"];
const backMRZError = "150";

class Error extends Component {
    static propTypes = {
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        dispatch: func.isRequired,
        errors: shape({}).isRequired,
    };

    handleClick = () => {
        const { dispatch, errors, match } = this.props;
        Mixpanel.track("onboarding.error", { ...errors, match: match.url });
        if (errors) {
            if (errors.providerErrorCode && errorCodesToBackFrontImage.indexOf(errors.providerErrorCode) !== -1) {
                dispatch(go(-2));
            } else if (errors.providerErrorCode === backMRZError && match.url === "/onboarding/step2/front/error") {
                dispatch(replace("/onboarding/step2/back/"));
            } else {
                dispatch(goBack());
            }
        } else {
            dispatch(goBack());
        }
    };

    render() {
        const { errors } = this.props;
        const { providerErrorCode, ...errorsToShow } = errors;

        return (
            <MainContainer className="main-container onboarding-layout">
                <div className="above-the-fold">
                    <Container className="flex-grow align-items-center container--layout">
                        <Col className="col col-12">
                            <div className="media-object media-object--state-error">
                                <figure>
                                    <Image src="images/error.svg" className="svg-icon" />
                                </figure>
                                <div className="media-object--text">
                                    {Object.values(errorsToShow).map((error) => (
                                        <h2 key={error}>{error}</h2>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <Button bsStyle="primary" onClick={this.handleClick} label="onboarding.error.tryAgain" />
                        </Col>
                    </Container>
                    {/* <Container className="flex-grow align-items-center container--layout"></Container> */}
                </div>
            </MainContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    errors: onboardingSelectors.getErrors(state),
});

export default connect(mapStateToProps)(Error);
