import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Col, Button } from "react-bootstrap";
import { bool, func, string } from "prop-types";

import { actions as settingActions, selectors as settingsSelectors } from "reducers/settings";
import { actions as notificationActions } from "reducers/notification";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import Image from "pages/_components/Image";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Heading from "pages/_components/Heading";
import MainContainer from "pages/_components/MainContainer";
import classNames from "classnames";

class AccountsOfficer extends Component {
    static propTypes = {
        accountsOfficer: {
            accountsOfficerInformation: {
                name: string.isRequired,
                email: string.isRequired,
                telephone: string.isRequired,
            },
        }.isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(settingActions.getAccountsOfficerData());
    }

    showNotification = () => {
        const { dispatch } = this.props;
        dispatch(
            notificationActions.showNotification(i18n.get("settings.index.accountsOfficer.copied"), "success", [
                "settings",
            ]),
        );
    };

    getSettingsData = () => {
        const { accountsOfficer, isDesktop } = this.props;
        const { name, email, telephone } = accountsOfficer.accountsOfficerInformation;

        return (
            <>
                <Col
                    sm={12}
                    md={4}
                    className={`col col-12 d-flex align-items-center ${
                        isDesktop ? "justify-content-start" : "justify-content-center"
                    }`}>
                    <Image wrapperClassName="w-auto svg-wrapper " className="svg-key" src="images/account_circle.svg" />
                    <span className="data-text content-data-strong officer-name f-size-1 line-height-1 align-font-fix-02">
                        {name}
                    </span>
                </Col>
                <Col sm={12} md={4} className="col col-12 d-flex justify-content-center align-items-center">
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex mb-0 justify-content-center mt-1"
                        dataClassName="f-size-4 font-light"
                        label="settings.index.accountsOfficer.mail.label"
                        data={email}
                    />
                </Col>
                <Col
                    sm={12}
                    md={4}
                    className={`col col-12 d-flex align-items-center ${
                        isDesktop ? "justify-content-right" : "justify-content-center"
                    }`}>
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex mb-0 justify-content-end mt-1"
                        dataClassName="f-size-4 font-light"
                        label="settings.index.accountsOfficer.telephone.label"
                        data={telephone}
                    />
                </Col>
            </>
        );
    };

    getText = (name, email, telephone) => {
        const params = {
            ACCOUNTS_OFFICER_NAME: name,
            ACCOUNTS_OFFICER_EMAIL: email,
            ACCOUNTS_OFFICER_PHONE: telephone,
        };

        const message = i18n.get("settings.index.accountsOfficer.copied.data");
        return i18n.replaceParams(message, params);
    };

    rightContent = () => {
        const { accountsOfficer, isDesktop } = this.props;
        const { name, email, telephone } = accountsOfficer.accountsOfficerInformation;

        const buttonShare = (
            <Button
                bsStyle={isDesktop ? "outline" : "link"}
                id="editDashboardBtn"
                onClick={() => {
                    if (!isDesktop && window.cordova) {
                        const options = {
                            message: this.getText(name, email, telephone),
                            subject: i18n.get("settings.index.accountsOfficer.label"),
                            // files: [contentData],
                        };
                        window.plugins.socialsharing.shareWithOptions(options, null, null);
                    }
                }}
                className={isDesktop ? "cmf-button-width" : "no-shadow"}>
                <Image
                    className="svg-icon-btn"
                    src={`images/share${!isDesktop ? "-white" : ""}.svg`}
                    styles={
                        isDesktop ? { fontSize: "1.6rem" } : { position: "relative", fontSize: "1.6rem", top: "3px" }
                    }
                />
                {isDesktop && (
                    <I18n id="global.share" componentProps={{ style: { marginLeft: "1rem", fontSize: "1rem" } }} />
                )}
            </Button>
        );

        return isDesktop || !window.cordova ? (
            <CopyToClipboard text={this.getText(name, email, telephone)} onCopy={() => this.showNotification()}>
                {buttonShare}
            </CopyToClipboard>
        ) : (
            buttonShare
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop/"));
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", margin: "0.2rem" }}>{i18n.get("settings.index.accountsOfficer.label")}</h1>
        </div>
    );

    render() {
        const { accountsOfficer, fetching, isDesktop } = this.props;
        const { name } = accountsOfficer.accountsOfficerInformation;

        return (
            <>
                <Notification scopeToShow="settings" />
                <div className="admin-detail-head px-0">
                    {isDesktop && <Head onBack={this.handleBack} />}
                    <Head
                        onBack={!isDesktop ? this.handleBack : null}
                        title={isDesktop ? "settings.index.accountsOfficer.label" : null}
                        rightContent={name && this.rightContent}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : " "}
                        centerElement={!isDesktop ? this.centerContentMobile : null}
                    />
                </div>
                <PageLoading className="line-loader" loading={fetching}>
                    <div className="view-morphing">
                        <Container className="align-items-baseline flex-grow container-white py-4 mt-45 mb-3">
                            {this.getSettingsData()}
                        </Container>
                    </div>
                    <div className="view-morphing">
                        <MainContainer>
                            <div className="above-the-fold">
                                <Container className="flex-grow account-officer-info">
                                    <Col xl="5" lg="5" md="5" sm="12" className={classNames({ "px-0": isDesktop })}>
                                        <I18n
                                            componentProps={{ className: "font-light" }}
                                            id="settings.index.accountsOfficer.info.text"
                                            component="p"
                                        />
                                    </Col>
                                </Container>
                            </div>
                        </MainContainer>
                    </div>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    accountsOfficer: settingsSelectors.getAccountsOfficer(state),
    fetching: settingsSelectors.isFetching(state),
});

export default connect(mapStateToProps)(AccountsOfficer);
