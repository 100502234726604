/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { idTransactionFormat } from "util/format";
import { getAccountDescription } from "util/accounts";

import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import * as i18n from "util/i18n";

class RecurrencyDebinDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
        showAction: bool,
    };

    static defaultProps = {
        showAction: false,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop, showAction } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                data: { debitAccount, sellerName, prestacion, action },
            } = object.transaction;

            const accountName = debitAccount?.number !== undefined && getAccountDescription(debitAccount);

            return (
                <>
                    <CardContainer className="confirmation__cards-container mt-0">
                        <Card
                            className="confirmation__cards"
                            icon={isDesktop && "images/transfer-PENDING.svg"}
                            iconClass="card__pending-transaction-icon"
                            hrClass="d-none"
                            title={
                                !isDesktop && (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get("debin.recurrency.seller.name.title")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>{sellerName}</span>
                                        </div>
                                    </div>
                                )
                            }
                            content={
                                <div>
                                    {isDesktop && (
                                        <div className="d-flex align-items-center">
                                            <div className="confirmation-cards__content pb-1">
                                                <div className="confirmation-cards__content-data">
                                                    <span>{i18n.get("debin.recurrency.seller.name.title")}</span>
                                                </div>
                                                <div className="confirmation-cards__content-info">
                                                    <span>{sellerName}</span>
                                                </div>
                                            </div>
                                            {showAction && (
                                                <div className="confirmation-cards__content info-type-a">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get("transaction.ticket.action")}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info">
                                                        <span>
                                                            {i18n.get(`debin.recurrency.${action.toLowerCase()}.debin`)}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    !showAction
                                                        ? "confirmation-cards__content info-type-a"
                                                        : "confirmation-cards__content info-type-b"
                                                }>
                                                <div className="confirmation-cards__content-data">
                                                    <span>{i18n.get("debin.recurrency.prestacion.title")}</span>
                                                </div>
                                                <div className="confirmation-cards__content-info">
                                                    <span>{prestacion}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!isDesktop && !showAction && (
                                        <div>
                                            <div className="confirmation-cards__content">
                                                <div className="confirmation-cards__content-data">
                                                    <span>{i18n.get("forms.transaction.ticket.number")}</span>
                                                </div>
                                                <div className="confirmation-cards__content-info">
                                                    <span>{idTransactionFormat(idTransaction)}</span>
                                                </div>
                                            </div>
                                            {accountName && (
                                                <div className="confirmation-cards__content">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get("debin.recurrency.account.title")}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info">
                                                        <span>{accountName}</span>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="confirmation-cards__content">
                                                <div className="confirmation-cards__content-data">
                                                    <span>{i18n.get("debin.recurrency.prestacion.title")}</span>
                                                </div>
                                                <div className="confirmation-cards__content-info">
                                                    <span>{prestacion}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!isDesktop && showAction && (
                                        <div>
                                            <div className="confirmation-cards__content">
                                                <div className="confirmation-cards__content-data">
                                                    <span>{i18n.get("transaction.ticket.action")}</span>
                                                </div>
                                                <div className="confirmation-cards__content-info">
                                                    <span>
                                                        {i18n.get(`debin.recurrency.${action.toLowerCase()}.debin`)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="confirmation-cards__content">
                                                <div className="confirmation-cards__content-data">
                                                    <span>{i18n.get("debin.recurrency.prestacion.title")}</span>
                                                </div>
                                                <div className="confirmation-cards__content-info">
                                                    <span>{prestacion}</span>
                                                </div>
                                            </div>
                                            <div className={accountName ? "confirmation-cards__content" : "d-none"}>
                                                <div className="confirmation-cards__content-data">
                                                    <span>{i18n.get("debin.recurrency.account.title")}</span>
                                                </div>
                                                <div className="confirmation-cards__content-info">
                                                    <span>{accountName}</span>
                                                </div>
                                            </div>
                                            <div className="confirmation-cards__content">
                                                <div className="confirmation-cards__content-data">
                                                    <span>{i18n.get("forms.transaction.ticket.number")}</span>
                                                </div>
                                                <div className="confirmation-cards__content-info">
                                                    <span>{idTransactionFormat(idTransaction)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                            bottom={
                                isDesktop && (
                                    <div className="d-flex align-items-center">
                                        {accountName && (
                                            <div className="confirmation-cards__bottom confirmation-cards-separator">
                                                <div className="confirmation-cards__bottom-data">
                                                    <span>{i18n.get("debin.recurrency.account.title")}</span>
                                                </div>
                                                <div className="confirmation-cards__bottom-info">
                                                    <span>{accountName}</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="confirmation-cards__bottom">
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get("forms.transaction.ticket.number")}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        />
                    </CardContainer>
                </>
            );
        });
    };

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(RecurrencyDebinDataTable);
