import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { func, bool, string } from "prop-types";
import { compose } from "redux";
import { Col } from "react-bootstrap";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";

import { actions as onboardingActions } from "reducers/onboarding";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";

import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import { Link } from "react-router-dom";
import { Mixpanel } from "util/clickstreaming";

import { REGION_USA } from "constants.js";

const FORM_ID = "onboarding.step0";

export const MAIN_ONBOARDING_ROUTE = "/onboarding/step0";

class Step0 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        activeRegion: string.isRequired,
    };

    handleButtonClick = () => () => {
        const { dispatch } = this.props;
        this.setState(() => {
            dispatch(push("/onboarding/step2/front"));
        });
    };

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    render() {
        const { isMobile, activeRegion } = this.props;

        return (
            <Fragment>
                <Head
                    title="onboarding.step0.title"
                    onBack={isMobile && this.onHeaderBack}
                    onClose={isMobile && this.onHeaderClose}
                />
                <MainContainer>
                    <Form className="above-the-fold" noValidate>
                        <Container
                            className="container--layout flex-grow align-items-center"
                            gridClassName="form-content">
                            <Col className="col col-12">
                                <Field
                                    autoComplete="off"
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={50}
                                    name="email"
                                    type="email"
                                />
                            </Col>
                        </Container>
                        <Container className="container--layout align-items-center">
                            {activeRegion !== REGION_USA && (
                                <Col className="col col-12">
                                    <I18n component="span" id="onboarding.step0.note" />{" "}
                                    <Link to="/onboarding/termsAndConditions">
                                        {i18nUtils.get("global.termAndConditions").toLowerCase()}
                                    </Link>
                                </Col>
                            )}
                        </Container>
                        <Container className="container--layout align-items-center">
                            <Col className="col col-12">
                                <Button bsStyle="primary" label="global.continue" loading={false} type="submit" />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    activeRegion: loginSelectors.getRegion(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            email: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                email: Yup.string()
                    .required(i18nUtils.get("onboarding.step4.email.error.empty"))
                    .test("format", i18nUtils.get("onboarding.step4.email.error.invalidFormat"), (value) =>
                        /^[A-z][\w.+-_]{0,63}@[\w.+-_]{1,200}\.[A-z.+-_]{2,55}$/i.test(value),
                    ),
            }),
        handleSubmit: ({ email }, formikBag) => {
            const { dispatch, activeRegion } = formikBag.props;
            dispatch(onboardingActions.setEmail(email));
            Mixpanel.identify(email);
            Mixpanel.track(FORM_ID);
            if (activeRegion === REGION_USA) {
                dispatch(push("/onboarding/stepCIP"));
            } else {
                dispatch(push("/onboarding/step1"));
            }
        },
    }),
)(Step0);
