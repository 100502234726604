import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Heading from "pages/_components/Heading";
import CapsList from "pages/administration/_components/CapsList";
import { shape, func, number, string, bool } from "prop-types";
import { channelsSelectors } from "reducers/administration";
import { selectors as sessionSelectors } from "reducers/session";
import { connect } from "react-redux";
import { actions as adminActions } from "reducers/administration/common/administrationTicket";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

class AdministrationChannelsTicket extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        user: shape({}).isRequired,
        topAmount: shape({
            amount: number,
            frequency: string,
            maximum: number,
        }).isRequired,
        isConfirmation: bool,
        currency: shape({
            id: string,
        }).isRequired,
        caps: shape({
            amount: number,
            frequency: string,
            currency: string,
        }).isRequired,
        hasDefaultLabel: bool,
        activeEnvironment: shape({ type: string }).isRequired,
    };

    static defaultProps = {
        hasDefaultLabel: false,
        isConfirmation: false,
    };

    state = {
        idUser: null,
    };

    generateExtraData = () => {
        const {
            currency = {},
            topAmount,
            isConfirmation,
            user,
            dispatch,
            caps,
            activeEnvironment: { type },
            hasDefaultLabel,
        } = this.props;

        let extraData = {};

        if (user.firstName) {
            extraData = { ...extraData, userExtraData: user.firstName.concat(" ", user.lastName) };

            if (topAmount) {
                const { frequency, maximum, amount } = topAmount;

                extraData = {
                    ...extraData,
                    topAmount: {
                        label: isConfirmation
                            ? "administration.channels.confirmation.topAmount"
                            : "administration.channels.topAmount",
                        value: {
                            frequency: i18n.get(`administration.channels.${frequency}`).toUpperCase(),
                            currency: currency.id,
                            quantity: maximum || amount,
                        },
                    },
                };
            }

            extraData = {
                ...extraData,
                channel: {
                    label: isConfirmation ? "administration.channels.confirmation.channels" : "administration.channels",
                    value: " ",
                },
            };

            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(caps)) {
                extraData = {
                    ...extraData,
                    ["channels".concat(key)]: {
                        label: "channels.".concat(key),
                        value: {
                            frequency: i18n.get(`administration.channels.${value.frequency}`).toUpperCase(),
                            currency: value.currency,
                            quantity:
                                value.amount === undefined
                                    ? parseFloat(configUtils.get(`default_cap_user_${type}`))
                                    : value.amount,
                            endText: hasDefaultLabel ? i18n.get("channels.defaultCap") : "",
                        },
                    },
                };
            }

            dispatch(adminActions.setExtraData(extraData));
        }
    };

    render() {
        const { user } = this.props;
        const { idUser } = this.state;

        if (user?.idUser && user.idUser !== idUser) {
            this.setState({ idUser: user.idUser });
            this.generateExtraData();
        }

        return (
            <Col sm={12} className="channels-ticket-detail">
                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="administration.channels.user"
                    data={`${user.firstName} ${user.lastName}`}
                    spaceBetween
                />
                <div className="channels-ticket-detail-caplist">
                    <CapsList spaceBetween />
                </div>
            </Col>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    caps: channelsSelectors.getCaps(state),
    currency: channelsSelectors.getCurrencies(state)[0],
    topAmount: channelsSelectors.getTopAmount(state),
});

export default connect(mapStateToProps)(AdministrationChannelsTicket);
