import React, { Component } from "react";
import classNames from "classnames";
import { Field } from "formik";
import { string } from "prop-types";

import FieldError from "pages/_components/fields/FieldError";
import MultiSelectGroup from "pages/_components/fields/formik/MultiSelectGroup";

class FormikMultiSelectGroup extends Component {
    static propTypes = {
        valueKey: string,
        name: string.isRequired,
    };

    static defaultProps = {
        valueKey: "value",
    };

    handleSelect = ({ form, field }, option) => {
        const { valueKey } = this.props;
        const { name, value = [] } = field;

        form.setFieldValue(name, [...value, option[valueKey]]);
    };

    handleDelete = ({ form, field }, option) => {
        const { valueKey } = this.props;

        form.setFieldValue(
            field.name,
            field.value.filter((selectedOption) => selectedOption !== option[valueKey]),
        );
    };

    render() {
        const { name } = this.props;

        return (
            <Field name={name}>
                {(formProps) => {
                    const { field, form } = formProps;
                    const { touched, errors } = form;
                    const hasError = touched[field.name] && errors[field.name];

                    return (
                        <div
                            className={classNames("form-group", {
                                "has-error": hasError,
                            })}>
                            <MultiSelectGroup
                                onSelect={(option) => this.handleSelect(formProps, option)}
                                onDelete={(option) => this.handleDelete(formProps, option)}
                                values={formProps.field.value}
                                {...this.props}
                            />
                            {hasError && <FieldError error={errors[field.name]} />}
                        </div>
                    );
                }}
            </Field>
        );
    }
}

export default FormikMultiSelectGroup;
