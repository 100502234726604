import { call, put, takeLatest } from "redux-saga/effects";

import * as campaigns from "middleware/campaigns";
import { types } from "reducers/campaigns";

const sagas = [
    takeLatest(types.LOAD_CAMPAIGN_REQUEST, loadCampaign),
    takeLatest(types.LOAD_CAMPAIGN_IMAGE, loadCampaignImage),
    takeLatest(types.DISMISS_CAMPAIGN_PERMANENT_REQUEST, dismissPermanentCampaign),
];

export default sagas;

function* loadCampaign({ section }) {
    try {
        const response = yield call(campaigns.fetchCampaign, section);
        if (response.status !== 304) {
            const { data } = response.data;

            yield put({ type: types.LOAD_CAMPAIGN_IMAGE, item: data.campaign });
        }
    } catch (err) {
        yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
    }
}

function getFittingImage([mobileImage, tabletImage, desktopImage]) {
    if (window.innerWidth < 769) {
        return mobileImage;
    }
    if (window.innerWidth < 993) {
        return tabletImage;
    }
    return desktopImage;
}

function* loadCampaignImage({ item }) {
    try {
        const image = getFittingImage(item.imageList);
        if (!image) {
            yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
        } else {
            const { idImage, idCampaign } = image;
            const response = yield call(campaigns.fetchCampaignImage, { idImage, idCampaign });

            if (response.status !== 304) {
                const { data } = response.data;
                yield put({ type: types.LOAD_CAMPAIGN_SUCCESS, item: { ...item, image: data.image.content } });
            }
        }
    } catch (err) {
        yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
    }
}

function* dismissPermanentCampaign(idCampaign) {
    try {
        const response = yield call(campaigns.dismissPermanentCampaign, idCampaign);

        if (response.data.code !== "COR000I") {
            yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_SUCCESS });
        } else {
            yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_FAILURE });
        }
    } catch (err) {
        yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_FAILURE });
    }
}
