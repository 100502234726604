import React, { Component } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { func, bool, shape } from "prop-types";
import { goBack } from "react-router-redux";
import { compose } from "redux";

import { selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import * as i18n from "util/i18n";

import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import classNames from "classnames";
import ServicePaymentsSummaryData from "./_components/ServicePaymentsSummaryData";
import ServicePaymentsTokenConfirmation from "./_components/ServicePaymentsTokenConfirmation";

const FORM_ID = "services.newPayment";

class AgendaServicePaymentsConfirmationTicket extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        summaryInfo: shape({}).isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", margin: 0 }}>{i18n.get("services.newPayment")}</h1>
        </div>
    );

    render() {
        const { isDesktop, summaryInfo } = this.props;

        return (
            <>
                <Notification scopeToShow="servicePayments" />
                <div className={classNames({ isDesktop: "admin-detail-head px-0 mb-4" })}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile agenda-payment-title-mobile" : ""}
                        navStyle="default pl-0"
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && (
                        <div className="view-title mb-2">
                            <h1>{i18n.get(`${FORM_ID}.confirmationTicket.title`)}</h1>
                        </div>
                    )}
                </div>
                <MainContainer>
                    <Container
                        className={`flex-grow ${isDesktop ? "align-items-center" : ""} container-white`}
                        gridClassName="form-content">
                        <div
                            className="admin-content-center"
                            style={{
                                marginBottom: isDesktop ? "2rem" : "1rem",
                                display: !isDesktop && "flex",
                                justifyContent: !isDesktop && "center",
                            }}>
                            <I18n
                                id={`${FORM_ID}.confirmationTicket.label`}
                                component={`${isDesktop ? "h2" : "h3"}`}
                                componentProps={{
                                    style: {
                                        width: !isDesktop && "50%",
                                        fontFamily: !isDesktop && "Graphik-Light",
                                    },
                                }}
                            />
                        </div>
                        <Col
                            className={`col-12 col-xl-4 ${!isDesktop && "payment-sumary-mobile"}`}
                            lg={4}
                            md={9}
                            sm={12}>
                            <ServicePaymentsSummaryData
                                summaryInfo={summaryInfo}
                                FORM_ID={FORM_ID}
                                isDesktop={isDesktop}
                            />
                        </Col>
                    </Container>

                    <ServicePaymentsTokenConfirmation paymentRequestData={summaryInfo} isDesktop={isDesktop} />
                </MainContainer>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    summaryInfo: servicePaymentsSelectors.getSummaryInfo(state),
});

export default compose(connect(mapStateToProps))(AgendaServicePaymentsConfirmationTicket);
