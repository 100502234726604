import React, { useEffect, useRef } from "react";
import { bool, shape } from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";

import * as config from "util/config";

import FieldError from "pages/_components/fields/FieldError";

function Captcha({ form, field, resetCaptcha }) {
    const captchaRef = useRef();

    const { touched, errors } = form;

    useEffect(() => {
        if (resetCaptcha) {
            captchaRef.current.reset();
        }
    }, [resetCaptcha]);

    const handleChange = (value) => {
        form.setFieldValue(field.name, value);
    };

    const handleExpired = () => {
        form.setFieldValue(field.name, "");
    };

    return (
        <div
            className={classNames("form-group", {
                "has-error": touched[field.name] && errors[field.name],
            })}>
            <ReCAPTCHA
                sitekey={config.get("core.auth.captcha.google.siteKey")}
                onChange={handleChange}
                ref={captchaRef}
                onExpired={handleExpired}
            />
            {touched[field.name] && errors[field.name] && <FieldError error={errors[field.name]} />}
        </div>
    );
}

Captcha.propTypes = {
    form: shape.isRequired,
    field: shape.isRequired,
    resetCaptcha: bool.isRequired,
};

export default Captcha;
