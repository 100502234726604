import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { actions as adminActions, selectors } from "reducers/administration/common/administrationTicket";
import { shape, func } from "prop-types";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";

const userStatusToAction = { blocked: "block", active: "unblock", delete: "delete" };

class UserActionTicket extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    state = {
        ticketValue: null,
    };

    chargeData = (ticketText) => {
        const { dispatch } = this.props;
        const { transactionData } = this.props;

        const userAction = transactionData?.data?.newStatus || "delete";
        const textKey = `administration.users.action.confirmation.${userStatusToAction[userAction]}`;

        const extraData = {
            data1: {
                label: textKey,
                value: ticketText,
            },
        };

        this.setState({ ticketValue: ticketText });

        dispatch(adminActions.setExtraData(extraData));
    };

    render() {
        const { transactionData } = this.props;
        const { ticketValue } = this.state;

        const value = transactionData?.data?.userNameList?.reduce(
            (acc, userName) => acc.concat(userName).concat("\n"),
            "",
        );

        if (value && value !== ticketValue) {
            this.chargeData(value);
        }

        if (transactionData.data.userNameList) {
            const userAction = transactionData.data.newStatus || "delete";
            const textKey = `administration.users.action.confirmation.${userStatusToAction[userAction]}`;
            const ticketText = transactionData.data.userNameList.map((userName, index) => (
                <div key={index}> {userName} </div>
            ));

            return (
                <Container
                    className="container--layout align-items-center flex-grow p-0 m-0 user-action-ticket-data"
                    gridClassName="form-content">
                    <Col>
                        <div className="transfer-data data-wrapper">
                            <Container.ColumnBody columnClass="list-wrapper">
                                <h4 className="form-title">
                                    <I18n id={textKey} />
                                </h4>
                                {ticketText}
                            </Container.ColumnBody>
                        </div>
                    </Col>
                </Container>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(UserActionTicket);
