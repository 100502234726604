import React, { Component, Fragment } from "react";
import { func, string } from "prop-types";
import Button from "pages/_components/Button";
import FieldLabel from "pages/_components/fields/FieldLabel";
import classNames from "classnames";
import { isMobileNative } from "util/device";

class InputSetProductAlias extends Component {
    static propTypes = {
        onSave: func.isRequired,
        defaultValue: string.isRequired,
    };

    state = {
        value: null,
        inputFocus: false,
    };

    handleOnChange = (event) => {
        this.setState({ value: event.target.value });
    };

    handleSave = () => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (onSave) {
            onSave(value);
        }
    };

    handleFocusBlur = () => {
        const { inputFocus } = this.state;

        this.setState({ inputFocus: !inputFocus });
    };

    render() {
        const { value, inputFocus } = this.state;
        const { defaultValue } = this.props;

        return (
            <Fragment>
                <FieldLabel key="1" labelKey="products.alias.edit.label.mobile" idField={defaultValue} />
                <input
                    id="modifyAlias"
                    className="form-control w-100 height-40"
                    onChange={this.handleOnChange}
                    onFocus={this.handleFocusBlur}
                    onBlur={this.handleFocusBlur}
                    value={value !== null ? value : defaultValue}
                    autoFocus
                    maxLength="50"
                />
                <div
                    className={classNames(`p-fixed bottom-0 left-0 w-100 px-3 padding-bottom-safe-area`, {
                        "ios-avoid-keyboard-margin": inputFocus && isMobileNative,
                        "mb-3": (!inputFocus && isMobileNative) || !isMobileNative,
                    })}>
                    <Button
                        key="10"
                        label="global.send"
                        bsStyle="primary"
                        type="submit"
                        className="w-100"
                        onClick={this.handleSave}
                    />
                </div>
            </Fragment>
        );
    }
}

export default InputSetProductAlias;
