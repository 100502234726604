import React, { Component } from "react";
import { bool, string } from "prop-types";
import { Link } from "react-router-dom";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import classNames from "classnames";

class SettingsOption extends Component {
    static propTypes = {
        linkTo: string.isRequired,
        messageKey: string.isRequired,
        isMobile: bool,
    };

    static defaultProps = {
        isMobile: false,
    };

    render() {
        const { linkTo, messageKey, isMobile } = this.props;

        return (
            <li className={classNames("navigational-list-item mb-2", { "b-none": isMobile })}>
                <Link to={linkTo}>
                    <I18n id={messageKey} />
                    <Image src="images/chevromRight.svg" className="svg-icon svg-caret" />
                </Link>
            </li>
        );
    }
}

export default SettingsOption;
