import { Component } from "react";
import { node, func } from "prop-types";

class ResizableComponent extends Component {
    static propTypes = {
        children: node.isRequired,
        onResize: func.isRequired,
    };

    componentDidMount() {
        const { onResize } = this.props;
        window.addEventListener("resize", onResize);
    }

    componentWillUnmount() {
        const { onResize } = this.props;
        window.removeEventListener("resize", onResize);
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

export default ResizableComponent;
