import React, { Component } from "react";
import { connect } from "react-redux";
import Heading from "pages/_components/Heading";
import Col from "react-bootstrap/lib/Col";
import { selectors as debinSelectors } from "reducers/debin";
import * as i18n from "util/i18n";
import FormattedDate from "pages/_components/FormattedDate";
import { shape, arrayOf, bool } from "prop-types";

class CreateDebinSellerSummary extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        seller: shape({}).isRequired,
        accounts: arrayOf(shape({})).isRequired,
        concepts: arrayOf(shape({})).isRequired,
    };

    render() {
        const { isDesktop, seller, accounts, concepts } = this.props;
        const { concept: conceptId, account, description, date } = seller;
        const selectedAccount = accounts.find((a) => a.idProduct === account);
        const concept = concepts.find(({ id }) => id === conceptId);

        const arrayAccountData = selectedAccount && selectedAccount.extraInfoMasked.split("|");

        return (
            <>
                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={`transfer-data  mb-0 ${
                            isDesktop ? "data-wrapper" : "data-wrapper-flex space-between debin-detail"
                        }`}
                        label="debin.create.summary.creditAccount.label"
                        data={
                            arrayAccountData.length > 0
                                ? `${arrayAccountData[1]} ${arrayAccountData[0]} 
                    ${i18n.get(`currency.label.${arrayAccountData[2]}`)}`
                                : ""
                        }
                    />
                </Col>

                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={`transfer-data  mb-0 ${
                            isDesktop ? "data-wrapper" : "data-wrapper-flex space-between debin-detail"
                        }`}
                        label="debin.create.summary.concept.label"
                        data={`${concept.id} - ${concept.name}`}
                    />
                </Col>

                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        dataClassName="data-desc word-break-all"
                        containerClassName={`transfer-data mb-0 ${
                            isDesktop ? "data-wrapper" : "data-wrapper-flex space-between debin-detail"
                        }`}
                        label="debin.create.summary.description.label"
                        data={description || "-"}
                    />
                </Col>

                <Col className="col col-12" lg={3} xl={3} md={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={`transfer-data  mb-0 ${
                            isDesktop ? "data-wrapper" : "data-wrapper-flex space-between debin-detail"
                        }`}
                        label="debin.create.summary.expiration.label"
                        data={<FormattedDate date={date} showTime />}
                    />
                </Col>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    seller: debinSelectors.getDebinSeller(state),
    accounts: debinSelectors.getAccounts(state),
    concepts: debinSelectors.getConcepts(state),
});

export default connect(mapStateToProps)(CreateDebinSellerSummary);
