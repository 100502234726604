import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";
import { push, goBack } from "react-router-redux";
import moment from "moment";

import { selectors, actions } from "reducers/administration/common/administrationTicket";
import { actions as formActions } from "reducers/form";

import * as i18nUtils from "util/i18n";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";

import PayPaymentAFIPTransactionData from "pages/servicePayments/_components/PayPaymentAFIPTransactionData";
import TransactionTicket from "pages/_components/TransactionTicket";

class DeletePaymentAFIPTicket extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        transactionData: shape({}).isRequired,
        dispatch: func.isRequired,
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
    };

    state = {
        fetchingDownload: false,
    };

    componentDidMount() {
        const { match, dispatch } = this.props;
        const { idTransaction } = match.params;

        dispatch(actions.loadAdministrationTicketRequest(idTransaction));
    }

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: "-0.5rem 2rem" }}>{i18nUtils.get("delete.paymentAFIP.summary.label")}</h1>
        </div>
    );

    generateTicketBody = () => {
        const {
            transactionData,
            transactionData: { data },
        } = this.props;

        const { creationDateTime, idTransaction } = transactionData;

        const {
            fiscalPeriod,
            dueDate,
            amount,
            conceptDesc,
            subConceptDesc,
            description,
            formNumber,
            advancePayment,
            taxpayerCUIT,
        } = data;

        const responseArray = [
            getGeneralTicketItem("pay.paymentAFIP.openDate", creationDateTime, "datefulltime"),
            getGeneralTicketItem(
                "pay.paymentAFIP.idTransaction",
                idTransaction.substring(0, 8).toUpperCase(),
                "string",
            ),
            getGeneralTicketItem("pay.paymentAFIP.numberVEP", data?.numberVEP && Number(data?.numberVEP), "string"),
            getGeneralTicketItem("pay.paymentAFIP.fiscalPeriod", fiscalPeriod, "string"),
            getGeneralTicketItem("pay.paymentAFIP.dueDate", moment(dueDate, "YYYY-MM-DD"), "date"),
            getGeneralTicketItem("pay.paymentAFIP.amount", amount?.quantity, "amountARS"),
            getGeneralTicketItem("pay.paymentAFIP.taxpayerCUIT", taxpayerCUIT, "string"),
            getGeneralTicketItem("pay.paymentAFIP.concept", conceptDesc, "string"),
            getGeneralTicketItem("pay.paymentAFIP.subConcept", subConceptDesc, "string"),
            getGeneralTicketItem("pay.paymentAFIP.description", description, "string"),
            getGeneralTicketItem("pay.paymentAFIP.formNumber", formNumber, "string"),
            getGeneralTicketItem("pay.paymentAFIP.advancePayment", advancePayment, "string"),
        ];

        return responseArray;
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const ticketData = {
            ticketName: i18nUtils.get("delete.paymentsAFIP.confirmation.label"),
            ticketBody: [...this.generateTicketBody()],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    onSign = () => {
        const {
            dispatch,
            transactionData: { data },
            transactionData,
        } = this.props;

        const summary = { paymentAFIP: data, idTransaction: transactionData.idTransaction };

        const signTransactionData = <PayPaymentAFIPTransactionData summary={summary} />;

        dispatch(push("/transactions/sign"));

        dispatch(
            formActions.signTransactionData({
                idTransaction: summary?.idTransaction,
                idActivity: transactionData?.idActivity,
                idForm: "",
                content: signTransactionData,
                title: "delete.paymentsAFIP.confirmation.label",
                pendingDispatch: summary?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: transactionData?.idActivity,
                idTransaction: transactionData?.idTransaction,
                idForm: "",
                ticketData: transactionData?.data,
            }),
        );
    };

    buttonsDetail = () => {
        const { dispatch } = this.props;

        return [
            {
                label: "services.newPayment.confirmationTicket.goToDesktop",
                bsStyle: "outline",
                onClick: () => dispatch(push(`/desktop`)),
            },
            {
                label: "services.newPayment.confirmationTicket.goToPayments",
                bsStyle: "primary",
                onClick: () => dispatch(push(`/servicePayments`)),
            },
        ];
    };

    render() {
        const {
            transactionData,
            transactionData: { data },
            isDesktop,
            fetching,
        } = this.props;

        const { fetchingDownload } = this.state;

        const summary = { paymentAFIP: data, idTransaction: transactionData.idTransaction, transactionData };

        return (
            <>
                {data && (
                    <TransactionTicket
                        notificationScope="deleteAFIPConfirmation"
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        headerClassName={
                            !isDesktop ? "blue-main-header-mobile blue-main-title-mobile new-payment-title-mobile" : ""
                        }
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        uniqueDownload={transactionData?.idTransactionStatus === "FINISHED"}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={transactionData}
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        summaryTitle="delete.paymentsAFIP.confirmation.label"
                        downloadImageWhite={!isDesktop}
                        fetching={fetching}
                        isFetchingExport={fetchingDownload}
                        onSignTicket={this.onSign}
                        ignoreHomeButton
                        isPaymentAFIPTicket>
                        <PayPaymentAFIPTransactionData summary={summary} isDesktop={isDesktop} />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
    fetching: selectors.isFetching(state),
});

export default connect(mapStateToProps)(DeletePaymentAFIPTicket);
