/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";

import { selectors as transactionsSelectors } from "reducers/transactions";
import { idTransactionFormat } from "util/format";

import I18n from "pages/_components/I18n";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class AdminRestrictionDeleteDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        return selectedTransactionRows.map((object) => {
            const {
                idTransaction,
                submitDateTimeAsString,
                data: { userFullName },
            } = object.transaction;

            return (
                <CardContainer className="confirmation__cards-container">
                    <Card
                        className="confirmation__cards"
                        icon={isDesktop && "images/transfer-PENDING.svg"}
                        iconClass="card__pending-transaction-icon"
                        hrClass="d-none"
                        title={
                            isDesktop ? (
                                submitDateTimeAsString && (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <I18n id="forms.transaction.ticket.date" />
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>{submitDateTimeAsString}</span>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div>
                                    {userFullName && (
                                        <div className="confirmation-cards__title">
                                            <div className="confirmation-cards__title-data">
                                                <I18n id="transaction.ticket.user" />
                                            </div>
                                            <div className="confirmation-cards__title-info">
                                                <span>{userFullName}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        content={
                            isDesktop ? (
                                userFullName && (
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <I18n id="transaction.ticket.user" />
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{userFullName}</span>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div>
                                    {submitDateTimeAsString && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="forms.transaction.ticket.date" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{submitDateTimeAsString}</span>
                                            </div>
                                        </div>
                                    )}
                                    {idTransactionFormat(idTransaction) && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <I18n id="forms.transaction.ticket.number" />
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{idTransactionFormat(idTransaction)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        bottom={
                            isDesktop &&
                            idTransactionFormat(idTransaction) && (
                                <div className="confirmation-cards__bottom">
                                    <div className="confirmation-cards__bottom-data">
                                        <I18n id="forms.transaction.ticket.number" />
                                    </div>
                                    <div className="confirmation-cards__bottom-info">
                                        <span>{idTransactionFormat(idTransaction)}</span>
                                    </div>
                                </div>
                            )
                        }
                    />
                </CardContainer>
            );
        });
    };

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(AdminRestrictionDeleteDataTable);
