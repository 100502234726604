import React, { Component } from "react";
import { shape, arrayOf, bool } from "prop-types";
import { format } from "date-fns";
import { Chart } from "react-google-charts";
import Col from "react-bootstrap/lib/Col";

import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedRate from "pages/_components/FormattedRate";
import Container from "pages/_components/Container";
import CardContainer from "pages/_components/Card/CardContainer";

import * as i18nUtils from "util/i18n";
import classNames from "classnames";
import { isEnabledApi5 } from "util/fundcorp";
import Image from "pages/_components/Image";
import NoDataMessage from "./NoDataMessage";
import FundReportItem from "./FundReportItem";

const FORM_ID = "fundcorp.list";
class FundcorpPortfolioCompositionList extends Component {
    static propTypes = {
        portfolioComposition: arrayOf(shape({})).isRequired,
        managementReport: arrayOf(shape({})).isRequired,
        isDesktop: bool.isRequired,
        isEmptyState: bool.isRequired,
    };

    renderPortfolioComposition = () => {
        const { isDesktop, portfolioComposition } = this.props;
        let completePortfolioCompositionsList = [...portfolioComposition.portfolioCompositionsList];

        completePortfolioCompositionsList = completePortfolioCompositionsList.concat(portfolioComposition.others);

        const chartDataPortfolioCompositionsList = [...portfolioComposition.portfolioCompositionsList];
        chartDataPortfolioCompositionsList.push(portfolioComposition.othersSum);

        const chartData = [
            [
                i18nUtils.get(`${FORM_ID}.portfolioComposition.column.instruments`),
                i18nUtils.get(`${FORM_ID}.portfolioComposition.column.percentage`),
            ],
            ...chartDataPortfolioCompositionsList.map((item) => this.renderListForPortfolioCompositionItem(item)),
        ];

        const chartOptions = {
            backgroundColor: "transparent",
            legend: "none",
            pieSliceText: "value",
            pieSliceTextStyle: {
                color: "black",
            },
            pieHole: 0.92,
            tooltip: {
                trigger: "none",
            },
            fontSize: 15,
            chartArea: {
                left: "15",
                right: "15",
                top: "15",
                bottom: "15",
                width: "100%",
                height: "100%",
            },
            slices: Object.assign(
                {},
                chartDataPortfolioCompositionsList.map((portfolioCompositionItem) => ({
                    color: portfolioCompositionItem.color,
                })),
            ),
        };

        const currency = completePortfolioCompositionsList[0].monedaID;
        const { fondoNombre } = completePortfolioCompositionsList[0];
        const { total } = portfolioComposition;

        const formatterRate = [
            {
                type: "NumberFormat",
                column: 1,
                options: { fractionDigits: 2, suffix: "%" },
            },
        ];

        return (
            <>
                <div className={isDesktop ? "mb-45" : "mb-3"}>
                    <div className={`font-regular mb-1 px-3 ${isDesktop ? "h1" : "h2"}`}>{fondoNombre}</div>
                    <I18n
                        id="fundcorp.list.portfolioComposition.subtitle"
                        fecha={format(completePortfolioCompositionsList[0].fecha, "DD/MM/YYYY")}
                        component={isDesktop ? "h1" : "span"}
                        componentProps={{ className: "my-0 px-3 font-light d-block" }}
                    />
                    <I18n
                        id="fundcorp.list.portfolioComposition.subtitle.lastValueShare"
                        component="span"
                        componentProps={{
                            className: `font-light pl-3 mt-2 ${isDesktop ? "f-size-55" : "f-size-6"}`,
                        }}
                    />
                </div>
                <div
                    className={classNames("flex-grow scrollable d-flex", {
                        "flex-column": !isDesktop,
                        "space-between fundcorp-tablet": isDesktop,
                    })}>
                    <div className="px-3">
                        <I18n
                            id="fundcorp.list.portfolioComposition.total"
                            component="h4"
                            componentProps={{ className: "text-uppercase mb-0" }}
                        />
                        <FormattedAmount currency={currency} quantity={total} className="text-left" />
                    </div>
                    <div className="content-center">
                        <Chart
                            height="350px"
                            width="350px"
                            chartType="PieChart"
                            data={chartData}
                            options={chartOptions}
                            graph_id="DonutChart"
                            loader={<div />}
                            formatters={formatterRate}
                        />
                    </div>
                    <div className="content-center px-3">{this.renderTable(completePortfolioCompositionsList)}</div>
                </div>
            </>
        );
    };

    renderListForPortfolioComposition = () => {
        const { portfolioComposition, isEmptyState } = this.props;

        return (
            <>
                {portfolioComposition &&
                    portfolioComposition.portfolioCompositionsList &&
                    portfolioComposition.portfolioCompositionsList.length > 0 &&
                    this.renderPortfolioComposition()}
                {isEmptyState && (
                    <NoDataMessage>
                        <I18n id={`${FORM_ID}.empty.message.information`} />
                    </NoDataMessage>
                )}
            </>
        );
    };

    renderListForPortfolioCompositionItem = (item) => {
        const { tipoEspecie, monedaDescripcion, porcentaje } = item;

        const instrument = i18nUtils.get("fundcorp.list.portfolioComposition.column.instruments.value", null, {
            tipoEspecie,
            monedaDescripcion,
        });

        return [instrument, porcentaje];
    };

    renderTable = (itemList) => {
        const { isDesktop } = this.props;

        return (
            <Table className={classNames("border-none row-no-min-height", { "d-flex flex-column": !isDesktop })}>
                <Table.Header>
                    <Table.HeaderData className="border-none fundcorp-reference">
                        <div />
                    </Table.HeaderData>
                    <Table.HeaderData align="left" className="border-none">
                        <I18n id={`${FORM_ID}.portfolioComposition.column.instruments`} />
                    </Table.HeaderData>
                    <Table.HeaderData align="right" className="border-none justify-content-start">
                        <I18n id={`${FORM_ID}.portfolioComposition.column.percentage`} />
                    </Table.HeaderData>
                </Table.Header>
                <Table.Body>{itemList.map((item) => this.renderTableItem(item))}</Table.Body>
            </Table>
        );
    };

    renderTableItem = (item) => {
        const itemArray = this.renderListForPortfolioCompositionItem(item);

        return (
            <Table.Row>
                <Table.Data className="fundcorp-reference">
                    <div style={{ background: item.color }} />
                </Table.Data>
                <Table.Data align="left">
                    <div className="data-text">{itemArray[0]}</div>
                </Table.Data>
                <Table.Data>
                    <FormattedRate
                        rate={itemArray[1]}
                        symbolToTheLeft={false}
                        className="data-desc small-font text-right"
                    />
                </Table.Data>
            </Table.Row>
        );
    };

    renderManagementReport = () => {
        const { isDesktop, managementReport } = this.props;
        const list = managementReport.map((fund) => (
            <CardContainer
                className={`${isDesktop ? "container-white" : "border-big-transparent infinite-scroll-height-80-px"}`}
                key={fund.number}
                renderAs="div">
                <FundReportItem key={`fund-${fund.number}`} fundReport={fund} isDesktop={isDesktop} />
            </CardContainer>
        ));

        return (
            <>
                <Container className="flex-grow scrollable">
                    <Col className="col col-12 px-0">
                        <div
                            style={{ borderSpacing: "0px 2px", width: "100%" }}
                            ref={(tableRef) => {
                                this.child = tableRef;
                            }}>
                            {managementReport.length ? (
                                <div className="w-100">{list}</div>
                            ) : (
                                <div className="text-center no-more-data" key="noMoreMovements">
                                    <div className="illustration-wrapper">
                                        <Image src="images/coloredIcons/folder-empty.svg" className="svg-big-icon" />
                                    </div>
                                    <p className="text-lead" style={{ opacity: 1 }}>
                                        <I18n id="fundcorp.list.managementReport.empty.message.information" />
                                    </p>
                                </div>
                            )}
                        </div>
                    </Col>
                </Container>
            </>
        );
    };

    render() {
        return <>{isEnabledApi5() ? this.renderManagementReport() : this.renderListForPortfolioComposition()}</>;
    }
}

export default FundcorpPortfolioCompositionList;
