import React, { Component } from "react";
import { string, bool, func, shape, objectOf, oneOfType, arrayOf } from "prop-types";

import Checkbox from "pages/_components/fields/Checkbox";

class FormikCheckbox extends Component {
    static propTypes = {
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([bool, arrayOf(string)]),
        }).isRequired,
        form: shape({
            touched: objectOf(bool),
            errors: objectOf(string),
        }).isRequired,
    };

    render() {
        const { field, form, ...props } = this.props;

        return <Checkbox {...field} {...props} error={form.touched[field.name] && form.errors[field.name]} />;
    }
}

export default FormikCheckbox;
