import React, { Component, Fragment } from "react";
import * as i18nUtils from "util/i18n";
import { shape, string } from "prop-types";
import { groupFormDataSelectors } from "reducers/administration";
import { connect } from "react-redux";
import MultiSelect from "pages/_components/fields/formik/MultiSelect";
import Image from "pages/_components/Image";
import { Col } from "react-bootstrap";
import Container from "pages/_components/Container";

class GoupMembers extends Component {
    propTypes = {
        idForm: string.isRequired,
        mode: string.isRequired,
        adminUsers: shape({}).isRequired,
        availableUsers: shape({}).isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
    };

    render() {
        const { idForm, availableUsers, mode, adminUsers, location } = this.props;
        const confirmStep1Path =
            location?.pathname === "/administration/advanced/group/create/confirm/step1" ||
            location?.pathname === "/administration/advanced/group/create/confirm/step3";

        return (
            <>
                <Container className={`background-white pb-45 ${!confirmStep1Path && "pt-3"}`}>
                    <Col
                        className={confirmStep1Path ? "px-0" : "d-flex col-12 max-xl-480 px-0"}
                        md={confirmStep1Path ? 12 : 9}
                        lg={confirmStep1Path ? 12 : 6}>
                        <span className={confirmStep1Path ? "text-uppercase" : "group-info-item"}>
                            {i18nUtils.get(`${idForm}.tab.members`)}
                        </span>
                        <span className={confirmStep1Path ? null : "ml-3"}>
                            <MultiSelect
                                name="users"
                                formGroupClassName={confirmStep1Path ? null : "mb-0 group-members"}
                                options={availableUsers}
                                placeholder={`${idForm}.users.placeholder`}
                                mode={mode}
                                labelKey="fullName"
                                valueKey="idUser">
                                {({ idUser, fullName }) => (
                                    <Fragment>
                                        <span className="data-desc">{fullName}</span>
                                        {adminUsers.includes(idUser) && (
                                            <Image src="images/administrator.svg" className="svg-icon svg-caret" />
                                        )}
                                    </Fragment>
                                )}
                            </MultiSelect>
                        </span>
                    </Col>
                </Container>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    availableUsers: groupFormDataSelectors.getAvailableUsers(state),
    adminUsers: groupFormDataSelectors.getAdminUsers(state),
    adminGroup: groupFormDataSelectors.isAdminGroup(state),
});

export default connect(mapStateToProps)(GoupMembers);
