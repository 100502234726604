import React, { Component } from "react";
import { bool, func, string, shape, oneOfType, number } from "prop-types";
import classNames from "classnames";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import FieldError from "pages/_components/fields/FieldError";
import Button from "./Button";

class Checkbox extends Component {
    static propTypes = {
        checked: bool,
        className: string,
        errorClassName: string,
        hideLabel: bool,
        isDisabled: bool,
        multipleSelectionEcheq: bool,
        name: string,
        labelText: string,
        onChange: func,
        onClickLabel: func,
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string]),
        }),
        idForm: string,
        linkLabel: string,
        linkColor: string,
        form: shape({
            touched: shape({}),
            errors: shape({}),
        }),
        labelTextProps: shape({}),
    };

    static defaultProps = {
        className: "",
        errorClassName: "",
        onChange: () => {},
        onClickLabel: null,
        hideLabel: false,
        checked: false,
        isDisabled: false,
        multipleSelectionEcheq: false,
        name: "",
        labelText: "",
        field: null,
        idForm: "",
        form: null,
        linkLabel: "",
        linkColor: "black",
        labelTextProps: {},
    };

    isChecked() {
        const { checked, field, multipleSelectionEcheq } = this.props;

        if (multipleSelectionEcheq) {
            return false;
        }
        return field ? field.value : checked;
    }

    render() {
        const {
            checked,
            className,
            errorClassName,
            onChange,
            onClickLabel,
            name,
            labelText,
            field,
            form,
            idForm,
            labelTextProps,
            hideLabel,
            isDisabled,
            linkLabel,
            linkColor,
        } = this.props;
        const controlName = field ? field.name : name;
        let hasError = false;
        let error = "";
        if (form) {
            const { touched, errors } = form;
            hasError = touched[field.name] && errors[field.name];
            error = errors[field.name];
        }

        return (
            <div
                className={classNames(
                    className,
                    "c-control",
                    "c-control--has-icon",
                    "c-control--checkbox",
                    { "is-disabled": isDisabled },
                    { "has-error": hasError },
                )}>
                <div className="d-flex align-items-center">
                    <input
                        id={`checkbox_${controlName}`}
                        className="c-control-input"
                        type="checkbox"
                        name={controlName}
                        checked={this.isChecked()}
                        onChange={() => {
                            if (field && form) {
                                form.setFieldValue(field.name, !field.value);
                            }
                            onChange(field ? !field.value : !checked);
                        }}
                    />
                    <label className="c-control-label" htmlFor={`checkbox_${controlName}`}>
                        <div className="c-control-icons">
                            <div className="c-control-mark">
                                <Image src="images/check.svg" className="svg-icon svg-caret" />
                            </div>
                        </div>
                        <div className="c-control-text">
                            {!hideLabel && labelText && <I18n id={labelText} {...labelTextProps} />}
                            {!hideLabel && !labelText && field && <I18n id={`${idForm}.${field.name}.label`} />}
                        </div>
                    </label>
                    {onClickLabel ? (
                        <Button
                            label={ linkLabel || `${idForm}.${field.name}.label`}
                            type="button"
                            className={`w-auto btn-asLink color-${linkColor} my-0 ${!hideLabel ? "ml-2" : ""}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onClickLabel();
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>

                {hasError && <FieldError error={error} errorClassName={errorClassName} />}
            </div>
        );
    }
}

export default Checkbox;
