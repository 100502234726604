import React, { Component, Fragment } from "react";
import { number, string, arrayOf, shape, func, bool, oneOf, oneOfType } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

import * as fileMiddleware from "middleware/file";
import { selectors as multilineFileProcessSelectors } from "reducers/formFields/multilineFileProcess";
import {
    actions as multilineFileActions,
    selectors as multilineFileSelectors,
} from "reducers/formFields/multilineFile";
import { selectors as transactionLinesSelectors } from "reducers/form/transactionLines";
import { actions as thirdPaymentActions } from "reducers/thirdPayment";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import FieldError from "pages/_components/fields/FieldError";
import FileEcheqs from "pages/forms/_components/_fields/_echeqmassivefile/FileEcheqs";
import formField from "pages/forms/_components/_fields/_commons/formField";
import Heading from "pages/_components/Heading";
import Col from "react-bootstrap/lib/Col";

import { listItemProps } from "pages/forms/_components/_fields/_transactionlines/ListItem";
import { getNestedObject } from "util/general";

class EcheqMassiveFile extends Component {
    static propTypes = {
        value: arrayOf(
            shape({
                fileId: number.isRequired,
                fileSize: number.isRequired,
                fileName: string.isRequired,
                fileType: string.isRequired,
            }),
        ),
        setValue: func.isRequired,
        fromBackoffice: bool.isRequired,
        editing: bool.isRequired,
        idForm: string.isRequired,
        idField: string.isRequired,
        formTitle: string.isRequired,
        label: string.isRequired,
        maxFileSizeMB: number.isRequired,
        acceptedFileTypes: arrayOf(string).isRequired,
        mode: oneOf(["edit", "view"]).isRequired,
        processedFileData: shape({
            fileIdentifier: string.isOptional,
            fileName: string,
            totalAmount: shape({
                quantity: number,
                currency: string,
            }),
        }).isRequired,
        pendingLines: number.isRequired,
        dispatch: func.isRequired,
        match: shape({
            params: shape({ idForm: string }),
        }).isRequired,
        idTransactionTicket: string.isRequired,
        idRelatedFile: oneOfType([number, string]).isRequired,
        nameRelatedFile: string.isRequired,
        isDesktop: bool,
        detailButtonLabel: string,
        transactionLines: arrayOf(shape(listItemProps)).isRequired,
        idFile: oneOfType([number, string]).isRequired,
        openModal: func.isRequired,
        additionalFields: shape({}),
        msgStatic: bool,
        nestedErrorsObject: bool,
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string]),
        }).isRequired,
        form: shape({
            errors: {},
            touched: {},
        }).isRequired,
        formGroupClassName: string,
        fileUploaded: bool,
        FORM_ID: string.isRequired,
        enabledUpload: bool.isRequired,
        rememberMassiveData: bool.isRequired,
    };

    static defaultProps = {
        value: [],
        isDesktop: true,
        detailButtonLabel: null,
        additionalFields: null,
        msgStatic: false,
        nestedErrorsObject: false,
        formGroupClassName: "",
        fileUploaded: false,
    };

    componentDidMount() {
        const { processedFileData, mode, dispatch, value, idRelatedFile, fileUploaded } = this.props;

        if (fileUploaded && mode === "edit" && processedFileData.hasFile) {
            if (value != null && value.fileId != null) {
                fileMiddleware.deleteFile(parseInt(value.fileId, 10));
            }
            fileMiddleware.deleteFile(parseInt(processedFileData.fileId, 10));
            if (idRelatedFile) {
                fileMiddleware.deleteFile(parseInt(idRelatedFile, 10));
            }

            dispatch(multilineFileActions.onFileRemoved());
        }
    }

    handleClick = () => {
        const { dispatch, match, idTransactionTicket } = this.props;
        const idTransaction = match.params.idTransaction ? match.params.idTransaction : idTransactionTicket;
        dispatch(routerActions.push(`/transaction/${idTransaction}/processDetail`));
    };

    handleDetailsClick = () => {
        const { dispatch, transactionLines, isDesktop, openModal } = this.props;
        if (!isDesktop) {
            dispatch(thirdPaymentActions.downloadTransactionLines("pdf", transactionLines));
        } else {
            openModal();
        }
    };

    renderLinesInfo = (downloadFile) => {
        const { processedFileData } = this.props;
        const { invalidLines, validLines } = processedFileData;
        if (!invalidLines || invalidLines === 0) {
            return validLines;
        }

        return (
            <Fragment>
                <div>
                    {validLines + invalidLines} <I18n id="forms.inputFile.massivePayments.total.lines" />
                </div>
                <div>
                    <I18n id="forms.inputFile.massivePayments.invalid.lines" /> {invalidLines} / {validLines}{" "}
                    <I18n id="forms.inputFile.massivePayments.valid.lines" />
                </div>
                <button
                    type="button"
                    className="btn btn-asLink btn-dl-error"
                    onClick={() => downloadFile({ isDownloadingRelatedFile: true })}>
                    <I18n id="forms.inputFile.massivePayments.download.errors" />
                </button>
                <button
                    type="button"
                    className="btn btn-asLink btn-dl-error"
                    onClick={() => downloadFile({ isDownloadingRelatedFile: true })}>
                    <I18n id="forms.inputFile.massivePayments.download.correct" />
                </button>
            </Fragment>
        );
    };

    renderAmount = () => {
        const { processedFileData } = this.props;
        const { invalidLines, totalAmount } = processedFileData;

        if (!invalidLines) {
            return <FormattedAmount className="data-desc" {...totalAmount} notBold="true" />;
        }

        return (
            <Fragment>
                <FormattedAmount className="data-desc" {...totalAmount} notBold="true" />*
                <div className="detailBox-data-aux">
                    <I18n id="forms.inputFile.massivePayments.calculated.amount.info" />
                </div>
            </Fragment>
        );
    };

    renderData = () => {
        const { processedFileData } = this.props;
        const { validLines, invalidLines } = processedFileData;
        return (
            <div>
                {validLines + invalidLines} <I18n id="forms.inputFile.massivePayments.total.lines" />
            </div>
        );
    };

    render() {
        const {
            additionalFields,
            detailButtonLabel,
            field,
            form,
            formGroupClassName,
            isDesktop,
            mode,
            msgStatic,
            nestedErrorsObject,
            pendingLines,
            processedFileData,
        } = this.props;
        const { totalAmount, validLines, linesWithNoAmount, filesMetadata } = processedFileData;
        const { touched, errors } = form;

        const hasError = nestedErrorsObject
            ? getNestedObject(touched, field.name.split(".")) && getNestedObject(errors, field.name.split("."))
            : touched[field.name] && errors[field.name];

        switch (mode) {
            case "edit": {
                return (
                    <div
                        className={classNames("echeqs-massive__file-container form-group", formGroupClassName, {
                            "has-error": hasError,
                        })}>
                        <FileEcheqs isDesktop={isDesktop} {...this.props} />
                        {hasError && (
                            <FieldError
                                error={
                                    nestedErrorsObject
                                        ? getNestedObject(errors, field.name.split("."))
                                        : errors[field.name]
                                }
                                msgStatic={msgStatic}
                            />
                        )}
                    </div>
                );
            }
            case "preview": {
                return (
                    <>
                        {!!linesWithNoAmount && (
                            <I18n
                                component="div"
                                componentProps={{ className: "alert alert-warning mTop", role: "alert" }}
                                LINE_COUNT={linesWithNoAmount}
                                id="massive.payments.warning.noAmount"
                            />
                        )}
                        {filesMetadata && filesMetadata.fileName && (
                            <Col xs={12} className="px-0">
                                <Heading.DataGroup
                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                    label="forms.inputFile.massivePayments.fileName"
                                    labelClassName="data-label-medium pr-2"
                                    dataClassName="data-desc"
                                    data={filesMetadata.fileName}
                                />
                            </Col>
                        )}
                        <Col xs={12} className="px-0">
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                label="forms.inputFile.massivePayments.lines"
                                labelClassName="data-label-medium pr-2"
                                dataClassName="data-desc"
                                data={validLines}
                            />
                        </Col>
                        <Col xs={12} className="px-0">
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                label="forms.inputFile.massivePayments.totalAmount"
                                labelClassName="data-label-medium pr-2"
                                dataClassName="data-desc"
                                data={<FormattedAmount className="data-desc" {...totalAmount} notBold="true" />}
                            />
                        </Col>

                        {additionalFields}

                        <Col xs={12} className="px-0">
                            <div className="detailBox-row p-0">
                                <Button
                                    className="btn btn-outline"
                                    onClick={this.handleDetailsClick}
                                    label={detailButtonLabel || "forms.inputFile.massivePayments.transaction.detail"}
                                    bsStyle="link"
                                />
                            </div>
                        </Col>
                    </>
                );
            }
            case "view": {
                return (
                    <>
                        {filesMetadata && filesMetadata.fileName && (
                            <Col xs={12} className="px-0">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex mb-1 d-flex space-between"
                                    labelClassName="data-label-medium"
                                    label="forms.inputFile.massivePayments.fileName"
                                    data={filesMetadata.fileName}
                                />
                            </Col>
                        )}

                        <Col xs={12} className="px-0">
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex mb-1 d-flex space-between"
                                labelClassName="data-label-medium"
                                label="forms.inputFile.massivePayments.lines"
                                data={validLines}
                            />
                        </Col>

                        <Col xs={12} className="px-0">
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex mb-1 d-flex space-between"
                                labelClassName="data-label-medium"
                                label="forms.inputFile.massivePayments.totalAmount"
                                data={<FormattedAmount className="data-desc" {...totalAmount} notBold="true" />}
                            />
                        </Col>

                        {additionalFields && additionalFields}

                        {!pendingLines && (
                            <Col xs={12} className="px-0 py-1">
                                <div className="detailBox-row p-0">
                                    <Button
                                        className="btn btn-outline"
                                        onClick={this.handleDetailsClick}
                                        label={
                                            detailButtonLabel || "forms.inputFile.massivePayments.transaction.detail"
                                        }
                                        bsStyle="link"
                                    />
                                </div>
                            </Col>
                        )}
                    </>
                );
            }
            default: {
                return null;
            }
        }
    }
}
const mapStateToProps = (state, ownProps) => ({
    pendingLines: ownProps?.pendingLines || multilineFileProcessSelectors.getPendingLines(state),
    transactionLines: transactionLinesSelectors.getTransactionLines(state),
    processedFileData: ownProps?.processedFileData || multilineFileSelectors.getProcessedFileData(state),
    hasFile: multilineFileSelectors.hasFile(state),
    idRelatedFile: multilineFileSelectors.getIdRelatedFile(state),
    nameRelatedFile: multilineFileSelectors.getNameRelatedFile(state),
    idFile: multilineFileSelectors.getProcessedFileData(state).idFile,
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    formField({
        customLabel: ({ editing }) => (!editing ? true : null),
        isValidValue: (_, { processedFileData }) => {
            const { invalidHeader, invalidFile } = processedFileData;
            return !invalidHeader && !invalidFile;
        },
        pureRender: true,
    }),
)(EcheqMassiveFile);
