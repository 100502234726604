import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as formActions } from "reducers/form";
import { signaturesSchemesSelectors } from "reducers/administration";
import { signaturesSchemesActions } from "reducers/administration/medium";

import SignaturesSchemeCreateDelete from "pages/administration/_components/confirmations/SignaturesSchemeDelete";

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    credentialGroups: signaturesSchemesSelectors.getCredentialGroups(state),
    idTransaction: signaturesSchemesSelectors.getIdTransaction(state),
    idActivity: signaturesSchemesSelectors.getIdActivity(state),
    fetching: signaturesSchemesSelectors.fetching(state),
    functionalGroups: signaturesSchemesSelectors.selectedFunctionalGroups(state),
    signature: signaturesSchemesSelectors.currentSignature(state),
    signatureLevelsCounts: signaturesSchemesSelectors.signatureLevelsCounts(state),
    signatureType: signaturesSchemesSelectors.signatureType(state),
    topAmount: signaturesSchemesSelectors.topAmount(state),
    environmentProducts: signaturesSchemesSelectors.environmentProducts(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(signaturesSchemesActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignaturesSchemeCreateDelete);
