import React, { Component, Fragment } from "react";
import { string, number, bool } from "prop-types";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { selectors as configSelectors } from "reducers/config";
import { selectors as i18nSelectors } from "reducers/i18n";
import * as i18n from "util/i18n";
import { countDecimalPlaces, numberFormat } from "util/number";
import classNames from "classnames";

class FormattedRate extends Component {
    static propTypes = {
        className: string,
        messageAmount: string,
        lang: string.isRequired,
        maximumDecimals: number.isRequired,
        minimumDecimals: number.isRequired,
        maximumDecimalsCustom: number,
        minimumDecimalsCustom: number,
        ml: bool,
        small: bool,
        rate: number,
        showAbbreviature: bool,
        intNumber: bool,
        symbolToTheLeft: bool,
    };

    static defaultProps = {
        className: "data-amount",
        showAbbreviature: false,
        ml: false,
        small: false,
        rate: 0,
        messageAmount: "",
        intNumber: false,
        symbolToTheLeft: true,
        maximumDecimalsCustom: 0,
        minimumDecimalsCustom: 0,
    };

    getAmountDetails = (rate) => {
        const { showAbbreviature } = this.props;
        const million = 1000000;

        if (showAbbreviature) {
            if (rate >= million) {
                return {
                    rate: rate / million,
                    abbreviature: i18n.get("formattedAmount.millionAbbreviature"),
                };
            }
        }

        return {
            rate,
            abbreviature: "",
        };
    };

    renderText = (formattedValue, isNegative) => {
        const { className, messageAmount, small, intNumber, symbolToTheLeft } = this.props;

        const symbolToShow = !intNumber ? "%" : "";
        const textToShow = symbolToTheLeft ? `${symbolToShow}${formattedValue}` : `${formattedValue}${symbolToShow}`;

        return (
            <Fragment>
                <div className={classNames({ red: isNegative }, { "small-font": small }, className)}>
                    {messageAmount && <span>{messageAmount}</span>}
                    {textToShow}
                </div>
            </Fragment>
        );
    };

    render() {
        const {
            rate,
            maximumDecimals,
            minimumDecimals,
            maximumDecimalsCustom,
            minimumDecimalsCustom,
            lang,
            ml,
            intNumber,
        } = this.props;
        const { decimalSeparator, thousandSeparator } = numberFormat(lang);

        const amount = this.getAmountDetails(rate);
        let decimalPlaces;
        let decimalScale;

        if (amount.abbreviature.length > 0) {
            decimalScale = 1;
        } else {
            decimalPlaces = countDecimalPlaces(rate, decimalSeparator);
            const maximumDecimalsToUse = maximumDecimalsCustom || maximumDecimals;
            const minimumDecimalsToUse = minimumDecimalsCustom || minimumDecimals;
            decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimalsToUse), minimumDecimalsToUse);
        }

        const isNegative = parseFloat(amount.rate, 10) < 0;

        const className = classNames({ "ml-2 my-0": ml });

        return (
            <div className={className}>
                <NumberFormat
                    decimalSeparator={decimalSeparator}
                    thousandSeparator={thousandSeparator}
                    decimalScale={decimalScale}
                    value={amount.rate}
                    displayType="text"
                    renderText={(formattedValue) => this.renderText(formattedValue, isNegative)}
                    suffix={amount.abbreviature}
                    fixedDecimalScale={!intNumber}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    maximumDecimals: Number(configSelectors.getConfig(state)["defaultDecimal.maximum"] || 0),
    minimumDecimals: Number(configSelectors.getConfig(state)["defaultDecimal.minimum"] || 0),
    lang: i18nSelectors.getLang(state),
});

export default connect(mapStateToProps)(FormattedRate);
