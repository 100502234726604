/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { goBack } from "react-router-redux";
import { string, bool, shape } from "prop-types";

import Notification from "pages/_components/Notification";

import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as thirdPaymentSelector } from "reducers/thirdPayment";
import { selectors as sessionSelectors } from "reducers/session";

import Col from "react-bootstrap/lib/Col";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";

import * as i18n from "util/i18n";
import { flattenArray, removeDuplicateItems } from "util/array";

class TransactionTicketCancelConfirmation extends Component {
    propTypes = {
        isDesktop: bool,
        dispatch: shape({}).isRequired,
        credentials: string.isRequired,
        isSubmitting: bool.isRequired,
        isLoading: bool.isRequired,
        withOutLoader: bool.isRequired,
        cancelTransactionData: shape({}).isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    centerContentMobile = () => {
        const { cancelTransactionData } = this.props;
        return (
            <div className="title-account-header-multiline-background-blue">
                <h1 style={{ margin: "0.2rem" }}>
                    {cancelTransactionData?.fullTitle || i18n.get(cancelTransactionData?.title)}
                </h1>
            </div>
        );
    };

    render() {
        const { credentials, isSubmitting, isLoading, withOutLoader, cancelTransactionData, isDesktop } = this.props;
        const titleText = cancelTransactionData?.fullTitle || i18n.get(cancelTransactionData?.title);
        if (!isLoading || withOutLoader) {
            return (
                <>
                    <Notification scopeToShow="cancelTransaction" />
                    <div className={isDesktop ? "admin-detail-head px-0" : ""}>
                        <Head
                            onBack={this.handleBack}
                            headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                            centerElement={isDesktop ? undefined : this.centerContentMobile}
                        />
                        {isDesktop && (
                            <div className="view-title">
                                <h1>{titleText.replaceAll("*", "")} </h1>
                            </div>
                        )}
                    </div>
                    <div className={`confirmation__container ${!isDesktop && "container-white"}`}>
                        <Container
                            className="align-items-center flex-grow px-0 container--layout with-margin mb-0"
                            gridClassName="confirmation__form confirmation__box">
                            <div className="admin-content-center" style={{ marginBottom: "2rem" }}>
                                <I18n id="cancel.check.transaction" component="h2" />
                            </div>
                            <Col className="col-12" lg={12} md={12} sm={12}>
                                {cancelTransactionData?.content}

                                <Form>
                                    {credentials.map((credential) => (
                                        <div key={credential}>
                                            <Field
                                                idForm="form.credential"
                                                name={credential}
                                                component={Credential}
                                                type={credential}
                                                placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                                otpTxt={
                                                    <div className="admin-content-center">
                                                        <I18n id="transfer.summary.confirm.label" />
                                                    </div>
                                                }
                                                withoutOtpTxt={
                                                    <div className="admin-content-center">
                                                        <I18n
                                                            id="confirmation.withoutotp.text"
                                                            component="p"
                                                            componentProps={{
                                                                className: "confirmation__text-without-otp",
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            />
                                        </div>
                                    ))}
                                    <div className="d-flex justify-content-center">
                                        <Button
                                            type="submit"
                                            label="global.confirm"
                                            bsStyle="primary"
                                            loading={isSubmitting}
                                            image="images/arrowRight.svg"
                                            imageStyle="mr-2"
                                            className="text-capitalize"
                                        />
                                    </div>
                                </Form>
                            </Col>
                        </Container>
                    </div>
                </>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(formSelectors.getCredentialsGroups(state)),
    isLoading: formSelectors.getFetching(state),
    cancelTransactionData: formSelectors.getCancelTransactionData(state),
    user: sessionSelectors.getUser(state),
    pageToGoBack: thirdPaymentSelector.getPageToGoBack(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ credentials }) =>
            credentials.reduce((values, credential) => ({ ...values, [credential]: "" }), { otp: "" }),
        validationSchema: ({ credentials }) =>
            Yup.object().shape(
                credentials.reduce(
                    (values, credential) => ({
                        ...values,
                        [credential]: Yup.string().required(i18n.get(`form.credential.${credential}.required`)),
                    }),
                    {},
                ),
            ),
        handleSubmit: (credentials, formikBag) => {
            const { dispatch, cancelTransactionData, user } = formikBag.props;
            const cancelType = cancelTransactionData?.title;
            let cancelByMenu = false;
            if (cancelType === "activities.thirdpay.table.menu.decline") {
                cancelByMenu = true;
            }

            dispatch(
                formActions.cancelTransaction({
                    credentials,
                    formikBag,
                    idTransaction: cancelTransactionData?.idTransaction,
                    userId: user.userId,
                    rejectedTransactions: cancelByMenu,
                    force: cancelTransactionData?.force,
                }),
            );
        },
    }),
)(TransactionTicketCancelConfirmation);
