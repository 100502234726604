import React, { Component } from "react";
import Yup from "yup";
import { shape, func, bool, number as num } from "prop-types";
import { push } from "react-router-redux";
import classNames from "classnames";
import Col from "react-bootstrap/lib/Col";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as accountsSelectors, actions as accountsActions } from "reducers/accounts";
import Head from "pages/_components/Head";
import { Field, Form, withFormik } from "formik";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import { DateField } from "pages/_components/fields/DateField";
import * as i18nUtils from "util/i18n";
import { compose } from "redux";
import { connect } from "react-redux";
import PageLoading from "pages/_components/PageLoading";
import I18n from "pages/_components/I18n";
import Row from "react-bootstrap/lib/Row";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as dateTimeFormatter from "date-fns";
import * as configUtils from "util/config";
import { maskedAccountNumber, productTypes } from "util/accounts";
import moment from "moment";
import Pagination from "pages/_components/pagination/Pagination";
import { getLimitDate } from "util/date";
import Notification from "pages/_components/Notification";

const FORM_ID = "accounts.historic.balance.period";

class HistoricBalance extends Component {
    static propTypes = {
        loggedUser: shape({}).isRequired,
        selectedAccount: shape({}).isRequired,
        dateFrom: shape({}).isRequired,
        dateTo: shape({}).isRequired,
        dispatch: func.isRequired,
        fetching: bool,
        fetchingDownload: bool,
        fetchingBalance: bool,
        historicBalances: shape([]).isRequired,
        historicBalancesTotalPages: num.isRequired,
        historicBalancesPageNumber: num.isRequired,
        isDesktop: bool.isRequired,
        setValues: func.isRequired,
        values: shape({}),
    };

    static defaultProps = {
        fetching: true,
        fetchingBalance: false,
        fetchingDownload: false,
        values: {},
    };

    componentDidMount = () => {
        const { selectedAccount, dispatch, isDesktop, ...rest } = this.props;
        if (!isDesktop) {
            const numberPage = 1;
            const dateFrom = this.getMinDateFrom();
            const dateTo = new Date();
            dispatch(accountsActions.historicBalance({ dateFrom, dateTo, selectedAccount, numberPage }));
        }
        if (!selectedAccount) {
            dispatch(push(`/accounts/${rest.match.params.id}`));
        }
    };

    getDateFromDate = (date) => `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

    getDateFromString = (str) => {
        const date = str.substring(0, str.indexOf("T"));
        return moment(date).format("DD/MM/YYYY");
    };

    handleBack = () => {
        const { dispatch, ...rest } = this.props;
        dispatch(push(`/accounts/${rest.match.params.id}`));
    };

    getNextPage = (numberPage) => {
        const { dispatch, selectedAccount, dateFrom, dateTo } = this.props;
        dispatch(accountsActions.historicBalance({ dateFrom, dateTo, selectedAccount, numberPage }));
    };

    handleExport = (format) => {
        const { values, dispatch, selectedAccount } = this.props;
        const { dateFrom: dateFromProps, dateTo: dateToProps } = values;
        const dateFrom = dateFromProps || this.getMinDateFrom();
        const dateTo = dateToProps || new Date();

        dispatch(accountsActions.downloadHistoricBalance(selectedAccount.idProduct, format, dateFrom, dateTo));
    };

    renderTable = () => {
        const { historicBalances, selectedAccount, isDesktop } = this.props;
        const { currency } = selectedAccount;

        const list = historicBalances.map(({ date, balance }) => {
            if (isDesktop) {
                return (
                    <Row className="account-table-wrapper">
                        <Col sm={12} md={3} lg={5} className="align-self-center text-align-left">
                            <div className="data-text">{this.getDateFromString(date)}</div>
                        </Col>
                        <Col sm={12} md={3} lg={3} className="align-self-center text-align-center">
                            <FormattedAmount currency={currency} className="data-amount" quantity={balance} notBold />
                        </Col>
                        {isDesktop && (
                            <React.Fragment>
                                <Col sm={12} md={3} lg={2} />
                                <Col sm={12} md={3} lg={2} />
                            </React.Fragment>
                        )}
                    </Row>
                );
            }
            return (
                <Row className="account-table-wrapper">
                    <Col sm={12} md={2} className="col col-6 align-self-center text-align-left">
                        <div className="data-text">{this.getDateFromString(date)}</div>
                    </Col>
                    <Col
                        sm={12}
                        md={2}
                        className="col col-6 align-self-center text-align-center"
                        style={{ flexDirection: "column" }}>
                        <div className="align-self-end text-align-right account-table-header">
                            <I18n id="accounts.table.header.balance" />
                        </div>
                        <div className="align-self-end text-align-right">
                            <FormattedAmount
                                currency={currency}
                                className="data-amount"
                                quantity={balance}
                                notBold
                                small
                            />
                        </div>
                    </Col>
                </Row>
            );
        });

        const date = new Date();
        const FORMATTED_DATE = dateTimeFormatter.format(
            date,
            configUtils.get("frontend.shortDateFormat").toUpperCase(),
        );
        const FORMATTED_HOUR = dateTimeFormatter.format(date, configUtils.get("frontend.timeFormat"));

        return (
            <div style={isDesktop ? { marginRight: "1rem" } : {}}>
                {isDesktop && (
                    <Row className="account-table-wrapper account-table-header">
                        <Col sm={12} md={3} lg={5} className="align-self-center text-align-left">
                            <I18n id="accounts.table.header.date" />
                        </Col>
                        <Col sm={12} md={3} lg={3} className="align-self-center text-align-center">
                            <I18n id="accounts.table.header.balance" />
                        </Col>

                        <Col
                            sm={12}
                            md={3}
                            lg={2}
                            className="align-self-center text-align-right account-table-header-small-font p-2 ">
                            <span>
                                <I18n id="accounts.table.header.queryDate" />
                                {": "}
                                <span style={{ fontWeight: 300 }}>{FORMATTED_DATE}</span>
                            </span>
                        </Col>

                        <Col
                            sm={12}
                            md={3}
                            lg={2}
                            className="align-self-center text-align-right account-table-header-small-font p-2">
                            <span>
                                <I18n id="accounts.table.header.queryHour" />
                                {": "}
                                <span style={{ fontWeight: 300 }}>{FORMATTED_HOUR}</span>
                            </span>
                        </Col>
                    </Row>
                )}
                <div>{list}</div>
            </div>
        );
    };

    getMinDateFrom = () => {
        const minDateFrom = configUtils.getInteger("accounts.max.dateFrom", 3);
        const date = new Date();
        date.setMonth(date.getMonth() - minDateFrom);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    };

    centerContentMobile = () => {
        const { selectedAccount } = this.props;

        const title = i18nUtils.get("historic.balance.title.mobile");

        return (
            <div className="title-account-header-multiline-background-blue">
                <h1 className="w-100 m-0 px-2 text-capitalize">{title}</h1>
                <p className="my-0">
                    {`${i18nUtils
                        .get(`accounts.productType.${productTypes[selectedAccount.productType]}`)
                        .toUpperCase()}  Nº ${maskedAccountNumber(selectedAccount?.number)}`}
                </p>
            </div>
        );
    };

    centerContent = () => {
        const { selectedAccount } = this.props;
        return (
            <>
                <h1 className="m-0">
                    {i18nUtils.get("historic.balance.title")}{" "}
                    {selectedAccount.productAlias
                        ? selectedAccount.productAlias
                        : maskedAccountNumber(selectedAccount?.number)}
                </h1>
                <span>
                    <p className="account-display-font d-block">
                        {`${i18nUtils
                            .get(`accounts.productType.${productTypes[selectedAccount.productType]}`)
                            .toUpperCase()} Nº ${selectedAccount && maskedAccountNumber(selectedAccount?.number)}`}
                    </p>
                </span>
            </>
        );
    };

    render() {
        const {
            selectedAccount,
            dispatch,
            loggedUser,
            fetchingDownload,
            fetchingBalance,
            historicBalances,
            fetching,
            historicBalancesTotalPages,
            historicBalancesPageNumber,
            isDesktop,
            values: { dateFrom, dateTo },
            setValues,
            ...rest
        } = this.props;
        if (selectedAccount) {
            if (selectedAccount === null) {
                dispatch(accountsActions.details(rest.match.params.id, loggedUser.userId));
            }

            const buttonDisabled = !dateFrom || !dateTo || dateFrom > dateTo;

            return (
                <PageLoading loading={fetching}>
                    <Notification scopeToShow="historicbalance" />
                    {selectedAccount && (
                        <>
                            <div className={isDesktop ? "admin-detail-head px-0" : ""}>
                                <Head
                                    onBack={this.handleBack}
                                    headerClassName={!isDesktop && "blue-main-header-mobile multiline-title-head"}
                                    centerElement={!isDesktop && this.centerContentMobile}
                                    centerContentClassName="mx-5"
                                    exportList={isDesktop ? undefined : true}
                                    handleClick={this.handleExport}
                                    downloadImageWhite={isDesktop ? undefined : true}
                                    hideNavbarInMobile
                                    isFetchingExport={false}
                                />
                                {isDesktop && (
                                    <Head
                                        exportList
                                        handleClick={this.handleExport}
                                        handleClickMessage="accounts.page.historicBalance.download"
                                        isFetchingExport={fetchingDownload}
                                        headerClassName="historic-balance-header-desktop"
                                        hasInlineButtons
                                        csvDownload
                                        exportDisabled={!historicBalances || historicBalances.length <= 0}
                                        centerElement={isDesktop && this.centerContent}
                                        centerContentClassName="flex-column align-items-start"
                                    />
                                )}
                            </div>
                            {isDesktop && (
                                <Form autoComplete="off">
                                    <Container
                                        className="container--layout align-items-center
                                    align-items-center container-white"
                                        gridClassName="form-content historic-balance-search">
                                        <Col sm={12} md={3} xl={2} lg={3} className="col col-12 col-no-pad-mobile">
                                            <Field
                                                component={DateField}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="dateFrom"
                                                selectsStart
                                                minDate={this.getMinDateFrom()}
                                                maxDate={dateTo || getLimitDate(0)}
                                                handleChange={this.handleChangeDateFrom}
                                                autocomplete="off"
                                            />
                                        </Col>
                                        <Col sm={12} md={3} lg={3} xl={2} className="col col-12 col-no-pad-mobile">
                                            <Field
                                                component={DateField}
                                                disabled={!dateFrom}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="dateTo"
                                                selectsEnd
                                                minDate={dateFrom || this.getMinDateFrom()}
                                                maxDate={getLimitDate(0)}
                                                autocomplete="off"
                                            />
                                        </Col>
                                        <Col
                                            sm={12}
                                            md={4}
                                            lg={2}
                                            className="col col-12 col-no-pad-mobile"
                                            style={{
                                                marginBottom: "0.5em",
                                            }}>
                                            <Button
                                                bsStyle="primary"
                                                label="product.filters.filter"
                                                loading={fetchingBalance}
                                                type="submit"
                                                className="cmf-button-width"
                                                disabled={buttonDisabled}
                                            />
                                        </Col>
                                    </Container>
                                </Form>
                            )}

                            <div className="above-the-fold mh-auto">
                                {historicBalances && this.renderTable()}
                                {historicBalances && (
                                    <div className="d-flex">
                                        <div
                                            className={classNames("d-flex w-100 justify-content-end mt-3", {
                                                "mr-3": !isDesktop,
                                            })}>
                                            <Pagination
                                                totalPages={historicBalancesTotalPages}
                                                pageNumber={historicBalancesPageNumber}
                                                action={this.getNextPage}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </PageLoading>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    selectedAccount: accountsSelectors.getSelectedAccount(state),
    fetching: accountsSelectors.getFetching(state),
    historicBalances: accountsSelectors.getHistoricBalances(state),
    historicBalancesTotalPages: accountsSelectors.getHistoricBalancesTotalPages(state),
    historicBalancesPageNumber: accountsSelectors.getHistoricBalancesPageNumber(state),
    fetchingBalance: accountsSelectors.getFetchingBalance(state),
    dateTo: accountsSelectors.getHistoricBalanceDateTo(state),
    dateFrom: accountsSelectors.getHistoricBalanceDateFrom(state),
    fetchingDownload: accountsSelectors.getFetchingDownload(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            dateFrom: null,
            dateTo: null,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18nUtils.get(`${FORM_ID}.dateFrom.error`))
                              .required(i18nUtils.get(`${FORM_ID}.dateFrom.required`))
                        : Yup.date().nullable(),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18nUtils.get(`${FORM_ID}.dateTo.error`))
                              .required(i18nUtils.get(`${FORM_ID}.dateTo.required`))
                        : Yup.date().nullable(),
                }),
            ),
        handleSubmit: ({ dateFrom, dateTo }, formikBag) => {
            const { dispatch, selectedAccount } = formikBag.props;
            const numberPage = 1;

            dispatch(accountsActions.historicBalance({ dateFrom, dateTo, selectedAccount, numberPage }));
        },
    }),
)(HistoricBalance);
