import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, func, bool } from "prop-types";

import { selectors as ecomexSelectors, actions as ecomexActions } from "reducers/ecomex";

import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Notification from "pages/_components/Notification";
import ProductDetail from "pages/_components/ProductDetail";
import PageLoading from "pages/_components/PageLoading";
import I18n from "pages/_components/I18n";
import InfoTag from "pages/_components/InfoTag";
import Table from "pages/_components/Table";
import Image from "pages/_components/Image";

import * as i18nUtils from "util/i18n";
import { string } from "yup";

import EcomexInformativeRegimeDetailList from "pages/ecomex/_components/EcomexInformativeRegimeDetailList";
import classNames from "classnames";

const FORM_ID = "ecomex.informativeRegime.detail";

class EcomexInformativeRegimeDetail extends Component {
    static propTypes = {
        advanceExportPaymentsDetail: shape({}).isRequired,
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        optionSelected: string.isRequired,
        selectedInformativeRegime: shape({}).isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(ecomexActions.closeDetailInformativeRegime());
    };

    renderContent = () => {
        const { optionSelected } = this.props;

        return (
            <>
                {optionSelected === "1" && this.renderContentImports()}
                {optionSelected === "2" && this.renderContentExports()}
                {optionSelected === "3" && this.renderContentDispatcher()}
                {optionSelected === "4" && this.renderContentBoardingPermits()}
            </>
        );
    };

    renderElements = (elements, customSeperation) => {
        const { isDesktop, selectedInformativeRegime, optionSelected } = this.props;

        const separation = customSeperation || 3;
        const className = "align-items-center flex-grow container-white with-margin py-2";
        const { estado } = selectedInformativeRegime;

        if (isDesktop) {
            return (
                <Container className={className}>
                    {elements.map((item) => (
                        <Col sm={12} md={separation} lg={separation} className="col col-12">
                            {item.label && (
                                <div className="data-wrapper">
                                    <I18n
                                        componentProps={{ className: "data-label" }}
                                        id={`${FORM_ID}.${item.label}`}
                                    />
                                    <span className="font-light">{item.value !== "" ? item.value : "-"}</span>
                                </div>
                            )}
                        </Col>
                    ))}
                </Container>
            );
        }

        return (
            <>
                <div className="align-items-center d-inline-flex w-100 space-between py-1 px-3">
                    <I18n
                        id={`ecomex.informativeRegime.${optionSelected}.label`}
                        componentProps={{ className: "text-uppercase" }}
                    />
                    <div>
                        <InfoTag
                            type="default"
                            message={estado}
                            tagBackground="#dddddd"
                            color="black"
                            tagClass="mx-0"
                        />
                    </div>
                </div>
                <Container className={className}>
                    {elements.map((item) => (
                        <Col sm={12} md={separation} lg={separation} className="col col-12">
                            {item.label && (
                                <div className="d-flex space-between">
                                    <I18n
                                        componentProps={{ className: "data-label" }}
                                        id={`${FORM_ID}.${item.label}`}
                                    />
                                    <span className="text-right font-light max-width-50 pl-1">
                                        {item.value !== "" ? item.value : "-"}
                                    </span>
                                </div>
                            )}
                        </Col>
                    ))}
                </Container>
            </>
        );
    };

    renderContentImports = () => {
        const { selectedInformativeRegime } = this.props;
        const {
            codigoConceptoBCRA,
            fechaPago,
            referenciaMSD,
            fechaVencimientoOriginal,
            diasProrroga,
            fechaVencimientoConProrroga,
            nombreBeneficiario,
            siglaDivisa,
            importe,
            aplicado,
            saldo,
        } = selectedInformativeRegime;

        const selectedInformativeRegimeForRender = [
            { value: codigoConceptoBCRA, label: "bcra" },
            { value: fechaPago, label: "payDate" },
            { value: referenciaMSD, label: "msdReference" },
            { value: null, label: "" },
            { value: fechaVencimientoOriginal, label: "dueDateOriginal" },
            { value: diasProrroga, label: "extensionDays" },
            { value: fechaVencimientoConProrroga, label: "dueDateExtension" },
            { value: nombreBeneficiario, label: "beneficiaryName" },
            { value: siglaDivisa, label: "currency" },
            { value: importe, label: "amount" },
            { value: aplicado, label: "aplicAmount" },
            { value: saldo, label: "balance" },
        ];

        return this.renderElements(selectedInformativeRegimeForRender);
    };

    renderContentExports = () => {
        const { advanceExportPaymentsDetail } = this.props;
        const {
            nroBol,
            fechaLiquidacion,
            paisBeneficiario,
            nombreBeneficiario,
            tipoOperacion,
            tipoOperacionOrigen,
            fechaPreFinanciacionOriginal,
            fechaMaxEmbarque,
            siglaDivisa,
            importeOperacion,
            saldo,
            tasa,
        } = advanceExportPaymentsDetail;

        const tipoOperacionOrigenFullName = i18nUtils.get(`${FORM_ID}.operationType.${tipoOperacionOrigen}`);

        const advanceExportPaymentsDetailForRender = [
            { value: nroBol, label: "nroBol" },
            { value: fechaLiquidacion, label: "settlementDate" },
            { value: paisBeneficiario, label: "beneficiaryCountry" },
            { value: nombreBeneficiario, label: "beneficiary" },
            { value: tipoOperacionOrigenFullName, label: "operationType" },
            { value: tipoOperacion, label: "operationTypeOrigen" },
            { value: fechaPreFinanciacionOriginal, label: "preFinancingOriginalDate" },
            { value: fechaMaxEmbarque, label: "maxEmbDate" },
            { value: siglaDivisa, label: "currency" },
            { value: importeOperacion, label: "amount" },
            { value: saldo, label: "balance" },
            { value: tasa, label: "rate" },
        ];

        return this.renderElements(advanceExportPaymentsDetailForRender);
    };

    renderLists = (listToRender) => {
        const { isDesktop } = this.props;
        return (
            <>
                {listToRender && listToRender.length > 0 ? (
                    <Container className="flex-grow scrollable">
                        <Col md={12} className="table-with-border px-0">
                            <Table className={!isDesktop && "mb-0"}>
                                <Table.Header className="container-white table-row-border-bottom align-items-center head-row-mobile-height">
                                    <Table.HeaderData />
                                    <Table.HeaderData align="right">
                                        <I18n id={`${FORM_ID}.dispatches.column.currency`} />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="right">
                                        <I18n id={`${FORM_ID}.dispatches.column.amount`} />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="right">
                                        <I18n id={`${FORM_ID}.dispatches.column.aplicAmount`} />
                                    </Table.HeaderData>
                                    <Table.HeaderData align="right">
                                        <I18n id={`${FORM_ID}.dispatches.column.balance`} />
                                    </Table.HeaderData>
                                </Table.Header>
                                <Table.Body>
                                    {listToRender.map((item) => this.renderListsItemForDispatcher(item))}
                                </Table.Body>
                            </Table>
                        </Col>
                    </Container>
                ) : (
                    <div className="text-center no-more-data">
                        <div className="illustration-wrapper">
                            <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                        </div>
                        <p className="text-lead">
                            <I18n id={`${FORM_ID}.empty.message`} />
                        </p>
                    </div>
                )}
            </>
        );
    };

    renderListsItemForDispatcher = (item) => {
        const { isDesktop } = this.props;
        const { label, currency, amount, aplicAmount, balance } = item;

        if (item.label === i18nUtils.get("ecomex.informativeRegime.detail.dispatches.row.totalBalance")) {
            return (
                <Table.Row className="container-white table-row-border-top align-items-center footer-row-mobile-height">
                    <Table.Data align="left">
                        <div className="data-text font-regular">{label}</div>
                    </Table.Data>
                    {isDesktop && (
                        <>
                            <Table.Data />
                            <Table.Data />
                            <Table.Data />
                        </>
                    )}
                    <Table.Data align="right" className="flex">
                        <div className="data-text">{balance || "-"}</div>
                    </Table.Data>
                </Table.Row>
            );
        }

        return (
            <Table.Row
                className={classNames("container-white", { "align-items-center row-mobile-height": !isDesktop })}>
                <Table.Data align="left">
                    <div className="data-text font-regular">{label}</div>
                </Table.Data>
                <Table.Data align="right">
                    <div className="data-text">{currency || "-"}</div>
                </Table.Data>
                <Table.Data align="right">
                    <div className="data-text">{amount || "-"}</div>
                </Table.Data>
                <Table.Data align="right">
                    <div className="data-text">{aplicAmount || "-"}</div>
                </Table.Data>
                <Table.Data align="right">
                    <div className="data-text">{balance || "-"}</div>
                </Table.Data>
            </Table.Row>
        );
    };

    renderContentDispatcher = () => {
        const { selectedInformativeRegime } = this.props;

        const {
            condicionVenta,
            fechaEmbarque,
            fechaOficializacion,
            vendedor,
            documTrans,
            viaTrans,
            entidadRecibe,
            entidadDesignada,
            nominado,
            siglaDivisaFOB,
            importeFOB,
            aplicadoFOB,
            saldoFOB,
            siglaDivisaFLETE,
            importeFLETE,
            aplicadoFLETE,
            saldoFLETE,
            siglaDivisaSEGURO,
            importeSEGURO,
            aplicadoSEGURO,
            saldoSEGURO,
            saldoTotalFOB,
        } = selectedInformativeRegime;

        const dispatcherForRender = [
            { value: condicionVenta, label: "cSells" },
            { value: fechaEmbarque, label: "embDate" },
            { value: fechaOficializacion, label: "officialDate" },
            { value: vendedor, label: "sellsman" },
            { value: documTrans, label: "docTransport" },
            { value: viaTrans, label: "viaTransport" },
            { value: entidadRecibe, label: "eTransferor" },
            { value: entidadDesignada, label: "eRecCession" },
            { value: nominado, label: "nominated" },
        ];

        const dispatcherForRenderList = [
            {
                label: i18nUtils.get("ecomex.informativeRegime.detail.dispatches.row.fob"),
                currency: siglaDivisaFOB,
                amount: importeFOB,
                aplicAmount: aplicadoFOB,
                balance: saldoFOB,
            },
            {
                label: i18nUtils.get("ecomex.informativeRegime.detail.dispatches.row.flete"),
                currency: siglaDivisaFLETE,
                amount: importeFLETE,
                aplicAmount: aplicadoFLETE,
                balance: saldoFLETE,
            },
            {
                label: i18nUtils.get("ecomex.informativeRegime.detail.dispatches.row.seguro"),
                currency: siglaDivisaSEGURO,
                amount: importeSEGURO,
                aplicAmount: aplicadoSEGURO,
                balance: saldoSEGURO,
            },
            {
                label: i18nUtils.get("ecomex.informativeRegime.detail.dispatches.row.totalBalance"),
                currency: "",
                amount: "",
                aplicAmount: "",
                balance: saldoTotalFOB,
            },
        ];

        return (
            <>
                {this.renderElements(dispatcherForRender, 4)}
                {this.renderLists(dispatcherForRenderList)}
            </>
        );
    };

    renderContentBoardingPermits = () => {
        const { selectedInformativeRegime } = this.props;

        const {
            condicionVenta,
            siglaDivisa,
            saldoTOTAL,
            importeFOB,
            importeFLETE,
            importeSEGURO,
            importeAplicado,
            fechaVencimiento,
            fechaVencimientoEspecial,
            fechaCumpEmb,
            fechaOficializacion,
            numeroCumplido,
            numeroIncumplido,
            fechaConfirmacionCumplido,
            fechaConfirmacionIncumplido,
        } = selectedInformativeRegime;

        const boardingPermitsForRender = [
            { value: condicionVenta, label: "cSells" },
            { value: null, label: "" },
            { value: siglaDivisa, label: "currency" },
            { value: saldoTOTAL, label: "totalBalance" },
            { value: importeFOB, label: "amountFOB" },
            { value: importeFLETE, label: "amountFLETE" },
            { value: importeSEGURO, label: "amountSEGURO" },
            { value: importeAplicado, label: "aplicAmount" },
            { value: fechaVencimiento, label: "dueDate" },
            { value: fechaVencimientoEspecial, label: "dueDateSpecial" },
            { value: fechaCumpEmb, label: "cumpEmbDate" },
            { value: fechaOficializacion, label: "officialDate" },
            { value: numeroCumplido, label: "complimentNumber" },
            { value: fechaConfirmacionCumplido, label: "complimentDate" },
            { value: numeroIncumplido, label: "incomplimentNumber" },
            { value: fechaConfirmacionIncumplido, label: "incomplimentDate" },
        ];

        return <>{this.renderElements(boardingPermitsForRender)}</>;
    };

    renderHeader = () => {
        const { advanceExportPaymentsDetail, isDesktop, optionSelected, selectedInformativeRegime } = this.props;
        const { estado } = selectedInformativeRegime;

        let refToShow;
        if (optionSelected === "1") {
            const { referenciaOperacion } = selectedInformativeRegime;
            refToShow = [
                <span>
                    {i18nUtils.get("ecomex.informativeRegime.detail.refOperation")} {referenciaOperacion}
                </span>,
            ];
        } else if (optionSelected === "2") {
            const { refOp } = advanceExportPaymentsDetail;
            refToShow = [
                <span>
                    {i18nUtils.get("ecomex.informativeRegime.detail.refOperation")} {refOp}
                </span>,
            ];
        } else if (optionSelected === "3" || optionSelected === "4") {
            const { destinacion } = selectedInformativeRegime;
            refToShow = `${i18nUtils.get("ecomex.informativeRegime.detail.destiny")} ${destinacion}`;
        }
        return (
            <>
                {isDesktop ? (
                    <>
                        <Col sm={12} md={9} lg={8} className="col col 12 pl-0">
                            <div className="d-inline-flex align-items-center">
                                <h1 className="mr-2">{refToShow}</h1>
                                <div>
                                    <InfoTag
                                        type="default"
                                        message={estado}
                                        tagBackground="#dddddd"
                                        color="black"
                                        tagClass=""
                                    />
                                </div>
                            </div>
                            <div className="mt-1">
                                {i18nUtils.get(`ecomex.informativeRegime.${optionSelected}.label`).toUpperCase()}
                            </div>
                        </Col>
                    </>
                ) : (
                    <>
                        <div className="view-title mb-2 pl-0 pr-45">
                            <h1 className="title-content header-mobile-title-background-blue">{refToShow}</h1>
                        </div>
                    </>
                )}
            </>
        );
    };

    renderDetail = () => {
        const { dispatch, fetching, isDesktop, optionSelected, selectedInformativeRegime } = this.props;

        let subsectionTitle;

        switch (optionSelected) {
            case "1":
                subsectionTitle = `${FORM_ID}.talks.title`;
                break;
            case "2":
                subsectionTitle = `${FORM_ID}.impacts.title`;
                break;
            case "3":
                subsectionTitle = `${FORM_ID}.talks.title`;
                break;
            case "4":
                subsectionTitle = `${FORM_ID}.talks.title`;
                break;
            default:
                break;
        }

        return (
            <PageLoading loading={fetching}>
                {!fetching && selectedInformativeRegime && (
                    <>
                        <Notification scopeToShow="ecomexInformativeRegime/details" />
                        <ProductDetail>
                            <ProductDetail.Head
                                onBack={this.handleBack}
                                dispatch={dispatch}
                                renderDownload={() => {}}
                                title="ecomex.consultOperations.detail.title">
                                {isDesktop && <div />}
                                {this.renderHeader()}
                            </ProductDetail.Head>
                            <ProductDetail.Body isDesktop={isDesktop} moreFilters={false}>
                                {this.renderContent()}

                                <I18n component="h4" componentProps={{ className: "ml-3" }} id={subsectionTitle} />

                                <EcomexInformativeRegimeDetailList isDesktop={isDesktop} />
                            </ProductDetail.Body>
                        </ProductDetail>
                    </>
                )}
            </PageLoading>
        );
    };

    render() {
        return <>{this.renderDetail()}</>;
    }
}

const mapStateToProps = (state) => ({
    fetching: ecomexSelectors.getFetching(state),
    selectedInformativeRegime: ecomexSelectors.getSelectedInformativeRegime(state),
    advanceExportPaymentsDetail: ecomexSelectors.getAdvanceExportPaymentsDetail(state),
    optionSelected: ecomexSelectors.getOptionSelected(state),
});

export default connect(mapStateToProps)(EcomexInformativeRegimeDetail);
