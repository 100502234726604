import React, { Children } from "react";
import { string, shape, bool } from "prop-types";
import DropdownMenuSection from "pages/_components/DropdownMenuSection";
import classNames from "classnames";

const MenuSection = ({ children, permissions, image, titleKey, isSelected, isSmallDesktop, isCollapsed }) => (
    <div className={classNames("toggle-menu-button-container", { "menu-section-selected": isSelected })}>
        <DropdownMenuSection
            label={titleKey}
            image={image}
            buttonClass="toggle-menu-button"
            isSmallDesktop={isSmallDesktop}
            isCollapsed={isCollapsed}>
            {Children.map(children, (child) => child && React.cloneElement(child, { permissions }))}
        </DropdownMenuSection>
    </div>
);

MenuSection.propTypes = {
    titleKey: string,
    image: string,
    isSelected: bool,
    isSmallDesktop: bool.isRequired,
    isCollapsed: bool.isRequired,
    children: shape({}).isRequired,
    permissions: shape({}),
};

MenuSection.defaultProps = {
    titleKey: "",
    image: "",
    isSelected: false,
    permissions: null,
};

export default MenuSection;
