import React, { Component } from "react";
import { node } from "prop-types";

class TableFooter extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    render() {
        const { children } = this.props;
        return <div className="table-footer">{children}</div>;
    }
}

export default TableFooter;
