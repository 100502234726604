import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { bool, func, shape } from "prop-types";

import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";

import Step4Content from "pages/login/_components/Step4Content";
import MainContainer from "pages/_components/MainContainer";
import { push } from "react-router-redux/actions";
import Button from "pages/_components/Button";
import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";

class LoginStep4 extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        termsAndConditions: bool.isRequired,
        propsGrid: shape({}).isRequired,
    };

    componentDidMount() {
        const { dispatch, termsAndConditions } = this.props;
        if (!termsAndConditions) {
            dispatch(push("/desktop"));
        }
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    downloadTerms = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.downloadDocumentRequest({ format: "pdf" }));
    };

    render() {
        const { isDesktop, propsGrid } = this.props;

        return (
            <GridLayoutExternal scopeToShow="loginStep4" isDesktop={isDesktop} {...propsGrid}>
                <div className="flex-container justify-content-end">
                    <Col>
                        <Button
                            style={!isDesktop ? { "margin-top": "2rem" } : {}}
                            onClick={this.downloadTerms}
                            image="images/download.svg"
                            bsStyle="link"
                            label={isDesktop && "global.download"}
                            imageStyle="mr-2"
                        />
                    </Col>
                    {!isDesktop && (
                        <Col>
                            <Button
                                className="view-close"
                                onClick={this.onHeaderClose}
                                style={!isDesktop ? { "margin-top": "2rem" } : {}}
                                image="images/cross-mobile-blue.svg"
                                bsStyle="link"
                                label={isDesktop && "global.download"}
                            />
                        </Col>
                    )}
                </div>

                <MainContainer
                    className={classNames({ "main-container": isDesktop }, { "container-fluid": isDesktop })}>
                    <I18n
                        id="login.step4.termsAndCondiciones.label"
                        accessibilityTextId="login.step4.termsAndCondiciones.label.a11yss"
                        component="h2"
                        componentProps={{ className: "modal-header my-0" }}
                    />
                    <Step4Content isDesktop={isDesktop} />
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}
const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    termsAndConditions: loginSelectors.getTermsAndConditions(state),
});
export default connect(mapStateToProps)(LoginStep4);
