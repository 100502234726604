import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";
import { routerActions } from "react-router-redux";
import classNames from "classnames";

import { DOCUMENT_TYPES } from "constants.js";
import * as i18nUtils from "util/i18n";
import { checksDetailStatusTagColor, checksDetailGetCodeMsg } from "util/checks";
import { getDateFromStrDate } from "util/date";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import InfoTag from "pages/_components/InfoTag";
import MainContainer from "pages/_components/MainContainer";
import { selectors as checksSelectors, actions as checksActions } from "reducers/checks";
import ContextMenu from "pages/_components/ContextMenu";
import Container from "pages/_components/Container";
import ChecksDetailViewEmitted from "./Echeqs/Preview/ChecksDetailViewEmitted";
import RowDetailInfo from "./_components/RowDetailInfo";
import ChecksDetailViewReceived from "./Echeqs/Preview/ChecksDetailViewReceived";

class ChecksDetailInfo extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        selectedCheckDetail: shape({}).isRequired,
        isEmittedSelected: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {};

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push("/checksDetail"));
    };

    getTag = () => {
        const { selectedCheckDetail } = this.props;

        return (
            <InfoTag
                type="info"
                message={checksDetailGetCodeMsg(selectedCheckDetail.descripcionEstadoCheque)}
                tagBackground={checksDetailStatusTagColor(selectedCheckDetail.descripcionEstadoCheque)}
                moreStyles={{
                    alignSelf: "flex-end",
                    width: "fit-content",
                    color: "black",
                }}
            />
        );
    };

    getCheckDetailTitle = () => {
        const { selectedCheckDetail, isDesktop } = this.props;
        if (!selectedCheckDetail) {
            return <></>;
        }

        const name = `${i18nUtils.get("checksDetail.info.header.title")} ${selectedCheckDetail.numeroCheque}`;

        return (
            <>
                <div className={classNames("view-title data-wrapper-flex", { "justify-content-center": !isDesktop })}>
                    <h1 className={classNames({ "mr-2 my-0": isDesktop })}>{name}</h1>
                    {isDesktop && <div className="ml-15 mt-1">{this.getTag()}</div>}
                </div>
            </>
        );
    };

    handleExportMobile = (format) => {
        const { dispatch, selectedCheckDetail, isEmittedSelected } = this.props;
        dispatch(checksActions.downloadCheckMobileInfo(format, selectedCheckDetail, isEmittedSelected));
    };

    rightContent = () => {
        const { isDesktop } = this.props;

        const options = [
            {
                label: "global.download",
                onClick: () => this.handleExportMobile("pdf"),
            },
        ];

        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                items={options}
            />
        );
    };

    renderHeader() {
        const { isDesktop } = this.props;

        if (isDesktop) {
            return (
                <div className="admin-detail-head px-0">
                    <Head onBack={this.handleBack} />
                    <Head hasInlineButtons centerElement={this.getCheckDetailTitle} />
                </div>
            );
        }

        return (
            <Head
                centerElement={this.getCheckDetailTitle}
                headerClassName="blue-main-header-mobile blue-main-title-mobile"
                onBack={this.handleBack}
                rightContent={this.rightContent}
            />
        );
    }

    render() {
        const { selectedCheckDetail, isEmittedSelected, isDesktop } = this.props;

        if (!selectedCheckDetail) {
            this.handleBack();
        }
        const firstLine = selectedCheckDetail.denominacionBeneficiario.trim()
            ? `${selectedCheckDetail.denominacionBeneficiario} - ${
                  DOCUMENT_TYPES[selectedCheckDetail.tipoDocumentoBeneficiario]
              } ${selectedCheckDetail.numeroDocumentoBeneficiario}`
            : "";

        const currency = isEmittedSelected
            ? selectedCheckDetail.codigoMonedaDebitoCheque
            : selectedCheckDetail.codigoMonedaAcreditacionCheque;

        const amount = `${i18nUtils.get(`currency.label.${currency}`)} ${new Intl.NumberFormat("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(selectedCheckDetail.importeCheque)}`;

        return (
            <>
                {this.renderHeader()}
                <Notification scopeToShow="checks.detail.info" />
                <MainContainer>
                    <div className="above-the-fold center">
                        <Container
                            className="align-items-center flex-grow"
                            rowClassName={classNames(
                                { "content-echeq-mobile": !isDesktop },
                                { "justify-content-center": isDesktop },
                            )}>
                            <div
                                className={classNames(
                                    { "echeq-view-mobile": !isDesktop },
                                    { "position-relative": isDesktop },
                                )}>
                                {selectedCheckDetail.codigoBancoLibrador === "319" ? (
                                    <ChecksDetailViewEmitted
                                        payDay={getDateFromStrDate(
                                            selectedCheckDetail.fechaDebitoAcreditacionCheque,
                                            "day",
                                        )}
                                        payMonth={
                                            getDateFromStrDate(
                                                selectedCheckDetail.fechaDebitoAcreditacionCheque,
                                                "month",
                                            ) &&
                                            i18nUtils.get(
                                                `checks.month.${getDateFromStrDate(
                                                    selectedCheckDetail.fechaDebitoAcreditacionCheque,
                                                    "month",
                                                )}`,
                                            )
                                        }
                                        payYear={getDateFromStrDate(
                                            selectedCheckDetail.fechaDebitoAcreditacionCheque,
                                            "year",
                                        )}
                                        firstLine={firstLine}
                                        echeqNro={selectedCheckDetail.numeroCheque}
                                        denominacionLibrador={selectedCheckDetail.denominacionLibrador}
                                        denominacionLibradorNro={`${
                                            DOCUMENT_TYPES[selectedCheckDetail.tipoDocumentoLibrador]
                                        } ${selectedCheckDetail.numeroDocumentoLibrador}`}
                                        amount={amount}
                                        showOrder
                                        order={
                                            selectedCheckDetail.caracterCheque === "A" ? "A LA ORDEN" : "NO A LA ORDEN"
                                        }
                                        largeNumber={selectedCheckDetail.cmc7}
                                    />
                                ) : (
                                    <ChecksDetailViewReceived
                                        payDay={getDateFromStrDate(selectedCheckDetail.fechaDepositoCheque, "day")}
                                        payMonth={
                                            getDateFromStrDate(selectedCheckDetail.fechaDepositoCheque, "month") &&
                                            i18nUtils.get(
                                                `checks.month.${getDateFromStrDate(
                                                    selectedCheckDetail.fechaDepositoCheque,
                                                    "month",
                                                )}`,
                                            )
                                        }
                                        payYear={getDateFromStrDate(selectedCheckDetail.fechaDepositoCheque, "year")}
                                        firstLine={firstLine}
                                        echeqNro={selectedCheckDetail.numeroCheque}
                                        denominacionLibrador={selectedCheckDetail.denominacionLibrador}
                                        denominacionLibradorNro={`${
                                            DOCUMENT_TYPES[selectedCheckDetail.tipoDocumentoLibrador]
                                        } ${selectedCheckDetail.numeroDocumentoLibrador}`}
                                        amount={amount}
                                        showOrder
                                        order={
                                            selectedCheckDetail.caracterCheque === "A" ? "A LA ORDEN" : "NO A LA ORDEN"
                                        }
                                        largeNumber={selectedCheckDetail.cmc7}
                                        bankName={selectedCheckDetail.denominacionBancoLibrador}
                                    />
                                )}
                            </div>
                        </Container>
                        {isDesktop && (
                            <RowDetailInfo
                                selectedCheckDetail={selectedCheckDetail}
                                isEmittedSelected={isEmittedSelected}
                            />
                        )}
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedCheckDetail: checksSelectors.getSelectedCheckDetail(state),
    isEmittedSelected: checksSelectors.isEmittedSelected(state),
});

export default connect(mapStateToProps)(ChecksDetailInfo);
