import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { createReducer } from "util/redux";

import { globalTypes } from "reducers/types/global";

import accounts from "reducers/accounts";
import bottomSheet from "reducers/bottomSheet";
import campaigns from "reducers/campaigns";
import communications from "reducers/communications";
import communication from "reducers/communication";
import communicationTrays from "reducers/communicationTrays";
import config from "reducers/config";
import enrollment from "reducers/enrollment";
import form from "reducers/form";
import i18n from "reducers/i18n";
import notification from "reducers/notification";
import recoveryPassword from "reducers/recoveryPassword";
import session, { types as sessionTypes } from "reducers/session";
import settings from "reducers/settings";
import fingerprint from "reducers/fingerprint";
import pushNotifications from "reducers/pushNotifications";
import status from "reducers/status";
import template from "reducers/template";
import onboarding from "reducers/onboarding";
import assistant from "reducers/assistant";
import desktop from "reducers/desktop";
import widgets from "reducers/widgets";
import transactions from "reducers/transactions";
import bankSelector from "reducers/bankSelector";
import login from "reducers/login";
import weather from "reducers/weather";
import administration from "reducers/administration";
import administrationGroups from "reducers/administration/groups";
import administrationSignaturesSchemes from "reducers/administration/common/signaturesSchemes";
import administrationTicket from "reducers/administration/common/administrationTicket";
import administrationUsers from "reducers/administration/users";
import administrationUsersInvite from "reducers/administration/usersInvite";
import pointsOfInterest from "reducers/pointsOfInterest";
import files from "reducers/files";
import formFields from "reducers/formFields";
import restrictions from "reducers/administration/restrictions";
import multipleTransfers from "reducers/multiple-transfers";
import transfer from "reducers/transfer";
import debin from "reducers/debin";
import frequentDestination from "reducers/frequentDestination";
import checks from "reducers/checks";
import deposits from "reducers/deposits";
import thirdPayment from "reducers/thirdPayment";
import token from "reducers/token";
import servicePayments from "reducers/servicePayments";
import financing from "reducers/financing";
import paymentsAFIP from "reducers/paymentsAFIP";
import metrocorp from "reducers/metrocorp";
import ecomex from "reducers/ecomex";
import posicionConsolidada from "reducers/posicionConsolidada";
import fundcorp from "reducers/fundcorp";
import holidays from "reducers/holidays";
import permissions from "reducers/permissions";
import cedip from "reducers/cedip";
import tooltip from "reducers//tooltip";
import ecomexIframe from "reducers/ecomexIframe";

export const actions = {
    init: () => ({
        type: globalTypes.INIT,
    }),
};

const appReducer = combineReducers({
    router: routerReducer,
    isLoading: createReducer(true, {
        [globalTypes.INIT]: () => false,
    }),

    assistant,
    accounts,
    bottomSheet,
    campaigns,
    communications,
    communication,
    communicationTrays,
    config,
    desktop,
    enrollment,
    fingerprint,
    form,
    i18n,
    notification,
    onboarding,
    pushNotifications,
    recoveryPassword,
    session,
    settings,
    status,
    template,
    widgets,
    transactions,
    bankSelector,
    login,
    weather,
    administration,
    administrationGroups,
    administrationSignaturesSchemes,
    administrationTicket,
    administrationUsers,
    administrationUsersInvite,
    files,
    formFields,
    pointsOfInterest,
    restrictions,
    multipleTransfers,
    transfer,
    debin,
    frequentDestination,
    checks,
    deposits,
    token,
    thirdPayment,
    servicePayments,
    financing,
    paymentsAFIP,
    metrocorp,
    ecomex,
    posicionConsolidada,
    fundcorp,
    holidays,
    permissions,
    cedip,
    tooltip,
    ecomexIframe,
});

const rootReducer = (state = {}, action) => {
    const initializationState = {
        i18n: state.i18n,
        config: state.config,
        notification: state.notification,
        isLoading: state.isLoading,
        session: state.session,
        fingerprint: state.fingerprint,
        weather: state.weather,
        login: state.login,
        token: state.token,
        transactions: state.transactions,
    };

    switch (action.type) {
        case globalTypes.CLEAN_UP:
        case sessionTypes.LOGOUT_REQUEST:
            return appReducer(
                {
                    ...initializationState,
                    login: state.login,
                },
                action,
            );
        // cleaning all specific environment data
        case sessionTypes.CHANGE_ENVIRONMENT_SUCCESS:
            return appReducer(
                {
                    ...initializationState,
                },
                action,
            );
        default:
            return appReducer(state, action);
    }
};

export default rootReducer;
