import React from "react";
import { bool, shape } from "prop-types";
import { connect } from "react-redux";

import Heading from "pages/_components/Heading";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";

const FORM_ID = "multiple.transfers";

function MultipleTransferIndividualTicketData({ isDesktop, data }) {
    return (
        <div className="transfer-multiple__ticket mt-3">
            {Object.entries(data).map(([key, item]) => (
                <Heading.DataGroup
                    containerClassName="transfer-data data-wrapper-flex"
                    label={`${FORM_ID}.individual.data.${key}.label`}
                    labelClassName="no-wrap"
                    data={(() => {
                        if (key.includes("date")) {
                            return <FormattedDate date={item} dateFormat="dd/MM/yyyy hh:mm:ss" showTime />;
                        }
                        if (key.includes("amount")) {
                            return (
                                <FormattedAmount
                                    {...item}
                                    notBold
                                    className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                                />
                            );
                        }
                        return item;
                    })()}
                    dataClassName="data-desc text-right"
                />
            ))}
        </div>
    );
}

MultipleTransferIndividualTicketData.propTypes = {
    isDesktop: bool.isRequired,
    data: shape({}).isRequired,
};

export default connect()(MultipleTransferIndividualTicketData);
