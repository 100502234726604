/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { arrayOf, element, bool, string, node } from "prop-types";
import classNames from "classnames";
import { Spring, config } from "react-spring";

import Button from "pages/_components/Button";

function Dropdown({
    children,
    pullRight,
    pullDown,
    pullCenterUp,
    pullLeftTop,
    listClassName,
    fetching,
    buttonClass,
    label,
    image,
    imageStyle,
    bsStyle,
    dropdownButtonContent,
    exportDisabled,
    closeOnBlur,
    dropClass,
}) {
    const ref = useRef(null);

    const [dropdownState, setDropdownState] = useState({
        isOpen: false,
        navIndex: 0,
    });

    const toggleOpen = useCallback(() => {
        setDropdownState({ isOpen: !dropdownState.isOpen, navIndex: 0 });
    }, [dropdownState.isOpen]);

    const onClose = useCallback(() => setDropdownState({ ...dropdownState, isOpen: false }), [dropdownState]);

    const handleClick = useCallback(
        ({ target }) => {
            if (!ref.current?.contains(target) && dropdownState.isOpen) {
                toggleOpen();
            }
        },
        [dropdownState.isOpen, toggleOpen],
    );

    const onBlurHandler = useCallback(
        ({ target }) => {
            if (ref.current?.contains(target) && closeOnBlur && !dropdownState.isOpen) {
                onClose();
            }
        },
        [dropdownState.isOpen, onClose, closeOnBlur],
    );

    const onChildFocus = (param) => {
        setDropdownState({ ...dropdownState, navIndex: param });
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick, false);
        document
            .querySelectorAll(".context-mobile")
            .forEach((elem) => elem.addEventListener("touchstart", onBlurHandler));
        return () => {
            document.removeEventListener("mousedown", handleClick, false);
            document
                .querySelectorAll(".context-mobile")
                .forEach((elem) => elem.removeEventListener("touchstart", onBlurHandler, false));
        };
    }, [handleClick, onBlurHandler]);

    const childrenLength = React.Children.toArray(children).length;

    const keyPressHandler = (ev) => {
        let aux = dropdownState.navIndex;
        if (ev.shiftKey && ev.key === "Tab") {
            aux -= 1;
        } else if (ev.key === "Tab") {
            aux += 1;
        }

        if (aux >= childrenLength || aux < 0) {
            setDropdownState({ ...dropdownState, isOpen: false });
        }
    };

    return (
        <div className={`dropdown  ${dropClass || ""}`} ref={ref}>
            <Button
                image={image}
                loading={fetching}
                block={false}
                className={buttonClass}
                label={label}
                bsStyle={bsStyle}
                onClick={toggleOpen}
                aria-haspopup
                aria-expanded={dropdownState.isOpen}
                onKeyDown={keyPressHandler}
                disabled={exportDisabled}
                imageStyle={imageStyle}
                fitWidth>
                {dropdownButtonContent}
            </Button>

            <Spring
                config={config.stiff}
                from={{ height: 0 }}
                to={{
                    height: dropdownState.isOpen ? "auto" : 0,
                    overflow: "hidden",
                    borderWidth: dropdownState.isOpen ? 1 : 0,
                }}>
                {(styles) =>
                    dropdownState.isOpen && (
                        <ul
                            style={styles}
                            // las clases que se agregan abajo se definen en el archivo
                            // src/styles/components/modules/_dropdown.scss
                            className={classNames("dropdown__menu", listClassName, {
                                "dropdown__menu--right": pullRight,
                                "dropdown__menu--down": pullDown,
                                "dropdown__menu--center-up": pullCenterUp,
                                "dropdown__menu--context-menu-left-top": pullLeftTop,
                            })}>
                            {React.Children.map(children, (child, ix) => (
                                <li
                                    className="dropdown__item"
                                    onClick={() => {
                                        // eslint-disable-next-line no-unused-expressions
                                        onClose();
                                    }}
                                    onKeyDown={keyPressHandler}>
                                    {child &&
                                        React.cloneElement(child, {
                                            ...child.props,
                                            ix,
                                            keyPressHandler,
                                            onFocus: () => {
                                                onChildFocus(ix);
                                            },
                                        })}
                                </li>
                            ))}
                        </ul>
                    )
                }
            </Spring>
        </div>
    );
}

Dropdown.propTypes = {
    children: arrayOf(element).isRequired,
    /**
     * Align the menu to the right side of the Dropdown
     */
    pullRight: bool,
    pullDown: bool,
    pullCenterUp: bool,
    pullLeftTop: bool,
    listClassName: string,
    fetching: bool,
    buttonClass: string,
    label: string,
    image: string,
    imageStyle: string,
    bsStyle: string,
    dropdownButtonContent: node,
    exportDisabled: bool,
    closeOnBlur: bool,
    dropClass: string,
};

Dropdown.defaultProps = {
    pullRight: false,
    pullDown: false,
    pullCenterUp: false,
    pullLeftTop: false,
    listClassName: "",
    fetching: false,
    buttonClass: null,
    label: null,
    image: null,
    imageStyle: "",
    bsStyle: null,
    dropdownButtonContent: null,
    exportDisabled: false,
    closeOnBlur: true,
    dropClass: "",
};

export default Dropdown;
