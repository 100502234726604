import React, { Component } from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { func, arrayOf, shape, string, bool } from "prop-types";

import { actions as servicePaymentsActions, selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import { selectors as sessionSelectors } from "reducers/session";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";
import { agendaPagoShape } from "util/servicePayments";
import PageLoading from "pages/_components/PageLoading";
import ContextMenu from "pages/_components/ContextMenu";
import AgendaDetailPaymentInfo from "./_components/AgendaDetailPaymentInfo";

const FORM_ID = "services.newPayment";

class AgendaPaymentDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        agendaPagos: arrayOf(agendaPagoShape).isRequired,
        match: shape({ params: shape({ conceptCode: string, userLp: string }) }).isRequired,
        isDesktop: bool,
        activeEnvironment: shape({
            permissions: shape({
                newPayment: bool,
            }),
        }).isRequired,
    };

    static defaultProps = {
        isDesktop: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(servicePaymentsActions.listAgendaPagos());
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/servicePayments"));
    };

    handlePayment = () => {
        const { dispatch } = this.props;
        const agendaPago = this.getAgendaPago();

        dispatch(servicePaymentsActions.loadSummaryInfo(agendaPago));
        dispatch(push(`/agenda/payment`));
    };

    handleUnattachService = () => {
        const { dispatch } = this.props;
        const agendaPago = this.getAgendaPago();
        const adhesion = {
            adhesionCode: agendaPago?.codeAdherence,
            enteDescription: agendaPago?.entity.description,
            enteCode: agendaPago?.entity.code,
            userLp: agendaPago?.ususerLP,
            category: agendaPago?.entity.category,
            reference: agendaPago?.reference,
            manejaBaseDeuda: agendaPago?.entity.handleBaseDebts,
        };
        dispatch(servicePaymentsActions.loadSummaryInfo(adhesion));
        dispatch(push(`/desadherir/confirmation`));
    };

    getAgendaPago = () => {
        const { agendaPagos } = this.props;
        const {
            match: {
                params: { conceptCode, userLp },
            },
        } = this.props;
        const agendaPago = agendaPagos.find((pago) => pago.ususerLP === userLp);

        if (agendaPago?.entity?.handleBaseDebts) {
            const expiration = agendaPago?.expirations?.find((exp) => exp.concept.conceptCode === conceptCode);
            return {
                ...agendaPago,
                expiration,
            };
        }
        const concept = agendaPago?.concepts.find((conc) => conc.conceptCode === conceptCode);
        return {
            ...agendaPago,
            concept,
        };
    };

    rightContent = () => {
        const { agendaPagos, isDesktop, activeEnvironment } = this.props;
        const agendaPago = agendaPagos ? this.getAgendaPago() : null;

        if (isDesktop) {
            return (
                agendaPago && (
                    <>
                        {activeEnvironment.permissions.newPayment ? (
                            <Button
                                block
                                label="payments.afip.list.actions.pay"
                                bsStyle="primary"
                                className="cmf-button-width"
                                onClick={this.handlePayment}
                            />
                        ) : (
                            undefined
                        )}
                        {agendaPago?.entity?.handleBaseDebts && (
                            <Button
                                block
                                label="services.adhesiones.table.header.unattachService"
                                onClick={this.handleUnattachService}
                                className="btn-outline cmf-button-width"
                            />
                        )}
                    </>
                )
            );
        }

        const items = [];

        if (activeEnvironment.permissions.newPayment) {
            items.push({
                label: "payments.afip.list.actions.pay",
                onClick: () => this.handlePayment(),
            });
        }

        items.push({
            label: "services.adhesiones.table.header.unattachService",
            onClick: () => this.handleUnattachService(),
        });

        return (
            <div>
                <ContextMenu className="justify-content-end" items={items} />
            </div>
        );
    };

    render() {
        const { agendaPagos, isDesktop } = this.props;
        const agendaPago = agendaPagos ? this.getAgendaPago() : null;

        return (
            <PageLoading loading={!agendaPago?.concept && !agendaPago?.expiration}>
                <Notification scopeToShow="servicePayments" />
                {isDesktop ? (
                    <div className="admin-detail-head px-0 mb-0">
                        <Head onBack={this.handleBack} />
                        <Head
                            titleText={agendaPago?.entity?.description}
                            rightContent={this.rightContent}
                            isFetchingExport={false}
                        />
                        <p>
                            {`${agendaPago?.entity?.category?.description?.toUpperCase()}`} |{" "}
                            {`${agendaPago?.reference?.toUpperCase()}`}
                        </p>
                    </div>
                ) : (
                    <div className="admin-detail-head px-0 mb-0">
                        <Head
                            onBack={this.handleBack}
                            titleText={agendaPago?.entity?.description}
                            rightContent={this.rightContent}
                            isFetchingExport={false}
                            headerClassName="blue-main-header-mobile payment-title-mobile"
                        />
                        <div className="d-flex mb-3 mx-3">
                            <div className="w-50 text-right">{`${agendaPago?.entity?.category?.description?.toUpperCase()}`}</div>
                            <div className="border-left mx-2" />
                            <div className="w-50 text-left">{`${agendaPago?.reference?.toUpperCase()}`}</div>
                        </div>
                    </div>
                )}
                <AgendaDetailPaymentInfo
                    FORM_ID={FORM_ID}
                    agendaPago={agendaPago}
                    handleBack={this.handleBack}
                    isDesktop={isDesktop}
                />
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    agendaPagos: servicePaymentsSelectors.getAgendaPagos(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(AgendaPaymentDetail);
