import { createReducer, makeActionCreator } from "util/redux";

const INITIAL_STATE = {
    fetching: true,
    groups: [],
    user: {},
};

export const selectors = {
    isFetching: ({ groupsOfUserTicket }) => groupsOfUserTicket.fetching,
    getGroups: ({ groupsOfUserTicket }) => groupsOfUserTicket.groups,
    getUser: ({ groupsOfUserTicket }) => groupsOfUserTicket.user,
};

export default (name) => {
    const types = {
        LOAD_GROUPS_OF_USER_TICKET_REQUEST: `${name}/LOAD_GROUPS_OF_USER_TICKET_REQUEST`,
        LOAD_GROUPS_OF_USER_FAILURE: `${name}/LOAD_GROUPS_OF_USER_FAILURE`,
        LOAD_GROUPS_OF_USER_SUCCESS: `${name}/LOAD_GROUPS_OF_USER_SUCCESS`,
    };

    return {
        types,
        reducer: createReducer(INITIAL_STATE, {
            [types.LOAD_GROUPS_OF_USER_TICKET_REQUEST]: (state) => ({
                ...state,
                fetching: true,
            }),
            [types.LOAD_GROUPS_OF_USER_FAILURE]: () => ({
                fetching: false,
            }),
            [types.LOAD_GROUPS_OF_USER_SUCCESS]: (state, { data }) => ({
                fetching: false,
                groups: data.groups,
                user: data.user,
            }),
        }),
        actions: {
            loadGroupsOfUserTicketRequest: makeActionCreator(types.LOAD_GROUPS_OF_USER_TICKET_REQUEST, "idTransaction"),
            loadGroupsOfUserTicketFailure: makeActionCreator(types.LOAD_GROUPS_OF_USER_FAILURE),
            loadGroupsOfUserTickeSuccess: makeActionCreator(types.LOAD_GROUPS_OF_USER_SUCCESS, "data"),
        },
    };
};
