import React from "react";
import { selectors as notificationSelectors, actions as notificationActions } from "reducers/notification";
import { connect } from "react-redux";
import { arrayOf, bool, element, func, number, string, shape } from "prop-types";
import NotificationSystem from "react-notification-system";
import { resizableRoute } from "pages/_components/Resizable";

/*
Por customizacion ver, ver: https://github.com/igorprado/react-notification-system
*/
class Notification extends React.Component {
    static propTypes = {
        scopeToShow: string.isRequired, // what scope this notification component must listen
        message: string.isRequired,
        scopes: arrayOf(element).isRequired,
        dispatch: func.isRequired,
        level: string.isRequired,
        autoDismiss: bool.isRequired,
        errors: shape({}),
        metadata: shape({
            idForm: string.isRequired,
            version: number,
            enabled: bool,
            category: string.isRequired,
            type: string.isRequired,
            adminOption: string.isRequired,
            idActivity: string.isRequired,
            fieldList: arrayOf(
                shape({
                    idField: string.isRequired,
                    labelMap: shape({}).isRequired,
                    requiredErrorMap: shape({}).isRequired,
                }),
            ).isRequired,
        }).isRequired,
        currentLang: string.isRequired,
        title: string,
        notificationClassname: string,
    };

    static defaultProps = {
        errors: {},
        title: null,
        notificationClassname: null,
    };

    state = {
        notificationSystem: null,
    };

    componentDidMount() {
        // eslint-disable-next-line react/no-string-refs
        const { notificationSystem } = this.refs;

        this.setState({ notificationSystem });
    }

    componentDidUpdate() {
        const { message, scopes, scopeToShow } = this.props;

        if (message && scopes && scopes.indexOf(scopeToShow) !== -1) {
            this.addNotification();
        }
    }

    addNotification = () => {
        const { notificationSystem } = this.state;
        const { dispatch, message, level, errors, metadata, currentLang, title, autoDismiss } = this.props;

        let allFormFieldsInfo;
        let errorFieldsKeys;
        let errorFieldsInfo;

        if (metadata) {
            allFormFieldsInfo = metadata.fieldList;
            errorFieldsKeys = Object.keys(errors);
            errorFieldsInfo = allFormFieldsInfo.filter((field) => errorFieldsKeys.includes(field.idField));
        }

        const finalTitle = title || (level === "error" ? "Error. " : "Atención. ");
        const finalMessage = (
            <div>
                <span style={{ fontWeight: 700 }}>{`${finalTitle}`}</span>
                <span>{` ${message}`}</span>
            </div>
        );
        notificationSystem.clearNotifications();
        if (level !== "success") {
            notificationSystem.addNotification({
                message: finalMessage,
                level,
                position: "tc",
                autoDismiss: autoDismiss ? 5 : 0,
                dismissible: true,
                children: (
                    <div className="visually-hidden">
                        {errorFieldsInfo &&
                            errorFieldsInfo.map((field) => (
                                <div>{`${field.labelMap[currentLang]} : ${field.requiredErrorMap[currentLang]}`}</div>
                            ))}
                    </div>
                ),
            });
        } else {
            notificationSystem.addNotification({
                message,
                level,
                position: "tc",
                autoDismiss: autoDismiss ? 5 : 0,
                dismissible: true,
                children: (
                    <div className="visually-hidden">
                        {errorFieldsInfo &&
                            errorFieldsInfo.map((field) => (
                                <div>{`${field.labelMap[currentLang]} : ${field.requiredErrorMap[currentLang]}`}</div>
                            ))}
                    </div>
                ),
            });
        }

        dispatch(notificationActions.removeNotification());
    };

    render() {
        const { notificationClassname } = this.props;
        // ignoring warning because the only way to remove all the styles from the component is to pass style as false
        return (
            <div aria-live="polite" className={notificationClassname}>
                <NotificationSystem
                    // eslint-disable-next-line react/no-string-refs
                    ref="notificationSystem"
                    // eslint-disable-next-line react/style-prop-object
                    style={false}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    message: notificationSelectors.getMessage(state),
    level: notificationSelectors.getLevel(state),
    scopes: notificationSelectors.getScopes(state),
    title: notificationSelectors.getTitle(state),
    autoDismiss: notificationSelectors.getAutoDismiss(state),
});

export default connect(mapStateToProps)(resizableRoute(Notification));
