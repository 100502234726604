import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import Col from "react-bootstrap/lib/Col";
import { Field, Form, withFormik } from "formik";
import { bool, func, shape, string } from "prop-types";
import Yup from "yup";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import OnboardingStepper, { orientations } from "pages/onboarding/_components/OnboardingStepper";
import { Mixpanel } from "util/clickstreaming";
import Image from "pages/_components/Image";
import Countdown from "react-countdown";
import EnrollmentStepper from "./_components/EnrollmentStepper";
import AssociateStepperSimple from "./_components/AssociateStepperSimple";

const FORM_ID = "enrollment.step1";

class Step1 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        exchangeToken: string.isRequired,
        invitationCode: string.isRequired,
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        documentData: shape({}).isRequired,
        maskedMobileNumber: string,
        match: shape.isRequired,
    };

    static defaultProps = {
        maskedMobileNumber: null,
    };

    state = {
        resending: null,
    };

    componentDidMount() {
        const { dispatch, exchangeToken, invitationCode, match } = this.props;
        const isCredentialWhitening = match.path === "/credentialWhitening/step1";

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        } else {
            dispatch(
                enrollmentActions.requestVerificationCodePre(invitationCode, exchangeToken, isCredentialWhitening),
            );
        }
    }

    handleClick = () => {
        const { dispatch, exchangeToken, invitationCode, match } = this.props;
        const isCredentialWhitening = match.path === "/credentialWhitening/step1";
        this.setState({ resending: Date.now() });
        dispatch(enrollmentActions.resendVerificationCode(invitationCode, exchangeToken, isCredentialWhitening));
    };

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    rendererCountdown = ({ seconds, completed }) => {
        if (completed) {
            // Render a completed state
            this.setState({ resending: null });
            return "";
        }
        // Render a countdown
        return <span>00:{seconds.toString().length === 1 ? `0${seconds}` : seconds} seg</span>;
    };

    renderTitleMobile = () => (
        <Image src="images/logoMobileHeader.svg" styles={{ position: "relative", top: "5px" }} className="" />
    );

    render() {
        const { isDesktop, isSubmitting, documentData, maskedMobileNumber, match } = this.props;
        const { resending } = this.state;
        const verificationCodeLength = configUtils.getInteger("enrollment.verificationCode.length", 4);
        const step = 8;
        const delayToResendSMS = configUtils.getTimeInMillis("communications.resendSMS.delay", 30000);
        const isCredentialWhitening = match.path === "/credentialWhitening/step1";

        return (
            <>
                <Notification scopeToShow="enrollment/step1" />
                <Head
                    onClose={!isDesktop ? this.handleHeaderBack : null}
                    onBack={!isDesktop && this.handleHeaderBack}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                    centerElement={isDesktop ? undefined : this.renderTitleMobile}
                    closeImageWhite={!isDesktop && true}
                />

                <div className="view-page">
                    {isDesktop && documentData.firstName && (
                        <OnboardingStepper currentStep={step} className="onboarding-steps" />
                    )}
                    {isDesktop && !documentData.firstName && (
                        <EnrollmentStepper
                            currentStep={2}
                            credentialWhitening={isCredentialWhitening}
                            className="onboarding-steps"
                        />
                    )}
                    <div className="view-content">
                        <main className="main-container">
                            <Form className="above-the-fold">
                                {!isDesktop && (
                                    <Container className="container--layout align-items-center">
                                        <Col className="col col-12">
                                            {!isDesktop && documentData.firstName && (
                                                <OnboardingStepper
                                                    currentStep={step}
                                                    orientation={orientations.horizontal}
                                                />
                                            )}
                                            {!(isDesktop || documentData.firstName) && (
                                                <AssociateStepperSimple currentStep={2} />
                                            )}
                                        </Col>
                                    </Container>
                                )}
                                <Container
                                    className="container--layout align-items-center"
                                    gridClassName="form-content">
                                    <Col sm={12} md={5} lg={4} className="col col-12">
                                        <Col sm={12} md={12} lg={12} className="col col-12">
                                            <I18n
                                                component="h1"
                                                componentProps={{ className: "title-content" }}
                                                id={`${FORM_ID}.header`}
                                            />
                                        </Col>
                                        {maskedMobileNumber && (
                                            <Col sm={12} md={12} lg={12} className="col col-12 enrollment-phone-text">
                                                <I18n
                                                    component="p"
                                                    id="enrollment.step1.subtitle"
                                                    maskedMobileNumber={maskedMobileNumber}
                                                />
                                            </Col>
                                        )}
                                        <Col
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            className="col col-12 sms-code enrollment-verification-code">
                                            <Field
                                                className="form-control form-control--verification-code"
                                                autoComplete="off"
                                                component={TextField}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                maxLength={verificationCodeLength}
                                                name="verificationCode"
                                            />
                                        </Col>

                                        <Col sm={12} md={12} lg={12} className="col col-12 on-error">
                                            <Image className="svg-icon" src="images/info.svg" />
                                            <I18n
                                                componentProps={{ className: "control-label text-wrap" }}
                                                id="enrollment.step1.supportInformation"
                                            />
                                        </Col>
                                        <Col sm={12} md={12} lg={12} className="col col-12">
                                            <Button
                                                bsStyle="link"
                                                label="enrollment.step1.resendSMS"
                                                type="button"
                                                onClick={this.handleClick}
                                                disabled={resending}
                                            />
                                            {resending != null && (
                                                <div className="sms-countdown">
                                                    <Countdown
                                                        date={resending + delayToResendSMS}
                                                        renderer={this.rendererCountdown}
                                                    />
                                                </div>
                                            )}
                                        </Col>
                                        <Col sm={12} md={12} lg={12} className="col col-12">
                                            <Button
                                                bsStyle="primary"
                                                label="global.continue"
                                                loading={isSubmitting}
                                                type="submit"
                                            />
                                        </Col>
                                    </Col>
                                </Container>
                            </Form>
                        </main>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    documentData: onboardingSelectors.getDocumentData(state),
    maskedMobileNumber: enrollmentSelectors.getMaskedMobileNumber(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            verificationCode: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                verificationCode: Yup.string()
                    .min(
                        configUtils.getInteger("enrollment.verificationCode.length"),
                        i18nUtils.get("enrollment.step1.verificationCode.invalidFormat"),
                    )
                    .max(
                        configUtils.getInteger("enrollment.verificationCode.length"),
                        i18nUtils.get("enrollment.step1.verificationCode.invalidFormat"),
                    )
                    .required(i18nUtils.get("enrollment.step1.verificationCode.empty")),
            }),
        handleSubmit: ({ verificationCode }, formikBag) => {
            const { dispatch, match } = formikBag.props;
            const isCredentialWhitening = match.path === "/credentialWhitening/step1";

            Mixpanel.track(FORM_ID);
            dispatch(enrollmentActions.verifyVerificationCode(verificationCode, formikBag, isCredentialWhitening));
        },
    }),
)(Step1);
