import { call, put, takeLatest } from "redux-saga/effects";
import { replace, push } from "react-router-redux";

import { ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE, INSUFFICIENT_SIGNATURES } from "util/responses.js";
import * as form from "middleware/form";
import { types, actions } from "reducers/administration/groups";
import { actions as notificationActions } from "reducers/notification";
import * as administrationGroups from "middleware/administration/groups";

import * as i18n from "util/i18n";
import * as utilDownload from "util/download";
import { credentialsToUnderscoreFormat } from "util/form.js";

const sagas = [
    takeLatest(types.LOAD_LIST_REQUEST, loadListRequest),
    takeLatest(types.LOAD_MORE_REQUEST, loadMoreRequest),
    takeLatest(types.EXPORT_LIST_REQUEST, exportListRequest),
    takeLatest(types.CHANGE_GROUP_STATUS_PREVIEW, changeGroupStatusPreview),
    takeLatest(types.CHANGE_GROUP_STATUS_CONFIRMATION, changeGroupStatusConfirmation),
];

export default sagas;

function* loadListRequest({ filters }) {
    const response = yield call(administrationGroups.loadListRequest, filters);
    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
        );
    } else {
        yield put(actions.loadListSuccess({
            ...response.data.data, 
            groups: 
                response.data.data.groups
                    .map( group => 
                        ({
                            ...group, 
                            description: group.description.replaceAll("<br/>", "\n")
                        })
                    )
        }));
    }
}

function* loadMoreRequest({ filters }) {
    const response = yield call(administrationGroups.loadListRequest, filters);
    if (response.type === "W") {
        yield put(actions.loadListFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
        );
    } else {
        yield put({
            type: types.LOAD_MORE_SUCCESS,
            data: {
                ...response.data.data, 
                groups: 
                    response.data.data.groups
                        .map( group => 
                            ({
                                ...group, 
                                description: group.description.replaceAll("<br/>", "\n")
                            })
                        )
            },
        });
    }
}

function* exportListRequest(params) {
    const { type, data } = yield call(administrationGroups.exportListRequest, params);
    const { format } = params;

    if (type === "W") {
        yield put({ type: types.EXPORT_LIST_FAILURE });
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
        );
    } else {
        const { content, fileName } = data.data;
        if (format === "pdf") {
            utilDownload.downloadPdf(fileName, content);
        } else {
            utilDownload.downloadXls(fileName, content);
        }
        yield put(
            notificationActions.showNotification(i18n.get("global.download.success"), "success", ["administration"]),
        );
        yield put({ type: types.EXPORT_LIST_SUCCESS });
    }
}

function* changeGroupStatusPreview({ groupList, groupNameList, groupAction }) {
    const params = {
        groupIdList: groupList,
        groupNameList,
    };
    let response = null;
    if (groupAction === "block" || groupAction === "unblock") {
        response = yield call(administrationGroups.changeGroupsStatusPreview, {
            ...params,
            blocked: groupAction === "block",
        });
    } else if (groupAction === "delete") {
        response = yield call(administrationGroups.deleteGroupsPreview, params);
    }

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
        );
    } else {
        const responseCredentials = yield call(
            form.listCredentialsGroups,
            null,
            "administration.groups.blockunblock.send",
        );
        const credentialGroups = responseCredentials.data.data.groups;

        yield put({
            type: types.CHANGE_GROUP_STATUS_PREVIEW_SUCCESS,
            credentialGroups,
            groupList,
            groupNameList,
            groupAction,
        });
        yield put(push(`/administration/confirmGroupAction`));
    }
}

function* changeGroupStatusConfirmation({ groupsToApplyAction, groupNameList, groupAction, credentials, formikBag }) {
    const params = {
        groupIdList: groupsToApplyAction,
        groupNameList,
    };
    let response = null;
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);

    if (groupAction === "block" || groupAction === "unblock") {
        response = yield call(
            administrationGroups.changeGroupsStatusConfirmation,
            { ...params, blocked: groupAction === "block" },
            credentialsWithUnderscore,
        );
    } else if (groupAction === "delete") {
        response = yield call(administrationGroups.deleteGroupsConfirmation, params, credentialsWithUnderscore);
    }
    formikBag.setSubmitting(false);
    if (response.type === "W") {
        if (response.data.data.NO_FIELD) {
            yield put(replace("/administration/groups"));
            yield put(notificationActions.showNotification(response.data.data.NO_FIELD, "error", ["administration"]));
        } else if (response.data.code === "COR055W") {
            yield put(notificationActions.showNotification(i18n.get("returnCode." + response.data.code + ".generic"), "error", ["administration"]));
        } else if (response.data.code === "COR054W" || response.data.code === "API524W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["administration"]));
        } else {
            formikBag.setErrors(response.data.data);
            yield put(replace("/administration/confirmGroupAction"));
        }
    } else {
        const { code } = response.data;
        if (code && [ADMINISTRATION_TRANSACTION_PENDING_SIGNATURE, INSUFFICIENT_SIGNATURES].includes(code)) {
            yield put(
                notificationActions.showNotification(
                    i18n.get("administration.group.status.change.pending"),
                    "warning",
                    ["administration"],
                ),
            );
        } else {
            let successMessageKey = `administration.groups.${groupAction}.success`;
            if (groupsToApplyAction.length > 1) {
                successMessageKey = `${successMessageKey}.plural`;
            }
            yield put(notificationActions.showNotification(i18n.get(successMessageKey), "success", ["administration"]));
        }
        yield put(replace("/administration/groups"));
    }
}
