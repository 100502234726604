import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { bool, func, shape } from "prop-types";
import { selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import * as configUtils from "util/config";
import PageLoading from "pages/_components/PageLoading";
import { isMobile, isAndroid } from "react-device-detect";

class AFIPServiceConditions extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        closeDrawer: func.isRequired,
        isDesktop: bool.isRequired,
        tokenAFIP: shape({}).isRequired,
    };

    navigateMobile = () => {
        const { tokenAFIP } = this.props;
        const { token, firma, accion, urlafip } = tokenAFIP;

        const formWeb = document.getElementById("formWeb");
        if (isAndroid) {
            window.navigator.app.loadUrl(formWeb.submit(), { openExternal: true });
        } else {
            const query = new URLSearchParams({
                token,
                sign: firma,
                action: accion,
            });

            window.cordova.InAppBrowser.open(
                urlafip.concat("?").concat(query.toString()),
                "_system",
                "hidden=no,location=no,clearsessioncache=yes,clearcache=yes",
            );
        }
    };

    submitFormAfip = () => {
        const { closeDrawer } = this.props;
        if (isMobile) {
            this.navigateMobile();
        } else {
            window.open("", "TheWindow");
            document.getElementById("formWeb").submit();
            closeDrawer();
        }
    };

    render() {
        const { fetching, closeDrawer, isDesktop, tokenAFIP } = this.props;
        const { token, firma, urlafip, accion } = tokenAFIP;
        return (
            <PageLoading loading={fetching}>
                <Container className="align-items-center" gridClassName="form-content">
                    <Col className="col-12" lg={10} md={10} sm={12}>
                        {isDesktop && (
                            <div className="transfer-block">
                                <h1 className="form-title text-center">
                                    <I18n id="payments.serviceAFIP.title" />
                                </h1>
                            </div>
                        )}
                        <div className="transfer-block text-center">
                            <div className="inline-grid account-alias-drawer-font account-alias-drawer-subtitle AFIP-subtitle">
                                <I18n id="payments.serviceAFIP.subTitle" />
                            </div>
                        </div>
                        <div className="transfer-block mb-1">
                            <div className="scrollable-content mb-0">
                                {configUtils.get("payments.serviceAFIP.termsAndConditions")}
                            </div>
                        </div>
                    </Col>
                    <div
                        className="admin-content-center"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: !isDesktop && "column-reverse",
                        }}>
                        <Col sm={4} md={4} lg={4}>
                            <Button
                                onClick={closeDrawer}
                                bsStyle="outline"
                                label="payments.serviceAFIP.declineTerms.label"
                            />
                        </Col>
                        <Col sm={4} md={4} lg={4}>
                            <form id="formWeb" name="F1" method="POST" action={urlafip} target="TheWindow">
                                <input type="hidden" name="token" value={token} />
                                <input type="hidden" name="sign" value={firma} />
                                <input type="hidden" name="action" value={accion} />
                            </form>
                            <Button
                                type="button"
                                onClick={this.submitFormAfip}
                                loading={fetching}
                                label="payments.serviceAFIP.acceptTerms.label"
                                bsStyle="primary"
                            />
                        </Col>
                    </div>
                </Container>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: paymentsAFIPSelectors.getFetching(state),
    tokenAFIP: paymentsAFIPSelectors.getTokenAFIP(state),
});

export default connect(mapStateToProps)(AFIPServiceConditions);
