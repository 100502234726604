import React, { Component } from "react";
import { bool, func, string } from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";

import Button from "pages/_components/Button";
import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";

class ExtractListItem extends Component {
    static propTypes = {
        fetchingDownload: bool.isRequired,
        dispatch: func.isRequired,
        productType: string.isRequired,
        fechaHasta: string.isRequired,
        extractId: string.isRequired,
    };

    handleClickSummariesExtract = (extractId, productType) => {
        const { dispatch } = this.props;
        dispatch(accountsActions.summariesExtract(extractId, productType));
    };

    render() {
        const { fechaHasta, extractId, productType, fetchingDownload } = this.props;
        return (
            <Col className="col col-12 cmf-container-white extract-list-item" sm={12} md={12} lg={12} xl={12}>
                <Button
                    className="toolbar-btn view-close"
                    onClick={() => this.handleClickSummariesExtract(extractId, productType)}
                    image="images/download_bold.svg"
                    bsStyle="link"
                    style={{ width: "3rem" }}
                    disabled={fetchingDownload}
                />
                <div className="data-text account-display-font font-weight-300 account-list-div light-font extract-download">
                    <I18n id="accounts.summaries.listDateToYear" />
                    {moment(fechaHasta).format("DD/MM/YYYY")}
                </div>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    fetchingDownload: accountsSelectors.getFetchingExtractDownload(state, ownProps.extractId),
});

export default connect(mapStateToProps)(ExtractListItem);
