import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func, arrayOf, shape, number, date, bool, oneOfType } from "prop-types";
import { FilePond, File, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";

import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import * as fileMiddleware from "middleware/file";
import { actions as notificationActions } from "reducers/notification";
import { selectors as fileSelectors, actions as fileActions } from "reducers/formFields/multilineFile";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as accountsSelectors } from "reducers/accounts";
import { selectors as i18nSelectors } from "reducers/i18n";
import { channel } from "middleware/api";

// Register the plugins
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

class FileXlsUploader extends Component {
    static propTypes = {
        idForm: string,
        idFormField: string,
        idActivity: string,
        description: string,
        onRemoveFile: func,
        onAddFile: func,
        onError: func,
        onFileProcess: func,
        setValue: func,
        lang: string.isRequired,
        idEnvironment: string.isRequired,
        files: arrayOf(),
        idRelatedFile: oneOfType([number, string]),
        accessToken: string.isRequired,
        summary: shape({
            account: string.isRequired,
            amount: number.isRequired,
            currency: string.isRequired,
            description: string,
            typePayment: string,
            file: arrayOf(
                shape({
                    fileId: number,
                    fileName: string,
                    fileSize: number,
                }),
            ),
            dateProcess: date,
        }).isRequired,
        dispatch: func.isRequired,
        acceptedFileTypes: string,
        removeFile: bool,
        fileUploaded: bool,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        values: shape({}).isRequired,
        FORM_ID: string.isRequired,
        enabledUpload: bool.isRequired,
    };
    // More props:
    // - https://pqina.nl/filepond/docs/patterns/api/filepond-instance/#properties
    // - https://pqina.nl/filepond/docs/patterns/plugins/file-validate-size/#properties
    // - https://pqina.nl/filepond/docs/patterns/plugins/file-validate-type/#properties
    // - https://pqina.nl/filepond/docs/patterns/plugins/image-preview/#properties

    static defaultProps = {
        idForm: null,
        idFormField: null,
        idActivity: null,
        description: null,
        onRemoveFile: null,
        onAddFile: null,
        setValue: null,
        files: [],
        idRelatedFile: null,
        onError: null,
        onFileProcess: null,
        acceptedFileTypes: "text/plain",
        removeFile: false,
        fileUploaded: false,
    };

    state = {
        files: [],
        removeFile: false,
    };

    componentDidMount() {
        document.getElementsByClassName("filepond--drop-label")[0].classList.add("filepond--disabled");
    }

    shouldComponentUpdate(nextProps) {
        const { files } = this.state;
        const { values, enabledUpload } = this.props;
        const hasCurencyChanged = !values?.account ? false : this.hasCurrencyChanged(this.props, nextProps);
        if (values?.account && nextProps?.enabledUpload) {
            document.getElementsByClassName("filepond--drop-label")[0].classList.remove("filepond--disabled");
        }

        return (
            nextProps?.enabledUpload !== enabledUpload ||
            nextProps?.files?.length !== files?.length ||
            hasCurencyChanged
        );
    }

    componentDidUpdate(prevProps) {
        const { dispatch } = this.props;
        const hasCurencyChanged = !prevProps?.values?.account ? false : this.hasCurrencyChanged(prevProps, this.props);
        if (hasCurencyChanged) {
            this.pond.removeFile();
            dispatch(fileActions.onFileRemoved());
        }
    }

    componentWillUnmount() {
        const { setValue } = this.props;
        if (setValue) {
            setValue([]);
        }
    }

    hasCurrencyChanged = (prevProps, currentProps) => {
        const {
            accounts,
            values: { account: prevAccount },
        } = prevProps;

        const {
            values: { account },
        } = currentProps;

        const prevCurrency = accounts.find(({ idProduct }) => idProduct === prevAccount).currency;
        const { currency } = accounts.find(({ idProduct }) => idProduct === account);

        return prevCurrency !== currency;
    };

    onProcessFile = (error, fileItem) => {
        const { onAddFile } = this.props;
        if (!error && fileItem) {
            const { files } = this.props;
            const file = {
                fileId: parseInt(fileItem.serverId, 10),
                fileName: fileItem.filename,
                fileSize: fileItem.fileSize,
                fileType: fileItem.fileType,
            };

            this.setState({ files: [...files, file] }, () => {
                if (onAddFile) {
                    onAddFile(file);
                }
            });
            document.getElementsByClassName("filepond--wrapper")[0].className =
                "filepond--wrapper border-none pb-15 pr-1";
        }
    };

    onRemoveFile = ({ serverId }) => {
        const { onRemoveFile, idRelatedFile } = this.props;
        const { files } = this.state;

        this.setState({ removeFile: true });

        this.setState({ files: files.filter((file) => file.fileId !== serverId) }, () => {
            if (onRemoveFile) {
                onRemoveFile(parseInt(serverId, 10));
            }
        });
        document.getElementsByClassName("filepond--wrapper")[0].className = "filepond--wrapper";
        fileMiddleware.deleteFile(parseInt(serverId, 10));
        if (idRelatedFile) {
            fileMiddleware.deleteFile(parseInt(idRelatedFile, 10));
        }
        return true;
    };

    onUpdateFiles = (fileItems) => {
        const { dispatch, acceptedFileTypes, fileUploaded, FORM_ID } = this.props;
        const { removeFile } = this.state;
        const invalidFileErrorMessage = i18n.get(`forms.inputFile.${FORM_ID}.invalid.file.type`);
        const arrayAcceptedFileTypes = acceptedFileTypes?.split(",").map((s) => s.trim());
        const error = fileItems.some((fileItem) => {
            // Revisar aqui o usar CSS
            if (fileItem.file.size === 0) {
                return true;
            }

            if (!arrayAcceptedFileTypes.includes(fileItem.fileType)) {
                return true;
            }

            if (!fileUploaded && !removeFile) {
                document
                    .getElementsByClassName("filepond--item")[0]
                    .setAttribute("data-filepond-item-state", "processing-complete");
                document.getElementsByClassName("filepond--file-info")[0].style.transform =
                    "translate3d(0px, 0px, 0px)";
                document.getElementsByClassName("filepond--file-status")[0].style.opacity = 1;
                document.getElementsByClassName("filepond--file-status")[0].style.visibility = "visible";
                document.getElementsByClassName("filepond--file-status")[0].style.transform =
                    "translate3d(0px, 0px, 0px)";
                document.getElementsByClassName("filepond--file-status-main")[0].innerHTML = i18n.get(
                    "file.upload.input.labelFileProcessingComplete",
                );
                document.getElementsByClassName("filepond--file-status-sub")[0].innerHTML = i18n.get(
                    "file.upload.input.labelTapToUndo",
                );
                document
                    .getElementsByClassName("filepond--file-action-button filepond--action-remove-item")[0]
                    .setAttribute("data-align", "right");
                document.getElementsByClassName("filepond--wrapper")[0].className =
                    "filepond--wrapper border-none pb-15 pr-1";
            }
            return false;
        });
        if (error) {
            dispatch(notificationActions.showNotification(invalidFileErrorMessage, "error", ["form"]));
        }
    };

    getLabels = (FORM_ID) => ({
        labelIdle: i18n.get(`file.upload.input.${FORM_ID}.labelIdle`),
        labelFileWaitingForSize: i18n.get("file.upload.input.labelFileWaitingForSize"),
        labelFileSizeNotAvailable: i18n.get("file.upload.input.labelFileSizeNotAvailable"),
        labelFileLoading: i18n.get("file.upload.input.labelFileLoading"),
        labelFileLoadError: i18n.get("file.upload.input.labelFileLoadError"),
        labelFileProcessing: i18n.get("file.upload.input.labelFileProcessing"),
        labelFileProcessingComplete: i18n.get("file.upload.input.labelFileProcessingComplete"),
        labelFileProcessingAborted: i18n.get("file.upload.input.labelFileProcessingAborted"),
        labelFileProcessingError: i18n.get(`file.upload.input.${FORM_ID}.labelFileProcessingError`),
        labelTapToCancel: i18n.get("file.upload.input.labelTapToCancel"),
        labelTapToRetry: i18n.get(`file.upload.input.${FORM_ID}.labelTapToRetry`),
        labelTapToUndo: i18n.get("file.upload.input.labelTapToUndo"),
        labelButtonRemoveItem: i18n.get("file.upload.input.labelButtonRemoveItem"),
        labelButtonAbortItemLoad: i18n.get("file.upload.input.labelButtonAbortItemLoad"),
        labelButtonRetryItemLoad: i18n.get("file.upload.input.labelButtonRetryItemLoad"),
        labelButtonAbortItemProcessing: i18n.get("file.upload.input.labelButtonAbortItemProcessing"),
        labelButtonUndoItemProcessing: i18n.get("file.upload.input.labelButtonUndoItemProcessing"),
        labelButtonRetryItemProcessing: i18n.get("file.upload.input.labelButtonRetryItemProcessing"),
        labelButtonProcessItem: i18n.get("file.upload.input.labelButtonProcessItem"),
        labelMaxFileSizeExceeded: i18n.get("file.upload.input.labelMaxFileSizeExceeded"),
        labelMaxFileSize: i18n.get("file.upload.input.labelMaxFileSize"),
        labelMinFileSize: i18n.get("file.upload.input.labelTapToUndo"),
        labelMinFileSizeExceeded: i18n.get("file.upload.input.labelFileTypeNotAllowed"),
        labelTotalFileSizeExceeded: i18n.get("file.upload.input.labelTotalFileSizeExceeded"),
        labelMaxTotalFileSize: i18n.get("file.upload.input.labelMaxTotalFileSize"),
        labelFileTypeNotAllowed: i18n.get("file.upload.input.labelFileTypeNotAllowed"),
        fileValidateTypeLabelExpectedTypes: i18n.get(`file.upload.input.${FORM_ID}.fileValidateTypeLabelExpectedTypes`),
    });

    // TODO: Pending migrate this logic to use our middleware (Axios)
    getServerOptions = () => ({
        load: null,
        fetch: null,
        restore: null,

        process: (fieldName, file, metadata, load, error, progress, abort) => {
            const {
                idForm,
                idFormField,
                idActivity,
                description,
                accessToken,
                lang,
                idEnvironment,
                accounts,
                onError,
                FORM_ID,
                values: { account },
            } = this.props;
            // fieldName is the name of the input field
            // file is the actual file object to send
            const abortRequest = {
                abort: () => {
                    abort(); // Let FilePond know the request has been cancelled
                },
            };

            const filename = file.name;
            const invalidFileErrorMessage = i18n.get(`forms.inputFile.${FORM_ID}.invalid.file.type`);
            if (
                filename.split(".")[0]?.length > 27 ||
                filename.substring(0, 19).toUpperCase() !== "EMISIONMASIVAECHEQ_"
            ) {
                onError({ errorMessage: invalidFileErrorMessage });
                error("oh");
                return abortRequest;
            }

            const formData = new FormData();

            if (idForm) {
                formData.append("idForm", idForm);
            }
            if (idFormField) {
                formData.append("idFormField", idFormField);
            }
            if (idActivity) {
                formData.append("idActivity", idActivity);
            }
            if (description) {
                formData.append("description", description);
            }
            if (account) {
                const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === account);
                formData.append("currency", completeDebitAccount.currency);
            }
            formData.append("_file", file, file.name);
            formData.append("channel", channel());
            formData.append("idEnvironment", idEnvironment);
            formData.append("lang", lang);

            const request = new XMLHttpRequest();
            request.open("POST", `${window.API_URL}/files.upload`);
            request.setRequestHeader("Authorization", `bearer ${accessToken}`);
            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            };

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = () => {
                let errorMessage = i18n.get("forms.notification.uploadFile.fail");
                if (request.status >= 200 && request.status < 300) {
                    const response = JSON.parse(request.responseText);
                    if (response.code.endsWith("I")) {
                        const { onFileProcess } = this.props;
                        const { idFile, validLines, invalidLines, ...data } = response.data;
                        const message =
                            invalidLines !== 0
                                ? i18n.get(`forms.inputFile.${FORM_ID}.invalid.file.errorLines`)
                                : i18n.get(`forms.inputFile.${FORM_ID}.uploadFile.success`, null, {
                                      VALID_LINES: validLines,
                                      TOTAL_LINES: validLines,
                                  });

                        load(response.data.idFile);

                        if (onFileProcess) {
                            onFileProcess({ idFile, message, validLines, invalidLines, ...data });
                        }
                    } else if (response.code.endsWith("W")) {
                        if (onError) {
                            const { invalidLines } = response.data;
                            const allowedEcheqs = configUtils.getInteger(
                                "frontend.MassiveEcheq.max.allowed.echeqs",
                                500,
                            );
                            errorMessage =
                                invalidLines && invalidLines > 0
                                    ? i18n.get(`forms.inputFile.${FORM_ID}.invalid.file.maxLines`, null, {
                                          ALLOWED_ECHEQS: allowedEcheqs,
                                      })
                                    : invalidFileErrorMessage;
                            onError({ errorMessage, ...response.data });
                        }
                        error("warning");
                    } else {
                        if (onError) {
                            onError({ errorMessage, ...response.data });
                        }
                        error("oh");
                    }
                } else {
                    // Can call the error method if something is wrong, should exit after
                    if (onError) {
                        onError({ errorMessage });
                    }
                    error("oh no");
                }
            };

            request.send(formData);

            // Should expose an abort method so the request can be cancelled
            return abortRequest;
        },
        revert: (uniqueFileId, load) => {
            // Should remove the earlier created temp file here
            if (uniqueFileId) {
                this.onRemoveFile({ serverId: uniqueFileId });
            }
            load();
        },
    });

    render() {
        const {
            idForm,
            idFormField,
            idActivity,
            description,
            accessToken,
            files,
            acceptedFileTypes,
            FORM_ID,
            enabledUpload,
            ...rest
        } = this.props;
        const labels = this.getLabels(FORM_ID);
        const serverOptions = this.getServerOptions();
        return (
            <FilePond
                ref={(ref) => {
                    this.pond = ref;
                }}
                server={serverOptions}
                {...labels}
                isDesktop
                {...rest}
                acceptedFileTypes={acceptedFileTypes}
                onprocessfile={this.onProcessFile}
                beforeRemoveFile={this.onRemoveFile}
                onupdatefiles={this.onUpdateFiles}
                allowDrop={enabledUpload}
                allowBrowse={enabledUpload}>
                {/* Update current files  */}
                {files &&
                    files.map((fileItem) => (
                        <File
                            key={fileItem.fileId}
                            src={fileItem.fileId}
                            origin="local"
                            name={fileItem.fileName}
                            size={fileItem.fileSize}
                            type={fileItem.fileType}
                        />
                    ))}
            </FilePond>
        );
    }
}

const mapStateToProps = (state) => ({
    accessToken: sessionSelectors.getAccessToken(state),
    idRelatedFile: fileSelectors.getIdRelatedFile(state),
    lang: i18nSelectors.getLang(state),
    idEnvironment: sessionSelectors.getActiveEnvironment(state).id,
    accounts: accountsSelectors.getAccounts(state),
});

export default connect(mapStateToProps)(FileXlsUploader);
