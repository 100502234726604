import React from "react";
import { node, string } from "prop-types";

const ListMessage = ({ children, className }) => (
    <div className={`text-center no-more-data ${className}`}>{children}</div>
);

ListMessage.propTypes = {
    children: node.isRequired,
    className: string,
};
ListMessage.defaultProps = {
    className: "",
};

export default ListMessage;
