import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { arrayOf, node, string, oneOfType, element, bool } from "prop-types";
import Row from "react-bootstrap/lib/Row";
import FieldLabel from "pages/_components/fields/FieldLabel";
import * as i18nUtils from "util/i18n";
import classNames from "classnames";

import Container from "pages/_components/Container";

class HeadingData extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    render() {
        const { children } = this.props;
        return <div className="content-data-wrapper col col-md-4 col-sm-12">{children}</div>;
    }
}

class HeadingDataGroup extends Component {
    static propTypes = {
        data: oneOfType([string, element, arrayOf(element)]).isRequired,
        label: string,
        containerClassName: string,
        dataClassName: string,
        spaceBetween: bool,
        notMarginYLabel: bool,
        addDots: bool,
        labelClassName: string,
    };

    static defaultProps = {
        label: "",
        containerClassName: "data-wrapper",
        dataClassName: "data-desc",
        labelClassName: "",
        spaceBetween: false,
        notMarginYLabel: false,
        addDots: false,
    };

    renderContent = () => {
        const { label, data, dataClassName, labelClassName, addDots } = this.props;

        if (label === "") {
            return <span className={dataClassName}>{data}</span>;
        }

        return (
            <>
                <span className={`data-label ${labelClassName}`}>{i18nUtils.get(label)}{ addDots ? ":" : ""}</span>{" "}
                <div className={dataClassName}>{data}</div>
            </>
        );
    };

    render() {
        const { label, data, dataClassName, spaceBetween, notMarginYLabel, containerClassName } = this.props;

        if (spaceBetween) {
            return (
                <Row style={{ justifyContent: "space-between" }}>
                    <FieldLabel labelKey={label} notMarginY={notMarginYLabel} />
                    <span className={dataClassName}>{data}</span>
                </Row>
            );
        }
        return <div className={containerClassName}>{this.renderContent()}</div>;
    }
}

class Heading extends Component {
    static propTypes = {
        children: node.isRequired,
        colClassName: string,
    };

    static defaultProps = {
        colClassName: "",
    };

    static Data = HeadingData;

    static DataGroup = HeadingDataGroup;

    render() {
        const { children, colClassName } = this.props;
        return (
            <div className="view-morphing theme-product-detail">
                <Container
                    className="container--layout align-items-center section-content-heading"
                    gridClassName="container-fluid">
                    <Col className={classNames("col", colClassName)}>{children}</Col>
                </Container>
            </div>
        );
    }
}

export default Heading;
