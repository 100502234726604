import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { routerActions } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { signaturesSchemesSelectors } from "reducers/administration";
import { signaturesSchemesActions } from "reducers/administration/advanced";

import SignaturesSchemeCreate from "pages/administration/_components/SignaturesSchemeCreate";

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    capFrequencyList: signaturesSchemesSelectors.capFrequencyList(state),
    caps: signaturesSchemesSelectors.caps(state),
    fetching: signaturesSchemesSelectors.fetching(state),
    functionalGroups: signaturesSchemesSelectors.functionalGroups(state),
    environmentProducts: signaturesSchemesSelectors.environmentProducts(state),
    masterCurrency: signaturesSchemesSelectors.masterCurrency(state),
    signature: signaturesSchemesSelectors.currentSignature(state),
    signatureGroupMap: signaturesSchemesSelectors.currentSignatureGroupMap(state),
    signatureTypeList: signaturesSchemesSelectors.signatureTypeList(state),
    credentialGroups: signaturesSchemesSelectors.getCredentialGroups(state),
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(signaturesSchemesActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignaturesSchemeCreate);
