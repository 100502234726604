import React, { Component } from "react";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, func, string } from "prop-types";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";

import { selectors as settingsSelectors, actions as settingsActions } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";

import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import MaskedTextField from "pages/_components/fields/MaskedTextField";

import * as maskUtils from "util/mask";
import * as i18n from "util/i18n";
import { goBack } from "react-router-redux/actions";
import classNames from "classnames";

const FORM_ID = "settings.changePhone";
class ChangePhoneConfirmation extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
        userFullName: string.isRequired,
        mobilePhone: string.isRequired,
        dispatch: func.isRequired,
    };

    confirmCodeForm = () => {
        const { isDesktop, isSubmitting, mobilePhone } = this.props;
        const maskedMobileNumber = `••••${mobilePhone.substr(mobilePhone.length - 4)}`;

        return (
            <>
                <Form className="above-the-fold">
                    <section
                        className={`align-items-center flex-grow container-white ${
                            isDesktop ? "with-margin container--layout" : ""
                        }`}>
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={10} lg={7} xl={7}>
                                    <div className="admin-content-center change-info-confirmation-explanation">
                                        <div
                                            className={classNames("justify-content-center d-flex masked-phone-data", {
                                                "d-block": !isDesktop,
                                            })}>
                                            <h2 className="masked-phone-message">
                                                {i18n.get("settings.changePhone.code.hasBeenSent")} {maskedMobileNumber}
                                            </h2>
                                        </div>
                                        <p className="change-info-confirm-text">
                                            <I18n
                                                id="settings.changePhone.code.hasBeenSent.info"
                                                component="span"
                                                componentProps={{ className: "settings-text mr-1" }}
                                            />
                                            <I18n
                                                id="settings.changePhone.code.didNotReceive"
                                                component="span"
                                                componentProps={{ className: "settings-text mr-1" }}
                                            />
                                            <I18n
                                                id="settings.changePhone.itWasNotReceived"
                                                component="span"
                                                componentProps={{ className: "settings-text to-uppercase" }}
                                            />
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row
                                className={classNames("justify-content-center pt-5 ", {
                                    "pb-6": !isDesktop,
                                })}>
                                <Col sm={12} md={6} lg={4} xl={4} className="pt-4">
                                    <Field
                                        idForm={FORM_ID}
                                        autoComplete="off"
                                        component={MaskedTextField}
                                        hidePlaceholder
                                        mask={maskUtils.invitationCodeMask()}
                                        maxLength={50}
                                        name="mobilePhoneCode"
                                        autoFocus={isDesktop}
                                    />
                                </Col>
                            </Row>

                            <Row className={classNames("justify-content-center", { "pb-8": isDesktop })}>
                                <Link to="/settings/changePhone" style={{ textDecoration: "none" }}>
                                    <Button
                                        className="didnot-receive-code-link-button p-0 mx-0"
                                        bsStyle="link"
                                        label="settings.changePhone.tryAgain"
                                        type="button"
                                    />
                                </Link>
                            </Row>
                        </Grid>
                    </section>

                    <section className="container--layout align-items-center container-whit">
                        <Grid className="form-content">
                            <Row className="justify-content-center">
                                <Col sm={12} md={6} lg={4} xl={4} className="col col-12">
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.continue"
                                        loading={isSubmitting}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    </section>
                </Form>
            </>
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    render() {
        const { userFullName, isDesktop } = this.props;

        return (
            <>
                <Notification scopeToShow="changePhone" />
                <div className="admin-detail-head px-0">
                    {isDesktop && <Head backLinkTo="/settings/changePhone" replace={{ USER_NAME: userFullName }} />}
                    <Head
                        title="settings.changePhoneConfirmation"
                        replace={{ USER_NAME: userFullName }}
                        titleClassName={isDesktop ? "" : "title-account-header-multiline-background-blue"}
                        centerContentClassName={isDesktop ? "pl-0" : "mx-5"}
                        onBack={isDesktop ? null : this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                    />
                </div>
                <MainContainer className="container-white">{this.confirmCodeForm()}</MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userFullName: sessionSelectors.getUserFullName(state),
    mobilePhone: settingsSelectors.getNewMobilePhone(state) || "",
});

export default compose(
    withRouter,
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            mobilePhoneCode: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                mobilePhoneCode: Yup.string().required(i18n.get(`${FORM_ID}.mobilePhoneCode.required`)),
            }),
        handleSubmit: ({ mobilePhoneCode }, formikBag) => {
            formikBag.props.dispatch(settingsActions.updateMobilePhone(mobilePhoneCode, formikBag));
        },
    }),
)(ChangePhoneConfirmation);
