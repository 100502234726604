export const types = {
    SET_ACTIVE_TOOLTIP: "tooltip/SET_ACTIVE_TOOLTIP",
    CLEAR_ACTIVE_TOOLTIP: "tooltip/CLEAR_ACTIVE_TOOLTIP",
};

export const INITIAL_STATE = {
    activeTooltip: null,
};

export default (state = INITIAL_STATE, action = {}) => {
    const { id } = action;

    switch (action.type) {
        case types.SET_ACTIVE_TOOLTIP:
            return { ...state, activeTooltip: id };
        case types.CLEAR_ACTIVE_TOOLTIP:
            return { ...state, activeTooltip: null };
        default:
            return state;
    }
};

export const actions = {
    setActiveTooltip: (id) => ({
        type: types.SET_ACTIVE_TOOLTIP,
        id,
    }),
    clearActiveTooltip: () => ({
        type: types.CLEAR_ACTIVE_TOOLTIP,
    }),
};

export const selectors = {
    getActiveTooltip: ({ tooltip }) => tooltip.activeTooltip,
};
