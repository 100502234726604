import React from "react";
import { bool, string } from "prop-types";
import { resizableRoute } from "pages/_components/Resizable";
import Image from "pages/_components/Image";

function ErrorProduct ({text, isDesktop}) {
    ErrorProduct.propTypes = {
        text: string.isRequired,
        isDesktop: bool.isRequired,
    };

    return (
        <div className={`newProduct col col-12 ${isDesktop ? "pl-25" : ""}`}>
            <div className="newProduct-row align-items-center">
                <Image src="images/warning-yellow-tri.svg" wrapperClassName="svg-wrapper w-auto mx-25" />
                <div className="newProduct-cell newProduct-cell--ellipsis">
                    <div className="data-name">
                        <span>{text}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default resizableRoute(ErrorProduct);
