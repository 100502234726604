/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { bool, shape, arrayOf, func } from "prop-types";
import { connect } from "react-redux";

import { actions as fundcorpActions, selectors as fundcorpSelectors } from "reducers/fundcorp";

import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import FilterTableHead from "pages/transactions/FilterTableHead";
import InfoTag from "pages/_components/InfoTag";
import { fundStatusTagColor } from "util/fundcorp";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import Image from "pages/_components/Image";
import { isMobileNative } from "util/device";
import NoDataMessage from "./NoDataMessage";
import FundcorpFilterSelect from "./FundcorpFilterSelect";

const FORM_ID = "fundcorp.list";
class FundcorpMovementAndRequestList extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        movements: arrayOf(shape({})).isRequired,
        filter: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    state = {
        selectedAmount: "",
    };

    filterChange = (filterName, filterType) => {
        const { dispatch, movements } = this.props;

        const filter = {
            filterSelected: filterName,
            directionFilter: filterType,
        };

        dispatch(fundcorpActions.filterChange(movements, filter));
    };

    renderHeader = (filterName, label) => {
        const { filter } = this.props;
        return (
            <div className="d-flex align-items-center">
                <I18n id={`${FORM_ID}.movement.column.${label}`} />
                <FilterTableHead filterName={filterName} columnFilter={filter} changeFilter={this.filterChange} />
            </div>
        );
    };

    renderListForMovements = () => {
        const { movements, filter, isDesktop } = this.props;

        const options = [
            {
                value: "fechaConcertacion",
                label: <I18n id="fundcorp.list.movement.filter.consultationDate" />,
            },
            {
                value: "fechaLiquidacion",
                label: <I18n id="fundcorp.list.movement.filter.settlementDate" />,
            },
            {
                value: "numero",
                label: <I18n id="fundcorp.list.movement.filter.number" />,
            },
            {
                value: "fondoNombre",
                label: <I18n id="fundcorp.list.movement.filter.fund" />,
            },
            {
                value: "tipoValorCuotaparteAbreviatura",
                label: <I18n id="fundcorp.list.movement.filter.class" />,
            },
            {
                value: "tipoDescripcion",
                label: <I18n id="fundcorp.list.movement.filter.type" />,
            },
            {
                value: "sharesQuantity",
                label: <I18n id="fundcorp.list.movement.filter.shares" />,
            },
            {
                value: "valorCuotaparte",
                label: <I18n id="fundcorp.list.movement.filter.balanceShare" />,
            },
            {
                value: "netAmount",
                label: <I18n id="fundcorp.list.movement.filter.balance" />,
            },
            {
                value: "estado",
                label: <I18n id="fundcorp.list.movement.filter.state" />,
            },
        ];
        const radioOptions = [
            {
                id: "0",
                label: <I18n id="fundcorp.list.movement.filter.ascendent" />,
            },
            {
                id: "1",
                label: <I18n id="fundcorp.list.movement.filter.descendent" />,
            },
        ];

        return (
            <>
                {movements && movements.length > 0 ? (
                    <>
                        {isDesktop && (
                            <div className="container-white mb-3 w-100 d-flex justify-content-end py-3">
                                <FundcorpFilterSelect
                                    options={options}
                                    radioOptions={radioOptions}
                                    changeFilter={this.filterChange}
                                    columnFilter={filter}
                                />
                            </div>
                        )}
                        {movements.map((item) => this.renderListForMovementsItem(item))}
                    </>
                ) : (
                    <NoDataMessage>
                        <I18n id={`${FORM_ID}.empty.message.information`} />
                    </NoDataMessage>
                )}
            </>
        );
    };

    renderListForMovementsItem = (item) => {
        const { isDesktop } = this.props;
        const { selectedAmount } = this.state;
        const {
            fechaConcertacion,
            fondoNombre,
            tipoValorCuotaparteAbreviatura,
            tipoDescripcion,
            numero,
            valorCuotaparte,
            monedaIso,
            fechaLiquidacion,
            estado,
            sharesQuantity,
            netAmount,
        } = item;

        const amountZero = selectedAmount === "netAmount" && netAmount === null;

        if (isDesktop) {
            return (
                <>
                    <CardContainer>
                        <Card
                            icon={
                                tipoDescripcion === "Rescate"
                                    ? "images/movementArrowLeft.svg"
                                    : "images/movementArrowRight.svg"
                            }
                            iconClass="fundcorp__arrow-icon"
                            className={amountZero ? "d-none" : null}
                            title={
                                <div className="d-flex fundcorp__card-title pl-075">
                                    <div className="d-flex fundcorp__card-date movements">
                                        <I18n
                                            id={`${FORM_ID}.movement.column.consultationDate`}
                                            componentProps={{ className: "fundcorp__date-info" }}
                                        />
                                        <FormattedDate
                                            date={fechaConcertacion}
                                            dateFormat="dd/MM/yyyy"
                                            className="fundcorp__date-data"
                                        />
                                    </div>
                                    <div className="d-flex fundcorp__card-date movements pl-0">
                                        <I18n
                                            id={`${FORM_ID}.movement.column.settlementDate`}
                                            componentProps={{ className: "fundcorp__date-info" }}
                                        />
                                        <FormattedDate
                                            date={fechaLiquidacion}
                                            dateFormat="dd/MM/yyyy"
                                            className="fundcorp__date-data"
                                        />
                                    </div>
                                    <div className="fundcorp__number">
                                        <I18n
                                            id={`${FORM_ID}.movement.column.number`}
                                            componentProps={{ className: "fundcorp__number-info" }}
                                        />
                                        <span className="fundcorp__number-data">{numero}</span>
                                    </div>
                                </div>
                            }
                            content={
                                <div className="fundcorp__card-content d-flex pl-075">
                                    <span className="fundcorp__name">{fondoNombre}</span>
                                    <div className="d-flex fundcorp__card-description">
                                        <I18n
                                            id={`${FORM_ID}.movement.column.class`}
                                            componentProps={{ className: "fundcorp__description-info" }}
                                        />
                                        <span className="fundcorp__description-data">
                                            {tipoValorCuotaparteAbreviatura}
                                        </span>
                                    </div>
                                    <span className="fundcorp__type">{tipoDescripcion}</span>
                                </div>
                            }
                            bottom={
                                <div className="d-flex fundcorp__card-bottom pl-075">
                                    {sharesQuantity && (
                                        <div className="fundcorp__shares">
                                            <I18n
                                                id={`${FORM_ID}.movement.column.shares`}
                                                componentProps={{ className: "fundcorp__shares-info" }}
                                            />
                                            <FormattedAmount
                                                className="data-amount"
                                                quantity={sharesQuantity}
                                                notBold
                                                noCurrency
                                                minimumDecimalsCustom={4}
                                                maximumDecimalsCustom={4}
                                            />
                                        </div>
                                    )}
                                    {valorCuotaparte !== 0 && (
                                        <div className="fundcorp__values">
                                            <I18n
                                                id={`${FORM_ID}.movement.column.shareValue`}
                                                componentProps={{ className: "fundcorp__values-info" }}
                                            />
                                            <FormattedAmount
                                                currency={monedaIso}
                                                className="data-amount"
                                                quantity={valorCuotaparte}
                                                minimumDecimalsCustom={7}
                                                maximumDecimalsCustom={7}
                                                notBold
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                            rightContent={
                                <div className="d-flex fundcorp__card-right-content">
                                    <div className="fundcorp__card-balance">
                                        {netAmount && (
                                            <FormattedAmount
                                                className="data-amount"
                                                quantity={netAmount}
                                                currency={monedaIso}
                                            />
                                        )}
                                        <InfoTag
                                            type="info"
                                            message={estado}
                                            tagBackground={fundStatusTagColor(estado)}
                                            moreStyles={{
                                                alignSelf: "flex-end",
                                                width: "fit-content",
                                                color: "black",
                                                margin: "auto",
                                                marginRight: 0,
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </CardContainer>
                </>
            );
        }
        return (
            <CardContainer className="fundcorp__card-mobile-movements px-3">
                <Card
                    className="fundcorp__card-mobile movements"
                    title={
                        <div className="fundcorp__card-title movements">
                            <Image
                                src={
                                    tipoDescripcion === "Rescate"
                                        ? "images/movementArrowLeft.svg"
                                        : "images/movementArrowRight.svg"
                                }
                            />
                            <span className="fundcorp__type-data">{tipoDescripcion}</span>
                            <div className="d-flex fundcorp__card-number">
                                <I18n
                                    id={`${FORM_ID}.movement.column.number`}
                                    componentProps={{ className: "fundcorp__number-info" }}
                                />
                                <span className="fundcorp__number-data">{numero}</span>
                            </div>
                        </div>
                    }
                    content={
                        <div className="fundcorp__card-content">
                            <hr />
                            <div className="d-flex fundcorp__card-state pt-0">
                                <I18n
                                    id={`${FORM_ID}.request.column.state`}
                                    componentProps={{ className: "fundcorp__state-info" }}
                                />
                                <InfoTag
                                    type="info"
                                    message={estado}
                                    tagBackground={fundStatusTagColor(estado)}
                                    moreStyles={{
                                        alignSelf: "flex-end",
                                        width: "fit-content",
                                        color: "black",
                                    }}
                                    tagClass={isMobileNative ? "mobile-native" : null}
                                />
                            </div>
                            <div className="d-flex fundcorp__card-name">
                                <I18n
                                    id={`${FORM_ID}.movement.column.fund`}
                                    componentProps={{ className: "fundcorp__name-info" }}
                                />
                                <span className="fundcorp__fund-data">{fondoNombre}</span>
                            </div>
                            <div className="d-flex fundcorp__card-class">
                                <I18n
                                    id={`${FORM_ID}.movement.filter.class`}
                                    componentProps={{ className: "fundcorp__class-info" }}
                                />
                                <span className="fundcorp__class-data">{tipoValorCuotaparteAbreviatura}</span>
                            </div>
                            <div className="d-flex fundcorp__card-date">
                                <I18n
                                    id={`${FORM_ID}.movement.column.consultationDate`}
                                    componentProps={{ className: "fundcorp__date-info" }}
                                />
                                <FormattedDate date={fechaConcertacion} dateFormat="dd/MM/yyyy" />
                            </div>
                            <div className="d-flex fundcorp__card-date">
                                <I18n
                                    id={`${FORM_ID}.movement.column.settlementDate`}
                                    componentProps={{ className: "fundcorp__date-info" }}
                                />
                                <FormattedDate date={fechaConcertacion} dateFormat="dd/MM/yyyy" />
                            </div>
                            <div className="d-flex fundcorp__card-shares">
                                {sharesQuantity && (
                                    <>
                                        <I18n
                                            id={`${FORM_ID}.movement.column.shares`}
                                            componentProps={{ className: "fundcorp__shares-info" }}
                                        />
                                        <FormattedAmount
                                            className="fundcorp__shares-data"
                                            quantity={sharesQuantity}
                                            notBold
                                            noCurrency
                                            minimumDecimalsCustom={4}
                                            maximumDecimalsCustom={4}
                                        />
                                    </>
                                )}
                            </div>
                            <div className="d-flex fundcorp__card-values">
                                {valorCuotaparte !== 0 && (
                                    <>
                                        <I18n
                                            id={`${FORM_ID}.movement.column.shareValue`}
                                            componentProps={{ className: "fundcorp__values-info" }}
                                        />
                                        <FormattedAmount
                                            currency={monedaIso}
                                            className="fundcorp__values-data"
                                            quantity={valorCuotaparte}
                                            notBold
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    }
                    rightContent={
                        <div className="d-flex fundcorp__card-right-content">
                            <div className="fundcorp__card-balance">
                                {netAmount && (
                                    <FormattedAmount
                                        className="data-amount"
                                        quantity={netAmount}
                                        notBold={isDesktop}
                                        currency={monedaIso}
                                    />
                                )}
                            </div>
                        </div>
                    }
                />
            </CardContainer>
        );
    };

    render() {
        return <div>{this.renderListForMovements()}</div>;
    }
}

const mapStateToProps = (state) => ({
    filter: fundcorpSelectors.getFilter(state),
});

export default connect(mapStateToProps)(FundcorpMovementAndRequestList);
