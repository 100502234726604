/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, node, func, bool, number, string } from "prop-types";
import { push } from "react-router-redux/actions";

import Button from "pages/_components/Button";
import PendingTransactionsListItem from "pages/desktop/widgets/_components/PendingTransactionsListItem";
import ScheduledTransactionsListItem from "pages/desktop/widgets/_components/ScheduledTransactionsListItem";
import WidgetList from "pages/desktop/widgets/WidgetList";
import WidgetLoading from "pages/_components/WidgetLoading";
import InfoWidget from "pages/desktop/widgets/_components/InfoWidget";

import { selectors } from "reducers/widgets";
import { actions as transactionsActions } from "reducers/transactions";

import * as i18nUtils from "util/i18n";
import * as config from "util/config";
import ErrorProduct from "./_components/ErrorProduct";
import WidgetHeader from "./_components/WidgetHeader";

const WIDGET_NAME = "pendingTransactions";

class PendingTransactions extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isFetching: bool,
        isEditable: bool,
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
        transactionCounts: number.isRequired,
        errorMsg: string.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        closeButton: null,
        isFetching: false,
        isEditable: false,
        isDesktop: true,
    };

    renderList = (list) => {
        const { dispatch, isFetching, errorMsg, isEditable } = this.props;
        let pendingTransactions = [];

        if (errorMsg) {
            return <ErrorProduct text={errorMsg} />;
        }

        if (list.length) {
            const onlyDrafts = list.every(({ transaction }) => transaction.idTransactionStatus === "DRAFT");
            pendingTransactions = list.map(({ transaction, transactionAmounts }) => {
                const currency = Object.keys(transactionAmounts)[0];
                const quantity = Object.values(transactionAmounts)[0];

                return {
                    ...transaction,
                    currency,
                    quantity,
                    onlyDrafts,
                };
            });
        }

        return (
            <WidgetLoading className="w-100" loading={!list.length && isFetching}>
                {list.length ? (
                    <div aria-hidden={isEditable}>
                        {pendingTransactions.map((item) => {
                            if (item?.programed) {
                                return <ScheduledTransactionsListItem key={item.idTransaction} isEditable={isEditable} {...item} />;
                            }
                            return (
                                <PendingTransactionsListItem key={item.idTransaction} dispatch={dispatch} isEditable={isEditable} {...item} />
                            );
                        })}
                    </div>
                ) : (
                    !isFetching && (
                        <div className="table table--products" aria-hidden={isEditable}>
                            <InfoWidget text={`desktop.widgets.${WIDGET_NAME}.empty`} />
                        </div>
                    )
                )}
            </WidgetLoading>
        );
    };

    render() {
        const {
            closeButton,
            errorMsg,
            draggableItemProps,
            transactionCounts,
            dispatch,
            isEditable,
            isDesktop,
        } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetPendingTransactions" };
        const maxItems = config.getInteger("dektop.max.items.widget");

        return (
            <WidgetList name={WIDGET_NAME} shouldMapList={false} close={closeButton} >
                {(list) => (
                    <div
                        role={isEditable ? "button" : "none"}
                        className={!isDesktop ? "widget transactions-widget" : null}
                        {...uiAutomationProp}
                        {...draggableItemProps}
                        aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                        <div>
                            <WidgetHeader
                                title={i18nUtils.get("desktop.widgets.pendingTransactions.title")}
                                action={
                                    <>
                                        {transactionCounts > maxItems && !isEditable && !errorMsg && (
                                            <Button
                                                bsStyle="outline"
                                                label="menu.seemore.label"
                                                type="button"
                                                image="images/arrow-right-blue.svg"
                                                onClick={() => {
                                                    dispatch(transactionsActions.initPendings());
                                                    dispatch(push("/pendingTransaction/list"));
                                                    dispatch(transactionsActions.changeStatusType("ALL"));
                                                    dispatch(
                                                        transactionsActions.loadListRequest(
                                                            {
                                                                pageNumber: 1,
                                                                status: "ALL",
                                                                filterPendingDispatch: false,
                                                                functionRequest: "pendingTransactions",
                                                            },
                                                            true,
                                                            false,
                                                        ),
                                                    );
                                                }}
                                            />
                                        )}
                                    </>
                                }
                                countBadge={transactionCounts}
                            />
                            <div className="widget__content">{this.renderList(list)}</div>
                        </div>
                    </div>
                )}
            </WidgetList>
        );
    }
}

const mapStateToProps = (state) => {
    const { data, errorMsg, isFetching } = selectors.getWidget(state, WIDGET_NAME);

    return {
        isFetching,
        transactionCounts: data.transactionCounts,
        errorMsg,
    };
};

export default connect(mapStateToProps)(PendingTransactions);
