import React, { Component } from "react";
import { bool, string } from "prop-types";
import { resizableRoute } from "pages/_components/Resizable";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class InfoWidget extends Component {
    static propTypes = {
        text: string.isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { text, isDesktop } = this.props;
        return (
            <div className={`newProduct col col-12 ${isDesktop ? "pl-25" : ""}`}>
                <div className="newProduct-row align-items-center">
                    <Image src="images/info-outline-blue.svg" wrapperClassName="svg-wrapper w-auto mx-25" />
                    <div className="newProduct-cell newProduct-cell--ellipsis">
                        <div className="data-name">
                            <I18n id={text} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default resizableRoute(InfoWidget);
