import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, arrayOf, string, shape, bool } from "prop-types";
import Notification from "pages/_components/Notification";
import { selectors, actions } from "reducers/checks";
import { echeqShape } from "util/checks";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import CreateFrequentDesitinationModal from "pages/forms/_components/CreateFrequentDesetinationModal";
import Yup from "yup";
import TextField from "pages/_components/fields/TextField";
import { actions as notificationActions } from "reducers/notification";
import DrawerModal from "pages/_components/drawer/DrawerModal";
import AddBeneficiary from "pages/checks/Echeqs/EcheqActions/AddBeneficiary";
import classNames from "classnames";

const FORM_ID = "echeqs";

class EmitCesion extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        echeqAction: shape({
            action: string.isRequired,
            echeqList: arrayOf(echeqShape),
            specialAttribute: string.isRequired,
        }).isRequired,
        cesionData: shape({
            beneficiary: shape({}),
        }).isRequired,
        setValues: func.isRequired,
        activeEnvironment: shape({
            permissions: shape({
                frequentDestination: bool,
            }),
        }).isRequired,
    };

    state = {
        frequentVisible: false,
        showDrawer: false,
        contentDrawer: "",
    };

    closeModal = () => {
        this.setState({ frequentVisible: false });
    };

    openModal = () => {
        const { cesionData } = this.props;

        if (!cesionData?.beneficiary?.reference) {
            this.setState({ frequentVisible: true });
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    cleanBeneficiary = () => {
        const { dispatch } = this.props;
        dispatch(actions.beneficiaryEcheq({}, "checks/CESION_ECHEQ_BENEFICIARY"));
    };

    componentWillMount = () => {
        const { echeqAction, cesionData, setValues } = this.props;

        if (!echeqAction.action) {
            this.handleBack();
        }

        const values = cesionData || {};
        setValues({ ...values, reference: values.reference || "", referencevalue: values.referencevalue || "" });
    };

    selectBeneficiary = (beneficiary) => {
        const { dispatch } = this.props;

        dispatch(
            actions.beneficiaryEcheq(
                {
                    document:
                        beneficiary?.document ||
                        `${beneficiary?.recipientDocumentType} ${beneficiary?.recipientDocumentNumber}`,
                    reference: beneficiary?.headline || beneficiary?.recipientName,
                },
                "checks/CESION_ECHEQ_BENEFICIARY",
            ),
        );

        this.setState({ frequentVisible: false });
    };

    closeDrawer = () => {
        this.setState({ showDrawer: false });
    };

    renderHeader = () => {
        const { echeqAction, isDesktop } = this.props;
        const title =
            echeqAction?.echeqList.length > 1
                ? `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.title.multi`
                : `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.title`;

        if (isDesktop) {
            return (
                <>
                    <Head onBack={this.handleBack} />
                    <Head title={title} centerContentClassName="p-0" />
                </>
            );
        }

        return (
            <Head
                headerClassName="blue-main-header-mobile"
                titleClassName="w-100"
                onBack={this.handleBack}
                title={title}
                emptyRightOption
            />
        );
    };

    render() {
        const { cesionData, isDesktop, activeEnvironment } = this.props;
        const { showDrawer, contentDrawer, frequentVisible } = this.state;

        if (cesionData?.beneficiary?.reference && showDrawer) {
            this.closeDrawer();
        }

        return (
            <>
                <CreateFrequentDesitinationModal
                    isVisible={frequentVisible}
                    onClick={this.selectBeneficiary}
                    closeModal={this.closeModal}
                    isDesktop={isDesktop}
                />
                <Notification scopeToShow="echeqs" />
                <div className="admin-detail-head px-0">{this.renderHeader()}</div>
                <MainContainer>
                    <div className="above-the-fold">
                        {showDrawer && (
                            <DrawerModal
                                content={contentDrawer}
                                width={isDesktop ? "600px" : "100vw"}
                                showDrawerRemote={showDrawer}
                                isDesktop={isDesktop}
                                title={`${isDesktop ? "" : "echeqs.add.beneficiary.title"}`}
                                headerContent={
                                    !isDesktop && (
                                        <div className="toolbar-item view-title align-self-center mx-0">
                                            <I18n
                                                id="echeqs.add.beneficiary.title"
                                                component="h1"
                                                componentProps={{
                                                    className: "title-content header-mobile-title-background-blue mb-0",
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                closeRemotely={() => this.closeDrawer()}
                            />
                        )}
                        <Container
                            className="flex-grow align-items-center container-white mt-4"
                            gridClassName="form-content">
                            <Col xs={12} md={8} lg={6} style={{ alignItems: "center" }} className="px-0 py-2 d-flex">
                                <Col xs={isDesktop ? 7 : 8} style={{ display: "flex", alignItems: "center" }}>
                                    <span className={classNames("control-label")}>
                                        {i18n.get(`${FORM_ID}.beneficiary.data`).toUpperCase()}
                                    </span>
                                </Col>
                                <Col
                                    xs={isDesktop ? 5 : 4}
                                    className="justify-content-end"
                                    style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        className={`btn-outline plus-button ${
                                            !isDesktop ? "radius mr-4 small-svg bold-svg mt-25" : ""
                                        }`}
                                        onClick={() =>
                                            this.setState({
                                                showDrawer: true,
                                                contentDrawer: (
                                                    <AddBeneficiary
                                                        type="checks/CESION_ECHEQ_BENEFICIARY"
                                                        isDesktop={isDesktop}
                                                    />
                                                ),
                                            })
                                        }>
                                        <Image src="images/plus.svg" />
                                        {isDesktop && (
                                            <I18n
                                                id={`${FORM_ID}.add.beneficiary`}
                                                componentProps={{ style: { lineHeight: 1 } }}
                                            />
                                        )}
                                    </Button>
                                    {activeEnvironment?.permissions.frequentDestination ? (
                                        <Button
                                            bsStyle="link"
                                            image="images/frequent-destination.svg"
                                            className="no-shadow m-0 p-0 svg30 width-3"
                                            onClick={this.openModal}
                                        />
                                    ) : (
                                        undefined
                                    )}
                                </Col>
                            </Col>
                        </Container>
                        {cesionData?.beneficiary?.reference && (
                            <Container
                                className="flex-grow align-items-center container-white my-2 with-padding"
                                gridClassName="form-content">
                                <Col xs={12} md={8} lg={6}>
                                    <div className="space-between">
                                        <div>
                                            <span className="control-label" style={{ marginRight: "0.5rem" }}>
                                                {i18n.get(`${FORM_ID}.reference.beneficiary`)}
                                            </span>
                                            <span className="data-desc">
                                                {cesionData?.beneficiary?.reference?.replaceAll("#", "Ñ")}
                                            </span>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn-link"
                                            onClick={() => this.cleanBeneficiary()}>
                                            <Image
                                                src="images/cross-mobile-blue.svg"
                                                styles={{ width: "25px", height: "25px" }}
                                            />
                                        </button>
                                    </div>
                                </Col>
                            </Container>
                        )}
                        <Form>
                            <Container
                                className="flex-grow align-items-center container-white my-2"
                                gridClassName="form-content">
                                <Col xs={12} md={8} lg={6} className="px-0">
                                    <Col xs={12}>
                                        <Col xs={12} md={6} className={classNames("pl-0 py-1", { "pr-0": !isDesktop })}>
                                            <Field
                                                component={TextField}
                                                hidePlaceholder
                                                disabled={!cesionData?.beneficiary?.reference}
                                                idForm={FORM_ID}
                                                name="address"
                                                type="text"
                                                style={{ paddingTop: "13px", paddingBottom: "13px" }}
                                                isDisabled={!cesionData?.beneficiary?.reference}
                                            />
                                        </Col>
                                    </Col>
                                    <Col xs={12} className="emit-cesion-reference">
                                        <Col xs={12} md={6} className={classNames("pl-0 py-1", { "pr-0": !isDesktop })}>
                                            <Field
                                                component={TextField}
                                                hidePlaceholder
                                                disabled={!cesionData?.beneficiary?.reference}
                                                idForm={FORM_ID}
                                                name="reference"
                                                type="text"
                                                style={{ paddingTop: "13px", paddingBottom: "13px" }}
                                                isDisabled={!cesionData?.beneficiary?.reference}
                                            />
                                        </Col>
                                        <Col xs={12} md={6} className={classNames("pr-0 py-1", { "pl-0": !isDesktop })}>
                                            <Field
                                                component={TextField}
                                                hidePlaceholder
                                                disabled={!cesionData?.beneficiary?.reference}
                                                idForm={FORM_ID}
                                                name="referencevalue"
                                                type="text"
                                                style={{ paddingTop: "13px", paddingBottom: "13px" }}
                                                isDisabled={!cesionData?.beneficiary?.reference}
                                            />
                                        </Col>
                                    </Col>
                                </Col>
                            </Container>
                            <Container className="flex-grow align-items-center" gridClassName="form-content">
                                <Col xs={12} md={4} className={classNames({ "mt-1": !isDesktop, "mt-8": isDesktop })}>
                                    <Container
                                        className="flex-grow align-items-center mt-4"
                                        gridClassName="form-content">
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.continue"
                                            style={{ marginTop: "2rem" }}
                                        />
                                    </Container>
                                </Col>
                            </Container>
                        </Form>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    option: selectors.getEcheqOption(state),
    echeqAction: selectors.getEcheqsToDoActions(state),
    cesionData: selectors.getEmitCesionData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            address: "",
            referencevalue: "",
            reference: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                address: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
            }),
        handleSubmit: (form, formikBag) => {
            const { dispatch, cesionData } = formikBag.props;

            if (!cesionData?.beneficiary?.reference) {
                dispatch(notificationActions.showNotification(`${FORM_ID}.empty.beneficiary`, "error", ["echeqs"]));
            } else {
                dispatch(actions.echeqCesionData(form));
                dispatch(push("/echeqs/action/cesion/confirm"));
            }
        },
    }),
)(EmitCesion);
