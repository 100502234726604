import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Col from "react-bootstrap/lib/Col";
import { bool, func, string } from "prop-types";
import { Form, Field, withFormik } from "formik";
import Yup from "yup";
import Button from "pages/_components/Button";
import classNames from "classnames";

import * as i18nUtils from "util/i18n";

import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import {
    actions as frequentDestinationActions,
    selectors as frequentDestinationSelector,
} from "reducers/frequentDestination";
import { routerActions } from "react-router-redux/actions";
import Credential from "pages/_components/fields/credentials/Credential";
import Heading from "pages/_components/Heading";
import isTokenActive from "util/token";

const FORM_ID = "modifyFrequentDestinations";
class ModifyFrequentDestinationConfirmations extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        frequentDestination: {},
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        name: string.isRequired,
        email: string.isRequired,
        idFrequentDestination: string.isRequired,
    };

    static defaultProps = {
        frequentDestination: {},
    };

    componentDidMount = () => {
        const { dispatch, idFrequentDestination, frequentDestination } = this.props;

        if (frequentDestination) {
            dispatch(frequentDestinationActions.modifyFrequentDestinationPreRequest(idFrequentDestination));
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.goBack());
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1>{i18nUtils.get("frequentDestination.modify.tittle")}</h1>
        </div>
    );

    render() {
        const { fetching, frequentDestination, name, email, isDesktop } = this.props;
        const containerClassName = `transfer-data data-wrapper-flex ${
            !isDesktop ? "space-between mb-0 container-details" : ""
        }`;
        const labelClassName = isDesktop ? "mb-0" : "";
        return (
            <Fragment>
                <Notification scopeToShow="frequentDestinationModifyConfirm" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        closeLinkTo="/FrequentDestination"
                        centerElement={isDesktop ? null : this.centerContentMobile}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        emptyRightOption
                    />
                    {isDesktop && (
                        <div className="view-title">
                            <h1>{i18nUtils.get("frequentDestination.modify.tittle")}</h1>
                        </div>
                    )}
                </div>

                {frequentDestination && (
                    <MainContainer showLoader={fetching}>
                        <Form className={!isDesktop ? "above-the-fold" : "confirmation__container"}>
                            <Container
                                className={!isDesktop && "align-items-center flex-grow container-white with-margin"}
                                gridClassName={!isDesktop ? "form-content" : "confirmation__form confirmation__box"}>
                                <div className={classNames("admin-content-center", { "mb-3": !isDesktop })}>
                                    <I18n
                                        id="frequentDestination.modify.sustitle"
                                        component={!isDesktop ? "h2" : "h1"}
                                        componentProps={!isDesktop && { className: "font-size-20-px mx-3" }}
                                    />
                                </div>
                                <Col sm={6} lg={12} className="col col-12">
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.numberAccount"
                                        data={`${frequentDestination.accountNumber}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.accountType"
                                        data={`${frequentDestination.productType}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.own"
                                        data={`${frequentDestination.isOwn === "1" ? "SI" : "NO"}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.cbu"
                                        data={`${frequentDestination.cbu}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.document"
                                        data={`${frequentDestination.recipientDocumentType}
                                            ${frequentDestination.recipientDocumentNumber}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.recipientName"
                                        data={`${frequentDestination.recipientName}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.bank"
                                        data={`${frequentDestination.localBank}/${frequentDestination.subsidiaryBank}`}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.email"
                                        data={email}
                                    />
                                    <Heading.DataGroup
                                        containerClassName={containerClassName}
                                        labelClassName={labelClassName}
                                        label="frequentDestination.create.confirmate.referent"
                                        data={`${name}`}
                                    />

                                    <div className="admin-content-center mt-5">
                                        {!isTokenActive(!isDesktop) ? (
                                            <I18n
                                                id="transfer.summary.confirm.label"
                                                component="p"
                                                componentProps={{
                                                    className: "account-dropdown-header-font font-light px-3",
                                                }}
                                            />
                                        ) : (
                                            <div className="admin-content-center pb-3">
                                                <I18n
                                                    id="confirmation.withoutotp.text"
                                                    component="p"
                                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col sm={12} className="col col-12 d-flex justify-content-center">
                                    <Col sm={12} md={6} lg={12} className="col col-12 px-0">
                                        <Field
                                            placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                                            name="otp"
                                            type="otp"
                                            idForm={FORM_ID}
                                            component={Credential}
                                        />
                                    </Col>
                                </Col>
                                <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                                    <Col lg={12} md={12} sm={12} className="px-0">
                                        <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                                            <div className="admin-content-center">
                                                <Button
                                                    type="submit"
                                                    bsStyle="primary"
                                                    label="global.confirm"
                                                    loading={fetching}
                                                    image="images/arrowRight.svg"
                                                    imageStyle={isDesktop ? "mr-2" : "mr-1"}
                                                    className="text-capitalize"
                                                />
                                            </div>
                                        </Col>
                                    </Col>
                                </Container>
                            </Container>
                        </Form>
                    </MainContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    frequentDestination: frequentDestinationSelector.getFrequentDestinationModify(state),
    name: frequentDestinationSelector.getReference(state),
    email: frequentDestinationSelector.getEmail(state),
    fetching: frequentDestinationSelector.getFetching(state),
    idFrequentDestination: frequentDestinationSelector.getIdDestinationModify(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    otp: Yup.string().required(i18nUtils.get("accounts.new.account.otp.error.required")),
                }),
            ),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, name, email, frequentDestination } = formikBag.props;
            dispatch(
                frequentDestinationActions.modifyFrequentDestinationRequest(
                    frequentDestination.idFrequentDestination,
                    name,
                    email,
                    otp,
                    formikBag,
                ),
            );
        },
    }),
)(ModifyFrequentDestinationConfirmations);
