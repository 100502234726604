import { string, func, node, bool } from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { selectors, actions as tooltipActions } from "reducers/tooltip";
import Button from "pages/_components/Button";

const Tooltip = ({ id, dispatch, activeTooltip, message, left, messageClass, showOnHover = false }) => {
    const [addClass, setAddClass] = useState("");
    const tooltipRef = useRef(null);

    useEffect(() => {
        const tooltip = tooltipRef.current;

        if (tooltip) {
            const tooltipRect = tooltip.getBoundingClientRect();
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            if (tooltipRect.right > viewportWidth || tooltipRect.bottom > viewportHeight) {
                setAddClass("no-overflow");
            }
            setTimeout(() => {
                tooltip.style.opacity = "1";
                tooltip.style.visibility = "visible";
            }, 100);
        }
    }, [activeTooltip]);

    const handleClick = (e) => {
        e.preventDefault();
        if (activeTooltip === id) {
            dispatch(tooltipActions.clearActiveTooltip());
        } else {
            dispatch(tooltipActions.setActiveTooltip(id));
        }
    };
    
    const handleHoverStart = (e) => {
        e.preventDefault();
        dispatch(tooltipActions.setActiveTooltip(id));
    };
    
    const handleHoverEnd = (e) => {
        e.preventDefault();
        if (activeTooltip === id) {
            dispatch(tooltipActions.clearActiveTooltip());
        }
    }
    return (
        <div className="hint__tooltip">
            {activeTooltip === id && left && (
                <div
                    style={{ visibility: "hidden", opacity: "0" }}
                    ref={tooltipRef}
                    className={
                        message && `hint__tooltip-msg ${left && "hint_tooltip-msg-left"} ${addClass} ${messageClass}`
                    }>
                    <div>{message}</div>
                </div>
            )}
            <Button 
                image="images/infoUserInvite.svg" 
                onClick={!showOnHover ? handleClick : undefined}
                onMouseOver={showOnHover ? handleHoverStart : undefined}
                onMouseDown={showOnHover ? handleHoverEnd : undefined} />
            {activeTooltip === id && !left && (
                <div
                    style={{ visibility: "hidden", opacity: "0" }}
                    ref={tooltipRef}
                    className={message && `hint__tooltip-msg ${addClass} ${messageClass}`}>
                    <div>{message}</div>
                </div>
            )}
        </div>
    );
};

Tooltip.propTypes = {
    id: string.isRequired,
    dispatch: func.isRequired,
    activeTooltip: string.isRequired,
    message: node.isRequired,
    left: bool,
    showOnHover: bool,
    messageClass: string,
};

Tooltip.defaultProps = {
    left: false,
    showOnHover: false,
    messageClass: "",
};

const mapStateToProps = (state) => ({
    activeTooltip: selectors.getActiveTooltip(state),
    nodeMessage: null,
});

export default connect(mapStateToProps)(Tooltip);
