import React, { Component } from "react";
import { Button as BSButton } from "react-bootstrap";
import { bool, func, shape, string, node } from "prop-types";
import classNames from "classnames";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class Button extends Component {
    static propTypes = {
        label: string,
        loading: bool,
        type: string,
        disabled: bool,
        className: string,
        image: string,
        imageStyle: string,
        bsStyle: string,
        onClick: func,
        href: string,
        id: string,
        replace: shape({}),
        block: bool,
        fitWidth: bool,
        defaultLabelText: string,
        style: shape({}),
        children: node.isRequired,
    };

    static defaultProps = {
        disabled: false,
        loading: false,
        fitWidth: false,
        block: true,
        type: "button",
        defaultLabelText: "",
        replace: null,
        style: null,
        label: "",
        className: "",
        image: "",
        imageStyle: "",
        href: "",
        id: "",
        bsStyle: undefined,
        onClick: () => {},
    };

    constructor(props) {
        super(props);
        this.alreadyClicked = false;
    }

    resetLink = () => {
        this.alreadyClicked = false;
    };

    renderChildren = ({ children, image, label, defaultLabelText, imageStyle, fitWidth, customLabel, ...replace }) => (
        <>
            {children ? (
                <>{children}</>
            ) : (
                <>
                    {image && (
                        <Image
                            src={image}
                            className="svg-icon"
                            wrapperClassName={`svg-wrapper my-0 ${imageStyle} ${
                                fitWidth ? "fit-width-context-menu" : ""
                            }`}
                        />
                    )}
                    {label !== null && <I18n id={label} {...replace} defaultValue={defaultLabelText} />}
                </>
            )}
        </>
    );

    render() {
        const {
            bsStyle,
            block,
            className,
            id,
            image,
            label,
            loading = false,
            disabled,
            type,
            defaultLabelText,
            style,
            href,
            onClick,
            replace,
            children,
            imageStyle,
            fitWidth,
            ...props
        } = this.props;

        const target = href ? { href } : { onClick };

        return (
            <BSButton
                {...target}
                id={id}
                type={type}
                disabled={loading || disabled}
                block={block}
                className={classNames(className, {
                    "is-loading": loading,
                    "disabled-button": disabled,
                })}
                bsStyle={bsStyle}
                style={style}
                {...props}>
                {loading ? (
                    <Spinner />
                ) : (
                    this.renderChildren({
                        children,
                        image,
                        label,
                        defaultLabelText,
                        imageStyle,
                        fitWidth,
                        ...replace,
                    })
                )}
            </BSButton>
        );
    }
}

function Spinner() {
    return (
        <>
            <span className="btn-loading-indicator">
                <span />
                <span />
                <span />
            </span>
            <span className="btn-loading-text">Loading</span>
        </>
    );
}

export default Button;
