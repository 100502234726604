/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { string, func, shape, bool } from "prop-types";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";

import { selectors as sessionSelectors } from "reducers/session";

import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import * as i18n from "util/i18n";

class PaymentAFIPSummaryData extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        summary: shape({
            debitAccount: string.isRequired,
            paymentAFIP: shape({}).isRequired,
        }).isRequired,
        fullName: string.isRequired,
        isDesktop: bool.isRequired,
        signature: bool,
    };

    static defaultProps = {
        signature: false,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    preparedBy = () => {
        const { summary, fullName } = this.props;
        return (
            <p>
                {summary?.creatorFullName || fullName} -{" "}
                <FormattedDate date={summary?.creationDate || new Date()} dateFormat="dd/MM/yyyy" showTime />
            </p>
        );
    };

    signBy = () => {
        const { summary } = this.props;
        return (
            <p>
                {summary?.signatures.map((object) => (
                    <>
                        {object?.userFirstName.concat(" ", object?.userLastName)} -{" "}
                        <FormattedDate date={object?.creationDateTime} dateFormat="dd/MM/yyyy" showTime />
                        <br />
                    </>
                ))}
            </p>
        );
    };

    render() {
        const {
            summary,
            summary: {
                paymentAFIP: {
                    numberVEP,
                    fiscalPeriod,
                    dueDate,
                    amount,
                    conceptDesc,
                    subConceptDesc,
                    description,
                    formNumber,
                    advancePayment,
                    taxpayerCUIT,
                    contributorsCUIT,
                },
            },
            isDesktop,
            signature,
        } = this.props;

        if (!summary) {
            return null;
        }

        return (
            <>
                <CardContainer className="confirmation__cards-container mt-0">
                    <Card
                        className="confirmation__cards"
                        icon={isDesktop && "images/transfer-PENDING.svg"}
                        iconClass="card__pending-transaction-icon"
                        title={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    <div
                                        className={`confirmation-cards__title ${Number(numberVEP) &&
                                            "confirmation-cards-separator"}`}>
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get("pay.paymentAFIP.dueDate")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <FormattedDate date={dueDate} />
                                        </div>
                                    </div>
                                    {Number(numberVEP) && (
                                        <div className="confirmation-cards__title">
                                            <div className="confirmation-cards__title-data no-wrap">
                                                <span>{i18n.get("pay.paymentAFIP.numVEP")}</span>
                                            </div>
                                            <div className="confirmation-cards__title-info">
                                                <span>{Number(numberVEP)}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="confirmation-cards__title">
                                    <div className="confirmation-cards__title-info no-wrap">
                                        <span>{description}</span>
                                    </div>
                                </div>
                            )
                        }
                        content={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    <div className="confirmation-cards__content pb-1">
                                        <div className="confirmation-cards__content-info no-wrap">
                                            <span>{description}</span>
                                        </div>
                                    </div>
                                    {conceptDesc && (
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("pay.paymentAFIP.concept")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{conceptDesc}</span>
                                            </div>
                                        </div>
                                    )}
                                    {subConceptDesc && (
                                        <div className="confirmation-cards__content info-type-b">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("pay.paymentAFIP.subConcept")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{subConceptDesc}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("pay.paymentAFIP.dueDate")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <FormattedDate date={dueDate} />
                                        </div>
                                    </div>
                                    {Number(numberVEP) && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data no-wrap">
                                                <span>{i18n.get("pay.paymentAFIP.numVEP")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{Number(numberVEP)}</span>
                                            </div>
                                        </div>
                                    )}
                                    {conceptDesc && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("pay.paymentAFIP.concept")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{conceptDesc}</span>
                                            </div>
                                        </div>
                                    )}
                                    {subConceptDesc && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("pay.paymentAFIP.subConcept")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{subConceptDesc}</span>
                                            </div>
                                        </div>
                                    )}
                                    {(contributorsCUIT || taxpayerCUIT) && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("pay.paymentAFIP.taxpayerCUIT.mobile")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{contributorsCUIT || taxpayerCUIT}</span>
                                            </div>
                                        </div>
                                    )}
                                    {fiscalPeriod && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("pay.paymentAFIP.fiscalPeriod")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{fiscalPeriod}</span>
                                            </div>
                                        </div>
                                    )}
                                    {formNumber && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("pay.paymentAFIP.formNumber")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{formNumber}</span>
                                            </div>
                                        </div>
                                    )}
                                    {advancePayment && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("pay.paymentAFIP.advancePayment")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{advancePayment}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        bottom={
                            isDesktop && (
                                <div className="d-flex align-items-center">
                                    {(contributorsCUIT ||
                                        taxpayerCUIT) && (
                                            <div
                                                className={`confirmation-cards__bottom ${fiscalPeriod ||
                                                    formNumber ||
                                                    (advancePayment && "confirmation-cards-separator")}`}>
                                                <div className="confirmation-cards__bottom-data">
                                                    <span>{i18n.get("pay.paymentAFIP.taxpayerCUIT")}</span>
                                                </div>
                                                <div className="confirmation-cards__bottom-info">
                                                    <span>{contributorsCUIT || taxpayerCUIT}</span>
                                                </div>
                                            </div>
                                        )}
                                    {fiscalPeriod && (
                                        <div
                                            className={`confirmation-cards__bottom ${formNumber ||
                                                (advancePayment && "confirmation-cards-separator")}`}>
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get("pay.paymentAFIP.fiscalPeriod")}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{fiscalPeriod}</span>
                                            </div>
                                        </div>
                                    )}
                                    {formNumber && (
                                        <div
                                            className={`confirmation-cards__bottom ${advancePayment &&
                                                "confirmation-cards-separator"}`}>
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get("pay.paymentAFIP.formNumber")}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{formNumber}</span>
                                            </div>
                                        </div>
                                    )}
                                    {advancePayment && (
                                        <div className="confirmation-cards__bottom">
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get("pay.paymentAFIP.advancePayment")}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{advancePayment}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                        rightContent={
                            <div className="confirmation-cards__right">
                                <div className="confirmation-cards__right-data">
                                    <FormattedAmount currency={amount.currency} quantity={amount.quantity} />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>

                {signature && (
                    <>
                        <I18n
                            component="h3"
                            id="accounts.new.account.signatures.label"
                            componentProps={{ className: "font-size-25-px" }}
                        />
                        <Heading.DataGroup
                            containerClassName={`transfer-data data-wrapper-flex space-between ${
                                isDesktop ? "" : "summary-data"
                            }`}
                            label="accounts.new.account.signatures.preparedBy.label"
                            data={this.preparedBy()}
                        />
                        {summary?.signatures?.length > 0 && (
                            <>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex space-between ${
                                        isDesktop ? "" : "summary-data"
                                    }`}
                                    label="accounts.new.account.signatures.signBy.label"
                                    data={this.signBy()}
                                />
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    fullName: sessionSelectors.getUser(state).userFullName,
});

export default compose(connect(mapStateToProps))(PaymentAFIPSummaryData);
