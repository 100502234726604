import React, { Fragment, Component } from "react";
import { compose } from "redux";
import classNames from "classnames";
import { routerActions } from "react-router-redux/actions";
import { connect } from "react-redux";
import { string, bool, func, arrayOf, number, shape } from "prop-types";

import * as fileMiddleware from "middleware/file";
import { actions as fileActions } from "reducers/files";
import { actions as formActions } from "reducers/form";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { actions, selectors as multilineFileSelectors } from "reducers/formFields/multilineFile";
import { actions as notificationActions } from "reducers/notification";
import * as i18nUtils from "util/i18n";

import DetailBox from "pages/_components/detailBox/DetailBox";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import FileActions from "pages/forms/_components/_fields/_commons/FileActions";
import FieldError from "pages/_components/fields/FieldError";
import withFocus from "pages/_components/withFocus";
import FileCsvUploader from "pages/_components/FileCsvUploader";

class FileMultipleTransfer extends Component {
    static propTypes = {
        idField: string.isRequired,
        idForm: string.isRequired,
        isDesktop: bool.isRequired,
        field: shape({}),
        form: shape({}),
        formTitle: string,
        dispatch: func.isRequired,
        setValue: func.isRequired,
        processedFileData: shape({
            fileIdentifier: string.isRequired,
            hasFile: bool.isRequired,
            validLines: number,
            invalidLines: number,
            totalAmount: shape({
                currency: string.isRequired,
                quantity: number.isRequired,
            }).isRequired,
        }).isRequired,
        fieldList: arrayOf(shape({})),
        idRelatedFile: string,
        idFile: string,
        value: arrayOf(),
        label: string.isRequired,
        maxFileSizeMB: number.isRequired,
        acceptedFileTypes: arrayOf().isRequired,
        isFocused: bool,
        toggleIsFocused: func,
        typePayment: string,
        FORM_ID: string.isRequired,
        enabledUpload: bool.isRequired,
        rememberMassiveData: bool.isRequired,
        values: shape({}).isRequired,
    };

    static defaultProps = {
        field: null,
        form: null,
        fieldList: [],
        idRelatedFile: null,
        idFile: null,
        formTitle: "",
        value: [],
        isFocused: false,
        toggleIsFocused: null,
        typePayment: "",
    };

    componentDidMount() {
        const { dispatch, processedFileData, rememberMassiveData, setValue } = this.props;

        if (rememberMassiveData && processedFileData.hasFile) {
            setValue([processedFileData.filesMetadata]);
            this.handleNotification(processedFileData);
        }
        dispatch(transactionLinesActions.onPaymentMethodChange());
    }

    componentDidUpdate(prevProps) {
        const { processedFileData } = this.props;

        if (processedFileData && prevProps.processedFileData !== processedFileData) {
            this.handleNotification(processedFileData);
        }
    }

    handleNotification = (processedFileData) => {
        const { dispatch } = this.props;
        const { filesMetadata, message, invalidLines, typeError } = processedFileData;

        if (filesMetadata) {
            if (!invalidLines) {
                dispatch(notificationActions.showNotification(`${message}.`, "success", ["form"]));
            } else {
                dispatch(notificationActions.showNotification(`${message}.`, typeError || "warning", ["form"]));
            }
        }
    };

    handleFileProcess = (data) => {
        const { dispatch } = this.props;
        dispatch(actions.onFileProcess(data));
    };

    handleError = (data) => {
        const { dispatch } = this.props;
        const { errorMessage } = data;

        dispatch(notificationActions.showNotification(`${errorMessage}.`, "error", ["form"]));
    };

    renderLinesInfo = () => {
        const { processedFileData } = this.props;
        const { invalidLines, validLines } = processedFileData;

        if (!invalidLines || invalidLines === 0) {
            return `${validLines}`;
        }

        return (
            <div>
                {validLines}/{validLines + invalidLines}
            </div>
        );
    };

    renderAmount = () => {
        const { processedFileData } = this.props;
        const { invalidLines, totalAmount } = processedFileData;

        if (!invalidLines) {
            return (
                <b>
                    <FormattedAmount className="data-desc" {...totalAmount} notBold="true" />
                </b>
            );
        }

        return (
            <Fragment>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <b>
                        <FormattedAmount className="data-desc" {...totalAmount} notBold="true" />
                    </b>
                    <b>*</b>
                </div>

                <div className="detailBox-data-aux">
                    <I18n id="forms.inputFile.massivePayments.calculated.amount.info" />
                </div>
            </Fragment>
        );
    };

    handleClick = () => {
        const { dispatch, form, fieldList, idFile, idForm, idRelatedFile, idField } = this.props;
        const { idField: selectorId, optionList } = fieldList.find(
            (field) => field.type === "selector" && field.defaultValue === "file",
        );

        if (idRelatedFile !== undefined) {
            fileMiddleware.deleteFile(Number(idRelatedFile));
        }
        dispatch(transactionLinesActions.setIsEditingPayment(false));
        dispatch(fileActions.getFileContentsRequest(idFile, true));
        dispatch(routerActions.push(`/form/${idForm}/manual`));
        dispatch(
            formActions.setData({
                ...form.values,
                [selectorId]: [optionList[1].id],
                [idField]: [],
            }),
        );
    };

    handleBlur = (event) => {
        const { field, toggleIsFocused } = this.props;

        field.onBlur(event);
        toggleIsFocused();
    };

    renderDetail = ({ downloadFile }) => {
        const { isDesktop, processedFileData, FORM_ID } = this.props;
        const { invalidLines = 0, totalAmount } = processedFileData;

        if (invalidLines == null) {
            return null;
        }
        if (invalidLines !== 0) {
            document.getElementsByClassName("filepond--file-status-main")[0].innerHTML = i18nUtils.get(
                "file.upload.input.labelFileTypeNotAllowed",
            );
        }

        return (
            <>
                {invalidLines === 0 ? (
                    <div className="text-align-right mt-4 mb-3">
                        <div>
                            <span
                                className={classNames(
                                    "acoount-header-detail-value-font no-padding small-font bold-font",
                                    {
                                        "small-margin-bottom": !isDesktop,
                                    },
                                )}>
                                {i18nUtils.get("transactions.selected.total.amount").toUpperCase()}
                            </span>
                            <FormattedAmount
                                className={classNames(
                                    "acoount-header-detail-value-font small-font bold-font-important",
                                    {
                                        "small-margin-bottom pl-0": !isDesktop,
                                    },
                                )}
                                {...totalAmount}
                            />
                        </div>

                        <div className="data-label m-0 pr-1 f-size-55">
                            {i18nUtils
                                .get(`${FORM_ID}.total.description`)
                                .concat("  ", this.renderLinesInfo(downloadFile))}
                        </div>
                    </div>
                ) : (
                    <DetailBox>
                        <div
                            className={classNames({
                                "text-right": isDesktop,
                                "text-center": !isDesktop,
                            })}>
                            <button
                                type="button"
                                className="btn btn-asLink btn-dl-error"
                                onClick={() => downloadFile({ isDownloadingRelatedFile: true })}>
                                <I18n id="forms.inputFile.echeq.massive.emit.download.errors" />
                            </button>
                        </div>
                    </DetailBox>
                )}
            </>
        );
    };

    renderError = () => {
        const { form, idField, processedFileData } = this.props;
        if (!form.touched[idField]) {
            return null;
        }
        if (!processedFileData.hasFile) {
            return <FieldError error={i18nUtils.get("forms.inputFile.massivePayments.invalid.required")} />;
        }
        if (processedFileData.invalidFile) {
            return <FieldError error={i18nUtils.get("forms.inputFile.massivePayments.invalid.field")} />;
        }
        return null;
    };

    isValid = () => {
        const { processedFileData } = this.props;
        const { invalidHeader, invalidFile, hasFile } = processedFileData;
        return !invalidHeader && !invalidFile && hasFile;
    };

    render() {
        const {
            acceptedFileTypes,
            dispatch,
            formTitle,
            idForm,
            idField,
            isFocused,
            label,
            maxFileSizeMB,
            processedFileData,
            setValue,
            toggleIsFocused,
            value,
            FORM_ID,
            ...rest
        } = this.props;
        const { invalidLines } = processedFileData;

        return (
            <FileActions
                {...this.props}
                renderPreview={this.renderDetail}
                render={({ onRemoveFile, onAddFile, downloadFile }) => (
                    <div
                        onFocus={toggleIsFocused}
                        onBlur={this.handleBlur}
                        className={classNames("form-group multiple-transfers", {
                            "has-error": !this.isValid(),
                            "has-focus": isFocused,
                            "invalid-lines": invalidLines > 0,
                        })}>
                        <FileCsvUploader
                            name={idField}
                            idActivity="transfer.multiple.files.upload"
                            idForm={idForm}
                            idFormField={idField}
                            description={`${formTitle} - ${label}`}
                            files={value}
                            allowMultiple={false}
                            maxFileSize={`${maxFileSizeMB}mb`}
                            minFileSize={1}
                            maxTotalFileSize={`${maxFileSizeMB}mb`}
                            maxFiles={1}
                            allowImagePreview
                            acceptedFileTypes={acceptedFileTypes}
                            onFileProcess={this.handleFileProcess}
                            onError={this.handleError}
                            onAddFile={onAddFile}
                            onRemoveFile={(file) => {
                                onRemoveFile(file);
                                dispatch(actions.onFileRemoved());
                            }}
                            setValue={setValue}
                            FORM_ID={FORM_ID}
                            {...rest}
                        />
                        {this.renderDetail({ downloadFile })}
                    </div>
                )}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    idFile: multilineFileSelectors.getProcessedFileData(state).idFile,
});

export default compose(connect(mapStateToProps), withFocus)(FileMultipleTransfer);
