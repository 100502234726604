import React, { Component } from "react";
import { string, obj, bool } from "prop-types";

class InfoTag extends Component {
    static propTypes = {
        message: string.isRequired,
        tagBackground: string,
        moreStyles: obj,
        color: string,
        tagClass: string,
        capitalize: bool,
    };

    static defaultProps = {
        tagBackground: "#cdcdcd",
        moreStyles: {},
        color: "#ffff",
        tagClass: "",
        capitalize: false,
    };

    render() {
        const { message, tagBackground, moreStyles, color, tagClass, capitalize } = this.props;
        return (
            <div
                className={`info-tag ${tagClass}`}
                style={{
                    background: tagBackground,
                    color,
                    ...moreStyles,
                }}>
                {capitalize ? message : message.toUpperCase()}
            </div>
        );
    }
}

export default InfoTag;
