import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createReducer, makeActionCreator } from "util/redux";
import { globalTypes } from "./types/global";

export const types = {
    LOAD_LIST_REQUEST: "transactions/LOAD_LIST_REQUEST",
    LOAD_LIST_FAILURE: "transactions/LOAD_LIST_FAILURE",
    LOAD_LIST_SUCCESS: "transactions/LOAD_LIST_SUCCESS",
    DOWNLOAD_PENDING_TRANSACTIONS_REQUEST: "transactions/DOWNLOAD_PENDING_TRANSACTIONS_REQUEST",
    DOWNLOAD_PENDING_TRANSACTIONS_FAILURE: "transactions/DOWNLOAD_PENDING_TRANSACTIONS_FAILURE",
    DOWNLOAD_PENDING_TRANSACTIONS_SUCCESS: "transactions/DOWNLOAD_PENDING_TRANSACTIONS_SUCCESS",
    LOAD_TRANSACTION_ACTIVITIES_REQUEST: "transactions/LOAD_TRANSACTION_ACTIVITIES_REQUEST",
    LOAD_TRANSACTION_ACTIVITIES_FAILURE: "transactions/LOAD_TRANSACTION_ACTIVITIES_FAILURE",
    LOAD_TRANSACTION_ACTIVITIES_SUCCESS: "transactions/LOAD_TRANSACTION_ACTIVITIES_SUCCESS",
    MULTIPLE_SIGNATURES_DATA: "transactions/MULTIPLE_SIGNATURES_DATA",
    MULTIPLE_SIGNATURES_DATA_REQUEST: "transactions/MULTIPLE_SIGNATURES_DATA_REQUEST",
    MULTIPLE_SIGNATURES_DATA_SUCCESS: "transactions/MULTIPLE_SIGNATURES_DATA_SUCCESS",
    MULTIPLE_SIGNATURES_DATA_FAILURE: "transactions/MULTIPLE_SIGNATURES_DATA_FAILURE",
    CHANGE_STATUS_TYPE: "transactions/CHANGE_STATUS_TYPE",
    CHANGE_COLUMN_FILTER: "transactions/CHANGE_COLUMN_FILTER",
    SET_FILTERS: "transactions/SET_FILTERS",
    RESET_FILTERS: "transactions/RESET_FILTERS",
    LOAD_MORE_TRANSACTIONS_REQUEST: "transactions/LOAD_MORE_TRANSACTIONS_REQUEST",
    LOAD_MORE_TRANSACTIONS_REQUEST_MOBILE: "transactions/LOAD_MORE_TRANSACTIONS_REQUEST_MOBILE",
    LOAD_MORE_TRANSACTIONS_SUCCESS: "transactions/LOAD_MORE_TRANSACTIONS_SUCCESS",
    LOAD_MORE_TRANSACTIONS_SUCCESS_MOBILE: "transactions/LOAD_MORE_TRANSACTIONS_SUCCESS_MOBILE",
    DELETE_DRAFT_REQUEST: "transactions/DELETE_DRAFT_REQUEST",
    DELETE_DRAFT_FAILURE: "transactions/DELETE_DRAFT_FAILURE",
    DELETE_DRAFT_SUCCESS: "transactions/DELETE_DRAFT_SUCCESS",
    SIGN_MULTIPLE_TRANSACTIONS: "transactions/SIGN_MULTIPLE_TRANSACTIONS",
    DELETE_MULTIPLE_TRANSACTIONS: "transactions/DELETE_MULTIPLE_TRANSACTIONS",
    REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS: "transactions/REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS",
    POTENTIAL_SIGNATURES_REQUEST: "transactions/POTENTIAL_SIGNATURES_REQUEST",
    POTENTIAL_SIGNATURES_SUCCESS: "transactions/POTENTIAL_SIGNATURES_SUCCESS",
    IS_FIRST_TRANSACTION_CBU_REQUEST: "transactions/IS_FIRST_TRANSACTION_CBU_REQUEST",
    SAVE_TRANSACTION_ROUTE: "transactions/SAVE_TRANSACTION_ROUTE",
    SAVE_SELECTED_TRANSACTION_ROWS: "transactions/SAVE_SELECTED_TRANSACTION_ROWS",
    REMOVE_SELECTED_TRANSACTION_ROWS: "transactions/REMOVE_SELECTED_TRANSACTION_ROWS",
    CLEAR_SELECTED_TRANSACTION_ROWS: "transactions/CLEAR_SELECTED_TRANSACTION_ROWS",
    INIT_PENDINGS: "transactions/INIT_PENDINGS",
    CLEAR_MULTIPLE_SIGNATURES_DATA: "transactions/CLEAR_MULTIPLE_SIGNATURES_DATA",
};

export const INITIAL_STATE = {
    transactions: [],
    isFetching: false,
    isFetchingMore: false,
    filters: null,
    historyFilters: null,
    hasMoreData: false,
    pageNumber: 1,
    isDeletingDraft: false,
    pendingTransactionsQuantity: 0,
    isFirstFetching: true,
    showFilters: false,
    activities: null,
    statusType: null,
    columnFilter: { filterSelected: "", directionFilter: "" },
    multipleSignaturesData: null,
    potentialSignaturesData: [],
    transactionRoute: "/desktop",
    selectedTransactionRows: [],
    isDownload: false,
    controlActivity: false,
    controlARS: null,
    controlUSD: null,
};

const reducer = createReducer(INITIAL_STATE, {
    [types.LOAD_LIST_REQUEST]: (state, action) => ({
        ...state,
        isFetching: true,
        filters: action.filters,
        historyFilters: action?.filters,
        isFirstFetching: action.isFirstFetching === true,
    }),
    [types.LOAD_LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        isFirstFetching: false,
    }),
    [types.LOAD_LIST_SUCCESS]: (state, action) => ({
        ...state,
        transactions: action.transactions,
        hasMoreData: action.pageNumber < action.totalPages,
        isFetching: false,
        isFirstFetching: false,
        pageNumber: action.pageNumber,
        totalPages: action.totalPages,
        totalRows: action.totalRows,
        showFilters: state.isFirstFetching ? action.totalPages > 1 : state.showFilters,
        selectedTransactionRows: [],
        controlActivity: action.controlActivity,
        controlARS: action.controlARS,
        controlUSD: action.controlUSD,
    }),
    [types.LOAD_TRANSACTION_ACTIVITIES_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.LOAD_TRANSACTION_ACTIVITIES_FAILURE]: (state) => ({
        ...state,
    }),
    [types.LOAD_TRANSACTION_ACTIVITIES_SUCCESS]: (state, action) => ({
        ...state,
        activities: action.activities,
    }),
    [types.DOWNLOAD_PENDING_TRANSACTIONS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        isDownload: true,
    }),
    [types.DOWNLOAD_PENDING_TRANSACTIONS_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
        isDownload: false,
    }),
    [types.DOWNLOAD_PENDING_TRANSACTIONS_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
        isDownload: false,
    }),
    [types.SET_FILTERS]: (state, action) => ({
        ...state,
        filters: action.filters,
        historyFilters: action?.filters,
    }),
    [types.RESET_FILTERS]: (state) => ({
        ...state,
        filters: INITIAL_STATE.filters,
        historyFilters: INITIAL_STATE.filters,
    }),
    [types.LOAD_MORE_TRANSACTIONS_REQUEST]: (state) => ({
        ...state,
        isFetchingMore: true,
    }),
    [types.LOAD_MORE_TRANSACTIONS_REQUEST_MOBILE]: (state) => ({
        ...state,
    }),
    [types.CHANGE_STATUS_TYPE]: (state, action) => ({
        ...state,
        statusType: action.status,
    }),
    [types.CHANGE_COLUMN_FILTER]: (state, action) => ({
        ...state,
        columnFilter: action.columnFilter,
    }),
    [types.LOAD_MORE_TRANSACTIONS_SUCCESS]: (state, action) => ({
        ...state,
        isFetchingMore: false,
        transactions: action.transactions,
        hasMoreData: action.pageNumber < action.totalPages,
        pageNumber: action.pageNumber,
        totalPages: action.totalPages,
        totalRows: action.totalRows,
    }),
    [types.LOAD_MORE_TRANSACTIONS_SUCCESS_MOBILE]: (state, action) => ({
        ...state,
        isFetching: false,
        transactions: [...state.transactions, ...action.transactions],
        hasMoreData: action.pageNumber < action.totalPages,
        pageNumber: action.pageNumber,
        totalPages: action.totalPages,
        totalRows: action.totalRows,
    }),
    [types.DELETE_DRAFT_REQUEST]: (state) => ({
        ...state,
        isDeletingDraft: true,
    }),
    [types.DELETE_DRAFT_FAILURE]: (state) => ({
        ...state,
        isDeletingDraft: false,
    }),
    [types.DELETE_DRAFT_SUCCESS]: (state, action) => ({
        ...state,
        isDeletingDraft: false,
        transactions: state.transactions.filter((item) => item.transaction.idTransaction !== action.idTransaction),
    }),
    [types.REFRESH_PENDING_TRANSACTIONS_QUANTITY_SUCCESS]: (state, action) => ({
        ...state,
        pendingTransactionsQuantity: action.pendingTransactionsQuantity,
    }),
    [types.MULTIPLE_SIGNATURES_DATA]: (state, action) => ({
        ...state,
        multipleSignaturesData: action.multipleSignaturesData,
    }),
    [types.MULTIPLE_SIGNATURES_DATA_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.MULTIPLE_SIGNATURES_DATA_SUCCESS]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.MULTIPLE_SIGNATURES_DATA_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.POTENTIAL_SIGNATURES_REQUEST]: (state) => ({
        ...state,
    }),
    [types.POTENTIAL_SIGNATURES_SUCCESS]: (state, action) => ({
        ...state,
        potentialSignaturesData: action.potentialSignaturesData,
        serverDate: action.serverDate,
    }),
    [types.IS_FIRST_TRANSACTION_CBU_REQUEST]: (state) => ({
        ...state,
    }),
    [types.SAVE_TRANSACTION_ROUTE]: (state, action) => ({
        ...state,
        transactionRoute: action.transactionRoute || INITIAL_STATE.transactionRoute,
    }),
    [types.SAVE_SELECTED_TRANSACTION_ROWS]: (state, action) => ({
        ...state,
        selectedTransactionRows: [...state.selectedTransactionRows, action.transaction],
    }),
    [types.REMOVE_SELECTED_TRANSACTION_ROWS]: (state, action) => ({
        ...state,
        selectedTransactionRows: state.selectedTransactionRows.filter(
            (selectedTransaction) =>
                selectedTransaction.transaction.idTransaction !== action.transaction.transaction.idTransaction,
        ),
    }),
    [types.CLEAR_SELECTED_TRANSACTION_ROWS]: (state) => ({
        ...state,
        selectedTransactionRows: [],
    }),
    [globalTypes.RESET_SELECTED_ELEMENTS]: (state) => ({ ...INITIAL_STATE, transactionRoute: state.transactionRoute }),
    [types.CLEAR_MULTIPLE_SIGNATURES_DATA]: (state) => ({
        ...state,
        multipleSignaturesData: INITIAL_STATE.multipleSignaturesData,
    }),
});

export default persistReducer(
    {
        storage,
        key: "transactions",
        whitelist: ["activities"],
    },
    reducer,
);

export const actions = {
    loadListRequest: makeActionCreator(types.LOAD_LIST_REQUEST, "filters", "onlyPendings", "pendingDispatch"),
    loadListFailure: makeActionCreator(types.LOAD_LIST_FAILURE),
    loadListSuccess: makeActionCreator(
        types.LOAD_LIST_SUCCESS,
        "transactions",
        "pageNumber",
        "totalPages",
        "totalRows",
        "controlActivity",
        "controlARS",
        "controlUSD",
    ),
    downloadPendingTransactionsRequest: makeActionCreator(
        types.DOWNLOAD_PENDING_TRANSACTIONS_REQUEST,
        "filters",
        "onlyPendings",
        "pendingDispatch",
        "format",
    ),
    downloadPendingTransactionsFailure: makeActionCreator(types.DOWNLOAD_PENDING_TRANSACTIONS_FAILURE),
    downloadPendingTransactionsSuccess: makeActionCreator(
        types.DOWNLOAD_PENDING_TRANSACTIONS_SUCCESS,
        "transactions",
        "pageNumber",
        "totalPages",
        "totalRows",
    ),
    loadTransactionActivitiesRequest: makeActionCreator(types.LOAD_TRANSACTION_ACTIVITIES_REQUEST),
    loadTransactionActivitiesFailure: makeActionCreator(types.LOAD_TRANSACTION_ACTIVITIES_FAILURE),
    loadTransactionActivitiesSuccess: makeActionCreator(types.LOAD_TRANSACTION_ACTIVITIES_SUCCESS, "activities"),
    setFilters: makeActionCreator(types.SET_FILTERS, "filters"),
    resetFilters: makeActionCreator(types.RESET_FILTERS),
    loadMoreTransactionsRequest: makeActionCreator(
        types.LOAD_MORE_TRANSACTIONS_REQUEST,
        "filters",
        "onlyPendings",
        "pendingDispatch",
        "isDesktop",
    ),
    loadMoreTransactionsRequestMobile: makeActionCreator(
        types.LOAD_MORE_TRANSACTIONS_REQUEST_MOBILE,
        "filters",
        "onlyPendings",
        "pendingDispatch",
        "isDesktop",
    ),
    loadMoreTransactionsSuccess: makeActionCreator(
        types.LOAD_MORE_TRANSACTIONS_SUCCESS,
        "transactions",
        "pageNumber",
        "totalPages",
        "totalRows",
    ),
    loadMoreTransactionsSuccessMobile: makeActionCreator(
        types.LOAD_MORE_TRANSACTIONS_SUCCESS_MOBILE,
        "transactions",
        "pageNumber",
        "totalPages",
        "totalRows",
    ),
    changeStatusType: makeActionCreator(types.CHANGE_STATUS_TYPE, "status"),
    changeColumnFilter: makeActionCreator(types.CHANGE_COLUMN_FILTER, "columnFilter"),
    deleteDraftRequest: makeActionCreator(types.DELETE_DRAFT_REQUEST, "idTransaction"),
    deleteDraftFailure: makeActionCreator(types.DELETE_DRAFT_FAILURE),
    deleteDraftSuccess: makeActionCreator(types.DELETE_DRAFT_SUCCESS, "idTransaction"),
    setMultipleSignaturesData: makeActionCreator(types.MULTIPLE_SIGNATURES_DATA, "multipleSignaturesData"),
    multipleSignaturesDataRequest: makeActionCreator(types.MULTIPLE_SIGNATURES_DATA_REQUEST, "multipleSignaturesData"),
    signMultipleTransactions: makeActionCreator(types.SIGN_MULTIPLE_TRANSACTIONS, "payload"),
    deleteMultipleTransactions: makeActionCreator(types.DELETE_MULTIPLE_TRANSACTIONS, "payload"),
    getPotentialSignaturesData: makeActionCreator(types.POTENTIAL_SIGNATURES_REQUEST, "idTransaction"),
    isFirstTransactionCBURequest: makeActionCreator(types.IS_FIRST_TRANSACTION_CBU_REQUEST, "cbu"),
    saveTransactionRoute: makeActionCreator(types.SAVE_TRANSACTION_ROUTE, "transactionRoute"),
    saveSelectedTransactionRows: makeActionCreator(types.SAVE_SELECTED_TRANSACTION_ROWS, "transaction"),
    removeSelectedTransactionRows: makeActionCreator(types.REMOVE_SELECTED_TRANSACTION_ROWS, "transaction"),
    clearSelectedTransactionRows: makeActionCreator(types.CLEAR_SELECTED_TRANSACTION_ROWS),
    initPendings: makeActionCreator(types.INIT_PENDINGS),
    clearMultipleSignaturesData: makeActionCreator(types.CLEAR_MULTIPLE_SIGNATURES_DATA),
};

export const selectors = {
    getActivities: ({ transactions }) => transactions.activities,
    getFilteredActivities: ({ transactions }, excludeIdActivities) =>
        transactions?.activities?.filter(({ idActivity }) => !excludeIdActivities.includes(idActivity)),
    getTransactions: ({ transactions }) => transactions.transactions,
    getFirstFetch: ({ transactions }) => transactions.isFirstFetching,
    getShowFilters: ({ transactions }) => transactions.showFilters,
    getFetching: ({ transactions }) => transactions.isFetching,
    isFetchingMore: ({ transactions }) => transactions.isFetchingMore,
    getHasMoreData: ({ transactions }) => transactions.hasMoreData,
    getPageNumber: ({ transactions }) => transactions.pageNumber,
    getTotalPages: ({ transactions }) => transactions.totalPages,
    getTotalRows: ({ transactions }) => transactions.totalRows,
    getFilters: ({ transactions }) => transactions.filters,
    getHistoryFilters: ({ transactions }) => transactions.historyFilters,
    isDeletingDraft: ({ transactions }) => transactions.isDeletingDraft,
    getPendingTransactionsQuantity: ({ transactions }) => transactions.pendingTransactionsQuantity,
    getStatusType: ({ transactions }) => transactions.statusType,
    getColumnFilter: ({ transactions }) => transactions.columnFilter,
    getMultipleSignaturesData: ({ transactions }) => transactions.multipleSignaturesData,
    getPotentialSignaturesData: ({ transactions }) => transactions.potentialSignaturesData,
    getTransactionRoute: ({ transactions }) => transactions.transactionRoute,
    getSelectedTransactionRows: ({ transactions }) => transactions.selectedTransactionRows,
    getServerDate: ({ transactions }) => transactions.serverDate,
    getIsDownload: ({ transactions }) => transactions.isDownload,
    getControlActivity: ({ transactions }) => transactions.controlActivity,
    getControlARS: ({ transactions }) => transactions.controlARS,
    getControlUSD: ({ transactions }) => transactions.controlUSD,
};
