import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, arrayOf, string, shape, bool } from "prop-types";
import Notification from "pages/_components/Notification";
import { selectors, actions } from "reducers/checks";
import { echeqShape, echeqConceptLabel } from "util/checks";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Heading from "pages/_components/Heading";
import Button from "pages/_components/Button";
import { selectors as sessionSelectors } from "reducers/session";
import { Field, Form, withFormik } from "formik";
import I18n from "pages/_components/I18n";
import Yup from "yup";
import { compose } from "redux";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import Credential from "pages/_components/fields/credentials/Credential";
import classNames from "classnames";
import isTokenActive from "util/token";

const FORM_ID = "echeqs";

class ConfirmAcceptReturnEcheq extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        echeqAction: shape({
            action: string.isRequired,
            echeqList: arrayOf(echeqShape),
            specialAttribute: string.isRequired,
        }).isRequired,
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    closeEcheqAction = () => {
        const { dispatch } = this.props;

        dispatch(actions.cleanEcheqAction());
        this.handleBack();
    };

    componentWillMount = () => {
        const { echeqAction } = this.props;

        if (!echeqAction.action) {
            this.handleBack();
        }
    };

    renderHeader = () => {
        const { echeqAction, isDesktop } = this.props;
        const title = `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.title`;

        if (isDesktop) {
            return (
                <div className="admin-detail-head px-0">
                    <Head onBack={this.handleBack} onClose={this.closeEcheqAction} />
                    <Head title={title} centerContentClassName="p-0" />
                </div>
            );
        }

        return (
            <Head
                headerClassName="blue-main-header-mobile"
                titleClassName="w-100"
                onBack={this.handleBack}
                title={title}
                emptyRightOption
            />
        );
    };

    render() {
        const { echeqAction, isSubmitting, isDesktop } = this.props;

        return (
            <>
                <Notification scopeToShow="echeqs" />
                {this.renderHeader()}
                <div
                    className={classNames({
                        "admin-content-center": !isDesktop,
                        "admin-detail-head px-0": isDesktop,
                        "mb-2 px-6": !isDesktop,
                    })}>
                    <I18n id="echeqs.action.subtitle" component="h4" />
                </div>
                <MainContainer>
                    <div className="above-the-fold container-white">
                        {echeqAction?.echeqList?.length > 1 && (
                            <Col xs={12} className="justify-content-right flex">
                                <SelectedAccountsAmount list={echeqAction?.echeqList} summaryView />
                            </Col>
                        )}
                        {echeqAction?.echeqList?.map((echeq) => (
                            <Container
                                className="flex-grow align-items-center container-white with-margin with-padding"
                                gridClassName="form-content">
                                <>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_numero`}
                                            data={echeq.cheque_numero}
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.beneficiario_nombre`}
                                            data={echeq.beneficiario_nombre.replaceAll("#", "Ñ")}
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.beneficiario_documento`}
                                            data={echeq.beneficiario_documento_tipo
                                                .toUpperCase()
                                                .concat(" ", echeq.beneficiario_documento)}
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.monto`}
                                            data={
                                                <FormattedAmount
                                                    currency={i18n.get("currency.label.".concat(echeq.emisor_moneda))}
                                                    quantity={echeq.monto}
                                                    notBold
                                                />
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_id`}
                                            data={echeq.cheque_id}
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_tipo`}
                                            data={echeq.cheque_tipo}
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_modo`}
                                            data={i18n.get(`echeqs.${echeq.cheque_modo}.modo`)}
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_caracter`}
                                            data={echeq.cheque_caracter}
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.fecha_pago`}
                                            data={<FormattedDate showTime={false} date={echeq.fecha_pago} />}
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_concepto`}
                                            data={echeqConceptLabel(echeq.cheque_concepto, "")}
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label={`${FORM_ID}.cheque_motivo_pago`}
                                            data={echeq.cheque_motivo_pago}
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <></>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label="echeq.request.return.cuit"
                                            data={echeq.cuit_solic_devol}
                                        />
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Heading.DataGroup
                                            containerClassName={`transfer-data data-wrapper-flex ${
                                                isDesktop ? "" : "space-between"
                                            }`}
                                            labelClassName="data-label-medium"
                                            label="echeqs.reference.beneficiary"
                                            data={echeqAction.solicitantName?.replaceAll("#", "Ñ")}
                                        />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <></>
                                    </Col>
                                </>
                            </Container>
                        ))}
                        <Container>
                            <Form className="confirmation__container">
                                <Container
                                    className="flex-grow align-items-center container-white"
                                    gridClassName="confirmation__form confirmation__box">
                                    <Col className="col-12" lg={12} md={12} sm={12}>
                                        {!isTokenActive(!isDesktop) ? (
                                            <div className="admin-content-center">
                                                <I18n id="transfer.summary.confirm.label" />
                                            </div>
                                        ) : (
                                            <div className="admin-content-center pb-3">
                                                <I18n
                                                    id="confirmation.withoutotp.text"
                                                    component="p"
                                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                                />
                                            </div>
                                        )}
                                        <div className="admin-content-center">
                                            <Field
                                                name="otp"
                                                type="otp"
                                                idForm={FORM_ID}
                                                component={Credential}
                                                loading={isSubmitting}
                                                placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                            />
                                        </div>
                                    </Col>
                                </Container>
                                <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                                    <Col lg={6} md={6} sm={12} className="px-0">
                                        <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                                            <Button
                                                type="submit"
                                                bsStyle="primary"
                                                image="images/arrowRight.svg"
                                                imageStyle="mr-2"
                                                label="global.confirm"
                                                className="text-capitalize"
                                                loading={isSubmitting}
                                            />
                                        </Col>
                                    </Col>
                                </Container>
                            </Form>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    option: selectors.getEcheqOption(state),
    echeqAction: selectors.getEcheqsToDoActions(state),
    userId: sessionSelectors.getUser(state).userId,
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: (form, formikBag) => {
            const { setSubmitting, setErrors } = formikBag;
            const { dispatch, echeqAction, userId } = formikBag.props;

            setSubmitting(true);

            dispatch(actions.acceptReturnEcheq(form.otp, setSubmitting, setErrors, echeqAction, userId));
        },
    }),
)(ConfirmAcceptReturnEcheq);
