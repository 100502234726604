import React, { Component } from "react";
import { string, func, shape, bool } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "redux";

import Heading from "pages/_components/Heading";
import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedRate from "pages/_components/FormattedRate";

import { selectors as depositsSelectors } from "reducers/deposits";
import { selectors as sessionSelectors } from "reducers/session";

import * as i18nUtils from "util/i18n";
import { getAccountDescription } from "util/accounts";

class DepositSummaryData extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        summary: shape({
            debitAccount: string.isRequired,
            depositType: string.isRequired,
            consolidatedAmount: shape({}).isRequired,
            termInDays: string.isRequired,
            dueDate: string.isRequired,
            capital: shape({}).isRequired,
            totalAmount: shape({}).isRequired,
            earnedInterests: shape({}).isRequired,
            rate: string.isRequired,
            automaticRenewal: bool.isRequired,
            actionToDue: string.isRequired,
        }).isRequired,
        fullName: string.isRequired,
        isDesktop: bool.isRequired,
        signature: bool,
    };

    static defaultProps = {
        signature: false,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/deposits"));
    };

    preparedBy = () => {
        const { summary, fullName } = this.props;
        return (
            <p>
                {summary?.creatorFullName || fullName} -{" "}
                <FormattedDate date={summary?.creationDate || new Date()} dateFormat="dd/MM/yyyy" showTime />
            </p>
        );
    };

    signBy = () => {
        const { summary } = this.props;
        return (
            <p>
                {summary?.signatures.map((object) => (
                    <>
                        {object?.userFirstName.concat(" ", object?.userLastName)} -{" "}
                        <FormattedDate date={object?.creationDateTime} dateFormat="dd/MM/yyyy" showTime />
                        <br />
                    </>
                ))}
            </p>
        );
    };

    render() {
        const {
            summary: {
                debitAccount,
                depositType,
                termInDays,
                dueDate,
                capital,
                totalAmount,
                earnedInterests,
                rate,
                automaticRenewal,
                actionToDue,
            },
            summary,
            isDesktop,
            signature,
        } = this.props;

        const depositTypeLabel = depositType.split("-")[2];

        return (
            <>
                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex mb-2 ${isDesktop ? "" : "space-between"}`}
                    dataClassName="data-desc small-font"
                    labelClassName="data-label-medium"
                    label="deposits.constitute.type.label"
                    data={depositTypeLabel}
                />

                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex mb-2 ${isDesktop ? "" : "space-between"}`}
                    dataClassName="data-desc small-font"
                    labelClassName="data-label-medium"
                    label="deposits.constitute.debitAccount.label"
                    data={debitAccount?.number !== undefined && getAccountDescription(debitAccount)}
                />

                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex mb-2 ${isDesktop ? "" : "space-between"}`}
                    label="deposits.constitute.consolidatedAmount.label"
                    labelClassName="data-label-medium"
                    data={
                        <FormattedAmount
                            currency={capital.currency.toString()}
                            quantity={capital.quantity}
                            notBold
                            className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                        />
                    }
                />

                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex mb-2 ${isDesktop ? "" : "space-between"}`}
                    label="deposits.constitute.earnedInterests.label"
                    labelClassName="data-label-medium"
                    data={
                        <FormattedAmount
                            currency={earnedInterests.currency}
                            quantity={earnedInterests.quantity}
                            notBold
                            className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                        />
                    }
                />

                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex mb-2 ${isDesktop ? "" : "space-between"}`}
                    label="deposits.constitute.totalAmount.label"
                    labelClassName="data-label-medium"
                    data={
                        <FormattedAmount
                            currency={totalAmount.currency}
                            quantity={totalAmount.quantity}
                            notBold
                            className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                        />
                    }
                />

                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex mb-2 ${isDesktop ? "" : "space-between"}`}
                    dataClassName="data-desc small-font"
                    labelClassName="data-label-medium"
                    label="deposits.list.dueDate"
                    data={<FormattedDate date={dueDate} />}
                />

                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex mb-2 ${isDesktop ? "" : "space-between"}`}
                    dataClassName="data-desc small-font"
                    labelClassName="data-label-medium"
                    label="deposits.constitute.termInDays.label"
                    data={termInDays}
                />

                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex mb-2 ${isDesktop ? "" : "space-between"}`}
                    label="deposits.constitute.rate.label"
                    labelClassName="data-label-medium"
                    data={
                        <FormattedRate
                            rate={rate}
                            className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                            symbolToTheLeft={false}
                        />
                    }
                />

                <Heading.DataGroup
                    containerClassName={`transfer-data data-wrapper-flex mb-2 ${isDesktop ? "" : "space-between"}`}
                    dataClassName="data-desc small-font"
                    labelClassName="data-label-medium"
                    label="deposits.constitute.automaticRenewal.label"
                    data={i18nUtils.get(`deposits.constitute.automaticRenewal.options.${automaticRenewal}`)}
                />

                {automaticRenewal && (
                    <Heading.DataGroup
                        containerClassName={`transfer-data data-wrapper-flex mb-2 ${isDesktop ? "" : "space-between"}`}
                        dataClassName="data-desc small-font"
                        labelClassName="data-label-medium"
                        label="deposits.constitute.actionToDue.label"
                        data={i18nUtils.get(`deposits.constitute.actionToDue.options.${actionToDue}`)}
                    />
                )}

                {signature && (
                    <>
                        <I18n
                            component="h3"
                            id="accounts.new.account.signatures.label"
                            componentProps={{ className: "font-size-25-px" }}
                        />
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex"
                            labelClassName="data-label-medium"
                            label="accounts.new.account.signatures.preparedBy.label"
                            data={this.preparedBy()}
                        />
                        {summary?.signatures?.length > 0 && (
                            <Heading.DataGroup
                                containerClassName="transfer-data data-wrapper-flex"
                                labelClassName="data-label-medium"
                                label="accounts.new.account.signatures.signBy.label"
                                data={this.signBy()}
                            />
                        )}
                    </>
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    summary: depositsSelectors.getSummary(state),
    fullName: sessionSelectors.getUser(state).userFullName,
});

export default compose(connect(mapStateToProps))(DepositSummaryData);
