import React, { Component } from "react";
import { shape } from "prop-types";
import { connect } from "react-redux";
import { echeqConceptLabel } from "util/checks";
import { selectors } from "reducers/checks";
import FormattedDate from "pages/_components/FormattedDate";

import moment from "moment";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";
import { Col } from "react-bootstrap";
import Heading from "pages/_components/Heading";
import * as configUtils from "util/config";
import { isFinalState } from "util/transaction";

const FORM_ID = "echeq.transaction";

class CesionEcheqTransactionData extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
    };

    dateTimeCreationIsDifferentFromModify = (creation, modification) => {
        const creation_ = moment(creation);
        const modification_ = moment(modification);
        return modification_.diff(creation_, "seconds") > 1;
    };

    render() {
        const { transactionData } = this.props;
        const { echeq } = transactionData;

        const concept = `${echeq.cheque_concepto} ${echeqConceptLabel(echeq.cheque_concepto, "-")}`;

        const chequesFunctionalities = configUtils.getBoolean(
            "frontend.show.failedVoucher.chequesFunctionalities",
            false,
        );

        return (
            transactionData?.idTransaction && (
                <>
                    {isFinalState(transactionData.idTransactionStatus) && (
                        <>
                            <Col xs={12} className="px-0">
                                <Heading.DataGroup
                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                    labelClassName="data-label-medium"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.date.time`}
                                    data={
                                        <p className="data-desc m-0">
                                            <FormattedDate
                                                date={moment(transactionData?.submitDateTime)}
                                                dateFormat="dd/MM/yyyy"
                                                showTime
                                            />
                                        </p>
                                    }
                                />
                            </Col>
                        </>
                    )}
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.transaction`}
                            data={
                                <p className="data-desc m-0">
                                    {transactionData?.idTransaction.substring(0, 8).toUpperCase()}
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.echeq.number`}
                            data={<p className="data-desc m-0">{echeq?.cheque_numero}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.echeq.id`}
                            data={<p className="data-desc m-0">{echeq?.cheque_id}</p>}
                        />
                    </Col>
                    {transactionData?.cesion && (
                        <>
                            <Col xs={12} className="px-0">
                                <Heading.DataGroup
                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                    labelClassName="data-label-medium"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.cesion_date`}
                                    data={
                                        <p className="data-desc m-0">{transactionData?.cesion?.fecha_emision_cesion}</p>
                                    }
                                />
                            </Col>
                            <Col xs={12} className="px-0">
                                <Heading.DataGroup
                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                    labelClassName="data-label-medium"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.cesion.id`}
                                    data={<p className="data-desc m-0">{transactionData?.cesion?.cesion_id}</p>}
                                />
                            </Col>
                        </>
                    )}
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.beneficiary`}
                            data={
                                <p className="data-desc m-0">
                                    {transactionData?.beneficiary?.reference?.replaceAll("#", "Ñ")}
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.beneficiary.document`}
                            data={<p className="data-desc m-0">{transactionData?.beneficiary?.document}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.cesion.address`}
                            data={<p className="data-desc m-0">{transactionData?.address}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.amount`}
                            data={
                                <p className="data-desc m-0">
                                    <FormattedAmount currency="0" quantity={echeq.monto} notBold />
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.emision`}
                            data={
                                <p className="data-desc m-0">
                                    <FormattedDate date={echeq?.fecha_emision} dateFormat="dd/MM/yyyy" />
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.payed`}
                            data={
                                <p className="data-desc m-0">
                                    <FormattedDate showTime={false} date={echeq?.fecha_pago} dateFormat="dd/MM/yyyy" />
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.echeq.type`}
                            data={
                                <p className="data-desc m-0">
                                    {i18n.get(`echeqs.emit.${echeq?.cheque_tipo.toLowerCase()}.label`)}
                                </p>
                            }
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.echeq.character`}
                            data={<p className="data-desc m-0">{echeq?.cheque_caracter}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.mode`}
                            data={<p className="data-desc m-0">{i18n.get(`echeqs.${echeq.cheque_modo}.modo`)}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.concept`}
                            data={<p className="data-desc m-0">{concept}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.motive`}
                            data={<p className="data-desc m-0">{echeq.cheque_motivo_pago}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.reference`}
                            data={<p className="data-desc m-0">{transactionData.reference}</p>}
                        />
                    </Col>
                    <Col xs={12} className="px-0">
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.value.reference`}
                            data={<p className="data-desc m-0">{transactionData.referencevalue}</p>}
                        />
                    </Col>
                    {!chequesFunctionalities && (
                        <>
                            <Col xs={12} className="px-0">
                                <Heading.DataGroup
                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                    labelClassName="data-label-medium"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.coelsa.response`}
                                    data={<p className="data-desc m-0">{transactionData.coelsaResponse}</p>}
                                />
                            </Col>
                            <Col xs={12} className="px-0">
                                <Heading.DataGroup
                                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                    labelClassName="data-label-medium"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.coelsa.code`}
                                    data={<p className="data-desc m-0">{transactionData.coelsaCode}</p>}
                                />
                            </Col>
                        </>
                    )}
                </>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionEcheqData(state),
});

export default connect(mapStateToProps)(CesionEcheqTransactionData);
