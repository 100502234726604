import React, { Component } from "react";
import { bool, shape, arrayOf, func, number, string } from "prop-types";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import InfiniteScroll from "react-infinite-scroll-component";

import { selectors as ecomexSelectors, actions as ecomexActions } from "reducers/ecomex";
import dynamicSort from "util/ecomex";
import Table from "pages/_components/Table";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import PaginationWithPageSelector from "pages/_components/pagination/PaginationWithPageSelector";
import FilterTableHead from "pages/transactions/FilterTableHead";
import Spinner from "pages/_components/Spinner";
import Heading from "pages/_components/Heading";
import classNames from "classnames";

const FORM_ID = "ecomex.alertsAndDues.list";

class EcomexAlertsAndDuesList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        ecomexAlerts: arrayOf(shape({})).isRequired,
        ecomexDues: arrayOf(shape({})).isRequired,
        pageNumber: number.isRequired,
        numberPerPageOptions: arrayOf(shape({})).isRequired,
        numberPerPage: number.isRequired,
        optionSelected: string.isRequired,
        filter: shape({}).isRequired,
        totalPages: number.isRequired,
    };

    paginateEcomexList = (pageNumber) => {
        const { dispatch, optionSelected, numberPerPage } = this.props;
        dispatch(ecomexActions.listEcomexAlertsAndDues(optionSelected, pageNumber, numberPerPage));
    };

    paginateEcomexListMobile = () => {
        const { dispatch, optionSelected, pageNumber, numberPerPage } = this.props;
        dispatch(ecomexActions.moreListEcomexAlertsAndDues(optionSelected, pageNumber + 1, numberPerPage));
    };

    changeNumberPerPage = (numberPerPage) => {
        const { dispatch, optionSelected, pageNumber } = this.props;
        dispatch(ecomexActions.listEcomexAlertsAndDues(optionSelected, pageNumber, numberPerPage));
    };

    filterChange = (filterName, filterType) => {
        const { dispatch, ecomexAlerts, ecomexDues, optionSelected } = this.props;

        const filter = {
            filterSelected: filterName,
            directionFilter: filterType,
        };

        const ecomexList = optionSelected === "1" ? ecomexAlerts : ecomexDues;

        dispatch(ecomexActions.filterChangeInformativeRegime(ecomexList, filter));
    };

    getHeader = () => {
        const headerItems = [
            { value: "refOperation", align: "left" },
            { value: "originDate", align: "center" },
            { value: "dueDate", align: "center" },
            { value: "beneficiaryName", align: "left" },
            { value: "currency", align: "right" },
            { value: "amount", align: "right" },
            { value: "balance", align: "right" },
        ];

        return headerItems;
    };

    renderListEcomexAlertsAndDues = () => {
        const {
            isDesktop,
            ecomexAlerts,
            ecomexDues,
            pageNumber,
            numberPerPageOptions,
            numberPerPage,
            optionSelected,
            filter,
            totalPages,
        } = this.props;

        const ecomexList = optionSelected === "1" ? ecomexAlerts : ecomexDues;

        if (!(ecomexList && ecomexList.length > 0)) {
            return (
                <div className="text-center no-more-data">
                    <div className="illustration-wrapper">
                        <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                    </div>
                    <p className="text-lead">
                        <I18n id={`${FORM_ID}.empty.message`} />
                    </p>
                </div>
            );
        }

        const numberPerPageOptionsToShow = numberPerPageOptions.map((numberPerPageOption) => ({
            value: numberPerPageOption,
            label: numberPerPageOption,
        }));

        let listSorted = ecomexList;
        if (filter.filterSelected !== "") {
            listSorted = ecomexList.sort(dynamicSort(filter.filterSelected, filter.directionFilter));
        }
        const list = listSorted.map((item) => this.renderListEcomexAlertsAndDuesItem(item));

        if (isDesktop) {
            const headerItems = this.getHeader();

            return (
                <>
                    <Container className="flex-grow scrollable">
                        <Col md={12} className="table-with-border px-0">
                            <Table>
                                <Table.Header className="ecomex__table-header container-white">
                                    {headerItems.map((headerItem) => (
                                        <Table.HeaderData align={headerItem.align}>
                                            <div
                                                className={classNames(
                                                    "ecomex__table-header-item d-flex align-items-center max-width-fit",
                                                    { "m-auto": headerItem.align === "center" },
                                                    { "ml-auto": headerItem.align === "right" },
                                                )}>
                                                <I18n
                                                    id={`${FORM_ID}.column.${headerItem.value}`}
                                                    componentProps={{
                                                        className: classNames("ecomex__table-header-item-text", {
                                                            "no-wrap":
                                                                headerItem.value === "refOperation" ||
                                                                headerItem.value === "beneficiaryName",
                                                        }),
                                                    }}
                                                />
                                                <FilterTableHead
                                                    filterName={headerItem.value}
                                                    columnFilter={filter}
                                                    changeFilter={this.filterChange}
                                                />
                                            </div>
                                        </Table.HeaderData>
                                    ))}
                                </Table.Header>
                                <Table.Body>{list}</Table.Body>
                            </Table>
                        </Col>
                    </Container>

                    <PaginationWithPageSelector
                        totalPages={totalPages}
                        pageNumber={pageNumber}
                        action={this.paginateEcomexList}
                        showFirstAndLastButton
                        numberPerPageOptionsToShow={numberPerPageOptionsToShow}
                        changeNumberPerPage={this.changeNumberPerPage}
                        className="ecomex-pagination"
                        selectedValue={numberPerPage}
                    />
                </>
            );
        }

        return (
            <Container className="flex-grow scrollable">
                <Col md={12} className="with-table-header-margin px-0">
                    <InfiniteScroll
                        dataLength={list.length}
                        next={this.paginateEcomexListMobile}
                        hasMore={totalPages !== pageNumber}
                        loader={<Spinner />}>
                        {list}
                    </InfiniteScroll>
                </Col>
            </Container>
        );
    };

    renderListEcomexAlertsAndDuesItem = (item) => {
        const { isDesktop } = this.props;
        const {
            siglaOperacion,
            sucursalOperacion,
            numeroOperacion,
            fechaOrigen,
            fechaVencimiento,
            nombreBeneficiario,
            siglaDivisa,
            importe,
            saldo,
        } = item;

        const elementForList = [
            { value: `${siglaOperacion} ${sucursalOperacion} ${numeroOperacion}`, align: "left" },
            { value: fechaOrigen, align: "center" },
            { value: fechaVencimiento, align: "center" },
            { value: nombreBeneficiario, align: "left" },
            { value: siglaDivisa, align: "right" },
            { value: importe, align: "right" },
            { value: saldo, align: "right" },
        ];

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    {elementForList.map((element) => (
                        <Table.Data align={element.align}>
                            <div className="data-text pr-3">{element.value}</div>
                        </Table.Data>
                    ))}
                </Table.Row>
            );
        }

        const headerItems = this.getHeader();
        return (
            <div
                className="account-table-wrapper account-table-header cursor-pointer py-2 my-2"
                onClick={() => this.getOperationDetail(item)}
                onKeyDown={() => this.getOperationDetail(item)}
                role="button"
                tabIndex={0}>
                <Col sm={6}>
                    {headerItems.map((headerItem, index) => (
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex space-between"
                            dataClassName="data-desc small-font"
                            label={`${FORM_ID}.column.${headerItem.value}`}
                            data={elementForList[index].value}
                        />
                    ))}
                </Col>
            </div>
        );
    };

    render() {
        return <>{this.renderListEcomexAlertsAndDues()}</>;
    }
}

const mapStateToProps = (state) => ({
    ecomexAlerts: ecomexSelectors.getEcomexAlerts(state),
    ecomexDues: ecomexSelectors.getEcomexDues(state),
    pageNumber: ecomexSelectors.getPageNumber(state),
    numberPerPageOptions: ecomexSelectors.getNumberPerPageOptions(state),
    numberPerPage: ecomexSelectors.getNumberPerPage(state),
    optionSelected: ecomexSelectors.getOptionSelected(state),
    filter: ecomexSelectors.getFilter(state),
    totalPages: ecomexSelectors.getTotalPages(state),
});

export default connect(mapStateToProps)(EcomexAlertsAndDuesList);
