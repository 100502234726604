import React, { Component } from "react";
import { bool, string } from "prop-types";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

class Logo extends Component {
    static propTypes = {
        className: string,
        logoColor: bool,
    };

    static defaultProps = {
        className: "",
        logoColor: false,
    };

    render() {
        const { className, logoColor } = this.props;
        return (
            <>
                {logoColor ? (
                    <Image src="images/logoCompanyColor.svg" className={className} />
                ) : (
                    <Image src="images/logoCompany.svg" className={className} />
                )}
                <I18n id="global.companyName" componentProps={{ className: "visually-hidden" }} />
            </>
        );
    }
}
export default Logo;
