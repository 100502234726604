import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";
import * as i18n from "util/i18n";
import * as config from "util/config";
import { bool } from "prop-types";

import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import { Mixpanel } from "util/clickstreaming";
import PasswordHelp from "pages/_components/PasswordHelp";

const FORM_ID_PASSWORD = "recoveryPassword.step3";
const FORM_ID_USERPASS = "recoveryUserpass.step3";

class Step3Content extends Component {
    getPasswordHelp = () => (
        <ul>
            <li>
                <I18n id="settings.changePassword.passwordHelp.rule1" />
            </li>
            <li>
                <I18n id="settings.changePassword.passwordHelp.rule2" />
            </li>
            <li>
                <I18n id="settings.changePassword.passwordHelp.rule3" />
            </li>
        </ul>
    );

    render() {
        const { isSubmitting, isDesktop, isRecoveringUserpass } = this.props;
        const maxLength = config.getInteger("core.password.maxLength", 8);
        const minLength = config.getInteger("core.password.minLength", 20);

        return (
            <Form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                <Container className="container--layout flex-grow align-items-center">
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <p className="text-lead">
                            <I18n id="recoveryPassword.step3.credential.message" />
                        </p>
                    </Col>
                </Container>
                <Container className="container--layout flex-grow align-items-center" gridClassName="container px-2">
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Field
                            name="password"
                            idForm={!isRecoveringUserpass ? FORM_ID_PASSWORD : FORM_ID_USERPASS}
                            component={Credential}
                            maxLength={maxLength}
                        />
                        <Field
                            name="passwordConfirmation"
                            idForm={!isRecoveringUserpass ? FORM_ID_PASSWORD : FORM_ID_USERPASS}
                            component={Credential}
                            maxLength={maxLength}
                        />
                        <PasswordHelp formId="settings.changePassword" minLength={minLength} maxLength={maxLength} />
                    </Col>
                </Container>
                <Container className="align-items-center container--layout" gridClassName="container px-2">
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Button label="global.send" loading={isSubmitting} type="submit" bsStyle="primary" />
                    </Col>
                </Container>
            </Form>
        );
    }
}

Step3Content.propTypes = {
    isSubmitting: bool,
    isDesktop: bool,
    isRecoveringUserpass: bool,
};

Step3Content.defaultProps = {
    isSubmitting: false,
    isDesktop: true,
    isRecoveringUserpass: false,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: true,
    mapPropsToValues: () => ({ password: "", passwordConfirmation: "" }),
    validationSchema: (props) =>
        Yup.lazy((values) => {
            if (values.password === undefined || values.passwordConfirmation === undefined) {
                return Yup.object().shape({
                    password: Yup.string().required(
                        i18n.get(
                            props.isRecoveringUserpass
                                ? `settings.changeUserpass.newUserpass.required`
                                : `settings.changePassword.password.required`,
                        ),
                    ),
                    passwordConfirmation: Yup.string().required(
                        i18n.get(
                            props.isRecoveringUserpass
                                ? `settings.changeUserpass.newUserpassConfirmation.required`
                                : `settings.changePassword.newPassword.required`,
                        ),
                    ),
                });
            }
            return Yup.object().shape({
                password: Yup.string()
                    .min(
                        config.getInteger("core.password.minLength", 8),
                        i18n.get(`settings.changePassword.modify.error.minLength`),
                    )
                    .max(
                        config.getInteger("core.password.maxLength", 20),
                        i18n.get(`settings.changePassword.modify.error.maxLength`),
                    )
                    .matches(
                        new RegExp(config.get("core.userconfiguration.updateuserpassword.pattern")),
                        i18n.get(`settings.changeUserpass.modify.error.mustBeAlphanumeric`),
                    ),
                passwordConfirmation: Yup.string()
                    .min(
                        config.getInteger("core.password.minLength", 8),
                        i18n.get(`settings.changePassword.modify.error.minLength`),
                    )
                    .max(
                        config.getInteger("core.password.maxLength", 20),
                        i18n.get(`settings.changePassword.modify.error.maxLength`),
                    )
                    .oneOf([values.password], i18n.get(`settings.changePassword.modify.error.passwordMustMatch`)),
            });
        }),
    handleSubmit: (values, formikBag) => {
        Mixpanel.track(FORM_ID_PASSWORD);
        const { isRecoveringUserpass } = formikBag.props;

        if (isRecoveringUserpass) {
            formikBag.props.dispatch(
                recoveryPasswordActions.recoveryUserpassStep3(
                    values.password,
                    values.passwordConfirmation,
                    formikBag.props.exchangeToken,
                    formikBag.props.resetCode,
                    formikBag,
                ),
            );
        } else {
            formikBag.props.dispatch(
                recoveryPasswordActions.recoveryPassStep3(
                    values.password,
                    values.passwordConfirmation,
                    formikBag.props.exchangeToken,
                    formikBag.props.resetCode,
                    formikBag,
                ),
            );
        }
    },
})(Step3Content);
