/*
 * API middleware to interact with the legacy API
 */

import axios from "axios";
import { store } from "../store";
import { version } from "../../package.json";
import cordovaAdapter from "./axiosCordovaAdapter";

let apiAxios;
if (window.isMobileApp() && window.API_URL.startsWith("https://")) {
    apiAxios = axios.create({
        adapter: cordovaAdapter,
    });
    apiAxios.defaults.headers["x-app-version"] = version;
} else {
    apiAxios = axios.create();
}
apiAxios.defaults.baseURL = window.API_URL;
apiAxios.defaults.headers.Accept = "application/json, application/octet-stream";

const cachedEtags = new Map();
const methodsWithoutBody = ["head", "get"];

apiAxios.interceptors.request.use((request) => {
    let urlKey;

    if (request.url.startsWith("/")) {
        urlKey = request.baseURL + request.url;
    } else {
        urlKey = request.url;
    }

    if (!methodsWithoutBody.includes(request.method)) {
        const { activeEnvironment } = store.getState().session;
        if (activeEnvironment) {
            request.data.idEnvironment = activeEnvironment.id;
        }

        if (!request.data.lang) {
            const { lang } = store.getState().i18n;
            request.data.lang = lang;
        }
        request.data.channel = channel();
    }

    if (cachedEtags.has(urlKey)) {
        request.headers["If-None-Match"] = cachedEtags.get(urlKey);
    }
    return request;
});

apiAxios.interceptors.response.use(
    (response) => {
        if (response.headers.etag) {
            cachedEtags.set(response.request.responseURL, response.headers.etag);
        }
        if (response.status === 204) {
            return response;
        }

        // cubre el caso de un download de un archivo
        if (response.status === 200 && response.request.responseType === "blob") {
            return response;
        }

        if (!response.data.code || response.data.code.endsWith("E")) {
            throw response;
        }
        // dejamos en type el tipo del error (I - Info, W - Warning, E - Error)
        response.type = response.data.code.slice(-1);
        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 304) {
                return error.response;
            }
        }

        // se agrega el atributo para saber que es un error de saga
        // eslint-disable-next-line
        error.httpError = true;

        throw error;
    },
);

export function channel() {
    const { isAssistantLogin } = store.getState().assistant;

    if (isAssistantLogin) {
        return "assistant";
    }
    return window.cordova ? "phonegap" : "frontend";
}

export const setAuthToken = (token) => {
    apiAxios.defaults.headers.common.Authorization = `bearer ${token}`;
};

export const executeWithAccessToken = (idActivity, params) => {
    if (apiAxios.defaults.headers.common.Authorization) {
        return apiAxios.post(idActivity, {
            ...params,
        });
    }
    return apiAxios.post(
        `/${idActivity}`,
        {
            ...params,
        },
        {
            baseURL: window.DAS_URL,
        },
    );
};

export const downloadWithAccessToken = (idActivity, idFile) =>
    apiAxios({
        url: `${idActivity}/${idFile}`,
        method: "GET",
        responseType: "blob",
        headers: {
            channel: channel(),
        },
    });

export const executeWithExchangeToken = (idActivity, params, exchangeToken) =>
    apiAxios.post(
        idActivity,
        {
            ...params,
        },
        {
            headers: {
                Authorization: `exchange ${exchangeToken}`,
            },
        },
    );

export const executeAnonymous = (idActivity, params) =>
    apiAxios.post(
        idActivity,
        {
            ...params,
        },
        {
            headers: {
                Authorization: undefined,
            },
        },
    );

export const executeAnonymousWithTimeout = (idActivity, params, timeout) =>
    apiAxios.post(
        idActivity,
        {
            ...params,
        },
        {
            timeout,
            headers: {
                Authorization: undefined,
            },
        },
    );
