import React, { Component } from "react";
import { string } from "prop-types";

import DetailBox from "pages/_components/detailBox/DetailBox";

class BankSearchDetailedItem extends Component {
    static propTypes = {
        bankName: string.isRequired,
        bankAddress: string.isRequired,
        bankCountryLabel: string.isRequired,
    };

    render() {
        const { bankName, bankAddress, bankCountryLabel } = this.props;

        return (
            <DetailBox>
                <DetailBox.Data label="forms.bankselector.infoBankName">{bankName}</DetailBox.Data>
                <DetailBox.Data label="forms.bankselector.infoBankAddress"> {bankAddress}</DetailBox.Data>
                <DetailBox.Data label="forms.bankselector.infoBankCountry">{bankCountryLabel}</DetailBox.Data>
            </DetailBox>
        );
    }
}

export default BankSearchDetailedItem;
