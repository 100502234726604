import React from "react";
import Image from "pages/_components/Image";
import PropTypes from "prop-types";
import classNames from "classnames";

import ConditionalContent from "./ConditionalContent";

function InvestorProfileCard({
    img,
    title,
    description,
    id,
    isActive,
    isDesktop,
    handleClick,
    profileDescriptionFeatures,
}) {
    return (
        <div className={classNames({ card: !isActive, "card--active": isActive })}>
            <div
                className="card__body"
                onClick={() => handleClick(id)}
                onKeyDown={() => handleClick(id)}
                role="button"
                tabIndex={id}>
                <div className={classNames("pt-2 px-2", { "d-flex": !isDesktop })}>
                    <Image src={img} className="svg-image" />
                    <h2 className="card__title my-0">{title}</h2>
                </div>
                <p className="card__description">{description}</p>
                <ConditionalContent className="justify-content-start pl-3 text-transform-none">
                    <ul className="card__description--items">
                        {profileDescriptionFeatures.map((descriptionItem) => (
                            <li>{descriptionItem}</li>
                        ))}
                    </ul>
                </ConditionalContent>
            </div>
        </div>
    );
}

InvestorProfileCard.propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    isDesktop: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    profileDescriptionFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
};

InvestorProfileCard.defaultProps = {
    isActive: false,
};

export default InvestorProfileCard;
