/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withFormik } from "formik";
import { func, bool } from "prop-types";
import * as i18nUtils from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import * as dateUtils from "util/date";
import moment from "moment";
import { Box, Icon, Modal, Text, Form } from "@ui-kit/components/index.es";
import LapseSubOption from "./LapseSubOption";

const STARTS_ON_DATE = moment(new Date()).add(1, "days");

function SchedulerModal(props) {
    SchedulerModal.propTypes = {
        onScheduleClick: func,
        onClose: func,
        isVisible: bool,
    };

    SchedulerModal.defaultProps = {
        onScheduleClick: () => {},
        onClose: () => {},
        isVisible: false,
    };

    const handleOnScheduleClick = (schedulerValues) => {
        const { onScheduleClick } = props;

        onScheduleClick({...schedulerValues, ...values, lapse: {...lapseValues}});

    };

    const [values, setValues] = useState({
        startsOn: STARTS_ON_DATE.toDate(),
        lapse: "",
        frequency: "TODAY",
        customFreq: "",
    });

    const [lapseValues, setLapseValues] = useState({
        startsOn: new Date(),
        date: new Date(),
        lapse: schedulerUtils.NEVER,
        number: 1,
    });

    const { onClose, isVisible } = props;

    const { frequency, startsOn } = values;

    return (
        <Modal closeOnBgTap isVisible={isVisible} onClose={onClose}>
            <Box m={24}>
                <Box align="center" flex column>
                    <Icon color="primary-color" id="UserDefault" name="CalendarAdd" size={32} />
                    <Text align="center" variant="bodyStrong">
                        {i18nUtils.get("scheduler.title")}
                    </Text>
                </Box>
                <Form
                    wrapButtons
                    buttonSubmit={{
                        colProps: {
                            size: 8,
                            offset: 3,
                        },
                        text: "Agendar",
                        variant: "solid",
                    }}
                    initialValues={{
                        frequency: {
                            label: "Ahora",
                            value: "TODAY",
                        },
                        dateFrom: new Date(),
                    }}
                    inputsList={[
                        {
                            colProps: {
                                size: 12,
                            },
                            component: "selectField",
                            defaultOption: "TODAY",
                            id: "frequency",
                            options: schedulerUtils.listRecurrencies(),
                            selectLabel: "Frecuencia",
                            value: frequency,
                            onSelectOption: (freq) => setValues({ ...values, frequency: freq.value }),
                        },
                        ...(frequency !== schedulerUtils.TODAY
                            ? [
                                  {
                                      colProps: {
                                          size: 12,
                                      },
                                      component: "dateField",
                                      id: "dateFrom",
                                      label: i18nUtils.get("scheduler.startsOn.label"),
                                      minDate: startsOn,
                                      startDate: startsOn,
                                      value: startsOn,
                                      selectsStart: true,
                                      onDateChange: (date) => setValues({ ...values, startsOn: date }),
                                  },
                              ]
                            : []),
                        ...(frequency !== schedulerUtils.TODAY && frequency !== schedulerUtils.ONCE
                            ? [
                                  {
                                      colProps: {
                                          size: 12,
                                      },
                                      id: "lapse",
                                      customElement: () => <LapseSubOption values={lapseValues} onChange={setLapseValues}/>,
                                  },
                              ]
                            : []),
                    ]}
                    onSubmit={(e) => {handleOnScheduleClick(e)}}
                />
            </Box>
        </Modal>
    );
}

const mapStateToProps = () => ({});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { value } = props;
            const hasDate = value?.valueDate;
            const newProgram = {
                frequency: value?.selectedOption ? value.selectedOption : schedulerUtils.TODAY,
                startsOn:
                    value && value.selectedOption === schedulerUtils.ONCE && hasDate
                        ? moment(value.valueDate).toDate()
                        : STARTS_ON_DATE.startOf("day").toDate(),
                lapse: {
                    date: STARTS_ON_DATE.startOf("day").toDate(),
                    lapse: schedulerUtils.NEVER,
                },
                customFreq: schedulerUtils.DAY,
                customFreqValue: "1",
                days: dateUtils.getDayFromDate(STARTS_ON_DATE.startOf("day").toDate()),
                occurrenceType: "day", // day(every month on x day ) or occurrence(every month on the "tirth" "tuesday")
            };

            if (!value || !value.program) {
                return newProgram;
            }

            const startsOn =
                typeof value.program.startsOn === "string"
                    ? moment(value.program.startsOn).toDate()
                    : value.program.startsOn;
            return {
                ...value.program,
                startsOn,
                frequency: value.program.isCustom ? schedulerUtils.CUSTOM : value.program.frequency,
                lapse: {
                    lapse: value.program.lapse,
                    date: value.program.date,
                    number: value.program.number,
                },
                customFreq: value.program.frequency,
                customFreqValue: value.program.frequencyValue,
                occurrenceType: value.program.occurrence ? "occurrence" : "day",
            };
        },
    }),
)(SchedulerModal);
