import { createReducer, makeActionCreator } from "util/redux";

const INITIAL_STATE = {
    fetching: false,
    credentialGroups: [],
    availableGroups: [],
    selectedGroups: [],
    groupsToSave: [],
    idTransaction: null,
    idActivity: null,
};

export const selectors = {
    isFetching: ({ groups }) => groups.fetching,
    getCredentialGroups: ({ groups }) => groups.credentialGroups,
    getAvailableGroups: ({ groups }) => groups.availableGroups,
    getSelectedGroups: ({ groups }) => groups.selectedGroups,
    getGroupsToSave: ({ groups }) => groups.groupsToSave,
    getIdTransaction: ({ groups }) => groups.idTransaction,
    getIdActivity: ({ groups }) => groups.idActivity,
};

export default (name) => {
    const types = {
        LOAD_GROUPS_REQUEST: `${name}/LOAD_GROUPS_REQUEST`,
        LOAD_GROUPS_FAILURE: `${name}/LOAD_GROUPS_FAILURE`,
        LOAD_GROUPS_SUCCESS: `${name}/LOAD_GROUPS_SUCCESS`,
        UPDATE_GROUPS_OF_USER_PREVIEW: `${name}/UPDATE_GROUPS_OF_USER_PREVIEW`,
        UPDATE_GROUPS_OF_USER_PREVIEW_SUCCESS: `${name}/UPDATE_GROUPS_OF_USER_PREVIEW_SUCCESS`,
        UPDATE_GROUPS_OF_USER_CONFIRM: `${name}/UPDATE_GROUPS_OF_USER_CONFIRM`,
    };

    return {
        types,
        reducer: createReducer(INITIAL_STATE, {
            [types.LOAD_GROUPS_REQUEST]: (state) => ({ ...state, fetching: true }),
            [types.LOAD_GROUPS_FAILURE]: (state) => ({ ...state, fetching: false }),
            [types.LOAD_GROUPS_SUCCESS]: (state, { data }) => ({
                ...state,
                availableGroups: data.availableGroups,
                selectedGroups: data.selectedGroups,
                fetching: false,
            }),
            [types.UPDATE_GROUPS_OF_USER_PREVIEW_SUCCESS]: (
                state,
                { selectedGroupsIds, credentialGroups, idTransaction, idActivity },
            ) => ({
                ...state,
                groupsToSave: state.availableGroups.filter(
                    (group) => selectedGroupsIds.indexOf(group.idGroupAsString) > -1,
                ),
                credentialGroups,
                idTransaction,
                idActivity,
                fetching: false,
            }),
        }),
        actions: {
            loadGroupsRequest: makeActionCreator(types.LOAD_GROUPS_REQUEST, "id"),
            loadGroupsFailure: makeActionCreator(types.LOAD_GROUPS_FAILURE),
            loadGroupsSuccess: makeActionCreator(types.LOAD_GROUPS_SUCCESS, "data"),
            updateGroupsOfUserPreview: makeActionCreator(types.UPDATE_GROUPS_OF_USER_PREVIEW, "data", "formikBag"),

            updateGroupsOfUserPreviewSuccess: makeActionCreator(
                types.UPDATE_GROUPS_OF_USER_PREVIEW_SUCCESS,
                "selectedGroupsIds",
                "credentialGroups",
                "idTransaction",
                "idActivity",
            ),
            updateGroupsOfUserConfirm: makeActionCreator(types.UPDATE_GROUPS_OF_USER_CONFIRM, "data", "formikBag"),
        },
    };
};
