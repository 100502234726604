import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import { Redirect } from "react-router-dom";
import { bool, func, shape, string, arrayOf } from "prop-types";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Circle from "pages/_components/Circle";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import Heading from "pages/_components/Heading";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import FormattedAmount from "pages/_components/FormattedAmount";

import { resizableRoute } from "pages/_components/Resizable";

class SignatureSchemeDetail extends Component {
    static propTypes = {
        actions: shape({
            modifySignaturesSchemePreRequest: func,
        }),
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        fetching: bool,
        isDesktop: bool.isRequired,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }),
        routerActions: shape({
            goBack: func,
            push: func,
        }),
        signature: shape({
            signatureType: string,
            signatureDispatch: bool,
        }),
        environmentProducts: arrayOf(
            shape({
                idProduct: string,
            }),
        ).isRequired,
    };

    static defaultProps = {
        actions: null,
        fetching: false,
        match: null,
        routerActions: null,
        signature: null,
    };

    componentDidMount() {
        const {
            actions,
            match: {
                params: { idSignature },
            },
        } = this.props;

        actions.modifySignaturesSchemePreRequest(idSignature);
    }

    handleBack = () => {
        const {
            activeEnvironment: { administrationScheme },
            routerActions,
        } = this.props;

        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes`);
    };

    handleModify = () => {
        const {
            activeEnvironment: { administrationScheme },
            signature,
            routerActions,
        } = this.props;

        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/${signature.idSignature}`);
    };

    handleDelete = () => {
        const {
            activeEnvironment: { administrationScheme },
            routerActions,
            signature,
        } = this.props;

        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/remove/${signature.idSignature}`);
    };

    renderPageHeader = () => {
        const {
            signature,
            activeEnvironment: { administrationScheme },
        } = this.props;
        const name = `${i18nUtils.get("administration.signatures.detail")}`;
        let letters = "";
        if (signature) {
            const { signatureGroup } = signature;
            letters += administrationScheme === "medium" ? `${`${signatureGroup.length}`}` : `${`${signatureGroup}`}`;
        }

        return (
            <>
                <div className="admin-detail-head admin-detail-head-section px-0">
                    <Head onBack={this.handleBack} />
                    <div className="mt-2 mb-4">
                        <Head
                            centerElement={() => (
                                <h1 className="title-content">
                                    {name}
                                    {letters !== "" && <span className="pl-2 letter-spacing-05">{letters}</span>}
                                </h1>
                            )}
                            toolbarItemClassName="pr-0"
                            handleDeleteClick={() => {
                                this.handleDelete();
                            }}
                            handleModifyClick={() => this.handleModify()}
                            hasInlineButtons
                        />
                    </div>
                </div>
            </>
        );
    };

    signatureToLabel = (signatureDispatch) => (
        <Circle
            smallSize
            value={
                signatureDispatch ? i18nUtils.get("global.yes").toUpperCase() : i18nUtils.get("global.no").toUpperCase()
            }
            bgColor={signatureDispatch ? "#0be0a2" : "#fc5f5f"}
        />
    );

    renderContent = () => {
        const { signature, environmentProducts } = this.props;
        const dispatch = signature && signature.signatureDispatch;
        let signatureType = "administration.signatures.detail.signatureType.";
        let functionalGroups = null;
        if (signature) {
            signatureType += `${signature.signatureType}`;
            const { signatureFeatures } = signature;
            functionalGroups = signatureFeatures.length > 0 ? signatureFeatures : null;
        }
        const signatureProducts =
            environmentProducts &&
            environmentProducts.filter(({ idProduct }) => signature && signature.products.includes(idProduct));
        const labelCapFrequency = "administration.signatures.transactions.capFrequency.";
        const labelFunctionalGroups = "administration.signatures.functionalGroups.";

        return (
            <>
                <Notification scopeToShow="signatureSchemeDetail" />
                {this.renderPageHeader()}
                <MainContainer>
                    <div className="above-the-fold signature-detail">
                        <div className="m-3">
                            <Row className="content-data background-white">
                                <div className="ml-3 mt-3">
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper-flex"
                                        label="administration.signatures.list.liberating"
                                        labelClassName="mb-3"
                                        data={this.signatureToLabel(dispatch)}
                                    />
                                </div>
                            </Row>
                        </div>
                        <Container className="flex-grow admin-detail" rowClassName="justify-content-start">
                            <Container.Column md={3} sm={12} className="pt-4">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="administration.signatures.list.transactionType"
                                    labelClassName="mb-3"
                                    data={signature ? i18nUtils.get(signatureType) : "no hay datos"}
                                />
                            </Container.Column>

                            <Container.Column md={3} sm={12} className="pt-4">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="administration.signatures.detail.signatureType.topAmount"
                                    labelClassName="mb-3"
                                    data={
                                        signature && signature.capList[0] ? (
                                            <FormattedAmount
                                                className="data-amount"
                                                frequencyInSpan
                                                currency={configUtils.get("core.masterCurrency")}
                                                quantity={
                                                    signature.capList[0].maximum === -1
                                                        ? configUtils.get(
                                                              `default_cap_signature_${signature.signatureType}`,
                                                          )
                                                        : signature.capList[0].maximum
                                                }
                                                frequency={i18nUtils.get(
                                                    "administration.signatures.create.advanced.topAmountPerPeriod",
                                                    null,
                                                    {
                                                        period: i18nUtils.get(
                                                            `${labelCapFrequency}${signature.capList[0].frequency}`,
                                                        ),
                                                    },
                                                )}
                                                notBold
                                            />
                                        ) : (
                                            <p> - </p>
                                        )
                                    }
                                />
                            </Container.Column>

                            <Container.Column md={3} sm={12} className="pt-4">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="administration.permissions.functionalities"
                                    labelClassName="mb-3"
                                    data={
                                        functionalGroups !== null ? (
                                            <ul className="list">
                                                {functionalGroups.map((functionalGroup) => (
                                                    <li key={functionalGroup} className="list-item mb-2">
                                                        <div className="chip-signature-scheme">
                                                            <span className="data-desc">
                                                                {i18nUtils.get(
                                                                    `${labelFunctionalGroups}${functionalGroup}`,
                                                                )}
                                                            </span>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p>-</p>
                                        )
                                    }
                                />
                            </Container.Column>

                            <Container.Column md={3} sm={12} className="pt-4">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper"
                                    label="administration.signatures.detail.signatureType.productList"
                                    labelClassName="mb-3"
                                    data={
                                        signatureProducts && signatureProducts.length ? (
                                            <ul className="list">
                                                {signatureProducts.map((product) => (
                                                    <li key={product.idProduct} className="list-item mb-2">
                                                        <div className="chip-signature-scheme">
                                                            <span className="data-desc">{product.label}</span>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p> - </p>
                                        )
                                    }
                                />
                            </Container.Column>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    };

    render() {
        const { fetching, isDesktop } = this.props;
        const isLoading = fetching;
        if (!isDesktop) {
            return <Redirect to="/desktop" />;
        }

        return <PageLoading loading={isLoading}>{!isLoading && this.renderContent()}</PageLoading>;
    }
}

export default resizableRoute(SignatureSchemeDetail);
