import React, { useState, useEffect } from "react";
import arrowUp from "styles/images/arrow-up.png";
import arrowDown from "styles/images/arrow-down.png";
import { string, func, shape } from "prop-types";

export default function FilterTableHead({ columnFilter, filterName, changeFilter }) {
    const [ascendent, setAscendent] = useState(true);
    const [descendent, setDescendent] = useState(true);

    FilterTableHead.propTypes = {
        columnFilter: shape({}).isRequired,
        filterName: string.isRequired,
        changeFilter: func.isRequired,
    };

    const changeOrder = () => {
        if (ascendent && descendent) {
            setDescendent(false);
            changeFilter(filterName, "ascendent");
        } else if (ascendent && !descendent) {
            setDescendent(true);
            setAscendent(false);
            changeFilter(filterName, "descendent");
        } else if (!ascendent && descendent) {
            setDescendent(false);
            setAscendent(true);
            changeFilter(filterName, "ascendent");
        }
    };

    useEffect(() => {
        if (columnFilter.filterSelected === filterName) {
            if (columnFilter.directionFilter === "ascendent") {
                setDescendent(false);
            } else {
                setAscendent(false);
            }
        } else {
            setAscendent(true);
            setDescendent(true);
        }
    }, [columnFilter, filterName]);

    return (
        <div className="d-inline-flex align-item-center">
            <button type="button" onClick={() => changeOrder()} className="order-btn">
                <ul>
                    <li>
                        <img src={arrowUp} alt="" style={{ opacity: ascendent ? 1 : 0 }} />
                    </li>
                    <li>
                        <img src={arrowDown} alt="" style={{ opacity: descendent ? 1 : 0 }} />
                    </li>
                </ul>
            </button>
        </div>
    );
}
