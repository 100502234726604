/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, node, func, number, bool, string } from "prop-types";

import { connect } from "react-redux";
import { push } from "react-router-redux";
import { selectors } from "reducers/widgets";

import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import WidgetList from "./WidgetList";
import WidgetHeader from "./_components/WidgetHeader";
import FundListItem from "./_components/FundListItem";

const WIDGET_NAME = "funds";

class Funds extends Component {
    static propTypes = {
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
        fundsCount: number.isRequired,
        dispatch: func.isRequired,
        errorMsg: string.isRequired,
        isEditable: bool,
        isDesktop: bool,
    };

    static defaultProps = {
        closeButton: null,
        isEditable: false,
        isDesktop: true,
    };

    render() {
        const { closeButton, errorMsg, draggableItemProps, fundsCount, dispatch, isEditable, isDesktop } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetFounds" };

        return (
            <WidgetList
                item={FundListItem}
                keyExtractor={(item) =>
                    `${item.cuotapartista.numero}${item.fondo.numFondo}${item.tipoValorCuotaparte.abreviatura}`
                }
                close={closeButton}
                isEditable={isEditable}
                name="funds">
                {(list) => (
                    <div
                        role={isEditable ? "button" : "none"}
                        className={!isDesktop ? "widget fondos-widget" : "fondos-widget"}
                        {...uiAutomationProp}
                        {...draggableItemProps}
                        aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                        <WidgetHeader
                            title={i18nUtils.get("list.addWidget.funds")}
                            action={
                                <>
                                    {fundsCount > 3 && !isEditable && !errorMsg && (
                                        <Button
                                            onClick={() => {
                                                dispatch(push("/fundcorpOperations"));
                                            }}
                                            bsStyle="outline"
                                            label="menu.seemore.label"
                                            type="button"
                                            image="images/arrow-right-blue.svg"
                                        />
                                    )}
                                </>
                            }
                        />
                        <div className="widget__content">{list}</div>
                    </div>
                )}
            </WidgetList>
        );
    }
}

const mapStateToProps = (state) => {
    const { data, errorMsg, isFetching } = selectors.getWidget(state, WIDGET_NAME);

    return {
        isFetching,
        fundsCount: data.fundsCount,
        errorMsg,
    };
};

export default connect(mapStateToProps)(Funds);
