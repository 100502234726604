/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { func, string, shape, arrayOf, objectOf, bool } from "prop-types";
import { connect } from "react-redux";

import { permissionsSelectors } from "reducers/administration";
import * as administrationUtils from "util/administration";

import ProductLabelIcon from "pages/_components/ProductLabelIcon";
import Accordion from "pages/_components/Accordion";
import PermissionsAmount from "pages/administration/_components/PermissionsAmount";
import classNames from "classnames";

class PermissionsList extends Component {
    static propTypes = {
        permissions: objectOf(arrayOf(string)),
        products: arrayOf(
            shape({
                idProduct: string,
                number: string,
                productAlias: string,
                label: string,
            }),
        ).isRequired,
        groups: administrationUtils.groupsPropType.isRequired,
        children: func.isRequired,
        accordionView: bool,
        withoutBorderItem: bool,
        borderClassName: string,
    };

    static defaultProps = {
        permissions: {},
        accordionView: false,
        withoutBorderItem: false,
        borderClassName: "",
    };

    renderProducts = (option) => {
        const { permissions: permissionsFromProps, products: productFromProps } = this.props;

        return (
            <ul className="list">
                {productFromProps.reduce((products, { idProduct, number, productAlias, label }) => {
                    const permissions = permissionsFromProps[option.idItem];

                    if (!permissions.includes(idProduct)) {
                        return products;
                    }

                    const correctLabel = label ? label.replaceAll("null", "") : "";

                    return [
                        ...products,
                        <li className="list-item" key={idProduct}>
                            <div className="list-item-inner">
                                <div
                                    style={{
                                        display: "inline-flex",
                                    }}
                                    className="product-label">
                                    <ProductLabelIcon number={number} />
                                    <div className="product-label-text">
                                        <div className="product-label-name">{productAlias || correctLabel}</div>
                                        {productAlias && <div className="product-label-info">{correctLabel}</div>}
                                    </div>
                                </div>
                            </div>
                        </li>,
                    ];
                }, [])}
            </ul>
        );
    };

    renderItem = (option) => {
        const { withoutBorderItem } = this.props;

        return (
            <li className="list-item" key={option.idItem}>
                <div className={`list-item-inner ${withoutBorderItem ? "without-border" : ""}`}>{option.label}</div>
            </li>
        );
    };

    loadOptions = (childrenList) =>
        childrenList.reduce((categoryOptions, option) => {
            if (option.childrenList && option.childrenList.length) {
                const subOptions = this.loadOptions(option.childrenList);

                if (!subOptions.length) {
                    return categoryOptions;
                }

                return [
                    ...categoryOptions,
                    <li className="list-item" key={option.idItem}>
                        <div className="list-item-inner">
                            <span className="navigational-list-subtitle px-0">{option.label}</span>
                        </div>
                        <ul className="list">{subOptions}</ul>
                    </li>,
                ];
            }

            const { permissions: permissionsFromProps } = this.props;

            const permissions = permissionsFromProps[option.idItem] || [];

            if (permissions.length) {
                const [permission] = option.permissionList || [];

                return [
                    ...categoryOptions,
                    permission && permission.simpleAllowProductSelection ? (
                        <li className="list-item" key={option.idItem}>
                            <div className="list-item-inner">
                                <span className="navigational-list-subtitle px-0">{option.label}</span>
                            </div>
                            {this.renderProducts(option)}
                        </li>
                    ) : (
                        this.renderItem(option)
                    ),
                ];
            }

            return categoryOptions;
        }, []);

    render() {
        const { children, groups, permissions, accordionView, borderClassName } = this.props;

        return (
            <PermissionsAmount permissions={permissions}>
                {(amountsById) => {
                    if (!Object.keys(amountsById).length) {
                        return children();
                    }

                    if (accordionView) {
                        return children(
                            <Accordion className={classNames("list list--permissions", borderClassName)}>
                                {groups.reduce((mainCategories, option) => {
                                    const { childrenList, idItem, label, permissionList = [] } = option;
                                    const categoryOptions = this.loadOptions(childrenList);
                                    const optionsAmount = amountsById[idItem];
                                    const [permission] = permissionList;

                                    if (
                                        (permission && permission.simpleAllowProductSelection) ||
                                        categoryOptions.length
                                    ) {
                                        if (permission && permission.simpleAllowProductSelection && !optionsAmount) {
                                            return mainCategories;
                                        }

                                        return [
                                            ...mainCategories,
                                            <Accordion.Item
                                                key={idItem}
                                                number={mainCategories.length}
                                                fitContent
                                                item={
                                                    <span>
                                                        <span>{label}</span>{" "}
                                                        <span className="list-item-hint">{optionsAmount}</span>
                                                    </span>
                                                }>
                                                {permission && permission.simpleAllowProductSelection ? (
                                                    this.renderProducts(option)
                                                ) : (
                                                    <ul className="list permissions__list-item">{categoryOptions}</ul>
                                                )}
                                            </Accordion.Item>,
                                        ];
                                    }

                                    if (!optionsAmount) {
                                        return mainCategories;
                                    }

                                    return [...mainCategories, this.renderItem(option)];
                                }, [])}
                            </Accordion>,
                        );
                    }
                    return children(
                        <div className="list list--permissions px-2">
                            {groups.reduce((mainCategories, option) => {
                                const { childrenList, idItem, label, permissionList = [] } = option;
                                const categoryOptions = this.loadOptions(childrenList);
                                const optionsAmount = amountsById[idItem];
                                const [permission] = permissionList;

                                if ((permission && permission.simpleAllowProductSelection) || categoryOptions.length) {
                                    if (permission && permission.simpleAllowProductSelection && !optionsAmount) {
                                        return mainCategories;
                                    }

                                    return [
                                        ...mainCategories,
                                        <div>
                                            <br />
                                            <span className="data-label">{label}</span>
                                            {permission && permission.simpleAllowProductSelection
                                                ? this.renderProducts(option)
                                                : categoryOptions.map((category) => category)}
                                        </div>,
                                    ];
                                }

                                if (!optionsAmount) {
                                    return mainCategories;
                                }

                                return [...mainCategories, this.renderItem(option)];
                            }, [])}
                        </div>,
                    );
                }}
            </PermissionsAmount>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    products: permissionsSelectors.getProducts(state),
    permissions: ownProps.permissions || permissionsSelectors.getPermissions(state),
    groups: permissionsSelectors.getGroups(state),
});

export default connect(mapStateToProps)(PermissionsList);
