import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { func, bool, shape, string, number, arrayOf } from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Measure from "react-measure";

import * as configUtils from "util/config";
import {
    actions as transactionLinesActions,
    selectors as transactionLinesSelectors,
} from "reducers/form/transactionLines";
import { selectors as fileSelectors } from "reducers/formFields/multilineFile";
import { selectors as formSelectors } from "reducers/form";

import Table from "pages/_components/Table";
import TransactionLinesListItem from "pages/forms/_components/_fields/_transactionlines/ListItem";
import I18n from "pages/_components/I18n";
import Scroll from "pages/_components/Scroll";
import Container from "pages/_components/Container";
import DataContent from "pages/_components/DataContent";
import FormattedDate from "pages/_components/FormattedDate";
import { resizableRoute } from "pages/_components/Resizable";
import Button from "pages/_components/Button";
import Pagination from "pages/_components/pagination/Pagination";

class TransactionLinesList extends Component {
    static propTypes = {
        filter: func,
        isDesktop: bool.isRequired,
        isEditing: bool,
        isReadOnly: bool.isRequired,
        processedFileData: shape({
            fileIdentifier: string,
            fileName: string,
            totalAmount: shape({
                quantity: number,
                currency: string,
            }),
        }).isRequired,
        transaction: shape({
            creationDateTime: string,
            data: shape({
                file: arrayOf(
                    shape({
                        fileName: string,
                    }),
                ),
            }),
        }).isRequired,
        detailPreview: string,
        dispatch: func.isRequired,
        onClose: func.isRequired,
        match: shape({
            params: shape({ idForm: string }),
        }).isRequired,
        activeFilters: shape({}).isRequired,
        latestPageNumber: number.isRequired,
        isTicket: bool.isRequired,
        isEditingPayments: bool.isRequired,
        lines: shape({}).isRequired,
        isLastPage: bool.isRequired,
        lastPages: number.isRequired,
        fetching: bool.isRequired,
    };

    static defaultProps = {
        detailPreview: false,
        isEditing: false,
        filter: null,
    };

    state = {
        top: 0,
    };

    renderDetailsColumns = () => {
        const { processedFileData, transaction } = this.props;
        // Was payed via file
        if (processedFileData.fileIdentifier) {
            const { fileName } = transaction.data.file[0];
            return [
                <Container.Row>
                    <Container.Column sm={12} md={4}>
                        <DataContent label={<I18n id="transaction.process.details.heading.id" />}>
                            {processedFileData.fileIdentifier}
                        </DataContent>
                    </Container.Column>
                    <Container.Column sm={12} md={4}>
                        <DataContent label={<I18n id="transaction.process.details.heading.name" />}>
                            {fileName}
                        </DataContent>
                    </Container.Column>
                    <Container.Column sm={12} md={4}>
                        <DataContent label={<I18n id="transaction.process.details.heading.date" />}>
                            <FormattedDate date={transaction.creationDateTime} />
                        </DataContent>
                    </Container.Column>
                </Container.Row>,
            ];
        }
        return [
            <Container.Row>
                <Container.Column sm={12} md={4}>
                    <DataContent label={<I18n id="transaction.process.details.heading.date" />}>
                        <FormattedDate date={transaction.creationDateTime} />
                    </DataContent>
                </Container.Column>
            </Container.Row>,
        ];
    };

    handleLoadMoreClick = (pageNumber) => {
        const { dispatch, match, activeFilters } = this.props;

        dispatch(
            transactionLinesActions.listTransactionLinesRequest({
                id: match.params.idTransaction,
                pageNumber,
                ...activeFilters,
            }),
        );
    };

    renderItem = (item) => {
        const { detailPreview, dispatch, isEditingPayments, isReadOnly, isTicket, lines } = this.props;
        const itemKey = `${lines.length - item.lineNumber}-${item.creditAccountNumber}`;

        return (
            <TransactionLinesListItem
                isTicket={isTicket}
                key={itemKey}
                {...item}
                detailPreview={detailPreview}
                dispatch={dispatch}
                isEditingPayments={isEditingPayments}
                totalLines={lines.length}
                isReadOnly={isReadOnly}
            />
        );
    };

    renderMobile = (items) => {
        const { top } = this.state;
        const { isLastPage, fetching, latestPageNumber } = this.props;

        if (!items.length) {
            return <I18n id="transactions.list.none" />;
        }

        const endOfListItem = (
            <div className="table-row" key="noMoreTransactions">
                <div className="table-data">
                    <I18n id="transactions.list.noMoreTransactions" />
                </div>
            </div>
        );
        const list = items.map((line) => (
            <TransactionLinesListItem key={line.creditAccount} {...line} isDesktop={false} />
        ));

        return (
            <Tabs
                className="container-white flex-grow"
                style={{ flexDirection: "column", marginBottom: 0, marginTop: "0" }}>
                <TabList>
                    <Tab>
                        <I18n id="transaction.process.details.tab.payments" />
                    </Tab>
                    <Tab>
                        <I18n id="transaction.process.details.tab.information" />
                    </Tab>
                </TabList>

                <TabPanel>
                    <Measure bounds>
                        {({ measureRef, contentRect }) => (
                            <div
                                ref={measureRef}
                                style={{
                                    flexGrow: 1,
                                    overflow: "auto",
                                    transition: "transform .3s ease",
                                    transform: `translate3d(0, ${top}px, 0)`,
                                }}>
                                <Container>
                                    <Container.Column>
                                        {contentRect.bounds.height && (
                                            <Scroll
                                                containerBounds={contentRect.bounds}
                                                setTop={this.setTop}
                                                endOfListItem={endOfListItem}
                                                fetchMoreData={this.handleLoadMoreClick}
                                                lastPage={isLastPage}
                                                isInfiniteScroll
                                                items={list}
                                                removeListenersWhenPulled
                                                fetching={fetching}
                                                latestPageNumber={latestPageNumber}
                                            />
                                        )}
                                    </Container.Column>
                                </Container>
                            </div>
                        )}
                    </Measure>
                </TabPanel>
                <TabPanel>
                    <Container>
                        <Container.Column>
                            <div className="listItem">{this.renderDetailsColumns()}</div>
                        </Container.Column>
                    </Container>
                </TabPanel>
            </Tabs>
        );
    };

    renderDesktop = (items) => {
        const { detailPreview, isReadOnly, onClose, lastPages, latestPageNumber } = this.props;
        const list = items?.map((item) => this.renderItem(item));
        return (
            <>
                <Table className="container-white">
                    <Table.Header>
                        <Table.HeaderData align="left" className="data-cbu">
                            <I18n id="transaction.process.details.table.header.cbu" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center" className="data-cta">
                            <I18n id="transaction.process.details.table.header.account" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center" className="data-cuit">
                            <I18n id="transaction.process.details.table.header.cuit" />
                        </Table.HeaderData>
                        <Table.HeaderData align="right" className="data-cell-amount">
                            <I18n id="transaction.process.details.table.header.amount" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center" className="data-vaucher">
                            <I18n id="transaction.process.details.table.header.voucher" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center" className="data-reason">
                            <I18n id="transaction.process.details.table.header.motivo" />
                        </Table.HeaderData>
                        <Table.HeaderData align="center" className="data-ref">
                            <I18n id="transaction.process.details.table.header.referenc" />
                        </Table.HeaderData>
                        {!detailPreview && (
                            <Table.HeaderData align="center" className="data-state">
                                <I18n id="transaction.process.details.table.header.state" />
                            </Table.HeaderData>
                        )}

                        {!isReadOnly && (
                            <Table.HeaderData>
                                <Button
                                    bsStyle="link"
                                    image="images/cross-mobile-blue.svg"
                                    className="toolbar-btn view-close mh-auto mt-2"
                                    onClick={() => onClose()}
                                />
                            </Table.HeaderData>
                        )}
                    </Table.Header>
                    <Table.Body>{list}</Table.Body>
                </Table>
                <div className="d-flex w-100 justify-content-end pt-3 pb-2 px-2">
                    <Pagination
                        totalPages={lastPages}
                        pageNumber={latestPageNumber}
                        action={this.handleLoadMoreClick}
                    />
                </div>
            </>
        );
    };

    render() {
        const { filter, lines, isDesktop, latestPageNumber } = this.props;
        let { isLastPage } = this.props;
        const filteredLines = lines ? lines.filter((line) => !filter || filter(line)) : [];
        isLastPage =
            isLastPage === undefined
                ? latestPageNumber * configUtils.get("transactions.rowsPerPage", 10) >= filteredLines.length
                : isLastPage;
        const items = lines?.filter((line) => line.errorCode === null || line.errorCode !== "EMPTY_LINE");

        if (!isDesktop) {
            return this.renderMobile(items, isLastPage);
        }

        return this.renderDesktop(items, isLastPage, latestPageNumber, filteredLines);
    }
}

const mapStateToProps = (state) => ({
    latestPageNumber: transactionLinesSelectors.getPageNumber(state),
    fetching: transactionLinesSelectors.isFetching(state),
    transaction: formSelectors.getTransaction(state),
    processedFileData: fileSelectors.getProcessedFileData(state),
    lastPages: transactionLinesSelectors.getTotalPage(state),
    activeFilters: transactionLinesSelectors.getActiveFilters(state),
    lines: transactionLinesSelectors.getTransactionLines(state),
});

export default compose(withRouter, resizableRoute, connect(mapStateToProps))(TransactionLinesList);
