import * as API from "middleware/api";

export const listAccounts = (userId) => API.executeWithAccessToken("/check.accounts.list", { userId });

export const checkbookChecksAmount = (checkType) =>
    API.executeWithAccessToken("/checkbook.checks.amounts", { checkSynthetic: checkType });

export const requestChecks = (checksRequestData, otp, creationDate, userId) => {
    const {
        account,
        checksTypes,
        checkBookChecksAmount,
        checkBooksAmount,
        documentType,
        document,
        name,
        surname,
        idTransaction,
    } = checksRequestData;
    return API.executeWithAccessToken("/account.checks", {
        account: account.idProduct,
        cbu: account.cbu,
        checksTypes,
        checkBookChecksAmount,
        checkBooksAmount,
        documentType,
        document,
        name,
        surname,
        creationDate,
        userId,
        idTransaction,
        _otp: otp,
    });
};

export const accountChecksPdf = (
    account,
    checksType,
    checkTypeValue,
    checkBookChecksAmount,
    checkBooksAmount,
    documentType,
    document,
    name,
    surname,
    idComprobante,
    creationDate,
) =>
    API.executeWithAccessToken("/account.check.pdf", {
        account,
        checksType,
        checkTypeValue,
        checkBookChecksAmount,
        checkBooksAmount,
        documentType,
        document,
        name,
        surname,
        idComprobante,
        creationDate,
    });

export const filterEcheqsList = (filter) => API.executeWithAccessToken("/list.echeqs", { ...filter, extraInfo: false });

export const downloadEcheqsList = (filter) =>
    API.executeWithAccessToken("/download.echeqs.list", { ...filter, reportInfo: true });

export const executeEcheqAction = (echeqAction, userId) =>
    API.executeWithAccessToken("/actions.echeqs", { ...echeqAction, userId });
export const bankedBeneficiary = (documentObject) => {
    const { document, type } = documentObject;
    return API.executeWithAccessToken("/banked.beneficiary", { document, type });
};
export const sendEndorseEcheq = (otp, endorseDataObject, echeqAction, userId) => {
    const { endorseData, beneficiary } = endorseDataObject;
    return API.executeWithAccessToken("/endorse.echeq.activity", {
        _otp: otp,
        ...endorseData,
        ...echeqAction,
        beneficiary: beneficiary.document,
        beneficiaryName: beneficiary.reference,
        userId,
    });
};
export const sendEmitEcheq = (otp, emitData, userId) => {
    const { beneficiary } = emitData;
    return API.executeWithAccessToken("/emit.echeq.pre.activity", {
        _otp: otp,
        ...emitData,
        beneficiary: beneficiary.document,
        beneficiaryName: beneficiary.reference,
        _accountNumberBeneficiary: beneficiary.accountNumberBeneficiary,
        cbuBeneficiary: beneficiary.cbuBeneficiary,
        userId,
    });
};
export const sendCesionEcheq = (otp, cesionData, echeqAction, userId) => {
    const { beneficiary } = cesionData;
    return API.executeWithAccessToken("/cesion.echeq.activity", {
        _otp: otp,
        ...cesionData,
        ...echeqAction,
        beneficiary: beneficiary.document,
        beneficiaryName: beneficiary.reference,
        userId,
    });
};

export const sendNegotiateEcheq = (otp, endorseDataObject, echeqAction, userId) => {
    const { endorseData, beneficiary } = endorseDataObject;
    return API.executeWithAccessToken("/negotiate.echeq.pre.activity", {
        _otp: otp,
        ...endorseData,
        ...echeqAction,
        beneficiary: beneficiary.document,
        beneficiaryName: beneficiary.reference,
        userId,
    });
};
export const sendMultipleNegotiateEcheq = (otp, endorseDataObject, echeqAction, userId) => {
    const { endorseData, beneficiary } = endorseDataObject;
    return API.executeWithAccessToken("/negotiate.multiple.echeq.activity", {
        _otp: otp,
        ...endorseData,
        ...echeqAction,
        beneficiary: beneficiary.document,
        beneficiaryName: beneficiary.reference,
        userId,
    });
};

export const sendMultipleEndorseEcheq = (otp, endorseDataObject, echeqAction, userId) => {
    const { endorseData, beneficiary } = endorseDataObject;
    return API.executeWithAccessToken("/endorse.multiple.echeq.activity", {
        _otp: otp,
        ...endorseData,
        ...echeqAction,
        beneficiary: beneficiary.document,
        beneficiaryName: beneficiary.reference,
        userId,
    });
};

export const acceptRequestReturnEcheq = (otp, echeqAction, userId) =>
    API.executeWithAccessToken("/accept.return.echeqs", { _otp: otp, ...echeqAction, userId });

export const sendMultipleCesionEcheq = (otp, cesionData, echeqAction, userId) => {
    const { beneficiary } = cesionData;
    return API.executeWithAccessToken("/cesion.multiple.echeq.activity", {
        _otp: otp,
        ...cesionData,
        ...echeqAction,
        beneficiary: beneficiary.document,
        beneficiaryName: beneficiary.reference,
        userId,
    });
};

export const echeqDetailRequestMiddleware = (echeqId) => API.executeWithAccessToken("/detail.echeq", { id: echeqId });

export const listCheckDetailMiddleware = (
    checksTypeChamber,
    pageNumber,
    checkNumber,
    dateFrom,
    dateTo,
    amountFrom,
    amountTo,
) =>
    API.executeWithAccessToken("/list.checksDetail", {
        checksTypeChamber,
        pageNumber,
        checkNumber,
        dateFrom,
        dateTo,
        amountFrom,
        amountTo,
    });

export const downloadCheckDetailMiddleware = (
    format,
    checksTypeChamber,
    pageNumber,
    checkNumber,
    dateFrom,
    dateTo,
    amountFrom,
    amountTo,
    registersByPage,
) =>
    API.executeWithAccessToken("/export.list.checksDetail", {
        format,
        checksTypeChamber,
        pageNumber,
        checkNumber,
        dateFrom,
        dateTo,
        amountFrom,
        amountTo,
        registersByPage,
    });

export const listDiscountedChecksDetailMiddleware = (
    pageNumber,
    checkNumber,
    liquidationNumber,
    checkStatus,
    dateFrom,
    dateTo,
) =>
    API.executeWithAccessToken("/list.chamber.discounted.checksDetail", {
        pageNumber,
        checkNumber,
        liquidationNumber,
        checkStatus,
        dateFrom,
        dateTo,
    });

export const downloadDiscountedChecksDetailMiddleware = (
    format,
    pageNumber,
    checkNumber,
    liquidationNumber,
    checkStatus,
    dateFrom,
    dateTo,
    registersByPage,
) =>
    API.executeWithAccessToken("/export.list.discountedChecksDetail", {
        format,
        pageNumber,
        checkNumber,
        liquidationNumber,
        checkStatus,
        dateFrom,
        dateTo,
        registersByPage,
    });

export const downloadNegotiateEcheqTicketMiddleware = (
    creationDate,
    idTransaction,
    beneficiary,
    endorseType,
    accountLabel,
    reference,
    referencevalue,
    echeqNumber,
    idEcheq,
    emittedDate,
    paymentDate,
    totalAmount,
    echeqType,
    echeqCharacter,
    echeqMode,
    echeqMotive,
    echeqConcept,
) =>
    API.executeWithAccessToken("/export.exportNegotiateEcheqTicket", {
        creationDate,
        _idTransaction: idTransaction,
        beneficiary,
        endorseType,
        accountLabel,
        reference,
        referencevalue,
        echeqNumber,
        idEcheq,
        emittedDate,
        paymentDate,
        totalAmount,
        echeqType,
        echeqCharacter,
        echeqMode,
        echeqMotive,
        echeqConcept,
    });

export const downloadChecksRequestTicketMiddleware = (
    dateTime,
    receipt,
    accountName,
    checksTypes,
    checkBookChecksAmount,
    checkBooksAmount,
    authorizedDocument,
    authorizedName,
    authorizedSurname,
    deliveryPoint,
    responseIBS,
) =>
    API.executeWithAccessToken("/export.exportChecksRequestTicket", {
        dateTime,
        receipt,
        accountName,
        checksTypes,
        checkBookChecksAmount,
        checkBooksAmount,
        authorizedDocument,
        authorizedName,
        authorizedSurname,
        deliveryPoint,
        responseIBS,
    });

export const dowloadEcheqRequestDetailMobileMiddleware = (format, echeqDetail) =>
    API.executeWithAccessToken("/export.echeq.detail.mobile", {
        format,
        echeqDetail,
    });

export const downloadDiscountChecksMiddleware = (format, operationNumber) =>
    API.executeWithAccessToken("/export.discount.checks", {
        format,
        operationNumber,
    });
export const dowloadCheckRequestDetailMobileMiddleware = (format, checkDetail, isEmittedSelected) =>
    API.executeWithAccessToken("/export.check.detail.mobile", {
        format,
        checkDetail,
        isEmittedSelected,
    });

export const emitMassiveEcheqPre = (documentType, document) =>
    API.executeWithAccessToken("/emit.massive.echeqs", { documentType, document });

export const emitMassiveEcheqRequest = (emitMassiveEcheqData) =>
    API.executeWithAccessToken("/emit.massive.echeqs.preview", { ...emitMassiveEcheqData });

export const emitMassiveEcheq = (otp, { higherAmount, ...summary }) =>
    API.executeWithAccessToken("/emit.massive.echeqs.send", {
        _otp: otp,
        amount: {
            currency: higherAmount?.currency,
            quantity: higherAmount?.quantity,
        },
        idAccount: summary?.account?.idProduct,
        ...summary,
    });

export const echeqBatchDetailRequest = (batchId, statusFilter, pageNumber) =>
    API.executeWithAccessToken("/detail.batch.emit.massive", { batchId, status: statusFilter, pageNumber });

export const downloadBatchDetailRequest = (batchId, statusFilter) =>
    API.executeWithAccessToken("/detail.batch.download", { batchId, status: statusFilter });

export const downloadEcheqListByCuit = (fromDate, toDate) =>
    API.executeWithAccessToken("/download.echeq.list.cuit", { fromDate, toDate });
