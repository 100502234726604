import React, { Component } from "react";
import { bool, func } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Col } from "react-bootstrap";
import { Field, Form, withFormik } from "formik";
import classNames from "classnames";
import Yup from "yup";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Selector from "pages/_components/fields/formik/Selector";
import Button from "pages/_components/Button";

import { actions as posicionCosolidadaActions, selectors } from "reducers/posicionConsolidada";
import { actions as notificationActions } from "reducers/notification";

import * as i18n from "util/i18n";

const FORM_ID = "desktop.posicionConsolidada";
const today = new Date();
const thisYear = today.getFullYear();
const startYear = 2020;

class ConsolidatedPosition extends Component {
    yearOpt = [];

    monthOpt = [];

    static propTypes = {
        dispatch: func,
        isDesktop: bool,
        fetchingDownload: bool,
    };

    static defaultProps = {
        dispatch: null,
        fetchingDownload: null,
        isDesktop: null,
    };

    changeMonths = (selectedYear) => {
        const { setFieldValue, values } = this.props;
        const { month } = values;

        const currDate = new Date();
        const months = selectedYear === thisYear ? currDate.getMonth() + 1 : 12;
        for (let i = 1; i <= months; i++) {
            const monthRow = {
                value: i,
                label: i18n.get("desktop.posicionConsolidada.month.".concat(i.toString())),
                disabled: selectedYear === thisYear && i === currDate.getMonth() + 1,
            };
            this.monthOpt.push(monthRow);
        }
        if (selectedYear === thisYear && month - 1 >= currDate.getMonth()) {
            setFieldValue("month", this.monthOpt.length - 1);
        }
        if (this.monthOpt.every((month) => month.disabled)) {
            setFieldValue("month", "");
        }
    };

    componentDidMount = () => {
        const currDate = new Date();
        for (let i = thisYear; i >= startYear; i--) {
            const yearRow = {
                value: i,
                label: i.toString(),
                disabled: thisYear === i && currDate.getMonth() === 0
            };
            this.yearOpt.push(yearRow);
        }

        this.changeMonths(thisYear);
    };

    updateButton = (option) => {
        const { values, setFieldValue } = this.props;
        const { buttonsFilter } = values;
        const changedFilters = buttonsFilter.map((opt) =>
            opt.value === option.value
                ? {
                      ...opt,
                      on: !opt.on,
                  }
                : opt,
        );
        setFieldValue("buttonsFilter", changedFilters);
    };

    filterButtons = () => {
        const { isDesktop, values } = this.props;
        const { buttonsFilter } = values;

        const buttons = buttonsFilter.map((option) => {
            const button = (
                <Button
                    className={classNames("btn-pills", { on: option.on })}
                    block={false}
                    image={option.on ? "images/check.svg" : ""}
                    onClick={() => this.updateButton(option)}
                    label={option.label}
                />
            );
            return button;
        });

        return (
            <div className={classNames({ "d-flex flex-wrap justify-content-center gap-2": isDesktop })}>{buttons}</div>
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    handleYear = (year) => {
        this.monthOpt = [];
        this.changeMonths(year);
    };

    centerContentMobile = () => (
        <I18n id="desktop.posicionConsolidada.title" component="h1" componentProps={{ className: "m-0" }} />
    );

    render() {
        const { fetchingDownload, isDesktop } = this.props;

        return (
            <>
                <Notification scopeToShow="posicionConsolidada" />
                <div className={classNames({ "admin-detail-head": isDesktop })}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && (
                        <div className="view-title d-flex mt-3 mb-4">
                            <h1>{i18n.get("desktop.posicionConsolidada.title")}</h1>
                        </div>
                    )}
                    <Form>
                        <Container className="flex-grow container-white">
                            <Col className="col mt-2 mb-2" sm={6} md={5} lg={3}>
                                <Field
                                    component={Selector}
                                    formGroupClassName="mb-0"
                                    idForm={FORM_ID}
                                    options={this.yearOpt}
                                    onCustomChange={this.handleYear}
                                    name="year"
                                    controlLabelClassName="input__required"
                                    errorClassName="p-absolute"
                                />
                            </Col>
                            <Col className="col mt-2 mb-2" sm={6} md={5} lg={3}>
                                <Field
                                    component={Selector}
                                    formGroupClassName="mb-0"
                                    idForm={FORM_ID}
                                    options={this.monthOpt}
                                    name="month"
                                    controlLabelClassName="input__required"
                                    errorClassName="p-absolute"
                                />
                            </Col>
                        </Container>
                        <Container
                            className={`container--layaut flex-grow container-white ${
                                isDesktop ? "pt-4 pb-5" : "py-2 mt-2 mb-4"
                            }`}>
                            <Col sm={12} className="max-width-1000">
                                {this.filterButtons()}
                            </Col>
                        </Container>
                        <Container className="container--layaut flex-grow">
                            <Col sm={12} md={6} lg={4}>
                                <Button
                                    bsStyle="primary"
                                    label="desktop.posicionConsolidada.generar.label"
                                    loading={fetchingDownload}
                                    type="submit"
                                    className={isDesktop ? "mt-5" : "mt-2"}
                                />
                            </Col>
                        </Container>
                    </Form>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetchingDownload: selectors.isFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({
            year: "",
            month: "",
            buttonsFilter: [
                {
                    value: 1,
                    label: FORM_ID.concat(".filter.cuentas.label"),
                    on: true,
                },
                {
                    value: 2,
                    label: FORM_ID.concat(".filter.acuerdos.label"),
                    on: true,
                },
                {
                    value: 3,
                    label: FORM_ID.concat(".filter.financiamiento.label"),
                    on: true,
                },
                {
                    value: 4,
                    label: FORM_ID.concat(".filter.plazoFijo.label"),
                    on: true,
                },
                {
                    value: 5,
                    label: FORM_ID.concat(".filter.metrocorp.label"),
                    on: true,
                },
                {
                    value: 6,
                    label: FORM_ID.concat(".filter.fundcorp.label"),
                    on: true,
                },
            ],
        }),
        validationSchema: () =>
            Yup.object().shape({
                year: Yup.string().required(i18n.get(`${FORM_ID}.label.year.error`)),
                month: Yup.string().required(i18n.get(`${FORM_ID}.label.month.error`)),
            }),
        handleSubmit: (values, formikBag) => {
            const { dispatch } = formikBag.props;
            const { year, month, buttonsFilter } = values;
            const activeButtons = buttonsFilter.filter((button) => button.on);

            if (activeButtons.length > 0) {
                dispatch(posicionCosolidadaActions.downloadPosicionConsolidada(year, month, buttonsFilter));
            } else {
                dispatch(
                    notificationActions.showNotification(i18n.get(`${FORM_ID}.option.error`), "error", [
                        "posicionConsolidada",
                    ]),
                );
            }
        },
    }),
)(ConsolidatedPosition);
