import React, { useEffect, useState } from "react";
import { arrayOf, bool, number, shape } from "prop-types";
import Heading from "pages/_components/Heading";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import * as i18nUtils from "util/i18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as accountUtils from "util/accounts";

import FormattedDate from "pages/_components/FormattedDate";
import classNames from "classnames";

export default function BatchDetailHeader({ echeqBatch, accounts, transactionOrder, batchErrors, isDesktop }) {
    BatchDetailHeader.propTypes = {
        batchErrors: shape({}).isRequired,
        echeqBatch: shape({}).isRequired,
        accounts: arrayOf(shape({})).isRequired,
        transactionOrder: number.isRequired,
        isDesktop: bool.isRequired,
    };
    const [accountName, setAccountName] = useState("-");
    const { reference, cbuOrigin, processDate, totalAmount } = echeqBatch;
    const { successes, amountSuccess, errors, amountError } = batchErrors;
    const containerClassName = `data-wrapper-flex mb-0 ${isDesktop ? "" : "space-between"}`;
    const labelClassName = `mb-0 data-wrapper-inline mr-2 ${isDesktop ? "" : "text-12"}`;
    const dataClassName = `mb-0 data-wrapper-inline mr-2 font-light f-size-4 ${isDesktop ? "" : "data-desc text-12"}`;

    useEffect(() => {
        if (accounts.length > 0) {
            const loggedUserAccount = accounts.find((account) => cbuOrigin === account?.cbu);
            setAccountName(accountUtils.getAccountDescription(loggedUserAccount));
        }
    }, [accounts, cbuOrigin]);

    const getHeaderDetail = () => (
        <div className={classNames("container-white", { "my-3 py-4 px-3": isDesktop }, { "mb-3 py-2": !isDesktop })}>
            <Container className={classNames("flex-grow container-white data-wrapper-inline", { "mb-2": isDesktop })}>
                <Col className="col col-12 pr-1" md={4} xl={4} lg={4} sm={12}>
                    <Heading.DataGroup
                        labelClassName={labelClassName}
                        containerClassName={containerClassName}
                        dataClassName={dataClassName}
                        label="echeq.batch.detail.header.accountEmit"
                        data={accountName}
                    />
                </Col>
                <Col className="col col-12 pr-1" md={4} xl={4} lg={4} sm={12}>
                    <Heading.DataGroup
                        labelClassName={labelClassName}
                        containerClassName={containerClassName}
                        dataClassName={dataClassName}
                        label="echeq.batch.detail.header.processDate"
                        data={<FormattedDate date={processDate} dateFormat="dd/MM/yyyy" />}
                    />
                </Col>
                <Col className="col col-12 pr-1" md={4} xl={4} lg={4} sm={12}>
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        dataClassName={dataClassName}
                        label="echeq.batch.detail.header.totalAmount"
                        data={
                            <FormattedAmount
                                currency={i18nUtils.get("currency.label.ARS")}
                                quantity={totalAmount}
                                className="data-desc"
                                small={!isDesktop}
                                notBold
                            />
                        }
                    />
                </Col>
            </Container>
            <Container className=" flex-grow container-white data-wrapper-inline">
                <Col className="col col-12 pr-1" md={4} xl={4} lg={4} sm={12}>
                    <Heading.DataGroup
                        labelClassName={labelClassName}
                        containerClassName={containerClassName}
                        dataClassName={dataClassName}
                        label="echeq.batch.detail.header.reference"
                        data={reference}
                    />
                </Col>
                <Col className="col col-12 pr-1" md={4} xl={4} lg={4} sm={12}>
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        dataClassName={dataClassName}
                        label="echeq.batch.detail.header.echeqQuantity"
                        data={transactionOrder}
                    />
                </Col>
                {isDesktop && (
                    <Col className="col col-12 pr-1" md={4} xl={4} lg={4} sm={12}>
                        <></>
                    </Col>
                )}
            </Container>
        </div>
    );

    const getHeaderDetailErrors = () => (
        <div className={classNames("container-white", { "my-3 py-4 px-3": isDesktop }, { "mb-3 py-2": !isDesktop })}>
            <Container className="flex-grow container-white data-wrapper-inline">
                <Col className="col col-12 pr-1" md={3} xl={3} lg={3} sm={12}>
                    <Heading.DataGroup
                        labelClassName={labelClassName}
                        containerClassName={containerClassName}
                        dataClassName={dataClassName}
                        label="echeq.batch.detail.header.errors.successedEmit"
                        data={successes}
                    />
                </Col>
                <Col className="col col-12 pr-1" md={9} xl={9} lg={9} sm={12}>
                    <Heading.DataGroup
                        labelClassName={labelClassName}
                        containerClassName={containerClassName}
                        dataClassName={dataClassName}
                        label="echeq.amount"
                        data={
                            <FormattedAmount
                                currency={i18nUtils.get("currency.label.ARS")}
                                quantity={amountSuccess}
                                className="data-desc"
                                small={!isDesktop}
                                notBold
                            />
                        }
                    />
                </Col>
            </Container>
            <Container className="flex-grow container-white data-wrapper-inline">
                <Col className="col col-12 pr-1" md={3} xl={3} lg={3} sm={12}>
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        dataClassName={dataClassName}
                        label="echeq.batch.detail.header.errors.failedEmit"
                        data={errors}
                    />
                </Col>
                <Col className="col col-12 pr-1" md={9} xl={9} lg={9} sm={12}>
                    <Heading.DataGroup
                        containerClassName={containerClassName}
                        labelClassName={labelClassName}
                        dataClassName={dataClassName}
                        label="echeq.amount"
                        data={
                            <FormattedAmount
                                currency={i18nUtils.get("currency.label.ARS")}
                                quantity={amountError}
                                className="data-desc"
                                small={!isDesktop}
                                notBold
                            />
                        }
                    />
                </Col>
            </Container>
        </div>
    );

    return (
        <>
            {getHeaderDetail()}
            {errors > 0 && getHeaderDetailErrors()}
        </>
    );
}
