import { combineReducers } from "redux";

import createPermissionsReducerModule from "reducers/administration/common/permissions";
import createPermissionsTicketReducerModule from "reducers/administration/common/permissionsTicket";

const {
    types: permissionsTypes,
    actions: permissionsActions,
    reducer: permissionsReducer,
} = createPermissionsReducerModule("administrationSimple");
const {
    types: permissionsTicketTypes,
    actions: permissionsTicketActions,
    reducer: permissionsTicketReducer,
} = createPermissionsTicketReducerModule("administrationSimple");

export { permissionsTypes, permissionsActions, permissionsTicketTypes, permissionsTicketActions };

export default combineReducers({ permissions: permissionsReducer, permissionsTicket: permissionsTicketReducer });
