export const INITIAL_STATE = {
    showFilters: true,
    payments: [],
};

const ACTIONS = {
    setPayments: "SET_PAYMENTS",
    setShowFilters: "SET_SHOW_FILTERS",
    setDate: "SET_DATE",
};

const areAllPaymentsMade = (newPayments) => newPayments.every(({ type }) => type === "PA");

export const AFIPListReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.setPayments: {
            const { newPayments } = action.payload;
            const areAllPaymentSelected = areAllPaymentsMade(newPayments)
                ? false
                : newPayments
                      .filter(
                          ({ isPending, actions: { isPaymentAllowed } }) =>
                              isPending === false && isPaymentAllowed === true,
                      )
                      .every(({ isChecked }) => isChecked) || false;

            return {
                ...state,
                payments: newPayments,
                areAllPaymentSelected,
            };
        }
        case ACTIONS.setShowFilters: {
            return {
                ...state,
                showFilters: !state.showFilters,
            };
        }
        case ACTIONS.setDate: {
            const { date } = action.payload;

            return {
                ...state,
                selectedDate: date,
            };
        }
        default:
            return state;
    }
};

export const setPayments = (newPayments) => ({ type: ACTIONS.setPayments, payload: { newPayments } });
export const setDate = (date) => ({ type: ACTIONS.setDate, payload: { date } });
export const setShowFilters = () => ({ type: ACTIONS.setShowFilters });
