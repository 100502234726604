import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { func, string, bool, shape } from "prop-types";
import Yup from "yup";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import PasswordRules from "pages/_components/PasswordRules";
import Credential from "pages/_components/fields/credentials/Credential";
import withExchangeToken from "pages/_components/withExchangeToken";
import OnboardingStepper, { orientations } from "pages/onboarding/_components/OnboardingStepper";
import { Mixpanel } from "util/clickstreaming";
import Image from "pages/_components/Image";
import Notification from "pages/_components/Notification";

import EnrollmentStepper from "./_components/EnrollmentStepper";
import AssociateStepperSimple from "./_components/AssociateStepperSimple";

const FORM_ID = "enrollment.step3";

class Step3Part2 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        exchangeToken: string.isRequired,
        invitationCode: string.isRequired,
        isMobile: bool.isRequired,
        isSubmitting: bool.isRequired,
        documentData: shape({
            firstName: string.isRequired,
        }).isRequired,
        match: shape.isRequired,
    };

    componentDidMount() {
        const { dispatch, invitationCode, exchangeToken, match } = this.props;
        const isCredentialWhitening = match.path === "/credentialWhitening/step3part2";

        if (!invitationCode) {
            dispatch(enrollmentActions.goToStep0());
        }
        if (!isCredentialWhitening) {
            dispatch(enrollmentActions.requestSecuritySeals(exchangeToken));
        }
    }

    handleHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    renderTitleMobile = () => (
        <Image src="images/logoMobileHeader.svg" styles={{ position: "relative", top: "5px" }} className="" />
    );

    render() {
        const { isSubmitting, isMobile, documentData, match } = this.props;
        const maxLength = configUtils.getInteger("core.password.maxLength");
        const minLength = configUtils.getInteger("core.password.minLength");
        const step = 9;
        const isCredentialWhitening = match.path === "/credentialWhitening/step3part2";
        return (
            <>
                <Notification scopeToShow="passwordValidity" />
                <Head
                    onClose={isMobile && this.handleHeaderBack}
                    onBack={isMobile && this.handleHeaderBack}
                    headerClassName={isMobile ? "blue-main-header-mobile" : ""}
                    centerElement={!isMobile ? undefined : this.renderTitleMobile}
                    closeImageWhite={isMobile && true}
                />
                <div className="view-page">
                    {!isMobile && documentData.firstName && (
                        <OnboardingStepper currentStep={step} className="onboarding-steps" />
                    )}
                    {!(isMobile || documentData.firstName) && (
                        <EnrollmentStepper
                            currentStep={4}
                            credentialWhitening={isCredentialWhitening}
                            className="onboarding-steps"
                        />
                    )}
                    <div className="view-content">
                        <main className="main-container">
                            <Form className="above-the-fold">
                                {isMobile && (
                                    <Container className="container--layout align-items-center">
                                        <Col className="col">
                                            {documentData.firstName && (
                                                <OnboardingStepper
                                                    currentStep={step}
                                                    orientation={orientations.horizontal}
                                                />
                                            )}
                                            {!documentData.firstName && <AssociateStepperSimple currentStep={4} />}
                                        </Col>
                                    </Container>
                                )}
                                <Container
                                    className="container--layout align-items-center"
                                    gridClassName="form-content">
                                    <Col sm={12} md={5} lg={4} className="col col-12">
                                        <Col sm={12} md={12} lg={12} className="col col-12 enrollment-create-pass-text">
                                            <I18n
                                                component="h1"
                                                componentProps={{ className: "title-content" }}
                                                id="onboarding.step.10.title"
                                            />
                                            <I18n
                                                component="p"
                                                componentProps={{ className: "text-lead" }}
                                                id="onboarding.step.10.info"
                                            />
                                        </Col>
                                        <Col
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            className="col col-12  enrollment-create-pass-inputs">
                                            <Field
                                                component={Credential}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="password"
                                                maxLength={maxLength}
                                            />
                                            <Field
                                                component={Credential}
                                                hidePlaceholder
                                                idForm={FORM_ID}
                                                name="passwordConfirmation"
                                                maxLength={maxLength}
                                            />
                                            <div className="password-rules">
                                                <PasswordRules>
                                                    <I18n
                                                        id={`${FORM_ID}.passwordRules.rule1`}
                                                        MIN_LENGTH={minLength}
                                                        MAX_LENGTH={maxLength}
                                                    />
                                                    <I18n id={`${FORM_ID}.passwordRules.rule2`} />
                                                    <I18n id={`${FORM_ID}.passwordRules.rule3`} />
                                                    <I18n id={`${FORM_ID}.passwordRules.rule4`} />
                                                    <I18n id={`${FORM_ID}.passwordRules.rule5`} />
                                                </PasswordRules>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12} lg={12} className="col col-12">
                                            <Button
                                                label="global.continue"
                                                type="submit"
                                                bsStyle="primary"
                                                loading={isSubmitting}
                                            />
                                        </Col>
                                    </Col>
                                </Container>
                            </Form>
                        </main>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: enrollmentSelectors.getFetching(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    invitation: enrollmentSelectors.getInvitation(state),
    invitationCode: enrollmentSelectors.getInvitationCode(state),
    documentData: onboardingSelectors.getDocumentData(state),
    user: enrollmentSelectors.getUser(state),
    userCredentials: enrollmentSelectors.getUserCredentials(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({ password: "", passwordConfirmation: "" }),
        validationSchema: (props) => {
            const { user } = props;
            return Yup.lazy((values) => {
                if (values.passwordConfirmation === undefined || values.password === undefined) {
                    return Yup.object().shape({
                        password: Yup.string().required(i18nUtils.get("enrollment.step3.password.error.empty")),
                        passwordConfirmation: Yup.string().required(
                            i18nUtils.get("enrollment.step3.password.error.empty"),
                        ),
                    });
                }

                if (values.password === user || values.passwordConfirmation === user) {
                    return Yup.object().shape({
                        password: Yup.string().notOneOf(
                            [user],
                            i18nUtils.get("enrollment.step3.password.error.userMustNotMatch"),
                        ),
                        passwordConfirmation: Yup.string().notOneOf(
                            [user],
                            i18nUtils.get("enrollment.step3.password.error.userMustNotMatch"),
                        ),
                    });
                }

                return Yup.object().shape({
                    password: Yup.string()
                        .required(i18nUtils.get("enrollment.step3.password.error.empty"))
                        .min(
                            configUtils.getInteger("core.password.minLength", 8),
                            i18nUtils.get("enrollment.step3.password.error.MinLength"),
                        )
                        .max(
                            configUtils.getInteger("core.password.maxLength", 20),
                            i18nUtils.get("enrollment.step3.password.error.MaxLength"),
                        )
                        .matches(
                            new RegExp(configUtils.get("core.userconfiguration.updateuserpassword.pattern")),
                            i18nUtils.get(`settings.changePassword.modify.error.mustBeAlphanumeric`),
                        )
                        .oneOf(
                            [values.passwordConfirmation],
                            i18nUtils.get("enrollment.step3.passwordConfirmation.error.passwordMustMatch"),
                        ),
                    passwordConfirmation: Yup.string()
                        .required(i18nUtils.get("enrollment.step3.passwordConfirmation.error.empty"))
                        .min(
                            configUtils.getInteger("core.password.minLength", 8),
                            i18nUtils.get("enrollment.step3.password.error.MinLength"),
                        )
                        .max(
                            configUtils.getInteger("core.password.maxLength", 20),
                            i18nUtils.get("enrollment.step3.password.error.MaxLength"),
                        )
                        .oneOf(
                            [values.password],
                            i18nUtils.get("enrollment.step3.passwordConfirmation.error.passwordMustMatch"),
                        ),
                });
            });
        },
        handleSubmit: ({ password, passwordConfirmation }, formikBag) => {
            const { match } = formikBag.props;
            const isCredentialWhitening = match.path === "/credentialWhitening/step3part2";

            if (isCredentialWhitening) {
                formikBag.props.dispatch(enrollmentActions.setCredentials(password, passwordConfirmation, formikBag));
            } else {
                formikBag.props.dispatch(enrollmentActions.validatePassword(password, passwordConfirmation, formikBag));
            }

            Mixpanel.track(`${FORM_ID}.password`);
        },
    }),
)(withExchangeToken(Step3Part2));
