/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from "react";
import { Col, Navbar, Row, Grid } from "react-bootstrap";
import { Link } from "react-router-dom";
import { func, node, string, bool } from "prop-types";

import * as i18n from "util/i18n";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import { resizableRoute } from "pages/_components/Resizable";
import { Helmet } from "react-helmet";
import classNames from "classnames";

class ProductHead extends Component {
    static propTypes = {
        /**
         * back link url
         */
        backLinkTo: string,
        /**
         * back function, ignored if backLinkTo is specified
         */
        onBack: func,
        fetchingDownload: bool,
        onClickDownloadPDF: func,
        onClickDownloadXLS: func,
        onClickDownloadCSV: func,
        renderDownload: func,
        handleOptionsClick: func,
        hideDownload: bool,
        isDesktop: bool.isRequired,
        children: node.isRequired,
        title: string,
    };

    static defaultProps = {
        onClickDownloadPDF: null,
        onClickDownloadXLS: null,
        onClickDownloadCSV: null,
        renderDownload: null,
        handleOptionsClick: null,
        backLinkTo: "",
        hideDownload: null,
        fetchingDownload: false,
        onBack: null,
        title: "",
    };

    handleClick = () => {
        const { handleOptionsClick } = this.props;

        if (handleOptionsClick) {
            handleOptionsClick();
        }
        document.activeElement.blur();
    };

    renderMenuOption = () => {
        const { isDesktop, handleOptionsClick } = this.props;

        if (!isDesktop) {
            return (
                handleOptionsClick && (
                    <Button
                        className="toolbar-item toolbar-item--fixed toolbar-btn toolbar-btn-right view-options"
                        onClick={this.handleClick}
                        image="images/headerCommandsMobile.svg"
                        label="view more options"
                        block={false}
                        bsStyle="link"
                    />
                )
            );
        }
        return null;
    };

    renderDownloadDropdown = () => {
        const {
            fetchingDownload,
            onClickDownloadPDF,
            onClickDownloadXLS,
            onClickDownloadCSV,
            renderDownload,
            isDesktop,
        } = this.props;

        if (!isDesktop) {
            return null;
        }
        if (renderDownload) {
            return renderDownload(fetchingDownload);
        }
        return (
            <div className="toolbar-item">
                <Dropdown
                    image="images/download.svg"
                    label="global.download"
                    bsStyle="outline"
                    fetching={fetchingDownload}
                    pullRight>
                    <Button
                        onClick={onClickDownloadPDF}
                        label="accounts.pdfFile"
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                    <Button
                        onClick={onClickDownloadXLS}
                        label="accounts.xlsFile"
                        className="dropdown__item-btn"
                        bsStyle="link"
                    />
                    {onClickDownloadCSV && (
                        <Button
                            onClick={onClickDownloadCSV}
                            label="accounts.csvFile"
                            className="dropdown__item-btn"
                            bsStyle="link"
                        />
                    )}
                </Dropdown>
            </div>
        );
    };

    render() {
        const { backLinkTo, children, onBack, hideDownload, isDesktop, title } = this.props;
        const filteredChildren = children.filter((c) => !!c);
        const childrenListRender = filteredChildren.slice(1);

        return (
            <>
                <div className={isDesktop ? "admin-detail-head px-0" : ""}>
                    <header className="view-header theme-product-detail">
                        <Helmet>
                            <title>Banco CMF - {i18n.get(title)}</title>
                        </Helmet>

                        <Navbar
                            collapseOnSelect
                            fluid
                            className={classNames("account-navbar-override-padding", {
                                "blue-main-header-mobile blue-main-title-mobile": !isDesktop,
                            })}>
                            <Navbar.Header>
                                <div className="toolbar toolbar-product-name">
                                    <div
                                        className={classNames("navbar-header navbar-header-blue", {
                                            "px-2": !isDesktop,
                                        })}>
                                        {backLinkTo && (
                                            <div className="toolbar-item toolbar-item-left pl-0">
                                                <Link
                                                    className="pl-0 ml-0 btn btn-link toolbar-btn view-back"
                                                    to={backLinkTo}>
                                                    <Image
                                                        className="svg-icon svg-caret"
                                                        src={`images/arrowLeft${!isDesktop ? "Mobile" : ""}.svg`}
                                                    />
                                                    <I18n
                                                        id="global.back"
                                                        componentProps={{ className: "visually-hidden" }}
                                                    />
                                                </Link>
                                            </div>
                                        )}
                                        {onBack && (
                                            <div className="toolbar-item toolbar-item-left pl-0">
                                                <Button
                                                    className={classNames("btn btn-link toolbar-btn view-back pl-0 ", {
                                                        "my-0 ml-0": isDesktop,
                                                        "mr-0 ml-0": !isDesktop,
                                                    })}
                                                    onClick={onBack}
                                                    image={`images/arrowLeft${!isDesktop ? "Mobile" : ""}.svg`}
                                                    block={false}
                                                    label="global.back"
                                                    bsStyle="link"
                                                />
                                            </div>
                                        )}

                                        {filteredChildren[0]}
                                        {this.renderMenuOption()}
                                    </div>
                                </div>

                                {!hideDownload && this.renderDownloadDropdown()}
                            </Navbar.Header>
                        </Navbar>
                    </header>

                    <section
                        className={classNames(
                            "align-items-center section-content-heading",
                            { "mb-2": isDesktop },
                            { "mt-0": !isDesktop },
                        )}>
                        <Grid fluid className="pb-1">
                            <Row className="justify-content-center">
                                {childrenListRender.map((child) => (
                                    <Col
                                        className={classNames(
                                            "col col-12",

                                            { "px-0": !isDesktop },
                                        )}
                                        sm={12}>
                                        {child}
                                    </Col>
                                ))}
                            </Row>
                        </Grid>
                    </section>
                </div>
            </>
        );
    }
}

export default resizableRoute(ProductHead);
