import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import classNames from "classnames";
import { bool, func, shape } from "prop-types";

import { actions as recoveryPasswordActions, selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import { actions as loginActions } from "reducers/login";
import Step2Content from "pages/recoveryPassword/_components/Step2Content";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import withExchangeToken from "pages/_components/withExchangeToken";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Logo from "pages/login/_components/Logo";

class RecoveryPassStep2 extends Component {
    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    renderTitleMobile = () => <Logo className="svg-image logo logo-mobile-size" logoColor />;

    render() {
        const { isDesktop, propsGrid } = this.props;

        return (
            <GridLayoutExternal scopeToShow="recoveryPassword" isDesktop={isDesktop} {...propsGrid}>
                <Head
                    onClose={this.onHeaderClose}
                    title={isDesktop ? "recoveryPassword.step2.header" : null}
                    headerClassName={
                        !isDesktop
                            ? "login-background-color-white"
                            : "recover-pass-desktop recover-pass-desktop-token"
                    }
                    centerElement={!isDesktop && this.renderTitleMobile}
                    hideMobileMenu={!isDesktop && true}
                    fixed
                />
                <MainContainer className={classNames("login__layout main-container", { 
                    "container-fluid": isDesktop,
                })}>
                    {!isDesktop && (
                        <Container className="container--layout flex-grow" >
                            <Col className="col col-12 view-title mt-3">
                                <div className="justify-content-center">
                                    <I18n 
                                        id="recoveryPassword.step2.header"
                                        componentProps={{ className: "f-size-5 font-regular" }}
                                        component="h2"
                                    />
                                </div>
                            </Col>
                        </Container>
                    )}
                    <Step2Content {...this.props} />
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}

RecoveryPassStep2.propTypes = {
    dispatch: func.isRequired,
    propsGrid: shape({}).isRequired,
    isDesktop: bool,
};

RecoveryPassStep2.defaultProps = {
    isDesktop: true,
};

const mapStateToProps = (state) => ({
    exchangeToken: recoveryPasswordSelectors.getExchangeToken(state),
});

export default connect(mapStateToProps)(withExchangeToken(RecoveryPassStep2));
