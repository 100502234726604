import React, { Component, Fragment } from "react";
import { func, arrayOf, objectOf, shape, bool, string } from "prop-types";
import { connect } from "react-redux";

import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import { detailsSelectors } from "reducers/administration";
import InfoTag from "pages/_components/InfoTag";

class DispatcherConfirm extends Component {
    static propTypes = {
        user: shape({ firstName: string, lastName: string }).isRequired,
        routerActions: shape({
            goBack: func,
        }),
        idActivity: string,
        idTransaction: string,
        actions: objectOf(func).isRequired,
        formActions: objectOf(func).isRequired,
        hasDispatcherEnabled: bool,
        fetching: bool,
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        hasMassiveEnabled: bool,
        userEnvStatus: string,
    };

    static defaultProps = {
        routerActions: null,
        idActivity: null,
        idTransaction: null,
        fetching: false,
        hasDispatcherEnabled: false,
        hasMassiveEnabled: false,
        userEnvStatus: "",
    };

    componentDidMount() {
        const { user, routerActions } = this.props;

        if (!Object.keys(user).length) {
            routerActions.goBack();
        }
    }

    handleSubmit = (data, formikBag) => {
        const { actions, formActions, idTransaction, idActivity, user, hasDispatcherEnabled } = this.props;
        const { signatureLevel, ...credentials } = data;
        if (!idTransaction) {
            actions.updateDispatcherRequest(
                {
                    credentials,
                    userId: user.idUser,
                    userFullName: user.fullName,
                    userDocument: user.documentType.concat(" ", user.documentNumber),
                    dispatcher: !hasDispatcherEnabled,
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    render() {
        const { fetching, user, credentialGroups, hasDispatcherEnabled, hasMassiveEnabled, userEnvStatus } = this.props;
        const isAdministrator = !hasMassiveEnabled;

        return (
            <Fragment>
                <div className="admin-detail-head admin-detail-head-section px-0 mb-4">
                    <Head onBack={this.handleBack} />
                    <div className="view-title d-flex">
                        <h1>
                            {i18nUtils.get(`administration.users.dispatcher.configure`)} {user.fullName}
                        </h1>
                        <div className="ml-3 data-wrapper data-wrapper-flex">
                            {isAdministrator && (
                                <InfoTag
                                    type="default"
                                    message={i18nUtils.get(`administration.administrator`)}
                                    tagBackground="#707070"
                                />
                            )}
                            <InfoTag
                                type="default"
                                message={i18nUtils.get(`user.status.${userEnvStatus}`)}
                                tagBackground={userEnvStatus === "blocked" ? "#fc5f5f" : "#0be0a2"}
                            />
                        </div>
                    </div>
                </div>
                <MainContainer
                    viewContentClassName="confirmation__container pt-0"
                    showLoader={fetching && !Object.keys(user).length}>
                    <Container
                        className="align-items-center flex-grow px-0 container--layout with-margin mb-0"
                        gridClassName="confirmation__form confirmation__box">
                        <Container className="align-items-center flex-grow admin-action" gridClassName="form-content">
                            <div className="admin-content-center">
                                <I18n
                                    id={`administration.users.dispatcher.change.${hasDispatcherEnabled}`}
                                    component="h2"
                                    componentProps={{ className: "mt-4" }}
                                />
                            </div>
                            <div className="transfer-block">
                                <div className="inline-grid">
                                    <span className="chip f-size-4">{`${user.fullName}`}</span>
                                </div>
                            </div>
                        </Container>
                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            disclaimerMessageKey="dispatcher.disclaimer.message"
                            MDcolumnWidth={12}
                            LGcolumnWidth={12}
                            buttonLabel="global.confirm"
                            buttonImage="images/arrowRight.svg"
                            buttonImageClass="mr-2"
                            formContainerClassName="confirmation__container"
                        />
                    </Container>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    hasMassiveEnabled: detailsSelectors.hasMassiveEnabled(state),
    userEnvStatus: detailsSelectors.getUserEnvStatus(state),
});

export default connect(mapStateToProps)(DispatcherConfirm);
