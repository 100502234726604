import React from "react";
import moment from "moment";
import RepudiateEcheq from "pages/checks/Echeqs/EcheqActions/RepudiateEcheq";
import DepositEcheq from "pages/checks/Echeqs/EcheqActions/DepositEcheq";
import CustodyEcheq from "pages/checks/Echeqs/EcheqActions/CustodyEcheq";
import AnulateEcheq from "pages/checks/Echeqs/EcheqActions/AnulateEcheq";
import RequestReturnEcheq from "pages/checks/Echeqs/EcheqActions/RequestReturnEcheq";
import { push } from "react-router-redux";
import { actions } from "reducers/checks";
import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

const FORM_ID = "echeq";

export default function specialOptions(echeq, serverSearchDate, environmentCuit, functionAction) {
    let contextSpecialMenuActions = [];
    const echeqsActivoPendienteAutomationEnabled = configUtils.getBoolean(
        "echeq.config.automation.activo.pendiente.enabled",
    );
    const echeqsCesionPendienteAutomationEnabled = configUtils.getBoolean(
        "echeq.config.automation.cesion.pendiente.enabled",
    );
    const echeqsEmitidoPendienteAutomationEnabled = configUtils.getBoolean(
        "echeq.config.automation.emitido.pendiente.enabled",
    );
    const echeqsActivoPendienteAutomationDiscountEnabled = configUtils.getBoolean(
        "echeq.config.automationDiscount.activo.pendiente.enabled",
    );
    const echeqsCesionPendienteAutomationDiscountEnabled = configUtils.getBoolean(
        "echeq.config.automationDiscount.cesion.pendiente.enabled",
    );
    const echeqsEmitidoPendienteAutomationDiscountEnabled = configUtils.getBoolean(
        "echeq.config.automationDiscount.emitido.pendiente.enabled",
    );
    const echeqsCustodyAutomationDiscountEnabled = configUtils.getBoolean(
        "echeq.config.automationDiscount.custody.enabled",
    );

    switch (echeq.estado) {
        case "ACTIVO": {
            if (echeq.tenencia_beneficiario_documento === environmentCuit) {
                contextSpecialMenuActions.push({
                    label: `${FORM_ID}.negotiate.echeq`,
                    onClick: () => functionAction("NEGOTIATE", echeq),
                });

                if (echeq.cheque_caracter.toLowerCase() === "no a la orden") {
                    contextSpecialMenuActions.push({
                        label: `${FORM_ID}.emit.cesion.echeq`,
                        onClick: () => functionAction("EMIT-CESION", echeq),
                    });
                } else {
                    contextSpecialMenuActions.push({
                        label: `${FORM_ID}.endorse.echeq`,
                        onClick: () => functionAction("ENDORSE", echeq),
                    });
                }

                contextSpecialMenuActions = showDepositOrCustody(
                    echeq,
                    contextSpecialMenuActions,
                    functionAction,
                    serverSearchDate,
                );
            }
            if (
                echeq.emisor_cuit === environmentCuit ||
                (echeq.total_endosos > 0 && echeq.endosos[0].emisor_documento === environmentCuit)
            ) {
                contextSpecialMenuActions.push({
                    label: `${FORM_ID}.request.return.echeq`,
                    onClick: () => functionAction("REQUEST-RETURN", echeq),
                });
            }

            break;
        }
        case "ACTIVO-PENDIENTE": {
            if (
                echeq.total_endosos > 0 &&
                echeq.endosos[0].benef_documento === environmentCuit &&
                echeq.endosos[0].estado_endoso.toLowerCase() === "pendiente"
            ) {
                contextSpecialMenuActions = [
                    {
                        label: `${FORM_ID}.admit.echeq`,
                        onClick: () => functionAction("ADMIT", echeq),
                    },
                    {
                        label: `${FORM_ID}.repudiate.echeq`,
                        onClick: () => functionAction("REPUDIATE", echeq),
                    },
                ];
                if (echeqsActivoPendienteAutomationEnabled) {
                    contextSpecialMenuActions = showDepositOrCustody(
                        echeq,
                        contextSpecialMenuActions,
                        functionAction,
                        serverSearchDate,
                    );
                }
                if (echeqsActivoPendienteAutomationDiscountEnabled) {
                    contextSpecialMenuActions = [
                        ...contextSpecialMenuActions,
                        {
                            label: `${FORM_ID}.negotiate.echeq`,
                            onClick: () => functionAction("NEGOTIATE", echeq),
                        },
                    ];
                }
            } else if (
                echeq.total_endosos > 0 &&
                echeq.endosos[0].emisor_documento === environmentCuit &&
                echeq.endosos[0].estado_endoso.toLowerCase() === "pendiente"
            ) {
                contextSpecialMenuActions = [
                    {
                        label: `${FORM_ID}.anulate-endorse.echeq`,
                        onClick: () => functionAction("ANULATE-ENDORSE", echeq),
                    },
                ];
            }

            break;
        }
        case "CUSTODIA": {
            const searchDate = serverSearchDate ? moment(serverSearchDate) : moment();
            const fechaPago = moment(echeq.fecha_pago);
            const diffDays = moment.duration(searchDate.diff(fechaPago)).asDays();
            if (echeq.tenencia_beneficiario_documento === environmentCuit && diffDays < 0) {
                contextSpecialMenuActions = [
                    {
                        label: `${FORM_ID}.rescue.echeq`,
                        onClick: () => functionAction("RESCUE", echeq),
                    },
                ];
                if (echeqsCustodyAutomationDiscountEnabled) {
                    contextSpecialMenuActions = [
                        ...contextSpecialMenuActions,
                        {
                            label: `${FORM_ID}.negotiate.echeq`,
                            onClick: () => functionAction("NEGOTIATE", echeq),
                        },
                    ];
                }
            }

            break;
        }
        case "RECHAZADO": {
            if (
                echeq.cesion_pendiente &&
                echeq.total_cesiones > 0 &&
                echeq.cesiones[0].cesionario_documento === environmentCuit
            ) {
                contextSpecialMenuActions = [
                    {
                        label: `${FORM_ID}.admit.echeq`,
                        onClick: () => functionAction("ADMIT-CESION", echeq),
                    },
                    {
                        label: `${FORM_ID}.repudiate.echeq`,
                        onClick: () => functionAction("REPUDIATE-CESION", echeq),
                    },
                ];
            } else if (
                echeq.cesion_pendiente &&
                echeq.total_cesiones > 0 &&
                echeq.cesiones[0].cedente_documento === environmentCuit
            ) {
                contextSpecialMenuActions = [
                    {
                        label: `${FORM_ID}.anulate.cesion.echeq`,
                        onClick: () => functionAction("ANULATE-CESION", echeq),
                    },
                ];
            }

            if (
                echeq.total_cesiones > 0 &&
                !echeq.certificado_emitido &&
                !echeq.re_presentar &&
                echeq.tenencia_beneficiario_documento === environmentCuit
            ) {
                contextSpecialMenuActions.push({
                    label: `${FORM_ID}.emit.cesion.echeq`,
                    onClick: () => functionAction("EMIT-CESION", echeq),
                });
            }
            if (
                (echeq.emisor_cuit === environmentCuit ||
                    (echeq.cheque_caracter.toLowerCase() !== "no a la orden" &&
                        echeq.endosos.find((endoso) => endoso.emisor_documento === environmentCuit))) &&
                echeq.tenencia_beneficiario_documento !== environmentCuit &&
                !echeq.re_presentar &&
                !echeq.solicitando_acuerdo &&
                !echeq.cheque_acordado
            ) {
                contextSpecialMenuActions.push({
                    label: `${FORM_ID}.request.return.echeq`,
                    onClick: () => functionAction("REQUEST-RETURN", echeq),
                });
            }
            if (
                echeq.tenencia_beneficiario_documento === environmentCuit &&
                !echeq.certificado_emitido &&
                !echeq.cesion_pendiente &&
                !echeq.re_presentar &&
                !echeq.cheque_acordado &&
                !echeq.solicitando_acuerdo
            ) {
                contextSpecialMenuActions.push({
                    label: `${FORM_ID}.request.certificate.echeq`,
                    onClick: () => functionAction("REQUEST-CERTIFICATE", echeq),
                });
            }

            if (echeq.solicitando_acuerdo && echeq.tenencia_beneficiario_documento === environmentCuit) {
                contextSpecialMenuActions = [
                    {
                        label: `${FORM_ID}.accept.return.echeq`,
                        onClick: () => functionAction("ACCEPT-RETURN", echeq),
                    },
                    {
                        label: `${FORM_ID}.repudiate.return.echeq`,
                        onClick: () => functionAction("REPUDIATE-RETURN", echeq),
                    },
                ];
            }

            if (echeq.cuit_solic_devol === environmentCuit && echeq.solicitando_acuerdo) {
                contextSpecialMenuActions = [
                    {
                        label: `${FORM_ID}.anulate.return.echeq`,
                        onClick: () => functionAction("ANULATE-RETURN", echeq),
                    },
                ];
            }

            break;
        }
        case "CESION-PENDIENTE": {
            if (echeq.total_cesiones > 0 && echeq.cesiones[0].cesionario_documento === environmentCuit) {
                contextSpecialMenuActions = [
                    {
                        label: `${FORM_ID}.admit.echeq`,
                        onClick: () => functionAction("ADMIT-CESION", echeq),
                    },
                    {
                        label: `${FORM_ID}.repudiate.echeq`,
                        onClick: () => functionAction("REPUDIATE-CESION", echeq),
                    },
                ];
                if (echeqsCesionPendienteAutomationEnabled) {
                    contextSpecialMenuActions = showDepositOrCustody(
                        echeq,
                        contextSpecialMenuActions,
                        functionAction,
                        serverSearchDate,
                    );
                }
                if (echeqsCesionPendienteAutomationDiscountEnabled) {
                    contextSpecialMenuActions = [
                        ...contextSpecialMenuActions,
                        {
                            label: `${FORM_ID}.negotiate.echeq`,
                            onClick: () => functionAction("NEGOTIATE", echeq),
                        },
                    ];
                }
            } else if (echeq.total_cesiones > 0 && echeq.cesiones[0].cedente_documento === environmentCuit) {
                contextSpecialMenuActions = [
                    {
                        label: `${FORM_ID}.anulate.cesion.echeq`,
                        onClick: () => functionAction("ANULATE-CESION", echeq),
                    },
                ];
            }
            break;
        }
        case "DEVOLUCION-PENDIENTE": {
            if (echeq.cuit_solic_devol === environmentCuit) {
                contextSpecialMenuActions.push({
                    label: `${FORM_ID}.anulate.return.echeq`,
                    onClick: () => functionAction("ANULATE-RETURN", echeq),
                });
            }
            if (echeq.tenencia_beneficiario_documento === environmentCuit) {
                contextSpecialMenuActions.push({
                    label: `${FORM_ID}.accept.return.echeq`,
                    onClick: () => functionAction("ACCEPT-RETURN", echeq),
                });
                contextSpecialMenuActions.push({
                    label: `${FORM_ID}.repudiate.return.echeq`,
                    onClick: () => functionAction("REPUDIATE-RETURN", echeq),
                });
            }
            break;
        }
        case "EMITIDO-PENDIENTE": {
            if (echeqsEmitidoPendienteAutomationEnabled && echeq.beneficiario_documento === environmentCuit) {
                contextSpecialMenuActions = showDepositOrCustody(
                    echeq,
                    contextSpecialMenuActions,
                    functionAction,
                    serverSearchDate,
                );
            }
            if (echeqsEmitidoPendienteAutomationDiscountEnabled && echeq.beneficiario_documento === environmentCuit) {
                contextSpecialMenuActions = [
                    ...contextSpecialMenuActions,
                    {
                        label: `${FORM_ID}.negotiate.echeq`,
                        onClick: () => functionAction("NEGOTIATE", echeq),
                    },
                ];
            }
            break;
        }
        default: {
            break;
        }
    }

    return contextSpecialMenuActions;
}

export const receivedEcheqAction = (action, echeq, dispatch, setState, isDesktop) => {
    const echeqList = [echeq];
    dispatch(actions.setEcheqsToDoActions({ action, echeqList }));

    switch (action) {
        case "ADMIT": {
            dispatch(push("/echeqs/action/confirmation"));
            break;
        }
        case "ANULATE-CESION": {
            dispatch(push("/echeqs/anulate/confirmation"));
            break;
        }
        case "REPUDIATE": {
            setState({
                showDrawer: true,
                contentDrawer: <RepudiateEcheq isDesktop={isDesktop} />,
                title: `${isDesktop ? "" : "echeqs.action.motive.title"}`,
            });
            break;
        }
        case "DEPOSIT": {
            setState({
                showDrawer: true,
                contentDrawer: <DepositEcheq isDesktop={isDesktop} />,
                title: `${isDesktop ? "" : "echeqs.action.deposit.title"}`,
            });
            break;
        }
        case "CUSTODY": {
            setState({
                showDrawer: true,
                contentDrawer: <CustodyEcheq isDesktop={isDesktop} />,
                title: `${isDesktop ? "" : "echeqs.action.custody.title"}`,
            });
            break;
        }
        case "RESCUE": {
            dispatch(push("/echeqs/action/confirmation"));
            break;
        }
        case "ENDORSE": {
            dispatch(push("/echeqs/action/endorse"));
            break;
        }
        case "ADMIT-CESION": {
            dispatch(push("/echeqs/action/confirmation"));
            break;
        }
        case "REPUDIATE-CESION": {
            setState({
                showDrawer: true,
                contentDrawer: <RepudiateEcheq isDesktop={isDesktop} />,
                title: `${isDesktop ? "" : "echeqs.action.motive.title"}`,
            });
            break;
        }
        case "EMIT-CESION": {
            dispatch(push("/echeqs/action/cesion"));
            break;
        }
        case "ANULATE-ENDORSE": {
            if (echeq.total_endosos > 0 && echeq.endosos[0].benef_documento === "30576614299") {
                dispatch(
                    notificationActions.showNotification(i18n.get("anulate.cmf.endorse.message"), "info", [
                        "echeqs",
                        "echeqsMainPage",
                    ]),
                );
            } else {
                setState({
                    showDrawer: true,
                    contentDrawer: <AnulateEcheq isDesktop={isDesktop} />,
                    title: `${isDesktop ? "" : "echeqs.anulate.motive.title"}`,
                });
            }

            break;
        }
        case "REQUEST-RETURN": {
            setState({
                showDrawer: true,
                contentDrawer: <RequestReturnEcheq isDesktop={isDesktop} echeq={echeq} />,
                title: `${isDesktop ? "" : "echeqs.request.return.motive.title"}`,
            });
            break;
        }
        case "ANULATE-ECHEQ": {
            setState({
                showDrawer: true,
                contentDrawer: <AnulateEcheq isDesktop={isDesktop} />,
                title: `${isDesktop ? "" : "echeqs.anulate.motive.title"}`,
            });
            break;
        }
        case "ANULATE-RETURN": {
            dispatch(push("/echeqs/anulate/confirmation"));
            break;
        }
        case "REPUDIATE-RETURN": {
            dispatch(push("/echeqs/action/confirmation"));
            break;
        }
        case "ACCEPT-RETURN": {
            dispatch(actions.setRequestReturnSolicitanInformation(echeq.cuit_solic_devol));
            dispatch(push("/echeqs/action/return/accept"));
            break;
        }
        case "REQUEST-CERTIFICATE": {
            dispatch(push("/echeqs/action/confirmation"));
            break;
        }
        case "NEGOTIATE": {
            dispatch(push("/echeqs/action/negotiate"));
            break;
        }
        default:
            break;
    }
};

function showDepositOrCustody(echeq, contextSpecialMenuActions, functionAction, serverSearchDate) {
    let newcontextSpecialMenuActions = contextSpecialMenuActions;

    const fechaPago = moment(echeq.fecha_pago)
        .utc()
        .format("YYYY-MM-DD");
    const fechaHoy = moment(serverSearchDate)
        .utc()
        .format("YYYY-MM-DD");
    const diff = Math.abs(moment(fechaPago).diff(moment(fechaHoy), "days"));

    if ((fechaPago < fechaHoy && diff <= 30) || diff === 0) {
        newcontextSpecialMenuActions = [
            ...contextSpecialMenuActions,
            {
                label: `${FORM_ID}.deposit.echeq`,
                onClick: () => functionAction("DEPOSIT", echeq),
            },
        ];
    }
    if (fechaPago > fechaHoy) {
        newcontextSpecialMenuActions = [
            ...contextSpecialMenuActions,
            {
                label: `${FORM_ID}.custody.echeq`,
                onClick: () => functionAction("CUSTODY", echeq),
            },
        ];
    }

    return newcontextSpecialMenuActions;
}
