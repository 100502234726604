import { Component } from "react";
import { func, string } from "prop-types";

import formField from "pages/forms/_components/_fields/_commons/formField";

class Coordinates extends Component {
    static propTypes = {
        setValue: func.isRequired,
        mode: string.isRequired,
    };

    componentDidMount() {
        const { setValue, mode } = this.props;
        if (mode === "edit") {
            setValue({
                latitude: 0.0,
                longitude: 0.0,
            });
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (currentLocation) => {
                        setValue({
                            latitude: currentLocation.coords.latitude,
                            longitude: currentLocation.coords.longitude,
                        });
                    },
                    () => {},
                );
            }
        }
    }

    render() {
        return null;
    }
}

export default formField()(Coordinates);
