import React, { Component } from "react";
import { bool, shape } from "prop-types";

import MetrocorpHoldingList from "pages/metrocorp/_components/MetrocorpHoldingList";
import MetrocorpFutureValuesList from "pages/metrocorp/_components/MetrocorpFutureValuesList";
import MetrocorpMovementsList from "pages/metrocorp/_components/MetrocorpMovementsList";

class MetrocorpList extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        optionSelected: bool.isRequired,
        currentDate: shape({}).isRequired,
    };

    render() {
        const { optionSelected } = this.props;

        switch (optionSelected) {
            case "holdings":
                return (
                    <div className="metrocorp__list">
                        <MetrocorpHoldingList {...this.props} />
                    </div>
                );
            case "futureValues":
                return (
                    <div className="metrocorp__list">
                        <MetrocorpFutureValuesList {...this.props} />
                    </div>
                );
            case "movements":
                return (
                    <div className="metrocorp__list">
                        <MetrocorpMovementsList {...this.props} />
                    </div>
                );
            default:
                return null;
        }
    }
}

export default MetrocorpList;
