import React, { Component } from "react";
import { routerActions } from "react-router-redux/actions";
import { func, string, shape, arrayOf, number } from "prop-types";
import { connect } from "react-redux";

import { actions as formActions } from "reducers/form";
import {
    actions as transactionLinesActions,
    selectors as transactionLinesSelectors,
} from "reducers/form/transactionLines";
import { actions as multilineFileActions } from "reducers/formFields/multilineFile";
import * as i18nUtils from "util/i18n";

import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";

import DetailBox from "pages/_components/detailBox/DetailBox";
import FormattedAmount from "pages/_components/FormattedAmount";
import Button from "pages/_components/Button";
import { listItemProps } from "pages/forms/_components/_fields/_transactionlines/ListItem";

class ManualPayment extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        idForm: string.isRequired,
        transactionLines: arrayOf(shape(listItemProps)),
        currencies: arrayOf(string),
        paymentCurrency: string.isRequired,
        form: shape({
            values: shape({}),
        }).isRequired,
        linesWithNoAmount: number.isRequired,
    };

    static defaultProps = {
        transactionLines: [],
        currencies: ["USD", "EUR"],
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(multilineFileActions.onPaymentMethodChange());
    }

    handleClick = () => {
        const { dispatch, idForm, form, transactionLines } = this.props;

        dispatch(transactionLinesActions.setIsEditingPayment(transactionLines.length === 0));
        dispatch(routerActions.push(`/form/${idForm}/manual`));
        dispatch(formActions.setData(form.values));
    };

    handleCurrencyChange = (currency) => {
        const { dispatch } = this.props;
        dispatch(transactionLinesActions.setCurrency(currency));
    };

    render() {
        const { transactionLines, paymentCurrency, currencies, totalAmount } = this.props;

        return (
            <DetailBox>
                <DetailBox.Data label="forms.inputFile.massivePayments.currency">
                    <RadioButtonGroup
                        options={currencies.map((currency) => ({
                            id: currency,
                            label: i18nUtils.get(`currency.label.${currency}`),
                        }))}
                        value={paymentCurrency}
                        onChange={this.handleCurrencyChange}
                        inLineControl
                    />
                </DetailBox.Data>
                <DetailBox.Data label="forms.inputFile.massivePayments.payments">
                    {transactionLines.length}
                </DetailBox.Data>
                <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                    <FormattedAmount className="data-desc" {...totalAmount} />
                </DetailBox.Data>
                <div className="detailBox-row">
                    <Button
                        image="images/plus.svg"
                        bsStyle="primary"
                        className="btn-small"
                        onClick={this.handleClick}
                        label={`forms.inputFile.massivePayments.${transactionLines.length ? "edit" : "add"}Payments`}
                    />
                </div>
            </DetailBox>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionLines: transactionLinesSelectors.getTransactionLines(state),
    totalAmount: transactionLinesSelectors.getTotalAmount(state),
    paymentCurrency: transactionLinesSelectors.getCurrency(state),
    linesWithNoAmount: transactionLinesSelectors.getLinesWithNoAmount(state).length,
});

export default connect(mapStateToProps)(ManualPayment);
