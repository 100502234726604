import React, { Component } from "react";
import { shape, string, func, bool, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";

import { actions as financingActions, selectors as financingSelectors } from "reducers/financing";

import * as i18nUtils from "util/i18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";

import classNames from "classnames";
import MovementsFinancing from "./MovementsFinancing";

import FinancingSummaryDetail from "./FinancingSummaryDetail";

class FinancingDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        financingDetail: shape({}).isRequired,
        match: shape({
            params: shape({
                id: string,
            }),
        }).isRequired,
        financingFeed: arrayOf(shape({})).isRequired,
        listCategories: arrayOf(shape({})).isRequired,
        fetching: bool.isRequired,
        fetchingDownload: bool.isRequired,
        isDesktop: bool.isRequired,
        optionSelected: string.isRequired,
        lastDateFromFilter: string.isRequired,
        lastDateToFilter: string.isRequired,
    };

    componentDidMount() {
        const {
            dispatch,
            match: { params },
            listCategories,
            optionSelected,
        } = this.props;
        if (optionSelected === "toPay") {
            dispatch(financingActions.getFinancingDetailReques(params.id, listCategories, "3"));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
        dispatch(financingActions.setOptionSelected("toPay"));
    };

    getTitle = () => {
        const { match } = this.props;
        const { financingDetail } = this.props;

        const category = financingDetail?.descripcionCategoriaProducto;
        const name = `${category} ${i18nUtils.get("financing.detail.title")} ${match.params.id}`;
        const oneLoan = `${i18nUtils.get("financing.loan.title")} ${i18nUtils.get("financing.detail.title")} ${
            match.params.id
        }`;

        return (
            <h1 className="title-content white-space-wrap">
                {financingDetail?.descripcionCategoriaProducto === i18nUtils.get("financing.loans.title")
                    ? oneLoan
                    : name}
            </h1>
        );
    };

    renderPageHeader = (fetchingDownload, financingFeed, isDesktop) => {
        const { financingDetail } = this.props;

        return (
            <>
                <Notification scopeToShow="form" />
                <div className={classNames({ "admin-detail-head": isDesktop }, "px-0")}>
                    {isDesktop && <Head onBack={this.handleBack} />}
                    {isDesktop ? (
                        <Head
                            handleClick={this.handleExport}
                            exportList={financingFeed}
                            isFetchingExport={fetchingDownload}
                            handleClickMessage="global.download"
                            uniqueDownload
                            hasInlineButtons
                            centerElement={financingDetail && this.getTitle}
                            imageStyle="mr-2"
                        />
                    ) : (
                        <Head
                            headerClassName="blue-main-header-mobile text-nowrap"
                            handleClick={this.handleExport}
                            exportList={financingFeed}
                            isFetchingExport={fetchingDownload}
                            handleClickMessage="global.download"
                            uniqueDownload
                            centerElement={financingDetail && this.getTitle}
                            centerContentClassName="mx-5"
                            onBack={this.handleBack}
                            downloadImageWhite
                            addButtonImageWhite
                        />
                    )}
                </div>
            </>
        );
    };

    handleExport = (format) => {
        const {
            dispatch,
            financingFeed,
            financingDetail,
            lastDateFromFilter,
            lastDateToFilter,
            optionSelected,
        } = this.props;
        dispatch(
            financingActions.downloadListFeed(
                format,
                financingFeed,
                financingDetail,
                lastDateFromFilter,
                lastDateToFilter,
                optionSelected,
            ),
        );
    };

    render() {
        const {
            listCategories,
            match,
            financingFeed,
            financingDetail,
            fetchingDownload,
            isDesktop,
            fetching,
            optionSelected,
        } = this.props;
        return (
            <div className="d-flex f-dir-col px-0">
                {this.renderPageHeader(fetchingDownload, financingFeed, isDesktop)}
                {financingDetail && <FinancingSummaryDetail financing={financingDetail} isDesktop={isDesktop} />}
                <MovementsFinancing
                    id={match.params.id}
                    listCategories={listCategories}
                    financingDetail={financingDetail}
                    financingFeed={financingFeed}
                    isDesktop={isDesktop}
                    optionSelected={optionSelected}
                    keyLabel="accounts.movements"
                    fetching={fetching}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    category: financingSelectors.getCategory(state),
    listCategories: financingSelectors.getFinancing(state),
    financingDetail: financingSelectors.getFinancingDetail(state),
    financingFeed: financingSelectors.getFinancingFeed(state),
    lastDateFromFilter: financingSelectors.getLastDateFromFilter(state),
    lastDateToFilter: financingSelectors.getLastDateToFilter(state),
    fetchingDownload: financingSelectors.getFetchingDownload(state),
    fetching: financingSelectors.getFetching(state),
    optionSelected: financingSelectors.getOptionSelected(state),
});

export default connect(mapStateToProps)(FinancingDetail);
