import React, { Component } from "react";
import { string, node, bool } from "prop-types";
import classNames from "classnames";

class DataContent extends Component {
    static propTypes = {
        children: node.isRequired,
        label: string,
        strong: bool,
    };

    static defaultProps = {
        label: "",
        strong: false,
    };

    render() {
        const { label, strong, children } = this.props;
        return (
            <div className="data-wrapper">
                <span className="data-label">{label}</span>
                <span className={classNames("data-text", { "content-data-strong": strong })}>{children}</span>
            </div>
        );
    }
}

export default DataContent;
