import React, { Component } from "react";
import { bool, shape, arrayOf, func } from "prop-types";
import { connect } from "react-redux";

import { actions as fundcorpActions, selectors as fundcorpSelectors } from "reducers/fundcorp";

import Table from "pages/_components/Table";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import FilterTableHead from "pages/transactions/FilterTableHead";
import NoDataMessage from "./NoDataMessage";
import classNames from "classnames";

const FORM_ID = "fundcorp.list";
class FundcorpMovementList extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        movements: arrayOf(shape({})).isRequired,
        filter: shape({}).isRequired,
        dispatch: func.isRequired,
    };

    filterChange = (filterName, filterType) => {
        const { dispatch, movements } = this.props;

        const filter = {
            filterSelected: filterName,
            directionFilter: filterType,
        };

        dispatch(fundcorpActions.filterChange(movements, filter));
    };

    renderListForMovements = () => {
        const { isDesktop, isSmallDesktop, movements, filter } = this.props;

        return (
            <>
                {movements && movements.length > 0 ? (
                    <Container className="flex-grow scrollable">
                        <Col md={12} className="table-with-border px-0 mt-2">
                            <Table>
                                {isDesktop && (
                                    <Table.Header className="container-white">
                                        <Table.HeaderData>
                                            <div className="d-flex align-items-center max-width-fit mx-auto">
                                                <I18n 
                                                    id={`${FORM_ID}.movement.column.consultationDate`} 
                                                    componentProps={{
                                                        className: classNames({ "w-min-content": isSmallDesktop })
                                                    }}
                                                    />
                                                <FilterTableHead
                                                    filterName="fechaConcertacion"
                                                    columnFilter={filter}
                                                    changeFilter={this.filterChange}
                                                />
                                            </div>
                                        </Table.HeaderData>
                                        <Table.HeaderData align="left">
                                            <div className="d-flex align-items-center">
                                                <I18n id={`${FORM_ID}.movement.column.fund`} />
                                                <FilterTableHead
                                                    filterName="fondoNombre"
                                                    columnFilter={filter}
                                                    changeFilter={this.filterChange}
                                                />
                                            </div>
                                        </Table.HeaderData>
                                        <Table.HeaderData>
                                            <I18n id={`${FORM_ID}.movement.column.shareType`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData>
                                            <I18n id={`${FORM_ID}.movement.column.settlementType`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData>
                                            <I18n id={`${FORM_ID}.movement.column.number`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="right">
                                            <I18n id={`${FORM_ID}.movement.column.shares`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="right">
                                            <I18n id={`${FORM_ID}.movement.column.balanceShare`} />
                                        </Table.HeaderData>
                                        <Table.HeaderData align="right">
                                            <I18n id={`${FORM_ID}.movement.column.totalBalance`} />
                                        </Table.HeaderData>
                                    </Table.Header>
                                )}
                                <Table.Body>
                                    {movements.map((item) => this.renderListForMovementsItem(item))}
                                </Table.Body>
                            </Table>
                        </Col>
                    </Container>
                ) : (
                    <NoDataMessage>
                        <I18n id={`${FORM_ID}.empty.message.information`} />
                    </NoDataMessage>
                )}
            </>
        );
    };

    renderListForMovementsItem = (item) => {
        const { isDesktop } = this.props;
        const {
            fechaConcertacion,
            fondoNombre,
            tipoVCPAbreviatura,
            liquidacionTipo,
            liquidacionNumero,
            cuotapartes,
            vCPValor,
            importeNeto,
            monedaIDAdicional,
        } = item;

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    <Table.Data>
                        <FormattedDate date={fechaConcertacion} dateFormat="dd/MM/yyyy" />
                    </Table.Data>
                    <Table.Data align="left">
                        <div className="data-text">{fondoNombre}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text">{tipoVCPAbreviatura}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text">{liquidacionTipo}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text">{liquidacionNumero}</div>
                    </Table.Data>
                    <Table.Data align="right">
                        <FormattedAmount
                            className="data-amount"
                            quantity={cuotapartes}
                            notBold
                            noCurrency
                            minimumDecimalsCustom={4}
                            maximumDecimalsCustom={4}
                        />
                    </Table.Data>
                    <Table.Data align="right">
                        <FormattedAmount
                            currency={monedaIDAdicional}
                            className="data-amount"
                            quantity={vCPValor}
                            minimumDecimalsCustom={7}
                            maximumDecimalsCustom={7}
                            notBold
                        />
                    </Table.Data>
                    <Table.Data align="right">
                        <FormattedAmount
                            className="data-amount"
                            quantity={importeNeto}
                            notBold
                            currency={monedaIDAdicional}
                        />
                    </Table.Data>
                </Table.Row>
            );
        }
        return (
            <div className="w-100 my-1">
                <Table.Row className="container-white pt-1">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.movement.column.consultationDate`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <FormattedDate date={fechaConcertacion} dateFormat="dd/MM/yyyy" />
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.movement.column.fund`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <div className="data-text">{fondoNombre}</div>
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.movement.column.shareType`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <div className="data-text">{tipoVCPAbreviatura}</div>
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.movement.column.settlementType`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <div className="data-text">{liquidacionTipo}</div>
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.movement.column.number`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <div className="data-text">{liquidacionNumero}</div>
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.movement.column.shares`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <FormattedAmount
                            className="data-amount"
                            quantity={cuotapartes}
                            notBold
                            noCurrency
                            minimumDecimalsCustom={4}
                            maximumDecimalsCustom={4}
                        />
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.movement.column.balanceShare`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <FormattedAmount
                            currency={monedaIDAdicional}
                            className="data-amount"
                            quantity={vCPValor}
                            notBold
                        />
                    </Table.Data>
                </Table.Row>
                <Table.Row className="container-white pb-1">
                    <Table.HeaderData align="left">
                        <I18n id={`${FORM_ID}.movement.column.totalBalance`} />
                    </Table.HeaderData>
                    <Table.Data align="right">
                        <FormattedAmount
                            className="data-amount"
                            quantity={importeNeto}
                            notBold
                            currency={monedaIDAdicional}
                        />
                    </Table.Data>
                </Table.Row>
            </div>
        );
    };

    render() {
        return <>{this.renderListForMovements()}</>;
    }
}

const mapStateToProps = (state) => ({
    filter: fundcorpSelectors.getFilter(state),
});

export default connect(mapStateToProps)(FundcorpMovementList);
