import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form, withFormik } from "formik";
import * as Yup from "yup";
import { push } from "react-router-redux";
import { func, shape, arrayOf, string, bool } from "prop-types";

import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

import { selectors as servicePaymentsSelectors, actions as servicePaymentsActions } from "reducers/servicePayments";
import { actions as checkActions } from "reducers/checks";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as accountsSelector } from "reducers/accounts";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import AgendaPaymentInfo from "./_components/AgendaPaymentInfo";
import AgendaConceptWithBaseDebt from "./_components/AgendaConceptWithBaseDebt";
import AgendaConceptWithoutBaseDebt from "./_components/AgendaConceptWithoutBaseDebt";
import SelectPaymentMethod from "./_components/SelectPaymentMethod";
import ContinueButton from "./_components/ContinueButton";

const FORM_ID = "services.newPayment";

class AgendaPayment extends Component {
    static propTypes = {
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSubmitting: bool,
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        submitForm: func.isRequired,
        summaryInfo: shape({}).isRequired,
        values: shape({}).isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
    };

    componentDidMount() {
        const { summaryInfo: agendaPago, dispatch, loggedUser } = this.props;
        dispatch(checkActions.fetchAccounts(loggedUser.userId));
        if (!(agendaPago &&
            agendaPago.entity)) {
            this.handleBack();
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/servicePayments"));
    };

    renderConceptBody = (agendaPago) => {
        const currencies = [{ id: 0, label: i18nUtils.get(`currency.label.0`) }];
        const { isDesktop } = this.props;

        if (!agendaPago.entity.handleBaseDebts) {
            return (
                <AgendaConceptWithoutBaseDebt
                    FORM_ID={FORM_ID}
                    agendaPago={agendaPago}
                    currencies={currencies}
                    isDesktop={isDesktop}
                />
            );
        }

        return (
            <AgendaConceptWithBaseDebt
                FORM_ID={FORM_ID}
                agendaPago={agendaPago}
                currencies={currencies}
                isDesktop={isDesktop}
            />
        );
    };

    renderConcept = () => {
        const { summaryInfo: agendaPago, isDesktop } = this.props;

        return (
            agendaPago &&
            agendaPago.entity && (
                <>
                    <Container className="px-3 mb-2 flex-grow scrollable container-white">
                        <Col className="payments-taxes-width px-0 px-md-3 px-xl-0" sm={12} md={9}>
                            {isDesktop ? (
                                <div className="d-flex flex-column w-100 bd-spacing-0-2 mt-3 mb-45">
                                    <Row className="mx-0">
                                        <Col className="d-flex justify-content-start pl-md-0" xs={12} md={6}>
                                            <I18n
                                                componentProps={{ className: "text-uppercase f-size-55" }}
                                                component="span"
                                                id={`${FORM_ID}.amountToPay`}
                                            />
                                        </Col>

                                        <Col className="d-flex justify-content-center" xs={6} md={3}>
                                            <I18n
                                                componentProps={{ className: "text-uppercase f-size-55" }}
                                                id={`${FORM_ID}.expiration`}
                                            />
                                        </Col>
                                        <Col className="d-flex justify-content-end px-0" xs={6} md={3}>
                                            <I18n
                                                componentProps={{ className: "text-uppercase f-size-55" }}
                                                id={`${FORM_ID}.concept`}
                                            />
                                        </Col>
                                    </Row>
                                    {this.renderConceptBody(agendaPago)}
                                </div>
                            ) : (
                                <>{this.renderConceptBody(agendaPago)}</>
                            )}
                        </Col>
                    </Container>
                </>
            )
        );
    };

    render() {
        const { summaryInfo: agendaPago, accounts, values, isDesktop, isSubmitting, submitForm } = this.props;
        const { account } = values;

        const accountsToShow = accounts.filter((accountToShow) => accountToShow.currency === "0");

        return (
            agendaPago &&
            agendaPago.entity ? (
                <>
                    {isDesktop ? (
                        <div className="admin-detail-head px-0" style={{ marginBottom: "0" }}>
                            <Head onBack={this.handleBack} onClose={this.handleBack} />
                            <Head title={FORM_ID} />
                        </div>
                    ) : (
                        <div className="admin-detail-head px-0" style={{ marginBottom: "0" }}>
                            <Head
                                title={FORM_ID}
                                onBack={this.handleBack}
                                onClose={this.handleBack}
                                headerClassName="blue-main-header-mobile agenda-payment-title-mobile"
                                emptyRightOption
                            />
                        </div>
                    )}
                    <Notification scopeToShow="servicePayments" />
                    <MainContainer>
                        <div className="above-the-fold payment-schedule">
                            <Form>
                                <AgendaPaymentInfo
                                    FORM_ID={FORM_ID}
                                    agendaPago={agendaPago}
                                    handleBack={this.handleBack}
                                    isDesktop={isDesktop}
                                />
                                {this.renderConcept()}
                                <SelectPaymentMethod
                                    FORM_ID={FORM_ID}
                                    accounts={accountsToShow}
                                    account={account}
                                    isDesktop={isDesktop}
                                    statusCPE={agendaPago?.entity}
                                    handleAddService={this.handleAddService}
                                    isAddServiceSelected={false}
                                    showAddService={false}
                                />
                                <ContinueButton
                                    isSubmittingConcepts={isSubmitting}
                                    submitForm={submitForm}
                                    className={`new-payment-continue-button mt-45 pt-2 ${!isDesktop && "px-3"}`}
                                    isDesktop={isDesktop}
                                    btnContainerClassName="max-xl-480 px-0 px-md-3 px-xl-0"
                                />
                            </Form>
                        </div>
                    </MainContainer>
                </>
            ) : null
        );
    }
}

const handlePreparePayment = (formikBag, values, serviceReference) => {
    const { summaryInfo: agendaPago, accounts, dispatch } = formikBag.props;
    const {
        entity: { handleBaseDebts },
    } = agendaPago;

    const selectedRubro = agendaPago.entity.category;
    const selectedEntity = agendaPago.entity;
    const selectedCPE = agendaPago.ususerLP;
    const selectedAmountToPay = values[`conceptAmount${values.concept}`];
    const selectedConceptExpirationDate = handleBaseDebts
        ? agendaPago.expiration
        : i18nUtils.get("services.newPayment.noExpirationDate");
    const selectedConcept = handleBaseDebts ? agendaPago.expiration : agendaPago.concept;
    const selectedDebitAccount = accounts.find((account) => account.idProduct === values.account);
    const { paymentReference } = values;
    const debtId = handleBaseDebts ? agendaPago.expiration.debtId : "";
    const statusCPE = agendaPago.entity;

    dispatch(
        servicePaymentsActions.preparePayment(
            {
                agendaPago,
                selectedRubro,
                selectedEntity,
                selectedCPE,
                selectedAmountToPay,
                selectedConceptExpirationDate: handleBaseDebts
                    ? selectedConceptExpirationDate.date
                    : selectedConceptExpirationDate,
                selectedConcept: handleBaseDebts ? selectedConcept.concept : selectedConcept,
                selectedDebitAccount,
                paymentReference,
                statusCPE,
                serviceReference,
                debtId,
                conceptCode: handleBaseDebts
                    ? agendaPago.expiration.concept.conceptCode
                    : agendaPago.concept.conceptCode,
                ...agendaPago,
            },
            formikBag,
        ),
    );
};

const mapStateToProps = (state) => ({
    summaryInfo: servicePaymentsSelectors.getSummaryInfo(state),
    accounts: accountsSelector.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    componentValues: servicePaymentsSelectors.getComponentValues(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: (props) => {
            let initialValues = {
                ...props.componentValues,
            };

            if (!props?.summaryInfo?.entity?.handleBaseDebts) {
                initialValues = {
                    ...initialValues,
                    concept: props?.summaryInfo?.concept?.conceptCode,
                    [`conceptAmount${props?.summaryInfo?.concept?.conceptCode}`]: "",
                };
            } else {
                initialValues = {
                    ...initialValues,
                    concept: props?.summaryInfo?.expiration?.debtId,
                    [`conceptAmount${props?.summaryInfo?.expiration?.debtId}`]:
                        props?.summaryInfo?.expiration?.amount || "",
                };
            }

            if (props?.summaryInfo?.selectedEntity) {
                initialValues = {
                    ...initialValues,
                    entity: props.summaryInfo.selectedEntity,
                };
            }

            if (props?.summaryInfo?.selectedDebitAccount) {
                initialValues = {
                    ...initialValues,
                    account: props.summaryInfo.selectedDebitAccount.idProduct,
                };
            }

            if (props?.summaryInfo?.paymentReference) {
                initialValues = {
                    ...initialValues,
                    paymentReference: props.summaryInfo.paymentReference,
                };
            }

            if (props?.summaryInfo?.selectedAmountToPay) {
                initialValues = {
                    ...initialValues,
                    [`conceptAmount${props?.summaryInfo?.concept?.conceptCode}`]: props?.summaryInfo
                        ?.selectedAmountToPay,
                };
            }

            return initialValues;
        },
        validationSchema: () =>
            Yup.lazy(() =>
                Yup.object().shape({
                    account: Yup.string().required(i18nUtils.get("new.check.field.error.required")),
                }),
            ),
        handleSubmit: (values, formikBag) => {
            handlePreparePayment(formikBag, values, "NO_REFERENCE");
        },
    }),
)(AgendaPayment);
