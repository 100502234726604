import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import { func, bool, number } from "prop-types";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import { Modal } from "react-bootstrap";
import { actions } from "reducers/campaigns";
import { actions as loginActions } from "reducers/login";

class CloseCampaignModal extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        goBack: func,
        isDisplayed: bool,
        idCampaign: number.isRequired,
        closeModal: func.isRequired,
    };

    static defaultProps = {
        goBack: () => {},
        isDisplayed: false,
    };

    state = {
        fetching: false,
        isClosing: false,
    };

    goBack = () => {
        const { goBack } = this.props;
        goBack();
    };

    dismissPermanentHandler = () => {
        const { dispatch, idCampaign } = this.props;
        dispatch(actions.dismissPermanentCampaign(idCampaign));
    };

    dismissHandler = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.dismissCampaigns());
    };

    render() {
        const { isDisplayed, closeModal } = this.props;
        const { fetching, isClosing } = this.state;

        return (
            <Modal
                aria-labelledby="modalTitleID"
                aria-describedby="modalDescID"
                aria-modal="true"
                show={isDisplayed && !isClosing}
                className="modal-campaign">
                <div className="modal-container">
                    <Modal.Header closeButton onClick={closeModal}>
                        <Modal.Title />
                    </Modal.Header>
                    <Modal.Body>
                        <MainContainer showLoader={fetching}>
                            <div className="above-the-fold">
                                <Container className="container--layout flex-grow align-items-center">
                                    <Col sm={12} className="col col-12">
                                        <Image src="images/warningIcon.svg" className="svg-icon" />
                                        <h3>
                                            <I18n id="desktop.campaigns.modal.title" />
                                        </h3>
                                        <I18n id="desktop.campaigns.modal.description" />
                                    </Col>
                                </Container>
                                <Container className="container--layout align-items-center">
                                    <Col className="col col-12">
                                        <Button
                                            type="button"
                                            label="desktop.campaigns.modal.button.dismiss"
                                            bsStyle="primary"
                                            onClick={this.dismissHandler}
                                        />
                                        <Button
                                            type="button"
                                            className="btn-outline"
                                            label="desktop.campaigns.modal.button.dismissPermanent"
                                            onClick={this.dismissPermanentHandler}
                                        />
                                    </Col>
                                </Container>
                            </div>
                        </MainContainer>
                    </Modal.Body>
                </div>
            </Modal>
        );
    }
}

export default CloseCampaignModal;
