import React, { useEffect, useState } from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import classNames from "classnames";

import ContextMenu from "pages/_components/ContextMenu";
import MultipleTransferTicketData from "../MultipleTransferTicketData";

import { actions as formActions } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";
import { actions as multipleTransferActions } from "reducers/multiple-transfers";
import { selectors as sessionSelectors } from "reducers/session";

import { getAccountDescription } from "util/accounts";
import * as i18n from "util/i18n";
import { VALIDATION_ERROR } from "util/responses";
import { checkSign } from "util/transaction";

import { multipleTransfersPre } from "middleware/multiple-transfers";
import { potentialSignaturesRequest } from "middleware/transactions";

const idActivity = "transfer.multiple.send";
const TITLE = "multiple.transfers.ticket.title";

function MultipleTransferContextMenu({
    dispatch,
    isDesktop,
    batch,
    idTransactionStatus,
    isBatchListLayout,
    loggedUser,
    activeEnvironment,
    bankConcepts,
    transfer,
    action,
    setActiveTransfer,
    handleDownloadVoucher,
}) {
    const [items, setItems] = useState([]);
    const [transferData, setTransferData] = useState(null);
    const [signatureData, setSignatureData] = useState(null);

    useEffect(() => {
        const getPotencialSignatures = async () => {
            if (batch?.transactionId) {
                const { type, data } = await potentialSignaturesRequest(batch?.transactionId);
                if (type === "W") {
                    dispatch(
                        notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                            "multipleTransfer.list",
                        ]),
                    );
                } else {
                    setSignatureData(data?.data?.potentialSignatures);
                }
            }
        };
        getPotencialSignatures();
    }, [dispatch, batch]);

    useEffect(() => {
        if (transfer) {
            const holderType =
                transfer?.cuitCuiltDestination === activeEnvironment?.clients[0]?.idClient
                    ? i18n.get("transfers.titular.same.label")
                    : i18n.get("transfers.titular.other.label");
            setTransferData({
                transferDate: {
                    value: batch.processDate,
                    format: "datetime",
                },
                voucherNumber: {
                    value: transfer.voucherNumber,
                    format: "string",
                },
                originName: {
                    value: activeEnvironment?.name,
                    format: "string",
                },
                debitAccount: {
                    value: getAccountDescription(batch.account),
                    format: "string",
                },
                transferType: {
                    value: holderType,
                    format: "string",
                },
                cbuDestination: {
                    value: transfer.cbuDestination,
                    format: "string",
                },
                cuitCuiltDestination: {
                    value: transfer?.cuitCuiltDestination,
                    format: "string",
                },
                holder: {
                    value: transfer.destinationName,
                    format: "string",
                },
                bankName: {
                    value: transfer.bankName,
                    format: "string",
                },
                transferAmount: {
                    value: { quantity: transfer.amount, currency: batch.amount?.currency || "ARS" },
                    format: `amount${i18n.get(`currency.label.${batch.amount?.currency || "ARS"}`)}`,
                },
                concept: {
                    value: `${transfer.idBankConcepts} - ${bankConcepts[transfer.idBankConcepts]}`,
                    format: "string",
                },
                transferReference: {
                    value: transfer.reference,
                    format: "string",
                },
            });
        }
    }, [batch, transfer]);

    let batchData = null;

    if (batch) {
        batchData = {
            id: {
                value: batch.idBatch,
                format: "string",
            },
            debitAccount: {
                value: getAccountDescription(batch.account),
                format: "string",
            },
            reference: {
                value: batch.reference,
                format: "string",
            },
            uploadDate: {
                value: batch.creationDate,
                format: "datetime",
            },
            executionDate: {
                value: batch.processDate,
                format: "date",
            },
            fileName: {
                value: batch.fileName,
                format: "string",
            },
            quantity: {
                value: batch.quantity,
                format: "string",
            },
            amount: {
                value: batch.amount?.quantity,
                format: `amount${i18n.get(`currency.label.${batch.amount?.currency || "ARS"}`)}`,
            },
        };
    }

    const cancelTransaction = () => {
        const cancelTransactionData = (
            <MultipleTransferTicketData
                dispatch={dispatch}
                isDesktop={isDesktop}
                data={batchData}
                batch={{ ...batch, idTransactionStatus: batch?.batchStatus }}
            />
        );
        dispatch(push({ pathname: "/transactions/cancel", state: { goBackState: "/transfer/multiple" } }));
        dispatch(
            formActions.cancelTransactionData({
                idTransaction: batch?.transactionId,
                content: cancelTransactionData,
                title: TITLE,
                force: true,
            }),
        );
        dispatch(formActions.cancelTransactionPre({ idActivity, idForm: "" }));
    };

    const signTransaction = () => {
        const signTransactionData = (
            <MultipleTransferTicketData
                dispatch={dispatch}
                isDesktop={isDesktop}
                data={batchData}
                batch={{ ...batch, idTransactionStatus: batch?.batchStatus }}
            />
        );

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: batch?.transactionId,
                idActivity,
                idForm: "",
                content: signTransactionData,
                title: TITLE,
                pendingDispatch: false,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity,
                idTransaction: batch?.transactionId,
                idForm: "",
                ticketData: batchData,
            }),
        );
    };

    const validateSignTransaction = async () => {
        dispatch(multipleTransferActions.setFetching(true));
        const { type, data } = await multipleTransfersPre();
        if (type === "W") {
            const { NO_FIELD } = data.data;
            if (data.code === VALIDATION_ERROR) {
                dispatch(notificationActions.showNotification(NO_FIELD, "warning", ["multipleTransfer.list"]));
            }
        } else {
            signTransaction();
        }
        dispatch(multipleTransferActions.setFetching(false));
    };

    useEffect(() => {
        switch (idTransactionStatus) {
            case "PENDING_SEND":
            case "PENDING_VALIDATION":
            case "PENDING":
                if (isBatchListLayout) {
                    const signedTransaction = checkSign({ signatures: batch.signatures }, loggedUser, signatureData);
                    const itemsPending = [
                        {
                            label: "multiple.transfers.card.cancel",
                            onClick: () => {
                                cancelTransaction();
                            },
                        },
                    ];
                    if (signedTransaction && idTransactionStatus === "PENDING") {
                        itemsPending.push({
                            label: "multiple.transfers.card.sign",
                            onClick: () => {
                                validateSignTransaction();
                            },
                        });
                    }
                    setItems(itemsPending);
                }
                break;
            case "SUCCESS":
                if (!isBatchListLayout) {
                    setItems([
                        {
                            label: "multiple.transfers.detail.context.ticket",
                            onClick: () => {
                                setActiveTransfer(transferData);
                            },
                        },
                        {
                            label: "multiple.transfers.detail.context.download",
                            onClick: () => {
                                handleDownloadVoucher(transferData);
                            },
                        },
                    ]);
                }
                break;
            case "ERROR":
                if (!isBatchListLayout && transfer.error) {
                    setItems([
                        {
                            label: "multiple.transfers.detail.context.error",
                            onClick: () => {
                                action();
                            },
                        },
                    ]);
                }
                break;

            default:
                break;
        }
    }, [dispatch, idTransactionStatus, isBatchListLayout, signatureData, transfer]);

    return (
        <button
            type="button"
            className={classNames("btn btn-link min-height-auto p-0 m-0", { "invisible min-width-2": !items.length })}
            onClick={(e) => e.stopPropagation()}>
            <ContextMenu imageStyle="pr-0" items={items} />
        </button>
    );
}

MultipleTransferContextMenu.propTypes = {
    dispatch: func.isRequired,
    setActiveTransfer: func,
    handleDownloadVoucher: func,
    isDesktop: bool.isRequired,
    batch: shape({}).isRequired,
    idTransactionStatus: string.isRequired,
    isBatchListLayout: bool.isRequired,
    loggedUser: shape({
        userId: string.isRequired,
    }).isRequired,
    activeEnvironment: shape({}).isRequired,
    bankConcepts: arrayOf(string),
    transfer: shape({}),
    action: func,
};

MultipleTransferContextMenu.defaultProps = {
    handleDownloadVoucher: undefined,
    setActiveTransfer: undefined,
    bankConcepts: [],
    transfer: null,
    action: null,
};

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(MultipleTransferContextMenu);
