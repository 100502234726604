import { LOCATION_CHANGE } from "react-router-redux";

import types from "reducers/types/form";
import transactionLinesReducer from "reducers/form/transactionLines";
import { shouldKeepFormState } from "reducers/helpers/form";
import { createReducer, makeActionCreator } from "util/redux";
import { toggleParamsFromFalse, toggleParamsFromTrue } from "util/boolean";

export const INITIAL_STATE = {
    id: null,
    values: null,
    fetching: true,
    fetchingDownloadTicket: false,
    metadata: { fieldList: [], formNameMap: {} },
    transaction: {},
    childrenTransactions: null,
    parentTransaction: null,
    data: {},
    credentialsGroups: [],
    name: "",
    submitAction: null,
    submitActionParams: null,
    mode: "edit",
    prevMode: "edit",
    previewData: null,
    isCancellingTransaction: false,
    prevRoute: { pathname: "" },
    cancelData: null,
    isSuccessSign: null,
};

const setPreviewState = (state, action) => ({
    ...state,
    credentialsGroups: action.credentialsGroups,
    submitAction: action.submitAction,
    submitActionParams: {
        values: { scheduler: null },
        ...action.submitActionParams,
    },
    fetching: false,
    prevMode: state.mode,
    mode: "preview",
    previewData: action.previewData,
});

const formReducer = createReducer(INITIAL_STATE, {
    [LOCATION_CHANGE]: (state, action) => {
        if (shouldKeepFormState(action.payload, state.prevRoute)) {
            return { ...state, prevRoute: action.payload };
        }

        return { ...INITIAL_STATE, prevRoute: action.payload };
    },
    [types.READ_FORM_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        id: action.idForm,
        metadata: action.formMetadata,
        data: { ...state.data, ...action.formData },
        transaction: {},
        mode: "edit",
    }),
    [types.SET_DATA]: (state, action) => ({
        ...state,
        data: { ...state.data, ...action.payload },
    }),
    [types.PREVIEW_FORM_REQUEST]: (state, action) => ({ ...state, values: action.payload.values }),
    [types.PREVIEW_FORM_SUCCESS]: setPreviewState,
    [types.SEND_FORM_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: [],
        idTransaction: action.idTransaction,
        fetching: false,
        transaction: action.transaction,
        data: action.transaction.data,
        mode: "view",
    }),
    [types.MODIFY_TRANSACTION_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.MODIFY_TRANSACTION_FAILURE]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.READ_TRANSACTION_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.READ_TRANSACTION_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        transaction: action.transaction,
        childrenTransactions: action.childrenTransactions,
        parentTransaction: action.parentTransaction,
        id: action.idForm,
        data: action.transaction.data,
        metadata: action.formMetadata,
        mode: action.transaction.idTransactionStatus === "DRAFT" ? "edit" : "view",
    }),
    [types.READ_TRANSACTION_FAILURE]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.SIGN_TRANSACTION_PREVIEW_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.SIGN_TRANSACTION_PREVIEW_SUCCESS]: setPreviewState,
    [types.CANCEL_TRANSACTION_PRE_REQUEST]: (state) =>
        toggleParamsFromFalse(state, "fetching", "isCancellingTransaction"),
    [types.CANCEL_TRANSACTION_PRE_SUCCESS]: (state, action) => ({
        ...state,
        credentialsGroups: action.credentialsGroups,
        fetching: false,
    }),
    [types.CANCEL_TRANSACTION_PRE_ERROR]: (state) => toggleParamsFromTrue(state, "fetching", "isCancellingTransaction"),
    [types.CANCEL_TRANSACTION_SUCCESS]: (state) => toggleParamsFromTrue(state, "isCancellingTransaction"),
    [types.SAVE_DRAFT_REQUEST]: (state) => toggleParamsFromFalse(state, "fetching"),
    [types.SAVE_DRAFT_SUCCESS]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.SAVE_DRAFT_FAILURE]: (state) => toggleParamsFromTrue(state, "fetching"),
    [types.SEND_FORM_DATA_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        mode: "edit",
    }),
    [types.CLOSE_TRANSACTION_PREVIEW]: (state) => ({
        ...state,
        mode: state.prevMode,
    }),
    [types.READ_TRANSACTION_FROM_BACKOFFICE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        transaction: action.transaction,
        id: action.idForm,
        data: action.transaction.data,
        metadata: action.formMetadata,
        mode: "view",
    }),
    [types.DOWNLOAD_TICKET_REQUEST]: (state) => ({
        ...state,
        fetchingDownloadTicket: true,
    }),
    [types.DOWNLOAD_TICKET_FAILURE]: (state) => ({
        ...state,
        fetchingDownloadTicket: false,
    }),
    [types.DOWNLOAD_TICKET_SUCCESS]: (state) => ({
        ...state,
        fetchingDownloadTicket: false,
    }),
    [types.CANCEL_TRANSACTION_DATA]: (state, action) => ({
        ...state,
        cancelData: action.cancelData,
    }),
    [types.SIGN_TRANSACTION_DATA]: (state, action) => ({
        ...state,
        signData: action.signData,
    }),
    [types.SIGN_TRANSACTION_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.SEND_FORM_CREDENTIAL_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.SIGN_TRANSACTION_SET_RESULT]: (state, action) => ({
        ...state,
        isSuccessSign: action.isSuccessSign,
    }),
    [types.UPDATE_SCHEDULER_TRANSACTION_REQUEST]: (state) => ({
        ...state,
    }),
    [types.UPDATE_SCHEDULER_SIGN_TRANSACTION_REQUEST]: (state) => ({
        ...state,
        fetching: true
    }),
});

export const actions = {
    previewForm: makeActionCreator(types.PREVIEW_FORM_REQUEST),
    cancelTransactionData: makeActionCreator(types.CANCEL_TRANSACTION_DATA, "cancelData"),
    signTransactionData: makeActionCreator(types.SIGN_TRANSACTION_DATA, "signData"),
    sendForm: makeActionCreator(types.SEND_FORM_REQUEST),
    saveDraft: makeActionCreator(types.SAVE_DRAFT_REQUEST),
    cancelTransactionPre: makeActionCreator(types.CANCEL_TRANSACTION_PRE_REQUEST),
    cancelTransaction: makeActionCreator(types.CANCEL_TRANSACTION_REQUEST),
    modifyTransaction: makeActionCreator(types.MODIFY_TRANSACTION_REQUEST, "idTransaction"),
    signTransactionPreview: makeActionCreator(types.SIGN_TRANSACTION_PREVIEW_REQUEST),
    signTransaction: makeActionCreator(types.SIGN_TRANSACTION_REQUEST),
    closeConfirmation: makeActionCreator(types.CLOSE_TRANSACTION_PREVIEW),
    setData: makeActionCreator(types.SET_DATA),
    readTransaction: makeActionCreator(types.READ_TRANSACTION_REQUEST),
    formClosed: makeActionCreator(types.FORM_CLOSED),
    downloadTicket: makeActionCreator(types.DOWNLOAD_TICKET_REQUEST, "idTicket", "format", "idForm"),
    shareTicket: makeActionCreator(types.SHARE_TICKET, "idTicket", "format", "idForm"),
    updateScheduledTransactionRequest: makeActionCreator(types.UPDATE_SCHEDULER_TRANSACTION_REQUEST),
    updateScheduledAndSignTransactionRequest: makeActionCreator(types.UPDATE_SCHEDULER_SIGN_TRANSACTION_REQUEST),
};

export default (state = INITIAL_STATE, action) => ({
    ...formReducer(state, action),
    transactionLines: transactionLinesReducer(state.transactionLines, action),
});

export const selectors = {
    getId: ({ form }) => form.id,
    getFetching: ({ form }) => form.fetching,
    isFetchingDownloadTicket: ({ form }) => form.fetchingDownloadTicket,
    getMetadata: ({ form }) => form.metadata,
    getCancelTransactionData: ({ form }) => form.cancelData,
    getSignTransactionData: ({ form }) => form.signData,
    getCredentialsGroups: ({ form }) => form.credentialsGroups,
    getTransaction: ({ form }) => form.transaction,
    getChildrenTransactions: ({ form }) => form.childrenTransactions,
    getParentTransaction: ({ form }) => form.parentTransaction,
    getTransactionFormMetadata: ({ form }) => form.metadata,
    getData: ({ form }) => form.data,
    getValues: ({ form }) => form.values,
    getName: ({ form }) => form.name,
    getSubmitAction: ({ form }) => form.submitAction,
    getSubmitActionParams: ({ form }) => form.submitActionParams,
    getMode: ({ form }) => form.mode,
    getPreviewData: ({ form }) => {
        if (form.previewData.notificationMails && form.previewData.notificationMails.length > 0) {
            const tags = [];
            form.previewData.notificationMails.forEach((item) => {
                tags.push({ id: item, text: item });
            });
            return { ...form.previewData, notificationMails: tags };
        }
        return form.previewData;
    },
    getField: ({ form }, id) => form.metadata.fieldList.find(({ idField }) => idField === id),
    getIsCancellingTransaction: ({ form }) => form.isCancellingTransaction,
    getIsSuccessSign: ({ form }) => form.isSuccessSign,
};
