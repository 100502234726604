import React, { Component } from "react";
import classNames from "classnames";
import { shape, number, node, bool, func } from "prop-types";

import Image from "pages/_components/Image";

class PointsOfInterestListItem extends Component {
    static propTypes = {
        children: node.isRequired,
        isActive: bool,
        data: shape({
            idPoi: number,
            idPoiType: number,
        }).isRequired,
        onClick: func.isRequired,
    };

    static defaultProps = {
        isActive: false,
    };

    static Image = ({ imagePath }) => (
        <div className="table-data table-data-icon">
            <Image src={imagePath} className="svg-icon" />
        </div>
    );

    static Content = ({ children }) => <div className="table-data">{children}</div>;

    render() {
        const { data, children, onClick, isActive } = this.props;

        return (
            <li className="list-item--poi" key={data.idPoi}>
                <button
                    type="button"
                    className={classNames("table-row", {
                        isActive,
                    })}
                    id={data.idPoi}
                    onClick={onClick}>
                    {children}
                </button>
            </li>
        );
    }
}

export default PointsOfInterestListItem;
