import React, { Component } from "react";
import { routerActions } from "react-router-redux";
import { connect } from "react-redux";
import { func, shape, string, arrayOf, bool } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import moment from "moment";
import * as i18nUtils from "util/i18n";
import { statusTagColor, echeqShape, echeqConceptLabel } from "util/checks";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import { selectors as checkSelectors, actions as checkActions } from "reducers/checks";
import InfoTag from "pages/_components/InfoTag";
import MainContainer from "pages/_components/MainContainer";
import Heading from "pages/_components/Heading";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Accordion from "pages/_components/Accordion";
import I18n from "pages/_components/I18n";
import TableHeader from "pages/_components/table/Header";
import TableHeaderData from "pages/_components/table/HeaderData";
import TableBody from "pages/_components/table/Body";
import Table from "pages/_components/Table";
import PageLoading from "pages/_components/PageLoading";
import DrawerModal from "pages/_components/drawer/DrawerModal";
import { receivedEcheqAction } from "pages/checks/Echeqs/EcheqActions/EcheqsActionMaker";
import EmittedContextMenu from "pages/checks/Echeqs/EmittedContextMenu";
import EcheqViewEmitted from "./Preview/EcheqViewEmitted";

class EmittedEcheqsDetail extends Component {
    static propTypes = {
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }).isRequired,
        dispatch: func.isRequired,
        echeqDetail: arrayOf(echeqShape).isRequired,
        isDesktop: bool.isRequired,
        fetching: bool,
        isSmallDesktop: bool.isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    state = {
        isMoreDataSelected: false,
        isExpandAllSelected: false,
        numberToExpand: "",
        showDrawer: false,
        contentDrawer: "",
    };

    componentDidMount() {
        const {
            dispatch,
            match: {
                params: { echeqId },
            },
        } = this.props;

        dispatch(checkActions.requestEcheqDetail(echeqId));
    }

    getCheckDetailTitle = () => {
        const { echeqDetail } = this.props;
        const name = `${i18nUtils.get("checks.received.detail.header.label")} ${
            echeqDetail.cheque_numero
        }`.toUpperCase();

        if (!echeqDetail) {
            return <></>;
        }

        return (
            <>
                <div className="view-title data-wrapper-flex">
                    <h1 className="my-0 mr-3">{name}</h1>
                    <InfoTag
                        type="info"
                        message={echeqDetail.estado}
                        tagBackground={statusTagColor(echeqDetail.estado)}
                        tagClass="px-2"
                        moreStyles={{
                            color: "black",
                        }}
                    />
                </div>
            </>
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(routerActions.push("/echeqs"));
    };

    renderPageHeader = () => (
        <>
            <Notification scopeToShow="echeqs" />
            <div className="admin-detail-head px-0">
                <Head onBack={this.handleBack} />
                <Head
                    hasInlineButtons
                    centerElement={this.getCheckDetailTitle}
                    rightContent={this.rightContent}
                    centerContentClassName="p-0"
                />
            </div>
        </>
    );

    rightContentMobile = () => {
        const { isDesktop, echeqDetail } = this.props;

        return <EmittedContextMenu echeq={echeqDetail} isDesktop={isDesktop} detailActions />;
    };

    renderPageHeaderMobile = (echeqDetail) => (
        <>
            <Notification scopeToShow="echeqs" />
            <Head
                headerClassName="blue-main-header-mobile"
                onBack={this.handleBack}
                centerElement={() => (
                    <h1 className="title-content header-mobile-title-background-blue mb-0 w-100">
                        {`${i18nUtils.get("checks.received.detail.header.label")} ${
                            echeqDetail.cheque_numero
                        }`.toUpperCase()}
                    </h1>
                )}
                rightContent={this.rightContentMobile}
            />
        </>
    );

    handleMoreData = () => {
        const { isMoreDataSelected } = this.state;

        this.setState((prevState) => ({
            ...prevState,
            isMoreDataSelected: !isMoreDataSelected,
        }));
    };

    handleExpandAll = () => {
        const { isExpandAllSelected } = this.state;
        this.setState((prevState) => ({
            ...prevState,
            isExpandAllSelected: !isExpandAllSelected,
        }));
    };

    handleExpandEndorsement = () => {
        this.setState((prevState) => ({
            ...prevState,
            numberToExpand: prevState.numberToExpand === "3" ? null : "3",
        }));
    };

    renderMoreInfo = () => {
        const { echeqDetail } = this.props;

        return (
            <>
                <Container
                    className=" flex-grow container-white with-margin with-padding"
                    style={{ textTransform: "uppercase" }}>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.emitterCbu.label"
                            data={echeqDetail.emisor_cbu || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.postalCode.label"
                            data={echeqDetail.emisor_cp || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.branchName.label"
                            data={echeqDetail.emisor_sucursal_nombre || <span> - </span>}
                        />
                    </Col>

                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.emitterAccount.label"
                            data={echeqDetail.emisor_cuenta || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.entityCode.label"
                            data={echeqDetail.emisor_banco_codigo || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.address.label"
                            data={echeqDetail.emisor_sucursal_domicilio || <span> - </span>}
                        />
                    </Col>

                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.subAccount.label"
                            data={echeqDetail.emisor_subcuenta || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.entityName.label"
                            data={echeqDetail.emisor_banco_nombre || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.postalCode.label"
                            data={echeqDetail.emisor_sucursal_cp || <span> - </span>}
                        />
                    </Col>

                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.emitterAddress.label"
                            data={echeqDetail.emisor_domicilio || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.branchCode.label"
                            data={echeqDetail.emisor_sucursal_codigo || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.province.label"
                            data={echeqDetail.emisor_sucursal_provincia || <span> - </span>}
                        />
                    </Col>
                </Container>
                <Container
                    className=" flex-grow container-white with-margin with-padding"
                    style={{ textTransform: "uppercase" }}>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.checkbookNumber.label"
                            data={echeqDetail.numero_chequera || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.concept.label"
                            data={
                                <span>
                                    {echeqDetail.cheque_concepto.toUpperCase()}{" "}
                                    {echeqConceptLabel(echeqDetail.cheque_concepto, "-")}
                                </span>
                            }
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            data={
                                echeqDetail.nro_lote ? (
                                    <div style={{ display: "flex" }}>
                                        <span className="data-label">
                                            {i18nUtils.get("checks.received.detail.lotid.label")}
                                        </span>
                                        <span style={{ marginLeft: "0.5rem" }}>{echeqDetail.nro_lote}</span>
                                        <span>{echeqDetail.nro_lote}</span>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex" }}>
                                        <span className="data-label">
                                            {i18nUtils.get("checks.received.detail.lotid.label")}
                                        </span>
                                        <span style={{ marginLeft: "0.5rem" }}> - </span>
                                        <span>{echeqDetail.nro_lote}</span>
                                    </div>
                                )
                            }
                        />
                    </Col>

                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.checkId.label"
                            data={echeqDetail.cheque_id || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.payReason.label"
                            data={echeqDetail.cheque_motivo_pago || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.arrangedEcheq.label"
                            data={
                                echeqDetail.cheque_acordado ? (
                                    <span> {i18nUtils.get("global.yes")} </span>
                                ) : (
                                    <span> {i18nUtils.get("global.no")} </span>
                                )
                            }
                        />
                    </Col>

                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="echeqs.cheque_tipo"
                            data={
                                i18nUtils.get(`echeqs.emit.${echeqDetail.cheque_tipo.toLowerCase()}.label`) || (
                                    <span> - </span>
                                )
                            }
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="frequentDestinations.reference.title"
                            data={echeqDetail.referencia || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.requestAgreement.label"
                            data={
                                echeqDetail.solicitando_acuerdo ? (
                                    <span> {i18nUtils.get("global.yes")} </span>
                                ) : (
                                    <span> {i18nUtils.get("global.no")} </span>
                                )
                            }
                        />
                    </Col>

                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.kind.label"
                            data={echeqDetail.cheque_caracter || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.referenceValue.label"
                            data={echeqDetail.valorReferencia || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.presentAgain.label"
                            data={
                                echeqDetail.re_presentar ? (
                                    <span> {i18nUtils.get("global.yes")} </span>
                                ) : (
                                    <span> {i18nUtils.get("global.no")} </span>
                                )
                            }
                        />
                    </Col>

                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.mode.label"
                            data={
                                <span>{i18nUtils.get(`checks.received.detail.mode.${echeqDetail.cheque_modo}`)}</span>
                            }
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.grouperId.label"
                            data={echeqDetail.agrupador_id || <span> - </span>}
                        />
                    </Col>
                    <Col md={6} lg={4} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.certificateEmitted.label"
                            data={
                                echeqDetail.certificado_emitido ? (
                                    <span> {i18nUtils.get("global.yes")} </span>
                                ) : (
                                    <span> {i18nUtils.get("global.no")} </span>
                                )
                            }
                        />
                    </Col>
                </Container>
                <Container
                    className=" flex-grow container-white with-margin with-padding"
                    style={{ textTransform: "uppercase" }}>
                    <Col md={12} lg={12} xs={12}>
                        <Heading.DataGroup
                            containerClassName="transfer-data d-flex"
                            labelClassName="data-label-medium"
                            label="checks.received.detail.depositCbu.label"
                            data={echeqDetail.cbu_deposito || <span> - </span>}
                        />
                    </Col>
                </Container>
            </>
        );
    };

    renderSignaturesContent = () => {
        const { echeqDetail } = this.props;

        if (!echeqDetail) {
            return <> </>;
        }
        const { firmantes } = echeqDetail;
        return (
            <Container className="flex-grow scrollable echeq-deteail-content-table">
                <Col className="col col-12 px-0">
                    <div
                        className="w-100"
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {firmantes.length > 0 ? (
                            <>
                                <Table>
                                    <TableHeader className="container-white">
                                        <TableHeaderData align="left">
                                            <I18n
                                                component="span"
                                                id="checks.received.detail.accordion.documentType.title"
                                            />
                                        </TableHeaderData>
                                        <TableHeaderData align="left">
                                            <I18n id="checks.received.detail.accordion.documentNumber.title" />
                                        </TableHeaderData>
                                        <TableHeaderData />
                                    </TableHeader>
                                    <TableBody>{this.signaturesBody()}</TableBody>
                                </Table>
                            </>
                        ) : (
                            <I18n
                                componentProps={{ className: "admin-content-center pt-4 pb-3" }}
                                id="checks.received.detail.accordion.noInfo"
                                component="div"
                            />
                        )}
                    </div>
                </Col>
            </Container>
        );
    };

    signaturesBody = () => {
        const {
            echeqDetail: { firmantes },
        } = this.props;

        return firmantes.map((firmante) => (
            <Table.Row className="container-white">
                <Table.Data align="left">
                    <span>{firmante.documento_tipo}</span>
                </Table.Data>
                <Table.Data align="left">
                    <span>{firmante.documento}</span>
                </Table.Data>
            </Table.Row>
        ));
    };

    renderSupportsContent = () => {
        const { echeqDetail } = this.props;

        if (!echeqDetail) {
            return <> </>;
        }
        const { avalistas } = echeqDetail;
        return (
            <Container className="flex-grow scrollable echeq-deteail-content-table">
                <Col className="col col-12 px-0">
                    <div
                        className="w-100"
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {avalistas.length > 0 ? (
                            <>
                                <hr
                                    style={{
                                        position: "absolute",
                                        top: "2.5rem",
                                        width: "100%",
                                        borderWidth: "0.20rem",
                                        zIndex: 100,
                                        borderColor: "#f9f9f9",
                                    }}
                                />
                                <Table>
                                    <TableHeader className="container-white">
                                        <TableHeaderData align="center">
                                            <I18n component="span" id="checks.received.detail.accordion.date.title" />
                                        </TableHeaderData>
                                        <TableHeaderData align="center">
                                            <I18n id="checks.received.detail.accordion.cuitSupporter.title" />
                                        </TableHeaderData>
                                        <TableHeaderData align="center">
                                            <I18n id="checks.received.detail.accordion.documentNumber.title" />
                                        </TableHeaderData>
                                        <TableHeaderData align="center">
                                            <I18n id="checks.received.detail.accordion.nameSupporter.title" />
                                        </TableHeaderData>
                                        <TableHeaderData align="center">
                                            <I18n id="checks.received.detail.kind.label" />
                                        </TableHeaderData>
                                        <TableHeaderData align="center">
                                            <I18n id="checks.received.detail.address.label" />
                                        </TableHeaderData>
                                        <TableHeaderData align="center">
                                            <I18n id="checks.received.detail.accordion.nameSupported.title" />
                                        </TableHeaderData>
                                        <TableHeaderData />
                                    </TableHeader>
                                    <TableBody>{this.supportsBody()}</TableBody>
                                </Table>
                            </>
                        ) : (
                            <I18n
                                componentProps={{ className: "admin-content-center pt-4 pb-3" }}
                                id="checks.received.detail.accordion.noInfo"
                                component="div"
                            />
                        )}
                    </div>
                </Col>
            </Container>
        );
    };

    supportsBody = () => {
        const {
            echeqDetail: { avalistas },
        } = this.props;

        return avalistas.map((avalista) => (
            <Table.Row className="container-white">
                <Table.Data align="center">
                    <span>{avalista.aval_fecha}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{avalista.aval_documento}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{avalista.aval_razon_social}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{avalista.aval_caracter}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{avalista.aval_domicilio}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{avalista.aval_importe_avalado}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{avalista.aval_sujeto_avalado}</span>
                </Table.Data>
            </Table.Row>
        ));
    };

    renderEndorsementsContent = () => {
        const { echeqDetail } = this.props;

        if (!echeqDetail) {
            return <> </>;
        }
        const { endosos } = echeqDetail;

        return (
            <Container className="flex-grow scrollable echeq-deteail-content-table">
                <Col className="col col-12 px-0">
                    <div
                        style={{ borderSpacing: "0px 2px", width: "100%" }}
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {endosos.filter((endoso) => endoso.emisor_documento !== endoso.benef_documento).length > 0 ? (
                            <>
                                <hr
                                    style={{
                                        position: "absolute",
                                        top: "2.5rem",
                                        width: "100%",
                                        borderWidth: "0.20rem",
                                        zIndex: 100,
                                        borderColor: "#f9f9f9",
                                    }}
                                />
                                <Table>
                                    <Table.Header className="container-white">
                                        <TableHeaderData>
                                            <I18n component="span" id="checks.received.detail.accordion.date.title" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n id="checks.received.detail.accordion.endorsementType.title" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n
                                                component="span"
                                                id="checks.received.detail.accordion.cuitReceiver.title"
                                            />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n id="checks.received.detail.accordion.cuitSender.title" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n id="checks.received.detail.accordion.endorsementState.title" />
                                        </TableHeaderData>
                                        <Table.HeaderData width="1rem" />
                                    </Table.Header>
                                    <Table.Body>{this.endorsementsBody()}</Table.Body>
                                </Table>
                            </>
                        ) : (
                            <I18n
                                componentProps={{ className: "admin-content-center pt-4 pb-3" }}
                                id="checks.received.detail.accordion.noInfo"
                                component="div"
                            />
                        )}
                    </div>
                </Col>
            </Container>
        );
    };

    endorsementsBody = () => {
        const {
            echeqDetail: { endosos },
        } = this.props;

        return endosos.map((endoso) => (
            <Table.Row className="container-white">
                <Table.Data align="center">
                    <span>{moment(endosos.fecha_hora).format("DD/MM/YYYY HH:mm")}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{endoso.tipo_endoso}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{endoso.benef_documento}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{endoso.emisor_documento}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{endoso.estado_endoso}</span>
                </Table.Data>
            </Table.Row>
        ));
    };

    renderRejectionsContent = () => {
        const { echeqDetail } = this.props;

        if (!echeqDetail) {
            return <> </>;
        }
        const { rechazos } = echeqDetail;

        return (
            <Container className="flex-grow scrollable echeq-deteail-content-table">
                <Col className="col col-12 px-0">
                    <div
                        style={{ borderSpacing: "0px 2px", width: "100%" }}
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {rechazos.length > 0 ? (
                            <>
                                <hr
                                    style={{
                                        position: "absolute",
                                        top: "2.5rem",
                                        width: "100%",
                                        borderWidth: "0.20rem",
                                        zIndex: 100,
                                        borderColor: "#f9f9f9",
                                    }}
                                />
                                <Table>
                                    <Table.Header className="container-white">
                                        <TableHeaderData>
                                            <I18n component="span" id="checks.received.detail.accordion.date.title" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n component="span" id="checks.received.detail.accordion.code" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n id="checks.received.detail.accordion.reason" />
                                        </TableHeaderData>
                                    </Table.Header>
                                    <Table.Body>{this.rejectionsBody()}</Table.Body>
                                </Table>
                            </>
                        ) : (
                            <I18n
                                componentProps={{ className: "admin-content-center pt-4 pb-3" }}
                                id="checks.received.detail.accordion.noInfo"
                                component="div"
                            />
                        )}
                    </div>
                </Col>
            </Container>
        );
    };

    rejectionsBody = () => {
        const {
            echeqDetail: { rechazos },
        } = this.props;

        return rechazos.map((rechazo) => (
            <Table.Row className="container-white">
                <Table.Data align="center">
                    <span>{moment(rechazos.fecha_hora).format("DD/MM/YYYY HH:mm:ss")}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{rechazo.codigo_rechazo}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{rechazo.motivo_rechazo}</span>
                </Table.Data>
            </Table.Row>
        ));
    };

    renderCessionsContent = () => {
        const { echeqDetail } = this.props;

        if (!echeqDetail) {
            return <> </>;
        }
        const { cesiones } = echeqDetail;

        return (
            <Container className="flex-grow scrollable echeq-deteail-content-table">
                <Col className="col col-12 px-0">
                    <div
                        style={{ borderSpacing: "0px 2px", width: "100%" }}
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {cesiones.length > 0 ? (
                            <>
                                <hr
                                    style={{
                                        position: "absolute",
                                        top: "2.5rem",
                                        width: "100%",
                                        borderWidth: "0.20rem",
                                        zIndex: 100,
                                        borderColor: "#f9f9f9",
                                    }}
                                />
                                <Table>
                                    <Table.Header className="container-white">
                                        <TableHeaderData>
                                            <I18n component="span" id="checks.received.detail.accordion.cessionId" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n id="checks.received.detail.accordion.cessionState" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n component="span" id="checks.received.detail.accordion.cuitCession" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n id="checks.received.detail.accordion.receiverCession" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n id="checks.received.detail.accordion.documentCession" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n id="checks.received.detail.address.label" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n id="checks.received.detail.accordion.rejectionReasonCession" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n id="checks.received.detail.accordion.dateCession" />
                                        </TableHeaderData>
                                        <TableHeaderData>
                                            <I18n id="checks.received.detail.accordion.modificationCession" />
                                        </TableHeaderData>
                                        <Table.HeaderData width="1rem" />
                                    </Table.Header>
                                    <Table.Body>{this.cessionsBody()}</Table.Body>
                                </Table>
                            </>
                        ) : (
                            <I18n
                                componentProps={{ className: "admin-content-center pt-4 pb-3" }}
                                id="checks.received.detail.accordion.noInfo"
                                component="div"
                            />
                        )}
                    </div>
                </Col>
            </Container>
        );
    };

    cessionsBody = () => {
        const {
            echeqDetail: { cesiones },
        } = this.props;

        return cesiones.map((cesion) => (
            <Table.Row className="container-white">
                <Table.Data align="center">
                    <span>{cesion.cesion_id}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{cesion.estado_cesion}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{cesion.cedente_documento}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{cesion.cesionario_nombre}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{cesion.cesionario_documento}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{cesion.cesionario_domicilio}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{cesion.cesion_motivo_repudio}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{moment(cesion.fecha_emision_cesion).format("DD/MM/YYYY HH:mm:ss")}</span>
                </Table.Data>
                <Table.Data align="center">
                    <span>{moment(cesion.fecha_ult_modificacion_cesion).format("DD/MM/YYYY HH:mm:ss")}</span>
                </Table.Data>
            </Table.Row>
        ));
    };

    renderAccordion = () => {
        const { isExpandAllSelected, numberToExpand } = this.state;
        const componentProps = {
            className: "w-100 text-left font-light f-size-25",
        };
        return (
            <Accordion expandAll={isExpandAllSelected} numberToExpand={numberToExpand}>
                <Accordion.Item
                    number="1"
                    item={
                        <I18n
                            id="checks.received.detail.accordion.signatures.title"
                            component="p"
                            componentProps={componentProps}
                        />
                    }
                    fitContent
                    navigationListItemClassName="my-2 pl-0"
                    buttonClassName="py-2 px-3">
                    <div>{this.renderSignaturesContent()}</div>
                </Accordion.Item>
                <Accordion.Item
                    number="2"
                    item={
                        <I18n
                            id="checks.received.detail.accordion.supports.title"
                            component="p"
                            componentProps={componentProps}
                        />
                    }
                    fitContent
                    navigationListItemClassName="my-2 pl-0"
                    buttonClassName="py-2 px-3">
                    <div>{this.renderSupportsContent()}</div>
                </Accordion.Item>
                <Accordion.Item
                    number="3"
                    toggleAccordion={this.handleExpandEndorsement}
                    item={
                        <I18n
                            id="checks.received.detail.accordion.endorsements.title"
                            component="p"
                            componentProps={componentProps}
                        />
                    }
                    fitContent
                    navigationListItemClassName="my-2 pl-0"
                    buttonClassName="py-2 px-3">
                    <div>{this.renderEndorsementsContent()}</div>
                </Accordion.Item>
                <Accordion.Item
                    number="4"
                    item={
                        <I18n
                            id="checks.received.detail.accordion.rejections.title"
                            component="p"
                            componentProps={componentProps}
                        />
                    }
                    navigationListItemClassName="my-2 pl-0"
                    buttonClassName="py-2 px-3">
                    <div>{this.renderRejectionsContent()}</div>{" "}
                </Accordion.Item>
                <Accordion.Item
                    number="5"
                    item={
                        <I18n
                            id="checks.received.detail.accordion.cessions.title"
                            component="p"
                            componentProps={componentProps}
                        />
                    }
                    fitContent
                    navigationListItemClassName="my-2 pl-0"
                    buttonClassName="py-2 px-3">
                    <div className="c">{this.renderCessionsContent()}</div>
                </Accordion.Item>
            </Accordion>
        );
    };

    rightContent = () => {
        const { echeqDetail, dispatch, isDesktop, isSmallDesktop } = this.props;

        return (
            <>
                {echeqDetail.estado === "RECHAZADO" &&
                    !echeqDetail.certificado_emitido &&
                    !echeqDetail.cesion_pendiente &&
                    !echeqDetail.re_presentar &&
                    !echeqDetail.cheque_acordado &&
                    !echeqDetail.solicitando_acuerdo && (
                        <Button
                            className="btn-outline cmf-button-width"
                            block
                            onClick={() =>
                                receivedEcheqAction("REQUEST-CERTIFICATE", echeqDetail, dispatch, (newState) =>
                                    this.setState({ ...newState }),
                                )
                            }
                            label="echeq.request.certificate.echeq"
                        />
                    )}
                <button
                    type="button"
                    className="btn btn-link btn-form"
                    style={{ padding: 0, margin: 0 }}
                    onClick={(e) => e.stopPropagation()}>
                    <EmittedContextMenu
                        detailActions
                        echeq={echeqDetail}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                    />
                </button>
            </>
        );
    };

    renderContent = () => {
        const { echeqDetail, isDesktop } = this.props;
        const { isMoreDataSelected, isExpandAllSelected, showDrawer, contentDrawer } = this.state;

        if (!echeqDetail) {
            return <></>;
        }
        const emittedDay = moment(echeqDetail.fecha_emision).format("DD");
        const emittedMonth = i18nUtils.get(`checks.month.${moment(echeqDetail.fecha_emision).format("M")}`);
        const emittedYear = moment(echeqDetail.fecha_emision).format("YYYY");
        const payDay = moment(echeqDetail.fecha_pago).format("DD");
        const payMonth = i18nUtils.get(`checks.month.${moment(echeqDetail.fecha_pago).format("M")}`);
        const payYear = moment(echeqDetail.fecha_pago).format("YYYY");
        const firstLine = `${echeqDetail.beneficiario_nombre.replaceAll(
            "#",
            "Ñ",
        )} - ${echeqDetail.beneficiario_documento_tipo.toUpperCase()} ${echeqDetail.beneficiario_documento}`;
        const echeqNumber = echeqDetail.cheque_numero;
        const echeqStatus = echeqDetail.estado;
        const companyData = `${echeqDetail.emisor_razon_social.replaceAll("#", "Ñ")} CUIT ${echeqDetail.emisor_cuit}`;
        const amount = `${i18nUtils.get(`currency.label.${echeqDetail.emisor_moneda}`)} ${new Intl.NumberFormat(
            "de-DE",
            { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        ).format(echeqDetail.monto)}`;
        const echeqCharacter = echeqDetail.cheque_caracter.toUpperCase();
        const cmc7Number = echeqDetail.cmc7;
        const emitterBankName = echeqDetail.emisor_banco_nombre;

        return (
            <>
                {this.renderPageHeader()}
                <MainContainer>
                    {showDrawer && (
                        <DrawerModal
                            content={contentDrawer}
                            width={isDesktop ? "600px" : "100vw"}
                            isDesktop={isDesktop}
                            showDrawerRemote={showDrawer}
                            closeRemotely={() => this.setState({ showDrawer: false, contentDrawer: "" })}
                        />
                    )}
                    <div className="above-the-fold">
                        <Container className="align-items-center flex-grow">
                            <div className="p-relative">
                                <EcheqViewEmitted
                                    day={emittedDay}
                                    month={emittedMonth}
                                    year={emittedYear}
                                    payDay={payDay}
                                    payMonth={payMonth}
                                    payYear={payYear}
                                    firstLine={firstLine}
                                    echeqNro={echeqNumber}
                                    echeqStatus={echeqStatus}
                                    echeqStatusColor={statusTagColor(echeqDetail.estado)}
                                    companyData={companyData}
                                    amount={amount}
                                    order={echeqCharacter}
                                    largeNumber={cmc7Number}
                                    bankName={emitterBankName}
                                />
                                {echeqDetail.endosos.length !== 0 && (
                                    <Button
                                        className="no-shadow echeq-btn-endosos"
                                        bsStyle="link"
                                        label="checks.emitted.detail.endorsement.button"
                                        type="button"
                                        onClick={this.handleExpandEndorsement}
                                    />
                                )}
                            </div>
                        </Container>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            {isMoreDataSelected ? (
                                <Button
                                    bsStyle="link"
                                    label="checks.received.detail.lessData.label"
                                    type="button"
                                    style={{ width: "11rem", fontSize: "0.9375rem" }}
                                    onClick={this.handleMoreData}
                                />
                            ) : (
                                <Button
                                    bsStyle="link"
                                    label="checks.received.detail.moreData.label"
                                    type="button"
                                    style={{ width: "11rem", fontSize: "0.9375rem" }}
                                    onClick={this.handleMoreData}
                                />
                            )}
                        </div>
                        {isMoreDataSelected && this.renderMoreInfo()}
                        {this.renderAccordion()}
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            {isExpandAllSelected ? (
                                <Button
                                    bsStyle="link"
                                    label="checks.received.detail.contractAll.label"
                                    type="button"
                                    style={{ width: "11rem", fontSize: "0.9375rem" }}
                                    onClick={this.handleExpandAll}
                                />
                            ) : (
                                <Button
                                    bsStyle="link"
                                    label="checks.received.detail.expandAll.label"
                                    type="button"
                                    style={{ width: "11rem", fontSize: "0.9375rem" }}
                                    onClick={this.handleExpandAll}
                                />
                            )}
                        </div>
                    </div>
                </MainContainer>
            </>
        );
    };

    renderContentMobile = () => {
        const { echeqDetail } = this.props;

        if (!echeqDetail) {
            return <></>;
        }
        const emittedDay = moment(echeqDetail.fecha_emision).format("DD");
        const emittedMonth = i18nUtils.get(`checks.month.${moment(echeqDetail.fecha_emision).format("M")}`);
        const emittedYear = moment(echeqDetail.fecha_emision).format("YYYY");
        const payDay = moment(echeqDetail.fecha_pago).format("DD");
        const payMonth = i18nUtils.get(`checks.month.${moment(echeqDetail.fecha_pago).format("M")}`);
        const payYear = moment(echeqDetail.fecha_emision).format("YYYY");
        const firstLine = `${
            echeqDetail.beneficiario_nombre
        } - ${echeqDetail.beneficiario_documento_tipo.toUpperCase()} ${echeqDetail.beneficiario_documento}`;
        const echeqNumber = echeqDetail.cheque_numero;
        const echeqStatus = echeqDetail.estado;
        const companyData = `${echeqDetail.emisor_razon_social} CUIT ${echeqDetail.emisor_cuit}`;
        const amount = `${i18nUtils.get(`currency.label.${echeqDetail.emisor_moneda}`)} ${new Intl.NumberFormat(
            "de-DE",
            { minimumFractionDigits: 2, maximumFractionDigits: 2 },
        ).format(echeqDetail.monto)}`;
        const echeqCharacter = echeqDetail.cheque_caracter.toUpperCase();
        const cmc7Number = echeqDetail.cmc7;
        const emitterBankName = echeqDetail.emisor_banco_nombre;

        return (
            <>
                {this.renderPageHeaderMobile(echeqDetail)}
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="align-items-center flex-grow" rowClassName="content-echeq-mobile">
                            <div className="echeq-view-mobile">
                                <EcheqViewEmitted
                                    day={emittedDay}
                                    month={emittedMonth}
                                    year={emittedYear}
                                    payDay={payDay}
                                    payMonth={payMonth}
                                    payYear={payYear}
                                    firstLine={firstLine}
                                    echeqNro={echeqNumber}
                                    echeqStatus={echeqStatus}
                                    echeqStatusColor={statusTagColor(echeqDetail.estado)}
                                    companyData={companyData}
                                    amount={amount}
                                    order={echeqCharacter}
                                    largeNumber={cmc7Number}
                                    bankName={emitterBankName}
                                />
                            </div>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    };

    render() {
        const { fetching, isDesktop } = this.props;

        return (
            <PageLoading loading={fetching}>
                {isDesktop ? this.renderContent() : this.renderContentMobile()}
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    echeqsList: checkSelectors.getEcheqsList(state),
    fetching: checkSelectors.getFetching(state),
    echeqDetail: checkSelectors.getEcheqDetail(state),
});

export default connect(mapStateToProps)(EmittedEcheqsDetail);
