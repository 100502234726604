import React, { useCallback, useEffect, useState } from "react";
import { bool, func, shape, string, arrayOf, number, boolean } from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import classNames from "classnames";
import queryString from "query-string";

import PageLoading from "pages/_components/PageLoading";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import DrawerModal from "pages/_components/drawer/DrawerModal";
import Hint from "pages/_components/hints/Hint";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as fundcorpSelectors, actions as fundcorpActions } from "reducers/fundcorp";
import { selectors as accountsSelectors, actions as accountsActions } from "reducers/accounts";
import { selectors as holidaysSelector, actions as holidaysActions } from "reducers/holidays";
import { actions as transactionsActions } from "reducers/transactions";

import { toNumber } from "util/number";
import * as i18n from "util/i18n";
import * as config from "util/config";
import FundListDrawer from "./FundListDrawer";
import SubscribeForm from "./_subscribe/SubscribeForm";
import FundListModal from "./FundListModal";

const FORM_ID = "fund.operation.subscribe";
function FundSubscribe(props) {
    const {
        isDesktop,
        dispatch,
        fetching,
        accountsFetching,
        match: {
            params: { fondoNumero },
        },
        fundFeatures,
        accounts,
        fetchingHolidays,
        location,
        fundOrderedFeatures,
        selectedFundOrderedFeatures,
        loggedUser,
    } = props;

    const [modalVisible, setModalVisible] = useState(false);
    const [modalAssociateVisible, setModalAssociateVisible] = useState(false);
    const [modalRiskLevelVisible, setRiskLevelVisible] = useState(false);
    const [selectedFundFeature, setSelectedFundFeature] = useState();
    const [selectedShareAccount, setSelectedShareAccount] = useState();

    const orderFuncionality = config.getBoolean("backend.show.managementFunds.functionalities", false);

    useEffect(() => {
        dispatch(fundcorpActions.createSubscribePreRequest());
        dispatch(transactionsActions.saveTransactionRoute(location?.pathname));
    }, [dispatch, location.pathname]);

    useEffect(() => {
        const { query } = queryString.parseUrl(location.search);
        if ((!fundOrderedFeatures?.length && orderFuncionality) || (!fundFeatures?.length && !orderFuncionality)) {
            dispatch(fundcorpActions.listFundcorpPre("operations", query?.opt));
        }
    }, [fundOrderedFeatures, fundFeatures]);

    useEffect(() => {
        if (orderFuncionality) {
            if (selectedFundOrderedFeatures) {
                setSelectedFundFeature(selectedFundOrderedFeatures);
            } else if (fondoNumero && fundOrderedFeatures?.length) {
                const allFunds = fundOrderedFeatures.reduce(
                    (acc, cat) => [...acc, ...Object.values(cat)[0].founds],
                    [],
                );
                const selectedFund = allFunds.find(
                    (fundFeature) => Object.values(fundFeature)[0].numero === toNumber(fondoNumero),
                );
                if (selectedFund) {
                    setSelectedFundFeature(Object.values(selectedFund)[0]);
                } else {
                    dispatch(fundcorpActions.emptySelectedFundOrderedFeature());
                    setSelectedFundFeature(undefined);
                }
            } else {
                dispatch(fundcorpActions.emptySelectedFundOrderedFeature());
                setSelectedFundFeature(undefined);
            }
        } else if (fondoNumero && fundFeatures?.length && !selectedFundFeature) {
            setSelectedFundFeature(fundFeatures.find((fundFeature) => fundFeature.numero === toNumber(fondoNumero)));
        } else if (!selectedFundFeature) {
            setSelectedFundFeature(undefined);
        }
    }, [fondoNumero, fundFeatures, orderFuncionality, fundOrderedFeatures, selectedFundOrderedFeatures]);

    useEffect(() => {
        dispatch(fundcorpActions.resetSummary());
        dispatch(accountsActions.listAccounts(loggedUser.userId));
        dispatch(holidaysActions.listHolidays());
        if (orderFuncionality) {
            dispatch(fundcorpActions.emptySelectedFundOrderedFeature());
        }
        // eslint-disable-next-line
    }, []);

    const handleBack = () => {
        dispatch(push("/fundcorpOperations"));
    };

    const selectFundFeature = (fundFeature) => {
        dispatch(fundcorpActions.setSelectedFundOrderedFeature(fundFeature));
        setSelectedFundFeature(fundFeature);
        setModalVisible(false);
    };

    const deselectFundFeature = () => {
        setSelectedFundFeature(null);
    };

    const openModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const toggleModalAssociate = useCallback(
        (status) => {
            setModalAssociateVisible(status);
        },
        [setModalAssociateVisible],
    );

    const setShareaccount = (shareAccount) => {
        setSelectedShareAccount(shareAccount);
    };

    const toggleModalRiskLevel = (status) => {
        setRiskLevelVisible(status);
    };

    const renderHead = () => (
        <>
            <Head
                onBack={handleBack}
                headerClassName={classNames({
                    "blue-main-header-mobile": !isDesktop,
                })}
                centerContentClassName={classNames({
                    "mx-5": !isDesktop,
                })}
                title={!isDesktop && `${FORM_ID}.head.label`}
                titleClassName="text-white"
                accessibilityTextId={`${FORM_ID}.head.label`}
            />
            {isDesktop && (
                <div className="d-flex align-items-center">
                    <h1 className="mt-1 mb-3">{i18n.get(`${FORM_ID}.head.label`)}</h1>
                    {orderFuncionality ? (
                        <div className="fundcorp__suscribe-hint">
                            <Hint
                                defaultValue={config.get("subscription.fundAvailable.tooltip", "")}
                                classNameGroup="d-flex align-items-center w-100 ml-25 mb-2"
                                classNameHintP="m-0 ml-2"
                                alwaysShowMessage
                            />
                        </div>
                    ) : (
                        undefined
                    )}
                </div>
            )}
        </>
    );

    return (
        <>
            <Notification scopeToShow="subscribe" />
            <div
                className={classNames("admin-detail-head px-0", {
                    "container-white": orderFuncionality && !isDesktop,
                })}>
                {renderHead()}
                {!isDesktop && orderFuncionality ? (
                    <div className="fundcorp__suscribe-hint ml-3">
                        <Hint
                            defaultValue={config.get("subscription.fundAvailable.tooltip", "")}
                            classNameGroup="d-flex align-items-center w-100 mb-2"
                            classNameHintP="m-0 ml-2"
                            alwaysShowMessage
                        />
                    </div>
                ) : (
                    undefined
                )}
                {orderFuncionality ? (
                    <DrawerModal
                        content={
                            <FundListDrawer
                                isDesktop={isDesktop}
                                onSelect={selectFundFeature}
                                fundFeatures={fundOrderedFeatures}
                            />
                        }
                        width={isDesktop ? "40%" : "100vw"}
                        showDrawerRemote={modalVisible}
                        isDesktop={isDesktop}
                        navStyle="default pt-3"
                        title={`${!isDesktop ? "fundcorp.suscribe.drawer.button.select" : ""}`}
                        closeRemotely={closeModal}
                    />
                ) : (
                    <FundListModal
                        isDesktop={isDesktop}
                        isVisible={modalVisible}
                        closeModal={closeModal}
                        onRowClick={selectFundFeature}
                    />
                )}
            </div>
            <PageLoading loading={fetching || accountsFetching || fetchingHolidays}>
                <MainContainer>
                    <SubscribeForm
                        {...props}
                        isDesktop={isDesktop}
                        openModal={openModal}
                        selectedFundFeature={selectedFundFeature}
                        deselectFundFeature={deselectFundFeature}
                        toggleModalAssociate={toggleModalAssociate}
                        toggleModalRiskLevel={toggleModalRiskLevel}
                        modalRiskLevelVisible={modalRiskLevelVisible}
                        setShareaccount={setShareaccount}
                        dispatch={dispatch}
                        fetching={fetching}
                        accounts={accounts}
                        modalAssociateVisible={modalAssociateVisible}
                        selectedShareAccount={selectedShareAccount}
                    />
                </MainContainer>
            </PageLoading>
        </>
    );
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    shareAccounts: fundcorpSelectors.getShareAccounts(state),
    fetching: fundcorpSelectors.getFetching(state),
    accountsFetching: accountsSelectors.getFetching(state),
    fundFeatures: fundcorpSelectors.getFundFeatures(state),
    summary: fundcorpSelectors.getSummary(state),
    accounts: accountsSelectors.getAccounts(state),
    fetchingHolidays: holidaysSelector.isFetching(state),
    fundOrderedFeatures: fundcorpSelectors.getFundOrderedFeatures(state),
    selectedFundOrderedFeatures: fundcorpSelectors.getSelectedFundOrderedFeatures(state),
    loggedUser: sessionSelectors.getUser(state),
});

FundSubscribe.propTypes = {
    isDesktop: bool,
    dispatch: func.isRequired,
    fetching: bool,
    accountsFetching: bool,
    match: shape({
        params: shape({
            fondoNumero: string,
        }),
    }),
    fundFeatures: arrayOf(
        shape({
            numero: number,
            rescatePlazo: number,
            suscripcionPlazo: number,
            nombre: string,
            estaAnulado: boolean,
            horizonteInversionDescripcion: string,
            sociedadGerenteDescripcion: string,
            moneda: string,
            monedaDescripcion: string,
            regionDescripcion: string,
            title: string,
            importeMin: string,
            horaDesde: string,
            horaHasta: string,
        }),
    ).isRequired,
    accounts: arrayOf(shape({})).isRequired,
    fetchingHolidays: bool.isRequired,
    fundOrderedFeatures: arrayOf(shape({})),
    selectedFundOrderedFeatures: arrayOf(shape({})),
    location: arrayOf(shape({})).isRequired,
    loggedUser: shape({}).isRequired,
};

FundSubscribe.defaultProps = {
    isDesktop: true,
    fetching: false,
    accountsFetching: false,
    match: shape({
        params: shape({
            fondoNumero: "",
        }),
    }),
    fundOrderedFeatures: null,
    selectedFundOrderedFeatures: null,
};

export default connect(mapStateToProps)(FundSubscribe);
