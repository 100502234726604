import React, { Component } from "react";
import { string, bool } from "prop-types";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Row from "react-bootstrap/lib/Row";

export default class UserCancelled extends Component {
    static propTypes = {
        modificationDateTime: string.isRequired,
        userEditorFullName: string.isRequired,
        boldTitle: bool,
    };

    static defaultProps = {
        boldTitle: false,
    };

    render() {
        const { userEditorFullName, modificationDateTime, boldTitle } = this.props;

        return (
            <Row style={{ justifyContent: "space-between" }}>
                <FieldLabel labelKey="accounts.new.account.signatures.cancelled.label" bold={boldTitle} notMarginY />
                <p className="data-desc">
                    {userEditorFullName} - {modificationDateTime}
                </p>
            </Row>
        );
    }
}
