import React, { Component } from "react";
import { shape, string, number, func } from "prop-types";
import { connect } from "react-redux";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import ProgressBar from "react-bootstrap/lib/ProgressBar";
import { Spring, config } from "react-spring";

import { actions, selectors } from "reducers/formFields/multilineFileProcess";
import { actions as formActions } from "reducers/form";

import TransactionStatus from "pages/transactions/_components/TransactionStatus";
import I18n from "pages/_components/I18n";
import FormattedNumber from "pages/_components/FormattedNumber";

class SalaryPayment extends Component {
    static propTypes = {
        transaction: shape({
            idTransactionStatus: string.isRequired,
        }).isRequired,
        dispatch: func.isRequired,
        totalLines: number,
        failedLines: number,
        processedLines: number,
        pendingLines: number,
    };

    static defaultProps = {
        totalLines: 0,
        failedLines: 0,
        processedLines: 0,
        pendingLines: 0,
    };

    refreshInterval = null;

    componentDidMount() {
        const { pendingLines } = this.props;

        if (pendingLines > 0) {
            this.setRefreshInterval();
        }
    }

    componentDidUpdate(prevProps) {
        const { pendingLines, totalLines, dispatch, transaction } = this.props;

        if (totalLines === 0) {
            this.setRefreshInterval();
        }

        if (!pendingLines && prevProps.pendingLines !== pendingLines) {
            clearInterval(this.refreshInterval);
            dispatch(formActions.readTransaction({ pathname: `/transaction/${transaction.idTransaction}` }));
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    setRefreshInterval = () => {
        const { dispatch, transaction } = this.props;

        dispatch(actions.updateProcess({ transactionId: transaction.idTransaction }));

        this.refreshInterval = setInterval(() => {
            dispatch(actions.updateProcess({ transactionId: transaction.idTransaction }));
        }, 10000);
    };

    renderTitle = (status) => {
        if (status === "SCHEDULED") {
            return <I18n id="forms.inputFile.massivePayments.progress.title.pending" />;
        }

        if (status === "FINISHED") {
            return <I18n id="forms.inputFile.massivePayments.progress.title.finished" />;
        }

        return <I18n id="forms.inputFile.massivePayments.progress.title" />;
    };

    render() {
        const { transaction, totalLines, failedLines, processedLines } = this.props;
        const progress = ((processedLines - failedLines) * 100) / totalLines;
        const failedProgress = (failedLines * 100) / totalLines;

        return (
            <section className="container--layout align-items-center flex-grow">
                <Grid className="form-content">
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <div className="media-object ">
                                <figure>
                                    <TransactionStatus
                                        iconFidelity="ticket"
                                        idTransactionStatus={transaction.idTransactionStatus}
                                    />
                                </figure>
                                <div className="media-object--text">
                                    <p className="text-lead">
                                        <I18n
                                            id={`forms.transaction.ticket.status.${transaction.idTransactionStatus}`}
                                        />
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                            <h3 className="data-title">{this.renderTitle(transaction.idTransactionStatus)}</h3>
                            <div className="progress-wrapper">
                                <ProgressBar>
                                    <ProgressBar now={failedProgress} bsStyle="danger" />
                                    <ProgressBar now={progress} active={progress + failedProgress !== 100} />
                                </ProgressBar>
                                <Spring
                                    config={config.slow}
                                    from={{ width: "25%" }}
                                    to={{
                                        width: progress < 55 ? "25%" : "95%",
                                    }}
                                    immediate={processedLines === totalLines}>
                                    {(styles) => (
                                        <div
                                            style={{
                                                position: "absolute",
                                                color: progress >= 55 && "white",
                                                ...styles,
                                            }}>
                                            <FormattedNumber>{processedLines}</FormattedNumber>{" "}
                                            <I18n id="forms.inputFile.massivePayments.processed" />
                                        </div>
                                    )}
                                </Spring>
                            </div>
                            {failedLines > 0 && (
                                <div className="totalErrors">
                                    <FormattedNumber>{totalLines}</FormattedNumber>{" "}
                                    <I18n id="forms.inputFile.massivePayments.total.lines" /> /{" "}
                                    <span className="error">
                                        <FormattedNumber>{failedLines}</FormattedNumber>{" "}
                                        <I18n id="forms.inputFile.massivePayments.errors" />
                                    </span>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    processedLines: selectors.getProcessedLines(state),
    failedLines: selectors.getFailedLines(state),
    totalLines: selectors.getTotalLines(state),
    pendingLines: selectors.getPendingLines(state),
});

export default connect(mapStateToProps)(SalaryPayment);
