/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import I18n from "pages/_components/I18n";
import { selectors as transactionsSelectors } from "reducers/transactions";
import * as i18n from "util/i18n";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class AccountOpeningDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dataRow = () => {
        const { multipleSignaturesData, isDesktop } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;
        const tableLabel = "table.accountOpening";
        return selectedTransactionRows.map((object) => (
            <CardContainer className="confirmation__cards-container">
                <Card
                    className="confirmation__cards"
                    icon={isDesktop && "images/transfer-PENDING.svg"}
                    iconClass="card__pending-transaction-icon"
                    hrClass="d-none"
                    title={
                        isDesktop ? (
                            <div className="confirmation-cards__title">
                                <div className="confirmation-cards__title-data">
                                    <I18n id={`${tableLabel}.currency`} />
                                </div>
                                <div className="confirmation-cards__title-info">
                                    <span>
                                        {i18n.get(
                                            `accounts.new.account.currency.${object?.transaction?.data?.currency}`,
                                        )}
                                    </span>
                                </div>
                            </div>
                        ) : (
                            object?.transaction?.data?.accountType && (
                                <div className="confirmation-cards__title">
                                    <div className="confirmation-cards__title-data">
                                        <I18n id={`${tableLabel}.accountType`} />
                                    </div>
                                    <div className="confirmation-cards__title-info">
                                        <span>
                                            {i18n.get(
                                                `accounts.new.account.accountType.${object?.transaction?.data?.accountType}`,
                                            )}
                                        </span>
                                    </div>
                                </div>
                            )
                        )
                    }
                    content={
                        isDesktop ? (
                            <div className="d-flex align-items-center">
                                {object?.transaction?.data?.accountType && (
                                    <div
                                        className={
                                            object?.transaction?.data?.message
                                                ? "confirmation-cards__content pb-1"
                                                : "confirmation-cards__content"
                                        }>
                                        <div className="confirmation-cards__content-data">
                                            <I18n id={`${tableLabel}.accountType`} />
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>
                                                {i18n.get(
                                                    `accounts.new.account.accountType.${object?.transaction?.data?.accountType}`,
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {object?.transaction?.data?.message && (
                                    <div className="confirmation-cards__content info-type-a">
                                        <div className="confirmation-cards__content-data">
                                            <I18n id={`${tableLabel}.message`} />
                                        </div>
                                        <div className="confirmation-cards__content-info withEllipsis">
                                            <div className="confirmation-cards__with-ellipsis">
                                                {object?.transaction?.data?.message}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                {
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <I18n id={`${tableLabel}.currency`} />
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>
                                                {i18n.get(
                                                    `accounts.new.account.currency.${object?.transaction?.data?.currency}`,
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                }
                                {object?.transaction?.data?.message && (
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <I18n id={`${tableLabel}.message`} />
                                        </div>
                                        <div className="confirmation-cards__content-info withEllipsis">
                                            <div className="confirmation-cards__with-ellipsis">
                                                {object?.transaction?.data?.message}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {object.transaction.idTransaction.substring(0, 8) && (
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <I18n id="table.transferInternal.idTransaction" />
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span className="text-uppercase">
                                                {object.transaction.idTransaction.substring(0, 8)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    }
                    bottom={
                        isDesktop && (
                            <div className="d-flex align-items-center">
                                {object.transaction.idTransaction.substring(0, 8) && (
                                    <div className="confirmation-cards__bottom">
                                        <div className="confirmation-cards__bottom-data">
                                            <I18n id="table.transferInternal.idTransaction" />
                                        </div>
                                        <div className="confirmation-cards__bottom-info">
                                            <span className="text-uppercase">
                                                {object.transaction.idTransaction.substring(0, 8)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    }
                />
            </CardContainer>
        ));
    };

    render() {
        return this.dataRow();
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(AccountOpeningDataTable);
