import React, { Component } from "react";
import { arrayOf, bool, func, objectOf, oneOfType, shape, string } from "prop-types";

import SwitchField from "pages/_components/fields/SwitchField";

class FormikSwitchField extends Component {
    static propTypes = {
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: bool,
        }).isRequired,
        form: shape({
            touched: objectOf(oneOfType([arrayOf(bool), bool, objectOf(bool)])),
            errors: objectOf(oneOfType([string, objectOf(string)])),
        }).isRequired,
        isDisabled: bool,
    };

    static defaultProps = {
        isDisabled: false,
    };

    handleChange = ({ target }, { name }, { setFieldValue }) => {
        setFieldValue(name, target.value);
        const { onChange } = this.props;
        if (onChange) {
            onChange();
        }
    };

    render() {
        const { field, form, ...props } = this.props;

        return (
            <SwitchField
                {...field}
                {...props}
                onChange={(event) => this.handleChange(event, field, form)}
                error={form.touched[field.name] && form.errors[field.name]}
            />
        );
    }
}

export default FormikSwitchField;
