import React, { Component } from "react";
import { bool, shape, arrayOf } from "prop-types";

import FormattedDate from "pages/_components/FormattedDate";
import { Field } from "formik";
import { Modal } from "react-bootstrap";
import TransactionLinesList from "pages/forms/_components/_fields/_transactionlines/List";
import Heading from "pages/_components/Heading";
import Col from "react-bootstrap/lib/Col";
import Multiline from "pages/forms/_components/_fields/Multilinefile";

class TicketMultilineFiles extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        summary: shape({}).isRequired,
        processedFileData: shape({}).isRequired,
        itemsDetail: arrayOf(shape({})).isRequired,
        limitDate: shape({}).isRequired,
    };

    state = {
        showModal: false,
    };

    renderLimitDate = () => {
        const { limitDate } = this.props;

        return (
            <Col xs={12} className="px-0">
                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium"
                    dataClassName="data-desc"
                    label="payments.thirdPayment.limit.date.label"
                    data={<FormattedDate date={limitDate} dateFormat="dd/MM/yyyy" showTime />}
                />
            </Col>
        );
    };

    showModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { isDesktop, summary, itemsDetail } = this.props;
        const { showModal } = this.state;

        return (
            <>
                <Modal show={showModal} className="payment-modal">
                    <Modal.Body className="d-flex flex-column">
                        <TransactionLinesList detailPreview onClose={this.closeModal} lines={itemsDetail} />
                    </Modal.Body>
                </Modal>
                <div className="third-payment-multiline">
                    <Field
                        name="file"
                        component={Multiline}
                        type="multilinefile"
                        mode="preview"
                        formVersion="1"
                        sub_type="salaryPayment"
                        formTitle="salaryPayment"
                        allowMultiple="false"
                        acceptedFileTypes="text/plain"
                        useForTotalAmount="true"
                        maxFileSizeMB="4"
                        idField="file"
                        idForm="salaryPayment"
                        value={summary.file?.values}
                        detailButtonLabel="payments.payThirdParties.summary.buttonDetail.label"
                        isDesktop={isDesktop}
                        processedFileData={summary?.processedFileData}
                        pendingLines={summary?.pendingLines}
                        openModal={this.showModal}
                        additionalFields={this.renderLimitDate()}
                    />
                </div>
            </>
        );
    }
}

export default TicketMultilineFiles;
