/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, arrayOf, string, shape, bool } from "prop-types";
import Notification from "pages/_components/Notification";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import CreateFrequentDesitinationModal from "pages/forms/_components/CreateFrequentDesetinationModal";
import Yup from "yup";
import Selector from "pages/_components/fields/formik/Selector";
import TextField from "pages/_components/fields/TextField";
import { actions as notificationActions } from "reducers/notification";
import DrawerModal from "pages/_components/drawer/DrawerModal";
import AddBeneficiary from "pages/checks/Echeqs/EcheqActions/AddBeneficiary";
import EmisionModesHint from "pages/checks/Echeqs/EcheqActions/EmisionModesHint";
import { actions as checkActions, selectors as checkSelector } from "reducers/checks";
import { selectors as sessionSelectors } from "reducers/session";
import Productselector from "pages/forms/_components/_fields/Productselector";
import Date from "pages/_components/fields/DateField";
import moment from "moment";
import Checkbox from "pages/_components/Checkbox";
import AmountField from "pages/_components/fields/formik/AmountField";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import classNames from "classnames";
import { createEmisionEcheqList, getTotalAmountEcheqs } from "util/checks";
import EcheqToEmit from "pages/checks/Echeqs/EcheqActions/EcheqToEmit";
import SelectedItemsAmount from "pages/charges/_components/SelectedItemsAmount";
import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { saveLocation } from "util/transaction";

const FORM_ID = "echeqs.emit";

const characters = [
    { value: "A la orden", label: "A la orden" },
    { value: "No a la orden", label: "No a la orden" },
];

const emisionModes = [
    { value: "INDI", label: "INDIVIDUAL" },
    { value: "MULT", label: "MULTICHEQUE" },
    { value: "PERS", label: "PERSONALIZADO" },
];

class EmitEcheq extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        emitData: shape({
            beneficiary: shape({}),
        }).isRequired,
        values: shape({}).isRequired,
        setValues: func.isRequired,
        emissionData: string.isRequired,
        echeqsToEmit: arrayOf(
            shape({
                amount: string.isRequired,
                date: string.isRequired,
                reference: string.isRequired,
                referencevalue: string.isRequired,
                type: string.isRequired,
            }),
        ).isRequired,
        setErrors: func.isRequired,
        setFieldTouched: func.isRequired,
        concepts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        activeEnvironment: shape({
            permissions: shape({
                frequentDestination: bool,
            }),
        }).isRequired,
    };

    state = {
        frequentVisible: false,
        showDrawer: false,
        showHintDrawer: false,
        contentDrawer: "",
        drawerHeader: "",
    };

    componentDidMount() {
        const { values, setValues, emissionData } = this.props;
        if (!emissionData) {
            setValues({ ...values, amount: "", reference: "", referencevalue: "" });
        }
    }

    closeModal = () => {
        this.setState({ frequentVisible: false });
    };

    openModal = () => {
        const { emitData } = this.props;

        if (!emitData?.beneficiary?.reference) {
            this.setState({ frequentVisible: true });
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    cleanBeneficiary = () => {
        const { dispatch } = this.props;
        dispatch(checkActions.beneficiaryEcheq({}, "checks/EMIT_ECHEQ_BENEFICIARY"));
    };

    componentWillMount = () => {
        const { dispatch, loggedUser } = this.props;
        saveLocation(this.props);
        dispatch(transferActions.preActivity());
        dispatch(checkActions.fetchAccounts(loggedUser.userId));
    };

    selectBeneficiary = (beneficiary) => {
        const { setValues, values, dispatch } = this.props;
        setValues({
            ...values,
            email: beneficiary?.email,
            accountNumberBeneficiary: beneficiary?.accountNumber,
            cbuBeneficiary: beneficiary?.account,
        });
        dispatch(
            checkActions.beneficiaryEcheq(
                {
                    document:
                        beneficiary?.document ||
                        `${beneficiary?.recipientDocumentType} ${beneficiary?.recipientDocumentNumber}`,
                    reference: beneficiary?.headline || beneficiary?.recipientName,
                    email: beneficiary?.email,
                    accountNumberBeneficiary: beneficiary?.accountNumber,
                    cbuBeneficiary: beneficiary?.account,
                },
                "checks/EMIT_ECHEQ_BENEFICIARY",
            ),
        );

        this.setState({ frequentVisible: false });
    };

    closeDrawer = () => {
        this.setState({ showDrawer: false, showHintDrawer: false });
    };

    changeEcheqType = (date) => {
        const { values, setValues } = this.props;
        const today = moment().startOf("day");
        const selectedDate = moment(date).startOf("day");

        if (Math.abs(today.diff(selectedDate, "days")) > 0) {
            setValues({ ...values, type: "CPD" });
        } else {
            setValues({ ...values, type: "CC" });
        }
    };

    renderHeader = () => {
        const { isDesktop } = this.props;

        const title = "echeq.emit";
        if (isDesktop) {
            return (
                <div className="admin-detail-head">
                    <Head onBack={this.handleBack} />
                    <Head title="echeq.emit" centerContentClassName="p-0" />
                </div>
            );
        }

        return (
            <Head
                headerClassName="blue-main-header-mobile"
                titleClassName="w-100 align-center"
                onBack={this.handleBack}
                title={title}
            />
        );
    };

    emisionModesHint = () => {
        const { isDesktop } = this.props;

        this.setState({
            showHintDrawer: true,
            contentDrawer: <EmisionModesHint isDesktop={isDesktop} />,
            drawerHeader: "echeqs.title.hint.emision.modes",
        });
    };

    addEcheqToEmit = () => {
        const { values, setErrors, dispatch, setFieldTouched, echeqsToEmit, setValues } = this.props;

        setFieldTouched("amount", true);

        const schema = Yup.object().shape({
            amount: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
        });

        schema
            .validate(values)
            .then(() => {
                if (echeqsToEmit.length === 12) {
                    dispatch(
                        notificationActions.showNotification(i18n.get(`${FORM_ID}.echeqs.emitted.limit`), "error", [
                            "echeqs",
                        ]),
                    );
                } else {
                    const echeqToEmit = [...echeqsToEmit, ...createEmisionEcheqList(values)];
                    dispatch(checkActions.updateListEcheqToEmit(echeqToEmit));
                    setValues({ ...values, amount: "", reference: "", referencevalue: "" });
                    setFieldTouched("amount", false);
                }
            })
            .catch((err) => {
                setErrors({ [err.path]: err.message });
            });
    };

    onChangeEmitMode = (mode) => {
        const { values, setValues, dispatch } = this.props;

        if (mode !== "MULT") {
            setValues({ ...values, quantity: 1 });
        }
        dispatch(checkActions.updateListEcheqToEmit([]));
    };

    renderAmountComponent = () => {
        const { values, echeqsToEmit, isDesktop } = this.props;
        const mode = values?.modes;

        let amount = values?.amount || 0;
        let itemsAmount = values?.quantity || 0;

        if (mode === "PERS") {
            amount = getTotalAmountEcheqs(echeqsToEmit);
            itemsAmount = echeqsToEmit.length;
        }

        const buildAmount = (component) =>
            (isDesktop && (
                <Container className="flex-grow" rowClassName="justify-content-left" gridClassName="form-content">
                    {component}
                </Container>
            )) || <>{component}</>;

        const amountComponent = () => (
            <SelectedItemsAmount
                amountText="echeqs.emission.amount.hint"
                amount={amount}
                itemsAmount={itemsAmount}
                leftOperations
                className={classNames("d-flex justify-content-start row f-dir-col", {
                    "pull-left mx-1": isDesktop,
                })}
            />
        );

        return buildAmount(amountComponent());
    };

    removeEcheq = (index) => {
        const { echeqsToEmit, dispatch } = this.props;

        const updatedEcheqList = [...echeqsToEmit];
        updatedEcheqList.splice(index, 1);

        dispatch(checkActions.updateListEcheqToEmit(updatedEcheqList));
    };

    render() {
        const {
            emitData,
            isDesktop,
            accounts,
            values,
            setValues,
            echeqsToEmit,
            isSmallDesktop,
            concepts,
            activeEnvironment,
        } = this.props;
        const { showDrawer, contentDrawer, frequentVisible, showHintDrawer, drawerHeader } = this.state;
        const newFormatCoelsa = configUtils.getBoolean("frontend.show.emisionECHEQ.functionalities", false);
        const echeqAllowedDays = configUtils.getInteger("echeq.allowed.Coelsa.days");
        const addChar = newFormatCoelsa ? 6 : 0;
        const { sendEmail, reference } = values;
        const accountsPesos = accounts?.filter((account) => account.currency === "0");
        if (emitData?.beneficiary?.reference && showDrawer) {
            this.closeDrawer();
        }

        if (!accountsPesos) {
            return "";
        }
        if (values.mode !== "Cruzado") {
            setValues({ ...values, mode: "Cruzado" });
        }

        const showAmountComponent = values.modes === "PERS" || values.modes === "MULT";
        return (
            <>
                <CreateFrequentDesitinationModal
                    isVisible={frequentVisible}
                    onClick={this.selectBeneficiary}
                    closeModal={this.closeModal}
                    isDesktop={isDesktop}
                />
                <Notification scopeToShow="echeqs" />
                {this.renderHeader()}
                <MainContainer viewContentClassName="overflow-visible pt-0">
                    <div className="above-the-fold echeq__emit">
                        {(showDrawer || showHintDrawer) && (
                            <DrawerModal
                                content={contentDrawer}
                                showDrawerRemote={showDrawer || showHintDrawer}
                                isDesktop={isDesktop}
                                title={!isDesktop && drawerHeader !== "echeqs.title.hint.emision.modes" && drawerHeader}
                                noBlueHeader={drawerHeader === "echeqs.title.hint.emision.modes"}
                                width={isDesktop ? "30%" : "100%"}
                                closeRemotely={() => this.closeDrawer()}
                            />
                        )}

                        <Container className="flex-grow align-items-center" gridClassName="form-content">
                            <Col xs={12} sm={12} xl={12} lg={12} md={12} className="px-0">
                                <Form>
                                    <Container
                                        className="flex-grow align-items-center container-white my-2 with-padding py-0"
                                        gridClassName="form-content">
                                        <Col xs={12} lg={isSmallDesktop ? 10 : 7}>
                                            <Col xs={12} className={classNames({ "px-0": !isDesktop })}>
                                                <Field
                                                    name="emision"
                                                    component={Productselector}
                                                    idField="emision"
                                                    data={{
                                                        emptyOptionLabel: "",
                                                        options: accountsPesos,
                                                    }}
                                                    isRequired
                                                    customPlaceholder="Seleccioná"
                                                    mode="edit"
                                                    labelText="deposit"
                                                    idForm={FORM_ID}
                                                    isDesktop={isDesktop}
                                                    notMarginYLabel
                                                />
                                            </Col>
                                        </Col>
                                    </Container>
                                    <Container
                                        className={classNames(
                                            "flex-grow align-items-center container-white my-2 with-padding py-0",
                                            { "mb-0": !isDesktop },
                                        )}
                                        gridClassName="form-content">
                                        <Col xs={12} lg={isSmallDesktop ? 10 : 7} className="d-flex py-1">
                                            <Col
                                                xs={isDesktop ? 7 : 8}
                                                className={classNames({ "px-0": !isDesktop })}
                                                style={{ display: "flex", alignItems: "center" }}>
                                                <span className={classNames("control-label")}>
                                                    {i18n.get(`${FORM_ID}.beneficiary.data`)}
                                                </span>
                                            </Col>
                                            <Col xs={isDesktop ? 5 : 4} className="justify-content-end d-flex px-0">
                                                <Button
                                                    className={`echeq__btn-add-benficiary plus-button
                                                        ${isDesktop ? "btn-outline" : "btn-link pr-3"}
                                                        `}
                                                    onClick={() =>
                                                        this.setState({
                                                            showDrawer: true,
                                                            contentDrawer: (
                                                                <AddBeneficiary
                                                                    type="checks/EMIT_ECHEQ_BENEFICIARY"
                                                                    isDesktop={isDesktop}
                                                                />
                                                            ),
                                                            drawerHeader: "echeqs.add.beneficiary.title",
                                                        })
                                                    }
                                                    disabled={emitData.beneficiary?.document}>
                                                    <Image
                                                        src={isDesktop ? "images/plus.svg" : "images/add-circle.svg"}
                                                    />

                                                    {isDesktop && (
                                                        <I18n
                                                            id={`${FORM_ID}.add.beneficiary`}
                                                            componentProps={{ style: { lineHeight: 1 } }}
                                                        />
                                                    )}
                                                </Button>
                                                {activeEnvironment?.permissions.frequentDestination ? (
                                                    <Button
                                                        bsStyle="link"
                                                        image="images/frequent-destination.svg"
                                                        className={classNames("no-shadow d-flex w-auto", {
                                                            " m-0 p-0 justify-content-end svg25": !isDesktop,
                                                            "svg30 mr-3": isDesktop,
                                                        })}
                                                        onClick={this.openModal}
                                                        disabled={emitData.beneficiary?.document}
                                                    />
                                                ) : (
                                                    undefined
                                                )}
                                            </Col>
                                        </Col>
                                    </Container>
                                    {emitData?.beneficiary?.reference && (
                                        <Container
                                            className={classNames(
                                                "flex-grow align-items-center container-white my-2 with-padding",
                                                { "mt-0 table-row-border-top": !isDesktop },
                                            )}
                                            gridClassName={classNames({ "pl-4": isDesktop })}>
                                            <Col
                                                xs={12}
                                                lg={isSmallDesktop ? 10 : 7}
                                                className={classNames({ "px-4": isDesktop, "pl-3 pr-2": !isDesktop })}>
                                                <div className="space-between align-item-center">
                                                    <div>
                                                        <span className="data-label mb-0 mr-2 f-size-55">
                                                            {i18n.get(`${FORM_ID}.reference.beneficiary`)}
                                                        </span>
                                                        <span className="data-label font-light f-size-55">
                                                            {emitData?.beneficiary?.reference?.replaceAll("#", "Ñ")}
                                                        </span>
                                                    </div>

                                                    <button
                                                        type="button"
                                                        className={classNames("btn-link d-flex", { "mr-1": isDesktop })}
                                                        onClick={() => this.cleanBeneficiary()}>
                                                        <Image
                                                            src="images/cross-mobile-blue.svg"
                                                            styles={{ width: "24px", height: "24px" }}
                                                        />
                                                    </button>
                                                </div>
                                            </Col>
                                        </Container>
                                    )}

                                    <Container
                                        className="flex-grow align-items-center container-white my-2"
                                        gridClassName="form-content">
                                        <Col
                                            xs={12}
                                            className={classNames({
                                                "px-6 pt-2 pb-0 align-items-end": isDesktop && !isSmallDesktop,
                                                "pt-2 pb-0 align-items-end": isSmallDesktop,
                                                "pb-3": !isDesktop,
                                            })}>
                                            <Col xs={12} md={3} lg={2} className={classNames({ "px-0": !isDesktop })}>
                                                <Field
                                                    component={Selector}
                                                    hidePlaceholder
                                                    disabled={!emitData?.beneficiary?.reference}
                                                    isDisabled={!emitData?.beneficiary?.reference}
                                                    options={[...emisionModes]}
                                                    idForm={FORM_ID}
                                                    name="modes"
                                                    isRequired
                                                    onCustomChange={this.onChangeEmitMode}
                                                    style={{ background: "transparent" }}
                                                    controlLabelChildClassName="white-space-wrap"
                                                    buttonInfo={
                                                        !isDesktop && (
                                                            <button
                                                                type="button"
                                                                className="btn-hint emission-echeq pt-2"
                                                                onClick={this.emisionModesHint}>
                                                                <Image src="images/infoUserInvite.svg" />
                                                            </button>
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col xs={12} md={3} className={classNames({ "px-0": !isDesktop })}>
                                                <Field
                                                    component={Selector}
                                                    searchable
                                                    hidePlaceholder
                                                    isDisabled={!emitData?.beneficiary?.reference}
                                                    options={concepts.map(({ id, name }) => ({
                                                        value: id,
                                                        label: `${id} - ${name}`,
                                                    }))}
                                                    idForm={FORM_ID}
                                                    name="concept"
                                                    isRequired
                                                    style={{ background: "transparent" }}
                                                />
                                            </Col>

                                            <Col xs={12} md={2} lg={3} className={classNames({ "px-0": !isDesktop })}>
                                                <Field
                                                    component={TextField}
                                                    hidePlaceholder
                                                    isDisabled={!emitData?.beneficiary?.reference}
                                                    idForm={FORM_ID}
                                                    name="motive"
                                                    maxLength="150"
                                                    type="text"
                                                    style={{ background: "transparent" }}
                                                />
                                            </Col>
                                            <Col xs={isDesktop ? 2 : 6} className={classNames({ "pl-0": !isDesktop })}>
                                                <Field
                                                    component={Selector}
                                                    hidePlaceholder
                                                    isDisabled={!emitData?.beneficiary?.reference}
                                                    options={[...characters]}
                                                    idForm={FORM_ID}
                                                    name="character"
                                                    isRequired
                                                />
                                            </Col>
                                            <Col xs={isDesktop ? 2 : 6} className={classNames({ "px-0": !isDesktop })}>
                                                <div className="form-group">
                                                    <div className="form-group-text">
                                                        <span
                                                            className={`control-label data-label ${
                                                                !emitData?.beneficiary?.reference
                                                                    ? "control-label-disable"
                                                                    : ""
                                                            }`}>
                                                            {i18n.get(`${FORM_ID}.mode.label`).toUpperCase()}
                                                        </span>
                                                    </div>
                                                    <Field
                                                        component={Checkbox}
                                                        className="min-height-3 d-flex align-center mt-2 mb-0"
                                                        idForm={FORM_ID}
                                                        isDisabled
                                                        name="mode"
                                                        isDesktop={isDesktop}
                                                        labelText="echeqs.1.modo"
                                                        labelTextProps={{
                                                            componentProps: {
                                                                className: `control-label data-label ${
                                                                    !emitData?.beneficiary?.reference
                                                                        ? "control-label-disable"
                                                                        : ""
                                                                }`,
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Col>
                                        {isDesktop && (
                                            <Col
                                                xs={12}
                                                className={classNames({
                                                    "pt-0 pb-2 pl-65": isDesktop && !isSmallDesktop,
                                                    "pt'0 pb-2 pl-45": isSmallDesktop,
                                                })}>
                                                <button
                                                    type="button"
                                                    className="btn-hint emission-echeq p-0"
                                                    disabled={!emitData?.beneficiary?.reference}
                                                    onClick={this.emisionModesHint}>
                                                    <Image src="images/infoUserInvite.svg" />
                                                    <I18n
                                                        id={`${FORM_ID}.emision.modes.hint`}
                                                        component="p"
                                                        componentProps={{ className: "hint-text text-nowrap my-0" }}
                                                    />
                                                </button>
                                            </Col>
                                        )}
                                    </Container>
                                    <Container
                                        className={classNames("flex-grow align-items-center container-white my-2", {
                                            "mb-0": !isDesktop,
                                        })}
                                        gridClassName="form-content">
                                        <Col
                                            xs={12}
                                            className={classNames({
                                                "align-items-end px-6 py-2 pb-5": isDesktop && !isSmallDesktop,
                                                "flex-wrap align-items-end px-3 py-2": isSmallDesktop,
                                            })}>
                                            <Col xs={12} md={4} lg={3} className={classNames({ "px-0": !isDesktop })}>
                                                <Field
                                                    autocomplete="off"
                                                    idForm={FORM_ID}
                                                    isDisabled={!emitData?.beneficiary?.reference}
                                                    disabled={!emitData?.beneficiary?.reference}
                                                    component={AmountField}
                                                    data={{
                                                        options: [{ id: 0, label: i18n.get(`currency.label.ARS`) }],
                                                    }}
                                                    name="amount"
                                                    clearable={false}
                                                    maxLength={15 + addChar}
                                                    noStyleToInput
                                                    amountClassName="font-size-15-px text-align-right"
                                                    currency="0"
                                                    plainValue
                                                    fixedDecimalScale
                                                />
                                            </Col>
                                            <Col
                                                xs={9}
                                                md={4}
                                                lg={2}
                                                className={classNames({ "px-0 pr-3": !isDesktop })}>
                                                <Field
                                                    idField="date"
                                                    formikValueFirst
                                                    isDisabled={!emitData?.beneficiary?.reference}
                                                    disabled={!emitData?.beneficiary?.reference}
                                                    component={Date}
                                                    hidePlaceholder
                                                    onCustomChange={(date) => this.changeEcheqType(date)}
                                                    idForm={FORM_ID}
                                                    name="date"
                                                    startDate={new Date()}
                                                    minDate={new Date()}
                                                    maxDate={moment()
                                                        .startOf("day")
                                                        .add(echeqAllowedDays, "days")}
                                                />
                                            </Col>

                                            <Col
                                                xs={3}
                                                md={4}
                                                lg={1}
                                                className={classNames("echeq-emit-type", { "px-0": !isDesktop })}>
                                                <Field
                                                    component={TextField}
                                                    hidePlaceholder
                                                    idForm={FORM_ID}
                                                    name="type"
                                                    innerDisabled={emitData?.beneficiary?.reference}
                                                    isDisabled
                                                    isRequired
                                                />
                                            </Col>
                                            <Col xs={12} md={4} lg={2} className={classNames({ "px-0": !isDesktop })}>
                                                <Field
                                                    component={TextField}
                                                    hidePlaceholder
                                                    isDisabled={!emitData?.beneficiary?.reference}
                                                    idForm={FORM_ID}
                                                    name="reference"
                                                    value={reference}
                                                    maxLength="100"
                                                    type="text"
                                                />
                                            </Col>
                                            {(isDesktop || (!isDesktop && values.modes !== "PERS")) && (
                                                <Col
                                                    xs={values.modes === "INDI" ? 12 : 9}
                                                    md={4}
                                                    lg={2}
                                                    className={classNames({
                                                        "px-0": !isDesktop && values.modes === "INDI",
                                                        "pl-0": !isDesktop && values.modes !== "INDI",
                                                    })}>
                                                    <Field
                                                        component={TextField}
                                                        hidePlaceholder
                                                        isDisabled={!emitData?.beneficiary?.reference}
                                                        idForm={FORM_ID}
                                                        name="referencevalue"
                                                        maxLength="50"
                                                        type="text"
                                                        controlLabelClassName="text-wrap"
                                                    />
                                                </Col>
                                            )}
                                            {values.modes === "MULT" && (
                                                <Col
                                                    xs={3}
                                                    md={4}
                                                    lg={2}
                                                    className={classNames("echeq__input-quantity", {
                                                        "px-0": !isDesktop,
                                                    })}>
                                                    <Field
                                                        component={TextField}
                                                        hidePlaceholder
                                                        isDisabled={!emitData?.beneficiary?.reference}
                                                        idForm={FORM_ID}
                                                        name="quantity"
                                                        type="number"
                                                        max="10"
                                                    />
                                                </Col>
                                            )}
                                            {values.modes === "PERS" && isDesktop && (
                                                <Col
                                                    xs={3}
                                                    md={4}
                                                    lg={2}
                                                    className={classNames({
                                                        "px-0 pt-1 align-items-end": !isDesktop,
                                                        "justify-content-right": isDesktop || !isSmallDesktop,
                                                    })}>
                                                    <Button
                                                        className={classNames("btn btn-outline", {
                                                            "mb-2 mr-0 min-width-8": isDesktop && !isSmallDesktop,
                                                            "small-svg bold-svg": !isDesktop || isSmallDesktop,
                                                            "mt-45": !isDesktop,
                                                        })}
                                                        bs-style="outline"
                                                        onClick={this.addEcheqToEmit}>
                                                        <Image src="images/plus.svg" />
                                                        <I18n
                                                            id={`${FORM_ID}.echeq`}
                                                            componentProps={{ className: "line-height-1" }}
                                                        />
                                                    </Button>
                                                </Col>
                                            )}
                                            {values.modes === "PERS" && !isDesktop && (
                                                <Container>
                                                    <Col
                                                        xs={9}
                                                        md={4}
                                                        lg={2}
                                                        className={classNames({
                                                            "pl-0": !isDesktop,
                                                        })}>
                                                        <Field
                                                            component={TextField}
                                                            hidePlaceholder
                                                            isDisabled={!emitData?.beneficiary?.reference}
                                                            idForm={FORM_ID}
                                                            name="referencevalue"
                                                            maxLength="50"
                                                            type="text"
                                                            controlLabelClassName="text-wrap"
                                                        />
                                                    </Col>
                                                    <Col
                                                        xs={3}
                                                        md={4}
                                                        lg={2}
                                                        className={classNames({
                                                            "px-0 pt-1 align-items-end": !isDesktop,
                                                            "justify-content-right": isDesktop || !isSmallDesktop,
                                                        })}>
                                                        <Button
                                                            className={classNames(
                                                                "btn btn-outline",
                                                                "small-svg bold-svg",
                                                            )}
                                                            bs-style="outline"
                                                            onClick={this.addEcheqToEmit}>
                                                            <Image src="images/plus.svg" />
                                                            <I18n
                                                                id={`${FORM_ID}.echeq`}
                                                                componentProps={{ className: "line-height-1" }}
                                                            />
                                                        </Button>
                                                    </Col>
                                                </Container>
                                            )}
                                        </Col>
                                    </Container>
                                    {values.modes === "PERS" &&
                                        echeqsToEmit.map((echeq, index) => (
                                            <EcheqToEmit
                                                echeq={echeq}
                                                removeEcheq={() => this.removeEcheq(index)}
                                                isDesktop={isDesktop}
                                            />
                                        ))}
                                    <Container
                                        className={classNames("flex-grow align-items-center", { "mx-3": !isDesktop })}
                                        gridClassName="form-content"
                                        rowClassName={showAmountComponent && "px-0"}>
                                        <Col
                                            xs={12}
                                            md={6}
                                            className={classNames("col-6", {
                                                "px-0": isDesktop,
                                                "py-3": !isDesktop,
                                                "d-none": !showAmountComponent && !isDesktop,
                                            })}>
                                            {showAmountComponent && this.renderAmountComponent()}
                                        </Col>
                                        <Col
                                            xs={12}
                                            md={6}
                                            className={classNames("col-6", { "px-0 py-3": !isDesktop })}>
                                            <div
                                                className={classNames("w-100", {
                                                    "text-left": !isDesktop,
                                                    "text-right": isDesktop,
                                                })}>
                                                <Field
                                                    isDisabled={!emitData?.beneficiary?.reference || !values.emision}
                                                    disabled={!emitData?.beneficiary?.reference || !values.emision}
                                                    component={SwitchField}
                                                    idForm={FORM_ID}
                                                    name="sendEmail"
                                                    notMb
                                                />
                                            </div>
                                        </Col>
                                    </Container>
                                    {sendEmail && (
                                        <Container
                                            className="flex-grow align-items-center container-white my-2"
                                            gridClassName="form-content">
                                            <Col xs={12} lg={isSmallDesktop ? 10 : 7}>
                                                <Col xs={12} md={6} className={classNames({ "px-0": !isDesktop })}>
                                                    <Field
                                                        component={TextField}
                                                        hidePlaceholder
                                                        idForm={FORM_ID}
                                                        name="email"
                                                        type="email"
                                                        isDisabled={!emitData?.beneficiary?.reference}
                                                    />
                                                </Col>

                                                <Col xs={12} md={6} className={classNames({ "px-0": !isDesktop })}>
                                                    <Field
                                                        component={TextField}
                                                        hidePlaceholder
                                                        idForm={FORM_ID}
                                                        name="emailText"
                                                        maxLength="150"
                                                        type="text"
                                                        isDisabled={!emitData?.beneficiary?.reference}
                                                    />
                                                </Col>
                                            </Col>
                                        </Container>
                                    )}
                                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                                        <Col
                                            xs={12}
                                            md={6}
                                            lg={4}
                                            className={classNames({
                                                "mb-2": !isDesktop,
                                                "mt-5": !sendEmail && isDesktop,
                                                "mt-25": sendEmail && isDesktop,
                                            })}>
                                            <Container
                                                className="flex-grow align-items-center mb-2"
                                                gridClassName="form-content">
                                                <Button
                                                    type="submit"
                                                    bsStyle="primary"
                                                    label="global.continue"
                                                    className="my-0"
                                                    disabled={!emitData?.beneficiary?.reference || !values.emision}
                                                />
                                            </Container>
                                        </Col>
                                    </Container>
                                </Form>
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: checkSelector.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    emitData: checkSelector.getEcheqsEmitData(state),
    echeqsToEmit: checkSelector.getEcheqsToEmit(state),
    concepts: transferSelectors.getConcepts(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    emissionData: checkSelector.getEmissionData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            emision: props?.emitData?.emision || "",
            modes: props?.emitData?.modes || "INDI",
            concept: props?.emitData?.concept || "VAR",
            motive: props?.emitData?.motive || "",
            character: props?.emitData?.character || characters[0].value,
            sendEmail: props?.emitData?.sendEmail || false,
            email: props?.emitData?.email || "",
            emailText: props?.emitData?.emailText || "",
            date:
                props?.emitData?.modes !== "PERS" && props?.echeqsToEmit[0]?.date
                    ? props?.echeqsToEmit[0]?.date
                    : moment().toDate(),
            quantity:
                props?.emitData?.modes !== "PERS" && props?.echeqsToEmit?.length ? props?.echeqsToEmit?.length : 1,
            reference:
                props?.emitData?.modes !== "PERS" && props?.echeqsToEmit[0]?.reference
                    ? props?.echeqsToEmit[0]?.reference
                    : "",
            referencevalue:
                props?.emitData?.modes !== "PERS" && props?.echeqsToEmit[0]?.referencevalue
                    ? props?.echeqsToEmit[0]?.referencevalue
                    : "",
            amount:
                props?.emitData?.modes !== "PERS" && props?.echeqsToEmit[0]?.amount
                    ? props?.echeqsToEmit[0]?.amount * props?.echeqsToEmit.length
                    : "",
            confirmation: false,
            type:
                props?.emitData?.modes !== "PERS" && props?.echeqsToEmit?.length ? props?.echeqsToEmit[0]?.type : "CC",
        }),
        validationSchema: () =>
            Yup.lazy((values) => {
                let validate = {
                    emision: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                    concept: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                    motive: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                    character: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                    mode: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                };

                if (values.modes !== "PERS") {
                    validate = {
                        ...validate,
                        amount: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                        quantity: Yup.number()
                            .min(1, i18n.get(`${FORM_ID}.min.quantity.required`))
                            .max(10, i18n.get(`${FORM_ID}.max.quantity.required`))
                            .required(i18n.get(`${FORM_ID}.error.required`)),
                        date: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                    };
                }

                if (values.sendEmail) {
                    validate = {
                        ...validate,
                        email: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                        emailText: Yup.string().required(i18n.get(`${FORM_ID}.error.required`)),
                    };
                }

                return Yup.object().shape(validate);
            }),

        handleSubmit: (form, formikBag) => {
            const { props, setValues } = formikBag;
            const { dispatch, emitData, echeqsToEmit } = props;

            if (!emitData?.beneficiary?.reference) {
                dispatch(notificationActions.showNotification(`${FORM_ID}.empty.beneficiary`, "error", ["echeqs"]));
            } else {
                const { emision, modes, concept, motive, character, mode, sendEmail, email, emailText } = form;
                const formData = { emision, modes, concept, motive, character, mode, sendEmail, email, emailText };

                let echeqList = [...echeqsToEmit];

                if (form.modes !== "PERS") {
                    echeqList = createEmisionEcheqList(form);
                }

                if (echeqsToEmit.length === 0 && form.modes === "PERS") {
                    dispatch(
                        notificationActions.showNotification(i18n.get(`${FORM_ID}.empty.echeq.list.message`), "error", [
                            "echeqs",
                        ]),
                    );
                } else if (!form.confirmation && form.modes === "PERS" && form.amount) {
                    setValues({ ...form, confirmation: true });
                    dispatch(
                        notificationActions.showNotification(i18n.get(`${FORM_ID}.confirmation.message`), "warning", [
                            "echeqs",
                        ]),
                    );
                } else {
                    dispatch(checkActions.echeqEmitData(formData, echeqList, formData.emision));
                    dispatch(push("/echeqs/action/emit/confirm"));
                }
            }
        },
    }),
)(EmitEcheq);
