import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { bool, arrayOf } from "prop-types";

import { selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import I18n from "pages/_components/I18n";
import Table from "pages/_components/Table";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import { adhesionShape } from "util/servicePayments";
import classNames from "classnames";
import AdhesionesItem from "./AdhesionesItem";

const tableLabel = "services.adhesiones.table.header";

class AdhesionesTable extends Component {
    static propTypes = {
        adhesiones: arrayOf(adhesionShape).isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { adhesiones, isDesktop } = this.props;

        const list = adhesiones.map((adhesion) => (
            <Table.Row
                className={classNames("container-white", {
                    "my-2 border-none": !isDesktop,
                })}
                key={adhesion.adhesionCode}>
                <AdhesionesItem
                    key={`transaction-${adhesion.adhesionCode}`}
                    adhesion={adhesion}
                    isDesktop={isDesktop}
                    tableLabel={tableLabel}
                />
            </Table.Row>
        ));

        const renderTable = isDesktop ? (
            <Table>
                <Table.Header className="container-white">
                    <Table.HeaderData align="left">
                        <I18n component="span" id={`${tableLabel}.entity`} />
                    </Table.HeaderData>
                    <Table.HeaderData align="center">
                        <I18n id={`${tableLabel}.category`} />
                    </Table.HeaderData>
                    <Table.HeaderData>
                        <I18n id={`${tableLabel}.reference`} />
                    </Table.HeaderData>
                    <Table.HeaderData>
                        <I18n id={`${tableLabel}.code`} />
                    </Table.HeaderData>
                    <Table.HeaderData />
                </Table.Header>
                <Table.Body>{list}</Table.Body>
            </Table>
        ) : (
            <Table className>{list}</Table>
        );

        return (
            <Container>
                <Col className="col col-12 px-0">
                    <div
                        style={{ borderSpacing: "0px 2px", width: "100%" }}
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        {adhesiones.length ? (
                            renderTable
                        ) : (
                            <div className="text-center no-more-data" key="noMoreMovements">
                                <div className="illustration-wrapper">
                                    <Image
                                        src="images/coloredIcons/no-attached-services.svg"
                                        className="svg-big-icon"
                                    />
                                </div>
                                <p className="text-lead" style={{ opacity: 1 }}>
                                    <I18n id="services.adhesiones.empty.message" />
                                </p>
                            </div>
                        )}
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    adhesiones: servicePaymentsSelectors.getAdhesiones(state),
});

export default connect(mapStateToProps)(AdhesionesTable);
