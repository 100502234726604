import React, { Component } from "react";
import Yup from "yup";
import { func, shape, bool, string, number, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";

import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import * as i18nUtils from "util/i18n";
import * as accountUtils from "util/accounts";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import * as debinMiddleware from "middleware/debin";
import Checkbox from "pages/_components/Checkbox";
import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import DebinTermsAndConditionsContent from "pages/charges/_components/DebinTermsAndConditionsContent";
import PageLoading from "pages/_components/PageLoading";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import { downloadPdf } from "util/download";
import isTokenActive from "util/token";

const FORM_ID = "fixedTermAcceptance.accept.summary";
class AcceptFixedTermConfirm extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        values: shape({}).isRequired,
        debinDetail: shape({}).isRequired,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
            securitySeal: number,
            userFullName: string,
        }),
        accounts: shape({}),
        fetchingAccounts: bool.isRequired,
        accountName: string.isRequired,
        isSubmitting: bool.isRequired,
    };

    static defaultProps = {
        loggedUser: null,
        accounts: null,
    };

    componentDidMount() {
        const { dispatch, loggedUser, accounts } = this.props;

        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleDownload = async () => {
        const { data } = await debinMiddleware.exportTermsAndConditions("pdf", "dummy");
        const { content, fileName } = data.data;
        downloadPdf(fileName, content);
    };

    getTitle = () => (
        <>
            <div className="view-title data-wrapper-flex">
                <h1 className="mr-3">{i18nUtils.get("fixedTermAcceptance.accept.header.title")}</h1>
            </div>
            <p>{i18nUtils.get("fixedTermAcceptance.accept.confirm.header.subtitle")}</p>
        </>
    );

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1>{i18nUtils.get("fixedTermAcceptance.accept.header.title")}</h1>
        </div>
    );

    render() {
        const { isDesktop, values, fetchingAccounts, accounts, debinDetail, accountName, isSubmitting } = this.props;

        if (!debinDetail) {
            return <></>;
        }

        const continueButton = (
            <Col className="col col-12" lg={12} xl={12} md={12} sm={12}>
                <Button
                    disabled={!values.acceptTerms}
                    type="submit"
                    bsStyle="primary"
                    label="global.confirm"
                    image="images/arrowRight.svg"
                    imageStyle="mr-2"
                    className="text-capitalize"
                    loading={isSubmitting}
                />
            </Col>
        );

        const credentials = (
            <>
                {!isTokenActive(!isDesktop) ? (
                    <div className="admin-content-center">
                        <I18n id="transfer.summary.confirm.label" />
                    </div>
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}
                <div className="admin-content-center">
                    <Field
                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </>
        );

        const {
            buyerDetail,
            concept,
            description,
            expirationDate,
            id,
            sellerDetail,
            amount: { currency, quantity },
            amount,
            reference,
        } = debinDetail;

        const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);

        return (
            <>
                <Notification scopeToShow="debin" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        onClose={isDesktop && this.handleClose}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && <Head centerElement={this.getTitle} />}
                </div>
                <PageLoading loading={fetchingAccounts}>
                    <MainContainer>
                        <Form className="above-the-fold">
                            <Container
                                className="flex-grow container-white with-margin"
                                style={{ textTransform: "uppercase" }}>
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper"
                                        label="fixedTermAcceptance.accept.table.header.debitAccount"
                                        data={accountName || <p> - </p>}
                                    />
                                    <Heading.DataGroup
                                        containerClassName="transfer-data"
                                        data={
                                            loggedUserAccount ? (
                                                <div style={{ display: "flex" }}>
                                                    <span style={{ marginRight: "0.5rem" }}>
                                                        {i18nUtils.get("accounts.availableBalance")}
                                                    </span>
                                                    <FormattedAmount
                                                        currency={loggedUserAccount.currency}
                                                        quantity={loggedUserAccount.balance}
                                                    />
                                                </div>
                                            ) : (
                                                <p> - </p>
                                            )
                                        }
                                    />
                                </Col>
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper"
                                        label="payments.debin.detail.table.header.concept"
                                        data={concept ? i18nUtils.get(`transfers.concept.${concept}`) : <p> - </p>}
                                    />
                                </Col>
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper"
                                        label="payments.debin.detail.table.header.description"
                                        data={description || <p> - </p>}
                                    />
                                </Col>
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        containerClassName="transfer-data data-wrapper"
                                        label="payments.debin.detail.table.header.expirationDate"
                                        data={
                                            expirationDate ? (
                                                <FormattedDate date={expirationDate} dateFormat="dd/MM/yyyy" showTime />
                                            ) : (
                                                <p> - </p>
                                            )
                                        }
                                    />
                                </Col>
                            </Container>
                            <Container
                                className=" flex-grow container-white with-margin"
                                style={{ textTransform: "uppercase" }}>
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        containerClassName="transfer-data"
                                        label="fixedTermAcceptance.accept.table.header.id"
                                        data={id}
                                    />
                                    <Heading.DataGroup
                                        containerClassName="transfer-data"
                                        label="fixedTermAcceptance.accept.table.header.creditAccount"
                                        data={accountUtils.getAccountNumber(sellerDetail.cbu)}
                                    />
                                </Col>
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        containerClassName="transfer-data"
                                        label="payments.debin.detail.table.header.sender"
                                        data={sellerDetail.titular || <span> - </span>}
                                    />
                                    <Heading.DataGroup
                                        containerClassName="transfer-data"
                                        label="fixedTermAcceptance.accept.table.header.cbu"
                                        data={sellerDetail.cbu}
                                    />
                                </Col>
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        containerClassName="transfer-data"
                                        data={
                                            (
                                                <div style={{ display: "flex" }}>
                                                    <span className="data-label">
                                                        {i18nUtils.get(
                                                            "fixedTermAcceptance.accept.table.header.documentType",
                                                        )}
                                                    </span>
                                                    <span style={{ marginRight: "0.5rem" }}>
                                                        {sellerDetail.documentType}
                                                    </span>
                                                    <span>{sellerDetail.documentNumber}</span>
                                                </div>
                                            ) || <p> - </p>
                                        }
                                    />
                                    <Heading.DataGroup
                                        containerClassName="transfer-data"
                                        label="fixedTermAcceptance.accept.table.header.bank"
                                        data={sellerDetail.bank ? sellerDetail.bank : <p> - </p>}
                                    />
                                </Col>
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    <Heading.DataGroup
                                        containerClassName="transfer-data"
                                        data={
                                            amount ? (
                                                <div className="d-flex">
                                                    <span className="data-label">
                                                        {i18nUtils.get("payments.debin.detail.table.header.amount")}
                                                    </span>
                                                    <FormattedAmount currency={currency} quantity={quantity} />
                                                </div>
                                            ) : (
                                                <p> - </p>
                                            )
                                        }
                                    />
                                    <Heading.DataGroup
                                        containerClassName="transfer-data"
                                        label="payments.debin.detail.table.header.reference"
                                        data={reference || <span> - </span>}
                                    />
                                </Col>
                            </Container>
                            <Container
                                className={`flex-grow align-items-center container-white ${!isDesktop ? "mb-0" : ""}`}
                                gridClassName="form-content">
                                <Col className="col col-12" md={3} xl={3} lg={3} sm={12}>
                                    {credentials}
                                    {continueButton}
                                </Col>
                            </Container>
                            <Container
                                className={`flex-grow align-items-center ${!isDesktop ? "mb-0" : ""}`}
                                gridClassName="form-content">
                                <Col className="col col-12 mt-3" lg={3} xl={3} md={9} sm={12}>
                                    <Row>
                                        <Field name="acceptTerms" component={Checkbox} idForm={FORM_ID} />
                                        <ButtonDrawer
                                            bsStyle="link"
                                            label="global.termAndConditions"
                                            buttonClassName="btn-noBlock btn-asLink p-0 mt-0 mb-2"
                                            content={<DebinTermsAndConditionsContent isDesktop={isDesktop} />}
                                            styleButton={{
                                                width: "15rem",
                                            }}
                                            width="500px"
                                            closeButton
                                            headerContent={
                                                <div className="d-flex justify-content-end">
                                                    <Button
                                                        className="drawer-button-image big-icon py-1 px-0"
                                                        onClick={this.handleDownload}
                                                        image="images/download_bold.svg"
                                                        bsStyle="link"
                                                    />
                                                </div>
                                            }
                                        />
                                    </Row>
                                </Col>
                            </Container>
                        </Form>
                    </MainContainer>
                </PageLoading>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    debinDetail: debinSelectors.getDebinDetail(state),
    accountName: debinSelectors.getAccountName(state),
    loggedUser: sessionSelectors.getUser(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
    accounts: accountsSelectors.getAccounts(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
                acceptTerms: Yup.bool()
                    .required(i18nUtils.get("enrollment.index.invitationCode.empty"))
                    .oneOf([true], i18nUtils.get("enrollment.index.acceptTerms.required")),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, debinDetail, accounts, loggedUser } = formikBag.props;

            const {
                id: idDebin,
                amount: { currency, quantity: amount },
                buyerDetail,
                sellerDetail,
                idVoucher,
            } = debinDetail;

            const loggedUserAccount = accountUtils.getUserAccount(accounts, buyerDetail?.cbu);

            dispatch(
                debinActions.acceptIncomingRequestSend(
                    currency,
                    amount,
                    idDebin,
                    otp,
                    loggedUserAccount,
                    loggedUser.userId,
                    formikBag,
                    sellerDetail,
                    idVoucher,
                ),
            );
        },
    }),
)(AcceptFixedTermConfirm);
