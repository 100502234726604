import React from "react";
import Col from "react-bootstrap/lib/Col";
import { Field } from "formik";
import Productselector from "pages/forms/_components/_fields/Productselector";
import { string, bool, arrayOf, shape, func } from "prop-types";

import Container from "pages/_components/Container";
import TextField from "pages/_components/fields/TextField";
import Check from "pages/_components/fields/Checkbox";
import { statusCPEShape } from "util/servicePayments";
import * as i18nUtils from "util/i18n";

function SelectPaymentMethod({
    FORM_ID,
    accounts,
    account,
    isDesktop,
    statusCPE,
    handleAddService,
    isAddServiceSelected,
    showAddService,
    handleIncomeReference,
}) {
    SelectPaymentMethod.propTypes = {
        FORM_ID: string.isRequired,
        isDesktop: bool,
        accounts: arrayOf(shape({})).isRequired,
        account: shape({}),
        statusCPE: statusCPEShape.isRequired,
        handleAddService: func.isRequired,
        isAddServiceSelected: bool,
        showAddService: bool,
        handleIncomeReference: bool,
    };

    SelectPaymentMethod.defaultProps = {
        isDesktop: true,
        account: null,
        isAddServiceSelected: true,
        showAddService: true,
        handleIncomeReference: false,
    };

    return (
        <Container
            className={`container--layout flex-grow align-items-center cmf-container-white ${!isDesktop && "px-3"}`}
            gridClassName="form-content">
            <Col
                sm={12}
                md={9}
                lg={8}
                className="align-items-center payments-taxes-width px-0 px-md-3 pt-0 pt-md-3 px-lg-0 pb-3">
                <Col sm={statusCPE.handleBaseDebts && showAddService ? 6 : 12} className="col-no-pad-mobile px-0">
                    <Field
                        name="account"
                        component={Productselector}
                        customPlaceholder={i18nUtils.get("payments.afip.list.generatedVEP.placeholder")}
                        idField="account"
                        data={{
                            emptyOptionLabel: "",
                            options: accounts,
                        }}
                        value={account}
                        isRequired
                        mode="edit"
                        labelText="new.check.account"
                        idForm={FORM_ID}
                        isDesktop={isDesktop}
                    />
                </Col>

                {handleIncomeReference && (
                    <Col sm={12} md={6} className="col-no-pad-mobile px-0 pr-md-3 pl-md-0">
                        <Field name="paymentReference" idForm={FORM_ID} component={TextField} hidePlaceholder />
                    </Col>
                )}

                {statusCPE.handleBaseDebts && showAddService && (
                    <Col sm={12} md={6} className="col-no-pad-mobile payment-adhesion-check h-100 mt-15">
                        <Check
                            label={i18nUtils.get("services.newPayment.addService.label")}
                            name="addServiceCheck"
                            onChange={handleAddService}
                            checked={isAddServiceSelected}
                            formGroupClassName="mb-0 mt-1"
                        />
                    </Col>
                )}
            </Col>
        </Container>
    );
}

export default SelectPaymentMethod;
