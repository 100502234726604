import React, { Component } from "react";
import TextField from "./TextField";

class TextArea extends Component {
    render() {
        return <TextField renderAs="textarea" {...this.props} />;
    }
}

export default TextArea;
