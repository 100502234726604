import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form } from "formik";
import { bool, func, string, shape } from "prop-types";

import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as assistantSelectors } from "reducers/assistant";

import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import { Mixpanel } from "util/clickstreaming";

const FORM_ID = "login.step4";

class Step4Content extends Component {
    static propTypes = {
        isSubmitting: bool,
        dispatch: func,
        isDesktop: bool,
        termsAndConditions: shape({ body: string }),
    };

    static defaultProps = {
        termsAndConditions: null,
    };

    state = {
        termsAndConditionsTemp: null,
    };

    render() {
        const { isSubmitting, isDesktop, dispatch, termsAndConditions } = this.props;
        const { termsAndConditionsTemp } = this.state;

        if (termsAndConditions && termsAndConditionsTemp !== termsAndConditions.text) {
            this.setState({ termsAndConditionsTemp: termsAndConditions.text });
        }

        return (
            <Form className={`login-terms ${isDesktop ? "above-the-fold login-desktop-wrapper" : ""}`}>
                <div className="flex-grow align-items-center">
                    {termsAndConditionsTemp ? (
                        // eslint-disable-next-line react/no-danger
                        <div dangerouslySetInnerHTML={{ __html: termsAndConditionsTemp }} />
                    ) : (
                        <I18n id="global.termsAndConditions.content" component="p" />
                    )}
                </div>
                <Container className="align-items-center container--layout">
                    <Col sm={6} md={6} lg={6}>
                        {/* <Field name="acceptConditions" idForm={FORM_ID} component={SwitchField} formGroup /> */}
                        <Button
                            onClick={() => dispatch(loginActions.reset())}
                            bsStyle="outline"
                            label="login.step4.declineTerms.label"
                        />
                    </Col>
                    <Col sm={6} md={6} lg={6}>
                        <Button
                            type="submit"
                            loading={isSubmitting}
                            label="login.step4.acceptTerms.label"
                            bsStyle="primary"
                        />
                    </Col>
                </Container>
            </Form>
        );
    }
}

Step4Content.propTypes = {
    isSubmitting: bool,
    isDesktop: bool,
    dispatch: func.isRequired,
};

Step4Content.defaultProps = {
    isSubmitting: false,
    isDesktop: true,
};

const mapStateToProps = (state) => ({
    termsAndConditions: loginSelectors.getTermsAndConditions(state),
    isAssistantLogin: assistantSelectors.isAssistantLogin(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        handleSubmit: (_, formikBag) => {
            Mixpanel.track(FORM_ID, { acceptConditions: true });
            formikBag.props.dispatch(loginActions.loginStep4(true, formikBag));
        },
    }),
)(Step4Content);
