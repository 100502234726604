import React, { Component } from "react";
import { node, string } from "prop-types";

class TableHeader extends Component {
    static propTypes = {
        children: node.isRequired,
        className: string,
        rowClassName: string,
    };

    static defaultProps = {
        className: "",
        rowClassName: "",
    };

    render() {
        const { children, className, rowClassName } = this.props;
        return (
            <div className={`table-head ${className}`}>
                <div className={`table-row ${rowClassName}`}>{children}</div>
            </div>
        );
    }
}

export default TableHeader;
