import React from "react";
import { arrayOf, shape, string, func, bool, oneOfType, object } from "prop-types";

import formField from "pages/forms/_components/_fields/_commons/formField";
import Select from "pages/forms/_components/_fields/Select";

class Branchlist extends React.Component {
    static propTypes = {
        data: shape({
            branches: arrayOf(shape({ id: string, name: string })).isRequired,
        }).isRequired,
        setValue: func.isRequired,
        editing: bool.isRequired,
        placeholder: string,
        value: oneOfType([string, object]),
        idField: string.isRequired,
    };

    static defaultProps = {
        placeholder: "",
        value: "" || {},
    };

    handleChange = ({ id }) => {
        const { setValue } = this.props;
        setValue(id);
    };

    render() {
        const {
            data: { branches },
            editing,
            value,
            placeholder,
            idField,
        } = this.props;

        if (editing) {
            return (
                <div className="input-group">
                    <div style={{ flex: 1 }}>
                        <Select
                            id={idField}
                            placeholder={placeholder}
                            value={value}
                            clearable={false}
                            searchable={false}
                            onChange={this.handleChange}
                            valueKey="id"
                            labelKey="name"
                            options={branches}
                            className="flex-container slideFromBottom"
                            optionClassName="needsclick"
                        />
                    </div>
                </div>
            );
        }
        const { name } = branches.find(({ id }) => id === value) || {};
        return name || null;
    }
}
export default formField({
    formClass: "form-group--select",
})(Branchlist);
