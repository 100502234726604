import React, { Component } from "react";
import { string, node, oneOfType, shape, bool } from "prop-types";

import Image from "pages/_components/Image";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import ReactHtmlParser from "react-html-parser";

class GeneralMsg extends Component {
    static propTypes = {
        title: oneOfType([string, node]),
        description: oneOfType([string, node]),
        imagePath: string,
        pageTitle: node,
        callToAction: node,
        className: string,
        imageStyle: shape({}),
        addContainerWhite: bool,
        isDesktop: bool.isRequired,
        rowCn: string,
    };

    static defaultProps = {
        title: null,
        description: null,
        imagePath: null,
        pageTitle: null,
        callToAction: null,
        className: null,
        imageStyle: {},
        addContainerWhite: false,
        rowCn: "justify-content-center",
    };

    render() {
        const {
            title,
            description,
            imagePath,
            callToAction,
            className,
            pageTitle,
            imageStyle,
            addContainerWhite,
            isDesktop,
            rowCn
        } = this.props;

        return (
            <div className={className || "d-flex flex-column align-items-center"}>
                {pageTitle}
                <Container
                    aria-live="polite"
                    rowClassName={rowCn}
                    className={classNames("icon-message-page align-items-center flex-grow w-100", {
                        "container-white m-3": addContainerWhite,
                    })}>
                    <Col
                        sm={12}
                        md={8}
                        lg={addContainerWhite ? 4 : 6}
                        xl={addContainerWhite ? 4 : 6}
                        className={classNames("col col-12", { "py-9": addContainerWhite })}>
                        {imagePath && (
                            <Image
                                src={imagePath}
                                styles={imageStyle}
                                wrapperClassName={classNames("svg-wrapper", { "w-100px": !isDesktop })}
                            />
                        )}
                        {title && (
                            <p className={classNames("icon-message-page--title", { "my-2": !addContainerWhite })}>
                                {title}
                            </p>
                        )}
                        {description && <p className="text-lead">{ReactHtmlParser(description)}</p>}
                    </Col>
                </Container>
                <Container aria-live="polite" className="align-items-center flex-grow w-100" rowClassName={rowCn}>
                    <Col sm={12} md={6} lg={2} xl={2} className="col">
                        {callToAction}
                    </Col>
                </Container>
            </div>
        );
    }
}

export default GeneralMsg;
