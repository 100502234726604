import React, { Component } from "react";
import { string, objectOf, bool } from "prop-types";

import formField from "pages/forms/_components/_fields/_commons/formField";

class Intro extends Component {
    static propTypes = {
        lang: string.isRequired,
        hintMap: objectOf(string).isRequired,
        editing: bool.isRequired,
    };

    render() {
        const { hintMap, lang, editing } = this.props;

        if (!editing) {
            return null;
        }

        return <p className="text-lead">{hintMap && hintMap[lang]}</p>;
    }
}

export default formField({ pureRender: true })(Intro);
