import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, arrayOf, string, shape, bool } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import * as i18n from "util/i18n";
import { compose } from "redux";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import Heading from "pages/_components/Heading";
import { accountDescription } from "util/checks";
import InfoTag from "pages/_components/InfoTag";
import { statusTagColorRecurrency, statusTagColorRecurrencyV3 } from "util/debin";
import Hint from "pages/_components/hints/Hint";
import I18n from "pages/_components/I18n";
import { Modal } from "react-bootstrap";
import Button from "pages/_components/Button";
import FormattedDate from "pages/_components/FormattedDate";
import classNames from "classnames";
import * as configUtils from "util/config";

const FORM_ID = "debin.recurrency";

class RecurrencyDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        debinRecurrencies: arrayOf(shape({})).isRequired,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }).isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        seller: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        showModal: false,
    };

    componentDidMount = () => {
        const {
            dispatch,
            match: {
                params: { recurrencyId },
            },
            debinRecurrencies,
        } = this.props;
        if (debinRecurrencies.length > 0) {
            const recurrency = debinRecurrencies.find((object) => object.id === parseInt(recurrencyId, 10));

            dispatch(debinActions.debinSeller(recurrency?.vendedor?.cuit));
        } else {
            this.handleBack();
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    getAccountDescription = (cbu) => {
        const { accounts } = this.props;

        const account = accounts.find((a) => a.cbu === cbu);
        if (account?.cbu) {
            const { productAlias, number, currency, productType } = account;

            return accountDescription(productAlias, number, currency, productType);
        }

        return cbu;
    };

    receivedDebinAction = () => {
        const { action, debin, dispatch } = this.state;
        this.receivedDebinActionArgs(action, debin, dispatch);
    };

    receivedDebinActionArgs = (action, debin, dispatch) => {
        dispatch(debinActions.setRecurrencyDebinAction({ action, debin }));
        dispatch(debinActions.debinSeller(debin?.vendedor?.cuit));
        dispatch(push("/payments/recurrency/action/confirmation"));
    };

    changeStatusRecurrency = (action) => {
        const {
            dispatch,
            match: {
                params: { recurrencyId },
            },
            debinRecurrencies,
        } = this.props;

        const debin = debinRecurrencies.find((object) => object.id === parseInt(recurrencyId, 10));

        if (debin) {
            const useApiV3 = configUtils.getBoolean("frontend.show.RecurringDebin.functionalities", false);
            if(useApiV3  && (action === 'ACTIVAR' || action === 'AUTORIZAR')){
                this.setState({ showModal: true, action, debin, dispatch });
            } else {
                this.receivedDebinActionArgs(action, debin, dispatch);
            }
        }
    };

    getEstado = (recurrency) => {
        if (recurrency.estado === "ACTIVA" && recurrency.autorizado === "AUTORIZADO") {
            return "ACTIVA";
        }
        if (recurrency.estado === "INACTIVA" && recurrency.autorizado === "AUTORIZADO") {
            return "INACTIVA";
        }
        if (
            (recurrency.estado === "ACTIVA" || recurrency.estado === "INACTIVA") &&
            recurrency.autorizado === "PENDIENTE"
        ) {
            return "PENDIENTE";
        }
        if (
            (recurrency.estado === "ACTIVA" || recurrency.estado === "INACTIVA") &&
            recurrency.autorizado === "RECHAZADO"
        ) {
            return "RECHAZADO";
        }
        return recurrency.estado;
    };

    showModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const {
            match: {
                params: { recurrencyId },
            },
            debinRecurrencies,
            seller,
            isDesktop,
        } = this.props;

        const recurrency = debinRecurrencies.find((object) => object.id === parseInt(recurrencyId, 10));
        if (!recurrency) {
            return <></>;
        }
        const useApiV3 = configUtils.getBoolean("frontend.show.RecurringDebin.functionalities", false);
        const estado = useApiV3 ? this.getEstado(recurrency) : recurrency?.estado;
        const { showModal, action } = this.state;
        const recurringbebinAcceptText = configUtils.get("recurringbebin.accept.text", "");
        const recurringbebinAuthorizeText = configUtils.get("recurringbebin.authorize.text", "");

        return (
            <div className="debin-recurrencies__detail">
                <div className={classNames("px-0", { "admin-detail-head": isDesktop })}>
                    <Head
                        onBack={this.handleBack}
                        title={!isDesktop && `${FORM_ID}.detail.title`}
                        headerClassName={classNames({ "blue-main-header-mobile": !isDesktop })}
                        messageTooltip={
                            !isDesktop && recurrency?.estado !== "INACTIVA DEFINITIVA"
                                ? `${FORM_ID}.detail.hint.title.${useApiV3 ? estado : "mobile"}`
                                : null
                        }
                    />

                    <Container
                        rowClassName={recurrency?.estado === "INACTIVA DEFINITIVA" ? "" : "justify-content-center"}>
                        <Col xs={12} md={useApiV3 ? (estado === "PENDIENTE" ? 8 : 10) : 8} className="pl-md-0">
                            <div className="d-flex">
                                <h1 className="debin-recurrencies__title mr-3" style={{ alignContent: "center" }}>
                                    {recurrency?.debin?.prestacion}
                                </h1>
                                <InfoTag
                                    type="info"
                                    message={
                                        recurrency?.estado === "INACTIVA DEFINITIVA"
                                            ? "BLOQUEADA"
                                            : useApiV3
                                            ? this.getEstado(recurrency)
                                            : recurrency?.estado
                                    }
                                    tagBackground={
                                        useApiV3
                                            ? statusTagColorRecurrencyV3(this.getEstado(recurrency))
                                            : statusTagColorRecurrency(recurrency?.estado)
                                    }
                                    moreStyles={{ color: "black" }}
                                    tagClass="align-self-flex-start my-2 0"
                                />
                                {isDesktop && recurrency?.estado !== "INACTIVA DEFINITIVA" && (
                                    <Hint
                                        idMessage={`${FORM_ID}.detail.hint.title${
                                            useApiV3 ? `.${this.getEstado(recurrency)}` : ""
                                        }`}
                                        styleImage={{ margin: 0, padding: "1.5rem 0 1.5rem 0.5rem" }}
                                        styleText={{ alignSelf: "center", marginBottom: "0.5rem" }}
                                        classNameHintP="font-weight-normal"
                                        styleDiv={{ marginBottom: 0 }}
                                        alwaysShowMessage
                                        left
                                    />
                                )}
                            </div>
                            <h3 className="debin-recurrencies__subtitle">{`${i18n.get("debin.recurrency.id")} ${
                                recurrency?.id
                            }`}</h3>
                        </Col>

                        {isDesktop && (
                            <>
                                {!useApiV3 && (
                                    <Col xs={12} md={2} className="pr-md-0">
                                        {estado !== "INACTIVA DEFINITIVA" && (
                                            <Button
                                                className="mt-3 mr-0"
                                                block
                                                bsStyle="primary"
                                                onClick={() => this.changeStatusRecurrency("BLOQUEAR")}
                                                label={`${FORM_ID}.block.recurrency`}
                                            />
                                        )}
                                    </Col>
                                )}
                                <Col xs={12} md={2} className="pr-md-0">
                                    {estado === "ACTIVA" && (
                                        <Button
                                            className="mt-3 mr-0"
                                            block
                                            bsStyle={useApiV3 ? "primary" : "outline"}
                                            onClick={() =>
                                                this.changeStatusRecurrency(useApiV3 ? "DESACTIVAR" : "INACTIVAR")
                                            }
                                            label={`${FORM_ID}.disable.recurrency`}
                                        />
                                    )}
                                    {estado === "INACTIVA" && (
                                        <Button
                                            className="mt-3 mr-0"
                                            block
                                            bsStyle={useApiV3 ? "primary" : "outline"}
                                            onClick={() => this.changeStatusRecurrency("ACTIVAR")}
                                            label={`${FORM_ID}.enable.recurrency`}
                                        />
                                    )}
                                    {estado === "PENDIENTE" && (
                                        <Button
                                            className="mt-3 mr-0"
                                            block
                                            bsStyle="primary"
                                            onClick={() => this.changeStatusRecurrency("AUTORIZAR")}
                                            label={`${FORM_ID}.authorize.recurrency`}
                                        />
                                    )}
                                    {estado === "RECHAZADO" && (
                                        <Button
                                            className="mt-3 mr-0"
                                            block
                                            bsStyle="primary"
                                            onClick={() => this.changeStatusRecurrency("AUTORIZAR")}
                                            label={`${FORM_ID}.authorize.recurrency`}
                                        />
                                    )}
                                </Col>
                                {estado === "PENDIENTE" && (
                                    <Col xs={12} md={2} className="pr-md-0">
                                        <Button
                                            className="mt-3 mr-0"
                                            block
                                            bsStyle="primary"
                                            onClick={() => this.changeStatusRecurrency("RECHAZAR")}
                                            label={`${FORM_ID}.decline.recurrency`}
                                        />
                                    </Col>
                                )}
                            </>
                        )}

                        <Modal show={showModal} className="fixed-term__modal modal-container">
                            <div className="modal-container debin__recurrent-modal-container">
                                <Modal.Header className="">
                                    <Modal.Title>
                                        <div className="debin__recurrent-modal-icon">
                                            <Image src="images/warningIcon.svg" className="svg-icon" />
                                        </div>
                                        <div className="text-center mt-3">
                                            <I18n
                                                componentProps={{ className: `mx-0` }}
                                                id={`debin.recurrency.modal.${
                                                    action === "ACTIVAR"
                                                        ? "accept"
                                                        : action === "AUTORIZAR"
                                                        ? "authorize"
                                                        : ""
                                                }.title`}
                                            />
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>

                                <Modal.Body className="modal-body">
                                    <div className="text-center">
                                        {action === 'ACTIVAR' && recurringbebinAcceptText}
                                        {action === 'AUTORIZAR' && recurringbebinAuthorizeText}
                                    </div>
                                </Modal.Body>

                                <Modal.Footer style={{ width: "100%" }}>
                                    <Button
                                        bsStyle="outline"
                                        label="global.cancel"
                                        block
                                        onClick={() => {
                                            this.closeModal();
                                        }}
                                    />

                                    <Button
                                        bsStyle="primary"
                                        label="global.confirm"
                                        block
                                        onClick={() => {                                            
                                            this.closeModal();
                                            this.receivedDebinAction();
                                        }}
                                    />                                   
                                </Modal.Footer>
                            </div>
                        </Modal>
                    </Container>
                </div>
                <MainContainer>
                    <div className="above-the-fold">
                        <Container
                            className="flex-grow align-items-center container-white with-margin with-padding-1-rem"
                            gridClassName="form-content">
                            <Col xs={12} md={useApiV3 ? 2 : 3} className="p-0">
                                <Heading.DataGroup
                                    containerClassName={classNames(
                                        "data-wrapper-flex",
                                        { "transfer-data data-wrapper-flex-column": isDesktop },
                                        { "transaction-ticket-detail space-between m-0 debin-detail": !isDesktop },
                                    )}
                                    labelClassName="data-label-medium mr-2"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.creation.date${useApiV3 ? "V3" : ""}`}
                                    data={<FormattedDate date={recurrency?.fecha_creacion} />}
                                />
                            </Col>
                            {useApiV3 && (
                                <Col xs={12} md={3} className="p-0">
                                    <Heading.DataGroup
                                        containerClassName={classNames(
                                            "data-wrapper-flex",
                                            { "transfer-data data-wrapper-flex-column": isDesktop },
                                            { "transaction-ticket-detail space-between m-0 debin-detail": !isDesktop },
                                        )}
                                        labelClassName="data-label-medium mr-2"
                                        dataClassName="data-desc"
                                        label={`${FORM_ID}.confirmation.date`}
                                        data={
                                            recurrency?.fecha_confirmacion ? (
                                                <FormattedDate date={recurrency?.fecha_confirmacion} />
                                            ) : (
                                                "-"
                                            )
                                        }
                                    />
                                </Col>
                            )}
                            <Col xs={12} md={3} className="p-0">
                                <Heading.DataGroup
                                    containerClassName={classNames(
                                        "data-wrapper-flex",
                                        { "transfer-data data-wrapper-flex-column": isDesktop },
                                        { "transaction-ticket-detail space-between m-0 debin-detail": !isDesktop },
                                    )}
                                    labelClassName="data-label-medium mr-2"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.cuenta`}
                                    data={this.getAccountDescription(recurrency?.comprador?.cbu)}
                                />
                            </Col>
                            <Col xs={12} md={useApiV3 ? 2 : 3} className="p-0">
                                <Heading.DataGroup
                                    containerClassName={classNames(
                                        "data-wrapper-flex",
                                        { "transfer-data data-wrapper-flex-column": isDesktop },
                                        { "transaction-ticket-detail space-between m-0 debin-detail": !isDesktop },
                                    )}
                                    labelClassName="data-label-medium mr-2"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.seller.name.title`}
                                    data={seller?.titular}
                                />
                            </Col>
                            <Col xs={12} md={useApiV3 ? 2 : 3} className="p-0">
                                <Heading.DataGroup
                                    containerClassName={classNames(
                                        "data-wrapper-flex",
                                        { "transfer-data data-wrapper-flex-column": isDesktop },
                                        { "transaction-ticket-detail space-between m-0 debin-detail": !isDesktop },
                                    )}
                                    labelClassName="data-label-medium mr-2"
                                    dataClassName="data-desc text-align-left w-100"
                                    label={`${FORM_ID}.cuit.vendedor`}
                                    data={recurrency?.vendedor?.cuit}
                                />
                            </Col>
                        </Container>
                        <Container
                            className="flex-grow align-items-center container-white with-margin with-padding-1-rem mt-0"
                            gridClassName="form-content">
                            <Col xs={12} md={useApiV3 ? 2 : 3} className="p-0">
                                <Heading.DataGroup
                                    containerClassName={classNames(
                                        "data-wrapper-flex",
                                        { "transfer-data data-wrapper-flex-column": isDesktop },
                                        { "transaction-ticket-detail space-between m-0 debin-detail": !isDesktop },
                                    )}
                                    labelClassName="data-label-medium mr-2"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.prestacion`}
                                    data={recurrency?.debin?.prestacion}
                                />
                            </Col>
                            <Col xs={12} md={3} className="p-0">
                                <Heading.DataGroup
                                    containerClassName={classNames(
                                        "data-wrapper-flex",
                                        { "transfer-data data-wrapper-flex-column": isDesktop },
                                        { "transaction-ticket-detail space-between m-0 debin-detail": !isDesktop },
                                    )}
                                    labelClassName="data-label-medium mr-2"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.referencia`}
                                    data={recurrency?.debin?.detalle}
                                />
                            </Col>
                            <Col xs={12} md={3} className="p-0">
                                <Heading.DataGroup
                                    containerClassName={classNames(
                                        "data-wrapper-flex",
                                        { "transfer-data data-wrapper-flex-column": isDesktop },
                                        { "transaction-ticket-detail space-between m-0 debin-detail": !isDesktop },
                                    )}
                                    labelClassName="data-label-medium mr-2"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.concepto`}
                                    data={recurrency?.debin?.concepto}
                                />
                            </Col>
                            <Col xs={12} md={useApiV3 ? 4 : 3} className="p-0">
                                <Heading.DataGroup
                                    containerClassName={classNames(
                                        "data-wrapper-flex",
                                        { "transfer-data data-wrapper-flex-column": isDesktop },
                                        { "transaction-ticket-detail space-between m-0 debin-detail": !isDesktop },
                                    )}
                                    labelClassName="data-label-medium mr-2"
                                    dataClassName="data-desc"
                                    label={`${FORM_ID}.descripcion`}
                                    data={recurrency?.debin?.referencia}
                                />
                            </Col>
                            {useApiV3 && <Col xs={12} md={3} className="p-0" />}
                        </Container>
                        {!isDesktop && (
                            <div className="mt-8 pt-4">
                                {!useApiV3 && (
                                    <Col xs={12} md={2} className="pr-md-0">
                                        {estado !== "INACTIVA DEFINITIVA" && (
                                            <Button
                                                className="mt-3 mr-0"
                                                block
                                                bsStyle="primary"
                                                onClick={() => this.changeStatusRecurrency("BLOQUEAR")}
                                                label={`${FORM_ID}.block.recurrency`}
                                            />
                                        )}
                                    </Col>
                                )}
                                <Col xs={12} md={2} className="pr-md-0">
                                    {estado === "ACTIVA" && (
                                        <Button
                                            className="mt-3 mr-0"
                                            block
                                            bsStyle={useApiV3 ? "primary" : "outline"}
                                            onClick={() =>
                                                this.changeStatusRecurrency(useApiV3 ? "DESACTIVAR" : "INACTIVAR")
                                            }
                                            label={`${FORM_ID}.disable.recurrency`}
                                        />
                                    )}
                                    {estado === "INACTIVA" && (
                                        <Button
                                            className="mt-3 mr-0"
                                            block
                                            bsStyle={useApiV3 ? "primary" : "outline"}
                                            onClick={() => this.changeStatusRecurrency("ACTIVAR")}
                                            label={`${FORM_ID}.enable.recurrency`}
                                        />
                                    )}
                                    {estado === "PENDIENTE" && (
                                        <Button
                                            className="mt-3 mr-0"
                                            block
                                            bsStyle="primary"
                                            onClick={() => this.changeStatusRecurrency("AUTORIZAR")}
                                            label={`${FORM_ID}.authorize.recurrency`}
                                        />
                                    )}
                                    {estado === "RECHAZADO" && (
                                        <Button
                                            className="mt-3 mr-0"
                                            block
                                            bsStyle="primary"
                                            onClick={() => this.changeStatusRecurrency("AUTORIZAR")}
                                            label={`${FORM_ID}.authorize.recurrency`}
                                        />
                                    )}
                                </Col>
                                {estado === "PENDIENTE" && (
                                    <Col xs={12} md={2} className="pr-md-0">
                                        <Button
                                            className="mt-3 mr-0"
                                            block
                                            bsStyle="primary"
                                            onClick={() => this.changeStatusRecurrency("RECHAZAR")}
                                            label={`${FORM_ID}.decline.recurrency`}
                                        />
                                    </Col>
                                )}
                            </div>
                        )}
                    </div>
                </MainContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    debinRecurrencies: debinSelectors.getRecurrenciasDebin(state),
    accounts: debinSelectors.getAccounts(state),
    seller: debinSelectors.getSeller(state),
});

export default compose(connect(mapStateToProps))(RecurrencyDetail);
