import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, func, bool, arrayOf } from "prop-types";

import { selectors as ecomexSelectors, actions as ecomexActions } from "reducers/ecomex";

import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Wizard from "pages/_components/Wizard";
import Table from "pages/_components/Table";
import Image from "pages/_components/Image";

import Heading from "pages/_components/Heading";

const FORM_ID = "ecomex.consultOperations.detail";

class EcomexOperationsDetailList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        selectedOperation: shape({}).isRequired,
        isDesktop: bool.isRequired,
        fetchingDownload: bool.isRequired,
        optionSelected: shape({}).isRequired,
        settlementsDetail: arrayOf(shape({})).isRequired,
        notificationsDetail: arrayOf(shape({})).isRequired,
        swiftMessagesDetail: arrayOf(shape({})).isRequired,
        ticketsDetail: arrayOf(shape({})).isRequired,
        duesDetail: arrayOf(shape({})).isRequired,
        dueDatesDetail: arrayOf(shape({})).isRequired,
        usesDetail: arrayOf(shape({})).isRequired,
    };

    constructor(props) {
        super(props);
        const { optionSelected, selectedOperation } = this.props;
        const { siglaOperacion } = selectedOperation;

        let tabSelectedNewState = "settlements";

        if (siglaOperacion === "CDI" || siglaOperacion === "CDE") {
            tabSelectedNewState = "uses";
        } else if (siglaOperacion === "COI" || siglaOperacion === "COE") {
            tabSelectedNewState = "dueDates";
        } else if (optionSelected === "3") {
            tabSelectedNewState = "dues";
        }

        this.state = {
            tabSelected: tabSelectedNewState,
        };
    }

    renderHeaderElements = () => {
        const { isDesktop } = this.props;
        const { tabSelected } = this.state;

        let headerElements = [];

        const getHeaderData = (text, aling) => {
            const alignHeader = isDesktop ? aling : "left";

            if (isDesktop) {
                return (
                    <Table.HeaderData align={alignHeader}>
                        <I18n id={`${FORM_ID}.${text}`} />
                    </Table.HeaderData>
                );
            }

            return text;
        };

        switch (tabSelected) {
            case "settlements":
            case "notifications":
                headerElements = [
                    getHeaderData("column.identification", "left"),
                    getHeaderData("column.date", "left"),
                    getHeaderData("column.show", "right"),
                ];
                break;
            case "swiftMessages":
                headerElements = [
                    getHeaderData("column.identification", "left"),
                    getHeaderData("column.date"),
                    getHeaderData("column.type"),
                    getHeaderData("column.situation"),
                    getHeaderData("column.show", "right"),
                ];
                break;
            case "tickets":
                headerElements = [
                    getHeaderData("column.reference", "left"),
                    getHeaderData("column.bcra"),
                    getHeaderData("column.buyAndSell"),
                    getHeaderData("column.openDate"),
                    getHeaderData("column.beneficiaryName"),
                    getHeaderData("column.currency"),
                    getHeaderData("column.extractMaxAmount"),
                    getHeaderData("column.exchangeType"),
                    getHeaderData("column.localMaxAmount", "right"),
                ];
                break;
            case "dues":
                headerElements = [
                    getHeaderData("column.reference", "left"),
                    getHeaderData("column.openDate"),
                    getHeaderData("column.dueDate"),
                    getHeaderData("column.currency"),
                    getHeaderData("column.interestRate", "right"),
                    getHeaderData("column.plausibleInterest", "right"),
                    getHeaderData("column.totalInterest", "right"),
                ];
                break;
            case "dueDates":
            case "uses":
                headerElements = [
                    getHeaderData("column.reference", "left"),
                    getHeaderData("column.openDate"),
                    getHeaderData("column.dueDate"),
                    getHeaderData("column.lastSettlementDate"),
                    getHeaderData("column.beneficiaryName", "left"),
                    getHeaderData("column.currency"),
                    getHeaderData("column.amount", "right"),
                    getHeaderData("column.ended", "right"),
                    getHeaderData("column.balance", "right"),
                ];
                break;
            default:
                break;
        }

        return headerElements;
    };

    isNotNull = (tabSelected, listToRender) => {
        let isNotNullList = true;

        switch (tabSelected) {
            case "swiftMessages":
                isNotNullList =
                    listToRender.fecha && listToRender.mur && listToRender.mensaje && listToRender.situacion;
                break;
            case "tickets":
                isNotNullList =
                    listToRender.bcra &&
                    listToRender.cv &&
                    listToRender.fechaOrigen &&
                    listToRender.nombreBeneficiario &&
                    listToRender.siglaDivisa &&
                    listToRender.importeMx &&
                    listToRender.cotizacion &&
                    listToRender.importeMl;
                break;
            default:
                break;
        }
        return isNotNullList;
    };

    renderLists = () => {
        const {
            isDesktop,
            settlementsDetail,
            notificationsDetail,
            swiftMessagesDetail,
            ticketsDetail,
            duesDetail,
            dueDatesDetail,
            usesDetail,
        } = this.props;

        const { tabSelected } = this.state;
        let listToRender;

        switch (tabSelected) {
            case "settlements":
                listToRender = settlementsDetail;
                break;
            case "notifications":
                listToRender = notificationsDetail;
                break;
            case "swiftMessages":
                listToRender = this.isNotNull(tabSelected, swiftMessagesDetail[0]) ? swiftMessagesDetail : [];
                break;
            case "tickets":
                listToRender = this.isNotNull(tabSelected, ticketsDetail[0]) ? ticketsDetail : [];
                break;
            case "dues":
                listToRender = duesDetail;
                break;
            case "dueDates":
                listToRender = dueDatesDetail;
                break;
            case "uses":
                listToRender = usesDetail;
                break;
            default:
                break;
        }

        return (
            <>
                {listToRender && listToRender.length > 0 ? (
                    <Container className="flex-grow scrollable">
                        <Col md={12} className="with-table-header-margin px-0">
                            <Table className="ecomex-op-details-tables">
                                {isDesktop && (
                                    <Table.Header className="container-white">
                                        {this.renderHeaderElements()}
                                    </Table.Header>
                                )}
                                <Table.Body>{listToRender.map((item) => this.renderListsItem(item))}</Table.Body>
                            </Table>
                        </Col>
                    </Container>
                ) : (
                    <div className="text-center no-more-data">
                        <div className="illustration-wrapper">
                            <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                        </div>
                        <p className="text-lead">
                            <I18n id={`${FORM_ID}.empty.message`} />
                        </p>
                    </div>
                )}
            </>
        );
    };

    handleExport = (identification, format, fileName) => {
        const { dispatch } = this.props;
        const { tabSelected } = this.state;

        dispatch(ecomexActions.downloadOperationsDetail(tabSelected, identification, format, fileName));
    };

    renderListsItem = (item) => {
        const { tabSelected } = this.state;

        switch (tabSelected) {
            case "settlements":
            case "notifications":
                return this.renderListsItemForSettlementsAndNotifications(item);
            case "swiftMessages":
                return this.renderListsItemForSwiftMessages(item);
            case "tickets":
                return this.renderListsItemForTickets(item);
            case "dues":
                return this.renderListsItemForDues(item);
            case "dueDates":
                return this.renderListsItemForDueDates(item);
            case "uses":
                return this.renderListsItemForUses(item);
            default:
                return null;
        }
    };

    renderListsItemForSettlementsAndNotifications = (item) => {
        const { isDesktop, fetchingDownload } = this.props;
        const { fecha, identificacion, formatoArchivo, nombreArchivo } = item;

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    <Table.Data align="left">
                        <div className="data-text light-font">{identificacion}</div>
                    </Table.Data>
                    <Table.Data align="left">
                        <div className="data-text light-font">{fecha}</div>
                    </Table.Data>
                    <Table.Data align="right" className="pr-0">
                        <div className="data-text light-font">
                            <Button
                                image="images/download_bold.svg"
                                className="btn-only-icon border-none ecomex-detail-download-icon svg25"
                                block={false}
                                bsStyle="link"
                                onClick={() => this.handleExport(identificacion, formatoArchivo, nombreArchivo)}
                                disabled={fetchingDownload}
                            />
                        </div>
                    </Table.Data>
                </Table.Row>
            );
        }

        return (
            <Table.Row className="container-white d-flex ecomex-row-mobile py-2">
                <Col className="col-10 pr-0">
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.identification`}
                        data={<div className="data-text">{identificacion} </div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.date`}
                        data={<div className="data-text">{fecha} </div>}
                    />
                </Col>

                <Col className="col-2 pl-0">
                    <Button
                        image="images/download_bold.svg"
                        className="btn-only-icon btn-icon border-none ecomex-detail-download-icon"
                        block={false}
                        bsStyle="link"
                        onClick={() => this.handleExport(identificacion, formatoArchivo, nombreArchivo)}
                        disabled={fetchingDownload}
                    />
                </Col>
            </Table.Row>
        );
    };

    renderListsItemForSwiftMessages = (item) => {
        const { isDesktop, fetchingDownload } = this.props;
        const { fecha, mur, mensaje, situacion } = item;

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    <Table.Data align="left">
                        <div className="data-text light-font">{mur}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{fecha}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{mensaje}</div>
                    </Table.Data>

                    <Table.Data>
                        <div className="data-text light-font">{situacion.toUpperCase()}</div>
                    </Table.Data>
                    <Table.Data align="right" className="pr-0">
                        <div className="data-text">
                            <Button
                                image="images/download_bold.svg"
                                className="btn-only-icon border-none ecomex-detail-download-icon svg25"
                                block={false}
                                bsStyle="link"
                                onClick={() => this.handleExport(mur, "pdf", "")}
                                disabled={fetchingDownload}
                            />
                        </div>
                    </Table.Data>
                </Table.Row>
            );
        }

        return (
            <Table.Row className="container-white d-flex ecomex-row-mobile py-2">
                <Col className="col-10 pr-0">
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.identification`}
                        data={<div className="data-text">{mur}</div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.date`}
                        data={<div className="data-text">{fecha}</div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.type`}
                        data={<div className="data-text">{mensaje}</div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.situation`}
                        data={<div className="data-text">{situacion.toUpperCase()}</div>}
                    />
                </Col>

                <Col className="col-2 pl-0  d-flex">
                    <Button
                        image="images/download_bold.svg"
                        className="btn-only-icon btn-icon border-none ecomex-detail-download-icon align-self-center"
                        block={false}
                        bsStyle="link"
                        onClick={() => this.handleExport(mur, "pdf", "")}
                        disabled={fetchingDownload}
                    />
                </Col>
            </Table.Row>
        );
    };

    renderListsItemForTickets = (item) => {
        const { isDesktop } = this.props;
        const {
            siglaOperacion,
            sucursalOperacion,
            numeroOperacion,
            secuenciaUtilizacion,
            secuenciaLiquidacion,
            bcra,
            cv,
            fechaOrigen,
            nombreBeneficiario,
            siglaDivisa,
            importeMx,
            cotizacion,
            importeMl,
        } = item;

        let title = `${siglaOperacion}-${sucursalOperacion}-${numeroOperacion}`;
        title = `${title}/${secuenciaUtilizacion}/${secuenciaLiquidacion}`;

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    <Table.Data align="left">
                        <div className="data-text light-font">{title}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{bcra}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{cv}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{fechaOrigen}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{nombreBeneficiario}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{siglaDivisa}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{importeMx}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{cotizacion}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{importeMl}</div>
                    </Table.Data>
                </Table.Row>
            );
        }

        return (
            <Table.Row className="container-white d-flex ecomex-row-mobile py-2">
                <Col className="col-12">
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.reference`}
                        data={title}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.bcra`}
                        data={<div className="data-text">{bcra} </div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.buyAndSell`}
                        data={<div className="data-text">{cv} </div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.openDate`}
                        data={<div className="data-text">{fechaOrigen} </div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.beneficiaryName`}
                        data={<div className="data-text">{nombreBeneficiario} </div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.currency`}
                        data={<div className="data-text">{siglaDivisa} </div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.extractMaxAmount`}
                        data={<div className="data-text">{importeMx} </div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.exchangeType`}
                        data={<div className="data-text">{cotizacion} </div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.localMaxAmount`}
                        data={<div className="data-text">{importeMl} </div>}
                    />
                </Col>
            </Table.Row>
        );
    };

    renderListsItemForDues = (item) => {
        const { isDesktop } = this.props;
        const {
            siglaOperacion,
            sucursalOperacion,
            numeroOperacion,
            fechaInicio,
            fechaVencimiento,
            siglaDivisa,
            tasa,
            intFecha,
            intVto,
        } = item;

        const elementForList = [
            `${siglaOperacion}-${sucursalOperacion}-${numeroOperacion}`,
            fechaInicio,
            fechaVencimiento,
            siglaDivisa,
            tasa,
            intFecha,
            intVto,
        ];

        const alignments = ["left", "center", "center", "center", "right", "right", "right"];

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    {elementForList.map((elementItem, index) => (
                        <Table.Data align={alignments[index]}>
                            <div className="data-text light-font">{elementItem}</div>
                        </Table.Data>
                    ))}
                </Table.Row>
            );
        }

        const headerElements = this.renderHeaderElements();

        return (
            <Table.Row className="container-white d-flex ecomex-row-mobile py-2">
                <Col className="col-12">
                    {elementForList.map((elementItem, index) => (
                        <Heading.DataGroup
                            containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                            dataClassName="data-desc small-font flex-wrap"
                            label={`${FORM_ID}.${headerElements[index]}`}
                            data={<div className="data-text">{elementItem} </div>}
                        />
                    ))}
                </Col>
            </Table.Row>
        );
    };

    renderListsItemForDueDates = (item) => {
        const { isDesktop } = this.props;
        const {
            siglaOperacion,
            sucursalOperacion,
            numeroOperacion,
            fechaOrigen,
            fechaVencimiento,
            siglaDivisa,
            importe,
            importeLiquidado,
            saldo,
            nombreBeneficiario,
            secuenciaUtilizacion,
        } = item;

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    <Table.Data align="left">
                        <div className="data-text light-font">{`${siglaOperacion}-${sucursalOperacion}-${numeroOperacion}/${secuenciaUtilizacion}`}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{fechaOrigen}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{fechaVencimiento}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font" />
                    </Table.Data>
                    <Table.Data align="left">
                        <div className="data-text light-font">{nombreBeneficiario}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{siglaDivisa}</div>
                    </Table.Data>
                    <Table.Data align="right">
                        <div className="data-text light-font">{importe}</div>
                    </Table.Data>
                    <Table.Data align="right">
                        <div className="data-text light-font">{importeLiquidado}</div>
                    </Table.Data>
                    <Table.Data align="right">
                        <div className="data-text light-font">{saldo}</div>
                    </Table.Data>
                </Table.Row>
            );
        }

        return (
            <div className="account-table-wrapper account-table-header">
                <Col sm={6} className="col col-6 align-self-center text-align-left">
                    <div className="data-text">{`${siglaOperacion}-${sucursalOperacion}-${numeroOperacion}/${secuenciaUtilizacion}`}</div>
                    <div className="data-text">{fechaOrigen}</div>
                    <div className="data-text">{fechaVencimiento}</div>
                    <div className="data-text">{nombreBeneficiario}</div>
                    <div className="data-text">{siglaDivisa}</div>
                    <div className="data-text">{importe}</div>
                </Col>
                <Col sm={6} className="col col-6 align-self-center text-align-center ">
                    <div className="data-text">{importeLiquidado}</div>
                    <div className="data-text">{saldo}</div>
                </Col>
            </div>
        );
    };

    renderListsItemForUses = (item) => {
        const { isDesktop } = this.props;
        const {
            siglaOperacion,
            sucursalOperacion,
            numeroOperacion,
            fechaOrigen,
            fechaVencimiento,
            fechaCancelacion,
            siglaDivisa,
            importe,
            importeLiquidado,
            saldo,
            nombreBeneficiario,
            secuenciaUtilizacion,
        } = item;

        if (isDesktop) {
            return (
                <Table.Row className="container-white">
                    <Table.Data align="left">
                        <div className="data-text light-font">{`${siglaOperacion}-${sucursalOperacion}-${numeroOperacion}/${secuenciaUtilizacion}`}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{fechaOrigen}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{fechaVencimiento}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{fechaCancelacion}</div>
                    </Table.Data>
                    <Table.Data>
                        <div className="data-text light-font">{nombreBeneficiario}</div>
                    </Table.Data>
                    <Table.Data align="right">
                        <div className="data-text light-font">{siglaDivisa}</div>
                    </Table.Data>
                    <Table.Data align="right">
                        <div className="data-text light-font">{importe}</div>
                    </Table.Data>
                    <Table.Data align="right">
                        <div className="data-text light-font">{importeLiquidado}</div>
                    </Table.Data>
                    <Table.Data align="right">
                        <div className="data-text light-font">{saldo}</div>
                    </Table.Data>
                </Table.Row>
            );
        }

        return (
            <Table.Row className="container-white d-flex ecomex-row-mobile py-2">
                <Col className="col-12">
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.reference`}
                        data={
                            <div className="data-text">{`${siglaOperacion}-${sucursalOperacion}-${numeroOperacion}/${secuenciaUtilizacion}`}</div>
                        }
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.openDate`}
                        data={<div className="data-text">{fechaOrigen}</div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.dueDate`}
                        data={<div className="data-text">{fechaVencimiento}</div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.lastSettlementDate`}
                        data={<div className="data-text">{fechaCancelacion}</div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.beneficiaryName`}
                        data={<div className="data-text">{nombreBeneficiario}</div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.currency`}
                        data={<div className="data-text">{siglaDivisa}</div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.amount`}
                        data={<div className="data-text">{importe}</div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.ended`}
                        data={<div className="data-text">{importeLiquidado}</div>}
                    />
                    <Heading.DataGroup
                        containerClassName="transfer-data data-wrapper-flex ecomex-beneficiary space-between"
                        dataClassName="data-desc small-font flex-wrap"
                        label={`${FORM_ID}.column.balance`}
                        data={<div className="data-text">{saldo}</div>}
                    />
                </Col>
            </Table.Row>
        );
    };

    handleTabChange = (newTabSelected) => {
        this.setState({ tabSelected: newTabSelected });
    };

    renderDetailListHeader = () => {
        const { isDesktop, optionSelected, selectedOperation } = this.props;
        const { tabSelected } = this.state;
        const { siglaOperacion } = selectedOperation;

        const renderTabs = (tabName) => (
            <Wizard.Tab>
                <Button
                    className={`account-detail-tab-button ${isDesktop ? "cmf-button-width" : ""} ${
                        tabSelected === tabName ? "is-selected" : ""
                    }`}
                    bsStyle="link"
                    label={`${FORM_ID}.${tabName}`}
                    type="button"
                    withoutRedirect
                    additionalNavItemClass={isDesktop ? "account-detail-tab" : "ecomex-detail-tab"}
                    onClick={() => this.handleTabChange(tabName)}
                />
            </Wizard.Tab>
        );

        const tabs = [
            (siglaOperacion === "CDI" || siglaOperacion === "CDE") && renderTabs("uses"),
            (siglaOperacion === "COI" || siglaOperacion === "COE") && renderTabs("dueDates"),
            optionSelected === "3" && renderTabs("dues"),
            renderTabs("settlements"),
            renderTabs("notifications"),
            renderTabs("swiftMessages"),
            renderTabs("tickets"),
        ];

        return <Wizard.Tabs>{tabs}</Wizard.Tabs>;
    };

    render() {
        return (
            <>
                <div className="tab-container">{this.renderDetailListHeader()}</div>
                {this.renderLists()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetchingDownload: ecomexSelectors.getFetchingDownload(state),
    optionSelected: ecomexSelectors.getOptionSelected(state),
    selectedOperation: ecomexSelectors.getSelectedOperation(state),
    settlementsDetail: ecomexSelectors.getSettlementsDetail(state),
    notificationsDetail: ecomexSelectors.getNotificationsDetail(state),
    swiftMessagesDetail: ecomexSelectors.getSwiftMessagesDetail(state),
    ticketsDetail: ecomexSelectors.getTicketsDetail(state),
    duesDetail: ecomexSelectors.getDuesDetail(state),
    dueDatesDetail: ecomexSelectors.getDueDatesDetail(state),
    usesDetail: ecomexSelectors.getUsesDetail(state),
});

export default connect(mapStateToProps)(EcomexOperationsDetailList);
