import * as API from "middleware/api";

export const listMetrocorp = (optionSelected, principalAccount, species, date, dateFrom, dateTo, page) =>
    API.executeWithAccessToken("/metrocorp.list", {
        optionSelected,
        principalAccount,
        species,
        date,
        dateFrom,
        dateTo,
        page
    });

export const listMetrocorpPre = () => API.executeWithAccessToken("/metrocorp.list.pre");

export const downloadList = (summary, format) =>
    API.executeWithAccessToken("/metrocorp.downloadList", { summary, format });

export const getDetail = (reference) => API.executeWithAccessToken("/metrocorp.detail", { reference });

export const downloadDetail = (detail, format) =>
    API.executeWithAccessToken("/metrocorp.downloadDetail", { detail, format });

export const downloadDetailByReference = (reference, format) =>
    API.executeWithAccessToken("/metrocorp.downloadDetail.reference", { reference, format });
