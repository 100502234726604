import React, { Fragment } from "react";
import I18n from "pages/_components/I18n";
import { string, number } from "prop-types";

function PasswordHelp({ formId, minLength, maxLength }) {
    const FORM_ID = formId;
    return (
        <Fragment>
            <div className="password-rules" style={{ marginBottom: "2rem" }}>
                <ul>
                    <li>
                        <I18n id={`${FORM_ID}.passwordHelp.rule1`} MIN_LENGTH={minLength} MAX_LENGTH={maxLength} />
                    </li>
                    <li>
                        <I18n id={`${FORM_ID}.passwordHelp.rule2`} />
                    </li>
                    <li>
                        <I18n id={`${FORM_ID}.passwordHelp.rule3`} />
                    </li>
                    <li>
                        <I18n id={`${FORM_ID}.passwordHelp.rule4`} />
                    </li>
                    <li>
                        <I18n id={`${FORM_ID}.passwordHelp.rule5`} />
                    </li>
                </ul>
            </div>
        </Fragment>
    );
}

PasswordHelp.propTypes = {
    formId: string.isRequired,
    minLength: number.isRequired,
    maxLength: number.isRequired,
};

PasswordHelp.defaultProps = {};

export default PasswordHelp;
