import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Col } from "react-bootstrap";
import { func, bool, shape, string } from "prop-types";
import classNames from "classnames";

import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import * as configUtils from "util/config";

class FingerprintDeleteConfirmation extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        device: shape({
            deviceId: string.isRequired,
            deviceModel: string.isRequired,
            idSession: string.isRequired,
        }).isRequired,
        fetching: bool,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    componentDidMount() {
        const { dispatch, device } = this.props;
        if (!device?.deviceId) {
            dispatch(push("/settings"));
        }
    }

    deleteDevice = (idSession) => () => {
        const { dispatch } = this.props;
        dispatch(fingerprintActions.fingerprintDeleteDevice(idSession));
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/settings/fingerprintConfiguration"));
    };

    getContent = () => {
        const {
            isDesktop,
            device: { deviceId, deviceModel, idSession },
        } = this.props;

        const deleteWarning = configUtils.get("biometric.DeleteBiometrics.Text");

        return (
            deviceId && (
                <div className="confirmation__container">
                    <Container
                        className={!isDesktop && "flex-grow align-items-center container-white pb-4"}
                        gridClassName={isDesktop && "confirmation__form confirmation__box"}>
                        <Col sm={12} md={12} lg={12} xl={12} className="col">
                            <I18n
                                id="settings.fingerprintConfiguration.deleteConfirmation.action"
                                component="p"
                                componentProps={{ className: "my-3" }}
                            />
                            <div className="box-shadow border-radius space-between w-100 py-2 px-4 my-4">
                                <I18n id={`devices.apple.identifier.${deviceModel}`} defaultValue={deviceModel} />
                                <span>{deviceId?.substring(deviceId.length - 4, deviceId.length)}</span>
                            </div>
                            <p className="my-3">{deleteWarning}</p>
                            <div className="admin-content-center my-4">
                                <I18n
                                    id="confirmation.withoutotp.text"
                                    component="p"
                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                />
                            </div>
                            <div className="flex-grow align-items-center w-100">
                                <Col lg={12} md={12} sm={12} className="px-0">
                                    <Col className="confirmation__content-button px-0" lg={12} md={12} sm={12}>
                                        <div className="admin-content-center">
                                            <Button
                                                label="global.confirm"
                                                bsStyle="primary"
                                                block={false}
                                                onClick={this.deleteDevice(idSession)}
                                                className="text-capitalize w-100"
                                                image="images/arrowRight.svg"
                                                imageStyle="mr-2"
                                            />
                                        </div>
                                    </Col>
                                </Col>
                            </div>
                        </Col>
                    </Container>
                </div>
            )
        );
    };

    render() {
        const { fetching, isDesktop } = this.props;

        return (
            <>
                <Head
                    title={!isDesktop && "settings.biometrics"}
                    headerClassName={classNames({
                        "blue-main-header-mobile": !isDesktop,
                        "admin-detail-head h-auto ml-3": isDesktop,
                    })}
                    onBack={this.handleBack}
                />
                {isDesktop && (
                    <I18n id="settings.biometrics" component="h1" componentProps={{ className: "mt-0 ml-3" }} />
                )}
                <MainContainer showLoader={fetching}>{this.getContent()}</MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: fingerprintSelectors.getFetching(state),
    device: fingerprintSelectors.getDevice(state),
});

export default connect(mapStateToProps)(FingerprintDeleteConfirmation);
