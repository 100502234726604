import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { string, shape, func, bool } from "prop-types";

import * as i18nUtils from "util/i18n";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import classNames from "classnames";

class AgendaDetailPaymentInfo extends Component {
    static propTypes = {
        FORM_ID: string.isRequired,
        agendaPago: shape({}).isRequired,
        handleBack: func.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        isDesktop: false,
    };

    componentDidMount() {
        const { agendaPago, handleBack } = this.props;

        if (!agendaPago) {
            handleBack();
        }
    }

    render() {
        const currencies = [{ id: 0, label: i18nUtils.get(`currency.label.0`) }];
        const { FORM_ID, agendaPago, isDesktop } = this.props;
        const handleBaseDebts = agendaPago?.entity?.handleBaseDebts;
        return (
            <Container
                className={classNames("flex-grow container-white text-uppercase", {
                    "mt-2": isDesktop,
                })}>
                <Col className="px-0" md={12} xl={12} lg={12} sm={12}>
                    <Col md={3} xl={3} lg={3} sm={12}>
                        <Heading.DataGroup
                            dataClassName="data-desc small-font"
                            containerClassName={`transfer-data data-wrapper-flex my-2 ${!isDesktop && "space-between"}`}
                            label={`${FORM_ID}.cpe.label`}
                            data={agendaPago?.ususerLP}
                        />
                    </Col>
                    <Col md={3} xl={3} lg={3} sm={12}>
                        <Heading.DataGroup
                            dataClassName="data-desc small-font"
                            containerClassName={`transfer-data data-wrapper-flex my-2 ${!isDesktop && "space-between"}`}
                            label={`${FORM_ID}.concept`}
                            data={
                                handleBaseDebts
                                    ? agendaPago?.expiration?.concept?.description
                                    : agendaPago?.concept?.description
                            }
                        />
                    </Col>
                    <Col md={3} xl={3} lg={3} sm={12}>
                        <Heading.DataGroup
                            dataClassName="data-desc small-font"
                            containerClassName={`transfer-data data-wrapper-flex my-2 ${!isDesktop && "space-between"}`}
                            label={`${FORM_ID}.expiration`}
                            data={
                                handleBaseDebts ? (
                                    <FormattedDate date={agendaPago?.expiration?.date} dateFormat="dd/MM/yyyy" />
                                ) : (
                                    <span>{i18nUtils.get("services.newPayment.noExpirationDate")}</span>
                                )
                            }
                        />
                    </Col>
                    <Col md={3} xl={3} lg={3} sm={12}>
                        <Heading.DataGroup
                            dataClassName="data-desc small-font"
                            containerClassName={`transfer-data data-wrapper-flex my-2 ${!isDesktop && "space-between"}`}
                            label={`${FORM_ID}.amountToPay`}
                            data={
                                handleBaseDebts ? (
                                    <FormattedAmount
                                        className="data-amount"
                                        currency={currencies[0].id.toString()}
                                        quantity={agendaPago?.expiration?.amount}
                                        notBold
                                        small
                                    />
                                ) : (
                                    <span> - </span>
                                )
                            }
                        />
                    </Col>
                </Col>
            </Container>
        );
    }
}

export default AgendaDetailPaymentInfo;
