import * as API from "middleware/api";
import { credentialsToUnderscoreFormat } from "util/form";
import * as configUtils from "util/config";
import * as restAPI from "middleware/apiRESTful";

const getMSEndpointByForm = (idForm, isPreview) => {
    if (idForm.includes("transfer")) {
        if (isPreview) {
            return `/api/transfers/v1/${idForm}/preview`;
        }
        return `/api/transfers/v1/${idForm}`;
    }
    return "";
};

export const readForm = (idForm, data) =>
    API.executeWithAccessToken("/core.forms.read", {
        idForm,
        ...data,
    });

export const preview = (idForm, idActivity, idTransaction, data) => {
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        return restAPI.postWithAccessToken(getMSEndpointByForm(idForm, true), {
            idForm,
            idTransaction,
            ...data,
        });
    }
    return API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
        ...data,
    });
};

export const send = (idForm, idActivity, idTransaction, data, credentials) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        const params = {
            ...data,
            credentials: { ...credentialsWithUnderscore },
        };
        return restAPI.postWithAccessToken(getMSEndpointByForm(idForm, false), {
            idForm,
            idTransaction,
            ...params,
        });
    }
    const params = {
        ...data,
        ...credentialsWithUnderscore,
    };
    return API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
        ...params,
    });
};

export const signPreview = (idForm, idActivity, idTransaction) => {
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        return restAPI.postWithAccessToken(getMSEndpointByForm(idForm, true), {
            idForm,
            transactionId: idTransaction,
        });
    }
    return API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
    });
};

export const sign = (idForm, idActivity, idTransaction, credentials) => {
    const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
    if (configUtils.getArray("ms.composite.migratedForms").includes(idForm)) {
        return restAPI.postWithAccessToken(getMSEndpointByForm(idForm, false), {
            idForm,
            transactionId: idTransaction,
            credentials: { ...credentialsWithUnderscore },
        });
    }
    return API.executeWithAccessToken(`/${idActivity}`, {
        idForm,
        idTransaction,
        ...credentialsWithUnderscore,
    });
};

export const listCredentialsGroups = (idForm, idActivity) =>
    API.executeWithAccessToken("/core.listTransactionCredentialsGroups", {
        idActivityToRead: idActivity,
        idForm,
    });

export const readTransaction = (idTransaction) =>
    API.executeWithAccessToken("/core.readTransaction", {
        idTransactionToRead: idTransaction,
    });

export const saveDraft = (idForm, transactionData, idActivityDraft, idTransactionToSave, scheduler) =>
    API.executeWithAccessToken("/core.saveDraftTransaction", {
        idForm,
        idActivityDraft,
        idTransactionToSave,
        transactionData,
        scheduler,
    });

export const saveTemplate = (idForm, data, templateName, idActivityTemplate) =>
    API.executeWithAccessToken("/core.createTransactionTemplate", {
        idForm,
        transactionData: data,
        idActivityTemplate,
        templateName,
    });

export const cancelTransaction = (idTransactionToCancel, credentials) =>
    API.executeWithAccessToken("/core.cancelTransaction", {
        idTransactionToCancel,
        ...credentials,
    });

export const forceCancelTransaction = (idTransactionToCancel, credentials) =>
    API.executeWithAccessToken("/core.forceCancelTransaction", {
        idTransactionToCancel,
        ...credentials,
    });

export const updateUserEditor = (transactionID, userId, status, pendingDispatch, rejectedTransactions) =>
    API.executeWithAccessToken("/transactions.update.editor", {
        transactionID,
        userId,
        status,
        pendingDispatch,
        rejectedTransactions,
    });

export const moveToDraftTransaction = (idTransactionToMove) =>
    API.executeWithAccessToken("/core.moveToDraftTransaction", {
        idTransactionToMove,
    });

export const readTransactionFromBackoffice = (exchangeToken) =>
    API.executeAnonymous("/core.forms.backoffice.read", { _backofficeExchangeToken: exchangeToken });

export const downloadTicket = (idTicket, fileFormat, idForm) =>
    API.executeWithAccessToken("/transactions.downloadTicket", {
        idTicket,
        format: fileFormat,
        idForm,
    });
export const download = (idTicket, fileFormat, activityName) =>
    API.executeWithAccessToken("/transactions.download", {
        idTicket,
        format: fileFormat,
        activityName,
    });

export const updateScheduledTransaction = (idTransaction, schedulerData) =>
    API.executeWithAccessToken("/scheduled.transactions.update", {
        idTransactionToUpdate: idTransaction,
        schedulerData,
    });
