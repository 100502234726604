import React, { Component, Fragment } from "react";
import { shape } from "prop-types";
import { connect } from "react-redux";

import { selectors as formSelectors } from "reducers/form";

import I18n from "pages/_components/I18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import ProductTag from "pages/_components/ProductTag";
import Image from "pages/_components/Image";

class TransferInternal extends Component {
    static propTypes = {
        previewData: shape({}).isRequired,
    };

    render() {
        const { previewData } = this.props;

        const {
            creditReference,
            amount,
            debitAmount,
            debitAccountAlias,
            creditAmount,
            creditAccountAlias,
        } = previewData;

        const showDebitAmount = debitAmount.currency !== amount.currency;
        const showCreditAmount = creditAmount.currency !== amount.currency;

        return (
            <Fragment>
                <p className="text-lead">
                    <I18n id="forms.transferInternal.confirmation.title" />
                </p>

                <div className="transfer-block">
                    <div className="transfer-data transfer-data-highlight data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transferInternal.confirmation.amount" />{" "}
                        </span>
                        <FormattedAmount currency={amount.currency} quantity={amount.quantity} />
                    </div>

                    {showCreditAmount && (
                        <div className="transfer-data data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.transferInternal.confirmation.creditAmount" />{" "}
                            </span>
                            <FormattedAmount currency={creditAmount.currency} quantity={creditAmount.quantity} />
                        </div>
                    )}

                    {showDebitAmount && (
                        <div className="transfer-data data-wrapper">
                            <span className="data-label">
                                <I18n id="forms.transferInternal.confirmation.debitAmount" />{" "}
                            </span>
                            <FormattedAmount currency={debitAmount.currency} quantity={debitAmount.quantity} />
                        </div>
                    )}

                    <div className="transfer-data data-wrapper">
                        <div className="flex-container">
                            <div className="transfer-data">
                                <div className="data-label">
                                    <I18n id="forms.transferInternal.confirmation.debitAccount" />
                                </div>
                                <ProductTag alias={debitAccountAlias} />
                            </div>
                            <Image src="images/arrowRight.svg" className="svg-icon" />
                            <div className="transfer-data">
                                <span className="data-label">
                                    <I18n id="forms.transferInternal.confirmation.creditAccount" />
                                </span>
                                <ProductTag alias={creditAccountAlias} />
                            </div>
                        </div>
                    </div>
                </div>
                {creditReference && (
                    <div className="data-wrapper">
                        <span className="data-label">
                            <I18n id="forms.transferInternal.confirmation.reference" />{" "}
                        </span>
                        <span className="data-text">{creditReference}</span>
                    </div>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    previewData: formSelectors.getPreviewData(state),
});

export default connect(mapStateToProps)(TransferInternal);
