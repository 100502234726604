import React from "react";
import { withFormik, Form } from "formik";
import { func, bool, shape } from "prop-types";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { compose } from "redux";

import { selectors as fundcorpSelectors, actions as fundcorpActions } from "reducers/fundcorp";
import * as i18nUtils from "util/i18n";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import RescueSummaryData from "./RescueSummaryData";

function FundRescueSummary({ isDesktop, dispatch, isSubmitting, summary }) {
    const handleBack = () => {
        dispatch(push("/fundcorpOperations/rescue/back"));
    };

    const confirmButton = (
        <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
            <div className="admin-content-center">
                <Button
                    image="images/arrowRight.svg"
                    imageStyle={isDesktop ? "mr-2" : "mr-1"}
                    type="submit"
                    bsStyle="primary"
                    label="global.confirm"
                    loading={isSubmitting}
                />
            </div>
        </Col>
    );

    const { equivalentAmount, equivalentCuotapartes, rescueType } = summary;

    if (!equivalentAmount || !equivalentCuotapartes || !rescueType) {
        handleBack();
    }
    return (
        <>
            <Notification scopeToShow="rescueSummary" />
            <div className="admin-detail-head px-0">
                <Head
                    onBack={handleBack}
                    title={!isDesktop ? "fund.rescue.summary.label" : undefined}
                    titleClassName="text-white"
                    accessibilityTextId="fund.rescue.summary.label"
                    headerClassName={classNames({
                        "blue-main-header-mobile": !isDesktop,
                    })}
                    centerContentClassName={classNames({
                        "mx-5": !isDesktop,
                    })}
                />
                {isDesktop && <h1 className="mt-1 mb-3">{i18nUtils.get("fund.rescue.summary.label")}</h1>}
            </div>
            <Form className="confirmation__container">
                <Container
                    className={classNames("", {
                        "flex-grow align-items-center cmf-container-white pt-3": !isDesktop,
                    })}
                    gridClassName={!isDesktop ? "form-content" : "confirmation__form confirmation__box"}>
                    <div
                        className={classNames("admin-content-center", {
                            "mb-3 px-4": !isDesktop,
                        })}>
                        <I18n
                            id="fund.rescue.summary.checkInfo.label"
                            component={!isDesktop ? "h2" : "h1"}
                            componentProps={{ className: classNames({ "f-size-35": !isDesktop }) }}
                        />
                    </div>
                    <Col className={`col-12 ${!isDesktop && "pt-3 mt-3"}`} lg={12} md={9} sm={12}>
                        <RescueSummaryData isDesktop={isDesktop} spaceBetween />
                        <div className="admin-content-center">
                            <I18n
                                id="confirmation.withoutotp.text"
                                component="p"
                                componentProps={{ className: "confirmation__text-without-otp" }}
                            />
                        </div>
                    </Col>
                    {isDesktop && (
                        <Container className="flex-grow align-items-center w-100" gridClassName="form-content">
                            {confirmButton}
                        </Container>
                    )}
                </Container>
                {!isDesktop && (
                    <Container
                        className="container--layaut flex-grow align-items-center cmf-container-white without-margin-top w-100"
                        gridClassName={
                            !isDesktop ? "form-content" : "confirmation__form confirmation__box-transparent"
                        }>
                        <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                            {confirmButton}
                        </Col>
                    </Container>
                )}
            </Form>
        </>
    );
}

FundRescueSummary.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool,
    isSubmitting: bool,
    summary: shape({}).isRequired,
};

FundRescueSummary.defaultProps = {
    isDesktop: true,
    isSubmitting: false,
};

const mapStateToProps = (state) => ({
    summary: fundcorpSelectors.getSummary(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        handleSubmit: (_, formikBag) => {
            const { dispatch, summary } = formikBag.props;

            dispatch(
                fundcorpActions.sendRescueRequest(
                    { ...summary, idActivity: "fundcorp.register.rescue.request" },
                    formikBag,
                ),
            );
        },
    }),
)(FundRescueSummary);
