import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import Slider from "react-slick";
import { shape, bool, number } from "prop-types";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import classNames from "classnames";

class DetailHeadInfo extends Component {
    static propTypes = {
        account: shape({}).isRequired,
        isDesktop: bool.isRequired,
        balance: number.isRequired,
        countableBalance: number.isRequired,
        saldoApertura: number.isRequired,
        acuerdos: number.isRequired,
        children: shape({}).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isHorizontalTable: false,
        };

        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isHorizontalTable: window.innerWidth >= 861 && window.innerWidth <= 1080 });
    }

    render() {
        const { account, children, isDesktop } = this.props;
        const { balance, countableBalance, currency, saldoApertura, acuerdos } = account;
        const { isHorizontalTable } = this.state;

        const settings = {
            dots: true,
            infinite: false,
            speed: 200,
            slidesToShow: isDesktop ? 3 : 1,
            slidesToScroll: 1,
            arrows: false,
        };

        const availableBalanceData = (
            <Col
                sm={12}
                md={isHorizontalTable ? 6 : 3}
                lg={3}
                className="content-data-wrapper col align-self-center justify-content-center p-0 mb-1">
                <div className={isDesktop ? "data-wrapper-flex account-flex-wrap account-flex-wrap-align-left" : ""}>
                    <div
                        className={`${
                            isDesktop ? "data-label" : "text-align-center"
                        } acoount-header-detail-label-font mb-0`}>
                        <I18n id="accounts.availableBalance" />
                    </div>

                    <FormattedAmount
                        className="acoount-header-detail-value-font big-font mb-0"
                        currency={currency}
                        quantity={balance}
                    />
                </div>
            </Col>
        );

        const realBalanceData = (
            <Col
                sm={12}
                md={isHorizontalTable ? 6 : 3}
                lg={3}
                className="content-data-wrapper col align-self-center justify-content-center p-0">
                <div className={isDesktop ? "data-wrapper-flex account-flex-wrap account-flex-wrap-align-left" : ""}>
                    <div
                        className={`${
                            isDesktop ? "data-label" : "text-align-center"
                        } acoount-header-detail-label-font mb-0`}>
                        <I18n id="accounts.realBalance" />
                    </div>

                    <FormattedAmount
                        className="acoount-header-detail-value-font mb-0"
                        currency={currency}
                        quantity={countableBalance}
                        medium
                        notBold
                    />
                </div>
            </Col>
        );

        const aperturaBalanceData = (
            <Col
                sm={12}
                md={isHorizontalTable ? 6 : 3}
                lg={3}
                className={classNames("content-data-wrapper col align-self-center", {
                    "justify-content-left pr-0": isDesktop,
                    "justify-content-center": !isDesktop,
                })}>
                <div className={isDesktop ? "data-wrapper-flex account-flex-wrap account-flex-wrap-align-left" : ""}>
                    <div
                        className={`${
                            isDesktop ? "data-label" : "text-align-center"
                        } acoount-header-detail-label-font mb-0`}>
                        <I18n id="accounts.aperturaBalance" />
                    </div>

                    <FormattedAmount
                        className="acoount-header-detail-value-font mb-0"
                        currency={currency}
                        quantity={saldoApertura}
                        medium
                        notBold
                    />
                </div>
            </Col>
        );
        const acuerdosBalance = (
            <Col
                sm={12}
                md={isHorizontalTable ? 6 : 3}
                lg={3}
                className="content-data-wrapper col align-self-center justify-content-center p-0">
                <div className={isDesktop ? "data-wrapper-flex account-flex-wrap account-flex-wrap-align-left" : ""}>
                    <div
                        className={`${
                            isDesktop ? "data-label" : "text-align-center"
                        } acoount-header-detail-label-font mb-0`}>
                        <I18n id="accounts.acuerdosBalance" />
                    </div>

                    <FormattedAmount
                        className="acoount-header-detail-value-font mb-0"
                        currency={currency}
                        quantity={acuerdos}
                        medium
                        notBold
                    />
                </div>
            </Col>
        );

        return (
            (isDesktop && (
                <>
                    <Col className="col col-12 padding-left-0 padding-right-2">
                        <Col className="d-flex mt-0 background-white account-detail-slider-desktop">
                            {aperturaBalanceData}
                            {realBalanceData}
                            {acuerdosBalance}
                            {availableBalanceData}
                        </Col>
                    </Col>
                    {children}
                </>
            )) || (
                <>
                    <div className="account-detail-slider-container">
                        {availableBalanceData}
                        <Slider className="account-detail-slider" {...settings}>
                            {<div>{aperturaBalanceData}</div>}
                            {<div>{realBalanceData}</div>}
                            {<div>{acuerdosBalance}</div>}
                        </Slider>
                    </div>
                    {children}
                </>
            )
        );
    }
}

export default DetailHeadInfo;
