import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Yup from "yup";
import Col from "react-bootstrap/lib/Col";
import { func, arrayOf, bool, string } from "prop-types";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";

import Notification from "pages/_components/Notification";
import Captcha from "pages/_components/fields/credentials/Captcha";
import DocumentField from "pages/_components/fields/DocumentField";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";

import * as i18nUtils from "util/i18n";

const FORM_ID = "enrollment.requestInvitationCode";

class RequestInvitationCode extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        countries: arrayOf(string),
        documentTypes: arrayOf(string),
        error: string,
        isDesktop: bool.isRequired,
        isMobileNative: bool.isRequired,
        isSubmitting: bool.isRequired,
    };

    static defaultProps = {
        error: null,
        countries: [],

        documentTypes: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(enrollmentActions.requestInvitationCodePre());
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    render() {
        const { countries, documentTypes, error, isDesktop, isMobileNative, isSubmitting } = this.props;

        return (
            <Fragment>
                <Notification scopeToShow="enrollment" />
                <Head
                    title="enrollment.requestInvitationCode.header"
                    onClose={!isDesktop ? this.onHeaderClose : null}
                />
                <div className="view-page">
                    <div className="view-content">
                        <main className="main-container">
                            <Form className="above-the-fold">
                                <Container className="container--layout flex-grow align-items-center">
                                    <Col className="col col-12">
                                        {error && (
                                            <I18n
                                                component="p"
                                                componentProps={{ className: "text-center" }}
                                                id={error}
                                            />
                                        )}
                                        <Field
                                            autoComplete="off"
                                            clearable={false}
                                            component={DocumentField}
                                            searchable
                                            data={{ countries, documentTypes }}
                                            hideDocumentPlaceholder
                                            idForm={FORM_ID}
                                            name="document"
                                        />
                                    </Col>
                                    <Col className="col col-12">
                                        {!isMobileNative && (
                                            <Field name="captcha" idForm={FORM_ID} component={Captcha} />
                                        )}
                                        <Button
                                            bsStyle="primary"
                                            label="global.continue"
                                            loading={isSubmitting}
                                            type="submit"
                                        />
                                    </Col>
                                </Container>
                                {/* <Container className="container--layout align-items-center"></Container> */}
                            </Form>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    countries: enrollmentSelectors.getCountryList(state),
    documentTypes: enrollmentSelectors.getDocumentTypeList(state),
    error: enrollmentSelectors.getError(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    fetching: enrollmentSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            captcha: "",
            document: {
                country: "",
                document: "",
                type: "",
            },
        }),
        validationSchema: ({ isMobileNative }) =>
            Yup.object().shape({
                captcha: !isMobileNative
                    ? Yup.string().required(i18nUtils.get(`${FORM_ID}.captcha.required`))
                    : Yup.string().notRequired(),
                document: Yup.object().shape({
                    country: Yup.string().required(
                        i18nUtils.get("enrollment.requestInvitationCode.document.country.empty"),
                    ),
                    document: Yup.string().required(
                        i18nUtils.get("enrollment.requestInvitationCode.document.document.empty"),
                    ),
                    type: Yup.string().required(i18nUtils.get("enrollment.requestInvitationCode.document.type.empty")),
                }),
            }),
        handleSubmit: ({ document, captcha }, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(enrollmentActions.requestInvitationCode(captcha, document, formikBag));
        },
    }),
)(RequestInvitationCode);
