import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { push } from "react-router-redux";
import { Modal } from "react-bootstrap";
import { bool, func, shape } from "prop-types";

import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";

import * as i18n from "util/i18n";
import { resizableRoute } from "pages/_components/Resizable";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import * as deviceUtils from "util/device";
import * as configUtils from "util/config";

class BiometricIdentification extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        availability: shape({
            isAvailable: bool,
            isHardwareDetected: bool,
        }),
        deviceWithFingerprint: bool,
        isMobileNative: bool,
        hidden: bool,
        showAgain: bool,
    };

    static defaultProps = {
        availability: null,
        deviceWithFingerprint: null,
        isMobileNative: null,
        hidden: true,
        showAgain: true,
    };

    state = {
        hidden: false,
        showAgain: true,
        showModal: true,
    };

    componentDidMount() {
        const { dispatch, isMobileNative, showAgain } = this.props;
        let device = "";
        if (isMobileNative) {
            device = deviceUtils.getDeviceId();
        }
        this.setState({ showAgain });
        dispatch(fingerprintActions.fingerprintConfigurationPre(device));
        dispatch(fingerprintActions.fingerprintAvailability());
    }

    handleCheckboxChange = (event) => {
        this.setState({ showAgain: !event.target.checked });
    };

    handleClose = () => {
        const { dispatch } = this.props;
        const { showAgain } = this.state;

        this.setState({ hidden: true, showModal: false });
        dispatch(fingerprintActions.fingerprintHideWidget(showAgain));
    };

    handleConfigure = () => {
        const { dispatch } = this.props;
        this.handleClose();
        dispatch(push("/settings/fingerprintConfiguration"));
    };

    render() {
        const { availability, deviceWithFingerprint, isMobileNative, hidden, showAgain } = this.props;
        const { hidden: hiddenState, showModal } = this.state;

        const hardwareAvailability = isMobileNative && availability && availability.isHardwareDetected;

        const showWidget = hardwareAvailability && !deviceWithFingerprint && !hidden && !hiddenState && showAgain;

        const uiAutomationProp = { "data-ui-automation": "widgetNotifications" };

        const widgetBody = configUtils.get("biometric.AllowsAuthetication.Text");

        if (showWidget) {
            return (
                <Modal show={showModal} className="px-0" dialogClassName="justify-content-end" onHide={!showModal}>
                    <Modal.Body>
                        <div className="p-4" {...uiAutomationProp}>
                            <div className="media-object media-object--tranparent">
                                <figure>
                                    <Image src="images/bioIcon.svg" className="svg-image" />
                                </figure>
                            </div>
                            <p className="text-center my-0">{i18n.get("widgets.biometricIdentification.title")}</p>
                            <p className="my-3">{widgetBody}</p>

                            <div className="mb-3 c-control c-control--checkbox">
                                <input
                                    type="checkbox"
                                    id="rememberBiometrics"
                                    onChange={this.handleCheckboxChange}
                                    className="c-control-input"
                                />

                                <label className="c-control-label" htmlFor="rememberBiometrics">
                                    <div className="c-control-icons">
                                        <div className="c-control-mark">
                                            <Image src="images/check.svg" className="svg-icon svg-caret" />
                                        </div>
                                    </div>
                                    <div>{i18n.get("widgets.biometricIdentification.dontShowAgain")}</div>
                                </label>
                            </div>
                            <div>
                                <Button
                                    bsStyle="primary"
                                    label="widgets.biometricIdentification.configureNow"
                                    onClick={this.handleConfigure}
                                    type="button"
                                />
                                <Button
                                    bsStyle="outline"
                                    label="global.close"
                                    onClick={this.handleClose}
                                    type="button"
                                />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        }

        return null;
    }
}

const mapStateToProps = (state) => ({
    availability: fingerprintSelectors.getAvailability(state),
    deviceWithFingerprint: fingerprintSelectors.getIsDeviceWithFingerprint(state),
    hidden: fingerprintSelectors.getFetching(state) || fingerprintSelectors.isHidden(state),
    showAgain: fingerprintSelectors.isShowAgain(state),
});

export default compose(connect(mapStateToProps), resizableRoute)(BiometricIdentification);
