import React, { Component } from "react";
import { func, bool } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "redux";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as depositsSelectors, actions as depositsActions } from "reducers/deposits";

import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Col from "react-bootstrap/lib/Col";
import Credential from "pages/_components/fields/credentials/Credential";
import Yup from "yup";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import { Field, Form, withFormik } from "formik";

import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";

import DepositSummaryData from "pages/deposits/_components/DepositSummaryData";

import { getAccountDescription } from "util/accounts";
import * as i18nUtils from "util/i18n";
import isTokenActive from "util/token";

const FORM_ID = "deposits.summary";

class DepositSummary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        loadingConfirm: bool.isRequired,
        isDesktop: bool.isRequired,
        isSubmitting: bool.isRequired,
    };

    getAccountDescription({ number, productType, currency }) {
        const { dispatch } = this.props;
        if (!number) {
            dispatch(push("/deposits"));
            return "";
        }
        return getAccountDescription({ number, productType, currency });
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/depositsConstitute"));
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: "-0.5rem 2rem" }}>{i18nUtils.get("deposits.summary.label")}</h1>
        </div>
    );

    render() {
        const { isDesktop, loadingConfirm, isSubmitting } = this.props;

        const continueButton = (
            <Col className="col-12" lg={12} md={12} sm={12}>
                <Button
                    type="submit"
                    bsStyle="primary"
                    label="global.confirm"
                    image="images/arrowRight.svg"
                    imageStyle="mr-2"
                    className="text-capitalize"
                    loading={loadingConfirm || isSubmitting}
                />
            </Col>
        );

        const credentials = (
            <>
                {!isTokenActive(!isDesktop) ? (
                    <div className="admin-content-center deposit-summary">
                        <I18n id="deposits.summary.confirm.label" />
                    </div>
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}
                <div className="admin-content-center">
                    <Field
                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </>
        );

        return (
            <>
                <Notification scopeToShow="depositsSummary" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        onClose={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        closeImageWhite={!isDesktop}
                    />
                    {isDesktop && <h1>{i18nUtils.get("deposits.summary.label")}</h1>}
                </div>
                <MainContainer>
                    <Form className="above-the-fold">
                        <Container
                            className={`flex-grow align-items-center container-white with-margin ${
                                !isDesktop ? "mb-0" : ""
                            }`}
                            gridClassName="form-content">
                            <div
                                className="admin-content-center mx-4"
                                style={{ marginBottom: isDesktop ? "2rem" : "1rem" }}>
                                <I18n id="deposits.summary.checkInfo.label" component="h2" />
                            </div>

                            <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                <DepositSummaryData isDesktop={isDesktop} />
                                {credentials}
                                {continueButton}
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: depositsSelectors.getSummary(state),
    userId: sessionSelectors.getUser(state).userId,
    loadingConfirm: depositsSelectors.getLoadingConfirm(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, summary } = formikBag.props;

            dispatch(depositsActions.confirmDeposit({ ...summary, formikBag }, otp));
        },
    }),
)(DepositSummary);
