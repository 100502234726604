/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { bool, func, shape, number, arrayOf, string } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import moment from "moment";
import {
    Box,
    Button,
    Card,
    Container,
    FormattedDate,
    List,
    Text,
    useMediaQuery,
    Separator,
    Tag,
    ThemeProvider,
    ThemeProvider2,
    BottomSheet,
} from "@ui-kit/components/index.es";
import Head from "pages/_components/Head";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import { actions as notificationActions } from "reducers/notification";
import { selectors as agendaSelectors } from "reducers/frequentDestination";
import { deleteDraftRequest } from "middleware/transactions";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import { findScheduleDesc } from "util/scheduler";
import Notification from "pages/_components/Notification";
import getTransferPath from "util/transfers";
import TransferHistoricFilters from "../../transfer/_components/TransferHistoricFilters";
import TransfersAgenda from "../../transfer/_components/TransfersAgenda";

const FORM_ID = "transfers.historic";

function TransferHistoric(props) {
    TransferHistoric.propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        fetchingMore: bool.isRequired,
        transactions: arrayOf().isRequired,
        selectedTransactionRows: arrayOf().isRequired,
        totalPages: number.isRequired,
        pageNumber: number.isRequired,
        filters: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
        location: shape({}).isRequired,
        selectedChip: string.isRequired,
        filtersHistoric: shape({}).isRequired,
        filtersPending: shape({}).isRequired,
        filtersDraft: shape({}).isRequired,
        filtersScheduled: shape({}).isRequired,
    };
    TransferHistoric.defaultProps = {};

    const {
        dispatch,
        fetching,
        isDesktop,
        fetchingMore,
        transactions,
        totalPages,
        pageNumber,
        filters,
        selectedTransactionRows,
        selectedChip,
        filtersHistoric,
        filtersPending,
        filtersDraft,
        filtersScheduled,
        activeEnvironment
    } = props;
    const [showTransferOptionsMb, setShowTransferOptionsMb] = useState(false);
    const [orderBy, setOrderBy] = useState("submit_date_time DESC");
    const [selectedCurrency, setSelectedCurrency] = useState(0);
    const [formikProps, setFormikProps] = useState({});

    const isMobile = useMediaQuery("min", "md");

    const dateFrom = new Date();
    dateFrom.setMonth(dateFrom.getMonth() - 1);
    const dateTo = new Date();
    const defaultFilters = {
        orderBy: "submit_date_time DESC",
        dateType: "submit_date_time",
        dateFrom,
        dateTo,
        pageNumber: 1,
        filter: "last",
        onlyTransfers: true,
        transferType: "all",
        onlyPendings: false,
    };

    const getDefaultFilter = (selectedOption) => {
        const basicDataFilter = {
            dateFrom,
            dateTo,
            pageNumber: 1,
            filter: "last",
            onlyTransfers: true,
            transferType: "all",
            onlyPendings: false,
        };

        switch (selectedOption) {
            case "historic":
                return { ...basicDataFilter, orderBy: "submit_date_time DESC", dateType: "submit_date_time" };
            case "PENDING":
                return {
                    ...basicDataFilter,
                    orderBy: "creation_date_time DESC",
                    dateType: "creation_date_time",
                    status: "PENDING",
                };
            case "SCHEDULED":
                return {
                    ...basicDataFilter,
                    orderBy: "value_date_time DESC",
                    dateType: "value_date_time",
                    status: "SCHEDULED",
                };
            case "DRAFT":
                return {
                    ...basicDataFilter,
                    orderBy: "creation_date_time DESC",
                    dateType: "creation_date_time",
                    status: "DRAFT",
                };
            default:
                return { ...basicDataFilter };
        }
    };

    useEffect(() => {
        const { location } = props;

        if (!isDesktop) {
            dispatch(transferActions.resetListTransfers());
        }
        dispatch(
            transferActions.loadListRequest(
                {
                    ...filters,
                    dateFrom: filters.dateFrom || dateFrom,
                    dateTo: filters.dateTo || dateTo,
                    pageNumber: isDesktop ? pageNumber : 1,
                },
                false,
                false,
            ),
        );
        dispatch(
            transferActions.loadPendingQuantityRequest(
                {
                    ...filters,
                    status: "PENDING",
                    dateFrom: filters.dateFrom || dateFrom,
                    dateTo: filters.dateTo || dateTo,
                    pageNumber: isDesktop ? pageNumber : 1,
                },
                true,
                false,
            ),
        );
        dispatch(transactionsActions.saveTransactionRoute(location?.pathname));
    }, []);

    const cleanSelectedRows = () => {
        dispatch(transactionsActions.clearSelectedTransactionRows());
    };

    const handleResetFilters = (selectedOption) => {
        switch (selectedOption) {
            case "historic":
                dispatch(transferActions.resetHistoric());
                break;
            case "PENDING":
                dispatch(transferActions.resetPendingFilters());
                break;
            case "SCHEDULED":
                dispatch(transferActions.resetScheduledFilters());
                break;
            case "DRAFT":
                dispatch(transferActions.resetDraftFilters());
                break;
            default:
        }
    };

    const getFiltersByOption = (selectedOption) => {
        switch (selectedOption) {
            case "historic":
                return { ...filtersHistoric };
            case "PENDING":
                return { ...filtersPending };
            case "SCHEDULED":
                return { ...filtersScheduled };
            case "DRAFT":
                return { ...filtersDraft };
            default:
                return {};
        }
    };

    const resetFilters = (selectedOption) => {
        cleanSelectedRows();
        handleResetFilters(selectedOption);
        const defaultOptionFilter = getDefaultFilter(selectedOption || selectedChip);
        dispatch(transferActions.loadListRequest({ ...defaultOptionFilter }, false, false));
    };

    const handleFilterObjectType = (selectedFilters, selectedChipValue) => {
        switch (selectedChipValue) {
            case "historic":
                dispatch(transferActions.setFiltersHistoric(selectedFilters));
                break;
            case "PENDING":
                dispatch(transferActions.setFiltersPending(selectedFilters));
                break;
            case "SCHEDULED":
                dispatch(transferActions.setFiltersScheduled(selectedFilters));
                break;
            case "DRAFT":
                dispatch(transferActions.setFiltersDraft(selectedFilters));
                break;
            default:
        }
    };

    const onSubmit = (selectedFilters) => {
        const { transferType, minAmount, maxAmount, selectedOption, onlyPendings, status } = selectedFilters;
        const statusFromHistoric = filters?.status;
        cleanSelectedRows();
        dispatch(transferActions.setFilters(selectedFilters));
        const minAmountFormated = minAmount ? parseFloat(minAmount.replace(/\./g, "").replace(",", ".")) : undefined;
        const maxAmountFormated = maxAmount ? parseFloat(maxAmount.replace(/\./g, "").replace(",", ".")) : undefined;

        const filtersToSend = {
            ...filters,
            ...selectedFilters,
            minAmount:
                minAmountFormated !== undefined
                    ? {
                          currency: selectedCurrency,
                          amount: minAmountFormated,
                      }
                    : undefined,
            maxAmount:
                maxAmountFormated !== undefined
                    ? {
                          currency: selectedCurrency,
                          amount: maxAmountFormated,
                      }
                    : undefined,
            idActivity: transferType.value !== "all" ? transferType.value : undefined,
            pageNumber: 1,
            filter: "last",
            onlyTransfers: true,
            status: status || (selectedOption === "historic" ? statusFromHistoric : selectedOption),
            hasSummitted: true,
        };

        handleFilterObjectType(filtersToSend, selectedOption);
        dispatch(transferActions.loadListRequest(filtersToSend, onlyPendings, false));
    };

    const handleFilterButtonsClick = (fieldName = "orderBy", value = "submit_date_time DESC") => {
        cleanSelectedRows();
        let filtersToSend;
        if (filters === null) {
            filtersToSend = {
                orderBy,
                dateFrom,
                dateTo,
                pageNumber: 1,
                filter: "last",
                onlyTransfers: true,
            };
        } else {
            filtersToSend = {
                ...filters,
                [fieldName]: value,
            };
            if (value === null) {
                delete filtersToSend.fieldName;
            }
        }

        handleFilterObjectType(filtersToSend, filtersToSend?.selectedOption || selectedChip);
        dispatch(transferActions.loadListRequest(filtersToSend, false, false));
    };

    const setOrder = (value) => {
        setOrderBy(value);
        handleFilterButtonsClick("orderBy", value);
    };

    const onSelectCurrency = (currency) => {
        setSelectedCurrency(currency);
        dispatch(transferActions.setFilters({ ...filters, minAmountCurrency: currency, maxAmountCurrency: currency }));
    };

    const filterByStatus = (status) => {
        handleFilterButtonsClick("status", status === "TODOS" ? null : status);
    };

    const fetchMoreTransactions = (newPageNumber) => {
        let { filters } = props;
        filters = filters
            ? { ...filters, pageNumber: newPageNumber }
            : { ...defaultFilters, pageNumber: newPageNumber };
        dispatch(transferActions.loadMoreTransfersRequest(filters, false, false));
    };

    const paginationProps = {
        totalPages,
        pageNumber,
        action: fetchMoreTransactions,
    };

    const onClickOnCard = (idTransaction) => {
        dispatch(routerActions.push(`/transaction/${idTransaction}`));
    };

    const formatDate = (date) => {
        const dateToParse = moment(date).format();
        return new Date(dateToParse);
    };

    const handleSelect = (selected, transaction) => {
        if (selected) {
            dispatch(transactionsActions.saveSelectedTransactionRows(transaction));
        } else {
            dispatch(transactionsActions.removeSelectedTransactionRows(transaction));
        }
    };

    const getBeneficiary = (transaction) => {
        const { idActivity, idTransactionStatus } = transaction;
        const { activeEnvironment } = props;
        if (
            idTransactionStatus === "DRAFT" &&
            (idActivity === "transfers.third.parties.send" || idActivity === "transfers.internal.send")
        ) {
            return {
                cuitCuil: activeEnvironment.clients[0].idClient,
                name: activeEnvironment.name,
            };
        }
        return {
            cuitCuil: transaction.data.clientUser?.cuil,
            name: transaction.data.clientUser?.firstName,
        };
    };

    const getDate = (transaction) => {
        const { idTransactionStatus } = transaction;
        if (idTransactionStatus === "PENDING" || idTransactionStatus === "DRAFT") {
            return formatDate(transaction.creationDateTime);
        }
        if (idTransactionStatus === "SCHEDULED") {
            return formatDate(transaction.data.scheduler.newValueDate);
        }
        return formatDate(transaction.submitDateTime);
    };

    const renderTransferCard = (transfer) => {
        const { transactionAmounts, transaction } = transfer;
        const { data, idTransaction, idTransactionStatus } = transaction;
        const { concept, reference, transferKind, scheduler, idActivity } = data;

        const cardStatusMap = new Map();
        cardStatusMap.set("FINISHED", "success");
        cardStatusMap.set("FAILED", "error");
        cardStatusMap.set("PENDING", "pending");
        cardStatusMap.set("SCHEDULED", "scheduled");
        cardStatusMap.set("DRAFT", "draft");

        const status = cardStatusMap.get(transaction.idTransactionStatus);
        const currency = Object.keys(transactionAmounts)[0];
        const currencyText = i18n.get(`currency.label.${currency}`);
        const quantity = Object.values(transactionAmounts)[0];
        const date = getDate(transaction);
        const conceptDescription = `${concept} - ${i18n.get(`transfers.concept.${concept}`)}`;

        const kind =
            transferKind === "other"
                ? i18n.get("transfers.titular.other.label")
                : i18n.get("transfers.titular.same.label");

        const transactionsTad = ["transfers.thirdParties.tad.send", "transfers.internal.tad.send"];
        const recurencyTag =
            transaction.idTransactionStatus === "PENDING" && transactionsTad.includes(idActivity)
                ? ""
                : findScheduleDesc(transaction?.program?.frequency?.frequencyUnit || scheduler?.selectedOption);

        const showCheckbox = status === "pending" || status === "draft";
        const isSelected = selectedTransactionRows.find(
            (selectedTransaction) => selectedTransaction.transaction.idTransaction === idTransaction,
        );

        const getCardTitle = () => {
            if (idTransactionStatus === "PENDING" || idTransactionStatus === "DRAFT") {
                return i18n.get(`${FORM_ID}.card.title.draftPending`);
            }
            if (idTransactionStatus === "SCHEDULED") {
                return i18n.get(`${FORM_ID}.card.title.scheduled`);
            }
            return i18n.get(`${FORM_ID}.card.title.historic`);
        };
        const beneficiary = getBeneficiary(transaction);
        const cuitCuil = `${i18n.get(`transfers.historic.cuit.cuil`)}: ${beneficiary.cuitCuil || "-"}`;
        const displayTime = idTransactionStatus === "PENDING" || idTransactionStatus === "DRAFT";

        return (
            <Card
                iconText={isMobile && false}
                key={idTransaction}
                title={
                    isMobile ? (
                        <Box flex align="start" column>
                            <Text variant="captionBold">{beneficiary.name || "-"}</Text>
                            <Box flex align="center">
                                <Text variant="caption">{cuitCuil || "-"}</Text>
                                <Tag variant="info" className="p-025 ml-2">
                                    <Text variant="caption">{kind}</Text>
                                </Tag>
                            </Box>
                        </Box>
                    ) : (
                        <Box flex align="center">
                            <Text variant="bodyBold">{`${getCardTitle()}:`}</Text>
                            <FormattedDate
                                variant="bodyBold"
                                date={date}
                                dateFormat="short"
                                timeFormat={displayTime ? "medium" : undefined}
                                locale="es-ES"
                                ml={4}
                            />
                        </Box>
                    )
                }
                variant={status}
                showCheckbox={showCheckbox}
                isChecked={isSelected}
                handleCheckbox={() => handleSelect(!isSelected, transfer)}
                content={
                    isMobile ? (
                        <Box flex column className="w-100">
                            <Box flex justify="between">
                                <Text variant="captionBold">{getCardTitle().toUpperCase()}</Text>
                                <FormattedDate
                                    variant="caption"
                                    date={date}
                                    dateFormat="short"
                                    timeFormat={displayTime ? "medium" : undefined}
                                    locale="es-ES"
                                    ml={4}
                                />
                            </Box>
                            {concept ? (
                                <Box flex justify="between">
                                    <Text variant="captionBold">CONCEPTO</Text>
                                    <Text variant="caption">{conceptDescription}</Text>
                                </Box>
                            ) : null}
                            <Box flex justify="between">
                                <Text variant="captionBold">REFERENCIA</Text>
                                {reference ? (
                                    <Text variant="caption" className="align-right">
                                        {reference}
                                    </Text>
                                ) : null}
                            </Box>
                        </Box>
                    ) : (
                        <Text variant="body2">{`${beneficiary.name || "-"} | ${cuitCuil || "-"}`}</Text>
                    )
                }
                infoOne={!isMobile && kind}
                bottom={
                    <Text variant="captionBold">{`${
                        concept ? `${i18n.get("transfers.historic.card.concept")}: ${conceptDescription} | ` : ""
                    }Referencia: ${reference}`}</Text>
                }
                id="card-Default"
                currency={currencyText}
                amount={quantity}
                onClick={() => onClickOnCard(idTransaction)}
                status={recurencyTag ? { label: recurencyTag?.toUpperCase(), variant: "neutral" } : null}
            />
        );
    };

    const handleBack = () => dispatch(push("/desktop"));

    const makeTransfersPermissions = () => {
        const { activeEnvironment } = props;
        return !!(
            activeEnvironment?.permissions?.transferInternal || activeEnvironment?.permissions?.transferThirdParties
        );
    };

    const handleSelectAll = (checked) => {
        transactions.forEach((transfer) => {
            const selected =
                selectedTransactionRows?.filter(
                    (selectedTransaction) =>
                        selectedTransaction.transaction.idTransaction === transfer.transaction.idTransaction,
                ).length > 0;
            if (checked && !selected) {
                dispatch(transactionsActions.saveSelectedTransactionRows(transfer));
            } else if (!checked && selected) {
                dispatch(transactionsActions.removeSelectedTransactionRows(transfer));
            }
        });
    };

    const handleMultipleSigns = () => {
        dispatch(
            transactionsActions.setMultipleSignaturesData({
                activitySelected: {
                    idActivity: "transfers.external.internal",
                    activityName: i18n.get("transfers.historic.multiple.select.title"),
                },
                selectedTransactionRows,
                filtered: true,
            }),
        );
        dispatch(push("/pendingTransaction/signMultiple"));
    };

    const deleteDraft = async (idTransaction) => {
        const response = await deleteDraftRequest(idTransaction);
        const { data } = response.data;

        return data;
    };

    const handleMultipleDeleteDrafts = () => {
        const bodyPromises = selectedTransactionRows.map((object) => {
            const { idTransaction } = object.transaction;
            return deleteDraft(idTransaction);
        });

        Promise.all(bodyPromises).then((values) => {
            const allDeleted = values.every((value) => value.deleted);
            if (allDeleted) {
                const message =
                    selectedTransactionRows.length > 1
                        ? i18n.get(`${FORM_ID}.delete.success`)
                        : i18n.get(`${FORM_ID}.delete.success.singular`);
                dispatch(notificationActions.showNotification(message, "success", ["transfersHistoric"]));
            } else {
                dispatch(
                    notificationActions.showNotification(i18n.get(`${FORM_ID}.delete.warn`), "warn", [
                        "transfersHistoric",
                    ]),
                );
            }
            onSubmit(filters);
        });
    };

    const handleMultipleDeletePendings = () => {
        dispatch(
            transactionsActions.setMultipleSignaturesData({
                activitySelected: {
                    idActivity: "transfers.external.internal",
                    activityName: i18n.get("transfers.historic.multiple.select.title"),
                },
                selectedTransactionRows,
                filtered: true,
            }),
        );
        dispatch(push("/pendingTransaction/deleteMultiple"));
    };

    const enableAgenda = () => {
        const { activeEnvironment } = props;
        const showMvp3 = configUtils.getBoolean("frontend.showMVP3.transfers.functionalities");
        return activeEnvironment.permissions?.frequentDestination && showMvp3;
    };

    const transferPath = getTransferPath();

    return (
        <ThemeProvider>
        <ThemeProvider2>
            <Notification scopeToShow="transfersHistoric" />
            {!isDesktop && (
                <>
                    <Box className="admin-detail-head z-index-1 px-0">
                        <Head
                            onBack={handleBack}
                            headerClassName="blue-main-header-mobile"
                            centerElement={() => (
                                <Text as="h1" className="m-0">
                                    {i18n.get("transfers.main.label")}
                                </Text>
                            )}
                            accessibilityTextId="transfers.main.label"
                        />
                    </Box>
                    <Container fixed>
                        <Box mt={12}>
                            <Button
                                justify="right"
                                text={i18n.get(`${FORM_ID}.btn.createTransfer`)}
                                variant="solid"
                                icon="CashflowOut"
                                className="w-100"
                                disabled={!makeTransfersPermissions()}
                                onClick={() => {
                                    if (isDesktop) {
                                        dispatch(push(transferPath));
                                    } else {
                                        setShowTransferOptionsMb(true);
                                    }
                                }}
                            />
                        </Box>
                        <BottomSheet
                            title={i18n.get("transfers.make.transfer.title")}
                            iconTitle="TransferAlt"
                            onClose={() => setShowTransferOptionsMb(false)}
                            isVisible={showTransferOptionsMb}>
                            <Box column gap={20} py={24} px={12} pb={24}>
                                {
                                    activeEnvironment.permissions?.transferInternal &&
                                        <Button
                                            text={i18n.get("transfers.form.internal.title")}
                                            variant="solid"
                                            icon="CashflowOut"
                                            className="w-100"
                                            disabled={!makeTransfersPermissions()}
                                            onClick={() => {
                                                dispatch(push(transferPath));
                                            }}
                                        />
                                }
                                {
                                    activeEnvironment.permissions?.transferThirdParties &&
                                        <Button
                                            text={i18n.get("transfers.form.thirdParties.title")}
                                            variant="solid"
                                            icon="CashflowOut"
                                            className="w-100"
                                            disabled={!makeTransfersPermissions()}
                                            onClick={() => {
                                                dispatch(push(getTransferPath("thirdParties")));
                                            }}
                                        />
                                }
                            </Box>
                        </BottomSheet>
                        {enableAgenda() && (
                            <>
                                <Separator mt={12} />
                                <TransfersAgenda formikProps={formikProps} isDesktop={isDesktop} />
                            </>
                        )}
                    </Container>
                </>
            )}
            <Container fixed className="h-100">
                {isDesktop && (
                    <Box className="admin-detail-head px-0">
                        <Head onBack={handleBack} accessibilityTextId="transfers.main.label" />
                        <Box flex align="center" justify="between">
                            <Text variant="h2">{i18n.get("transfers.main.label")}</Text>
                            <Button
                                justify="right"
                                text={i18n.get(`${FORM_ID}.btn.createTransfer`)}
                                variant="solid"
                                icon="CashflowOut"
                                disabled={!makeTransfersPermissions()}
                                onClick={() => {
                                    dispatch(push(transferPath));
                                }}
                            />
                        </Box>
                    </Box>
                )}
                {enableAgenda() && isDesktop && <TransfersAgenda formikProps={formikProps} isDesktop={isDesktop} />}
                <TransferHistoricFilters
                    dispatch={dispatch}
                    setOrder={setOrder}
                    filterByStatus={filterByStatus}
                    onSubmit={onSubmit}
                    onSelectCurrency={onSelectCurrency}
                    onResetFilters={resetFilters}
                    onSelectAll={handleSelectAll}
                    onDeletePendings={handleMultipleDeletePendings}
                    onDeleteDrafts={handleMultipleDeleteDrafts}
                    onSign={handleMultipleSigns}
                    selectedCurrency={selectedCurrency}
                    formikProps={formikProps}
                    setFormikProps={setFormikProps}
                    handleFilterObjectType={handleFilterObjectType}
                    getFiltersByOption={getFiltersByOption}
                />
                <Box flex className="min-height-40vh" justify="center">
                    <List
                        emptyStateText={i18n.get("transactions.list.none")}
                        array={transactions}
                        mapCallback={renderTransferCard}
                        paginationProps={paginationProps}
                        loading={fetching || fetchingMore}
                        isInfiniteScroll={!isDesktop}
                        endMessage={i18n.get("transactions.list.noMoreTransactions")}
                    />
                </Box>
            </Container>
        </ThemeProvider2>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => ({
    transactions: transferSelectors.getTransfers(state),
    selectedTransactionRows: transactionsSelectors.getSelectedTransactionRows(state),
    fetching: transferSelectors.getFetching(state),
    filters: transferSelectors.getFilters(state),
    pageNumber: transferSelectors.getPageNumber(state),
    hasMoreData: transferSelectors.getHasMoreData(state),
    fetchingMore: transferSelectors.isFetchingMore(state),
    totalPages: transferSelectors.getTotalPages(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    idEnvironment: agendaSelectors.getEnviromentId(state),
    listFrequentDestinations: agendaSelectors.getListFrequentDestinations(state),
    selectedChip: transferSelectors.getSelectedChip(state),
    filtersHistoric: transferSelectors.getFiltersHistoric(state),
    filtersPending: transferSelectors.getFiltersPending(state),
    filtersScheduled: transferSelectors.getFiltersScheduled(state),
    filtersDraft: transferSelectors.getFiltersDraft(state),
});

export default withRouter(connect(mapStateToProps)(TransferHistoric));
