import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";

import { node, number, string } from "prop-types";

class ContainerColumn extends Component {
    static propTypes = {
        children: node.isRequired,
        className: string,
        componentClass: string,
        xs: number,
        sm: number,
        md: number,
        lg: number,
    };

    static defaultProps = {
        className: "col",
        componentClass: "article",
        xs: null,
        sm: null,
        md: null,
        lg: null,
    };

    render() {
        const { children, ...props } = this.props;

        return <Col {...props}>{children}</Col>;
    }
}

export default ContainerColumn;
