import React, { Component, Fragment } from "react";
import { bool, node, arrayOf, oneOfType } from "prop-types";

class WidgetLoading extends Component {
    static propTypes = {
        loading: bool,
        children: oneOfType([arrayOf(node), node]),
    };

    static defaultProps = {
        loading: true,
        children: null,
    };

    render() {
        return (
            <Fragment>
                {this.props.loading && (
                    <div className="widget-preloader">
                        <div>
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                )}
                {this.props.children}
            </Fragment>
        );
    }
}

export default WidgetLoading;
