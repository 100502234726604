import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import Head from "pages/_components/Head";
import { goBack } from "react-router-redux";
import { func, bool } from "prop-types";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import * as configUtils from "util/config";

class SafariSuggestionPage extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
    };

    static defaultProps = {};

    onHeaderBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleClick = () => {
        const downloadUrliOS = configUtils.get("frontend.apps.ios.downloadUrl");
        window.location.href = downloadUrliOS;
    };

    render() {
        const { isMobile } = this.props;
        return (
            <>
                <Head title="onboarding.suggestionPage.title" onBack={isMobile && this.onHeaderBack} closeLinkTo="/" />
                <MainContainer className="main-container onboarding-layout">
                    <div className="above-the-fold">
                        <Container className="flex-grow container--layout">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <div className="illustration-wrapper">
                                    <Image src="images/download-app.svg" className="illustration-icon" />
                                    <div className="illustration-text">
                                        <p>
                                            <I18n component="strong" id="onboarding.suggestionPage.message1" />
                                        </p>
                                        <p>
                                            <I18n id="onboarding.suggestionPage.message2" />
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <Button
                                    bsStyle="primary"
                                    onClick={this.handleClick}
                                    label="onboarding.suggestionPage.download"
                                />
                            </Col>
                        </Container>
                        {/* <Container className="align-items-center container--layout"></Container> */}
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(SafariSuggestionPage);
