import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LOAD_ADMINISTRATION_TICKET_REQUEST: "ADMINISTRATION/LOAD_ADMINISTRATION_TICKET_REQUEST",
    LOAD_ADMINISTRATION_TICKET_FAILURE: "ADMINISTRATION/LOAD_ADMINISTRATION_TICKET_FAILURE",
    LOAD_ADMINISTRATION_TICKET_SUCCESS: "ADMINISTRATION/LOAD_ADMINISTRATION_TICKET_SUCCESS",
    DOWNLOAD_ADMINISTRATION_TICKET_SUCCESS: "ADMINISTRATION/DOWNLOAD_ADMINISTRATION_TICKET_SUCCESS",
    DOWNLOAD_ADMINISTRATION_TICKET_REQUEST: "ADMINISTRATION/DOWNLOAD_ADMINISTRATION_TICKET_REQUEST",
    SET_EXTRA_DATA_DOWNLOAD: "ADMINISTRATION/SET_EXTRA_DATA_DOWNLOAD",
};

export const INITIAL_STATE = {
    data: {},
    fetching: true,
    fetchingDownload: false,
    extraData: {},
};

export default createReducer(INITIAL_STATE, {
    [types.LOAD_ADMINISTRATION_TICKET_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.LOAD_ADMINISTRATION_TICKET_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOAD_ADMINISTRATION_TICKET_SUCCESS]: (state, { data }) => ({
        ...state,
        fetching: false,
        data,
    }),
    [types.DOWNLOAD_ADMINISTRATION_TICKET_REQUEST]: (state) => ({
        ...state,
        fetchingDownload: true,
    }),
    [types.DOWNLOAD_ADMINISTRATION_TICKET_SUCCESS]: (state) => ({
        ...state,
        fetchingDownload: false,
    }),
    [types.SET_EXTRA_DATA_DOWNLOAD]: (state, { extraData }) => ({
        ...state,
        extraData,
    }),
});

export const actions = {
    loadAdministrationTicketRequest: makeActionCreator(types.LOAD_ADMINISTRATION_TICKET_REQUEST, "idTransaction"),
    loadAdministrationTicketFailure: makeActionCreator(types.LOAD_ADMINISTRATION_TICKET_FAILURE),
    loadAdministrationTicketSuccess: makeActionCreator(types.LOAD_ADMINISTRATION_TICKET_SUCCESS, "data"),
    loadUpdateUserTicket: makeActionCreator(types.LOAD_ADMINISTRATION_UPDATE_USER, "idTransaction"),
    downloadAdministrationTicketRequest: makeActionCreator(
        types.DOWNLOAD_ADMINISTRATION_TICKET_REQUEST,
        "idTicket",
        "format",
        "activityName",
    ),
    downloadAdministrationTicketSuccess: makeActionCreator(types.DOWNLOAD_ADMINISTRATION_TICKET_SUCCESS),
    setExtraData: makeActionCreator(types.SET_EXTRA_DATA_DOWNLOAD, "extraData"),
};

export const selectors = {
    isFetching: ({ administrationTicket }) => administrationTicket.fetching,
    isFetchingDownload: ({ administrationTicket }) => administrationTicket.fetchingDownload,
    getData: ({ administrationTicket }) => administrationTicket.data,
    getExtraData: ({ administrationTicket }) => administrationTicket.extraData,
};
