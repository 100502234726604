import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool } from "prop-types";
import { actions } from "reducers/checks";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Yup from "yup";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import { push } from "react-router-redux";
import classNames from "classnames";

const FORM_ID = "echeqs";

class AnulateEcheq extends Component {
    static propTypes = {
        handleSubmit: func.isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { handleSubmit, isDesktop } = this.props;

        return (
            <Container className={`px-2 ${isDesktop && "py-6 my-4"}`}>
                <div className="form-section-title admin-content-center my-5">
                    {isDesktop && <I18n component="h2" id={`${FORM_ID}.anulate.motive.title`} />}
                </div>
                <Form
                    className="above-the-fold"
                    onSubmit={(e) => {
                        handleSubmit(e);
                        e.stopPropagation();
                    }}>
                    <Container className="flex-grow align-items-center" gridClassName="form-content">
                        <Col xs={10} md={8} className={classNames({ "px-0": isDesktop, "pt-6": isDesktop })}>
                            <Field
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="anulate"
                                type="text"
                                style={{ paddingTop: "12px", paddingBottom: "12px" }}
                            />
                        </Col>

                        <Col xs={10} md={8} className={classNames({ "px-0": isDesktop, "mt-5": true })}>
                            <Button type="submit" bsStyle="primary" label="global.add" />
                        </Col>
                    </Container>
                </Form>
            </Container>
        );
    }
}

export default compose(
    connect(),
    withFormik({
        mapPropsToValues: () => ({ anulate: "" }),
        validationSchema: () =>
            Yup.object().shape({
                anulate: Yup.string().required(i18n.get("accounts.new.account.field.error.required")),
            }),
        handleSubmit: (filters, formikBag) => {
            const { dispatch } = formikBag.props;

            dispatch(actions.setEcheqsSpecialAttribute(filters.anulate));
            dispatch(push("/echeqs/anulate/confirmation"));
        },
    }),
)(AnulateEcheq);
