/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { bool, element, func, node, number, object, oneOf, oneOfType, shape, string } from "prop-types";
import { compose } from "redux";

import * as i18n from "util/i18n";
import { getNestedObject } from "util/general";

import FieldError from "pages/_components/fields/FieldError";
import FieldWarning from "pages/_components/fields/FieldWarning";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { resizableRoute } from "pages/_components/Resizable";
import withFocus from "pages/_components/withFocus";

class TextField extends Component {
    static propTypes = {
        onCustomChange: func,
        autoCapitalize: string,
        autoComplete: string,
        autoFocus: bool,
        controlLabelClassName: string,
        errorClassName: string,
        formGroupTextClassName: string,
        field: shape({
            onBlur: func,
            onChange: func,
            name: string,
            value: oneOfType([number, string]),
        }).isRequired,
        form: shape({
            errors: {},
            touched: {},
        }).isRequired,
        handleOnBlur: func,
        handleOnChange: func,
        hideLabel: bool,
        hidePlaceholder: bool,
        inputFunctions: element,
        inputRef: oneOfType([func, object]),
        isDesktop: bool.isRequired,
        isMobile: bool.isRequired,
        isMobileNative: bool.isRequired,
        maxLength: number,
        mobileOS: string.isRequired,
        mode: oneOf(["view", "edit"]),
        nestedErrorsObject: bool,
        optional: string,
        pattern: string,
        renderAs: string,
        type: string,
        warningMessage: string,
        labelText: string,
        placeholderText: string,
        isFocused: bool,
        toggleIsFocused: func,
        idForm: string,
        classNameForViewMode: string,
        copyEnabled: bool,
        idField: string,
        minLength: number,
        validationRegularExpresion: string,
        idValidation: string,
        onKeyDown: func,
        extraLabel: node,
        formGroupClassName: string,
        className: string,
        elementClassName: string,
        isDisabled: bool,
        isFetching: bool,
        msgStatic: bool,
        innerDisabled: bool,
        onPaste: func,
        customKeyDown: func,
        infoMsg: node,
    };

    static defaultProps = {
        onCustomChange: null,
        autoCapitalize: "sentences",
        autoComplete: "on",
        autoFocus: false,
        controlLabelClassName: "",
        errorClassName: "",
        formGroupTextClassName: "",
        handleOnBlur: null,
        handleOnChange: null,
        hideLabel: false,
        hidePlaceholder: false,
        inputFunctions: null,
        inputRef: React.createRef(),
        maxLength: 50,
        mode: "edit",
        nestedErrorsObject: false,
        optional: "",
        pattern: null,
        renderAs: "input",
        type: "text",
        warningMessage: "",
        labelText: null,
        placeholderText: null,
        isFocused: false,
        toggleIsFocused: null,
        idForm: "",
        classNameForViewMode: "",
        copyEnabled: true,
        idField: "",
        minLength: 0,
        validationRegularExpresion: "",
        idValidation: "",
        onKeyDown: null,
        extraLabel: null,
        formGroupClassName: "",
        className: "",
        elementClassName: "",
        isDisabled: false,
        isFetching: false,
        msgStatic: false,
        innerDisabled: false,
        onPaste: null,
        customKeyDown: null,
        infoMsg: null,
    };

    state = {
        hasWarning: false,
    };

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    onResize = () => {
        const { isDesktop, isFocused, mobileOS } = this.props;

        if (isFocused && !isDesktop && mobileOS === "Android") {
            /* eslint-disable-next-line react/no-find-dom-node */
            ReactDOM.findDOMNode(this).scrollIntoView({ block: "center", behavior: "smooth" });
        }
    };

    cleanErrors = (form, field) => {
        const { [field?.name]: fieldName, ...errorObj } = form?.errors;
        form.setErrors(errorObj);
    };

    handleBlur = (event) => {
        const { field, toggleIsFocused } = this.props;
        this.setState({
            hasWarning: false,
        });
        field.onBlur(event);
        toggleIsFocused();
    };

    handleOnChange = (event) => {
        const { field, handleOnChange, maxLength, pattern, onCustomChange } = this.props;
        const { onChange } = field;

        /* eslint no-control-regex: "off" */
        const reg = /[^\x00-\xFF]/g;

        if (!reg.test(event.target.value)) {
            if (!pattern || event.target.validity.valid) {
                if (event.target.value.length <= maxLength) {
                    if (handleOnChange) {
                        handleOnChange(event);
                    }

                    onChange(event);
                    this.setState({
                        hasWarning: false,
                    });
                    if (onCustomChange) {
                        onCustomChange(event.target.value);
                    }
                    return;
                }
            }
        }

        this.setState({
            hasWarning: true,
        });
    };

    handleOnCopy = (event) => {
        const { copyEnabled } = this.props;
        if (!copyEnabled) {
            event.preventDefault();
        }
    };

    renderLabel = () => {
        const { idForm, field, labelText, optional } = this.props;
        const text = labelText || `${idForm}.${field.name}.label`;
        return <FieldLabel labelKey={text} optional={optional} />;
    };

    renderPlaceholder = () => {
        const { idForm, field, hidePlaceholder, placeholderText } = this.props;
        if (hidePlaceholder) {
            return "";
        }
        if (placeholderText !== null) {
            return placeholderText;
        }
        return i18n.get(`${idForm}.${field.name}.placeholder`);
    };

    customMinLengthValidation = ({ target }) => {
        const { minLength, idValidation, validationRegularExpresion } = this.props;

        const { value } = target;

        if (minLength) {
            if (value && value.length < minLength) {
                target.setCustomValidity(
                    i18n.get("generic.text.field.minLength.warning.message", null, {
                        MINLENGTH: minLength,
                        CURRENTLENGTH: value.length,
                    }),
                );
            } else {
                target.setCustomValidity("");
            }
        }

        if (idValidation && validationRegularExpresion) {
            const rexp = new RegExp(validationRegularExpresion);
            if (!rexp.test(value)) {
                switch (idValidation) {
                    case "email":
                        target.setCustomValidity(i18n.get("generic.text.field.email.validationError", null));
                        break;
                    default:
                        break;
                }
            } else {
                target.setCustomValidity("");
            }
        }
    };

    render() {
        const {
            autoCapitalize,
            className,
            classNameForViewMode,
            controlLabelClassName,
            copyEnabled,
            elementClassName,
            errorClassName,
            extraLabel,
            field,
            form,
            formGroupClassName,
            formGroupTextClassName,
            handleOnBlur,
            handleOnChange,
            hideLabel,
            hidePlaceholder,
            idField,
            idForm,
            innerDisabled,
            inputFunctions,
            inputRef,
            isDesktop,
            isDisabled,
            isFetching,
            isFocused,
            isMobile,
            isMobileNative,
            labelText,
            mobileOS,
            mode,
            msgStatic,
            nestedErrorsObject,
            onKeyDown,
            optional,
            placeholderText,
            renderAs: Element,
            toggleIsFocused,
            warningMessage,
            onPaste,
            customKeyDown,
            infoMsg,
            ...props
        } = this.props;

        const { touched, errors } = form;

        const { hasWarning } = this.state;
        const hasError = nestedErrorsObject
            ? getNestedObject(touched, field.name.split(".")) && getNestedObject(errors, field.name.split("."))
            : touched[field.name] && errors[field.name];

        if (mode !== "edit" && !field.value) {
            return null;
        }

        const fieldLabel = labelText || `${idForm}.${field.name}.label`;
        const id = idField || `${idForm}.${field.name}`;

        return (
            <div
                className={classNames("form-group", formGroupClassName, {
                    "has-error": hasError,
                    "has-focus": isFocused,
                })}>
                <div className={infoMsg && "d-flex"}>
                    <FieldLabel
                        hideLabel={hideLabel}
                        labelKey={fieldLabel}
                        optional={optional}
                        idField={id}
                        extraLabel={extraLabel}
                        isDisabled={isDisabled && !innerDisabled}
                        isFetching={isFetching}
                        controlLabelClassName={controlLabelClassName}
                        formGroupTextClassName={formGroupTextClassName}
                    />
                    {infoMsg}
                </div>

                {mode === "edit" ? (
                    <div
                        className={classNames(`input-group ${className}`, {
                            "input-group-disable": (isDisabled && !innerDisabled) || isFetching,
                            "inner-input-group-disable": innerDisabled,
                        })}>
                        <Element
                            id={id}
                            className={classNames("form-control", elementClassName)}
                            placeholder={this.renderPlaceholder()}
                            {...field}
                            {...props}
                            onFocus={toggleIsFocused}
                            onBlur={handleOnBlur || this.handleBlur}
                            ref={inputRef}
                            onChange={this.handleOnChange}
                            autoCapitalize={autoCapitalize}
                            onCopy={this.handleOnCopy}
                            onCut={this.handleOnCopy}
                            onInvalid={this.customMinLengthValidation}
                            onKeyDown={customKeyDown || ((e) => e.key === "Enter" && onKeyDown && onKeyDown())}
                            disabled={isDisabled}
                            onClick={() => this.cleanErrors(form, field)}
                            autocomplete="off"
                            onPaste={onPaste}
                        />
                        {inputFunctions}
                    </div>
                ) : (
                    <div className={classNameForViewMode}>{field.value}</div>
                )}

                {hasError && (
                    <FieldError
                        error={nestedErrorsObject ? getNestedObject(errors, field.name.split(".")) : errors[field.name]}
                        msgStatic={msgStatic}
                        errorClassName={errorClassName}
                    />
                )}

                {hasWarning && <FieldWarning warning={warningMessage} />}
            </div>
        );
    }
}

export default compose(resizableRoute, withFocus)(TextField);
