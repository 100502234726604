import React, { Component } from "react";
import { shape, bool, number, string } from "prop-types";
import { connect } from "react-redux";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import { channelsSelectors } from "reducers/administration";
import Row from "react-bootstrap/lib/Row";
import FieldLabel from "pages/_components/fields/FieldLabel";
import I18n from "pages/_components/I18n";
import classNames from "classnames";
import FormattedAmount from "pages/_components/FormattedAmount";

class CapsList extends Component {
    static propTypes = {
        activeEnvironment: shape({ type: string }).isRequired,
        caps: shape({
            amount: number,
            frequency: string,
            currency: string,
        }).isRequired,
        currency: shape({
            id: string,
        }).isRequired,
        topAmount: shape({
            amount: number,
            frequency: string,
            maximum: number,
        }).isRequired,
        hasDefaultLabel: bool,
        isConfirmation: bool,
        topAmountInline: bool,
        spaceBetween: bool,
        noBackgroundChip: bool,
        h4ClassName: string,
    };

    static defaultProps = {
        hasDefaultLabel: false,
        isConfirmation: false,
        topAmountInline: false,
        spaceBetween: false,
        noBackgroundChip: false,
        h4ClassName: "",
    };

    renderCaps = () => {
        const {
            activeEnvironment: { type },
            caps,
            hasDefaultLabel,
            isConfirmation,
            spaceBetween,
            noBackgroundChip,
            h4ClassName,
        } = this.props;

        if (spaceBetween) {
            return (
                <>
                    <Row>
                        <h4 className="data-label">
                            {isConfirmation ? (
                                <I18n id="administration.channels.confirmation.channels" />
                            ) : (
                                <I18n id="administration.channels" />
                            )}
                        </h4>
                    </Row>
                    {Object.entries(caps).map(([channel, { amount, frequency, currency }]) => (
                        <Row style={{ justifyContent: "space-between", marginBottom: "0.5rem" }}>
                            <span
                                className={`data-desc gray-pill
                                ${noBackgroundChip ? "background-none" : "background-color-efefef"}`}>
                                <I18n id={`channels.${channel}`} />
                            </span>
                            <>
                                <FormattedAmount
                                    className="f-size-4 font-light"
                                    quantity={
                                        amount === undefined
                                            ? parseFloat(configUtils.get(`default_cap_user_${type}`))
                                            : amount
                                    }
                                    frequency={i18nUtils.get(`administration.channels.${frequency}`).toUpperCase()}
                                    currency={currency}
                                    notBold
                                />{" "}
                                {hasDefaultLabel && amount === undefined && (
                                    <small className="data-desc text-muted">
                                        <I18n id="channels.defaultCap" />
                                    </small>
                                )}
                            </>
                        </Row>
                    ))}
                </>
            );
        }

        return (
            <section className="mt-3">
                {isConfirmation ? (
                    <I18n
                        component="h4"
                        componentProps={{ className: `data-label mb-3 ${h4ClassName}` }}
                        id="administration.channels.confirmation.channels"
                    />
                ) : (
                    <I18n
                        component="h4"
                        componentProps={{ className: `data-label mb-3 ${h4ClassName}` }}
                        id="administration.channels"
                    />
                )}

                {Object.entries(caps).map(([channel, { amount, frequency, currency }]) => (
                    <div key={channel} className="data-wrapper-flex mb-2">
                        <span
                            className={`data-desc gray-pill
                            ${noBackgroundChip ? "background-none pl-0" : "background-color-efefef"}`}>
                            <I18n id={`channels.${channel}`} />
                        </span>{" "}
                        <FormattedAmount
                            className="f-size-4 font-light"
                            quantity={
                                amount === undefined ? parseFloat(configUtils.get(`default_cap_user_${type}`)) : amount
                            }
                            frequency={i18nUtils.get(`administration.channels.${frequency}`).toUpperCase()}
                            currency={currency}
                            notBold
                        />{" "}
                        {hasDefaultLabel && amount === undefined && (
                            <small className="data-desc text-muted">
                                <I18n id="channels.defaultCap" />
                            </small>
                        )}
                    </div>
                ))}
            </section>
        );
    };

    render() {
        const { currency = {}, topAmount, isConfirmation, topAmountInline, spaceBetween } = this.props;
        if (topAmount) {
            const { frequency, maximum, amount } = topAmount;

            if (spaceBetween) {
                return (
                    <>
                        <Row style={{ justifyContent: "space-between" }}>
                            <FieldLabel
                                labelKey={
                                    isConfirmation
                                        ? "administration.channels.confirmation.topAmount"
                                        : "administration.channels.topAmount"
                                }
                            />
                            <FormattedAmount
                                quantity={maximum || amount}
                                frequency={i18nUtils.get(`administration.channels.${frequency}`).toUpperCase()}
                                currency={currency.id}
                                className="f-size-4 font-light mt-2"
                                notBold
                            />
                        </Row>
                        {this.renderCaps()}
                    </>
                );
            }

            return (
                <>
                    <div className={`data-wrapper ${topAmountInline ? "d-flex" : ""}`}>
                        {isConfirmation ? (
                            <I18n
                                componentProps={{
                                    className: `data-label ${topAmountInline ? "data-label-inline caps" : ""}`,
                                }}
                                id="administration.channels.confirmation.topAmount"
                            />
                        ) : (
                            <I18n
                                componentProps={{
                                    className: `data-label ${topAmountInline ? "data-label-inline caps" : ""}`,
                                }}
                                id="administration.channels.topAmount"
                            />
                        )}

                        <FormattedAmount
                            className={classNames("f-size-4 font-light", { "mt-2": !topAmountInline })}
                            quantity={maximum || amount}
                            frequency={i18nUtils.get(`administration.channels.${frequency}`).toUpperCase()}
                            currency={currency.id}
                            notBold
                        />
                    </div>
                    {this.renderCaps()}
                </>
            );
        }

        return null;
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    caps: channelsSelectors.getCaps(state),
    currency: channelsSelectors.getCurrencies(state)[0],
    topAmount: channelsSelectors.getTopAmount(state),
});

export default connect(mapStateToProps)(CapsList);
