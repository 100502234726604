/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { Link } from "react-router-dom";

import * as i18nUtils from "util/i18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import { number as numberType, bool } from "prop-types";
import { resizableRoute } from "pages/_components/Resizable";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class FinancingListItem extends Component {
    static propTypes = {
        balance: numberType.isRequired,
        currencyCode: numberType.isRequired,
        amountPerCurrency: numberType.isRequired,
        productCategoryCode: numberType.isRequired,
        isDesktop: bool,
        isEditable: bool.isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    render() {
        const { amountPerCurrency, balance, productCategoryCode, currencyCode, isDesktop, isEditable } = this.props;
        const currency = i18nUtils.get(`currency.label.${currencyCode.toString()}`);
        const title = i18nUtils.get(`financing.operationsType.${currency.toLowerCase()}`);
        const activeText = i18nUtils.get("financing.activeOperations.label");
        const amountLabel = i18nUtils.get("financing.amount.label");

        if (isDesktop) {
            return (
                <CardContainer
                    className="widget__card-financing mt-0"
                    renderAs={Link}
                    to={!isEditable ? "/financing/categories" : "/desktop"}>
                    <Card
                        className="financing__card"
                        title={
                            <div className="financing__card-title">
                                <span>{title}</span>
                                <div className="financing__card-active">
                                    <span className="financing__card-active-info">{activeText}</span>
                                    <span>{amountPerCurrency}</span>
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="financing__card-right" id={productCategoryCode}>
                                <span className="financing__card-amount-info">{amountLabel}</span>
                                <FormattedAmount
                                    className="financing__card-amount-data"
                                    quantity={balance}
                                    currency={currencyCode.toString()}
                                />
                            </div>
                        }
                    />
                </CardContainer>
            );
        }

        return (
            <CardContainer className="widget__card-financing mt-0" renderAs={Link} to="/financing/categories">
                <Card
                    className="financing__card m-0"
                    hrClass="ml-0"
                    title={
                        <div className="financing__card-title">
                            <span>{title}</span>
                            <div className="financing__card-active">
                                <span className="financing__card-active-info">{activeText}</span>
                                <span>{amountPerCurrency}</span>
                            </div>
                        </div>
                    }
                    rightContent={
                        <div className="financing__card-right" id={productCategoryCode}>
                            <span className="financing__card-amount-info">{amountLabel}</span>
                            <FormattedAmount
                                className="financing__card-amount-data"
                                quantity={balance}
                                currency={currencyCode.toString()}
                            />
                        </div>
                    }
                />
            </CardContainer>
        );
    }
}

export default resizableRoute(FinancingListItem);
