import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    LIST_REQUEST: "communicationTrays/LIST_REQUEST",
    LIST_FAILURE: "communicationTrays/LIST_FAILURE",
    LIST_SUCCESS: "communicationTrays/LIST_SUCCESS",
};

export const INITIAL_STATE = {
    list: [],
};

export default createReducer(INITIAL_STATE, {
    [types.LIST_REQUEST]: () => INITIAL_STATE,
    [types.LIST_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LIST_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        list: action.list,
    }),
});

export const actions = {
    listRequest: makeActionCreator(types.LIST_REQUEST),
    listFailure: makeActionCreator(types.LIST_FAILURE),
    listSuccess: makeActionCreator(types.LIST_SUCCESS, "list"),
};

export const selectors = {
    list: ({ communicationTrays }) => communicationTrays.list,
    isFetching: ({ communicationTrays }) => communicationTrays.isFetching,
};
