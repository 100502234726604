import { call, put, takeLatest } from "redux-saga/effects";
import { types } from "reducers/financing";
import {
    loadListFinanRequest,
    getFinancingDetailMiddleware,
    downloadListRequest,
    downloadListFeedRequest,
    downloadFeedRequestMiddleware,
} from "middleware/financing";
import { downloadPdf, downloadXls } from "util/download";
import * as i18n from "util/i18n";

import { actions as notificationActions } from "reducers/notification";

const sagas = [
    takeLatest(types.LOAD_LIST_REQUEST, loadListFinancingRequest),
    takeLatest(types.GET_FINANCING_DETAIL_REQUEST, getFinancingDetailRequest),
    takeLatest(types.DOWNLOAD_LIST_REQUEST, downloadList),
    takeLatest(types.DOWNLOAD_LISTFEED_REQUEST, downloadListFeed),
    takeLatest(types.DOWNLOAD_FEED_REQUEST, downloadFeedRequest),
];

export default sagas;

function* loadListFinancingRequest({ filters }) {
    const response = yield call(loadListFinanRequest, filters);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["loans"]));
        yield put({ type: types.LOAD_LIST_FAILURE });
    } else {
        yield put({
            type: types.LOAD_LIST_SUCCESS,
            financingList: response.data.data.financing,
            totalPages: response.data.data.totalPages,
            pageNumber: response.data.data.pageNumber,
        });
    }
}
function* getFinancingDetailRequest({ numOperacion, financingList, stateFeed, filters }) {
    const response = yield call(getFinancingDetailMiddleware, numOperacion, financingList, stateFeed, filters);
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["loans"]));
        yield put({ type: types.GET_FINANCING_DETAIL_FAILURE });
    } else {
        yield put({
            type: types.GET_FINANCING_DETAIL_SUCCESS,
            financingDetail: response.data.data.financingDetail,
            financingFeed: response.data.data.financingFeed,
            lastDateFromFilter: response.data.data.lastDateFrom,
            lastDateToFilter: response.data.data.lastDateTo,
        });
    }
}
function* downloadListFeed({
    format,
    financingFeed,
    financingDetail,
    lastDateFromFilter,
    lastDateToFilter,
    optionSelected,
}) {
    const { type, data } = yield call(
        downloadListFeedRequest,
        format,
        financingFeed,
        financingDetail,
        lastDateFromFilter,
        lastDateToFilter,
        optionSelected,
    );

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_LISTFEED_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]));
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_LISTFEED_SUCCESS });
        yield put(notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["form"]));
    }
}
function* downloadList({ format, financing }) {
    const { type, data } = yield call(downloadListRequest, format, financing);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_LIST_FAILURE });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["loans"]));
    } else {
        const { content, fileName } = data.data;

        downloadPdf(fileName, content);

        yield put({ type: types.DOWNLOAD_LIST_SUCCESS });
        yield put(notificationActions.showNotification(i18n.get("global.successDownload"), "success", ["loans"]));
    }
}
function* downloadFeedRequest({ format, financingFeed, financingDetail }) {
    const { type, data } = yield call(downloadFeedRequestMiddleware, format, financingFeed, financingDetail);

    if (type === "W") {
        yield put({ type: types.DOWNLOAD_FEED_SUCCESS });
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["financing"]));
    } else {
        const { content, fileName } = data.data;

        if (format === "pdf") {
            downloadPdf(fileName, content);
        } else {
            downloadXls(fileName, content);
        }

        yield put({ type: types.DOWNLOAD_FEED_FAILURE });
    }
}
