import React from "react";
import { Modal } from "react-bootstrap";
import { bool, func, shape, arrayOf } from "prop-types";
import classNames from "classnames";

// import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";

function BatchDetailErrorsModal(props) {
    const { isVisible, isDesktop, toggleModal, dataModal } = props;

    const getItem = (item) => (
        <li key={item.code} className="pb-2">
            <div>
                <I18n
                    component="h4"
                    componentProps={{
                        className: "data-label text-label danger-label mb-0",
                        style: { textTransform: "initial" },
                    }}
                    id="echeq.batch.detail.errors.codeTitle"
                    CODE={item.code}
                />
                <p>{item.description}</p>
            </div>
        </li>
    );

    const contentInfo = () => (
        <div>
            <div className={`w-100 align-items-start flex-column ${dataModal?.echeqNumber && "pb-4"}`}>
                <div className={isDesktop && "my-3"}>
                    <div className="d-flex align-items-center">
                        {isDesktop && <div className="px-2 py-4 bg-danger" />}
                        {dataModal?.echeqNumber ? (
                            <I18n
                                component="h2"
                                componentProps={{
                                    className: classNames("content-data-strong", {
                                        "pl-4": isDesktop,
                                        "pl-5": !isDesktop,
                                    }),
                                }}
                                id="echeq.batch.detail.errors.title"
                                upperCase
                                ECHEQ_NUMBER={dataModal?.echeqNumber}
                            />
                        ) : (
                            <div className={isDesktop ? "d-flex w-100 pl-3" : "d-flex w-100 pl-3 pt-3"}>
                                <I18n
                                    upperCase
                                    id="echeq.batch.detail.table.header.echeqNumber"
                                    componentProps={{ className: "pr-2" }}
                                />
                                <I18n id="echeq.batch.detail.body.echeqNumber.notAvailable" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="px-4 form-group-control-list">
                    <ul>{dataModal?.errors?.map((item) => (item?.code ? getItem(item) : <></>))}</ul>
                </div>
                {!isDesktop && (
                    <div className="px-4 w-100 d-flex justify-content-end">
                        <Button
                            block={false}
                            bsStyle="link"
                            label="global.accept"
                            onClick={() => toggleModal(false, [])}
                            className="my-0 py-0"
                        />
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <Modal show={isVisible} className="echeq-template-modal" bsClass="modal" onHide={() => toggleModal(false, [])}>
            {isDesktop && (
                <Modal.Header
                    closeButton
                    className={`no-title ${dataModal?.echeqNumber === null && "echeqs__no-number"}`}
                />
            )}
            <Modal.Body
                className={classNames(
                    "pb-2 show",
                    { "max-width-600": isDesktop },
                    { "min-width-20": dataModal?.echeqNumber === null },
                )}>
                {contentInfo()}
            </Modal.Body>
        </Modal>
    );
}

BatchDetailErrorsModal.propTypes = {
    isDesktop: bool,
    isVisible: bool,
    toggleModal: func.isRequired,
    dataModal: arrayOf(shape({})),
};

BatchDetailErrorsModal.defaultProps = {
    isDesktop: true,
    isVisible: false,
    dataModal: [],
};

export default BatchDetailErrorsModal;
