import React, { Component, Fragment } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import { arrayOf, func, string, bool, shape, number } from "prop-types";

import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { selectors as onboardingSelectors } from "reducers/onboarding";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";

import Notification from "pages/_components/Notification";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Head from "pages/_components/Head";
import withExchangeToken from "pages/_components/withExchangeToken";
import I18n from "pages/_components/I18n";
import OnboardingStepper, { orientations } from "pages/onboarding/_components/OnboardingStepper";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import EsignModal from "pages/forms/_components/_esign/EsignModal";
import DownloadableDocument from "pages/forms/_components/_esign/DownloadableDocument";
import EnrollmentStepper from "./_components/EnrollmentStepper";
import AssociateStepperSimple from "./_components/AssociateStepperSimple";

const FORM_ID = "enrollment.step4";

class Step3Part4 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool.isRequired,
        documentData: shape({}).isRequired,
        documents: arrayOf(shape({ fileNameKey: string, size: string })).isRequired,
        fetching: bool.isRequired,
        exchangeToken: string,
        client: shape({}),
        invitation: shape({}),
        idEnvironment: number,
        loginEmail: string,
        onboardingEmail: string,
    };

    static defaultProps = {
        exchangeToken: null,
        client: null,
        invitation: {},
        idEnvironment: null,
        loginEmail: null,
        onboardingEmail: null,
    };

    state = {
        disableSubmit: true,
        disableContracts: true,
        acceptTerms: false,
        acceptEsign: false,
        displayModal: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(settingsActions.loadDocumentsRequest());
    }

    onHeaderBack = () => {
        const { dispatch } = this.props;

        dispatch(goBack());
    };

    onHeaderClose = () => {
        const { dispatch } = this.props;

        dispatch(loginActions.reset());
    };

    handleReadTermsClick = () => {
        const { acceptTerms } = this.state;
        this.setState({ acceptTerms: !acceptTerms });
    };

    handleChange = (value) => {
        const { acceptTerms } = this.state;
        if (acceptTerms) {
            this.setState({ acceptTerms: false });
        }
        switch (value) {
            case "ACCEPT":
                this.setState({ disableContracts: false });
                this.setState({ disableSubmit: false });
                break;
            case "DECLINE":
                this.setState({ disableContracts: true });
                this.setState({ disableSubmit: false });
                break;
            default:
                this.setState({ disableSubmit: true });
                break;
        }
        this.setState({ acceptEsign: value });
    };

    handleCloseBottomSheetClick = () => {
        this.setState({ displayModal: false });
    };

    handleSubmit = () => {
        const { acceptEsign, acceptTerms } = this.state;
        const { exchangeToken, dispatch } = this.props;
        if (acceptEsign && acceptTerms) {
            if (exchangeToken) {
                // esto se ejecuta cuando venimos de enrollment
                this.sendTermsAndConditions(true);
            } else {
                // y esto cuando venimos del login y falta aceptar ESign
                dispatch(loginActions.loginStep4(true, null));
            }
        } else {
            this.setState({ displayModal: true });
        }
    };

    handleSendByEmail = () => {
        const { dispatch, loginEmail, onboardingEmail } = this.props;
        const onboardingOrLoginEmail = onboardingEmail || loginEmail;
        let userEmail = onboardingOrLoginEmail;
        if (!userEmail) {
            const {
                client: { email },
            } = this.props;
            // only enrollment email case
            userEmail = email;
        }

        dispatch(enrollmentActions.sendDocumentsByMail(userEmail));
    };

    handleProceedClick = () => {
        this.sendTermsAndConditions(false);
    };

    sendTermsAndConditions = (accept) => {
        const { client, invitation, idEnvironment, dispatch, exchangeToken } = this.props;

        let emailOut = "";
        let firstNameOut = "";
        let lastNameOut = "";
        if (client && client.email) {
            const { email, firstName, lastName } = client;
            emailOut = email;
            firstNameOut = firstName;
            lastNameOut = lastName;
        } else {
            emailOut = invitation.email;
            firstNameOut = invitation.firstName;
            lastNameOut = invitation.lastName;
        }

        dispatch(
            enrollmentActions.acceptESign(idEnvironment, emailOut, exchangeToken, firstNameOut, lastNameOut, accept),
        );
    };

    handleClickDownload = (fileNameKey) => {
        const { dispatch } = this.props;

        dispatch(settingsActions.downloadDocumentRequest(fileNameKey));
    };

    render() {
        const { disableSubmit, acceptTerms, acceptEsign, disableContracts, displayModal } = this.state;
        const { isMobile, documentData, documents, fetching } = this.props;
        const electronicSignatureDoc = documents.filter(
            (doc) => doc.fileNameKey === "generalConditionDocument.electronic_signature_disclosure_and_consent.pdf",
        );
        const restOfDocuments = documents.filter(
            (doc) => doc.fileNameKey !== "generalConditionDocument.electronic_signature_disclosure_and_consent.pdf",
        );

        return (
            <Fragment>
                <EsignModal
                    idForm={FORM_ID}
                    acceptEsign={acceptEsign}
                    acceptTerms={acceptTerms}
                    handleCloseBottomSheetClick={this.handleCloseBottomSheetClick}
                    goBack={this.handleCloseBottomSheetClick}
                    handleProceedClick={this.handleProceedClick}
                    isDisplayed={displayModal}
                />
                <Notification scopeToShow="enrollmentTermsAndConditions" />
                <Head title="onboarding.step.12.title.1" onClose={isMobile && this.onHeaderClose} />
                <div className="view-page">
                    {!isMobile && documentData.firstName && (
                        <OnboardingStepper currentStep={11} className="onboarding-steps" />
                    )}
                    {!(isMobile || documentData.firstName) && (
                        <EnrollmentStepper currentStep={5} className="onboarding-steps" />
                    )}
                    <div className="view-content">
                        <main className="main-container">
                            <Form className="above-the-fold" noValidate>
                                {isMobile && (
                                    <Container
                                        className="container--layout align-items-center"
                                        gridClassName="form-content">
                                        <Col className="col col-12">
                                            {documentData.firstName && (
                                                <OnboardingStepper
                                                    currentStep={11}
                                                    orientation={orientations.horizontal}
                                                />
                                            )}
                                            {!documentData.firstName && <AssociateStepperSimple currentStep={5} />}
                                        </Col>
                                    </Container>
                                )}
                                <Container className="container--layout flex-grow">
                                    <Col className="col col-12">
                                        <ul className="navigational-list">
                                            <li className="navigational-list-item navigational-list-downLoad">
                                                {electronicSignatureDoc.map((contract) => (
                                                    <li className="navigational-list-item navigational-list-downLoad">
                                                        <DownloadableDocument
                                                            fileName={contract.fileNameKey}
                                                            size={contract.size}
                                                            handleClickDownload={this.handleClickDownload}
                                                        />
                                                    </li>
                                                ))}
                                            </li>
                                        </ul>
                                        <fieldset className="form-group form-group_mrg">
                                            <RadioButtonGroup
                                                inLineControl
                                                name="eSignOption"
                                                onChange={this.handleChange}
                                                optionClassName="needsclick"
                                                options={[
                                                    {
                                                        id: "ACCEPT",
                                                        label: <I18n id="onboarding.step.12.esign.accept" />,
                                                    },
                                                    {
                                                        id: "DECLINE",
                                                        label: <I18n id="onboarding.step.12.esign.decline" />,
                                                    },
                                                ]}
                                                value={acceptEsign}
                                            />
                                        </fieldset>
                                    </Col>
                                    {!disableContracts && (
                                        <Col className="col col-12">
                                            <I18n component="h3" id="onboarding.step.12.title.2" />
                                            <I18n
                                                component="span"
                                                componentProps={{ className: "text-lead" }}
                                                id="onboarding.step.12.info.1"
                                            />
                                            <ul className="navigational-list">
                                                {restOfDocuments.map((contract) => (
                                                    <li className="navigational-list-item navigational-list-downLoad">
                                                        <DownloadableDocument
                                                            fileName={contract.fileNameKey}
                                                            size={contract.size}
                                                            handleClickDownload={this.handleClickDownload}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                            <I18n id="onboarding.step.12.info.2" />
                                            <Field
                                                name="readAgreements"
                                                idForm={FORM_ID}
                                                label={<I18n id="onboarding.step.12.check" />}
                                                component={Checkbox}
                                                value={acceptTerms}
                                                onClick={this.handleReadTermsClick}
                                                checked={acceptTerms}
                                                disabled={disableContracts}
                                            />
                                        </Col>
                                    )}
                                </Container>
                                <Container className="container--layout align-items-center">
                                    <Col className="col col-12">
                                        <Button
                                            bsStyle="primary"
                                            label="global.continue"
                                            loading={fetching}
                                            type="button"
                                            disabled={disableSubmit}
                                            onClick={this.handleSubmit}
                                        />
                                        <Button
                                            className="btn-outline"
                                            label="onboarding.step.12.email"
                                            loading={fetching}
                                            type="button"
                                            image="images/e-mail.svg"
                                            onClick={this.handleSendByEmail}
                                        />
                                    </Col>
                                </Container>
                            </Form>
                        </main>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    idEnvironment: enrollmentSelectors.getIdEnvironment(state),
    client: enrollmentSelectors.getClient(state),
    exchangeToken: enrollmentSelectors.getExchangeToken(state),
    fetching: enrollmentSelectors.getFetching(state),
    invitation: enrollmentSelectors.getInvitation(state) || {},
    documentData: onboardingSelectors.getDocumentData(state),
    documents: settingsSelectors.getDocuments(state),
    loginEmail: loginSelectors.getUsername(state),
    onboardingEmail: onboardingSelectors.getEmail(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
    }),
)(withExchangeToken(Step3Part4));
