import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import { shape, func, string, bool } from "prop-types";

import * as i18nUtils from "util/i18n";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
/* eslint-disable react/no-multi-comp */
import PageLoading from "pages/_components/PageLoading";
import AdministrationHeading from "pages/administration/_components/Heading";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import PermissionsList from "pages/administration/_components/PermissionsList";
import CapsList from "pages/administration/_components/CapsList";
import UserDetailsGroupList from "pages/administration/_components/UserDetailsGroupList";
import Heading from "pages/_components/Heading";
import FeatureFlag from "pages/_components/FeatureFlag";
import RestrictionsUserProfile from "pages/administration/restrictions/RestrictionsUserProfile";
import Circle from "pages/_components/Circle";
import Button from "pages/_components/Button";
import InfoTag from "pages/_components/InfoTag";
import { Col } from "react-bootstrap";
import I18n from "pages/_components/I18n";
import { Link } from "react-router-dom";

class Details extends Component {
    static propTypes = {
        actions: shape({
            loadDetailsRequest: func.isRequired,
            changeUserStatusPreview: func.isRequired,
            updateDispatcherRequest: func.isRequired,
            getRestrictions: func.isRequired,
        }).isRequired,
        match: shape({
            params: shape({ id: string }),
        }).isRequired,
        routerActions: shape({
            goBack: func.isRequired,
        }).isRequired,
        userEnvStatus: string,
        user: shape({
            idUser: string,
            fullName: string,
        }),
        userInfo: shape({
            dispatcher: bool,
        }),
        hasMassiveEnabled: bool,
        hasSignatureEnabled: bool,
        signatureLevel: string,
        schemeName: string,
        fetching: bool.isRequired,
    };

    static defaultProps = {
        userEnvStatus: "",
        user: {},
        userInfo: {},
        hasMassiveEnabled: false,
        hasSignatureEnabled: false,
        signatureLevel: "",
        schemeName: "",
    };

    componentDidMount() {
        const { actions, match } = this.props;
        const idUser = match.params.id;
        actions.loadDetailsRequest(idUser);
        actions.getRestrictions(idUser);
    }

    handleBack = () => {
        const { routerActions } = this.props;

        routerActions.goBack();
    };

    handleActionClick = (actionOnTheUser, fullName) => {
        const { actions, match } = this.props;

        actions.changeUserStatusPreview([match.params.id], [fullName], actionOnTheUser);
    };

    handleToggleDispatcher = (dispatcher) => {
        const { user, actions } = this.props;
        actions.updateDispatcherRequest(user.idUser, dispatcher);
    };

    renderHeader = () => {
        const { user, userEnvStatus, hasMassiveEnabled } = this.props;
        const isAdministrator = !hasMassiveEnabled;

        return (
            <>
                <div className="admin-detail-head admin-detail-head-section px-0">
                    <Head onBack={this.handleBack} />
                    <div className="mb-3">
                        <div className="view-title d-flex">
                            <h1 className="d-flex align-items-center">{user.fullName}</h1>
                            <div className="data-wrapper data-wrapper-flex ml-3">
                                {isAdministrator && (
                                    <InfoTag
                                        type="default"
                                        message={i18nUtils.get(`administration.administrator`)}
                                        tagBackground="#707070"
                                    />
                                )}
                                <InfoTag
                                    type="default"
                                    message={i18nUtils.get(`user.status.${userEnvStatus}`)}
                                    tagBackground={userEnvStatus === "blocked" ? "#fc5f5f" : "#0be0a2"}
                                />
                            </div>
                            {hasMassiveEnabled && (
                                <div className="flex-container">
                                    {userEnvStatus === "active" && (
                                        <div>
                                            <Button
                                                bsStyle="link"
                                                key="block"
                                                label="administration.block"
                                                type="button"
                                                className="py-1"
                                                onClick={() => this.handleActionClick("block", user.fullName)}
                                            />
                                        </div>
                                    )}
                                    {userEnvStatus === "blocked" && (
                                        <div>
                                            <Button
                                                bsStyle="link"
                                                key="unblock"
                                                label="administration.unblock"
                                                type="button"
                                                className="py-1"
                                                onClick={() => this.handleActionClick("unblock", user.fullName)}
                                            />
                                        </div>
                                    )}

                                    <Button
                                        bsStyle="link"
                                        key="delete"
                                        label="administration.delete"
                                        type="button"
                                        onClick={() => this.handleActionClick("delete", user.fullName)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <AdministrationHeading>
                        {({ email, documentType, documentNumber, mobileNumber }) => (
                            <Row className="content-data px-4">
                                <Col md={3} sm={12} className="px-0 d-flex align-items-center">
                                    <Heading.DataGroup
                                        label="administration.permissions.document"
                                        data={`${documentType} ${documentNumber}`}
                                    />
                                </Col>
                                <Container.Column
                                    md={6}
                                    sm={12}
                                    className="px-0 d-flex justify-content-center align-items-center">
                                    <Heading.DataGroup label="administration.permissions.email" data={email} />
                                </Container.Column>
                                <Container.Column
                                    md={3}
                                    sm={12}
                                    className="px-0 d-flex align-items-center justify-content-end">
                                    <Heading.DataGroup
                                        label="administration.permissions.mobileNumber"
                                        data={mobileNumber}
                                    />
                                </Container.Column>
                            </Row>
                        )}
                    </AdministrationHeading>
                </div>
            </>
        );
    };

    renderAdvancedContent = () => {
        const { Column } = Container;
        const { user, signatureLevel, schemeName, userInfo } = this.props;

        return (
            <>
                {this.renderHeader()}
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="flex-grow admin-detail mt-2">
                            <Column md={4} sm={12} className="pl-4">
                                <div className="d-flex align-items-center">
                                    <I18n component="h2" id="administration.channels.title" />
                                    <Link
                                        className="btn btn-link ml-3"
                                        to={`/administration/${schemeName}/channels/${user.idUser}`}>
                                        <I18n id="administration.configure" />
                                    </Link>
                                </div>
                                <Container.ColumnBody>
                                    <CapsList />
                                </Container.ColumnBody>
                            </Column>

                            <Column md={4} sm={12}>
                                <div className="d-flex align-items-center">
                                    <I18n component="h2" id="administration.permissions.signature" />
                                    <Link
                                        className="btn btn-link ml-3"
                                        to={`/administration/${schemeName}/signature/${user.idUser}/confirm`}>
                                        <I18n id="administration.configure" />
                                    </Link>
                                </div>
                                <Container.ColumnBody>
                                    <p className="mt-0 mb-4">
                                        <Circle value={signatureLevel || i18nUtils.get("global.no").toUpperCase()} />
                                    </p>
                                </Container.ColumnBody>
                                <div className="d-flex align-items-center">
                                    <I18n component="h2" id="administration.groups.index.title" />
                                    <Link
                                        className="btn btn-link ml-3"
                                        to={`/administration/${schemeName}/groupsOfUser/${user.idUser}`}>
                                        <I18n id="administration.configure" />
                                    </Link>
                                </div>
                                <Container.ColumnBody>
                                    <UserDetailsGroupList />
                                </Container.ColumnBody>
                            </Column>

                            <Column md={4} sm={12} className="pr-4">
                                <FeatureFlag id="feature.signatureSchema.dispatchControl">
                                    <div className="d-flex align-items-center">
                                        <I18n component="h2" id="administration.dispatcher.title" />
                                        <Link
                                            className="btn btn-link ml-3"
                                            to={`/administration/users/dispatcher/${user.idUser}/confirm`}>
                                            <I18n
                                                id={
                                                    userInfo.dispatcher
                                                        ? "administration.dispatcher.disable"
                                                        : "administration.dispatcher.enable"
                                                }
                                            />
                                        </Link>
                                    </div>
                                    <Container.ColumnBody>
                                        <p className="mt-0 mb-4">
                                            <Circle
                                                value={
                                                    userInfo.dispatcher
                                                        ? i18nUtils.get("global.yes").toUpperCase()
                                                        : i18nUtils.get("global.no").toUpperCase()
                                                }
                                                bgColor={userInfo.dispatcher ? "#0be0a2" : "#fc5f5f"}
                                            />
                                        </p>
                                    </Container.ColumnBody>
                                </FeatureFlag>
                                <Container.ColumnHeader title="administration.restrictions.title" />
                                <div className="d-flex align-items-center">
                                    <I18n component="h2" id="administration.restrictions.title" />
                                </div>
                                <Container.ColumnBody>
                                    <RestrictionsUserProfile idUser={user.idUser} />
                                </Container.ColumnBody>
                            </Column>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    };

    renderMediumContent = () => {
        const { user, hasSignatureEnabled, schemeName, userInfo } = this.props;

        return (
            <>
                {this.renderHeader()}
                <MainContainer>
                    <div className="above-the-fold">
                        <Container className="flex-grow admin-detail">
                            <Container.Column md={4} sm={12}>
                                <div className="d-flex align-items-center">
                                    <I18n component="h2" id="administration.channels.title" />
                                    <Link
                                        className="btn btn-link ml-3"
                                        to={`/administration/${schemeName}/channels/${user.idUser}`}>
                                        <I18n id="administration.configure" />
                                    </Link>
                                </div>
                                <Container.ColumnBody>
                                    <CapsList />
                                </Container.ColumnBody>
                            </Container.Column>

                            <Container.Column md={4} sm={12}>
                                <div className="d-flex align-items-center">
                                    <I18n component="h2" id="administration.permissions.signature" />
                                    <Link
                                        className="btn btn-link ml-3"
                                        to={`/administration/${schemeName}/signature/${user.idUser}/confirm`}>
                                        <I18n id={`administration.signature.configure.text.${hasSignatureEnabled}`} />
                                    </Link>
                                </div>
                                <Container.ColumnBody>
                                    <p>
                                        <Circle
                                            value={
                                                userInfo.signatureLevel
                                                    ? i18nUtils.get("global.yes").toUpperCase()
                                                    : i18nUtils.get("global.no").toUpperCase()
                                            }
                                        />
                                    </p>
                                </Container.ColumnBody>

                                <div className="d-flex align-items-center">
                                    <I18n component="h2" id="administration.permissions" />
                                    <Link
                                        className="btn btn-link ml-3"
                                        to={`/administration/${schemeName}/permissions/${user.idUser}`}>
                                        <I18n id="administration.configure" />
                                    </Link>
                                </div>
                                <Container.ColumnBody columnClass="list-wrapper">
                                    <PermissionsList accordionView borderClassName="border-acordion">
                                        {(list) => list || i18nUtils.get("administration.permissions.empty")}
                                    </PermissionsList>
                                </Container.ColumnBody>
                            </Container.Column>

                            <Container.Column md={4} sm={12}>
                                <FeatureFlag id="feature.signatureSchema.dispatchControl">
                                    <div className="d-flex align-items-center">
                                        <I18n component="h2" id="administration.dispatcher.title" />
                                        <Link
                                            className="btn btn-link ml-3"
                                            to={`/administration/users/dispatcher/${user.idUser}/confirm`}>
                                            <I18n id={`administration.users.dispatcher.${userInfo.dispatcher}`} />
                                        </Link>
                                    </div>
                                    <Container.ColumnBody>
                                        <p>
                                            <Circle
                                                value={
                                                    userInfo.dispatcher
                                                        ? i18nUtils.get("global.yes").toUpperCase()
                                                        : i18nUtils.get("global.no").toUpperCase()
                                                }
                                                bgColor={userInfo.dispatcher ? "#0be0a2" : "#fc5f5f"}
                                            />
                                        </p>
                                    </Container.ColumnBody>
                                </FeatureFlag>
                                <div className="d-flex align-items-center">
                                    <I18n component="h2" id="administration.restrictions.title" />
                                </div>
                                <Container.ColumnBody>
                                    <RestrictionsUserProfile idUser={user.idUser} />
                                </Container.ColumnBody>
                            </Container.Column>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    };

    renderContent = () => {
        const { schemeName } = this.props;

        if (schemeName === "medium") {
            return this.renderMediumContent();
        }

        return this.renderAdvancedContent();
    };

    render() {
        const { fetching } = this.props;
        const isLoading = fetching; // && !Object.keys(user).length;

        return (
            <>
                <Notification scopeToShow="administration" />
                <PageLoading loading={isLoading}>{!isLoading && this.renderContent()}</PageLoading>
            </>
        );
    }
}

export default Details;
