import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Col from "react-bootstrap/lib/Col";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import { bool, func, string, shape } from "prop-types";
import { withFormik, Form, Field } from "formik";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import I18n from "pages/_components/I18n";
import Selector from "pages/_components/fields/formik/Selector";
import { Link } from "react-router-dom";
import Yup from "yup";
import { Mixpanel } from "util/clickstreaming";
import { selectors as tokenSelectors } from "reducers/token";
import { push, goBack } from "react-router-redux/actions";
import { compose } from "redux";
import Logo from "pages/login/_components/Logo";

const FORM_ID = "select.channels";

class SelectChannel extends Component {
    componentDidMount() {
        const { configSelectChannel, dispatch } = this.props;

        if (!configSelectChannel?.onSubmit) {
            dispatch(push("/"));
        }
    }

    renderTitleMobile = () => <Logo className="svg-image logo logo-mobile-size" logoColor />;

    render() {
        const { isDesktop, configSelectChannel, isSubmitting, dispatch } = this.props;

        const {
            headerTitle,
            title,
            onCloseHeader,
            replaceValues,
            maskedPhone,
            maskedEmail,
            formHeader,
            closeable,
        } = configSelectChannel;

        const closeHeader = onCloseHeader ? () => onCloseHeader() : () => dispatch(goBack());

        return (
            <>
                <Notification scopeToShow="recoveryPassword" />
                <Head
                    title={isDesktop && headerTitle}
                    onClose={closeable && closeHeader}
                    headerClassName={!isDesktop && "login-background-color-white"}
                    centerElement={!isDesktop && this.renderTitleMobile}
                    hideMobileMenu={!isDesktop && true}
                    replace={isDesktop && replaceValues}
                    fixed
                />
                {!isDesktop && (
                    <>
                        <Head
                            title={headerTitle}
                            headerClassName={!isDesktop && "login-background-color-white"}
                            hideMobileMenu={!isDesktop && true}
                            replace={replaceValues}
                        />
                        {formHeader && (
                            <div className="view-title">
                                <I18n id={formHeader} component="h1" />
                            </div>
                        )}
                    </>
                )}

                <MainContainer className={classNames("main-container", { "container-fluid": isDesktop })}>
                    <Form className={`above-the-fold ${isDesktop ? "login-desktop-wrapper" : ""}`}>
                        <Container className="container--layout flex-grow align-items-center">
                            <Col sm={12} md={12} lg={12} className="col col-12">
                                <p className="text-lead">
                                    <I18n id={title} />
                                </p>
                                <Field
                                    component={Selector}
                                    renderAs="radio"
                                    radioDisplayGrid
                                    name="sendChannel"
                                    options={[
                                        {
                                            id: `numberPhone`,
                                            label: `${i18n.get(`recoveryPassword.step1.device.phone`)} ${maskedPhone}`,
                                        },
                                        {
                                            id: `email`,
                                            label: `${i18n.get(`recoveryPassword.step1.device.email`)} ${maskedEmail}`,
                                        },
                                    ]}
                                />
                                <div style={{ marginTop: "0.1rem", textAlign: "center" }}>
                                    <Link
                                        className="login-link-blue to-uppercase"
                                        to="/recoveryPassword/step2/processDisclaimer">
                                        <I18n id="recoveryPassword.step1.device.none" />
                                    </Link>
                                </div>
                            </Col>
                        </Container>
                        <Container className="align-items-center container--layout">
                            <Col sm={12} md={12} lg={12} className="col col-12">
                                <Button
                                    label="global.continue"
                                    type="submit"
                                    bsStyle="primary"
                                    loading={isSubmitting}
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </>
        );
    }
}

SelectChannel.propTypes = {
    isDesktop: bool,
    isSubmitting: bool,
    dispatch: func.isRequired,
    configSelectChannel: shape({
        title: string,
        onSubmit: func,
        headerTitle: string,
        onCloseHeader: func,
        replaceValues: func,
        maskedPhone: "",
        maskedEmail: "",
    }).isRequired,
};

SelectChannel.defaultProps = {
    isSubmitting: false,
    isDesktop: true,
};

const mapStateToProps = (state) => ({
    configSelectChannel: tokenSelectors.getSelectChannelConfig(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            secondFactor: "",
            sendChannel: "phone",
            configSelectChannel: props.configSelectChannel,
        }),
        validationSchema: () =>
            Yup.object().shape({
                sendChannel: Yup.string().required(i18n.get(`${FORM_ID}.sendChannel.required`)),
            }),
        handleSubmit: (values) => {
            Mixpanel.track(FORM_ID);
            const { onSubmit } = values.configSelectChannel;
            onSubmit(values.sendChannel);
        },
    }),
)(SelectChannel);
