import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bool, func, shape, string } from "prop-types";

import { selectors as fundcorpSelectors } from "reducers/fundcorp";
import { chooseImage } from "util/transaction";
import * as i18n from "util/i18n";

import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import Container from "pages/_components/Container";
import PageLoading from "pages/_components/PageLoading";
import MainContainer from "pages/_components/MainContainer";
import FieldLabel from "pages/_components/fields/FieldLabel";
import * as stringUtils from "util/string";

const FORM_ID = "fund.operation.registerShareAccount";

function ShareAccountTicket({
    dispatch,
    isDesktop,
    fetching,
    summaryShareaccount,
    status,
    numCuotapartista,
    investorProfile,
}) {
    const handleBack = () => {
        dispatch(push("/fundcorpOperations"));
    };

    const renderHead = () => (
        <>
            <Head
                onBack={handleBack}
                headerClassName={classNames({
                    "blue-main-header-mobile": !isDesktop,
                })}
                centerContentClassName={classNames({
                    "mx-5": !isDesktop,
                })}
                title={!isDesktop && `${FORM_ID}.head.label`}
                titleClassName="text-white"
                accessibilityTextId={`${FORM_ID}.head.label`}
            />
            {isDesktop && <h1 className="mt-1 mb-3">{i18n.get(`${FORM_ID}.head.label`)}</h1>}
        </>
    );

    const renderContent = () => {
        const profile = !stringUtils.isEmpty(investorProfile?.investorProfileType?.trim())
            ? i18n.get(`${FORM_ID}.investorProfile.type.${investorProfile?.investorProfileType}`)
            : "-";

        return (
            <main className="px-0">
                <Container className="flex-grow align-items-center">
                    <Col
                        className={classNames(
                            "cmf-container-white col-12",
                            { "py-5": status === "FINISHED" && isDesktop },
                            { "py-8": status === "FAILED" && !isDesktop },
                        )}>
                        <div className="admin-content-center mt-3 pt-3">
                            <Image
                                src={chooseImage(status)}
                                styles={{
                                    width: isDesktop ? "90px" : "65px",
                                    height: isDesktop ? "90px" : "65px",
                                }}
                            />
                        </div>
                        <div className={`admin-content-center ${status === "FINISHED" ? "" : "pb-5"}`}>
                            <I18n
                                component="h3"
                                id={
                                    status === "FINISHED"
                                        ? `${FORM_ID}.summary.success.message`
                                        : `${FORM_ID}.summary.error.message`
                                }
                                componentProps={{ className: `cmf-title-center` }}
                            />
                        </div>
                        {status === "FINISHED" && (
                            <Col className="px-2 pb-4 col-12">
                                <Container className="flex-grow align-items-center mt-4">
                                    <Col xs={12} md={4} className="px-3 mb-2">
                                        <Row className="align-items-start">
                                            <FieldLabel
                                                idField="1"
                                                labelKey={`${FORM_ID}.summary.profile.label`}
                                                notMarginY
                                                bold={false}
                                            />
                                        </Row>
                                        <Row className="align-items-start pb-2">
                                            <p className={`data-desc ${!isDesktop ? "mt-0" : ""}`}>
                                                {stringUtils.capitalizeFirstLetter(profile.toLowerCase())}
                                            </p>
                                        </Row>
                                        <Row className="align-items-start">
                                            <FieldLabel
                                                idField="1"
                                                labelKey={`${FORM_ID}.summary.accounts.label`}
                                                notMarginY
                                                bold={false}
                                            />
                                        </Row>
                                        {summaryShareaccount?.labelArs && (
                                            <Row className="align-items-start">
                                                <p className={`data-desc ${!isDesktop ? "mt-0" : ""}`}>
                                                    {summaryShareaccount.labelArs}
                                                </p>
                                            </Row>
                                        )}
                                        {summaryShareaccount?.labelUsd && (
                                            <Row className="align-items-start pb-3">
                                                <p className={`data-desc ${!isDesktop ? "mt-0" : ""}`}>
                                                    {summaryShareaccount.labelUsd}
                                                </p>
                                            </Row>
                                        )}
                                        <Row className="align-items-start">
                                            <FieldLabel
                                                idField="1"
                                                labelKey={`${FORM_ID}.summary.shareaccount.label`}
                                                notMarginY
                                                bold={false}
                                            />
                                        </Row>
                                        <Row className="align-items-start">
                                            <p className={`data-desc ${!isDesktop ? "mt-0" : ""}`}>
                                                {numCuotapartista}
                                            </p>
                                        </Row>
                                    </Col>
                                </Container>
                            </Col>
                        )}
                    </Col>
                </Container>
                <div
                    className={classNames("pt-4 d-flex gap-3", {
                        "justify-content-center mb-4 ": isDesktop,
                        "flex-column px-3 my-4": !isDesktop,
                    })}>
                    {status === "FINISHED" ? (
                        <Button
                            type="button"
                            bsStyle="primary"
                            onClick={() => dispatch(push("/fundcorpOperations/subscribe"))}
                            label="global.continue"
                            block={!isDesktop}
                            className={classNames(
                                "m-0",
                                { "w-100 max-width-20rem": isDesktop },
                                { "my-4": !isDesktop },
                            )}
                        />
                    ) : (
                        <Button
                            type="button"
                            bsStyle="primary"
                            onClick={() => dispatch(push("/fundcorpOperations"))}
                            label="fund.subscribe.ticket.goToFundTrade"
                            block={!isDesktop}
                            className={classNames(
                                "m-0",
                                { "w-100 max-width-20rem": isDesktop },
                                { "my-4": !isDesktop },
                            )}
                        />
                    )}
                </div>
            </main>
        );
    };

    return (
        <>
            <div className="admin-detail-head px-0">{renderHead()}</div>
            <PageLoading loading={fetching}>
                <MainContainer>{!fetching && renderContent()}</MainContainer>
            </PageLoading>
        </>
    );
}

ShareAccountTicket.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool,
    fetching: bool,
    status: string,
    numCuotapartista: string,
    summaryShareaccount: shape({}).isRequired,
    investorProfile: shape({}).isRequired,
};

ShareAccountTicket.defaultProps = {
    isDesktop: true,
    fetching: false,
    status: "FAILED",
    numCuotapartista: "",
};

const mapStateToProps = (state) => ({
    summaryShareaccount: fundcorpSelectors.getSummaryShareAccount(state),
    status: fundcorpSelectors.getStatusRegisterShareAccount(state),
    numCuotapartista: fundcorpSelectors.getNumCuotapartista(state),
    fetching: fundcorpSelectors.getFetching(state),
    investorProfile: fundcorpSelectors.getInvestorProfile(state),
});

export default connect(mapStateToProps)(ShareAccountTicket);
