import React, { Component, Fragment } from "react";
import { routerActions } from "react-router-redux";

import { actions } from "reducers/bankSelector";
import { actions as formActions } from "reducers/form";
import * as i18nUtils from "util/i18n";

import BankSearchDetailedItem from "pages/forms/_components/_fields/_bankselector/bankSearch/DetailedItem";

const bankSearchOuterComponents = (BaseComponent) =>
    class extends Component {
        static displayName = "BankSearchOuterComponents";

        componentWillUnmount() {
            const { history, match, dispatch } = this.props;

            if (history.location.pathname.indexOf(match.url) === -1) {
                dispatch(actions.resetSelectedBanks());
            }
        }

        handleClick = () => {
            const { dispatch, match, field, form } = this.props;

            dispatch(routerActions.push(`${match.url}/bankSearch/${field.name}`, { shouldLoadForm: false }));
            dispatch(formActions.setData(form.values));
        };

        render() {
            const { selectedBank, formMode } = this.props;

            return (
                <Fragment>
                    <BaseComponent {...this.props} />
                    {formMode === "edit" && (
                        <Fragment>
                            {`${i18nUtils.get("forms.bankselector.searchText")} `}
                            <button type="button" className="btn btn-asLink" onClick={this.handleClick}>
                                {i18nUtils.get("forms.bankselector.searchLink")}
                            </button>
                            {selectedBank && <BankSearchDetailedItem {...selectedBank} />}
                        </Fragment>
                    )}
                </Fragment>
            );
        }
    };

export default bankSearchOuterComponents;
