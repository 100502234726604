import React, { Component } from "react";
import { string, number, bool, oneOfType, shape } from "prop-types";

import ListItemDesktopAccount from "pages/_components/listItem/widgetAccount/ListItemDesktopAccount";
import ListItemMobileAccount from "pages/_components/listItem/widgetAccount/ListItemMobileAccount";
import { resizableRoute } from "pages/_components/Resizable";

class ListItemAccount extends Component {
    static propTypes = {
        path: oneOfType([
            string,
            shape({
                pathname: string,
                search: string,
            }),
        ]).isRequired,
        name: string.isRequired,
        title: string.isRequired,
        reference: string.isRequired,
        currency: string.isRequired,
        numberOfuscado: string.isRequired,
        amount: number.isRequired,
        amountLabel: string,
        icon: string,
        realBalance: number.isRequired,
        expirationDate: string,
        expiredText: string,
        expirationText: string,
        isExpired: bool,
    };

    static defaultProps = {
        icon: "",
        amountLabel: "",
        expirationDate: "",
        expiredText: "",
        expirationText: "",
        isExpired: false,
    };

    render() {
        const { amount, realBalance } = this.props;

        const balanceDiffers = amount !== realBalance; // productType === "CC" && overdraftLine > 0;
        const { isDesktop, ...props } = { ...this.props, balanceDiffers };

        if (isDesktop) {
            return <ListItemDesktopAccount {...props} />;
        }

        return <ListItemMobileAccount {...props} />;
    }
}

export default resizableRoute(ListItemAccount);
