/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, node, func, bool, string, number } from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";
import { selectors } from "reducers/widgets";

import WidgetList from "pages/desktop/widgets/WidgetList";
import Button from "pages/_components/Button";
import MetrocorpWidgetItem from "pages/accounts/MetrocorpWidgetItem";
import WidgetHeader from "./_components/WidgetHeader";
import InfoWidget from "./_components/InfoWidget";

import * as i18nUtils from "util/i18n";

const WIDGET_NAME = "metrocorp";

class Metrocorp extends Component {
    static propTypes = {
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
        isEditable: bool,
        isFetching: bool.isRequired,
        dispatch: func.isRequired,
        errorMsg: string.isRequired,
        metrocorpLength: number.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        closeButton: null,
        isEditable: false,
        isDesktop: true,
    };

    render() {
        const { closeButton, errorMsg, metrocorpLength, number, draggableItemProps, dispatch, isEditable, isFetching, isDesktop } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetMetrocorp" };

        return (
            <>
                <WidgetList
                    item={MetrocorpWidgetItem}
                    keyExtractor={(item) => item.idProduct}
                    name={WIDGET_NAME}
                    close={closeButton}>
                    {(list) => (
                        <div
                            role={isEditable ? "button" : "none"}
                            className={!isDesktop ? "widget metrocorp-widget" : "metrocorp-widget"}
                            {...uiAutomationProp}
                            {...draggableItemProps}
                            aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                            <WidgetHeader
                                title={`${i18nUtils.get("metrocorp.title")} ${
                                    number ? i18nUtils.get("metrocorp.title.widget.secondPart", null, { number: ` ${number}` }) : ""
                                }`}
                                action={
                                    <>
                                        {!errorMsg && metrocorpLength && !isEditable ? (
                                            <Button
                                                onClick={() => {
                                                    dispatch(push("/metrocorp"));
                                                }}
                                                bsStyle="outline"
                                                label="menu.seemore.label"
                                                type="button"
                                                image="images/arrow-right-blue.svg"
                                            />
                                        ) : undefined}
                                    </>
                                }
                            />
                            <div className="widget__content">
                                {!errorMsg && metrocorpLength && !isFetching ? <InfoWidget text="metrocorp.title.widget.message" /> : undefined}
                                {list}
                            </div>
                        </div>
                    )}
                </WidgetList>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        errorMsg,
        data: { client, metrocorp },
        isFetching,
    } = selectors.getWidget(state, WIDGET_NAME);

    return {
        isFetching,
        errorMsg,
        metrocorpLength: metrocorp.length,
        number: client?.cuentas?.ctaDep[0]?.numeroComitente,
    };
};

export default connect(mapStateToProps)(Metrocorp);
