import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { bool, func, string, oneOfType, element, arrayOf, shape } from "prop-types";
import { goBack } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as depositActions, selectors as depositSelectors } from "reducers/deposits";
import { actions as productsActions } from "reducers/products";
import Container from "pages/_components/Container";
import InputSetProductAlias from "pages/accounts/_components/InputSetProductAlias";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";

import * as i18nUtils from "util/i18n";

class SetProductAlias extends Component {
    static propTypes = {
        selectedAccount: shape({}).isRequired,
        selectedDeposit: shape({}).isRequired,
        hidePlaceholder: bool,
        placeholder: string,
        onSave: func.isRequired,
        value: string.isRequired,
        children: oneOfType([element, arrayOf(element)]).isRequired,
        favorite: bool,
        dispatch: func.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        hidePlaceholder: true,
        favorite: null,
        placeholder: "",
        isDesktop: true,
    };

    constructor(props) {
        super(props);
        this.titleRef = React.createRef();
    }

    componentDidMount() {
        const { isDesktop, match } = this.props;
        const { params: {id}, url } = match; 

        if (this.titleRef.current) {
            this.titleRef.current.focus();
        }

        if (url.includes("account")) {
            accountsActions.readAccount(id)
        }

        if (url.includes("deposit")) {
            depositActions.readDeposit(id)
        }

        if (isDesktop) {
            this.handleBack();
        }
    }

    handleEdit = () => {
        const { value } = this.props;

        this.setState({ value });
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    handleKeyDown = (event) => {
        const { value } = this.state;
        const { onSave } = this.props;

        if (event.key === "Enter" && onSave) {
            this.onSave();

            onSave(value);
        } else if (event.key === "Escape") {
            this.handleBack();
        }
    };

    handleSubmit = (newAlias) => {
        const { dispatch, ...rest } = this.props;
        dispatch(productsActions.changeProductAlias(newAlias, rest.match.params.id, true));
    };

    centerContentMobile = () => {
        const { ...rest } = this.props;
        return (
            <div>
                <h1 className="m-0">
                    {i18nUtils.get(
                        `products.alias.${
                            rest.match.path.includes("account") ? "account" : "deposit"
                        }.edit.title.mobile`,
                    )}
                </h1>
            </div>
        );
    };

    render() {
        const { selectedAccount, selectedDeposit, match } = this.props;
        const { url } = match;

        return (
            <Fragment>
                <div className="height-40">
                    <Head
                        onBack={this.handleBack}
                        headerClassName="blue-main-header-mobile"
                        centerElement={this.centerContentMobile}
                        emptyRightOption
                    />
                </div>
                <div className="mt-6 mr-2 ml-2">
                    <MainContainer shouldHideOnLoad className="main-container">
                        <div className="above-the-fold">
                            <Container fluid className="flex-grow align-items-center container--layout">
                                <div className="w-100 margin-top-safe-area mx-2">
                                    <InputSetProductAlias
                                        onSave={this.handleSubmit}
                                        defaultValue={
                                            url.includes("account") ?
                                                selectedAccount?.productAlias :
                                                url.includes("deposit") ?
                                                    selectedDeposit?.productAlias : ""
                                        }
                                    />
                                </div>
                            </Container>
                        </div>
                    </MainContainer>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    selectedAccount: accountsSelectors.getSelectedAccount(state),
    selectedDeposit: depositSelectors.getSelectedDeposit(state),
});

export default connect(mapStateToProps)(SetProductAlias);
