import { selectors } from "reducers/token";
import * as i18n from "util/i18n";
import { isMobileNative } from "util/device";
import { store } from "../store";

const isTokenActive = () => {
    if (isMobileNative) {
        const tokenActive = selectors.getIsTokenActiveInThisDevice(store.getState());
        return tokenActive;
    }
    return false;
};

export const isActiveInOtherDevice = () => {
    let isActive = false;
    const isTokenActiveInDevice = selectors.getIsTokenActiveInThisDevice(store.getState());
    const userHasActiveToken = selectors.userHasActiveToken(store.getState());
    if (isMobileNative) {
        isActive = userHasActiveToken && !isTokenActiveInDevice;
    }
    return isActive;
};

export const getResponseMessage = (response) => {
    const tokenMessage = response?.data?.data?.otp && i18n.get("token.otp.invalid");
    const noField = response?.data?.data?.NO_FIELD;
    const message = noField || tokenMessage || response.data.message;
    return message;
};

export default isTokenActive;
