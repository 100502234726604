/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { any, arrayOf, bool, func, number, objectOf, oneOfType, shape, string } from "prop-types";

import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import Heading from "pages/_components/Heading";

import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import AdministrationFormConfirmation from "pages/administration/_components/AdministrationFormConfirmation";
import Notification from "pages/_components/Notification";

class SignaturesSchemeDelete extends Component {
    static propTypes = {
        actions: shape({
            deleteSignaturesSchemeRequest: func,
        }).isRequired,
        activeEnvironment: shape({
            administrationScheme: string,
        }).isRequired,
        fetching: bool.isRequired,
        functionalGroups: arrayOf(string),
        masterCurrency: string,
        match: shape({
            params: shape({
                idSignature: string,
            }),
        }),
        credentialGroups: arrayOf(shape({ idCredentialGroup: string, credentials: arrayOf(string) })).isRequired,
        signature: shape({
            capList: arrayOf(objectOf(any)),
            groupsMap: oneOfType([
                shape({
                    A: number,
                }),
                shape(
                    configUtils.getArray("administration.signatures.signatureLevels").reduce((res, signLevel) => {
                        const result = res;
                        result[signLevel] = number;

                        return result;
                    }, {}),
                ),
            ]),
            idSignature: number,
        }),
        formActions: objectOf(func).isRequired,
        idActivity: string,
        idTransaction: string,
        routerActions: func.isRequired,
        environmentProducts: arrayOf(
            shape({
                idProduct: string,
            }),
        ).isRequired,
    };

    static defaultProps = {
        functionalGroups: [],
        masterCurrency: configUtils.get("core.masterCurrency"),
        match: null,
        signature: null,
        idActivity: null,
        idTransaction: null,
    };

    componentDidMount() {
        const {
            actions,
            match: {
                params: { idSignature },
            },
        } = this.props;

        actions.deleteSignaturesSchemePreRequest(idSignature);
    }

    handleSubmit = (credentials, formikBag) => {
        const { actions, formActions, idTransaction, idActivity, functionalGroups, signature } = this.props;

        if (!idTransaction) {
            actions.deleteSignaturesSchemeRequest(
                {
                    credentials,
                    ...{ ...signature, functionalGroups },
                },
                formikBag,
            );
        } else {
            const paramsSign = { idForm: null, idActivity, idTransaction };
            formActions.signTransaction({ ...paramsSign, credentials, formikBag });
        }
    };

    handleBack = () => {
        const {
            activeEnvironment: { administrationScheme },
            routerActions,
            signature,
        } = this.props;

        routerActions.push(`/administration/${administrationScheme}/signaturesSchemes/detail/${signature.idSignature}`);
    };

    signersCount = (groupsMap) => {
        const {
            activeEnvironment: { administrationScheme },
        } = this.props;

        return administrationScheme === "medium"
            ? `${groupsMap.A}`
            : Object.keys(groupsMap)
                  .sort()
                  .reduce((result, key) => `${result}${`${key}`.repeat(groupsMap[key])}`, "");
    };

    topAmount = (signature, masterCurrency) => (
        <div className="data-wrapper-flex align-items-baseline mb-1 text-uppercase">
            <span className="font-light f-size-4 font-weight-600 mr-2">
                {i18nUtils.get("administration.signatures.delete.topAmount.label")}
            </span>
            <div>
                {signature.capList[0] ? (
                    <FormattedAmount
                        className="data-amount"
                        currency={masterCurrency}
                        frequency={i18nUtils.get("administration.signatures.delete.advanced.topAmountPerPeriod", null, {
                            period: i18nUtils.get(
                                `administration.signatures.transactions.capFrequency.${signature.capList[0].frequency}`,
                            ),
                        })}
                        quantity={signature.capList[0].maximum}
                        notBold
                    />
                ) : (
                    <div>-</div>
                )}
            </div>
        </div>
    );

    renderContent = (signature) => {
        const {
            activeEnvironment: { administrationScheme },
            masterCurrency,
            functionalGroups,
            environmentProducts,
        } = this.props;

        const groupsMap = signature ? signature.groupsMap : null;
        const signatureProducts =
            environmentProducts &&
            environmentProducts.filter(({ idProduct }) => signature.products.includes(idProduct));

        return (
            <>
                <Heading.DataGroup
                    containerClassName="data-wrapper-flex align-items-baseline mb-1"
                    labelClassName="font-light font-weight-600 mr-2"
                    label={
                        administrationScheme === "medium"
                            ? `administration.signatures.modify.${administrationScheme}.confirm.signersCount`
                            : `administration.signatures.delete.${administrationScheme}.confirm.Reference`
                    }
                    data={groupsMap ? this.signersCount(groupsMap) : "-"}
                />

                <Heading.DataGroup
                    containerClassName="data-wrapper-flex align-items-baseline mb-1"
                    labelClassName="font-light font-weight-600 mr-2"
                    label="administration.signatures.list.liberating"
                    data={signature?.signatureDispatch ? i18nUtils.get("global.yes") : i18nUtils.get("global.no")}
                />

                <Heading.DataGroup
                    containerClassName="data-wrapper-flex align-items-baseline mb-1"
                    labelClassName="font-light font-weight-600 mr-2"
                    label={`administration.signatures.delete.${administrationScheme}.confirm.transactionType`}
                    data={i18nUtils.get(`administration.signatures.delete.signatureType.${signature?.signatureType}`)}
                />

                {/* monto tope */}

                {administrationScheme === "advanced" && signature ? this.topAmount(signature, masterCurrency) : <></>}

                {/* grupo funcional */}
                <Heading.DataGroup
                    containerClassName="data-wrapper-flex align-items-baseline mb-1 flex-wrap"
                    labelClassName="font-light font-weight-600 mr-2"
                    label="administration.signatures.functionalGroups.label"
                    data={
                        functionalGroups.length > 0 ? (
                            functionalGroups.map((functionalGroup) => (
                                <div key={functionalGroup}>
                                    {i18nUtils.get(`administration.signatures.functionalGroups.${functionalGroup}`)}
                                </div>
                            ))
                        ) : (
                            <div>-</div>
                        )
                    }
                />

                <Heading.DataGroup
                    containerClassName="data-wrapper-flex align-items-baseline mb-1 flex-wrap"
                    labelClassName="font-light font-weight-600 mr-2"
                    label="administration.signatures.detail.signatureType.productList"
                    data={
                        signatureProducts && signatureProducts.length > 0 ? (
                            signatureProducts.map((product) => <div key={product.idProduct}>{product.label}</div>)
                        ) : (
                            <div>-</div>
                        )
                    }
                />
            </>
        );
    };

    render() {
        const {
            credentialGroups,
            activeEnvironment: { administrationScheme },
            fetching,
            signature,
        } = this.props;

        return (
            <>
                <Notification scopeToShow="administrationDeleteSignatureScheme" />
                <div className="admin-detail-head admin-detail-head-section px-0 rigth-position-desktop">
                    <Head
                        onBack={this.handleBack}
                        closeLinkTo={`/administration/${administrationScheme}/signaturesSchemes`}
                    />
                    <div className="mb-4 view-title">
                        <h1>
                            {i18nUtils.get(`administration.signatures.delete.${administrationScheme}.confirm.title`)}
                        </h1>
                    </div>
                </div>
                <MainContainer showLoader={fetching} viewPageClassName="confirmation__container">
                    <div className="confirmation__form confirmation__box-transparent px-0">
                        <Container className="container-white mb-0" gridClassName="px-0">
                            <div>
                                <I18n
                                    id="administration.signatures.delete.advanced.confirm.credenctial"
                                    component="h1"
                                />
                            </div>
                            <Col className="col-12" md={9} lg={12}>
                                <div className="display-inline-flex">{this.renderContent(signature)}</div>
                            </Col>
                        </Container>

                        <AdministrationFormConfirmation
                            credentialGroups={credentialGroups}
                            onSubmit={this.handleSubmit}
                            MDcolumnWidth={12}
                            LGcolumnWidth={12}
                            XLcolumnWidth={12}
                            formContainerClassName="confirmation__container"
                            containerClassName="confirmation__form confirmation__box pb-0 mb-3"
                            buttonLabel="global.confirm"
                            buttonImage="images/arrowRight.svg"
                            buttonImageClass="mr-2"
                        />
                    </div>
                </MainContainer>
            </>
        );
    }
}

export default SignaturesSchemeDelete;
