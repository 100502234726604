export const directions = {
    BACK: "back",
    FRONT: "front",
};

export const start = (direction, resolve, reject) => window.camera.start(direction, resolve, reject);

export const stop = () => window.camera.stop();

export const takePicture = (resolve, reject) => window.camera.takePicture(resolve, reject);
