/* eslint-disable import/no-unresolved */
import React, { Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { string, bool, shape, func } from "prop-types";
import { push } from "react-router-redux";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as loginActions } from "reducers/login";

import GeneralMsg from "pages/_components/GeneralMsg";
import Image from "pages/_components/Image";
import Navbar from "react-bootstrap/lib/Navbar";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import { ANDROID_DEVICE, IOS_DEVICE, IPHONE_DEVICE, getDisplay, getMobileOS } from "util/device";

import * as i18n from "util/i18n";
import * as configUtil from "util/config";

class Error extends React.Component {
    isDesktop = getDisplay() === "desktop";

    static propTypes = {
        dispatch: func.isRequired,
        hasActiveSession: bool.isRequired,
        isMobile: bool.isRequired,
        location: shape({
            code: string,
            message: string,
        }).isRequired,
    };

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { code } = location;
        if (code === "COR041E" || code === "COR029E") {
            dispatch(
                loginActions.goBackToLoginAndShowMessage(
                    i18n.get("administration.restrictions.unavailableEnvironment"),
                ),
            );
        }
        window.common.hideSplashScreen();
    }

    getErrorTitle = (code) => {
        switch (code) {
            case "API528E":
                return i18n.get("error.connection.service.title");
            case "API041E":
                return i18n.get("error.mobile.minSupportedVersion.title");
            case "API529E":
                return null;
            default: {
                let errorTitle = i18n.get("error.title");
                if (!errorTitle || errorTitle === "*error.title*") {
                    errorTitle = "Ha ocurrido un error";
                }
                return errorTitle;
            }
        }
    };

    getImagePath = (code) => {
        switch (code) {
            case "API041E":
                return "images/update.svg";
            default:
                return "images/iconRedError.svg";
        }
    };

    getLabelButton = (code) => {
        switch (code) {
            case "API041E":
                return i18n.get("error.mobile.update");
            case "API528E":
                return i18n.get("error.goTo.accounts");
            default:
                return i18n.get("error.back");
        }
    };

    getContent = () => {
        const { hasActiveSession, location } = this.props;
        const { message } = location;
        const { code } = location;

        if (!code) {
            return <Redirect to="/desktop" />;
        }

        const errorTitle = this.getErrorTitle(code);

        let errorMessage = message || i18n.get(`returnCode.${code}`);
        if (!errorMessage || errorMessage === `*returnCode.${code}*`) {
            errorMessage =
                "Estimado cliente: ha ocurrido un problema procesando su solicitud. Por favor espere unos minutos y vuelva a intentarlo más tarde.";
        }
        let errorBack = this.getLabelButton(code);
        if (!errorBack || errorBack === "error.back") {
            errorBack = "Volver";
        }

        if (code === "COR047E") {
            errorMessage = i18n.get(`returnCode.COR047E`);
        }

        const btnHandlerOnClick = () => {
            const { dispatch } = this.props;
            if (code === "API041E" && !this.isDesktop) {
                const deviceType = getMobileOS(getDisplay());
                if ([IOS_DEVICE, IPHONE_DEVICE].includes(deviceType)) {
                    window.cordova.InAppBrowser.open(
                        configUtil.get("mobile.URL.AppStore"),
                        "_system",
                        "hidden=no,location=no",
                    );
                } else if (deviceType === ANDROID_DEVICE) {
                    window.open(configUtil.get("mobile.URL.PlayStore"), "_blank");
                }
            } else if (hasActiveSession && code !== "COR047E") {
                dispatch(push(code !== "API528E" ? "/desktop" : "/accounts"));
            } else {
                dispatch(push("/"));
            }
        };

        const button = code !== "COR086E" && (
            <Button className="btn btn-primary btn-block" defaultLabelText={errorBack} onClick={btnHandlerOnClick} />
        );

        return (
            <MainContainer>
                <div className="error-container">
                    <GeneralMsg
                        title={errorTitle}
                        description={errorMessage}
                        imagePath={this.getImagePath(code)}
                        callToAction={button}
                        addContainerWhite
                        isDesktop={this.isDesktop}
                    />
                </div>
            </MainContainer>
        );
    };

    getMobileLayout = () => (
        <ErrorBoundary>
            <div className="view-wrapper theme-auth">{this.getContent()}</div>
        </ErrorBoundary>
    );

    getDesktopLayout = () => (
        <ErrorBoundary>
            <div className="app theme-auth enrollment-layout">
                <header className="app-header">
                    <Navbar collapseOnSelect fluid>
                        <Navbar.Header>
                            <div className="container-navbar">
                                <Navbar.Brand>
                                    <Link className="navbar-brand" to="/desktop">
                                        <Image src="images/logoCompany.svg" className="header__logo" />
                                    </Link>
                                </Navbar.Brand>
                            </div>
                        </Navbar.Header>
                    </Navbar>
                </header>

                <div className="app-page">
                    <div className="app-content align-items-center d-flex">
                        <div className="view-wrapper theme-auth h-auto">{this.getContent()}</div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );

    render() {
        const { isMobile } = this.props;
        return isMobile ? (
            <Fragment>{this.getMobileLayout()}</Fragment>
        ) : (
            <Fragment>{this.getDesktopLayout()}</Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    hasActiveSession: sessionSelectors.isLoggedIn(state),
});

export default connect(mapStateToProps)(Error);
