import React, { Component } from "react";
import { string } from "prop-types";

import * as creditCardsUtils from "util/creditCards";

import Image from "pages/_components/Image";

class ProductLabelIcon extends Component {
    static propTypes = {
        number: string,
    };

    static defaultProps = {
        number: "",
    };

    render() {
        const { number } = this.props;

        if (!number) {
            return null;
        }

        const brand = creditCardsUtils.detectBrand(number);

        return (
            <div className="product-label-icon">
                <Image src={`images/${brand}.svg`} className="svg-icon" />
                <span className="visually-hidden">{brand}</span>
            </div>
        );
    }
}

export default ProductLabelIcon;
