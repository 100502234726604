/* eslint-disable import/no-unresolved */
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { func, arrayOf, oneOfType, bool, number, string, shape } from "prop-types";

import * as i18nUtils from "util/i18n";
import { actions as notificationActions } from "reducers/notification";
import { SALARY_PAYMENT_ID_FORM } from "constants.js";

import FormattedDate from "pages/_components/FormattedDate";
import { resizableRoute } from "pages/_components/Resizable";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class PendingTransactionsListItem extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        idForm: string.isRequired,
        activityName: string.isRequired,
        idTransaction: string.isRequired,
        creationDateTime: string.isRequired,
        currency: arrayOf(oneOfType([number, string])),
        quantity: number,
        onlyDrafts: bool.isRequired,
        idTransactionStatus: string.isRequired,
        valueDateTime: string,
        data: shape({}).isRequired,
    };

    static defaultProps = {
        valueDateTime: null,
        quantity: null,
        currency: null,
    };

    renderModifiedDate = () => {
        const { onlyDrafts, idTransactionStatus, valueDateTime, data } = this.props;
        const { scheduler } = data;

        if (onlyDrafts) {
            return null;
        }

        if (idTransactionStatus === "DRAFT") {
            return "-";
        }

        return (
            <Fragment>
                <span className="text-label mr-2">
                    {i18nUtils.get(`transactions.list.item.${idTransactionStatus}`)}
                </span>{" "}
                <span className="data-label">
                    <FormattedDate date={scheduler ? scheduler.valueDate : valueDateTime} />
                </span>
            </Fragment>
        );
    };

    onMobileItemCLick = (event) => {
        const { dispatch, idForm } = this.props;

        if (idForm === SALARY_PAYMENT_ID_FORM) {
            event.preventDefault();
            dispatch(
                notificationActions.showNotification(i18nUtils.get("massive.payments.mobile.disable"), "error", [
                    "desktop",
                ]),
            );
        }
    };

    renderRightContent = (data) => {
        const data1 = {
            amount: {
                quantity: "",
                currency: "",
            },
        };

        const amount = data?.amount?.quantity ? data.amount.quantity : data1.amount?.quantity;
        const currency = data?.amount?.currency ? i18nUtils.get(`currency.label.${data.amount.currency}`) : "";
        return (
            <div className="card__component-item-currency d-flex align-items-center">
                <span className="pr-1">{currency}</span>
                <span>
                    {amount &&
                        amount?.toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                </span>
            </div>
        );
    };

    render() {
        const { activityName, idTransaction, idTransactionStatus, creationDateTime, data, isEditable } = this.props;

        return (
            <CardContainer
                renderAs={Link}
                to={!isEditable ? `/transaction/${idTransaction}` : "/desktop"}
                className="widget__card-transactions">
                <Card
                    icon={`images/transfer-${idTransactionStatus}.svg`}
                    iconClass="card__pending-transaction-icon"
                    rightArrow
                    title={
                        <div className="card__pending-transaction-data-title">
                            <div className="d-flex align-items-center">
                                <span className="card__pending-transaction-data-creation">
                                    {i18nUtils.get("transactions.list.header.creationDate")}
                                </span>
                                <FormattedDate showTime date={creationDateTime} />
                            </div>
                        </div>
                    }
                    bottom={
                        <div className="card__pending-transaction-data-bottom">
                            <span>{activityName}</span>
                        </div>
                    }
                    rightContent={this.renderRightContent(data)}
                />
            </CardContainer>
        );
    }
}

export default resizableRoute(PendingTransactionsListItem);
