import React, { Component, Fragment } from "react";
import Route from "react-router-dom/Route";
import withRouter from "react-router-dom/withRouter";
import PageTransition from "react-router-page-transition";
import { func, shape, string, node, bool } from "prop-types";
import { getTransition } from "util/transition";

import ErrorBoundary from "pages/_components/ErrorBoundary";
import { resizableRoute } from "pages/_components/Resizable";
import { isIOS } from "react-device-detect";

class ExternalLayout extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        history: shape({}).isRequired,
        fetching: bool.isRequired,
        location: shape({}).isRequired,
        transition: string,
        component: node.isRequired,
        isDesktop: bool,
        isMobile: bool,
        isMobileNative: bool,
        showLogo: bool,
    };

    static defaultProps = {
        isDesktop: false,
        isMobile: false,
        isMobileNative: false,
        transition: "",
        showLogo: true,
    };

    constructor(props) {
        super(props);
        const { showLogo } = props;
        this.state = { transition: "", showLogoState: showLogo, classNames: "" };
    }

    componentDidMount() {
        const { isMobileNative, location } = this.props;

        if (isMobileNative) {
            this.setState({
                classNames: "external-layout-native",
            });
        }

        if (
            location.pathname === "/termsAndConditions" ||
            location.pathname === "/support" ||
            location.pathname === "/regionSelection" ||
            location.pathname === "/privacyPolicy" ||
            location.pathname === "/loginStep4"
        ) {
            this.setState({
                showLogoState: false,
            });
        }
    }

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = this.props;

        if (location.pathname !== nextProps.location.pathname) {
            this.setState({
                showLogoState: !(
                    nextProps.location.pathname === "/termsAndConditions" ||
                    nextProps.location.pathname === "/support" ||
                    nextProps.location.pathname === "/privacyPolicy" ||
                    nextProps.location.pathname === "/regionSelection" ||
                    nextProps.location.pathname === "/loginStep4"
                ),
                transition: nextProps.transition || getTransition(this.props, nextProps),
            });
        }
    }

    getMobileLayout = (matchProps) => {
        const { component: ReceivedComponent, isMobile, isDesktop, isMobileNative } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        const { transition, classNames } = this.state;

        const curHr = new Date().getHours();
        let backgroundImage = "day-background";
        if (curHr >= 18 || curHr < 8) {
            backgroundImage = "night-background";
        }

        return (
            <div className={`${transition} login-background ${backgroundImage} ${classNames}`}>
                <PageTransition timeout={transition ? 600 : 0}>
                    <div className={isIOS ? "view-wrapper transition-item px-3" : "view-wrapper transition-item"}>
                        <ErrorBoundary>
                            <ReceivedComponent {...matchProps} {...extras} />
                        </ErrorBoundary>
                    </div>
                </PageTransition>
            </div>
        );
    };

    getDesktopLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isMobileNative,
            location: { pathname },
        } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        const { transition, showLogoState } = this.state;
        const propsGrid = { showLogoState, pathname };

        const curHr = new Date().getHours();
        let backgroundImage = "day-background";
        if (curHr >= 18 || curHr < 8) {
            backgroundImage = "night-background";
        }

        return (
            <div className={`${transition} login-background ${backgroundImage}`}>
                <PageTransition
                    timeout={transition ? 600 : 0}
                    style={{
                        background: "linear-gradient(transparent, transparent 67.5%, black)",
                    }}>
                    <div className="app transition-item">
                        <ErrorBoundary>
                            <section className="container--layout align-items-center">
                                <ReceivedComponent {...matchProps} {...extras} propsGrid={propsGrid} />
                            </section>
                        </ErrorBoundary>
                    </div>
                </PageTransition>
            </div>
        );
    };

    render() {
        const { component: ReceivedComponent, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={(matchProps) => {
                    const { isMobile } = this.props;

                    if (isMobile) {
                        return <Fragment>{this.getMobileLayout(matchProps)}</Fragment>;
                    }

                    return <Fragment>{this.getDesktopLayout(matchProps)}</Fragment>;
                }}
            />
        );
    }
}

export default withRouter(resizableRoute(ExternalLayout));
