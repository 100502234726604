import React, { Component, Fragment } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import Col from "react-bootstrap/lib/Col";
import { Route, Redirect } from "react-router-dom";

import * as i18nUtils from "util/i18n";
import { actions, selectors } from "reducers/bankSelector";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";

import Head from "pages/_components/Head";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import BankSearchForm from "pages/forms/_components/_fields/_bankselector/bankSearch/Form";
import BankSearchList from "pages/forms/_components/_fields/_bankselector/bankSearch/List";
import { resizableRoute } from "pages/_components/Resizable";
import Notification from "pages/_components/Notification";

class BankSearch extends Component {
    /* eslint-disable-next-line react/destructuring-assignment */
    boundActionCreators = bindActionCreators(actions, this.props.dispatch);

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.goBack());
    };

    handleCloseClick = () => {
        const { dispatch } = this.props;

        dispatch(actions.resetSelectedBanks());
    };

    handleSelect = (bank) => {
        const { dispatch, idForm, idField } = this.props;
        const { type, code } = bank;

        dispatch(routerActions.push(`/form/${idForm}`, { shouldLoadForm: false }));
        dispatch(formActions.setData({ [idField]: { type, code, bank: {} } }));
        dispatch(actions.bankSelected(bank, idField));
    };

    renderForm = (routeProps) => {
        const { isDesktop, data } = this.props;

        return <BankSearchForm isDesktop={isDesktop} {...routeProps} {...data} {...this.boundActionCreators} />;
    };

    renderList = () => {
        const { searchResults } = this.props;

        return <BankSearchList {...searchResults} {...this.boundActionCreators} onSelect={this.handleSelect} />;
    };

    render() {
        const { match, location, isDesktop, fetching, formNameMap, lang, searchResults } = this.props;
        const isResultsVisible = location.pathname === `${match.url}/results`;
        const subTitle =
            isResultsVisible && !isDesktop
                ? i18nUtils.get("forms.bankselector.searchResults")
                : i18nUtils.get("forms.bankselector.searchBank");

        return (
            <Fragment>
                <MainContainer showLoader={fetching}>
                    <Notification scopeToShow="form" />
                    <Head
                        titleText={formNameMap[lang]}
                        closeLinkTo="/desktop"
                        onBack={this.handleBack}
                        onCloseClick={this.handleCloseClick}
                        hideMenu
                    />
                    <div className="above-the-fold">
                        <Container className="container--layout align-items-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">{subTitle}</p>
                            </Col>
                        </Container>
                        <Route path={match.url} render={this.renderForm} exact={!isDesktop} />
                        <Route path={`${match.url}/results`} component={this.renderList} />
                        {isResultsVisible && !searchResults.items.length && <Redirect to={match.url} />}
                    </div>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, { match }) => ({
    ...formSelectors.getMetadata(state),
    ...formSelectors.getField(state, match.params.fieldId),
    fetching: formSelectors.getFetching(state),
    lang: i18nSelectors.getLang(state),
    searchResults: {
        items: selectors.getBanks(state),
        hasMoreResults: selectors.getHasMoreResults(state),
    },
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
)(BankSearch);
