import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { Field } from "formik";
import { arrayOf, bool, func, objectOf, oneOfType, shape, string } from "prop-types";

import FieldError from "pages/_components/fields/FieldError";
import StepperField from "pages/_components/fields/StepperField";

class StepperFieldGroup extends Component {
    static propTypes = {
        field: shape({
            name: string,
        }).isRequired,
        form: shape({
            errors: objectOf(oneOfType([string, objectOf(string)])),
            touched: objectOf(oneOfType([arrayOf(bool), bool, objectOf(bool)])),
        }).isRequired,
        handleChange: func,
        hideLabel: bool,
        idForm: string.isRequired,
        max: string.isRequired,
        min: string.isRequired,
        options: arrayOf(string).isRequired,
        showFirstErrorWhenEquals: bool,
        fieldClassName: string.isRequired,
    };

    static defaultProps = {
        handleChange: null,
        hideLabel: false,
        showFirstErrorWhenEquals: false,
    };

    sameErrorForAll = (errors, field, optionsLength) =>
        optionsLength > 1 &&
        Object.keys(errors[field.name]).length === optionsLength &&
        Object.values(errors[field.name]).every((error) => Object.values(errors[field.name])[0] === error);

    renderErrors = (errors, field, options, showFirstErrorWhenEquals) => {
        if (showFirstErrorWhenEquals && this.sameErrorForAll(errors, field, options.length)) {
            return <FieldError error={Object.values(errors[field.name])[0]} />;
        }

        return Object.keys(errors[field.name]).map((error) => (
            <FieldError error={`${error} - ${errors[field.name][error]}`} key={error} />
        ));
    };

    render() {
        const {
            field,
            form,
            handleChange,
            hideLabel,
            idForm,
            max,
            min,
            options,
            showFirstErrorWhenEquals,
            fieldClassName,
        } = this.props;
        const { touched, errors } = form;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <Fragment>
                <div className="stepper-list">
                    {options.map((option) => (
                        <Field
                            className={classNames(`${fieldClassName}`, {
                                "has-error": hasError && Object.keys(errors[field.name]).includes(option),
                            })}
                            component={StepperField}
                            handleChange={handleChange}
                            hideLabel={hideLabel}
                            idForm={idForm}
                            key={option}
                            name={`${field.name}.${option}`}
                            max={max}
                            min={min}
                        />
                    ))}
                </div>
                <div
                    className={classNames({
                        "has-error": hasError,
                    })}>
                    {hasError && this.renderErrors(errors, field, options, showFirstErrorWhenEquals)}
                </div>
            </Fragment>
        );
    }
}

export default StepperFieldGroup;
