import React, { Component } from "react";
import { string, arrayOf, shape, bool } from "prop-types";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Row from "react-bootstrap/lib/Row";
import classNames from "classnames";

const Title = ({ i18n }) => (
    <span className="data-label">
        <I18n id={i18n} />
    </span>
);

Title.propTypes = { i18n: string.isRequired };

const Line = ({
    userFirstName,
    userLastName,
    creationDateTime,
    userFullName,
    signatureLevel,
    isDesktop,
    showSignatureLevel,
}) => (
    <div className={classNames("d-flex", { "ml-auto": isDesktop })}>
        <span className={classNames("data-desc m-0 ", { "justify-content-end": isDesktop })}>
            {userFullName || `${userFirstName}, ${userLastName}`}
            {signatureLevel && showSignatureLevel && ` (${signatureLevel})`}
            <span className="add-dash" />
            {creationDateTime && <FormattedDate showTime date={creationDateTime} withSpan />}
        </span>
    </div>
);

Line.propTypes = {
    userFirstName: string,
    userLastName: string,
    creationDateTime: string,
    userFullName: string,
    signatureLevel: string,
    isDesktop: bool.isRequired,
    showSignatureLevel: bool,
};

Line.defaultProps = {
    userFirstName: null,
    userLastName: null,
    creationDateTime: null,
    userFullName: null,
    signatureLevel: null,
    showSignatureLevel: true,
};

export default class UserSignatures extends Component {
    static propTypes = {
        list: arrayOf(shape({})).isRequired,
        title: string.isRequired,
        isDesktop: bool.isRequired,
        boldTitle: bool,
        showSignatureLevel: bool,
    };

    static defaultProps = {
        boldTitle: false,
        showSignatureLevel: true,
    };

    static Line = Line;

    static Title = Title;

    render() {
        const { list, title, isDesktop, boldTitle, showSignatureLevel } = this.props;
        return (
            <Row
                className={classNames(
                    { "d-block": !isDesktop },
                    { "space-between flex-no-wrap align-items-start": isDesktop },
                )}>
                <FieldLabel labelKey={title} bold={boldTitle} notMarginY />
                <div className="d-flex flex-column">
                    {list.map((signature) => (
                        <Line
                            key={`${signature.userFirstName}${signature.userLastName}${signature.userFullName}`}
                            isDesktop={isDesktop}
                            {...signature}
                            showSignatureLevel={showSignatureLevel}
                        />
                    ))}
                </div>
            </Row>
        );
    }
}
