// Action types
export const types = {
    CLEAN: "recoveryPassword/CLEAN",

    RECOVERY_PASS_BACK_TO_STEP_1: "recoveryPassword/BACK_TO_STEP_1",
    RECOVERY_PASS_PREVIEW_REQUEST: "recoveryPassword/RECOVERY_PASS_PREVIEW_REQUEST",
    RECOVERY_PASS_STEP1_REQUEST: "recoveryPassword/RECOVERY_PASS_STEP1_REQUEST",
    RECOVERY_PASS_STEP2_REQUEST: "recoveryPassword/RECOVERY_PASS_STEP2_REQUEST",
    RECOVERY_PASS_STEP3_REQUEST: "recoveryPassword/RECOVERY_PASS_STEP3_REQUEST",

    RECOVERY_USERPASS_BACK_TO_STEP_1: "recoveryPassword/BACK_TO_USERPASS_STEP_1",
    RECOVERY_USERPASS_PRE: "recoveryPassword/RECOVERY_USERPASS_PRE",
    RECOVERY_USERPASS_STEP0_REQUEST: "recoveryPassword/RECOVERY_USERPASS_STEP0_REQUEST",
    RECOVERY_USERPASS_STEP1_REQUEST: "recoveryPassword/RECOVERY_USERPASS_STEP1_REQUEST",
    RECOVERY_USERPASS_STEP2_REQUEST: "recoveryPassword/RECOVERY_USERPASS_STEP2_REQUEST",
    RECOVERY_USERPASS_STEP3_REQUEST: "recoveryPassword/RECOVERY_USERPASS_STEP3_REQUEST",

    RECOVERY_PASS_USERPASS_STEP1_REQUEST: "recoveryPassword/RECOVERY_PASS_USERPASS_STEP1_REQUEST",
    RECOVERY_PASS_USERPASS_STEP2_REQUEST: "recoveryPassword/RECOVERY_PASS_USERPASS_STEP2_REQUEST",
    RECOVERY_PASS_USERPASS_STEP3_REQUEST: "recoveryPassword/RECOVERY_PASS_USERPASS_STEP3_REQUEST",

    RECOVERY_PASS_PREVIEW_SUCCESS: "recoveryPassword/RECOVERY_PASS_PREVIEW_SUCCESS",
    RECOVERY_PASS_STEP1_SUCCESS: "recoveryPassword/RECOVERY_PASS_STEP1_SUCCESS",
    RECOVERY_PASS_STEP2_SUCCESS: "recoveryPassword/RECOVERY_PASS_STEP2_SUCCESS",

    RECOVERY_USERPASS_STEP0_SUCCESS: "recoveryPassword/RECOVERY_USERPASS_STEP0_SUCCESS",
    RECOVERY_USERPASS_STEP2_SUCCESS: "recoveryPassword/RECOVERY_USERPASS_STEP2_SUCCESS",

    RECOVERY_PASS_USERPASS_STEP1_SUCCESS: "recoveryPassword/RECOVERY_PASS_USERPASS_STEP1_SUCCESS",
    RECOVERY_PASS_USERPASS_STEP2_SUCCESS: "recoveryPassword/RECOVERY_PASS_USERPASS_STEP2_SUCCESS",

    RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA: "recoveryPassword/RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA",
    RECOVERY_PASS_RESET_CAPTCHA: "recoveryPassword/RECOVERY_PASS_RESET_CAPTCHA",

    SET_RECOVERY_PASS_USERPASS: "recoveryPassword/SET_RECOVERY_PASS_USERPASS",
    REMOVE_RECOVERY_PASS_USERPASS: "recoveryPassword/REMOVE_RECOVERY_PASS_USERPASS",
};

// Initial state
export const INITIAL_STATE = {
    resetCode: null,
    exchangeToken: null,
    showCaptcha: false,
    resetCaptcha: false,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.CLEAN_UP:
        case types.RECOVERY_PASS_BACK_TO_STEP_1:
            return INITIAL_STATE;
        case types.RECOVERY_PASS_FAILURE_REQUIRE_CAPTCHA:
            return { ...state, showCaptcha: true };
        case types.RECOVERY_PASS_RESET_CAPTCHA:
            return { ...state, resetCaptcha: action.resetCaptcha };
        case types.RECOVERY_PASS_PREVIEW_SUCCESS:
            return { ...state, exchangeToken: action.exchangeToken };
        case types.RECOVERY_PASS_STEP1_SUCCESS:
            return { ...state, exchangeToken: action.exchangeToken };
        case types.RECOVERY_PASS_STEP2_SUCCESS:
            return { ...state, exchangeToken: action.exchangeToken, resetCode: action.resetCode };
        case types.RECOVERY_USERPASS_PRE:
            return { ...state, document: action.document, documentType: action.documentType };
        case types.RECOVERY_USERPASS_STEP0_SUCCESS:
            return {
                ...state,
                userFirstName: action.userFirstName,
                userFullName: action.userFullName,
                userName: action.userName,
                lang: action.lang,
                userMaskedEmail: action.userMaskedEmail,
                userMaskedPhone: action.userMaskedPhone,
            };
        case types.RECOVERY_USERPASS_STEP2_SUCCESS:
            return { ...state, exchangeToken: action.exchangeToken, resetCode: action.resetCode };
        case types.SET_RECOVERY_PASS_USERPASS:
            return { ...state, isRecoveryPassAndUserpass: true };
        case types.REMOVE_RECOVERY_PASS_USERPASS:
            return { ...state, isRecoveryPassAndUserpass: false };
        case types.RECOVERY_PASS_USERPASS_STEP1_SUCCESS:
            return { ...state, exchangeToken: action.exchangeToken, resetCode: action.resetCode };
        case types.RECOVERY_PASS_USERPASS_STEP2_SUCCESS:
            return { ...state, exchangeToken: action.exchangeToken };
        default:
            return state;
    }
};

// Action creators
export const actions = {
    backToStep1: () => ({
        type: types.RECOVERY_PASS_BACK_TO_STEP_1,
    }),
    recoveryPassStep1: (sendChannel, userName, secondFactor, formikBag, exchangeToken) => ({
        type: types.RECOVERY_PASS_STEP1_REQUEST,
        sendChannel,
        userName,
        secondFactor,
        formikBag,
        exchangeToken,
    }),
    recoveryPassStep2: (code, formikBag, exchangeToken) => ({
        type: types.RECOVERY_PASS_STEP2_REQUEST,
        code,
        formikBag,
        exchangeToken,
    }),
    recoveryPassStep3: (newPassword, newPasswordConfirmation, exchangeToken, resetCode, formikBag) => ({
        type: types.RECOVERY_PASS_STEP3_REQUEST,
        newPassword,
        newPasswordConfirmation,
        exchangeToken,
        resetCode,
        formikBag,
    }),
    recoveryPassCleanUp: () => ({
        type: types.CLEAN,
    }),
    recoveryUserpassPre: (document, documentType) => ({
        type: types.RECOVERY_USERPASS_PRE,
        document,
        documentType,
    }),
    recoveryUserpassStep0: (password, document, documentType, formikBag, isRecoveryPassAndUserpass, captcha) => ({
        type: types.RECOVERY_USERPASS_STEP0_REQUEST,
        password,
        document,
        documentType,
        formikBag,
        isRecoveryPassAndUserpass,
        captcha,
    }),
    recoveryUserpassStep1: (sendChannel, userName, secondFactor, formikBag, isRecoveryPassAndUserpass) => ({
        type: types.RECOVERY_USERPASS_STEP1_REQUEST,
        sendChannel,
        userName,
        secondFactor,
        formikBag,
        isRecoveryPassAndUserpass,
    }),
    recoveryUserpassStep2: (code, formikBag) => ({
        type: types.RECOVERY_USERPASS_STEP2_REQUEST,
        code,
        formikBag,
    }),
    recoveryUserpassStep3: (newPassword, newPasswordConfirmation, exchangeToken, resetCode, formikBag) => ({
        type: types.RECOVERY_USERPASS_STEP3_REQUEST,
        newPassword,
        newPasswordConfirmation,
        exchangeToken,
        resetCode,
        formikBag,
    }),
    backToUserpassStep1: () => ({
        type: types.RECOVERY_USERPASS_BACK_TO_STEP_1,
    }),
    setRecoveryPassAndUserpass: () => ({
        type: types.SET_RECOVERY_PASS_USERPASS,
    }),
    removeRecoveryPassAndUserpass: () => ({
        type: types.REMOVE_RECOVERY_PASS_USERPASS,
    }),
    recoveryPassAndUserpassStep1: (code, formikBag) => ({
        type: types.RECOVERY_PASS_USERPASS_STEP1_REQUEST,
        code,
        formikBag,
    }),
    recoveryPassAndUserpassStep2: (newPin, newPinConfirmation, exchangeToken, resetCode, formikBag) => ({
        type: types.RECOVERY_PASS_USERPASS_STEP2_REQUEST,
        newPin,
        newPinConfirmation,
        exchangeToken,
        resetCode,
        formikBag,
    }),
    recoveryPassAndUserpassStep3: (newPassword, newPasswordConfirmation, exchangeToken, resetCode, formikBag) => ({
        type: types.RECOVERY_PASS_USERPASS_STEP3_REQUEST,
        newPassword,
        newPasswordConfirmation,
        exchangeToken,
        resetCode,
        formikBag,
    }),
    recoveryPassPreview: (username, exchangeToken) => ({
        type: types.RECOVERY_PASS_PREVIEW_REQUEST,
        username,
        exchangeToken,
    }),
    setResetCaptcha: (resetCaptcha) => ({
        type: types.RECOVERY_PASS_RESET_CAPTCHA,
        resetCaptcha,
    }),
};

// Selectors
export const selectors = {
    getExchangeToken: (state) => state.recoveryPassword.exchangeToken,
    getResetCode: (state) => state.recoveryPassword.resetCode,
    getShowCaptcha: (state) => state.recoveryPassword.showCaptcha,
    getResetCaptcha: (state) => state.recoveryPassword.resetCaptcha,
    getUserFirstName: (state) => state.recoveryPassword.userFirstName,
    getMaskedEmail: (state) => state.recoveryPassword.userMaskedEmail,
    getMaskedPhone: (state) => state.recoveryPassword.userMaskedPhone,
    getUserName: (state) => state.recoveryPassword.userName,
    getRecoveryPassAndUserpass: (state) => state.recoveryPassword.isRecoveryPassAndUserpass,
    getDocument: (state) => state.recoveryPassword.document,
    getDocumentType: (state) => state.recoveryPassword.documentType,
};
