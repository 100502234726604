import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { bool, string, shape, arrayOf, func } from "prop-types";
import { Field, Form, withFormik } from "formik";
import classNames from "classnames";
import Yup from "yup";
import moment from "moment";

import { actions as fundcorpActions, selectors as fundcorpSelectors } from "reducers/fundcorp";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import TabletSelectors from "pages/_components/TabletSelectors";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Col from "react-bootstrap/lib/Col";
import Selector from "pages/_components/fields/formik/Selector";
import { DateField } from "pages/_components/fields/DateField";

import { push } from "react-router-redux";
import { isEnabledApi5 } from "util/fundcorp";
import FundcorpList from "./_components/FundcorpList";

const FORM_ID = "fundcorp.list";
class FundcorpInformationMain extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        isDisabledDownload: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        fetchingDownload: bool.isRequired,
        isFetchingFundcorp: bool.isRequired,
        optionSelected: string.isRequired,
        shareValues: arrayOf(shape({})).isRequired,
        portfolioComposition: shape({}).isRequired,
        values: shape({}).isRequired,
        setFieldValue: func.isRequired,
        funds: arrayOf(shape({})).isRequired,
        filtersData: shape({}).isRequired,
        managementReport: arrayOf(shape({})).isRequired,
    };

    state = {
        showForm: false,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(fundcorpActions.listFundcorpInfPre("information"));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(fundcorpActions.cleanUp());
    }

    handleExport = (format) => {
        const { dispatch, shareValues, portfolioComposition, optionSelected, filtersData } = this.props;

        const summary = { shareValues, portfolioComposition, optionSelected, filtersData };
        dispatch(fundcorpActions.downloadFundcorpList(summary, format));
    };

    changeOption = (option) => {
        const { dispatch, portfolioComposition, shareValues, managementReport } = this.props;
        dispatch(
            fundcorpActions.saveOptionSelected(
                option,
                portfolioComposition?.portfolioCompositionsList?.length,
                shareValues?.length,
            ),
        );
        if (isEnabledApi5() && option === "portfolioComposition" && managementReport?.length === 0) {
            dispatch(fundcorpActions.listFundcorpInformation(option));
        }
    };

    getLabelTabSelector = (option) =>
        `fundcorp.${option === "portfolioComposition" && isEnabledApi5() ? "managementReport" : option}.label`;

    rowButtons = () => {
        const { dispatch, isDesktop, optionSelected } = this.props;
        if (optionSelected && optionSelected === "positions") {
            dispatch(fundcorpActions.saveOptionSelected("shareValues"));
        }

        const possibleOptions = ["shareValues", "portfolioComposition"];
        const options = possibleOptions.map((possibleOption) => ({
            value: possibleOption,
            label: this.getLabelTabSelector(possibleOption),
        }));

        return (
            <TabletSelectors
                isDesktop={isDesktop}
                possibleOptions={options}
                changeOption={this.changeOption}
                optionSelected={optionSelected}
                containerClass={classNames(
                    "fundcorp-tablet-selector py-25 d-flex justify-content-center",
                    { "mt-4": isDesktop },
                    { "my-0": !isDesktop },
                )}
            />
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    getActiveFunds = (funds) => {
        const fundsParameters = JSON.parse(configUtils.get("frontend.investment.funds.parameters"));
        const activeFunds = funds.filter(({ id }) => fundsParameters[`fondo${id}`]?.isON);

        return activeFunds;
    };

    renderList() {
        const { isDesktop, isFetchingFundcorp, values, optionSelected, funds } = this.props;
        const { shareValuesDate, portfolioCompositionDate } = values;
        const { showForm } = this.state;

        const fundOptions = [
            ...this.getActiveFunds(funds).map((fund) => ({
                value: fund.id,
                label: fund.description,
            })),
        ];

        return (
            <>
                {!isDesktop && (optionSelected === "shareValues" || !isEnabledApi5()) && (
                    <div className="justify-content-end d-flex">
                        <Button
                            className="btn-link px-2 my-0 mr-2"
                            block={false}
                            label={showForm ? "echeq.button.hideFilter" : "echeq.button.seeFilter"}
                            onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                        />
                    </div>
                )}
                {(isDesktop || showForm) && (
                    <Form autoComplete="off">
                        <Container
                            className={classNames("align-items-left account-header-align-items-left fundcorp-form", {
                                "account-header-detail":
                                    isDesktop && (optionSelected === "shareValues" || !isEnabledApi5()),
                            })}
                            gridClassName="form-content"
                            rowClassName={classNames("justify-content-left px-2", {
                                "pb-45": isDesktop && (optionSelected === "shareValues" || !isEnabledApi5()),
                                "pb-3": !isDesktop,
                            })}>
                            {optionSelected === "shareValues" && (
                                <>
                                    <Col
                                        className={classNames("p-2", {
                                            "w-100": !isDesktop,
                                            "d-flex align-items-end min-width-25 max-width-25": isDesktop,
                                        })}>
                                        <Field
                                            idForm={FORM_ID}
                                            name="shareValuesFund"
                                            component={Selector}
                                            options={fundOptions}
                                            formGroupClassName="mb-0 w-100 fundcorp__label-required"
                                            isRequired
                                            searchable={isDesktop}
                                        />
                                    </Col>
                                    <Col
                                        className={classNames("p-2", {
                                            "w-100": !isDesktop,
                                            "d-flex align-items-end": isDesktop,
                                        })}>
                                        <Field
                                            idField="shareValuesDate"
                                            component={DateField}
                                            formGroupClassName="mb-0"
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="shareValuesDate"
                                            minDate={null}
                                            value={shareValuesDate}
                                            maxDate={new Date()}
                                        />
                                    </Col>
                                </>
                            )}
                            {!isEnabledApi5() && optionSelected === "portfolioComposition" && (
                                <>
                                    <Col
                                        className={classNames("p-2", {
                                            "w-100": !isDesktop,
                                            "d-flex align-items-end min-width-25 max-width-25": isDesktop,
                                        })}>
                                        <Field
                                            component={Selector}
                                            formGroupClassName="mb-0 w-100"
                                            options={fundOptions}
                                            idForm={FORM_ID}
                                            name="portfolioCompositionFund"
                                            isRequired
                                            placeholderText={i18nUtils.get(`${FORM_ID}.placeholder`)}
                                        />
                                    </Col>
                                    <Col
                                        className={classNames("p-2", {
                                            "w-100": !isDesktop,
                                            "d-flex align-items-end": isDesktop,
                                        })}>
                                        <Field
                                            idField="portfolioCompositionDate"
                                            component={DateField}
                                            formGroupClassName="mb-0"
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="portfolioCompositionDate"
                                            minDate={null}
                                            value={portfolioCompositionDate}
                                        />
                                    </Col>
                                </>
                            )}
                            {(optionSelected === "shareValues" || !isEnabledApi5()) && (
                                <Col
                                    className={classNames("d-flex p-2", {
                                        "align-items-end ": isDesktop,
                                        "w-100": !isDesktop,
                                    })}>
                                    <Button
                                        bsStyle="primary"
                                        label={`${FORM_ID}.btn.filter.label`}
                                        loading={isFetchingFundcorp}
                                        type="submit"
                                        className={classNames({
                                            "filter-button mb-0": isDesktop,
                                            "w-100 mt-4": !isDesktop,
                                        })}
                                    />
                                </Col>
                            )}
                        </Container>
                    </Form>
                )}
                <FundcorpList {...this.props} />
            </>
        );
    }

    render() {
        const { isDesktop, isDisabledDownload, fetchingDownload, fetching, optionSelected } = this.props;
        const download =
            isEnabledApi5() && optionSelected === "portfolioComposition"
                ? {}
                : {
                      csvDownload: true,
                      xlsDownload: true,
                      handleClick: this.handleExport,
                      handleClickMessage: "global.download",
                      isFetchingExport: fetchingDownload,
                      exportDisabled: isDisabledDownload,
                      exportList: !fetching,
                  };

        if (isDesktop) {
            const centerElement = () => (
                <div className="d-flex align-items-center">
                    <Image src="images/fundcorp-logo.svg" wrapperClassName="fundcorp-logo" />
                    <h1 className="px-25 my-0">|</h1>
                    <I18n
                        id="fundcorp.title.information.secondPart"
                        component="h1"
                        componentProps={{ className: "my-0" }}
                    />
                </div>
            );

            return (
                <>
                    <Notification scopeToShow={FORM_ID} />
                    <div className="admin-detail-head px-0 mb-0">
                        <Head onBack={this.handleBack} />
                        <Head
                            centerElement={centerElement}
                            accessibilityTextId="fundcorp.title.information.secondPart"
                            imageStyle="mr-2"
                            {...download}
                        />
                        <PageLoading loading={fetching}>
                            {this.rowButtons()}
                            {this.renderList()}
                        </PageLoading>
                    </div>
                </>
            );
        }

        const centerElementMobile = () => (
            <div className="d-flex flex-column align-items-center">
                <I18n
                    id="fundcorp.title.information.secondPart"
                    component="h1"
                    componentProps={{ className: "my-0 line-height-1" }}
                />
                <Image src="images/fundcorp-logo-mobile.svg" wrapperClassName="fundcorp-logo" />
            </div>
        );

        const downloadMobile =
            isEnabledApi5() && optionSelected === "portfolioComposition"
                ? {}
                : {
                      downloadImageWhite: !fetching,
                      handleClick: this.handleExport,
                      handleClickMessage: "global.download",
                      isFetchingExport: fetchingDownload,
                      exportDisabled: isDisabledDownload,
                      exportList: true,
                  };

        return (
            <div className="admin-detail-head d-block px-0 mb-0">
                <Notification scopeToShow={FORM_ID} notificationClassname="navbar-fixed-top" />
                <Head
                    centerElement={centerElementMobile}
                    onBack={this.handleBack}
                    headerClassName="blue-main-header-mobile"
                    centerContentClassName="mx-5"
                    accessibilityTextId="fundcorp.title.information.secondPart"
                    {...downloadMobile}
                />
                <PageLoading loading={fetching}>
                    {this.rowButtons()}
                    {this.renderList()}
                </PageLoading>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: fundcorpSelectors.getFetching(state),
    fetchingDownload: fundcorpSelectors.getFetchingDownload(state),
    optionSelected: fundcorpSelectors.getOptionSelected(state),
    isDisabledDownload: fundcorpSelectors.getDisabledDownload(state),
    isFetchingFundcorp: fundcorpSelectors.isFetchingFundcorp(state),
    isEmptyState: fundcorpSelectors.getIsEmptyState(state),
    funds: fundcorpSelectors.getFunds(state),
    shareAccounts: fundcorpSelectors.getShareAccounts(state),
    shareValues: fundcorpSelectors.getShareValues(state),
    portfolioComposition: fundcorpSelectors.getPortfolioComposition(state),
    managementReport: fundcorpSelectors.getManagementReport(state),
    filtersData: fundcorpSelectors.getFiltersData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            shareValuesFund:
                props.filtersData && props.filtersData.shareValuesFund ? props.filtersData.shareValuesFund : "",
            shareValuesDate:
                props.filtersData && props.filtersData.shareValuesDate
                    ? props.filtersData.shareValuesDate
                    : moment(new Date()),
            portfolioCompositionFund:
                props.filtersData && props.filtersData.portfolioCompositionFund
                    ? props.filtersData.portfolioCompositionFund
                    : "",
            portfolioCompositionDate:
                props.filtersData && props.filtportfolioComposition?.subtitleersData.portfolioCompositionDate
                    ? props.filtersData.portfolioCompositionDate
                    : moment(new Date()),
        }),
        validationSchema: (props) =>
            Yup.lazy(() => {
                const { optionSelected } = props;

                let validations = {};

                if (optionSelected === "shareValues") {
                    validations = {
                        ...validations,
                        shareValuesFund: Yup.string().required(i18nUtils.get(`${FORM_ID}.search.fund.error.required`)),
                        shareValuesDate: Yup.string()
                            .nullable()
                            .required(i18nUtils.get(`${FORM_ID}.field.error.required`)),
                    };
                }

                if (optionSelected === "portfolioComposition") {
                    validations = {
                        ...validations,
                        portfolioCompositionFund: Yup.string().required(
                            i18nUtils.get(`${FORM_ID}.search.fund.error.required`),
                        ),
                        portfolioCompositionDate: Yup.string()
                            .nullable()
                            .required(i18nUtils.get(`${FORM_ID}.field.error.required`)),
                    };
                }

                return Yup.object().shape({
                    ...validations,
                });
            }),
        handleSubmit: (
            { shareValuesFund, shareValuesDate, portfolioCompositionFund, portfolioCompositionDate },
            formikBag,
        ) => {
            const { dispatch, optionSelected, funds } = formikBag.props;

            const fundOptions = [
                {
                    id: "all",
                    description: i18nUtils.get(`${FORM_ID}.fund.options.all`),
                },
                ...funds,
            ];

            const shareValuesFundDescription = shareValuesFund
                ? fundOptions.find(({ id }) => id === shareValuesFund).description
                : null;

            const portfolioCompositionFundDescription = portfolioCompositionFund
                ? fundOptions.find(({ id }) => id === portfolioCompositionFund).description
                : null;

            dispatch(
                fundcorpActions.listFundcorpInformation(
                    optionSelected,
                    shareValuesFund,
                    shareValuesFundDescription,
                    shareValuesDate,
                    portfolioCompositionFund,
                    portfolioCompositionFundDescription,
                    portfolioCompositionDate,
                ),
            );
        },
    }),
)(FundcorpInformationMain);
