import React from "react";
import { bool, number, string } from "prop-types";

import FormattedAmount from "pages/_components/FormattedAmount";

import * as i18nUtils from "util/i18n";
import classNames from "classnames";

function Range({ currency, min, max, hasError, className, containerClassName }) {
    return (
        <div className={classNames("rangue__box", className)}>
            <div className={classNames("rangue__container", containerClassName, { rangue__error: hasError })}>
                <div className="rangue__item">
                    <span className="rangue__label ">
                        {i18nUtils.get("accounts.movements.filters.minAmount.label")}
                    </span>
                    <FormattedAmount className="" currency={currency.toString()} quantity={min} notBold />
                </div>
                <div className="rangue__item">
                    <span className="rangue__label ">
                        {i18nUtils.get("accounts.movements.filters.maxAmount.label")}
                    </span>
                    <FormattedAmount className="" currency={currency.toString()} quantity={max} notBold />
                </div>
            </div>
        </div>
    );
}

Range.propTypes = {
    currency: number.isRequired,
    min: number.isRequired,
    max: number.isRequired,
    hasError: bool,
    className: string,
    containerClassName: string,
};

Range.defaultProps = {
    hasError: false,
    className: "",
    containerClassName: "",
};

export default Range;
