import React, { Component } from "react";
import classNames from "classnames";
import { string, node } from "prop-types";

class NavbarItem extends Component {
    static propTypes = {
        children: node.isRequired,
        align: string,
        className: string,
    };

    static defaultProps = {
        align: "",
        className: "",
    };

    render() {
        const { align, className, children } = this.props;
        return (
            <div
                className={classNames(
                    "toolbar-item",
                    {
                        "toolbar-item--fixed": align !== "",
                        [`toolbar-item-${align}`]: align !== "",
                    },
                    className,
                )}>
                {children}
            </div>
        );
    }
}

export default NavbarItem;
