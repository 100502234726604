import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { func, bool, shape } from "prop-types";

import { actions as recoveryPasswordActions, selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import { actions as loginActions } from "reducers/login";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Step2Content from "pages/recoveryPassAndUserpass/_components/Step2Content";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import withExchangeToken from "pages/_components/withExchangeToken";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Logo from "pages/login/_components/Logo";
import { Col } from "react-bootstrap";
import Container from "pages/_components/Container";

class RecoveryPassAndUserpassStep2 extends Component {
    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    renderTitleMobile = () => (
        <div className="w-min-content">
            <Logo className="svg-image logo logo-mobile-size" logoColor />
        </div>
    );

    render() {
        const { isDesktop, propsGrid } = this.props;

        return (
            <GridLayoutExternal scopeToShow="recoveryPassword" isDesktop={isDesktop} {...propsGrid}>
                <Notification scopeToShow="recoveryPassword" />
                <Container>
                    <Col xs="12" className={isDesktop ? "px-0" : ""}>
                        <Head
                            onClose={this.onHeaderClose}
                            title={isDesktop ? "recoveryUserpass.step3.header" : null}
                            headerClassName={!isDesktop ? "login-background-color-white" : "recover-pass-desktop"}
                            centerElement={!isDesktop && this.renderTitleMobile}
                            hideMobileMenu={!isDesktop && true}
                            fixed
                        />
                    </Col>
                </Container>
                <MainContainer className={classNames("login__layout main-container", { 
                    "container-fluid": isDesktop,
                    "px-3": !isDesktop,
                })}>
                    {!isDesktop && (
                        <Container className="container--layout flex-grow" >
                            <Col className="col col-12 view-title mt-3">
                                <div className="justify-content-center">
                                    <I18n
                                        id="recoveryUserpass.step3.header"
                                        componentProps={{ className: "f-size-5 font-regular" }}
                                        component="h2"
                                    />
                                </div>
                            </Col>
                        </Container>
                    )}
                    <Step2Content {...this.props} />
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}

RecoveryPassAndUserpassStep2.propTypes = {
    dispatch: func.isRequired,
    propsGrid: shape({}).isRequired,
    isDesktop: bool,
    isRecoveringUserpass: bool,
};

RecoveryPassAndUserpassStep2.defaultProps = {
    isDesktop: true,
    isRecoveringUserpass: true,
};

const mapStateToProps = (state) => ({
    exchangeToken: recoveryPasswordSelectors.getExchangeToken(state),
    isRecoveryPassAndUserpass: recoveryPasswordSelectors.getRecoveryPassAndUserpass(state),
    resetCode: recoveryPasswordSelectors.getResetCode(state),
});

export default connect(mapStateToProps)(withExchangeToken(RecoveryPassAndUserpassStep2));
