import React from "react";
import { func, string } from "prop-types";

class MobileCustomDatePickerInput extends React.Component {
    static propTypes = {
        onClick: func,
        value: string,
    };

    static defaultProps = {
        onClick: null,
        value: "",
    };

    render() {
        const { onClick, value } = this.props;

        return <input value={value} readOnly onClick={onClick} className="mobile-datepicker-custom" />;
    }
}

export default MobileCustomDatePickerInput;
