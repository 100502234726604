/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import classNames from "classnames";
import { bool, func, arrayOf, string, shape, number, objectOf, oneOfType } from "prop-types";

import { actions as statusActions } from "reducers/status";
import { actions as transactionsActions } from "reducers/transactions";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as desktopSelectors, actions } from "reducers/desktop";
import { selectors as campaignsSelectors } from "reducers/campaigns";
import { actions as accountsActions } from "reducers/accounts";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import * as stringUtils from "util/string";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import DraggableList from "pages/_components/DraggableList";
import Header from "pages/_components/header/Header";
import ContextMenu from "pages/_components/ContextMenu";
import Image from "pages/_components/Image";
import Head from "pages/_components/Head";
import * as Widgets from "pages/desktop/widgets";
import I18n from "pages/_components/I18n";
import Campaigns from "pages/campaigns/Campaigns";
import Container from "pages/_components/Container";
import GeneralMsg from "pages/_components/GeneralMsg";
import { Helmet } from "react-helmet";

import ButtonDrawer from "pages/_components/drawer/ButtonDrawer";
import BiometricIdentification from "./widgets/BiometricIdentification";
import EconomicGroups from "./widgets/economicGroup/EconomicGroups";
import WidgetDrawerContent from "./widgets/WidgetDrawerContent";

class Desktop extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
        layout: arrayOf(
            shape({
                column: number,
                id: string,
                row: number,
                uri: string,
            }),
        ).isRequired,
        isDesktop: bool,
        availableWidgets: arrayOf(
            shape({
                column: number,
                id: string,
                row: number,
                uri: string,
            }),
        ).isRequired,
        isEditable: bool,
        sessionFetching: bool,
        isMobileNative: bool.isRequired,
        desktopFetching: bool,
        campaigns: arrayOf(
            shape({
                clickable: bool,
                contentType: string,
                creationDate: string,
                creator: string,
                dismissable: bool,
                endDate: string,
                endDateAsString: string,
                expired: bool,
                idCampaign: number,
                image: string,
                imageList: arrayOf(
                    shape({
                        content: string,
                        creationDate: string,
                        fileName: string,
                        idCampaign: number,
                        idImage: number,
                        imageSize: objectOf(oneOfType([number, string])).isRequired,
                    }),
                ).isRequired,
                name: string,
                priority: number,
                length: number,
                startDate: string,
                startDateAsString: string,
                suspended: bool,
                url: string,
            }),
        ).isRequired,
        activeEnvironment: shape({ type: string }).isRequired,
        displayCampaigns: bool,
        daysToExpireCredential: shape({ type: string }).isRequired,
        desktopCredentialsNotificationShowed: bool.isRequired,
    };

    static defaultProps = {
        isDesktop: null,
        isEditable: null,
        sessionFetching: null,
        desktopFetching: null,
        displayCampaigns: null,
    };

    componentDidMount() {
        const { dispatch, location } = this.props;
        dispatch(actions.loadLayoutRequest());
        dispatch(statusActions.saveLastHref(location));
        dispatch(transactionsActions.saveTransactionRoute(location?.pathname));
        this.handleCredentialNotification();
        dispatch(accountsActions.accountsLastHref(location.pathname));
    }

    handleCredentialNotification = () => {
        const { dispatch, daysToExpireCredential, desktopCredentialsNotificationShowed } = this.props;
        const passwordNotifyDays = configUtils.getInteger("login.password.validity.notify.days", 10);
        const userpassNotifyDays = configUtils.getInteger("login.userpass.validity.notify.days", 10);
        const userNameAboutToExpire = parseInt(daysToExpireCredential?.userpassDaysToExpire, 10) <= userpassNotifyDays;
        const passwordAboutToExpire = parseInt(daysToExpireCredential?.passwordDaysToExpire, 10) <= passwordNotifyDays;

        if (desktopCredentialsNotificationShowed) {
            return;
        }

        if (userNameAboutToExpire) {
            setTimeout(() => {
                dispatch(
                    notificationActions.showNotification(
                        daysToExpireCredential.desktopUserpassMessage,
                        "warning",
                        ["desktop"],
                        i18n.get("enrollment.stepIRS.warning.title"),
                    ),
                );
            }, 1000);
            if (passwordAboutToExpire) {
                setTimeout(() => {
                    dispatch(
                        notificationActions.showNotification(
                            daysToExpireCredential.desktopPasswordMessage,
                            "warning",
                            ["desktop"],
                            i18n.get("enrollment.stepIRS.warning.title"),
                        ),
                    );
                }, 7000);
            }
        } else if (passwordAboutToExpire) {
            setTimeout(() => {
                dispatch(
                    notificationActions.showNotification(
                        daysToExpireCredential.desktopPasswordMessage,
                        "warning",
                        ["desktop"],
                        i18n.get("enrollment.stepIRS.warning.title"),
                    ),
                );
            }, 1000);
        }
        dispatch(loginActions.setDesktopCredentialNotificationShowed());
    };

    getColumns = (layout) =>
        layout.reduce((obj, item) => {
            const columnValue = obj[item.column] || [];

            return { ...obj, [item.column]: [...columnValue, item] };
        }, {});

    handleClick = (item) => {
        const { dispatch, layout } = this.props;
        const { column, row } = item;

        dispatch(actions.deleteWidget(layout.findIndex((widget) => widget.column === column && widget.row === row)));
    };

    handleIsEditableChange = () => {
        const { dispatch } = this.props;
        dispatch(actions.toggleIsEditable());
    };

    handleItemsPositionChange = (items) => {
        const { dispatch } = this.props;
        dispatch(actions.setLayout(items));
        dispatch(actions.saveLayoutRequest());
    };

    renderCenterElement = () => <Image src="images/logoMobileHeader.svg" className="svg-desktop-mobile" />;

    navigatePosicionConsolidada = () => {
        const { dispatch } = this.props;
        dispatch(push("/ConsolidatedPosition"));
    };

    handleRemoveWidget = (index) => {
        const { dispatch } = this.props;
        dispatch(actions.deleteWidget(index));
    };

    renderHeader = () => {
        const { isDesktop, availableWidgets, isEditable, activeEnvironment } = this.props;
        const idWidgetFunds = "funds";
        const showMvp2Release = configUtils.getBoolean("frontend.show.MVP2.functionalities");
        const hideDropdown =
            availableWidgets.length === 1 && availableWidgets[0].id === idWidgetFunds && !showMvp2Release;

        const hasAvailableWidgets =
            !hideDropdown && availableWidgets.length > 0 && isEditable ? (
                <ButtonDrawer
                    drawerContentClassName="widgets__drawer-container"
                    buttonElement={
                        <Button
                            image="images/plus.svg"
                            imageStyle="px-3"
                            label="desktop.selectWidget"
                            bsStyle="outline"
                            className="f-dir-row-reverse px-3"
                        />
                    }
                    content={<WidgetDrawerContent />}
                />
            ) : (
                <I18n id="desktop.widgets.empty" componentProps={{ className: "desktop-edit-empty-message" }} />
            );
        const type = activeEnvironment?.type;
        const title = "menu.desktop";
        const showSecondRelease = configUtils.getBoolean("frontend.show.second.release.functionalities");

        if (!isDesktop) {
            return (
                <Head
                    headerClassName="blue-main-header-mobile"
                    centerElement={this.renderCenterElement}
                    viewCommunications
                    communicationsRightIcon={
                        showSecondRelease && (
                            <ContextMenu
                                isDesktop={isDesktop}
                                buttonClassName="toolbar-item desktop-edit toolbar-item--fixed"
                                items={[
                                    {
                                        label: "desktop.posicionConsolidada.label",
                                        onClick: () => this.navigatePosicionConsolidada(),
                                    },
                                ]}
                            />
                        )
                    }
                />
            );
        }

        return (
            <Header
                navStyle="default p-0 pr-2 d-flex align-items-center"
                additionalClassName="view-header-desktop desktop__widgets">
                <Helmet>
                    <title>{`Banco CMF - ${i18n.get(title)}`}</title>
                </Helmet>
                <div className="toolbar-item view-title">
                    <I18n id={title} component="h1" componentProps={{ className: "visually-hidden" }} />
                </div>
                {type !== CORPORATE_GROUP_ENVIRONMENT_TYPE && (
                    <>
                        {showSecondRelease && (
                            <div className="toolbar-item desktop-edit toolbar-item--fixed">
                                <Button
                                    image="images/survey.svg"
                                    imageStyle="pr-2"
                                    label="desktop.posicionConsolidada.label"
                                    onClick={() => this.navigatePosicionConsolidada()}
                                    bsStyle="primary"
                                    id="posicionConsolidada"
                                />
                            </div>
                        )}
                        {!isEditable ? (
                            <>
                                <div className="toolbar-item desktop-edit toolbar-item--fixed">
                                    <Button
                                        image="images/pencil-stroke.svg"
                                        imageStyle="pr-2"
                                        label="desktop.editLayout.edit.label"
                                        onClick={() => this.handleIsEditableChange()}
                                        bsStyle="outline"
                                        style={{ background: "white" }}
                                        id="editDashboardBtn"
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="toolbar-item desktop-edit toolbar-item--fixed">
                                    {hasAvailableWidgets}
                                </div>
                                <div className="toolbar-item desktop-edit toolbar-item--fixed px-0">
                                    <Button
                                        image="images/cross.svg"
                                        className="btn-only-icon btn-circle ml-4"
                                        label="desktop.editLayout.finish.label"
                                        defaultLabelText="Terminar edición"
                                        onClick={() => this.handleIsEditableChange()}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </Header>
        );
    };

    renderItem = (item, { draggableItemProps }) => {
        const { isEditable, isDesktop } = this.props;
        const Widget = Widgets[stringUtils.capitalizeFirstLetter(item.id)];

        const buttonActionDesc = `${i18n.get("global.close")} ${i18n.get("global.widget")}, ${i18n.get(
            `list.addWidget.${item.id}`,
        )}`;

        return (
            <Widget
                className={classNames({ "draggable-list__item": isEditable })}
                closeButton={
                    isEditable && (
                        <>
                            <Button className="btn-outline widget__btn-close " onClick={() => this.handleClick(item)}>
                                <Image src="images/cross.svg" />
                                <span className="pr-2 widget__delete-button">
                                    {i18n.get("desktop.widgets.delete.text")}
                                </span>
                                <span className="visually-hidden">{buttonActionDesc}</span>
                            </Button>
                        </>
                    )
                }
                draggableItemProps={draggableItemProps}
                isEditable={isEditable}
                isDesktop={isDesktop}
            />
        );
    };

    render() {
        const {
            isEditable,
            activeEnvironment,
            campaigns,
            sessionFetching,
            desktopFetching,
            layout,
            isDesktop,
            displayCampaigns,
            isMobileNative,
        } = this.props;
        const type = activeEnvironment?.type;
        const fingerprintEnabled = configUtils.getBoolean("frontend.login.fingerprint.enabled");

        return (
            <>
                <Notification scopeToShow="desktop" />

                {this.renderHeader()}
                <Container
                    showLoader={sessionFetching || desktopFetching}
                    className={isDesktop ? "z-index-200" : undefined}>
                    <div className="above-the-fold">
                        {displayCampaigns && campaigns && (
                            <Container className="container--layout p-0" gridClassName="container-fluid">
                                <Campaigns section="desktop-header" />
                            </Container>
                        )}

                        {type === CORPORATE_GROUP_ENVIRONMENT_TYPE && <EconomicGroups />}

                        {layout.length > 0 && (
                            <Container
                                className="container--layout flex-grow px-md-0 pt-0 mt-0"
                                gridClassName="container-fluid px-md-0">
                                <Col sm={12} className="col">
                                    <DraggableList
                                        columns={this.getColumns(layout)}
                                        isDragEnabled={isEditable}
                                        itemRenderer={this.renderItem}
                                        onItemsPositionChange={this.handleItemsPositionChange}
                                    />
                                </Col>
                            </Container>
                        )}
                        {layout.length === 0 && !isEditable && type !== CORPORATE_GROUP_ENVIRONMENT_TYPE && (
                            <GeneralMsg
                                imagePath="images/coloredIcons/desktop.svg"
                                title={
                                    isDesktop ? (
                                        <h2>
                                            <I18n id="widgets.list.empty.title" />
                                            <br />
                                            <I18n id="widgets.list.empty.description" />
                                        </h2>
                                    ) : (
                                        <>
                                            <I18n id="widgets.list.empty.title" />
                                            <I18n id="widgets.list.empty.description.mobile" />
                                        </>
                                    )
                                }
                                isDesktop={isDesktop}
                            />
                        )}
                    </div>
                    {fingerprintEnabled && isMobileNative ? <BiometricIdentification /> : <></>}
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    availableWidgets: desktopSelectors.getAvailableWidgets(state),
    layout: desktopSelectors.getLayout(state),
    isEditable: desktopSelectors.getIsEditale(state),
    campaigns: campaignsSelectors.getCampaigns(state),
    sessionFetching: sessionSelectors.isFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    desktopFetching: desktopSelectors.isFetching(state),
    displayCampaigns: loginSelectors.getDisplayCampaigns(state),
    daysToExpireCredential: loginSelectors.getDaysToExpireCredential(state),
    desktopCredentialsNotificationShowed: loginSelectors.getDesktopCredentialsNotificationShowed(state),
});

export default connect(mapStateToProps)(Desktop);
