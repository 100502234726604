/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { shape, bool, arrayOf, number, func } from "prop-types";

import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "pages/_components/Spinner";
import CardContainer from "pages/_components/Card/CardContainer";
import ThirdPaymentItem from "./ThirdPaymentItem";

class TransactionThirdPaymentList extends Component {
    static propTypes = {
        requests: arrayOf(shape({})).isRequired,
        isDesktop: bool.isRequired,
        latestPageNumber: number.isRequired,
        latestTotalPages: number.isRequired,
        option: bool.isRequired,
        fetchMoreTransactions: func.isRequired,
    };

    render() {
        const { fetchMoreTransactions, isDesktop, latestPageNumber, latestTotalPages, option, requests } = this.props;

        const list =
            requests &&
            requests.map((request) => {
                const location = {
                    pathname: `/payThirdParties/${request.transaction.idTransaction}/detail`,
                    state: { tr: request.transaction },
                };
                if (isDesktop) {
                    return (
                        <CardContainer
                            className="w-100 mb-3"
                            key={request.transaction.idTransactionStatus}
                            renderAs={Link}
                            to={location}>
                            <ThirdPaymentItem
                                key={`transaction-${request.transaction.idTransactionStatus}`}
                                request={request}
                                isDesktop={isDesktop}
                            />
                        </CardContainer>
                    );
                }

                return (
                    <ThirdPaymentItem
                        key={`transaction-${request.transaction.idTransactionStatus}`}
                        request={request}
                        isDesktop={isDesktop}
                    />
                );
            });

        if (isDesktop) {
            return (
                <Container className="flex-grow scrollable ">
                    <Col xs={12} className="px-0">
                        <div
                            ref={(tableRef) => {
                                this.child = tableRef;
                            }}>
                            {requests && requests.length ? (
                                list
                            ) : (
                                <div
                                    className="flex-grow text-center newThirdPayment-empty-state"
                                    key="noMoreMovements">
                                    <div className="illustration-wrapper">
                                        <Image
                                            src="images/coloredIcons/find-in-page.svg"
                                            className="svg-big-icon"
                                            styles={{ width: "120px", height: "132px" }}
                                        />
                                    </div>
                                    <div
                                        className="newThirdPayment-empty-state-label justify-content-center mx-4 my-4"
                                        style={{ opacity: 1 }}>
                                        {option ? (
                                            <I18n id="payments.thirdPayment.supplier.empty.message" component="p" />
                                        ) : (
                                            <I18n id="payments.thirdPayment.salary.empty.message" component="p" />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                </Container>
            );
        }

        return (
            <>
                {list && list.length > 0 ? (
                    <div className="container-fluid">
                        <InfiniteScroll
                            dataLength={list.length}
                            next={fetchMoreTransactions}
                            hasMore={latestPageNumber !== latestTotalPages}
                            loader={<Spinner />}
                            style={{ overflow: "hidden" }}
                            endMessage={
                                <div className="table-row table-end" key="noMoreTransactions">
                                    <I18n id="transactions.list.noMoreTransactions" />
                                </div>
                            }>
                            {list}
                        </InfiniteScroll>
                    </div>
                ) : (
                    <div className="text-center no-more-data" key="noMoreTransactions">
                        <div className="illustration-wrapper">
                            <Image src="images/loupeInFile.svg" className="svg-big-icon" />
                        </div>
                        <p className="text-lead px-2">
                            {option ? (
                                <I18n id="payments.thirdPayment.supplier.empty.message" />
                            ) : (
                                <I18n id="payments.thirdPayment.salary.empty.message" />
                            )}
                        </p>
                    </div>
                )}
            </>
        );
    }
}

export default TransactionThirdPaymentList;
