import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { bool, func, shape } from "prop-types";
import { withFormik } from "formik";
import Yup from "yup";
import moment from "moment";

import PageLoading from "pages/_components/PageLoading";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";
import MovementsFilters from "./MovementsFilters";
import LastMovementsGrid from "./LastMovementsGrid";

import PendingsMovementsGrid from "./PendingsMovementsGrid";

const FORM_ID = "accounts.movements";
const INITIAL_MOVEMENTS_SELECTED = true;

function MovementsV2({
    dispatch,
    selectedAccount,
    isDesktop,
    isFetchingMovements,
    values: {
        findBy,
        lastDateFrom,
        lastDateTo,
        pendingDateFrom,
        pendingDateTo,
        amountFrom,
        amountTo,
        voucher,
        detail,
        movementType,
    },
    setFieldValue,
    resetForm,
}) {
    const [isLatestMovementsSelected, setIsLatestMovementsSelected] = useState(INITIAL_MOVEMENTS_SELECTED);

    const getPendingMinDate = () => {
        const isTest = configUtils.getBoolean("corePendingDate.isTest", false);
        const strDate = configUtils.get("coreDate.defaultPendingDate");
        return isTest && strDate ? moment.utc(strDate) : moment().startOf("day");
    };

    const setDates = () => {
        const configuredDateFromValue = configUtils.getInteger("movements.dateTo.periodFront", 1);

        const startDateLatest = moment()
            .startOf("day")
            .subtract(configuredDateFromValue, "months");
        const endDateLatest = moment().startOf("day");
        const startDatePending = getPendingMinDate();
        const plusDays = configUtils.getInteger("movements.maxDays.default", 30);
        const endDatePending = getPendingMinDate().add(plusDays, "days");
        setFieldValue("lastDateFrom", startDateLatest);
        setFieldValue("lastDateTo", endDateLatest);
        setFieldValue("pendingDateFrom", startDatePending);
        setFieldValue("pendingDateTo", endDatePending);
        return { startDateLatest, endDateLatest, startDatePending, endDatePending };
    };

    const fetchInitialMovements = (isSelectedLatestMovements) => {
        const { startDateLatest, endDateLatest, startDatePending, endDatePending } = setDates();
        dispatch(
            accountsActions.fetchLatestMovements({
                selectedAccount,
                latestMovementsPageNumber: "1",
                pendingMovementsPageNumber: "1",
                findBy: "period",
                lastDateFrom: startDateLatest,
                lastDateTo: endDateLatest,
                pendingDateFrom: startDatePending,
                pendingDateTo: endDatePending,
                isLatestMovementsSelected: isSelectedLatestMovements,
            }),
        );
    };
    const handleMovementToShow = (isSelectedLatestMovements) => {
        setIsLatestMovementsSelected(isSelectedLatestMovements);
        resetForm();
        dispatch(accountsActions.setIsLatestMovements(isSelectedLatestMovements));
        fetchInitialMovements(isSelectedLatestMovements);
    };

    useEffect(() => {
        dispatch(accountsActions.setIsLatestMovements(INITIAL_MOVEMENTS_SELECTED));
        fetchInitialMovements(INITIAL_MOVEMENTS_SELECTED);
        return () => {
            handleMovementToShow(isLatestMovementsSelected);
            dispatch(accountsActions.removeMovements());
        };
    }, []);

    const getStrDate = (str) => {
        const date = str.substring(0, str.indexOf("T"));
        return moment(date).format("DD/MM/YYYY");
    };

    const getMovementsNextPage = (nextPage) =>
        dispatch(
            accountsActions.fetchLatestMovements({
                selectedAccount,
                latestMovementsPageNumber: isLatestMovementsSelected ? nextPage : undefined,
                pendingMovementsPageNumber: !isLatestMovementsSelected ? nextPage : undefined,
                findBy,
                lastDateFrom,
                lastDateTo,
                pendingDateFrom,
                pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
                isLatestMovementsSelected,
            }),
        );

    return (
        <PageLoading className="line-loader" loading={isFetchingMovements}>
            {selectedAccount && (
                <>
                    <MovementsFilters
                        isDesktop={isDesktop}
                        findBy={findBy}
                        isLatestMovementsSelected={isLatestMovementsSelected}
                        lastDateFrom={lastDateFrom}
                        lastDateTo={lastDateTo}
                        pendingDateFrom={pendingDateFrom}
                        pendingDateTo={pendingDateTo}
                        amountFrom={amountFrom}
                        amountTo={amountTo}
                        voucher={voucher}
                        detail={detail}
                        movementType={movementType}
                        getPendingMinDate={getPendingMinDate}
                        handleMovementToShow={handleMovementToShow}
                        setDates={setDates}
                    />
                    {isLatestMovementsSelected ? (
                        <LastMovementsGrid
                            isDesktop={isDesktop}
                            getStrDate={getStrDate}
                            getMovementsNextPage={getMovementsNextPage}
                        />
                    ) : (
                        <PendingsMovementsGrid
                            isDesktop={isDesktop}
                            getStrDate={getStrDate}
                            getMovementsNextPage={getMovementsNextPage}
                        />
                    )}
                </>
            )}
        </PageLoading>
    );
}

MovementsV2.propTypes = {
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    selectedAccount: shape({}).isRequired,
    isFetchingMovements: bool.isRequired,
    setFieldValue: func.isRequired,
    values: shape({}).isRequired,
    resetForm: func.isRequired,
};

const mapStateToProps = (state) => ({
    selectedAccount: accountsSelectors.getSelectedAccount(state),
    isFetchingMovements: accountsSelectors.getFetchingMovements(state),
    pendingMovementsTotalPages: accountsSelectors.getPendingMovementsTotalPages(state),
    isLatestMovementsSelected: accountsSelectors.isLatestMovementsSelected(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            dateFrom: null,
            dateTo: null,
            lastDateFrom: null,
            lastDateTo: null,
            pendingDateFrom: null,
            pendingDateTo: null,
            findBy: "period",
            amountFrom: null,
            amountTo: null,
            voucher: null,
            detail: null,
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18nUtils.get(`${FORM_ID}.dateFrom.error`))
                        : Yup.date().nullable(),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18nUtils.get(`${FORM_ID}.dateTo.error`))
                        : Yup.date().nullable(),
                    voucher: values.voucher
                        ? Yup.number()
                              .typeError(i18nUtils.get("accounts.movements.integer.positive"))
                              .nullable()
                              .positive(i18nUtils.get("accounts.movements.integer.positive"))
                              .integer(i18nUtils.get("accounts.movements.integer.invalidValue"))
                        : Yup.number().nullable(),
                }),
            ),
        handleSubmit: (
            {
                findBy,
                lastDateFrom,
                lastDateTo,
                pendingDateFrom,
                pendingDateTo,
                amountFrom,
                amountTo,
                voucher,
                detail,
                longDetail,
                movementType,
            },
            formikBag,
        ) => {
            const { dispatch, selectedAccount, isLatestMovementsSelected } = formikBag.props;

            const type = movementType > 1 ? null : movementType;
            dispatch(
                accountsActions.fetchLatestMovements({
                    selectedAccount,
                    latestMovementsPageNumber: "1",
                    pendingMovementsPageNumber: "1",
                    findBy,
                    lastDateFrom,
                    lastDateTo,
                    pendingDateFrom,
                    pendingDateTo,
                    amountFrom,
                    amountTo,
                    voucher,
                    detail,
                    type,
                    isLatestMovementsSelected,
                    longDetail,
                }),
            );
        },
    }),
)(MovementsV2);
