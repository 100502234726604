// Action types
export const types = {
    OPEN_BOTTOM_SHEET: "bottomSheet/OPEN_BOTTOM_SHEET",
    CLOSE_BOTTOM_SHEET: "bottomSheet/CLOSE_BOTTOM_SHEET",
};

// Initial state
export const INITIAL_STATE = {
    isBottomSheetDisplayed: false,
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.OPEN_BOTTOM_SHEET:
            return {
                ...state,
                isBottomSheetDisplayed: action.openBottomSheet,
            };

        case types.CLOSE_BOTTOM_SHEET:
            return {
                ...state,
                isBottomSheetDisplayed: action.closeBottomSheet,
            };

        default:
            return state;
    }
};

// Action creators
export const actions = {
    openBottomSheet: () => ({
        type: types.OPEN_BOTTOM_SHEET,
    }),
    // poi data actions
    closeBottomSheet: (payload) => ({
        type: types.CLOSE_BOTTOM_SHEET,
        payload,
    }),
};

// Selectors
export const selectors = {
    getBottomSheetDisplayState: (state) => state.isBottomSheetDisplayed,
};
