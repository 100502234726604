import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, arrayOf, string, shape, bool } from "prop-types";
import Notification from "pages/_components/Notification";
import { selectors, actions } from "reducers/checks";
import { echeqShape, accountDescription, findBeneficiary } from "util/checks";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import Heading from "pages/_components/Heading";
import Button from "pages/_components/Button";
import { selectors as sessionSelectors } from "reducers/session";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import classNames from "classnames";
import I18n from "pages/_components/I18n";

const FORM_ID = "echeqs";

class RequestReturnEcheqAction extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        echeqAction: shape({
            action: string.isRequired,
            echeqList: arrayOf(echeqShape),
            specialAttribute: string.isRequired,
        }).isRequired,
        accounts: arrayOf(shape({})).isRequired,
        userId: string.isRequired,
        environmentCuit: string.isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        submitting: false,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    closeEcheqAction = () => {
        const { dispatch } = this.props;

        dispatch(actions.cleanEcheqAction());
        this.handleBack();
    };

    componentWillMount = () => {
        const { echeqAction } = this.props;

        if (!echeqAction.action) {
            this.handleBack();
        }
    };

    createAccountDescription = () => {
        const { echeqAction, accounts } = this.props;
        const { specialAttribute } = echeqAction;
        const account = accounts.find((a) => a.idProduct === specialAttribute);

        const { productAlias, number, currency, productType } = account;

        return accountDescription(productAlias, number, currency, productType);
    };

    executeEcheqAction = () => {
        const { dispatch, echeqAction, userId, accounts } = this.props;
        let attribute = echeqAction.specialAttribute;

        if (echeqAction.action === "CUSTODY" || echeqAction.action === "DEPOSIT") {
            const accountSelected = accounts.find((account) => account.idProduct === echeqAction.specialAttribute);
            attribute = accountSelected.cbu;
        }
        this.setState({ submitting: true });
        dispatch(actions.executeActionEcheq({ ...echeqAction, specialAttribute: attribute }, userId));
    };

    renderHeader = () => {
        const { echeqAction, isDesktop } = this.props;

        const title = `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.title`;
        if (isDesktop) {
            return (
                <div className="admin-detail-head px-0">
                    <Head onBack={this.handleBack} onClose={this.closeEcheqAction} />
                    <Head title={title} centerContentClassName="p-0" />
                </div>
            );
        }

        return (
            <Head
                headerClassName="blue-main-header-mobile"
                titleClassName="w-100"
                onBack={this.handleBack}
                title={title}
                centerContentClassName="mx-5"
            />
        );
    };

    render() {
        const { echeqAction, environmentCuit, isDesktop } = this.props;
        const { submitting } = this.state;

        return (
            <>
                {this.renderHeader()}
                <div
                    className={classNames({
                        "admin-content-center": !isDesktop,
                        "admin-detail-head px-0": isDesktop,
                        "mb-2 px-6": !isDesktop,
                    })}>
                    <I18n id="echeqs.action.subtitle" component="h4" />
                </div>
                <Notification scopeToShow="echeqs" />
                <MainContainer>
                    <div className="above-the-fold">
                        {echeqAction?.echeqList?.length > 1 && (
                            <Col xs={12} className="justify-content-right flex">
                                <SelectedAccountsAmount list={echeqAction?.echeqList} summaryView />
                            </Col>
                        )}
                        {echeqAction?.echeqList?.map((echeq) => {
                            const beneficiary = findBeneficiary(echeq, environmentCuit);
                            return (
                                <Container
                                    className="flex-grow align-items-center container-white with-margin with-padding mb-0"
                                    gridClassName="form-content">
                                    <>
                                        <Col xs={12} md={3}>
                                            <Heading.DataGroup
                                                containerClassName={`transfer-data data-wrapper-flex ${
                                                    isDesktop ? "" : "space-between"
                                                }`}
                                                label={`${FORM_ID}.cheque_numero`}
                                                data={echeq.cheque_numero}
                                            />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Heading.DataGroup
                                                containerClassName={`transfer-data data-wrapper-flex ${
                                                    isDesktop ? "" : "space-between"
                                                }`}
                                                label={`${FORM_ID}.beneficiario_nombre`}
                                                data={beneficiary.beneficiarioRazonSocial.replaceAll("#", "Ñ")}
                                            />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Heading.DataGroup
                                                containerClassName={`transfer-data data-wrapper-flex ${
                                                    isDesktop ? "" : "space-between"
                                                }`}
                                                label={`${FORM_ID}.beneficiario_documento`}
                                                data={beneficiary.beneficiarioDocumento}
                                            />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Heading.DataGroup
                                                containerClassName={`transfer-data data-wrapper-flex ${
                                                    isDesktop ? "" : "space-between"
                                                }`}
                                                label={`${FORM_ID}.monto`}
                                                data={
                                                    <FormattedAmount
                                                        currency={i18n.get(
                                                            "currency.label.".concat(echeq.emisor_moneda),
                                                        )}
                                                        quantity={echeq.monto}
                                                        notBold
                                                    />
                                                }
                                            />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Heading.DataGroup
                                                containerClassName={`transfer-data data-wrapper-flex ${
                                                    isDesktop ? "" : "space-between"
                                                }`}
                                                label={`${FORM_ID}.fecha_emision`}
                                                data={<FormattedDate date={echeq.fecha_emision} />}
                                            />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Heading.DataGroup
                                                containerClassName={`transfer-data data-wrapper-flex ${
                                                    isDesktop ? "" : "space-between"
                                                }`}
                                                label={`${FORM_ID}.fecha_pago`}
                                                data={<FormattedDate showTime={false} date={echeq.fecha_pago} />}
                                            />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            {echeqAction.action === "REQUEST-RETURN" && (
                                                <Heading.DataGroup
                                                    containerClassName={`transfer-data data-wrapper-flex ${
                                                        isDesktop ? "" : "space-between"
                                                    }`}
                                                    label={`${FORM_ID}.return.label`}
                                                    data={echeqAction.specialAttribute}
                                                />
                                            )}
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <></>
                                        </Col>
                                    </>
                                </Container>
                            );
                        })}

                        <Container className="align-items-center flex-grow container-white mt-0 pb-3">
                            <Col lg={4} md={9} sm={12}>
                                <div className="admin-content-center">
                                    <I18n
                                        id="confirmation.withoutotp.text"
                                        component="p"
                                        componentProps={{ className: "confirmation__text-without-otp" }}
                                    />
                                </div>
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="global.confirm"
                                    onClick={this.executeEcheqAction}
                                    loading={submitting}
                                    image="images/arrowRight.svg"
                                    imageStyle="mr-2"
                                    className="text-capitalize"
                                />
                            </Col>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    option: selectors.getEcheqOption(state),
    echeqAction: selectors.getEcheqsToDoActions(state),
    accounts: selectors.getAccounts(state),
    userId: sessionSelectors.getUser(state).userId,
    environmentCuit: selectors.getEnvironmentCuit(state),
});

export default connect(mapStateToProps)(RequestReturnEcheqAction);
