import * as API from "middleware/api";

export const getRestrictions = (idUser) => API.executeWithAccessToken("/administration.restrictions.list", idUser);

export const manageRestrictionsPreview = (data) =>
    API.executeWithAccessToken(`/administration.restrictions.manage.preview`, data);

export const manageRestrictions = (data) =>
    API.executeWithAccessToken(`/administration.restrictions.manage.send`, data);

export const manageRestrictionsPre = () => API.executeWithAccessToken("/administration.restrictions.manage.pre");

export const restrictionsDeleteRequest = (data) =>
    API.executeWithAccessToken(`/administration.restrictions.user.delete.send`, data);

export const getRestrictionsAvailability = () =>
    API.executeWithAccessToken(`/administration.restrictions.availability.get`);
