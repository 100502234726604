import React, { Component } from "react";
import { func, shape, bool, arrayOf, number, string } from "prop-types";
import { connect } from "react-redux";
import { push, goBack } from "react-router-redux";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import Yup from "yup";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import * as i18nUtils from "util/i18n";

import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import isTokenActive from "util/token";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";
import Heading from "pages/_components/Heading";
import { getIncomingDebinAccountName, getAccountNumber } from "util/accounts";

const FORM_ID = "debin.addAccount.summary";
class DebinAddAccountsSummary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        accounts: arrayOf(shape({ length: number.isRequired })),
        debin: shape({}).isRequired,
        loadingCancel: bool.isRequired,
        loggedUser: func.isRequired,
        debinToCancel: shape({
            id: string,
            seller: shape({
                titular: string,
                documentType: string,
                documentNumber: string,
                cbu: string,
                cbuAlias: string,
                terminal: string,
            }),
        }).isRequired,
    };

    static defaultProps = {
        accounts: [],
    };

    componentDidMount() {
        const { dispatch, loggedUser } = this.props;

        dispatch(accountsActions.listAccounts(loggedUser.userId));
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1>{i18nUtils.get("debin.cancelRequest.title")}</h1>
        </div>
    );

    render() {
        const { dispatch, isDesktop, debin, loadingCancel, debinToCancel, accounts } = this.props;
        if (!debin) {
            dispatch(push("/charges/generateDebin"));
            return null;
        }

        const {
            id,
            buyer: { cbu: buyerCbu, ...buyer },
            amount: { currency, quantity },
            concept,
            expirationDate,
            description,
        } = debin;

        const continueButton = (
            <Button
                loading={loadingCancel}
                type="submit"
                bsStyle="primary"
                label="global.confirm"
                image="images/arrowRight.svg"
                imageStyle="mr-2"
                className="text-capitalize"
            />
        );

        const credentials = (
            <>
                {!isTokenActive(!isDesktop) ? (
                    <I18n
                        id="transfer.summary.confirm.label"
                        component="p"
                        componentProps={{ className: "small font-light text-center" }}
                    />
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}
                <div className="admin-content-center">
                    <Field
                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </>
        );

        const sellerAccount = accounts.find((account) => account.cbu === debinToCancel.seller.cbu);
        const sellerAccountName = getIncomingDebinAccountName(sellerAccount);

        const content = (
            <MainContainer>
                <Form className="above-the-fold">
                    {!isDesktop && <h4 className="text-center p-2">{i18nUtils.get("debin.cancelRequest.subtitle")}</h4>}
                    <Container
                        className={`flex-grow align-items-center container-white my-2 py-2 ${!isDesktop ? "mb-0" : ""}`}
                        gridClassName="form-content">
                        <Col xl={3} lg={3} md={3} sm={12}>
                            <Heading.DataGroup
                                containerClassName={`transfer-data  mb-0 ${
                                    isDesktop ? "data-wrapper" : "data-wrapper-flex space-between debin-detail"
                                }`}
                                label="debin.create.summary.creditAccount.label"
                                data={sellerAccountName}
                            />
                        </Col>
                        <Col xl={3} lg={3} md={3} sm={12}>
                            <Heading.DataGroup
                                containerClassName={`transfer-data  mb-0 ${
                                    isDesktop ? "data-wrapper" : "data-wrapper-flex space-between debin-detail"
                                }`}
                                label="debin.create.summary.concept.label"
                                data={i18nUtils.get(`transfers.concept.${concept}`)}
                            />
                        </Col>
                        <Col xl={3} lg={3} md={3} sm={12}>
                            <Heading.DataGroup
                                containerClassName={`transfer-data mb-0 ${
                                    isDesktop ? "data-wrapper" : "data-wrapper-flex space-between debin-detail"
                                }`}
                                label="debin.create.summary.description.label"
                                data={description || "-"}
                            />
                        </Col>
                        <Col xl={3} lg={3} md={3} sm={12}>
                            <Heading.DataGroup
                                containerClassName={`transfer-data  mb-0 ${
                                    isDesktop ? "data-wrapper" : "data-wrapper-flex space-between debin-detail"
                                }`}
                                label="debin.create.summary.expiration.label"
                                data={<FormattedDate showTime date={expirationDate} />}
                            />
                        </Col>
                    </Container>
                    <Container
                        className={`flex-grow align-items-center container-white my-2 py-2 ${!isDesktop ? "mb-0" : ""}`}
                        gridClassName="form-content">
                        <Col className="col-12 col-xl-12 px-0" lg={12} md={12} sm={12}>
                            <Col xl={3} lg={3} md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    label="debin.create.summary.idDebin.label"
                                    data={id}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    label="debin.create.summary.owner.label"
                                    data={buyer?.titular}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    label="debin.create.summary.documentType.label"
                                    data={`${buyer?.documentType} ${buyer?.documentNumber}`}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    label="debin.create.summary.amount.label"
                                    data={
                                        <FormattedAmount
                                            currency={i18nUtils.get(`global.coelsa.currency.code.${currency}`)}
                                            quantity={quantity}
                                            notBold
                                            className={`${isDesktop ? "f-size-4" : "f-size-65"}`}
                                        />
                                    }
                                />
                            </Col>
                        </Col>
                        <Col className="col-12 col-xl-12 px-0" lg={12} md={12} sm={12}>
                            <Col xl={3} lg={3} md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    label="debin.create.summary.debitAccount.label"
                                    data={getAccountNumber(buyerCbu)}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    label="debin.create.summary.cbu.label"
                                    data={buyerCbu}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    label="debin.create.summary.bankName.label"
                                    data={<span>Banco CMF</span>}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={12}>
                                <Heading.DataGroup
                                    containerClassName={`transfer-data data-wrapper-flex ${
                                        isDesktop ? "" : "space-between"
                                    }`}
                                    label="debin.create.summary.reference.label"
                                    data={<span />}
                                />
                            </Col>
                        </Col>
                    </Container>
                    <Container
                        className={`flex-grow align-items-center container-white my-2 py-2 ${!isDesktop ? "mb-0" : ""}`}
                        gridClassName="form-content">
                        <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                            {credentials}
                            {continueButton}
                        </Col>
                    </Container>
                </Form>
            </MainContainer>
        );

        return (
            <>
                <Notification scopeToShow="debinCancel" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={!isDesktop && this.centerContentMobile}
                        centerContentClassName={!isDesktop && "mx-5"}
                    />
                    {isDesktop && (
                        <>
                            <h1 className="my-0">{i18nUtils.get("debin.cancelRequest.title")}</h1>
                            <p>{i18nUtils.get("debin.cancelRequest.subtitle")}</p>
                        </>
                    )}
                </div>
                {content}
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    debin: debinSelectors.getDebinToCancel(state),
    loadingCancel: debinSelectors.getFetching(state),
    accounts: accountsSelectors.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
    debinToCancel: debinSelectors.getDebinToCancel(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, debin } = formikBag.props;
            dispatch(debinActions.cancelRequest(debin, otp, formikBag));
        },
    }),
)(DebinAddAccountsSummary);
