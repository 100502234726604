import { call, put, takeLatest } from "redux-saga/effects";

import { actions as notificationActions } from "reducers/notification";
import * as i18n from "util/i18n";
import * as form from "middleware/form";

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.LOAD_SIGNATURE_TICKET_REQUEST, loadSignatureTicketRequest(middleware, actions)),
];

export default sagasCreator;

const loadSignatureTicketRequest = (middleware, actions) =>
    function*({ idTransaction }) {
        const transactionResponse = yield call(form.readTransaction, idTransaction);

        if (transactionResponse.type === "W") {
            yield put(actions.loadSignatureTicketFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["administration"]),
            );
        } else {
            const { idUser, signatureLevel } = transactionResponse.data.data.transaction.data;
            const detailsResponse = yield call(middleware.loadDetailsRequest, idUser);
            if (detailsResponse.type === "W") {
                yield put(actions.loadSignatureTicketFailure());
                yield put(
                    notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                        "administration",
                    ]),
                );
            } else {
                yield put(actions.loadSignatureTicketSuccess(signatureLevel));
                yield put(actions.loadDetailsSuccess({ ...detailsResponse.data.data, signatureLevel }));
            }
        }
    };
