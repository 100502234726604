import {
    acceptIncomingDebinPreMiddleware,
    acceptIncomingDebinSendMiddleware,
    cancelDebin,
    createCredin,
    createCredinPre,
    createDebin,
    createDebinPre,
    declineIncomingDebinSendMiddleware,
    getRecurrencies,
    getSeller,
    listConcepts,
    loadIncomingDebinListMiddleware,
    loadSellerDebinDetail,
    loadSentRequestsMiddleware,
    relateBuyerToRecurrency,
    removeSellerAccountMiddleware,
    sendAddAccountsAction,
    setUpdateRecurrencyState,
} from "middleware/debin";
import * as session from "middleware/session";
import { push, routerActions } from "react-router-redux";
import { actions as debinActions, selectors as debinSelectors, types } from "reducers/debin";
import { actions as formActions } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";
import { call, put, select, take, takeLatest } from "redux-saga/effects";
import * as configUtils from "util/config";
import * as utilDownload from "util/download";
import * as i18n from "util/i18n";
import { INSUFFICIENT_SIGNATURES } from "util/responses";

const sagas = [
    takeLatest(types.LOAD_SENT_REQUESTS_REQUEST, loadSentRequests),
    takeLatest(types.SEND_ADD_ACCOUNT_ACTION_REQUEST, sendAddAccountAction),
    takeLatest(types.CANCEL_DEBIN_REQUEST, cancelDebinRequest),
    takeLatest(types.LOAD_INCOMING_DEBIN_LIST_REQUEST, loadIncomingDebinList),
    takeLatest(types.REMOVE_SELLER_ACCOUNT_REQUEST, removeSellerAccount),
    takeLatest(types.ACCEPT_INCOMING_DEBIN_PRE_REQUEST, acceptIncomingDebinPreRequest),
    takeLatest(types.ACCEPT_INCOMING_DEBIN_PRE_REQUEST_REDIRECT, acceptIncomingDebinPreRequestRedirect),
    takeLatest(types.DEBIN_SEND_REQUEST, sendDebinCreateRequest),
    takeLatest(types.SEND_DEBIN_PRE_REQUEST, sendDebinCreatePreRequest),
    takeLatest(types.ACCEPT_INCOMING_DEBIN_SEND_REQUEST, acceptIncomingDebinSendRequest),
    takeLatest(types.DECLINE_INCOMING_DEBIN_SEND_REQUEST, declineIncomingDebinSendRequest),
    takeLatest(types.SEND_CREDIN_PRE_REQUEST, sendCredinCreatePreRequest),
    takeLatest(types.SEND_CREDIN_REQUEST, sendCredinCreateRequest),
    takeLatest(types.GET_SELLER_DEBIN_DETAIL_REQUEST, getSellerDebinDetail),
    takeLatest(types.DECLINE_FIXED_TERM_SEND_REQUEST, declineFixedTermSendRequest),
    takeLatest(types.DEBIN_PRE_REQUEST, debinPreRequest),
    takeLatest(types.DEBIN_SELLER_CUIT, getSellerInfo),
    takeLatest(types.RELATE_BUYER_RECURRENCY, relateBuyerRecurrency),
    takeLatest(types.DEBIN_RECURRENCIES, getDebinRecurrencies),
    takeLatest(types.SET_UPDATE_RECURRENCY_STATE, updateRecurrencyState),
    takeLatest(types.SET_IS_BACK_FROM_SUMMARY, setIsBackFromSummary),
    takeLatest(types.PUTNOTIFICATION, putNotification),
];

export default sagas;

function* updateRecurrencyState({ debin, setSubmitting }) {
    try {
        const response = yield call(setUpdateRecurrencyState, debin);
        const debinStateMessage = debin?.action?.toLowerCase();

        if (response.type === "W" && response.data.code !== "API516W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
        } else if (response.data.code === "COR023I") {
            yield put({
                type: types.CLEAN_DEBIN_RECURRENCIES,
            });

            yield put(routerActions.push("/payments/recurrent"));
            yield put(
                notificationActions.showNotification(
                    i18n.get(`debin.recurrency.${debinStateMessage}.pending`),
                    "warning",
                    ["debin.recurrency"],
                ),
            );
        } else if (response.data.code === "API516W") {
            const transferFunctionalities = configUtils.getBoolean(
                "frontend.show.failedVoucher.transfersFunctionalities",
                false,
            );
            const recurringDebinV3 = configUtils.getBoolean("frontend.show.RecurringDebin.functionalities", false);
            let notificationMessage;
            if (transferFunctionalities || recurringDebinV3) {
                const error = response.data.data.voucherErrorCode;
                const message = response.data.data.voucherErrorMessage;
                notificationMessage = i18n.get("notification.snackbar.failed.voucher", null, {
                    VOUCHER_MESSAGE: message,
                    VOUCHER_ERROR: error,
                });
            } else {
                notificationMessage = i18n.get("debin.recurrencia.generic.error");
            }
            yield put(routerActions.push("/payments/recurrent"));
            yield put(notificationActions.showNotification(notificationMessage, "error", ["debin.recurrency"]));
        } else {
            yield put({
                type: types.CLEAN_DEBIN_RECURRENCIES,
            });
            yield put(routerActions.push("/payments/recurrent"));

            yield put(
                notificationActions.showNotification(
                    i18n.get(`debin.recurrency.${debinStateMessage}.success`),
                    "success",
                    ["debin.recurrency"],
                ),
            );
        }
    } finally {
        setSubmitting(false);
    }
}

function* getDebinRecurrencies({ formFilter, setSubmitting }) {
    try {
        const response = yield call(getRecurrencies, formFilter);

        if (response.type === "W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
        } else if (response.data.data?.error) {
            yield put({
                type: types.CLEAN_DEBIN_RECURRENCIES,
            });
        } else {
            yield put({
                type: types.SET_DEBIN_RECURRENCIES,
                debinRecurrency: response.data.data?.recurrencies,
                paginas_totales: response.data.data?.paginas_totales,
            });
        }
    } finally {
        setSubmitting(false);
    }
}

function* relateBuyerRecurrency({ debinPrestacion, setSubmitting }) {
    try {
        const response = yield call(relateBuyerToRecurrency, debinPrestacion);

        if (response.type === "W" && response.data.code !== "API516W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
        } else {
            if (response.data.code !== "API516W" && response.data.code === INSUFFICIENT_SIGNATURES) {
                yield put(
                    notificationActions.showNotification(i18n.get("debin.recurrency.activate.pending"), "success", [
                        "debin.ticket",
                    ]),
                );
            } else if (response.data.code !== "API516W" && response.data.code !== INSUFFICIENT_SIGNATURES) {
                yield put(
                    notificationActions.showNotification(i18n.get("debin.recurrency.activate.success"), "success", [
                        "debin.ticket",
                    ]),
                );
            }
            yield put(
                formActions.readTransaction({
                    pathname: `/transaction/${response.data.idTransaction}`,
                    newTransaction: true,
                }),
            );
            yield take("READ_TRANSACTION_REQUEST"); // waiting until finish action
        }
    } finally {
        setSubmitting(false);
    }
}

function* getSellerInfo({ seller, page }) {
    const response = yield call(getSeller, seller);
    const FORM_ID = "debin.recurrency";
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
        yield put({ type: types.CANCEL_DEBIN_FAILURE });
    } else if (response.data.data?.error && page === FORM_ID) {
        yield put(notificationActions.showNotification(response.data.data.message, "error", ["debin"]));
        yield put({ type: types.CLEAN_DEBIN_SELLER });
    } else if (response.data.data?.error && page !== FORM_ID) {
        yield put({ type: types.CLEAN_DEBIN_SELLER });
    } else {
        yield put({
            type: types.LOAD_DEBIN_SELLER,
            seller: response.data.data?.seller,
            prestaciones: response.data.data?.prestaciones,
        });
    }
}

function* loadSentRequests({ filters }) {
    const response = yield call(loadSentRequestsMiddleware, filters);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
        yield put({ type: types.LOAD_SENT_REQUESTS_FAILURE });
    } else {
        yield put({
            type: types.LOAD_SENT_REQUESTS_SUCCESS,
            sentRequests: response.data.data.sentRequests,
            totalPages: parseInt(response.data.data.totalPages, 10),
        });
    }
}

function* sendAddAccountAction({ otp, accountToChange, userId, formikBag }) {
    const response = yield call(sendAddAccountsAction, otp, accountToChange.idProduct, userId);
    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            formikBag.setErrors(response.data.data);
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
        }
        yield put({ type: types.SEND_ADD_ACCOUNT_ACTION_FAILURE });
    } else {
        yield put(routerActions.push("/charges/debin/addAccounts"));
        yield put(
            notificationActions.showNotification(i18n.get("debin.addAccount.success.message"), "success", [
                "debinAccounts",
            ]),
        );
    }
}

function* cancelDebinRequest({ otp, debinToCancel, formikBag }) {
    const response = yield call(cancelDebin, otp, debinToCancel);

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            formikBag.setErrors(response.data.data);
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["debinCancel"]));
        }
        yield put({ type: types.CANCEL_DEBIN_FAILURE });
    } else {
        yield put({
            type: types.CANCEL_DEBIN_SUCCESS,
        });
        yield put(routerActions.push("/charges/generateDebin"));
        yield put(notificationActions.showNotification(i18n.get("debin.cancel.success.message"), "success", ["debin"]));
    }
}

function* loadIncomingDebinList({ filters }) {
    const response = yield call(loadIncomingDebinListMiddleware, filters);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
        yield put({ type: types.LOAD_INCOMING_DEBIN_LIST_FAILURE });
    } else {
        yield put({
            type: types.LOAD_INCOMING_DEBIN_LIST_SUCCESS,
            sentRequests: response.data.data.sentRequests,
            totalPages: parseInt(response.data.data.totalPages, 10),
        });
    }
}

function* removeSellerAccount({ accountToChange }) {
    const response = yield call(removeSellerAccountMiddleware, accountToChange.idProduct);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
        yield put({ type: types.REMOVE_SELLER_ACCOUNT_FAILURE });
    } else {
        yield put(routerActions.push("/charges/debin/addAccounts"));
        yield put(
            notificationActions.showNotification(i18n.get("debin.removeAccount.success.message"), "success", ["debin"]),
        );
        yield put({
            type: types.REMOVE_SELLER_ACCOUNT_SUCCESS,
        });
    }
}

function* acceptIncomingDebinPreRequest({ idDebin }) {
    const response = yield call(acceptIncomingDebinPreMiddleware, idDebin);
    if (response.data.code === "COR120I") {
        yield put(routerActions.push("/payments/payDebin"));
        yield put(notificationActions.showNotification(response.data.data.messageError, "warning", ["debin"]));
        yield put({ type: types.ACCEPT_INCOMING_DEBIN_PRE_FAILURE });
    }
    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
        yield put({ type: types.ACCEPT_INCOMING_DEBIN_PRE_FAILURE });
    } else {
        yield put({
            type: types.ACCEPT_INCOMING_DEBIN_PRE_SUCCESS,
            debinDetail: response.data.data.debinDetail,
            control: response.data?.data?.control,
        });
    }
}

function* acceptIncomingDebinPreRequestRedirect({ idDebin, route }) {
    const response = yield call(acceptIncomingDebinPreMiddleware, idDebin);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
        yield put({ type: types.ACCEPT_INCOMING_DEBIN_PRE_FAILURE });
    } else {
        yield put({
            type: types.ACCEPT_INCOMING_DEBIN_PRE_SUCCESS,
            debinDetail: response.data.data.debinDetail,
            control: response.data?.data?.control,
        });
    }

    yield put(push(route));
}

function* getSellerDebinDetail({ idDebin }) {
    const response = yield call(loadSellerDebinDetail, idDebin);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
        yield put({ type: types.GET_SELLER_DEBIN_DETAIL_FAILURE });
    } else {
        yield put({
            type: types.GET_SELLER_DEBIN_DETAIL_SUCCESS,
            debinDetail: response.data.data.debinDetail,
        });
    }
}

function* sendDebinCreateRequest({ debinSeller, recipients, otp, formikBag }) {
    const { date } = debinSeller;
    const debin = {
        expirationDate: date,
        ...debinSeller,
    };
    const response = yield call(createDebin, otp, debin, recipients);

    if (response.type === "W") {
        if (response.data.code === "COR020W") {
            formikBag.setErrors(response.data.data);
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["debinSummary"]));
        }
        yield put({ type: types.DEBIN_SEND_FAILURE });
    } else if (response.data.code === "COR120I") {
        yield put({ type: types.DEBIN_SEND_FAILURE });
        yield put(notificationActions.showNotification(response.data.data.message, "error", ["debin"]));
        yield put(routerActions.push("/charges/generateDebin"));
    } else {
        yield put({
            type: types.DEBIN_SEND_SUCCESS,
        });

        if (response.data.data.frequentDestination) {
            yield put(
                notificationActions.showNotification(i18n.get("debin.create.success.message.frequent"), "success", [
                    "debin",
                ]),
            );
        } else {
            yield put(
                notificationActions.showNotification(i18n.get("debin.create.success.message"), "success", ["debin"]),
            );
        }
        yield put(routerActions.push("/charges/generateDebin"));
    }
}

function* sendDebinCreatePreRequest({ debinSeller, cbuList }) {
    const { date, concept, cbu, amount, idAccount } = debinSeller;
    const debin = {
        expirationDate: date,
        concept,
        cbu,
        amount,
        idAccount,
    };
    const response = yield call(createDebinPre, debin, cbuList);
    if (response.type === "W" && response.data.code !== "API516W") {
        const message = response?.data?.data?.message || response?.data?.message;
        yield put(notificationActions.showNotification(message, "error", ["debin"]));
        yield put({ type: types.SEND_DEBIN_PRE_FAILURE });
    } else if (response.data.code === "COR120I") {
        yield put({ type: types.SEND_DEBIN_PRE_FAILURE });
        yield put(notificationActions.showNotification(response.data.data.message, "error", ["debin"]));
    } else {
        yield put({
            type: types.SEND_DEBIN_PRE_SUCCESS,
            recipients: response.data.data.recipients,
        });
        yield put(routerActions.push("requestDebin/summary"));
    }
}

function* sendCredinCreatePreRequest({ debinSeller, cbuList }) {
    const { concept, cbu, amount, idAccount } = debinSeller;
    const credin = {
        concept,
        cbu,
        amount,
        idAccount,
    };
    const response = yield call(createCredinPre, credin, cbuList);

    if (response.type === "W" && response.data.code !== "API516W") {
        const message = response?.data?.data?.message || response?.data?.message;
        yield put(notificationActions.showNotification(message, "error", ["credin"]));
        yield put({ type: types.SEND_CREDIN_PRE_FAILURE });
    } else if (response.data.code === "COR001E") {
        yield put({ type: types.SEND_CREDIN_PRE_FAILURE });
        yield put(notificationActions.showNotification(response.data.data.message, "error", ["credin"]));
    } else {
        yield put({
            type: types.SEND_CREDIN_PRE_SUCCESS,
            recipients: response.data.data.recipients,
            id: response.data.data.id,
            control: response.data?.data?.control,
        });
        yield put(routerActions.push("requestCredin/generateCredin"));
    }
}

function* sendCredinCreateRequest({ debinSeller, recipients, otp, id, formikBag }) {
    const { setSubmitting, setErrors } = formikBag;
    try {
        const credin = {
            ...debinSeller,
        };

        const cbuList = formikBag?.props?.cbuList;
        const response = yield call(createCredin, otp, credin, recipients, id, cbuList);

        if (response.type === "W" && response.data.code !== "API516W" && response.data.code !== "COR020W") {
            yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
            yield put({ type: types.CREDIN_SEND_FAILURE });
        } else if (response.data.code === "COR999E" || response.data.code === "COR020W") {
            yield put({ type: types.CREDIN_SEND_FAILURE });
            setErrors(response.data.data);
            if (response.data.code !== "COR020W") {
                yield put(notificationActions.showNotification(response.data.data.message, "error", ["debin"]));
            }
        } else if (recipients?.length > 1) {
            yield put(routerActions.push("/requestCredin"));

            yield put({
                type: types.CREDIN_SEND_SUCCESS,
            });
            if (response.data.code === INSUFFICIENT_SIGNATURES) {
                yield put(
                    notificationActions.showNotification(i18n.get("credin.create.pending.message"), "success", [
                        "credin",
                    ]),
                );
            } else if (response.data.data.frequentDestination) {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("credin.create.success.message.frequent"),
                        "success",
                        ["credin"],
                    ),
                );
            } else {
                yield put(
                    notificationActions.showNotification(i18n.get("credin.create.success.message"), "success", [
                        "credin",
                    ]),
                );
            }
        } else {
            yield put(
                formActions.readTransaction({
                    pathname: `/transaction/${response.data.idTransaction}`,
                    newTransaction: true,
                }),
            );
            yield take("READ_TRANSACTION_REQUEST");
            yield put(debinActions.setDebinSeller({}, []));
        }
    } finally {
        setSubmitting(false);
    }
}

function* acceptIncomingDebinSendRequest({
    currency,
    amount,
    idDebin,
    otp,
    account,
    userId,
    formikBag,
    sellerDetail,
    idVoucher,
    isOwn,
}) {
    const { setSubmitting, setErrors } = formikBag;

    try {
        const response = yield call(
            acceptIncomingDebinSendMiddleware,
            currency,
            amount,
            idDebin,
            otp,
            account?.idProduct,
            userId,
            account,
            sellerDetail,
            idVoucher,
            isOwn,
        );

        if (response.type === "W" && response.data.code !== "API516W") {
            const errorMessage =
                response.data?.data?.NO_FIELD ||
                response.data?.data?.message ||
                response.data?.message ||
                i18n.get("global.unexpectedError");
            if (response.data.code === "COR020W") {
                setErrors(response.data.data);
                yield put(notificationActions.showNotification(errorMessage, "error", ["debin"]));
            } else {
                setErrors(response.data.data);
                yield put(notificationActions.showNotification(errorMessage, "error", ["debin"]));
            }
            yield put({ type: types.ACCEPT_INCOMING_DEBIN_SEND_FAILURE });
        } else {
            yield put({
                type: types.ACCEPT_INCOMING_DEBIN_SEND_SUCCESS,
            });
            if (response.data.code !== "API516W") {
                if (response.data.code === INSUFFICIENT_SIGNATURES) {
                    const control = yield select((state) => debinSelectors.getControl(state));
                    if (!control) {
                        yield put(
                            notificationActions.showNotification(i18n.get("accept.incoming.debin.pending"), "success", [
                                "debinTransaction",
                            ]),
                        );
                    }
                } else {
                    yield put(
                        notificationActions.showNotification(i18n.get("accept.incoming.debin.success"), "success", [
                            "debinTransaction",
                        ]),
                    );
                }
            }
            yield put(
                formActions.readTransaction({
                    pathname: `/transaction/${response.data.idTransaction}`,
                    newTransaction: true,
                }),
            );
            yield take("READ_TRANSACTION_REQUEST");
        }
    } finally {
        setSubmitting(false);
    }
}
function* declineIncomingDebinSendRequest({ currency, amount, idDebin, otp, account, userId, formikBag }) {
    const { setSubmitting, setErrors } = formikBag;
    try {
        const response = yield call(
            declineIncomingDebinSendMiddleware,
            currency,
            amount,
            idDebin,
            otp,
            account?.idProduct,
            userId,
        );

        if (response.type === "W") {
            if (response.data.code === "COR020W") {
                setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["decline-debin"]));
            }
            yield put({ type: types.DECLINE_INCOMING_DEBIN_FAILURE });
        } else {
            const confirmationMessage = i18n.get("payments.debin.decline.confirmationMessage");
            yield put({ type: types.DECLINE_INCOMING_DEBIN_SUCCESS });
            yield put(routerActions.push("/payments/payDebin"));
            yield put(notificationActions.showNotification(confirmationMessage, "success", ["debin"]));
        }
    } finally {
        setSubmitting(false);
    }
}
function* declineFixedTermSendRequest({ currency, amount, idDebin, otp, account, userId, formikBag }) {
    const { setSubmitting, setErrors } = formikBag;
    try {
        const response = yield call(
            declineIncomingDebinSendMiddleware,
            currency,
            amount,
            idDebin,
            otp,
            account.idProduct,
            userId,
        );

        if (response.type === "W") {
            if (response.data.code === "COR020W") {
                setErrors(response.data.data);
            } else {
                yield put(notificationActions.showNotification(response.data.message, "error", ["debin"]));
            }
            yield put({ type: types.DECLINE_INCOMING_DEBIN_FAILURE });
        } else {
            yield put({
                type: types.DECLINE_INCOMING_DEBIN_SUCCESS,
            });
            const confirmationMessage = i18n.get("payments.debin.decline.confirmationMessage");
            yield put(notificationActions.showNotification(confirmationMessage, "success", ["debin"]));
            yield put(routerActions.push("/fixedTermAcceptance"));
        }
    } finally {
        setSubmitting(false);
    }
}

function* debinPreRequest() {
    const response = yield call(listConcepts);

    if (response.type === "W") {
        yield put({ type: types.DEBIN_PRE_FAILURE });
    } else {
        yield put({
            type: types.DEBIN_PRE_SUCCESS,
            concepts: response.data.data.concepts,
            accounts: response.data.data.accounts,
        });
    }
}

function* setIsBackFromSummary({ page }) {
    yield put(routerActions.push(page));
}

function* putNotification({ format }) {
    const { type, data } = yield call(session.exportTermsAndConditions, format);
    if (type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["debin"]));
        yield put({ type: types.PUTNOTIFICATION_FAILURE });
    } else {
        const { content, fileName } = data.data;
        utilDownload.downloadPdf(fileName, content);
        yield put(notificationActions.showNotification(i18n.get("global.download.success"), "success", ["debin"]));
        yield put({ type: types.PUTNOTIFICATION_SUCCESS });
    }
}
