import React from "react";
import { func, arrayOf, shape, bool } from "prop-types";
import Col from "react-bootstrap/lib/Col";

import * as i18n from "util/i18n";
import getRescueDeadlineLabel from "util/fundcorp";

import Table from "pages/_components/Table";
import Heading from "pages/_components/Heading";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import * as configUtils from "util/config";

function FundFeaturesTableContent({ onRowClick, fundFeatures, isDesktop }) {
    const generateTableHeader = [
        {
            align: "left",
            key: "funds",
            dataIndex: "funds",
            title: i18n.get("fund.table.header.label.funds"),
            width: "15%",
        },
        {
            align: "center",
            key: "description",
            dataIndex: "description",
            title: i18n.get("fund.table.header.label.description"),
            width: "20%",
        },
        {
            align: "center",
            key: "horizon",
            dataIndex: "horizon",
            title: i18n.get("fund.table.header.label.horizon"),
            width: "15%",
        },
        {
            align: "center",
            key: "rescueTerm",
            dataIndex: "rescueTerm",
            title: i18n.get("fund.table.header.label.rescueTerm"),
            width: "20%",
        },
        {
            align: "center",
            key: "riskLevel",
            dataIndex: "riskLevel",
            title: i18n.get("fund.table.header.label.riskLevel"),
            width: "20%",
        },
        {
            align: "center",
            key: "info",
            dataIndex: "info",
            title: i18n.get("fundcorp.subscribe.detail.label"),
            width: "20%",
        },
    ];

    const openUrl = (url, e) => {
        e.stopPropagation();
        if (window.cordova && window.cordova.InAppBrowser) {
            window.cordova.InAppBrowser.open(url, "_system", "hidden=no,location=no");
        } else {
            window.open(url, "_blank");
        }
    };

    const fundUrlList = JSON.parse(configUtils.get("frontend.investment.funds.parameters.extension1"));

    const fundFeaturesWithFile = fundFeatures.map((fundFeature) => ({
        ...fundFeature,
        fileUrl: fundUrlList[`fondo${fundFeature.numero}`]?.reportUrl || null,
    }));

    return (
        <Col xs={12} className={isDesktop ? "px-4" : "px-0 h-100"}>
            <div>
                {isDesktop ? (
                    <Table className="container-white mt-5">
                        {generateTableHeader.map((head) => (
                            <Table.HeaderData key={head.key} align={head.align} width={head.width}>
                                <span>{head.title}</span>
                            </Table.HeaderData>
                        ))}
                        {fundFeaturesWithFile &&
                            fundFeaturesWithFile.map((fundFeature) => (
                                <Table.Row
                                    key={fundFeature.numero}
                                    onClick={() => {
                                        onRowClick(fundFeature);
                                    }}>
                                    <Table.Data className="table-data-cell py-3 pl-3" align="left">
                                        <span className="font-light">{fundFeature.nombre}</span>
                                    </Table.Data>
                                    <Table.Data className="table-data-cell py-3" align="center">
                                        <span className="font-light text-capitalize">{`${i18n.get(
                                            "fund.info.label.rentaFija",
                                        )} ${fundFeature.monedaDescripcion}`}</span>
                                    </Table.Data>
                                    <Table.Data className="table-data-cell py-3" align="center">
                                        <span className="font-light">{fundFeature.horizonteInversionDescripcion}</span>
                                    </Table.Data>
                                    <Table.Data className="table-data-cell py-3" align="center">
                                        <span className="font-light">
                                            {`${i18n.get("rescue.fund.key.helper")} ${getRescueDeadlineLabel(
                                                fundFeature.rescatePlazo,
                                            )}`}
                                        </span>
                                    </Table.Data>
                                    <Table.Data className="table-data-cell  py-3" align="center">
                                        <span className="font-light word-break-all">
                                            {fundFeature.regionDescripcion}
                                        </span>
                                    </Table.Data>
                                    <Table.Data className="table-data-cell py-3" align="right">
                                        {fundFeature.fileUrl ? (
                                            <Button
                                                onClick={(e) => {
                                                    openUrl(fundFeature.fileUrl, e);
                                                }}
                                                rel="noopener noreferrer"
                                                label="fundcorp.subscribe.detail.button.label"
                                                className="btn btn-outline fundcorp__btn-detail"
                                                image="images/arrow-right.svg"
                                            />
                                        ) : (
                                            <Button
                                                label="fundcorp.subscribe.detail.button.label"
                                                className="btn btn-outline fundcorp__btn-detail"
                                                image="images/arrow-right.svg"
                                                disabled
                                            />
                                        )}
                                    </Table.Data>
                                </Table.Row>
                            ))}
                    </Table>
                ) : (
                    fundFeaturesWithFile.map((fundFeature) => (
                        <Container
                            key={fundFeature.numero}
                            role="button"
                            onClick={() => {
                                onRowClick(fundFeature);
                            }}
                            className="align-items-center flex-grow container-white py-2 my-2 container-details">
                            <Col className="col col-12">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                                    label="fund.table.header.label.funds"
                                    labelClassName="mb-0"
                                    data={<span>{`${fundFeature.nombre}`}</span>}
                                />
                            </Col>
                            <Col className="col col-12">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                                    label="fund.table.header.label.description"
                                    labelClassName="mb-0"
                                    data={<span>{`${fundFeature.monedaDescripcion}`}</span>}
                                />
                            </Col>
                            <Col className="col col-12">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                                    label="fund.table.header.label.horizon"
                                    labelClassName="mb-0"
                                    data={<span>{`${fundFeature.horizonteInversionDescripcion}`}</span>}
                                />
                            </Col>
                            <Col className="col col-12">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                                    label="fund.table.header.label.rescueTerm"
                                    labelClassName="mb-0"
                                    data={
                                        <span>{`${i18n.get("rescue.fund.key.helper")} ${getRescueDeadlineLabel(
                                            fundFeature.rescatePlazo,
                                        )}`}</span>
                                    }
                                />
                            </Col>
                            <Col className="col col-12">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                                    label="fund.table.header.label.riskLevel"
                                    labelClassName="mb-0"
                                    data={<span>{`${fundFeature.regionDescripcion}`}</span>}
                                />
                            </Col>
                            <Col className="col col-12">
                                <Heading.DataGroup
                                    containerClassName="transfer-data data-wrapper-flex space-between mb-0"
                                    label="fundcorp.subscribe.detail.label"
                                    labelClassName="mb-0"
                                    data={
                                        fundFeature.fileUrl ? (
                                            <Button
                                                onClick={(e) => {
                                                    openUrl(fundFeature.fileUrl, e);
                                                }}
                                                rel="noopener noreferrer"
                                                label="fundcorp.subscribe.detail.button.label"
                                                className="btn btn-outline fundcorp__btn-detail m-0"
                                                image="images/arrow-right.svg"
                                            />
                                        ) : (
                                            <Button
                                                label="fundcorp.subscribe.detail.button.label"
                                                className="btn btn-outline fundcorp__btn-detail m-0"
                                                image="images/arrow-right.svg"
                                                disabled
                                            />
                                        )
                                    }
                                />
                            </Col>
                        </Container>
                    ))
                )}
            </div>
        </Col>
    );
}

FundFeaturesTableContent.propTypes = {
    onRowClick: func.isRequired,
    isDesktop: bool.isRequired,
    fundFeatures: arrayOf(shape({})).isRequired,
};

export default FundFeaturesTableContent;
