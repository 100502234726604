import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { getLang } from "util/i18n";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { func, shape, bool } from "prop-types";
import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Logo from "pages/login/_components/Logo";
import classNames from "classnames";
import ReactHtmlParser from "react-html-parser";
import Dropdown from "pages/_components/Dropdown";
import I18n from "./I18n";
import Notification from "./Notification";

class TermsAndConditions extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        hasActiveSession: bool,
        history: shape({ goBack: func }),
        isFetching: bool.isRequired,
        isMobile: bool,
        propsGrid: shape({}).isRequired,
        termsAndConditions: shape({}),
        fetchingDownload: bool.isRequired,
    };

    static defaultProps = {
        hasActiveSession: false,
        history: null,
        isMobile: false,
        termsAndConditions: null,
    };

    componentDidMount = () => {
        const { dispatch } = this.props;
        dispatch(settingsActions.getTermsAndConditions());
    };

    handleDownload = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.downloadDocumentRequest({ format: "pdf" }));
    };

    backButtonAction = () => {
        const { dispatch, hasActiveSession, history } = this.props;
        return hasActiveSession ? history.goBack() : dispatch(push("/"));
    };

    rightContentMobile = () => {
        const { isFetching } = this.props;

        return (
            <div className="d-flex">
                <Button
                    bsStyle="link"
                    image="images/download_bold.svg"
                    disabled={isFetching}
                    onClick={() => this.handleDownload()}
                />
                <Button
                    className="view-close"
                    bsStyle="link"
                    image="images/cross-mobile-blue.svg"
                    onClick={this.backButtonAction}
                />
            </div>
        );
    };

    renderTitleMobile = () => <Logo className="svg-image logo logo-mobile-size" logoColor />;

    getContent = () => {
        const { termsAndConditions } = this.props;

        return (
            <div className="terms_wrapper scrollable-content border-top max-width-600 mx-auto">
                {termsAndConditions ? (
                    <div> {ReactHtmlParser(termsAndConditions)} </div>
                ) : (
                    <I18n
                        componentProps={{ className: "mt-0 break-word" }}
                        component="p"
                        id="global.termsAndCondiciones.message"
                    />
                )}
            </div>
        );
    };

    renderContent = () => {
        const { isFetching, isMobile, fetchingDownload } = this.props;

        return (
            <>
                <PageLoading
                    className={classNames("line-loader", {
                        "flex-grow-1": !isMobile,
                        "mt-5": isMobile,
                    })}
                    loading={isFetching}>
                    <div
                        className={classNames({
                            "pr-0": !isMobile,
                        })}>
                        {this.getContent(getLang())}
                        {!isMobile && (
                            <div className="d-flex justify-content-end max-width-600 mx-auto mt-3">
                                <Dropdown
                                    image="images/download_bold.svg"
                                    imageStyle="mr-2"
                                    buttonClass="min-width-14"
                                    label="global.download"
                                    bsStyle="outline"
                                    fetching={fetchingDownload}
                                    pullDown>
                                    <Button
                                        onClick={() => this.handleDownload()}
                                        label="accounts.pdfFile"
                                        className="dropdown__item-btn"
                                        bsStyle="link"
                                    />
                                </Dropdown>
                            </div>
                        )}
                    </div>
                </PageLoading>
            </>
        );
    };

    render() {
        const { hasActiveSession, isFetching, isMobile, propsGrid } = this.props;

        return hasActiveSession ? (
            <>
                <Notification scopeToShow="settings" />
                <div className="admin-detail-head px-0">
                    <Head onBack={this.backButtonAction} />
                    <Head title="login.step4.termsAndCondiciones.label" titleClassName="mx-auto" />
                </div>
                {this.renderContent()}
            </>
        ) : (
            <GridLayoutExternal isDesktop={!isMobile} {...propsGrid} scopeToShow="settings" showLogoState>
                <Head
                    title={!isMobile && "login.step4.termsAndCondiciones.label"}
                    titleClassName="mb-4"
                    onClose={!isMobile && this.backButtonAction}
                    headerClassName={classNames({
                        "pr-0 recover-pass-desktop": !isMobile,
                        "login-background-color-white": isMobile,
                    })}
                    centerElement={isMobile && this.renderTitleMobile}
                    rightContent={isMobile && this.rightContentMobile}
                    hideMobileMenu={isMobile && true}
                    fixed
                    isFetching={isFetching}
                />
                {isMobile && (
                    <I18n
                        id="login.step4.termsAndCondiciones.label"
                        accessibilityTextId="login.step4.termsAndCondiciones.label.a11yss"
                        componentProps={{ className: "modal-header text-center" }}
                        component="h2"
                    />
                )}
                {this.renderContent()}
            </GridLayoutExternal>
        );
    }
}

const mapStateToProps = (state) => ({
    isFetching: settingsSelectors.isFetching(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    termsAndConditions: settingsSelectors.getTermsAndConditions(state),
    fetchingDownload: loginSelectors.getFetchingDownload(state),
});

export default withRouter(connect(mapStateToProps)(TermsAndConditions));
