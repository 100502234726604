import React, { Component } from "react";
import { oneOfType, node, string, bool, func } from "prop-types";
import classNames from "classnames";

import Image from "pages/_components/Image";

class Radio extends Component {
    static propTypes = {
        checked: bool.isRequired,
        label: oneOfType([string, node]).isRequired,
        value: string.isRequired,
        mode: string,
        inLineControl: bool,
        name: string.isRequired,
        onChange: func.isRequired,
        id: string.isRequired,
        textAlign: string,
        maskedData: string,
        radioClassName: string,
    };

    static defaultProps = {
        inLineControl: false,
        mode: "edit",
        textAlign: "",
        maskedData: "",
        radioClassName: "",
    };

    render() {
        const {
            id,
            inLineControl,
            value,
            name,
            checked,
            onChange,
            label,
            mode,
            textAlign,
            maskedData,
            radioClassName,
        } = this.props;

        if (mode !== "edit" && !checked) {
            return null;
        }

        if (mode !== "edit") {
            return <div>{label}</div>;
        }

        return (
            <div
                className={classNames(
                    "c-control c-control--has-icon c-control--radio",
                    {
                        "c-control-block": !inLineControl,
                    },
                    radioClassName,
                )}>
                <input
                    className="c-control-input"
                    checked={checked}
                    id={id}
                    type="radio"
                    value={value}
                    onChange={onChange}
                    name={name}
                />
                <label className={classNames("c-control-label", textAlign)} htmlFor={id}>
                    <div className="c-control-icons">
                        <div className="c-control-mark">
                            <Image src="images/radioButtonMark.svg" className="svg-icon svg-caret" />
                        </div>
                    </div>
                    <span className="font-light">{label}</span>
                    <span className="font-regular">{maskedData}</span>
                </label>
            </div>
        );
    }
}

export default Radio;
