import React, { Component } from "react";
import { node, shape, string, func } from "prop-types";
import classNames from "classnames";

class TableData extends Component {
    static propTypes = {
        children: node,
        align: string,
        className: string,
        width: string,
        moreStyles: shape({}),
        onClick: func,
    };

    static defaultProps = {
        children: null,
        align: "center",
        className: "",
        width: "",
        moreStyles: {},
        onClick: null,
    };

    render() {
        const { children, align, className, width, moreStyles, onClick } = this.props;
        return (
            <div
                className={classNames("table-data", `text-${align}`, className)}
                style={{ width, ...moreStyles }}
                onClick={onClick}>
                {children}
            </div>
        );
    }
}

export default TableData;
