/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { string, bool, func, shape } from "prop-types";

import { selectors as communicationsSelectors } from "reducers/communications";
import { selectors as communicationSelectors } from "reducers/communication";

import Compose from "pages/communications/_components/Compose";
import Reader from "pages/communications/_components/Reader";

class LeftPanel extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        preloadedMessage: shape({}).isRequired,
        leftPanel: string.isRequired,
        close: func.isRequired,
        isModalVisible: func.isRequired,
        handleModalDelete: func.isRequired,
    };

    render() {
        const { isDesktop, preloadedMessage, leftPanel, close, isModalVisible, handleModalDelete } = this.props;

        return (
            (leftPanel === "compose" && (
                <Compose preloadedMessage={preloadedMessage} isDesktop={isDesktop} onCloseClick={close} />
            )) ||
            (leftPanel === "reply" && <Compose isDesktop={isDesktop} isReply onCloseClick={close} />) ||
            (leftPanel === "read" && (
                <Reader isModalVisible={isModalVisible} isDesktop={isDesktop} handleModalDelete={handleModalDelete} />
            ))
        );
    }
}

const mapStateToProps = (state) => ({
    leftPanel: communicationsSelectors.leftPanel(state),
    preloadedMessage: communicationSelectors.getPreloadedMessage(state),
});
export default connect(mapStateToProps)(LeftPanel);
