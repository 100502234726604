import { useCallback, useEffect, useState } from "react";
import { getCurrencyIso } from "util/cbuCmf";

function useValidateDebitMonetaryAccount(fund, shareAccount) {
    const [validMonetaryAccount, setValidMonetaryAccount] = useState({});

    const validateMonetaryAccount = useCallback(() => {
        if (!fund || !shareAccount || !shareAccount?.monetaryAccounts) {
            setValidMonetaryAccount({});
        } else {
            const validMonetaryDebitAccount = shareAccount.monetaryAccounts.find(
                (monetaryAccount) =>
                    ![monetaryAccount.monedaIso, getCurrencyIso(monetaryAccount.cbu)].some((c) => c !== fund.moneda),
            );
            setValidMonetaryAccount(validMonetaryDebitAccount);
        }
    }, [fund, shareAccount]);

    useEffect(() => {
        validateMonetaryAccount();
    }, [validateMonetaryAccount]);

    return { monetaryAccount: validMonetaryAccount };
}

export default useValidateDebitMonetaryAccount;
