import React, { Component } from "react";
import { func, bool, shape, string } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import Yup from "yup";
import { push } from "react-router-redux";
import { Col } from "react-bootstrap";
import { Field, Form, withFormik } from "formik";

import Head from "pages/_components/Head";
import PageLoading from "pages/_components/PageLoading";
import Hint from "pages/_components/hints/Hint";
import TextField from "pages/_components/fields/TextField";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import Selector from "../_components/fields/formik/Selector";
import { saveDraft } from "middleware/form";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as formActions } from "reducers/form";
import { actions as transactionsActions } from "reducers/transactions";

import * as i18n from "util/i18n";

const FORM_ID = "accounts.new.account";

class AccountsOpening extends Component {
    static propTypes = {
        isDesktop: bool,
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        openingData: shape({
            returnToPending: bool.isRequired,
            idTransaction: string.isRequired,
        }).isRequired,
        values: shape({}).isRequired,
        location: shape({}).isRequired,
        isRetailEnvironment: bool.isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    componentWillMount = () => {
        const { dispatch, location } = this.props;
        if (location.pathname === "/accountOpening") {
            dispatch(accountsActions.clearOpeningData());
        }
        dispatch(transactionsActions.saveTransactionRoute(location?.pathname))
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/accounts"));
    };

    saveDraft = () => {
        const { dispatch, values, openingData } = this.props;
        dispatch(
            formActions.saveDraft({
                idActivityDraft: "accounts.opening",
                data: {
                    ...values,
                },
                idTransaction: openingData.returnToPending ? openingData.idTransaction : null,
            }),
        );
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 className="w-100 m-0 justify-content-center">{i18n.get("new.account")}</h1>
        </div>
    );

    getAccountOptions = () => {
        const { isRetailEnvironment } = this.props;

        const specialAccount = isRetailEnvironment
            ? {
                  value: 7,
                  label: i18n.get("accounts.new.account.accountType.7"),
              }
            : {
                  value: 5,
                  label: i18n.get("accounts.new.account.accountType.5"),
              };

        return [
            specialAccount,
            {
                value: 3,
                label: i18n.get("accounts.new.account.accountType.3"),
            },
        ];
    };

    render() {
        const { isDesktop, isSubmitting, openingData } = this.props;

        return (
            <PageLoading loading={false}>
                <div className={isDesktop ? "admin-detail-head px-0" : ""}>
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                    />
                    {isDesktop && (
                        <div className="view-title mb-4">
                            <h1>{i18n.get(openingData?.returnToPending ? "modify.account" : "new.account")}</h1>
                        </div>
                    )}
                </div>
                <MainContainer>
                    <Form checkUrl={this.fillFields} className="above-the-fold">
                        <Container
                            className={`${isDesktop ? "align-items-center cmf-container-white " : ""} pt-3 pb-3`}>
                            <Col sm={12} md={8} lg={6}>
                                <Col sm={12} md={6} lg={6} className="col-no-pad-mobile">
                                    <Field
                                        idForm={FORM_ID}
                                        name="accountType"
                                        component={Selector}
                                        options={this.getAccountOptions()}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={6} className="col-no-pad-mobile">
                                    <Field
                                        idForm={FORM_ID}
                                        name="currency"
                                        component={Selector}
                                        options={[
                                            { value: 0, label: i18n.get("accounts.new.account.currency.0") },
                                            { value: 2, label: i18n.get("accounts.new.account.currency.2") },
                                        ]}
                                    />
                                </Col>

                                <Col sm={12} md={12} className="col-no-pad-mobile">
                                    <Field
                                        name="message"
                                        idForm={FORM_ID}
                                        component={TextField}
                                        className="message-content"
                                        renderAs="textarea"
                                        optional={i18n.get(`${FORM_ID}.description.optional.text`)}
                                        mode="edit"
                                        hidePlaceholder
                                        maxLength="500"
                                    />
                                </Col>
                                <Col sm={12} md={12} className="col-no-pad-mobile">
                                    <Hint
                                        idMessage={`${FORM_ID}.hint`}
                                        styleText={{ maxWidth: "100%" }}
                                        styleImage={{ alignSelf: "center", display: "flex" }}
                                        classNameHintP="hint-font-size-small my-auto"
                                        alwaysShowMessage
                                    />
                                </Col>
                            </Col>
                        </Container>
                        <Container className={isDesktop ? "align-items-center  " : ""}>
                            <Col sm={12} md={8} lg={4}>
                                <Button
                                    bsStyle="primary"
                                    label="global.continue"
                                    loading={isSubmitting}
                                    type="submit"
                                />
                            </Col>
                        </Container>
                    </Form>
                </MainContainer>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    openingData: accountsSelectors.getAccountOpeningData(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
});
export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: (props) => ({
            accountType: props.location.pathname !== "/accountOpening" ? props.openingData?.accountType : "",
            currency: props.location.pathname !== "/accountOpening" ? props.openingData?.currency : "",
            message: props.location.pathname !== "/accountOpening" ? props.openingData?.message : "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                accountType: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
                currency: Yup.string().required(i18n.get(`${FORM_ID}.field.error.required`)),
            }),
        handleSubmit: ({ accountType, currency, message }, formikBag) => {
            const { dispatch, openingData } = formikBag.props;
            dispatch(
                accountsActions.accountOpeningData({
                    accountType,
                    currency,
                    message,
                    idTransaction: openingData?.idTransaction,
                }),
            );
            if (openingData?.returnToPending) {
                saveDraft();
            }
            dispatch(push("/accountOpening/summary"));
        },
    }),
)(AccountsOpening);
