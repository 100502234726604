import React, { Component } from "react";
import { shape, bool } from "prop-types";
import I18n from "pages/_components/I18n";
import FieldLabel from "pages/_components/fields/FieldLabel";
import Row from "react-bootstrap/lib/Row";
import FormattedDate from "pages/_components/FormattedDate";
import UserSignatures from "pages/forms/UserSignatures";
import UserLiberator from "pages/forms/UserLiberator";
import UserCancelled from "pages/forms/UserCancelled";
import moment from "moment";
import Col from "react-bootstrap/lib/Col";

class TransactionsSignatures extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    dateTimeCreationIsDifferentFromModify = (creation, modification) => {
        const creation_ = moment(creation);
        const modification_ = moment(modification);
        return modification_.diff(creation_, "seconds") > 1;
    };

    render() {
        const { transactionData, isDesktop } = this.props;

        const showModifiedBy = this.dateTimeCreationIsDifferentFromModify(
            transactionData?.creationDateTime,
            transactionData?.modificationDateTime,
        );

        const userEditorFullName = `${transactionData?.userEditorLastName} ${transactionData?.userEditorFirstName}`;
        const userCreatorFullName = `${transactionData?.userCreatorLastName} ${transactionData?.userCreatorFirstName}`;

        return (
            transactionData?.idTransaction && (
                <>
                    <Row className="mx-0">
                        <I18n
                            component="h2"
                            id="accounts.new.account.signatures.label"
                            componentProps={{ className: "font-size-28-px" }}
                        />
                    </Row>
                    <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                        <Row style={{ justifyContent: "space-between" }}>
                            <FieldLabel labelKey="accounts.new.account.signatures.preparedBy.label" notMarginY />
                            <p className="data-desc">
                                {userCreatorFullName} -
                                <FormattedDate
                                    date={transactionData?.creationDateTime}
                                    dateFormat="dd/MM/yyyy"
                                    showTime
                                    className="pl-1"
                                />
                            </p>
                        </Row>
                    </Col>
                    {showModifiedBy && (
                        <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                            <Row style={{ justifyContent: "space-between" }}>
                                <FieldLabel labelKey="accounts.new.account.signatures.modifiedBy.label" notMarginY />
                                <p className="data-desc">
                                    {userEditorFullName} -
                                    <FormattedDate
                                        date={transactionData?.modificationDateTime}
                                        dateFormat="dd/MM/yyyy"
                                        showTime
                                    />
                                </p>
                            </Row>
                        </Col>
                    )}
                    {transactionData?.signatures?.length > 0 && (
                        <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                            <UserSignatures
                                title="accounts.new.account.signatures.signBy.label"
                                list={transactionData?.signatures}
                                isDesktop={isDesktop}
                            />
                        </Col>
                    )}
                    {transactionData?.idTransactionStatus === "CANCELLED" && (
                        <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                            <UserCancelled
                                userEditorFullName={transactionData?.data?.cancelledUser}
                                modificationDateTime={transactionData?.data?.cancelledTime}
                                isDesktop={isDesktop}
                            />
                        </Col>
                    )}
                    {transactionData?.data?.liberatorUser && transactionData?.idTransactionStatus === "FINISHED" && (
                        <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                            <UserLiberator
                                userEditorFullName={transactionData?.data?.liberatorUser}
                                modificationDateTime={transactionData?.data?.liberatorTime}
                                isDesktop={isDesktop}
                            />
                        </Col>
                    )}
                </>
            )
        );
    }
}

export default TransactionsSignatures;
