/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { selectors } from "reducers/checks";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { arrayOf, string, shape, func, bool } from "prop-types";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";
import FormattedDate from "pages/_components/FormattedDate";
import InfoTag from "pages/_components/InfoTag";
import * as i18n from "util/i18n";
import { echeqShape } from "util/checks";
import * as accountUtils from "util/accounts";
import * as checkUtils from "util/checks";
import { Link } from "react-router-dom";
import Spinner from "pages/_components/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

const FORM_ID = "echeq";

class BatchList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        batchList: arrayOf(echeqShape).isRequired,
        loggedUser: shape({
            userId: string.isRequired,
        }).isRequired,
        isDesktop: bool.isRequired,
        hasMoreData: bool.isRequired,
        fetchNextTransactions: func.isRequired,
        accounts: arrayOf(
            shape({
                cbu: string.isRequired,
            }),
        ).isRequired,
    };

    componentDidMount() {
        const { dispatch, accounts, loggedUser } = this.props;

        if (!accounts.length > 0) {
            dispatch(accountsActions.listAccounts(loggedUser.userId));
        }
    }

    cardsBatchEcheqs = () => {
        const { batchList, accounts } = this.props;

        return batchList.map((object) => {
            const accountOrigin = accounts.find((account) => object.cbuOrigin === account?.cbu);
            const accountName = accountOrigin?.number ? accountUtils.getAccountDescription(accountOrigin) : "-";
            return (
                <CardContainer
                    key={object.idBatch}
                    renderAs={Link}
                    to={object?.transactionId ? `/echeqs/emit/massive/${object?.transactionId}/detail` : "#!"}>
                    <Card
                        title={
                            <div className="echeqs__cards-title">
                                <div
                                    className={
                                        object.reference === ""
                                            ? "d-flex echeqs__batch-info no-separator"
                                            : "d-flex echeqs__batch-info"
                                    }>
                                    <I18n
                                        id={`${FORM_ID}.batch.table.header.processDate`}
                                        componentProps={{ className: "echeqs__emittedDate" }}
                                    />
                                    <FormattedDate showTime={false} date={object.processDate} />
                                </div>
                                <div className="d-flex echeqs__batch-info-secondary">
                                    {object.reference !== "" && (
                                        <I18n
                                            id={`${FORM_ID}.batch.table.header.reference`}
                                            componentProps={{ className: "echeqs__paymentDate" }}
                                        />
                                    )}
                                    <span>{object.reference}</span>
                                </div>
                            </div>
                        }
                        content={
                            <div className="echeqs__cards-content">
                                <I18n
                                    component="span"
                                    id={`${FORM_ID}.batch.table.header.batchId`}
                                    componentProps={{ className: "echeqs__batchId" }}
                                />
                                <span>{object.idBatch}</span>
                                <div className="echeqs__type">
                                    <I18n
                                        id={`${FORM_ID}.batch.table.header.echeqQuantity`}
                                        componentProps={{ className: "echeqs__quantity" }}
                                    />
                                    <span>{object.quantity}</span>
                                </div>
                            </div>
                        }
                        bottom={
                            <div className="echeqs__cards-bottom">
                                <div className="echeqs__batch-emitted">
                                    <I18n
                                        id={`${FORM_ID}.batch.table.header.emitAccount`}
                                        componentProps={{ className: "echeqs__received-title" }}
                                    />
                                    <span>{accountName}</span>
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="echeqs__cards-rightContent">
                                <div className="echeqs__cards-right-info">
                                    <div className="echeqs__cards-amount">
                                        <I18n
                                            id={`${FORM_ID}.batch.table.header.totalAmount`}
                                            componentProps={{ className: "echeqs__total-amount-label" }}
                                        />
                                        <FormattedAmount
                                            currency={i18n.get("currency.label.ARS")}
                                            quantity={object.totalAmount}
                                        />
                                    </div>
                                    <InfoTag
                                        type="info"
                                        message={i18n.get(`echeq.batch.status.${object.status.toLowerCase()}`)}
                                        tagBackground={checkUtils.statusTagColorBatch(object.status)}
                                        moreStyles={{
                                            color: "black",
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            );
        });
    };

    echeqsListMobile = (echeq) => {
        const { accounts, isDesktop } = this.props;

        const { idBatch, transactionId, status, cbuOrigin, reference, processDate, quantity, totalAmount } = echeq;
        const accountOrigin = accounts.find((account) => cbuOrigin === account?.cbu);
        const accountName = accountOrigin?.number ? accountUtils.getAccountDescription(accountOrigin) : "-";

        return (
            <CardContainer
                renderAs={Link}
                to={transactionId ? `/echeqs/emit/massive/${transactionId}/detail` : "#!"}
                className="px-3">
                <Card
                    hrClass="ml-2"
                    title={
                        <div className="d-flex w-100 space-between echeqs__cards-title">
                            <div className="d-flex w-100">
                                <I18n
                                    id={`${FORM_ID}.batch.table.header.batchId`}
                                    componentProps={{ className: "ml-2 echeq__info" }}
                                />
                                <span className="echeq__data">{idBatch}</span>
                            </div>
                        </div>
                    }
                    content={
                        <div>
                            <hr className="ml-2" />
                            <div className="echeqs__cards-content">
                                <div className="d-flex space-between w-100 pb-1 align-items-center">
                                    <I18n id="echeqs.status.label" componentProps={{ className: "ml-2 echeq__info" }} />
                                    <InfoTag
                                        type="info"
                                        message={i18n.get(`echeq.batch.status.${status.toLowerCase()}`)}
                                        tagBackground={checkUtils.statusTagColorBatch(status)}
                                        tagClass="d-flex px-2 ml-2 my-0 mr-0"
                                        moreStyles={{
                                            color: "black",
                                        }}
                                    />
                                </div>
                                <div className="d-flex space-between w-100">
                                    <I18n
                                        id={`${FORM_ID}.batch.table.header.processDate`}
                                        componentProps={{ className: "ml-2 echeq__info" }}
                                    />
                                    <FormattedDate date={processDate} />
                                </div>
                                <div className="d-flex space-between w-100">
                                    <I18n
                                        id={`${FORM_ID}.batch.table.header.reference`}
                                        componentProps={{ className: "ml-2 echeq__info" }}
                                    />
                                    <span className="echeq__data">{reference}</span>
                                </div>
                                <div className="d-flex space-between w-100 pt-1">
                                    <I18n
                                        id={`${FORM_ID}.batch.table.header.echeqQuantity`}
                                        componentProps={{ className: "ml-2 echeq__info" }}
                                    />
                                    <span className="echeq__data">{quantity}</span>
                                </div>
                                <div className="d-flex space-between w-100 pt-1">
                                    <I18n
                                        id={`${FORM_ID}.batch.table.header.emitAccount`}
                                        componentProps={{ className: "ml-2 echeq__info" }}
                                    />
                                    <span className="echeq__data">{accountName}</span>
                                </div>
                            </div>
                        </div>
                    }
                    rightContent={
                        <div className="echeqs__cards-rightContent">
                            <div className="echeqs__cards-amount">
                                <I18n
                                    id={`${FORM_ID}.batch.table.header.totalAmount`}
                                    componentProps={{ className: "echeqs__total-amount-label" }}
                                />
                                <FormattedAmount
                                    currency={i18n.get("currency.label.0")}
                                    quantity={totalAmount}
                                    notBold={isDesktop}
                                    className="echeq__data"
                                />
                            </div>
                        </div>
                    }
                />
            </CardContainer>
        );
    };

    render() {
        const { batchList, isDesktop, hasMoreData, fetchNextTransactions } = this.props;
        const endOfListItem = (
            <div className="table-row table-end mb-5" key="noMoreTransactions">
                <I18n id="echeqs.noMoreEcheqs" />
            </div>
        );
        if (!isDesktop) {
            return (
                <>
                    <InfiniteScroll
                        dataLength={batchList.length}
                        next={fetchNextTransactions}
                        hasMore={hasMoreData}
                        loader={<Spinner />}
                        endMessage={endOfListItem}>
                        {batchList.map((echeq) => this.echeqsListMobile(echeq))}
                    </InfiniteScroll>
                </>
            );
        }

        return (
            <Container className="flex-grow" rowClassName="">
                <Col xs={12} className="px-0">
                    <div
                        style={{ borderSpacing: "0px 2px", width: "100%" }}
                        ref={(tableRef) => {
                            this.child = tableRef;
                        }}>
                        <Container className="flex-grow align-items-center" gridClassName="scrolleable form-content">
                            {this.cardsBatchEcheqs()}
                        </Container>
                    </div>
                </Col>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    batchList: selectors.getEcheqsList(state),
    hasMoreData: selectors.getHasMoreData(state),
    accounts: accountsSelectors.getAccounts(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(BatchList);
