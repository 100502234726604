import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, bool, shape } from "prop-types";
import Notification from "pages/_components/Notification";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import { Field, Form, withFormik } from "formik";
import I18n from "pages/_components/I18n";
import { compose } from "redux";
import Yup from "yup";
import Credential from "pages/_components/fields/credentials/Credential";
import { selectors as debinSelectors, actions as debinActions } from "reducers/debin";
import RecurrencySummaryData from "pages/debinPayments/_components/recurrentDebin/RecurrencySummaryData";
import isTokenActive from "util/token";
import classNames from "classnames";

const FORM_ID = "debin.recurrency";

class ConfirmRecurrencyDebin extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        debinPrestacion: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    componentWillMount = () => {
        const { debinPrestacion } = this.props;

        if (!debinPrestacion?.account) {
            this.handleBack();
        }
    };

    render() {
        const { isSubmitting, debinPrestacion, dispatch, isDesktop } = this.props;
        return (
            <div className="debin-recurrencies__add">
                <Notification scopeToShow="debin" />
                <div className={classNames({ "admin-detail-head": isDesktop }, "px-0")}>
                    {isDesktop && (
                        <Head onBack={this.handleBack} onClose={() => dispatch(push("/payments/recurrent"))} />
                    )}
                    <Head
                        title={`${FORM_ID}.load.title`}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        titleClassName={!isDesktop ? "px-2" : ""}
                        onBack={!isDesktop && this.handleBack}
                        emptyRightOption
                    />
                    <I18n
                        id={`${FORM_ID}.load.subtitle`}
                        component="p"
                        componentProps={{
                            className: "debin-recurrencies__add-confirm-subtitle ml-1 my-0",
                        }}
                    />
                </div>
                <MainContainer>
                    <div className="above-the-fold">
                        {debinPrestacion?.account && (
                            <RecurrencySummaryData debinPrestacion={debinPrestacion} isDesktop={isDesktop} />
                        )}
                        <Container>
                            <Form className="above-the-fold">
                                <Container className="flex-grow align-items-center container-white py-4">
                                    <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                        {!isTokenActive(!isDesktop) ? (
                                            <div className="admin-content-center">
                                                <I18n id="transfer.summary.confirm.label" />
                                            </div>
                                        ) : (
                                            <div className="admin-content-center pb-3">
                                                <I18n
                                                    id="confirmation.withoutotp.text"
                                                    component="p"
                                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                                />
                                            </div>
                                        )}

                                        <div className="admin-content-center">
                                            <Field
                                                name="otp"
                                                type="otp"
                                                idForm={FORM_ID}
                                                component={Credential}
                                                loading={isSubmitting}
                                                placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                            />
                                        </div>
                                    </Col>
                                </Container>
                                <Container
                                    className="flex-grow align-items-center container-white mt-0"
                                    gridClassName="form-content">
                                    <Col className="col-12" lg={4} md={9} sm={12}>
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.confirm"
                                            image="images/arrowRight.svg"
                                            imageStyle="mr-2"
                                            className="text-capitalize"
                                            loading={isSubmitting}
                                        />
                                    </Col>
                                </Container>
                            </Form>
                        </Container>
                    </div>
                </MainContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    debinPrestacion: debinSelectors.getdebinPrestacionData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: (form, formikBag) => {
            const { setSubmitting } = formikBag;
            const { dispatch, debinPrestacion } = formikBag.props;

            setSubmitting(true);

            const {
                prestacionSeleccionada,
                concept,
                description,
                account,
                reference,
                seller,
                selectedAccount,
            } = debinPrestacion;

            const objectToSend = {
                prestacion: prestacionSeleccionada.nombre,
                prestacionSeleccionada,
                concept,
                description,
                account,
                reference,
                seller: seller.cuit,
                cbu: selectedAccount.cbu,
                moneda: selectedAccount.currency,
                sellerName: seller.titular,
                debitAccount: selectedAccount,
            };

            dispatch(debinActions.relateBuyerToRecurrency({ _otp: form.otp, ...objectToSend }, setSubmitting));
        },
    }),
)(ConfirmRecurrencyDebin);
