import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { shape, func, string } from "prop-types";
import { Field } from "formik";

import { permissionsSelectors } from "reducers/administration";
import * as administrationUtils from "util/administration";
import { isEnabledFunctionality, permissionsAggrupation } from "util/permissions";
import { Col } from "react-bootstrap";
import Container from "pages/_components/Container";

import Accordion from "pages/_components/Accordion";
import IndeterminateCheckbox from "pages/_components/fields/IndeterminateCheckbox";
import PermissionsAmount from "pages/administration/_components/AdvancedPermissionsAmount";
import PermissionsList from "pages/administration/_components/AdvancedPermissionsList";
import PermissionField from "pages/administration/_components/advancedPermissionsForm/PermissionField";
import { Checkbox } from "pages/_components/fields/Checkbox";
import I18n from "pages/_components/I18n";
import { actions as permissionsActions } from "reducers/permissions";

class AdvancedPermissionsForm extends Component {
    static propTypes = {
        setValues: func.isRequired,
        groups: administrationUtils.groupsPropType.isRequired,
        mode: string.isRequired,
        // TODO this is an object with object that has object inside
        // having keys with string values, need to solve this somehow
        values: shape({
            permissions: administrationUtils.permissionsPropType,
        }),
        oldPermissions: administrationUtils.permissionsPropType.isRequired,
        navigationListItemClassName: string,
        buttonClassName: string,
        dispatch: func.isRequired,
        location: shape({
            pathname: string,
        }).isRequired,
    };

    static defaultProps = {
        values: {},
        navigationListItemClassName: "",
        buttonClassName: "",
    };

    dependsFromFields = ({ childrenList }, idItemToFind) =>
        childrenList.reduce((flattenedOptions, option) => {
            if (!option.childrenList.length) {
                if (option.dependsOn === idItemToFind) {
                    return [...flattenedOptions, option.idItem];
                }

                return flattenedOptions;
            }

            return [...flattenedOptions, ...this.dependsFromFields(option, idItemToFind)];
        }, []);

    setValues = ({ nextValue, setValues, dependsOn, values, idItem, parentOption }) => {
        let value = { [idItem]: nextValue };

        if (!nextValue.length && dependsOn) {
            value = { [idItem]: nextValue, [dependsOn]: nextValue };
        } else if (nextValue.length && !dependsOn) {
            value = [idItem, ...this.dependsFromFields(parentOption, idItem)].reduce(
                (acc, id) => ({ ...acc, [id]: nextValue }),
                {},
            );
        }

        setValues({
            ...values,
            permissions: {
                ...values.permissions,
                ...value,
            },
        });
    };

    fillValues = ({ permissionList, childrenList, idItem }) => {
        if (permissionList.length && permissionList[0].advancedAllowProductSelection) {
            return { [idItem]: permissionList[0].productTypes.split(",").map((type) => `ALL_${type}`) };
        }

        if (childrenList.length) {
            return childrenList.reduce(
                (acc, option) => ({
                    ...acc,
                    ...this.fillValues(option, acc),
                }),
                {},
            );
        }

        return { [idItem]: ["NONE"] };
    };

    handleCheckClick = (values, option, setValues, selectedOptionsAmount) => {
        const filled = this.fillValues(option);
        if (selectedOptionsAmount === 0) {
            setValues({
                ...values,
                permissions: {
                    ...values.permissions,
                    ...filled,
                },
            });
        } else {
            const keysToRemove = Object.keys(filled);

            setValues({
                ...values,
                permissions: Object.entries(values.permissions).reduce(
                    (filteredValues, [key, value]) => ({
                        ...filteredValues,
                        [key]: keysToRemove.includes(key) ? [] : value,
                    }),
                    {},
                ),
            });
        }
    };

    renderField = ({ idItem, label, number, productAlias, permissionList, ...option }) => (
        <Field name="permissions" key={idItem}>
            {({ form, field }) => {
                const value = field.value[idItem] || [];
                return (
                    <Fragment>
                        <Checkbox
                            name={idItem}
                            onChange={() => {
                                this.setValues({
                                    ...option,
                                    ...form,
                                    idItem,
                                    nextValue: value.length ? [] : ["NONE"],
                                });
                            }}
                            checked={value.length > 0}
                            formGroup={false}
                            label={label}
                        />
                    </Fragment>
                );
            }}
        </Field>
    );

    renderOptions = (option) => {
        if (option.childrenList && option.childrenList.length) {
            return (
                <Fragment key={option.idItem}>
                    {option.childrenList
                        .filter((subOption) => isEnabledFunctionality(subOption.idItem))
                        .map((subOption) => this.renderOptions({ ...subOption, parentOption: option }))}
                </Fragment>
            );
        }

        if (!option.permissionList[0].advancedAllowProductSelection) {
            return this.renderField(option);
        }

        return <PermissionField mode="edit" key={option.idItem} {...option} />;
    };

    render() {
        const {
            dispatch,
            values,
            setValues,
            groups,
            mode,
            oldPermissions,
            navigationListItemClassName,
            buttonClassName,
            location,
        } = this.props;

        const { newPermissionsToShow, removePermissionsToShow } = permissionsAggrupation(
            values.permissions,
            oldPermissions,
        );

        const editedPermissions = {
            newPermissions: newPermissionsToShow,
            removedPermissions: removePermissionsToShow,
        };

        dispatch(permissionsActions.saveEditedPermissions(editedPermissions));

        if (mode !== "edit") {
            const confirmStep1Path =
                location?.pathname === "/administration/advanced/group/create/confirm/step1" ||
                location?.pathname === "/administration/advanced/group/create/confirm/step3";
            return (
                <Container className={`background-white ${!confirmStep1Path && "pb-3"}`}>
                    <Col
                        className={confirmStep1Path ? "px-0" : "col-12 max-xl-480 px-0"}
                        md={confirmStep1Path ? 12 : 9}
                        lg={confirmStep1Path ? 12 : 6}>
                        {newPermissionsToShow && (
                            <>
                                <I18n
                                    component="h4"
                                    id="administration.configuratePermissions.confirm.added.subtitle"
                                    componentProps={{
                                        className: `form-title control-label mb-0 permisions-control-label ${!confirmStep1Path &&
                                            "mt-4"}`,
                                    }}
                                />

                                <PermissionsList permissions={newPermissionsToShow}>
                                    {(list) => {
                                        if (list) {
                                            return <Fragment>{list}</Fragment>;
                                        }

                                        return <I18n id="administration.permissions.empty" />;
                                    }}
                                </PermissionsList>
                            </>
                        )}
                        {removePermissionsToShow && (
                            <>
                                <I18n
                                    component="h4"
                                    id="administration.configuratePermissions.confirm.removed.subtitle"
                                    componentProps={{
                                        className: "form-title mt-4 control-label mb-0 permisions-control-label",
                                    }}
                                />
                                <PermissionsList permissions={removePermissionsToShow}>
                                    {(list) => {
                                        if (list) {
                                            return <Fragment>{list}</Fragment>;
                                        }

                                        return <I18n id="administration.permissions.empty" />;
                                    }}
                                </PermissionsList>
                            </>
                        )}
                    </Col>
                </Container>
            );
        }

        return (
            <PermissionsAmount permissions={values.permissions} isEnabledFunctionality={isEnabledFunctionality}>
                {(amountsById, totalAmountsById) => (
                    <div className="container-permissions" style={{ position: "relative" }}>
                        <Accordion className="list list--permissions">
                            {groups.map((group, index) => {
                                const { idItem, label, ordinal } = group;
                                const selectedOptionsAmount = amountsById[idItem] || 0;
                                const optionsAmount = totalAmountsById[idItem];
                                const options = this.renderOptions(
                                    { ...group, parentOption: { ...group }, number: index },
                                    true,
                                );
                                const quantityText = `${selectedOptionsAmount} / ${optionsAmount}`;

                                if (
                                    options.props &&
                                    (options.props.children === PermissionField.type ||
                                        typeof options.props.children === "function")
                                ) {
                                    return (
                                        <li key={idItem} className="navigational-list-item list-item--noChild px-0">
                                            {options}
                                        </li>
                                    );
                                }

                                return (
                                    <Accordion.Item
                                        key={idItem}
                                        number={index}
                                        navigationListItemClassName={navigationListItemClassName}
                                        buttonClassName={buttonClassName}
                                        fitContent
                                        item={
                                            <Fragment>
                                                <IndeterminateCheckbox
                                                    id={`${idItem}${ordinal}`}
                                                    selectedOptionsAmount={selectedOptionsAmount}
                                                    optionsAmount={optionsAmount}
                                                    onCheckClick={() =>
                                                        this.handleCheckClick(
                                                            values,
                                                            group,
                                                            setValues,
                                                            selectedOptionsAmount,
                                                        )
                                                    }
                                                />
                                                <span>
                                                    <span>{label}</span>{" "}
                                                    <span className="list-item-hint">{quantityText}</span>
                                                </span>
                                            </Fragment>
                                        }>
                                        {options}
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    </div>
                )}
            </PermissionsAmount>
        );
    }
}

const mapStateToProps = (state) => ({
    products: permissionsSelectors.getProducts(state),
    groups: permissionsSelectors.getGroups(state),
    oldPermissions: permissionsSelectors.getPermissions(state),
});

export default connect(mapStateToProps)(AdvancedPermissionsForm);
