import React, { Component } from "react";
import classNames from "classnames";
import PropTypes, { bool, node, func, string } from "prop-types";

import AccordionItemContent from "pages/_components/AccordionItemContent";
import DropdownArrow from "pages/_components/DropdownArrow";
import { AccordionContext } from "pages/_components/Accordion";

class AccordionItem extends Component {
    static propTypes = {
        item: node.isRequired,
        children: node.isRequired,
        number: PropTypes.number.isRequired,
        fitContent: bool,
        toggleAccordion: func,
        buttonClassName: string,
        navigationListItemClassName: string,
    };

    static defaultProps = {
        fitContent: false,
        toggleAccordion: null,
        buttonClassName: "",
        navigationListItemClassName: "",
    };

    handleClickAccordion = (onClick) => {
        const { toggleAccordion, number } = this.props;

        onClick(number);
        if (toggleAccordion) {
            toggleAccordion();
        }
    };

    render() {
        const { item, number, children, fitContent, buttonClassName, navigationListItemClassName } = this.props;

        return (
            <AccordionContext.Consumer>
                {({ itemOpen, onClick, expandAll, numberToExpand }) => {
                    const isActive = expandAll || itemOpen === number || numberToExpand === number;

                    return (
                        <li
                            className={classNames("navigational-list-item", navigationListItemClassName, {
                                "min-w-table-detail-echeq": !fitContent,
                            })}>
                            <button
                                className={classNames("collapsable-button", buttonClassName, {
                                    "collapse-open": isActive,
                                })}
                                type="button"
                                onClick={() => this.handleClickAccordion(onClick)}>
                                {item}
                                <DropdownArrow isActive={isActive} />
                            </button>
                            <AccordionItemContent isActive={isActive}>{children}</AccordionItemContent>
                        </li>
                    );
                }}
            </AccordionContext.Consumer>
        );
    }
}

export default AccordionItem;
