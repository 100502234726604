import * as configUtils from "util/config";

export const invitationCodeMask = () => {
    const invitationCodeUnmaskedLength = configUtils.getInteger("backoffice.invitationCodes.unmaskedLength", 4);

    let mask = new Array(invitationCodeUnmaskedLength).fill(/[a-zA-Z\d]/);
    for (let i = 0; i < 2; i++) {
        mask = [...mask, "-", ...new Array(invitationCodeUnmaskedLength).fill(/[a-zA-Z\d]/)];
    }

    return mask;
};

export const timeCodeMask = () => [/\d/, /\d/, ":", /\d/, /\d/];

export const timeRegex = () => "^(?:0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$";

export default {};
