import React, { Component } from "react";
import { bool, shape, func } from "prop-types";
import { connect } from "react-redux";

import * as i18nUtils from "util/i18n";
import * as checkUtils from "util/checks";

import Table from "pages/_components/Table";
import FormattedAmount from "pages/_components/FormattedAmount";
import { CURRENCY_CODE } from "constants.js";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import InfoTag from "pages/_components/InfoTag";
import { Link } from "react-router-dom";

export const listItemProps = {
    echeq: shape({}).isRequired,
    summary: shape({}).isRequired,
    showErrorsModal: func.isRequired,
};

class BatchItem extends Component {
    static propTypes = {
        ...listItemProps,
        isDesktop: bool,
    };

    static defaultProps = {
        isDesktop: true,
    };

    handleShowErrors = (e, item) => {
        const { showErrorsModal } = this.props;
        const { echeqNumber, coelsaResp } = item;
        const coelsaResponse = JSON.parse(coelsaResp);
        const errors = coelsaResponse?.errors || [];
        showErrorsModal(true, { echeqNumber, errors });
        e.preventDefault();
    };

    renderDesktop = () => {
        const {
            echeq,
            summary: { idTransaction },
        } = this.props;
        const { echeqNumber, cuitCuilBeneficiary, paymentDate, amount, status, echeqId, id } = echeq;
        const isSuccess = status?.toUpperCase() === "SUCCESS";
        return (
            <>
                <Table.Row
                    key={id}
                    renderAs={status === null ? "div" : Link}
                    to={
                        isSuccess ? `/echeqs/emitted/detail/${echeqId}` : `/echeqs/emit/massive/${idTransaction}/detail`
                    }
                    onClick={isSuccess || status === null ? null : (e) => this.handleShowErrors(e, echeq)}>
                    <Table.Data align="left" className="data-ref">
                        <div className="data-text light-font">
                            {echeqNumber || i18nUtils.get("echeq.batch.detail.body.echeqNumber.notAvailable")}
                        </div>
                    </Table.Data>
                    <Table.Data align="center" className="data-ref">
                        <div className="data-text light-font">{cuitCuilBeneficiary}</div>
                    </Table.Data>
                    <Table.Data align="right" className="data-ref">
                        <div className="data-text light-font">
                            <FormattedDate date={paymentDate} dateFormat="dd/MM/yyyy" />
                        </div>
                    </Table.Data>
                    <Table.Data align="right" className="data-cell-amount">
                        <div className="data-text light-font">
                            <FormattedAmount notBold quantity={amount} currency={CURRENCY_CODE.ARS} />
                        </div>
                    </Table.Data>
                    {status && (
                        <Table.Data align="right" className="data-state">
                            <div className="data-text light-font d-flex justify-content-end">
                                <InfoTag
                                    type="info"
                                    message={i18nUtils.get(`echeq.batch.detail.status.${status.toLowerCase()}`)}
                                    tagBackground={checkUtils.statusTagColorBatch(status)}
                                    moreStyles={{
                                        color: "black",
                                        width: "fit-content",
                                    }}
                                />
                            </div>
                        </Table.Data>
                    )}
                    <Table.Data />
                </Table.Row>
            </>
        );
    };

    renderMobile = () => {
        const {
            echeq,
            summary: { idTransaction },
        } = this.props;
        const { echeqNumber, cuitCuilBeneficiary, paymentDate, amount, status, echeqId } = echeq;
        const isSuccess = status?.toUpperCase() === "SUCCESS";
        return (
            <div className="d-flex container-white my-2">
                <Link
                    className="w-100 my-1 text-decoration-none"
                    to={
                        isSuccess ? `/echeqs/emitted/detail/${echeqId}` : `/echeqs/emit/massive/${idTransaction}/detail`
                    }
                    onClick={isSuccess || status === null ? null : (e) => this.handleShowErrors(e, echeq)}>
                    {status && (
                        <Table.Row className="pb-1">
                            <Table.Data>
                                <div className="d-flex justify-content-start">
                                    <InfoTag
                                        type="info"
                                        message={i18nUtils.get(`echeq.batch.detail.status.${status.toLowerCase()}`)}
                                        tagBackground={checkUtils.statusTagColorBatch(status)}
                                        tagClass="m-0"
                                        moreStyles={{
                                            color: "black",
                                            width: "fit-content",
                                        }}
                                    />
                                </div>
                            </Table.Data>
                        </Table.Row>
                    )}
                    <Table.Row className="pt-1">
                        <Table.HeaderData align="left">
                            <I18n id="echeq.batch.detail.table.header.echeqNumber" />
                        </Table.HeaderData>
                        <Table.Data align="left">
                            <div className="data-text">
                                {echeqNumber || i18nUtils.get("echeq.batch.detail.body.echeqNumber.notAvailable")}
                            </div>
                        </Table.Data>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderData align="left">
                            <I18n id="echeq.batch.detail.body.header.document" />
                        </Table.HeaderData>
                        <Table.Data align="right">
                            <div className="data-text">{cuitCuilBeneficiary}</div>
                        </Table.Data>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderData align="left">
                            <I18n id="echeq.batch.detail.body.header.paymentDate" />
                        </Table.HeaderData>
                        <Table.Data align="right">
                            <div className="data-text">
                                <FormattedDate date={paymentDate} />
                            </div>
                        </Table.Data>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderData align="left">
                            <I18n id="echeq.batch.detail.body.header.amount" />
                        </Table.HeaderData>
                        <Table.Data>
                            <FormattedAmount
                                currency={CURRENCY_CODE.ARS}
                                className="data-amount"
                                quantity={amount}
                                notBold
                            />
                        </Table.Data>
                    </Table.Row>
                </Link>
            </div>
        );
    };

    render() {
        const { isDesktop } = this.props;

        if (!isDesktop) {
            return this.renderMobile();
        }
        return this.renderDesktop();
    }
}

export default connect()(BatchItem);
