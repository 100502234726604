/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { arrayOf, bool, number, element, func, shape } from "prop-types";
import Measure from "react-measure";

import { actions as communicationsActions, selectors as communicationsSelectors } from "reducers/communications";
import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";
import { selectors as sessionSelectors } from "reducers/session";

import Container from "pages/_components/Container";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import PageLoading from "pages/_components/PageLoading";
import Scroll from "pages/_components/Scroll";
import I18n from "pages/_components/I18n";
import MessageItem from "pages/communications/_components/MessageItem";
import NoResults from "pages/communications/_components/NoResults";
import ModalEnvironments from "pages/communications/_components/ModalEnvironments";

import * as i18n from "util/i18n";
import classNames from "classnames";
import Select from "react-select";

const trays = {
    all: "all",
    unread: "unread",
    read: "read",
    sent: "sent",
};

class List extends Component {
    static propTypes = {
        isFetching: bool.isRequired,
        isDesktop: bool.isRequired,
        totalPages: number.isRequired,
        currentPage: number.isRequired,
        list: arrayOf(element).isRequired,
        dispatch: func.isRequired,
        dimensions: shape({}).isRequired,
        selectedIndex: number.isRequired,
        environments: String.isRequired,
        activeEnvironment: String.isRequired,
        isModalVisible: func.isRequired,
        handleModalDelete: func.isRequired,
    };

    state = {
        activeTray: trays.all,
        searchSubject: "",
        selectedFilter: "",
        isPopupVisible: false,
        environment: "",
    };

    componentWillMount() {
        window.showPopup = (idTransaction, idEnvironment, environment) => {
            const { dispatch, activeEnvironment } = this.props;
            if (idEnvironment === activeEnvironment.id) {
                dispatch(routerActions.push(`/transaction/${idTransaction}`));
            } else {
                this.setState({ isPopupVisible: true, environment });
            }
        };
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(communicationActions.setSelectedIndex(null));
    }

    handleUnreadClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.unread });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER", onlyUnread: true }));
    };

    handleAllClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.all });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER" }));
    };

    handleReadClick = () => {
        const { dispatch } = this.props;
        this.setState({ activeTray: trays.read });
        dispatch(communicationsActions.listRequest({ direction: "BANK_TO_CUSTOMER", onlyUnread: false }));
    };

    handleSelectMessageClick = (idCommunication, userRead, index) => {
        const { dispatch, isDesktop } = this.props;

        dispatch(communicationActions.setSelectedIndex(index));
        dispatch(communicationActions.setSelectedIdCommunication(idCommunication));
        if (isDesktop) {
            dispatch(communicationsActions.showReadPanel());
            dispatch(communicationActions.detailRequest(idCommunication, index));
        } else {
            dispatch(replace(`/communications/read/${idCommunication}`));
        }
        if (!userRead) {
            dispatch(communicationsActions.toggleMessageStatus(index));
        }
    };

    handleChangeMessageStatus = (e, idCommunication, userRead, index) => {
        e.stopPropagation();
        e.preventDefault();

        const { dispatch } = this.props;
        const { activeTray } = this.state;

        if (userRead) {
            dispatch(communicationActions.markAsUnReadRequest(idCommunication));
        } else {
            dispatch(communicationActions.markAsReadRequest(idCommunication));
        }

        if (activeTray !== trays.all) {
            dispatch(communicationsActions.removeFromTray(index));
        } else {
            dispatch(communicationsActions.toggleMessageStatus(index));
        }
    };

    handleRemoveClick = (e, idCommunication, index) => {
        e.stopPropagation();
        e.preventDefault();

        const { dispatch, selectedIndex, handleModalDelete, isModalVisible } = this.props;

        isModalVisible(e, true);

        handleModalDelete(() => {
            dispatch(communicationActions.deleteRequest(idCommunication, index));
            isModalVisible(e, false);
            if (index === selectedIndex) {
                dispatch(communicationActions.setSelectedIndex(null));
                dispatch(communicationActions.setSelectedIdCommunication(null));
            }
            dispatch(communicationsActions.fetchMoreRequest());
        });
    };

    handleFetchMoreClick = () => {
        const { currentPage, dispatch, isFetching } = this.props;
        const { activeTray } = this.state;
        let filters = {};

        if (!isFetching) {
            switch (activeTray) {
                case trays.read:
                    filters.onlyUnread = false;
                    break;
                case trays.unread:
                    filters.onlyUnread = true;
                    break;
                case trays.sent:
                    filters.direction = "CUSTOMER_TO_BANK";
                    break;
                case trays.all:
                    filters.direction = "BANK_TO_CUSTOMER";
                    break;
                default:
                    filters = {};
            }

            filters.pageNumber = currentPage + 1;

            dispatch(communicationsActions.fetchMoreRequest(filters));
        }
    };

    handleSearchChange = (e) => {
        this.setState({ searchSubject: e.target.value });
    };

    getUrl = (event) => {
        const linkElement = event.currentTarget.querySelector("a");
        return linkElement.getAttribute("href");
    };

    handleOnClickOnLink = (event) => {
        const { dispatch, environments, activeEnvironment } = this.props;
        const { target } = event;
        if (target.tagName.toLowerCase() === "i") {
            event.preventDefault();
            event.stopPropagation();
            const url = this.getUrl(event);
            const env = this.getEnvironmentFromUrl(url);
            if (env === activeEnvironment.id.toString()) {
                window.location.href = url;
            } else {
                const correctEnvironmentName = environments[env].name;
                dispatch(communicationActions.showWrongEnvironmentModal(correctEnvironmentName));
            }
        }
    };

    getEnvironmentFromUrl = (url) => {
        const match = url.match(/\/(\d+)\//);
        return match[1].toString();
    };

    getItems = () => {
        const { list, handleModalDelete, isModalVisible } = this.props;
        const { searchSubject } = this.state;
        const search = searchSubject.toLocaleLowerCase();

        return list.reduce((acc, communication, index) => {
            if (communication.subject.toLocaleLowerCase().indexOf(search) >= 0) {
                return [
                    ...acc,
                    <MessageItem
                        communication={communication}
                        index={index}
                        handleRemoveClick={this.handleRemoveClick}
                        handleChangeMessageStatus={this.handleChangeMessageStatus}
                        handleSelectMessageClick={this.handleSelectMessageClick}
                        handleOnClickOnLink={this.handleOnClickOnLink}
                        key={communication.idCommunication}
                        isModalVisible={isModalVisible}
                        handleModalDelete={handleModalDelete}
                    />,
                ];
            }
            return acc;
        }, []);
    };

    chargeDimensions = () => {
        const { dimensions } = this.state;
        const { dimensions: propsDimension } = this.props;

        if (dimensions && !propsDimension) {
            this.saveDimensions(dimensions);
        }

        if (propsDimension && dimensions && propsDimension.height !== dimensions.height) {
            this.setState({ dimensions: propsDimension });
        }
    };

    saveDimensions = (dimensions) => {
        const { dispatch } = this.props;
        dispatch(communicationsActions.setDimensions(dimensions));
    };

    handleChange = ({ value }) => {
        this.setState({ selectedFilter: value });
        if (value === "ALL") {
            this.handleAllClick();
        }
        if (value === "READ") {
            this.handleReadClick();
        }
        if (value === "UNREAD") {
            this.handleUnreadClick();
        }
    };

    render() {
        const { currentPage, totalPages, isDesktop, isFetching } = this.props;
        const { dimensions, searchSubject, selectedFilter, isPopupVisible, environment } = this.state;
        const moreMessages = totalPages > currentPage;
        const list = this.getItems();
        const endOfListItem = (
            <li key="listEnd">
                <I18n id="communications.message.list.end" />
            </li>
        );

        if (!isDesktop) {
            this.chargeDimensions();
        }

        const options = [
            { value: "ALL", label: i18n.get("communications.trays.received") },
            { value: "READ", label: i18n.get("communications.trays.read") },
            { value: "UNREAD", label: i18n.get("communications.trays.unread") },
        ];

        return (
            <>
                <section className="align-items-center">
                    <Grid>
                        <Row className="justify-content-center">
                            <Col className="col col-12">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input
                                            type="search"
                                            className="form-control"
                                            placeholder={i18n.get("communications.list.search")}
                                            required=""
                                            id="search"
                                            autoComplete="off"
                                            onChange={this.handleSearchChange}
                                        />
                                        <Button className="btn-only-icon border-none">
                                            <I18n
                                                id="global.search"
                                                componentProps={{ className: "visually-hidden" }}
                                            />
                                            <Image src="images/search.svg" />
                                        </Button>
                                    </div>
                                </div>
                                {isDesktop && (
                                    <div className="communications-list-filter">
                                        <I18n
                                            id="communications.filter.label"
                                            componentProps={{ className: "communications-list-filter-label" }}
                                        />
                                        <Select
                                            name="filter"
                                            clearable={false}
                                            label="selector"
                                            options={options}
                                            onChange={this.handleChange}
                                            value={selectedFilter}
                                            placeholder={i18n.get("communications.trays.received")}
                                        />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Grid>
                </section>

                <Measure
                    bounds
                    onResize={(contentRect) => {
                        if (!dimensions) {
                            this.setState({ dimensions: contentRect.bounds });
                        }
                    }}>
                    {({ measureRef }) => {
                        if (!isDesktop && dimensions) {
                            return (
                                <PageLoading loading={isFetching && currentPage === 0}>
                                    <ul className={classNames("message-list", { "mt-8": !list.length })}>
                                        {list.length > 0 ? (
                                            <Scroll
                                                {...this.props}
                                                lastPage={totalPages === currentPage}
                                                items={list}
                                                isInfiniteScroll
                                                removeListenersWhenPulled
                                                fetchMoreData={this.handleFetchMoreClick}
                                                endOfListItem={endOfListItem}
                                                headerHeight={68.313 + 16}
                                                pageNumber={currentPage}
                                            />
                                        ) : (
                                            <NoResults
                                                message={
                                                    searchSubject
                                                        ? "communications.list.search.empty"
                                                        : "communications.list.empty"
                                                }
                                            />
                                        )}
                                    </ul>
                                </PageLoading>
                            );
                        }
                        return (
                            <section className="flex-grow align-items-center" ref={measureRef}>
                                <Grid>
                                    <Row className="justify-content-center">
                                        <Col className="col col-12">
                                            {list.length > 0 && isDesktop && <ul className="message-list">{list}</ul>}
                                            {isDesktop && <PageLoading loading={isFetching} className="line-loader" />}
                                            {(list.length > 0 && isDesktop && moreMessages && (
                                                <Container className=" align-items-center">
                                                    <Col className="col col-12">
                                                        <Button
                                                            label="communications.messages.more"
                                                            bsStyle="primary"
                                                            onClick={this.handleFetchMoreClick}
                                                        />
                                                    </Col>
                                                </Container>
                                            )) ||
                                                (!isFetching && (
                                                    <Container className="align-items-center">
                                                        <Col className="col col-12 ">
                                                            {searchSubject ? (
                                                                <NoResults message="communications.list.search.empty" />
                                                            ) : (
                                                                <NoResults message="communications.list.empty" />
                                                            )}
                                                        </Col>
                                                    </Container>
                                                ))}
                                        </Col>
                                    </Row>
                                </Grid>
                            </section>
                        );
                    }}
                </Measure>

                <ModalEnvironments
                    environment={environment}
                    isVisible={isPopupVisible}
                    submitClick={() => {
                        this.setState({ isPopupVisible: false });
                    }}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    list: communicationsSelectors.list(state),
    currentPage: communicationsSelectors.currentPage(state),
    totalPages: communicationsSelectors.totalPages(state),
    isFetching: communicationsSelectors.isFetching(state),
    dimensions: communicationsSelectors.getDimensions(state),
    selectedIndex: communicationSelectors.getSelectedIndex(state),
    environments: sessionSelectors.getEnvironments(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(List);
