import React from "react";
import { string, func, node } from "prop-types";

import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";

const DecisionButton = ({ label, image, onClick, children }) => (
    <button onClick={onClick} className="decision" type="button">
        <I18n componentProps={{ className: "buttonLabel" }} id={label} />
        <Image src={image} />
        {children}
    </button>
);

DecisionButton.propTypes = {
    label: string.isRequired,
    image: string.isRequired,
    onClick: func.isRequired,
    children: node.isRequired,
};

export default DecisionButton;
