import React, { Component } from "react";
import { node, string } from "prop-types";

class TableBody extends Component {
    static propTypes = {
        children: node.isRequired,
        className: string,
    };

    static defaultProps = {
        className: "",
    };

    render() {
        const { children, className } = this.props;
        return <div className={`table-body ${className}`}>{children}</div>;
    }
}

export default TableBody;
