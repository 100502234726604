import { combineReducers } from "redux";

import multilineFileReducer from "reducers/formFields/multilineFile";
import multilineFileProcessReducer from "reducers/formFields/multilineFileProcess";
import formTypes from "reducers/types/form";

const formFieldsReducers = combineReducers({
    multilineFile: multilineFileReducer,
    multilineFileProcess: multilineFileProcessReducer,
});

export default (state, action) => {
    if (action.type === formTypes.FORM_CLOSED) {
        return formFieldsReducers(undefined, action);
    }

    return formFieldsReducers(state, action);
};
