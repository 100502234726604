import React, { Component } from "react";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import { Formik, Form } from "formik";
import { Switch, Route } from "react-router-dom";
import { func, arrayOf, shape, string, bool, element, number as num } from "prop-types";
import { connect } from "react-redux";
import { groupsSelectors, detailsSelectors } from "reducers/administration";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import AdministrationHeading from "pages/administration/_components/Heading";
import PermissionsForm from "pages/administration/_components/PermissionsForm";
import PageLoading from "pages/_components/PageLoading";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";
import InfoTag from "pages/_components/InfoTag";
import Heading from "pages/_components/Heading";

const groupShape = {
    idItem: string,
    label: string,
    ordinal: num,
};

groupShape.childrenList = arrayOf(shape(groupShape)).isRequired;

class Permissions extends Component {
    static propTypes = {
        match: shape({
            url: string.isRequired,
            params: shape({ id: string.isRequired }),
        }).isRequired,
        actions: shape({
            loadPermissionsRequest: func.isRequired,
            updatePermissionsPreview: func.isRequired,
        }).isRequired,
        products: arrayOf(shape(groupShape)).isRequired,
        groups: arrayOf(
            shape({
                idItem: string.isRequired,
                label: string.isRequired,
                ordinal: num.isRequired,
            }),
        ).isRequired,
        permissions: shape({}),
        isFetching: bool.isRequired,
        routerActions: shape({
            goBack: func.isRequired,
        }).isRequired,
        confirmRoute: element.isRequired,
        user: shape({}),
        hasMassiveEnabled: bool,
        fetching: bool,
    };

    static defaultProps = {
        permissions: null,
        hasMassiveEnabled: false,
        fetching: false,
        user: null,
    };

    componentDidMount() {
        const { match, actions } = this.props;

        actions.loadPermissionsRequest(match.params.id);
    }

    handleSubmit = (values, { setSubmitting }) => {
        const { match, actions } = this.props;

        actions.updatePermissionsPreview(
            {
                permissions: values,
                idUser: match.params.id,
            },
            setSubmitting,
        );
    };

    handleBack = () => {
        const { routerActions } = this.props;
        routerActions.goBack();
    };

    renderContent = ({ isSubmitting, ...props }) => (
        <>
            <MainContainer>
                <Form className="above-the-fold">
                    <Container
                        className="container--layout flex-grow align-items-center px-0"
                        gridClassName="form-content">
                        <p className="text-lead">
                            <Container.ColumnHeader title="administration.configuratePermissions.subtitle" />
                        </p>
                    </Container>
                    <Container
                        className="container--layout flex-grow align-items-center px-0"
                        gridClassName="form-content">
                        <Container.Column className="col-12 col-xl-6 pl-lg-0" lg={6} md={6} sm={12}>
                            <Container.ColumnBody>
                                <PermissionsForm column={1} {...props} />
                            </Container.ColumnBody>
                        </Container.Column>
                        <Container.Column className="col-12 col-xl-6 pr-lg-0" lg={6} md={6} sm={12}>
                            <Container.ColumnBody>
                                <PermissionsForm column={2} {...props} />
                            </Container.ColumnBody>
                        </Container.Column>
                    </Container>
                    <Container className="container--layout align-items-center" gridClassName="form-content">
                        <Container.Column className="col-12 max-xl-480 px-xl-0" md={9} lg={6}>
                            <Container.ColumnBody>
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    label="administration.permissions.modify"
                                    loading={isSubmitting}
                                />
                            </Container.ColumnBody>
                        </Container.Column>
                    </Container>
                </Form>
            </MainContainer>
        </>
    );

    renderHeader = () => {
        const { user, hasMassiveEnabled } = this.props;
        const isAdministrator = !hasMassiveEnabled;

        if (user) {
            const { fullName, idUserStatus } = user;

            return (
                <>
                    <div className="admin-detail-head admin-detail-head-section px-0">
                        <div>
                            <Head onBack={this.handleBack} />
                        </div>
                        {fullName && (
                            <div className="page-title px-0 mb-3">
                                <I18n
                                    component="h1"
                                    componentProps={{ className: "d-flex align-items-center" }}
                                    id="administration.configuratePermissions.title"
                                    fullName={fullName}
                                />

                                <div className="data-wrapper data-wrapper-flex ml-2">
                                    {isAdministrator && (
                                        <InfoTag
                                            type="default"
                                            message={i18nUtils.get(`administration.administrator`)}
                                            tagBackground="#707070"
                                        />
                                    )}
                                    {user && (
                                        <InfoTag
                                            type="default"
                                            message={i18nUtils.get(`user.status.${idUserStatus}`)}
                                            tagBackground={idUserStatus === "blocked" ? "#fc5f5f" : "#0be0a2"}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            );
        }
        return null;
    };

    render() {
        const { fetching, groups, match, confirmRoute, permissions } = this.props;
        const isLoading = fetching && !groups.length;

        return (
            <Formik initialValues={permissions} onSubmit={this.handleSubmit} enableReinitialize={isLoading}>
                {(props) => (
                    <Switch>
                        <Route path={match.url} exact>
                            <>
                                {this.renderHeader()}
                                <div className="admin-detail-head px-0">
                                    <AdministrationHeading colClassName="px-0">
                                        {({ email, documentType, documentNumber, mobileNumber }) => (
                                            <div>
                                                {documentNumber && (
                                                    <Grid>
                                                        <Row className="content-data align-items-center px-2">
                                                            <Container.Column md={3} sm={12}>
                                                                <Heading.DataGroup
                                                                    label="administration.permissions.document"
                                                                    data={`${documentType} ${documentNumber}`}
                                                                />
                                                            </Container.Column>
                                                            <Container.Column md={6} sm={12} className="text-center">
                                                                <Heading.DataGroup
                                                                    label="administration.permissions.email"
                                                                    data={email}
                                                                />
                                                            </Container.Column>
                                                            <Container.Column className="text-right" md={3} sm={12}>
                                                                <Heading.DataGroup
                                                                    label="administration.permissions.mobileNumber"
                                                                    data={mobileNumber}
                                                                />
                                                            </Container.Column>
                                                        </Row>
                                                    </Grid>
                                                )}
                                            </div>
                                        )}
                                    </AdministrationHeading>
                                    <PageLoading loading={isLoading}>
                                        {!isLoading && this.renderContent(props)}
                                    </PageLoading>
                                </div>
                            </>
                        </Route>
                        <Route>{confirmRoute}</Route>
                    </Switch>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = (state) => ({
    user: groupsSelectors.getUser(state),
    hasMassiveEnabled: detailsSelectors.hasMassiveEnabled(state),
});

export default connect(mapStateToProps)(Permissions);
