import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createReducer, makeActionCreator } from "util/redux";
import { globalTypes } from "reducers/types/global";

export const types = {
    CLEAN_UP: "fingerprint/CLEAN_UP",
    ENROLL_FINGERPRINT_ON_DEVICE: "fingerprint/ENROLL_FINGERPRINT_ON_DEVICE",
    FINGERPRINT_AVAILABILITY: "fingerprint/FINGERPRINT_AVAILABILITY",
    FINGERPRINT_AVAILABILITY_SUCCESS: "fingerprint/FINGERPRINT_AVAILABILITY_SUCCESS",
    FINGERPRINT_CONFIGURATION_PRE: "fingerprint/FINGERPRINT_CONFIGURATION_PRE",
    FINGERPRINT_CONFIGURATION_PRE_SUCCESS: "fingerprint/FINGERPRINT_CONFIGURATION_PRE_SUCCESS",
    FINGERPRINT_DELETE_DEVICE: "fingerprint/FINGERPRINT_DELETE_DEVICE",
    FINGERPRINT_DELETE_DEVICE_PRE: "fingerprint/FINGERPRINT_DELETE_DEVICE_PRE",
    FINGERPRINT_HIDE_WIDGET: "fingerprint/FINGERPRINT_HIDE_WIDGET",
    FINGERPRINT_SHOW_WIDGET: "fingerprint/FINGERPRINT_SHOW_WIDGET",
    FINGERPRINT_VERIFICATION_REQUEST: "fingerprint/FINGERPRINT_VERIFICATION_REQUEST",
    FINGERPRINT_GET_INITIAL_DATA: "fingerprint/FINGERPRINT_GET_INITIAL_DATA",
    ACCEPTTERMISCONDITIONS: "fingerprint/ACCEPTTERMISCONDITIONS",
    RESET_ACCEPT_TERMS_CONDITIONS: "fingerprint/RESET_ACCEPT_TERMS_CONDITIONS",
    SET_ACCEPT_TERMISCONDITIONS: "fingerprint/SET_ACCEPT_TERMISCONDITIONS",
    SET_REMEMBER_DOCUMENT: "fingerprint/SET_REMEMBER_DOCUMENT",
};

export const INITIAL_STATE = {
    availability: null,
    device: null,
    fetching: false,
    fingerprintConfiguredUserDevices: null,
    hidden: false,
    isDeviceWithFingerprint: null,
    showAgain: true,
    document: null,
    documentType: null,
    userFullName: null,
    userFirstName: null,
    acceptTermsConditionsRedux: false,
    remeberDocumentRedux: true,
};

const reducer = createReducer(INITIAL_STATE, {
    [globalTypes.CLEAN_UP]: ({ hidden, showAgain }) => ({ ...INITIAL_STATE, hidden, showAgain }),
    [types.FINGERPRINT_AVAILABILITY]: (state) => ({ ...state, availability: null }),
    [types.FINGERPRINT_AVAILABILITY_SUCCESS]: (state, { availability }) => ({ ...state, availability }),
    [types.FINGERPRINT_CONFIGURATION_PRE]: (state, { device }) => ({
        ...state,
        device,
        fetching: true,
        fingerprintConfiguredUserDevices: null,
        isDeviceWithFingerprint: null,
    }),
    [types.FINGERPRINT_CONFIGURATION_PRE_SUCCESS]: (
        state,
        {
            fingerprintConfiguredUserDevices,
            isDeviceWithFingerprint,
            document,
            documentType,
            userFullName,
            userFirstName,
        },
    ) => ({
        ...state,
        fetching: false,
        fingerprintConfiguredUserDevices,
        isDeviceWithFingerprint,
        document,
        documentType,
        userFullName,
        userFirstName,
    }),
    [types.FINGERPRINT_DELETE_DEVICE_PRE]: (state, { device }) => ({ ...state, device }),
    [types.FINGERPRINT_HIDE_WIDGET]: (state, { showAgain }) => ({ ...state, hidden: true, showAgain }),
    [types.FINGERPRINT_SHOW_WIDGET]: (state) => ({ ...state, hidden: false }),
    [types.RESET_ACCEPT_TERMS_CONDITIONS]: (state) => ({ ...state, acceptTermsConditionsRedux: false }),
    [types.SET_ACCEPT_TERMISCONDITIONS]: (state) => ({
        ...state,
        acceptTermsConditionsRedux: !state.acceptTermsConditionsRedux,
    }),
    [types.SET_REMEMBER_DOCUMENT]: (state) => ({
        ...state,
        remeberDocumentRedux: !state.remeberDocumentRedux,
    }),
});

export default persistReducer(
    {
        storage,
        key: "fingerprint",
        whitelist: ["showAgain"],
    },
    reducer,
);

export const actions = {
    fingerprintAvailability: makeActionCreator(types.FINGERPRINT_AVAILABILITY),
    fingerprintConfigurationPre: makeActionCreator(types.FINGERPRINT_CONFIGURATION_PRE, "device"),
    fingerprintVerification: makeActionCreator(types.FINGERPRINT_VERIFICATION_REQUEST),
    fingerprintDeleteDevice: makeActionCreator(types.FINGERPRINT_DELETE_DEVICE, "idSession"),
    fingerprintDeleteUserDevicePre: makeActionCreator(types.FINGERPRINT_DELETE_DEVICE_PRE, "device"),
    fingerprintHideWidget: makeActionCreator(types.FINGERPRINT_HIDE_WIDGET, "showAgain"),
    enrollFingerprintOnDevice: makeActionCreator(types.ENROLL_FINGERPRINT_ON_DEVICE),
    fingerprintGetInitialData: makeActionCreator(types.FINGERPRINT_GET_INITIAL_DATA),
    fingerprintResetAcceptTermsConditions: makeActionCreator(types.RESET_ACCEPT_TERMS_CONDITIONS),
    fingerprintSetAcceptTermsConditions: makeActionCreator(types.SET_ACCEPT_TERMISCONDITIONS),
    fingerprintSetRememberDocument: makeActionCreator(types.SET_REMEMBER_DOCUMENT),
};

export const selectors = {
    getFetching: ({ fingerprint }) => fingerprint.fetching,
    getFingerprintConfiguredUserDevices: ({ fingerprint }) => fingerprint.fingerprintConfiguredUserDevices,
    getAvailability: ({ fingerprint }) => fingerprint.availability,
    getIsDeviceWithFingerprint: ({ fingerprint }) => fingerprint.isDeviceWithFingerprint,
    getDevice: ({ fingerprint }) => fingerprint.device,
    isHidden: ({ fingerprint }) => fingerprint.hidden,
    isShowAgain: ({ fingerprint }) => fingerprint.showAgain,
    getAcceptTermsConditions: ({ fingerprint }) => fingerprint.acceptTermsConditionsRedux,
    getRememberDocument: ({ fingerprint }) => fingerprint.remeberDocumentRedux,
};
