import { call, put, takeEvery } from "redux-saga/effects";
import { types, actions } from "reducers/widgets";
import * as widgets from "middleware/widgets";
import * as i18n from "util/i18n";

const sagas = [takeEvery(types.LIST_REQUEST, listRequest)];

export default sagas;

function* listRequest({ widget, userId }) {
    try {
        const response = yield call(widgets.listRequest, widget, userId);

        if (response.type === "W") {
            yield put(actions.listFailure(widget, i18n.get("desktop.widgets.error")));
        } else {
            yield put(actions.listSuccess(widget, response.data.data));
        }
    } catch (error) {
        yield put(actions.listFailure(widget, i18n.get("desktop.widgets.error")));
    }
}
