import React, { Component } from "react";
import { connect } from "react-redux";
import { func, arrayOf, shape, bool } from "prop-types";

import { actions as templateActions, selectors as templateSelectors } from "reducers/template";

import List from "pages/_components/List";
import TemplateListItem from "pages/forms/_components/TemplateListItem";

class TemplateList extends Component {
    static propTypes = {
        onSelect: func.isRequired,
        dispatch: func.isRequired,
        // TODO this values are dynamic, need to the define the correct type
        templates: arrayOf(shape({})).isRequired,
        isFetching: bool.isRequired,
        closeDrawer: func,
    };

    static defaultProps = {
        closeDrawer: undefined,
    };

    handleSelect = (num) => {
        const { onSelect, templates, closeDrawer } = this.props;
        onSelect(templates[num].transactionData);

        // En caso de que se esté ejecutando desde el buttonDrawer se le agrega esta función como prop
        if (closeDrawer) {
            closeDrawer();
        }
    };

    handleDelete = (item) => {
        const { dispatch } = this.props;
        dispatch(templateActions.deleteTemplate(item));
    };

    render() {
        const { templates, isFetching } = this.props;
        return isFetching ? (
            <Spinner />
        ) : (
            <List onSelect={this.handleSelect} className="scrollable-transfer-template">
                {templates.map((item, index) => (
                    <TemplateListItem key={item.name} item={item} num={index} onDelete={this.handleDelete} />
                ))}
            </List>
        );
    }
}

function Spinner() {
    return (
        <div className="btn is-loading no-button-spinner">
            <span className="btn-loading-indicator">
                <span />
                <span />
                <span />
            </span>
            <span className="btn-loading-text">Loading</span>
        </div>
    );
}

const mapStateToProps = (state) => ({
    templates: templateSelectors.getTemplateList(state),
    isFetching: templateSelectors.isFetching(state),
});

export default connect(mapStateToProps)(TemplateList);
