import { routerActions } from "react-router-redux";

import * as administrationSimple from "middleware/administration/simple";
import {
    permissionsTypes,
    permissionsActions,
    permissionsTicketTypes,
    permissionsTicketActions,
} from "reducers/administration/simple";
import permissionsSagasCreator from "sagas/administration/common/permissions";
import permissionsTicketSagasCreator from "sagas/administration/common/permissionsTicket";

export default [
    ...permissionsSagasCreator(administrationSimple, permissionsTypes, {
        ...permissionsActions,
        onSuccess: (id) => routerActions.push(`/administration/simple/permissions/${id}/confirm`),
    }),
    ...permissionsTicketSagasCreator(administrationSimple, permissionsTicketTypes, {
        ...permissionsTicketActions,
        ...permissionsActions,
    }),
];
