import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { routerActions } from "react-router-redux/actions";

import { selectors } from "reducers/administration/common/administrationTicket";

import GroupFormDataTicketContent from "pages/administration/_components/tickets/GroupFormDataTicketContent";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";

class GroupFormDataTicket extends Component {
    render() {
        return (
            <AdministrationTicket {...this.props}>
                <GroupFormDataTicketContent {...this.props} />
            </AdministrationTicket>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

const mapDispatchToProps = (dispatch) => ({
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GroupFormDataTicket);
