/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { arrayOf, string, shape, bool } from "prop-types";
import { echeqShape, echeqConceptLabel } from "util/checks";
import * as i18n from "util/i18n";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import { compose } from "redux";
import { selectors as sessionSelectors } from "reducers/session";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

const FORM_ID = "echeqs";

class CesionSummaryData extends Component {
    static propTypes = {
        echeqAction: shape({
            action: string.isRequired,
            echeqList: arrayOf(echeqShape),
            specialAttribute: string.isRequired,
        }).isRequired,
        cesionData: shape({
            beneficiary: shape({}),
        }).isRequired,
        isDesktop: bool.isRequired,
    };

    render() {
        const { echeqAction, cesionData, isDesktop } = this.props;

        if (echeqAction?.echeqList?.length > 1) {
            return (
                <>
                    <CardContainer className="confirmation__cards-container mt-0">
                        <Card
                            className="confirmation__cards card__info mb-4"
                            hrClass="d-none"
                            title={
                                !isDesktop && (
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get(`${FORM_ID}.add.beneficiary`)}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info no-wrap">
                                            <span>{cesionData?.beneficiary?.reference?.replaceAll("#", "Ñ")}</span>
                                        </div>
                                    </div>
                                )
                            }
                            content={
                                isDesktop ? (
                                    <div className="d-flex align-items-center">
                                        <div className="confirmation-cards__content pb-1">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get(`${FORM_ID}.add.beneficiary`)}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    <span>
                                                        {cesionData?.beneficiary?.reference?.replaceAll("#", "Ñ")}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis">
                                                    <span>{cesionData?.beneficiary?.document?.toUpperCase()}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get(`${FORM_ID}.beneficiario_documento`)}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>{cesionData?.beneficiary?.document?.toUpperCase()}</span>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get(`${FORM_ID}.reference.label`)}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis short">
                                                    <span>{cesionData?.reference}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get(`${FORM_ID}.referencevalue.label`)}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis short">
                                                    <span>{cesionData?.referencevalue}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get(`${FORM_ID}.address.label`)}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info withEllipsis">
                                                <div className="confirmation-cards__with-ellipsis short">
                                                    <span>{cesionData?.address}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            bottom={
                                isDesktop && (
                                    <div className="d-flex align-items-center">
                                        {cesionData?.address && (
                                            <div
                                                className={`confirmation-cards__bottom ${(cesionData?.reference ||
                                                    cesionData?.referencevalue) &&
                                                    "confirmation-cards-separator"}`}>
                                                <div className="confirmation-cards__bottom-data">
                                                    <span>{i18n.get(`${FORM_ID}.address.label`)}</span>
                                                </div>
                                                <div className="confirmation-cards__bottom-info">
                                                    <span>{cesionData?.address}</span>
                                                </div>
                                            </div>
                                        )}
                                        {cesionData?.reference && (
                                            <div
                                                className={`confirmation-cards__bottom ${cesionData?.referencevalue &&
                                                    "confirmation-cards-separator"}`}>
                                                <div className="confirmation-cards__bottom-data">
                                                    <span>{i18n.get(`${FORM_ID}.reference.label`)}</span>
                                                </div>
                                                <div className="confirmation-cards__bottom-info">
                                                    <span>
                                                        <span>{cesionData?.reference}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        {cesionData?.referencevalue && (
                                            <div className="confirmation-cards__bottom">
                                                <div className="confirmation-cards__bottom-data">
                                                    <span>{i18n.get(`${FORM_ID}.referencevalue.label`)}</span>
                                                </div>
                                                <div className="confirmation-cards__bottom-info">
                                                    <span>{cesionData?.referencevalue}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            }
                        />
                    </CardContainer>
                    {echeqAction?.echeqList?.map((echeq) => (
                        <>
                            <CardContainer className="confirmation__cards-container mt-0">
                                <Card
                                    className={`confirmation__cards ${isDesktop && "confirm__icon-gray"}`}
                                    icon={isDesktop && "images/writing-pending.svg"}
                                    iconClass="card__pending-transaction-icon gray-icon"
                                    title={
                                        isDesktop ? (
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="confirmation-cards__title
                                            confirmation-cards-separator">
                                                    <div className="confirmation-cards__title-data no-wrap">
                                                        <span>{i18n.get(`${FORM_ID}.fecha_pago`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__title-info">
                                                        <FormattedDate showTime={false} date={echeq.fecha_pago} />
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__title confirmation-cards-separator">
                                                    <div className="confirmation-cards__title-data no-wrap">
                                                        <span>{i18n.get("echeq.emissionDate")}</span>
                                                    </div>
                                                    <div className="confirmation-cards__title-info">
                                                        <FormattedDate date={echeq.fecha_emision} />
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__title">
                                                    <div className="confirmation-cards__title-data no-wrap">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_id`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__title-info">
                                                        <span>{echeq.cheque_id}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="confirmation-cards__title">
                                                <div className="confirmation-cards__title-data">
                                                    <span>{i18n.get(`${FORM_ID}.cheque_numero`)}</span>
                                                </div>
                                                <div className="confirmation-cards__title-info no-wrap">
                                                    <span>{echeq.cheque_numero}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                    content={
                                        isDesktop ? (
                                            <div className="d-flex align-items-center">
                                                <div className="confirmation-cards__content pb-1">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_numero`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info">
                                                        <span>{echeq.cheque_numero}</span>
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__content info-type-a">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_motivo_pago`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info withEllipsis">
                                                        <div className="confirmation-cards__with-ellipsis">
                                                            <span>{echeq.cheque_motivo_pago}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="confirmation-cards__content">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get(`${FORM_ID}.fecha_pago`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info">
                                                        <FormattedDate showTime={false} date={echeq.fecha_pago} />
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__content">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get("echeq.emissionDate")}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info">
                                                        <FormattedDate date={echeq.fecha_emision} />
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__content">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_id`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info">
                                                        <span>
                                                            <span>{echeq.cheque_id}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__content">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_motivo_pago`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info withEllipsis">
                                                        <div className="confirmation-cards__with-ellipsis">
                                                            <span>{echeq.cheque_motivo_pago}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__content">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_caracter`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info">
                                                        <span className="text-uppercase">{echeq.cheque_caracter}</span>
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__content">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_modo`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info">
                                                        <span>{i18n.get(`echeqs.${echeq.cheque_modo}.modo`)}</span>
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__content">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_concepto`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info withEllipsis">
                                                        <div className="confirmation-cards__with-ellipsis">
                                                            <span>{echeqConceptLabel(echeq.cheque_concepto, "")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__content">
                                                    <div className="confirmation-cards__content-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_tipo`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__content-info">
                                                        <span>{echeq.cheque_tipo}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    bottom={
                                        isDesktop && (
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="confirmation-cards__bottom
                                            confirmation-cards-separator">
                                                    <div className="confirmation-cards__bottom-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_caracter`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__bottom-info text-uppercase">
                                                        <span>{echeq.cheque_caracter}</span>
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__bottom confirmation-cards-separator">
                                                    <div className="confirmation-cards__bottom-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_modo`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__bottom-info">
                                                        <span>{i18n.get(`echeqs.${echeq.cheque_modo}.modo`)}</span>
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__bottom confirmation-cards-separator">
                                                    <div className="confirmation-cards__bottom-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_concepto`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__bottom-info">
                                                        <span>{echeqConceptLabel(echeq.cheque_concepto, "")}</span>
                                                    </div>
                                                </div>
                                                <div className="confirmation-cards__bottom">
                                                    <div className="confirmation-cards__bottom-data">
                                                        <span>{i18n.get(`${FORM_ID}.cheque_tipo`)}</span>
                                                    </div>
                                                    <div className="confirmation-cards__bottom-info">
                                                        <span>{echeq.cheque_tipo}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    rightContent={
                                        <div className="confirmation-cards__right">
                                            <div className="confirmation-cards__right-data">
                                                <FormattedAmount
                                                    currency={i18n.get("currency.label.".concat(echeq.emisor_moneda))}
                                                    quantity={echeq.monto}
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            </CardContainer>
                        </>
                    ))}
                </>
            );
        }
        return (
            <>
                <CardContainer className="confirmation__cards-container mt-0">
                    <Card
                        className="confirmation__cards card__info mb-4"
                        hrClass="d-none"
                        title={
                            !isDesktop && (
                                <div className="confirmation-cards__title">
                                    <div className="confirmation-cards__title-data">
                                        <span>{i18n.get(`${FORM_ID}.add.beneficiary`)}</span>
                                    </div>
                                    <div className="confirmation-cards__title-info no-wrap">
                                        <span>{cesionData?.beneficiary?.reference?.replaceAll("#", "Ñ")}</span>
                                    </div>
                                </div>
                            )
                        }
                        content={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    <div className="confirmation-cards__content pb-1">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.add.beneficiary`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{cesionData?.beneficiary?.reference?.replaceAll("#", "Ñ")}</span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content info-type-a">
                                        <div className="confirmation-cards__content-info">
                                            <span>{cesionData?.beneficiary?.document?.toUpperCase()}</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.beneficiario_documento`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{cesionData?.beneficiary?.document?.toUpperCase()}</span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.reference.label`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{cesionData?.reference}</span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.referencevalue.label`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{cesionData?.referencevalue}</span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.address.label`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{cesionData?.address}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        bottom={
                            isDesktop && (
                                <div className="d-flex align-items-center">
                                    {cesionData?.address && (
                                        <div
                                            className={`confirmation-cards__bottom ${(cesionData?.reference ||
                                                cesionData?.referencevalue) &&
                                                "confirmation-cards-separator"}`}>
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get(`${FORM_ID}.address.label`)}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{cesionData?.address}</span>
                                            </div>
                                        </div>
                                    )}
                                    {cesionData?.reference && (
                                        <div
                                            className={`confirmation-cards__bottom ${cesionData?.referencevalue &&
                                                "confirmation-cards-separator"}`}>
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get(`${FORM_ID}.reference.label`)}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>
                                                    <span>{cesionData?.reference}</span>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {cesionData?.referencevalue && (
                                        <div className="confirmation-cards__bottom">
                                            <div className="confirmation-cards__bottom-data">
                                                <span>{i18n.get(`${FORM_ID}.referencevalue.label`)}</span>
                                            </div>
                                            <div className="confirmation-cards__bottom-info">
                                                <span>{cesionData?.referencevalue}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    />
                </CardContainer>
                <CardContainer className="confirmation__cards-container mt-0">
                    <Card
                        className={`confirmation__cards ${isDesktop && "confirm__icon-gray"}`}
                        icon={isDesktop && "images/writing-pending.svg"}
                        iconClass="card__pending-transaction-icon gray-icon"
                        title={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    <div
                                        className="confirmation-cards__title
                                            confirmation-cards-separator">
                                        <div className="confirmation-cards__title-data no-wrap">
                                            <span>{i18n.get(`${FORM_ID}.fecha_pago`)}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <FormattedDate
                                                showTime={false}
                                                date={echeqAction?.echeqList[0]?.fecha_pago}
                                            />
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__title confirmation-cards-separator">
                                        <div className="confirmation-cards__title-data no-wrap">
                                            <span>{i18n.get("echeq.emissionDate")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <FormattedDate date={echeqAction?.echeqList[0]?.fecha_emision} />
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data no-wrap">
                                            <span>{i18n.get(`${FORM_ID}.cheque_id`)}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>{echeqAction?.echeqList[0]?.cheque_id}</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="confirmation-cards__title">
                                    <div className="confirmation-cards__title-data">
                                        <span>{i18n.get(`${FORM_ID}.cheque_numero`)}</span>
                                    </div>
                                    <div className="confirmation-cards__title-info no-wrap">
                                        <span>{echeqAction?.echeqList[0]?.cheque_numero}</span>
                                    </div>
                                </div>
                            )
                        }
                        content={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    <div className="confirmation-cards__content pb-1">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_numero`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{echeqAction?.echeqList[0]?.cheque_numero}</span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content info-type-a">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_motivo_pago`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info withEllipsis">
                                            <div className="confirmation-cards__with-ellipsis">
                                                <span>{echeqAction?.echeqList[0]?.cheque_motivo_pago}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.fecha_pago`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <FormattedDate
                                                showTime={false}
                                                date={echeqAction?.echeqList[0]?.fecha_pago}
                                            />
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("echeq.emissionDate")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <FormattedDate date={echeqAction?.echeqList[0]?.fecha_emision} />
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_id`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>
                                                <span>{echeqAction?.echeqList[0]?.cheque_id}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_motivo_pago`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info withEllipsis">
                                            <div className="confirmation-cards__with-ellipsis">
                                                <span>{echeqAction?.echeqList[0]?.cheque_motivo_pago}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_caracter`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span className="text-uppercase">
                                                {echeqAction?.echeqList[0]?.cheque_caracter}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_modo`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>
                                                {i18n.get(`echeqs.${echeqAction?.echeqList[0]?.cheque_modo}.modo`)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_concepto`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info withEllipsis">
                                            <div className="confirmation-cards__with-ellipsis">
                                                <span>
                                                    {echeqConceptLabel(echeqAction?.echeqList[0]?.cheque_concepto, "")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_tipo`)}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{echeqAction?.echeqList[0]?.cheque_tipo}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        bottom={
                            isDesktop && (
                                <div className="d-flex align-items-center">
                                    <div
                                        className="confirmation-cards__bottom
                                            confirmation-cards-separator">
                                        <div className="confirmation-cards__bottom-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_caracter`)}</span>
                                        </div>
                                        <div className="confirmation-cards__bottom-info">
                                            <span className="text-uppercase">
                                                {echeqAction?.echeqList[0]?.cheque_caracter}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__bottom confirmation-cards-separator">
                                        <div className="confirmation-cards__bottom-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_modo`)}</span>
                                        </div>
                                        <div className="confirmation-cards__bottom-info">
                                            <span>
                                                {i18n.get(`echeqs.${echeqAction?.echeqList[0]?.cheque_modo}.modo`)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__bottom confirmation-cards-separator">
                                        <div className="confirmation-cards__bottom-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_concepto`)}</span>
                                        </div>
                                        <div className="confirmation-cards__bottom-info">
                                            <span>
                                                {echeqConceptLabel(echeqAction?.echeqList[0]?.cheque_concepto, "")}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__bottom">
                                        <div className="confirmation-cards__bottom-data">
                                            <span>{i18n.get(`${FORM_ID}.cheque_tipo`)}</span>
                                        </div>
                                        <div className="confirmation-cards__bottom-info">
                                            <span>{echeqAction?.echeqList[0]?.cheque_tipo}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        rightContent={
                            <div className="confirmation-cards__right">
                                <div className="confirmation-cards__right-data">
                                    <FormattedAmount
                                        currency={i18n.get(
                                            "currency.label.".concat(echeqAction?.echeqList[0]?.emisor_moneda),
                                        )}
                                        quantity={echeqAction?.echeqList[0]?.monto}
                                    />
                                </div>
                            </div>
                        }
                    />
                </CardContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userId: sessionSelectors.getUser(state).userId,
});

export default compose(connect(mapStateToProps))(CesionSummaryData);
