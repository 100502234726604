import React, { Component } from "react";
import { shape } from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers/checks";
import FormattedDate from "pages/_components/FormattedDate";
import moment from "moment";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";
import { echeqConceptLabel } from "util/checks";
import Heading from "pages/_components/Heading";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import * as configUtils from "util/config";

const FORM_ID = "echeq.transaction";

class EmitEcheqTransactionData extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
    };

    dateTimeCreationIsDifferentFromModify = (creation, modification) => {
        const creation_ = moment(creation);
        const modification_ = moment(modification);
        return modification_.diff(creation_, "seconds") > 1;
    };

    render() {
        const { transactionData } = this.props;

        const echeqsGeneralData = JSON.parse(transactionData?.echeqNumeration);

        const concept = `${echeqConceptLabel(transactionData?.concept, "")}`;

        const chequesFunctionalities = configUtils.getBoolean(
            "frontend.show.failedVoucher.chequesFunctionalities",
            false,
        );

        return (
            transactionData?.idTransaction && (
                <>
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.date.time`}
                        data={<FormattedDate date={transactionData.submitDateTime || transactionData.creationDate} dateFormat="dd/MM/yyyy" showTime />}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.transaction`}
                        data={transactionData.idTransaction.substring(0, 8).toUpperCase()}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="echeq.transaction.environment.cuit"
                        data={transactionData?.environmentCuit}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label="echeqs.emit.emision.label"
                        data={transactionData?.accountFull}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.beneficiary`}
                        data={transactionData?.beneficiary?.reference?.replaceAll("#", "Ñ")}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.beneficiary.document`}
                        data={transactionData?.beneficiary?.document}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.concept`}
                        data={concept}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.motive`}
                        data={transactionData?.motive}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.mode`}
                        data={transactionData?.mode}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.echeq.character`}
                        data={transactionData?.character}
                    />
                    {transactionData?.idTransactionStatus === "FINISHED" && (
                        <Heading.DataGroup
                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                            labelClassName="data-label-medium"
                            dataClassName="data-desc"
                            label={`${FORM_ID}.emision.date`}
                            data={
                                <FormattedDate date={moment(transactionData?.creationDate)} dateFormat="dd/MM/yyyy" />
                            }
                        />
                    )}
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.payed`}
                        data={
                            <FormattedDate
                                date={moment(transactionData?.echeqsToEmit[0]?.date)}
                                dateFormat="dd/MM/yyyy"
                            />
                        }
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.echeq.type`}
                        data={i18n.get(`echeqs.emit.${transactionData?.echeqsToEmit[0]?.type.toLowerCase()}.label`)}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.reference`}
                        data={transactionData?.echeqsToEmit[0]?.reference}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.value.reference`}
                        data={transactionData?.echeqsToEmit[0]?.referencevalue}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc"
                        label={`${FORM_ID}.echeq.quantity`}
                        data={transactionData?.echeqsToEmit.length}
                    />
                    <Heading.DataGroup
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                        labelClassName="data-label-medium"
                        dataClassName="data-desc line-height-1"
                        label={`${FORM_ID}.amount`}
                        data={
                            <FormattedAmount
                                currency="0"
                                quantity={
                                    transactionData?.echeqsToEmit[0]?.amount * transactionData?.echeqsToEmit.length
                                }
                                notBold
                            />
                        }
                    />
                    {echeqsGeneralData.map((echeq) => (
                        <div
                            key={echeq?.cheque_numero}
                            className={classNames({ "mt-4": isDesktop }, { "mt-3": !isDesktop })}>
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label={`${FORM_ID}.checkbook`}
                                data={echeq?.chequera}
                            />
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label={`${FORM_ID}.echeq.number`}
                                data={echeq?.cheque_numero}
                            />
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc line-height-1"
                                label={`${FORM_ID}.check.amount.label`}
                                data={
                                    <FormattedAmount
                                        currency="0"
                                        quantity={parseFloat(transactionData?.echeqsToEmit[0]?.amount)}
                                        notBold
                                    />
                                }
                            />

                            {transactionData.idTransactionStatus === "FINISHED" && (
                                <>
                                    <Heading.DataGroup
                                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                        labelClassName="data-label-medium"
                                        dataClassName="data-desc"
                                        label={`${FORM_ID}.echeq.id`}
                                        data={
                                            transactionData?.emittedEcheqs
                                                ? transactionData?.emittedEcheqs[echeq?.cheque_numero]
                                                : ""
                                        }
                                    />
                                    {transactionData?.modes === "MULT" && (
                                        <Heading.DataGroup
                                            containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                            labelClassName="data-label-medium"
                                            dataClassName="data-desc"
                                            label={`${FORM_ID}.echeq.agrupador_id`}
                                            data={
                                                transactionData?.emittedEcheqs?.echeqExtraData
                                                    ? transactionData.emittedEcheqs.echeqExtraData[echeq?.cheque_numero]
                                                          ?.agrupador_id
                                                    : ""
                                            }
                                        />
                                    )}
                                    <Heading.DataGroup
                                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                        labelClassName="data-label-medium"
                                        dataClassName="data-desc"
                                        label={`${FORM_ID}.echeq.cmc7`}
                                        data={echeq?.cmc7}
                                    />
                                </>
                            )}
                        </div>
                    ))}
                    {!chequesFunctionalities && (
                        <>
                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label={`${FORM_ID}.coelsa.response`}
                                data={transactionData?.coelsaResponse}
                            />

                            <Heading.DataGroup
                                containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                                labelClassName="data-label-medium"
                                dataClassName="data-desc"
                                label={`${FORM_ID}.coelsa.code`}
                                data={transactionData?.coelsaCode}
                            />
                        </>
                    )}
                </>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionEcheqData(state),
});

export default connect(mapStateToProps)(EmitEcheqTransactionData);
