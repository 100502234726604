import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import SwitchField from "pages/_components/fields/SwitchField";
import { maskedAccountNumber } from "util/accounts";
import { actions as debinActions } from "reducers/debin";
import * as i18n from "util/i18n";
import { func, shape, string, arrayOf, bool } from "prop-types";
import { push } from "react-router-redux";

class DebinAccountItem extends Component {
    state = {
        checked: false,
    };

    static propTypes = {
        dispatch: func.isRequired,
        account: shape({}).isRequired,
        idProduct: string.isRequired,
        productAlias: string.isRequired,
        number: string.isRequired,
        productType: string.isRequired,
        currency: string.isRequired,
        cbu: string.isRequired,
        debinAccounts: arrayOf(shape({})).isRequired,
        isDesktop: bool.isRequired,
    };

    componentDidMount() {
        const { cbu, debinAccounts } = this.props;
        const debinAccount = debinAccounts.find((account) => account.cbu === cbu);
        if (debinAccount) {
            this.setState({ checked: true });
        }
    }

    handleChange = (ev) => {
        const { dispatch, ...account } = this.props;
        this.setState({ checked: ev.target.checked });
        if (ev.target.checked) {
            dispatch(debinActions.setAccountToChange(account));
            dispatch(push("addAccounts/summary"));
        } else {
            dispatch(debinActions.removeSellerAccount(account));
        }
    };

    render() {
        const { idProduct, productAlias, number, productType, currency, cbu, isDesktop } = this.props;
        const { checked } = this.state;
        const maskedNumber = maskedAccountNumber(number);
        const reference = `${i18n.get("global.number")} ${maskedNumber}`;
        const productName = productAlias || reference;

        if (isDesktop) {
            return (
                <Row className="container-white" style={{ width: "100%", margin: "0.25rem 0" }}>
                    <div className="Product">
                        <div
                            className="Product-row product-row-min-width-content account-list-div"
                            style={{ marginRight: "2rem" }}>
                            <SwitchField
                                hideLabel
                                name={idProduct}
                                key={idProduct}
                                onChange={(event) => this.handleChange(event)}
                                value={checked}
                                style={{ width: "2rem" }}
                            />
                        </div>
                        <div className="Product-row account-list-div">
                            <div className="data-desc">
                                <span className="single-line-ellipsis">{productName}</span>
                            </div>
                        </div>
                        <div className="Product-row account-list-div">
                            <div className="data-desc">{`${productType} ${i18n.get(
                                `currency.label.${currency}`,
                            )}`}</div>
                        </div>
                        <div className="Product-row account-list-div">
                            <div className="data-desc">{reference}</div>
                        </div>
                        <div className="Product-row product-row-max-width-content account-list-div">
                            <div className="data-desc">{`${i18n.get(
                                `debin.addAccount.summary.cbu.label`,
                            )} ${cbu}`}</div>
                        </div>
                    </div>
                </Row>
            );
        }

        return (
            <div
                className="container-white with-padding-1-rem"
                style={{ width: "100%", display: "flex", margin: "0.5rem" }}>
                <div style={{ marginRight: "2rem" }}>
                    <SwitchField
                        hideLabel
                        name={idProduct}
                        key={idProduct}
                        onChange={(event) => this.handleChange(event)}
                        value={checked}
                        style={{ width: "1rem" }}
                    />
                </div>
                <div>
                    <div className="data-label" style={{ width: "100%" }}>
                        <span className="single-line-ellipsis">{productName}</span>
                    </div>
                    <div className="data-label" style={{ width: "100%" }}>{`${productType} ${i18n.get(
                        `currency.label.${currency}`,
                    )} ${reference}`}</div>
                    <div className="data-label " style={{ width: "100%" }}>{`${i18n.get(
                        `debin.addAccount.summary.cbu.label`,
                    )} ${cbu}`}</div>
                </div>
            </div>
        );
    }
}

export default DebinAccountItem;
