export default {
    LIST_TRANSACTION_LINES_REQUEST: "forms/LIST_TRANSACTION_LINES_REQUEST",
    LIST_TRANSACTION_LINES_FAILURE: "forms/LIST_TRANSACTION_LINES_FAILURE",
    CLEAR_TRANSACTION_LINES: "forms/CLEAR_TRANSACTION_LINES",
    EDIT_TRANSACTION_LINE: "forms/EDIT_TRANSACTION_LINE",
    LIST_TRANSACTION_LINES_SUCCESS: "forms/LIST_TRANSACTION_LINES_SUCCESS",
    ADD_TRANSACTION_LINE: "forms/ADD_TRANSACTION_LINE",
    REMOVE_TRANSACTION_LINE: "forms/REMOVE_TRANSACTION_LINE",
    SAVE_CHANGED_TRANSACTION_LINES: "forms/SAVE_CHANGED_TRANSACTION_LINES",
    CANCEL_CHANGED_TRANSACTION_LINES: "forms/CANCEL_CHANGED_TRANSACTION_LINES",
    CHANGE_TRANSACTION_LINE: "forms/CHANGE_TRANSACTION_LINE",
    STOP_RESETTING_LINES: "forms/STOP_RESETTING_LINES",
    INITIALIZE_EDITED_LINES: "forms/INITIALIZE_EDITED_LINES",
    ADD_EDITED_LINES: "forms/ADD_EDITED_LINES",
    SUBSTRACT_EDITED_LINES: "forms/SUBSTRACT_EDITED_LINES",
    RESET_EDITED_LINES: "forms/RESET_EDITED_LINES",
    REMOVE_ALL_FROM_INITIAL_VALUES: "forms/REMOVE_ALL_FROM_INITIAL_VALUES",
    SET_CURRENCY: "forms/SET_CURRENCY",
    ON_PAYMENT_METHOD_CHANGE: "forms/ON_PAYMENT_METHOD_CHANGE",
    SET_IS_EDITING_PAYMENT: "forms/SET_IS_EDITING_PAYMENT",
    SET_PAGE_NUMBER: "forms/SET_PAGE_NUMBER",
    LOAD_TEMPLATE_DATA: "forms/LOAD_TEMPLATE_DATA",
    ON_FILTERS_CHANGED: "forms/ON_FILTERS_CHANGED",
};
