import React from "react";
import I18n from "pages/_components/I18n";
import * as i18nUtils from "util/i18n";

const TransactionAditionalInfo = {
    "accounts.opening": ({ currency, accountType }) =>
        `${i18nUtils.get(`accounts.new.account.accountType.${accountType}`)} ${i18nUtils.get(
            `accounts.new.account.currency.${currency}`,
        )}`,
    "account.checks": ({ checksTypes }) => checksTypes.label,
    "pay.multiline.send": ({ typePayment }) =>
        (typePayment === "PH" // eslint-disable-line
            ? i18nUtils.get("payments.payThirdParties.newPayment.salaryPayments.label")
            : i18nUtils.get("payments.payThirdParties.newPayment.supplierPayments.label")), // eslint-disable-line
    "administration.advanced.group.create.send": ({ name }) => name,
    "administration.advanced.group.modify.send": ({ name }) => name,
    "administration.groups.blockunblock.send": ({ groupNameList }) =>
        groupNameList?.map((groupName) => <div key={groupName}> {groupName} </div>),
    "administration.groups.delete.send": ({ groupNameList }) =>
        groupNameList?.map((groupName) => <div key={groupName}> {groupName} </div>),
    "administration.users.blockunblock.send": ({ userNameList }) =>
        userNameList?.map((userName) => <div key={userName}> {userName} </div>),
    "administration.users.delete.send": ({ userNameList }) =>
        userNameList?.map((userName) => <div key={userName}> {userName} </div>),
    "administration.users.update": ({ userFullName }) => userFullName,
    "administration.signatures.create.send": ({ signatureLevelsCounts }, administrationScheme) =>
        (administrationScheme === "medium" // eslint-disable-line
            ? `${i18nUtils.get("administration.signatures.create.medium.confirm.signersCount")} ` +
              `${signatureLevelsCounts.A}`
            : Object.keys(signatureLevelsCounts).reduce(
                  (result, key) => result + key.toString().repeat(signatureLevelsCounts[key]),
                  "",
              )), // eslint-disable-line
    "administration.signatures.delete.send": ({ signatureLevelsCounts }, administrationScheme) =>
        (administrationScheme === "medium" // eslint-disable-line
            ? `${i18nUtils.get("administration.signatures.create.medium.confirm.signersCount")} ` +
              `${signatureLevelsCounts.A}`
            : Object.keys(signatureLevelsCounts).reduce(
                  (result, key) => result + key.toString().repeat(signatureLevelsCounts[key]),
                  "",
              )), // eslint-disable-line
    "administration.signatures.modify.send": ({ signatureLevelsCounts }, administrationScheme) =>
        (administrationScheme === "medium" // eslint-disable-line
            ? `${i18nUtils.get("administration.signatures.create.medium.confirm.signersCount")} ` +
              `${signatureLevelsCounts.A}`
            : Object.keys(signatureLevelsCounts).reduce(
                  (result, key) => result + key.toString().repeat(signatureLevelsCounts[key]),
                  "",
              )), // eslint-disable-line
    "administration.users.invite.send": ({ firstName, lastName }) => `${firstName} ${lastName}`,
    "administration.restrictions.manage.send": ({ userName }) =>
        userName || `${i18nUtils.get("administration.restrictions.restrictions.environment")}`,
    "administration.restrictions.user.delete.send": ({ userFullName }) => userFullName,
    "administration.medium.modify.signature.send": ({ userFullName }) => userFullName,
    "administration.medium.modify.permissions.send": ({ userFullName }) => userFullName,
    "administration.simple.modify.permissions.send": ({ userFullName }) => userFullName,
    "administration.medium.modify.channels.send": ({ enabledChannels }) =>
        enabledChannels
            .filter((channel) => channel !== "all")
            .map((channel) => <I18n id={`channels.${channel}`} component="div" />),
    "administration.user.detail.groups.modify.send": ({ groupNameList }) =>
        groupNameList?.map((groupName) => <div key={groupName}> {groupName} </div>),
    "debin.incoming.accept.send": ({ idDebin }) => (
        <span style={{ textTransform: "uppercase" }}>{`${i18nUtils.get("payments.debin.table.header.id")}: ${idDebin ||
            ""}`}</span>
    ),
    "credin.sentRequest.send": ({ recipients }) => (
        <div>
            <span style={{ textTransform: "uppercase" }}>{`${i18nUtils.get("credin.transaction.id.credin")}:`}</span>
            {Array.isArray(recipients) &&
                recipients?.map(({ id }, index) => (
                    <>
                        {index !== 0 ? <span className="px-2">|</span> : ""}
                        <span>{id}</span>
                    </>
                ))}
        </div>
    ),
};

export default TransactionAditionalInfo;
