import React, { Component } from "react";
import { number, shape, func, node, string, oneOfType } from "prop-types";
import classNames from "classnames";

import { withList } from "pages/_components/List";

class ListItem extends Component {
    static propTypes = {
        num: number.isRequired,
        list: shape({
            onSelect: func.isRequired,
        }).isRequired,
        children: oneOfType([func, node]).isRequired,
        className: string,
        role: string,
    };

    static defaultProps = {
        className: "",
        role: null,
    };

    handleClick = () => {
        const { list, num } = this.props;
        list.onSelect(num);
    };

    renderContent = (children) => {
        if (typeof children === "function") {
            return children(({ className = "btn", ...props }) => (
                <button type="button" className={className} onClick={this.handleClick}>
                    {props.children}
                </button>
            ));
        }

        return (
            <div className="list-item-inner">
                <button type="button" className="btn" onClick={this.handleClick}>
                    {children}
                </button>
            </div>
        );
    };

    render() {
        const { children, className, role } = this.props;
        return (
            <li className={classNames("list-item", className)} role={role}>
                {this.renderContent(children)}
            </li>
        );
    }
}

export default withList(ListItem);
