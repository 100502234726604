/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool } from "prop-types";
import * as i18n from "util/i18n";
import { selectors as transactionsSelectors } from "reducers/transactions";
import { getAccountInfo } from "middleware/accounts";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";

class RequestChecbookDataTable extends Component {
    static propTypes = {
        multipleSignaturesData: shape({}).isRequired,
        isDesktop: bool.isRequired,
    };

    state = {
        body: "",
    };

    componentDidMount = () => {
        this.tableBody();
    };

    tableBody = () => {
        const { multipleSignaturesData } = this.props;
        const { selectedTransactionRows } = multipleSignaturesData;

        const bodyPromises = selectedTransactionRows.map((object) => {
            const { account } = object.transaction.data;
            return this.accountInfo(account);
        });

        Promise.all(bodyPromises).then((values) => {
            this.setState({
                body: selectedTransactionRows.map((object, index) => this.dataRow(object, values[index])),
            });
        });
    };

    dataRow = (object, cuenta) => {
        const { isDesktop } = this.props;

        return (
            <>
                <CardContainer className="confirmation__cards-container mt-0">
                    <Card
                        className="confirmation__cards"
                        icon={isDesktop && "images/transfer-PENDING.svg"}
                        iconClass="card__pending-transaction-icon"
                        hrClass="d-none"
                        title={
                            isDesktop ? (
                                <div className="d-flex align-items-center w-100 no-wrap">
                                    <div className="confirmation-cards__title confirmation-cards-separator">
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get("table.requestCheckbook.Sol")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>{object.transaction.data?.checkBooksAmount}</span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__title">
                                        <div className="confirmation-cards__title-data">
                                            <span>{i18n.get("table.requestCheckbook.checksAmount")}</span>
                                        </div>
                                        <div className="confirmation-cards__title-info">
                                            <span>{object.transaction.data?.checkBookChecksAmount}</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="confirmation-cards__title">
                                    <div className="confirmation-cards__title-data">
                                        <span>{i18n.get("table.requestCheckbook.checkType")}</span>
                                    </div>
                                    <div className="confirmation-cards__title-info">
                                        <span>{object.transaction.data?.checksTypes.label}</span>
                                    </div>
                                </div>
                            )
                        }
                        content={
                            isDesktop ? (
                                <div className="d-flex align-items-center">
                                    <div
                                        className={
                                            object.transaction.data?.document !== "" ||
                                            object.transaction.data?.name !== ""
                                                ? "confirmation-cards__content pb-1"
                                                : "confirmation-cards__content"
                                        }>
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("table.requestCheckbook.checkType")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{object.transaction.data?.checksTypes.label}</span>
                                        </div>
                                    </div>
                                    {object.transaction.data?.document !== "" && (
                                        <div className="confirmation-cards__content info-type-a">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.requestCheckbook.document")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {object.transaction.data?.document !== "" &&
                                                        object.transaction.data?.documentType.concat(
                                                            " ",
                                                            object.transaction.data?.document,
                                                        )}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {object.transaction.data?.name !== "" && (
                                        <div className="confirmation-cards__content info-type-b">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.requestCheckbook.name")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {object.transaction.data?.name !== "" &&
                                                        object.transaction.data?.name.concat(
                                                            " ",
                                                            object.transaction.data?.surname,
                                                        )}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("table.requestCheckbook.Sol")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{object.transaction.data?.checkBooksAmount}</span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("table.requestCheckbook.checksAmount")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{object.transaction.data?.checkBookChecksAmount}</span>
                                        </div>
                                    </div>
                                    {object.transaction.data?.document !== "" && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.requestCheckbook.document")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {object.transaction.data?.document !== "" &&
                                                        object.transaction.data?.documentType.concat(
                                                            " ",
                                                            object.transaction.data?.document,
                                                        )}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {object.transaction.data?.name !== "" && (
                                        <div className="confirmation-cards__content">
                                            <div className="confirmation-cards__content-data">
                                                <span>{i18n.get("table.requestCheckbook.name")}</span>
                                            </div>
                                            <div className="confirmation-cards__content-info">
                                                <span>
                                                    {object.transaction.data?.name !== "" &&
                                                        object.transaction.data?.name.concat(
                                                            " ",
                                                            object.transaction.data?.surname,
                                                        )}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("table.requestCheckbook.account")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>{cuenta}</span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__content">
                                        <div className="confirmation-cards__content-data">
                                            <span>{i18n.get("table.transferInternal.idTransaction")}</span>
                                        </div>
                                        <div className="confirmation-cards__content-info">
                                            <span>
                                                {object.transaction.idTransaction.substring(0, 6).toUpperCase()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        bottom={
                            isDesktop && (
                                <div className="d-flex align-items-center">
                                    <div className="confirmation-cards__bottom confirmation-cards-separator">
                                        <div className="confirmation-cards__bottom-data">
                                            <span>{i18n.get("table.requestCheckbook.account")}</span>
                                        </div>
                                        <div className="confirmation-cards__bottom-info">
                                            <span>{cuenta}</span>
                                        </div>
                                    </div>
                                    <div className="confirmation-cards__bottom">
                                        <div className="confirmation-cards__bottom-data">
                                            <span>{i18n.get("table.transferInternal.idTransaction")}</span>
                                        </div>
                                        <div className="confirmation-cards__bottom-info">
                                            <span>
                                                {object.transaction.idTransaction.substring(0, 6).toUpperCase()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    />
                </CardContainer>
            </>
        );
    };

    accountInfo = async (account) => {
        const response = await getAccountInfo(account);
        const accountData = response.data.data.account;

        return accountData.number.concat(
            " ",
            accountData.productType,
            " ",
            i18n.get(`currency.label.${accountData.currency}`),
        );
    };

    render() {
        const { body } = this.state;
        return body;
    }
}
const mapStateToProps = (state) => ({
    multipleSignaturesData: transactionsSelectors.getMultipleSignaturesData(state),
});

export default connect(mapStateToProps)(RequestChecbookDataTable);
