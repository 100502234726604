import React, { Component } from "react";
import { bool, shape } from "prop-types";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { compose } from "redux";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";

import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import * as i18n from "util/i18n";

import Container from "pages/_components/Container";
import Enviroments from "pages/_components/Enviroments";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import Button from "pages/_components/Button";
import { Mixpanel } from "util/clickstreaming";

const FORM_ID = "login.step3";

class Step3Content extends Component {
    static propTypes = {
        isSubmitting: bool,
        environments: shape([]).isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
    };

    render() {
        const { environments, isSubmitting } = this.props;

        return (
            <Form className="above-the-fold login-desktop-wrapper">
                <Container className="container--layout flex-grow align-items-center">
                    <Col sm={12} md={12} lg={12} className="col col-12">
                        <Field
                            name="environment"
                            environments={environments}
                            component={Enviroments}
                            legendTextID="login.step3.environment.required"
                            fromSetDefaultEnvironmentPage={false}
                            fromLoginSelectEnvironment
                        />
                        <Field name="rememberEnvironment" idForm={FORM_ID} component={SwitchField} formGroup />
                    </Col>
                </Container>
                <Container className="container--layout align-items-center">
                    <Col sm={12} md={12} lg={12} className="col col-12 pt-4">
                        <Button
                            className="text-none"
                            type="submit"
                            loading={isSubmitting}
                            label="login.nextStep"
                            bsStyle="primary"
                        />
                    </Col>
                </Container>
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    environments: Object.entries(loginSelectors.getEnvironments(state)),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            environment: props.environments.lenght > 0 ? props.environments[0][0] : "",
            rememberEnvironment: false,
        }),
        validationSchema: () =>
            Yup.object().shape({
                environment: Yup.string().required(i18n.get(`${FORM_ID}.environment.required`)),
            }),
        handleSubmit: ({ environment, rememberEnvironment }, formikBag) => {
            Mixpanel.track(FORM_ID, { environment, rememberEnvironment });
            formikBag.props.dispatch(loginActions.loginStep3(environment, rememberEnvironment, formikBag));
        },
    }),
)(Step3Content);
