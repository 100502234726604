/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { bool, string, func, shape } from "prop-types";

import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";

import Notification from "pages/_components/Notification";
import Step2Content from "pages/login/_components/Step2Content";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";
import TooltipTour from "pages/forms/_components/_fields/TooltipTour";
import * as i18n from "util/i18n";
import { push } from "react-router-redux/actions";
import I18n from "pages/_components/I18n";
import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Logo from "pages/login/_components/Logo";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";

let stepsTour = [];
let runTooltipTour = false;

class LoginStep2 extends Component {
    componentDidMount() {
        const { dispatch, lastLogin, activeEnvironment, username } = this.props;
        if (activeEnvironment) {
            dispatch(push("/desktop"));
            return;
        }
        if (!username) {
            dispatch(push("/"));
            return;
        }
        stepsTour.push({
            selector: "figureId",
            title: "tooltipTour.login.securityImage.title",
            content: i18n.get("tooltipTour.login.securityImage.content"),
        });
        runTooltipTour = !lastLogin;
    }

    componentWillUnmount() {
        stepsTour = [];
        runTooltipTour = false;
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    renderTitleMobile = () => (
        <div className="w-min-content">
            <Logo className="svg-image logo logo-mobile-size" logoColor />
        </div>
    );

    render() {
        const { isDesktop, userFirstName, isInFlow, propsGrid } = this.props;

        if (!isDesktop) {
            return (
                <>
                    <TooltipTour
                        steps={stepsTour}
                        run={runTooltipTour}
                        showNavigation={false}
                        showCloseButton={false}
                        showNumber={false}
                        closeWithMask={false}
                        lastStepNextButton={
                            <Button className="btn btn-outline btn-small">
                                {i18n.get("tooltipTour.lastButton.label")}
                            </Button>
                        }
                    />
                    <Notification scopeToShow="loginStep2" />
                    {isInFlow && (
                        <>
                            <Container>
                                <Col xs="12">
                                    <Head
                                        replace={{
                                            USER_NAME:
                                                userFirstName && userFirstName.length > 1
                                                    ? userFirstName
                                                          .split(" ")
                                                          .map(
                                                              (x) =>
                                                                  x.charAt(0).toUpperCase() + x.slice(1).toLowerCase(),
                                                          )
                                                          .join(" ")
                                                    : "",
                                        }}
                                        onClose={this.onHeaderClose}
                                        accessibilityTextId="login.step2.header.a11y"
                                        headerClassName="login-background-color-white"
                                        fixed
                                        centerElement={this.renderTitleMobile}
                                        hideMobileMenu
                                    />
                                </Col>
                            </Container>
                            <div className="login-second-step">
                                <MainContainer className="login__layout main-container">
                                    <Container>
                                        <Col xs="12">
                                            <h4>
                                                <I18n
                                                    id="login.step2.header"
                                                    accessibilityTextId="login.step2.header.a11y"
                                                />
                                            </h4>
                                        </Col>
                                    </Container>
                                    <Step2Content isDesktop={isDesktop} />
                                </MainContainer>
                            </div>
                        </>
                    )}
                </>
            );
        }

        return (
            <GridLayoutExternal scopeToShow="loginStep2" isDesktop={isDesktop} {...propsGrid}>
                <TooltipTour
                    steps={stepsTour}
                    run={runTooltipTour}
                    showNavigation={false}
                    showCloseButton={false}
                    showNumber={false}
                    closeWithMask={false}
                    lastStepNextButton={
                        <Button className="btn btn-outline btn-small">
                            {i18n.get("tooltipTour.lastButton.label")}
                        </Button>
                    }
                />
                {isInFlow && (
                    <>
                        <Head
                            title="login.step2.header"
                            replace={{
                                USER_NAME:
                                    userFirstName && userFirstName.length > 1
                                        ? userFirstName
                                              .split(" ")
                                              .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
                                              .join(" ")
                                        : "",
                            }}
                            onClose={this.onHeaderClose}
                            headerClassName="recover-pass-desktop login-header-desktop"
                            fixed
                            tag="h4"
                        />
                        <div className="login-second-step">
                            <MainContainer className={classNames("login__layout main-container", "container-fluid")}>
                                <Step2Content isDesktop={isDesktop} />
                            </MainContainer>
                        </div>
                    </>
                )}
            </GridLayoutExternal>
        );
    }
}

LoginStep2.propTypes = {
    isDesktop: bool.isRequired,
    userFirstName: string.isRequired,
    isInFlow: bool.isRequired,
    dispatch: func.isRequired,
    propsGrid: shape({}).isRequired,
    lastLogin: string,
    username: string.isRequired,
    activeEnvironment: shape({}).isRequired,
};

LoginStep2.defaultProps = {
    lastLogin: null,
};

const mapStateToProps = (state) => ({
    userFirstName: loginSelectors.getUserFirstName(state) || i18n.get("login.defaultUserName"),
    isInFlow: loginSelectors.getIsInFlow(state),
    lastLogin: loginSelectors.getLastLogin(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    username: loginSelectors.getUsername(state),
});

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(LoginStep2));
