import { call, put, takeLatest, select } from "redux-saga/effects";
import { routerActions, replace } from "react-router-redux";

import { actions as notificationActions } from "reducers/notification";

import { credentialsToUnderscoreFormat } from "util/form.js";
import { selectors as sessionSelectors } from "reducers/session";
import * as administrationUtils from "util/administration";
import * as utilDownload from "util/download";
import * as form from "middleware/form";
import * as i18n from "util/i18n";

const createSignaturesSchemePreRequest = (middleware, actions) =>
    function* createSignaturesSchemePreRequestSaga() {
        const { data, type } = yield call(middleware.createSignaturesSchemePre);

        if (type === "W") {
            yield put(actions.createSignaturesSchemePreFailure());
            const errorMessage = data?.message || i18n.get("global.unexpectedError");
            yield put(notificationActions.showNotification(errorMessage, "error", ["signature"]));
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.signatures.create.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.createSignaturesSchemePreSuccess(data.data, credentialGroups));
        }
    };

const createSignaturesSchemeRequest = (middleware, actions) =>
    function* createSignaturesSchemeRequestSaga({ signatureData, formikBag }) {
        const { setSubmitting } = formikBag;
        const { credentials, signatureLevelsCounts, ...restOfParams } = signatureData;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
        const signatureLevelCountToInt = administrationUtils.signatuleLevelsCountToInt(signatureLevelsCounts);
        const { data, type } = yield call(middleware.createSignaturesScheme, {
            ...restOfParams,
            signatureLevelsCounts: signatureLevelCountToInt,
            ...credentialsWithUnderscore,
            ...{
                capFrequencies: [restOfParams.topAmount.period] || [],
                maxAmount: restOfParams.topAmount.amount || -1,
            },
        });

        setSubmitting(false);

        if (type === "W") {
            const errorMessage = data.data.signatureGroup || data.message || i18n.get("global.unexpectedError");

            if (data.code === "COR020W") {
                formikBag.setErrors(data.data);
            } else {
                yield put(notificationActions.showNotification(errorMessage, "error", ["signature"]));
            }
        } else {
            let confirmationMessage;

            switch (data.code) {
                case "COR023I":
                    confirmationMessage = data.message;
                    break;
                default:
                    confirmationMessage = i18n.get("administration.signatures.create.success");
            }

            yield put(
                notificationActions.showNotification(
                    confirmationMessage,
                    data.code === "COR023I" ? "warning" : "success",
                    ["administrationSignaturesSchemes"],
                ),
            );
            const administrationScheme = yield select((state) => sessionSelectors.getAdministrationScheme(state));
            yield put(replace(`/administration/${administrationScheme}/signaturesSchemes`));
            yield put(actions.createSignaturesSchemeSuccess(data.data));
        }
    };

const deleteSignaturesSchemePreRequest = (middleware, actions) =>
    function* deleteSignaturesSchemePreRequestSaga(params) {
        const { idSignature } = params;
        const { data, type } = yield call(middleware.deleteSignaturesSchemePre, idSignature);

        if (type === "W") {
            yield put(actions.deleteSignaturesSchemePreFailure());
            const errorMessage = data?.message || i18n.get("global.unexpectedError");
            yield put(notificationActions.showNotification(errorMessage, "error", ["administrationSignaturesSchemes"]));
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.signatures.delete.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.deleteSignaturesSchemePreSuccess(data.data, credentialGroups));
        }
    };

const deleteSignaturesSchemeRequest = (middleware, actions) =>
    function* deleteSignaturesSchemeRequestSaga({ signatureData, formikBag }) {
        const { credentials, ...restOfParams } = signatureData;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
        const { capList, functionalGroups = [], groupsMap, idSignature, signatureType } = restOfParams;
        const { data, type } = yield call(middleware.deleteSignaturesScheme, {
            ...credentialsWithUnderscore,
            capFrequencies: capList.length ? [capList[0].frequency] : [],
            functionalGroups,
            maxAmount: capList.length ? capList[0].maximum : -1,
            signatureId: idSignature,
            signatureLevelsCounts: groupsMap,
            signatureType,
        });

        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);

        if (type === "W") {
            const errorMessage = data.data.NO_FIELD || data.message || i18n.get("global.unexpectedError");
            if (data.code === "COR020W") {
                setErrors(data.data);
            } else {
                yield put(
                    notificationActions.showNotification(errorMessage, "error", [
                        "administrationDeleteSignatureScheme",
                    ]),
                );
            }
        } else {
            yield put(
                notificationActions.showNotification(data.message, data.code === "COR023I" ? "warning" : "success", [
                    "administrationSignaturesSchemes",
                ]),
            );
            yield put(routerActions.go(-2));
            yield put(actions.deleteSignaturesSchemeSuccess(data.data));
        }
    };

const listSignaturesSchemesRequest = (middleware, actions) =>
    function* listSignaturesSchemesRequestSaga({ params }) {
        const { data, type } = yield call(middleware.listSignaturesSchemes, params ? params.filters : params);

        if (type === "W") {
            yield put(actions.listSignaturesSchemesFailure());
            const errorMessage = data?.message || i18n.get("global.unexpectedError");
            yield put(notificationActions.showNotification(errorMessage, "error", ["administrationSignaturesSchemes"]));
        } else {
            yield put(actions.listSignaturesSchemesSuccess(data.data));
        }
    };

const loadMoreSignaturesRequest = (middleware, actions) =>
    function* loadMoreSignaturesRequestSaga({ pageNumber }) {
        const { data, type } = yield call(middleware.listSignaturesSchemes, pageNumber);

        if (type === "W") {
            yield put(actions.listSignaturesSchemesFailure());
            const errorMessage = data?.message || i18n.get("global.unexpectedError");
            yield put(notificationActions.showNotification(errorMessage, "error", ["administrationSignaturesSchemes"]));
        } else {
            yield put(actions.listMoreSignaturesSchemesSuccess(data.data));
        }
    };

const modifySignaturesSchemePreRequest = (middleware, actions) =>
    function* modifySignaturesSchemePreRequesSaga(params) {
        const { idSignature } = params;
        const { data, type } = yield call(middleware.modifySignaturesSchemePre, idSignature);

        if (type === "W") {
            yield put(actions.modifySignaturesSchemePreFailure());
            const errorMessage = data?.message || i18n.get("global.unexpectedError");
            yield put(
                notificationActions.showNotification(errorMessage, "error", ["administrationModifySignatureScheme"]),
            );
        } else {
            const responseCredentials = yield call(
                form.listCredentialsGroups,
                null,
                "administration.signatures.modify.send",
            );
            const credentialGroups = responseCredentials.data.data.groups;
            yield put(actions.modifySignaturesSchemePreSuccess(data.data, credentialGroups));
        }
    };

const modifySignaturesSchemeRequest = (middleware, actions) =>
    function* modifySignaturesSchemeRequestSaga({ signatureData, formikBag }) {
        const { credentials, signatureLevelsCounts, ...restOfParams } = signatureData;
        const credentialsWithUnderscore = credentialsToUnderscoreFormat(credentials);
        const signatureLevelCountToInt = administrationUtils.signatuleLevelsCountToInt(signatureLevelsCounts);
        const { data, type } = yield call(middleware.modifySignaturesScheme, {
            ...restOfParams,
            signatureLevelsCounts: signatureLevelCountToInt,
            ...credentialsWithUnderscore,
            ...{
                capFrequencies: [restOfParams.topAmount.period] || [],
                maxAmount: restOfParams.topAmount.amount || -1,
            },
        });
        const { setSubmitting, setErrors } = formikBag;

        setSubmitting(false);

        if (type === "W") {
            const errorMessage = data.data.NO_FIELD || data.message || i18n.get("global.unexpectedError");
            if (data.code === "COR020W") {
                setErrors(data.data);
            } else {
                yield put(
                    notificationActions.showNotification(errorMessage, "error", [
                        "administrationModifySignatureScheme",
                    ]),
                );
            }
        } else {
            let confirmationMessage;
            switch (data.code) {
                case "COR023I":
                    confirmationMessage = data.message;
                    break;
                default:
                    confirmationMessage = i18n.get("administration.signatures.modify.success");
            }

            yield put(
                notificationActions.showNotification(
                    confirmationMessage,
                    data.code === "COR023I" ? "warning" : "success",
                    ["signatureSchemeDetail"],
                ),
            );
            yield put(routerActions.go(-2));
            yield put(actions.modifySignaturesSchemeSuccess(data.data));
        }
    };

const signatureSchemeExportRequest = (middleware, actions) =>
    function* signatureSchemeExportRequestSaga({ format }) {
        const { data, type } = yield call(middleware.exportSignatureSchemes, { format, orderBy: "id_signature ASC" });

        if (type === "W") {
            yield put(actions.exportListFailure());
            const errorMessage = data?.message || i18n.get("global.unexpectedError");
            yield put(notificationActions.showNotification(errorMessage, "error", ["administrationSignaturesSchemes"]));
        } else {
            const { content, fileName } = data.data;
            if (format === "pdf") {
                utilDownload.downloadPdf(fileName, content);
            } else {
                utilDownload.downloadXls(fileName, content);
            }
            yield put(actions.exportListSuccess());
        }
    };

const sagasCreator = (middleware, types, actions) => [
    takeLatest(types.CREATE_SIGNATURES_SCHEME_PRE_REQUEST, createSignaturesSchemePreRequest(middleware, actions)),
    takeLatest(types.CREATE_SIGNATURES_SCHEME_REQUEST, createSignaturesSchemeRequest(middleware, actions)),
    takeLatest(types.DELETE_SIGNATURES_SCHEME_PRE_REQUEST, deleteSignaturesSchemePreRequest(middleware, actions)),
    takeLatest(types.DELETE_SIGNATURES_SCHEME_REQUEST, deleteSignaturesSchemeRequest(middleware, actions)),
    takeLatest(types.LIST_SIGNATURES_SCHEMES_REQUEST, listSignaturesSchemesRequest(middleware, actions)),
    takeLatest(types.SIGNATURES_SCHEME_LOAD_MORE_REQUEST, loadMoreSignaturesRequest(middleware, actions)),
    takeLatest(types.MODIFY_SIGNATURES_SCHEME_PRE_REQUEST, modifySignaturesSchemePreRequest(middleware, actions)),
    takeLatest(types.MODIFY_SIGNATURES_SCHEME_REQUEST, modifySignaturesSchemeRequest(middleware, actions)),
    takeLatest(types.SIGNATURES_SCHEME_EXPORT_LIST_REQUEST, signatureSchemeExportRequest(middleware, actions)),
];

export default sagasCreator;
