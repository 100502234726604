import React from "react";
import { string } from "prop-types";

import I18n from "pages/_components/I18n";

const SectionTitle = ({ title, subTitle }) => (
    <div className="form-section-title">
        <I18n component="h2" id={title} componentProps={{ className: "font-size-28-px" }} />

        <I18n component="p" id={subTitle} componentProps={{ className: "light-font" }} />
    </div>
);

SectionTitle.propTypes = {
    title: string.isRequired,
    subTitle: string,
};

SectionTitle.defaultProps = {
    subTitle: "",
};

export default SectionTitle;
