import { Button, Box, Form, Text, useMediaQuery } from "@ui-kit/components/index.es";
import React, { useEffect, useState } from "react";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";

import { actions as transferActions, selectors as transferSelectors } from "reducers/transfer";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CreateTemplateModal from "pages/v2/transfer/CreateTemplateModal";
import { actions as templateActions } from "reducers/template";
import * as transferMiddleware from "middleware/transfer";
import Limits from "./Limits";
import Message from "./scheduler/Message";

function TransferThirdPartiesForm(props) {
    TransferThirdPartiesForm.propTypes = {
        dispatch: func.isRequired,
        onSubmit: func.isRequired,
        onSaveDraft: func.isRequired,
        onCreateTemplate: func.isRequired,
        isDesktop: bool.isRequired,
        accounts: arrayOf(
            shape({
                idProduct: string.isRequired,
            }),
        ).isRequired,
        limit: number.isRequired,
        limitUsed: number.isRequired,
        values: shape({}).isRequired,
        setValues: func.isRequired,
        setHint: func.isRequired,
        setIsScheduleModalVisible: func.isRequired,
        concepts: arrayOf(shape({})).isRequired,
        isCVU: bool.isRequired,
        tadWarningShowed: bool.isRequired,
        onClickTadWaring: func.isRequired,
        isValidCbu: func.isRequired,
        internalTransfer: bool.isRequired,
        agendaVisible: bool.isRequired,
    };

    const {
        accounts,
        setValues,
        setIsScheduleModalVisible,
        values,
        setHint,
        tadWarningShowed,
        internalTransfer,
    } = props;
    const isMobile = useMediaQuery("min", "md");

    const handleDebitAccountSelect = (accountId) => {
        const { dispatch } = props;
        setValues({ ...values, debitAccount: accountId });
        dispatch(transferActions.accountLimitsActivities(accountId, "transfers.thirdParties.send"));
    };

    const [formikProps, setFormikProps] = useState({});

    useEffect(() => {
        const { dispatch } = props;
        dispatch(transferActions.preActivity());
        dispatch(templateActions.loadTemplateList("transferThirdParties"));
    }, []);

    useEffect(() => {
        const { dispatch } = props;
        const defaultAccount = accounts.find((account) => account.favorite) || accounts[0];
        setValues({ ...values, debitAccount: defaultAccount?.idProduct });
        dispatch(transferActions.accountLimitsActivities(defaultAccount?.idProduct, "transfers.thirdParties.send"));
        if (formikProps?.setValues) {
            formikProps.setValues({ debitAccount: defaultAccount?.idProduct });
        }
    }, [accounts, internalTransfer]);

    const selectedCurrency = () => {
        const selectedAccount = accounts.find((account) => account.idProduct === values.debitAccount);
        return i18n.get(`currency.label.${selectedAccount?.currency}`);
    };

    const selectedAccount = () => accounts.find((account) => account.idProduct === values.debitAccount);

    const mapAccounts = accounts.map((account) => ({
        accountName: account.productAlias || account.shortLabel,
        amount: account.balance,
        currency: i18n.get(`currency.label.${account.currency}`),
        description: i18n.get(`productType.${account.productType}`),
        id: account.idProduct,
    }));

    const {
        isDesktop,
        limit,
        limitUsed,
        onSubmit,
        onSaveDraft,
        onCreateTemplate,
        agendaVisible,
        onClickTadWaring,
    } = props;

    const handleCreateTemplate = (propsFormik) => {
        const { currency } = accounts.find((account) => account.idProduct === values.debitAccount);
        onCreateTemplate({ ...propsFormik.values, currency });
    };
    const parseAmount = (value) => {
        if (typeof value === "string") {
            return parseFloat(value.replaceAll(".", "").replace(",", "."));
        }
        return value;
    };

    const handleChangeAmount = (amount) => {
        const { currency } = accounts.find((account) => account.idProduct === values.debitAccount);
        setHint(parseAmount(amount), currency);
    };

    const handleAccountSearch = async (cbuCvuOrAlias) => {
        const { isValidCbu } = props;
        let cbuCvu;
        let alias;
        if (isValidCbu(cbuCvuOrAlias)) {
            cbuCvu = cbuCvuOrAlias;
        } else {
            alias = cbuCvuOrAlias;
        }
        return new Promise((resolve, reject) => {
            transferMiddleware
                .getAccountByCbu(cbuCvu, alias)
                .then((response) => {
                    const { clientUser, accountDetail } = response.data.data;
                    if (!clientUser && !accountDetail) {
                        reject(response.data.message);
                    }
                    resolve({
                        dataAccount: {
                            owner: clientUser.firstName,
                            document: clientUser.cuil,
                            bankName: accountDetail.bankName,
                        },
                    });
                })
                .catch(() => {
                    reject(i18n.get("problems.finding.cbu"));
                });
        });
    };

    const handleConfirmCreditAccount = (value) => {
        setValues({
            ...values,
            cbuOrAlias: value,
        });
    };

    const transferKindOptions = [
        {
            value: "same",
            label: i18n.get("transfers.form.transfer.kind.same"),
        },
        {
            value: "other",
            label: i18n.get("transfers.form.transfer.kind.other"),
        },
    ];

    const conceptsFilter = () => {
        const { isCVU, concepts } = props;
        return isCVU
            ? concepts
                  .filter(({ id }) => !["BRH", "SON", "ROP", "OIH", "BNR", "APC", "SIS"].includes(id))
                  .map(({ id, name }) => ({
                      value: id,
                      label: `${id} - ${name}`,
                  }))
            : concepts.map(({ id, name }) => ({
                  value: id,
                  label: `${id} - ${name}`,
              }));
    };

    const referenceMaxLength = configUtils.getBoolean("frontend.transfers.reference.maxLength", 12);
    const idActivity = "transfers.thirdParties.send";
    const idForm = "transferThirdParties";
    const inputList = [
        {
            colProps: {
                size: 12,
            },
            component: "accountSelect",
            id: "debitAccount",
            label: i18n.get("transfers.form.debit.account"),
            required: true,
            options: mapAccounts,
            defaultValue: values.debitAccount,
            value: values.debitAccount,
            onChange: (id) => handleDebitAccountSelect(id),
        },
        {
            colProps: {
                size: 12,
            },
            id: "limits",
            customElement: () => (
                <Limits
                    values={values}
                    limit={limit}
                    limitUsed={limitUsed}
                    isDesktop={isDesktop}
                    currency={selectedAccount()?.currency}
                />
            ),
        },
        {
            colProps: {
                size: 12,
            },
            component: "selectField",
            id: "transferKind",
            required: true,
            options: transferKindOptions,
            onSelectOption: (option) => setValues({ ...values, transferKind: option }),
            selectLabel: i18n.get("transfers.form.transfer.kind"),
            placeholder: i18n.get("transfers.form.select.transfer.placeholder"),
        },
        {
            colProps: {
                size: 12,
            },
            component: "accountSearcher",
            id: "creditAccount",
            required: true,
            searchCallback: (cbuCvuOrAlias) => handleAccountSearch(cbuCvuOrAlias),
            onAccept: (e) => handleConfirmCreditAccount(e),
            placeholder: i18n.get("transfers.form.cbu.placeholder"),
        },
    ];

    const showFullList = values.debitAccount && values.transferKind && values.cbuOrAlias;
    if (showFullList) {
        inputList.push(
            {
                colProps: {
                    size: 12,
                },
                component: "amountField",
                allowNegative: false,
                currency: selectedCurrency(),
                id: "amount",
                label: i18n.get("transfers.form.amount"),
                required: true,
                onBlur: (e, propsFormik) => handleChangeAmount(propsFormik.values.amount),
                maxLength: 24, // maximo de 18 numeros + puntos y comas
                afterElement: tadWarningShowed ? (
                    <Box mx={12}>
                        <Button icon="Info" variant="text" onClick={onClickTadWaring} />
                    </Box>
                ) : null,
                helper: tadWarningShowed ? (
                    <Text color="primary" variant="caption">
                        {configUtils.get("frontend.TAD.warning.text")}
                    </Text>
                ) : null,
            },
            {
                colProps: {
                    size: 12,
                },
                component: "selectField",
                id: "concept",
                required: true,
                options: conceptsFilter(),
                selectLabel: i18n.get("transfers.form.transfer.concept"),
                defaultValue: conceptsFilter()?.[0] || { value: `VAR`, label: `VAR - Varios` },
            },
            {
                colProps: {
                    size: 12,
                },
                component: "textField",
                id: "reference",
                label: i18n.get("transfers.form.reference"),
                placeholder: i18n.get("transfers.form.reference.placeholder"),
                maxLength: referenceMaxLength,
            },
            {
                colProps: {
                    size: {
                        xs: 9,
                        lg: 5,
                    },
                },
                id: "schedule",
                customElement: () =>
                    (agendaVisible ? (
                        <Box>
                            <Button
                                text={i18n.get("transfers.form.schedule")}
                                icon="CalendarAdd"
                                variant="solid"
                                id="modal-button"
                                onClick={() => setIsScheduleModalVisible(true)}
                            />
                            <Message value={values.scheduler || { valueDate: "" }} />
                        </Box>
                    ) : (
                        <></>
                    )),
            },
            {
                colProps: {
                    size: 12,
                },
                component: "checkbox",
                id: "sendMail",
                label: i18n.get("transfers.form.send.mail"),
                isChecked: values.sendMail,
                onChange: () => setValues({ ...values, sendMail: !values.sendMail }),
            },
        );
    }

    if (values.sendMail) {
        inputList.push(
            {
                colProps: {
                    size: 12,
                },
                component: "textField",
                id: "email",
                label: "Email",
                maxLength: 50,
                required: true,
            },
            {
                colProps: {
                    size: 12,
                },
                component: "textField",
                id: "emailText",
                label: "Mensaje",
                maxLength: 50,
            },
        );
    }
    return (
        <Box p={24}>
            <Form
                wrapButtons
                wrapButtonsFlex
                buttonSubmit={{
                    colProps: {
                        size: {
                            md: 3,
                            xs: 12,
                        },
                    },
                    text: i18n.get("transfers.form.continue"),
                    variant: "solid",
                }}
                initialValues={{ amount: 0 }}
                inputsList={inputList}
                buttonsList={[
                    {
                        colProps: {
                            size: {
                                xs: 12,
                                lg: 3,
                            },
                        },
                        w: isMobile ? "100%" : 200,
                        component: "button",
                        key: "draft",
                        text: i18n.get("transfers.form.save.draft"),
                        icon: "Save",
                        variant: "outline",
                        type: "button",
                        onClick: (e, propsFormik) => onSaveDraft(propsFormik.values),
                    },
                    {
                        colProps: {
                            size: {
                                xs: 12,
                                lg: 3,
                            },
                        },
                        w: isMobile ? "100%" : 200,
                        component: "button",
                        key: "template",
                        text: i18n.get("transfers.form.save.template"),
                        icon: "Save",
                        variant: "outline",
                        type: "button",
                        id: "modal-button",
                        onClick: (e, propsFormik) => handleCreateTemplate(propsFormik),
                    },
                    {
                        colProps: {
                            size: {
                                xs: 12,
                                lg: 3,
                            },
                        },
                        w: isMobile ? "100%" : 200,
                        component: "button",
                        key: "submit",
                        text: i18n.get("transfers.form.continue"),
                        icon: "ArrowRight",
                        iconVariant: "outline",
                        variant: "solid",
                        type: "submit",
                        onClick: () => {},
                    },
                ]}
                onSubmit={(v) => onSubmit(v)}
                setFormikProps={setFormikProps}
                rowSpacingForm={16}
            />
            <CreateTemplateModal values={values} idActivityTemplate={idActivity} idForm={idForm} />
        </Box>
    );
}

const mapStateToProps = (state) => ({
    accounts: transferSelectors.getAccounts(state),
    limit: transferSelectors.getLimit(state),
    limitUsed: transferSelectors.getLimitUsed(state),
    concepts: transferSelectors.getConcepts(state),
    isCVU: transferSelectors.getIsCVU(state),
    internalTransfer: transferSelectors.getInternalTransfer(state),
});

export default withRouter(connect(mapStateToProps)(TransferThirdPartiesForm));
