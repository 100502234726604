import React, { Component } from "react";
import { func, bool, shape } from "prop-types";
import { connect } from "react-redux";

import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Button from "pages/_components/Button";

import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";
import { selectors as sessionSelectors } from "reducers/session";

import PaymentAFIPDetailContent from "pages/servicePayments/_components/PaymentAFIPDetailContent";

import * as i18n from "util/i18n";
import ContextMenu from "pages/_components/ContextMenu";

class PaymentAFIPDetail extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        paymentAFIP: shape({}).isRequired,
        fetching: bool.isRequired,
        activeEnvironment: shape({}).isRequired,
        isReturnFromDetail: bool.isRequired,
    };

    handleGoBack = () => {
        const { dispatch, isReturnFromDetail } = this.props;
        dispatch(paymentsAFIPActions.setIsBackFromOtherPage(isReturnFromDetail));
    };

    centerContentMobile = () => {
        const { isDesktop, paymentAFIP } = this.props;

        const numberVEP = paymentAFIP ? Number(paymentAFIP.numberVEP) : undefined;

        if (!isDesktop) {
            return (
                <div className="title-account-header-multiline-background-blue">
                    <h1 style={{ margin: "-0.5rem 2rem" }}>
                        {i18n.get("paymentsAFIP.details.title", null, { numberVEP })}
                    </h1>
                </div>
            );
        }

        return (
            <div className="view-title" style={{ display: "flex" }}>
                <h1>{i18n.get("paymentsAFIP.details.title", null, { numberVEP })}</h1>
            </div>
        );
    };

    handlePay = () => {
        const { dispatch, paymentAFIP } = this.props;

        dispatch(paymentsAFIPActions.cleanHandleBackFromTicket());
        dispatch(paymentsAFIPActions.payPaymentAFIPPre(paymentAFIP, true));
    };

    handleDelete = () => {
        const { dispatch, paymentAFIP } = this.props;

        dispatch(paymentsAFIPActions.deletePaymentAFIPPre(paymentAFIP, true));
    };

    rightContent = () => {
        const { activeEnvironment, paymentAFIP, isDesktop } = this.props;
        const { type, token } = paymentAFIP;

        if (isDesktop && token) {
            return (
                <>
                    {type === "PE" && activeEnvironment.permissions.accounts && (
                        <Button
                            block
                            label="payments.afip.list.actions.pay"
                            bsStyle="primary"
                            className="cmf-button-width"
                            onClick={this.handlePay}
                        />
                    )}
                    {type === "PE" && (
                        <Button
                            block
                            label="payments.afip.list.actions.delete"
                            className="btn-outline cmf-button-width"
                            onClick={this.handleDelete}
                        />
                    )}
                </>
            );
        }
        return (
            <>
                {type === "PE" && token && (
                    <ContextMenu
                        styleContext={{ justifyContent: "flex-end" }}
                        items={[
                            {
                                label: "payments.afip.list.actions.delete",
                                onClick: () => this.handleDelete(),
                            },
                        ]}
                    />
                )}
                {type === "PE" && token && (
                    <ContextMenu
                        styleContext={{ justifyContent: "flex-end" }}
                        items={[
                            {
                                label: "payments.afip.list.actions.pay",
                                onClick: () => this.handlePay(),
                            },
                            {
                                label: "payments.afip.list.actions.delete",
                                onClick: () => this.handleDelete(),
                            },
                        ]}
                    />
                )}
            </>
        );
    };

    render() {
        const { isDesktop, fetching, paymentAFIP } = this.props;

        return (
            <>
                {isDesktop ? (
                    <div className="admin-detail-head px-0 mb-0">
                        <Head onBack={this.handleGoBack} />
                        <Head
                            rightContent={this.rightContent}
                            centerElement={this.centerContentMobile}
                            hasInlineButtons
                        />
                    </div>
                ) : (
                    <div className="admin-detail-head px-0 mb-0">
                        <Head
                            onBack={this.handleGoBack}
                            headerClassName="blue-main-header-mobile blue-main-title-mobile"
                            centerElement={this.centerContentMobile}
                            rightContent={this.rightContent}
                        />
                    </div>
                )}
                <Notification scopeToShow="paymentAFIP" />
                <PageLoading loading={fetching}>
                    <MainContainer>
                        <div className="above-the-fold">
                            {paymentAFIP && (
                                <PaymentAFIPDetailContent paymentAFIP={paymentAFIP} isDesktop={isDesktop} />
                            )}
                        </div>
                    </MainContainer>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: paymentsAFIPSelectors.getFetching(state),
    paymentAFIP: paymentsAFIPSelectors.getPaymentAFIP(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    isReturnFromDetail: paymentsAFIPSelectors.getIsReturnFromDetail(state),
});

export default connect(mapStateToProps)(PaymentAFIPDetail);
