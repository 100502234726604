import React, { Fragment, Component } from "react";
import { compose } from "redux";
import classNames from "classnames";
import { routerActions } from "react-router-redux/actions";
import { connect } from "react-redux";
import { string, bool, func, arrayOf, number, shape } from "prop-types";

import * as fileMiddleware from "middleware/file";
import { actions as fileActions } from "reducers/files";
import { actions as formActions } from "reducers/form";
import { actions as transactionLinesActions } from "reducers/form/transactionLines";
import { actions, selectors as multilineFileSelectors } from "reducers/formFields/multilineFile";
import { actions as notificationActions } from "reducers/notification";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import FileUploader from "pages/_components/FileUploader";
import DetailBox from "pages/_components/detailBox/DetailBox";
import FormattedAmount from "pages/_components/FormattedAmount";
import I18n from "pages/_components/I18n";
import FileActions from "pages/forms/_components/_fields/_commons/FileActions";
import FieldError from "pages/_components/fields/FieldError";
import withFocus from "pages/_components/withFocus";

class FilePayment extends Component {
    static propTypes = {
        idField: string.isRequired,
        idForm: string.isRequired,
        isDesktop: bool.isRequired,
        field: shape({}),
        form: shape({}),
        formTitle: string,
        dispatch: func.isRequired,
        setValue: func.isRequired,
        processedFileData: shape({
            fileIdentifier: string.isRequired,
            hasFile: bool.isRequired,
            validLines: number,
            invalidLines: number,
            totalAmount: shape({
                currency: string.isRequired,
                quantity: number.isRequired,
            }).isRequired,
        }).isRequired,
        fieldList: arrayOf(shape({})),
        idRelatedFile: string,
        idFile: string,
        value: arrayOf(),
        label: string.isRequired,
        maxFileSizeMB: number.isRequired,
        acceptedFileTypes: arrayOf().isRequired,
        isFocused: bool,
        toggleIsFocused: func,
        typePayment: string,
    };

    static defaultProps = {
        field: null,
        form: null,
        fieldList: [],
        idRelatedFile: null,
        idFile: null,
        formTitle: "",
        value: null,
        isFocused: false,
        toggleIsFocused: null,
        typePayment: "",
    };

    componentDidMount() {
        const { dispatch, processedFileData, setValue } = this.props;

        if (processedFileData.hasFile) {
            setValue([processedFileData.filesMetadata]);
            this.handleNotification(processedFileData);
        }
        dispatch(transactionLinesActions.onPaymentMethodChange());
    }

    componentDidUpdate(prevProps) {
        const { processedFileData } = this.props;

        if (processedFileData && prevProps.processedFileData !== processedFileData) {
            this.handleNotification(processedFileData);
        }
    }

    handleNotification = (processedFileData) => {
        const { dispatch } = this.props;
        const { filesMetadata, message, invalidLines } = processedFileData;

        if (filesMetadata) {
            if (!invalidLines) {
                dispatch(notificationActions.showNotification(`${message}.`, "success", ["form"]));
            } else {
                dispatch(notificationActions.showNotification(`${message}.`, "warning", ["form"]));
            }
        }
    };

    handleFileProcess = (data) => {
        const { dispatch } = this.props;
        dispatch(actions.onFileProcess(data));
    };

    handleError = (data) => {
        const { dispatch } = this.props;
        const { errorMessage } = data;

        dispatch(notificationActions.showNotification(`${errorMessage}.`, "error", ["form"]));
    };

    renderLinesInfo = () => {
        const { processedFileData } = this.props;
        const { invalidLines, validLines } = processedFileData;

        if (!invalidLines || invalidLines === 0) {
            return `${validLines} / ${validLines}`;
        }

        return (
            <div>
                {validLines} / {validLines + invalidLines}
            </div>
        );
    };

    renderAmount = () => {
        const { processedFileData } = this.props;
        const { invalidLines, totalAmount } = processedFileData;
        if (!invalidLines) {
            return (
                <b>
                    <FormattedAmount className="data-desc" {...totalAmount} notBold="true" />
                </b>
            );
        }

        return (
            <Fragment>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <b>
                        <FormattedAmount className="data-desc" {...totalAmount} notBold="true" />
                    </b>
                    <b>*</b>
                </div>

                <div className="detailBox-data-aux">
                    <I18n id="forms.inputFile.massivePayments.calculated.amount.info" />
                </div>
            </Fragment>
        );
    };

    handleClick = () => {
        const { dispatch, form, fieldList, idFile, idForm, idRelatedFile, idField } = this.props;
        const { idField: selectorId, optionList } = fieldList.find(
            (field) => field.type === "selector" && field.defaultValue === "file",
        );

        if (idRelatedFile !== undefined) {
            fileMiddleware.deleteFile(Number(idRelatedFile));
        }
        dispatch(transactionLinesActions.setIsEditingPayment(false));
        dispatch(fileActions.getFileContentsRequest(idFile, true));
        dispatch(routerActions.push(`/form/${idForm}/manual`));
        dispatch(
            formActions.setData({
                ...form.values,
                [selectorId]: [optionList[1].id],
                [idField]: [],
            }),
        );
    };

    handleBlur = (event) => {
        const { field, toggleIsFocused } = this.props;

        field.onBlur(event);
        toggleIsFocused();
    };

    renderDetail = ({ downloadFile }) => {
        const { isDesktop, processedFileData } = this.props;
        const { invalidLines, invalidHeader, invalidFile, validLines } = processedFileData;
        const maxBatchLines = configUtils.get("multilinePayments.transactionLinesBatch.count", 1000);
        const validBatch = invalidLines + validLines > maxBatchLines;

        if (invalidLines === null) {
            return null;
        }

        if (invalidHeader) {
            return (
                <div className="alert alert-warning" role="alert">
                    <I18n id="forms.inputFile.massivePayments.invalid.header" invalidLines={invalidLines} />
                </div>
            );
        }

        return (
            <DetailBox>
                {invalidLines > 0 && (
                    <div className="filePayment text-align-center my-0" role="alert">
                        {invalidFile && (
                            <I18n
                                id="forms.inputFile.massivePayments.invalid.file"
                                percentage={configUtils.getInteger(
                                    "forms.fields.multilinefile.minimum.invalid.percentage.allowed",
                                )}
                            />
                        )}
                        {!invalidFile && validBatch && (
                            <I18n id="forms.notification.uploadFile.fail.batch.limit.reached" count={maxBatchLines} />
                        )}
                        {!invalidFile && !validBatch && (
                            <I18n
                                id="forms.inputFile.massivePayments.invalid.lines.warning"
                                invalidLines={invalidLines}
                            />
                        )}
                    </div>
                )}
                {invalidLines > 0 && (
                    <div
                        className={classNames({
                            "text-right": isDesktop,
                            "text-center": !isDesktop,
                        })}>
                        <button
                            type="button"
                            className="btn btn-asLink btn-dl-error"
                            onClick={() => downloadFile({ isDownloadingRelatedFile: true })}>
                            <I18n id="forms.inputFile.massivePayments.download.errors" />
                        </button>
                    </div>
                )}
                <DetailBox.Data label="forms.inputFile.massivePayments.lines">
                    {this.renderLinesInfo(downloadFile)}
                </DetailBox.Data>
                <DetailBox.Data label="forms.inputFile.massivePayments.totalAmount">
                    {this.renderAmount()}
                </DetailBox.Data>
            </DetailBox>
        );
    };

    renderError = () => {
        const { form, idField, processedFileData } = this.props;
        const { invalidLines, validLines } = processedFileData;
        const maxBatchLines = configUtils.get("multilinePayments.transactionLinesBatch.count", 1000);
        const validLinesTotal = invalidLines + validLines;
        if (!form.touched[idField]) {
            return null;
        }
        if (!processedFileData.hasFile) {
            return <FieldError error={i18nUtils.get("forms.inputFile.massivePayments.invalid.required")} />;
        }
        if (processedFileData.invalidFile) {
            return <FieldError error={i18nUtils.get("forms.inputFile.massivePayments.invalid.field")} />;
        }
        if (validLinesTotal > maxBatchLines) {
            return <FieldError error={i18nUtils.get("forms.inputFile.massivePayments.invalid.field")} />;
        }
        return null;
    };

    isValid = () => {
        const { processedFileData } = this.props;
        const { invalidHeader, invalidFile, hasFile } = processedFileData;
        return !invalidHeader && !invalidFile && hasFile;
    };

    render() {
        const {
            acceptedFileTypes,
            dispatch,
            formTitle,
            idForm,
            idField,
            isFocused,
            label,
            maxFileSizeMB,
            processedFileData,
            setValue,
            toggleIsFocused,
            value,
            ...rest
        } = this.props;
        const { invalidLines } = processedFileData;

        return (
            <FileActions
                {...this.props}
                renderPreview={this.renderDetail}
                render={({ onRemoveFile, onAddFile, downloadFile }) => (
                    <div
                        onFocus={toggleIsFocused}
                        onBlur={this.handleBlur}
                        className={classNames("form-group", {
                            "has-error": !this.isValid(),
                            "has-focus": isFocused,
                            "invalid-lines": invalidLines > 0,
                        })}>
                        <FileUploader
                            name={idField}
                            idActivity="files.upload"
                            idForm={idForm}
                            idFormField={idField}
                            description={`${formTitle} - ${label}`}
                            files={value}
                            allowMultiple={false}
                            maxFileSize={`${maxFileSizeMB}mb`}
                            minFileSize={1}
                            maxTotalFileSize={`${maxFileSizeMB}mb`}
                            maxFiles={1}
                            allowImagePreview
                            acceptedFileTypes={acceptedFileTypes}
                            onFileProcess={this.handleFileProcess}
                            onError={this.handleError}
                            onAddFile={onAddFile}
                            onRemoveFile={(file) => {
                                onRemoveFile(file);
                                dispatch(actions.onFileRemoved());
                            }}
                            setValue={setValue}
                            {...rest}
                        />
                        {this.renderDetail({ downloadFile })}
                    </div>
                )}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    idFile: multilineFileSelectors.getProcessedFileData(state).idFile,
});

export default compose(connect(mapStateToProps), withFocus)(FilePayment);
