import * as API from "middleware/api";
import * as restAPI from "middleware/apiRESTful";
import * as OAUTH from "middleware/oauth";
import * as deviceUtils from "util/device";

export const loginStep1 = (username, document, documentType, recaptchaResponse) =>
    API.executeAnonymous("/session.login.legacy.step1", {
        _pin: username,
        _captcha: recaptchaResponse,
        document,
        documentType,
    });

export const validateCaptcha = (recaptchaResponse) =>
    API.executeAnonymous("/validate.captcha", {
        _captcha: recaptchaResponse,
    });

export const loginStep2 = (exchangeToken, password, recaptchaResponse) =>
    API.executeWithExchangeToken(
        "/session.login.legacy.step2",
        {
            _password: password,
            _captcha: recaptchaResponse,
        },
        exchangeToken,
    );

export const oauth = (email, password, exchangeToken = "") =>
    OAUTH.executePasswordGrant(email, `${password}#${exchangeToken}#`);

export const thirdPartyOauth = (
    email,
    password,
    thirdPartyToken,
    redirectUri,
    exchangeToken = "",
    recaptchaResponse = "",
) =>
    OAUTH.executeImplicit(
        "assistant-client",
        redirectUri,
        thirdPartyToken,
        email,
        `${password}#${exchangeToken}#${recaptchaResponse}`,
    );

export const fingerprintOauth = (email, fingerprintAuthToken, idDevice) =>
    OAUTH.executePasswordGrant(
        email,
        `fingerprint#${fingerprintAuthToken}#${idDevice}#${deviceUtils.getDeviceModel()}`,
    );

export const listEnvironments = () => API.executeWithAccessToken("session.login.oauth.step2b");

export const loginStep3 = (exchangeToken, environment, setAsDefault, location, idDevice, assistant) =>
    API.executeWithAccessToken(
        "/session.login.legacy.step3",
        {
            environment,
            setAsDefault,
            idDevice,
            ...location,
            ...assistant,
        },
        exchangeToken,
    );
export const loginStep4 = (exchangeToken, acceptConditions, idEnvironmentToAcceptConditions) =>
    API.executeWithAccessToken(
        "/session.login.oauth.step4",
        {
            acceptConditions,
            idEnvironmentToAcceptConditions,
        },
        exchangeToken,
    );

export const logout = () => {
    const response = API.executeWithAccessToken("/session.logout");

    API.setAuthToken(null);
    restAPI.setAuthToken(null);
    return response;
};

export const setAuthToken = (token) => {
    API.setAuthToken(token);
    restAPI.setAuthToken(token);
};

export const changeEnvironment = (idEnvironmentToChange, setAsDefault) =>
    API.executeWithAccessToken("/session.changeEnvironment", {
        idEnvironmentToChange,
        setAsDefault,
    });

export const check = () => API.executeWithAccessToken("/session.get");

export const extend = () => API.executeWithAccessToken("/session.extend");

export const checkFingerprintSession = (_accessToken) => {
    API.setAuthToken(_accessToken);
    restAPI.setAuthToken(_accessToken);

    return API.executeWithAccessToken("/fingerprint.sessionExist");
};

export const fingerprintLogin = (location) => API.executeWithAccessToken("/login.fingerprint", { ...location });

export const registerUserDevice = (exchangeToken, idDevice, pushToken, extraInfo) =>
    API.executeWithAccessToken(
        "/session.login.oauth.registerUserDevice",
        {
            idDevice,
            pushToken,
            extraInfo,
        },
        exchangeToken,
    );

export const getClientCountry = () => API.executeAnonymous("/session.getUserCountry", null, 2000);

export const getRandomSecuritySeal = () => API.executeAnonymous("/session.listRandomSecuritySeal", null);

export const exportTermsAndConditions = (format) => API.executeAnonymous("/session.exportTermsAndConditions", format);

export const exportTermsAndConditionsConfigVar = (format, configVar) =>
    API.executeAnonymous("/session.exportTermsAndConditions", format, configVar);
