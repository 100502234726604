import React, { Component, Fragment } from "react";
import { Field } from "formik";
import { connect } from "react-redux";
import { bool, shape, string } from "prop-types";

import { groupFormDataSelectors } from "reducers/administration";
import * as i18nUtils from "util/i18n";

import Image from "pages/_components/Image";
import Wizard from "pages/_components/Wizard";
import FieldLabel from "pages/_components/fields/FieldLabel";
import I18n from "pages/_components/I18n";
import AdvancedPermissionsForm from "pages/administration/_components/AdvancedPermissionsForm";
import MultiSelect from "pages/_components/fields/formik/FormickMultiSelectGroup";
import TextField from "pages/_components/fields/TextField";
import FormGroup from "pages/_components/fields/FormGroup";

import Selector from "pages/_components/fields/formik/Selector";

class GroupFormWizardSteps extends Component {
    static propTypes = {
        idForm: string.isRequired,
        mode: string.isRequired,
        adminUsers: shape({}).isRequired,
        formProps: shape({}).isRequired,
        availableUsers: shape({}).isRequired,
        isStep2: bool,
        location: shape({
            pathname: string,
        }).isRequired,
    };

    static defaultProps = {
        isStep2: false,
    };

    render() {
        const { idForm, availableUsers, adminUsers, formProps, mode, isStep2, location } = this.props;
        const confirmStep1Path =
            location?.pathname === "/administration/advanced/group/create/confirm/step1" ||
            location?.pathname === "/administration/advanced/group/create/confirm/step3";

        return (
            <div className={isStep2 ? `col-md-9 col-lg-6 ${confirmStep1Path && "col-lg-12"}` : "col-12 pl-0"}>
                <Wizard.Steps>
                    {mode === "edit" && (
                        <Wizard.Step>
                            <div className={confirmStep1Path ? null : "w-100 max-xl-480 mx-auto"}>
                                <Field
                                    name="description"
                                    idForm={idForm}
                                    component={TextField}
                                    renderAs="textarea"
                                    optional={i18nUtils.get(`${idForm}.description.optional.text`)}
                                    mode={mode}
                                    hidePlaceholder
                                />
                                <FormGroup>
                                    <FieldLabel labelKey={`${idForm}.status.label`} />
                                    <div className="form-group-control-list">
                                        <Field
                                            component={Selector}
                                            renderAs="radio"
                                            inLineControl
                                            name="status"
                                            options={[
                                                {
                                                    id: `active`,
                                                    label: <I18n id={`${idForm}.status.active.label`} />,
                                                },
                                                {
                                                    id: `blocked`,
                                                    label: <I18n id={`${idForm}.status.blocked.label`} />,
                                                },
                                            ]}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        </Wizard.Step>
                    )}
                    <Wizard.Step>
                        <AdvancedPermissionsForm
                            navigationListItemClassName="pl-0 my-2"
                            buttonClassName="pl-0"
                            {...formProps}
                            mode={mode}
                        />
                    </Wizard.Step>
                    <Wizard.Step>
                        <div className={confirmStep1Path ? null : "w-100 max-xl-480 mb-45 mx-auto"}>
                            <MultiSelect
                                name="users"
                                label={`${idForm}.members.list.title`}
                                options={availableUsers}
                                placeholder={`${idForm}.users.placeholder`}
                                mode={mode}
                                labelKey="fullName"
                                valueKey="idUser">
                                {({ idUser, fullName }) => (
                                    <Fragment>
                                        <span className="data-desc">{fullName}</span>
                                        {adminUsers.includes(idUser) && (
                                            <Image
                                                src="images/administrator.svg"
                                                className="svg-icon svg-caret-space"
                                            />
                                        )}
                                    </Fragment>
                                )}
                            </MultiSelect>
                        </div>
                    </Wizard.Step>
                </Wizard.Steps>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    availableUsers: groupFormDataSelectors.getAvailableUsers(state),
    adminUsers: groupFormDataSelectors.getAdminUsers(state),
    adminGroup: groupFormDataSelectors.isAdminGroup(state),
});

export default connect(mapStateToProps)(GroupFormWizardSteps);
