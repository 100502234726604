import React, { Component } from "react";
import { connect } from "react-redux";
import { string, shape, objectOf, func } from "prop-types";
import { selectors, actions as adminActions } from "reducers/administration/common/administrationTicket";

import Col from "react-bootstrap/lib/Col";
import I18n from "pages/_components/I18n";
import Container from "pages/_components/Container";

class RestrictionsUserDeleteTicketData extends Component {
    static propTypes = {
        transactionData: shape({
            data: objectOf(string),
            idActivity: string,
            idTransaction: string,
        }).isRequired,
        dispatch: func.isRequired,
    };

    componentDidMount() {
        this.generateExtraData();
    }

    generateExtraData() {
        const { dispatch, transactionData } = this.props;

        const extraData = {
            type: {
                label: "administration.restrictions.user.deleted.all",
                value: `${transactionData.data.userFullName || ""}`,
            },
        };

        dispatch(adminActions.setExtraData(extraData));
    }

    render() {
        const { transactionData } = this.props;
        if (transactionData.data) {
            return (
                <Container
                    className="container--layout align-items-center flex-grow p-0 m-0"
                    gridClassName="form-content">
                    <Col>
                        <div className="transfer-data data-wrapper">
                            <Container.ColumnBody columnClass="list-wrapper">
                                <h4 className="form-title">
                                    <I18n id="administration.restrictions.user.deleted.all" />
                                </h4>
                                {`${transactionData.data.userFullName || ""}`}
                            </Container.ColumnBody>
                        </div>
                    </Col>
                </Container>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getData(state),
});

export default connect(mapStateToProps)(RestrictionsUserDeleteTicketData);
