/* eslint-disable object-shorthand */
/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import I18n from "pages/_components/I18n";

const Title = ({ type, id, className, tabIndex, role }) => (
    <I18n id={id} component={type} tabIndex={tabIndex} componentProps={{ className: className, role: role }} />
);

Title.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    role: PropTypes.string,
};

Title.defaultProps = {
    type: "h1",
    className: "",
    tabIndex: 0,
    role: "heading",
};

export default Title;
