import { createReducer, makeActionCreator } from "util/redux";
import formTypes from "reducers/types/form";
import transactionLinesTypes from "reducers/form/transactionLinesTypes";

import { SALARY_PAYMENT_ID_FORM } from "constants.js";

export const types = {
    UPDATE_PROCESS_REQUEST: "formFields/multilineFileProcess/UPDATE_PROCESS_REQUEST",
    UPDATE_PROCESS_SUCCESS: "formFields/multilineFileProcess/UPDATE_PROCESS_SUCCESS",
    UPDATE_PROCESS_FAILURE: "formFields/multilineFileProcess/UPDATE_PROCESS_FAILURE",
};

const INITIAL_STATE = {
    pendingLines: 0,
    processedLines: 0,
    failedLines: 0,
};

const linesByStatus = {
    PENDING: "pendingLines",
    PROCESSED: "processedLines",
    PROCESSED_WITH_ERROR: "failedLines",
};

const mapLines = (state, lines) => {
    if (!lines) {
        return state;
    }
    return lines.reduce(
        (mappedLines, { status, count }) => ({ ...mappedLines, [linesByStatus[status]]: count }),
        state,
    );
};

export default createReducer(INITIAL_STATE, {
    [formTypes.READ_TRANSACTION_SUCCESS]: (state, { formMetadata }) => {
        const { idForm, fieldList } = formMetadata;

        if (idForm !== SALARY_PAYMENT_ID_FORM) {
            return state;
        }

        const { data } = fieldList.find(({ type }) => type === "multilinefile");
        return mapLines(INITIAL_STATE, data && data.transactionLinesStatus);
    },
    [types.UPDATE_PROCESS_SUCCESS]: (_, { data }) => mapLines(INITIAL_STATE, data.transactionLinesStatus),
    [transactionLinesTypes.LIST_TRANSACTION_LINES_SUCCESS]: (state, action) => ({
        ...state,
        processedLines: action.totalLines,
    }),
});

export const actions = {
    updateProcess: makeActionCreator(types.UPDATE_PROCESS_REQUEST, "data"),
    updateProcessSuccess: makeActionCreator(types.UPDATE_PROCESS_SUCCESS, "data"),
    updateProcessFailure: makeActionCreator(types.UPDATE_PROCESS_FAILURE),
};

export const selectors = {
    getFailedLines: ({ formFields }) => formFields.multilineFileProcess.failedLines,
    getPendingLines: ({ formFields }) => formFields.multilineFileProcess.pendingLines,
    getProcessedLines: ({ formFields }) => {
        const { processedLines, failedLines } = formFields.multilineFileProcess;

        return processedLines + failedLines;
    },
    getTotalLines: ({ formFields }) => {
        const { pendingLines, processedLines, failedLines } = formFields.multilineFileProcess;

        return pendingLines + processedLines + failedLines;
    },
};
