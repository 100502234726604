import React, { Component } from "react";
import { shape, string } from "prop-types";

import Container from "pages/_components/Container";

import TransactionStatus from "pages/transactions/_components/TransactionStatus";
import I18n from "pages/_components/I18n";

class Default extends Component {
    static propTypes = {
        transaction: shape({
            idTransactionStatus: string.isRequired,
        }).isRequired,
    };

    render() {
        const { transaction } = this.props;

        return (
            <div className="media-object admin-content-center">
                <Container className="container--layout align-items-center">
                    <TransactionStatus
                        iconFidelity="ticket"
                        idTransactionStatus={transaction.idTransactionStatus}
                        ticketWrapperStyles
                    />
                </Container>
                <div className="admin-content-center">
                    <I18n id={`forms.transaction.ticket.status.${transaction.idTransactionStatus}`} />
                </div>
            </div>
        );
    }
}

export { default as SalaryPayment } from "pages/forms/_components/ticketHeaders/SalaryPayment";

export default Default;
