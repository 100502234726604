import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { withFormik, Form, Field } from "formik";
import Yup from "yup";
import { func, bool, string, shape } from "prop-types";

import SelectorInput from "pages/_components/fields/formik/SelectorInput";
import I18n from "pages/_components/I18n";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import Credential from "pages/_components/fields/credentials/Credential";
import FooterDesktop from "pages/_components/FooterDesktop";
import RegionSelectionLink from "pages/login/_components/RegionSelectionLink";
import ForgotUserButton from "pages/login/_components/ForgotUserButton";

import { actions as recoveryPasswordActions } from "reducers/recoveryPassword";
import { actions as loginActions } from "reducers/login";

import { Mixpanel } from "util/clickstreaming";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import Captcha from "pages/_components/fields/credentials/Captcha";
import HaveCodeButton from "./HaveCodeButton";

const FORM_ID = "recoveryUserpass.step1";

class Step0Content extends Component {
    static propTypes = {
        setErrors: func.isRequired,
        location: shape({}).isRequired,
        showCaptcha: bool.isRequired,
    };

    state = {
        clickContinue: false,
    };

    handleGoHome = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    contentTopDesktop = () => {
        const { values, setValues, setErrors } = this.props;

        return (
            <Col sm={12} className="px-0">
                <I18n
                    id="recoveryUserpass.step0.title"
                    component="p"
                    componentProps={{ className: "text-lead font-size-14-px" }}
                />
                <Field
                    component={SelectorInput}
                    className="text-uppercase"
                    name="document"
                    selectorSize="small"
                    selectProps={{
                        name: "documentType",
                        value: {
                            value: values.documentType,
                            label: values.documentType,
                        } /* values.documentType */,
                        options: [
                            { value: "DNI", label: "DNI" },
                            { value: "CUIT", label: "CUIT" },
                            { value: "CUIL", label: "CUIL" },
                            { value: "CDI", label: "CDI" },
                        ],
                    }}
                    isFocused
                    label={i18n.get("recoveryUserpass.step0.dni.label")}
                    inputProps={{
                        name: "document",
                        value: values.document,
                        inputmode: "numeric",
                        autofocus: true,
                    }}
                    onChange={(type, stringDocument) => {
                        setErrors({ document: "" });
                        setValues({
                            ...values,
                            documentType: type?.value ? type.value : type,
                            document: stringDocument,
                        });
                    }}
                />
                <Field idForm={FORM_ID} name="password" component={Credential} />
            </Col>
        );
    };

    contentTopMobile = () => (
        <>
            <div className="view-title mt-3">
                <I18n
                    id="credential.recovery.mobile"
                    component="h2"
                    componentProps={{ className: "f-size-5 font-regular" }}
                />
            </div>
            <p className="text-lead font-size-14-px">{configUtils.get("login.RecuperarCredenciales.Mobile.Texto")}</p>
        </>
    );

    contentBottomDesktop = () => {
        const { isSubmitting, submitForm, dispatch, showCaptcha, resetCaptcha } = this.props;

        const { clickContinue } = this.state;
        const captchaEnabled = configUtils.getBoolean("core.auth.captcha.enabled");

        return (
            <>
                <Col sm={12} md={12} lg={12} className="col col-12 px-0">
                    {captchaEnabled && showCaptcha && (
                        <Field name="captcha" idForm={FORM_ID} component={Captcha} resetCaptcha={resetCaptcha} />
                    )}
                </Col>
                <ForgotUserButton
                    label={`${FORM_ID}.forgotPasswordToo`}
                    onClick={() => {
                        dispatch(recoveryPasswordActions.setRecoveryPassAndUserpass());
                        setTimeout(() => {
                            submitForm();
                        }, 100);
                    }}
                />
                <Col sm={12} className="pt-4 px-0">
                    <Button
                        label="global.continue"
                        type="button"
                        bsStyle="primary"
                        loading={isSubmitting && clickContinue}
                        onClick={() => {
                            this.setState({ clickContinue: true });
                            dispatch(recoveryPasswordActions.removeRecoveryPassAndUserpass());
                            setTimeout(() => {
                                submitForm();
                            }, 100);
                        }}
                    />
                </Col>
            </>
        );
    };

    contentBottomMobile = () => {
        const {
            isDesktop,
            dispatch,
            location: { pathname },
        } = this.props;
        return (
            <>
                <Col sm={12} className="pt-4 px-0">
                    <Button
                        label="credential.recovery.mobile.goback"
                        type="button"
                        bsStyle="primary"
                        onClick={this.handleGoHome}
                    />
                    {configUtils.getBoolean("mobile.recovercredentials.enabled", false) && (
                        <HaveCodeButton
                            label="recoveryPassword.step1.userHasCode"
                            to="/recoveryUserpass/step2"
                            dispatch={dispatch}
                            className="px-0 text-transform-none"
                        />
                    )}
                </Col>
                <Col sm={12}>
                    <FooterDesktop
                        isDesktop={isDesktop}
                        moreOptions={[<RegionSelectionLink disabled={pathname === "/regionSelection"} />]}
                    />
                </Col>
            </>
        );
    };

    render() {
        const { isDesktop, isMobileNative } = this.props;

        return isDesktop || (isMobileNative && configUtils.getBoolean("mobile.recovercredentials.enabled", false)) ? (
            <Form className="login-desktop-wrapper">
                <Container className="container--layout flex-grow align-items-center">
                    {this.contentTopDesktop()}
                </Container>
                <Container className="align-items-center container--layout">{this.contentBottomDesktop()}</Container>
            </Form>
        ) : (
            <>
                <Container className="container--layout pass__recovery-top-content flex-grow align-items-center">
                    {this.contentTopMobile()}
                </Container>
                <Container className="align-items-center container--layout">{this.contentBottomMobile()}</Container>
            </>
        );
    }
}

const documentTypeFormats = (documentType, document) => {
    const isnum = /^\d+$/.test(document);
    if (documentType === "DNI") {
        if (document.length < 6 || document.length > 8 || !isnum) {
            return false;
        }
    }

    if (documentType === "CUIT" || documentType === "CUIL" || documentType === "CDI") {
        if (document.length !== 11 || !isnum) {
            return false;
        }
    }
    return true;
};

Step0Content.propTypes = {
    isSubmitting: bool,
    isDesktop: bool,
    isMobileNative: bool,
    submitForm: func.isRequired,
    dispatch: func.isRequired,
    values: shape({
        document: string,
        documentType: string,
        password: string,
        captcha: string,
    }).isRequired,
    setValues: func.isRequired,
    resetCaptcha: bool.isRequired,
};

Step0Content.defaultProps = {
    isSubmitting: false,
    isDesktop: true,
    isMobileNative: false,
};

export default withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => ({
        documentType: props.documentType ? props.documentType : "DNI",
        document: props.document || "",
        password: "",
        captcha: "",
    }),
    validationSchema: (props) => {
        const { isRecoveryPassAndUserpass, showCaptcha } = props;

        return Yup.object().shape({
            password: !isRecoveryPassAndUserpass
                ? Yup.string().required(i18n.get(`${FORM_ID}.password.required`))
                : Yup.string(),
            document: Yup.string().required(i18n.get(`${FORM_ID}.document.required`)),
            captcha: showCaptcha ? Yup.string().required(i18n.get(`${FORM_ID}.captcha.required`)) : Yup.string(),
        });
    },
    handleSubmit: ({ documentType, document, password, captcha }, formikBag) => {
        Mixpanel.track(FORM_ID);
        const formatValidation = documentTypeFormats(documentType, document, formikBag);
        const { isRecoveryPassAndUserpass } = formikBag.props;

        formikBag.props.dispatch(recoveryPasswordActions.setResetCaptcha(false));
        if (formatValidation) {
            formikBag.props.dispatch(
                recoveryPasswordActions.recoveryUserpassStep0(
                    password,
                    document,
                    documentType,
                    formikBag,
                    isRecoveryPassAndUserpass,
                    captcha,
                ),
            );
        } else {
            formikBag.setErrors({ document: i18n.get("login.step1.wrong.format") });
            formikBag.setValues({
                documentType: "DNI",
                document: formikBag.props.document ? formikBag.props.document : "",
                username: "",
                captcha: "",
            });
            formikBag.setSubmitting(false);
        }
    },
})(Step0Content);
