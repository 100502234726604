import React, { Component } from "react";

class Spinner extends Component {
    render() {
        return (
            <div className="btn is-loading no-button-spinner transparent">
                <span className="btn-loading-indicator">
                    <span />
                    <span />
                    <span />
                </span>
                <span className="btn-loading-text">Loading</span>
            </div>
        );
    }
}

export default Spinner;
