import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as assistantSelectors } from "reducers/assistant";
import I18n from "../../_components/I18n";

class AssistantAlert extends Component {
    render() {
        const { currentAssistant } = this.props;

        return (
            <div className="view-alert">
                <section className="container--layout align-items-center">
                    <div className="container">
                        <div className="justify-content-center row">
                            <div xl="6" className="col col-12">
                                <div className="alert alert-warning" role="alert">
                                    <I18n id="assistant.account.link.alert" ASSISTANT={currentAssistant} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentAssistant: assistantSelectors.getCurrentAssistant(state),
});

export default connect(mapStateToProps)(AssistantAlert);
