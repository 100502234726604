import React, { Component } from "react";

import { node, string } from "prop-types";

class ContainerColumnBody extends Component {
    static propTypes = {
        children: node,
        columnClass: string,
    };

    static defaultProps = {
        children: null,
        columnClass: "",
    };

    render() {
        const { columnClass, children } = this.props;
        // eslint-disable-next-line no-console
        console.warn(`${ContainerColumnBody.name} component is deprecated and it will be removed`);
        return <div className={`col-body ${columnClass}`}>{children}</div>;
    }
}

export default ContainerColumnBody;
