import React, { Component } from "react";
import { string } from "prop-types";
import classNames from "classnames";

import I18n from "pages/_components/I18n";

class MetrocorpWidgetItem extends Component {
    static propTypes = { 
        tipoEspecie: string.isRequired,
        saldoValorizado: string.isRequired,
    };

    render() {
        const {
            tipoEspecie: speciesType,
            saldoValorizado: balance,
        } = this.props;
        
        return (
            <div className="widgets__meprocorp-item space-between align-items-center d-flex">
                <I18n
                    defaultValue={speciesType}
                    componentProps={{ className: "mr-2 f-size-5" }}
                />
                <I18n
                    defaultValue={balance}
                    componentProps={{ 
                        className: classNames(
                            "justify-content-end d-flex f-size-5 font-medium",
                            {"color-error": balance.includes("-")}
                        )}
                    }
                />
            </div>
        );
    }
}

export default MetrocorpWidgetItem;