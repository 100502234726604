/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
import React, { Component } from "react";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import { actions, selectors } from "reducers/frequentDestination";

import { replace, push } from "react-router-redux";
import { connect } from "react-redux";
import { arrayOf, bool, func, string, shape, number } from "prop-types";

import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";

import TableFrequentDestination from "pages/frequentDestinations/_components/TableFrequentDestination";
import classNames from "classnames";

const FORM_ID = "frequentDestination.list";

class FrequentDestinationList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        listFrequentDestinations: arrayOf(
            shape({
                name: string,
                recipientName: string,
                recipientDocumentType: string,
                recipientDocumentNumber: string,
                cbu: string,
                recipientEmail: string,
            }),
        ),
        fetching: bool,
        filter: shape({}).isRequired,
        idEnvironment: number.isRequired,
        values: shape({}),
        isDesktop: bool.isRequired,
        fetchingExport: bool,
        isMobile: bool.isRequired,
        activeEnvironment: shape({
            permissions: arrayOf(string),
        }).isRequired,
    };

    static defaultProps = {
        listFrequentDestinations: [],
        fetching: false,
        values: {},
        fetchingExport: false,
    };

    componentDidMount() {
        const {
            dispatch,
            idEnvironment,
            values: { filter },
        } = this.props;
        dispatch(actions.loadListRequest(idEnvironment, filter));
    }

    onSearch = () => {
        const { dispatch, idEnvironment, values } = this.props;
        dispatch(actions.loadListRequest(idEnvironment, values.filter));
    };

    onClick = () => {
        const { dispatch } = this.props;

        dispatch(replace("/form/frequentDestination"));
    };

    linkClick = () => {
        const { listFrequentDestinations } = this.props;
        const selectedItems = [];
        listFrequentDestinations.forEach((fd) => {
            if (fd.selected) {
                selectedItems.push(fd);
            }
        });
    };

    btnHandlerOnClick = () => {
        const { dispatch } = this.props;
        dispatch(actions.resetListFrequentDestinations());
        dispatch(push("/FrequentDestination/create/step1"));
    };

    handleClickDownload = async () => {
        const { dispatch } = this.props;
        dispatch(actions.downloadListFrequentDestinations());
    };

    onRowClick = (record) => {
        const { dispatch } = this.props;
        dispatch(push(`/FrequentDestination/detail/${record.idFrequentDestination}/`));
    };

    handleClickCopy = () => {
        const { dispatch } = this.props;
        dispatch(
            notificationActions.showNotification(i18n.get("frequentDestination.copyDate"), "success", [
                "frequentDestination",
            ]),
        );
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    renderPageHeader = () => {
        const { isDesktop, isMobile, fetchingExport, activeEnvironment } = this.props;
        const managePermission = activeEnvironment.permissions["frequentDestination.manage"];
        return (
            <>
                <Notification scopeToShow="FrequentDestinations" />
                <div className="admin-detail-head px-0 ">
                    {isDesktop && <Head onBack={this.handleBack} />}
                    <Head
                        uniqueDownload
                        handleClick={this.handleClickDownload}
                        handleClickMessage="global.download"
                        hasInlineButtons
                        title="transfer.frequentDestinationList.title"
                        handleCreateMessage={
                            managePermission ? "transfer.frequentDestinationList.title.button" : undefined
                        }
                        handleCreateClick={managePermission ? this.btnHandlerOnClick : undefined}
                        titleClassName={
                            !isDesktop ? "header-mobile-title-background-blue white-space-normal align-center" : ""
                        }
                        headerClassName={
                            !isDesktop ? "blue-main-header-mobile blue-main-title-mobile background-transparent" : ""
                        }
                        addButtonImageWhite={!isDesktop}
                        onBack={!isDesktop && this.handleBack}
                        isFetchingExport={fetchingExport}
                        downloadImageWhite={isMobile}
                    />
                </div>
            </>
        );
    };

    render() {
        const { fetching, isDesktop } = this.props;
        return (
            <>
                <Notification scopeToShow="frequentDestination" />
                {this.renderPageHeader()}
                <TableFrequentDestination
                    formId={FORM_ID}
                    onRowClick={this.onRowClick}
                    visibleContextMenu
                    fetching={fetching}
                    searchClassName={classNames("px-0", {
                        "mt-45": isDesktop,
                    })}
                    onClickCopy={this.handleClickCopy}
                    isDesktop={isDesktop}
                    isTransferAgenda
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    listFrequentDestinations: selectors.getListFrequentDestinations(state),
    moreDestinations: selectors.getMoreDestinations(state),
    idEnvironment: selectors.getEnviromentId(state),
    fetching: selectors.getFetching(state),
    fetchingExport: selectors.getFetchingExport(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(FrequentDestinationList);
