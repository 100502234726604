/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import { bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";

import I18n from "pages/_components/I18n";
import MessageActions from "pages/communications/_components/MessageActions";

function MessageSender(props) {
    const { communication } = props;

    if (communication.direction === "BANK_TO_CUSTOMER") {
        return communication.bodyMap.TITLE ? (
            <div>
                <strong>{communication.subject}</strong>
            </div>
        ) : (
            <div>
                <I18n
                    component="span"
                    className="data-aux"
                    id={`communications.tray.${communication.idCommunicationTray}`}
                />
                <span> &gt; </span>
                <I18n component="span" id="communication.thread.message.me" />
            </div>
        );
    }
    return (
        <div>
            <I18n component="span" id="communication.thread.message.me" />
            <span> &gt; </span>
            <I18n
                component="span"
                className="data-aux"
                id={`communications.tray.${communication.idCommunicationTray}`}
            />
        </div>
    );
}

MessageSender.propTypes = {
    communication: shape({
        idCommunication: number,
        direction: string,
    }).isRequired,
};

class MessageItem extends Component {
    static propTypes = {
        index: number.isRequired,
        communication: shape({
            idCommunication: number,
            userRead: bool,
            sentDateAsString: string,
            direction: string,
            subject: string,
            body: string,
        }).isRequired,
        handleRemoveClick: func.isRequired,
        handleChangeMessageStatus: func.isRequired,
        handleSelectMessageClick: func.isRequired,
        handleOnClickOnLink: func.isRequired,
        isModalVisible: func.isRequired,
        handleModalDelete: func.isRequired,
    };

    render() {
        const {
            communication,
            index,
            handleRemoveClick,
            handleChangeMessageStatus,
            handleSelectMessageClick,
            handleOnClickOnLink,
            isModalVisible,
            handleModalDelete,
        } = this.props;

        return (
            <li
                ref={index}
                onClick={() => handleSelectMessageClick(communication.idCommunication, communication.userRead, index)}
                key={communication.idCommunication}
                className={communication.userRead ? "read-message" : "unread-message"}>
                <div className="message-item">
                    <div className="flex-container">
                        <MessageSender communication={communication} />
                        <span className="data-date">{communication.sentDateAsString}</span>
                    </div>

                    <div className="flex-container">
                        <div className="ellipsis">
                            <h4 className="message-title">
                                {communication.bodyMap.TITLE ? communication.bodyMap.TITLE : communication.subject}
                            </h4>
                            <p
                                onClick={handleOnClickOnLink}
                                className="data-desc list-content ellipsis"
                                // eslint-disable-next-line
                                dangerouslySetInnerHTML={{
                                    __html: communication.body,
                                }}
                            />
                        </div>
                        <MessageActions
                            communication={communication}
                            handleRemoveClick={handleRemoveClick}
                            handleChangeMessageStatus={handleChangeMessageStatus}
                            index={index}
                            isModalVisible={isModalVisible}
                            handleModalDelete={handleModalDelete}
                        />
                    </div>
                </div>
            </li>
        );
    }
}

export default MessageItem;
