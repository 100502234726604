import moment from "moment";
import { globalTypes } from "reducers/types/global";

export const types = {
    LOAD_SENT_REQUESTS_REQUEST: "debin/LOAD_SENT_REQUESTS_REQUEST",
    LOAD_SENT_REQUESTS_FAILURE: "debin/LOAD_SENT_REQUESTS_FAILURE",
    LOAD_SENT_REQUESTS_SUCCESS: "debin/LOAD_SENT_REQUESTS_SUCCESS",
    SEND_ADD_ACCOUNT_ACTION_REQUEST: "debin/SEND_ADD_ACCOUNT_ACTION_REQUEST",
    SEND_ADD_ACCOUNT_ACTION_FAILURE: "debin/SEND_ADD_ACCOUNT_ACTION_FAILURE",
    SEND_ADD_ACCOUNT_ACTION_SUCCESS: "debin/SEND_ADD_ACCOUNT_ACTION_SUCCESS",
    SET_DEBIN_TO_CANCEL: "debin/SET_DEBIN_TO_CANCEL",
    SET_ACCOUNT_TO_CHANGE: "debin/SET_ACCOUNT_TO_CHANGE",
    CANCEL_DEBIN_REQUEST: "debin/CANCEL_DEBIN_REQUEST",
    CANCEL_DEBIN_FAILURE: "debin/CANCEL_DEBIN_FAILURE",
    CANCEL_DEBIN_SUCCESS: "debin/CANCEL_DEBIN_SUCCESS",
    LOAD_INCOMING_DEBIN_LIST_REQUEST: "debin/LOAD_INCOMING_DEBIN_LIST_REQUEST",
    LOAD_INCOMING_DEBIN_LIST_FAILURE: "debin/LOAD_INCOMING_DEBIN_LIST_FAILURE",
    LOAD_INCOMING_DEBIN_LIST_SUCCESS: "debin/LOAD_INCOMING_DEBIN_LIST_SUCCESS",
    REMOVE_SELLER_ACCOUNT_REQUEST: "debin/REMOVE_SELLER_ACCOUNT_REQUEST",
    REMOVE_SELLER_ACCOUNT_FAILURE: "debin/REMOVE_SELLER_ACCOUNT_FAILURE",
    REMOVE_SELLER_ACCOUNT_SUCCESS: "debin/REMOVE_SELLER_ACCOUNT_SUCCESS",
    ACCEPT_INCOMING_DEBIN_PRE_REQUEST: "debin/ACCEPT_INCOMING_DEBIN_PRE_REQUEST",
    ACCEPT_INCOMING_DEBIN_PRE_REQUEST_REDIRECT: "debin/ACCEPT_INCOMING_DEBIN_PRE_REQUEST_REDIRECT",
    ACCEPT_INCOMING_DEBIN_PRE_FAILURE: "debin/ACCEPT_INCOMING_DEBIN_PRE_FAILURE",
    ACCEPT_INCOMING_DEBIN_PRE_SUCCESS: "debin/ACCEPT_INCOMING_DEBIN_PRE_SUCCESS",
    ACCEPT_INCOMING_DEBIN_SEND_REQUEST: "debin/ACCEPT_INCOMING_DEBIN_SEND_REQUEST",
    ACCEPT_INCOMING_DEBIN_SEND_FAILURE: "debin/ACCEPT_INCOMING_DEBIN_SEND_FAILURE",
    ACCEPT_INCOMING_DEBIN_SEND_SUCCESS: "debin/ACCEPT_INCOMING_DEBIN_SEND_SUCCESS",
    DECLINE_INCOMING_DEBIN_SEND_REQUEST: "debin/DECLINE_INCOMING_DEBIN_SEND_REQUEST",
    DECLINE_INCOMING_DEBIN_FAILURE: "debin/DECLINE_INCOMING_DEBIN_FAILURE",
    DECLINE_INCOMING_DEBIN_SUCCESS: "debin/DECLINE_INCOMING_DEBIN_SUCCESS",
    DECLINE_FIXED_TERM_SEND_REQUEST: "debin/DECLINE_FIXED_TERM_SEND_REQUEST",
    SET_CBU_LIST: "debin/SET_CBU_LIST",
    ADD_TO_CBU_LIST: "debin/ADD_TO_CBU_LIST",
    DEBIN_SEND_REQUEST: "debin/DEBIN_SEND_REQUEST",
    DEBIN_SEND_FAILURE: "debin/DEBIN_SEND_FAILURE",
    DEBIN_SEND_SUCCESS: "debin/DEBIN_SEND_SUCCESS",
    SEND_DEBIN_PRE_REQUEST: "debin/SEND_DEBIN_PRE_REQUEST",
    SEND_DEBIN_PRE_FAILURE: "debin/SEND_DEBIN_PRE_FAILURE",
    SEND_DEBIN_PRE_SUCCESS: "debin/SEND_DEBIN_PRE_SUCCESS",
    LOAD_CONFIRM_INCOMING_DEBIN: "debin/LOAD_CONFIRM_INCOMING_DEBIN",
    LOAD_ACCOUNT_NAME: "debin/LOAD_ACCOUNT_NAME",
    SEND_CREDIN_PRE_REQUEST: "debin/SEND_CREDIN_PRE_REQUEST",
    SEND_CREDIN_PRE_FAILURE: "debin/SEND_CREDIN_PRE_FAILURE",
    SEND_CREDIN_PRE_SUCCESS: "debin/SEND_CREDIN_PRE_SUCCESS",
    SEND_CREDIN_REQUEST: "debin/SEND_CREDIN_REQUEST",
    CREDIN_SEND_FAILURE: "debin/CREDIN_SEND_FAILURE",
    CREDIN_SEND_SUCCESS: "debin/CREDIN_SEND_SUCCESS",
    LOAD_CONFIRM_CREDIN: "debin/LOAD_CONFIRM_CREDIN",
    GET_SELLER_DEBIN_DETAIL_REQUEST: "debin/GET_SELLER_DEBIN_DETAIL_REQUEST",
    GET_SELLER_DEBIN_DETAIL_FAILURE: "debin/GET_SELLER_DEBIN_DETAIL_FAILURE",
    GET_SELLER_DEBIN_DETAIL_SUCCESS: "debin/GET_SELLER_DEBIN_DETAIL_SUCCESS",
    DEBIN_PRE_REQUEST: "debin/DEBIN_PRE_REQUEST",
    DEBIN_PRE_SUCCESS: "debin/DEBIN_PRE_SUCCESS",
    DEBIN_PRE_FAILURE: "debin/DEBIN_PRE_FAILURE",
    DEBIN_SELLER_CUIT: "debin/DEBIN_SELLER_CUIT",
    LOAD_DEBIN_SELLER: "debin/LOAD_DEBIN_SELLER",
    CLEAN_DEBIN_SELLER: "debin/CLEAN_DEBIN_SELLER",
    SET_DEBIN_PRESTACION: "debin/SET_DEBIN_PRESTACION",
    RELATE_BUYER_RECURRENCY: "debin/RELATE_BUYER_RECURRENCY",
    DEBIN_RECURRENCIES: "debin/DEBIN_RECURRENCIES",
    SET_DEBIN_RECURRENCIES: "debin/SET_DEBIN_RECURRENCIES",
    CLEAN_DEBIN_RECURRENCIES: "debin/CLEAN_DEBIN_RECURRENCIES",
    SET_RECURRENCY_FILTERS: "debin/SET_RECURRENCY_FILTERS",
    SET_RECURRENCY_DEBIN_ACTION: "debin/SET_RECURRENCY_DEBIN_ACTION",
    SET_UPDATE_RECURRENCY_STATE: "debin/SET_UPDATE_RECURRENCY_STATE",
    CLEAR_REQUEST_DATA: "debin/CLEAR_REQUEST_DATA",
    SET_DEBIN_SELLER: "debin/SET_DEBIN_SELLER",
    SET_IS_BACK_FROM_SUMMARY: "debin/SET_IS_BACK_FROM_SUMMARY",
    CLEAN_IS_BACK_FROM_SUMMARY: "debin/CLEAN_IS_BACK_FROM_SUMMARY",
    SET_PAGE_TO_GO_BACK: "debin/SET_PAGE_TO_GO_BACK",
    RESET_FILTERS: "debin/RESET_FILTERS",
    PUTNOTIFICATION: "debin/SET_NOTIFICATION",
    PUTNOTIFICATION_SUCCESS: "debin/PUTNOTIFICATION_SUCCESS",
    PUTNOTIFICATION_FAILURE: "debin/PUTNOTIFICATION_FAILURE",
    SET_AGENDA_DRAWER_VISIBLE: "debin/SET_AGENDA_DRAWER_VISIBLE",
};

const getLimitDate = (dateDif) => {
    const date = moment(new Date()); // the day before DST in the US

    date.add(dateDif, "days");

    return date;
};

export const INITIAL_STATE = {
    fetching: false,
    fetchingDownload: false,
    dateFrom: null,
    dateTo: null,
    titular: null,
    recipient: null,
    id: null,
    status: null,
    sentRequests: [],
    accountToChange: null,
    changeToStatus: null,
    accountAction: null,
    cbuList: [],
    summary: null,
    concepts: [],
    accounts: [],
    seller: {},
    prestaciones: [],
    debinPrestacion: {},
    debinRecurrency: [],
    filtersSentRequests: {
        dateFrom: moment()
            .add(-1, "weeks")
            .toDate(),
        dateTo: moment().toDate(),
        status: null,
        recipient: null,
        pageNumber: 1,
    },
    filtersIncomingDebins: {
        dateTo: moment().toDate(),
        dateFrom: moment()
            .add(-1, "weeks")
            .toDate(),
        recipient: "",
        status: "INICIADO",
        pageNumber: 1,
    },
    filterRecurrency: {
        createdTo: getLimitDate(7),
        createdFrom: getLimitDate(-90),
        account: "TODOS",
        seller: "",
        status: "TODOS",
        pageNumber: 1,
    },
    recurrencyDebinAction: {},
    pageNumber: 1,
    totalPages: 1,
    isBackFromSummary: false,
    pageToGoBack: null,
    control: false,
    agendaDrawerVisibleRed: false,
    modalValues: {
        cbu: "",
        reference: "",
        email: "",
    },
    allCBUsExist: false,
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        sentRequests,
        filters = {},
        accountToChange,
        otp,
        debinToCancel,
        debinDetail,
        cbuList,
        newCbu,
        debinSeller,
        recipients,
        id,
        summary,
        accountName,
        concepts,
        accounts,
        pageToGoBack,
        control,
    } = action;
    switch (action.type) {
        case types.SET_RECURRENCY_DEBIN_ACTION:
            return {
                ...state,
                recurrencyDebinAction: action.debin,
            };
        case types.DEBIN_RECURRENCIES:
            return {
                ...state,
                filterRecurrency: { ...state.filterRecurrency, ...action.formFilter },
            };
        case types.SET_RECURRENCY_FILTERS:
            return {
                ...state,
                filterRecurrency: { ...state.filterRecurrency, ...action.filters },
            };
        case types.CLEAN_DEBIN_RECURRENCIES:
            return {
                ...state,
                debinRecurrency: [],
                filterRecurrency: { ...state.filterRecurrency, paginas_totales: 1 },
            };
        case types.SET_DEBIN_RECURRENCIES:
            return {
                ...state,
                debinRecurrency: action.debinRecurrency && JSON.parse(action.debinRecurrency),
                filterRecurrency: { ...state.filterRecurrency, paginas_totales: action.paginas_totales },
            };
        case types.SET_DEBIN_PRESTACION:
            return {
                ...state,
                debinPrestacion: action.debinPrestacion,
            };
        case types.LOAD_DEBIN_SELLER:
            return {
                ...state,
                seller: action.seller && JSON.parse(action.seller),
                fetching: false,
                prestaciones: action.prestaciones && JSON.parse(action.prestaciones),
            };
        case types.CLEAN_DEBIN_SELLER:
            return {
                ...state,
                seller: {},
                prestaciones: [],
                fetching: false,
                debinPrestacion: {},
            };
        case types.LOAD_SENT_REQUESTS_REQUEST:
            return {
                ...state,
                filtersSentRequests: filters,
                fetching: true,
            };
        case types.LOAD_SENT_REQUESTS_FAILURE:
            return {
                ...state,
                cbuSelected: false,
            };
        case types.SET_CBU_LIST:
            return {
                ...state,
                cbuList,
            };
        case types.ADD_TO_CBU_LIST:
            return {
                ...state,
                cbuList: [...state.cbuList, newCbu],
            };
        case types.LOAD_SENT_REQUESTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                sentRequests,
                totalPages: action.totalPages,
            };
        case types.SEND_ADD_ACCOUNT_ACTION_REQUEST:
            return {
                ...state,
                accountToChange,
                fetching: true,
                otp,
            };
        case types.SEND_ADD_ACCOUNT_ACTION_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.SEND_ADD_ACCOUNT_ACTION_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.SET_ACCOUNT_TO_CHANGE:
            return {
                ...state,
                accountToChange,
            };
        case types.SET_DEBIN_TO_CANCEL:
            return {
                ...state,
                debinToCancel,
            };
        case types.CANCEL_DEBIN_REQUEST:
            return {
                ...state,
                otp,
                debinToCancel,
                fetching: true,
            };
        case types.CANCEL_DEBIN_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.CANCEL_DEBIN_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LOAD_INCOMING_DEBIN_LIST_REQUEST:
            return {
                ...state,
                filtersIncomingDebins: filters,
                fetching: true,
            };
        case types.LOAD_INCOMING_DEBIN_LIST_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LOAD_INCOMING_DEBIN_LIST_SUCCESS:
            return {
                ...state,
                sentRequests,
                fetching: false,
                totalPages: action.totalPages,
            };
        case types.REMOVE_SELLER_ACCOUNT_REQUEST:
            return {
                ...state,
                fetching: true,
                accountToChange,
            };
        case types.REMOVE_SELLER_ACCOUNT_FAILURE:
            return {
                ...state,
                fetching: false,
                accountToChange: null,
            };
        case types.REMOVE_SELLER_ACCOUNT_SUCCESS:
            return {
                ...state,
                fetching: false,
                accountToChange: null,
            };
        case types.ACCEPT_INCOMING_DEBIN_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ACCEPT_INCOMING_DEBIN_PRE_REQUEST_REDIRECT:
            return {
                ...state,
                fetching: true,
            };
        case types.ACCEPT_INCOMING_DEBIN_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.ACCEPT_INCOMING_DEBIN_PRE_SUCCESS:
            return {
                ...state,
                debinDetail,
                control,
                fetching: false,
            };
        case types.DEBIN_SEND_REQUEST:
            return {
                ...state,
                debinSeller,
                recipients,
                otp,
                fetching: true,
            };
        case types.DEBIN_SEND_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.DEBIN_SEND_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.SEND_CREDIN_REQUEST:
            return {
                ...state,
                debinSeller,
                fetching: true,
            };
        case types.CREDIN_SEND_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.ACCEPT_INCOMING_DEBIN_SEND_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.ACCEPT_INCOMING_DEBIN_SEND_FAILURE:
        case types.ACCEPT_INCOMING_DEBIN_SEND_SUCCESS:
            return {
                ...state,
                fetching: false,
                pageToGoBack: "/payments/payDebin",
            };
        case types.SEND_DEBIN_PRE_REQUEST:
            return {
                ...state,
                debinSeller,
                fetching: true,
            };
        case types.SEND_DEBIN_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                recipients,
            };
        case types.SEND_DEBIN_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LOAD_CONFIRM_INCOMING_DEBIN:
            return {
                ...state,
                summary,
            };
        case types.LOAD_ACCOUNT_NAME:
            return {
                ...state,
                accountName,
            };
        case types.CLEAR_REQUEST_DATA:
            return {
                ...state,
                fetching: false,
            };
        case types.SEND_CREDIN_PRE_REQUEST:
            return {
                ...state,
                debinSeller,
                fetching: true,
            };
        case types.SEND_CREDIN_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                recipients,
                id,
                control,
            };
        case types.SEND_CREDIN_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.GET_SELLER_DEBIN_DETAIL_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.GET_SELLER_DEBIN_DETAIL_SUCCESS:
            return {
                ...state,
                debinDetail,
                fetching: false,
            };
        case types.GET_SELLER_DEBIN_DETAIL_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LOAD_CONFIRM_CREDIN:
            return {
                ...state,
                summary,
            };
        case types.DEBIN_PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.DEBIN_PRE_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.DEBIN_PRE_SUCCESS:
            return {
                ...state,
                concepts,
                accounts,
                fetching: false,
            };
        case types.DEBIN_SELLER_CUIT:
            return {
                ...state,
                fetching: true,
            };
        case types.SET_DEBIN_SELLER:
            return {
                ...state,
                debinSeller,
                cbuList,
                recipients: debinSeller?.recipients,
            };
        case types.SET_IS_BACK_FROM_SUMMARY:
            return {
                ...state,
                isBackFromSummary: true,
            };
        case types.CLEAN_IS_BACK_FROM_SUMMARY:
            return {
                ...state,
                isBackFromSummary: false,
            };

        case types.SET_PAGE_TO_GO_BACK:
            return {
                ...state,
                pageToGoBack,
            };
        case types.RESET_FILTERS:
            return {
                ...state,
                filtersIncomingDebins: INITIAL_STATE.filtersIncomingDebins,
            };
        case types.PUTNOTIFICATION:
            return {
                ...state,
                fetchingDownload: true,
            };
        case types.PUTNOTIFICATION_SUCCESS:
        case types.PUTNOTIFICATION_FAILURE:
            return {
                ...state,
                fetchingDownload: false,
            };
        case types.SET_AGENDA_DRAWER_VISIBLE:
            return {
                ...state,
                agendaDrawerVisibleRed: action.agendaDrawerVisibleRed,
            };
        case globalTypes.RESET_SELECTED_ELEMENTS:
        case types.CREDIN_SEND_SUCCESS:
        case globalTypes.CLEAN_UP:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export const actions = {
    loadSentRequests: (filters) => ({
        type: types.LOAD_SENT_REQUESTS_REQUEST,
        filters,
    }),
    setCbuSelected: (cbuList) => ({
        type: types.SET_CBU_LIST,
        cbuList,
    }),
    setAccountToChange: (accountToChange) => ({
        type: types.SET_ACCOUNT_TO_CHANGE,
        accountToChange,
    }),
    sendAddAccountChange: (accountToChange, userId, otp, formikBag) => ({
        type: types.SEND_ADD_ACCOUNT_ACTION_REQUEST,
        accountToChange,
        userId,
        otp,
        formikBag,
    }),
    debinToCancel: (debinToCancel) => ({
        type: types.SET_DEBIN_TO_CANCEL,
        debinToCancel,
    }),
    cancelRequest: (debinToCancel, otp, formikBag) => ({
        type: types.CANCEL_DEBIN_REQUEST,
        debinToCancel,
        otp,
        formikBag,
    }),
    loadIncomingRequests: (filters) => ({
        type: types.LOAD_INCOMING_DEBIN_LIST_REQUEST,
        filters,
    }),
    removeSellerAccount: (accountToChange) => ({
        type: types.REMOVE_SELLER_ACCOUNT_REQUEST,
        accountToChange,
    }),
    acceptIncomingRequestPre: (idDebin) => ({
        type: types.ACCEPT_INCOMING_DEBIN_PRE_REQUEST,
        idDebin,
    }),
    acceptIncomingRequestPreRedirect: (idDebin, route) => ({
        type: types.ACCEPT_INCOMING_DEBIN_PRE_REQUEST_REDIRECT,
        idDebin,
        route,
    }),
    generateCredin: (otp, debinSeller, recipients, id, formikBag) => ({
        type: types.SEND_CREDIN_REQUEST,
        debinSeller,
        recipients,
        otp,
        id,
        formikBag,
    }),
    getDebinDetail: (idDebin) => ({
        type: types.GET_SELLER_DEBIN_DETAIL_REQUEST,
        idDebin,
    }),
    sendDebinRequest: (otp, debinSeller, recipients, formikBag) => ({
        type: types.DEBIN_SEND_REQUEST,
        debinSeller,
        recipients,
        otp,
        formikBag,
    }),
    clearRequestData: () => ({ type: types.CLEAR_REQUEST_DATA }),
    preGenerateCredin: (debinSeller, cbuList) => ({
        type: types.SEND_CREDIN_PRE_REQUEST,
        debinSeller,
        cbuList,
    }),
    sendDebinPreRequest: (debinSeller, cbuList) => ({
        type: types.SEND_DEBIN_PRE_REQUEST,
        debinSeller,
        cbuList,
    }),
    acceptIncomingRequestSend: (
        currency,
        amount,
        idDebin,
        otp,
        account,
        userId,
        formikBag,
        sellerDetail,
        idVoucher,
        isOwn,
    ) => ({
        type: types.ACCEPT_INCOMING_DEBIN_SEND_REQUEST,
        currency,
        amount,
        idDebin,
        otp,
        account,
        userId,
        formikBag,
        sellerDetail,
        idVoucher,
        isOwn,
    }),
    declineIncomingRequestSend: (currency, amount, idDebin, otp, account, userId, formikBag) => ({
        type: types.DECLINE_INCOMING_DEBIN_SEND_REQUEST,
        currency,
        amount,
        idDebin,
        otp,
        account,
        userId,
        formikBag,
    }),
    declineFixedTermRequestSend: (currency, amount, idDebin, otp, account, userId, formikBag) => ({
        type: types.DECLINE_FIXED_TERM_SEND_REQUEST,
        currency,
        amount,
        idDebin,
        otp,
        account,
        userId,
        formikBag,
    }),
    loadConfirmIncomingDebin: (summary) => ({
        type: types.LOAD_CONFIRM_INCOMING_DEBIN,
        summary,
    }),
    loadAccountName: (accountName) => ({
        type: types.LOAD_ACCOUNT_NAME,
        accountName,
    }),
    loadConfirmCredin: (summary) => ({
        type: types.LOAD_CONFIRM_CREDIN,
        summary,
    }),
    debinPreActivity: () => ({
        type: types.DEBIN_PRE_REQUEST,
    }),
    debinSeller: (cuit, page) => ({
        type: types.DEBIN_SELLER_CUIT,
        seller: cuit,
        page,
    }),
    cleanDebinSeller: () => ({
        type: types.CLEAN_DEBIN_SELLER,
    }),
    setdebinPrestacionData: (debinPrestacion) => ({
        type: types.SET_DEBIN_PRESTACION,
        debinPrestacion,
    }),
    relateBuyerToRecurrency: (debinPrestacion, setSubmitting) => ({
        type: types.RELATE_BUYER_RECURRENCY,
        debinPrestacion,
        setSubmitting,
    }),
    getDebinRecurrencies: (filterRecurrency, setSubmitting) => ({
        type: types.DEBIN_RECURRENCIES,
        formFilter: filterRecurrency,
        setSubmitting,
    }),
    setRecurrencyDebinFilters: (filters) => ({
        type: types.SET_RECURRENCY_FILTERS,
        filters,
    }),
    setRecurrencyDebinAction: (debin) => ({
        type: types.SET_RECURRENCY_DEBIN_ACTION,
        debin,
    }),
    updateRecurrencyState: (debin, setSubmitting) => ({
        type: types.SET_UPDATE_RECURRENCY_STATE,
        debin,
        setSubmitting,
    }),
    setDebinSeller: (debinSeller, cbuList) => ({
        type: types.SET_DEBIN_SELLER,
        debinSeller,
        cbuList,
    }),
    setIsBackFromSummary: (page) => ({
        type: types.SET_IS_BACK_FROM_SUMMARY,
        page,
    }),
    cleanIsBackFromSummary: (page) => ({
        type: types.CLEAN_IS_BACK_FROM_SUMMARY,
        page,
    }),
    setPageToGoBack: (pageToGoBack) => ({
        type: types.SET_PAGE_TO_GO_BACK,
        pageToGoBack,
    }),
    resetFilters: () => ({
        type: types.RESET_FILTERS,
    }),
    putNotification: (format) => ({
        type: types.PUTNOTIFICATION,
        format,
    }),
    setAgendaDrawerVisible: (agendaDrawerVisibleRed) => ({
        type: types.SET_AGENDA_DRAWER_VISIBLE,
        agendaDrawerVisibleRed,
    }),
};

export const selectors = {
    getAccountToChange: ({ debin }) => debin.accountToChange,
    getDebinToCancel: ({ debin }) => debin.debinToCancel,
    getSentRequests: ({ debin }) => debin.sentRequests,
    getFetching: ({ debin }) => debin.fetching,
    getDebinDetail: ({ debin }) => debin.debinDetail,
    getListCbu: ({ debin }) => debin.cbuList,
    getDebinSeller: ({ debin }) => debin.debinSeller,
    getRecipients: ({ debin }) => debin.recipients,
    getId: ({ debin }) => debin.id,
    getControl: ({ debin }) => debin.control,
    getSummary: ({ debin }) => debin.summary,
    getAccountName: ({ debin }) => debin.accountName,
    getConcepts: ({ debin }) => debin.concepts,
    getAccounts: ({ debin }) => debin.accounts,
    getSeller: ({ debin }) => debin.seller,
    getPrestaciones: ({ debin }) => debin.prestaciones,
    getdebinPrestacionData: ({ debin }) => debin.debinPrestacion,
    getRecurrenciasDebin: ({ debin }) => debin.debinRecurrency,
    getFiltersSentRequests: ({ debin }) => debin.filtersSentRequests,
    getFiltersIncomingDebins: ({ debin }) => debin.filtersIncomingDebins,
    getFilterRecurrency: ({ debin }) => debin.filterRecurrency,
    getRecurrencyDebinAction: ({ debin }) => debin.recurrencyDebinAction,
    getDebinObject: ({ debin }) => debin,
    getIsBackFromSummary: ({ debin }) => debin.isBackFromSummary,
    getPageToGoBack: ({ debin }) => debin.pageToGoBack,
    getFetchingDownload: ({ debin }) => debin.fetchingDownload,
    getAgendaDrawerVisibleRed: ({ debin }) => debin.agendaDrawerVisibleRed,
};
