import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, shape, string } from "prop-types";
import classNames from "classnames";
import { push } from "react-router-redux";

import { actions as recoveryPasswordActions, selectors as recoveryPasswordSelectors } from "reducers/recoveryPassword";
import { actions as loginActions } from "reducers/login";

import GridLayoutExternal from "pages/_layouts/GridLayoutExternal";
import Head from "pages/_components/Head";
import Logo from "pages/login/_components/Logo";
import MainContainer from "pages/_components/MainContainer";
import Step1Content from "pages/recoveryPassword/_components/Step1Content";
import Container from "pages/_components/Container";
import { Col } from "react-bootstrap";
import I18n from "pages/_components/I18n";
import * as configUtils from "util/config";

class RecoveryUserpassStep1 extends Component {
    componentDidMount() {
        const { dispatch, userName } = this.props;
        dispatch(recoveryPasswordActions.recoveryPassCleanUp());
        if (!userName) {
            dispatch(push("/"));
        }
    }

    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
    };

    renderTitleMobile = () => (
        <div className="w-min-content">
            <Logo className="svg-image logo logo-mobile-size" logoColor />
        </div>
    );

    render() {
        const { isDesktop, isRecoveringUserpass, userFirstName, isRecoveryPassAndUserpass, propsGrid } = this.props;

        return (
            <GridLayoutExternal scopeToShow="recoveryPassword" isDesktop={isDesktop} {...propsGrid}>
                <div className="admin-detail-head px-0">
                    <Container>
                        <Col xs="12" className={isDesktop ? "px-0" : ""}>
                            <Head
                                title={
                                    isDesktop &&
                                    (!isRecoveryPassAndUserpass
                                        ? "recoveryUserpass.step1.welcome"
                                        : "recoveryUserpass.step1.isRecoveryPassAndUserpass")
                                }
                                onClose={this.onHeaderClose}
                                headerClassName={
                                    !isDesktop
                                        ? "login-background-color-white"
                                        : "recover-pass-desktop login-header-desktop"
                                }
                                centerElement={!isDesktop && this.renderTitleMobile}
                                hideMobileMenu={!isDesktop && true}
                                titleTab="recoveryUserpass.step1.welcome.tab"
                                replace={
                                    isDesktop && {
                                        USER_NAME: userFirstName,
                                    }
                                }
                                fixed
                                tag="h4"
                            />
                        </Col>
                    </Container>
                </div>
                <MainContainer
                    className={classNames("login__layout main-container", {
                        "container-fluid": isDesktop,
                    })}>
                    {!isDesktop && configUtils.getBoolean("mobile.recovercredentials.enabled", false) && (
                        <Container className="container--layout flex-grow">
                            <Col className="col col-12 view-title">
                                <div className="justify-content-center">
                                    <I18n
                                        id={
                                            !isRecoveryPassAndUserpass
                                                ? "recoveryUserpass.step1.welcome"
                                                : "recoveryUserpass.step1.isRecoveryPassAndUserpass"
                                        }
                                        component="h2"
                                        componentProps={{ className: "f-size-5 font-regular mt-0" }}
                                        USER_NAME={userFirstName}
                                    />
                                </div>
                            </Col>
                        </Container>
                    )}
                    <Step1Content {...this.props} isRecoveringUserpass={isRecoveringUserpass} />
                </MainContainer>
            </GridLayoutExternal>
        );
    }
}

RecoveryUserpassStep1.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool,
    isRecoveringUserpass: bool,
    userFirstName: string,
    isRecoveryPassAndUserpass: bool,
    propsGrid: shape({}).isRequired,
    userName: string.isRequired,
};

RecoveryUserpassStep1.defaultProps = {
    isDesktop: true,
    isRecoveringUserpass: true,
    userFirstName: "",
    isRecoveryPassAndUserpass: false,
};

const mapStateToProps = (state) => ({
    maskedEmail: recoveryPasswordSelectors.getMaskedEmail(state),
    maskedPhone: recoveryPasswordSelectors.getMaskedPhone(state),
    userFirstName: recoveryPasswordSelectors.getUserFirstName(state),
    userName: recoveryPasswordSelectors.getUserName(state),
    isRecoveryPassAndUserpass: recoveryPasswordSelectors.getRecoveryPassAndUserpass(state),
});

export default connect(mapStateToProps)(RecoveryUserpassStep1);
