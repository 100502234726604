import { delay } from "redux-saga";
import { call, put, takeLatest, select } from "redux-saga/effects";

import * as configUtil from "util/config";
import * as communicationsMiddleware from "middleware/communications";
import { actions as notificationActions } from "reducers/notification";

import { globalTypes } from "reducers/types/global";
import { types, actions, selectors } from "reducers/communications";
import { types as loginTypes } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";

import * as i18n from "util/i18n";

const sagas = [
    takeLatest([globalTypes.INIT, loginTypes.LOGIN_SUCCESS], refreshLatestCommunications),
    takeLatest([types.LIST_TYPES_REQUEST], listTypes),
    takeLatest([types.LIST_REQUEST], list),
    takeLatest([types.FETCH_MORE_REQUEST], list),
];

export default sagas;

function* refreshLatestCommunications() {
    while (true) {
        const hasActiveSession = yield select(sessionSelectors.isLoggedIn);
        if (!hasActiveSession) {
            break;
        }

        try {
            const response = yield call(communicationsMiddleware.latestCommunications);
            if (response.status !== 304) {
                const { data } = response.data;
                const { unreadCommunicationsIds } = response.data.data;
                const currentUnreadCommunicationsIds = yield select(selectors.getUnreadCommunicationsIds);

                if (
                    window.location.pathname === "/communications" &&
                    JSON.stringify(unreadCommunicationsIds) !== JSON.stringify(currentUnreadCommunicationsIds)
                ) {
                    const listFilters = yield select(selectors.getListFilters);
                    const newResponse = yield call(communicationsMiddleware.list, listFilters);
                    const { communications, currentPage, totalPages } = newResponse.data.data;

                    const index = yield select(communicationSelectors.getSelectedIndex);
                    const selectedIdCommunication = yield select(communicationSelectors.getSelectedIdCommunication);

                    if (
                        index != null &&
                        !communications.includes((x) => x.idCommunication === selectedIdCommunication)
                    ) {
                        yield put(communicationActions.detailRequest(selectedIdCommunication, index));
                    }
                    yield put(actions.listSuccess(communications, currentPage, totalPages));
                }

                yield put({
                    type: types.LATEST_COMMUNICATION_SUCCESS,
                    unreadCommunications: data.unreadCommunications,
                    unreadCommunicationsIds: data.unreadCommunicationsIds,
                });
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }

        yield call(delay, configUtil.get("communications.refreshRate", 30) * 1000);
    }
}

function* listTypes() {
    const response = yield call(communicationsMiddleware.listTypes);

    if (response.type === "W") {
        yield put(actions.listTypesFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        yield put(actions.listTypesSuccess(response.data.data.communicationTypes));
    }
}

function* list({ filters }) {
    const response = yield call(communicationsMiddleware.list, { ...filters, orderBy: "sent_date DESC" });

    yield put(actions.setListFilters(filters));
    if (response.type === "W") {
        yield put(actions.listFailure);
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["communications"]),
        );
    } else {
        const { communications, currentPage, totalPages } = response.data.data;

        if (currentPage > 1) {
            yield put(actions.fetchMoreSuccess(communications, currentPage, totalPages));
        } else {
            yield put(actions.listSuccess(communications, currentPage, totalPages));
        }
    }
}
