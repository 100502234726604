/* eslint-disable camelcase */
import { bool, func, shape, string } from "prop-types";

import React from "react";

import Button from "pages/_components/Button";
import Dropdown from "pages/_components/Dropdown";
import * as i18nUtils from "util/i18n";
import { routerActions } from "react-router-redux/actions";
import { actions as cedipActions } from "reducers/cedip";
import Head from "pages/_components/Head";

function DetailHeadTitle({
    dispatch,
    cedip: { cedipId, numeroCertificado },
    isDesktop,
    fetchingDownload,
    handleClickDownload,
    handleBack,
}) {
    const handleClickManageCedip = () => {
        dispatch(cedipActions.handleClickManageCedip(cedipId));
        dispatch(routerActions.push("/cedip"));
    };

    const maskCedipId = (cedipId) => {
        const maskedPart = "***";
        const visiblePart = cedipId.slice(-7);
        return maskedPart + visiblePart;
    };

    const maskedCedipId = maskCedipId(cedipId);
    const downloadMobile = (
        <Button
            onClick={handleClickDownload("pdf")}
            label="global.download"
            className="dropdown__item-btn dropdown__item-btn-custom btn-b-none my-0"
        />
    );

    const dropDownContextMenuMobile = () => (
        <div className="toolbar-item">
            <Dropdown
                image="images/context-menu.svg"
                buttonClass="context-menu-button context-menu-dropdown view-back ml-0"
                bsStyle="link"
                imageStyle="toolbar-btn-image-mobile margin-right-negative"
                pullRight
                closeOnBlur={false}>
                {downloadMobile}
            </Dropdown>
        </div>
    );

    const centerElementMobile = () => (
        <div className="cedip__detail-head-mobile">
            <h1 className="cedip__detail-title">{cedipId}</h1>
            <p className="cedip__detail-subtitle">
                {`${i18nUtils.get("deposits.information.certificate").toUpperCase()}  Nº ${numeroCertificado}`}
            </p>
            <p className="cedip__detail-subtitle">
                {`${i18nUtils.get("deposits.information.certificate.cedip").toUpperCase()}  ${maskedCedipId}`}
            </p>
        </div>
    );

    const rightContent = () => (
        <div className="d-flex">
            <Button
                onClick={handleClickManageCedip}
                className="min-width-14"
                label="deposits.cedip.manageCedip"
                bsStyle="primary"
                imageStyle="mr-2"
            />
            <Dropdown
                image="images/download_bold.svg"
                imageStyle="mr-2"
                buttonClass="min-width-14"
                label="global.download"
                bsStyle="outline"
                fetching={fetchingDownload}
                pullDown>
                <Button
                    onClick={handleClickDownload("pdf")}
                    label="accounts.pdfFile"
                    className="dropdown__item-btn"
                    bsStyle="link"
                />
            </Dropdown>
        </div>
    );

    if (isDesktop) {
        return (
            <div className="cedip__detail-head">
                <Head onBack={handleBack} navStyle="default pl-0" headerClassName="align-items-center d-flex" />
                <Head
                    navStyle="default pl-0"
                    titleText={cedipId}
                    handleClickMessage="global.download"
                    rightContent={() => rightContent()}
                />
                <p className="cedip__detail-subtitle">
                    {`${i18nUtils.get("deposits.information.certificate").toUpperCase()}  Nº ${numeroCertificado}`}
                </p>
                <p className="cedip__detail-subtitle">
                    {`${i18nUtils.get("deposits.information.certificate.cedip").toUpperCase()}  ${maskedCedipId}`}
                </p>
            </div>
        );
    }

    return (
        <Head
            headerClassName="blue-main-header-mobile"
            titleClassName="w-100 align-center"
            onBack={handleBack}
            centerElement={centerElementMobile}
            rightContent={dropDownContextMenuMobile}
        />
    );
}

DetailHeadTitle.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    cedip: shape({
        cedipId: string.isRequired,
        numeroCertificado: string.isRequired,
    }).isRequired,
    fetchingDownload: bool.isRequired,
    handleClickDownload: func.isRequired,
    handleBack: func.isRequired,
};

DetailHeadTitle.defaultProps = {
    handleClickDownload: null,
};

export default DetailHeadTitle;
