import React, { Component } from "react";
import classNames from "classnames";
import { string, bool, shape, func } from "prop-types";

import Button from "pages/_components/Button";
import { Col, Grid, Row } from "react-bootstrap";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";

class SecuritySealCarrouselField extends Component {
    static propTypes = {
        field: shape({ name: string }).isRequired,
        idForm: string.isRequired,
        form: shape({
            touched: shape({}),
            errors: shape({}),
        }).isRequired,
        hideLabel: bool,
        securitySeals: shape([]),
        currentSecuritySeal: shape({}),
        handleSecuritySealClick: func.isRequired,
        isDesktop: bool,
    };

    static defaultProps = {
        hideLabel: false,
        securitySeals: [],
        currentSecuritySeal: {},
        isDesktop: true,
    };

    render() {
        const {
            field,
            form: { touched, errors },
            hideLabel,
            idForm,
            securitySeals,
            currentSecuritySeal,
            handleSecuritySealClick,
            form,
            isDesktop,
        } = this.props;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames("form-group", "form-group--image-selector", {
                    "has-error": hasError,
                })}>
                {!hideLabel && <FieldLabel labelKey={`${idForm}.${field.name}.label`} />}
                <Grid className="container-images">
                    <Row>
                        {Object.entries(securitySeals).map(([id, securitySeal]) => {
                            const colClass = classNames(isDesktop ? "col col-3 px-1" : "col col-4");
                            const btnClass = classNames("btn-image security-image", {
                                selected: id === `${currentSecuritySeal.id}`,
                                unselected:
                                    currentSecuritySeal && currentSecuritySeal.id && id !== `${currentSecuritySeal.id}`,
                            });
                            return (
                                <Col className={colClass} key={`securitySeal-${id}`}>
                                    <Button
                                        className={btnClass}
                                        id={id}
                                        label=""
                                        onClick={() => {
                                            form.setFieldValue(field.name, {
                                                id,
                                                image: securitySeals[id],
                                            });
                                            handleSecuritySealClick(id);
                                        }}
                                        style={{ backgroundImage: `url(${securitySeal})` }}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </Grid>

                {hasError && <FieldError error={errors[field.name].id} />}
            </div>
        );
    }
}

export default SecuritySealCarrouselField;
