import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import classNames from "classnames";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Yup from "yup";
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, shape, string, func, arrayOf } from "prop-types";

import { actions as tokenActions, selectors as tokenSelectors } from "reducers/token";
import { selectors as settingsSelectors, actions as settingsActions } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";

import * as i18n from "util/i18n";
import SelectorInput from "pages/_components/fields/formik/SelectorInput";
import Selector from "pages/_components/fields/formik/Selector";
import Logo from "pages/login/_components/Logo";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import Notification from "pages/_components/Notification";
import I18n from "pages/_components/I18n";
import Credential from "pages/_components/fields/credentials/Credential";
import Button from "pages/_components/Button";

const FORM_ID = "settings.token.recoverPassword";

class ChangeTokenPass extends Component {
    static propTypes = {
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        values: shape({
            username: string,
            secondFactor: string,
            captcha: string,
            rememberEmail: bool,
        }).isRequired,
        setValues: func.isRequired,
        currentEmail: string.isRequired,
        mobilePhone: string.isRequired,
        isTokenBlocked: bool.isRequired,
        loggedUser: shape({
            previousLoginInfo: arrayOf(
                shape({
                    idUser: string,
                }),
            ),
        }).isRequired,
        history: shape({}).isRequired,
        dispatch: func.isRequired,
        isMobileNative: bool,
    };

    static defaultProps = {
        isMobileNative: false,
    };

    state = {
        otherScreen: false,
    };

    componentDidMount() {
        const { dispatch, loggedUser } = this.props;
        this.pushNotification();
        if (loggedUser) {
            dispatch(settingsActions.getUserData());
        }
    }

    renderTitleMobileNative = () => <Logo className="svg-image logo logo-mobile-size" logoColor />;

    renderTitleMobile = () => {
        const { isTokenBlocked } = this.props;

        return (
            <div>
                <h1 className="m-0 text-white">
                    <I18n
                        id={
                            isTokenBlocked
                                ? "settings.token.blockedPassword.tittle"
                                : "settings.token.recoverPassword.tittle"
                        }
                    />
                </h1>
            </div>
        );
    };

    pushNotification = () => {
        const { dispatch, isMobileNative } = this.props;

        if (!isMobileNative) {
            dispatch(
                notificationActions.showNotification(
                    i18n.get("settings.token.recoverPassword.password.notification"),
                    "warning",
                    ["recoverTokenPass"],
                ),
            );
        }
    };

    selectChannel = () => {
        const { currentEmail, mobilePhone } = this.props;
        const { otherScreen } = this.state;
        const firstEmailLetter = currentEmail.charAt(0);
        const textAfterLastLetter = currentEmail.substring(currentEmail.indexOf("@") - 1, currentEmail.length);

        const maskPhone = `
            ${mobilePhone.charAt(0)}${mobilePhone.charAt(1)}******${mobilePhone.charAt(
            mobilePhone.length - 2,
        )}${mobilePhone.charAt(mobilePhone.length - 1)}`;

        return (
            <Col sm={12} className="mt-2 channel-select-token">
                <Field
                    component={Selector}
                    renderAs="radio"
                    radioDisplayGrid
                    name="sendChannel"
                    options={[
                        {
                            id: `numberPhone`,

                            label: `${i18n.get("setting.token.content.device.phone")} ${maskPhone}`,
                        },

                        {
                            id: `email`,

                            label: `${i18n.get(
                                "setting.token.content.device.email",
                            )} ${firstEmailLetter}*****${textAfterLastLetter}`,
                        },
                    ]}
                />

                <div>
                    <Button
                        label="recoveryPassword.step1.device.none"
                        type="link"
                        bsStyle="link"
                        onClick={() => this.setState({ otherScreen: !otherScreen })}
                    />
                </div>
            </Col>
        );
    };

    renderButton = () => {
        const { isSubmitting, isDesktop } = this.props;

        return (
            <Container
                className={`flex-grow align-items-center ${!isDesktop ? "container-white" : ""}`}
                gridClassName="form-content"
                style={!isDesktop ? { marginTop: "4rem", marginBottom: "10vh" } : {}}>
                <Col sm={12} md={4} lg={4} xl={4} className="px-0">
                    <Col sm={12} md={6} lg={6} xl={6} className="align-items-center">
                        <Button label="global.send" loading={isSubmitting} type="submit" bsStyle="primary" />
                    </Col>
                </Col>
            </Container>
        );
    };

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(push("/"));
    };

    handleBack = () => {
        const { history } = this.props;
        const { otherScreen } = this.state;

        if (!otherScreen) {
            history.goBack();
        } else {
            this.setState({ otherScreen: !otherScreen });
        }
    };

    render() {
        const { isDesktop, isMobileNative, isSubmitting, values, setValues, isTokenBlocked, loggedUser } = this.props;
        const { otherScreen } = this.state;

        return (
            <>
                <Notification scopeToShow="recoverTokenPass" notificationClassname="recover-token-notification" />
                <div className="admin-detail-head px-0">
                    <Head
                        onBack={
                            isMobileNative && isTokenBlocked && !values.loggedUser ? this.handleClose : this.handleBack
                        }
                        headerClassName={
                            !isDesktop
                                ? isMobileNative && isTokenBlocked && !values.loggedUser
                                    ? "login-background-color-white"
                                    : "blue-main-header-mobile"
                                : ""
                        }
                        centerElement={
                            !isDesktop
                                ? isMobileNative && isTokenBlocked && !values.loggedUser
                                    ? this.renderTitleMobileNative
                                    : this.renderTitleMobile
                                : ""
                        }
                        centerContentClassName={!isDesktop && "mx-5"}
                        hideMobileMenu={!isDesktop && true}
                    />
                    {isDesktop && (
                        <I18n
                            id={
                                isTokenBlocked
                                    ? isMobileNative
                                        ? "settings.token.modal.tittle"
                                        : "settings.token.blockedPassword.tittle"
                                    : "settings.token.recoverPassword.tittle"
                            }
                            component="h1"
                        />
                    )}
                </div>
                {isMobileNative && isTokenBlocked && !values.loggedUser ? (
                    <MainContainer
                        className={classNames("login__layout main-container", {
                            "container-fluid": isDesktop,
                        })}>
                        <Form>
                            <Container className="container--layout flex-grow">
                                <Col className="col col-12 view-title mt-3">
                                    <div className="justify-content-center">
                                        <I18n
                                            id="recoveryPassword.step2.disclaimer2.header"
                                            component="h2"
                                            componentProps={{ className: "f-size-5 font-regular" }}
                                        />
                                    </div>
                                </Col>
                            </Container>
                            <Container className="container--layout flex-grow mt-3">
                                <Col sm={12} md={12} lg={12} className="col col-12">
                                    <p className="text-lead">
                                        <I18n id="recoveryPassword.step2.disclaimer.message" />
                                    </p>
                                </Col>
                            </Container>
                            <Container className="align-items-center container--layout mt-3">
                                <Col sm={12} md={4}>
                                    <Button
                                        label="global.button.signout"
                                        loading={isSubmitting}
                                        type="submit"
                                        bsStyle="primary"
                                        onClick={this.handleClose}
                                    />
                                </Col>
                            </Container>
                        </Form>
                    </MainContainer>
                ) : !otherScreen ? (
                    <MainContainer className="unblock-token-pass">
                        <Form className="above-the-fold bg-white-mobile h-100-mobile">
                            <section className="container--layout flex-grow align-items-center cmf-container-white">
                                <Grid className="form-content">
                                    <Row className="justify-content-center">
                                        <div className="admin-content-center">
                                            <I18n id="settings.token.recoverPassword.subtittle" component="h3" />
                                        </div>
                                        <Col sm={12} md={9} lg={4} xl={4} className="align-items-center">
                                            <Field
                                                component={SelectorInput}
                                                name="document"
                                                selectorSize="small"
                                                selectProps={{
                                                    name: "documentType",
                                                    value: {
                                                        value: values.documentType,
                                                        label: values.documentType,
                                                    } /* values.documentType */,
                                                    options: [
                                                        { value: "DNI", label: "DNI" },
                                                        { value: "CUIT", label: "CUIT" },
                                                        { value: "CUIL", label: "CUIL" },
                                                        { value: "CDI", label: "CDI" },
                                                    ],
                                                }}
                                                isFocused
                                                label={i18n.get("login.step1.documentType.label")}
                                                inputProps={{
                                                    name: "document",
                                                    value: values.document,
                                                    inputmode: "numeric",
                                                }}
                                                onChange={(type, stringDocument) => {
                                                    setValues({
                                                        ...values,
                                                        documentType: type?.value ? type.value : type,
                                                        document: stringDocument,
                                                    });
                                                }}
                                            />
                                            <Field
                                                idForm={FORM_ID}
                                                name="username"
                                                component={Credential}
                                                copyEnabled={false}
                                            />
                                            <Field name="password" idForm={FORM_ID} component={Credential} />
                                            {loggedUser && this.selectChannel()}
                                        </Col>
                                        {!loggedUser && (
                                            <Col sm={12} md={12} lg={12} className="col col-12">
                                                <Button
                                                    label="global.continue"
                                                    type="submit"
                                                    bsStyle="primary"
                                                    loading={isSubmitting}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </Grid>
                            </section>
                            {loggedUser && (
                                <Container
                                    className={`flex-grow align-items-center ${!isDesktop ? "container-white" : ""}`}
                                    gridClassName="form-content">
                                    <Col sm={12} md={4}>
                                        <Button
                                            label="global.send"
                                            loading={isSubmitting}
                                            type="submit"
                                            bsStyle="primary"
                                        />
                                    </Col>
                                </Container>
                            )}
                        </Form>
                    </MainContainer>
                ) : (
                    <MainContainer>
                        <Form>
                            <div className="above-the-fold">
                                <Container
                                    className="container--layout flex-grow align-items-center container-white py-3"
                                    gridClassName="form-content">
                                    <Col className="col col-12">
                                        <Logo className="svg-image logo max-height-3 logo-mobile-size" logoColor />
                                    </Col>
                                    <Col className="col col-12">
                                        <div className="admin-content-center">
                                            <I18n id="recoveryPassword.step2.disclaimer.header" component="h1" />
                                        </div>
                                    </Col>
                                </Container>
                                <Container className="container--layout flex-grow align-items-center">
                                    <Col sm={12}>
                                        <I18n
                                            id="recoveryPassword.step2.disclaimer.message"
                                            component="p"
                                            componentProps={{ className: "font-light text-center" }}
                                        />
                                    </Col>
                                </Container>
                                <Container className="align-items-center container--layout">
                                    <Col sm={12} md={4}>
                                        <Button
                                            label="global.button.signout"
                                            loading={isSubmitting}
                                            type="submit"
                                            bsStyle="primary"
                                            onClick={this.handleBack}
                                        />
                                    </Col>
                                </Container>
                            </div>
                        </Form>
                    </MainContainer>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    currentEmail: settingsSelectors.getEmail(state),
    mobilePhone: settingsSelectors.getMobilePhone(state) || "",
    isTokenBlocked: tokenSelectors.getIsTokenBlocked(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: true,
        mapPropsToValues: (props) => ({
            username: "",
            documentType: props.documentType ? props.documentType : "DNI",
            document: props.document,
            password: "",
            sendChannel: "numberPhone",
            loggedUser: props.loggedUser,
        }),
        validationSchema: () =>
            Yup.lazy((values) => {
                const validations = !values?.loggedUser
                    ? {
                          username: Yup.string().required(i18n.get("login.step1.username.required")),
                          document: Yup.string().required(i18n.get("login.step1.document.required")),
                      }
                    : {};

                if (
                    values.username === undefined ||
                    values.documentType === undefined ||
                    values.document === undefined ||
                    values.password === undefined ||
                    values.sendChannel === undefined
                ) {
                    return Yup.object().shape({
                        username: Yup.string().required(i18n.get("login.step1.username.required")),
                        document: Yup.string().required(i18n.get("login.step1.document.required")),
                        password: Yup.string().required(i18n.get(`${FORM_ID}.password.required`)),
                        ...validations,
                    });
                }

                return Yup.object().shape({
                    password: Yup.string(),
                });
            }),

        handleSubmit: (values, formikBag) => {
            const onSubmit = (sendChannel) => {
                formikBag.props.dispatch(
                    tokenActions.recoverTokenPassword(
                        values.username,
                        values.documentType,
                        values.document,
                        values.password,
                        sendChannel,
                        formikBag,
                    ),
                );
            };

            if (values.loggedUser) {
                onSubmit(values.sendChannel);
            } else {
                const config = {
                    title: "recoveryToken.select.channel.title",
                    onSubmit,
                    headerTitle: "recoveryToken.select.channel.header",
                    closeable: true,
                };
                formikBag.props.dispatch(tokenActions.tokenRecoverAnonymousRequest(values, formikBag, config));
            }
        },
    }),
)(ChangeTokenPass);
