import * as API from "middleware/api";

export const operationTypeChange = (optionSelected) =>
    API.executeWithAccessToken("/ecomex.operationType.change", { optionSelected });

export const listEcomexOperations = (
    pageNumber,
    numberPerPage,
    optionSelected,
    ecomexState,
    operationType,
    beneficiaryName,
    operationNumber,
    openDateFrom,
    openDateTo,
    dueDateFrom,
    dueDateTo,
) =>
    API.executeWithAccessToken("/ecomex.consultOperations.list", {
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        beneficiaryName,
        operationNumber,
        openDateFrom,
        openDateTo,
        dueDateFrom,
        dueDateTo,
    });

export const listEcomexOperationsPre = (optionSelected) =>
    API.executeWithAccessToken("/ecomex.consultOperations.list.pre", { optionSelected });

export const downloadOperationsList = (summary, format) =>
    API.executeWithAccessToken("/ecomex.consultOperations.downloadList", { summary, format });

export const getEcomexOperationDetail = (selectedOperation) =>
    API.executeWithAccessToken("/ecomex.consultOperations.detail", { selectedOperation });

export const downloadOperationsDetail = (tabSelected, identification) =>
    API.executeWithAccessToken("/ecomex.consultOperations.downloadDetail", { tabSelected, identification });

export const listEcomexInformativeRegime = (
    pageNumber,
    numberPerPage,
    optionSelected,
    ecomexState,
    operationType,
    destination,
    operationNumber,
    payDateFrom,
    payDateTo,
    dueDateFrom,
    dueDateTo,
    officialDateFrom,
    officialDateTo,
    settlementDateFrom,
    settlementDateTo,
    embDateFrom,
    embDateTo,
) =>
    API.executeWithAccessToken("/ecomex.informativeRegime.list", {
        pageNumber,
        numberPerPage,
        optionSelected,
        ecomexState,
        operationType,
        destination,
        operationNumber,
        payDateFrom,
        payDateTo,
        dueDateFrom,
        dueDateTo,
        officialDateFrom,
        officialDateTo,
        settlementDateFrom,
        settlementDateTo,
        embDateFrom,
        embDateTo,
    });

export const listEcomexInformativeRegimePre = (optionSelected, ecomexState) =>
    API.executeWithAccessToken("/ecomex.informativeRegime.list.pre", { optionSelected, ecomexState });

export const downloadInformativeRegimeList = (summary, format) =>
    API.executeWithAccessToken("/ecomex.informativeRegime.downloadList", { summary, format });

export const operationTypeChangeInformativeRegime = (optionSelected) =>
    API.executeWithAccessToken("/ecomex.informativeRegime.operationType.change", { optionSelected });

export const getEcomexInformativeRegimeDetail = (selectedInformativeRegime, optionSelected) =>
    API.executeWithAccessToken("/ecomex.informativeRegime.detail", { selectedInformativeRegime, optionSelected });

export const downloadInformativeRegimeDetail = (tabSelected, identification) =>
    API.executeWithAccessToken("/ecomex.informativeRegime.downloadDetail", { tabSelected, identification });

export const listEcomexAlertsAndDues = (optionSelected, pageNumber, numberPerPage) =>
    API.executeWithAccessToken("/ecomex.alertsAndDues.list", { optionSelected, pageNumber, numberPerPage });

export const downloadAlertsAndDuesList = (summary, format) =>
    API.executeWithAccessToken("/ecomex.alertsAndDues.downloadList", { summary, format });
