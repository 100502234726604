import React, { Component } from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";
import flowRight from "lodash/flowRight";
import SelectIOS from "react-dropdown-select";
import * as i18n from "util/i18n";
import formField from "pages/forms/_components/_fields/_commons/formField";
import FormattedAmount from "pages/_components/FormattedAmount";
import withFocus from "pages/_components/withFocus";
import ProductTag from "pages/_components/ProductTag";
import Select from "pages/forms/_components/_fields/Select";
import FieldLabel from "pages/_components/fields/FieldLabel";
import { maskedAccountNumber } from "util/accounts";
import { isIOS } from "react-device-detect";

class Productselector extends Component {
    static propTypes = {
        data: shape({ options: arrayOf }).isRequired,
        editing: bool.isRequired,
        value: shape({
            isFrequentDestination: bool,
            value: string,
        }).isRequired,
        toggleIsFocused: func.isRequired,
        mode: string.isRequired,
        isRequired: bool.isRequired,
        setValue: func.isRequired,
        idField: string.isRequired,
        customPlaceholder: string.isRequired,
        disabled: string.isRequired,
        isDesktop: bool.isRequired,
        style: {},
        addOptionEverything: bool,
        onCustomChange: func,
        withoutBalance: bool,
    };

    static defaultProps = {
        style: {},
        withoutBalance: false,
        addOptionEverything: false,
        onCustomChange: null,
    };

    state = {
        isDefault: true,
    };

    componentDidMount() {
        const { mode, isRequired } = this.props;
        let { value } = this.props;
        if (mode === "edit") {
            if (!value && !isRequired) {
                value = "_empty";
            }
            if (value) {
                // es value.value si es un campo cargado con setFieldValue (dado que el valor es
                // {value: xxx, isFrequestDestination: bool}) si es un valor que viene cargado por url,
                // el value es directamente el id de la cuenta es así hasta que eliminemos el
                // isFrequestDestination y esto se resuelva de otra forma (con value solito :P)
                this.handleChange({ id: value || value });
            }
        }
    }

    handleChange = ({ id }) => {
        const { setValue, onCustomChange } = this.props;

        if (onCustomChange) {
            onCustomChange(id);
        }

        if (id) {
            setValue(id);
        }
    };

    handleChangeIOS = (option) => {
        const { setValue, onCustomChange } = this.props;
        if (onCustomChange) {
            onCustomChange(option[0].id);
        }

        if (option[0].id) {
            setValue(option[0].id);
        }
        this.setState({ isDefault: false });
    };

    buildProductOptions = (originalOptions) => {
        const { isRequired, isDesktop, withoutBalance } = this.props;

        // \u00A0 es un character especial para forzar un espacio en blanco
        const options = isRequired
            ? originalOptions
            : [{ id: "_empty", isFrequentDestination: false, label: "\u00A0" }, ...originalOptions];
        return options.map((elem) => {
            if (typeof elem.balance !== "undefined") {
                return {
                    id: elem.idProduct,
                    label: isDesktop ? (
                        <>
                            <span className="control-label">
                                {`${elem.productAlias || maskedAccountNumber(elem.number)} ${
                                    elem.productType
                                } ${i18n.get(`currency.label.${elem.currency}`)}`}
                            </span>
                            {!withoutBalance && (
                                <FormattedAmount quantity={elem.balance} currency={elem.currency} notBold />
                            )}
                        </>
                    ) : (
                        <div style={{ display: "grid" }}>
                            <div className="control-label transfer-dropdown-account-font">
                                {`${elem.productAlias || maskedAccountNumber(elem.number)} ${
                                    elem.productType
                                } ${i18n.get(`currency.label.${elem.currency}`)}`}
                            </div>
                            {!withoutBalance && (
                                <FormattedAmount
                                    quantity={elem.balance}
                                    currency={elem.currency}
                                    className="transfer-dropdown-account-font gray-color"
                                    notBold
                                />
                            )}
                        </div>
                    ),
                };
            }
            return {
                id: elem.id,
                label: elem.label,
            };
        });
    };

    render() {
        const {
            editing,
            value,
            toggleIsFocused,
            data: { options },
            idField,
            disabled,
            customPlaceholder,
            style,
            addOptionEverything,
        } = this.props;
        const { isDefault } = this.state;
        const productId = value && value.value ? value.value : value;

        if (editing) {
            let newOptions = this.buildProductOptions(options);

            if (addOptionEverything) {
                newOptions = [{ id: "TODOS", label: "TODOS" }, ...newOptions];
            }

            const productIosDefault = newOptions.find((prod) => prod.id === productId);

            return (
                <>
                    <div className="input-group">
                        <div className="selector-container">
                            {!isIOS ? (
                                <Select
                                    id={idField.name}
                                    disabled={disabled}
                                    value={productId}
                                    clearable={false}
                                    searchable={false}
                                    onChange={this.handleChange}
                                    valueKey="id"
                                    options={newOptions}
                                    onFocus={toggleIsFocused}
                                    onBlur={toggleIsFocused}
                                    className="flex-container slideFromBottom"
                                    optionClassName="needsclick"
                                    placeholder={customPlaceholder}
                                    style={style}
                                    noResultsText={i18n.get(`global.no.results`)}
                                />
                            ) : (
                                <SelectIOS
                                    options={newOptions}
                                    onChange={this.handleChangeIOS}
                                    className="flex-container slideFromBottom IOS-select px-3"
                                    color="#fff"
                                    noDataLabel={i18n.get(`global.no.results`)}
                                    style={{
                                        border: "none",
                                    }}
                                    inputRenderer={({ inputRef }) => {
                                        if (productIosDefault && isDefault) {
                                            return <div ref={inputRef}>{productIosDefault.label}</div>;
                                        }
                                        if (isDefault) {
                                            return (
                                                <div ref={inputRef} className="Select-placeholder">
                                                    <span>{customPlaceholder}</span>
                                                </div>
                                            );
                                        }
                                        return <div ref={inputRef} />;
                                    }}
                                    dropdownHandleRenderer={() => <div className="Select-arrow" />}
                                    dropdownPosition="bottom"
                                    searchable={false}
                                    valueField="id"
                                />
                            )}
                        </div>
                    </div>
                </>
            );
        }
        const { label } = options.find(({ id }) => id === productId) || {};

        return (
            <>
                <ProductTag alias={label} />
            </>
        );
    }
}

const AccountLabel = (props) => {
    const { field, idForm, blackLabel, isTransferFormSelect, controlLabelChildClassName, controlLabelClassName } = props;

    return (
        <FieldLabel
            controlLabelChildClassName={controlLabelChildClassName}
            idField={field.name}
            labelKey={`${idForm}.${field.name}.label`}
            blackLabel={blackLabel}
            isTransferFormSelect={isTransferFormSelect}
            controlLabelClassName={controlLabelClassName}
        />
    );
};

AccountLabel.propTypes = {
    idForm: string.isRequired,
    field: shape({
        name: string.isRequired,
    }).isRequired,
    blackLabel: bool,
    isTransferFormSelect: bool,
    controlLabelChildClassName: string,
    controlLabelClassName: string,
};

AccountLabel.defaultProps = {
    blackLabel: false,
    isTransferFormSelect: false,
    controlLabelChildClassName: "",
    controlLabelClassName: "",
};

export default flowRight(
    withFocus,
    formField({
        customLabel: AccountLabel,
    }),
)(Productselector);
