/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { goBack, push } from "react-router-redux";
import * as i18n from "util/i18n";
import { FORM_ID } from "constants/servicePayments";

import Col from "react-bootstrap/lib/Col";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import classNames from "classnames";
import { arrayOf, bool, func, shape, string } from "prop-types";

import { selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import { selectors as accountsSelectors } from "reducers/accounts";

import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import { getAccountDescription } from "util/accounts";
import I18n from "pages/_components/I18n";
import { parseFloatToAmountFormat } from "util/number";
import moment from "moment";
import CardContainer from "pages/_components/Card/CardContainer";
import Card from "pages/_components/Card/Card";
import FormattedAmount from "pages/_components/FormattedAmount";
import MultiplePaymentsConfirmationToken from "./MultiplePaymentsConfirmationToken";

const MultiplePaymentsConfirmation = ({ isDesktop, dispatch, account, payments, accounts, redirect }) => {
    const [redirectState] = useState(redirect);
    const currencies = [{ id: 0, label: i18n.get(`currency.label.0`) }];
    const completeDebitAccount = accounts.find(({ idProduct }) => idProduct === account);

    useEffect(() => {
        if (redirectState) {
            dispatch(push("/servicePayments"));
        }
    }, [redirectState, dispatch]);

    const handleBack = () => {
        dispatch(goBack());
    };

    const centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", margin: 0 }}>{i18n.get("services.newPayment")}</h1>
        </div>
    );

    const renderHeader = () => (
        <Container>
            <Col sm={12} className="d-flex justify-content-end">
                <div className="d-flex flex-column justify-content-center text-right text-uppercase">
                    <SelectedAccountsAmount
                        list={payments.filter(({ isChecked }) => isChecked)}
                        summaryView
                        leftOperations={false}
                    />
                </div>
            </Col>
            <CardContainer className="confirmation__cards-container my-4">
                <Card
                    className={`confirmation__cards ${isDesktop && "pl-0"}`}
                    hrClass={!isDesktop && "d-none"}
                    title={
                        !isDesktop && (
                            <div className="confirmation-cards__title p-0 space-between border-none pb-0 mb-0">
                                <div className="confirmation-cards__title-data">
                                    <span>{i18n.get("transfers.debitAccount.label")}</span>
                                </div>
                                <div className="confirmation-cards__title-info font-regular">
                                    <span>
                                        {completeDebitAccount?.number !== undefined &&
                                            getAccountDescription(completeDebitAccount)}
                                    </span>
                                </div>
                            </div>
                        )
                    }
                    content={
                        isDesktop && (
                            <div className="confirmation-cards__content p-0">
                                <div className="confirmation-cards__content-data">
                                    <span>{i18n.get("transfers.debitAccount.label")}</span>
                                </div>
                                <div className="confirmation-cards__content-info">
                                    <span>
                                        {completeDebitAccount?.number !== undefined &&
                                            getAccountDescription(completeDebitAccount)}
                                    </span>
                                </div>
                            </div>
                        )
                    }
                />
            </CardContainer>
        </Container>
    );

    const renderInfo = ({ entity, concept, ...payment }) => (
        <CardContainer className="confirmation__cards-container mt-0">
            <Card
                className="confirmation__cards"
                icon={isDesktop && "images/transfer-PENDING.svg"}
                iconClass="card__pending-transaction-icon"
                title={
                    isDesktop ? (
                        <div className="d-flex align-items-center">
                            <div
                                className={`confirmation-cards__title ${payment.reference &&
                                    "confirmation-cards-separator"}`}>
                                <div className="confirmation-cards__title-data">
                                    <span>{i18n.get(`${FORM_ID}.confirmationTicket.expiration`)}</span>
                                </div>
                                <div className="confirmation-cards__title-info">
                                    <span>
                                        {concept?.date
                                            ? moment(concept?.date).format("DD/MM/YYYY")
                                            : i18n.get("services.newPayment.noExpirationDate")}
                                    </span>
                                </div>
                            </div>
                            <div className="confirmation-cards__title">
                                <div className="confirmation-cards__title-data no-wrap">
                                    <span>{i18n.get(`${FORM_ID}.confirmationTicket.paymentCode`)}</span>
                                </div>
                                <div className="confirmation-cards__title-info">
                                    <span>{payment.reference}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="confirmation-cards__title">
                            <div className="confirmation-cards__title-info no-wrap">
                                <span>{entity.category.description}</span>
                            </div>
                        </div>
                    )
                }
                content={
                    isDesktop ? (
                        <div className="d-flex align-items-center">
                            <div className="confirmation-cards__content pb-1">
                                <div className="confirmation-cards__content-info">
                                    <span>{entity.category.description}</span>
                                </div>
                            </div>
                            {concept.description && (
                                <div className="confirmation-cards__content info-type-a">
                                    <div className="confirmation-cards__content-data">
                                        <span>{i18n.get(`${FORM_ID}.confirmationTicket.serviceReference`)}</span>
                                    </div>
                                    <div className="confirmation-cards__content-info">
                                        <span>{concept.description}</span>
                                    </div>
                                </div>
                            )}
                            {payment.paymentReference && (
                                <div className="confirmation-cards__content info-type-b">
                                    <div className="confirmation-cards__content-data">
                                        <span>{i18n.get(`${FORM_ID}.confirmationTicket.paymentReference`)}</span>
                                    </div>
                                    <div className="confirmation-cards__content-info">
                                        <span>{payment.paymentReference}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <div className="confirmation-cards__content">
                                <div className="confirmation-cards__content-data">
                                    <span>{i18n.get(`${FORM_ID}.confirmationTicket.expiration`)}</span>
                                </div>
                                <div className="confirmation-cards__content-info">
                                    <span>
                                        {concept?.date
                                            ? moment(concept?.date).format("DD/MM/YYYY")
                                            : i18n.get("services.newPayment.noExpirationDate")}
                                    </span>
                                </div>
                            </div>
                            <div className="confirmation-cards__content">
                                <div className="confirmation-cards__content-data">
                                    <span>{i18n.get(`${FORM_ID}.confirmationTicket.paymentCode`)}</span>
                                </div>
                                <div className="confirmation-cards__content-info">
                                    <span>{payment.reference}</span>
                                </div>
                            </div>
                            <div className="confirmation-cards__content">
                                <div className="confirmation-cards__content-data">
                                    <span>{i18n.get(`${FORM_ID}.confirmationTicket.serviceReference.mobile`)}</span>
                                </div>
                                <div className="confirmation-cards__content-info">
                                    <span>{concept.description}</span>
                                </div>
                            </div>
                            {payment.paymentReference && (
                                <div className="confirmation-cards__content">
                                    <div className="confirmation-cards__content-data">
                                        <span>{i18n.get(`${FORM_ID}.confirmationTicket.paymentReference.mobile`)}</span>
                                    </div>
                                    <div className="confirmation-cards__content-info">
                                        <span>{payment.paymentReference}</span>
                                    </div>
                                </div>
                            )}
                            <div className="confirmation-cards__content">
                                <div className="confirmation-cards__content-data">
                                    <span>{i18n.get(`${FORM_ID}.confirmationTicket.concept`)}</span>
                                </div>
                                <div className="confirmation-cards__content-info">
                                    <span>{concept.description}</span>
                                </div>
                            </div>
                            <div className="confirmation-cards__content">
                                <div className="confirmation-cards__content-data">
                                    <span>{i18n.get(`${FORM_ID}.confirmationTicket.company`)}</span>
                                </div>
                                <div className="confirmation-cards__content-info">
                                    <span>{entity.description}</span>
                                </div>
                            </div>
                        </div>
                    )
                }
                bottom={
                    isDesktop && (
                        <div className="d-flex align-items-center">
                            <div
                                className={`confirmation-cards__bottom ${entity.description &&
                                    "confirmation-cards-separator"}`}>
                                <div className="confirmation-cards__bottom-data">
                                    <span>{i18n.get(`${FORM_ID}.confirmationTicket.concept`)}</span>
                                </div>
                                <div className="confirmation-cards__bottom-info">
                                    <span>{concept.description}</span>
                                </div>
                            </div>
                            <div className="confirmation-cards__bottom">
                                <div className="confirmation-cards__bottom-data">
                                    <span>{i18n.get(`${FORM_ID}.confirmationTicket.company`)}</span>
                                </div>
                                <div className="confirmation-cards__bottom-info">
                                    <span>{entity.description}</span>
                                </div>
                            </div>
                        </div>
                    )
                }
                rightContent={
                    <div className="confirmation-cards__right">
                        <div className="confirmation-cards__right-data">
                            <FormattedAmount
                                currency={currencies[0].label.toString()}
                                quantity={parseFloatToAmountFormat(payment.amount.quantity)}
                            />
                        </div>
                    </div>
                }
            />
        </CardContainer>
    );

    return (
        <>
            <Notification scopeToShow="servicePayments" />
            <div className={classNames({ isDesktop: "admin-detail-head px-0 mb-4" })}>
                <Head
                    onBack={handleBack}
                    headerClassName={!isDesktop ? "blue-main-header-mobile agenda-payment-title-mobile" : ""}
                    navStyle="default pl-0"
                    centerElement={isDesktop ? undefined : centerContentMobile}
                />
                {isDesktop && (
                    <div className="view-title mb-2">
                        <h1>{i18n.get(`${FORM_ID}.confirmationTicket.title`)}</h1>
                    </div>
                )}
                <div
                    className={`admin-content-center mb-4 w-70 ${
                        !isDesktop ? "admin-content-center-mobile my-4" : "text-left"
                    }`}>
                    <I18n id={`${FORM_ID}.confirmationTicket.label`} component="p" />
                </div>
            </div>
            {renderHeader()}
            {payments
                .filter(({ isChecked }) => isChecked)
                .map((payment) => (
                    <div>{renderInfo(payment)}</div>
                ))}
            <MultiplePaymentsConfirmationToken isDesktop={isDesktop} payments={payments} />
            <MainContainer />
        </>
    );
};

MultiplePaymentsConfirmation.propTypes = {
    isDesktop: bool.isRequired,
    account: string.isRequired,
    accounts: arrayOf(shape({})).isRequired,
    payments: arrayOf(shape({})).isRequired,
    dispatch: func.isRequired,
};

const mapStateToProps = (state) => ({
    payments: servicePaymentsSelectors.getMultiplePaymentsInfo(state),
    account: servicePaymentsSelectors.getMultiplePaymentsAccount(state),
    redirect: servicePaymentsSelectors.getBackFromTicket(state),
    accounts: accountsSelectors.getAccounts(state),
});

export default compose(connect(mapStateToProps))(MultiplePaymentsConfirmation);
