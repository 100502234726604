/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { replace, push } from "react-router-redux";
import { arrayOf, bool, func, number, shape, string } from "prop-types";

import { actions as communicationActions, selectors as communicationSelectors } from "reducers/communication";
import { actions as communicationsActions } from "reducers/communications";
import { selectors as sessionSelectors } from "reducers/session";

import Loader from "pages/_components/Loader";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";

import ChatLayout from "pages/communications/_components/ChatLayout";

const ButtonWrapper = ({ onSubmit, onDelete }) => (
    <div className="btn-wrapper">
        <Button className="btn-primary" label="communications.message.reply.label" onClick={onSubmit} />
        {onDelete && (
            <Button
                className="btn-outline"
                image="images/delete-message.svg"
                label="communications.message.delete.label"
                onClick={onDelete}
            />
        )}
    </div>
);

ButtonWrapper.propTypes = {
    onSubmit: func.isRequired,
    onDelete: func,
};

ButtonWrapper.defaultProps = {
    onDelete: null,
};

const MessageHeader = ({ title, onClose }) => (
    <div className="flex-container">
        <div className="view-title">
            <h3>
                <strong>{title}</strong>
            </h3>
        </div>
        {onClose && (
            <div className="toolbar-item toolbar-item--fixed toolbar-item-right">
                <Button className="toolbar-btn view-close" onClick={onClose} image="images/cross.svg" bsStyle="link" />
            </div>
        )}
    </div>
);

MessageHeader.propTypes = {
    title: string.isRequired,
    onClose: func,
};

MessageHeader.defaultProps = {
    onClose: null,
};
class Reader extends Component {
    static propTypes = {
        attachments: arrayOf({
            idCommunication: number,
            files: arrayOf(
                shape({
                    idFile: number,
                    fileName: string,
                }),
            ),
        }),
        dispatch: func.isRequired,

        detail: arrayOf(
            shape({
                body: string,
                communicationTray: shape({
                    name: string,
                }),
                idCommunication: number,
                subject: string,
                sentDateAsString: string,
            }),
        ),
        selectedIndex: number.isRequired,
        isDesktop: bool.isRequired,
        isFetching: bool,
        environments: String.isRequired,
        activeEnvironment: String.isRequired,
        isModalVisible: func.isRequired,
        handleModalDelete: func.isRequired,
    };

    static defaultProps = {
        attachments: null,
        detail: null,
        isFetching: true,
    };

    handleDeleteClick = () => {
        const { detail, dispatch, selectedIndex, isModalVisible } = this.props;

        dispatch(communicationActions.deleteRequest(detail[0].idCommunication, selectedIndex));
        dispatch(replace("/communications"));
        isModalVisible(false);
    };

    handleReplyClick = () => {
        const { detail, dispatch, isDesktop } = this.props;

        if (isDesktop) {
            dispatch(communicationActions.detailRequest(detail[0].idCommunication));
            dispatch(communicationsActions.showReplyPanel());
        } else {
            dispatch(push(`/communications/reply/${detail[0].idCommunication}`));
        }
    };

    handleCloseClick = () => {
        const { dispatch } = this.props;
        dispatch(communicationsActions.hideAllPanels());
    };

    handleDownload = (idFile) => () => {
        const { dispatch } = this.props;
        dispatch(communicationActions.downloadAttachmentRequest(idFile));
    };

    scrollToBottom = () => {
        const { isDesktop } = this.props;

        const nodes = document.getElementsByClassName("view-content");
        let objDiv = nodes[nodes.length - 1];
        if (isDesktop) {
            objDiv = document.getElementById("chatLayout");
        }
        objDiv.scrollTop = objDiv.scrollHeight;
    };

    getUrl = (event) => {
        const linkElement = event.currentTarget.querySelector("a");
        return linkElement.getAttribute("href");
    };

    handleOnClickOnLink = (event) => {
        const { dispatch, environments, activeEnvironment } = this.props;
        const { target } = event;
        if (target.tagName.toLowerCase() === "i") {
            event.preventDefault();
            event.stopPropagation();
            const url = this.getUrl(event);
            const env = this.getEnvironmentFromUrl(url);
            if (env === activeEnvironment.id.toString()) {
                window.location.href = url;
            } else {
                const correctEnvironmentName = environments[env].name;
                dispatch(communicationActions.showWrongEnvironmentModal(correctEnvironmentName));
            }
        }
    };

    getEnvironmentFromUrl = (url) => {
        const match = url.match(/\/(\d+)\//);
        return match[1].toString();
    };

    render() {
        const { detail, attachments, isDesktop, isFetching, isModalVisible, handleModalDelete } = this.props;

        handleModalDelete(() => {
            this.handleDeleteClick();
        });

        return (
            (isFetching && <Loader />) ||
            (detail &&
                (isDesktop ? (
                    <div className="above-the-fold" style={{ height: "100%" }}>
                        <div
                            style={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                padding: "0 1rem",
                            }}>
                            <div
                                style={{
                                    padding: "1rem 0 0",
                                }}>
                                <MessageHeader
                                    title={detail[0].bodyMap.TITLE ? detail[0].bodyMap.TITLE : detail[0].subject}
                                    onClose={this.handleCloseClick}
                                />
                            </div>
                            <div
                                id="chatLayout"
                                style={{
                                    flex: "1",
                                    overflow: "auto",
                                }}>
                                <ChatLayout
                                    detail={detail}
                                    attachments={attachments}
                                    scrollToBottom={this.scrollToBottom}
                                    handleDownloadAttachment={this.handleDownload}
                                    handleOnClickOnLink={this.handleOnClickOnLink}
                                />
                            </div>
                            <ButtonWrapper onSubmit={this.handleReplyClick} onDelete={(e) => isModalVisible(e, true)} />
                        </div>
                    </div>
                ) : (
                    <div className="above-the-fold">
                        <Container className="flex-grow container--layout container--scroll">
                            <Col className="col col-12 open-conversation">
                                <ChatLayout
                                    detail={detail}
                                    attachments={attachments}
                                    scrollToBottom={this.scrollToBottom}
                                    handleDownloadAttachment={this.handleDownload}
                                    handleOnClickOnLink={this.handleOnClickOnLink}
                                />
                            </Col>
                        </Container>
                        <Container className="container--layout">
                            <Col className="col col-12">
                                <ButtonWrapper
                                    onSubmit={this.handleReplyClick}
                                    onDelete={(e) => isModalVisible(e, true)}
                                />
                            </Col>
                        </Container>
                    </div>
                )))
        );
    }
}

const mapStateToProps = (state) => ({
    detail: communicationSelectors.detail(state),
    attachments: communicationSelectors.attachments(state),
    selectedIndex: communicationSelectors.getSelectedIndex(state),
    isFetching: communicationSelectors.isFetching(state),
    environments: sessionSelectors.getEnvironments(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(Reader);
