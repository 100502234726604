import React, { Component } from "react";
import { func, bool, shape, string } from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import classNames from "classnames";
import Col from "react-bootstrap/lib/Col";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import AccountSummaryData from "pages/accounts/AccountSummaryData";
import AccountTokenConfirmation from "pages/accounts/AccountTokenConfirmation";
import I18n from "pages/_components/I18n";

import * as i18n from "util/i18n";

import { selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";

const FORM_ID = "accounts.new.account";

class AccountsOpeningSummary extends Component {
    static propTypes = {
        isDesktop: bool,
        dispatch: func.isRequired,
        openingData: shape({}).isRequired,
        fullName: string.isRequired,
    };

    static defaultProps = {
        isDesktop: true,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push(`/accountOpening/back`));
    };

    render() {
        const { isDesktop, openingData, fullName } = this.props;

        const centerContentMobile = () => (
            <div className="title-account-header-multiline-background-blue w-100">
                <h1>{i18n.get("new.account")}</h1>
            </div>
        );

        return Object.keys(openingData).length ? (
            <>
                <Notification scopeToShow="accountOpeningSummary" />
                <div className={isDesktop ? "admin-detail-head px-0" : ""}>
                    <Head
                        onBack={this.handleBack}
                        closeLinkTo="/accounts"
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : centerContentMobile}
                    />
                    {isDesktop && (
                        <div className="view-title">
                            <h1>{i18n.get(openingData?.returnToPending ? "modify.account" : "new.account")}</h1>
                        </div>
                    )}
                </div>
                <MainContainer viewPageClassName="confirmation__container">
                    <div className="above-the-fold">
                        <Container
                            className={!isDesktop && "flex-grow align-items-center container-white py-4"}
                            gridClassName={isDesktop ? "confirmation__form confirmation__box mb-0" : "form-content"}>
                            <Col className="col-12 col-xl-12" lg={12} md={9} sm={12}>
                                <div
                                    className={classNames("admin-content-center", {
                                        "mb-3": !isDesktop,
                                    })}>
                                    <I18n
                                        id="account.opening.summary.checkInfo.label"
                                        component={!isDesktop ? "h2" : "h1"}
                                        componentProps={{ className: classNames({ "f-size-35": !isDesktop }) }}
                                    />
                                </div>
                                <AccountSummaryData
                                    openingData={openingData}
                                    FORM_ID={FORM_ID}
                                    fullName={fullName}
                                    isDesktop={isDesktop}
                                />
                            </Col>
                        </Container>
                        <AccountTokenConfirmation isDesktop={isDesktop} />
                    </div>
                </MainContainer>
            </>
        ) : null;
    }
}

const mapStateToProps = (state) => ({
    openingData: accountsSelectors.getAccountOpeningData(state),
    userId: sessionSelectors.getUser(state).userId,
    fullName: sessionSelectors.getUser(state).userFullName,
});

export default compose(connect(mapStateToProps))(AccountsOpeningSummary);
