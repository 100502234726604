import { call, put, takeLatest, select } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import * as enrollment from "middleware/enrollment";

import { types as enrollmentTypes } from "reducers/enrollment";
import { types as loginTypes } from "reducers/login";
import { selectors as sessionSelectors, types as sessionTypes } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";

import { adjustIdFieldErrors } from "util/form";
import { get } from "util/i18n";
import { REGION_USA } from "constants.js";

const sagas = [
    takeLatest(enrollmentTypes.GO_TO_STEP_0, goToStep0),
    takeLatest(enrollmentTypes.REQUEST_INVITATION_CODE_PRE_REQUEST, requestInvitationCodePre),
    takeLatest(enrollmentTypes.REQUEST_INVITATION_CODE_REQUEST, requestInvitationCode),
    takeLatest(enrollmentTypes.REQUEST_PERSONAL_DATA_REQUEST, requestPersonalData),
    takeLatest(enrollmentTypes.REQUEST_SECURITY_SEALS_REQUEST, requestSecuritySeals),
    takeLatest(enrollmentTypes.REQUEST_VERIFICATION_CODE_PRE_REQUEST, requestVerificationCodePre),
    takeLatest(enrollmentTypes.RESEND_VERIFICATION_CODE_REQUEST, resendVerificationCode),
    takeLatest(enrollmentTypes.SET_USER_CREDENTIALS_REQUEST, setUserCredentials),
    takeLatest(enrollmentTypes.ACCEPT_ESIGN_REQUEST, esignAccept),
    takeLatest(enrollmentTypes.VERIFY_INVITATION_CODE_REQUEST, verifyInvitationCode),
    takeLatest(enrollmentTypes.VERIFY_INVITATION_CODE_PRE_REQUEST, verifyInvitationCodePre),
    takeLatest(enrollmentTypes.VERIFY_VERIFICATION_CODE_REQUEST, verifyVerificationCode),
    takeLatest(enrollmentTypes.SEND_DOCUMENTS_BY_MAIL, sendDocumentsByMail),
    takeLatest(enrollmentTypes.SEND_IRS_REQUEST, sendIRS),
    takeLatest(enrollmentTypes.SEND_PEP_REQUEST, sendPEP),
    takeLatest(enrollmentTypes.VALIDATE_PASSWORD, validatePassword),
    takeLatest(enrollmentTypes.VALIDATE_USER, validateUser),
    takeLatest(enrollmentTypes.SET_CREDENTIALS_REQUEST, setCredentials),
];

export default sagas;

const APIErrorCodes = {
    API508W: "invalid",
    API509W: "expired",
    API510W: "alreadyUsed",
    API511W: "cancelled",
    API512W: "invalid",
    API513E: "usernameExists",
    API045I: "cardInvalid",
};

function* goToStep0() {
    yield put(replace("/enrollment"));
}

function* validateUser({ user, userConfirmation, formikBag, isCredentialWhitening }) {
    const { exchangeToken, invitation } = formikBag.props;
    const { documentType, documentNumber, id } = invitation;
    const response = yield call(
        enrollment.validateUser,
        user,
        documentType,
        documentNumber,
        id,
        exchangeToken,
        isCredentialWhitening,
    );

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            if (response.data.code === "API526W") {
                yield put(notificationActions.showNotification(response.data.message, "error", ["userpassValidity"]));
            }
        } else {
            yield put({
                type: enrollmentTypes.SET_USER,
                user,
                userConfirmation,
            });
            if (isCredentialWhitening) {
                yield put(push("/credentialWhitening/step3part2"));
            } else {
                yield put(push("/enrollment/step3part2"));
            }
        }
    }
}

function* validatePassword({ password, passwordConfirmation, formikBag }) {
    const { exchangeToken, invitation } = formikBag.props;
    const { documentType, documentNumber, id } = invitation;
    const response = yield call(enrollment.validatePassword, password, documentType, documentNumber, id, exchangeToken);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else {
            yield put({
                type: enrollmentTypes.SET_PASSWORD,
                password,
                passwordConfirmation,
            });
            yield put(push("/enrollment/step3part3"));
        }
    }
}

function* requestInvitationCode({ captcha, documentInfo, formikBag }) {
    const { country, document, type } = documentInfo;
    const { exchangeToken } = formikBag.props;
    const response = yield call(enrollment.requestInvitationCode, captcha, country, document, type, exchangeToken);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));

            yield put(notificationActions.showNotification(response.data.data.docNumber, "error", ["enrollment"]));
            yield put({
                type: enrollmentTypes.REQUEST_INVITATION_CODE_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { channelSent } = response.data.data;

            yield put({
                type: enrollmentTypes.REQUEST_INVITATION_CODE_SUCCESS,
                channelSent,
            });

            yield put(push("/enrollment/requestInvitationCode/success"));
        }
    }
}

function* requestInvitationCodePre() {
    const response = yield call(enrollment.requestInvitationCodePre);

    if (response && response.status === 200) {
        const { countryList, documentTypeList, _exchangeToken } = response.data.data;

        yield put({
            type: enrollmentTypes.REQUEST_INVITATION_CODE_PRE_SUCCESS,
            countryList,
            documentTypeList,
            exchangeToken: _exchangeToken,
        });
    } else if (response.status === 401) {
        yield put(push("/"));
        yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
    }
}

function* requestPersonalData({ invitationCode, verificationCode, exchangeToken }) {
    const response = yield call(enrollment.requestPersonalData, invitationCode, verificationCode, exchangeToken);

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.REQUEST_PERSONAL_DATA_ERROR,
            });

            yield put(
                yield put(notificationActions.showNotification(get(response.data.code), "error", ["enrollment/step2"])),
            );
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: enrollmentTypes.REQUEST_PERSONAL_DATA_SUCCESS,
            });
        }
    }
}

function* requestSecuritySeals({ exchangeToken }) {
    const response = yield call(enrollment.requestSecuritySeals, exchangeToken);
    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.REQUEST_SECURITY_SEALS_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken, _securitySeals } = response.data.data;
            // rondom pic
            const shuffled = Object.entries(_securitySeals).sort(() => 0.5 - Math.random());
            let securitySealsResult = {};
            shuffled.slice(0, 8).forEach(([id, securitySeal]) => {
                securitySealsResult = {
                    ...securitySealsResult,
                    [id]: securitySeal,
                };
            });
            yield put({
                type: enrollmentTypes.REQUEST_SECURITY_SEALS_SUCCESS,
                exchangeToken: _exchangeToken,
                securitySeals: securitySealsResult,
            });
        }
    }
}

function* requestVerificationCodePre({ invitationCode, exchangeToken, isCredentialWhitening }) {
    const response = yield call(
        enrollment.requestVerificationCodePre,
        invitationCode,
        exchangeToken,
        isCredentialWhitening,
    );

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.REQUEST_VERIFICATION_CODE_PRE_ERROR,
            });
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: enrollmentTypes.REQUEST_VERIFICATION_CODE_PRE_SUCCESS,
                ...response.data.data,
            });
        }
    }
}

function* resendVerificationCode({ invitationCode, exchangeToken, isCredentialWhitening }) {
    const response = yield call(
        enrollment.resendVerificationCode,
        invitationCode,
        exchangeToken,
        isCredentialWhitening,
    );

    if (response) {
        if (response.type === "W") {
            yield put({
                type: enrollmentTypes.RESEND_VERIFICATION_CODE_ERROR,
            });

            yield put(notificationActions.showNotification(get(response.data.code), "error", ["enrollment/step1"]));
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            yield put({
                type: enrollmentTypes.RESEND_VERIFICATION_CODE_SUCCESS,
            });

            yield put(
                notificationActions.showNotification(
                    get("enrollment.step1.verificationCode.request.success"),
                    "success",
                    ["enrollment/step1"],
                ),
            );
        }
    }
}

function* setUserCredentials({
    password,
    passwordConfirmation,
    securitySealId,
    email,
    formikBag,
    region,
    user,
    userConfirmation,
}) {
    const { exchangeToken, invitationCode, verificationCode, invitation } = formikBag.props;
    try {
        const isUSA = region === REGION_USA;
        const isCredentialWhitening = false;
        const response = yield call(
            enrollment.setUserCredentials,
            email,
            invitationCode,
            password,
            passwordConfirmation,
            securitySealId,
            verificationCode,
            exchangeToken,
            isUSA,
            user,
            userConfirmation,
            isCredentialWhitening,
        );

        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        if (response) {
            if (response.type === "W" && response.data.code !== "API046W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors({
                    password: errors.password,
                    passwordConfirmation: errors.passwordConfirmation,
                });

                yield put({
                    type: enrollmentTypes.SET_USER_CREDENTIALS_ERROR,
                });
            } else if (response.status === 401) {
                yield put(push("/"));
                yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            } else if (response.data.code === "API045I" || response.data.code === "API046W") {
                const error = `enrollment.index.invitationCode.admin.contact`;
                yield put({
                    type: enrollmentTypes.USERNAME_ALREADY_EXISTS_ERROR,
                    error,
                });
                yield put(push("/enrollment/error"));
            } else {
                const { idEnvironment } = response.data.data;
                yield put({
                    type: enrollmentTypes.SET_USER_CREDENTIALS_SUCCESS,
                    idEnvironment,
                });

                const fromOnboardingLoginData = {
                    firstName: invitation.firstName,
                    lastName: invitation.lastName,
                };
                yield put({
                    type: loginTypes.SET_FROM_ONBOARDING_DATA,
                    fromOnboardingLoginData,
                });

                if (region === REGION_USA) {
                    yield put(push("/enrollment/Step3Part4"));
                } else {
                    yield put(push("/enrollment/success"));
                }
            }
        }
    } catch (ex) {
        if (!APIErrorCodes || !APIErrorCodes[ex.data.code]) {
            throw ex;
        }
        const error = `enrollment.index.invitationCode.${APIErrorCodes[ex.data.code]}`;
        yield put({
            type: enrollmentTypes.USERNAME_ALREADY_EXISTS_ERROR,
            error,
        });
        yield put(push("/enrollment/error"));
    }
}

function* setCredentials({ password, passwordConfirmation, formikBag }) {
    const { exchangeToken, invitation } = formikBag.props;
    const { user, userConfirmation } = formikBag.props.userCredentials;
    const { documentNumber, documentType, id } = invitation;

    try {
        const response = yield call(
            enrollment.setCredentials,
            password,
            passwordConfirmation,
            user,
            userConfirmation,
            id,
            documentNumber,
            documentType,
            exchangeToken,
        );
        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        if (response) {
            if (response.type === "W") {
                const errors = adjustIdFieldErrors(response.data.data);
                formikBag.setErrors({
                    password: errors.password,
                    passwordConfirmation: errors.passwordConfirmation,
                });

                yield put({
                    type: enrollmentTypes.SET_USER_CREDENTIALS_ERROR,
                });
                // error API519W significa que el usuario es igual a los ultimos 3 usuarios registrados
                if (response.data.code === "API519W") {
                    yield put(
                        notificationActions.showNotification(response.data.message, "error", ["passwordValidity"]),
                    );
                } else {
                    yield put(
                        notificationActions.showNotification("Error de validación", "error", ["passwordValidity"]),
                    );
                }
            } else if (response.status === 401) {
                yield put(push("/"));
                yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
            } else {
                const fromOnboardingLoginData = {
                    firstName: invitation.firstName,
                    lastName: invitation.lastName,
                };
                yield put({
                    type: loginTypes.SET_FROM_ONBOARDING_DATA,
                    fromOnboardingLoginData,
                });
                yield put(push("/credentialWhitening/success"));
            }
        }
    } catch (ex) {
        if (ex.data?.code === "API025E") {
            yield put(
                notificationActions.showNotification("usuario igual a los anteriores anterior", "error", [
                    "passwordValidity",
                ]),
            );
        } else {
            if (!APIErrorCodes || !APIErrorCodes[ex.data?.code]) {
                throw ex;
            }
            const error = `enrollment.index.invitationCode.${APIErrorCodes[ex.data.code]}`;
            yield put({
                type: enrollmentTypes.USERNAME_ALREADY_EXISTS_ERROR,
                error,
            });
            yield put(push("/enrollment/error"));
        }
    }
}

function* sendIRS({ IRS, validateSSNID, setErrors }) {
    try {
        const response = yield call(enrollment.sendIRS, IRS, validateSSNID);
        if (response) {
            if (response.type === "W") {
                if (setErrors !== null) {
                    setErrors(adjustIdFieldErrors(response.data.data));
                }
                yield put(notificationActions.showNotification(response.data.message, "error", ["pendingActions"]));
                yield put({
                    type: enrollmentTypes.SEND_IRS_ERROR,
                    error: response.data.data,
                });
            } else {
                yield put({
                    type: enrollmentTypes.SEND_IRS_SUCCESS,
                });

                yield put({ type: sessionTypes.UPDATE_PENDINGACTIONS_IRS });
                const user = yield select(sessionSelectors.getUser);
                if (user.pepCompleted && user.irsCompleted) {
                    yield put(push("/desktop"));
                } else {
                    yield put(push("/pendingActions"));
                }
            }
        }
    } catch (error) {
        if (error && error.data && error.data.code === "API006E") {
            yield put(notificationActions.showNotification(error.data.message, "error", ["pendingActions"]));
        } else {
            throw error;
        }
    }
}

function* sendPEP() {
    try {
        const response = yield call(enrollment.sendPEP);
        if (response) {
            if (response.type === "W") {
                yield put(notificationActions.showNotification(response.data.message, "error", ["pendingActions"]));
                yield put({
                    type: enrollmentTypes.SEND_PEP_ERROR,
                });
            } else {
                yield put({
                    type: enrollmentTypes.SEND_PEP_SUCCESS,
                });

                yield put({ type: sessionTypes.UPDATE_PENDINGACTIONS_PEP });
                const user = yield select(sessionSelectors.getUser);
                if (user.pepCompleted && user.irsCompleted) {
                    yield put(push("/desktop"));
                } else {
                    yield put(push("/pendingActions"));
                }
            }
        }
    } catch (error) {
        if (error && error.data && error.data.code === "API006E") {
            yield put(notificationActions.showNotification(error.data.message, "error", ["pendingActions"]));
        } else {
            throw error;
        }
    }
}

function* esignAccept({ idEnvironment, userEmail, exchangeToken, firstName, lastName, acceptESign }) {
    if (!acceptESign) {
        yield put({
            type: enrollmentTypes.ACCEPT_ESIGN_SUCCESS,
        });
        yield put({
            type: loginTypes.SET_FROM_ONBOARDING_DATA,
            fromOnboardingLoginData: {
                firstName,
                lastName,
                acceptESign,
            },
        });

        yield put(push("/enrollment/success"));
        return;
    }

    try {
        const response = yield call(enrollment.esignAccept, userEmail, idEnvironment, exchangeToken);
        if (response) {
            if (response.type === "W") {
                yield put(notificationActions.showNotification(response.data.message, "error", ["enrollment"]));
                yield put({
                    type: enrollmentTypes.ACCEPT_ESIGN_ERROR,
                });
            } else {
                yield put({
                    type: enrollmentTypes.ACCEPT_ESIGN_SUCCESS,
                });
                yield put({
                    type: loginTypes.SET_FROM_ONBOARDING_DATA,
                    fromOnboardingLoginData: {
                        firstName,
                        lastName,
                        acceptESign,
                    },
                });

                yield put(push("/enrollment/success"));
            }
        }
    } catch (error) {
        if (error && error.data && error.data.code === "API006E") {
            yield put({
                type: enrollmentTypes.ACCEPT_ESIGN_ERROR,
                error: error.data.message,
            });
            yield put(push("/enrollment/error"));
        } else {
            throw error;
        }
    }
}

function* sendDocumentsByMail({ userEmail }) {
    yield put(
        notificationActions.showNotification(get("generalConditionDocument.email.front.text.sent"), "success", [
            "enrollmentTermsAndConditions",
        ]),
    );
    yield call(enrollment.sendDocumentsByEmail, userEmail);
}

function* verifyInvitationCode({ invitationCode, formikBag, isCredentialWhitening }) {
    const response = yield call(enrollment.verifyInvitationCode, invitationCode, isCredentialWhitening);

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            const error = response.data.code.includes("API")
                ? `enrollment.index.invitationCode.${APIErrorCodes[response.data.code]}`
                : adjustIdFieldErrors(response.data.data).code;

            yield put({
                type: enrollmentTypes.VERIFY_INVITATION_CODE_ERROR,
            });

            yield put(notificationActions.showNotification(get(error), "error", ["enrollment"]));
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken, maskedMobileNumber } = response.data.data;

            yield put({
                type: enrollmentTypes.VERIFY_INVITATION_CODE_SUCCESS,
                exchangeToken: _exchangeToken,
                invitationCode,
                maskedMobileNumber,
            });
            if (isCredentialWhitening) {
                yield put(push(`/credentialWhitening/step1`));
            } else {
                yield put(push(`/enrollment/step1`));
            }
        }
    }
}

function* verifyInvitationCodePre({ invitationCode, formikBag }) {
    const response = yield call(enrollment.verifyInvitationCodePre, invitationCode);
    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response.status !== 200) {
        yield put(push("/"));
        yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
    } else {
        const { _code, email, termsCond } = response.data.data;

        yield put({
            type: enrollmentTypes.VERIFY_INVITATION_CODE_PRE_SUCCESS,
            invitationCode: _code,
            termsCond,
            email,
        });
    }
}

function* verifyVerificationCode({ verificationCode, formikBag, isCredentialWhitening }) {
    const { exchangeToken, invitationCode, personalDataEnabled } = formikBag.props;
    const response = yield call(
        enrollment.verifyVerificationCode,
        personalDataEnabled,
        invitationCode,
        verificationCode,
        exchangeToken,
        isCredentialWhitening,
    );

    if (formikBag) {
        formikBag.setSubmitting(false);
    }

    if (response) {
        if (response.type === "W") {
            const { verificationCode: verificationCodeParam, NO_FIELD } = adjustIdFieldErrors(response.data.data);
            const { message } = response.data;
            if (NO_FIELD) {
                yield put({
                    type: enrollmentTypes.VERIFY_VERIFICATION_CODE_ERROR,
                    error: `enrollment.step1.verificationCode.maxAttemptsReached`,
                });
                yield put(notificationActions.showNotification(NO_FIELD, "error", ["enrollment/step1"]));
            } else if (message) {
                yield put({
                    type: enrollmentTypes.VERIFY_VERIFICATION_CODE_ERROR,
                });
                yield put(notificationActions.showNotification(message, "error", ["enrollment/step1"]));
            } else {
                yield put({
                    type: enrollmentTypes.VERIFY_VERIFICATION_CODE_ERROR,
                });
                yield put(notificationActions.showNotification(verificationCodeParam, "error", ["enrollment/step1"]));
            }
        } else if (response.status === 401) {
            yield put(push("/"));
            yield put(notificationActions.showNotification(response.data.message, "error", ["login"]));
        } else {
            const { _exchangeToken } = response.data.data;

            yield put({
                type: enrollmentTypes.VERIFY_VERIFICATION_CODE_SUCCESS,
                exchangeToken: _exchangeToken,
                verificationCode,
            });
            if (isCredentialWhitening) {
                yield put(push("/credentialWhitening/step3part1"));
            } else {
                yield put(push("/enrollment/step3part1"));
            }
        }
    }
}
