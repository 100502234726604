import * as API from "middleware/api";

export const loadListRequest = ({ pageNumber = 1, orderBy = "name ASC" } = {}) =>
    API.executeWithAccessToken("/administration.groups.list", {
        pageNumber,
        orderBy,
    });

export const exportListRequest = ({ format = "xls", orderBy = "name ASC" } = {}) =>
    API.executeWithAccessToken("/administration.groups.export", {
        format,
        orderBy,
    });

export const changeGroupsStatusPreview = (params) =>
    API.executeWithAccessToken("/administration.groups.blockunblock.preview", params);

export const changeGroupsStatusConfirmation = (params, credentials) =>
    API.executeWithAccessToken("/administration.groups.blockunblock.send", {
        ...params,
        ...credentials,
    });

export const deleteGroupsPreview = (params) =>
    API.executeWithAccessToken("/administration.groups.delete.preview", params);

export const deleteGroupsConfirmation = (params, credentials) =>
    API.executeWithAccessToken("/administration.groups.delete.send", {
        ...params,
        ...credentials,
    });
