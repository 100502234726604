import React, { Component } from "react";
import Measure from "react-measure";
import Col from "react-bootstrap/lib/Col";
import { func, arrayOf, objectOf, shape, bool, number, string } from "prop-types";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import Table from "rc-table";

import { actions, selectors } from "reducers/administration/users";
import { selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";

import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import Check from "pages/_components/fields/Checkbox";
import Image from "pages/_components/Image";
import FeatureFlag from "pages/_components/FeatureFlag";
import Circle from "pages/_components/Circle";
import InfoTag from "pages/_components/InfoTag";

const UserName = ({ name, dispatcher, massiveEnabled }) => (
    <div className="data-wrapper data-wrapper-flex">
        <span className="data-desc">{name}</span>&nbsp;&nbsp;
        {!massiveEnabled && <Image src="images/key.svg" className="svg-icon svg-caret" />}
        <FeatureFlag id="feature.signatureSchema.dispatchControl">
            {dispatcher && <Image src="images/no-administrator.svg" className="svg-icon svg-caret" />}
        </FeatureFlag>
    </div>
);

UserName.propTypes = {
    name: string.isRequired,
    dispatcher: bool.isRequired,
    massiveEnabled: bool.isRequired,
};

class AdministrationUsers extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        activeEnvironment: shape({ administrationScheme: string }).isRequired,
        users: arrayOf(shape({ fullName: string, email: string, lastLoginAsMediumString: string })).isRequired,
        usersInfo: objectOf(
            shape({
                massiveEnabled: bool.isRequired,
                status: string.isRequired,
                signatureLevel: string,
            }),
        ).isRequired,
        currentPage: number,
        totalPages: number,
        fetching: bool,
        fetchingExport: bool,
        fetchingMoreUsers: bool,
        hasMoreData: bool,
    };

    static defaultProps = {
        currentPage: 0,
        totalPages: 0,
        fetching: false,
        fetchingExport: false,
        fetchingMoreUsers: false,
        hasMoreData: false,
    };

    state = {
        tableHeight: 0,
        selectedUsers: new Map(),
        selectAllChecked: false,
        blockedQuantity: 0,
        unblockedQuantity: 0,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(actions.loadListRequest());
    }

    onRowClick = (record) => {
        const {
            dispatch,
            activeEnvironment: { administrationScheme },
        } = this.props;
        const path = {
            simple: "simple/permissions",
            medium: "medium/details",
            advanced: "advanced/details",
        };

        dispatch(routerActions.push(`/administration/${path[administrationScheme]}/${record.idUser}`));
    };

    signatureToLabel = (signatureLevel, administrationScheme) =>
        ((administrationScheme === "medium" || administrationScheme === "simple") && (
            <Circle
                value={signatureLevel ? i18n.get("global.yes").toUpperCase() : i18n.get("global.no").toUpperCase()}
                smallSize
            />
        )) || <Circle value={signatureLevel || i18n.get("global.no").toUpperCase()} smallSize />;

    generateTableColumns = (massiveDissabledQuantity) => {
        const { selectAllChecked, selectedUsers } = this.state;
        const { activeEnvironment, users } = this.props;
        const columnArray = [
            {
                key: "check",
                dataIndex: "check",
                title: (
                    <Check
                        hideLabel
                        onChange={this.handleSelectAll}
                        name="selectAllUsers"
                        checked={
                            selectAllChecked ||
                            (users.length === selectedUsers.size + massiveDissabledQuantity && selectedUsers.size !== 0)
                        }
                        disabledCheck={users.length === massiveDissabledQuantity}
                    />
                ),
                width: 20,
                onCell: () => ({
                    onClick(e) {
                        e.stopPropagation();
                    },
                }),
            },
            {
                className: "text-center",
                key: "userName",
                dataIndex: "userName",
                title: i18n.get("administration.users.list.username"),
                width: 275,
            },
            {
                className: "text-center",
                key: "document",
                dataIndex: "document",
                title: i18n.get("administration.users.list.document"),
                width: 200,
            },
            {
                className: "text-center",
                key: "email",
                dataIndex: "email",
                title: i18n.get("administration.users.list.email"),
                width: 315,
            },
            {
                className: "text-center",
                key: "signature",
                dataIndex: "signature",
                title: i18n.get("administration.users.list.signature"),
                width: 75,
            },
            {
                className: "text-center",
                key: "lastLogin",
                dataIndex: "lastLogin",
                title: i18n.get("administration.users.list.lastLogin"),
                width: 200,
            },
            {
                className: "text-right",
                key: "status",
                dataIndex: "status",
                title: i18n.get("administration.users.list.status"),
                width: 50,
            },
        ];
        if (activeEnvironment.administrationScheme !== "simple") {
            return columnArray;
        }
        const auxArray = columnArray.slice(1, columnArray.length);
        auxArray.splice(2, 1);
        return auxArray;
    };

    populateUsersData = (massiveDissabledQuantity) => {
        const { activeEnvironment, users, usersInfo } = this.props;
        const { selectedUsers, selectAllChecked } = this.state;
        return users.map((user) => ({
            key: user.idUser,
            check: activeEnvironment.administrationScheme !== "simple" && (
                <Check
                    onChange={() => this.handleCheckClick(user.idUser, user.fullName, massiveDissabledQuantity)}
                    hideLabel
                    id={user.idUser}
                    name={user.idUser}
                    checked={
                        usersInfo[user.idUser].massiveEnabled && (selectAllChecked || selectedUsers.has(user.idUser))
                    }
                    disabledCheck={!usersInfo[user.idUser].massiveEnabled}
                />
            ),
            idUser: user.idUser,
            userName: (
                <UserName
                    name={user.fullName}
                    massiveEnabled={
                        activeEnvironment.administrationScheme !== "simple"
                            ? usersInfo[user.idUser].massiveEnabled
                            : false
                    }
                    dispatcher={usersInfo[user.idUser].dispatcher}
                />
            ),
            document: `${user.documentType} ${user.documentNumber}`,
            email: user.email,
            signature: this.signatureToLabel(
                usersInfo[user.idUser].signatureLevel,
                activeEnvironment.administrationScheme,
            ),
            lastLogin: user.lastLoginAsMediumString,
            status: (
                <div className="data-wrapper data-wrapper-flex">
                    <InfoTag
                        tagClass="ml-auto mr-0 px-2"
                        type="default"
                        message={i18n.get(`user.status.${usersInfo[user.idUser].status}`)}
                        tagBackground={usersInfo[user.idUser].status === "blocked" ? "#fc5f5f" : "#0be0a2"}
                    />
                </div>
            ),
        }));
    };

    renderPageHeader = () => {
        const { activeEnvironment, fetchingExport } = this.props;
        const { selectedUsers } = this.state;

        return (
            <>
                <div className="admin-detail-head admin-detail-head-section px-0 mt-5 mb-45">
                    <Head
                        title="administration.users.list.title"
                        exportList
                        toolbarItemClassName="pr-0"
                        handleNewClick={this.handleInviteUserClick}
                        handleClick={this.handleClickDownload}
                        handleInviteClick={
                            activeEnvironment.administrationScheme !== "simple" && this.handleInviteUserClick
                        }
                        isFetchingExport={fetchingExport}
                        hasInlineButtons
                        imageStyle="mr-2">
                        {this.renderActionButtons()}
                    </Head>
                    {selectedUsers.size > 0 && (
                        <div className="pl-2">
                            {selectedUsers.size}{" "}
                            {selectedUsers.size === 1
                                ? i18n.get("administration.users.list.selected.onlyOne")
                                : i18n.get("administration.users.list.selected.quantity")}
                        </div>
                    )}
                </div>
            </>
        );
    };

    handleCheckClick = (idUserChecked, nameUserChecked, massiveDissabledQuantity) => {
        const { users, usersInfo } = this.props;
        const { selectedUsers, selectAllChecked } = this.state;
        let { blockedQuantity, unblockedQuantity } = this.state;

        if (!selectedUsers.has(idUserChecked)) {
            selectedUsers.set(idUserChecked, nameUserChecked);
            if (usersInfo[idUserChecked].status === "blocked") {
                blockedQuantity += 1;
            } else {
                unblockedQuantity += 1;
            }

            if (selectedUsers.size === users.length - massiveDissabledQuantity) {
                this.setState({
                    selectAllChecked: true,
                });
            }
        } else {
            selectedUsers.delete(idUserChecked);
            if (usersInfo[idUserChecked].status === "blocked") {
                blockedQuantity -= 1;
            } else {
                unblockedQuantity -= 1;
            }
            if (selectAllChecked) {
                this.setState({ selectAllChecked: false });
            }
        }
        this.setState({
            selectedUsers,
            blockedQuantity,
            unblockedQuantity,
        });
    };

    handleSelectAll = () => {
        const { selectAllChecked } = this.state;
        const { users, usersInfo } = this.props;
        const newSelectedUsers = new Map();
        let blockedQuantity = 0;
        let unblockedQuantity = 0;
        if (!selectAllChecked) {
            users.forEach((user) => {
                if (usersInfo[user.idUser].massiveEnabled) {
                    newSelectedUsers.set(user.idUser, user.fullName);
                    if (usersInfo[user.idUser].status === "blocked") {
                        blockedQuantity += 1;
                    } else {
                        unblockedQuantity += 1;
                    }
                }
            });
        }

        this.setState((prevState) => ({
            selectAllChecked: !prevState.selectAllChecked,
            selectedUsers: newSelectedUsers,
            blockedQuantity,
            unblockedQuantity,
        }));
    };

    handleMoreDataClick = () => {
        const { dispatch, currentPage } = this.props;

        this.setState({ selectAllChecked: false });
        dispatch(actions.loadMoreRequest({ pageNumber: currentPage + 1 }));
    };

    handleClickDownload = (format) => {
        const { dispatch } = this.props;

        dispatch(actions.exportListRequest(format));
    };

    handleActionClick = (action) => {
        const { dispatch } = this.props;
        const { selectedUsers } = this.state;

        dispatch(actions.changeUserStatusPreview([...selectedUsers.keys()], [...selectedUsers.values()], action));
    };

    handleInviteUserClick = () => {
        const { dispatch } = this.props;

        dispatch(routerActions.push("/administration/users/invite"));
    };

    renderActionButtons = () => {
        const { selectedUsers, blockedQuantity, unblockedQuantity } = this.state;

        return (
            <div className="flex-container ml-3">
                {(selectedUsers.size === 0 || unblockedQuantity > 0) && (
                    <div>
                        <Button
                            bsStyle="link"
                            key="block"
                            label="administration.block"
                            type="button"
                            className="py-1"
                            disabled={unblockedQuantity === 0}
                            onClick={() => this.handleActionClick("block")}
                        />
                    </div>
                )}
                {blockedQuantity > 0 && (
                    <div>
                        <Button
                            bsStyle="link"
                            key="unblock"
                            label="administration.unblock"
                            className="py-1"
                            type="button"
                            onClick={() => this.handleActionClick("unblock")}
                        />
                    </div>
                )}
                <div>
                    <Button
                        bsStyle="link"
                        key="delete"
                        label="administration.delete"
                        className="py-1"
                        type="button"
                        disabled={selectedUsers.size === 0}
                        onClick={() => this.handleActionClick("delete")}
                    />
                </div>
            </div>
        );
    };

    renderTableFooter = () => {
        const { totalPages, hasMoreData, fetchingMoreUsers } = this.props;

        return (
            <div>
                {totalPages > 1 &&
                    (hasMoreData ? (
                        <div className="text-center no-more-data my-2" key="noMoreUsers">
                            <Button
                                className="btn btn-link w-auto"
                                onClick={this.handleMoreDataClick}
                                loading={fetchingMoreUsers}
                                image="images/show.svg"
                                label="administration.users.list.more"
                            />
                        </div>
                    ) : (
                        <div className="text-center no-more-data my-2" key="noMoreUsers">
                            <p className="text-lead">
                                <I18n id="administration.users.list.more.noMoreData" />
                            </p>
                        </div>
                    ))}
            </div>
        );
    };

    render() {
        const { usersInfo, totalPages, fetching, users } = this.props;
        const { tableHeight } = this.state;
        const massiveDissabledQuantity = Object.values(usersInfo).filter((userInfo) => !userInfo.massiveEnabled).length;
        const columns = this.generateTableColumns(massiveDissabledQuantity);

        return (
            <>
                <Notification scopeToShow="administrationUsers" />
                <PageLoading loading={fetching && !users.length} className="screen-loader">
                    {this.renderPageHeader()}
                    <MainContainer viewContentClassName="pt-0" className="main-container px-0">
                        <div className="above-the-fold">
                            <Container
                                className="container--layout mt-0 px-0 flex-grow align-items-center"
                                rowClassName="mx-0"
                                gridClassName="form-content px-0">
                                <Col className="col col-12 px-0">
                                    {tableHeight ? (
                                        <Table
                                            columns={columns}
                                            data={this.populateUsersData(massiveDissabledQuantity)}
                                            rowKey={(record) => record.key}
                                            emptyText={i18n.get("administration.users.list.noRecords")}
                                            onRow={(record) => ({
                                                onClick: () => this.onRowClick(record),
                                            })}
                                            footer={totalPages > 1 && this.renderTableFooter}
                                            className="table-adminUsers"
                                        />
                                    ) : (
                                        <Measure
                                            bounds
                                            onResize={({ bounds }) => this.setState({ tableHeight: bounds.height })}>
                                            {({ measureRef }) => (
                                                <div ref={measureRef} style={{ height: "100%", width: "100%" }} />
                                            )}
                                        </Measure>
                                    )}
                                </Col>
                            </Container>
                        </div>
                    </MainContainer>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    users: selectors.getUsers(state),
    usersInfo: selectors.getUsersInfo(state),
    currentPage: selectors.getCurrentPage(state),
    totalPages: selectors.getTotalPages(state),
    fetchingExport: selectors.isFetchingExport(state),
    fetching: selectors.isFetching(state),
    fetchingMoreUsers: selectors.isFetchingMoreUsers(state),
    hasMoreData: selectors.getHasMoreData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(AdministrationUsers);
