import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { func, shape, boolean } from "prop-types";
import { signatureTicketSelectors, detailsSelectors } from "reducers/administration";
import { signatureTicketActions } from "reducers/administration/advanced";

import SignatureTicket from "pages/administration/_components/tickets/SignatureTicket";
import AdministrationTicket from "pages/administration/_components/tickets/AdministrationTicket";
import PageLoading from "pages/_components/PageLoading";

class SignatureTicketContent extends Component {
    static propTypes = {
        actions: func.isRequired,
        match: shape({}).isRequired,
        fetching: boolean,
        fetchingSignature: boolean,
    };

    static defaultProps = {
        fetching: true,
        fetchingSignature: true,
    };

    componentDidMount() {
        const { actions, match } = this.props;
        actions.loadSignatureTicketRequest(match.params.idTransaction);
    }

    render() {
        const { fetching, fetchingSignature } = this.props;

        return (
            <PageLoading loading={fetching || fetchingSignature}>
                <AdministrationTicket {...this.props}>
                    <SignatureTicket {...this.props} />
                </AdministrationTicket>
            </PageLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    user: detailsSelectors.getUser(state),
    signatureLevel: detailsSelectors.getSignatureLevel(state),
    hasSignatureEnabled: signatureTicketSelectors.getSignatureLevel(state) !== undefined,
    fetching: detailsSelectors.isFetching(state),
    fetchingSignature: signatureTicketSelectors.isFetching(state),
    schemeName: "advanced",
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(signatureTicketActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignatureTicketContent);
