import React, { Component } from "react";
import { node } from "prop-types";

import DetailBoxData from "pages/_components/detailBox/DetailBoxData";

class DetailBox extends Component {
    static propTypes = {
        children: node.isRequired,
    };

    static Data = DetailBoxData;

    render() {
        const { children } = this.props;

        return <div className="detailBox">{children}</div>;
    }
}

export default DetailBox;
