import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Col, Grid, Row } from "react-bootstrap";
import { bool, string, func, arrayOf, shape } from "prop-types";

import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import { selectors as sessionSelectors } from "reducers/session";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import DownloadableDocument from "pages/forms/_components/_esign/DownloadableDocument";
import * as utilDownload from "util/download";

class EsignDocumentSigned extends Component {
    static propTypes = {
        fetching: bool,
        dispatch: func.isRequired,
        documents: arrayOf(shape({ fileNameKey: string, size: string })).isRequired,
        iRSDocument: arrayOf(shape({ fileNameKey: string, size: string })).isRequired,
        signedTAndCDate: string,
        irsDate: string,
    };

    static defaultProps = {
        fetching: false,
        signedTAndCDate: "",
        irsDate: "",
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(settingsActions.loadDocumentsRequest());
    }

    getContent() {
        const { documents, signedTAndCDate, iRSDocument, irsDate } = this.props;

        return (
            <form className="above-the-fold">
                <section className="container--layout">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.options.documents.termsAndConditions.viewOrDownload.subtitle" />
                                </p>
                            </Col>
                        </Row>
                    </Grid>
                </section>
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <ul className="navigational-list">
                                    {documents.map((doc) => (
                                        <li className="navigational-list-item navigational-list-downLoad">
                                            <DownloadableDocument
                                                fileName={doc.fileNameKey}
                                                handleClickDownload={this.handleClickDownload}
                                                size={doc.size}
                                                sizeUnit={doc.sizeUnit}
                                                dateToDisplay={signedTAndCDate}
                                            />
                                        </li>
                                    ))}
                                    {irsDate &&
                                        iRSDocument.map((doc) => (
                                            <li className="navigational-list-item navigational-list-downLoad">
                                                <DownloadableDocument
                                                    fileName={doc.fileNameKey}
                                                    handleClickDownload={this.handleClickDownloadIRSPdf}
                                                    size={doc.size}
                                                    sizeUnit={doc.sizeUnit}
                                                    dateToDisplay={irsDate}
                                                />
                                            </li>
                                        ))}
                                </ul>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    }

    handleClickDownload = (fileNameKey) => {
        const { dispatch } = this.props;

        dispatch(settingsActions.downloadDocumentRequest(fileNameKey));
    };

    handleClickDownloadIRSPdf = (fileNameKey) => {
        utilDownload.downloadLink(fileNameKey, "/pdf/IRS%20Statement.pdf");
    };

    render() {
        const { fetching } = this.props;
        return (
            <Fragment>
                <PageLoading loading={fetching}>
                    <Head title="settings.options.documents.termsAndConditions" closeLinkTo="/settings" />
                    <Notification scopeToShow="settings" />
                    <MainContainer>{this.getContent()}</MainContainer>
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    iRSDocument: [{ fileNameKey: "irs.statement.pdf", size: "345" }],
    documents: settingsSelectors.getDocuments(state),
    fetching: settingsSelectors.isFetching(state),
    signedTAndCDate: sessionSelectors.getUser(state) ? sessionSelectors.getUser(state).signedTAndCDate : null,
    irsDate: sessionSelectors.getUser(state) ? sessionSelectors.getUser(state).irsDate : null,
});

export default connect(mapStateToProps)(EsignDocumentSigned);
