import React, { Component } from "react";
import { connect } from "react-redux";
import { func, bool, shape, arrayOf, string } from "prop-types";

import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import * as i18n from "util/i18n";
import PageLoading from "pages/_components/PageLoading";
import { actions as servicePaymentsActions, selectors as servicePaymentsSelectors } from "reducers/servicePayments";
import { actions as paymentsAFIPActions, selectors as paymentsAFIPSelectors } from "reducers/paymentsAFIP";
import classNames from "classnames";
import AdhesionesTable from "./_components/AdhesionesTable";

const FORM_ID = "services.adhesiones";

class AdhesionesList extends Component {
    state = {};

    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        adhesiones: arrayOf(
            shape({
                adhesionCode: string,
                enteDescription: string,
                enteCode: string,
                userLp: string,
                category: shape({
                    code: string,
                    description: string,
                    shortDescrition: string,
                }),
                manejaBaseDeuda: bool,
            }),
        ).isRequired,
        isReturnFromDetail: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(servicePaymentsActions.listAdhesiones());
    }

    handleBack = () => {
        const { dispatch, isReturnFromDetail } = this.props;
        dispatch(paymentsAFIPActions.setIsBackFromOtherPage(isReturnFromDetail));
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", margin: "-0.2rem" }}>{i18n.get("services.adhesiones.title")}</h1>
        </div>
    );

    render() {
        const { fetching, isDesktop, adhesiones } = this.props;

        return (
            <>
                <Notification scopeToShow="adhesiones" />
                {isDesktop ? (
                    <>
                        <div className="admin-detail-head px-0">
                            <Head onBack={this.handleBack} centerContentClassName="pl-0" />
                            <Head
                                title={`${FORM_ID}.title`}
                                headerClassName={classNames({
                                    "py-2": isDesktop,
                                })}
                                centerContentClassName="pl-0"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="admin-detail-head px-0">
                            <Head
                                title={`${FORM_ID}.title`}
                                onBack={this.handleBack}
                                headerClassName="blue-main-header-mobile adhesiones-list-title-mobile"
                                centerContentClassName="mx-5"
                            />
                        </div>
                    </>
                )}
                <PageLoading loading={fetching}>{adhesiones && <AdhesionesTable isDesktop={isDesktop} />}</PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: servicePaymentsSelectors.getFetching(state),
    adhesiones: servicePaymentsSelectors.getAdhesiones(state),
    isReturnFromDetail: paymentsAFIPSelectors.getIsReturnFromDetail(state),
});

export default connect(mapStateToProps)(AdhesionesList);
