import React, { Component } from "react";
import classNames from "classnames";
import { string, bool, func, shape, number } from "prop-types";

import SelectorInput from "pages/_components/fields/SelectorInput";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FieldError from "pages/_components/fields/FieldError";
import withFocus from "pages/_components/withFocus";

class FormikSelectorInput extends Component {
    static propTypes = {
        form: shape({}).isRequired,
        field: shape({}).isRequired,
        inputProps: shape({}).isRequired,
        selectProps: shape({}).isRequired,
        toggleIsFocused: func.isRequired,
        isFocused: bool.isRequired,
        label: string.isRequired,
        onChange: func.isRequired,
        selectorSize: string,
        placeholder: string.isRequired,
        labelRegular: bool,
        controlLabelClassName: string,
        className: string,
        inputMaxLength: number.isRequired,
    };

    static defaultProps = {
        selectorSize: "medium",
        labelRegular: false,
        controlLabelClassName: "",
        className: "",
    };

    handleBlur = () => {
        const { field, form } = this.props;

        form.setFieldTouched(field.name);
    };

    cleanErrors = (form, field) => {
        const { [field?.name]: fieldName, ...errorObj } = form?.errors;
        form.setErrors(errorObj);
    };

    render() {
        const {
            form,
            field,
            isFocused,
            label,
            inputProps,
            toggleIsFocused,
            selectProps,
            onChange,
            selectorSize,
            placeholder,
            labelRegular,
            controlLabelClassName,
            className,
            inputMaxLength,
        } = this.props;
        const { touched, errors } = form;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <div
                className={classNames(
                    className,
                    "form-group",
                    "form-group--composite",
                    `selector-size--${selectorSize}`,
                    {
                        "has-error": hasError,
                        "has-focus": isFocused,
                    },
                )}>
                <FieldLabel
                    controlLabelClassName={controlLabelClassName}
                    idField={field.name}
                    labelText={label}
                    labelRegular={labelRegular}
                />
                <SelectorInput
                    selectProps={selectProps}
                    inputProps={inputProps}
                    idField={field.name}
                    onChange={onChange}
                    placeholder={placeholder}
                    isFocused={isFocused}
                    toggleIsFocused={toggleIsFocused}
                    onBlur={this.handleBlur}
                    onClickInput={() => this.cleanErrors(form, field)}
                    autofocus={inputProps.autofocus}
                    inputMaxLength={inputMaxLength}
                />
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }
}

export default withFocus(FormikSelectorInput);
