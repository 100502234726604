import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, bool, shape, arrayOf, string } from "prop-types";
import Notification from "pages/_components/Notification";
import { selectors, actions } from "reducers/checks";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import { Field, Form, withFormik } from "formik";
import I18n from "pages/_components/I18n";
import { compose } from "redux";
import Yup from "yup";
import Credential from "pages/_components/fields/credentials/Credential";
import { selectors as sessionSelectors } from "reducers/session";
import EmitSummaryData from "pages/checks/Echeqs/EcheqTransactions/EmitSummaryData";
import isTokenActive from "util/token";
import { getTotalAmountEcheqs } from "util/checks";
import SelectedItemsAmount from "pages/charges/_components/SelectedItemsAmount";

const FORM_ID = "echeqs";

class ConfirmEmitEcheq extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        isDesktop: bool.isRequired,
        emissionData: string.isRequired,
        emitData: shape({
            beneficiary: shape({}),
        }).isRequired,
        echeqsToEmit: arrayOf(
            shape({
                amount: string.isRequired,
                date: string.isRequired,
                reference: string.isRequired,
                referencevalue: string.isRequired,
                type: string.isRequired,
            }),
        ).isRequired,
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    componentWillMount = () => {
        const { emissionData, dispatch } = this.props;
        if (!emissionData) {
            dispatch(goBack());
        }
    };

    closeEcheqAction = () => {
        const { dispatch } = this.props;

        dispatch(actions.cleanEcheqAction());
        this.handleBack();
    };

    renderHeader = () => {
        const { isDesktop, echeqsToEmit } = this.props;
        const title = "echeq.emit";
        if (isDesktop) {
            return (
                <>
                    <Head
                        onBack={this.handleBack}
                        onClose={this.closeEcheqAction}
                        navStyle="default nav-fit-height px-0"
                    />
                    <Container className="mb-2">
                        <Col md={6} className="px-0">
                            <div className="echeq-align-title">
                                <Head
                                    title="echeq.emit"
                                    titleClassName="mx-0"
                                    navStyle="default nav-fit-height px-0"
                                    headerClassName="h-auto"
                                />
                            </div>
                            <I18n id="echeqs.action.data.subtitle" component="h4" />
                        </Col>
                        <Col md={6}>
                            <SelectedItemsAmount
                                amountText="echeqs.emission.amount.hint"
                                amount={getTotalAmountEcheqs(echeqsToEmit)}
                                className="justify-content-end pb-3"
                                itemsAmount={echeqsToEmit.length}
                                isDesktop={isDesktop}
                                justify="end"
                            />
                        </Col>
                    </Container>
                </>
            );
        }

        return (
            <>
                <Head
                    headerClassName="blue-main-header-mobile"
                    titleClassName="w-100"
                    onBack={this.handleBack}
                    title={title}
                    emptyRightOption
                />
                <div className="admin-content-center px-6 pb-3 pt-2">
                    <I18n id="echeqs.action.data.subtitle" component="h4" />
                </div>
                <SelectedItemsAmount
                    amountText="echeqs.emission.amount.hint"
                    amount={getTotalAmountEcheqs(echeqsToEmit)}
                    className="justify-content-end px-2"
                    itemsAmount={echeqsToEmit.length}
                    justify="end"
                />
            </>
        );
    };

    render() {
        const { emitData, isSubmitting, isDesktop, echeqsToEmit } = this.props;
        return (
            <>
                <Notification scopeToShow="echeqs" />
                {this.renderHeader()}
                <MainContainer viewContentClassName="overflow-visible">
                    <div className={`above-the-fold ${!isDesktop && "container-white"}`}>
                        <EmitSummaryData emitData={emitData} echeqsToEmit={echeqsToEmit} isDesktop={isDesktop} />
                        <Container>
                            <Form className={`above-the-fold ${!isDesktop && "container-white"}`}>
                                {!isTokenActive(!isDesktop) && (
                                    <Container className="flex-grow align-items-center container-white">
                                        <Col className="col-12 col-xl-4 py-2" lg={4} md={9} sm={12}>
                                            <div className="admin-content-center">
                                                <I18n
                                                    id="transfer.summary.confirm.label"
                                                    componentProps={{ className: "light-font" }}
                                                />
                                            </div>
                                            <div className="admin-content-center">
                                                <Field
                                                    name="otp"
                                                    type="otp"
                                                    idForm={FORM_ID}
                                                    component={Credential}
                                                    loading={isSubmitting}
                                                    placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                                />
                                            </div>
                                            <Button
                                                type="submit"
                                                bsStyle="primary"
                                                label="global.confirm"
                                                loading={isSubmitting}
                                                image="images/arrowRight.svg"
                                                imageStyle="mr-2"
                                                className="text-capitalize"
                                            />
                                        </Col>
                                    </Container>
                                )}
                                {isTokenActive(!isDesktop) && (
                                    <Container className="flex-grow align-items-center container-white">
                                        <Col className="col-12 col-xl-4 py-2" lg={4} md={9} sm={12}>
                                            <div className="admin-content-center pb-3">
                                                <I18n
                                                    id="confirmation.withoutotp.text"
                                                    component="p"
                                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                                />
                                            </div>
                                            <Field
                                                name="otp"
                                                type="otp"
                                                idForm={FORM_ID}
                                                component={Credential}
                                                loading={isSubmitting}
                                                placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                            />
                                            <Button
                                                type="submit"
                                                bsStyle="primary"
                                                label="global.confirm"
                                                loading={isSubmitting}
                                                image="images/arrowRight.svg"
                                                imageStyle="mr-2"
                                                className="text-capitalize"
                                            />
                                        </Col>
                                    </Container>
                                )}
                            </Form>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    option: selectors.getEcheqOption(state),
    emitData: selectors.getEcheqsEmitData(state),
    userId: sessionSelectors.getUser(state).userId,
    accounts: selectors.getAccounts(state),
    echeqsToEmit: selectors.getEcheqsToEmit(state),
    environmentCuit: selectors.getEnvironmentCuit(state),
    emissionData: selectors.getEmissionData(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: (form, formikBag) => {
            const { setSubmitting, setErrors } = formikBag;
            const { dispatch, emitData, userId, accounts, echeqsToEmit, environmentCuit } = formikBag.props;

            setSubmitting(true);
            const accountSelected = accounts.find((account) => account.idProduct === emitData.emision);

            dispatch(actions.setEcheqsToDoActions({ action: "EMIT-ECHEQ", echeqList: echeqsToEmit }));
            dispatch(actions.setGoBackEcheqs(true));
            dispatch(
                actions.emitEcheq(
                    form.otp,
                    setSubmitting,
                    setErrors,
                    {
                        ...emitData,
                        emision: accountSelected.cbu,
                        echeqsToEmit,
                        environmentCuit,
                        account: accountSelected.number,
                    },
                    userId,
                ),
            );
        },
    }),
)(ConfirmEmitEcheq);
