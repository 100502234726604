import React, { Component } from "react";
import { connect } from "react-redux";
import Col from "react-bootstrap/lib/Col";
import { string, func, bool, shape } from "prop-types";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";

import { selectors as sessionSelectors } from "reducers/session";
import { actions as servicePaymentsActions } from "reducers/servicePayments";
import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import I18n from "pages/_components/I18n";
import ContextMenu from "pages/_components/ContextMenu";

import { agendaPagoShape } from "util/servicePayments";
import Table from "pages/_components/Table";
import classNames from "classnames";
import Range from "pages/_components/Range";

class AgendaPagoItem extends Component {
    static propTypes = {
        agendaPago: agendaPagoShape.isRequired,
        tableLabel: string.isRequired,
        currencies: string.isRequired,
        dispatch: func.isRequired,
        isDesktop: bool,
        isSmallDesktop: bool,
        activeEnvironment: shape({
            permissions: shape({
                newPayment: bool,
            }),
        }).isRequired,
    };

    static defaultProps = {
        isDesktop: false,
        isSmallDesktop: false,
    };

    handleExpirationPayment = (expiration) => {
        const { dispatch, agendaPago } = this.props;
        dispatch(
            servicePaymentsActions.loadSummaryInfo({
                ...agendaPago,
                expiration,
            }),
        );
        dispatch(push(`/agenda/payment`));
    };

    handleConceptPayment = (concept) => {
        const { dispatch, agendaPago } = this.props;
        dispatch(
            servicePaymentsActions.loadSummaryInfo({
                ...agendaPago,
                concept,
            }),
        );
        dispatch(push(`/agenda/payment`));
    };

    unattachService = () => {
        const { agendaPago, dispatch } = this.props;
        const {
            entity: { description, handleBaseDebts, code, category },
        } = agendaPago;

        const adhesion = {
            adhesionCode: agendaPago.codeAdherence,
            enteDescription: description,
            enteCode: code,
            userLp: agendaPago.ususerLP,
            category: {
                code: category.code,
                description: category.description,
                shortDescrition: category.shortDescrition,
            },
            reference: agendaPago.reference,
            manejaBaseDeuda: handleBaseDebts,
        };
        dispatch(servicePaymentsActions.loadSummaryInfo(adhesion));
        dispatch(push(`/desadherir/confirmation`));
    };

    menuExpiration = (expiration) => {
        const { activeEnvironment } = this.props;
        const actions = [];
        if (activeEnvironment.permissions.newPayment) {
            actions.push({
                label: "payments.afip.list.actions.pay",
                onClick: () => this.handleExpirationPayment(expiration),
            });
        }
        actions.push({
            label: "services.adhesiones.table.header.unattachService",
            onClick: this.unattachService,
        });
        return <ContextMenu imageStyle="pr-0" items={actions} />;
    };

    menuConcept = (concept) => {
        const { activeEnvironment } = this.props;
        if (activeEnvironment.permissions.newPayment) {
            return (
                <ContextMenu
                    imageStyle="pr-0"
                    items={[
                        {
                            label: "payments.afip.list.actions.pay",
                            onClick: () => this.handleConceptPayment(concept),
                        },
                    ]}
                />
            );
        }
        return null;
    };

    render() {
        const { agendaPago, tableLabel, currencies, isDesktop, isSmallDesktop } = this.props;

        const {
            entity: { description, handleBaseDebts },
            reference,
        } = agendaPago;

        const row = handleBaseDebts
            ? agendaPago.expirations.map((expiration) => (
                  <Table.Row
                      className={`container-white border-bottom-none ${!isDesktop && "my-2"}`}
                      key={agendaPago.ususerLP}
                      renderAs={Link}
                      to={`/agenda/payment/detail/${agendaPago.ususerLP}/${expiration.concept.conceptCode}`}>
                      <Container className="flex-grow container-white text-uppercase">
                          {isDesktop ? (
                              <Col
                                  sm={12}
                                  className={classNames("px-0", {
                                      "d-flex align-items-center": isSmallDesktop,
                                  })}>
                                  <Col md={4} sm={12}>
                                      <Heading.DataGroup
                                          containerClassName={classNames("data-wrapper-flex", {
                                              "mb-0": isSmallDesktop,
                                              "my-2": !isSmallDesktop,
                                          })}
                                          data={
                                              <>
                                                  <span className="data-label f-size-4 mr-2">
                                                      {description.toUpperCase()}
                                                  </span>
                                                  <span className="f-size-55 color-primary">
                                                      {reference.toUpperCase()}
                                                  </span>
                                              </>
                                          }
                                          dataClassName={classNames("d-flex", {
                                              "f-dir-col": isSmallDesktop,
                                              "align-items-center": !isSmallDesktop,
                                          })}
                                      />
                                  </Col>
                                  <Col md={isSmallDesktop ? 3 : 4} sm={12}>
                                      <Heading.DataGroup
                                          containerClassName={classNames(
                                              "light-font font-weight-600 data-wrapper-flex justify-content-center ",
                                              {
                                                  "my-2": !isSmallDesktop,
                                              },
                                          )}
                                          label={`${tableLabel}.agenda.expiration.label`}
                                          data={
                                              <FormattedDate
                                                  className="f-size-4"
                                                  date={expiration.date}
                                                  dateFormat="dd/MM/yyyy"
                                              />
                                          }
                                      />
                                  </Col>
                                  <Col
                                      md={isSmallDesktop ? 5 : 4}
                                      sm={12}
                                      className={`d-flex align-items-center justify-content-end h-100 
                                      ${expiration.managesRange && expiration.managesIncomeAmounts && "flex-column"}`}>
                                      <div>
                                          <Heading.DataGroup
                                              containerClassName={classNames(
                                                  "data-wrapper-flex justify-content-end w-100",
                                                  { "my-15": !isSmallDesktop },
                                              )}
                                              label="services.newPayment.amountToPay"
                                              labelClassName="f-size-55 mr-2"
                                              data={
                                                  <span className="d-flex align-items-center">
                                                      <FormattedAmount
                                                          className="data-amount"
                                                          fontClassName="f-size-4"
                                                          currency={currencies[0].id.toString()}
                                                          quantity={expiration.amount}
                                                      />
                                                      {this.menuExpiration(expiration)}
                                                  </span>
                                              }
                                          />
                                          {expiration.concept.managesRange && (
                                              <Range
                                                  currency={currencies[0].id}
                                                  min={expiration.minAmount}
                                                  max={expiration.maxAmount}
                                              />
                                          )}
                                      </div>
                                  </Col>
                              </Col>
                          ) : (
                              <Col className="px-0" sm={12} md={6}>
                                  <Col sm={12} md={4}>
                                      <Heading.DataGroup
                                          containerClassName={classNames("data-wrapper-flex my-2", {
                                              "w-100 space-between": !isDesktop,
                                          })}
                                          dataClassName="w-100"
                                          data={
                                              <>
                                                  <div className="d-flex f-dir-col w-100">
                                                      <div className="d-flex space-between w-100">
                                                          <div className="d-flex f-dir-col">
                                                              <span className="font-regular color-primary f-size-65">
                                                                  {reference.toUpperCase()}
                                                              </span>
                                                              <span className="mr-2 font-regular f-size-55 line-height-1">
                                                                  {description.toUpperCase()}
                                                              </span>
                                                          </div>
                                                          {this.menuExpiration(expiration)}
                                                      </div>
                                                      <div className="w-100">
                                                          <Heading.DataGroup
                                                              containerClassName="d-flex align-items-baseline line-height-1"
                                                              labelClassName="f-size-65 font-light line-height-1"
                                                              label={`${tableLabel}.agenda.expiration.label`}
                                                              data={
                                                                  <FormattedDate
                                                                      date={expiration.date}
                                                                      dateFormat="dd/MM/yyyy"
                                                                      className="f-size-65 line-height-1"
                                                                  />
                                                              }
                                                          />
                                                      </div>

                                                      <div className="d-flex w-100 justify-content-end">
                                                          <div>
                                                              <Heading.DataGroup
                                                                  containerClassName="data-wrapper-flex justify-content-end"
                                                                  labelClassName="f-size-65 font-light line-height-1"
                                                                  label="services.newPayment.amountToPay"
                                                                  data={
                                                                      <div className="d-flex f-dir-col">
                                                                          <FormattedAmount
                                                                              currency={currencies[0].id.toString()}
                                                                              quantity={expiration.amount}
                                                                          />
                                                                      </div>
                                                                  }
                                                              />
                                                              {expiration.concept.managesRange && (
                                                                  <Range
                                                                      currency={currencies[0].id}
                                                                      min={expiration.minAmount}
                                                                      max={expiration.maxAmount}
                                                                  />
                                                              )}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </>
                                          }
                                      />
                                  </Col>
                              </Col>
                          )}
                      </Container>
                  </Table.Row>
              ))
            : agendaPago.concepts.map((concept) => (
                  <Table.Row
                      className={`container-white border-bottom-none ${!isDesktop && "my-2"}`}
                      key={agendaPago.ususerLP}
                      renderAs={Link}
                      to={`/agenda/payment/detail/${agendaPago.ususerLP}/${concept.conceptCode}`}>
                      <Container className="flex-grow container-white text-uppercase">
                          {isDesktop ? (
                              <Col
                                  sm={12}
                                  className={classNames("px-0", {
                                      "d-flex align-items-center": isSmallDesktop,
                                  })}>
                                  <Col md={4} sm={12}>
                                      <Heading.DataGroup
                                          containerClassName={classNames("data-wrapper-flex ", {
                                              "mb-0": isSmallDesktop,
                                              "my-2": !isSmallDesktop,
                                          })}
                                          data={
                                              <>
                                                  <span className="data-label f-size-4 mr-2">
                                                      {description.toUpperCase()}
                                                  </span>
                                                  <span className="f-size-55 color-primary">
                                                      {reference.toUpperCase()}
                                                  </span>
                                              </>
                                          }
                                          dataClassName={classNames("d-flex", {
                                              "f-dir-col": isSmallDesktop,
                                              "align-items-center": !isSmallDesktop,
                                          })}
                                      />
                                  </Col>
                                  <Col md={isSmallDesktop ? 3 : 4} sm={12}>
                                      <Heading.DataGroup
                                          containerClassName={classNames(
                                              "light-font font-weight-600 data-wrapper-flex justify-content-center mb-0",
                                              {
                                                  "my-2": !isSmallDesktop,
                                              },
                                          )}
                                          label={`${tableLabel}.agenda.expiration.label`}
                                          data={<I18n id="services.newPayment.noExpirationDate" />}
                                      />
                                  </Col>
                                  <Col
                                      md={isSmallDesktop ? 5 : 4}
                                      sm={12}
                                      className="d-flex align-items-center justify-content-end h-100">
                                      <Heading.DataGroup
                                          containerClassName={classNames(
                                              "data-wrapper-flex justify-content-end w-100",
                                              { "my-2": !isSmallDesktop },
                                          )}
                                          label="services.newPayment.amountToPay"
                                          labelClassName="f-size-55 mr-2"
                                          data={
                                              <div className="d-flex align-items-center">
                                                  <span className="data-amount px-2">
                                                      <span className="data-amount-currency f-size-4">-</span>
                                                  </span>
                                              </div>
                                          }
                                          dataClassName="data-desc"
                                      />
                                      {this.menuConcept(concept)}
                                  </Col>
                              </Col>
                          ) : (
                              <Col className="px-0" sm={12} md={6}>
                                  <Col sm={12} md={4}>
                                      <Heading.DataGroup
                                          containerClassName={classNames("data-wrapper-flex my-2", {
                                              "w-100 space-between": !isDesktop,
                                          })}
                                          dataClassName="w-100"
                                          data={
                                              <div className="d-flex f-dir-col w-100">
                                                  <div className="d-flex space-between w-100">
                                                      <div className="d-flex f-dir-col">
                                                          <span className="font-regular color-primary f-size-65">
                                                              {reference.toUpperCase()}
                                                          </span>
                                                          <span className="mr-2 font-regular f-size-55 line-height-1">
                                                              {description.toUpperCase()}
                                                          </span>
                                                      </div>
                                                      {this.menuConcept(concept)}
                                                  </div>
                                                  <div className="d-flex w-100 space-between">
                                                      <Heading.DataGroup
                                                          containerClassName="d-flex align-items-baseline line-height-1"
                                                          labelClassName="f-size-65 font-light line-height-1"
                                                          label={`${tableLabel}.agenda.expiration.label`}
                                                          data={
                                                              <I18n
                                                                  id="services.newPayment.noExpirationDate"
                                                                  componentProps={{
                                                                      className: "f-size-65 line-height-1",
                                                                  }}
                                                              />
                                                          }
                                                      />
                                                      <Heading.DataGroup
                                                          containerClassName="data-wrapper-flex justify-content-end"
                                                          labelClassName="f-size-65 font-light line-height-1"
                                                          label="services.newPayment.amountToPay"
                                                      />
                                                  </div>
                                                  <div className="d-flex w-100 justify-content-end">
                                                      <Heading.DataGroup
                                                          containerClassName="data-wrapper-flex justify-content-end"
                                                          data={
                                                              <div className="f-size-4 font-regular font-weight-600">
                                                                  <span> - </span>
                                                              </div>
                                                          }
                                                      />
                                                  </div>
                                              </div>
                                          }
                                      />
                                  </Col>
                              </Col>
                          )}
                      </Container>
                  </Table.Row>
              ));

        return row;
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(AgendaPagoItem);
