import React, { Component } from "react";
import { func, node } from "prop-types";
import Measure from "react-measure";

class ItemHeight extends Component {
    static propTypes = {
        children: func.isRequired,
        item: node,
        onResize: func,
    };

    static defaultProps = {
        onResize: () => {},
        item: null,
    };

    state = {
        itemHeight: 0,
    };

    handleResize = ({ bounds }) => {
        const { onResize } = this.props;
        this.setState({ itemHeight: bounds.height }, onResize);
    };

    render() {
        const { children, item } = this.props;
        const { itemHeight } = this.state;
        if (itemHeight) {
            return children(itemHeight);
        }
        return (
            <Measure bounds onResize={this.handleResize}>
                {({ measureRef }) => <div ref={measureRef}>{item}</div>}
            </Measure>
        );
    }
}

export default ItemHeight;
