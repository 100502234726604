import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Col, Grid, Row } from "react-bootstrap";
import { bool, func, string } from "prop-types";

import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { getArray } from "util/config";
import PageLoading from "pages/_components/PageLoading";

class ChangeLanguage extends Component {
    static propTypes = {
        fetching: bool,
        dispatch: func.isRequired,
        activeLanguage: string.isRequired,
    };

    static defaultProps = {
        fetching: false,
    };

    constructor(props) {
        super(props);
        const { activeLanguage } = this.props;

        this.state = { currentLanguage: activeLanguage, supportedLanguages: getArray("frontend.i18n.languages") };
    }

    getContent() {
        const { currentLanguage, supportedLanguages } = this.state;
        return (
            <form className="above-the-fold">
                <section className="container--layout">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <p className="text-lead">
                                    <I18n id="settings.changeLanguage.title" />
                                </p>
                            </Col>
                        </Row>
                    </Grid>
                </section>
                <section className="container--layout flex-grow align-items-center">
                    <Grid className="form-content">
                        <Row className="justify-content-center">
                            <Col sm={12} md={9} lg={6} xl={6} className="col col-12">
                                <div className="form-group">
                                    <div className="selection-list">
                                        {Object.keys(supportedLanguages).map((idLanguage) => {
                                            const radioId = `language-${supportedLanguages[idLanguage]}`;
                                            return (
                                                <div
                                                    className="c-control c-control--radio "
                                                    key={`language-${idLanguage}`}>
                                                    <input
                                                        defaultChecked={
                                                            supportedLanguages[idLanguage] === currentLanguage
                                                                ? "checked"
                                                                : ""
                                                        }
                                                        id={radioId}
                                                        type="radio"
                                                        name="language"
                                                        className="c-control-input"
                                                        onClick={() => this.handleClick(supportedLanguages[idLanguage])}
                                                    />
                                                    <label
                                                        className="c-control-label c-control-label--environment"
                                                        htmlFor={radioId}>
                                                        <I18n
                                                            id={`settings.changeLanguage.label.${supportedLanguages[idLanguage]}`}
                                                        />
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </section>
            </form>
        );
    }

    handleClick = (language) => {
        const { dispatch } = this.props;
        this.setState({
            currentLanguage: language,
        });
        document.documentElement.lang = language;
        dispatch(settingsActions.changeLanguage(language));
    };

    render() {
        const { fetching } = this.props;
        return (
            <Fragment>
                <PageLoading loading={fetching}>
                    <Head title="settings.changeLanguage" closeLinkTo="/settings" />
                    <Notification scopeToShow="changeLanguage" />
                    <MainContainer>{this.getContent()}</MainContainer>
                </PageLoading>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLanguage: i18nSelectors.getLang(state),
    fetching: settingsSelectors.isFetching(state) || i18nSelectors.getFetching(state),
});

export default connect(mapStateToProps)(ChangeLanguage);
