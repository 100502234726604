import * as API from "middleware/api";

export const loadSentRequestsMiddleware = (filters) =>
    API.executeWithAccessToken("debin.load.sentRequests", { ...filters, type: "vendedor" });

export const sendAddAccountsAction = (otp, idAccount, userId) =>
    API.executeWithAccessToken("debin.accounts.addOrRemove", { _otp: otp, idAccount, userId });

export const cancelDebin = (otp, debin) => {
    const {
        id,
        seller: { documentNumber },
    } = debin;
    return API.executeWithAccessToken("debin.cancel.sentRequest.send", { _otp: otp, documentNumber, id });
};

export const loadIncomingDebinListMiddleware = (filters) =>
    API.executeWithAccessToken("debin.load.sentRequests", { ...filters, type: "comprador" });

export const removeSellerAccountMiddleware = (idAccount) =>
    API.executeWithAccessToken("debin.seller.accounts.remove", { idAccount });

export const acceptIncomingDebinPreMiddleware = (idDebin) =>
    API.executeWithAccessToken("debin.incoming.accept.pre", { idDebin });

export const createCredinPre = (debin, recipients) =>
    API.executeWithAccessToken("credin.create.preview", { ...debin, recipients });

export const createCredin = (otp, debin, recipients, id, cbuList) =>
    API.executeWithAccessToken("credin.sentRequest.send", { _otp: otp, ...debin, recipients, id, cbuList });

export const loadSellerDebinDetail = (idDebin) => API.executeWithAccessToken("debin.seller.detail", { idDebin });

export const createDebin = (otp, debin, recipients) =>
    API.executeWithAccessToken("debin.create.send", { _otp: otp, ...debin, recipients });

export const createDebinPre = (debin, recipients) =>
    API.executeWithAccessToken("debin.create.preview", { ...debin, recipients });

export const acceptIncomingDebinSendMiddleware = (
    currency,
    amount,
    idDebin,
    otp,
    idAccount,
    userId,
    debitAccount,
    sellerDetail,
    idVoucher,
    isOwn,
) =>
    API.executeWithAccessToken("debin.incoming.accept.send", {
        currency,
        amount: {
            quantity: amount,
            currency,
        },
        idDebin,
        _otp: otp,
        idAccount,
        userId,
        debitAccount,
        sellerDetail,
        idVoucher,
        isOwn,
    });

export const declineIncomingDebinSendMiddleware = (currency, amount, idDebin, otp, idAccount, userId) =>
    API.executeWithAccessToken("debin.incoming.decline.send", {
        currency,
        amount,
        idDebin,
        _otp: otp,
        idAccount,
        userId,
    });

export const exportIncomingDebinTicket = (
    creationDate,
    idVoucher,
    debitAccount,
    sellerAccount,
    sellerCbu,
    sellerDocumentType,
    sellerDocumentNumber,
    sellerTitle,
    sellerBank,
    reference,
    amountCurrency,
    amountQuantity,
    concept,
    expirationDate,
    description,
    idDebin,
) =>
    API.executeWithAccessToken("/export.incoming.debin.ticket", {
        creationDate,
        idVoucher,
        debitAccount,
        sellerAccount,
        sellerCbu,
        sellerDocumentType,
        sellerDocumentNumber,
        sellerTitle,
        sellerBank,
        reference,
        amountCurrency,
        amountQuantity,
        concept,
        expirationDate,
        description,
        idDebin,
    });

export const exportTermsAndConditions = (format, dummy) =>
    API.executeAnonymous("/session.exportTermsAndConditions", { format, dummy });

export const getSeller = (seller) => API.executeWithAccessToken("/get.debin.seller", { seller });

export const relateBuyerToRecurrency = (debinPrestacion) =>
    API.executeWithAccessToken("/load.debin.recurrency", { ...debinPrestacion });

export const getRecurrencies = (form) => API.executeWithAccessToken("/debin.recurrency.list", { ...form });

export const setUpdateRecurrencyState = (debin) =>
    API.executeWithAccessToken("/change.status.debin.recurrency", { ...debin });

export const listConcepts = () => API.executeWithAccessToken("/debin.create.pre");
