import * as dateUtils from "util/date";

const creditCardBrandsValidations = {
    amex: /^3[47]/,
    visa: /^4/,
    mastercard: /^5[1-7]/,
};

export const detectBrand = (number) =>
    Object.keys(creditCardBrandsValidations).find((key) => creditCardBrandsValidations[key].test(number));

export const format = (number) => number.match(/.{4}/g).join(" ");

export const creditCardHasExpiredPayment = (creditCard) => dateUtils.isDateLessThanToday(creditCard.expirationDate);
