import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool, number, string } from "prop-types";
import { routerActions } from "react-router-redux/actions";

import { actions, selectors } from "reducers/form/transactionLines";
import { actions as notificationActions } from "reducers/notification";
import * as i18nUtils from "util/i18n";

import Button from "pages/_components/Button";

class PaymentLinesButtons extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        backUrl: string.isRequired,
        editedLines: shape({}).isRequired,
        hasEditedLines: bool.isRequired,
        linesBeingEdited: number.isRequired,
        isEditingPayment: bool.isRequired,
        linesWithNoAmount: number.isRequired,
    };

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(actions.resetEditedLines());
    }

    handleAddPaymentClick = () => {
        const { dispatch } = this.props;
        dispatch(actions.setIsEditingPayment(true));
    };

    handleSaveClick = () => {
        const { dispatch, editedLines, linesWithNoAmount } = this.props;
        dispatch(actions.saveLines(editedLines));
        if (linesWithNoAmount) {
            dispatch(
                notificationActions.showNotification(
                    i18nUtils.get("massive.payments.warning.noAmount", "", {
                        LINE_COUNT: linesWithNoAmount,
                    }),
                    "warning",
                    ["form"],
                ),
            );
        }
        this.handleBackClick();
    };

    handleBackClick = () => {
        const { backUrl } = this.props;
        this.navigate(routerActions.replace({ pathname: backUrl, state: { shouldLoadForm: false } }));
    };

    navigate = (action) => {
        const { dispatch } = this.props;
        dispatch(action);
    };

    handleCancelClick = () => {
        const { dispatch, backUrl } = this.props;
        dispatch(actions.cancelEdition());
        this.navigate(routerActions.replace({ pathname: backUrl, state: { shouldLoadForm: false } }));
    };

    render() {
        const { hasEditedLines, linesBeingEdited, isEditingPayment } = this.props;
        return (
            <div>
                <Button
                    image="images/plus.svg"
                    bsStyle="primary"
                    className="btn-small"
                    onClick={this.handleAddPaymentClick}
                    label="salaryPayment.manual.addPayment.showForm"
                    disabled={isEditingPayment}
                />
                <Button
                    bsStyle="primary"
                    className="btn-small"
                    onClick={this.handleSaveClick}
                    label="salaryPayment.manual.addPayment.saveAndReturn"
                    disabled={!hasEditedLines || linesBeingEdited > 0}
                />
                <Button
                    className="btn-small btn-outline"
                    onClick={this.handleCancelClick}
                    label="global.cancel"
                    disabled={!hasEditedLines}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    editedLines: selectors.getEditedLines(state),
    hasEditedLines:
        Object.keys(selectors.getEditedLines(state)).length > 0 ||
        selectors.getEditedLines(state).length !== selectors.getTransactionLines(state).length,
    linesBeingEdited: selectors.getLinesBeingEdited(state),
    linesWithNoAmount: selectors.getLinesWithNoAmount(state).length,
});

export default connect(mapStateToProps)(PaymentLinesButtons);
