import React, { Component } from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";

import RadioButton from "pages/_components/fields/Radio";
import classNames from "classnames";

class RadioButtonGroup extends Component {
    static propTypes = {
        inLineControl: bool,
        spaceBetween: bool,
        labelKey: string,
        name: string.isRequired,
        onChange: func.isRequired,
        options: arrayOf(shape({})).isRequired,
        value: string,
        valueKey: string,
        selectorId: string.isRequired,
    };

    static defaultProps = {
        labelKey: "label",
        inLineControl: false,
        spaceBetween: false,
        value: "",
        valueKey: "id",
    };

    handleRadioChange = (event) => {
        const { onChange } = this.props;

        onChange(event.target.value);
    };

    render() {
        const { inLineControl, labelKey, name, options, selectorId, value, valueKey, spaceBetween } = this.props;
        return (
            <div className={classNames("form-group-control-list", { "space-between": spaceBetween })}>
                {options.map((option) => (
                    <RadioButton
                        id={`${selectorId}_${option[valueKey]}`}
                        checked={option[valueKey] === value}
                        inLineControl={inLineControl}
                        key={`${selectorId}_${option[valueKey]}`}
                        label={option[labelKey]}
                        maskedData={option.maskedData}
                        name={name}
                        textAlign={option.textAlign}
                        onChange={this.handleRadioChange}
                        value={option[valueKey]}
                        radioClassName={spaceBetween ? "m-0" : ""}
                    />
                ))}
            </div>
        );
    }
}

export default RadioButtonGroup;
