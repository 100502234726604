/* eslint-disable no-else-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unused-state */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, createElement } from "react";
import { bool, func, shape, string, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { push } from "react-router-redux";
import Select from "react-select";

import { actions as transactionsActions, selectors as transactionsSelectors, actions } from "reducers/transactions";
import * as i18n from "util/i18n";
import { selectors as sessionSelectors } from "reducers/session";

import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import List from "pages/transactions/_components/List";
import MainContainer from "pages/_components/MainContainer";
import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import SelectedTransactionsAmount from "pages/transactions/SelectedTransactionsAmount";
import classNames from "classnames";
import DownloadButton from "pages/_components/DownloadButton";
import Tooltip from "pages/_components/tooltip/Tooltip";
import { actions as tooltipActions } from "reducers/tooltip";
import * as configUtils from "util/config";
import { actions as statusActions } from "reducers/status";
import PeriodFilterPendingTransactions from "./_components/PeriodFilterPendingTransactions";
import AmountFilterPendingTransactions from "./_components/AmountFilterPendingTransactions";

import FilterSelect from "./FilterSelect";

const FORM_ID = "pending.transactions";
const PENDING = "PENDING";

const components = {
    creationDate: PeriodFilterPendingTransactions,
    amount: AmountFilterPendingTransactions,
};

class PendingTransactionList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        status: string.isRequired,
        pendingDispatch: bool,
        filters: shape({}).isRequired,
        activeEnvironment: shape({}).isRequired,
        location: shape({}).isRequired,
        activities: arrayOf(shape({})).isRequired,
        selectedTransactionRows: arrayOf().isRequired,
        fetchingMore: bool.isRequired,
        isDownload: bool.isRequired,
    };

    static defaultProps = {
        pendingDispatch: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedFilter: props.filters?.filter ? props.filters?.filter : null,
            activitySelected: props.filters?.idActivity,
            dataSelected: {},
            widthScreen: window.innerWidth,
            cleanFilter: false,
            filtered: false,
            orderBy: "submit_date_time DESC",
        };
    }

    componentDidMount = () => {
        const { dispatch, location, activities, filters } = this.props;
        if (!activities) {
            dispatch(transactionsActions.loadTransactionActivitiesRequest());
        }
        if (!filters) {
            this.changeFilterType(PENDING, true);
        }
        if (this.isScheduled()) {
            dispatch(transactionsActions.loadListRequest({ ...filters, status: filters?.status }, true, false));
        }
        dispatch(transactionsActions.saveTransactionRoute(location?.pathname));
        window.addEventListener("resize", this.updateWidthScreen);
        dispatch(statusActions.saveLastHref(location));
        this.filterTransactions();
    };

    componentDidUpdate(prevProps, prevState) {
        const { widthScreen } = this.state;
        if (prevState.widthScreen !== widthScreen) {
            this.updateWidthScreen();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWidthScreen);
    }

    dataSelect = (columnFilter, changeFilter) => {
        this.setState({ dataSelected: { columnFilter, changeFilter } });
    };

    changeFilterType = (option, firstTime) => {
        const { dispatch, status } = this.props;
        const { filtered } = this.state;
        if (firstTime || option !== status) {
            const limitMonths = configUtils.getInteger("frontend.pendingTransactions.allowedPeriod.Months", 6);
            const dateFrom = new Date();
            dateFrom.setMonth(dateFrom.getMonth() - limitMonths);
            const dateTo = new Date();
            const filters = {
                pageNumber: 1,
                filter: undefined,
                status,
                filterPendingDispatch: false,
                functionRequest: "pendingTransactions",
                dateFrom,
                dateTo,
                orderBy: "submit_date_time DESC",
            };
            this.setState({ activitySelected: null, selectedFilter: null, cleanFilter: true });
            dispatch(actions.changeStatusType(option));
            dispatch(transactionsActions.loadListRequest({ ...filters, status: option }, true, false));
            dispatch(tooltipActions.clearActiveTooltip());
            dispatch(transactionsActions.clearMultipleSignaturesData());
        }
        if (filtered) {
            this.setState({ filtered: false });
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop/"));
    };

    filterButtons = () => {
        const { status, isDesktop, activeEnvironment } = this.props;
        const stateFilters = [];
        if (activeEnvironment?.administrationScheme !== "simple") {
            stateFilters.push("PENDING");
        }
        stateFilters.push("SCHEDULED", "SCHEDULED_RECURRENT", "DRAFT", "ALL");

        const buttons = stateFilters.map((option, idx) => {
            const button = (
                <Button
                    key={option}
                    className="btn-outline btn-regular"
                    block={false}
                    image={status === option ? "images/check.svg" : ""}
                    style={{
                        borderRadius: "25px",
                        textTransform: "none",
                        fontWeight: "400",
                        padding: "8px 8px",
                        marginRight: "1rem",
                        backgroundColor: status === option && "#f2f8fd",
                        width: isDesktop ? "" : "100%",
                        borderColor: status === option ? "#0071ce" : "#666666",
                        color: status === option ? "#0071ce" : "#666666",
                    }}
                    onClick={() => this.changeFilterType(option)}
                    label={FORM_ID.concat(".", option.toLowerCase(), `${isDesktop ? "" : ".mobile"}`)}
                />
            );

            return isDesktop ? (
                button
            ) : (
                <div
                    key={option}
                    style={{
                        width: stateFilters.length - 1 === idx ? "100%" : "50%",
                        paddingLeft: "1%",
                        paddingRight: "1%",
                    }}>
                    {button}
                </div>
            );
        });

        return isDesktop ? buttons : <div style={{ display: "flex", flexWrap: "wrap" }}>{buttons}</div>;
    };

    handleChange = ({ value }) => {
        this.setState({ selectedFilter: value });
    };

    cleanSelectedRows = () => {
        const { dispatch } = this.props;
        dispatch(transactionsActions.clearSelectedTransactionRows());
    };

    renderFilter = () => {
        const { selectedFilter, activitySelected } = this.state;
        const { dispatch, fetching, pendingDispatch, status, filters } = this.props;
        if (!selectedFilter || (selectedFilter !== "amount" && selectedFilter !== "creationDate")) {
            return null;
        }

        const limitMonths = configUtils.getInteger("frontend.pendingTransactions.allowedPeriod.Months", 6);
        const dateFrom = new Date();
        dateFrom.setMonth(dateFrom.getMonth() - limitMonths);
        const dateTo = new Date();
        const props = {
            dispatch,
            isDesktop: true,
            fetching,
            onlyPendings: true,
            pendingDispatch,
            className: "cmf-button-width",
            status,
            idActivity: activitySelected,
            buttonLabel: "transactions.filter.button",
            marginTopButton: false,
            cleanSelectedRows: this.cleanSelectedRows,
            minAmount: filters?.minAmount,
            maxAmount: filters?.maxAmount,
            dateTo: selectedFilter === "creationDate" ? dateTo : undefined,
            dateFrom: selectedFilter === "creationDate" ? dateFrom : undefined,
            cleanFiltersButton: this.cleanFiltersButton(),
            renderOrder: this.renderOrderBy(),
        };

        return (
            <Col sm={12} lg={12} className="pending-transactions__amount-date pl-0 pr-3">
                {createElement(components[selectedFilter], { ...props })}
            </Col>
        );
    };

    setActivityInState = ({ value }) => {
        this.setState({ activitySelected: value });
    };

    selectActivity = ({ value }) => {
        this.setState({ activitySelected: value });
        this.filterTransactions(value);
    };

    filterTransactions = (selectActivity) => {
        const { dispatch, status, filters } = this.props;
        const { selectedFilter, activitySelected } = this.state;
        const idActivity = selectActivity || activitySelected;

        this.cleanSelectedRows();

        const limitMonths = configUtils.getInteger("frontend.pendingTransactions.allowedPeriod.Months", 6);
        const dateFrom = new Date();
        dateFrom.setMonth(dateFrom.getMonth() - limitMonths);
        const dateTo = new Date();
        const orderFilters = {
            ...filters,
            dateFrom,
            dateTo,
            pageNumber: 1,
            filter: selectedFilter,
            status: status || "PENDING",
            idActivity,
            filterPendingDispatch: selectedFilter !== null,
            functionRequest: "pendingTransactions",
        };
        const pendingDispatch = selectedFilter === "liberador";
        dispatch(transactionsActions.loadListRequest(orderFilters, true, pendingDispatch));
        this.setState((prevState) => ({
            ...prevState,
            filtered: true,
        }));
    };

    addMultipleSelectedRow = (checked, transaction) => {
        const { dispatch } = this.props;
        if (checked) {
            dispatch(transactionsActions.saveSelectedTransactionRows(transaction));
        } else {
            dispatch(transactionsActions.removeSelectedTransactionRows(transaction));
        }
    };

    montoOperaciones = () => {
        const { isDesktop, selectedTransactionRows } = this.props;
        return (
            <SelectedTransactionsAmount
                selectedTransactionRows={selectedTransactionRows}
                isDesktop={isDesktop}
                withoutData={selectedTransactionRows}
            />
        );
    };

    multipleSigns = () => {
        const { dispatch, activities, selectedTransactionRows } = this.props;
        const { activitySelected } = this.state;

        const completeActivity = activities.find((activity) => activity.idActivity === activitySelected);

        dispatch(
            transactionsActions.setMultipleSignaturesData({
                activitySelected: completeActivity,
                selectedTransactionRows,
                filtered: true,
            }),
        );
        dispatch(push("/pendingTransaction/signMultiple"));
    };

    deleteMultipleSigns = () => {
        const { dispatch, activities, selectedTransactionRows } = this.props;
        const { activitySelected } = this.state;

        const completeActivity = activities.find((activity) => activity.idActivity === activitySelected);

        dispatch(
            transactionsActions.setMultipleSignaturesData({
                activitySelected: completeActivity,
                selectedTransactionRows,
                filtered: true,
            }),
        );
        dispatch(push("/pendingTransaction/deleteMultiple"));
    };

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("pendingDispatch.list.title")}</h1>
        </div>
    );

    rightContent = () => {
        const pendingTransactionsFunctionalities = configUtils.getBoolean(
            "backend.show.PendingTransactions.functionalities",
            false,
        );

        return (
            <>
                {pendingTransactionsFunctionalities && (
                    <div className="pending-transaction-dropdown">
                        <Button
                            image="images/download_bold_white.svg"
                            className="button-white-header"
                            onClick={() => this.handleDownloadClick("pdf")}
                            bsStyle="outline"
                        />
                    </div>
                )}
            </>
        );
    };

    rightContentDisabled = () => {
        const pendingTransactionsFunctionalities = configUtils.getBoolean(
            "backend.show.PendingTransactions.functionalities",
            false,
        );

        return (
            <>
                {pendingTransactionsFunctionalities && (
                    <div className="pending-transaction-dropdown">
                        <Button
                            image="images/download_bold_white.svg"
                            className="button-white-header"
                            bsStyle="outline"
                            disabled
                        />
                    </div>
                )}
            </>
        );
    };

    cleanFiltersButton = () => {
        const { fetching } = this.props;
        const { activitySelected } = this.state;
        if (activitySelected) {
            return (
                <Button
                    label="global.clean.filters"
                    className="clean-filters-label font-light filter-button my-0 w-100"
                    onClick={() => this.changeFilterType(PENDING, true)}
                    image="images/cross.svg"
                    imageStyle="circle-cross"
                    disabled={fetching}
                />
            );
        }
        return null;
    };

    updateWidthScreen = () => {
        this.setState({ widthScreen: window.innerWidth });
    };

    updateCols = () => {
        const { widthScreen, activitySelected } = this.state;
        if (widthScreen >= 1920 && activitySelected) {
            return 4;
        } else if (widthScreen >= 1920 && !activitySelected) {
            return 4;
        } else if (widthScreen < 1920 && !activitySelected) {
            return 5;
        }
        return 3;
    };

    renderOrderBy = () => {
        const { selectedFilter, activitySelected } = this.state;
        const options = [
            {
                value: "creationDate",
                label: i18n.get("transactions.filters.searchBy.creationDate"),
            },
            {
                value: "amount",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
            {
                value: "liberador",
                label: i18n.get("transaction.to.liberate"),
            },
            {
                value: "pending",
                label: i18n.get("transaction.to.sign"),
            },
        ];
        return (
            <>
                <Col
                    sm={12}
                    md={3}
                    lg={3}
                    className={
                        activitySelected
                            ? "w100 px-0 pending-transactions__search-by"
                            : "px-0 pending-transactions__search-by"
                    }>
                    <div className="form-group-text">
                        <label htmlFor="searchBy" className="control-label">
                            <I18n id="accounts.movements.filters.searchBy" />
                        </label>
                    </div>
                    <Select
                        name="searchBy"
                        className="input-group"
                        clearable={false}
                        searchable={false}
                        onChange={this.handleChange}
                        options={options}
                        value={selectedFilter}
                        optionClassName="needsclick"
                        placeholder={i18n.get("transactions.filters.placeholder")}
                        noResultsText={i18n.get("global.no.results")}
                    />
                </Col>
            </>
        );
    };

    isScheduled = () => {
        const { filters } = this.props;
        if (filters?.status === "SCHEDULED" || filters?.status === "SCHEDULED_RECURRENT") {
            return true;
        }
        return false;
    };

    handleDownloadClick = (format) => {
        const { dispatch, filters } = this.props;
        const { selectedFilter } = this.state;

        const filterPendingDispatch = selectedFilter !== null;
        const functionRequest = "pendingTransactions";
        const pendingDispatch = selectedFilter === "liberador";
        dispatch(
            transactionsActions.downloadPendingTransactionsRequest(
                {
                    ...filters,
                    pageNumber: 1,
                    filterPendingDispatch,
                    functionRequest,
                },
                true,
                pendingDispatch,
                format,
            ),
        );
    };

    handleFilterButtonsClick = (orderByName, orderDirection) => {
        const { filters } = this.props;
        const { selectedFilter } = this.state;

        const limitMonths = configUtils.getInteger("frontend.pendingTransactions.allowedPeriod.Months", 6);
        const dateFrom = new Date();
        dateFrom.setMonth(dateFrom.getMonth() - limitMonths);
        const dateTo = new Date();
        let orderFilters = { filters };
        const order = orderDirection === "ascendent" ? " ASC" : " DESC";
        orderFilters = {
            ...filters,
            dateFrom,
            dateTo,
            orderBy: orderByName + order,
            orderByName,
            orderDirection,
            pageNumber: 1,
        };
        const { dispatch } = this.props;
        const pendingDispatch = selectedFilter === "liberador";
        dispatch(transactionsActions.loadListRequest(orderFilters, true, pendingDispatch));
    };

    setOrder = (orderByName, orderDirection) => {
        const { dispatch, filters } = this.props;
        dispatch(
            transactionsActions.setFilters({
                ...filters,
                orderByName,
                orderDirection,
            }),
        );

        this.handleFilterButtonsClick(orderByName, orderDirection);
    };

    render() {
        const {
            fetching,
            isDesktop,
            status,
            selectedTransactionRows,
            activities,
            fetchingMore,
            isDownload,
            filters,
        } = this.props;
        const {
            defaultFilters,
            selectedFilter,
            activitySelected,
            dataSelected,
            widthScreen,
            cleanFilter,
            filtered,
        } = this.state;

        const options = [
            {
                value: "creationDate",
                label: i18n.get("transactions.filters.searchBy.creationDate"),
            },
            {
                value: "amount",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
            {
                value: "liberador",
                label: i18n.get("transaction.to.liberate"),
            },
            {
                value: "pending",
                label: i18n.get("transaction.to.sign"),
            },
        ];

        const orderByOptions = [
            {
                value: "id_activity",
                label: i18n.get("accounts.movements.filters.searchBy.transaction"),
            },
            {
                value: "total_amount_data",
                label: i18n.get("accounts.movements.filters.searchBy.amount"),
            },
            {
                value: "creation_date_time",
                label: i18n.get("transactions.filters.searchBy.creationDate"),
            },
        ];

        const orderOptions = [
            {
                id: "0",
                label: <I18n id="accounts.movements.filters.searchBy.upward" />,
            },
            {
                id: "1",
                label: <I18n id="accounts.movements.filters.searchBy.falling" />,
            },
        ];

        const activityOptions =
            activities &&
            activities.length > 0 &&
            activities.map(({ activityName, idActivity }) => ({
                value: idActivity,
                label: activityName,
            }));
        const pendingTransactionsFunctionalities = configUtils.getBoolean(
            "backend.show.PendingTransactions.functionalities",
            false,
        );

        const downloadEnabled = status === "PENDING";

        return (
            <>
                <Notification scopeToShow="transactions" />
                <div className="admin-detail-head pl-0 pr-1">
                    <Head
                        onBack={this.handleBack}
                        headerClassName={!isDesktop ? "blue-main-header-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        centerContentClassName="mx-5"
                        rightContent={
                            // eslint-disable-next-line no-nested-ternary
                            isDesktop || status !== "PENDING"
                                ? null
                                : isDownload
                                ? this.rightContentDisabled
                                : this.rightContent
                        }
                        accessibilityTextId="pendingDispatch.list.title"
                    />
                    {isDesktop && (
                        <div className="d-flex">
                            <div className="d-flex align-items-center w-100 space-between mb-3">
                                <div className="d-flex align-items-center">
                                    <I18n
                                        component="h1"
                                        componentProps={{ className: "view-title mb-0" }}
                                        id="pendingDispatch.list.title"
                                    />
                                </div>
                                {pendingTransactionsFunctionalities && (
                                    <div className="d-flex align-items-center">
                                        <Tooltip
                                            id="pending-transactions-tooltip"
                                            activeTooltip="pending-transactions-tooltip"
                                            message={<I18n id="transactions.list.tooltip.message" />}
                                            left
                                            messageClass="pending-transactions__tooltip"
                                        />
                                        <DownloadButton
                                            fetching={fetching}
                                            handleClickMessage="global.download"
                                            csvDownload
                                            xlsDownload
                                            imageStyle="mr-2"
                                            handleClick={this.handleDownloadClick}
                                            btnClass="min-width-14"
                                            exportDisabled={!downloadEnabled}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    <Container className="container--layaut flex-grow container-white mt-1">
                        <div className="w-100 my-2 mx-3">{this.filterButtons()}</div>
                    </Container>
                    <Row
                        className={
                            isDesktop
                                ? `my-2 mx-0 container-white d-flex pending-transactions__filter ${
                                      status !== "PENDING" ? "justify-content-end" : null
                                  }`
                                : "mt-2 mb-0 mx-0"
                        }>
                        {status === "PENDING" && (
                            <>
                                <Col sm={12} md={3} lg={3} className="px-0">
                                    <Col sm={12} lg={12} md={12} className={isDesktop ? "pr-0" : ""}>
                                        <div className="form-group-text">
                                            <label htmlFor="activity" className="control-label">
                                                <I18n id="transactions.list.filters.transaction.activity.label" />
                                            </label>
                                        </div>
                                        <div className="input-group">
                                            <Select
                                                name="activity"
                                                className="slideFromBottom"
                                                clearable={false}
                                                searchable={isDesktop}
                                                onChange={isDesktop ? this.setActivityInState : this.selectActivity}
                                                options={activityOptions}
                                                optionClassName="needsclick"
                                                placeholder={i18n.get("transactions.filters.placeholder")}
                                                value={activitySelected}
                                                noResultsText={i18n.get("global.no.results")}
                                            />
                                        </div>
                                    </Col>
                                </Col>
                            </>
                        )}
                        <Col
                            sm={12}
                            md={9}
                            lg={status === "PENDING" ? 9 : 3}
                            className={`px-0 d-flex filters my-0 ${isDesktop ? "pb-4 space-between" : "pb-1"}`}>
                            {status === "PENDING" && (
                                <div className="w-100">
                                    {(selectedFilter === "liberador" ||
                                        selectedFilter === "pending" ||
                                        !selectedFilter) &&
                                        isDesktop && (
                                            <Col
                                                sm={12}
                                                md={4}
                                                lg={3}
                                                className="d-flex f-dir-col mt-35 pt-3 pl-3 pr-0">
                                                <Button
                                                    bsStyle="primary"
                                                    label="transactions.filter.button"
                                                    loading={fetching}
                                                    onClick={() => this.filterTransactions()}
                                                    type="submit"
                                                    className="filter-button m-0 w-100"
                                                />
                                                {this.cleanFiltersButton()}
                                            </Col>
                                        )}
                                    {activitySelected &&
                                        isDesktop &&
                                        selectedFilter !== "amount" &&
                                        selectedFilter !== "creationDate" && (
                                            <Col sm={12} md={3} lg={3} className="pr-0 pending-transactions__search-by">
                                                <div className="form-group-text">
                                                    <label htmlFor="searchBy" className="control-label">
                                                        <I18n id="accounts.movements.filters.searchBy" />
                                                    </label>
                                                </div>
                                                <Select
                                                    name="searchBy"
                                                    className="input-group"
                                                    clearable={false}
                                                    searchable={false}
                                                    onChange={this.handleChange}
                                                    options={options}
                                                    value={selectedFilter}
                                                    optionClassName="needsclick"
                                                    placeholder={i18n.get("transactions.filters.placeholder")}
                                                    noResultsText={i18n.get("global.no.results")}
                                                />
                                            </Col>
                                        )}
                                    {this.renderFilter()}
                                </div>
                            )}
                            {isDesktop && (
                                <Col
                                    sm={12}
                                    md={3}
                                    lg={status === "PENDING" ? this.updateCols() : 12}
                                    className={fetching ? "opacity-0" : "px-0"}>
                                    <FilterSelect
                                        className={classNames({
                                            "orderby-column":
                                                (activitySelected && widthScreen < 1920) || widthScreen < 1200,
                                            "min-w100": widthScreen < 1200,
                                        })}
                                        orderByOptions={orderByOptions}
                                        orderOptions={orderOptions}
                                        isDesktop={isDesktop}
                                        columnFilter={dataSelected.columnFilter}
                                        changeFilter={this.setOrder}
                                        cleanFilter={cleanFilter}
                                        setCleanFilter={(value) => this.setState({ cleanFilter: value })}
                                        defaultOrderBy={
                                            filters?.orderByName
                                                ? orderByOptions.find(({ value }) => value === filters?.orderByName)
                                                : orderByOptions.find(({ value }) => value === "creation_date_time")
                                        }
                                        defaultOrder={filters?.orderDirection || "descendent"}
                                    />
                                </Col>
                            )}
                        </Col>
                    </Row>
                </div>
                <div>
                    <MainContainer
                        showLoader={fetching || (isDesktop && fetchingMore)}
                        loadingClassName="line-loader"
                        showChildrenWithLoader
                        className="main-container pending-transactions-container">
                        <div className="above-the-fold ">
                            <List
                                isDesktop={isDesktop}
                                activitySelected={activitySelected}
                                showSearchButton
                                defaultFilters={defaultFilters}
                                onlyPendings
                                selectedTransactionRows={selectedTransactionRows}
                                addMultipleSelectedRow={this.addMultipleSelectedRow}
                                cleanSelectedRows={this.cleanSelectedRows}
                                selectedFilter={selectedFilter}
                                dataSelect={this.dataSelect}
                                filtered={activitySelected && filtered}
                                multipleSigns={this.multipleSigns}
                                deleteMultipleSigns={this.deleteMultipleSigns}
                                montoOperaciones={this.montoOperaciones}
                            />
                        </div>
                    </MainContainer>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: transactionsSelectors.getFetching(state),
    pageNumber: transactionsSelectors.getPageNumber(state),
    filters: transactionsSelectors.getFilters(state),
    status: transactionsSelectors.getStatusType(state),
    transactions: transactionsSelectors.getTransactions(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    activities: transactionsSelectors.getFilteredActivities(state, [
        "echeq.admit.action",
        "echeq.anulate-echeq.action",
        "echeq.repudiate.action",
        "echeq.rescue.action",
        "echeq.custody.action",
        "echeq.deposit.action",
        "echeq.repudiate-return.action",
        "echeq.request-return.action",
        "core.forms.send",
        "fundcorp.register.rescue.request",
        "fundcorp.register.suscription.request",
        "deposits.confirmDeposit",
    ]),
    selectedTransactionRows: transactionsSelectors.getSelectedTransactionRows(state),
    fetchingMore: transactionsSelectors.isFetchingMore(state),
    isDownload: transactionsSelectors.getIsDownload(state),
});

export default connect(mapStateToProps)(PendingTransactionList);
