import React, { Component } from "react";
import { connect } from "react-redux";
import { shape, bool, arrayOf, string, func } from "prop-types";
import { selectors } from "reducers/checks";
import { selectors as transactionsSelectors } from "reducers/transactions";
import TransactionPotencialSchemesDrawer from "pages/transactions/_components/TransactionPotencialSchemesDrawer";
import Button from "pages/_components/Button";
import Drawer from "rc-drawer";
import Container from "pages/_components/Container";

class TransactionSchemeMobile extends Component {
    static propTypes = {
        potentialSignaturesData: arrayOf(
            shape({ typesOfSignatures: arrayOf({ signature: string, selected: bool }), liberator: string }),
        ).isRequired,
        showDrawerMobile: bool.toString.isRequired,
        closeMobileDrawer: func.isRequired,
    };

    render() {
        const { potentialSignaturesData, showDrawerMobile, closeMobileDrawer } = this.props;

        return (
            <>
                {potentialSignaturesData?.length !== 0 && (
                    <Drawer
                        width="100vw"
                        open={showDrawerMobile}
                        handler={false}
                        placement="right"
                        level={null}
                        onClose={closeMobileDrawer}>
                        <div className="drawer-content-inner">
                            <div className="btn-block justify-content-end d-flex">
                                <Button
                                    bsStyle="link"
                                    image="images/cross.svg"
                                    className="toolbar-btn view-close btn-quiet view-close mt-4 mr-3"
                                    block={false}
                                    onClick={closeMobileDrawer}
                                />
                            </div>
                            <Container>
                                <TransactionPotencialSchemesDrawer potentialSignaturesData={potentialSignaturesData} />
                            </Container>
                        </div>
                    </Drawer>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionEcheqData(state),
    potentialSignaturesData: transactionsSelectors.getPotentialSignaturesData(state),
});

export default connect(mapStateToProps)(TransactionSchemeMobile);
